import React from 'react';

import { array, func, shape } from 'prop-types';

import ImagesCarousel from '@pm/components/AttachmentsV2/components/ImagesCarousel';

const ImagesCarouselWrapper = ({ field, options }) => (
  <ImagesCarousel
    images={field.value.images}
    imageWidth={172}
    imageHeight={139}
    chunkSize={4}
    margin={15}
    timeout={200}
    titleText="Select Field Photos"
    selectable
    onSelect={options.onSelectImages}
  />
);

ImagesCarouselWrapper.propTypes = {
  field: shape({
    value: shape({
      images: array.isRequired
    }).isRequired
  }).isRequired,
  options: shape({
    onSelectImage: func.isRequired
  }).isRequired
};

export default ImagesCarouselWrapper;
