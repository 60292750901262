import React from 'react';
import { Button, withStyles } from '@material-ui/core';

const BOButton = withStyles(theme => ({
  root: {
    width: '100%'
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    '&:focus': {
      backgroundColor: theme.palette.error.focus
    },
    '&:hover': {
      backgroundColor: theme.palette.error.hover
    }
  }
}))(({isConfirmRemoveMode, classes, color = 'primary', ...other}) => (
  <Button
    color={color}
    className={`${classes.root} ${isConfirmRemoveMode && classes.deleteButton}`}
    {...other}
  />
));

export default BOButton;
