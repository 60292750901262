import React, { useState } from 'react';

import { Button, ThemeProvider, TV, TW, Typography } from '@buildhero/sergeant';
import { Box, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { sortBy } from 'lodash';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';

import { SergeantModal } from 'components';
import Context from 'components/Context';
import ResponsiveTable from 'components/ResponsiveTable';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import ReadonlyWrapper from 'scenes/Quotes/components/ReadonlyWrapper';
import { CustomerPropertyService } from 'services/core';
import { Logger } from 'services/Logger';
import { getTenantSettingValueForKey, logErrorWithCallback } from 'utils';
import { QuoteStatus, TaskConstants } from 'utils/AppConstants';
import { Mode } from 'utils/constants';

import { NewCardButton, RenderCard } from './TaskCard';
import TaskGroup from './TaskGroups';
import TasksGroupLayout from './TaskGroups/TaskGroup.layout';
import { updateTasksAndGroups } from './TaskGroups/TaskGroup.utils';
import { taskListMeta } from './Tasks.layout';
import useStyles from './Tasks.styles';
import TotalsPanel from './TotalsPanel';

const LABELS = {
  noDataMsg: 'Nothing here.',
  noTaskMsg: 'Please add a task'
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export function TasksDNDComponent(props) {
  const {
    addTaskService,
    updateTaskService,
    config,
    quoteInfo,
    tasks,
    addTaskGroup,
    updateTaskGroup,
    taskGroups,
    setTaskGroups,
    deleteTaskGroup,
    isReadOnly
  } = props;
  const classes = useStyles();
  const [isTaskGroupModalOpen, setIsTaskGroupModalOpen] = useState({ isOpen: false, type: '' });
  const [checkedTasks, setCheckedTasks] = useState([]);

  const getChanges = (newState, idx) => {
    return newState[idx].tasks
      .filter((item, index) => item !== taskGroups[idx].tasks[index])
      .map(item => ({
        item,
        newIndex: newState[idx].tasks.findIndex(elem => elem.id === item.id)
      }));
  };

  async function onDragEnd(result) {
    const { source, destination } = result;
    // dropped outside the task groups
    if (!destination) {
      return;
    }
    const sInd = source.droppableId;
    const dInd = destination.droppableId;
    if (sInd === dInd) {
      // DnD within a group or within tasks that have no group
      const items = reorder(taskGroups[sInd]?.tasks, source.index, destination.index);
      const newState = [...taskGroups];
      newState[sInd] = { ...taskGroups[sInd], tasks: items };
      setTaskGroups(newState);
      const changes = getChanges(newState, sInd);
      try {
        const promises = changes.map(set => {
          const newItem = set.item;
          newItem.sortOrder = set.newIndex;
          return updateTaskService({
            id: newItem.id,
            sortOrder: newItem.sortOrder
          });
        });
        await Promise.all(promises).then(values => {
          values.forEach(response => {
            if (response?.data?.updateTask) {
              const data = response?.data?.updateTask;
              const index = newState[sInd].tasks.findIndex(elem => elem.id === data.id);
              newState[sInd].tasks[index].version = data.version;
              newState[sInd].tasks[index].sortOrder = data.newIndex;
            }
          });
        });
        setTaskGroups(newState);
      } catch (e) {
        logErrorWithCallback(e, snackbarOn, 'Unable to update order');
      }
    } else {
      // DnD across groups
      const moveResult = move(
        taskGroups[sInd]?.tasks,
        taskGroups[dInd]?.tasks,
        source,
        destination
      );
      const newState = [...taskGroups];
      newState[sInd] = { ...taskGroups[sInd], tasks: moveResult[sInd] };
      newState[dInd] = { ...taskGroups[dInd], tasks: moveResult[dInd] };
      setTaskGroups(newState);

      const groupPayload = newState
        .filter(group => group.id)
        .map(group => ({
          id: group.id,
          name: group.name,
          sortOrder: group.sortOrder,
          taskIds: group.tasks.map(task => task.id)
        }));

      try {
        const response = await updateTaskGroup(groupPayload);
        updateTasksAndGroups({
          groups: response.quoteTransition.quoteTaskGroups.items,
          tasks: response.quoteTransition.quoteLineTasks.items,
          setTaskGroups
        });
      } catch (e) {
        logErrorWithCallback(e, snackbarOn, 'Unable to update order');
      }
    }
  }

  const NoData = () => {
    return (
      <Box
        className={classes.noData}
        justifyContent="center"
        alignItems="center"
        display="flex"
        width="100%"
        height="200px"
      >
        <Box m="auto">
          <ThemeProvider>
            <Typography variant={TV.XL} weight={TW.BOLD}>
              {LABELS.noDataMsg}
            </Typography>
            <Typography variant={TV.S1}>{LABELS.noTaskMsg}</Typography>
          </ThemeProvider>
        </Box>
      </Box>
    );
  };

  const handleCheckboxChange = (checkedProps, task) => {
    if (checkedProps) {
      setCheckedTasks([...checkedTasks, task.id]);
    } else {
      setCheckedTasks(checkedTasks.filter(elem => elem !== task.id));
    }
  };
  return (
    <>
      <Box component="div" className={classes.container}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Box justifyContent="center" style={{ display: 'flex', flexDirection: 'column' }}>
            <ThemeProvider>
              <Button
                disabled={isReadOnly}
                size="small"
                type="secondary"
                style={{ alignSelf: 'flex-end', marginBottom: 8 }}
                onClick={() => {
                  setCheckedTasks([]);
                  setIsTaskGroupModalOpen({ isOpen: true, type: Mode.ADD });
                }}
                startIcon={<AddCircleOutlineIcon />}
              >
                Add Task Group
              </Button>
            </ThemeProvider>
            {taskGroups.length > 0 ? (
              taskGroups.map((item, index) => {
                if (item.id) {
                  // if task group exists, render all tasks within the group
                  return (
                    <Droppable key={index} droppableId={`${index}`}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <div style={{ padding: '24px 0' }}>
                            <TaskGroup
                              quoteId={quoteInfo.id}
                              config={config}
                              updateTaskGroup={updateTaskGroup}
                              deleteTaskGroup={deleteTaskGroup}
                              item={item}
                              setIsTaskGroupModalOpen={setIsTaskGroupModalOpen}
                              setCheckedTasks={setCheckedTasks}
                              setTaskGroups={setTaskGroups}
                            />
                            {item.tasks.map((task, idx) => (
                              <RenderCard
                                key={task.id}
                                {...props}
                                groupIndex={index}
                                item={task}
                                index={idx}
                              />
                            ))}
                          </div>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  );
                }
                if (!item.id) {
                  // no task group id.  render remaining tasks without a task group
                  return (
                    <Droppable key={index} droppableId={`${index}`}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          {item.tasks.map((task, idx) => (
                            <RenderCard
                              key={task.id}
                              {...props}
                              groupIndex={index}
                              item={task}
                              index={idx}
                            />
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  );
                }
              })
            ) : (
              <NoData />
            )}

            {(quoteInfo.status === QuoteStatus.DRAFT ||
              quoteInfo.status === QuoteStatus.REJECTED) && (
              <NewCardButton
                addTaskService={addTaskService}
                config={config}
                isReadOnly={isReadOnly}
                setTaskGroups={setTaskGroups}
                taskGroups={taskGroups}
              />
            )}
          </Box>
        </DragDropContext>
        <SergeantModal
          data={isTaskGroupModalOpen.data}
          customComponents={{
            customLabel: () => <span style={{ color: '#666666ff' }}>QUOTE TASKS</span>
          }}
          customPrimaryButtonLabel={`${isTaskGroupModalOpen.type} Task Group`}
          dataType="Task Group"
          formVersion="default"
          handleClose={() => {
            setIsTaskGroupModalOpen({ isOpen: false, type: '', data: null });
          }}
          handlePrimaryAction={async (newData, doneLoading) => {
            const sortOrder = taskGroups.reduce(
              (acc, b) => (b.sortOrder > acc ? b.sortOrder : acc),
              0
            );
            try {
              const response =
                isTaskGroupModalOpen.type === Mode.ADD
                  ? await addTaskGroup({ ...newData, sortOrder: sortOrder + 1 }, checkedTasks)
                  : await updateTaskGroup(newData, checkedTasks);
              updateTasksAndGroups({
                groups: response.quoteTransition.quoteTaskGroups.items,
                tasks: response.quoteTransition.quoteLineTasks.items,
                setTaskGroups
              });
            } catch (error) {
              Logger.error(error);
            } finally {
              doneLoading();
              setIsTaskGroupModalOpen({ isOpen: false, type: '', data: null });
              setCheckedTasks([]);
            }
          }}
          layout={TasksGroupLayout(tasks, handleCheckboxChange, checkedTasks)}
          open={isTaskGroupModalOpen.isOpen}
          mode={isTaskGroupModalOpen.type}
        />
      </Box>
    </>
  );
}

function Tasks(props) {
  const {
    addQuoteLineProducts,
    taskList,
    customerPropertyId,
    addTaskService,
    assetList,
    user,
    config = {},
    quoteInfo,
    quoteLineProducts,
    deleteLineItem,
    updateTaxRateOnQuote,
    updateOverrideAmountOnQuote,
    isReadOnly
  } = props;
  const customerPropertyService = new CustomerPropertyService();
  const [selectedItems, setSelectedItems] = useState([]);
  const [refreshData, setRefreshData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const groupIndex = 0;
  const isAssetEnabled = getTenantSettingValueForKey('assetTrackingAgainstTask') === 'true';
  const tasks = sortBy(taskList, 'sortOrder');
  const preferredPricebookId =
    quoteInfo?.priceBookId || Context.getCompanyContext().getCompany?.defaultPriceBookId;
  const handleRowActions = (actionName, actionData) => {
    setSelectedItems(actionData);
  };
  const [taskGroups, setTaskGroups] = useState([]);
  const [tasksOnCustomerProperty, setTasksOnCustomerProperty] = useState([]);

  const fetchTasksOnCustomerProperty = async (filter, limit, offset, sortBy, sortOrder) => {
    if (!user.tenantId) {
      return { items: [], nextToken: '0' };
    }
    let data;
    try {
      data = await customerPropertyService.getTasksByCustomerPropertyById(
        customerPropertyId,
        filter,
        limit,
        offset,
        sortBy,
        sortOrder
      );
      setTasksOnCustomerProperty(
        data.items.filter(
          task => task.status === TaskConstants.OPEN || task.status === TaskConstants.PENDING
        )
      );
    } catch (error) {
      props.snackbarOn('error', 'Unable to fetch tasks, please try again later');
    }
  };

  React.useEffect(() => {
    fetchTasksOnCustomerProperty();
    updateTasksAndGroups({
      groups: quoteInfo.quoteTaskGroups?.items || [],
      tasks,
      setTaskGroups
    });
  }, []);

  React.useEffect(() => {
    updateTasksAndGroups({
      groups: quoteInfo.quoteTaskGroups?.items || [],
      tasks,
      setTaskGroups
    });
  }, [quoteInfo.versionNumber]);

  React.useEffect(() => {
    const fetchAssetsOnCustomerProperty = async propertyId => {
      let data;
      try {
        data = await customerPropertyService.getAssetsByCustomerPropertyById(propertyId);
        setAssets(data?.data?.getCustomerPropertyById?.propertyAssets?.items || []);
      } catch (error) {
        // Logger.error('error', 'Unable to fetch assets, please try again later');
      }
    };
    if (customerPropertyId && !assetList && isAssetEnabled) {
      fetchAssetsOnCustomerProperty(customerPropertyId);
    }
  }, [assetList, customerPropertyId, isAssetEnabled, user.tenantId]);

  const addTask = async () => {
    if (selectedItems.length) {
      setLoading(true);
      try {
        const formattedSelectedItems = selectedItems.map((item, idx) => ({
          name: item.name || '',
          description: item.description || '',
          taskId: item.id,
          unitPrice: item.unitPrice,
          unitCost: item.unitCost,
          taxable: item.taxable || false,
          markupValue: item.markupValue,
          quantity: item.quantity,
          sortOrder: tasks.length + idx,
          propertyAssetId: item.assetId,
          quoteLineProducts:
            item.products.map((product, i) => ({
              description: product.description || '',
              markupType: product.markupType || 'Percentage',
              markupValue: product.markupValue ?? product.priceBookEntry?.markupValue,
              name: product.name || '',
              productId: product.productId,
              quantity: product.quantity,
              sortOrder: i,
              taxable: product.taxable || false,
              unitCost: product.unitCost,
              unitPrice: product.unitPrice
            })) || []
        }));
        const addedTask = await addTaskService(formattedSelectedItems);
        if (addedTask) {
          updateTasksAndGroups({
            groups: addedTask.quoteTransition.quoteTaskGroups.items,
            tasks: addedTask.quoteTransition.quoteLineTasks.items,
            setTaskGroups
          });
          setSelectedItems([]);
          setRefreshData(refreshData + 1);
          props.snackbarOn('success', 'Successfully added task');
          fetchTasksOnCustomerProperty();
        }
      } catch (e) {
        props.snackbarOn('error', 'Unable to add tasks to quote', e);
      }
      setLoading(false);
    }
  };

  return (
    <ErrorBoundaries>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginRight: 1.5, marginBottom: 30, padding: 0 }}
      >
        <TasksDNDComponent
          {...props}
          key={quoteInfo.id}
          assetList={assetList || assets}
          tasks={tasks}
          groupIndex={groupIndex}
          preferredPricebookId={preferredPricebookId}
          fetchTasksOnCustomerProperty={fetchTasksOnCustomerProperty}
          taskGroups={taskGroups}
          setTaskGroups={setTaskGroups}
        />
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ margintop: 30, flex: '0 1 60%' }}
        >
          {!isReadOnly && config?.showTaskList && tasksOnCustomerProperty.length > 0 && (
            <Box>
              <ThemeProvider>
                <Typography style={{ padding: '8px 0' }} weight={TW.BOLD} variant={TV.L}>
                  Recommended Tasks For This Property
                </Typography>
              </ThemeProvider>
              <ResponsiveTable
                disableFilter
                refreshData={refreshData}
                rowMetadata={taskListMeta}
                data={tasksOnCustomerProperty}
                noDataMsg="No tasks added to property"
                rowActions={handleRowActions}
                rowActionButtons={{
                  select: {
                    referenceKey: 'id'
                  }
                }}
              />
              <Box display="flex" flexDirection="row-reverse">
                <ThemeProvider>
                  <Button
                    type="leading"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={addTask}
                    disabled={isReadOnly}
                  >
                    {loading ? `Adding to ${config.addToLabel}...` : `Add To ${config.addToLabel}`}
                  </Button>
                </ThemeProvider>
              </Box>
            </Box>
          )}
        </Grid>
        <Grid style={{ flex: '0 1 40%', marginBottom: 80, minWidth: 0 }}>
          <ReadonlyWrapper readOnly={isReadOnly} style={{ minWidth: 0 }}>
            <TotalsPanel
              addQuoteLineProducts={addQuoteLineProducts}
              updateTaxRateOnQuote={updateTaxRateOnQuote}
              priceBookId={preferredPricebookId}
              quoteLineProducts={quoteLineProducts}
              quoteInfo={quoteInfo}
              user={user}
              deleteLineItem={deleteLineItem}
              config={config}
              updateOverrideAmountOnQuote={updateOverrideAmountOnQuote}
              isReadOnly={isReadOnly}
            />
          </ReadonlyWrapper>
        </Grid>
      </Grid>
    </ErrorBoundaries>
  );
}

const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const connectedTasks = connect(mapStateToProps, mapDispatcherToProps)(Tasks);
export default withStyles({ withTheme: true })(connectedTasks);
