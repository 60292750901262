export const ProjectStatus = {
  BID: 'Bid',
  ACCEPTED: 'Accepted',
  WORK_IN_PROGRESS: 'Work in Progress',
  WORK_COMPLETE: 'Work Complete',
  CLOSED: 'Closed'
};

export const ChangeOrderStatus = {
  DRAFT: 'Draft',
  SENT: 'Sent for Approval',
  RESPONDED: 'Responded',
  APPROVED: 'Approved'
};

export const SubmittalsPackageStatus = {
  DRAFT: 'Draft',
  SENT: 'Sent',
  RESPONDED: 'Responded',
  APPROVED: 'Approved',
  APPROVED_AS_NOTED: 'Approved as Noted'
};

export const SubmittalsPackageStatusBtnTitles = [
  SubmittalsPackageStatus.DRAFT,
  SubmittalsPackageStatus.SENT,
  SubmittalsPackageStatus.RESPONDED,
  SubmittalsPackageStatus.APPROVED
];

export const SectionTypeMap = {
  WORK_COMPLETED: 'workCompleted',
  DELAYS_ISSUES: 'issues',
  OUT_OF_SCOPE_WORK: 'outOfScopeWork',
  SAFETY_CONCERNS: 'safetyConcerns',
  OTHER_OBSERVABLES: 'otherObservations',
  INTERNAL_NOTES: 'internalNotes'
};
