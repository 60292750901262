import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import MultiRowQuery from './MultiRowQuery';
import Table from '../Table';

class DatatableQuery extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const {
      tableProperties,
      query,
      variable,
      preProcessFunction,
      showRowButtons,
      rowActions,
      showTableHeader,
      showTableFooter,
      filterBy,
      caslKey,
      client
    } = this.props;
    return (
      <Grid container direction="row" spacing={3}>
        <Grid item lg={12} md={12} sm={12}>
          <MultiRowQuery
            query={query}
            client={client}
            variable={variable}
            preProcessFunction={preProcessFunction}
          >
            {this.mounted && (
              <Table
                captionAttributes={tableProperties.captionAttributes}
                idField={tableProperties.idField}
                dataPathInQueryResult={tableProperties.dataPathInQueryResult}
                showTableHeader={showTableHeader || false}
                showTableFooter={showTableFooter || false}
                showRowButtons={showRowButtons}
                rowActions={rowActions}
                noDataMsg={tableProperties.noDataMsg}
                filterBy={filterBy}
                caslKey={caslKey}
              />
            )}
          </MultiRowQuery>
        </Grid>
      </Grid>
    );
  }
}

export default DatatableQuery;
