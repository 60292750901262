import React, { useCallback, useEffect, useRef, useState, createContext, useContext } from 'react';

const scrollPixelBuffer = 600;

const LazyMountContext = createContext({ scrollContainerRef: null });

const selectShouldRender = ({ wrapperRect, scrollRect }) => {
  return (
    wrapperRect.top - scrollPixelBuffer - scrollRect.bottom < 0 &&
    wrapperRect.bottom + scrollPixelBuffer - scrollRect.top > 0
  );
};

const withLazyMount = Component => ({ mountBeforeScroll, ...props }) => {
  const wrapperRef = useRef();
  const [shouldRender, setShouldRender] = useState(mountBeforeScroll || false);
  const shouldRenderRef = useRef(false);
  const { scrollContainerRef } = useContext(LazyMountContext);

  const handleScroll = useCallback(() => {
    const wrapperRect = wrapperRef?.current?.getBoundingClientRect();
    const scrollRect = scrollContainerRef?.current?.getBoundingClientRect();

    if (!(wrapperRect && scrollRect)) return;

    const newShouldRender = selectShouldRender({ wrapperRect, scrollRect });

    if (newShouldRender !== shouldRenderRef.current) {
      shouldRenderRef.current = newShouldRender;
      setShouldRender(newShouldRender);
    }
  }, [scrollContainerRef]);

  useEffect(() => {
    if (scrollContainerRef?.current) {
      scrollContainerRef.current.addEventListener('scroll', handleScroll);
    }
    handleScroll();
  }, [handleScroll, scrollContainerRef]);

  const willRender = !scrollContainerRef || shouldRender;

  return (
    <div ref={wrapperRef} style={{ height: willRender ? undefined : 10 }}>
      {willRender && <Component {...props} />}
    </div>
  );
};

export const LazyMountScrollContainer = ({ children, className }) => {
  const scrollContainerRef = useRef();

  return (
    <div css={className} ref={scrollContainerRef}>
      <LazyMountContext.Provider value={{ scrollContainerRef }}>
        {children}
      </LazyMountContext.Provider>
    </div>
  );
};

export default withLazyMount;
