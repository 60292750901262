import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { CommonService, validations } from 'services/core';
import ErrorBoundaries from 'scenes/Error';
import AttachmentLayout from 'meta/attachment-layout';
import { snackbarOn } from 'redux/actions/globalActions';
import { PageForm } from '../../../../../index';

class Attachment extends Component {
  constructor(props) {
    super(props);
    this.CommonService = new CommonService();
  }

  render() {
    const AttachmentLayoutMeta = AttachmentLayout.entity.layouts.web;

    const { data, mode, caslKey } = this.props;
    let mutationFlag = false;
    const handleOnComplete = mutatedData => {
      const localMutatedData = mutatedData;
      localMutatedData.createdBy = this.props.user.displayName;
      localMutatedData.createdDateTime = moment().unix() * 1000;

      if (!localMutatedData.newRecordId) localMutatedData.newRecordId = this.props.nextRowId;
      this.props.addTo(localMutatedData);
      mutationFlag = true;
      this.props.handleClose(mutationFlag, localMutatedData);
      return data;
    };

    // TODO: to be handled in metadata
    if (mode === 'new') {
      AttachmentLayoutMeta.sections[0].title = AttachmentLayoutMeta.sections[0].title.replace(
        'Edit',
        'Add'
      );
    } else {
      AttachmentLayoutMeta.sections[0].title = AttachmentLayoutMeta.sections[0].title.replace(
        'Add',
        'Edit'
      );
    }

    return (
      <ErrorBoundaries>
        <PageForm
          layout={AttachmentLayoutMeta.sections}
          validateWithSchema={validations.attachmentSchema}
          data={data}
          mode={mode}
          mutationService={this.CommonService.mutateAttachment}
          showFooterButton
          buttons={AttachmentLayoutMeta.buttons}
          onComplete={handleOnComplete}
          entityName="Attachment"
          caslKey={caslKey}
          skipSave
        />
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapAttachmentToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedAddAttachment = connect(mapStateToProps, mapAttachmentToProps)(Attachment);

export default reduxConnectedAddAttachment;
