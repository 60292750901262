import gql from 'graphql-tag';

const addProductsToQuoteLineTask = gql`
  mutation addProductsToQuoteLineTask(
    $partitionKey: String!
    $data: AddProductsToQuoteLineTaskInput!
  ) {
    addProductsToQuoteLineTask(partitionKey: $partitionKey, data: $data) {
      id
      version
      name
      description
      unitPrice
      unitCost
      markupType
      markupValue
      quantity
      taxable
      productId
      priceBookEntryId
      parentId
    }
  }
`;

export default addProductsToQuoteLineTask;
