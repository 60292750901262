import moment from 'moment';
import memoize from 'fast-memoize';

import { DURATION_FORMAT, ONE_DAY_UNIX_DURATION, TIME_FORMAT } from './Dispatch.constants';

export const selectEndDay = memoize(
  (endDay, weekView, day) =>
    endDay ||
    (weekView
      ? moment(day)
          .add(7, 'days')
          .format('MM-DD-YYYY')
      : day)
);

export const selectRangeText = event => {
  if (!event.range) return '';
  const { day } = event;
  let startTime = event.range.start;
  let endTime = event.range.end;

  if (day) {
    startTime = event.range.start.isBefore(day.range.start) ? day.range.start : event.range.start;
    endTime = event.range.end.isAfter(day.range.end) ? day.range.end : event.range.end;
  }

  return `${startTime.format(TIME_FORMAT)} - ${endTime.format(TIME_FORMAT)}`;
};

export const selectDurationText = range => {
  const duration = moment.utc(range.diff());
  return duration.unix() === ONE_DAY_UNIX_DURATION ? '24:00' : duration.format(DURATION_FORMAT);
};
