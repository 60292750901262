/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { memo } from 'react';
import { LinearProgress } from '@material-ui/core';

import { HeaderProps } from '../WrapTable.types';
import HeaderColumn from '../HeaderColumn';

import { useHeaderStyles } from './Header.styles';
import { useHeaderColumns } from '../WrapTable.hooks';

const Header = ({
  columns,
  scrollX,
  scrollY,
  loading,
  noWrap,
  isFirstLoad,
  rowGroupingModel,
  rowGroupingColumnMode
}) => {
  const styles = useHeaderStyles({ scrollY });

  const rowColumns = useHeaderColumns({
    columns,
    rowGroupingModel,
    rowGroupingColumnMode
  });

  return (
    <div css={styles.stickyContainer}>
      <div css={styles.fullWidthContainer}>
        <div css={styles.header}>
          {rowColumns.map((colDef, index) => (
            <HeaderColumn
              header
              scrollX={scrollX}
              key={colDef.field}
              value={colDef.headerName}
              colDef={colDef}
              noWrap={noWrap}
              isFirstCol={index === 0}
              isLastCol={index === columns.length - 1}
            />
          ))}
        </div>
        {!isFirstLoad && loading && <LinearProgress css={styles.progressBar} />}
      </div>
    </div>
  );
};

Header.propTypes = HeaderProps;

export default memo(Header);
