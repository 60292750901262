import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CompanyService } from 'services/core';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Logger } from 'services/Logger';
import { snackbarOn } from 'redux/actions/globalActions';
import { PermissionConstants, EmployeeStatus, EmployeeAction } from 'utils/AppConstants';
import { checkPermission } from 'utils';
import Spinner from 'components/Spinners/CircularIndeterminate';

import styles from './styles';

/**
 * Renders Readonly inputs as per the build hero designs
 */

class EmployeeLoginInvite extends Component {
  constructor(props) {
    super(props);
    this.CompanyService = new CompanyService();
    this.state = {
      oldStatus: this.props.specialbehaviour.queryResult.employee.status,
      buttonLabel:
        this.props.specialbehaviour.queryResult.employee.status === EmployeeStatus.INVITED ||
        this.props.specialbehaviour.queryResult.employee.status === EmployeeStatus.INACTIVE
          ? 'Resend invitation'
          : 'Invite to Buildops',
      buttonClicked: false
    };
  }

  clickAction = async () => {
    // const employeeDetails = this.props.specialbehaviour.queryResult.employee || '';
    const companyInfo = this.props.specialbehaviour.queryResult.company || '';
    const employeeDetails = this.props.form.values;

    if (employeeDetails) {
      this.setState({ buttonClicked: true });
      try {
        if (employeeDetails.status === EmployeeStatus.CREATED) {
          const { data } = await this.CompanyService.inviteEmployee(
            employeeDetails,
            companyInfo,
            'invited'
          );
          if (data) {
            this.props.snackbarOn('success', `Successfully sent invitation`);
            this.props.specialbehaviour.queryResult.refetch();
          }
        } else if (employeeDetails.status === EmployeeStatus.INVITED) {
          const { data } = await this.CompanyService.saveEmployee({
            input: {
              id: employeeDetails.id,
              isAdmin: !!employeeDetails.isAdmin,
              email: employeeDetails.email,
              userName: employeeDetails.userName
            },
            action: EmployeeAction.RESEND_CONFIRMATION_EMAIL
          });
          if (data) {
            this.props.snackbarOn('success', `Successfully resent invitation`);
            this.props.specialbehaviour.queryResult.refetch();
            this.setState({ buttonClicked: false });
          }
        }
      } catch (error) {
        this.props.specialbehaviour.queryResult.employee.status = this.state.oldStatus;
        Logger.error(error);
        this.setState({ buttonClicked: false });
        const actionMsg =
          this.props.specialbehaviour.queryResult.employee.status === EmployeeStatus.INVITED
            ? 'resend invitation'
            : 'send invitation';
        this.props.snackbarOn('error', `Unable to ${actionMsg}, please try again later`);
      }
    }
  };

  render() {
    const { classes, user } = this.props;
    const employeeDetails = this.props.specialbehaviour.queryResult.employee;

    const hasPermission = checkPermission('update', [PermissionConstants.OBJECT_EMPLOYEE], user);

    if (
      (employeeDetails.status === EmployeeStatus.CREATED ||
        employeeDetails.status === EmployeeStatus.INVITED) &&
      employeeDetails.isActive &&
      hasPermission
    ) {
      return (
        <Button
          variant="outlined"
          color="secondary"
          // fullWidth={true}
          className={classes.inlineFormButton}
          onClick={this.clickAction}
          disabled={employeeDetails.isCrewMember || this.state.buttonClicked}
        >
          {this.state.buttonLabel}
          {this.state.buttonClicked && (
            <Spinner className={this.props.classes.buttonProgress} size={18} />
          )}
        </Button>
      );
    }
    return null;
  }
}
const styledBuildopInviteButton = withStyles(styles)(EmployeeLoginInvite);

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const connectedBuildopsInviteButtton = connect(null, mapDispatchToProps)(styledBuildopInviteButton);

export default connectedBuildopsInviteButtton;
