import React from 'react';
import { Card, Grid } from '@material-ui/core';
import {
  Button,
  Divider,
  Field,
  FieldType,
  ThemeProvider,
  Typography,
  TV,
  TW,
  theme,
  InlineAlert,
  InlineAlertTypes
} from '@buildhero/sergeant';
import WarningIcon from '@material-ui/icons/Warning';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Spinner } from 'components';
import moment from 'moment';
import AppConstants from 'utils/AppConstants';

export const CustomPDFComponent = ({ formData, downloadFile, data }) => {
  return (
    <>
      {!formData?.options?.showSpinner && (
        <Card
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            boxShadow: 'none',
            marginBottom: 10
          }}
        >
          <ThemeProvider>
            <Button
              type="leading"
              onClick={() => downloadFile(formData?.options?.pdfKey)}
              startIcon={
                <InsertDriveFileIcon style={{ color: theme.default.palette.support.green.dark }} />
              }
              style={{ background: '#e6e6e6ff' }}
            >
              <Typography variant={TV.S1} weight={TW.MEDIUM}>
                {data?.pdfFileName}
              </Typography>
            </Button>
            <Button
              type="leading"
              size="small"
              style={{
                background: 'transparent',
                textDecoration: 'underline',
                paddingRight: 12
              }}
              onClick={() => downloadFile(formData?.options?.pdfKey)}
            >
              View
            </Button>
          </ThemeProvider>
        </Card>
      )}
      {formData?.options?.showSpinner && <Spinner />}
    </>
  );
};

export const ExpirationDateComponent = ({ formData, daysUntilExpiration }) => {
  const expirationDate = moment(new Date()).add(formData?.options?.daysUntilExpiration, 'days');
  return (
    <ThemeProvider>
      <Grid
        container
        direction="column"
        alignItems="baseline"
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        <Field label="*Days until expiration" type={FieldType.TEXT} value={daysUntilExpiration} />
        <Typography style={{ marginTop: 12 }}>
          {`Expires on: ${moment(expirationDate).format(AppConstants.DATE_FORMAT)}`}
        </Typography>
      </Grid>
    </ThemeProvider>
  );
};

export const AttachmentThumbnailsComponent = ({ formData, downloadFile }) => (
  <>
    {formData?.options?.selectedAttachments.length > 0 && <Divider />}
    {formData?.options?.selectedAttachments.map(elem => (
      <>
        <Card
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            boxShadow: 'none',
            marginBottom: 10
          }}
        >
          <ThemeProvider>
            <Button
              type="leading"
              onClick={() => downloadFile(elem.imageKey)}
              startIcon={
                <InsertDriveFileIcon style={{ color: theme.default.palette.support.green.dark }} />
              }
              style={{ background: '#e6e6e6ff' }}
            >
              <Typography variant={TV.S1} weight={TW.MEDIUM}>
                {elem.fileName}
              </Typography>
            </Button>
            <Button
              type="leading"
              size="small"
              style={{
                background: 'transparent',
                textDecoration: 'underline',
                paddingRight: 12
              }}
              onClick={() => downloadFile(elem.imageKey)}
            >
              View
            </Button>
          </ThemeProvider>
        </Card>
      </>
    ))}
  </>
);

export const AttachmentSizeWarningComponent = ({
  formData,
  maxAttachmentSizeWarningLabel,
  maxAttachmentSize,
  generatedPDFSize
}) => {
  // set initial size to size of generated PDF with 500kb error margin
  const initialSize = (generatedPDFSize || 0) + 500000;
  const totalAttachmentSize = formData?.options?.selectedAttachments.reduce(
    (acc, curr) => curr.fileSize + acc,
    initialSize
  );
  return (
    <>
      {totalAttachmentSize >= maxAttachmentSize && (
        <InlineAlert Icon={WarningIcon} type={InlineAlertTypes.YELLOW}>
          {maxAttachmentSizeWarningLabel}
        </InlineAlert>
      )}
    </>
  );
};
