import React from 'react';
import ReportTable from '../../../ReportTable';
import { selectTechnicianNotes } from '../../../../selectors';
import { ReportTableSectionDefaultProps, ReportTableSectionPropTypes } from '../../../../propTypes';
import { notesColumns } from '../../../../meta/notesColumns';

const TechnicianReportNotesTable = ({ visit, loading, error }) => {
  const title = 'Technician Notes (Internal Use)';
  const noDataMessage = 'No Technician Notes';
  const rows = selectTechnicianNotes(visit) || [];
  return (
    <ReportTable
      title={title}
      columns={notesColumns}
      rows={rows}
      loading={loading}
      error={error}
      noDataMessage={noDataMessage}
    />
  );
};

TechnicianReportNotesTable.propTypes = ReportTableSectionPropTypes;
TechnicianReportNotesTable.defaultProps = ReportTableSectionDefaultProps;

export default TechnicianReportNotesTable;
