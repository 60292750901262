export const useStyles = () => ({
  formRoot: {
    width: '100%',
    '& [class*="MuiInputBase-input"]': {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: '-0.02em',
      paddingLeft: 0,
      paddingRight: 0
    },
    '& [class*="MuiInputBase-root"]:before, [class*="MuiInputBase-root"]:after': {
      display: 'none'
    },
    '& [class*="RawSelectInput-disabled"]': {
      backgroundColor: 'unset'
    },
    '& [class*="MuiInputBase-root"]': {
      borderRadius: 2,
      border: '1px solid #999999',
      paddingLeft: 8,
      paddingRight: 8,
      height: 36,
      boxSizing: 'border-box'
    }
  },
  title: theme => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  })
});
