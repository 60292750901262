/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getRFIs(projectId, noInclude) {
  return await instance
    .get(`rfis/byproject/${projectId}`, {
      params: { include: noInclude ? [] : ['sender', 'recipient'] }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getRFIById(rfiId, noInclude) {
  return await instance
    .get(`rfis/${rfiId}`, {
      params: {
        include: noInclude
          ? []
          : [
              'sender',
              'senderCompany',
              'recipient',
              'recipientCompany',
              'AuditLogEntry',
              'RfiAttachment'
            ]
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return {};
    });
}

export async function getNextNumber(projectId) {
  let paramsClause = {};
  if (projectId) {
    paramsClause = { projectId };
  }
  return await instance
    .get('rfis/next/number', {
      params: paramsClause
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return {};
    });
}

export async function rfiCreate(rfi) {
  return await instance
    .post('rfis', rfi)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function rfiChange(id, rfi) {
  return await instance
    .put(`rfis/${id}`, rfi)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function rfiDelete(id) {
  return await instance
    .delete(`rfis/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
