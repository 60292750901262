import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/client';

export const getPricebookEntryByProductSortKey = gql`
  query($pricebookId: String!, $productSortKey: String!) {
    getPricebookEntryByProductSortKey(pricebookId: $pricebookId, productSortKey: $productSortKey) {
      id
      productSortKey
      unitPrice
      markupValue
      markupType
      materialMarkup
      costCodeId
      revenueTypeId
      jobCostTypeId
    }
  }
`;

export const useLazyFetchPricebookEntry = () => {
  const client = useApolloClient();
  return useCallback(
    async (variables, skip) => {
      const res = await client.query({
        query: getPricebookEntryByProductSortKey,
        variables,
        skip
      });
      return res?.data?.getPricebookEntryByProductSortKey;
    },
    [client]
  );
};
