import DELETE_SUMMARY_NOTE from 'services/core/graphql/review-report/mutations/DeleteSummary';
import useExtendedMutation from 'customHooks/useExtendedMutation';
import { GET_REVIEW_REPORT_BY_ID } from '../queries';

const serializer = ({ tenantId, summaryId }) => ({
  variables: {
    partitionKey: tenantId,
    id: summaryId
  }
});

export const useDeleteReviewReportSummaryNote = reviewReportId => {
  return useExtendedMutation(DELETE_SUMMARY_NOTE, {
    serializer,
    showConfirmation: () => true,
    confirmationModalOptions: {
      title: 'Delete Summary Note',
      warn: true,
      modalText: 'Are you sure you want to delete this visit summary?'
    },
    update: (cache, { data: { deleteSummary } }) => {
      const cachedData = cache.readQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        }
      });
      cache.writeQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        },
        data: {
          getReviewReportById: {
            ...cachedData.getReviewReportById,
            summaries: {
              __typename: 'SummaryConnection',
              items:
                cachedData.getReviewReportById?.summaries?.items?.filter(
                  summary => summary?.id !== deleteSummary?.id
                ) || []
            }
          }
        }
      });
    }
  });
};
