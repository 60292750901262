import React from 'react';
import { Checkbox } from '@buildhero/sergeant';

export const checkboxColumn = ({
  disabled,
  onRowToggle,
  onHeaderToggle,
  isRowChecked,
  isHeaderChecked
}) => ({
  field: 'action',
  flex: 1,
  width: 41,
  maxWidth: 41,
  noPadding: true,
  align: 'center',
  justify: 'center',
  noHeaderPadding: true,
  headerAlign: 'center',
  headerJustify: 'center',
  renderHeader: () => (
    <div style={{ align: 'center' }}>
      <Checkbox
        css={{ marginTop: 0 }}
        checked={isHeaderChecked()}
        onChange={onHeaderToggle}
        disabled={disabled}
      />
    </div>
  ),
  // eslint-disable-next-line react/prop-types
  renderCell: ({ row }) => (
    <Checkbox
      css={{ marginTop: 0 }}
      // eslint-disable-next-line react/prop-types
      checked={isRowChecked(row)}
      onChange={onRowToggle(row)}
      disabled={disabled}
    />
  )
});
