const styles = theme => {
  return {
    activeStatusChip: {
      backgroundColor: theme.palette.brand.green,
      color: theme.palette.grayscale(100),
      marginLeft: theme.spacing(0.5),
      borderRadius: 2,
      height: theme.spacing(3),
      marginRight: theme.spacing(2)
    },
    deactivatedStatusChip: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.grayscale(100),
      marginLeft: theme.spacing(0.5),
      borderRadius: 2,
      height: theme.spacing(3),
      marginRight: theme.spacing(2)
    }
  };
};

export default styles;
