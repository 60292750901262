import { object, string } from 'yup';
import { tenantTypeOptions } from '../Tenant/Tenant.config';

const rowStyle = {
  gap: 16,
  flexDirection: 'row',
  marginBottom: 16,
  flexWrap: 'wrap'
};

const colStyle = {
  width: 250
};

export default {
  layouts: {
    default: {
      props: {},
      contents: [
        {
          props: { style: rowStyle },
          contents: [
            {
              component: 'Input',
              source: 'firstName',
              props: {
                style: colStyle,
                label: 'First Name'
              }
            },
            {
              component: 'Input',
              source: 'lastName',
              props: {
                style: colStyle,
                label: 'Last Name'
              }
            }
          ]
        },
        {
          props: { style: rowStyle },
          contents: [
            {
              component: 'Input',
              source: 'companyName',
              props: {
                style: colStyle,
                label: 'Company Name',
                required: true
              }
            },
            {
              component: 'Input',
              source: 'accountName',
              props: {
                style: colStyle,
                label: 'Account Name'
              }
            }
          ]
        },
        {
          props: { style: rowStyle },
          contents: [
            {
              component: 'Input',
              source: 'userName',
              props: {
                style: colStyle,
                label: 'Username',
                required: true
              }
            },
            {
              component: 'Input',
              source: 'email',
              props: {
                style: colStyle,
                label: 'Email Address',
                required: true
              }
            },
            {
              component: 'Select',
              source: 'type',
              props: {
                style: colStyle,
                label: 'Type',
                options: tenantTypeOptions
              }
            },
            {
              component: 'Input',
              source: 'salesforceId',
              props: {
                style: colStyle,
                label: 'Salesforce ID'
              }
            }
          ]
        },
        {
          props: { style: rowStyle },
          contents: [
            {
              component: 'Input',
              source: 'tier',
              props: {
                style: colStyle,
                label: 'Tier'
              }
            },
            {
              component: 'Input',
              source: 'status',
              props: {
                style: colStyle,
                label: 'Status'
              }
            },
            {
              component: 'Input',
              source: 'ownerName',
              props: {
                style: colStyle,
                label: 'Owner Name'
              }
            }
          ]
        }
      ]
    }
  },
  validation: object({
    firstName: string().nullable(),
    lastName: string().nullable(),
    companyName: string()
      .nullable()
      .required('Company Name is required.'),
    accountName: string().nullable(),
    userName: string()
      .nullable()
      .required('Username is required.'),
    email: string()
      .nullable()
      .required('Email is required.'),
    tier: string().nullable(),
    status: string().nullable(),
    ownerName: string().nullable()
  })
};
