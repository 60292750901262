import { EnumType } from 'utils/constants';

export const serviceAgreementRowsMeta = [
  {
    id: 'agreementNumber',
    label: 'Agreement #',
    showLink: 'view',
    linkPath: '/serviceAgreement/view',
    linkReference: 'id'
  },
  {
    id: 'agreementName',
    label: 'Agreement Name',
    showLink: 'view',
    linkPath: '/serviceAgreement/view',
    linkReference: 'id'
  },
  {
    id: 'termPrice',
    label: 'Term Price',
    disableSort: true
  },
  {
    id: 'startDate',
    type: 'dateOnly',
    label: 'Start Date',
    disableSort: true
  },
  {
    id: 'endDate',
    type: 'dateOnly',
    label: 'End Date',
    disableSort: true
  },
  {
    id: 'paymentTerms',
    label: 'Payment Schedule',
    disableSort: true
  },
  {
    id: 'nextBillDate',
    type: 'dateOnly',
    label: 'Next Billable Date',
    disableSort: true
  },
  {
    id: 'status',
    label: 'Status',
    enumType: EnumType.SERVICE_AGREEMENT_STATUS
  }
];

export default serviceAgreementRowsMeta;
