import React, { useState } from 'react';

import { Button, Modal, ThemeProvider } from '@buildhero/sergeant';

import AttachmentsForJob from '../AttachmentsForJob';

const NewJobModal = ({
  classes,
  handleAddToJob,
  isCustomJobNumberEnabled,
  loading,
  openNewJobModal,
  quoteAttachments,
  setOpenNewJobModal
}) => {
  const [formService, setFormService] = useState();

  return (
    <ThemeProvider>
      <Modal
        title="Add Quote to New Job"
        open={openNewJobModal}
        onClose={() => setOpenNewJobModal(false)}
        PaperProps={{
          style: { minWidth: 454, minHeight: 375, overflowY: 'initial' },
          className: classes.existingJobModal
        }}
        actions={
          <Button
            fullWidth
            loading={loading}
            style={{ marginTop: '1rem' }}
            onClick={formService?.formikContext.handleSubmit}
          >
            Continue
          </Button>
        }
      >
        <AttachmentsForJob
          isCustomJobNumberEnabled={isCustomJobNumberEnabled}
          quoteAttachments={quoteAttachments}
          setFormService={setFormService}
          handleSubmit={handleAddToJob}
        />
      </Modal>
    </ThemeProvider>
  );
};

export default NewJobModal;
