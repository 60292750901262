const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(2)
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  noOptionsMessage: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    color: theme.palette.grayscale(40)
  },
  muiFilledInput: {
    backgroundColor: theme.palette.grayscale(95),
    borderRadius: 4
  },

  inputRoot: {
    flexWrap: 'wrap',
    color: theme.palette.grayscale(40)
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
    backgroundColor: theme.palette.grayscale(95),
    borderRadius: 4,
    paddingTop: 20,
    color: theme.palette.grayscale(40)
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: '#3f3f3f'
  },
  input: {
    display: 'flex',
    padding: '4px 10px 5px',
    height: 'auto'
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  multiValueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  singleValue: {
    fontSize: 16,
    borderRadius: 4,
    font: 'inherit',
    color: 'currentColor',
    whiteSpace: 'nowrap'
  },
  clearIndicator: {
    padding: '2px'
  },
  dropdownIndicator: {
    padding: '2px'
  },
  inputLabel: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'normal',
    letterSpacing: '0.01px',
    lineHeight: '14px'
  },
  requiredLabel: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: '8px',
    letterSpacing: 0,
    lineHeight: '14px'
  },
  errorStyle: {
    marginLeft: 0
  }
});

export default styles;
