import gql from 'graphql-tag';

const addSummariesToReviewReport = gql`
  mutation addSummariesToReviewReport(
    $partitionKey: String
    $data: AddSummariesToReviewReportInput!
  ) {
    addSummariesToReviewReport(partitionKey: $partitionKey, data: $data) {
      id
      sortKey
      summary
      version
      includeInInvoice
      createdDateTime
      lastUpdatedBy
      lastUpdatedDateTime
    }
  }
`;

export default addSummariesToReviewReport;
