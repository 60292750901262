export const addInvoiceToPaymentRowsMeta = [
  {
    id: 'paymentNumber',
    filterKey: 'Payment.paymentNumber',
    filterType: 'number',
    label: 'Payment',
    showLink: 'view',
    linkPath: '/payments/view',
    linkReference: 'id',
    linkStateKey: 'recordSortKey',
    linkStateValue: 'sortKey',
    bold: true
  },
  {
    id: 'paymentTypeName',
    filterKey: 'Payment.paymentType',
    filterType: 'string',
    label: 'Type'
  },
  {
    id: 'paymentStatus',
    filterKey: 'Payment.paymentStatus',
    filterType: 'string',
    type: 'enum',
    label: 'Status'
  },
  {
    id: 'paymentDate',
    disableFilter: true,
    filterKey: 'Payment.paymentDate',
    filterType: 'date',
    type: 'dateOnly',
    label: 'Date'
  },
  {
    id: 'paymentAmount',
    filterKey: 'Payment.paymentAmount',
    filterType: 'currency',
    type: 'currency',
    bold: true,
    label: 'Total Amount'
  },
  {
    id: 'availableAmount',
    filterKey: 'Payment.availableAmount',
    filterType: 'currency',
    type: 'currency',
    bold: true,
    label: 'Available Amount'
  },
  {
    id: 'amountToApply',
    disableFilter: true,
    disableSort: true,
    isCustom: true,
    type: 'applyPaymentToInvoice',
    label: 'Amount to Apply',
    headerStyle: {
      width: '20%',
      minWidth: 240
    }
  }
];

const ApplyPaymentForm = {
  fields: {
    invoiceBalance: {
      default: 'invoiceBalance',
      edit: 'invoiceBalance'
    },
    payments: {
      default: 'payments',
      edit: 'payments'
    }
  },
  layouts: {
    edit: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        // Amount
        {
          options: {
            size: 4,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'invoiceBalance',
                  options: {
                    label: 'Outstanding Balance',
                    type: 'currency',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Payments
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'PaymentApplicationTable'
                  },
                  source: 'payments',
                  options: {
                    label: 'Payments'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  }
};

export default ApplyPaymentForm;
