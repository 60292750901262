import gql from 'graphql-tag';

const addPricebookToCompany_new = gql`
  mutation addPriceBook_new($partitionKey: String, $data: AddPriceBookInput!) {
    addPriceBook_new(partitionKey: $partitionKey, data: $data) {
      id
      entityType
      version
    }
  }
`;

export default addPricebookToCompany_new;
