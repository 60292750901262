import { FieldType } from '@buildhero/sergeant';

const JobCloseoutInformationLayout = {
  fields: {},
  layouts: {
    view: {
      options: {
        height: 'unset'
      },
      contents: [
        {
          props: {
            flexDirection: 'row',
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: 2
          },
          contents: [
            {
              props: {
                marginRight: 3,
                flexDirection: 'column',
                flex: 1
              },
              contents: [
                {
                  component: 'Field',
                  source: 'customer',
                  props: {
                    label: 'Customer',
                    type: FieldType.LINK,
                    style: { marginBottom: 16 },
                    innerStyle: { marginTop: 0 }
                  }
                },
                {
                  component: 'Field',
                  source: 'billTo',
                  props: {
                    label: 'Bill To',
                    type: FieldType.TEXT,
                    innerStyle: { marginTop: 0 }
                  }
                }
              ]
            },
            {
              props: {
                marginRight: 3,
                flexDirection: 'column',
                flex: 1
              },
              contents: [
                {
                  component: 'Field',
                  source: 'property',
                  props: {
                    label: 'Property',
                    type: FieldType.LINK,
                    style: { marginBottom: 16 },
                    innerStyle: { marginTop: 0 }
                  }
                },
                {
                  component: 'Field',
                  source: 'propertyAddress',
                  props: {
                    label: 'Property Address',
                    type: FieldType.TEXT,
                    innerStyle: { marginTop: 0 }
                  }
                }
              ]
            },
            {
              props: {
                marginRight: 3,
                flexDirection: 'column',
                flex: 1
              },
              contents: [
                {
                  component: 'Field',
                  source: 'billingCustomer',
                  props: {
                    label: 'Billing Customer',
                    type: FieldType.LINK,
                    style: { marginBottom: 16 },
                    innerStyle: { marginTop: 0 }
                  }
                },
                {
                  component: 'Field',
                  source: 'billingAddress',
                  props: {
                    label: 'Billing Address',
                    type: FieldType.TEXT,
                    innerStyle: { marginTop: 0 }
                  }
                }
              ]
            },
            {
              props: {
                marginRight: 3,
                flexDirection: 'column',
                flex: 1
              },
              contents: [
                {
                  component: 'Field',
                  source: 'departments',
                  props: {
                    label: 'Departments',
                    type: FieldType.TEXT,
                    style: { marginBottom: 16 },
                    innerStyle: { marginTop: 0 }
                  }
                },
                {
                  component: 'Field',
                  source: 'projectManager',
                  props: {
                    label: 'Project Manager',
                    type: FieldType.TEXT,
                    innerStyle: { marginTop: 0 }
                  }
                }
              ]
            },
            {
              props: {
                marginRight: 3,
                flexDirection: 'column',
                flex: 1
              },
              contents: [
                {
                  component: 'Field',
                  source: 'jobType',
                  props: {
                    label: 'Job Type',
                    type: FieldType.TEXT,
                    style: { marginBottom: 16 },
                    innerStyle: { marginTop: 0 }
                  }
                },
                {
                  component: 'Field',
                  source: 'billingType',
                  props: {
                    label: 'Billing Type',
                    type: FieldType.TEXT,
                    innerStyle: { marginTop: 0 }
                  }
                }
              ]
            },
            {
              props: { flexDirection: 'column', flex: 1 },
              contents: [
                {
                  component: 'Field',
                  source: 'customerWONumber',
                  props: {
                    label: 'Customer WO Number',
                    type: FieldType.TEXT,
                    style: { marginBottom: 16 },
                    innerStyle: { marginTop: 0 }
                  }
                },
                {
                  component: 'Field',
                  source: 'customerPONumber',
                  props: {
                    label: 'Customer PO Number',
                    type: FieldType.TEXT,
                    innerStyle: { marginTop: 0 }
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  }
};

export default JobCloseoutInformationLayout;
