/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback } from 'react';
import { Divider, IconButton, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { bool, shape, string } from 'prop-types';
import { useStyles } from './AccordionSection.styles';

const defaultOptions = {
  expanded: false,
  heading: 'options.heading',
  bottomBorder: true,
  topBorder: false
};

const AccordionSection = props => {
  const options = { ...defaultOptions, ...props.options };
  const [expanded, setExpanded] = useState(options.expanded);
  const childCount = React.Children.count(props.children);
  const classes = useStyles({ expanded, maxHeight: options.maxHeight || childCount * 120 });

  const handleHeaderClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  return (
    <div className={options.className}>
      {options.topBorder && <Divider classes={{ root: classes.divider }} />}
      <div
        role="button"
        tabIndex={0}
        className={classes.headingContainer}
        onClick={handleHeaderClick}
      >
        <Typography className={classes.header} variant="h4">
          {options.heading}
        </Typography>
        <IconButton className={classes.iconButton}>
          {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </div>
      <div className={classes.contents}>{props.children}</div>
      {options.bottomBorder && <Divider classes={{ root: classes.divider }} />}
    </div>
  );
};

AccordionSection.propTypes = {
  options: shape({
    expanded: bool,
    heading: string,
    bottomBorder: bool,
    topBorder: bool
  })
};

AccordionSection.defaultProps = {
  options: defaultOptions
};

export default AccordionSection;
