import React from 'react';
import { func, node, string } from 'prop-types';
import isFunction from 'lodash/isFunction';
import { Typography, TV, TypographyWeight, ThemeProvider } from '@buildhero/sergeant';
import { useStyles } from './ReportSection.styles';

const ReportSection = ({ title, renderTitle, children }) => {
  const styles = useStyles();
  return (
    <ThemeProvider>
      <div css={styles.container}>
        {isFunction(renderTitle) ? (
          renderTitle()
        ) : (
          <Typography variant={TV.L} weight={TypographyWeight.BOLD} style={{ marginBottom: 8 }}>
            {title}
          </Typography>
        )}
        <div>{children}</div>
      </div>
    </ThemeProvider>
  );
};

ReportSection.propTypes = {
  title: string,
  renderTitle: func,
  children: node.isRequired
};

ReportSection.defaultProps = {
  title: '',
  renderTitle: undefined
};

export default ReportSection;
