import gql from 'graphql-tag';

const updateInvoice = gql`
  mutation updateInvoice($partitionKey: String, $data: UpdateInvoiceInput!) {
    updateInvoice(partitionKey: $partitionKey, data: $data) {
      id
      partitionKey
      tenantId
      entityType
      version
      status
      syncStatus
    }
  }
`;

export default updateInvoice;
