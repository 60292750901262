import React from 'react';
import { Switch, ThemeProvider } from '@buildhero/sergeant';

const CreateMultipleVisitsSwitch = record => {
  return (
    <ThemeProvider>
      <Switch
        label="Create Multiple Visits"
        horizontalLabel
        disabled={record.options?.disabled}
        checked={record.options?.checked}
        onChange={record.options?.onChange}
      />
    </ThemeProvider>
  );
};

export default CreateMultipleVisitsSwitch;
