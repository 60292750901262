import gql from 'graphql-tag';
import useExtendedMutation from 'customHooks/useExtendedMutation';

const UPDATE_VISIT_SUMMARY = gql`
  mutation updateSummary($partitionKey: String, $data: UpdateSummaryInput!) {
    updateSummary(partitionKey: $partitionKey, data: $data) {
      id
      summary
      includeInInvoice
      lastUpdatedBy
      lastUpdatedDateTime
      version
    }
  }
`;

const serializer = ({ tenantId, summaryId, version, includeInInvoice, summary }) => ({
  variables: {
    partitionKey: tenantId,
    data: {
      id: summaryId,
      version,
      includeInInvoice,
      summary
    }
  }
});

const optimisticResponseFactory = ({ summaryId, version, includeInInvoice, summary }) => ({
  updateSummary: {
    __typename: 'Summary',
    id: summaryId,
    version: version + 1,
    includeInInvoice,
    summary
  }
});

export const useUpdateVisitSummary = () => {
  return useExtendedMutation(UPDATE_VISIT_SUMMARY, {
    serializer,
    optimisticResponseFactory
  });
};
