export const PurchaseOrderTypeColumns = [
  {
    id: 'name',
    maxTextLen: 30,
    label: 'Name',
    filterKey: 'PurchaseOrderType.name',
    filterType: 'string'
  },
  {
    id: 'departmentNames',
    numeric: false,
    type: 'setDepartmentList',
    isCustom: true,
    label: 'Department',
    filterKey: 'PurchaseOrderType.departmentNames',
    filterType: 'string'
  },
  {
    id: 'autoReceive',
    label: 'Auto-Receive',
    type: 'setAutoReceive',
    isCustom: true,
    filterKey: 'PurchaseOrderType.autoReceive',
    filterType: 'string'
  }
];

export const PurchaseOrderTypeLayout = departmentOptions => {
  return {
    fields: {
      name: {
        default: 'name',
        edit: 'name'
      },
      departmentIds: {
        default: 'departmentIds',
        edit: 'departmentIds'
      },
      type: {
        default: 'isAutoReceive',
        edit: 'isAutoReceive'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      default: 'FieldWithLabel',
                      edit: 'TextInput'
                    },
                    source: 'name',
                    options: {
                      label: 'Name',
                      placeholder: '',
                      isRequired: true
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      default: 'SelectInputView',
                      edit: 'SelectInput'
                    },
                    source: 'departmentIds',
                    options: {
                      label: 'Department',
                      placeholder: '',
                      isRequired: false,
                      valueSet: departmentOptions,
                      isMultipleSelection: true
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              direction: 'column',
              grow: 1,
              padding: 12
            },
            contents: [
              {
                component: {
                  edit: 'CheckboxInput',
                  default: 'CheckboxInput'
                },
                source: 'autoReceive',
                options: {
                  label: 'Auto-Receive',
                  checked: false,
                  isRequired: false,
                  fullWidth: true,
                  alignment: 'left'
                }
              },
              {
                component: 'BodyText',
                source:
                  'With Auto-Receive checked, Receipts will be automatically generated with all Purchase Order Items fulfilled'
              }
            ]
          }
        ]
      }
    },
    validation: {
      type: 'object',
      properties: {
        name: {
          type: 'string'
        },
        departmentIds: {
          type: 'string'
        },
        autoReceive: {
          type: 'string'
        }
      },
      required: ['name']
    },
    validationErrors: {
      name: {
        required: 'Name is required.'
      }
    }
  };
};

export default PurchaseOrderTypeLayout;
