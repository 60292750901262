import gql from 'graphql-tag';

const getDepartmentWithSkillEmployees = gql`
  query getDepartment($partitionKey: String!, $sortKey: String!) {
    getDepartment(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      tagName
      accountingRefIdOfClass
      version
      skills(entityConnection: "Skill") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          invertedSortKey
          hierarchy
          entityType
          version
          mappedEntity {
            ... on Skill {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              tagType
              tagName
              contacts(entityConnection: "Employee") {
                items {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  invertedSortKey
                  hierarchy
                  entityType
                  mappedEntity {
                    ... on Employee {
                      id
                      tenantId
                      tenantCompanyId
                      partitionKey
                      sortKey
                      hierarchy
                      entityType
                      name
                      firstName
                      lastName
                      nickName
                      profilePictureUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
      contacts(entityConnection: "Employee") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          invertedSortKey
          hierarchy
          entityType
          version
          mappedEntity {
            ... on Employee {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              name
              firstName
              lastName
              nickName
              profilePictureUrl
            }
          }
        }
      }
    }
  }
`;

export default getDepartmentWithSkillEmployees;
