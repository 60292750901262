import theme from 'themes/BuildHeroTheme';

export const dispatchSettingLayout = () => ({
  fields: {},
  layouts: {
    default: {
      options: {
        grow: 0
      },
      contents: [
        {
          options: {
            direction: 'column',
            grow: 1,
            padding: [12, 0]
          },
          contents: [
            {
              options: {
                color: theme.palette.secondary.main,
                fontWeight: 'bold',
                fontSize: 14
              },
              component: 'BodyText',
              source: 'Week View'
            }
          ]
        },
        {
          options: {
            size: 2,
            direction: 'row',
            paddingBottom: 50,
            borderBottom: `1px solid ${theme.palette.grayscale(80)}`
          },
          contents: [
            {
              options: {
                direction: 'column',
                padding: [4, 0]
              },
              contents: [
                {
                  options: {
                    direction: 'row'
                  },
                  contents: [
                    {
                      options: {
                        fontSize: 20,
                        marginTop: 20,
                        marginRight: 10
                      },
                      component: 'BodyText',
                      source: 'The week view would start on a'
                    },
                    {
                      options: {
                        direction: 'row',
                        flex: '0 1 150px'
                      },
                      contents: [
                        {
                          component: 'SelectInput',
                          source: 'weekViewStartDate',
                          options: {
                            isRequired: true,
                            isSearchable: true,
                            enableSort: false,
                            isClearable: false,
                            valueSet: [
                              { label: 'Monday', value: 'MONDAY' },
                              { label: 'Tuesday', value: 'TUESDAY' },
                              { label: 'Wednesday', value: 'WEDNESDAY' },
                              { label: 'Thursday', value: 'THURSDAY' },
                              { label: 'Friday', value: 'FRIDAY' },
                              { label: 'Saturday', value: 'SATURDAY' },
                              { label: 'Sunday', value: 'SUNDAY' }
                            ]
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  }
});
