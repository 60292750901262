import gql from 'graphql-tag';

const addProductBundlesToCompany = gql`
  mutation addProductBundlesToCompany(
    $partitionKey: String!
    $data: AddProductBundlesToCompanyInput!
  ) {
    addProductBundlesToCompany(partitionKey: $partitionKey, data: $data) {
      id
      name
      description
      category
      subCategory
      type
      isActive
      version
    }
  }
`;

export default addProductBundlesToCompany;
