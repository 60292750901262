import theme from 'themes/BuildHeroTheme';

const statusChips = {
  inactive: {
    name: {
      en: 'Inactive'
    },
    color: 'default'
  },
  active: {
    name: {
      en: 'Active'
    },
    color: 'default'
  },
  pending: {
    name: {
      en: 'Pending'
    },
    color: 'default'
  },
  posted: {
    name: {
      en: 'Posted'
    },
    color: 'primary'
  },
  exported: {
    name: {
      en: 'Exported'
    },
    color: 'primary'
  },
  bypassed: {
    name: {
      en: 'Bypassed'
    },
    color: 'default'
  },
  closed: {
    name: {
      en: 'Closed',
      es: 'Cerrado'
    },
    color: 'primary'
  },
  approved: {
    name: {
      en: 'approved'
    },
    color: 'secondary'
  },
  notApproved: {
    name: {
      en: 'Not Approved'
    },
    color: 'default'
  },
  deactivated: {
    name: {
      en: 'Deactivated'
    },
    backgroundColor: theme.palette.error.main,
    textColor: 'white'
  }
};

export default statusChips;
