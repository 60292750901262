import { theme } from '@buildhero/sergeant';

export const row = contents => ({
  options: {
    marginBottom: theme.default.spacing(2)
  },
  contents
});

export const colRow = contents => ({
  options: {
    marginBottom: theme.default.spacing(2),
    direction: 'row'
  },
  contents
});

export const col = (width, contents) => ({
  options: {
    size: 'auto',
    paddingRight: theme.default.spacing(2),
    grow: 0,
    shrink: 1,
    direction: 'column',
    width
  },
  contents
});
