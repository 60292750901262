import { VisitStatus } from 'utils/AppConstants';

export const JobCloseoutTypes = {
  TIME_AND_MATERIAL: 'TIME_AND_MATERIAL',
  QUOTED: 'QUOTED',
  MAINTENANCE: 'MAINTENANCE'
};

export const JobCloseoutTypeStrings = {
  TIME_AND_MATERIAL: 'Time and Material',
  QUOTED: 'Quoted',
  MAINTENANCE: 'Maintenance'
};

export const RelevantVisitStatuses = [
  VisitStatus.ONHOLD,
  VisitStatus.CANCELED,
  VisitStatus.COMPLETE,
  VisitStatus.CLOSED,
  VisitStatus.CONVERTED
];

export const InventoryPartSelectionSource = {
  JOB_REPORT: 'Job Report',
  VISIT: 'Visit',
  INVOICE: 'Invoice'
};

export default JobCloseoutTypes;
