export const communicationRowsMeta = [
  {
    id: 'name',
    numeric: false,
    label: 'Name',
    filterKey: 'Communication.name',
    filterType: 'string'
  },
  {
    id: 'type',
    numeric: false,
    type: 'CommunicationType',
    isCustom: true,
    label: 'Type',
    filterKey: 'Communication.type',
    filterType: 'string'
  },
  {
    id: 'departmentIds',
    numeric: false,
    type: 'CommunicationDepartments',
    isCustom: true,
    label: 'Department',
    filterKey: 'Communication.departmentIds',
    filterType: 'string'
  },
  {
    id: 'message',
    numeric: false,
    label: 'Message',
    filterKey: 'Communication.message',
    filterType: 'string'
  }
];

export const communicationForm = (communicationTypes, departments) => {
  return {
    fields: {
      name: {
        default: 'name',
        edit: 'name'
      },
      type: {
        default: 'type',
        edit: 'type'
      },
      departmentIds: {
        default: 'departmentIds',
        edit: 'departmentIds'
      },
      message: {
        default: 'message',
        edit: 'message'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      default: 'FieldWithLabel',
                      edit: 'TextInput'
                    },
                    source: 'name',
                    options: {
                      label: 'Name',
                      placeholder: '',
                      isRequired: true
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      default: 'SelectInputView',
                      edit: 'SelectInput'
                    },
                    source: 'departmentIds',
                    options: {
                      label: 'Department',
                      placeholder: '',
                      isRequired: true,
                      valueSet: departments,
                      isMultipleSelection: true
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      default: null,
                      edit: 'SelectInput'
                    },
                    source: 'type',
                    options: {
                      label: 'Type',
                      placeholder: '',
                      isRequired: true,
                      valueSet: communicationTypes
                    }
                  },
                  {
                    component: {
                      default: 'FieldWithLabel',
                      edit: null
                    },
                    source: 'typeName',
                    options: {
                      label: 'Type',
                      placeholder: '',
                      isRequired: true
                    }
                  }
                ]
              },
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: []
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      default: 'FieldWithLabel',
                      edit: 'TextInput'
                    },
                    source: 'message',
                    options: {
                      label: 'Message',
                      placeholder: 'Enter message',
                      isRequired: true,
                      lines: 12
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    },
    validation: {
      type: 'object',
      properties: {
        name: {
          type: 'string'
        },
        type: {
          type: 'string'
        },
        departmentIds: {
          type: 'string'
        },
        message: {
          type: 'string'
        }
      },
      required: ['name', 'type', 'departmentIds', 'message']
    },
    validationErrors: {
      name: {
        required: 'Name is required.'
      },
      type: {
        required: 'Type is required.'
      },
      departmentIds: {
        required: 'Department is required.'
      },
      message: {
        required: 'Message is required.'
      }
    }
  };
};

export default communicationRowsMeta;
