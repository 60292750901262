import gql from 'graphql-tag';

const getReviewReportPhotosByJob = gql`
  query getReviewReportPhotosByJob($partitionKey: String!, $sortKey: String!) {
    getJob(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      jobNumber
      visits: visitsView(entityConnection: "Visit") {
        items {
          visitNumber
          id
          reviewReports(entityConnection: "ReviewReport") {
            items {
              id
              attachments(entityConnection: "Attachment") {
                items {
                  id
                  customFileName
                  fileName
                  fileUrl
                  type
                  description
                  comment
                  sortOrder
                  addedBy
                  addedDateTime
                  dateAdded
                  createdBy
                  createdDate
                  createdDateTime
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getReviewReportPhotosByJob;
