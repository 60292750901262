import { connect } from 'react-redux';

import {
  setProjectDetails,
  setProjectKpis,
  refetchProjectDetails,
  setTimezone
} from 'redux/actions/projectManagementActions';

import ProjectLayout from './ProjectLayout.component';

const mapStateToProps = state => ({
  user: state.user,
  projectDetailsRefetch: state.pm.projectDetailsRefetch,
  project: state.pm.project,
  timezone: state.pm.timezone.timezone
});

const mapDispatchToProps = {
  setProjectDetails,
  setProjectKpis,
  refetchProjectDetails,
  setTimezone
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectLayout);
