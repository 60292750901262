import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import ErrorBoundaries from 'scenes/Error';

const useStyles = makeStyles(theme => ({
  addBtn: {
    backgroundColor: theme.palette.other.genoa,
    borderRadius: 3,
    boxShadow: 'none',
    color: theme.palette.grayscale(100),
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    letterSpacing: 0,
    textAlign: 'center',
    textTransform: 'uppercase',
    height: 32,
    '&:hover': {
      backgroundColor: theme.palette.other.genoa,
      boxShadow: 'none'
    }
  },
  actionItems: {
    marginBottom: 4
  }
}));

export default function InventoryPageHeader(props) {
  const classes = useStyles();
  const { btnLabel, handleAdd } = props;

  return (
    <ErrorBoundaries>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        className={classes.actionItems}
      >
        <Grid item>
          <Button variant="contained" onClick={() => handleAdd()} className={classes.addBtn}>
            {btnLabel}
          </Button>
        </Grid>
      </Grid>
    </ErrorBoundaries>
  );
}
