import * as R from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import toMap from 'utils/toMap';
import { getCompanyBytenantId } from 'services/API/companies';
import {
  useEmployees,
  useProjectPhases
} from '@pm/ProjectDetails/FieldReport/DailyReportList/DailyReport.hooks';
import {
  projectTimesheetsUrl,
  useFetchProjectTimesheets
} from '@pm/ProjectDetails/FieldReport/DailyReportList/DailyReport.api';
import moment from 'moment';

export const useCompanyInfo = tenantId => {
  const [info, setInfo] = useState({});
  useEffect(() => {
    (async () => {
      const response = await getCompanyBytenantId(tenantId);
      setInfo(response[0]);
    })();
  }, [tenantId]);
  return info;
};

export const useProjectTimesheets = (
  { projectId, dailyReportId, dailyReportDate },
  { depends, skip }
) => {
  const [loadingTimesheets, setLoadingTimesheets] = useState(true);
  const [{ data: employees, loading: loadingEmployees }] = useEmployees();
  const [{ data: projectPhases, loading: loadingProjectPhases }] = useProjectPhases(projectId);
  const [{ data: timesheetsData }] = useFetchProjectTimesheets(
    projectTimesheetsUrl(
      projectId,
      moment.unix(dailyReportDate).format('YYYY-MM-DD'),
      dailyReportId
    ),
    {
      skip: !projectId || !dailyReportId || skip,
      depends,
      onSuccess: () => setLoadingTimesheets(false)
    }
  );

  const data = useMemo(() => {
    const employeesMap = toMap(employees || []);
    const phasesMap = toMap(projectPhases || []);
    return R.sortBy(
      R.prop('name'),
      timesheetsData
        ?.filter(
          item =>
            (item?.dailyReportId === dailyReportId || !item?.dailyReportId) &&
            item?.projectId === projectId
        )
        ?.map(item => {
          const payrollHours =
            item?.timesheetEntries?.reduce(
              (result, payrollHour) => ({
                ...result,
                [payrollHour?.hourTypeId]: (payrollHour?.duration || 0) / 3600
              }),
              {}
            ) || {};
          return {
            name: employeesMap[item?.employeeId]?.name || '',
            phaseName: phasesMap[item?.projectPhaseId]?.name || '',
            ...payrollHours
          };
        }) || []
    );
  }, [timesheetsData, employees, projectPhases, dailyReportId, projectId]);
  return [{ data, loading: loadingTimesheets || loadingEmployees || loadingProjectPhases }];
};
