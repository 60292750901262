export const useStyles = () => ({
  rowContainer: theme => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.default.spacing(1),
    marginTop: theme.default.spacing(1)
  }),
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  checkbox: {
    padding: 0,
    marginLeft: -2,
    marginRight: 4,
    '& svg': {
      height: 16,
      width: 16
    }
  }
});
