import { useMemo } from 'react';

import { last } from 'lodash';
import moment from 'moment';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { InvoiceStatus } from 'utils/constants';

import { UPDATE_INVOICE } from '../../../../Invoices/InvoiceDetail/InvoiceDetail.gql';

import { serializeVoidPayApp } from './PayApplicationList.serializer';

export const useLastPayApplication = ({ payApplications }) => {
  return useMemo(() => {
    return (
      last(payApplications.filter(payApplication => payApplication.status !== InvoiceStatus.VOID))
        ?.PayApplication || {}
    );
  }, [payApplications]);
};

export const useInitialData = ({
  isEdit,
  generateModalPayApp,
  nextPayAppNumberResponse,
  lastPayApplication = {},
  project
}) => {
  return useMemo(() => {
    return isEdit
      ? generateModalPayApp?.PayApplication
      : {
          periodFrom: lastPayApplication?.periodTo
            ? moment
                .unix(lastPayApplication?.periodTo)
                .add(1, 'd')
                .unix()
            : Number(project.dateStart),
          number: nextPayAppNumberResponse?.data,
          applicationDate: moment().unix()
        };
  }, [isEdit, generateModalPayApp, nextPayAppNumberResponse, lastPayApplication, project]);
};

export const useLinkedPayApplications = ({ payApplications }) => {
  return useMemo(() => {
    let previousPayApplication;
    const linkedPayApps = [];

    payApplications.forEach(payApplication => {
      if (payApplication.status === InvoiceStatus.VOID) {
        linkedPayApps.push(payApplication);
      } else {
        const linkedPayApplication = { ...payApplication, previousPayApplication };
        linkedPayApps.push(linkedPayApplication);
        previousPayApplication = linkedPayApplication;
      }
    });

    return linkedPayApps;
  }, [payApplications]);
};

export const useVoidPayAppInvoice = (options = undefined) => {
  return useExtendedMutation(UPDATE_INVOICE, {
    serializer: serializeVoidPayApp,
    ...options
  });
};
