export default {
  layouts: {
    default: {
      props: {
        style: {
          flexDirection: 'row',
          justifyContent: 'space-between'
        }
      },
      contents: [
        {
          component: 'DateInput',
          source: 'renewalDate',
          props: {
            label: 'Next Renewal On',
            tooltip: `A tentative date you'd like to renew this agreement used for monitoring upcoming renewals.
                  This will be the default start date for the new agreement, but this can be changed at any time`,
            style: {
              width: '200px',
              padding: '5px'
            }
          }
        },
        {
          component: 'Input',
          source: 'renewalNote',
          props: {
            label: 'Renewal Notes',
            style: { padding: '5px' },
            multiline: true,
            rowsMax: 30,
            rows: 1
          }
        }
      ]
    }
  }
};
