import gql from 'graphql-tag';

const deleteFormDefinition = gql`
  mutation DeleteFormDefinition($input: DeleteFormDefinitionInput!) {
    deleteFormDefinition(input: $input) {
      id
    }
  }
`;

export default deleteFormDefinition;
