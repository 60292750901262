// Match fields based on property address
export function matchField(fields, address, nullIfNoMatch = true) {
  const matchedFields = {};

  Object.keys(fields).forEach(key => {
    let regions = fields[key][0];
    const field = fields[key][1];
    regions = regions.map(region => ({
      ...region,
      state: region?.state?.trim().toLowerCase(),
      county: region?.county?.trim().toLowerCase(),
      city: region?.city?.trim().toLowerCase()
    }));
    const stateFromAddress = [
      address?.state?.longName.trim().toLowerCase(),
      address?.state?.shortName.trim().toLowerCase()
    ];
    const countyFromAddress = [
      address?.county?.longName.trim().toLowerCase(),
      address?.county?.shortName.trim().toLowerCase()
    ];
    const cityFromAddress = [
      address?.city?.longName.trim().toLowerCase(),
      address?.city?.shortName.trim().toLowerCase()
    ];
    // get best match. Priority: city, county, state
    // will never match a tax rate where city, county, and state are falsy
    let match = null;
    regions.some(candidate => {
      // throw away any non-matches
      if (
        (candidate.state && !stateFromAddress.includes(candidate.state)) ||
        (candidate.county && !countyFromAddress.includes(candidate.county)) ||
        (candidate.city && !cityFromAddress.includes(candidate.city))
      ) {
        return false;
      }

      // this assumes that if candidate.city is defined, then it also has
      // state & county defined.
      if (
        candidate.city ||
        (candidate.county && !match?.county) ||
        (candidate.state && !match?.state)
      ) {
        match = candidate;
      }
      return candidate.city;
    });
    // If there is no match, change nothing
    if (match) {
      matchedFields[key] = match[field];
    } else if (nullIfNoMatch) {
      matchedFields[key] = null;
    }
  });
  return matchedFields;
}
