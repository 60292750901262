const styles = theme => ({
  title: {
    fontSize: 17,
    fontFamily: theme.typography.fontFamily,
    color: '#272b39',
    letterSpacing: 0.05,
    fontWeight: 'bold'
  },
  listItemIcon: {
    marginTop: -30,
    minWidth: 30
  },
  listIcon: {
    fontSize: 17,
    color: theme.palette.brand.logoBlue
  },
  listText: {
    textAlign: 'left',
    width: '90%'
  },
  listSecondaryAction: {
    right: 30
  },
  containerStyle: {
    width: 'auto',
    flexGrow: 1,
    alignItems: 'left'
  },
  entityTile: {
    padding: 6,
    marginRight: 18,
    backgroundColor: theme.palette.grayscale(90),
    color: '#4c505f',
    fontSize: 11,
    letterSpacing: 0.03,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold'
  },
  secondLine: {
    marginTop: 15
  },
  thirdLine: {
    marginTop: 15
  },
  subtitleKeyLabel: {
    paddingLeft: 25,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: 12
  },
  subtitleKey: {
    paddingLeft: 5
  },
  subtitleLongKeyLabel: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: 12,
    display: 'block',
    float: 'left',
    marginTop: 2
  },
  subtitleLongKey: {
    paddingLeft: 5,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '80%',
    display: 'block',
    overflow: 'hidden'
  },
  searchItemHighlights: {
    fontSize: 13,
    fontFamily: theme.typography.fontFamily,
    lineHeight: 2,
    letterSpacing: 0.04,
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#4c505f'
  },
  searchResults: {
    padding: 20,
    borderBottom: '1px solid rgba(189, 189, 189, 0.3)'
  },
  actionLink: {
    color: '#4c505f',
    textDecoration: 'none',
    [theme.breakpoints.up('sm')]: {
      width: '100%'
    }
  },
  secondaryActionLink: {
    color: '#4c505f',
    fontSize: 13,
    fontFamily: theme.typography.fontFamily,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    },
    paddingLeft: 8
  }
});
export default styles;
