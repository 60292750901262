import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';
import { UserPermission } from 'components';
import { PermissionConstants } from 'utils/AppConstants';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  buttonHover: {
    '&:hover': {
      backgroundColor: theme.palette.grayscale(90)
    }
  },
  iconColor: {
    color: '#646464',
    fontSize: 18,
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  checkboxStyle: {
    padding: 0
  }
}));

const ReviewReportRowActions = ({
  editAction,
  deleteAction,
  checkboxAction,
  record,
  isEditable
}) => {
  const classes = useStyles();
  return (
    <>
      {isEditable && (
        <>
          <UserPermission I="edit" action={PermissionConstants.OBJECT_REVIEWREPORT}>
            <Tooltip title="Edit">
              <IconButton aria-label="Edit" className={classes.buttonHover} onClick={editAction}>
                <EditIcon className={classes.iconColor} />
              </IconButton>
            </Tooltip>
          </UserPermission>
          <UserPermission I="delete" action={PermissionConstants.OBJECT_REVIEWREPORT}>
            <Tooltip title="Delete ">
              <IconButton
                aria-label="Delete"
                className={classes.buttonHover}
                onClick={deleteAction}
              >
                <DeleteIcon className={classes.iconColor} />
              </IconButton>
            </Tooltip>
          </UserPermission>
        </>
      )}
      {checkboxAction && (
        <Checkbox
          onChange={checkboxAction}
          className={classes.checkboxStyle}
          checked={record?.includeInInvoice}
          disabled={!isEditable}
        />
      )}
    </>
  );
};

export default ReviewReportRowActions;
