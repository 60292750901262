import { useMemo, useRef } from 'react';

import { selectPropertyPin } from './PropertyMarker.selectors';

export const useMarkerIcon = ({ visits = [], scale = 1 }) => {
  const { Size, Point, MarkerImage } = google.maps;
  const anchor = useRef(new Point(23 * scale, 40 * scale));
  const origin = useRef(new Point(0, 0));
  const size = useRef(new Size(46 * scale, 60 * scale));
  const mapPin = selectPropertyPin(visits);

  return useMemo(() => {
    return new MarkerImage(
      mapPin,
      null /* size is determined at runtime */,
      origin.current,
      anchor.current,
      size.current
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visits]);
};
