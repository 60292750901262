import { calculateMargin, calculateMarginFromMarkup } from '@buildhero/math';

import { PermissionConstants } from 'utils/AppConstants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import { convertForMathLib } from 'utils/mathLibrary';

export const pricebookEntryColumns = (isQuickBooksEnabled, showMargin, flags = {}, showMarkup) => {
  const rowMeta = [
    {
      id: 'product.name',
      label: 'Product Name',
      filterKey: 'Product.name',
      filterType: 'string',
      numeric: false
    },
    {
      id: 'product.code',
      label: 'Product Code',
      filterKey: 'Product.code',
      filterType: 'string',
      numeric: false
    },
    {
      id: 'product.taxable',
      filterKey: 'Product.taxable',
      filterType: 'boolean',
      label: 'Taxable',
      type: 'boolean',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ],
      numeric: false
    },
    isQuickBooksEnabled && {
      id: 'product.glIncomeAccountRef.name',
      label: 'Income Account',
      filterKey: 'LedgerAccount.name',
      filterType: 'string',
      numeric: false
    },
    {
      id: 'product.unitCost',
      label: 'Unit Cost',
      caslKey: PermissionConstants.DATA_VIEW_COST_DATA,
      caslAction: 'allow',
      filterKey: 'PriceBookEntry.unitCost',
      filterType: 'float',
      type: 'currency',
      precision: 5,
      numeric: true
    }
  ];

  const materialMarkup = showMarkup && {
    id: 'materialMarkup',
    label: 'Material Markup',
    valueFormatter: undefined,
    caslKey: PermissionConstants.DATA_VIEW_PRICE_DATA,
    caslAction: 'allow',
    disableFilter: showMargin,
    filterKey: 'PriceBookEntry.materialMarkup',
    filterType: 'float',
    type: 'percentage',
    numeric: true
  };
  const totalMarkup = showMarkup && {
    id: 'markupValue',
    label: 'Total Markup',
    valueFormatter: undefined,
    caslKey: PermissionConstants.DATA_VIEW_PRICE_DATA,
    caslAction: 'allow',
    disableFilter: showMargin,
    filterKey: 'PriceBookEntry.markupValue',
    filterType: 'float',
    type: 'percentage',
    numeric: true
  };

  const materialMargin = showMargin && {
    id: 'materialMarkup',
    label: 'Material Margin',
    valueFormatter: markup => convertForMathLib(calculateMarginFromMarkup, markup),
    caslKey: PermissionConstants.DATA_VIEW_PRICE_DATA,
    caslAction: 'allow',
    filterKey: 'PriceBookEntry.materialMarkup',
    type: 'percentage',
    numeric: true,
    disableFilter: true
  };

  const totalMargin = showMargin && {
    id: 'markupValue',
    label: 'Total Margin',
    valueFormatter: markup => convertForMathLib(calculateMarginFromMarkup, markup),
    caslKey: PermissionConstants.DATA_VIEW_PRICE_DATA,
    caslAction: 'allow',
    filterKey: 'PriceBookEntry.markupValue',
    filterType: 'float',
    type: 'percentage',
    numeric: true,
    disableFilter: true
  };

  return [
    ...rowMeta,
    materialMarkup,
    totalMarkup,
    materialMargin,
    totalMargin,
    {
      id: 'unitPrice',
      caslKey: PermissionConstants.DATA_VIEW_PRICE_DATA,
      caslAction: 'allow',
      label: 'Unit price',
      filterKey: 'PriceBookEntry.unitPrice',
      filterType: 'float',
      type: 'currency',
      numeric: true
    },
    flags[FeatureFlags.PRICEBOOK_AUTO_UPDATE_SCALED_MARKUP] && {
      id: 'autoUpdateScaledMarkup',
      filterKey: 'autoUpdateScaledMarkup',
      filterType: 'boolean',
      label: 'Auto-update Scaled Markup',
      type: 'boolean',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ],
      numeric: false
    }
  ].filter(Boolean);
};
export default pricebookEntryColumns;
