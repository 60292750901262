import gql from 'graphql-tag';

const deletePurchaseOrderType = gql`
  mutation deletePurchaseOrderType($partitionKey: String!, $id: String!) {
    deletePurchaseOrderType(partitionKey: $partitionKey, id: $id) {
      id
      name
    }
  }
`;

export default deletePurchaseOrderType;
