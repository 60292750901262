const totalsAndSubtotals = ({
  HTML,
  convertToCurrencyStringCheck,
  ItemType,
  settingsJSON,
  SettingConstants,
  smartFieldInfo,
  smartFieldsMap,
  styles,
  fontFamilyStyle
}) => {
  const settingsTotals = settingsJSON[SettingConstants.TOTALS_AND_SUBTOTALS];
  const materialLineItems = smartFieldInfo.quoteLineProducts.items.filter(
    item => item.lineItemType === ItemType.MATERIAL
  );
  const discountItems = smartFieldInfo.quoteLineProducts.items.filter(
    item => item.lineItemType === ItemType.DISCOUNT
  );

  return `<figure>
            <table style="width:80%;float:right;border-color:hsl(207, 90%, 54%, 0);border-style:solid;" data-smartfield="TotalsandSubtotals">
            <tbody>
                ${HTML.taskSubtotal({
                  convertToCurrencyStringCheck,
                  settingsTotals,
                  SettingConstants,
                  smartFieldInfo,
                  styles,
                  fontFamilyStyle
                })}
                ${HTML.lineItemOrDiscount({
                  isDisplayed: settingsTotals[SettingConstants.SHOW_LINE_ITEM_PRICING],
                  lineItems: materialLineItems,
                  ItemType,
                  type: 'Line Item',
                  convertToCurrencyStringCheck,
                  styles,
                  fontFamilyStyle
                })}
                ${HTML.lineItemOrDiscount({
                  isDisplayed: settingsTotals[SettingConstants.SHOW_DISCOUNT],
                  lineItems: discountItems,
                  ItemType,
                  type: ItemType.DISCOUNT,
                  convertToCurrencyStringCheck,
                  styles,
                  fontFamilyStyle
                })}
                ${HTML.quoteSubtotal({
                  convertToCurrencyStringCheck,
                  settingsTotals,
                  SettingConstants,
                  smartFieldInfo,
                  styles,
                  fontFamilyStyle
                })}
                ${HTML.taxableSubtotal({
                  convertToCurrencyStringCheck,
                  settingsTotals,
                  SettingConstants,
                  smartFieldInfo,
                  styles,
                  fontFamilyStyle
                })}
                ${HTML.taxAmount({
                  convertToCurrencyStringCheck,
                  settingsTotals,
                  SettingConstants,
                  smartFieldInfo,
                  smartFieldsMap,
                  styles,
                  fontFamilyStyle
                })}
                ${HTML.quoteTotal({
                  convertToCurrencyStringCheck,
                  settingsTotals,
                  SettingConstants,
                  smartFieldInfo,
                  smartFieldsMap,
                  styles,
                  fontFamilyStyle
                })}
            </tbody>
            </table>
            </figure>
            `;
};

export default totalsAndSubtotals;
