const scheduleSchema = [
  'employeeSortKey',
  'scheduledFor',
  'startTime',
  'endTime',
  'onRoute',
  'delayed',
  'delayedReason',
  'onHold',
  'onHoldReason ',
  'status',
  'parentId',
  'hierarchy',
  'id',
  'entityType',
  'version',
  'tenantId',
  'tenantCompanyId',
  'partitionKey',
  'parentSortKey',
  'sortKey',
  'createdBy',
  'createdDate',
  'createdDateTime',
  'deletedBy',
  'deletedDate',
  'deletedDateTime',
  'lastUpdatedBy',
  'lastUpdatedDate',
  'lastUpdatedDateTime',
  'lsi1',
  'lsi2',
  'lsi3',
  'lsi4',
  'lsi5',
  'gsi1',
  'gsi2'
];

const scheduleSchemaMappings = ['timeSheets', 'employee', 'parentEntity'];

export default scheduleSchema;
export { scheduleSchemaMappings };
