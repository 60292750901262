import React from 'react';
import moment from 'moment';
import { Grid, Typography, Checkbox } from '@material-ui/core';
import invoiceLabels from 'meta/Jobs/Invoice/labels';
import AppConstants from 'utils/AppConstants';
import { getCustomerSignatureSrc } from 'utils';

const Signature = ({ classes, current, user, send, isReviewReport }) => {
  const { context } = current;
  const signatures = context.customerSignatures || [];
  return (
    <>
      <Typography className={classes.greySectionTitle}>
        {invoiceLabels.customerSignature[user.locale]}
      </Typography>
      {signatures.map(signature => (
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          key={`sigRow${signature?.id}`}
        >
          <Grid item key={`sigImageItem${signature?.id}`}>
            <Typography className={classes.signature} key={`sigImg${signature?.id}`}>
              {signature.signatureImageUrl && (
                <img
                  alt="Customer Signature"
                  src={getCustomerSignatureSrc(signature)}
                  className={classes.signatureImage}
                  key={`${signature?.id}img`}
                />
              )}
            </Typography>
            <Typography className={classes.signatureText} key={`sigTxt${signature?.id}`}>
              {`Captured By: ${signature.capturedBy || signature.createdBy},
                    ${moment
                      .unix(+signature.signedDateTime / 1000 || +signature.createdDate)
                      .format(AppConstants.DATETIME_FORMAT)}`}
              <br />
              {`Signee: ${signature.nameOfSignee}`}
            </Typography>
          </Grid>
          {isReviewReport && (
            <Grid item key={`sigIncluedItem${signature?.id}`}>
              <Checkbox
                style={{ marginRight: 8 }}
                checked={signature.includeInInvoice}
                disabled={context.freezeEdit}
                onChange={event =>
                  send('INCLUDE_CUSTOMER_SIGNATURE', {
                    data: signature,
                    checked: event.target.checked
                  })
                }
                key={`sigInclue${signature?.id}`}
              />
            </Grid>
          )}
        </Grid>
      ))}
    </>
  );
};

export default Signature;
