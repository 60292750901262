import gql from 'graphql-tag';

const getQuoteProductBundlesById = gql`
  query getQuoteById($id: String!) {
    getQuoteById(id: $id) {
      id
      quoteLineProductBundles: quoteLineProductBundlesView(
        entityConnection: "QuoteLineProductBundle"
      ) {
        nextToken
        items {
          id
          productBundle(entityConnection: "ProductBundle") {
            id
            name
            description
            type
            category
            subCategory
          }
        }
      }
    }
  }
`;
export default getQuoteProductBundlesById;
