import { gql } from '@apollo/client/core';

import useExtendedQuery from 'customHooks/useExtendedQuery';

import { jobCostingFragment } from './queryFragment';

const REFRESH_JOB_COSTING = gql`
  query getDerivedJobCosting($jobId: String!) {
    getDerivedJobCosting(jobId: $jobId) {
      ${jobCostingFragment}
    }
  }
`;

export const useGetDerivedJobCosting = jobId => {
  return useExtendedQuery(REFRESH_JOB_COSTING, {
    variables: {
      jobId
    }
  });
};
