import React, { Component } from 'react';

import { Button, ButtonType, ThemeProvider } from '@buildhero/sergeant';
import Grid from '@material-ui/core/Grid';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';

import { ResponsiveTable, SectionHeader, UserPermission } from 'components';
import ConfirmModal from 'components/Modal/ConfirmDialog';
import { attachmentRows } from 'meta/attachment-layout';
import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import { CommonService } from 'services/core';
import { Logger } from 'services/Logger';

import AttachmentModal from './AddAttachment';

/**
 * Creates customer page. new customer fields & layouts are generated from the meta file
 * labels are fetched from application level
 * locale of the user is referred from user context
 */
class AttachmentDetail extends Component {
  constructor(props) {
    super(props);
    this.CommonService = new CommonService();
    this.state = {
      openAttachment: false,
      dataRecord: '',
      modalMode: '',
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: ''
    };
  }

  handleOpenPopUp = (popUpKey, mode, record) => {
    this.setState({ [popUpKey]: true, modalMode: mode, dataRecord: record });
  };

  handleClosePopUp = popUpKey => {
    this.setState({ [popUpKey]: false });
  };

  performAttachmentDelete = async record => {
    if (!record) {
      return;
    }

    try {
      const { partitionKey, sortKey } = record;
      const { data } = this.props.customDeleteMutationService
        ? await this.props.customDeleteMutationService(partitionKey, sortKey)
        : await this.CommonService.deleteAttachment(partitionKey, sortKey);
      if (data) {
        if (this.props.refresh) {
          await this.props.refresh();
        }

        this.props.snackbarOn(
          'success',
          `Successfully deleted attachment ${record.fileName || ''}`
        );
        this.setState({
          confirmDialog: false,
          confirmAction: '',
          confirmMessage: ''
        });
      }
    } catch (error) {
      Logger.error(error);
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        this.props.snackbarOn('error', error.graphQLErrors[0].message);
      } else {
        this.props.snackbarOn('error', 'Unable to delete attachment, please try again later');
      }
    }
  };

  handleAttachmentRowActions = (mode, record) => {
    if (mode === 'delete') {
      this.setState({
        confirmDialog: true,
        confirmAction: async () => this.performAttachmentDelete(record),
        confirmMessage: 'attachment'
      });
    } else {
      this.handleOpenPopUp('openAttachment', mode, {
        ...record,
        shareWithTechniciansOnMobile: record.shareWithTechniciansOnMobile === 'Yes',
        internalFile: record.internalFile === 'Yes'
      });
    }
  };

  handleCancelConfirmation = () => {
    this.setState({ confirmDialog: false, confirmAction: '', confirmMessage: '' });
  };

  render() {
    const {
      parent,
      data,
      caslKey,
      mutationService,
      disabledDocumentHeader,
      readOnly,
      flags,
      hasShareWithTechsOption = false,
      hasPermissionToEdit = true, // since its a common component
      hasInternalFileOption = false
    } = this.props;
    return (
      <Grid item xs={12}>
        <UserPermission I="new" action={caslKey}>
          {!disabledDocumentHeader && (
            <SectionHeader
              title={Labels.Documents[this.props.user.locale]}
              enablePadding
              icon="descriptionIcon"
              overrideHeaderButtons={[
                !readOnly && hasPermissionToEdit && (
                  <ThemeProvider>
                    <Button
                      type={ButtonType.SECONDARY}
                      onClick={() => this.handleOpenPopUp('openAttachment', 'new', '')}
                      startIcon={<AddCircleOutlineIcon />}
                      key="addButton"
                    >
                      Add Attachment
                    </Button>
                  </ThemeProvider>
                )
              ]}
            />
          )}
        </UserPermission>
        <ResponsiveTable
          isLoading={this.props.isLoading}
          rowMetadata={attachmentRows(flags, hasShareWithTechsOption, hasInternalFileOption)}
          data={data.items}
          disableFilter
          rowActions={this.handleAttachmentRowActions}
          rowActionButtons={
            readOnly || !hasPermissionToEdit
              ? null
              : {
                  edit: {
                    label: 'Edit',
                    icon: 'BorderColor'
                  },
                  delete: {
                    label: 'Delete',
                    icon: 'Delete'
                  }
                }
          }
          caslKey={caslKey}
        />
        <AttachmentModal
          open={this.state.openAttachment}
          mode={this.state.modalMode}
          data={this.state.dataRecord}
          parent={parent}
          mutationService={mutationService}
          handleClose={flag => {
            this.handleClosePopUp('openAttachment');
            if (flag && parent && this.props.refresh) {
              this.props.refresh();
            }
          }}
          maxFileSizeWarningLabel={this.props.maxFileSizeWarningLabel}
          maxFileSize={this.props.maxFileSize}
          caslKey={caslKey}
          hasShareWithTechsOption={hasShareWithTechsOption}
          hasInternalFileOption={hasInternalFileOption}
        />
        <ConfirmModal
          open={this.state.confirmDialog}
          confirm={this.state.confirmAction}
          cancel={this.handleCancelConfirmation}
          message={this.state.confirmMessage}
        />
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const connectedAttachmentDetail = connect(mapStateToProps, mapDispatchToProps)(AttachmentDetail);

export default withLDConsumer()(connectedAttachmentDetail);
