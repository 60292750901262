const styles = theme => ({
  textField: {
    height: 'auto', // 71,
    minHeight: 91,
    marginTop: 3,
    paddingBottom: 3
  },
  cssUnderline: {
    '&:after': {
      borderBottomColor: theme.palette.brand.logoBlue
    },
    '&&&&:hover:before': {
      borderBottom: `1px solid ${theme.palette.brand.logoBlue}`
    }
  }
});

export default styles;
