import debounce from 'lodash/debounce';
import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';
import { productSearch, getProducts } from 'services/API/product';

const defaultFlexStyle = '0 0 219px';

const retainageFields = {
  retainage: { required: false }
};

const retainageContents = (handleChangeRetainage, handleRetainageProductSelection) => {
  return [
    {
      options: {
        direction: 'row',
        flexWrap: 'nowrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle
          },
          contents: [
            {
              component: {
                default: 'TextInput'
              },
              source: 'retainage',
              options: {
                label: 'Retainage %',
                placeholder: ' ',
                fullWidth: true,
                type: 'percentage',
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                onChange: debounce(handleChangeRetainage, 250)
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            paddingLeft: 20
          },
          contents: [
            {
              component: 'SearchBar',
              source: 'retainageProduct',
              options: {
                onSelect: handleRetainageProductSelection,
                label: 'Retainage Category',
                placeholder: 'Search Retainage Category',
                searchFunction: productSearch,
                emptySearchFunction: getProducts,
                resultFormatFunction: result => result?.name?.trim(),
                hideOptions: false,
                useId: false,
                variant: 'standard',
                color: 'secondary'
              }
            }
          ]
        }
      ]
    }
  ];
};

const retainageFormLayout = ({ handleChangeRetainage, handleRetainageProductSelection }) => {
  return {
    fields: generateDefaultFieldsObject(retainageFields),
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: retainageContents(handleChangeRetainage, handleRetainageProductSelection)
      }
    }
  };
};

export { retainageFields, retainageFormLayout };
