import moment from 'moment';
import { getAssignedFormDataFromForm } from 'utils/FormMetaConverter';

const buildVisitCreatePayload = ({
  jobId,
  user,
  availableForms,
  propertyAssets,
  visitFormData,
  isMultiVisits
}) => {
  const {
    description,
    prerequisites,
    formIds,
    visitFormIds, // this is a misnomer as this is an array of full visitForm objects
    assetIds,
    preferredTechnicians,
    visitDuration,
    visitDate,
    visitTime,
    endDate,
    excludeWeekend
  } = visitFormData;

  const vFormIds = Array.isArray(visitFormIds) ? visitFormIds.map(f => f?.id) : [];

  let prerequisitesAcknowledged = false;
  if (prerequisites !== undefined && prerequisites.length === 0) {
    prerequisitesAcknowledged = null;
  }

  const formData = availableForms
    .map(form => form?.value ?? form)
    .filter(form => [...(formIds ?? []), ...(vFormIds ?? [])]?.includes(form.id))
    .map(getAssignedFormDataFromForm);

  let visitAssets;

  if (assetIds) {
    visitAssets = propertyAssets
      ?.filter(asset => assetIds?.includes(asset.id))
      .map(asset => ({ propertyAssetId: asset.id }));
  }

  // Adds the Time string (16:45) value to the date unix value
  const getVisitScheduledFor = (dateValue, timeString) => {
    const time = moment(timeString, 'HH:mm');
    const date = moment.unix(dateValue).set({
      hour: time.get('hour'),
      minute: time.get('minute')
    });
    return date.unix();
  };
  const actualDuration = visitDuration > 0 ? `${visitDuration} minutes` : undefined;
  const scheduledFor =
    actualDuration && visitDate && visitTime ? getVisitScheduledFor(visitDate, visitTime) : null;
  const tentativeDate = visitDate ? moment.unix(visitDate).format() : undefined;
  const tentativeTime = visitTime || undefined;
  const additionalTechCount =
    preferredTechnicians?.extraTechsNumber ?? preferredTechnicians?.additionalTechnicianIds?.length;
  const visitPayload = {
    jobId,
    tenantCompanyId: user.tenantCompanyId,
    description: description || '',
    prerequisites: prerequisites || '',
    prerequisitesAcknowledged: prerequisitesAcknowledged || null,
    departmentId: preferredTechnicians?.departmentId,
    primaryTechnicianId: preferredTechnicians?.primaryTechnicianId,
    extraTechnicians: {
      requiredCount: additionalTechCount || 0, // setting null as reqd count in integer
      technicianIds: preferredTechnicians?.additionalTechnicianIds || []
    },
    visitDateTime: {
      actualDuration,
      scheduledFor,
      minimumDuration: actualDuration, // TODO: No longer used?
      tentativeDate,
      tentativeTime
    },
    visitAssets,
    formData
  };

  const recurringVisitPayload = {
    endDate,
    excludeWeekend: excludeWeekend || false,
    visit: visitPayload
  };

  return isMultiVisits ? recurringVisitPayload : visitPayload;
};

export default buildVisitCreatePayload;
