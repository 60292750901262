import React from 'react';

import { Image, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import PropTypes from 'prop-types';

import { getCloudinaryImageUrl } from 'scenes/ProjectManagement/components/utils';
import { AppConstants } from 'utils/AppConstants';

import styles from '../../ItemPDF.styles';

const ItemCoverPage = props => {
  const { companyInfo, itemData } = props;

  return (
    <Page size="letter" wrap={false}>
      <View style={styles.root}>
        {/* Logo and address */}
        <View style={[styles.displayFlex, { marginTop: 10, alignItems: 'flex-start' }]}>
          {companyInfo?.logoUrl ? (
            <Image style={styles.logoImage} src={getCloudinaryImageUrl(companyInfo?.logoUrl)} />
          ) : (
            <View />
          )}
          <View style={[styles.displayFlexColumn, { minWidth: 150, alignItems: 'flex-end' }]}>
            <Text style={[styles.text]}>
              {`${companyInfo?.companyAddresses?.addressLine1}, ${companyInfo?.companyAddresses?.addressLine2}`}
            </Text>
            <Text style={[styles.text]}>
              {`${companyInfo?.companyAddresses?.city}, ${companyInfo?.companyAddresses?.state}, ${companyInfo?.companyAddresses?.zipcode}`}
            </Text>
            <Text style={[styles.text]}>{companyInfo?.phonePrimary || null}</Text>
          </View>
        </View>
        {/* item title and date */}
        <View style={[styles.displayFlex, { marginTop: 20, marginBottom: 12 }]}>
          <Text style={styles.numberTitle}>Submittal Item Information</Text>
          <Text style={styles.date}>{moment().format(AppConstants.DATE_FORMAT)}</Text>
        </View>
        {/* Spec Section */}
        {itemData?.specSection ? (
          <View style={[styles.displayFlexColumn, { marginTop: 8 }]}>
            <Text style={styles.sectionTitle}>Spec Section</Text>
            <Text style={styles.text}>{itemData.specSection}</Text>
          </View>
        ) : (
          <View />
        )}
        {/* Sub Section */}
        {itemData?.subSection ? (
          <View style={[styles.displayFlexColumn, { marginTop: 8 }]}>
            <Text style={styles.sectionTitle}>Sub Section</Text>
            <Text style={styles.text}>{itemData.subSection}</Text>
          </View>
        ) : (
          <View />
        )}
        {/* Type */}
        {itemData?.type ? (
          <View style={[styles.displayFlexColumn, { marginTop: 8 }]}>
            <Text style={styles.sectionTitle}>Type</Text>
            <Text style={styles.text}>{itemData.type}</Text>
          </View>
        ) : (
          <View />
        )}
        {/* Description */}
        {itemData?.description ? (
          <View style={[styles.displayFlexColumn, { marginTop: 8 }]}>
            <Text style={styles.sectionTitle}>Description</Text>
            <Text style={styles.text}>{itemData.description}</Text>
          </View>
        ) : (
          <View />
        )}
        {/* Notes */}
        {itemData?.notes ? (
          <View style={[styles.displayFlexColumn, { marginTop: 8 }]}>
            <Text style={styles.sectionTitle}>Notes</Text>
            <Text style={styles.text}>{itemData.notes}</Text>
          </View>
        ) : (
          <View />
        )}
      </View>
    </Page>
  );
};

ItemCoverPage.propTypes = {
  companyInfo: PropTypes.object.isRequired,
  itemData: PropTypes.object.isRequired
};

export default ItemCoverPage;
