const reportPdfConfirm = (hasDraftReport = true) => ({
  fields: {},
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0,
            padding: [0, 0, 16, 0],
            hide: !hasDraftReport
          },
          contents: [
            {
              component: 'DraftReportWarningMessage'
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0,
            padding: [0, 6]
          },
          contents: [
            {
              component: 'BodyText',
              source:
                'By default cost and price are shown, use these options to hide cost, price or both in the generated Job Report.'
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: 'BodyText',
                  source: 'Hide Cost'
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  options: {
                    name: 'hideCost'
                  },
                  component: 'Switch',
                  source: 'hideCost'
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: 'BodyText',
                  source: 'Hide Price'
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  options: {
                    name: 'hidePrice'
                  },
                  component: 'Switch',
                  source: 'hidePrice'
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: 'BodyText',
                  source: 'Hide Before and After Photos'
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  options: {
                    name: 'hideBeforeAfterPhotos'
                  },
                  component: 'Switch',
                  source: 'hideBeforeAfterPhotos'
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: 'BodyText',
                  source: 'Hide Forms'
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  options: {
                    name: 'hideForms'
                  },
                  component: 'Switch',
                  source: 'hideForms'
                }
              ]
            }
          ]
        }
      ]
    }
  }
});

export default reportPdfConfirm;
