import React, { useEffect } from 'react';
import { any } from 'prop-types';
import { useParams } from 'react-router-dom';

import { UserPermission } from 'components';
import ErrorBoundaries from 'scenes/Error';
import { PermissionConstants, ReviewReportStatus } from 'utils/AppConstants';
import { LazyMountScrollContainer } from 'utils/withLazyMount';

import { useReviewReport } from '../../queries';
import { selectForms } from '../../selectors';

import AssetTable from '../AssetTable';
import FormTable from '../FormTable';
import LaborSection from '../LaborSection';
import ReportInfo from '../ReportInfo';
import TasksSection from '../TasksSection';

import ReviewReportHeader from './components/ReviewReportHeader';
import ReviewReportSummaryNotesTable from './components/ReviewReportSummaryNotesTable';
import ReviewReportNotesTable from './components/ReviewReportNotesTable';
import ReviewReportAttachmentTable from './components/ReviewReportAttachmentTable';
import SignatureSection from '../SignatureSection';
import ReviewReportDiscounts from './components/ReviewReportDiscounts';
import ReviewReportFees from './components/ReviewReportFees';
import ReviewReportLaborLineItems from './components/ReviewReportLaborLineItems';

import { ReviewReportDisabledContext } from './ReviewReport.contexts';
import { useStyles } from './ReviewReport.styles';
import ReviewReportPartsAndMaterialsSection from './components/ReviewReportPartsAndMaterialsSection';
import { withAddPOModal } from './hocs/withAddPOModal';

const ReviewReport = ({ client }) => {
  useEffect(() => {
    // ensure all data is fresh when navigating back to dispatch
    client.resetStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { id } = useParams();
  const styles = useStyles();

  const {
    data: {
      visit: { job, ...visit },
      ...reviewReport
    },
    loading,
    error,
    refetch
  } = useReviewReport(id);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const disabled = reviewReport?.status?.toLowerCase() !== ReviewReportStatus.DRAFT;

  return (
    <ErrorBoundaries>
      <UserPermission I="read" action={PermissionConstants.OBJECT_VISIT}>
        <ReviewReportDisabledContext.Provider value={{ disabled }}>
          <LazyMountScrollContainer className={styles.scrollContainer}>
            <ReviewReportHeader reportId={id} job={job} visit={visit} reviewReport={reviewReport} />
            <ReportInfo job={job} visit={visit} reviewReport={reviewReport} mountBeforeScroll />
            <ReviewReportSummaryNotesTable
              reviewReport={reviewReport}
              loading={loading}
              error={error}
              mountBeforeScroll
            />
            <ReviewReportNotesTable
              reviewReport={reviewReport}
              loading={loading}
              error={error}
              mountBeforeScroll
            />
            <AssetTable visit={visit} loading={loading} error={error} mountBeforeScroll />
            <TasksSection visit={visit} job={job} loading={loading} error={error} />
            <FormTable
              forms={selectForms(visit)}
              loading={loading}
              error={error}
              editable={!disabled}
            />
            <ReviewReportAttachmentTable
              reviewReport={reviewReport}
              loading={loading}
              error={error}
            />
            <SignatureSection reviewReport={reviewReport} isReviewReport />
            <ReviewReportPartsAndMaterialsSection
              reviewReport={reviewReport}
              visit={visit}
              job={job}
              loading={loading}
              error={error}
            />
            <LaborSection visit={visit} job={job} loading={loading} error={error} isReviewReport />
            <ReviewReportLaborLineItems
              job={job}
              visit={visit}
              reviewReport={reviewReport}
              loading={loading}
            />
            <ReviewReportDiscounts
              reviewReport={reviewReport}
              job={job}
              visit={visit}
              loading={loading}
              error={error}
            />
            <ReviewReportFees
              reviewReport={reviewReport}
              job={job}
              visit={visit}
              loading={loading}
              error={error}
            />
          </LazyMountScrollContainer>
        </ReviewReportDisabledContext.Provider>
      </UserPermission>
    </ErrorBoundaries>
  );
};

ReviewReport.propTypes = {
  client: any.isRequired
};

export default withAddPOModal(ReviewReport);
