/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Button, ButtonType, ThemeProvider, TV, TW } from '@buildhero/sergeant';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _, { uniq } from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  AuditLogs,
  Context,
  Notes,
  NotesV2,
  ServiceChannelButton,
  StatusChip,
  TagButtons,
  UserPermission
} from 'components';
import AlgoliaSelect from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import LinkButtonForm from 'components/BuildHeroFormComponents/LinkButtonForm';
import LeftSidebarWithContent from 'components/Layouts/LeftSidebarWithContent';
import MapViewStatic from 'components/MapViewStatic';
import useEmployees from 'customHooks/useEmployees';
import { CustomerRepLayout } from 'meta/Customer/CustomerRep/layout';
import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import { startGetJob, startGetJobFailure, startGetJobSuccess } from 'redux/actions/jobActions';
import { convertInlineForm } from 'scenes/Customer/CustomerDetail/utils';
import CustomerRep, { RepType } from 'scenes/Customer/CustomerRepModal';
import ErrorBoundaries from 'scenes/Error';
import { JobCloseoutTypes } from 'scenes/JobCloseout/constants';
import { useJobStatusHelper } from 'scenes/JobCloseout/JobCloseoutHeader/hooks/useJobStatusHelper';
import useUpdateJob from 'scenes/JobCloseout/JobCloseoutHeader/hooks/useUpdateJob';
import JobBillingStatusChip from 'scenes/JobCloseout/JobCloseoutHeader/JobBillingStatusChip/';
import { StatusButtons } from 'scenes/JobCloseout/JobCloseoutHeader/StatusButtons';
import {
  generateProcurementStatusTooltip,
  generateQuoteStatusTooltip,
  getJobCloseoutType,
  getProcurementStatus,
  getQuoteStatus
} from 'scenes/JobCloseout/utils';

import Routes from 'scenes/Routes';

import { getActiveServiceAgreementsForCustomerQuery } from 'scenes/ServiceAgreements/DetailView/queries';
import {
  CommonService,
  CompanyService,
  CustomerPropertyService,
  CustomerService,
  JobService,
  validations
} from 'services/core';
import { Logger } from 'services/Logger';

import { addressObjectToString, convertToCurrencyString, isTenantSettingEnabled } from 'utils';
import {
  FormEntityType,
  JOB_CLOSEOUT_STATUS,
  JobBillingStatus,
  JobProcurementStatus,
  jobQuoteStatus,
  PermissionConstants,
  ServiceAgreementStatus,
  TagType
} from 'utils/AppConstants';
import {
  AccountingApp,
  CustomFieldTypes,
  EnumType,
  InvoiceStatus,
  JobStatus
} from 'utils/constants';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import getSageJobs from 'utils/getSageJobs';

import {
  combineDataForJobUpdate,
  formatCustomFormDataForJobUpdate,
  formatExistingDataForJobUpdate,
  formatExistingDepartmentsForUpdate,
  formatFormDataForJobUpdate,
  formatJobDataForMainForm,
  formatJobDataForSidebarForm,
  getFetchErrorMessage,
  getPropertyRepresentative
} from './formattingUtils';
import JobStatusButtons from './JobStatusButtons';
import JobTabs from './JobTabs';
import { jobMainLayout, jobSidebarLayout } from './layout';
import styles from './styles';

const useStyles = makeStyles(theme => ({
  fieldTitle: {
    color: theme.palette.grayscale(20),
    marginBottom: '0.35em'
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  linkText: {
    fontSize: 16
  },
  inputLabel: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'normal',
    letterSpacing: '0.01px',
    lineHeight: '14px',
    marginBottom: theme.spacing(0.3)
  }
}));

const createLabel = (label, value) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="caption" className={classes.inputLabel}>
        {label}
      </Typography>
      <Typography variant="body1">{`${value ? 'Enabled' : 'Disabled'}`}</Typography>
    </Box>
  );
};

const DetailedJobCostingLabel = ({ field }) => createLabel('Detail Job Costing', field.value);

const CertifiedPayrollLabel = ({ field }) => createLabel('Certified Payroll', field.value);

function LocationView({ field }) {
  const { latitude, longitude } = field.value || {};
  if (!latitude && !longitude) return null;
  return (
    <MapViewStatic
      coordinates={{
        latitude,
        longitude
      }}
      dimensions={{ height: 160 }}
    />
  );
}

const onSAChange = (selected, form, serviceAgreements) => {
  const selectedSA = selected && serviceAgreements.find(item => item.id === selected.value);
  if (selectedSA?.pricebookId) {
    form.setFieldValue('priceBookId', selectedSA.pricebookId);
  } else {
    form.setFieldValue('pricebookId', Context.getCompanyContext().getCompany?.defaultPriceBookId);
  }
};

// Retreive all the dropdown options for each form field in the main form (i.e. not the sidebar).
// This means getting all Job Types, Departments, Project Managers, and Priority Settings for the tenant.
function getMainFormOptions(
  jobData,
  serviceAgreements,
  tenantSettings,
  sageJobs,
  activeEmployees,
  accountManagerList,
  flags
) {
  const { departments, jobTypes, priceBooks } = Context.getCompanyContext()?.getCompany || {};
  if (jobTypes)
    jobTypes.items = _.sortBy(
      jobTypes.items?.filter(type => type.tagType === CustomFieldTypes.JobTypes),
      'sortOrder'
    );
  const forceCertifiedPayrollJobs = isTenantSettingEnabled('forceCertifiedPayrollJobs');
  const certifiedPayrollJobs = isTenantSettingEnabled('certifiedPayrollJobs');
  const jobTypeDropdown = constructSelectOptions(jobTypes?.items, 'tagName');
  const departmentDropdown = constructSelectOptions(departments?.items, 'tagName');
  const activeSalesEmployees = activeEmployees.filter(employee => employee.isSales);
  const projectManagerDropdown = constructSelectOptions(activeSalesEmployees, 'name');
  const soldByDropdown = constructSelectOptions(activeEmployees, 'name');
  const accountManagerDropdown = constructSelectOptions(accountManagerList, 'name', 'id');

  // Priority is stored as string field on job as raw string (e.g. "Urgent"), so display name is same as value passed to backend
  const priorityList = ['Urgent', 'High', 'Normal', 'Low'].join(',');

  const serviceAgreementList = serviceAgreements
    .filter(item => {
      const properties = JSON.parse(item.propertiesJSON) || [];
      const propertyIdsInSA = properties.map(property => property.value);
      return (
        item.status === ServiceAgreementStatus.ACTIVE &&
        item.customerId === jobData.customerId &&
        propertyIdsInSA.includes(jobData.customerPropertyId)
      );
    })
    .map(item => ({
      label: `${item.agreementNumber}-${item.agreementName}`,
      value: item.id
    }));

  const pricebookList = constructSelectOptions(priceBooks?.items);
  const sageJobsList = constructSelectOptions(sageJobs, 'code');
  const procurementStatusList = [
    {
      label: 'POs Needed',
      value: 'POs Needed'
    }
  ];
  return {
    jobTypeList: jobTypeDropdown,
    departmentList: departmentDropdown,
    projectManagerList: projectManagerDropdown,
    soldByList: soldByDropdown,
    accountManagerList: accountManagerDropdown,
    priorityList,
    serviceAgreementList,
    priceBooks: pricebookList,
    sageJobs: sageJobsList,
    flags,
    isSageEnabled: tenantSettings.accountingApp === AccountingApp.SAGE,
    isSpectrumEnabled: tenantSettings.accountingApp === AccountingApp.SPECTRUM,
    isVistaEnabled: tenantSettings.accountingApp === AccountingApp.VISTA,
    onSAChange: (selected, form) => onSAChange(selected, form, serviceAgreements),
    showTotalBudgetedHours: tenantSettings.budgetLaborHoursAtJobLevel,
    showCertifiedPayroll: certifiedPayrollJobs,
    showJobProcurementStatus: flags[FeatureFlags.JOB_PROCUREMENT_STATUS],
    procurementStatusList,
    forceCertifiedPayrollJobs
  };
}

function JobDetail(props) {
  // Job number is a unique identifier for this job. It can be used in the same way as an entity id (sortKey in DynamoDB).
  // Job number is extracted from the page route.

  const { id: jobNumberEncoded, mode } = props.computedMatch?.params || {};
  const jobNumber = decodeURIComponent(jobNumberEncoded);

  const { snackbar, user, settings, history, classes } = props;

  const flags = useFlags();

  const [jobData, setJobData] = useState({});
  const [priceBooks, setPriceBooks] = useState([]);
  const [inlineForm, setInlineForm] = useState({});
  const [hasLoaded, setHasLoaded] = useState(false);
  const [customerRepModal, setCustomerRepModal] = useState(false);
  const [newRep, setNewRep] = useState({});
  const [jobSubscription, setJobSubscription] = useState(null);
  const jobsVisitRef = useRef({ visits: null });
  const [visits, setVisits] = useState([]);
  const displayJobNumber = jobData?.customIdentifier || jobData?.jobNumber || '';
  const [serviceAgreements, setServiceAgreements] = useState([]);
  const [sageJobs, setSageJobs] = useState([]);
  const [currentProperty, setCurrentProperty] = useState({});

  const [currentCustomerReps, setCurrentCustomerReps] = useState([]);

  const [currentPropertyRepList, setCurrentPropertyRepList] = useState([]);
  const [accountManagerList, setAccountManagerList] = useState([]);
  const [currentAuthorizedByList, setCurrentAuthorizedByList] = useState([]);

  const [updateBillingStatus] = useUpdateJob();
  const dispatch = useDispatch();

  const [activeEmployees] = useEmployees({ filter: { isActive: { eq: true } } });
  const theme = useTheme();

  const jobCloseoutType = useMemo(() => getJobCloseoutType(jobData), [jobData]);

  document.title = `BuildOps - Job #${displayJobNumber}`;

  const [jobStatusService, jobStatusActions, actionButtons] = useJobStatusHelper({
    job: jobData,
    visits
  });

  const setMode = useCallback(
    newMode => {
      if (!jobData.sortKey || !jobData.customerSortKey) return;
      history.push(`/job/${newMode === 'edit' ? 'edit' : 'view'}/${jobNumber}`);
    },
    [history, jobData, jobNumber]
  );

  const isOverrideQuotedAmount =
    Boolean(jobData.quoteJobs?.items?.length) &&
    (flags[FeatureFlags.JOB_CLOSEOUT] || jobData.closeoutReport);

  const fetchServiceAgreements = useCallback(async () => {
    if (!jobData.customerId) return;
    try {
      const response = await new CommonService().getQuery(
        {
          partitionKey: user.tenantId,
          sortKey: `${user.tenantId}_Company_${user.tenantCompanyId}`,
          customerId: jobData.customerId
        },
        getActiveServiceAgreementsForCustomerQuery
      );
      const serviceAgreementList = response?.data?.getCompany?.serviceAgreements?.items || [];
      setServiceAgreements(serviceAgreementList);
    } catch (error) {
      Logger.error(error);
    }
  }, [user, jobData.customerId]);

  const getAccountManagerList = customerProperty => {
    const propertyTenantReps = (customerProperty?.tenantReps?.items || []).map(
      rep => rep.mappedEntity
    );

    const customerTenantReps = (customerProperty?.parentEntity?.tenantReps?.items || []).map(
      rep => rep.mappedEntity
    );
    return _.uniqBy(propertyTenantReps.concat(customerTenantReps), 'id');
  };

  const fetchCurrentProperty = useCallback(
    async customerPropertyId => {
      try {
        const propertyResponse = await new CustomerPropertyService().getCustomerPropertyInfoById(
          customerPropertyId
        );
        const localAccountManagerList = getAccountManagerList(
          propertyResponse?.data?.getCustomerPropertyById
        );
        setAccountManagerList(localAccountManagerList);

        setCurrentProperty(propertyResponse?.data?.getCustomerPropertyById || {});
        return propertyResponse;
      } catch (error) {
        Logger.error(error);
        snackbar('error', getFetchErrorMessage('property'), error);
      }
    },
    [snackbar]
  );

  const authorizedByDropdownFromReps = reps =>
    reps.map(rep => ({
      label: rep.name,
      value: rep.id
    }));

  const getAuthorizedByList = inJobData => {
    const { customer, billingCustomer } = inJobData;

    const customerReps = customer?.customerReps?.items || [];
    setCurrentCustomerReps(customerReps);

    const reps = [...customerReps];
    if (customer?.id !== billingCustomer?.id) {
      const billingCustomerReps = billingCustomer?.customerReps?.items || [];
      reps.push(...billingCustomerReps);
    }

    return authorizedByDropdownFromReps(_.uniqBy(reps, 'id'));
  };

  const getPropertyRepList = inJobData =>
    (inJobData?.customerProperty?.customerReps?.items || []).map(rep => ({
      label: rep.mappedEntity?.name,
      value: rep.mappedEntity?.id
    }));

  const fetchJobData = useCallback(
    async (number, isRefetch = false, callback) => {
      try {
        dispatch(startGetJob());
        const { data } = await new JobService().getJobDetailsInfoByJobNumber(`${number}`);
        const priceBookResponse = await new CompanyService().getDefaultPricebookForCompany(
          user.tenantId,
          `${user.tenantId}_Company_${user.tenantCompanyId}`
        );
        if (priceBookResponse?.data) {
          const rawResponse = priceBookResponse.data.getCompany.priceBooks.items;
          setPriceBooks(rawResponse?.map(elem => ({ ...elem, label: elem.name, value: elem.id })));
        }

        const jobDataByNumber = data.getJobByJobNumber;
        dispatch(startGetJobSuccess(jobDataByNumber));
        setJobData(jobDataByNumber);

        const authorizedByList = getAuthorizedByList(jobDataByNumber);
        setCurrentAuthorizedByList(authorizedByList);

        const propertyRepList = getPropertyRepList(jobDataByNumber);
        setCurrentPropertyRepList(propertyRepList);

        await fetchCurrentProperty(jobDataByNumber?.customerPropertyId);
        if (callback) callback(jobDataByNumber);
        setHasLoaded(true);
      } catch (error) {
        Logger.error(error);
        dispatch(startGetJobFailure(error));
        // No need to notify the user so forcefully that a background data fetch failed
        if (!isRefetch) {
          snackbar('error', getFetchErrorMessage('data', number), error);
        }
      }
    },
    [snackbar, setJobData, user, setPriceBooks]
  );

  // Custom inline form can be created from form builder and be associate with job. For e.g.: Holmes will have inline forms
  const fetchInlineForm = useCallback(
    async number => {
      const formWithData = {};
      try {
        const { data } = await new CommonService().getFormsAvailableToEntity(
          user.tenantId,
          `${user.tenantId}_Company_${user.tenantCompanyId}`,
          FormEntityType.JOB
        );
        const queryResult = data?.getCompany?.forms?.items;
        const forms = queryResult.filter(item => item?.viewType === 'Inline');
        if (forms) {
          const formMeta = forms?.[0]?.latestPublishedFormDefinition?.formDefinitionJson || '{}';
          const response = await new JobService().getFormDataByJobNumber(`${number}`);
          formWithData.data = response?.data?.getJobByJobNumber?.formData?.items?.[0] || {};
          formWithData.formMeta = convertInlineForm(JSON.parse(formMeta));
          formWithData.form = forms?.[0];
        }
        setInlineForm(formWithData);
      } catch (error) {
        Logger.error(error);
        snackbar('error', getFetchErrorMessage('custom form data', number), error);
      }
    },
    [snackbar, user]
  );

  const updateJob = useCallback(
    async formJobData => {
      const formattedExistingData = formatExistingDataForJobUpdate(jobData);
      const formattedFormData = formatFormDataForJobUpdate(formJobData);
      formattedFormData.closeoutReport = jobData?.closeoutReport;
      formattedFormData.invoiceTotal = jobData?.invoices?.items?.reduce((acc, currentInvoice) => {
        if (currentInvoice?.status !== 'void') {
          return acc + currentInvoice?.totalAmount;
        }
        return acc;
      }, 0);
      const additionalPayload = getPropertyRepresentative(jobData, formJobData);
      const customFormData = formatCustomFormDataForJobUpdate(formJobData, inlineForm?.form) || {};
      const finalPayload = combineDataForJobUpdate(
        formattedExistingData,
        formattedFormData,
        additionalPayload,
        customFormData
      );
      try {
        if (
          formattedFormData.closeoutReport &&
          jobCloseoutType === JobCloseoutTypes.QUOTED &&
          formattedFormData.amountQuoted < formattedFormData.invoiceTotal
        ) {
          snackbar(
            'error',
            `Amount quoted cannot be lower than invoiced. Current invoiced amount: ${convertToCurrencyString(
              formattedFormData?.invoiceTotal
            )}`
          );
          return;
        }
        setHasLoaded(false);
        const service = new JobService();
        const response = await service.updateJobAndRelated(user.tenantId, finalPayload);
        // Don't try to delete M2M department/job connections unless rest of update succeeded.
        // It would be unintuitive to the user to successfully handle department deletions but not additions in some cases.
        if (!response?.data?.updateJobAndRelated?.[0]?.id) {
          throw new Error(
            `Failed to update Job ${jobNumber} with GraphQL error: ${JSON.stringify(
              response?.errors
            )}`
          );
        } else {
          if (formattedFormData.amountQuoted && jobCloseoutType === JobCloseoutTypes.QUOTED) {
            if (
              jobData?.billingStatus === JobBillingStatus.FULLY_INVOICED &&
              formattedFormData.amountQuoted !== formattedExistingData.amountQuoted
            ) {
              updateBillingStatus({
                id: jobData?.id,
                version: jobData?.version,
                billingStatus: JobBillingStatus.PARTIALLY_INVOICED
              });
            }
            if (
              jobData?.billingStatus === JobBillingStatus.PARTIALLY_INVOICED &&
              formattedFormData.amountQuoted === formattedFormData.invoiceTotal
            ) {
              updateBillingStatus({
                id: jobData?.id,
                version: jobData?.version,
                billingStatus: JobBillingStatus.FULLY_INVOICED
              });
            }
          }
          snackbar('success', `Successfully updated Job ${jobNumber}.`);
          setMode('view');
          setHasLoaded(true);
        }
      } catch (error) {
        Logger.error(error);
        snackbar('error', `Unable to update Job ${jobNumber}. Please try again.`, error);
      }
    },
    [inlineForm, jobData, user.tenantId, jobNumber, snackbar, setMode]
  );

  const fetchSageJobs = useCallback(async () => {
    try {
      const activeSageJobs = await getSageJobs(user);
      setSageJobs(activeSageJobs);
    } catch (error) {
      Logger.error(error);
    }
  }, [user]);

  // Job data initial fetch effect.
  useEffect(() => {
    if (!user?.tenantId) return;
    fetchJobData(jobNumber);
    fetchInlineForm(jobNumber);
    fetchServiceAgreements();
    if (settings.accountingApp === AccountingApp.SAGE) {
      fetchSageJobs();
    }
  }, [jobNumber, fetchJobData, fetchInlineForm, user, fetchServiceAgreements, fetchSageJobs]);

  // Job data subscription effect.
  // TODO: Add 'notes' and 'attachments' entity connections to this subscription in backend so that the subscription
  // fires when either of those are updated. In the old jobs page job notes, job attachments, and job data were handled
  // with three separate subscriptions. (See JobService.jobNotesUpdated and CustomerPropertyService.propertyNoteUpdated).
  useEffect(() => {
    // Sub-optimal because the subscription notification is fired when *any* job is updated.
    // Then we have to check if it was our job that indeed was updated. As app usage scales this becomes less and less likely,
    // resulting in wasted network traffic.
    // TODO: Implement subscriptions to specific jobs.
    async function subscribeToAllJobs() {
      try {
        const subscription = (await new JobService().jobUpdated(user.tenantId)).subscribe(
          result => {
            // Ensure that the job we're currently viewing was the one that fired the subscription notification
            if (result?.data?.jobUpdateNotification?.jobNumber === jobNumber) {
              fetchJobData(jobNumber, true);
            }
          }
        );
        setJobSubscription(subscription);
      } catch (error) {
        Logger.error('Unable to subscribe job updates');
      }
    }
    // This useEffect routine needs to run twice because all useEffect routines are synchronous. On the first run,
    // we fire the asynchronous `subscribeToAllJobs` call. Within `subscribeToAllJobs`, after the subscription is
    // established, it is stored in state. Due to the dependency array this prompts the routine to run again,
    // which we need in order to pass the right cleanup / unsubscribe function return value. However,
    // we don't want to re-subscribe on the second run since this would cause an infinite loop.
    if (!jobSubscription && user.tenantId) {
      subscribeToAllJobs();
    }

    return () => {
      if (jobSubscription) {
        jobSubscription.unsubscribe();
      }
    };
  }, [jobNumber, fetchJobData, jobSubscription, user.tenantId]);

  const shouldDisallowEditing =
    jobData?.status === JobStatus.COMPLETE || jobData?.status === JobStatus.CANCELED;
  // Disallow editing when job is complete or cancelled
  useEffect(() => {
    if (mode === 'edit' && shouldDisallowEditing) {
      setMode('view');
    }
  }, [shouldDisallowEditing, mode, setMode]);

  // Div wrapper needed for tooltip to work properly: https://stackoverflow.com/questions/48930334/mui-next-tooltip-does-not-show-on-hover

  const customHeaderButtons = ({
    handleSubmitStart,
    isSubmitting,
    shouldDisallowEditing: disableEditting
  }) => {
    const headerButtons = [
      <Tooltip
        title={disableEditting ? 'Cannot edit job that has been completed or cancelled.' : ''}
        placement="left"
      >
        <ThemeProvider>
          <div>
            {mode === 'edit' && (
              <Button
                type={ButtonType.TERTIARY}
                onClick={() => setMode('view')}
                css={{ marginRight: 8 }}
              >
                Cancel
              </Button>
            )}
            <UserPermission I="edit" action={PermissionConstants.OBJECT_JOB}>
              <Button
                type={mode === 'edit' ? ButtonType.PRIMARY : ButtonType.TERTIARY}
                disabled={isSubmitting || disableEditting}
                loading={isSubmitting}
                css={{ marginRight: 8 }}
                onClick={() => {
                  if (mode === 'edit') {
                    handleSubmitStart();
                  } else {
                    // mode === 'view'
                    setMode('edit');
                  }
                }}
              >
                {mode === 'edit' ? 'Submit' : 'Edit'}
              </Button>
            </UserPermission>
            {jobData.closeoutReport && (
              <Link
                to={Routes.jobCloseout({
                  jobid: jobData.id,
                  jobCloseoutType
                })}
              >
                <Button type="secondary">Open Job Report</Button>
              </Link>
            )}
          </div>
        </ThemeProvider>
      </Tooltip>,
      <UserPermission I="edit" action={PermissionConstants.OBJECT_JOB}>
        <JobStatusButtons
          job={{
            id: jobData.id,
            version: jobData.version,
            status: jobData.status
          }}
          jobsVisitRef={jobsVisitRef}
          visits={visits}
          customerPropertyStatus={jobData?.customerProperty?.status}
          updateStatus={data =>
            setJobData({ ...jobData, status: data.status, version: data.version })
          }
          key="jobDetailHeaderStatusButtons"
        />
      </UserPermission>
    ];

    return headerButtons;
  };

  const handleSearchChange = async (selectedItem, selectedItemName, form, field) => {
    const billingCustomerId = selectedItem.parentId;

    // Include additional data from `searchData` (e.g. customerId) not rendered in any form field;
    // this data is required for a job update.
    form.setValues({
      ...form.values,
      // Include because it is required for updating the job backend with the proper entity connection.
      billingCustomerId,
      [field.name]: selectedItem.customerName
    });

    if (!billingCustomerId) {
      setCurrentAuthorizedByList(authorizedByDropdownFromReps(currentCustomerReps));
      return;
    }

    const customerResponse = await new CustomerService().getCustomerRepsByCustomerById(
      billingCustomerId
    );
    const newBillingCustomerReps =
      customerResponse.data?.getCustomerById?.customerReps?.items || [];

    const allReps = _.uniqBy([...currentCustomerReps, ...newBillingCustomerReps], 'id');
    const authorizedBy = authorizedByDropdownFromReps(allReps);
    setCurrentAuthorizedByList(authorizedBy);
  };

  const handlePropertyChange = async (selectedItem, selectedItemName, form, field) => {
    form.setFieldValue([field.name], selectedItem.customerPropertyName);
    if (!selectedItem) return;

    const propertyResponse = await fetchCurrentProperty(selectedItem.parentId);
    if (!propertyResponse) return;

    const selectedProperty = propertyResponse.data.getCustomerPropertyById;
    const propertyAddress = selectedProperty.companyAddresses.items.find(
      address => address.addressType === 'propertyAddress'
    );

    form.setValues({
      ...form.values,
      address: addressObjectToString(propertyAddress),
      authorizedBy: '',
      bestContactMethod: '',
      billingCustomerId: selectedProperty.billingCustomerId,
      billingCustomer: selectedProperty?.billingCustomer?.customerName,
      customer: {
        label: selectedProperty?.parentEntity.customerName,
        path: `/customer/view/${selectedProperty?.parentEntity?.id}`
      },
      customerId: selectedProperty?.parentEntity?.id,
      customerSortKey: selectedProperty?.parentEntity?.sortKey,
      location: {
        latitude: propertyAddress?.latitude,
        longitude: propertyAddress?.longitude
      },
      propertyInstructions: [],
      propertyRepresentative: '',
      [field.name]: selectedItem.customerPropertyName,
      propertySortKey: selectedItem.parentSortKey,
      propertyId: selectedItem.parentId
    });

    // Use customer pb associated with updated property if available
    setJobData(prev => ({
      ...prev,
      priceBookId:
        selectedProperty?.parentEntity?.priceBook?.id ||
        Context.getCompanyContext().getCompany?.defaultPriceBookId
    }));
  };

  const handlePropertyNote = useCallback(
    async noteObj => {
      const commonServiceObj = new CommonService();
      const customerPropertyServiceObj = new CustomerPropertyService();
      const payload = {
        customerPropertyId: jobData?.customerProperty?.id,
        notes: [
          {
            note: noteObj.note,
            subject: noteObj.subject
          }
        ]
      };
      if (noteObj?.id) {
        return commonServiceObj.updateNote(user.tenantId, noteObj);
      }

      return customerPropertyServiceObj.addNotesToCustomerProperty(user.tenantId, payload);
    },
    [jobData, user.tenantId]
  );

  const handleJobNote = useCallback(
    async noteObj => {
      const jobServiceObj = new JobService();
      const commonServiceObj = new CommonService();
      const payload = {
        jobId: jobData?.id,
        notes: [
          {
            note: noteObj.note,
            subject: noteObj.subject
          }
        ]
      };
      if (noteObj?.id) {
        return commonServiceObj.updateNote(user.tenantId, noteObj);
      }

      return jobServiceObj.addNotesToJob(user.tenantId, payload);
    },
    [jobData, user.tenantId]
  );

  const jobNotesV2 = useCallback(
    formProps => {
      const currentJob = useSelector(state => state.jobs.currentJob);
      return (
        <NotesV2
          title={Labels.jobNotes[user.locale]}
          linkName={Labels.viewAllJobNotesV2[user.locale]}
          allNotesFor={`Job #${displayJobNumber}`}
          notesData={formProps?.displayValue || []}
          parent={{ ...currentJob, name: currentJob.customIdentifier || currentJob.jobNumber }}
          refetch={async () => fetchJobData(currentJob.jobNumber)}
        />
      );
    },
    [displayJobNumber, fetchJobData, handleJobNote, jobData, user.locale]
  );

  const propertyNotes = useCallback(
    formProps => (
      <Notes
        title={Labels.propertyNotes[user.locale]}
        subtitle={Labels.notesOnProperty[user.locale]}
        linkName={Labels.viewEditAllPropertyNotes[user.locale]}
        allNotesFor={jobData?.customerPropertyName}
        notesData={formProps?.displayValue || []}
        parent={jobData?.customerProperty}
        mutateService={handlePropertyNote}
        refetch={async () => fetchJobData(jobData.jobNumber)}
      />
    ),
    [fetchJobData, handlePropertyNote, jobData, user.locale]
  );

  const handleStatusChange = async status => {
    await updateBillingStatus({
      id: jobData?.id,
      version: jobData?.version,
      ...status
    });
  };

  const restoreStatusLabel = () => (
    <Typography variant={TV.S2} weight={TW.MEDIUM} style={{ marginBottom: theme.spacing(0.5) }}>
      Restore automated status
    </Typography>
  );

  const jobStatusOptions = useMemo(
    () =>
      actionButtons &&
      uniq(
        Object.values(actionButtons)
          ?.map(btn => jobStatusActions.includes(btn.actionConstant) && btn.targetStatus)
          ?.filter(Boolean)
      ),
    [actionButtons, jobStatusActions]
  );

  const deriveJobStatus = async statusObj => {
    const actionButton = Object.values(actionButtons).find(
      btn => btn.targetStatus === statusObj.status
    );

    let currentState;
    try {
      currentState = await jobStatusService.send(actionButton.actionConstant);
    } catch (e) {
      Logger.error(e);
      snackbar('warning', actionButton.errorMessage);
    }
    return currentState?.value;
  };

  const getAdditionalStatusLabels = () => {
    // Use Manual status or get automated status
    const automatedQuoteLabel = getQuoteStatus(jobData?.quoteJobs?.items);
    const quoteStatusLabel = jobData.manualQuoteStatus || automatedQuoteLabel;
    const automatedProcurementLabel = getProcurementStatus(jobData?.purchaseOrders?.items);
    const procurementStatusLabel = jobData.procurementStatus || automatedProcurementLabel;
    return (
      <>
        <StatusButtons
          enumType={EnumType.JOB_STATUS}
          value={jobData.status}
          deriveStatus={deriveJobStatus}
          overrideOptions={jobStatusOptions}
          actionCallback={data => {
            if (data) setJobData(prev => ({ ...prev, status: data.status, version: data.version }));
          }}
          jobData={jobData}
          useStatusLabelMap
        />
        {flags[FeatureFlags.JOB_QUOTE_STATUS] && (
          <StatusButtons
            enumType={EnumType.JOB_QUOTE_STATUS}
            value={quoteStatusLabel}
            overrideOptions={[
              jobData.manualQuoteStatus ? automatedQuoteLabel : jobQuoteStatus.QUOTE_NEEDED
            ]}
            overrideHandleStatusChange={() =>
              handleStatusChange({
                manualQuoteStatus: jobData.manualQuoteStatus ? null : jobQuoteStatus.QUOTE_NEEDED
              })
            }
            tooltipContent={generateQuoteStatusTooltip(jobData?.quoteJobs?.items, theme)}
            jobData={jobData}
            statusCue={jobData.manualQuoteStatus && restoreStatusLabel()}
            noLabelFormat
          />
        )}
        {flags[FeatureFlags.JOB_PROCUREMENT_STATUS] && (
          <StatusButtons
            enumType={EnumType.JOB_PROCUREMENT_STATUS}
            value={procurementStatusLabel}
            overrideOptions={[
              jobData.procurementStatus
                ? automatedProcurementLabel
                : JobProcurementStatus.POS_NEEDED
            ]}
            overrideHandleStatusChange={() =>
              handleStatusChange({
                procurementStatus: jobData.procurementStatus
                  ? null
                  : JobProcurementStatus.POS_NEEDED
              })
            }
            jobData={jobData}
            statusCue={jobData.procurementStatus && restoreStatusLabel()}
            tooltipContent={generateProcurementStatusTooltip(jobData?.purchaseOrders?.items, theme)}
            noLabelFormat
          />
        )}
      </>
    );
  };

  const handleCloseRepsPopUp = async popUpData => {
    if (!popUpData) {
      setCustomerRepModal(false);
      return;
    }
    // Get data with the newly created Reps and optimistically
    // update the form
    await fetchJobData(jobNumber, true, latestData => {
      const updatedJobData = { ...latestData };
      const { addCustomerRepToCustomer } = popUpData;
      const addedRep = addCustomerRepToCustomer.pop();
      const fieldName = newRep.field.name;
      if (fieldName === 'propertyRepresentative') {
        updatedJobData.customerRep = addedRep;
        updatedJobData.propertyRepresentative = addedRep.id;
      }
      if (fieldName === 'authorizedBy') {
        updatedJobData.authorizedBy = addedRep;
        updatedJobData.authorizedById = addedRep.id;
      }
      setJobData(updatedJobData);
      setCustomerRepModal(false);
    });
  };

  const configuration = jobSidebarLayout({
    authorizedByList: currentAuthorizedByList,
    propertyRepList: currentPropertyRepList,
    onSearchChange: handleSearchChange,
    onCreatePropRep: newSelectedOption => {
      setNewRep(newSelectedOption);
      setCustomerRepModal(true);
    },
    onPropertySearchChange: handlePropertyChange,
    user,
    disableEditAmountQuoted:
      jobData?.billingStatus === JobBillingStatus.FULLY_INVOICED ? false : isOverrideQuotedAmount,
    disableEditCostAmount:
      jobData?.closeoutReport &&
      jobData?.invoices?.items?.find(({ status }) => status !== InvoiceStatus.VOID)
  });

  const serviceChannelButton =
    flags[FeatureFlags.SERVICE_CHANNEL_INTEGRATION] &&
    jobData.serviceChannelWorkOrder?.workOrderId ? (
      <ServiceChannelButton
        serviceChannelWorkOrderNumber={jobData.serviceChannelWorkOrder.workOrderId}
      />
    ) : null;

  const formatReviewStatusText = reviewStatus => {
    return reviewStatus?.replaceAll(' ', '_')?.toUpperCase();
  };

  return (
    <UserPermission I="read" action={PermissionConstants.OBJECT_JOB}>
      <LeftSidebarWithContent
        customHeaderButtons={customHeaderButtons}
        TagButtons={
          <TagButtons
            info={{
              id: jobData?.id,
              version: jobData?.version,
              customerPropertyId: jobData?.customerPropertyId,
              departments: formatExistingDepartmentsForUpdate(jobData?.departments?.items)
            }}
            tags={jobData?.jobJobTags?.items}
            getService={() => new JobService()}
            TagType={TagType.JOB}
          />
        }
        headerProps={{
          breadcrumbsArray: [
            { link: '', title: 'Operations' },
            { link: '/job/list', title: Labels.jobs[user.locale] }
          ],
          caslKey: [PermissionConstants.OBJECT_JOB],
          pageMapKey: 'jobDetail',
          userLocale: user.locale,
          title: `Job: ${displayJobNumber}`,
          additionalStatusLabels: [
            getAdditionalStatusLabels(),
            jobData.closeoutReport && jobData.reviewStatus && (
              <StatusChip
                label={JOB_CLOSEOUT_STATUS[formatReviewStatusText(jobData?.reviewStatus)]}
                enumType={EnumType.JOB_CLOSEOUT_STATUS}
                enumValue={JOB_CLOSEOUT_STATUS[formatReviewStatusText(jobData?.reviewStatus)]}
                showIcon
                css={{
                  borderRadius: 2
                }}
              />
            ),
            jobData.closeoutReport && <JobBillingStatusChip job={jobData} readOnly />
          ].filter(chip => chip)
        }}
        mode={mode}
        loadingParams={{
          leftSection: {
            variant: 'table',
            repeatCount: 5,
            paddingTop: 12,
            paddingLeft: 8,
            paddingRight: 8
          },
          mainSection: {
            paddingLeft: 24,
            paddingTop: 24
          }
        }}
        isLoading={!hasLoaded}
        leftSectionProps={{
          configuration,
          data: formatJobDataForSidebarForm(jobData, mode, currentProperty),
          customComponents: {
            LocationView,
            LinkButton: LinkButtonForm,
            AlgoliaSelect,
            Notes: propertyNotes
          }
        }}
        mainSectionProps={{
          configuration: useMemo(
            () =>
              jobMainLayout(
                {
                  // Material UI withWidth HOC prop
                  width: props.width,
                  ...getMainFormOptions(
                    jobData,
                    serviceAgreements,
                    settings,
                    sageJobs,
                    activeEmployees,
                    accountManagerList,
                    flags
                  ),
                  user
                },
                inlineForm?.formMeta || {}
              ),
            [user, sageJobs, jobData, inlineForm, accountManagerList]
          ),
          data: useMemo(
            () => formatJobDataForMainForm(jobData, mode, inlineForm?.data, priceBooks),
            [jobData, inlineForm?.data, priceBooks, mode]
          ),
          customComponents: {
            Notes: jobNotesV2,
            DetailedJobCostingLabel,
            CertifiedPayrollLabel,
            LinkButton: LinkButtonForm,
            AlgoliaSelect
          }
        }}
        shouldDisallowEditing={shouldDisallowEditing}
        handleFormsSubmit={updateJob}
        caslKey={PermissionConstants.OBJECT_JOB}
        ServiceChannelButton={serviceChannelButton}
      >
        <>
          <div className={classes.tabsContainer}>
            <JobTabs
              jobData={jobData}
              jobsVisitRef={jobsVisitRef}
              visits={visits}
              setVisits={setVisits}
              hasLoaded={hasLoaded}
              shouldDisallowEditing={shouldDisallowEditing}
              currentProperty={currentProperty}
            />
          </div>
          <div className={classes.activityContainer}>
            <ErrorBoundaries>
              <Typography variant="h4" className={classes.activityTitle}>
                Activity
              </Typography>
              <AuditLogs
                dataService={() => new JobService().getAllAuditLogByJobNumber(jobNumber)}
              />
            </ErrorBoundaries>
          </div>
          {jobData?.customerSortKey && (
            <CustomerRep
              open={customerRepModal}
              mode="new"
              data={{ firstName: newRep?.value }}
              validationSchema={validations.customerRepSchema}
              parent={{
                id: (() => {
                  const temp = jobData.customerSortKey.split('_');
                  return temp[temp.length - 1];
                })(),
                sortKey: jobData.customerSortKey,
                customerName: jobData.customerName,
                tenantId: user.tenantId,
                tenantCompanyId: user.tenantCompanyId,
                hierarchy: `${user.tenantId}_${user.tenantCompanyId}`,
                entityType: 'Customer',
                partitionKey: user.tenantId,
                customerPropertyId:
                  newRep?.field?.name === 'propertyRepresentative' ? jobData.customerPropertyId : ''
              }}
              layout={CustomerRepLayout}
              repType={RepType.CUSTOMER}
              handleClose={(flag, data) => {
                handleCloseRepsPopUp(data);
              }}
            />
          )}
        </>
      </LeftSidebarWithContent>
    </UserPermission>
  );
}

const Styled = withStyles(styles)(JobDetail);
export default connect(state => ({ user: state.user, settings: state.settings }), {
  snackbar: snackbarOn
})(Styled);
