const taskMaterialsHeader = (
  settingsJSON,
  SettingConstants,
  materialToggles,
  styles,
  fontFamilyStyle
) =>
  materialToggles.some(toggle => settingsJSON.Tasks.Material[toggle])
    ? `<tr>
      <td colspan="2" style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;">
        <span fontfamily=${fontFamilyStyle} class="text-small"><strong>${
        settingsJSON.Tasks.Material[SettingConstants.SHOW_ITEMIZED_MATERIAL_ITEMS] ? 'Material' : ''
      }</strong></span>
      </td>
      <td style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;text-align:right;">
        <span fontfamily=${fontFamilyStyle}  class="text-small"><strong>${
        settingsJSON.Tasks.Material[SettingConstants.SHOW_ITEMIZED_MATERIAL_QUANTITY] ? 'Qty' : ''
      }</strong></span>
      </td>
      <td style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;text-align:right;">
        <span fontfamily=${fontFamilyStyle} class="text-small"><strong>${
        settingsJSON.Tasks.Material[SettingConstants.SHOW_ITEMIZED_MATERIAL_ITEM_PRICING]
          ? 'Unit Price'
          : ''
      }</strong></span>
      </td>
      <td style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;text-align:right;">
        <span fontfamily=${fontFamilyStyle} class="text-small"><strong>${
        settingsJSON.Tasks.Material[SettingConstants.SHOW_ITEMIZED_MATERIAL_ITEM_PRICING]
          ? 'Subtotal'
          : ''
      }</strong></span>
      </td>
    </tr>`
    : ``;

export default taskMaterialsHeader;
