import gql from 'graphql-tag';

const approveTimesheetPeriod = gql`
  mutation approveTimesheetPeriod($partitionKey: String, $data: ApproveTimesheetPeriodInput!) {
    approveTimesheetPeriod(partitionKey: $partitionKey, data: $data) {
      id
      version
    }
  }
`;

export default approveTimesheetPeriod;
