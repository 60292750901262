import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Button, Snackbar, SnackbarContent } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import { styles, styles2 } from './styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  failure: ErrorIcon,
  info: InfoIcon
};

/**
 * Custom error message that popus at top center of the page
 *
 * TODO: to align with vicky on positioning of the box
 */
function MySnackbarContent(props) {
  const { classes, className, message, errorLog, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <>
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />

            {message &&
              message.split('\n').map((line, index) => (
                <Fragment key={`fragement${index}`}>
                  {line}
                  <br key={`line${index}`} />
                </Fragment>
              ))}

            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={onClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          </span>
          {errorLog && (
            <Button
              style={{
                color: 'gray',
                float: 'right'
              }}
              onClick={e => {
                const textField = document.createElement('textarea');
                textField.innerText = JSON.stringify(
                  errorLog,
                  Object.getOwnPropertyNames(errorLog)
                );
                document.body.appendChild(textField);
                textField.select();
                document.execCommand('copy');
                textField.remove();
                e.preventDefault();
                e.target.innerHTML = 'Copied';
                e.target.disabled = true;
              }}
            >
              Copy Error
            </Button>
          )}
        </>
      }
      // action={[<></>]}
      {...other}
    />
  );
}
const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

class CustomizedSnackbars extends React.Component {
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.props.closeAction();
  };

  render() {
    const mode = this.props.renderProps[0];
    if (mode === 'off') return null;
    const message = this.props.renderProps[1];
    const errorLog = this.props.renderProps[2] || '';

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open
          autoHideDuration={mode === 'error' ? 4000 : 2000}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant={mode}
            message={message}
            errorLog={errorLog}
          />
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  renderProps: state.pageNotification
});
const connectedSnackbar = connect(mapStateToProps)(CustomizedSnackbars);
const StylizedSnackBar = withStyles(styles2)(connectedSnackbar);

export default StylizedSnackBar;
