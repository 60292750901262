import React from 'react';
// eslint-disable-next-line no-unused-vars
import { jsx } from '@emotion/react';
import { Typography, TW, TV, Button, Divider } from '@buildhero/sergeant';
import { useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import styles from './IntegrationCard.styles';

const TopHeader = ({ image, title, subtitle }) => {
  const { palette } = useTheme();

  return (
    <div css={styles.header.container}>
      <img alt={`${title} Logo`} src={image} css={styles.header.image} />
      <div css={styles.header.titleContainer}>
        <Typography weight={TW.BOLD}>{title}</Typography>
        <Typography weight={TW.MEDIUM} color={palette.text.secondary}>
          {subtitle}
        </Typography>
      </div>
    </div>
  );
};

const IntegrationCard = ({ loading, image, title, subtitle, description, buttonProps }) => {
  const { palette } = useTheme();

  return (
    <div css={styles.container}>
      <TopHeader image={image} title={title} subtitle={subtitle} />
      <Typography css={styles.description} variant={TV.S1} color={palette.text.secondary}>
        {description}
      </Typography>
      <div css={styles.footer.container}>
        <Divider />
        {loading ? <Skeleton height={30} variant="rect" /> : <Button fullWidth {...buttonProps} />}
      </div>
    </div>
  );
};

export default IntegrationCard;
