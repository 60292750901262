import * as React from 'react';
import { useMemo } from 'react';
import { DataGridPro as Grid } from '@mui/x-data-grid-pro';
import { connect } from 'react-redux';
import { ThemeProvider } from '@buildhero/sergeant';
import ErrorBoundaries from 'scenes/Error';
import { UserPermission } from 'components';
import { useBuildHeroTableStyles } from 'components/XGrid/style';
import { snackbarOn } from 'redux/actions/globalActions';
import { getMaterialMarkup, getTotalMarkup, getUnitPrice } from 'utils/pricebooks';
import { useTrigger } from '../../../../customHooks/useTrigger';
import { getColumns } from './gridMeta';
import { useProductListQuery } from './useProductListQuery';
import { PermissionConstants } from '../../../../utils/AppConstants';

const Pricebook2DetailGrid = ({
  user,
  snackbar,
  productRowButtons,
  handleActions,
  refreshCount,
  pricebook2,
  pricebook2EntryOverridesMap,
  onOpenEditItemModal
}) => {
  const tableClasses = useBuildHeroTableStyles();
  const [initialCount, setInitialCount] = React.useState(refreshCount);
  const [subscribe, trigger] = useTrigger();

  React.useEffect(() => {
    if (initialCount !== refreshCount) {
      trigger();
      setInitialCount(refreshCount);
    }
  }, [initialCount, refreshCount, trigger]);

  // (tenantId, filter, sorting, queryVariables)
  const { rowCount = 0, rows = [], loading, error, refetch } = useProductListQuery(user.tenantId);

  React.useEffect(() => {
    if (!loading) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribe]);

  React.useEffect(() => {
    if (error) snackbar(`error', 'Unable to fetch, please try again later`, error);
  }, [error, snackbar]);

  const computeMarkupValueColumn = (productId, productUnitCost) => {
    return getMaterialMarkup(pricebook2, pricebook2EntryOverridesMap, productId, productUnitCost);
  };

  const computeTotalMarkupValueColumn = (productId, productUnitCost) => {
    return (
      getTotalMarkup(pricebook2, pricebook2EntryOverridesMap, productId, productUnitCost) * 100
    );
  };

  const computeUnitPriceValueColumn = (productId, productUnitCost) => {
    return getUnitPrice(pricebook2, pricebook2EntryOverridesMap, productId, productUnitCost);
  };

  const showEditItem = row => {
    onOpenEditItemModal({
      ...row,
      pricebookMarkup: pricebook2.markupValue,
      materialMarkup: computeMarkupValueColumn(row.id, row.unitCost),
      markupValue: computeTotalMarkupValueColumn(row.id, row.unitCost),
      unitPrice: computeUnitPriceValueColumn(row.id, row.unitCost)
    });
  };

  const columnMeta = useMemo(
    () =>
      getColumns({
        productRowButtons,
        computeMarkupValueColumn,
        computeTotalMarkupValueColumn,
        computeUnitPriceValueColumn,
        showEditItem
      }),
    [handleActions, productRowButtons, pricebook2, pricebook2EntryOverridesMap]
  );

  return (
    <ErrorBoundaries>
      <UserPermission I="read" action={PermissionConstants.OBJECT_INVENTORTY}>
        <ThemeProvider>
          <div style={{ display: 'flex', height: '800px', width: '100%' }}>
            <Grid
              className={tableClasses.root}
              columns={columnMeta}
              rows={rows}
              loading={loading}
              rowCount={rowCount}
              refetchTrigger={subscribe}
              tableName="Items"
            />
          </div>
        </ThemeProvider>
      </UserPermission>
    </ErrorBoundaries>
  );
};

const mapStateToProps = state => ({
  user: state.user
});
const mapDispatchToProps = { snackbar: snackbarOn };

const connectedGrid = connect(mapStateToProps, mapDispatchToProps)(Pricebook2DetailGrid);

export default connectedGrid;
