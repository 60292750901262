const PurchaseOrderLayout = (departmentName, showFileName) => ({
  fields: {},
  layouts: {
    default: {
      options: {
        direction: 'row'
      },
      contents: [
        {
          options: {
            direction: 'column'
          },
          contents: [
            {
              options: {
                direction: 'row'
              },
              contents: [
                {
                  options: {
                    grow: 1,
                    padding: 12
                  },
                  contents: [
                    {
                      component: {
                        default: 'FileLink',
                        edit: 'FileUpload'
                      },
                      source: 'newFiles',
                      options: {
                        label: 'Attachment',
                        multiple: false,
                        accept: 'image/*'
                      }
                    }
                  ]
                },
                showFileName
                  ? {
                      options: {
                        grow: 1,
                        padding: 12,
                        justify: 'center',
                        align: 'center'
                      },
                      contents: [
                        {
                          component: {
                            default: 'FieldWithLabel',
                            edit: 'TextInput'
                          },
                          source: 'customFileName',
                          options: {
                            label: 'File Name'
                          }
                        }
                      ]
                    }
                  : { component: null }
              ]
            },
            {
              options: {
                direction: 'row'
              },
              contents: [
                {
                  options: {
                    grow: 1,
                    padding: 12
                  },
                  contents: [
                    {
                      component: {
                        default: 'FieldWithLabel',
                        edit: 'SelectInput'
                      },
                      source: 'departmentName',
                      options: {
                        label: 'Department',
                        disabled: true,
                        fullWidth: true,
                        alignment: 'left',
                        inputOptions: [
                          {
                            label: departmentName,
                            value: departmentName
                          }
                        ]
                      }
                    }
                  ]
                },
                {
                  options: {
                    grow: 1,
                    padding: 12
                  },
                  contents: [
                    {
                      component: {
                        default: 'FieldWithLabel',
                        edit: 'TextInput'
                      },
                      source: 'vendorName',
                      options: {
                        label: 'Vendor',
                        isRequired: true
                      }
                    }
                  ]
                },
                {
                  options: {
                    grow: 1,
                    padding: 12
                  },
                  contents: [
                    {
                      component: {
                        default: 'FieldWithLabel',
                        edit: 'DateInput'
                      },
                      source: 'dateOfPurchase',
                      options: {
                        label: 'Purchase Date',
                        isRequired: true
                      }
                    }
                  ]
                }
              ]
            },
            {
              options: {
                direction: 'row'
              },
              contents: [
                {
                  options: {
                    grow: 1,
                    padding: 12
                  },
                  contents: [
                    {
                      component: {
                        default: 'FieldWithLabel',
                        edit: 'TextInput'
                      },
                      source: 'receiptNumber',
                      options: {
                        label: 'Receipt Number'
                      }
                    }
                  ]
                },
                {
                  options: {
                    grow: 1,
                    padding: 12
                  },
                  contents: [
                    {
                      component: {
                        default: 'FieldWithLabel',
                        edit: 'TextInput'
                      },
                      source: 'poNumber',
                      options: {
                        label: 'PO Number',
                        isRequired: true
                      }
                    }
                  ]
                }
              ]
            },
            {
              options: {
                direction: 'row'
              },
              contents: [
                {
                  options: {
                    grow: 1,
                    padding: 12
                  },
                  contents: [
                    {
                      component: {
                        default: 'FieldWithLabel',
                        edit: 'CurrencyInput'
                      },
                      source: 'totalAmountPreTax',
                      options: {
                        label: 'Total Amount',
                        isRequired: true
                      }
                    }
                  ]
                },
                {
                  options: {
                    grow: 1,
                    padding: 12
                  },
                  contents: [
                    {
                      component: {
                        default: 'FieldWithLabel',
                        edit: 'TextInput'
                      },
                      source: 'tax',
                      options: {
                        label: 'Tax',
                        type: 'percentage'
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      vendorName: {
        type: 'string'
      },
      dateOfPurchase: {
        type: 'string'
      },
      poNumber: {
        type: 'string'
      },
      totalAmountPreTax: {
        type: 'number'
      }
    },
    required: ['vendorName', 'dateOfPurchase', 'poNumber', 'totalAmountPreTax']
  },
  validationErrors: {
    vendorName: {
      required: 'Vendor is required.'
    },
    dateOfPurchase: {
      required: 'Purchase date is required.'
    },
    poNumber: {
      required: 'PO number is required.'
    },
    totalAmountPreTax: {
      required: 'Total amount is required.'
    }
  }
});

export default PurchaseOrderLayout;
