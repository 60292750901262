import React, { useCallback } from 'react';
import { Typography } from '@buildhero/sergeant';
import TextField from '@material-ui/core/TextField';
import { bool, func, number, object, string } from 'prop-types';
import isNumber from 'lodash/isNumber';
import { useStyles } from './TimesheetEntryItem.styles';

const TimesheetEntryItem = ({ value, payrollHourType, variant, color, onChange, disabled }) => {
  const styles = useStyles();

  const handleOnChange = useCallback(
    event => {
      const newValue = parseFloat(event?.target?.value);
      onChange({
        id: payrollHourType?.id,
        value: Number.isNaN(newValue) ? undefined : Math.round(newValue * 3600)
      });
    },
    [payrollHourType, onChange]
  );

  const displayValue = isNumber(value) ? value / 3600 : '';

  return (
    <div css={styles.item}>
      <Typography css={styles.label}>
        {payrollHourType?.hourTypeAbbreviation?.trim() || '-'}
      </Typography>
      <TextField
        value={displayValue}
        variant={variant}
        color={color}
        type="number"
        InputProps={{ inputProps: { min: 0 } }}
        onChange={handleOnChange}
        disabled={disabled}
      />
    </div>
  );
};

TimesheetEntryItem.propTypes = {
  value: number.isRequired,
  payrollHourType: object.isRequired,
  variant: string.isRequired,
  color: string.isRequired,
  onChange: func.isRequired,
  disabled: bool.isRequired
};

export default TimesheetEntryItem;
