import { bundleIndex as defaultProductIndex } from 'constants/algoliaIndex';

const discountLayout = (filters, handleOnItemSelection) => ({
  fields: {},
  layouts: {
    default: {
      options: {
        direction: 'row',
        width: 450
      },
      contents: [
        {
          options: {
            direction: 'column'
          },
          contents: [
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel',
                    edit: 'AlgoliaSearch'
                  },
                  source: 'itemName',
                  options: {
                    label: 'Item Name',
                    required: true,
                    searchIndexName: defaultProductIndex,
                    searchResultDisplayFields: ['name', 'code'],
                    valueField: 'itemName',
                    delimiter: ' - ',
                    setItemName: false,
                    onChange: handleOnItemSelection,
                    filters
                  }
                }
              ]
            },
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel',
                    edit: 'TextInput'
                  },
                  source: 'reasonForDiscount',
                  options: {
                    label: 'Reason For Discount',
                    isRequired: true,
                    linesMax: 5
                  }
                }
              ]
            },
            {
              options: {
                direction: 'row'
              },
              contents: [
                {
                  options: {
                    grow: 1,
                    padding: 12
                  },
                  contents: [
                    {
                      component: {
                        default: 'FieldWithLabel',
                        edit: 'CurrencyInput'
                      },
                      source: 'amount',
                      options: {
                        label: 'Discount Amount',
                        isRequired: true
                      }
                    }
                  ]
                },
                {
                  options: {
                    grow: 1,
                    padding: 12,
                    justify: 'center'
                  },
                  contents: [
                    {
                      component: {
                        default: 'ReadOnlyCheckboxInput',
                        edit: 'CheckboxInput'
                      },
                      source: 'taxable',
                      options: {
                        label: 'Taxable'
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      itemName: {
        type: 'string'
      },
      reasonForDiscount: {
        type: 'string'
      },
      amount: {
        type: 'number'
      }
    },
    required: ['itemName', 'reasonForDiscount', 'amount']
  },
  validationErrors: {
    itemName: {
      required: 'Name is required.'
    },
    reasonForDiscount: {
      required: 'Reason is required.'
    },
    amount: {
      required: 'Amount is required.'
    }
  }
});

export default discountLayout;
