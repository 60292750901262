import React, { useMemo } from 'react';

import { BoldCell, TagCell, tableEmptyValueFormatter } from 'components/WrapTable';
import { selectJobDisplayText } from '@dispatch/components/JobNumber';
import { TaskStatus } from 'utils/AppConstants';

import { FormLinkList, PartLinkList, VisitDisplay } from './TasksTable.components';
import { TaskStatusColors, TaskTableLayout } from './TasksTable.constants';
import { selectTaskStatusText } from './TasksTable.selectors';

export const useTaskTableColumns = ({ isAssetEnabled, taskTableLayout }) => {
  return useMemo(
    () => [
      {
        headerName: 'Visit',
        field: 'visit',
        hide: ![
          TaskTableLayout.JOB_CLOSEOUT_MULTI_VISIT_TASK,
          TaskTableLayout.JOB_CLOSEOUT_MULTI_JOB_TASK
        ].includes(taskTableLayout),
        renderCell: VisitDisplay,
        flex: 1
      },
      {
        headerName: 'Asset',
        field: 'asset',
        hide: !isAssetEnabled,
        valueGetter: ({ value }) => value?.assetName,
        valueFormatter: tableEmptyValueFormatter,
        renderCell: BoldCell,
        maxWidth: 140
      },
      {
        headerName: 'Task',
        field: 'name',
        valueFormatter: tableEmptyValueFormatter,
        flex: 2
      },
      {
        headerName: 'Task Description',
        field: 'description',
        valueFormatter: tableEmptyValueFormatter,
        flex: 3
      },
      {
        headerName: 'Forms',
        field: 'formData',
        hide: [
          TaskTableLayout.JOB_CLOSEOUT_VISIT_TASK,
          TaskTableLayout.JOB_CLOSEOUT_MULTI_VISIT_TASK,
          TaskTableLayout.VISIT_TASK
        ].includes(taskTableLayout),
        renderCell: FormLinkList,
        maxWidth: 220
      },
      {
        headerName: 'Parts & Materials',
        field: 'taskEntries',
        hide: [
          TaskTableLayout.JOB_CLOSEOUT_VISIT_TASK,
          TaskTableLayout.JOB_CLOSEOUT_MULTI_VISIT_TASK,
          TaskTableLayout.VISIT_TASK
        ].includes(taskTableLayout),
        renderCell: PartLinkList,
        maxWidth: 140
      },
      {
        headerName: 'Status',
        field: 'status',
        hide: [
          TaskTableLayout.JOB_TASK,
          TaskTableLayout.JOB_CLOSEOUT_MULTI_JOB_TASK,
          TaskTableLayout.JOB_CLOSEOUT_JOB_TASK
        ].includes(taskTableLayout),
        maxWidth: 140,
        valueGetter: ({ value, row }) => {
          return selectTaskStatusText({ status: value, taskTypeInternal: row?.taskTypeInternal });
        },
        renderCell: props => <TagCell {...props} color={TaskStatusColors[props.value]} />
      },
      {
        headerName: 'Associated Work',
        field: 'associatedWork',
        maxWidth: 140,
        valueFormatter: tableEmptyValueFormatter,
        renderCell: ({ row }) => {
          const job = row?.jobs?.[0];
          return (
            <a href={`/${job?.jobTypeInternal?.toLowerCase()}/view/${job?.jobNumber}`}>
              {selectJobDisplayText({ job })}
            </a>
          );
        },
        hide: [
          TaskTableLayout.JOB_TASK,
          TaskTableLayout.JOB_CLOSEOUT_MULTI_JOB_TASK,
          TaskTableLayout.JOB_CLOSEOUT_JOB_TASK
        ].includes(taskTableLayout)
      },
      {
        headerName: 'Created By',
        field: 'createdBy',
        maxWidth: 140
      },
      {
        headerName: 'Completed By',
        field: 'lastUpdatedBy',
        hide: ![
          TaskTableLayout.JOB_CLOSEOUT_MULTI_JOB_TASK,
          TaskTableLayout.JOB_CLOSEOUT_JOB_TASK
        ].includes(taskTableLayout),
        valueGetter: ({ value, row }) => (row.status === TaskStatus.COMPLETED ? value : '-'),
        maxWidth: 140
      }
    ],
    [isAssetEnabled, taskTableLayout]
  );
};
