import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { startCase } from 'lodash';
import { Edit, PictureAsPdf, Email, BrokenImage, Visibility, Receipt } from '@material-ui/icons';
import { Button, Typography } from '@buildhero/sergeant';

import {
  tableDateFormatter,
  tableCurrencyFormatter,
  TagCell,
  BoldCell,
  actionButtonColumn
} from 'components/WrapTable';

import Routes from 'scenes/Routes';
import { InvoiceStatus, InvoiceStatusColor } from 'utils/constants';

export const usePayApplicationColumns = ({
  handleEditPayAppClick,
  handleViewPayAppClick,
  handlePreviewPdfClick,
  handlePreviewEmailClick,
  handleVoidPayAppClick
}) => {
  const history = useHistory();

  return useMemo(
    () => [
      {
        field: 'id',
        hide: true
      },
      {
        headerName: 'Number',
        field: 'number',
        noPadding: true,
        justify: 'center',
        renderCell: ({ row }) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Button type="leading" onClick={() => handleViewPayAppClick(row)}>
            <Typography underlined>{row.number}</Typography>
          </Button>
        )
      },
      {
        headerName: 'Status',
        field: 'status',
        valueFormatter: ({ value }) => startCase(value),
        renderCell: props => <TagCell color={InvoiceStatusColor[props.value]} {...props} />
      },
      {
        headerName: 'Due',
        field: 'dueDate',
        valueFormatter: tableDateFormatter
      },
      {
        headerName: 'Issued',
        field: 'applicationDate',
        valueFormatter: tableDateFormatter
      },
      {
        headerName: 'Completed & Stored',
        field: 'totalCompletedAndStored',
        valueFormatter: tableCurrencyFormatter
      },
      {
        headerName: 'Retained Amount',
        field: 'retainedAmount',
        valueGetter: ({ row }) => (row.retainedAmount ?? 0) - (row.billedRetainage ?? 0),
        valueFormatter: tableCurrencyFormatter
      },
      {
        headerName: 'Invoice Amount',
        field: 'invoiceAmount',
        valueFormatter: tableCurrencyFormatter,
        renderCell: BoldCell
      },
      {
        headerName: 'AR Balance',
        field: 'balance',
        valueFormatter: tableCurrencyFormatter,
        renderCell: BoldCell
      },
      actionButtonColumn({
        actions: [
          {
            icon: Visibility,
            label: 'View',
            onClick: handleViewPayAppClick
          },
          {
            icon: PictureAsPdf,
            label: 'Preview',
            onClick: handlePreviewPdfClick
          },
          {
            icon: Receipt,
            label: 'View Invoice',
            onClick: row => history.push(Routes.invoice({ mode: 'view', id: row.invoiceId }))
          },
          {
            icon: Edit,
            label: 'Edit',
            disabled: ({ status }) => status !== InvoiceStatus.DRAFT,
            onClick: handleEditPayAppClick
          },
          {
            icon: Email,
            label: 'Email',
            disabled: ({ status }) => status === InvoiceStatus.VOID,
            onClick: handlePreviewEmailClick
          },
          {
            icon: BrokenImage,
            label: 'Void',
            disabled: ({ status }) => [InvoiceStatus.CLOSED, InvoiceStatus.VOID].includes(status),
            onClick: handleVoidPayAppClick
          }
        ]
      })
    ],
    [
      handleViewPayAppClick,
      handlePreviewPdfClick,
      handleEditPayAppClick,
      handlePreviewEmailClick,
      handleVoidPayAppClick,
      history
    ]
  );
};
