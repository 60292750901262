import React, { useEffect, useState } from 'react';
import { array, bool, func, object } from 'prop-types';
import { some } from 'lodash';
import getJobIdentifier from 'utils/getJobIdentifier';
import { useCreateInvoiceFromJobQuotes } from '../../hooks/useCreateInvoiceFromJobQuotes';
import CreateInvoiceModal from '../CreateInvoiceModal';
import InvoiceAmountFooter from '../InvoiceAmountFooter';
import JobLabel from '../JobLabel';
import EmptyQuoteListMessage from './components/EmptyQuoteListMessage';
import MultipleQuoteMessage from './components/MultipleQuoteMessage';
import QuotesListHeader from './components/QuotesListHeader';
import QuoteItem from './components/QuoteItem';
import { useQuoteItems } from './QuotedJobCreateInvoiceModal.hooks';
import DepartmentSelect from '../DepartmentSelect';
import useDepartmentValue from '../../hooks/useDepartmentValue';

const QuotedJobCreateInvoiceModal = ({ jobData, quotes, open, onClose, onSuccess }) => {
  const quoteItems = useQuoteItems(quotes);
  const [selectedQuotes, setSelectedQuotes] = useState({});
  const [loading, setLoading] = useState(false);
  const [departmentValue, setDepartmentValue] = useDepartmentValue(jobData);
  const [createInvoice] = useCreateInvoiceFromJobQuotes();
  const jobIdentifier = getJobIdentifier(jobData);
  const canCreateInvoice =
    some(Object.values(selectedQuotes), isSelected => isSelected) && Boolean(departmentValue);
  const invoiceAmount = quoteItems.reduce((result, quote) => {
    if (selectedQuotes[quote.id]) {
      return result + quote.amount;
    }
    return result;
  }, 0);

  useEffect(() => {
    if (quoteItems.length === 1) {
      setSelectedQuotes({
        [quoteItems[0].id]: true
      });
    }
  }, [quoteItems]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const selectedQuoteIds = quoteItems
        .filter(quote => selectedQuotes[quote?.id])
        .map(quote => quote.id);
      const response = await createInvoice({
        jobId: jobData?.id,
        departmentId: departmentValue?.value,
        quoteIds: selectedQuoteIds
      });
      await onSuccess();
      return response?.data?.createInvoiceFromJobQuotes;
    } finally {
      setLoading(false);
    }
  };

  const handleQuoteSelectChange = quoteId =>
    setSelectedQuotes(prevSelectedQuotes => ({
      ...prevSelectedQuotes,
      [quoteId]: !prevSelectedQuotes[quoteId]
    }));

  const renderBody = () => (
    <>
      <JobLabel jobIdentifier={jobIdentifier} />
      {quoteItems.length === 0 && <EmptyQuoteListMessage />}
      {quoteItems.length === 1 && (
        <>
          <QuotesListHeader />
          <QuoteItem quote={quoteItems[0]} hideCheckbox />
        </>
      )}
      {quoteItems.length > 1 && (
        <>
          <MultipleQuoteMessage />
          <QuotesListHeader />
          {quoteItems.map(quote => (
            <QuoteItem
              checked={Boolean(selectedQuotes[quote.id])}
              quote={quote}
              onChange={handleQuoteSelectChange}
            />
          ))}
          <InvoiceAmountFooter invoiceAmount={invoiceAmount} />
        </>
      )}
      <DepartmentSelect disabled={loading} onChange={setDepartmentValue} value={departmentValue} />
    </>
  );

  return (
    <CreateInvoiceModal
      renderBody={renderBody}
      onSubmit={handleSubmit}
      canCreateInvoice={canCreateInvoice}
      onClose={onClose}
      open={open}
    />
  );
};

QuotedJobCreateInvoiceModal.propTypes = {
  jobData: object.isRequired,
  quotes: array.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
  onSuccess: func.isRequired
};

export default QuotedJobCreateInvoiceModal;
