export const SettingConstants = {
  QUOTE_PRESETS: 'Quote Presets',
  QUOTE_MODE: 'Quote Mode',
  ENABLE_OVERRIDES: 'Enable overrides',
  TASK_GROUPS: 'Task Groups',
  SHOW_TASK_GROUP_TITLE: 'Show task group title',
  SHOW_TASK_GROUP_PRICING: 'Show task group pricing',
  SHOW_TASK_TITLE: 'Show task title',
  SHOW_TASK_DESCRIPTION: 'Show task description',
  SHOW_TASK_PRICING: 'Show task pricing',
  SHOW_ASSET: 'Show asset',
  SHOW_LABOR_TOTAL: 'Show labor total',
  SHOW_ITEMIZED_LABOR: 'Show itemized labor',
  SHOW_ITEMIZED_LABOR_PRICING: 'Show itemized labor pricing',
  SHOW_ITEMIZED_LABOR_QUANTITY: 'Show itemized labor quantity',
  SHOW_MATERIAL_TOTAL: 'Show material total',
  SHOW_ITEMIZED_MATERIAL_ITEMS: 'Show itemized material items',
  SHOW_ITEMIZED_MATERIAL_ITEM_PRICING: 'Show itemized material item pricing',
  SHOW_ITEMIZED_MATERIAL_QUANTITY: 'Show itemized material quantity',
  TOTALS_AND_SUBTOTALS: 'Totals & Subtotals',
  SHOW_TASK_SUBTOTAL: 'Show task subtotal',
  SHOW_LINE_ITEM_PRICING: 'Show line item pricing',
  SHOW_DISCOUNT: 'Show discount',
  SHOW_SUBTOTAL: 'Show subtotal',
  SHOW_TAX: 'Show tax',
  SHOW_TOTAL: 'Show total',
  CUSTOM: 'Custom'
};

export const ItemType = {
  LABOR: 'Labor',
  INVENTORY_PART: 'Parts & Materials',
  MATERIAL: 'Material',
  DISCOUNT: 'Discount'
};
