import moment from 'moment';

import Context from 'components/Context';
import { updateTasksAndGroups } from 'scenes/Quotes/components/Tasks/TaskGroups/TaskGroup.utils';
import { ItemType, SettingConstants } from 'scenes/Quotes/constants';
import { getQuoteNumberAndVersion } from 'scenes/Quotes/utils/helper';
import { backendDateToMoment, processAddressArrayAsJson } from 'utils';
import AppConstants, { getDateFormat } from 'utils/AppConstants';

import { smartFields, smartFieldsMap } from './CKEditor.constants';
import { addDataSmartfield, convertToCurrencyStringCheck } from './CKEditor.utils';
import * as HTML from './smartfieldHTML';

const getTableStyles = (htmlStr, smartFieldType) => {
  // regex gets the styling attributes that were added on the company quote template page, so they can be maintained in ckEditor
  const pat = new RegExp(`<p(?=(?:(?!</p).)*\\[\\[(?=${smartFieldType}\\]\\])).+?</p>`);
  const smartFieldMatch = htmlStr.match(pat);
  let styles = '';
  let fontFamilyStyle = '';
  if (Array.isArray(smartFieldMatch)) {
    const stylePat = new RegExp('style="(.+?(?="))', 'g');
    const styleMatches = [...smartFieldMatch[0].matchAll(stylePat)];
    if (!styleMatches.length) return [styles, fontFamilyStyle];
    const styleContent = styleMatches.map(m => m[1]);
    const uniqueStyles = Array.from(new Set(styleContent));
    fontFamilyStyle = uniqueStyles.find(style => style.startsWith('font-family'));
    styles = uniqueStyles.join('');
  }
  return [styles, fontFamilyStyle];
};

export const parseHTMLForSmartFields = async ({
  hasMultipleVersions,
  htmlStr,
  smartFieldInfo,
  settingsJSON,
  propertyDetails = {}
}) => {
  if (Context.getCompanyContext()) {
    if (!settingsJSON) return '';

    const companyInfo = Context.getCompanyContext().getCompany;
    let logoUrl;
    if (companyInfo?.logoUrl) {
      logoUrl = await HTML.getLogo(companyInfo.logoUrl);
    }

    const parsedStr = Object.values(smartFields).reduce((acc, item) => {
      const patt = new RegExp(`\\[\\[${item}]\\]`, 'g');
      let newValue;

      switch (item) {
        case smartFields.BILL_TO_ADDRESS:
          newValue = addDataSmartfield(
            smartFields.BILL_TO_ADDRESS,
            HTML.formatBillToAddressSmartfield(propertyDetails, HTML)
          );
          break;
        case smartFields.COMPANY_ADDRESS:
          {
            const processedAddresses = processAddressArrayAsJson(
              companyInfo.companyAddresses?.items
            );
            newValue = addDataSmartfield(
              smartFields.COMPANY_ADDRESS,
              processedAddresses.billingAddress
            );
          }
          break;
        case smartFields.COMPANY_NAME:
          newValue = addDataSmartfield(
            smartFields.COMPANY_NAME,
            companyInfo[smartFieldsMap.COMPANY_NAME]
          );
          break;
        case smartFields.COMPANY_LOGO:
          newValue = logoUrl;
          break;
        case smartFields.COMPANY_PHONE:
          newValue = addDataSmartfield(
            smartFields.COMPANY_PHONE,
            companyInfo[smartFieldsMap.COMPANY_PHONE]
          );
          break;
        case smartFields.CUSTOMER_ADDRESS:
          newValue = addDataSmartfield(
            smartFields.CUSTOMER_ADDRESS,
            HTML.getAddress(propertyDetails?.customer?.companyAddresses?.items)
          );
          break;
        case smartFields.CUSTOMER_NAME:
          newValue = addDataSmartfield(
            smartFields.CUSTOMER_NAME,
            propertyDetails?.customer?.customerName
          );
          break;
        case smartFields.CURRENT_DATE:
          newValue = addDataSmartfield(
            smartFields.CURRENT_DATE,
            new Date().toLocaleDateString(undefined, {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            })
          );
          break;
        case smartFields.DAYS_UNTIL_EXPIRATION:
          newValue = addDataSmartfield(
            smartFields.DAYS_UNTIL_EXPIRATION,
            smartFieldInfo.expirationLength
          );
          break;
        case smartFields.DISCOUNT:
          newValue = HTML.lineItemsOrDiscounts(smartFieldInfo, ItemType.DISCOUNT);
          break;
        case smartFields.EXPIRATION_DATE:
          {
            const formattedExpirationDate = moment()
              .add(smartFieldInfo.expirationLength, 'days')
              .format(AppConstants.DATE_FORMAT);
            newValue = addDataSmartfield(smartFields.EXPIRATION_DATE, formattedExpirationDate);
          }
          break;
        case smartFields.LABOR_SUBTOTAL:
          newValue = addDataSmartfield(
            smartFields.LABOR_SUBTOTAL,
            HTML.laborSubtotal({ quoteLineTasks: smartFieldInfo.quoteLineTasks.items })
          );
          break;
        case smartFields.LINE_ITEMS:
          newValue = HTML.lineItemsOrDiscounts(smartFieldInfo, ItemType.MATERIAL);
          break;
        case smartFields.PARTS_AND_MATERIALS:
          newValue = addDataSmartfield(
            smartFields.PARTS_AND_MATERIALS,
            HTML.partsAndMaterials({ quoteLineTasks: smartFieldInfo.quoteLineTasks.items })
          );
          break;
        case smartFields.PROJECT_MANAGER_EMAIL:
          newValue = addDataSmartfield(
            smartFields.PROJECT_MANAGER_EMAIL,
            smartFieldInfo.owner?.email
          );
          break;
        case smartFields.PROJECT_MANAGER:
          newValue = addDataSmartfield(smartFields.PROJECT_MANAGER, smartFieldInfo.owner?.name);
          break;
        case smartFields.PROPERTY_ADDRESS:
          newValue = addDataSmartfield(
            smartFields.PROPERTY_ADDRESS,
            HTML.getAddress(propertyDetails?.property?.companyAddresses?.items)
          );
          break;
        case smartFields.PROPERTY_BILLING_CUSTOMER_ADDRESS: {
          const billingAddress = HTML.getBillingAddress(
            propertyDetails?.property?.billingCustomer?.companyAddresses?.items
          );
          newValue = addDataSmartfield(
            smartFields.PROPERTY_BILLING_CUSTOMER_ADDRESS,
            billingAddress
          );
          break;
        }
        case smartFields.PROPERTY_BILLING_CUSTOMER_BILL_TO:
          newValue = addDataSmartfield(
            smartFields.PROPERTY_BILLING_CUSTOMER_BILL_TO,
            propertyDetails?.property?.billTo
          );
          break;
        case smartFields.PROPERTY_BILLING_CUSTOMER_NAME:
          newValue = addDataSmartfield(
            smartFields.PROPERTY_BILLING_CUSTOMER_NAME,
            propertyDetails?.property?.billingCustomer?.customerName
          );
          break;
        case smartFields.PROPERTY_NAME:
          newValue = addDataSmartfield(
            smartFields.PROPERTY_NAME,
            propertyDetails?.property?.companyName
          );
          break;
        case smartFields.PROPERTY_REP:
          newValue = addDataSmartfield(smartFields.PROPERTY_REP, smartFieldInfo.propertyRep?.name);
          break;
        case smartFields.QUOTE_CREATION_DATE:
          {
            const momentObj = backendDateToMoment(smartFieldInfo.createdDate);
            const dateText = momentObj && momentObj.format(getDateFormat('date'));
            newValue = addDataSmartfield(smartFields.QUOTE_CREATION_DATE, dateText);
          }
          break;
        case smartFields.QUOTE_NUMBER:
          newValue = addDataSmartfield(
            smartFields.QUOTE_NUMBER,
            getQuoteNumberAndVersion(smartFieldInfo, hasMultipleVersions)
          );

          break;
        case smartFields.QUOTE_ORDERED_BY:
          newValue = addDataSmartfield(
            smartFields.QUOTE_ORDERED_BY,
            smartFieldInfo.orderedBy?.name
          );
          break;
        case smartFields.QUOTE_ORDERED_BY_EMAIL:
          newValue = addDataSmartfield(
            smartFields.QUOTE_ORDERED_BY_EMAIL,
            smartFieldInfo.orderedBy?.email
          );
          break;
        case smartFields.QUOTE_ORDERED_BY_PHONE:
          newValue = addDataSmartfield(
            smartFields.QUOTE_ORDERED_BY_PHONE,
            smartFieldInfo.orderedBy?.cellPhone ?? smartFieldInfo.orderedBy?.landlinePhone
          );
          break;
        case smartFields.QUOTE_SUBJECT:
          newValue = addDataSmartfield(
            smartFields.QUOTE_SUBJECT,
            smartFieldInfo[smartFieldsMap?.QUOTE_SUBJECT]
          );
          break;
        case smartFields.QUOTE_SUBTOTAL:
          {
            const subtotal = HTML.subtotal({
              smartFieldInfo,
              settingsJSON,
              smartFieldsMap,
              SettingConstants,
              convertToCurrencyStringCheck
            });
            newValue = addDataSmartfield(smartFields.QUOTE_SUBTOTAL, subtotal);
          }
          break;
        case smartFields.REMOVE_ITEM:
          newValue = '';
          break;
        case smartFields.TASKS: {
          const [styles, fontFamilyStyle] = getTableStyles(htmlStr, smartFields.TASKS);
          newValue = HTML.tasks({ HTML, smartFieldInfo, settingsJSON, styles, fontFamilyStyle });
          break;
        }
        case smartFields.TASK_GROUPS: {
          const [styles, fontFamilyStyle] = getTableStyles(htmlStr, smartFields.TASK_GROUPS);
          newValue = HTML.taskGroups({
            convertToCurrencyStringCheck,
            HTML,
            updateTasksAndGroups,
            smartFieldInfo,
            settingsJSON,
            SettingConstants,
            styles,
            fontFamilyStyle
          });
          break;
        }
        case smartFields.TAX_PERCENTAGE:
          newValue = addDataSmartfield(
            smartFields.TAX_PERCENTAGE,
            smartFieldInfo[smartFieldsMap.TAX] ? `${smartFieldInfo[smartFieldsMap.TAX]}%` : ''
          );
          break;
        case smartFields.TAX_AMOUNT:
          newValue = addDataSmartfield(
            smartFields.TAX_AMOUNT,
            convertToCurrencyStringCheck(
              smartFieldInfo[smartFieldsMap.TAX_AMOUNT_OVERRRIDE] ??
                smartFieldInfo[smartFieldsMap.TAX_AMOUNT]
            )
          );
          break;
        case smartFields.TERMS_AND_CONDITIONS:
          newValue = addDataSmartfield(
            smartFields.TERMS_AND_CONDITIONS,
            smartFieldInfo[smartFieldsMap.TERMS_AND_CONDITIONS]
          );
          break;
        case smartFields.TOTAL:
          newValue = addDataSmartfield(
            smartFields.TOTAL,
            HTML.total({
              smartFieldInfo,
              settingsJSON,
              smartFieldsMap,
              SettingConstants,
              convertToCurrencyStringCheck
            })
          );
          break;
        case smartFields.TOTALS_AND_SUBTOTALS: {
          const [styles, fontFamilyStyle] = getTableStyles(
            htmlStr,
            smartFields.TOTALS_AND_SUBTOTALS
          );
          newValue = HTML.totalsAndSubtotals({
            HTML,
            convertToCurrencyStringCheck,
            ItemType,
            settingsJSON,
            SettingConstants,
            smartFieldInfo,
            smartFieldsMap,
            styles,
            fontFamilyStyle
          });
          break;
        }
        default:
          newValue = item;
      }

      return acc.replace(patt, newValue);
    }, htmlStr);

    return parsedStr;
  }
};
