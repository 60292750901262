import React from 'react';

import { func } from 'prop-types';

import { AlgoliaSearch } from 'components';
import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';

const VisitSearchHeader = ({ onVisitSelected }) => {
  return (
    <AlgoliaSearch
      searchIndex={defaultSearchIndex}
      placeholder="Search Visits"
      name="selectVisit"
      value=""
      displayText={['jobNumber', 'visitNumber']}
      delimiter=" - "
      locatedInPopUp
      filters={[
        {
          attribute: 'entityType',
          valueArray: ['Visit']
        }
      ]}
      handleChange={onVisitSelected}
      isRequired
      customHitFormatter={hit => `Job ${hit?.jobNumber} - Visit ${hit?.visitNumber}`}
    />
  );
};

VisitSearchHeader.propTypes = {
  onVisitSelected: func.isRequired
};

export default VisitSearchHeader;
