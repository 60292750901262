import gql from 'graphql-tag';
import {
  quoteFragment,
  propertyFragement,
  customFormFragment,
  quoteJobsFragment,
  quoteTagsFragment
} from './queryFragments';

const getVersionedQuoteById = gql`
  query getVersionedQuoteById($id: String!, $sort: [SortingInput]) {
    getVersionedQuoteById(id: $id) {
      quoteTaskGroups(entityConnection: "QuoteTaskGroups") {
        items {
          id
          name
          sortOrder
          totalAmount
          totalAmountOverride
        }
      }
      quoteLineTasks(entityConnection: "QuoteLineTask") {
        nextToken
        items {
          id
          taskId
          quoteTaskGroupId
          version
          entityType
          sortKey
          taskId
          unitPrice
          unitCost
          quantity
          taxable
          markupType
          markupValue
          name
          createdBy
          createdDateTime
          sortOrder
          description
          propertyAsset(entityConnection: "PropertyAsset") {
            id
            assetName
            assetType {
              tagName
            }
          }
          quoteLineLabors(entityConnection: "QuoteLineLabor") {
            items {
              id
              description
              labourTypeId
              labourType(entityConnection: "LabourType"){
                name
              }
              unitCost
              unitPrice
              markup
              quantity
              isTaxable
              subtotal
              totalCost
              totalPrice
              quoteLineLaborBillingHours(entityConnection: "QuoteLineLaborBillingHour"){
                items {
                  id
                  billingHourTypeId
                  hours
                  unitPrice
                  productId
                  billingHourType(entityConnection: "BillingHourType"){
                    hourType
                    sortOrder
                  }
                }
              }
              quoteLineLaborPayrollHours(entityConnection: "QuoteLineLaborPayrollHour"){
                items {
                  id
                  payrollHourTypeId
                  hours
                  unitCost
                  costCodeId
                  jobCostTypeId
                  payrollHourType(entityConnection: "PayrollHourType"){
                    hourType
                    sortOrder
                  }
                }
              }
            }
          }
          subtotal: aggregatedField(
            input: {
              entityConnection: "QuoteLineProduct"
              isDirectChildren: true
              aggregations: [
                {
                  aggregationType: SUM
                  aggregationField: "QuoteLineProduct.unitPrice * QuoteLineProduct.quantity"
                }
              ]
            }
          ) {
            items {
              total: aggregation1
            }
          }
          taxableTotal: aggregatedField(
            input: {
              entityConnection: "QuoteLineProduct"
              isDirectChildren: true
              aggregations: [
                {
                  aggregationType: SUM
                  aggregationField: "QuoteLineProduct.unitPrice * QuoteLineProduct.quantity"
                }
              ]
              filter: {
                booleanFilters: { fieldName: "QuoteLineProduct.taxable", filterInput: { eq: true } }
              }
            }
          ) {
            items {
              total: aggregation1
            }
          }
          products: quoteLineProductsView(entityConnection: "QuoteLineProduct", sorting: $sort) {
            nextToken
            items {
              id
              version
              productId
              sortKey
              entityType
              name
              sortOrder
              description
              unitPrice
              unitCost
              quantity
              markupType
              markupValue
              taxable
              sortOrder
              priceBookEntryId
              lineItemType
            }
          }
        }
      }
      ${quoteFragment}
      opportunity: parentEntity {
        ... on Opportunity {
          versionedQuotes(
            entityConnection: "VersionedQuote"
            filter: { versionOfEntityId: { eq: $id } }
          ) {
            items {
              id
              versionLabel
              versionNumber
              totalAmountQuoted
              totalAmountQuotedOverride
              lastUpdatedDateTime
              lastUpdatedBy
              status
            }
          }
          id
          property: parentEntity {
            ... on CustomerProperty {
              ${propertyFragement}
            }
          }
        }
      }
      priceBookId
      priceBook(entityConnection: "PriceBook"){
        name
        pricebookLabourEntries {
          items {
            id
            version
            rate
            labourTypeId
            labourType {
              name
            }
            billingHourTypeId
            billingHourType(entityConnection: "BillingHourType"){
              hourType
              id
            }
            costCode {
              name
            }
            revenueType {
              name
            }
          }
        }
      }
      quoteLineProducts(entityConnection: "QuoteLineProduct"){
        items {
          id
          costCode(entityConnection: "CostCode"){
            description
            id
          }
          revenueType(entityConnection: "RevenueType"){
            id
            name
          }
          jobCostType(entityConnection: "JobCostType"){
            id
            name
          }
          costCodeId
          departmentId
          description
          jobCostTypeId
          lineItemType
          markupValue
          name
          productId
          quantity
          revenueTypeId
          sortOrder
          taxable
          unitCost
          unitPrice
        }
      }
      emails {
        items {
          createdDate
          attachments: attachments(entityConnection: "Attachment") {
            items {
              fileUrl
              fileName
              comment
            }
          }
        }
      }
      quoteJobs (entityConnection: "QuoteJob") {
        items {
          ${quoteJobsFragment}
        }
      }
      customIdentifier
      settingsJSON
      template
      ${customFormFragment}
      ${quoteTagsFragment}
    }
  }
`;
export default getVersionedQuoteById;
