const labelSchema = {
  customerSignature: {
    en: 'Customer signature',
    es: 'Firma del cliente'
  },
  partsAndMaterials: {
    en: 'Parts and materials',
    es: 'Partes y materiales'
  },
  labour: {
    en: 'Labor',
    es: 'Labor'
  },
  inventoryItem: {
    en: 'Inventory items',
    es: 'Artículos de inventario'
  },
  inventoryItems: {
    en: 'Inventory items',
    es: 'Artículos de inventario'
  },
  purchasedItems: {
    en: 'Purchased item(s)',
    es: 'Elementos comprados'
  },
  timeTracking: {
    en: 'Time tracked by mobile app',
    es: 'Seguimiento de tiempo por la app'
  },
  timeTrackingReport: {
    en: 'Time Tracking Report',
    es: 'Informe de seguimiento del tiempo'
  },
  timeCards: {
    en: 'Time entered by technician',
    es: 'time entered by technician'
    // TODO spanish
  },
  discount: {
    en: 'Discount',
    es: 'Descuento'
  },
  fee: {
    en: 'Fee',
    es: 'Tarifa'
  },
  notesByTechnician: {
    en: 'Notes By Technician',
    es: 'Notas del técnico'
  },
  bills: {
    en: 'Bill line items that have not been invoiced for this job',
    es: 'Facturas con líneas de pedido que no se han facturado por este trabajo'
  }
};

export default labelSchema;
