import { Auth } from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { Logger } from '../Logger';
import techHeartbeatNotification from '../core/graphql/tracking/employee/subscriptions/techHeartbeatNotification';
import getHeartbeatById from '../core/graphql/tracking/employee/queries/getHeartbeatById';

class AppSyncClient {
  client = null;

  config = null;

  status = 'online';

  constructor(config) {
    this.config = config;
    this.client = this.createClient(this.config);
  }

  createClient = config => {
    const { options } = config;
    if (options && options.cache) {
      console.log('USING CACHE', typeof config.options.cache);
    }
    const { disableOffline, cache } = options || {};

    return new AWSAppSyncClient(
      {
        url: config.appsync.aws_appsync_graphqlEndpoint,
        region: config.appsync.aws_project_region,
        auth: {
          credentials: () => Auth.currentCredentials(),
          type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
          jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
          apiKey: null
        },
        cacheOptions: {
          dataIdFromObject: obj => obj.id
        },
        disableOffline: true, // disableOffline || false,
        offlineConfig: {
          storage: cache,
          callback: (err, succ) => {
            // TODO: figure out what to do here..
            if (err) {
              const { mutation, variables } = err;
              console.warn(`UNHANDLED MUTATION ERROR for ${mutation}, vars`, variables);
            } else {
              // NOP for now..
              const { mutation, variables } = succ;
              console.log(`MUTATION SUCCESS for ${mutation}, vars`, variables);
            }
          },
          complexObjectsCredentials: () => Auth.currentCredentials()
        }
      },
      {
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'network-only'
          },
          query: {
            fetchPolicy: 'no-cache'
          },
          mutate: {
            fetchPolicy: 'no-cache'
          }
        }
      }
    );
  };

  query = async (query, variables) => {
    // writeContentsToFile(query,variables,"Query")
    try {
      if (this.status === 'offline') {
        await this.client.hydrated();
      }
      const response = await this.client.query({
        query,
        variables
      });
      return response;
    } catch (error) {
      Logger.warn(error);
      throw error;
    }
  };

  queryWithCache = async (query, variables) => {
    // writeContentsToFile(query,variables,"Query")
    try {
      if (this.status === 'offline') {
        await this.client.hydrated();
      }
      const response = await this.client.query({
        query,
        variables,
        fetchPolicy: this.status === 'offline' ? 'cache-only' : 'network-only'
        // fetchPolicy: 'no-cache'
      });
      return response;
    } catch (error) {
      Logger.warn(error);
      throw error;
    }
  };

  observableQuery = async (query, variables) => {
    // writeContentsToFile(query,variables,"Query")
    try {
      const hydratedClient = await this.client.hydrated();
      const response = await hydratedClient.watchQuery({
        query,
        variables,
        fetchPolicy: 'cache-and-network'
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  subscribe = async (query, variables) => {
    const hydratedClient = await this.client.hydrated();
    return hydratedClient.subscribe({
      query,
      variables
    });
  };

  mutate = async (query, variables) => {
    // writeContentsToFile(query,variables,"Mutation")
    try {
      if (this.status === 'offline') {
        await this.client.hydrated();
      }
      const response = await this.client.mutate({
        mutation: query,
        variables
      });
      return response;
    } catch (error) {
      Logger.warn(error);
      throw error;
    }
  };

  fullMutate = async (query, variables, optimisticResponse, update, refetchQueries) => {
    // writeContentsToFile(query,variables,"Mutation")
    const mutation = this.client.mutate({
      mutation: query,
      variables,
      //      fetchPolicy: 'no-cache',
      refetchQueries,
      optimisticResponse,
      update
    });
    return mutation;
  };

  subscribeToEmployeeHeartbeats = async (partitionKey, handleNewHeartbeatData) => {
    console.log('attempting hydration and subscription to heartbeat service');
    await this.client.hydrated();
    const params = {
      partitionKey
    };

    const observable = this.client.subscribe({
      query: techHeartbeatNotification,
      variables: params
    });
    const handleHeartbeatNotification = async notification => {
      if (notification) {
        // console.log('received notification: ', JSON.stringify(notification, null, 2));
        const notificationData = notification.data;
        if (
          notificationData &&
          notificationData.childMutationNotification &&
          notificationData.childMutationNotification.entityType === 'Heartbeat'
        ) {
          params.id = notificationData.childMutationNotification.entityId;
          const response = await this.client.query({
            query: getHeartbeatById,
            variables: params
          });

          if (response) {
            const { data } = response;
            // console.log('received data: ', JSON.stringify(data, null, 2));
            if (data.getHeartbeatById) {
              const hb = data.getHeartbeatById;
              const pe = hb.parentEntity;

              handleNewHeartbeatData({
                lat: hb.latitude,
                lng: hb.longitude,
                employee: pe
              });
            }
          }
        }
      }
    };

    observable.subscribe({
      next: handleHeartbeatNotification,
      complete: console.log,
      error: console.log
    });
  };
}

export default AppSyncClient;
