import AmplifyService from 'services/AmplifyService';
import * as Query from '../../graphql/project/queries';
import * as Mutations from '../../graphql/project/mutations';
import { generateCompanySortKey } from '../../utils';

export default class ProjectService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
  }

  getCostCodes = async (partitionKey, companyId, filter) => {
    const params = {
      partitionKey,
      sortKey: generateCompanySortKey(partitionKey, companyId),
      filter
    };

    const response = await this.api.query(Query.getCostCodes, params);
    return response;
  };

  getJobCostTypes = async (partitionKey, companyId, filter) => {
    const params = {
      partitionKey,
      sortKey: generateCompanySortKey(partitionKey, companyId),
      filter
    };

    const response = await this.api.query(Query.getJobCostTypes, params);
    return response;
  };

  getWIPReportSettings = async partitionKey => {
    const params = {
      partitionKey
    };
    const response = await this.api.query(Query.getWIPReportSettings, params);
    return response;
  };

  addJobCostTypes = async (partitionKey, data) => {
    const params = {
      data,
      partitionKey
    };

    const response = await this.api.mutate(Mutations.addJobCostTypes, params);
    return response;
  };

  updateJobCostType = async (partitionKey, data) => {
    const params = {
      data,
      partitionKey
    };

    const response = await this.api.mutate(Mutations.updateJobCostType, params);
    return response;
  };

  addCostCodes = async (partitionKey, data) => {
    const params = {
      data,
      partitionKey
    };

    const response = await this.api.mutate(Mutations.addCostCodes, params);
    return response;
  };

  updateCostCode = async (partitionKey, data) => {
    const params = {
      data,
      partitionKey
    };

    const response = await this.api.mutate(Mutations.updateCostCode, params);
    return response;
  };

  updateWIPReportSettings = async (partitionKey, settings) => {
    const params = {
      partitionKey,
      settings
    };

    const response = await this.api.mutate(Mutations.updateWIPReportSettings, params);
    return response;
  };
}
