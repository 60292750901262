import React, { memo } from 'react';
import { useTheme, withTheme } from '@emotion/react';
import { ThemeProvider } from '@buildhero/sergeant';

import compose from 'utils/compose';
import withErrorBoundary from 'utils/withErrorBoundary';

import WrapTable from './WrapTable.component';
import { WrapTableProps } from './WrapTable.types';

import {
  DEFAULT_EMPTY_MESSAGE,
  DEFAULT_ERROR_MESSAGE,
  TablePlaceholderVariant
} from './WrapTable.constants';

import TablePlaceholder from './TablePlaceholder';

const withSergeantTheme = Component => props => (
  <ThemeProvider>
    <Component {...props} />
  </ThemeProvider>
);

const ErrorTable = props => {
  const { palette } = useTheme();

  return (
    <TablePlaceholder
      color={palette.error.main}
      message={props.errorMessage || DEFAULT_ERROR_MESSAGE}
      variant={props.placeholderVariant}
    />
  );
};

const TableContainer = props => {
  if (
    !props.rows.length &&
    !props.loading &&
    props.placeholderVariant !== TablePlaceholderVariant.TABLE
  ) {
    return <TablePlaceholder message={props.noDataMessage} variant={props.placeholderVariant} />;
  }

  return <WrapTable {...props} />;
};

TableContainer.propTypes = WrapTableProps;

TableContainer.defaultProps = {
  placeholderVariant: TablePlaceholderVariant.TABLE,
  noDataMessage: DEFAULT_EMPTY_MESSAGE
};

export default compose(
  withTheme,
  withSergeantTheme,
  withErrorBoundary(ErrorTable),
  memo
)(TableContainer);
