import gql from 'graphql-tag';

const propertyAttachmentUpdateNotification = gql`
  subscription propertyAttachmentUpdateNotification($partitionKey: String!) {
    propertyAttachmentUpdateNotification(partitionKey: $partitionKey) {
      fileName
      customFileName
      fileUrl
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      description
      createdDate
      createdDateTime
      createdBy
      parentId
    }
  }
`;

export default propertyAttachmentUpdateNotification;
