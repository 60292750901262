import gql from 'graphql-tag';

export const batchMutateJobTasks = gql`
  mutation batchMutateJobTasks($partitionKey: String!, $data: BatchMutateJobTasksInput!) {
    batchMutateJobTasks(partitionKey: $partitionKey, data: $data) {
      id
      name
      asset {
        id
      }
      assetId
      description
      formData {
        items {
          id
          form {
            id
            name
          }
          formDefinitionId
          formDefinitionSortKey
          formId
          formSortKey
          isRequired
        }
      }
      isActive
      lastUpdatedBy
      lastUpdatedDateTime
      sortOrder
      status
      taskEntries {
        items {
          id
          name
          description
          priceBookEntry {
            id
            product {
              code
              description
              id
              name
            }
          }
          priceBookEntryId
          productId
          quantity
          version
        }
      }
      taskNumber
      version
    }
  }
`;
