export const useStyles = () => ({
  label: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  text: {
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 'normal',
    letterSpacing: '0.01px',
    lineHeight: '14px'
  },
  required: {
    textTransform: 'uppercase',
    fontSize: 8,
    letterSpacing: 0,
    lineHeight: '14px'
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 4
  },
  errorText: {
    fontSize: 12,
    color: 'red'
  },
  errorIcon: theme => ({
    color: theme.palette.error.main,
    fontSize: 12
  })
});
