import gql from 'graphql-tag';

const deleteFee = gql`
  mutation deleteFee($partitionKey: String!, $id: String!) {
    deleteFee(partitionKey: $partitionKey, id: $id) {
      id
      sortKey
    }
  }
`;

export default deleteFee;
