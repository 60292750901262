import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
    position: 'relative'
  },
  boardFilter: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    width: 175,
    background: 'white',
    borderRadius: 4
  }
}));
