import gql from 'graphql-tag';

const getWIPReportSettings = gql`
  query getWIPReportSettings($partitionKey: String!) {
    getWIPReportSettings(partitionKey: $partitionKey) {
      overBillingGlAccountId
      overBillingGlOffsetAccountId
      underBillingGlAccountId
      underBillingGlOffsetAccountId
      accountingClassId
    }
  }
`;

export default getWIPReportSettings;
