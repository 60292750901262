import gql from 'graphql-tag';

const deleteProjectType = gql`
  mutation deleteProjectType($partitionKey: String!, $id: String!) {
    deleteProjectType(partitionKey: $partitionKey, id: $id) {
      id
      name
      sortOrder
    }
  }
`;

export default deleteProjectType;
