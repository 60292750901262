import gql from 'graphql-tag';
import { VisitStatus } from 'utils/AppConstants';

const getEmployeeWithVisitsAndPayrolls = gql`
  query getEmployeeWithVisitsAndPayrolls(
    $partitionKey: String!
    $sortKey: String!
    $startTime: Int!
    $endTime: Int!
  ) {
    getEmployee(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      sortKey
      partitionKey

      employeePayrolls(entityConnection: "EmployeePayroll") {
        items {
          id
          status
          timeCards: timeCardsInRange(
            entityConnection: "TimeCard",
            rangeFilter: {startTime: $startTime, endTime: $endTime}
          ) {
            items {
              id
              partitionKey
              sortKey
              timeCardDate
              version
              timeCardLines(entityConnection: "TimeCardLine") {
                items {
                  id
                  visitId
                  offScheduleId
                  nonVisitEventId
                  timeType
                  timeTypeAbbreviation
                  timeMinutes
                  version
                  isActive
                  lastUpdatedBy
                  lastUpdatedDateTime
                  auditLogs(entityConnection: "AuditLogEntry") {
                    items {
                      auditedEntityType
                      auditedEntityDisplayName
                      executionType
                      executedBy
                      executedDateTime
                      auditedEntityParentEntityType
                      auditedEntityParentDisplayName
                      changeLog
                    }
                  }
                }
              }
              timeCardVisits(entityConnection: "TimeCardVisit") {
                items {
                  id
                  jobId
                  visitId
                  offScheduleId
                  nonVisitEventId
                  approvedBy
                  approvedDateTime
                  reopenReason
                  status
                  version
                  notes(entityConnection: "Note") {
                    items {
                      id
                      note
                      subject
                      version
                      lastUpdatedBy
                      lastUpdatedDateTime
                      auditLogs(entityConnection: "AuditLogEntry") {
                        items {
                          auditedEntityType
                          auditedEntityDisplayName
                          executionType
                          executedBy
                          executedDateTime
                          auditedEntityParentEntityType
                          auditedEntityParentDisplayName
                          changeLog
                        }
                      }
                    }
                  }
                  auditLogs(entityConnection: "AuditLogEntry") {
                    items {
                      auditedEntityType
                      auditedEntityDisplayName
                      executionType
                      executedBy
                      executedDateTime
                      auditedEntityParentEntityType
                      auditedEntityParentDisplayName
                      changeLog
                    }
                  }
                }
              }
              formData(entityConnection: "FormData") {
                items {
                  id
                  formDataJson
                  version
                  createdDateTime
                  lastUpdatedDateTime
                  sortKey
                  text1
                  text2
                  text3
                  text4
                  text5
                  text6
                  text7
                  text8
                  text9
                  text10
                  text11
                  text12
                  text13
                  text14
                  text15
                  longText1
                  longText2
                  longText3
                  longText4
                  longText5
                  longText6
                  longText7
                  longText8
                  longText9
                  longText10
                  number1
                  number2
                  number3
                  number4
                  number5
                  number6
                  number7
                  number8
                  number9
                  number10
                  number11
                  number12
                  number13
                  number14
                  number15
                  pickList1
                  pickList2
                  pickList3
                  pickList4
                  pickList5
                  pickList6
                  pickList7
                  pickList8
                  pickList9
                  pickList10
                  pickList11
                  pickList12
                  pickList13
                  pickList14
                  pickList15
                  lookup1
                  lookup2
                  lookup3
                  lookup4
                  lookup5
                  lookup6
                  lookup7
                  lookup8
                  lookup9
                  lookup10
                  date1
                  date2
                  date3
                  date4
                  date5
                  date6
                  date7
                  date8
                  date9
                  date10
                  richText1
                  richText2
                  richText3
                  richText4
                  richText5
                  checkBox1
                  checkBox2
                  checkBox3
                  checkBox4
                  checkBox5
                  checkBox6
                  checkBox7
                  checkBox8
                  checkBox9
                  checkBox10
                  form(entityConnection: "form") {
                    id
                    name
                    description
                  }
                }
              }
            }
          }
        }
      }

      allVisitsView(
        startDate: $startTime
        endDate: $endTime
        status: [
          "${VisitStatus.ON_HOLD}"
          "${VisitStatus.COMPLETE}"
          "${VisitStatus.WORKING}"
          "${VisitStatus.TRAVELING}"
          "${VisitStatus.PAUSED}"
          "${VisitStatus.CONVERTED}"
          "${VisitStatus.CLOSED}"
        ]
      ) {
        items {
          id
          departmentName
          departmentId
          sortKey
          partitionKey
          visitNumber
          description
          version
          tenantCompanyId
          tenantId
          hierarchy
          actualDuration
          minimumDuration
          status
          startTime
          endTime
          scheduledFor
          onHold
          onHoldReason
          onRoute
          entityType
          parentEntity {
            ... on Job {
              id
              entityType
              partitionKey
              sortKey
              tenantId
              tenantCompanyId
              hierarchy
              jobNumber
              issueDescription
              customIdentifier
              customerName
              customerPropertyName
              customerPropertyTypeName
              jobTypeName
              jobTypeInternal
              customerRepName
              amountNotToExceed
              amountQuoted
              customerProvidedPONumber
              parentEntity {
                ... on CustomerProperty {
                  id
                  companyName
                  entityType
                  hierarchy
                  partitionKey
                  sortKey
                  tenantId
                  tenantCompanyId
                  parentId
                  customerPropertyTypeValue
                  parentEntity {
                    ... on Customer {
                      id
                      entityType
                      partitionKey
                      sortKey
                      customerName
                      email
                      phonePrimary
                    }
                  }
                }
              }
            }
          }
          schedules(
            entityConnection: "Schedule"
            filter: { employeeSortKey: { eq: $sortKey } }
          ) {
            items {
              id
              partitionKey
              sortKey
              hierarchy
              version
              entityType
              tenantCompanyId
              tenantId
              employeeSortKey
              scheduledFor
              startTime
              endTime
              onRoute
              delayed
              delayedReason
              status
              timeSheets(entityConnection: "TimeSheet") {
                items {
                  id
                  partitionKey
                  sortKey
                  hierarchy
                  version
                  entityType
                  tenantCompanyId
                  tenantId
                  clockInTime
                  clockOutTime
                  visitDate
                  labourType
                  totalDuration
                }
              }
            }
          }
        }
        nextToken
      }

      offSchedules(
        entityConnection: "OffSchedule"
        filter: {
          offDate: { between: [$startTime, $endTime] }
        }
      ) {
        items {
          entityType
          offDate
          offReason
          startTime
          endTime
          sortKey
          partitionKey
          id
        }
        nextToken
      }
    }
  }
`;

export default getEmployeeWithVisitsAndPayrolls;
