import gql from 'graphql-tag';

const addTimeCardLinesToTimeCard = gql`
  mutation AddTimeCardLinesToTimeCard(
    $partitionKey: String
    $data: AddTimeCardLinesToTimeCardInput!
  ) {
    addTimeCardLinesToTimeCard(partitionKey: $partitionKey, data: $data) {
      id
      timeType
      timeMinutes
      visitId
      offScheduleId
      nonVisitEventId
    }
  }
`;

export default addTimeCardLinesToTimeCard;
