import React from 'react';
import { bool, shape, string } from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@buildhero/sergeant';

import TechStatus from '../TechStatus';
import { useStyles } from './TechCard.styles';

const TechCard = ({ tech, techLoading, showStatus }) => {
  const { profilePictureUrl } = tech;
  const classes = useStyles({ profilePictureUrl });
  return (
    <>
      {techLoading ? (
        <Skeleton className={classes.techPhoto} circle height={40} width={40} />
      ) : (
        <div className={classes.techPhoto} />
      )}
      <div>
        <Typography loading={techLoading} width={100} className={classes.techName} variant="h6">
          <Tooltip title={tech.role}>
            <span>{tech.name}</span>
          </Tooltip>
        </Typography>
        {!techLoading && showStatus && <TechStatus techStatus={tech?.status} />}
      </div>
    </>
  );
};

TechCard.propTypes = {
  tech: shape({
    name: string,
    profilePictureUrl: string,
    role: string,
    status: string
  }).isRequired,
  techLoading: bool,
  showStatus: bool
};

TechCard.defaultProps = {
  techLoading: false,
  showStatus: false
};

export default TechCard;
