import React, { useEffect, useState } from 'react';

import { Button, ButtonType, ThemeProvider } from '@buildhero/sergeant';
import { Divider, Grid } from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { useSelector } from 'react-redux';

import CustomTabs from '@pm/components/CustomTabs';

import { ConfirmLeave, PageHeader, Spinner } from 'components';
import { useSnackbar } from 'customHooks/useSnackbar';

import Labels from 'meta/labels';
import ErrorBoundaries from 'scenes/Error';
import { checkPermission } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';
import { Mode } from 'utils/constants';

import {
  addSettings,
  getPayrollHourTypes,
  getSettings,
  updatePayrollHourType,
  updateSettings
} from '../payrollSettings-service';

import styles from '../styles';

import BillingHourRatesAndTypes from './BillingHourRatesAndTypes';
import GeneralSettings from './GeneralSettings';
import LaborRateGroupsAndTypes from './LaborRateGroupsAndTypes';
import LaborRateModifiers from './LaborRateModifiers';
import PayrollHourRatesAndTypes from './PayrollHourRatesAndTypes';

const TABS = {
  GENERAL_SETTINGS: { label: 'General Settings', value: 0 },
  LABOR_RATE_GROUPS_AND_TYPES: { label: 'Labor Rate Groups & Types', value: 1 },
  LABOR_RATE_MODIFIERS: { label: 'Labor Rate Modifiers', value: 2 },
  PAYROLL_HOUR_RATES_AND_TYPES: { label: 'Payroll Hour Rates & Types', value: 3 },
  BILLING_HOUR_RATES_AND_TYPES: { label: 'Billing Hour Rates & Types', value: 4 }
};

const EnterpriseLaborSettings = () => {
  const user = useSelector(s => s.user);
  const { tenantId, tenantCompanyId } = user;
  const snackbar = useSnackbar();

  document.title = 'BuildOps - Payroll';

  const isAllowedToEdit = checkPermission(
    Mode.EDIT,
    PermissionConstants.OBJECT_TIMETRACKINGSETTING,
    user
  );

  const [isLoading, setIsLoading] = useState(true);
  const [formChanged, setFormChanged] = useState(false);
  const [settingService, setSettingService] = useState();
  const [payrollSettings, setPayrollSettings] = useState();
  const [payrollHourTypeOptions, setPayrollHourTypeOptions] = useState([]);
  const [payrollRegHourTypeSelect, setPayrollRegHourTypeSelect] = useState({});
  const [payrollOTHourTypeSelect, setPayrollOTHourTypeSelect] = useState({});
  const [defaultBillingProduct, setDefaultBillingProduct] = useState('');

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const getPayrollSettings = async () => {
      const settings = await getSettings(tenantId, tenantCompanyId, snackbar);
      setPayrollSettings(settings);
      setDefaultBillingProduct({
        id: settings.defaultProductId,
        name: settings.defaultProductName
      });
    };

    const getPayrollHourTypeOptions = async () => {
      const types = await getPayrollHourTypes(tenantId, tenantCompanyId, snackbar);
      const options = types.map(t => ({
        label: `${t.hourType} (${t.hourTypeAbbreviation})`,
        value: t
      }));
      setPayrollHourTypeOptions(options);
      setPayrollRegHourTypeSelect(options.find(o => o.value.hourTypeTag === 'REG'));
      setPayrollOTHourTypeSelect(options.find(o => o.value.hourTypeTag === 'OT'));
    };

    const initialize = async () => {
      setIsLoading(true);
      await getPayrollSettings();
      await getPayrollHourTypeOptions();
      setIsLoading(false);
    };

    initialize();

    // Want this function to be called only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormComplete = async filledFormData => {
    // TODO - move the "Map Payroll Hours to Billing Hours" setting to the Payroll Hour Rates & Types tab
    const settingsJsonPayload = {
      ...filledFormData,
      defaultProductId: defaultBillingProduct.id,
      defaultProductName: defaultBillingProduct.name
    };

    let data;
    if (filledFormData?.id) {
      data = await updateSettings(settingsJsonPayload, user.tenantId, snackbar);
    } else {
      data = await addSettings(settingsJsonPayload, user.tenantId, user.tenantCompanyId, snackbar);
    }
    setPayrollSettings(data);
    setDefaultBillingProduct({
      id: data.defaultProductId,
      name: data.defaultProductName
    });
  };

  const headerButtons = (
    <ThemeProvider>
      <Button
        type={ButtonType.PRIMARY}
        css={{ float: 'right' }}
        onClick={() => settingService?.submit()}
      >
        Save
      </Button>
    </ThemeProvider>
  );
  const isTouched = Boolean(defaultBillingProduct?.unsaved) || formChanged;

  const handlePayrollExportSettingsSelect = async (type, option) => {
    const optionToClear = payrollHourTypeOptions.find(o => o.value.hourTypeTag === type);
    if (optionToClear !== undefined) {
      await updatePayrollHourType(
        { ...optionToClear.value, hourTypeTag: null },
        tenantId,
        snackbar
      );
    }

    if (type === 'REG') {
      setPayrollRegHourTypeSelect(option);
      await updatePayrollHourType({ ...option.value, hourTypeTag: 'REG' }, tenantId, snackbar);
    }
    if (type === 'OT') {
      setPayrollOTHourTypeSelect(option);
      await updatePayrollHourType({ ...option.value, hourTypeTag: 'OT' }, tenantId, snackbar);
    }
  };

  return (
    <ErrorBoundaries>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ConfirmLeave when={isTouched} />
          <Grid container>
            <Grid item css={styles.stickyHeader}>
              <PageHeader
                title={Labels.laborSettings[user.locale]}
                breadcrumbsArray={[{ link: '', title: Labels.settings[user.locale] }]}
                iconComponent={<SettingsOutlinedIcon css={styles.settingIcon} />}
                overrideHeaderButtons={isAllowedToEdit ? headerButtons : ''}
              />
            </Grid>
          </Grid>
          <Grid container direction="column">
            <Grid item css={{ marginTop: 150 }}>
              <CustomTabs
                value={selectedTab}
                tabMenus={Object.values(TABS).map(tab => ({ tabLabel: tab.label }))}
                onChange={(event, newValue) => setSelectedTab(newValue)}
              />
              <Divider fullwidth />
            </Grid>
            {selectedTab === TABS.GENERAL_SETTINGS.value && (
              <GeneralSettings
                isAllowedToEdit={isAllowedToEdit}
                payrollSettings={payrollSettings}
                setSettingService={setSettingService}
                setFormChanged={setFormChanged}
                handleFormComplete={handleFormComplete}
              />
            )}
            {selectedTab === TABS.LABOR_RATE_GROUPS_AND_TYPES.value && <LaborRateGroupsAndTypes />}
            {selectedTab === TABS.LABOR_RATE_MODIFIERS.value && <LaborRateModifiers />}
            {selectedTab === TABS.PAYROLL_HOUR_RATES_AND_TYPES.value && (
              <PayrollHourRatesAndTypes
                payrollRegHourTypeSelect={payrollRegHourTypeSelect}
                payrollHourTypeOptions={payrollHourTypeOptions}
                payrollOTHourTypeSelect={payrollOTHourTypeSelect}
                handlePayrollExportSettingsSelect={handlePayrollExportSettingsSelect}
                styles={styles}
              />
            )}
            {selectedTab === TABS.BILLING_HOUR_RATES_AND_TYPES.value && (
              <BillingHourRatesAndTypes
                styles={styles}
                defaultBillingProduct={defaultBillingProduct}
                setDefaultBillingProduct={setDefaultBillingProduct}
              />
            )}
          </Grid>
        </>
      )}
    </ErrorBoundaries>
  );
};

export default EnterpriseLaborSettings;
