const styles = theme => ({
  userControls: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingLeft: 16
  },
  search: {
    position: 'relative',
    flexGrow: 1,
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.grayscale(100),
    color: theme.palette.text.primary,
    height: 40,
    paddingLeft: theme.spacing(1),
    marginRight: theme.spacing(2)
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  searchPopOver: {
    width: '100%'
  },
  searchInputRoot: {
    color: 'inherit'
  },
  searchInput: {
    padding: 0,
    height: 40,
    width: '100%'
  },
  searchIcon: {
    color: theme.palette.grayscale(60),
    fontSize: 20
  },
  clearIcon: {
    color: theme.palette.grayscale(60),
    fontSize: 20
  },
  clearSearchButton: {
    position: 'absolute',
    top: 10,
    right: 8,
    padding: 0
  },
  profileButton: {
    opacity: 0.8,
    '&:hover': {
      opacity: 1
    }
  }
});
export default styles;
