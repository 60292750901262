import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { Logger } from 'services/Logger';

import styles from './styles';

// Renders Buildhero branded checkbox for form inputs
class MuiCheckBox extends Component {
  handleChange = () => event => {
    const { field, form, specialbehaviour } = this.props;
    // Formik expects string values
    const boolAsString = `${event.target.checked}`;

    if (specialbehaviour.controlField && field.value !== boolAsString) {
      const { mappings } = specialbehaviour;
      const mappedValues = {};
      const checkBoxChecked = boolAsString === 'true';

      Object.keys(mappings).forEach(key => {
        if (checkBoxChecked) {
          // allows for the form to remember values before the checkbox was selected
          mappedValues[`previous_${key}`] = form.values[key];
          mappedValues[key] = checkBoxChecked ? form.values[mappings[key]] || '' : '';
        } else {
          mappedValues[key] = form.values[`previous_${key}`] || '';
        }
      });
      const newValues = {
        ...form.values,
        ...mappedValues,
        [field.name]: boolAsString,
        checkBoxChecked
      };
      form.setValues(newValues);
      return;
    }

    form.setFieldValue(field.name, boolAsString, false);
  };

  componentDidMount = () => {
    try {
      const { specialbehaviour } = this.props;
      if (specialbehaviour?.defaultChecked) {
        this.handleChange()({ target: { checked: true } });
      }
    } catch (error) {
      Logger.error(error);
    }
  };

  render() {
    const { field, form, specialbehaviour, classes, user, theme, ...rest } = this.props;
    const { showField, queryResult, disabled } = specialbehaviour;

    if (showField && queryResult[showField]) {
      return <></>;
    }

    return (
      <FormControlLabel
        control={
          <Checkbox
            onChange={this.handleChange()}
            {...rest}
            className={classes.checkboxStyle}
            value={`${field.value}`}
            checked={`${field.value}` === 'true'}
            disabled={disabled}
          />
        }
        label={rest.placeholder}
        labelPlacement="start"
        className={classes.checkboxLabel}
      />
    );
  }
}
const StyledCheckbox = withStyles(styles, { withTheme: true })(MuiCheckBox);
export const ReadOnlyCheckboxInput = props => (
  <StyledCheckbox {...props} specialbehaviour={{ ...props.specialbehaviour, disabled: true }} />
);
export default StyledCheckbox;
