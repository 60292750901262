import { roundCurrency, roundFloat } from 'utils';
import { QuoteLineItemType } from 'utils/constants';
import { getMarkupValue } from 'utils/onCalcChange';
import Routes from 'scenes/Routes';

export const selectInventoryInput = formData => ({
  costCodeId: formData?.costCode?.value || null,
  departmentId: formData?.department?.value,
  departmentName: formData?.department?.label,
  description: formData?.description,
  itemName: formData?.name,
  jobCostTypeId: formData?.jobCostType?.value || null,
  markup: roundFloat(formData?.markupValue),
  priceBookEntryId: formData?.priceBookEntry?.id,
  quantity: formData?.quantity,
  revenueTypeId: formData?.revenueType?.value,
  taxable: formData?.taxable,
  truckId: formData?.truck?.value,
  unitCost: roundFloat(formData?.unitCost),
  unitOfMeasure: formData?.product?.unitOfMeasure?.name,
  unitPrice: roundCurrency(formData?.unitPrice)
});

export const selectInventoryItemUpdateInput = formData => ({
  ...selectInventoryInput(formData),
  id: formData?.id,
  version: formData?.version,
  includeInInvoice: formData?.includeInInvoice
});

export const selectInventoryItemFormData = ({ inventoryItem, departmentOptions }) => ({
  ...inventoryItem,
  department: departmentOptions.find(item => item.value === inventoryItem.departmentId),
  markupValue:
    inventoryItem?.markup ||
    (inventoryItem?.unitCost && inventoryItem?.unitCost
      ? getMarkupValue(inventoryItem?.unitCost, inventoryItem?.unitPrice)
      : null),
  name: inventoryItem?.itemName,
  addedBy: inventoryItem.addedBy || inventoryItem.createdBy,
  amount: roundCurrency(inventoryItem.quantity * inventoryItem.unitPrice),
  unitOfMeasure: inventoryItem?.name,
  costCode: !inventoryItem?.costCode?.id
    ? null
    : {
        value: inventoryItem.costCode.id,
        label: inventoryItem.costCode.name
      },
  jobCostType: !inventoryItem?.jobCostType?.id
    ? null
    : {
        value: inventoryItem.jobCostType.id,
        label: inventoryItem.jobCostType.name,
        type: inventoryItem.jobCostType.type
      },
  revenueType: !inventoryItem?.revenueType?.id
    ? null
    : {
        value: inventoryItem.revenueType.id,
        label: inventoryItem.revenueType.name,
        type: inventoryItem.revenueType.type
      },
  truck: !inventoryItem?.truck?.id
    ? null
    : {
        value: inventoryItem.truck.id,
        label: inventoryItem.truck.name
      }
});

export const getInvoiceLineItemsByLineItemType = (jobData, lineItemTypeArray = []) =>
  jobData.invoices?.items
    ?.map(inv =>
      inv.invoiceItems?.items
        ?.filter(item => lineItemTypeArray.includes(item.lineItemType))
        ?.map(iItem => ({
          ...iItem,
          subtotal: iItem.quantity * iItem.unitPrice,
          source: `Invoice ${inv.invoiceNumber}`,
          sourceLink: Routes.invoice({ mode: 'view', id: inv.id })
        }))
    )
    .flat();

export const getDiscountItemsFromQuote = jobData => {
  const allQuotes = jobData.quoteJobs?.items?.map(qj => qj.quote) || [];
  return allQuotes
    .map(q =>
      q.quoteLineProducts?.items
        ?.filter(qp => qp.lineItemType === QuoteLineItemType.DISCOUNT)
        .map(qProducts => ({
          ...qProducts,
          subtotal: qProducts.quantity * qProducts.unitPrice,
          source: `Quote ${q.customIdentifier || q.quoteNumber}`,
          sourceLink: Routes.quote({ mode: 'view', id: q.id })
        }))
    )
    .flat();
};
