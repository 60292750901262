import gql from 'graphql-tag';

const addTimeCardsToEmployeePayroll = gql`
  mutation AddTimeCardsToEmployeePayroll(
    $partitionKey: String
    $data: AddTimeCardsToEmployeePayrollInput!
  ) {
    addTimeCardsToEmployeePayroll(partitionKey: $partitionKey, data: $data) {
      id
    }
  }
`;

export default addTimeCardsToEmployeePayroll;
