import gql from 'graphql-tag';

const getPaymentById = gql`
  query getPaymentById($id: String!) {
    getPaymentById(id: $id) {
      id
      sortKey
      paymentNumber
      paymentAmount
      paymentDate
      paymentStatus
      exportStatus
      accountType
      createdDate
      version
      syncStatus
      syncLog
      lastUpdatedDateTime
      availableAmount: adjustedBalance
      bankAccountId
      bankAccount {
        id
        name
        accountingRefId
        currencyCode
      }
      glAccountId
      glAccount {
        id
        name
      }
      billingCustomer(entityConnection: "BillingCustomer") {
        id
        customerName
        companyAddresses(
          entityConnection: "CompanyAddress"
          filter: { addressType: { eq: "billingAddress" } }
        ) {
          items {
            id
            addressType
            addressLine1
            addressLine2
            billTo
            city
            state
            zipcode
            longitude
            latitude
          }
        }
      }
      paymentType(entityConnection: "PaymentType") {
        id
        name
        ledgerAccountId
        ledgerAccount(entityConnection: "LedgerAccount") {
          name
          accountNumber
          accountType
          accountingRefId
          accountingApplication
          id
        }
      }
      paymentInvoices(entityConnection: "PaymentInvoice") {
        items {
          id
          invoiceId
          appliedAmount
          version
          invoice(entityConnection: "Invoice") {
            id
            invoiceNumber
          }
          deletedDate
          lastUpdatedDateTime
        }
      }
      adjustmentTransactions {
        items {
          appliedAmount
          adjustment {
            id
            number
          }
        }
      }
      aggregatedField(
        input: {
          entityConnection: "PaymentInvoice"
          aggregations: [{ aggregationType: SUM, aggregationField: "PaymentInvoice.appliedAmount" }]
        }
      ) {
        items {
          aggregation1
        }
      }
      auditLogs(entityConnection: "AuditLogEntry") {
        items {
          auditedEntityType
          auditedEntityDisplayName
          executionType
          executedBy
          executedDateTime
          auditedEntityId
          auditedEntitySortKey
          auditedEntityParentId
          auditedEntityParentSortKey
          auditedEntityParentEntityType
          auditedEntityParentDisplayName
          changeLog
          customMessage
        }
      }
    }
  }
`;

export default getPaymentById;
