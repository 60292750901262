import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StatusChip } from 'components';

const useStyles = makeStyles({
  chipLabel: {
    textTransform: 'capitalize'
  },
  chip: {
    marginRight: 16,
    height: 32,
    flex: 1,
    borderRadius: 2,
    filter: 'drop-shadow(0px 4px 15px rgba(51, 51, 51, 0.25))',
    '&:last-child': {
      marginRight: 0
    }
  }
});

function StatusFilter({ filterData, filterEnumType, onClickStatusFilter, disabled = false }) {
  const classes = useStyles();
  const handleClickFilter = clickedStatus => {
    onClickStatusFilter(clickedStatus);
  };

  return (
    <>
      {Object.values(filterData).map(status => (
        <StatusChip
          key={`status-filter-${status}`}
          label={status}
          enumType={filterEnumType}
          enumValue={status}
          onClick={() => handleClickFilter(status)}
          classes={{
            root: classes.chip,
            label: classes.chipLabel
          }}
          disabled={disabled}
        />
      ))}
    </>
  );
}

export default StatusFilter;
