/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, makeStyles, Box } from '@material-ui/core';
import _, { noop, omit, values } from 'lodash';
import { CustomerPropertyService, validations } from 'services/core';
import { Logger } from 'services/Logger';
import ErrorBoundaries from 'scenes/Error';
import { openTaskRowsMeta, completedTaskRowsMeta } from 'meta/Customer/Task/table';
import { recommendedTaskLayout } from 'meta/Customer/Task';
import {
  ResponsiveTable,
  PageForm,
  Modal,
  SergeantModal,
  UserPermission,
  CollapsibleSection,
  LinkButton,
  Context,
  AuditLogs
} from 'components';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { snackbarOn } from 'redux/actions/globalActions';
import { Button, MoreButton, ThemeProvider } from '@buildhero/sergeant';
import { convertStringToFloat, truncateString } from 'utils';
import { OpenTaskStatus, PermissionConstants, TaskConstants } from 'utils/AppConstants';
import { CustomFieldTypes, EntityType, Mode } from 'utils/constants';
import TaskDetail from './TaskDetail';
import AddTaskToWorkModal from './AddTaskToWorkModal';
import TaskStatusFilter from './TaskStatusFilter';

const { COMPLETED, OPEN } = TaskConstants;

/**
 * Creates customer page. new customer fields & layouts are generated from the meta file
 * labels are fetched from application level
 * locale of the user is referred from user context
 */
class Tasks extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted || true;
    this.CustomerPropertyServiceObj = new CustomerPropertyService();
    let departments;
    let taskTypes;
    let jobTypes;
    let listTenantSettings;
    let pricebookSetting;
    let isPriceBookEnabled = false;
    this.openTaskStatusValues = values({
      ...omit(TaskConstants, ['COMPLETED']),
      ADDED_TO_WORK: 'ADDED_TO_WORK'
    });

    if (Context.getCompanyContext()) {
      ({ departments, taskTypes, jobTypes } = Context.getCompanyContext().getCompany);
      if (jobTypes) {
        jobTypes.items = jobTypes.items?.filter(type => type.tagType === CustomFieldTypes.JobTypes);
      }
      ({ listTenantSettings } = Context.getCompanyContext());
      pricebookSetting =
        listTenantSettings &&
        listTenantSettings.filter(
          setting => setting.settingKey === 'pricebook' && setting.settingValue === 'true'
        );
    }

    if (pricebookSetting && pricebookSetting.length > 0) {
      isPriceBookEnabled = true;
    }

    this.state = {
      openTaskPopup: false,
      mode: 'new',
      record: '',
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: '',
      confirmTaskName: '',
      departments,
      taskTypes,
      jobTypes,
      isPriceBookEnabled,
      refreshCount: 0,
      completedTaskCount: 0,
      activityModal: { open: false, data: null },
      taskTableFilter: ''
    };
  }

  getTaskNumber = async () => {
    const { data, error } = await this.CustomerPropertyServiceObj.getTaskNumber();
    if (data) {
      const result = data.getCurrentCounter;
      const taskNumber = JSON.parse(result).task;
      return taskNumber;
    }
    if (error) {
      this.props.snackbarOn('error', 'Unable to create task, please try again later', error);
      return '';
    }
  };

  getTasksByCustomerPropertyById = async ({ filter, limit, offset, sortBy, sortOrder }, mode) => {
    if (!this.props.user.tenantId) {
      return { items: [], nextToken: '0' };
    }
    let data;
    try {
      data = await this.CustomerPropertyServiceObj.getTasksByCustomerPropertyById(
        this.props.propertyId,
        filter,
        limit,
        offset,
        sortBy,
        sortOrder
      );

      const { JOB, QUOTE, MAINTENANCE_JOB } = EntityType;
      ((data || {}).items || []).map(dataItem => {
        const localDataItem = dataItem;
        const getAssociatedWork = task => {
          const job = task?.jobs[0];
          const jobType = job?.jobTypeInternal;
          const quoteNumber = task?.quoteNumber;
          if ([JOB, MAINTENANCE_JOB].includes(jobType)) {
            localDataItem.associatedWorkLinkConfig = {
              linkPath: `/${jobType === JOB ? 'job' : 'maintenance'}/${Mode.VIEW}`,
              linkReference: job.jobNumber
            };
            return `${jobType}-${job?.customIdentifier || job?.jobNumber || '-'}`;
          }
          if (quoteNumber && task?.quoteId) {
            localDataItem.associatedWorkLinkConfig = {
              linkPath: `/quote`,
              linkReference: task?.quoteId
            };
            return quoteNumber && `${QUOTE}-${task?.customIdentifier || quoteNumber || '-'}`;
          }
          localDataItem.associatedWorkLinkConfig = {
            linkPath: '',
            linkReference: ''
          };
          return '';
        };
        localDataItem.associatedWork = getAssociatedWork(localDataItem);
        if (dataItem.status) {
          localDataItem.openStatus = TaskConstants[dataItem.status] || dataItem.status;
          if (![OPEN, TaskConstants.PENDING].includes(localDataItem.status)) {
            localDataItem.openStatus = OpenTaskStatus.ADDED_TO_WORK;
            localDataItem.selectionDisabled = true;
          }
          if (localDataItem.status === COMPLETED) {
            // if the task is complete, set the completed by data
            localDataItem.completedBy = localDataItem?.lastUpdatedBy;
            localDataItem.completedDate = localDataItem?.lastUpdatedDateTime;
          }
        }
        return localDataItem;
      });

      const tasksByMode = (data?.items || []).filter(task => {
        if (mode === COMPLETED) return task.status === COMPLETED;
        return task.status !== COMPLETED;
      });

      if (mode === COMPLETED) {
        const completedTaskCount = tasksByMode.length;
        this.setState({ completedTaskCount });
      }

      return {
        nextToken: tasksByMode?.length || '0',
        items: tasksByMode
      };
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to fetch tasks, please try again later');
    }
    return data || [];
  };

  addTaskToProperty = async values => {
    const taskNumber = await this.getTaskNumber();
    const { departments, taskTypes, jobTypes, refreshCount } = this.state;
    const chosenDepartment =
      (values.departmentId && departments.items.filter(item => item.id === values.departmentId)) ||
      [];
    const chosenTask =
      (values.taskTypeId && taskTypes.items.filter(item => item.id === values.taskTypeId)) || [];
    const chosenJobType =
      (values.jobTypeId && jobTypes.items.filter(item => item.id === values.jobTypeId)) || [];

    const data = {
      customerPropertyId: this.props.propertyId,
      tasks: [
        {
          name: values.name || null,
          taskNumber: taskNumber || null,
          description: values.description || null,
          departmentId: values.departmentId || null,
          departmentValue:
            (chosenDepartment && chosenDepartment.length > 0 && chosenDepartment[0].tagName) ||
            null,
          taskTypeId: values.taskTypeId || null,
          taskTypeValue: (chosenTask && chosenTask.length > 0 && chosenTask[0].tagName) || null,
          jobTypeId: values.jobTypeId || null,
          jobTypeValue:
            (chosenJobType && chosenJobType.length > 0 && chosenJobType[0].tagName) || null,
          taskTypeInternal: 'Recommended',
          productBundleValue: values.productBundleValue || null,
          productBundleId: values.productBundleId || null,
          status: 'Open',
          isActive: true
        }
      ]
    };

    const response = await this.CustomerPropertyServiceObj.addTasksToCustomerProperty(
      this.props.user.tenantId,
      data
    );

    if (
      response &&
      response.data &&
      response.data.addTasksToCustomerProperty &&
      response.data.addTasksToCustomerProperty.length > 0
    ) {
      if (values.taskLineItems && values.taskLineItems.length > 0) {
        await this.addTaskEntryToTask(
          response.data.addTasksToCustomerProperty[0].id,
          values.taskLineItems
        );
      }

      this.props.snackbarOn('success', `Successfuly added task - #${taskNumber}`);
      this.setState({
        openTaskPopup: false,
        mode: 'new',
        record: '',
        refreshCount: refreshCount + 1
      });
    }
  };

  addTaskEntryToTask = async (taskId, values) => {
    if (!values || !Array.isArray(values)) {
      Logger.error('Error in picking products');
      return;
    }
    const payload = {
      taskId,
      taskEntries: []
    };

    const updatePayload = {
      taskEntries: []
    };
    values.forEach(item => {
      const taskEntryItem = {
        isActive: true,
        priceBookEntryId: item.id,
        productId: item.productId,
        name: item.name || null,
        description: item.description || null,
        unitPrice: convertStringToFloat(item.unitPrice || 0),
        unitCost: convertStringToFloat(item.unitCost || 0),
        markupType: item.markupType || 'Percentage',
        markupValue: item.markupValue || 0,
        quantity: convertStringToFloat(item.quantity) || 0,
        taxable: item.taxable === true
      };
      if (item.entityType !== 'TaskEntry') {
        payload.taskEntries.push(taskEntryItem);
      } else {
        taskEntryItem.id = item.id;
        taskEntryItem.productId = item.productId;
        taskEntryItem.version = item.version;
        updatePayload.taskEntries.push(taskEntryItem);
      }
    });

    try {
      if (payload.taskEntries.length > 0) {
        await this.CustomerPropertyServiceObj.addTaskEntriesToTask(
          this.props.user.tenantId,
          payload
        );
      }

      if (updatePayload.taskEntries.length > 0) {
        await this.CustomerPropertyServiceObj.updateTaskEntriesForTask(
          this.props.user.tenantId,
          updatePayload
        );
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to add products to the task');
    }
  };

  updateTask = async values => {
    const { departments, taskTypes, jobTypes, refreshCount } = this.state;
    const chosenDepartment =
      (values.departmentId && departments.items.filter(item => item.id === values.departmentId)) ||
      [];
    const chosenTask =
      (values.taskTypeId && taskTypes.items.filter(item => item.id === values.taskTypeId)) || [];
    const chosenJobType =
      (values.jobTypeId && jobTypes.items.filter(item => item.id === values.jobTypeId)) || [];
    const data = {
      id: values.id,
      version: values.version,
      name: values.name || null,
      taskNumber: values.taskNumber || null,
      description: values.description || null,
      departmentId: values.departmentId || null,
      departmentValue:
        (chosenDepartment && chosenDepartment.length > 0 && chosenDepartment[0].tagName) || null,
      taskTypeId: values.taskTypeId || null,
      taskTypeValue: (chosenTask && chosenTask.length > 0 && chosenTask[0].tagName) || null,
      jobTypeId: values.jobTypeId || null,
      jobTypeValue: (chosenJobType && chosenJobType.length > 0 && chosenJobType[0].tagName) || null,
      taskTypeInternal: 'Recommended',
      productBundleValue: values.productBundleValue || null,
      productBundleId: values.productBundleId || null
    };

    const response = await this.CustomerPropertyServiceObj.updateTask(
      this.props.user.tenantId,
      data
    );
    if (response && response.data) {
      if (values.taskLineItems && values.taskLineItems.length > 0) {
        await this.addTaskEntryToTask(values.id, values.taskLineItems);
      }
      this.props.snackbarOn('success', `Successfuly updated task - #${values.taskNumber}`);
      this.setState({
        openTaskPopup: false,
        mode: 'new',
        record: '',
        refreshCount: refreshCount + 1
      });
    }
  };

  deleteTaskEntry = async product => {
    try {
      const deleteResp = await this.CustomerPropertyServiceObj.deleteTaskEntryFromTask(
        this.props.user.tenantId,
        product.id
      );

      if (deleteResp) {
        this.props.snackbarOn('success', `Successfully deleted product ${product.name}`);
        this.setState(prevState => ({ ...prevState, refreshPageAfterDelete: true }));
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to delete product, please try again later', error);
    }
  };

  deleteTask = async task => {
    if (task && task.status !== 'Open') {
      this.props.snackbarOn(
        'error',
        `Task - ${task.name} is used in ${task.associatedWork}-${
          task?.associatedWork === 'Quote' ? task.quoteNumber : task?.jobs[0]?.jobNumber
        }`
      );
      this.setState({
        confirmDialog: false,
        confirmMessage: '',
        confirmAction: '',
        confirmTaskName: ''
      });
      return;
    }

    try {
      const data = await this.CustomerPropertyServiceObj.deleteTaskFromProperty(
        this.props.user.tenantId,
        task.id
      );
      if (data) {
        const { refreshCount } = this.state;
        this.props.snackbarOn('success', `Successfully deleted task ${task.name}`);
        this.setState({
          confirmDialog: false,
          confirmMessage: '',
          confirmAction: '',
          confirmTaskName: '',
          refreshCount: refreshCount + 1
        });
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to delete task, please try again later', error);
    }
  };

  handleCancelConfirmation = () => {
    if (this.mounted) {
      this.setState({
        confirmDialog: false,
        confirmMessage: '',
        confirmAction: '',
        confirmTaskName: ''
      });
    }
  };

  handleRowActions = (actionName, record) => {
    switch (actionName) {
      case 'view':
        this.setState({ mode: 'view', record, openTaskPopup: true });
        return;
      case 'select':
        this.setState({ selectedTasks: record || [] });
        break;
      default:
        break;
    }
  };

  taskFormData = record => {
    const { isPriceBookEnabled } = this.state;
    // regression fix for pricebook
    const taskLineItems = record?.products?.map(taskEntry => {
      if (!taskEntry.name) {
        // if data stored in priceBookEntry field
        const pbEntry = taskEntry?.priceBookEntry;
        taskEntry.name = pbEntry?.product?.name;
        taskEntry.description = pbEntry?.product?.description;
        taskEntry.unitPrice = pbEntry?.unitPrice;
        taskEntry.unitCost = pbEntry?.unitCost;
        taskEntry.markupValue = pbEntry?.markupValue;
        taskEntry.taxable = pbEntry?.taxable;
      }
      return taskEntry;
    });
    const processedData = {
      ...record,
      isPriceBookEnabled,
      taskLineItems,
      productFilter: { entityType: ['ProductBundle'] }
    };

    return processedData;
  };

  handleAdd = (type = '') => {
    if (!type) {
      this.setState(({ record }) => ({ mode: 'new', record, openTaskPopup: true }));
    } else if (type === 'addToWork') {
      this.setState(({ record }) => ({ mode: 'addToWork', record, openAddToWork: true }));
    }
  };

  AddToWorkButtonConfig = () => [
    {
      value: 'addToWork',
      caslKey: [PermissionConstants.OBJECT_TASK],
      caslAction: 'create',
      label: 'Add To Work',
      onClick: () => this.handleAdd('addToWork'),
      icon: () => <AddCircleOutlineOutlinedIcon style={{ marginRight: 8 }} />,
      disabled: !this.state.selectedTasks?.length
    }
  ];

  renderTaskTable = (mode, refreshCount) => {
    const metaData = mode === OPEN ? openTaskRowsMeta : completedTaskRowsMeta;
    const fetchTasksByMode = (filter, limit, offset, sortBy, sortOrder) =>
      this.getTasksByCustomerPropertyById({ filter, limit, offset, sortBy, sortOrder }, mode);
    const actionButtons = { select: { referenceKey: 'id' } };

    const useCustomLinkStyles = makeStyles(() => ({
      flexStart: {
        justifyContent: 'flex-start'
      },
      textWrapper: {
        whiteSpace: 'nowrap'
      }
    }));

    const CustomLinkComponent = props => {
      const { record = {}, meta } = props || {};
      const truncatedCellText = truncateString(record?.[meta?.id]);
      const recordLinkConfig = record.associatedWorkLinkConfig;
      const path = `${recordLinkConfig.linkPath}/${recordLinkConfig.linkReference}`;
      const classes = useCustomLinkStyles();
      return (
        <LinkButton
          label={truncatedCellText}
          path={path}
          state={{ [meta.linkStateKey]: record[meta.linkStateValue] }}
          classes={{
            label: `${classes.flexStart} ${classes.textWrapper}`
          }}
        />
      );
    };

    const CustomActionButtons = props => {
      const { ADDED_TO_WORK } = OpenTaskStatus;
      const isDeletable =
        ![ADDED_TO_WORK, COMPLETED].includes(props.record?.status) &&
        [OPEN, TaskConstants.PENDING].includes(props.record?.openStatus);
      let options = [
        {
          value: 'activity',
          onClick: () => this.setState({ activityModal: { open: true, data: props?.record } }),
          label: 'Activity'
        },
        {
          value: 'Edit',
          onClick: () =>
            this.setState({
              mode: 'edit',
              record: props?.record,
              openTaskPopup: true,
              refreshPageAfterDelete: false
            }),
          label: 'Edit'
        }
      ];
      if (isDeletable) {
        options = options.concat({
          value: 'Delete',
          onClick: () =>
            this.setState({
              confirmDialog: true,
              confirmAction: () => this.deleteTask(props?.record),
              confirmMessage: 'task',
              confirmTaskName: props?.record?.name || ''
            }),
          label: 'Delete'
        });
      }

      return (
        <UserPermission I="update" action={PermissionConstants.OBJECT_TASK}>
          <ThemeProvider>
            <MoreButton options={options} size="medium" />
          </ThemeProvider>
        </UserPermission>
      );
    };

    const addTaskButtonRef = React.createRef();

    return (
      <>
        {mode === OPEN && this.props.isActive && (
          <UserPermission I="create" action={PermissionConstants.OBJECT_TASK} key="permAddRow">
            <Box display="flex" flex={1} p={2} justifyContent="flex-end">
              <ThemeProvider>
                <Button
                  ref={addTaskButtonRef}
                  type="primary"
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  onClick={() => this.handleAdd()}
                >
                  ADD NEW TASK
                </Button>
                <MoreButton options={this.AddToWorkButtonConfig()} size="medium" />
              </ThemeProvider>
            </Box>
          </UserPermission>
        )}
        <ResponsiveTable
          tableName="property_open_tasks"
          fullScreen
          rowMetadata={metaData}
          service={fetchTasksByMode}
          customCellComponents={{
            CustomLink: CustomLinkComponent,
            CustomActionButtons
          }}
          rowActionButtons={mode === OPEN ? actionButtons : {}}
          rowActions={this.handleRowActions}
          noDataMsg="No tasks"
          caslKey={PermissionConstants.OBJECT_TASK}
          key={refreshCount}
          allRowsAreSelectable={false}
          topPanel={
            mode === OPEN
              ? ({ filter, setFilter }) => (
                  <Box display="flex" alignItems="center" flexDirection="row">
                    <TaskStatusFilter
                      onClickStatusFilter={status => {
                        setFilter({
                          ...filter,
                          status: {
                            condition: status === OpenTaskStatus.ADDED_TO_WORK ? 'ne' : 'eq',
                            type: 'stringFilters',
                            value:
                              status === OpenTaskStatus.ADDED_TO_WORK ? OpenTaskStatus.OPEN : status
                          }
                        });
                      }}
                    />
                  </Box>
                )
              : noop
          }
        />
      </>
    );
  };

  getCollapsibleSectionTitle = collapsed =>
    collapsed
      ? `Show Completed Tasks (${this.state.completedTaskCount})`
      : `Hide Completed Tasks (${this.state.completedTaskCount})`;

  render() {
    const {
      mode,
      record,
      departments,
      taskTypes,
      jobTypes,
      refreshCount,
      refreshPageAfterDelete
    } = this.state;
    const taskFormLayout = recommendedTaskLayout.entity.layouts.web;
    const taskFormButtons = recommendedTaskLayout.entity.layouts.web.buttons;
    if (taskFormButtons.cancel) {
      taskFormButtons.cancel.action = () => {
        if (refreshPageAfterDelete) {
          this.setState(prevState => ({
            ...prevState,
            openTaskPopup: false,
            record: '',
            mode: 'new',
            refreshCount: prevState.refreshCount + 1
          }));
        } else {
          this.setState({ openTaskPopup: false, record: '', mode: 'new' });
        }
      };
    }

    const useTableHeaderStyles = makeStyles(theme => ({
      header: {
        ...theme.typography.h6,
        fontSize: theme.typography.fontSize,
        marginBottom: theme.spacing(1)
      },
      description: {
        ...theme.typography.caption,
        fontWeight: 400
      },
      moreOptionsIcon: {
        marginRight: theme.spacing(1)
      }
    }));

    const TableHeader = () => {
      const classes = useTableHeaderStyles();
      return (
        <Grid>
          <Typography className={classes.header}>Open Property Tasks</Typography>
          <Typography className={classes.description}>
            View and add tasks to this property, and assign them to a job, maintenance, or quote.
          </Typography>
        </Grid>
      );
    };

    return (
      <ErrorBoundaries>
        <UserPermission I="read" action={PermissionConstants.OBJECT_TASK}>
          <Grid item xs={12}>
            <TableHeader />
            {this.renderTaskTable(OPEN, refreshCount)}
          </Grid>
          <Grid item xs={12}>
            <CollapsibleSection getDynamicTitle={this.getCollapsibleSectionTitle} useDynamicTitle>
              {this.renderTaskTable(COMPLETED, refreshCount)}
            </CollapsibleSection>
          </Grid>
          <Grid style={{ paddingTop: 40 }} />
          <Modal
            open={this.state.openTaskPopup}
            handleClose={() => this.setState({ mode: 'new', record: '', openTaskPopup: false })}
            width="1056"
          >
            <PageForm
              layout={taskFormLayout.sections}
              data={this.taskFormData(record)}
              mode={mode}
              buttons={taskFormButtons}
              validateWithSchema={validations.customerPropertyTaskSchema}
              queryResult={{
                departments: departments?.items ? departments.items : [],
                taskTypes: _.orderBy(
                  taskTypes?.items ? taskTypes.items : [],
                  ['sortOrder', 'tagName'],
                  ['asc', 'asc']
                ),
                jobTypes: jobTypes?.items ? jobTypes.items : [],
                deleteService: this.deleteTaskEntry
              }}
              skipSave
              showFooterButton
              onComplete={mode === 'new' ? this.addTaskToProperty : this.updateTask}
              entityName="Task"
              caslKey={PermissionConstants.OBJECT_TASK}
            />
            <Grid item style={{ margin: 5 }} />
            {mode === 'view' && (
              <TaskDetail
                record={record}
                refetch={() => this.setState({ refreshCount: refreshCount + 1 })}
                handleClose={() => {
                  this.setState({ mode: 'new', record: '', openTaskPopup: false });
                }}
                mode={mode}
              />
            )}
          </Modal>
          <SergeantModal
            open={this.state.confirmDialog}
            mode={Mode.DELETE}
            confirmRemoveItemLabel={this.state.confirmTaskName}
            confirmRemoveStatement="This delete will permanently delete the task from the property"
            dataType="Task"
            handleClose={() =>
              this.setState({
                confirmDialog: false,
                confirmMessage: '',
                confirmAction: ''
              })
            }
            handlePrimaryAction={this.state.confirmAction}
          />
          <AddTaskToWorkModal
            open={this.state.openAddToWork}
            handleClose={() => this.setState({ openAddToWork: false })}
            customerPropertyInfo={{
              propertyId: this.props.propertyId,
              ...this.props.customerPropertyInfo
            }}
            selectedTasks={this.state.selectedTasks || []}
            refreshTasks={() => this.setState({ refreshCount: refreshCount + 1 })}
            partitionKey={this.props.user.tenantId}
          />
          <Modal
            open={this.state.activityModal.open}
            handleClose={() => this.setState({ activityModal: { open: false, data: null } })}
          >
            <ErrorBoundaries>
              <Typography variant="h4">Activity</Typography>
              <div style={{ padding: 10, marginTop: 10 }}>
                <AuditLogs
                  variant="singleEntity"
                  dataService={() => {
                    return this.state.activityModal.data?.auditLogEntries?.items || [];
                  }}
                />
              </div>
            </ErrorBoundaries>
          </Modal>
        </UserPermission>
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const connectedTasks = connect(mapStateToProps, mapDispatcherToProps)(Tasks);
export default connectedTasks;
