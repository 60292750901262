import gql from 'graphql-tag';

const addCustomerPropertiesToCustomer = gql`
  mutation addCustomerPropertiesToCustomer(
    $partitionKey: String!
    $data: AddCustomerPropertiesToCustomerInput!
  ) {
    addCustomerPropertiesToCustomer(partitionKey: $partitionKey, data: $data) {
      id
      sortKey
      entityType
      version
      status
      isActive
      isTaxable
      syncStatus
      syncLog
    }
  }
`;

export default addCustomerPropertiesToCustomer;
