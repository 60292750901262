import React, { useState, useEffect } from 'react';
import { SergeantModal } from 'components';
import PurchaseOrderLayout from 'meta/Jobs/Invoice/PurchaseOrdersFormSergeant';
import { fileInputToAttachment, attachmentToFileInput } from 'components/AttachmentSection/helpers';
import { Logger } from 'services/Logger';
import { useSetStopLoading } from '../ReviewReport/ReviewReport.hook';

const PurchaseOrderModal = ({ open, data, mode, title, send, service, user }) => {
  const setStopLoading = useSetStopLoading(service);
  const fileItem = data?.purchaseOrderReceipts?.items?.[0];
  const [newFiles, setNewFiles] = useState(null);
  const [uploaded, setUploaded] = useState(!!fileItem);

  useEffect(() => {
    const fileUrl = fileItem?.imageUrl || data.fileUrl;
    if (fileUrl) {
      const attachment = { fileName: fileItem?.fileName || data.fileName, fileUrl };
      const fileType = fileUrl?.match(/\.(jpeg|jpg|gif|png)$/) != null ? 'image' : 'other';
      attachmentToFileInput(attachment, fileType)
        .then(fileData => setNewFiles(fileData.newFiles))
        .catch(e => Logger.error(e));
    }
  }, []);

  const handlePrimaryAction = async (values, stopLoading) => {
    const saveData = { ...values };
    if (newFiles !== values.newFiles) {
      // file has changed
      const { fileName, fileUrl } = await fileInputToAttachment(user.tenantId, values);
      saveData.fileUrl = fileUrl;
      if (!saveData.customFileName) saveData.fileName = fileName;
    } else if (fileItem) {
      // filename might have changed
      saveData.fileUrl = fileItem.imageUrl;
      if (!saveData.customFileName) saveData.fileName = fileItem.fileName;
    } else {
      delete saveData.fileUrl;
    }
    setStopLoading(stopLoading);
    send('SAVE', { saveData });
  };

  const onFieldChange = (key, currentValue) => {
    if (key === 'newFiles' && currentValue) {
      setUploaded(true);
    }
  };

  const customFileName = fileItem?.fileName || data.fileName;

  return (
    <SergeantModal
      open={open}
      data={{ ...data, customFileName, newFiles }}
      mode={mode}
      formVersion="edit"
      layout={PurchaseOrderLayout(data?.departmentName, uploaded)}
      onFieldChange={onFieldChange}
      handlePrimaryAction={handlePrimaryAction}
      handleClose={() => send('CLOSE')}
      title={title}
    />
  );
};

export default PurchaseOrderModal;
