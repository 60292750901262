import gql from 'graphql-tag';

const softDeleteTruck = gql`
  mutation softDeleteTruck($id: String!) {
    softDeleteTruck(id: $id) {
      id
    }
  }
`;

export default softDeleteTruck;
