export const unUsedEmployeeFields = [
  'appRole',
  'tenantId',
  'sortKey',
  'hierarchy',
  'version',
  'maxHeight',
  'maxWidth',
  'entityType',
  'partitionKey',
  'tenantCompanyId',
  'accountingRefId',
  'contactAddressId'
];

export const contactAddressesFields = [
  'addressType',
  'addressLine1',
  'addressLine2',
  'city',
  'county',
  'state',
  'zipcode',
  'country',
  'latitude',
  'longitude'
];
