import React from 'react';
import { Button, ButtonType, ThemeProvider } from '@buildhero/sergeant';
import { useStyles } from './GenerateTimesheetsButton.styles';

const GenerateTimesheetsButton = props => {
  const styles = useStyles();
  return (
    <ThemeProvider>
      <Button type={ButtonType.SECONDARY} css={styles.button} {...props}>
        Generate Individual Timesheet Entries
      </Button>
    </ThemeProvider>
  );
};

export default GenerateTimesheetsButton;
