import React from 'react';
import { func } from 'prop-types';
import { Button, TV, Typography, TypographyWeight } from '@buildhero/sergeant';

import { useReviewReportDisabled } from '../../../../ReviewReport.contexts';

import { useStyles } from './FeesSectionHeader.styles';

const FeesSectionHeader = ({ onAddFees }) => {
  const styles = useStyles();
  const { disabled } = useReviewReportDisabled();

  return (
    <div css={styles.header}>
      <Typography variant={TV.L} weight={TypographyWeight.BOLD} style={{ marginBottom: 8 }}>
        Fees
      </Typography>
      <Button disabled={disabled} type="secondary" size="small" onClick={onAddFees}>
        Add Fee
      </Button>
    </div>
  );
};

FeesSectionHeader.propTypes = {
  onAddFees: func.isRequired
};

export default FeesSectionHeader;
