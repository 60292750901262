import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { snackbarOn } from 'redux/actions/globalActions';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Switch, ThemeProvider } from '@buildhero/sergeant';
import { makeUseAxios } from 'axios-hooks';
import { axiosInstancePMAPI as instance } from 'configs/http-client';

const useAxios = makeUseAxios({ axios: instance });
const useStyles = makeStyles(() => ({
  root: {
    lineHeight: '35px'
  }
}));

const PermissionSwitch = ({ record, user, onSwitchToggle, ...props }) => {
  const classes = useStyles();
  const [userData, setUserData] = useState(record);
  const [toggleSwitch, setToggleSwitch] = useState(record.permission);
  const [
    { data: disabledPermission, loading: disableLoading, error: disableError },
    executeDisable
  ] = useAxios({ url: 'projectuserpermissions', method: 'post' }, { manual: true });
  const [{ loading: enableLoading }, executeEnable] = useAxios(
    { url: `projectuserpermissions/${userData.permissionId}`, method: 'delete' },
    { manual: true }
  );

  useEffect(() => {
    setUserData(record);
    setToggleSwitch(record.permission);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(record)]);

  useEffect(() => {
    if (disabledPermission?.id) {
      setUserData({ ...userData, permissionId: disabledPermission?.id, permission: false });
      setToggleSwitch(false);
      onSwitchToggle();
    }
    if (disableError) {
      props.snackbarOn('error', 'Failed to change user permission');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledPermission, disableError]);

  const handleToggleSwitch = async event => {
    if (event.target.checked) {
      if (userData.permissionId) {
        await executeEnable();
        setUserData({ ...userData, permissionId: null, permission: true });
        setToggleSwitch(true);
        onSwitchToggle();
      }
    } else {
      await executeDisable({
        data: { projectId: userData.projectId, employeeId: userData.employeeId, permission: false }
      });
    }
  };

  return userData.employeeId ? (
    <ThemeProvider>
      <Box className={classes.root}>
        <Switch
          checked={toggleSwitch}
          onChange={handleToggleSwitch}
          disabled={disableLoading || enableLoading || user.employeeId === userData.employeeId}
        />
      </Box>
    </ThemeProvider>
  ) : null;
};

PermissionSwitch.propTypes = {
  record: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  snackbarOn: PropTypes.func.isRequired,
  onSwitchToggle: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatcherToProps = { snackbarOn };
const ReduxConnectedPermissionSwitch = connect(
  mapStateToProps,
  mapDispatcherToProps
)(PermissionSwitch);

export default ReduxConnectedPermissionSwitch;
