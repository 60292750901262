import mutationQuery from '../../../common/mutations/createEntity';
import deleteMapping from '../../../common/mutations/deleteMapping';
import offSchedulesSchema, {
  offSchedulesSchemaMappings
} from '../../../crm/employee/schemas/offSchedules';
import { asyncForEach } from '../../../../utils';
import InstructionSetGenerator from '../../../../mutation-schema/InstructionSetGenerator';

const removeAssociations = async (client, records) =>
  asyncForEach(records, async record => {
    const directParams = {
      input: {
        partitionKey: record.tenantId,
        sortKey: record.directSortKey
      }
    };
    const invertedParams = {
      input: {
        partitionKey: record.tenantId,
        sortKey: record.invertedSortKey
      }
    };
    await client.mutate(deleteMapping, directParams);
    await client.mutate(deleteMapping, invertedParams);
  });

const saveOffSchedule = async (client, offSchedule, replacedOffSchedule = null) => {
  const isg = new InstructionSetGenerator(
    offSchedule,
    offSchedulesSchema,
    offSchedulesSchemaMappings,
    replacedOffSchedule
  );
  const associationsToRemove = isg.associationsToRemove();
  const dataset = isg.createDataset();
  if (associationsToRemove.length > 1) {
    await removeAssociations(client, associationsToRemove);
  }
  return client.mutate(mutationQuery, dataset);
};

export const saveOffScheduleFull = async (
  client,
  offSchedule,
  optimisticResponse,
  update,
  refetchQueries,
  replacedOffSchedule = null
) => {
  const isg = new InstructionSetGenerator(
    offSchedule,
    offSchedulesSchema,
    offSchedulesSchemaMappings,
    replacedOffSchedule
  );
  const associationsToRemove = isg.associationsToRemove();
  const dataset = isg.createDataset();
  if (associationsToRemove.length > 1) {
    await removeAssociations(client, associationsToRemove);
  }
  return client.fullMutate(mutationQuery, dataset, optimisticResponse, update, refetchQueries);
};

const deleteOffSchedule = async (client, offSchedule) => {
  const params = {
    input: {
      partitionKey: offSchedule.tenantId,
      sortKey: offSchedule.sortKey
    }
  };
  await client.mutate(deleteMapping, params);
};

export default saveOffSchedule;
export { deleteOffSchedule };
