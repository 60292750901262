import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { setOpenQuickAddModal } from 'redux/actions/globalActions';
import { QuickAddModal } from 'utils/constants';
import { ClickAwayListener, IconButton } from '@material-ui/core';
import Popper from '../Popper';
import { PermissionConstants } from 'utils/AppConstants';
import displayIconElement from '../icons';

// TouchRipple classes
const useStyles = makeStyles(theme => ({
  iconActive: {
    color: `${theme.palette.grayscale(80)} !important`
  },
  button: {
    height: '100%',
    '&:hover': {
      '& $icon': {
        color: theme.palette.grayscale(80)
      }
    }
  },
  icon: {
    color: theme.palette.grayscale(60),
    fontSize: '20px'
  }
}));

const AddEntryButton = props => {
  const classes = useStyles();
  const { isSmallScreen, tenantSettingProcessed, setOpenQuickAddModal } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const isPopoverOpen = Boolean(anchorEl);
  const hidePopover = () => {
    setAnchorEl(null);
  };
  const handleMainClick = event => {
    if (isPopoverOpen) {
      hidePopover();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const generateHandleClick = itemKey => event => {
    setOpenQuickAddModal(itemKey);
    hidePopover();
  };
  const menuItems = [
    {
      menu: {
        title: 'New Job',
        caslAction: 'create',
        caslKey: '',
        scope: 'tenant',
        iconKey: 'addCircleIcon',
        onClick: generateHandleClick(QuickAddModal.CREATE_JOB)
      }
    },
    {
      menu: {
        title: 'New Quote',
        caslAction: 'create',
        caslKey: '',
        scope: 'tenant',
        iconKey: 'addCircleIcon',
        onClick: generateHandleClick(QuickAddModal.CREATE_QUOTE)
      }
    },
    {
      menu: {
        title: 'New Invoice',
        caslAction: 'create',
        caslKey: PermissionConstants.OBJECT_INVOICE,
        scope: 'tenant',
        iconKey: 'addCircleIcon',
        onClick: generateHandleClick(QuickAddModal.CREATE_INVOICE)
      }
    },
    {
      menu: {
        title: 'New Payment',
        caslAction: 'create',
        caslKey: '',
        scope: 'tenant',
        iconKey: 'addCircleIcon',
        onClick: generateHandleClick(QuickAddModal.CREATE_PAYMENT)
      }
    }
  ];

  return (
    <>
      <ClickAwayListener onClickAway={hidePopover}>
        <IconButton onClick={handleMainClick} className={classes.button}>
          {React.cloneElement(displayIconElement('addCircleIcon'), {
            className: `${classes.icon} ${isPopoverOpen && classes.iconActive}`
          })}
          <Popper
            placement="bottom-end"
            buttonElements
            menuItems={menuItems}
            tenantSettingProcessed={tenantSettingProcessed}
            handleClosePopover={hidePopover}
            open={isPopoverOpen}
            anchorEl={anchorEl}
            isSmallScreen={isSmallScreen}
          />
        </IconButton>
      </ClickAwayListener>
    </>
  );
};

export default connect(null, { setOpenQuickAddModal })(AddEntryButton);
