import gql from 'graphql-tag';

const addAttachmentsToJob = gql`
  mutation addAttachmentsToJob($partitionKey: String!, $data: AddAttachmentsToJobInput!) {
    addAttachmentsToJob(partitionKey: $partitionKey, data: $data) {
      customFileName
      fileName
      fileUrl
      dateAdded
      createdDateTime
      addedBy
      description
      type
      sortOrder
      parentId
      parentSortKey
      hierarchy
      id
      version
      partitionKey
      sortKey
    }
  }
`;

export default addAttachmentsToJob;
