import gql from 'graphql-tag';

const getReviewReportPartsByJobNumber = gql`
  query getReviewReportPartsByJobNumber($jobNumber: String!) {
    getJobByJobNumber(jobNumber: $jobNumber) {
      id
      jobNumber
      visits: visitsView(entityConnection: "Visit") {
        items {
          visitNumber
          id
          sortKey
          status
          reviewReports(entityConnection: "ReviewReport") {
            items {
              id
              parentId
              sortKey
              status
            }
          }
        }
      }
      inventoryParts(entityConnection: "InventoryPart") {
        items {
          id
          itemName
          description
          quantity
          unitCost
          unitPrice
          inventoryType
          vendorName
          unitOfMeasure
          addedBy
          markup
          parentId
          amount
          taxable
          totalAmountTaxable
          tax
          createdBy
          includeInInvoice
          version
          priceBookEntry(entityConnection: "PriceBookEntry") {
            id
            product(entityConnection: "Product") {
              id
              code
            }
          }
        }
      }
    }
  }
`;

export default getReviewReportPartsByJobNumber;
