import gql from 'graphql-tag';

const savePropertyAttachments = gql`
  mutation savePropertyAttachments($data: String!, $instructionSet: String!) {
    savePropertyAttachments(data: $data, instructionSet: $instructionSet) {
      fileName
      customFileName
      fileUrl
      id
      comment
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      description
      createdDate
      createdDateTime
      createdBy
      parentId
    }
  }
`;

export default savePropertyAttachments;
