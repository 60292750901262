export const getLocalizedSize = inValue => {
  let value = inValue || '0';
  if (Array.isArray(value)) {
    value = value.map(p => `${Math.floor(p * 0.7)}pt`).join(' ');
  }
  if (value === '') {
    value = '0';
  }
  return value;
};

export const fontSize = relativeSize => `${(relativeSize * 1.2).toFixed(1)}pt`;

export default getLocalizedSize;
