import gql from 'graphql-tag';

const addTimeCardVisitsToTimeCard = gql`
  mutation AddTimeCardVisitsToTimeCard(
    $partitionKey: String
    $data: AddTimeCardVisitsToTimeCardInput!
  ) {
    addTimeCardVisitsToTimeCard(partitionKey: $partitionKey, data: $data) {
      partitionKey
      sortKey
      id
    }
  }
`;

export default addTimeCardVisitsToTimeCard;
