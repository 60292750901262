/* eslint-disable camelcase */
import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import AppConstants from 'utils/AppConstants';

const TechnicianNotes = ({ classes, current, send, isReviewReport }) => {
  const { context } = current;

  const notesData = isReviewReport ? context.technicianNotes : context.notes;
  const notes = (notesData || []).map(note => {
    const localNote = note;
    const { addedBy, lastUpdatedBy, lastUpdatedDateTime, addedDateTime } = note;
    // addedDateTime will be coming only for items that are copied for review report creation
    localNote.modifiedDate = addedDateTime || lastUpdatedDateTime;
    localNote.lastUpdatedBy = addedBy || lastUpdatedBy;
    return localNote;
  });

  return (
    <>
      <Typography className={classes.sectionTitle}>Technician notes (internal use)</Typography>
      <Divider variant="fullWidth" classes={{ root: classes.blueDivider }} />
      {notes.map(note => (
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className={classes.subject}> {note.subject}</Typography>
                <Typography className={classes.bodyContent}>{note.note}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Typography className={classes.footer}>
            Last edited by {note.lastUpdatedBy} on &nbsp;
            {`${moment
              .unix(parseInt(note.modifiedDate, 10) / 1000)
              .format(AppConstants.DATETIME_FORMAT)}`}
          </Typography>
        </Grid>
      ))}
    </>
  );
};

export default TechnicianNotes;
