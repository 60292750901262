import { useTheme } from '@material-ui/core';
import { DispatchTheme } from '@dispatch/Dispatch.styles';

export const useStyles = ({ active, Status } = {}) => {
  const theme = useTheme();

  const Label = {
    textAlign: 'center',
    padding: '4px 8px',
    display: 'inline',
    fontSize: theme.typography.caption.fontSize
  };

  return {
    container: {
      height: 'inherit'
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      width: '100%',
      height: '48px',
      borderBottom: DispatchTheme.mixins.mainBorder,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    content: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      height: 'calc(100% - 48px)',
      overflow: 'scroll',
      '& *': {
        overflowY: 'hidden'
      }
    },
    tabsContainer: {
      overflow: 'scroll',
      whiteSpace: 'nowrap',
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    tabHeader: {
      borderColor: theme.palette.grayscale(20),
      borderStyle: 'solid',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      borderWidth: '1px',
      borderBottomStyle: 'none',
      cursor: 'pointer',
      display: 'inline-block',
      lineHeight: '38px',
      marginRight: '4px',
      outline: 'none',
      textAlign: 'center',
      width: 90,
      backgroundColor: active ? Status?.value?.primaryColor : Status?.value?.secondaryColor,
      color: active ? 'white' : theme.palette.grayscale(20),
      fontWeight: active ? 600 : 400,
      '&:hover': {
        backgroundColor: Status?.value?.primaryColor,
        color: 'white',
        fontWeight: 600
      }
    },
    Maintenance: {
      ...Label,
      backgroundColor: theme.palette.other.lightOrange
    },
    ServiceJob: {
      ...Label,
      backgroundColor: theme.palette.other.lightGreen
    },
    HighPriority: {
      ...Label,
      backgroundColor: theme.palette.other.lightOrange
    },
    Priority: {
      ...Label,
      backgroundColor: theme.palette.grayscale(90)
    },
    visitLink: {
      cursor: 'pointer',
      color: theme.palette.other.secondaryCyan
    },
    TableCell: {
      fontSize: theme.typography.fontSize,
      fontWeight: 400
    }
  };
};
