import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import { Highlight } from 'react-instantsearch-dom';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import InboxIcon from '@material-ui/icons/Inbox';
import ChatBubbleOutlined from '@material-ui/icons/ChatBubbleOutline';
import LocalPostOfficeOutlined from '@material-ui/icons/LocalPostOfficeOutlined';
import AssignmentOutlined from '@material-ui/icons/AssignmentOutlined';
import BallotIcon from '@material-ui/icons/Ballot';
import Build from '@material-ui/icons/Build';
import Schedule from '@material-ui/icons/Schedule';
import Store from '@material-ui/icons/Store';
import Person from '@material-ui/icons/Person';
import Contact from '@material-ui/icons/Contacts';
import DescriptionIcon from '@material-ui/icons/Description';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { capitalizeFirstLetter } from 'utils';
import { AppConstants, PermissionConstants, JobTypes } from 'utils/AppConstants';
import { UserPermission } from 'components/AppPermissions';
import { StatusChip } from 'components';
import statusChips from 'meta/statusChips';
import ServiceAgreementIcon from 'assets/Icons/ServiceAgreements';
import ProcurementIcon from 'assets/Icons/Procurement';
import styles from './styles';

const CREATE_JOB_BTN = '+ Create job';

const strategy = flags => ({
  Job: {
    entityTypeLabel: 'Job',
    title: ['jobNumber'],
    subtitle: [
      { label: 'Status', value: 'status' },
      {
        label: 'Job type',
        value: 'jobTypeName'
      },
      {
        label: 'Property',
        value: 'customerPropertyName'
      },
      {
        label: 'Tags',
        value: 'jobTags'
      }
    ],
    longText: [
      {
        label: 'Description',
        value: 'issueDescription'
      }
    ],
    path: '/job/view/##jobNumber##',
    routeState: { recordSortKey: 'sortKey' }
  },
  Employee: {
    entityTypeLabel: 'Techs',
    title: ['name'],
    subtitle: [
      { label: 'Email', value: 'email' },
      { label: 'Personal email', value: 'personalEmail' },
      { label: 'Phone', value: 'cellPhone' }
    ],
    path: '/settings/people'
  },
  Customer: {
    entityTypeLabel: 'Customer',
    title: ['customerName'],
    deactivatedPath: 'status',
    statusChip: 'deactivated',
    subtitle: [
      { label: 'Customer type', value: 'customerTypeValue' },
      { label: 'Email', value: 'email' },
      { label: 'Phone', value: 'phonePrimary' },
      { label: 'Tags', value: 'tag' }
    ],
    actionBtn: CREATE_JOB_BTN,
    actionPath: '/job/new',
    actionState: { customerName: 'customerName', customerSortKey: 'sortKey' },
    actionPermission: [PermissionConstants.OBJECT_JOB],
    actionType: 'new',
    path: '/customer/view/##id##',
    routeState: { recordSortKey: 'sortKey' }
  },
  CustomerRep: {
    entityTypeLabel: 'Reps',
    title: ['name'],
    subtitle: [
      { label: 'Customer', value: 'customerName' },
      { label: 'Email', value: 'email' },
      { label: 'Phone', value: 'cellPhone' }
    ],
    path: '/::parentEntityType::/view/##parentId##',
    routeState: { recordSortKey: 'parentSortKey' }
  },
  CompanyAddress: {
    entityTypeLabel: 'Address',
    deactivatedPath: 'parentStatus',
    statusChip: 'deactivated',
    title: ['customerName', 'customerPropertyName'],
    subtitle: [
      { value: 'addressLine1' },
      { value: 'addressLine2' },
      { value: 'city' },
      { value: 'state' },
      { value: 'zipcode' },
      { value: 'addressType' }
    ],
    propertyName: 'customerPropertyName',
    actions: [
      {
        actionBtn: CREATE_JOB_BTN,
        actionPath: '/job/new',
        actionState: {
          customerName: 'customerName',
          propertyName: 'customerPropertyName',
          propertyId: 'parentId',
          customerSortKey: 'customerSortKey||parentSortKey'
        },
        actionType: 'new',
        actionPermission: [PermissionConstants.OBJECT_JOB]
      }
    ],
    path: '/::parentEntityType::/view/##parentId##',
    routeState: { recordSortKey: 'parentSortKey' }
  },
  CustomerProperty: {
    entityTypeLabel: 'Property',
    title: ['customerPropertyName'],
    deactivatedPath: 'status',
    statusChip: 'deactivated',
    subtitle: ['customerName'],
    actions: [
      {
        actionBtn: CREATE_JOB_BTN,
        actionPath: '/job/new',
        actionState: {
          customerName: 'customerName',
          propertyName: 'customerPropertyName',
          propertyId: 'id',
          customerSortKey: 'parentSortKey'
        },
        actionType: 'new',
        actionPermission: [PermissionConstants.OBJECT_JOB]
      },
      flags?.[FeatureFlags.QUOTESV2] && {
        actionBtn: '+ Create quote',
        actionPath: '/quotes/new',
        actionType: 'new',
        actionState: {
          propertyId: 'id'
        },
        actionPermission: [PermissionConstants.OBJECT_QUOTES],
        featureGate: 'quotes'
      }
    ].filter(Boolean),
    path: '/property/view/##id##',
    routeState: { recordSortKey: 'sortKey' }
  },
  Note: {
    entityTypeLabel: 'Note',
    title: ['subject', 'note'],
    subtitle: [
      { label: 'Last updated by', value: 'lastUpdatedBy' },
      { label: 'Customer', value: 'customerName' },
      { label: 'Property', value: 'customerPropertyName' }
    ],
    path: '/::parentEntityType::/view/##parentId##',
    routeState: { recordSortKey: 'parentSortKey' }
  },
  Visit: {
    entityTypeLabel: 'Visit',
    title: ['jobNumber'],
    subtitle: [
      { label: 'Visit #', value: 'visitNumber' },
      { label: 'Job type', value: 'jobTypeName' },
      { label: 'Customer', value: 'customerName' },
      { label: 'Property', value: 'customerPropertyName' },
      { label: 'Tech', value: 'name' }
    ],
    path: '/job/view/##jobNumber##',
    routeState: { recordSortKey: 'jobSortKey' }
  },
  PurchaseOrderNumber: {
    entityTypeLabel: 'Job',
    title: ['jobNumber'],
    subtitle: [{ label: 'PO number #', value: 'purchaseOrderNumber' }],
    path: '/job/view/##jobNumber##'
  },
  PurchaseOrder: {
    entityTypeLabel: 'PurchaseOrder',
    title: ['poNumber'],
    subtitle: [
      { label: 'Job', value: ['customIdentifier', 'jobCustomIdentifier', 'jobNumber'] },
      { label: 'Status', value: 'status' },
      { label: 'Vendor', value: 'vendorName' }
    ],
    longText: [
      {
        label: 'Description',
        value: 'description'
      }
    ],
    path: '/procurement/purchaseorders/view/##id##'
  },
  Invoice: {
    entityTypeLabel: 'Invoice',
    title: ['invoiceNumber'],
    subtitle: [
      { label: 'Status', value: 'status' },
      { label: 'Job #', value: ['customIdentifier', 'jobNumber'] },
      { label: 'Customer', value: 'customerName' },
      { label: 'Property', value: 'customerPropertyName' }
    ],
    path: '/invoice/view/##id##',
    routeState: { recordSortKey: 'sortKey' }
  },
  ...(flags?.[FeatureFlags.QUOTESV2] && {
    Quote: {
      entityTypeLabel: 'Quote',
      title: ['quoteNumber'],
      subtitle: [
        { label: 'Status', value: 'status' },
        { label: 'Customer', value: 'customerName' },
        { label: 'Property', value: 'customerPropertyName' },
        { label: 'Tags', value: 'quoteTags' }
      ],
      path: '/quote/##id##'
    }
  }),
  Truck: {
    entityTypeLabel: 'Truck',
    title: ['name'],
    subtitle: [{ label: 'Inventory bundle', value: 'productBundleName' }],
    path: '/truck/##id##'
  },
  ServiceAgreement: {
    entityType: 'Service Agreement',
    title: ['agreementName'],
    subtitle: [
      { label: 'Agreement number', value: 'agreementNumber' },
      { label: 'Status', value: 'status' },
      { label: 'Customer', value: 'customerName' },
      { label: 'Billing customer', value: 'billingCustomerName' },
      {
        label: 'Tags',
        value: 'serviceAgreementTags'
      }
    ],
    path: '/serviceAgreement/view/##id##'
  },
  Project: {
    entityTypeLabel: 'Project',
    title: ['projectName', 'projectNumber'],
    subtitle: [
      { label: 'Customer', value: 'customerName' },
      { label: 'Property', value: 'propertyName' },
    ],
    path: '/project/view/##id##/dashboard'
  }
});

const iconStrategy = {
  customer: Contact,
  customerproperty: Store,
  job: Build,
  purchaseordernumber: Build,
  purchaseorder: ProcurementIcon,
  visit: Schedule,
  note: ChatBubbleOutlined,
  employee: Person,
  companyaddress: LocalPostOfficeOutlined,
  customerrep: Person,
  task: AssignmentOutlined,
  invoice: DescriptionIcon,
  quote: BallotIcon,
  serviceagreement: ServiceAgreementIcon,
  project: Build
};

const fieldNames = {
  customerName: 'customer - ',
  city: 'city - ',
  state: 'state - ',
  zipcode: 'zipcode - '
};

const partOfResultStrategy = {
  customerrep: 'Includes  ',
  customerproperty: 'Includes ',
  job: 'Includes ',
  purchaseordernumber: 'Includes ',
  purchaseorder: 'Includes ',
  visit: 'Includes ',
  note: 'Includes ',
  companyaddress: 'Includes ',
  project: 'Includes '
};

function changesAddressType(value) {
  let processedAddressType = value.replace('Address', '');
  processedAddressType = capitalizeFirstLetter(processedAddressType);
  return `${processedAddressType} address`;
}

function setDeactivatedText(deactivated) {
  const deactivatedChip = statusChips.deactivated;
  return deactivated && <StatusChip type={deactivatedChip} />;
}

function ResultTile(props) {
  return (
    <>
      <Grid container style={styles.containerStyle}>
        <Typography variant="body1" className={props.classes.title}>
          {!props.titleHasHighlight &&
            (props.hit[props.titleKey[0]] || props.hit[props.titleKey[1]])}
          {props.titleHasHighlight && props.titleKey[0] && (
            <>
              <Highlight attribute={props.titleKey[0]} hit={props.hit} />{' '}
            </>
          )}
          {props.titleHasHighlight && props.titleKey[1] && (
            <Highlight attribute={props.titleKey[1]} hit={props.hit} />
          )}
        </Typography>
        {setDeactivatedText(props.statusChip === 'deactivated')}
      </Grid>
      {props.highlightSubText && props.titleKey !== props.highlightSubText && (
        <Typography>
          {props.highlightPreText}
          {props.highlightSubText && (
            <>
              {fieldNames[props.highlightSubText]}
              <Highlight attribute={props.highlightSubText} hit={props.hit} />
            </>
          )}
        </Typography>
      )}
      <div className={props.classes.secondLine}>
        <Typography className={props.classes.searchItemHighlights}>
          <span className={props.classes.entityTile}>{props.entityType}</span>
          {props.subText &&
            props.subText.map((item, index) => [
              item.label === 'Tags' && <div />,
              // eslint-disable-next-line react/no-array-index-key
              <span
                className={item.label ? props.classes.subtitleKeyLabel : null}
                key={item.label + props.entityType + index}
                style={item.label === 'Tags' ? { paddingLeft: '0px' } : {}}
              >
                {item.label || ''}
              </span>,
              <span
                className={props.classes.subtitleKey}
                key={item.value + props.entityType + index}
              >
                {[
                  'businessAddress',
                  'billingAddress',
                  'propertyAddress',
                  'companyAddress'
                ].includes(item.value)
                  ? changesAddressType(item.value)
                  : item.label === 'Tags'
                  ? item.value.map((value, index) => (index ? ', ' : '') + value)
                  : item.value}
              </span>
            ])}
        </Typography>
      </div>
      <div className={props.classes.thirdLine}>
        <Typography className={props.classes.searchItemHighlights}>
          {props.longText &&
            props.longText.map((item, index) => [
              // eslint-disable-next-line react/no-array-index-key
              <span
                className={item.label ? props.classes.subtitleLongKeyLabel : null}
                key={item.label + props.entityType + index}
              >
                {item.label || ''}
              </span>,
              <span
                className={props.classes.subtitleLongKey}
                key={`${item.value}-${props.entityType}-${index}`}
              >
                {item.value}
              </span>
            ])}
        </Typography>
      </div>
    </>
  );
}

function processOrCondition(recordObject, objectKeysArr) {
  if (!Array.isArray(objectKeysArr)) return recordObject?.[objectKeysArr];
  return objectKeysArr.reduce((acc, currentValue) => {
    return acc || recordObject[currentValue];
  }, '');
}

// used in constructing the tile's additional information
function getValuesOfArray(values, keys) {
  const returnValue = [];
  if (!keys) {
    return returnValue;
  }
  // values will be [{label: 'Quote', value: 'quoteNumber'}, {label: 'Job', value: ['customIdentifier', 'jobNumber']}]
  keys.forEach(key => {
    const displayValue = processOrCondition(values, key?.value);
    if (displayValue) {
      returnValue.push({ label: key.label, value: displayValue });
    }
  });
  return returnValue;
}

function getHighlightedText(entityType, values) {
  let highlightKey;
  if (values) {
    Object.keys(values).forEach(key => {
      if (typeof values[key] === 'object' && Array.isArray(values[key])) {
        values[key].forEach(item => {
          if (item.matchLevel === 'full') {
            highlightKey = key;
          }
        });
      } else if (typeof values[key] === 'object') {
        if (values[key].matchLevel === 'full') {
          highlightKey = key;
        }
      }
    });
  }

  return highlightKey;
}

function getStateValues(values, stateSchema) {
  let returnStateValue = {};
  Object.keys(stateSchema).forEach(key => {
    let value;
    const valueAtttributes = stateSchema[key].split('||');
    if (valueAtttributes.length > 1) {
      valueAtttributes.forEach(attr => {
        value = value || values[attr];
      });
    } else {
      value = values[stateSchema[key]];
    }
    returnStateValue = { ...returnStateValue, [key]: value };
  });
  return returnStateValue;
}

function Hit(props) {
  const flags = useFlags();
  const cardType = props.hit.entityType;
  const cardSchema = strategy(flags)[cardType] || '';
  const Icon = iconStrategy[cardType.toLowerCase()] || InboxIcon;
  let linkPath;
  let routeState;

  // TODO: Not scalable fix. Need to leaverage isActive when alogila starts showing for all hits
  const { status } = props.hit;
  const showButtons = !status || status === 'active';

  const isCustomJobNumberEnabled = useSelector(s => s.settings?.job_customJobNumber);
  // TODO: Dirty fix, need better solution
  if (cardSchema.title && cardSchema.title.includes('jobNumber') && isCustomJobNumberEnabled) {
    const localArray = cardSchema.title.filter(item => item !== 'jobNumber');
    localArray.push('customIdentifier');
    cardSchema.title = localArray;
  }

  // TODO: Dirty fix, need better solution
  if (cardSchema.title && cardSchema.title.includes('quoteNumber') && props.hit.customIdentifier) {
    const localArray = cardSchema.title.filter(item => item !== 'quoteNumber');
    localArray.push('customIdentifier');
    cardSchema.title = localArray;
  }

  const pathVariables = cardSchema.path && cardSchema.path.split('##');
  const entityVariables = cardSchema.path && cardSchema.path.split('::');
  if (pathVariables && pathVariables.length > 0) {
    linkPath = cardSchema.path.replace(`##${pathVariables[1]}##`, props.hit[pathVariables[1]]);
    if (cardSchema.routeState) {
      routeState = getStateValues(props.hit, cardSchema.routeState);
    }
  }
  if (entityVariables && entityVariables.length > 0 && linkPath) {
    linkPath = linkPath.replace(`::${entityVariables[1]}::`, props.hit[entityVariables[1]]);
  }

  // TODO: Bad hack done for hot fix, need better solution
  if (linkPath && linkPath.toLowerCase().includes('customerproperty')) {
    linkPath = linkPath.toLowerCase().replace('customerproperty', 'property');
  }

  if (linkPath && linkPath.toLowerCase().includes('customerrep')) {
    linkPath = `/${props.hit.grandParentEntityType || 'customer'}/view/${props.hit.grandParentId}`;
    routeState.recordSortKey = props.hit.grandParentSortKey || null;
  }

  // handling unique special cases

  // entities like notes will always navigate to URL + navigate to entityId, for jobs and maintenance - we have jobNumber in the url
  if (linkPath && linkPath.toLowerCase().includes('/job/view')) {
    // since entityType for job and maintenance is 'Job', have to check jobTypeInternal and manipulate the url and entity type label
    const isMaintenanceJob = props.hit?.jobTypeInternal === JobTypes.MAINTENANCE;
    linkPath = `/${isMaintenanceJob ? 'maintenance' : 'job'}/view/${encodeURIComponent(
      props.hit.jobNumber
    )}`;
    cardSchema.entityTypeLabel = isMaintenanceJob ? 'Maintenance' : 'Job';
  }

  if (linkPath && linkPath.toLowerCase().includes('/maintenance/view')) {
    linkPath = `/maintenance/view/${encodeURIComponent(props.hit.jobNumber)}`;
  }

  if (props.hit?.entityType === 'PurchaseOrder' && props?.hit?.isSimplePurchaseOrder) {
    linkPath = `/${
      props.hit.parentEntityType.toLowerCase() === 'visit'
        ? 'technicianreport'
        : props.hit.parentEntityType.toLowerCase()
    }/view/${encodeURIComponent(props.hit.parentId)}`;
    cardSchema.subtitle = [
      ...cardSchema.subtitle,
      { label: 'Added in', value: 'parentEntityType' }
    ];
  }

  let highlightKey;
  if (cardSchema) {
    highlightKey = getHighlightedText(cardType, props.hit._highlightResult);
  }

  const ItemPath = React.forwardRef((btnProps, ref) => (
    <Link
      to={{
        pathname: linkPath,
        state: routeState
      }}
      {...btnProps}
      ref={ref}
      onClick={() => props.closeSearchResultModal()}
    />
  ));

  const getStatusChip = hit => {
    return (
      cardSchema &&
      cardSchema.deactivatedPath &&
      hit[cardSchema.deactivatedPath] === AppConstants.INACTIVE &&
      cardSchema.statusChip
    );
  };

  return (
    <ListItem disableGutters className={props.classes.searchResults}>
      <Button component={ItemPath} className={props.classes.actionLink}>
        <ListItemIcon className={props.classes.listItemIcon}>
          <Icon className={props.classes.listIcon} />
        </ListItemIcon>
        <ListItemText className={props.classes.listText}>
          <ResultTile
            titleKey={cardSchema ? cardSchema.title : ''}
            titleHasHighlight={
              highlightKey && cardSchema && cardSchema.title.includes(highlightKey)
            }
            hit={props.hit}
            subText={cardSchema ? getValuesOfArray(props.hit, cardSchema.subtitle) : ''}
            statusChip={getStatusChip(props.hit)}
            longText={
              cardSchema && cardSchema.longText
                ? getValuesOfArray(props.hit, cardSchema.longText)
                : ''
            }
            entityType={cardSchema.entityTypeLabel || cardType}
            highlightSubText={
              highlightKey && !cardSchema.title.includes(highlightKey) ? highlightKey : ''
            }
            highlightPreText={(highlightKey && partOfResultStrategy[cardType.toLowerCase()]) || ''}
            classes={props.classes}
          />
        </ListItemText>
      </Button>
      {showButtons && (
        <ListItemSecondaryAction classes={{ root: props.classes.listSecondaryAction }}>
          {cardSchema.actions &&
            cardSchema.actions.map(action => {
              let stateValues;
              if (action.actionBtn && action.actionState) {
                stateValues = getStateValues(props.hit, action.actionState);
              }
              let permitAction = true;
              if (
                action.actionBtn === CREATE_JOB_BTN &&
                props.hit[cardSchema.deactivatedPath] === AppConstants.INACTIVE
              ) {
                permitAction = false;
              }
              return (
                permitAction && (
                  <UserPermission
                    I={action.actionType}
                    action={action.actionPermission}
                    featureGate={action.featureGate}
                  >
                    <Link
                      to={{ pathname: action.actionPath, state: stateValues }}
                      className={props.classes.secondaryActionLink}
                    >
                      {action.actionBtn}
                    </Link>
                  </UserPermission>
                )
              );
            })}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Hit);
