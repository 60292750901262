import React, { useMemo } from 'react';

import { Field, FieldType, ThemeProvider } from '@buildhero/sergeant';
import { useTheme } from '@material-ui/core/styles';
import { orderBy } from 'lodash';

import WrapTable from 'components/WrapTable';
import { JobCloseoutTypes } from 'scenes/JobCloseout/constants';
import {
  getJobCloseoutType,
  tableCurrencyFormatter,
  tablePercentageFormatter
} from 'scenes/JobCloseout/utils';

const getColumns = (jobType, byCost, derivedJobCostingValues, theme) => {
  const columns = [
    byCost === 'code'
      ? { field: 'costCodeName', headerName: 'Cost Code', width: 150 }
      : { field: 'jobCostTypeName', headerName: 'Job Cost Type', width: 150 },
    { field: 'departmentName', headerName: 'Department', width: 200 },
    { field: 'itemName', headerName: 'Item', width: 200 },
    jobType === JobCloseoutTypes.QUOTED && {
      field: 'costBudgeted',
      headerName: 'Estimated Costs',
      width: 200,
      valueFormatter: tableCurrencyFormatter,
      renderCell: ({ row }) => (
        <Field
          type={FieldType.CURRENCY}
          value={row?.costBudgeted}
          weight={`${row?.isDerivedCostValue ? 'bold' : 'initial'}`}
          style={{ textAlign: 'right', width: '100%' }}
        />
      ),
      renderTotal: () => (
        <Field
          type={FieldType.CURRENCY}
          value={derivedJobCostingValues?.costBudgeted}
          style={{ textAlign: 'right', width: '100%' }}
        />
      )
    },
    {
      field: 'costActual',
      headerName: 'Actual Costs',
      width: 200,
      valueFormatter: tableCurrencyFormatter,
      renderCell: ({ row }) => (
        <Field
          type={FieldType.CURRENCY}
          value={row?.costActual}
          weight={`${row?.isDerivedCostValue ? 'bold' : 'initial'}`}
          style={{ textAlign: 'right', width: '100%' }}
        />
      ),
      renderTotal: () => (
        <Field
          type={FieldType.CURRENCY}
          value={derivedJobCostingValues?.costActual}
          style={{ textAlign: 'right', width: '100%' }}
        />
      )
    },
    jobType === JobCloseoutTypes.QUOTED && {
      field: 'costVariance',
      headerName: 'Variance',
      width: 200,
      valueFormatter: tablePercentageFormatter,
      renderCell: ({ row }) => (
        <Field
          type={
            row?.costVariance || row?.costVariance === 0 ? FieldType.PERCENTAGE : FieldType.TEXT
          }
          value={row?.costVariance}
          color={row?.costVariance > 0 ? theme.palette.error.main : theme.palette.success.main}
          style={{ textAlign: 'right', width: '100%' }}
        />
      ),
      renderTotal: () => (
        <Field
          type={
            derivedJobCostingValues?.costVariance || derivedJobCostingValues?.costVariance === 0
              ? FieldType.PERCENTAGE
              : FieldType.TEXT
          }
          value={derivedJobCostingValues?.costVariance}
          color={
            derivedJobCostingValues?.costVariance > 0
              ? theme.palette.error.main
              : theme.palette.success.main
          }
          style={{ textAlign: 'right', width: '100%' }}
        />
      )
    },
    jobType === JobCloseoutTypes.QUOTED && {
      field: 'revenueBudgeted',
      headerName: 'Estimated Revenue',
      width: 200,
      valueFormatter: tableCurrencyFormatter,
      renderCell: ({ row }) => (
        <Field
          type={FieldType.CURRENCY}
          value={row?.revenueBudgeted}
          style={{ textAlign: 'right', width: '100%' }}
        />
      ),
      renderTotal: () => (
        <Field
          type={FieldType.CURRENCY}
          value={derivedJobCostingValues?.revenueBudgeted}
          style={{ textAlign: 'right', width: '100%' }}
        />
      )
    },
    {
      field: 'revenueActual',
      headerName: 'Actual Revenue',
      width: 200,
      valueFormatter: tableCurrencyFormatter,
      renderCell: ({ row }) => (
        <Field
          type={FieldType.CURRENCY}
          value={row?.revenueActual}
          style={{ textAlign: 'right', width: '100%' }}
        />
      ),
      renderTotal: () => (
        <Field
          type={FieldType.CURRENCY}
          value={derivedJobCostingValues?.revenueActual}
          style={{ textAlign: 'right', width: '100%' }}
        />
      )
    },
    jobType === JobCloseoutTypes.QUOTED && {
      field: 'revenueVariance',
      headerName: 'Variance',
      width: 200,
      valueFormatter: tablePercentageFormatter,
      renderCell: ({ row }) => (
        <Field
          type={
            row?.revenueVariance || row?.revenueVariance === 0
              ? FieldType.PERCENTAGE
              : FieldType.TEXT
          }
          value={row?.revenueVariance}
          color={row?.revenueVariance > 0 ? theme.palette.error.main : theme.palette.success.main}
          style={{ textAlign: 'right', width: '100%' }}
        />
      ),
      renderTotal: () => (
        <Field
          type={
            derivedJobCostingValues?.revenueVariance ||
            derivedJobCostingValues?.revenueVariance === 0
              ? FieldType.PERCENTAGE
              : FieldType.TEXT
          }
          value={derivedJobCostingValues?.revenueVariance}
          color={
            derivedJobCostingValues?.revenueVariance > 0
              ? theme.palette.error.main
              : theme.palette.success.main
          }
          style={{ textAlign: 'right', width: '100%' }}
        />
      )
    },
    jobType === JobCloseoutTypes.QUOTED && {
      field: 'profitBudgeted',
      headerName: 'Estimated Profit',
      width: 200,
      valueFormatter: tableCurrencyFormatter,
      renderCell: ({ row }) => (
        <Field
          type={FieldType.CURRENCY}
          value={row?.profitBudgeted}
          style={{ textAlign: 'right', width: '100%' }}
        />
      ),
      renderTotal: () => (
        <Field
          type={FieldType.CURRENCY}
          value={derivedJobCostingValues?.profitBudgeted}
          style={{ textAlign: 'right', width: '100%' }}
        />
      )
    },
    {
      field: 'profitActual',
      headerName: 'Actual Profit',
      width: 200,
      valueFormatter: tableCurrencyFormatter,
      renderCell: ({ row }) => (
        <Field
          type={FieldType.CURRENCY}
          value={row?.profitActual}
          style={{ textAlign: 'right', width: '100%' }}
        />
      ),
      renderTotal: () => (
        <Field
          type={FieldType.CURRENCY}
          value={derivedJobCostingValues?.profitActual}
          style={{ textAlign: 'right', width: '100%' }}
        />
      )
    },
    jobType === JobCloseoutTypes.QUOTED && {
      field: 'profitVariance',
      headerName: 'Variance',
      width: 200,
      valueFormatter: tablePercentageFormatter,
      renderCell: ({ row }) => (
        <Field
          type={
            row?.profitVariance || row?.profitVariance === 0 ? FieldType.PERCENTAGE : FieldType.TEXT
          }
          value={row?.profitVariance}
          color={row?.profitVariance > 0 ? theme.palette.error.main : theme.palette.success.main}
          style={{ textAlign: 'right', width: '100%' }}
        />
      ),
      renderTotal: () => (
        <Field
          type={
            derivedJobCostingValues?.profitVariance || derivedJobCostingValues?.profitVariance === 0
              ? FieldType.PERCENTAGE
              : FieldType.TEXT
          }
          value={derivedJobCostingValues?.profitVariance}
          color={
            derivedJobCostingValues?.profitVariance > 0
              ? theme.palette.error.main
              : theme.palette.success.main
          }
          style={{ textAlign: 'right', width: '100%' }}
        />
      )
    },
    jobType === JobCloseoutTypes.QUOTED && {
      field: 'marginBudgeted',
      headerName: 'Estimated Margin',
      width: 200,
      valueFormatter: tablePercentageFormatter,
      renderCell: ({ row }) => (
        <Field
          type={
            row?.marginBudgeted || row?.marginBudgeted === 0 ? FieldType.PERCENTAGE : FieldType.TEXT
          }
          value={row?.marginBudgeted}
          style={{ textAlign: 'right', width: '100%' }}
        />
      ),
      renderTotal: () => (
        <Field
          value={derivedJobCostingValues?.marginBudgeted}
          type={
            derivedJobCostingValues?.marginBudgeted || derivedJobCostingValues?.marginBudgeted === 0
              ? FieldType.PERCENTAGE
              : FieldType.TEXT
          }
          style={{ textAlign: 'right', width: '100%' }}
        />
      )
    },
    {
      field: 'marginActual',
      headerName: 'Actual Margin',
      width: 200,
      valueFormatter: tablePercentageFormatter,
      renderCell: ({ row }) => (
        <Field
          type={
            row?.marginActual || row?.marginActual === 0 ? FieldType.PERCENTAGE : FieldType.TEXT
          }
          value={row?.marginActual}
          style={{ textAlign: 'right', width: '100%' }}
        />
      ),
      renderTotal: () => (
        <Field
          type={
            derivedJobCostingValues?.marginActual || derivedJobCostingValues?.marginActual === 0
              ? FieldType.PERCENTAGE
              : FieldType.TEXT
          }
          value={derivedJobCostingValues?.marginActual}
          style={{ textAlign: 'right', width: '100%' }}
        />
      )
    },
    jobType === JobCloseoutTypes.QUOTED && {
      field: 'marginVariance',
      headerName: 'Variance',
      width: 200,
      valueFormatter: tablePercentageFormatter,
      renderCell: ({ row }) => (
        <Field
          type={
            row?.marginVariance || row?.marginVariance === 0 ? FieldType.PERCENTAGE : FieldType.TEXT
          }
          value={row?.marginVariance}
          color={row?.marginVariance > 0 ? theme.palette.error.main : theme.palette.success.main}
          style={{ textAlign: 'right', width: '100%' }}
        />
      ),
      renderTotal: () => (
        <Field
          type={
            derivedJobCostingValues?.marginVariance || derivedJobCostingValues?.marginVariance === 0
              ? FieldType.PERCENTAGE
              : FieldType.TEXT
          }
          value={derivedJobCostingValues?.marginVariance}
          color={
            derivedJobCostingValues?.marginVariance > 0
              ? theme.palette.error.main
              : theme.palette.success.main
          }
          style={{ textAlign: 'right', width: '100%' }}
        />
      )
    }
  ];
  return columns.filter(column => column);
};

const mapCostsToRows = ({ costItems, derivedCostCodeValues, derivedJobCostTypeValues }, byCost) => {
  const mappedRows = [];
  if (byCost === 'code') {
    derivedCostCodeValues?.forEach(({ ...values }) => {
      mappedRows.push({
        departmentName: '',
        isDerivedCostValue: true,
        ...values
      });
    });
  } else {
    derivedJobCostTypeValues?.forEach(({ ...values }) => {
      mappedRows.push({
        departmentName: '',
        isDerivedCostValue: true,
        ...values
      });
    });
  }
  costItems?.forEach(({ ...item }) => {
    mappedRows.push({
      costBudgeted: null,
      revenueBudgeted: null,
      revenueActual: null,
      revenueVariance: null,
      profitBudgeted: null,
      profitedActual: null,
      profitVariance: null,
      marginBudgeted: null,
      marginActual: null,
      marginVariance: null,
      ...item
    });
  });
  if (byCost === 'code') {
    return orderBy(mappedRows, ['costCodeId', 'actual', 'costCodeName'], ['asc', 'desc', 'asc']);
  }
  return orderBy(
    mappedRows,
    ['jobCostTypeId', 'actual', 'jobCostTypeName'],
    ['asc', 'desc', 'asc']
  );
};

const JobCostingCloseoutTable = ({ jobData, byCost }) => {
  const theme = useTheme();
  const jobCloseoutType = useMemo(() => getJobCloseoutType(jobData), [jobData]);
  const columns = useMemo(
    () =>
      getColumns(
        jobCloseoutType,
        byCost,
        jobData?.derivedJobCosting?.derivedJobCostingValues,
        theme
      ),
    [jobCloseoutType, byCost, jobData.derivedJobCosting.derivedJobCostingValues, theme]
  );
  const rows = useMemo(() => mapCostsToRows(jobData?.derivedJobCosting, byCost), [jobData, byCost]);

  return (
    <ThemeProvider>
      <WrapTable
        rows={rows}
        columns={columns}
        noDataMessage="No costs data"
        numberOfRowsPerPage={25}
        enableTotalsRow
        scrollX
      />
    </ThemeProvider>
  );
};

export default JobCostingCloseoutTable;
