const poBillListTableMeta = [
  {
    id: 'billNumber',
    label: 'Bill Number',
    type: 'number',
    numeric: true,
    align: 'left',
    showLink: 'view',
    linkPath: '/procurement/receipts-bills/bill/view',
    linkReference: 'id'
  },
  {
    id: 'Vendor.name',
    label: 'Vendor',
    type: 'string',
    align: 'left'
  },
  {
    id: 'vendorDocumentNumber',
    label: 'Vendor Document Number',
    type: 'string',
    align: 'left'
  },
  {
    id: 'Department.tagName',
    label: 'Department',
    type: 'string',
    align: 'left'
  },
  {
    id: 'jobOrProjectText',
    label: 'Job / Project',
    showLink: 'view',
    linkPath: '',
    linkReference: 'jobOrProjectLink',
    align: 'left'
  },
  {
    id: 'totalCost',
    label: 'Total',
    type: 'currency',
    numeric: true,
    align: 'left'
  }
];

export default poBillListTableMeta;
