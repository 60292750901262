import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { formatCurrency } from 'utils';
import AppConstants from 'utils/AppConstants';

/**
 * Renders Readonly Currency values
 */
const CurrencyDisplay = ({ field, form, specialbehaviour, classes, ...rest }) => {
  const { fontWeight } = specialbehaviour;
  // To be sent as special behaviour in future
  const prefix = '$';
  let { value } = field;
  let fieldLabel = rest.label;
  if (fieldLabel && fieldLabel.charAt(0) === '*') {
    fieldLabel = fieldLabel.slice(1);
  }

  if (value) {
    if (typeof value !== 'number') {
      value = parseFloat(value);
    }
    value = formatCurrency(value.toFixed(AppConstants.ROUND_OFF_VALUE));
  }

  return (
    <>
      <Typography variant="caption" className={classes.label}>
        {fieldLabel}
      </Typography>
      <Typography
        className={
          fontWeight && fontWeight === 'normal' ? classes.normalContent : classes.boldContent
        }
      >
        {prefix} {value || '0.00'}
      </Typography>
    </>
  );
};

export default withStyles(styles)(CurrencyDisplay);
