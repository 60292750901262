/* eslint-disable no-template-curly-in-string */
const FacilitySystemsPDFForm = settings => {
  return {
    fields: {
      senderInfoLogoUrl: {
        pdf: '${senderInfoLogo}'
      },
      tenantAddress: {
        pdf:
          '${senderInfo.addressLine1}  *  ${senderInfo.addressLine3}  *  ${senderInfo.phoneNumber}'
      },
      // Customer Detail
      customerContactNameTitle: {
        pdf: 'Attn: '
      },
      customerContactName: {
        pdf: '${customerContactNameTitle}'
      },
      customerNameTitle: {
        pdf: 'Company: '
      },
      customerName: {
        pdf: '${customer.customerName}'
      },
      AddressTitle: {
        pdf: 'Address: '
      },
      billingAddressLine1: {
        pdf: '${billingAddressLine1}'
      },
      billingAddressLine2: {
        pdf: '${billingAddressLine2}'
      },
      billingAddress3: {
        pdf: '${billingAddressLine3}'
      },
      customerContactPhoneTitle: {
        pdf: 'Phone: '
      },
      customerContactPhone: {
        pdf: '${customerContactPhoneTitle}'
      },
      customerContactEmailTitle: {
        pdf: 'Via Email: '
      },
      customerContactEmail: {
        pdf: '${customerContactEmailTitle}'
      },
      // Tenant Detail
      createdDateTitle: {
        pdf: 'Date: '
      },
      createdDate: {
        pdf: '${createdDate}'
      },
      quoteNameTitle: {
        pdf: 'Re: '
      },
      quoteName: {
        pdf: '${quoteInfo.name}'
      },
      propertyLabel: {
        pdf: 'Property: '
      },
      propertyName: {
        pdf: '${property.companyName}'
      },
      propertyAddressLine1: {
        pdf: '${propertyDetail.propertyAddress1}'
      },
      propertyAddressLine2: {
        pdf: '${propertyDetail.propertyAddress2}'
      },
      propertyAddressLine3: {
        pdf: '${propertyDetail.propertyAddress3}'
      },
      quoteNumberValueTitle: {
        pdf: 'Quote #: '
      },
      quoteNumberValue: {
        pdf: '${customizedQuoteNumber}'
      },
      licenseNumberValueTitle: {
        pdf: 'License #: '
      },
      licenseNumberValue: {
        pdf: '${quoteCustomFieldsData.Custom_text1}'
      },
      // Introduction
      introduction: {
        pdf: '${quoteCustomFieldsData.Custom_longText1}'
      },
      // Total
      totalTitle: {
        pdf: 'Total Proposal: '
      },
      totalText: {
        pdf: '$${finalAmount}'
      },
      // Closing Remarks
      closingRemarks: {
        pdf: '${quoteCustomFieldsData.Custom_longText2}'
      },
      initialsTitle: {
        pdf: 'Initials: __________________________    '
      },
      initialsDateTitle: {
        pdf: 'Date: __________________________'
      }
    },
    layouts: {
      default: {
        options: {
          pdfTitle: 'Quote'
        },
        contents: [
          // Logo Row
          {
            options: {
              direction: 'row',
              size: 'auto',
              grow: 0,
              shrink: 1,
              justify: 'center',
              padding: [6, 36, 6]
              // debug: true
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  size: 'auto',
                  grow: 0,
                  shrink: 1
                  // debug: true
                },
                contents: [
                  {
                    component: {
                      default: 'Logo'
                    },
                    options: {
                      width: 500,
                      height: 200,
                      alt: 'Company logo',
                      size: 'auto',
                      grow: 0,
                      shrink: 1
                    },
                    source: 'senderInfoLogoUrl'
                  }
                ]
              }
            ]
          },
          // Address Row
          {
            options: {
              direction: 'row',
              size: 'auto',
              grow: 0,
              shrink: 1,
              justify: 'center',
              padding: [0, 0, 12]
              // debug: true
            },
            contents: [
              {
                component: {
                  default: 'Text',
                  pdf: 'BodyText'
                },
                source: 'tenantAddress'
              }
            ]
          },
          // Customer Detail Row
          {
            options: {
              direction: 'row',
              size: 'auto',
              grow: 0,
              shrink: 1,
              justify: 'space-between',
              padding: [6, 36, 6]
              // debug: true
            },
            contents: [
              // Customer Details
              {
                options: {
                  size: 'auto',
                  grow: 0,
                  shrink: 1,
                  direction: 'column',
                  width: '50%',
                  padding: [0, 5, 0, 0]
                },
                contents: [
                  // Contact Name
                  {
                    options: {
                      size: 'auto',
                      grow: 0,
                      shrink: 1,
                      direction: 'row'
                    },
                    contents: [
                      {
                        component: { pdf: 'BodyText' },
                        source: 'customerContactNameTitle'
                      },
                      {
                        component: { pdf: 'BoldText' },
                        source: 'customerContactName'
                      }
                    ]
                  },
                  // Constact Phone
                  {
                    options: {
                      size: 'auto',
                      grow: 0,
                      shrink: 1,
                      direction: 'row'
                    },
                    contents: [
                      {
                        component: { pdf: 'BodyText' },
                        source: 'customerContactPhoneTitle'
                      },
                      {
                        component: { pdf: 'BoldText' },
                        source: 'customerContactPhone'
                      }
                    ]
                  },
                  // Contact Email
                  {
                    options: {
                      size: 'auto',
                      grow: 0,
                      shrink: 1,
                      direction: 'row'
                    },
                    contents: [
                      {
                        component: { pdf: 'BodyText' },
                        source: 'customerContactEmailTitle'
                      },
                      {
                        component: { pdf: 'BoldText' },
                        source: 'customerContactEmail'
                      }
                    ]
                  },
                  // Customer Name
                  {
                    options: {
                      size: 'auto',
                      grow: 0,
                      shrink: 1,
                      direction: 'row'
                    },
                    contents: [
                      {
                        component: { pdf: 'BodyText' },
                        source: 'customerNameTitle'
                      },
                      {
                        component: { pdf: 'BoldText' },
                        source: 'customerName'
                      }
                    ]
                  },
                  // Customer Address
                  {
                    options: {
                      size: 'auto',
                      grow: 0,
                      shrink: 1,
                      direction: 'row'
                    },
                    contents: [
                      {
                        component: { pdf: 'BodyText' },
                        source: 'AddressTitle'
                      },
                      {
                        options: {
                          size: 'auto',
                          grow: 0,
                          shrink: 1,
                          direction: 'column'
                        },
                        contents: [
                          {
                            component: { pdf: 'BoldText' },
                            source: 'billingAddressLine1'
                          },
                          {
                            component: { pdf: 'BoldText' },
                            source: 'billingAddressLine2'
                          },
                          {
                            component: { pdf: 'BoldText' },
                            source: 'billingAddressLine3'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              // Tenant Details
              {
                options: {
                  size: 'auto',
                  grow: 0,
                  shrink: 1,
                  direction: 'column',
                  width: '35%'
                },
                contents: [
                  // Created Date
                  {
                    options: {
                      size: 'auto',
                      grow: 0,
                      shrink: 1,
                      direction: 'row'
                    },
                    contents: [
                      {
                        component: { pdf: 'BodyText' },
                        source: 'createdDateTitle'
                      },
                      {
                        component: { pdf: 'BoldText' },
                        source: 'createdDate'
                      }
                    ]
                  },
                  // Quote Name
                  {
                    options: {
                      size: 'auto',
                      grow: 0,
                      shrink: 1,
                      direction: 'row'
                    },
                    contents: [
                      {
                        component: { pdf: 'BodyText' },
                        source: 'quoteNameTitle'
                      },
                      {
                        component: { pdf: 'BoldText' },
                        source: 'quoteName'
                      }
                    ]
                  },
                  // Quote Number
                  {
                    options: {
                      size: 'auto',
                      grow: 0,
                      shrink: 1,
                      direction: 'row'
                    },
                    contents: [
                      {
                        component: { pdf: 'BodyText' },
                        source: 'quoteNumberValueTitle'
                      },
                      {
                        component: { pdf: 'BoldText' },
                        source: 'quoteNumberValue'
                      }
                    ]
                  },
                  // Property Name
                  {
                    options: {
                      size: 'auto',
                      grow: 0,
                      shrink: 1,
                      direction: 'row'
                    },
                    contents: [
                      {
                        component: { pdf: 'BodyText' },
                        source: 'propertyLabel'
                      },
                      {
                        component: { pdf: 'BoldText' },
                        source: 'propertyName'
                      }
                    ]
                  },
                  // Property Address
                  {
                    options: {
                      size: 'auto',
                      grow: 0,
                      shrink: 1,
                      direction: 'row'
                    },
                    contents: [
                      {
                        component: { pdf: 'BodyText' },
                        source: 'AddressTitle'
                      },
                      {
                        options: {
                          size: 'auto',
                          grow: 0,
                          shrink: 1,
                          direction: 'column'
                        },
                        contents: [
                          {
                            component: { pdf: 'BoldText' },
                            source: 'propertyAddressLine1'
                          },
                          {
                            component: { pdf: 'BoldText' },
                            source: 'propertyAddressLine2'
                          },
                          {
                            component: { pdf: 'BoldText' },
                            source: 'propertyAddressLine3'
                          }
                        ]
                      }
                    ]
                  },
                  // License Number
                  {
                    options: {
                      size: 'auto',
                      grow: 0,
                      shrink: 1,
                      direction: 'row'
                    },
                    contents: [
                      {
                        component: { pdf: 'BodyText' },
                        source: 'licenseNumberValueTitle'
                      },
                      {
                        component: { pdf: 'BoldText' },
                        source: 'licenseNumberValue'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // Divider
          {
            options: {
              direction: 'row',
              size: 'auto',
              grow: 0,
              shrink: 1,
              justify: 'center',
              padding: [6, 36, 6]
              // debug: true
            },
            contents: [
              {
                options: {
                  color: '#333'
                },
                component: {
                  pdf: 'Divider'
                }
              }
            ]
          },
          // Intro
          {
            options: {
              direction: 'row',
              size: 'auto',
              grow: 0,
              shrink: 1,
              justify: 'start',
              padding: [6, 36, 6]
              // debug: true
            },
            contents: [
              {
                component: {
                  default: 'Text',
                  pdf: 'RichContentViewer'
                },
                options: {
                  size: 'auto',
                  grow: 0,
                  shrink: 1
                },
                source: 'introduction'
              }
            ]
          },
          // Tasks and Products
          {
            options: {
              direction: 'row',
              size: 'auto',
              grow: 0,
              shrink: 1,
              justify: 'start',
              padding: [6, 36, 6]
              // debug: true
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  justify: 'start',
                  size: 'auto',
                  grow: 0,
                  shrink: 1,
                  padding: [0, 0, 0, 0]
                  // debug: true
                },
                contents: [
                  {
                    component: {
                      pdf: 'TaskList'
                    },
                    options: {
                      size: 'auto',
                      grow: 0,
                      shrink: 1,
                      width: 480
                      // debug: true
                    },
                    source: 'quoteTasks'
                  }
                ]
              }
            ]
          },
          // Total
          {
            options: {
              direction: 'row',
              size: 'auto',
              grow: 0,
              shrink: 1,
              justify: 'flex-end',
              align: 'stetch',
              padding: [6, 36, 6],
              hide: settings.hideTotalAmount
              // debug: true
            },
            contents: [
              {
                component: {
                  pdf: 'Subtitle'
                },
                source: 'totalTitle'
              },
              {
                component: {
                  pdf: 'Subtitle'
                },
                source: 'totalText'
              }
            ]
          },
          // Closing Remarks
          {
            options: {
              direction: 'row',
              size: 'auto',
              grow: 0,
              shrink: 1,
              justify: 'start',
              padding: [6, 36, 6]
              // debug: true
            },
            contents: [
              {
                component: {
                  default: 'Text',
                  pdf: 'RichContentViewer'
                },
                source: 'closingRemarks'
              }
            ]
          },
          // Terms and conditions Row
          {
            options: {
              direction: 'row',
              breakPage: 'before',
              width: '100%'
              // debug: true
            },
            contents: [
              {
                options: {
                  size: 'auto',
                  grow: 0,
                  shrink: 1,
                  justify: 'flex-start'
                  // debug: true
                },
                // For centering title
                contents: [
                  {
                    options: {
                      direction: 'row',
                      size: 'auto',
                      grow: 0,
                      shrink: 1,
                      justify: 'center',
                      padding: [6, 36, 6]
                      // debug: true
                    },
                    contents: [
                      {
                        options: {
                          direction: 'column',
                          size: 'auto',
                          grow: 0,
                          shrink: 1
                          // debug: true
                        },
                        contents: [
                          {
                            component: {
                              default: 'BoldText'
                            },
                            source: 'Terms and Conditions'
                          }
                        ]
                      }
                    ]
                  },
                  {
                    options: {
                      padding: [6, 36, 6],
                      fontSize: 9
                    },
                    component: {
                      default: 'BodyText'
                    },
                    source:
                      'Covered Equipment: “Covered Equipment” means the equipment, as listed on the equipment Inventory, for which services are to be provided under this Agreement.  Within the Covered Equipment, “maintainable” parts are those parts of equipment that are mechanically moving parts, that through periodic servicing can be made to operate, or continue to operate, efficiently and effectively.  Examples of maintainable parts include motors, compressors, relays, controls, bearings and belts.  A non-exhaustive list of examples of parts that are non-maintainable, and therefore are excluded from related coverage (unless otherwise specified), includes replacement and disposition of refrigerant, ductwork, piping, boiler refractory materials, heat exchangers, insulation, wiring, structural cabinets and supports, shell and tube bundles, evaporators, condensers and cooling tower structures.'
                  },
                  {
                    options: {
                      padding: [6, 36, 6],
                      fontSize: 9
                    },
                    component: {
                      default: 'BodyText'
                    },
                    source:
                      'Access to the Premises: Customer shall, at all times, provide FSS, Inc. with access to all covered equipment under this agreement.  Customer shall keep the areas adjacent to the covered equipment free and clear of any obstructions that may impeded performance of the Services.  Any failure to provide such access may result in additional charges if a return call is required to perform these Services.'
                  },
                  {
                    options: {
                      padding: [6, 36, 6],
                      fontSize: 9
                    },
                    component: {
                      default: 'BodyText'
                    },
                    source:
                      'Payment and Incidental Charges: Customer will promptly pay invoices according to Terms.  Should a payment become thirty (30) days or more delinquent, FSS may stop all services and terminate this agreement upon written notice to Customer.  A finance charge will be added to past due accounts at the rate of one and one-half percent (1.5%) per month, or at the highest legal rate, whichever is lower.'
                  },
                  {
                    options: {
                      padding: [6, 36, 6],
                      fontSize: 9
                    },
                    component: {
                      default: 'BodyText'
                    },
                    source:
                      'Time of Performance: All Services to be provided under this Agreement shall be performed during FSS’ normal working hours.  Services not covered by this Agreement shall be billed at FSS’ prevailing rates.'
                  },
                  {
                    options: {
                      padding: [6, 36, 6],
                      fontSize: 9
                    },
                    component: {
                      default: 'BodyText'
                    },
                    source:
                      'Operation of Equipment: Customer agrees that during the Term, Customer shall: Operate the covered equipment according to manufacturer’s recommendations; keep accurate logs and information on the covered equipment; ensure that all operators of the covered equipment are adequately trained; all allow FSS to start and stop or temporarily suspend operation of the covered equipment so that FSS can perform the Services.  FSS will notify Customer prior to starting or stopping the covered equipment.'
                  },
                  {
                    options: {
                      padding: [6, 36, 6],
                      fontSize: 9
                    },
                    component: {
                      default: 'BodyText'
                    },
                    source:
                      'Maintainability:  This agreement assumes that covered equipment is in maintainable condition.  Should repairs or replacements be found necessary upon initial inspection, initial seasonal start-up or otherwise, FSS will prepare and submit proposed repair and/or replacement charges to Customer for approval before commencing any such work.'
                  },
                  {
                    options: {
                      padding: [6, 36, 6],
                      fontSize: 9
                    },
                    component: {
                      default: 'BodyText'
                    },
                    source:
                      'Environmental:  FSS will not be liable in any way, shape or form for any problems or injuries caused directly or indirectly from, but not limited to, outside air, refrigerant recovery, building air or any other ventilation or environmental type problem. Customer shall indemnify and hold harmless FSS, Inc. from any loss or damage, which may arise from any environmental problems.'
                  },
                  {
                    options: {
                      padding: [6, 36, 6],
                      fontSize: 9
                    },
                    component: {
                      default: 'BodyText'
                    },
                    source:
                      'Warranty: FSS, Inc. will provide a one-year warranty on all equipment installed and maintained by FSS, Inc. FSS, Inc. will only provide a ninety-day warranty on all installed equipment, which is not maintained by our company.  FSS, Inc. shall provide no warranty on any equipment, which has not yet been paid in full. Except as provided herein, no warranty or guaranty, express or implied, including any warranty as to merchantability or fitness for a particular purpose.'
                  },
                  {
                    options: {
                      padding: [6, 36, 6],
                      fontSize: 9
                    },
                    component: {
                      default: 'BodyText'
                    },
                    source:
                      'Indemnification: To the fullest extent provided by law, each party hereto agrees to indemnify and hold the other party, its successors, assigns, agent’s affiliates, employees and representatives harmless from and against all claims, damages, losses, and expenses, including but not limited to attorneys’ fees; unless such claims, damages, losses, or expenses arise out of or result from such party’s negligence or unlawful conduct.'
                  },
                  {
                    options: {
                      padding: [6, 36, 6],
                      fontSize: 9
                    },
                    component: {
                      default: 'BodyText'
                    },
                    source:
                      'Agreement Changes: This agreement is the entire Agreement between FSS and Customer and supersedes any prior oral understandings, written agreements, proposals or other communications between FSS and Customer.  Any and all changes, amendments or modifications to this Agreement shall be in writing and executed by an officer, or other authorized person, of each party.'
                  },
                  {
                    options: {
                      direction: 'row',
                      size: 'auto',
                      grow: 0,
                      shrink: 1,
                      justify: 'flex-end',
                      align: 'stetch',
                      padding: [36, 36, 6]
                    },
                    contents: [
                      {
                        component: {
                          pdf: 'BodyText'
                        },
                        source: 'initialsTitle'
                      },
                      {
                        component: {
                          pdf: 'BodyText'
                        },
                        source: 'initialsDateTitle'
                      }
                    ]
                  },
                  // to stick TOC in one page
                  {
                    options: {
                      padding: 40
                    },
                    contents: []
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  };
};
export default FacilitySystemsPDFForm;
