const dailyReportSidebar = [
  {
    source: 'dateText',
    label: 'Date'
  },
  {
    source: 'createdBy',
    label: 'Submitted By'
  },
  {
    source: 'workCompleted',
    label: 'Work Performed'
  },
  {
    source: 'issues',
    label: 'Delays/Issues'
  },
  {
    source: 'outOfScopeWork',
    label: 'Out of Scope Work'
  },
  {
    source: 'safetyConcerns',
    label: 'Safety Concerns'
  },
  {
    source: 'otherObservations',
    label: 'Other Observations'
  },
  {
    source: 'internalNotes',
    label: 'Internal Notes'
  }
];

export default dailyReportSidebar;
