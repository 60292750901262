import gql from 'graphql-tag';

const updateQuoteLineProduct = gql`
  mutation updateQuoteLineProduct($partitionKey: String!, $data: UpdateQuoteLineProductInput!) {
    updateQuoteLineProduct(partitionKey: $partitionKey, data: $data) {
      id
      version
      name
      description
      unitPrice
      unitCost
      markupType
      markupValue
      quantity
      taxable
      productId
      priceBookEntryId
      parentId
    }
  }
`;

export default updateQuoteLineProduct;
