import React, { useMemo } from 'react';

import { Field, FieldType, ThemeProvider, TV, TW, Typography } from '@buildhero/sergeant';
import { jsx } from '@emotion/react';
import { sortBy } from 'lodash';

import WrapTable, { BoldCell } from 'components/WrapTable';
import { convertToCurrencyString } from 'utils';
import { InvoiceItemSourceType, InvoiceItemType } from 'utils/constants';

import { SectionHeader } from '../../Components';
import { tableCurrencyFormatter, tablePercentageFormatter } from '../../utils';

const getColumns = [
  {
    field: 'source',
    headerName: 'Source',
    width: 175,
    renderCell: ({ row }) => (
      <Field
        value={{
          label: row.source,
          to: row.quoteId ? `/quote/${row.quoteId}` : `/invoice/view/${row.invoiceId}`
        }}
        type={FieldType.LINK}
      />
    )
  },
  { field: 'laborType', headerName: 'Labor Type' },
  { field: 'description', headerName: 'Description' },
  {
    field: 'unitCost',
    headerName: 'Unit Cost',
    valueFormatter: tableCurrencyFormatter,
    width: 100
  },
  { field: 'markup', headerName: 'Markup', valueFormatter: tablePercentageFormatter, width: 140 },
  {
    field: 'unitPrice',
    headerName: 'Unit Price',
    width: 100,
    valueFormatter: tableCurrencyFormatter
  },
  {
    field: 'hours',
    headerName: 'Hours',
    width: 65,
    valueFormatter: ({ value }) => (
      <Typography
        css={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}
        variant={TV.BASE}
        numeric
      >
        {value}
      </Typography>
    )
  },
  {
    field: 'subtotal',
    headerName: 'Subtotal',
    width: 100,
    valueFormatter: tableCurrencyFormatter,
    renderCell: BoldCell,
    totalGetter: ({ rows }) => rows.reduce((acc, r) => acc + r.subtotal, 0),
    renderTotal: props => (
      <Typography
        css={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}
        variant={TV.BASE}
        weight={TW.BOLD}
        numeric
      >
        {convertToCurrencyString(props.formattedValue ?? 0)}
      </Typography>
    )
  }
];

const mapItemsToRows = (quoteJobs, invoices) => {
  const quoteRows = [];
  quoteJobs.forEach(quoteJob => {
    const quoteTasks = quoteJob.quote.quoteLineTasks?.items || [];
    quoteTasks.forEach(quoteTask => {
      const quoteLabors = quoteTask.quoteLineLabors?.items || [];
      quoteLabors.forEach(quoteLabor => {
        quoteRows.push({
          source: `Quote ${quoteJob.quote.customIdentifier || quoteJob.quote.quoteNumber}`,
          laborType: quoteLabor.labourType?.name,
          description: quoteLabor.description,
          unitCost: quoteLabor.unitCost,
          markup: quoteLabor.markup,
          unitPrice: quoteLabor.unitPrice,
          hours: quoteLabor.quantity,
          subtotal: quoteLabor.totalPrice,
          quoteCustomIdentifier: quoteJob.quote.customIdentifier,
          quoteNumber: quoteJob.quote.quoteNumber,
          quoteId: quoteJob.quote.id
        });
      });
    });
  });

  quoteRows.sort((a, b) => {
    const quoteLabelA = a.quoteCustomIdentifier || a.quoteNumber;
    const quoteLabelB = b.quoteCustomIdentifier || b.quoteNumber;
    if (quoteLabelA < quoteLabelB) return -1;
    if (quoteLabelA > quoteLabelB) return 1;
    if (a.laborType < b.laborType) return -1;
    if (a.laborType > b.laborType) return 1;
    return 0;
  });

  const invoiceRows = [];
  invoices.forEach(invoice => {
    const invoiceItems = invoice.invoiceItems?.items || [];
    invoiceItems
      .filter(
        i =>
          i.lineItemType === InvoiceItemType.LABOR_LINE_ITEM &&
          i.source === InvoiceItemSourceType.INVOICE
      )
      .forEach(invoiceItem => {
        invoiceRows.push({
          source: `Invoice ${invoice.invoiceNumber}`,
          description: invoiceItem.description,
          unitPrice: invoiceItem.unitPrice,
          hours: invoiceItem.quantity,
          subtotal: invoiceItem.unitPrice * invoiceItem.quantity,
          invoiceNumber: invoice.invoiceNumber,
          invoiceId: invoice.id
        });
      });
  });
  return [...quoteRows, ...sortBy(invoiceRows, 'invoiceNumber')];
};

const InvoiceLaborTable = ({ jobData, isLoading }) => {
  const quoteJobs = jobData.quoteJobs?.items || [];
  const invoices = jobData.invoices?.items || [];
  const rows = useMemo(() => mapItemsToRows(quoteJobs, invoices), [quoteJobs, invoices]);
  const columns = useMemo(() => getColumns, []);

  return (
    <ThemeProvider>
      <SectionHeader title="Labor" />
      <WrapTable
        columns={columns}
        rows={rows}
        noDataMessage="No labor data"
        hideFooter={rows.length <= 10}
        enableTotalsRow
        loading={isLoading}
        loadingRows={3}
      />
    </ThemeProvider>
  );
};

InvoiceLaborTable.defaultProps = {
  isLoading: false
};

export default InvoiceLaborTable;
