import React, { useCallback, useEffect } from 'react';

import { calculateMarginFromMarkup, calculateMarkupFromMargin } from '@buildhero/math';
import { PercentageInput, ThemeProvider } from '@buildhero/sergeant';
import PropTypes from 'prop-types';

import { PricingStrategy } from 'utils/constants';
import { convertForMathLib } from 'utils/mathLibrary';

const DefaultRate = ({ form, field, options }) => {
  const { showConfigSection, marginEnabled } = options;
  let label;

  useEffect(() => {
    if (form.values?.defaultMarkup && marginEnabled) {
      form.setFieldValue(
        'defaultMarkup',
        convertForMathLib(calculateMarginFromMarkup, form.values.defaultMarkup)
      );
    }
  }, []);

  const convertDataBasedOnConfig = data => {
    return form.values.pricebookConfig === PricingStrategy.MARGIN
      ? convertForMathLib(calculateMarginFromMarkup, data)
      : convertForMathLib(calculateMarkupFromMargin, data);
  };

  useEffect(() => {
    if (field.value) {
      const convertedValue = convertDataBasedOnConfig(field.value);
      form.setFieldValue(field.name, convertedValue);
    }
  }, [form.values.pricebookConfig]);

  if (showConfigSection) {
    label =
      form?.values?.pricebookConfig === PricingStrategy.MARGIN
        ? 'Default Margin'
        : 'Default Markup';
  } else {
    label = marginEnabled ? 'Default Margin' : 'Default Markup';
  }

  const handleChange = useCallback(evt => form.setFieldValue(field.name, evt), [field.name, form]);

  return (
    <ThemeProvider>
      <PercentageInput
        onChange={handleChange}
        label={label}
        required
        error={form?.errors?.defaultMarkup}
        subtext={form?.errors?.defaultMarkup}
        value={field.value}
      />
    </ThemeProvider>
  );
};

export default DefaultRate;

DefaultRate.propTypes = {
  options: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired
};
