import gql from 'graphql-tag';

const getAddressesForCustomerProperty = gql`
  query getAddressesForCustomerProperty($partitionKey: String!, $sortKey: String!) {
    getCustomerProperty(partitionKey: $partitionKey, sortKey: $sortKey) {
      companyAddresses(entityConnection: "CompanyAddress") {
        items {
          billTo
          addressLine1
          addressLine2
          state
          city
          zipcode
          addressType
        }
      }
      billingCustomerId
      billingCustomer(entityConnection: "Customer") {
        isTaxable
        taxRateId
        companyAddresses(entityConnection: "CompanyAddress") {
          items {
            id
            tenantId
            tenantCompanyId
            partitionKey
            sortKey
            hierarchy
            entityType
            addressLine1
            addressLine2
            state
            city
            zipcode
            addressType
            entityType
            sortKey
            billTo
            longitude
            latitude
          }
        }
      }
      parentEntity {
        ... on Customer {
          isTaxable
          taxRateId
          companyAddresses(entityConnection: "CompanyAddress") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              addressLine1
              addressLine2
              state
              city
              zipcode
              addressType
              entityType
              sortKey
              billTo
              longitude
              latitude
            }
          }
        }
      }
      taxRate(entityConnection: "TaxRate") {
        taxRate
        accountingRefId
      }
      isTaxable
      companyName
    }
  }
`;

export default getAddressesForCustomerProperty;
