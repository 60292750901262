import gql from 'graphql-tag';

const getUnassignedVisitsWithKeys = gql`
  query getUnassignedVisitsWithKeys($partitionKey: String!, $sortKey: String!, $keys: [String]) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      visits(
        entityConnection: "Visit"
        filter: { status: { eq: "Unassigned" }, sortKey: { in: $keys } }
      ) {
        items {
          visitNumber
          version
          status
          startTime
          endTime
          scheduledFor
          description
          prerequisites
          prerequisitesAcknowledged
          departmentName
          departmentId
          hierarchy
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          onHold
          onHoldReason
          onRoute
          delayed
          delayedReason
          detailsSent
          tentativeDate
          extraTechsRequired
          extraTechsNumber
          minimumDuration
          actualDuration
          tentativePeriod
          tentativeDate
          tentativeTime
          id
          entityType
          parentId
          schedules(entityConnection: "Schedule") {
            items {
              id
              employeeSortKey
              parentId
              hierarchy
              entityType
              version
              tenantId
              tenantCompanyId
              partitionKey
              parentSortKey
              sortKey
              entityType
              status
              scheduledFor
              startTime
              endTime
              onRoute
              delayed
              delayedReason
              onHold
              onHoldReason
              timeSheets(entityConnection: "TimeSheet") {
                items {
                  visitDate
                  clockInTime
                  clockOutTime
                  parentId
                  hierarchy
                  id
                  entityType
                  version
                  tenantId
                  tenantCompanyId
                  partitionKey
                  parentSortKey
                  sortKey
                }
              }
            }
          }
          primaryTechs(entityConnection: "VisitPrimaryTech") {
            items {
              id
              sortKey
              invertedSortKey
              mappedEntity {
                ... on Employee {
                  id
                  firstName
                  lastName
                  entityType
                  sortKey
                  hierarchy
                  tenantId
                  profilePictureUrl
                }
              }
            }
          }
          extraTechs(entityConnection: "VisitExtraTech") {
            items {
              id
              sortKey
              invertedSortKey
              mappedEntity {
                ... on Employee {
                  id
                  firstName
                  lastName
                  entityType
                  sortKey
                  hierarchy
                  tenantId
                  profilePictureUrl
                }
              }
            }
          }
          parentEntity {
            ... on Job {
              id
              tenantId
              tenantCompanyId
              partitionKey
              entityType
              sortKey
              hierarchy
              entityType
              jobNumber
              customIdentifier
              customerSortKey
              customerName
              priority
              customerPropertyName
              jobTypeName
              jobTypeInternal
              customerRepName
              amountNotToExceed
              customerProvidedPONumber
              issueDescription
              departments(entityConnection: "JobDepartment") {
                items {
                  id
                  mappedEntity {
                    ... on Department {
                      id
                      tagName
                    }
                  }
                }
              }
              parentEntity {
                ... on CustomerProperty {
                  id
                  companyName
                  companyAddresses(entityConnection: "CompanyAddress") {
                    items {
                      id
                      addressType
                      addressLine1
                      addressLine2
                      city
                      state
                      zipcode
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getUnassignedVisitsWithKeys;
