export const formatMaintenanceTemplates = templates =>
  templates.map(template => {
    const {
      jobTagsJSON = '',
      maintenanceTaskTemplates,
      maintenanceType,
      ...templateDetail
    } = template;
    let tags = [];
    if (jobTagsJSON.length !== 0) {
      tags = JSON.parse(jobTagsJSON);
    }
    return {
      ...templateDetail,
      maintenanceTaskTemplates: maintenanceTaskTemplates?.items || [],
      tags,
      maintenanceTypeId: maintenanceType?.id,
      maintenanceTypeName: maintenanceType?.tagName
    };
  });

export const constructMaintenanceTemplatePayload = (data, maintenanceTemplatesV2) => {
  const formattedData = {
    ...data,
    firstDueDate: data?.firstDueDate,
    endDate: data?.endDate,
    // numeric fields when cleared, formik sends empty string
    estimatedVisits: data.estimatedVisits || null,
    budgetedHours: data?.budgetedHours || null,
    numberOfTechs: data?.numberOfTechs || null,
    totalBudgetedHours: data?.totalBudgetedHours || null,
    numberOfOccurrences: data?.numberOfOccurrences || 0,
    maintenanceTaskTemplates: maintenanceTemplatesV2
      ? data.maintenanceTaskTemplates
      : data?.maintenanceTaskTemplates?.filter(key => Object.keys(key).length) || []
  };
  return formattedData;
};

export const getActiveStatusFromFilter = filter => {
  if (filter?.status?.condition === 'eq') return filter.status?.value || null;
  return null;
};

export const callSetFilter = (setFilter, filter, status) => {
  if (status) {
    setFilter({
      ...filter,
      status: {
        condition: 'eq',
        type: 'stringFilters',
        value: status
      }
    });
  } else {
    const { status: remove, ...newFilter } = filter;
    setFilter(newFilter);
  }
};
