import gql from 'graphql-tag';

const addNotesToCustomerProperty = gql`
  mutation addNotesToCustomerProperty(
    $partitionKey: String!
    $data: AddNotesToCustomerPropertyInput!
  ) {
    addNotesToCustomerProperty(partitionKey: $partitionKey, data: $data) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      subject
      note
      createdBy
      lastUpdatedDate
      lastUpdatedDateTime
      lastUpdatedBy
    }
  }
`;

export default addNotesToCustomerProperty;
