import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PageHeader from 'components/PageHeader';
// import Breadcrumb from "components/Breadcrumb";
import Divider from 'components/Divider';

class Settings extends Component {
  render() {
    return (
      <Fragment>
        <PageHeader title="Settings" />
        <Divider />
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps, null)(Settings);
