import gql from 'graphql-tag';

const createProductCategory = gql`
  mutation saveProductCategoryToCompany(
    $partitionKey: String!
    $data: AddProductCategoryToCompanyInput!
  ) {
    saveProductCategoryToCompany(partitionKey: $partitionKey, data: $data) {
      id
      name
      sortOrder
    }
  }
`;

export default createProductCategory;
