import gql from 'graphql-tag';

const getCostCodes = gql`
  query getCostCodes($partitionKey: String!, $sortKey: String!, $filter: TableCostCodeFilterInput) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      costCodes(entityConnection: "CostCode", filter: $filter) {
        items {
          id
          name
          description
          version
          isActive
        }
      }
    }
  }
`;

export default getCostCodes;
