import React from 'react';
import { number, string } from 'prop-types';
import { Field, FieldType } from '@buildhero/sergeant';

const ReportHeaderTitle = ({ reportTitle, visitScheduledFor }) => (
  <>
    {reportTitle && <Field value={reportTitle} />}
    {reportTitle && visitScheduledFor && <Field value="|" />}
    {visitScheduledFor && <Field type={FieldType.DATETIME} value={visitScheduledFor * 1000} />}
  </>
);

ReportHeaderTitle.propTypes = {
  reportTitle: string.isRequired,
  visitScheduledFor: number.isRequired
};

export default ReportHeaderTitle;
