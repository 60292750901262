import gql from 'graphql-tag';

const jobNoteUpdateNotification = gql`
  subscription jobNoteUpdateNotification($partitionKey: String!) {
    jobNoteUpdateNotification(partitionKey: $partitionKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      subject
      note
      createdBy
      lastUpdatedDate
      lastUpdatedDateTime
      lastUpdatedBy
      parentId
    }
  }
`;

export default jobNoteUpdateNotification;
