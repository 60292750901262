import gql from 'graphql-tag';

const saveReviewReportBillItem = gql`
  mutation saveReviewReportBillItems(
    $partitionKey: String
    $items: [SaveBillItemToReviewReportInput]
  ) {
    saveReviewReportBillItems(partitionKey: $partitionKey, items: $items) {
      id
      costCodeId
      jobCostTypeId
      revenueTypeId
      description
      reviewReportId
      billLineId
      quantity
      unitCost
      taxable
      unitPrice
      markup
      billLine {
        remainingQuantity
      }
      reviewReport {
        id
        reviewReportBillItems {
          items {
            id
            costCodeId
            jobCostTypeId
            revenueTypeId
            billLineId
            quantity
            markup
            unitCost
            unitPrice
            createdBy
            createdDate
            taxable
            description
            billLine {
              billId
              jobId
              invoicedStatus
              remainingQuantity
              product {
                description
                name
              }
              bill {
                billNumber
                purchaseOrderReceipt {
                  imageUrl
                  receiptNumber
                }
                purchaseOrder {
                  addedBy
                  createdBy
                  receiptNumber
                  vendorName
                  poNumber
                  dateOfPurchase
                  totalAmountPreTax
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default saveReviewReportBillItem;
