import React, { Component } from 'react';

import { Button, ButtonType, Divider, ThemeProvider } from '@buildhero/sergeant';
import Grid from '@material-ui/core/Grid';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { keys, mapValues, omit, sortBy, values } from 'lodash';
import { connect } from 'react-redux';

import { ConfirmLeave, PageHeader, Placeholder, UserPermission } from 'components';
import Context from 'components/Context';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { CompanyService, ServiceAgreement } from 'services/core';
import { Logger } from 'services/Logger';

import { getTenantSettingValueForKey, removeEmptyValues } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';
import {
  CustomFieldNameMapping,
  CustomFieldNames,
  CustomFieldTypeNames,
  CustomFieldTypes
} from 'utils/constants';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { CustomFieldsCard } from './CustomFieldsCard';

class CustomFields extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.CompanyService = new CompanyService();
    this.ServiceAgreementService = new ServiceAgreement();
    this.state = {
      assetTypes: '',
      customerTags: '',
      customerTypes: '',
      customerPropertyTypes: '',
      jobTypes: '',
      jobTags: '',
      maintenanceTypes: '',
      purchaseOrderTags: '',
      taskTypes: '',
      productCategories: '',
      projectTypes: '',
      projectSubTypes: '',
      formServices: {
        customerTags: null,
        customerTypes: null,
        customerPropertyTypes: null,
        jobTypes: null,
        jobTags: null,
        maintenanceTypes: null,
        purchaseOrderTags: null,
        taskTypes: null
      },
      formChanged: {
        customerTags: false,
        customerTypes: false,
        customerPropertyTypes: false,
        jobTypes: false,
        jobTags: false,
        maintenanceTypes: false,
        purchaseOrderTags: false,
        taskTypes: false,
        projectTypes: false,
        projectSubTypes: false
      },
      loading: false
    };
  }

  contextUpdate = () => {
    Logger.debug('Context is refreshed');
  };

  componentDidMount = async () => {
    document.title = 'BuildOps - Custom fields';
    if (!this.props.user.tenantId) {
      return null;
    }
    const sortKey = `${this.props.user.tenantId}_Company_${this.props.user.tenantCompanyId}`;
    try {
      const { data } = await this.CompanyService.getCustomFieldsDefinitions(
        this.props.user.tenantId,
        sortKey
      );
      let processedData;
      if (data && data.getCompany) {
        const {
          assetTypes,
          customerTags,
          customerTypes,
          customerPropertyTypes,
          jobTypes,
          jobTags,
          invoiceTags,
          serviceAgreementTags,
          quoteTags,
          purchaseOrderTags,
          taskTypes,
          projectTypes,
          projectSubTypes,
          productCategories,
          ...rest
        } = data.getCompany;

        processedData = {
          parent: rest,
          assetTypes: assetTypes?.items,
          customerTags: customerTags?.items,
          customerTypes: customerTypes?.items,
          customerPropertyTypes: customerPropertyTypes?.items,
          jobTypes: jobTypes?.items?.filter(type => type.tagType === CustomFieldTypes.JobTypes),
          jobTags: jobTags?.items,
          invoiceTags: invoiceTags?.items,
          quoteTags: quoteTags?.items,
          serviceAgreementTags: serviceAgreementTags?.items,
          maintenanceTypes: jobTypes?.items?.filter(
            type => type.tagType === CustomFieldTypes.MaintenanceTypes
          ),
          purchaseOrderTags: purchaseOrderTags?.items,
          taskTypes: taskTypes?.items,
          projectTypes: projectTypes?.items.map(p => ({ ...p, tagName: p.name })),
          projectSubTypes: projectSubTypes?.items.map(p => ({ ...p, tagName: p.name })),
          productCategories: productCategories?.items.map(p => ({ ...p, tagName: p.name }))
        };

        let isJobTypeCounterEnabled;
        if (Context.getCompanyContext() && Context.getCompanyContext().getCompany) {
          isJobTypeCounterEnabled = getTenantSettingValueForKey('jobTypeSequence') === 'true';
        }
        if (this.mounted) {
          this.setState({ ...processedData, isJobTypeCounterEnabled });
        }
      }
    } catch (error) {
      Logger.error(`Error in fetching custom settings ${JSON.stringify(error)}`);
      this.props.snackbarOn(
        'error',
        'Unable to fetch custom fields , please try again later',
        error
      );
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  mutateCustomFields = async values => {
    const response = await this.CompanyService.mutateCustomFields(values);
    return response;
  };

  mutateAssetTypes = values => {
    return this.CompanyService.saveAssetTypeToCompany(values);
  };

  mutateJobTags = values => {
    return this.CompanyService.mutateCustomFieldJobTags(values);
  };

  mutateInvoiceTags = values => {
    return this.CompanyService.mutateCustomFieldInvoiceTags(values);
  };

  mutateServiceAgreementTags = values => {
    return this.ServiceAgreementService.addServiceAgreementTagToCompany(values);
  };

  mutateQuoteTags = values => {
    return this.CompanyService.mutateCustomFieldQuoteTags(values);
  };

  mutatePurchaseOrderTags = values => {
    return this.CompanyService.mutateCustomFieldPurchaseOrderTags(values);
  };

  mutateProjectTypes = values => {
    return this.CompanyService.mutateProjectTypes(values);
  };

  mutateProjectSubTypes = values => {
    return this.CompanyService.mutateProjectSubTypes(values);
  };

  mutateProductCategories = values => {
    return this.CompanyService.mutateProductCategories(values);
  };

  // Get the mutation service based on the fieldName (e.g. jobTags, assetType, etc..)
  getMutationService = name => {
    switch (name) {
      case CustomFieldNames.JobTags:
        return this.mutateJobTags;
      case CustomFieldNames.InvoiceTags:
        return this.mutateInvoiceTags;
      case CustomFieldNames.ServiceAgreementTags:
        return this.mutateServiceAgreementTags;
      case CustomFieldNames.QuoteTags:
        return this.mutateQuoteTags;
      case CustomFieldNames.PurchaseOrderTags:
        return this.mutatePurchaseOrderTags;
      case CustomFieldNames.AssetTypes:
        return this.mutateAssetTypes;
      case CustomFieldNames.ProjectTypes:
        return this.mutateProjectTypes;
      case CustomFieldNames.ProjectSubTypes:
        return this.mutateProjectSubTypes;
      case CustomFieldNames.ProductCategories:
        return this.mutateProductCategories;
      default:
        return this.mutateCustomFields;
    }
  };

  handleDelete = async (data, type) => {
    let result = false;
    if (!data.id) {
      return result;
    }
    try {
      let response = null;
      const { tenantId, tenantCompanyId } = this.props.user;
      switch (type) {
        case CustomFieldTypeNames.JobTags:
          response = await this.CompanyService.deleteJobTags(tenantId, data.id);
          break;
        case CustomFieldTypeNames.InvoiceTags:
          response = await this.CompanyService.deleteInvoiceTags(tenantId, data.id);
          break;
        case CustomFieldTypeNames.ServiceAgreementTags:
          response = await this.ServiceAgreementService.deleteServiceAgreementTagsFromCompany(
            data.id
          );
          break;
        case CustomFieldTypeNames.QuoteTags:
          response = await this.CompanyService.deleteQuoteTags(tenantId, data.id);
          break;
        case CustomFieldTypeNames.PurchaseOrderTags:
          response = await this.CompanyService.deletePurchaseOrderTags(tenantId, data.id);
          break;
        case CustomFieldTypeNames.AssetTypes:
          response = await this.CompanyService.deleteAssetTypeById(tenantId, data.id);
          break;
        case CustomFieldTypeNames.ProjectTypes:
          response = await this.CompanyService.deleteProjectType(tenantId, data.id);
          break;
        case CustomFieldTypeNames.ProjectSubTypes:
          response = await this.CompanyService.deleteProjectSubType(tenantId, data.id);
          break;
        case CustomFieldTypeNames.ProductCategories:
          response = await this.CompanyService.deleteProductCategory(tenantId, data.id);
          break;
        default:
          response = await this.CompanyService.deleteCustomField(tenantId, data.sortKey);
      }
      if (response?.data) {
        result = true;
        this.props.snackbarOn('success', `Successfully deleted ${type}: ${data.tagName}`);
        Context.setCompanyContext(
          tenantId,
          Context.generateCompanyContextSortKey(this.props.user),
          this.contextUpdate,
          true
        );
        // on collapse and expand, the delete time is still listed as the data are maintained at the root level
        // By marking the form change, the save button is enabled and user can hit save, enables the states in the data refreshed
        // re-querying or modifying the state, removes the unsaved items when an item is deleted, Hence took this less UX degradation route
        this.setChanged(CustomFieldNameMapping[type]);
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to delete, please try again later', error);
    }
    return result;
  };

  setChanged = name => {
    this.setState(prev => ({ ...prev, formChanged: { ...prev.formChanged, [name]: true } }));
  };

  setLoading = val => this.setState({ loading: val });

  // Save form service for each SgtForm
  setFormService = (fieldName, service) => {
    const { formServices } = this.state;
    formServices[fieldName] = service;
    this.setState(prev => ({ ...prev, formServices }));
  };

  render() {
    const { user, flags } = this.props;
    let renderPageForm = false;
    const {
      assetTypes,
      customerTags,
      customerTypes,
      customerPropertyTypes,
      jobTypes,
      jobTags,
      invoiceTags,
      quoteTags,
      serviceAgreementTags,
      maintenanceTypes,
      purchaseOrderTags,
      taskTypes,
      projectTypes,
      projectSubTypes,
      productCategories,
      parent
    } = this.state;

    if (customerTags !== '') {
      renderPageForm = true;
    }

    let customFieldsDataMapping = {
      [CustomFieldTypeNames.CustomerTags]: customerTags,
      [CustomFieldTypeNames.CustomerTypes]: customerTypes,
      [CustomFieldTypeNames.CustomerPropertyTypes]: customerPropertyTypes,
      [CustomFieldTypeNames.JobTypes]: jobTypes,
      [CustomFieldTypeNames.JobTags]: jobTags,
      [CustomFieldTypeNames.InvoiceTags]: invoiceTags,
      [CustomFieldTypeNames.ServiceAgreementTags]: serviceAgreementTags,
      [CustomFieldTypeNames.QuoteTags]: quoteTags,
      [CustomFieldTypeNames.PurchaseOrderTags]: purchaseOrderTags,
      [CustomFieldTypeNames.TaskTypes]: taskTypes,
      [CustomFieldTypeNames.AssetTypes]: assetTypes,
      [CustomFieldTypeNames.MaintenanceTypes]: maintenanceTypes,
      [CustomFieldTypeNames.ProjectTypes]: projectTypes,
      [CustomFieldTypeNames.ProjectSubTypes]: projectSubTypes,
      [CustomFieldTypeNames.ProductCategories]: productCategories
    };
    // Hide custom fields that have feature flag off
    customFieldsDataMapping = omit(customFieldsDataMapping, [
      flags?.procurement ? null : CustomFieldTypeNames.PurchaseOrderTags,
      flags[FeatureFlags.PRODUCT_CATEGORIES_ENABLED] ? null : CustomFieldTypeNames.ProductCategories
    ]);

    const sortedData = {};
    keys(customFieldsDataMapping).forEach(key => {
      sortedData[key] = sortBy(customFieldsDataMapping[key], ['sortOrder']);
    });

    // Get preparePayload functions based on the fieldName
    const getPreparePayload = fieldName => values => {
      const nullRemovedFormData = removeEmptyValues(values);
      const valuesWithNewSortOrders = {};
      valuesWithNewSortOrders[fieldName] = nullRemovedFormData[fieldName].map((value, index) => ({
        ...value,
        sortOrder: index + 1
      }));
      const valuesWithSystemInputs = {
        ...valuesWithNewSortOrders,
        tenantId: user.tenantId,
        tenantCompanyId: user.tenantCompanyId,
        fieldName,
        parent
      };
      return valuesWithSystemInputs;
    };

    // Get an array of mutation functions based on the fieldNames of changed fields
    const getBatchUpdateFunctions = fieldNames => {
      const functions = fieldNames.map(fieldName => ({
        fieldName,
        action: this.getMutationService(fieldName)
      }));
      return functions;
    };

    // Updates all changed Custom Fields
    const batchUpdate = async fieldNames => {
      const batchUpdateFunctions = getBatchUpdateFunctions(fieldNames);
      const batchUpdateFunctionsWithData = [];

      batchUpdateFunctions.forEach(funcObj => {
        const { fieldName, action } = funcObj;
        const { values: rawVal } = this.state.formServices[fieldName].formikContext;
        const payload = getPreparePayload(fieldName)(rawVal);
        batchUpdateFunctionsWithData.push(action(payload));
      });
      return Promise.all(batchUpdateFunctionsWithData);
    };

    // Check and update all forms that have been changed
    const handleSave = async () => {
      const { tenantId, tenantCompanyId } = user;
      this.setLoading(true);

      const formsToUpdate = keys(this.state.formChanged).filter(key => this.state.formChanged[key]);
      try {
        await batchUpdate(formsToUpdate);
        let message = 'Successfully Updated the following Custom Fields: \n';
        message += formsToUpdate
          .map(
            fieldName => CustomFieldTypeNames[fieldName[0].toUpperCase() + fieldName.substring(1)]
          )
          .join('\n');
        this.props.snackbarOn('success', message);
      } catch (err) {
        this.props.snackbarOn('error', 'Failed to save, please try again later', err);
      }
      // Refetch the data and reset the states
      this.componentDidMount();
      this.setLoading(false);
      this.setState(prevState => ({ formChanged: mapValues(prevState.formChanged, () => false) }));
      Context.setCompanyContext(
        tenantId,
        Context.generateCompanyContextSortKey(user),
        this.contextUpdate,
        true
      );
    };

    return (
      <ErrorBoundaries>
        <ConfirmLeave when={values(this.state.formChanged).some(value => value)} />
        <UserPermission I="allow" action={PermissionConstants.MENU_SETTINGS}>
          <PageHeader
            pageMapKey="customFields"
            userLocale={user.locale}
            mode="edit"
            overrideHeaderButtons={[
              <ThemeProvider>
                <Button
                  type={ButtonType.PRIMARY}
                  style={{ width: 100 }}
                  onClick={handleSave}
                  loading={this.state.loading}
                  disabled={!values(this.state.formChanged).some(value => value)}
                  key="addButton"
                >
                  Save
                </Button>
              </ThemeProvider>
            ]}
          />
          <Divider height={2} />
          {!renderPageForm ? (
            <Placeholder variant="card" repeatCount={4} />
          ) : (
            <>
              {keys(sortedData).map(key => (
                <Grid item sm={12} md={8} lg={4}>
                  <CustomFieldsCard
                    data={sortedData[key]}
                    displayName={key}
                    fieldName={CustomFieldNameMapping[key]}
                    setFormService={this.setFormService}
                    setChanged={this.setChanged}
                    onDelete={this.handleDelete}
                  />
                  <Divider />
                </Grid>
              ))}
            </>
          )}
          <Grid style={{ paddingTop: 93 }} />
        </UserPermission>
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapNewcustomFieldsToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const reduxConnectedcustomFields = connect(
  mapStateToProps,
  mapNewcustomFieldsToProps
)(CustomFields);

export default withLDConsumer()(reduxConnectedcustomFields);
