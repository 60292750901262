import React from 'react';

import { MUIForm } from '@buildhero/sergeant';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import {
  generalInformationFields,
  layout,
  shippingInformationFields
} from 'meta/Procurement/PurchaseOrders/generalInformationFormForSummary';
import {
  itemListFields,
  itemListLayout
} from 'meta/Procurement/PurchaseOrders/ItemListFormForSummary';
import MuiFormSectionTitle from 'scenes/Procurement/component/MuiFormSectionTitle';
import PurchaseOrderCost from 'scenes/Procurement/component/PurchaseOrderCost';
import buildHeroMuiFormOverrides from 'scenes/ProjectManagement/components/buildHeroMuiFormOverrides';
import CustomDivider from 'scenes/ProjectManagement/components/CustomDivider';
import CustomFieldWithLabel from 'scenes/ProjectManagement/components/CustomFieldWithLabel';
import { generateDefaultValidationSchema } from 'scenes/ProjectManagement/components/formattingUtils';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  formSummaryContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  addedItemsContainer: {
    minWidth: 920
  },
  pocContainer: {
    maxWidth: 220,
    marginLeft: 24
  },
  dividerContainer: {
    width: 714,
    padding: 8
  },
  divider: {
    width: '100%',
    backgroundColor: '#E5E5E5'
  },
  formContainer: buildHeroMuiFormOverrides(theme)
}));

const CustomDividerStyled = () => {
  return <CustomDivider width={714} padding={8} backgroundColor="#E5E5E5" />;
};

const CustomFieldWithLabelNarrow = ({ form, field, options }) => {
  return (
    <CustomFieldWithLabel
      form={form}
      field={field}
      options={options}
      style={{ lineHeight: '21px', wordBreak: 'break-word' }}
      nowrap={options?.nowrap}
    />
  );
};

const SummaryStep = props => {
  const classes = useStyles();
  const { generalData, itemsData, associatedProject, costsData, allTags = [] } = props;
  const tagNames = allTags
    .filter(tag => generalData?.purchaseOrderTags?.includes(tag.value))
    .map(tag => tag.label);
  generalData.tagNames = tagNames;
  generalData.shipToName = generalData?.employee?.name || generalData?.shipToName;
  const isVistaEnabled = useSelector(state => state.settings.isVistaEnabled);
  const formattedItemsData = itemsData.map(item => {
    let costCodeDescription = null;
    if (item.projectId) {
      costCodeDescription = `${item.projectCostCode?.name}-${item.projectCostCode?.description}`;
    } else if (item.costCodeObj?.id) {
      costCodeDescription = item.costCodeObj?.description;
    }
    return {
      ...item,
      costCodeDescription,
      jcPhase: item.jcPhase?.name,
      jcCostType: item.jcCostType?.name
    };
  });

  return (
    <div className={classes.root}>
      <div className={classes.formSummaryContainer}>
        <div className={classes.formContainer}>
          <MUIForm
            configuration={layout({ jobNumber: generalData?.jobNumber })}
            data={generalData}
            layout="default"
            onCreateService={() => {}}
            onComplete={() => {}}
            customComponents={{
              MuiFormSectionTitle,
              CustomDividerStyled,
              CustomFieldWithLabelNarrow
            }}
            validationSchema={generateDefaultValidationSchema({
              ...generalInformationFields,
              ...shippingInformationFields
            })}
          />
          {formattedItemsData && (
            <div className={classes.addedItemsContainer}>
              {formattedItemsData.map((item, index) => (
                <MUIForm
                  key={`procurement-po-items-summary-${item.itemNumber}`}
                  configuration={itemListLayout({
                    showLabel: {
                      showLabel: index === 0
                    },
                    associatedProject,
                    isVistaEnabled
                  })}
                  data={item}
                  layout="default"
                  onCreateService={() => {}}
                  onComplete={() => {}}
                  customComponents={{ MuiFormSectionTitle, CustomFieldWithLabelNarrow }}
                  validationSchema={generateDefaultValidationSchema(itemListFields)}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={classes.pocContainer}>
        <PurchaseOrderCost
          formLayout="default"
          freightCosts={costsData.freightCost || 0}
          taxRateId={costsData.taxRate?.id || costsData.taxRateId || ''}
          taxRate={costsData.taxRate || {}}
          lineItems={itemsData}
        />
      </div>
    </div>
  );
};

CustomFieldWithLabelNarrow.propTypes = {
  field: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

SummaryStep.propTypes = {
  generalData: PropTypes.object.isRequired,
  itemsData: PropTypes.array.isRequired,
  costsData: PropTypes.object.isRequired,
  associatedProject: PropTypes.object.isRequired
};

export default SummaryStep;
