import React from 'react';
import { shape, string } from 'prop-types';

import mergeDeep from 'utils/mergeDeep';

import { VisitStatuses } from '@dispatch/Dispatch.constants';
import { emptyVisitDetailsFragment } from '@dispatch/fragments';
import VisitCard from '@dispatch/components/VisitCard';

const formatVisitPartial = job => {
  const fragment = {
    job,
    status: VisitStatuses.UNKNOWN.key,
    Status: VisitStatuses.UNKNOWN,
    property: {
      customerPropertyName: job?.customerPropertyName,
      id: job?.customerPropertyId
    }
  };

  return mergeDeep(emptyVisitDetailsFragment, fragment);
};

const JobDetailsHeader = ({ job }) => {
  return <VisitCard isDrawer visit={formatVisitPartial(job)} />;
};

JobDetailsHeader.propTypes = {
  job: shape({
    customerId: string,
    customerName: string,
    customerPropertyId: string,
    customerPropertyName: string,
    jobNumber: string
  }).isRequired
};

export default JobDetailsHeader;
