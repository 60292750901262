import gql from 'graphql-tag';

const updateAttachment = gql`
  mutation updateAttachment($partitionKey: String, $data: UpdateAttachmentInput!) {
    updateAttachment(partitionKey: $partitionKey, data: $data) {
      fileName
      fileSize
      customFileName
      fileUrl
      id
      version
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      description
      comment
      createdDate
      createdDateTime
      createdBy
      isInternalFile
      hideFromTechniciansOnMobile
    }
  }
`;

export default updateAttachment;
