import { InventoryService, PricebookService } from 'services/core';
import { Logger } from 'services/Logger';
import { convertStringToFloat } from 'utils';

export const createTruck = async (formData, tenantId, tenantCompanyId) => {
  const InventoryServiceObj = new InventoryService();

  const payload = {
    companyId: tenantCompanyId,
    trucks: [
      {
        name: formData.truckName || null,
        productBundleId: formData.bundleId || null
      }
    ]
  };

  if (formData?.employeeId) {
    payload.trucks[0].truckEmployees = formData.employeeId.map(empId => ({
      isDefault: true,
      employeeId: empId
    }));
  }

  const response = await InventoryServiceObj.addTruck(tenantId, payload);
  return response;
};

export const updateTruck = async (formData, tenantId) => {
  const InventoryServiceObj = new InventoryService();

  const payload = {
    id: formData.id,
    version: formData.version,
    name: formData.truckName,
    productBundleId: formData.bundleId || null
  };

  if (formData?.employeeId) {
    payload.truckEmployees = formData.employeeId.map(empId => ({
      isDefault: true,
      employeeId: empId
    }));
  }
  const response = await InventoryServiceObj.updateTruck(tenantId, payload);
  return response;
};

export const deleteTruck = async (id, name, snackbarOn) => {
  try {
    const InventoryServiceObj = new InventoryService();
    const response = await InventoryServiceObj.deleteTruck(id);
    if (response?.data) {
      snackbarOn('success', `Successfully deleted truck - ${name}`);
    }
  } catch (error) {
    Logger.error(error);
    snackbarOn('error', 'Unable to fetch trucks, please try again later', error);
  }
};

export const getAllBundles = async user => {
  const PricebookServiceObj = new PricebookService();
  const sortKey = `${user.tenantId}_Company_${user.tenantCompanyId}`;
  const truckTemplateFilter = {
    stringFilters: {
      fieldName: 'type',
      filterInput: {
        eq: 'TruckTemplate'
      }
    }
  };
  const response = await PricebookServiceObj.getProductBundles(
    user.tenantId,
    sortKey,
    truckTemplateFilter
  );
  return response;
};

export const getAllActiveTrucks = async (
  tenantId,
  companySortKey,
  filter,
  limit,
  offset,
  sortBy,
  sortOrder,
  snackbarOn
) => {
  const InventoryServiceObj = new InventoryService();
  let data;
  try {
    data = await InventoryServiceObj.getAllActiveTrucks(
      tenantId,
      companySortKey,
      filter,
      limit,
      offset,
      sortBy,
      sortOrder
    );
  } catch (error) {
    Logger.error(error);
    snackbarOn('error', 'Unable to fetch trucks, please try again later', error);
  }
  return data || { items: [] };
};

export const addTruckInventories = async (formData, truckId) => {
  const InventoryServiceObj = new InventoryService();

  const payload = {
    truckId,
    truckInventories: [
      {
        productId: formData.productId,
        minQuantity: convertStringToFloat(formData.minQuantity) || 0,
        maxQuantity: convertStringToFloat(formData.maxQuantity) || 0,
        quantityOnHand: convertStringToFloat(formData.quantityOnHand) || 0
      }
    ]
  };

  const response = await InventoryServiceObj.addTruckInventories(payload);
  return response;
};

export const updateTruckInventories = async formData => {
  const InventoryServiceObj = new InventoryService();

  const payload = {
    id: formData.id,
    version: formData.version,
    minQuantity: convertStringToFloat(formData.minQuantity) || 0,
    maxQuantity: convertStringToFloat(formData.maxQuantity) || 0,
    quantityOnHand: convertStringToFloat(formData.quantityOnHand) || 0,
    productId: formData.productId
  };

  const response = await InventoryServiceObj.updateTruckInventories(payload);
  return response;
};

export const deleteTruckInventories = async (id, name, snackbarOn) => {
  try {
    const InventoryServiceObj = new InventoryService();
    const response = await InventoryServiceObj.deleteTruckInventories(id);
    if (response?.data) {
      snackbarOn('success', `Successfully deleted - ${name}`);
    }
  } catch (error) {
    Logger.error(error);
    snackbarOn('error', 'Unable to de, please try again later', error);
  }
};

export const doReplenishmentForItems = async (inventoryItems, truckId) => {
  const InventoryServiceObj = new InventoryService();

  const truckInventories = inventoryItems.map(item => ({
    id: item.id,
    productId: item.productId,
    quantityOnHand: convertStringToFloat(item.maxQuantity) || 0
  }));

  const payload = {
    truckId,
    truckInventories
  };

  const response = await InventoryServiceObj.addTruckInventories(payload);
  return response;
};

export const bulkDeleteTruckInventories = async inventoryItems => {
  const InventoryServiceObj = new InventoryService();

  const truckInventoryIds = inventoryItems.map(item => item.id);

  const payload = {
    truckInventoryIds
  };

  const response = await InventoryServiceObj.bulkDeleteTruckInventories(payload);
  return response;
};
