/* eslint-disable no-useless-concat */
/* eslint-disable no-template-curly-in-string */
import React from 'react';
import { View, Text } from '@react-pdf/renderer';

const mottPDFForm = settings => ({
  fields: {
    senderInfoLogoUrl: {
      pdf: '${senderInfoLogo}'
    },
    tenantAddress: {
      pdf: '${senderInfo.addressLine1}  *  ${senderInfo.addressLine3}  *  ${senderInfo.phoneNumber}'
    },
    // Customer Detail
    customerContactNameTitle: {
      pdf: 'Attn: '
    },
    customerContactName: {
      pdf: '${customerContactNameTitle}'
    },
    customerNameTitle: {
      pdf: 'Company: '
    },
    customerName: {
      pdf: '${customer.customerName}'
    },
    billingAddressLine1Title: {
      pdf: 'Address: '
    },
    billingBillTo: {
      pdf: '${quoteInfo.billTo}'
    },
    customerContactPhoneTitle: {
      pdf: 'Phone: '
    },
    customerContactPhone: {
      pdf: '${customerContactPhoneTitle}'
    },
    customerContactEmailTitle: {
      pdf: 'Via Email: '
    },
    customerContactEmail: {
      pdf: '${customerContactEmailTitle}'
    },
    // Tenant Detail
    createdDateTitle: {
      pdf: 'Date: '
    },
    createdDate: {
      pdf: '${createdDate}'
    },
    quoteNameTitle: {
      pdf: 'Re: '
    },
    quoteName: {
      pdf: '${quoteInfo.name}'
    },
    propertyLabel: {
      pdf: 'Site: '
    },
    propertyName: {
      pdf: '${property.companyName}'
    },
    propertyAddress1: {
      pdf: '${propertyDetail.propertyAddressLine1}'
    },
    propertyAddress2: {
      pdf: '${propertyDetail.propertyAddressLine2}'
    },
    propertyAddress3: {
      pdf: '${propertyDetail.propertyAddressLine3}'
    },
    quoteNumberValueTitle: {
      pdf: 'File Number: '
    },
    quoteNumberValue: {
      pdf: '${customizedQuoteNumber}'
    },
    ownerValueTitle: {
      pdf: 'From: '
    },
    ownerValue: {
      pdf: '${quoteInfo.ownerValue}'
    },
    senderInfoEmailTitle: {
      pdf: 'Email: '
    },
    senderInfoEmail: {
      pdf: '${owner.email}'
    },
    // Introduction
    introduction: {
      pdf: '${quoteCustomFieldsData.Custom_longText1}'
    },
    // Total
    totalTitle: {
      pdf: 'Total Proposal: '
    },
    totalText: {
      pdf: '$${finalAmount}'
    },
    // Closing Remarks
    closingRemarks: {
      pdf: '${quoteCustomFieldsData.Custom_longText2}'
    },
    // Estimator
    estimator: {
      pdf: '${quoteInfo.ownerValue}\nProject manager\n${senderInfo.companyName}'
    }
  },
  layouts: {
    default: {
      options: {
        pdfTitle: 'Quote',
        customFooterHeight: '30mm',
        customPageFooter: () => (
          <View style={{ textAlign: 'center' }}>
            <Text style={{ fontSize: 11, marginBottom: 8 }}>
              Serving B.C.’s Electrical and Communication Industries Since 1930
            </Text>
            <Text style={{ fontSize: 8 }}>
              Burnaby Address: 100 – 4599 Tillicum Street, Burnaby, BC V5J 3J9 T: 604 522 5757 F:
              604 524 3531
            </Text>
            <Text style={{ fontSize: 8 }}>
              Vancouver Address: 419 W Hastings Street, Vancouver, BC V6B 1L4 T: 604 683 5752. F:
              604 683 5753
            </Text>
          </View>
        )
      },
      contents: [
        // Logo Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              options: {
                direction: 'column',
                size: 'auto',
                grow: 0,
                shrink: 1
                // debug: true
              },
              contents: [
                {
                  component: {
                    default: 'Logo'
                  },
                  options: {
                    width: 300,
                    height: 300,
                    alt: 'Company logo',
                    size: 'auto',
                    grow: 0,
                    shrink: 1
                  },
                  source: 'senderInfoLogoUrl'
                }
              ]
            }
          ]
        },
        // Customer Detail Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'space-between',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            // Customer Details
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                direction: 'column',
                width: '65%',
                padding: [0, 5, 0, 0]
              },
              contents: [
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactName'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerName'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'billingAddressLine1Title'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'propertyAddress'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactPhoneTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactPhone'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactEmailTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactEmail'
                    }
                  ]
                }
              ]
            },
            // Tenant Details
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                direction: 'column',
                width: '30%'
              },
              contents: [
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'createdDateTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'createdDate'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'quoteNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'quoteName'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'quoteNumberValueTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'quoteNumberValue'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'ownerValueTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'ownerValue'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'senderInfoEmailTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'senderInfoEmail'
                    }
                  ]
                }
              ]
            }
          ]
        },
        // Divider
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              options: {
                color: '#333'
              },
              component: {
                pdf: 'Divider'
              }
            }
          ]
        },
        // Intro
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'RichContentViewer'
              },
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1
              },
              source: 'introduction'
            }
          ]
        },
        // Tasks and Products
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              options: {
                direction: 'column',
                justify: 'start',
                size: 'auto',
                grow: 0,
                shrink: 1,
                padding: [0, 0, 0, 0]
                // debug: true
              },
              contents: [
                {
                  component: {
                    pdf: 'TaskList'
                  },
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    width: 480
                    // debug: true
                  },
                  source: 'quoteTasks'
                }
              ]
            }
          ]
        },
        // Total
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'flex-end',
            align: 'stetch',
            padding: [6, 36, 6],
            hide: settings.hideTotalAmount
            // debug: true
          },
          contents: [
            {
              component: {
                pdf: 'Subtitle'
              },
              source: 'totalTitle'
            },
            {
              component: {
                pdf: 'Subtitle'
              },
              source: 'totalText'
            }
          ]
        },
        // Closing Remarks
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'RichContentViewer'
              },
              source: 'closingRemarks'
            }
          ]
        }
      ]
    }
  }
});

export default mottPDFForm;
