import React, { useEffect, useRef, useState } from 'react';

import { ButtonType, Button as SgtButton, ThemeProvider } from '@buildhero/sergeant';
import { Box, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { noop, uniq } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { compose } from 'redux';

import { Context, PageHeader, ResponsiveTable } from 'components';
import ErrorBoundaries from 'scenes/Error';
import AmplifyService from 'services/AmplifyService';
import { CommonService } from 'services/core';
import exportPartsList from 'services/core/graphql/maintenance/queries/exportPartsList';
import { Logger } from 'services/Logger';
import { checkPermission } from 'utils';
import {
  JobStatus,
  MaintenanceStatus,
  PermissionConstants,
  ServiceAgreementStatus
} from 'utils/AppConstants';
import { SergeantButtonTypes, VisitCreationType } from 'utils/constants';

import { FeatureFlags } from 'utils/FeatureFlagConstants';
import fileDownload from 'utils/fileDownload';

import BulkVisitCreationModal from './BulkVisitCreationModal';
import CreateVisitsModal from './components/CreateVisitsModal';
import StatusFilters from './components/StatusFilter';
import UpcomingMaintenanceJobsInlineAlert from './components/UpcomingMaintenanceJobsInlineAlert';
import { CreateMaintenanceModal } from './CreateMaintenanceModal';
import DueDate from './DueDate.component';
import Filter from './Filter';
import { getDueDateRangeFromFilter } from './helpers';
import ManualVisitCreationModal from './ManualVisitCreationModal';
import {
  GetCompanyServiceAgreementSettings,
  GetServiceAgreementAndMaintenanceTemplateByNumber,
  MAINTENANCE_LIST_DATA,
  MAINTENANCE_LIST_DATA_BY_LOCATION
} from './queries';
import styles from './styles';

const getJobsPreferredTechniciansConfigMap = async jobs => {
  const companySettings = Context.getCompanySettingsMap();
  const data = await Promise.all(
    jobs.map(async ({ jobNumber }) => {
      try {
        const { client } = AmplifyService.appSyncClient();

        const response = await client.query({
          query: GetServiceAgreementAndMaintenanceTemplateByNumber,
          variables: {
            jobNumber
          }
        });
        const { serviceAgreement, maintenanceTemplate, id: maintenanceId } =
          response?.data?.getJobByJobNumber || {};
        const assetsInMaintenanceTaskTemplates =
          maintenanceTemplate?.maintenanceTaskTemplates?.items?.map(template => template.assetId) ||
          [];
        const visitAssets = uniq(assetsInMaintenanceTaskTemplates).map(assetInTemplate => ({
          propertyAssetId: assetInTemplate
        }));

        return {
          jobNumber,
          maintenanceId,
          serviceAgreement,
          maintenanceTemplate,
          visitAssets
        };
      } catch (error) {
        Logger.error(error);
      }
    })
  );
  return data
    ?.filter(item => !!item)
    .reduce(
      (
        result,
        { jobNumber, maintenanceId, serviceAgreement, maintenanceTemplate, visitAssets }
      ) => {
        return {
          ...result,
          [jobNumber]: {
            maintenanceId,
            companySettings,
            serviceAgreement,
            maintenanceTemplate,
            visitAssets
          }
        };
      },
      {}
    );
};

const BulkActionTypes = {
  EXPORT_PARTS: 'exportParts',
  BULK_VISIT_ACTION: 'bulkVisitAction'
};

const Button = props => (
  <ThemeProvider>
    <SgtButton {...props}>{props.children}</SgtButton>
  </ThemeProvider>
);

const PanelButtons = ({
  agreementInfo,
  selectedRecords,
  handleRecords,
  exporting,
  isInlineTable,
  openCreateMaintenance,
  isActiveSA,
  hasPermissionToCreateJob,
  hasPermissionToCreateVisits,
  shouldShowUpcomingMaintenanceJobsAlert,
  companyTimeZone
}) => (
  <Grid container justify="space-between">
    {shouldShowUpcomingMaintenanceJobsAlert && (
      <Grid container xs={6}>
        <Grid item>
          <ThemeProvider>
            <UpcomingMaintenanceJobsInlineAlert
              agreementInfo={agreementInfo}
              companyTimeZone={companyTimeZone}
            />
          </ThemeProvider>
        </Grid>
      </Grid>
    )}
    <Grid container justify="flex-end" xs={shouldShowUpcomingMaintenanceJobsAlert ? 6 : 12}>
      {isInlineTable && isActiveSA && hasPermissionToCreateJob && (
        <Grid item>
          <ThemeProvider>
            <Button type={ButtonType.SECONDARY} onClick={openCreateMaintenance}>
              Create Maintenance
            </Button>
          </ThemeProvider>
        </Grid>
      )}
      {hasPermissionToCreateVisits && (
        <Grid item style={{ marginLeft: 10 }}>
          <Button
            type={ButtonType.PRIMARY}
            onClick={() => handleRecords(selectedRecords)}
            disabled={
              !selectedRecords.length ||
              selectedRecords.find(item => item.status === JobStatus.COMPLETE)
            }
          >
            Create Visits
          </Button>
        </Grid>
      )}
      <Grid item style={{ marginLeft: 10 }}>
        <Button
          type={SergeantButtonTypes.SECONDARY}
          loading={exporting}
          disabled={!selectedRecords.length || exporting}
          onClick={() => handleRecords(selectedRecords, BulkActionTypes.EXPORT_PARTS)}
        >
          Export Parts List
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

PanelButtons.propTypes = {
  agreementInfo: PropTypes.shape({}),
  selectedRecords: PropTypes.arrayOf(PropTypes.object),
  handleRecords: PropTypes.func.isRequired,
  exporting: PropTypes.bool,
  isInlineTable: PropTypes.bool,
  openCreateMaintenance: PropTypes.func,
  companyTimeZone: PropTypes.string
};

PanelButtons.defaultProps = {
  agreementInfo: null,
  selectedRecords: [],
  exporting: false,
  isInlineTable: false,
  openCreateMaintenance: noop,
  companyTimeZone: ''
};

function MaintenanceView(props) {
  const flags = useFlags();
  const [isCreateVisitsModalOpen, setIsCreateVisitsModalOpen] = useState(false);
  const [isCreateMaintenanceModalOpen, setIsCreateMaintenanceModalOpen] = useState(false);
  const [isCreateManualVisitsModalOpen, setIsCreateManualVisitsModalOpen] = useState(false);

  // Value == true :> If bulkVisit feature is disabled and selected records has completed scheduled and skipped status
  const [bulkVisitOption, setBulkVisitOption] = useState(false);

  // Value == true :> If bulkVisit feature is enabled and selected records has completed or skipped status
  const [disabledBulkVisit, setDisabledBulkVisit] = useState(false);

  // BulkFeatureMode disabled for scheduled maintenances, if enableBulkVisitCreation is not assigned in ServiceAgreementSettings.
  const [bulkFeatureMode, setBulkFeatureMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [jobsPreferredTechniciansConfigMap, setJobsPreferredTechniciansConfigMap] = useState([]);
  const commonService = useRef(new CommonService()).current;
  const [openCreateVisitsModal, setOpenCreateVisitsModal] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [refreshData, setRefreshData] = useState(0);
  const { client } = AmplifyService.appSyncClient();

  // used when the list is rendered within service agreement, recurring maintenance template
  const {
    serviceAgreementId,
    agreementInfo,
    overrideQuery,
    resultNode,
    isInlineTable,
    companyTimeZone
  } = props;

  const handleCreateVisits = (event, selectedValue) => {
    event.preventDefault();

    if (selectedValue === VisitCreationType.BULK) {
      setIsCreateVisitsModalOpen(true);
    } else if (selectedValue === VisitCreationType.MANUAL) {
      setIsCreateManualVisitsModalOpen(true);
    }
    setOpenCreateVisitsModal(false);
  };

  const getMaintenanceListData = async (columns, filter, sorting, pagination) => {
    if (Array.isArray(filter?.stringFilters) && filter?.stringFilters[0].fieldName === 'distance') {
      const [radius, property, lat, lng] = filter.stringFilters[0].filterInput.eq.split('|');

      const response = await commonService.getQuery(
        {
          columns,
          filter: {
            floatFilters: [
              {
                fieldName: 'distance',
                filterInput: {
                  le: Number(radius)
                }
              }
            ]
          },
          sorting,
          pagination,
          lat: Number(lat),
          lng: Number(lng),
          unit: 'miles'
        },
        MAINTENANCE_LIST_DATA_BY_LOCATION
      );
      return response?.data?.maintenanceListData;
    }

    const response = await commonService.getQuery(
      {
        serviceAgreementId,
        columns,
        filter,
        sorting,
        pagination
      },
      overrideQuery || MAINTENANCE_LIST_DATA
    );

    const node = resultNode || 'maintenanceListData';
    return response?.data?.[node];
  };

  const getExportLink = async maintenanceIds => {
    const filter = {
      stringFilters: {
        fieldName: 'maintenanceId',
        filterInput: {
          in: maintenanceIds
        }
      }
    };
    setExporting(true);
    const response = await client.query({
      query: exportPartsList,
      variables: {
        filter
      }
    });
    const exportLink = response?.data?.exportPartsList;
    fileDownload({}, null, '', exportLink);
    setExporting(false);
  };

  const handleRecords = (selectedRecords, mode = BulkActionTypes.BULK_VISIT_ACTION) => {
    if (mode === BulkActionTypes.EXPORT_PARTS) {
      const maintenanceIds = selectedRecords.map(maintenance => maintenance?.id);
      getExportLink(maintenanceIds);
      return;
    }
    // check selectedRecords's status for complete, scheduled or skipped if bulkFeatureMode is disabled
    if (!bulkFeatureMode)
      setBulkVisitOption(
        selectedRecords.some(
          item =>
            item.status === JobStatus.COMPLETE ||
            item.status === MaintenanceStatus.SCHEDULED ||
            item.status === MaintenanceStatus.SKIPPED
        )
      );
    // check selectedRecords's status for complete or skipped if bulkFeatureMode is enabled
    else
      setDisabledBulkVisit(
        selectedRecords.some(
          item => item.status === JobStatus.COMPLETE || item.status === MaintenanceStatus.SKIPPED
        )
      );
    setSelectedItems(selectedRecords);
    setOpenCreateVisitsModal(true);
  };

  useEffect(() => {
    const getServiceAgreementSettings = async () => {
      try {
        const { data } = await client.query({
          query: GetCompanyServiceAgreementSettings,
          variables: {
            partitionKey: props.user.tenantId,
            sortKey: `${props.user.tenantId}_Company_${props.user.tenantCompanyId}`
          }
        });
        const itemZero = data?.getCompany?.companySettings?.items[0];
        if (itemZero) {
          const { enableBulkVisitCreation } = JSON.parse(itemZero.settings);
          setBulkFeatureMode(enableBulkVisitCreation);
        }
      } catch (err) {
        Logger.error(err);
      }
    };
    getServiceAgreementSettings();
  }, [props.user.tenantCompanyId, props.user.tenantId]);

  useEffect(() => {
    if (selectedItems.length) {
      (async () => {
        const configs = await getJobsPreferredTechniciansConfigMap(selectedItems);
        setJobsPreferredTechniciansConfigMap(configs);
      })();
    }
  }, [selectedItems]);

  const hasPermissionToCreateJob = checkPermission(
    'create',
    PermissionConstants.OBJECT_JOB,
    props.user
  );

  const hasPermissionToCreateVisits = checkPermission(
    'create',
    PermissionConstants.OBJECT_VISIT,
    props.user
  );

  const shouldShowUpcomingMaintenanceJobsAlert =
    flags[FeatureFlags.SERVICE_AGREEMENTS_JOBS_PREVIEW] && agreementInfo?.upcomingMaintenanceJobs;

  return (
    <ErrorBoundaries>
      <BulkVisitCreationModal
        isOpen={isCreateVisitsModalOpen}
        user={props.user}
        handleClose={() => setIsCreateVisitsModalOpen(false)}
        refreshList={() => setRefreshData(refreshData + 1)}
        items={selectedItems}
        jobsPreferredTechniciansConfigMap={jobsPreferredTechniciansConfigMap}
      />
      {!overrideQuery && <PageHeader pageMapKey="maintenance" userLocale={props.user.locale} />}
      {openCreateVisitsModal && (
        <CreateVisitsModal
          openCreateVisitsModal={openCreateVisitsModal}
          handleClose={() => setOpenCreateVisitsModal(false)}
          disabledBulkVisit={disabledBulkVisit}
          user={props.user}
          items={selectedItems}
          bulkVisitOption={bulkVisitOption}
          handleCreateVisits={handleCreateVisits}
          classes={props.classes}
          isBulkFeatureDisabled={!bulkFeatureMode} // Value == true :> If bulkfeautre is disabled and selected record's does not have status from completed, skipped or scheduled
        />
      )}
      {isCreateMaintenanceModalOpen && (
        <CreateMaintenanceModal
          open={isCreateMaintenanceModalOpen}
          handleClose={(maintenanceCreated = false) => {
            if (maintenanceCreated) {
              setRefreshData(refreshData + 1);
            }
            setIsCreateMaintenanceModalOpen(false);
          }}
          agreementInfo={agreementInfo}
        />
      )}
      <div style={{ paddingBottom: '1rem' }} />
      <ManualVisitCreationModal
        isOpen={isCreateManualVisitsModalOpen}
        user={props.user}
        refreshList={() => setRefreshData(refreshData + 1)}
        handleClose={() => setIsCreateManualVisitsModalOpen(false)}
        items={selectedItems}
        jobsPreferredTechniciansConfigMap={jobsPreferredTechniciansConfigMap}
      />
      <ResponsiveTable
        fullScreen={!isInlineTable}
        noMaxHeight={isInlineTable}
        disableFilter={!!overrideQuery}
        tableName={overrideQuery ? null : 'Maintenance'} // avoiding the views when rendered withing SA
        user={props.user}
        caslKey={PermissionConstants.OBJECT_SERVICE_AGREEMENT}
        noDataMsg="No Maintenance Items"
        rowActionButtons={{
          select: true
        }}
        listDataService={getMaintenanceListData}
        defaults={{
          sortBy: 'dueDate',
          sortOrder: 'asc'
        }}
        refreshData={refreshData}
        topPanel={({ filter, setFilter, loading }) => (
          <Box display="flex" alignItems="center" flexDirection="row">
            <StatusFilters
              onClickStatusFilter={status => {
                setFilter({
                  ...filter,
                  status: {
                    condition: 'eq',
                    type: 'stringFilters',
                    value: status
                  }
                });
              }}
              disabled={loading}
            />
            {!overrideQuery && (
              <Filter
                onChangeDateRange={dateRange => {
                  setFilter({
                    ...filter,
                    dueDate: {
                      condition: 'between',
                      type: 'integerFilters',
                      value: [moment(dateRange.startDate).unix(), moment(dateRange.endDate).unix()]
                    }
                  });
                }}
                dueDateRange={getDueDateRangeFromFilter(filter)}
                onChangeLocation={filter => {
                  const lat = filter.billingLatitude;
                  const lng = filter.billingLongitude;

                  setFilter({
                    ...filter,
                    distance: {
                      condition: 'eq',
                      type: 'stringFilters',
                      value: `${filter.radius}|${filter.address}|${lat}|${lng}`
                    }
                  });
                }}
              />
            )}
          </Box>
        )}
        actionPanel={selectedRecords => (
          <PanelButtons
            agreementInfo={agreementInfo}
            selectedRecords={selectedRecords}
            handleRecords={handleRecords}
            exporting={exporting}
            isInlineTable={isInlineTable}
            openCreateMaintenance={() => setIsCreateMaintenanceModalOpen(true)}
            isActiveSA={agreementInfo?.status === ServiceAgreementStatus.ACTIVE}
            hasPermissionToCreateJob={hasPermissionToCreateJob}
            hasPermissionToCreateVisits={hasPermissionToCreateVisits}
            shouldShowUpcomingMaintenanceJobsAlert={shouldShowUpcomingMaintenanceJobsAlert}
            companyTimeZone={companyTimeZone}
          />
        )}
        customCellComponents={{
          DueDate: compProps => (
            <DueDate
              {...compProps}
              refreshList={() => setRefreshData(refreshData + 1)}
              timezone={companyTimeZone}
            />
          )
        }}
      />
    </ErrorBoundaries>
  );
}

MaintenanceView.propTypes = {
  user: PropTypes.shape({
    locale: PropTypes.string,
    tenantId: PropTypes.string,
    tenantCompanyId: PropTypes.string
  }).isRequired,
  classes: PropTypes.object,
  serviceAgreementId: PropTypes.string,
  overrideQuery: PropTypes.object,
  resultNode: PropTypes.string,
  isInlineTable: PropTypes.bool,
  agreementInfo: PropTypes.shape(),
  companyTimeZone: PropTypes.string
};

const mapStateToProps = state => ({
  user: state.user
});

MaintenanceView.defaultProps = {
  classes: {},
  serviceAgreementId: null,
  overrideQuery: null,
  resultNode: null,
  isInlineTable: false,
  agreementInfo: null,
  companyTimeZone: ''
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps)
)(MaintenanceView);
