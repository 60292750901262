/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getTaxRates() {
  return await instance
    .get('taxrates')
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getTaxRateById(id) {
  return await instance
    .get(`taxrates/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return {};
    });
}

export async function taxRateSearch(term, searchColumns) {
  if (term.length > 0) {
    return await instance
      .post(`taxrates/search/${term}`, { searchColumns: searchColumns || ['name'] })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  }
  return await getTaxRates();
}
