import { useTheme } from '@material-ui/core';

export const useStyles = () => {
  const theme = useTheme();
  return {
    link: {
      textDecoration: 'none',
      color: theme.palette.other.secondaryCyan
    }
  };
};
