import React from 'react';

import WrapTable from 'components/WrapTable/WrapTable.component';
import { TablePlaceholderVariant } from 'components/WrapTable/WrapTable.constants';

import { useBillLineItemColumns } from './BillLineItemsTable.columns';

const BillLineItemsTable = ({
  billLineItems,
  loading,
  error,
  onEditClick,
  onDeleteClick,
  isMarginEnabled
}) => {
  const billLineItemColumns = useBillLineItemColumns({
    onEditClick,
    onDeleteClick,
    isMarginEnabled
  });

  return (
    <WrapTable
      placeholderVariant={TablePlaceholderVariant.TEXT}
      columns={billLineItemColumns}
      error={error}
      loading={loading}
      rows={billLineItems}
      noDataMessage="NO BILL LINE ITEMS"
    />
  );
};

export default BillLineItemsTable;
