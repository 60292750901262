export const costType = ({ types }) => ({
  fields: {
    name: { default: 'name' },
    type: { default: 'type' }
  },
  layouts: {
    default: {
      options: {
        direction: 'column',
        grow: 0
      },
      contents: [
        {
          options: {
            direction: 'row',
            alignment: 'center',
            justify: 'center',
            align: 'center'
          },
          contents: [
            {
              options: {
                align: 'center',
                padding: [0, 12, 0, 12]
              },
              contents: [
                {
                  options: {
                    direction: 'column',
                    grow: 1,
                    padding: [12, 0, 12, 0]
                  },
                  contents: [
                    {
                      component: 'BodyText',
                      source: 'Name'
                    },
                    {
                      component: {
                        edit: 'TextInput',
                        default: 'FieldWithLabel'
                      },
                      source: 'name',
                      options: {
                        label: '',
                        placeholder: 'Name',
                        default_value: '',
                        smartField: '',
                        isRequired: true,
                        fullWidth: true,
                        alignment: 'left'
                      }
                    }
                  ]
                },
                {
                  options: {
                    direction: 'column',
                    grow: 1,
                    padding: [12, 0, 12, 0]
                  },
                  contents: [
                    {
                      component: { default: 'SelectInput' },
                      source: 'type',
                      options: {
                        label: 'Revenue Type',
                        valueSet: types,
                        placeholder: 'Cost Type',
                        isRequired: true
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      name: {
        type: 'string'
      },
      type: {
        type: 'string'
      }
    },
    required: ['name', 'type']
  },
  validationErrors: {
    name: {
      required: 'Field is required.'
    },
    type: {
      required: 'Field is required.'
    }
  }
});

export default costType;
