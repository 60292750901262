/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getCustomerReps(customerId) {
  const params = customerId ? { params: { where: { parentId: customerId } } } : undefined;
  return await instance
    .get('customerRep', params)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function customerRepSearch(term, searchColumns, customerId = undefined) {
  return await instance
    .post(`customerRep/search/${term}`, {
      searchColumns: searchColumns || ['name'],
      parentId: customerId
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function customerRepSearchProject(term, searchColumns, customerId = undefined) {
  if (term.length > 0) {
    return await instance
      .post(`customerRep/search/${term}`, {
        searchColumns: searchColumns || ['name'],
        parentId: customerId
      })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  }
  const params = customerId ? { params: { where: { parentId: customerId } } } : undefined;
  return await instance
    .get('customerRep', params)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getCustomerRepById(id) {
  return await instance
    .get(`customerRep/${id}`, { params: { include: '*' } })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
