import React, { useState, useEffect } from 'react';
import AmplifyService from 'services/AmplifyService';
import CustomerSearch from 'components/SgtAlgoliaSearch/SgtFormComponents/CustomerSearch';
import { ThemeProvider, Button, Modal, SgtForm } from '@buildhero/sergeant';
import { Logger } from 'services/Logger';

import { configuration } from './configuration';
import { AGREEMENT_ACTIONS } from '../../constants';
import { transitionServiceAgreement } from '../../service';
import { GetServiceAgreementNextCounterValue } from '../../../queries';

const CloneAgreementModal = ({ user, snackbar, open, onClose, agreementInfo, history }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [formService, setFormService] = useState();

  const handleSubmit = async data => {
    setLoading(true);

    // when the autogenerated number is chosen, value should not be sent to enable backend to generate it
    // per design, if no value sent for agreement number, value will be autogenerated,
    // when value is present its used as custom agreement number
    const agreementNumber =
      data.agreementNumber === formData?.agreementNumber ? undefined : data.agreementNumber;
    const payload = {
      id: data.id,
      agreementName: data.agreementName,
      agreementNumber,
      customerId: data.customer.id
    };
    try {
      const { id } = await transitionServiceAgreement(
        AGREEMENT_ACTIONS.CLONE,
        payload,
        user.tenantId,
        snackbar
      );
      history.push(`/serviceAgreement/view/${id}`);
    } catch (error) {
      Logger.error(error);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  useEffect(() => {
    const getLatestSANumber = async () => {
      const { client } = AmplifyService.appSyncClient();
      const { data } = await client.query({
        query: GetServiceAgreementNextCounterValue
      });
      try {
        const { serviceAgreement } = JSON.parse(data.getServiceAgreementNextCounterValue);
        setFormData({
          ...agreementInfo,
          ...formData,
          agreementName: '',
          agreementNumber: `SA${serviceAgreement}`
        });
      } catch (error) {
        Logger.error(error);
      }
    };

    getLatestSANumber();
  }, [agreementInfo]);

  return (
    <ThemeProvider>
      <Modal
        open={open}
        title="Clone Service Agreement"
        actions={
          <Button loading={loading} onClick={formService?.formikContext.handleSubmit} fullWidth>
            Clone Agreement
          </Button>
        }
        onClose={onClose}
      >
        <SgtForm
          initialValues={formData}
          configuration={configuration}
          customComponents={{ CustomerSearch }}
          onCreateService={setFormService}
          onSubmit={handleSubmit}
        />
      </Modal>
    </ThemeProvider>
  );
};

export default CloneAgreementModal;
