import { makeStyles } from '@material-ui/core/styles';

const menuWidth = {
  minWidth: '210px',
  maxWidth: '300px'
};

export default makeStyles(theme => ({
  container: {
    background: theme.palette.other.mayaBlue,
    justifyContent: 'space-between',
    borderRadius: '1px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    ...menuWidth
  },
  label: {
    flex: 1,
    fontSize: '14px'
  },
  menuItem: {
    ...menuWidth,
    fontSize: '14px'
  }
}));
