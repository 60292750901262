import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useEmployees from 'customHooks/useEmployees';
import useLabourRates from 'scenes/Jobs/JobDetail/queries/useLabourRates';
import useLabourTypes from 'scenes/Jobs/JobDetail/queries/useLabourTypes';
import usePayrollBillingHourTypes from 'scenes/Jobs/JobDetail/queries/usePayrollBillingHourTypes';
import usePricebooks from 'scenes/Jobs/JobDetail/queries/usePricebooks';

export const useCompanyEmployees = () => {
  const [data, loading] = useEmployees({
    includeBillingRate: true,
    includePayrollCost: true,
    includeDepartments: true
  });

  const dataMap = useMemo(
    () =>
      data?.reduce(
        (result, item) => ({
          ...result,
          [item?.id]: item
        }),
        {}
      ) || {},
    [data]
  );
  return { data, dataMap, loading };
};

export const useReportLaborRates = () => {
  const { tenantId, tenantCompanyId } = useSelector(state => state?.user) || {};
  return useLabourRates(tenantId, tenantCompanyId);
};

export const useReportLaborTypes = () => {
  const { tenantId, tenantCompanyId } = useSelector(state => state?.user) || {};
  return useLabourTypes(tenantId, tenantCompanyId);
};

export const useReportBillingHourTypes = () => {
  const { tenantId, tenantCompanyId } = useSelector(state => state?.user) || {};
  return usePayrollBillingHourTypes(tenantId, tenantCompanyId);
};

export const useReportPriceBooks = () => {
  const { tenantId, tenantCompanyId } = useSelector(state => state?.user) || {};
  return usePricebooks(tenantId, tenantCompanyId);
};
