export const drawerWidth = 216;
export const mobileDrawerWidth = 154;
const toolbarHeight = 60;
const bannerHeight = 32;

const styles = theme => ({
  root: {
    display: 'flex'
  },
  show: {
    transform: 'translateY(0)',
    transition: 'transform .5s'
  },
  hide: {
    transform: 'translateY(-110%)',
    transition: 'transform .5s'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth
    },
    [theme.breakpoints.down('sm')]: {
      width: mobileDrawerWidth
    },
    flexShrink: 0
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: mobileDrawerWidth
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${mobileDrawerWidth}px)`,
      marginLeft: mobileDrawerWidth
    },

    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20
  },
  toolbar: {
    height: toolbarHeight,
    minHeight: toolbarHeight,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    alignItems: 'stretch'
  },
  drawerPaper: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth
    },
    [theme.breakpoints.down('sm')]: {
      width: mobileDrawerWidth
    },
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: 0
  },
  content: {
    ...theme.mixins.main,
    flexGrow: 1,
    paddingTop: toolbarHeight + theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('sm')]: {
      marginLeft: -drawerWidth
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: -mobileDrawerWidth
    },
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  contentBannerShift: {
    paddingTop: toolbarHeight + bannerHeight + theme.spacing(3)
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  menuItem: {
    color: theme.palette.primary.contrastText,
    width: 'auto',
    borderBottom: '2px solid transparent',
    opacity: 0.8,
    '&:hover': {
      color: theme.palette.brand.logoGreen,
      borderColor: theme.palette.brand.logoGreen
    }
  },
  menuItemArrow: {
    fontSize: '1.3em',
    marginTop: 3
  },
  menuItemOpened: {
    color: theme.palette.brand.logoGreen,
    width: 'auto',
    borderBottom: `2px solid ${theme.palette.brand.logoGreen}`
  },
  menuSelected: {
    opacity: '1 !important',
    backgroundColor: `${theme.palette.primary.main}!important`
  },
  menuPopover: {
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
    zIndex: 500
  },
  menuPopoverItem: {
    color: theme.palette.text.primary,
    borderBottom: '2px solid transparent',
    backgroundColor: theme.palette.grayscale(100),
    '&:hover': {
      backgroundColor: theme.palette.grayscale(98),
      color: theme.palette.secondary.main
    }
  },
  listIcons: {
    color: 'inherit',
    margin: 0,
    marginRight: 12
  },
  listTexts: {
    fontWeight: 'inherit',
    textTransform: 'capitalize',
    cursor: 'pointer'
  },
  companyButtonWrapper: {
    width: 'auto',
    borderBottom: '2px solid transparent',
    opacity: 0.8,
    padding: 0,
    marginLeft: 0,
    marginRight: 16,
    whiteSpace: 'nowrap'
  },
  companyButton: {
    color: theme.palette.primary.contrastText,
    height: 40,
    backgroundColor: theme.palette.other.darkBlue,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 8
  },
  companyLogo: {
    width: 'auto',
    height: 'auto',
    marginTop: 6,
    maxWidth: 150,
    maxHeight: 100,
    marginLeft: 16
  },
  boLogo: {
    padding: 5,
    margin: 15
  },

  nestedMenuItems: {
    '& a': {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 0,
      paddingLeft: theme.spacing(7.5)
    }
  }
});

export default styles;
