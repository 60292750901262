import { withQueryStringStore } from '@dispatch/Dispatch.store';

import MapViewSelector from './MapViewSelector.component';

const mapQueryStringToProps = store => ({
  setMapView: store.setMapView,
  mapView: store.state.mapView
});

export default withQueryStringStore(mapQueryStringToProps)(MapViewSelector);
