import gql from 'graphql-tag';

const getAllCustomerByCompany = gql`
  query getCompany(
    $partitionKey: String!
    $sortKey: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      customer: customersView(
        entityConnection: "Customer"
        pagination: { limit: $limit, offset: $offset }
        filter: $filter
        sorting: $sort
        includeComputedColumns: true
      ) {
        nextToken
        items {
          companyAddresses(entityConnection: "CompanyAddress") {
            items {
              addressType
              addressLine1
              addressLine2
              city
              state
              zipcode
            }
          }
          customerTags(entityConnection: "CustomerTags") {
            items {
              id
              mappedEntityId
              parentId
              mappedEntity {
                ... on CustomerTag {
                  id
                  tagName
                }
              }
            }
          }

          createdBy
          createdDate
          customerName
          customerTypeValue
          email
          id
          isActive
          openJobsComputed
          openJobsValueComputed
          outstandingBalanceComputed
          overdueBalanceComputed
          phoneAlternate
          phonePrimary
          propertyCountComputed
          sortKey
        }
      }
    }
  }
`;

export default getAllCustomerByCompany;
