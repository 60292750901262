import React from 'react';

import { JobsTable } from 'components';

import ToggleButton from '@dispatch/components/ToggleButton/ToggleButton.component';

import { useStyles } from './JobsView.styles';

const JobsView = () => {
  const styles = useStyles();

  return (
    <div css={styles.container}>
      <div css={styles.header}>
        <ToggleButton />
      </div>
      <div css={styles.content}>
        <JobsTable />
      </div>
    </div>
  );
};

export default JobsView;
