const constructPayload = ({ customFileName: fileName, fileUrl, type, comment }) => ({
  fileName,
  fileUrl,
  type,
  comment
});

export const saveAttachment = async (context, event) => {
  const { services, id } = context;
  const { reviewReportService } = services;

  const formattedData = constructPayload(event?.saveData);

  const mutateData = {
    reviewReportId: id,
    attachments: [{ ...formattedData }]
  };

  const response = await reviewReportService.addAttachmentsToReviewReport(
    context.user.tenantId,
    mutateData
  );
  const { addAttachmentsToReviewReport } = response.data;
  const { attachments } = context;

  attachments.push(addAttachmentsToReviewReport[0]);

  return { attachments, modalRecord: {}, modalIndex: '' };
};

export const editAttachment = async (context, event) => {
  const { services, attachments } = context;
  const { reviewReportService } = services;
  const { id, version } = event?.saveData;

  const formattedData = constructPayload(event?.saveData);

  const updateAttachementResponse = await reviewReportService.updateAttachment(
    context.user.tenantId,
    { ...formattedData, id, version }
  );
  const { updateAttachment } = updateAttachementResponse.data;
  const modifiedAttachments = attachments.map(attachment => {
    let localAttachment = attachment;
    if (localAttachment.id === updateAttachment.id) {
      localAttachment = updateAttachment;
    }
    return localAttachment;
  });

  return { attachments: modifiedAttachments, modalRecord: {}, modalIndex: '' };
};

/* Code to include an Attachment item to invoice from Review Report */
export const includeAttachment = async context => {
  const { services, modalRecord, modalChecked } = context;
  const { reviewReportService } = services;

  const formattedData = (({ id, version, type, includeInInvoice }) => ({
    id,
    version,
    type,
    includeInInvoice
  }))(modalRecord);

  formattedData.includeInInvoice = modalChecked;

  const data = await reviewReportService.updateAttachment(modalRecord.partitionKey, formattedData);
  const { updateAttachment } = data.data;
  const { attachments } = context;

  const modifiedAttachments = attachments.map(attachment => {
    let localAttachment = attachment;
    if (localAttachment.id === updateAttachment.id) {
      localAttachment = updateAttachment;
    }
    return localAttachment;
  });

  return { attachments: modifiedAttachments, modalRecord: {}, modalIndex: '' };
};

export const deleteAttachment = async context => {
  const { services, user, modalRecord } = context;
  const { reviewReportService } = services;
  const queryData = await reviewReportService.deleteAttachmentById(user.tenantId, modalRecord.id);
  const { deleteAttachmentById } = queryData.data;

  const { attachments } = context;

  if (deleteAttachmentById) {
    const fileIndex = attachments.findIndex(attachment => attachment.id === modalRecord.id);
    attachments.splice(fileIndex, 1);
  }

  return { attachments, modalRecord: {}, modalIndex: '' };
};
