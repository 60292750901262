import React, { useMemo } from 'react';
import { ThemeProvider, Modal, PDFDocument } from '@buildhero/sergeant';
import { PDFViewer } from '@react-pdf/renderer';
import getConfiguration from './PaymentReceipt.config';

const PaymentReceiptModal = ({ open, onClose, payment }) => {
  const MemoizedPDF = useMemo(
    () =>
      payment?.id && (
        <PDFViewer width="100%" height="100%">
          <PDFDocument configuration={getConfiguration(payment)} initialValues={payment} />
        </PDFViewer>
      ),
    [payment]
  );

  return (
    <ThemeProvider>
      <Modal title="Payment Receipt" open={open} onClose={onClose} fullScreen>
        {MemoizedPDF}
      </Modal>
    </ThemeProvider>
  );
};

export default PaymentReceiptModal;
