import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  itemWrapper: {
    display: 'flex'
  },
  jobId: {
    color: theme.palette.grayscale(10)
  },
  visit: {
    color: theme.palette.grayscale(60),
    marginLeft: theme.spacing(0.5),
    textTransform: 'capitalize'
  },
  note: {
    '& a': {
      fontWeight: 600,
      color: theme.palette.grayscale(10)
    }
  },
  noteWrapper: {
    minWidth: '280px',
    maxWidth: '680px',
    flexBasis: 'auto',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center'
  },
  notesTitle: {
    color: theme.palette.grayscale(60),
    textTransform: 'uppercase',
    marginBottom: theme.spacing(1)
  }
}));
