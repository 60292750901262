import { PermissionConstants } from 'utils/AppConstants';

export const customerLayout = {
  entity: {
    name: 'customer',
    tenant: 'default',
    layouts: {
      web: {
        sections: [
          {
            title: 'General info',
            icon: 'contactIcon',
            sm: '12',
            md: '12',
            lg: '10',
            rows: [
              {
                spacing: 3,
                lg: '10',
                cols: [
                  {
                    type: 'dbField',
                    value: 'customerName',
                    name: 'customerName',
                    label: 'customerName',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'customerTypeValue',
                    name: 'customerTypeValue',
                    label: 'customerType',
                    specialBehaviour: {
                      includeQueryResult: true,
                      queryPath: 'customerTypes',
                      fieldName: 'tagName'
                      // fieldValue: 'sortKey'
                    },
                    behavior: {
                      new: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  }
                ]
              },
              {
                spacing: 3,
                cols: [
                  {
                    type: 'dbField',
                    value: 'phonePrimary',
                    name: 'phonePrimary',
                    label: 'customerPhone',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'receiveSMS',
                    name: 'receiveSMS',
                    label: 'receiveSMS',
                    behavior: {
                      new: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'email',
                    name: 'email',
                    label: 'customerEmail',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  }
                ]
              }
            ]
          },
          {
            title: '',
            sm: '12',
            md: '12',
            lg: '12',
            rows: [
              {
                spacing: '0',
                cols: [
                  {
                    type: 'rowHeading',
                    value: 'billingAddress',
                    name: 'billingAddress',
                    label: 'billingAddress',
                    behavior: {
                      new: {
                        sm: '12',
                        md: '12',
                        lg: '12'
                      },
                      edit: {
                        sm: '12',
                        md: '12',
                        lg: '12'
                      }
                    }
                  }
                ]
              }
            ]
          },
          {
            title: '',
            sm: '12',
            md: '12',
            lg: '10',
            rows: [
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbField',
                    value: 'billingBillTo',
                    name: 'billingBillTo',
                    label: 'billTo',
                    showField: 'isExtendedFieldsEnabled',
                    behavior: {
                      new: {
                        ui: 'TextArea',
                        sm: '12',
                        md: '8',
                        lg: '6'
                      },
                      edit: {
                        ui: 'TextArea',
                        sm: '12',
                        md: '8',
                        lg: '6'
                      }
                    }
                  }
                ]
              },
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbField',
                    value: 'billingAddressLine1',
                    name: 'billingAddressLine1',
                    label: 'customerBillingAddressLine1',
                    specialBehaviour: {
                      addressLine1: 'billingAddressLine1',
                      addressLine2: 'billingAddressLine2',
                      city: 'billingCity',
                      state: 'billingState',
                      zipcode: 'billingZipcode',
                      latitude: 'billingLatitude',
                      longitude: 'billingLongitude',
                      regionOptionsPath: 'taxRates',
                      regionField: 'taxRateId',
                      regionOptionField: 'id',
                      includeQueryResult: true
                    },
                    behavior: {
                      new: {
                        ui: 'AutoCompleteAddress',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      },
                      edit: {
                        ui: 'AutoCompleteAddress',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      }
                    }
                  }
                ]
              }
            ]
          },
          {
            title: '',
            sm: '12',
            md: '12',
            lg: '10',
            rows: [
              {
                spacing: '1',
                cols: [
                  {
                    type: 'dbField',
                    value: 'taxRateId',
                    name: 'taxRateId',
                    label: 'taxRate',
                    specialBehaviour: {
                      includeQueryResult: true,
                      queryPath: 'taxRates',
                      fieldName: 'label',
                      fieldValue: 'id'
                    },
                    behavior: {
                      new: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'isTaxable',
                    name: 'isTaxable',
                    label: 'taxable',
                    behavior: {
                      new: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '6',
                        lg: '4'
                      },
                      edit: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '6',
                        lg: '4'
                      }
                    }
                  }
                ]
              }
            ]
          },
          {
            title: '',
            sm: '12',
            md: '12',
            lg: '10',
            rows: [
              {
                spacing: 3,
                cols: [
                  {
                    type: 'dbField',
                    value: 'defaultProperty',
                    name: 'defaultProperty',
                    label: 'defaultProperty',
                    specialBehaviour: {
                      includeQueryResult: true,
                      showField: 'hideDefaultProperty'
                    },
                    behavior: {
                      new: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '6',
                        lg: '4'
                      }
                    }
                  }
                ]
              },
              {
                spacing: 3,
                cols: [
                  {
                    type: 'dbField',
                    value: 'sameAddress',
                    name: 'sameAddress',
                    label: 'businessAddressIsSame',
                    specialBehaviour: {
                      controlField: true,
                      mappings: {
                        businessAddressLine1: '',
                        businessAddressLine2: '',
                        businessCity: '',
                        businessState: '',
                        businessZipcode: '',
                        businessLatitude: '',
                        businessLongitude: ''
                      }
                    },
                    behavior: {
                      new: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '6',
                        lg: '4'
                      },
                      edit: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      }
                    }
                  }
                ]
              }
            ]
          },
          {
            title: '',
            sm: '12',
            md: '12',
            lg: '10',
            specialBehaviour: {
              dependentField: 'sameAddress',
              hideFieldOnCondition: true
            },
            rows: [
              {
                spacing: 3,
                lg: '10',
                cols: [
                  {
                    type: 'rowHeading',
                    value: 'businessAddressHeading',
                    name: 'businessAddressHeading',
                    label: 'businessAddressHeading',
                    specialBehaviour: {
                      dependentField: 'sameAddress',
                      hideFieldOnCondition: true
                    },
                    behavior: {
                      new: {
                        sm: '12',
                        md: '12',
                        lg: '12'
                      },
                      edit: {
                        sm: '12',
                        md: '12',
                        lg: '12'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'businessShipTo',
                    name: 'businessShipTo',
                    label: 'shipto',
                    showField: 'isExtendedFieldsEnabled',
                    behavior: {
                      new: {
                        ui: 'TextArea',
                        sm: '12',
                        md: '8',
                        lg: '6'
                      },
                      edit: {
                        ui: 'TextArea',
                        sm: '12',
                        md: '8',
                        lg: '6'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'businessAddressLine1',
                    name: 'businessAddressLine1',
                    label: 'businessAddressLine1',
                    specialBehaviour: {
                      dependentField: 'sameAddress',
                      hideFieldOnCondition: true,
                      addressLine1: 'businessAddressLine1',
                      addressLine2: 'businessAddressLine2',
                      city: 'businessCity',
                      state: 'businessState',
                      zipcode: 'businessZipcode',
                      latitude: 'businessLatitude',
                      longitude: 'businessLongitude'
                      // dependentValue: 'billingAddressLine1'
                    },
                    behavior: {
                      new: {
                        ui: 'AutoCompleteAddress',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      },
                      edit: {
                        ui: 'AutoCompleteAddress',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      }
                    }
                  }
                ]
              }
            ]
          },
          {
            title: '',
            sm: '12',
            md: '12',
            lg: '10',
            rows: [
              {
                spacing: '1',
                cols: [
                  {
                    type: 'dbArrayField',
                    value: 'processedCustomerTags',
                    name: 'processedCustomerTags',
                    label: 'tags',
                    specialBehaviour: {
                      includeQueryResult: true,
                      queryPath: 'customerTags',
                      fieldName: 'tagName',
                      // reference to the hidden field in the form
                      databaseMapping: 'tagMapping',
                      databaseId: 'customerDbIds'
                    },
                    behavior: {
                      new: {
                        ui: 'MultiDropdown',
                        sm: '12',
                        md: '12',
                        lg: '10'
                      },
                      edit: {
                        ui: 'MultiDropdown',
                        sm: '12',
                        md: '12',
                        lg: '10'
                      }
                    }
                  }
                ]
              }
            ]
          }
          // {
          //   title: '',
          //   sm: '12',
          //   md: '12',
          //   lg: '10',
          //   rows: [
          //     {
          //       spacing: 3,
          //       cols: [
          //         {
          //           type: 'rowHeading',
          //           value: 'notes',
          //           behavior: {
          //             new: {
          //               sm: '12',
          //               md: '12',
          //               lg: '12'
          //             },
          //             edit: {
          //               sm: '12',
          //               md: '12',
          //               lg: '12'
          //             }
          //           }
          //         },
          //         {
          //           type: 'dbArrayField',
          //           value: 'customerNotes',
          //           name: 'customerNotes',
          //           label: 'notes',
          //           allowAdd: true,
          //           addButtonName: 'Add note',
          //           specialBehaviour: {
          //             objectReferenceFieldName: 'note',
          //             numberOfLines: 3,
          //             showMore: false
          //           },
          //           behavior: {
          //             new: {
          //               ui: 'Notes',
          //               sm: '12',
          //               md: '12',
          //               lg: '10'
          //             },
          //             edit: {
          //               ui: 'Notes',
          //               sm: '12',
          //               md: '12',
          //               lg: '12'
          //             }
          //           }
          //         }
          //       ]
          //     }
          //   ]
          // }
        ],
        buttons: {
          save: {
            label: 'Save Changes',
            newLabel: 'Next',
            color: 'primary',
            buttonType: 'contained',
            caslKey: [PermissionConstants.OBJECT_CUSTOMER],
            behavior: {
              new: true,
              edit: true
            }
          },
          cancel: {
            label: 'Cancel',
            color: 'secondary',
            buttonType: 'contained',
            caslKey: [PermissionConstants.OBJECT_CUSTOMER],
            behavior: {
              new: true,
              edit: true
            }
          }
        }
      },
      search: null
    }
  }
};

const customerListRows = [
  {
    id: 'customerName',
    numeric: false,
    disablePadding: true,
    label: 'Name',
    showLink: 'view'
  },
  {
    id: 'customerTypeValue',
    numeric: false,
    disablePadding: true,
    label: 'Type'
  },
  {
    id: 'noOfProperties',
    numeric: false,
    disablePadding: true,
    label: 'No of properties'
  },
  {
    id: 'activityText',
    numeric: false,
    disablePadding: true,
    label: 'Activity'
  }
];

export const customerListtableProperties = {
  dataPathInQueryResult: '',
  idField: 'id',
  captionAttributes: customerListRows,
  noDataMsg: 'No customers'
};

export const customerRepRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'contactRole',
    numeric: false,
    disablePadding: true,
    label: 'Role'
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: 'Email'
  },
  {
    id: 'landlinePhone',
    numeric: false,
    disablePadding: true,
    label: 'Landline'
  },
  {
    id: 'cellPhone',
    numeric: false,
    disablePadding: true,
    label: 'Mobile phone'
  },
  {
    id: 'isSmsOptOut',
    numeric: false,
    type: 'boolean',
    disablePadding: true,
    label: 'Prefer SMS'
  },
  /* {
     Temporarily disabled, see https://buildops.atlassian.net/browse/BUOP-9307
    id: 'emailInvoiceLabel',
    numeric: false,
    disablePadding: true,
    label: 'Email Invoice Default' 
  }, */
  {
    id: 'bestContact',
    numeric: false,
    disablePadding: true,
    label: 'Best contact'
  },
  {
    id: 'notesCount',
    type: 'note',
    other: 'repNotes',
    numeric: false,
    disablePadding: true,
    label: 'Notes'
  }
];

export const tenantRepRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'contactRole',
    numeric: false,
    disablePadding: true,
    label: 'Title'
  },
  // {
  //   id: 'status',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'Status'
  // }
  {
    id: 'notesCount',
    type: 'note',
    other: 'repNotes',
    numeric: false,
    disablePadding: true,
    label: 'Notes'
  }
];

export const customerPropertyRows = [
  {
    id: 'companyName',
    numeric: false,
    disablePadding: true,
    label: 'Name',
    showLink: 'view',
    linkPath: '/property/view',
    linkReference: 'id',
    linkStateKey: 'recordSortKey',
    linkStateValue: 'customerPropertySortKey'
  },
  {
    id: 'customerPropertyTypeValue',
    numeric: false,
    disablePadding: true,
    label: 'Property type'
  },
  {
    id: 'propertyAddress',
    numeric: false,
    disablePadding: true,
    label: 'Property address'
  },
  {
    id: 'jobCompletedCount',
    numeric: false,
    disablePadding: true,
    label: 'Jobs completed'
  },
  {
    id: 'activityText',
    numeric: false,
    disablePadding: true,
    label: 'Activity'
  }
];

export const eventRows = [
  {
    id: 'eventType',
    numeric: false,
    disablePadding: true,
    label: 'Event type'
  },
  {
    id: 'jobNumber',
    numeric: false,
    disablePadding: true,
    label: 'Job number',
    type: 'jobLink'
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status'
  },
  {
    id: 'date',
    numeric: false,
    type: 'dateOnly',
    disablePadding: true,
    label: 'Date'
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: true,
    label: 'Description'
  },
  {
    id: 'propertyName',
    numeric: false,
    disablePadding: true,
    label: 'Property'
  },
  {
    id: 'assignedTo',
    numeric: false,
    disablePadding: true,
    label: 'Assigned to'
  }
];

export const jobRowMetaByCustomer = [
  {
    id: 'jobNumber',
    filterKey: 'Job.jobNumber',
    filterType: 'number',
    numeric: false,
    label: 'Job #',
    type: 'jobLink'
  },
  {
    id: 'noOfVisits',
    disableFilter: true,
    disableSort: true,
    filterKey: 'Job.departmentNames',
    filterType: 'string',
    numeric: false,
    label: 'Visits',
    other: 'visits'
  },
  {
    id: 'outstandingBalance',
    disableFilter: true,
    disableSort: true,
    numeric: false,
    type: 'currency',
    label: 'Outstanding Balance',
    caslKey: [PermissionConstants.DATA_VIEW_PRICE_DATA, PermissionConstants.DATA_VIEW_COST_DATA],
    caslAction: 'allow'
  },
  {
    id: 'overdueBalance',
    disableSort: true,
    disableFilter: true,
    numeric: false,
    type: 'currency',
    label: 'Overdue Balance',
    caslKey: [PermissionConstants.DATA_VIEW_PRICE_DATA, PermissionConstants.DATA_VIEW_COST_DATA],
    caslAction: 'allow'
  },
  {
    id: 'jobTypeName',
    filterKey: 'Job.jobTypeName',
    filterType: 'string',
    numeric: false,
    label: 'Job type'
  },
  {
    id: 'jobTags',
    filterKey: 'JobTag.tagName',
    filterType: 'string',
    type: 'chip',
    disableSort: true,
    label: 'Tags'
  },
  {
    id: 'customerName',
    filterKey: 'Job.customerName',
    filterType: 'string',
    numeric: false,
    label: 'Customer',
    showLink: 'view',
    linkPath: '/customer/view',
    linkReference: 'customerId',
    linkStateKey: 'recordSortKey',
    linkStateValue: 'customerSortKey'
  },
  {
    id: 'customerPropertyName',
    filterKey: 'Job.customerPropertyName',
    filterType: 'string',
    numeric: false,
    label: 'Property',
    showLink: 'view',
    linkPath: '/property/view',
    linkReference: 'customerPropertyId',
    linkStateKey: 'recordSortKey',
    linkStateValue: 'customerPropertySortKey'
  },
  {
    id: 'createdDate',
    filterKey: 'Job.createdDate',
    filterType: 'date',
    numeric: false,
    type: 'dateOnly',
    label: 'Created on'
  },
  {
    id: 'amountQuoted',
    filterKey: 'Job.amountQuoted',
    filterType: 'number',
    numeric: false,
    label: 'salesAmount',
    type: 'currency',
    caslKey: [
      PermissionConstants.DATA_VIEW_PRICE_DATA,
      PermissionConstants.DATA_VIEW_COST_DATA,
      PermissionConstants.DATA_SHOW_FINANCIAL_DATA
    ],
    caslAction: 'allow'
  },
  {
    id: 'age',
    disableFilter: true,
    disableSort: true,
    numeric: false,
    label: 'Age(Days)'
  },
  {
    id: 'status',
    filterKey: 'Job.status',
    filterType: 'string',
    type: 'text',
    numeric: false,
    label: 'Status'
  },
  {
    id: 'ownerValue',
    filterKey: 'Employee.name',
    filterType: 'string',
    type: 'text',
    numeric: false,
    label: 'Project Manager'
  }
];
