import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CustomerService } from 'services/core';
import ErrorBoundaries from 'scenes/Error';
import { Logger } from 'services/Logger';
import { SergeantModal } from 'components';
import sendEmailForm from 'meta/sendEmailForm';
import StorageService from 'services/StorageService';
import { constructContactEmailOptions, constructSelectOptions } from 'utils/constructSelectOptions';
import validateEmail from 'utils/validateEmail';
import { logErrorWithCallback } from 'utils';
import moment from 'moment';
import { uniqueIdentifier } from 'utils/constants.js';
import {
  AttachmentThumbnailsComponent,
  ExpirationDateComponent,
  CustomPDFComponent,
  AttachmentSizeWarningComponent
} from './CustomComponents';

const propTypes = {
  dataType: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  user: PropTypes.shape({}),
  data: PropTypes.shape({}),
  templates: PropTypes.array,
  snackbar: PropTypes.func.isRequired,
  maxAttachmentSize: PropTypes.number,
  maxAttachmentSizeWarningLabel: PropTypes.string,
  generatedPDFSizeRef: PropTypes.object
};

const defaultProps = {
  dataType: '',
  user: {},
  data: {},
  templates: [],
  maxAttachmentSize: null,
  maxAttachmentSizeWarningLabel: null,
  generatedPDFSizeRef: {}
};

const filterUniqueRecipients = reps => {
  // Remove any reps without email address
  const filterNonemptyEmails = reps.filter(cr => cr.email !== null && cr.email !== '');

  // Filter reps on ids
  const uniqueAllReps = Array.from(new Set(filterNonemptyEmails.map(rep => rep.id))).map(id => {
    return filterNonemptyEmails.find(rep => rep.id === id);
  });

  // Remove reps with same 'firstName', 'lastName' and 'email'
  const uniqueRecipients = uniqueAllReps.filter(
    (v, i, a) =>
      a.findIndex(v2 => ['email', 'firstName', 'lastName'].every(k => v2[k] === v[k])) === i
  );
  return uniqueRecipients;
};

const extractEmailsFromReps = (reps, billingType) => {
  const repsWithEmailInvoiceType = reps.filter(v => v.emailInvoice === billingType);
  return repsWithEmailInvoiceType.map(v => v.email);
};

const SendEmailPopUp = ({
  open,
  onModalClose,
  user,
  data,
  dataType,
  templates,
  snackbar,
  maxAttachmentSizeWarningLabel,
  maxAttachmentSize,
  generatedPDFSizeRef
}) => {
  const [recipients, setRecipients] = useState([]);
  const [defaultToRecipients, setDefaultToRecipients] = useState([]);
  const [defaultCCRecipients, setDefaultCCRecipients] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const [pdfKey, setPDFKey] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const daysUntilExpiration = data?.daysUntilExpiration || 30;
  const formattedAttachments = (data?.attachments?.items || [])
    .filter(item => !item.isInternalFile)
    .map(item => ({
      ...item,
      formattedFileName: item.customFileName || item.fileName
    }));

  const getImageUrl = async url => {
    let response;
    try {
      const storageService = new StorageService();
      response = await storageService.getFile(url);
    } catch (error) {
      Logger.info(`Error uploading image ${error}`);
    }
    return response;
  };

  const downloadFile = async formPdfKey => {
    if (!formPdfKey) {
      return;
    }
    try {
      const url = await getImageUrl(formPdfKey);
      const a = document.createElement('a');
      a.download = data.pdfFileName.replace('.pdf', '');
      a.href = url;
      a.target = '_blank';
      a.click();
    } catch (error) {
      Logger.info(`Error getting image ${error}`);
    }
  };

  useEffect(() => {
    async function generatePdfFn() {
      if (open && data?.shouldGeneratePDF) {
        setShowSpinner(true);
        try {
          const response = await data.uploadPDF();
          if (response) {
            setPDFKey(response);
            setShowSpinner(false);
          }
        } catch (error) {
          logErrorWithCallback(
            error,
            snackbar,
            'Error occurred while generating file. Please try again.'
          );
        }
      }
    }
    generatePdfFn();

    if (!open) {
      setSelectedAttachments([]);
    }
  }, [open]);

  useEffect(() => {
    const getCustomerReps = async (customer, billingCustomer, customerProperty) => {
      const customerService = new CustomerService();
      const { data: response } = await customerService.getCustomerReps(
        customer?.id || '',
        customerProperty?.id || '',
        billingCustomer?.id && customer?.id !== billingCustomer.id ? billingCustomer.id : ''
      );

      const customerReps = response?.getCustomerById?.customerReps?.items || [];
      const billingCustomerReps = response?.getBillingCustomerById?.customerReps?.items || [];
      const propertyRepsRaw = response?.getCustomerPropertyById?.customerReps?.items || [];
      const propertyReps = propertyRepsRaw.map(v => v.mappedEntity);
      const allReps = [...customerReps, ...billingCustomerReps, ...propertyReps];
      const filteredUniqueReicpients = filterUniqueRecipients(allReps);
      const contactEmailOptions = constructContactEmailOptions(filteredUniqueReicpients);
      setRecipients(contactEmailOptions);
      const defaultRecipientEmails =
        contactEmailOptions
          ?.filter(rep => rep.id === data?.customerRep?.id)
          .map(item => item.value) || [];

      setDefaultToRecipients(defaultRecipientEmails);
      // Temporarily disable email invoice functionality. See https://buildops.atlassian.net/browse/BUOP-9307
      // eslint-disable-next-line no-lone-blocks
      {
        /* setDefaultToRecipients(extractEmailsFromReps(allRecipients, EmailInvoiceType.EmailTo));
         setDefaultCCRecipients(extractEmailsFromReps(allRecipients, EmailInvoiceType.EmailCC)); */
      }
    };

    getCustomerReps(data.customer, data.billingCustomer, data.customerProperty);
  }, []);

  const handleSendEmail = async (
    {
      recipients: recipientsList,
      ccRecipients: ccRecipientList = [],
      bccRecipients: bccRecipientList = [],
      body,
      subject
    },
    modalCallback
  ) => {

    const strippedRecipientList = recipientsList.map(item =>
      item?.indexOf(uniqueIdentifier) > -1 ? item.slice(item.indexOf('-') + 1) : item
    );
    const strippedccRecipientList = ccRecipientList.map(ccItem =>
      ccItem?.indexOf(uniqueIdentifier) > -1 ? ccItem.slice(ccItem.indexOf('-') + 1) : ccItem
    );
    const strippedbccRecipientList = bccRecipientList.map(bccItem =>
      bccItem?.indexOf(uniqueIdentifier) > -1 ? bccItem.slice(bccItem.indexOf('-') + 1) : bccItem
    );

    const payload = {
      invoiceId: data.invoiceId,
      paymentId: data.paymentId,
      email: {
        fromName: user.displayName || undefined,
        from: 'noreply@buildops.com',
        replyTo: user.email,
        importance: 'High',
        body: body || null,
        sendTo: strippedRecipientList.join(';'),
        sendCc: strippedccRecipientList.join(';'),
        sendBcc: strippedbccRecipientList.join(';'),
        subject: subject || data.emailSubject,
        attachments: selectedAttachments.map(item => ({
          fileName: item.fileName,
          fileUrl: item.fileUrl,
          type: item.type,
          comment: item.comment
        }))
      }
    };

    if (data.logoUrl) {
      payload.email.attachments.push({
        fileName: 'logo',
        fileUrl: data.logoUrl.startsWith('https') ? data.logoUrl : await getImageUrl(data.logoUrl),
        type: 'image',
        comment: 'logo'
      });
    }

    if (pdfKey || data.uploadPdf) {
      const url = await getImageUrl(pdfKey || (await data.uploadPdf()));
      payload.email.attachments.push({
        fileName: data.pdfFileName,
        fileUrl: url,
        type: 'pdf',
        comment: 'main-document'
      });
    }

    if (!data.disableExpirationDate) {
      const expirationDate = moment(new Date()).add(daysUntilExpiration, 'days');
      payload.expirationDate = `${moment(expirationDate).unix()}`;
    }

    await data.sendEmailFn(payload);
    onModalClose();
    modalCallback();
    setSelectedAttachments([]);
  };

  const onCreate = selectedOption => {
    const { form, field, value } = selectedOption;
    const validEmail = validateEmail(value);
    if (!validEmail) return;
    setRecipients([...recipients, { label: value, value }]);
    const newFieldValue = Array.isArray(field.value) ? [...field.value, value] : [value];
    form.setFieldValue(field.name, newFieldValue);
  };

  const onSelectTemplate = (template, form) => {
    if (!template?.value) return;
    if (selectedTemplate === template.value) return;
    setSelectedTemplate(template.value);

    const templateWithMessage = templates.find(t => t.id === template.value);
    form.setFieldValue('body', templateWithMessage.message);
  };

  const onSelectAttachment = async selections => {
    const formattedSelections = await Promise.all(
      selections.map(async selectedItem => {
        const attachment = data.attachments.items.find(item => item.id === selectedItem.value);
        if (attachment) {
          const fileUrl = await getImageUrl(attachment.fileUrl);
          return {
            fileName: attachment.customFileName || attachment.fileName,
            fileUrl,
            type: attachment.type,
            imageKey: attachment.fileUrl,
            fileSize: attachment.fileSize,
            comment: 'attachment'
          };
        }
      })
    );

    setSelectedAttachments(formattedSelections);
  };

  return (
    <ErrorBoundaries>
      <SergeantModal
        customComponents={{
          CustomPDFComponent: formData => CustomPDFComponent({ formData, downloadFile, data }),
          ExpirationDateComponent: formData =>
            ExpirationDateComponent({
              formData,
              daysUntilExpiration
            }),
          AttachmentThumbnailsComponent: formData =>
            AttachmentThumbnailsComponent({ formData, downloadFile }),
          AttachmentSizeWarning: formData =>
            AttachmentSizeWarningComponent({
              formData,
              maxAttachmentSizeWarningLabel,
              maxAttachmentSize,
              generatedPDFSize: generatedPDFSizeRef.current
            })
        }}
        data={{
          recipients: defaultToRecipients,
          ccRecipients: defaultCCRecipients,
          subject: data.emailSubject
        }}
        open={open}
        layout={sendEmailForm({
          attachments: formattedAttachments
            ? constructSelectOptions(formattedAttachments, 'formattedFileName')
            : null,
          templates: constructSelectOptions(templates),
          recipients,
          selectedTemplate,
          onCreate,
          onSelectTemplate,
          onSelectAttachment,
          daysUntilExpiration,
          pdfKey,
          showSpinner,
          disableAttachments: data?.disableAttachments,
          disableExpirationDate: data?.disableExpirationDate,
          disableViewPdf: data?.disableViewPdf,
          allowEditEmailSubject: data?.allowEditEmailSubject,
          selectedAttachments
        })}
        mode=""
        customPrimaryButtonLabel="Send"
        dataType={dataType || 'Send invoice'}
        handlePrimaryAction={handleSendEmail}
        handleClose={onModalClose}
        disablePrimaryButton={showSpinner}
      />
    </ErrorBoundaries>
  );
};

SendEmailPopUp.propTypes = propTypes;
SendEmailPopUp.defaultProps = defaultProps;

const mapStateToProps = state => ({
  user: state.user
});

const connectedAllNotes = connect(mapStateToProps, null)(SendEmailPopUp);

export default connectedAllNotes;
