/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';
import { sentryException } from '../Logger/index';

export async function getProjectsByUser(id, where = {}) {
  const params = { include: ['gcProjectManager', 'projectManager', 'ProjectSubtype'] };
  if (Object.keys(where).length !== 0) {
    params.where = where;
  }
  return await instance
    .get(`projects/user/${id}`, { params })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get Projects');
      return [];
    });
}

export async function getProjects(where = {}) {
  const params = { include: ['gcProjectManager', 'projectManager', 'ProjectSubtype'] };
  if (Object.keys(where).length !== 0) {
    params.where = where;
  }
  return await instance
    .get('Projects', { params })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get Projects');
      return [];
    });
}

export async function getProjectFinance(id) {
  return await instance
    .get(`projects/calculatefinance/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get Projects');
      return [];
    });
}

export async function getProjectKPIValues(id) {
  return await instance
    .get(`projects/getkpi/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get Project KPIs');
      return [];
    });
}

export async function getProjectById(id) {
  return await instance
    .get(`projects/${id}`, { params: { include: '*' } })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get Projects');
      return [];
    });
}

export async function projectSearch(term, searchColumns) {
  if (term.length > 0) {
    return await instance
      .post(`Projects/search/${term}`, { searchColumns: searchColumns || ['number'] })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        sentryException(err, 'Unable to get Projects');
        return [];
      });
  }
  return await getProjects();
}

export async function projectCreate(project) {
  return await instance
    .post('Projects', project)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to create Projects');
      return [];
    });
}

export async function projectChange(id, project) {
  return await instance
    .put(`Projects/${id}`, project)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to change Projects');
      return [];
    });
}

export async function getNextPhaseNumber(project) {
  return await instance
    .get('Projects/nextnumber', project)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get Phase Number');
      return [];
    });
}
