import gql from 'graphql-tag';

const addDepartmentsToCompany = gql`
  mutation addDepartmentsToCompany($partitionKey: String!, $data: AddDepartmentsToCompanyInput!) {
    addDepartmentsToCompany(partitionKey: $partitionKey, data: $data) {
      id
      version
      companyAddresses(entityConnection: "CompanyAddress") {
        items {
          id
          version
          addressType
        }
      }
    }
  }
`;

export default addDepartmentsToCompany;
