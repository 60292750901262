import gql from 'graphql-tag';

const deleteLabourRateLineItem = gql`
  mutation deleteLabourRateLineItem($partitionKey: String!, $id: String!) {
    deleteLabourRateLineItem(partitionKey: $partitionKey, id: $id) {
      id
    }
  }
`;

export default deleteLabourRateLineItem;
