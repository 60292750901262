import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { DefaultButton } from 'components';
import { getTenantSettingValueForKey } from 'utils';
import Item from './Item';

const MaintenanceTemplates = ({
  handleAddTemplate,
  handleEditTemplate,
  handleDeleteTemplate,
  data,
  shouldShowAddButton,
  isReadOnly,
  companyTimeZone
}) => {
  const isAssetEnabled = getTenantSettingValueForKey('assetTrackingAgainstTask') === 'true';

  const renderTemplateItem = item => (
    <Item
      data={item}
      handleEditTemplate={handleEditTemplate}
      handleDeleteTemplate={handleDeleteTemplate}
      isAssetEnabled={isAssetEnabled}
      isReadOnly={isReadOnly}
      companyTimeZone={companyTimeZone}
    />
  );

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4">Maintenance Templates</Typography>
        {shouldShowAddButton && (
          <DefaultButton
            label="Add maintenance template"
            onClick={handleAddTemplate}
            color="secondary"
          />
        )}
      </Box>
      <Box mt={2} display="flex" flexDirection="column">
        {data.map(renderTemplateItem)}
      </Box>
    </Box>
  );
};

export default MaintenanceTemplates;
