import React, { useEffect, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import SergeantModal from 'components/SergeantModal';
import PurchaseOrderLayout from 'meta/Jobs/Invoice/PurchaseOrdersFormSergeant';

const PurchaseOrderModal = ({ mode, onAction, onClose, open, purchaseOrder, departmentName }) => {
  const [showFileName, setShowFileName] = useState(false);

  const layout = PurchaseOrderLayout(departmentName, showFileName);

  const onFieldChange = (key, value) => {
    if (key === 'newFiles' && value) {
      setShowFileName(true);
    }
  };

  const handlePrimaryAction = async (newData, stopLoading) => {
    await onAction({ ...newData, customFileName: newData?.customFileName || '' });
    stopLoading();
  };

  const handleClose = () => {
    setShowFileName(false);
    onClose();
  };

  useEffect(() => {
    if (!open) {
      setShowFileName(false);
    }
  }, [open]);

  return (
    <SergeantModal
      open={open}
      mode={mode}
      dataType="receipt"
      layout={layout}
      data={purchaseOrder}
      handlePrimaryAction={handlePrimaryAction}
      handleClose={handleClose}
      onFieldChange={onFieldChange}
    />
  );
};

PurchaseOrderModal.propTypes = {
  purchaseOrder: object.isRequired,
  departmentName: string.isRequired,
  open: bool.isRequired,
  mode: string.isRequired,
  onClose: func.isRequired,
  onAction: func.isRequired
};

export default PurchaseOrderModal;
