const transformCreateCustomerSchema = (data, tenantId, tenantCompanyId, pageState) => {
  const { customerTypes, syncNow, formAttributes, customerTags } = pageState;
  let selectedCustomerType;
  if (customerTypes && customerTypes.items && customerTypes.items.length > 0) {
    selectedCustomerType = customerTypes.items.filter(
      item => item.tagName === data.customerTypeValue
    );
  }

  const customFields = {};
  if (data && formAttributes) {
    Object.keys(data).forEach(dataItem => {
      if (dataItem.startsWith('Custom_')) {
        customFields[dataItem.replace('Custom_', '')] = data[dataItem] || undefined;
      }
    });
    customFields.formDefinitionSortKey = formAttributes.formDefinitionSortKey;
    customFields.formSortKey = formAttributes.formSortKey;
    customFields.formId = formAttributes.formId;
    customFields.formDefinitionId = formAttributes.formDefinitionId;
  }

  const payload = {
    companyId: tenantCompanyId,
    customers: [
      {
        priceBookId: data.priceBookId || null,
        invoicePresetId: data.invoicePresetId || null,
        customerName: data.customerName || undefined,
        customerNumber: `${data.customerNumber}` || undefined,
        accountNumber: data.accountNumber || undefined,
        customerTypeSortKey:
          (selectedCustomerType &&
            selectedCustomerType.length > 0 &&
            selectedCustomerType[0].sortKey) ||
          null,
        customerTypeValue: data.customerTypeValue || undefined,
        email: data.email || undefined,
        isActive: true,
        phonePrimary: data.phonePrimary || undefined,
        receiveSMS: data.receiveSMS || false,
        status: 'active',
        sameAddress: data.sameAddress === 'true' || data.sameAddress === true || false,
        syncStatus: 'InSync',
        isTaxable: data.isTaxable === 'true' || data.isTaxable === true || false,
        taxRateId: data.taxRateId || undefined,
        taxExemptIdValue: data.taxExemptIdValue,
        paymentTermId: data.paymentTermId || null,
        companyAddresses: [
          data?.billingAddressLine1 && {
            billTo: data.billingBillTo || undefined,
            addressLine1: data.billingAddressLine1,
            addressLine2: data.billingAddressLine2 || undefined,
            city: data.billingCity || undefined,
            state: data.billingState || undefined,
            zipcode: data.billingZipcode || undefined,
            latitude: data.billingLatitude || undefined,
            longitude: data.billingLongitude || undefined,
            status: 'active',
            addressType: 'billingAddress',
            entityType: 'CompanyAddress',
            tenantId
          },
          data.businessAddressLine1 && {
            shipTo: data.businessShipTo || undefined,
            addressLine1: data.businessAddressLine1,
            addressLine2: data.businessAddressLine2 || undefined,
            city: data.businessCity || undefined,
            state: data.businessState || undefined,
            zipcode: data.businessZipcode || undefined,
            latitude: data.businessLatitude || undefined,
            longitude: data.businessLongitude || undefined,
            status: 'active',
            addressType: 'businessAddress',
            entityType: 'CompanyAddress',
            tenantId
          }
        ].filter(Boolean)
      }
    ]
  };

  if (data.processedCustomerTags) {
    const selectedTags = ((customerTags || {}).items || []).filter(tag =>
      data.processedCustomerTags.includes(tag.id)
    );
    selectedTags.map(tag => {
      const localRecord = tag;
      if (tag.__typename) {
        delete localRecord.__typename;
      }
      return localRecord;
    });
    payload.customers[0].customerTags = selectedTags;
  }

  if (syncNow) {
    payload.customers[0].syncStatus = 'Syncing';
  } else {
    payload.customers[0].syncStatus = null;
  }
  if (data && formAttributes) {
    payload.customers[0].formData = customFields;
  }

  if (data.defaultProperty) {
    const property = {
      companyName: data.customerName || undefined,
      status: 'active',
      email: data.email || undefined,
      isActive: true,
      isTaxable: data.isTaxable === 'true' || data.isTaxable === true || false,
      taxRateId: data.taxRateId || undefined,
      phonePrimary: data.phonePrimary || undefined,
      companyAddresses: data.billingAddressLine1
        ? [
            {
              addressLine1: data.billingAddressLine1,
              addressLine2: data.billingAddressLine2 || undefined,
              city: data.billingCity || undefined,
              state: data.billingState || undefined,
              zipcode: data.billingZipcode || undefined,
              latitude: data.billingLatitude || undefined,
              longitude: data.billingLongitude || undefined,
              status: 'active',
              addressType: 'propertyAddress',
              entityType: 'CompanyAddress',
              tenantId
            },
            {
              addressLine1: data.billingAddressLine1,
              addressLine2: data.billingAddressLine2 || undefined,
              city: data.billingCity || undefined,
              state: data.billingState || undefined,
              zipcode: data.billingZipcode || undefined,
              latitude: data.billingLatitude || undefined,
              longitude: data.billingLongitude || undefined,
              billTo: data.billingBillTo || undefined,
              status: 'active',
              addressType: 'billingAddress',
              entityType: 'CompanyAddress',
              tenantId
            }
          ]
        : []
    };
    if (syncNow) {
      property.syncStatus = 'Syncing';
    } else {
      property.syncStatus = null;
    }

    payload.customers[0].customerProperties = [property];
  }

  return payload;
};

export default transformCreateCustomerSchema;
