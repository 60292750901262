export const packageDetailSidebarForm = [
  {
    source: 'subject',
    label: 'Subject'
  },
  {
    source: 'status',
    label: 'Status',
    component: 'sidebarHighlightedItem'
  },
  {
    source: 'specDescription',
    label: 'Notes'
  },
  {
    source: 'createdDate',
    label: 'Date Created'
  },
  {
    source: 'dueDate',
    label: 'Date Due'
  },
  {
    source: 'items',
    label: 'Items'
  },
  {
    source: 'daysOverdue',
    label: 'Days Overdue'
  }
];
