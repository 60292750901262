import { EnumType } from 'utils/constants';

const purchaseOrdersTableMeta = [
  {
    id: 'poNumber',
    label: 'PO Number',
    showLink: 'view',
    linkPath: '/procurement/purchaseorders/view',
    linkReference: 'id',
    type: 'string',
    filterType: 'string',
    filterKey: 'poNumber'
  },
  {
    id: 'status',
    label: 'Status',
    enumType: EnumType.PROCUMENT_PURCHASE_ORDER_STATUS,
    type: 'enum',
    filterType: 'string',
    filterKey: 'status'
  },
  {
    id: 'vendorName',
    label: 'Vendor',
    filterType: 'string',
    filterKey: 'vendorName',
    type: 'string'
  },
  {
    id: 'description',
    label: 'Description',
    filterType: 'string',
    filterKey: 'description',
    type: 'string'
  },
  {
    id: 'departmentName',
    label: 'Department',
    filterType: 'string',
    filterKey: 'departmentName',
    type: 'string'
  },
  {
    id: 'totalCost',
    label: 'Total',
    type: 'currency',
    filterType: 'currency',
    filterKey: 'totalCost',
    numeric: true
  },
  {
    id: 'requiredByDate',
    label: 'Required By',
    filterType: 'string',
    filterKey: 'requiredByDate',
    type: 'date'
  },
  {
    id: 'receipts',
    label: 'Receipt(s)',
    filterType: 'string',
    filterKey: 'requiredByDate',
    type: 'string'
  },
  {
    id: 'bills',
    label: 'Bill(s)',
    filterType: 'string',
    filterKey: 'requiredByDate',
    type: 'string'
  }
];

export default purchaseOrdersTableMeta;
