import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    fontWeight: 'normal',
    fontSize: '16px',
    width: 90,
    padding: theme.spacing(0.7),
    background: theme.palette.grayscale(98),
    margin: `0`,
    '&:hover': {
      background: theme.palette.grayscale(92)
    },
    '& input': {
      padding: 0,
      textAlign: 'right'
    }
  },
  icon: {
    color: theme.palette.grayscale(90)
  }
}));
