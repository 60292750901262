import { isEmpty } from 'lodash';

const contents = [
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 0,
      padding: [0, 6]
    },
    contents: [
      {
        component: 'BodyText',
        source: 'This type will be added to the Payroll Hour types. '
      }
    ]
  },
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 0,
      padding: [0, 6]
    },
    contents: [
      {
        component: 'BodyText',
        source: 'Example: “Regular”, “REG”'
      }
    ]
  },
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 0
    },
    contents: [
      {
        options: {
          direction: 'column',
          grow: 1,
          padding: 12
        },
        contents: [
          {
            component: {
              edit: 'TextInput',
              default: 'FieldWithLabel'
            },
            source: 'hourType',
            options: {
              label: '',
              placeholder: 'REGULAR',
              default_value: '',
              smartField: '',
              isRequired: true,
              fullWidth: true,
              alignment: 'left'
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 0
    },
    contents: [
      {
        options: {
          direction: 'column',
          grow: 1,
          padding: 12
        },
        contents: [
          {
            component: {
              edit: 'TextInput',
              default: 'FieldWithLabel'
            },
            source: 'hourTypeAbbreviation',
            options: {
              toolLabel: 'Text',
              toolIcon: 'TextFields',
              label: '',
              placeholder: 'REG',
              default_value: '',
              smartField: '',
              isRequired: true,
              fullWidth: true,
              alignment: 'left'
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 0
    },
    contents: [
      {
        options: {
          direction: 'column',
          grow: 1,
          padding: 12
        },
        contents: [
          {
            component: {
              edit: 'CheckboxInput',
              default: 'CheckboxInput'
            },
            source: 'isAutofill',
            options: {
              label: 'Autofill Time',
              displayAsSwitch: true
            }
          }
        ]
      }
    ]
  }
];

const accountNumber = {
  options: {
    size: 1,
    direction: 'column',
    grow: 0
  },
  contents: [
    {
      options: {
        direction: 'column',
        grow: 1,
        padding: 12
      },
      contents: [
        {
          component: {
            edit: 'TextInput',
            default: 'TextInput'
          },
          source: 'intacctAccountNumber',
          options: {
            label: 'Account Number (Billable Time)'
          }
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 1,
        padding: 12
      },
      contents: [
        {
          component: {
            edit: 'TextInput',
            default: 'TextInput'
          },
          source: 'intacctNonBillableAccountNumber',
          options: {
            label: 'Account Number (Non-Billable Time)'
          }
        }
      ]
    }
  ]
};

const billingType = billingHourTypes => ({
  options: {
    size: 1,
    direction: 'column',
    grow: 0
  },
  contents: [
    {
      options: {
        direction: 'column',
        grow: 1,
        padding: 12
      },
      contents: [
        {
          component: 'TooltipLabel',
          options: {
            label: 'Related Billing Hour Type',
            message:
              'Automatically fills selected billing hour type with mapped payroll hour type on labor line items in review reports.'
          }
        },
        {
          component: {
            edit: 'SelectInput',
            default: 'FieldWithLabel'
          },
          source: 'billingHourTypeId',
          options: {
            valueSet: billingHourTypes,
            isSearchable: true,
            enableSort: false,
            isClearable: false
          }
        }
      ]
    }
  ]
});

const getContents = ({ settings, options }) => {
  return [
    ...contents,
    settings.showAccountNumberOnPayrollHourType && accountNumber,
    settings.mapPayrollHourToBilling &&
      !isEmpty(options?.billingHourTypes) &&
      billingType(options.billingHourTypes)
  ].filter(Boolean);
};

export const payrollHourType = config => {
  return {
    fields: {},
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: getContents(config)
      }
    }
  };
};

export default payrollHourType;
