import gql from 'graphql-tag';

const getScheduledInThePastWithCache = gql`
  query getScheduledInThePastWithCache(
    $partitionKey: String!
    $sortKey: String!
    $startTime: Int!
    $endTime: Int!
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      visitsInRange(
        entityConnection: "Visit"
        rangeFilter: { startTime: $startTime, endTime: $endTime }
        filter: { status: { eq: "Scheduled" } }
      ) {
        items {
          id
          sortKey
          version
        }
      }
    }
  }
`;

export default getScheduledInThePastWithCache;
