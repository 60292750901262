import { row } from 'utils/layout';

const margin = '0px 15px 18px 0px';

const addAttachmentsModalLayout = ({
  onSelectImages,
  onSelectFiles,
  onAddFiles,
  onOpenFilesModal
}) => ({
  fields: {
    customFileName: {
      default: 'customFileName',
      edit: 'customFileName'
    },
    newFiles: {
      default: 'newFiles',
      edit: 'newFiles'
    },
    comment: {
      default: 'comment',
      edit: 'comment'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0,
        maxWidth: 811,
        minWidth: 453
      },
      contents: [
        row([
          {
            component: 'FilesList',
            options: {
              onSelectFiles
            }
          }
        ]),
        row([
          {
            component: 'ImagesCarousel',
            options: {
              onSelectImages
            }
          }
        ]),
        row(
          [
            {
              component: 'AddAttachmentsFromFilesButton',
              options: {
                onClick: onOpenFilesModal
              }
            }
          ],
          {
            margin
          }
        ),
        row([
          {
            component: 'SectionTitle'
          }
        ]),
        row([
          {
            component: 'AddAttachmentsButton',
            options: {
              onChange: onAddFiles
            }
          }
        ])
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      customFileName: {
        type: 'string'
      },
      comment: {
        type: 'string'
      },
      newFiles: {
        type: 'array'
      }
    },
    required: ['newFiles']
  },
  validationErrors: {
    newFiles: {
      required: 'File is required.'
    }
  }
});

export default addAttachmentsModalLayout;
