const ICON_SIZE = 40;
const styles = theme => ({
  avatar: {
    marginLeft: 10,
    marginRight: 10
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60
  },
  profileIconWrapper: {
    width: ICON_SIZE,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: ICON_SIZE,
    borderRadius: ICON_SIZE / 2,
    backgroundColor: theme.palette?.other?.darkBlue || theme.palette.grayscale(50),
    lineHeight: `${ICON_SIZE}px`
  },
  profileIcon: {
    color: theme.palette.grayscale(94),
    fontSize: ICON_SIZE / 2
  },
  profileIconLetters: {
    color: theme.palette.primary.contrastText,
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: 500,
    letterSpacing: '-0.06px'
  }
});
export default styles;
