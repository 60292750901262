import { ROW_HEIGHT } from '../WrapTable.constants';

export const useRowStyles = ({ isGroupedRow, isLastRow, groupExpanded } = {}) => {
  return {
    row: theme => ({
      display: 'flex',
      flexDirection: 'row',
      minHeight: ROW_HEIGHT,
      backgroundColor: isGroupedRow ? theme.palette.grayscale(96) : 'white',
      borderBottom:
        (isGroupedRow && isLastRow) || groupExpanded
          ? `solid 1px ${theme.palette.grayscale(80)}`
          : undefined
    }),
    groupHeader: theme => ({
      backgroundColor: theme.palette.grayscale(92),
      padding: '1px 4px 2px 8px',
      borderLeft: `1px solid ${theme.palette.grayscale(80)}`,
      borderRight: `1px solid ${theme.palette.grayscale(80)}`,
      borderBottom: `1px solid ${theme.palette.grayscale(80)}`
    }),
    totalsRow: {
      display: 'flex',
      flexDirection: 'row',
      minHeight: ROW_HEIGHT,
      marginTop: 'auto',
      position: 'sticky',
      bottom: 0,
      '& >div': {
        backgroundColor: 'white',
        borderTop: 'solid 1px black'
      }
    }
  };
};
