import isUndefined from 'lodash/isUndefined';

import { withQueryStringStore } from '@dispatch/Dispatch.store';

import MapSettings from './MapSettings.component';

const mapQueryStringToProps = ({ filterBy, state: { showVisits, showTechs, showLocations } }) => ({
  filterBy,
  showVisits: isUndefined(showVisits) ? true : showVisits,
  showTechs: isUndefined(showTechs) ? true : showTechs,
  showLocations: isUndefined(showLocations) ? true : showLocations
});

export default withQueryStringStore(mapQueryStringToProps)(MapSettings);
