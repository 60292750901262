export const getHitName = (
  hit,
  displayText,
  delimiter,
  showEntityNameInResults,
  customHitFormatter
) => {
  const entityRename = {
    CustomerProperty: 'Property'
  };
  let resultText = '';
  if (customHitFormatter) {
    resultText = customHitFormatter(hit);
  }
  if (displayText && Array.isArray(displayText) && !customHitFormatter) {
    displayText.forEach(text => {
      let key = text;
      if (key === 'jobNumber' && hit.customIdentifier) key = 'customIdentifier';
      if (hit[key] && key !== 'address') {
        resultText = resultText ? `${resultText}${delimiter}${hit[key]}` : hit[key];
      }
    });
  } else if (displayText && typeof displayText === 'string' && !customHitFormatter) {
    resultText = hit[displayText];
  }
  const entityName = entityRename[hit.entityType] || hit.entityType || '';
  return `${showEntityNameInResults ? `${entityName}: ` : ''}${resultText}`;
};
