const styles = theme => ({
  container: {
    overflow: 'auto',
    '& div:first-child': {
      height: 'auto !important'
    }
  },
  removeIcon: {
    color: theme.palette.support.red.dark
  },
  loseText: {
    color: theme.palette.support.red.dark
  },
  warningIcon: {
    color: theme.palette.other.gold,
    marginRight: '.5rem'
  }
});

export default styles;
