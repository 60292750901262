import gql from 'graphql-tag';

const getWorkflowStepsForCompany = gql`
  query($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      companyName
      workflows(entityConnection: "Workflow") {
        items {
          id
          workflowName
          workflowCopy
          workflowSteps(entityConnection: "WorkflowStep") {
            items {
              id
              workflowStepName
              workflowStepCopy
              isRequired
              version
              workflowSubSteps {
                key
                label
                enabled
              }
            }
          }
        }
      }
    }
  }
`;

export default getWorkflowStepsForCompany;
