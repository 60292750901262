import React from 'react';
import { DragSource } from 'react-dnd';
// import TitleIcon from '@material-ui/icons/Title';
import * as Icons from '@material-ui/icons/';
import ItemTypes from '../ItemTypes';

const Tool = ({ tool, isDragging, connectDragSource, classes }) => {
  const { toolLabel, toolIcon } = tool;
  const opacity = isDragging ? 0.4 : 1;
  const ToolIcon = Icons[toolIcon];
  return (
    <div className={classes.tool} ref={connectDragSource} style={{ opacity }}>
      <div className={classes.toolContent}>
        <div className={classes.toolContentWrapper}>
          <ToolIcon className={classes.toolContentIcon} />
          <p className={classes.toolContentLabel}>{toolLabel}</p>
        </div>
      </div>
    </div>
  );
};

export default DragSource(
  ItemTypes.ITEM,
  {
    beginDrag: props => {
      props.updateDragging({ dragging: true, item: 'tool' });
      return { item: props.tool };
    },
    endDrag(props, monitor) {
      const item = monitor.getItem();
      const dropResult = monitor.getDropResult();
      props.updateDragging({ dragging: false, item: null });
      if (dropResult) {
        props.handleDrop(item, dropResult.row, dropResult.column);
      }
    }
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  })
)(Tool);
