import gql from 'graphql-tag';

const getCompanyRoles = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      version
      appRoles(entityConnection: "AppRole") {
        nextToken
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          tagName
          tagType
          entityType
          appPermissions
          contacts(entityConnection: "Employee") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              invertedSortKey
              hierarchy
              entityType
              mappedEntityValue
              mappedEntityType
              mappedEntityId
              mappedEntity {
                ... on Employee {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  name
                  firstName
                  lastName
                  nickName
                  profilePictureUrl
                }
              }
              systemEntityValue
              systemEntityType
              systemEntityId
            }
          }
        }
      }
    }
  }
`;

export default getCompanyRoles;
