import React from 'react';
import { ThemeProvider, Button, ButtonType, ButtonSize } from '@buildhero/sergeant';
import { makeStyles } from '@material-ui/core/styles';
import ServiceChannelIcon from '../../assets/Icons/serviceChannel';
import OpenExternal from '../../assets/Icons/OpenExternal';
import { getServiceChannelExternalLink } from '../../scenes/ServiceChannel/utils';
import { styles as stylesFn } from './styles';

const ServiceChannelButton = ({ serviceChannelWorkOrderNumber, ...props }) => {
  const useStyles = makeStyles(stylesFn);
  const classes = useStyles(props);

  const buttonLabel = `Service Channel - ${serviceChannelWorkOrderNumber}`;

  return (
    <ThemeProvider>
      <Button
        type={ButtonType.LEADING}
        size={ButtonSize.SMALL}
        className={classes.button}
        startIcon={<ServiceChannelIcon />}
        endIcon={<OpenExternal className={classes.endIcon} />}
        target="_blank"
        component="a"
        href={getServiceChannelExternalLink(serviceChannelWorkOrderNumber)}
      >
        {buttonLabel}
      </Button>
    </ThemeProvider>
  );
};

export default ServiceChannelButton;
