const equipmentCost = 'equipmentCost';
const laborDollars = 'laborDollars';
const otherCost = 'otherCost';
const overheadCost = 'overheadCost';
const subcontractorCost = 'subcontractorCost';

const costTypeMap = {
  equipmentCost: {
    label: 'Equipment',
    value: 'equipment'
  },
  laborDollars: {
    label: 'Labor',
    value: 'labor'
  },
  materialCost: {
    label: 'Materials',
    value: 'materials'
  },
  otherCost: {
    label: 'Other',
    value: 'other'
  },
  overheadCost: {
    label: 'Overhead',
    value: 'overhead'
  },
  subcontractorCost: {
    label: 'Subcontractor',
    value: 'subcontractor'
  }
};

const costTypeOptions = [equipmentCost, laborDollars, otherCost, overheadCost, subcontractorCost];

const costTypeOptionsForDropdown = projectCostCode =>
  costTypeOptions.reduce(
    (acc, option) => {
      if (projectCostCode?.[option] > 0) {
        return [...acc, costTypeMap[option]];
      }
      return acc;
    },
    // Materials are always part of the dropdown
    [costTypeMap.materialCost]
  );

export default costTypeOptionsForDropdown;
