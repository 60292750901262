export const configuration = {
  fields: {},
  layouts: {
    default: {
      options: {
        direction: 'column',
        grow: 0
      },
      contents: [
        {
          component: {
            default: 'Input'
          },
          source: 'agreementName',
          props: {
            label: 'Agreement Name',
            placeholder: 'Enter Agreement Name',
            required: 'true',
            style: {
              paddingBottom: 24,
              width: 406
            }
          }
        },
        {
          component: {
            default: 'Input'
          },
          source: 'agreementNumber',
          props: {
            label: 'Agreement Number',
            placeholder: 'Enter Agreement Number',
            required: 'true',
            style: {
              paddingBottom: 24
            }
          }
        },

        {
          component: {
            default: 'CustomerSearch'
          },
          source: 'customer.customerName',
          props: {
            label: 'Customer',
            required: 'true'
          }
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      agreementName: { type: 'string', required: true },
      agreementNumber: { type: 'string', required: true },
      customer: { type: 'string', required: true }
    }
  },
  validationErrors: {
    agreementName: { required: 'Agreement name is required.' },
    agreementNumber: { required: 'Agreement number is required.' },
    customer: { required: 'Customer is required.' }
  }
};
