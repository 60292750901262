import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { ListService } from 'services/core';
import { Logger } from 'services/Logger';
import ResponsiveTable from 'components/ResponsiveTable';
import { PageHeader, Divider } from 'components';
import Labels from 'meta/labels';
import { formRowMeta } from 'meta/ListViews';
import ErrorBoundaries from 'scenes/Error';
import { snackbarOn } from 'redux/actions/globalActions';

import styles from './styles';

class FormView extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.ListService = new ListService();
    this.state = {
      serviceFn: this.getAllForms
    };
  }

  componentDidMount = async () => {
    document.title = 'BuildOps - Form List';
  };

  getAllForms = async (filter, limit, offset, sortBy, sortOrder) => {
    if (!this.props.user.tenantId) {
      return { items: [], nextToken: '0' };
    }
    let data;
    const sortKey = `${this.props.user.tenantId}_Company_${this.props.user.tenantCompanyId}`;
    try {
      data = await this.ListService.getAllForms(
        this.props.user.tenantId,
        sortKey,
        filter,
        limit,
        offset,
        sortBy,
        sortOrder
      );

      return data;
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to fetch Forms, please try again later', error);
    }
    return data || [];
  };

  render() {
    const { user } = this.props;
    const { serviceFn } = this.state;
    return (
      <ErrorBoundaries>
        <PageHeader title={Labels.Forms[user.locale]} />
        <Divider />
        <ResponsiveTable
          rowMetadata={formRowMeta}
          useServerQueries
          service={serviceFn}
          noDataMsg="No Forms"
        />
      </ErrorBoundaries>
    );
  }
}

const styledFormView = withStyles(styles, { withTheme: true })(FormView);

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const reduxConnectedForm = connect(mapStateToProps, mapDispatcherToProps)(styledFormView);

export default reduxConnectedForm;
