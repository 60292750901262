export const useStyles = () => ({
  container: theme => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.default.spacing(1)
  }),
  icon: theme => ({
    color: theme.palette.grayscale(20),
    fontSize: 20
  }),
  text: theme => ({
    color: theme.palette.grayscale(20),
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '-0.02em',
    textDecoration: 'none',
    marginLeft: theme.default.spacing(0.5)
  })
});
