import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { MUIForm } from '@buildhero/sergeant';
import { makeStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AlgoliaSearch from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import DefaultButton from 'components/Buttons/DefaultButton';
import FullScreenModal from 'components/FullScreenModal';
import ManulStatusChangeModal from 'components/ManualStatusChangeModal/index';
import Labels from 'meta/labels';
import {
  fieldOrderGeneralInformationFields,
  fieldOrderGeneralInformationLayout
} from 'meta/Procurement/PurchaseOrders/fieldOrderGeneralInformationForm';
import { snackbarOn } from 'redux/actions/globalActions';
import { getProcurementStatus } from 'scenes/JobCloseout/utils';
import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';
import buildHeroMuiFormOverrides from 'scenes/ProjectManagement/components/buildHeroMuiFormOverrides';
import CustomFieldWithLabel from 'scenes/ProjectManagement/components/CustomFieldWithLabel';
import { generateDefaultValidationSchema } from 'scenes/ProjectManagement/components/formattingUtils';
import { purchaseOrderCreate } from 'services/API/purchaseOrder';
import { getPurchaseOrderTags } from 'services/API/purchaseOrderTag';
import { JobService } from 'services/core';
import { EnumType } from 'utils/constants';
import { constructSelectOptions } from 'utils/constructSelectOptions';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import MuiFormSectionTitle from '../../component/MuiFormSectionTitle';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 40,
    maxWidth: 700,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  formContainer: buildHeroMuiFormOverrides(theme)
}));

const CustomFieldWithLabelStyled = ({ field, options }) => {
  return (
    <CustomFieldWithLabel
      field={field}
      options={options}
      style={{ background: '#F0F0F0', color: '#999999', padding: '1px 8px', height: 41 }}
    />
  );
};

const CreateFieldOrder = props => {
  const { open, handleClose, user, initialData, jobData } = props;
  const classes = useStyles();
  const flags = useFlags();
  const history = useHistory();
  const [formService, setFormService] = useState(null);
  const [department, setDepartment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openProcurementStatusModal, setOpenProcurementStatusModal] = useState(false);
  const [procurementStatusModalData, setProcurementStatusModalData] = useState(null);
  const [updatedJobData, setUpdatedJobData] = useState(null);
  const [jobProcStatusToUpdate, setJobProcStatusToUpdate] = useState(null);
  const [tagOptions, setTagOptions] = useState(props.tagOptions);

  const getFormattedData = useCallback((data = null) => {
    let { jobId, projectId } = data;
    if (data?.jobAndProject?.jobNumber) {
      jobId = data?.jobAndProject?.id || '';
    }
    if (data?.jobAndProject?.projectNumber) {
      projectId = data?.jobAndProject?.id || '';
    }
    return {
      poType: data?.poType || '',
      date: data?.date || moment.utc(moment().format('L')).unix(),
      department: data?.department || '',
      vendor: data?.vendor || '',
      assignedTo: data?.assignedTo || '',
      jobOrProjectDisplay:
        data?.jobAndProject?.customIdentifier ||
        data?.jobAndProject?.jobNumber ||
        data?.jobAndProject?.name ||
        data?.jobAndProject?.number ||
        '',
      jobAndProject: data?.jobAndProject || '',
      description: data?.description || '',
      requiredByDate: data?.requiredByDate || '',
      tags: data?.tags || [],
      jobId,
      projectId
    };
  }, []);

  const initialFormData = useMemo(() => getFormattedData(initialData), [
    initialData,
    getFormattedData
  ]);

  const createFieldOrderPayload = data => {
    return {
      poNumber: data?.poNumber || null,
      vendorName: data?.vendor?.name || null,
      vendorId: data?.vendor?.id || null,
      departmentName: data?.department?.tagName || null,
      departmentId: data?.department?.id || null,
      assignedToId: data?.assignedTo?.id || null,
      poTypeId: data?.poType?.id || null,
      description: data?.description || null,
      dateOfPurchase: data?.date || undefined,
      requiredByDate: data?.requiredByDate || null,
      totalAmountPreTax: 0,
      freight: 0,
      tax: 0,
      totalCost: 0,
      dateAdded: moment().unix(),
      status: 'Draft',
      jobId: data?.jobId || null,
      projectId: data?.projectId || null,
      purchaseOrderLines: [],
      purchaseOrderTags: data?.tags?.map(tag => ({ id: tag })),
      isFieldOrder: 1
    };
  };

  const submitCompleted = async purchaseOrder => {
    props.snackbarOn('success', 'Successfully Posted');
    if (jobProcStatusToUpdate) {
      const service = new JobService();
      const updatedProcurementStatusPayload = {
        customerPropertyId: jobProcStatusToUpdate?.customerPropertyId,
        ignoreDepartmentUpdates: true,
        jobs: [
          {
            id: jobProcStatusToUpdate?.id,
            version: jobProcStatusToUpdate?.version + 1 || 1,
            procurementStatus: null
          }
        ]
      };
      await service.updateJobAndRelated(user.tenantId, updatedProcurementStatusPayload);
    }
    handleClose(purchaseOrder);
    history.push(`/procurement/purchaseorders/view/${purchaseOrder.id}`);
  };

  const handleComplete = async finalizeData => {
    if (formService) {
      setIsSubmitting(true);
      const payload = createFieldOrderPayload(finalizeData);
      await purchaseOrderCreate(payload).then(purchaseOrder => submitCompleted(purchaseOrder));
    }
    setIsSubmitting(false);
  };

  const handleCreateFO = () => {
    formService.submit();
  };

  const handleFormChange = data => {
    setDepartment(data.department);
  };

  useEffect(() => {
    setTagOptions(props.tagOptions);
  }, [props.tagOptions]);

  const tagOptionsFetched = useRef(false);
  if (!tagOptions.length && !tagOptionsFetched.current) {
    tagOptionsFetched.current = true;
    getPurchaseOrderTags().then(purchaseOrderTags => {
      setTagOptions(constructSelectOptions(purchaseOrderTags, 'tagName'));
    });
  }

  const handleJobOrProjectIdChange = async (selectedItem, _selectedItemName, form) => {
    const jobService = new JobService();
    const jobDetails = await jobService.getJobDetailsByJobNumber(`${selectedItem.jobNumber}`);
    setUpdatedJobData(jobDetails?.data?.getJobByJobNumber);
    const modifiedValues = {
      ...form.values,
      jobOrProjectDisplay:
        selectedItem?.customIdentifier ||
        selectedItem?.jobNumber ||
        selectedItem?.projectName ||
        selectedItem?.projectNumber ||
        '',
      projectId: selectedItem?.projectNumber ? selectedItem?.id : null,
      jobId: selectedItem?.jobNumber ? selectedItem?.id : null
    };

    form.setValues(modifiedValues);
  };

  const jobManualProcurementStatus = job => {
    return job.entityType === 'Job' && job.procurementStatus;
  };

  const openProcurementStatusChangedModal = job => {
    const automatedStatus = getProcurementStatus([
      ...(job?.purchaseOrders?.items || []),
      { status: 'Draft' }
    ]);
    setProcurementStatusModalData({
      manualStatus: job?.procurementStatus || 'POs Needed',
      automatedStatus
    });
    setOpenProcurementStatusModal(true);
  };

  const checkForPrefilledJob = async job => {
    if (isEmpty(job) && initialData?.jobAndProject?.jobNumber) {
      const jobService = new JobService();
      const jobDetails = await jobService.getJobDetailsByJobNumber(
        `${initialData?.jobAndProject?.jobNumber}`
      );
      if (jobDetails?.data?.getJobByJobNumber) {
        return jobDetails?.data?.getJobByJobNumber;
      }
    }
    return job;
  };

  const handleSave = async () => {
    if (flags[FeatureFlags.JOB_PROCUREMENT_STATUS]) {
      const job = await checkForPrefilledJob(updatedJobData || jobData);
      if (jobManualProcurementStatus(job)) {
        openProcurementStatusChangedModal(job);
        setJobProcStatusToUpdate(job);
        return;
      }
    }
    setProcurementStatusModalData(null);
    setJobProcStatusToUpdate(null);
    handleCreateFO();
  };
  return (
    <FullScreenModal
      title={Labels.createFieldOrder[user.locale]}
      open={open}
      handleClose={handleClose}
      modalHeaderButtons={[
        <DefaultButton
          key="createFieldOrderHeaderBtn"
          label={Labels.assignFieldOrder[user.locale]}
          variant="containedPrimary"
          onClick={handleSave}
          showSpinner={isSubmitting}
          disabled={isSubmitting}
        />
      ]}
    >
      <div className={classes.root}>
        <div className={classes.formContainer}>
          <MUIForm
            configuration={fieldOrderGeneralInformationLayout({
              tags: tagOptions,
              department,
              handleJobOrProjectIdChange
            })}
            data={initialFormData}
            layout="edit"
            onCreateService={service => setFormService(service)}
            onComplete={handleComplete}
            onFormChange={handleFormChange}
            customComponents={{
              MuiFormSectionTitle,
              SearchBar,
              CustomFieldWithLabelStyled,
              AlgoliaSearch
            }}
            validationSchema={generateDefaultValidationSchema(fieldOrderGeneralInformationFields)}
          />
          {flags[FeatureFlags.JOB_PROCUREMENT_STATUS] && (
            <ManulStatusChangeModal
              title="Update Procurement Status"
              statusEnumType={EnumType.JOB_PROCUREMENT_STATUS}
              data={procurementStatusModalData}
              open={openProcurementStatusModal}
              handleClose={xButtonClosed => {
                if (!xButtonClosed) {
                  setJobProcStatusToUpdate(null);
                  handleCreateFO();
                }
                setOpenProcurementStatusModal(false);
              }}
              handleSubmit={() => {
                handleCreateFO();
                setOpenProcurementStatusModal(false);
              }}
            />
          )}
        </div>
      </div>
    </FullScreenModal>
  );
};

CustomFieldWithLabelStyled.propTypes = {
  field: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

CreateFieldOrder.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  snackbarOn: PropTypes.func.isRequired,
  tagOptions: PropTypes.array,
  initialData: PropTypes.object,
  jobData: PropTypes.object
};

CreateFieldOrder.defaultProps = {
  tagOptions: [],
  initialData: {},
  jobData: {}
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});
const ReduxConnectedCreateFieldOrder = connect(
  mapStateToProps,
  mapDispatcherToProps
)(CreateFieldOrder);
export default ReduxConnectedCreateFieldOrder;
