import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';

import { generateWeekDate } from 'scenes/Payroll/TimeTrackingReport/helpers';
import { sentryMessage } from 'services/Logger';
import { TimeCardStatusTypes } from 'utils/AppConstants';

import { eventTypes } from '../constants';
import { getTimesheetPeriodsPendingForEmployee } from '../services';

// UNDEFINED_HOUR_TYPE is just a placeholder,
// DayCard component will be blocked by <CorruptData /> component when its time to render.
const UNDEFINED_HOUR_TYPE = 'undefined';

const getScheduledTime = ({
  actualDuration,
  actualStartTimeUTC,
  actualEndTimeUTC,
  plannedStartTimeUTC,
  plannedEndTimeUTC,
  type,
  isTimeSheetWorkEvent = false
}) => {
  let value;
  if (type === eventTypes.VISIT) {
    if (!actualDuration) return 0;
    const minutes = actualDuration?.split(' ')[0];
    value = minutes / 60;
  } else if (isTimeSheetWorkEvent) {
    value = (actualEndTimeUTC - actualStartTimeUTC) / 3600;
  } else {
    value = (plannedEndTimeUTC - plannedStartTimeUTC) / 3600;
  }
  const hour = value !== 0 ? value.toFixed(2) : value;
  return hour > 0 ? `${Math.round(hour * 2) / 2} hrs` : '-';
};

const constructManDayIdentifier = (
  {
    id,
    project,
    projectPhase,
    projectPhaseDepartment,
    projectPhaseDepartmentCostCode,
    dailyReport,
    status,
    plannedStartTimeUTC,
    plannedEndTimeUTC
  },
  isTimeSheetWorkEvent,
  entry
) => ({
  type: eventTypes.MAN_DAY,
  id,
  projectId: project?.id,
  dailyReportId: dailyReport?.id,
  dailyReportNumber: dailyReport?.number,
  project: project?.name,
  projectNumber: project?.number,
  costCode: projectPhaseDepartmentCostCode?.name,
  phase: projectPhase?.name,
  department: projectPhaseDepartment?.tagName,
  status,
  scheduledTime: getScheduledTime({
    type: eventTypes.MAN_DAY,
    isTimeSheetWorkEvent,
    ...(entry || { plannedStartTimeUTC, plannedEndTimeUTC })
  })
});

const constructNonVisitIndentifier = (nonVisitEvent = {}) => {
  const status = nonVisitEvent?.status ? nonVisitEvent?.status : '';
  const department =
    nonVisitEvent.assignedEntity?.departmentName ?? nonVisitEvent?.department?.tagName ?? '';

  const job =
    nonVisitEvent.assignedEntity?.job?.customIdentifier ??
    nonVisitEvent.assignedEntity?.job?.jobNumber ??
    '';

  const name = nonVisitEvent.name ?? '';

  const visit = nonVisitEvent.assignedEntity?.visitNumber ?? '';

  const property = nonVisitEvent.assignedEntity?.job?.customerProperty?.companyName ?? '';

  const customer =
    nonVisitEvent.assignedEntity?.job?.customerProperty?.customer?.customerName ?? '';

  const scheduledTime = getScheduledTime({ type: eventTypes.NON_VISIT_EVENT, ...nonVisitEvent });

  return {
    type: eventTypes.NON_VISIT_EVENT,
    name,
    job,
    jobNumber: nonVisitEvent.assignedEntity?.job?.jobNumber,
    jobType: nonVisitEvent.assignedEntity?.job?.jobTypeInternal,
    visit,
    customer,
    property,
    status,
    department,
    id: compact([name, job, visit, property, customer, status, department]).join(' | '),
    scheduledTime
  };
};

const constructVisitIndentifier = (billableEntity = {}) => {
  const customer = billableEntity.job?.customerProperty?.customer?.customerName ?? '';

  const department = billableEntity.departmentName ?? '';

  const job = billableEntity.job?.customIdentifier ?? billableEntity.job?.jobNumber;
  const visit = billableEntity.visitNumber;
  const property = billableEntity.job?.customerProperty?.companyName ?? '';

  const { status } = billableEntity;

  const scheduledTime = getScheduledTime({ type: eventTypes.VISIT, ...billableEntity });

  return {
    type: eventTypes.VISIT,
    job,
    jobNumber: billableEntity.job?.jobNumber,
    jobType: billableEntity.job?.jobTypeInternal,
    visit,
    customer,
    property,
    status,
    department,
    id: compact([job, visit, property, customer, status, department]).join(' | '),
    scheduledTime
  };
};

const constructEventIdentifier = (event, timesheetEntryBinder) => {
  switch (event?.entityType) {
    case eventTypes.NON_VISIT_EVENT:
      return constructNonVisitIndentifier(event);
    case eventTypes.VISIT:
      return constructVisitIndentifier(event);
    case eventTypes.MAN_DAY:
      return constructManDayIdentifier({
        ...timesheetEntryBinder,
        ...event,
        plannedStartTimeUTC: event.startDateTime,
        plannedEndTimeUTC: event.endDateTime
      });
    default:
      return {};
  }
};

const constructWorkEvent = (timesheetEntryBinder, timesheetHours, payrollHourTypes) => {
  const { id, event, timesheetNotes } = timesheetEntryBinder;
  const timesheetNotesForSort = timesheetNotes?.items ? [...timesheetNotes?.items] : [];
  return {
    binderId: id,
    ...event,
    timesheetHours,
    auditLogs: timesheetEntryBinder?.timesheetEntries?.items.map(entry =>
      entry?.auditLogs?.items.map(log => ({
        ...log,
        hourType: payrollHourTypes.find(t => t.id === entry.hourTypeId),
        entry: {
          ...entry,
          workEvent: {
            // timesheetEntries from daily reports won't have event id's
            id: event?.id
          }
        }
      }))
    ),
    timesheetNotes: timesheetNotesForSort.sort(
      (noteA, noteB) => parseInt(noteB.createdDateTime, 10) - parseInt(noteA.createdDateTime, 10)
    ),
    // TODO: populate schedules
    schedules: [],
    identifier: constructEventIdentifier(event, timesheetEntryBinder),
    canceled: event?.isActive === false
  };
};

export const generateWeekDataFromBinder = (
  timesheetEntryBinders,
  date,
  timezone,
  payrollHourTypes,
  selectedStatuses,
  entriesToUpdate = []
) => {
  const payrollHourDailyTotals = payrollHourTypes.reduce(
    (acc, hourType) => ({
      ...acc,
      [hourType.hourTypeAbbreviation]: 0
    }),
    {}
  );
  const weekDays = generateWeekDate(date, timezone);
  const timesheetsByWeekDay = weekDays.reduce(
    (acc, day) => ({
      ...acc,
      [`${day.dayStartUTC}-${day.dayEndUTC}`]: {
        ...day,
        dailyTotals: payrollHourDailyTotals,
        workEvents: [],
        bindersOnThisDay: [],
        validHourTypes: []
      }
    }),
    {}
  );

  timesheetEntryBinders.forEach(binder => {
    const {
      startDayCompanyTZ,
      manualStatus,
      timesheetEntries: { items: entriesOnThisDay }
    } = binder;

    if (!selectedStatuses.includes(manualStatus)) {
      return;
    }

    const weekDayKey = Object.keys(timesheetsByWeekDay).filter(key => {
      const [dayStartUTC, dayEndUTC] = key.split('-');
      const startDay = parseInt(startDayCompanyTZ, 10);
      return startDay <= parseInt(dayEndUTC, 10) && startDay >= parseInt(dayStartUTC, 10);
    })[0];

    const weekDay = timesheetsByWeekDay[weekDayKey];
    if (!weekDay) {
      sentryMessage(
        'TimesheetEntryBinder does not have a startDayCompanyTZ within assigned TimesheetPeriod',
        {
          timesheetEntryBinder: binder
        }
      );
      return;
    }
    const { dailyTotals, validHourTypes } = weekDay;
    const timesheetHours = {};

    entriesOnThisDay.forEach(entry => {
      let timesheetEntry = entry;

      // update entry if included in entriesToUpdate
      const updatedEntry = entriesToUpdate.find(e => e.id === entry.id);
      if (updatedEntry) {
        const { extra, ...props } = updatedEntry;
        timesheetEntry = { ...entry, ...props };
      }

      const { id, hourTypeId, actualTotalDuration, actualTotalDurationOverride } = timesheetEntry;
      const hourType = payrollHourTypes.find(t => t.id === hourTypeId);

      timesheetHours[hourType?.hourTypeAbbreviation || UNDEFINED_HOUR_TYPE] = {
        actualTotalDuration,
        actualTotalDurationOverride,
        hourType,
        id
      };

      validHourTypes.push(hourType?.hourTypeAbbreviation || UNDEFINED_HOUR_TYPE);

      dailyTotals[hourType?.hourTypeAbbreviation || UNDEFINED_HOUR_TYPE] += Number.isInteger(
        actualTotalDurationOverride
      )
        ? actualTotalDurationOverride
        : actualTotalDuration;
    });

    timesheetsByWeekDay[weekDayKey] = {
      ...weekDay,
      workEvents: [
        ...weekDay.workEvents,
        constructWorkEvent(binder, timesheetHours, payrollHourTypes)
      ],
      bindersOnThisDay: [...weekDay.bindersOnThisDay, binder],
      validHourTypes: uniq(validHourTypes),
      dailyTotals
    };
  });

  return Object.values(timesheetsByWeekDay);
};

// Deprecate this method once wrinkle-in-time FF enabled everywhere
export const generateWeekData = (
  timesheetEntries,
  date,
  timezone,
  payrollHourTypes,
  approvalStatus,
  selectedEmployee,
  unsubmittedEvents
) =>
  generateWeekDate(date, timezone).map(day => {
    const dailyTotals = payrollHourTypes.reduce(
      (acc, hourType) => ({
        ...acc,
        [hourType.hourTypeAbbreviation]: 0
      }),
      {}
    );

    const entriesOnThisDay = timesheetEntries
      .filter(e => e.actualStartTimeUTC <= day.dayEndUTC && e.actualStartTimeUTC >= day.dayStartUTC)
      .filter(e => e.manualStatus === approvalStatus);

    // BUOP-9995 unknown root cause & not reproduceable, adding this filter to 3.17.0 to detect if it occurs.
    // Remove after root cause has been found or if it never happens again.
    const entriesWithoutManualStatus = timesheetEntries.filter(e => e.manualStatus === null);
    if (entriesWithoutManualStatus.length) {
      sentryMessage('TimesheetEntry(ies) missing manual status', {
        entries: entriesWithoutManualStatus
      });
    }

    // TODO: simplify and get rid of a lot of this logic now that we have the work event info we need
    const workEvents = entriesOnThisDay.reduce((acc, entry) => {
      const workEventIndex = acc.findIndex(v => {
        const hasWorkEventId = !!v.id;
        const nonVisitMatch = v.id === entry.nonVisitEventId;
        const visitMatch =
          v.id === entry.billableEntity?.id && v.entityType === entry.billableEntity?.entityType;
        const binderMatch = v.id === entry.timesheetEntryBinderId;
        return hasWorkEventId && (nonVisitMatch || visitMatch || binderMatch);
      });

      const {
        actualTotalDuration,
        actualTotalDurationOverride,
        actualStartTimeUTC,
        actualEndTimeUTC,
        hourTypeId,
        id,
        auditLogs
      } = entry;
      const hourType = payrollHourTypes.find(t => t.id === hourTypeId);

      if (workEventIndex !== -1) {
        const newArr = [...acc];

        const corruptEntry =
          !hourType || newArr[workEventIndex].timesheetHours?.[hourType.hourTypeAbbreviation]
            ? { entry, newArr, hourType }
            : null;

        newArr[workEventIndex] = {
          ...newArr[workEventIndex],
          timesheetHours: {
            ...newArr[workEventIndex].timesheetHours,
            [hourType?.hourTypeAbbreviation || UNDEFINED_HOUR_TYPE]: {
              actualTotalDuration,
              actualTotalDurationOverride,
              hourType,
              id
            }
          },
          auditLogs: (newArr[workEventIndex].auditLogs ?? [])
            .concat((auditLogs?.items ?? []).map(log => ({ ...log, hourType, entry })))
            .sort(
              (logA, logB) =>
                parseInt(logB.executedDateTime, 10) - parseInt(logA.executedDateTime, 10)
            ),
          corruptData: corruptEntry
            ? [...newArr[workEventIndex].corruptData, corruptEntry]
            : newArr[workEventIndex].corruptData
          // if an workEvent is already added, no need to re-add timesheetNotes or schedules prop to it.
        };
        return newArr;
      }

      let corruptData = hourType ? [] : [{ entry, hourType }];

      if (entry.timesheetEntryBinder) {
        return [
          ...acc,
          {
            id: entry.timesheetEntryBinderId,
            timesheetHours: {
              [hourType?.hourTypeAbbreviation || UNDEFINED_HOUR_TYPE]: {
                actualTotalDuration,
                actualTotalDurationOverride,
                hourType,
                id
              }
            },
            entityType: 'TimesheetEntryBinder',
            auditLogs: auditLogs.items
              .map(log => ({ ...log, hourType, entry }))
              .sort(
                (logA, logB) =>
                  parseInt(logB.executedDateTime, 10) - parseInt(logA.executedDateTime, 10)
              ),
            timesheetNotes: (entry.timesheetEntryBinder?.timesheetNotes?.items || []).sort(
              (noteA, noteB) =>
                parseInt(noteA.createdDateTime, 10) - parseInt(noteB.createdDateTime, 10)
            ),
            schedules: [], // @TODO future enhacement
            identifier: constructManDayIdentifier(entry.timesheetEntryBinder, true, entry),
            corruptData,
            disableRequestRevision: !entry.timesheetEntryBinder.eventId, // @TODO at Dec. 1 2021 the only .eventType are ManDays. In the future when Binders are generalized to Visits and NVEs this needs to be changed to entry.timesheetEntryBinder.event?.entityType === 'ManDay'
            actualStartTimeUTC,
            actualEndTimeUTC
          }
        ];
      }

      if (entry.billableEntity) {
        return [
          ...acc,
          {
            ...entry.billableEntity,
            timesheetHours: {
              [hourType?.hourTypeAbbreviation || UNDEFINED_HOUR_TYPE]: {
                actualTotalDuration,
                actualTotalDurationOverride,
                hourType,
                id
              }
            },
            auditLogs: auditLogs.items
              .map(log => ({ ...log, hourType, entry }))
              .sort(
                (logA, logB) =>
                  parseInt(logB.executedDateTime, 10) - parseInt(logA.executedDateTime, 10)
              ),
            timesheetNotes: (entry.billableEntity?.timesheetNotes?.items || [])
              .filter(note => note.employeeId === selectedEmployee.id)
              .sort((noteA, noteB) => noteA.createdDateTime - noteB.createdDateTime),
            schedules: (entry.billableEntity?.schedules?.items || [])
              .filter(s => s.employee?.id === selectedEmployee.id)
              .reduce((timeSheets, s) => {
                return timeSheets.concat(
                  s.timeSheets.items.map(item => ({ ...item, employeeName: s.createdBy }))
                );
              }, [])
              .sort((timeSheetA, timeSheetB) => timeSheetA.clockInTime - timeSheetB.clockInTime),
            identifier: constructVisitIndentifier(entry.billableEntity),
            canceled: entry.billableEntity.status === 'Canceled',
            corruptData
          }
        ];
      }

      if (entry.nonVisitEvent) {
        return [
          ...acc,
          {
            ...entry.nonVisitEvent,
            timesheetHours: {
              [hourType?.hourTypeAbbreviation || UNDEFINED_HOUR_TYPE]: {
                actualTotalDuration,
                actualTotalDurationOverride,
                hourType,
                id
              }
            },
            auditLogs: auditLogs.items
              .map(log => ({ ...log, hourType, entry }))
              .sort(
                (logA, logB) =>
                  parseInt(logB.executedDateTime, 10) - parseInt(logA.executedDateTime, 10)
              ),
            timesheetNotes: (entry.nonVisitEvent?.timesheetNotes?.items || [])
              .filter(note => note.employeeId === selectedEmployee.id)
              .sort((noteA, noteB) => noteA.createdDateTime - noteB.createdDateTime),
            schedules: (entry.nonVisitEvent?.timekeepingLedgersView?.items || [])
              .filter(ledger => ledger.employeeId === selectedEmployee.id)
              .map(l => ({
                ...l,
                clockInTime: l.actualStartTimeUTC,
                labourType: l.userActionType,
                totalDuration: l.actualTotalDuration
              }))
              .sort((timeSheetA, timeSheetB) => timeSheetA.clockInTime - timeSheetB.clockInTime),
            identifier: constructNonVisitIndentifier(entry.nonVisitEvent),
            canceled: entry.nonVisitEvent?.isActive === false,
            corruptData
          }
        ];
      }

      corruptData = [
        {
          entry,
          hourType,
          reason: 'TimesheetEntry missing billableEntity and nonVisitEvent and timesheetEntryBinder'
        }
      ];

      return [
        ...acc,
        {
          corruptData
        }
      ];
    }, []);

    const validHourTypes = [];
    entriesOnThisDay.forEach(e => {
      const { hourTypeId, actualTotalDuration, actualTotalDurationOverride } = e;

      const hourType = payrollHourTypes.find(t => t.id === hourTypeId);

      dailyTotals[hourType?.hourTypeAbbreviation || UNDEFINED_HOUR_TYPE] += Number.isInteger(
        actualTotalDurationOverride
      )
        ? actualTotalDurationOverride
        : actualTotalDuration;

      validHourTypes.push(hourType?.hourTypeAbbreviation || UNDEFINED_HOUR_TYPE);
    });

    return {
      ...day,
      entriesOnThisDay,
      workEvents,
      dailyTotals,
      validHourTypes: uniq(validHourTypes),
      dailyUnsubmittedEvents: unsubmittedEvents
        .filter(
          e => day.dayStartUTC < e.plannedStartTimeUTC && e.plannedStartTimeUTC < day.dayEndUTC
        )
        .map(e => {
          if (e.isVisit) return { ...e, identifier: constructVisitIndentifier(e) };
          if (e.isNonVisitEvent) return { ...e, identifier: constructNonVisitIndentifier(e) };
          if (e.isProjectVisit) return { ...e, identifier: constructManDayIdentifier(e) };
          return e;
        })
    };
  });

export const getPendingDates = async ({
  employee,
  snackbarOn,
  payrollSetting,
  payrollHourTypes,
  unsubmittedEvents
}) => {
  const periods = await getTimesheetPeriodsPendingForEmployee({
    employee,
    snackbarOn
  });
  const weekDatesData = flatten(
    periods.map(period => {
      const {
        timesheetEntriesView: { items: manualTimesheetEntries }
      } = period;

      return generateWeekData(
        manualTimesheetEntries,
        period,
        payrollSetting.timeZone,
        payrollHourTypes,
        TimeCardStatusTypes.DISPUTED,
        employee,
        unsubmittedEvents
      );
    })
  ).filter(d => d.workEvents.length);

  return weekDatesData;
};
