import React, { useEffect, useState } from 'react';

import { calculateMarginFromMarkup } from '@buildhero/math';
import {
  Button,
  Divider,
  Field,
  FieldType,
  MoreButton,
  Select,
  ThemeProvider,
  TV,
  Typography
} from '@buildhero/sergeant';
import { Box, Grid } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Context, SergeantModal } from 'components';
import AlgoliaSearchWrapper from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import Spinner from 'components/Spinners/CircularIndeterminate';
import { useLazyFetchPricebookEntry } from 'components/Tasks/components/useLazyFetchPricebookEntry';
import {
  getCostCodes,
  getJobCostTypes,
  splitJobCostTypes
} from 'customHooks/useLabourTypeDependency';
import LineItemLayout from 'meta/Quotes/LineItemLayout';

import { snackbarOn } from 'redux/actions/globalActions';
import { getPriceBook2Helper } from 'scenes/Pricebooks/fetchHelper';
import { ItemType, SettingConstants } from 'scenes/Quotes/constants';
import { PricebookService } from 'services/core';
import { Logger } from 'services/Logger';
import { roundCurrency } from 'utils';
import { EntityType, Mode, PricingStrategy, QuoteLineItemType } from 'utils/constants';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import { convertForMathLib } from 'utils/mathLibrary';
import { getMarginFromMarkup, onItemCalcChange } from 'utils/onCalcChange';
import { getTotalMarkup, getUnitPrice } from 'utils/pricebooks';

import useStyles from './TotalsPanel.styles';
import { TotalsPanelLine } from './TotalsPanelLine';

function TotalsPanel({
  addQuoteLineProducts,
  deleteLineItem,
  updateTaxRateOnQuote,
  priceBookId,
  quoteInfo,
  quoteLineProducts,
  user,
  config,
  updateOverrideAmountOnQuote,
  isReadOnly
}) {
  const pricebookVersion = useSelector(s => s.company.pricebookVersion);
  const [priceBook2, setPriceBook2] = useState(null);
  const fetchPricebookEntry = useLazyFetchPricebookEntry();
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState({ isOpen: false, type: '' });
  const [isDeleting, setIsDeleting] = useState({ bool: false, id: '' });
  const [data, setData] = useState(null);
  const { pricingStrategy } = useSelector(s => s.settings);
  const showMargin =
    pricingStrategy === PricingStrategy.MARGIN ||
    pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN;
  const showMarkup =
    pricingStrategy === PricingStrategy.MARKUP ||
    pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN;

  const company = Context.getCompanyContext().getCompany;
  // clarify that the tax rate should prepopulated by the property address by default
  const taxRateOptions = company?.taxRates?.items?.map(tax => ({
    ...tax,
    label: `${tax.name}: ${tax.taxRate}%`,
    value: tax.id
  }));

  const onItemSelection = async (selectedItem, selectedItemName, form, field) => {
    let pbValues = {};
    if (priceBookId && selectedItem?.sortKey) {
      if (pricebookVersion >= 2) {
        const productId = selectedItem.sortKey.slice(-36);
        const unitCost = selectedItem?.unitCost;

        const totalMarkup =
          getTotalMarkup(priceBook2.priceBook2, priceBook2.overridesMap, productId, unitCost) * 100;
        const unitPrice = getUnitPrice(
          priceBook2.priceBook2,
          priceBook2.overridesMap,
          productId,
          unitCost
        );

        pbValues = {
          markupValue: totalMarkup,
          unitPrice,
          markupType: 'Percentage',
          costCodeId: null,
          revenueTypeId: null,
          jobCostTypeId: null,
          amount: unitPrice
        };
      } else {
        const pbEntry = await fetchPricebookEntry({
          pricebookId: priceBookId,
          productSortKey: selectedItem.sortKey
        });

        pbValues = {
          markupValue: pbEntry?.markupValue,
          markupType: pbEntry?.markupType,
          unitPrice: pbEntry?.unitPrice,
          costCodeId: pbEntry?.costCodeId,
          revenueTypeId: pbEntry?.revenueTypeId,
          jobCostTypeId: pbEntry?.jobCostTypeId,
          amount: pbEntry?.unitPrice
        };
      }
    }

    const { values, setValues } = form;
    if (!selectedItem) {
      setValues({
        ...values,
        [field.name]: undefined
      });
      return;
    }
    const valuesToSet = [
      {
        name: 'name',
        productId: 'id',
        productSortKey: 'sortKey',
        description: 'description',
        unitCost: 'unitCost',
        accountingRefIdOfEntity: 'accountingRefId',
        unitOfMeasure: 'unitOfMeasure',
        quantity: 1,
        taxable: 'taxable'
      }
    ];

    // object passed in meta are stripped by form library. Expecting only one object within the arr
    const keysToExtractFromResult = Object.keys(valuesToSet[0]);
    const extractedValues = {};
    keysToExtractFromResult.forEach(key => {
      const valueKey = valuesToSet[0][key];
      // set as the valueKey if it exists, otherwise set as the value given
      extractedValues[key] =
        typeof valueKey === 'string' ? get(selectedItem, valueKey, null) : valueKey;
    });

    setValues({
      ...values,
      ...extractedValues,
      ...pbValues
    });
    if (showMargin) {
      form.setFieldValue('marginValue', getMarginFromMarkup(pbValues.markupValue));
    }
  };

  const [formData, setFormData] = useState({
    departmentList: [],
    onCalcChange: onItemCalcChange,
    isPricebookEnabled: true,
    priceBookId,
    pricebookService: new PricebookService()
  });

  useEffect(() => {
    const departments = company?.departments?.items || [];
    const departmentList = departments.map(d => ({
      label: d.tagName,
      value: d.id,
      accountingRefIdOfClass: d.accountingRefIdOfClass
    }));

    const getJobCostingFields = async () => {
      const costCodes = await getCostCodes(user.tenantId, user.tenantCompanyId, snackbarOn);
      const costCodeTypeDetails = await getJobCostTypes(
        user.tenantId,
        user.tenantCompanyId,
        snackbarOn
      );
      const { jobCostTypes, revenueTypes } = splitJobCostTypes(costCodeTypeDetails);

      setFormData(f => ({
        ...f,
        departmentList,
        costCodes: constructSelectOptions(costCodes),
        costTypes: constructSelectOptions(jobCostTypes),
        revenueTypes: constructSelectOptions(revenueTypes),
        discountType: [
          { label: QuoteLineItemType.DISCOUNT, value: QuoteLineItemType.DISCOUNT },
          { label: QuoteLineItemType.FEE, value: QuoteLineItemType.FEE }
        ]
      }));
    };
    getJobCostingFields();
  }, [user.tenantCompanyId, user.tenantId]);

  useEffect(() => {
    const getPB2 = async () => {
      const pb2 = await getPriceBook2Helper(priceBookId);
      setPriceBook2(pb2);
    };

    if (pricebookVersion >= 2 && priceBookId) {
      getPB2();
    }
  }, [priceBookId]);

  const handlePrimaryAction = async (newData, doneLoading) => {
    try {
      const product = {
        id: newData.id,
        costCodeId: newData.costCodeId,
        departmentId: newData.departmentId,
        description: newData.description,
        jobCostTypeId: newData.jobCostTypeId,
        lineItemType: isModalOpen.type,
        markupType: newData.markupType,
        markupValue: newData.markupValue,
        name: newData.name,
        productId: newData.productId,
        quantity: newData.quantity,
        revenueTypeId: newData.revenueTypeId,
        taxable: newData.taxable,
        unitCost: newData.unitCost,
        unitPrice:
          isModalOpen.type === QuoteLineItemType.MATERIAL
            ? newData.unitPrice
            : roundCurrency(newData?.amount)
      };
      await addQuoteLineProducts(product);
    } catch (e) {
      Logger.error(e);
    } finally {
      doneLoading();
      setIsModalOpen(false);
      setData(null);
    }
  };

  const lineItems = [
    { type: QuoteLineItemType.MATERIAL, btnLabel: 'Add Line Item' },
    { type: QuoteLineItemType.DISCOUNT, btnLabel: 'Add Discount' }
  ];

  const deleteItem = async product => {
    setIsDeleting({ bool: true, id: product.id });
    try {
      await deleteLineItem(product.id);
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsDeleting({ bool: false, id: '' });
    }
  };

  const [selectedTaxRate, setSelectedTaxRate] = useState({ id: quoteInfo?.taxRateId });
  const onSelectTaxRate = async selected => {
    if (selected?.id !== selectedTaxRate?.id) {
      setSelectedTaxRate(selected);
      const payload = {
        id: selected.id,
        value: selected.taxRate
      };
      await updateTaxRateOnQuote(payload);
    }
  };

  const taxRateSelectValue = taxRateOptions.find(opt => opt.id === selectedTaxRate?.id);

  const isShowTaskSubtotalStriped = !config?.[SettingConstants.TOTALS_AND_SUBTOTALS]?.[
    SettingConstants.SHOW_TASK_SUBTOTAL
  ];

  const isShowLineItemPricingStriped = !config?.[SettingConstants.TOTALS_AND_SUBTOTALS]?.[
    SettingConstants.SHOW_LINE_ITEM_PRICING
  ];

  const isShowDiscountStriped = !config?.[SettingConstants.TOTALS_AND_SUBTOTALS]?.[
    SettingConstants.SHOW_DISCOUNT
  ];

  const isShowLineItemDiscountStriped = lineItemType =>
    (lineItemType === ItemType.MATERIAL && isShowLineItemPricingStriped) ||
    (lineItemType === ItemType.DISCOUNT && isShowDiscountStriped);

  const isShowSubtotalStriped = !config?.[SettingConstants.TOTALS_AND_SUBTOTALS]?.[
    SettingConstants.SHOW_SUBTOTAL
  ];

  const isShowTaxStriped = !config?.[SettingConstants.TOTALS_AND_SUBTOTALS]?.[
    SettingConstants.SHOW_TAX
  ];

  const isShowTotalStriped = !config?.[SettingConstants.TOTALS_AND_SUBTOTALS]?.[
    SettingConstants.SHOW_TOTAL
  ];

  return (
    <Grid container alignItems="flex-end" direction="column">
      <ThemeProvider>
        {/* Task Subtotal */}
        <TotalsPanelLine
          quoteId={quoteInfo.id}
          value={quoteInfo.taskSubTotal}
          labelWithInfo
          labelName="Task Subtotal"
          tooltip="Subtotal of all tasks"
          divider
          striped={isShowTaskSubtotalStriped}
        />
        {/* Line Items and Discounts */}
        {lineItems.map(item => {
          return (
            <>
              {quoteLineProducts &&
                quoteLineProducts
                  .filter(product => product.lineItemType === item.type)
                  .map(product => {
                    return (
                      <Grid
                        container
                        justify="flex-end"
                        alignItems="center"
                        style={{ textAlign: 'right' }}
                      >
                        <Typography
                          variant={TV.BASE}
                          style={{ paddingRight: 48 }}
                          className={classes.lineItemDescription}
                        >
                          {(product.description && product.description.trim()) || product.name}
                        </Typography>
                        <Box className={`${classes.lineItemAmount} ${product.lineItemType}`}>
                          <Field
                            striped={isShowLineItemDiscountStriped(product.lineItemType)}
                            type={FieldType.CURRENCY}
                            value={product.unitPrice * product.quantity}
                          />
                        </Box>
                        {isDeleting.bool && isDeleting.id === product.id ? (
                          <Spinner size={24} />
                        ) : (
                          <MoreButton
                            disabled={isReadOnly}
                            options={[
                              {
                                label: 'Edit',
                                onClick: () => {
                                  setData({
                                    ...product,
                                    amount: product.unitPrice * product.quantity,
                                    marginValue: convertForMathLib(
                                      calculateMarginFromMarkup,
                                      product.markupValue
                                    )
                                  });
                                  setIsModalOpen({ isOpen: true, type: product.lineItemType });
                                }
                              },
                              {
                                label: 'Delete',
                                onClick: () => deleteItem(product)
                              }
                            ]}
                          />
                        )}
                      </Grid>
                    );
                  })}
              <Box>
                <Button
                  disabled={isReadOnly}
                  style={{ color: '#00874d' }}
                  type="leading"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => setIsModalOpen({ isOpen: true, type: item.type })}
                >
                  {item.btnLabel}
                </Button>
                <Divider color="#333333" margin={3} />
              </Box>
            </>
          );
        })}
        {/* Subtotal */}
        <TotalsPanelLine
          quoteId={quoteInfo.id}
          value={quoteInfo.subTotal}
          labelWithInfo
          labelName="Subtotal"
          tooltip="Subtotal of all tasks including line items and discounts"
          divider
          striped={isShowSubtotalStriped}
          overrideValue={quoteInfo.subTotalOverride}
          updateOverrideAmountOnQuote={updateOverrideAmountOnQuote}
          config={config}
        />
        {/* Taxable Subtotal */}
        <TotalsPanelLine
          quoteId={quoteInfo.id}
          value={quoteInfo.subTotalAmountTaxable}
          labelName="Taxable Subtotal"
          striped={isShowTaxStriped}
          overrideValue={quoteInfo.subTotalAmountTaxableOverride}
          updateOverrideAmountOnQuote={updateOverrideAmountOnQuote}
          config={config}
        />
        {/* Tax Rate Dropdown Selection */}
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          style={{ textAlign: 'right', width: '25%' }}
        >
          <Select
            disabled={isReadOnly}
            label="Tax Rate"
            value={taxRateSelectValue}
            options={taxRateOptions}
            onChange={onSelectTaxRate}
            placeholder="Select..."
            searchable
            menuHeight={150}
          />
        </Grid>
        {/* Tax Amount */}
        <TotalsPanelLine
          quoteId={quoteInfo.id}
          value={quoteInfo.totalTaxAmountOverride ?? quoteInfo.totalTaxAmount}
          labelName="Tax Amount"
          divider
          striped={isShowTaxStriped}
        />
        {/* Total */}
        <TotalsPanelLine
          quoteId={quoteInfo.id}
          value={quoteInfo.totalAmountQuoted}
          labelName="Total"
          boldLabel
          overrideValue={quoteInfo.totalAmountQuotedOverride}
          config={config}
          updateOverrideAmountOnQuote={updateOverrideAmountOnQuote}
          striped={isShowTotalStriped}
        />
      </ThemeProvider>
      <SergeantModal
        customComponents={{
          AlgoliaSearchWrapper
        }}
        data={data || { departmentId: quoteInfo.departmentId }}
        dataType={
          isModalOpen.type === QuoteLineItemType.MATERIAL
            ? EntityType.LINE_ITEM
            : EntityType.DISCOUNT
        }
        formVersion="default"
        handleClose={() => {
          setIsModalOpen({ isOpen: false, type: '' });
          setData(null);
        }}
        handlePrimaryAction={handlePrimaryAction}
        layout={LineItemLayout(isModalOpen.type, formData, onItemSelection, showMargin, showMarkup)}
        open={isModalOpen.isOpen}
        mode={Mode.ADD}
      />
    </Grid>
  );
}

export default TotalsPanel;

TotalsPanel.propTypes = {
  addQuoteLineProducts: PropTypes.func,
  config: PropTypes.object,
  deleteLineItem: PropTypes.func,
  updateTaxRateOnQuote: PropTypes.func,
  priceBookId: PropTypes.string,
  quoteLineProducts: PropTypes.array,
  user: PropTypes.object,
  quoteInfo: PropTypes.object,
  updateOverrideAmountOnQuote: PropTypes.func
};

TotalsPanel.defaultProps = {
  addQuoteLineProducts: () => {},
  config: {},
  deleteLineItem: () => {},
  updateTaxRateOnQuote: () => {},
  priceBookId: '',
  quoteLineProducts: [],
  user: {},
  quoteInfo: {},
  updateOverrideAmountOnQuote: () => {}
};
