import { useExtendedFetchForPMAPI as useExtendedFetch } from 'customHooks/useExtendedFetch';

export const useGetProjectPermissionByProjectId = (projectId, options) => {
  return useExtendedFetch(
    {
      url: `projectuserpermissions/project/${projectId}`
    },
    {
      errorData: [],
      defaultData: [],
      ...options
    }
  );
};

export const useGetProjectPermissionByUserId = (employId, options) => {
  return useExtendedFetch(
    {
      url: `projectuserpermissions/user/${employId}`
    },
    {
      errorData: [],
      defaultData: [],
      ...options
    }
  );
};
