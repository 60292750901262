import React from 'react';
import { Typography } from '@material-ui/core';
import { ResponsiveTable } from 'components';
import { skillsListMeta } from 'meta/Settings/Company/Department/index';
import Divider from '@material-ui/core/Divider';

export default function SkillsView({ form }) {
  return (
    <div>
      <Typography variant="caption">
        The Skills listed here can be assigned employees and used to describe jobs.
      </Typography>
      <ResponsiveTable
        selfBackgroundColor="none"
        rowMetadata={skillsListMeta}
        data={form.values.skills || []}
        customCellComponents={{
          TextInput: data => {
            return <Typography variant="body1">{data.record}</Typography>;
          }
        }}
        noDataMsg="No skills selected"
      />
      <Divider fullWidth />
    </div>
  );
}
