import React from 'react';
import { oneOf, string } from 'prop-types';
import { EVENT_TYPES, EventEntities } from '@dispatch/Dispatch.constants';
import CreateBillableEvent from './components/CreateBillableEvent';
import CreateManDay from './components/CreateManDay';
import CreateNonBillableEvent from './components/CreateNonBillableEvent';
import CreateVisit from './components/CreateVisit';
import ContinueVisit from './components/ContinueVisit';
import EditBillableEvent from './components/EditBillableEvent';
import EditNonBillableEvent from './components/EditNonBillableEvent';
import EditManDay from './components/EditManDay';
import EditVisit from './components/EditVisit';

const Drawer = ({ visitId, billableEventId, nonBillableEventId, manDayId, eventType }) => {
  if (visitId) {
    return <EditVisit />;
  }

  if (billableEventId) {
    return <EditBillableEvent />;
  }

  if (nonBillableEventId) {
    return <EditNonBillableEvent />;
  }

  if (manDayId) {
    return <EditManDay />;
  }

  if (eventType === EventEntities.BILLABLE_EVENT.value.clientValue) {
    return <CreateBillableEvent />;
  }

  if (eventType === EventEntities.NON_BILLABLE_EVENT.value.clientValue) {
    return <CreateNonBillableEvent />;
  }

  if (eventType === EventEntities.CONTINUED_VISIT.value.clientValue) {
    return <ContinueVisit />;
  }

  if (eventType === EventEntities.MAN_DAY.value.clientValue) {
    return <CreateManDay />;
  }

  if (eventType === EventEntities.VISIT.value.clientValue) {
    return <CreateVisit />;
  }

  return null;
};

Drawer.propTypes = {
  visitId: string,
  billableEventId: string,
  nonBillableEventId: string,
  manDayId: string,
  eventType: oneOf(Object.values(EVENT_TYPES))
};

Drawer.defaultProps = {
  visitId: null,
  billableEventId: null,
  nonBillableEventId: null,
  manDayId: null,
  eventType: null
};

export default Drawer;
