import gql from 'graphql-tag';

const deleteInvoiceInvoiceTag = gql`
  mutation deleteInvoiceInvoiceTag($partitionKey: String!, $id: String!) {
    deleteInvoiceInvoiceTag(partitionKey: $partitionKey, id: $id) {
      id
      invoiceTag {
        id
        tagType
        tagName
      }
    }
  }
`;

export default deleteInvoiceInvoiceTag;
