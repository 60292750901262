import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import { JobService } from 'services/core';
import { Logger } from 'services/Logger';
import { ResponsiveTable, SectionHeader, ImageFullScreenDisplay } from 'components';
import { Button as DefaultButton, ThemeProvider } from '@buildhero/sergeant';
import { purchaseRecieptRows } from 'meta/attachment-layout';
import { snackbarOn } from 'redux/actions/globalActions';
import { UserPermission } from 'components/AppPermissions';
import Labels from 'meta/labels';

/**
 * Creates Receipt Section for Attachments.
 */
class ReceiptSection extends Component {
  constructor(props) {
    super(props);
    this.JobService = new JobService();
    this.state = {
      fullScreenActive: false,
      images: ''
    };
  }

  componentDidMount = () => {};

  savePONumber = async () => {
    try {
      const { user, parent } = this.props;
      const params = {
        partitionKey: user.tenantId,
        data: {
          jobId: parent?.id,
          tenantCompanyId: user.tenantCompanyId
        }
      };

      const { data } = await this.JobService.addPurchaseOrderNumberToJob(params);
      if (data) {
        const { addPurchaseOrderNumberToJob } = data;
        const { value, ...others } = addPurchaseOrderNumberToJob;
        const result = { poNumber: value, ...others };
        this.props.onGeneratePurchaseOrder(result);
        this.props.snackbarOn('success', `Successfully generated new purchase order: ${value}`);
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to generate purchase order. Please try again.', error);
    }
  };

  // Opens the Receipt Images when the receipt number link is clicked in table
  handleReceiptsRowActions = record => {
    const allReceiptImages = [];
    if (
      record.purchaseOrderReceipts &&
      record.purchaseOrderReceipts.items &&
      record.purchaseOrderReceipts.items.length
    ) {
      const receipts = record.purchaseOrderReceipts.items;

      receipts.forEach(receipt => {
        const formattedReceipt = {};
        if (receipt.imageUrl) {
          formattedReceipt.fileUrl = receipt.imageUrl;
          allReceiptImages.push(formattedReceipt);
        }
      });
      if (allReceiptImages.length > 0) {
        this.setState({ images: allReceiptImages, fullScreenActive: true });
      }
    } else {
      this.props.snackbarOn('warning', 'No receipts attached');
    }
  };

  renderButton = ({ meta, record }) => (
    <Button variant="text" onClick={() => this.handleReceiptsRowActions(record)}>
      {record[meta.id]}
    </Button>
  );

  toggleView = () => {
    this.setState(prevState => ({
      fullScreenActive: !prevState.fullScreenActive
    }));
  };

  render() {
    const { caslKey, receipts } = this.props;
    const { images, fullScreenActive } = this.state;

    const overrideHeaderButtons = [
      <ThemeProvider>
        <DefaultButton onClick={() => this.savePONumber()} type="primary" key="addButton">
          {Labels.generatePurchaseOrder[this.props.user.locale]}
        </DefaultButton>
      </ThemeProvider>
    ];

    return (
      <Grid item xs={12}>
        <UserPermission I="new" action={caslKey} user={this.props.user}>
          <SectionHeader
            title={Labels.Receipts[this.props.user.locale]}
            enablePadding
            icon="descriptionIcon"
            overrideHeaderButtons={overrideHeaderButtons}
          />
        </UserPermission>
        <ResponsiveTable
          isLoading={this.props.isLoading}
          disableFilter
          rowMetadata={purchaseRecieptRows}
          customCellComponents={{
            viewImage: this.renderButton
          }}
          data={receipts}
          caslKey={caslKey}
          sortOrder="desc"
        />
        {fullScreenActive ? (
          <ImageFullScreenDisplay images={images} index={0} toggle={this.toggleView} />
        ) : null}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const connectedReceiptSection = connect(mapStateToProps, mapDispatchToProps)(ReceiptSection);

export default connectedReceiptSection;
