import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setOpenQuickAddModal } from 'redux/actions/globalActions';
import { QuickAddModal } from 'utils/constants';
import CreateInvoicePopup from 'scenes/Invoices/CreateInvoice';
import CreatePaymentPopup from 'scenes/Payments/CreatePayment';
import CreateQuotePopup from 'scenes/Quotes/components/CreateQuote';
import CreateJobPopup from 'scenes/Jobs/CreateJobModal';
import CreatePricebookPopup from 'scenes/Pricebooks/CreatePricebook';
import CreateProjectPopup from 'scenes/ProjectManagement/components/CreateProject';
import GenerateChangeOrderPopup from 'scenes/ProjectManagement/ProjectDetails/ChangeOrdersList/GenerateChangeOrder';
import AddSubmittalItemPopup from 'scenes/ProjectManagement/ProjectDetails/SubmittalsList/ItemLists/AddSubmittalItem';
import GeneratePayApplicationPopup from 'scenes/ProjectManagement/ProjectDetails/Finance/PayApplicationsList/GeneratePayApplication';
import CreatePurchaseOrderPopup from 'scenes/Procurement/PurchaseOrders/CreatePurchaseOrder';
import CreateFieldOrderPopup from 'scenes/Procurement/PurchaseOrders/CreateFieldOrder';
import { ApolloProvider } from '@apollo/client';
import { getHttpClient } from 'client';

const entityModals = {
  [QuickAddModal.CREATE_INVOICE]: CreateInvoicePopup,
  [QuickAddModal.CREATE_PAYMENT]: CreatePaymentPopup,
  [QuickAddModal.CREATE_QUOTE]: CreateQuotePopup,
  [QuickAddModal.CREATE_JOB]: CreateJobPopup,
  [QuickAddModal.CREATE_PRICEBOOK]: CreatePricebookPopup,
  [QuickAddModal.CREATE_PROJECT]: CreateProjectPopup,
  [QuickAddModal.CREATE_PROJECT_CHANGE_ORDER]: GenerateChangeOrderPopup,
  [QuickAddModal.CREATE_PROJECT_SUBMITTAL_ITEM]: AddSubmittalItemPopup,
  [QuickAddModal.CREATE_PROJECT_PAY_APPLICATION]: GeneratePayApplicationPopup,
  [QuickAddModal.CREATE_PROCUREMENT_PO]: CreatePurchaseOrderPopup,
  [QuickAddModal.CREATE_PROCUREMENT_FO]: CreateFieldOrderPopup
};

const CreateEntryModals = props => {
  const closeModal = () => {
    props.setOpenQuickAddModal(QuickAddModal.NONE);
  };
  const history = useHistory();
  const { openQuickAddModal } = props;
  const quickAddType = openQuickAddModal?.type;
  const initialData = openQuickAddModal?.data || {};
  const onSubmit = openQuickAddModal?.onSubmit;
  const EntityModal = entityModals[quickAddType];

  return !openQuickAddModal || quickAddType === QuickAddModal.NONE ? null : (
    <ApolloProvider client={getHttpClient()}>
      <EntityModal
        open
        handleClose={closeModal}
        history={history}
        initialData={initialData}
        onSubmit={onSubmit}
      />
    </ApolloProvider>
  );
};

const mapStateToProps = state => ({
  openQuickAddModal: state.openQuickAddModal
});
const mapDispatchToProps = { setOpenQuickAddModal };
export default connect(mapStateToProps, mapDispatchToProps)(CreateEntryModals);
