import gql from 'graphql-tag';

const searchCustomerAndProperty = gql`
  query searchCustomerAndProperty($partitionKey: String!, $sortKey: String!, $searchText: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      customers(
        entityConnection: "Customer"
        filter: { lsi3: { contains: $searchText } }
        limit: 100
      ) {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          customerName
          customerTypeValue
          companyAddresses(entityConnection: "CompanyAddress") {
            items {
              id
              addressType
              addressLine1
              addressLine2
              city
              state
              zipcode
            }
          }
        }
      }
      customerProperties(
        entityConnection: "CustomerProperty"
        filter: { lsi3: { contains: $searchText } }
        limit: 100
      ) {
        items {
          companyName
          sortKey
          parentId
          parentSortKey
          customerPropertyTypeValue
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          companyAddresses(entityConnection: "CompanyAddress") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              addressType
              addressLine1
              addressLine2
              city
              state
              zipcode
            }
          }
        }
      }
      allCompanyAddresses(
        entityConnection: "CompanyAddress"
        filter: { lsi3: { contains: $searchText } }
        limit: 10000
      ) {
        items {
          lsi3
          id
          addressLine1
          addressLine2
          state
          city
          zipcode
          entityType
          addressType
          parentSortKey
          parentEntity {
            id
            __typename
            entityType
            sortKey
            hierarchy
            ... on Customer {
              id
              customerName
              customerTypeValue
            }
            ... on CustomerProperty {
              id
              companyName
              customerPropertyTypeValue
              parentId
              parentSortKey
            }
          }
        }
      }
    }
  }
`;

export default searchCustomerAndProperty;
