import gql from 'graphql-tag';

const AddClosedPeriodToCompany = gql`
  mutation addClosedPeriodsToCompany(
    $partitionKey: String!
    $data: AddClosedPeriodsToCompanyInput!
  ) {
    addClosedPeriodsToCompany(partitionKey: $partitionKey, data: $data) {
      closeDate
      isActive
      affectedAdjustmentCount
      affectedInvoiceCount
      affectedPaymentCount
    }
  }
`;

export default AddClosedPeriodToCompany;
