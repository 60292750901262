import React from 'react';

import isUndefined from 'lodash/isUndefined';

import { withQueryStringStore } from '@dispatch/Dispatch.store';
import { useCompanyOverview } from '@dispatch/queries';
import compose from 'utils/compose';

import { withGoogleMapScript } from 'utils/withGoogleMapScript';

import MapView from './MapView.component';
import { useUnscheduledVisits } from './MapView.queries';

const mapQueryStringToProps = ({
  state: { visitStatuses, showVisits, showTechs, showLocations, showUnscheduled }
}) => ({
  visitStatuses,
  showVisits: isUndefined(showVisits) ? true : showVisits,
  showTechs: isUndefined(showTechs) ? true : showTechs,
  showLocations: isUndefined(showLocations) ? true : showLocations,
  showUnscheduled: Boolean(showUnscheduled)
});

const DataProvider = props => {
  const unscheduledVisitsResponse = useUnscheduledVisits({ skip: !props.showUnscheduled });
  const companyOverviewResponse = useCompanyOverview();

  return (
    <MapView
      {...props}
      unscheduledVisitsResponse={unscheduledVisitsResponse}
      companyOverviewResponse={companyOverviewResponse}
    />
  );
};

export default compose(
  withGoogleMapScript,
  withQueryStringStore(mapQueryStringToProps)
)(DataProvider);
