import { maintenanceFrequencyLabel } from 'constants/common';

export const getOverAllSummary = ({
  maintenanceTaskTemplates,
  budgetedHours = '-',
  estimatedVisits = '-'
}) =>
  ` ${(maintenanceTaskTemplates || []).length} Maintenance Tasks | ${estimatedVisits ||
    0} Visit Estimated | ${budgetedHours || 0} Hours Budgeted`;

export const getIntervalValue = ({ schedule }) => {
  const label = maintenanceFrequencyLabel[schedule];
  return { label };
};
