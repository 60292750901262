const renderDataTemplate = {
  fields: {},
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: []
    }
  }
};

export default renderDataTemplate;
