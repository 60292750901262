import gql from 'graphql-tag';

export const WIP_REPORT_FRAGMENT = gql`
  fragment WIPReportFragment on WIPReport {
    id
    actualCostsToDate
    billedToDate
    costToComplete
    createdBy
    createdDate
    hoursUsed
    overBilled
    recordNumber
    revenueCompletedToDate
    status
    syncStatus
    tenantId
    tenantCompanyId
    totalHours
    underBilled
    updatedContractsAmount
    updatedCostBudgets
    updatedEstimatedGrossProfits
  }
`;
