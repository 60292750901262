/* eslint-disable camelcase */
import React from 'react';
import Moment from 'moment';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { calculateMarginFromMarkup } from '@buildhero/math';
import { Grid, Typography, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { AddRecordButton } from 'components';
import { AppConstants } from 'utils/AppConstants';
import { convertForMathLib } from 'utils/mathLibrary';
import { purchasedItemsRows } from 'meta/Jobs/Invoice/review-report-tables';
import PurchasedItemLayout from 'meta/Jobs/Invoice/PurchasedItemForm';
import ErrorBoundaries from 'scenes/Error';
import { PricingStrategy } from 'utils/constants';
import ImageThumbnail from 'components/ImageThumbnail';
import PurchaseOrderLine from './PurchaseOrderLines';
import PurchaseOrderModal from './PurchaseOrderModal';
import { useSetStopLoading } from '../ReviewReport/ReviewReport.hook';

const PurchaseOrders = ({
  current,
  send,
  service,
  user,
  classes,
  isReviewReport,
  snackbarOn,
  isProcurementEnabled
}) => {
  const marginEnabled = useSelector(s => s.settings.pricingStrategy === PricingStrategy.MARGIN);
  const { context, value } = current;
  const setStopLoading = useSetStopLoading(service);
  if (isProcurementEnabled && context?.purchaseOrders?.length === 0) return null;
  /* Amount Calculation for Purchase Line Items */
  const purchaseOrders = context?.purchaseOrders?.map(purchaseOrder => {
    if (purchaseOrder?.purchaseOrderLines?.items?.length > 0) {
      const { purchaseOrderLines } = purchaseOrder;
      purchaseOrderLines.items.map(purchaseOrderLine => {
        const currentPurchaseLine = purchaseOrderLine;
        if (currentPurchaseLine && currentPurchaseLine.quantity && currentPurchaseLine.unitPrice) {
          currentPurchaseLine.amount = currentPurchaseLine.quantity * currentPurchaseLine.unitPrice;
          currentPurchaseLine.amount = currentPurchaseLine.amount.toFixed(2);
          currentPurchaseLine.marginValue = convertForMathLib(
            calculateMarginFromMarkup,
            currentPurchaseLine.markup
          );
        }
        return currentPurchaseLine;
      });
    }
    return purchaseOrder;
  });

  const PurchasedLayoutMeta = PurchasedItemLayout.entity.layouts.web;
  PurchasedLayoutMeta.buttons.cancel.action = () => send('CLOSE');

  const isEditable = isReviewReport && !context.freezeEdit;

  const purchasedItemsTableMeta = [...purchasedItemsRows(marginEnabled)];
  if (isEditable) {
    purchasedItemsTableMeta.push({
      id: 'actions',
      isCustom: true,
      type: 'actions',
      label: '',
      cellStyle: {
        float: 'right'
      }
    });
  }

  const getImagesUrl = imageUrlArray => {
    const processImageArray = imageUrlArray.map(imgKey => ({ fileUrl: imgKey }));
    return processImageArray;
  };

  const { cardHeader, cardText } = classes;

  const open = value?.po_item_new || value?.po_item_edited || false;
  let title = ' ';
  if (value.po_item_new) title = 'Add Receipt';
  if (value?.po_item_edited) title = 'Edit Receipt';

  return (
    <>
      <Grid container justify="flex-start" style={{ marginTop: 16 }} />
      {isEditable && <AddRecordButton label="+ Add receipt" handle={() => send('ADD_PO_ITEM')} />}
      <ErrorBoundaries>
        {purchaseOrders.map(receiptItem => (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ borderTop: '1px solid #e0e0e4' }}
          >
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item xs={10} sm={10} md={10} lg={10} xl={11}>
                <Grid container style={{ marginTop: 20 }} spacing={4} justify="space-around">
                  <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                    {receiptItem?.purchaseOrderReceipts?.items?.[0]?.imageUrl && (
                      <ImageThumbnail
                        image={{
                          fileUrl: receiptItem.purchaseOrderReceipts.items[0].imageUrl
                        }}
                        allImages={getImagesUrl(
                          R.pluck('imageUrl', receiptItem.purchaseOrderReceipts.items)
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item>
                    <Typography className={cardHeader}>Added by</Typography>
                    <Typography className={cardText}>
                      {receiptItem.addedBy || receiptItem.createdBy}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={cardHeader}>Vendor</Typography>
                    <Typography className={cardText}>{receiptItem.vendorName}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={cardHeader}>Receipt No</Typography>
                    <Typography className={cardText}>{receiptItem.receiptNumber}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={cardHeader}>P.O No</Typography>
                    <Typography className={cardText}>{receiptItem.poNumber}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={cardHeader}>Date</Typography>
                    <Typography className={cardText}>
                      {isNaN(receiptItem.dateOfPurchase)
                        ? Moment(receiptItem.dateOfPurchase).format(AppConstants.DATE_FORMAT)
                        : Moment.unix(parseInt(receiptItem.dateOfPurchase, 10)).format(
                            AppConstants.DATE_FORMAT
                          )}
                    </Typography>
                  </Grid>
                  <Grid item style={{ textAlign: 'center' }}>
                    <Typography className={cardHeader}>Total</Typography>
                    <Typography className={cardText}>
                      $
                      {receiptItem.totalAmountPreTax &&
                      typeof receiptItem.totalAmountPreTax === 'number'
                        ? receiptItem.totalAmountPreTax
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                        : receiptItem.totalAmountPreTax || 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={1}>
                {isEditable && (
                  <Grid item>
                    <Grid container justify="flex-end" alignContent="flex-end" direction="row">
                      <IconButton
                        aria-label="delete"
                        onClick={() =>
                          send('EDIT_PO_ITEM', {
                            data: receiptItem
                          })
                        }
                      >
                        <EditIcon style={{ fontSize: 18 }} />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() =>
                          send('DELETE_PO_ITEM', {
                            data: receiptItem
                          })
                        }
                      >
                        <DeleteIcon style={{ fontSize: 18 }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <PurchaseOrderLine
              receiptItem={receiptItem}
              setStopLoading={setStopLoading}
              user={user}
              current={current}
              snackbarOn={snackbarOn}
              send={send}
              isReviewReport={isReviewReport}
              marginEnabled={marginEnabled}
            />
          </Grid>
        ))}
      </ErrorBoundaries>
      {open && (
        <PurchaseOrderModal
          open={open}
          data={context.modalRecord}
          mode={value.po_item_new ? 'new' : 'edit'}
          title={title}
          send={send}
          service={service}
          user={user}
        />
      )}
    </>
  );
};

export default PurchaseOrders;
