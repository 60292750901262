import gql from 'graphql-tag';

const getAllCustomers = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      customersView(entityConnection: "Customer") {
        nextToken
        items {
          id
          customerName
        }
      }
    }
  }
`;

export default getAllCustomers;
