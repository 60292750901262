import React from 'react';
import { DialogTitle, Grid, IconButton, Typography, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const BODialogTitle = withStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  closeButton: {
    padding: 0,
    width: 24,
    height: 24
  },
  closeRight: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  }
}))(({children, classes, onClose, alignCloseRight, titleIcon, ...other}) => (
  <DialogTitle disableTypography className={classes.root} {...other}>
    {onClose && (
      <div className={alignCloseRight ? classes.closeRight : null}>
        <Typography variant="h3">
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon/>
          </IconButton>
        </Typography>
      </div>
    )}
    <Grid container alignItems="center">
      <Typography variant="h3" className={classes.title}>
        {children}
      </Typography>
    </Grid>
  </DialogTitle>
));

export default BODialogTitle;
