import * as R from 'ramda';
import { LineItemBillingStatus, VisitStatus } from 'utils/AppConstants';

export const filterNonBilledItems = R.filter(
  item =>
    item?.billingStatus !== LineItemBillingStatus.BILLED &&
    item?.billingStatus !== LineItemBillingStatus.DO_NOT_INVOICE
);

// TODO: Pre-filter labour items with 0 hours on the back end
export const filterNonBilledLabourItems = R.filter(labourItem => {
  const labourRateBillingHourLines = labourItem.labourRateBillingHourLines?.items || [];
  return R.any(item => {
    return item?.totalHours > 0 && item?.billingStatus !== LineItemBillingStatus.BILLED;
  })(labourRateBillingHourLines);
});

export const filterBillableVisits = R.filter(item =>
  [
    VisitStatus.COMPLETE,
    VisitStatus.ONHOLD,
    VisitStatus.CANCELED,
    VisitStatus.CONVERTED,
    VisitStatus.CLOSED
  ].includes(item?.status)
);
