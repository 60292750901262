export const documentHistoryMeta = [
  {
    id: 'fileUrl',
    isCustom: true,
    label: 'Document Link',
    type: 'customPDFLink'
  },
  {
    id: 'createdDate',
    label: 'Created On',
    type: 'dateOnly',
    headerStyle: {
      width: '20%'
    }
  }
];
