import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import SectionHeader from 'components/SectionHeader';
import { attachmentRows } from 'meta/attachment-layout';
import Table from '../Table';
import { getImageUrl } from 'utils';
import styles from './styles';

class OCRModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      record: null,
      crop: {
        unit: '%',
        width: 100,
        height: 30
      }
    };
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
    // As the image URL is now direct cloudinary url, canvas blocs due to corb
    image.crossOrigin = 'anonymous';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => this.setState({ src: reader.result }));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  handleRowActions = async (mode, record) => {
    if (mode === 'select') {
      const fileURL = record.fileUrl;
      if (fileURL) {
        const url = await getImageUrl(fileURL);
        this.setState({
          src: url,
          record
        });
      }
    }
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, 'croppedFile.jpeg');
      this.setState({ croppedImageUrl });
    }
  }

  submitCroppedImg() {
    this.props.selectionHandler(this.state.croppedImageUrl, this.state.record, this.state.src);
  }

  render() {
    const { crop, src } = this.state;
    const { optionList, caslKey, classes } = this.props;

    return (
      <>
        {src ? (
          <Grid item sm={12}>
            <SectionHeader title="Crop the image to only contains the text you want" />
            <Grid item sm={12} md={6} className={classes.cropperContainer}>
              <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            </Grid>
            <Button
              variant="contained"
              className={classes.submitImageButton}
              color="secondary"
              onClick={() => this.submitCroppedImg()}
            >
              Submit
            </Button>
          </Grid>
        ) : (
          <Grid item sm={12}>
            <SectionHeader title="Select an image from Property Attachments" />
            <Table
              tableId="receipts"
              captionAttributes={attachmentRows({})}
              queryResult={optionList}
              idField="id"
              dataPathInQueryResult="items"
              enableThumbnails
              showTableHeader={false}
              showRowButtons={['select']}
              rowActions={this.handleRowActions}
              noDataMsg="No images available"
              caslKey={caslKey}
            />
          </Grid>
        )}
      </>
    );
  }
}

export default withStyles(styles)(OCRModal);
