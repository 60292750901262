const styles = theme => ({
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 10,
    letterSpacing: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal'
  },
  subtitle: {
    fontFamily: theme.typography.fontFamily,
    color: '#3f3f3f',
    fontSize: 14,
    letterSpacing: 0.13,
    fontStyle: 'normal',
    fontStretch: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    paddingTop: 13
  },
  subject: {
    fontFamily: theme.typography.fontFamily,
    color: '#3f3f3f',
    fontSize: 12,
    letterSpacing: 0.1,
    fontStyle: 'normal',
    fontStretch: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    paddingTop: 7
  },
  content: {
    fontFamily: theme.typography.fontFamily,
    color: '#959595',
    fontSize: 14,
    letterSpacing: 0.24,
    fontWeight: 'normal',
    lineHeight: 'normal',
    paddingTop: 7,
    whiteSpace: 'pre-line',
    wordBreak: 'break-word'
  },
  footer: {
    fontFamily: theme.typography.fontFamily,
    color: '#959595',
    fontSize: 12,
    letterSpacing: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    paddingTop: 7,
    paddingBottom: 7
  },
  iconStyle: {
    fontSize: 20,
    color: '#646464'
  },
  iconButton: {
    marginTop: 14,
    paddingTop: 8,
    paddingBottom: 8
  },
  buttonOutlinedSecondary: {
    border: 'none',
    textTransform: 'unset',
    paddingTop: 0,
    paddingLeft: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.3,
    '&:hover': {
      border: 'none',
      backgroundColor: '#fff'
    },
    '&:disabled': {
      border: 'none',
      backgroundColor: '#fff'
    }
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
});

export default styles;
