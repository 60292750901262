import AmplifyService from 'services/AmplifyService';
import gql from 'graphql-tag';
import { getPricebookEntryByProductSortKey } from 'components/Tasks/components/useLazyFetchPricebookEntry';
import getCompany from '../../graphql/onboarding/queries/getCompany';
import getCompanyInfo from '../../graphql/onboarding/queries/getCompanyInfo';
import getCompanyWithDepartmentSKill from '../../graphql/onboarding/queries/getCompanyWithDepartmentSKills';
import getCompanyWithAppRoles from '../../graphql/onboarding/queries/getCompanyRoles';
import getCompanyWithEmployees from '../../graphql/onboarding/queries/getCompanyEmployees';
import getCompanyWithForms from '../../graphql/queries/getCompanyWithForms';
import getDepartmentWithSkillEmployees from '../../graphql/onboarding/queries/getDepartmentWithSkillEmployees';
import getEmployee from '../../graphql/onboarding/queries/getEmployee';
import saveEmployeeMutation from '../../graphql/mutations/employee/saveEmployee';
import addEmployeesToCompany from '../../graphql/mutations/employee/addEmployeesToCompany';
import getCustomFieldsDefinitions from '../../graphql/onboarding/queries/getCustomFieldsDefinitions';
import deleteDepartment from '../../graphql/onboarding/mutations/deleteDepartment';
import deleteDepartmentForEmployee from '../../graphql/onboarding/mutations/deleteDepartmentForEmployee';
import deleteSkill from '../../graphql/onboarding/mutations/deleteSkill';
import deleteMapping from '../../graphql/common/mutations/deleteMapping';
import deleteRole from '../../graphql/onboarding/mutations/deleteRole';
import companySchema from '../../mutation-schema/onboarding/company-schema';
import departmentSchema from '../../mutation-schema/onboarding/department-schema';
import employeeSchema from '../../mutation-schema/onboarding/employee-schema';
import loginSchema from '../../mutation-schema/onboarding/login-schema';
import inactivateEmployeeSchema from '../../mutation-schema/onboarding/inactivate-employee-schema';
import {
  customFieldsSchema,
  jobTagSchema,
  invoiceTagSchema,
  purchaseOrderTagSchema,
  quoteTagSchema,
  projectTypeSchema,
  projectSubTypeSchema,
  productCategorySchema
} from '../../mutation-schema/onboarding/customFields-schema';
import mutationQuery from '../../graphql/common/mutations/batchCreateEntityData';
import roleSchema from '../../mutation-schema/onboarding/role-schema';
import permissionSchema from '../../mutation-schema/onboarding/permission-schema';
import CommonService from '../Common/CommonService';
import updateCompany from '../../graphql/common/mutations/updateCompany';
import getPricebookForCompany from '../../graphql/common/queries/getPricebooksForCompany';
import getDefaultPricebookForCompany from '../../graphql/common/queries/getDefaultPricebookForCompany';
import addPricebookToCompany_new from '../../graphql/common/mutations/addPricebookToCompany_new';
import updatePriceBook from '../../graphql/pricebook/mutations/updatePriceBook';
import updatePricebookEntry from '../../graphql/jobs/mutations/updatePricebookEntry';
import addVendor from '../../graphql/mutations/vendor/addVendor';
import getCompanyVendors from '../../graphql/common/queries/getCompanyVendors';
import updateVendor from '../../graphql/mutations/vendor/updateVendor';
import getAllCustomersInfo from '../../graphql/common/queries/getAllCustomersInfo';
import getAllAuditLogForCompanyInRange from '../../graphql/common/queries/getAuditLogsForCompany';
import addForm from '../../graphql/common/mutations/addForm';
import getForm from '../../graphql/common/queries/getForm';
import updateForm from '../../graphql/common/mutations/updateForm';
import deleteForm from '../../graphql/common/mutations/deleteForm';
import deleteFormDefinition from '../../graphql/common/mutations/deleteFormDefinition';
import checkUsernameExists from '../../graphql/onboarding/queries/checkUsernameExists';
import getPayrollHourTypes from '../../graphql/common/queries/getPayrollHourTypes';
import getCompanySettingsQuery from '../../graphql/common/queries/getCompanySettings';
import addCompanySetting from '../../graphql/common/mutations/addCompanySettings';
import addAfterHoursSettingsToCompany from '../../graphql/common/mutations/addAfterHoursSettingsToCompany';
import addCustomerSignatureSettingsToCompany from '../../graphql/common/mutations/addCustomerSignatureSettingsToCompany';
import updateCompanySetting from '../../graphql/common/mutations/updateCompanySetting';
import createJobTag from '../../graphql/onboarding/mutations/createJobTag';
import deleteJobTag from '../../graphql/onboarding/mutations/deleteJobTag';
import createInvoiceTag from '../../graphql/onboarding/mutations/createInvoiceTag';
import createPurchaseOrderTag from '../../graphql/onboarding/mutations/createPurchaseOrderTag';
import createQuoteTag from '../../graphql/onboarding/mutations/createQuoteTag';
import createProjectType from '../../graphql/onboarding/mutations/createProjectType';
import createProjectSubType from '../../graphql/onboarding/mutations/createProjectSubType';
import createProductCategory from '../../graphql/onboarding/mutations/createProductCategory.js';
import deleteInvoiceTag from '../../graphql/onboarding/mutations/deleteInvoiceTag';
import deletePurchaseOrderTag from '../../graphql/onboarding/mutations/deletePurchaseOrderTag';
import deleteQuoteTag from '../../graphql/onboarding/mutations/deleteQuoteTag';
import deleteProjectType from '../../graphql/onboarding/mutations/deleteProjectType';
import deleteProjectSubType from '../../graphql/onboarding/mutations/deleteProjectSubType';
import deleteProductCategory from '../../graphql/onboarding/mutations/deleteProductCategory';
import { generateCompanySortKey, removeEmptyValues } from '../../utils';
import getCompanyDepartments from '../../graphql/common/queries/getCompanyDepartments';
import addDepartmentsToCompany from '../../graphql/company/mutations/addDepartmentsToCompany';
import softDeleteDepartment from '../../graphql/company/mutations/softDeleteDepartment';
import updateDepartment from '../../graphql/company/mutations/updateDepartment';
import updateCompanySkills from '../../graphql/common/mutations/updateCompanySkills';
import saveAssetTypeToCompany from '../../graphql/company/mutations/saveAssetTypeToCompany';
import deleteAssetTypeById from '../../graphql/company/mutations/deleteAssetTypeById';
import getCompanyDepartmentsAndEmployees from '../../graphql/onboarding/queries/getCompanyDepartmentsAndEmployees';

export default class CompanyService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
    this.CommonService = new CommonService();
  }

  getCompany = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };
    const response = await this.api.query(getCompany, params);
    return response;
  };

  getCompanyInfo = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };
    const response = await this.api.query(getCompanyInfo, params);
    return response;
  };

  getCompanyWithDepartmentSKill = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getCompanyWithDepartmentSKill, params);
    return response;
  };

  // for MultiOffice, need all departments without filters in the settings page
  getAllDepartmentsWithSkills = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getCompanyDepartments, params);
    return response;
  };

  getAllDepartmentsAndEmployees = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };
    const response = await this.api.query(getCompanyDepartmentsAndEmployees, params);
    return response;
  };

  getCompanyWithAppRoles = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getCompanyWithAppRoles, params);
    return response;
  };

  getEmployeesForVisitsTab = async (partitionKey, sortKey) => {
    return this.api.query(
      gql`
        query getCompany($partitionKey: String!, $sortKey: String!) {
          getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
            id
            employees(entityConnection: "Employee") {
              items {
                id
                firstName
                lastName
                code
                name
                code
                isTech
                isSales
                isCrewMember
                status
                nickName
                isActive
                departments(entityConnection: "Department") {
                  items {
                    mappedEntityId
                  }
                }
              }
            }
          }
        }
      `,
      { partitionKey, sortKey }
    );
  };

  getEmployeesForReviewReport = async (partitionKey, sortKey) => {
    return this.api.query(
      gql`
        query getCompany($partitionKey: String!, $sortKey: String!) {
          getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
            id
            employees(entityConnection: "Employee") {
              items {
                id
                labourTypeId
                billingRates(entityConnection: "BillingRate") {
                  items {
                    id
                    rate
                    hourTypeId
                  }
                }
                payrollCosts(entityConnection: "PayrollCost") {
                  items {
                    id
                    cost
                    hourTypeId
                  }
                }
                firstName
                lastName
                code
                name
                code
                isTech
                isSales
                isCrewMember
                status
                nickName
                isActive
              }
            }
          }
        }
      `,
      { partitionKey, sortKey }
    );
  };

  getCompanyWithEmployees = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getCompanyWithEmployees, params);
    return response;
  };

  getCompanyWithForms = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getCompanyWithForms, params);
    return response;
  };

  getDepartmentWithSkillEmployees = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getDepartmentWithSkillEmployees, params);
    return response;
  };

  getEmployee = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getEmployee, params);
    return response;
  };

  getCustomFieldsDefinitions = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getCustomFieldsDefinitions, params);
    return response;
  };

  deleteDepartment = async (partitionKey, sortKey) => {
    const params = {
      input: {
        partitionKey,
        sortKey
      }
    };

    const response = await this.api.mutate(deleteDepartment, params);
    return response;
  };

  deleteDepartmentForEmployee = async (employeeId, departmentId) => {
    const params = {
      employeeId,
      departmentId
    };

    const response = await this.api.mutate(deleteDepartmentForEmployee, params);
    return response;
  };

  deleteSkillAndMappings = async (partitionKey, sortKey, mappings) => {
    const params = {
      input: {
        partitionKey,
        sortKey
      }
    };
    const parentMappingParams = {
      input: {
        partitionKey,
        sortKey: mappings.sortKey
      }
    };

    const childMappingParams = {
      input: {
        partitionKey,
        sortKey: mappings.invertedSortKey
      }
    };
    let response;
    const childResponse = await this.api.mutate(deleteMapping, childMappingParams);
    if (childResponse) {
      const parentResponse = await this.api.mutate(deleteMapping, parentMappingParams);
      if (parentResponse) {
        response = await this.api.mutate(deleteSkill, params);
      }
    }
    return response;
  };

  deleteCompanySkill = async (partitionKey, sortKey) => {
    const params = {
      input: {
        partitionKey,
        sortKey
      }
    };
    const response = await this.api.mutate(deleteSkill, params);
    return response;
  };

  deleteRole = async (partitionKey, sortKey) => {
    const params = {
      input: {
        partitionKey,
        sortKey
      }
    };
    const response = await this.api.mutate(deleteRole, params);
    return response;
  };

  updateCompany = async values => {
    const structuredValues = companySchema(values);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };
    const response = await this.api.mutate(mutationQuery, dataSet);
    return response;
  };

  updateCompanySkills = async (partitionKey, data) => {
    const params = {
      partitionKey,
      data
    };
    const response = await this.api.mutate(updateCompanySkills, params);
    return response;
  };

  saveAssetTypeToCompany = async values => {
    const payload = {
      partitionKey: values.tenantId,
      data: {
        companyId: values.tenantCompanyId,
        assetType: values.assetTypes.map(tag => ({
          id: tag.id,
          tagName: tag.tagName,
          sortOrder: tag.sortOrder
        }))
      }
    };
    const response = await this.api.mutate(saveAssetTypeToCompany, payload);
    return response;
  };

  deleteAssetTypeById = async (partitionKey, id) => {
    const payload = {
      partitionKey,
      id
    };
    const response = await this.api.mutate(deleteAssetTypeById, payload);
    return response;
  };

  mutateDepartmentSkill = async values => {
    const structuredValues = departmentSchema(values);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };

    const response = await this.api.mutate(mutationQuery, dataSet);
    return response;
  };

  mutateEmployee = async values => {
    const structuredValues = employeeSchema(values);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };

    const response = await this.api.mutate(mutationQuery, dataSet);
    return response;
  };

  // For Buildops Invite and Deactivate button
  inviteEmployee = async (employeeDetails, companyInfo, actionType) => {
    const structuredValues = loginSchema(employeeDetails, companyInfo, actionType);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };

    const response = await this.api.mutate(mutationQuery, dataSet);
    return response;
  };

  inactivateEmployee = async employeeDetails => {
    const structuredValues = inactivateEmployeeSchema(employeeDetails);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };
    const response = await this.api.mutate(mutationQuery, dataSet);
    return response;
  };

  mutateRole = async (roleName, permissionsString, Company) => {
    const structuredValues = roleSchema(roleName, permissionsString, Company);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };

    const response = await this.api.mutate(mutationQuery, dataSet);
    return response;
  };

  mutatePermission = async (permissions, Company) => {
    const structuredValues = permissionSchema(permissions, Company);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };

    const response = await this.api.mutate(mutationQuery, dataSet);
    return response;
  };

  mutateCustomFields = async values => {
    const structuredValues = customFieldsSchema(values);
    let response;
    if (structuredValues.instructionSet && structuredValues.instructionSet.length > 0) {
      const dataSet = {
        instructionSet: JSON.stringify(structuredValues.instructionSet),
        data: JSON.stringify(structuredValues.data)
      };

      response = await this.api.mutate(mutationQuery, dataSet);
    } else {
      throw new Error('No data to save');
    }

    return response;
  };

  mutateCustomFieldJobTags = async values => {
    const structuredValues = jobTagSchema(values);
    let response;
    if (structuredValues.data && structuredValues.data.length > 0) {
      response = await this.api.mutate(createJobTag, structuredValues);
    } else {
      throw new Error('No data to save');
    }
    return response;
  };

  mutateCustomFieldInvoiceTags = async values => {
    const structuredValues = invoiceTagSchema(values);
    let response;
    if (Array.isArray(structuredValues?.data) && structuredValues.data.length > 0) {
      response = await this.api.mutate(createInvoiceTag, structuredValues);
    } else {
      throw new Error('No data to save');
    }
    return response;
  };

  mutateCustomFieldPurchaseOrderTags = async values => {
    const structuredValues = purchaseOrderTagSchema(values);
    let response;
    if (Array.isArray(structuredValues?.data) && structuredValues.data.length > 0) {
      response = await this.api.mutate(createPurchaseOrderTag, structuredValues);
    } else {
      throw new Error('No data to save');
    }
    return response;
  };

  mutateCustomFieldQuoteTags = async values => {
    const structuredValues = quoteTagSchema(values);
    let response;
    if (Array.isArray(structuredValues?.data) && structuredValues.data.length > 0) {
      response = await this.api.mutate(createQuoteTag, structuredValues);
    } else {
      throw new Error('No data to save');
    }
    return response;
  };

  mutateProjectTypes = async values => {
    const structuredValues = projectTypeSchema(values);
    let response;
    if (
      Array.isArray(structuredValues?.data.projectTypes) &&
      structuredValues.data.projectTypes.length > 0
    ) {
      response = await this.api.mutate(createProjectType, structuredValues);
    } else {
      throw new Error('No data to save');
    }
    return response;
  };

  mutateProjectSubTypes = async values => {
    const structuredValues = projectSubTypeSchema(values);
    let response;
    if (
      Array.isArray(structuredValues?.data.projectSubTypes) &&
      structuredValues.data.projectSubTypes.length > 0
    ) {
      response = await this.api.mutate(createProjectSubType, structuredValues);
    } else {
      throw new Error('No data to save');
    }
    return response;
  };

  mutateProductCategories = async values => {
    const structuredValues = productCategorySchema(values);
    let response;
    if (
      Array.isArray(structuredValues?.data?.productCategory) &&
      structuredValues.data.productCategory.length > 0
    ) {
      response = await this.api.mutate(createProductCategory, structuredValues);
    } else {
      throw new Error('No data to save');
    }
    return response;
  };

  deleteJobTags = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };

    const response = await this.api.mutate(deleteJobTag, params);
    return response;
  };

  deleteInvoiceTags = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };

    const response = await this.api.mutate(deleteInvoiceTag, params);
    return response;
  };

  deletePurchaseOrderTags = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };

    const response = await this.api.mutate(deletePurchaseOrderTag, params);
    return response;
  };

  deleteQuoteTags = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };

    const response = await this.api.mutate(deleteQuoteTag, params);
    return response;
  };

  deleteProjectType = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };

    const response = await this.api.mutate(deleteProjectType, params);
    return response;
  };

  deleteProjectSubType = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };

    const response = await this.api.mutate(deleteProjectSubType, params);
    return response;
  };

  deleteProductCategory = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };

    const response = await this.api.mutate(deleteProductCategory, params);
    return response;
  };

  deleteCustomField = async (partitionKey, sortKey) =>
    this.CommonService.deleteTag(partitionKey, sortKey);

  saveEmployee = async params => {
    const response = await this.api.mutate(saveEmployeeMutation, params);
    return response;
  };

  addEmployeesToCompany = async (partitionKey, data) => {
    const params = {
      partitionKey,
      data
    };
    const response = await this.api.mutate(addEmployeesToCompany, params);
    return response;
  };

  updateCompanyForInvoice = async (partitionKey, data) => {
    const params = {
      partitionKey,
      data
    };
    const response = await this.api.mutate(updateCompany, params);
    return response;
  };

  getPricebooksForCompany = async (
    partitionKey,
    tenantCompanySortKey,
    filter = {},
    sortBy,
    sortOrder,
    entriesNextToken
  ) => {
    const params = {
      partitionKey,
      sortKey: tenantCompanySortKey,
      nextToken: entriesNextToken,
      filter,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    const response = await this.api.query(getPricebookForCompany, params);
    return response;
  };

  getDefaultPricebookForCompany = async (partitionKey, tenantCompanySortKey) => {
    const params = {
      partitionKey,
      sortKey: tenantCompanySortKey
    };

    const response = await this.api.query(getDefaultPricebookForCompany, params);
    return response;
  };

  addPricebookToCompany = async (partitionKey, data) => {
    const params = {
      partitionKey,
      data
    };

    const response = await this.api.mutate(addPricebookToCompany_new, params);
    return response;
  };

  updatePriceBook = async (partitionKey, data) => {
    const params = {
      partitionKey,
      data
    };

    const response = await this.api.mutate(updatePriceBook, params);
    return response;
  };

  updatePricebookEntry = async (partitionKey, data) => {
    const params = {
      partitionKey,
      data
    };

    const response = await this.api.mutate(updatePricebookEntry, params);
    return response;
  };

  getPricebookEntryByProductSortKey = async (pricebookId, productSortKey) => {
    const params = {
      pricebookId,
      productSortKey
    };

    const response = await this.api.query(getPricebookEntryByProductSortKey, params);
    return response;
  };

  addVendor = async values => {
    const data = {
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      city: values.city,
      state: values.state,
      zipcode: values.zipcode,
      fax: values.fax,
      name: values.name,
      notes: values.notes,
      primaryContact: values.primaryContact,
      tenantCompanyId: values.tenantCompanyId,
      email: values.email,
      phone: values.phone,
      type: values.type,
      syncStatus: values.syncStatus
    };
    const nonEmptyData = removeEmptyValues(data);
    const params = {
      partitionKey: values.tenantId,
      data: nonEmptyData
    };

    const response = await this.api.mutate(addVendor, params);
    return response;
  };

  getVendors = async (partitionKey, companySortKey) => {
    const params = {
      partitionKey,
      sortKey: companySortKey
    };

    const response = await this.api.query(getCompanyVendors, params);
    return response;
  };

  updateVendor = async values => {
    const data = {
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      city: values.city,
      state: values.state,
      zipcode: values.zipcode,
      fax: values.fax,
      name: values.name,
      notes: values.notes,
      primaryContact: values.primaryContact,
      email: values.email,
      phone: values.phone,
      type: values.type,
      isActive: values.isActive === 'true' || values.isActive === true || false,
      id: values.id,
      version: values.version,
      syncStatus: values.syncStatus
    };

    const params = {
      partitionKey: values.tenantId,
      data
    };

    const response = await this.api.mutate(updateVendor, params);
    return response;
  };

  getAllAuditLog = async (partitionKey, sortKey, startTime, endTime) => {
    const params = {
      partitionKey,
      sortKey,
      startTime,
      endTime
    };
    const { data, error } = await this.api.query(getAllAuditLogForCompanyInRange, params);
    if (data && data.getCompany && data.getCompany.allAuditLogs) {
      return data.getCompany.allAuditLogs.items || [];
    }
    if (error) {
      throw error;
    }
  };

  addForm = async values => {
    const data = {
      name: values.name,
      description: values.description,
      formDefinitionJson: values.formDefinitionJson,
      associatedEntityType: values.associatedEntityType,
      viewType: values.viewType,
      tenantId: values.tenantId,
      tenantCompanyId: values.tenantCompanyId,
      projectId: values.projectId || null
    };

    const nonEmptyData = removeEmptyValues(data);
    const params = {
      partitionKey: values.tenantId,
      data: nonEmptyData
    };

    const response = await this.api.mutate(addForm, params);
    return response;
  };

  getForm = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getForm, params);
    return response;
  };

  updateForm = async values => {
    const data = {
      id: values.id,
      name: values.name,
      description: values.description,
      viewType: values.viewType,
      formDefinitionJson: values.formDefinitionJson,
      associatedEntityType: values.associatedEntityType,
      publish: values.publish
    };
    const nonEmptyData = removeEmptyValues(data);
    nonEmptyData.version = values.version || 0;

    const params = {
      partitionKey: values.tenantId,
      data: nonEmptyData
    };

    const response = await this.api.mutate(updateForm, params);
    return response;
  };

  deleteForm = async (partitionKey, sortKey) => {
    const params = {
      input: {
        partitionKey,
        sortKey
      }
    };

    const response = await this.api.mutate(deleteForm, params);
    return response;
  };

  deleteFormDefinition = async (partitionKey, sortKey) => {
    const params = {
      input: {
        partitionKey,
        sortKey
      }
    };

    const response = await this.api.mutate(deleteFormDefinition, params);
    return response;
  };

  getAllCustomerProperties = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getAllCustomersInfo, params);
    return response;
  };

  checkUsernameExists = async username => {
    const params = {
      username
    };

    const response = await this.api.query(checkUsernameExists, params);
    return response;
  };

  getPayrollHourTypes = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const { data } = await this.api.query(getPayrollHourTypes, params);
    const payrollHourTypes = data?.getCompany?.payrollHourTypes?.items;
    return { data: payrollHourTypes || [] };
  };

  getCompanySettings = async (partitionKey, companyId, filter) => {
    const params = {
      partitionKey,
      filter,
      sortKey: generateCompanySortKey(partitionKey, companyId)
    };
    const response = await this.api.query(getCompanySettingsQuery, params);
    return response;
  };

  addCompanySettings = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(addCompanySetting, params);
    return response;
  };

  addAfterHoursSettingsToCompany = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(addAfterHoursSettingsToCompany, params);
    return response;
  };

  addCustomerSignatureSettingsToCompany = async values => {
    const params = {
      data: values
    };
    const response = await this.api.mutate(addCustomerSignatureSettingsToCompany, params);
    return response;
  };

  updateCompanySettings = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(updateCompanySetting, params);
    return response;
  };

  addDepartmentsToCompany = async (partitionKey, data) => {
    const params = {
      partitionKey,
      data
    };
    const response = await this.api.mutate(addDepartmentsToCompany, params);
    return response;
  };

  updateDepartment = async (partitionKey, data) => {
    const params = {
      partitionKey,
      data
    };
    const response = await this.api.mutate(updateDepartment, params);
    return response;
  };

  softDeleteDepartment = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };
    const response = await this.api.mutate(softDeleteDepartment, params);
    return response;
  };
}
