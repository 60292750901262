import React, { useCallback, useState } from 'react';
import { Hub } from 'aws-amplify';
import gql from 'graphql-tag';
import { XGrid, Spinner } from 'components';
import { ExitToApp } from '@material-ui/icons';
import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import QuickFilter from '../../QuickFilter';
import '../../../Jobs/JobList.css';

const GET_EMPLOYEES = gql`
  query getEmployeeList(
    $tenantId: String
    $filter: TableFilterInput
    $pagination: PaginationInput
    $sorting: [TableSortingInput]
  ) {
    data: getEmployeeList(
      tenantId: $tenantId
      filter: $filter
      pagination: $pagination
      sorting: $sorting
    ) {
      rowCount
      items
    }
  }
`;

const columns = [
  {
    field: 'quickFilter',
    headerName: '',
    hide: true,
    sortable: false,
    filterable: true,
    metaOnly: true
  },
  {
    field: 'name',
    headerName: 'Employee Name',
    width: 300
  },
  {
    field: 'username',
    headerName: 'Username',
    width: 300
  },
  {
    field: 'isAdmin',
    headerName: 'Admin',
    type: 'boolean',
    width: 100
  },
  {
    field: 'impersonate',
    headerName: 'Impersonate',
    sortable: false,
    filterable: false,
    width: 200,
    renderCell: ({ row }) =>
      row.username && (
        <div className="LinkButton">
          <ExitToApp fontSize="small" />
          Impersonate
        </div>
      )
  }
];

const ConfirmImpersonate = username => ({
  title: `Impersonate ${username}`,
  body: (
    <>
      Are you sure you want to impersonate <b>{username}</b>?
      <br />
      This will log you out.
    </>
  ),
  buttonLabel: 'Yes'
});

const Users = ({ auth, tenantId }) => {
  const [loading, setLoading] = useState(false);
  const confirmCtx = useConfirmModal();

  const handleCellClick = useCallback(async ({ row, field }) => {
    const { username } = row;
    if (username && field === 'impersonate') {
      if (await confirmCtx.confirm(ConfirmImpersonate(row.username))) {
        setLoading(true);
        auth.logout('impersonation');
        return auth
          .impersonateUser(row.username)
          .then(() => auth.checkAuthentication())
          .then(user => {
            // reload auth
            Hub.dispatch('auth', { event: 'signIn', data: user }, 'Auth');
            setLoading(false);
            window.location.reload(); // reload to reset LD flags, etc
          })
          .catch(err => {
            console.error(err);
            setLoading(false);
          });
      }
    }
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <XGrid
      columns={columns}
      entityTypeName="Employees"
      query={GET_EMPLOYEES}
      onCellClick={handleCellClick}
      toolbarCustomComponent={QuickFilter}
      tenantIdOverride={tenantId}
    />
  );
};

export default Users;
