const DOMAIN = 'buildops.com';

export const ReactEnvironment = {
  LOCAL: 'local',
  DEV: 'dev',
  QA: 'qa',
  STAGING: 'staging',
  PROD: 'prod'
};

//* * ============= Cloudinary config ============= */
const CLOUDINARY_NONPROD_API_KEY = '614748165579445';
const CLOUDINARY_PROD_API_KEY = '711654689479779';
const CLOUDINARY_CLOUD_NAME = 'buildops';
const CLOUDINARY_PROD_CLOUD_NAME = 'gobuildops';

//* * ============= LaunchDarkly Anonymous ============= */
const LAUNCH_DARKLY_ANONYMOUS_KEY = '1fdbf2b8-308a-4265-8814-f4f0ebfada2e';

//* * ============= CKEditor License Key ============= */
const CKEDITOR_PRODUCTION_LICENSE_KEY = '4scJFp6sBk5LlsUMtvltI2hB7PVWg1NyUPpeaSf6TxFsejNqjYBk';
const CKEDITOR_DEVELOPMENT_LICENSE_KEY = 'alUA2V/BycHt1j3s7QLbF6htnfdP/EhnhCfcj58NP3z2q1VQZG8b';

//* * ============= Algolia ============= */
const algoliaAppKey = '7J8RD52LHY';
const algoliaProdAppKey = 'UXP532H1YB';

//* * ============= graphql ============= */
const GQLEndpoint = {
  [ReactEnvironment.LOCAL]: 'localhost:8000/graphql',
  [ReactEnvironment.DEV]: `graphql.dev.${DOMAIN}/graphql`,
  [ReactEnvironment.QA]: `graphql.qa.${DOMAIN}/graphql`,
  [ReactEnvironment.STAGING]: `graphql.staging.${DOMAIN}/graphql`,
  [ReactEnvironment.PROD]: `graphql.live.${DOMAIN}/graphql`
};

//* * ============= PM api ============= */
const PMApiEndpoint = {
  [ReactEnvironment.LOCAL]: 'http://localhost:3001/',
  [ReactEnvironment.DEV]: 'https://projectmgmt.dev.buildops.com/',
  [ReactEnvironment.QA]: 'https://projectmgmt.qa.buildops.com/',
  [ReactEnvironment.STAGING]: 'https://projectmgmt.staging.buildops.com/',
  [ReactEnvironment.PROD]: 'https://projectmgmt.live.buildops.com/'
};

//* * ============= rest ============= */
const RestApiEndpoint = {
  [ReactEnvironment.LOCAL]: 'http://localhost:3002',
  [ReactEnvironment.DEV]: 'https://rest-api.dev.buildops.com',
  [ReactEnvironment.QA]: 'https://rest-api.qa.buildops.com',
  [ReactEnvironment.STAGING]: 'https://rest-api.staging.buildops.com',
  [ReactEnvironment.PROD]: 'https://rest-api.live.buildops.com'
};

//* * ============= Cognito config ============= */
const localCognitoConfig = {
  REGION: 'us-east-1',
  USER_POOL_ID: 'us-east-1_ycHR3UNB1',
  APP_CLIENT_ID: '3912a9dqjlr9fkcrdclblc4bjq',
  IDENTITY_POOL_ID: 'us-east-1:606be91f-64f9-4791-aedb-fb37f5cd608d'
};

const devCognitoConfig = {
  REGION: 'us-east-1',
  USER_POOL_ID: 'us-east-1_ycHR3UNB1',
  APP_CLIENT_ID: '3912a9dqjlr9fkcrdclblc4bjq',
  IDENTITY_POOL_ID: 'us-east-1:606be91f-64f9-4791-aedb-fb37f5cd608d'
};

const qaCognitoConfig = {
  REGION: 'us-east-1',
  USER_POOL_ID: 'us-east-1_UpsYZW2U5',
  APP_CLIENT_ID: '7bej9615pd6jrqpj1i6a3004oe',
  IDENTITY_POOL_ID: 'us-east-1:f19e59ff-4f37-4e72-8e6b-aeb42593591d'
};

const prodCognitoConfig = {
  REGION: 'us-east-1',
  USER_POOL_ID: 'us-east-1_ENDxhUQ8j',
  APP_CLIENT_ID: '605iblcjhr7kd5bcip674bgsf5',
  IDENTITY_POOL_ID: 'us-east-1:fcfd4e14-b1a0-4467-870d-923b386e0b5a'
};

const CognitoConfig = {
  [ReactEnvironment.LOCAL]: localCognitoConfig,
  [ReactEnvironment.DEV]: devCognitoConfig,
  [ReactEnvironment.QA]: qaCognitoConfig,
  [ReactEnvironment.STAGING]: prodCognitoConfig,
  [ReactEnvironment.PROD]: prodCognitoConfig
};

//* * ============= S3 config ============= */

const localS3Config = {
  REGION: 'us-east-1',
  BUCKET: 'bh-tenant-content-dev'
};

const devS3Config = {
  REGION: 'us-east-1',
  BUCKET: 'bh-tenant-content-dev'
};

const qaS3Config = {
  REGION: 'us-east-1',
  BUCKET: 'bh-tenant-content-qa'
};

const stagingS3Config = {
  REGION: 'us-east-1',
  BUCKET: 'bh-tenant-content-staging'
};

const prodS3Config = {
  REGION: 'us-east-1',
  BUCKET: 'bh-tenant-content-prod'
};

const S3Config = {
  [ReactEnvironment.LOCAL]: localS3Config,
  [ReactEnvironment.DEV]: devS3Config,
  [ReactEnvironment.QA]: qaS3Config,
  [ReactEnvironment.STAGING]: stagingS3Config,
  [ReactEnvironment.PROD]: prodS3Config
};

//* * ============= AppSync config ============= */
const localAppsyncConfig = {
  aws_project_region: 'us-east-1',
  aws_exportEndpoint: `https://api.dev.${DOMAIN}`,
  aws_appsync_graphqlEndpoint: `http://${GQLEndpoint[ReactEnvironment.LOCAL]}`,

  aws_appsync_region: 'us-east-1',
  aws_appsync_graphqlHeaders: async () => ({
    'Accept-Encoding': 'gzip, deflate'
  })
};

const devAppsyncConfig = {
  aws_project_region: 'us-east-1',
  aws_exportEndpoint: `https://api.dev.${DOMAIN}`,
  aws_appsync_graphqlEndpoint: `https://${GQLEndpoint[ReactEnvironment.DEV]}`,

  aws_appsync_region: 'us-east-1',
  aws_appsync_graphqlHeaders: async () => ({
    'Accept-Encoding': 'gzip, deflate'
  })
};

const qaAppsyncConfig = {
  aws_project_region: 'us-east-1',
  aws_exportEndpoint: `https://api.qa.${DOMAIN}`,
  aws_appsync_graphqlEndpoint: `https://${GQLEndpoint[ReactEnvironment.QA]}`,

  aws_appsync_region: 'us-east-1',
  aws_appsync_graphqlHeaders: async () => ({
    'Accept-Encoding': 'gzip, deflate'
  })
};

const stagingAppsyncConfig = {
  aws_project_region: 'us-east-1',
  aws_exportEndpoint: `https://api.staging.${DOMAIN}`,
  aws_appsync_graphqlEndpoint: `https://${GQLEndpoint[ReactEnvironment.STAGING]}`,

  aws_appsync_region: 'us-east-1',
  aws_appsync_graphqlHeaders: async () => ({
    'Accept-Encoding': 'gzip, deflate'
  })
};

const prodAppsyncConfig = {
  aws_project_region: 'us-east-1',
  aws_exportEndpoint: `https://api.live.${DOMAIN}`,
  aws_appsync_graphqlEndpoint: `https://${GQLEndpoint.prod}`,

  aws_appsync_region: 'us-east-1',
  aws_appsync_graphqlHeaders: async () => ({
    'Accept-Encoding': 'gzip, deflate'
  })
};

const AppsyncConfig = {
  [ReactEnvironment.LOCAL]: localAppsyncConfig,
  [ReactEnvironment.DEV]: devAppsyncConfig,
  [ReactEnvironment.QA]: qaAppsyncConfig,
  [ReactEnvironment.STAGING]: stagingAppsyncConfig,
  [ReactEnvironment.PROD]: prodAppsyncConfig
};

//* * ============= local ============= */
const localEnvironmentConfig = {
  s3: S3Config[ReactEnvironment.LOCAL],
  appsync: AppsyncConfig[ReactEnvironment.LOCAL],
  pmapi: PMApiEndpoint[ReactEnvironment.LOCAL],
  restapi: RestApiEndpoint[ReactEnvironment.LOCAL],
  ckeditorLicenseKey: CKEDITOR_DEVELOPMENT_LICENSE_KEY,
  // Change cognito config to proper environment to do local development for higher environments.
  cognito: CognitoConfig[ReactEnvironment.LOCAL],
  algoliaAppKey,
  subscriptionEndpoint: `ws://${GQLEndpoint[ReactEnvironment.LOCAL]}`,
  publicUrlEndpoint: 'https://4ooo0tquya.execute-api.us-east-1.amazonaws.com/dev/public/getDetails',
  CLOUDINARY_API_KEY: CLOUDINARY_NONPROD_API_KEY,
  CLOUDINARY_UPLOAD_URL: `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/upload`,
  CLOUDINARY_IMAGE_URL: `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/fl_force_strip/public/`,
  CLOUDINARY_VIDEO_URL: `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/video/upload/public/`,
  CLOUDINARY_RAW_DOWNLOAD_URL: `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/raw/upload/public/`,
  CLOUDINARY_THUMBNAIL_URL: `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/c_thumb,w_200/public/`,
  CLOUDINARY_PROFILE_URL: `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,b_rgb:262c35/public/`,
  launchdarklyClientId: '6011a60a2a25290a8622568b',
  launchdarklyAnonymousId: LAUNCH_DARKLY_ANONYMOUS_KEY,
  METABASE_URL: 'https://metabase.dev.buildops.com',
  ENTERPRISE_DOMAIN: 'localhost'
};

//* * ============= Dev - Development ============= */
const devEnvironmentConfig = {
  s3: S3Config[ReactEnvironment.DEV],
  appsync: AppsyncConfig[ReactEnvironment.DEV],
  pmapi: PMApiEndpoint[ReactEnvironment.DEV],
  restapi: RestApiEndpoint[ReactEnvironment.DEV],
  ckeditorLicenseKey: CKEDITOR_DEVELOPMENT_LICENSE_KEY,
  cognito: CognitoConfig[ReactEnvironment.DEV],
  algoliaAppKey,
  subscriptionEndpoint: `wss://${GQLEndpoint[ReactEnvironment.DEV]}`,
  publicUrlEndpoint: 'https://4ooo0tquya.execute-api.us-east-1.amazonaws.com/dev/public/getDetails',
  CLOUDINARY_API_KEY: CLOUDINARY_NONPROD_API_KEY,
  CLOUDINARY_UPLOAD_URL: `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/upload`,
  CLOUDINARY_IMAGE_URL: `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/fl_force_strip/public/`,
  CLOUDINARY_VIDEO_URL: `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/video/upload/public/`,
  CLOUDINARY_RAW_DOWNLOAD_URL: `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/raw/upload/public/`,
  CLOUDINARY_THUMBNAIL_URL: `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/c_thumb,w_200/public/`,
  CLOUDINARY_PROFILE_URL: `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,b_rgb:262c35/public/`,
  launchdarklyClientId: '6011a60a2a25290a8622568b',
  launchdarklyAnonymousId: LAUNCH_DARKLY_ANONYMOUS_KEY,
  METABASE_URL: 'https://metabase.dev.buildops.com',
  ENTERPRISE_DOMAIN: 'ee.dev.buildops.com',
  NON_ENTERPRISE_DOMAIN: 'dev.buildops.com'
};

//* * ============= QA - Testing ============= */
const qaEnvironmentConfig = {
  s3: S3Config[ReactEnvironment.QA],
  appsync: AppsyncConfig[ReactEnvironment.QA],
  pmapi: PMApiEndpoint[ReactEnvironment.QA],
  restapi: RestApiEndpoint[ReactEnvironment.QA],
  ckeditorLicenseKey: CKEDITOR_DEVELOPMENT_LICENSE_KEY,
  cognito: CognitoConfig[ReactEnvironment.QA],
  algoliaAppKey,
  subscriptionEndpoint: `wss://${GQLEndpoint[ReactEnvironment.QA]}`,
  publicUrlEndpoint: 'https://owu9yyjugh.execute-api.us-east-1.amazonaws.com/qa/public/getDetails',
  CLOUDINARY_API_KEY: CLOUDINARY_NONPROD_API_KEY,
  CLOUDINARY_UPLOAD_URL: `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/upload`,
  CLOUDINARY_IMAGE_URL: `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/fl_force_strip/public/`,
  CLOUDINARY_VIDEO_URL: `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/video/upload/public/`,
  CLOUDINARY_RAW_DOWNLOAD_URL: `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/raw/upload/public/`,
  CLOUDINARY_THUMBNAIL_URL: `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/t_media_lib_thumb/public/`,
  CLOUDINARY_PROFILE_URL: `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,b_rgb:262c35/public/`,
  launchdarklyClientId: '6011a6522a25290a86225697',
  launchdarklyAnonymousId: LAUNCH_DARKLY_ANONYMOUS_KEY,
  METABASE_URL: 'https://metabase.qa.buildops.com',
  ENTERPRISE_DOMAIN: 'ee.qa.buildops.com',
  NON_ENTERPRISE_DOMAIN: 'qa.buildops.com'
};

//* * ============= Staging - Testing ============= */
const stagingEnvironmentConfig = {
  s3: S3Config[ReactEnvironment.STAGING],
  appsync: AppsyncConfig[ReactEnvironment.STAGING],
  pmapi: PMApiEndpoint[ReactEnvironment.STAGING],
  restapi: RestApiEndpoint[ReactEnvironment.STAGING],
  ckeditorLicenseKey: CKEDITOR_PRODUCTION_LICENSE_KEY,
  cognito: CognitoConfig[ReactEnvironment.STAGING],
  algoliaAppKey: algoliaProdAppKey,
  subscriptionEndpoint: `wss://${GQLEndpoint[ReactEnvironment.STAGING]}`,
  publicUrlEndpoint:
    'https://b2imc5rof2.execute-api.us-east-1.amazonaws.com/staging/public/getDetails',
  CLOUDINARY_API_KEY: CLOUDINARY_PROD_API_KEY,
  CLOUDINARY_UPLOAD_URL: `https://api.cloudinary.com/v1_1/${CLOUDINARY_PROD_CLOUD_NAME}/upload`,
  CLOUDINARY_IMAGE_URL: `https://res.cloudinary.com/${CLOUDINARY_PROD_CLOUD_NAME}/image/upload/fl_force_strip/public/`,
  CLOUDINARY_VIDEO_URL: `https://res.cloudinary.com/${CLOUDINARY_PROD_CLOUD_NAME}/video/upload/public/`,
  CLOUDINARY_RAW_DOWNLOAD_URL: `https://res.cloudinary.com/${CLOUDINARY_PROD_CLOUD_NAME}/raw/upload/public/`,
  CLOUDINARY_THUMBNAIL_URL: `https://res.cloudinary.com/${CLOUDINARY_PROD_CLOUD_NAME}/image/upload/t_media_lib_thumb/public/`,
  CLOUDINARY_PROFILE_URL: `https://res.cloudinary.com/${CLOUDINARY_PROD_CLOUD_NAME}/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,b_rgb:262c35/public/`,
  launchdarklyClientId: '6011a687e21c2309ff2c09a5',
  launchdarklyAnonymousId: LAUNCH_DARKLY_ANONYMOUS_KEY,
  METABASE_URL: 'https://metabase.staging.buildops.com',
  ENTERPRISE_DOMAIN: 'ee.staging.buildops.com',
  NON_ENTERPRISE_DOMAIN: 'staging.buildops.com'
};

//* * ============= Prod - Production ============= */
const prodEnvironmentConfig = {
  s3: S3Config[ReactEnvironment.PROD],
  appsync: AppsyncConfig[ReactEnvironment.PROD],
  pmapi: PMApiEndpoint[ReactEnvironment.PROD],
  restapi: RestApiEndpoint[ReactEnvironment.PROD],
  ckeditorLicenseKey: CKEDITOR_PRODUCTION_LICENSE_KEY,
  cognito: CognitoConfig[ReactEnvironment.PROD],
  algoliaAppKey: algoliaProdAppKey,
  subscriptionEndpoint: `wss://${GQLEndpoint[ReactEnvironment.PROD]}`,
  publicUrlEndpoint:
    'https://a67qb40vte.execute-api.us-east-1.amazonaws.com/prod/public/getDetails',
  CLOUDINARY_API_KEY: CLOUDINARY_PROD_API_KEY,
  CLOUDINARY_UPLOAD_URL: `https://api.cloudinary.com/v1_1/${CLOUDINARY_PROD_CLOUD_NAME}/upload`,
  CLOUDINARY_IMAGE_URL: `https://res.cloudinary.com/${CLOUDINARY_PROD_CLOUD_NAME}/image/upload/fl_force_strip/public/`,
  CLOUDINARY_VIDEO_URL: `https://res.cloudinary.com/${CLOUDINARY_PROD_CLOUD_NAME}/video/upload/public/`,
  CLOUDINARY_RAW_DOWNLOAD_URL: `https://res.cloudinary.com/${CLOUDINARY_PROD_CLOUD_NAME}/raw/upload/public/`,
  CLOUDINARY_THUMBNAIL_URL: `https://res.cloudinary.com/${CLOUDINARY_PROD_CLOUD_NAME}/image/upload/t_media_lib_thumb/public/`,
  CLOUDINARY_PROFILE_URL: `https://res.cloudinary.com/${CLOUDINARY_PROD_CLOUD_NAME}/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,b_rgb:262c35/public/`,
  launchdarklyClientId: '5ff4ca091b2f0b0bd4516e62',
  launchdarklyAnonymousId: LAUNCH_DARKLY_ANONYMOUS_KEY,
  METABASE_URL: 'https://metabase.live.buildops.com',
  ENTERPRISE_DOMAIN: 'team.buildops.com',
  NON_ENTERPRISE_DOMAIN: 'live.buildops.com'
};

//* * ============================================= */

const configForEnvironment = env => {
  switch (env) {
    case ReactEnvironment.LOCAL:
      return localEnvironmentConfig;

    case ReactEnvironment.DEV:
      return devEnvironmentConfig;

    case ReactEnvironment.QA:
      return qaEnvironmentConfig;

    case ReactEnvironment.STAGING:
      return stagingEnvironmentConfig;

    case ReactEnvironment.PROD:
      return prodEnvironmentConfig;

    default:
      return null;
  }
};

export default configForEnvironment;
