import gql from 'graphql-tag';

const addJobsToCustomerProperty = gql`
  mutation addJobsToCustomerProperty(
    $partitionKey: String
    $data: AddJobsToCustomerPropertyInput!
  ) {
    addJobsToCustomerProperty(partitionKey: $partitionKey, data: $data) {
      id
      sortKey
      version
      jobNumber
      customIdentifier
    }
  }
`;

export default addJobsToCustomerProperty;
