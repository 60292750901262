import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  modalTitle: {
    fontSize: 24,
    fontWeight: 700,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& img': {
      position: 'relative',
      top: 3
    }
  },
  modalBody: {
    '& *': {
      textAlign: 'left'
    }
  }
}));
