import { convertToCurrencyStringCheck } from '../../CKEditor.utils';

function parsePartsAndMaterialsSubtotal({ quoteLineTasks }) {
  const quoteMaterialsTotal = quoteLineTasks.reduce((acc, task) => {
    const taskMaterialTotal = (task.quoteLineProducts || task.products).items.reduce(
      (taskAcc, item) => taskAcc + item.unitPrice * item.quantity,
      0
    );
    return acc + taskMaterialTotal;
  }, 0);

  return convertToCurrencyStringCheck(quoteMaterialsTotal);
}

export default parsePartsAndMaterialsSubtotal;
