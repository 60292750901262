const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5)
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: '#2dce89',
    color: '#fff',
    height: 24,
    letterSpacing: 0.24,
    borderRadius: 12,
    maxWidth: '300px',
    position: 'relative',
    display: 'inline-block',
    lineHeight: '24px',
    '&>span': {
      textOverflow: 'ellipsis',
      maxWidth: '300px',
      overflow: 'hidden',
      position: 'relative',
      display: 'inline-block'
    }
  }
});

export default styles;
