import moment from 'moment';
import { DEFAULT_EVENT_DURATION_IN_MIN } from '@dispatch/Dispatch.constants';

export const selectVisitData = data => {
  const address = data?.property?.address;
  return {
    scheduledFor: data?.scheduledFor || moment().unix(),
    excludeWeekends: true,
    recurringStartDateAndTime: data?.scheduledFor || moment().unix(),
    actualDuration: data?.actualDuration || DEFAULT_EVENT_DURATION_IN_MIN,
    primaryTechId: data?.primaryTechId,
    primaryTechName: data?.primaryTechName,
    description: data?.description || '',
    prerequisites: data?.prerequisites || '',
    customerRep: { name: data?.customerRep?.name || '' },
    prerequisitesAcknowledged: data?.prerequisitesAcknowledged || false,
    property: {
      address: {
        addressLine1: address?.addressLine1 || '',
        addressLine2: address?.addressLine2 || '',
        city: address?.city || '',
        state: address?.state || '',
        zipcode: address?.zipcode || ''
      }
    },
    departmentId: data?.departmentId,
    extraTechsNumber: data?.extraTechsNumber,
    extraTechs: data?.extraTechs
  };
};
