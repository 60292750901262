import buildVisitCreatePayload from './buildVisitCreatePayload';
import { allowedInputsInEditVisit, getVisitActions } from '../../../helper';

const buildVisitUpdateActionAndPayload = ({
  jobId,
  user,
  departments,
  techs,
  availableForms,
  propertyAssets,
  originalVisit,
  visitFormData
}) => {
  const visitPayload = buildVisitCreatePayload({
    jobId,
    user,
    availableForms,
    propertyAssets,
    visitFormData
  });
  const { preferredTechnicians } = visitFormData;
  const department = departments?.find(dep => dep?.id === preferredTechnicians?.departmentId) || {};
  const primaryTechs =
    techs?.filter(tech => tech?.id === preferredTechnicians?.primaryTechnicianId) || [];
  const { visitDateTime, ...visitData } = visitPayload;
  // the number shouldnt be changed unless user changes, null to update, undefined doesnt update the value in db
  const numberOfExtraTechs = Number.isInteger(preferredTechnicians?.extraTechsNumber)
    ? preferredTechnicians?.extraTechsNumber
    : null;
  const localVisit = {
    ...originalVisit,
    ...visitData,
    ...visitDateTime,
    primaryTechs,
    departmentName: department?.tagName,
    departmentId: department?.id,
    extraTechs: preferredTechnicians?.additionalTechnicianIds?.map(id => ({ id })) || [],
    extraTechsNumber: numberOfExtraTechs,
    // When there is increase/descrease in addn tech reqd, without having additional techs, reschedule action, requires this field as true
    // backend does the check of extraTechsNumber >= preferredTechnicians?.additionalTechnicianIds?.length
    extraTechsRequired: !!numberOfExtraTechs
  };

  const sortKeyComponents = originalVisit?.sortKey?.split('_');
  const localId = sortKeyComponents && sortKeyComponents[sortKeyComponents.length - 1];
  if (localId) {
    localVisit.id = localId;
  }

  const action = getVisitActions(originalVisit, localVisit);
  return {
    action,
    payload: {
      visit: allowedInputsInEditVisit(localVisit),
      primaryTechIds: preferredTechnicians?.primaryTechnicianId
        ? [preferredTechnicians?.primaryTechnicianId]
        : [],
      extraTechIds: preferredTechnicians?.additionalTechnicianIds || [],
      visitAssets: localVisit.visitAssets,
      formData: localVisit.formData
    }
  };
};

export default buildVisitUpdateActionAndPayload;
