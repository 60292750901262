export const useStyles = palette => {
  return {
    root: {
      padding: 0,
      '&:last-child': {
        padding: 0
      }
    },
    cardHeader: {
      height: 87,
      width: 219,
      background: palette.grayscale(20),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    cardHeaderTitle: {
      color: palette.grayscale(100),
      fontWeight: 'bold',
      fontSize: 20
    },
    cardContent: {
      height: 178,
      width: 219,
      background: palette.grayscale(100)
    }
  };
};
