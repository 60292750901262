/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { MUIForm } from '@buildhero/sergeant';
import { PaymentService } from 'services/core';
import { logErrorWithCallback } from 'utils';
import { DefaultButton, FullScreenModal } from 'components';
import { snackbarOn } from 'redux/actions/globalActions';
import { handlePayments } from 'scenes/Payments/PaymentDetail/helper';
import ApplyPaymentForm from './layout';
import PaymentApplicationTable from './PaymentApplicationTable';

const ApplyPayment = ({ open, handleClose, snackbarOn: snackbar, initialData, onSubmit, user }) => {
  // eslint-disable-next-line
  const [isApplying, setIsApplying] = useState(false);
  const [formService, setFormService] = useState();
  const paymentService = new PaymentService();

  const handleApply = () => {
    formService.submit();
  };

  const handleComplete = async data => {
    // payments is { paymentId: appliedAmount }
    // paymentMap is { paymentId: payment }
    const { payments = {}, paymentInvoices = [], paymentMap = {} } = data;
    const { tenantId, tenantCompanyId } = user;
    setIsApplying(true);

    try {
      const payload = {
        companyId: tenantCompanyId,
        payments: handlePayments(payments, paymentMap, paymentInvoices, initialData.invoiceId)
      };
      // only call mutation if there are changes to save
      if (!payload.payments.length) {
        snackbar('error', 'No changes to apply');
        return;
      }

      const updatedPayments = await paymentService.upsertPaymentsToCompany(tenantId, payload);
      if (updatedPayments.length) snackbar('success', 'Payments successfully applied');
    } catch (error) {
      logErrorWithCallback(error, snackbar, `Failed to apply payments, please try again later`);
    } finally {
      setIsApplying(false);
    }
    handleClose();
    if (onSubmit) onSubmit();
  };

  const modalHeaderButtons = [
    <DefaultButton
      color="primary"
      label="Apply"
      handle={handleApply}
      key="apply"
      disabled={isApplying}
      showSpinner={isApplying}
    />
  ];

  return (
    <FullScreenModal
      title="Apply Payment"
      modalHeaderButtons={modalHeaderButtons}
      open={open}
      handleClose={handleClose}
      fixedHeader
    >
      <MUIForm
        configuration={ApplyPaymentForm}
        data={initialData}
        layout="edit"
        customComponents={{ PaymentApplicationTable }}
        onCreateService={service => setFormService(service)}
        onComplete={handleComplete}
      />
    </FullScreenModal>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const reduxConnectedApplyPayment = connect(mapStateToProps, mapDispatchToProps)(ApplyPayment);

export default reduxConnectedApplyPayment;
