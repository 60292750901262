import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import ENV from 'configs/env';
import useExtendedMutation from 'customHooks/useExtendedMutation';
import useExtendedLazyQuery from 'customHooks/useExtendedLazyQuery';

export const GET_WO = gql`
  query getServiceChannelWorkOrderList(
    $tenantId: String
    $filter: TableFilterInput
    $pagination: PaginationInput
    $sorting: [TableSortingInput]
  ) {
    data: getServiceChannelWorkOrderList(
      tenantId: $tenantId
      filter: $filter
      pagination: $pagination
      sorting: $sorting
    ) {
      rowCount
      items
    }
  }
`;

export const serviceChannelBase = ['qa', 'staging', 'prod'].includes(ENV)
  ? 'https://www.servicechannel.com'
  : 'https://sb2.servicechannel.com';

const GET_COUNTS = gql`
  query getServiceChannelWorkOrderCounts($tenantId: String) {
    getServiceChannelWorkOrderCounts(tenantId: $tenantId)
  }
`;

export const useQuickFilterCount = () => {
  const { data = {}, refetch, loading } = useExtendedQuery(GET_COUNTS, {
    transform: result => {
      return result.getServiceChannelWorkOrderCounts.reduce(
        (obj, a) => ({ ...obj, [a.boStatus]: a.count }),
        {}
      );
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  });

  return { counts: data, refetchCounts: refetch, loadingCounts: loading };
};

const SERVICE_CHANNEL_TO_JOB = gql`
  mutation addServiceChannelToJob(
    $serviceChannelWorkOrderId: String!
    $jobId: String
    $customerId: String
    $customerPropertyId: String
  ) {
    addServiceChannelToJob(
      serviceChannelWorkOrderId: $serviceChannelWorkOrderId
      jobId: $jobId
      customerId: $customerId
      customerPropertyId: $customerPropertyId
    ) {
      jobNumber
    }
  }
`;

export const useAssociateJob = () => {
  const history = useHistory();
  const [associateJob, { loading: associateJobLoading }] = useExtendedMutation(
    SERVICE_CHANNEL_TO_JOB,
    {
      serializer: ({ serviceChannelWorkOrderId, jobId, customerId, customerPropertyId }) => {
        return {
          variables: {
            serviceChannelWorkOrderId,
            jobId,
            customerId,
            customerPropertyId
          }
        };
      },
      onCompleted: data => {
        history.push(`/job/view/${data.addServiceChannelToJob.jobNumber}`);
      }
    }
  );

  return { associateJob, associateJobLoading };
};

const GET_WO_BY_ID = gql`
  query getServiceChannelWorkOrderById($id: String!) {
    getServiceChannelWorkOrderById(id: $id) {
      id
      subscriberName
      locationName
      locationStoreId
      customer {
        id
        customerName
      }
      customerProperty {
        id
        companyName
      }
    }
  }
`;

export const useLazyGetWOById = opts =>
  useExtendedLazyQuery(GET_WO_BY_ID, {
    serializer: id => ({ variables: { id } }),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...opts
  });

const DISCARD = gql`
  mutation discardServiceChannelWorkOrder($serviceChannelWorkOrderId: String!) {
    discardServiceChannelWorkOrder(serviceChannelWorkOrderId: $serviceChannelWorkOrderId)
  }
`;

export const useDiscardWorkOrder = () => {
  const [discardWorkOrder, { loading: discardWorkOrderLoading }] = useExtendedMutation(DISCARD, {
    serializer: serviceChannelWorkOrderId => ({ variables: { serviceChannelWorkOrderId } })
  });

  return { discardWorkOrder, discardWorkOrderLoading };
};

const REOPEN = gql`
  mutation reopenServiceChannelWorkOrder($serviceChannelWorkOrderId: String!) {
    reopenServiceChannelWorkOrder(serviceChannelWorkOrderId: $serviceChannelWorkOrderId)
  }
`;

export const useReopenWorkOrder = () => {
  const [reopenWorkOrder, { loading: reopenWorkOrderLoading }] = useExtendedMutation(REOPEN, {
    serializer: serviceChannelWorkOrderId => ({ variables: { serviceChannelWorkOrderId } })
  });

  return { reopenWorkOrder, reopenWorkOrderLoading };
};

const DELETE = gql`
  mutation deleteServiceChannelWorkOrder($serviceChannelWorkOrderId: String!) {
    deleteServiceChannelWorkOrder(serviceChannelWorkOrderId: $serviceChannelWorkOrderId)
  }
`;

export const useDeleteWorkOrder = () => {
  const [deleteWorkOrder, { loading: deleteWorkOrderLoading }] = useExtendedMutation(DELETE, {
    serializer: serviceChannelWorkOrderId => ({ variables: { serviceChannelWorkOrderId } })
  });

  return { deleteWorkOrder, deleteWorkOrderLoading };
};
