import moment from 'moment';
import React, { useState } from 'react';
import { func } from 'prop-types';
import { useFormService } from '@dispatch/hooks';
import { DEFAULT_EVENT_DURATION_IN_MIN, EventEntities } from '@dispatch/Dispatch.constants';
import { EventDataPropTypes } from '@dispatch/Dispatch.props';
import useAddManDay from '@dispatch/mutations/useAddManDay';
import { getTimestampFromDateAndTime } from '../../Drawer.utils';
import EventBadge from '../EventBadge';
import DrawerLayout from '../DrawerLayout';
import ProjectDetailsHeader from '../ProjectDetailsHeader';
import ProjectSearchHeader from './components/ProjectSearchHeader';
import ManDayCreateForm from './components/ManDayCreateForm';

const prepareFormData = eventData => {
  const eventDuration = eventData?.actualDuration || DEFAULT_EVENT_DURATION_IN_MIN;
  const eventTime = eventData?.scheduledFor ? moment.unix(eventData.scheduledFor) : moment();
  const date = moment(eventTime)
    .startOf('day')
    .unix();
  const fromTime = eventTime.format('HH:mm');
  const toTime = moment(eventTime)
    .add(eventDuration, 'minutes')
    .format('HH:mm');
  const technicianIds = eventData?.primaryTechId ? [eventData?.primaryTechId] : [];
  return {
    fromTime,
    toTime,
    fromDate: date,
    toDate: date,
    technicianIds,
    excludeWeekends: true,
    description: null
  };
};

const serializeFormData = formData => {
  const {
    technicianIds,
    fromTime,
    toTime,
    fromDate,
    toDate,
    excludeWeekends,
    description
  } = formData;
  return {
    technicianIds,
    startDateTime: getTimestampFromDateAndTime(fromDate, fromTime),
    endDateTime: getTimestampFromDateAndTime(toDate, toTime),
    excludeWeekends,
    description
  };
};

const CreateManDay = ({ eventData, closeDrawer }) => {
  const formData = prepareFormData(eventData);
  const [project, setProject] = useState();
  const [disableSave, setDisableSave] = useState(false);

  const { isDirty, onCreateService, onDirtyChange, resetForm, submitForm } = useFormService();
  const [triggerMutation, mutationResponse] = useAddManDay();
  const { loading } = mutationResponse;
  const disabled = !project || loading;

  const createManDay = async manDayFormData => {
    const data = serializeFormData(manDayFormData);
    await triggerMutation({
      ...data,
      project,
      projectId: project?.id
    });
    closeDrawer();
    resetForm();
  };

  const handleFormReset = () => {
    setProject(null);
    resetForm();
  };

  const renderHeader = () => {
    return (
      <>
        <EventBadge eventType={EventEntities.MAN_DAY.value.clientValue} />
        {project ? (
          <ProjectDetailsHeader project={project} loading={loading} />
        ) : (
          <ProjectSearchHeader onProjectSelected={setProject} />
        )}
      </>
    );
  };

  const renderBody = () => (
    <ManDayCreateForm
      onDirtyChange={onDirtyChange}
      onComplete={createManDay}
      onCreateService={onCreateService}
      data={formData}
      disabled={disabled}
      disableSave={disableSave}
      setDisableSave={setDisableSave}
    />
  );

  return (
    <DrawerLayout
      renderHeader={renderHeader}
      renderBody={renderBody}
      onResetForm={handleFormReset}
      onSubmitForm={submitForm}
      isDirty={project || isDirty}
      loading={loading}
      disabledSave={disabled || disableSave}
      disabledClose={loading}
    />
  );
};

CreateManDay.propTypes = {
  eventData: EventDataPropTypes.isRequired,
  closeDrawer: func.isRequired
};

export default CreateManDay;
