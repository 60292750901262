import React, { useRef, memo, useState } from 'react';

import compose from 'utils/compose';

import { withDispatchActions, withDispatchStore } from '@dispatch/Dispatch.store';

import useJobDetails from '@dispatch/queries/useJobDetails';
import { useVisitTransition } from '@dispatch/mutations';
import ContinueVisit from './ContinueVisit.component';

const mapActionsToProps = ({ closeDrawer }) => ({
  closeDrawer
});

const mapDispatchToProps = store => ({
  eventData: store.state.eventData
});

const DataProvider = props => {
  const Memoized = useRef(memo(ContinueVisit)).current;
  // eslint-disable-next-line react/prop-types
  const { eventData } = props;
  const [jobNumber, setJobNumber] = useState(eventData?.job?.jobNumber);
  const jobDetailsResponse = useJobDetails(jobNumber);
  const visitTransitionTuple = useVisitTransition();

  return (
    <Memoized
      {...props}
      jobNumber={jobNumber}
      setJobNumber={setJobNumber}
      jobDetailsResponse={jobDetailsResponse}
      visitTransitionTuple={visitTransitionTuple}
    />
  );
};

export default compose(
  withDispatchStore(mapDispatchToProps),
  withDispatchActions(mapActionsToProps)
)(DataProvider);
