import { isObject } from 'utils';

const generatePresetsSelect = (presets, selectComponent, changeHandler, presetsLabel) => {
  const presetsSelectSubheader = {
    component: 'SubHeader',
    props: {
      marginLeft: 0,
      label: presetsLabel
    }
  };

  const options = presets.map(e => {
    const { label, value, isDefault } = e[presetsLabel];
    return { label, value, isDefault };
  });

  const presetsSelectControl = {
    component: selectComponent,
    source: presetsLabel,
    props: {
      onChange: changeHandler,
      marginLeft: 0,
      options,
      slowField: true,
      style: {
        marginTop: 8
      }
    }
  };

  const fields = {};
  fields[presetsLabel] = {
    default: presetsLabel
  };

  const contents = [presetsSelectSubheader, presetsSelectControl];

  return { fields, contents };
};

const generateSections = (data = {}, maps, level = 0, prefix = '', dependentField) => {
  let fields = {};
  const contents = Object.entries(data).reduce((list, [key, value]) => {
    let result = [];
    const nestedKey = `${prefix}${key}`;

    // handle dependency relations - in recursive calls, dependentField will have a value already.
    // cascade the dependency unless there is a more relevant dependency defined
    let dependency = dependentField;
    const mapItem = maps.dependentMap[nestedKey];
    if (mapItem) {
      dependency = {
        fieldName: mapItem.fieldName,
        expectedValue: mapItem.disableIf ?? mapItem.hideIf, // disableIf will be defined if relationship is to disable, same for hideIf
        operation: 'bool',
        action: mapItem.disableIf !== undefined ? 'DISABLE' : 'HIDE'
      };
    }

    if (isObject(value)) {
      // recurse on the object value
      const { fields: nestedFields, contents: nestedContents } = generateSections(
        value,
        maps,
        level + 1,
        `${nestedKey}.`,
        dependency
      );
      result = [
        {
          component: 'SubHeader',
          props: {
            marginLeft: level * 23,
            label: key
          }
        },
        ...nestedContents
      ];
      fields = { ...fields, ...nestedFields };
    } else if (typeof value === 'boolean') {
      // switch input
      result = [
        {
          component: 'Switch',
          source: nestedKey,
          dependentField: dependency,
          props: {
            slowField: true, // (formik) force use Field instead of FastField
            checked:
              nestedKey === 'Quote Mode.Enable overrides'
                ? maps.propsMap[nestedKey]?.disableIf
                : null,
            onChange: maps.changeHandlerMap[nestedKey] || maps.changeHandlerMap['*'],
            horizontalLabel: true,
            marginLeft: Math.max(level - 1, 0) * 23,
            label: key,
            style: { marginTop: 8 },
            disabled: nestedKey in maps.propsMap && maps.propsMap[nestedKey].disableIf
          }
        }
      ];

      fields[nestedKey] = {
        default: nestedKey
      };
    }
    return list.concat(result);
  }, []);

  return { fields, contents };
};

/**
 * Function to generate form meta based on the data itself
 * @param {string} headerLabel The label for the header like "Invoice Configuration"
 * @param {object} settings The data for the settings where each key of object is the subheader name
 *                     and key of boolean/string are labels of inputs
 * @param {array} presets The list of presets; labels and values
 * @param {object} maps Three map types:
 *                 - dependentMap: to define disable/hide relationships between fields.
 *                   DependentFieldName: { fieldName: nameOfFieldDependentOn, disable/hide If: value }
 *                   'Line Items': { fieldName: 'Invoice View.Simple Invoice', disableIf: true }
 *                   'Line Items': { fieldName: 'Invoice View.Simple Invoice', hideIf: true }
 *                 - propsMap: to define props for select inputs
 *                   FieldName: [{ label: '', value: '' }, ...]
 *                 - changeHandlerMap: define functions that are called with (key, value, form) on change.
 *                   use '*' as field name to apply for all fields
 *                   '*': (key, value, form) => { form.submitForm(); }
 * @param {function} Change handler for preset select
 * @param {string} subtext Helper text to display under the title
 * @param {string} styleProps Style for the main container
 * @param {string} presetsLabel The label for the preset dropdown field, such as "Invoice Presets"
 */
export const generateSettingLayout = (
  headerLabel = '',
  settings = {},
  presets = [],
  maps = { dependentMap: {}, propsMap: {}, changeHandlerMap: {} },
  selectComponent = '',
  presetsSelectChangeHandler,
  subtext = '',
  styleProps = {},
  presetsLabel
) => {
  const { fields: presetsSelectFields, contents: presetsSelectContent } = generatePresetsSelect(
    presets,
    selectComponent,
    presetsSelectChangeHandler,
    presetsLabel
  );
  const { fields: sectionsFields, contents: sectionsContents } = generateSections(settings, maps);
  const fields = { ...presetsSelectFields, ...sectionsFields };

  return {
    fields,
    layouts: {
      default: {
        props: { style: { padding: 24, ...styleProps } },
        contents: [
          {
            component: 'Header',
            props: {
              label: headerLabel
            }
          },
          subtext && {
            component: 'Typography',
            props: {
              value: subtext
            }
          },
          ...presetsSelectContent,
          ...sectionsContents
        ]
      }
    }
  };
};

export default generateSettingLayout;
