import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';

export const algoliaProps = {
  searchIndex: defaultSearchIndex,
  placeholder: 'Search',
  name: 'selectProperty',
  value: '',
  locatedInPopUp: true,
  displayText: ['jobNumber', 'visitNumber'],
  delimiter: '-',
  filters: [
    {
      attribute: 'entityType',
      valueArray: ['Visit']
    }
  ]
};

export const timeStampWarning = 'Warning: Overlapping timestamps';

export const headerLabel = 'Time Tracking Report';

export const reviewedStatus = {
  reviewed: 'REVIEWED',
  pending: 'PENDING'
};

export const tabType = {
  REVIEW: 0,
  PENDING: 1,
  APPROVED: 2
};

export const placeholder = 'Select Date';

export const timePickerRangeAnchorId = 'time-period-selector';

export const anchorOrigin = { vertical: 'bottom', horizontal: 'right' };

export const transformOrigin = { vertical: 'top', horizontal: 'right' };

export const timesheetNeededProperties = [
  { key: 'id' },
  { key: 'hourTypeAbbreviation' },
  { key: 'entryType' },
  { key: 'actualStartTimeUTC' },
  { key: 'actualEndTimeUTC' },
  { key: 'latitude' },
  { key: 'longitude' },
  { key: 'actualStartTimeOverrideUTC' },
  { key: 'actualEndTimeOverrideUTC' },
  { key: 'actualTotalDuration' },
  { key: 'actualTotalDurationOverride' },
  { key: 'billableEntityType' },
  { key: 'billableEntityId' },
  { key: 'hourTypeId' },
  { key: 'actualCreatedDate' },
  { key: 'reviewStatus' },
  { key: 'isSplitted', defaultValue: false },
  { key: 'approvalStatus' },
  { key: 'userActionType' },
  { key: 'timesheetPeriodId' },
  { key: 'timekeepingLedgerId' }
];

export const serviceSuccessMessage = {
  approveTimesheet: 'Successfully Approved the timesheet',
  reviewTimesheet: 'Successfully Reviewed the timesheet',
  deleteTimesheetEntry: 'Timesheet Entry deleted Successfully',
  bulkSave: 'Successfully Saved the changes'
};

export const serviceErrorMessage = {
  hourSummary: 'Unable to get timesheet period summary, please try again later',
  approveTimesheet: 'Unable to Approve the timesheet, please try again later',
  reviewTimesheet: 'Unable to Review the timesheet, please try again later',
  deleteTimesheetEntry: 'Unable to delete the entry, please try again later',
  bulkSave: 'Unable to Save the changes, please try again later',
  dailyOverride: 'Unable to override daily timesheet, please try again later',
  payrollSetting: 'Unable to fetch settings, please try again later',
  payrollHourTypes: 'Unable to fetch the payroll hour types.',
  timePeriods: 'Unable to fetch the time periods',
  timePeriod: 'Unable to fetch the time period',
  report: 'Unable to fetch the report',
  noTimesheetEntry: 'No Timesheet entries found',
  nonEditedTimesheetEntry: 'No Timesheet entries Edited',
  downloadReport: 'Unable to download the report',
  emptyTimes: 'Please review time entries for missing Start and End Times before saving changes'
};

export const eventTypeLabel = {
  BillableNonVisit: 'Billable Non-Visit Work',
  NonBillable: 'Non-Billable Work'
};

export const maxRecordCount = 30;
export const message = {
  noEntry: 'No entries to review'
};

export const entryKeysToCheck = ['actualEndTimeUTC', 'actualStartTimeUTC'];

export const reportTypes = {
  WEEKLY: 'WEEKLY',
  MANUAL: 'MANUAL',
  SUMMARY: 'SUMMARY',
  ADP: 'ADP'
};

export const exportDetail = {
  MANUAL: {
    table: 'ExportManualTimesheet',
    exportFileName: 'manual-reports.xlsx',
    exportFunction: 'TimeSheetManual'
  },
  WEEKLY: {
    table: 'ExportTimeSheet',
    exportFileName: 'weekly-reports.xlsx',
    exportFunction: 'TimeSheetAutomated'
  },
  SUMMARY: {
    table: 'ExportSummaryTimesheet',
    exportFileName: 'summary-reports.xlsx',
    exportFunction: 'TimeSheetAutomatedSummary'
  },
  ADP: {
    table: 'ExportADP',
    // exportFileName dynamically generated from accountingAppSettings
    exportFunction: 'ADPExport'
  }
};
