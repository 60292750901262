const SIDEBAR_WIDTH = '218px';

export const useStyles = () => ({
  root: {},
  header: {},
  sidebar: ({ palette, default: { spacing } }) => ({
    borderRight: `1px solid ${palette.grayscale(90)}`,
    width: '218px',
    paddingRight: spacing(3)
  }),
  projectImgContainer: ({ default: { spacing } }) => ({
    marginTop: spacing(3)
  }),
  projectImg: {
    width: '204px',
    height: '204px',
    objectFit: 'cover'
  },
  body: ({ palette }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderTop: `1px solid ${palette.grayscale(90)}`
  }),
  mainContent: {
    width: `calc(100% - ${SIDEBAR_WIDTH})`
  }
});
