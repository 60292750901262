import gql from 'graphql-tag';

const getAllCustomersInfo = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      customers(entityConnection: "Customer", filter: { status: { eq: "active" } }) {
        items {
          id
          customerName
          companyAddresses(
            entityConnection: "CompanyAddress"
            filter: { addressType: { eq: "billingAddress" } }
          ) {
            items {
              addressLine1
              addressLine2
              city
              state
              zipcode
            }
          }
          customerProperties(
            entityConnection: "CustomerProperty"
            filter: { status: { eq: "active" } }
          ) {
            items {
              id
              companyName
              companyAddresses(
                entityConnection: "CompanyAddress"
                filter: { addressType: { eq: "propertyAddress" } }
              ) {
                items {
                  addressLine1
                  addressLine2
                  city
                  state
                  zipcode
                }
              }
              jobs: jobsView(entityConnection: "Job") {
                items {
                  id
                  jobNumber
                  customer(entityConnection: "customer") {
                    id
                  }
                  customerProperty(entityConnection: "customerProperty") {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getAllCustomersInfo;
