/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PaymentService } from 'services/core';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CloudDownload, MailOutline } from '@material-ui/icons';
import { getHttpClient } from 'client';
import ErrorBoundaries from 'scenes/Error';
import {
  formatAddress,
  getImageUrl,
  logErrorWithCallback,
  convertToCurrencyString,
  getTenantSettingValueForKey
} from 'utils';
import Labels from 'meta/labels';

import { AccountingApp, ExportStatus, SyncStatus, EnumType, AddressType } from 'utils/constants';
import { PermissionConstants } from 'utils/AppConstants';
import { snackbarOn } from 'redux/actions/globalActions';
import { Button, ButtonType, MoreButton, ThemeProvider } from '@buildhero/sergeant';
import {
  PageHeader,
  DefaultButton,
  UserPermission,
  FullScreenModal,
  SplitButton,
  StatusChip,
  SyncStatus as SyncStatusDisplay
} from 'components';
import SendEmailPopUp from 'components/BuildHeroFormComponents/SendEmailPopUp';
import { sentryException } from 'services/Logger';
import { handlePaymentInvoices, handleAccountDetail } from './helper';
import PaymentForm from '../PaymentForm';
import { PaymentReceiptModal } from './components';
import PaymentActivityList from './components/PaymentActivityList';
import { PaymentAccountTypeDisplay } from '../constants';
import { downloadPayment, uploadPaymentPdf, getPaymentPdfName } from './PaymentDetail.utils';
import { SendPaymentEmailMutation } from './PaymentDetail.gql';

const styles = theme => {
  const borderColor = theme.palette.grayscale(90);
  return {
    header: { borderBottom: `1px solid ${borderColor}` },
    topPadding: { paddingTop: 14 }
  };
};

class PaymentDetail extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.PaymentService = new PaymentService();
    this.state = {
      paymentStatus: undefined,
      exportStatus: undefined,
      syncStatus: undefined,
      accountingIntegration: undefined,
      isIntacctEnabled: false,
      formData: { accountTypeDisplay: null }, // added default to handle radio button group issue
      isEditMode: false,
      readOnly: true,
      paymentReceiptModalOpen: false,
      isSubmitting: false
    };
  }

  componentDidMount = async () => {
    const { id } = this.props.computedMatch.params;
    this.setPaymentDetails(id);
    const accountingIntegration = getTenantSettingValueForKey('accountingApp');
    if (accountingIntegration) {
      const isIntacctEnabled = accountingIntegration === AccountingApp.INTACCT;
      this.setState({ accountingIntegration, isIntacctEnabled });
    }
  };

  setPaymentDetails = async paymentId => {
    const formData = await this.getPaymentDetail(paymentId);
    this.setState({ formData });
  };

  getPaymentDetail = async paymentId => {
    try {
      const response = await this.PaymentService.getPaymentById(paymentId);
      if (response && this.mounted) {
        const { isIntacctEnabled } = this.state;
        const { data } = response;
        const {
          billingCustomer,
          paymentType,
          paymentInvoices,
          adjustmentTransactions: givenAdjustmentTransactions,
          accountType,
          bankAccount,
          glAccount,
          ...rest
        } = data.getPaymentById;
        const appliedPaymentInvoices = paymentInvoices?.items.map(paymentInvoice => {
          const { appliedAmount, invoice } = paymentInvoice;
          const convertedValue = convertToCurrencyString(appliedAmount);
          return {
            invoiceId: invoice.id,
            label: `Invoice ${invoice.invoiceNumber} - ${convertedValue} APPLIED`
          };
        });
        const adjustmentTransactions = givenAdjustmentTransactions?.items.map(
          ({ appliedAmount, adjustment }) => ({
            adjustmentId: adjustment.id,
            label: `Adjustment ${adjustment.number} - ${convertToCurrencyString(
              appliedAmount
            )} APPLIED`
          })
        );
        const formattedPaymentData = {
          tenantId: this.props.user.tenantId,
          adjustmentTransactions,
          appliedPaymentInvoices,
          paymentInvoices: paymentInvoices?.items,
          paymentTypeName: paymentType?.name,
          paymentType: paymentType?.id,
          billingCustomerBillTo: formatAddress(billingCustomer?.companyAddresses.items?.[0]),
          billingCustomerName: billingCustomer?.customerName,
          billingCustomerId: billingCustomer?.id,
          accountTypeDisplay: isIntacctEnabled && PaymentAccountTypeDisplay[accountType],
          bankAccountName: bankAccount?.id
            ? `${bankAccount.accountingRefId}--${bankAccount.name}(${bankAccount.currencyCode})`
            : null,
          glAccountName: glAccount?.name,
          sender: {
            name: this.props.company.companyName,
            logoUrl: await getImageUrl(this.props.company.logoUrl),
            phoneNumber: this.props.company.phonePrimary,
            email: this.props.company.email,
            address: formatAddress(
              this.props.company.companyAddresses?.items.find(
                a => a.addressType === AddressType.BILLING
              )
            )
          },
          ...rest
        };
        const { paymentStatus, exportStatus } = formattedPaymentData;
        const readOnly = exportStatus === ExportStatus.CLOSED;
        this.setState({ paymentStatus, exportStatus, readOnly });
        return formattedPaymentData;
      }
    } catch (error) {
      logErrorWithCallback(
        error,
        this.props.snackbarOn,
        'Unable to fetch Payment details, please try again later'
      );
    }
    return {};
  };

  handleOnComplete = async completedFormData => {
    const { syncStatus, exportStatus } = this.state;
    const { tenantId, tenantCompanyId } = this.props.user;
    const {
      id,
      version,
      paymentNumber,
      paymentAmount,
      paymentDate,
      billingCustomerId,
      paymentType,
      paymentInvoices,
      invoices,
      accountTypeDisplay,
      bankAccountId,
      glAccountId
    } = completedFormData;
    const processedPaymentInvoices = handlePaymentInvoices(invoices, paymentInvoices);
    const accountDetail = handleAccountDetail(accountTypeDisplay, bankAccountId, glAccountId);

    const payload = {
      companyId: tenantCompanyId,
      payments: [
        {
          id,
          version,
          paymentNumber: parseInt(paymentNumber, 10),
          paymentAmount: parseFloat(paymentAmount),
          paymentDate: parseInt(paymentDate, 10),
          billingCustomerId,
          paymentTypeId: paymentType,
          syncStatus,
          exportStatus,
          ...processedPaymentInvoices,
          ...accountDetail
        }
      ]
    };

    this.setState({ isSubmitting: true });
    try {
      const payments = await this.PaymentService.upsertPaymentsToCompany(tenantId, payload);
      if (payments?.[0]) {
        this.props.snackbarOn(
          'success',
          `Successfully updated Payment ${completedFormData.paymentNumber || ''}`
        );

        this.setPaymentDetails(id);
        this.setState({ isEditMode: false });
      }
    } catch (error) {
      logErrorWithCallback(
        error,
        this.props.snackbarOn,
        'Unable to update payment. Please try again.'
      );
    }
    this.setState({ isSubmitting: false });
  };

  handleSave = () => this.state.formService.submit();

  handleSavePost = () => {
    this.setState({ syncStatus: SyncStatus.SYNCING });
    this.state.formService.submit();
  };

  handleSaveBypass = () => {
    this.setState({ syncStatus: SyncStatus.BYPASSED, exportStatus: ExportStatus.BYPASSED });
    this.state.formService.submit();
  };

  undoPost = () => console.log('UNDO POST');

  setPageHeaderButtons = () => {
    const { readOnly } = this.state;
    const containerStyle = {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      justifyContent: 'flex-end'
    };

    return (
      <ThemeProvider>
        <div style={containerStyle}>
          {!readOnly && (
            <Button
              testingid="edit-payment"
              type={ButtonType.TERTIARY}
              onClick={() => this.setState({ isEditMode: true })}
            >
              Edit Payment
            </Button>
          )}
          <Button
            testingid="view-receipt"
            onClick={() => this.setState({ paymentReceiptModalOpen: true })}
          >
            View Receipt
          </Button>
          <MoreButton
            testingid="kebabMenu"
            style={{ fontSize: 24, padding: 0 }}
            options={[
              {
                label: 'Email Receipt',
                icon: MailOutline,
                onClick: () => this.setState({ emailModalOpen: true })
              },
              {
                label: 'Download Receipt',
                icon: CloudDownload,
                onClick: () => downloadPayment(this.state.formData)
              }
            ]}
          />
        </div>
      </ThemeProvider>
    );
  };

  setModalHeaderButtons = (data, accountingIntegration) => [
    [ExportStatus.POSTED, ExportStatus.EXPORTED].includes(data?.exportStatus) ? (
      <DefaultButton
        color="primary"
        label="Save & Post Payment"
        handle={this.handleSavePost}
        disabled={this.state.isSubmitting}
        showSpinner={this.state.isSubmitting}
      />
    ) : (
      <SplitButton
        testingid="save-payment"
        color="primary"
        label="Save Payment"
        onClick={this.handleSave}
        options={
          accountingIntegration && [
            { label: 'Save & Post Payment', onClick: this.handleSavePost },
            { label: 'Save & Bypass', onClick: this.handleSaveBypass }
          ]
        }
        disabled={this.state.isSubmitting}
        key="save"
      />
    )
  ];

  handleSendEmail = async payload => {
    try {
      const { data: response } = await getHttpClient().mutate({
        mutation: SendPaymentEmailMutation,
        variables: { partitionKey: this.props.user.tenantId, data: payload }
      });
      if (response) {
        this.props.snackbarOn('success', 'Email sent successfully');
      }
    } catch (e) {
      sentryException(e, 'Unable to send payment email');
      this.props.snackbarOn('error', 'Unable to send email');
    }
  };

  render() {
    const { user, classes, snackbarOn } = this.props;
    const {
      formData,
      isEditMode,
      accountingIntegration,
      paymentStatus,
      paymentReceiptModalOpen,
      emailModalOpen,
      exportStatus
    } = this.state;
    const title = `${Labels.Payment[user.locale]} ${formData.paymentNumber ?? '-'}`;

    return (
      <ErrorBoundaries>
        <UserPermission I="read" action={PermissionConstants.OBJECT_INVOICE}>
          <div style={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.header}>
                <PageHeader
                  pageMapKey="payments"
                  title={title}
                  breadcrumbsArray={[
                    { title: 'Accounting', link: '' },
                    { title: Labels.Payments[user.locale], link: '/payments/list' }
                  ]}
                  userLocale={user.locale}
                  additionalTitleComponents={
                    <>
                      <StatusChip
                        label={paymentStatus}
                        enumType={EnumType.PAYMENT_STATUS}
                        enumValue={paymentStatus}
                      />
                      {!!exportStatus && (
                        <StatusChip
                          label={exportStatus}
                          enumType={EnumType.EXPORT_STATUS}
                          enumValue={exportStatus}
                        />
                      )}
                    </>
                  }
                >
                  {this.setPageHeaderButtons()}
                </PageHeader>
              </Grid>
              <Grid container spacing={3} className={classes.topPadding}>
                <Grid item xs={6}>
                  <PaymentForm
                    data={formData}
                    onComplete={completedFormData => this.handleOnComplete(completedFormData)}
                  />
                </Grid>
                <Grid item xs={6}>
                  {accountingIntegration && (
                    <SyncStatusDisplay
                      locale={user.locale}
                      syncStatus={formData.syncStatus ?? undefined}
                      syncLog={formData.syncLog}
                    />
                  )}
                  <PaymentActivityList
                    loading={formData?.auditLogs?.items === undefined}
                    data={formData}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
          <FullScreenModal
            title="Edit Payment"
            modalHeaderButtons={this.setModalHeaderButtons(formData, accountingIntegration)}
            open={isEditMode}
            handleClose={() => this.setState({ isEditMode: false })}
            fixedHeader
          >
            <PaymentForm
              data={formData}
              onCreateService={service => this.setState(() => ({ formService: service }))}
              onComplete={completedFormData => this.handleOnComplete(completedFormData)}
              isEditMode
            />
          </FullScreenModal>
          <PaymentReceiptModal
            open={paymentReceiptModalOpen}
            onClose={() => this.setState({ paymentReceiptModalOpen: false })}
            payment={formData}
          />
          <SendEmailPopUp
            open={emailModalOpen}
            onModalClose={() => this.setState({ emailModalOpen: false })}
            user={user}
            snackbar={snackbarOn}
            dataType="Send Payment Receipt"
            data={{
              paymentId: formData.id,
              disableAttachments: true,
              disableExpirationDate: true,
              disableViewPdf: true,
              emailSubject: `Payment ${formData.paymentNumber}`,
              pdfFileName: getPaymentPdfName(formData),
              invoiceNumber: formData.paymetNumber,
              logoUrl: formData.sender?.logoUrl,
              sendEmailFn: payload => this.handleSendEmail(payload),
              uploadPdf: () => uploadPaymentPdf(formData),
              replyToEmail: formData.sender?.email,
              customer: formData.customer,
              customerProperty: formData.customerProperty,
              billingCustomer: formData.billingCustomer
            }}
          />
        </UserPermission>
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  company: state.company,
  application: state.application,
  menu: state.menu
});

const mapPaymentsToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const reduxConnectedPaymentDetail = connect(mapStateToProps, mapPaymentsToProps)(PaymentDetail);

export default withStyles(styles)(reduxConnectedPaymentDetail);
