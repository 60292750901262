import { createContext } from 'react';

const ViewContext = createContext({
  views: [],
  viewsLoaded: false,
  setViewsLoaded: () => {},
  setViews: () => {}
});

export default ViewContext;
