import moment from 'moment';
import { object, number } from 'yup';

const MAX_DATE = moment('3000-01-01').unix();
const MIN_DATE = moment('1900-01-01').unix();

export default object({
  issuedDate: number()
    .nullable()
    .required('Issued date is required')
    .max(MAX_DATE, 'Date is out of range')
    .min(MIN_DATE, 'Date is out of range'),
  dueDate: number()
    .nullable()
    .required('Due date is required')
    .max(MAX_DATE, 'Date is out of range')
    .min(MIN_DATE, 'Date is out of range')
});
