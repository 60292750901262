import * as React from 'react';
import gql from 'graphql-tag';
import ErrorBoundaries from 'scenes/Error';
import { PermissionConstants, MultiSelectTypes } from 'utils/AppConstants';
import { PageHeader, UserPermission, XGrid } from 'components';
import { useSelector } from 'react-redux';
import CreateEntryButton from 'components/Buttons/CreateEntryButton';
import { column, ColumnType, valueGetters } from 'components/XGrid/columnTypes';
import { EnumType } from 'utils/constants';

const GET_CUSTOMERS = gql`
  query getCustomersList(
    $tenantId: String
    $filter: TableFilterInput
    $pagination: PaginationInput
    $sorting: [TableSortingInput]
  ) {
    data: getCustomersList(
      tenantId: $tenantId
      filter: $filter
      pagination: $pagination
      sorting: $sorting
    ) {
      rowCount
      items
    }
  }
`;

/**
 * We can either massage the data here through valueGetter
 * or we can format the data before it goes into the table.
 */
const customerColumns = [
  {
    field: 'customerName',
    headerName: 'Customer',
    width: 200,
    dataField: 'id',
    valueGetter: valueGetters.customerLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    enumType: EnumType.CUSTOMER_STATUS,
    ...column[ColumnType.ENUM]
  },
  {
    field: 'propertiesCount',
    headerName: 'Number of Properties',
    width: 200,
    ...column[ColumnType.NUMBER]
  },
  {
    field: 'openJobsCount',
    headerName: 'Open Jobs',
    width: 130,
    ...column[ColumnType.NUMBER]
  },
  {
    field: 'openJobsValue',
    headerName: 'Open Jobs Value',
    width: 170,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'outstandingBalance',
    headerName: 'Outstanding Balance',
    width: 210,
    isBalance: true,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'overdueBalance',
    headerName: 'Overdue Balance',
    width: 170,
    isBalance: true,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'billingAddress',
    headerName: 'Billing Address',
    width: 250,
    ...column[ColumnType.ADDRESS]
  },
  {
    field: 'businessAddress',
    headerName: 'Business Address',
    width: 250,
    ...column[ColumnType.ADDRESS]
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'createdDate',
    headerName: 'Created On',
    width: 150,
    ...column[ColumnType.DATE]
  },
  {
    field: 'customerTypeValue',
    headerName: 'Customer Type',
    width: 200,
    enumType: MultiSelectTypes.CUSTOMER_TYPES,
    ...column[ColumnType.ENUM]
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 200,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'phonePrimary',
    headerName: 'Phone',
    width: 130,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'customerTags',
    headerName: 'Tags',
    width: 100,
    enumType: MultiSelectTypes.CUSTOMER_TAGS,
    ...column[ColumnType.TAGS]
  },
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    filterable: false,
    isEditLink: true,
    dataField: 'id',
    valueGetter: valueGetters.customerLink,
    ...column[ColumnType.EDITLINK]
  }
];

const CustomerList = ({ history }) => {
  const user = useSelector(state => state.user);

  const pageHeaderButtons = [
    <CreateEntryButton
      handleAdd={() => history.push('/customer/new')}
      label="New Customer"
      caslKey={PermissionConstants.OBJECT_CUSTOMER}
      key="createCustomer"
    />
  ];

  return (
    <ErrorBoundaries>
      <UserPermission I="read" action={PermissionConstants.OBJECT_CUSTOMER}>
        <PageHeader
          pageMapKey="customers"
          userLocale={user.locale}
          overrideHeaderButtons={pageHeaderButtons}
        />
        <XGrid
          columns={customerColumns}
          entityTypeName="Customers"
          tableName="CustomerXGrid"
          query={GET_CUSTOMERS}
          enableExport
        />
      </UserPermission>
    </ErrorBoundaries>
  );
};

export default CustomerList;
