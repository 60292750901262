import gql from 'graphql-tag';

const getQuoteProductsById = gql`
  query getQuoteById($id: String!, $sort: [SortingInput]) {
    getQuoteById(id: $id) {
      id
      quoteLineProducts: quoteLineProductsView(
        entityConnection: "QuoteLineProduct"
        sorting: $sort
      ) {
        nextToken
        items {
          id
          name
          description
          sortOrder
          product(entityConnection: "Product") {
            id
            name
            isActive
            code
          }
        }
      }
    }
  }
`;
export default getQuoteProductsById;
