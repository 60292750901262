/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getItemGlGroups(relations = null) {
  return await instance
    .get(relations ? `itemglgroups?include=${relations}` : `itemglgroups`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getItemGlGroupById(id) {
  return await instance
    .get(`itemglgroups/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function itemGlGroupSearch(term, searchColumns) {
  if (term.length > 0) {
    return await instance
      .post(`itemglgroups/search/${term}`, {
        searchColumns: searchColumns || ['name']
      })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  }
  return await getItemGlGroups();
}
