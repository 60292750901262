import gql from 'graphql-tag';

const getPropertyInfoByJob = gql`
  query getJobDetails($partitionKey: String!, $sortKey: String!) {
    getJob(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      tenantId
      hierarchy
      entityType
      jobNumber
      customIdentifier
      parentEntity {
        ... on CustomerProperty {
          companyName
          customerPropertyTypeValue
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          isActive
          status
          parentEntity {
            ... on Customer {
              customerName
            }
          }
          propertyAssets(entityConnection: "PropertyAsset") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              assetName
              imageUrl
              make
              version
              modelNumber
              serialNo
              installDate
            }
          }
          notes(entityConnection: "Note") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              subject
              note
              createdBy
              lastUpdatedDate
              lastUpdatedDateTime
              lastUpdatedBy
            }
          }
          companyAddresses(entityConnection: "CompanyAddress") {
            items {
              id
              addressType
              addressLine1
              addressLine2
              city
              state
              zipcode
              longitude
              latitude
            }
          }
          customerReps(entityConnection: "CustomerRep") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              invertedSortKey
              hierarchy
              entityType
              mappedEntity {
                ... on CustomerRep {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  name
                  firstName
                  lastName
                  email
                  cellPhone
                  landlinePhone
                  isSmsOptOut
                  bestContact
                  contactRole
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getPropertyInfoByJob;
