import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { bool, func, string } from 'prop-types';
import * as R from 'ramda';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { EVENT_TYPES } from '@dispatch/Dispatch.constants';
import { watchedQueries } from '@dispatch/Dispatch.store';
import { handleError } from '@dispatch/Dispatch.utils';
import { DragScenarios, ItemTypes, selectDragScenario } from '@dispatch/dnd';
import { GET_VISITS, transformVisitRow } from '@dispatch/queries';
import { getHttpClient } from 'client';
import ResponsiveTable from 'components/ResponsiveTable';
import { getTenantSettingValueForKey } from 'utils';

import Duration from './components/Duration';
import JobNumber from './components/JobNumber';
import Maintenance from './components/Maintenance';
import Priority from './components/Priority';
import ServiceChannel from './components/ServiceChannel';
import TechniciansList from './components/TechniciansList';
import TentativeDate from './components/TentativeDate';
import { tableMetadata } from './VisitsTable.meta';

const getVisitStatusFilters = status => {
  if (status) {
    return [
      {
        fieldName: 'status',
        filterInput: { eq: status }
      }
    ];
  }
  return [];
};

const listDataService = (visitStatus, crewTimeTracking) => {
  return async (columns, filter, sorting, pagination) => {
    const visitStatusFilters = getVisitStatusFilters(visitStatus);

    const filterWithStatus = {
      ...filter,
      stringFilters: [...(filter?.stringFilters || []), ...visitStatusFilters]
    };

    try {
      const variables = {
        columns,
        filter: filterWithStatus,
        sorting,
        pagination
      };

      watchedQueries.listDataService = {
        query: GET_VISITS,
        variables
      };

      const { data } = await getHttpClient().query({
        query: GET_VISITS,
        variables
      });

      if (!data?.visitsListData) {
        return {};
      }

      let visitsListData = {
        ...data.visitsListData
      };
      if (crewTimeTracking) {
        visitsListData = R.evolve(
          {
            meta: R.filter(x => !['extraTechsRequired', 'extraTechsNumber'].includes(x.id))
          },
          data.visitsListData
        );
      }

      return visitsListData;
    } catch (err) {
      handleError({ err, errContext: 'listDataService', hideError: true });
    }
  };
};

const isDragEligible = data => {
  const dragScenario = selectDragScenario({ type: ItemTypes.TABLE_VISIT, data });

  return dragScenario !== DragScenarios.NO_DRAG;
};

const VisitsTable = ({
  visitStatus,
  tableName,
  hoverVisitRow,
  clearVisitRowHover,
  openEvent,
  refreshTrigger,
  refreshQuietly,
  resetPagination,
  disableQuietRefresh
}) => {
  const flags = useFlags();
  const crewTimeTracking = getTenantSettingValueForKey('crewTimeTracking') === 'true';

  const handleMouseEnterRow = (e, data, isDnd) => {
    if (isDnd) hoverVisitRow(data);
  };

  const handleMouseLeaveRow = (e, data, isDnd) => {
    if (isDnd) clearVisitRowHover(data);
  };

  const handleRowClick = (e, data) => {
    openEvent({
      eventId: data.id,
      eventType: EVENT_TYPES.VISIT
    });
  };

  const handleRefreshCompleted = () => {
    disableQuietRefresh();
  };

  return (
    <ResponsiveTable
      dragItemName={ItemTypes.TABLE_VISIT}
      dragPreviewImg={getEmptyImage()}
      dragEligible={isDragEligible}
      dragTransform={transformVisitRow}
      tableName={`Dispatch Board Tray Visit Table ${tableName}`}
      rowMetadata={tableMetadata(tableName, flags, crewTimeTracking)}
      allowDynamicRowMetadata
      showToolbars
      listDataService={listDataService(visitStatus, crewTimeTracking)}
      resetPagination={resetPagination}
      refreshData={refreshTrigger}
      refreshQuietly={refreshQuietly}
      showFilters
      customCellComponents={{
        Duration,
        JobNumber,
        Maintenance,
        Priority,
        ServiceChannel,
        TechniciansList,
        TentativeDate
      }}
      onRefetchCompleted={handleRefreshCompleted}
      onMouseEnterRow={handleMouseEnterRow}
      onMouseLeaveRow={handleMouseLeaveRow}
      onRowClick={handleRowClick}
    />
  );
};

VisitsTable.propTypes = {
  clearVisitRowHover: func.isRequired,
  hoverVisitRow: func.isRequired,
  tableName: string.isRequired,
  visitStatus: string,
  openEvent: func.isRequired,
  refreshTrigger: bool.isRequired,
  refreshQuietly: bool.isRequired,
  disableQuietRefresh: func.isRequired,
  resetPagination: bool.isRequired
};

VisitsTable.defaultProps = {
  visitStatus: undefined
};

export default VisitsTable;
