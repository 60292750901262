import gql from 'graphql-tag';

const sendInvoice = gql`
  mutation sendInvoice($partitionKey: String, $data: AddEmailToInvoiceInput!) {
    addEmailToInvoice(partitionKey: $partitionKey, data: $data) {
      id
    }
  }
`;

export default sendInvoice;
