import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Select, ThemeProvider } from '@buildhero/sergeant';
import { jsx } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

import { PageHeader, Spinner } from 'components';
import DefaultButton from 'components/Buttons/DefaultButton';
import { Tab, Tabs } from 'components/Tabs';
import useCrews from 'customHooks/useCrews';
import useEmployees from 'customHooks/useEmployees';
import Labels from 'meta/labels';
import ErrorBoundaries from 'scenes/Error';
import {
  getPayrollHourTypes,
  handleTimesheetDownload
} from 'scenes/Payroll/TimeTrackingReport/services';
import { getTenantSettingValueForKey, isJSONParseableObjectOrArray } from 'utils';
import { AccountingApp } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { reportTypes, tabType } from '../Payroll/TimeTrackingReport/constants';

import { statusTabs, timesheetViews } from './constants';
import {
  getEmployeeNumberOfDisputedDayCards,
  getEmployeeNumberOfSubmittedDayCards,
  getTimeOfOldestTimesheetThatNeedsReview,
  getTimesheetPeriods,
  updateTimesheetUrl
} from './services';
import PendingRevision from './Tabs/BindersPendingRevision';
import ToReview from './Tabs/BindersToReview';
import TimesheetFilter from './TimesheetFilter';
import { timesheetFilter } from './TimesheetFilter/utils';

const useStyles = () => ({
  tabsContainer: () => ({ marginTop: 36 }),
  buttons: () => ({ padding: '5px' })
});

const headerStyles = makeStyles(() => ({
  pageTitleContainer: { maxWidth: '200px' },
  controlsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    top: 40,
    marginTop: -40,
    position: 'relative'
  }
}));

const Timesheets = ({ payrollSettings, dateUnix, employeeId, ...props }) => {
  const flags = useFlags();
  const classes = useStyles();
  const user = useSelector(state => state.user);

  const [employees] = useEmployees({
    filter: { isActive: { eq: true }, isTech: { eq: true } },
    includeDepartments: true
  });

  const [crews] = useCrews();

  const [filter, setFilter] = useState({});

  const employeeOptions = useMemo(() => timesheetFilter({ filter, employees, crews }), [
    employees,
    filter,
    crews
  ]);

  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [isExportDisabled, setIsExportDisabled] = useState(true);
  const [selectedTab, setSelectedTab] = useState('');
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isADPExportLoading, setIsADPExportLoading] = useState(false);

  const [payrollHourTypes, setPayrollHourTypes] = useState([]);

  const [numDaycardsToReview, setNumDaycardsToReview] = useState(0);
  const [numDaycardsPending, setNumDaycardsPending] = useState(0);

  const [exportSelectedDate, setExportSelectedDate] = useState({});

  const [defaultReviewDay, setDefaultReviewDay] = useState();

  const [timesheetPeriods, setTimesheetPeriods] = useState([]);
  const [isFetchingTimesheetPeriods, setIsFetchingTimesheetPeriods] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState();

  const [dayOrWeek, setDayOrWeek] = useState(timesheetViews.DAY);
  const [toReviewSelectedDate, setToReviewSelectedDate] = useState();
  const [approvedSelectedDate, setApprovedSelectedDate] = useState();

  const [dirty, setDirty] = useState(false);

  const accountingApp = getTenantSettingValueForKey('accountingApp');
  const settingsString = getTenantSettingValueForKey('accountingAppSettings');

  const setSelectedPeriodFromDate = useCallback(
    date => {
      const period =
        timesheetPeriods.find(
          ({ dateStartUTC, dateEndUTC }) => dateStartUTC <= date && date <= dateEndUTC
        ) || {};
      setSelectedPeriod(period);
    },
    [timesheetPeriods]
  );

  const ADPSettings =
    accountingApp === AccountingApp.INTACCT &&
    isJSONParseableObjectOrArray(settingsString) &&
    JSON.parse(settingsString);

  const updateNumDaycardsToReview = async employee => {
    setNumDaycardsToReview(0);
    const numDaysToReview = await getEmployeeNumberOfSubmittedDayCards({
      employee,
      snackbarOn: props.snackbarOn
    });
    setNumDaycardsToReview(numDaysToReview);
  };

  const updateNumDaycardsPending = async employee => {
    setNumDaycardsPending(0);
    const numPendingDays = await getEmployeeNumberOfDisputedDayCards({
      employee,
      snackbarOn: props.snackbarOn
    });

    setNumDaycardsPending(numPendingDays);
  };

  useEffect(() => {
    if (employeeId) {
      const employee = employees.find(({ id }) => id === employeeId);
      if (employee) {
        setSelectedEmployee({
          label: employee.code ? `${employee.name} (${employee.code})` : employee.name,
          value: employee
        });
      }
    }
  }, [employeeId, employees]);

  useEffect(() => {
    (async () => {
      setIsFetchingTimesheetPeriods(true);
      setIsExportDisabled(true);
      if (selectedEmployee) {
        const [
          timeOfOldestTimesheetThatNeedsReview,
          timesheetPeriodsForSelectedEmployee
        ] = await Promise.all([
          getTimeOfOldestTimesheetThatNeedsReview({
            employee: selectedEmployee.value,
            snackbarOn: props.snackbarOn
          }),
          getTimesheetPeriods({
            employee: selectedEmployee.value,
            snackbarOn: props.snackbarOn
          }),
          updateNumDaycardsToReview(selectedEmployee.value),
          updateNumDaycardsPending(selectedEmployee.value)
        ]);

        setDefaultReviewDay(dateUnix || timeOfOldestTimesheetThatNeedsReview || moment().unix());
        updateTimesheetUrl({ employee: selectedEmployee.value, date: defaultReviewDay });
        setTimesheetPeriods(timesheetPeriodsForSelectedEmployee);
        setSelectedPeriodFromDate(defaultReviewDay);
        setIsFetchingTimesheetPeriods(false);
      }
    })();
  }, [selectedEmployee]);

  useEffect(() => {
    getPayrollHourTypes({
      user,
      snackbarOn: props.snackbarOn,
      successCallback: setPayrollHourTypes
    });
  }, []);

  const handleExportClick = async summaryType => {
    setIsExportLoading(true);
    await handleTimesheetDownload({
      snackbarOn: props.snackbarOn,
      selectedTimesheetPeriod: exportSelectedDate,
      summaryType
    });
    setIsExportLoading(false);
  };

  const handleADPExportClick = async summaryType => {
    setIsADPExportLoading(true);

    await handleTimesheetDownload({
      snackbarOn: props.snackbarOn,
      selectedTimesheetPeriod: exportSelectedDate,
      summaryType,
      settings: { ADPSettings }
    });
    setIsADPExportLoading(false);
  };

  const changeTab = async (_, tab) => {
    const selectedDate =
      (tab === tabType.APPROVED ? approvedSelectedDate : toReviewSelectedDate) || defaultReviewDay;
    setSelectedPeriodFromDate(selectedDate);
    setSelectedTab(tab);
    setDirty(false);
  };

  const sharedTabProps = {
    selectedEmployee: selectedEmployee?.value,
    payrollHourTypes,
    timesheetPeriods,
    payrollSetting: payrollSettings,
    snackbarOn: props.snackbarOn,
    setExportSelectedDate,
    setIsExportDisabled,
    dayOrWeek,
    setDayOrWeek,
    selectedPeriod,
    setSelectedPeriod
  };

  return (
    <ErrorBoundaries>
      <Grid>
        <PageHeader
          pageMapKey="timesheets"
          userLocale={user.locale}
          breadcrumbsArray={[
            { title: Labels.accountingLabel[user.locale], link: '' },
            { title: Labels.payroll[user.locale], link: '/accounting/payroll/report/time-tracking' }
          ]}
          overrideHeaderButtons={[
            <Grid css={classes.buttons}>
              <DefaultButton
                onClick={() => handleExportClick(reportTypes.MANUAL)}
                label="Export Manual Timesheet"
                disabled={isExportDisabled || isExportLoading || selectedTab === tabType.PENDING}
                showSpinner={isExportLoading}
              />
            </Grid>,
            flags[FeatureFlags.ADP_EXPORT] ? (
              <Grid css={classes.buttons}>
                <DefaultButton
                  onClick={() => handleADPExportClick(reportTypes.ADP)}
                  label="ADP Export"
                  showSpinner={isADPExportLoading}
                  disabled={isADPExportLoading}
                />
              </Grid>
            ) : null
          ]}
          classes={headerStyles()}
          justifyChildren="flex-start"
        >
          <ThemeProvider>
            <Select
              searchable
              menuHeight={230}
              options={employeeOptions}
              value={selectedEmployee}
              placeholder="Select an employee"
              onChange={employee => {
                setSelectedEmployee(employee);
                setDayOrWeek(timesheetViews.DAY);
                setToReviewSelectedDate();
                setApprovedSelectedDate();
                setSelectedPeriod();
                updateTimesheetUrl({ employee: employee.value });
              }}
              style={{ maxWidth: 406 }}
            />
            <div css={{ width: 180, marginLeft: 20 }}>
              <TimesheetFilter
                employees={employees}
                crews={crews}
                filterBy={setFilter}
                filter={filter}
              />
            </div>
          </ThemeProvider>
        </PageHeader>
      </Grid>

      {/* empty div to fix scroll issue */}
      {!selectedEmployee && <div style={{ height: 300 + 52 }} />}
      {selectedEmployee && isFetchingTimesheetPeriods && <Spinner />}
      {selectedEmployee && !isFetchingTimesheetPeriods && (
        <div css={classes.tabsContainer}>
          <Tabs onChange={changeTab} confirmSwitch={dirty}>
            <Tab label={`To Review (${numDaycardsToReview})`} tabKey={tabType.REVIEW}>
              <ToReview
                {...sharedTabProps}
                onUpdateDayCard={async employee => {
                  updateNumDaycardsToReview(employee);
                  updateNumDaycardsPending(selectedEmployee.value);
                }}
                setDirty={setDirty}
                selectedDate={toReviewSelectedDate || defaultReviewDay}
                setSelectedDate={date => {
                  setToReviewSelectedDate(date);
                  setSelectedPeriodFromDate(date);
                }}
                user={user}
              />
            </Tab>
            <Tab label={`Pending Revision (${numDaycardsPending})`} tabKey={tabType.PENDING}>
              <PendingRevision
                selectedEmployee={selectedEmployee.value}
                payrollHourTypes={payrollHourTypes}
                timesheetPeriods={timesheetPeriods}
                payrollSetting={payrollSettings}
                snackbarOn={props.snackbarOn}
                setNumDaycardsPending={setNumDaycardsPending}
                unsubmittedEvents={[]}
              />
            </Tab>
            <Tab label="Approved" tabKey={tabType.APPROVED}>
              <ToReview
                {...sharedTabProps}
                tab={statusTabs.APPROVED}
                onUpdateDayCard={employee => updateNumDaycardsToReview(employee)}
                selectedDate={approvedSelectedDate || defaultReviewDay || moment().unix()}
                setSelectedDate={date => {
                  setApprovedSelectedDate(date);
                  setSelectedPeriodFromDate(date);
                }}
                user={user}
              />
            </Tab>
          </Tabs>
        </div>
      )}
    </ErrorBoundaries>
  );
};

export default Timesheets;
