import React, { useRef, useState } from 'react';

import { IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { PopperMenu } from 'components';
import { snackbarOn } from 'redux/actions/globalActions';

import { Logger } from 'services/Logger';
import { allJobStatusActions } from 'utils/constants';

import { useJobStatusHelper } from '../../JobCloseout/JobCloseoutHeader/hooks/useJobStatusHelper';

const propTypes = {
  job: PropTypes.shape({
    id: PropTypes.string,
    version: PropTypes.string,
    status: PropTypes.number
  }).isRequired,
  visits: PropTypes.array.isRequired,
  snackbar: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired
};

function JobStatusButtons({ job, visits, snackbar, updateStatus }) {
  const [jobStatusService, jobStatusActions, actionButtons] = useJobStatusHelper({ job, visits });

  const actionsToShow = {
    putOnHold: jobStatusActions.includes(allJobStatusActions.ON_WEB_HOLD),
    reopen: jobStatusActions.includes(allJobStatusActions.ON_REOPEN) && visits?.length === 0,
    continue: jobStatusActions.includes(allJobStatusActions.ON_CONTINUE) && visits?.length > 0,
    cancel: jobStatusActions.includes(allJobStatusActions.ON_CANCEL),
    complete: jobStatusActions.includes(allJobStatusActions.ON_COMPLETE)
  };

  const buttonParamList = Object.keys(actionButtons)
    .filter(actionKey => actionsToShow[actionKey])
    .map(actionKey => {
      const actionButton = actionButtons[actionKey];
      return {
        label: actionButton.label,
        onClick: () => {
          try {
            const currentState = jobStatusService.send(actionButton.actionConstant);
            updateStatus({
              status: currentState.value,
              version: currentState.context.version
            });
          } catch (e) {
            Logger.error(e);
            snackbar('warning', actionButton.errorMessage);
          }
        }
      };
    });

  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef();
  const handleClick = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const theme = useTheme();

  if (isEmpty(buttonParamList)) return null;

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleClick}
        disableRipple
        style={{ padding: 0, margin: 5 }}
      >
        <MoreHorizIcon style={{ fontSize: 24, color: theme.palette.grayscale(20) }} />
      </IconButton>
      <PopperMenu
        itemList={buttonParamList}
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      />
    </>
  );
}

JobStatusButtons.propTypes = propTypes;

export default connect(null, { snackbar: snackbarOn })(JobStatusButtons);
