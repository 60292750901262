import gql from 'graphql-tag';

const upsertPaymentsToCompany = gql`
  mutation upsertPaymentsToCompany($partitionKey: String!, $data: UpsertPaymentsToCompanyInput!) {
    upsertPaymentsToCompany(partitionKey: $partitionKey, data: $data) {
      id
      version
      paymentStatus
      exportStatus
      syncStatus
      syncLog
    }
  }
`;

export default upsertPaymentsToCompany;
