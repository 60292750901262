import { productSearch } from 'services/API/product';
import { generateDefaultFieldsObject } from '@pm/components/formattingUtils';

const margin = '0px 15px 21px 0px';

// Material Information
const materialFields = {
  material: { required: false },
  name: { required: false },
  description: { required: false },
  quantity: { required: false },
  unitOfMeasure: { required: false },
  unitOfMeasureName: { required: false }
};

const materialContents = [
  {
    options: {
      margin
    },
    contents: [
      {
        component: {
          default: 'SearchBar',
          edit: 'SearchBar'
        },
        source: 'material',
        options: {
          label: 'Search Material',
          fullWidth: false,
          searchFunction: productSearch,
          resultFormatFunction: product => product.name,
          placeholder: 'Search',
          alignment: 'left',
          variant: 'standard',
          color: 'secondary'
        }
      }
    ]
  },
  {
    options: {
      direction: 'row'
    },
    contents: [
      // column 1
      {
        options: {
          direction: 'column'
        },
        contents: [
          {
            options: {
              margin
            },
            contents: [
              {
                component: {
                  default: 'TextInput',
                  edit: 'TextInput'
                },
                source: 'name',
                options: {
                  label: 'Name',
                  fullWidth: true,
                  placeholder: 'Enter Product Name',
                  alignment: 'left',
                  variant: 'standard',
                  color: 'secondary',
                  multiline: false
                }
              }
            ]
          },
          {
            options: {
              direction: 'row'
            },
            contents: [
              {
                options: {
                  margin,
                  direction: 'column'
                },
                contents: [
                  {
                    component: {
                      default: 'TextInput',
                      edit: 'TextInput'
                    },
                    source: 'quantity',
                    options: {
                      label: 'Quantity',
                      fullWidth: true,
                      alignment: 'left',
                      variant: 'standard',
                      color: 'secondary',
                      type: 'number',
                      min: 0
                    }
                  }
                ]
              },
              {
                options: {
                  margin,
                  direction: 'column'
                },
                contents: [
                  {
                    component: {
                      default: 'TextInput',
                      edit: 'TextInput'
                    },
                    source: 'unitOfMeasureName',
                    options: {
                      label: 'Unit of Measure',
                      fullWidth: true,
                      alignment: 'left',
                      variant: 'standard',
                      color: 'secondary',
                      multiline: false
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      // column 2
      {
        options: {
          direction: 'column'
        },
        contents: [
          {
            options: {
              margin,
              direction: 'column'
            },
            contents: [
              {
                component: {
                  default: 'TextInput',
                  edit: 'TextInput'
                },
                source: 'description',
                options: {
                  label: 'Description',
                  placeholder: 'Enter Description',
                  fullWidth: true,
                  alignment: 'left',
                  variant: 'standard',
                  color: 'secondary',
                  multiline: true,
                  lines: 6
                }
              }
            ]
          }
        ]
      },
      // column 3: delete button
      {
        options: {
          direction: 'column'
        },
        contents: [
          {
            options: {
              margin,
              direction: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-start'
            },
            contents: [
              {
                component: {
                  default: 'deleteButton',
                  edit: 'deleteButton'
                }
              }
            ]
          }
        ]
      }
    ]
  }
];

const materialLayout = {
  fields: generateDefaultFieldsObject(materialFields),
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: materialContents
    },
    edit: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: materialContents
    }
  }
};

export { materialFields, materialLayout };
