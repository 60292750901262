import gql from 'graphql-tag';

const deleteProductCategory = gql`
  mutation deleteProductCategoryById($partitionKey: String!, $id: String!) {
    deleteProductCategoryById(partitionKey: $partitionKey, id: $id) {
      id
      name
      sortOrder
    }
  }
`;

export default deleteProductCategory;
