import gql from 'graphql-tag';

const deleteTaskEntry = gql`
  mutation deleteTaskEntry($partitionKey: String!, $id: String!) {
    deleteTaskEntry(partitionKey: $partitionKey, id: $id) {
      id
    }
  }
`;

export default deleteTaskEntry;
