import gql from 'graphql-tag';

const updateScheduleQuery = gql`
  mutation updateSchedule($input: UpdateScheduleInput!) {
    updateSchedule(input: $input) {
      id
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      version
      onRoute
      delayed
      delayedReason
      onHold
      onHoldReason
      status
    }
  }
`;

export default updateScheduleQuery;
