export const sanitizeAddressInput = (address = {}) => ({
  addressType: address.addressType,
  billTo: address.billTo,
  addressLine1: address.addressLine1,
  addressLine2: address.addressLine2,
  city: address.city,
  state: address.state,
  zipcode: address.zipcode
});

const selectValidAddress = (
  addresses = [],
  { throwError, sanitizeAddressInput: shouldSanitizeAddressInput } = {}
) => {
  [...addresses].sort((a, b) => {
    return Number.parseInt(a.lastUpdatedDateTime, 10) - Number.parseInt(b.lastUpdatedDateTime, 10);
  });

  let validAddress;

  for (let i = 0; i < addresses.length; i += 1) {
    const address = addresses[i];

    if (
      address.isActive &&
      address.addressLine1 &&
      address.city &&
      address.state &&
      address.zipcode
    ) {
      validAddress = address;
    }
  }

  if (throwError && !validAddress) {
    throw new Error(
      `No valid address found. Address must have address line 1, city, state, and zip code.
        ${addresses.reduce(
          (message, address) => {
            return `${message}
            address line 1: ${address.addressLine1}
            city: ${address.city}
            state: ${address.state}
            zip code: ${address.zipcode}

          `;
          },
          `
          Addresses Found:
        `
        )}
      `
    );
  }

  return shouldSanitizeAddressInput ? sanitizeAddressInput(validAddress) : validAddress;
};

export default selectValidAddress;
