import React, { useState } from 'react';
import { SergeantModal } from 'components';
import { BillLineItemStatus } from 'utils/AppConstants';
import { useSetStopLoading } from '../ReviewReport/ReviewReport.hook';
import { withBuildHeroTheme } from '../../../../themes/BuildHeroTheme';

const BillModalOld = ({ billOptions, data, mode, open, reviewReportId, send, service, title }) => {
  const setStopLoading = useSetStopLoading(service);
  const [checkedBills, setCheckedBills] = useState([]);
  const handlePrimaryAction = async (values, stopLoading) => {
    /*
    Since bills can have multiple line items with differing invoice statuses,
    each qualifying line item must be extracted from the bill for payload
    */
    if (!checkedBills.length) {
      return send('CLOSE');
    }

    const billItemsToAdd = [];
    checkedBills.forEach(checkedBill => {
      checkedBill.billLineItems.forEach(lineItem => {
        if (lineItem.invoicedStatus !== BillLineItemStatus.INVOICED && lineItem.quantity) {
          billItemsToAdd.push({
            billLineId: lineItem.billLineId,
            description: lineItem.description,
            quantity:
              lineItem.remainingQuantity && lineItem.remainingQuantity !== 0
                ? lineItem.remainingQuantity
                : lineItem.quantity,
            taxable: lineItem.taxable,
            reviewReportId
          });
        }
      });
    });
    const saveData = { ...values, billItemsToAdd };
    setStopLoading(stopLoading);
    send('SAVE', { saveData });
  };

  const handleCheckboxChange = (checkedProps, bill) => {
    if (checkedProps) {
      setCheckedBills([...checkedBills, bill]);
    } else {
      setCheckedBills(checkedBills.filter(elem => elem.billId !== bill.billId));
    }
  };

  const billLayout = () => {
    return {
      fields: {},
      layouts: {
        default: {
          contents: [
            {
              contents: billOptions.map(bill => ({
                contents: [
                  {
                    options: {
                      label: bill.billNumber,
                      onChange: props => handleCheckboxChange(props, bill)
                    },
                    component: 'CheckboxInput',
                    source: checkedBills.includes(bill.billId) ? null : bill.billId
                  }
                ]
              }))
            }
          ]
        }
      }
    };
  };

  return (
    <SergeantModal
      disablePrimaryButton={!billOptions.length}
      open={open}
      data={{ ...data }}
      mode={mode}
      formVersion="edit"
      layout={billLayout()}
      handlePrimaryAction={handlePrimaryAction}
      handleClose={() => send('CLOSE')}
      title={billOptions.length ? title : 'No Bills To Add'}
    />
  );
};

export default withBuildHeroTheme(BillModalOld);
