export const visitDetailsFragment = `
  id
  actualDuration
  departmentName
  departmentId
  description
  detailsSent
  extraTechs {
    items {
      id
      mappedEntityId
    }
  }
  extraTechsNumber
  extraTechsRequired
  job: parentEntity {
    ... on Job {
      id
      customIdentifier
      customerId
      customerName
      property: customerProperty {
        id
        companyName
        companyAddresses {
          items {
            id
            addressType
            addressLine1
            addressLine2
            city
            state
            zipcode
            latitude
            longitude
          }
        }
      }
      customerRep {
        id
        name
        email
        cellPhone
      }
      customerPropertyName
      jobNumber
      procurementStatus
      jobTypeInternal
      priority
      status
      purchaseOrders { 
        items { 
          status
        } 
      }
    }
  }
  onHold
  onHoldReason
  prerequisites
  prerequisitesAcknowledged
  primaryTechs {
    items {
      id
      mappedEntityId
    }
  }
  scheduledFor
  schedules {
    items {
      id
      status
      onHoldReason
      onHold
      lastUpdatedDateTime
      employee {
        id
      }
      timeSheets {
        items {
          id
        }
      }
    }
  }
  status
  version
  visitNumber
`;

export const emptyVisitDetailsFragment = {
  __typename: 'Visit',
  id: 'optimistic-visit-id',
  actualDuration: null,
  departmentName: null,
  departmentId: null,
  description: null,
  detailsSent: null,
  extraTechs: {
    __typename: 'SystemEntityMapConnection',
    items: []
  },
  extraTechsNumber: null,
  extraTechsRequired: null,
  job: {
    __typename: 'Job',
    id: 'optimistic-job-id',
    customIdentifier: null,
    customerId: null,
    customerName: null,
    property: {
      __typename: 'CustomerProperty',
      id: 'optimistic-property-id',
      companyName: null,
      companyAddresses: {
        __typename: 'CompanyAddressConnection',
        items: []
      }
    },
    customerRep: null,
    customerPropertyName: null,
    jobNumber: null,
    jobTypeInternal: null,
    priority: null,
    procurementStatus: null
  },
  onHold: null,
  onHoldReason: null,
  prerequisites: null,
  prerequisitesAcknowledged: null,
  primaryTechs: {
    __typename: 'SystemEntityMapConnection',
    items: []
  },
  scheduledFor: null,
  schedules: {
    __typename: 'ScheduleConnection',
    items: []
  },
  status: null,
  version: null,
  visitNumber: null
};
