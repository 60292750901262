import _ from 'lodash';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import AppConstants from 'utils/AppConstants';
import removeObjProperties from 'utils/removeObjProperties';
import { reviewedStatus } from './constants';

export const secondsToHour = seconds => {
  if (!seconds) return 0;
  const value = seconds / 3600;
  const hour = value !== 0 ? value.toFixed(2) : value;
  return hour;
};
export const hourToSeconds = hour => {
  if (!hour) return hour;
  return parseFloat((hour * 3600).toFixed(2));
};

const getSummaryValue = summary => {
  const [value = 0] = _.compact([summary.total, summary.seconds]);
  return secondsToHour(value);
};

export const checkShift = ({ userActionType }) => (userActionType || '').toLowerCase() === 'shift';

export const getHourSummary = ({ data = [], dailyOverrides = [], hourTypes, dayStartUTC }) => {
  const dataWithoutShift = data.filter(record => !checkShift(record));
  const summary = hourTypes.map(({ id, ...type }) => {
    const overrideValue =
      dailyOverrides.find(({ hourTypeId, dateUTC }) => {
        return hourTypeId === id && dateUTC === dayStartUTC;
      }) || {};

    const seconds = dataWithoutShift.reduce((total, report) => {
      if (id === report.hourTypeId) {
        const { actualTotalDurationOverride, actualTotalDuration } = report;
        const value = Number.isInteger(actualTotalDurationOverride)
          ? actualTotalDurationOverride
          : actualTotalDuration;
        return total + (Number(value) || 0);
      }
      return total;
    }, 0);

    const hour = getSummaryValue({ ...overrideValue, seconds });
    const actualTotalDurationOverride = Number.isInteger(overrideValue.actualTotalDurationOverride)
      ? overrideValue.actualTotalDurationOverride
      : undefined;

    return { ...type, hourTypeId: id, hour, ...overrideValue, actualTotalDurationOverride };
  });
  return summary;
};

export const formatTime = (time, format = 'hh:mm') => {
  if (!time) return '';
  return moment.unix(time).format(format);
};

export const getNotes = (reports = [], employeeId) => {
  const notes = reports.reduce((acc, item) => {
    const { billableEntity, timekeepingLedger } = item;
    const billableEntityNotes = billableEntity?.timesheetNotes?.items || [];
    const timekeepingLedgerNotes = timekeepingLedger?.eventEntity?.timesheetNotes?.items || [];
    const entryDetail = getAssignmentName(item);
    const reportNotes = [...billableEntityNotes, ...timekeepingLedgerNotes]
      .map(note => ({
        ...note,
        entryDetail
      }))
      .filter(n => n.employeeId === employeeId);
    return [...acc, ...reportNotes];
  }, []);
  return _.uniqBy(notes, 'id');
};

export const handleRemove = (reports, index) => {
  const clonedReport = [...reports];
  clonedReport.splice(index, 1);
  return clonedReport;
};

export const handleSplit = (reports, index) => {
  const newReports = [...reports];
  const currentReport = reports[index];

  const commonProperties = {
    isSplitted: true,
    isEdited: true,
    actualTotalDuration: null,
    actualTotalDurationOverride: null
  };

  const report = removeObjProperties(
    {
      actualEndTimeUTC: null,
      actualEndTimeOverrideUTC: null,
      ...currentReport,
      actualStartTimeUTC: null,
      actualStartTimeOverrideUTC: null,
      ...commonProperties
    },
    ['note', 'id']
  );
  newReports[index] = {
    ...reports[index],
    actualEndTimeUTC: null,
    actualEndTimeOverrideUTC: null,
    ...commonProperties
  };
  newReports.splice(index + 1, 0, report);
  return newReports;
};

export const updateTimesheetEntryMultiFields = (timesheetEntries, index, value = {}) => {
  const newEntry = { ...timesheetEntries[index], ...value, isEdited: true };
  const newTimesheetEntries = timesheetEntries.map((entry, ind) => {
    if (ind === index) return newEntry;
    return entry;
  });
  return newTimesheetEntries;
};

export const secondsDifference = (start, end) => {
  const isValid = [start, end].every(val => val);
  if (!isValid) return 0;

  const startTime = moment.unix(start).format('hh:mm:ss a');
  const endTime = moment.unix(end).format('hh:mm:ss a');

  const seconds = moment(endTime, 'hh:mm:ss a').diff(moment(startTime, 'hh:mm:ss a'), 'seconds');
  return seconds;
};

export const updateTimesheetEntryField = (timesheetEntries, index, fieldKey, value) => {
  const timesheetEntry = { ...timesheetEntries[index], isEdited: true };
  timesheetEntry[fieldKey] = value;
  const tempKey = fieldKey.toLowerCase();
  const {
    actualStartTimeOverrideUTC,
    actualStartTimeUTC,
    actualEndTimeOverrideUTC,
    actualEndTimeUTC
  } = timesheetEntry;

  const removeNull = list => list.filter(item => ![null, undefined].includes(item));
  const [start] = removeNull([actualStartTimeOverrideUTC, actualStartTimeUTC]);
  const [end] = removeNull([actualEndTimeOverrideUTC, actualEndTimeUTC]);

  // where an hour increment is 0.01hrs or 36s;
  const roundToNearestHourIncrement = t => Math.round(t / 36) * 36;

  const override = roundToNearestHourIncrement(secondsDifference(start, end));

  if (tempKey.includes('override')) {
    timesheetEntry.actualTotalDurationOverride = override;
  } else if (tempKey.includes('utc')) {
    timesheetEntry.actualTotalDuration = override;
  }

  const newTimesheetEntries = timesheetEntries.map((entry, ind) => {
    if (ind === index) return timesheetEntry;
    return entry;
  });

  return newTimesheetEntries;
};

export const updateHourSelected = (selectedList, value) => {
  const withOutCurrentSelection = selectedList.filter(
    ({ rowIndex }) => rowIndex !== value.rowIndex
  );
  if (selectedList.length === withOutCurrentSelection.length) {
    selectedList.push(value);
    return selectedList;
  }
  return withOutCurrentSelection;
};

export const updateHourSelectedValue = (selectedList, reports, detail) => {
  const selectedRow = [...selectedList.map(({ rowIndex }) => rowIndex), detail.rowIndex];
  const { hourType, hourTypeAbbreviation, id } = detail.value;
  const updatedReports = reports.map((value, index) => {
    if (selectedRow.includes(index)) {
      value.hourTypeId = id;
      value.eventType = `${hourType} (${hourTypeAbbreviation})`;
      value.hourTypeAbbreviation = hourTypeAbbreviation;
      value.isEdited = true;
    }
    return value;
  });
  return updatedReports;
};

export const reviewedCheck = (data = []) => {
  if (data.length === 0) return false;
  const status = data.every(({ reviewStatus: status }) => status === reviewedStatus.reviewed);
  return status;
};

export const summaryEdit = (list, newValue) => {
  const index = list.findIndex(
    ({ hourTypeAbbreviation }) => hourTypeAbbreviation === newValue.hourTypeAbbreviation
  );
  const clonedSummary = [...list];
  clonedSummary[index] = { ...list[index], ...newValue };
  return clonedSummary;
};

export const getHourValue = ({ actualTotalDurationOverride, hour }) => {
  if (Number.isInteger(actualTotalDurationOverride))
    return secondsToHour(actualTotalDurationOverride);
  return hour;
};

export const totalFieldCheck = detail =>
  [detail.hourTypeAbbreviation, detail.calculatedAbbreviation].some(item => item === 'Total');

export const calculatedTotal = (data = []) => {
  const calculatedList = data.filter(
    ({ actualTotalDurationOverride }) => actualTotalDurationOverride !== undefined
  );

  if (calculatedList.length !== 0) {
    const hour = _.sumBy(calculatedList, ({ hour }) => Number(hour));
    return hour !== 0 ? hour.toFixed(2) : 0;
  }
};

export const hourTotal = (data = []) => {
  const total = data.reduce((count, info) => {
    const { hour, actualTotalDurationOverride } = info;
    const value = Number.isInteger(actualTotalDurationOverride)
      ? secondsToHour(actualTotalDurationOverride)
      : hour;

    return count + parseFloat(value);
  }, 0);

  return total.toFixed(2);
};

export const getCustomerInfo = (timeSheetEntry, isProperty = false) => {
  const { billableEntityType, billableEntity, assignmentDetail } = timeSheetEntry;

  if (assignmentDetail) {
    const { customerPropertyName, customerName } = assignmentDetail;
    const value = isProperty ? customerPropertyName : customerName;
    return value;
  }

  if ((billableEntityType || '').toLowerCase() !== 'visit' || !billableEntity) return '-';
  const customerProperty = billableEntity.job?.customerProperty || {};
  if (isProperty) return customerProperty?.companyName || '-';
  return customerProperty?.customer?.customerName || '-';
};

export const getFormatedDate = selectedDate => {
  if (_.isEmpty(selectedDate)) return '';
  const formatDate = date => {
    if (date) return moment.unix(date).format(AppConstants.DATE_FORMAT);
  };
  const { dateStartUTC, dateEndUTC } = selectedDate;
  return `${formatDate(dateStartUTC)} - ${formatDate(dateEndUTC)}`;
};

export const generateWeekDate = ({ dateStartUTC, dateEndUTC }, timeZone) => {
  if (!dateStartUTC && !dateEndUTC) return [];
  const dayUnix = (date, type) => {
    const value = moment.unix(date).format();
    return momentTimeZone.tz(value, timeZone)[type]('day');
  };

  const dayDifference = dayUnix(dateEndUTC, 'endOf').diff(dayUnix(dateStartUTC, 'startOf'), 'days');

  const numberOfDays = Math.round(dayDifference) + 1;

  const dates = [...Array(numberOfDays).keys()].reduce((acc, count) => {
    const [dayStartUTC, dayEndUTC] = ['startOf', 'endOf'].map(type =>
      dayUnix(dateStartUTC, type)
        .add(count, 'days')
        .unix()
    );
    return [...acc, { dayStartUTC, dayEndUTC }];
  }, []);

  return dates;
};

export const getHourTypeLabel = (hourTypes, selectedHourTypeId) => {
  const selectedHourType = hourTypes.find(({ id }) => id === selectedHourTypeId);
  if (!selectedHourType) return '-';
  return `${selectedHourType.hourType} (${selectedHourType.hourTypeAbbreviation})`;
};

export const getTimesheetPeriodSummary = ({ overAllHourSummary, hourTypes }) => {
  const summaryList = _.flattenDeep(Object.values(overAllHourSummary));
  const timePeriodSummary = hourTypes.map(hourType => {
    const items = summaryList.filter(({ hourTypeId }) => hourTypeId === hourType.id);
    const totalHour = items.reduce((acc, val) => {
      return (
        Number(
          Number.isInteger(val.actualTotalDurationOverride)
            ? secondsToHour(val.actualTotalDurationOverride)
            : val.hour
        ) + acc
      );
    }, 0);
    const hour = totalHour !== 0 ? totalHour.toFixed(2) : totalHour;
    return { ...hourType, hour };
  });
  return timePeriodSummary;
};

export const getAssignmentName = (entry = {}) => {
  const { billableEntityType, billableEntity } = entry;
  if (!billableEntityType || !billableEntity) {
    return { assignmentName: '-' };
  }
  const { job } = billableEntity;

  if (billableEntityType.toLowerCase() === 'job') {
    return {
      jobNumber: billableEntity.customIdentifier || billableEntity.jobNumber,
      jobId: billableEntity.id
    };
  } else if (billableEntityType.toLowerCase() === 'visit' && job) {
    return {
      jobNumber: job.customIdentifier || job.jobNumber,
      jobId: job.id,
      visitNumber: billableEntity.visitNumber
    };
  }
  return {};
};

export const getTimesheetCsvData = ({ stringFilters = [], ...exportArgs }) => ({
  mode: 'SELECT',
  exportType: 'excel',
  filter: {
    stringFilters
  },
  ...exportArgs
});

export const checkOverlap = ({ shifts, compareValue, rowIndex, isEndTime = false }) => {
  const list = [...shifts].filter((va, i) => {
    if (isEndTime) return i > rowIndex;
    return i < rowIndex;
  });
  const overlap = list.filter(item => {
    const key = isEndTime ? 'Start' : 'End';
    const time = item[`actual${key}TimeUTC`];
    const override = item[`actual${key}TimeOverrideUTC`];
    const value = override || time;
    const { userActionType, isCustomEntry = false } = item;
    const isShift = checkShift({ userActionType });

    const invalidItemChecks = [
      isCustomEntry,
      isShift && !isEndTime,
      !Boolean(value),
      !Boolean(compareValue)
    ];
    if (invalidItemChecks.some(Boolean)) return false;
    if (isEndTime) {
      return value < compareValue;
    }
    return value > compareValue;
  });

  return !_.isEmpty(overlap);
};

export const calculateTime = ({ value, dayStartUTC }) => {
  const unixValue = value.unix();
  if (!unixValue) return null;
  const [hours, minutes] = value.format('HH:mm').split(':');
  const time = moment.unix(dayStartUTC).set({ hours, minutes });
  return time.unix();
};

export const compareDayWithWeekEnd = (weekend, timezone = '') => {
  if (!weekend) return false;
  const currentDate = momentTimeZone.tz(new Date(), timezone).unix();
  return currentDate > weekend;
};

export const addShiftEndToEntry = (timesheetEntries = []) => {
  const entries = [...timesheetEntries];
  const shiftEntryIndexList = entries.reduce((indexList, { userActionType }, index) => {
    if (checkShift({ userActionType })) {
      indexList.push(index);
    }
    return indexList;
  }, []);

  shiftEntryIndexList.forEach(value => {
    const entry = { ...entries[value] };
    const shiftStart = {
      ...entry,
      customLabel: 'Shift Start',
      actualEndTimeUTC: entry.actualStartTimeUTC,
      isCustomEntry: true
    };
    const shiftEnd = {
      ...entry,
      customLabel: 'Shift End',
      actualStartTimeUTC: entry.actualEndTimeUTC,
      isCustomEntry: true
    };
    entries[value] = shiftStart;
    entries.push(shiftEnd);
  });

  return _.orderBy(entries, ['actualStartTimeUTC', 'actualEndTimeUTC'], ['asc', 'desc']);
};

export const checkHourTypeSelected = (list, index) =>
  list.some(({ rowIndex }) => rowIndex === index);
