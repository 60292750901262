import gql from 'graphql-tag';

const updateLaborLineItem = gql`
  mutation updateLaborLineItem($partitionKey: String, $data: UpdateLaborLineItemInput!) {
    updateLaborLineItem(partitionKey: $partitionKey, data: $data) {
      id
      sortKey
      itemName
      description
      taxable
      departmentName
      accountingRefIdOfClass
      billableTimeInHours
      hourlyCharge
      includeInInvoice
      version
    }
  }
`;

export default updateLaborLineItem;
