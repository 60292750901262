import React, { useState } from 'react';

import { Button, Divider, ThemeProvider } from '@buildhero/sergeant';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { calculateMarginFromMarkup } from '@buildhero/math';
import { FullScreenModal } from 'components';
import { Mode, PricingStrategy } from 'utils/constants';
import { convertForMathLib } from 'utils/mathLibrary';

import LaborForm from './LaborForm';
import { formatLaborLineItemsForDisplay } from './LaborModal.utils';

const styles = makeStyles(() => ({
  addButton: {
    textDecoration: 'none',
    '& span': {
      justifyContent: 'start'
    }
  },
  addIcon: {
    fontSize: 20,
    paddingRight: 5
  },
  container: {
    width: '65%',
    margin: 'auto'
  },
  divider: {
    width: '65%'
  }
}));

function AddLaborModal({
  isLaborModalOpen,
  laborRates,
  payrollHourTypes,
  setIsLaborModalOpen,
  selectedLaborTypes,
  setSelectedLaborTypes,
  updateLaborLineItems,
  item,
  setTaskGroups
}) {
  const [formService, setFormService] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isMarginEnabled = useSelector(s => s.settings.pricingStrategy === PricingStrategy.MARGIN);

  const classes = styles();

  const modalHeaderButtons = [
    <ThemeProvider>
      <Button type="primary" onClick={() => formService?.submit()} loading={isSubmitting}>
        {isLaborModalOpen.type === Mode.ADD ? 'Add Labor' : 'Update Labor'}
      </Button>
    </ThemeProvider>
  ];

  // Still want to display one instance of the Labor Form in modal even if no labor line items exist yet
  const modifiedSelectedLaborTypes = selectedLaborTypes.length ? selectedLaborTypes : [{}];

  return (
    <FullScreenModal
      title="Estimate Task Labor"
      modalHeaderButtons={modalHeaderButtons}
      open={isLaborModalOpen.isOpen}
      handleClose={() => {
        setSelectedLaborTypes(formatLaborLineItemsForDisplay([]));
        setIsLaborModalOpen({
          isOpen: false,
          type: null
        });
      }}
      fixedHeader
    >
      {modifiedSelectedLaborTypes?.map((selection, idx) => {
        let markupValue;
        if (selection?.unitCost === 0 && selection?.totalCost === 0 && isMarginEnabled) {
          markupValue = 100;
        } else if (selection?.unitCost === 0 && selection?.totalCost === 0) {
          markupValue = 'Infinity';
        } else {
          markupValue = selection.markup;
        }
        const formattedSelection = {
          ...selection,
          markup: markupValue,
          margin: convertForMathLib(calculateMarginFromMarkup, markupValue)
        };
        return (
          <div key={`${selection.id}-${idx}`} style={{ position: 'relative' }}>
            <LaborForm
              allSelectedLaborTypes={selectedLaborTypes}
              setFormService={setFormService}
              selectedLaborType={formattedSelection}
              laborRates={laborRates}
              payrollHourTypes={payrollHourTypes}
              idx={idx}
              setSelectedLaborTypes={setSelectedLaborTypes}
              item={item}
              updateLaborLineItems={updateLaborLineItems}
              setIsLaborModalOpen={setIsLaborModalOpen}
              setIsSubmitting={setIsSubmitting}
              setTaskGroups={setTaskGroups}
            />
            {idx !== 0 && (
              <CloseIcon
                style={{
                  position: 'absolute',
                  right: '18%',
                  top: '45px',
                  fontSize: '1.4rem',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  const modifiedLaborTypes = [
                    ...selectedLaborTypes.slice(0, idx),
                    ...selectedLaborTypes.slice(idx + 1)
                  ];
                  setSelectedLaborTypes(modifiedLaborTypes);
                }}
              />
            )}

            <Divider width="65%" margin={20} marginLeft="auto" marginRight="auto" />
          </div>
        );
      })}
      {isLaborModalOpen.type === Mode.ADD && (
        <Box className={classes.container}>
          <ThemeProvider>
            <Button
              type="leading"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => setSelectedLaborTypes(prev => [...prev, {}])}
            >
              Add Labor Type
            </Button>
          </ThemeProvider>
        </Box>
      )}
    </FullScreenModal>
  );
}

export default AddLaborModal;
AddLaborModal.propTypes = {
  isLaborModalOpen: PropTypes.object,
  item: PropTypes.object,
  laborRates: PropTypes.array,
  payrollHourTypes: PropTypes.array,
  selectedLaborTypes: PropTypes.array,
  setIsLaborModalOpen: PropTypes.func,
  setSelectedLaborTypes: PropTypes.func,
  setTaskGroups: PropTypes.func,
  updateLaborLineItems: PropTypes.func
};

AddLaborModal.defaultProps = {
  isLaborModalOpen: {},
  item: {},
  laborRates: [],
  payrollHourTypes: [],
  selectedLaborTypes: [],
  setIsLaborModalOpen: () => {},
  setSelectedLaborTypes: () => {},
  setTaskGroups: () => {},
  updateLaborLineItems: []
};
