import React from 'react';
import PropTypes from 'prop-types';
import { searchIndex } from 'constants/algoliaIndex';
import { Typography, TV, TW } from '@buildhero/sergeant';
import { useTheme } from '@material-ui/core';
import AlgoliaSearch from 'components/SgtAlgoliaSearch/AlgoliaSearch';
import { getState } from 'redux/store';
import { AccountingApp } from 'utils/constants';
import { renderFor } from './InvoiceParentSearch.utils';
import { getRelatedData } from './InvoiceParentSearch.gql';

// Possible parents of a invoice mapped to its display value.
// vista only allows jobs as parent.
const getParents = () =>
  getState().settings.accountingApp === AccountingApp.VISTA
    ? {
        Job: 'Job'
      }
    : {
        Customer: 'Customer',
        CustomerProperty: 'Property',
        Job: 'Job',
        ServiceAgreement: 'Service Agreement',
        Project: 'Project'
      };

const getName = hit =>
  hit.customIdentifier ||
  hit.jobNumber ||
  hit.agreementName ||
  hit.projectName ||
  hit.customerPropertyName ||
  hit.customerName;

export default function InvoiceParentSearch({ field, form, props: givenProps }) {
  const { palette } = useTheme();
  const props = {
    indexName: searchIndex,
    formatHitLabel: getName,
    filters: `(${Object.keys(getParents())
      .map(e => `entityType:${e}`)
      .join(' OR ')}) AND (customerStatus:active OR status:active)`,
    onChange: async hit => {
      form.setFieldValue('loading', true);
      document.body.style.cursor = 'wait';
      const data = await getRelatedData(hit.entityType, hit.id, givenProps.defaultPriceBook);
      form.setValues(
        prev => ({
          ...prev,
          ...data,
          loading: undefined,
          [field.name]: getName(hit)
        }),
        true
      );
      document.body.style.cursor = 'unset';
      // act like blur event -> can't use actual blur b/c mousedown on popper
      // will run the blur event before the selection
      form.setFieldTouched(field.name, true, false);
    },
    onClickAway: () => form.setFieldTouched(field.name, true),
    handleRenderOption: ({ label, value }) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: 24
        }}
      >
        <Typography variant={TV.S3} weight={TW.MEDIUM} caps>
          {value.jobTypeInternal || getParents()[value.entityType]}
        </Typography>
        {renderFor[value.jobTypeInternal || value.entityType](label, value, palette)}
      </div>
    ),
    configureProps: {
      restrictSearchableAttributes: [
        'customerName',
        'customerPropertyName',
        'customIdentifier',
        'agreementName',
        'agreementNumber',
        'projectName',
        'projectNumber'
      ]
    },
    ...givenProps,
    name: field.name,
    value: field.value,
    testingid: givenProps.testingid
  };

  return <AlgoliaSearch {...props} />;
}

InvoiceParentSearch.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  // any input props or anything to override
  props: PropTypes.shape({
    style: PropTypes.object,
    label: PropTypes.string,
    required: PropTypes.bool,
    testingid:PropTypes.string,
  }).isRequired
};
