export const useStyles = () => ({
  subSectionContainer: theme => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }),
  title: theme => ({
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  }),
  headerContainer: theme => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(1)
  })
});
