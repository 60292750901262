import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import { XGrid } from 'components';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 18
  },
  grid: {
    'min-height': 200
  }
}));

const GET_JOB_PHASES = gql`
  query getJobPhases($id: String!) {
    data: getJobById(id: $id) {
      id
      items: jcPhaseCostTypes {
        id
        jcPhaseName
        jcCostTypeName
      }
    }
  }
`;

const GET_JOB_CONTRACT_ITEMS = gql`
  query getJobContractItems($id: String!) {
    data: getJobById(id: $id) {
      id
      items: jcContractContractItems {
        id
        jcContractName
        jcContractItemName
      }
    }
  }
`;

const jobPhasesColumns = [
  {
    field: 'jcPhaseName',
    headerName: 'Job Phase',
    minWidth: 200,
    flex: 1
  },
  {
    field: 'jcCostTypeName',
    headerName: 'Cost Type',
    minWidth: 200,
    flex: 2
  }
];

const jobContractItemColumns = [
  {
    field: 'jcContractName',
    headerName: 'Contract Number',
    minWidth: 200,
    flex: 1
  },
  {
    field: 'jcContractItemName',
    headerName: 'Contract Item',
    minWidth: 200,
    flex: 1
  }
];

const Vista = ({ jobId }) => {
  const queryVariables = {
    id: jobId
  };
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6" className={classes.title}>
        Job Phases
      </Typography>
      <XGrid
        columns={jobPhasesColumns}
        entityTypeName="Job Phase"
        tableName="VistaJobPhaseXGrid"
        query={GET_JOB_PHASES}
        queryVariables={queryVariables}
        disableToolbar
        useItemsCountAsRowCount
      />
      <Typography variant="h6" className={classes.title}>
        Job Contract Items
      </Typography>
      <XGrid
        columns={jobContractItemColumns}
        entityTypeName="Job Contract Items"
        tableName="VistaJobContractItemsXGrid"
        query={GET_JOB_CONTRACT_ITEMS}
        queryVariables={queryVariables}
        disableToolbar
        useItemsCountAsRowCount
      />
    </>
  );
};

export default Vista;
