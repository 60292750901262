import { PermissionAction } from './constants';

class PermissionsUtils {
  static hasTenantAdminPermissions = convertedAppPermissionRules => {
    return convertedAppPermissionRules.some(
      permission =>
        Array.isArray(permission) &&
        permission.some(
          partialPermission =>
            typeof partialPermission === 'string' &&
            partialPermission.includes(PermissionAction.ALLOW)
        ) &&
        permission.includes('all')
    );
  };

  static hasRequiredUserPermissions = ({
    convertedAppPermissionRules,
    action,
    category,
    subcategory
  }) => {
    return convertedAppPermissionRules.some(
      permission =>
        Array.isArray(permission) &&
        permission.some(
          partialPermission =>
            typeof partialPermission === 'string' && partialPermission.includes(action)
        ) &&
        permission.includes(`${category}_${subcategory}`)
    );
  };
}

export default PermissionsUtils;
