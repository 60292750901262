export const useStyles = () => {
  return {
    buttonContainer: theme => ({
      display: 'flex',
      button: {
        marginLeft: theme.spacing(1)
      }
    }),
    link: theme => ({
      color: `${theme.palette.text.primary} !important`
    })
  };
};
