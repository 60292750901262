export const FormPropertiesLayout = {
  fields: {
    name: {
      default: 'name',
      edit: 'name'
    },
    description: {
      default: 'description',
      edit: 'description'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'SectionHeader',
                    default: 'SectionHeader'
                  },
                  source: 'section_header',
                  options: {
                    toolLabel: 'Section Header',
                    toolIcon: 'Title',
                    label: 'Section Header',
                    value: 'Form Properties',
                    fullWidth: true,
                    editFormLayout: 'SectionHeaderPropertiesLayout'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'name',
                  options: {
                    toolLabel: 'Text',
                    toolIcon: 'TextFields',
                    label: 'Name',
                    placeholder: 'Provide a name for your form.',
                    fullWidth: true,
                    editFormLayout: 'TextInputPropertiesLayout',
                    isRequired: true
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'description',
                  options: {
                    toolLabel: 'Text Area',
                    toolIcon: 'TextFields',
                    label: 'Description',
                    placeholder: 'Provide a description for your form.',
                    lines: 4,
                    fullWidth: true,
                    editFormLayout: 'TextAreaPropertiesLayout'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      name: {
        type: 'string'
      }
    },
    required: ['name']
  },
  validationErrors: {
    name: {
      required: 'Field is required.'
    }
  }
};

export const FormPropertiesLayoutAdmin = {
  fields: {
    name: {
      default: 'name',
      edit: 'name'
    },
    associatedEntityType: {
      default: 'associatedEntityType',
      edit: 'associatedEntityType'
    },
    viewType: {
      default: 'viewType',
      edit: 'viewType'
    },
    description: {
      default: 'description',
      edit: 'description'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'SectionHeader',
                    default: 'SectionHeader'
                  },
                  source: 'section_header',
                  options: {
                    toolLabel: 'Section Header',
                    toolIcon: 'Title',
                    label: 'Section Header',
                    value: 'Form Properties',
                    fullWidth: true,
                    editFormLayout: 'SectionHeaderPropertiesLayout'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'name',
                  options: {
                    toolLabel: 'Text',
                    toolIcon: 'TextFields',
                    label: 'Name',
                    placeholder: 'Provide a name for your form.',
                    fullWidth: true,
                    editFormLayout: 'TextInputPropertiesLayout',
                    isRequired: true
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'SelectInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'associatedEntityType',
                  options: {
                    toolLabel: 'Select Input',
                    toolIcon: 'ViewListOutlined',
                    label: 'Entity Type',
                    displayEmpty: false,
                    inputOptions: 'Job,Quote,Customer,TimeCard,Visit,Project,Task',
                    fullWidth: true,
                    editFormLayout: 'SelectInputPropertiesLayout',
                    isRequired: true
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'SelectInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'viewType',
                  options: {
                    toolLabel: 'Select Input',
                    toolIcon: 'ViewListOutlined',
                    label: 'View Type',
                    displayEmpty: false,
                    inputOptions: 'Document,Inline',
                    fullWidth: true,
                    editFormLayout: 'SelectInputPropertiesLayout',
                    isRequired: true
                  },
                  dependentField: {
                    fieldName: 'associatedEntityType',
                    expectedValue: 'Task',
                    operation: 'equal',
                    action: 'DISABLE'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'description',
                  options: {
                    toolLabel: 'Text Area',
                    toolIcon: 'TextFields',
                    label: 'Description',
                    placeholder: 'Provide a description for your form.',
                    lines: 4,
                    fullWidth: true,
                    editFormLayout: 'TextAreaPropertiesLayout'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      name: {
        type: 'string'
      },
      associatedEntityType: {
        type: 'string'
      },
      viewType: {
        type: 'string'
      }
    },
    required: ['name', 'associatedEntityType', 'viewType']
  },
  validationErrors: {
    name: {
      required: 'Field is required.'
    },
    associatedEntityType: {
      required: 'Field is required.'
    },
    viewType: {
      required: 'Field is required.'
    },
    description: {
      required: 'Field is required.'
    }
  }
};
