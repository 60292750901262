import React, { useState, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  Button,
  SplitButton,
  MoreButton,
  ButtonType,
  ThemeProvider,
  PDFDocument
} from '@buildhero/sergeant';
import { Box, makeStyles } from '@material-ui/core';
import { sentryException } from 'services/Logger';
import { CloudDownload, MailOutline, NotInterested } from '@material-ui/icons';
import { connect } from 'react-redux';
import { InvoiceService } from 'services/core';
import { snackbarOn, setOpenQuickAddModal } from 'redux/actions/globalActions';
import { roundCurrency } from 'utils';
import {
  InvoiceStatus,
  AccountingApp,
  QuickAddModal,
  Mode,
  SyncStatus,
  EntityType
} from 'utils/constants';
import { SergeantModal, FullScreenModal } from 'components';
import SendEmailPopUp from 'components/BuildHeroFormComponents/SendEmailPopUp';
import { PDFViewer } from '@react-pdf/renderer';
import { useExternalMessagesQuery } from '../../../Settings/Company/CommunicationsSection/service';
import { Action, Message } from '../InvoiceDetail.constants';
import ApplyPayment from '../../ApplyPayment';
import { downloadInvoice, isReadonly, uploadInvoicePdf } from '../InvoiceDetail.utils';
import getInvoiceLayout from '../InvoiceConfiguration';
import { CustomerSignaturesDisplayPDF } from './CustomerSignatures';

const combineItems = ({ laborItems, partsAndMaterials, discountsAndFees }) =>
  laborItems.concat(partsAndMaterials).concat(discountsAndFees);

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    '& > *:not(:last-child)': {
      marginRight: spacing(1)
    }
  },
  moreButton: {
    fontSize: spacing(3),
    padding: 0
  }
}));

/**
 * Renders the buttons that perform actions
 * @param user Logged in user info from redux
 * @param openQuickAddModal Global action from redux
 */
function InvoiceActions({
  data,
  settings,
  initializingData,
  blockInteractions,
  updateInvoice,
  accountingApp,
  formService,
  refetch,
  onActionStart = () => {},
  onActionComplete = () => {},
  user,
  userCanUpdateInvoice,
  snackbar,
  openQuickAddModal,
  isMissingJcContractItems
}) {
  const classes = useStyles();
  const [buttonLoading, setButtonLoading] = useState();
  const [configuration, setConfiguration] = useState();
  const [localData, setLocalData] = useState(); // to prevent rerender when rendering PDF
  const [applyPaymentModalOpen, setApplyPaymentModalOpen] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [modal, setModal] = useState({
    open: false,
    mode: Mode.VOID,
    dataType: EntityType.INVOICE,
    handlePrimaryAction: () => {}
  });
  const { data: companyExternalMessages } = useExternalMessagesQuery(
    user.tenantId,
    user.tenantCompanyId,
    snackbar
  );

  // prevent rerendering of pdf - it will error if pdf is rerendered during rendering
  const previewModal = useMemo(
    () => (
      <FullScreenModal
        title="Invoice Preview"
        open={previewModalOpen}
        handleClose={() => setPreviewModalOpen(false)}
        style={{ display: 'flex', flex: '1 1 auto' }}
        contentStyle={{ marginTop: 0, marginBottom: 0, flex: '1 1 auto' }}
      >
        <PDFViewer width="100%" height="100%">
          <PDFDocument
            layout="pdf"
            configuration={configuration}
            initialValues={localData}
            customComponents={{ CustomerSignaturesDisplayPDF }}
          />
        </PDFViewer>
      </FullScreenModal>
    ),
    [previewModalOpen]
  );

  if (initializingData)
    return <Skeleton count={3} height={40} width={150} style={{ lineHeight: 'unset' }} />;
  if (data.status === InvoiceStatus.VOID) return <></>;

  const paymentInfo = {
    invoiceId: data.id,
    billingCustomerId: data.billingCustomer.id,
    billingCustomerName: data.billingCustomer.customerName
  };

  /**
   * Mapping of invoice actions to their handler functions
   */
  const actions = {
    [Action.POST]: async () => {
      if (!formService?.formikContext?.isValid) {
        snackbar('error', Message.POST_ERROR);
        return;
      }
      if (
        data.laborItems.length === 0 &&
        data.partsAndMaterials.length === 0 &&
        data.discountsAndFees.length === 0
      ) {
        snackbar('error', Message.EMPTY_INVOICE_ERROR);
        return;
      }
      setButtonLoading(Action.POST);
      await updateInvoice({
        status: InvoiceStatus.POSTED,
        syncStatus: accountingApp ? SyncStatus.SYNCING : undefined
      });
      setButtonLoading(null);
    },
    [Action.APPLY_PAYMENT]: () => {
      setApplyPaymentModalOpen(true);
    },
    [Action.NEW_PAYMENT]: () => {
      openQuickAddModal(QuickAddModal.CREATE_PAYMENT, paymentInfo, refetch);
    },
    [Action.PREVIEW]: () => {
      // updated data for simple invoice PDF view -> need to combine items
      const updatedData = { ...data, invoiceItems: combineItems(data), settings: settings.current };
      setConfiguration(getInvoiceLayout(updatedData, undefined, undefined, true));
      setLocalData(updatedData);
      setPreviewModalOpen(true);
    },
    [Action.EMAIL]: () => {
      setSendEmailModalOpen(true);
    },
    [Action.DOWNLOAD]: async () => {
      downloadInvoice({ ...data, invoiceItems: combineItems(data), settings: settings.current });
    },
    [Action.VOID]: () => {
      const handlePrimaryAction = async (_, callback) => {
        await updateInvoice({
          status: InvoiceStatus.VOID,
          syncStatus: accountingApp ? SyncStatus.SYNCING : undefined
        });
        callback();
        setModal(prev => ({ ...prev, open: false }));
      };
      setModal(prev => ({
        ...prev,
        open: true,
        confirmRemoveItemLabel: `Invoice ${data.invoiceNumber}`,
        confirmRemoveStatement:
          accountingApp === AccountingApp.QUICKBOOKS &&
          '\n\nThis will void the invoice in QuickBooks.',
        handlePrimaryAction
      }));
    }
  };

  const getActionHandler = action => async () => {
    onActionStart(action);
    await actions[action]();
    onActionComplete(action);
  };

  const isSageIntegrated = accountingApp === AccountingApp.SAGE;

  let disablePostButton;
  if (InvoiceStatus.CLOSED === data.status) {
    disablePostButton = 'Invoice is closed';
  }
  if (isMissingJcContractItems) {
    disablePostButton = 'There are invoice items missing the JC Contract Item';
  }
  if (
    [InvoiceStatus.POSTED, InvoiceStatus.EXPORTED].includes(data.status) &&
    (!accountingApp || isSageIntegrated)
  ) {
    disablePostButton = 'Invoice is already posted';
  }
  const readonly = isReadonly(userCanUpdateInvoice, data.status, isSageIntegrated);

  const splitButtonOptions = [
    { label: 'Apply Payment', onClick: getActionHandler(Action.APPLY_PAYMENT) },
    { label: 'New Payment', onClick: getActionHandler(Action.NEW_PAYMENT) }
  ];

  const moreButtonOptions = [
    { label: 'Email Invoice', icon: MailOutline, onClick: getActionHandler(Action.EMAIL) },
    { label: 'Download Invoice', icon: CloudDownload, onClick: getActionHandler(Action.DOWNLOAD) },
    !readonly &&
      data.payments.length === 0 && {
        label: 'Void Invoice',
        icon: NotInterested,
        onClick: getActionHandler(Action.VOID)
      }
  ].filter(Boolean);

  const emailTemplateOptions = data?.department?.id
    ? (companyExternalMessages || []).filter(
        template =>
          template?.type === EntityType.INVOICE &&
          (template?.externalMessageDepartments?.items || []).some(
            d => d?.department?.id === data.department.id
          )
      )
    : [];

  const handleSendEmail = async payload => {
    try {
      const invoiceService = new InvoiceService();
      const { data: response } = await invoiceService.emailInvoice(user.tenantId, payload);
      if (response) {
        snackbar('success', 'Email sent successfully');
      }
    } catch (e) {
      sentryException(e, 'Unable to send invoice email');
      snackbar('error', 'Unable to send email');
    }
  };

  return (
    <>
      {previewModal}
      <SergeantModal {...modal} handleClose={() => setModal(prev => ({ ...prev, open: false }))} />
      <ApplyPayment
        open={applyPaymentModalOpen}
        handleClose={() => setApplyPaymentModalOpen(false)}
        initialData={{
          totalAmount: roundCurrency(data.totalAmount - data.adjustedAmount),
          ...paymentInfo
        }}
        onSubmit={refetch}
      />
      <SendEmailPopUp
        open={sendEmailModalOpen}
        onModalClose={() => setSendEmailModalOpen(false)}
        user={user}
        snackbar={snackbar}
        data={{
          disableAttachments: true,
          disableExpirationDate: true,
          disableViewPdf: true,
          emailSubject: `Invoice ${data.invoiceNumber}`,
          pdfFileName: `Invoice${data.invoiceNumber}.pdf`,
          invoiceNumber: data.invoiceNumber,
          invoiceId: data.id,
          logoUrl: data.sender?.logoUrl,
          sendEmailFn: payload => handleSendEmail(payload),
          uploadPdf: () =>
            uploadInvoicePdf({
              ...data,
              invoiceItems: combineItems(data),
              settings: settings.current
            }),
          replyToEmail: data.sender?.email,
          customer: data.customer,
          customerProperty: data.customerProperty,
          billingCustomer: data.billingCustomer
        }}
        templates={emailTemplateOptions}
      />
      <ThemeProvider>
        <Box display="flex" alignItems="center" flexBasis="auto" className={classes.container}>
          {data.status !== InvoiceStatus.VOID && userCanUpdateInvoice && (
            <>
              <SplitButton
                options={splitButtonOptions}
                type={ButtonType.TERTIARY}
                disabled={blockInteractions}
                onClick={getActionHandler(Action.APPLY_PAYMENT)}
                testingid="splitbutton-apply-payment-invoices"
              >
                Apply Payment
              </SplitButton>
              <Button
                type={ButtonType.SECONDARY}
                onClick={getActionHandler(Action.POST)}
                loading={buttonLoading === Action.POST}
                disabled={!!disablePostButton || blockInteractions}
                tooltip={disablePostButton}
                testingid={
                  accountingApp ? 'button-exportInvoice-invoices' : 'button-postInvoice-invoices'
                }
              >
                {accountingApp ? 'Export Invoice' : 'Post Invoice'}
              </Button>
            </>
          )}
          <Button
            disabled={blockInteractions}
            onClick={getActionHandler(Action.PREVIEW)}
            testingid="button-previewInvoice-invoices"
          >
            Preview Invoice
          </Button>
          <MoreButton
            disabled={blockInteractions}
            className={classes.moreButton}
            options={moreButtonOptions}
            testingid="kebabmenu"
          />
        </Box>
      </ThemeProvider>
    </>
  );
}

export default connect(state => ({ user: state.user }), {
  snackbar: snackbarOn,
  openQuickAddModal: setOpenQuickAddModal
})(InvoiceActions);
