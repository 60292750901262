/* eslint-disable no-useless-concat */

import { object, number, string } from 'yup';
import { SyncStatus, EntityType } from 'utils/constants';
import { nameValidationField } from 'meta/validations';

/* eslint-disable no-template-curly-in-string */
const defaultInventoryItemsForm = ({
  data,
  vendorsList,
  isIntacctEnabled,
  incomeAccountsList,
  expenseAccountList,
  groupList,
  unitOfMeasureAsOptions,
  costCodeOptions,
  costTypeOptions,
  revenueTypeOptions,
  isVistaEnabled,
  departmentOptions,
  isProductCategoriesEnabled,
  productCategoryOptions
}) => {
  return {
    fields: {},
    layouts: {
      // forms container
      default: {
        options: {
          direction: 'column',
          grow: 0,
          padding: [35, 0, 0, 0]
        },
        // boxing
        contents: [
          {
            options: {
              direction: 'column',
              grow: 0,
              size: '100%',
              padding: [0, 0]
            },
            // container
            contents: [
              // Fields for: Item Name | Description
              {
                options: {
                  direction: 'row',
                  shrink: 0,
                  padding: [0, 0, 18, 0]
                },
                contents: [
                  {
                    options: {
                      direction: 'column',
                      padding: [0, 10, 0, 0]
                    },
                    contents: [
                      {
                        options: {
                          label: 'ITEM NAME',
                          isRequired: true
                        },
                        component: {
                          default: 'TextInput'
                        },
                        source: 'name'
                      }
                    ]
                  },
                  {
                    options: {
                      direction: 'column',
                      padding: [0, 0, 0, 10]
                    },
                    contents: [
                      {
                        options: {
                          label: 'DESCRIPTION',
                          isRequired: true
                        },
                        component: {
                          default: 'TextInput'
                        },
                        source: 'description'
                      }
                    ]
                  }
                ]
              },
              // Fields for: Category | Department
              isProductCategoriesEnabled
                ? {
                    options: {
                      direction: 'row',
                      shrink: 0,
                      padding: [0, 0, 18, 0]
                    },
                    contents: [
                      {
                        options: {
                          direction: 'column',
                          padding: [0, 10, 0, 0]
                        },
                        contents: [
                          {
                            options: {
                              label: 'Category',
                              valueSet: productCategoryOptions
                            },
                            component: {
                              default: 'SelectInput'
                            },
                            source: 'productCategoryId'
                          }
                        ]
                      },
                      {
                        options: {
                          direction: 'column',
                          padding: [0, 0, 0, 10]
                        },
                        contents: [
                          {
                            options: {
                              label: 'Associated Department',
                              isMultipleSelection: true,
                              valueSet: departmentOptions
                            },
                            component: {
                              default: 'SelectInput'
                            },
                            source: 'departments'
                          }
                        ]
                      }
                    ]
                  }
                : { options: {}, contents: [] },
              // Fields for: Product Code | Vendor | UOM | Taxable
              {
                options: {
                  direction: 'row',
                  shrink: 0,
                  padding: [0, 0, 18, 0]
                },
                contents: [
                  {
                    options: {
                      direction: 'column',
                      padding: [0, 10, 0, 0]
                    },
                    contents: [
                      {
                        options: {
                          label: 'PRODUCT CODE',
                          isRequired: isIntacctEnabled,
                          // for intacct, can't change code after exporting
                          disabled:
                            isIntacctEnabled &&
                            (data?.accountingRefId || data?.syncStatus === SyncStatus.SYNCING)
                        },
                        component: {
                          default: 'TextInput'
                        },
                        source: 'code'
                      }
                    ]
                  },
                  {
                    options: {
                      direction: 'column',
                      padding: [0, 10, 0, 10]
                    },
                    contents: [
                      {
                        options: {
                          label: 'VENDOR',
                          valueSet: vendorsList
                        },
                        component: {
                          default: 'SelectInput'
                        },
                        source: 'vendorSortKey'
                      }
                    ]
                  },
                  {
                    options: {
                      direction: 'column',
                      padding: [0, 10, 0, 10],
                      size: 1
                    },
                    contents: [
                      {
                        options: {
                          label: 'UOM',
                          valueSet: unitOfMeasureAsOptions,
                          isRequired: isIntacctEnabled || isVistaEnabled
                        },
                        component: {
                          default: 'SelectInput'
                        },
                        source: 'unitOfMeasureId'
                      }
                    ]
                  },
                  {
                    options: {
                      direction: 'column',
                      padding: [0, 0, 0, 10],
                      margin: [10, 0, 0, 0],
                      size: 1
                    },
                    contents: [
                      {
                        options: {
                          label: 'Taxable'
                        },
                        component: {
                          default: 'CheckboxInput'
                        },
                        source: 'taxable'
                      }
                    ]
                  }
                ]
              },
              // Fields for: Cost Code | Cost Type | Revenue Type
              {
                options: {
                  direction: 'row',
                  shrink: 0,
                  padding: [0, 0, 18, 0]
                },
                contents: [
                  {
                    options: {
                      direction: 'column',
                      padding: [0, 10, 0, 0]
                    },
                    contents: [
                      {
                        options: {},
                        contents: [
                          {
                            options: {
                              label: 'Cost Code',
                              valueSet: costCodeOptions
                            },
                            component: {
                              default: 'SelectInput'
                            },
                            source: 'costCodeId'
                          }
                        ]
                      }
                    ]
                  },
                  {
                    options: {
                      direction: 'column',
                      padding: [0, 10, 0, 10]
                    },
                    contents: [
                      {
                        options: {
                          label: 'Cost Type',
                          valueSet: costTypeOptions
                        },
                        component: {
                          default: 'SelectInput'
                        },
                        source: 'jobCostTypeId'
                      }
                    ]
                  },
                  {
                    options: {
                      padding: [0, 0, 0, 10],
                      direction: 'column'
                    },
                    contents: [
                      {
                        options: {},
                        contents: [
                          {
                            options: {
                              label: 'Revenue Type',
                              valueSet: revenueTypeOptions
                            },
                            component: {
                              default: 'SelectInput'
                            },
                            source: 'revenueTypeId'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              // Fields for: Sales Account | Expense Account | Unit Cost
              {
                options: {
                  direction: 'row',
                  shrink: 0,
                  padding: [0, 0, 18, 0]
                },
                contents: [
                  {
                    options: {
                      direction: 'column',
                      padding: [0, 10, 0, 0]
                    },
                    contents: isIntacctEnabled
                      ? [
                          {
                            options: {},
                            contents: [
                              {
                                options: {
                                  label: 'Item GL GROUP',
                                  valueSet: groupList,
                                  isRequired: true
                                },
                                component: {
                                  default: 'SelectInput'
                                },
                                source: 'itemGlGroupId'
                              }
                            ]
                          }
                        ]
                      : [
                          {
                            options: {},
                            contents: [
                              {
                                options: {
                                  label: 'SALES ACCOUNT',
                                  valueSet: incomeAccountsList
                                },
                                component: {
                                  default: 'SelectInput'
                                },
                                source: 'glIncomeAccountRefId'
                              }
                            ]
                          }
                        ]
                  },
                  {
                    options: {
                      direction: 'column',
                      padding: [0, 10, 0, 10],
                      size: 1
                    },
                    contents: [
                      {
                        options: {
                          label: 'EXPENSE ACCOUNT',
                          valueSet: expenseAccountList
                        },
                        component: {
                          default: 'SelectInput'
                        },
                        source: 'glExpenseAccountRefId'
                      }
                    ]
                  },
                  {
                    options: {
                      direction: 'column',
                      padding: [0, 0, 0, 10]
                    },
                    contents: [
                      {
                        options: {},
                        contents: [
                          {
                            options: {
                              label: 'Unit Cost',
                              isRequired: true
                            },
                            component: { default: 'CurrencyInput' },
                            source: 'unitCost'
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    },
    validation: object({
      ...nameValidationField('name', EntityType.PRODUCT, 'Item name is required'),
      description: string().required('Description is required'),
      unitCost: number().required('Unit cost is required'),
      itemGlGroupId:
        isIntacctEnabled &&
        string()
          .nullable()
          .required('Item GL group is required'),
      code:
        isIntacctEnabled &&
        string()
          .nullable()
          .required('Product code is required'),
      unitOfMeasureId:
        (isIntacctEnabled || isVistaEnabled) &&
        string()
          .nullable()
          .required('UOM is required')
    })
  };
};

export default defaultInventoryItemsForm;
