import React, { useEffect } from 'react';

import { Grid, ListItemIcon, Menu, MenuItem, Switch } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import PropTypes from 'prop-types';

import RenameModal from 'components/ResponsiveTable/RenameModal';

import { useStyles } from './SavedFilter.styles';

const Submenu = ({
  filter,
  handleCloseSubmenu,
  handleUpsertFilter,
  handleDeleteFilter,
  handleSetIsDefault,
  anchor,
  disabled
}) => {
  const [anchorEl, setAnchorEl] = React.useState(anchor);
  const [showRenameModal, setShowRenameModal] = React.useState();
  const changeLabelRef = React.useRef();

  useEffect(() => {
    setAnchorEl(anchor);
  }, [anchor]);

  const classes = useStyles();

  return (
    <>
      <Menu
        id={`filterAction${filter.displayName}`}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={e => e.stopPropagation()}
        onClose={handleCloseSubmenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem
          ref={changeLabelRef}
          aria-describedby={`${filter.displayName}Rename`}
          onClick={e => {
            e.stopPropagation();
            setShowRenameModal(true);
          }}
          disabled={disabled}
          className={classes.menuText}
        >
          <ListItemIcon className={classes.menuIcon}>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Rename
        </MenuItem>
        <MenuItem
          onClick={async e => {
            e.stopPropagation();
            await handleDeleteFilter(filter.displayName, filter.id);
            handleCloseSubmenu();
          }}
          disabled={disabled || filter.isDefault}
          className={classes.menuText}
        >
          <ListItemIcon className={classes.menuIcon}>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Remove
        </MenuItem>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={`
            ${classes.menuTopBorder} ${classes.defaultBtnContainer}
          `}
          onClick={e => e.stopPropagation()}
        >
          <Grid item className={classes.menuText}>
            Make default
          </Grid>
          <Grid item>
            <Switch
              classes={{
                switchBase: classes.switchBase,
                track: classes.track,
                colorSecondary: classes.colorSecondary,
                checked: classes.checked
              }}
              checkedIcon={<CheckCircleIcon />}
              checked={filter.isDefault}
              disabled={disabled}
              onChange={async e => {
                await handleSetIsDefault(filter.displayName, filter.id, e.target.checked);
                handleCloseSubmenu();
              }}
            />
          </Grid>
        </Grid>
      </Menu>
      <RenameModal
        open={showRenameModal}
        currentLabel={filter.displayName}
        dataType="Filter"
        handleViewRename={async newDisplayName => {
          await handleUpsertFilter(newDisplayName, filter.id);
          handleCloseSubmenu();
        }}
        handleClose={() => setShowRenameModal(null)}
        onClick={e => e.stopPropagation()}
      />
    </>
  );
};

Submenu.propTypes = {
  filter: PropTypes.object.isRequired,
  anchor: PropTypes.object.isRequired,
  handleCloseSubmenu: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

Submenu.defaultProps = {
  disabled: false
};

export default Submenu;
