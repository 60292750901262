import React, { Component } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Spinner from 'components/Spinners/CircularIndeterminate';
import styles from './styles';

class OCRDataDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imgHeight: 0
    };
  }

  handleImageLoaded() {
    const height = this.imgElement.clientHeight;
    this.setState({
      imgHeight: height
    });
  }

  render() {
    const { classes, dataOCRInput } = this.props;
    const { imageURL, data, loading } = dataOCRInput;
    const imgHeightToRows = Math.floor(this.state.imgHeight / 20) - 1;
    const maxRow = Math.max(imgHeightToRows, 5);

    return (
      <Grid container direction="row">
        <Grid item xs={12} sm={6} className={classes.container}>
          <img
            alt={imageURL}
            src={imageURL}
            onLoad={this.handleImageLoaded.bind(this)}
            className={classes.thumbnail}
            ref={imgElement => {
              this.imgElement = imgElement;
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.container}>
          {loading ? (
            <Spinner className={classes.spinner} />
          ) : (
            <TextField
              label="Text from Image"
              multiline
              value={data}
              rowsMax={maxRow}
              variant="outlined"
              className={classes.ocrInputStyle}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(OCRDataDisplay);
