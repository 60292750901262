import gql from 'graphql-tag';

const getTruckById = gql`
  query getTruckById($id: String!) {
    getTruckById(id: $id) {
      id
      version
      name
      productBundle(entityConnection: "ProductBundle") {
        id
        version
        name
        category
        subCategory
        type
        isActive
      }
      truckInventories(entityConnection: "TruckInventory") {
        items {
          id
          version
          minQuantity
          maxQuantity
          quantityOnHand
          productId
          product(entityConnection: "Product") {
            id
            version
            code
            name
            description
            unitCost
            unitOfMeasureId
            unitOfMeasure(entityConnection: "UnitOfMeasure") {
              id
              name
              unitOfMeasureType
              unitOfMeasureCategory
            }
          }
        }
      }
      truckEmployees(entityConnection: "TruckEmployee") {
        items {
          id
          version
          employee(entityConnection: "Employee") {
            id
            firstName
            lastName
            name
          }
        }
      }
    }
  }
`;

export default getTruckById;
