const baseEntitySchema = [
  'parentId',
  'parentSortKey',
  'hierarchy',
  'id',
  'entityType',
  'version',
  'tenantId',
  'tenantCompanyId',
  'partitionKey',
  'sortKey',
  'createdBy',
  'createdDate',
  'createdDateTime',
  'deletedBy',
  'deletedDate',
  'deletedDateTime',
  'lastUpdatedBy',
  'lastUpdatedDate',
  'lastUpdatedDateTime',
  'lsi1',
  'lsi2',
  'lsi3',
  'lsi4',
  'lsi5',
  'gsi1',
  'gsi2'
];

const mappingRequired = [
  'hierarchy',
  'id',
  'entityType',
  'tenantId',
  'tenantCompanyId',
  'partitionKey',
  'sortKey'
];

export default baseEntitySchema;
export { mappingRequired };
