export const useStyles = () => ({
  root: ({ default: { spacing } }) => ({
    paddingRight: spacing(3),
    paddingLeft: spacing(3)
  }),
  progressBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'nowrap'
  }
});
