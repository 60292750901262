import * as Actions from '../constants';

export const refreshTableData = () => ({
  type: Actions.REFRESH_TABLE_DATA
});

export const quietRefreshTableData = () => ({
  type: Actions.QUIET_REFRESH_TABLE_DATA
});

export const disableQuietRefresh = () => ({
  type: Actions.DISABLE_QUIET_REFRESH
});

export const createMultiVisitStart = () => ({
  type: Actions.SET_CREATE_MULTI_VISIT_STARTED
});

export const createMultiVisitEnd = () => ({
  type: Actions.SET_CREATE_MULTI_VISIT_ENDED
});
