import gql from 'graphql-tag';

const SyncClasses = gql`
  mutation SyncClasses($partitionKey: String!, $data: SyncClassesInput!) {
    syncClasses(partitionKey: $partitionKey, data: $data) {
      id
      entityType
      name
      isActive
      accountingRefId
      accountingApplication
      version
    }
  }
`;

export default SyncClasses;
