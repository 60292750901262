import { gql } from '@apollo/client/core';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import useExtendedQuery from 'customHooks/useExtendedQuery';

export const GET_TIMESHEET_FILTERS = gql`
  query getTimesheetFiltersByEmployee {
    getTimesheetFiltersByEmployee {
      id
      displayName
      filter
      isDefault
    }
  }
`;

export const useTimesheetFilters = () =>
  useExtendedQuery(GET_TIMESHEET_FILTERS, {
    transform: result => result?.getTimesheetFiltersByEmployee
  });

const MUTATE_TIMESHEET_FILTER = gql`
  mutation upsertTimesheetFilters($timesheetFilters: [UpsertTimesheetFilterTypeInput!]!) {
    upsertTimesheetFilters(timesheetFilters: $timesheetFilters) {
      id
      displayName
      filter
      isDefault
    }
  }
`;

const updateEdit = (cache, { data: { upsertTimesheetFilters } }) => {
  const query = {
    query: GET_TIMESHEET_FILTERS
  };

  const updateData = {
    getTimesheetFiltersByEmployee: { items: [...upsertTimesheetFilters] }
  };

  cache.writeQuery({ ...query, data: updateData });
};

export const useTimesheetMutateFilter = () =>
  useExtendedMutation(MUTATE_TIMESHEET_FILTER, {
    serializer: newFilters => ({
      variables: {
        timesheetFilters: newFilters.map(newFilter => ({
          id: newFilter?.id,
          displayName: newFilter.displayName,
          filter: newFilter.filter,
          isDefault: newFilter.isDefault
        }))
      }
    }),
    update: updateEdit,
    onSuccess: (data, snackbar) => snackbar('success', 'Saved Timesheet Filter')
  });

const DELETE_TIMESHEET_FILTER = gql`
  mutation deleteTimesheetFilter($id: String!) {
    deleteTimesheetFilter(id: $id) {
      id
    }
  }
`;

const updateDelete = (cache, { data: { deleteTimesheetFilter } }) => {
  const query = {
    query: GET_TIMESHEET_FILTERS
  };

  const cachedData = cache.readQuery(query);

  const updateData = {
    getTimesheetFiltersByEmployee: {
      items: [
        cachedData?.getTimesheetFiltersByEmployee?.filter(x => x.id !== deleteTimesheetFilter.id)
      ]
    }
  };

  cache.writeQuery({ ...query, data: updateData });
};

export const useTimesheetDeleteFilter = () =>
  useExtendedMutation(DELETE_TIMESHEET_FILTER, {
    serializer: id => ({
      variables: {
        id
      }
    }),
    update: updateDelete,
    onSuccess: (data, snackbar) => snackbar('success', 'Removed Timesheet Filter')
  });
