/* eslint-disable react/no-array-index-key */
import React, { memo, useMemo } from 'react';
import { arrayOf, bool, number } from 'prop-types';
import { Skeleton } from '@material-ui/lab';

import { ColDef } from '../../WrapTable.types';
import { DEFAULT_MAX_HEIGHT } from '../../WrapTable.constants';
import Row from '../../Row';

import { useLoaderStyles } from './LoadingOverlay.styles';

const loadingData = {};

const LoadingOverlay = ({
  columns,
  scrollY,
  maxHeight,
  isFirstLoad,
  loadingRows,
  rowGroupingModel,
  rowGroupingColumnMode
}) => {
  const loaderStyles = useLoaderStyles({ scrollY, maxHeight });

  const loadingRowsArr = useMemo(() => Array.from({ length: loadingRows }), [loadingRows]);

  return (
    <div className={loaderStyles.loadingContainer}>
      {isFirstLoad && (
        <>
          {loadingRowsArr.map((_, i) => (
            <Row
              key={i}
              data={loadingData}
              columns={columns}
              rowGroupingModel={rowGroupingModel}
              rowGroupingColumnMode={rowGroupingColumnMode}
              isFirstLoad
            />
          ))}
          <Skeleton classes={{ root: loaderStyles.rowOverlay }} variant="rect" height="100%" />
        </>
      )}
    </div>
  );
};

LoadingOverlay.propTypes = {
  columns: arrayOf(ColDef).isRequired,
  isFirstLoad: bool,
  scrollY: bool,
  maxHeight: number,
  loadingRows: number.isRequired
};

LoadingOverlay.defaultProps = {
  isFirstLoad: false,
  scrollY: false,
  maxHeight: DEFAULT_MAX_HEIGHT
};

export default memo(LoadingOverlay);
