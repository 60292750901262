import React, { createRef, useEffect, useMemo, useState } from 'react';

import { jsx } from '@emotion/react';

import { Button, Grid, ListItemIcon, MenuItem, Select, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UpdateIcon from '@material-ui/icons/Update';

import { orderBy } from 'lodash';

import RenameModal from 'components/ResponsiveTable/RenameModal';
import { useSnackbar } from 'customHooks/useSnackbar';
import { Mode } from 'utils/constants';

import { useStyles } from './SavedFilter.styles';

import SavedFilterActionsMenuItem from './SavedFilterActionsMenuItem';
import {
  useTimesheetDeleteFilter,
  useTimesheetFilters,
  useTimesheetMutateFilter
} from './useTimesheetFilters';

const defaultFilter = {
  displayName: 'Default Filter',
  filter: ''
};

const defaultNewFilterName = 'Custom Filter';

const SavedFilter = ({ filterBy = () => {}, handleFiltersClear = () => {}, filterData }) => {
  const { data: savedFilters, loading: loadingSavedFilters } = useTimesheetFilters();
  const [
    upsertSavedFilter,
    { loading: upsertingSavedFilter, error: errorUpsertingSavedFilter }
  ] = useTimesheetMutateFilter();

  const [
    deleteSavedFilter,
    { loading: deletingSavedFilter, error: errorDeletingSavedFilter }
  ] = useTimesheetDeleteFilter();
  const [selectedFilter, setSelectedFilter] = useState(defaultFilter);
  const [subMenuFilter, setSubmenuFilter] = useState();
  const [showCreateNewViewModal, setShowCreateNewViewModal] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const defaultFilterDefinedByUser = useMemo(() => savedFilters?.find(f => f.isDefault), [
    savedFilters
  ]);

  useEffect(() => {
    if (loadingSavedFilters) return;
    setIsFirstLoad(false);

    if (defaultFilterDefinedByUser && isFirstLoad) {
      setSelectedFilter(defaultFilterDefinedByUser);
      filterBy(JSON.parse(defaultFilterDefinedByUser.filter));
    }
  }, [defaultFilterDefinedByUser, isFirstLoad, loadingSavedFilters, filterBy]);

  const classes = useStyles();

  const selectedFilterSameAsAppliedFilter = useMemo(
    () => selectedFilter.filter === JSON.stringify(filterData),
    [selectedFilter, filterData]
  );

  const snackbar = useSnackbar();

  const filterSelectRef = createRef();

  const MenuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    anchorEl: () => filterSelectRef.current,
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        width: 220
      }
    },
    autoFocus: false
  };

  return (
    <div css={classes.savedFilterWrapper}>
      <Typography variant="caption" css={classes.filterCaption}>
        FILTERS
      </Typography>
      <Select
        ref={d => {
          if (d) {
            filterSelectRef.current = d;
          }
        }}
        disabled={loadingSavedFilters}
        MenuProps={MenuProps}
        css={classes.selectWrapper}
        onChange={event => {
          if (loadingSavedFilters) {
            return;
          }
          const selected = event.target.value;
          if (!selected) {
            return;
          }

          setSelectedFilter(selected);
          if (!selected.filter) {
            handleFiltersClear();
            return;
          }
          filterBy(JSON.parse(selected.filter));
        }}
        value={selectedFilter?.id ? selectedFilter.displayName : defaultFilter.displayName}
        renderValue={() => (!loadingSavedFilters ? selectedFilter?.displayName : '...Loading')}
        variant="outlined"
      >
        <Typography variant="body2" css={classes.filtersTitle}>
          Filters for Timesheets
        </Typography>
        <Typography variant="caption" css={classes.sectionTitle}>
          DEFAULT FILTER
        </Typography>

        <MenuItem value={defaultFilter} css={classes.menuText} disabled={loadingSavedFilters}>
          {defaultFilter.displayName}
        </MenuItem>

        {defaultFilterDefinedByUser && (
          <MenuItem value={defaultFilterDefinedByUser} css={classes.menuText}>
            {defaultFilterDefinedByUser.displayName}
            <ListItemIcon
              aria-label="more"
              aria-controls={`viewAction${defaultFilterDefinedByUser.displayName}`}
              aria-haspopup="true"
              css={classes.moreIcon}
              onClick={event => {
                event.stopPropagation();
                setSubmenuFilter({
                  filter: defaultFilterDefinedByUser,
                  anchorEl: event.currentTarget
                });
              }}
            >
              <MoreVertIcon css={classes.moreIconStyle} />
            </ListItemIcon>
          </MenuItem>
        )}

        <Typography variant="caption" css={classes.sectionTitle}>
          SAVED FILTERS
        </Typography>
        {orderBy(savedFilters, [f => f.displayName.toLowerCase()], ['asc'])
          ?.filter(f => f.id !== defaultFilterDefinedByUser?.id)
          ?.map(filter => (
            <MenuItem
              key={filter.id}
              value={filter}
              css={classes.menuText}
              disabled={loadingSavedFilters}
            >
              {filter.displayName}
              <ListItemIcon
                aria-label="more"
                aria-controls={`filterAction${filter.displayName}`}
                aria-haspopup="true"
                css={classes.moreIcon}
                onClick={event => {
                  event.stopPropagation();
                  setSubmenuFilter({ filter, anchorEl: event.currentTarget });
                }}
              >
                <MoreVertIcon css={classes.moreIconStyle} />
              </ListItemIcon>
            </MenuItem>
          ))}
        <Grid item>
          <Button
            disabled={!filterData}
            variant="text"
            css={classes.button}
            onClick={e => {
              e.stopPropagation();
              setShowCreateNewViewModal(true);
            }}
          >
            <AddCircleIcon css={classes.iconStyle} />
            Save as new filter
          </Button>
          <Button
            disabled={!selectedFilter?.id || selectedFilterSameAsAppliedFilter}
            variant="text"
            css={classes.button}
            onClick={e => {
              e.stopPropagation();
              const newSelectedFilter = {
                id: selectedFilter.id,
                displayName: selectedFilter.displayName,
                isDefault: selectedFilter.isDefault,
                filter: JSON.stringify(filterData)
              };
              upsertSavedFilter([newSelectedFilter]);
              setSelectedFilter(newSelectedFilter);
              setSubmenuFilter(null);
            }}
          >
            <UpdateIcon css={classes.iconStyle} />
            Update selected filter
          </Button>
        </Grid>
      </Select>
      {subMenuFilter && (
        <SavedFilterActionsMenuItem
          disabled={loadingSavedFilters}
          filter={subMenuFilter?.filter}
          anchor={subMenuFilter?.anchorEl}
          snackbar={snackbar}
          handleUpsertFilter={(name, filterId) => {
            const existingFilter = savedFilters.find(f => f.id === filterId);
            if (!existingFilter)
              snackbar(
                'error',
                'Something went wrong updating the filter, please refresh and try again'
              );
            const newFilter = {
              id: filterId,
              displayName: name,
              filter: existingFilter.filter,
              isDefault: existingFilter.isDefault
            };
            upsertSavedFilter([newFilter]);
            if (filterId === selectedFilter?.id) {
              setSelectedFilter(newFilter);
            }
          }}
          handleDeleteFilter={(name, filterId) => deleteSavedFilter(filterId)}
          handleSetIsDefault={(name, filterId, isNewDefault) => {
            const existingFilter = savedFilters.find(f => f.id === filterId);
            if (!existingFilter || existingFilter.isDefault === isNewDefault)
              snackbar(
                'error',
                'Something went wrong updating the filter, please refresh and try again'
              );

            const newFilters = [{ ...existingFilter, isDefault: isNewDefault }];

            if (isNewDefault) {
              const oldDefault = savedFilters.find(f => f.isDefault);
              if (oldDefault) newFilters.push({ ...oldDefault, isDefault: false });
            }

            upsertSavedFilter(newFilters);
          }}
          handleCloseSubmenu={() => setSubmenuFilter(null)}
        />
      )}
      <RenameModal
        open={showCreateNewViewModal}
        mode={Mode.NEW}
        dataType="Filter"
        currentLabel={defaultNewFilterName}
        handleViewRename={name =>
          upsertSavedFilter([
            {
              displayName: name,
              filter: JSON.stringify(filterData),
              isDefault: false
            }
          ])
        }
        handleClose={() => setShowCreateNewViewModal(null)}
        onClick={e => e.stopPropagation()}
      />
    </div>
  );
};

export default SavedFilter;
