import React from 'react';

import Box from '@material-ui/core/Box';
import {
  DetailsView,
  FileManagerComponent,
  Inject,
  Toolbar
} from '@syncfusion/ej2-react-filemanager';
import { func, string } from 'prop-types';

import ErrorBoundaries from 'scenes/Error';

import {
  beforeDownload,
  beforeImageLoad,
  beforeSend,
  getFilesApiUrl,
  useJwtToken
} from '../FileManagement.hooks';

import FileViewer from '../FileViewer';

import { useStyles } from './SelectOnlyFileManager.styles';

const SelectOnlyFileManager = ({ projectId, onSelectFile }) => {
  const styles = useStyles();
  const jwtToken = useJwtToken();
  const fsApiUrl = getFilesApiUrl(projectId);

  function handleFileSelect(event) {
    const fileManager = this;
    const { id, name, s3Url } = event?.fileDetails || {};
    onSelectFile({ id, name, s3Url }, fileManager?.selectedItems || []);
  }

  return (
    <ErrorBoundaries>
      <Box css={styles.root}>
        <div className="control-section">
          {jwtToken && (
            <FileViewer>
              {handleFileOpen => (
                <FileManagerComponent
                  id="select_file_storage"
                  ajaxSettings={{
                    url: fsApiUrl
                  }}
                  contextMenuSettings={{
                    folder: ['Open'],
                    file: ['Open'],
                    layout: ['SortBy', 'View', 'Refresh', '|', 'SelectAll']
                  }}
                  toolbarSettings={{
                    items: []
                  }}
                  beforeSend={beforeSend(jwtToken)}
                  beforeDownload={beforeDownload(jwtToken)}
                  beforeImageLoad={beforeImageLoad}
                  fileOpen={handleFileOpen}
                  fileSelect={handleFileSelect}
                >
                  <Inject services={[DetailsView, Toolbar]} />
                </FileManagerComponent>
              )}
            </FileViewer>
          )}
        </div>
      </Box>
    </ErrorBoundaries>
  );
};

SelectOnlyFileManager.propTypes = {
  projectId: string.isRequired,
  onSelectFile: func.isRequired
};

export default SelectOnlyFileManager;
