const styles = () => ({
  cropperContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    padding: 12,
    maxWidth: 400
  },
  submitImageButton: {
    float: 'right'
  }
});

export default styles;
