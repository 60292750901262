import React, { useMemo, useCallback } from 'react';
import gql from 'graphql-tag';
import { useSelector, useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box } from '@material-ui/core';

import { PageHeader, UserPermission, XGrid } from 'components';
import { column, ColumnType, valueGetters } from 'components/XGrid/columnTypes';
import DownloadInvoice from 'components/DownloadInvoice';
import SyncStatusIcon from 'components/SyncStatusIcon';
import CreateEntryButton from 'components/Buttons/CreateEntryButton';

import ErrorBoundaries from 'scenes/Error';
import { setOpenQuickAddModal, snackbarOn } from 'redux/actions/globalActions';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import { PermissionConstants, MultiSelectTypes } from 'utils/AppConstants';
import { QuickAddModal, EnumType } from 'utils/constants';

import ExportInvoice from './ExportInvoice';
import { toTitleCase } from 'utils';

export const GET_INVOICES = gql`
  query getInvoicesList(
    $tenantId: String
    $filter: TableFilterInput
    $pagination: PaginationInput
    $sorting: [TableSortingInput]
  ) {
    data: getInvoicesList(
      tenantId: $tenantId
      filter: $filter
      pagination: $pagination
      sorting: $sorting
    ) {
      rowCount
      items
    }
  }
`;

export const invoiceColumns = (isAccountIntegrationEnabled, isAdjustmentEnabled, isPMEnabled) =>
  [
    {
      field: 'invoiceNumber',
      headerName: 'Invoice',
      width: 150,
      valueGetter: valueGetters.invoiceLink,
      ...column[ColumnType.LINK]
    },
    {
      field: 'jobCustomIdentifier',
      headerName: 'Job',
      width: 150,
      valueGetter: valueGetters.jobLink,
      ...column[ColumnType.LINK]
    },
    isPMEnabled && {
      field: 'projectName',
      headerName: 'Project',
      width: 150,
      valueGetter: valueGetters.projectLink,
      ...column[ColumnType.LINK]
    },
    {
      field: 'billingCustomerName',
      headerName: 'Billing Customer',
      width: 200,
      valueGetter: valueGetters.billingCustomerLink,
      ...column[ColumnType.LINK]
    },
    {
      field: 'propertyName',
      headerName: 'Property',
      width: 200,
      dataField: 'propertyId',
      valueGetter: valueGetters.propertyLink,
      ...column[ColumnType.LINK]
    },
    {
      field: 'departmentName',
      headerName: 'Department',
      width: 150,
      enumType: MultiSelectTypes.DEPARTMENTS,
      ...column[ColumnType.TAGS]
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      enumType: MultiSelectTypes.INVOICE_STATUS,
      ...column[ColumnType.ENUM]
    },
    {
      field: 'invoiceTags',
      headerName: 'Tags',
      width: 100,
      enumType: MultiSelectTypes.INVOICE_TAGS,
      ...column[ColumnType.TAGS]
    },
    {
      field: 'dueDate',
      headerName: 'Due',
      width: 150,
      ...column[ColumnType.DATE]
    },
    {
      field: 'issuedDate',
      headerName: 'Issued',
      width: 150,
      ...column[ColumnType.DATE]
    },
    {
      field: 'totalAmount',
      headerName: 'Amount',
      width: 150,
      ...column[ColumnType.CURRENCY]
    },
    {
      field: 'outstandingBalance',
      headerName: 'Balance',
      width: 150,
      isBalance: true,
      ...column[ColumnType.CURRENCY]
    },
    isAdjustmentEnabled && {
      field: 'adjustedBalance',
      headerName: 'Adjusted Balance',
      width: 150,
      isBalance: true,
      ...column[ColumnType.CURRENCY]
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 150,
      ...column[ColumnType.TEXT]
    },
    {
      field: 'createdDate',
      headerName: 'Created On',
      width: 150,
      ...column[ColumnType.DATE]
    },
    {
      field: 'customerProvidedPONumber',
      headerName: 'Customer PO#',
      width: 175,
      ...column[ColumnType.TEXT]
    },
    {
      field: 'amountNotToExceed',
      headerName: 'Amount Not To Exceed',
      width: 220,
      ...column[ColumnType.CURRENCY]
    },
    {
      field: 'propertyAddress',
      headerName: 'Property Address',
      width: 275,
      ...column[ColumnType.ADDRESS]
    },
    {
      field: 'propertyAddress',
      headerName: 'Property Address',
      width: 275,
      ...column[ColumnType.ADDRESS]
    },
    {
      field: 'propertyAddress',
      headerName: 'Property Address',
      width: 275,
      ...column[ColumnType.ADDRESS]
    },
    isAccountIntegrationEnabled && {
      field: 'syncStatus',
      headerName: 'Sync Status',
      width: 150,
      renderCell: ({ value }) => <SyncStatusIcon status={value} />,
      valueFormatter: ({ value }) => toTitleCase(value)
    },
    {
      field: 'download',
      headerName: 'Download',
      sortable: false,
      filterable: false,
      align: 'center',
      width: 150,
      renderCell: ({ row }) => <DownloadInvoice record={row} />,
      disableExport: true
    }
  ].filter(Boolean);

const InvoicesList = () => {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const addModal = useCallback((...args) => dispatch(setOpenQuickAddModal(...args)), [dispatch]);
  const snackbar = useCallback((...args) => dispatch(snackbarOn(...args)), [dispatch]);

  const pageHeaderButtons = useMemo(
    () => (
      <Box display="flex">
        <ExportInvoice user={user} snackbarOn={snackbar} />
        <CreateEntryButton
          handleAdd={() => addModal(QuickAddModal.CREATE_INVOICE)}
          label="New Invoice"
          caslKey={PermissionConstants.OBJECT_INVOICE}
          key="createInvoice"
        />
      </Box>
    ),
    [addModal, snackbar, user]
  );

  const tenantSettings = useSelector(s => s.settings);
  const { accountingApp } = tenantSettings;
  const accountingIntegrationEnabled = !!accountingApp;
  const flags = useFlags();
  const isAdjustmentEnabled = flags[FeatureFlags.ADJUSTMENTS];
  const isPMEnabled = flags[FeatureFlags.PROJECT_MANAGEMENT];
  const columns = useMemo(
    () => invoiceColumns(accountingIntegrationEnabled, isAdjustmentEnabled, isPMEnabled),
    [accountingIntegrationEnabled, isAdjustmentEnabled, isPMEnabled]
  );
  return (
    <ErrorBoundaries>
      <UserPermission I="read" action={PermissionConstants.OBJECT_INVOICE}>
        <PageHeader
          pageMapKey="invoices"
          userLocale={user.locale}
          overrideHeaderButtons={pageHeaderButtons}
        />
        <XGrid
          columns={columns}
          entityTypeName="Invoices"
          tableName="InvoicesXGrid"
          query={GET_INVOICES}
          rowHeight={38}
          key={`${isAdjustmentEnabled}-${isPMEnabled}`}
          enableExport
        />
      </UserPermission>
    </ErrorBoundaries>
  );
};

export default InvoicesList;
