import { bool, arrayOf } from 'prop-types';
import { gql } from '@apollo/client/core';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { getState } from 'redux/store';

import { visitDetailsFragment } from '@dispatch/fragments';
import { ERROR_PROP } from '@dispatch/Dispatch.constants';
import { VISIT_DETAILS_PROP, transformVisitDetails } from '@dispatch/queries';
import { VISIT_SUBSCRIPTION } from '@dispatch/subscription';

const GET_UNSCHEDULED_VISITS = gql`
  query getUnscheduledVisits($partitionKey: String!, $sortKey: String!) {
    unscheduledVisits: getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      visits: visitsView(
        entityConnection: "Visit"
        pagination: { limit: 10000 }
        filter: { integerFilters: [{ fieldName: "Visit.scheduledFor", filterInput: { is: null } }] }
      ) {
        items {
          ${visitDetailsFragment}
        }
      }
    }
  }
`;

const DEFAULT_DATA = [];

// format to match VISIT_DETAILS_PROP
export const transformBoardVisits = data => {
  const visits = data?.unscheduledVisits?.visits?.items;

  if (!visits) return DEFAULT_DATA;

  return visits.map(visit => transformVisitDetails({ visit }));
};

export const useUnscheduledVisits = ({ skip } = {}) => {
  const { tenantId: partitionKey, tenantCompanyId } = getState().user;
  const sortKey = `${partitionKey}_Company_${tenantCompanyId}`;

  return useExtendedQuery(GET_UNSCHEDULED_VISITS, {
    transform: transformBoardVisits,
    defaultData: DEFAULT_DATA,
    variables: {
      partitionKey,
      sortKey
    },
    skip,
    subscribeToMore: {
      document: VISIT_SUBSCRIPTION,
      variables: { partitionKey },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        const updatedVisit = subscriptionData.data.visitUpdateNotification;

        const hasNoScheduledFor = !updatedVisit.scheduledFor;
        const prevVisits = prev?.unscheduledVisits?.visits?.items || [];

        const newItems = hasNoScheduledFor
          ? [updatedVisit, ...prevVisits.filter(visit => visit.id !== updatedVisit.id)]
          : [...prevVisits.filter(visit => visit.id !== updatedVisit.id)];

        return {
          ...prev,
          unscheduledVisits: {
            ...prev.unscheduledVisits,
            visits: {
              ...prev.unscheduledVisits.visits,
              items: newItems
            }
          }
        };
      }
    }
  });
};

export const BOARD_VISIT_RESPONSE_PROP = {
  error: ERROR_PROP,
  loading: bool,
  data: arrayOf(VISIT_DETAILS_PROP)
};
