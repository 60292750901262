// eslint-disable-next-line import/prefer-default-export
import { useTheme } from '@emotion/react';

export const useFooterStyles = () => {
  const { palette } = useTheme();

  return {
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight: 24,
      paddingLeft: 24,
      height: 40,
      borderRight: `1px solid ${palette.grayscale(90)}`,
      borderLeft: `1px solid ${palette.grayscale(90)}`,
      borderBottom: `1px solid ${palette.grayscale(90)}`,
      padding: 0
    },
    pagination: {
      width: '100%',
      '& [class*="MuiTablePagination-spacer"]': {
        order: 2
      },
      '& [class*="MuiTablePagination-caption"]:nth-of-type(1)': {
        order: 3
      },
      '& [class*="MuiTablePagination-selectRoot"]': {
        order: 4
      },
      '& [class*="MuiTablePagination-caption"]:nth-of-type(2)': {
        order: 1
      },
      '& [class*="MuiTablePagination-actions"]': {
        order: 5
      }
    }
  };
};
