import gql from 'graphql-tag';
import orderBy from 'lodash/orderBy';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { useSnackbar } from 'customHooks/useSnackbar';
import { logErrorWithCallback } from 'utils';

const GET_CREWS = gql`
  query getCrews {
    crews {
      items {
        id
        name
        departmentId
        foremanId
        techs {
          id
        }
      }
    }
  }
`;

const transformCrews = data => {
  return orderBy(data.crews.items, [crew => crew.name.toLowerCase()]);
};

const useCrews = (options = {}) => {
  const snackbar = useSnackbar();

  const { data, loading, error } = useExtendedQuery(GET_CREWS, {
    transform: transformCrews,
    defaultData: [],
    ...options
  });

  if (error) {
    logErrorWithCallback(error, snackbar, 'Unable to get crews');
  }
  return [data, loading];
};

export default useCrews;
