import React from 'react';
import { List, ListItem, Typography, ListItemSecondaryAction, withStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Divider from '@material-ui/core/Divider';
import useEmployees from 'customHooks/useEmployees';
import { MUIFormComponents } from '@buildhero/sergeant';
import BulkSelect from './BulkSelect';

function withDividers(items) {
  const listItems = [];
  items.forEach((item, index) => {
    listItems.push(item);
    if (index + 1 < items.length) listItems.push(<Divider />);
  });

  return listItems;
}

/*
 * CUSTOM OPTIONS
 * options: {
 *   filterOptions: fn(
 *      form, - only if you need to access form values for filtering
 *   )
 * }
 */
const MultiEmployeeSelector = props => {
  const { form, classes, options, field } = props;
  const [employees] = useEmployees({ includeDepartments: true });

  // added employees processing
  const addedEmployeeIds = field.value ?? [];
  const addedEmployees = addedEmployeeIds.map(addedEmployeeId =>
    employees.find(({ id }) => id === addedEmployeeId)
  );

  // X button on added employee
  const handleRemoveAddedEmployeeId = id => {
    form.setFieldValue(
      field.name,
      addedEmployeeIds.filter(addedEmployeeId => addedEmployeeId !== id)
    );
  };

  // filter employees with custom filter
  let employeeOptions = employees;
  if (options.filterOptions) {
    employeeOptions = options.filterOptions(employees, form);
  }

  const listOptions = employeeOptions.map(employee => ({
    label: employee.name,
    value: employee.id,
    profilePictureUrl: employee.profilePictureUrl
  }));

  return (
    <>
      <div style={{ width: '50%' }}>
        <MUIFormComponents.LabelControl value={options.label} />
        <List style={{ padding: 0, paddingBottom: addedEmployees?.length ? 5 : 0 }}>
          {withDividers(
            addedEmployees.map(tech => (
              <ListItem className={classes.addedTechItem}>
                <Typography>{tech?.name || ''}</Typography>
                <ListItemSecondaryAction>
                  <ClearIcon
                    className={classes.clearIcon}
                    onClick={() => handleRemoveAddedEmployeeId(tech.id)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))
          )}
        </List>
      </div>
      <BulkSelect
        options={listOptions}
        onAdd={items => {
          form.setFieldValue(field.name, [...addedEmployeeIds, ...items.map(({ value }) => value)]);
        }}
        isDisabled={options.disabled}
      />
    </>
  );
};

const styles = theme => ({
  addedTechItem: {
    alignItems: 'center',
    backgroundColor: theme.palette.grayscale(94)
  },
  clearIcon: {
    color: theme.palette.grayscale(20),
    fontSize: 'unset',
    lineHeight: 'unset'
  }
});

export default withStyles(styles)(MultiEmployeeSelector);
