import React from 'react';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Modal, AlgoliaSearch } from 'components';
import Constants from 'utils/AppConstants';
import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';
import { AddressType, EntityType } from 'utils/constants';

const SelectCustomerModal = props => {
  const { open, onCustomerSelected, handleClose } = props;
  const { CUSTOMER, COMPANY_ADDRESS } = EntityType;
  const { BILLING } = AddressType;
  const handleSearchResultsFilter = resultList => {
    let allowedIds = [];
    let uniqueCustomerIds = [];
    const companyAddressResult = resultList.filter(
      hit =>
        hit.entityType === COMPANY_ADDRESS &&
        hit.parentEntityType === CUSTOMER &&
        hit.addressType === BILLING
    );

    if (companyAddressResult && companyAddressResult.length > 0) {
      allowedIds = allowedIds.concat(map(companyAddressResult, 'id'));
      uniqueCustomerIds = uniqueCustomerIds.concat(map(companyAddressResult, 'parentId'));
    }

    const customerList = resultList.filter(hit => hit.entityType === CUSTOMER);
    if (customerList && customerList.length > 0) {
      customerList.forEach(customer => {
        if (!uniqueCustomerIds.includes(customer.id)) {
          allowedIds.push(customer.id);
        }
      });
    }

    const filterList = resultList.filter(hit => allowedIds.includes(hit.id));

    return filterList;
  };

  return (
    <Modal open={open} handleClose={() => handleClose()} width="1100" height="700">
      <div style={{ marginTop: 20, marginBottom: 240 }}>
        <Typography style={{ marginBottom: 10 }}>
          Please select a customer to create a property:
        </Typography>
        <AlgoliaSearch
          searchIndex={defaultSearchIndex}
          label="Search by customer name or address"
          name="selectCustomer"
          value=""
          displayText={['customerName', 'address']}
          delimiter=" "
          locatedInPopUp
          filters={[
            {
              attribute: 'entityType',
              valueArray: [COMPANY_ADDRESS, CUSTOMER]
            },
            {
              parenthetical: true,
              operand: 'OR',
              attributeArray: ['status', 'parentStatus'],
              valueArray: [Constants.ACTIVE, Constants.ACTIVE]
            }
          ]}
          handleFilter={handleSearchResultsFilter}
          handleChange={customer => customer && onCustomerSelected(customer)}
        />
      </div>
    </Modal>
  );
};

SelectCustomerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCustomerSelected: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default SelectCustomerModal;
