import { useFlags } from 'launchdarkly-react-client-sdk';
import { orderBy } from 'lodash';

import { payrollSettingFilter } from 'constants/common';
import AmplifyService from 'services/AmplifyService';
import { CompanyService } from 'services/core';
import { sentryException, sentryMessage } from 'services/Logger';
import { logErrorWithCallback } from 'utils';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import {
  addTimesheetNotesToNonVisitEventMutation,
  addTimesheetNotesToTimesheetEntryBinderMutation,
  addTimesheetNotesToVisitMutation,
  getActiveUnsubmittedEventsQuery,
  getNumberOfDisputedDayCardsQuery,
  getNumberOfSubmittedDayCardsQuery,
  getTimeOfOldestTimesheetThatNeedsReviewQuery,
  getTimesheetPeriodByIdQuery,
  getTimesheetPeriodSelectOptionsQuery,
  getTimesheetPeriodsPendingForEmployeeQuery,
  prevNextTimesheetPeriodQuery,
  updateTimesheetEntryHoursMutation,
  updateTimesheetNoteMutation
} from './customHooks/gql';

export const bindersEnabled = () => {
  const flags = useFlags();
  return flags[FeatureFlags.WRINKLE_IN_TIME];
};

// deprecate once wrinkle-in-time FF enabled everywhere
export const getActiveUnsubmittedEvents = async ({ employee, snackbarOn }) => {
  const api = AmplifyService.appSyncClient();
  try {
    if (!employee) return null;
    const { id } = employee;
    const params = {
      employeeId: id
    };
    const { data } = await api.query(getActiveUnsubmittedEventsQuery, params);
    return data.getActiveUnsubmittedEvents;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to fetch active unsubmitted events');
  }
};

export const getTimeOfOldestTimesheetThatNeedsReview = async ({ employee, snackbarOn }) => {
  const api = AmplifyService.appSyncClient();
  try {
    if (!employee) return null;
    const { id } = employee;
    const params = {
      employeeId: id
    };
    const { data } = await api.query(getTimeOfOldestTimesheetThatNeedsReviewQuery, params);
    return data.getTimeOfOldestTimesheetThatNeedsReview;
  } catch (error) {
    logErrorWithCallback(
      error,
      snackbarOn,
      'Unable to fetch the time of oldest event that needs review'
    );
  }
};

export const getTimesheetPeriodsPendingForEmployee = async ({ employee, snackbarOn }) => {
  const api = AmplifyService.appSyncClient();

  try {
    if (!employee) return null;
    const { id } = employee;
    const params = {
      employee: id
    };

    const { data } = await api.query(getTimesheetPeriodsPendingForEmployeeQuery, params);
    return data.timesheetPeriodsOfStatusForEmployee;
  } catch (error) {
    logErrorWithCallback(
      error,
      snackbarOn,
      'Unable to fetch the timesheet period pending for employee'
    );
  }
};

export const getPayrollSettings = async ({ user, snackbarOn }) => {
  let responseData = {};
  try {
    const Service = new CompanyService();
    const { tenantId, tenantCompanyId } = user;
    const { data } = await Service.getCompanySettings(
      tenantId,
      tenantCompanyId,
      payrollSettingFilter
    );
    responseData = data?.getCompany?.companySettings?.items?.[0];
  } catch (error) {
    const errorMessage = 'Unable to fetch settings, please try again later';
    logErrorWithCallback(error, snackbarOn, errorMessage);
  } finally {
    const payrollSettingStr = responseData?.settings;
    const settingObj = payrollSettingStr ? JSON.parse(payrollSettingStr) : {};
    return settingObj;
  }
};

export const getEmployeeNumberOfSubmittedDayCards = async ({ employee, snackbarOn }) => {
  const api = AmplifyService.appSyncClient();

  try {
    if (!employee) return null;
    const { id } = employee;
    const params = {
      employeeId: id
    };

    const { data } = await api.query(getNumberOfSubmittedDayCardsQuery, params);
    return data.getNumberOfSubmittedDayCards;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to fetch number of submitted timesheets');
  }
};

export const getEmployeeNumberOfDisputedDayCards = async ({ employee, snackbarOn }) => {
  const api = AmplifyService.appSyncClient();

  try {
    if (!employee) return null;
    const { id } = employee;
    const params = {
      employeeId: id
    };

    const { data } = await api.query(getNumberOfDisputedDayCardsQuery, params);
    return data.getNumberOfDisputedDayCards;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to fetch number of pending timesheets');
  }
};

export const getTimesheetPeriods = async ({ employee, snackbarOn }) => {
  const api = AmplifyService.appSyncClient();

  try {
    if (!employee) return null;
    const { tenantId, sortKey } = employee;
    const params = {
      partitionKey: tenantId,
      sortKey
    };

    const { data } = await api.query(getTimesheetPeriodSelectOptionsQuery, params);

    const timesheetPeriods = data?.getEmployee?.timesheetPeriods?.items || [];

    const result = orderBy(timesheetPeriods, 'dateStartUTC', 'desc');

    if (result.filter((p, i, ps) => i !== 0 && p.dateStartUTC === ps[i - 1].dateStartUTC).length) {
      sentryMessage('Duplicate timesheet periods exist', { periods: result });
    }
    return result;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to fetch timesheet periods');
  }
};

// deprecate once wrinke-in-time FF enabled everywhere
export const getTimesheetPeriodById = async ({ id, snackbarOn }) => {
  const api = AmplifyService.appSyncClient();

  try {
    const { data } = await api.query(getTimesheetPeriodByIdQuery, { id });
    return data.getTimesheetPeriodById;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to fetch the timesheet period');
  }
};

export const updateTimesheetEntryHours = async ({ employee, timesheetEntries, snackbarOn }) => {
  const { tenantId, id } = employee;
  const api = AmplifyService.appSyncClient();

  try {
    const params = {
      partitionKey: tenantId,
      data: {
        employeeId: id,
        timesheetEntries
      }
    };
    const { data } = await api.mutate(updateTimesheetEntryHoursMutation, params);
    snackbarOn('success', 'Updated timesheet successfully');
    return data;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to update time entries hours');
  }
};

export const prevNextTimesheetPeriod = async ({ employee, status, date, dir, snackbarOn }) => {
  const { id: employeeId } = employee;
  const api = AmplifyService.appSyncClient();

  try {
    const params = {
      employeeId,
      data: {
        statuses: [status],
        date,
        dir
      }
    };
    const { data } = await api.query(prevNextTimesheetPeriodQuery, params);
    return data.prevNextTimesheetPeriod;
  } catch (error) {
    logErrorWithCallback(
      error,
      snackbarOn,
      `Unable to get ${dir === 'NEXT' ? 'next' : 'previous'} timesheet period`
    );
  }
};

export const upsertTimesheetNote = async finalData => {
  const api = AmplifyService.appSyncClient();
  // errors are caught in handleOnComplete of src/components/AttachmentSection/AddAttachment/index.js
  if (!finalData.customFileName) {
    throw new Error('Subject is Required');
  }
  if (finalData.id) {
    const data = {
      id: finalData.id,
      subject: finalData.customFileName,
      note: finalData.comment,
      version: finalData.version,
      attachments: finalData.fileUrl
        ? [
            {
              id: finalData.attachmentId,
              fileUrl: finalData.fileUrl,
              fileName: finalData.fileName || finalData.customFileName
            }
          ]
        : []
    };
    await api.client.mutate({
      mutation: updateTimesheetNoteMutation,
      variables: {
        partitionKey: finalData.parent?.tenantId,
        data
      }
    });
  } else if (finalData.parent?.entityType === 'Visit') {
    const data = {
      visitId: finalData.parent?.id,
      timesheetNotes: [
        {
          // id is undefined for new entries
          subject: finalData.customFileName,
          note: finalData.comment,
          employeeId: finalData.employeeId,
          attachments: finalData.fileUrl
            ? [
                {
                  fileUrl: finalData.fileUrl,
                  fileName: finalData.fileName || finalData.customFileName
                }
              ]
            : []
        }
      ]
    };
    await api.client.mutate({
      mutation: addTimesheetNotesToVisitMutation,
      variables: {
        partitionKey: finalData.parent?.tenantId,
        data
      }
    });
  } else if (finalData.parent?.entityType === 'NonVisitEvent') {
    const data = {
      nonVisitEventId: finalData.parent?.id,
      timesheetNotes: [
        {
          subject: finalData.customFileName,
          note: finalData.comment,
          employeeId: finalData.employeeId,
          attachments: finalData.fileUrl
            ? [
                {
                  fileUrl: finalData.fileUrl,
                  fileName: finalData.fileName || finalData.customFileName
                }
              ]
            : []
        }
      ]
    };

    await api.client.mutate({
      mutation: addTimesheetNotesToNonVisitEventMutation,
      variables: {
        partitionKey: finalData.parent?.tenantId,
        data
      }
    });
  } else if (finalData.parent?.entityType === 'TimesheetEntryBinder') {
    const data = {
      binderId: finalData.parent?.id,
      timesheetNotes: [
        {
          subject: finalData.customFileName,
          note: finalData.comment,
          employeeId: finalData.employeeId,
          attachments: finalData.fileUrl
            ? [
                {
                  fileUrl: finalData.fileUrl,
                  fileName: finalData.fileName || finalData.customFileName
                }
              ]
            : []
        }
      ]
    };

    await api.client.mutate({
      mutation: addTimesheetNotesToTimesheetEntryBinderMutation,
      variables: {
        partitionKey: finalData.parent?.tenantId,
        data
      }
    });
  } else {
    sentryException('invalid TimesheetNote Data on add/edit', { finalData });
    throw new Error();
  }
};

export const updateTimesheetUrl = ({ employee, date }) =>
  window.history.replaceState(
    null,
    '',
    date ? `/timesheets/${employee.id}/${date}` : `/timesheets/${employee.id}`
  );
