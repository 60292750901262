import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import DefaultButton from 'components/Buttons/DefaultButton';
import SendIcon from '@material-ui/icons/Send';
import Skeleton from 'react-loading-skeleton';
import MailIcon from '@material-ui/icons/Mail';
import { useFetchEmails } from 'services/APIHooks';
import useStyles from './Communications.styles';
import Messages from './components/Messages';
import EmailModal from './components/EmailModal';

const Communications = props => {
  const {
    parentId,
    onClickEmailButton,
    showEmailButton,
    previewPdfTitle,
    user,
    handleEmailClose,
    pdfIsLoading,
    emailData,
    project,
    beforeSendEmail,
    onEmailSent,
    initialData,
    messagesTransform
  } = props;
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const classes = useStyles();

  const [{ data: emails, loading: emailsAreLoading }, refetchEmails] = useFetchEmails(parentId, {
    depends: [parentId],
    transform: messagesTransform
  });

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <div className={classes.titleBar}>
          <MailIcon className={classes.mailIcon} />
          <Typography className={classes.title}>Communication History</Typography>
        </div>
        <div className={classes.titleButton}>
          {showEmailButton && (
            <DefaultButton
              label="EMAIL"
              onClick={() => {
                onClickEmailButton();
                setIsEmailModalOpen(true);
              }}
              variant="containedPrimary"
              buttonProps={{ startIcon: <SendIcon /> }}
              disabled={pdfIsLoading}
              showSpinner={pdfIsLoading}
            />
          )}
        </div>
      </div>
      <div className={classes.messagesContainer}>
        {emailsAreLoading ? (
          <Skeleton count={4} height={50} />
        ) : (
          <Messages user={user} messages={emails} />
        )}
      </div>

      <EmailModal
        open={isEmailModalOpen}
        data={initialData}
        project={project}
        user={user}
        previewPdfTitle={previewPdfTitle}
        beforeSendEmail={beforeSendEmail}
        handleClose={() => {
          handleEmailClose();
          setIsEmailModalOpen(false);
          refetchEmails();
        }}
        onEmailSent={onEmailSent}
        emailData={emailData}
        close={() => setIsEmailModalOpen(false)}
      />
    </div>
  );
};

Communications.propTypes = {
  parentId: PropTypes.string.isRequired,
  showEmailButton: PropTypes.bool,
  onClickEmailButton: PropTypes.func,
  project: PropTypes.object.isRequired,
  messagesTransform: PropTypes.func,
  initialData: PropTypes.object.isRequired,
  previewPdfTitle: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  handleEmailClose: PropTypes.func,
  pdfIsLoading: PropTypes.bool,
  beforeSendEmail: PropTypes.func,
  emailData: PropTypes.object,
  onEmailSent: PropTypes.func.isRequired
};
Communications.defaultProps = {
  showEmailButton: false,
  messagesTransform: data => data,
  onClickEmailButton: () => {},
  handleEmailClose: () => {},
  pdfIsLoading: false,
  beforeSendEmail: () => {},
  emailData: {}
};

export default Communications;
