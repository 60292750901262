import gql from 'graphql-tag';

const getCustomerProperty = gql`
  query getCustomerProperty($partitionKey: String!, $sortKey: String!) {
    getCustomerProperty(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      parentId
      parentSortKey
      companyName
      customerPropertyTypeValue
      customerPropertyTypeSortKey
      accountNumber
      status
      email
      phonePrimary
      amountNotToExceed
      sameAddress
      version
      isTaxable
      isActive
      billTo
      billingCustomerId
      billingCustomer(entityConnection: "Customer") {
        id
        customerName
        isActive
        isTaxable
        taxRateId
        companyAddresses(entityConnection: "CompanyAddress") {
          items {
            id
            tenantId
            tenantCompanyId
            partitionKey
            sortKey
            hierarchy
            entityType
            addressLine1
            addressLine2
            longitude
            latitude
            state
            city
            zipcode
            addressType
            entityType
            sortKey
            billTo
          }
        }
      }
      taxRateId
      taxRate(entityConnection: "TaxRate") {
        name
        taxRate
      }
      accountingRefId
      companyAddresses(entityConnection: "CompanyAddress") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          addressLine1
          addressLine2
          state
          city
          zipcode
          addressType
          entityType
          sortKey
          billTo
          longitude
          latitude
        }
      }
      notes(entityConnection: "Note") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          subject
          note
          createdBy
          lastUpdatedDate
          lastUpdatedDateTime
          lastUpdatedBy
          version
        }
      }
      jobs: jobsView(entityConnection: "Job") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          customIdentifier
          jobNumber
          jobTypeName
          jobTypeInternal
          priority
          status
          customerName
          customerPropertyName
        }
      }
      parentEntity {
        ... on Customer {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          customerName
          isActive
          isTaxable
          taxRateId
          companyAddresses(entityConnection: "CompanyAddress") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              addressLine1
              addressLine2
              state
              city
              zipcode
              addressType
              entityType
              sortKey
              billTo
              longitude
              latitude
            }
          }
        }
      }
    }
  }
`;
export default getCustomerProperty;
