import memoize from 'memoize-one';
import React, { useEffect, useState, createContext, useContext } from 'react';
import moment from 'moment';

import { ElementSizes } from '@dispatch/Dispatch.styles';

const { techCellWidth, cellWidth } = ElementSizes;

const positionOffset = techCellWidth;

const getCurrentTimePosition = time => {
  const hours = time.hours();
  const minutes = time.minutes();
  return positionOffset + hours * cellWidth + (minutes * cellWidth) / 60;
};

const getContextValueForCurrentTime = memoize(() => {
  const time = moment();
  return {
    time,
    position: getCurrentTimePosition(time),
    positionOffset
  };
});

const getMemoizedContextValue = () => {
  return getContextValueForCurrentTime(
    moment()
      .seconds(0)
      .milliseconds(0)
      .toISOString()
  );
};

const CurrentTimePositionContext = createContext(getMemoizedContextValue());

export const useTimePosition = () => useContext(CurrentTimePositionContext);

export const withTimePosition = Component => props => {
  const [contextValue, setContextValue] = useState(getMemoizedContextValue());

  useEffect(() => {
    const interval = setInterval(() => {
      setContextValue(getMemoizedContextValue());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <CurrentTimePositionContext.Provider value={contextValue}>
      <Component {...props} />
    </CurrentTimePositionContext.Provider>
  );
};
