import gql from 'graphql-tag';

const addPayrollBillingHourTypesToCompany = gql`
  mutation addPayrollBillingHourTypesToCompany(
    $partitionKey: String
    $data: AddBillingHourTypesToCompanyInput!
  ) {
    addBillingHourTypesToCompany(partitionKey: $partitionKey, data: $data) {
      id
      version
      isActive
      hourType
      hourTypeAbbreviation
      sortOrder
    }
  }
`;

export default addPayrollBillingHourTypesToCompany;
