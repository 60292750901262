import gql from 'graphql-tag';

const getBillingAddressForCustomer = gql`
  query getCustomer($partitionKey: String!, $sortKey: String!) {
    getCustomer(partitionKey: $partitionKey, sortKey: $sortKey) {
      companyAddresses(
        entityConnection: "CompanyAddress"
        filter: { addressType: { eq: "billingAddress" } }
      ) {
        items {
          billTo
          addressLine1
          addressLine2
          state
          city
          zipcode
          addressType
          latitude
          longitude
        }
      }
      taxRateId
      taxRate(entityConnection: "TaxRate") {
        taxRate
        accountingRefId
      }
      isTaxable
      customerName
    }
  }
`;

export default getBillingAddressForCustomer;
