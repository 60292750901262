import React, { Component } from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Hits,
  connectStateResults,
  connectSearchBox,
  connectCurrentRefinements,
  Configure
} from 'react-instantsearch-dom';
import { CommonService, CustomerService } from 'services/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  Popper,
  // ClickAwayListener,
  Fade,
  Paper,
  TextField,
  List,
  IconButton,
  InputAdornment
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import configForEnvironment from 'configs/aws-exports';
import ENV from 'configs/env';
import { Logger } from 'services/Logger';
import { findAddressByAddressType } from 'utils';
import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';

import Hit from './Hit';
import styles from './styles';
import './style.css';

const entityFormatActions = {
  Customer: async (tenantCompanyId, value) => {
    const customerService = new CustomerService();
    const { data } = await customerService.getCustomerBillingAddress(
      value.partitionKey,
      value.sortKey
    );
    const addresses = data?.getCustomer?.companyAddresses?.items || [];
    const billingAddress = findAddressByAddressType(addresses, 'billingAddress') || {};
    if (!billingAddress.billTo) {
      billingAddress.billTo = data?.getCustomer?.customerName;
    }
    return {
      customerId: value.id,
      entityType: value.entityType,
      customerName: value.customerName,
      idField: 'customerId',
      customerPropertyName: '',
      jobNumber: '',
      tenantCompanyId,
      billingAddress,
      salesTaxRate: data?.getCustomer?.taxRate?.taxRate,
      isTaxable: data?.getCustomer?.isTaxable,
      taxRateId: data?.getCustomer?.taxRateId
    };
  },
  /*
    https://buildops.atlassian.net/browse/BUOP-4606
    Show customer address in dropdown
  */
  CompanyAddress: async (tenantCompanyId, value) => {
    const customerService = new CustomerService();
    const { data } = await customerService.getCustomerBillingAddress(
      value.partitionKey,
      value.parentSortKey
    );
    const addresses = data?.getCustomer?.companyAddresses?.items || [];
    const billingAddress = findAddressByAddressType(addresses, 'billingAddress') || {};
    if (!billingAddress.billTo) {
      billingAddress.billTo = data?.getCustomer?.customerName;
    }
    return {
      customerId: value.parentId,
      entityType: value.parentEntityType,
      customerName: value.customerName,
      idField: 'customerId',
      customerPropertyName: '',
      jobNumber: '',
      tenantCompanyId,
      billingAddress
    };
  }
};
class AlgoliaSearchDropdown extends Component {
  // eslint-disable-next-line no-confusing-arrow

  constructor(props) {
    super(props);
    this.CommonService = new CommonService();
    this.state = {
      searchKey: '',
      searchClient: ''
    };
    this.isEdited = false;
    this.currentRefinement = '';
    this.inputRef = React.createRef();
    this.searchItems = [];
    this.billingCustomerSelected = false;
  }

  componentDidMount = async () => {
    try {
      const { data } = await this.CommonService.getAlgoliaSecuredKey();
      const searchKey = data.getAlgoliaSecuredKey;
      const searchClient = algoliasearch(configForEnvironment(ENV).algoliaAppKey, searchKey);
      this.searchClient = searchClient;
      this.setState({ searchKey, searchClient });
    } catch (error) {
      Logger.error(error);
    }
  };

  clearRefinementsComponent = ({ items, refine }) => (
    <IconButton
      style={{ backgroundColor: 'transparent' }}
      aria-label="Close"
      onClick={() => {
        this.props.form.setFieldValue(this.props.field.name, '', false);
        return refine(items);
      }}
    >
      <CloseIcon
        style={{ color: '#3f3f3f', fontSize: 20 }}
        disabled={!items || (items && !items.length)}
      />
    </IconButton>
  );

  HitComp = props => (
    <Hit
      formProps={this.props}
      isFieldEdited={() => {
        if (this.isEdited) {
          this.isEdited = false;
        }

        return this.isFieldEdited;
      }}
      {...props}
    />
  );

  searchResults = props => {
    const { searchState, searchResults } = props;
    const { HitComp } = this;
    const { specialbehaviour } = this.props;
    const { warning } = specialbehaviour;
    this.searchItems = searchResults?.hits;

    return searchState && searchState.query ? (
      <Popper
        id="searchResults"
        open={searchState !== ''}
        disablePortal
        anchorEl={() => this.inputRef.current}
        placement="bottom-start"
        transition
        style={{ zIndex: 5, width: 350 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            {/* <ClickAwayListener onClickAway={this.handleClickAway}> */}
            <Paper
              style={{
                backgroundColor: '#fafafa',
                width: '100%',
                maxHeight: 240,
                // width: window.innerWidth < 600 ? '100%' : window.innerWidth * 0.64,
                // maxHeight: window.innerHeight * 0.7,
                overflowY: 'auto',
                marginTop: -20
              }}
            >
              <List component="nav">
                <Hits hitComponent={HitComp} />
                {searchResults && searchResults.nbHits === 0 && (
                  <p style={{ paddingLeft: 5 }}>{warning || 'No option'}</p>
                )}
              </List>
            </Paper>
            {/* </ClickAwayListener> */}
          </Fade>
        )}
      </Popper>
    ) : null;
  };

  handleSearchChange = async (selectedItem, form) => {
    const { tenantCompanyId } = this.props.user;
    const { entityType } = selectedItem;
    const formatFunction = entityFormatActions[entityType];
    let searchData = {};
    if (formatFunction) {
      searchData = await formatFunction(tenantCompanyId, selectedItem);
    }
    // Include additional data from `searchData` (e.g. customerId) not rendered in any form field;
    // this data is required for invoice creation.
    const newValues = {
      ...form.values,
      billingAddressLine1: searchData.billingAddress.addressLine1,
      billingAddressLine2: searchData.billingAddress.addressLine2,
      billingCity: searchData.billingAddress.city,
      billingState: searchData.billingAddress.state,
      billingZipcode: searchData.billingAddress.zipcode
    };
    form.setValues(newValues);
  };

  SearchBox = props => {
    const { currentRefinement, refine, ...rest } = props;
    const { field, label, form } = this.props;
    const { errors, values } = form;
    const { value } = field;
    this.currentRefinement = currentRefinement;
    const valueToDisplay = this.isEdited ? currentRefinement : value;
    const ClearRefinementsComponent = connectCurrentRefinements(this.clearRefinementsComponent);
    const hasError = errors && errors[field.name] && errors[field.name] !== '';

    if (!this.isEdited && value && !this.billingCustomerSelected) {
      const item = this.searchItems?.filter(customer => customer.customerName === value);
      if (item && item[0]) {
        this.handleSearchChange(item[0], form);
      }
      this.billingCustomerSelected = true;
    }

    let disabled = true;
    if (values.checkBoxChecked !== 'true' && values.checkBoxChecked !== true) {
      disabled = false;
      rest.InputProps = {
        endAdornment: (
          <InputAdornment position="end">
            <ClearRefinementsComponent clearsQuery />
          </InputAdornment>
        )
      };
    }

    return (
      <TextField
        id={this.props.field.name}
        label={label}
        fullWidth
        disabled={disabled}
        variant="filled"
        autoComplete="off"
        error={hasError}
        helperText={errors[field.name] || ''}
        className={hasError ? null : this.props.classes.inputRoot}
        value={valueToDisplay}
        onChange={event => {
          refine(event.currentTarget.value);
          if (!this.isEdited) {
            this.isEdited = true;
            this.billingCustomerSelected = false;
          }
        }}
        onBlur={() =>
          setTimeout(() => {
            if (value !== this.currentRefinement && this.isEdited) {
              this.isEdited = false;
              form.setFieldValue(field.name, this.currentRefinement, false);
            }
          }, 1000)
        }
        {...rest}
      />
    );
  };

  // handleClickAway = () => {};

  render() {
    const { searchClient, searchKey } = this.state;
    const { specialbehaviour, form } = this.props;
    const { searchIndex, filterString } = specialbehaviour;
    const CustomSearchBox = connectSearchBox(this.SearchBox);
    const CustomResults = connectStateResults(this.searchResults);
    const filterCriteria = form.values[filterString];
    let filterStr = '';
    const filterStrArr = [];

    if (specialbehaviour?.showAddress) {
      filterStrArr.push(`entityType:CompanyAddress`);
      filterStrArr.push(`addressType:billingAddress`);
      filterStrArr.push(`parentEntityType:Customer`);
      filterStrArr.push(`parentStatus:${filterCriteria.status}`); // status attribute is missing for some data, parentStatus is available since feb 2019
    } else if (filterCriteria) {
      if (filterCriteria.entityType) {
        filterStrArr.push(`entityType:${filterCriteria.entityType}`);
      }
      if (filterCriteria.status) {
        filterStrArr.push(`status:${filterCriteria.status}`);
      }
    }

    // if (filterCriteria && filterCriteria.priceBookId) {
    //   filterStrArr.push(`priceBookId:${filterCriteria.priceBookId}`);
    // }

    if (!_.isEmpty(filterStrArr) && filterStrArr.length >= 1) {
      filterStr = filterStrArr.join(' AND ');
    }
    if (!searchKey || !searchClient) {
      return <p>Loading...</p>;
    }
    return (
      <InstantSearch indexName={searchIndex || defaultSearchIndex} searchClient={this.searchClient}>
        <CustomSearchBox />
        <div id="alogliaSearchResultsFormik" ref={this.inputRef} />
        <CustomResults />
        {filterStr !== ' AND ' && <Configure filters={filterStr} />}
      </InstantSearch>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AlgoliaSearchDropdown);
