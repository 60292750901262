import React from 'react';
import Grid from '@material-ui/core/Grid';
import Labels from 'meta/labels';
import PropTypes from 'prop-types';
import DefaultButton from '../Buttons/DefaultButton';

const ButtonArray = props => {
  const { dataArr, user, style, selectedValue, handleClick } = props;

  return (
    <Grid item xs={12}>
      {dataArr &&
        dataArr.map((data, index) => {
          const label = Labels[data][user.locale];
          const styles = { ...style };

          if (index !== 0) {
            styles.marginLeft = 10;
          }

          return (
            <DefaultButton
              key={`chip-${data}`}
              color={selectedValue === label ? 'primary' : 'default'}
              label={label}
              style={styles}
              handle={() => handleClick(label)}
            />
          );
        })}
    </Grid>
  );
};

ButtonArray.propTypes = {
  dataArr: PropTypes.array,
  user: PropTypes.object.isRequired,
  style: PropTypes.object,
  selectedValue: PropTypes.string,
  handleClick: PropTypes.func.isRequired
};

ButtonArray.defaultProps = {
  dataArr: [],
  style: {},
  selectedValue: ''
};

export default ButtonArray;
