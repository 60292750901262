import gql from 'graphql-tag';

const deleteSystemEntityMap = gql`
  mutation deleteSystemEntityMap($input: DeleteSystemEntityMapInput!) {
    deleteSystemEntityMap(input: $input) {
      id
    }
  }
`;

export default deleteSystemEntityMap;
