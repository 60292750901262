import React from 'react';
import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles = theme => ({
  checked: {
    '& + $track': {
      backgroundColor: theme.palette.grayscale(94),
      opacity: 1
    }
  },
  colorSecondary: {
    '&$checked + $track': {
      backgroundColor: theme.palette.grayscale(94),
      opacity: 1,
      height: 14
    },
    '&$checked': {
      top: '-2px'
    },
    disabled: {
      '& + $track': {
        backgroundColor: theme.palette.grayscale(94),
        opacity: 1
      }
    }
  },
  dragIconRoot: {
    color: theme.palette.grayscale(60),
    display: 'flex'
  },
  listItemPrimary: {
    color: theme.palette.grayscale(20),
    fontSize: 13,
    fontWeight: 400
  },
  listItemRoot: {
    height: '30px',
    paddingLeft: '8px',
    paddingRight: '2px'
  },
  switchBase: {
    color: theme.palette.grayscale(60)
  },
  track: {
    backgroundColor: theme.palette.grayscale(94),
    opacity: 1
  }
});

const SwitchComponent = props => {
  const { classes, form, options, field } = props;
  const { name, disableFn } = options || {};
  let { disabled } = options || {};
  // used in v2 job reports
  if (disableFn) {
    disabled = disableFn(form.values);
  }
  // when disabled keep in false state
  if (disabled && field.value) {
    form.setFieldValue(name, false);
  }

  return (
    <Switch
      size="small"
      classes={{
        switchBase: classes.switchBase,
        track: classes.track,
        colorSecondary: classes.colorSecondary,
        checked: classes.checked
      }}
      disabled={disabled}
      checked={field?.value || false}
      checkedIcon={<CheckCircleIcon fontSize="small" />}
      onChange={event => {
        if (form) {
          form.setFieldValue(name, event.target.checked);
        }
      }}
      key={`Switch${disabled}`}
    />
  );
};

export default withStyles(styles)(SwitchComponent);
