import { PermissionConstants } from 'utils/AppConstants';

export const timeTableMeta = [
  {
    id: 'visitNumber',
    label: 'Visit'
  },
  {
    id: 'employeeName',
    label: 'Technician'
  },
  {
    id: 'labourType',
    type: 'string',
    label: 'Labor type'
  },
  {
    id: 'description',
    type: 'string',
    label: 'Descripton'
  },
  {
    id: 'timeInHrs',
    type: 'number',
    label: 'Hours',
    numeric: true
  },
  {
    id: 'rate',
    type: 'currency',
    numeric: true,
    caslKey: PermissionConstants.DATA_VIEW_PRICE_DATA,
    caslAction: 'allow',
    label: 'Rate',
    align: 'right'
  }
];

export const productTableMeta = [
  {
    id: 'visitNumber',
    label: 'Visit #'
  },
  {
    id: 'productCode',
    label: 'Product Code'
  },
  {
    id: 'itemName',
    type: 'string',
    label: 'Item Name'
  },
  {
    id: 'description',
    type: 'string',
    label: 'Description'
  },
  {
    id: 'quantity',
    type: 'number',
    label: 'Quantity',
    numeric: true
  },
  {
    id: 'unitCost',
    type: 'currency',
    numeric: true,
    caslKey: PermissionConstants.DATA_VIEW_PRICE_DATA,
    caslAction: 'allow',
    label: 'Unit Cost',
    align: 'right'
  }
];

export const purchasedItemTableMeta = [
  {
    id: 'visitNumber',
    label: 'Visit #'
  },
  {
    id: 'receiptNumber',
    label: 'Receipt number'
  },
  {
    id: 'itemName',
    type: 'string',
    label: 'Item Name'
  },
  {
    id: 'description',
    type: 'string',
    label: 'Description'
  },
  {
    id: 'quantity',
    type: 'number',
    label: 'Quantity',
    numeric: true
  },
  {
    id: 'unitCost',
    type: 'currency',
    numeric: true,
    caslKey: PermissionConstants.DATA_VIEW_PRICE_DATA,
    caslAction: 'allow',
    label: 'Unit Cost',
    align: 'right'
  }
];

export default timeTableMeta;
