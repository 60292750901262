export const smartFieldsDropdownList = [
  'Company Name',
  'Company Phone',
  'Company Address',
  'Company Logo',
  'Current Date',
  'Customer Address',
  'Customer Name',
  'Days until Expiration',
  'Discount',
  'Expiration Date',
  'Labor Subtotal',
  'Line Items',
  'Ordered By',
  'Ordered By Email',
  'Ordered By Phone',
  'Parts and Materials Subtotal',
  'Property Address',
  'Property Billing Customer Address',
  'Property Billing Customer Bill To',
  'Property Billing Customer Name',
  'Property Name',
  'Project Manager',
  'Project Manager Email',
  'Property Rep',
  'Quote Creation Date',
  'Quote Number',
  'Quote Subject',
  'Quote Subtotal',
  'Tasks',
  'Task Groups',
  'Tax Amount',
  'Tax Percentage',
  'Terms and Conditions',
  'Total',
  'Totals and Subtotals'
];

export const smartFields = {
  BILL_TO_ADDRESS: 'BillToAddress',
  COMPANY_ADDRESS: 'CompanyAddress',
  COMPANY_NAME: 'CompanyName',
  COMPANY_LOGO: 'CompanyLogo',
  COMPANY_PHONE: 'CompanyPhone',
  CURRENT_DATE: 'CurrentDate',
  CUSTOMER_ADDRESS: 'CustomerAddress',
  CUSTOMER_NAME: 'CustomerName',
  DAYS_UNTIL_EXPIRATION: 'DaysuntilExpiration',
  DISCOUNT: 'Discount',
  EXPIRATION_DATE: 'ExpirationDate',
  LABOR_SUBTOTAL: 'LaborSubtotal',
  LINE_ITEMS: 'LineItems',
  PARTS_AND_MATERIALS: 'PartsandMaterialsSubtotal',
  PROJECT_MANAGER: 'ProjectManager',
  PROJECT_MANAGER_EMAIL: 'ProjectManagerEmail',
  PROPERTY_NAME: 'PropertyName',
  PROPERTY_ADDRESS: 'PropertyAddress',
  PROPERTY_BILLING_CUSTOMER_ADDRESS: 'PropertyBillingCustomerAddress',
  PROPERTY_BILLING_CUSTOMER_BILL_TO: 'PropertyBillingCustomerBillTo',
  PROPERTY_BILLING_CUSTOMER_NAME: 'PropertyBillingCustomerName',
  PROPERTY_REP: 'PropertyRep',
  QUOTE_NUMBER: 'QuoteNumber',
  QUOTE_SUBJECT: 'QuoteSubject',
  QUOTE_CREATION_DATE: 'QuoteCreationDate',
  QUOTE_ORDERED_BY: 'OrderedBy',
  QUOTE_ORDERED_BY_EMAIL: 'OrderedByEmail',
  QUOTE_ORDERED_BY_PHONE: 'OrderedByPhone',
  QUOTE_SUBTOTAL: 'QuoteSubtotal',
  REMOVE_ITEM: 'RemoveItem',
  TASKS: 'Tasks',
  TASK_GROUPS: 'TaskGroups',
  TAX_AMOUNT: 'TaxAmount',
  TAX_PERCENTAGE: 'TaxPercentage',
  TERMS_AND_CONDITIONS: 'TermsandConditions',
  TOTAL: 'Total',
  TOTALS_AND_SUBTOTALS: 'TotalsandSubtotals'
};

export const smartFieldsMap = {
  BILL_TO_ADDRESS: 'billTo',
  COMPANY_ADDRESS: 'companyAddress',
  COMPANY_LOGO: 'logoUrl',
  COMPANY_PHONE: 'phonePrimary',
  COMPANY_NAME: 'companyName',
  DISCOUNT: 'discount',
  QUOTE_SUBJECT: 'name',
  SUBTOTAL: 'subTotal',
  SUBTOTAL_OVERRIDE: 'subTotalOverride',
  TAX: 'taxRateValue',
  TAX_AMOUNT: 'totalTaxAmount',
  TAX_AMOUNT_OVERRRIDE: 'totalTaxAmountOverride',
  TERMS_AND_CONDITIONS: 'termsOfService',
  TOTAL: 'totalAmountQuoted',
  TOTAL_OVERRIDE: 'totalAmountQuotedOverride'
};
