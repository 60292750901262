import gql from 'graphql-tag';

const updateProduct = gql`
  mutation updateProduct($partitionKey: String, $data: UpdateProductAndRelatedInput!) {
    updateProduct(partitionKey: $partitionKey, data: $data) {
      id
      name
      description
      isActive
      createdDate
      version
    }
  }
`;

export default updateProduct;
