import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { getContentText } from 'scenes/ProjectManagement/components/utils';

import styles from '../style';

const ContinuationSheetTableGrandTotal = ({ totals }) => {
  return (
    <View style={styles.displayFlex}>
      <Text style={[styles.text, styles.borderDoubleTop, { width: '5%' }]} />
      <Text
        style={[
          styles.text,
          styles.borderDoubleTop,
          styles.textCenter,
          { width: '27%', fontFamily: 'Times-Bold' }
        ]}
      >
        GRAND TOTALS
      </Text>
      <Text style={[styles.text, styles.borderDoubleTop, styles.numberAlign, { width: '12%' }]}>
        {/* Contract value */}
        {getContentText('currency', totals.contractValue, 2)}
      </Text>
      <Text style={[styles.text, styles.borderDoubleTop, styles.numberAlign, { width: '8%' }]}>
        {getContentText('currency', totals.prevAmountCompleted, 2)}
      </Text>
      <Text style={[styles.text, styles.borderDoubleTop, styles.numberAlign, { width: '8%' }]}>
        {/* This period */}
        {getContentText('currency', totals.workCompleted, 2)}
      </Text>
      <Text style={[styles.text, styles.borderDoubleTop, styles.numberAlign, { width: '10%' }]}>
        {/* Materials presently */}
        {getContentText('currency', totals.materialsStored, 2)}
      </Text>
      <Text style={[styles.text, styles.borderDoubleTop, styles.numberAlign, { width: '10%' }]}>
        {/* total completed */}
        {getContentText('currency', totals.totalCompletedAndStoredToDate, 2)}
      </Text>
      <Text style={[styles.text, styles.borderDoubleTop, styles.numberAlign, { width: '8%' }]}>
        {/* total completed percentage */}
        {getContentText(
          'percentage',
          (100 * totals.totalCompletedAndStoredToDate) / totals.contractValue,
          2
        )}
      </Text>
      <Text
        style={[
          styles.text,
          styles.borderDoubleTop,
          styles.numberAlign,
          { width: '12%', borderRight: 0 }
        ]}
      >
        {getContentText('currency', totals.contractValue - totals.totalCompletedAndStoredToDate, 2)}
      </Text>
    </View>
  );
};

ContinuationSheetTableGrandTotal.propTypes = {
  totals: PropTypes.object.isRequired
};

export default ContinuationSheetTableGrandTotal;
