import React from 'react';
import { bool, object } from 'prop-types';
import { Typography } from '@buildhero/sergeant';

import { VISIT_DETAILS_PROP } from '@dispatch/queries';

import { useStyles } from '../../VisitDetailsForm.styles';

const PropertyAddress = ({ visit, loading, job }) => {
  const classes = useStyles();
  const address = visit?.property?.address?.addressLine1
    ? visit.property.address
    : job?.property?.companyAddresses?.items?.find(x => x.addressType === 'propertyAddress');

  return (
    <>
      <Typography classes={{ root: classes.oneLineText }} loading={loading} variant="body2">
        {address?.addressLine1}
      </Typography>
      {address?.addressLine2 && (
        <Typography classes={{ root: classes.oneLineText }} loading={loading} variant="body2">
          {address?.addressLine2}
        </Typography>
      )}
      <Typography classes={{ root: classes.oneLineText }} loading={loading} variant="body2">
        {address?.city}
        {address?.city ? ',' : ''} {address?.state} {address?.zipcode}
      </Typography>
    </>
  );
};

PropertyAddress.propTypes = {
  visit: VISIT_DETAILS_PROP.isRequired,
  loading: bool.isRequired,
  job: object.isRequired
};

export default PropertyAddress;
