import gql from 'graphql-tag';

const batchUpdateQuoteLineProducts = gql`
  mutation batchUpdateQuoteLineProducts(
    $partitionKey: String!
    $data: [UpdateQuoteLineProductInput]!
  ) {
    batchUpdateQuoteLineProducts(partitionKey: $partitionKey, data: $data) {
      id
      entityType
      version
      name
      description
      unitPrice
      unitCost
      markupType
      markupValue
      quantity
      taxable
    }
  }
`;

export default batchUpdateQuoteLineProducts;
