export const addressFragment = `
    billTo
    addressType
    addressLine1
    addressLine2
    city
    state
    zipcode
    country
    version
`;

export const attachmentFragment = `
    customFileName
    fileName
    fileUrl
    fileSize
    description
    comment
    type
    parentId
    parentSortKey
    hierarchy
    id
    version
    partitionKey
    sortKey
    createdDate
    createdDateTime
    lastUpdatedDateTime
    lastUpdatedBy
    createdBy
    hideFromTechniciansOnMobile
    isInternalFile
`;

export const quoteJobsFragment = `
  id
  job (entityConnection: "Job") {
    id
    customIdentifier
    jobNumber
    issueDescription
    status
    version
  }
`;

export const quoteFragment = `
      id
      version
      versionLabel
      versionNumber
      orderedById
      orderedBy {
        name
        bestContact
        cellPhone
        landlinePhone
        email
        company {
          companyName
          phonePrimary
        }
      }
      customerSignatures(entityConnection: "CustomerSignature") {
        items {
          id
          nameOfSignee
          createdDate
        }
      }
      propertyRepId
      propertyRep {
        name
      }
      priceBookId
      serviceAgreementId
      detailsShownToCustomer
      hideTotalAmount
      name
      quoteNumber
      dateSent
      accountManagerId
      accountManagerValue
      customIdentifier
      createdDate
      createdFromMobile
      description
      dueDate
      issueDescription
      billTo
      departmentId
      departmentValue
      expirationDate
      expirationLength
      parentId
      status
      jobTypeId
      jobTypeValue
      ownerId
      ownerValue
      salesById
      salesByValue
      taxRateId
      taxRateValue
      customerPoNumber
      isActive
      discount
      termsOfService
      rejectedReason
      lastSubmissionDate
      lastUpdatedDateTime
      lastUpdatedBy
      pdfUrl
      totalAmountQuoted
      totalAmountQuotedOverride
      totalEstimatedCost
      subTotalOverride
      subTotalAmountTaxableOverride
      totalTaxAmountOverride
      subTotalAmountTaxable
      subTotalAmountNonTaxable
      totalTaxAmount
      taskSubTotal
      subTotal
      settingsJSON
      owner(entityConnection: "owner") {
        id
        accountingRefId
        name
        email
        cellPhone
        landlinePhone
      }
      department(entityConnection: "Department") {
        id
        tagName
        logoUrl
        phonePrimary
        email
        accountingRefIdOfClass
        companyAddresses {
          items {
            ${addressFragment}
          }
        }
      }
    
`;

export const customerFragment = `
    id
    customerName
    companyAddresses(entityConnection: "CompanyAddress") {
        items {
            ${addressFragment}
        }
    }
`;

export const propertyFragement = `
id
companyName
billingCustomer(entityConnection: "Customer") {
  ${customerFragment}
}
companyAddresses: companyAddresses(entityConnection: "CompanyAddress") {
  items {
    ${addressFragment}
  }
}
customer: parentEntity {
    ... on Customer {
        ${customerFragment}
    }
}
`;

export const quoteTagsFragment = `
quoteQuoteTags(entityConnection: "QuoteQuoteTag") {
  items {
    id
    entityType
    quoteTag(entityConnection: "QuoteTag"){
      id
      entityType
      tagName
    }
  }
}
`;

export const quotePurchaseOrdersFragment = `
quotePurchaseOrders(entityConnection: "QuotePurchaseOrders") {
  items {
    id
    purchaseOrder(entityConnection: "PurchaseOrder") {
      id
      poNumber
      status
    }
  }
}
`;

export const customFormFragment = `
formData(entityConnection: "FormData") {
  items {
    id
    version
    Custom_text1: text1
    Custom_text2: text2
    Custom_text3: text3
    Custom_text4: text4
    Custom_text5: text5
    Custom_text6: text6
    Custom_text7: text7
    Custom_text8: text8
    Custom_text9: text9
    Custom_text10: text10
    Custom_text11: text11
    Custom_text12: text12
    Custom_text13: text13
    Custom_text14: text14
    Custom_text15: text15
    Custom_longText1: longText1
    Custom_longText2: longText2
    Custom_longText3: longText3
    Custom_longText4: longText4
    Custom_longText5: longText5
    Custom_longText6: longText6
    Custom_longText7: longText7
    Custom_longText8: longText8
    Custom_longText9: longText9
    Custom_longText10: longText10
    Custom_number1: number1
    Custom_number2: number2
    Custom_number3: number3
    Custom_number4: number4
    Custom_number5: number5
    Custom_number6: number6
    Custom_number7: number7
    Custom_number8: number8
    Custom_number9: number9
    Custom_number10: number10
    Custom_number11: number11
    Custom_number12: number12
    Custom_number13: number13
    Custom_number14: number14
    Custom_number15: number15
    Custom_pickList1: pickList1
    Custom_pickList2: pickList2
    Custom_pickList3: pickList3
    Custom_pickList4: pickList4
    Custom_pickList5: pickList5
    Custom_pickList6: pickList6
    Custom_pickList7: pickList7
    Custom_pickList8: pickList8
    Custom_pickList9: pickList9
    Custom_pickList10: pickList10
    Custom_pickList11: pickList11
    Custom_pickList12: pickList12
    Custom_pickList13: pickList13
    Custom_pickList14: pickList14
    Custom_pickList15: pickList15
    Custom_lookup1: lookup1
    Custom_lookup2: lookup2
    Custom_lookup3: lookup3
    Custom_lookup4: lookup4
    Custom_lookup5: lookup5
    Custom_lookup6: lookup6
    Custom_lookup7: lookup7
    Custom_lookup8: lookup8
    Custom_lookup9: lookup9
    Custom_lookup10: lookup10
    Custom_date1: date1
    Custom_date2: date2
    Custom_date3: date3
    Custom_date4: date4
    Custom_date5: date5
    Custom_date6: date6
    Custom_date7: date7
    Custom_date8: date8
    Custom_date9: date9
    Custom_date10: date10
    Custom_richText1: richText1
    Custom_richText2: richText2
    Custom_richText3: richText3
    Custom_richText4: richText4
    Custom_richText5: richText5
    Custom_checkBox1: checkBox1
    Custom_checkBox2: checkBox2
    Custom_checkBox3: checkBox3
    Custom_checkBox4: checkBox4
    Custom_checkBox5: checkBox5
    Custom_checkBox6: checkBox6
    Custom_checkBox7: checkBox7
    Custom_checkBox8: checkBox8
    Custom_checkBox9: checkBox9
    Custom_checkBox10: checkBox10
    form(entityConnection: "form") {
      id
      name
      description
      viewType
    }
  }
}`;

export default quoteFragment;
