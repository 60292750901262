import gql from 'graphql-tag';

const listTenantUsers = gql`
  query listTenantUsers($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      companyName
      employees: employeesView(entityConnection: "Employee") {
        items {
          name
          isAdmin
          status
          logins(entityConnection: "Login") {
            items {
              isAdmin
              userName
              preferred_username
            }
          }
        }
      }
    }
  }
`;

export default listTenantUsers;
