const getSearchIndex = () => {
  if (process.env.REACT_APP_env === 'staging') return `bh-staging-searchIndex`;
  return 'bh-searchIndex';
};

const getProductIndex = () => {
  if (process.env.REACT_APP_env === 'staging') return `bh-staging-productIndex`;
  return 'bh-productIndex';
};

module.exports = {
  searchIndex: getSearchIndex(),
  bundleIndex: getProductIndex()
};
