import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  time: {
    minWidth: '120px',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '20px',
    textAlign: 'center',
    color: theme.palette.grayscale(10),
    '&:hover': {
      background: theme.palette.grayscale(92)
    }
  },
  overlap: {
    marginLeft: theme.spacing(0.5),
    borderRadius: 4,
    border: `1px solid ${theme.palette.error.main}`
  },
  timeInput: {
    margin: '0 !important',
    '& button': {
      display: 'none'
    }
  },
  timeInputFocused: {
    background: theme.palette.grayscale(92)
  },
  timeInputRoot: {
    '&:hover': {
      background: theme.palette.grayscale(92)
    },
    '& input': {
      textTransform: 'uppercase'
    },
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  disabled: {
    '&:hover': {
      background: 'transparent'
    },
    '& input': {
      color: theme.palette.grayscale(30)
    }
  },
  timeDetails: {
    marginTop: theme.spacing(1),
    color: theme.palette.grayscale(30)
  }
}));
