import * as Yup from 'yup';

const quantity = Yup.number().required('Quantity is required');

export const truckTemplateItem = Yup.object().shape({
  quantity,
  minQuantity: Yup.number().required('Minimum quantity is required'),
  maxQuantity: Yup.number().required('Maximum quantity is required')
});

export const taskTemplateItem = Yup.object().shape({
  quantity
});

export default truckTemplateItem;
