import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { theme } from '@buildhero/sergeant';
import React from 'react';

// TODO (10 July 2020): Once this theme matures further and more dimension elements
// are added, we will need to figure out a way to organize dimension data
// within the theme object. The best solution is probably to use mixins
// (https://material-ui.com/customization/default-theme/),
export const MAX_FORMS_WIDTH = 960;
export const MAX_DEFAULT_TABLE_HEIGHT = 350;

// NOTE: All other fields should not be exported. They should be accessed only
// through the theme object to preserve extensibility and modularity.
const brand = {
  logoGreen: '#2dce89',
  logoBlue: '#17294d',
  green: '#00874d',
  greenFocus: '#00663a',
  greenHover: '#004d2c',
  background: '#ffffff',
  secondaryBackground: '#f0f0f0',
  sectionHint: '#808080'
};

const error = {
  main: '#990000',
  focus: '#660000',
  hover: '#660000'
};

// Return value is padded with a leading zero if necessary so each channel width is always 2.
function percentToHexString256(percent) {
  const HEX_MAX = 2 ** 8 - 1; // 0 to 255
  let sanitizedPercent = Math.max(0, Math.min(parseFloat(percent), 100));
  if (Number.isNaN(sanitizedPercent)) sanitizedPercent = 0;
  const mappedTo256 = Math.ceil((sanitizedPercent * HEX_MAX) / 100);
  const hex256 = mappedTo256.toString(16);
  return hex256.length === 1 ? `0${hex256}` : hex256;
}

function grayscale(channelBrightnessPercent, alphaPercent = 100) {
  const channelBrightnessHexString = percentToHexString256(channelBrightnessPercent);
  const alphaHexString = percentToHexString256(alphaPercent);
  return `#${channelBrightnessHexString}${channelBrightnessHexString}${channelBrightnessHexString}${alphaHexString}`;
}

// function to be used for pdf rendering
export function grayscalePdf(channelBrightnessPercent, alphaPercent) {
  return grayscale(channelBrightnessPercent, alphaPercent).slice(0, 7);
}

// Sergeant Design System
const defaultTheme = createMuiTheme();
export const buildHeroTheme = createMuiTheme({
  mixins: {
    main: {
      padding: defaultTheme.spacing(3)
    }
  },
  palette: {
    // Default palette fields (present in out-of-the-box Material UI lib)
    primary: {
      main: brand.logoBlue,
      contrastText: grayscale(100)
    },
    secondary: {
      main: brand.green,
      contrastText: grayscale(100)
    },
    error: { ...error },
    text: {
      primary: grayscale(20),
      secondary: brand.green,
      dark: '#333333'
    },
    // Resolve contrastThreshold and tonalOffset
    contrastThreshold: 3,
    tonalOffset: 0.2,
    background: {
      default: brand.background
    },
    // Custom (non-default) palette fields
    grayscale,
    brand: { ...brand },
    support: theme.palette.support,
    other: {
      darkBlue: '#0c1527',
      secondaryCyan: '#13809e',
      scarletRed: '#b00020',
      logoGreen: '#31c67e',
      yellow: '#fffde5',
      aeroBlue: '#bfe1d3',
      cosmos: '#ffd3d3',
      genoa: '#1b7b52',
      cosmicLatte: '#f9fefc',
      luckyPoint: '#333456',
      ghostWhite: '#fafaff',
      navyBlue: '#465471',
      palePink: '#FFF3F7',
      palePurple: '#FBF3FF',
      mintCream01: '#f2fffa',
      mintCream02: '#e6fff4',
      mayaBlue: '#F3F7FE',
      gold: '#B99A00',
      statusRed: '#E8AAAA',
      statusBlue: '#C9D8FF',
      statusGreen: '#BAE4B6',
      statusOrange: '#F0CEAF',
      statusYellow: '#FFDC00',
      statusLightBlue: '#B3CDFF',
      statusLightGreen: '#B3FFDE',
      statusLightRed: '#FFB3B3',
      statusLightYellow: '#FFE0B3',
      greyScale: '#333333',
      lightOrange: '#FFF7EB',
      lightGreen: '#EBFFF6',
      lightGray: '#FAFAFA',
      dimGray: '#E6E6E6',
      blue1Dark: '#003399',
      blue1Light: '#EBF1FF',
      supportYellow1: '#FFFCEB'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['Inter', 'sans-serif'].join(','),
    fontSize: 14,
    lineHeight: 1.4,
    h2: {
      fontSize: 24,
      fontWeight: 700,
      color: brand.green,
      letterSpacing: -1
    },
    h3: {
      fontSize: 24,
      color: grayscale(20),
      letterSpacing: -1,
      fontWeight: 700
    },
    h4: {
      fontSize: 20,
      color: grayscale(20),
      letterSpacing: -1,
      fontWeight: 700
    },
    h5: {
      color: grayscale(20),
      fontSize: 24,
      letterSpacing: -1,
      fontWeight: 500
    },
    h6: {
      color: grayscale(20),
      fontSize: 16,
      letterSpacing: -1,
      fontWeight: 700
    },
    subtitle1: {
      color: grayscale(60),
      fontSize: 18,
      letterSpacing: -0.014,
      fontWeight: 500
    },
    subtitle2: {
      color: grayscale(20),
      fontSize: 16,
      letterSpacing: -0.011,
      fontWeight: 500
    },
    body1: {
      color: grayscale(20),
      fontSize: 16,
      letterSpacing: -0.011,
      fontWeight: 500
    },
    body2: {
      color: grayscale(20),
      fontSize: 14,
      letterSpacing: -0.006,
      fontWeight: 500
    },
    button: {
      color: grayscale(100),
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: -0.006,
      textTransform: 'uppercase'
    },
    caption: {
      fontSize: 12,
      letterSpacing: 0,
      fontWeight: 500,
      lineHeight: 'normal',
      color: grayscale(20)
    },
    overline: {
      color: grayscale(60),
      fontSize: 10,
      fontWeight: 'normal',
      letterSpacing: 0.01,
      lineHeight: '14px'
    }
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none',
        backgroundColor: brand.background
      },
      colorPrimary: {
        backgroundColor: brand.background
      },
      positionFixed: {
        backgroundColor: brand.background
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none'
      }
    },
    MuiToolbar: {
      root: {
        color: grayscale(20)
      }
    },
    MuiButton: {
      root: {
        textTransform: 'unset'
      },
      containedPrimary: {
        backgroundColor: brand.green,
        borderRadius: 4,
        border: 0,
        color: grayscale(100),
        boxShadow: 'none',
        textTransform: 'uppercase',
        '&:disabled': {
          opacity: 0.5,
          color: grayscale(100)
        },
        '&:focus': {
          backgroundColor: brand.greenFocus
        },
        '&:hover': {
          backgroundColor: brand.greenHover
        }
      },
      containedSecondary: {
        backgroundColor: grayscale(20),
        borderRadius: 4,
        color: grayscale(100),
        boxShadow: 'none',
        textTransform: 'uppercase',
        '&:disabled': {
          color: grayscale(40)
        },
        '&:focus': {
          backgroundColor: grayscale(10)
        },
        '&:hover': {
          backgroundColor: grayscale(0)
        }
      },
      outlinedSecondary: {
        borderRadius: 4,
        borderColor: grayscale(20),
        color: grayscale(20),
        boxShadow: 'none',
        textTransform: 'uppercase',
        '&:disabled': {
          color: grayscale(80)
        },
        '&:focus': {
          borderColor: grayscale(10)
        },
        '&:hover': {
          borderColor: grayscale(0)
        }
      },
      text: {
        color: grayscale(20),
        border: 'none',
        backgroundColor: 'inherit',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.5,
        textDecoration: 'underline',
        letterSpacing: '-0.011px',
        padding: 0,
        '&:hover': {
          border: 'none',
          backgroundColor: 'inherit'
        },
        '&:disabled': {
          border: 'none',
          backgroundColor: 'inherit'
        }
      },
      textPrimary: {
        fontWeight: 'bold',
        '&:hover': {
          textDecoration: 'underline'
        },
        '&:disabled': {
          textDecoration: 'underline'
        }
      }
    },
    MuiCard: {
      root: {
        borderRadius: 4,
        backgroundColor: grayscale(90)
      }
    },
    MuiChip: {
      root: {
        color: grayscale(0),
        fontWeight: 400,
        backgroundColor: grayscale(85)
      },
      label: {
        paddingLeft: 16,
        paddingRight: 16,
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 14
      }
    },
    MuiInputLabel: {
      filled: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 16,
        color: grayscale(40),
        '&$shrink': {
          color: brand.green,
          marginTop: -2,
          marginLeft: -2
        }
      },
      outlined: {
        color: grayscale(60)
      }
    },
    MuiInputBase: {
      root: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontWeight: 'normal',
        fontSize: 16,
        color: grayscale(20),
        height: 'fit-content'
      },
      input: {
        height: 'fit-content'
      },
      colorSecondary: {
        '& .MuiInputBase-input': {
          padding: '1px 8px'
        },
        background: grayscale(94),
        '&.MuiInput-underline input': {
          padding: '11px 8px'
        },
        '&.MuiInput-underline:before': {
          border: 'none'
        }
      }
    },
    MuiFormHelperText: {
      root: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 12,
        color: grayscale(60),
        '&$error': {
          color: error.main,
          marginLeft: 0
        }
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: grayscale(98),
        '&$error': {
          borderBottomColor: error.main
        }
      },
      input: {
        borderRadius: '4px',
        padding: '25px 12px 5px'
      },
      underline: {
        '&:after': {
          borderBottomColor: brand.green
        },
        // TODO: redo the fix
        '&&&&:hover:before': {
          borderBottom: `1px solid ${brand.green}`
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: grayscale(100),
        borderColor: grayscale(80),
        borderRadius: 0,
        '&$focused $notchedOutline': {
          borderColor: grayscale(30)
        },
        '&$error': {
          borderBottomColor: error.main
        },
        '&$disabled': {
          backgroundColor: grayscale(94)
        }
      },
      input: {
        padding: 8,
        fontSize: 14,
        color: grayscale(20),
        borderColor: grayscale(80)
      },
      multiline: {
        padding: 8,
        fontSize: 14,
        color: grayscale(20),
        borderColor: grayscale(80)
      }
    },
    MuiFormLabel: {
      root: {
        color: brand.green
      }
    },
    MuiListItemText: {
      root: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: -0.006
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 14
      }
    },
    MuiTableCell: {
      root: {
        paddingLeft: 5,
        paddingRight: 5
      },
      head: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 12,
        fontWeight: 700,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 0,
        color: grayscale(20)
      },
      body: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 14,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: -0.006,
        color: grayscale(20)
      }
    },
    MuiTableRow: {
      root: {
        height: 46,
        borderTop: '1px solid',
        borderColor: grayscale(90),
        '&$hover:hover': {
          backgroundColor: grayscale(90)
        }
      },
      hover: {
        backgroundColor: grayscale(100),
        borderColor: grayscale(90)
      }
    },
    MuiTablePagination: {
      root: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        backgroundColor: grayscale(100),
        fontSize: 12,
        color: grayscale(20)
      }
    },
    MuiTableSortLabel: {
      active: {
        color: grayscale(20)
      }
    },
    MuiCheckbox: {
      root: {
        color: grayscale(40)
      }
    },
    MuiFormControlLabel: {
      label: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 14,
        fontWeight: 500,
        fontStyle: 'normal',
        letterSpacing: -0.006,
        color: grayscale(40)
      }
    },
    MuiDialogContent: {
      root: {
        padding: '0 24px 24px',
        minWidth: 496
      }
    },
    MuiStepper: {
      root: {
        padding: 10
      }
    },
    MuiStepIcon: {
      root: {
        color: grayscale(80),
        '&$active': {
          color: brand.logoGreen
        }
      }
    },
    MuiStepLabel: {
      label: {
        color: grayscale(60)
      }
    }
  }
});

export const BuildHeroThemeProvider = ({ children }) => (
  <ThemeProvider theme={buildHeroTheme}>{children}</ThemeProvider>
);

export const withBuildHeroTheme = Component => props => (
  <BuildHeroThemeProvider>
    <Component {...props} />
  </BuildHeroThemeProvider>
);

export default buildHeroTheme;
