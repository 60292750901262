import { PermissionConstants } from 'utils/AppConstants';

const partMaterialLayout = {
  entity: {
    name: 'inventory',
    tenant: 'default',
    layouts: {
      web: {
        sections: [
          {
            title: 'Edit line item',
            sm: '12',
            md: '12',
            lg: '12',
            rows: [
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbField',
                    value: 'image',
                    label: 'name',
                    name: 'image',
                    behavior: {
                      new: {
                        ui: 'ImageArrayDisplay',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      },
                      edit: {
                        ui: 'ImageArrayDisplay',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'itemName',
                    label: 'name',
                    name: 'itemName',
                    behavior: {
                      new: {
                        ui: 'ReadOnly',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      },
                      edit: {
                        ui: 'ReadOnly',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'departmentName',
                    label: 'departmentName',
                    name: 'departmentName',
                    behavior: {
                      new: {
                        ui: 'ReadOnly',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'ReadOnly',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'taxable',
                    label: 'taxable',
                    name: 'taxable',
                    behavior: {
                      new: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '2',
                        lg: '2'
                      },
                      edit: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '2',
                        lg: '2'
                      }
                    }
                  }
                ]
              },
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbField',
                    value: 'description',
                    label: 'inventoryDescription',
                    name: 'description',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      }
                    }
                  },

                  {
                    type: 'dbField',
                    value: 'quantity',
                    label: 'inventoryQuantity',
                    name: 'quantity',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '3'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '3'
                      }
                    }
                  }
                ]
              },
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbField',
                    value: 'unitPrice',
                    label: 'inventoryUnitPrice',
                    name: 'unitPrice',
                    hideField: 'isPriceBookEnabled',
                    specialBehaviour: {
                      applyTierPricing: true,
                      unitCostKey: 'unitCost',
                      unitPriceKey: 'unitPrice',
                      markupKey: 'markup'
                    },
                    behavior: {
                      new: {
                        ui: 'CostMarkup',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      },
                      edit: {
                        ui: 'CostMarkup',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      }
                    }
                  }
                ]
              },
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbField',
                    value: 'costCodeId',
                    label: 'Cost Codes',
                    name: 'costCodeId',
                    specialBehaviour: {
                      includeQueryResult: true,
                      queryPath: 'costCodes',
                      fieldName: 'name',
                      fieldValue: 'id'
                    },
                    behavior: {
                      new: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '6',
                        lg: '3'
                      },
                      edit: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '6',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'jobCostTypeId',
                    label: 'Cost Type',
                    name: 'jobCostTypeId',
                    specialBehaviour: {
                      includeQueryResult: true,
                      queryPath: 'costTypes',
                      fieldName: 'name',
                      fieldValue: 'id'
                    },
                    behavior: {
                      new: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '6',
                        lg: '3'
                      },
                      edit: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '6',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'revenueTypeId',
                    label: 'Revenue Type',
                    name: 'revenueTypeId',
                    specialBehaviour: {
                      includeQueryResult: true,
                      queryPath: 'revenueTypes',
                      fieldName: 'name',
                      fieldValue: 'id'
                    },
                    behavior: {
                      new: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '6',
                        lg: '3'
                      },
                      edit: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '6',
                        lg: '3'
                      }
                    }
                  }
                ]
              },
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbField',
                    value: 'unitPrice',
                    label: 'inventoryUnitPrice',
                    name: 'unitPrice',
                    showField: 'isPriceBookEnabled',
                    specialBehaviour: {
                      applyTierPricing: true,
                      unitCostKey: 'unitCost',
                      unitPriceKey: 'unitPrice',
                      markupKey: 'markup',
                      customDecimals: true
                    },
                    behavior: {
                      new: {
                        ui: 'CostMarkup',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      },
                      edit: {
                        ui: 'CostMarkup',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      }
                    }
                  }
                ]
              }
            ]
          }
        ],
        buttons: {
          save: {
            label: 'Save',
            color: 'primary',
            buttonType: 'contained',
            caslKey: [PermissionConstants.OBJECT_REVIEWREPORT],
            behavior: {
              new: true,
              edit: true
            }
          },
          cancel: {
            label: 'Cancel',
            color: 'secondary',
            buttonType: 'contained',
            caslKey: [PermissionConstants.OBJECT_REVIEWREPORT],
            behavior: {
              new: true,
              edit: true
            }
          }
        }
      }
    }
  }
};

export default partMaterialLayout;
