import gql from 'graphql-tag';

const getProducts = gql`
  query getProducts($id: String!) {
    getProductById(id: $id) {
      id
      version
      entityType
      name
      description
      isActive
      taxable
      unitCost
      createdDate
      version
      unitOfMeasureId
      costCodeId
      costCode(entityConnection: "CostCode") {
        id
        name
        description
      }
      jobCostTypeId
      jobCostType(entityConnection: "jobCostType") {
        id
        name
        type
      }
      revenueTypeId
      revenueType(entityConnection: "revenueType") {
        id
        name
        type
      }
      unitOfMeasure(entityConnection: "UnitOfMeasure") {
        id
        name
        unitOfMeasureType
        unitOfMeasureCategory
      }
      productVendors(entityConnection: "ProductVendor") {
        items {
          id
          vendor(entityConnection: "Vendor") {
            id
            name
          }
        }
      }
    }
  }
`;

export default getProducts;
