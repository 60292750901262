import gql from 'graphql-tag';

const deleteInvoiceTag = gql`
  mutation deleteInvoiceTag($partitionKey: String!, $id: String!) {
    softDeleteInvoiceTag(partitionKey: $partitionKey, id: $id) {
      id
      tagName
    }
  }
`;

export default deleteInvoiceTag;
