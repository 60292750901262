export const useRootStyles = ({ boxSizing }) => {
  return {
    root: {
      width: '100%',
      '& *': {
        boxSizing
      }
    }
  };
};
