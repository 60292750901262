import React, { Component } from 'react';
import * as R from 'ramda';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { JobService } from 'services/core';
import styles from './styles';

/**
 * Materail UI text input with BuildHero styles
 */
class MuiTextInput extends Component {
  constructor(props) {
    super(props);
    this.JobService = new JobService();
  }

  state = {
    overrideLabelShrink: '',
    showConfirmDialog: false
  };

  componentDidMount() {
    // Issue: chromeautofilled values doesnot shrink label in text input.
    // To override chrome autofill shrink issue
    setTimeout(() => {
      const autofilledFields = document.querySelectorAll('input:-webkit-autofill');
      if (autofilledFields.length > 0) {
        const fieldNames = R.pluck('name', autofilledFields);
        this.setState({ overrideLabelShrink: fieldNames });
      }
    }, 300);
  }

  hideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  handleOnChange = event => {
    const { field, form, specialbehaviour } = this.props;
    const currentValue =
      specialbehaviour.fieldType === 'number' ? +event.target.value : event.target.value;
    const { dependentField, dependentValue } = specialbehaviour;
    if (specialbehaviour && dependentField && form?.values[dependentField] === 'true') {
      const newValues = {
        ...form.values,
        [field.name]: currentValue,
        [dependentValue]: currentValue
      };
      form.setValues(newValues);
    } else if (form && field) {
      form.setFieldValue(field.name, currentValue, false);
    }
  };

  handleOnBlur = async event => {
    const currentValue = event.target.value;
    const { specialbehaviour = {}, form, field } = this.props;
    const { validation, queryResult } = specialbehaviour;

    if (!validation) {
      return;
    }

    if (validation && queryResult) {
      const validationFn = queryResult[validation];
      if (!validationFn) {
        return;
      }
      const validationResult = await validationFn(currentValue);
      if (form && field) {
        form.setFieldError(field.name, validationResult);
      }
    }
  };

  render() {
    const { field, form, classes, specialbehaviour = {}, user, theme, ...rest } = this.props;
    const {
      queryResult,
      queryPath,
      dependentFieldName,
      dependentQueryPath,
      dependentReferenceKey,
      disableFieldWhen,
      disableFieldValue,
      dependentField,
      hideFieldOnCondition
    } = specialbehaviour;

    if (
      hideFieldOnCondition &&
      dependentField &&
      form &&
      (form.values[dependentField] === '' || form.values[dependentField] === 'false')
    ) {
      return <></>;
    }

    // for getting values from a dependent field selection and based on selecetion populate value from queryResult
    if (
      dependentQueryPath &&
      dependentFieldName &&
      dependentReferenceKey &&
      form?.values[dependentFieldName]
    ) {
      let localResult = queryResult;
      let displayDataValue = null;
      let dependentArray = null;
      const localDependentQueryPathSplit = dependentQueryPath.split('.');

      localDependentQueryPathSplit.forEach(element => {
        if (dependentArray === null) dependentArray = queryResult[element];
        else dependentArray = dependentArray[element];
      });
      localResult =
        dependentArray &&
        dependentArray.filter(
          item => item[dependentReferenceKey] === form?.values[dependentFieldName]
        );

      // Choosing only the first element in the array after filtering
      if (localResult) {
        [localResult] = localResult;
      }

      if (localResult) {
        const fieldPath = queryPath.split('.');
        fieldPath.forEach(element => {
          if (displayDataValue === null) displayDataValue = localResult[element];
          else displayDataValue = displayDataValue[element];
        });

        if (
          field &&
          field.value !== displayDataValue &&
          (field.value === '' || !specialbehaviour.allowEdit)
        ) {
          form.setFieldValue(field.name, displayDataValue, false);
        }
      }
    }

    const helperText = (form?.errors && form.errors[field.name]) || ' ';

    let isReadOnly = false;

    if (
      disableFieldWhen &&
      disableFieldValue &&
      form &&
      form.values[disableFieldWhen] &&
      disableFieldValue.includes(form.values[disableFieldWhen].toLowerCase())
    ) {
      isReadOnly = true;
    }

    return (
      <TextField
        variant="filled"
        error={Boolean(helperText?.trim())}
        helperText={helperText}
        label={this.props.label}
        fullWidth
        className={classes.textField}
        name={field?.name}
        value={field?.value || this.props.value}
        onChange={this.handleOnChange}
        onBlur={this.handleOnBlur}
        disabled={isReadOnly || this.props.disabled}
        {...rest}
        InputLabelProps={
          (this.state.overrideLabelShrink &&
            this.state.overrideLabelShrink.includes(field?.name)) ||
          field?.value
            ? { shrink: true }
            : null
        }
      />
    );
  }
}

export default withStyles(styles)(MuiTextInput);
