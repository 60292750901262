import React, { useState } from 'react';
import { XGrid } from 'components';
import PropTypes from 'prop-types';
import { GET_INVOICES } from 'scenes/Invoices/InvoiceList';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import { useStyles } from './InvoiceList.styles';
import { useInvoiceListColumns } from './InvoiceList.column';

const InvoiceList = ({ projectId, refetchTrigger }) => {
  const styles = useStyles();
  const flags = useFlags();
  const [pageSize, setPageSize] = useState(5);

  const isAdjustmentEnabled = flags[FeatureFlags.ADJUSTMENTS];
  const columns = useInvoiceListColumns({ isAdjustmentEnabled });

  return (
    <div css={styles.root}>
      <XGrid
        columns={columns}
        entityTypeName="Invoices"
        tableName="ProjectInvoicesXGrid"
        disableToolbar
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        pageSize={pageSize}
        silentFilter={[{ columnField: 'projectId', operatorValue: 'equals', value: projectId }]}
        rowsPerPageOptions={[5, 10, 20]}
        rowHeight={40}
        query={GET_INVOICES}
        refetchTrigger={refetchTrigger}
      />
    </div>
  );
};

InvoiceList.propTypes = {
  projectId: PropTypes.string.isRequired,
  refetchTrigger: PropTypes.bool.isRequired
};

export default InvoiceList;
