import gql from 'graphql-tag';

const taskFragment = gql`
  fragment taskFields on Task {
    id
    name
    version
    description
    status
    taskNumber
    sortOrder
    isActive
    lastUpdatedBy
    lastUpdatedDateTime
    auditLogEntries(entityConnection: "AuditLogEntry") {
      items {
        auditedEntityType
        auditedEntityDisplayName
        executionType
        executedBy
        executedDateTime
        auditedEntityId
        auditedEntitySortKey
        auditedEntityParentId
        auditedEntityParentSortKey
        auditedEntityParentEntityType
        auditedEntityParentDisplayName
        changeLog
      }
    }
    assetId
    asset(entityConnection: "PropertyAsset") {
      id
      assetName
      assetTypeId
    }
    taskEntries(entityConnection: "TaskEntry") {
      items {
        id
        productId
        version
        quantity
        name
        description
        priceBookEntryId
        priceBookEntry(entityConnection: "PriceBookEntry") {
          unitPrice
          unitCost
          markupType
          markupValue
          product(entityConnection: "Product") {
            id
            code
            name
            description
            taxable
          }
        }
      }
    }
    formData(entityConnection: "FormData") {
      items {
        id
        isRequired
        formId
        formDefinitionId
        formSortKey
        formDefinitionSortKey
        form {
          id
          name
        }
      }
    }
  }
`;

export default taskFragment;
