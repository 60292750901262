import { useMemo, useCallback, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { handleError } from '@dispatch/Dispatch.utils';

/**
 * Wrapper around @apollo/client useLazyQuery that adds options for transforming and resolving data.
 *
 * @param {string} query - The graphQl query string.
 * @param {Object?} options - An extended [useLazyQuery options object](https://www.apollographql.com/docs/react/api/react/hooks/#options-1).
 *
 * @param {function} options.transform - a function that transforms the resolved data from a successful mutation.
 * @param {Object} options.subscribeToMore - an object to be passed to the query's subscribeToMore function. [see docs](https://www.apollographql.com/docs/react/data/subscriptions/#subscribing-to-updates-for-a-query)
 * @param {any} options.loadingData - will be returned as data when the query is loading
 * @param {any} options.errorData - will be returned as data when there is an error
 * @param {any} options.defaultData - will be returned if data is falsy. errorData and loadingData take priority
 * @param {boolean} options.hideError - suppress notification on error
 *
 * @returns [queryResponse](https://www.apollographql.com/docs/react/api/react/hooks/#result-1) { data: any, loading: boolean, error: Error, called: boolean, client: ApolloClient }
 */
const useExtendedLazyQuery = (
  query,
  {
    transform,
    loadingData,
    errorData,
    defaultData,
    hideError,
    serializer,
    subscribeToMore,
    onError,
    ...options
  } = {}
) => {
  const [trigger, res] = useLazyQuery(query, {
    ...options,
    onError: err => {
      onError?.(err);
      const queryName = query?.definitions?.[0]?.name?.value;
      handleError({ err, errContext: queryName });
    }
  });

  const queryFn = useCallback(
    queryOptions => {
      try {
        return trigger({
          ...(serializer?.(queryOptions) || queryOptions)
        });
      } catch (err) {
        onError?.(err);
        const queryName = query?.definitions?.[0]?.name?.value;
        handleError({ err, errContext: queryName });
      }
    },
    [trigger, serializer, query, onError]
  );

  useEffect(() => {
    const unsubscribe = subscribeToMore ? res?.subscribeToMore?.(subscribeToMore) : null;

    return () => unsubscribe?.();
  }, []);

  const transformedData = useMemo(() => {
    return res.data && transform ? transform(res.data) : res.data;
  }, [res.data, transform]);

  const data = (() => {
    if (res.loading && loadingData) return loadingData;
    if (res.error && errorData) return errorData;

    return transformedData || defaultData;
  })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => [queryFn, { ...res, data }], [queryFn, res.data, res.loading, res.error]);
};

export default useExtendedLazyQuery;
