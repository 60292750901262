/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';
import { getProjects } from 'services/API/project';

export async function getJobs(relations = null) {
  return await instance
    .get(relations ? `jobs?include=${relations}` : `jobs`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getJobById(id, relations = null) {
  return await instance
    .get(relations ? `jobs/${id}?include=${relations}` : `jobs/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function jobSearch(term, searchColumns) {
  if (term.length > 0) {
    return await instance
      .post(`jobs/search/${term}`, { searchColumns: searchColumns || ['jobNumber'] })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  }
  return await getJobs();
}

export async function jobAndProjectSearch(term, searchColumns) {
  let jobList = [];
  let projectList = [];
  const relations = 'companyAddresses';
  if (term.length > 0) {
    jobList = await instance
      .post(`jobs/search/${term}?include=${relations}`, {
        searchColumns: searchColumns || ['jobNumber', 'customIdentifier']
      })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  } else {
    jobList = await getJobs(relations);
  }

  if (term.length > 0) {
    projectList = await instance
      .post(`projects/search/${term}?include=${relations}`, {
        searchColumns: searchColumns || ['number']
      })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  } else {
    projectList = await getProjects();
  }

  return jobList.concat(projectList);
}

export async function jobCreate(job) {
  return await instance
    .post('jobs', job)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function jobChange(id, job) {
  return await instance
    .put(`jobs/${id}`, job)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
