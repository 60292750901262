import React, {
  useEffect,
  useMemo,
  useReducer,
  createContext,
  useContext,
  useRef,
  memo
} from 'react';

import memoize from 'fast-memoize';

import { useQueryString } from 'utils/queryString';

const REPLACE_STATE = 'REPLACE_STATE';

const replaceState = state => ({
  type: REPLACE_STATE,
  payload: state
});

const extendReducer = reducer => (state, action) => {
  switch (action.type) {
    case REPLACE_STATE: {
      return {
        ...action.payload
      };
    }
    default: {
      return reducer(state, action);
    }
  }
};

const valueToArray = memoize(value => (typeof value === 'string' ? [value] : value));

const normalizeArrayValues = (data = {}, arrayKeys) => {
  if (!arrayKeys) return data;

  return Object.entries(data).reduce(
    (curr, [key, value]) => ({
      ...curr,
      [key]: arrayKeys[key] ? valueToArray(value) : value
    }),
    {}
  );
};

export const useQueryStringReducer = (reducer = state => state, actions = {}, options = {}) => {
  const { data, setQueryString } = useQueryString({
    parseBooleans: true,
    parseNumbers: true,
    ...options
  });

  const [state, dispatch] = useReducer(extendReducer(reducer), data);
  // changing logic necessary to ensure query string and state stay
  // in sync when there are router history changes
  const stateChanging = useRef(false);
  const queryStringChanging = useRef(false);

  useEffect(() => {
    if (queryStringChanging.current) {
      queryStringChanging.current = false;
    } else {
      stateChanging.current = true;
      setQueryString(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (stateChanging.current) {
      stateChanging.current = false;
    } else {
      queryStringChanging.current = true;
      dispatch(replaceState(data));
    }
  }, [data]);

  const dispatchActions = useMemo(() => {
    return Object.entries(actions).reduce(
      (wrappedActions, [key, action]) => ({
        ...wrappedActions,
        [key]: (...params) => dispatch(action(...params))
      }),
      {}
    );
  }, [actions, dispatch]);

  return useMemo(
    () => ({
      state: normalizeArrayValues(data, options.arrayKeys),
      ...dispatchActions
    }),
    [data, dispatchActions]
  );
};

export const QueryStringContext = createContext({});
QueryStringContext.displayName = 'QueryStringContext';

export const useQueryStringStore = () => useContext(QueryStringContext);

export const withQueryStringProvider = (reducer, actions, options) => Component => props => {
  const queryStringStore = useQueryStringReducer(reducer, actions, options);

  return (
    <QueryStringContext.Provider value={queryStringStore}>
      <Component {...props} />
    </QueryStringContext.Provider>
  );
};

/**
 * withQueryStringStore is the preferred method for accessing the querystring
 * store as it only re-renders the base component when the state it is
 * subscribed to changes
 *
 * @param {func} mapStoreToProps = (store, ownProps) => mappedProps
 * @param propsAreEqual = passed as the second argument on the memo
 * @returns {Component}
 */
export const withQueryStringStore = (
  mapStoreToProps = store => store,
  { propsAreEqual } = {}
) => Component => props => {
  const mappedProps = mapStoreToProps(useQueryStringStore(), props);
  const MemoizedComponent = useRef(memo(Component, propsAreEqual)).current;

  return <MemoizedComponent {...props} {...mappedProps} />;
};
