import gql from 'graphql-tag';

const getNotesByCustomer = gql`
  query getCustomer($partitionKey: String!, $sortKey: String!) {
    getCustomer(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      customerName
      notes(entityConnection: "Note", limit: 100) {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          subject
          note
          createdDate
          createdDateTime
          createdBy
          lastUpdatedDate
          lastUpdatedDateTime
          lastUpdatedBy
        }
      }
      customerProperties(entityConnection: "CustomerProperty", limit: 500) {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          companyName
          notes(entityConnection: "Note", limit: 100) {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              subject
              note
              createdDate
              createdDateTime
              createdBy
              lastUpdatedDate
              lastUpdatedDateTime
              lastUpdatedBy
            }
          }
        }
      }
      customerReps(entityConnection: "CustomerRep", limit: 100) {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          name
          notes(entityConnection: "Note", limit: 100) {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              subject
              note
              createdDate
              createdDateTime
              createdBy
              lastUpdatedDate
              lastUpdatedDateTime
              lastUpdatedBy
            }
          }
        }
      }
      tenantReps(entityConnection: "CustomerEmployee", limit: 100) {
        items {
          id
          mappedEntityId
          mappedEntityType
          systemEntityId
          systemEntityType
          hierarchy
          mappedEntity {
            ... on Employee {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              name
            }
          }
        }
      }
    }
  }
`;

export default getNotesByCustomer;
