import gql from 'graphql-tag';

const updateTruckAndRelated = gql`
  mutation updateTruckAndRelated($partitionKey: String!, $data: UpdateTruckAndRelatedInput!) {
    updateTruckAndRelated(partitionKey: $partitionKey, data: $data) {
      id
      version
      name
      productBundleId
      productBundle(entityConnection: "ProductBundle") {
        id
        version
        name
        category
        subCategory
        type
        isActive
      }
      truckEmployees(entityConnection: "TruckEmployee") {
        items {
          id
          version
          employee(entityConnection: "Employee") {
            id
            firstName
            lastName
            name
          }
        }
      }
    }
  }
`;

export default updateTruckAndRelated;
