import gql from 'graphql-tag';

const sendQuoteToCustomer = gql`
  mutation sendQuoteToCustomer($partitionKey: String!, $data: SendQuoteToCustomerInput!) {
    sendQuoteToCustomer(partitionKey: $partitionKey, data: $data) {
      id
      tenantId
      partitionKey
      entityType
      version
      status
    }
  }
`;

export default sendQuoteToCustomer;
