import React from 'react';

import { Typography, TV } from '@buildhero/sergeant';

import { TableRow, TableCell, useTheme } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import Linkify from 'react-linkify';

import ProfileIcon from 'components/Navigation/UserControls/ProfileIcon';
import { AttachmentImages } from 'scenes/Payroll/TimeTrackingReport/TimesheetNotes';

import { Mode } from 'utils/constants';

const useStyles = theme => ({
  item: {
    display: 'inline-flex',
    width: '100%'
  },
  mainLabel: {
    fontSize: 14,
    flex: '1 0 auto'
  },
  subject: {
    fontSize: 14,
    flex: '1 0 auto',
    maxWidth: '150px',
    marginLeft: theme.spacing(0.8)
  },
  mainTextColor: {
    color: theme.palette.grayscale(20)
  },
  avatar: {
    textTransform: 'uppercase',
    background: theme.palette.grayscale(40),
    flexShrink: 0
  },
  auxiliaryLabel: {
    fontSize: 12,
    marginTop: theme.spacing(0.5)
  },
  auxiliaryTextColor: {
    color: theme.palette.grayscale(60)
  },
  changeLabel: {
    fontSize: 12,
    marginLeft: theme.spacing(1)
  },
  editIcon: {
    height: 16,
    cursor: 'pointer'
  }
});

const LogRow = ({
  key,
  id,
  message,
  date,
  author,
  subject,
  attachments,
  workEvent,
  tenantId,
  version,
  employeeId,
  setInteractiveModalData = () => {},
  interactive = false
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <TableRow key={key} css={styles.row}>
      <TableCell
        styles={{
          root: styles.item
        }}
      >
        <div
          style={{ display: 'flex', alignItems: 'flex-start', padding: '10px 0', width: '100%' }}
        >
          {author && <ProfileIcon css={styles.avatar} employeeName={author} />}
          <div
            style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px', width: '100%' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 400,
                flexWrap: 'wrap',
                width: '100%'
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'inline-flex', flexWrap: 'nowrap' }}>
                  <Typography
                    variant={TV.PRINT}
                    css={{ ...styles.mainLabel, ...styles.mainTextColor }}
                  >
                    {author}
                  </Typography>
                  <Linkify>
                    <Typography
                      variant={TV.PRINT}
                      css={{ ...styles.subject, ...styles.auxiliaryTextColor }}
                    >
                      {subject}:
                    </Typography>
                    <span css={{ ...styles.changeLabel, ...styles.auxiliaryTextColor }}>
                      {message}
                    </span>
                  </Linkify>
                  {interactive && (
                    <CreateIcon
                      css={styles.editIcon}
                      onClick={() => {
                        // currently not possible to attach 2+ attachments to a TimesheetNote on mobile (Sept 8 2021)
                        if (attachments?.items.length < 2) {
                          setInteractiveModalData({
                            mode: Mode.EDIT,
                            parent: {
                              id: workEvent.id,
                              entityType: workEvent.entityType,
                              tenantId,
                              binderId: workEvent?.binderId
                            },
                            data: {
                              ...attachments?.items?.[0],
                              attachmentId: attachments?.items?.[0]?.id,
                              id,
                              customFileName: subject,
                              comment: message,
                              version,
                              employeeId
                            }
                          });
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <Typography
              variant="body2"
              css={{ ...styles.auxiliaryLabel, ...styles.auxiliaryTextColor }}
            >
              {date}
            </Typography>
            <AttachmentImages attachments={attachments} />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default LogRow;
