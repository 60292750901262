export const includeCustomerSignature = async context => {
  const { services, modalRecord, modalChecked } = context;
  const { reviewReportService } = services;

  const formattedData = (({ id, version, includeInInvoice }) => ({
    id,
    version,
    includeInInvoice
  }))(modalRecord);

  formattedData.includeInInvoice = modalChecked;

  const response = await reviewReportService.updateCustomerSignature(
    modalRecord.partitionKey,
    formattedData
  );
  const { updateCustomerSignature } = response.data;
  const { customerSignatures } = context;

  const modifiedCustomerSignatures = customerSignatures.map(signature => {
    let localSignature = signature;
    if (localSignature.id === updateCustomerSignature.id) {
      localSignature = updateCustomerSignature;
    }
    return localSignature;
  });

  return { customerSignatures: modifiedCustomerSignatures, modalRecord: {}, modalIndex: '' };
};

export default includeCustomerSignature;
