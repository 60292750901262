import gql from 'graphql-tag';

const propertyAssetUpdateNotification = gql`
  subscription propertyAssetUpdateNotification($partitionKey: String!) {
    propertyAssetUpdateNotification(partitionKey: $partitionKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      assetName
      make
      modelNumber
      serialNo
      version
      installDate
      location
      propertyZone
      lastUpdatedDateTime
      parentId
      # can remove notes - leaving for backwards compatibility and mobile
      notes(entityConnection: "Note") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          subject
          note
          version
          createdBy
          lastUpdatedDate
          lastUpdatedDateTime
          lastUpdatedBy
        }
      }
    }
  }
`;

export default propertyAssetUpdateNotification;
