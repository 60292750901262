import gql from 'graphql-tag';
import { dispatchVisitFragment } from './visitFragment';

export const visitTransitionMutation = gql`
  mutation transitionVisitDispatch($input:visitTransitionInput!,$action:String!) {
    visitTransition(visitAction:$action, data: $input) {
      ${dispatchVisitFragment}
    }
  }
`;
