export const LOGOUT = 'LOGOUT';

export const SPINNER_ON = 'SPINNER_ON';
export const SPINNER_OFF = 'SPINNER_OFF';

export const SNACKBAR_ON = 'SNACKBAR_ON';
export const SNACKBAR_OFF = 'SNACKBAR_ON';

export const ALERT_ON = 'ALERT_ON';
export const ALERT_OFF = 'ALERT_OFF';

export const APP_MENU = 'APP_MENU';
export const MENU_OPEN = 'MENU_OPEN';
export const BREADCRUMB = 'BREADCRUMB';
export const PAGE_MODE = 'pageMode';

export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_OPTIONS = 'SET_OPTIONS';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_COMPANY_SETTINGS = 'SET_COMPANY_SETTINGS';
export const UPDATE_SETTING = 'UPDATE_SETTING';

export const SIGNED_IN = 'SIGNED_IN';
export const SIGNED_OUT = 'SIGNED_OUT';
export const REGISTER = 'REGISTER';
export const USERNAME = ' USERNAME';
export const SET_USER_TENANT_SETTINGS = 'SET_USER_TENANT_SETTINGS';
export const UPDATE_USER_TENANT_SETTING = 'UPDATE_USER_TENANT_SETTING';

export const REFRESH_TABLE_DATA = 'REFRESH_TABLE_DATA';
export const QUIET_REFRESH_TABLE_DATA = 'QUIET_REFRESH_TABLE_DATA';
export const DISABLE_QUIET_REFRESH = 'DISABLE_QUIET_REFRESH';

export const OPEN_QUICK_ADD_MODAL = 'OPEN_QUICK_ADD_MODAL';

export const SET_PROJECT_SUMMARY = 'SET_PROJECT_SUMMARY';
export const SET_PROJECT_DETAILS = 'SET_PROJECT_DETAILS';
export const REFETCH_PROJECT_DETAILS = 'REFETCH_PROJECT_DETAILS';
export const SET_PROJECT_KPIS = 'SET_PROJECT_KPIS';
export const SET_COMPANY_TIMEZONE = 'SET_COMPANY_TIMEZONE';

export const SET_LD_FLAGS = 'SET_LD_FLAGS';

export const SHOW_MUTATION_CONFIRMATION = 'SHOW_MUTATION_CONFIRMATION';
export const HIDE_MUTATION_CONFIRMATION = 'HIDE_MUTATION_CONFIRMATION';

export const SET_CREATE_MULTI_VISIT_STARTED = 'SET_CREATE_MULTI_VISIT_STARTED';
export const SET_CREATE_MULTI_VISIT_ENDED = 'SET_CREATE_MULTI_VISIT_ENDED';
