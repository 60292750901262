import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AuthService from 'services/AuthServices';
import { Logger as BHLogger } from 'services/Logger';
import { makeMountAware } from 'utils/makeMountAware';
import { snackbarOn, snackbarOff } from 'redux/actions/globalActions';
import { TV, Typography, ThemeProvider } from '@buildhero/sergeant';
import ForgotPasswordUsernameTextBox from './ForgotPasswordUsername';
import ForgotPasswordCodeTextBox from './ForgotPasswordCode';
import styles from '../styles';

export class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.submitCodeAndNewPassword = this.submitCodeAndNewPassword.bind(this);
    this.completeNewPassword = this.completeNewPassword.bind(this);
    this.displayError = this.displayError.bind(this);
    this.validateField = this.validateField.bind(this);
    this.lastStepAction = this.lastStepAction.bind(this);
    this.state = {
      step: 1,
      username: '',
      code: null,
      newPassword: null,
      confirmPassword: '',
      formErrors: {
        code: '',
        username: '',
        newPassword: '',
        confirmPassword: ''
      },
      newPasswordValid: false,
      codeValid: false,
      confirmPasswordValid: false,
      usernameValid: false,
      formValid: false,
      passwordResetIsRequired: false,
      passwordResetException: false,
      resetPassword: false
    };
  }

  componentDidMount() {
    const { location } = this.props;

    if (location.state !== undefined) {
      const { state } = location;
      const { passwordResetException, passwordResetIsRequired, resetPassword, username } = state;

      if (username) {
        this.validateField('username', username);
      }

      if (resetPassword) {
        this.setState({
          step: 1,
          username,
          resetPassword
        });
        return;
      }

      if (passwordResetIsRequired || resetPassword) {
        this.setState(
          {
            passwordResetException,
            passwordResetIsRequired,
            resetPassword,
            username,
            step: 2
          },
          () => {
            this.validateField('username', username);
          }
        );
      }
    }
  }

  nextStep = async () => {
    const { step, username } = this.state;
    try {
      await this.forgotPassword(username);
    } catch (error) {
      this.setState({
        step: 1,
        usernameValid: false,
        username: ''
      });
      return;
    }

    this.setState({
      step: step + 1
    });
  };

  previousStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  handleChange = inputName => event => {
    const { value } = event.target;
    this.setState(
      {
        [inputName]: value.trim()
      },
      () => {
        this.validateField(inputName, value);
      }
    );
  };

  lastStepAction = async () => {
    const { passwordResetException, resetPassword } = this.state;
    if (passwordResetException || resetPassword) {
      return this.submitCodeAndNewPassword();
    }
    return this.completeNewPassword();
  };

  validateField(fieldName, value) {
    const { i18nMessages, user } = this.props;
    const { locale } = user;

    const { formErrors } = this.state;

    let { newPasswordValid, codeValid, usernameValid, confirmPasswordValid } = this.state;

    switch (fieldName) {
      case 'code':
        codeValid = value.length >= 1;
        formErrors.code = codeValid ? '' : i18nMessages.invalidCodeHelperText[locale];
        break;

      case 'newPassword': {
        newPasswordValid = value.length >= 8;
        formErrors.password = newPasswordValid
          ? ''
          : i18nMessages.invalidPasswordHelperText[locale];

        const { confirmPassword } = this.state;
        this.validateField('confirmPassword', confirmPassword);

        break;
      }

      case 'confirmPassword': {
        const { newPassword } = this.state;
        confirmPasswordValid = value === newPassword;
        formErrors.confirmPassword = confirmPasswordValid
          ? ''
          : i18nMessages.invalidConfirmPasswordHelperText[locale];
        break;
      }

      case 'username':
        usernameValid = value.length >= 3;
        formErrors.username = usernameValid ? '' : i18nMessages.invalidUsernameHelperText[locale];
        break;

      default:
        break;
    }

    this.setState(
      {
        formErrors,
        newPasswordValid,
        usernameValid,
        codeValid,
        confirmPasswordValid
      },
      this.validateForm
    );
  }

  displayError(message) {
    this.props.snackbarOn('error', message);
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.codeValid &&
        this.state.newPasswordValid &&
        this.state.usernameValid &&
        this.state.confirmPasswordValid
    });
  }

  async forgotPassword() {
    const { auth, i18nMessages, user } = this.props;
    const { locale } = user;
    const { username } = this.state;

    try {
      await auth.forgotPassword(username.trim().toLowerCase());
      this.props.snackbarOn('success', i18nMessages.codeSentSuccessMessage[locale]);
    } catch (error) {
      this.props.snackbarOn('error', error.message, error);
      throw new Error(error.message);
    }
  }

  async completeNewPassword() {
    const { auth, history, i18nMessages, user } = this.props;
    const { locale } = user;
    const { newPassword } = this.state;

    try {
      const loggedUser = await auth.completeNewPassword(newPassword);
      BHLogger.debug(`Forgot password: Logged User- ${JSON.stringify(loggedUser)}`);
      this.props.snackbarOn('success', i18nMessages.passwordResetSuccessMessage[locale]);
      history.push('/');
    } catch (error) {
      let errorMessage = error.message;
      if (
        error.message &&
        error.message.includes('PreTokenGeneration failed with error Login Failure:')
      ) {
        errorMessage = error.message.replace(
          'PreTokenGeneration failed with error Login Failure:',
          ''
        );
      }
      this.props.snackbarOn('error', errorMessage, error);
    }
  }

  async submitCodeAndNewPassword() {
    const { auth, history, i18nMessages, user } = this.props;
    const { locale } = user;
    const { username, code, newPassword } = this.state;

    try {
      await auth.forgotPasswordSubmit(username, code, newPassword);
      await auth.login(username, newPassword);
      this.props.snackbarOn('success', i18nMessages.passwordResetSuccessMessage[locale]);
      history.push('/');
    } catch (error) {
      let errorMessage = error.message;
      if (
        error.message &&
        error.message.includes('PreTokenGeneration failed with error Login Failure:')
      ) {
        errorMessage = error.message.replace(
          'PreTokenGeneration failed with error Login Failure:',
          ''
        );
      }
      this.props.snackbarOn('error', errorMessage, error);
    }
  }

  render() {
    const { classes, i18nMessages, user } = this.props;
    const { locale } = user;

    const {
      step,
      code,
      username,
      newPassword,
      confirmPassword,
      passwordResetIsRequired,
      resetPassword
    } = this.state;
    const values = {
      code,
      username,
      newPassword,
      confirmPassword
    };
    const {
      emailValid,
      codeValid,
      usernameValid,
      newPasswordValid,
      confirmPasswordValid,
      formErrors
    } = this.state;

    const validValues = {
      emailValid,
      codeValid,
      usernameValid,
      newPasswordValid,
      confirmPasswordValid
    };
    const errorMessages = {
      email: formErrors.email,
      code: formErrors.code,
      username: formErrors.username,
      password: formErrors.password,
      confirmPassword: formErrors.confirmPassword
    };

    let stepComponent = <div />;

    switch (step) {
      case 1:
        stepComponent = (
          <ForgotPasswordUsernameTextBox
            nextStep={this.nextStep}
            previousStep={() => this.props.history.push('/signin')}
            handleChange={this.handleChange}
            values={values}
            validValues={validValues}
            errorMessages={errorMessages}
            displayError={this.displayError}
            i18nMessages={i18nMessages}
            locale={locale}
            validateValue={this.validateField}
          />
        );
        break;

      case 2:
        stepComponent = (
          <ForgotPasswordCodeTextBox
            nextStep={this.lastStepAction}
            previousStep={this.previousStep}
            resendCode={this.forgotPassword}
            handleChange={this.handleChange}
            showVerificationCodeInput={resetPassword}
            values={values}
            validValues={validValues}
            errorMessages={errorMessages}
            displayError={this.displayError}
            i18nMessages={i18nMessages}
            locale={locale}
          />
        );
        break;

      default:
        break;
    }

    return (
      <ThemeProvider>
        <Grid
          container
          spacing={0}
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.banner}
        >
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <img
                src="/static/images/Logo_Lockup_White.svg"
                alt="BuildOps"
                style={{ maxWidth: 250 }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item xs={12} sm={12} lg={4} md={4} xl={4} className={classes.authSection}>
            <Typography variant={TV.XXL} align="center">
              {passwordResetIsRequired
                ? i18nMessages.resetPasswordTitle[locale]
                : i18nMessages.forgotPasswordTitle[locale]}
            </Typography>
          </Grid>
          {stepComponent}
        </Grid>
      </ThemeProvider>
    );
  }
}

ForgotPassword.propTypes = {
  // eslint-disable-next-line react/require-default-props
  auth: PropTypes.instanceOf(AuthService)
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog)),
  snackbarOff: () => dispatch(snackbarOff())
});

const reduxConnectedForgotPassword = connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

export const ForgotPasswordWithStyle = withStyles(styles, { withTheme: true })(ForgotPassword);

export default compose(
  makeMountAware,
  withRouter,
  withStyles(styles, { withTheme: true })
)(reduxConnectedForgotPassword);
