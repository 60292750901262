import gql from 'graphql-tag';

const addTaskEntriesToTask = gql`
  mutation addTaskEntriesToTask($partitionKey: String!, $data: AddTaskEntriesToTaskInput!) {
    addTaskEntriesToTask(partitionKey: $partitionKey, data: $data) {
      id
      version
      entityType
      productId
      markupType
      markupValue
      unitPrice
      unitCost
      quantity
      priceBookEntryId
      priceBookEntry(entityConnection: "PriceBookEntry") {
        product(entityConnection: "Product") {
          name
          code
          description
        }
      }
    }
  }
`;

export default addTaskEntriesToTask;
