import React, { useState, useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { snackbarOn } from 'redux/actions/globalActions';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { generateDefaultValidationSchema } from '@pm/components/formattingUtils';
import MUIFormListView from '@pm/components/MUIFormListView';
import SearchBar from '@pm/components/APISearchComponents/SearchBar';
import FormWithAccordion from '@pm/components/FormWithAccordion';
import { getLastLineNumber } from 'scenes/Procurement/component/utils';
import { useProductivityPhaseDelete } from 'services/APIHooks/productivityPhase';
import { findById } from 'utils/ramda';

import { productivityFields, productivityLayout } from './layout';
import { useProjectPhases } from '../../../DailyReport.hooks';
import {
  getDefaultIds,
  getDefaultIdFromMapping,
  getPhaseDeptCostCodesMapping
} from '../LaborFieldForm/LaborFieldForm.helpers';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 24
  },
  accordionFooter: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '21px 28px'
  },
  hideElement: {
    display: 'none'
  },
  addButton: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '14px',
    textDecoration: 'none'
  },
  footerForm: {
    width: 702
  },
  footerTotal: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 21
  },
  label: {
    fontSize: 10,
    letterSpacing: 0.01,
    fontWeight: 'normal',
    textTransform: 'uppercase',
    lineHeight: '14px',
    marginBottom: '0.35em'
  },
  deleteButton: {
    background: theme?.palette?.error?.light
  }
}));

const reductions = {
  update: (state, payload) =>
    R.update(
      state.findIndex(item => item.index === payload.index),
      payload,
      state
    ),
  add: (state, payload) => [...state, payload],
  delete: (state, payload) => {
    return [...state.filter(value => payload.index !== value.index)];
  },
  set: (_, payload) => [...payload]
};

const formDataReducer = (state, { type, payload }) => reductions[type]?.(state, payload) ?? state;

const ProductivityFieldForm = props => {
  const {
    initialData,
    mode,
    onCreateService,
    onComplete,
    onDeleteService,
    formatFuncForSave,
    projectId,
    loading
  } = props;
  const classes = useStyles();
  const [{ data: projectPhases }] = useProjectPhases(projectId);
  const projectPhasesRef = useRef();
  projectPhasesRef.current = projectPhases;
  const [panelExpanded, setPanelExpanded] = useState(false);

  const [formData, dispatchStateItems] = useReducer(formDataReducer, []);

  const arrDispatch = (state, { index, item }) => {
    const newState = [...state];
    newState[index] = item;
    return newState;
  };

  const [phaseIds, setPhaseIds] = useReducer(arrDispatch, []);
  const [departments, setDepartments] = useReducer(arrDispatch, []);

  const getFormattedData = (data, index) => {
    const { phaseId, departmentId, costCodeId } = data;
    const phase = findById(phaseId)(projectPhasesRef.current || []);
    const department = findById(departmentId)(phase?.ProjectPhaseDepartment);
    const costCode = findById(costCodeId)(department?.ProjectPhaseDepartmentCostCode);
    const defaultValueMapping = getPhaseDeptCostCodesMapping(projectPhasesRef.current);
    const defaultDepartmentId =
      getDefaultIdFromMapping(defaultValueMapping?.[phaseId]) || departmentId;
    const defaultCostCodeId = getDefaultIdFromMapping(
      defaultValueMapping?.[phaseId]?.[defaultDepartmentId]
    );

    return {
      index,
      serviceName: `productivity-${index}`,
      phaseId: phase?.id || undefined,
      costCodeId: costCode?.id || defaultCostCodeId,
      departmentId: department?.id || defaultDepartmentId,
      installedUnits: parseFloat(data?.installedUnits || 0),
      unproductiveHours: parseFloat(data?.unproductiveHours || 0),
      id: data?.id || null
    };
  };

  useEffect(() => {
    if (
      initialData &&
      projectPhases.length > 0 &&
      Array.isArray(initialData) &&
      initialData?.length > 0
    ) {
      dispatchStateItems({
        type: 'set',
        payload: initialData?.map((value, index) => ({
          ...value,
          phaseId: value?.projectPhaseId,
          departmentId: value?.projectPhaseDepartmentId,
          costCodeId: value?.projectPhaseDepartmentCostCodeId,
          index,
          serviceName: `productivity-${index}`
        }))
      });
    }
  }, [initialData, projectPhases]);

  const addNewFormData = () => {
    const indexNumber = formData.length ? getLastLineNumber(formData, 'index') + 1 : 0;

    const { defaultPhaseId, defaultDepartmentId, defaultCostCodeId } = getDefaultIds(projectPhases);
    const newFormData = getFormattedData(
      { phaseId: defaultPhaseId, departmentId: defaultDepartmentId, costCodeId: defaultCostCodeId },
      indexNumber
    );

    dispatchStateItems({ type: 'add', payload: newFormData });
  };

  const handlePanelExpand = (_, newExpanded) => {
    setPanelExpanded(newExpanded);
  };

  const handleFormChange = value => {
    const { phaseId, departmentId, index } = value;
    if (phaseId) {
      setPhaseIds({ index, item: phaseId });
    }
    if (departmentId) {
      setDepartments({ index, item: departmentId });
    }
    dispatchStateItems({
      type: 'update',
      payload: getFormattedData(value, value.index)
    });
  };

  const [{ loading: deleteInProcess }, execute] = useProductivityPhaseDelete({
    onSuccess: () => props.snackbarOn('success', 'An item was successfully deleted')
  });

  const handleDeleteButton = async item => {
    if (item.id) {
      await execute({ url: `productivityphase/${item.id}` });
    }
    if (item.serviceName) {
      onDeleteService(item.serviceName);
    }
    dispatchStateItems({ type: 'delete', payload: item });
  };

  const deleteButton = ({ field }) => {
    return (
      <Button
        className={classes.deleteButton}
        variant="contained"
        color="secondary"
        disable={deleteInProcess}
        onClick={() => handleDeleteButton(field?.value || {})}
      >
        Delete
      </Button>
    );
  };

  const muiForms = () => (
    <MUIFormListView
      keyName="productivity"
      formData={formData}
      onComplete={onComplete}
      formatFuncForSave={formatFuncForSave}
      mode="default"
      key="productivity"
      layout={mode}
      useIndexConfigCallback
      configuration={index => {
        return productivityLayout({
          phaseIds,
          departments,
          projectPhases,
          index
        });
      }}
      onCreateService={onCreateService}
      customComponents={{ SearchBar, deleteButton }}
      onFormChange={handleFormChange}
      validationSchema={generateDefaultValidationSchema(productivityFields)}
    />
  );

  const addFormBtn = (
    <Button
      startIcon={<AddCircleOutlineIcon />}
      className={classes.addButton}
      onClick={addNewFormData}
    >
      <Typography>Add Productivity</Typography>
    </Button>
  );

  return (
    <div className={classes.root}>
      <FormWithAccordion
        sectionName="productivity (Installed Units / Unproductive Hours)"
        formComponent={muiForms}
        buttonComponent={addFormBtn}
        handlePanelExpand={handlePanelExpand}
        expanded={panelExpanded}
        loading={loading}
      />
      <Divider />
    </div>
  );
};

ProductivityFieldForm.propTypes = {
  initialData: PropTypes.array.isRequired,
  onCreateService: PropTypes.func.isRequired,
  mode: PropTypes.string,
  onComplete: PropTypes.func.isRequired,
  formatFuncForSave: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  projectId: PropTypes.number.isRequired,
  snackbarOn: PropTypes.func,
  onDeleteService: PropTypes.func.isRequired
};

ProductivityFieldForm.defaultProps = {
  mode: 'default',
  loading: false,
  snackbarOn: () => false
};
const mapStateToProps = state => ({ user: state.user });
const mapDispatcherToProps = { snackbarOn };
const ReduxConnectedMaterialFieldForm = connect(
  mapStateToProps,
  mapDispatcherToProps
)(ProductivityFieldForm);

export default ReduxConnectedMaterialFieldForm;
