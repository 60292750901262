import { DEFAULT_PRESET } from './InvoiceAndReport';

const headingTextStyle = theme => ({
  fontWeight: 'bold',
  color: theme.palette.grayscale(60)
});

const sectionContainerStyle = theme => ({
  direction: 'row',
  grow: 0,
  padding: [4, 0],
  marginTop: 36,
  borderTop: `1px solid ${theme.palette.grayscale(80)}`,
  alignItems: 'center'
});

const rowStyle = {
  direction: 'row',
  minWidth: 376,
  alignItems: 'center',
  padding: [2, 0]
};

const shortRowStyle = {
  direction: 'column',
  minWidth: 264,
  marginLeft: 112,
  padding: [2, 0]
};

const switchStyle = {
  direction: 'column',
  grow: 1,
  shrink: 0,
  padding: [0, 8]
};

const reportPdfConfirm = (
  hasDraftReport = true,
  theme,
  presetOptions = [],
  onClickCreateNewPreset = () => {},
  onClickUpdatePreset = () => {},
  onClickRenamePreset = () => {},
  onClickDuplicatePreset = () => {},
  onClickRemovePreset = () => {},
  isJobCloseoutReport = false
) => ({
  fields: {},
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          options: {
            ...sectionContainerStyle(theme)
          },
          contents: [
            {
              component: 'BodyText',
              source: 'Job Report Presets',
              options: {
                ...headingTextStyle(theme)
              }
            }
          ]
        },
        {
          options: {
            direction: 'row',
            grow: 0,
            marginBottom: isJobCloseoutReport ? 16 : ''
          },
          contents: [
            {
              component: 'PresetSelect',
              source: 'presetId',
              options: {
                saveText: 'Save as new preset',
                updateText: 'Update selected preset',
                options: [DEFAULT_PRESET, ...presetOptions],
                onClickCreateNew: onClickCreateNewPreset,
                onClickUpdate: onClickUpdatePreset,
                onClickRename: onClickRenamePreset,
                onClickDuplicate: onClickDuplicatePreset,
                onClickRemove: onClickRemovePreset
              }
            }
          ]
        },
        {
          options: {
            direction: 'row',
            grow: 0,
            hide: !hasDraftReport
          },
          contents: [
            {
              component: 'DraftReportWarningMessage'
            }
          ]
        },
        isJobCloseoutReport && {
          options: {
            direction: 'row',
            alignItems: 'center'
          },
          contents: [
            {
              options: rowStyle,
              contents: [
                {
                  component: 'BodyText',
                  source: 'Show costs associated with Job'
                },
                {
                  component: 'TooltipInfo',
                  options: {
                    message:
                      'Purchased items and some inventory items are associated with the job rather than a specific visit. Enabling this will show all items that aren’t associated with a specific visit.'
                  }
                }
              ]
            },
            {
              options: switchStyle,
              contents: [
                {
                  options: {
                    name: 'showJobCost'
                  },
                  component: 'Switch',
                  source: 'showJobCost'
                }
              ]
            }
          ]
        },
        {
          options: {
            ...sectionContainerStyle(theme)
          },
          contents: [
            {
              component: 'BodyText',
              source: 'Descriptions',
              options: {
                ...headingTextStyle(theme)
              }
            }
          ]
        },
        {
          options: {
            direction: 'row',
            alignItems: 'center'
          },
          contents: [
            {
              options: rowStyle,
              contents: [
                {
                  component: 'BodyText',
                  source: 'Show Job Description'
                }
              ]
            },
            {
              options: switchStyle,
              contents: [
                {
                  options: {
                    name: 'showJobDescription'
                  },
                  component: 'Switch',
                  source: 'showJobDescription'
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row',
            alignItems: 'center'
          },
          contents: [
            {
              options: rowStyle,
              contents: [
                {
                  component: 'BodyText',
                  source: 'Show Job Summary'
                }
              ]
            },
            {
              options: switchStyle,
              contents: [
                {
                  options: {
                    name: 'showJobSummary'
                  },
                  component: 'Switch',
                  source: 'showJobSummary'
                }
              ]
            }
          ]
        },
        {
          options: {
            ...sectionContainerStyle(theme)
          },
          contents: [
            {
              component: 'BodyText',
              source: 'Tasks',
              options: {
                ...headingTextStyle(theme)
              }
            }
          ]
        },
        {
          options: {
            direction: 'row',
            alignItems: 'center'
          },
          contents: [
            {
              options: rowStyle,
              contents: [
                {
                  component: 'BodyText',
                  source: 'Show Tasks Completed'
                }
              ]
            },
            {
              options: switchStyle,
              contents: [
                {
                  options: {
                    name: 'showTaskCompleted'
                  },
                  component: 'Switch',
                  source: 'showTaskCompleted'
                }
              ]
            }
          ]
        },
        {
          options: {
            ...sectionContainerStyle(theme)
          },
          contents: [
            {
              component: 'BodyText',
              source: 'Labor',
              options: {
                ...headingTextStyle(theme)
              }
            }
          ]
        },
        {
          options: {
            direction: 'row',
            alignItems: 'center'
          },
          contents: [
            {
              options: rowStyle,
              contents: [
                {
                  component: 'BodyText',
                  source: 'Show Hours Worked'
                },
                {
                  component: 'TooltipInfo',
                  options: {
                    message: 'Populated from Technician Timesheet'
                  }
                }
              ]
            },
            {
              options: switchStyle,
              contents: [
                {
                  options: {
                    name: 'showHoursWorked'
                  },
                  component: 'Switch',
                  source: 'showHoursWorked'
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row',
            alignItems: 'center'
          },
          contents: [
            {
              options: rowStyle,
              contents: [
                {
                  component: 'BodyText',
                  source: 'Show Hours Billed'
                },
                {
                  component: 'TooltipInfo',
                  options: {
                    message: 'Populated from Review Report'
                  }
                }
              ]
            },
            {
              options: switchStyle,
              contents: [
                {
                  options: {
                    name: 'showHoursBilled'
                  },
                  component: 'Switch',
                  source: 'showHoursBilled'
                }
              ]
            }
          ]
        },
        {
          options: {
            ...sectionContainerStyle(theme),
            marginLeft: 112
          },
          contents: [
            {
              component: 'BodyText',
              source: 'Hours Worked',
              options: {
                ...headingTextStyle(theme)
              }
            },
            {
              component: 'TooltipInfo',
              options: {
                message: 'Toggle on Hours Worked to enable this option'
              }
            }
          ]
        },
        {
          options: {
            direction: 'row',
            alignItems: 'center'
          },
          contents: [
            {
              options: shortRowStyle,
              contents: [
                {
                  component: 'BodyText',
                  source: 'Show Cost Summary'
                }
              ]
            },
            {
              options: switchStyle,
              contents: [
                {
                  options: {
                    name: 'showCostSummary'
                  },
                  component: 'Switch',
                  source: 'showCostSummary',
                  dependentField: {
                    fieldName: 'showHoursWorked',
                    expectedValue: false,
                    operation: 'bool',
                    action: 'DISABLE'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            ...sectionContainerStyle(theme),
            marginLeft: 112
          },
          contents: [
            {
              component: 'BodyText',
              source: 'Hours Billed',
              options: {
                ...headingTextStyle(theme)
              }
            },
            {
              component: 'TooltipInfo',
              options: {
                message: 'Toggle on Hours Billed to enable this option'
              }
            }
          ]
        },
        {
          options: {
            direction: 'row',
            alignItems: 'center'
          },
          contents: [
            {
              options: shortRowStyle,
              contents: [
                {
                  component: 'BodyText',
                  source: 'Show Price Summary'
                }
              ]
            },
            {
              options: switchStyle,
              contents: [
                {
                  options: {
                    name: 'showPriceSummary'
                  },
                  component: 'Switch',
                  source: 'showPriceSummary',
                  dependentField: {
                    fieldName: 'showHoursBilled',
                    expectedValue: false,
                    operation: 'bool',
                    action: 'DISABLE'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            ...sectionContainerStyle(theme)
          },
          contents: [
            {
              component: 'BodyText',
              source: 'Parts & Materials',
              options: {
                ...headingTextStyle(theme)
              }
            }
          ]
        },
        {
          options: {
            direction: 'row',
            alignItems: 'center'
          },
          contents: [
            {
              options: rowStyle,
              contents: [
                {
                  component: 'BodyText',
                  source: 'Show Inventory Items'
                }
              ]
            },
            {
              options: switchStyle,
              contents: [
                {
                  options: {
                    name: 'showInventoryItems'
                  },
                  component: 'Switch',
                  source: 'showInventoryItems'
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row',
            alignItems: 'center'
          },
          contents: [
            {
              options: rowStyle,
              contents: [
                {
                  component: 'BodyText',
                  source: 'Show Purchased Items'
                }
              ]
            },
            {
              options: switchStyle,
              contents: [
                {
                  options: {
                    name: 'showPurchasedItems'
                  },
                  component: 'Switch',
                  source: 'showPurchasedItems'
                }
              ]
            }
          ]
        },
        {
          options: {
            ...sectionContainerStyle(theme),
            marginLeft: 112
          },
          contents: [
            {
              component: 'BodyText',
              source: 'Materials',
              options: {
                ...headingTextStyle(theme)
              }
            },
            {
              component: 'TooltipInfo',
              options: {
                message: 'Toggle on Purchased Items and/or Inventory Items to enable these options'
              }
            }
          ]
        },
        {
          options: {
            direction: 'row',
            alignItems: 'center'
          },
          contents: [
            {
              options: shortRowStyle,
              contents: [
                {
                  component: 'BodyText',
                  source: 'Show Materials Cost Summary'
                }
              ]
            },
            {
              options: switchStyle,
              contents: [
                {
                  options: {
                    name: 'showMaterialsCostSummary',
                    disableFn: values => !values?.showInventoryItems && !values?.showPurchasedItems
                  },
                  component: 'Switch',
                  source: 'showMaterialsCostSummary'
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row',
            alignItems: 'center'
          },
          contents: [
            {
              options: shortRowStyle,
              contents: [
                {
                  component: 'BodyText',
                  source: 'Show Materials Price Summary'
                }
              ]
            },
            {
              options: switchStyle,
              contents: [
                {
                  options: {
                    name: 'showMaterialsPriceSummary',
                    disableFn: values => !values?.showInventoryItems && !values?.showPurchasedItems
                  },
                  component: 'Switch',
                  source: 'showMaterialsPriceSummary'
                }
              ]
            }
          ]
        },
        {
          options: {
            ...sectionContainerStyle(theme),
            marginLeft: 112
          },
          contents: [
            {
              component: 'BodyText',
              source: 'Purchased Items',
              options: {
                ...headingTextStyle(theme)
              }
            }
          ]
        },
        {
          options: {
            direction: 'row',
            alignItems: 'center'
          },
          contents: [
            {
              options: shortRowStyle,
              contents: [
                {
                  component: 'BodyText',
                  source: 'Show Vendor'
                }
              ]
            },
            {
              options: switchStyle,
              contents: [
                {
                  options: {
                    name: 'showVendor',
                    disableFn: values => !values?.showPurchasedItems
                  },
                  component: 'Switch',
                  source: 'showVendor'
                }
              ]
            }
          ]
        },
        {
          options: {
            ...sectionContainerStyle(theme)
          },
          contents: [
            {
              component: 'BodyText',
              source: 'Photos',
              options: {
                ...headingTextStyle(theme)
              }
            }
          ]
        },
        {
          options: {
            direction: 'row',
            alignItems: 'center'
          },
          contents: [
            {
              options: rowStyle,
              contents: [
                {
                  component: 'BodyText',
                  source: 'Show Before & After Photos'
                }
              ]
            },
            {
              options: switchStyle,
              contents: [
                {
                  options: {
                    name: 'showBeforeAfterPhotos'
                  },
                  component: 'Switch',
                  source: 'showBeforeAfterPhotos'
                }
              ]
            }
          ]
        },
        {
          options: {
            ...sectionContainerStyle(theme),
            marginLeft: 112
          },
          contents: [
            {
              component: 'BodyText',
              source: 'Before & After Photos',
              options: {
                ...headingTextStyle(theme)
              }
            }
          ]
        },
        {
          options: {
            direction: 'row',
            alignItems: 'center'
          },
          contents: [
            {
              options: shortRowStyle,
              contents: [
                {
                  component: 'BodyText',
                  source: 'Show Date and Time'
                }
              ]
            },
            {
              options: switchStyle,
              contents: [
                {
                  options: {
                    name: 'showDateAndTimeForPhotos'
                  },
                  component: 'Switch',
                  source: 'showDateAndTimeForPhotos'
                }
              ]
            }
          ]
        },
        {
          options: {
            ...sectionContainerStyle(theme)
          },
          contents: [
            {
              component: 'BodyText',
              source: 'Totals',
              options: {
                ...headingTextStyle(theme)
              }
            }
          ]
        },
        {
          options: {
            direction: 'row',
            alignItems: 'center'
          },
          contents: [
            {
              options: rowStyle,
              contents: [
                {
                  component: 'BodyText',
                  source: 'Show Cost and Price Summary'
                }
              ]
            },
            {
              options: switchStyle,
              contents: [
                {
                  options: {
                    name: 'showCostAndPriceSummary',
                    disableFn: values =>
                      !values?.showMaterialsCostSummary &&
                      !values?.showMaterialsPriceSummary &&
                      !values?.showCostSummary &&
                      !values?.showPriceSummary
                  },
                  component: 'Switch',
                  source: 'showCostAndPriceSummary'
                }
              ]
            }
          ]
        },
        {
          options: {
            ...sectionContainerStyle(theme)
          },
          contents: [
            {
              component: 'BodyText',
              source: 'Customer Signature',
              options: {
                ...headingTextStyle(theme)
              }
            }
          ]
        },
        {
          options: {
            direction: 'row',
            alignItems: 'center'
          },
          contents: [
            {
              options: rowStyle,
              contents: [
                {
                  component: 'BodyText',
                  source: 'Show Customer Signature'
                }
              ]
            },
            {
              options: switchStyle,
              contents: [
                {
                  options: {
                    name: 'showCustomerSignature'
                  },
                  component: 'Switch',
                  source: 'showCustomerSignature'
                }
              ]
            }
          ]
        },
        {
          options: {
            ...sectionContainerStyle(theme)
          },
          contents: [
            {
              component: 'BodyText',
              source: 'Forms',
              options: {
                ...headingTextStyle(theme)
              }
            }
          ]
        },
        {
          options: {
            direction: 'row',
            alignItems: 'center'
          },
          contents: [
            {
              options: rowStyle,
              contents: [
                {
                  component: 'BodyText',
                  source: 'Show Job Forms'
                }
              ]
            },
            {
              options: switchStyle,
              contents: [
                {
                  options: {
                    name: 'showForms'
                  },
                  component: 'Switch',
                  source: 'showForms'
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row',
            alignItems: 'center'
          },
          contents: [
            {
              options: rowStyle,
              contents: [
                {
                  component: 'BodyText',
                  source: 'Show Task Forms'
                },
                {
                  component: 'TooltipInfo',
                  options: {
                    message: 'Only forms for completed tasks will be included'
                  }
                }
              ]
            },
            {
              options: switchStyle,
              contents: [
                {
                  options: {
                    name: 'showTaskForms'
                  },
                  component: 'Switch',
                  source: 'showTaskForms'
                }
              ]
            }
          ]
        }
      ].filter(Boolean)
    }
  }
});

export default reportPdfConfirm;
