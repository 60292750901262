const styles = theme => ({
  auditText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#959595',
    padding: '5px'
  },
  rateDescription: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#959595'
  },
  radioButtonLabel: {
    fontSize: 'larger',
    color: 'red'
  },
  button: {
    padding: 5,
    backgroundColor: 'black'
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    marginLeft: '5px',
    marginRight: '5px'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: theme.spacing(5)
    }
  },
  hourAbbreviation: {
    minWidth: 50
  },
  noEditableValue: {
    fontWeight: 'normal',
    fontSize: '16px',
    background: theme.palette.grayscale(96),
    margin: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    minWidth: 70,
    padding: '8px'
  },
  input: {
    fontWeight: 'normal',
    fontSize: '16px',
    padding: theme.spacing(1),
    background: theme.palette.grayscale(96),
    margin: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    appearance: 'none',
    '&:hover': {
      background: theme.palette.grayscale(90)
    },
    '& input': {
      padding: 0
    }
  },
  markupInput: {
    width: '100px',
    fontWeight: 'normal',
    fontSize: '16px',
    padding: theme.spacing(1),
    background: theme.palette.grayscale(90),
    margin: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    '&:hover': {
      background: theme.palette.grayscale(92)
    },
    '& input': {
      padding: 0
    }
  },
  subtitle: {
    fontSize: '10px',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  inputIcon: {
    color: theme.palette.grayscale(90)
  },
  dialogContent: {
    [theme.breakpoints.up('md')]: {
      minWidth: 550,
      maxWidth: 550
    }
  },
  container: {
    fontWeight: 'normal',
    fontSize: '16px',
    width: 110,
    padding: theme.spacing(1),
    background: theme.palette.grayscale(96),
    margin: `${theme.spacing(0.5)}px 0`,
    '&:hover': {
      background: theme.palette.grayscale(92)
    },
    '& input': {
      padding: 0,
      textAlign: 'right'
    }
  },
  markupLabel: {
    fontWeight: 'bold',
    fontSize: 22
  },
  icon: {
    color: theme.palette.grayscale(90)
  },
  justifyContentBetween: {
    justifyContent: 'space-between'
  },
  alignItemsCenter: {
    alignItems: 'center'
  },
  width150: {
    width: 150
  },
  width75: {
    width: 75
  },
  w100: {
    width: '100%'
  },
  width100: {
    width: 110
  },
  dFlex: {
    display: 'flex'
  },
  mb2: {
    marginBottom: '0.5rem'
  },
  mb3: {
    marginBottom: '1rem'
  },
  mb5: {
    marginBottom: '3rem'
  },
  mt2: {
    marginTop: '0.5rem'
  },
  ml2: {
    marginLeft: '0.5rem'
  },
  mr2: {
    marginRight: '0.5rem'
  },
  ml4: {
    marginLeft: '18.5rem'
  },
  handIcon: {
    cursor: 'pointer'
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  createPricebookToggleContainer: {
    width: '200px'
  },
  priceBookConfiguration: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '8px'
  }
});

export default styles;
