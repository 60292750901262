/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';
import { sentryException } from '../Logger/index';

export async function getFormsById(id) {
  return await instance
    .get(`forms/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get form');
      return [];
    });
}

export async function getPublishedProjectForms() {
  return await instance
    .get(`forms/project`, {
      params: { include: '*' }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to get form');
      return [];
    });
}
export async function formCreate(co) {
  return await instance
    .post('forms', co)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to create forms');
      return [];
    });
}

export async function formChange(id, co) {
  return await instance
    .put(`forms/${id}`, co)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to change forms');
      return [];
    });
}

export async function formDelete(id) {
  return await instance
    .delete(`forms/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to delete forms');
      return [];
    });
}
