import { makeStyles } from '@material-ui/core';
import { ElementSizes } from '@dispatch/Dispatch.styles';

export const useStyles = makeStyles(theme => ({
  button: {
    height: 36,
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textDecoration: 'none',
    justifyContent: 'space-between'
  },
  popOver: {
    display: 'flex',
    flexDirection: 'column',
    width: ElementSizes.filterPopoverWidth,
    padding: theme.spacing(3),
    '& .MuiChip-root': {
      maxWidth: 'calc(100% - 12px)'
    }
  }
}));
