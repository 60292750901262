import { makeStyles } from '@material-ui/core';
import { CONFIG } from '@dispatch/Dispatch.constants';
import techAvatarImg from '@dispatch/assets/images/avatar.png';

export const useStyles = makeStyles(theme => ({
  techPhoto: ({ profilePictureUrl }) => ({
    borderRadius: '100%',
    backgroundImage: `url('${
      profilePictureUrl ? CONFIG.CLOUDINARY_IMAGE_URL + profilePictureUrl : techAvatarImg
    }')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginRight: theme.spacing(1),
    flex: '0 0 40px',
    height: 40,
    width: 40
  }),
  techName: {
    lineHeight: '1.2em',
    maxHeight: '2.4em',
    overflow: 'hidden'
  }
}));
