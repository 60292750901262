import gql from 'graphql-tag';

export const getCompany = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      labourTypes {
        items {
          id
          name
          sortOrder
        }
      }
      billingHourTypes {
        items {
          id
          hourType
          hourTypeAbbreviation
          sortOrder
        }
      }
    }
  }
`;
