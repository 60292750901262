import { useExtendedFetchForPMAPI as useExtendedFetch } from 'customHooks/useExtendedFetch';

export const useFetchEmployees = options => {
  return useExtendedFetch(
    {
      url: `employees`,
      params: {
        include: 'departments'
      }
    },
    {
      errorData: [],
      defaultData: [],
      ...options
    }
  );
};
