import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    paddingBottom: theme.spacing(2)
  },
  searchWrapper: {
    display: 'flex'
  },
  icon: {
    marginRight: theme.spacing(0.5)
  },
  menu: {
    width: '210px'
  },
  itemWrapper: {
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  menuItem: {
    width: '100%',
    paddingBottom: 0,
    paddingTop: 0
  },
  itemHeading: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    textTransform: 'capitalize',
    fontSize: 12
  },
  filterIcon: {
    display: 'flex',
    padding: theme.spacing(0.57),
    background: theme.palette.grayscale(100),
    border: `1px solid ${theme.palette.grayscale(80)}`,
    borderLeft: theme.palette.grayscale(100),
    borderRadius: '1px'
  },
  checkbox: {
    width: 16,
    height: 16
  },
  filterActionWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  selected: {
    fill: theme.palette.grayscale(20),
    color: theme.palette.grayscale(20)
  },
  formControl: {
    fontSize: 12,
    width: '100%',
    marginRight: 0
  },
  filterAction: {
    paddingLeft: theme.spacing(0.2),
    paddingRight: theme.spacing(0.2),
    minWidth: '50px',
    fontSize: 12
  },
  popover: {
    width: '200px',
    maxHeight: '350px',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  },
  filterSummary: {
    display: 'flex',
    padding: theme.spacing(0.5),
    alignItems: 'center'
  },
  filterCount: {
    fontSize: 12,
    color: theme.palette.grayscale(60),
    paddingLeft: theme.spacing(1),
    minWidth: '120px'
  },
  resultCount: {
    color: theme.palette.grayscale(20),
    fontWeight: 700
  },
  clear: {
    fontSize: 12,
    cursor: 'pointer',
    paddingLeft: theme.spacing(1),
    color: theme.palette.grayscale(20)
  },
  chip: {
    fontSize: 12,
    background: theme.palette.grayscale(90),
    color: theme.palette.grayscale(20),
    margin: theme.spacing(0.25),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  algoliaInput: {
    marginBottom: 0
  }
}));
