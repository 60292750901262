import gql from 'graphql-tag';

const getCompanyDepartmentsAndEmployees = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      departments: departmentsView(
        entityConnection: "Department"
        filter: {
          integerFilters: [{ fieldName: "Department.deletedDate", filterInput: { is: null } }]
        }
      ) {
        items {
          id
          tagName
        }
      }
      employees(entityConnection: "Employee") {
        items {
          id
          name
          status
          isTech
          departments(entityConnection: "Department") {
            items {
              mappedEntity {
                ... on Department {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getCompanyDepartmentsAndEmployees;
