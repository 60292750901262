import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  selectionInput: {
    padding: theme.spacing(0.3),
    '& p': {
      fontSize: '14px',
      marginLeft: '5px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 'auto',
      color: theme.palette.text.primary,
      marginTop: '0px'
    }
  },
  selectionWrapper: {
    padding: 0,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    background: theme.palette.grayscale(98),
    '&:hover': {
      background: theme.palette.grayscale(92)
    },
    minWidth: 180
  },
  selectionInputPlaceholder: {
    '& label': {
      fontSize: '16px',
      color: theme.palette.text.primary
    }
  }
}));
