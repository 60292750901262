import gql from 'graphql-tag';
import useExtendedMutation from 'customHooks/useExtendedMutation';

const UPDATE_JOB_GQL = gql`
  mutation markJobDoNotInvoice($jobId: String!, $tenantId: String!) {
    markJobDoNotInvoice(jobId: $jobId, tenantId: $tenantId)
  }
`;

const serializer = ({ jobId, tenantId }) => ({
  variables: {
    jobId,
    tenantId
  }
});

const useMarkDoNotInvoice = () =>
  useExtendedMutation(UPDATE_JOB_GQL, {
    serializer
  });

export default useMarkDoNotInvoice;
