import gql from 'graphql-tag';

const getAttachmentsByCustomerId = gql`
  query getCustomer($partitionKey: String!, $sortKey: String!) {
    getCustomer(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      attachments(entityConnection: "Attachment") {
        items {
          fileName
          customFileName
          fileUrl
          id
          version
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          description
          comment
          createdDate
          createdDateTime
          createdBy
        }
      }
    }
  }
`;
export default getAttachmentsByCustomerId;
