import { withQueryStringStore } from '@dispatch/Dispatch.store';

import DayWeekSelector from './DayWeekSelector.component';

const mapQueryStringToProps = store => ({
  setWeekView: store.setWeekView,
  weekView: store.state.weekView
});

export default withQueryStringStore(mapQueryStringToProps)(DayWeekSelector);
