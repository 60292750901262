export const LABOUR_RATE_FIELDS = [
  'billingHourTypeId',
  'costCodeId',
  'labourTypeId',
  'revenueTypeId'
];

export const AGREEMENT_ACTION_BUTTONS = {
  approve: {
    label: 'Approve'
  },
  cancel: {
    label: 'Cancel'
  }
};

export const ERROR_MESSAGES = [
  'Min range value must be less than Max range value',
  'Please enter unique range values',
  'Value must be less than equal 100',
  'End date must be greater than start date'
];

export const MAX_FETCH_MORE = 3;
