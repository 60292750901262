const styles = theme => {
  const borderColor = theme.palette.grayscale(90);
  const detailLeftPadding = theme.spacing(2);
  const muiFormLeftPadding = theme.spacing(1.5);
  return {
    root: {
      display: 'flex',
      flexDirection: 'column'
    },
    body: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      borderTop: `1px solid ${borderColor}`
    },
    sidebar: {
      borderRight: `1px solid ${borderColor}`,
      [theme.breakpoints.down('xs')]: {
        width: '35%'
      },
      [theme.breakpoints.up('sm')]: {
        width: '25%'
      },
      [theme.breakpoints.up('md')]: {
        width: '240px'
      }
    },
    sidebarInner: {
      marginTop: theme.spacing(1.5),
      marginRight: theme.spacing(2)
    },
    detail: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: '1',
      overflow: 'auto',
      maxWidth: '85%',
      '& > *': {
        width: '100%',
        overflow: 'hidden'
      }
    },
    detailForm: {
      paddingBottom: theme.spacing(4),
      borderBottom: `1px solid ${borderColor}`,
      paddingLeft: detailLeftPadding - muiFormLeftPadding
    },
    tabsContainer: {
      minHeight: '400px',
      paddingLeft: detailLeftPadding,
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(4),
      borderBottom: `1px solid ${borderColor}`
    },
    activityContainer: {
      paddingTop: theme.spacing(1.5),
      paddingLeft: detailLeftPadding
    },
    activityTitle: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(2),
      letterSpacing: -1
    },
    statusChip: {
      backgroundColor: theme.palette.brand.green,
      color: theme.palette.grayscale(100),
      marginLeft: theme.spacing(0.5),
      borderRadius: 2,
      height: theme.spacing(3),
      marginRight: theme.spacing(2)
    }
  };
};

export default styles;
