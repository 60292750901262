import React, { useMemo } from 'react';
import { jsx } from '@emotion/react';
import { sumBy, round } from 'lodash';
import { Box, Table, TableHead, TableBody, TableCell, TableRow, useTheme } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import { ThemeProvider, Typography, TV, TW } from '@buildhero/sergeant';
import usePayrollHourTypes from 'customHooks/usePayrollHourTypes';
import { useJobCostingLabor } from './useJobCostingLabor';

const bordersStyles = (theme, isHeader) => ({
  borderStyle: 'solid',
  borderColor: theme.palette.grayscale(isHeader ? 80 : 90),
  borderWidth: 1
});

const headerCellStyle = theme => ({
  backgroundColor: `${theme.palette.grayscale(94)}`,
  borderColor: `${theme.palette.grayscale(80)}`,
  borderTopWidth: 1,
  paddingLeft: theme.spacing(1.5)
});

const cellTextStyle = theme => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: 700,
  fontSize: 14,
  padding: theme.spacing(2)
});

const LaborCostForJobTable = (hrTypes = []) => [
  {
    id: 'display',
    label: ' '
  },
  ...hrTypes.map(type => ({
    id: type.id,
    label: type.hourTypeAbbreviation,
    textAlign: 'center',
    skipForLaborTypeRow: true
  })),
  {
    id: 'totalHrs',
    label: 'Total hours',
    textAlign: 'center'
  }
];

export const JobCostingLabor = ({ user, jobId, snackbarOn }) => {
  const theme = useTheme();
  const [payrollHourTypes, , hrTypeLoading] = usePayrollHourTypes(
    user.tenantId,
    user.tenantCompanyId,
    snackbarOn
  );

  const [data, loading] = useJobCostingLabor({ user, jobId, snackbarOn });
  const tableMeta = useMemo(() => LaborCostForJobTable(payrollHourTypes), [payrollHourTypes]);
  const isLoading = loading || hrTypeLoading;

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      margin={[1, 0]}
      width="100%"
      overflow="scroll"
    >
      <div css={{ marginBottom: theme.spacing(1) }}>
        <ThemeProvider>
          <Typography variant={TV.BASE} caps weight={TW.BOLD}>
            Labor (Time tracking Report)
          </Typography>
        </ThemeProvider>
      </div>
      {isLoading ? (
        <Skeleton count={6} />
      ) : (
        <Table>
          <TableHead css={{ ...headerCellStyle(theme) }}>
            <TableRow css={{ ...bordersStyles(theme, true) }}>
              {tableMeta.map(col => (
                <TableCell
                  key={col.label}
                  css={{ ...cellTextStyle(theme), ...bordersStyles(theme, true) }}
                >
                  {col.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(rowData => (
              <TableRow
                css={{ ...bordersStyles(theme) }}
                key={`${rowData.display}-${rowData.employeeId || ''}`}
              >
                {tableMeta.map((col, colIndex) => {
                  const isHrTypeColumForLaborType =
                    rowData.isLaborTypeRow && col.skipForLaborTypeRow;
                  if (isHrTypeColumForLaborType) return null;
                  return (
                    <TableCell
                      key={`${col.id}-${rowData.labourTypeId || ''}-${rowData.employeeId ||
                        ''}-${rowData.hourTypeId || ''}`}
                      colspan={
                        colIndex === 0 && rowData.isLaborTypeRow
                          ? payrollHourTypes.length + 1
                          : null
                      }
                      css={{
                        ...bordersStyles(theme),
                        paddingLeft:
                          !rowData?.isLaborTypeRow && colIndex === 0
                            ? theme.spacing(4)
                            : theme.spacing(1),
                        textAlign: col?.textAlign || 'left'
                      }}
                    >
                      {!col?.type && rowData[col.id]}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
            <TableRow css={{ ...bordersStyles(theme) }}>
              <TableCell
                colspan={payrollHourTypes.length + 1}
                css={{
                  ...bordersStyles(theme),
                  paddingLeft: theme.spacing(1),
                  fontWeight: 700
                }}
              >
                Totals
              </TableCell>
              <TableCell
                css={{
                  ...bordersStyles(theme),
                  textAlign: 'center',
                  fontWeight: 700
                }}
              >
                {round(
                  sumBy(
                    data.filter(item => item.isLaborTypeRow),
                    'totalHrs'
                  ),
                  2
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </Box>
  );
};
