import { useCallback, useEffect, useState } from 'react';

export const useShowStickyHeader = ({
  headerRef,
  headerYOffset,
  headerHeight,
  stickyHeaderHeight
}) => {
  const [showStickyHeader, setShowStickyHeader] = useState(false);
  const handleScroll = useCallback(() => {
    const position = headerRef?.current?.getBoundingClientRect();
    if (position) {
      setShowStickyHeader(headerHeight - headerYOffset + position.y <= stickyHeaderHeight);
    }
  }, [setShowStickyHeader, headerRef, headerHeight, headerYOffset, stickyHeaderHeight]);
  useEffect(() => {
    document.addEventListener('scroll', handleScroll, true);
    return () => {
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);
  return showStickyHeader;
};
