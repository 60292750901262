import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@buildhero/sergeant';
import { convertToCurrencyString, roundCurrency } from 'utils';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
  costSummaryLabel: {
    minWidth: 100,
    fontWeight: 500,
    paddingBottom: '4px'
  },
  costSummaryValue: {
    fontSize: '14px',
    fontWeight: 700,
    float: 'right',
    alignSelf: 'flex-end'
  }
}));

const OverallTotalCostDisplay = (
  records = [],
  conditionCallback = () => true,
  totalCostKey = 'totalCost',
  conditionKey = 'status'
) => {
  const classes = useStyles();
  const [overallTotal, setOverallTotal] = useState();

  useEffect(() => {
    const totals = records.reduce((acc, curr) => {
      if (conditionCallback(curr[conditionKey])) {
        return acc + roundCurrency(curr[totalCostKey]);
      }
      return acc;
    }, 0);
    setOverallTotal(totals);
  }, [conditionCallback, conditionKey, records, totalCostKey]);

  return (
    <Box display="flex" justifyContent="flex-end">
      <Box display="flex" flexDirection="column">
        <Typography className={classes.costSummaryLabel} variant="subtitle1">
          OVERALL TOTAL COST
        </Typography>
        <Typography className={classes.costSummaryValue}>
          {convertToCurrencyString(overallTotal)}
        </Typography>
      </Box>
    </Box>
  );
};

export default OverallTotalCostDisplay;
