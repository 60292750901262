import gql from 'graphql-tag';

const getAllAttachmentsByJobNumber = gql`
  query getAllAttachmentsByJobNumber($jobNumber: String!) {
    getJobByJobNumber(jobNumber: $jobNumber) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      tenantId
      hierarchy
      entityType
      jobNumber
      visits: visitsView(entityConnection: "Visit") {
        items {
          visitNumber
          id
          reviewReports(entityConnection: "ReviewReport") {
            items {
              id
              parentId
            }
          }
        }
      }
      customIdentifier
      allAttachments(entityConnection: "Attachment") {
        items {
          fileName
          customFileName
          fileUrl
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          type
          entityType
          description
          comment
          createdDate
          createdDateTime
          lastUpdatedDateTime
          lastUpdatedBy
          parentId
          createdBy
          addedBy
          hideFromTechniciansOnMobile
        }
      }
    }
  }
`;

export default getAllAttachmentsByJobNumber;
