import gql from 'graphql-tag';

const getPricebookForCompany = gql`
  query getCompany(
    $partitionKey: String!
    $sortKey: String!
    $filter: GenericFilterInput
    $sort: [SortingInput]
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      priceBooks: priceBooksView(entityConnection: "PriceBook", filter: $filter, sorting: $sort) {
        items {
          id
          name
          description
          markupValue
          isActive
          markupType
          isDefault
        }
      }
    }
  }
`;

export default getPricebookForCompany;
