import { PermissionConstants, ManualTimesheetWorkTypes } from 'utils/AppConstants';

export const techNotes = [
  {
    id: 'subject',
    numeric: false,
    disablePadding: true,
    showLink: 'view',
    label: 'Subject'
  },
  {
    id: 'addedBy',
    numeric: false,
    disablePadding: true,
    label: 'Added by'
  },
  {
    id: 'createdDateTime',
    numeric: false,
    type: 'datetime',
    disablePadding: true,
    label: 'Date added'
  }
];

export const assetsRows = [
  {
    id: 'imageUrl',
    label: '',
    type: 'thumbnail'
  },
  {
    id: 'assetName',
    label: 'Name',
    linkPath: '/asset/view',
    linkReference: 'id',
    showLink: true
  },
  {
    id: 'make',
    label: 'Make'
  },
  {
    id: 'modelNumber',
    label: 'Model Number'
  },
  {
    id: 'serialNo',
    label: 'Serial Number'
  },
  {
    id: 'installDate',

    type: 'date',
    label: 'Install Date'
  },
  {
    id: 'location',
    label: 'location'
  },
  {
    id: 'propertyZone',
    label: 'Property Zone'
  }
];

export const attachmentsRows = [
  {
    id: 'fileUrl',
    numeric: false,
    disablePadding: true,
    label: '',
    type: 'thumbnail'
  },
  {
    id: 'displayType',
    numeric: false,
    disablePadding: true,
    label: 'Type'
  },
  {
    id: 'fileName',
    numeric: false,
    disablePadding: true,
    type: 'attachment',
    other: 'fileUrl',
    label: 'File name'
  },
  {
    id: 'addedBy',
    numeric: false,
    disablePadding: true,
    label: 'Added by'
  },
  {
    id: 'createdDate',
    numeric: false,
    type: 'date',
    disablePadding: true,
    label: 'Date added'
  },
  {
    id: 'comment',
    numeric: false,
    disablePadding: true,
    label: 'Comment'
  }
];

export const inventoryItemsRows = isMarginEnabled => [
  {
    id: 'addedBy',
    numeric: false,
    disablePadding: true,
    label: 'Added by'
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: true,
    label: 'Description',
    maxTextLen: 500,
    prewrap: true,
    headerStyle: {
      width: '25%',
      minWidth: 300
    }
  },
  {
    id: 'quantity',
    numeric: true,
    disablePadding: true,
    label: 'Qty'
  },
  {
    id: 'unitOfMeasure',
    numeric: true,
    disablePadding: true,
    label: 'UOM'
  },
  {
    id: 'unitCost',
    numeric: true,
    disablePadding: true,
    caslKey: PermissionConstants.DATA_VIEW_COST_DATA,
    caslAction: 'allow',
    type: 'currency',
    precision: 5,
    label: 'Unit cost'
  },
  isMarginEnabled
    ? {
        id: 'marginValue',
        numeric: true,
        disablePadding: true,
        caslKey: PermissionConstants.DATA_VIEW_PRICE_DATA,
        caslAction: 'allow',
        label: 'Margin',
        type: 'percentage'
      }
    : {
        id: 'markupValue',
        numeric: true,
        disablePadding: true,
        caslKey: PermissionConstants.DATA_VIEW_PRICE_DATA,
        caslAction: 'allow',
        label: 'Markup',
        type: 'percentage'
      },
  {
    id: 'unitPrice',
    numeric: true,
    disablePadding: true,
    caslKey: PermissionConstants.DATA_VIEW_PRICE_DATA,
    caslAction: 'allow',
    type: 'currency',
    label: 'Unit price'
  },
  {
    id: 'costCode',
    dataKey: 'label',
    isCustom: true,
    type: 'NestedProperty',
    disableFilter: true,
    label: 'Cost Code'
  },
  {
    id: 'jobCostType',
    dataKey: 'label',
    isCustom: true,
    type: 'NestedProperty',
    disableFilter: true,
    label: 'Cost Type'
  },
  {
    id: 'revenueType',
    dataKey: 'label',
    isCustom: true,
    type: 'NestedProperty',
    disableFilter: true,
    label: 'Revenue Type'
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: true,
    caslKey: PermissionConstants.DATA_VIEW_PRICE_DATA,
    caslAction: 'allow',
    type: 'currency',
    label: 'Amount'
  }
];

export const purchasedItemsRows = marginEnabled => [
  {
    id: 'itemName',
    numeric: false,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: true,
    label: 'Description'
  },
  {
    id: 'quantity',
    numeric: true,
    disablePadding: true,
    label: 'Quantity'
  },
  {
    id: 'unitCost',
    numeric: true,
    disablePadding: true,
    type: 'currency',
    label: 'Unit cost'
  },
  marginEnabled
    ? {
        id: 'marginValue',
        numeric: true,
        disablePadding: true,
        label: 'Margin',
        type: 'percentage'
      }
    : {
        id: 'markup',
        numeric: true,
        disablePadding: true,
        label: 'Markup',
        type: 'percentage'
      },
  {
    id: 'unitPrice',
    numeric: true,
    disablePadding: true,
    type: 'currency',
    label: 'Unit price'
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: true,
    type: 'currency',
    label: 'Amount'
  }
];

export const billItemsRows = marginEnabled => [
  {
    id: 'itemName',
    numeric: false,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: true,
    label: 'Description'
  },
  {
    id: 'quantity',
    numeric: true,
    disablePadding: true,
    label: 'Quantity'
  },
  {
    id: 'remainingQuantity',
    numeric: true,
    disablePadding: true,
    label: 'Quantity Not Yet Invoiced'
  },
  {
    id: 'unitCost',
    numeric: true,
    disablePadding: true,
    type: 'CustomCurrency',
    isCustom: true,
    label: 'Unit cost'
  },
  marginEnabled
    ? {
        id: 'marginValue',
        numeric: true,
        disablePadding: true,
        label: 'Margin',
        type: 'percentage'
      }
    : {
        id: 'markup',
        numeric: true,
        disablePadding: true,
        label: 'Markup',
        type: 'percentage'
      },
  {
    id: 'unitPrice',
    numeric: true,
    disablePadding: true,
    type: 'currency',
    label: 'Unit price'
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: true,
    type: 'currency',
    label: 'Amount'
  }
];

export const labourLineRows = [
  {
    id: 'itemName',
    numeric: false,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: true,
    label: 'Description'
  },
  {
    id: 'billableTimeInHours',
    numeric: true,
    disablePadding: true,
    label: 'Billing time'
  },
  {
    id: 'hourlyCharge',
    numeric: true,
    disablePadding: true,
    type: 'currency',
    label: 'Hourly Charge'
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: true,
    type: 'currency',
    label: 'Amount'
  }
];

export const discountRows = [
  {
    id: 'itemName',
    numeric: false,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'reasonForDiscount',
    numeric: false,
    disablePadding: true,
    label: 'Reason for discount'
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: true,
    type: 'currency',
    label: 'Amount'
  }
];

export const feeRows = [
  {
    id: 'itemName',
    numeric: false,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'reasonForFee',
    numeric: false,
    disablePadding: true,
    label: 'Reason for fee'
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: true,
    type: 'currency',
    label: 'Amount'
  }
];

export const timeTrackingRows = [
  {
    id: 'employeeName',
    numeric: false,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'startTime',
    numeric: false,
    disablePadding: true,
    label: 'Start',
    type: 'DateWithLocation',
    isCustom: true
  },
  {
    id: 'endTime',
    numeric: false,
    disablePadding: true,
    label: 'End',
    type: 'DateWithLocation',
    isCustom: true
  },
  {
    id: 'Travel',
    numeric: false,
    disablePadding: true,
    label: 'Travel'
  },
  {
    id: 'Work',
    numeric: false,
    disablePadding: true,
    label: 'Work'
  },
  {
    id: 'Break',
    numeric: false,
    disablePadding: true,
    label: 'Break'
  },
  {
    id: 'Pause',
    numeric: false,
    disablePadding: true,
    label: 'Pause'
  },
  {
    id: 'billableEvents',
    numeric: false,
    disablePadding: true,
    label: 'Billable-Non-Visit'
  }
];

export const manualTimeTrackingRows = [
  {
    id: 'createdBy',
    numeric: false,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'startTime',
    numeric: false,
    disablePadding: true,
    label: 'Start',
    type: 'DateWithLocation',
    isCustom: true
  },
  {
    id: 'endTime',
    numeric: false,
    disablePadding: true,
    label: 'End',
    type: 'DateWithLocation',
    isCustom: true
  },
  {
    id: ManualTimesheetWorkTypes.TRAVELING,
    numeric: false,
    disablePadding: true,
    label: 'Traveled'
  },
  {
    id: ManualTimesheetWorkTypes.WORKING,
    numeric: false,
    disablePadding: true,
    label: 'Worked'
  },
  {
    id: ManualTimesheetWorkTypes.ONABREAK,
    numeric: false,
    disablePadding: true,
    label: 'Break'
  },
  {
    id: ManualTimesheetWorkTypes.PAUSED,
    numeric: false,
    disablePadding: true,
    label: 'Pause'
  },
  {
    id: ManualTimesheetWorkTypes.NONVISIT,
    numeric: false,
    disablePadding: true,
    label: 'Billable-Non-Visit'
  }
];

export const technicianTimesheetNotes = [
  {
    id: 'createdBy',
    numeric: false,
    disablePadding: false,
    label: 'Name'
  },
  {
    id: 'subject',
    numeric: false,
    disablePadding: false,
    label: 'Note Subject'
  },
  {
    id: 'note',
    numeric: false,
    disablePadding: false,
    label: 'Note content',
    type: 'bigtext',
    maxTextLen: 54
  },
  {
    id: 'attachments',
    numeric: false,
    disablePadding: true,
    isCustom: true,
    type: 'AttachmentImages',
    label: 'Image'
  },
  {
    id: 'eventName',
    numeric: false,
    disablePadding: false,
    label: 'Event Type'
  }
];

export const manualTechnicianTimesheetNotes = [
  {
    id: 'lastUpdatedBy',
    numeric: false,
    disablePadding: false,
    label: 'Name'
  },
  {
    id: 'subject',
    numeric: false,
    disablePadding: false,
    label: 'Note Subject'
  },
  {
    id: 'note',
    numeric: false,
    disablePadding: false,
    label: 'Note content',
    type: 'bigtext',
    maxTextLen: 54
  },
  {
    id: 'attachments',
    numeric: false,
    disablePadding: true,
    isCustom: true,
    type: 'AttachmentImages',
    label: 'Image'
  },
  {
    id: 'eventName',
    numeric: false,
    disablePadding: true,
    label: 'Event type'
  }
];
