import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { snackbarOn } from 'redux/actions/globalActions';
import { Typography, Box, Button, Grid } from '@material-ui/core';
import useEmployees from 'customHooks/useEmployees';
import usePricebooks from 'customHooks/usePricebooks';
import AutoComplete from './CustomCell/AutoComplete';
import LineItemTable from './LineItemTable';
import { getReviewReport, updateReviewReport } from './service';

import { getLineItems } from './helpers';

import { defaultHourRateId, temporaryWorkMessageLabourRateSheetChange } from './constants';

const getLineItemsFromReport = report => report?.labourRateLineItems?.items || [];

const LabourLineItems = ({ reviewReportId, context, send, user, ...props }) => {
  const visitId = context?.visit?.id;
  const reportVersion = context?.version;

  const departmentName = context?.visit?.departmentName;
  const { tenantId, tenantCompanyId } = user;
  const serviceArgs = [tenantId, tenantCompanyId, props.snackbarOn];
  const [companyEmployees, employeesLoading] = useEmployees({
    includeBillingRate: true,
    includePayrollCost: true
  });
  const [pricebooks, priceBooksLoaded] = usePricebooks(...serviceArgs);
  const [reviewReport, setReviewReport] = useState();
  const [reportLoaded, setReportLoaded] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [overwriteRatesOnInitialize, setOverwriteRatesOnInitialize] = useState(false);
  const [numEntriesInLineItemTable, setNumEntriesInLineItemTable] = useState(0);
  const [hasEdits, setHasEdits] = useState(false);

  const [
    triggerReinitializeLineItemsInLineTable,
    setTriggerReinitializeLineItemsInLineTable
  ] = useState(false);

  const [
    triggerAddTimesheetEmployeesToLineItem,
    setTriggerAddTimesheetEmployeesToLineItem
  ] = useState(false);

  const fetchReport = useCallback(() => {
    const reviewReportSuccessCallback = response => {
      setReviewReport(response);
      // @TODO - discuss - current implementation of getReviewReport uses .finally to call success callback so it will get called even if it was unsuccessful
      setTriggerReinitializeLineItemsInLineTable(true);
      setReportLoaded(true);
    };
    setReportLoaded(false);
    getReviewReport(reviewReportId, reviewReportSuccessCallback, props.snackbarOn);
  }, [reviewReportId, props.snackbarOn]);

  useEffect(() => {
    if (!employeesLoading && priceBooksLoaded && isFirstLoad) {
      fetchReport();
      setIsFirstLoad(false);
    }
  }, [employeesLoading, priceBooksLoaded, isFirstLoad, fetchReport]);

  const handleReviewReportChange = (key, value) => {
    const itemsInDB = getLineItemsFromReport(reviewReport);
    if (hasEdits || numEntriesInLineItemTable !== itemsInDB.length) {
      return props.snackbarOn('error', temporaryWorkMessageLabourRateSheetChange);
    }

    setReportLoaded(false);
    const { id } = reviewReport;
    const info = { id, version: reportVersion, [key]: value };
    const successCallback = response => {
      send('UPDATE_REPORT_VERSION', { version: response.version });
      setReviewReport({ ...reviewReport, ...info });
      setOverwriteRatesOnInitialize(true);
      setTriggerReinitializeLineItemsInLineTable(true);
      setReportLoaded(true);
    };
    updateReviewReport(tenantId, info, successCallback, props.snackbarOn);
  };

  const reportEditable = !context.freezeEdit && reportLoaded;
  // Fetch priceBookId from the Review Report, then the
  // Job (reviewReport.parentEntity.parentEntity) or use
  // default Pricebook Id. If nothing exists it will use
  // the hour rate at defaultHourRateId.
  const priceBookId =
    reviewReport?.priceBookId ||
    reviewReport?.parentEntity?.parentEntity?.priceBookId ||
    context.defaultPriceBookId;

  return (
    <Box display="flex" pt={2} flexDirection="column">
      <Box display="flex" justifyContent="space-between">
        <Box display="block">
          <Button
            variant="contained"
            color="primary"
            disabled={!reportLoaded || !reportEditable}
            onClick={() => setTriggerAddTimesheetEmployeesToLineItem(true)}
          >
            Create labor line items based on technicians who worked
          </Button>
        </Box>
      </Box>

      <Grid container justify="space-between" alignItems="center">
        <Box display="flex">
          <Typography variant="body1">Select labor rate sheet:</Typography>
          <Box pl={1} display="flex">
            <AutoComplete
              options={[
                ...pricebooks,
                { id: defaultHourRateId, name: '(Default) Technician Labor Rate Sheet' }
              ]}
              name="priceBookId"
              isEditable={reportEditable}
              value={priceBookId || defaultHourRateId}
              handleOnChange={id => handleReviewReportChange('priceBookId', id)}
            />
          </Box>
        </Box>
      </Grid>
      <LineItemTable
        user={user}
        visitId={visitId}
        triggerAddTimesheetEmployeesToLineItem={triggerAddTimesheetEmployeesToLineItem}
        onAddTimesheetEmployeeToLineItemStart={() =>
          setTriggerAddTimesheetEmployeesToLineItem(false)
        }
        reinitialize={triggerReinitializeLineItemsInLineTable}
        onInitializeStart={() => {
          setTriggerReinitializeLineItemsInLineTable(false);
        }}
        onInitializeComplete={() => {
          setOverwriteRatesOnInitialize(false);
          setHasEdits(false);
        }}
        fetchReport={fetchReport}
        data={getLineItems(
          getLineItemsFromReport(reviewReport).sort((a, b) => a.createdDate - b.createdDate)
        )}
        onEdit={() => setHasEdits(true)}
        snackbarOn={props.snackbarOn}
        employees={companyEmployees}
        reviewReportId={reviewReportId}
        reportEditable={reportEditable}
        departmentName={departmentName}
        isDefaultRate={priceBookId === defaultHourRateId}
        priceBookId={priceBookId}
        timeCardLines={context.timeCardLines}
        isLoading={!reportLoaded}
        setReportLoaded={setReportLoaded}
        overwriteRatesOnInitialize={overwriteRatesOnInitialize}
        handleReviewReportRateSheetChange={handleReviewReportChange}
        onChangeNumLineItems={setNumEntriesInLineItemTable}
      />
    </Box>
  );
};

const mapStateToProps = ({ user }) => ({
  user
});
const mapDispatcherToProps = { snackbarOn };

const connectedLabourLineItems = connect(mapStateToProps, mapDispatcherToProps)(LabourLineItems);

export default connectedLabourLineItems;
