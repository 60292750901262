import gql from 'graphql-tag';
import useExtendedMutation from 'customHooks/useExtendedMutation';

const UPDATE_LABOUR_RATE_BILLING_HOUR_LINE = gql`
  mutation updateLabourRateBillingHourLine(
    $partitionKey: String
    $data: UpdateLabourRateBillingHourLineInput!
  ) {
    updateLabourRateBillingHourLine(partitionKey: $partitionKey, data: $data) {
      id
      version
      rate
      productId
      hourTypeId
      totalHours
      costCodeId
      revenueTypeId
      timesheetEntryId
    }
  }
`;

const serializer = ({
  tenantId,
  hourLineId,
  version,
  rate,
  totalHours,
  hourTypeId,
  productId
}) => ({
  variables: {
    partitionKey: tenantId,
    data: {
      id: hourLineId,
      version,
      rate,
      totalHours,
      hourTypeId,
      productId
    }
  }
});

export const useUpdateLabourRateBillingHourLine = () => {
  return useExtendedMutation(UPDATE_LABOUR_RATE_BILLING_HOUR_LINE, {
    serializer
  });
};
