import { EnumType } from 'utils/constants';

const applicationCell = {
  id: 'amountToApply',
  type: 'ApplicationCell',
  label: 'Amount to Apply',
  isCustom: true,
  headerStyle: {
    width: 163
  },
  cellStyle: {
    padding: 0
  },
  disableSort: true
};

export const PaymentColumns = [
  {
    id: 'paymentNumber',
    label: 'Payment',
    showLink: 'view',
    linkPath: '/payments/view',
    linkReference: 'id'
  },
  {
    id: 'paymentStatus',
    type: 'enum',
    label: 'Status',
    enumType: EnumType.PAYMENT_STATUS
  },
  {
    id: 'exportStatus',
    type: 'enum',
    label: 'Accounting Status',
    enumType: EnumType.EXPORT_STATUS
  },
  {
    id: 'paymentInvoices',
    label: 'Invoices',
    type: 'LinkList',
    itemLabel: 'Invoice ',
    itemLabelReference: 'invoice.invoiceNumber',
    linkPath: '/invoice/view',
    linkReference: 'invoice.id',
    moreLinkReference: 'id',
    moreLinkPath: '/payments/view',
    disableSort: true
  },
  {
    id: 'paymentType.name',
    label: 'Payment Type'
  },
  {
    id: 'paymentAmount',
    type: 'currency',
    label: 'Amount',
    numeric: true,
    bold: true
  },
  {
    id: 'unappliedAmount',
    type: 'currency',
    label: 'Unapplied Amount',
    numeric: true,
    bold: true
  },
  {
    id: 'adjustedBalance',
    type: 'balance',
    label: 'Adjusted Unapplied Amount',
    numeric: true,
    bold: true
  },
  applicationCell
];

export const InvoiceColumns = [
  {
    id: 'invoiceNumber',
    label: 'Invoice',
    showLink: 'view',
    linkPath: '/invoice/view',
    linkReference: 'id'
  },
  {
    id: 'jobNumber',
    label: 'Job',
    showLink: 'view',
    linkPath: '/job/view',
    linkReference: 'job.jobNumber'
  },
  {
    id: 'customerProperty.companyName',
    label: 'Property',
    showLink: 'view',
    linkPath: '/property/view',
    linkReference: 'customerProperty.id'
  },
  {
    id: 'dueDate',
    type: 'dateOnly',
    label: 'Due'
  },
  {
    id: 'issuedDate',
    type: 'dateOnly',
    label: 'Issued'
  },
  {
    id: 'totalAmount',
    type: 'currency',
    label: 'Amount',
    numeric: true,
    bold: true
  },
  {
    id: 'balance',
    type: 'balance',
    label: 'Balance',
    numeric: true,
    bold: true
  },
  {
    id: 'adjustedBalance',
    type: 'balance',
    label: 'Adjusted Balance',
    numeric: true,
    bold: true
  },
  applicationCell
];
