import gql from 'graphql-tag';

const deleteDiscount = gql`
  mutation deleteDiscount($partitionKey: String!, $id: String!) {
    deleteDiscount(partitionKey: $partitionKey, id: $id) {
      id
      sortKey
    }
  }
`;

export default deleteDiscount;
