import gql from 'graphql-tag';

const deleteJobJobTag = gql`
  mutation deleteJobJobTag($partitionKey: String!, $id: String!) {
    deleteJobJobTag(partitionKey: $partitionKey, id: $id) {
      id
      jobTag {
        id
        tagType
        tagName
      }
    }
  }
`;

export default deleteJobJobTag;
