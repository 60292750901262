import { any, bool, object } from 'prop-types';

export const reviewReportPropTypes = {
  reviewReport: object.isRequired,
  loading: bool.isRequired,
  error: any
};
export const reviewReportDefaultProps = {
  error: undefined
};
