import React, { useEffect, useReducer, useState } from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { UserPermission } from 'components';
import ResponsiveTable from 'components/ResponsiveTable';
import Spinner from 'components/Spinners/CircularIndeterminate';
import withMultipleForms from 'components/WithMultipleForms';
import rbDetailTableMeta from 'meta/Procurement/PurchaseOrders/rbDetailTableMeta';
import ErrorBoundaries from 'scenes/Error';
import ReceiptBillDetailFrame from 'scenes/Procurement/component/ReceiptBillDetailFrame';
import {
  getEntireStatus,
  getFormattedPOLinesData,
  getJobOrProjectLink,
  getPurchaseOrderSideBarShipping,
  getReceiptSideBarVendor,
  GOOGLE_MAPS_BASE_URL
} from 'scenes/Procurement/component/utils';
import { getBillById } from 'services/API/bill';
import { getBillLinesByBillId } from 'services/API/billLine';
import { getPaymentTermById } from 'services/API/paymentTerm';
import { getPurchaseOrderById } from 'services/API/purchaseOrder';
import { roundCurrency, roundFloat } from 'utils';
import { GOOGLE_MAPS_API_KEY, PermissionConstants } from 'utils/AppConstants';

import PurchaseOrderCost from '../../component/PurchaseOrderCost';

const useStyles = makeStyles(() => ({
  pocContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16
  },
  customCurrency: {
    fontSize: '0.9em',
    fontWeight: '700',
    textAlign: 'right',
    alignSelf: 'right'
  }
}));

const initialState = {
  sidebarVendor: {},
  sidebarShipping: {},
  cost: {}
};

const CustomCurrency = valueProps => {
  const classes = useStyles();
  const { record, meta } = valueProps;
  return <Typography className={classes.customCurrency}>${roundFloat(record[meta.id])}</Typography>;
};

const billFrameDataReducer = (state, action) => {
  switch (action.type) {
    case 'updateAll':
      return {
        ...state,
        ...action.payload,
        sidebarVendor: {
          ...state.sidebarVendor,
          ...getReceiptSideBarVendor(action.mode, action.payload)
        },
        sidebarShipping: {
          ...state.sidebarShipping,
          ...getPurchaseOrderSideBarShipping(action.mode, action.payload?.PurchaseOrder)
        }
      };
    case 'updateTopbar':
      return {
        ...state,
        ...action.payload
      };
    case 'updateSidebarVendor':
      return { ...state, sidebarVendor: { ...state.sidebarVendor, ...action.payload } };
    case 'updateSidebarShipping':
      return { ...state, sidebarShipping: { ...state.sidebarShipping, ...action.payload } };
    case 'initialize':
      return { topbar: {}, sidebar: {}, cost: {} };
    default:
      return state;
  }
};

const BillsDetail = props => {
  // withMultipleForms HOC props
  const {
    getHandleCreateService,
    getHandleComplete,
    handleSubmitStart,
    setOnSubmitFinal,
    isSubmitting
  } = props;
  const isVistaEnabled = useSelector(state => state.settings.isVistaEnabled);
  const { billId, mode } = props.computedMatch?.params || {};
  const classes = useStyles();
  const history = useHistory();
  const [billFrameState, dispatchBillFrameData] = useReducer(billFrameDataReducer, initialState);
  const [billItems, setBillItems] = useState([]);
  const [bill, setBill] = useState();
  const [loaded, setLoaded] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState({});

  useEffect(() => {
    if (!billId) return;
    setLoaded(false);
    async function getBill() {
      const result = await getBillById(billId);
      const billLines = await getBillLinesByBillId(billId);
      const paymentTerm = await getPaymentTermById(result.PurchaseOrderReceipt?.paymentTermId);
      getPurchaseOrderById(result.PurchaseOrder?.id).then(po => {
        if (po?.poType?.id) {
          result.poType = po.poType;
        }
        if (po?.assignedTo?.id) {
          result.PurchaseOrder.assignedTo = po?.assignedTo;
        }
        if (po?.shipToEmployee?.id) {
          result.PurchaseOrder.shipToEmployee = po?.shipToEmployee;
        }
      });
      if (paymentTerm && result) result.paymentTerm = paymentTerm;
      setBillItems(
        getFormattedPOLinesData(
          billLines
            ?.sort((a, b) => a?.lineNumber - b?.lineNumber)
            .map(line => {
              return { ...line };
            }) || []
        )
      );
      setPurchaseOrder(result.PurchaseOrder);
      setBill(result);
      setLoaded(true);
    }

    getBill();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bill) {
      getPurchaseOrderById(bill.PurchaseOrder.id || bill.purchaseOrderId).then(po => {
        dispatchBillFrameData({
          type: 'updateAll',
          payload: {
            ...bill,
            ...getJobOrProjectLink(bill),
            poType: po.poType
          },
          mode
        });
      });
    }
  }, [bill, mode]);

  // TODO: send final data to server using API
  // parameter: async data => await
  const handleOnComplete = () => {
    history.push(`/procurement/receipts-bills/bill/view/${billId}`);
  };

  return (
    <ErrorBoundaries>
      <UserPermission I="read" action={PermissionConstants.OBJECT_PURCHASE_ORDER}>
        <ReceiptBillDetailFrame
          googleMapURL={GOOGLE_MAPS_BASE_URL + GOOGLE_MAPS_API_KEY}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          id={billId}
          type="Bill"
          rbData={billFrameState}
          mode={mode === 'view' ? 'default' : 'edit'}
          status={getEntireStatus(purchaseOrder.status)}
          getHandleCreateService={getHandleCreateService}
          getHandleComplete={getHandleComplete}
          handleSubmitStart={handleSubmitStart}
          setOnSubmitFinal={setOnSubmitFinal}
          isSubmitting={isSubmitting}
          handleFormsSubmit={handleOnComplete}
          frameDataReducer={dispatchBillFrameData}
          isLoading={loaded}
        >
          <ResponsiveTable
            fullScreen
            rowMetadata={rbDetailTableMeta(isVistaEnabled)}
            data={billItems}
            noDataMsg={loaded ? 'No Bills' : <Spinner />}
            disableFilter
            defaults={{
              sortBy: 'itemNumber',
              sortOrder: 'asc'
            }}
            customCellComponents={{
              CustomCurrency
            }}
          />
          <div className={classes.pocContainer}>
            <PurchaseOrderCost
              formLayout={mode === 'view' ? 'default' : 'edit'}
              freightCosts={roundCurrency(billFrameState.freight) || 0}
              taxRateId={billFrameState.taxRateId || ''}
              taxRate={billFrameState.TaxRate}
              lineItems={billItems}
              onFormChange={() => {}}
              getHandleCreateService={getHandleCreateService}
              getHandleComplete={getHandleComplete}
              useMultipleForm
            />
          </div>
        </ReceiptBillDetailFrame>
      </UserPermission>
    </ErrorBoundaries>
  );
};

BillsDetail.propTypes = {
  getHandleCreateService: PropTypes.func.isRequired,
  getHandleComplete: PropTypes.func.isRequired,
  handleSubmitStart: PropTypes.func.isRequired,
  setOnSubmitFinal: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  computedMatch: PropTypes.object.isRequired
};

const MultipleFormsPurchaseOrderDetail = withMultipleForms(BillsDetail, [
  'sidebarVendor',
  'sidebarShipping',
  'mainTop',
  'purchaseOrderCost'
]);
export default MultipleFormsPurchaseOrderDetail;
