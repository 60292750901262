import gql from 'graphql-tag';

const addPurchaseOrderToReviewReport = gql`
  mutation AddPurchaseOrderToReviewReport(
    $partitionKey: String
    $data: AddPurchaseOrderToReviewReportInput!
  ) {
    addPurchaseOrderToReviewReport(partitionKey: $partitionKey, data: $data) {
      id
      sortKey
      vendorName
      receiptNumber
      poNumber
      dateOfPurchase
      totalAmountPreTax
      departmentName
      tax
      addedBy
      createdBy
      version
      purchaseOrderReceipts(entityConnection: "PurchaseOrderReceipt") {
        items {
          id
          imageUrl
          fileName
          version
        }
      }
    }
  }
`;

export default addPurchaseOrderToReviewReport;
