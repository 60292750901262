import React, { Component } from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import BrokenImage from '@material-ui/icons/BrokenImage';
import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';
import ErrorBoundaries from 'scenes/Error';
import { getFileExtension, isCloudinaryVideoType } from 'utils';
import { Spinner } from '..';

/**
 * Renders images in a Thumbnail view. On click of image , navigates to fullScreen component
 * labels are fetched from application level
 * locale of the user is referred from user context
 */
class Thumbnail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: '',
      imageURL: '',
      mediaType: ''
    };
  }

  getImage = async image => {
    const { fileUrl } = image;

    let imageUrl;
    try {
      const storageService = new StorageService();
      const fileExt = getFileExtension(fileUrl);
      const isVideo = isCloudinaryVideoType(fileExt);
      imageUrl = await storageService.getFile(fileUrl);
      const mediaType = isVideo ? 'video' : 'img';
      this.setState({
        imageURL: imageUrl,
        image,
        mediaType
      });
    } catch (error) {
      this.setState({
        imageURL: null
      });
      Logger.info(`Error uploading image ${error}`);
    }
  };

  render() {
    const { image, height, style } = this.props;
    const { imageURL, mediaType } = this.state;

    if (image.fileUrl !== this.state.image.fileUrl) {
      this.getImage(image);
    }

    return (
      <ErrorBoundaries>
        {imageURL && (
          <CardMedia
            component={mediaType}
            alt={image.name}
            height={height || 'auto'}
            style={style}
            src={imageURL}
            title={image.name}
            controls
            autostart={0}
          />
        )}
        {imageURL === '' && <Spinner />}
        {imageURL === null && <BrokenImage style={{ fontSize: 42 }} />}
      </ErrorBoundaries>
    );
  }
}

export default Thumbnail;
