const styles = theme => ({
  textField: {
    fontFamily: theme.typography.fontFamily,
    width: 233,
    marginRight: 25,
    marginTop: 2
  },
  infoIcon: {
    fontSize: 16,
    marginRight: 10,
    marginTop: 30,
    color: theme.palette.brand.logoBlue,
    verticalAlign: 'bottom',
    marginBottom: 25
  },
  labelStyle: {
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 'normal'
  }
});

export default styles;
