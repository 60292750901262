import * as Yup from 'yup';

export const truckTemplateItem = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  minQuantity: Yup.number().required('Minimum quantity is required'),
  maxQuantity: Yup.number().required('Maximum quantity is required')
});

export const taskTemplateItem = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  quantity: Yup.number().required('Quantity is required'),
  unitCost: Yup.number().required('Cost is required')
});

export default truckTemplateItem;
