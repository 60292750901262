import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import { Tasks } from 'components';
import { useLazyFetchPricebookEntry } from 'components/Tasks/components/useLazyFetchPricebookEntry';
import { snackbarOn } from 'redux/actions/globalActions';
import { Mode } from 'utils/constants';

const formatTasksInitialData = ({ mode, data }) => {
  // data.maintenanceTaskTemplates may be set for Mode.ADD if user naviagated away <-> back to the step.
  if (mode === Mode.ADD && !data.maintenanceTaskTemplates) return [];

  return data.maintenanceTaskTemplates
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .reduce((acc, template) => {
      return template.assetId
        ? acc
        : [
            ...acc,
            {
              id: template.id,
              name: template.maintenanceTaskName,
              description: template.maintenanceTaskDescription,
              partsAndMaterials:
                template.maintenanceTaskRequiredParts?.items?.map(part => ({
                  id: part.id,
                  label: part.pricebookEntry?.product?.name,
                  value: {
                    pricebookEntryId: part.pricebookEntryId,
                    name: part.pricebookEntry?.product?.name,
                    code: part.pricebookEntry?.product?.code,
                    description: part.priceBookEntryDescription,
                    quantity: part.quantity
                  }
                })) ?? [],
              forms:
                template.maintenanceTaskForms?.items?.map(form => ({
                  id: form.formId,
                  label: form.form.name,
                  value: {
                    required: form.isRequired
                  }
                })) ?? []
            }
          ];
    }, []);
};

const formatAssetTasksInitialData = ({ mode, data }) => {
  // data.maintenanceTaskTemplates may be set for Mode.ADD if user naviagated away <-> back to the step.
  if (mode === Mode.ADD && !data.maintenanceTaskTemplates) return {};

  return data.maintenanceTaskTemplates
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .reduce((acc, template) => {
      if (!template.assetId) return acc;
      const task = {
        id: template.id,
        name: template.maintenanceTaskName,
        description: template.maintenanceTaskDescription,
        assetId: template.assetId,
        assetTypeId: template.assetTypeId,
        partsAndMaterials:
          template.maintenanceTaskRequiredParts?.items?.map(part => ({
            id: part.id,
            label: part.pricebookEntry?.product?.name,
            group: template.asset.maintenanceTaskRequiredParts?.items.find(
              p => p.pricebookEntryId === part.pricebookEntryId
            )
              ? 'Associated Parts & Materials'
              : undefined,
            value: {
              pricebookEntryId: part.pricebookEntryId,
              name: part.pricebookEntry?.product?.name,
              code: part.pricebookEntry?.product?.code,
              description: part.priceBookEntryDescription,
              quantity: part.quantity
            }
          })) ?? [],
        forms:
          template.maintenanceTaskForms?.items?.map(form => ({
            id: form.formId,
            label: form.form.name,
            value: {
              required: form.isRequired
            }
          })) ?? []
      };
      return {
        ...acc,
        [template.assetId]: [...(acc[template.assetId] ?? []), task]
      };
    }, {});
};

const formatTasksSubmitData = ({ tasks, mode }) => {
  let sortOrder = 0;
  return tasks.map(task => {
    const template = {
      id: mode === Mode.EDIT ? task.id : undefined,
      maintenanceTaskName: task.name,
      maintenanceTaskDescription: task.description ?? '',
      maintenanceTaskRequiredParts: {
        items:
          task.partsAndMaterials?.map(part => ({
            quantity: part.value.quantity,
            pricebookEntryId: part.value.pricebookEntryId,
            // pricebookEntryName: part.value.name - BE does not record pricebookEntryName
            priceBookEntryDescription: part.value.description,
            id: part.id, // .id prop removed before hittin gql
            pricebookEntry: {
              // .pricebookEntry prop removed before hitting gql.
              product: { name: part.label, code: part.value.code }
            }
          })) ?? []
      },
      maintenanceTaskForms: {
        // .items prop flattened before hitting gql
        items:
          task.forms?.map(form => ({
            formId: form.id,
            isRequired: form.value.required,
            form: { name: form.label } // .form prop removed before hitting gql
          })) ?? []
      },
      sortOrder
    };
    sortOrder += 1;
    return template;
  });
};

const formatAssetTasksSubmitData = ({ assetTasks, assetMap, mode, pricebookEntries }) => {
  let sortOrder = 0;

  return Object.keys(assetTasks).reduce((acc, assetId) => {
    const formattedAssetTasks = assetTasks[assetId].map(task => {
      const assetTask = {
        id: mode === Mode.EDIT ? task.id : undefined,
        assetId,
        assetTypeId: assetMap[assetId].assetTypeId,
        asset: assetMap[assetId], // asset prop removed before hitting gql
        maintenanceTaskName: task.name,
        maintenanceTaskDescription: task.description ?? '',
        maintenanceTaskRequiredParts: {
          // .items prop flattened before hitting gql
          items:
            task.partsAndMaterials?.map(part => {
              let { pricebookEntryId } = part.value;
              if (!pricebookEntryId) {
                pricebookEntryId = pricebookEntries.find(
                  entry => entry.productSortKey === part.value.productSortKey
                )?.id;
              }
              return {
                quantity: part.value.quantity,
                pricebookEntryId,
                // pricebookEntryName: part.value.name - BE does not record pricebookEntryName - to be fixed in BUOP-10647
                priceBookEntryDescription: part.value.description,
                id: part.id, // .id prop removed before hittin gql
                pricebookEntry: {
                  // .pricebookEntry prop removed before hitting gql.
                  product: { name: part.label, code: part.value.code }
                }
              };
            }) ?? []
        },
        maintenanceTaskForms: {
          // .items prop flattened before hitting gql
          items:
            task.forms?.map(form => ({
              formId: form.id,
              isRequired: form.value.required,
              form: { name: form.label } // .form prop removed before hitting gql
            })) ?? []
        },
        sortOrder
      };
      sortOrder += 1;
      return assetTask;
    });
    return [...acc, ...formattedAssetTasks];
  }, []);
};

const AddTasksStep = ({
  data,
  handleFormService,
  handleOnCompleteForm,
  snackbarOn: snackbar,
  stepIndex,
  isLastStep
}) => {
  const mode = data?.id ? Mode.EDIT : Mode.ADD;

  const fetchPricebookEntry = useLazyFetchPricebookEntry();
  const defaultPriceBookId = useSelector(s => s.company.defaultPriceBookId);

  const initialTasks = useMemo(() => formatTasksInitialData({ mode, data }), []);
  const initialAssetTasks = useMemo(() => formatAssetTasksInitialData({ mode, data }), []);

  return (
    <>
      <Tasks
        propertyId={data.propertyId}
        assetTasks={initialAssetTasks}
        tasks={initialTasks}
        tasksTitle="Tasks"
        onSubmit={async ({ assetTasks, tasks, assetMap }) => {
          const combinedTasks = Object.values(assetTasks || {}).flat();
          const promises = combinedTasks.map(tsk =>
            tsk.partsAndMaterials
              ?.map(part => {
                if (part.value?.productSortKey && !part.value?.priceBookEntryId) {
                  return fetchPricebookEntry({
                    pricebookId: defaultPriceBookId,
                    productSortKey: part.value?.productSortKey
                  });
                }
                return false;
              })
              .filter(Boolean)
          );
          const pricebookEntries = await Promise.all(promises.flat());
          const formValues = {
            maintenanceTaskTemplates: [
              ...formatAssetTasksSubmitData({
                assetTasks,
                assetMap,
                mode,
                pricebookEntries
              }),
              ...formatTasksSubmitData({ tasks, mode })
            ]
          };

          handleOnCompleteForm(formValues, isLastStep);
        }}
        setSubmitService={service => handleFormService(service, stepIndex)}
      />
    </>
  );
};

AddTasksStep.propTypes = {
  data: PropTypes.object.isRequired,
  handleFormService: PropTypes.func.isRequired,
  handleOnCompleteForm: PropTypes.func.isRequired,
  stepIndex: PropTypes.number.isRequired,
  isLastStep: PropTypes.bool,
  snackbarOn: PropTypes.func.isRequired
};

AddTasksStep.defaultProps = {
  isLastStep: true
};

const mapStateToProps = state => ({
  user: state.user
});
const mapDispatcherToProps = { snackbarOn };

export default connect(mapStateToProps, mapDispatcherToProps)(AddTasksStep);
