import React, { useState } from 'react';

import { SgtForm } from '@buildhero/sergeant';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import layout from './AttachmentsForJob.layout';

const SELECT_ALL = 'selectAll';

const AttachmentsForJob = ({
  handleSubmit,
  isCustomJobNumberEnabled,
  setFormService,
  quoteAttachments
}) => {
  const [allSelected, setAllSelected] = useState(false);

  const CustomCheckbox = ({ field, form, props }) => {
    const composeAttachmentsWithSelectAllValue = selectAllValue => {
      const newFormValues = form.values.attachments.map(item => ({
        ...item,
        checked: selectAllValue
      }));
      form.setValues({ ...form.values, attachments: newFormValues, selectAll: selectAllValue });
      setAllSelected(selectAllValue);
    };

    const handleFormChange = evt => {
      if (field.name === SELECT_ALL && evt.target.checked) {
        composeAttachmentsWithSelectAllValue(true);
      } else if (field.name === SELECT_ALL) {
        composeAttachmentsWithSelectAllValue(false);
      } else if (field.name !== SELECT_ALL) {
        form.setFieldValue(field.name, { ...field.value, checked: evt.target.checked });
        if (!evt?.target.checked) {
          form.setFieldValue(SELECT_ALL, false);
          setAllSelected(false);
        }
      }
    };

    const checked = field.name === SELECT_ALL ? field.value : field.value.checked;
    return (
      <FormControlLabel
        control={<Checkbox checked={checked || allSelected} />}
        label={props.label}
        value={props.label}
        labelPlacement="end"
        onChange={handleFormChange}
      />
    );
  };

  return (
    <SgtForm
      layout="default"
      configuration={layout(quoteAttachments, isCustomJobNumberEnabled)}
      customComponents={{ CustomCheckbox }}
      initialValues={{
        jobNumber: '',
        selectAll: false,
        attachments: quoteAttachments.items
      }}
      onCreateService={setFormService}
      onSubmit={handleSubmit}
    />
  );
};

export default AttachmentsForJob;
