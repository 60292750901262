import gql from 'graphql-tag';

const getQuotes = gql`
  query getQuoteList(
    $tenantId: String
    $filter: TableFilterInput
    $pagination: PaginationInput
    $sorting: [TableSortingInput]
  ) {
    data: getQuoteList(
      tenantId: $tenantId
      filter: $filter
      pagination: $pagination
      sorting: $sorting
    ) {
      rowCount
      items
    }
  }
`;

export default getQuotes;
