import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import {
  Divider,
  MoreButton,
  Field,
  FieldType,
  CurrencyInput,
  ThemeProvider
} from '@buildhero/sergeant';
import { Mode } from 'utils/constants';
import { Logger } from 'services/Logger';
import Spinner from 'components/Spinners/CircularIndeterminate';
import { debounce } from 'lodash';
import { SettingConstants } from 'scenes/Quotes/constants';
import { overridesAreEnabled } from 'scenes/Quotes/utils/helper';
import useStyles from '../Tasks.styles';
import { updateTasksAndGroups } from './TaskGroup.utils';

const TaskGroup = ({
  config,
  deleteTaskGroup,
  item,
  setIsTaskGroupModalOpen,
  setCheckedTasks,
  setTaskGroups,
  updateTaskGroup,
  quoteId
}) => {
  const classes = useStyles();
  const [isDeleting, setIsDeleting] = useState({ bool: false, id: '' });

  const deleteItem = async () => {
    setIsDeleting({ bool: true, id: item.id });
    try {
      const response = await deleteTaskGroup(item.id);
      if (response) {
        updateTasksAndGroups({
          groups: response.quoteTransition.quoteTaskGroups.items,
          tasks: response.quoteTransition.quoteLineTasks.items,
          setTaskGroups
        });
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsDeleting({ bool: false, id: '' });
    }
  };

  const handleGroupTotalChange = debounce(async value => {
    try {
      const newItem = { ...item, totalAmountOverride: value || 0 };
      const taskIds = newItem.tasks.map(task => task.id);
      const response = await updateTaskGroup(newItem, taskIds);
      updateTasksAndGroups({
        groups: response.quoteTransition.quoteTaskGroups.items,
        tasks: response.quoteTransition.quoteLineTasks.items,
        setTaskGroups
      });
    } catch (error) {
      Logger.error(error);
    }
  }, 1500);

  const isGroupNameStriped = !config?.[SettingConstants.TASK_GROUPS]?.[
    SettingConstants.SHOW_TASK_GROUP_TITLE
  ];
  const isGroupPriceStriped = !config?.[SettingConstants.TASK_GROUPS]?.[
    SettingConstants.SHOW_TASK_GROUP_PRICING
  ];

  const taskGroupPricingInput = useMemo(
    () => (
      <CurrencyInput
        striped={isGroupPriceStriped}
        value={item.totalAmountOverride ?? item.totalAmount}
        onChange={handleGroupTotalChange}
      />
    ),
    [isGroupPriceStriped, item.totalAmount, quoteId, item.id]
  );

  return (
    <Grid container alignItems="center">
      <Grid item className={classes.addTaskGroup}>
        <ThemeProvider>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item className={classes.groupName}>
              <Field type={FieldType.TEXT} value={item.name} striped={isGroupNameStriped} />
            </Grid>
            <Grid item style={{ width: '75%' }}>
              <Divider width="100%" />
            </Grid>
            <Grid item className={classes.groupPrice}>
              {overridesAreEnabled(config) ? (
                taskGroupPricingInput
              ) : (
                <Field
                  striped={isGroupPriceStriped}
                  type={FieldType.CURRENCY}
                  value={item.totalAmount}
                  style={{ paddingLeft: 8 }}
                />
              )}
            </Grid>
            <Grid item>
              {isDeleting.bool && isDeleting.id === item.id ? (
                <Spinner size={24} />
              ) : (
                <MoreButton
                  style={{ paddingTop: 16 }}
                  options={[
                    {
                      label: 'Edit',
                      onClick: () => {
                        setCheckedTasks(item.tasks.map(task => task.id));
                        setIsTaskGroupModalOpen({ isOpen: true, type: Mode.EDIT, data: item });
                      }
                    },
                    {
                      label: 'Delete',
                      onClick: () => deleteItem()
                    }
                  ]}
                />
              )}
            </Grid>
          </Grid>
        </ThemeProvider>
      </Grid>
    </Grid>
  );
};

export default TaskGroup;

TaskGroup.propTypes = {
  config: PropTypes.object,
  deleteTaskGroup: PropTypes.func,
  item: PropTypes.object,
  setIsTaskGroupModalOpen: PropTypes.func,
  setCheckedTasks: PropTypes.func,
  setTaskGroups: PropTypes.func,
  updateTaskGroup: PropTypes.func,
  quoteId: PropTypes.string
};

TaskGroup.defaultProps = {
  config: {},
  deleteTaskGroup: () => {},
  item: {},
  setIsTaskGroupModalOpen: () => {},
  setCheckedTasks: () => {},
  setTaskGroups: () => {},
  updateTaskGroup: () => {},
  quoteId: null
};
