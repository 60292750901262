import React from 'react';
import { bool, func, number, object, shape } from 'prop-types';
import { MUIForm } from '@buildhero/sergeant';
import AccordionSection from '../AccordionSection';
import DateTimeInput from '../DateTimeInput';
import { useBillableEventFormConfig } from './BillableEventForm.config';
import { shouldDisableScheduling, validateScheduling } from '../../NonVisitEvents.helpers';

const BillableEventForm = ({
  eventData,
  visit,
  job,
  disabled,
  onCreateService,
  onDirtyChange,
  onComplete,
  visitDataLoading
}) => {
  const formConfig = useBillableEventFormConfig({
    disabled,
    disableScheduling: shouldDisableScheduling(eventData),
    visit,
    job,
    visitDataLoading
  });

  const customComponents = {
    AccordionSection,
    DateTimeInput
  };

  return (
    <MUIForm
      configuration={formConfig}
      customComponents={customComponents}
      data={eventData}
      onCreateService={onCreateService}
      onDirtyChange={onDirtyChange}
      onComplete={onComplete}
      validate={validateScheduling}
    />
  );
};

BillableEventForm.propTypes = {
  disabled: bool.isRequired,
  visit: object,
  job: object,
  visitDataLoading: bool.isRequired,
  eventData: shape({
    from: number
  }).isRequired,
  onCreateService: func.isRequired,
  onDirtyChange: func.isRequired,
  onComplete: func.isRequired
};

BillableEventForm.defaultProps = {
  visit: {},
  job: {}
};

export default BillableEventForm;
