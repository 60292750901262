import React from 'react';
import { Typography, Divider, FieldType, SgtFormComponents } from '@buildhero/sergeant';
import { FastField } from 'formik';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

const typeToInput = {
  [FieldType.BOOL]: SgtFormComponents.Checkbox,
  [FieldType.TEXT]: SgtFormComponents.Input,
  [FieldType.CHIP]: SgtFormComponents.Select,
  json: props => {
    return (
      <JSONInput
        {...props}
        height={200}
        viewOnly={props.props.disabled}
        placeholder={props.field.value ? JSON.parse(props.field.value) : undefined}
        onBlur={e => !e.error && props.form.setFieldValue(props.field.name, e.json)}
        locale={locale}
      />
    );
  }
};

const Row = React.memo(({ setting, index, layout }) => {
  return (
    <>
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <Typography css={{ marginBottom: 4 }}>{setting.displayName}</Typography>
        <FastField
          name={`tenantSettings.${index}.settingValue`}
          component={typeToInput[setting.type]}
          props={{
            ...setting.props,
            style: { maxWidth: 250 },
            disabled: layout !== 'edit'
          }}
        />
      </div>
      <Divider />
    </>
  );
});

const TenantSettingList = ({ form, name, props }) => {
  return (
    <div>
      {form.values.tenantSettings.map((setting, index) => (
        <Row
          key={`${form.values.id}-${setting.settingKey}`}
          setting={setting}
          index={index}
          layout={props.layout}
        />
      ))}
    </div>
  );
};

export default TenantSettingList;
