import React from 'react';

import moment from 'moment-timezone';

import AppConstants, { TimeCardStatusTypes } from 'utils/AppConstants';

import { secondsToHour } from 'scenes/Payroll/TimeTrackingReport/helpers';

import LogRow from './LogRow';

const AuditMessages = {
  Edited: {
    actualTotalDurationOverride: ({ hourType, relevantChangeLog, timesheetEntry }) => {
      return `
      Changed the value for hour type "${hourType.hourType}"
      ${
        typeof relevantChangeLog.old !== 'undefined'
          ? `
          from
          ${secondsToHour(
            Number.isInteger(relevantChangeLog.old)
              ? relevantChangeLog.old
              : timesheetEntry.actualTotalDuration
          )}
      `
          : ''
      }
      to ${secondsToHour(relevantChangeLog.new)}`;
    },
    manualStatus: ({ relevantChangeLog, manualReopenReason }) => {
      return `
        Change Approval Status from ${relevantChangeLog.old || 'Empty'} to ${
        relevantChangeLog.new
      } ${manualReopenReason ? ` with reason ${manualReopenReason}` : ''}
      `;
    }
  },
  Added: ({ hourType, timesheetEntry }) => {
    return `
      Logged hour type "${hourType.hourType}" with value ${secondsToHour(
      timesheetEntry.actualTotalDuration
    )}
    `;
  }
};

const AuditLogEntry = ({ hourType, timesheetEntry, auditLog, payrollSetting, previousLog }) => {
  const executedDateTime = parseInt(parseInt(auditLog.executedDateTime, 10) / 1000, 10);

  const date = moment
    .tz(moment.unix(executedDateTime).format(), payrollSetting.timeZone)
    .format(AppConstants.DATETIME_FORMAT);

  switch (auditLog.executionType) {
    case 'Edited': {
      const changeLog = JSON.parse(auditLog.changeLog);

      const relevantChangeLog = changeLog.find(
        l => l.field === 'actualTotalDurationOverride' || l.field === 'manualStatus'
      );

      if (!relevantChangeLog) return null;

      // need this section to prevent repeat of approval status audit logs since there is one for every hour type.
      // if an approval status log is the same as the previous one do not show it.
      if (
        previousLog &&
        JSON.parse(previousLog.changeLog) &&
        previousLog.executionType === 'Edited' &&
        relevantChangeLog.field === 'manualStatus'
      ) {
        const previousChangeLog = JSON.parse(previousLog.changeLog);

        const relevantPreviousChangeLog = previousChangeLog.find(l => l.field === 'manualStatus');

        const previousExecutedDateTime = parseInt(
          parseInt(previousLog?.executedDateTime, 10) / 1000,
          10
        );

        if (
          relevantPreviousChangeLog &&
          (previousExecutedDateTime === executedDateTime ||
            (relevantPreviousChangeLog.new === relevantChangeLog.new &&
              relevantPreviousChangeLog.old === relevantChangeLog.old)) &&
          auditLog.executedBy === previousLog.executedBy
        ) {
          return null;
        }
      }

      let manualReopenReason;
      if (
        relevantChangeLog.field === 'manualStatus' &&
        (relevantChangeLog.new === TimeCardStatusTypes.SUBMITTED ||
          relevantChangeLog.new === TimeCardStatusTypes.DISPUTED)
      ) {
        manualReopenReason = changeLog.find(l => l.field === 'manualReopenReason')?.new;
      }

      return (
        <LogRow
          key={auditLog.id}
          id={auditLog.id}
          date={date}
          subject={auditLog.executionType}
          author={auditLog.executedBy}
          message={AuditMessages.Edited[relevantChangeLog.field]({
            hourType,
            relevantChangeLog,
            timesheetEntry,
            manualReopenReason
          })}
        />
      );
    }
    case 'Added': {
      return (
        <LogRow
          key={auditLog.id}
          id={auditLog.id}
          date={date}
          subject={auditLog.executionType}
          author={auditLog.executedBy}
          message={AuditMessages.Added({ hourType, timesheetEntry })}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default AuditLogEntry;
