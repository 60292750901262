import React from 'react';

import { VisitStatuses } from '@dispatch/Dispatch.constants';
import Context from 'components/Context';
import { getMultiSelectOptions } from 'meta/ListViews';
import { MultiSelectTypes } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { formatDurationValue, serializeDurationValue } from './VisitsTable.helpers';

const getJobTagOptions = () => {
  return (
    Context?.getCompanyContext()?.getCompany?.[MultiSelectTypes.JOB_TAGS]?.items?.map(jobTag => ({
      label: jobTag?.tagName,
      value: jobTag?.id
    })) || []
  );
};

export const hideOnHoldReason = tableName => {
  return !(
    tableName === VisitStatuses.ON_HOLD.value.displayValue ||
    tableName === VisitStatuses.CLOSED.value.displayValue
  );
};

export const tableMetadata = (tableName, flags, crewTimeTracking) => {
  const jobTagOptions = getJobTagOptions();
  return [
    {
      id: 'visitNumber',
      label: 'Visit Number'
    },
    {
      id: 'status',
      label: 'Status'
    },
    {
      id: 'procurementStatus',
      label: 'Procurement Status',
      type: 'enum',
      filterType: 'multi-select',
      labelFormatter: procurementStatus => <>{procurementStatus}</>,
      options: getMultiSelectOptions(MultiSelectTypes.JOB_PROCUREMENT_STATUS),
      enumType: EnumType.JOB_PROCUREMENT_STATUS,
      internal: !flags[FeatureFlags.JOB_PROCUREMENT_STATUS]
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: true,
      label: 'Visit Description',
      maxTextLen: 80
    },
    {
      id: 'departmentName',
      numeric: false,
      disablePadding: true,
      label: 'Department Name',
      maxTextLen: 80
    },
    {
      id: 'prerequisites',
      label: 'Pre-Dispatch Actions'
    },
    {
      id: 'scheduledFor',
      label: 'Date and Time',
      type: 'datetime',
      filterType: 'date'
    },
    {
      id: 'actualDuration',
      label: 'Duration',
      isCustom: true,
      type: 'Duration',
      filterType: {
        filterType: 'stringFilters',
        customFieldConditionOptions: [
          { label: 'Is', value: 'in' },
          { label: 'Is empty', value: 'empty', defaultValue: 'noFilter' },
          { label: 'Is not empty', value: 'notEmpty', defaultValue: 'noFilter' }
        ]
      },
      filterValueFormatter: formatDurationValue,
      filterValueSerializer: serializeDurationValue
    },
    {
      id: 'tentativeDate',
      label: 'Tentative Date',
      isCustom: true,
      type: 'TentativeDate',
      filterType: 'dateString',
      internal: true
    },
    {
      id: 'tentativeTime',
      label: 'Tentative Time',
      internal: true
    },
    {
      id: 'jobNo',
      label: 'Job Number',
      type: 'JobNumber',
      isCustom: true
    },
    {
      id: 'serviceChannelWorkOrderWOId',
      label: 'Service Channel',
      type: 'ServiceChannel',
      isCustom: true,
      internal: !flags[FeatureFlags.SERVICE_CHANNEL_INTEGRATION]
    },
    {
      id: 'propertyAddr',
      label: 'Property Address'
    },
    {
      id: 'jobTags',
      label: 'Job Tags',
      filterKey: 'jobTagIds',
      filterType: {
        uiType: 'multi-select',
        filterType: 'stringFilters',
        customFieldConditionOptions: [
          { label: 'Is', value: 'in' },
          { label: 'Is empty', value: 'empty', defaultValue: 'noFilter' },
          { label: 'Is not empty', value: 'notEmpty', defaultValue: 'noFilter' }
        ]
      },
      options: jobTagOptions,
      type: 'chip'
    },
    {
      id: 'jobTagIds',
      label: 'Job Tags',
      convertToFilterWithHumanReadableOptions: true,
      options: jobTagOptions,
      internal: true
    },
    {
      id: 'jobCustomIdentifier',
      internal: true
    },
    {
      id: 'jobType',
      label: 'Job Type'
    },
    {
      id: 'jobTypeInternal',
      label: 'Maintenance',
      type: 'Maintenance',
      isCustom: true
    },
    {
      id: 'priority',
      label: 'Priority',
      type: 'Priority',
      isCustom: true
    },
    {
      id: 'customer',
      label: 'Customer'
    },
    {
      id: 'property',
      label: 'Property'
    },
    {
      id: 'primaryTechs',
      label: 'Primary Technician'
    },
    {
      id: 'primaryTechIds',
      internal: true
    },
    {
      id: 'extraTechs',
      label: 'Additional Technicians',
      isCustom: true,
      type: 'TechniciansList'
    },
    {
      id: 'extraTechIds',
      internal: true
    },
    ...(!crewTimeTracking
      ? [
          {
            id: 'extraTechsRequired',
            internal: true
          },
          {
            id: 'extraTechsNumber',
            label: 'Extra Techs Required'
          }
        ]
      : []),
    {
      id: 'onHoldReason',
      label: 'On Hold Reason',
      hide: hideOnHoldReason(tableName)
    }
  ];
};
