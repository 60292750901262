/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { snackbarOn } from 'redux/actions/globalActions';
import { connect } from 'react-redux';
import { DefaultButton, FullScreenModal, withMultipleFormsDynamic } from 'components';
import { phaseChange, phaseCreate } from 'services/API/projectPhases';
import { phaseDepartmentChange, phaseDepartmentCreate } from 'services/API/projectPhaseDepartment';
import { isStartBeforeEndDate } from 'scenes/ProjectManagement/components/utils';
import PhaseForm from '../PhaseForm';
import { calcContractValue, formatPhaseForUpdate, getFormattedPhaseDepartment } from '../utils';
import { costTypeFieldLabelMapping } from '../DepartmentForm/constants';

const PhaseModal = props => {
  const {
    isOpen,
    setIsOpen,
    projectId,
    startingContractValue,
    phaseData,
    onSubmit,
    classes,
    mode,
    getHandleCreateService,
    getHandleRemoveService,
    getHandleRemoveAllServices,
    getHandleComplete,
    handleSubmitStart,
    setOnSubmitFinal,
    isSubmitting
  } = props;
  const [contractValue, setContractValue] = useState(startingContractValue);
  const [departments, setDepartments] = useState([{ tempId: uuid.v4() }]);
  const isEdit = mode === 'edit';

  useEffect(() => {
    if (isOpen && phaseData?.ProjectPhaseDepartment) {
      setDepartments(
        phaseData.ProjectPhaseDepartment.map(x => ({
          ...x,
          ...(!x.tempId && { tempId: uuid.v4() })
        }))
      );
    }
  }, [phaseData, isOpen]);

  const checkIfAllDeptsSelected = depts => {
    let missingDeparment = false;
    depts.forEach(dept => {
      if (!dept.departmentId) {
        missingDeparment = true;
      }
    });
    return missingDeparment;
  };

  const editPhase = async formattedFormData => {
    await phaseChange(
      formattedFormData.id,
      formatPhaseForUpdate({
        ...formattedFormData,
        projectPhaseId: formattedFormData.id,
        projectId,
        ProjectPhaseDepartment: []
      })
    );
    // have to do this for now as the backend doesn't support inserting nested
    // relations when updating
    for (const dept of formattedFormData.ProjectPhaseDepartment) {
      const formattedDept = getFormattedPhaseDepartment({
        ...dept,
        projectPhaseId: formattedFormData.id
      });
      if (dept.id) {
        await phaseDepartmentChange(dept.id, formattedDept);
      } else {
        await phaseDepartmentCreate(formattedDept);
      }
    }
    setIsOpen(false);
    onSubmit();
  };

  const createPhase = async formattedFormData => {
    const formattedDepts = formattedFormData.ProjectPhaseDepartment;
    await phaseCreate(
      formatPhaseForUpdate({
        ...formattedFormData,
        projectId,
        ProjectPhaseDepartment: formattedDepts
      })
    );
    setIsOpen(false);
    onSubmit();
  };

  const submit = ({ PhaseData }) => {
    if (!isStartBeforeEndDate(PhaseData.startDate, PhaseData.endDate)) {
      props.snackbarOn('error', 'Start date must be before End date');
      return;
    }
    const formattedFormData = { ...PhaseData };
    if (!formattedFormData) return;
    // The ProjectPhaseDepartment key is an object, where each key value is a department.
    // We only need the values, so we do Object.values on it to get them all as an array.
    // Same goes for each dept's ProjectPhaseDepartmentCostCode.
    const phaseDepartments = formattedFormData.ProjectPhaseDepartment
      ? Object.values(formattedFormData.ProjectPhaseDepartment)
      : [];

      if (!phaseDepartments.length) {
      props.snackbarOn('error', `You need to add a department`);
      return;
    }

    const deptNotSelected = checkIfAllDeptsSelected(phaseDepartments);

    if (deptNotSelected) {
      props.snackbarOn('error', 'One or more departments is not selected');
      return;
    }

    phaseDepartments.forEach(dept => {
      // eslint-disable-next-line no-param-reassign
      dept.ProjectPhaseDepartmentCostCode = dept.ProjectPhaseDepartmentCostCode
        ? Object.values(dept.ProjectPhaseDepartmentCostCode)
        : undefined;
    });

    formattedFormData.ProjectPhaseDepartment = phaseDepartments;
    mode === 'edit' ? editPhase(formattedFormData) : createPhase(formattedFormData);
  };

  const onAddDepartment = (newDept, index = -1) => {
    if (index >= 0) {
      setDepartments(prevState => {
        const newState = [...prevState];
        newState[index] = { ...prevState[index], ...newDept };
        return [...newState];
      });
    } else {
      setDepartments(prevState => [...prevState, newDept]);
    }
  };

  const onDeleteDepartment = index => {
    setDepartments(prevState => R.remove(index, 1, prevState));
  };

  const updateContractValue = (field, value, deptIndex) => {
    setDepartments(prevState => {
      const newDepts = [...prevState];
      newDepts[deptIndex][field] = value;
      newDepts[deptIndex].totalRevenue = R.pipe(
        R.pick(R.keys(costTypeFieldLabelMapping)),
        R.values,
        R.sum
      )(newDepts[deptIndex]);
      return [...newDepts];
    });
  };

  useEffect(() => {
    if (departments?.length) {
      let newContractValue = Number(`${startingContractValue}`);
      departments.forEach(dept => {
        newContractValue = calcContractValue(dept, newContractValue);
      });
      setContractValue(newContractValue);
    }
  }, [departments, startingContractValue]);

  useEffect(() => {
    setOnSubmitFinal(submit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FullScreenModal
      title={isEdit ? 'Edit Phase' : 'Create Phase'}
      open={isOpen}
      handleClose={() => {
        setIsOpen(false);
      }}
      onExited={() => {
        // Reset to initial state after exiting modal
        setDepartments([{ tempId: uuid.v4() }]);
        getHandleRemoveAllServices();
      }}
      contentStyle={{ maxWidth: '90%' }}
      modalHeaderButtons={[
        <DefaultButton
          key="phase-modal-submit-button"
          label={isEdit ? 'Save Changes' : 'Create Phase'}
          showSpinner={isSubmitting}
          disabled={isSubmitting}
          variant="containedPrimary"
          onClick={handleSubmitStart}
        />
      ]}
    >
      <div className={classes.formContainer}>
        <PhaseForm
          projectId={projectId}
          classes={classes}
          contractValue={contractValue}
          updateContractValue={updateContractValue}
          onAddDepartment={onAddDepartment}
          onDeleteDepartment={onDeleteDepartment}
          phaseData={{
            ...phaseData,
            ProjectPhaseDepartment: departments
          }}
          getHandleCreateService={getHandleCreateService}
          getHandleComplete={getHandleComplete}
          getHandleRemoveService={getHandleRemoveService}
          isEdit={isEdit}
        />
      </div>
    </FullScreenModal>
  );
};

PhaseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  phaseData: PropTypes.object.isRequired,
  startingContractValue: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  snackbarOn: PropTypes.func.isRequired,
  mode: PropTypes.string,
  getHandleCreateService: PropTypes.func.isRequired,
  getHandleRemoveService: PropTypes.func.isRequired,
  getHandleRemoveAllServices: PropTypes.func.isRequired,
  getHandleComplete: PropTypes.func.isRequired,
  handleSubmitStart: PropTypes.func.isRequired,
  setOnSubmitFinal: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
};

PhaseModal.defaultProps = {
  mode: ''
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});
const withMultiple = withMultipleFormsDynamic(PhaseModal);
export default connect(mapStateToProps, mapDispatcherToProps)(withMultiple);
