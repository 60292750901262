const styles = theme => ({
  root: ({ disableBottomPadding }) => ({
    paddingBottom: disableBottomPadding ? 0 : theme.spacing(3),
    minWidth: 0
  }),
  tabHeading: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: -1,
    whiteSpace: 'nowrap',
    color: theme.palette.grayscale(60)
  },
  selectedTabHeading: {
    color: theme.palette.grayscale(20)
  },
  tabIndicator: {
    height: 3,
    backgroundColor: theme.palette.brand.logoGreen
  },
  tab: {
    overflow: 'unset',
    opacity: 'unset',
    [theme.breakpoints.up('xs')]: {
      minWidth: 0
    },
    minHeight: 0,
    padding: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    '&:first-child': {
      marginLeft: 0
    },
    '&:last-child': {
      marginRight: 0
    },
    marginBottom: theme.spacing(1)
  },
  scrollButton: {
    color: theme.palette.grayscale(60),
    height: 24,
    width: 24
  },
  iconContainer: {
    display: 'flex',
    paddingLeft: 8,
    paddingBottom: 2
  }
});
export default styles;
