import gql from 'graphql-tag';

const saveJobNotes = gql`
  mutation saveJobNotes($data: String!, $instructionSet: String!) {
    saveJobNotes(data: $data, instructionSet: $instructionSet) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      subject
      note
      createdBy
      lastUpdatedDate
      lastUpdatedDateTime
      lastUpdatedBy
      parentId
    }
  }
`;

export default saveJobNotes;
