const minFirstRowItemWidth = 200;
const minItemWidth = 200;
const padding = 10;

const mainSectionRow1ItemsViewMode = (data, options) => {
  return [
    {
      options: {
        padding,
        fontWeight: 'bold',
        fontSize: '18px',
        minWidth: minItemWidth,
        grow: 0
      },
      contents: [
        {
          component: {
            default: 'LinkFieldWithLabel'
          },
          source: 'agreementName.label',
          options: {
            label: 'Service Agreement Name',
            linkPath: data?.agreementName?.path,
            inputOptions: []
          }
        }
      ]
    },
    {
      options: {
        padding,
        minWidth: minItemWidth,
        grow: 0
      },
      contents: [
        {
          component: {
            default: 'LinkFieldWithLabel'
          },
          source: 'agreementNumber.label',
          options: {
            label: 'Service Agreement Number',
            linkPath: data?.agreementNumber?.path,
            inputOptions: []
          }
        }
      ]
    },
    {
      options: {
        padding,
        minWidth: minItemWidth,
        grow: 0
      },
      contents: [
        {
          component: {
            default: 'FieldWithLabel',
            edit: 'SelectInput'
          },
          source: 'jobTypeName',
          options: {
            label: 'Maintenance Type',
            inputOptions:
              options?.jobTypes?.map(t => ({
                label: t.tagName,
                value: t.tagName
              })) ?? []
          }
        }
      ]
    },
    {
      options: {
        padding,
        maxWidth: 400
      },
      contents: [
        {
          component: {
            edit: 'TextInput',
            default: 'FieldWithLabel'
          },
          source: 'serviceDescription',
          options: {
            label: 'Service Description',
            inputOptions: []
          }
        }
      ]
    }
  ];
};

const mainSectionRow2ItemsViewMode = (data, showTotalBudgetedHours) => {
  return [
    {
      options: {
        padding,
        minWidth: minItemWidth,
        grow: 0
      },
      contents: [
        {
          component: {
            default: 'ChipsList'
          },
          source: 'departmentList',
          options: {
            label: 'Department',
            inputOptions: []
          }
        }
      ]
    },
    {
      options: {
        padding,
        minWidth: minItemWidth,
        grow: 0
      },
      contents: [
        {
          component: {
            edit: 'DateInput',
            default: 'FieldWithLabel'
          },
          source: {
            view: 'dueDateFormatted',
            edit: 'dueDate'
          },
          options: {
            label: 'Maintenance Due Date'
          }
        }
      ]
    },
    {
      options: {
        padding,
        minWidth: minItemWidth,
        grow: 0,
        hide: !showTotalBudgetedHours
      },
      contents: [
        {
          component: {
            edit: 'TextInput',
            default: 'FieldWithLabel'
          },
          source: 'totalBudgetedHours',
          options: {
            label: 'Total Budgeted Hours',
            type: 'number',
            min: 1,
            step: '.01'
          }
        }
      ]
    },
    {
      options: {
        padding,
        minWidth: minItemWidth,
        grow: 0
      },
      contents: [
        {
          component: {
            default: 'LabelControl'
          },
          source: 'Contract'
        },
        {
          component: {
            default: 'AttachItem',
            view: 'AttachItem'
          },
          source: 'contract',
          options: {
            label: 'Contract',
            inputOptions: []
          }
        }
      ]
    },
    {
      options: {
        padding
      },
      contents: [
        {
          component: 'Notes',
          source: 'jobNotes',
          options: {
            label: 'Office Notes',
            lines: 4
          }
        }
      ]
    }
  ];
};

const sidebarItems = options => {
  return [
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          component: {
            default: 'LinkButton'
          },
          source: 'customer',
          options: {
            label: 'Customer',
            inputOptions: []
          }
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          component: {
            default: 'LinkButton'
          },
          source: 'billingCustomer',
          options: {
            label: 'Billing Customer'
          }
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          component: {
            default: 'LinkButton'
          },
          source: 'property',
          options: {
            label: 'Property',
            inputOptions: []
          }
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          component: {
            default: 'FieldWithLabel'
          },
          source: 'propertyType',
          options: {
            label: 'Property Type',
            inputOptions: []
          }
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          component: {
            default: 'FieldWithLabel'
          },
          source: 'address',
          options: {
            label: 'Property Address'
          }
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        minWidth: minItemWidth
      },
      contents: [
        {
          component: {
            default: 'LocationView'
          },
          source: 'location'
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          component: {
            default: 'FieldWithLabel',
            edit: 'SelectInput'
          },
          source: 'propertyRepresentative',
          options: {
            label: 'Property Representative',
            isSearchable: true,
            inputOptions: options.propertyRepList,
            creatable: true,
            createLabel: 'Property Representative',
            onCreate: options.onCreatePropRep
          }
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        minWidth: minItemWidth
      },
      contents: [
        {
          component: {
            edit: 'AddNotesView',
            view: 'AddNotesView'
          },
          source: 'propertyInstructions',
          options: {
            inputOptions: []
          }
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          component: {
            default: 'FieldWithLabel'
          },
          source: 'bestContactMethod',
          options: {
            label: 'Best Contact Method',
            overflowWrap: 'anywhere'
          }
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 0,
        padding,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          component: {
            edit: 'TextInput',
            default: 'FieldWithLabel'
          },
          source: 'customerProvidedPONumber',
          options: {
            label: 'Purchase Order (Customer)',
            overflowWrap: 'anywhere'
          }
        }
      ]
    }
  ];
};

export const mainSectionViewMode = (data, options) => ({
  fields: {},
  layouts: {
    default: {
      options: {
        direction: 'column',
        grow: 0
      },
      contents: [
        {
          options: {
            direction: 'row',
            grow: 0,
            flexWrap: 'wrap'
          },
          contents: mainSectionRow1ItemsViewMode(data, options)
        },
        {
          options: {
            direction: 'row',
            grow: 0,
            flexWrap: 'wrap'
          },
          contents: mainSectionRow2ItemsViewMode(data, options.showTotalBudgetedHours)
        }
      ]
    }
  }
});

export const sidebarSection = options => ({
  fields: {},
  layouts: {
    default: {
      options: {
        direction: 'column',
        grow: 0
      },
      contents: sidebarItems(options)
    }
  }
});
