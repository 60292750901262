import { gql } from '@apollo/client/core';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const UPDATE_JOB_COSTING = gql`
  mutation Mutation($jobId: String!) {
    processJob(jobId: $jobId)
  }
`;

export const useUpdateJobCostingValues = jobId => {
  return useExtendedMutation(UPDATE_JOB_COSTING, {
    variables: {
      jobId
    }
  });
};
