import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Toolbar, Drawer, CssBaseline, AppBar, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';

import { ThemeProvider } from '@buildhero/sergeant';
import GlobalBanner from 'components/GlobalBanner';
import Context, { companyStore } from 'components/Context';
import UserPermisson from 'components/AppPermissions';
import { PermissionConstants } from 'utils/AppConstants';

import styles, { mobileDrawerWidth } from './styles';
import UserControls from './UserControls';
import CreateEntryModals from './CreateEntryModals';
import CompanyButton from './CompanyButton';
import MenuSection from './MenuSection';
import MenuItem from './MenuItem';
import CatchErrors from 'scenes/Error';
import widthHOC from './widthHOC';

/**
 * Foundational layout component - Splits the browser windows for menu and content
 * Children of this component will be rendered in the content area
 * renders Menu section and menus
 */

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    const { isSmallScreen } = props;
    this.unsubscribeCompany = companyStore.subscribe(this.contextChange);
    this.state = {
      tenantSetting: {},
      expandedMenu: '',
      isSmallScreen,
      open: false,
      bannerShowing: false
    };
  }

  componentDidMount() {
    this.getCompany();
  }

  componentDidUpdate = prevProps => {
    const { isSmallScreen } = this.props;
    if (prevProps.isSmallScreen !== isSmallScreen) {
      this.setState({ open: isSmallScreen === false });
    }
  };

  componentWillUnmount() {
    this.unsubscribeCompany();
  }

  contextChange = () => {
    const comp = companyStore.getState();
    const listTenantSettings = comp?.listTenantSettings;
    const tenantSettingProcessed = {};
    if (listTenantSettings) {
      listTenantSettings.forEach(setting => {
        tenantSettingProcessed[setting.settingKey] = setting.settingValue;
      });
      this.setState(prevState => ({
        ...prevState,
        tenantSettingProcessed
      }));
    }
  };

  getCompany = async () => {
    const companyInfo = await Context.getCompanyContext();
    if (companyInfo) {
      const { listTenantSettings = [], getCompany = {} } = companyInfo;
      const tenantSettingProcessed = {};
      if (listTenantSettings) {
        listTenantSettings.forEach(setting => {
          tenantSettingProcessed[setting.settingKey] = setting.settingValue;
        });
        this.setState(prevState => ({
          ...prevState,
          companyInfo: getCompany,
          tenantSettingProcessed
        }));
      }
    }
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  renderBOLogo = () => {
    const { classes } = this.props;
    return (
      <a className={classes.boLogo} href="/">
        <img src="/static/images/bo-logo.svg" alt="BuildOps Logo" width={20} height={20} />
      </a>
    );
  };

  renderCompanyButton = ({ companyButton }) => {
    const { companyInfo, expandedMenu, isSmallScreen, tenantSettingProcessed } = this.state;
    return (
      <UserPermisson
        I={companyButton.caslAction}
        action={companyButton} // When object, permission component will extract caslkey
        scope={companyButton.scope}
        key={`permMob${companyButton.title}${companyButton.scope}`}
      >
        <CompanyButton
          companyContext={companyInfo}
          data={companyButton}
          key={companyButton.title}
          expandedMenu={expandedMenu}
          setExpandedMenu={newValue => this.setState({ expandedMenu: newValue })}
          tenantSettingProcessed={tenantSettingProcessed}
          isSmallScreen={isSmallScreen}
        />
      </UserPermisson>
    );
  };

  renderMenuItems = (navItems = []) => {
    const { expandedMenu, isSmallScreen, tenantSettingProcessed } = this.state;
    return navItems.map(item => {
      if (item.companyButton) return this.renderCompanyButton(item);
      if (item.section) {
        return (
          <UserPermisson
            I={item.section.caslAction}
            action={item.section}
            scope={item.section.scope}
            key={`permMob${item.section.title}${item.section.scope}`}
          >
            <MenuSection
              data={item.section}
              key={item.section.title}
              expandedMenu={expandedMenu}
              setExpandedMenu={newValue =>
                this.setState(prevState => ({
                  ...prevState,
                  expandedMenu: newValue
                }))
              }
              tenantSettingProcessed={tenantSettingProcessed}
              isSmallScreen={isSmallScreen}
            />
          </UserPermisson>
        );
      }
      if (item.menu) {
        return (
          <UserPermisson
            I={item.menu.caslAction}
            action={item.menu} // When object, permission component will extract caslkey
            scope={item.menu.scope}
            key={`permWeb${item.menu.title}`}
          >
            <MenuItem
              data={item.menu}
              key={`${item.menu.title}_${item.menu.url}`}
              tenantSettingProcessed={tenantSettingProcessed}
              isSmallScreen={isSmallScreen}
            />
          </UserPermisson>
        );
      }
      return null;
    });
  };

  setShowBanner = bannerShowing => {
    this.setState({ bannerShowing });
  };

  render() {
    const { data, classes } = this.props;
    const { open, bannerShowing, isSmallScreen, tenantSettingProcessed } = this.state;
    return (
      <div className={classes.root}>
        <UserPermisson I="allow" action={PermissionConstants.FUNCTIONS_WEB}>
          <Box display="block" displayPrint="none">
            <CssBaseline />
            <AppBar
              position="fixed"
              className={classNames(classes.appBar, {
                [classes.appBarShift]: open
              })}
            >
              <ThemeProvider>
                <GlobalBanner setShowBanner={this.setShowBanner} />
              </ThemeProvider>
              <Toolbar className={classes.toolbar} disableGutters>
                {isSmallScreen && (
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={this.handleDrawerToggle}
                    className={classes.menuButton}
                  >
                    <MenuIcon />
                  </IconButton>
                )}
                {this.renderBOLogo()}
                {this.renderMenuItems(data)}
                <UserControls
                  auth={this.props.auth}
                  tenantSettingProcessed={tenantSettingProcessed}
                  isSmallScreen={isSmallScreen}
                />
              </Toolbar>
            </AppBar>
            <nav className={classes.drawer}>
              <Box display="block" displayPrint="none">
                <Drawer
                  className={classes.drawer}
                  classes={{
                    paper: classes.drawerPaper
                  }}
                  variant="persistent"
                  anchor="left"
                  open={open}
                >
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{ marginTop: 8 }}
                  >
                    <Grid item>
                      <img
                        src="/static/images/buildops-logo.png"
                        alt="BuildOps"
                        width={isSmallScreen ? `${mobileDrawerWidth}px` : 'auto'}
                      />
                    </Grid>
                  </Grid>
                  <CatchErrors>{this.renderMenuItems(data.navigation)}</CatchErrors>
                </Drawer>
              </Box>
            </nav>
            <CreateEntryModals />
          </Box>
        </UserPermisson>
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: open,
            [classes.contentBannerShift]: bannerShowing
          })}
        >
          {this.props.children}
        </main>
      </div>
    );
  }
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(widthHOC(Navigation));
