const styles = theme => ({
  container: {
    marginTop: 20,
    marginBottom: 50
  },
  button: {
    maxWidth: '176px',
    width: '100%',
    height: '36px',
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(3.75),
    borderColor: theme.palette.brand.logoBlue
  },
  confirmButton: {
    maxWidth: '176px',
    width: '100%',
    height: '36px',
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(3.75),
    borderColor: theme.palette.brand.logoBlue,
    color: theme.palette.brand.logoBlue,
    float: 'right',
    '&:hover': {
      backgroundColor: theme.palette.brand.logoBlue,
      color: theme.palette.grayscale(98)
    }
  },
  warningText: {
    color: theme.palette.error.main,
    fontSize: 20,
    paddingTop: 20,
    textAlign: 'center'
  }
});

export default styles;
