import gql from 'graphql-tag';

const updateCustomerStatus = gql`
  mutation updateCustomerStatus($partitionKey: String, $data: UpdateCustomerStatusInput!) {
    updateCustomerStatus(partitionKey: $partitionKey, data: $data) {
      id
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      version
      status
      isActive
    }
  }
`;

export default updateCustomerStatus;
