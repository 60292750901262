// see the link below for a visualization of the state machine as of 4.2021
// https://xstate.js.org/viz/?gist=39a4d0a81f6adca1db445425587ca408

import { Machine } from 'xstate';

import { VisitActions, VisitStatuses } from '@dispatch/Dispatch.constants';

import { getState } from 'redux/store';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import * as Guards from './getVisitStateMachine.guards';
import * as Transitions from './getVisitStateMachine.transitions';

// eslint-disable-next-line import/prefer-default-export
export const getVisitStateMachine = (visitId, visitInitialState = VisitStatuses.UNKNOWN.key) => {
  const wrinkleTimeFF = getState()?.launchDarklyFlags?.flags?.[FeatureFlags.WRINKLE_IN_TIME];

  return Machine(
    {
      id: `visit_sm_${visitId}`,
      initial: visitInitialState,
      states: {
        [VisitStatuses.UNKNOWN.key]: {},
        [VisitStatuses.UNASSIGNED.key]: {
          on: {
            [VisitActions.SCHEDULE.key]: Transitions.SCHEDULE,
            [VisitActions.CANCEL.key]: Transitions.CANCEL,
            [VisitActions.UPDATE_VISIT.key]: Transitions.UPDATE_UNASSIGNED
          }
        },
        [VisitStatuses.SCHEDULED.key]: {
          on: {
            [VisitActions.RESCHEDULE.key]: Transitions.RESCHEDULE,
            [VisitActions.CANCEL.key]: Transitions.CANCEL,
            [VisitActions.COMPLETE.key]: Transitions.COMPLETE,
            [VisitActions.DRAG_AND_DROP.key]: Transitions.DRAG_AND_DROP,
            [VisitActions.PUT_ON_HOLD.key]: Transitions.PUT_ON_HOLD,
            [VisitActions.TECH_PUT_ON_HOLD.key]: Transitions.TECH_PUT_ON_HOLD,
            [VisitActions.TRAVEL.key]: Transitions.TRAVEL,
            [VisitActions.WORK.key]: Transitions.WORK,
            [VisitActions.UPDATE_VISIT.key]: Transitions.UPDATE_SCHEDULED,
            [VisitActions.RELEASE_TO_TECHS.key]: Transitions.RELEASE_TO_TECHS // R2T state transition
          }
        },
        [VisitStatuses.TRAVELING.key]: {
          on: {
            ...(wrinkleTimeFF && { [VisitActions.RESCHEDULE.key]: Transitions.TRAVEL_RESCHEDULE }),
            [VisitActions.COMPLETE.key]: Transitions.COMPLETE,
            [VisitActions.CANCEL.key]: Transitions.CANCEL,
            [VisitActions.PUT_ON_HOLD.key]: Transitions.PUT_ON_HOLD,
            [VisitActions.TECH_PUT_ON_HOLD.key]: Transitions.TECH_PUT_ON_HOLD,
            [VisitActions.DRAG_AND_DROP.key]: Transitions.DRAG_AND_DROP,
            [VisitActions.TRAVEL.key]: Transitions.TRAVEL,
            [VisitActions.UPDATE_VISIT.key]: Transitions.UPDATE_TRAVELING,
            [VisitActions.WORK.key]: Transitions.WORK,
            [VisitActions.PAUSE.key]: Transitions.PAUSE,
            [VisitActions.WORK_DONE.key]: Transitions.WORK_DONE
          }
        },
        [VisitStatuses.WORKING.key]: {
          on: {
            ...(wrinkleTimeFF && { [VisitActions.RESCHEDULE.key]: Transitions.WORK_RESCHEDULE }),
            [VisitActions.COMPLETE.key]: Transitions.COMPLETE,
            [VisitActions.CANCEL.key]: Transitions.CANCEL,
            [VisitActions.WORK_DONE.key]: Transitions.WORK_DONE,
            [VisitActions.PUT_ON_HOLD.key]: Transitions.PUT_ON_HOLD,
            [VisitActions.TECH_PUT_ON_HOLD.key]: Transitions.TECH_PUT_ON_HOLD,
            [VisitActions.TRAVEL.key]: Transitions.TRAVEL,
            [VisitActions.WORK.key]: Transitions.WORK,
            [VisitActions.UPDATE_VISIT.key]: Transitions.UPDATE_WORKING,
            [VisitActions.PAUSE.key]: Transitions.PAUSE
          }
        },
        [VisitStatuses.ON_HOLD.key]: {
          on: {
            [VisitActions.COMPLETE.key]: Transitions.COMPLETE,
            [VisitActions.TECH_PUT_ON_HOLD.key]: Transitions.TECH_PUT_ON_HOLD,
            [VisitActions.CONTINUE_IN_NEW_VISIT.key]: Transitions.CONTINUE_IN_NEW_VISIT,
            [VisitActions.CONVERT.key]: Transitions.STAY_ON_HOLD,
            [VisitActions.UPDATE_VISIT.key]: Transitions.UPDATE_ON_HOLD,
            [VisitActions.CLOSE.key]: Transitions.CLOSE
          }
        },
        [VisitStatuses.PAUSED.key]: {
          on: {
            ...(wrinkleTimeFF && { [VisitActions.RESCHEDULE.key]: Transitions.PAUSE_RESCHEDULE }),
            [VisitActions.COMPLETE.key]: Transitions.COMPLETE,
            [VisitActions.PUT_ON_HOLD.key]: Transitions.PUT_ON_HOLD,
            [VisitActions.TECH_PUT_ON_HOLD.key]: Transitions.TECH_PUT_ON_HOLD,
            [VisitActions.CANCEL.key]: Transitions.CANCEL,
            [VisitActions.TRAVEL.key]: Transitions.TRAVEL,
            [VisitActions.WORK.key]: Transitions.WORK,
            [VisitActions.WORK_DONE.key]: Transitions.WORK_DONE,
            [VisitActions.UPDATE_VISIT.key]: Transitions.UPDATE_PAUSED,
            [VisitActions.PAUSE.key]: Transitions.PAUSE
          }
        },
        [VisitStatuses.CANCELED.key]: {
          on: {
            [VisitActions.REOPEN.key]: Transitions.REOPEN
          }
        },
        [VisitStatuses.COMPLETE.key]: {
          type: 'final',
          on: {
            [VisitActions.CONVERT.key]: Transitions.CONVERT
          }
        },
        [VisitStatuses.CLOSED.key]: {
          on: {
            [VisitActions.CONVERT.key]: Transitions.CONVERT
          }
        },
        [VisitStatuses.CONVERTED.key]: {
          type: 'final'
        }
      }
    },
    {
      guards: Guards
    }
  );
};
