import { VisitActions } from '@dispatch/Dispatch.constants';

export const selectActualDuration = duration => {
  if (!duration) return null;
  // comes in the format "## minuets"?!?
  return Number.parseInt(duration.split(' ')[0], 10);
};

export const defaultAddress = {
  addressType: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipcode: ''
};

export const selectDisplayAddress = visit => {
  const addresses = visit?.job?.property?.companyAddresses?.items || [];
  const firstAddress = addresses[0];
  const propertyAddress = addresses.find(addObj => addObj?.addressType === 'propertyAddress');

  return propertyAddress || firstAddress || defaultAddress;
};

export const selectSaveTransition = nextEvents => {
  const SCHEDULE = nextEvents?.find(event => event === VisitActions.SCHEDULE.key);
  const RESCHEDULE = nextEvents?.find(event => event === VisitActions.RESCHEDULE.key);
  const UPDATE_VISIT = nextEvents?.find(event => event === VisitActions.UPDATE_VISIT.key);

  return SCHEDULE || RESCHEDULE || UPDATE_VISIT;
};

export const selectDndTransition = nextEvents => {
  const SCHEDULE = nextEvents?.find(event => event === VisitActions.SCHEDULE.key);
  const RESCHEDULE = nextEvents?.find(event => event === VisitActions.RESCHEDULE.key);

  return SCHEDULE || RESCHEDULE;
};

export const selectPosition = address => {
  if (address?.latitude && address?.longitude) {
    return {
      lat: address.latitude,
      lng: address.longitude
    };
  }

  return {};
};
