import { EnumType } from 'utils/constants';

export const maintenanceRowsMeta = [
  {
    id: 'maintenanceTaskName',
    label: 'Maintenance Task',
    maxTextLen: 80
  },
  {
    id: 'maintenanceTaskDescription',
    label: 'Description'
  },
  {
    id: 'asset.assetName',
    label: 'Asset',
    showLink: 'view',
    linkPath: '/asset/view',
    linkReference: 'assetId'
  },
  {
    id: 'partsAndMaterials',
    numeric: false,
    disableSort: true,
    isCustom: true,
    type: 'PartsAndMaterials',
    label: 'Parts & Materials',
    maxTextLen: 80
  }
];

export const intervalValueMap = {
  monthly: {
    incrementer: [1, 'month'],
    numberOfJobs: 12,
    label: 'Monthly'
  },
  alt_monthly: {
    incrementer: [2, 'month'],
    numberOfJobs: 6,
    label: 'Alternate Month'
  },
  quarterly: {
    incrementer: [3, 'month'],
    numberOfJobs: 4,
    label: 'Quarterly'
  },
  annual: {
    incrementer: [1, 'year'],
    numberOfJobs: 1,
    label: 'Annual'
  }
};

export const propertyMaintenanceRowsMeta = [
  {
    id: 'maintenanceNumber',
    label: 'Maintenance #',
    showLink: 'view',
    filterKey: 'Job.jobNumber',
    filterType: 'number',
    linkPath: '/maintenance/view',
    linkReference: 'maintenanceNumber'
  },
  {
    id: 'dueDate',
    type: 'dateOnly',
    filterKey: 'Job.dueDate',
    filterType: 'date',
    label: 'Due Date'
  },
  {
    id: 'status',
    label: 'Status',
    filterKey: 'Job.status',
    filterType: 'string',
    numeric: false,
    enumType: EnumType.MAINTENANCE_ITEM_STATUS
  },
  {
    id: 'agreementName',
    label: 'Agreement Name',
    showLink: 'view',
    linkPath: '/serviceAgreement/view',
    linkReference: 'agreementId',
    disableSort: true
  },
  {
    id: 'interval',
    label: 'Interval',
    disableSort: true
  },
  {
    id: 'tags',
    label: 'Tags',
    type: 'chip',
    disableSort: true
  },
  {
    id: 'numTasks',
    label: '# of Tasks',
    disableSort: true
  },
  {
    id: 'numParts',
    label: 'All Parts & Materials',
    disableSort: true
  }
];
export default maintenanceRowsMeta;
