import React, { Component } from 'react';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

/**
 * Material UI text input with BuildHero styles
 */
class FieldWithLabel extends Component {
  render() {
    const { field, options, classes, form } = this.props;
    const { showLineTo, addressTypeLabel, addressEditSource, testingid } = options;

    const toType = addressTypeLabel === 'billing' ? 'Bill To: ' : 'Ship To: ';
    const toName = form?.values?.[addressEditSource]?.[showLineTo];

    return (
      <>
        <Typography variant="caption" className={classes.label}>
          {options.label}
          {options.enableEdit && (
            <IconButton onClick={options?.onEdit} aria-label="Edit" size="small">
              <EditIcon className={classes.editIcon} />
            </IconButton>
          )}
        </Typography>
        {showLineTo && toName && (
          <Typography className={classes.billToLabel}>
            {<b>{toType}</b>}
            {toName}
          </Typography>
        )}
        <Typography
          className={options.useSideSectionStyling ? classes.sideSectionStyles : classes.content}
        >
          {(field && field.value) || '-'}
        </Typography>
      </>
    );
  }
}

const styledFieldWithLabel = withStyles(styles)(FieldWithLabel);
export default styledFieldWithLabel;
