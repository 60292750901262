/* eslint-disable import/prefer-default-export */
export const WarrantyLayout = {
  fields: {
    warrantyInfo: {
      default: 'warrantyInfo',
      edit: 'warrantyInfo'
    },
    startDate: {
      default: 'startDate',
      edit: 'startDate'
    },
    endDate: {
      default: 'endDate',
      edit: 'endDate'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel',
                    edit: 'TextInput'
                  },
                  source: 'warrantyInfo',
                  options: {
                    label: 'Warranty Text',
                    placeholder: 'Enter warranty information',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left',
                    lines: 15
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel',
                    edit: 'DateInput'
                  },
                  source: 'startDate',
                  options: {
                    label: 'Start Date',
                    placeholder: '',
                    fullWidth: true,
                    alignment: 'left',
                    searchableCategory: 'date'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel',
                    edit: 'DateInput'
                  },
                  source: 'endDate',
                  options: {
                    label: 'End Date',
                    placeholder: '',
                    fullWidth: true,
                    alignment: 'left',
                    searchableCategory: 'date'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      warrantyInfo: {
        type: 'string'
      }
    },
    required: ['warrantyInfo']
  },
  validationErrors: {
    warrantyInfo: {
      required: 'Warranty information is required.'
    }
  }
};
