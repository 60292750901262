import gql from 'graphql-tag';

const addNotesToJob = gql`
  mutation addNotesToJob($partitionKey: String!, $data: AddNotesToJobInput!) {
    addNotesToJob(partitionKey: $partitionKey, data: $data) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      subject
      note
      createdBy
      lastUpdatedDate
      lastUpdatedDateTime
      lastUpdatedBy
    }
  }
`;

export default addNotesToJob;
