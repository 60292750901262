import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ThemeProvider, Typography, TV, TW } from '@buildhero/sergeant';
import Box from '@material-ui/core/Box';

export const CircularProgressWithLabel = props => (
  <ThemeProvider>
    <Box position="relative" display="inline-flex" marginLeft={0.5}>
      <CircularProgress variant="determinate" {...props} color="secondary" />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant={TV.S1}
          weight={TW.BOLD}
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  </ThemeProvider>
);

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired
};

export default CircularProgressWithLabel;
