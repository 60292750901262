import gql from 'graphql-tag';

const getEmptyTimeCards = gql`
  query getEmptyTimeCards(
    $partitionKey: String!
    $SortKey: String!
    $visitRange: GenericFilterInput
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $SortKey) {
      id
      visitsView(entityConnection: "Visit", filter: $visitRange) {
        items {
          primaryTechs(entityConnection: "VisitPrimaryTech") {
            items {
              mappedEntityId
            }
          }
          extraTechs(entityConnection: "VisitExtraTech") {
            items {
              mappedEntityId
            }
          }
          id
          scheduledFor
          timeCardVisitsView(entityConnection: "TimeCardVisit") {
            items {
              id
            }
          }
        }
      }
    }
  }
`;

export default getEmptyTimeCards;
