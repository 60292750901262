import gql from 'graphql-tag';

const getInvoiceAdditionalInfoByInvoice = gql`
  query getInvoiceAdditionalInfoByInvoice($partitionKey: String!, $sortKey: String!) {
    getInvoice(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      sortKey
      summaries(entityConnection: "Summary") {
        items {
          id
          sortKey
          includeInInvoice
          summary
        }
      }
      attachments(entityConnection: "Attachment") {
        items {
          id
          fileUrl
          fileName
          customFileName
          includeInInvoice
        }
      }
      customerSignatures(entityConnection: "CustomerSignature") {
        items {
          id
          nameOfSignee
          signedDateTime
        }
      }
      notes(entityConnection: "Note") {
        items {
          id
          sortKey
          note
        }
      }
      termsOfService
    }
  }
`;

export default getInvoiceAdditionalInfoByInvoice;
