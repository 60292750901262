export const mockData = `<p style="text-align:center;">[[CompanyLogo]]</p>
<p style="text-align:center;">
    <span class="text-small">[[CompanyAddress]] * [[CompanyPhone]]</span>
</p>
<p>&nbsp;</p>
<figure class="table" style="width:95%;">
    <table>
        <tbody>
            <tr>
                <td style="width:50%;">
                    <p><span class="text-small">Attn: [[OrderedBy]]</span></p>
                    <p><span class="text-small">Phone: [[OrderedByPhone]]</span></p>
                    <p><span class="text-small">Email: [[OrderedByEmail]]</span></p>
                    <p><span class="text-small">Company: [[CustomerName]] </span></p>
                    <p><span class="text-small">Address: [[BillToAddress]]</span></p>
                </td>
                <td style="width:50%;">
                    <p><span class="text-small">Date: [[QuoteCreationDate]]</span></p>
                    <p><span class="text-small">Re: [[QuoteSubject]]</span></p>
                    <p><span class="text-small">File Number: [[QuoteNumber]]</span></p>
                    <p><span class="text-small">Property: [[PropertyName]]&nbsp;</span></p>
                    <p><span class="text-small">Address: [[PropertyAddress]]</span></p>
                </td>
            </tr>
        </tbody>
    </table>
</figure>
<p>&nbsp;</p>
<p>As requested, we are pleased to offer our electrical proposal for the above referenced project as follows:&nbsp;</p>
<p>&nbsp;</p>
<p><strong>BID BASIS&nbsp;</strong></p>
<p>This proposal is based on the following documentation and as indicated in the scope of work below.&nbsp;</p>
<ol>
    <li>&nbsp;</li>
    <li>&nbsp;</li>
</ol>
<p><strong>SPECIFIC INCLUSIONS&nbsp;</strong></p>

<p><span class="text-small">[[Tasks]]</span></p>

<p><strong>QUALIFICATIONS</strong>&nbsp;</p>
<ol>
    <li>Our offer is firm for 30 thirty days from the date listed above.</li>
    <li>Our offer is predicated upon a clear and accessible area that will be made available by others, where our work is to be performed.</li><li>Unless noted in the specific inclusions, our work will be performed during our normal working hours and workweek.</li>
</ol>
<p><strong>SPECIFIC EXCLUSIONS&nbsp;</strong></p>

<p><span class="text-small">[[TaskGroups]]</span></p>

<p>This proposal is based on the following documentation and as indicated in the scope of work below.&nbsp;</p>

 [[TotalsandSubtotals]]
<figure class="table" style="width:100%;">
    <table style="border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);">
        <tbody>
            <tr>
                <td style="border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);text-align:right;">
                    <h3>Total Proposal as Outlined Above……………….</h3>
                 </td>
                <td>[[Total]]</td>
             </tr>
        </tbody>
    </table>
</figure>

<p>&nbsp;</p>
<p>&nbsp;</p>
<p>Our goal is to provide quality electrical construction and competitive pricing. We will accomplish this by performing efficiently, timely, safely and to your complete and total satisfaction. Thank you for allowing us to proceed by authorizing the work in the space provided below.&nbsp;</p><p>&nbsp;</p><p>Sincerely,&nbsp;</p><p>Project manager &nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p><strong>ACCEPTANCE OF PROPOSAL&nbsp;</strong></p><p>This proposal represents the entire agreement between the parties. There are no representations, promises or other understandings unless expressly included herein.</p>
`;
