import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import labourLineItems, { subtotalMeta } from 'meta/Jobs/LabourLineItems/table';
import { AddRecordButton } from 'components';
import useLabourTypes from 'customHooks/useLabourTypes';
import useLabourRates from 'customHooks/useLabourRates';
import usePayrollBillingHourTypes from 'customHooks/usePayrollBillingHourTypes';
import Table from './Table';
import { saveLabourRateLineItems, deleteLabourRateLineItem, getTimesheetByVisit } from './service';
import {
  overAllTotal,
  handleRemove,
  editLineItem,
  newItemTemplate,
  modifyLineItemMeta,
  checkLineItemPropEmpty,
  getLineItemsForLabourRateSheet,
  addTimesheetEmployeeToLineItem
} from './helpers';

import { emptyFieldMessage, emptyFieldMessageForDelete } from './constants';
import useStyles from './LineItem.styles';

const LineItemTable = ({
  employees,
  data,
  user,
  snackbarOn,
  visitId,
  fetchReport,
  isDefaultRate,
  reviewReportId,
  departmentName,
  isEditable,
  timeCardLines,
  reportEditable,
  priceBookId,
  isLoading,
  setReportLoaded,
  reinitialize,
  triggerAddTimesheetEmployeesToLineItem,
  onInitializeComplete,
  onInitializeStart,
  onAddTimesheetEmployeeToLineItemStart,
  overwriteRatesOnInitialize,
  onChangeNumLineItems,
  onEdit
}) => {
  const classes = useStyles();
  const { tenantId, tenantCompanyId } = user;

  const [currentPage, setCurrentPage] = useState({
    page: 0,
    rowsPerPage: 10
  });

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [lineItems, setLineItems] = useState([]);
  const [visitTimesheet, setVisitTimesheet] = useState([]);

  const commonArgs = [tenantId, tenantCompanyId, snackbarOn, priceBookId];
  const [labourRates, , labourRatesLoaded] = useLabourRates(...commonArgs);
  const [payrollLabourTypes, , payrollLabourTypesLoaded] = useLabourTypes(...commonArgs);
  const [payrollBillingHourTypes, , payrollBillingHourTypesLoaded] = usePayrollBillingHourTypes(
    ...commonArgs
  );

  const [visitTimesheetLoaded, setVisitTimesheetLoaded] = useState(false);

  const initalizeLineItemsFromData = useCallback(() => {
    if (labourRatesLoaded) {
      onInitializeStart();
      const items = getLineItemsForLabourRateSheet({
        lineItems: data,
        employees,
        visitTimesheet,
        isDefaultRate,
        labourTypes: payrollLabourTypes,
        labourRates,
        overwriteRates: overwriteRatesOnInitialize
      });
      setLineItems(items);
      onChangeNumLineItems(items.length);
      onInitializeComplete();
    }
  }, [
    data,
    employees,
    visitTimesheet,
    isDefaultRate,
    payrollLabourTypes,
    labourRates,
    labourRatesLoaded,
    setLineItems,
    onInitializeStart,
    onInitializeComplete,
    overwriteRatesOnInitialize,
    onChangeNumLineItems
  ]);

  // on page load
  useEffect(() => {
    if (!isLoading && labourRatesLoaded && payrollLabourTypesLoaded && isFirstLoad) {
      setIsFirstLoad(false);
      setVisitTimesheetLoaded(false);

      initalizeLineItemsFromData();

      const successCallback = timesheets => setVisitTimesheet(timesheets);

      getTimesheetByVisit(visitId, successCallback);
      setVisitTimesheetLoaded(true);
    }
  }, [
    data,
    isFirstLoad,
    isDefaultRate,
    employees,
    labourRates,
    labourRatesLoaded,
    visitTimesheet,
    payrollLabourTypes,
    payrollLabourTypesLoaded,
    visitId,
    timeCardLines,
    isLoading,
    initalizeLineItemsFromData
  ]);

  // on reinitialize
  useEffect(() => {
    if (reinitialize && !isFirstLoad) {
      initalizeLineItemsFromData();
    }
  }, [reinitialize, initalizeLineItemsFromData, isFirstLoad]);

  // on create labour line items based on technicians who worked click
  useEffect(() => {
    if (triggerAddTimesheetEmployeesToLineItem) {
      onAddTimesheetEmployeeToLineItemStart();
      const newItems = addTimesheetEmployeeToLineItem({
        employees,
        labourRates,
        isDefaultRate,
        visitTimesheet,
        labourTypes: payrollLabourTypes,
        hourTypes: payrollBillingHourTypes
      }).filter(
        item => !lineItems.some(existingItem => existingItem.employeeId === item.employeeId)
      );
      const items = [...lineItems, ...newItems];
      setLineItems(items);
      onChangeNumLineItems(items.length);

      if (newItems.length === 0) {
        snackbarOn('error', 'All Timesheet employees already have labour line items');
      }
    }
  }, [
    triggerAddTimesheetEmployeesToLineItem,
    onAddTimesheetEmployeeToLineItemStart,
    employees,
    labourRates,
    isDefaultRate,
    visitTimesheet,
    payrollLabourTypes,
    payrollBillingHourTypes,
    lineItems,
    snackbarOn,
    onChangeNumLineItems
  ]);

  const calculateRowIndex = displayRowIndex =>
    currentPage.rowsPerPage * currentPage.page + displayRowIndex;

  const handleEdit = ({ isItemSelection = false, rowIndex, ...args }) => {
    onEdit();
    const updateValue = editLineItem({
      labourRates,
      lineItems,
      isDefaultRate,
      visitTimesheet,
      labourTypes: payrollLabourTypes,
      employees,
      rowIndex: calculateRowIndex(rowIndex),
      ...args
    });
    setLineItems(updateValue);
  };

  const handleDelete = (rowIndex, lineItemId) => {
    if (lineItemId) {
      const isPropNotEmpty = checkLineItemPropEmpty(lineItems);
      if (!isPropNotEmpty) {
        return snackbarOn('error', emptyFieldMessageForDelete);
      }

      setReportLoaded(false);

      const successCallback = () => {
        const successCallbackAfterDelete = () => {
          return fetchReport();
        };
        deleteLabourRateLineItem({
          tenantId,
          lineItemId,
          successCallback: successCallbackAfterDelete,
          snackbarOn
        });
      };

      // need to save temporary work before delete.
      saveLabourRateLineItems({
        reviewReportId,
        tenantId,
        snackbarOn,
        successCallback,
        labourRateLineItems: lineItems
      });
    } else {
      // this entry only existed in FE view
      const items = handleRemove(lineItems, calculateRowIndex(rowIndex));
      setLineItems(items);
      onChangeNumLineItems(items.length);
    }
  };

  const computedMeta = [
    ...modifyLineItemMeta(labourLineItems, payrollBillingHourTypes),
    subtotalMeta
  ];

  const handleAutocompleteSelect = ({ rowIndex, meta, value }) => {
    const { dataKey: key } = meta;
    handleEdit({ rowIndex, value, key, isItemSelection: true });
  };

  const handleAddNewItem = () => {
    const item = newItemTemplate(payrollBillingHourTypes);
    const items = [...lineItems, item];
    setLineItems(items);
    onChangeNumLineItems(items.length);
  };

  const handleSaveAction = () => {
    const isPropNotEmpty = checkLineItemPropEmpty(lineItems);
    if (!isPropNotEmpty) {
      return snackbarOn('error', emptyFieldMessage);
    }

    setReportLoaded(false);

    const successCallback = () => {
      fetchReport();
    };

    saveLabourRateLineItems({
      reviewReportId,
      tenantId,
      snackbarOn,
      successCallback,
      labourRateLineItems: lineItems
    });
  };

  return (
    <Grid className={classes.itemMargin} container flexDirection="column">
      <Table
        meta={computedMeta}
        onEdit={handleEdit}
        isLoading={
          isLoading ||
          !payrollBillingHourTypesLoaded ||
          !payrollLabourTypesLoaded ||
          !labourRatesLoaded ||
          !visitTimesheetLoaded
        }
        lineItems={lineItems}
        employees={employees}
        onDelete={handleDelete}
        isEditable={isEditable}
        isDefaultRate={isDefaultRate}
        reportEditable={reportEditable}
        labourTypes={payrollLabourTypes}
        departmentName={departmentName}
        labourLineItems={labourLineItems}
        handleAutocompleteSelect={handleAutocompleteSelect}
        onChangePage={page => setCurrentPage({ ...currentPage, page })}
        onChangeRowsPerPage={rowsPerPage => setCurrentPage({ ...currentPage, rowsPerPage })}
      />

      <Box flex={1} display="flex" justifyContent="flex-end" className={classes.itemMargin}>
        <Box display="flex" flex={1} flexDirection="column">
          <Box display="flex" flex={1}>
            <Box display="flex" pt={2} flex={1}>
              <div>
                <AddRecordButton
                  disabled={!reportEditable}
                  label="+ Add labor line item"
                  handle={handleAddNewItem}
                />
              </div>
            </Box>
            <Box display="flex" flex={1} p={1} flexDirection="column" alignItems="flex-end">
              <Typography variant="body1">Total</Typography>
              <Typography variant="h6">{overAllTotal(lineItems)}</Typography>
            </Box>
          </Box>
          <Box pt={1} flex={1} justifyContent="flex-end" display="flex">
            <Button
              color="primary"
              variant="contained"
              onClick={handleSaveAction}
              className={classes.saveButton}
              disabled={!reportEditable}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default LineItemTable;
