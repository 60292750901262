import React, { useState } from 'react';

import { Input, ThemeProvider, FieldType, Field } from '@buildhero/sergeant';

import { secondsToHour } from 'scenes/Payroll/TimeTrackingReport/helpers';

const HourInput = ({
  workEvent,
  hourTypeAbbreviation,
  updateTimesheetEntry = () => {},
  isEditable,
  isIncomplete,
  hourType,
  style
}) => {
  const { timesheetHours } = workEvent;
  const [displayValue, setDisplayValue] = useState();

  const getValue = () => {
    if (displayValue !== undefined) return displayValue;

    return Number.isInteger(timesheetHours[hourTypeAbbreviation].actualTotalDurationOverride)
      ? secondsToHour(timesheetHours[hourTypeAbbreviation].actualTotalDurationOverride)
      : secondsToHour(timesheetHours[hourTypeAbbreviation].actualTotalDuration);
  };

  const value = getValue();

  return (
    <ThemeProvider>
      {isEditable ? (
        <Input
          label={hourTypeAbbreviation}
          placeholder="0.0"
          type={FieldType.NUMBER}
          value={value}
          disabled={isIncomplete}
          onChange={e => setDisplayValue(e.target.value)}
          onBlur={e => {
            updateTimesheetEntry({
              id: timesheetHours[hourTypeAbbreviation].id,
              actualTotalDurationOverride: parseInt(parseFloat(e.target.value || 0) * 3600, 10),
              extra: {
                workEvent,
                hourType
              }
            });
            setDisplayValue(undefined);
          }}
          style={{ maxWidth: 100, ...style }}
        />
      ) : (
        <Field
          style={{ width: '100%', maxWidth: 100, ...style }}
          label={hourTypeAbbreviation}
          placeholder="0.0"
          type={FieldType.NUMBER}
          value={value}
        />
      )}
    </ThemeProvider>
  );
};

export default HourInput;
