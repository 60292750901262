const styles = theme => ({
  rateDescription: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#959595'
  },
  addCardOuter: {
    border: 'dashed 2px #2dce89',
    minHeight: '500px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  addCardInner: {
    textAlign: 'left',
    width: '300px',
    margin: '0 auto'
  },
  mb2: {
    marginBottom: '0.5rem'
  }
});
export default styles;
