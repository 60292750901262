import * as Yup from 'yup';
import { EntityType } from 'utils/constants';
import { nameValidationField, addressValidationFields } from 'meta/validations';

export const phoneValidation = /(\+\d{1,2}\s?-?)??\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/;
export const USZipcode = /^[0-9]{5}(?:-[0-9]{4})?$/;
export const CanadaZipcode = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
export const email = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*([,|;]\s*\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)*$/;

const tenantSchema = Yup.object().shape({
  companyName: Yup.string().required('Company Name is required'),
  accountName: Yup.string(),
  ownerName: Yup.string(),
  tier: Yup.string(),
  email: Yup.string()
    .required('email is required')
    .email('Invalid email'),
  status: Yup.string(),
  userName: Yup.string().email('Invalid email'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required')
});

const customerSchema = isEmailRequired =>
  Yup.object().shape({
    ...nameValidationField('customerName', EntityType.CUSTOMER, 'Customer Name is required.'),
    customerTypeValue: Yup.string(),
    status: Yup.string(),
    phonePrimary: Yup.string().matches(phoneValidation, 'Invalid phone number'),
    email: (isEmailRequired
      ? Yup.string()
          .trim()
          .required('Email Address is required.')
      : Yup.string().trim()
    ).matches(email, 'Invalid email'),
    billingAddress: Yup.string(),
    ...addressValidationFields('billing', true),
    billingBillTo: Yup.string().required('Bill To is required.'),
    billingZipcode: Yup.string()
      .when('billingCountry', {
        is: val => val === 'United States',
        then: Yup.string().matches(USZipcode, 'Invalid zipcode'),
        otherwise: Yup.string()
      })
      .when('billingCountry', {
        is: val => val === 'Canada',
        then: Yup.string().matches(CanadaZipcode, 'Invalid zipcode'),
        otherwise: Yup.string()
      })
      .required('Zip/Postal Code is required.'),
    businessAddress: Yup.string(),
    businessAddressIsSame: Yup.boolean(),
    receiveSMS: Yup.boolean(),
    businessZipcode: Yup.string()
      .when('businessCountry', {
        is: val => val === 'United States',
        then: Yup.string().matches(USZipcode, 'Invalid zipcode'),
        otherwise: Yup.string()
      })
      .when('businessCountry', {
        is: val => val === 'Canada',
        then: Yup.string().matches(CanadaZipcode, 'Invalid zipcode'),
        otherwise: Yup.string()
      }),
    customerId: Yup.string()
  });

const companySchema = Yup.object().shape({
  companyName: Yup.string()
    .trim()
    .required('Company name is required'),
  addLogo: Yup.string().nullable(),
  shippingAddressLine1: Yup.string()
    .trim()
    .required('Address line is required'),
  shippingAddressLine2: Yup.string().nullable(),
  shippingCity: Yup.string().required('City is required'),
  shippingState: Yup.string().required('State is required'),
  shippingZipcode: Yup.string()
    .required('Zipcode is required')
    .when('country', {
      is: val => val === 'United States',
      then: Yup.string().matches(USZipcode, 'Invalid zipcode'),
      otherwise: Yup.string()
    })
    .when('country', {
      is: val => val === 'Canada',
      then: Yup.string().matches(CanadaZipcode, 'Invalid zipcode'),
      otherwise: Yup.string()
    }),
  billingAddressIsSame: Yup.boolean().nullable(),
  billingAddressLine1: Yup.string()
    .trim()
    .required('Address line is required'),
  billingAddressLine2: Yup.string().nullable(),
  billingCity: Yup.string().required('City is required'),
  billingState: Yup.string().required('State is required'),
  billingZipcode: Yup.string()
    .required('Zipcode is required')
    .when('country', {
      is: val => val === 'United States',
      then: Yup.string().matches(USZipcode, 'Invalid zipcode'),
      otherwise: Yup.string()
    })
    .when('country', {
      is: val => val === 'Canada',
      then: Yup.string().matches(CanadaZipcode, 'Invalid zipcode'),
      otherwise: Yup.string()
    }),
  email: Yup.string()
    .trim()
    .required('Email is required')
    .email('Invalid email'),
  fax: Yup.string()
    .matches(phoneValidation, 'Invalid fax number')
    .nullable(),
  phonePrimary: Yup.string()
    .required('Phone is required')
    .matches(phoneValidation, 'Invalid phone number'),
  websiteUrl: Yup.string().nullable(),
  purchaseOrderTermsAndConditions: Yup.string()
    .nullable()
    .max(2000, 'You have exceeded the maximum 2000 character limit.')
});

const departmentSchema = Yup.object().shape({
  departmentName: Yup.string()
    .trim()
    .required('Department name is required')
});

const employeeSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required('Firstname is required'),
  lastName: Yup.string()
    .trim()
    .required('Lastname is required'),
  homeAddressLine1: Yup.string(),
  homeAddressLine2: Yup.string(),
  homeCity: Yup.string(),
  homeState: Yup.string(),
  homeZipcode: Yup.string()
    .when('country', {
      is: val => val === 'United States',
      then: Yup.string().matches(USZipcode, 'Invalid zipcode'),
      otherwise: Yup.string()
    })
    .when('country', {
      is: val => val === 'Canada',
      then: Yup.string().matches(CanadaZipcode, 'Invalid zipcode'),
      otherwise: Yup.string()
    }),
  email: Yup.string()
    .trim()
    .required('Email is required')
    .email('Invalid email'),
  userName: Yup.string()
    .trim()
    .required('Username is required'),
  personalEmail: Yup.string()
    .trim()
    .email('Invalid email')
    .nullable(),
  cellPhone: Yup.string()
    .trim()
    .matches(phoneValidation, 'Invalid phone number')
    .nullable(),
  landlinePhone: Yup.string()
    .matches(phoneValidation, 'Invalid phone number')
    .nullable(),
  appRole: Yup.string().required('Select a role'),
  departments: Yup.array()
    .of(Yup.string())
    .required('Select a department'),
  nickName: Yup.string().nullable()
});

const propertySchema = Yup.object().shape({
  companyName: Yup.string()
    .trim()
    .required('Property name is required')
    .matches(/^[^:]+$/, 'Property name cannot contain :'),
  propertyAddressLine1: Yup.string()
    .trim()
    .required('Address Line is required'),
  propertyAddressLine2: Yup.string(),
  propertyCity: Yup.string().required('City is required'),
  propertyState: Yup.string().required('State is required'),
  propertyZipcode: Yup.string()
    .required('Zipcode is required')
    .when('country', {
      is: val => val === 'United States',
      then: Yup.string().matches(USZipcode, 'Invalid zipcode'),
      otherwise: Yup.string()
    })
    .when('country', {
      is: val => val === 'Canada',
      then: Yup.string().matches(CanadaZipcode, 'Invalid zipcode'),
      otherwise: Yup.string()
    }),
  billingZipcode: Yup.string()
    .when('country', {
      is: val => val === 'United States',
      then: Yup.string().matches(USZipcode, 'Invalid zipcode'),
      otherwise: Yup.string()
    })
    .when('country', {
      is: val => val === 'Canada',
      then: Yup.string().matches(CanadaZipcode, 'Invalid zipcode'),
      otherwise: Yup.string()
    })
});

const customerRepSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required('Firstname is required'),
  lastName: Yup.string(),
  contactRole: Yup.string(),
  cellPhone: Yup.string().matches(phoneValidation, 'Invalid phone number'),
  landlinePhone: Yup.string().matches(phoneValidation, 'Invalid phone number'),
  email: Yup.string()
    .trim()
    .email('Invalid email')
});

const customerPropertyCustomerRepSchema = Yup.object().shape({
  customerRep: Yup.array(),
  firstName: Yup.string().when('customerRep', {
    is: val => !val?.length,
    then: Yup.string()
      .trim()
      .required('Firstname is required'),
    otherwise: Yup.string().notRequired()
  }),
  lastName: Yup.string(),
  contactRole: Yup.string(),
  cellPhone: Yup.string().matches(phoneValidation, 'Invalid phone number'),
  landlinePhone: Yup.string().matches(phoneValidation, 'Invalid phone number'),
  email: Yup.string()
    .trim()
    .email('Invalid email')
});

const tenantRepSchema = Yup.object().shape({
  name: Yup.string().required('Name is required')
});

const propertyAssetSchema = Yup.object().shape({
  assetName: Yup.string()
    .trim()
    .required('Asset name is required')
});

const attachmentSchema = Yup.object().shape({
  fileName: Yup.string()
    .trim()
    .required('Please upload the attachment')
});

const reviewReportAttachementSchema = Yup.object().shape({
  fileName: Yup.string()
    .trim()
    .required('Please upload the attachment'),
  customFileName: Yup.string()
    .trim()
    .required('Please provide a file name'),
  type: Yup.string()
    .trim()
    .required('Type is required')
});

const jobSchema = Yup.object().shape({
  customerName: Yup.string()
    .trim()
    .required('Customer name is required'),
  customerPropertyId: Yup.string().required('Select a property'),
  customerRepName: Yup.string(),
  amountNotToExceed: Yup.number().typeError('Invalid number'),
  amountQuoted: Yup.number().typeError('Invalid number'),
  jobTypeName: Yup.string(),
  priority: Yup.string(),
  customerProvidedPONumber: Yup.string(),
  customIdentifier: Yup.string().when('customJobNumber', {
    is: true,
    then: Yup.string()
      .trim()
      .required('Job number is required')
      .matches(/^[^:]+$/, 'Job number cannot contain :'),
    otherwise: Yup.string().notRequired()
  })
});

const addDepartmentField = base =>
  base.clone().shape({
    departmentId: Yup.string().required('Department is required')
  });

const discountToReviewReportSchema = Yup.object().shape({
  itemName: Yup.string().required('Name is required'),
  reasonForDiscount: Yup.string().required('Reason is required'),
  amount: Yup.number()
    .required('Amount is required')
    .typeError('Invalid number')
});

const discountToInvoiceSchema = addDepartmentField(
  Yup.object().shape({
    itemName: Yup.string().required('Name is required'),
    description: Yup.string().required('Reason is required'),
    amount: Yup.number()
      .required('Amount is required')
      .typeError('Invalid number')
  })
);

const flatRateToReviewReportSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description  is required'),
  quantity: Yup.number()
    .required('Quantity  is required')
    .typeError('Invalid number'),
  unitPrice: Yup.number()
    .required('Price is required')
    .typeError('Invalid number')
});

const inventoryPartsToReviewReportSchema = Yup.object().shape({
  itemName: Yup.string().required('Name is required'),
  description: Yup.string().required('Description  is required'),
  quantity: Yup.number()
    .required('Quantity  is required')
    .typeError('Invalid number'),
  unitPrice: Yup.number()
    .required('Unit price is required')
    .typeError('Invalid number'),
  unitCost: Yup.number()
    .required('Unit cost is required')
    .typeError('Invalid number')
});

const laborLineItemToReviewReportSchema = Yup.object().shape({
  itemName: Yup.string().required('Name is required'),
  description: Yup.string().required('Description  is required'),
  billableTimeInHours: Yup.number()
    .required('Billable hours is required')
    .typeError('Invalid number'),
  hourlyCharge: Yup.number()
    .required('Hourly charge is required')
    .typeError('Invalid number')
});

const purchaseOrderToReviewReportSchema = Yup.object().shape({
  vendorName: Yup.string().required('Name is required'),
  poNumber: Yup.string().required('PO number is required'),
  dateOfPurchase: Yup.string()
    .required('Date of purchase  is required')
    .typeError('Invalid date'),
  totalAmountPreTax: Yup.number()
    .required('Tax is required')
    .typeError('Invalid number'),
  tax: Yup.number().typeError('Invalid number')
});

const reviewReportPurchaseOrderLinesSchema = Yup.object().shape({
  itemName: Yup.string().required('Name is required'),
  description: Yup.string().required('Description  is required'),
  quantity: Yup.number()
    .required('Quantity  is required')
    .typeError('Invalid number'),
  unitPrice: Yup.number()
    .required('Price is required')
    .typeError('Invalid number'),
  unitCost: Yup.string().required('Unit Cost is required'),
  amount: Yup.number()
    .required('Price is required')
    .typeError('Invalid number')
});

const reviewReportBillLinesSchema = Yup.object().shape({
  itemName: Yup.string().required('Name is required'),
  description: Yup.string().required('Description  is required'),
  quantity: Yup.number()
    .required('Quantity  is required')
    .typeError('Invalid number'),
  unitPrice: Yup.number()
    .required('Price is required')
    .typeError('Invalid number'),
  unitCost: Yup.string().required('Unit Cost is required')
});

const [
  flatRateToInvoiceSchema,
  laborLineItemToInvoiceSchema,
  inventoryPartsToInvoiceSchema,
  purchaseOrderLinesToInvoiceSchema
] = [
  flatRateToReviewReportSchema,
  laborLineItemToReviewReportSchema,
  inventoryPartsToReviewReportSchema,
  reviewReportPurchaseOrderLinesSchema
].map(addDepartmentField);

const summariesToReviewReportSchema = Yup.object().shape({
  summary: Yup.string().required('Summary is required')
});

const taxRateSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  taxRate: Yup.number()
    .required('Tax rate is required')
    .typeError('Invalid number')
});

const addItemSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  unitCost: Yup.string().required('Unit Cost is required')
});

const editItemSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  unitPrice: Yup.string().required('Unit Price is required'),
  unitCost: Yup.string().required('Unit Cost is required')
});

const createPricebook = Yup.object().shape({
  name: Yup.string().required('Name is required')
});

const newQuote = Yup.object().shape({
  name: Yup.string().required('General scope is required')
});

const quoteTaskSchema = Yup.object().shape({
  name: Yup.string().required('Scope is required'),
  quantity: Yup.number()
    .typeError('Invalid number')
    .when('unitPrice', {
      is: val => val > 0,
      then: Yup.number()
        .typeError('Invalid number')
        .required('Quantity is required'),
      otherwise: Yup.number().notRequired()
    })
});

const customerPropertyTaskSchema = Yup.object().shape({
  name: Yup.string().required('Scope is required')
});

const companyPhoneAndEmailSchema = isEmailRequired =>
  Yup.object().shape({
    email: (isEmailRequired
      ? Yup.string()
          .trim()
          .required('Email address is required')
      : Yup.string().trim()
    )
      .matches(email, 'Invalid email')
      .nullable(),
    phonePrimary: Yup.string()
      .nullable()
      .matches(phoneValidation, 'Invalid phone number')
  });

export default {
  addItemSchema,
  attachmentSchema,
  companyPhoneAndEmailSchema,
  companySchema,
  createPricebook,
  customerPropertyCustomerRepSchema,
  customerPropertyTaskSchema,
  customerRepSchema,
  customerSchema,
  departmentSchema,
  discountToInvoiceSchema,
  discountToReviewReportSchema,
  editItemSchema,
  employeeSchema,
  flatRateToInvoiceSchema,
  flatRateToReviewReportSchema,
  inventoryPartsToInvoiceSchema,
  inventoryPartsToReviewReportSchema,
  jobSchema,
  laborLineItemToInvoiceSchema,
  laborLineItemToReviewReportSchema,
  newQuote,
  propertyAssetSchema,
  propertySchema,
  purchaseOrderLinesToInvoiceSchema,
  purchaseOrderToReviewReportSchema,
  quoteTaskSchema,
  reviewReportAttachementSchema,
  reviewReportBillLinesSchema,
  reviewReportPurchaseOrderLinesSchema,
  summariesToReviewReportSchema,
  taxRateSchema,
  tenantRepSchema,
  tenantSchema
};
