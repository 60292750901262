import gql from 'graphql-tag';

const getCustomFieldsDefinitions = gql`
  query($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      version
      customerTags(entityConnection: "CustomerTag") {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      customerTypes(entityConnection: "CustomerType") {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      customerPropertyTypes(entityConnection: "CustomerPropertyType") {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      jobTypes(entityConnection: "JobType") {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      jobTags(entityConnection: "JobTag", filter: { isActive: { ne: false } }) {
        items {
          tagType
          tagName
          sortOrder
          showOnMobile
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      invoiceTags(entityConnection: "InvoiceTag", limit: 50, filter: { isActive: { ne: false } }) {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      serviceAgreementTags(filter: { isActive: { ne: false } }) {
        items {
          id
          tagName
          sortOrder
          isActive
        }
      }
      quoteTags(entityConnection: "QuoteTag", limit: 50, filter: { isActive: { ne: false } }) {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      purchaseOrderTags(limit: 50) {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
        }
      }
      taskTypes(entityConnection: "TaskType", limit: 50) {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      assetTypes(entityConnection: "AssetType") {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      projectTypes {
        items {
          id
          tenantId
          name
          sortOrder
        }
      }
      projectSubTypes {
        items {
          id
          name
          typeId
          sortOrder
        }
      }
      productCategories(entityConnection: "ProductCategory") {
        items {
          id
          name
          sortOrder
        }
      }
    }
  }
`;
export default getCustomFieldsDefinitions;
