import gql from 'graphql-tag';

const addPayrollHourTypesToCompany = gql`
  mutation addPayrollHourTypesToCompany(
    $partitionKey: String
    $data: AddPayrollHourTypesToCompanyInput!
  ) {
    addPayrollHourTypesToCompany(partitionKey: $partitionKey, data: $data) {
      id
      version
      isActive
      hourType
      hourTypeAbbreviation
      isAutofill
      intacctAccountNumber
      intacctNonBillableAccountNumber
      billingHourTypeId
    }
  }
`;

export default addPayrollHourTypesToCompany;
