import React from 'react';

import { array, func, shape } from 'prop-types';

import FilesList from '@pm/components/AttachmentsV2/components/FilesList';

const FilesListWrapper = ({ field, options }) => (
  <FilesList
    files={field.value.files}
    selectable
    titleText="Select Files"
    onSelect={options.onSelectFiles}
  />
);

FilesListWrapper.propTypes = {
  field: shape({
    value: shape({
      images: array.isRequired
    }).isRequired
  }).isRequired,
  options: shape({
    onSelectFiles: func.isRequired
  }).isRequired
};

export default FilesListWrapper;
