import gql from 'graphql-tag';

const addCompanySetting = gql`
  mutation AddCompanySetting($partitionKey: String!, $data: AddCompanySettingsToCompanyInput!) {
    addCompanySettingsToCompany(partitionKey: $partitionKey, data: $data) {
      id
      type
      version
      isActive
      settings
    }
  }
`;

export default addCompanySetting;
