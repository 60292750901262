import React from 'react';
import { string, object } from 'prop-types';
import {
  VisitCloneIcon,
  VisitContinueAsNewIcon,
  VisitDeleteIcon,
  VisitReleaseIcon,
  CheckCircleOutline
} from '@dispatch/icons';
import Divider from '@material-ui/core/Divider';
import { useStyles } from './MenuItems.styles';

export const MenuItem = ({ text, icon, showDivider }) => {
  const classes = useStyles();

  return (
    <div className={classes.itemContainer}>
      {showDivider && <Divider className={classes.divider} />}
      {icon && (
        <div className={classes.imgContainer}>
          <img alt={text} src={icon} className={classes.img} />
        </div>
      )}
      <div className={classes.text}>{text}</div>
    </div>
  );
};

MenuItem.propTypes = {
  text: string.isRequired,
  // eslint-disable-next-line react/require-default-props
  icon: object,
  // eslint-disable-next-line react/require-default-props
  showDivider: Boolean
};

export const CloneVisitMenuItem = <MenuItem text="Clone Visit" icon={VisitCloneIcon} />;

export const ContinueVisitAsNewMenuItem = (
  <MenuItem text="Continue in a New Visit" icon={VisitContinueAsNewIcon} />
);

export const DeleteMenuItem = <MenuItem text="Delete" icon={VisitDeleteIcon} />;

export const DeleteAllMenuItem = <MenuItem text="Delete All" icon={VisitDeleteIcon} />;

export const ReleaseToTechMenuItem = <MenuItem text="Release to Tech" icon={VisitReleaseIcon} />;

export const CreatePurchaseOrderMenuItem = <MenuItem text="Create Purchase Order" />;

export const CreateFieldOrderMenuItem = <MenuItem text="Create Field Order" />;

export const CompleteProjectVisit = (
  <MenuItem showDivider text="Mark Visit as Complete" icon={CheckCircleOutline} />
);
