import gql from 'graphql-tag';

const getProducts = gql`
  query getProducts(
    $partitionKey: String!
    $sortKey: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      products: productsView(
        entityConnection: "Product"
        filter: $filter
        pagination: { limit: $limit, offset: $offset }
        sorting: $sort
      ) {
        nextToken
        items {
          id
          name
          description
          code
          isActive
          taxable
          unitCost
          taxable
          createdDate
          version
          accountingRefId
          syncStatus
          costCodeId
          costCode(entityConnection: "CostCode") {
            id
            name
            description
          }
          jobCostTypeId
          jobCostType(entityConnection: "jobCostType") {
            id
            name
            type
          }
          revenueTypeId
          revenueType(entityConnection: "revenueType") {
            id
            name
            type
          }
          itemGlGroupId
          itemGlGroup {
            id
            name
          }
          unitOfMeasureId
          unitOfMeasure(entityConnection: "UnitOfMeasure") {
            id
            name
            unitOfMeasureType
            unitOfMeasureCategory
          }
          glIncomeAccountRef(entityConnection: "glIncomeAccountRef") {
            name
            id
            sortKey
          }
          glExpenseAccountRef(entityConnection: "glExpenseAccountRef") {
            name
            id
            sortKey
          }
          productVendors(entityConnection: "ProductVendor") {
            items {
              id
              vendor(entityConnection: "Vendor") {
                id
                name
                sortKey
              }
            }
          }
        }
      }
    }
  }
`;

export default getProducts;
