import React, { useMemo } from 'react';

import { ThemeProvider } from '@buildhero/sergeant';
import { jsx } from '@emotion/react';
import { map } from 'lodash';

import WrapTable from 'components/WrapTable';
import { JobCloseoutTypes } from 'scenes/JobCloseout/constants';
import { getJobCloseoutType } from 'scenes/JobCloseout/utils';
import { InvoiceItemType } from 'utils/constants';

import { SectionHeader } from '../Components';
import { getDiscountItemsFromQuote, getInvoiceLineItemsByLineItemType } from '../selectors';

import { DiscountsColumns } from './DiscountsColumns';

export const InvoiceDiscounts = ({ jobData }) => {
  const jobCloseoutType = useMemo(() => getJobCloseoutType(jobData), [jobData]);
  const isTandMJob = jobCloseoutType === JobCloseoutTypes.TIME_AND_MATERIAL;
  const invoiceDiscountRows = useMemo(
    () => getInvoiceLineItemsByLineItemType(jobData, [InvoiceItemType.DISCOUNT]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobData.invoices?.items?.length]
  );

  const discountItemsFromQuote = useMemo(
    () => (isTandMJob ? [] : getDiscountItemsFromQuote(jobData)),
    // eslint-disable-next-line react/prop-types
    [jobData.quoteJobs?.items?.length, isTandMJob]
  );

  // TODO: reframe the dedupe logic based on a flag in invoice
  const itemNames = map(discountItemsFromQuote, 'name');
  const dedupedInvoiceDiscounts = invoiceDiscountRows.filter(row => !itemNames.includes(row.name));
  const rows = [...discountItemsFromQuote, ...dedupedInvoiceDiscounts];

  if (!rows.length) return null;

  return (
    <ThemeProvider>
      <SectionHeader title="Discounts" />
      <WrapTable
        columns={DiscountsColumns}
        rows={rows}
        hideFooter={rows.length <= 10}
        noDataMessage="No Discounts"
        enableTotalsRow
      />
    </ThemeProvider>
  );
};
