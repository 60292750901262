import theme from 'themes/BuildHeroTheme';

const projectStatusChips = {
  Bid: {
    name: {
      en: 'Bid'
    },
    backgroundColor: theme.palette.grayscale(60),
    textColor: 'white'
  },
  Accepted: {
    name: {
      en: 'Accepted'
    },
    backgroundColor: theme.palette.grayscale(20),
    textColor: 'white'
  },
  'Work in Progress': {
    name: {
      en: 'Work in Progress'
    },
    backgroundColor: theme.palette.brand.green,
    textColor: 'white'
  },
  'Work Complete': {
    name: {
      en: 'Work Complete'
    },
    backgroundColor: theme.palette.brand.logoBlue,
    textColor: 'white'
  },
  Closed: {
    name: {
      en: 'Closed'
    },
    backgroundColor: theme.palette.grayscale(20),
    textColor: 'white'
  }
};

export default projectStatusChips;
