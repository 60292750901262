import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  statusTitle: {
    fontSize: '10px',
    fontWeight: 'normal',
    textTransform: 'uppercase'
  },
  statusContent: {
    marginTop: theme.spacing(0.5)
  },
  customLabelTitle: {
    fontSize: 10,
    fontWeight: 'normal',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(0.5)
  },
  blueDivider: {
    backgroundColor: theme.palette.secondary.main,
    height: '2px',
    marginTop: 12
  }
}));
