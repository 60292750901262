import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  selectionInput: {
    background: theme.palette.grayscale(96),
    padding: theme.spacing(0.5),
    paddingTop: theme.spacing(0.7),
    paddingBottom: theme.spacing(0.7),
    '& p': {
      fontSize: '20px',
      marginLeft: '5px',
      color: theme.palette.text.primary
    }
  },
  selectionWrapper: {
    padding: 0,
    minWidth: 400,
    maxWidth: 400,
    overflow: 'auto',
    minHeight: 'auto !important',
    backgroundColor: `${theme.palette.grayscale(100)} !important`
  },
  selectionInputPlaceholder: {
    '& label': {
      fontSize: '20px',
      color: theme.palette.text.primary
    }
  },
  input: {
    fontWeight: 'normal',
    fontSize: '16px',
    padding: theme.spacing(1),
    background: theme.palette.grayscale(96),
    margin: `${theme.spacing(0.5)}px 0`,
    '&:hover': {
      background: theme.palette.grayscale(92)
    },
    '& input': {
      padding: 0
    }
  },
  time: {
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '20px',
    textAlign: 'center',
    color: theme.palette.grayscale(10),
    '&:hover': {
      background: theme.palette.grayscale(92)
    }
  },
  overlap: {
    marginLeft: theme.spacing(0.5),
    borderRadius: 4,
    border: `1px solid ${theme.palette.error.main}`
  },
  timeInput: {
    marginRight: 10,
    width: 100,
    '& button': {
      display: 'none'
    }
  },
  timeInputFocused: {
    background: theme.palette.grayscale(92)
  },
  timeInputRoot: {
    '&:hover': {
      background: theme.palette.grayscale(92)
    },
    '& input': {
      textTransform: 'uppercase'
    },
    background: theme.palette.grayscale(96),
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  itemBorder: {
    borderBottom: `1px solid ${theme.palette.grayscale(80)}`
  }
}));
