import gql from 'graphql-tag';

const softDeleteTask = gql`
  mutation softDeleteTask($partitionKey: String!, $id: String!) {
    softDeleteTask(partitionKey: $partitionKey, id: $id) {
      id
    }
  }
`;

export default softDeleteTask;
