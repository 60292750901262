const padding = 12;

export const MaintenanceForm = ({
  jobTagOptions,
  assetOptions,
  propertyOptions,
  maintenanceFrequencyOptions,
  handlePropertyChange,
  isLoadingAssets,
  maintenanceTypeOptions,
  departments,
  crews,
  technicians,
  showPreferredTechnicians,
  setAssetOptions,
  showTotalBudgetedHours,
  crewTimeTracking
}) => ({
  fields: {},
  layouts: {
    edit: {
      options: {
        pageSize: 'LETTER',
        direction: 'column'
      },
      contents: [
        {
          options: {
            direction: 'column'
          },
          contents: [
            {
              options: {
                direction: 'column',
                padding
              },
              contents: [
                {
                  component: {
                    default: 'Title'
                  },
                  options: {
                    label: 'General Information'
                  }
                },
                {
                  options: {
                    direction: 'row'
                  },
                  contents: [
                    {
                      options: {
                        direction: 'column',
                        padding,
                        paddingLeft: 0
                      },
                      contents: [
                        {
                          component: {
                            edit: 'SelectInput',
                            view: 'FieldWithLabel'
                          },
                          source: 'propertyId',
                          options: {
                            label: 'Property',
                            isRequired: true,
                            inputOptions: propertyOptions,
                            onChange: handlePropertyChange
                          }
                        }
                      ]
                    },
                    {
                      options: {
                        direction: 'column',
                        padding
                      },
                      contents: [
                        {
                          component: {
                            edit: 'SelectInput',
                            view: 'FieldWithLabel'
                          },
                          source: 'maintenanceTypeId',
                          options: {
                            label: 'Maintenance Type',
                            inputOptions: maintenanceTypeOptions,
                            enableSort: false
                          }
                        }
                      ]
                    },
                    {
                      options: {
                        direction: 'column',
                        padding
                      },
                      contents: [
                        {
                          component: {
                            edit: 'SelectInput',
                            view: 'FieldWithLabel'
                          },
                          source: 'schedule',
                          options: {
                            label: 'Maintenance Schedule',
                            inputOptions: maintenanceFrequencyOptions
                          }
                        }
                      ]
                    },
                    {
                      options: {
                        direction: 'column',
                        padding
                      },
                      contents: [
                        {
                          component: {
                            edit: 'DateInput',
                            view: 'FieldWithLabel'
                          },
                          source: 'firstDueDate',
                          options: {
                            searchableCategory: 'date',
                            label: 'Start date',
                            inputOptions: []
                          }
                        }
                      ]
                    },
                    {
                      options: {
                        direction: 'column',
                        padding
                      },
                      contents: [
                        {
                          component: {
                            edit: 'DateInput',
                            view: 'FieldWithLabel'
                          },
                          source: 'endDate',
                          options: {
                            searchableCategory: 'date',
                            label: 'End date',
                            inputOptions: []
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              options: {
                direction: 'column'
              },
              contents: [
                {
                  options: {
                    direction: 'row'
                  },
                  contents: [
                    {
                      options: {
                        direction: 'column',
                        padding,
                        minWidth: 316
                      },
                      contents: [
                        {
                          options: {
                            direction: 'row',
                            alignItems: 'center'
                          },
                          contents: [
                            {
                              options: {
                                direction: 'column',
                                flex: 1,
                                padding,
                                paddingLeft: 0,
                                hide: !showTotalBudgetedHours
                              },
                              contents: [
                                {
                                  component: {
                                    edit: 'TextInput',
                                    view: 'FieldWithLabel'
                                  },
                                  source: 'totalBudgetedHours',
                                  options: {
                                    type: 'number',
                                    label: 'Budgeted Hours for Maintenance',
                                    min: 1,
                                    step: '.01'
                                  }
                                }
                              ]
                            },
                            {
                              options: {
                                direction: 'column',
                                padding,
                                paddingLeft: showTotalBudgetedHours ? padding : 0,
                                flex: 1
                              },
                              contents: [
                                {
                                  component: {
                                    edit: 'TextInput',
                                    view: 'FieldWithLabel'
                                  },
                                  source: 'budgetedHours',
                                  options: {
                                    type: 'number',
                                    label: 'Budgeted Hours Per Visit'
                                  }
                                }
                              ]
                            },
                            {
                              options: {
                                direction: 'column',
                                flex: 1,
                                padding
                              },
                              contents: [
                                {
                                  component: {
                                    edit: 'TextInput',
                                    view: 'FieldWithLabel'
                                  },
                                  source: 'estimatedVisits',
                                  options: {
                                    type: 'number',

                                    label: 'Estimated Visits'
                                  }
                                }
                              ]
                            },
                            {
                              options: {
                                direction: 'column',
                                flex: 1,
                                padding,
                                hide: crewTimeTracking
                              },
                              contents: [
                                {
                                  component: {
                                    edit: 'TextInput',
                                    view: 'FieldWithLabel'
                                  },
                                  source: 'numberOfTechs',
                                  options: {
                                    type: 'number',
                                    label: 'Number of Techs',
                                    min: 1
                                  }
                                }
                              ]
                            }
                          ]
                        },
                        {
                          options: {
                            direction: 'column'
                          },
                          contents: [
                            {
                              options: {
                                direction: 'row'
                              },
                              contents: [
                                {
                                  options: {
                                    direction: 'column',
                                    padding,
                                    paddingLeft: 0,
                                    maxWidth: 305
                                  },
                                  contents: [
                                    {
                                      component: {
                                        edit: 'SelectInput',
                                        view: 'FieldWithLabel'
                                      },
                                      source: 'tags',
                                      options: {
                                        label: 'Tags',
                                        isMultipleSelection: true,
                                        inputOptions: jobTagOptions,
                                        enableSort: false
                                      }
                                    }
                                  ]
                                },
                                {
                                  options: {
                                    direction: 'column',
                                    padding
                                  },
                                  contents: [
                                    {
                                      component: {
                                        edit: 'TextInput',
                                        view: 'FieldWithLabel'
                                      },
                                      source: 'serviceDescription',
                                      options: {
                                        label: 'Service description',
                                        lines: 1
                                      }
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            showPreferredTechnicians
              ? {
                  options: {
                    direction: 'column',
                    padding
                  },
                  contents: [
                    {
                      component: {
                        default: 'Title'
                      },
                      options: {
                        label: 'Preferred Technicians'
                      }
                    },
                    {
                      options: {
                        direction: 'row'
                      },
                      contents: [
                        {
                          options: {
                            direction: 'column'
                          },
                          contents: [
                            {
                              component: 'PreferredTechniciansFormLayoutComponent',
                              source: 'preferredTechnicians',
                              options: {
                                departments,
                                crews,
                                technicians,
                                getRequiredFields: () => ({})
                              }
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              : {
                  options: {},
                  contents: []
                }, // end of Preferred Technicians section
            {
              options: {
                direction: 'column',
                padding
              },
              contents: [
                {
                  component: {
                    default: 'Divider'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                padding,
                paddingTop: 24
              },
              contents: [
                {
                  component: {
                    default: 'Subtitle'
                  },
                  options: {
                    label: 'Maintenance Tasks'
                  }
                },
                {
                  component: {
                    default: 'MaintenanceTasks'
                  },
                  source: 'maintenanceTaskTemplates',
                  options: {
                    assetOptions,
                    setAssetOptions,
                    isLoadingAssets
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  }
});

export default MaintenanceForm;
