import includes from 'lodash/includes';

export const useTechOptions = ({ primaryTechId, extraTechIds, techsResponse }) => {
  const techs =
    techsResponse?.data?.map(tech => ({
      value: tech.id,
      label: tech.name
    })) || [];
  const extraTechOptions = techs.filter(({ value }) => value !== primaryTechId);
  const primaryTechOptions = techs.filter(({ value }) => !includes(extraTechIds, value));
  return {
    extraTechOptions,
    primaryTechOptions,
    techsLoading: techsResponse.loading
  };
};
