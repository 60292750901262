import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

import { UserPermission } from 'components';
import ErrorBoundaries from 'scenes/Error';
import { PermissionConstants } from 'utils/AppConstants';

import SubmenuTitle from '@pm/components/SubmenuTitle';
import KpiElement from '@pm/components/KpiElement';
import CustomTabs from '@pm/components/CustomTabs';

import { useStyles } from './ProjectFinance.styles';
import InvoiceList from './InvoiceList';
import PayApplicationsList from './PayApplicationsList';
import ProjectFinanceTable, { ProjectFinanceTableView } from './ProjectFinanceTable';

const tabMenus = [
  { tabLabel: 'Costs' },
  { tabLabel: 'Revenue' },
  { tabLabel: 'Pay Applications' },
  { tabLabel: 'Invoices' },
  { tabLabel: 'Labor Report' }
];

const Finance = ({ history, mode, payApplicationId, projectData, projectId, projectKpis }) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <ErrorBoundaries>
      <UserPermission I="read" action={PermissionConstants.OBJECT_PM_FINANCE}>
        <SubmenuTitle>Finance</SubmenuTitle>
        <Box className={classes.root}>
          <Box className={classes.kpiContainer}>
            <Typography className={classes.kpiSubTitle}>Billing KPIs</Typography>
            <KpiElement type="currency" content={projectKpis?.openAr || 0} title="Open AR" />
            <KpiElement
              type="currency"
              content={projectKpis?.revenueBilled || 0}
              title="Revenue Billed To Date"
            />
            <KpiElement
              type="currency"
              content={projectKpis?.overBilled ? projectKpis?.overBilled : projectKpis?.underBilled}
              title={projectKpis?.overBilled ? 'Over Billed' : 'Under Billed'}
            />
          </Box>
          <Divider />
        </Box>
        <CustomTabs value={selectedTab} tabMenus={tabMenus} onChange={handleTabChange} />
        {selectedTab === 0 && <ProjectFinanceTable view={ProjectFinanceTableView.COST_BUDGET} />}
        {selectedTab === 1 && <ProjectFinanceTable view={ProjectFinanceTableView.REVENUE_BUDGET} />}
        {selectedTab === 2 && (
          <PayApplicationsList
            payApplicationId={payApplicationId}
            projectId={projectId}
            mode={mode}
            projectData={projectData}
            history={history}
          />
        )}
        {selectedTab === 3 && (
          <InvoiceList projectId={projectId} refetchTrigger={selectedTab === 3} />
        )}
        {selectedTab === 4 && <ProjectFinanceTable view={ProjectFinanceTableView.LABOR_REPORT} />}
      </UserPermission>
    </ErrorBoundaries>
  );
};

Finance.propTypes = {
  user: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  projectData: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => ({
  user: state.user,
  projectData: state.pm.project,
  mode: props.match.params.mode,
  projectId: props.match.params.projectId,
  history: props.history,
  projectKpis: state.pm.projectKpis
});

export default connect(mapStateToProps)(Finance);
