import { gql } from '@apollo/client/core';
import useExtendedMutation from 'customHooks/useExtendedMutation';
import useExtendedQuery from 'customHooks/useExtendedQuery';

export const GET_DISPATCH_BOARD_FILTERS = gql`
  query getDispatchFiltersByEmployee {
    getDispatchFiltersByEmployee {
      id
      displayName
      filter
      isDefault
    }
  }
`;

export const useDispatchBoardFilters = () => useExtendedQuery(GET_DISPATCH_BOARD_FILTERS);

const MUTATE_DISPATCH_BOARD_FILTER = gql`
  mutation upsertDispatchFilters($dispatchFilters: [UpsertDispatchFilterTypeInput!]!) {
    upsertDispatchFilters(dispatchFilters: $dispatchFilters) {
      id
      displayName
      filter
      isDefault
    }
  }
`;

const updateEdit = (cache, { data: { upsertDispatchFilters } }) => {
  const query = {
    query: GET_DISPATCH_BOARD_FILTERS
  };

  const updateData = {
    getDispatchFiltersByEmployee: { items: [...upsertDispatchFilters] }
  };

  cache.writeQuery({ ...query, data: updateData });
};

export const useDispatchBoardMutateFilter = () =>
  useExtendedMutation(MUTATE_DISPATCH_BOARD_FILTER, {
    serializer: newFilters => ({
      variables: {
        dispatchFilters: newFilters.map(newFilter => ({
          id: newFilter?.id,
          displayName: newFilter.displayName,
          filter: newFilter.filter,
          isDefault: newFilter.isDefault
        }))
      }
    }),
    update: updateEdit
  });

const DELETE_DISPATCH_BOARD_FILTER = gql`
  mutation deleteDispatchFilter($id: String!) {
    deleteDispatchFilter(id: $id) {
      id
    }
  }
`;

const updateDelete = (cache, { data: { deleteDispatchFilter } }) => {
  const query = {
    query: GET_DISPATCH_BOARD_FILTERS
  };

  const cachedData = cache.readQuery(query);

  const updateData = {
    getDispatchFiltersByEmployee: {
      items: [
        cachedData?.getDispatchFiltersByEmployee?.filter(x => x.id !== deleteDispatchFilter.id)
      ]
    }
  };

  cache.writeQuery({ ...query, data: updateData });
};

export const useDispatchBoardDeleteFilter = () =>
  useExtendedMutation(DELETE_DISPATCH_BOARD_FILTER, {
    serializer: id => ({
      variables: {
        id
      }
    }),
    update: updateDelete
  });
