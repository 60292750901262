import React, { useState } from 'react';
import { ThemeProvider, Divider } from '@buildhero/sergeant';
import { DefaultButton, UserPermission, Placeholder } from 'components';
import { isTenantSettingEnabled } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';
import { JobStatus } from 'utils/constants';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { VisitCreateModal, ViewVisit } from 'components/Visit';
import useEmployees from 'customHooks/useEmployees';

const VisitsTab = props => {
  const { visitsData, jobData, tasksData, propertyAssets, user, fetchVisits, isLoading } = props;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const isAssetDropdownEnabled = isTenantSettingEnabled('assetTracking');

  const [activeTechs] = useEmployees({
    includeDepartments: true,
    filter: { isActive: { eq: true }, isTech: { eq: true } }
  });

  let maxVisitNumber = 0;
  if (visitsData.length) {
    const visitNumbers = visitsData.map(el => parseInt(el.visitNumber, 10));
    maxVisitNumber = Math.max(...visitNumbers);
  }
  return (
    <>
      <UserPermission I="create" action={PermissionConstants.OBJECT_VISIT}>
        {![JobStatus.COMPLETE, JobStatus.CANCELED, JobStatus.ON_HOLD, 'On hold'].includes(
          jobData.status
        ) && (
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <DefaultButton
              label="Add Visit"
              color="primary"
              handle={() => {
                setIsPopupOpen(true);
              }}
            />
          </div>
        )}
      </UserPermission>
      {isLoading || !activeTechs ? (
        <Placeholder repeatCount={3} />
      ) : (
        <>
          {visitsData.length === 0 && (
            <Typography variant="caption" style={{ lineHeight: 4 }}>
              No Visits
            </Typography>
          )}
          {visitsData.map(item => (
            <div key={item.id}>
              <ThemeProvider>
                <Divider />
              </ThemeProvider>
              <ViewVisit
                key={item.visitNumber}
                visit={item}
                locale={user.locale}
                techs={activeTechs}
                propertyAssets={propertyAssets}
                enableAssetDropDown={isAssetDropdownEnabled}
                user={props.user}
                continueInNewVisit={() => {
                  setIsPopupOpen(true);
                }}
                updateVisits={() => fetchVisits()}
                handleDelete={() => fetchVisits()}
                nextVisitNumber={maxVisitNumber + 1} // for on hold status, new visit needs to be created when continue in new visit is clicked
                job={jobData}
                tasks={tasksData}
              />
            </div>
          ))}
        </>
      )}
      <VisitCreateModal
        user={props.user}
        visit={{}}
        locale={props.user.locale}
        job={jobData}
        techs={activeTechs}
        propertyAssets={propertyAssets}
        open={isPopupOpen}
        onComplete={() => fetchVisits()}
        handleClose={() => setIsPopupOpen(false)}
      />
    </>
  );
};

export default connect(state => ({ user: state.user }), null)(VisitsTab);
