import orderBy from 'lodash/orderBy';
import moment from 'moment';

import { ElementSizes } from '@dispatch/Dispatch.styles';
import { ItemTypes } from '@dispatch/dnd';

const SECONDS_IN_ONE_DAY = 60 * 60 * 24;

export const selectVisitXPosition = visit => {
  const dayStart = moment
    .unix(visit.scheduledFor)
    .startOf('day')
    .unix();

  return Math.floor(
    ((visit.scheduledFor - dayStart) / SECONDS_IN_ONE_DAY) * ElementSizes.timeWidth
  );
};

export const selectVisitWidth = visit => {
  return (visit.actualDuration / 60) * ElementSizes.cellWidth || ElementSizes.techCellWidth;
};

export const selectCardXPosition = timestamp => {
  const dayStart = moment
    .unix(timestamp)
    .startOf('day')
    .unix();

  return Math.floor(((timestamp - dayStart) / SECONDS_IN_ONE_DAY) * ElementSizes.timeWidth);
};

export const selectCardWidth = durationInMinutes => {
  return (durationInMinutes / 60) * ElementSizes.cellWidth || ElementSizes.techCellWidth;
};

export const selectEndTime = ({ day, endDay, mapView, weekView, customWeekStartDay }) => {
  if (mapView) {
    return moment(endDay)
      .endOf('day')
      .unix();
  }

  if (weekView) {
    if (customWeekStartDay) {
      return moment(day)
        .isoWeekday(customWeekStartDay)
        .add(7, 'days')
        .endOf('day')
        .unix();
    }
    return moment(day)
      .endOf('isoWeek')
      .unix();
  }

  return moment(day)
    .endOf('day')
    .unix();
};

export const selectStartTime = ({ day, weekView, customWeekStartDay }) => {
  if (weekView) {
    if (customWeekStartDay) {
      return moment(day)
        .isoWeekday(customWeekStartDay)
        .subtract(8, 'days')
        .startOf('day')
        .unix();
    }
    return moment(day)
      .startOf('isoWeek')
      .unix();
  }

  return moment(day)
    .startOf('day')
    .unix();
};

export const selectTechVisits = boardVisits => {
  const techVisits = {};

  boardVisits.forEach(visit => {
    const { primaryTechId } = visit;

    if (primaryTechId) {
      techVisits[primaryTechId] = [...(techVisits[primaryTechId] || []), visit];
    }

    visit.extraTechs?.forEach(techId => {
      techVisits[techId] = [...(techVisits[techId] || []), visit];
    });
  });

  return techVisits;
};

export const selectTechStatus = (techId, visits) => {
  const techVisitSchedules = visits?.reduce((result, visit) => {
    const schedules = visit?.schedules?.filter(schedule => schedule?.employee?.id === techId) || [];
    return [...result, ...schedules];
  }, []);

  return orderBy(techVisitSchedules, ['lastUpdatedDateTime'], ['desc'])[0]?.status;
};

export const selectBoardVisits = (visits = []) => {
  return visits.filter(
    visit => visit.Status.showOnBoard && visit.actualDuration && visit.scheduledFor
  );
};

export const selectDropTech = item => {
  switch (item?.type) {
    case ItemTypes.BOARD_VISIT:
    case ItemTypes.TABLE_VISIT:
      return item?.data?.primaryTechId;
    case ItemTypes.NON_VISIT_EVENT:
      return item?.data?.employeeId;
    default:
      return undefined;
  }
};
