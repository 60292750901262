import React, { useCallback, useEffect, useState } from 'react';

import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DetailView from '@pm/components/DetailView';
import { getNotesDataFromDailyReport } from '@pm/components/utils';
import { usePayrollHourTypes } from '@pm/ProjectDetails/FieldReport/DailyReportList/DailyReport.hooks';
import dailyReportSidebar from 'meta/ProjectManagement/FieldReport/dailyReportSidebar';
import { snackbarOn } from 'redux/actions/globalActions';

import { useFetchDailyReport } from 'services/APIHooks/dailyReport';
import { AppConstants } from 'utils/AppConstants';

import GenerateDailyReport from '../GenerateDailyReport';

import { useCompanyInfo, useProjectTimesheets } from '../GeneratePdfModal/GeneratePdfModal.hooks';

import { useGetDailyReportPDFBlob } from './DailyReportPdf/DailyReportPdf.hook';

const DailyReportDetailView = props => {
  const history = useHistory();
  const { projectId, projectData, dailyReportId, user } = props;

  const [formattedData, setFormattedData] = useState({});
  const [refetchTimeSheets, setRefetchTimeSheets] = useState(false);

  const [{ data: payrollHourTypes }] = usePayrollHourTypes();
  const companyInfo = useCompanyInfo(user.tenantId);

  const timezone = useSelector(s => s.pm.timezone.timezone);

  const [{ loading: dailyReportLoading, data: dailyReport }, refetchDR] = useFetchDailyReport(
    dailyReportId,
    {
      skip: !dailyReportId
    }
  );

  const [{ data: timesheets, loading: loadingTimesheets }] = useProjectTimesheets(
    {
      projectId,
      dailyReportId,
      dailyReportDate: dailyReport?.date
    },
    {
      skip: !dailyReport?.date,
      depends: [projectId, dailyReportId, dailyReport?.date, refetchTimeSheets]
    }
  );

  const getFormattedData = useCallback(
    (item, companyTimezone) => {
      const data = getNotesDataFromDailyReport(item);
      return {
        ...data,
        id: dailyReportId,
        number: item.number,
        SendTo: {
          email: projectData?.customer?.email
        },
        createdBy: item.createdBy,
        AuditLogEntry: item.AuditLogEntry,
        dateText:
          item?.date && companyTimezone
            ? moment
                .unix(item.date)
                .tz(companyTimezone)
                .format(AppConstants.DATE_FORMAT)
            : null
      };
    },
    [dailyReportId, projectData]
  );

  const update = useCallback(() => {
    setFormattedData(getFormattedData(dailyReport, timezone));
  }, [dailyReport, timezone, getFormattedData]);

  useEffect(() => {
    update();
  }, [update]);

  const getDailyReportPDFBob = useGetDailyReportPDFBlob({
    timesheets,
    payrollHourTypes,
    user,
    projectData,
    dailyReportData: dailyReport,
    companyInfo
  });

  return (
    <>
      <DetailView
        open={!!dailyReportId}
        handleClose={() => history.goBack()}
        projectData={projectData}
        initialData={{ ...formattedData }}
        getPDFBlob={getDailyReportPDFBob}
        attachments={formattedData.attachments}
        onAttachmentsChange={() => {}}
        noAttachmentAddButton
        user={user}
        editData={{
          label: 'EDIT',
          render: (isOpen, close) => (
            <GenerateDailyReport
              dailyReportId={dailyReportId}
              open={isOpen}
              handleClose={() => {
                close();
              }}
              projectId={projectId}
              update={() => {
                refetchDR();
                setRefetchTimeSheets(state => !state);
                update();
              }}
            />
          )
        }}
        title={`Daily Report ${dailyReport?.number || ''}`}
        emailData={{
          modalTitle: 'New Daily Report Email',
          body: `To whom it may concern,<br/><br/>Please find the attached Daily Report ${dailyReport?.number}.<br/><br/>${user.displayName}`,
          subject: `${projectData?.name || null} Daily Report ${dailyReport?.number}`
        }}
        isSubmitting={dailyReportLoading || loadingTimesheets}
        sidebarConfiguration={dailyReportSidebar}
        sidebarData={formattedData}
      />
    </>
  );
};

DailyReportDetailView.propTypes = {
  user: PropTypes.object.isRequired,
  projectData: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  dailyReportId: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => ({
  user: state.user,
  projectData: state.pm.project,
  mode: props.match.params.mode,
  projectId: props.match.params.projectId,
  dailyReportId: props.match.params.id
});

const mapDispatcherToProps = dispatch => ({
  snackbar: (mode, message) => dispatch(snackbarOn(mode, message))
});

const ReduxConnectedDailyReportDetailView = connect(
  mapStateToProps,
  mapDispatcherToProps
)(DailyReportDetailView);
export default ReduxConnectedDailyReportDetailView;
