import React from 'react';

import { Document, Image, Page, View } from '@react-pdf/renderer';

import ItemCoverPage from './components/ItemCoverPage';
import styles from './ItemPDF.styles';

const packageItemPDF = props => {
  const { packageItem, companyInfo } = props;

  return (
    <Document>
      <ItemCoverPage
        key={`packageItem-cover-${packageItem.id}`}
        companyInfo={companyInfo}
        itemData={packageItem}
      />
      {packageItem.imageAttachments?.map(image => {
        return (
          <Page size="letter" style={[styles.root]}>
            <View>
              <Image src={image.fileUrl} />
            </View>
          </Page>
        );
      })}
    </Document>
  );
};

export default packageItemPDF;
