import { useCallback, useEffect, useState } from 'react';
import { getHourTypes } from 'services/API/dailyReport';
import { getEmployees } from 'services/API/employee';
import { getPhasesByProject } from 'services/API/projectPhases';

const useFetchService = serviceFetchFunction => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async function fetchData() {
      setLoading(true);
      try {
        const fetchedData = await serviceFetchFunction();
        setData(fetchedData);
      } finally {
        setLoading(false);
      }
    })();
  }, [serviceFetchFunction]);

  return [{ data, loading }];
};

export const usePayrollHourTypes = () => useFetchService(getHourTypes);

export const useEmployees = () => useFetchService(getEmployees);

export const useProjectPhases = projectId =>
  useFetchService(
    useCallback(() => {
      if (projectId) {
        return getPhasesByProject(projectId);
      }
      return [];
    }, [projectId])
  );
