import { useTheme } from '@material-ui/core';

export const useStyles = () => {
  const theme = useTheme();
  return {
    container: {
      display: 'flex',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    image: {
      maxWidth: theme.spacing(20),
      maxHeight: theme.spacing(10)
    },
    info: {
      padding: theme.spacing(1),
      fontSize: 12,
      color: theme.palette.grayscale(60)
    },
    button: {
      margin: '0'
    }
  };
};
