import gql from 'graphql-tag';

const softDeletePayrollHourType = gql`
  mutation softDeletePayrollHourType($partitionKey: String, $id: String!) {
    softDeletePayrollHourType(partitionKey: $partitionKey, id: $id) {
      id
      version
      isActive
      hourType
      hourTypeAbbreviation
    }
  }
`;

export default softDeletePayrollHourType;
