import { gql } from '@apollo/client/core';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { getState } from 'redux/store';

const UPDATE_REVIEW_REPORT_STATUS = gql`
  mutation updateReviewReportStatus($partitionKey: String, $data: UpdateReviewReportStatusInput!) {
    updateReviewReportStatus(partitionKey: $partitionKey, data: $data) {
      id
      status
      version
    }
  }
`;

const serializer = ({ id, status }) => {
  const { partitionKey } = getState()?.company;

  return {
    variables: {
      partitionKey,
      data: { id, status }
    }
  };
};

export const useUpdateReviewReportStatus = () => {
  return useExtendedMutation(UPDATE_REVIEW_REPORT_STATUS, {
    serializer
  });
};
