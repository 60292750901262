import gql from 'graphql-tag';

export const getPricebooks = gql`
  query getCompany(
    $partitionKey: String!
    $sortKey: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      version
      defaultPriceBookId
      priceBooksView(
        entityConnection: "PriceBook"
        pagination: { limit: $limit, offset: $offset }
        filter: $filter
        sorting: $sort
      ) {
        nextToken
        items {
          id
          name
          description
          isActive
          markupValue
          markupType
        }
      }
    }
  }
`;

export const updateCompany = gql`
  mutation updateCompany($partitionKey: String!, $data: UpdateCompanyInput!) {
    updateCompany(partitionKey: $partitionKey, data: $data) {
      id
      version
      defaultPriceBookId
    }
  }
`;

export default getPricebooks;
