/* eslint-disable no-param-reassign */
import _ from 'lodash';
import React, { Component } from 'react';

/*
 * HOC that abstracts the functionality required to have multiple <MUIForm /> components as children (of any level)
 * within the same React component. Works with a dynamic number of nested (or not) forms.
 *
 * This is the same as the original withMuiForms, however it supports adding forms dynamically instead of the fixed forms
 * from the 'formList' parameter.
 *
 * Wrap your component as follows:
 * `const WithForms = withMultipleFormsDynamic(Component);
 *  export default WithForms;`
 *
 * Then the HOC will inject the following props into your component:
 * ----------------------------------------------------------------
 *
 * `getHandleCreateService` and `getHandleComplete`. These generator functions supply handles to each
 * MUIForm in your component, given its unique name or ID.
 * Example usage:
 * `<MUIForm onCreateService={getHandleCreateService('myForm1')} onComplete={getHandleComplete('myForm1')} ...otherProps />`
 *
 * If you have a dynamic numbers of forms, you can specify the path to save to in the getHandleComplete function, as well as
 * a formatFunction to format the submitted data like so:
 *  `onComplete={
 *     getHandleComplete(
 *        'SecondLevelForm-1-ThirdLevelForm-0',
 *        (data) => ({ ...data, exampleKey: 'something' }),
 *        ['FirstLevelForm', 'SecondLevelForm-1']
 *     )
 *  }`
 *
 * In the above example, 'SecondLevelForm-1-ThirdLevelForm-0' is the name for a service that belongs to SecondLevelForm-1.
 * If the SecondLevelForm and ThirdLevelForm had multiple items numbered like above, onSubmitFinal would pass this object:
 * {
 *     'FirstLevelForm': {
 *         'SecondLevelForm-0': {
 *             'SecondLevelForm-0-ThirdLevelForm-0': {
 *                 ... some data for ThirdLevelForm-0
 *             },
 *             'SecondLevelForm-0-ThirdLevelForm-1': {
 *                 ... some data for ThirdLevelForm-0
 *             },
 *             ... other data for SecondLevelForm-0
 *         },
 *         'SecondLevelForm-1': {
 *             'SecondLevelForm-1-ThirdLevelForm-0': {
 *                 ... some data for ThirdLevelForm-0 (not same as above)
 *             },
 *             ... other data for SecondLevelForm-1
 *         }
 *         ... other data for FirstLevelForm
 *     }
 * }
 *
 *
 * `getHandleRemoveService`. Call this function when you want to remove a service from the list.
 *
 * `handleSubmitStart`. Call this function when you want to submit all forms in your component. For example,
 * you can call this function from the onClick callback of a submit button.
 *
 * `setOnSubmitFinal`. This function allows you to pass a callback upwards from your component to this wrapper,
 * 'against' the standard flow of data in React. Call this function once when your component mounts. For example:
 * `useEffect(() => {
 *    setOnSubmitFinal(async (allFormsData) => {
 *      await myResultingServerCall(allFormsData);
 *    })
 *  }, []);`
 * This function is called after all forms have successfully submitted (no validation errors), and it is passed
 * the combined data from all submitted forms. After this function completes, `isSubmitting` is set of false.
 * For this reason if your callback has asynchronous parts it is important to await those results; otherwise `isSubmitting` will
 * immediately be set to false, which could allow submission too frequently.
 *
 * `isSubmitting`. Boolean; true when the HOC is in the process of submitting any of the forms in your component, false otherwise.
 * Use this to disable the master button that submits all forms for a better user experience.
 *
 * See 'scenes/ProjectManagement/ProjectDetails/ProjectSettings/Budget/Phases' for an example of how to use this with
 * a dynamic number of forms.
 */
function assign(obj, keyPath, value) {
  const lastKeyIndex = keyPath.length - 1;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < lastKeyIndex; i++) {
    const key = keyPath[i];
    if (!(key in obj)) {
      obj[key] = {};
    }
    obj = obj[key];
  }
  obj[keyPath[lastKeyIndex]] = value;
}

function withMultipleFormsDynamic(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        formServiceList: {},
        formNames: [],
        isSubmitting: false
      };

      this.setOnSubmitFinal = this.setOnSubmitFinal.bind(this);
      this.getFormService = this.getFormService.bind(this);
      this.getFormServiceList = this.getFormServiceList.bind(this);
      this.getHandleCreateService = this.getHandleCreateService.bind(this);
      this.getHandleComplete = this.getHandleComplete.bind(this);
      this.handleSubmitStart = this.handleSubmitStart.bind(this);
      this.handleSubmitForm = this.handleSubmitForm.bind(this);
      this.getHandleRemoveService = this.getHandleRemoveService.bind(this);
      this.getHandleRemoveAllServices = this.getHandleRemoveAllServices.bind(this);
    }

    async handleSubmitStart() {
      const { formServiceList, formNames } = this.state;
      const formServices = Object.values(formServiceList);
      let error = false;
      // Validate all forms before submission to highlight all errors at once
      await Promise.all(formServices.map(service => service.validateForm())).then(() => {
        if (formServices.some(service => !_.isEmpty(service.formikContext.errors))) {
          error = true;
        }
      });
      // Terminate form submission if any form contains an error
      if (error) {
        return;
      }
      // Clear previously stored data, then start "submit cascade"
      this.setState({ submittedForms: {} }, () => {
        formServiceList[formNames[0]].submit();
      });
    }

    getHandleRemoveService(serviceName) {
      this.setState(prevState => {
        const forms = { ...prevState.formServiceList };
        const names = [...prevState.formNames];
        delete forms[serviceName];
        const index = names.indexOf(serviceName);
        if (index !== -1) {
          names.splice(index, 1);
        }
        return {
          ...prevState,
          formServiceList: forms,
          formNames: names
        };
      });
    }

    getHandleRemoveAllServices() {
      this.setState({
        formServiceList: {},
        submittedForms: {},
        formNames: [],
        isSubmitting: false
      });
    }

    // Aggregates form data from all forms in list and submits only when all forms submit successfully
    handleSubmitForm(formData, formName, formatFunc, keyPath) {
      const { formServiceList, onSubmitFinal, isSubmitting, formNames } = this.state;

      const formattedData = formatFunc ? formatFunc(formData) : formData;
      if (!onSubmitFinal || isSubmitting) return;

      let nextFormName = '';
      formNames.forEach((name, index) => {
        if (formName === name) {
          nextFormName = formNames[index + 1] || '';
        }
      });
      const submitNext = () => {
        // Momentarily set submitting to false so that if the next submission fails (e.g. validation error),
        // we don't get stuck with a disabled "submitting in process" button for the user.
        this.setState({ isSubmitting: false }, () => {
          // Submit next form in "cascade" - basically a loop where each iteration is another run through this callback function.

          formServiceList[nextFormName].submit();
        });
      };

      const submitFinal = async () => {
        // No next form - assume that means current form was the final one needed.
        // await onSubmitFinal(this.state.combinedFormData);
        await onSubmitFinal(this.state.submittedForms);

        // NOTE: Clients of this HOC should make the onSubmitFinal function await all network requests rather than firing them
        // in the background. Otherwise, `isSubmitting` will be set to false too soon.
        this.setState({ isSubmitting: false });
      };

      const hasNextForm = nextFormName !== '';
      const nextSubmit = hasNextForm ? submitNext : submitFinal;

      const updatedStateData = prevState => {
        if (Array.isArray(keyPath)) {
          const { submittedForms } = prevState;
          assign(submittedForms, [...keyPath, formName], formattedData);
          //   const parent = { ...prevState.submittedForms[parentName] };

          return { ...submittedForms };
        }
        return {
          ...prevState.submittedForms,
          [formName]: formattedData
        };
      };

      this.setState(
        prevState => ({
          isSubmitting: true,
          submittedForms: updatedStateData(prevState)
        }),
        () => {
          nextSubmit();
        }
      );
    }

    /**
     *
     * @param {*} formName
     * @param {*} formatFunc
     * @param {Array} keyPath Array of strings pointing to the path to which to save. The formName is tacked onto the end of it.
     * @returns
     */
    getHandleComplete(formName, formatFunc, keyPath) {
      return formData => {
        this.handleSubmitForm(formData, formName, formatFunc, keyPath);
      };
    }

    setOnSubmitFinal(onSubmitFinal) {
      this.setState({ onSubmitFinal });
    }

    /**
     *
     * @param {formKey} formName;
     * @returns Form service object of the relevant form. Can be use to trigger the validate form etc
     */
    getFormService(formName) {
      return this.state?.formServiceList?.[formName];
    }

    /**
     *
     * @returns Form service list
     */
    getFormServiceList() {
      return this.state?.formServiceList;
    }

    getHandleCreateService(formName) {
      return formService => {
        this.setState(prevState => ({
          formNames: [...new Set([...prevState.formNames, formName])],
          formServiceList: {
            ...prevState.formServiceList,
            [formName]: formService
          }
        }));
      };
    }

    render() {
      return (
        <WrappedComponent
          getHandleCreateService={this.getHandleCreateService}
          getFormService={this.getFormService}
          getFormServiceList={this.getFormServiceList}
          getHandleComplete={this.getHandleComplete}
          handleSubmitStart={this.handleSubmitStart}
          setOnSubmitFinal={this.setOnSubmitFinal}
          isSubmitting={this.state.isSubmitting}
          getHandleRemoveService={this.getHandleRemoveService}
          getHandleRemoveAllServices={this.getHandleRemoveAllServices}
          {...this.props}
        />
      );
    }
  };
}

export default withMultipleFormsDynamic;
