import gql from 'graphql-tag';

const skipReviewReport = gql`
  mutation skipReviewReport($partitionKey: String, $data: SkipReviewReportForVisitsInput!) {
    skipReviewReportForVisits(partitionKey: $partitionKey, data: $data) {
      id
    }
  }
`;

export default skipReviewReport;
