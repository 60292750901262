import gql from 'graphql-tag';
import { dispatchVisitFragment } from '../../scheduling/visit/queries/visitFragments';

const updateFullVisitQuery = gql`
  mutation updateFullVisit($input: UpdateVisitInput!) {
    updateVisit(input: $input) {
      ${dispatchVisitFragment}
    }
  }
`;

export default updateFullVisitQuery;
