import React from 'react';
import { TransactionType } from 'utils/constants';
import { SgtFormComponents } from '@buildhero/sergeant';

export default props => {
  if (props.form.values.transactionType === TransactionType.OVERPAYMENT) {
    return <SgtFormComponents.CurrencyInput {...props} />;
  }
  return (
    <SgtFormComponents.Field
      {...props}
      props={{ ...props.props, style: { ...props.props.style, marginBottom: 14 } }}
    />
  );
};
