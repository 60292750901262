import { PermissionConstants } from 'utils/AppConstants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

const propertyViewLayout = flags => ({
  entity: {
    name: 'property',
    tenant: 'default',
    layouts: {
      web: {
        sections: [
          {
            title: 'General info',
            icon: 'contactIcon',
            sm: '12',
            md: '12',
            lg: '12',
            rows: [
              {
                spacing: 3,
                lg: '10',
                cols: [
                  {
                    type: 'dbField',
                    value: 'customerName',
                    name: 'customerName',
                    label: 'customer',
                    behavior: {
                      view: {
                        ui: 'HighlightText',
                        sm: '12',
                        md: '4',
                        lg: '2'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'jobCompletedCount',
                    name: 'jobCompletedCount',
                    label: 'jobsCompleted',
                    behavior: {
                      view: {
                        ui: 'HighlightText',
                        sm: '12',
                        md: '4',
                        lg: '2'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'accountNumber',
                    name: 'accountNumber',
                    label: 'accountNumber',
                    showField: 'isExtendedFieldsEnabled',
                    behavior: {
                      view: {
                        ui: 'HighlightText',
                        sm: '12',
                        md: '4',
                        lg: '2'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'taxRateLabel',
                    name: 'taxRateLabel',
                    label: 'taxCode',
                    behavior: {
                      view: {
                        ui: 'HighlightText',
                        sm: '12',
                        md: '4',
                        lg: '2'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'customerPropertyTypeValue',
                    name: 'customerPropertyTypeValue',
                    label: 'propertyType',
                    behavior: {
                      view: {
                        ui: 'HighlightText',
                        sm: '12',
                        md: '4',
                        lg: '2'
                      }
                    }
                  }
                ]
              },
              {
                spacing: 3,
                lg: '10',
                cols: [
                  {
                    type: 'dbField',
                    value: 'property',
                    name: 'property',
                    label: 'propertyAddress',
                    behavior: {
                      view: {
                        ui: 'CombinedAddress',
                        sm: '12',
                        md: '6',
                        lg: '4'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'billingCustomerName',
                    name: 'billingCustomerName',
                    label: 'Billing Customer',
                    behavior: {
                      view: {
                        ui: 'HighlightText',
                        sm: '12',
                        md: '4',
                        lg: '2'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'billing',
                    name: 'billing',
                    label: 'billingAddress',
                    behavior: {
                      view: {
                        ui: 'CombinedAddress',
                        sm: '12',
                        md: '6',
                        lg: '4'
                      }
                    }
                  }
                ]
              }
            ]
          }
        ],
        buttons: {
          deactivate: {
            label: 'Deactivate Property',
            color: 'secondary',
            buttonType: 'contained',
            caslKey: PermissionConstants.OBJECT_PROPERTY,
            caslAction: 'edit',
            behavior: {
              view: true
            },
            icon: 'Block'
          },
          activate: {
            label: 'Activate Property',
            color: 'secondary',
            buttonType: 'contained',
            caslKey: PermissionConstants.OBJECT_PROPERTY,
            caslAction: 'edit',
            behavior: {
              view: true
            },
            icon: 'CheckCircleOutlined'
          },
          createJob: {
            label: 'Create Job',
            color: 'primary',
            buttonType: 'contained',
            caslKey: PermissionConstants.OBJECT_JOB,
            caslAction: 'create',
            behavior: {
              view: true
            },
            icon: 'jobsIcon',
            lookUpIconMap: true
          },
          ...(flags?.[FeatureFlags.QUOTESV2] && {
            createQuote: {
              label: 'Create Quote',
              color: 'primary',
              buttonType: 'contained',
              caslKey: PermissionConstants.OBJECT_QUOTES,
              caslAction: 'create',
              behavior: {
                view: true
              },
              icon: 'quotesIcon',
              lookUpIconMap: true
            }
          })
        }
      },
      search: null
    }
  }
});
export default propertyViewLayout;
