import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  propertyName: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    fontSize: 20,
    letterSpacing: -1,
    fontWeight: 700
  },
  onHoldReason: {
    paddingTop: theme.spacing(2)
  },
  jobNumber: {
    paddingBottom: theme.spacing(0.5)
  },
  statusChipBar: {
    display: 'flex',
    paddingBottom: theme.spacing(0.5)
  },
  statusChip: {
    borderRadius: 0
  }
}));
