import gql from 'graphql-tag';
import useExtendedQuery from 'customHooks/useExtendedQuery';

const GET_SOV_VALUES = gql`
  query getScheduleOfValueLinesByProject($id: number) {
    getScheduleOfValueLinesByProject(id: $id)
      @rest(
        type: "SOVValues"
        path: "scheduleofvaluelines/byproject/{args.id}"
        endpoint: "pmapi"
      ) {
      id
      contractValue
    }
  }
`;

export const useScheduleOfValueLinesByProject = (projectId, options = {}) => {
  return useExtendedQuery(GET_SOV_VALUES, {
    variables: { id: projectId },
    transform: data => data?.getScheduleOfValueLinesByProject,
    defaultData: {},
    fetchPolicy: 'cache-and-network',
    ...options
  });
};
