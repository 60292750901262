export const useStyles = () => ({
  root: () => ({
    padding: 14,
    width: '100%',
    '& .e-filemanager .e-search-wrap': {
      display: 'none'
    },
    '& .e-toolbar': {
      display: 'none'
    }
  })
});
