import React from 'react';
import Typography from '@material-ui/core/Typography';
import { LinkButton } from 'components';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import Box from '@material-ui/core/Box';

const styles = () => ({
  inputLabel: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'normal',
    letterSpacing: '0.01px',
    lineHeight: '14px'
  },
  linkButton: {
    whiteSpace: 'pre-line',
    marginRight: '4px',
    display: 'initial',
    fontSize: '16px',
    fontWeight: 'bold'
  }
});

const LinkFieldWithLabel = props => {
  const { classes, options, field } = props;
  const { label, linkPath } = options;
  const { value } = field;

  const divStyles = { display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' };

  const getLinkDisplay = () => {
    if (value?.labelArray) {
      return (
        <Box display="flex" justifyContent="flex-start" m={0} p={0}>
          {value.labelArray.map((valueLabel, index) => (
            <LinkButton
              label={`${valueLabel}${index !== value.labelArray.length - 1 ? ',' : ''}`}
              path={value.linkPathArray[index]}
              classes={{
                root: classes.linkButton
              }}
            />
          ))}
        </Box>
      );
    }
    return (
      <LinkButton
        label={value}
        path={linkPath}
        classes={{
          root: classes.linkButton
        }}
      />
    );
  };

  return (
    <>
      <div style={divStyles}>
        <Typography className={classes.inputLabel} variant="caption" gutterBottom>
          {label}
        </Typography>
      </div>
      {!isEmpty(value) && (value?.labelArray ? !isEmpty(value?.labelArray) : true) ? (
        getLinkDisplay()
      ) : (
        <Typography variant="body1">-</Typography>
      )}
    </>
  );
};

export default withStyles(styles)(LinkFieldWithLabel);
