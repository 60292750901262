import gql from 'graphql-tag';

const deletePaymentTerm = gql`
  mutation deletePaymentTerm($partitionKey: String, $id: String!) {
    deletePaymentTerm(partitionKey: $partitionKey, id: $id) {
      id
    }
  }
`;

export default deletePaymentTerm;
