const workTable = [
  {
    id: 'userActionType',
    numeric: false,
    disableSort: true,
    isCustom: true,
    type: 'TimestampType',
    label: '',
    cellStyle: {
      minWidth: '200px'
    }
  },
  {
    id: 'billableEntityId',
    numeric: false,
    disableSort: true,
    isCustom: true,
    type: 'Search',
    label: 'Assignment',
    cellStyle: {
      padding: 0
    }
  },
  {
    id: 'customerName',
    numeric: false,
    disableSort: true,
    isCustom: true,
    type: 'CustomerName',
    label: 'Customer',
    cellStyle: {
      minWidth: '150px'
    }
  },
  {
    id: 'customerPropertyName',
    numeric: false,
    isCustom: true,
    disableSort: true,
    type: 'CustomerProperty',
    label: 'Property',
    cellStyle: {
      minWidth: '150px'
    }
  },
  {
    id: 'actualStartTimeUTC',
    editId: 'actualStartTimeOverrideUTC',
    dependantId: 'actualEndTimeUTC',
    dependantEditId: 'actualEndTimeOverrideUTC',
    locationIdPrefix: 'start',
    numeric: false,
    disableSort: true,
    disablePadding: true,
    isCustom: true,
    type: 'CustomTimeComponent',
    label: 'Start',
    cellStyle: {
      paddingRight: 0
    }
  },
  {
    id: 'actualEndTimeUTC',
    editId: 'actualEndTimeOverrideUTC',
    dependantId: 'actualStartTimeUTC',
    dependantEditId: 'actualStartTimeOverrideUTC',
    locationIdPrefix: 'end',
    numeric: false,
    disableSort: true,
    isCustom: true,
    type: 'CustomTimeComponent',
    label: 'End',
    cellStyle: {
      padding: 0
    },
    disablePadding: true
  },
  {
    id: 'endOverlap',
    dataKey: 'actualEndTimeUTC',
    numeric: false,
    disableSort: true,
    disablePadding: true,
    isCustom: true,
    type: 'EndOverlap',
    cellStyle: {
      paddingRight: 0,
      width: 100
    }
  },
  {
    id: 'hours',
    numeric: false,
    disableSort: true,
    isCustom: true,
    type: 'TotalHours',
    label: 'Hours'
  },
  {
    id: 'eventType',
    numeric: false,
    disableSort: true,
    isCustom: true,
    type: 'Dropdown',
    label: 'Payroll Hour Type'
  },
  {
    id: 'splittedIcon',
    numeric: false,
    disableSort: true,
    isCustom: true,
    type: 'SplittedIcon',
    label: '',
    cellStyle: {
      padding: 0
    }
  },
  {
    id: 'split',
    numeric: false,
    disableSort: true,
    isCustom: true,
    type: 'SplitButton',
    label: ''
  },
  {
    id: 'delete',
    numeric: false,
    disableSort: true,
    isCustom: true,
    type: 'Delete',
    label: '',
    cellStyle: {
      paddingLeft: 0
    }
  }
];

export default workTable;
