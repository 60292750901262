import React, { useMemo } from 'react';
import { jsx } from '@emotion/react';
import { ThemeProvider, Typography, TV, TW, Button } from '@buildhero/sergeant';
import { connect } from 'react-redux';
import uuidV4 from 'uuid/v4';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { Box, Grid, useTheme } from '@material-ui/core';
import { reorder } from 'utils';

import TaskCard from './TaskCard';

const Tasks = ({
  defaultPriceBookId,
  asset,
  onTasksChange,
  forms,
  tasks = [],
  showCheckmarks = false
}) => {
  const theme = useTheme();

  const creatingNewTask = tasks[tasks.length - 1]?.name === '';

  const addTask = () => {
    const task = { id: uuidV4(), name: '' };
    onTasksChange([...tasks, asset ? { ...task, assetId: asset.id } : task]);
  };

  const moveTask = ({ source, destination }) => {
    if (!Number.isInteger(source?.index) || !Number.isInteger(destination?.index)) return; // invalid drag
    const reorderedTasks = reorder({
      arr: tasks,
      source: source.index,
      destination: destination.index
    });
    onTasksChange(reorderedTasks);
  };

  const suggestedForms = useMemo(
    () =>
      !asset
        ? []
        : forms
            .filter(f => f.value.asset === asset.id)
            .map(f => ({ ...f, group: 'Suggested Forms' })),
    [asset, forms]
  );
  const groupedForms = useMemo(
    () =>
      asset &&
      forms
        .filter(f => {
          // a form may be associated with multiple Assets. If it is associated with this asset & other assets,
          // then filter out entries for the other assets so it only appears in the "Suggested Forms" group and not also in the "Other" group
          if (f.value.asset !== asset.id && suggestedForms.find(sf => sf.id === f.id)) return false;
          return true;
        })
        .map(f => ({
          ...f,
          group: f.value.asset === asset.id ? 'Suggested Forms' : undefined
        }))
        // If this form is not associated with this asset, the "Other" group may still have multiple entries of forms that associated with multiple other assets.
        // filter all but one of these out.
        .sort((a, b) => {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;
          return 0;
        })
        .filter((f, index, fs) => index === 0 || f.id !== fs[index - 1].id),
    [asset, forms]
  );

  const associatedParts = useMemo(
    () =>
      !asset
        ? []
        : asset.maintenanceTaskRequiredParts?.items.map(part => ({
            id: part.id,
            label: part.pricebookEntry.product.name,
            rightLabel: part.priceBookEntryDescription ?? part.pricebookEntry.product.description,
            value: {
              pricebookEntryId: part.pricebookEntry.id,
              name: part.pricebookEntry.product.name,
              code: part.pricebookEntry.product.code,
              description:
                part.priceBookEntryDescription ?? part.pricebookEntry.product.description,
              quantity: part.quantity
            },
            group: 'Associated Parts & Materials'
          })) ?? [],
    [asset]
  );

  const initialTasks = useMemo(() => tasks, []);

  return (
    <ErrorBoundaries>
      <ThemeProvider>
        <Grid item style={{ padding: 0, width: '100%' }}>
          <Box component="div">
            <DragDropContext onDragEnd={moveTask}>
              <Box justifyContent="center">
                <Droppable key={0} droppableId="0">
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      {tasks.map((item, index) => (
                        <TaskCard
                          key={item.id}
                          item={item}
                          index={index}
                          setTasks={onTasksChange}
                          tasks={tasks}
                          initialTasks={initialTasks}
                          forms={groupedForms ?? forms}
                          suggestedForms={suggestedForms}
                          associatedParts={associatedParts}
                          defaultPriceBookId={defaultPriceBookId}
                          asset={asset}
                          showCheckmarks={showCheckmarks}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                {!creatingNewTask && (
                  <Button type="leading" onClick={addTask}>
                    <Typography
                      variant={TV.BASE}
                      weight={TW.MEDIUM}
                      color={theme.palette.text.primary}
                    >
                      + Add Task
                    </Typography>
                  </Button>
                )}
              </Box>
            </DragDropContext>
          </Box>
        </Grid>
      </ThemeProvider>
    </ErrorBoundaries>
  );
};

const mapStateToProps = state => ({
  user: state.user,
  defaultPriceBookId: state.company.defaultPriceBookId
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const connectedTasks = connect(mapStateToProps, mapDispatcherToProps)(Tasks);

export default connectedTasks;
