import React from 'react';

import { jsx } from '@emotion/react';
import { Divider, useTheme } from '@material-ui/core';

import { Field, FieldType, SgtForm, TV, TW, Typography } from '@buildhero/sergeant';
import { withLoading } from 'components';
import JobCloseoutInformationLayout from 'meta/JobCloseout/InformationLayout';
import { findAddressByAddressType, getCombinedAddress } from 'utils';

import { SectionHeader } from '../Components';
import { JobCloseoutTypes, JobCloseoutTypeStrings } from '../constants';
import { getStyles } from '../styles';
import { getJobCloseoutType } from '../utils';

const SgtFormWithLoading = withLoading(SgtForm);

const JobCloseoutInformation = ({ jobData, visitTabDisplay, isLoading }) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const departments = jobData.departments?.items?.map(d => d?.mappedEntity?.tagName).join(', ');
  const customerAddresses = jobData.customerProperty?.companyAddresses?.items || [];
  const billingCustomerAddresses = jobData.billingCustomer?.companyAddresses?.items || [];
  const propertyAddress = getCombinedAddress(
    findAddressByAddressType(customerAddresses, 'propertyAddress') || {}
  );

  const billingCompanyAddresss = findAddressByAddressType(
    billingCustomerAddresses,
    'billingAddress'
  );
  const billingAddress = getCombinedAddress(billingCompanyAddresss || {});
  const billTo = jobData.customerProperty?.billTo || billingCompanyAddresss?.billTo;
  const jobCloseoutType = getJobCloseoutType(jobData);

  return (
    <>
      <Divider css={styles.divider} />
      <Typography variant={TV.L} weight={TW.BOLD}>
        {visitTabDisplay ? 'Job & Visit Information' : 'Job Information'}
      </Typography>
      <Typography loading={isLoading} css={{ marginBottom: theme.spacing(2) }}>
        <Field
          value={{
            label: `View Job ${jobData.customIdentifier}`,
            to:
              jobCloseoutType === JobCloseoutTypes.MAINTENANCE
                ? `/maintenance/view/${jobData.jobNumber}`
                : `/job/view/${jobData.jobNumber}`
          }}
          variant={TV.BASE}
          weight={TW.MEDIUM}
          type={FieldType.LINK}
          color={theme.palette.primary.main}
        />
      </Typography>
      <SgtFormWithLoading
        configuration={JobCloseoutInformationLayout}
        initialValues={{
          customer: {
            label: jobData.customerName,
            to: `/customer/view/${jobData.customerId}`
          },
          property: {
            label: jobData.customerPropertyName,
            to: `/property/view/${jobData.customerPropertyId}`
          },
          billingCustomer: {
            label: jobData.billingCustomerName || jobData.billingCustomer?.customerName,
            to: `/customer/view/${jobData.billingCustomerId || jobData.billingCustomer?.id}`
          },
          departments: departments || '-',
          jobType: jobData.jobTypeName,
          customerWONumber: jobData.customerProvidedWONumber || '-',
          billTo: billTo || '-',
          propertyAddress: propertyAddress || '-',
          billingAddress: billingAddress || '-',
          projectManager: jobData.owner?.name || '-',
          billingType: JobCloseoutTypeStrings[jobCloseoutType],
          customerPONumber: jobData.customerProvidedPONumber || '-'
        }}
        layout="view"
        isLoading={isLoading}
      />
      <SectionHeader title="Job Description" />
      <Typography variant={TV.S1} weight={TW.REGULAR} loading={isLoading}>
        {jobData.issueDescription}
      </Typography>
    </>
  );
};

JobCloseoutInformation.defaultProps = {
  visitTabDisplay: false,
  isLoading: false
};

export default JobCloseoutInformation;
