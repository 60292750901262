import React, { useMemo, useState } from 'react';

import { Button, MUIForm, ThemeProvider } from '@buildhero/sergeant';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ConfirmLeave, PageHeader, withLoading } from 'components';
import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';

import { dispatchSettingLayout } from './DispatchSettings.config';
import { defaultDispatchSettings, loadingParams } from './DispatchSettings.constants';
import {
  useGetCompanyDispatchSettings,
  useSaveCompanyDispatchSettings
} from './DispatchSettings.hooks';
import { useStyles } from './styles';

const DispatchSettings = ({ user, snackbar }) => {
  const styles = useStyles();
  const { tenantId, tenantCompanyId } = user;
  const [MUIService, setMUIService] = useState();
  const [isDirty, setIsDirty] = useState(false);
  const MuiFormWithLoading = useMemo(() => withLoading(MUIForm), []);

  const {
    data: dispatchSettingData,
    loading: getSettingLoading,
    refetch: refetchDispatchSettings
  } = useGetCompanyDispatchSettings({
    tenantId,
    tenantCompanyId,
    snackbar
  });

  const [saveSetting, { loading: saveSettingLoading }] = useSaveCompanyDispatchSettings({
    tenantId,
    tenantCompanyId,
    isUpdate: !!dispatchSettingData,
    setIsDirty
  });

  const handleSave = async formData => {
    await saveSetting({ formData, prevSettingData: dispatchSettingData });
    refetchDispatchSettings();
  };

  const initialFormData = useMemo(
    () =>
      dispatchSettingData ? JSON.parse(dispatchSettingData.settings) : defaultDispatchSettings,
    [dispatchSettingData]
  );

  const SaveButton = (
    <ThemeProvider>
      <Button loading={getSettingLoading || saveSettingLoading} onClick={() => MUIService.submit()}>
        Save Changes
      </Button>
    </ThemeProvider>
  );

  return (
    <ErrorBoundaries>
      <ConfirmLeave when={isDirty} />
      <PageHeader
        title={Labels.dispatchSettings[user.locale]}
        iconComponent={<SettingsOutlinedIcon css={styles.settingIcon} />}
        breadcrumbsArray={[{ link: '', title: Labels.settings[user.locale] }]}
        overrideHeaderButtons={SaveButton}
      />
      <MuiFormWithLoading
        data={initialFormData}
        loadingParams={loadingParams}
        configuration={dispatchSettingLayout()}
        onCreateService={service => {
          setMUIService(service);
        }}
        onDirtyChange={dirty => setIsDirty(dirty)}
        onComplete={handleSave}
        isLoading={getSettingLoading}
      />
    </ErrorBoundaries>
  );
};

DispatchSettings.propTypes = {
  user: PropTypes.object.isRequired,
  snackbar: PropTypes.func.isRequired
};

const mapDispatcherToProps = { snackbar: snackbarOn };

const mapStateToProps = state => ({
  user: state.user
});

const reduxConnectedDispatchSettings = connect(
  mapStateToProps,
  mapDispatcherToProps
)(DispatchSettings);

export default reduxConnectedDispatchSettings;
