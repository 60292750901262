import gql from 'graphql-tag';

const updatePropertyAsset = gql`
  mutation updatePropertyAsset($partitionKey: String!, $data: UpdatePropertyAssetInput!) {
    updatePropertyAsset(partitionKey: $partitionKey, data: $data) {
      id
      version
      sortKey
    }
  }
`;

export default updatePropertyAsset;
