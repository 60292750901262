import gql from 'graphql-tag';

const getTimesheetPeriodsHourSummary = gql`
  query getTimesheetPeriodsHourSummary(
    $partitionKey: String!
    $sortKey: String!
    $timesheetPeriodFilter: TableTimesheetPeriodFilterInput
    $dailyInput: AggregationInput!
    $overAllInput: AggregationInput!
  ) {
    getEmployee(partitionKey: $partitionKey, sortKey: $sortKey) {
      timesheetPeriods(entityConnection: "TimesheetPeriod", filter: $timesheetPeriodFilter) {
        items {
          id
          entityType
          dailySummary: aggregatedField(input: $dailyInput) {
            items {
              total: aggregation1
              hourTypeId: groupByField1
            }
          }
          overAllSummary: aggregatedField(input: $overAllInput) {
            items {
              total: aggregation1
              hourTypeId: groupByField1
            }
          }
        }
      }
    }
  }
`;

export default getTimesheetPeriodsHourSummary;
