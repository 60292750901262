import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { withStyles } from '@material-ui/core';
import _ from 'lodash';
import { Button, ButtonType, ThemeProvider, Modal, Typography } from '@buildhero/sergeant';
import Linkify from 'react-linkify';
import UserPermission from 'components/AppPermissions';
import AmplifyService from 'services/AmplifyService';
import { Logger } from 'services/Logger';
import AppConstants, { PermissionConstants, JobStatus, VisitStatus } from 'utils/AppConstants';
import labels from 'meta/Scheduler/labels';
import actions from 'meta/Scheduler/actions';
import { getTenantSettingValueForKey } from 'utils';
import { visitTransitionMutation } from '../../visitTransitionMutation';

import styles from './styles';
import VisitEditModal from '../VisitEditModal';
import VisitActions from './components/VisitActions';
import VisitStatusComponent from './components/VisitStatus';
import ScheduledInfo from './components/ScheduledInfo';
import CrewInfo from './components/CrewInfo';
import OnHold from '../OnHold';
import iconMap from './components/assets/icons';
import {
  CLOSE_ACTIONS,
  getPossibleVisitActions,
  allowedInputsInEditVisit,
  isFutureDate
} from '../../helper';

const CONFIRMATION_TEXT =
  'If any technicians working on this visit ' +
  'are offline and have already started working on this visit, ' +
  'any information that has not been uploaded from their mobile app ' +
  'may be lost. Are you sure you want to continue?';

const frozenJobStatus = [JobStatus.ON_HOLD, JobStatus.CANCELED, JobStatus.COMPLETE];
class ViewVisit extends React.Component {
  constructor(props) {
    super(props);
    this.confirmFn = null;
    this.state = {
      openEditVisit: false,
      openOnHoldVisit: false,
      currentStatus: this.props.visit.status,
      visitAvailableActions: getPossibleVisitActions(this.props.visit),
      inProgressAction: '',
      confirmAction: null,
      isConfirmModalOpen: false,
      isLoadingConfirm: false
    };
  }

  componentDidUpdate() {
    const { status } = this.props.visit;
    const { currentStatus } = this.state;
    if (status !== currentStatus) {
      this.updateStatus(status);
    }
  }

  updateStatus = newStatus =>
    this.setState({
      currentStatus: newStatus,
      visitAvailableActions: getPossibleVisitActions({ ...this.props.visit, status: newStatus })
    });

  putOnHold = async reason =>
    this.mutateVisit('PUT_ON_HOLD', { onHoldReason: reason, onHold: true });

  scheduleVisit = () => {
    this.setState({
      visitAvailableActions: this.stateMachine.getAvailableEvents(true)
    });
  };

  handleOpenPopUp = popUpKey => {
    this.setState({ [popUpKey]: true });
  };

  handleClosePopUp = popUpKey => {
    this.setState({ [popUpKey]: false });
  };

  handleDeleteVisit = async visit => {
    //
    this.rulesEngine
      .deleteVisit(visit)
      .then(data => {
        this.props.handleDelete(data.data.deleteVisit);
      })
      .catch(error => Logger.error(error));
  };

  mutateVisit = async (actionName, visitAttributes = {}) => {
    this.setState({ inProgressAction: actionName });
    const allowedVisitInput = allowedInputsInEditVisit({ ...this.props.visit, ...visitAttributes });

    const payload = {
      visit: allowedVisitInput
    };

    // Cancel and Complate action doesnt requires techs, only for Reopen action techs are required
    // Schedule and Reschedule will have popup and it will go through the edit visit flow
    if (actionName === 'REOPEN') {
      payload.primaryTechIds = this.props.visit?.primaryTechs?.map(tech => tech.id) || [];
      payload.extraTechIds = this.props.visit?.extraTechs?.map(tech => tech.id) || [];
    }

    const appsyncClient = AmplifyService.appSyncClient();
    const response = await appsyncClient.client.mutate({
      mutation: visitTransitionMutation,
      variables: {
        action: actionName,
        input: payload
      }
    });
    if (response?.data) {
      this.props.updateVisits();
    }
    this.setState({ inProgressAction: '' });
  };

  handleVisitActions = async actionName => {
    if (actionName === 'PUT_ON_HOLD') return this.setState({ openOnHoldVisit: true });
    if (actionName === 'SCHEDULE' || actionName === 'RESCHEDULE')
      return this.handleOpenPopUp('openEditVisit');

    if (CLOSE_ACTIONS.includes(actionName) && !isFutureDate(this.props.visit.scheduledFor)) {
      this.setState({
        isConfirmModalOpen: true,
        confirmAction: actionName
      });
      this.confirmFn = () => this.mutateVisit(actionName);
      return;
    }

    return this.mutateVisit(actionName);
  };

  closeConfirmationModal = () => {
    this.setState({
      isConfirmModalOpen: false,
      openOnHoldVisit: false
    });
    this.confirmFn = null;
  };

  render() {
    const { classes, visit, locale, job } = this.props;
    const showVisitAssets = (visit && visit.visitAssets && visit.visitAssets.items) || [];
    const {
      visitAvailableActions,
      inProgressAction,
      confirmAction,
      isConfirmModalOpen,
      isLoadingConfirm
    } = this.state;
    const copyVisit = { ...visit };
    const { scheduledFor } = visit;
    const enableAssetDropDown = getTenantSettingValueForKey('assetTracking') === 'true';
    const blockVisitActions = frozenJobStatus.includes((job || {}).status);
    const reason =
      this.state.currentStatus === VisitStatus.ONHOLD ||
      this.state.currentStatus === VisitStatus.CLOSED
        ? visit.onHoldReason
        : '';
    const nonEditableVisitStatus = [
      VisitStatus.ONHOLD,
      VisitStatus.COMPLETE,
      VisitStatus.CONVERTED,
      VisitStatus.CLOSED,
      VisitStatus.CANCELED
    ];
    return (
      <div className={classNames(classes.root)}>
        <div className={classNames(classes.subtitle1, classes.title, classes.root)}>
          <span className={classes.icon}>Visit #{visit.visitNumber}</span>
          <div style={{ marginLeft: '30px' }}>
            <VisitStatusComponent
              statuses={[this.state.currentStatus]}
              reason={reason}
              locale={locale}
            />
          </div>
          {!blockVisitActions && (
            <UserPermission I="update" action={PermissionConstants.OBJECT_VISIT}>
              {this.state.currentStatus &&
                !nonEditableVisitStatus.includes(this.state.currentStatus) && (
                  <div
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                    className={classes.edit}
                    onClick={() => this.handleOpenPopUp('openEditVisit')}
                  >
                    <div style={{ margin: '10px' }}>{iconMap('edit', 20)}</div>
                  </div>
                )}
            </UserPermission>
          )}
        </div>
        <div className={classes.leftSide}>
          <ScheduledInfo
            scheduledFor={
              scheduledFor ? moment.unix(scheduledFor).format(AppConstants.DATETIME_FORMAT) : '-'
            }
            locale={locale}
          />
          {!blockVisitActions && (
            <UserPermission I="update" action={PermissionConstants.OBJECT_VISIT}>
              <VisitActions
                inProgressAction={inProgressAction}
                visitActions={visitAvailableActions}
                handleVisitUpdate={this.handleVisitActions}
              />
            </UserPermission>
          )}
        </div>
        <div className={classes.rightSide}>
          <CrewInfo
            locale={locale}
            primaryTechs={visit.primaryTechs.map(
              tech => `${tech?.firstName || ''} ${tech?.lastName || ''}`
            )}
            extraTechs={visit.extraTechs.map(
              tech => `${tech?.firstName || ''} ${tech?.lastName || ''}`
            )}
            departmentName={visit.departmentName}
          />
          {/* <TaskInfo locale={locale} tasks={visit.tasks} tasksInfo={this.state.tasksInfo} /> */}
          <div className={classes.root}>
            <div className={classes.techCell}>
              <div className={classes.headersText}>{labels.viewVisit_shortDescription[locale]}</div>
              <Linkify>
                <div className={classes.dataText}>{visit.description || '-'}</div>
              </Linkify>
            </div>
            {enableAssetDropDown && (
              <div className={classes.techCell}>
                <div className={classes.headersText} style={{ marginLeft: '40px' }}>
                  {labels.viewVisit_assetsWorkedOn[locale]}
                </div>
                <div className={classes.dataText} style={{ marginLeft: '40px' }}>
                  {showVisitAssets && !_.isEmpty(showVisitAssets)
                    ? showVisitAssets.map(asset => asset?.propertyAsset?.assetName || []).join(', ')
                    : '-'}
                </div>
              </div>
            )}
          </div>
        </div>
        <OnHold
          locale={locale}
          save={data => {
            if (!isFutureDate(this.props.visit.scheduledFor)) {
              this.setState({
                confirmAction: 'PUT_ON_HOLD',
                isConfirmModalOpen: true
              });
              this.confirmFn = () => this.putOnHold(data);
            } else {
              this.putOnHold(data);
            }
          }}
          handleClose={() => this.handleClosePopUp('openOnHoldVisit')}
          open={this.state.openOnHoldVisit}
        />
        <VisitEditModal
          user={this.props.user}
          visit={copyVisit}
          job={this.props.job}
          locale={locale}
          techs={this.props.techs}
          propertyAssets={this.props.propertyAssets}
          open={this.state.openEditVisit}
          handleClose={() => this.handleClosePopUp('openEditVisit')}
          onSubmit={async (submitFn, newPayload) => {
            const isChangingVisitTime =
              newPayload.visit?.scheduledFor !== this.props.visit?.scheduledFor;
            if (!isFutureDate(this.props.visit?.scheduledFor) && isChangingVisitTime) {
              this.setState({
                confirmAction: 'RESCHEDULE',
                isConfirmModalOpen: true
              });
              this.confirmFn = submitFn;
            } else {
              await submitFn();
            }
          }}
          onComplete={data => this.props.updateVisits(data)}
          reschedule={false}
        />

        <ThemeProvider>
          <Modal
            title={actions?.[confirmAction]?.title || ''}
            open={isConfirmModalOpen}
            onClose={this.closeConfirmationModal}
            actions={
              <Button
                type={ButtonType.ERROR}
                onClick={async () => {
                  this.setState({ isLoadingConfirm: true });
                  try {
                    await this.confirmFn();
                    this.closeConfirmationModal();
                  } finally {
                    this.setState({ isLoadingConfirm: false });
                  }
                }}
                fullWidth
                loading={isLoadingConfirm}
              >
                Continue
              </Button>
            }
          >
            <Typography>{CONFIRMATION_TEXT}</Typography>
          </Modal>
        </ThemeProvider>
      </div>
    );
  }
}

export default withStyles(styles)(ViewVisit);
