import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { setOpenQuickAddModal } from 'redux/actions/globalActions';
import { QuickAddModal } from 'utils/constants';
import ErrorBoundaries from 'scenes/Error';
import { PermissionConstants } from 'utils/AppConstants';
import { PageHeader, UserPermission, XGrid, ChipArray } from 'components';
import CreateEntryButton from 'components/Buttons/CreateEntryButton';
import { Grid, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BuildHeroThemeProvider } from 'themes/BuildHeroTheme';
import quoteListColumns from './meta/quoteListColumns';
import GET_QUOTES from './queries/getQuoteList';

const useStyles = makeStyles(() => ({
  divider: {
    margin: '8px -24px 18px -24px'
  },
  quickFilters: {
    marginBottom: '28px'
  }
}));

const filterConstants = {
  ALL: 'All',
  DRAFT: 'Draft',
  SENT_TO_CUSTOMER: 'Sent to customer',
  APPROVED: 'Approved',
  MY_QUOTES: 'My quotes'
};

const filterMap = {
  Draft: 'Draft',
  Approved: 'Approved',
  'Sent to customer': 'SentToCustomer'
};

const QuoteList = props => {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const [selectedFilterValue, setSelectedFilterValue] = useState(filterConstants.ALL);

  const pageHeaderButtons = (
    <CreateEntryButton
      handleAdd={() => props.setOpenQuickAddModal(QuickAddModal.CREATE_QUOTE)}
      label="New Quote"
      caslKey={PermissionConstants.OBJECT_QUOTES}
      key="createQuote"
    />
  );

  const quickFilters = ({ setFilterModel, filterModel }) => {
    const handleQuickFilterSelect = filterValue => {
      let newFilterModel = {
        items: []
      };
      if (filterValue === filterConstants.MY_QUOTES) {
        newFilterModel = {
          linkOperator: 'and',
          items: [
            ...filterModel.items?.filter(item => item.id !== 1),
            {
              columnField: 'createdBy',
              operatorValue: 'contains',
              value: user.displayName,
              id: 1
            }
          ]
        };
      } else if (
        filterValue === filterConstants.DRAFT ||
        filterValue === filterConstants.SENT_TO_CUSTOMER ||
        filterValue === filterConstants.APPROVED
      ) {
        newFilterModel = {
          linkOperator: 'and',
          items: [
            ...filterModel.items?.filter(item => item.id !== 1),
            {
              columnField: 'status',
              operatorValue: 'contains',
              value: filterMap[filterValue],
              id: 1
            }
          ]
        };
      }
      setFilterModel(newFilterModel);
      setSelectedFilterValue(filterValue);
    };

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.quickFilters}>
          <BuildHeroThemeProvider>
            <ChipArray
              dataArr={Object.values(filterConstants)}
              selectedValue={selectedFilterValue}
              handleClick={handleQuickFilterSelect}
            />
          </BuildHeroThemeProvider>
        </Grid>
      </Grid>
    );
  };

  return (
    <ErrorBoundaries>
      <UserPermission I="read" action={PermissionConstants.OBJECT_QUOTES}>
        <PageHeader
          pageMapKey="quotes"
          userLocale={user.locale}
          overrideHeaderButtons={pageHeaderButtons}
        />
        <Divider className={classes.divider} />
        <XGrid
          columns={quoteListColumns}
          entityTypeName="Quote"
          tableName="QuoteXGrid"
          query={GET_QUOTES}
          rowHeight={38}
          customComponent={quickFilters}
          enableExport
        />
      </UserPermission>
    </ErrorBoundaries>
  );
};

QuoteList.propTypes = {
  setOpenQuickAddModal: PropTypes.func.isRequired
};

export default connect(null, { setOpenQuickAddModal })(QuoteList);
