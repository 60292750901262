import gql from 'graphql-tag';

const updatePayrollHourType = gql`
  mutation updatePayrollHourType($partitionKey: String, $data: UpdatePayrollHourTypeInput!) {
    updatePayrollHourType(partitionKey: $partitionKey, data: $data) {
      id
      version
      isActive
      hourType
      hourTypeAbbreviation
      hourTypeTag
      isAutofill
      intacctAccountNumber
      intacctNonBillableAccountNumber
      billingHourTypeId
    }
  }
`;

export default updatePayrollHourType;
