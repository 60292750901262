import { useTheme } from '@material-ui/core';

export const useStyles = () => {
  const theme = useTheme();
  return {
    input: {
      color: 'pink',
      '& .MuiOutlinedInput-root.Mui-error': {
        color: 'blue',
        borderBottomStyle: 'solid',
        borderBottomWidth: 2
      },
      '& .MuiOutlinedInput-root.Mui-error fieldset': {
        color: 'green',
        border: 'none'
      }
    },
    error: {
      color: theme.palette.error.main,
      fontSize: 12,
      marginTop: 3
    }
  };
};
