export const dispatchEmployeeFragment = `
  id
  isSales
  partitionKey
  sortKey
  firstName
  lastName
  name
  email
  personalEmail
  status
  cellPhone
  landlinePhone
  profilePictureUrl
  contactAddresses(entityConnection: "ContactAddress") {
    items {
      id
      sortKey
      addressType
      addressLine1
      addressLine2
      city
      county
      state
      zipcode
      country
      latitude
      longitude
    }
  }
  departments(entityConnection: "Department") {
    items {
      id
      sortKey
      mappedEntity {
        ... on Department {
          id
          sortKey
          tagName
          tagType
        }
      }
    }
  }
`;

export const visitJobFragment = `
  parentEntity {
    ... on Job {
      id
      ownerId
      tenantId
      tenantCompanyId
      partitionKey
      entityType
      sortKey
      hierarchy
      entityType
      jobNumber
      customIdentifier
      customerSortKey
      customerName
      priority
      customerPropertyName
      jobTypeName
      jobTypeInternal
      amountNotToExceed
      customerProvidedPONumber
      issueDescription
      customerRep(entityConnection: "CustomerRep") {
        id
        name
        firstName
        lastName
        email
        cellPhone
        landlinePhone
        bestContact
        contactRole
      }
      departments(entityConnection: "Department") {
        items {
          id
          sortKey
          mappedEntity {
            ... on Department {
              id
              sortKey
              tagName
              tagType
            }
          }
        }
      }
      parentEntity {
        ... on CustomerProperty {
          id
          companyName
          companyAddresses(entityConnection: "CompanyAddress") {
            items {
              id
              addressType
              addressLine1
              addressLine2
              city
              state
              zipcode
              latitude
              longitude
            }
          }
          propertyAssets(entityConnection: "PropertyAsset") {
            items {
              id
              hierarchy
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              lastUpdatedDate
              lastUpdatedDateTime
              createdDate
              assetName
              make
              modelNumber
              serialNo
              installDate
              imageUrl
              version
              notes(entityConnection: "Note") {
                items {
                  id
                  hierarchy
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  subject
                  note
                  version
                  createdBy
                  lastUpdatedDate
                  lastUpdatedDateTime
                  lastUpdatedBy
                }
              }
            }
          }
        }
      }
    }
  }        
`;

export const visitTechsFragment = `
  primaryTechs(entityConnection: "VisitPrimaryTech") {
    items {
      id 
      sortKey
      invertedSortKey
      mappedEntity {
        ... on Employee {
          ${dispatchEmployeeFragment}
        }
      }
    }
  }
  extraTechs(entityConnection: "VisitExtraTech") {
    items {
      id 
      sortKey
      invertedSortKey
      mappedEntity {
        ... on Employee {
          ${dispatchEmployeeFragment}
        }
      }
    }
  }
`;

export const visitSchedulesFragment = `
  schedules(entityConnection: "Schedule") {
    items {
      id
      employeeSortKey
      parentId
      hierarchy
      id
      entityType
      version
      tenantId
      tenantCompanyId
      partitionKey
      parentSortKey
      sortKey
      entityType
      scheduledFor
      startTime
      endTime
      onRoute
      delayed
      delayedReason
      onHold
      onHoldReason
      timeSheets(entityConnection: "TimeSheet") {
        items {
          visitDate
          clockInTime
          clockOutTime
          totalDuration
          hours
          minutes
          labourType
          gpsLocations(entityConnection: "GpsLocation") {
            items {
              timestamp
              latitude
              longitude
              altitude
              heading
              speed
              accuracy
              altitudeAccuracy
              userAction
            }
          }
          parentId
          hierarchy
          id
          entityType
        }
      }
    }
  }
`;

export const dispatchVisitAttributeFragment = `
  id
  entityType
  visitNumber
  version
  status
  description
  prerequisites
  prerequisitesAcknowledged
  startTime
  endTime
  scheduledFor
  hierarchy
  partitionKey
  sortKey
  onHold
  onHoldReason
  onRoute
  delayed
  delayedReason
  detailsSent
  departmentName
  departmentId
  tentativeDate
  extraTechsRequired
  extraTechsNumber
  minimumDuration
  actualDuration
  tentativePeriod
  tentativeDate
  tentativeTime
  parentId
  parentSortKey
  createdDate
  lastUpdatedDate
  lastUpdatedDateTime
  visitAssets(entityConnection: "VisitAsset") {
    items {
      id
      propertyAsset(entityConnection: "PropertyAsset") {
        id
        assetName
        make
        modelNumber
        serialNo
        installDate
        location
        propertyZone
        imageUrl
      }
    }
  }
`;

export const dispatchVisitFragment = `
  ${dispatchVisitAttributeFragment}
  ${visitSchedulesFragment}
  ${visitTechsFragment}
  ${visitJobFragment}
`;
