import { useTheme } from '@material-ui/core';

const useStyle = () => {
  const { spacing, palette, breakpoints } = useTheme();

  return {
    leftInfoPanelContainer: {
      display: 'flex',
      borderRight: `1px solid ${palette.divider}`,
      paddingTop: spacing(3),
      width: '243px',
      [breakpoints.down('md')]: {
        width: '192px',
        paddingRight: spacing(1),
        marginRight: spacing(2)
      }
    },
    centreContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      height: 'fit-content',
      [breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column'
      }
    },
    invoiceFormContainer: {
      minWidth: '816px',
      marginTop: spacing(3),
      marginRight: spacing(4),
      marginLeft: spacing(2),
      gridRowStart: 1,
      gridRowEnd: 4,
      [breakpoints.down('md')]: {
        marginLeft: spacing(0),
        marginTop: spacing(2)
      }
    },
    syncStatusContainer: {
      display: 'flex',
      flex: '1 1 auto',
      alignSelf: 'flex-start',
      marginTop: spacing(3),
      marginBottom: spacing(2),
      flexDirection: 'column-reverse',
      rowGap: '25px',
      gridColumnStart: 2,
      [breakpoints.down('md')]: {
        marginBottom: 0,
        columnGap: '42px',
        flexDirection: 'row',
        order: -1,
        flex: '0 1 auto'
      }
    },
    activityListContainer: {
      gridColumnStart: 2,
      [breakpoints.down('md')]: {
        marginTop: spacing(3)
      }
    }
  };
};

export default useStyle;
