import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { CustomerPropertyService, QuickbooksService } from 'services/core';
import ErrorBoundaries from 'scenes/Error';
import { snackbarOn } from 'redux/actions/globalActions';
import { FullScreenModal, Spinner } from 'components';
import { AddPropertyLayout } from 'meta/Customer/NewCustomerProperty';
import AlgoliaSearchWrapper from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import PlacesSearch from 'components/BuildHeroFormComponents/PlacesSearch';
import Constants from 'utils/AppConstants';
import Context from 'components/Context';
import { MUIForm } from '@buildhero/sergeant';
import { TenantSetting, AccountType } from 'utils/constants';
import { getTenantSettingValueForKey } from 'utils';
import { billingAddressCheckbox, shippingAddressCheckbox } from './components';

class AddProperty extends Component {
  constructor(props) {
    super(props);
    this.CustomerPropertyService = new CustomerPropertyService();
    let propertyTypesDefs = {};
    let companyAddresses = '';
    let companyState = '';
    let isExtendedFieldsEnabled;
    let isIntegrationEnabled;
    let isTaxRateAutoAssignDisabled = false;

    if (Context.getCompanyContext()) {
      ({
        customerPropertyTypes: propertyTypesDefs,
        companyAddresses
      } = Context.getCompanyContext().getCompany);
      if (companyAddresses && companyAddresses.items && companyAddresses.items.length === 1) {
        companyState = companyAddresses.items[0].state;
      }
      const { listTenantSettings } = Context.getCompanyContext();

      const extendedAddressFields =
        listTenantSettings &&
        listTenantSettings.filter(
          setting =>
            setting.settingKey === 'customer_extendedFields' && setting.settingValue === 'true'
        );

      const quickbooksSetting =
        listTenantSettings &&
        listTenantSettings.filter(
          setting => setting.settingKey === 'accountingApp' && setting.settingValue !== ''
        );

      isIntegrationEnabled = quickbooksSetting && quickbooksSetting.length > 0;
      isExtendedFieldsEnabled = extendedAddressFields && extendedAddressFields.length > 0;
      const { TAX_RATE_ASSIGN_DISABLED } = TenantSetting;
      isTaxRateAutoAssignDisabled =
        getTenantSettingValueForKey(TAX_RATE_ASSIGN_DISABLED) === 'true';
    }
    this.queryAndSetTaxRates();
    this.state = {
      companyState: companyState || 'CA',
      propertyTypesDefs,
      propertyTypeOptions: _.orderBy(
        propertyTypesDefs?.items || [],
        ['sortOrder', 'tagName'],
        ['asc', 'asc']
      ),
      isExtendedFieldsEnabled,
      isIntegrationEnabled,
      isTaxRateAutoAssignDisabled
    };
  }

  queryAndSetTaxRates = async () => {
    const { user } = this.props;
    const quickBookService = new QuickbooksService();
    const response = await quickBookService.getTaxRates(
      user.tenantId,
      `${user.tenantId}_Company_${user.tenantCompanyId}`
    );
    let taxRateOptions = [];
    if (
      response &&
      response.data &&
      response.data.getCompany &&
      response.data.getCompany.taxRates &&
      response.data.getCompany.taxRates.items
    ) {
      const taxRates = response.data.getCompany.taxRates.items;
      taxRateOptions = _.orderBy(
        taxRates.filter(t => t.accountType !== AccountType.AP) || [],
        'name'
      ).map(taxRate => ({
        label: `${taxRate.name} - ${taxRate.taxRate}`,
        value: taxRate.id,
        ...taxRate
      }));
      taxRateOptions = [{ label: 'None', value: '' }].concat(taxRateOptions);
      this.setState(prevState => ({
        ...prevState,
        taxRates,
        taxRateOptions
      }));
    }
  };

  render() {
    const {
      isExtendedFieldsEnabled,
      isIntegrationEnabled,
      isTaxRateAutoAssignDisabled,
      taxRateOptions,
      propertyTypesDefs,
      propertyTypeOptions
    } = this.state;
    let { data } = this.props;
    const { layout, onCreateService } = this.props;
    // for copying customer billing address to property billing address
    const customerAddresses = (this.props.parent || {}).companyAddresses;
    const customerBillingAddress = customerAddresses.items.filter(
      address => address.addressType === 'billingAddress'
    );
    const customerBusinessAddress = customerAddresses.items.filter(
      address => address.addressType === 'businessAddress'
    );
    if (!data) data = {};
    if (customerBillingAddress && customerBillingAddress.length > 0) {
      const [customerBilling] = customerBillingAddress.slice(-1);
      data.billTo = data.customerBillTo = customerBilling.billTo;
      data.billingAddressLine1 = data.customerBillingAddressLine1 = customerBilling.addressLine1;
      data.billingAddressLine2 = data.customerBillingAddressLine2 = customerBilling.addressLine2;
      data.billingCity = data.customerBillingCity = customerBilling.city;
      data.billingState = data.customerBillingState = customerBilling.state;
      data.billingZipcode = data.customerBillingZipcode = customerBilling.zipcode;
      data.billingLatitude = data.customerBillingLatitude = customerBilling.latitude;
      data.billingLongitude = data.customerBillingLongitude = customerBilling.longitude;
      data.customerTaxRateLabel = this.props.parent.taxRateLabel;
      // default to haveing billing customer = customer
      data.customerTaxRateId = this.props.parent?.taxRateId;
      data.customerIsTaxable = this.props.parent?.isTaxable;
      data.parentId = data.billingCustomerId = this.props.parent.id;
      data.customerName = data.billingCustomerName = this.props.parent.customerName;
      data.sameAddress = 'true';
      data.billingAddressCheckbox = true;
      data.shippingAddressCheckbox = false;
      // default to have tax option = customer's tax option
      data.taxRateId = this.state.isTaxRateAutoAssignDisabled ? '' : data.customerTaxRateId;
      data.isTaxable = data.customerIsTaxable;
      data.customerFilter = {
        entityType: ['Customer'],
        status: Constants.ACTIVE
      };
    }
    if (customerBusinessAddress && customerBusinessAddress.length > 0) {
      const customerBusiness = customerBusinessAddress[0];
      data.customerAddressLine1 = customerBusiness.addressLine1;
      data.customerAddressLine2 = customerBusiness.addressLine2;
      data.customerCity = customerBusiness.city;
      data.customerState = customerBusiness.state;
      data.customerZipcode = customerBusiness.zipcode;
      data.customerLatitude = customerBusiness.latitude;
      data.customerLongitude = customerBusiness.longitude;
    }

    if (isExtendedFieldsEnabled) {
      data.isExtendedFieldsEnabled = isExtendedFieldsEnabled;
    }
    data.isIntegrationEnabled = isIntegrationEnabled;
    // Set property type options
    let typeOptions;
    if (propertyTypeOptions) {
      typeOptions = propertyTypeOptions.map(type => ({
        value: type.sortKey,
        label: type.tagName
      }));
    }
    const showLoading = [propertyTypesDefs].every(({ items }) => !items);
    if (showLoading) {
      return <Spinner />;
    }

    return (
      <ErrorBoundaries>
        <FullScreenModal
          fixedHeader
          open={this.props.open}
          handleClose={this.props.handleClose}
          modalHeaderButtons={this.props.headerButtons}
          title={this.props.title}
        >
          <MUIForm
            data={data}
            layout={layout}
            onCreateService={onCreateService}
            configuration={AddPropertyLayout(
              taxRateOptions,
              typeOptions,
              isTaxRateAutoAssignDisabled
            )}
            customComponents={{
              AlgoliaSearchWrapper,
              PlacesSearch,
              billingAddressCheckbox,
              shippingAddressCheckbox
            }}
            onComplete={this.props.onComplete}
          />
        </FullScreenModal>
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapNewAddPropertyToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedAddProperty = connect(mapStateToProps, mapNewAddPropertyToProps)(AddProperty);

export default reduxConnectedAddProperty;
