import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  viewDescriptionSection: {
    minWidth: 150,
    padding: '0 16px'
  },
  title: {
    fontSize: '14px',
    color: theme.palette.grayscale(20)
  },
  titleDescription: {
    fontSize: '10px',
    color: theme.palette.grayscale(60)
  },
  detailViewTitle: {
    color: theme.palette.other.logoGreen,
    fontWeight: 'normal',
    marginBottom: theme.spacing(1),
    paddingLeft: 16
  },
  costSummaryWrapper: {
    borderTop: `2px solid ${theme.palette.grayscale(0)}`
  },
  costSummaryLabel: {
    minWidth: 100
  }
}));
