import {
  BoldCell,
  TagCell,
  tableCurrencyFormatter,
  tableEmptyValueFormatter,
  tableDateFormatter
} from 'components/WrapTable';

import { checkPermission } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';

export const useGetTimeTrackingTableColumns = user => [
  {
    headerName: 'Tech Name',
    field: 'name',
    valueFormatter: tableEmptyValueFormatter
  },
  {
    headerName: 'Status',
    field: 'approvalStatus',
    renderCell: TagCell
  },
  {
    headerName: 'Total',
    field: 'duration',
    valueFormatter: tableEmptyValueFormatter
  },
  {
    headerName: 'Cost',
    field: 'totalCost',
    hide:
      !user || !checkPermission('allow', PermissionConstants.DATA_PAYROLL_RATES_ON_REPORTS, user),
    valueFormatter: tableCurrencyFormatter,
    renderCell: BoldCell
  },
  {
    headerName: 'Reviewed By',
    field: 'reviewedByList',
    valueFormatter: tableEmptyValueFormatter
  },
  {
    headerName: 'Date Reviewed',
    field: 'reviewedDateList',
    valueFormatter: tableDateFormatter
  },
  {
    headerName: 'Approved By',
    field: 'approvedByList',
    valueFormatter: tableEmptyValueFormatter
  },
  {
    headerName: 'Date Approved',
    field: 'approvedDateList',
    valueFormatter: tableDateFormatter
  }
];
