import React from 'react';
import { connectRange } from 'react-instantsearch-dom';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import AppConstants from 'utils/AppConstants';

const RangeInput = ({ currentRefinement, min, max, precision, refine }) => (
  <Grid item>
    <Typography variant="body2" style={{ padding: 10 }}>
      DATES
    </Typography>
    <form>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          allowKeyboardControl
          disableToolbar
          autoOk
          variant="inline"
          inputVariant="filled"
          invalidLabel=""
          fullWidth
          name="startDate"
          onChange={date =>
            refine({
              ...currentRefinement,
              min: (date && date.startOf('day').unix()) || min
            })
          }
          format={AppConstants.DATE_FORMAT}
          label="Start date"
          value={
            (currentRefinement.min &&
              moment.unix(currentRefinement.min).format(AppConstants.DATE_FORMAT)) ||
            null
          }
          style={{ padding: 8 }}
          InputProps={{ style: { marginTop: -8, marginLeft: -4 } }}
        />
        <KeyboardDatePicker
          allowKeyboardControl
          disableToolbar
          autoOk
          variant="inline"
          inputVariant="filled"
          invalidLabel=""
          fullWidth
          name="endDate"
          onChange={date =>
            refine({
              ...currentRefinement,
              max: (date && date.endOf('day').unix()) || max
            })
          }
          format={AppConstants.DATE_FORMAT}
          label="End date"
          value={
            (currentRefinement.max &&
              moment.unix(currentRefinement.max).format(AppConstants.DATE_FORMAT)) ||
            null
          }
          style={{ padding: 8 }}
          InputProps={{ style: { marginTop: -8, marginLeft: -4 } }}
        />
      </MuiPickersUtilsProvider>
    </form>
  </Grid>
);

const CustomRangeInput = connectRange(RangeInput);

export default CustomRangeInput;
