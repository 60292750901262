import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import { CompanyService } from 'services/core';
import { Logger } from 'services/Logger';
import FormViewer from 'components/FormSection/FormViewer';
import ErrorBoundaries from 'scenes/Error';
import {
  formsPublishedTable,
  formsDraftsTable,
  formsPublishedTableAdmin,
  formsDraftsTableAdmin
} from 'meta/Settings/Forms';
import Labels from 'meta/labels';
import {
  PageHeader,
  SectionHeader,
  AddRecordButton,
  UserPermission,
  ResponsiveTable,
  Modal,
  LinkButton
} from 'components';
import Spinner from 'components/Spinners/CircularIndeterminate';
import ConfirmModal from 'components/Modal/ConfirmDialog';
import { snackbarOn } from 'redux/actions/globalActions';
import { PermissionConstants } from 'utils/AppConstants';

class Forms extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.CompanyService = new CompanyService();
    document.title = 'BuildOps - Forms';
    const { search } = this.props.history.location;
    const isAdmin = search && search.toLowerCase().indexOf('isadmin') !== -1;
    this.state = {
      companyData: '',
      showFormViewerModal: false,
      FormViewerRecord: '',
      publishedFormsList: [],
      isListLoading: true,
      confirmDialog: false,
      isAdmin: isAdmin || false
    };
  }

  componentWillUnmount = () => {
    this.mounted = false;
  };

  queryPublishedForms = async (nextToken, limit) => {
    const sortKey = `${this.props.user.tenantId}_Company_${this.props.user.tenantCompanyId}`;
    try {
      const { data } = await this.CompanyService.getCompanyWithForms(
        this.props.user.tenantId,
        sortKey,
        nextToken,
        limit
      );

      if (data) {
        const filter = item => item.latestPublishedFormDefinition;
        const processedData = this.processQueryData(data, filter);
        this.setState({ publishedFormsList: processedData.items });
        return processedData;
      }
    } catch (error) {
      Logger.error(`Error in fetching forms ${JSON.stringify(error)}`);
      this.props.snackbarOn('error', 'Unable to fetch forms, please try again later', error);
    }
    return [];
  };

  queryFormDrafts = async (nextToken, limit) => {
    const sortKey = `${this.props.user.tenantId}_Company_${this.props.user.tenantCompanyId}`;
    try {
      const { data } = await this.CompanyService.getCompanyWithForms(
        this.props.user.tenantId,
        sortKey,
        nextToken,
        limit
      );

      const filter = item => {
        const { latestPublishedFormDefinition, latestDraftFormDefinition } = item;
        return (
          latestPublishedFormDefinition === null ||
          (latestDraftFormDefinition !== null &&
            latestDraftFormDefinition.lastUpdatedDateTime >
              latestPublishedFormDefinition.lastUpdatedDateTime)
        );
      };

      if (data) return this.processQueryData(data, filter);
    } catch (error) {
      Logger.error(`Error in fetching forms ${JSON.stringify(error)}`);
      this.props.snackbarOn('error', 'Unable to fetch forms, please try again later', error);
    }
    return true;
  };

  processQueryData = (data, filter) => {
    if (!data.getCompany) return;
    const { companyData, isAdmin } = this.state;
    if (!companyData && this.mounted) {
      this.setState({ companyData: data.getCompany });
    }
    const { forms, ...rest } = data.getCompany;
    const { nextToken } = forms;
    const filteredForms = forms.items.filter(item => {
      if (isAdmin) return true;
      if (item.viewType !== 'Inline') return true;
      return false;
    });

    const formsList = filteredForms.filter(filter);
    const publishedList = filteredForms.filter(item => item.latestPublishedFormDefinition);

    formsList.map(item => {
      const form = item;
      form.draftType = item.latestPublishedFormDefinition === null ? 'New Form' : 'Update';
      form.parent = rest;
      return form;
    });

    publishedList.map(item => {
      const { latestPublishedFormDefinition } = item;
      const form = item;
      if (latestPublishedFormDefinition !== null) {
        form.lastUpdatedTime = latestPublishedFormDefinition.lastUpdatedDateTime;
      }
      form.parent = rest;
      return form;
    });

    this.setState({
      publishedFormsList: publishedList,
      isListLoading: false
    });

    // eslint-disable-next-line consistent-return
    return { nextToken: nextToken, items: formsList };
  };

  publishForm = async record => {
    let actionStatus = false;
    const { id, version } = record;
    const { tenantId, tenantCompanyId } = this.props.user;
    const payload = {
      id: id,
      publish: true,
      tenantId: tenantId,
      tenantCompanyId: tenantCompanyId,
      version: version || 0
    };

    try {
      const { data } = await this.CompanyService.updateForm(payload);
      actionStatus = true;
      if (data) this.props.snackbarOn('success', 'Successfully published form.');
      this.queryPublishedForms();
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to publish form, please try again later', error);
    }
    return actionStatus;
  };

  deleteForm = async record => {
    let actionStatus = false;
    try {
      const sortKey = `${this.props.user.tenantId}_${this.props.user.tenantCompanyId}_Form_${record.id}`;
      const { data } = await this.CompanyService.deleteForm(this.props.user.tenantId, sortKey);

      if (data) {
        actionStatus = true;
        this.props.snackbarOn('success', `Successfully deleted form: ${record.name}`);
        this.queryPublishedForms();
        this.setState({ confirmDialog: false, confirmAction: '' });
      }
    } catch (error) {
      Logger.error(error);
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        this.props.snackbarOn('error', error.graphQLErrors[0].message);
      } else {
        this.props.snackbarOn('error', 'Unable to delete form, please try again later');
      }
    }
    return actionStatus;
  };

  deleteFormDefinition = async record => {
    let actionStatus = false;
    try {
      const { data } = await this.CompanyService.deleteFormDefinition(
        this.props.user.tenantId,
        record.latestDraftFormDefinitionSortKey
      );
      if (data) {
        actionStatus = true;
        this.props.snackbarOn('success', `Successfully deleted draft: ${record.name}`);
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to delete form, please try again later', error);
    }
    return actionStatus;
  };

  handlePublishedRowActions = (mode, record) => {
    if (mode === 'delete') {
      this.setState({
        confirmDialog: true,
        confirmAction: () => this.deleteForm(record)
      });
    } else if (mode === 'view') {
      const { formDefinitionJson } = record.latestPublishedFormDefinition;
      this.setState({
        FormViewerRecord: {
          name: record.name,
          description: record.description,
          formDataJson: JSON.parse(formDefinitionJson)
        }
      });
      this.openModal();
    } else if (mode === 'edit' || mode === 'copy') {
      this.props.history.push(`/settings/forms/${mode}/${record.id}`, {
        recordSortKey: record.sortKey
      });
    }
    return true;
  };

  handleDraftRowActions = (mode, record) => {
    const { latestPublishedFormDefinition, latestDraftFormDefinition } = record;
    if (mode === 'publish') return this.publishForm(record);

    if (mode === 'delete') {
      if (latestPublishedFormDefinition === null) {
        return this.deleteForm(record);
      }
      return this.deleteFormDefinition(record);
    }

    if (mode === 'view') {
      const { formDefinitionJson } = latestDraftFormDefinition;
      this.setState({
        FormViewerRecord: {
          name: record.name,
          description: record.description,
          formDataJson: JSON.parse(formDefinitionJson)
        }
      });
      this.openModal();
    } else if (mode === 'edit' || mode === 'copy') {
      this.props.history.push(`/settings/forms/${mode}/${record.id}`, {
        recordSortKey: record.sortKey
      });
    }
    return true;
  };

  handleCancelConfirmation = () => this.setState({ confirmDialog: false, confirmAction: '' });

  openModal = () => this.setState({ showFormViewerModal: true });

  closeModal = () => this.setState({ showFormViewerModal: false });

  render() {
    const { computedMatch, user } = this.props;
    const {
      showFormViewerModal,
      FormViewerRecord,
      publishedFormsList,
      isListLoading,
      confirmDialog,
      confirmAction,
      isAdmin
    } = this.state;

    // TODO: Switch to responsive tables
    if (this.props.user.username && !this.props.user.tenantCompanyId) {
      this.props.snackbarOn(
        'error',
        'No company is associated with you. \nPlease sign in as employee or tenant admin'
      );
      return <> </>;
    }

    const publishedTable = isAdmin ? formsPublishedTableAdmin : formsPublishedTable;
    const draftsTable = isAdmin ? formsDraftsTableAdmin : formsDraftsTable;

    return (
      <ErrorBoundaries>
        <UserPermission I="allow" action={PermissionConstants.MENU_SETTINGS}>
          <PageHeader pageMapKey="forms" userLocale={user.locale} />
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12}>
              <SectionHeader title={Labels['Published Forms'][this.props.user.locale]} />
            </Grid>
          </Grid>
          <Grid container>
            {isListLoading ? (
              <Spinner />
            ) : (
              <Grid item xs={12}>
                <div style={{ minHeight: 190 }}>
                  {this.state.companyData && (
                    <AddRecordButton
                      label={Labels['Create new form'][this.props.user.locale]}
                      icon={AddIcon}
                      component={Link}
                      to={{
                        pathname: `${computedMatch.url}/new`,
                        state: { parent: this.state.companyData }
                      }}
                    />
                  )}
                  <ResponsiveTable
                    rowMetadata={publishedTable.captionAttributes}
                    data={publishedFormsList}
                    service={this.queryPublishedForms}
                    rowActionButtons={{
                      edit: {
                        label: 'Edit',
                        caslAction: 'update',
                        icon: 'Edit'
                      },
                      copy: {
                        label: 'Copy',
                        caslAction: 'copy',
                        icon: 'FileCopy'
                      },
                      delete: {
                        label: 'Delete',
                        caslAction: 'delete',
                        icon: 'Delete'
                      }
                    }}
                    rowActions={this.handlePublishedRowActions}
                    noDataMsg={publishedTable.noDataMsg}
                    refreshAction={() => this.queryPublishedForms()}
                    customCellComponents={{
                      LinkButton: ({ record, meta }) => (
                        <LinkButton
                          label={record[meta.id]}
                          disableLink
                          onClick={() => this.handlePublishedRowActions(meta.showLink, record)}
                        />
                      )
                    }}
                  />
                </div>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={3} style={{ marginTop: 12 }}>
              <Grid item xs={12}>
                <SectionHeader title={Labels['Form Drafts'][this.props.user.locale]} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ResponsiveTable
              rowMetadata={draftsTable.captionAttributes}
              service={this.queryFormDrafts}
              refreshAction={() => this.queryPublishedForms()}
              rowActionButtons={{
                publish: {
                  label: 'Publish',
                  caslAction: 'publish',
                  icon: 'Publish'
                },
                edit: {
                  label: 'Edit',
                  caslAction: 'update',
                  icon: 'Edit'
                },
                copy: {
                  label: 'Copy',
                  caslAction: 'copy',
                  icon: 'FileCopy'
                },
                delete: {
                  label: 'Delete',
                  caslAction: 'delete',
                  icon: 'Delete'
                }
              }}
              rowActions={this.handleDraftRowActions}
              noDataMsg={draftsTable.noDataMsg}
              customCellComponents={{
                LinkButton: ({ record, meta }) => (
                  <LinkButton
                    label={record[meta.id]}
                    disableLink
                    onClick={() => this.handleDraftRowActions(meta.showLink, record)}
                  />
                )
              }}
            />
          </Grid>
          <Modal open={showFormViewerModal} handleClose={this.closeModal} width={696}>
            <FormViewer
              name={FormViewerRecord.name}
              description={FormViewerRecord.description}
              formMetaData={FormViewerRecord.formDataJson}
              handleClose={this.closeModal}
              allowToggle
            />
          </Modal>
          <ConfirmModal
            open={confirmDialog}
            confirm={confirmAction}
            cancel={this.handleCancelConfirmation}
            message="published form"
          />
        </UserPermission>
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});
const mapFormsToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const reduxConnectedForms = connect(mapStateToProps, mapFormsToProps)(Forms);

export default reduxConnectedForms;
