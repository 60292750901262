import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDrag } from 'react-dnd';
import classnames from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  TableContainer,
  TablePagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Checkbox,
  IconButton
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons/';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import * as R from 'ramda';
import { UserPermission } from 'components/AppPermissions';
import { Placeholder } from 'components';
import { COMPANY_SETTING_TYPE } from 'constants/common';
import ErrorBoundaries from 'scenes/Error';
import { rgbaToHexCode } from 'utils';
import TableHead from './TableHead';
import RowDetail from './RowDetail';
import NamedIcon from './NamedIcon';
import ActionsMenu from './ActionsMenu';
import { getCellDataAlignment, getCellComponent, getRowId } from './tableUtils';
function getRowBackgroundColor(contextBackgroundColor, theme) {
  const defaultBackgroundColor = theme.palette.grayscale(100);
  // If the contextual background color is identical to the default row
  // background color, make row background color the global default (assumes
  // they are not equal). Otherwise always use the default row background color.
  return defaultBackgroundColor.startsWith(
    rgbaToHexCode(contextBackgroundColor) ?? contextBackgroundColor
  )
    ? theme.palette.background.default
    : defaultBackgroundColor;
}
const tableCellStyles = ({ theme, multiline, metadata }) => ({
  width: metadata.width || 'auto',
  borderStyle: 'solid',
  borderColor: theme.palette.grayscale(90),
  borderWidth: 0,
  borderBottomWidth: 1,
  borderLeftWidth: 1,
  paddingTop: multiline ? theme.spacing(1.75) : 0,
  paddingBottom: multiline ? theme.spacing(1.75) : 0,
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  verticalAlign: multiline ? 'top' : null,
  '&:first-child': {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2.5)
  },
  '&:last-child': {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2.5),
    borderRightWidth: 1
  },
  '&.hide': {
    visibility: 'hidden'
  },
  '&.totalsRow': {
    border: 'none',
    borderTop: '1px solid black',
    '&:first-child': {
      borderLeft: `1px solid ${theme.palette.grayscale(90)}`
    },
    '&:last-child': {
      borderRight: `1px solid ${theme.palette.grayscale(90)}`
    },
    '& div': {
      fontWeight: 'bold'
    }
  }
});
const useStylesTableContent = makeStyles(theme => ({
  tableWrapper: ({ maxHeight }) => ({
    maxHeight,
    marginTop: theme.spacing(1),
    borderRight: `1px solid ${theme.palette.grayscale(90)}`,
    borderLeft: `1px solid ${theme.palette.grayscale(90)}`
  }),
  table: {
    width: '100%',
    // need this to have borders apply to sticky headers
    // https://stackoverflow.com/questions/50361698/border-style-do-not-work-with-sticky-position-element
    borderCollapse: 'separate'
  },
  tableRow: ({ rowHeight, rowBackgroundColor, isPaginationDisabled, isRowClickAction }) => ({
    height: rowHeight,
    // eslint-disable-next-line no-nested-ternary
    cursor: isRowClickAction ? 'pointer' : undefined,
    backgroundColor: rowBackgroundColor,
    '&:last-child td': {
      borderBottomWidth: isPaginationDisabled ? 1 : 0
    },
    '&.totalsRow': {
      background: 'none'
    },
    '&.collapsible': {
      background: theme.palette.grayscale(96)
    }
  }),
  dndRow: {
    cursor: 'grab !important',
    '&:hover': {
      '& td': {
        backgroundColor: theme.palette.support.green.light
      }
    }
  },
  isRowClickAction: {
    cursor: 'pointer'
  },
  tableCell: ({ multiline, metadata }) => tableCellStyles({ multiline, theme, metadata }),
  tableCellNumberType: ({ multiline, metadata }) => ({
    ...tableCellStyles({ multiline, theme, metadata }),
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'zero' on, 'salt' on, 'ss01' on"
  }),
  paginationFooter: {
    borderColor: theme.palette.grayscale(90),
    borderWidth: 1,
    borderStyle: 'solid'
  }
}));

// to avoid calling for each cell, placed it here
let companyTimezone;

export default function TableContent(props) {
  const {
    dragEligible,
    dragItemName,
    dragPreviewImg,
    dragOptions,
    dragTransform,
    sortOrder,
    sortBy,
    onRowClick,
    onSelectRowClick,
    onSelectAllClick,
    onRequestSort,
    rows,
    selectedRows,
    isSelectionEnabled,
    rowActionButtons,
    isLoading,
    rowActions,
    noDataMsg,
    caslKey,
    metadata,
    reorderColumn,
    multiline,
    expandedRows,
    shouldUseQueries,
    rowDetailLayout,
    rowCollapsible,
    customCellComponents,
    maxHeight: maxHeightProp,
    footerComponent,
    nonEditableList,
    backgroundColor: contextBackgroundColor,
    hasSummaryRow,
    refreshTable,
    /** @prop {boolean} isFullScreen - is a show-more dynamically growing table */
    isFullScreen,
    // TODO - change fullScreen table to use rowsPerPage and onChangeRowsPerPage
    /**
     * @prop {boolean} isPaginationDisabled - unrestricted table. no pagination
     *  options. Show all data.
     */
    isPaginationDisabled,
    /**
     * @prop {number} rowsPerPage - if it's a paginated table, number of items
     *  per page. If it's a showMore table, number of items to load when
     *  querying for more data. Only used if !isPaginationDisabled.
     */
    rowsPerPage,
    /**
     * @prop  {func}   onChangeRowsPerPage - cb function passing back newly
     *  selected rowsPerPage, only used if !isPaginationDisabled.
     * @param {number} rowsPerPage - newly selected rowsPerPage from select
     *  component
     */
    onChangeRowsPerPage,
    /**
     * @prop {number} rowsTotalCount - total number of rows. If
     *  isPaginationDisabled, it's the count of all the data items. If not, it
     *  should represent the count query from the server.
     */
    rowsTotalCount,
    page,
    /**
     * @prop {func}   onChangePage - cb function passing back newly selected
     *  page using arrow keys
     */
    onChangePage,
    isListDataTable,
    isRowClickAction,
    allRowsAreSelectable = true,
    timezoneSensitiveColumnIds
  } = props;

  companyTimezone = useSelector(s => s.companySettings?.[COMPANY_SETTING_TYPE.PAYROLL]?.timeZone);

  // don't use maxHeight if page table
  const maxHeight = !isFullScreen && !isPaginationDisabled ? undefined : maxHeightProp;
  const ROW_HEIGHT = 40; // In px
  const theme = useTheme();
  const rowBackgroundColor = getRowBackgroundColor(contextBackgroundColor, theme);
  const classes = useStylesTableContent({
    metadata,
    multiline,
    rowHeight: ROW_HEIGHT,
    maxHeight,
    contextBackgroundColor,
    rowBackgroundColor,
    hasSummaryRow,
    isPaginationDisabled,
    isRowClickAction
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClick = (event, record) => {
    setAnchorEl({ ref: event.currentTarget, record });
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  if (rowActionButtons && Object.keys(rowActionButtons).length > 0 && !rowActions) {
    console.warn(
      '"rowActionButtons" will not be displayed without a "rowActions" handler function'
    );
  }
  // +1 for each of checkbox, row actions kebab menu, and detail view (expand more) button
  const columnCount = metadata.length + 3;
  const shouldShowRowButtons = rowActionButtons && !R.isEmpty(rowActionButtons) && rowActions;
  // scrolling adjustments for fullScreen vs paginated table
  const tableWrapperScrollStyle = isFullScreen ? { overflow: 'auto' } : {};
  const tableContainerScrollStyle = isFullScreen ? { overflow: 'visible' } : {};
  const tableScrollStyle = isFullScreen ? { overflowX: 'auto' } : {};
  // pass back new numRows value as int instead of event to onChangeRowsPerPage cb
  const handleChangeRowsPerPage = event => onChangeRowsPerPage(parseInt(event.target.value, 10));

  
  return (
    <div className={classes.tableWrapper} style={tableWrapperScrollStyle}>
      <TableContainer style={tableContainerScrollStyle}>
        <Table className={classes.table} style={tableScrollStyle}>
          <TableHead
            classes={{ tableCell: classes.tableCell }}
            selectedRowsCount={selectedRows.size}
            sortOrder={sortOrder}
            sortBy={sortBy}
            onSelectAllClick={onSelectAllClick}
            shouldUseQueries={shouldUseQueries}
            onRequestSort={onRequestSort}
            rowCount={rows.length}
            rowDetailLayout={rowDetailLayout}
            rowCollapsible={rowCollapsible}
            metadata={metadata}
            isSelectionEnabled={isSelectionEnabled}
            areRowButtonsEnabled={
              props.rowActionButtons && Object.keys(props.rowActionButtons).length > 0
            }
            customCellComponents={customCellComponents}
            user={props.user}
            isLoading={isLoading}
            reorderColumn={reorderColumn}
            isListDataTable={isListDataTable}
            allRowsAreSelectable={allRowsAreSelectable}
          />
          <ErrorBoundaries>
            <TableBody>
              {isLoading ? (
                <ComponentRowWrapper colSpan={columnCount} backgroundColor={rowBackgroundColor}>
                  <Placeholder variant="table" repeatCount={2} />
                </ComponentRowWrapper>
              ) : (
                <>
                  {rows.map((rowData, rowIndex) => {
                    const rowId = getRowId(rowData);
                    const isRowSelected = !rowData?.selectionDisabled && selectedRows.has(rowId);
                    const isRowExpanded = expandedRows.has(rowId);
                    const rowKey = `${rowId}`;
                    const activeRowActionButtons = { ...rowActionButtons };
                    if (
                      nonEditableList &&
                      nonEditableList.includes(rowId) &&
                      activeRowActionButtons.edit
                    ) {
                      delete activeRowActionButtons.edit;
                      // Remove delete option for non-editable entity if present (eg. Deactivate option on already deactivated property)
                      if (activeRowActionButtons.delete) {
                        delete activeRowActionButtons.delete;
                      }
                    }
                    return (
                      <CustomTableRow
                        classes={{
                          tableCell: classes.tableCell,
                          tableRow: classes.tableRow,
                          dndRow: classes.dndRow,
                          tableCellNumberType: classes.tableCellNumberType
                        }}
                        dragEligible={dragEligible}
                        dragItemName={dragItemName}
                        dragPreviewImg={dragPreviewImg}
                        dragOptions={dragOptions}
                        dragTransform={dragTransform}
                        rowData={rowData}
                        rowKey={rowId}
                        customCellComponents={customCellComponents}
                        metadata={metadata}
                        rowIndex={rowIndex}
                        isSelectionEnabled={isSelectionEnabled}
                        isRowSelected={isRowSelected}
                        isRowExpanded={isRowExpanded}
                        isRowClickAction={isRowClickAction}
                        rowDetailLayout={rowDetailLayout}
                        rowCollapsible={rowCollapsible}
                        rowActionButtons={activeRowActionButtons}
                        rowActions={rowActions}
                        shouldShowRowButtons={shouldShowRowButtons}
                        onRowClick={onRowClick}
                        onSelectRowClick={onSelectRowClick}
                        onMouseEnterRow={props.onMouseEnterRow}
                        onMouseLeaveRow={props.onMouseLeaveRow}
                        onMenuClick={handleMenuClick}
                        caslKey={caslKey}
                        refreshTable={refreshTable}
                        timezoneSensitiveColumnIds={timezoneSensitiveColumnIds}
                        key={`tableRow-${rowKey}`}
                      />
                    );
                  })}
                  {rows.length === 0 && (
                    <ComponentRowWrapper colSpan={columnCount} backgroundColor={rowBackgroundColor}>
                      {noDataMsg}
                    </ComponentRowWrapper>
                  )}
                </>
              )}
            </TableBody>
          </ErrorBoundaries>
        </Table>
      </TableContainer>
      {!isPaginationDisabled && !isFullScreen && (
        <TablePagination
          className={classes.paginationFooter}
          rowsPerPageOptions={[5, 10, 25]}
          rowsPerPage={rowsPerPage}
          count={rowsTotalCount === null ? -1 : rowsTotalCount}
          page={page}
          onChangePage={(e, newPage) => onChangePage(newPage)}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          component="div"
          prevIconButtonProps={{
            disabled: isLoading || page <= 0
          }}
          nextIconButtonProps={{
            disabled: isLoading || (page + 1) * rowsPerPage >= rowsTotalCount
          }}
        />
      )}
      {!isPaginationDisabled && isFullScreen && footerComponent()}
      {shouldShowRowButtons && (
        <ErrorBoundaries>
          <ActionsMenu
            rowActionButtons={rowActionButtons}
            rowActions={rowActions}
            handleMenuClose={handleMenuClose}
            anchorEl={anchorEl}
          />
        </ErrorBoundaries>
      )}
    </div>
  );
}

const CustomTableRowBase = React.forwardRef((props, ref) => {
  const {
    classes,
    rowData,
    rowKey,
    metadata,
    isDnd,
    isSelectionEnabled,
    isRowSelected,
    isRowExpanded,
    isRowClickAction,
    rowDetailLayout,
    rowCollapsible,
    rowActionButtons,
    rowActions,
    shouldShowRowButtons,
    onRowClick,
    onSelectRowClick,
    onMenuClick,
    caslKey,
    refreshTable,
    rowIndex,
    onMouseDown,
    onMouseUp,
    onMouseEnterRow,
    onMouseLeaveRow
  } = props;
  const getTableCellClassName = columnMetadata => {
    if (
      (rowData.totalsRow && columnMetadata.id === 'costCode') ||
      (rowData.totalsRow && columnMetadata.id === 'costDescription')
    ) {
      return `${classes.tableCell} hide`;
    }
    if (rowData.totalsRow) {
      return `${classes.tableCell} ${rowData.totalsRow}`;
    }
    if (columnMetadata.numeric) {
      return classes.tableCellNumberType;
    }
    return classes.tableCell;
  };
  const displayTableCells = (rowDataInput, isCollapsible = false) => {
    return metadata.map((columnMetadata, columnIndex) => {
      if (columnMetadata.hide || columnMetadata.internal) return null;
      const cellKey = isCollapsible
        ? `${rowKey}-collapsible-${columnMetadata.id}`
        : `${rowKey}-${columnMetadata.id}`;
      return (
        <CustomTableCell
          className={getTableCellClassName(columnMetadata)}
          rowData={rowDataInput}
          rowIndex={rowIndex}
          customCellComponents={props.customCellComponents}
          columnMetadata={columnMetadata}
          columnIndex={columnIndex}
          caslKey={caslKey}
          refreshTable={refreshTable}
          key={cellKey}
          id={cellKey}
          rowCollapsible={rowCollapsible}
          isRowExpanded={isRowExpanded}
          timezoneSensitiveColumnIds={props.timezoneSensitiveColumnIds}
        />
      );
    });
  };
  const displayRowActionButton = rowDataInput =>
    shouldShowRowButtons && (
      <TableCell
        align="right"
        padding="none"
        className={`${classes.tableCell} ${rowDataInput.totalsRow && 'totalsRow'}`}
        key={`rowButtonsCell-${rowKey}`}
      >
        {!rowDataInput.totalsRow && (
          <RowButtons
            rowData={rowDataInput}
            rowIndex={rowIndex}
            rowActions={rowActions}
            rowActionButtons={rowActionButtons}
            onMenuClick={onMenuClick}
          />
        )}
      </TableCell>
    );
  const displayExpandableCustomRow = () =>
    rowDetailLayout &&
    !rowCollapsible &&
    isRowExpanded && <RowDetail layout={rowDetailLayout} rowData={rowData} />;
  const addCollapsibleRows = collapsibleRowDataInput => {
    return (
      <>
        {collapsibleRowDataInput?.map(childRowData => {
          const rowId = getRowId(childRowData);
          return (
            <TableRow
              className={classnames(
                rowData.totalsRow,
                classes.tableRow,
                'collapsible',
                { [classes.dndRow]: isDnd },
                { [classes.isRowClickAction]: isRowClickAction }
              )}
              key={`tableRow-collapsible-${rowId}`}
            >
              {displayTableCells(childRowData, true)}
              {displayRowActionButton(childRowData)}
            </TableRow>
          );
        })}
      </>
    );
  };

  return (
    <>
      <TableRow
        className={classnames(
          rowData.totalsRow,
          classes.tableRow,
          { [classes.dndRow]: isDnd },
          { [classes.isRowClickAction]: isRowClickAction }
        )}
        onClick={event => onRowClick(event, rowData, isDnd)}
        role="checkbox"
        ref={ref}
        tabIndex={-1}
        onMouseEnter={e => onMouseEnterRow?.(e, rowData, isDnd)}
        onMouseLeave={e => onMouseLeaveRow?.(e, rowData, isDnd)}
        onMouseDown={e => onMouseDown?.(e)}
        onMouseUp={e => onMouseUp?.(e)}
      >
        <ErrorBoundaries>
          {isSelectionEnabled && (
            <TableCell
              className={classes.tableCell}
              padding="checkbox"
              onClick={event => onSelectRowClick(event, rowData)}
              key={`selectCell-${rowKey}`}
            >
              <Checkbox
                checked={isRowSelected}
                key={`selectCheckbox-${rowKey}`}
                disabled={!!rowData?.selectionDisabled}
              />
            </TableCell>
          )}
          {displayTableCells(rowData)}
          {displayRowActionButton(rowData)}
          {rowDetailLayout && !rowCollapsible && (
            <TableCell
              align="right"
              padding="none"
              key={`rowDetailLayout-${rowKey}`}
              className={classes.tableCell}
            >
              {isRowExpanded ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
            </TableCell>
          )}
        </ErrorBoundaries>
      </TableRow>
      {displayExpandableCustomRow()}
      {rowCollapsible && isRowExpanded && addCollapsibleRows(rowData.collapsibleRows)}
    </>
  );
});
const DndTableRow = props => {
  const {
    dragEligible,
    dragItemName,
    dragOptions = {},
    dragPreviewImg,
    dragTransform,
    rowData
  } = props;
  const data = dragTransform ? dragTransform(rowData) : rowData;
  const canDrag = dragEligible ? dragEligible(data) : true;
  // eslint-disable-next-line no-unused-vars
  const [collected, drag, dragPreview] = useDrag({
    item: { type: dragItemName, data },
    ...dragOptions,
    canDrag: () => (dragEligible ? canDrag : true)
  });
  useEffect(() => {
    if (dragPreviewImg) {
      dragPreview(dragPreviewImg, { captureDraggingState: true });
    }
  }, []);
  return <CustomTableRowBase {...props} isDnd={canDrag} ref={drag} />;
};
const CustomTableRow = props => {
  if (props.dragItemName) return <DndTableRow {...props} />;
  return <CustomTableRowBase {...props} />;
};
const CustomTableCell = props => {
  const {
    rowData,
    columnMetadata,
    columnIndex,
    customCellComponents,
    rowIndex,
    caslKey,
    refreshTable,
    rowCollapsible,
    isRowExpanded,
    timezoneSensitiveColumnIds
  } = props;
  const DataComponent = getCellComponent(columnMetadata, customCellComponents);
  const align = getCellDataAlignment(columnMetadata, columnIndex);
  let flexAlign;
  switch (align) {
    case 'right': {
      flexAlign = 'flex-end';
      break;
    }
    case 'left': {
      flexAlign = 'flex-start';
      break;
    }
    case 'center': {
      flexAlign = align;
      break;
    }
    default: {
      break;
    }
  }
  return (
    columnMetadata.colSpan !== 0 && (
      <TableCell
        className={props.className}
        id={props.id}
        scope="row"
        padding={columnMetadata.disablePadding ? 'none' : null}
        align={align}
        colSpan={columnMetadata.colSpan}
        style={{
          display: columnMetadata.colSpan === 0 ? 'none' : null,
          ...columnMetadata.cellStyle,
          ...props.style
        }}
      >
        <UserPermission I="view" action={caslKey}>
          <ErrorBoundaries>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: flexAlign }}>
              {rowCollapsible &&
                columnMetadata.collapsible &&
                rowData.collapsibleRows &&
                (isRowExpanded ? (
                  <KeyboardArrowUpIcon style={{ fontSize: 16, marginRight: 4 }} />
                ) : (
                  <KeyboardArrowDownIcon style={{ fontSize: 16, marginRight: 4 }} />
                ))}
              <DataComponent
                rowIndex={rowIndex}
                meta={columnMetadata}
                record={rowData}
                refreshTable={refreshTable}
                timezoneSensitiveColumnIds={timezoneSensitiveColumnIds}
                companyTimezone={companyTimezone}
              />
            </div>
          </ErrorBoundaries>
        </UserPermission>
      </TableCell>
    )
  );
};

const useStylesRowButtons = makeStyles(theme => ({
  buttonHover: {
    '&:hover': {
      backgroundColor: theme.palette.grayscale(96)
    }
  }
}));
const RowButtons = props => {
  const { rowActionButtons, rowActions, rowData, onMenuClick, rowIndex } = props;
  const classes = useStylesRowButtons();
  const buttonKeys = Object.keys(rowActionButtons);
  // If only one button is passed, display that button inline
  if (buttonKeys.length === 1) {
    const firstButtonKey = buttonKeys[0];
    const singleRowButton = rowActionButtons[firstButtonKey];
    const isDisabled = singleRowButton.disable && singleRowButton.disable(rowData);
    return (
      <Tooltip title={singleRowButton.label}>
        <IconButton
          className={classes.buttonHover}
          onClick={() => rowActions(firstButtonKey, rowData, rowIndex)}
          disabled={isDisabled}
          testingid="tableRowAction"
        >
          <NamedIcon name={singleRowButton.icon} />
        </IconButton>
      </Tooltip>
    );
  }
  // Otherwise, list all buttons using the "More Menu" component
  return (
    <IconButton
      className={classes.buttonHover}
      onClick={event => onMenuClick(event, rowData)}
      testingid={`tableRowActions-${rowIndex}`}
    >
      <NamedIcon name="MoreVert" />
    </IconButton>
  );
};
const useStylesComponentRowWrapper = makeStyles(theme => ({
  componentWrapperRow: ({ backgroundColor }) => ({
    border: 'none',
    backgroundColor
  }),
  componentWrapperCell: {
    borderWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: theme.palette.grayscale(90),
    borderStyle: 'solid',
    overflow: 'hidden',
    padding: 0,
    textAlign: 'center'
  }
}));
// Wraps a row around individual components that need to span an entire row
const ComponentRowWrapper = props => {
  const { colSpan, children, backgroundColor } = props;
  const classes = useStylesComponentRowWrapper({ backgroundColor });
  return (
    <TableRow className={classes.componentWrapperRow}>
      <TableCell className={classes.componentWrapperCell} colSpan={colSpan || 1}>
        {children}
      </TableCell>
    </TableRow>
  );
};
