import gql from 'graphql-tag';

const addReviewReportPurchaseOrderLines = gql`
  mutation AddReviewReportPurchaseOrderLines(
    $partitionKey: String
    $data: AddReviewReportPurchaseOrderLinesInput!
  ) {
    addReviewReportPurchaseOrderLines(partitionKey: $partitionKey, data: $data) {
      id
      sortKey
      itemName
      vendorName
      description
      quantity
      unitCost
      unitPrice
      markup
      amount
      createdBy
      departmentName
      includeInInvoice
      taxable
      version
      parentId
      costCodeId
      jobCostTypeId
      revenueTypeId
    }
  }
`;

export default addReviewReportPurchaseOrderLines;
