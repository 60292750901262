import React from 'react';
import { PDFFormComponents } from '@buildhero/sergeant';
import _ from 'lodash';
import LineItemTable from '../LineItemTable';

class TaskSection extends React.Component {
  render() {
    const { options, displayValue } = this.props;
    const { columns, ...rest } = options;
    return (
      <>
        {displayValue.map(value => (
          <>
            <PDFFormComponents.BodyText
              displayValue={value.name || ''}
              options={{ ...rest, bold: true, fontSize: 16 }}
              key={`header${value.id}`}
            />
            <PDFFormComponents.BodyText
              displayValue={value.description || ''}
              options={rest}
              key={`desc${value.id}`}
            />
            {(!_.isEmpty((value.products || []).items) ||
              !_.isEmpty((value.quoteLineProducts || []).items)) && (
              <LineItemTable
                options={{ ...options, padding: [5, 0, 5, 0] }}
                field={{ value: value.products || [] }}
                displayValue={{ value: value.products || [] }}
                key={`table${value.id}`}
              />
            )}
            <PDFFormComponents.Table
              options={{
                showTotal: true,
                hideHeader: true,
                hideSeparators: true,
                columns: [
                  {
                    id: 'description',
                    label: 'description',
                    colSpan: 4
                  },
                  {
                    id: 'amount',
                    label: 'Amount',
                    format: 'currency',
                    type: 'currency',
                    align: 'right',
                    width: 80
                  },
                  {
                    id: 'spacer',
                    label: '',
                    width: 50
                  }
                ]
              }}
              field={{
                value: [
                  {
                    description: 'Subtotal:',
                    amount: value.subtotalValue || 0,
                    columnStyle: {
                      fontWeight: 'bold',
                      fontSize: PDFFormComponents.fontSize(9)
                    }
                  }
                ]
              }}
              key={`subtotal${value.id}`}
            />
          </>
        ))}
      </>
    );
  }
}

export default TaskSection;
