import gql from 'graphql-tag';
import useExtendedMutation from 'customHooks/useExtendedMutation';
import { watchedQueries } from '@dispatch/Dispatch.store';

const DELETE_MAN_DAY_MUTATION = gql`
  mutation deleteManDay($data: DeleteManDayInput!) {
    deleteManDay(data: $data) {
      id
      isActive
    }
  }
`;

const serializer = ({ id }) => ({
  variables: {
    data: {
      id
    }
  }
});

const useDeleteManDay = () => {
  return useExtendedMutation(DELETE_MAN_DAY_MUTATION, {
    serializer,
    update: (cache, { data }) => {
      const query = watchedQueries.useDispatchBoardManDayItems;
      if (!query) {
        return;
      }

      const prevItems = cache.readQuery(query)?.listManDay;
      if (prevItems) {
        const deletedManDayId = data?.deleteManDay?.id;
        const nextItems = prevItems.filter(({ id }) => id !== deletedManDayId);
        cache.writeQuery({
          ...query,
          data: {
            listManDay: nextItems
          }
        });
      }
    }
  });
};

export default useDeleteManDay;
