import { useTheme } from '@material-ui/core';
import { DispatchTheme } from '@dispatch/Dispatch.styles';

export const useStyles = () => {
  const theme = useTheme();
  return {
    container: {
      height: 'inherit'
    },
    header: {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      width: '100%',
      height: '48px',
      borderBottom: DispatchTheme.mixins.mainBorder,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    content: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      height: 'calc(100% - 48px)',
      overflow: 'auto',
    }
  };
};
