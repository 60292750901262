import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Box from '@material-ui/core/Box';
import Carousel from './Carousel';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
    fontWeight: 700,
    color: '#333333',
    letterSpacing: '-0.03em',
    marginBottom: 15
  },
  chunkedItemContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  imageCard: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center, center'
  },
  selectable: {
    cursor: 'pointer'
  },
  downloadable: {
    cursor: 'pointer'
  },
  selectedImage: {
    position: 'absolute',
    color: '#00874D',
    fontSize: 24,
    bottom: '5px',
    right: '5px',
    zIndex: 1
  },
  displayNone: {
    display: 'none'
  }
}));

const CustomCarousel = ({
  imageData,
  imageDataLength,
  imageWidth,
  imageHeight,
  margin,
  chunkSize,
  timeout,
  titleText,
  selectable,
  downloadable,
  onSelect
}) => {
  const classes = useStyles();
  const [attachmentLists, setAttachmentLists] = useState([]);

  const handleSelected = item => {
    const currentLists = [...attachmentLists];
    currentLists[item.id].selected = !item.selected;
    setAttachmentLists(currentLists);

    // eslint-disable-next-line no-shadow
    const selectedLists = currentLists.filter(item => item.selected === true);
    onSelect(selectedLists);
  };

  useEffect(() => {
    const newLists = imageData.map((image, index) => {
      return {
        ...image,
        id: index,
        selected: image.selected
      };
    });
    setAttachmentLists(newLists);
  }, [imageData, imageDataLength]);

  const handleClickImage = item => {
    if (downloadable) {
      const aLink = document.createElement('a');
      aLink.target = '_blank';
      // eslint-disable-next-line no-useless-escape
      aLink.download = item.name || item.url?.split(/[\\\/]/).pop();
      aLink.href = item.url;
      aLink.click();
    }

    if (selectable) {
      handleSelected(item);
    }
  };

  return (
    <>
      <Box className={classNames(classes.title, !titleText && classes.displayNone)}>
        {titleText} {`(${imageDataLength})`}
      </Box>
      <Carousel timeout={timeout}>
        {chunk(attachmentLists, chunkSize).map((chunkedItem, index) => {
          return (
            <Box
              className={classes.chunkedItemContainer}
              style={{
                width: 'auto',
                height: `${imageHeight}px`
              }}
              // eslint-disable-next-line react/no-array-index-key
              key={`chunked-images-${index}`}
            >
              {chunkedItem.map(item => (
                <Box
                  style={{ margin: `0px ${margin / 2}px`, width: imageWidth, height: imageHeight }}
                  key={item.id}
                >
                  <Box
                    className={classNames(
                      classes.imageCard,
                      selectable && classes.selectable,
                      downloadable && classes.downloadable
                    )}
                    style={{ backgroundImage: `url(${item.url})` }}
                    onClick={() => handleClickImage(item)}
                  >
                    {selectable && (
                      <CheckCircleIcon
                        className={classNames(
                          classes.selectedImage,
                          !item.selected && classes.displayNone
                        )}
                      />
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          );
        })}
      </Carousel>
    </>
  );
};

CustomCarousel.propTypes = {
  imageData: PropTypes.array.isRequired,
  imageDataLength: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
  imageHeight: PropTypes.number.isRequired,
  margin: PropTypes.number.isRequired,
  chunkSize: PropTypes.number.isRequired,
  timeout: PropTypes.number.isRequired,
  titleText: PropTypes.string,
  selectable: PropTypes.bool,
  downloadable: PropTypes.bool,
  onSelect: PropTypes.func
};
CustomCarousel.defaultProps = {
  titleText: '',
  selectable: false,
  downloadable: false,
  onSelect: () => {}
};

export default CustomCarousel;
