import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  img: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1.5),
    height: 18
  },
  imgContainer: {
    display: 'inline-block',
    width: 20,
    textAlign: 'center'
  },
  text: {
    paddingTop: '4px',
    display: 'inline-block',
    fontSize: 14
  },
  itemContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  divider: {
    width: '100%',
    marginBottom: 5,
    backgroundColor: '#808080'
  }
}));
