const { makeStyles } = require('@material-ui/core');

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0px 0px 0px 0px',
    // styles for the subject field
    '& .MuiFilledInput-root': {
      borderBottom: `1px solid ${theme.palette.grayscale(90)}`,
      padding: '8px 5px',
      borderRadius: 0
    }
  },
  emailContainer: {
    marginLeft: '60px',
    marginRight: '120px',
    padding: '0px 0px 0px 0px'
  },
  attachmentsContainer: {
    marginLeft: '40px',
    marginRight: '60px'
  },
  toContainer: {
    borderBottom: `1px solid ${theme.palette.grayscale(90)}`,
    padding: '0px 0px 10px 0px'
  },
  to: {
    display: 'inline-block',
    fontSize: '14px',
    color: `${theme.palette.grayscale(50)}`
  },
  toContents: {
    maxWidth: '1000px'
  },
  subjectContainer: {
    borderBottom: `1px solid ${theme.palette.grayscale(90)}`,
    padding: '10px 0px 10px 0px'
  },
  subject: {
    display: 'inline-block',
    fontSize: '14px',
    color: `${theme.palette.grayscale(50)}`
  },
  subjectContents: {
    width: '100%',
    maxWidth: '1000px',
    innerPadding: '0px 0px 0px 0px'
  },
  contents: {
    display: 'block',
    width: '100%',
    maxWidth: '100%'
  }
}));

export default useStyles;
