export const COMPANY_SETTING_TYPE = {
  PAYROLL: 'PAYROLL',
  PROJECT: 'PROJECT',
  AFTER_HOURS: 'AFTER_HOURS',
  CUSTOMER_SIGNATURE: 'CUSTOMER_SIGNATURE',
  QUOTE: 'QUOTE'
};

export const COST_TYPE = {
  REVENUE: 'REVENUE',
  JOB_COST: 'JOB_COST',
  BOTH: 'BOTH'
};

export const payrollSettingFilter = {
  type: { eq: COMPANY_SETTING_TYPE.PAYROLL }
};

export const projectSettingFilter = {
  type: { eq: COMPANY_SETTING_TYPE.PROJECT }
};

export const typeModalDefaultState = {
  open: false,
  mode: null,
  data: {},
  dataType: 'Type'
};

// backend enum
export const maintenanceFrequency = {
  Daily: 'Daily',
  TwiceAWeek: 'Twice A Week',
  Weekly: 'Weekly',
  BiWeekly: 'Bi-Weekly',
  Monthly: 'Monthly',
  AlternateMonths: 'Alternate Months',
  Quarterly: 'Quarterly',
  BiAnnually: 'Bi-Annually',
  Annually: 'Annually'
};

// what we want to show
export const maintenanceFrequencyLabel = {
  Daily: 'Daily',
  TwiceAWeek: 'Twice a Week',
  Weekly: 'Weekly',
  BiWeekly: 'Bi Weekly',
  Monthly: 'Monthly',
  AlternateMonths: 'Alternate Months',
  Quarterly: 'Quarterly',
  BiAnnually: 'Bi Annually',
  Annually: 'Annually'
};
