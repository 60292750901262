import React, { useMemo } from 'react';
import gql from 'graphql-tag';
import ErrorBoundaries from 'scenes/Error';
import {
  PermissionConstants,
  MultiSelectTypes,
  VISIT_REVIEW_STATUS,
  JOB_CLOSEOUT_STATUS,
  StatusValToLabelMapping,
  JobTypes,
  JobStatus,
  MaintenanceStatus
} from 'utils/AppConstants';
import { getGridStringOperators } from '@mui/x-data-grid-pro';
import { FieldType, formatValue } from '@buildhero/sergeant';

import { PageHeader, UserPermission, XGrid } from 'components';
import StatusChip from 'components/StatusChip';
import { connect } from 'react-redux';
import { snackbarOn, setOpenQuickAddModal } from 'redux/actions/globalActions';
import { EnumType } from 'utils/constants';
import {
  column,
  ColumnType,
  valueGetters,
  enumFilterOperators
} from 'components/XGrid/columnTypes';
import EnumFilterInput from 'components/XGrid/FilterInputs/EnumFilterInput';
import { useTrigger } from 'customHooks/useTrigger';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RelevantVisitStatuses } from 'scenes/JobCloseout/constants';
import { TechReport } from '../Jobs/DetailView/JobTabs/InvoiceAndReport';
import { featureFlagFilter } from '../Jobs/utils';

const GET_VISITS = gql`
  query getVisitsList(
    $tenantId: String
    $filter: TableFilterInput
    $pagination: PaginationInput
    $sorting: [TableSortingInput]
  ) {
    data: getVisitsList(
      tenantId: $tenantId
      filter: $filter
      pagination: $pagination
      sorting: $sorting
    ) {
      rowCount
      items
    }
  }
`;

const reviewStatusOptions = [
  { label: JOB_CLOSEOUT_STATUS.REVIEWED, value: VISIT_REVIEW_STATUS.REVIEWED },
  { label: JOB_CLOSEOUT_STATUS.REVIEW_NEEDED, value: VISIT_REVIEW_STATUS.UNREVIEWED }
];

const getReviewStatusColDef = selectOptions => {
  const filterOpertor = getGridStringOperators().find(o => o.value === 'equals');
  filterOpertor.InputComponent = EnumFilterInput;
  filterOpertor.InputComponentProps = { selectOptions };
  return { ...column[ColumnType.ENUM], filterOperators: [filterOpertor] };
};

/**
 * We can either massage the data here through valueGetter
 * or we can format the data before it goes into the table.
 */
const visitColumns = [
  {
    field: 'jobCustomIdentifier',
    headerName: 'Job',
    width: 100,
    valueGetter: valueGetters.jobOrMaintenanceLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'visitNumber',
    headerName: 'Visit',
    width: 100,
    type: 'string',
    renderCell: ({ id, value, colDef, api, getValue }) => {
      const isJobCloseoutReport = Boolean(getValue(id, 'jobCloseoutReport'));
      const isLinkableVisit = RelevantVisitStatuses.includes(getValue(id, 'status'));

      if (!isJobCloseoutReport || !isLinkableVisit) return `Visit ${value}`;
      const linkValue = {
        label: `Visit ${value}`,
        to: `/jobreport/${getValue(id, 'jobId')}/visit/${getValue(id, 'id')}`
      };

      return formatValue[FieldType.LINK](linkValue, {
        containerProps: {
          style: { flexWrap: 'unset', gap: '8px' }
        },
        linkProps: { testingid: `record-${colDef.field}-${api.getRowIndex()}` }
      });
    }
  },
  {
    field: 'departmentName',
    headerName: 'Department',
    width: 150,
    enumType: MultiSelectTypes.DEPARTMENTS,
    ...column[ColumnType.TAGS]
  },
  {
    field: 'customerName',
    headerName: 'Customer',
    width: 150,
    valueGetter: valueGetters.customerLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'propertyName',
    headerName: 'Property',
    width: 150,
    valueGetter: valueGetters.propertyLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'status',
    headerName: 'Visit Status',
    width: 150,
    enumType: EnumType.VISIT_STATUS,
    ...column[ColumnType.ENUM]
  },
  {
    ldFlag: FeatureFlags.JOB_CLOSEOUT,
    field: 'reviewStatus',
    headerName: 'Visit Review Status',
    width: 200,
    showIcon: true,
    enumType: EnumType.VISIT_REVIEW_STATUS,
    ...getReviewStatusColDef(reviewStatusOptions)
  },
  {
    field: 'jobCompletionStatus',
    headerName: 'Job Completion Status',
    width: 200,
    showIcon: true,
    type: 'string',
    filterOperators: enumFilterOperators.map(o => ({
      ...o,
      InputComponentProps: { selectOptions: Object.values({ ...JobStatus, ...MaintenanceStatus }) }
    })),
    renderCell: ({ value, id, colDef, getValue }) => {
      const isMaintenanceJob = getValue(id, 'jobTypeInternal') === JobTypes.MAINTENANCE;
      const type = isMaintenanceJob ? EnumType.MAINTENANCE_STATUS : EnumType.JOB_STATUS;
      return (
        value && (
          <StatusChip
            showIcon={colDef.showIcon}
            style={{ borderRadius: 2 }}
            enumType={type}
            enumValue={value}
            label={StatusValToLabelMapping(type, value) || value}
          />
        )
      );
    }
  },
  {
    ldFlag: FeatureFlags.JOB_PROCUREMENT_STATUS,
    field: 'computedJobProcurementStatus',
    headerName: 'Job Procurement Status',
    width: 200,
    enumType: EnumType.JOB_PROCUREMENT_STATUS,
    showIcon: true,
    noLabelFormat: true,
    ...column[ColumnType.ENUM]
  },
  {
    ldFlag: FeatureFlags.JOB_CLOSEOUT,
    field: 'jobBillingStatus',
    headerName: 'Job Billing Status',
    width: 200,
    enumType: EnumType.JOB_BILLING_STATUS,
    showIcon: true,
    ...column[ColumnType.ENUM]
  },
  {
    field: 'primaryTech',
    headerName: 'Primary Technician',
    width: 200,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'jobTypeName',
    headerName: 'Job Type',
    width: 150,
    enumType: MultiSelectTypes.JOB_TYPES,
    ...column[ColumnType.TAG]
  },
  {
    field: 'jobTags',
    headerName: 'Tags',
    width: 100,
    enumType: MultiSelectTypes.JOB_TAGS,
    ...column[ColumnType.TAGS]
  },
  {
    field: 'createdDate',
    headerName: 'Created On',
    width: 150,
    ...column[ColumnType.DATE]
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 150,
    type: 'string'
  },
  {
    field: 'jobCompletedDate',
    headerName: 'Job Completion Date',
    width: 200,
    ...column[ColumnType.DATE]
  },
  {
    field: 'reviewedBy',
    headerName: 'Reviewed By',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'reviewedUnixDate',
    headerName: 'Reviewed on',
    width: 150,
    ...column[ColumnType.DATE]
  },
  {
    field: 'projectManagerName',
    headerName: 'Project Manager',
    width: 175,
    type: 'string'
  },
  {
    field: 'techReport',
    headerName: 'Report',
    width: 175,
    renderCell: ({ value }) => (
      <TechReport record={{ techReport: value, technicianReport: 'Download' }} />
    )
  }
];

const JobsList = ({ user }) => {
  const flags = useFlags();

  const columns = useMemo(() => featureFlagFilter(flags)(visitColumns), [flags]);

  const [subscribe, trigger] = useTrigger();

  return (
    <ErrorBoundaries>
      <UserPermission I="read" action={PermissionConstants.OBJECT_JOB}>
        <PageHeader pageMapKey="visits" userLocale={user.locale} />
        <XGrid
          refetchTrigger={subscribe}
          columns={columns}
          entityTypeName="Visit"
          tableName="VisitsXGrid"
          query={GET_VISITS}
        />
      </UserPermission>
    </ErrorBoundaries>
  );
};

const mapStateToProps = state => ({
  user: state.user
});
const mapDispatchToProps = { snackbar: snackbarOn, addModal: setOpenQuickAddModal };

const connectedJobs = connect(mapStateToProps, mapDispatchToProps)(JobsList);

export default connectedJobs;
