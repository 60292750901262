export const truckListMeta = [
  {
    id: 'name',
    filterKey: 'Truck.name',
    filterType: 'string',
    numeric: false,
    label: 'Truck name',
    showLink: 'view',
    linkPath: '/truck',
    linkReference: 'id'
  },
  {
    id: 'employeeName',
    filterKey: 'Employee.name',
    filterType: 'string',
    disableFilter: true,
    numeric: false,
    maxTextLen: 60,
    label: 'Technicians'
  },
  {
    id: 'productBundleName',
    filterKey: 'ProductBundle.name',
    filterType: 'string',
    numeric: false,
    label: 'Inventory bundles'
  }
];

export const inventoryListMeta = [
  {
    id: 'productName',
    filterKey: 'Product.name',
    filterType: 'string',
    numeric: false,
    label: 'Item name'
  },
  {
    id: 'productCode',
    filterKey: 'Product.code',
    filterType: 'string',
    numeric: false,
    label: 'Product Code'
  },
  {
    id: 'productDescription',
    filterKey: 'Product.description',
    filterType: 'string',
    disableFilter: true,
    numeric: false,
    label: 'Item description'
  },
  {
    id: 'unitOfMeasureValue',
    filterKey: 'TruckInventory.unitOfMeasure',
    filterType: 'string',
    numeric: false,
    label: 'UOM'
  },
  {
    id: 'quantityOnHand',
    filterKey: 'TruckInventory.quantityOnHand',
    filterType: 'number',
    numeric: true,
    label: 'Current QOH'
  },
  {
    id: 'minQuantity',
    filterKey: 'TruckInventory.minQuantity',
    filterType: 'number',
    numeric: true,
    label: 'Min Qty'
  },
  {
    id: 'maxQuantity',
    filterKey: 'TruckInventory.maxQuantity',
    filterType: 'number',
    numeric: true,
    label: 'Max Qty'
  }
];

export default truckListMeta;
