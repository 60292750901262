import gql from 'graphql-tag';

const getFormsAvailableToEntity = gql`
  query getFormsAvailableToEntity($partitionKey: String!, $sortKey: String!, $formType: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      companyName
      email
      fax
      phonePrimary
      websiteUrl
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      version
      forms(entityConnection: "Form", filter: { associatedEntityType: { eq: $formType } }) {
        items {
          id
          name
          description
          viewType
          sortKey
          latestPublishedFormDefinitionSortKey
          latestPublishedFormDefinition(entityConnection: "latestPublishedFormDefinition") {
            id
            createdBy
            formDefinitionJson
            lastUpdatedDateTime
          }
          associatedEntityType
          hierarchy
          tenantCompanyId
          version
        }
      }
    }
  }
`;

export default getFormsAvailableToEntity;
