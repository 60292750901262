import gql from 'graphql-tag';

const getTimesheetsByJobNumber = gql`
  query getTimesheetsByJobNumber($jobNumber: String!) {
    getJobByJobNumber(jobNumber: $jobNumber) {
      visits(entityConnection: "Visit") {
        items {
          id
          visitNumber
          timeCardLinesView(entityConnection: "TimeCardLine") {
            items {
              id
              timeMinutes
              timeTypeAbbreviation
              timeType
              employee(entityConnection: "Employee") {
                id
                name
                payrollCosts(entityConnection: "PayrollCost") {
                  items {
                    id
                    cost
                    hourType(entityConnection: "HourType") {
                      id
                      hourType
                    }
                  }
                }
                labourType(entityConnection: "LabourType") {
                  id
                  name
                }
              }
            }
          }
          timesheetEntriesView(
            entityConnection: "TimesheetEntry"
            filter: { stringFilters: { fieldName: "userActionType", filterInput: { ne: "Shift" } } }
          ) {
            items {
              id
              entryType
              userActionType
              actualTotalDuration
              actualTotalDurationOverride
              hourType(entityConnection: "PayrollHourType") {
                id
                hourType
              }
              timekeepingLedger(entityConnection: "TimekeepingLedger") {
                id
                employee(entityConnection: "Employee") {
                  id
                  name
                  payrollCosts(entityConnection: "PayrollCost") {
                    items {
                      id
                      cost
                      hourType(entityConnection: "HourType") {
                        id
                        hourType
                      }
                    }
                  }
                  labourType(entityConnection: "LabourType") {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getTimesheetsByJobNumber;
