// The ultimate goal is to compute this value:
//
// select (
//   (select sum(paymentAmount) from Payment where billingCustomerId = $1)
//   -
//   (select sum(totalAmount) from Invoice where billingCustomerId = $1)
// );
//
// We use aggregatedField here to get the two halves of the difference, and then
// finish up in CustomerService

import gql from 'graphql-tag';

export const fragment = `
  invoiceTotal: aggregatedField(
    input: {
      entityConnection: "Invoice"
      relationshipType: LookupChild
      aggregations: [
        { aggregationType: SUM, aggregationField: "Invoice.totalAmount" }
      ]
      filter: {

        # Not necessary right now, because the bug in BUOP-3024 (see below)
        # doesn't affect this association, but it could if someone defines
        # a second association between Customer and Invoice, so I include
        # this defensively. Remove as soon as BUOP-3024 is fixed.
        filterRelatedEntityData: false

        integerFilters: [
          # Exclude deleted records
          { fieldName: "Invoice.deletedDate", filterInput: { is: null } }
        ],
        stringFilters: [
          { fieldName: "Invoice.status", filterInput: { ne: "void" } }
        ]
      }
      # No way to make an unbounded query
      pagination: { limit: 1000000000 }
    }
  ) {
    items {
      total: aggregation1
    }
  }

  paymentTotal: aggregatedField(
    input: {
      entityConnection: "Payment"
      relationshipType: LookupChild
      aggregations: [
        { aggregationType: SUM, aggregationField: "Payment.paymentAmount" }
      ]
      filter: {

        # This flag will prevent the query builder from making needless
        # joins which also, because of a bug -
        # https://buildops.atlassian.net/browse/BUOP-3024 -
        # causes this aggregation to return an incorrect result
        filterRelatedEntityData: false

        integerFilters: [
          { fieldName: "Payment.deletedDate", filterInput: { is: null } }
        ]
      }
      pagination: { limit: 1000000000 }
    }
  ) {
    items {
      total: aggregation1
    }
  }
`;

const getARBalanceData = gql`
  query getARBalanceData($partitionKey: String!, $sortKey: String!) {
    getCustomer(partitionKey: $partitionKey, sortKey: $sortKey) {
      ${fragment}    
    }
  }
`;

export default getARBalanceData;
