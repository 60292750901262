import React from 'react';

import { Select, ThemeProvider, Typography } from '@buildhero/sergeant';
import { Divider } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

const PayrollHourRatesAndTypes = ({
  payrollRegHourTypeSelect,
  payrollHourTypeOptions,
  payrollOTHourTypeSelect,
  handlePayrollExportSettingsSelect,
  styles
}) => {
  const flags = useFlags();
  return (
    <>
      {flags[FeatureFlags.ADP_EXPORT] && (
        <ThemeProvider>
          <Typography style={{ margin: '20px 0' }}>Payroll Export Settings</Typography>
          <Select
            label="Payroll Reg Hour Type"
            style={{ width: 222, marginBottom: 20 }}
            placeholder="Select payroll hour type"
            defaultValue={payrollRegHourTypeSelect}
            options={payrollHourTypeOptions.filter(
              o => o.value.id !== payrollOTHourTypeSelect?.value?.id
            )}
            onChange={option => handlePayrollExportSettingsSelect('REG', option)}
          />
          <Select
            label="Payroll O/T Hour Type"
            style={{ width: 222 }}
            placeholder="Select payroll hour type"
            defaultValue={payrollOTHourTypeSelect}
            options={payrollHourTypeOptions.filter(
              o => o.value.id !== payrollRegHourTypeSelect?.value?.id
            )}
            onChange={option => handlePayrollExportSettingsSelect('OT', option)}
          />
        </ThemeProvider>
      )}
      <Divider fullwidth css={styles.formEndDivider} />
    </>
  );
};

export default PayrollHourRatesAndTypes;
