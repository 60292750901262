import { makeStyles } from '@material-ui/core';

import { ElementSizes } from '@dispatch/Dispatch.styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    height: ElementSizes.headerContentHeight
  },
  rightContent: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  leftContent: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    '& h5': {
      marginLeft: theme.spacing(1)
    }
  },
  icon: {
    color: theme.palette.brand.logoGreen
  },
  pageTitle: {
    fontWeight: 'bold'
  }
}));
