import gql from 'graphql-tag';

const saveInventoryParts = gql`
  mutation saveInventoryParts(
    $inventoryParts: [InventoryPartInput]!
    $partitionKey: String!
    $visitId: String!
  ) {
    addInventoryPartsToVisit(
      partitionKey: $partitionKey
      data: { visitId: $visitId, inventoryParts: $inventoryParts }
    ) {
      id
    }
  }
`;

export default saveInventoryParts;
