import React, { Component } from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import { validations, CustomerService } from 'services/core';
import { Logger } from 'services/Logger';
import ErrorBoundaries from 'scenes/Error';
import { customerRepRows, tenantRepRows } from 'meta/Customer';
import { CustomerRepLayout } from 'meta/Customer/CustomerRep/layout';
import { AddRecordButton, ConfirmModal, ResponsiveTable } from 'components';
import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import { BestContactType } from 'utils/constants';
import CustomerRep, { RepType } from '../../CustomerRepModal';
import TenantRep from '../../TenantRepModal';

/**
 * Creates customer page. new customer fields & layouts are generated from the meta file
 * labels are fetched from application level
 * locale of the user is referred from user context
 */
class Reps extends Component {
  constructor(props) {
    super(props);
    this.CustomerService = new CustomerService();
    this.state = {
      openCustomerRep: false,
      openTenantRep: false,
      dataRecord: {},
      modalMode: '',
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: ''
    };
  }

  handleOpenPopUp = (popUpKey, mode, record) => {
    this.setState({ [popUpKey]: true, modalMode: mode, dataRecord: record });
  };

  handleClosePopUp = popUpKey => {
    this.setState({ [popUpKey]: false });
  };

  handleCustomerRepRowActions = (mode, record) => {
    if (mode === 'delete') {
      this.setState({
        confirmDialog: true,
        confirmAction: async () => this.deleteCustomerRep(record),
        confirmMessage: 'customer representative'
      });
    } else {
      this.handleOpenPopUp('openCustomerRep', mode, record);
    }
  };

  deleteCustomerRep = async record => {
    try {
      const { data } = await this.CustomerService.deleteCustomerRep(record);
      if (data) {
        await this.props.refetchCustomerReps(this.props.customerData.sortKey);
        this.props.snackbarOn('success', `Successfully deleted our representative: ${record.name}`);
        this.setState({
          confirmDialog: false,
          confirmAction: '',
          confirmMessage: ''
        });
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn(
        'error',
        'Unable to delete our representative, please try again later',
        error
      );
    }
  };

  deleteTenantRep = async record => {
    const { partitionKey, sortKey, invertedSortKey } = record;
    try {
      const { data } = await this.CustomerService.deleteTenantRep(
        partitionKey,
        sortKey,
        invertedSortKey
      );
      if (data) {
        await this.props.refetchTenantReps(this.props.customerData.sortKey);
        const employeeName = record.name || `${record.firstName} ${record.lastName}`;
        this.props.snackbarOn(
          'success',
          `Successfully deleted our representative: ${employeeName}`
        );
        this.setState({
          confirmDialog: false,
          confirmAction: '',
          confirmMessage: ''
        });
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn(
        'error',
        'Unable to delete our representative, please try again later',
        error
      );
    }
  };

  handleTenantRepRowActions = (mode, record) => {
    if (mode === 'delete') {
      // Logger.debug('TODO: update employee mutation');
      this.setState({
        confirmDialog: true,
        confirmAction: async () => this.deleteTenantRep(record),
        confirmMessage: 'our representative'
      });
    } else {
      this.handleOpenPopUp('openTenantRep', mode, record);
    }
  };

  handleCancelConfirmation = () => {
    this.setState({
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: ''
    });
  };

  render() {
    const { customerReps, tenantReps, customerData, isActive } = this.props;

    return (
      <ErrorBoundaries>
        <Grid item style={{ paddingBottom: 22 }}>
          <Typography variant="body2" style={{ color: '#3f3f3f' }}>
            {Labels['customer representative'][this.props.user.locale]}
          </Typography>
        </Grid>

        <Grid item>
          {isActive && (
            <AddRecordButton
              label="Add customer representative"
              icon={AddIcon}
              handle={() =>
                this.handleOpenPopUp('openCustomerRep', 'new', {
                  bestContact: BestContactType.CELLPHONE
                })
              }
            />
          )}
          <ResponsiveTable
            rowMetadata={customerRepRows}
            data={customerReps?.items}
            rowActionButtons={{
              view: {
                label: 'View',
                caslAction: 'read',
                icon: 'Launch'
              },
              edit: {
                label: 'Edit',
                caslAction: 'update',
                icon: 'Edit'
              },
              delete: {
                label: 'Delete',
                caslAction: 'delete',
                icon: 'Delete'
              }
            }}
            rowActions={this.handleCustomerRepRowActions}
            noDataMsg="No customer reps"
            refreshTable={() => this.props.refetchCustomerReps(customerData.sortKey)}
          />
        </Grid>

        <Grid item style={{ paddingTop: 40, paddingBottom: 22 }}>
          <Typography variant="body2" style={{ color: '#3f3f3f' }}>
            {Labels['representative on our side'][this.props.user.locale]}
          </Typography>
        </Grid>
        <Grid item>
          {isActive && (
            <AddRecordButton
              label="Add representative on our side"
              icon={AddIcon}
              handle={() => this.handleOpenPopUp('openTenantRep', 'new', {})}
            />
          )}
          <ResponsiveTable
            rowMetadata={tenantRepRows}
            data={tenantReps}
            rowActionButtons={{
              edit: {
                label: 'Edit',
                caslAction: 'update',
                icon: 'Edit'
              },
              delete: {
                label: 'Delete',
                caslAction: 'delete',
                icon: 'Delete'
              }
            }}
            rowActions={this.handleTenantRepRowActions}
            noDataMsg="No reps on our side "
            refreshTable={() => this.props.refetchTenantReps(customerData.sortKey)}
          />
        </Grid>

        {this.state.openCustomerRep && (
          <CustomerRep
            open={this.state.openCustomerRep}
            mode={this.state.modalMode}
            data={this.state.dataRecord}
            validationSchema={validations.customerRepSchema}
            parent={customerData}
            layout={CustomerRepLayout}
            repType={RepType.CUSTOMER}
            recordSortKey={this.props.recordSortKey}
            handleClose={flag => {
              this.handleClosePopUp('openCustomerRep');
              if (flag) {
                // for refreshing the page
                this.props.refetchCustomerReps(customerData.sortKey);
              }
            }}
          />
        )}

        {this.state.openTenantRep && (
          <TenantRep
            open={this.state.openTenantRep}
            mode={this.state.modalMode}
            data={this.state.dataRecord}
            parent={customerData}
            recordSortKey={this.props.recordSortKey}
            handleClose={flag => {
              this.handleClosePopUp('openTenantRep');
              if (flag) {
                // sending true false
                this.props.refetchTenantReps(customerData.sortKey);
              }
            }}
            mounted={this.mounted}
          />
        )}

        <ConfirmModal
          open={this.state.confirmDialog}
          confirm={this.state.confirmAction}
          cancel={this.handleCancelConfirmation}
          message={this.state.confirmMessage}
        />
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const connectedReps = connect(mapStateToProps, mapDispatcherToProps)(Reps);
export default connectedReps;
