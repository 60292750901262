import * as Actions from '../constants';

export const setProjectDetails = projectData => ({
  type: Actions.SET_PROJECT_DETAILS,
  payload: projectData
});

export const setProjectKpis = kpiData => ({
  type: Actions.SET_PROJECT_KPIS,
  payload: kpiData
});

export const refetchProjectDetails = () => ({
  type: Actions.REFETCH_PROJECT_DETAILS
});

export const setTimezone = (timezone, loading) => ({
  type: Actions.SET_COMPANY_TIMEZONE,
  payload: {
    timezone,
    loading
  }
});
