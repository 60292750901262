import gql from 'graphql-tag';

const getPropertyInfoByJobNumber = gql`
  query getPropertyInfoByJobNumber($jobNumber: String!) {
    getJobByJobNumber(jobNumber: $jobNumber) {
      id
      jobNumber
      customIdentifier
      parentEntity {
        ... on CustomerProperty {
          id
          propertyAssets(entityConnection: "PropertyAsset") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              assetName
              imageUrl
              make
              version
              modelNumber
              isActive
              serialNo
              installDate
            }
          }
        }
      }
    }
  }
`;

export default getPropertyInfoByJobNumber;
