import { bundleIndex } from 'constants/algoliaIndex';

export const PartsAndMaterialsLayout = (defaultPriceBookId, addItem, handleItemChange) => ({
  fields: {
    partsAndMaterials: {
      default: 'partsAndMaterials'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          options: {
            direction: 'column'
          },
          contents: [
            {
              component: {
                default: 'List'
              },
              options: {
                showAddButton: true,
                allowEdit: true,
                onChange: handleItemChange
              }
            },
            {
              component: {
                default: 'ItemSearch',
                edit: null
              },
              source: 'productName',
              options: {
                addItem,
                label: '',
                alignment: 'left',
                placeholder: 'Search to add items',
                searchIndexName: bundleIndex,
                searchResultDisplayFields: ['name', 'code'],
                delimiter: ' - ',
                setItemName: 0,
                filters: [
                  {
                    attribute: 'entityType',
                    valueArray: ['Product']
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  }
});
