const layout = () => ({
  fields: {},
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },

      contents: [
        {
          options: {
            direction: 'column'
          },
          contents: [
            {
              component: {
                default: 'Description'
              }
            }
          ]
        }
      ]
    }
  }
});

export default layout;
