export const useStyles = () => ({
  item: theme => ({
    width: 80,
    marginRight: theme.default.spacing(2)
  }),
  label: {
    fontSize: 10,
    fontWeight: 'normal',
    textTransform: 'uppercase'
  }
});
