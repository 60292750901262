const styles = theme => ({
  backIcon: {
    fontSize: 18
  },
  button: {
    margin: 2,
    padding: 2
  },
  searchText: {
    color: theme.palette.grayscale(60),
    fontSize: 16
  },
  entityTypeText: {
    color: theme.palette.brand.logoBlue,
    fontWeight: 'bold',
    fontSize: 16
  },
  customerName: {
    color: theme.palette.brand.logoBlue,
    fontWeight: 'normal',
    fontFamily: theme.typography.fontFamily,
    fontSize: 15
  },
  detailTop: {
    marginBottom: 10,
    borderBottom: `1px solid ${theme.palette.grayscale(85)}`,
    paddingBottom: 10
  },
  chipTop: {
    marginTop: 5
  },
  highlightText: {
    fontSize: 30,
    fontWeight: 'bold'
  },
  highlightPanel: {
    backgroundColor: '#f2f3f9',
    padding: 20,
    paddingBottom: 15
  },
  otherPanel: {
    padding: 20
  }
});
export default styles;
