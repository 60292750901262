import React, { useState } from 'react';
import { connect } from 'react-redux';
import ErrorBoundaries from 'scenes/Error';
import { Grid, Box } from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { Button, Divider, ThemeProvider, Typography, TV, TW, MUIForm } from '@buildhero/sergeant';
import { PageHeader, Tabs, Tab, withLoading } from 'components';
import { sum } from 'lodash';
import Labels from 'meta/labels';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import CKEditor from 'components/CKEditor';
import { mockData } from 'components/CKEditor/mockData';
import PropTypes from 'prop-types';
import { Mode } from 'utils/constants';
import { quoteSettingLayout } from 'meta/Settings/QuoteSettings/QuoteSettingsMeta';
import { PermissionConstants } from 'utils/AppConstants';
import { logErrorWithCallback, checkPermission, isJSONParseableObjectOrArray } from 'utils';
import { snackbarOn } from 'redux/actions/globalActions';
import { useMutation } from '@apollo/client';
import {
  CreateCompanyQuoteSettings,
  UpdateCompanyQuoteSettings,
  SaveCompanyQuoteTemplate
} from './gql';
import { useQuoteTemplateQuery, useQuoteSettingsQuery } from '../../Quotes/service';
import useStyles from './styles';
import { DefaultQuotedWorkLineItem } from './DefaultQuotedWorkLineItem';

const QuoteSettingsTabs = {
  TEMPLATE: 'TEMPLATE',
  PREFERENCES: 'PREFERENCES'
};

const tabIndexMap = {
  0: QuoteSettingsTabs.TEMPLATE,
  1: QuoteSettingsTabs.PREFERENCES
};

const loadingParams = {
  variant: 'table',
  repeatCount: 5,
  paddingTop: 12,
  paddingLeft: 8,
  paddingRight: 8
};

const MuiFormWithLoading = withLoading(MUIForm);
const CKEditorWithLoading = withLoading(CKEditor);

const defaultSettings = {
  enableLockScopeAndPricingData: false,
  approvalSignatureRequired: false,
  defaultQuotedWorkLineItems: []
};

const Quote = ({ user, ...props }) => {
  const flags = useFlags();
  const { tenantId, tenantCompanyId } = user;
  const [selectedTab, setSelectedTab] = useState(tabIndexMap[0]);
  const [quoteTemplateStr, setQuoteTemplateStr] = useState();
  const [muiService, setMuiService] = useState();
  const [isTotalPercentageError, setIsTotalPercentageError] = useState(false);

  const classes = useStyles();

  const isAllowedToEdit = checkPermission(Mode.EDIT, PermissionConstants.OBJECT_QUOTES, user);

  const {
    data: quoteTemplateData,
    loading: loadingTemplate,
    refetch: refetchQuoteTemplateData
  } = useQuoteTemplateQuery(tenantId, props.snackbar);
  const {
    data: quoteSettingData,
    loading: loadingSettings,
    refetch: refetchQuoteSettingsData
  } = useQuoteSettingsQuery(tenantId, tenantCompanyId, props.snackbar);
  const [saveTemplate, { loading: saveTemplateLoading }] = useMutation(SaveCompanyQuoteTemplate);
  const [createSettings, { loading: createSettingsLoading }] = useMutation(
    CreateCompanyQuoteSettings
  );
  const [updateSettings, { loading: updateSettingsLoading }] = useMutation(
    UpdateCompanyQuoteSettings
  );

  const saveQuoteTemplate = async () => {
    const templatePayload = {
      id: quoteTemplateData?.id,
      version: quoteTemplateData?.version,
      template: quoteTemplateStr
    };
    try {
      const { data } = await saveTemplate({
        variables: {
          tenantId,
          items: [templatePayload]
        }
      });
      const { template } = data?.saveQuoteTemplate[0] || {};
      refetchQuoteTemplateData();
      setQuoteTemplateStr(template);
      props.snackbarOn('success', 'Successfully saved company quote template');
    } catch (err) {
      logErrorWithCallback(
        err,
        props.snackbarOn,
        err.message ? err.message : 'Failed to save company quote template'
      );
    }
  };

  const pageHeaderButtons = {
    [QuoteSettingsTabs.TEMPLATE]: [
      <ThemeProvider>
        <Button
          type="primary"
          onClick={() => saveQuoteTemplate()}
          disabled={loadingTemplate || saveTemplateLoading}
        >
          Save Changes
        </Button>
      </ThemeProvider>
    ],
    [QuoteSettingsTabs.PREFERENCES]: [
      <ThemeProvider>
        <Button
          type="primary"
          onClick={() => muiService?.submit()}
          disabled={loadingSettings || createSettingsLoading || updateSettingsLoading}
        >
          Save Changes
        </Button>
      </ThemeProvider>
    ]
  };

  const validateSettings = data => {
    const { defaultQuotedWorkLineItems } = data;
    if (!defaultQuotedWorkLineItems?.length) return true;
    const percentages = defaultQuotedWorkLineItems.map(({ percentage }) => Number(percentage));
    const percentageError = sum(percentages) !== 100;
    return !percentageError;
  };

  const saveQuoteSettings = async formData => {
    if (!validateSettings(formData) && flags[FeatureFlags.JOB_CLOSEOUT]) {
      setIsTotalPercentageError(true);
      props.snackbarOn(
        'error',
        `Unable to save changes because total percentage allocated isn’t 100%. 
        Please ensure that you’ve entered all values correctly before saving.`
      );
      return;
    }

    try {
      if (!quoteSettingData) {
        await createSettings({
          variables: {
            partitionKey: tenantId,
            companyId: tenantCompanyId,
            settings: JSON.stringify(formData)
          }
        });
        refetchQuoteSettingsData();
        props.snackbarOn('success', 'Successfully created and saved company quote settings');
      } else {
        await updateSettings({
          variables: {
            ...quoteSettingData,
            partitionKey: tenantId,
            settings: JSON.stringify(formData)
          }
        });
        props.snackbarOn('success', 'Successfully saved company quote settings');
      }
    } catch (err) {
      logErrorWithCallback(
        err,
        props.snackbarOn,
        err.message ? err.message : 'Failed to save company quote settings'
      );
    }
  };

  const formQuoteSettingData =
    quoteSettingData && isJSONParseableObjectOrArray(quoteSettingData.settings)
      ? JSON.parse(quoteSettingData.settings)
      : defaultSettings;

  const formEditLayout = quoteSettingData ? Mode.EDIT : Mode.ADD;

  const formQuoteTemplate = quoteTemplateStr || quoteTemplateData?.template || mockData;

  return (
    <ErrorBoundaries>
      <Grid container direction="column">
        <PageHeader
          title={Labels.quoteSettings[user.locale]}
          breadcrumbsArray={[{ link: '', title: Labels.settings[user.locale] }]}
          iconComponent={<SettingsOutlinedIcon className={classes.settingIcon} />}
          overrideHeaderButtons={isAllowedToEdit && pageHeaderButtons[selectedTab]}
        />
        <Divider />
        <ErrorBoundaries>
          <Tabs disableBottomPadding onChange={(_, value) => setSelectedTab(tabIndexMap[value])}>
            <Tab label="Template" tabKey="template">
              <Grid>
                <Box style={{ padding: '24px 0' }}>
                  <Typography variant={TV.S1} weight={TW.BOLD}>
                    Default Quote Format
                  </Typography>
                  <Typography variant={TV.S1} weight={TW.REGULAR} style={{ width: '50%' }}>
                    By default, all quotes are formatted like the one below. If changes are made to
                    the quote format, they will only apply to quotes created after the updates are
                    saved. Changes will not impact already-created quotes.
                  </Typography>
                </Box>
                <CKEditorWithLoading
                  initialData={formQuoteTemplate}
                  updateDataFn={setQuoteTemplateStr}
                  loadingParams={loadingParams}
                  isLoading={saveTemplateLoading || loadingTemplate}
                />
              </Grid>
            </Tab>
            <Tab label="Preferences" tabKey="preferences">
              <Divider />
              <MuiFormWithLoading
                configuration={quoteSettingLayout({
                  isTotalPercentageError,
                  enableJobCloseoutSetting: flags[FeatureFlags.JOB_CLOSEOUT]
                })}
                data={formQuoteSettingData}
                loadingParams={loadingParams}
                layout={isAllowedToEdit ? formEditLayout : Mode.VIEW}
                onCreateService={service => setMuiService(service)}
                onComplete={saveQuoteSettings}
                isLoading={createSettingsLoading || updateSettingsLoading || loadingSettings}
                customComponents={{ DefaultQuotedWorkLineItem }}
              />
            </Tab>
          </Tabs>
        </ErrorBoundaries>
      </Grid>
    </ErrorBoundaries>
  );
};

Quote.propTypes = {
  user: PropTypes.object,
  snackbarOn: PropTypes.func.isRequired
};

Quote.defaultProps = {
  user: {}
};

const mapDispatcherToProps = { snackbarOn };

const mapStateToProps = state => ({
  user: state.user
});

const reduxConnectedProject = connect(mapStateToProps, mapDispatcherToProps)(Quote);

export default reduxConnectedProject;
