import React from 'react';
import { Grid, Link } from '@material-ui/core';
import { jsx } from '@emotion/react';
import { useTheme } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import { TV, TW, Typography, ThemeProvider } from '@buildhero/sergeant';
import configForEnvironment from 'configs/aws-exports';
import ENV from 'configs/env';

export const WrongDomainPage = () => {
  const { ENTERPRISE_DOMAIN, NON_ENTERPRISE_DOMAIN } = configForEnvironment(ENV);
  const targetDomain =
    window.location.host === NON_ENTERPRISE_DOMAIN ? ENTERPRISE_DOMAIN : NON_ENTERPRISE_DOMAIN;
  const theme = useTheme();
  const classes = {
    banner: {
      height: 164,
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      top: 0,
      left: 0
    },
    content: {
      position: 'absolute',
      top: 240,
      left: 0,
      padding: theme.spacing(1),
      textAlign: 'center'
    }
  };

  const redirectToDomain = async event => {
    event.preventDefault();
    await Auth.signOut();
    window.location = `https://${targetDomain}`;
  };

  return (
    <ThemeProvider>
      <Grid
        container
        spacing={0}
        direction="column"
        justify="center"
        alignItems="center"
        alignContent="center"
        css={classes.banner}
      >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={12}>
            <img
              src="/static/images/Logo_Lockup_White.svg"
              alt="BuildOps"
              style={{ maxWidth: 250 }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column" justify="center" alignItems="center" css={classes.content}>
        <Typography variant={TV.BASE} weight={TW.REGULAR}>
          {`You are accessing BuildOps in the wrong domain. Please click `}
          <Link href={`https://${targetDomain}`} onClick={redirectToDomain}>
            here
          </Link>
        </Typography>
      </Grid>
    </ThemeProvider>
  );
};
