import React from 'react';

import { bool, oneOf } from 'prop-types';

import WrapTable from 'components/WrapTable';
import { TablePlaceholderVariant } from 'components/WrapTable/WrapTable.constants';
import { getTenantSettingValueForKey } from 'utils';

import { TaskTableLayout } from './TasksTable.constants';
import { useTaskTableColumns } from './TasksTable.hooks';

const TasksTable = ({ tasks, loading, error, noDataMessage, taskTableLayout }) => {
  const isAssetEnabled = getTenantSettingValueForKey('assetTrackingAgainstTask') === 'true';
  const taskTableColumns = useTaskTableColumns({ isAssetEnabled, taskTableLayout });

  return (
    <WrapTable
      placeholderVariant={TablePlaceholderVariant.TEXT}
      columns={taskTableColumns}
      rows={tasks}
      loading={loading}
      error={error}
      noDataMessage={noDataMessage}
      loadingRows={3}
    />
  );
};

TasksTable.propTypes = {
  taskTableLayout: oneOf(Object.values(TaskTableLayout)),
  loading: bool
};

TasksTable.defaultProps = {
  taskTableLayout: TaskTableLayout.JOB_TASK,
  loading: false
};

export default TasksTable;
