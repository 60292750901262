import React, { memo } from 'react';
import { string } from 'prop-types';
import { Typography, TV } from '@buildhero/sergeant';

import { TablePlaceholderVariant } from '../WrapTable.constants';

import { BorderBox, TextContainer } from './TablePlaceholder.styled';

const TablePlaceholder = ({ color, message, variant }) => {
  if (variant === TablePlaceholderVariant.BOX) {
    return (
      <BorderBox color={color}>
        <Typography variant={TV.XL}>{message}</Typography>
      </BorderBox>
    );
  }

  if (variant === TablePlaceholderVariant.TEXT) {
    return (
      <TextContainer color={color}>
        <Typography variant={TV.S1}>{message}</Typography>
      </TextContainer>
    );
  }
};

TablePlaceholder.propTypes = {
  // eslint-disable-next-line react/require-default-props
  color: string,
  message: string.isRequired
};

export default memo(TablePlaceholder);
