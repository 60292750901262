import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';

const margin = '0px 8px 4px 8px';

const itemListFields = isVistaEnabled => ({
  itemName: { required: true },
  jcPhase: { required: Boolean(isVistaEnabled) },
  jcCostType: { required: Boolean(isVistaEnabled) },
  description: { required: false },
  costCodeDescription: { required: false },
  unitOfMeasure: { required: false },
  unitCost: { required: false },
  quantity: { required: false },
  totalCost: { required: false },
  moreActions: { required: false }
});

const itemListContents = ({ showLabel, associatedProject, isVistaEnabled }) => {
  return [
    // the 1st section title
    {
      options: {
        size: 1,
        direction: 'row',
        grow: 1
      },
      contents: [
        {
          options: {
            margin
          },
          contents: [
            {
              component: {
                default: 'MuiFormSectionTitle'
              },
              options: {
                label: showLabel ? 'Item List' : ''
              }
            }
          ]
        }
      ]
    },

    // the 1st row
    {
      options: {
        direction: 'row',
        grow: 1,
        flexWrap: 'wrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: '0 0 136px',
            maxWidth: 136,
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'itemName',
              options: {
                label: showLabel ? 'Item' : '',
                fullWidth: true,
                alignment: 'left'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: '0 0 180px',
            maxWidth: 180,
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'description',
              options: {
                label: showLabel ? 'Description' : '',
                fullWidth: true,
                alignment: 'left'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: '0 0 60px',
            maxWidth: 60,
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'costCodeDescription',
              options: {
                label: showLabel ? 'Cost Code' : '',
                fullWidth: true,
                alignment: 'left'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: '0 0 44px',
            maxWidth: 44,
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'unitOfMeasure',
              options: {
                label: showLabel ? 'UOM' : '',
                fullWidth: true,
                alignment: 'left'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: '0 0 77px',
            maxWidth: 77,
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'unitCost',
              options: {
                label: showLabel ? 'Unit Cost' : '',
                fullWidth: true,
                alignment: 'left',
                type: 'currency',
                decimalPlaces: 5
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: '0 0 50px',
            maxWidth: 50,
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'quantity',
              options: {
                label: showLabel ? 'QTY' : '',
                fullWidth: true,
                alignment: 'left'
              }
            }
          ]
        },
        ...(isVistaEnabled && !associatedProject?.id
          ? [
              {
                options: {
                  direction: 'column',
                  flex: '0 0 75px',
                  maxWidth: 75,
                  margin
                },
                contents: [
                  {
                    component: {
                      default: 'CustomFieldWithLabelNarrow'
                    },
                    source: 'jcPhase',
                    options: {
                      label: showLabel ? 'Job Phase' : '',
                      fullWidth: true,
                      alignment: 'left'
                    }
                  }
                ]
              },
              {
                options: {
                  direction: 'column',
                  flex: '0 0 75px',
                  maxWidth: 75,
                  margin
                },
                contents: [
                  {
                    component: {
                      default: 'CustomFieldWithLabelNarrow'
                    },
                    source: 'jcCostType',
                    options: {
                      label: showLabel ? 'Cost Type' : '',
                      fullWidth: true,
                      alignment: 'left'
                    }
                  }
                ]
              }
            ]
          : []),
        {
          options: {
            direction: 'column',
            flex: '0 0 77px',
            maxWidth: 77,
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow'
              },
              source: 'totalCost',
              options: {
                label: showLabel ? 'Total Cost' : '',
                fullWidth: true,
                alignment: 'left',
                type: 'currency'
              }
            }
          ]
        }
      ]
    }
  ];
};

const itemListLayout = itemOptions => {
  return {
    fields: generateDefaultFieldsObject(itemListFields(itemOptions.isVistaEnabled)),
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: itemListContents(itemOptions)
      }
    }
  };
};

export { itemListFields, itemListLayout };
