import { useState, useEffect } from 'react';
import AmplifyService from 'services/AmplifyService';
import { Logger } from 'services/Logger';
import { CREWS } from '../../queries';

const useCrews = user => {
  const [crews, setCrews] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const { client } = AmplifyService.appSyncClient();
        const crewsQueryResult = await client.query({ query: CREWS });
        const result = crewsQueryResult?.data?.crews?.items || [];
        setCrews(result);
      } catch (error) {
        Logger.warn(`Error fetching crews for user id ${user?.id}: ${error.message}`);
      }
    })();
  }, [user]);
  return crews;
};

export default useCrews;
