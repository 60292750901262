import gql from 'graphql-tag';

const getCustomerRepsForPropertyById = gql`
  query getCustomerPropertyInfoById($id: String!) {
    getCustomerPropertyById(id: $id) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      parentId
      parentSortKey
      parentEntity {
        ... on Customer {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          customerName
          customerReps(entityConnection: "CustomerRep") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              name
              firstName
              lastName
              email
              contactRole
              cellPhone
              landlinePhone
              isSmsOptOut
              bestContact
              version
              status
            }
          }
        }
      }
      customerReps(entityConnection: "CustomerRep") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          invertedSortKey
          hierarchy
          entityType
          mappedEntity {
            ... on CustomerRep {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              name
              firstName
              lastName
              email
              cellPhone
              landlinePhone
              isSmsOptOut
              bestContact
              contactRole
              emailInvoice
              version
              notes(entityConnection: "Note") {
                items {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  subject
                  note
                  createdBy
                  lastUpdatedDate
                  lastUpdatedDateTime
                  lastUpdatedBy
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default getCustomerRepsForPropertyById;
