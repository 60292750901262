import {
  calculateMargin,
  calculateMarginFromMarkup,
  calculateMarkup,
  calculateMarkupFromMargin,
  calculateMaterialMargin,
  calculateMaterialMarkup,
  calculateTotalMarkup,
  calculateUnitPriceWithMargin,
  calculateUnitPriceWithMarkup,
  roundCurrency,
  roundFive
} from '@buildhero/math';

import { getState } from 'redux/store';
import { convertForMathLib } from 'utils/mathLibrary';

import { PricingStrategy } from './constants';

// MARKUP CALCULATIONS
export const getUnitPrice = (unitCost, markupValue = 0) =>
  convertForMathLib(roundCurrency, unitCost * (markupValue / 100 + 1));

export const getMarkupValue = (unitCost, unitPrice) =>
  convertForMathLib(roundFive, (unitPrice / unitCost - 1) * 100);

export const getMarkupFromMargin = margin =>
  convertForMathLib(roundFive, (margin / (100 - margin)) * 100);

// MARGIN CALCULATIONS
export const getMarginFromMarkup = markup =>
  convertForMathLib(roundFive, (markup / (100 + markup)) * 100);

export const onItemCalcChange = ({ form, field, currentValue }) => {
  const { unitCost, unitPrice, markupValue, marginValue, quantity, amount } = {
    ...form.values,
    [field.name]: currentValue
  };
  const { pricingStrategy } = getState().settings;
  const showMarkupAndMargin = pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN;
  const showMargin = pricingStrategy === PricingStrategy.MARGIN || showMarkupAndMargin;

  const showMarkup = pricingStrategy === PricingStrategy.MARKUP || showMarkupAndMargin;

  switch (field.name) {
    case 'unitPrice': {
      if (showMarkup)
        form.setFieldValue('markupValue', convertForMathLib(calculateMarkup, unitCost, unitPrice));
      if (showMargin)
        form.setFieldValue('marginValue', convertForMathLib(calculateMargin, unitCost, unitPrice));
      form.setFieldValue('amount', convertForMathLib(roundCurrency, quantity * unitPrice));
      break;
    }
    case 'unitCost': {
      let newUnitPrice;
      if (showMarkup)
        newUnitPrice = convertForMathLib(calculateUnitPriceWithMarkup, unitCost, markupValue);
      if (showMargin)
        newUnitPrice = convertForMathLib(calculateUnitPriceWithMargin, unitCost, marginValue);

      form.setFieldValue('unitPrice', newUnitPrice);
      form.setFieldValue('amount', convertForMathLib(roundCurrency, quantity * newUnitPrice));
      break;
    }
    case 'markupValue': {
      const newUnitPrice = convertForMathLib(calculateUnitPriceWithMarkup, unitCost, markupValue);
      if (showMargin)
        form.setFieldValue(
          'marginValue',
          convertForMathLib(calculateMarginFromMarkup, markupValue)
        );
      form.setFieldValue('unitPrice', newUnitPrice);
      form.setFieldValue('amount', convertForMathLib(roundCurrency, quantity * newUnitPrice));
      break;
    }
    case 'marginValue': {
      const newUnitPrice = convertForMathLib(calculateUnitPriceWithMargin, unitCost, marginValue);
      if (showMarkup)
        form.setFieldValue(
          'markupValue',
          convertForMathLib(calculateMarkupFromMargin, marginValue)
        );
      form.setFieldValue('unitPrice', newUnitPrice);
      form.setFieldValue('amount', convertForMathLib(roundCurrency, quantity * newUnitPrice));
      break;
    }
    case 'quantity': {
      form.setFieldValue('amount', convertForMathLib(roundCurrency, quantity * unitPrice));
      break;
    }
    case 'amount': {
      // amount is only editable in discount/fee modal
      form.setFieldValue('unitPrice', convertForMathLib(roundCurrency, amount));
      break;
    }
    default:
      break;
  }
};

// wrapper for SgtForm since its input's onChange gives (key, value, form)
export const sgtItemCalc = (key, value, form) =>
  onItemCalcChange({ form, field: { name: key, value }, currentValue: value });

export const onPricebookCalcChange = ({ form, field, currentValue }) => {
  const {
    unitCost,
    unitPrice,
    markupValue,
    materialMarkup,
    materialMargin,
    marginValue,
    pricebookMarkup,
    pricebookMargin
  } = {
    ...form.values,
    [field.name]: currentValue
  };

  switch (field.name) {
    case 'unitPrice': {
      const newMarkupValue = convertForMathLib(calculateMarkup, unitCost, unitPrice);
      const newMarginValue = convertForMathLib(calculateMargin, unitCost, unitPrice);

      form.setFieldValue(
        'materialMarkup',
        convertForMathLib(calculateMaterialMarkup, pricebookMarkup, newMarkupValue)
      );
      form.setFieldValue('markupValue', newMarkupValue);
      form.setFieldValue(
        'materialMargin',
        convertForMathLib(calculateMaterialMargin, pricebookMargin, newMarginValue)
      );
      form.setFieldValue('marginValue', newMarginValue);
      break;
    }
    case 'materialMarkup': {
      const totalMarkup = convertForMathLib(calculateTotalMarkup, pricebookMarkup, materialMarkup);
      const newUnitPrice = convertForMathLib(calculateUnitPriceWithMarkup, unitCost, totalMarkup);

      const newTotalMargin = convertForMathLib(calculateMarginFromMarkup, totalMarkup);

      form.setFieldValue('markupValue', totalMarkup);
      form.setFieldValue(
        'materialMargin',
        convertForMathLib(calculateMarginFromMarkup, materialMarkup)
      );
      form.setFieldValue('marginValue', newTotalMargin);
      form.setFieldValue('unitPrice', newUnitPrice);
      break;
    }
    case 'markupValue': {
      const newUnitPrice = convertForMathLib(calculateUnitPriceWithMarkup, unitCost, markupValue);
      const newMarginValue = convertForMathLib(calculateMarginFromMarkup, markupValue);
      const newMaterialMarkup = convertForMathLib(
        calculateMaterialMarkup,
        pricebookMarkup,
        markupValue
      );
      form.setFieldValue('materialMarkup', newMaterialMarkup);
      form.setFieldValue(
        'materialMargin',
        convertForMathLib(calculateMarginFromMarkup, newMaterialMarkup)
      );
      form.setFieldValue('marginValue', newMarginValue);
      form.setFieldValue('unitPrice', newUnitPrice);
      break;
    }
    case 'materialMargin': {
      const newMaterialMarkup = convertForMathLib(calculateMarkupFromMargin, materialMargin);
      const totalMarkup = convertForMathLib(
        calculateTotalMarkup,
        pricebookMarkup,
        newMaterialMarkup
      );

      const newMargin = convertForMathLib(calculateMarginFromMarkup, totalMarkup);
      const newUnitPrice = convertForMathLib(calculateUnitPriceWithMargin, unitCost, newMargin);

      form.setFieldValue('materialMarkup', newMaterialMarkup);

      form.setFieldValue('markupValue', totalMarkup);
      form.setFieldValue('marginValue', newMargin);
      form.setFieldValue('unitPrice', newUnitPrice);
      break;
    }
    case 'marginValue': {
      const newUnitPrice = convertForMathLib(calculateUnitPriceWithMargin, unitCost, marginValue);
      const newMarkupValue = convertForMathLib(calculateMarkupFromMargin, marginValue);
      const newMaterialMarkup = convertForMathLib(
        calculateMaterialMarkup,
        pricebookMarkup,
        newMarkupValue
      );
      form.setFieldValue('materialMarkup', newMaterialMarkup);
      form.setFieldValue('markupValue', newMarkupValue);
      form.setFieldValue(
        'materialMargin',
        convertForMathLib(calculateMarginFromMarkup, newMaterialMarkup)
      );
      form.setFieldValue('unitPrice', newUnitPrice);
      break;
    }
    default:
      break;
  }
};

// wrapper for SgtForm since its input's onChange gives (key, value, form)
export const sgtPricebookItemCalc = (key, value, form) =>
  onPricebookCalcChange({ form, field: { name: key, value }, currentValue: value });
