import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles(() => ({
  root: {
    width: 219,
    height: 20,
    borderRadius: 10,
    backgroundColor: '#F0F0F0'
  },
  bar: {
    borderRadius: 10,
    backgroundColor: '#2DCE89'
  }
}))(LinearProgress);

const useStyles = makeStyles({
  root: {
    padding: 0,
    marginRight: 6
  }
});

export default function CustomLinearProgress(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BorderLinearProgress variant="determinate" value={props?.value || 0} />
    </div>
  );
}
