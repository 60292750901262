import React, { Component } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ContactIcon from '@material-ui/icons/Person';
import StoreIcon from '@material-ui/icons/Store';
import EventIcon from '@material-ui/icons/Event';
import ChatIcon from '@material-ui/icons/ChatBubbleOutline';
import HistoryIcon from '@material-ui/icons/History';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

class CustomerMenu extends Component {
  state = {
    selected: ''
  };

  render() {
    const { selected } = this.state;
    const { classes } = this.props;
    return (
      <MenuList>
        <MenuItem
          selected={selected === 'contact'}
          onClick={() => this.setState({ selected: 'contact' })}
          classes={{ root: classes.menuItem, selected: classes.selected }}
        >
          <ListItemIcon className={selected === 'contact' ? classes.iconColor : null}>
            <ContactIcon />
          </ListItemIcon>
          Contact
        </MenuItem>
        <MenuItem
          selected={selected === 'property'}
          onClick={() => this.setState({ selected: 'property' })}
          classes={{ root: classes.menuItem, selected: classes.selected }}
        >
          <ListItemIcon>
            <StoreIcon />
          </ListItemIcon>
          Properties
        </MenuItem>
        <MenuItem
          selected={selected === 'job'}
          onClick={() => this.setState({ selected: 'job' })}
          classes={{ root: classes.menuItem, selected: classes.selected }}
        >
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          Jobs
        </MenuItem>
        <MenuItem
          selected={selected === 'note'}
          onClick={() => this.setState({ selected: 'note' })}
          classes={{ root: classes.menuItem, selected: classes.selected }}
        >
          <ListItemIcon>
            <ChatIcon />
          </ListItemIcon>
          Notes
        </MenuItem>
        <MenuItem
          selected={selected === 'activity'}
          onClick={() => this.setState({ selected: 'activity' })}
          classes={{ root: classes.menuItem, selected: classes.selected }}
        >
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          Activity
        </MenuItem>
      </MenuList>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CustomerMenu);
