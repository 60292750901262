import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ServiceAgreements(props) {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SvgIcon {...props}>
      <path d="M16.3671 1C16.6225 1 16.8681 1.09767 17.0538 1.27299L17.357 1.559L7.32353 11.6931L6 16L10.4118 14.8119L20.548 4.574L20.6866 4.70403C20.8866 4.89294 21 5.15592 21 5.43105V22C21 22.5523 20.5523 23 20 23H4C3.44772 23 3 22.5523 3 22V2C3 1.44772 3.44772 1 4 1H16.3671Z" />
    </SvgIcon>
  );
}
