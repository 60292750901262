import React, { useCallback } from 'react';
import { any, array, bool, func } from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography } from '@buildhero/sergeant';
import { useStyles } from './DropdownCell.styles';

const DropdownCell = ({ value, options, onChange, loading, disabled }) => {
  const styles = useStyles();
  const optionValue = options.find(option => option.value === value);
  const handleChange = useCallback(
    (event, option) => {
      onChange(option.value);
    },
    [onChange]
  );

  if (loading && (!options.length || !optionValue)) {
    return <Typography>-</Typography>;
  }

  return (
    <Autocomplete
      classes={{ root: styles.root }}
      getOptionLabel={option => option.label}
      renderInput={params => <TextField {...params} variant="outlined" />}
      options={options}
      value={{ ...optionValue }}
      onChange={handleChange}
      disableClearable
      disabled={disabled}
    />
  );
};

DropdownCell.propTypes = {
  value: any.isRequired,
  options: array.isRequired,
  onChange: func.isRequired,
  loading: bool.isRequired,
  disabled: bool.isRequired
};

export default DropdownCell;
