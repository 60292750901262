import gql from 'graphql-tag';

const getAllAttachmentsByJob = gql`
  query getAllAttachmentsByJob($partitionKey: String!, $sortKey: String!) {
    getJob(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      tenantId
      hierarchy
      entityType
      jobNumber
      visits: visitsView(entityConnection: "Visit") {
        items {
          visitNumber
          id
          reviewReports(entityConnection: "ReviewReport") {
            items {
              id
              parentId
            }
          }
        }
      }
      customIdentifier
      allAttachments(entityConnection: "Attachment") {
        items {
          fileName
          parentId
          customFileName
          fileUrl
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          type
          entityType
          description
          comment
          createdDate
          createdDateTime
          createdBy
          addedBy
        }
      }
    }
  }
`;

export default getAllAttachmentsByJob;
