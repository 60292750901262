import React, { Component } from 'react';

class Note extends Component {
  state = {};

  render() {
    return <div>Note</div>;
  }
}

export default Note;
