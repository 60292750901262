import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemIcon, ListItemText, List } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { checkPermission } from 'utils';
import IconMap from 'meta/IconMap';
import MenuItem from '../MenuItem';
import styles from '../styles';

const propTypes = {
  subSection: PropTypes.shape({
    title: PropTypes.string,
    menus: PropTypes.array,
    icon: PropTypes.string
  }).isRequired,
  classes: PropTypes.shape({}),
  tenantSettingProcessed: PropTypes.shape({}),
  isSmallScreen: PropTypes.bool,
  isPopover: PropTypes.bool,
  user: PropTypes.shape({})
};

const defaultProps = {
  classes: {},
  tenantSettingProcessed: {},
  isSmallScreen: false,
  isPopover: false,
  user: {}
};

const MenuSubSection = ({
  subSection,
  classes,
  tenantSettingProcessed,
  isSmallScreen,
  isPopover,
  user
}) => {
  const { title, menus, icon } = subSection;

  const menusToDisplay = menus.filter(({ menu }) =>
    checkPermission(menu.caslAction, menu.caslKey, user, menu.scope, menu.featureGate)
  );

  const isMulitpleSubMenus = menusToDisplay.length > 1;

  return (
    <>
      {isMulitpleSubMenus && (
        <ListItem
          classes={{
            root: isPopover ? classes.menuPopoverItem : classes.menuItem,
            selected: isPopover ? null : classes.menuSelected
          }}
        >
          {icon && (
            <ListItemIcon classes={{ root: classes.listIcons }}>
              <IconMap icon={icon} style={{ fontSize: 16 }} />
            </ListItemIcon>
          )}
          <ListItemText primary={title} classes={{ root: classes.listTexts }} disableTypography />
        </ListItem>
      )}
      <List
        component="div"
        disablePadding
        className={isMulitpleSubMenus ? classes.nestedMenuItems : null}
      >
        {menusToDisplay.map(item => {
          return (
            <MenuItem
              showIcons={!isMulitpleSubMenus}
              data={item.menu}
              isPopover
              nested="true"
              key={`${item.menu.title || item.menu.pageMapKey}_${item.menu.url}`}
              tenantSettingProcessed={tenantSettingProcessed}
              isSmallScreen={isSmallScreen}
            />
          );
        })}
      </List>
    </>
  );
};

MenuSubSection.propTypes = propTypes;
MenuSubSection.defaultProps = defaultProps;

export default withStyles(styles)(MenuSubSection);
