import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const AddRecordButton = React.forwardRef((props, ref) => {
  const Icon = props.icon;
  const { handle, classes, icon, caslKey, theme, ...rest } = props;
  return (
    <Button onClick={handle} {...rest} ref={ref}>
      {Icon && <Icon className={props.classes.linkIcon} />}
      {props.label}
    </Button>
  );
});

export default withStyles(styles, { withTheme: true })(AddRecordButton);
