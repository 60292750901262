import gql from 'graphql-tag';

const getNotes = gql`
  query getJobNotes($partitionKey: String!, $sortKey: String!) {
    getJob(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      hierarchy
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      notes(entityConnection: "Note") {
        items {
          id
          hierarchy
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          subject
          note
          createdBy
          lastUpdatedDate
          lastUpdatedDateTime
          lastUpdatedBy
          version
        }
      }
      parentEntity {
        ... on CustomerProperty {
          id
          hierarchy
          entityType
          partitionKey
          sortKey
          hierarchy
          notes(entityConnection: "Note") {
            items {
              id
              hierarchy
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              subject
              note
              createdBy
              lastUpdatedDate
              lastUpdatedDateTime
              lastUpdatedBy
            }
          }
        }
      }
    }
  }
`;

export default getNotes;
