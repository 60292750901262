const styles = () => ({
  inputLabel: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'normal',
    letterSpacing: '0.01px',
    lineHeight: '14px',
    marginTop: 17
  },
  inputField: {
    width: 64,
    fontSize: 12
  },
  formControlTS: {
    minWidth: 250,
    height: 36
  },
  timeIcon: {
    fontSize: '24px',
    float: 'right',
    marginRight: '10px',
    marginTop: '-15px'
  },
  nextButton: {
    marginLeft: 60,
    marginTop: 10
  }
});

export default styles;
