import { mappingRequired } from '../../../common/schemas/baseEntity';

const offSchedulesSchema = [
  'offDate',
  'startTime',
  'endTime',
  'offReason',
  'parentId',
  'hierarchy',
  'id',
  'entityType',
  'version',
  'tenantId',
  'tenantCompanyId',
  'partitionKey',
  'parentSortKey',
  'sortKey',
  'createdBy',
  'createdDate',
  'createdDateTime',
  'deletedBy',
  'deletedDate',
  'deletedDateTime',
  'lastUpdatedBy',
  'lastUpdatedDate',
  'lastUpdatedDateTime'
];

const offSchedulesSchemaMappings = [
  {
    key: 'parentEntity',
    type: 'parent',
    mapType: '1M',
    required: mappingRequired
  }
];

export default offSchedulesSchema;
export { offSchedulesSchemaMappings };
