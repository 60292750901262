import React from 'react';

import { useSelector } from 'react-redux';

import Spinner from 'components/Spinners/CircularIndeterminate';
import { useSnackbar } from 'customHooks/useSnackbar';

import TimeTrackingReport from '../Payroll/TimeTrackingReport';

import BinderTimesheets from './BinderTimesheets';
import { bindersEnabled, getPayrollSettings } from './services';

import Timesheets from './index';

const TimesheetsDecider = ({ computedMatch, ...props }) => {
  const user = useSelector(s => s.user);
  const snackbar = useSnackbar();

  const [payrollSettings, setPayrollSettings] = React.useState();
  React.useEffect(() => {
    (async () => {
      const settings = await getPayrollSettings({ user, snackbarOn: props.snackbarOn });
      setPayrollSettings(settings);
    })();
  }, [user, snackbar]);
  if (!payrollSettings) return <Spinner size={34} />;
  if (payrollSettings.enableAutomatedTimeTracking) return <TimeTrackingReport />;

  if (bindersEnabled()) {
    return (
      <BinderTimesheets
        payrollSettings={payrollSettings}
        dateUnix={computedMatch?.params?.dateUnix}
        employeeId={computedMatch?.params?.employeeId}
        snackbarOn={snackbar}
      />
    );
  }

  return (
    <Timesheets
      payrollSettings={payrollSettings}
      dateUnix={computedMatch?.params?.dateUnix}
      employeeId={computedMatch?.params?.employeeId}
      snackbarOn={snackbar}
    />
  );
};

export default TimesheetsDecider;
