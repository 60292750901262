import React from 'react';
import { Typography } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';

const AsyncTypography = ({ loading, width, height, ...props }) => (
  <Typography component="span" display="block" {...props}>
    {loading ? <Skeleton width={width} height={height} /> : props.children}
  </Typography>
);

export default AsyncTypography;
