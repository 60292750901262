const styles = theme => ({
  gridCustomStyle: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 24
  }
});

export default styles;
