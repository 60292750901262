const styles = theme => ({
  linkStyles: {
    color: 'inherit',
    textDecoration: 'none'
  },
  linkText: {
    fontWeight: 'normal',
    paddingTop: 15.5
  },
  linkIcon: {
    fontSize: 12
  },
  buttonOutlinedSecondary: {
    border: 'none',
    textTransform: 'unset',
    paddingTop: 15.5,
    paddingLeft: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.3,
    '&:hover': {
      border: 'none'
    },
    '&:disabled': {
      border: 'none'
    }
  }
});

export default styles;
