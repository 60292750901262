import React, { useCallback, useEffect, useRef, useState } from 'react';

import { calculateMarginFromMarkup, calculateMarkupFromMargin } from '@buildhero/math';
import { Button, ButtonType, Modal, SgtForm, ThemeProvider } from '@buildhero/sergeant';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { connect, useSelector } from 'react-redux';

import { PageHeader, Spinner, Tab, Tabs, UserPermission } from 'components';
import { getLaborRowsAndCols } from 'components/LabourRateSection/LabourRateHelpers';
import LabourRate from 'components/LabourRateSection/LabourRates';
import { getCompany } from 'components/LabourRateSection/queries';
import ResponsiveTable from 'components/ResponsiveTable';
import { pricebookEntryColumns } from 'meta/Items/columns';
import { pricebookEntryLayout } from 'meta/Items/layout';
import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import EditPricebookModal from 'scenes/Pricebooks/EditPricebook';
import {
  isFlatMaterialMarkupRange,
  isMultipleMaterialMarkupRange,
  materialRangesFromJSON
} from 'scenes/Pricebooks/helpers';
import AmplifyService from 'services/AmplifyService';

import { getPriceBookById } from 'services/API/priceBook';
import {
  getPriceBookEntryOverridesByPriceBookId,
  upsertPriceBookEntryOverride
} from 'services/API/priceBookEntryOverride';
import { checkPermission, getTenantSettingValueForKey, logErrorWithCallback } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';
import { AccountingApp, Mode, PricingStrategy } from 'utils/constants';
import { convertForMathLib } from 'utils/mathLibrary';
import { pb2OverrideArrayToMap } from 'utils/pricebooks';

import { FeatureFlags } from '../../../../utils/FeatureFlagConstants';
import Pricebook2Details from '../Pricebook2Detail/Pricebook2DetailGrid';

import styles from '../styles';

import {
  addLabourRate,
  getPricebookById,
  getPricebookEntries,
  updateLabourRate,
  updatePricebookEntry
} from './gql';

function PricebookDetail({ user, computedMatch, snackbarOn: snackbar, settings, flags }) {
  const { client } = AmplifyService.appSyncClient();

  const pricebookVersion = useSelector(s => s.company.pricebookVersion);

  const [pricebook, setPricebook] = useState();
  const [pricebook2EntryOverridesMap, setPricebook2EntryOverridesMap] = useState(new Map());
  const [itemModalOpen, setItemModalOpen] = useState(false);
  const [itemModalMode, setItemModalMode] = useState(Mode.EDIT);
  const [itemModalData, setItemModalData] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [refreshData, setRefreshData] = useState(0);
  const [laborItems, setLaborItems] = useState([]);
  const [laborMetaData, setLaborMetaData] = useState([]);
  const [isQuickBooksEnabled, setIsQuickBooksEnabled] = useState();
  const formServiceRef = useRef();

  const getLaborItems = async pb => {
    const { data } = await client.query({
      query: getCompany,
      variables: {
        partitionKey: user.tenantId,
        sortKey: `${user.tenantId}_Company_${user.tenantCompanyId}`
      }
    });
    const [rows, cols] = getLaborRowsAndCols(data, pb.pricebookLabourEntries?.items);
    setLaborItems(rows);
    setLaborMetaData(cols);
  };

  const getPricebookV1 = async id => {
    try {
      const { data } = await client.query({
        query: getPricebookById,
        variables: { id }
      });
      const pb = data?.getPricebookById || {};
      pb.id = id;
      setPricebook(pb);
      await getLaborItems(pb);
    } catch (error) {
      logErrorWithCallback(
        error,
        snackbar,
        'Unable to fetch pricebook information, please try again later'
      );
    }
  };

  const getPricebookV2 = async id => {
    try {
      const { data } = await getPriceBookById(id);
      // Adapt equivalent pricebook 2 fields to pricebook 1.
      // TODO: Clean this up when pricebook1 goes away.
      setPricebook({
        ...data,
        markupValue: data.baseMaterialMarkup,
        materialMarkupJSON: data.rangedMaterialMarkupJSON // Somehow already parsed
      });

      const overrides = await getPriceBookEntryOverridesByPriceBookId(id);
      setPricebook2EntryOverridesMap(pb2OverrideArrayToMap(overrides.data));

      // TODO: labour items
      // await getLaborItems(pb2);
    } catch (error) {
      logErrorWithCallback(
        error,
        snackbarOn,
        'Unable to add new pricebook, please try again later'
      );
    }
  };

  const getPricebook = async () => {
    const isQuickBooks = getTenantSettingValueForKey('accountingApp') === AccountingApp.QUICKBOOKS;
    setIsQuickBooksEnabled(isQuickBooks);

    const { id } = computedMatch.params;

    if (pricebookVersion >= 2) {
      await getPricebookV2(id);
    } else {
      await getPricebookV1(id);
    }
  };

  const getEntriesV1 = async (filter, limit, offset, sortField, sortDirection) => {
    if (!user.tenantId) {
      return { items: [], nextToken: null };
    }
    try {
      const { data } = await client.query({
        query: getPricebookEntries,
        variables: {
          id: pricebook.id,
          filter,
          limit,
          offset,
          sort: (sortField && sortDirection && { sortField, sortDirection }) || undefined
        }
      });

      const { items, nextToken } = data.getPricebookById?.priceBookEntriesView;
      return { items, nextToken };
    } catch (error) {
      logErrorWithCallback(
        error,
        snackbar,
        'Unable to fetch pricebook entries, please try again later'
      );
      return { items: [], nextToken: null };
    }
  };

  const getEntries = async (filter, limit, offset, sortField, sortDirection) => {
    if (pricebookVersion >= 2) {
      return { items: [], nextToken: null };
    }

    return getEntriesV1(filter, limit, offset, sortField, sortDirection);
  };

  const refreshEntries = () => {
    // trigger table refresh
    setRefreshData(refreshData + 1);
  };

  useEffect(() => {
    const getPricebookHelper = async () => {
      await getPricebook();
    };

    getPricebookHelper();
  }, []);

  const isMarginEnabled =
    settings.pricingStrategy === PricingStrategy.MARGIN ||
    settings.pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN;

  const isMarkupEnabled =
    settings.pricingStrategy === PricingStrategy.MARKUP ||
    settings.pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN;

  const handleItemModalOpen = (mode, data) => {
    setItemModalOpen(true);
    setItemModalMode(mode);

    const marginData = isMarginEnabled && {
      pricebookMargin: convertForMathLib(calculateMarginFromMarkup, pricebook.markupValue),
      materialMargin: convertForMathLib(calculateMarginFromMarkup, data.materialMarkup),
      marginValue: convertForMathLib(calculateMarginFromMarkup, data.markupValue)
    };

    const markupData = isMarkupEnabled && {
      pricebookMarkup: pricebook.markupValue,
      markupValue: data.markupValue,
      materialMarkup: data.materialMarkup
    };

    setItemModalData({
      ...data,
      ...markupData,
      ...marginData,
      unitCost: data.product.unitCost
    });
  };

  const handleItemModalOpen2 = pseudoEntry => {
    setItemModalOpen(true);
    setItemModalMode(Mode.EDIT); // This needs rework, but keep for now
    setItemModalData({
      ...pseudoEntry,
      product: {
        name: pseudoEntry.name,
        code: pseudoEntry.code,
        taxable: pseudoEntry.taxable,
        description: pseudoEntry.description
      },
      costCode: {
        name: pseudoEntry.costCodeName
      },
      jobCostType: {
        name: pseudoEntry.jobTypeName
      },
      revenueType: {
        name: pseudoEntry.revenueTypeName
      },
      pricebookMarkup: isMarginEnabled
        ? convertForMathLib(calculateMarginFromMarkup, pseudoEntry.pricebookMarkup)
        : pseudoEntry.pricebookMarkup,
      materialMarkup: isMarginEnabled
        ? convertForMathLib(calculateMarginFromMarkup, pseudoEntry.materialMarkup)
        : pseudoEntry.materialMarkup,
      markupValue: isMarginEnabled
        ? convertForMathLib(calculateMarginFromMarkup, pseudoEntry.markupValue)
        : pseudoEntry.markupValue
    });
  };

  const handleItemModalClose = () => {
    setItemModalOpen(false);
  };

  const handleSaveItem1 = useCallback(async () => {
    const formatData = ({
      id,
      version,
      unitCost,
      unitPrice,
      markupValue,
      materialMarkup,
      marginValue,
      materialMargin,
      autoUpdateScaledMarkup
    }) => ({
      id,
      version,
      unitCost,
      unitPrice,
      autoUpdateScaledMarkup,
      markupValue: isMarginEnabled
        ? convertForMathLib(calculateMarkupFromMargin, marginValue)
        : markupValue,
      materialMarkup: isMarginEnabled
        ? convertForMathLib(calculateMarkupFromMargin, materialMargin)
        : materialMarkup,
      markupType: 'Percentage'
    });

    // only support Mode.EDIT for now
    try {
      await client.mutate({
        mutation: updatePricebookEntry,
        variables: {
          partitionKey: user.tenantId,
          data: formatData(formServiceRef.current.formikContext.values)
        }
      });

      refreshEntries();
      snackbar('success', `Successfully ${Mode.past(itemModalMode)} pricebook item`);
      handleItemModalClose();
    } catch (error) {
      logErrorWithCallback(
        error,
        snackbar,
        `Unable to ${itemModalMode} pricebook item, please try again later.`
      );
    }
  }, [client, itemModalMode, refreshEntries, isMarginEnabled, snackbar, user.tenantId]);

  const handleSaveItem2 = useCallback(async () => {
    const formValues = formServiceRef?.current?.formikContext?.values;

    const { id } = computedMatch.params;

    try {
      const payload = {
        priceBookEntryOverride: {
          priceBook2Id: id,
          productId: formValues.id,
          materialMarkup: formValues.materialMarkup,
          taxable: formValues.taxable
        }
      };

      const newOverride = await upsertPriceBookEntryOverride(payload);
      setPricebook2EntryOverridesMap(
        new Map(pricebook2EntryOverridesMap.set(newOverride?.data?.productId, newOverride?.data))
      );

      snackbar('success', `Successfully ${Mode.past(itemModalMode)} pricebook item`);
      handleItemModalClose();
    } catch (error) {
      logErrorWithCallback(
        error,
        snackbar,
        `Unable to create PriceBookEntryOverride, please try again later.`
      );
    }
  }, [itemModalMode, isMarginEnabled, snackbar, user.tenantId]);

  const handleLabourRateChanges = async (detail, updateCallback) => {
    const formatData = ({
      id,
      version,
      isActive,
      rate,
      billingHourTypeId,
      labourTypeId,
      costCodeId,
      revenueTypeId
    }) => ({
      id,
      version,
      isActive,
      rate,
      billingHourTypeId,
      labourTypeId,
      costCodeId,
      revenueTypeId
    });

    const mode = detail.id ? Mode.EDIT : Mode.ADD;
    let updatedFields = {};

    try {
      if (mode === Mode.EDIT) {
        // update
        const { data } = await client.mutate({
          mutation: updateLabourRate,
          variables: {
            partitionKey: user.tenantId,
            data: formatData(detail)
          }
        });
        updatedFields = data.updatePricebookLabourEntry;
      } else {
        // add
        const { data } = await client.mutate({
          mutation: addLabourRate,
          variables: {
            partitionKey: user.tenantId,
            data: {
              priceBookId: pricebook.id,
              pricebookLabourEntries: [formatData(detail)]
            }
          }
        });
        updatedFields = data.addPricebookLabourEntriesToPriceBook?.[0];
      }
    } catch (error) {
      logErrorWithCallback(error, snackbar, `Unable to ${Mode.past(mode)} labour rate`);
    } finally {
      const updatedLaborItems = [...laborItems];
      const toUpdate = updatedLaborItems.find(l => l.id === detail.labourTypeId);
      const updatedItem = { ...detail, ...updatedFields };
      toUpdate[detail.billingHourTypeId] = updatedItem;
      setLaborItems(updatedLaborItems);
      updateCallback(updatedItem);
    }
  };

  const summaryText = () => {
    if (!pricebook) {
      return '';
    }

    const defaultVal = `${isMarkupEnabled ? `Default Markup: ${pricebook.markupValue}%,` : ''} ${
      isMarginEnabled
        ? `Default Margin: ${convertForMathLib(calculateMarginFromMarkup, pricebook.markupValue)}%,`
        : ''
    }`;

    let valType;
    if (isMarkupEnabled && isMarginEnabled) {
      valType = 'markup/margin';
    } else if (isMarginEnabled) {
      valType = 'margin';
    } else {
      valType = 'markup';
    }

    let appliedScheme = '';
    const materialMarkups = materialRangesFromJSON(pricebook.materialMarkupJSON);
    if (isMultipleMaterialMarkupRange(materialMarkups)) {
      appliedScheme = ` Range-based ${valType} applied`;
    } else if (isFlatMaterialMarkupRange(materialMarkups)) {
      appliedScheme = ` Flat ${valType} applied`;
    }

    return `${defaultVal}${appliedScheme}`;
  };

  const renderPageHeaderButtons = () => {
    const enableEditPricebook = flags[FeatureFlags.ENABLE_EDIT_PRICEBOOK];

    if (!enableEditPricebook) {
      return <div />;
    }

    const containerStyle = {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      justifyContent: 'flex-end'
    };

    return (
      <ThemeProvider>
        <div style={containerStyle}>
          <Button type={ButtonType.TERTIARY} onClick={() => setEditModalOpen(true)}>
            Edit
          </Button>
          {/*  This will be needed soon
          <MoreButton
            style={{ fontSize: 24, padding: 0 }}
            options={[
              {
                label: 'Upload stuff',
                icon: MailOutline,
                onClick: () => console.log('upload stuff')
              }
            ]}
          /> */}
        </div>
      </ThemeProvider>
    );
  };

  if (!pricebook || !pricebookVersion) {
    return <Spinner />;
  }

  // Menu_pricebooks have allow action only, only admins, it will turn true, for non admins it will be false
  const isAdmin = checkPermission('update', PermissionConstants.MENU_PRICEBOOKS, user);

  return (
    <ErrorBoundaries>
      <UserPermission I="allow" action={PermissionConstants.MENU_PRICEBOOKS}>
        <PageHeader
          breadcrumbsArray={[
            { link: '', title: 'Settings' },
            { link: '/settings/pricebooks', title: Labels.pricebooks[user.locale] }
          ]}
          title={`${Labels.pricebook[user.locale]}: ${pricebook?.name}`}
          pageMapKey="pricebook"
          userLocale={user.locale}
        >
          {renderPageHeaderButtons()}
        </PageHeader>
        <ErrorBoundaries>
          <Grid>
            <Grid container justify="space-between" xs={12}>
              <Grid item xs={8}>
                <Typography variant="body2">{summaryText()}</Typography>
                <Typography variant="body2">{pricebook.description}</Typography>
              </Grid>
            </Grid>
            <div style={{ borderBottom: '1px solid #E6E6E6', margin: '24px -24px 24px -24px' }} />
            <Tabs disableBottomPadding>
              <Tab label={Labels.partsAndMaterials[user.locale]}>
                {pricebookVersion >= 2 ? (
                  <Pricebook2Details
                    pricebook2={pricebook}
                    pricebook2EntryOverridesMap={pricebook2EntryOverridesMap}
                    onOpenEditItemModal={handleItemModalOpen2}
                  />
                ) : (
                  <ResponsiveTable
                    rowMetadata={pricebookEntryColumns(
                      isQuickBooksEnabled,
                      isMarginEnabled,
                      flags,
                      isMarkupEnabled
                    )}
                    fullScreen
                    refreshData={refreshData}
                    service={getEntries}
                    showToolbars
                    noDataMsg="No Items"
                    rowActions={handleItemModalOpen}
                    tableName="Pricebook Items"
                    rowActionButtons={
                      isAdmin
                        ? {
                            edit: {
                              icon: 'Edit',
                              label: 'Edit'
                            }
                          }
                        : null
                    }
                  />
                )}
              </Tab>
              <Tab label={Labels.laborRates[user.locale]}>
                <LabourRate
                  metaData={laborMetaData}
                  data={laborItems}
                  handleLabourRateChanges={handleLabourRateChanges}
                  disableFilter
                  showDefaults
                  selectedPricebook={pricebook}
                  isReadOnly={!isAdmin}
                />
              </Tab>
            </Tabs>
            <EditPricebookModal
              open={editModalOpen}
              handleClose={() => setEditModalOpen(false)}
              pricebook={pricebook}
              onSubmit={async () => {
                await getPricebook();
                refreshEntries();
              }}
            />
            <ThemeProvider>
              <Modal
                title="Edit Pricebook Item"
                open={itemModalOpen}
                onClose={handleItemModalClose}
                actions={
                  <Button
                    type={ButtonType.PRIMARY}
                    name="post"
                    onClick={pricebookVersion >= 2 ? handleSaveItem2 : handleSaveItem1}
                    fullWidth
                  >
                    Save
                  </Button>
                }
                PaperProps={{ style: { minWidth: 700 } }}
              >
                <SgtForm
                  layout="default"
                  configuration={pricebookEntryLayout(
                    isQuickBooksEnabled,
                    isMarginEnabled,
                    isMarkupEnabled,
                    flags
                  )}
                  initialValues={itemModalData}
                  onCreateService={formService => {
                    formServiceRef.current = formService;
                  }}
                />
              </Modal>
            </ThemeProvider>
          </Grid>
        </ErrorBoundaries>
      </UserPermission>
    </ErrorBoundaries>
  );
}

const styledPricebooks = withStyles(styles, { withTheme: true })(PricebookDetail);

const mapStateToProps = state => ({
  user: state.user,
  settings: state.settings
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedPricebooks = connect(mapStateToProps, mapDispatcherToProps)(styledPricebooks);

export default withLDConsumer()(reduxConnectedPricebooks);
