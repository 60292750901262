import sortBy from 'lodash/sortBy';
import React, { useEffect, useState } from 'react';
import { func } from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { Typography } from '@buildhero/sergeant';
import { getProjects } from 'services/API/project';

const ProjectSearchHeader = ({ onProjectSelected }) => {
  const [projectOptions, setProjectOptions] = useState();
  useEffect(() => {
    (async function fetchProjects() {
      const projects = await getProjects();
      setProjectOptions(
        sortBy(projects, project => `${project.name} ${project.number}`.toLowerCase())
      );
    })();
  }, []);
  return (
    <Autocomplete
      id="search-projects"
      options={projectOptions || []}
      getOptionLabel={option => `${option.number} ${option.name}`}
      fullWidth
      size="small"
      renderInput={params => <TextField {...params} label="Search Projects" variant="outlined" />}
      renderOption={option => (
        <Typography>
          {option?.name} – #{option?.number}
        </Typography>
      )}
      onChange={(event, value) => {
        onProjectSelected(value);
      }}
    />
  );
};

ProjectSearchHeader.propTypes = {
  onProjectSelected: func.isRequired
};

export default ProjectSearchHeader;
