import { MAX_FORMS_WIDTH } from 'themes/BuildHeroTheme';

const styles = theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.grayscale(100)
  },
  toolbar: {
    height: 60,
    minHeight: 60
  },
  modalContent: {
    width: '100%',
    maxWidth: MAX_FORMS_WIDTH,
    margin: 'auto',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5)
  },
  title: {
    fontSize: 20,
    margin: 0,
    fontWeight: 500
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  fixedHeader: {
    position: 'fixed',
    width: '100%',
    backgroundColor: theme.palette.grayscale(100),
    zIndex: 100
  }
});

export default styles;
