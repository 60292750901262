export const styles = theme => ({
  inputLabel: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'normal',
    letterSpacing: '0.01px',
    lineHeight: '14px'
  },
  requiredLabel: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    fontSize: '8px',
    letterSpacing: 0,
    lineHeight: '14px'
  },
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  paper: props => ({
    position: 'absolute',
    zIndex: 3,
    left: 0,
    right: 0,
    height: props.menuHeight ?? 300,
    overflowY: 'auto'
  }),
  divider: {
    height: theme.spacing(2)
  },
  noOptionsMessage: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`
  },
  muiFilledInput: {
    borderRadius: 4
  },
  input: {
    display: 'flex',
    padding: '4px 10px 5px',
    height: 'auto'
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  multiValueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  singleValue: {
    fontSize: 16,
    borderRadius: 4,
    font: 'inherit',
    color: 'currentColor',
    whiteSpace: 'nowrap'
  },
  clearIndicator: {
    padding: '2px'
  },
  dropdownIndicator: {
    padding: '2px'
  },
  menuItem: {
    minHeight: 33
  }
});

export default styles;
