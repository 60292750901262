import gql from 'graphql-tag';

const updatePurchaseOrderLine = gql`
  mutation updatePurchaseOrderLine($partitionKey: String, $data: UpdatePurchaseOrderLineInput!) {
    updatePurchaseOrderLine(partitionKey: $partitionKey, data: $data) {
      id
      sortKey
      itemName
      vendorName
      description
      departmentName
      accountingRefIdOfClass
      priceBookEntryId
      quantity
      unitCost
      unitPrice
      markup
      amount
      createdBy
      includeInInvoice
      taxable
      version
      parentId
      costCodeId
      jobCostTypeId
      revenueTypeId
      billingStatus
    }
  }
`;

export default updatePurchaseOrderLine;
