import React from 'react';
import { array, func } from 'prop-types';
import EmployeeTimesheets from './components/EmployeeTimesheets';
import { useStyles } from './EmployeesTimesheets.styles';
import { useTimesheetsByEmployee } from './EmployeesTimesheets.hooks';

const EmployeesTimesheets = ({
  employees,
  employeesTimesheets,
  projectPhases,
  payrollHourTypes,
  onTimesheetChange
}) => {
  const styles = useStyles();
  const items = useTimesheetsByEmployee(employeesTimesheets, employees);
  return (
    <div css={styles.root}>
      {items.map(({ employee, employeeTimesheets }) => (
        <EmployeeTimesheets
          key={employee?.id}
          employee={employee}
          employeeTimesheets={employeeTimesheets}
          projectPhases={projectPhases}
          payrollHourTypes={payrollHourTypes}
          onTimesheetChange={onTimesheetChange}
        />
      ))}
    </div>
  );
};

EmployeesTimesheets.propTypes = {
  employees: array.isRequired,
  employeesTimesheets: array.isRequired,
  projectPhases: array.isRequired,
  payrollHourTypes: array.isRequired,
  onTimesheetChange: func.isRequired
};

export default EmployeesTimesheets;
