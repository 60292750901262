import gql from 'graphql-tag';

const getCompanyVendors = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      vendors(entityConnection: "Vendor", limit: 10000) {
        items {
          name
          type
          accountingRefId
          primaryContact
          phone
          email
          fax
          notes
          addressLine1
          addressLine2
          city
          state
          zipcode
          isActive
          id
          version
          sortKey
        }
      }
    }
  }
`;

export default getCompanyVendors;
