export const generalInformationFields = {
  date: { required: true, nullable: true },
  number: { required: true }
};

export const getGeneralInformationLayout = ({ loading, timezone }) => ({
  fields: {
    date: { default: 'date', required: true }
  },
  layouts: {
    default: {
      options: {
        borderBottom: '1px solid #E5E5E5'
      },
      contents: [
        {
          options: {
            width: '100%',
            padding: [10, 0]
          },
          contents: [
            {
              options: {
                direction: 'row'
              },
              contents: [
                {
                  options: {
                    direction: 'row'
                  },
                  contents: [
                    {
                      options: {
                        paddingRight: '15px'
                      },
                      contents: [
                        {
                          component: 'ReportDateInput',
                          source: 'date',
                          options: {
                            label: 'Date',
                            disabled: loading,
                            timezone
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  options: {
                    fullWidth: false
                  },
                  contents: [
                    {
                      options: {
                        fullWidth: false,
                        width: '100px'
                      },
                      contents: [
                        {
                          component: 'TextInput',
                          source: 'number',
                          options: {
                            label: 'Number',
                            variant: 'standard',
                            color: 'secondary',
                            value: '-',
                            numeric: true,
                            disabled: true,
                            isRequired: true
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  options: {},
                  contents: []
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    $schema: 'http://json-schema.org/draft-07/schema#',
    title: 'DailyReportGeneralInfo',
    type: 'object',
    properties: {}
  },
  validationErrors: {}
});
