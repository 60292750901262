import theme from 'themes/BuildHeroTheme';

export const quoteSettingLayout = ({ isTotalPercentageError, enableJobCloseoutSetting }) => ({
  fields: {},
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    color: theme.palette.secondary.main,
                    fontWeight: 'bold',
                    fontSize: 14
                  },
                  component: 'BodyText',
                  source: 'Scope and Pricing'
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0,
            paddingBottom: 75,
            borderBottom: `1px solid ${theme.palette.grayscale(80)}`
          },
          contents: [
            {
              options: {
                direction: 'column',
                padding: [12, 0],
                width: '50%'
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '80%',
                    justify: 'space-between'
                  },
                  contents: [
                    {
                      options: {
                        fontSize: 20,
                        marginTop: 20
                      },
                      component: 'BodyText',
                      source: 'Lock Default Scope and Pricing Information'
                    },
                    {
                      component: {
                        view: 'ReadOnlyCheckboxInput',
                        default: 'CheckboxInput'
                      },
                      source: 'enableLockScopeAndPricingData',
                      options: {
                        checked: false,
                        value: '',
                        fullWidth: true,
                        labelPlacement: 'start',
                        displayAsSwitch: true
                      }
                    }
                  ]
                }
              ]
            },
            {
              options: {
                direction: 'column',
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '50%'
                  },
                  contents: [
                    {
                      options: {
                        color: theme.palette.grayscale(60)
                      },
                      component: 'BodyText',
                      source: `
                        Enabling this feature will prevent modifications to tasks, line items, and pricing data on the document.
                        Labels such as "Scope", etc can still be edited.
                      `
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0,
            paddingBottom: 75,
            borderBottom: `1px solid ${theme.palette.grayscale(80)}`
          },
          contents: [
            {
              options: {
                direction: 'column',
                padding: [12, 0],
                width: '50%'
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '80%',
                    justify: 'space-between'
                  },
                  contents: [
                    {
                      options: {
                        fontSize: 20,
                        marginTop: 20
                      },
                      component: 'BodyText',
                      source: 'Customer signature required on Quote Approval page'
                    },
                    {
                      component: {
                        view: 'ReadOnlyCheckboxInput',
                        default: 'CheckboxInput'
                      },
                      source: 'approvalSignatureRequired',
                      options: {
                        checked: false,
                        value: '',
                        fullWidth: true,
                        labelPlacement: 'start',
                        displayAsSwitch: true
                      }
                    }
                  ]
                }
              ]
            },
            {
              options: {
                direction: 'column',
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '50%'
                  },
                  contents: [
                    {
                      options: {
                        color: theme.palette.grayscale(60)
                      },
                      component: 'BodyText',
                      source: `
                        Enabling this feature will allow customers to type in their names when they approve quotes via email. 
                        Approver name will be displayed alongside the approval note.
                      `
                    }
                  ]
                }
              ]
            }
          ]
        },
        enableJobCloseoutSetting && {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    color: theme.palette.secondary.main,
                    fontWeight: 'bold',
                    fontSize: 14
                  },
                  component: 'BodyText',
                  source: 'Default Line Items for Quoted Work'
                }
              ]
            }
          ]
        },
        enableJobCloseoutSetting && {
          options: {
            size: 1,
            direction: 'row',
            grow: 0,
            paddingBottom: 20,
            borderBottom: `1px solid ${theme.palette.grayscale(80)}`
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: [12, 0]
              },
              contents: [
                {
                  component: 'DefaultQuotedWorkLineItem',
                  source: 'defaultQuotedWorkLineItems',
                  options: {
                    isTotalPercentageError
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '50%'
                  },
                  contents: [
                    {
                      options: {
                        color: theme.palette.grayscale(60)
                      },
                      component: 'BodyText',
                      source: `This setting applies to quoted work that does not have a BuildOps quote associated with a job.
                       Determine how amount quoted will be divided into line items on an invoice. Add and select line items and allocate a 
                       percentage of the term price towards each line item.`
                    }
                  ]
                }
              ]
            }
          ]
        }
      ].filter(Boolean)
    }
  }
});

export default quoteSettingLayout;
