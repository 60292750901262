import React from 'react';

import { jsx } from '@emotion/react';
import { Divider, useTheme } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import {
  Field,
  FieldType,
  InlineAlert,
  InlineAlertTypes,
  ThemeProvider,
  TV,
  TW,
  Typography
} from '@buildhero/sergeant';
import { InventoryPartSourceType, InvoiceStatus } from 'utils/constants';

import JobCloseoutInformation from '../InformationSection';
import QuotedInventoryCosts from '../InventoryCosts';
import { InvoiceDiscounts } from '../InvoiceOverviewDiscounts';
import { InvoiceOtherItems } from '../InvoiceOverviewOtherItems';
import NotesTable from '../NotesTable';
import PurchasedItemsTable from '../PurchasedItemsTable';
import QuotedPartsAndMaterials from '../QuotedOverviewTab/PartsAndMaterials';
import { getStyles } from '../styles';

import { InvoicedQuoteTasks } from './InvoicedTasks';
import InvoiceLaborTable from './InvoiceItems/InvoiceLaborTable';
import QuotedLaborCosts from './LaborCosts';

const QuoteLink = ({ theme, quote }) => (
  <div css={{ marginRight: 8 }}>
    <Field
      value={{
        label: `View Quote ${quote.customIdentifier || quote.quoteNumber} - ${
          quote.name
        } (Version ${quote.versionLabel ?? quote.versionNumber})`,
        to: `/quote/${quote.id}`
      }}
      variant={TV.S1}
      type={FieldType.LINK}
      color={theme.palette.primary.main}
      style={{ marginBottom: theme.spacing(2) }}
    />
  </div>
);

const JobCloseoutQuotedOverview = ({ data, visits, refetchJob, companyTimezone, isLoading }) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const costsModifiedByInvoice = (data.inventoryParts?.items || [])
    .filter(part => !part.invoiceItemId || part.invoiceItem.invoice.status !== InvoiceStatus.VOID)
    .some(part => part?.invoiceItem?.source === InventoryPartSourceType.INVOICE);

  const invoiceItemsModifiedByInvoice = (data.invoices?.items || [])
    .filter(({ status }) => status !== InvoiceStatus.VOID)
    .some(invoice =>
      (invoice.invoiceItems?.items || []).some(
        ({ source }) => source === InventoryPartSourceType.INVOICE
      )
    );

  return (
    <ThemeProvider>
      <JobCloseoutInformation jobData={data} isLoading={isLoading} />
      <NotesTable
        notes={data?.notes?.items || []}
        job={data}
        refetchJob={refetchJob}
        isLoading={isLoading}
      />
      <Divider css={styles.divider} />
      <Typography variant={TV.L} weight={TW.BOLD} css={{ marginBottom: theme.spacing(2) }}>
        Costs
      </Typography>
      {costsModifiedByInvoice && (
        <InlineAlert Icon={WarningIcon} type={InlineAlertTypes.YELLOW}>
          This information has been modified from an invoice.
        </InlineAlert>
      )}
      <QuotedLaborCosts
        visits={data.visits?.items || []}
        companyTimezone={companyTimezone}
        isLoading={isLoading}
      />
      <QuotedInventoryCosts
        jobData={data}
        visits={visits}
        refetchJob={refetchJob}
        companyTimezone={companyTimezone}
        isLoading={isLoading}
      />
      <PurchasedItemsTable
        purchaseOrderLines={data?.purchaseOrderLines?.items}
        billLines={data?.billLines?.items}
        purchaseOrders={data?.purchaseOrders?.items}
        isLoading={isLoading}
      />
      <Divider css={styles.divider} />
      <Typography variant={TV.L} weight={TW.BOLD}>
        Invoice Items
      </Typography>
      <div css={{ display: 'flex' }}>
        {data?.quoteJobs?.items.map(qj => (
          <QuoteLink key={qj.quote.id} theme={theme} quote={qj.quote} />
        ))}
      </div>
      {invoiceItemsModifiedByInvoice && (
        <InlineAlert Icon={WarningIcon} type={InlineAlertTypes.YELLOW}>
          <Typography>This information has been modified from an invoice.</Typography>
        </InlineAlert>
      )}
      <InvoiceLaborTable jobData={data} isLoading={isLoading} />
      <QuotedPartsAndMaterials jobData={data} isLoading={isLoading} />
      {!isLoading && (
        <>
          <InvoiceOtherItems jobData={data} />
          <InvoiceDiscounts jobData={data} />
          <InvoicedQuoteTasks jobData={data} />
        </>
      )}
    </ThemeProvider>
  );
};

JobCloseoutQuotedOverview.defaultProps = {
  isLoading: false
};

export default JobCloseoutQuotedOverview;
