const paddingBottom = 12;

const PreferredTechniciansSidebarViewLayout = () => ({
  fields: {},
  layouts: {
    view: {
      contents: [
        {
          options: {
            paddingBottom
          },
          contents: [
            {
              component: 'FieldWithLabel',
              source: 'department',
              options: {
                label: 'Department'
              }
            }
          ]
        },
        {
          options: {
            paddingBottom
          },
          contents: [
            {
              component: 'FieldWithLabel',
              source: 'crew',
              options: {
                label: 'Crew'
              }
            }
          ]
        },
        {
          options: {
            paddingBottom
          },
          contents: [
            {
              component: 'FieldWithLabel',
              source: 'primaryTechnician',
              options: {
                label: 'Primary Technician'
              }
            }
          ]
        },
        {
          options: {
            paddingBottom
          },
          contents: [
            {
              component: 'FieldWithLabel',
              source: 'additionalTechnicians',
              options: {
                label: 'Other Technicians'
              }
            }
          ]
        }
      ]
    }
  }
});

export default PreferredTechniciansSidebarViewLayout;
