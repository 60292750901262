export default {
  'Invoice Presets': {
    label: 'Detailed Invoice',
    value: 'default-value',
    isDefault: true
  },
  Labor: {
    'Show labor total': true,
    'Labor Line Items': {
      'Show labor name': true,
      'Show labor description': true,
      'Show labor taxable': true,
      'Show labor hours': true,
      'Show labor rate': true,
      'Show labor subtotal': true
    }
  },
  'Parts & Materials': {
    'Show parts & materials total': true,
    'Parts & Materials Line Items': {
      'Show item name': true,
      'Show product code': true,
      'Show item description': true,
      'Show item taxable': true,
      'Show item quantity': true,
      'Show item unit price': true,
      'Show item subtotal': true
    }
  },
  'Discounts & Fees': {
    'Show discounts & fees total': true,
    'Discounts & Fees Line Items': {
      'Show item name': true,
      'Show item description': true,
      'Show item type': true,
      'Show item taxable': true,
      'Show item quantity': true,
      'Show item unit price': true,
      'Show item subtotal': true
    }
  },
  Payments: {
    'Show payments total': true,
    'Payment Line Items': {
      'Show payment number': true,
      'Show payment type': true,
      'Show payment date': true,
      'Show payment amount': true
    }
  },
  'Totals & Subtotals': {
    'Show invoice subtotal': true,
    'Show discounts & fees': true,
    'Show tax': true,
    'Show amount paid': true,
    'Show balance': true
  },
  'Invoice Mode': {
    'Simple Invoice': false
  }
};
