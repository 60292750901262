import gql from 'graphql-tag';

const getQuoteTaskLevelTotal = gql`
  query getQuoteTaskLevelTotal($id: String!) {
    getQuoteById(id: $id) {
      id
      taskLevelGrouping: aggregatedField(
        input: {
          entityConnection: "QuoteLineTask"
          isDirectChildren: false
          groupByFields: ["taxable", "id", "entityType"]
          aggregations: [{ aggregationType: SUM, aggregationField: "unitPrice * quantity" }]
          aggregationFilter: { fieldName: aggregation1, filterInput: { gt: 0 } }
        }
      ) {
        items {
          totalAmount: aggregation1
          taxable: groupByField1
          taskId: groupByField2
          entityType: groupByField3
        }
      }
    }
  }
`;
export default getQuoteTaskLevelTotal;
