import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  header: { borderBottom: `1px solid ${theme.palette.grayscale(90)}` },
  subHeaderItem: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`
  },
  title: {
    color: theme.palette.grayscale(10),
    padding: `0 ${theme.spacing(1)}px`
  },
  name: {
    color: theme.palette.grayscale(10)
  },
  exportButton: {
    background: theme.palette.grayscale(10),
    marginLeft: theme.spacing(1)
  },
  chip: {
    height: '25px',
    textTransform: 'capitalize',
    background: theme.palette.other.logoGreen
  },
  actionButton: {
    marginLeft: theme.spacing(1)
  },
  filterSection: {
    display: 'flex',
    alignItems: 'center',
    flex: 2,
    marginTop: theme.spacing(0.2)
  },
  hourSummary: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  }
}));
