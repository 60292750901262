export const eventTypes = {
  VISIT: 'Visit',
  NON_VISIT_EVENT: 'NonVisitEvent',
  MAN_DAY: 'ManDay'
};

export const timesheetViews = {
  DAY: 'day',
  WEEK: 'week'
};

export const statusTabs = {
  TO_REVIEW: 'toReview',
  APPROVED: 'approved',
  PENDING_REVISION: 'disputed'
};
