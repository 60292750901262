import React from 'react';
import withLazyMount from 'utils/withLazyMount';
import ReportTable from '../../../ReportTable';
import { notesColumns } from '../../../../meta/notesColumns';
import { reviewReportDefaultProps, reviewReportPropTypes } from '../../reviewReportPropTypes';
import { selectReviewReportNotes } from '../../../../selectors';

const ReviewReportNotesTable = ({ reviewReport, loading, error }) => {
  const title = 'Technician Notes (Internal Use)';
  const noDataMessage = 'No Technician Notes';
  const rows = selectReviewReportNotes(reviewReport) || [];
  return (
    <ReportTable
      title={title}
      columns={notesColumns}
      rows={rows}
      loading={loading}
      error={error}
      noDataMessage={noDataMessage}
    />
  );
};

ReviewReportNotesTable.propTypes = reviewReportPropTypes;
ReviewReportNotesTable.defaultProps = reviewReportDefaultProps;

export default withLazyMount(ReviewReportNotesTable);
