import moment from 'moment';

export const formatGeneralInfoForSave = generalInfo => {
  return {
    date: generalInfo.date,
    number: `${generalInfo.number}`
  };
};

export const formatNotesForSave = note => {
  return {
    ...note,
    id: note.id || null,
    attachments: null
  };
};

export const formatMaterialsForSave = material => {
  return {
    id: material.id || null,
    name: material.name || null,
    description: material.description || null,
    quantity: material.quantity || null,
    unitOfMeasure: material.unitOfMeasureName || null,
    productId: material.productId || null
  };
};

export const formatProductivityForSave = productivity => ({
  ...productivity,
  projectPhaseId: productivity?.phaseId,
  projectPhaseDepartmentCostCodeId: productivity?.costCodeId,
  projectPhaseDepartmentId: productivity?.departmentId
});

export const formatCommonForSave = item => {
  return {
    ...item,
    id: item.id || null
  };
};

export const getMomentTime = data => (typeof data === 'number' ? moment.unix(data) : data ?? null);

export const getCollapsedTime = (time, generatedDate) => {
  if (time) {
    const seconds = moment(time).seconds();
    const minutes = moment(time).minutes();
    const hours = moment(time).hours();
    const collapsedSecsFromDate = seconds + minutes * 60 + hours * 60 * 60;
    return generatedDate + collapsedSecsFromDate;
  }
  return null;
};
