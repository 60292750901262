import { pick } from 'ramda';
import useExtendedMutation from 'customHooks/useExtendedMutation';
import addReviewReportPurchaseOrderLines from 'services/core/graphql/review-report/mutations/AddReviewReportPurchaseOrderLines';
import { GET_REVIEW_REPORT_BY_ID } from '../queries';

export const useAddReviewReportPurchaseOrderLine = reviewReportId =>
  useExtendedMutation(addReviewReportPurchaseOrderLines, {
    serializer: ({ tenantId, purchaseOrderLine }) => {
      const orderLine = pick(
        [
          'description',
          'itemName',
          'departmentName',
          'priceBookEntryId',
          'costCodeId',
          'jobCostTypeId',
          'revenueTypeId',
          'taxable',
          'quantity',
          'markup',
          'unitCost',
          'unitPrice'
        ],
        purchaseOrderLine
      );
      return {
        variables: {
          partitionKey: tenantId,
          data: {
            purchaseOrderId: purchaseOrderLine?.parentId,
            orderLines: [
              {
                ...orderLine,
                markup: purchaseOrderLine.markupValue,
                taxable: Boolean(purchaseOrderLine.taxable)
              }
            ]
          }
        }
      };
    },
    update: (cache, { data: { addReviewReportPurchaseOrderLines: purchaseOrderLines } }) => {
      if (!purchaseOrderLines) {
        return;
      }

      const cachedData = cache.readQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        }
      });

      cache.writeQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        },
        data: {
          getReviewReportById: {
            ...(cachedData?.getReviewReportById || {}),
            purchaseOrders: {
              __typename: cachedData.getReviewReportById?.purchaseOrderLines?.__typename,
              items:
                cachedData.getReviewReportById?.purchaseOrders?.items?.map(purchaseOrder => {
                  if (purchaseOrder.id === purchaseOrderLines?.[0]?.parentId) {
                    return {
                      ...purchaseOrder,
                      purchaseOrderLines: {
                        __typename: 'PurchaseOrderLineConnection',
                        items: [
                          ...(purchaseOrder?.purchaseOrderLines?.items || []),
                          ...purchaseOrderLines
                        ]
                      }
                    };
                  }
                  return purchaseOrder;
                }) || []
            }
          }
        }
      });
    }
  });
