import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import Moment from 'react-moment';
import AppConstants from 'utils/AppConstants';
import styles from './styles';

const TechSummary = props => {
  const { classes, summary } = props;
  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className={classes.bodyContent}>{summary.summary}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.footer}>
        Last edited by {summary.lastUpdatedBy} on &nbsp;
        <Moment unix format={AppConstants.DATETIME_FORMAT}>
          {parseInt(summary.lastUpdatedDateTime, 10) / 1000}
        </Moment>
      </Typography>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(TechSummary);
