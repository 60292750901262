import { TaskStatus, TaskTypeInternal } from 'utils/AppConstants';

export const selectTaskStatusText = ({ taskTypeInternal, status }) => {
  switch (taskTypeInternal) {
    case TaskTypeInternal.RECOMMENDED:
      switch (status) {
        case TaskStatus.IN_JOB:
        case TaskStatus.IN_QUOTE:
          return 'Added to Quote';
        case TaskStatus.OPEN:
          return 'Unquoted';
        default:
          return status;
      }
    case TaskTypeInternal.PENDING:
      switch (status) {
        case TaskStatus.IN_JOB:
          return 'Added to Work';
        default:
          return status;
      }
    default:
      return status;
  }
};
