import gql from 'graphql-tag';
import useExtendedMutation from 'customHooks/useExtendedMutation';

const UPDATE_LABOUR_RATE_LINE_ITEM = gql`
  mutation updateLabourRateLineItem($partitionKey: String, $data: UpdateLabourRateLineItemInput!) {
    updateLabourRateLineItem(partitionKey: $partitionKey, data: $data) {
      id
      version
      labourTypeId
      employeeId
      labourRateBillingHourLines {
        items {
          id
          version
          rate
          hourTypeId
          totalHours
          productId
        }
      }
    }
  }
`;

const serializer = ({ tenantId, lineItemId, version, labourTypeId }) => ({
  variables: {
    partitionKey: tenantId,
    data: {
      id: lineItemId,
      version,
      labourTypeId
    }
  }
});

export const useUpdateLabourRateLineItem = () => {
  return useExtendedMutation(UPDATE_LABOUR_RATE_LINE_ITEM, {
    serializer
  });
};
