import { tableDateTimeFormatter, tableEmptyValueFormatter } from 'components/WrapTable';

export const summaryNotesColumns = [
  {
    field: 'id',
    hide: true
  },
  {
    field: 'summary',
    flex: 8,
    headerName: 'Summary Note',
    valueFormatter: tableEmptyValueFormatter
  },
  {
    field: 'lastUpdatedBy',
    flex: 1,
    headerName: 'Last Edited By',
    valueFormatter: tableEmptyValueFormatter
  },
  {
    field: 'lastUpdatedDateTime',
    flex: 1,
    type: 'dateTime',
    headerName: 'Last Edited',
    valueFormatter: tableDateTimeFormatter
  }
];
