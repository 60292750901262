import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import StatusChip from 'components/StatusChip';
import projectStatusChips from 'meta/projectStatusChips';
import { AppConstants } from 'utils/AppConstants';
import Photo from '@material-ui/icons/Photo';

const useStyles = makeStyles(theme => ({
  itemContainer: {
    borderRadius: 0,
    background: '#FFFFFF',
    maxHeight: 315,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)'
  },
  descriptionContainer: {
    padding: theme.spacing(1)
  },
  emptyCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '160px',
    background: '#CCCCCC'
  },
  statusChip: {
    position: 'absolute',
    top: 8,
    left: 8,
    zIndex: 1
  },
  projectName: {
    height: 42,
    color: '#333333',
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: '-0.03em',
    lineHeight: '20px',
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  },
  projectInfomation: {
    minHeight: 20,
    color: '#333333',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px'
  },
  projectLocation: {
    minHeight: 20,
    color: '#333333',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px'
  },
  projectDate: {
    color: '#999999',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px'
  }
}));

const ProjectItemCard = ({ projectData, user }) => {
  const classes = useStyles();

  return (
    <Card className={classes.itemContainer}>
      <CardActionArea>
        <StatusChip
          label={projectStatusChips[projectData.status]?.name[user.locale]}
          backgroundColor={projectStatusChips[projectData.status]?.backgroundColor}
          textColor={projectStatusChips[projectData.status]?.textColor}
          className={classes.statusChip}
          style={{ fontWeight: 500, lineHeight: '20px' }}
        />
        {projectData.imgUrl ? (
          <CardMedia
            component="img"
            alt={`${projectData.name} project`}
            height="160"
            image={projectData.imgUrl}
            title={projectData.name}
          />
        ) : (
          <Box className={classes.emptyCard}>
            <Photo style={{ fontSize: '60px' }} color="disabled" />
          </Box>
        )}
        <CardContent className={classes.descriptionContainer}>
          <Typography className={classes.projectName}>{projectData.name || '-'}</Typography>
          <Typography className={classes.projectInfomation}>
            {projectData.ProjectSubtype?.name || '-'}
          </Typography>
          <Typography className={classes.projectInfomation}>
            {projectData.projectManager?.firstName && projectData.projectManager?.name
              ? projectData.projectManager?.name
              : '-'}
          </Typography>
          <Typography className={classes.projectLocation}>
            {projectData.addressCity || '-'}
          </Typography>
          <Typography className={classes.projectDate}>
            {projectData.dateStart
              ? moment.unix(projectData.dateStart).format(AppConstants.DATE_FORMAT)
              : 'TBD'}
          </Typography>
          <Typography className={classes.projectDate}>
            {projectData.dateEnd
              ? moment.unix(projectData.dateEnd).format(AppConstants.DATE_FORMAT)
              : 'TBD'}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

ProjectItemCard.propTypes = {
  projectData: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default ProjectItemCard;
