const formsPublishedRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
    showLink: 'view',
    isCustom: true,
    type: 'LinkButton'
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: true,
    label: 'Description'
  },
  {
    id: 'associatedEntityType',
    numeric: false,
    disablePadding: true,
    label: 'Form Type'
  },
  {
    id: 'lastUpdatedTime',
    numeric: false,
    type: 'datetime',
    disablePadding: true,
    label: 'Last Updated'
  }
];

const formsPublishedRowsAdmin = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
    showLink: 'view',
    isCustom: true,
    type: 'LinkButton'
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: true,
    label: 'Description'
  },
  {
    id: 'associatedEntityType',
    numeric: false,
    disablePadding: true,
    label: 'Form Type'
  },
  {
    id: 'viewType',
    numeric: false,
    disablePadding: true,
    label: 'View Type'
  },
  {
    id: 'lastUpdatedTime',
    numeric: false,
    type: 'datetime',
    disablePadding: true,
    label: 'Last Updated'
  }
];

export const formsPublishedTable = {
  dataPathInQueryResult: '',
  idField: 'id',
  captionAttributes: formsPublishedRows,
  noDataMsg: 'No forms'
};

export const formsPublishedTableAdmin = {
  dataPathInQueryResult: '',
  idField: 'id',
  captionAttributes: formsPublishedRowsAdmin,
  noDataMsg: 'No forms'
};

const formsDraftsRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
    showLink: 'view',
    isCustom: true,
    type: 'LinkButton'
  },
  {
    id: 'draftType',
    numeric: false,
    disablePadding: true,
    label: 'Type'
  }
];

const formsDraftsRowsAdmin = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
    showLink: 'view',
    isCustom: true,
    type: 'LinkButton'
  },
  {
    id: 'draftType',
    numeric: false,
    disablePadding: true,
    label: 'Type'
  },
  {
    id: 'viewType',
    numeric: false,
    disablePadding: true,
    label: 'View Type'
  }
];

export const formsDraftsTable = {
  dataPathInQueryResult: '',
  idField: 'id',
  captionAttributes: formsDraftsRows,
  noDataMsg: 'No Drafts'
};

export const formsDraftsTableAdmin = {
  dataPathInQueryResult: '',
  idField: 'id',
  captionAttributes: formsDraftsRowsAdmin,
  noDataMsg: 'No Drafts'
};

export const formBuilderPageTitleButtons = {
  save: {
    label: 'Save & Close',
    color: 'primary',
    buttonType: 'contained',
    behavior: {
      new: true,
      edit: true
    }
  },
  cancel: {
    label: 'Cancel',
    color: 'secondary',
    buttonType: 'contained',
    behavior: {
      new: true,
      edit: true
    }
  }
};

const formSectionRows = [
  {
    id: 'name',
    filterKey: 'Form.name',
    filterType: 'string',
    numeric: false,
    label: 'Name',
    isCustom: true,
    type: 'formName'
  },
  {
    id: 'description',
    filterKey: 'Form.description',
    filterType: 'string',
    numeric: false,
    label: 'Notes'
  },
  {
    id: 'lastUpdatedBy',
    filterKey: 'Form.lastUpdatedBy',
    filterType: 'string',
    numeric: false,
    label: 'Last Updated By'
  },
  {
    id: 'lastUpdatedDateTime',
    filterKey: 'Form.lastUpdatedDateTime',
    filterType: 'number',
    numeric: false,
    type: 'datetime',
    label: 'Last Updated'
  }
];

export const formSectionTable = {
  dataPathInQueryResult: '',
  idField: 'id',
  captionAttributes: formSectionRows,
  noDataMsg: 'No forms'
};

const selectFormRows = [
  {
    id: 'name',
    numeric: false,
    label: 'Name',
    isCustom: true,
    type: 'formName',
    bold: true
  },
  {
    id: 'description',
    filterKey: 'Form.description',
    filterType: 'string',
    numeric: false,
    label: 'Description'
  },
  {
    id: 'lastUpdatedTime',
    numeric: false,
    type: 'datetime',
    disablePadding: true,
    label: 'Last Updated'
  }
];

export const selectFormTable = {
  dataPathInQueryResult: '',
  idField: 'id',
  captionAttributes: selectFormRows,
  noDataMsg: 'No forms'
};
