import React from 'react';
import PropTypes from 'prop-types';
import { jsx } from '@emotion/react';
import { Stepper, Step, StepButton } from '@material-ui/core';

const useStyles = maxWidth => ({
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  stepper: {
    paddingLeft: 20,
    paddingRight: 20,
    maxWidth,
    flex: 1
  }
});

function StepsStatus(props) {
  const { steps, currentStepIndex, onClickStep, maxStepperWidth } = props;
  const classes = useStyles(maxStepperWidth);

  return (
    <div css={classes.container}>
      <Stepper activeStep={currentStepIndex} css={classes.stepper}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton disabled={index > currentStepIndex} onClick={() => onClickStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

StepsStatus.propTypes = {
  // labels of each step
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  // index of current step
  currentStepIndex: PropTypes.number.isRequired,
  // cb func called when you click on a step
  // func(stepIndex)
  onClickStep: PropTypes.func.isRequired,
  // used to keep track of the latest step that the user got to, so if the user
  // decides to go back a step, he can click back into any step up to that point
  maxStepperWidth: PropTypes.number
};

StepsStatus.defaultProps = {
  maxStepperWidth: 880
};

export default StepsStatus;
