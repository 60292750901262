import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, makeStyles } from '@material-ui/core';
import { MUIForm } from '@buildhero/sergeant';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import { useCrewMembersFromLeader } from '@dispatch/queries';
import { useCompanyTimezone } from '@pm/components/hooks';
import getLayout from './PreferredTechniciansFormLayout';
import CrewFormWarningMessage from './CrewFormWarningMessage';
import CrewMembersAddedMessage from './CrewMembersAddedFromLeaderMessage';
import {
  mapById,
  filterCrewsByDepartment,
  filterTechniciansByDepartment,
  getAdditionalTechnicians,
  crewHasSameTechniciansAsSelected
} from '../../scenes/ServiceAgreements/DetailView/helpers';
import {
  CrewsPropType,
  DepartmentsPropType,
  TechniciansPropType,
  PreferredTechniciansPropType
} from './PropTypes';

const useStyles = makeStyles(() => {
  return {
    formContainer: {
      width: '100%'
    }
  };
});

const handleDepartmentChange = (
  departmentId,
  state,
  departmentTechniciansMap,
  departmentCrewsMap
) => {
  const { primaryTechnicianId, additionalTechnicianIds, crewId } = state;
  let updatedFields = {};

  const crewBelongsToDepartment = !!departmentCrewsMap[crewId];
  if (!crewBelongsToDepartment) {
    updatedFields = {
      ...updatedFields,
      crewId: undefined
    };
  }

  const primaryTechnicianBelongsToDepartment = !!departmentTechniciansMap[primaryTechnicianId];
  if (!primaryTechnicianBelongsToDepartment) {
    updatedFields = {
      ...updatedFields,
      primaryTechnicianId: undefined
    };
  }

  if (additionalTechnicianIds?.length) {
    const departmentAdditionalTechnicianIds = _.filter(
      additionalTechnicianIds,
      id => !!departmentTechniciansMap[id]
    );
    updatedFields = {
      ...updatedFields,
      additionalTechnicianIds: departmentAdditionalTechnicianIds
    };
  }

  return {
    ...updatedFields,
    departmentId
  };
};

const handlePrimaryTechnicianChange = (primaryTechnicianId, state, additionalTechniciansMap) => {
  const { additionalTechnicianIds } = state;
  const newAdditionalTechnicianIds = _.filter(
    additionalTechnicianIds,
    id => !!additionalTechniciansMap[id]
  );

  return {
    ...state,
    additionalTechnicianIds: newAdditionalTechnicianIds,
    primaryTechnicianId
  };
};

const handleCrewChange = (crewId, crew, state) => {
  if (!crewId) {
    return {
      ...state,
      crewId
    };
  }

  const primaryTechnicianId = crew?.foreman?.id;
  const crewTechs = crew?.techs || [];
  const additionalTechnicianIds = _.filter(
    _.map(crewTechs, tech => tech.id),
    id => id !== primaryTechnicianId
  );

  return {
    ...state,
    primaryTechnicianId,
    additionalTechnicianIds,
    crewId
  };
};

const PreferredTechniciansForm = props => {
  const {
    preferredTechnicians,
    departments,
    crews,
    technicians: employees,
    jobDepartments,
    requiredFields,
    onCreateService,
    onComplete,
    onChange,
    hideExtraTechNumber,
    restrictedEdits,
    crewTimeTracking,
    visitDate,
    isEdit
  } = props;

  const technicians = employees.filter(e => e.isTech || !(typeof e.isTech === 'boolean'));

  const classes = useStyles();

  const [prefTechsData, setPrefTechsData] = useState(preferredTechnicians || {});
  useEffect(() => {
    if (_.isEmpty(prefTechsData) && !_.isEmpty(preferredTechnicians)) {
      setPrefTechsData(preferredTechnicians);
    }
  }, [preferredTechnicians, prefTechsData]);

  useEffect(() => {
    // Auto-populate department if the job only has one and their isn't one
    // already specified.
    if (!preferredTechnicians?.departmentId && jobDepartments.length === 1) {
      setPrefTechsData({ departmentId: jobDepartments[0].mappedEntityId });
    }
  }, [jobDepartments, preferredTechnicians]);

  const state = useRef();
  state.current = {
    prefTechsData,
    crews,
    technicians,
    onChange
  };

  const [primaryTechId, setPrimaryTechId] = useState();
  const [primaryTechName, setPrimaryTechName] = useState('');

  const [selectedVisitDate, setSelectedVisitDate] = useState(moment().unix());
  useEffect(() => {
    if (visitDate) {
      setSelectedVisitDate(visitDate);
    }
  }, [visitDate]);

  const [{ data: companyTimezone }] = useCompanyTimezone();
  const [startOfDayCompanyTimezone, setStartOfDayCompanyTimezone] = useState(selectedVisitDate);

  useEffect(() => {
    if (companyTimezone) {
      setStartOfDayCompanyTimezone(
        moment
          .unix(selectedVisitDate)
          .tz(companyTimezone)
          .startOf('day')
          .unix()
      );
    }
  }, [companyTimezone, selectedVisitDate]);

  const crewMembersResponse = useCrewMembersFromLeader(primaryTechId, startOfDayCompanyTimezone);
  useEffect(() => {
    if (crewTimeTracking && !isEdit && !crewMembersResponse.loading && crewMembersResponse.data) {
      const newFields = {
        ...state.current.prefTechsData,
        primaryTechnicianId: primaryTechId,
        additionalTechnicianIds: [...crewMembersResponse.data]
      };
      setPrimaryTechName(
        crewMembersResponse.data.length
          ? state.current?.technicians?.find?.(x => x.id === primaryTechId)?.name
          : ''
      );
      setPrefTechsData(newFields);
      state.current.onChange(newFields);
    }
  }, [crewMembersResponse, crewTimeTracking, isEdit, primaryTechId]);

  const departmentTechnicians = filterTechniciansByDepartment(
    technicians,
    prefTechsData?.departmentId
  );
  const departmentCrews = filterCrewsByDepartment(crews, prefTechsData?.departmentId);
  const additionalTechnicians = getAdditionalTechnicians(
    departmentTechnicians,
    prefTechsData.primaryTechnicianId
  );

  const departmentOptions = constructSelectOptions(departments, 'tagName', 'id');
  const crewOptions = constructSelectOptions(departmentCrews, 'name', 'id');
  const technicianOptions = constructSelectOptions(departmentTechnicians, 'name', 'id');
  const additionalTechniciansOptions = constructSelectOptions(additionalTechnicians, 'name', 'id');

  const crewNotChanged = crewHasSameTechniciansAsSelected(
    state.current.crews,
    prefTechsData.crewId,
    prefTechsData.primaryTechnicianId,
    prefTechsData.additionalTechnicianIds
  );

  const onFieldChange = (field, value) => {
    let updatedFields;
    if (field === 'departmentId') {
      const departmentTechniciansMap = mapById(
        filterTechniciansByDepartment(state.current.technicians, value)
      );
      const departmentCrewsMap = mapById(filterCrewsByDepartment(state.current.crews, value));
      updatedFields = handleDepartmentChange(
        value,
        state.current.prefTechsData,
        departmentTechniciansMap,
        departmentCrewsMap
      );
    } else if (field === 'primaryTechnicianId') {
      setPrimaryTechId(value);

      const additionalTechniciansMap = mapById(
        getAdditionalTechnicians(
          filterTechniciansByDepartment(
            state.current.technicians,
            state.current.prefTechsData?.departmentId
          ),
          value
        )
      );
      updatedFields = handlePrimaryTechnicianChange(
        value,
        state.current.prefTechsData,
        additionalTechniciansMap
      );
    } else if (field === 'crewId') {
      const crew = _.find(state.current.crews, ({ id }) => id === value);
      updatedFields = handleCrewChange(value, crew, state.current.prefTechsData);
    } else {
      updatedFields = {
        [field]: value
      };
    }

    const newFields = {
      ...state.current.prefTechsData,
      ...updatedFields
    };
    setPrefTechsData(newFields);
    state.current.onChange(newFields);
  };

  const layout = getLayout({
    departmentOptions,
    technicianOptions,
    additionalTechniciansOptions,
    crewOptions,
    requiredFields,
    showCrewWarningMessage: !crewNotChanged,
    color: 'primary',
    hideExtraTechNumber,
    restrictedEdits,
    crewTimeTracking,
    primaryTechName
  });

  return (
    <Box className={classes.formContainer}>
      <MUIForm
        customComponents={{
          CrewFormWarningMessage,
          CrewMembersAddedMessage
        }}
        layout="edit"
        configuration={layout}
        data={prefTechsData}
        onFieldChange={onFieldChange}
        onCreateService={onCreateService}
        onComplete={onComplete}
      />
    </Box>
  );
};

PreferredTechniciansForm.propTypes = {
  preferredTechnicians: PreferredTechniciansPropType.isRequired,
  departments: DepartmentsPropType.isRequired,
  crews: CrewsPropType.isRequired,
  technicians: TechniciansPropType.isRequired,
  jobDepartments: PropTypes.array,
  onCreateService: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  requiredFields: PropTypes.object,
  hideExtraTechNumber: PropTypes.bool,
  restrictedEdits: PropTypes.bool,
  crewTimeTracking: PropTypes.bool,
  visitDate: PropTypes.number,
  isEdit: PropTypes.bool
};

PreferredTechniciansForm.defaultProps = {
  requiredFields: {},
  jobDepartments: [],
  hideExtraTechNumber: false,
  restrictedEdits: false,
  crewTimeTracking: false,
  visitDate: null,
  isEdit: false
};

export default PreferredTechniciansForm;
