/* eslint-disable import/prefer-default-export */
export const noteLayout = {
  fields: {},
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        direction: 'row',
        width: 450
      },
      contents: [
        {
          options: {
            direction: 'column'
          },
          contents: [
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel',
                    edit: 'TextInput'
                  },
                  source: 'subject',
                  options: {
                    label: 'Subject',
                    placeholder: 'Enter Subject',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel',
                    edit: 'TextInput'
                  },
                  source: 'note',
                  options: {
                    label: 'Content',
                    placeholder: 'Enter Content',
                    fullWidth: true,
                    alignment: 'left',
                    lines: 5
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  }
};

export const noteRows = [
  {
    id: 'subject',
    numeric: false,
    disablePadding: true,
    label: 'Subject'
  },
  {
    id: 'note',
    numeric: false,
    disablePadding: true,
    type: 'text',
    label: 'Content',
    maxTextLen: 180,
    headerStyle: {
      width: '35%'
    }
  },
  {
    id: 'lastUpdatedDateTime',
    numeric: false,
    type: 'dateOnly',
    disablePadding: true,
    label: 'Date added'
  },
  {
    id: 'lastUpdatedBy',
    numeric: false,
    disablePadding: true,
    label: 'Added by'
  }
];