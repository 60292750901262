const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5)
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.grayscale(90),
    color: theme.palette.grayscale(60),
    height: 24,
    letterSpacing: 0.24,
    borderRadius: 12
  },
  selectedChip: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.brand.logoGreen,
    color: theme.palette.grayscale(100),
    fontWeight: 500,
    height: 32,
    letterSpacing: 0.24,
    borderRadius: 12
  },
  hoverChip: {
    '&:hover, &:focus': {
      backgroundColor: theme.palette.brand.logoGreen,
      color: theme.palette.grayscale(100)
    }
  }
});

export default styles;
