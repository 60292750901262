import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';
import { costCodeSearch } from 'services/API/costCode';
import { jobAndProjectSearch } from 'services/API/job';
import { searchTypes, searchRevenues } from 'services/API/jobCostType';

const defaultFlexStyle = '0 1 195px';
const margin = '8px 8px 8px 8px';

const editItemFields = {
  costCodeObj: { required: false },
  jobCostType: { required: false },
  revenueTypeObj: { required: false },
  jobAndProject: { required: false }
};

const editItemContents = () => {
  return [
    {
      options: {
        direction: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'SearchBar'
              },
              source: 'costCodeObj',
              options: {
                resultFormatFunction: obj => obj.description,
                searchFunction: costCodeSearch,
                searchColumns: ['description'],
                label: 'Cost Code',
                placeholder: 'Select Cost Code',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'SearchBar'
              },
              source: 'jobCostType',
              options: {
                resultFormatFunction: obj => obj.name,
                searchFunction: searchTypes,
                searchColumns: ['name'],
                label: 'Cost Type',
                placeholder: 'Select Cost Type',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              }
            }
          ]
        }
      ]
    },
    {
      options: {
        direction: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'SearchBar'
              },
              source: 'revenueTypeObj',
              options: {
                resultFormatFunction: obj => obj.name,
                searchFunction: searchRevenues,
                searchColumns: ['name'],
                label: 'Revenue Type',
                placeholder: 'Select Revenue Type',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'SearchBar'
              },
              source: 'jobAndProject',
              options: {
                resultFormatFunction: obj =>
                  typeof obj.jobNumber !== 'undefined' ? `${obj.jobNumber}` : `${obj.number}`,
                searchFunction: jobAndProjectSearch,
                label: 'Job / Project',
                placeholder: 'Search and Select',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              }
            }
          ]
        }
      ]
    }
  ];
};

const editItemLayout = () => {
  return {
    fields: generateDefaultFieldsObject(editItemFields),
    layouts: {
      edit: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: editItemContents()
      }
    },
    validation: {
      type: 'object',
      properties: {},
      required: []
    },
    validationErrors: {}
  };
};

export { editItemFields, editItemLayout };
