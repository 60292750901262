import gql from 'graphql-tag';

const updateMaintenanceTemplate = gql`
  mutation updateMaintenanceTemplate($data: MaintenanceTemplateUpdateInput!) {
    updateMaintenanceTemplate(input: $data) {
      id
      serviceAgreementId
      propertyId
      customerProperty {
        id
        companyName
      }
      schedule
      firstDueDate
      budgetedHours
      estimatedVisits
      version
    }
  }
`;

export default updateMaintenanceTemplate;
