import gql from 'graphql-tag';
import useExtendedMutation from '../../../../customHooks/useExtendedMutation';
import { GET_REVIEW_REPORT_BY_ID } from '../queries';

const ADD_ATTACHMENT = gql`
  mutation addAttachmentsToReviewReport(
    $partitionKey: String
    $data: AddAttachmentsToReviewReportInput!
  ) {
    addAttachmentsToReviewReport(partitionKey: $partitionKey, data: $data) {
      id
      customFileName
      fileName
      fileUrl
      type
      comment
      createdBy
      createdDate
      createdDateTime
      includeInInvoice
      version
    }
  }
`;

const serializer = ({ tenantId, reviewReportId, attachment }) => ({
  variables: {
    partitionKey: tenantId,
    data: {
      reviewReportId,
      attachments: [attachment]
    }
  }
});

export const useAddReviewReportAttachment = reviewReportId => {
  return useExtendedMutation(ADD_ATTACHMENT, {
    serializer,
    update: (cache, { data: { addAttachmentsToReviewReport } }) => {
      const newAttachment = addAttachmentsToReviewReport?.[0];
      if (!newAttachment) {
        return;
      }

      const cachedData = cache.readQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        }
      });

      cache.writeQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        },
        data: {
          getReviewReportById: {
            ...(cachedData?.getReviewReportById || {}),
            attachments: {
              __typename: cachedData.getReviewReportById?.attachments?.__typename,
              items: [
                ...(cachedData.getReviewReportById?.attachments?.items || []),
                {
                  ...newAttachment
                }
              ]
            }
          }
        }
      });
    }
  });
};
