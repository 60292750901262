import React from 'react';
import { ThemeProvider, Field, Input } from '@buildhero/sergeant';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, IconButton } from '@material-ui/core';
import useStyles from './styles';

const List = ({ field, form, options }) => {
  const { setFieldValue } = form;
  const { showAddButton, allowEdit = false, onChange } = options;
  const classes = useStyles();
  const partsAndMaterials = field?.value?.items || [];
  const handleEdit = (value, index) => {
    partsAndMaterials[index] = value;
    if (onChange) {
      onChange({ items: partsAndMaterials });
    }
    setFieldValue('items', partsAndMaterials);
  };

  const handleDelete = index => {
    partsAndMaterials.splice(index, 1);
    if (onChange) {
      onChange({ items: partsAndMaterials });
    }
    setFieldValue('items', partsAndMaterials);
  };

  return (
    <Box display="flex" py={2} flexDirection="column">
      {partsAndMaterials.map((item, i) => {
        return (
          <ThemeProvider>
            <Box key={item.id} display="flex" py={2}>
              <Field
                label="Item"
                value={item?.pricebookEntry?.product?.name || item.priceBookEntryName}
                style={{ width: 250 }}
              />
              <Input
                label="Description"
                multiline
                defaultValue={
                  item.priceBookEntryDescription || item?.pricebookEntry?.product?.description
                }
                style={{ marginLeft: 16, width: 350 }}
                onChange={e =>
                  handleEdit({ ...item, priceBookEntryDescription: e.target.value }, i)
                }
                disabled={!showAddButton && !allowEdit}
              />
              <Input
                label="Quantity"
                value={item.quantity || 0}
                disabled={!showAddButton && !allowEdit}
                onChange={e => handleEdit({ ...item, quantity: Number(e.target.value || 0) }, i)}
                style={{ marginLeft: 16, width: 150 }}
              />
              {showAddButton && (
                <IconButton
                  aria-label="Delete"
                  onClick={() => handleDelete(i)}
                  key={`iconDeleteButton${item.id}`}
                  className={classes.deleteIcon}
                >
                  <DeleteIcon className={classes.iconStyle} key={`deleteIconButton${item.id}`} />
                </IconButton>
              )}
            </Box>
          </ThemeProvider>
        );
      })}
    </Box>
  );
};

export default List;
