import gql from 'graphql-tag';

const deleteNote = gql`
  mutation deleteNote($input: DeleteNoteInput!) {
    deleteNote(input: $input) {
      id
    }
  }
`;

export default deleteNote;
