import theme from 'themes/BuildHeroTheme';

export const purchaseOrderStatusChips = {
  draft: {
    name: {
      en: 'Draft',
      es: 'Sequía'
    },
    backgroundColor: theme.palette.grayscale(80),
    textColor: theme.palette.grayscale(20)
  },
  unfulfilled: {
    name: {
      en: 'Unfulfilled',
      es: 'Incumplido'
    },
    backgroundColor: theme.palette.grayscale(80),
    textColor: theme.palette.grayscale(20)
  },
  ordered: {
    name: {
      en: 'Ordered',
      es: 'Ordenado'
    },
    backgroundColor: theme.palette.other.statusBlue,
    textColor: theme.palette.grayscale(20)
  },
  partiallyFulfilled: {
    name: {
      en: 'Partially Fulfilled',
      es: 'Parcialmente cumplido'
    },
    backgroundColor: theme.palette.other.statusOrange,
    textColor: theme.palette.grayscale(20)
  },
  fulfilled: {
    name: {
      en: 'Fulfilled',
      es: 'Cumplido'
    },
    backgroundColor: theme.palette.other.statusGreen,
    textColor: theme.palette.grayscale(20)
  },
  fieldOrder: {
    name: {
      en: 'Field Order',
      es: 'Orden de Campo'
    },
    backgroundColor: theme.palette.grayscale(80),
    textColor: theme.palette.grayscale(20)
  },
  void: {
    name: {
      en: 'Void',
      es: 'Vacía'
    },
    backgroundColor: theme.palette.other?.statusRed ?? theme.palette.support.red.medium,
    textColor: theme.palette.grayscale(20)
  }
};

export const receiptStatusChips = {
  pending: {
    name: {
      en: 'Pending'
    },
    backgroundColor: theme.palette.other.statusOrange,
    textColor: theme.palette.grayscale(20)
  },
  posted: {
    name: {
      en: 'Posted'
    },
    backgroundColor: theme.palette.other.statusGreen,
    textColor: theme.palette.grayscale(20)
  },
  exported: {
    name: {
      en: 'Exported'
    },
    backgroundColor: theme.palette.other.statusBlue,
    textColor: theme.palette.grayscale(20)
  },
  closed: {
    name: {
      en: 'Closed'
    },
    backgroundColor: theme.palette.grayscale(80),
    textColor: theme.palette.grayscale(20)
  },
  ordered: {
    name: {
      en: 'Ordered',
      es: 'Ordenado'
    },
    backgroundColor: theme.palette.other.statusBlue,
    textColor: theme.palette.grayscale(20)
  },
  partiallyFulfilled: {
    name: {
      en: 'Partially Fulfilled',
      es: 'Parcialmente cumplido'
    },
    backgroundColor: theme.palette.other.statusOrange,
    textColor: theme.palette.grayscale(20)
  },
  fulfilled: {
    name: {
      en: 'Fulfilled',
      es: 'Cumplido'
    },
    backgroundColor: theme.palette.other.statusGreen,
    textColor: theme.palette.grayscale(20)
  }
};
