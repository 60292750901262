import { useExtendedFetchForPMAPI as useExtendedFetch } from 'customHooks/useExtendedFetch';

export const useGetSubmittalPackage = (id, options = {}, params = {}) => {
  return useExtendedFetch(
    {
      url: `submittalpackages/${id}`,
      params
    },
    {
      errorData: [],
      ...options
    }
  );
};

export const useGetSubmittalPackageItems = (id, options = {}, params = {}) => {
  return useExtendedFetch(
    {
      url: `submittalpackagesubmittalitem`,
      params: {
        where: {
          submittalPackageId: id
        },
        ...params
      }
    },
    {
      errorData: [],
      ...options
    }
  );
};
