import React, { useEffect, useState } from 'react';
import { func, object, string, array } from 'prop-types';
import moment from 'moment';
import { getState } from 'redux/store';

import { useFormService } from '@dispatch/hooks';
import { EVENT_TYPES } from '@dispatch/Dispatch.constants';
import { selectVisitData } from '@dispatch/components/Drawer/Drawer.selectors';

import { useCrewMembersFromLeader } from '@dispatch/queries';
import { getTenantSettingValueForKey } from 'utils';
import { useCompanyTimezone } from '@pm/components/hooks';
import EventBadge from '../EventBadge';
import DrawerLayout from '../DrawerLayout';
import VisitDetailsForm from '../VisitDetailsForm';
import JobDetailsHeader from '../JobDetailsHeader';

import JobSearchHeader from './components/JobSearchHeader';

const CreateVisit = ({
  closeDrawer,
  eventData,
  jobNumber,
  setJobNumber,
  addVisitTuple,
  addMultipleVisitsTuple,
  jobDetailsResponse
}) => {
  const { tenantId, tenantCompanyId } = getState().user;
  const { data: job, loading: fetchingJobDetails } = jobDetailsResponse;
  const [triggerAddVisitMutation, addVisitResponse] = addVisitTuple;
  const [
    triggerAddMultipleVisitsMutation,
    addMultipleVisitsMutationResponse
  ] = addMultipleVisitsTuple;
  const { isDirty, onCreateService, onDirtyChange, resetForm, submitForm } = useFormService();
  const { loading: submittingVisit } = addVisitResponse;
  const { loading: submittingMultipleVisits } = addMultipleVisitsMutationResponse;
  const loading = submittingVisit || submittingMultipleVisits || fetchingJobDetails;
  const disabled = !job || loading;
  const hasJobSelected = !!jobNumber && !!job;
  const selVisitData = selectVisitData(eventData);
  const crewTimeTracking = getTenantSettingValueForKey('crewTimeTracking') === 'true';

  const [{ data: companyTimezone }] = useCompanyTimezone();
  const [startOfDayCompanyTimezone, setStartOfDayCompanyTimezone] = useState(
    selVisitData.scheduledFor
  );
  useEffect(() => {
    if (companyTimezone) {
      setStartOfDayCompanyTimezone(
        moment
          .unix(selVisitData.scheduledFor)
          .tz(companyTimezone)
          .startOf('day')
          .unix()
      );
    }
  }, [companyTimezone, selVisitData]);

  const crewMembersResponse = useCrewMembersFromLeader(
    selVisitData.primaryTechId,
    startOfDayCompanyTimezone
  );
  const [initialVisitData, setInitialVisitData] = useState(selVisitData);
  const [crewMembersAdded, setCrewMembersAdded] = useState(false);
  useEffect(() => {
    if (crewTimeTracking && !crewMembersResponse.loading && crewMembersResponse.data) {
      setCrewMembersAdded(!!(!selVisitData.extraTechs && crewMembersResponse?.data.length));

      setInitialVisitData({
        ...initialVisitData,
        extraTechs: selVisitData.extraTechs ?? [...crewMembersResponse.data]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crewMembersResponse, crewTimeTracking]);

  const handleFormReset = () => {
    setJobNumber(null);
    resetForm();
  };
  const createVisit = (visit, shouldCreateMultipleVisits) => {
    if (shouldCreateMultipleVisits) {
      triggerAddMultipleVisitsMutation({
        tenantId,
        tenantCompanyId,
        visit,
        job
      });
    } else {
      triggerAddVisitMutation({
        tenantId,
        tenantCompanyId,
        visit,
        job
      });
    }
    closeDrawer();
    handleFormReset();
  };

  const renderHeader = () => {
    return (
      <>
        <EventBadge eventType={EVENT_TYPES.VISIT} />
        {hasJobSelected ? (
          <JobDetailsHeader job={job} />
        ) : (
          <JobSearchHeader onJobSelected={setJobNumber} />
        )}
      </>
    );
  };

  const renderBody = () => (
    <VisitDetailsForm
      visit={initialVisitData}
      onCreateService={onCreateService}
      onDirtyChange={onDirtyChange}
      onComplete={createVisit}
      visitLoading={loading}
      disabled={disabled}
      job={job}
      crewTimeTracking={crewTimeTracking}
      crewMembersAdded={crewMembersAdded}
      collapseDetails
      allowMultipleVisitsCreation
    />
  );

  return (
    <DrawerLayout
      renderHeader={renderHeader}
      renderBody={renderBody}
      onResetForm={handleFormReset}
      onSubmitForm={submitForm}
      isDirty={hasJobSelected || isDirty}
      loading={loading}
      disabledSave={!job || loading}
      disabledClose={loading}
    />
  );
};

CreateVisit.propTypes = {
  closeDrawer: func.isRequired,
  eventData: object,
  jobNumber: string.isRequired,
  setJobNumber: func.isRequired,
  addVisitTuple: array.isRequired,
  addMultipleVisitsTuple: array.isRequired,
  jobDetailsResponse: object.isRequired
};

CreateVisit.defaultProps = {
  eventData: null
};

export default CreateVisit;
