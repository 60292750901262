import { makeStyles } from '@material-ui/core';
import { DispatchTheme } from '@dispatch/Dispatch.styles';

export const useStyles = makeStyles(theme => ({
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    outline: 'none'
  },
  iconButton: {
    flex: 0
  },
  contents: ({ expanded, maxHeight }) => ({
    transition: '0.3s',
    overflow: 'hidden',
    paddingBottom: expanded ? theme.spacing(1) : 0,
    maxHeight: expanded ? maxHeight : 0
  }),
  divider: {
    backgroundColor: DispatchTheme.lightBorderColor
  },
  header: {
    fontSize: 20,
    letterSpacing: -1,
    fontWeight: 700
  }
}));
