import gql from 'graphql-tag';

const addFormDataToEntity = gql`
  mutation AddFormDataToEntity($partitionKey: String!, $data: CreateFormDataInput!) {
    addFormDataToEntity(partitionKey: $partitionKey, data: $data) {
      id
      version
    }
  }
`;

export default addFormDataToEntity;
