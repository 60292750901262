import gql from 'graphql-tag';

const getTimesheetPeriodById = gql`
  query getTimesheetPeriodById($partitionKey: String, $id: String!) {
    getTimesheetPeriodById(partitionKey: $partitionKey, id: $id) {
      id
      dateStartUTC
      dateEndUTC
      approved
      approvedDateUTC
      approvalStatus
      approvedByName
      isActive
      approvedById
      parentId
      parentSortKey
      entityType
      version
      tenantCompanyId
      partitionKey
      sortKey
      timesheetDailyOverrides(entityConnection: "TimesheetDailyOverride") {
        items {
          id
          dateUTC
          hourTypeAbbreviation
          actualTotalDurationOverride
          hourTypeId
          version
        }
      }
      timesheetEntriesView(entityConnection: "TimesheetEntry", pagination: { limit: 1000 }) {
        items {
          id
          hourTypeId
          entityType
          actualTotalDuration
          reviewStatus
          userActionType
          timesheetPeriodId
          approvalStatus
          isSplitted
          billableEntityId
          actualTotalDurationOverride
          actualStartTimeUTC
          actualStartTimeOverrideUTC
          actualEndTimeUTC
          actualEndTimeOverrideUTC
          actualCreatedDate
          startLatitude
          startLongitude
          endLatitude
          endLongitude
          version
          billableEntityId
          billableEntityType
          billableEntity(entityConnection: "BillableEntity") {
            ... on Visit {
              id
              entityType
              visitNumber
              timesheetNotes(entityConnection: "TimesheetNote") {
                items {
                  id
                  note
                  subject
                  createdBy
                  employeeId
                  attachments(entityConnection: "Attachment") {
                    items {
                      fileUrl
                      id
                      _deleted
                    }
                  }
                }
              }
              job: parentEntity {
                ... on Job {
                  id
                  jobNumber
                  customIdentifier
                  customerProperty: parentEntity {
                    ... on CustomerProperty {
                      id
                      companyName
                      customer: parentEntity {
                        ... on Customer {
                          id
                          customerName
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          timekeepingLedgerId
          timekeepingLedger(entityConnection: "TimekeepingLedger") {
            id
            employeeName
            billableEntityType
            eventType(entityConnection: "EventType") {
              id
              name
              isBillable
            }
            eventEntity(entityConnection: "EventEntity") {
              ... on NonVisitEvent {
                id
                name
                timesheetNotes(entityConnection: "TimesheetNote") {
                  items {
                    id
                    note
                    subject
                    createdBy
                    employeeId
                    attachments(entityConnection: "Attachment") {
                      items {
                        fileUrl
                        id
                        _deleted
                      }
                    }
                  }
                }
                customerProperty: parentEntity {
                  ... on CustomerProperty {
                    id
                    companyName
                    customer: parentEntity {
                      ... on Customer {
                        id
                        customerName
                      }
                    }
                  }
                }
                job: parentEntity {
                  ... on Job {
                    jobNumber
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getTimesheetPeriodById;
