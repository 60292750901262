import { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import gql from 'graphql-tag';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import { logErrorWithCallback } from 'utils';
import { snackbarOn } from 'redux/actions/globalActions';

export const employeeGQL = gql`
  query useEmployees(
    $partitionKey: String!
    $sortKey: String!
    $filter: TableContactFilterInput
    $includeOffschedules: Boolean!
    $includeDepartments: Boolean!
    $includeAppRole: Boolean!
    $includeBillingRate: Boolean!
    $includePayrollCost: Boolean!
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      employees: employees(filter: $filter) {
        items {
          id
          code
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          firstName
          lastName
          name
          email
          personalEmail
          status
          isAdmin
          isTech
          isSales
          isActive
          isCrewMember
          cellPhone
          landlinePhone
          profilePictureUrl
          labourTypeId
          offSchedules(entityConnection: "OffSchedule") @include(if: $includeOffschedules) {
            items {
              offDate
              version
              startTime
              endTime
              hierarchy
              sortKey
              partitionKey
              entityType
              id
              tenantCompanyId
            }
          }
          departments: unmappedDepartments @include(if: $includeDepartments) {
            items {
              id
              tagName
              tagType
              accountingRefIdOfClass
            }
          }
          appRoles: unmappedAppRoles @include(if: $includeAppRole) {
            items {
              id
              tagName
              tagType
            }
          }
          billingRates(entityConnection: "BillingRate") @include(if: $includeBillingRate) {
            items {
              id
              rate
              version
              hourTypeId
            }
          }
          payrollCosts(entityConnection: "PayrollCost") @include(if: $includePayrollCost) {
            items {
              id
              cost
              version
              hourTypeId
            }
          }
        }
      }
    }
  }
`;

const useEmployees = ({
  includeOffschedules = false,
  includeDepartments = false,
  includeAppRole = false,
  includeBillingRate = false,
  includePayrollCost = false,
  filter = {},
  transform
} = {}) => {
  const [data, setData] = useState([]);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const snackbar = useCallback((...args) => dispatch(snackbarOn(...args)), [dispatch]);

  const companySortKey = `${user.tenantId}_Company_${user.tenantCompanyId}`;
  const { data: rawData, loading, error } = useExtendedQuery(employeeGQL, {
    variables: {
      partitionKey: user.tenantId,
      sortKey: companySortKey,
      includeOffschedules,
      includeDepartments,
      includeAppRole,
      includeBillingRate,
      includePayrollCost,
      filter
    },
    transform: ({ getCompany }) => {
      const emps =
        getCompany?.employees?.items?.map(emp => ({
          ...emp,
          name: `${emp.firstName || ''} ${emp.lastName || ''}`
        })) || [];
      if (transform) return transform(emps);
      return emps;
    }
  });

  useEffect(() => {
    if (rawData) {
      setData(rawData);
    }
  }, [rawData]);

  if (error) {
    logErrorWithCallback(error, snackbar, 'Unable to get the employees');
  }
  return [data, loading];
};

export default useEmployees;
