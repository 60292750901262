import { makeStyles } from '@material-ui/core/styles';

const styles = theme => ({
  outerContainer: {
    width: '100%'
  },
  footer: {
    position: 'sticky',
    left: 0,
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grayscale(90),
    padding: 8
  },
  footerRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12
  }
});

export const viewStyles = makeStyles(theme => ({
  viewCaption: {
    fontSize: 10,
    lineHeight: '12px',
    marginBottom: 4,
    fontWeight: 500,
    color: theme.palette.grayscale(20),
    letterSpacing: '0.02em'
  },
  viewsTitle: {
    marginLeft: 8,
    marginTop: 16,
    marginBottom: 16,
    color: theme.palette.grayscale(20),
    fontWeight: 500,
    fontSize: 14
  },
  sectionTitle: {
    marginLeft: 8,
    marginBottom: 6,
    color: theme.palette.grayscale(60),
    fontWeight: 500,
    fontSize: 10
  },
  selectWrapper: {
    position: 'relative',
    width: 219,
    height: 36,
    backgroundColor: theme.palette.grayscale(94),
    borderRadius: 2,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '-0.011px',
    border: 0,
    marginRight: 8,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0
    },
    '&MuiList-root': {
      padding: 0
    }
  },
  addBtn: {
    height: 48,
    width: '100%',
    paddingLeft: 16,
    alignItems: 'center',
    color: theme.palette.secondary.main,
    borderTop: `1px solid ${theme.palette.grayscale(90)}`,
    justifyContent: 'left'
  },
  moreIcon: {
    color: theme.palette.grayscale(20),
    display: 'flex',
    fontSize: 16,
    minWidth: 16
  },
  menuContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.grayscale(20),
    fontSize: 12,
    fontWeight: 400
  },
  menuText: {
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  menuIcon: {
    color: theme.palette.grayscale(60),
    paddingRight: 10
  },
  subMenuPaper: {
    background: theme.palette.grayscale(100),
    marginLeft: 16
  },
  menuTopBorder: {
    borderTop: `1px solid ${theme.palette.grayscale(90)}`
  },
  defaultBtnContainer: {
    paddingLeft: 8,
    paddingRight: 8
  },
  checked: {
    '& + $track': {
      backgroundColor: theme.palette.grayscale(94)
    }
  },
  colorSecondary: {
    '&$checked + $track': {
      backgroundColor: theme.palette.grayscale(94)
    },
    '&$checked': {
      top: '-2px'
    }
  },
  switchBase: {
    color: theme.palette.grayscale(60)
  },
  track: {
    backgroundColor: theme.palette.grayscale(94)
  },
  changeLabelStyles: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8
  }
}));

export default styles;
