import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { MUIForm } from '@buildhero/sergeant';
import { Modal, AlgoliaSearch } from 'components';
import bundleForm from 'meta/Settings/Inventory/bundle/bundleForm';
import arrayToObject from 'utils/arrayToObject';
import { templateTypes } from 'constants/bundle';
import { bundleIndex } from 'constants/algoliaIndex';
import { algoliaFilters } from 'constants/inventoryItem';
import { addBundle, updateBundle } from './service';

const ItemsSearch = ({ ...props }) => {
  const {
    field,
    form,
    options: { label }
  } = props;
  const handleChange = value => form.setFieldValue(field.name, value[field.name]);

  return (
    <Fragment>
      <Typography variant="body2" style={{ fontWeight: 'normal', fontSize: 10 }} gutterBottom>
        {label}
      </Typography>

      <AlgoliaSearch
        searchIndex={bundleIndex}
        name="selectBundle"
        locatedInPopUp
        placeholder=""
        displayText={['name', 'description']}
        delimiter=" - "
        value={field.value || ''}
        filters={algoliaFilters}
        handleChange={handleChange}
        overrideVariant="outlined"
      />
    </Fragment>
  );
};

const customComponents = {
  ItemsSearch
};

const formActions = (onSave, onCancel) => ({
  cancel: {
    buttonType: 'contained',
    color: 'secondary',
    label: 'Cancel',
    action: onCancel
  },
  save: {
    buttonType: 'contained',
    color: 'primary',
    action: onSave,
    label: 'Save'
  }
});

export default function Form({
  addRefreshCount,
  isEdit,
  user,
  snackbarOn,
  isVisible,
  handleFormClose,
  data
}) {
  const [MUIService, setMUIService] = useState();
  if (!isVisible) return null;
  const formConfig = bundleForm(templateTypes);
  const invokeFormSubmit = () => MUIService && MUIService.submit();
  const formFields = Object.keys(formConfig.fields);

  const handleOnSave = data => {
    const successCallback = () => {
      handleFormClose();
      addRefreshCount();
    };
    const action = isEdit ? updateBundle : addBundle;
    const bundle = {
      id: data.id,
      name: data.name,
      type: data.type || '',
      version: data.version,
      category: data.category || '',
      subCategory: data.subCategory || '',
      description: data.description || ''
    };
    action({
      user,
      bundle,
      snackbarOn,
      successCallback
    });
  };

  return (
    <Modal
      open={isVisible}
      showModalHeader
      handleClose={handleFormClose}
      modalTitle={isEdit ? 'Edit Bundle' : 'New Bundle'}
      buttons={formActions(invokeFormSubmit, handleFormClose)}
      width="960"
    >
      <MUIForm
        configuration={formConfig}
        customComponents={customComponents}
        data={!isEdit ? arrayToObject(formFields) : data}
        onCreateService={formService => setMUIService(formService)}
        onComplete={handleOnSave}
      />
    </Modal>
  );
}

Form.propTypes = {
  isVisible: PropTypes.bool,
  isEdit: PropTypes.bool,
  data: PropTypes.object,
  addRefreshCount: PropTypes.func.isRequired,
  handleFormClose: PropTypes.func.isRequired
};

Form.defaultProps = {
  isVisible: true,
  isEdit: false,
  data: {}
};
