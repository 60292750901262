import gql from 'graphql-tag';

const updateCustomerProperty = gql`
  mutation updateCustomerProperty($input: UpdateCustomerPropertyInput!) {
    updateCustomerProperty(input: $input) {
      id
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      version
      status
      isActive
    }
  }
`;

export default updateCustomerProperty;
