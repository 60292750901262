import gql from 'graphql-tag';

const reviewTimesheetEntries = gql`
  mutation reviewTimesheetEntries($partitionKey: String, $data: ReviewTimesheetEntriesInput!) {
    reviewTimesheetEntries(partitionKey: $partitionKey, data: $data) {
      id
      version
    }
  }
`;

export default reviewTimesheetEntries;
