import gql from 'graphql-tag';

const getCompanyActiveTechsWindowed = gql`
  query getCompanyActiveTechsWindowed(
    $partitionKey: String!
    $sortKey: String!
    $offscheduleStart: Int
    $offscheduleEnd: Int
    $heartbeatStart: Int
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      logoUrl
      companyName
      email
      fax
      phonePrimary
      websiteUrl
      status
      tenantId
      partitionKey
      sortKey
      hierarchy
      entityType
      employees: employeesView(
        entityConnection: "Employee"
        filter: {
          stringFilters: [{ fieldName: "status", filterInput: { eq: "active" } }]
          booleanFilters: [{ fieldName: "isTech", filterInput: { eq: true } }]
        }
      ) {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          firstName
          lastName
          name
          email
          personalEmail
          status
          cellPhone
          landlinePhone
          profilePictureUrl
          contactAddresses(entityConnection: "ContactAddress", limit: 1) {
            items {
              addressType
              addressLine1
              addressLine2
              city
              county
              state
              zipcode
              country
              latitude
              longitude
            }
          }
          devices(entityConnection: "Device") {
            items {
              id
              isActive
            }
          }
          offSchedules(
            entityConnection: "OffSchedule"
            filter: { endTime: { gt: $offscheduleStart }, startTime: { lt: $offscheduleEnd } }
          ) {
            items {
              offDate
              offReason
              version
              startTime
              endTime
              hierarchy
              sortKey
              partitionKey
              entityType
              id
              tenantCompanyId
            }
          }
          heartbeats(
            entityConnection: "Heartbeat"
            filter: { timestamp: { gt: $heartbeatStart } }
          ) {
            items {
              id
              latitude
              longitude
              expiresAt
              timestamp
            }
          }
          departments(entityConnection: "Department") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              invertedSortKey
              hierarchy
              entityType
              mappedEntity {
                ... on Department {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  tagName
                  tagType
                }
              }
            }
          }
        }
      }
      departments: departmentsView(entityConnection: "Department") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          tagName
          tagType
          skills(entityConnection: "Skill") {
            items {
              mappedEntity {
                ... on Skill {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  tagName
                  tagType
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getCompanyActiveTechsWindowed;
