import * as Yup from 'yup';
import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';
import { departmentSearch, getDepartments } from 'services/API/department';
// import { ledgerAccountAndItemGlGroupSearch } from 'services/API/ledgerAccount';
import { productSearch, getProducts } from 'services/API/product';

const defaultFlexStyle = '0 0 195px';
const wideItemFlexStyle = '0 0 617px';
const margin = '0px 8px 16px 8px';

const sovFields = () =>
  Yup.object().shape({
    number: Yup.string(),
    contractValue: Yup.number().required('Field is required'),
    glAccount: Yup.object().shape({}),
    Product: Yup.object().shape({
      name: Yup.string().required('Field is required')
    }),
    department: Yup.object().shape({
      tagName: Yup.string().required('Field is required')
    }),
    description: Yup.string().required('Field is required')
  });

const sovContents = handleProductSelection => [
  // the 1st row
  {
    options: {
      direction: 'row',
      flexWrap: 'nowrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'CustomFieldWithLabelStyled',
              edit: 'CustomFieldWithLabelStyled'
            },
            source: 'number',
            options: {
              label: 'Item Number',
              placeholder: '',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true
            }
          }
        ]
      }
    ]
  },
  // the 2nd row
  {
    options: {
      direction: 'row'
    },
    contents: [
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar',
              edit: 'SearchBar'
            },
            source: 'Product',
            options: {
              label: 'Item Name',
              searchFunction: productSearch,
              emptySearchFunction: getProducts,
              resultFormatFunction: result => result.name,
              onSelect: handleProductSelection,
              placeholder: ' ',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true
            }
          }
        ]
      }
    ]
  },
  // 3rd row
  {
    options: {
      direction: 'row'
    },
    contents: [
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            component: {
              default: 'CurrencyInput',
              edit: 'CurrencyInput'
            },
            source: 'contractValue',
            options: {
              label: 'Contract Value',
              placeholder: '',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true,
              min: 0
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar',
              edit: 'SearchBar'
            },
            source: 'department',
            options: {
              label: 'Department',
              searchFunction: departmentSearch,
              emptySearchFunction: getDepartments,
              resultFormatFunction: dept => dept.tagName,
              placeholder: ' ',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true,
              errorText: 'Field is required'
            }
          }
        ]
      }
    ]
  },
  // the 4th row
  {
    options: {
      direction: 'row',
      flexWrap: 'nowrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: wideItemFlexStyle,
          margin: '0px 8px 0px 8px'
        },
        contents: [
          {
            component: {
              default: 'TextInput',
              edit: 'TextInput'
            },
            source: 'description',
            options: {
              label: 'Description of Work',
              placeholder: 'Enter a description...',
              fullWidth: true,
              lines: 2,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true,
              maxLength: 250
            }
          }
        ]
      }
    ]
  }
];

const sovAddItemFormLayout = (isIntegratedAccount, handleProductSelection) => {
  const fields = sovFields(isIntegratedAccount);
  return {
    fields: generateDefaultFieldsObject(fields),
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          width: 632
        },
        contents: sovContents(handleProductSelection)
      },
      edit: {
        options: {
          pageSize: 'LETTER',
          width: 632
        },
        contents: sovContents(handleProductSelection)
      }
    }
  };
};

export { sovFields, sovAddItemFormLayout };
