import React, { useState } from 'react';
import ErrorBoundaries from 'scenes/Error';
import { ThemeProvider, Select } from '@buildhero/sergeant';

function AssetSelect(props) {
  const { options } = props;
  const {
    valueSet,
    taskGroupAndSet: [taskGroupAndSet],
    updateService,
    updateServiceFields: [updateServiceFields]
  } = options;
  const { taskGroups, taskIndex } = taskGroupAndSet;
  const currentTask = taskGroups[0][taskIndex];

  const [asset, setAsset] = useState(currentTask.asset);
  const [loading, setLoading] = useState(false);
  const onSelect = async selectedAsset => {
    if (selectedAsset?.id !== asset?.id) {
      setAsset(selectedAsset);
      setLoading(true);
      await updateService(updateServiceFields.ASSET)(selectedAsset);
      setLoading(false);
    }
  };

  const selectOptions = valueSet.map(elem => ({ ...elem, label: elem.assetName, value: elem.id }));

  return (
    <>
      <ErrorBoundaries>
        <ThemeProvider>
          <Select
            label="Asset"
            searchable
            loading={loading}
            options={selectOptions}
            onChange={onSelect}
            defaultValue={selectOptions.find(opt => opt.id === asset?.id)}
          />
        </ThemeProvider>
      </ErrorBoundaries>
    </>
  );
}

export default AssetSelect;
