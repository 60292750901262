import gql from 'graphql-tag';

const getJobsByCustomer = gql`
  query getCustomer(
    $partitionKey: String!
    $sortKey: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getCustomer(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      job: jobsView(
        entityConnection: "Job"
        pagination: { limit: $limit, offset: $offset }
        filter: $filter
        sorting: $sort
        includeComputedColumns: true
      ) {
        nextToken
        items {
          id
          jobNumber
          customIdentifier
          customerProvidedJobNumber
          customerId
          customerName
          customerSortKey
          issueDescription
          jobTypeName
          jobTypeInternal
          customerPropertyName
          customerPropertyId
          customerPropertySortKey
          createdDate
          amountQuoted
          amountNotToExceed
          status
          createdBy
          jobJobTags(entityConnection: "JobJobTag") {
            items {
              id
              entityType
              mappedEntity: jobTag {
                id
                entityType
                tagName
              }
            }
          }
          owner(entityConnection: "owner") {
            id
            name
            firstName
            lastName
          }
          outstandingBalanceComputed
          overdueBalanceComputed
          visits: visitsView(entityConnection: "Visit") {
            items {
              departmentName
              visitNumber
              description
              prerequisites
              prerequisitesAcknowledged
              departmentName
              scheduledFor
              status
              primaryTechs(entityConnection: "VisitPrimaryTech") {
                items {
                  sortKey
                  invertedSortKey
                  mappedEntity {
                    ... on Employee {
                      id
                      tenantId
                      tenantCompanyId
                      partitionKey
                      sortKey
                      partitionKey
                      hierarchy
                      entityType
                      firstName
                      lastName
                      email
                    }
                  }
                }
              }
            }
          }
          properties: parentEntity {
            ... on CustomerProperty {
              id
              companyName
              companyAddresses(entityConnection: "CompanyAddress") {
                items {
                  addressLine1
                  addressLine2
                  city
                  state
                  zipcode
                }
              }
              customer: parentEntity {
                ... on Customer {
                  id
                  customerName
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default getJobsByCustomer;
