import React, { useState, useRef, useEffect } from 'react';

import { InputAdornment, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

const EditableInputWrapper = ({
  children,
  isMarkup = false,
  isUnitPrice = false,
  setRowsEditingUnitPrice,
  row,
  rowIsLoading,
  setRowIsLoading
}) => {
  const inputRef = useRef();
  const buttonRef = useRef();
  const [shouldBeFocused, setShouldBeFocused] = useState(false);
  const [isDisabled, setIsDisabled] = useState(isUnitPrice);

  useEffect(() => {
    if (shouldBeFocused) {
      inputRef.current.focus();
    }
    return () => {
      if (isUnitPrice) {
        setRowsEditingUnitPrice(prevRowsEditingUnitPrice => [...prevRowsEditingUnitPrice, row?.id]);
      } else {
        setRowsEditingUnitPrice(prevRowsEditingUnitPrice =>
          prevRowsEditingUnitPrice.filter(id => id !== row.id)
        );
      }
    };
  }, [shouldBeFocused, isUnitPrice, row.id, setRowsEditingUnitPrice]);

  useEffect(() => {
    if (!shouldBeFocused && isUnitPrice) {
      setIsDisabled(!row?.rowsEditingUnitPrice?.includes(row?.id));
    } else if (!shouldBeFocused && !isUnitPrice) {
      setIsDisabled(row?.rowsEditingUnitPrice?.includes(row?.id));
    }
  }, [row, isUnitPrice]);

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      if (rowIsLoading?.includes(row?.id)) {
        e.preventDefault();
      }
    }
  };

  return React.Children.map(children, child => {
    return React.cloneElement(child, {
      disabled: isDisabled,
      inputRef,
      onBlur: value => {
        child?.props?.onBlur(value);
        setShouldBeFocused(false);
      },
      onFocus: () =>
        setRowIsLoading(prev => {
          if (!prev?.includes(row.id) && !row?.readOnly) {
            return [...prev, row?.id];
          }
          return prev;
        }),
      endAdornment: !row?.readOnly && isDisabled && (
        <InputAdornment position="end" disablePointerEvents={rowIsLoading?.includes(row?.id)}>
          <IconButton
            edge="end"
            onClick={() => {
              setIsDisabled(false);
              setShouldBeFocused(true);
            }}
            onKeyPress={handleKeyPress}
            size="small"
            ref={buttonRef}
          >
            {isDisabled ? <EditIcon fontSize="small" /> : isMarkup && '%'}
          </IconButton>
        </InputAdornment>
      )
    });
  });
};

export default EditableInputWrapper;
