import React from 'react';
import { withStyles } from '@material-ui/core';
import actions from 'meta/Scheduler/actions';
import VisitActionButton from '../VisitActionButton';
import styles from './styles';

const VisitActions = ({ classes, visitActions, handleVisitUpdate, inProgressAction }) => (
  <div className={classes.root}>
    <div className={classes.actionButtons}>
      {visitActions.map(action => (
        <div key={action} style={{ margin: '5px 5px' }}>
          <VisitActionButton
            icon={actions[action].icon}
            title={actions[action].title}
            color={actions[action].color}
            isLoading={action === inProgressAction}
            handleClick={() => handleVisitUpdate(action)}
            shouldDisplay
          />
        </div>
      ))}
    </div>
  </div>
);

export default withStyles(styles)(VisitActions);
