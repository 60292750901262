/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';
import { connect } from 'react-redux';

import { Modal, UserPermission } from 'components';
import Context from 'components/Context';
import { itemsLayout } from 'meta/Items';
import { bundlesLayout } from 'meta/Products';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { CompanyService, PricebookService, QuickbooksService } from 'services/core';
import { Logger } from 'services/Logger';
import { convertStringToFloat, getBooleanValue, getTenantSettingValueForKey } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';
import { AccountingApp, SyncStatus } from 'utils/constants';
import { constructSelectOptions, filterIsActive } from 'utils/constructSelectOptions';
import exportToFile from 'utils/export';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import InventoryPageHeader from '../InventoryPageHeader';

import AccountingService from './accountingService';
import AddToBundle from './AddToBundle';
import Form from './Form';
import formActions from './formActionConfig';

import ProductList from './ProductList';

class ProductsList extends Component {
  constructor(props) {
    super(props);
    this.PricebookService = new PricebookService();
    this.QuickbooksService = new QuickbooksService();
    this.AccountingService = new AccountingService();
    this.CompanyService = new CompanyService();
    this.mounted = props.mounted || true;
    this.state = {
      openAddItem: false,
      incomeAccounts: [],
      expenseAccounts: [],
      groupList: [],
      vendors: [],
      mode: 'new',
      record: '',
      updatedData: '',
      isQuickBooksEnabled: false,
      isIntacctEnabled: false,
      openAddProductToBundle: false,
      spinner: true,
      productBundle: '',
      refreshCount: 0,
      resetToFirstPage: 0,
      addInventoryItemFormService: '',
      unitOfMeasureAsOptions: '',
      modalSpinnerStatus: false
    };
  }

  componentDidMount = async () => {
    this.getVendors();
    await this.queryAndSetSalesAccounts();
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  queryAndSetSalesAccounts = async () => {
    let unitOfMeasureAsOptions;
    const accountingApp = getTenantSettingValueForKey('accountingApp');
    const isQuickBooksEnabled = accountingApp === AccountingApp.QUICKBOOKS;
    const isIntacctEnabled = accountingApp === AccountingApp.INTACCT;
    try {
      if (!this.props.user.tenantId) {
        return;
      }
      const { unitOfMeasures } = await Context.getCompanyContext().getCompany;
      unitOfMeasureAsOptions = (unitOfMeasures?.items || []).map(uom => ({
        label: uom.name,
        value: uom.id
      }));
      const { user } = this.props;
      // This block pulls all General Ledger Accounts with income type either "Income" or "Expense"
      if (isQuickBooksEnabled) {
        const { data } = await this.QuickbooksService.getGLAccountsByType(
          user.tenantId,
          `${user.tenantId}_Company_${user.tenantCompanyId}`
        );
        if (data && this.mounted) {
          const incomeAccounts = data?.getCompany?.incomeAccounts?.items || [];
          this.setState(prevState => ({
            ...prevState,
            incomeAccounts
          }));
          const expenseAccounts = data?.getCompany?.expenseAccounts?.items || [];
          this.setState(prevState => ({
            ...prevState,
            expenseAccounts
          }));
        }
      }
      if (isIntacctEnabled) {
        let nextToken = null;
        do {
          // eslint-disable-next-line no-await-in-loop
          const { data } = await this.AccountingService.fetchGLGroups(
            user.tenantId,
            `${user.tenantId}_Company_${user.tenantCompanyId}`,
            nextToken
          );
          if (data && this.mounted) {
            const groupList = data?.getCompany?.itemGlGroups?.items || [];
            nextToken = data.getCompany?.itemGlGroups?.nextToken;
            this.setState(prevState => ({
              ...prevState,
              groupList
            }));
          }
        } while (nextToken);
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to fetch GL accounts, please try again later');
    }

    if (this.mounted && this.state.spinner) {
      this.setState(prevState => ({
        ...prevState,
        isQuickBooksEnabled,
        isIntacctEnabled,
        unitOfMeasureAsOptions,
        spinner: false
      }));
    }
  };

  getVendors = async () => {
    if (!this.props.user.tenantId) {
      return;
    }
    let data;
    try {
      const response = await this.CompanyService.getVendors(
        this.props.user.tenantId,
        `${this.props.user.tenantId}_Company_${this.props.user.tenantCompanyId}`
      );

      if (response.data && response.data.getCompany && response.data.getCompany.vendors) {
        data = response.data.getCompany.vendors.items || [];
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to fetch vendors');
    }

    this.setState(prevState => ({ ...prevState, vendors: data || [] }));
  };

  handleAdd = formName => {
    this.setState({ [formName]: true, mode: 'new' });
  };

  handleClose = formName => {
    this.setState({ [formName]: false, mode: 'new', record: '' });
  };

  handleExportInventoryList = async (columns, filter, sortBy, sortOrder) => {
    const { user } = this.props;

    const variables = {
      partitionKey: user.tenantId,
      sortKey: `${user.tenantId}_Company_${user.tenantCompanyId}`,
      filter
    };
    if (sortBy && sortOrder) {
      variables.sort = [
        {
          sortField: sortBy,
          sortDirection: sortOrder
        }
      ];
    }

    await exportToFile('export.xlsx', variables, 'InventoryList', columns, this.props.snackbarOn);
  };

  composeDepartmentsForUpdate = (departmentIdList = [], departments) => {
    if (!departments) return [];
    const deptFields = [
      'id',
      'sortKey',
      'entityType',
      'tenantId',
      'tagName',
      'tagType',
      'accountingRefId',
      'accountingRefIdOfClass'
    ];

    const formattedDepts = departmentIdList.map(selectedId => {
      const selectedDepartment = departments.find(dep => dep.id === selectedId);
      return _.pick(selectedDepartment, deptFields);
    });
    return formattedDepts;
  };

  updateProduct = async (values, departments) => {
    const { user } = this.props;
    const { isIntacctEnabled } = this.state;
    const syncImmediately = getTenantSettingValueForKey('syncImmediately');
    const payload = {};
    const updateProductPayload = {
      id: values.id,
      name: values.name || null,
      code: values.code || null,
      glIncomeAccountRefId: values.glIncomeAccountRefId || null,
      glExpenseAccountRefId: values.glExpenseAccountRefId || null,
      taxable: getBooleanValue(values.taxable),
      unitCost: convertStringToFloat(values.unitCost) || 0,
      unitOfMeasureId: values?.unitOfMeasureId || null,
      description: values.description || null,
      costCodeId: values.costCodeId || undefined,
      jobCostTypeId: values.jobCostTypeId || undefined,
      revenueTypeId: values.revenueTypeId || undefined,
      version: values.version,
      departments: this.composeDepartmentsForUpdate(values.departments, departments) || undefined,
      productCategoryId: values.productCategoryId || null
    };

    if (isIntacctEnabled) {
      updateProductPayload.itemGlGroupId = values.itemGlGroupId || null;
    }

    if (syncImmediately) {
      updateProductPayload.syncStatus = SyncStatus.SYNCING;
    } else {
      updateProductPayload.syncStatus = null;
    }

    if (!values.isActive) {
      updateProductPayload.isActive = false;
    }

    if (values.vendorSortKey) {
      updateProductPayload.productVendors = [
        {
          vendorSortKey: values.vendorSortKey || undefined,
          unitCost: convertStringToFloat(values.unitCost) || 0
        }
      ];
    }

    payload.products = [updateProductPayload];
    payload.companyId = user.tenantCompanyId;

    try {
      const { data } = await this.PricebookService.updateProduct(user.tenantId, payload);
      if (data) {
        this.setState(prevState => ({
          ...prevState,
          openAddItem: false,
          refreshCount: prevState.refreshCount + 1,
          modalSpinnerStatus: false
        }));
      }
      this.props.snackbarOn('success', `Successfully updated item - ${values.name}`);
    } catch (error) {
      Logger.error(error);
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        this.props.snackbarOn('error', error.graphQLErrors[0].message);
      } else {
        this.props.snackbarOn('error', 'Unable to save item, please try again later');
      }
      this.setState(prevState => ({
        ...prevState,
        modalSpinnerStatus: false
      }));
    }
  };

  handleProductRowactions = (actionName, record) => {
    switch (actionName) {
      case 'edit':
        this.setState({ openAddItem: true, mode: 'edit', record });
        break;
      case 'addToBundle':
        this.setState({ openAddProductToBundle: true, record });
        break;
      default:
        break;
    }
  };

  addProducts = async (values, departments) => {
    const { addInventoryItemFormService, isIntacctEnabled } = this.state;
    const syncImmediately = getTenantSettingValueForKey('syncImmediately');
    if (addInventoryItemFormService) {
      addInventoryItemFormService.submit();
      if (values) {
        const { user } = this.props;
        let payload = {
          name: values.name || undefined,
          code: values.code || undefined,
          taxable: values.taxable || false,
          glIncomeAccountRefId: values.glIncomeAccountRefId || undefined,
          glExpenseAccountRefId: values.glExpenseAccountRefId || undefined,
          unitCost: convertStringToFloat(values.unitCost) || 0,
          description: values.description || undefined,
          tenantCompanyId: user.tenantCompanyId,
          costCodeId: values.costCodeId || undefined,
          jobCostTypeId: values.jobCostTypeId || undefined,
          revenueTypeId: values.revenueTypeId || undefined,
          unitOfMeasureId: values?.unitOfMeasureId || undefined,
          departments:
            this.composeDepartmentsForUpdate(values.departments, departments) || undefined,
          productCategoryId: values.productCategoryId || null
        };
        if (isIntacctEnabled) {
          payload.itemGlGroupId = values.itemGlGroupId || undefined;
        }
        if (values.vendorSortKey) {
          payload = {
            ...payload,
            productVendors: [
              {
                vendorSortKey: values.vendorSortKey || undefined,
                unitCost: convertStringToFloat(values.unitCost) || 0
              }
            ]
          };
        }
        if (syncImmediately) {
          payload.syncStatus = SyncStatus.SYNCING;
        } else {
          payload.syncStatus = null;
        }
        try {
          const { data } = await this.QuickbooksService.addNonInventoryProduct(
            user.tenantId,
            payload
          );
          if (data) {
            this.setState(prevState => ({
              ...prevState,
              openAddItem: false,
              refreshCount: prevState.refreshCount + 1,
              resetToFirstPage: prevState.refreshCount + 1, // Memoization for the product list to reset to page 0
              modalSpinnerStatus: false
            }));
          }
          this.props.snackbarOn('success', `Successfully added item - ${values.name}`);
        } catch (error) {
          Logger.error(error);
          if (error.graphQLErrors && error.graphQLErrors.length > 0) {
            this.props.snackbarOn('error', error.graphQLErrors[0].message);
          } else {
            this.props.snackbarOn('error', 'Unable to save item, please try again later');
          }
          this.setState(prevState => ({
            ...prevState,
            modalSpinnerStatus: false
          }));
        }
      }
    }
  };

  getFormattedData = () => {
    const formattedData = {
      name: '',
      description: '',
      vendorSortKey: '',
      code: '',
      unitCost: '',
      itemMarkup: ''
    };
    return formattedData;
  };

  handleOnSave = (data, departments) => {
    const { mode } = this.state;
    if (mode === 'edit') {
      return this.updateProduct(data, departments);
    }
    return this.addProducts(data, departments);
  };

  invokeFormSubmit = () => {
    const { addInventoryItemFormService } = this.state;
    if (addInventoryItemFormService) {
      addInventoryItemFormService.submit();
    }
  };

  render() {
    const {
      vendors,
      isIntacctEnabled,
      incomeAccounts,
      expenseAccounts,
      groupList,
      mode,
      record,
      openAddProductToBundle,
      refreshCount,
      resetToFirstPage,
      unitOfMeasureAsOptions
    } = this.state;
    const { productTableMeta, disableBundle, departments, flags } = this.props;
    const isProductCategoriesEnabled = flags[FeatureFlags.PRODUCT_CATEGORIES_ENABLED];
    const itemsFormLayout = itemsLayout.entity.layouts.web;
    const bundlesFormLayout = bundlesLayout.entity.layouts.web;
    itemsFormLayout.buttons.cancel.action = () => this.handleClose('openAddItem');
    bundlesFormLayout.buttons.cancel.action = () => this.handleClose('openAddBundle');

    const handleInvokeFormSubmit = () => this.invokeFormSubmit();
    const handleDialogClose = () => this.handleClose('openAddItem');

    const departmentIds = (record?.departments || []).map(
      d => JSON.parse(record.departmentData).find(data => data.tagName === d)?.id
    );
    const formattedData =
      mode === 'edit'
        ? {
            ...record,
            departments: departmentIds
          }
        : this.getFormattedData();

    const productRowButtons = {
      edit: {
        label: 'Edit item',
        icon: 'BorderColor',
        caslKey: PermissionConstants.OBJECT_INVENTORTY,
        caslAction: 'update'
      }
    };

    if (!disableBundle && this.props?.flags?.truckStockInventory) {
      productRowButtons.addToBundle = {
        label: 'Add to bundle',
        icon: 'AddCircleOutlineOutlined',
        caslKey: PermissionConstants.OBJECT_INVENTORTY,
        caslAction: 'create'
      };
    }

    const selectNoneOption = { label: 'None', value: undefined };
    const vendorsList =
      vendors &&
      !_.isEmpty(vendors) &&
      vendors
        .filter(vendor => vendor.isActive)
        .map(vendor => ({ label: vendor.name, value: vendor.sortKey }));
    if (vendorsList?.length > 0) {
      vendorsList?.unshift(selectNoneOption);
    }
    const incomeAccountsList = constructSelectOptions(incomeAccounts, 'name', 'id');
    const expenseAccountList = constructSelectOptions(expenseAccounts, 'name', 'id');

    return (
      <ErrorBoundaries>
        <UserPermission I="create" action={PermissionConstants.OBJECT_INVENTORTY}>
          <InventoryPageHeader
            btnLabel="new item"
            handleAdd={() => this.handleAdd('openAddItem')}
          />
        </UserPermission>
        <ProductList
          productRowButtons={productRowButtons}
          handleActions={this.handleProductRowactions}
          refreshCount={refreshCount}
          key={resetToFirstPage}
          vendors={vendors}
          isProductCategoriesEnabled={isProductCategoriesEnabled}
        />
        <Modal
          open={this.state.openAddItem}
          handleClose={handleDialogClose}
          width="960"
          showModalHeader
          modalTitle={mode === 'edit' ? 'Edit item' : 'New Item'}
          buttons={formActions(handleInvokeFormSubmit, handleDialogClose)}
          spinnerStatus={this.state.modalSpinnerStatus}
        >
          <Form
            departments={departments}
            data={formattedData}
            vendorsList={vendorsList}
            isIntacctEnabled={isIntacctEnabled}
            groupList={filterIsActive(
              constructSelectOptions(groupList),
              formattedData?.itemGlGroupId
            )}
            incomeAccountsList={incomeAccountsList}
            expenseAccountList={expenseAccountList}
            snackbarOn={this.props.snackbarOn}
            tenantId={this.props.user.tenantId}
            tenantCompanyId={this.props.user.tenantCompanyId}
            unitOfMeasureAsOptions={unitOfMeasureAsOptions}
            onCreateService={service => this.setState({ addInventoryItemFormService: service })}
            onSave={async data => {
              this.setState({ modalSpinnerStatus: true });
              this.handleOnSave(data, departments);
            }}
            isProductCategoriesEnabled={isProductCategoriesEnabled}
          />
        </Modal>
        <AddToBundle
          isVisible={openAddProductToBundle}
          handleFormClose={() => this.setState({ openAddProductToBundle: false, record: {} })}
          product={record}
        />
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedProductsList = connect(
  mapStateToProps,
  mapDispatcherToProps
)(withLDConsumer()(ProductsList));

export default reduxConnectedProductsList;
