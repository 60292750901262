export const jobCostingRows = [
  {
    id: 'costCode',
    numeric: false,
    label: 'Cost Code'
  },
  {
    id: 'costDescription',
    label: 'Description',
    isCustom: true,
    type: 'CostDescription',
    numeric: false
  },
  {
    id: 'revenue',
    label: 'Revenue',
    numeric: true,
    type: 'currency'
  },
  {
    id: 'cost',
    label: 'Cost',
    numeric: true,
    type: 'currency'
  },
  {
    id: 'profit',
    label: 'Gross Profit',
    numeric: false,
    type: 'currency'
  },
  {
    id: 'grossMargin',
    type: 'percentage',
    numeric: true,
    label: 'Gross Margin'
  }
];

export const revenueRows = [
  {
    id: 'costCode',
    label: 'Revenue Cost Type',
    isCustom: true,
    type: 'CostTypeLabel',
    numeric: false,
    disableSort: true
  },
  {
    id: 'total',
    label: 'Amount',
    fieldType: 'currency',
    isCustom: true,
    type: 'Total',
    numeric: true,
    disableSort: true
  }
];

export const costRows = [
  {
    id: 'jobCostType',
    label: 'Job Cost Type',
    isCustom: true,
    type: 'CostTypeLabel',
    numeric: false,
    disableSort: true
  },
  {
    id: 'revenueTotal',
    label: '% of Revenue',
    isCustom: true,
    fieldType: 'percentage',
    type: 'Total',
    numeric: false,
    disableSort: true
  },
  {
    id: 'total',
    label: 'Amount',
    isCustom: true,
    fieldType: 'currency',
    type: 'Total',
    numeric: true,
    disableSort: true
  }
];

export default jobCostingRows;
