import gql from 'graphql-tag';

const createInvoiceTag = gql`
  mutation addInvoiceTagsToCompany(
    $partitionKey: String!
    $companyId: String!
    $data: [CompanyInvoiceTagInput]!
  ) {
    addInvoiceTagsToCompany(
      partitionKey: $partitionKey
      data: { companyId: $companyId, invoiceTags: $data }
    ) {
      id
      tagType
      tagName
      sortOrder
    }
  }
`;

export default createInvoiceTag;
