import { useExtendedFetchForPMAPI as useExtendedFetch } from 'customHooks/useExtendedFetch';

export const useFetchEmails = (parentId, options = {}) => {
  return useExtendedFetch(
    {
      url: `sendemail/byparent/${parentId}`,
      params: { include: '*' }
    },
    {
      errorData: [],
      defaultData: [],
      ...options
    }
  );
};
