import { makeStyles } from '@material-ui/core/styles';

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold'
  },
  formContainer: {
    width: '100%',
    '& .MuiInputBase-input': {
      fontSize: 14
    },
    '& .MuiTypography-caption': {
      color: theme.palette.grayscale(60)
    },
    '& .placeholder': {
      color: theme.palette.grayscale(60)
    },
    '& .MuiInputLabel-shrink': {
      color: theme.palette.grayscale(60),
      fontWeight: 400,
      fontSize: 13
    },
    '& .MuiInputBase-root': {
      minHeight: '40px'
    },
    '& .MuiTypography-body1, .MuiTypography-body2': {
      fontSize: 14
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 12
    },
    '& .MuiCheckbox-root': {
      padding: '0 9px'
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  },
  container: {
    maxWidth: '938px',
    boxSizing: 'content-box',
    border: `1px solid ${theme.palette.grayscale(80)}`
  },
  containerMarked: {
    maxWidth: '938px',
    boxSizing: 'content-box',
    border: `1px solid ${theme.palette.brand.green}`
  },
  hr: {
    color: theme.palette.grayscale(60)
  },
  hrContainer: {
    boxSizing: 'content-box',
    padding: '20px 8px 24px 8px'
  },
  customSelectRoot: {
    fontWeight: 500,
    padding: '11px 8px !important'
  },
  titleChip: {
    background: theme.palette.grayscale(20),
    color: theme.palette.grayscale(100),
    textTransform: 'uppercase',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  expandIcon: {
    cursor: 'pointer',
    marginTop: theme.spacing(0.2),
    fill: theme.palette.grayscale(20)
  },
  closeIcon: {
    cursor: 'pointer',
    marginRight: '13px'
  },
  warningIcon: {
    width: '20px',
    fill: theme.palette.grayscale(20)
  },
  checkCircleIcon: {
    width: '20px',
    fill: theme.palette.brand.green
  },
  scheduleTextContainer: {
    display: 'flex',
    marginBottom: '20px',
    marginLeft: '10px'
  },
  scheduleText: {
    marginLeft: '5px',
    marginTop: '2.5px'
  },
  vertIconButton: {
    padding: 0,
    paddingLeft: 4,
    cursor: 'pointer'
  },
  scheduleIcon: {
    fill: theme.palette.grayscale(100)
  },
  overAllCount: {
    color: theme.palette.grayscale(60)
  },
  tags: {
    background: theme.palette.brand.logoGreen,
    color: theme.palette.grayscale(20),
    textTransform: 'capitalize',
    padding: theme.spacing(0.3),
    paddingLeft: theme.spacing(0.75),
    paddingRight: theme.spacing(0.75),
    borderRadius: 4,
    marginRight: theme.spacing(0.75)
  },
  dialogContent: {
    [theme.breakpoints.up('md')]: {
      minWidth: 550,
      maxWidth: 550
    }
  },
  header: {
    marginBottom: 20
  },
  description: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '20px',
    letterSpacing: 'normal',
    color: '#808080'
  }
}));
