import React, { useMemo } from 'react';
import ErrorBoundaries from 'scenes/Error';
import PropTypes from 'prop-types';
import AmplifyService from 'services/AmplifyService';
import { ResponsiveTable } from 'components';
import { connect } from 'react-redux';
import { snackbarOn } from 'redux/actions/globalActions';
import { getTenantSettingValueForKey, logErrorWithCallback } from 'utils';
import { AccountingApp, PricingStrategy } from 'utils/constants';
import { pricebookEntryColumns } from 'meta/Items/columns';
import { getPricebookEntries } from 'scenes/Settings/Pricebooks/PricebookDetail/gql';

const MaterialItems = ({ pricebookId, user, snackbarOn: snackbar, settings }) => {
  const isMarginEnabled =
    settings.pricingStrategy === PricingStrategy.MARGIN ||
    settings.pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN;
  const isMarkupEnabled =
    settings.pricingStrategy === PricingStrategy.MARKUP ||
    settings.pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN;
  const isQuickBooksEnabled = useMemo(
    () => getTenantSettingValueForKey('accountingApp') === AccountingApp.QUICKBOOKS,
    // eslint-disable-next-line
    [user]
  );
  const { client } = useMemo(() => AmplifyService.appSyncClient(), []);

  const getEntries = async (filter, limit, offset, sortField, sortDirection) => {
    if (!user.tenantId) {
      return { items: [], nextToken: null };
    }
    try {
      const { data } = await client.query({
        query: getPricebookEntries,
        variables: {
          id: pricebookId,
          filter,
          limit,
          offset,
          sort: (sortField && sortDirection && { sortField, sortDirection }) || undefined
        }
      });

      const { items, nextToken } = data.getPricebookById?.priceBookEntriesView;
      return { items, nextToken };
    } catch (error) {
      logErrorWithCallback(
        error,
        snackbar,
        'Unable to fetch material items, please try again later'
      );
      return { items: [], nextToken: null };
    }
  };

  return (
    <ErrorBoundaries>
      <div style={{ marginTop: 5 }}>
        <ResponsiveTable
          rowMetadata={pricebookEntryColumns(
            isQuickBooksEnabled,
            isMarginEnabled,
            {},
            isMarkupEnabled
          )}
          fullScreen
          service={getEntries}
          showToolbars
          noDataMsg="No Items"
          tableName="Pricebook Items"
        />
      </div>
    </ErrorBoundaries>
  );
};

MaterialItems.propTypes = {
  user: PropTypes.object.isRequired,
  pricebookId: PropTypes.string.isRequired
};

MaterialItems.defaultProps = {};

const mapStateToProps = state => ({
  user: state.user,
  settings: state.settings
});

const mapDispatcherToProps = { snackbarOn };

export default connect(mapStateToProps, mapDispatcherToProps)(MaterialItems);
