import * as R from 'ramda';
import React from 'react';
import { array, func, object } from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { Typography } from '@buildhero/sergeant';
import { TimeCardStatusTypes } from 'utils/AppConstants';
import { useStyles } from './EmployeeTimesheets.styles';
import TimesheetCard from '../../../TimesheetCard';

const getTimesheetStatusText = status => {
  switch (status) {
    case TimeCardStatusTypes.DISPUTED:
      return 'Pending Revision';
    case TimeCardStatusTypes.APPROVED:
      return 'Approved';
    case TimeCardStatusTypes.PENDING:
      return 'Unsubmitted';
    case TimeCardStatusTypes.SUBMITTED:
      return 'Submitted';
    default:
      return undefined;
  }
};
const EmployeeTimesheets = ({
  employee,
  employeeTimesheets,
  projectPhases,
  payrollHourTypes,
  onTimesheetChange
}) => {
  const styles = useStyles();
  const timesheetStatus = R.find(R.prop('timesheetStatus'), employeeTimesheets)?.timesheetStatus;
  const timesheetStatusText = getTimesheetStatusText(timesheetStatus);
  return (
    <div css={styles.root}>
      <Typography css={styles.employeeName}>
        {employee?.name || ''}
        {timesheetStatusText ? (
          <Chip
            label={timesheetStatusText}
            css={styles.approvedLabel}
            color={timesheetStatus === TimeCardStatusTypes.APPROVED ? 'secondary' : 'primary'}
          />
        ) : null}
      </Typography>
      {employeeTimesheets?.map(employeeTimesheet => (
        <TimesheetCard
          key={employeeTimesheet.id}
          employeeTimesheet={employeeTimesheet}
          projectPhases={projectPhases}
          payrollHourTypes={payrollHourTypes}
          onChange={onTimesheetChange}
          variant="standard"
        />
      ))}
    </div>
  );
};

EmployeeTimesheets.propTypes = {
  employee: object.isRequired,
  employeeTimesheets: array.isRequired,
  projectPhases: array.isRequired,
  payrollHourTypes: array.isRequired,
  onTimesheetChange: func.isRequired
};

export default EmployeeTimesheets;
