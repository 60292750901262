import { JobReviewStatus, PricingStrategy, SyncStatus } from 'utils/constants';

export const labelSchema = {
  renewals: {
    en: 'Renewals',
    es: 'Renovaciones'
  },
  termsOfService: {
    en: 'Invoice Terms of Service',
    es: 'Términos de Servicio de la Factura'
  },
  purchaseOrderTermsAndConditions: {
    en: 'Purchase Order Terms and Conditions',
    es: 'Términos y Condiciones de la Orden de Compra'
  },
  signOutMenuButtonText: {
    en: 'Sign Out',
    es: 'Cerrar sesión'
  },
  myAccountMenuButtonText: {
    en: 'My Account',
    es: 'Mi cuenta'
  },
  switchToAdminMenuButtonText: {
    en: 'Switch to Admin',
    es: 'Cambiar a administrador(a)'
  },
  profileMenuButtonText: {
    en: 'Profile',
    es: 'Perfil'
  },
  cancelButtonText: {
    en: 'Cancel',
    es: 'Cancelar'
  },
  saveChangesButtonText: {
    en: 'Save changes',
    es: 'Guardar cambios'
  },
  accountName: {
    en: 'Account Name',
    es: 'Nombre de cuenta'
  },
  ownerName: {
    en: 'Owner Name',
    es: 'Nombre de propietario(a)'
  },
  owner: {
    en: 'Owner',
    es: 'Propietario(a)'
  },
  firstName: {
    en: 'First Name',
    es: 'Nombre'
  },
  lastName: {
    en: 'Last Name',
    es: 'Apellido'
  },
  mandatoryFirstName: {
    en: '*First Name',
    es: '*Nombre'
  },
  mandatoryLastName: {
    en: '*Last Name',
    es: '*Apellido'
  },
  addBilling_city: {
    en: 'Billing City',
    es: 'Ciudad de facturación'
  },
  addBilling_street: {
    en: 'Billing Street',
    es: 'Calle de facturación'
  },
  addBilling_zipCode: {
    en: 'Billing Zip Code',
    es: 'Código postal de facturación'
  },
  companyName: {
    en: '*Company Name',
    es: '*Nombre de la empresa'
  },
  'Contact information': {
    en: 'Contact Information',
    es: 'Información de contacto'
  },
  'Customer details': {
    en: 'Customer Details',
    es: 'Detalles del cliente'
  },
  customerId: {
    en: 'Customer ID',
    es: 'Identificación del cliente'
  },
  email: {
    en: '*Email Address',
    es: '*Dirección de correo electrónico'
  },
  customerEmail: {
    en: 'Email Address',
    es: 'Dirección de correo electrónico'
  },
  arBalance: {
    en: 'AR Balance'
  },
  'New Tenant': {
    en: 'New Tenant',
    es: 'Nuevo inquilino'
  },
  'New User': {
    en: 'New User',
    es: 'Nuevo usuario'
  },
  phoneNumber: {
    en: 'Phone Number',
    es: 'Número de teléfono'
  },
  status: {
    en: 'Status',
    es: 'Estado'
  },
  loginStatus: {
    en: 'Login Status',
    es: 'Estado de registro'
  },
  tier: {
    en: 'Tier',
    es: 'Nivel'
  },
  userName: {
    en: '*User name',
    es: 'Nombre de usuario'
  },
  'User Information': {
    en: 'User Information',
    es: 'Informacion del usuario'
  },
  logoUrl: {
    en: 'Add logo',
    es: 'Añadir logotipo'
  },
  addImage: {
    en: 'Add image',
    es: 'Añadir imagen'
  },
  addLogo: {
    en: 'Add logo',
    es: 'Añadir logotipo'
  },
  'Company info': {
    en: 'Company info',
    es: 'Información de la empresa'
  },
  'Company Infomation': {
    en: 'Company Infomation',
    es: 'Información de la empresa'
  },
  addressLine1: {
    en: 'Address Line 1',
    es: 'Información de la empresa'
  },
  addressLine2: {
    en: 'Address Line 2',
    es: 'Información de la empresa'
  },
  addresses: {
    en: 'Address Line 1',
    es: 'Información de la empresa'
  },
  city: {
    en: 'City',
    es: 'Ciudad'
  },
  state: {
    en: 'State/Province',
    es: 'Provincia del estado'
  },
  zipcode: {
    en: 'Zip/Postal code',
    es: 'Código postal'
  },
  mandatoryAddressLine1: {
    en: '*Address Line 1',
    es: 'Información de la empresa'
  },
  mandatoryCity: {
    en: '*City',
    es: 'Ciudad'
  },
  mandatoryState: {
    en: '*State/Province',
    es: 'Estado'
  },
  mandatoryZipcode: {
    en: '*Zip/Postal code',
    es: 'Código postal'
  },
  shippingAddressLine1: {
    en: '*Address Line 1',
    es: 'Información de la empresa'
  },
  shippingAddressLine2: {
    en: 'Address Line 2',
    es: 'Información de la empresa'
  },
  shippingAddresses: {
    en: 'Address Line 1',
    es: 'Información de la empresa'
  },
  shippingCity: {
    en: '*City',
    es: 'Ciudad'
  },
  shippingState: {
    en: '*State/Province',
    es: 'Estado'
  },
  shippingZipcode: {
    en: '*Zip/Postal code',
    es: 'Código postal'
  },
  assetType: {
    en: 'Asset type',
    es: 'Tipo de activo'
  },
  bills: {
    en: 'Bills',
    es: 'Cuentas'
  },
  billingAddressLine1: {
    en: '*Address Line 1',
    es: '*Información de la empresa'
  },
  billingAddressLine2: {
    en: 'Address Line 2',
    es: 'Información de la empresa'
  },
  billingCity: {
    en: '*City',
    es: '*Ciudad'
  },
  billingState: {
    en: '*State/Province',
    es: '*Estado'
  },
  billingZipcode: {
    en: '*Zip/Postal code',
    es: '*Código postal'
  },
  customerBillingAddressLine1: {
    en: 'Address Line 1',
    es: 'Línea de direcciones 1'
  },
  customerBillingAddressLine2: {
    en: 'Address Line 2',
    es: 'Línea de direcciones 2'
  },
  customerBillingCity: {
    en: 'City',
    es: 'Ciudad'
  },
  customerBillingState: {
    en: 'State/Province',
    es: 'Estado'
  },
  customerBillingZipcode: {
    en: 'Zip/Postal code',
    es: 'Código postal'
  },
  phone: {
    en: '*Phone',
    es: '*Teléfono'
  },
  customerPhone: {
    en: 'Phone',
    es: 'Teléfono'
  },
  phonePrimary: {
    en: '*Phone',
    es: '*Teléfono'
  },
  fax: {
    en: 'Fax',
    es: 'Fax'
  },
  companyEmail: {
    en: 'Email',
    es: 'Email'
  },
  websiteUrl: {
    en: 'Website',
    es: 'Sitio web'
  },
  poBox: {
    en: 'PO Box',
    es: 'Apartado de correos'
  },
  useBusinessAddress: {
    en: 'Use business address',
    es: 'Utilizar domicilio social'
  },
  billingAddressIsSame: {
    en: 'Billing Address is same as above',
    es: 'La dirección de facturación es la misma que la anterior'
  },
  billingAddress: {
    en: 'Billing Address',
    es: 'Dirección de facturación'
  },
  'Department and Skills': {
    en: 'Department and Skills',
    es: 'Departamentos y Habilidades'
  },
  'Department Name': {
    en: 'Department name',
    es: 'Nombre de Departamento'
  },
  'No of active team members': {
    en: 'No of active team members',
    es: 'Núm. de miembros activos del equipo'
  },
  'No of skills offered': {
    en: 'No of skills offered',
    es: 'Núm. de habilidades ofrecidas'
  },
  'Add Department': {
    en: 'Add department',
    es: 'Agregar departamento'
  },
  'New department': {
    en: 'New department',
    es: 'Nuevo departamento'
  },
  departmentName: {
    en: 'Department name',
    es: 'Nombre de Departamento'
  },
  mandatoryDepartmentName: {
    en: '*Department name',
    es: '*Nombre de Departamento'
  },
  skillsOffered: {
    en: 'Skills offered',
    es: 'Habilidades ofrecidas'
  },
  departmentMembers: {
    en: 'Department members',
    es: 'Miembros del departamento'
  },
  company: {
    en: 'Company',
    es: 'Empresa'
  },
  companySettings: {
    en: 'Company Settings',
    es: 'Ajustes de la empresa'
  },
  People: {
    en: 'People',
    es: 'Gente'
  },
  customFields: {
    en: 'Custom Fields',
    es: 'Campos personalizados'
  },
  'Roles and Access Rights': {
    en: 'Roles and Access Rights',
    es: 'Funciones y derechos de acceso'
  },
  Employees: {
    en: 'Employees',
    es: 'Empleados'
  },
  employees: {
    en: 'Employees',
    es: 'Empleados'
  },
  homeAddressLine1: {
    en: 'Home address Line 1',
    es: 'Domicilio línea 1'
  },
  homeAddressLine2: {
    en: 'Home address Line 2',
    es: 'Domicilio línea 2'
  },
  personalPhone: {
    en: '*Personal phone',
    es: '*Telefono personal'
  },
  personalEmail: {
    en: '*Personal email',
    es: '*Email personal'
  },
  optionalPhone: {
    en: 'Personal phone',
    es: 'Telefono personal'
  },
  optionalEmail: {
    en: 'Personal email',
    es: 'Email personal'
  },
  addPhoto: {
    en: 'Add photo',
    es: 'Añadir foto'
  },
  changePhoto: {
    en: 'Change photo',
    es: 'Cambiar foto'
  },
  'Employee settings': {
    en: 'Employee settings',
    es: 'Ajustes de empleados'
  },
  'Add employees': {
    en: 'Add employees',
    es: 'Añadir empleado'
  },
  'Add role': {
    en: 'Add role',
    es: 'Agregar función'
  },
  workPhone: {
    en: 'Work phone',
    es: 'Teléfono del trabajo'
  },
  workEmail: {
    en: '*Work email',
    es: '*Correo electrónico del trabajo'
  },
  role: {
    en: 'Role',
    es: 'Función'
  },
  mandatoryRole: {
    en: '*Role',
    es: '*Función'
  },
  departments: {
    en: '*Department(s)',
    es: '*Departamento(s)'
  },
  inviteToBh: {
    en: 'Invite to BuildOps',
    es: 'Invitar a BuildOps'
  },
  activeAccToBh: {
    en: 'Deactivate account',
    es: 'Desactivar cuenta'
  },
  makeAdmin: {
    en: 'Admin rights in BuildOps',
    es: 'Derechos de administrador(a) en BuildOps'
  },
  isTech: {
    en: 'Technician user',
    es: 'Usuario técnico'
  },
  isSales: {
    en: 'Sales user',
    es: 'Usuario de ventas'
  },
  isCrewMember: {
    en: 'Crew member',
    es: 'Miembro de la tripulacion'
  },
  bhName: {
    en: 'Name in BuildOps',
    es: 'Nombre en BuildOps'
  },
  skills: {
    en: 'Skills',
    es: 'Habilidades'
  },
  address: {
    en: 'Address',
    es: 'Dirección'
  },
  companyAddress: {
    en: 'Business address',
    es: 'Dirección de Negocios'
  },
  shippingAddress: {
    en: 'Address',
    es: 'Dirección'
  },
  id: {
    en: 'id',
    es: 'id'
  },
  customers: {
    en: 'Customers',
    es: 'Clientes'
  },
  'all customers': {
    en: 'All customers',
    es: 'Todos los clientes'
  },
  'general info': {
    en: 'General info',
    es: 'Información general'
  },
  customerName: {
    en: '*Name',
    es: '*Nombre'
  },
  customerNameForQuote: {
    en: 'Name',
    es: 'Nombre'
  },
  customerType: {
    en: 'Customer type',
    es: 'Tipo de cliente'
  },
  customerStatus: {
    en: 'Customer status',
    es: 'Estado del cliente'
  },
  'amount not to exceed': {
    en: 'Amount not to exceed',
    es: 'Cantidad que no debe exceder'
  },
  receiveSMS: {
    en: 'Receive SMS',
    es: 'Recibir sms'
  },
  businessAddressLine1: {
    en: 'Address Line 1',
    es: 'Información de la empresa'
  },
  businessAddressLine2: {
    en: 'Address Line 2',
    es: 'Información de la empresa'
  },
  businessAddresses: {
    en: 'Address Line 1',
    es: 'Información de la empresa'
  },
  businessCity: {
    en: 'City',
    es: 'Ciudad'
  },
  businessState: {
    en: 'State/Province',
    es: 'Estado'
  },
  businessZipcode: {
    en: 'Zip/Postal code',
    es: 'Código postal'
  },
  businessAddress: {
    en: 'Business address',
    es: 'Dirección de Negocios'
  },
  'primary contacts': {
    en: 'Primary contacts',
    es: 'Contacto primario'
  },
  'customer representative': {
    en: 'Customer representative(s)',
    es: 'Representante del cliente'
  },
  'representative on our side': {
    en: 'Representative(s) on our side',
    es: 'Representante(s) de nuestro lado'
  },
  'customer properties': {
    en: 'Customer properties',
    es: 'Propiedades del cliente'
  },
  'new job': {
    en: 'New job',
    es: 'Nuevo trabajo'
  },
  landlinePhone: {
    en: 'Landline phone',
    es: 'Teléfono fijo'
  },
  cellPhone: {
    en: 'Cell phone',
    es: 'Teléfono celular'
  },
  isSmsOptOut: {
    en: 'SMS notifications?',
    es: 'Notificaciones por SMS?'
  },
  bestContact: {
    en: 'Best contact',
    es: 'Mejor contacto'
  },
  name: {
    en: 'Name',
    es: 'Nombre'
  },
  representativeStatus: {
    en: 'Representative status',
    es: 'Estado representativo'
  },
  assetName: {
    en: '*Asset name',
    es: '*Nombre del activo'
  },
  make: {
    en: 'Make',
    es: 'Marca'
  },
  modelNumber: {
    en: 'Model no',
    es: 'Núm. del modelo'
  },
  serialNo: {
    en: 'Serial no',
    es: 'Núm. del serie'
  },
  installDate: {
    en: 'Install date',
    es: 'Fecha de instalación'
  },
  propertyName: {
    en: '*Property name',
    es: '*Nombre de propiedad'
  },
  propertyType: {
    en: 'Property type',
    es: 'Tipo de propiedad'
  },
  amountNotToExceed: {
    en: 'NTE',
    es: 'NTE'
  },
  amountQuoted: {
    en: 'Amount quoted',
    es: 'Cantidad cotizada'
  },
  issueDescription: {
    en: 'Issue description',
    es: 'Descripcion del problema'
  },
  sameBillingAddress: {
    en: 'Same billing address',
    es: 'Misma dirección de facturación'
  },
  tags: {
    en: 'Tags',
    es: 'Etiquetas'
  },
  noOfProperties: {
    en: 'No of Properties',
    es: 'Núm. de Propiedades'
  },
  notes: {
    en: 'Notes',
    es: 'Notas'
  },
  notesAndAttachments: {
    en: 'Notes & Attachments',
    es: 'Notas y archivos adjuntos'
  },
  propertyAddress: {
    en: 'Property address',
    es: 'Dirección de Propiedad'
  },
  latestNote: {
    en: 'Latest note',
    es: 'última nota'
  },
  businessAddressHeading: {
    en: 'Corporate address',
    es: 'Dirección de Negocios'
  },
  businessAddressIsSame: {
    en: 'Add a corporate address',
    es: 'Añadir una dirección corporativa'
  },
  companyAddressIsSame: {
    en: 'Business Address is same as above',
    es: 'La dirección comercial es la misma que la anterior'
  },
  customerProperties: {
    en: 'customer properties',
    es: 'propiedades del cliente'
  },
  customerReps: {
    en: 'customer representative(s)',
    es: 'representante (s) del cliente'
  },
  tenantReps: {
    en: 'representative(s)',
    es: 'representante (s)'
  },
  customer: {
    en: 'Customer',
    es: 'Cliente'
  },
  billingCustomer: {
    en: 'Billing Customer'
  },
  allNotesFor: {
    en: 'All notes for',
    es: 'Todas las notas para'
  },
  addNoteFor: {
    en: 'Add note for',
    es: 'Agregar nota para'
  },
  propertyAddressLine1: {
    en: '*Address line1',
    es: '*Dirección line1'
  },
  propertyAddressLine2: {
    en: 'Address line2',
    es: 'Dirección line2'
  },
  propertyCity: {
    en: '*City',
    es: '*Ciudad'
  },
  propertyState: {
    en: '*State',
    es: '*Estado'
  },
  propertyZipcode: {
    en: '*Zipcode',
    es: '*Código postal'
  },
  copyCustomerAddress: {
    en: 'Reuse customer address',
    es: 'Reutilizar la dirección del cliente'
  },
  propertyShipTo: {
    en: 'Ship to',
    es: 'Envie a'
  },
  propertyHasBillingAddressAsCustomer: {
    en: 'Property has same billing address as customer',
    es: 'La propiedad tiene la misma dirección de facturación que el cliente'
  },
  'property assets': {
    en: 'Property assets',
    es: 'Bienes inmuebles'
  },
  'property attachments': {
    en: 'Property attachments',
    es: 'Adjuntos de propiedad'
  },
  'property contacts': {
    en: 'Property contacts',
    es: 'Contactos de propiedad'
  },
  description: {
    en: 'Description',
    es: 'Descripción'
  },
  inventory: {
    en: 'Inventory',
    es: 'Inventario'
  },
  inventoryDescription: {
    en: '*Description',
    es: '*Descripción'
  },
  unassigned: {
    en: 'Unassigned',
    es: 'Sin asignar'
  },
  onhold: {
    en: 'On Hold',
    es: 'On Hold'
  },

  'edit property': {
    en: 'Edit property'
  },
  propertyStatus: {
    en: 'Status'
  },
  jobsCompleted: {
    en: 'Jobs completed'
  },
  customerFieldCustomerType: {
    en: 'Customer type',
    es: 'Tipo de cliente'
  },
  customerTag: {
    en: 'Customer tags',
    es: 'Etiquetas de cliente'
  },
  jobType: {
    en: 'Job type',
    es: 'Tipo de empleo'
  },
  [JobReviewStatus.NO_REVIEW_NEEDED]: {
    en: 'No Review Needed',
    es: 'No se Necesita Revisión'
  },
  [JobReviewStatus.REVIEW_NEEDED]: {
    en: 'Review Needed',
    es: 'Revisión Necesaria'
  },
  [JobReviewStatus.REVIEWED]: {
    en: 'Reviewed',
    es: 'Revisados'
  },
  taskType: {
    en: 'Task type',
    es: 'Tipo de tarea'
  },
  timeType: {
    en: 'Time type',
    es: 'Tipo de tiempo'
  },
  fileName: {
    en: 'Upload document',
    es: 'Subir documento'
  },
  or: {
    en: 'or',
    es: 'o'
  },
  addNewCustomerRepForProperty: {
    en: 'Add new customer representative just for this property',
    es: 'Agregar nuevo representante de clientes solo para esta propiedad'
  },
  chooseExistingRep: {
    en: 'Choose from Reps assigned to this customer',
    es: 'Elija entre los representantes asignados a este cliente'
  },
  chooseRep: {
    en: 'Choose rep',
    es: 'Elegir rep'
  },
  jobs: {
    en: 'Jobs',
    es: 'Trabajos'
  },
  jobsAndVisits: {
    en: 'Jobs & Visits',
    es: 'Empleos y visitas'
  },
  jobsPullThroughWork: {
    en: 'Jobs (Pull Through Work)',
    es: 'Trabajos (Pull Through Work)'
  },
  'all jobs': {
    en: 'All jobs',
    es: 'Todos los trabajos'
  },
  'create new job': {
    en: 'Create new job',
    es: 'Crear nuevo trabajo'
  },
  createInvoiceDirect: {
    en: 'New Invoice',
    es: 'Nueva Factura'
  },
  createCrew: {
    en: 'New Crew',
    es: 'Nuevo Equipo'
  },
  property: {
    en: '*Property',
    es: '*Propiedad'
  },
  customerRep: {
    en: 'Customer rep',
    es: 'Representante del cliente'
  },

  departmentsNeeded: {
    en: 'Departments needed',
    es: 'Departamentos necesarios'
  },
  propertyRep: {
    en: 'Property rep',
    es: 'Representante de la propiedad'
  },
  priority: {
    en: 'Priority',
    es: 'Prioridad'
  },
  customerProvidedPONumber: {
    en: 'Customer provided PO #',
    es: 'Número de pedido proporcionado por el cliente'
  },
  billingStatus: {
    en: 'Billing Status',
    es: 'Estado de facturación'
  },
  customerProvidedJobNumber: {
    en: '*Customer provided Job #',
    es: '*Número de trabajo proporcionado por el cliente'
  },
  'job attachments': {
    en: 'Job attachment(s)',
    es: 'Adjuntos de trabajo'
  },
  scheduling: {
    en: 'Scheduling',
    es: 'Programación'
  },
  tasks: {
    en: 'Tasks',
    es: 'Tareas'
  },
  pendingTasksProperty: {
    en: 'Pending Tasks for this Property',
    es: 'Tareas pendientes para esta propiedad'
  },
  bestContactMethod: {
    en: 'Best contact method',
    es: 'Mejor método de contacto'
  },
  'customerProvidedJob#': {
    en: 'Customer provided job #',
    es: 'Número de trabajo proporcionado por el cliente'
  },
  'customerProvidedPO#': {
    en: 'Customer provided PO #',
    es: 'Número de pedido proporcionado por el cliente'
  },
  selectTask: {
    en: 'Select task',
    es: 'Seleccionar tarea'
  },
  typeTaskDescripton: {
    en: 'Type task description',
    es: 'Escriba la descripción de la tarea'
  },
  searchForCustomerOrProperty: {
    en: 'Search for customer or property',
    es: 'Buscar cliente o propiedad'
  },
  'or create new customer': {
    en: 'Or create new customer',
    es: 'O crear nuevo cliente'
  },
  partsNotes: {
    en: 'Parts',
    es: 'Partes'
  },
  attachments: {
    en: 'Attachments',
    es: 'Archivos adjuntos'
  },
  jobNumber: {
    en: '*Job number',
    es: '*Job numero'
  },
  jobCustomerName: {
    en: '*Customer',
    es: '*Cliente'
  },
  currentVisitsForProperty: {
    en: 'Current visits for property',
    es: 'Visitas actuales de propiedad'
  },
  pastVisitsForProperty: {
    en: 'Past visits for property',
    es: 'Visitas pasadas de propiedad'
  },
  currentVisits: {
    en: 'Current visits',
    es: 'Visitas actuales'
  },
  pastVisits: {
    en: 'Past visits',
    es: 'Visitas pasadas'
  },
  corporateAddress: {
    en: 'Corporate address',
    es: 'Dirección corporativa'
  },
  customerRepEmail: {
    en: 'Email Address',
    es: 'Dirección de correo electrónico'
  },
  defaultProperty: {
    en: 'This address is also a property',
    es: 'Esta dirección es también una propiedad'
  },
  quickJobCustomerName: {
    en: 'Customer name',
    es: 'Nombre del cliente'
  },
  quickJobPropertyName: {
    en: 'Property name',
    es: 'Nombre de propiedad'
  },
  quickJobAmountNotToExceed: {
    en: 'Amount not to exceed',
    es: 'Cantidad que no debe exceder'
  },
  quickJobCustomerRep: {
    en: 'Customer rep',
    es: 'Representante del cliente'
  },
  quickJobContact: {
    en: 'Contact',
    es: 'Contacto'
  },
  quickJobType: {
    en: 'Job type',
    es: 'Tipo de trabajo'
  },
  jobDescription: {
    en: 'Job description',
    es: 'Descripción del trabajo'
  },
  lineItems: {
    en: 'Items Used',
    es: 'Artículos utilizados'
  },

  quantity: {
    en: 'Quantity',
    es: 'Cantidad'
  },
  inventoryQuantity: {
    en: '*Quantity',
    es: '*Cantidad'
  },
  unitCost: {
    en: 'Unit Cost',
    es: 'Costo unitario'
  },
  inventoryUnitCost: {
    en: '*Unit Cost',
    es: '*Costo unitario'
  },
  unitPrice: {
    en: 'Unit Price',
    es: 'Precio unitario'
  },
  customerNotes: {
    en: 'Customer notes',
    es: 'Notas de los clientes'
  },
  noNote: {
    en: 'No note added',
    es: 'Sin nota añadida'
  },
  viewEditAllNotes: {
    en: 'View / edit all notes',
    es: 'Ver / Editar todas las notas'
  },
  notesOnCustomer: {
    en: 'Notes on customer',
    es: 'Notas sobre el cliente'
  },
  viewEditAllPropertyNotes: {
    en: 'View / edit all property instructions',
    es: 'Ver / editar todas las instrucciones de propiedad'
  },
  notesOnProperty: {
    en: 'Notes on property',
    es: 'Notas sobre la propiedad'
  },
  propertyNotes: {
    en: 'Property instructions',
    es: 'Instrucciones de propiedad'
  },
  jobNotes: {
    en: 'OFFICE NOTES',
    es: 'Notas de oficina para trabajo'
  },
  viewAllJobNotesV2: {
    en: 'Add / View / Edit all office notes for job',
    es: 'Agregar / Ver / Editar todas las notas de Office para el trabajo'
  },
  viewAllJobNotes: {
    en: 'View / Edit all office notes for job',
    es: 'Ver / Editar todas las notas de Office para el trabajo'
  },
  viewAllMaintenanceNotes: {
    en: 'View / Edit all office notes for Maintenance',
    es: 'Ver / editar todas las notas de oficina para mantenimiento'
  },
  viewAllMaintenanceNotesV2: {
    en: 'Add / View / Edit all office notes for Maintenance',
    es: 'Agregar / Ver / editar todas las notas de oficina para mantenimiento'
  },
  job: {
    en: 'Job',
    es: 'Trabajo'
  },
  type: {
    en: 'Type',
    es: 'Tipo'
  },
  attachmentType: {
    en: '*Type',
    es: '*Tipo'
  },
  accountNumber: {
    en: 'Account number',
    es: 'Número de cuenta'
  },
  billTo: {
    en: 'Bill to',
    es: 'Cobrar a'
  },
  shipto: {
    en: 'Ship to',
    es: 'Envie a'
  },
  customFileName: {
    en: 'Rename file',
    es: 'Renombrar archivo'
  },
  attachmentFileName: {
    en: '*File name',
    es: '*Nombre del archivo'
  },
  'job reports': {
    en: 'Job Report(s)',
    es: 'Informe de Trabajo(s)'
  },
  maintenanceReports: {
    en: 'Maintenance Report(s)',
    es: 'Informe (s) de mantenimiento'
  },
  invoicesIssued: {
    en: 'Invoice(s) issued',
    es: 'Facturas emitida'
  },
  totalBilledForJob: {
    en: 'Total billed for job',
    es: 'Total facturado por trabajo'
  },
  invoices: {
    en: 'Invoices',
    es: 'Facturas'
  },
  invoiceSettings: {
    en: 'Invoice Settings',
    es: 'Configuración de facturas'
  },
  invoicesAndCreditMemos: {
    en: 'Invoices & Credit Memos',
    es: 'Facturas y notas de crédito'
  },
  pricebooks: {
    en: 'Pricebooks',
    es: 'Libros De Precios'
  },
  pricebook: {
    en: 'Pricebook',
    es: 'Libro De Precios'
  },
  addPricebook: {
    en: 'Add Pricebook',
    es: 'Agregar Libro De Precios'
  },
  editPricebook: {
    en: 'Edit Pricebook',
    es: 'Editar libro de precios'
  },
  [PricingStrategy.MARKUP]: {
    en: 'Markup Only',
    es: 'Solo Marcado'
  },
  [PricingStrategy.MARGIN]: {
    en: 'Margin Only',
    es: 'Solo Margen'
  },
  [PricingStrategy.MARKUP_AND_MARGIN]: {
    en: 'Both Markup and Margin',
    es: 'Tanto el Marcado como el Margen'
  },
  [JobReviewStatus.NO_REVIEW_NEEDED]: {
    en: 'No Review Needed',
    es: 'No se Necesita Revisión'
  },
  [JobReviewStatus.NO_REVIEW_NEEDED]: {
    en: 'No Review Needed',
    es: 'No se Necesita Revisión'
  },
  inventorySettings: {
    en: 'Inventory Settings',
    es: 'Inventory Settings'
  },
  timesheets: {
    en: 'Timesheets',
    es: 'Timesheets'
  },
  working: {
    en: 'Working',
    es: 'Trabajo'
  },
  hours: {
    en: 'Hours',
    es: 'Horas'
  },
  minutes: {
    en: 'Minutes',
    es: 'Minutos'
  },
  labour: {
    en: 'Labor',
    es: 'Trabajo'
  },
  hourlyCharge: {
    en: 'Hourly charge',
    es: 'Carga horaria'
  },
  hourlyChargeMandatory: {
    en: '*Hourly charge',
    es: '*Carga horaria'
  },
  companyHourSetting: {
    en: 'After Hours Settings',
    es: 'Configuración fuera de horario'
  },
  note: {
    en: 'Note',
    es: 'Nota'
  },
  vendor: {
    en: '*Vendor',
    es: '*Proveedor'
  },
  vendorNonMandatory: {
    en: 'Vendor',
    es: 'Proveedor'
  },
  receiptNo: {
    en: 'Receipt no',
    es: 'Receipt no'
  },
  poNo: {
    en: '*Po no',
    es: '*Núm. de orden de compra'
  },
  dateOfPurchase: {
    en: '*Date of purchase',
    es: '*Fecha de compra'
  },
  total: {
    en: '*Total',
    es: '*Total'
  },
  totalNonMandatory: {
    en: 'Total',
    es: 'Total'
  },
  comment: {
    en: 'Comment',
    es: 'Comentario'
  },
  department: {
    en: 'Department',
    es: 'Departamento'
  },
  measuringUnit: {
    en: 'Measuring unit',
    es: 'Unidad de medición'
  },
  markup: {
    en: 'Markup %',
    es: 'Marcado %'
  },
  packingSlipFileName: {
    en: 'File name',
    es: 'Nombre de archivo'
  },
  attachmentComments: {
    en: 'Comments',
    es: 'Comentario'
  },
  message: {
    en: 'Message',
    es: 'Mensaje'
  },
  okay: {
    en: 'Okay',
    es: 'Bueno'
  },
  doNotShow: {
    en: 'Don’t show me this again',
    es: 'No me muestres esto de nuevo'
  },
  reasonForDiscount: {
    en: '*Reason for discount',
    es: '*Razón para el descuento'
  },
  amount: {
    en: 'Amount',
    es: 'Cantidad'
  },
  taxable: {
    en: 'Taxable',
    es: 'Imponible'
  },
  inventoryUnitPrice: {
    en: '*Unit Price',
    es: '*Precio unitario'
  },
  tax: {
    en: 'Tax',
    es: 'Impuesto'
  },
  totalAmountPreTax: {
    en: '*Total amount (pretax)',
    es: '*Cantidad total (antes de impuestos)'
  },
  upload: {
    en: 'Upload',
    es: 'Subir'
  },
  createInvoice: {
    en: 'Create Invoice from',
    es: 'Crear factura desde'
  },
  createInvoiceFromJobs: {
    en:
      'This job has additional visits that are ready to be invoiced.  Which visit(s) would you like to add to this invoice?',
    es: 'Crear factura desde'
  },
  finalInvoice: {
    en: 'This is the final invoice for this job',
    es: ' Esta es la factura final para este trabajo'
  },
  sendInvoice: {
    en: 'Send invoice',
    es: 'Enviará la factura'
  },
  Documents: {
    en: 'Documents',
    es: ' Documentos'
  },
  Receipts: {
    en: 'Receipts',
    es: ' Ingresos'
  },
  PartsInstalled: {
    en: 'Parts Installed',
    es: 'Partes Instaladas'
  },
  generatePurchaseOrder: {
    en: 'Generate Purchase Order',
    es: 'Generar Orden de Compra'
  },
  Photos: {
    en: 'Photos and Videos',
    es: 'Fotos y Videos'
  },
  billingTime: {
    en: 'Billing time',
    es: 'Tiempo de facturación'
  },
  billingTimeMandatory: {
    en: '*Billing time',
    es: '*Tiempo de facturación'
  },
  items: {
    en: 'Items',
    es: 'Artículos'
  },
  partsAndMaterials: {
    en: 'Parts & Materials',
    es: 'Partes y materiales'
  },
  laborRates: {
    en: 'Labor Rates',
    es: 'Artículos'
  },
  accountingSettings: {
    en: 'Accounting Settings',
    es: 'Configuraciones Contables'
  },
  accounting: {
    en: 'Accounting',
    es: 'Contables'
  },
  salesAccount: {
    en: 'Sales accounts',
    es: 'Cuentas de ventas'
  },
  date: {
    en: 'Date',
    es: 'Fecha'
  },
  from: {
    en: 'From',
    es: 'De'
  },
  to: {
    en: 'To',
    es: 'A'
  },
  classesForDepartment: {
    en: 'Reference in Quickbooks',
    es: 'Reference in Quickbooks'
  },
  default: {
    en: 'Is default pricebook?',
    es: 'Es la lista de precios predeterminada?'
  },
  markupValue: {
    en: 'Markup value',
    es: 'Valor de Marcado'
  },
  costMarkup: {
    en: 'Cost Markup',
    es: 'Marcado de costos'
  },
  discountAmount: {
    en: '*Amount',
    es: '*Cantidad'
  },
  vendorHeader: {
    en: '*Items & Pricebook > Add vendor',
    es: '*Artículos y lista de precios> Agregar proveedor'
  },
  vendorType: {
    en: 'Vendor Type',
    es: 'Tipo de vendedor'
  },
  vendorStatus: {
    en: '*Vendor Status',
    es: '*Estado del vendedor'
  },
  primaryContact: {
    en: 'Primary Contact',
    es: 'Contacto Primario'
  },
  vendorEmail: {
    en: 'Email',
    es: 'Email'
  },
  vendorPhone: {
    en: 'Phone',
    es: 'Teléfono'
  },
  active: {
    en: 'Active',
    es: 'Activo'
  },
  deactivated: {
    en: 'Deactivated',
    es: 'Desactivado'
  },
  itemName: {
    en: 'Item Name',
    es: 'Nombre del árticulo'
  },
  vendors: {
    en: 'Vendors',
    es: 'Vendedores'
  },
  allVendors: {
    en: 'All Vendors',
    es: 'Todos los vendedores'
  },
  addVendor: {
    en: 'Add Vendor',
    es: 'Añadir vendedor'
  },
  salesTax: {
    en: 'Sales Tax',
    es: 'Impuesto de venta'
  },
  paymentType: {
    en: 'Payment Type',
    es: 'Tipo de pago'
  },
  paymentTerms: {
    en: 'Payment Terms',
    es: 'Términos de pago'
  },
  purchaseOrderType: {
    en: 'Purchase Order Type',
    es: 'Tipo de orden de pago'
  },
  adjustments: {
    en: 'Adjustments',
    es: 'Ajuste'
  },
  adjustmentTypes: {
    en: 'Adjustment Types',
    es: 'Tipos de ajuste'
  },
  syncLogHistory: {
    en: 'Sync Log History',
    es: 'Historial de registro de sincronización'
  },
  Rate: {
    en: 'Rate',
    es: 'Tarifa'
  },
  Percent: {
    en: 'Percent',
    es: 'Por ciento'
  },
  Currency: {
    en: 'Currency',
    es: 'Moneda'
  },
  Forms: {
    en: 'Forms',
    es: 'Formularios'
  },
  FormBuilder: {
    en: 'Form Builder',
    es: 'Formularios'
  },
  'Published Forms': {
    en: 'Published Forms',
    es: 'Published Forms'
  },
  'Form Drafts': {
    en: 'Drafts',
    es: 'Drafts'
  },
  'Create new form': {
    en: 'Create new form',
    es: 'Añadir formulario'
  },
  propertyLabel: {
    en: 'Property',
    es: 'Propiedad'
  },
  jobLabel: {
    en: 'Job',
    es: 'Trabajo'
  },
  billingAddressLabel: {
    en: 'Billing Address',
    es: 'Dirección de Envio'
  },
  propertyAddressLabel: {
    en: 'Property Address',
    es: 'Dirección de Propiedad'
  },
  followUps: {
    en: 'Follow Ups',
    es: 'Seguimiento'
  },
  serviceAgreements: {
    en: 'Service Agreements',
    es: 'Acuerdos de servicio'
  },
  serviceAgreement: {
    en: 'Service Agreement',
    es: 'Acuerdo de servicio'
  },
  dashboards: {
    en: 'Dashboards',
    es: 'Tablero'
  },
  products: {
    en: 'Item List',
    es: 'Lista de articulos'
  },
  settings: {
    en: 'Settings',
    es: 'Configuraciones'
  },
  projectSetting: {
    en: 'Job Costing',
    es: 'Costo de empleo'
  },
  category: {
    en: 'Category',
    es: 'Categoría'
  },
  subCategory: {
    en: 'Sub category',
    es: 'Subcategoría'
  },
  productBundle: {
    en: 'Product bundle',
    es: 'Paquete de productos'
  },
  version: {
    en: 'Version',
    es: 'Version'
  },
  assets: {
    en: 'Assets',
    es: 'Bienes'
  },
  contacts: {
    en: 'Contacts',
    es: 'Contactos'
  },
  quotes: {
    en: 'Quotes',
    es: 'Citas'
  },
  properties: {
    en: 'Properties',
    es: 'Propiedades'
  },
  technicianReport: {
    en: 'Technician Reports',
    es: 'Informes técnicos'
  },
  reviewReport: {
    en: 'Review Reports',
    es: 'Revisar informes'
  },
  asset: {
    en: 'Asset',
    es: 'Biene'
  },
  quote: {
    en: 'Quote',
    es: 'Cita'
  },
  selectProductBundle: {
    en: 'Choose product bundle',
    es: 'Seleccione paquete de productos'
  },
  newquote: {
    en: 'New quote',
    es: 'Nueva cita'
  },
  editquote: {
    en: 'Edit quote',
    es: 'Editar cotización'
  },
  quoteName: {
    en: 'Quote Title',
    es: 'Cotización título'
  },
  quoteNameMandatory: {
    en: '*General scope',
    es: '*Alcance general'
  },
  detailsShownOnCustomer: {
    en: 'Details shown on customer',
    es: 'Detalles mostrados en el cliente'
  },
  expirationDate: {
    en: 'Expiration date',
    es: 'Fecha de caducidad'
  },
  assignTo: {
    en: 'Assign to',
    es: 'Asignar a'
  },
  projectManager: {
    en: 'Project Manager',
    es: 'Gerente de proyecto'
  },
  customerPoNumber: {
    en: 'Customer po number',
    es: 'Número de pedido del cliente'
  },
  soldBy: {
    en: 'Sold by',
    es: 'Vendido por'
  },
  subtotal: {
    en: 'Sub total',
    es: 'Total parcial'
  },
  quoteNotes: {
    en: 'Office notes for quote',
    es: 'Notas de oficina para cotizar'
  },
  viewAllQuoteNotes: {
    en: 'View / Edit all office notes for quote',
    es: 'Ver / editar todas las notas de la oficina para cotizar'
  },
  purchaseOrderNotes: {
    en: 'Office notes',
    es: 'Notas de oficina'
  },
  viewAllPurchaseOrderNotes: {
    en: 'View / Edit all office notes for purchase order',
    es: 'Ver / editar todas las notas de la oficina para orden de compra'
  },
  addPropertyTaskToQuote: {
    en: 'Add property task to quote',
    es: 'Agregar tarea de propiedad para cotizar'
  },
  selectTaskFromProperty: {
    en: 'Select task from this property',
    es: 'Seleccionar tarea de esta propiedad'
  },
  jobVisits: {
    en: 'Visits',
    es: 'Visitas'
  },
  taxRate: {
    en: 'Tax code',
    es: 'Impuesto'
  },
  addItems: {
    en: 'Add Items',
    es: 'Agregar elementos'
  },
  sendQuote: {
    en: 'Send Quote',
    es: 'Enviar Presupuesto'
  },
  ProductCode: {
    en: 'Product code',
    es: 'Código de producto'
  },
  rejectedReason: {
    en: 'Approved/Rejected comments',
    es: 'Comentarios aprobados / rechazados'
  },
  totalAmountQuotedOverride: {
    en: 'Amount quoted',
    es: 'Monto citado'
  },
  calculatedTotalAmount: {
    en: 'Calculated total',
    es: 'Total calculado'
  },
  productCode: {
    en: 'Product code',
    es: 'Código de producto'
  },
  salesAmount: {
    en: 'Sales amount',
    es: 'Cantidad de ventas'
  },
  costAmount: {
    en: 'Cost amount',
    es: 'Cantidad de costo'
  },
  taxCode: {
    en: 'Tax code',
    es: 'Código de impuestos'
  },
  propertyNonMandatory: {
    en: 'Property',
    es: 'Propiedad'
  },
  taskName: {
    en: 'Scope',
    es: 'Alcance'
  },
  taskNameMandatory: {
    en: '*Scope',
    es: '*Alcance'
  },
  employeeCode: {
    en: 'Code',
    es: 'Código'
  },
  auditFilterEntityType: {
    en: 'Audit Entity Type',
    es: 'Entidad tipo'
  },
  auditFilterDateRange: {
    en: 'Date Range',
    es: 'Rango de fechas'
  },
  auditFilterSearch: {
    en: 'Search',
    es: 'Buscar'
  },
  customerProvidedWONumber: {
    en: 'Customer WO #',
    es: 'Cliente WO #'
  },
  authorizedBy: {
    en: 'Authorized by',
    es: 'Autorizado por'
  },
  serviceOrders: {
    en: 'Service orders',
    es: 'Service orders'
  },
  VisitDate: {
    en: 'Date',
    es: 'Date'
  },
  VisitJob: {
    en: 'Job #',
    es: 'Job #'
  },
  Visit: {
    en: 'Visit',
    es: 'Visit'
  },
  Technician: {
    en: 'Technician',
    es: 'Technician'
  },
  WorkDescription: {
    en: 'Work Description',
    es: 'Work Description'
  },
  VisitCustomIdentifier: {
    en: 'Custom Job #',
    es: 'Custom Job #'
  },
  itemMarkup: {
    en: 'Item markup',
    es: 'Marcado de artículo'
  },
  Workflow: {
    en: 'Workflows',
    es: 'Workflows'
  },
  TruckList: {
    en: 'Truck list',
    es: 'Lista de camiones'
  },
  Procurement: {
    en: 'Procurement',
    es: 'Obtención'
  },
  InventoryItems: {
    en: 'Inventory Items',
    es: 'Artículos de inventario'
  },
  inventoryUom: {
    en: 'UOM',
    es: 'UOM'
  },
  Payments: {
    en: 'Payments',
    es: 'Payments'
  },
  Payment: {
    en: 'Payment',
    es: 'Payment'
  },
  activityLogs: {
    en: 'Activity Logs',
    es: 'Activity Logs'
  },
  proceedCreateVisit: {
    en: 'Proceed and create Visits',
    es: 'Continuar y crear visitas'
  },
  continueScheduling: {
    en: 'continue scheduling',
    es: 'continuar programando'
  },
  technicianName: {
    en: 'Technician Name',
    es: 'Nombre del técnico'
  },
  inventoryBundle: {
    en: 'Inventory bundle',
    es: 'Paquete de inventario'
  },
  newItem: {
    en: 'New Item',
    es: 'Nuevo artículo'
  },
  payroll: {
    en: 'Payroll',
    es: 'Nómina de sueldos'
  },
  payrollHourTypes: {
    en: 'Payroll Hour Types',
    es: 'Tipos de hora de nómina'
  },
  costCodes: {
    en: 'Cost Codes',
    es: 'Códigos de costo'
  },
  costTypes: {
    en: 'Cost Types',
    es: 'Tipos de costos'
  },
  billingHourTypes: {
    en: 'Billing Hour Types',
    es: 'Tipos de horas de facturación'
  },
  labourTypes: {
    en: 'Labor Types',
    es: 'Tipos de mano de obra de nómina'
  },
  timeTrackingSettings: {
    en: 'Time Tracking Settings',
    es: 'Configuraciones de seguimiento de tiempo'
  },
  laborSettings: {
    en: 'Labor Settings',
    es: 'Configuración laboral'
  },
  dispatchSettings: {
    en: 'Dispatch Settings',
    es: 'Configuración del despacho'
  },
  serviceAgreementSettings: {
    en: 'Service Agreement Settings',
    es: 'Configuración del acuerdo de servicio'
  },
  quoteSettings: {
    en: 'Quote Settings',
    es: 'Configuración del acuerdo de cotización'
  },
  projectSettings: {
    en: 'Project Settings',
    es: 'Configuración del proyecto'
  },
  accountingLabel: {
    en: 'Accounting',
    es: 'Contabilidad'
  },
  dispatch: {
    en: 'Dispatch',
    es: 'Dispatch'
  },
  Communications: {
    en: 'External Message Templates',
    es: 'Communications'
  },
  bulkVisitCreation: {
    en: 'Bulk Visit Creation',
    es: 'Creación de Visitas en Bulto'
  },
  manualVisitCreation: {
    en: 'Manual Visit Creation',
    es: 'Creación Manual de Visitas'
  },
  selectPricebook: {
    en: 'Select Pricebook',
    es: 'Seleccionar lista de precios'
  },
  recurringMaintenance: {
    en: 'Recurring Maintenance',
    es: 'Mantenimiento recurrente'
  },
  nonRecurringMaintenance: {
    en: 'Non-Recurring Services',
    es: 'Servicios no recurrentes'
  },
  materialMarkupRates: {
    en: 'Material Markup Rates',
    es: 'Tasas de margen de material'
  },
  materialMarginRates: {
    en: 'Material Margin Rates',
    es: 'Tasas de margen de material'
  },
  materialMarkupAndMarginRates: {
    en: 'Material Markup and Margin Rates',
    es: 'Tasas de margen de material'
  },
  pricebookConfiguration: {
    en: 'Pricebook Configuration',
    es: 'Configuración del libro de precios'
  },
  configureWithMarkup: {
    en: 'Configure with Markup',
    es: 'Configurar con marcado'
  },
  configureWithMargin: {
    en: 'Configure with Margin',
    es: 'Configurar con margen'
  },
  changeFormat: {
    en: 'Change Format',
    es: 'Cambiar format'
  },
  addNewRange: {
    en: 'Add New Range',
    es: 'Agregar nuevo rango'
  },
  range: {
    en: 'Range',
    es: 'Rango'
  },
  flatMarkup: {
    en: 'Flat Markup',
    es: 'Marcado plano'
  },
  flatMargin: {
    en: 'Flat Margin',
    es: 'Margen plano'
  },
  addRateCard: {
    en: 'Add Rate Card',
    es: 'Agregar tarjeta Rte'
  },
  newServiceAgreement: {
    en: 'New Service Agreement',
    es: 'Nuevo acuerdo de servicio'
  },
  rangeMarkup: {
    en: 'Markup',
    es: 'Margen'
  },
  rangeMargin: {
    en: 'Margin',
    es: 'Margen'
  },
  configMarkup: {
    en: 'Configure with Markup',
    es: 'Configurar con Marcado'
  },
  configMargin: {
    en: 'Configure with Margin',
    es: 'Configurar con Margen'
  },
  createFromExistingPricebook: {
    en: 'Create From Existing Pricebook',
    es: 'Crear a partir de la lista de precios existente'
  },
  addMaterialMarkupRates: {
    en: 'Add Material Markup Rate',
    es: 'AÑADIR TASA DE MARCADO DE MATERIAL'
  },
  addMaterialMarginRates: {
    en: 'Add Material Margin Rate',
    es: 'AÑADIR TASA DE MARGEN DE MATERIAL'
  },
  createVisits: {
    en: 'Create Visits',
    es: 'Crear visitas'
  },
  rateCard: {
    en: 'Rate Card',
    es: 'Tarjeta de tarifa'
  },
  saveRates: {
    en: 'Save Rates',
    es: 'Ahorre tarifas'
  },
  addMaintenanceRecord: {
    en: 'Add Maintenance Template',
    es: 'Agregar plantilla de mantenimiento'
  },
  MaintenanceRecord: {
    en: 'Add Maintenance Template',
    es: 'Agregar plantilla de mantenimiento'
  },
  newMaintenanceTemplate: {
    en: 'New Maintenance Template',
    es: 'Nueva plantilla de mantenimiento'
  },
  editMaintenanceTemplate: {
    en: 'Edit Maintenance Template',
    es: 'Editar plantilla de mantenimiento'
  },
  maintenance: {
    en: 'Maintenance',
    es: 'Mantenimiento'
  },
  maintenanceType: {
    en: 'Maintenance type',
    es: 'Tipo de mantenimiento'
  },
  createRecord: {
    en: 'Create Template',
    es: 'Crear plantilla'
  },
  updateRecord: {
    en: 'Update Template',
    es: 'Actualizar plantilla'
  },
  materialItems: {
    en: 'Material Items',
    es: 'Elementos materiales'
  },
  viewFullPricebook: {
    en: 'View Full Pricebook',
    es: 'Ver lista de precios completa'
  },
  invoiceCreate: {
    en: 'Create Invoice',
    es: 'Crear factura'
  },
  Active: {
    en: 'Active',
    es: 'Activo'
  },
  Approve: {
    en: 'Approve',
    es: 'Aprobar'
  },
  Approved: {
    en: 'Approved',
    es: 'Aprobado'
  },
  Cancelled: {
    en: 'Cancelled',
    es: 'Cancelado'
  },
  Draft: {
    en: 'Draft',
    es: 'Sequía'
  },
  Expired: {
    en: 'Expired',
    es: 'Caducado'
  },
  scheduleVisits: {
    en: 'Schedule Visits',
    es: 'Programar visitas'
  },
  dayBeforeDueDate: {
    en: 'day(s) before due date',
    es: 'día (s) antes de la fecha de vencimiento'
  },
  startingTime: {
    en: 'Starting Time',
    es: 'Tiempo de empezar'
  },
  enterTime: {
    en: 'Enter Time',
    es: 'Ingrese la hora'
  },
  nextStep: {
    en: 'Next Step',
    es: 'Próximo paso'
  },
  report: {
    en: 'Report',
    es: 'Reporte'
  },
  editCustomerName: {
    en: 'Edit Customer Name',
    es: 'Editar el nombre del cliente'
  },
  [SyncStatus.NOT_SYNCED]: {
    en: 'Not Synced',
    es: 'No Sincronizado'
  },
  [SyncStatus.IN_SYNC]: {
    en: 'Synced',
    es: 'Sincronizado'
  },
  [SyncStatus.SYNCING]: {
    en: 'In Progress',
    es: 'En Curso'
  },
  [SyncStatus.SYNC_FAILED]: {
    en: 'Failed',
    es: 'Fallido'
  },
  [SyncStatus.BYPASSED]: {
    en: 'Bypassed',
    es: 'Sobrepasado'
  },
  addProject: {
    en: 'Add Project',
    es: 'Agregar proyecto'
  },
  addToProject: {
    en: 'Add to Project',
    es: 'Agregar al proyecto'
  },
  quoteAssociatedProject: {
    en: 'Associated Project',
    es: 'Proyecto Asociado'
  },
  quoteAssociatedJob: {
    en: 'Associated Job',
    es: 'Trabajo Asociado'
  },
  projectAssociatedQuote: {
    en: 'Associated Quote',
    es: 'Cita Asociada'
  },
  editProject: {
    en: 'Edit Project',
    es: 'Editar proyecto'
  },
  generateChageOrder: {
    en: 'Generate Change Order',
    es: 'Generar Orden de Cambio'
  },
  editChageOrder: {
    en: 'Edit Change Order',
    es: 'Editar Orden de Cambio'
  },
  addLabor: {
    en: 'Add Labor',
    es: 'Agregar mano de obra'
  },
  addMaterial: {
    en: 'Add Material',
    es: 'Agregar material'
  },
  addEquipment: {
    en: 'Add Equipment',
    es: 'Agregar equipo'
  },
  addSubcontractor: {
    en: 'Add Subcontractor',
    es: 'Agregar subcontratista'
  },
  addOther: {
    en: 'Add Other',
    es: 'Añadir otra'
  },
  addOverhead: {
    en: 'Add Overhead',
    es: 'Agregar gastos generales'
  },
  addSubmittalItem: {
    en: 'Add Submittal Item',
    es: 'Agregar artículo de envío'
  },
  editSubmittalItem: {
    en: 'Edit Submittal Item',
    es: 'Editar artículo de envío'
  },
  createSubmittalPackage: {
    en: 'Create Submittal Package',
    es: 'Crear paquete de envío'
  },
  editSubmittalPackage: {
    en: 'Edit Submittal Package',
    es: 'Editar paquete de envío'
  },
  updateSubmittalPackage: {
    en: 'Update Submittal Package',
    es: 'Actualizar paquete de envío'
  },
  previewSubmittalPackage: {
    en: 'Preview Submittal Package',
    es: 'Vista previa del paquete de envío'
  },
  import: {
    en: 'Import',
    es: 'Importar'
  },
  addAttachment: {
    en: 'Add Attachment',
    es: 'Añadir un adjunto'
  },
  addAttachmentsFromFiles: {
    en: 'Add Attachment from BuildOps Files',
    es: 'Agregar archivo adjunto desde archivos BuildOps'
  },
  uploadAttachment: {
    en: 'Upload Attachment',
    es: 'Subir archivo adjunto'
  },
  generatePayApplication: {
    en: 'Generate Pay Application',
    es: 'Generar aplicación de pago'
  },
  editPayApplication: {
    en: 'Edit Pay Application',
    es: 'Editar aplicación de pago'
  },
  createPurchaseOrder: {
    en: 'Create Purchase Order',
    es: 'Crear orden de compra'
  },
  createFieldOrder: {
    en: 'Create Field Order',
    es: 'Crear orden de campo'
  },
  addFieldOrder: {
    en: 'Add Field Order',
    es: 'Agregar orden de campo'
  },
  purchaseOrders: {
    en: 'Purchase Orders',
    es: 'Orden de compra'
  },
  purchaseOrderItems: {
    en: 'Purchase Order Items',
    es: 'Artículos de orden de compra'
  },
  addNewLineItem: {
    en: 'Add Purchase Order Item',
    es: 'Agregar artículo de orden de compra'
  },
  addPurchaseOrder: {
    en: 'Add Purchase Order',
    es: 'Agregar orden de compra'
  },
  quoteAssociatedPurchaseOrder: {
    en: 'Associated Purchase Orders',
    es: 'Ordenes de compra asociadas'
  },
  generateReceipt: {
    en: 'Generate Receipt',
    es: 'Generar recibo'
  },
  addNewReceiptItem: {
    en: 'Add Receipt Item',
    es: 'Agregar artículo de recibo'
  },
  receiveAll: {
    en: 'Receive All',
    es: 'Recibir todo'
  },
  postReceipt: {
    en: 'Post Receipt',
    es: 'Recibo posterior'
  },
  previewReceipt: {
    en: 'Preview Receipt',
    es: 'Recibo de vista previa'
  },
  saveAsDraft: {
    en: 'Save As Draft',
    es: 'Guardar como borrador'
  },
  assignFieldOrder: {
    en: 'Assign Field Order',
    es: 'Asignar orden de campo'
  },
  editPurchaseOrderItem: {
    en: 'Edit Purchase Order Item',
    es: 'Editar artículo de orden de compra'
  },
  editPurchaseOrderReceipt: {
    en: 'Edit Purchase Order Receipt',
    es: 'Editar recibo de orden de compra'
  },
  editPurchaseOrderReceiptItem: {
    en: 'Edit Receipt Item',
    es: 'Editar artículo de recibo'
  },
  wipReport: {
    en: 'WIP Report',
    es: 'WIP Informe'
  },
  wipReports: {
    en: 'WIP Reports',
    es: 'WIP Informes'
  },
  addItem: {
    en: 'Add Item',
    es: 'Añadir artículo'
  },
  addSovItem: {
    en: 'Add SOV Item',
    es: 'Agregar elemento SOV'
  },
  editItem: {
    en: 'Edit Item',
    es: 'Editar artículo'
  },
  editSovItem: {
    en: 'Edit SOV Item',
    es: 'Editar artículo SOV'
  },
  savePackage: {
    en: 'Save Package',
    es: 'Guardar paquete'
  },
  addSelectedItems: {
    en: 'Add Selected Item(s)',
    es: 'Agregar artículo seleccionado(s)'
  }
};

export default labelSchema;
