import gql from 'graphql-tag';

const deleteInventoryPart = gql`
  mutation deleteInventoryPart($partitionKey: String!, $id: String!) {
    deleteInventoryPart(partitionKey: $partitionKey, id: $id) {
      itemName
      id
      sortKey
    }
  }
`;

export default deleteInventoryPart;
