import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { AppConstants } from 'utils/AppConstants';
import { Text, View } from '@react-pdf/renderer';
import styles from '../style';

const ContinuationSheetDescriptions = props => {
  const { project, payApplication } = props;

  return (
    <View style={styles.displayFlex}>
      <View style={[styles.displayFlexDirectionColumn, { width: '62%' }]}>
        <Text style={styles.text}>
          Document Continuation Original Contract, APPLICATION AND CERTIFICATION FOR PAYMENT,
        </Text>
        <Text style={styles.text}>containing Contractor's signed certification is attached.</Text>
        <Text style={styles.text}>
          In tabulations below, amounts are stated to the nearest dollar.
        </Text>
      </View>
      <View style={[styles.displayFlexDirectionColumn, { width: '38%' }]}>
        <View style={styles.displayFlex}>
          <Text style={[styles.text, styles.longestFieldName]}>APPLICATION NO:</Text>
          <Text style={styles.text}>
            {Number(payApplication?.number) || ''}
            {'  '}OF{'  '}
            {project?.PayApplication?.length || ''}
          </Text>
        </View>
        <View style={styles.displayFlex}>
          <Text style={[styles.text, styles.longestFieldName]}>APPLICATION DATE:</Text>
          <Text style={styles.text}>
            {payApplication?.applicationDate
              ? moment.unix(payApplication?.applicationDate).format('L')
              : null}
          </Text>
        </View>
        <View style={styles.displayFlex}>
          <Text style={[styles.text, styles.longestFieldName]}>PERIOD:</Text>
          <Text style={styles.text}>
            {payApplication?.periodFrom
              ? moment.unix(payApplication?.periodFrom).format('L')
              : null}
            {'  '}TO{'  '}
            {payApplication?.periodTo ? moment.unix(payApplication?.periodTo).format('L') : null}
          </Text>
        </View>
        <View style={styles.displayFlex}>
          <Text style={[styles.text, styles.longestFieldName]}>PROJECT NUMBER:</Text>
          <Text style={styles.text}>{project?.number || null}</Text>
        </View>
        <View style={styles.displayFlex}>
          <Text style={[styles.text, styles.longestFieldName]}>CONTRACT DATE:</Text>
          <Text style={styles.text}>
            {project?.ScheduleOfValue?.id
              ? moment.unix(project?.ScheduleOfValue?.createdDate).format(AppConstants.DATE_FORMAT)
              : null}
          </Text>
        </View>
        <View style={styles.displayFlex}>
          <Text style={[styles.text, styles.longestFieldName]}>CONTRACT FOR:</Text>
          <Text style={styles.text}>{payApplication?.contractFor || null}</Text>
        </View>
      </View>
    </View>
  );
};

ContinuationSheetDescriptions.propTypes = {
  project: PropTypes.object.isRequired,
  payApplication: PropTypes.object.isRequired
};

export default ContinuationSheetDescriptions;
