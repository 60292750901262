import gql from 'graphql-tag';

const savePropertyAssets = gql`
  mutation savePropertyAssets($data: String!, $instructionSet: String!) {
    savePropertyAssets(data: $data, instructionSet: $instructionSet) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      assetName
      imageUrl
      make
      modelNumber
      isActive
      serialNo
      installDate
      location
      propertyZone
      lastUpdatedDateTime
      version
      parentId
      # can remove notes - leaving for backwards compatibility and mobile
      notes(entityConnection: "Note") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          subject
          note
          createdBy
          lastUpdatedDate
          lastUpdatedDateTime
          lastUpdatedBy
          version
        }
      }
    }
  }
`;

export default savePropertyAssets;
