import { DragScenarios, ItemTypes } from '@dispatch/dnd/dnd.constants';

export const selectDragScenario = item => {
  switch (item?.type) {
    case ItemTypes.TABLE_VISIT: {
      const visit = item.data;

      if (!visit.dndTransition) return DragScenarios.NO_DRAG;

      if (visit.extraTechsRequired || visit.extraTechsNumber || visit.extraTechs?.length) {
        return visit.extraTechs.length >= visit.extraTechsNumber && visit.primaryTechId
          ? DragScenarios.FULLY_ASSIGNED_MULTI_TECH
          : DragScenarios.NO_DRAG;
      }

      return visit.primaryTechId
        ? DragScenarios.FULLY_ASSIGNED_SINGLE_TECH
        : DragScenarios.UNASSIGNED_SINGLE_TECH;
    }
    case ItemTypes.BOARD_VISIT: {
      const visit = item.data;

      if (!visit.dndTransition) return DragScenarios.NO_DRAG;

      return DragScenarios.VISIT_BOARD_TO_BOARD;
    }
    case ItemTypes.NON_VISIT_EVENT: {
      return DragScenarios.NON_VISIT_BOARD_TO_BOARD;
    }
    case ItemTypes.VISIT_START: {
      return DragScenarios.VISIT_START;
    }
    case ItemTypes.VISIT_END: {
      return DragScenarios.VISIT_END;
    }
    case ItemTypes.EVENT_START: {
      return DragScenarios.EVENT_START;
    }
    case ItemTypes.EVENT_END: {
      return DragScenarios.EVENT_END;
    }
    case ItemTypes.MAN_DAY: {
      return DragScenarios.MAN_DAY_BOARD_TO_BOARD;
    }
    case ItemTypes.MAN_DAY_START: {
      return DragScenarios.MAN_DAY_START;
    }
    case ItemTypes.MAN_DAY_END: {
      return DragScenarios.MAN_DAY_END;
    }
    default: {
      return DragScenarios.NO_DRAG;
    }
  }
};
