import gql from 'graphql-tag';

const softDeletePaymentById = gql`
  mutation softDeletePayment($partitionKey: String!, $id: String!) {
    softDeletePayment(partitionKey: $partitionKey, id: $id) {
      id
      version
      exportStatus
      paymentStatus
      deletedDate
    }
  }
`;

export default softDeletePaymentById;
