import gql from 'graphql-tag';

const softDeletePaymentType = gql`
  mutation softDeletePaymentType($partitionKey: String!, $id: String!) {
    softDeletePaymentType(partitionKey: $partitionKey, id: $id) {
      id
      name
      version
      deletedDate
      accountingRefId
      lastUpdatedDateTime
      ledgerAccountId
      ledgerAccount(entityConnection: "LedgerAccount") {
        name
        accountNumber
        accountType
        accountingRefId
        accountingApplication
        id
      }
    }
  }
`;

export default softDeletePaymentType;
