import useExtendedMutation from 'customHooks/useExtendedMutation';
import { GET_REVIEW_REPORT_BY_ID } from 'scenes/Jobs/JobDetail/queries';
import DELETE_FEE from 'services/core/graphql/review-report/mutations/DeleteFee';

const serializer = ({ tenantId, fee }) => {
  return {
    variables: {
      partitionKey: tenantId,
      id: fee.id
    }
  };
};

export const useDeleteReviewReportFee = reviewReportId => {
  return useExtendedMutation(DELETE_FEE, {
    serializer,
    showConfirmation: true,
    confirmationModalOptions: {
      title: 'Delete Fee',
      warn: true,
      modalText: 'Are you sure you want to delete this fee?'
    },
    update: (cache, { data: { deleteFee } }) => {
      const deletedFee = deleteFee;
      if (!deletedFee) {
        return;
      }
      const cachedData = cache.readQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        }
      });
      cache.writeQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        },
        data: {
          getReviewReportById: {
            ...(cachedData?.getReviewReportById || {}),
            fees: {
              __typename: 'FeeConnection',
              items: [
                ...cachedData.getReviewReportById?.fees?.items.filter(
                  fee => fee.id !== deletedFee.id
                )
              ]
            }
          }
        }
      });
    }
  });
};
