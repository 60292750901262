import gql from 'graphql-tag';

const getAllAuditLogForCompanyInRange = gql`
  query getAuditLogs($partitionKey: String!, $sortKey: String!, $startTime: Int!, $endTime: Int!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      allAuditLogs(
        entityConnection: "AuditLogEntry"
        sortDirection: "DESC"
        rangeFilter: { startTime: $startTime, endTime: $endTime }
      ) {
        items {
          auditedEntityType
          auditedEntityDisplayName
          executionType
          executedBy
          executedDateTime
          auditedEntityId
          auditedEntitySortKey
          auditedEntityParentId
          auditedEntityParentSortKey
          auditedEntityParentEntityType
          auditedEntityParentDisplayName
          changeLog
        }
      }
    }
  }
`;

export default getAllAuditLogForCompanyInRange;
