/* eslint-disable no-template-curly-in-string */
const basic2PDFForm = settings => ({
  fields: {
    senderInfoLogoUrl: {
      pdf: '${senderInfoLogo}'
    },
    tenantAddress: {
      pdf: '${senderInfo.addressLine1}  *  ${senderInfo.addressLine3}  *  ${senderInfo.phoneNumber}'
    },
    // Customer Detail
    customerContactNameTitle: {
      pdf: 'Attn: '
    },
    customerContactName: {
      pdf: '${customerContactNameTitle}'
    },
    customerNameTitle: {
      pdf: 'Company: '
    },
    customerName: {
      pdf: '${customer.customerName}'
    },
    addressTitle: {
      pdf: 'Address: '
    },
    billingAddressLine1: {
      pdf: '${billingAddressLine1}'
    },
    billingAddressLine2: {
      pdf: '${billingAddressLine2}'
    },
    billingAddress3: {
      pdf: '${billingAddressLine3}'
    },
    customerContactPhoneTitle: {
      pdf: 'Phone: '
    },
    customerContactPhone: {
      pdf: '${customerContactPhoneTitle}'
    },
    customerContactEmailTitle: {
      pdf: 'Via Email: '
    },
    customerContactEmail: {
      pdf: '${customerContactEmailTitle}'
    },
    // Tenant Detail
    createdDateTitle: {
      pdf: 'Date: '
    },
    createdDate: {
      pdf: '${createdDate}'
    },
    quoteNameTitle: {
      pdf: 'Re: '
    },
    quoteName: {
      pdf: '${quoteInfo.name} ${quoteInfo.versionLabel}'
    },
    propertyLabel: {
      pdf: 'Property: '
    },
    propertyName: {
      pdf: '${property.companyName}'
    },
    propertyAddressLine1: {
      pdf: '${propertyDetail.propertyAddress1}'
    },
    propertyAddressLine2: {
      pdf: '${propertyDetail.propertyAddress2}'
    },
    propertyAddressLine3: {
      pdf: '${propertyDetail.propertyAddress3}'
    },
    quoteNumberValueTitle: {
      pdf: 'File Number: '
    },
    quoteNumberValue: {
      pdf: '${customizedQuoteNumber}'
    },
    // Introduction
    introduction: {
      pdf: '${quoteCustomFieldsData.Custom_longText1}'
    },
    // Total
    totalTitle: {
      pdf: 'Total Proposal: '
    },
    totalText: {
      pdf: '$${finalAmount}'
    },
    // Closing Remarks
    closingRemarks: {
      pdf: '${quoteCustomFieldsData.Custom_longText2}'
    }
  },
  layouts: {
    default: {
      options: {
        pdfTitle: 'Quote'
      },
      contents: [
        // Logo Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [6, 36, 6]
          },
          contents: [
            {
              options: {
                direction: 'column',
                size: 'auto',
                grow: 0,
                shrink: 1
              },
              contents: [
                {
                  component: {
                    default: 'Logo'
                  },
                  options: {
                    width: 500,
                    height: 200,
                    alt: 'Company logo',
                    size: 'auto',
                    grow: 0,
                    shrink: 1
                  },
                  source: 'senderInfoLogoUrl'
                }
              ]
            }
          ]
        },
        // Address Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [0, 0, 12]
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'BodyText'
              },
              source: 'tenantAddress'
            }
          ]
        },
        // Customer Detail Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'space-between',
            padding: [6, 36, 6]
          },
          contents: [
            // Customer Details
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                direction: 'column',
                width: '50%',
                padding: [0, 5, 0, 0]
              },
              contents: [
                // Contact Name
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactName'
                    }
                  ]
                },
                // Constact Phone
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactPhoneTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactPhone'
                    }
                  ]
                },
                // Contact Email
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactEmailTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactEmail'
                    }
                  ]
                },
                // Customer Name
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerName'
                    }
                  ]
                },
                // Customer Address
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'addressTitle'
                    },
                    {
                      options: {
                        size: 'auto',
                        grow: 0,
                        shrink: 1,
                        direction: 'column'
                      },
                      contents: [
                        {
                          component: { pdf: 'BoldText' },
                          source: 'billingAddressLine1'
                        },
                        {
                          component: { pdf: 'BoldText' },
                          source: 'billingAddressLine2'
                        },
                        {
                          component: { pdf: 'BoldText' },
                          source: 'billingAddressLine3'
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            // Tenant Details
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                direction: 'column',
                width: '35%'
              },
              contents: [
                // Created Date
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'createdDateTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'createdDate'
                    }
                  ]
                },
                // Quote Name
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'quoteNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'quoteName'
                    }
                  ]
                },
                // Quote Number
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'quoteNumberValueTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'quoteNumberValue'
                    }
                  ]
                },
                // Property Name
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'propertyLabel'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'propertyName'
                    }
                  ]
                },
                // Property Address
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'addressTitle'
                    },
                    {
                      options: {
                        size: 'auto',
                        grow: 0,
                        shrink: 1,
                        direction: 'column'
                      },
                      contents: [
                        {
                          component: { pdf: 'BoldText' },
                          source: 'propertyAddressLine1'
                        },
                        {
                          component: { pdf: 'BoldText' },
                          source: 'propertyAddressLine2'
                        },
                        {
                          component: { pdf: 'BoldText' },
                          source: 'propertyAddressLine3'
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        // Divider
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [6, 36, 6]
          },
          contents: [
            {
              options: {
                color: '#333'
              },
              component: {
                pdf: 'Divider'
              }
            }
          ]
        },
        // Intro
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'RichContentViewer'
              },
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1
              },
              source: 'introduction'
            }
          ]
        },
        // Tasks and Products
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
          },
          contents: [
            {
              options: {
                direction: 'column',
                justify: 'start',
                size: 'auto',
                grow: 0,
                shrink: 1,
                padding: [0, 0, 0, 0]
              },
              contents: [
                {
                  component: {
                    pdf: 'TaskList'
                  },
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    width: 480
                  },
                  source: 'quoteTasks'
                }
              ]
            }
          ]
        },
        // Total
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'flex-end',
            align: 'stetch',
            padding: [6, 36, 6],
            hide: settings.hideTotalAmount
          },
          contents: [
            {
              component: {
                pdf: 'Subtitle'
              },
              source: 'totalTitle'
            },
            {
              component: {
                pdf: 'Subtitle'
              },
              source: 'totalText'
            }
          ]
        },
        // Closing Remarks
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'RichContentViewer'
              },
              source: 'closingRemarks'
            }
          ]
        }
      ]
    }
  }
});

export default basic2PDFForm;
