import React, { useRef, memo, useState } from 'react';

import compose from 'utils/compose';

import {
  withDispatchActions,
  withDispatchStore,
  withQueryStringStore
} from '@dispatch/Dispatch.store';

import useAddNonVisit from '@dispatch/mutations/useAddNonVisit';
import { EventEntities } from '@dispatch/Dispatch.constants';
import useJobDetails from '@dispatch/queries/useJobDetails';

import CreateBillableEvent from './CreateBillableEvent.component';

const mapActionsToProps = ({ closeDrawer }) => ({
  closeDrawer
});

const mapDispatchToProps = store => ({
  eventType: store.state.eventType,
  eventData: store.state.eventData
});

const mapQueryStringToProps = store => ({
  day: store.state.day
});

const DataProvider = props => {
  const Memoized = useRef(memo(CreateBillableEvent)).current;
  // eslint-disable-next-line react/prop-types
  const { day } = props;
  const [visit, setVisit] = useState();

  const addNonVisitTuple = useAddNonVisit(day, EventEntities.BILLABLE_EVENT.value.serverValue);

  const jobDetailsResponse = useJobDetails(visit?.jobNumber);

  return (
    <Memoized
      {...props}
      addNonVisitTuple={addNonVisitTuple}
      jobDetailsResponse={jobDetailsResponse}
      visit={visit}
      setVisit={setVisit}
    />
  );
};

export default compose(
  withDispatchActions(mapActionsToProps),
  withDispatchStore(mapDispatchToProps),
  withQueryStringStore(mapQueryStringToProps)
)(DataProvider);
