import gql from 'graphql-tag';

const createJobTag = gql`
  mutation addJobTagsToCompany(
    $partitionKey: String!
    $companyId: String!
    $data: [CompanyJobTagInput]!
  ) {
    addJobTagsToCompany(
      partitionKey: $partitionKey
      data: { companyId: $companyId, jobTags: $data }
    ) {
      id
      tagType
      tagName
      sortOrder
    }
  }
`;

export default createJobTag;
