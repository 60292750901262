import gql from 'graphql-tag';

const updateCustomerRep = gql`
  mutation updateCustomerRepAndRealated(
    $paritionKey: String!
    $data: UpdateCustomerRepAndRealatedInput!
  ) {
    updateCustomerRepAndRealated(partitionKey: $paritionKey, data: $data) {
      id
      name
      status
      isActive
    }
  }
`;

export default updateCustomerRep;
