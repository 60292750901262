import React from 'react';
import { ResponsiveTable, SergeantModal } from 'components';
import { Mode } from 'utils/constants';
import AutoComplete from './CustomCell/AutoComplete';
import CostDetailInput from './CustomCell/CostDetailInput';
import BillingDetailInput from './CustomCell/BillingDetailInput';
import { makeCustomComponentDetails } from './helpers';
import { maxRecordCount } from './constants';

const Table = ({
  lineItems,
  meta,
  isLoading,
  employees,
  onEdit,
  onDelete,
  labourTypes,
  reportEditable,
  departmentName,
  isDefaultRate,
  handleAutocompleteSelect,
  labourLineItems,
  onChangePage,
  onChangeRowsPerPage
}) => {
  const [confirmModalSetting, setConfirmModalSetting] = React.useState({});
  const customComponentDetails = makeCustomComponentDetails({
    employees,
    isDefaultRate,
    labourTypes,
    labourLineItems
  });

  const handleRowAction = (actionName, record, rowIndex) => {
    if (actionName === 'delete') {
      setConfirmModalSetting({
        open: true,
        data: record,
        handlePrimaryAction: (data, stopSpinning) => {
          onDelete(rowIndex, record.id);
          setConfirmModalSetting({ open: false });
          stopSpinning();
        },
        layout: null
      });
    }
  };

  return (
    <>
      <ResponsiveTable
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        data={lineItems}
        disableFilter
        rowMetadata={meta}
        isLoading={isLoading}
        rowsPerPage={maxRecordCount}
        rowActions={handleRowAction}
        rowActionButtons={
          reportEditable && {
            delete: {
              label: 'Delete',
              icon: 'DeleteOutlined'
            }
          }
        }
        customCellComponents={{
          ...customComponentDetails.reduce((acc, val) => {
            const { key, options, disableEdit = false } = val;
            acc[key] = ({ ...rowProps }) => (
              <AutoComplete
                options={options}
                isEditable={reportEditable && !disableEdit}
                placeholder={val.placeholder}
                value={rowProps.record[rowProps.meta.dataKey]}
                menuPortalTarget={document.querySelector('body')}
                handleOnChange={value => handleAutocompleteSelect({ value, ...rowProps })}
              />
            );
            return acc;
          }, {}),
          Department: () => departmentName || '-',
          BillingDetailInput: ({ rowIndex, ...rowProps }) => (
            <BillingDetailInput
              {...rowProps}
              isEditable={reportEditable}
              onEdit={(key, value, itemIndex) =>
                onEdit({
                  key,
                  value,
                  rowIndex,
                  itemIndex,
                  hourTypeId: rowProps.meta.billingHourTypeId
                })
              }
            />
          ),
          CostDetailInput: ({ rowIndex, ...rowProps }) => (
            <CostDetailInput
              {...rowProps}
              isEditable={reportEditable}
              onEdit={(key, value) => onEdit({ rowIndex, key, value })}
            />
          )
        }}
        key={meta.length}
      />
      <SergeantModal
        layout={null}
        open={false}
        dataType="Entry"
        mode={Mode.DELETE}
        handlePrimaryAction={() => {}}
        {...confirmModalSetting}
        handleClose={() => setConfirmModalSetting({})}
      />
    </>
  );
};

export default Table;
