import gql from 'graphql-tag';

const getAttachmentsByCustomerId = gql`
  query getAttachmentsByCustomerId($id: String!) {
    getCustomerById(id: $id) {
      id
      attachments(entityConnection: "Attachment") {
        items {
          fileName
          customFileName
          fileUrl
          id
          version
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          description
          comment
          createdDate
          createdDateTime
          createdBy
        }
      }
    }
  }
`;
export default getAttachmentsByCustomerId;
