import LRU from 'lru-cache';

function createCache(max, maxAge) {
  return new LRU({
    max,
    maxAge
  });
}

export const cachePM = createCache(200, 1200000); // maxAge -> 1000 * 60 * 20 -> 20 min
export const cacheREST = createCache(200, 1200000); // maxAge -> 1000 * 60 * 20 -> 20 min
