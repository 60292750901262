import { numberWithCommas } from 'utils/numberWithCommas';

const WIPDetailFormConfig = {
  fields: {
    recordNumber: {
      default: 'recordNumber'
    },
    asOfDate: {
      default: 'createdDate'
    },
    createdBy: {
      default: 'createdBy'
    },
    updatedContractsAmount: {
      default: 'updatedContractsAmount'
    },
    updatedCostBudgets: {
      default: 'updatedCostBudgets'
    },
    updatedEstimatedGrossProfits: {
      default: 'updatedEstimatedGrossProfits'
    },
    actualCostsToDate: {
      default: 'actualCostsToDate'
    },
    revenueCompletedToDate: {
      default: 'revenueCompletedToDate'
    },
    billedToDate: {
      default: 'billedToDate'
    },
    costToComplete: {
      default: 'costToComplete'
    },
    overBilled: {
      default: 'overBilled'
    },
    underBilled: {
      default: 'underBilled'
    },
    totalHours: {
      default: 'totalHours'
    },
    hoursUsed: {
      default: 'hoursUsed'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          options: {
            size: 4,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'recordNumber',
                  options: {
                    label: 'Record Number',
                    type: 'number',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'createdDate',
                  options: {
                    label: 'As of Date',
                    type: 'dateTime',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'createdBy',
                  options: {
                    label: 'Created By',
                    type: 'string',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 4,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'updatedContractsAmount',
                  options: {
                    label: 'Updated Contract Amounts',
                    type: 'currency',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'updatedCostBudgets',
                  options: {
                    label: 'Updated Cost Budgets',
                    type: 'currency',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'updatedEstimatedGrossProfits',
                  options: {
                    label: 'Updated Estimated Gross Profits',
                    type: 'currency',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 4,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'actualCostsToDate',
                  options: {
                    label: 'Actual Costs To Date',
                    type: 'currency',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'revenueCompletedToDate',
                  options: {
                    label: 'Revenue Completed To Date',
                    type: 'currency',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'billedToDate',
                  options: {
                    label: 'Billed to Date',
                    type: 'currency',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 4,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'costToComplete',
                  options: {
                    label: 'Costs to Complete',
                    type: 'currency',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'overBilled',
                  options: {
                    label: 'Over Billed',
                    type: 'currency',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'underBilled',
                  options: {
                    label: 'Under Billed',
                    type: 'currency',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 4,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'totalHours',
                  options: {
                    label: 'Total Hours',
                    type: 'custom',
                    transform: val => (val ? numberWithCommas(val) : '-'),
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'hoursUsed',
                  options: {
                    label: 'Hours Used',
                    type: 'custom',
                    transform: val => (val ? numberWithCommas(val) : '-'),
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: []
            }
          ]
        }
      ]
    }
  }
};

export default WIPDetailFormConfig;
