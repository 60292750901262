import gql from 'graphql-tag';

const updateCustomerAndRelated = gql`
  mutation updateCustomerAndRelated($data: UpdateCustomerAndRelatedInput!) {
    updateCustomerAndRelated(data: $data) {
      id
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      version
      status
      syncStatus
      syncLog
      isTaxable
      isActive
      invoicePresetId
      priceBookId
    }
  }
`;

export default updateCustomerAndRelated;
