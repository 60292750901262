import React, { useEffect, memo } from 'react';
import { any, bool } from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';

import BoardLayout from './components/BoardLayout';
import DispatchHeader from './components/DispatchHeader';
import DispatchBoard from './components/DispatchBoard';
import Drawer from './components/Drawer';
import JobsView from './components/JobsView';
import VisitsTabs from './components/VisitsTabs';

const Dispatch = ({ mapView, client }) => {
  const { jobDispatch } = useFlags();
  useEffect(() => {
    // ensure all data is fresh when navigating back to dispatch
    client.resetStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BoardLayout.FullScreenContainer>
      <BoardLayout.LeftContent>
        <BoardLayout.Header>
          <DispatchHeader />
        </BoardLayout.Header>
        <BoardLayout.Board>
          <DispatchBoard />
        </BoardLayout.Board>
        {!mapView && (
          <BoardLayout.BottomTray>
            {jobDispatch ? <JobsView /> : <VisitsTabs />}
          </BoardLayout.BottomTray>
        )}
      </BoardLayout.LeftContent>
      <BoardLayout.RightDrawer>
        <Drawer />
      </BoardLayout.RightDrawer>
    </BoardLayout.FullScreenContainer>
  );
};

Dispatch.propTypes = {
  mapView: bool.isRequired,
  client: any.isRequired
};

export default memo(Dispatch);
