import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography, Box } from '@material-ui/core';
import Labels from 'meta/labels';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import LaborRate from 'components/LabourRateSection/LabourRates';
import FlatMarkup from 'components/MaterialRateSection/FlatMarkup';
import MaterialRateRangeInput from 'components/MaterialRateSection/MaterialRateRangeInput';
import { compose } from 'redux';
import styles from 'components/MaterialRateSection/style';
import { PricingStrategy } from 'utils/constants';

const PricebookRateCard = props => {
  const { user, classes, laborItems, laborMeta, materialMarkupEntries } = props;
  const isRangeMarkup = materialMarkupEntries?.length > 1;
  const pricingStrategy = useSelector(s => s.settings.pricingStrategy);
  const isMarginEnabled =
    pricingStrategy === PricingStrategy.MARGIN ||
    pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN;
  const isMarkupEnabled =
    pricingStrategy === PricingStrategy.MARKUP ||
    pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN;

  const displayMaterialRateLabel = () => {
    if (isMarkupEnabled && !isMarginEnabled) {
      return Labels.materialMarkupRates[user.locale];
    }
    if (isMarginEnabled && !isMarkupEnabled) {
      return Labels.materialMarginRates[user.locale];
    }
    return Labels.materialMarkupAndMarginRates[user.locale];
  };

  return (
    <Grid item xs={12}>
      <Box component="div">
        <Typography variant="h6" style={{ marginTop: '1.5rem' }}>
          {Labels.laborRates[user.locale]}
        </Typography>
      </Box>
      <LaborRate isReadOnly metaData={laborMeta} data={laborItems} disableFilter />
      <Box component="div">
        <Typography variant="h6" style={{ marginTop: '2rem' }}>
          {displayMaterialRateLabel()}
        </Typography>
      </Box>
      {isRangeMarkup ? (
        <MaterialRateRangeInput
          classes={classes}
          startRangeKey="start"
          endRangeKey="end"
          markupKey="rate"
          isViewMode
          minRangeLabel="$0.00"
          maxRangeLabel="MAX $"
          rangeData={materialMarkupEntries}
        />
      ) : (
        <FlatMarkup
          flatMarkup={materialMarkupEntries}
          adornmentSymbol="%"
          showAdornment
          isViewMode
        />
      )}
    </Grid>
  );
};

PricebookRateCard.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, null)
)(PricebookRateCard);
