import gql from 'graphql-tag';

const addFeeToReviewReport = gql`
  mutation addFeeToReviewReport($partitionKey: String!, $data: AddFeeToReviewReportInput!) {
    addFeeToReviewReport(partitionKey: $partitionKey, data: $data) {
      id
      sortKey
      itemName
      createdDateTime
      reasonForFee
      departmentName
      accountingRefIdOfClass
      taxable
      version
      amount
      includeInInvoice
    }
  }
`;

export default addFeeToReviewReport;
