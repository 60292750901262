import React from 'react';
import PropTypes from 'prop-types';
import { capitalizeFirstLetter, getAddressAndLocation } from 'utils';
import { ADDRESS_FIELDS } from 'utils/constants';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import FieldWithLabel from 'components/BuildHeroFormComponents/FieldWithLabel';
import { EditAddressModal } from 'components/Modal/EditAddressModal';

const AddressEditAndView = props => {
  const { options, field, form } = props;
  const {
    taxOptions,
    updateTaxRateId,
    addressTypeLabel,
    showAddressModal,
    closeAddressModal,
    addressLocationSource,
    addressViewSource,
    addressEditSource,
    disableTaxRateAutoAssign,
    nullIfNoMatch = true
  } = options;
  const { values, setFieldValue } = form;

  let fieldWithLabelValue = field.value;
  if (field.name === addressEditSource) {
    /**
     * @description extract value for FieldWithLabel for EDIT mode
     */
    const addressViewValue = values[addressViewSource];
    fieldWithLabelValue = addressViewValue;
  }

  const updateAddress = data => {
    const addressObj = pick(data, ADDRESS_FIELDS);
    /**
     * @description transforming data from addressLine1 -> billingAddressLine1 (or propertyAddressLine1)
     */
    const updatedAddressFields = reduce(
      addressObj,
      (acc, value, key) => {
        return { ...acc, [`${addressTypeLabel}${capitalizeFirstLetter(key)}`]: value };
      },
      {}
    );

    const { address: updatedAddress, location: updatedLocation } = getAddressAndLocation(
      addressObj
    );
    setFieldValue(addressViewSource, updatedAddress);
    setFieldValue(addressEditSource, {
      ...values[addressEditSource],
      ...updatedAddressFields
    });
    setFieldValue(addressLocationSource, updatedLocation);

    // Only update tax rate when enable auto-assign and address has changed
    if (!disableTaxRateAutoAssign && data?.addressChanged) {
      // Empty the taxRateId when nullIfNoMatch is true and there is no match
      if (nullIfNoMatch) {
        updateTaxRateId(data?.taxRateId || '');
      } else if (data?.taxRateId) {
        updateTaxRateId(data?.taxRateId);
      }
    }
  };
  return (
    <>
      <FieldWithLabel {...props} field={{ ...field, value: fieldWithLabelValue }} />
      <EditAddressModal
        open={showAddressModal}
        onClose={() => closeAddressModal(false)}
        data={{ ...field.value }}
        editAddressType={addressTypeLabel}
        onComplete={d => {
          updateAddress(d);
          closeAddressModal(false);
        }}
        dataType={`${addressTypeLabel} Address`}
        taxOptions={taxOptions || []}
      />
    </>
  );
};

AddressEditAndView.propTypes = {
  options: PropTypes.shape({
    taxOptions: PropTypes.arrayOf(PropTypes.object),
    updateTaxRateId: PropTypes.func.isRequired,
    addressTypeLabel: PropTypes.string.isRequired,
    showAddressModal: PropTypes.bool.isRequired,
    closeAddressModal: PropTypes.func.isRequired,
    addressLocationSource: PropTypes.string.isRequired,
    addressViewSource: PropTypes.string.isRequired,
    addressEditSource: PropTypes.string.isRequired,
    disableTaxRateAutoAssign: PropTypes.bool.isRequired,
    nullIfNoMatch: PropTypes.bool.isRequired
  }).isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired
};

export default AddressEditAndView;
