import AmplifyService from 'services/AmplifyService';
import getBankAccounts from './query/getBankAccounts';
import getGLAccounts from './query/getGLAccounts';

function AccountingService() {
  const api = AmplifyService.appSyncClient();
  return {
    fetchGLAccounts: async (partitionKey, sortKey, nextToken) => {
      const params = {
        partitionKey,
        sortKey,
        nextToken
      };
      const response = await api.query(getGLAccounts, params);
      return response;
    },
    fetchBankAccounts: async (partitionKey, sortKey, nextToken) => {
      const params = {
        partitionKey,
        sortKey,
        nextToken
      };
      const response = await api.query(getBankAccounts, params);
      return response;
    }
  };
}
export default AccountingService;
