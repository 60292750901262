import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  search: {
    marginBottom: 0,
    '& input': {
      paddingTop: theme.spacing(1)
    }
  },
  searchContainer: {
    marginBottom: theme.spacing(0.2)
  },
  searchIcon: {
    marginTop: `${theme.spacing(0.2)}px !important`
  },
  labelContainer: {
    fontWeight: 'normal',
    fontSize: '16px',
    padding: `${theme.spacing(0.8)}px ${theme.spacing(1)}px`,
    '&:hover': {
      background: theme.palette.grayscale(92)
    }
  },
  disabled: {
    '&:hover': {
      background: 'none'
    }
  },
  boldText: {
    marginLeft: theme.spacing(0.7),
    marginRight: theme.spacing(0.7)
  }
}));
