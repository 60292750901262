import gql from 'graphql-tag';

const updateReviewReport = gql`
  mutation UpdateReviewReport($partitionKey: String, $data: UpdateReviewReportInput!) {
    updateReviewReport(partitionKey: $partitionKey, data: $data) {
      status
      discount
      messageToCustomerInvoice
      id
      priceBookId
      isLabourRateLineItemFromTimeTracking
      version
      includeMessageToCustomerInInvoice
    }
  }
`;

export default updateReviewReport;
