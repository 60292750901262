import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, pick, isEmpty } from 'lodash';
import { snackbarOn } from 'redux/actions/globalActions';
import { Box } from '@material-ui/core';
import { Select, ThemeProvider, Modal, Button, ButtonType } from '@buildhero/sergeant';
import { QuoteStatus } from 'utils/AppConstants';

import { updateTaskService } from 'scenes/Quotes/service';
import { useCustomerPropertyQuotes } from '../hooks/useCustomerPropertyQuotes';

const getMutationParams = (quote, tasks, partitionKey, propertyId) => {
  const task = tasks[0];
  const products = task?.taskEntries?.items || [];
  const getProductInfo = product => {
    if (!product || !task) return;
    let taskProductInfo = pick(product, [
      'name',
      'description',
      'productId',
      'priceBookEntryId',
      'unitPrice',
      'unitCost',
      'taxable',
      'markupType',
      'markupValue',
      'quantity'
    ]);
    if (product?.priceBookEntry?.product) {
      const { costCodeId, revenueTypeId, jobCostTypeId } = product?.priceBookEntry?.product;
      taskProductInfo = { ...taskProductInfo, costCodeId, revenueTypeId, jobCostTypeId };
    }
    const { departmentId } = task;
    return {
      departmentId,
      ...taskProductInfo
    };
  };

  return [
    {
      taskToUpdate: {
        taskId: task?.id,
        name: task?.name,
        description: task?.description,
        propertyAssetId: task?.assetId,
        quoteLineProducts: products.map(getProductInfo)
      },
      propertyId,
      quoteInfo: {
        id: quote?.data?.id,
        version: quote?.data?.version
      },
      setQuoteLineTasks: () => {},
      snackbar: () => {},
      tenantId: partitionKey
    }
  ];
};

const AddTaskToQuoteModal = ({
  open,
  onClose,
  selectedTasks,
  refetchTasks,
  customerPropertyId
}) => {
  const [quote, setQuote] = useState({});
  const [mutationLoading, setMutationLoading] = useState(false);

  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const snackbar = useCallback((...args) => dispatch(snackbarOn(...args)), [dispatch]);

  const [openQuotes, quotesLoading] = useCustomerPropertyQuotes({
    customerPropertyId,
    snackbar,
    filter: {
      stringFilters: {
        fieldName: 'Quote.status',
        filterInput: {
          eq: QuoteStatus.DRAFT
        }
      }
    },
    skip: !open
  });

  if (!open) return null;

  const options =
    openQuotes?.map(item => ({
      label: `${item?.customIdentifier || item?.quoteNumber}`,
      value: get(item, 'id'),
      data: item
    })) || [];

  const addToQuote = async () => {
    try {
      setMutationLoading(true);
      const addTasksToQuotePromises = selectedTasks?.map(task => {
        const updateTaskServiceParams = getMutationParams(
          quote,
          [task],
          user.tenantId,
          customerPropertyId
        );
        return updateTaskService(...updateTaskServiceParams);
      });
      await Promise.all(addTasksToQuotePromises);
      snackbar('success', `Task${selectedTasks?.length > 1 ? 's' : ''} added to ${quote.label}`);
      refetchTasks();
    } catch (error) {
      snackbar(
        'error',
        `Unable to add the selected task${selectedTasks?.length > 1 ? 's' : ''}`,
        error
      );
    } finally {
      setMutationLoading(false);
      onClose();
    }
  };

  return (
    <ThemeProvider>
      <Modal
        open={open}
        title="Add Task To Quote"
        onClose={onClose}
        actions={
          <Button
            type={ButtonType.PRIMARY}
            loading={mutationLoading}
            disabled={isEmpty(quote)}
            fullWidth
            onClick={addToQuote}
          >
            ADD TASK TO QUOTE
          </Button>
        }
      >
        <Box width={496} height={140}>
          <Select
            clearable
            searchable
            portal
            value={quote}
            loading={quotesLoading}
            options={options}
            onChange={d => setQuote(d)}
            placeholder="Search or Select Quote"
          />
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default AddTaskToQuoteModal;
