export const useStyles = () => ({
  root: theme => ({
    margin: theme.default.spacing(3)
  }),
  headerContainer: theme => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.default.spacing(2)
  }),
  headerText: theme => ({
    fontWeight: 'bold',
    fontSize: 14
  }),
  addButtonContainer: theme => ({
    marginLeft: theme.default.spacing(0.5)
  }),
  addButton: {
    textDecoration: 'none'
  }
});
