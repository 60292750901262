import React, { useMemo } from 'react';

import { jsx } from '@emotion/react';
import { orderBy } from 'lodash';
import { useSelector } from 'react-redux';

import { ThemeProvider, TV, TW, Typography } from '@buildhero/sergeant';
import WrapTable, { BoldCell } from 'components/WrapTable';
import useLabourTypes from 'customHooks/useLabourTypes';
import { snackbarOn } from 'redux/actions/globalActions';
import { convertToCurrencyString } from 'utils';

import { SectionHeader } from '../../Components';
import {
  getBillableEventDisplayText,
  getVisitDisplayText,
  tableCurrencyFormatter,
  timeSubmittedDisplay
} from '../../utils';

const getTimesheetData = (visit, timesheet, labourTypes, tech) => {
  const duration = Number.isInteger(timesheet.actualTotalDurationOverride)
    ? timesheet.actualTotalDurationOverride
    : timesheet.actualTotalDuration;
  const durationHours = duration / 3600;
  const durationDisplayText = durationHours > 0 ? `${durationHours} hr` : 'N/A';
  const labourType = labourTypes.find(({ id }) => id === tech?.labourTypeId);

  return {
    technician: `${tech.name} (${labourType?.name})`,
    timeSubmitted: timeSubmittedDisplay(
      timesheet,
      durationDisplayText,
      duration,
      visit.scheduledFor
    ),
    payrollHourType: timesheet.hourType?.hourType || '-',
    payrollHourTypeSortOrder: timesheet.hourType?.sortOrder,
    subtotal: timesheet.cost * durationHours,
    visitNumber: visit.visitNumber
  };
};

const getColumns = () => [
  { field: 'source', headerName: 'Source', width: 200 },
  { field: 'technician', headerName: 'Technician' },
  { field: 'timeSubmitted', headerName: 'Time Submitted', width: 125 },
  { field: 'payrollHourType', headerName: 'Payroll Hour Type', width: 140 },
  {
    field: 'subtotal',
    headerName: 'Subtotal',
    width: 100,
    valueFormatter: tableCurrencyFormatter,
    renderCell: BoldCell,
    totalGetter: ({ rows }) => rows.reduce((acc, r) => acc + r.subtotal, 0),
    renderTotal: props => (
      <Typography
        css={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}
        variant={TV.BASE}
        weight={TW.BOLD}
        numeric
      >
        {convertToCurrencyString(props.formattedValue ?? 0)}
      </Typography>
    )
  }
];

const mapVisitsToRows = (visits, labourTypes, companyTimezone) => {
  const rows = [];
  visits.forEach(visit => {
    const allTechs = [...visit.primaryTechs?.items, ...visit.extraTechs?.items].map(
      t => t.mappedEntity
    );
    const timesheetEntries = visit.timesheetEntriesView?.items || [];
    const visitTag = getVisitDisplayText(visit, companyTimezone);

    timesheetEntries
      .filter(t => t.actualTotalDurationOverride || t.actualTotalDuration)
      .forEach(timesheet => {
        const tech = allTechs.find(at => at.id === timesheet.timesheetPeriod?.parentId);
        const timesheetData = getTimesheetData(visit, timesheet, labourTypes, tech);
        rows.push({
          source: visitTag,
          ...timesheetData
        });
      });
  });

  const billableEventRows = [];
  visits.forEach(visit => {
    const nonVisitEvents = visit.nonVisitEvents?.items || [];
    nonVisitEvents.forEach(event => {
      const timesheets = event.timesheetEntries?.items || [];
      const billableEventTag = getBillableEventDisplayText(event, companyTimezone);
      timesheets
        .filter(t => t.actualTotalDurationOverride || t.actualTotalDuration)
        .forEach(timesheet => {
          const timesheetData = getTimesheetData(visit, timesheet, labourTypes, event.employee);
          billableEventRows.push({
            source: billableEventTag,
            ...timesheetData
          });
        });
    });
  });
  return [
    ...orderBy(rows, ['visitNumber', 'technician', 'payrollHourTypeSortOrder']),
    ...orderBy(billableEventRows, ['visitNumber', 'technician', 'payrollHourTypeSortOrder'])
  ];
};

const JobCloseoutQuotedLaborCosts = ({ visits, companyTimezone, isLoading }) => {
  const user = useSelector(state => state?.user);
  const { tenantId, tenantCompanyId } = user;
  const serviceArgs = [tenantId, tenantCompanyId, snackbarOn];
  const [labourTypes, , labourTypesLoaded] = useLabourTypes(...serviceArgs);

  const rows = useMemo(
    () => (labourTypesLoaded ? mapVisitsToRows(visits, labourTypes, companyTimezone) : []),
    [companyTimezone, visits, labourTypes, labourTypesLoaded]
  );
  const columns = useMemo(() => getColumns(), []);

  return (
    <ThemeProvider>
      <SectionHeader title="Labor" />
      <WrapTable
        columns={columns}
        rows={rows}
        noDataMessage="No labor data"
        hideFooter={rows.length <= 10}
        enableTotalsRow
        loading={isLoading}
        loadingRows={3}
      />
    </ThemeProvider>
  );
};

JobCloseoutQuotedLaborCosts.defaultProps = {
  isLoading: false
};

export default JobCloseoutQuotedLaborCosts;
