const styles = theme => ({
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 10,
    letterSpacing: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal'
  },
  subject: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontStretch: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    paddingTop: 7
  },
  content: {
    paddingTop: 7,
    whiteSpace: 'pre-line',
    wordBreak: 'break-word'
  },
  footer: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.grayscale(60),
    fontSize: 12,
    letterSpacing: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    paddingTop: 7,
    paddingBottom: 7
  },
  bigContent: {
    fontFamily: theme.typography.fontFamily,
    color: '#3f3f3f',
    fontSize: 14,
    letterSpacing: 0.24,
    fontWeight: 'normal',
    lineHeight: 'normal',
    paddingTop: 7,
    whiteSpace: 'pre-line',
    wordBreak: 'break-word'
  },
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing()
  },
  inputLabel: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'normal',
    letterSpacing: '0.01px',
    lineHeight: '14px'
  },
  addIcon: {
    fontSize: 18
  }
});

export default styles;
