import gql from 'graphql-tag';

const addLabourRateLineItemsToReviewReport = gql`
  mutation AddLaborLineItemToReviewReport(
    $partitionKey: String
    $data: AddLabourRateLineItemsToReviewReportInput!
  ) {
    addLabourRateLineItemsToReviewReport(partitionKey: $partitionKey, data: $data) {
      id
      employeeId
      labourTypeId
      version
      labourRateBillingHourLines(entityConnection: "LabourRateBillingHourLine") {
        items {
          id
          rate
          version
          hourTypeId
          totalHours
        }
      }
    }
  }
`;

export default addLabourRateLineItemsToReviewReport;
