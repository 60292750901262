import React from 'react';

import { shape, string } from 'prop-types';

import FileManager from '@pm/components/FileManagement/FileManager';

const Files = ({ match }) => {
  const { projectId } = match.params;
  return <FileManager projectId={projectId} />;
};

Files.propTypes = {
  match: shape({
    params: shape({
      projectId: string.isRequired
    }).isRequired
  }).isRequired
};

export default Files;
