import { generateDefaultFieldsObject } from '@pm/components/formattingUtils';

const miniFlexStyle = '0 1 60px';
const margin = '0px 15px 21px 0px';

// Equipment Information
const equipmentFields = {
  name: { required: true },
  description: { required: false },
  hours: { required: true },
  comments: { required: false }
};

const equipmentContents = [
  {
    options: {
      direction: 'row'
    },
    contents: [
      // column 1
      {
        options: {
          direction: 'column'
        },
        contents: [
          {
            options: {
              margin
            },
            contents: [
              {
                component: {
                  default: 'TextInput',
                  edit: 'TextInput'
                },
                source: 'name',
                options: {
                  label: 'Name',
                  fullWidth: true,
                  alignment: 'left',
                  variant: 'standard',
                  color: 'secondary',
                  isRequired: true
                }
              }
            ]
          },
          {
            options: {
              direction: 'row'
            },
            contents: [
              {
                options: {
                  margin,
                  direction: 'column',
                  flex: miniFlexStyle
                },
                contents: [
                  {
                    component: {
                      default: 'TextInput',
                      edit: 'TextInput'
                    },
                    source: 'hours',
                    options: {
                      label: 'Hours',
                      fullWidth: true,
                      alignment: 'left',
                      variant: 'standard',
                      color: 'secondary',
                      type: 'numeric',
                      min: 0,
                      isRequired: true
                    }
                  }
                ]
              },
              {
                options: {
                  margin,
                  direction: 'column'
                },
                contents: [
                  {
                    component: {
                      default: 'TextInput',
                      edit: 'TextInput'
                    },
                    source: 'comments',
                    options: {
                      label: 'Comments',
                      fullWidth: true,
                      alignment: 'left',
                      variant: 'standard',
                      color: 'secondary'
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      // column 2
      {
        options: {
          direction: 'column'
        },
        contents: [
          {
            options: {
              margin,
              direction: 'column'
            },
            contents: [
              {
                component: {
                  default: 'TextInput',
                  edit: 'TextInput'
                },
                source: 'description',
                options: {
                  label: 'Description',
                  placeholder: 'Enter Description',
                  fullWidth: true,
                  alignment: 'left',
                  variant: 'standard',
                  color: 'secondary',
                  lines: 6
                }
              }
            ]
          }
        ]
      },
      // column 3: delete button
      {
        options: {
          direction: 'column'
        },
        contents: [
          {
            options: {
              margin,
              direction: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-start'
            },
            contents: [
              {
                component: {
                  default: 'deleteButton',
                  edit: 'deleteButton'
                }
              }
            ]
          }
        ]
      }
    ]
  }
];

const equipmentLayout = {
  fields: generateDefaultFieldsObject(equipmentFields),
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: equipmentContents
    },
    edit: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: equipmentContents
    }
  }
};

export { equipmentFields, equipmentLayout };
