import gql from 'graphql-tag';

const addJobCostTypes = gql`
  mutation addJobCostTypes($partitionKey: String!, $data: AddJobCostTypesToCompanyInput!) {
    addJobCostTypesToCompany(partitionKey: $partitionKey, data: $data) {
      id
      name
      type
      isActive
      version
    }
  }
`;

export default addJobCostTypes;
