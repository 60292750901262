import React, { useMemo, useCallback, useRef, useState } from 'react';
import { string, object, boolean } from 'yup';
import { Modal, Button, ThemeProvider, SgtForm } from '@buildhero/sergeant';
import {
  SgtCustomerSearch,
  SgtPropertySearch
} from 'components/SgtAlgoliaSearch/SgtFormComponents';

const rowStyle = { gap: 16, flexDirection: 'row', marginBottom: 16 };

const configuration = {
  layouts: {
    default: {
      props: {},
      contents: [
        {
          props: { style: rowStyle },
          contents: [
            {
              component: 'Field',
              source: 'subscriberName',
              props: {
                slowField: true,
                style: { width: 190 },
                label: 'Subscriber'
              }
            },
            {
              component: 'Field',
              source: 'locationName',
              props: {
                slowField: true,
                style: { width: 190 },
                label: 'Location'
              }
            },
            {
              component: 'Field',
              source: 'locationStoreId',
              props: {
                slowField: true,
                style: { width: 190 },
                label: 'Location ID'
              }
            }
          ]
        },
        {
          props: { style: rowStyle },
          contents: [
            {
              component: 'SgtCustomerSearch',
              source: 'customer.customerName',
              resolveProps: ({
                loading,
                hasExistingCustomer,
                customer: { createNewCustomer }
              }) => ({
                disabled: loading || hasExistingCustomer || createNewCustomer
              }),
              props: {
                slowField: true,
                style: { width: 406 },
                label: 'Customer'
              }
            },
            {
              component: 'Checkbox',
              source: 'customer.createNewCustomer',
              resolveProps: ({ customer }) => ({
                disabled: customer.customerName
              }),
              props: {
                style: { width: 'auto' },
                label: 'Create New Customer'
              }
            }
          ]
        },
        {
          props: { style: { ...rowStyle, marginBottom: 0 } },
          contents: [
            {
              component: 'SgtPropertySearch',
              source: 'property.companyName',
              resolveProps: ({
                loading,
                hasExistingProperty,
                property: { createNewProperty },
                customer
              }) => {
                const hasCustomer = Boolean(customer?.customerName);
                return {
                  filters:
                    hasCustomer &&
                    `entityType:CustomerProperty AND status:active AND customerName:"${customer?.customerName}"`,
                  disabled: loading || hasExistingProperty || createNewProperty || !hasCustomer,
                  tooltip: !hasCustomer && 'Select a customer first to select a property'
                };
              },
              props: {
                slowField: true,
                style: { width: 406 },
                label: 'Property'
              }
            },
            {
              component: 'Checkbox',
              source: 'property.createNewProperty',
              resolveProps: ({ property, customer }) => ({
                disabled: property.companyName || customer?.createNewCustomer
              }),
              props: {
                style: { width: 'auto' },
                label: 'Create New Property'
              }
            }
          ]
        }
      ]
    }
  },
  validation: object({
    customer: object({
      id: string().nullable(),
      customerName: string()
        .nullable()
        .when('createNewCustomer', (createNewCustomer, schema) =>
          createNewCustomer
            ? schema
            : schema.required(
                'Please select a customer or mark the checkbox to create a new customer.'
              )
        ),
      createNewCustomer: boolean()
    }),
    property: object({
      id: string().nullable(),
      companyName: string()
        .nullable()
        .when('createNewProperty', (createNewProperty, schema) =>
          createNewProperty
            ? schema
            : schema.required(
                'Please select a property or mark the checkbox to create a new property.'
              )
        ),
      createNewProperty: boolean()
    })
  })
};

const useAddToNewJobModal = () => {
  const [open, setOpen] = useState();
  const [initialData, setInitialData] = useState();
  const [formService, setFormService] = useState();
  const resolver = useRef();

  const handleClose = useCallback(() => {
    if (resolver.current) resolver.current();
    setOpen(false);
  }, []);

  const handleAdd = useCallback(data => {
    if (resolver.current) resolver.current(data);
    setOpen(false);
  }, []);

  const AddToNewJobModal = useMemo(
    () => (
      <ThemeProvider>
        <Modal
          title="Add to New Job"
          open={open}
          onClose={handleClose}
          actions={
            <Button name="submit" onClick={formService?.formikContext?.handleSubmit} fullWidth>
              Add To Job
            </Button>
          }
        >
          <SgtForm
            configuration={configuration}
            initialValues={initialData}
            onCreateService={setFormService}
            onSubmit={handleAdd}
            formikProps={{ validateOnChange: false, validateOnBlur: true }}
            customComponents={{ SgtCustomerSearch, SgtPropertySearch }}
            onFieldChange={(key, value, context) => {
              if (key === 'customer' && value.createNewCustomer) {
                context.setFieldValue('property.createNewProperty', true);
              }
            }}
          />
        </Modal>
      </ThemeProvider>
    ),
    [open, initialData, handleAdd, handleClose, formService]
  );

  const triggerAddToNewJob = useCallback(
    ({ customer, customerProperty: property, subscriberName, locationName, locationStoreId }) => {
      setInitialData({
        subscriberName,
        locationName,
        locationStoreId,
        customer: {
          id: customer?.id,
          customerName: customer?.customerName,
          createNewCustomer: false
        },
        property: {
          id: property?.id,
          companyName: property?.companyName,
          createNewProperty: false
        },
        hasExistingCustomer: Boolean(customer),
        hasExistingProperty: Boolean(property)
      });
      setOpen(true);
      return new Promise(resolve => {
        resolver.current = resolve;
      });
    },
    []
  );

  return { AddToNewJobModal, triggerAddToNewJob };
};

export default useAddToNewJobModal;
