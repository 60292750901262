import gql from 'graphql-tag';

const addAttachmentsToReviewReport = gql`
  mutation AddAttachmentsToReviewReport(
    $partitionKey: String
    $data: AddAttachmentsToReviewReportInput!
  ) {
    addAttachmentsToReviewReport(partitionKey: $partitionKey, data: $data) {
      id
      sortKey
      customFileName
      fileName
      fileUrl
      type
      dateAdded
      description
      comment
      sortOrder
      createdBy
      createdDate
      addedBy
      addedDateTime
      version
      includeInInvoice
    }
  }
`;

export default addAttachmentsToReviewReport;
