const submittalItemRegistryCustomRowMeta = [
  {
    id: 'specSection',
    label: 'Spec',
    type: 'string',
    headerStyle: {
      maxWidth: 115
    }
  },
  {
    id: 'subSection',
    label: 'Sub Section',
    type: 'string',
    headerStyle: {
      maxWidth: 125
    }
  },
  // commented out: QA requested
  // {
  //   id: 'version',
  //   label: 'Version',
  //   headerStyle: {
  //     maxWidth: 90
  //   }
  // },
  {
    id: 'description',
    label: 'Description',
    type: 'string'
  },
  {
    id: 'type',
    label: 'Type',
    type: 'string',
    headerStyle: {
      maxWidth: 170
    }
  },
  {
    id: 'customStatus',
    label: 'Status',
    type: 'CustomStatusInput',
    isCustom: true,
    headerStyle: {
      maxWidth: 135
    }
  },
  {
    id: 'submitBy',
    label: 'Submit By',
    type: 'date',
    headerStyle: {
      maxWidth: 125
    }
  }
];

export default submittalItemRegistryCustomRowMeta;
