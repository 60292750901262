import React from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import labels from 'meta/Scheduler/labels';
import iconMap from '../assets/icons';
import styles from './styles';

class CrewInfo extends React.Component {
  render() {
    const { classes, primaryTechs, extraTechs, locale, departmentName } = this.props;
    const [primaryTech] = primaryTechs;
    return (
      <div className={classes.root}>
        <div className={classNames(classes.techCell)}>
          <div className={classes.headersText}>{labels.viewVisit_departmentName[locale]}</div>
          <div className={classes.dataText}>{departmentName || '-'}</div>
        </div>
        <div className={classNames(classes.techCell, classes.icon)}>{iconMap('crew', 16)}</div>
        <div className={classNames(classes.techCell)}>
          <div className={classes.headersText}>
            {labels.viewVisit_crew_primaryTechsLabel[locale]}
          </div>
          <div className={classes.dataText}>{primaryTech || '-'}</div>
        </div>
        <div className={classNames(classes.techCell)}>
          <div className={classes.headersText}>
            {labels.viewVisit_crew_additionalTechsLabel[locale]}
          </div>
          <div className={classes.dataText}>{extraTechs.join(', ') || '-'}</div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CrewInfo);
