import React from 'react';

import Box from '@material-ui/core/Box';
import { array } from 'prop-types';

import CustomFileLists from '@pm/components/CustomFileLists';

import { useStyles } from './FilesList.styles';

const FilesList = ({ files, ...props }) => {
  const styles = useStyles();

  if (!files.length) {
    return null;
  }

  return (
    <Box css={styles.container}>
      <CustomFileLists fileData={files} fileDataLength={files.length} {...props} />
    </Box>
  );
};

FilesList.propTypes = {
  files: array.isRequired
};

export default FilesList;
