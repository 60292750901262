import React, { useMemo } from 'react';
import { string, func, number, shape } from 'prop-types';
import moment from 'moment';

import { ElementSizes } from '@dispatch/Dispatch.styles';

import { useStyles } from './TimeCell.styles';

const TimeCell = ({ hour, currentTime, day }) => {
  const classes = useStyles();
  const currentHour = currentTime.hours();
  const isPastDay = useMemo(() => moment(day).isBefore(moment(), 'day'), [day]);
  const isFutureDay = useMemo(() => moment(day).isAfter(moment(), 'day'), [day]);

  if (isPastDay || (!isFutureDay && hour < currentHour)) {
    return <div className={classes.timeCellPassed} />;
  }

  if (hour === currentHour) {
    const minutes = currentTime.minutes();
    const passedWidth = isFutureDay ? 0 : (minutes * ElementSizes.cellWidth) / 60;

    return (
      <div className={classes.timeCell}>
        <div className={classes.timeCellPassedOverlay} style={{ width: passedWidth }} />
      </div>
    );
  }

  return <div className={classes.timeCell} />;
};

TimeCell.propTypes = {
  // eslint-disable-next-line react/require-default-props
  day: string,
  hour: number.isRequired,
  currentTime: shape({
    hours: func.isRequired,
    minutes: func.isRequired
  }).isRequired
};

export default React.memo(TimeCell);
