import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography, FormControlLabel, Checkbox, Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { InvoiceService, JobService } from 'services/core';
import { Logger } from 'services/Logger';
import { snackbarOn } from 'redux/actions/globalActions';
import { SectionHeader, Spinner } from 'components';
import ErrorBoundaries from 'scenes/Error';
import Labels from 'meta/labels';
import styles from './styles';

/**
 * Creates Invoice from one or more visits as selected by user.
 * User will have the option to select if it is the final Invoice or not.
 * locale of the user is referred from user context
 */
class AddInvoice extends Component {
  state = {
    selectedVisitIds: [],
    finalInvoiceFlag: false,
    nextDisabled: true,
    visitNumbersWithInvoices: [],
    showVisits: false
  };

  componentDidMount = async () => {
    await this.getVisitsWithInvoices();
  };

  createInvoice = async () => {
    const { jobInfo } = this.props;
    const invoiceService = new InvoiceService();
    try {
      const { data } = await invoiceService.createInvoiceFromVisits(jobInfo.tenantId, {
        tenantCompanyId: jobInfo.tenantCompanyId,
        jobId: jobInfo.id,
        isFinalInvoice: this.state.finalInvoiceFlag,
        visitIds: this.state.selectedVisitIds
      });

      if (data && data.createInvoiceFromVisits) {
        this.props.history.push(`/invoice/view/${data.createInvoiceFromVisits.id}`, {
          recordSortKey: data.createInvoiceFromVisits.sortKey
        });
        if (this.props.handleInvoice) {
          this.props.handleInvoice('1');
        }
      }
    } catch (error) {
      Logger.error(error);
      let message;
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        message = error.graphQLErrors[0].message;
      } else if (error.message) {
        message = error.message;
      } else {
        message = 'Unabled to create invoice, please try again later';
      }
      this.props.snackbarOn('error', message);
    }
  };

  handleVisitClick = item => {
    const currentVisits = this.state.selectedVisitIds;
    if (this.state.selectedVisitIds.includes(item)) {
      const filtered = currentVisits.filter(it => it !== item);
      this.setState({
        selectedVisitIds: filtered,
        nextDisabled: !(filtered && filtered.length)
      });
    } else {
      currentVisits.push(item);
      this.setState({
        selectedVisitIds: currentVisits,
        nextDisabled: !(currentVisits && currentVisits.length)
      });
    }
  };

  toggleInvoiceFlag = () => {
    const { finalInvoiceFlag } = this.state;
    this.setState({ finalInvoiceFlag: !finalInvoiceFlag });
  };

  getVisitsWithInvoices = async () => {
    const jobService = new JobService();
    const response = await jobService.getInvoicesByJobNumber(this.props.jobInfo.jobNumber);
    const invoices = response.data.getJobByJobNumber.invoices
      ? response.data.getJobByJobNumber.invoices.items
      : [];
    let visits = [];
    if (invoices) {
      invoices.forEach(invoice => {
        if (!['void', 'deleted'].includes(invoice.status) && invoice.invoiceVisits) {
          visits = [...visits, ...invoice.invoiceVisits.items];
        }
      });
    }

    const visitNumbers = [];
    if (visits) {
      visits.forEach(visit => {
        visitNumbers.push(visit.visit.visitNumber);
      });
    }

    this.setState({ visitNumbersWithInvoices: [...new Set(visitNumbers)], showVisits: true });
  };

  allVisits = () => {
    const { selectedVisitIds } = this.state;
    const { classes } = this.props;
    let visitChips = null;
    visitChips = this.props.visits.map(visit => {
      let visitChipComp = null;
      const hasReviewReport =
        (visit.reviewReports &&
          visit.reviewReports.items &&
          visit.reviewReports.items.length > 0) ||
        false;
      const hasCompletedReviewReport =
        (hasReviewReport &&
          visit.reviewReports.items[0].status &&
          visit.reviewReports.items[0].status.toLowerCase() === 'submitted') ||
        false;
      if (
        ['Complete', 'Converted', 'On hold'].includes(visit.status) &&
        hasCompletedReviewReport &&
        !this.state.visitNumbersWithInvoices.includes(visit.visitNumber)
      ) {
        visitChipComp = (
          <Grid item style={{ padding: 8 }} key={`Grid${visit.visitNumber}`}>
            <div
              className={
                selectedVisitIds.includes(visit.id)
                  ? classes.checkedVisits
                  : classes.uncheckedVisits
              }
              onClick={() => this.handleVisitClick(visit.id)}
              role="presentation"
              key={visit.visitNumber}
            >
              {`Visit #${visit.visitNumber}`}
            </div>
          </Grid>
        );
      }
      return visitChipComp;
    });

    if (visitChips && visitChips.every(chip => chip === null)) {
      visitChips = (
        <Typography style={{ fontSize: 16, color: '#d82d51' }}>
          All completed visits have been invoiced
        </Typography>
      );
    }
    return visitChips;
  };

  render() {
    const { classes } = this.props;
    const { nextDisabled, showVisits } = this.state;

    return (
      <ErrorBoundaries>
        <div>
          <SectionHeader
            style={{ fontSize: 18, paddingTop: 18 }}
            title={`${Labels.createInvoice[this.props.user.locale]} :`}
            enablePadding
          />
          <Typography style={{ fontSize: 16, paddingBottom: 14 }}>Visit(s) :</Typography>
          <Grid container justify="flex-start" alignItems="center" direction="row">
            {showVisits ? this.allVisits() : <Spinner />}
          </Grid>
          <div style={{ paddingTop: 18, paddingBottom: 0 }}>
            <FormControlLabel
              control={<Checkbox onChange={this.toggleInvoiceFlag} />}
              label={`${Labels.finalInvoice[this.props.user.locale]}`}
              labelPlacement="start"
            />
          </div>
          {showVisits && (
            <Grid container style={{ paddingTop: 0 }} justify="flex-end" direction="row">
              <Grid item style={{ paddingRight: 16 }}>
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.invoiceCancelButton}
                  onClick={this.props.handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item style={{ paddingLeft: 16 }}>
                <Button
                  className={classes.invoiceNextButton}
                  variant="contained"
                  color="secondary"
                  onClick={async () => {
                    this.props.handleClose();
                    await this.createInvoice();
                  }}
                  disabled={nextDisabled}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      </ErrorBoundaries>
    );
  }
}

const styledAddInvoice = withStyles(styles)(AddInvoice);

const mapStateToProps = state => ({
  user: state.user
});

// utilizes global spinner actions
const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const connectedAllNotes = connect(mapStateToProps, mapDispatchToProps)(styledAddInvoice);

export default connectedAllNotes;
