import _ from 'lodash';

const getPreferredTechniciansForExistingVisit = visit => {
  const { departmentId, primaryTechs, extraTechs, extraTechsNumber } = visit || {};
  const primaryTechnicianId = _.first(primaryTechs)?.id || undefined;
  const additionalTechnicianIds = _.map(extraTechs, tech => tech?.id);
  return {
    departmentId,
    primaryTechnicianId,
    additionalTechnicianIds,
    extraTechsNumber
  };
};

export default getPreferredTechniciansForExistingVisit;
