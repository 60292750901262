import gql from 'graphql-tag';

const deletePurchaseOrderTag = gql`
  mutation deletePurchaseOrderTag($partitionKey: String!, $id: String!) {
    deletePurchaseOrderTag(partitionKey: $partitionKey, id: $id) {
      id
      tagName
    }
  }
`;

export default deletePurchaseOrderTag;
