import gql from 'graphql-tag';

const SyncLedgerAccounts = gql`
  mutation SyncLedgerAccounts($partitionKey: String!, $data: SyncLedgerAccountsInput!) {
    syncLedgerAccounts(partitionKey: $partitionKey, data: $data) {
      id
      entityType
    }
  }
`;

export default SyncLedgerAccounts;
