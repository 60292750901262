const styles = theme => ({
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 10,
    letterSpacing: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal'
  },
  content: {
    fontFamily: theme.typography.fontFamily,
    color: '#3f3f3f',
    fontSize: 14,
    letterSpacing: 0.24,
    fontWeight: 'normal',
    lineHeight: 'normal',
    paddingTop: 7,
    wordBreak: 'break-all',
    whiteSpace: 'pre-line'
  },
  bigContent: {
    fontFamily: theme.typography.fontFamily,
    color: '#3f3f3f',
    fontSize: 14,
    letterSpacing: 0.24,
    fontWeight: 'normal',
    lineHeight: 'normal',
    paddingTop: 7,
    whiteSpace: 'pre-line'
  },
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing()
  }
});

export default styles;
