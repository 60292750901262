import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import useStyles from './Header.style';

export default function Header({ isVisible, onNewItemClick, newItemLabel }) {
  const classes = useStyles();
  if (!isVisible) return null;

  return (
    <Grid
      container
      direction="row"
      justify="flex-end"
      alignItems="flex-end"
      className={classes.container}
    >
      <Grid item>
        <Button variant="contained" onClick={onNewItemClick} className={classes.newItem}>
          {newItemLabel}
        </Button>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  isVisible: PropTypes.bool,
  onNewItemClick: PropTypes.func.isRequired,
  newItemLabel: PropTypes.string.isRequired
};

Header.defaultProps = {
  isVisible: true
};
