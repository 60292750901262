import React from 'react';
import { func, array } from 'prop-types';
import { getState } from 'redux/store';

import { useFormService } from '@dispatch/hooks';
import { EventEntities } from '@dispatch/Dispatch.constants';
import { prepareNonVisitData } from '@dispatch/Dispatch.utils';
import { EventDataPropTypes } from '@dispatch/Dispatch.props';
import { useDispatchSettings } from '@dispatch/settings';
import NonBillableEventForm from '../NonBillableEventForm';
import EventBadge from '../EventBadge';
import DrawerLayout from '../DrawerLayout';

const CreateNonBillableEvent = ({ addNonVisitTuple, eventData, closeDrawer }) => {
  const { tenantId, tenantCompanyId } = getState().user;
  const nonVisitEventData = prepareNonVisitData(eventData);
  const [triggerMutation, mutationResponse] = addNonVisitTuple;
  const { isDirty, onCreateService, onDirtyChange, resetForm, submitForm } = useFormService();
  const { loading } = mutationResponse;
  const { showDepartmentForNonBillable } = useDispatchSettings();

  const createNonVisitEvent = data => {
    triggerMutation({ tenantId, tenantCompanyId, data, techId: eventData.primaryTechId });
    closeDrawer();
    resetForm();
  };

  const renderHeader = () => (
    <EventBadge eventType={EventEntities.NON_BILLABLE_EVENT.value.clientValue} />
  );

  const renderBody = () => (
    <NonBillableEventForm
      onDirtyChange={onDirtyChange}
      onComplete={createNonVisitEvent}
      onCreateService={onCreateService}
      eventData={nonVisitEventData}
      disabled={loading}
      enableDepartment={showDepartmentForNonBillable}
    />
  );

  return (
    <DrawerLayout
      renderHeader={renderHeader}
      renderBody={renderBody}
      onResetForm={resetForm}
      onSubmitForm={submitForm}
      isDirty={isDirty}
      loading={loading}
      disabledSave={loading}
      disabledClose={loading}
    />
  );
};

CreateNonBillableEvent.propTypes = {
  addNonVisitTuple: array.isRequired,
  eventData: EventDataPropTypes.isRequired,
  closeDrawer: func.isRequired
};

export default CreateNonBillableEvent;
