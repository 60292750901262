import { PricebookService } from 'services/core';
import { Logger } from 'services/Logger';
import extractErrorMessage from 'utils/extractErrorMessage';
import { templateTypes } from 'constants/bundle';

const addBundle = async ({ user, bundle, snackbarOn, successCallback }) => {
  try {
    const { addProductBundle } = new PricebookService();
    const { tenantId, tenantCompanyId: companyId } = user;
    const productBundles = [{ ...bundle, isActive: true }];
    const payload = {
      companyId,
      productBundles
    };
    await addProductBundle(tenantId, payload);
    snackbarOn('success', `Successfully added item - ${bundle.name}`);
    successCallback();
  } catch (error) {
    Logger.error(error);
    const errorMessage = extractErrorMessage(error, 'Unable to save item, please try again later');
    snackbarOn('error', errorMessage);
  }
};

const updateBundle = async ({ user, bundle, snackbarOn, successCallback }) => {
  try {
    const { updateProductBundle } = new PricebookService();
    const { tenantId } = user;
    const { productBundleEntries, ...payload } = bundle;
    await updateProductBundle(tenantId, payload);
    snackbarOn('success', `Successfully updated the item - ${bundle.name}`);
    successCallback();
  } catch (error) {
    Logger.error(error);
    const errorMessage = extractErrorMessage(
      error,
      'Unable to update item, please try again later'
    );
    snackbarOn('error', errorMessage);
  }
};

const getBundles = async ({
  filter,
  limit,
  offset,
  sortBy,
  sortOrder,
  snackbarOn,
  user: { tenantId, tenantCompanyId }
}) => {
  if (!tenantId) return { items: [], nextToken: '0' };
  const { getProductBundles } = new PricebookService();
  let data;
  try {
    const sortKey = `${tenantId}_Company_${tenantCompanyId}`;
    data = await getProductBundles(tenantId, sortKey, filter, limit, offset, sortBy, sortOrder);
    (data?.items || []).forEach(item => {
      const localItem = item;
      if (item.type) {
        localItem.templateTypeValue = templateTypes.find(
          template => template.value === item.type
        )?.label;
      }
    });
    return data;
  } catch (error) {
    Logger.error(error);
    const errorMessage = extractErrorMessage(
      error,
      'Unable to fetch product bundles, please try again later'
    );
    snackbarOn('error', errorMessage);
  } finally {
    return data || [];
  }
};

const deleteBundle = async ({ user, bundle, snackbarOn, successCallback }) => {
  try {
    const { softDeleteProductBundle } = new PricebookService();
    const response = await softDeleteProductBundle(user.tenantId, bundle.id);
    if (response && response.data) {
      snackbarOn('success', `Successfully delete bundle - ${bundle.name}`);
      successCallback();
    }
  } catch (error) {
    Logger.error(error);
    const errorMessage = extractErrorMessage(error, `Unable delete bundle - ${bundle.name}`);
    snackbarOn('error', errorMessage);
  }
};

export { addBundle, updateBundle, getBundles, deleteBundle };
