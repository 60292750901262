import { VisitStatuses } from '@dispatch/Dispatch.constants';
import { Serializers as Actions } from '@dispatch/mutations/useVisitTransition';

// eslint-disable-next-line import/named
import * as Guards from './getVisitStateMachine.guards';

export const SCHEDULE = [
  {
    target: VisitStatuses.SCHEDULED.key,
    cond: Guards.isFullyScheduled,
    actions: [Actions.updateTechs, Actions.updateVisit, Actions.updateScheduledFor]
  },
  {
    target: VisitStatuses.UNASSIGNED.key,
    actions: [Actions.updateTechs, Actions.updateVisit, Actions.updateScheduledFor]
  }
];

const RESCHEDULE_ACTIONS = [
  Actions.updateTechs,
  Actions.updateVisit,
  Actions.updateScheduledFor,
  Actions.clearDetailsSent
];

export const RESCHEDULE = [
  {
    target: VisitStatuses.SCHEDULED.key,
    cond: Guards.isFullyScheduled,
    actions: RESCHEDULE_ACTIONS
  },
  {
    target: VisitStatuses.UNASSIGNED.key,
    actions: RESCHEDULE_ACTIONS
  }
];

export const CANCEL = {
  target: VisitStatuses.CANCELED.key,
  cond: Guards.hasScheduleWorkDone,
  actions: [Actions.cancelSchedules, Actions.clearDetailsSent] // R2T clear action
};

export const COMPLETE = {
  target: VisitStatuses.COMPLETE.key,
  actions: [Actions.completeSchedules]
};

export const CONVERT = {
  target: VisitStatuses.CONVERTED.key,
  actions: []
};

export const CLOSE = {
  target: VisitStatuses.CLOSED.key,
  actions: []
};

export const WORK_DONE = {
  target: VisitStatuses.COMPLETE.key,
  cond: Guards.canCompleteVisit,
  actions: [Actions.completeSchedules]
};

export const TRAVEL = {
  target: VisitStatuses.TRAVELING.key,
  cond: Guards.atLeastOneIsTraveling
};

export const TRAVEL_RESCHEDULE = [
  {
    target: VisitStatuses.TRAVELING.key,
    cond: Guards.samePrimaryTech,
    actions: RESCHEDULE_ACTIONS
  },
  {
    target: VisitStatuses.SCHEDULED.key,
    actions: RESCHEDULE_ACTIONS
  }
];

export const WORK = {
  target: VisitStatuses.WORKING.key,
  cond: Guards.atLeastOneIsWorking
};

export const WORK_RESCHEDULE = [
  {
    target: VisitStatuses.WORKING.key,
    cond: Guards.samePrimaryTech,
    actions: RESCHEDULE_ACTIONS
  },
  {
    target: VisitStatuses.SCHEDULED.key,
    actions: RESCHEDULE_ACTIONS
  }
];

export const UPDATE_UNASSIGNED = {
  target: VisitStatuses.UNASSIGNED.key,
  actions: [Actions.updateVisit]
};

export const UPDATE_TRAVELING = {
  target: VisitStatuses.TRAVELING.key,
  actions: [Actions.updateWorkingVisit]
};

export const UPDATE_WORKING = {
  target: VisitStatuses.WORKING.key,
  actions: [Actions.updateWorkingVisit]
};

export const UPDATE_ON_HOLD = {
  target: VisitStatuses.ON_HOLD.key,
  actions: [Actions.updateVisit]
};

export const UPDATE_PAUSED = {
  target: VisitStatuses.PAUSED.key,
  actions: [Actions.updateWorkingVisit]
};

export const UPDATE_SCHEDULED = {
  target: VisitStatuses.SCHEDULED.key,
  actions: [Actions.updateVisit]
};

export const PAUSE = {
  target: VisitStatuses.PAUSED.key,
  cond: Guards.allArePaused
};

export const PAUSE_RESCHEDULE = [
  {
    target: VisitStatuses.PAUSED.key,
    cond: Guards.samePrimaryTech,
    actions: RESCHEDULE_ACTIONS
  },
  {
    target: VisitStatuses.SCHEDULED.key,
    actions: RESCHEDULE_ACTIONS
  }
];

export const REOPEN = [
  {
    target: VisitStatuses.SCHEDULED.key,
    cond: Guards.isFullyScheduled,
    actions: [
      Actions.undoOnHold,
      Actions.updateVisit,
      Actions.updateScheduledFor,
      Actions.rescheduleSchedules
    ]
  },
  {
    target: VisitStatuses.UNASSIGNED.key,
    actions: [
      Actions.undoOnHold,
      Actions.updateVisit,
      Actions.updateScheduledFor,
      Actions.rescheduleSchedules
    ]
  }
];

export const DRAG_AND_DROP = [
  {
    target: VisitStatuses.SCHEDULED.key,
    cond: Guards.isFullyScheduled,
    actions: [Actions.updateVisit, Actions.updateScheduledFor, Actions.clearDetailsSent] // R2T clear action
  },
  {
    target: VisitStatuses.UNASSIGNED.key,
    actions: [Actions.updateVisit, Actions.updateScheduledFor, Actions.clearDetailsSent] // R2T clear action
  }
];

export const STAY_ON_HOLD = {
  target: VisitStatuses.ON_HOLD.key
};

export const PUT_ON_HOLD = {
  target: VisitStatuses.ON_HOLD.key,
  actions: [
    Actions.setOnHold,
    Actions.updateVisit,
    Actions.putSchedulesOnHold,
    Actions.clearDetailsSent
  ] // R2T clear action
};

export const TECH_PUT_ON_HOLD = {
  target: VisitStatuses.ON_HOLD.key,
  cond: Guards.primaryTechIsOnHold,
  actions: [
    Actions.setOnHold,
    Actions.updateVisit,
    Actions.clearDetailsSent,
    Actions.putSchedulesOnHold
  ]
};

export const CONTINUE_IN_NEW_VISIT = {
  target: VisitStatuses.CLOSED.key,
  actions: [Actions.duplicateVisit] // duplicateVisit sets r2t detailsSent to null now
};

export const RELEASE_TO_TECHS = {
  // R2T state transition
  target: VisitStatuses.SCHEDULED.key, // R2T state transition
  cond: Guards.isFullyScheduled, // R2T state transition
  actions: [Actions.setDetailsSent] // R2T state transition
}; // R2T state transition
