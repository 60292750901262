import React from 'react';
import {
  ChipArray,
  UserPermission,
  ResponsiveTable,
  SectionHeader,
  Tabs,
  Tab,
  SyncStatusIcon
} from 'components';
import Labels from 'meta/labels';
import { paymentRowsMeta } from 'meta/ListViews';
import { checkPermission } from 'utils';
import { AppConstants, PermissionConstants } from 'utils/AppConstants';
import { MAX_DEFAULT_TABLE_HEIGHT } from 'themes/BuildHeroTheme';
import { jobRowMetaByCustomer, eventRows } from 'meta/Customer';
import Attachment from 'components/AttachmentSection';
import AuditLogsSection from 'components/AuditLogs';
import { Box, Grid, Typography } from '@material-ui/core';
import { QuickAddModal } from 'utils/constants';
import { serviceAgreementRowsMeta } from 'meta/ServiceAgreements/customer/tables';
import { CustomerService } from 'services/core';
import CreateEntryButton from 'components/Buttons/CreateEntryButton';
import adjustmentRowsMeta from 'scenes/Adjustments/Adjustments.columns';
import AdjustmentModal from 'scenes/Adjustments/Adjustment/Adjustment.modal';
import { TableTransactionList } from 'scenes/Adjustments/Adjustments';
import { InvoiceTableForCustomer } from './InvoiceTable';

import Reps from '../Reps';
import Properties from '../Properties';

export const CustomerTabs = props => {
  const {
    flags,
    user,
    mode,
    renderPageForm,
    history,
    location,
    customerData,
    customerAttachments,
    refreshCounter,
    queryAndSetProperties,
    propertiesData,
    incompleteJobNumbers,
    customerRepsData,
    createProperty,
    tenantRepsData,
    queryAndSetCustomerReps,
    queryAndSetTenantReps,
    globalFilterName,
    setGlobalFilters,
    queryJobsByCustomer,
    globalFilters,
    handleEventsRowActions,
    data,
    queryUpcomingVisit,
    queryPastVisit,
    getServiceAgreements,
    getCustomerAdjustments,
    getCustomerInvoices,
    getCustomerPayments,
    queryAndSetAttachments,
    handleCustomerAttachment,
    handleCreateAgreementModal,
    setOpenQuickAddModal,
    adjustmentModalOpen,
    setOpenAdjustmentModal,
    isServiceAgreementEnabled
  } = props;
  const SCHEDULED_FOR_TODAY = 'Scheduled for today';
  const ALL = 'All';
  const MY_JOBS = 'My jobs';
  const sectionContainerStyles = {
    minHeight: MAX_DEFAULT_TABLE_HEIGHT - 100,
    marginBottom: 40,
    overflow: 'hidden'
  };
  const CustomerServiceInstance = new CustomerService();
  if (mode === 'new' || !renderPageForm) {
    return <></>;
  }

  const hasPermissionToCreateInvoice = checkPermission(
    'create',
    PermissionConstants.OBJECT_INVOICE,
    user
  );


  return (
    <Tabs>
      <Tab label={Labels.properties[user.locale]} tabKey="properties">
        <UserPermission I="read" action={PermissionConstants.OBJECT_PROPERTY}>
          <Properties
            customerProperties={propertiesData}
            customerData={customerData}
            createProperty={createProperty}
            incompleteJobNumbers={incompleteJobNumbers}
            refetch={queryAndSetProperties}
            history={history}
            location={location}
          />
        </UserPermission>
      </Tab>
      <Tab label={Labels.contacts[user.locale]} tabKey="contacts">
        <Reps
          customerReps={customerRepsData}
          tenantReps={tenantRepsData}
          customerData={customerData}
          refetchCustomerReps={queryAndSetCustomerReps}
          refetchTenantReps={queryAndSetTenantReps}
          isActive={customerData.isActive}
        />
      </Tab>
      <Tab label={Labels.jobsAndVisits[user.locale]} tabKey="jobs">
        <Typography variant="body2" style={{ color: '#3f3f3f' }}>
          {Labels.jobs[user.locale]}
        </Typography>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ChipArray
            dataArr={[ALL, SCHEDULED_FOR_TODAY, MY_JOBS]}
            selectedValue={globalFilterName}
            handleClick={setGlobalFilters}
          />
        </Grid>
        <ResponsiveTable
          rowMetadata={jobRowMetaByCustomer}
          service={queryJobsByCustomer}
          filter={globalFilters}
          showToolbars
          caslKey={PermissionConstants.OBJECT_JOB}
          rowActions={handleEventsRowActions}
          noDataMsg="No Jobs"
          key={refreshCounter}
          addRow={
            data.noOfProperties > 0 &&
            data.isActive && {
              label: 'Create job',
              caslKey: PermissionConstants.OBJECT_JOB,
              handleAdd: () => {
                history.push('/job/new', {
                  customerName: customerData.tenantCompanyId,
                  customerSortKey: customerData.sortKey
                });
              }
            }
          }
        />
        <Grid style={{ paddingTop: 40 }} />
        <Typography variant="body2" style={{ color: '#3f3f3f' }}>
          {Labels.currentVisits[user.locale]}
        </Typography>
        <ResponsiveTable
          rowMetadata={eventRows}
          service={queryUpcomingVisit}
          noDataMsg="No upcoming visits"
          showToolbars
          disableFilter
        />
        <Grid style={{ paddingTop: 40 }} />

        <Typography variant="body2" style={{ color: '#3f3f3f' }}>
          {Labels.pastVisits[user.locale]}
        </Typography>
        <ResponsiveTable
          rowMetadata={eventRows}
          service={queryPastVisit}
          noDataMsg="No past visits"
          showToolbars
          disableFilter
        />
        <Grid style={{ paddingTop: 40 }} />
      </Tab>
      {isServiceAgreementEnabled && (
        <Tab
          label={Labels.serviceAgreements[user.locale]}
          caslKey={PermissionConstants.OBJECT_SERVICE_AGREEMENT}
          tabKey="serviceAgreements"
        >
          <Grid container direction="column" justify="flex-start" style={sectionContainerStyles}>
            <Box display="flex" justifyContent="flex-end">
              <CreateEntryButton
                label="New Agreement"
                caslKey={PermissionConstants.OBJECT_SERVICE_AGREEMENT}
                handleAdd={handleCreateAgreementModal}
              />
            </Box>
            <ResponsiveTable
              rowMetadata={serviceAgreementRowsMeta}
              service={getServiceAgreements}
              noDataMsg="No Service Agreements"
              showToolbars
              disableFilter
            />
          </Grid>
        </Tab>
      )}
      <Tab label={Labels.accounting[user.locale]} tabKey="accounting">
        <Grid container direction="column" justify="flex-start" style={sectionContainerStyles}>
          <SectionHeader
            title={Labels.invoices[user.locale] || 'Invoices'}
            buttons={
              customerData.isActive && hasPermissionToCreateInvoice && [
                {
                  label: 'New Invoice',
                  action: () => setOpenQuickAddModal(QuickAddModal.CREATE_INVOICE),
                  color: 'secondary'
                }
              ]
            }
          />
          <InvoiceTableForCustomer customerData={data} />
        </Grid>
        <Grid container direction="column" justify="flex-start" style={sectionContainerStyles}>
          <SectionHeader
            title={Labels.Payments[user.locale] || 'Payments'}
            buttons={
              customerData.isActive && hasPermissionToCreateInvoice && [
                {
                  label: 'New Payment',
                  action: () =>
                    setOpenQuickAddModal(QuickAddModal.CREATE_PAYMENT, {
                      billingCustomerName: data.customerName,
                      billingCustomerId: data.id
                    }),
                  color: 'secondary'
                }
              ]
            }
          />
          <ResponsiveTable
            rowMetadata={paymentRowsMeta({})}
            service={getCustomerPayments}
            showToolbars
            disableFilter
          />
        </Grid>
        {flags.adjustments && (
          <Grid container direction="column" justify="flex-start" style={sectionContainerStyles}>
            <SectionHeader
              title={Labels.adjustments[user.locale] || 'Adjustments'}
              buttons={
                customerData.isActive && hasPermissionToCreateInvoice && [
                  {
                    label: 'New Adjustment',
                    action: () => setOpenAdjustmentModal(true),
                    color: 'secondary'
                  }
                ]
              }
            />
            <AdjustmentModal
              open={adjustmentModalOpen}
              onClose={id => {
                setOpenAdjustmentModal(false);
                if (id) history.push(`/adjustment/view/${id}`);
              }}
            />
            <ResponsiveTable
              rowMetadata={adjustmentRowsMeta}
              service={getCustomerAdjustments}
              customCellComponents={{
                TransactionList: TableTransactionList,
                syncStatus: ({ record }) => <SyncStatusIcon status={record.syncStatus} />
              }}
              showToolbars
              disableFilter
            />
          </Grid>
        )}
      </Tab>
      <Tab label="Attachments" tabKey="attachments">
        <Attachment
          parent={customerData}
          data={customerAttachments}
          refresh={queryAndSetAttachments}
          mutationService={handleCustomerAttachment}
          enableThumbnails
          readOnly={customerData?.status?.toLowerCase() === AppConstants.INACTIVE}
        />
      </Tab>
      <Tab label="Activity" tabKey="activity">
        <AuditLogsSection
          dataService={() => CustomerServiceInstance.getAllAuditLogByCustomerId(customerData.id)}
        />
      </Tab>
    </Tabs>
  );
};
