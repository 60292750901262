import { EventEntities } from '@dispatch/Dispatch.constants';

export const DEFAULT_SETTINGS = {
  enabledEvents: {
    [EventEntities.BILLABLE_EVENT.value.clientValue]: {
      enabled: false,
      enabledForTech: false
    },
    [EventEntities.NON_BILLABLE_EVENT.value.clientValue]: {
      enabled: false,
      enabledForTech: false
    }
  },
  releaseToTechEnabled: false,
  showDepartmentForNonBillable: false
};
