import React from 'react';

import GeneratePdfModal from './GeneratePdfModal.component';

const Wrapper = props => {
  if (!props.open) return null;
  return <GeneratePdfModal {...props} />;
};

export default Wrapper;
