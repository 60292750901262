import gql from 'graphql-tag';

const updateSummary = gql`
  mutation updateSummary($partitionKey: String, $data: UpdateSummaryInput!) {
    updateSummary(partitionKey: $partitionKey, data: $data) {
      id
      sortKey
      summary
      version
      includeInInvoice
      createdDateTime
      lastUpdatedBy
      lastUpdatedDateTime
    }
  }
`;

export default updateSummary;
