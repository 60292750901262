import gql from 'graphql-tag';

const updateTruckInventory = gql`
  mutation updateTruckInventory($data: UpdateTruckInventoryInput!) {
    updateTruckInventory(data: $data) {
      id
      version
      minQuantity
      maxQuantity
      quantityOnHand
      productId
      product(entityConnection: "Product") {
        id
        version
        name
        description
        unitCost
        unitOfMeasureId
        unitOfMeasure(entityConnection: "UnitOfMeasure") {
          id
          name
          unitOfMeasureType
          unitOfMeasureCategory
        }
      }
    }
  }
`;

export default updateTruckInventory;
