import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { SectionHeader } from 'components';
import { snackbarOn } from 'redux/actions/globalActions';
import Labels from 'meta/labels';
import ThumbnailView from './ThumbnailView';

/*
 * This component creates an image gallery to view photos attached by technicians during the course of service.
 */
function generateVisitAttachmentsMap(visits, attachments) {
  const visitAttachmentsMap = new Map();
  if (!Array.isArray(visits) || !Array.isArray(attachments)) return visitAttachmentsMap;

  const visitIdNumberMap = new Map();
  const revReportVisitMap = new Map();

  visits.forEach(visit => {
    visitIdNumberMap.set(visit.id, visit.visitNumber);
    if (visit?.reviewReports?.items) {
      const reviewReportList = visit.reviewReports.items;
      reviewReportList.forEach(reviewReport => {
        revReportVisitMap.set(reviewReport.id, visit.visitNumber);
      });
    }
  });

  const addedAttachmentUrls = [];
  attachments.forEach(attachment => {
    if (!addedAttachmentUrls.includes(attachment.fileUrl)) {
      if (visitIdNumberMap.has(attachment.parentId)) {
        const visitNumber = visitIdNumberMap.get(attachment.parentId);
        if (visitAttachmentsMap.has(visitNumber)) {
          const attachList = visitAttachmentsMap.get(visitNumber);
          attachList.push(attachment);
          visitAttachmentsMap.set(visitNumber, attachList);
        } else {
          visitAttachmentsMap.set(visitNumber, [attachment]);
        }
        addedAttachmentUrls.push(attachment.fileUrl);
      } else if (revReportVisitMap.has(attachment.parentId)) {
        const visitNumber = revReportVisitMap.get(attachment.parentId);
        if (visitAttachmentsMap.has(visitNumber)) {
          const attachList = visitAttachmentsMap.get(visitNumber);
          attachList.push(attachment);
          visitAttachmentsMap.set(visitNumber, attachList);
        } else {
          visitAttachmentsMap.set(visitNumber, [attachment]);
        }
        addedAttachmentUrls.push(attachment.fileUrl);
      }
    }
  });

  return visitAttachmentsMap;
}

// Stateless components: the best kind of components :)
const ImageSection = props => {
  const { visits, attachments: unparsedAttachments, user, disableTitle = false } = props;
  const attachments = unparsedAttachments?.map(item => {
    const attachment = { ...item };
    if (!attachment.addedBy && attachment.createdBy) {
      attachment.addedBy = attachment.createdBy;
    }
    return attachment;
  });

  const visitAttachmentsMap = generateVisitAttachmentsMap(visits, attachments);
  const visitAttachmentsArray = Array.from(visitAttachmentsMap);
  if (visitAttachmentsArray.length === 0) return null;

  return (
    <>
      {!disableTitle && (
        <SectionHeader title={Labels.Photos[user.locale]} enablePadding icon="photoCameraIcon" />
      )}
      {visitAttachmentsArray.map(([key, value]) => {
        if (!value) return null;
        return (
          <>
            <Typography
              style={{
                fontSize: 16,
                paddingBottom: 16,
                paddingTop: 24
              }}
            >
              Visit #{key}
            </Typography>
            <Grid container direction="row" justify="flex-start">
              <Grid item xs={6}>
                <ThumbnailView
                  images={value.filter(item => item.type?.toLowerCase() === 'before')}
                  caption="Before"
                />
              </Grid>
              <Grid item xs={6}>
                <ThumbnailView
                  images={value.filter(item => item.type?.toLowerCase() === 'after')}
                  caption="After"
                />
              </Grid>
            </Grid>
          </>
        );
      })}
    </>
  );
};

const connectedImageSection = connect(state => ({ user: state.user }), { snackbar: snackbarOn })(
  ImageSection
);

export default connectedImageSection;
