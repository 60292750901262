/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getNotesByPurchaseOrderId(purchaseOrderId) {
  try {
    const response = await instance.get(`note/bypurchaseorder/${purchaseOrderId}`);
    return response.data;
  } catch (err) {
    console.log({ err });
  }
}
