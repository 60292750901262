import { useTheme } from '@material-ui/core';

export const useStyles = () => {
  const theme = useTheme();

  return {
    root: {
      display: 'flex',
      position: 'relative',
      width: '100%',
      fontSize: 10,
      height: 14,
      cursor: 'pointer'
    },
    skeleton: {
      width: '100%',
      height: 14,
      fontSize: 14,
      lineHeight: 1
    },
    priority: {
      backgroundColor: theme.palette.grayscale(20),
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      paddingRight: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
      flex: 0
    },
    status: {
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
      whiteSpace: 'nowrap',
      flex: 1
    },
    rightContent: {
      flex: '0 0 24px',
      display: 'flex',
      justifyContent: 'flex-end'
    },
    iconTech: {
      paddingLeft: theme.spacing(0.5),
      fontSize: 14
    },
    iconReleased: {
      height: 9,
      marginTop: 2
    },
    iconUnreleased: {
      height: 9,
      marginTop: 2,
      '&:hover': {
        filter: 'sepia(85%) saturate(600%) hue-rotate(70deg) brightness(85%) contrast(150%)'
      }
    }
  };
};
