import React from 'react';
import PropTypes from 'prop-types';
import {
  Popper,
  Paper,
  Fade,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener
} from '@material-ui/core';

const propTypes = {
  isOpen: PropTypes.bool,
  anchorEl: PropTypes.object.isRequired,
  onClickAway: PropTypes.func,
  classes: PropTypes.shape({})
};

const defaultProps = {
  isOpen: false,
  onClickAway: () => {},
  classes: {}
};

const SearchResults = ({ isOpen, anchorEl, onClickAway, classes, listItems, onChange }) => {
  return (
    <Popper
      id="searchResults"
      open={isOpen}
      anchorEl={anchorEl}
      placement="bottom-start"
      transition
      style={{ zIndex: 5, width: '15%' }}
      modifiers={{
        preventOverflow: {
          boundariesElement: '#searchResults'
        }
      }}
      className={classes.root}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={onClickAway}>
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <List component="nav">
                {listItems.length > 0 ? (
                  listItems.map(li => (
                    <ListItem
                      disableGutters
                      button
                      key={li.value}
                      onClick={() => onChange(li)}
                      testingid={li.label}
                    >
                      <ListItemText>{li.label}</ListItemText>
                    </ListItem>
                  ))
                ) : (
                  <ListItem disableGutters button testingid="no-results">
                    <ListItemText>No results</ListItemText>
                  </ListItem>
                )}
              </List>
            </Paper>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
};

SearchResults.propTypes = propTypes;
SearchResults.defaultProps = defaultProps;

export default SearchResults;
