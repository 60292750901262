import React from 'react';
import * as R from 'ramda';
import { Typography } from '@material-ui/core';
import { Modal, AlgoliaSearch } from 'components';
import AppConstants from 'utils/AppConstants';
import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';

const CreateJobModal = props => {
  const { open, history, handleClose } = props;

  const handleSearchResultsFilter = resultList => {
    let allowedIds = [];
    let uniqueCustomerIds = [];
    const companyAddressResult = resultList.filter(
      hit =>
        hit.entityType === 'CompanyAddress' &&
        hit.parentEntityType === 'Customer' &&
        hit.addressType === 'billingAddress'
    );

    if (companyAddressResult && companyAddressResult.length > 0) {
      allowedIds = allowedIds.concat(R.pluck('id', companyAddressResult) || []);
      uniqueCustomerIds = uniqueCustomerIds.concat(R.pluck('parentId', companyAddressResult) || []);
    }

    const customerList = resultList.filter(hit => hit.entityType === 'Customer');
    if (customerList && customerList.length > 0) {
      customerList.forEach(customer => {
        if (!uniqueCustomerIds.includes(customer.id)) {
          allowedIds.push(customer.id);
        }
      });
    }

    const filterList = resultList.filter(hit => allowedIds.includes(hit.id));

    return filterList;
  };

  const redirectToNewJobPage = customer => {
    history.push('/job/new', {
      customerName: customer.parentId || customer.id,
      customerSortKey: customer.parentSortKey || customer.sortKey
    });
    handleClose();
  };

  return (
    <Modal open={open} handleClose={() => handleClose()} width="1100" height="700">
      <div style={{ marginTop: 20, marginBottom: 240 }}>
        <Typography style={{ marginBottom: 10 }}>
          Please select a customer to create a job:
        </Typography>
        <AlgoliaSearch
          searchIndex={defaultSearchIndex}
          label="Search by customer name or address"
          name="selectCustomer"
          value=""
          displayText={['customerName', 'address']}
          delimiter=" "
          locatedInPopUp
          filters={[
            {
              attribute: 'entityType',
              valueArray: ['CompanyAddress', 'Customer']
            },
            {
              parenthetical: true,
              operand: 'OR',
              attributeArray: ['status', 'parentStatus'],
              valueArray: [AppConstants.ACTIVE, AppConstants.ACTIVE]
            }
          ]}
          handleFilter={handleSearchResultsFilter}
          handleChange={customer => customer && redirectToNewJobPage(customer)}
        />
      </div>
    </Modal>
  );
};
export default CreateJobModal;
