import { Auth, Hub } from 'aws-amplify';
import * as Sentry from '@sentry/browser';
import { Logger as BOLogger } from 'services/Logger';
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  AuthenticationHelper
} from 'amazon-cognito-identity-js';
import Mixpanel from 'services/mixpanel';
import { MIXPANEL_EVENT } from 'constants/mixpanelEvents';
import { logUser, signOut } from 'redux/actions/authActions';
import { getCompanyById } from 'services/API/companies';
import ENV from 'configs/env';
import CacheService from '../CacheService';

export default class AuthService {
  authWatcher = null;

  store = null;

  cognitoUser = null;

  constructor(store = null) {
    this.store = store;
    this.unauthenticatedStateAware = false;
    this.impersonation = false;
  }

  configuredListener = null;

  signInListener = null;

  signOutListener = null;

  signInSubscribe = callbackFunction => {
    this.signInListener = callbackFunction;
  };

  signOutSubscribe = callbackFunction => {
    this.signOutListener = callbackFunction;
  };

  configuredSubscribe = callbackFunction => {
    this.configuredListener = callbackFunction;
  };

  startAuthListener() {
    this.authWatcher = capsule => {
      const { data } = capsule.payload;
      switch (capsule.payload.event) {
        case 'signIn': {
          this.store.dispatch(
            logUser(
              data.username,
              `${data.attributes.given_name} ${data.attributes.family_name}`,
              data.attributes['custom:tenantId'],
              data.attributes['custom:companyId'],
              data.attributes['custom:role'],
              data.attributes['custom:contactId'],
              data.signInUserSession.idToken.payload.rules,
              data.attributes.email
            )
          );

          Sentry.configureScope(scope => {
            scope.setUser({ email: data.attributes.email || data.username });
            scope.setExtra('user_attributes', {
              ...data.attributes,
              rules: data.signInUserSession.idToken.payload.rules
            });
          });

          Mixpanel.identify(data.username);

          const defaultMixpanelAttrs = {
            $first_name: data.attributes?.given_name,
            $last_name: data.attributes?.family_name,
            $email: data.attributes?.email,
            companyId: data.attributes['custom:companyId'],
            contactId: data.attributes['custom:contactId'],
            role: data.attributes['custom:role'],
            tenantId: data.attributes['custom:tenantId'],
            tier: data.attributes['custom:tier'],
            preferred_username: data.attributes.preferred_username
          };

          getCompanyById(data.attributes['custom:companyId'])
            .then(res => {
              const attrs = { ...defaultMixpanelAttrs, companyName: res?.companyName || '' };
              Mixpanel.people.set(attrs);

              window.pendo.initialize({
                visitor: {
                  id: data.attributes['custom:contactId'],
                  full_name: `${data.attributes?.given_name} ${data.attributes?.family_name}`,
                  role: data.attributes['custom:role'],
                  email: data.attributes?.email,
                  preferred_username: data.attributes.preferred_username,
                  companyId: data.attributes['custom:companyId'],
                  tenantId: data.attributes['custom:tenantId'],
                  tier: data.attributes['custom:tier']
                },
                account: {
                  id: data.attributes['custom:tenantId'],
                  companyId: data.attributes['custom:companyId'],
                  name: res?.companyName,
                  createdBy: res?.createdBy,
                  creationDate: res?.createdDate,
                  companyEmail: res?.email,
                  env: ENV
                }
              });
            })
            .catch(err => {
              console.log(err);
              Mixpanel.people.set(defaultMixpanelAttrs);
            });
          this.unauthenticatedStateAware = false;
          break;
        }
        case 'signUp':
          break;

        case 'signOut':
          if (!this.unauthenticatedStateAware && !this.impersonation) {
            this.store.dispatch(signOut());
            this.unauthenticatedStateAware = true;
          }
          break;

        case 'signIn_failure':
          Mixpanel.track(MIXPANEL_EVENT.UNSUCCESSFUL_LOGIN);
          // eslint-disable-next-line no-console
          console.error('user sign in failed');
          break;

        default:
          break;
      }
    };

    Hub.listen('auth', this.authWatcher);
  }

  checkAuthentication = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user;
    } catch (error) {
      // do nothing, every user starts life unauthenticated. this is normal. some sign in, some don't.
    }
  };

  register = async (fullName, email, username, password) => {
    try {
      const user = await Auth.signUp({
        username,
        password,
        attributes: {
          email
        }
      });
      return user;
    } catch (err) {
      BOLogger.error(`Error in register ${JSON.stringify(err)}`);
    }
  };

  login(username, password) {
    try {
      localStorage.clear();
    } catch (error) {
      BOLogger.error(error.message);
    }
    return new Promise((resolve, reject) => {
      Auth.signIn(username, password)
        .then(user => {
          this.cognitoUser = user;

          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            const error = new Error(user.challengeName);
            error.code = user.challengeName;
            reject(error);
          }
          resolve(user);
        })
        .catch(error => {
          BOLogger.error(`Error in Login : ${JSON.stringify(error)}`);
          if (error.code === 'UserNotConfirmedException') {
            BOLogger.error('the user is not confirmed');
          } else if (error.code === 'PasswordResetRequiredException') {
            reject(error);
          } else if (error.code === 'UserNotFoundException') {
            reject(error);
          } else {
            BOLogger.error(error);
          }
          reject(error);
        });
    });
  }

  async completeNewPassword(newPassword) {
    try {
      const loggedUser = await Auth.completeNewPassword(
        this.cognitoUser, // the Cognito User Object
        newPassword // the new password
      );
      this.cognitoUser = loggedUser;
      return loggedUser;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  logout = async (isImpersonation = false) => {
    this.impersonation = isImpersonation;
    CacheService.clearCache();
    const logout = await Auth.signOut();
    this.cognitoUser = null;
    return logout;
  };

  forgotPassword = async username => {
    try {
      await Auth.forgotPassword(username);
      return;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  forgotPasswordSubmit = async (username, code, newPassword) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      return;
    } catch (error) {
      BOLogger.error(`Error in forgotPasswordSubmit : ${JSON.stringify(error)}`);
      throw new Error(error.message);
    }
  };

  signUp = async (username, password, email) => {
    const signupInfo = {
      username,
      password,
      attributes: {
        email
      }
    };

    try {
      const signedUpUser = await Auth.signUp(signupInfo);
      return signedUpUser;
    } catch (error) {
      BOLogger.error(`Error in signedUpUser : ${JSON.stringify(error)}`);
      throw new Error(error.message);
    }
  };

  confirmSignUp = async (username, code) => {
    try {
      const signedUpUser = await Auth.confirmSignUp(username, code);
      return signedUpUser;
    } catch (error) {
      BOLogger.error(`Error in signedUpUser : ${JSON.stringify(error)}`);
      throw new Error(error.message);
    }
  };

  resendCode = async username => {
    try {
      const resentCode = await Auth.resendSignUp(username);
      return resentCode;
    } catch (error) {
      BOLogger.error(`Error in signedUpUser : ${JSON.stringify(error)}`);
      throw new Error(error.message);
    }
  };

  sendCustomChallengeAnswer(answerChallenge, clientMetadata, callback) {
    const challengeResponses = {};
    challengeResponses.USERNAME = this.username;
    challengeResponses.ANSWER = answerChallenge;

    const authenticationHelper = new AuthenticationHelper(Auth.userPool.userPoolId.split('_')[1]);
    this.getCachedDeviceKeyAndPassword();
    if (this.deviceKey != null) {
      challengeResponses.DEVICE_KEY = this.deviceKey;
    }

    const jsonReq = {
      ChallengeName: 'CUSTOM_CHALLENGE',
      ChallengeResponses: challengeResponses,
      ClientId: Auth.userPool.clientId,
      Session: this.Session,
      ClientMetadata: clientMetadata
    };
    if (this.getUserContextData()) {
      jsonReq.UserContextData = this.getUserContextData();
    }
    this.client.request('RespondToAuthChallenge', jsonReq, (err, data) => {
      if (err) {
        return callback.onFailure(err);
      }

      return this.authenticateUserInternal(data, authenticationHelper, callback);
    });
  }

  authFailure = (reject, err) => {
    console.error(err.message || JSON.stringify(err));
    reject(err);
  };

  authSuccess = (action, username, reject, resolve, result) => {
    const idToken = result.getIdToken().getJwtToken();
    resolve(idToken);
  };

  impersonateUser = async impersonateUsername =>
    new Promise(async (resolve, reject) => {
      const userPool = new CognitoUserPool({
        UserPoolId: Auth.userPool.userPoolId,
        ClientId: Auth.userPool.clientId
      });
      const userToImpersonate = new CognitoUser({
        Username: impersonateUsername,
        Pool: userPool
      });
      userToImpersonate.setAuthenticationFlowType('CUSTOM_AUTH');

      const authenticationDetails = new AuthenticationDetails({
        Username: impersonateUsername
      });

      const currentSession = await Auth.currentSession();

      const adminMetadata = {
        username: Auth.user.username,
        firstname: Auth.user.attributes.given_name,
        lastname: Auth.user.attributes.family_name
      };

      const iauthCallbacks = {
        onSuccess: this.authSuccess.bind(
          this,
          'impersonation',
          impersonateUsername,
          reject,
          resolve
        ),
        onFailure: this.authFailure.bind(this, reject),
        customChallenge: () => {
          this.sendCustomChallengeAnswer.call(
            userToImpersonate,
            currentSession.idToken.jwtToken,
            adminMetadata,
            iauthCallbacks
          );
        }
      };

      userToImpersonate.initiateAuth(authenticationDetails, iauthCallbacks);
    });
}
