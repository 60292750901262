import React from 'react';
import { View } from '@react-pdf/renderer';

export default function Divider({ options }) {
  return (
    <View
      wrap={false}
      style={{
        width: `${options?.width ?? '100%'}`,
        alignSelf: 'center',
        height: 0.5,
        backgroundColor: `${options?.color ?? '#E0E4F4'}`
      }}
    />
  );
}
