import gql from 'graphql-tag';

const getPayrollHourTypes = gql`
  query getPayrollHourTypes($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      payrollHourTypes(entityConnection: "PayrollHourType", filter: { isActive: { eq: true } }) {
        items {
          id
          version
          hourType
          hourTypeAbbreviation
          hourTypeTag
          sortOrder
          isAutofill
          intacctAccountNumber
          intacctNonBillableAccountNumber
          billingHourTypeId
        }
      }
    }
  }
`;

export default getPayrollHourTypes;
