import React from 'react';
import moment from 'moment';
import { AppConstants } from 'utils/AppConstants';
import { jsx } from '@emotion/react';
import { TV, TW, Typography } from '@buildhero/sergeant';
import { getCustomerSignatureSrc } from 'utils';

export default function CustomerSignaturesDisplay({ name, form }) {
  const customerSignatures = form.values[name] || [];
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    item: {
      width: '180px',
      height: '116px',
      marginBottom: '16px',
      marginRight: '96px'
    },
    item3: {
      width: '180px',
      height: '116px',
      marginBottom: '16px'
    }
  };

  return (
    <div css={styles.container}>
      {customerSignatures.map((signature, index) => {
        return (
          <div
            css={(index + 1) % 3 === 0 ? styles.item3 : styles.item}
            key={`sigImageItem${signature.id}`}
          >
            {signature.visit && (
              <Typography variant={TV.S2} weight={TW.BOLD}>
                {`Visit ${signature.visit.visitNumber}`}
              </Typography>
            )}
            {signature.signatureImageUrl && (
              <img
                alt="Customer Signature"
                src={getCustomerSignatureSrc(signature)}
                width="180px"
                height="58px"
                key={`${signature.id}img`}
              />
            )}
            <Typography variant={TV.S2}>
              {`Captured By: ${signature.capturedBy}`}
              <br />
              {`${moment(parseInt(signature.signedDateTime, 10)).format(
                AppConstants.DATETIME_FORMAT
              )}`}
              <br />
              {`Signee: ${signature.nameOfSignee}`}
            </Typography>
          </div>
        );
      })}
    </div>
  );
}
