import React, { PureComponent } from 'react';

import { DndProvider } from 'react-dnd';

import TouchBackend from 'react-dnd-touch-backend';
import HTML5Backend from 'react-dnd-html5-backend';

import isTouchDevice from './isTouchDevice';

const useTouch = isTouchDevice();

class DnDProvider extends PureComponent {
  render() {
    return (
      <DndProvider backend={useTouch ? TouchBackend : HTML5Backend}>
        {this.props.children}
      </DndProvider>
    );
  }
}

export const withDnd = Component => props => (
  <DndProvider backend={useTouch ? TouchBackend : HTML5Backend}>
    <Component {...props} />
  </DndProvider>
);

export default DnDProvider;
