import React from 'react';

import { ThemeProvider, Typography } from '@buildhero/sergeant';
import { useSelector } from 'react-redux';

import WrapTable from 'components/WrapTable';
import { TablePlaceholderVariant } from 'components/WrapTable/WrapTable.constants';
import usePayrollHourTypes from 'scenes/Jobs/JobDetail/queries/usePayrollHourTypes';

import { findAddressByAddressType } from 'utils';
import withLazyMount from 'utils/withLazyMount';

import { ReportTableSectionDefaultProps, ReportTableSectionPropTypes } from '../../propTypes';
import { usePayrollSettings } from '../../queries/usePayrollSettings';
import { useTimekeepingLedger } from '../../queries/useTimekeepingLedger';
import {
  selectNonVisitTimesheetNotes,
  selectTimesheetManualEntry,
  selectTimesheets,
  selectVisitTimesheetNotes
} from '../../selectors';

import ReportSection from '../ReportSection';

import TimeTrackingReportTable from './components/TimeTrackingReportTable';
import { NOTED_COLUMNS, TIMESHEET_COLUMNS } from './LaborSection.constants';
import { useGetManualTimeCols, useGetManualTimeRows } from './LaborSection.hooks';
import { useStyles } from './LaborSection.styles';

const LaborSection = ({ visit, job, loading, error, isReviewReport }) => {
  const styles = useStyles();
  const title = 'Labor';
  const user = useSelector(s => s.user);
  const propertyAddress = findAddressByAddressType(
    job?.customerProperty?.companyAddresses?.items,
    'propertyAddress'
  );
  const { data: settings } = usePayrollSettings(user);
  const isAutomatedTimeTracking = !!settings?.enableAutomatedTimeTracking;
  const { data: locationData } = useTimekeepingLedger(visit.id);

  // Time tracked by mobile App
  const mobileAppLaborRows = isAutomatedTimeTracking
    ? selectTimesheets({ visit, propertyAddress }) || []
    : selectTimesheetManualEntry({ visit, propertyAddress, locationData }) || [];

  // Timesheet notes consist of visit notes and billable nonvisit notes
  const visitNoteRows = selectVisitTimesheetNotes(visit) || [];
  const nonVisitNoteRows = selectNonVisitTimesheetNotes({ visit, isAutomatedTimeTracking }) || [];
  const allNotes = [...visitNoteRows, ...nonVisitNoteRows];

  // Time entered by technicians
  const { data: payrollHourTypes } = usePayrollHourTypes(user.tenantId, user.tenantCompanyId);
  const manualTimeCols = useGetManualTimeCols({ payrollHourTypes, styles, user });
  const manualTimeRows = useGetManualTimeRows({ visit, isAutomatedTimeTracking });

  return (
    <ThemeProvider>
      <ReportSection title={title}>
        <Typography css={styles.subSectionTitle}>Time Tracked by Mobile app</Typography>
        <WrapTable
          placeholderVariant={TablePlaceholderVariant.TEXT}
          columns={TIMESHEET_COLUMNS}
          rows={mobileAppLaborRows}
          loading={loading}
          error={error}
          noDataMessage="No Time Tracked"
        />
        <Typography css={styles.subSectionTitle}>Timesheet Note by Technician</Typography>
        <WrapTable
          placeholderVariant={TablePlaceholderVariant.TEXT}
          columns={NOTED_COLUMNS}
          rows={allNotes}
          loading={loading}
          error={error}
          noDataMessage="No Timesheet Notes"
        />
        {isReviewReport && isAutomatedTimeTracking && (
          <>
            <Typography css={styles.subSectionTitle}>Time Tracking Report</Typography>
            <TimeTrackingReportTable visitId={visit.id} user={user} />
          </>
        )}
        {!isAutomatedTimeTracking && (
          <>
            <Typography css={styles.subSectionTitle}>Time entered by Technician</Typography>
            <WrapTable
              placeholderVariant={TablePlaceholderVariant.TEXT}
              scrollX
              columns={manualTimeCols}
              rows={manualTimeRows}
              loading={loading}
              error={error}
              noDataMessage="No Time entered By Technician"
            />
          </>
        )}
      </ReportSection>
    </ThemeProvider>
  );
};

LaborSection.propTypes = ReportTableSectionPropTypes;
LaborSection.defaultProps = ReportTableSectionDefaultProps;

export default withLazyMount(LaborSection);
