// Adds new line char when the text length exceeds the number of charecter in a line
// While adding new line, it adds at the end of last spaced word
// Sergeant repo already handles to convert new line charecter
export const formatLongTextForPDF = (text, numOfCharsInALine) => {
  const SPACE_CHAR = ' ';
  // when the text is less number of charecters in a line
  if (text.length < numOfCharsInALine) return text;

  // identify the words in the text with the space
  const splittedText = text.split(SPACE_CHAR);

  let lastLineEndCharIndex = 0;
  const formattedText = splittedText.reduce((prevText, currentText) => {
    let textToConcat = SPACE_CHAR;
    // if the length of new word is greater than the limit, add new line char.
    // prevText.length - lastLineEndCharIndex - the current line length
    if (prevText.length - lastLineEndCharIndex + currentText.length > numOfCharsInALine) {
      textToConcat = `${SPACE_CHAR}\n`;
      lastLineEndCharIndex += prevText.length;
    }
    return `${prevText}${textToConcat}${currentText}`;
  }, '');

  return formattedText;
};
