import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { StatusChip } from 'components';
import { MaintenanceStatus } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';

const useStyles = makeStyles({
  chipLabel: {
    textTransform: 'capitalize'
  },
  chip: {
    margin: 5,
    height: 28,
    flex: 1,
    borderRadius: 2,
    filter: 'drop-shadow(0px 4px 15px rgba(51, 51, 51, 0.25))'
  }
});

function StatusFilter(props) {
  const classes = useStyles();

  const { onClickStatusFilter, disabled = false } = props;

  const handleClickFilter = clickedStatus => {
    // if you click an already active filter, it deactivates it
    onClickStatusFilter(clickedStatus);
  };

  return (
    <>
      {Object.values(MaintenanceStatus).map(status => (
        <StatusChip
          key={status}
          label={status}
          enumType={EnumType.MAINTENANCE_STATUS}
          enumValue={status}
          onClick={() => handleClickFilter(status)}
          classes={{
            root: classes.chip,
            label: classes.chipLabel
          }}
          disabled={disabled}
        />
      ))}
    </>
  );
}

StatusFilter.propTypes = {
  /**
   * @func - cb called with the maintenance item status chip that was clicked
   * @param status - one of the MaintenanceStatus values, or null if
   *  unselected
   */
  onClickStatusFilter: PropTypes.func.isRequired
};

export default StatusFilter;
