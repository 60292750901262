import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    justifyContent: 'space-between',
    marginTop: theme.spacing(1.5),
    background: theme.palette.grayscale(98)
  },
  reviewedContainer: {
    background: theme.palette.other.mintCream01
  },
  itemWrapper: {
    display: 'flex'
  },
  summaryWrapper: {
    display: 'flex',
    width: 'auto'
  },
  ReviewWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between'
  },
  dayWrapper: {
    marginTop: theme.spacing(2.3),
    marginBottom: theme.spacing(2.3),
    display: 'flex',
    flexDirection: 'column'
  },
  day: {
    color: theme.palette.grayscale(10),
    fontSize: '20px',
    fontWeight: 600
  },
  reviewed: {
    fontSize: '20px'
  },
  date: {
    color: theme.palette.grayscale(10),
    fontSize: '20px',
    fontWeight: 400
  },
  expandAction: {
    display: 'flex',
    justifyContent: 'center',
    padding: `0 ${theme.spacing(1)}px`,
    background: theme.palette.grayscale(97),
    flex: 1,
    width: '100%'
  },
  reviewedExpandAction: {
    background: theme.palette.other.mintCream02
  }
}));
