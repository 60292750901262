export const sum = ({ rows, colDef, field, valueGetter }) => {
  return rows.reduce((acc, row) => {
    const value =
      (valueGetter || colDef?.cellValueGetter)?.({ row, colDef }) || row[field || colDef?.field];
    if (typeof value === 'number' && !Number.isNaN(value)) {
      return acc + value;
    }
    return acc;
  }, 0);
};
