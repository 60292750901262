import React, { useMemo, useState } from 'react';

import { Button, ThemeProvider } from '@buildhero/sergeant';
import { DeleteOutlined, EditOutlined } from '@material-ui/icons';
import every from 'lodash/every';
import pick from 'lodash/pick';

import WrapTable, {
  actionButtonColumn,
  tableDateTimeFormatter,
  tableEmptyValueFormatter
} from 'components/WrapTable';
import StorageService from 'services/StorageService';
import { BuildHeroThemeProvider } from 'themes/BuildHeroTheme';

import { getUniqueName } from 'utils';
import { VisitStatus } from 'utils/AppConstants';
import { Mode } from 'utils/constants';

import {
  attachmentLayout,
  FileNameField,
  SectionHeader,
  TableRowModal,
  ThumbnailField,
  VisitSubjectField
} from '../Components';

import { useAddAttachmentsToVisit, useDeleteAttachment, useUpdateAttachment } from '../mutations';

const getColumns = ({ isMultiVisits, isEditable, actions }) =>
  [
    isMultiVisits && {
      field: 'subject',
      headerName: 'Visit',
      renderCell: ({ row }) => VisitSubjectField({ ...row.visit }),
      flex: 1
    },
    {
      field: 'id',
      hide: true
    },
    {
      field: 'fileUrl',
      headerName: 'Preview',
      renderCell: ThumbnailField,
      align: 'center',
      flex: 1
    },
    {
      field: 'type',
      headerName: 'Type',
      valueFormatter: tableEmptyValueFormatter,
      flex: 1
    },
    {
      field: 'fileName',
      headerName: 'File Name',
      valueFormatter: ({ row }) => {
        return tableEmptyValueFormatter({ value: row.customFileName || row.fileName });
      },
      renderCell: FileNameField,
      flex: 2
    },
    {
      field: 'addedBy',
      headerName: 'Added By',
      valueFormatter: ({ row }) => {
        return tableEmptyValueFormatter({ value: row.addedBy || row.createdBy });
      },
      flex: 2
    },
    {
      field: 'addedDateTime',
      headerName: 'Date Added',
      valueFormatter: ({ row }) => {
        return tableDateTimeFormatter({ value: row.addedDateTime || row.createdDateTime });
      },
      flex: 2
    },
    {
      field: 'comment',
      headerName: 'Comment',
      valueFormatter: tableEmptyValueFormatter,
      flex: 2
    },
    isEditable && actionButtonColumn({ actions })
  ].filter(Boolean);

const mapVisitsToRows = visits =>
  visits
    .filter(visit => visit.attachments?.items?.length > 0)
    .flatMap(visit => {
      return visit.attachments.items.map(attachment => ({
        visit: {
          id: visit.id,
          status: visit.status,
          reviewStatus: visit.reviewStatus,
          visitNumber: visit.visitNumber,
          scheduledFor: visit.scheduledFor
        },
        ...attachment
      }));
    })
    .filter(row => row.type === 'before' || row.type === 'after')
    .sort((a, b) => {
      if (a.visit.visitNumber - b.visit.visitNumber === 0) {
        if (a.type === b.type) {
          return a.customFileName.toLowerCase() > b.customFileName.toLowerCase();
        }
        return a.type < b.type;
      }
      return a.visit.visitNumber - b.visit.visitNumber;
    });

const pickAttachmentFields = attachment =>
  pick(attachment, ['id', 'version', 'customFileName', 'type', 'comment', 'includeInInvoice']);

const uploadFile = ({ tenantId, file }) => {
  const storageService = new StorageService();
  const uniqueName = getUniqueName(tenantId, file?.name);
  return storageService.uploadFile(file, uniqueName);
};

const prepareAttachmentForEdit = async data => {
  const storageService = new StorageService();
  const fileUrl = await storageService.getFile(data.fileUrl);
  return {
    ...data,
    visitId: data.visit?.id,
    attachments: [
      {
        key: 0,
        label: data.fileName,
        file: {
          type: fileUrl?.match(/\.(jpeg|jpg|gif|png)$/) ? 'image' : 'video'
        },
        fileUrl
      }
    ]
  };
};

const PhotosAndVideosTable = ({ tenantId, visits, isMultiVisits, isLoading }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [attachmentToEdit, setAttachmentToEdit] = useState(null);

  const [addAttachmentMutation] = useAddAttachmentsToVisit();
  const [deleteAttachmentMutation] = useDeleteAttachment();
  const [editAttachmentMutation] = useUpdateAttachment();

  const handleDeleteAttachment = async attachmentToDelete => {
    await deleteAttachmentMutation({
      tenantId,
      visitId: attachmentToDelete.visit.id,
      attachmentId: attachmentToDelete.id
    });
  };

  const handleSetAttachmentToEdit = async row => {
    const preparedData = await prepareAttachmentForEdit(row);
    setAttachmentToEdit(preparedData);
  };

  const handleEditAttachment = async updatedAttachment => {
    // Are we updating the underlying image or not?
    if (attachmentToEdit?.attachments === updatedAttachment?.attachments) {
      await editAttachmentMutation({
        tenantId,
        attachment: pickAttachmentFields(updatedAttachment)
      });
    } else {
      const fileInput = updatedAttachment.attachments?.[0];
      const fileUrl = await uploadFile({ tenantId, file: fileInput?.file });
      const fileName = fileInput?.label;
      await editAttachmentMutation({
        tenantId,
        attachment: { ...pickAttachmentFields(updatedAttachment), fileUrl, fileName }
      });
    }
    setAttachmentToEdit(null);
  };

  const handleAddAttachment = async ({ visitId, attachments, type, comment, customFileName }) => {
    const fileInput = attachments?.[0];
    const fileUrl = await uploadFile({ tenantId, file: fileInput?.file });
    await addAttachmentMutation({
      tenantId,
      visitId,
      attachments: [
        {
          fileName: fileInput?.label,
          customFileName,
          fileUrl,
          isUploaded: true,
          type,
          comment
        }
      ]
    });
    setShowAddModal(false);
  };

  const actions = [
    {
      icon: EditOutlined,
      label: 'Edit',
      onClick: row => handleSetAttachmentToEdit(row)
    },
    {
      icon: DeleteOutlined,
      label: 'Delete',
      onClick: row => handleDeleteAttachment(row)
    }
  ];

  const visitOptions = useMemo(() => {
    if (!isMultiVisits) return;

    return visits.map(v => ({ label: `Visit ${v.visitNumber}`, value: v.id }));
  }, [visits, isMultiVisits]);

  const layout = useMemo(() => attachmentLayout({ isMultiVisits, visitOptions }), [
    isMultiVisits,
    visitOptions
  ]);

  const isEditable = every(visits, visit => visit.status === VisitStatus.CONVERTED);

  const rows = useMemo(() => mapVisitsToRows(visits), [visits]);
  const columns = useMemo(() => getColumns({ rows, isMultiVisits, isEditable, actions }), [
    rows,
    isMultiVisits,
    isEditable,
    actions
  ]);

  return (
    <>
      <ThemeProvider>
        <SectionHeader title="Photos and Videos">
          {isEditable && (
            <Button type="secondary" size="small" onClick={() => setShowAddModal(true)}>
              Add Photo Or Video
            </Button>
          )}
        </SectionHeader>
        <WrapTable
          columns={columns}
          rows={rows}
          noDataMessage="No Photos Or Videos"
          hideFooter={rows.length < 11}
          loading={isLoading}
          loadingRows={3}
        />
      </ThemeProvider>
      {/* TODO: Note this is due to theme incompatibility */}
      {!isLoading && (
        <BuildHeroThemeProvider>
          {showAddModal && (
            <TableRowModal
              onAction={handleAddAttachment}
              onClose={() => setShowAddModal(false)}
              open={showAddModal}
              layout={layout}
              data={{ visitId: isMultiVisits ? '' : visits[0].id, content: '' }}
              mode={Mode.ADD}
              formVersion={Mode.ADD}
              title="Add Photo or Video"
            />
          )}
          {attachmentToEdit && (
            <TableRowModal
              onAction={handleEditAttachment}
              onClose={() => setAttachmentToEdit(null)}
              open={Boolean(attachmentToEdit)}
              layout={layout}
              data={attachmentToEdit}
              mode={Mode.EDIT}
              formVersion={Mode.EDIT}
              title="Edit Photo or Video"
            />
          )}
        </BuildHeroThemeProvider>
      )}
    </>
  );
};

PhotosAndVideosTable.defaultProps = {
  visits: [],
  isLoading: false
};

export default PhotosAndVideosTable;
