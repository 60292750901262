import gql from 'graphql-tag';

const deleteTimesheetEntry = gql`
  mutation deleteTimesheetEntry($partitionKey: String, $id: String!) {
    deleteTimesheetEntry(partitionKey: $partitionKey, id: $id) {
      id
      version
    }
  }
`;

export default deleteTimesheetEntry;
