import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CX from 'classnames';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { Grid, Typography } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Spinner from 'components/Spinners/CircularIndeterminate';
import ErrorBoundaries from 'scenes/Error';
import AppConstants, { PermissionConstants } from 'utils/AppConstants';
import { checkPermission } from 'utils';
import WorkTable from './WorkTable';
import HourSummary from './HourSummary';
import TimesheetNotes from './TimesheetNotes';
import {
  getNotes,
  handleSplit,
  summaryEdit,
  getHourSummary,
  handleRemove,
  reviewedCheck,
  updateTimesheetEntryField,
  updateHourSelected,
  updateTimesheetEntryMultiFields,
  updateHourSelectedValue
} from './helpers';
import {
  deleteTimesheetEntry,
  handleTimesheetDailyOverrides,
  reviewTimesheetEntries
} from './services';
import { message } from './constants';
import useStyles from './DaySummary.styles';

const DaySummary = ({
  user,
  employee,
  snackbarOn,
  dateRange,
  hourTypes,
  setDayApprovedStatus,
  fetchTimeSheetPeriods,
  handleEditTimesheetEntries,
  updateOverAllHourSummary,
  setLeaveConfirmation,
  dailyOverrides,
  timesheetEntries,
  timesheetPeriodId,
  refetch
}) => {
  const { dayStartUTC } = dateRange;

  const [showLoader, setShowLoader] = React.useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedPayrollHours, setSelectedPayrollHours] = useState([]);
  const [daySummary, setDaySummary] = useState([]);

  const isReviewed = reviewedCheck(timesheetEntries);

  const isEmptyTimesheetEntries = timesheetEntries.length === 0 && !showLoader;

  React.useEffect(() => {
    setDayApprovedStatus({ [dayStartUTC]: isReviewed || timesheetEntries.length === 0 });
    // NOTE: setDayApprovedStatus function is no need to add
    // eslint-disable-next-line
  }, [isReviewed, timesheetEntries]);

  const updateHourSummary = data => {
    const summary = getHourSummary({ data, dailyOverrides, hourTypes, dayStartUTC });
    setDaySummary(summary);
    updateOverAllHourSummary({ [dayStartUTC]: summary });
  };

  useEffect(() => {
    updateHourSummary(timesheetEntries);
    // NOTE: updateHourSummary function is no need to add
    // eslint-disable-next-line
  }, [timesheetEntries, hourTypes, dailyOverrides, dayStartUTC]);

  const classes = useStyles();
  const notes = getNotes(timesheetEntries, employee.id);

  const setEditedTimesheetEntries = data => {
    handleEditTimesheetEntries(data, dayStartUTC);
    setLeaveConfirmation(true);
  };

  const handleTimestampAction = (index, actionName, item) => {
    const action = {
      split: handleSplit,
      delete: handleRemove
    };
    if (actionName === 'split') {
      setSelectedPayrollHours([]);
    }
    if (actionName === 'delete' && item.id) {
      deleteTimesheetEntry({
        timesheetId: item.id,
        snackbarOn
      });
    }
    const newTimesheetEntries = action[actionName](timesheetEntries, index);
    setEditedTimesheetEntries(newTimesheetEntries);
  };

  const handleTimesheetEntryEdit = (
    index,
    fieldKey,
    value,
    isMultiFields = false,
    dependantFieldKey = null,
    dependantValue = null
  ) => {
    if (value === null) return;

    let newTimesheetEntries;
    if (isMultiFields) {
      newTimesheetEntries = updateTimesheetEntryMultiFields(timesheetEntries, index, value);
    } else {
      newTimesheetEntries = updateTimesheetEntryField(timesheetEntries, index, fieldKey, value);
      if (dependantFieldKey) {
        newTimesheetEntries = updateTimesheetEntryField(
          newTimesheetEntries,
          index,
          dependantFieldKey,
          dependantValue
        );
      }
    }
    if (fieldKey.toLowerCase().includes('utc')) {
      updateHourSummary(newTimesheetEntries);
    }
    setEditedTimesheetEntries(newTimesheetEntries);
  };

  const selectPayrollHour = value => {
    const newList = updateHourSelected(selectedPayrollHours, value);
    setSelectedPayrollHours(newList);
  };

  const changePayrollHour = value => {
    const newTimesheetEntries = updateHourSelectedValue(
      selectedPayrollHours,
      timesheetEntries,
      value
    );
    setEditedTimesheetEntries(newTimesheetEntries);
    setSelectedPayrollHours([]);
  };

  const updateDailySummary = summary => {
    const data = summaryEdit(daySummary, summary);
    updateOverAllHourSummary({ [dayStartUTC]: data });
    setDaySummary(data);
  };

  const updateSummary = summary => {
    const { tenantId: partitionKey } = user;
    handleTimesheetDailyOverrides({
      partitionKey,
      snackbarOn,
      successCallback: updateDailySummary,
      timesheetPeriodId,
      data: { ...summary, dateUTC: dayStartUTC }
    });
  };

  const hasEditPermission = checkPermission(
    'allow',
    PermissionConstants.FUNCTIONS_TIMETRACKING_REPORT,
    user
  );

  return (
    <ErrorBoundaries key={dayStartUTC}>
      <Grid
        container
        direction="column"
        className={CX(classes.container, { [classes.reviewedContainer]: isReviewed })}
      >
        <Box
          display="flex"
          p={3}
          paddingTop={2}
          paddingBottom={1}
          flexDirection="column"
          overflow="scroll"
          width="100%"
        >
          <Box display="flex" marginTop={2} justifyContent="space-between">
            <Grid container className={classes.summaryWrapper}>
              <Grid className={classes.dayWrapper} item>
                <Typography className={classes.day} variant="subtitle1">
                  {moment.unix(dayStartUTC).format('dddd')}
                </Typography>
                <Typography className={classes.date} variant="subtitle1">
                  {moment.unix(dayStartUTC).format(AppConstants.DATE_FORMAT)}
                </Typography>
              </Grid>
              <HourSummary
                isEditable
                user={user}
                showCalculatedInfo
                data={daySummary}
                onHourSummaryFocusOut={updateSummary}
                onHourSummaryChange={summary => updateDailySummary(summary)}
              />
              {showLoader && <Spinner size={24} />}
            </Grid>
            {isEmptyTimesheetEntries ? (
              <div style={{ minWidth: 135 }}>
                <Typography variant="body1">{message.noEntry}</Typography>
              </div>
            ) : (
              <Grid className={classes.ReviewWrapper}>
                <TimesheetNotes notes={notes} />
                <Box alignItems="baseline" className={classes.itemWrapper}>
                  <FormControlLabel
                    value="start"
                    control={
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        checked={isReviewed}
                        disabled={isReviewed || !hasEditPermission}
                        onClick={() =>
                          !isReviewed &&
                          reviewTimesheetEntries({
                            data: timesheetEntries,
                            setShowLoader,
                            employeeId: employee.id,
                            snackbarOn,
                            partitionKey: user.partitionKey,
                            successCallback: refetch
                          })
                        }
                      />
                    }
                    label={
                      <Typography className={classes.reviewed} variant="body2">
                        Reviewed
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                </Box>
              </Grid>
            )}
          </Box>
        </Box>
        <Grid
          item
          className={CX(classes.expandAction, { [classes.reviewedExpandAction]: isReviewed })}
        >
          <Box
            display="flex"
            alignItems="center"
            style={{ cursor: 'pointer' }}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <Typography variant="body2"> {isExpanded ? 'Hide' : 'Show'} Details</Typography>
            {isExpanded ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
          </Box>
        </Grid>
        {isExpanded && (
          <WorkTable
            dayStartUTC={dayStartUTC}
            user={user}
            shifts={timesheetEntries}
            employee={employee}
            hourTypes={hourTypes}
            isVisible={isExpanded}
            snackbarOn={snackbarOn}
            refetch={refetch}
            onSelectPayrollHour={selectPayrollHour}
            onChangePayrollHour={changePayrollHour}
            selectedPayrollHours={selectedPayrollHours}
            fetchTimeSheetPeriods={fetchTimeSheetPeriods}
            onTimesheetEntryEdit={handleTimesheetEntryEdit}
            onHandleTimestampAction={handleTimestampAction}
            hasEditPermission={hasEditPermission}
            setLeaveConfirmation={setLeaveConfirmation}
          />
        )}
      </Grid>
    </ErrorBoundaries>
  );
};

export default DaySummary;

DaySummary.propTypes = {
  updateOverAllHourSummary: PropTypes.func.isRequired,
  setDayApprovedStatus: PropTypes.func.isRequired,
  snackbarOn: PropTypes.func.isRequired,
  setLeaveConfirmation: PropTypes.func.isRequired,
  dateRange: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  employee: PropTypes.object.isRequired,
  hourTypes: PropTypes.array
};

DaySummary.defaultProps = {
  hourTypes: []
};
