import React from 'react';
import { CompanyService } from 'services/core';
import { logErrorWithCallback } from 'utils';
import removeObjProperties from 'utils/removeObjProperties';

export const addCompanySettings = async (
  setting = {},
  type,
  tenantId,
  tenantCompanyId,
  snackbarOn
) => {
  const Service = new CompanyService();
  const payload = {
    companyId: tenantCompanyId,
    companySettings: [
      removeObjProperties(
        {
          ...setting,
          type
        },
        ['version', '__typename']
      )
    ]
  };

  let responseData = {};

  try {
    const response = await Service.addCompanySettings(tenantId, payload);
    snackbarOn('success', 'Successfully saved settings');
    responseData = response?.data?.addCompanySettingsToCompany?.[0];
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to update settings, please try again later');
  }
  return responseData;
};

export const getCompanySettings = async (tenantId, companyId, type, snackbarOn) => {
  let responseData = {};
  try {
    const Service = new CompanyService();
    const settingFilter = {
      type: { eq: type }
    };
    const { data } = await Service.getCompanySettings(tenantId, companyId, settingFilter);
    responseData = data?.getCompany?.companySettings?.items?.[0] || {};
  } catch (error) {
    logErrorWithCallback(
      error,
      snackbarOn,
      'Unable to fetch company setting, please try again later'
    );
  }
  return responseData;
};

const useCompanySetting = (type, tenantId, tenantCompanyId, snackbarOn) => {
  const [companySetting, setCompanySetting] = React.useState({});
  const getData = async () => {
    const companySettingDetails = await getCompanySettings(
      tenantId,
      tenantCompanyId,
      type,
      snackbarOn
    );
    setCompanySetting(companySettingDetails);
  };
  const saveData = async data => {
    const companySettingDetails = await addCompanySettings(
      data,
      type,
      tenantId,
      tenantCompanyId,
      snackbarOn
    );
    setCompanySetting(companySettingDetails);
  };
  React.useEffect(() => {
    if (tenantId && tenantCompanyId) {
      getData();
    }
    // Disable lint - getData function not needed
    // eslint-disable-next-line
  }, [tenantId, tenantCompanyId]);
  return [companySetting, saveData, getData];
};

export default useCompanySetting;
