import { useTheme } from '@emotion/react';

import { DEFAULT_COLUMN_WIDTH, ROW_HEIGHT } from '../WrapTable.constants';

const noWrapCss = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
};

const flexAlignMap = {
  center: 'center',
  right: 'flex-end'
};

export const useHeaderColumnStyles = ({ colDef, scrollX, lockRowHeight, ...props }) => {
  const { palette } = useTheme();

  const {
    flex,
    minWidth,
    maxWidth,
    noWrap,
    width,
    headerAlign,
    headerCss = {},
    headerJustify,
    noHeaderPadding
  } = colDef;

  const shouldNotWrap = props.noWrap || lockRowHeight || noWrap;
  const overriddenCss = typeof headerCss === 'function' ? headerCss(props) : headerCss;

  return {
    headerColumn: {
      alignItems: flexAlignMap[headerAlign],
      backgroundColor: palette.grayscale(92),
      borderBottom: `1px solid ${palette.grayscale(80)}`,
      borderLeft: `1px solid ${palette.grayscale(80)}`,
      boxSizing: 'border-box',
      display: 'flex',
      flex: flex || 1,
      flexBasis: width || DEFAULT_COLUMN_WIDTH,
      flexDirection: 'column',
      flexShrink: scrollX ? 0 : 1,
      justifyContent: headerJustify,
      maxHeight: lockRowHeight ? ROW_HEIGHT : undefined,
      maxWidth: maxWidth || width,
      minHeight: 49,
      minWidth: minWidth || width,
      overflow: 'hidden',
      paddingBottom: 16,
      paddingLeft: noHeaderPadding ? 0 : 8,
      paddingRight: noHeaderPadding ? 0 : 8,
      paddingTop: 16,
      width: width || DEFAULT_COLUMN_WIDTH,
      '& [class^="MuiTypography-root"]': {
        fontWeight: 600
      },
      '& *': {
        ...(shouldNotWrap ? noWrapCss : { overflowWrap: 'anywhere' }),
        textAlign: headerAlign
      },
      ...overriddenCss
    },
    error: {
      backgroundColor: palette.support.red.light,
      p: {
        color: palette.support.red.dark
      }
    }
  };
};
