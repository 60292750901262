import { sumBy } from 'lodash';
import { roundCurrency, getTenantSettingValueForKey } from 'utils';
import { Context } from 'components';
import { Logger } from 'services/Logger';
import { TransactionType } from 'utils/constants';
import { formatAdjustmentItemForMutation } from './components/AdjustmentItem/AdjustmentItem.utils';

export const calculateTotals = (items, taxRate = 0) => {
  const computeSubtotal = arr => roundCurrency(sumBy(arr, 'amount'));

  const subtotal = computeSubtotal(items);
  const taxableSubtotal = computeSubtotal(items.filter(i => i.taxable));
  const taxAmount = roundCurrency(taxableSubtotal * (taxRate / 100));
  const amount = roundCurrency(subtotal + taxAmount);
  return {
    subtotal,
    taxableSubtotal,
    taxAmount,
    amount
  };
};

export const handleFieldChange = (key, value, { values, setValues, setFieldValue }) => {
  if (key === 'amount') {
    setFieldValue('availableAmount', roundCurrency(value - values.appliedAmount));
  } else if (key === 'appliedAmount') {
    setFieldValue('availableAmount', roundCurrency(values.amount - value));
  } else if (['adjustmentItems', 'taxRate'].includes(key)) {
    setValues({ ...values, ...calculateTotals(values.adjustmentItems, values.taxRate?.taxRate) });
  }
};

export const formatAdjustmentData = async (rawData, user = null) => {
  if (rawData.companyId) return rawData; // only run format once
  const { tenantId, tenantCompanyId } = user ?? rawData;
  const {
    adjustmentItems: { items: adjustmentItems },
    transactions: { items: transactions },
    priceBook: givenPriceBook,
    taxRate,
    billingCustomer,
    property,
    ...data
  } = rawData;

  const company =
    Context.getCompanyContext()?.getCompany ??
    (await Context.setCompanyContext(tenantId, `${tenantId}_Company_${tenantCompanyId}`));
  if (!company) {
    Logger.error('Company is null');
    return null;
  }

  const priceBook =
    givenPriceBook ??
    (company.defaultPriceBook && {
      label: company.defaultPriceBook.name,
      value: company.defaultPriceBook.id
    });

  const adjustmentTypeOptions = company.adjustmentTypes.items.map(a => ({
    label: a.name,
    value: a.id,
    type: a.type,
    ledgerAccount: a.ledgerAccount
      ? { label: a.ledgerAccount.name, value: a.ledgerAccount.id }
      : undefined,
    ledgerOffsetAccount: a.ledgerOffsetAccount
      ? { label: a.ledgerOffsetAccount.name, value: a.ledgerOffsetAccount.id }
      : undefined
  }));

  const departmentOptions = company.departments.items.map(d => ({
    label: d.tagName,
    value: d.id
  }));

  const taxRateOptions = company.taxRates.items.map(t => ({
    label: `${t.name}: ${t.taxRate}%`,
    value: t.id,
    taxRate: t.taxRate
  }));

  const priceBookOptions = company.priceBooks.items.map(p => ({
    label: p.name,
    value: p.id
  }));

  let accountingApp;
  try {
    const settingsString = getTenantSettingValueForKey('accountingAppSettings');
    accountingApp = settingsString && JSON.parse(settingsString)?.app;
  } catch (error) {
    Logger.error(error);
  }

  return {
    ...data,
    billingCustomer: {
      customerName: '',
      ...billingCustomer,
      link: { label: billingCustomer?.customerName, to: `/customer/view/${billingCustomer?.id}` }
    },
    property: {
      companyName: '',
      ...property,
      link: { label: property?.companyName, to: `/property/view/${property?.id}` }
    },
    // taxRateValue is used for historical accuracy in case the taxRate changes
    ...(data.transactionType !== TransactionType.OVERPAYMENT &&
      calculateTotals(adjustmentItems, data.taxRateValue)),
    availableAmount: roundCurrency(data.amount - data.appliedAmount),
    tenantId,
    tenantCompanyId,
    taxRate: taxRate
      ? { ...taxRate, label: `${taxRate.label}: ${data.taxRateValue}%`, taxRate: data.taxRateValue }
      : undefined,
    companyId: company.id,
    adjustmentItems: adjustmentItems
      .slice()
      .sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0)),
    transactions,
    priceBook,
    adjustmentTypeOptions,
    departmentOptions,
    taxRateOptions,
    priceBookOptions,
    accountingApp
  };
};

export const formatAdjustmentDataForMutation = data => ({
  companyId: data.companyId,
  id: data.id,
  number: data.number,
  date: data.date,
  amount: data.amount,
  departmentId: data.department.value,
  billingCustomerId: data.billingCustomer.id,
  propertyId: data.property?.id,
  adjustmentTypeId: data.adjustmentType.value,
  creditCardTransaction: data.creditCardTransaction,
  exportStatus: data.exportStatus,
  syncStatus: data.syncStatus,
  transactions: Object.values(data.applicationMapRef?.current ?? {}),
  ...(data.transactionType !== TransactionType.OVERPAYMENT && {
    priceBookId: data.priceBook?.value,
    taxAmount: data.taxAmount,
    taxRateValue: data.taxRate?.taxRate,
    taxRateId: data.taxRate?.value,
    taxLedgerAccountId: data.taxLedgerAccount?.value,
    adjustmentItems: data.adjustmentItems.map(formatAdjustmentItemForMutation)
  })
});
