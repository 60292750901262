import gql from 'graphql-tag';

const SyncSageJobs = gql`
  mutation SyncSageJobs($partitionKey: String!, $data: SyncSageJobsInput!) {
    syncSageJobs(partitionKey: $partitionKey, data: $data) {
      id
      accountingRefId
      code
      isActive
      name
      parentCode
    }
  }
`;

export default SyncSageJobs;
