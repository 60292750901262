import React from 'react';
import { RowActionsMenu } from './RowActionsMenu';

export const actionButtonColumn = ({ actions = [] }) => ({
  field: 'actions',
  headerName: '',
  align: 'center',
  flex: 1,
  width: 41,
  maxWidth: 41,
  noPadding: true,
  justify: 'center',

  // eslint-disable-next-line react/prop-types
  renderCell: ({ row }) => <RowActionsMenu row={row} actions={actions} />
});
