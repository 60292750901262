/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';
import { getItemGlGroups } from 'services/API/itemGlGroup';

export async function getLedgerAccounts(relations = null) {
  return await instance
    .get(relations ? `ledgeraccounts?include=${relations}` : `ledgeraccounts`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getLedgerAccountById(id) {
  return await instance
    .get(`ledgeraccounts/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function ledgerAccountSearch(term, searchColumns) {
  if (term.length > 0) {
    return await instance
      .post(`ledgeraccounts/search/${term}`, {
        searchColumns: searchColumns || ['name']
      })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  }
  return await getLedgerAccounts();
}

export async function ledgerAccountAndItemGlGroupSearch(term, searchColumns) {
  let ledgerAccountList = [];
  let itemGlGroupList = [];

  if (term.length > 0) {
    ledgerAccountList = await instance
      .post(`ledgeraccounts/search/${term}`, {
        searchColumns: searchColumns || ['name']
      })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  } else {
    ledgerAccountList = await getLedgerAccounts();
  }

  if (term.length > 0) {
    itemGlGroupList = await instance
      .post(`itemgigroup/search/${term}`, {
        searchColumns: searchColumns || ['name']
      })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  } else {
    itemGlGroupList = await getItemGlGroups();
  }

  return ledgerAccountList.concat(itemGlGroupList);
}
