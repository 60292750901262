import gql from 'graphql-tag';

const updateDepartment = gql`
  mutation updateDepartment($partitionKey: String, $data: UpdateDepartmentInput!) {
    updateDepartment(partitionKey: $partitionKey, data: $data) {
      id
      version
      companyAddresses(entityConnection: "CompanyAddress") {
        items {
          id
          version
          addressType
        }
      }
      skills(entityConnection: "Skill") {
        items {
          id
          mappedEntity {
            ... on Skill {
              tagName
            }
          }
        }
      }
    }
  }
`;

export default updateDepartment;
