import mixpanel from 'mixpanel-browser';
import ENV from 'configs/env';

import { MIXPANEL_PROPERTY_NAME } from 'constants/mixpanelEvents';

mixpanel.init('0f80f05a55879105bad8de14739fc4c6');
// TODO - Remove QA after first deploy

const sendToMixpanel = ENV === 'qa' || ENV === 'prod';
const Mixpanel = {
  identify: id => {
    if (sendToMixpanel) mixpanel.identify(id);
  },
  alias: id => {
    if (sendToMixpanel) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (sendToMixpanel) mixpanel.track(name, props);
  },
  people: {
    set: props => {
      if (sendToMixpanel) mixpanel.people.set(props);
    }
  }
};

export const sendMixpanelEvent = (eventName, pageName, props = {}) => {
  Mixpanel.track(eventName, {
    [MIXPANEL_PROPERTY_NAME.PAGE_NAME]: pageName,
    ...props
  });
};

export default Mixpanel;
