import gql from 'graphql-tag';

const deleteSummary = gql`
  mutation deleteSummary($partitionKey: String!, $id: String!) {
    deleteSummary(partitionKey: $partitionKey, id: $id) {
      id
      sortKey
    }
  }
`;

export default deleteSummary;
