import React, { Component } from 'react';
import { MUIForm } from '@buildhero/sergeant';
import { FormPropertiesLayout, FormPropertiesLayoutAdmin } from './layout';
import { Modal } from 'components';

const actionButtons = {
  save: {
    label: 'Confirm',
    color: 'primary',
    buttonType: 'contained',
    behavior: {
      new: true,
      edit: true
    }
  },
  cancel: {
    label: 'Cancel',
    color: 'secondary',
    buttonType: 'outlined',
    behavior: {
      new: true,
      edit: true
    }
  }
};

class FormPropertiesModal extends Component {
  constructor(props) {
    super(props);
    this.state = { isAdmin: props.isAdmin || false };
  }

  render() {
    const { formService, isAdmin } = this.state;
    const { open, confirm, cancel, ...data } = this.props;

    actionButtons.save.action = () => formService.submit();
    actionButtons.cancel.action = () => cancel();
    const layout = isAdmin ? FormPropertiesLayoutAdmin : FormPropertiesLayout;

    return (
      <Modal
        open={open}
        handleClose={cancel}
        width={696}
        buttons={actionButtons}
        disableBackdropClick
      >
        <MUIForm
          configuration={layout}
          data={data}
          layout="edit"
          onCreateService={service => this.setState(() => ({ formService: service }))}
          onComplete={completed => confirm(completed)}
        />
      </Modal>
    );
  }
}

export default FormPropertiesModal;
