import gql from 'graphql-tag';

const getPropertiesByCustomer = gql`
  query getCustomer($partitionKey: String!, $sortKey: String!) {
    getCustomer(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      customerName
      customerTypeValue
      accountNumber
      status
      email
      phonePrimary
      amountNotToExceed
      sameAddress
      receiveSMS
      customerProperties(entityConnection: "CustomerProperty", limit: 1500) {
        items {
          id
          tenantId
          partitionKey
          sortKey
          hierarchy
          entityType
          companyName
          customerPropertyTypeValue
          accountNumber
          accountingRefId
          amountNotToExceed
          sameAddress
          version
          isActive
          companyAddresses(entityConnection: "CompanyAddress") {
            items {
              id
              tenantId
              partitionKey
              sortKey
              hierarchy
              addressLine1
              addressLine2
              state
              city
              zipcode
              entityType
              addressType
              billTo
            }
          }
          jobs(entityConnection: "Job", limit: 100) {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              customIdentifier
              jobNumber
              jobTypeName
              jobTypeInternal
              priority
              status
              customerName
              customerPropertyName
            }
          }
          quotes: quotesView(entityConnection: "Quote") {
            items {
              id
              status
            }
          }
        }
      }
    }
  }
`;

export default getPropertiesByCustomer;
