import React from 'react';
import { func } from 'prop-types';
import { AlgoliaSearch } from 'components';
import { EntityType, JobStatus } from 'utils/constants';
import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';

const JobSearchHeader = ({ onJobSelected }) => {
  const handleChange = job => onJobSelected(job?.jobNumber);
  return (
    <AlgoliaSearch
      searchIndex={defaultSearchIndex}
      placeholder="Search Companies, Properties, or Jobs"
      name="selectJob"
      value=""
      displayText={['jobNumber', 'customerName', 'customerPropertyName']}
      delimiter=", "
      locatedInPopUp
      filters={[
        {
          attribute: 'entityType',
          valueArray: [EntityType.JOB]
        },
        {
          attribute: 'status',
          negate: true,
          valueArray: [JobStatus.CANCELED]
        },
        {
          attribute: 'status',
          negate: true,
          valueArray: [JobStatus.COMPLETE]
        },
        {
          attribute: 'status',
          negate: true,
          valueArray: [JobStatus.ON_HOLD]
        }
      ]}
      handleChange={handleChange}
      isRequired
    />
  );
};

JobSearchHeader.propTypes = {
  onJobSelected: func.isRequired
};

export default JobSearchHeader;
