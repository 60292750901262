import gql from 'graphql-tag';

const getAttachmentsByJobNumber = gql`
  query getAttachmentsByJobNumber($jobNumber: String!) {
    getJobByJobNumber(jobNumber: $jobNumber) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      tenantId
      hierarchy
      entityType
      jobNumber
      customIdentifier
      attachments(entityConnection: "Attachment") {
        items {
          fileName
          customFileName
          fileUrl
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          description
          comment
          createdDate
          createdDateTime
          createdBy
        }
      }
    }
  }
`;

export default getAttachmentsByJobNumber;
