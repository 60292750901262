const quoteAttributes = {
  executionTypes: ['Added'],
  fields: {
    status: 'Status',
    name: 'Title',
    versionLabel: 'Version Title',
    ownerValue: 'Project Manager',
    salesByValue: 'Sold By',
    departmentValue: 'Department',
    customerPoNumber: 'Customer PO Number',
    priceBookId: 'Pricebook ID',
    serviceAgreementId: 'Service Agreement ID',
    dueDate: 'Due Date',
    expirationLength: 'Expiration (Number of days after receiving)',
    propertyRepId: 'Property Representative ID',
    orderedById: 'Company Representative/Ordered By ID',
    issueDescription: 'Issue Description',
    description: 'Notes',
    subTotalOverride: 'Subtotal Override',
    subTotalAmountTaxableOverride: 'Taxable Subtotal Override',
    taxRateValue: 'Tax Rate Percentage',
    totalAmountQuotedOverride: 'Total Override',
    accountManagerValue: 'Account Manager'
  }
};

const parentEntityMapToFieldName = {
  QuoteLineTask: 'Task Material Line Item',
  Quote: 'Quote Material/Discount Line Item'
};

const entityMapAttributes = ({ parentEntityType }) => {
  const materialLineItemFieldName = parentEntityMapToFieldName[parentEntityType] || '';
  // This contains entities with fields required to be displayed on the Activity Log
  return {
    Quote: quoteAttributes,
    VersionedQuote: quoteAttributes,
    QuoteTaskGroup: {
      // execution types added to the executionTypes array will always be displayed
      executionTypes: [],
      // the fields object contain keys which represent entity data included in the Activity Log
      // the properties represent the name used when the log is displayed
      fields: {
        name: 'Group Name',
        totalAmountOverride: 'Group Total Override'
      },
      // hideChanges array contains displayed logs, but changes with old and new values will be hidden
      hideChanges: []
    },
    QuoteLineTask: {
      executionTypes: [],
      fields: {
        name: 'Task Name',
        description: 'Task Description',
        propertyAssetId: 'Task Asset ID',
        quoteTaskGroupId: 'Task Group ID',
        unitPrice: 'Task Cost Override',
        sortOrder: 'Task Sort Order'
      },
      hideChanges: []
    },
    QuoteLineProduct: {
      executionTypes: [],
      fields: {
        name: `${materialLineItemFieldName} Name`,
        description: `${materialLineItemFieldName} Description`,
        departmentId: `${materialLineItemFieldName} Department ID`,
        quantity: `${materialLineItemFieldName} Quantity`,
        unitCost: `${materialLineItemFieldName} Unit Cost`,
        markupValue: `${materialLineItemFieldName} Markup Percentage`,
        // TO DO: distinguish total and unit price from Discount and Material QuoteLineItem before displaying log
        // unitPrice: 'Quote Line Item Unit Price',
        costCodeId: `${materialLineItemFieldName} Cost Code ID`,
        jobCostTypeId: `${materialLineItemFieldName} Job Cost Type ID`,
        revenueTypeId: `${materialLineItemFieldName} Revenue Type ID`,
        taxable: `${materialLineItemFieldName} Taxable`,
        lineItemType: `${materialLineItemFieldName} Discount`
      },
      hideChanges: []
    },
    QuoteLineLabor: {
      executionTypes: [],
      fields: {
        isTaxable: 'Task Labor Line Item Taxable',
        labourTypeId: 'Task Labor Line Item Labor Type ID',
        description: 'Task Labor Line Item Description',
        unitCost: 'Task Labor Line Item Unit Cost',
        markup: 'Task Labor Line Item Markup',
        unitPrice: 'Task Labor Line Item Unit Price',
        quantity: 'Task Labor Line Item Quantity',
        subtotal: 'Task Labor Line Item Subtotal',
        totalCost: 'Task Labor Line Item Total Cost',
        totalPrice: 'Task Labor Line Item Total Price'
      },
      hideChanges: []
    },
    Attachment: {
      executionTypes: [],
      fields: {
        customFileName: 'Attachment Filename',
        comment: 'Attachment Description',
        fileUrl: 'Attachment File Upload'
      },
      hideChanges: ['fileUrl']
    }
  };
};

export default entityMapAttributes;
