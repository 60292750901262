import gql from 'graphql-tag';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import { nonVisitDetailsFragment } from '@dispatch/fragments/nonVisitDetailsFragment';
import { transformNonVisitEventData } from '@dispatch/Dispatch.utils';

const GET_NON_VISIT_EVENT = gql`
  query getNonVisitEventById($id: String!) {
    getNonVisitEventById(id: $id) {
      ${nonVisitDetailsFragment}
    }
  }
`;

const useNonVisitEventDetails = eventId => {
  return useExtendedQuery(GET_NON_VISIT_EVENT, {
    transform: transformNonVisitEventData,
    variables: {
      id: eventId
    }
  });
};

export default useNonVisitEventDetails;
