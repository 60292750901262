import gql from 'graphql-tag';

const getJobDetailsInfo = gql`
  query getJobDetails($partitionKey: String!, $sortKey: String!) {
    getJob(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      version
      status
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      tenantId
      hierarchy
      entityType
      jobNumber
      customIdentifier
      customerProvidedPONumber
      customerProvidedWONumber
      jobTypeId
      jobTypeName
      jobTypeInternal
      priority
      customerId
      customerName
      billingCustomerId
      billingCustomerName
      billingCustomer(entityConnection: "BillingCustomer") {
        id
        sortKey
        customerName
        companyAddresses(entityConnection: "CompanyAddress") {
          items {
            id
            addressType
            addressLine1
            addressLine2
            billTo
            city
            state
            zipcode
            longitude
            latitude
          }
        }
      }
      customerSortKey
      customerRepName
      customerRep(entityConnection: "CustomerRep") {
        id
        name
      }
      customerPropertyId
      customerPropertyName
      customerPropertyTypeName
      customerPropertySortKey
      amountNotToExceed
      amountQuoted
      costAmount
      quoteId
      quote(entityConnection: "Quote") {
        id
        quoteNumber
        customIdentifier
      }
      issueDescription
      bestContact
      parentId
      parentSortKey
      createdDateTime
      createdBy
      accountingRefId
      syncStatus
      owner(entityConnection: "owner") {
        id
        name
        firstName
        lastName
      }
      ownerId
      departments(entityConnection: "JobDepartment") {
        items {
          id
          mappedEntityId
          mappedEntityType
          mappedEntityValue
          systemEntityId
          systemEntityType
          hierarchy
          sortKey
          invertedSortKey
          partitionKey
          mappedEntity {
            ... on Department {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              tagName
            }
          }
        }
      }
      notes(entityConnection: "Note") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          subject
          note
          createdBy
          lastUpdatedDate
          lastUpdatedDateTime
          lastUpdatedBy
          version
        }
      }
      jobJobTags(entityConnection: "JobJobTag", limit: 50) {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          jobTag(entityConnection: "JobTag") {
            tagType
            tagName
            sortOrder
            id
            tenantId
            tenantCompanyId
            partitionKey
            sortKey
            hierarchy
            entityType
          }
        }
      }
      authorizedBy(entityConnection: "authorizedBy") {
        ... on CustomerRep {
          id
          firstName
          lastName
          version
          isActive
        }
      }
      authorizedById
    }
  }
`;

export default getJobDetailsInfo;
