export default class InstructionHelper {
  constructor() {
    this.instructionSet = [];
  }

  oneToMany(parentIndex, childIndex, childConnection = '') {
    const instruction = {
      parent: parentIndex,
      child: childIndex,
      childConnection: childConnection,
      actionType: '1toM'
    };
    this.instructionSet.push(instruction);
    return this;
  }

  manyToMany(
    parentIndex,
    childIndex,
    parentDisplayAttribute = '',
    childDisplayAttribute = '',
    parentConnection = '',
    childConnection = ''
  ) {
    const instruction = {
      parent: parentIndex,
      child: childIndex,
      parentConnection: parentConnection,
      childConnection: childConnection,
      actionType: 'MtoM',
      displayAttributeParent: parentDisplayAttribute === '' ? 'id' : parentDisplayAttribute,
      displayAttributeChild: childDisplayAttribute === '' ? 'id' : childDisplayAttribute
    };
    this.instructionSet.push(instruction);
    return this;
  }

  flat(itemIndex) {
    const instruction = {
      parent: itemIndex,
      actionType: 'none'
    };
    this.instructionSet.push(instruction);
    return this;
  }
}
