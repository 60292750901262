/* eslint-disable no-useless-concat */
/* eslint-disable no-template-curly-in-string */
const defaultRepresentativeForm = () => {
  return {
    fields: {
      Id: {
        default: '${id}'
      },
      Version: {
        default: '${version}'
      },
      FirstName: {
        default: '${firstName}',
        edit: 'firstName'
      },
      LastName: {
        default: '${lastName}',
        edit: 'lastName'
      },
      Role: {
        default: '${role}',
        edit: 'role'
      },
      Mail: {
        default: '${mail}',
        edit: 'mail'
      },
      Mobile: {
        default: '${mobile}',
        edit: 'mobile'
      },
      Landline: {
        default: '${landline}',
        edit: 'landline'
      },
      MailPreferred: {
        default: '${mailPreferred}',
        edit: 'mailPreferred'
      },
      MobilePreferred: {
        default: '${mobilePreferred}',
        edit: 'mobilePreferred'
      },
      LandlinePreferred: {
        default: '${landlinePreferred}',
        edit: 'landlinePreferred'
      },
      SmsNotication: {
        default: '${smsNotication}',
        edit: 'smsNotication'
      },
      Note: {
        default: '${note}',
        edit: 'note'
      }
    },
    layouts: {
      // forms container
      default: {
        options: {
          direction: 'column',
          grow: 0,
          size: '100%',
          padding: [0]
        },
        // boxing
        contents: [
          {
            options: {
              direction: 'column',
              grow: 0,
              size: '100%',
              padding: [0, 0]
            },
            // container
            contents: [
              // name fields
              {
                options: {
                  direction: 'row',
                  shrink: 0,
                  size: '100%',
                  padding: [0, 0, 18, 0]
                },
                contents: [
                  {
                    options: {
                      direction: 'column',
                      padding: [0, 5, 0, 0]
                    },
                    contents: [
                      {
                        options: {
                          direction: 'row'
                        },
                        contents: [
                          {
                            component: {
                              default: 'FieldLabel'
                            },
                            source: 'FIRST NAME'
                          },
                          {
                            options: {
                              direction: 'row',
                              size: 'auto',
                              justify: 'flex-end'
                            },
                            contents: [
                              {
                                component: {
                                  default: 'FieldLabel'
                                },
                                source: 'Required'
                              }
                            ]
                          }
                        ]
                      },
                      {
                        component: {
                          default: 'TextInput'
                        },
                        source: 'firstName'
                      }
                    ]
                  },
                  {
                    options: {
                      direction: 'column',
                      padding: [0, 0, 0, 5]
                    },
                    contents: [
                      {
                        component: {
                          default: 'FieldLabel'
                        },
                        source: 'LAST NAME'
                      },
                      {
                        component: {
                          default: 'TextInput'
                        },
                        source: 'lastName'
                      }
                    ]
                  }
                ]
              },
              // role fields
              {
                options: {
                  direction: 'Column',
                  shrink: 0,
                  size: '100%',
                  padding: [0, 0, 18, 0]
                },
                contents: [
                  {
                    component: {
                      default: 'FieldLabel'
                    },
                    source: 'ROLE'
                  },
                  {
                    component: {
                      default: 'TextInput'
                    },
                    source: 'role'
                  }
                ]
              },
              // mail address fields
              {
                options: {
                  direction: 'column',
                  shrink: 0,
                  grow: 0,
                  size: '100%',
                  padding: [0, 0, 5, 0]
                },
                contents: [
                  {
                    component: {
                      default: 'FieldLabel'
                    },
                    source: 'EMAIL ADDRESS'
                  },
                  {
                    component: {
                      default: 'TextInput'
                    },
                    source: 'mail'
                  }
                ]
              },
              // mail preferred fields
              {
                options: {
                  direction: 'row',
                  shrink: 0,
                  size: '100%',
                  padding: [0, 0, 0, 0]
                },
                contents: [
                  {
                    component: {
                      default: 'Checkbox'
                    },
                    source: 'mailPreferred'
                  },
                  {
                    component: {
                      default: 'FieldLabel'
                    },
                    source: 'Preferred'
                  }
                ]
              },
              // contact fields
              {
                options: {
                  direction: 'row',
                  shrink: 0,
                  size: '100%',
                  padding: [0, 0, 5, 0]
                },
                contents: [
                  {
                    options: {
                      direction: 'row',
                      size: '100%',
                      padding: [0, 0, 0, 0]
                    },
                    contents: [
                      // phone fields
                      {
                        options: {
                          direction: 'column',
                          padding: [0, 90, 0, 0]
                        },
                        contents: [
                          {
                            component: {
                              default: 'FieldLabel'
                            },
                            source: 'Phone - Mobile'
                          },
                          {
                            component: {
                              default: 'TextInput'
                            },
                            source: 'mobile'
                          }
                        ]
                      },
                      // landline fields
                      {
                        options: {
                          direction: 'column',
                          padding: [0, 85, 0, 5]
                        },
                        contents: [
                          {
                            component: {
                              default: 'FieldLabel'
                            },
                            source: 'Phone - Landline'
                          },
                          {
                            component: {
                              default: 'TextInput'
                            },
                            source: 'landline'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              // contact preferred fields
              {
                options: {
                  direction: 'row',
                  shrink: 0,
                  size: '100%',
                  padding: [0, 0, 0, 0]
                },
                contents: [
                  // mobile preferred fields
                  {
                    options: {
                      direction: 'row',
                      size: '100%',
                      padding: [0, 0, 0, 0]
                    },
                    contents: [
                      // mobile preferred fields
                      {
                        options: {
                          direction: 'row',
                          padding: [0, 90, 0, 0]
                        },
                        contents: [
                          {
                            component: {
                              default: 'Checkbox'
                            },
                            source: 'mobilePreferred'
                          },
                          {
                            component: {
                              default: 'FieldLabel'
                            },
                            source: 'Preferred'
                          }
                        ]
                      },
                      // landline preferred fields
                      {
                        options: {
                          direction: 'row',
                          padding: [0, 90, 0, 5]
                        },
                        contents: [
                          {
                            component: {
                              default: 'Checkbox'
                            },
                            source: 'landlinePreferred'
                          },
                          {
                            component: {
                              default: 'FieldLabel'
                            },
                            source: 'Preferred'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              // Sms notification
              {
                options: {
                  direction: 'row',
                  padding: [0, 0, 0, 0]
                },
                contents: [
                  {
                    component: {
                      default: 'Checkbox'
                    },
                    source: 'smsNotication'
                  },
                  {
                    component: {
                      default: 'FieldLabel'
                    },
                    source: 'SMS Notifications OK'
                  }
                ]
              },
              // Note
              {
                options: {
                  direction: 'column',
                  padding: [0, 0, 18, 0]
                },
                contents: [
                  {
                    component: {
                      default: 'FieldLabel'
                    },
                    source: 'Note'
                  },
                  {
                    component: {
                      default: 'TextArea'
                    },
                    source: 'note'
                  }
                ]
              }
            ]
          }
        ]
      }
    },
    validation: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      title: 'Representative',
      type: 'object',
      properties: {
        firstName: { type: 'string', required: true }
      }
    },
    validationErrors: {
      firstName: {
        required: 'firstName is required'
      }
    }
  };
};

export default defaultRepresentativeForm;
