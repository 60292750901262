import initialState from '../state';
import * as Actions from '../constants';

export const dispatch = (state = initialState.dispatch, action) => {
  switch (action.type) {
    case Actions.REFRESH_TABLE_DATA: {
      return {
        ...state,
        refreshTrigger: !state.refreshTrigger,
        refreshQuietly: false
      };
    }
    case Actions.QUIET_REFRESH_TABLE_DATA: {
      return {
        ...state,
        refreshTrigger: !state.refreshTrigger,
        refreshQuietly: true
      };
    }
    case Actions.DISABLE_QUIET_REFRESH: {
      return {
        ...state,
        refreshQuietly: false
      };
    }
    case Actions.SET_CREATE_MULTI_VISIT_STARTED: {
      return {
        ...state,
        multipleVisitsCreateInProcess: true
      };
    }
    case Actions.SET_CREATE_MULTI_VISIT_ENDED: {
      return {
        ...state,
        multipleVisitsCreateInProcess: false
      };
    }
    default: {
      return state;
    }
  }
};
