import gql from 'graphql-tag';

const getTimesheetPeriods = gql`
  query getTimesheetPeriods($partitionKey: String!, $sortKey: String!) {
    getEmployee(partitionKey: $partitionKey, sortKey: $sortKey) {
      timesheetPeriods(entityConnection: "TimesheetPeriod") {
        items {
          dateStartUTC
          dateEndUTC
          approved
          approvedDateUTC
          approvalStatus
          approvedByName
          isActive
          approvedById
          parentId
          parentSortKey
          id
          entityType
          version
          tenantCompanyId
          partitionKey
          sortKey
        }
      }
    }
  }
`;

export default getTimesheetPeriods;
