import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';
import { vendorSearch } from 'services/API/vendor';

const margin = '0px 0px 16px 0px';

const poFrameSideFormVendorFields = {
  vendor: { required: false },
  vendorAddress: { required: false },
  vendorLocation: { required: false },
  phoneNumber: { required: false },
  vendorEmail: { required: false }
};

const poFrameSideFormVendorContents = canEdit => {
  return [
    {
      contents: [
        {
          options: {
            margin: '0px 0px 10px 0px'
          },
          contents: [
            {
              component: {
                default: 'MuiFormSectionTitle',
                edit: 'MuiFormSectionTitle'
              },
              options: {
                label: 'Vendor Information'
              }
            }
          ]
        }
      ]
    },
    {
      contents: [
        {
          options: {
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow',
                edit: 'SearchBar'
              },
              source: {
                default: 'vendorName',
                edit: 'vendor'
              },
              options: {
                label: 'Vendor',
                placeholder: 'Select Vendor',
                resultFormatFunction: item => `${item.name}`,
                searchFunction: vendorSearch,
                searchColumns: ['name'],
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false,
                errorText: 'Field is required',
                disabled: !canEdit
              }
            }
          ]
        }
      ]
    },
    {
      contents: [
        {
          options: {
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelWrap',
                edit: 'CustomFieldWithLabelWrap'
              },
              source: 'vendorAddress',
              options: {
                label: 'Address'
              }
            }
          ]
        }
      ]
    },
    {
      contents: [
        {
          options: {
            margin
          },
          contents: [
            {
              component: {
                default: 'LocationView',
                edit: 'LocationView'
              },
              source: 'vendorLocation'
            }
          ]
        }
      ]
    },
    {
      contents: [
        {
          options: {
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow',
                edit: 'CustomFieldWithLabelNarrow'
              },
              source: 'phoneNumber',
              options: {
                label: 'Phone Number'
              }
            }
          ]
        }
      ]
    },
    {
      contents: [
        {
          options: {
            margin: '0px 0px 40px 0px'
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow',
                edit: 'CustomFieldWithLabelNarrow'
              },
              source: 'vendorEmail',
              options: {
                label: 'Email'
              }
            }
          ]
        }
      ]
    }
  ];
};

const poFrameSideFormVendorLayout = canEdit => {
  return {
    fields: generateDefaultFieldsObject(poFrameSideFormVendorFields),
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: poFrameSideFormVendorContents()
      },
      edit: {
        options: {
          pageSize: 'LETTER',
          width: 203,
          grow: 0
        },
        contents: poFrameSideFormVendorContents(canEdit)
      }
    }
  };
};

export { poFrameSideFormVendorFields, poFrameSideFormVendorLayout };
