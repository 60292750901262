import gql from 'graphql-tag';

const updateTask = gql`
  mutation updateTask($partitionKey: String!, $data: UpdateTaskInput!) {
    updateTask(partitionKey: $partitionKey, data: $data) {
      id
      version
      name
      description
      departmentId
      departmentValue
      taskTypeId
      taskTypeValue
      taskTypeInternal
      taskNumber
      status
      sortOrder
      jobTypeId
      jobTypeValue
      assetId
      assetValue
      nextDueDate
      productBundleId
      productBundleValue
      isActive
    }
  }
`;

export default updateTask;
