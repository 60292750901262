import { Cache } from 'aws-amplify';
import moment from 'moment';

const config = {
  keyPrefix: 'BO_',
  capacityInBytes: 5000000, // 5mb, max size
  itemMaxSize: 4900000 // 4mb needed at least for now for tenantsettings
};
const customCache = Cache.createInstance(config);

export default class CacheService {
  static getCachedItems(items) {
    const needUpdate = [];
    const cachedElements = [];
    const localItems = items || [];

    localItems.forEach(el => {
      const cachedElement = customCache.getItem(el.sortKey);
      if (!cachedElement) needUpdate.push(el.sortKey);
      else {
        try {
          const cachedObject = JSON.parse(cachedElement);
          if (cachedObject.version < el.version) {
            needUpdate.push(el.sortKey);
          } else {
            cachedElements.push(cachedObject);
          }
        } catch {
          needUpdate.push(el.sortKey);
        }
      }
    });
    return {
      needUpdate,
      cachedElements
    };
  }

  static clearCache() {
    return customCache.clear();
  }

  static cacheItem(item) {
    const newItem = this.removeKeys(item, ['__typename', 'hierarchy', 'invertedSortKey']);

    const cachedValue = JSON.stringify(newItem);
    const timestamp = moment()
      .add(12, 'hour')
      .valueOf();
    return customCache.setItem(item.sortKey, cachedValue, { expires: timestamp });
  }

  static getCachedItem(key) {
    const cachedElement = customCache.getItem(key);
    try {
      return JSON.parse(cachedElement);
    } catch {
      return null;
    }
  }

  static removeKeys = (obj, keys) => obj !== Object(obj)
    ? obj
    : Array.isArray(obj)
    ? obj.map((item) => this.removeKeys(item, keys))
    : Object.keys(obj)
        .filter((k) => !keys.includes(k))
        .reduce(
          (acc, x) => Object.assign(acc, { [x]: this.removeKeys(obj[x], keys) }),
          {}
        )

  static async fetchOrCache(idsQuery, keysQuery, params, responseKeys = null) {
    const queryParams = params;
    if (responseKeys) {
      const splitItems = await idsQuery(params);
      let allTags = [];
      const splitKeys = {};
      responseKeys.forEach(el => {
        allTags = allTags.concat(splitItems[el]);
        splitKeys[el] = splitItems[el].map(itm => itm.sortKey);
      });
      const cachedItems = CacheService.getCachedItems(allTags);
      let updatedItems = [];
      if (cachedItems.needUpdate.length > 0) {
        queryParams.keys = cachedItems.needUpdate;
        const splitNewItems = await keysQuery(queryParams);
        responseKeys.forEach(el => {
          updatedItems = updatedItems.concat(splitNewItems[el]);
        });
      }
      updatedItems.forEach(el => CacheService.cacheItem(el));
      const allUnsplitItems = cachedItems.cachedElements.concat(updatedItems);
      const items = {};
      responseKeys.forEach(el => {
        items[el] = [];
      });
      allUnsplitItems.forEach(el => {
        responseKeys.forEach(key => {
          if (splitKeys[key].includes(el.sortKey)) items[key].push(el);
        });
      });
      return items;
    }
    const tags = await idsQuery(params);
    const cachedItems = CacheService.getCachedItems(tags);
    let updatedItems = [];
    if (cachedItems.needUpdate.length > 0) {
      queryParams.keys = cachedItems.needUpdate;
      updatedItems = await keysQuery(queryParams);
    }
    updatedItems.forEach(el => CacheService.cacheItem(el));
    const items = cachedItems.cachedElements.concat(updatedItems);
    return items;
  }
}
