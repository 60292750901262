import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { getState } from 'redux/store';

import { nonVisitDetailsFragment } from '@dispatch/fragments';
import { updateWatchedNonVisitQueries } from '@dispatch/Dispatch.utils';

const COMPLETE_NON_VISIT_EVENT_MUTATION = gql`
  mutation nonVisitEventTransition($partitionKey: String, $data: NonVisitEventTransitionInput!) {
    nonVisitEventTransition(partitionKey: $partitionKey, data: $data) {
      ${nonVisitDetailsFragment}
    }
  }
`;

const serializer = ({ eventId }) => ({
  variables: {
    partitionKey: getState().user.tenantId,
    data: {
      id: eventId,
      action: 'COMPLETE'
    }
  }
});

const optimisticResponseFactory = ({ originalEvent }) => {
  const { eventName, from, to } = originalEvent;
  return {
    nonVisitEventTransition: {
      ...originalEvent,
      name: eventName,
      plannedEndTimeUTC: to,
      plannedStartTimeUTC: from
    }
  };
};

const CONFIRMATION_TEXT =
  'If any technicians working on this event ' +
  'are offline and have already started working on this event, ' +
  'any information that has not been uploaded from their mobile app ' +
  'may be lost. Are you sure you want to continue?';

const useCompleteNonVisitEvent = () => {
  return useExtendedMutation(COMPLETE_NON_VISIT_EVENT_MUTATION, {
    serializer,
    optimisticResponseFactory,
    showConfirmation: () => {
      return true;
    },
    confirmationModalOptions: {
      title: 'Complete Event',
      warn: true,
      modalText: CONFIRMATION_TEXT
    },
    update: (cache, { data }) => {
      const updatedNonVisit = data.nonVisitEventTransition;
      updateWatchedNonVisitQueries(cache, updatedNonVisit);
    }
  });
};

export default useCompleteNonVisitEvent;
