import { sortBy } from 'lodash';

export const updateTasksAndGroups = ({ groups, tasks, setTaskGroups }) => {
  const tasksWithGroups = sortBy(
    groups.map(group => ({
      ...group,
      tasks: sortBy(
        tasks.filter(task => task.quoteTaskGroupId === group.id),
        'sortOrder'
      )
    })),
    'sortOrder'
  );

  const tasksWithNoGroup = sortBy(
    tasks.filter(task => !task.quoteTaskGroupId),
    'sortOrder'
  );

  if (setTaskGroups) {
    setTaskGroups([...tasksWithGroups, { tasks: [...tasksWithNoGroup] }]);
  }
  return { tasksWithGroups, tasksWithNoGroup };
};
