import React from 'react';
import MapViewStatic from 'components/MapViewStatic';

const LocationView = ({ field }) => {
  const { latitude, longitude } = field.value || {};
  if (!latitude && !longitude) return null;

  return (
    <MapViewStatic coordinates={{ latitude, longitude }} dimensions={{ width: 202, height: 148 }} />
  );
};

export default LocationView;
