import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import { snackbarOn } from 'redux/actions/globalActions';

import { jsx } from '@emotion/react';
import { useTheme } from '@material-ui/core';
import { ThemeProvider, Typography, TV, TW } from '@buildhero/sergeant';

import useLabourTypes from 'customHooks/useLabourTypes';
import WrapTable, { BoldCell } from 'components/WrapTable';
import { convertToCurrencyString } from 'utils';
import { SectionHeader, VisitSubjectField } from '../Components';
import { timeSubmittedDisplay, tableCurrencyFormatter } from '../utils';

const getColumns = isSingleVisit => {
  const columns = [
    { field: 'technician', headerName: 'Technician' },
    { field: 'timeSubmitted', headerName: 'Time Submitted', width: 125 },
    { field: 'payrollHourType', headerName: 'Payroll Hour Type', width: 140 },
    {
      field: 'subtotal',
      headerName: 'Subtotal',
      width: 100,
      valueFormatter: tableCurrencyFormatter,
      renderCell: BoldCell,
      totalGetter: ({ rows }) => rows.reduce((acc, r) => acc + r.subtotal, 0),
      renderTotal: props => (
        <Typography
          css={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}
          variant={TV.BASE}
          weight={TW.BOLD}
          numeric
        >
          {convertToCurrencyString(props.formattedValue ?? 0)}
        </Typography>
      )
    }
  ];

  if (!isSingleVisit) {
    return [
      {
        field: 'subject',
        headerName: 'Source',
        width: 230,
        renderCell: ({ row }) => VisitSubjectField({ ...row.subject })
      },
      ...columns
    ];
  }

  return columns;
};

const getTimesheetData = (visit, timesheet, labourTypes, tech) => {
  const duration = Number.isInteger(timesheet.actualTotalDurationOverride)
    ? timesheet.actualTotalDurationOverride
    : timesheet.actualTotalDuration;
  const durationHours = duration / 3600;
  const durationDisplayText = durationHours > 0 ? `${durationHours} hr` : 'N/A';
  const labourType = labourTypes.find(({ id }) => id === tech?.labourTypeId);

  return {
    technician: `${tech?.name} (${labourType?.name})`,
    timeSubmitted: timeSubmittedDisplay(
      timesheet,
      durationDisplayText,
      duration,
      visit.scheduledFor
    ),
    payrollHourType: timesheet.hourType?.hourType || '-',
    payrollHourTypeSortOrder: timesheet.hourType?.sortOrder,
    subtotal: timesheet.cost * durationHours,
    visitNumber: visit.visitNumber
  };
};

const mapVisitsToRows = (visits, labourTypes) => {
  const rows = [];
  visits.forEach(visit => {
    const timesheetEntries = visit.timesheetEntriesView?.items || [];
    const allTechs = [...visit.primaryTechs?.items, ...visit.extraTechs?.items].map(
      t => t.mappedEntity
    );
    timesheetEntries
      .filter(t => t.actualTotalDurationOverride || t.actualTotalDuration)
      .forEach(timesheet => {
        const tech = allTechs.find(at => at.id === timesheet.timesheetPeriod.parentId);

        rows.push({
          subject: {
            ...visit
          },
          ...getTimesheetData(visit, timesheet, labourTypes, tech)
        });
      });
  });

  const billableEventRows = [];
  visits.forEach(visit => {
    const nonVisitEvents = visit.nonVisitEvents?.items || [];
    nonVisitEvents.forEach(event => {
      const timesheets = event.timesheetEntries?.items || [];
      timesheets
        .filter(t => t.actualTotalDurationOverride || t.actualTotalDuration)
        .forEach(timesheet => {
          const timesheetData = getTimesheetData(visit, timesheet, labourTypes, event.employee);
          billableEventRows.push({
            subject: {
              ...visit,
              isBillableEvent: true
            },
            ...timesheetData
          });
        });
    });
  });
  return [
    ...orderBy(rows, ['visitNumber', 'technician', 'payrollHourTypeSortOrder']),
    ...orderBy(billableEventRows, ['visitNumber', 'technician', 'payrollHourTypeSortOrder'])
  ];
};

const VisitLaborTable = ({ visits, isMultiVisits, isLoading }) => {
  const theme = useTheme();
  const user = useSelector(state => state?.user);
  const { tenantId, tenantCompanyId } = user;
  const serviceArgs = [tenantId, tenantCompanyId, snackbarOn];
  const [labourTypes, , labourTypesLoaded] = useLabourTypes(...serviceArgs);

  const rows = useMemo(() => (labourTypesLoaded ? mapVisitsToRows(visits, labourTypes) : []), [
    visits,
    labourTypes,
    labourTypesLoaded
  ]);
  const columns = useMemo(
    () =>
      getColumns(
        !isMultiVisits &&
          visits
            .map(visit =>
              visit.nonVisitEvents?.items?.map(event => event.timesheetEntries?.items || [])
            )
            .flat().length < 0
      ),
    [isMultiVisits, visits]
  );

  return (
    <ThemeProvider>
      <SectionHeader title="Labor" />
      <WrapTable
        columns={columns}
        rows={rows}
        noDataMessage="No labor data"
        hideFooter={rows.length <= 10}
        enableTotalsRow
        loading={isLoading}
        loadingRows={3}
      />
    </ThemeProvider>
  );
};

VisitLaborTable.defaultProps = {
  visits: [],
  isLoading: false
};

export default VisitLaborTable;
