import { useCallback } from 'react';
import gql from 'graphql-tag';

import { useMutation } from '@apollo/client';

const UPDATE_SERVICE_AGREEMENT = gql`
  mutation updateServiceAgreement($data: ServiceAgreementUpdateInput!) {
    updateServiceAgreement(input: $data) {
      id
      renewalDate
      renewalNote
      version
    }
  }
`;

/**
 * Wrapper around useMutation for updating service agreement
 * @param id ID of the service agreement to update
 * @param options Optional additional options for useMutation
 */
export const useServiceAgreementUpdate = (id, options = undefined) => {
  const [updateServiceAgreement, { loading, error }] = useMutation(UPDATE_SERVICE_AGREEMENT, {
    ...options
  });

  const update = useCallback(
    async data => {
      return updateServiceAgreement({ variables: { data: { id, ...data } } });
    },
    [updateServiceAgreement, id]
  );

  return [update, loading, error]; // passing the error back is mostly useless since on update failure the error is thrown
};

export const getRenewalHistory = gql`
  query getRenewalHistory($id: String) {
    serviceAgreement(id: $id) {
      id
      isRenewed
      renewalGroup {
        id
        version
        serviceAgreement {
          id
          agreementNumber
          renewalNote
          status
        }
        renewalDate
      }
    }
  }
`;
