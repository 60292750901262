import React, { memo } from 'react';
import { areEqual as propsAreEqual } from 'react-window';

import compose from 'utils/compose';

import { useVisitTransition } from '@dispatch/mutations';
import { withDispatchStore, withQueryStringStore } from '@dispatch/Dispatch.store';

import useUpdateBillableNonVisit from '@dispatch/mutations/useUpdateBillableNonVisit';
import useUpdateManDay from '@dispatch/mutations/useUpdateManDay';
import SwimLane from './SwimLane.component';

const Memoized = memo(SwimLane, propsAreEqual);

const mapQueryStringToProps = store => ({
  day: store.state.day
});

const mapStoreToProps = (store, { data, index }) => {
  const { tech } = data[index];

  return {
    eventData: store.state.eventData,
    clearVisitRowHover: store.clearVisitRowHover,
    isHighlightedTech: store.state.highlightedTechs?.includes(tech?.id)
  };
};

const DataProvider = props => {
  const visitTransitionTuple = useVisitTransition();
  const updateNonVisitTuple = useUpdateBillableNonVisit();
  const updateManDayTuple = useUpdateManDay();
  // index 0 is virtualized list sticky header offset
  if (props.index === 0) return null;

  return (
    <Memoized
      {...props}
      visitTransitionTuple={visitTransitionTuple}
      updateNonVisitTuple={updateNonVisitTuple}
      updateManDayTuple={updateManDayTuple}
    />
  );
};

export default compose(
  withQueryStringStore(mapQueryStringToProps, { propsAreEqual }),
  withDispatchStore(mapStoreToProps, { propsAreEqual })
)(DataProvider);
