import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  itemsTypeChipsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },

  selectedItemsTypeChip: {
    background: 'transparent',
    marginRight: theme.spacing(0.5),
    fontSize: 12,
    border: `1px solid ${theme.palette.support.green.dark}`,
    color: theme.palette.support.green.dark
  },

  itemsTypeChip: {
    background: 'transparent',
    marginRight: theme.spacing(0.5),
    fontSize: 12,
    border: `1px solid ${theme.palette.text.primary}`,
    pointer: 'cursor'
  }
}));
