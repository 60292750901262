import gql from 'graphql-tag';

const deleteTag = gql`
  mutation deleteTag($input: DeleteTagInput!) {
    deleteTag(input: $input) {
      id
    }
  }
`;

export default deleteTag;
