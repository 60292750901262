import React from 'react';
import { Grid } from '@material-ui/core';
import { jsx } from '@emotion/react';
import { Auth } from 'aws-amplify';
import { useTheme } from '@material-ui/core/styles';
import { TV, TW, Typography, Button, ThemeProvider } from '@buildhero/sergeant';

export const NoAccessPage = ({ history }) => {
  const theme = useTheme();
  const classes = {
    banner: {
      height: 164,
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      top: 0,
      left: 0
    },
    content: {
      position: 'absolute',
      top: 240,
      left: 0,
      padding: theme.spacing(1),
      textAlign: 'center'
    }
  };

  return (
    <ThemeProvider>
      <Grid
        container
        spacing={0}
        direction="column"
        justify="center"
        alignItems="center"
        alignContent="center"
        css={classes.banner}
      >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={12}>
            <img
              src="/static/images/Logo_Lockup_White.svg"
              alt="BuildOps"
              style={{ maxWidth: 250 }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column" justify="center" alignItems="center" css={classes.content}>
        <Typography variant={TV.BASE} weight={TW.REGULAR}>
          Your profile cannot be accessed on Web. Please use the BuildOps mobile app or contact your
          office admin.
        </Typography>
        <Button
          css={{ marginTop: 8 }}
          onClick={async () => {
            await Auth.signOut();
            return history.push('/');
          }}
        >
          Sign Out
        </Button>
      </Grid>
    </ThemeProvider>
  );
};
