import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Reports(props) {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SvgIcon {...props}>
      <path d="M14.1676 1V2.59852H13.0169V3.81773C14.6493 3.9803 16.1211 4.57635 17.4056 5.47044L18.1282 4.60345C18.1817 4.52217 18.3155 4.52217 18.3958 4.57635L19.3324 5.36207C19.4127 5.41626 19.4127 5.55172 19.3592 5.63301L18.6366 6.52709C18.6634 6.52709 18.6901 6.55419 18.7169 6.58128C20.4296 8.31527 21.5 10.7266 21.5 13.3818C21.5 16.0369 20.4296 18.4483 18.7169 20.1823C17.0042 21.9163 14.6225 23 12 23C6.75493 23 2.5 18.6921 2.5 13.3818C2.5 10.7266 3.57042 8.31527 5.2831 6.58128C6.78169 5.06404 8.76197 4.06158 10.9831 3.81773V2.59852H9.83239V1H14.1676ZM13.0167 6.02255C12.912 6.00766 12.7929 6.09672 12.7785 6.20069L11.7682 13.4782C11.7442 13.6515 11.9391 13.7852 12.0932 13.7011L18.728 10.5105C18.8423 10.4561 18.8964 10.3224 18.8013 10.2382C18.6052 9.85691 18.3741 9.47064 18.1033 9.11406C16.8999 7.52925 15.1009 6.38979 13.0167 6.02255Z" />
    </SvgIcon>
  );
}
