import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';

import { WIP_REPORT_FRAGMENT } from '../fragments/wipReportFragment';

export const GET_REST_WIP_REPORT_LIST = gql`
  ${WIP_REPORT_FRAGMENT}
  query getWIPReportList(
    $tenantId: String
    $filter: TableFilterInput
    $pagination: PaginationInput
    $sorting: [TableSortingInput]
  ) {
    data: getWIPReportList(input: { filter: $filter, pagination: $pagination, sorting: $sorting })
      @rest(
        type: "GetWIPReportListResponse"
        path: "wipreports/list"
        endpoint: "pmapi"
        method: "post"
      ) {
      items @type(name: "WIPReport") {
        ...WIPReportFragment
      }
      rowCount
    }
  }
`;

export const useGetWIPReportList = (options = {}) => {
  return useExtendedQuery(GET_REST_WIP_REPORT_LIST, {
    transform: data => data?.data?.items,
    defaultData: [],
    ...options
  });
};
