import moment from 'moment';
import { DateTime } from 'luxon';
import { isEqual } from 'lodash';
import { VisitStatus, AppConstants } from 'utils/AppConstants';

export const canReschedule = visit =>
  [VisitStatus.SCHEDULED, VisitStatus.UNASSIGNED].includes(visit.status);

export const isEditsRestricted = visit =>
  [VisitStatus.TRAVELING, VisitStatus.WORKING, VisitStatus.PAUSED].includes(visit.status);

export const calculateScheduledUnixTime = (tentativeDate, tentativeTime) => {
  if (!tentativeDate || !tentativeTime) return null;

  const timeSplit = tentativeTime ? tentativeTime.split(' ') : [];
  if (timeSplit.length !== 2) return null;

  return moment(
    `${moment(tentativeDate).format(AppConstants.DATE_FORMAT)} ${tentativeTime}`
  ).unix();
};

const getTechIds = (techArray = []) => techArray.map(tech => `${tech.id}`);

export const getVisitActions = (visit, newVisit) => {
  const actions = {
    SCHEDULE: 'SCHEDULE',
    UPDATE_VISIT: 'UPDATE_VISIT',
    RESCHEDULE: 'RESCHEDULE'
  };

  const restrictedEdits = isEditsRestricted(visit);

  let actionName = actions.UPDATE_VISIT;

  // when the visits unassigned status, always schedule
  if (VisitStatus.UNASSIGNED === visit.status) return actions.SCHEDULE;

  // change in extra tech number should reschedule
  if (visit.extraTechsNumber !== newVisit.extraTechsNumber) return actions.RESCHEDULE;

  // when any change in primary techs and visits is assigned or any other status, reschedule
  if (!isEqual(getTechIds(visit.primaryTechs), getTechIds(newVisit.primaryTechs))) {
    actionName = actions.RESCHEDULE;
  }

  // when any change in extra techs and visits is assigned or any other status, reschedule
  if (
    !isEqual(getTechIds(visit.extraTechs), getTechIds(newVisit.extraTechs)) &&
    actionName !== actions.RESCHEDULE &&
    !restrictedEdits
  ) {
    actionName = actions.RESCHEDULE;
  }

  // when any change in scheduled for and visits is assigned or any other status, reschedule
  if (visit.scheduledFor !== newVisit.scheduledFor && actionName !== actions.RESCHEDULE) {
    actionName = actions.RESCHEDULE;
  }

  return actionName;
};

export const getPossibleVisitActions = visit => {
  const visitActionForStatus = {
    [VisitStatus.UNASSIGNED]: ['SCHEDULE', 'CANCEL'],
    [VisitStatus.SCHEDULED]: ['RESCHEDULE', 'CANCEL', 'COMPLETE', 'PUT_ON_HOLD'],
    [VisitStatus.TRAVELING]: ['CANCEL', 'COMPLETE', 'PUT_ON_HOLD'],
    [VisitStatus.WORKING]: ['COMPLETE', 'PUT_ON_HOLD', 'CANCEL'],
    [VisitStatus.ONHOLD]: ['COMPLETE', 'CONTINUE_IN_NEW_VISIT'],
    [VisitStatus.PAUSED]: ['CANCEL', 'COMPLETE', 'PUT_ON_HOLD'],
    [VisitStatus.CANCELED]: ['REOPEN']
  };
  return visitActionForStatus[visit.status] || [];
};

export const CLOSE_ACTIONS = ['RESCHEDULE', 'CANCEL', 'COMPLETE', 'PUT_ON_HOLD'];

// Assumes that the user is in the company timezone
export const isFutureDate = unixSecondsTimestamp => {
  if (!unixSecondsTimestamp) return true;
  const currentDate = DateTime.local();
  return DateTime.fromSeconds(unixSecondsTimestamp) > currentDate.endOf('day');
};

export const allowedInputsInEditVisit = ({
  id,
  sortKey,
  description,
  visitNumber,
  status,
  prerequisites,
  prerequisitesAcknowledged,
  departmentName,
  departmentId,
  accountingRefIdOfClass,
  extraTechsRequired,
  extraTechsNumber,
  minimumDuration,
  actualDuration,
  scheduledFor,
  tentativePeriod,
  tentativeDate,
  tentativeTime,
  detailsSent,
  onHoldReason,
  onHold,
  version
}) => ({
  id,
  sortKey,
  description,
  visitNumber,
  status,
  prerequisites,
  prerequisitesAcknowledged,
  departmentName,
  departmentId,
  accountingRefIdOfClass,
  extraTechsRequired,
  extraTechsNumber,
  minimumDuration,
  actualDuration,
  scheduledFor,
  tentativePeriod,
  tentativeDate,
  tentativeTime,
  detailsSent,
  onHoldReason,
  onHold,
  version
});

export default calculateScheduledUnixTime;
