const taskDescription = (
  settingsJSON,
  SettingConstants,
  descriptionWithLineBreaks,
  styles,
  fontFamilyStyle
) =>
  settingsJSON.Tasks[SettingConstants.SHOW_TASK_DESCRIPTION]
    ? `<tr>
            <td style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;" colspan="5" class="restricted">
              <span fontfamily=${fontFamilyStyle} contenteditable="false" class="text-small">${descriptionWithLineBreaks ||
        ''}</span>
            </td>
          </tr>`
    : '';

export default taskDescription;
