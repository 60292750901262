import { useState, useRef, useEffect, useReducer, useCallback } from 'react';
import { sentryMessage, sentryException } from 'services/Logger';
import { NetworkStatuses } from 'utils/constants';
import { isEmpty, omit } from 'lodash';

const getNetworkStatus = ({ valuesToResave, updateQueue, hasNetworkError, hasTempData }) => {
  if (valuesToResave) return NetworkStatuses.RETRYING;

  if (hasTempData) return NetworkStatuses.HAS_UNSAVED_DATA;

  if (updateQueue.length > 0) return NetworkStatuses.UPDATING;

  if (hasNetworkError) return NetworkStatuses.ERROR;

  return NetworkStatuses.READY;
};

/**
 * @param {function} update The service to wrap.
 * update function should take the changes to the entity as a {prop: value} map and
 * on success must return the new entity version
 * @param {integer} initialVersion - initialize the version. Get this from an external query service that initializes the data for the entity.
 * @param {string} entityName - Name of the entity used for error & Sentry message copy.
 * @param {function} formatChanges - Optional: Format changes before deciding to up the version and call the update.
 * The main use case is to prevent unneccessary updates.
 * @param {function} destructureUpdateResult - on success, destructure the result of the update service to get to the level that has "version" as a prop.
 * @param {function} versionIncrementedOnBackend - when version increment is handled on the Backend, the version in the mutation params should be 1 less than the version in the response.
 * when version increment is not handled on the backend (default), the version in the mutation params should be equal to the version in the response.
 * @return {function} autosave - the wrapped update service that should be used for any non-blocking (autosave) update call (i.e. onBlur, onChange, debounce, etc).
 * @return {enum} status:
 * from utils/constants - should block user interactions when status === NetworkStatuses.RETRYING.
 * Can reflect the status with a UI icon element such as <InvoiceCloudIcon />
 * @returns {integer} version - the most recent version of the resource.
 * @returns {boolean} confirmLeave -  Consider pairing with <ConfirmLeave when={confirmLeave} />
 */

const useAutosaveManager = ({
  update,
  initialVersion,
  snackbarOn,
  entityName,
  formatChanges,
  destructureUpdateResult,
  versionIncrementedOnBackend = false
}) => {
  const [hasNetworkError, setHasNetworkError] = useState(false);
  const [networkStatus, setNetworkStatus] = useState(NetworkStatuses.READY);
  const [hasTempData, setHasTempData] = useState(false);
  const [confirmLeave, setConfirmLeave] = useState(false);

  const [rerenderCount, forceRender] = useReducer(x => x + 1, 0);

  const updateQueueRef = useRef([]);
  const versionRef = useRef();
  const valuesToResaveRef = useRef();

  const calculateVersionForMutationParams = ({ nextVersion }) =>
    versionIncrementedOnBackend ? nextVersion - 1 : nextVersion;

  const updateWrapper = useCallback(
    async ({ changes: rawChanges, values }) => {
      // ignore the version changes since it's monitored by versionRef
      const changes = omit(formatChanges ? formatChanges(rawChanges) : rawChanges, ['version']);
      if (isEmpty(changes)) {
        setHasTempData(false);
        return null;
      }
      const onUpdateQueueChange = newValue => {
        updateQueueRef.current = newValue;
        forceRender();
      };

      const onValuesToResaveChange = newValue => {
        valuesToResaveRef.current = newValue;
        forceRender();
      };

      const resave = async ({ newValuesToResave }) => {
        if (updateQueueRef.current.length === 0) {
          // the update queue has been resolved, do the resave to restore data state
          const resaveVersion = newValuesToResave.version + 1;
          versionRef.current = resaveVersion;
          const newestParams = {
            ...newValuesToResave,
            version: calculateVersionForMutationParams({ nextVersion: resaveVersion })
          };
          try {
            await update(newestParams);
            setHasNetworkError(false);
          } catch (e) {
            setHasNetworkError(true);
            const errorMsg = `updating ${entityName} failed`;
            // resaving failed, giving up :(
            sentryMessage(errorMsg, {
              newestParams
            });
            sentryException(e, { newestParams });

            snackbarOn(
              'error',
              e?.graphQLErrors?.[0]?.message || errorMsg.concat('. Try again later')
            );
          }
          onValuesToResaveChange(null);
        }
      };

      const handleUpdateComplete = async ({ result, params, nextVersion, error = null }) => {
        const newEntity = destructureUpdateResult(result);

        if (valuesToResaveRef.current) {
          if (error) {
            sentryException(error, {
              updateQueue: updateQueueRef.current,
              params,
              values
            });
          }
          // this code remains the same whether or not there is an error. Need to resave anyways

          // replace the values that need resaving with the ones that have the highest version (reflect what user sees on screen)
          const newValuesToResave =
            valuesToResaveRef.current.version < calculateVersionForMutationParams({ nextVersion })
              ? {
                  ...valuesToResaveRef.current,
                  ...params,
                  version: calculateVersionForMutationParams({ nextVersion })
                }
              : { ...params, ...valuesToResaveRef.current };

          onValuesToResaveChange(newValuesToResave);

          onUpdateQueueChange(
            updateQueueRef.current.filter(
              entry => entry.version !== calculateVersionForMutationParams({ nextVersion })
            )
          );
          resave({ newValuesToResave });
        } else if (error) {
          sentryException(error, {
            updateQueue: updateQueueRef.current,
            params,
            values
          });

          onUpdateQueueChange(
            updateQueueRef.current.filter(
              entry => entry.version !== calculateVersionForMutationParams({ nextVersion })
            )
          );
          const newValuesToResave = {
            ...params,
            version: calculateVersionForMutationParams({ nextVersion })
          };
          onValuesToResaveChange(newValuesToResave);

          resave({ newValuesToResave });
        } else if (!updateQueueRef?.current?.[0] || !newEntity?.version) {
          // this case should not be possible
          const errorMsg = `corrupt ${entityName} update queue`;
          sentryMessage(errorMsg, {
            updateQueueRef,
            newEntity,
            params,
            values
          });
          snackbarOn('error', errorMsg.concat('. contact BuildOps support'));
        } else if (
          updateQueueRef?.current?.[0]?.version ===
          calculateVersionForMutationParams({ nextVersion: newEntity?.version })
        ) {
          // normal flow, entry that has been in queue the longest resolved so shift it out of the queue
          const [_, ...remainingQueue] = updateQueueRef.current;
          onUpdateQueueChange(remainingQueue);
        } else if (
          updateQueueRef?.current?.[0]?.version !==
          calculateVersionForMutationParams({ nextVersion: newEntity?.version })
        ) {
          // abnormal, likely due to slow internet, an entry resolved faster than the entry that has been in queue the longest
          sentryMessage(`${entityName} update queue save order annomally`, {
            updateQueue: updateQueueRef.current,
            newEntity,
            params,
            values
          });
          onUpdateQueueChange(
            updateQueueRef.current.filter(
              entry =>
                entry.version !==
                calculateVersionForMutationParams({ nextVersion: newEntity?.version })
            )
          );
          const newValuesToResave = {
            ...params,
            version: calculateVersionForMutationParams({ nextVersion })
          };
          onValuesToResaveChange(newValuesToResave);
        }
      };

      const nextVersion = versionRef.current + 1;
      versionRef.current = nextVersion;
      const params = {
        ...changes,
        version: calculateVersionForMutationParams({ nextVersion })
      };
      onUpdateQueueChange([...updateQueueRef.current, params]);
      setHasTempData(false);
      let result;
      try {
        // uncomment "setTimeout" code to test edge case for handleUpdateComplete
        // const timeout = 18000 / updateQueueRef.current.length + 1;
        // console.log({ timeout });
        // await setTimeout(async () => {
        //   try {
        result = await update(params);
        // } catch (error) {
        //   handleUpdateComplete({ error, params, nextVersion }); // "setTimeout code"
        // } // "setTimeout code"
        handleUpdateComplete({ result, params, nextVersion });
        setHasNetworkError(false);
        // }, timeout); // "setTimeout code"
      } catch (error) {
        handleUpdateComplete({ error, params, nextVersion });
      }
      return result;
    },
    [update, initialVersion]
  );

  useEffect(() => {
    if (!versionRef.current) {
      versionRef.current = initialVersion;
    }
  }, [initialVersion]);

  useEffect(() => {
    const newStatus = getNetworkStatus({
      valuesToResave: valuesToResaveRef.current,
      updateQueue: updateQueueRef.current,
      hasNetworkError,
      hasTempData
    });
    setNetworkStatus(newStatus);

    if (newStatus === NetworkStatuses.RETRYING) {
      document.activeElement.blur();
    }

    setConfirmLeave(
      [
        NetworkStatuses.HAS_UNSAVED_DATA,
        NetworkStatuses.RETRYING,
        NetworkStatuses.UPDATING
      ].includes(newStatus)
    );
  }, [rerenderCount, hasNetworkError, hasTempData]); // rerenderCount gets updated on changes to valuesToResaveRef and updateQueueRef

  return {
    autosave: updateWrapper,
    status: networkStatus,
    version: versionRef.current,
    hasTempData,
    setHasTempData,
    confirmLeave
  };
};

export default useAutosaveManager;
