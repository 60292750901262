export const useHeaderStyles = ({ scrollY }) => ({
  stickyContainer: {
    position: scrollY ? 'sticky' : undefined,
    top: scrollY ? 0 : undefined,
    zIndex: 10
  },
  fullWidthContainer: {
    position: 'relative',
    minWidth: '100%'
  },
  header: {
    display: 'flex',
    flexDirection: 'row'
  },
  progressBar: {
    position: 'sticky',
    left: 0
  }
});
