const styles = theme => ({
  textField: {
    fontFamily: theme.typography.fontFamily,
    width: 233,
    marginRight: 25
  },
  checkboxLabel: {
    marginLeft: 0,
    marginBottom: theme.spacing(3.25)
  },
  radioboxLabel: {
    marginLeft: 0,
    marginBottom: theme.spacing(3.25),
    paddingLeft: 10
  },
  gridContainer: {
    paddingTop: '12px'
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.grayscale(25),
    fontSize: 13,
    letterSpacing: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal'
  }
});

export default styles;
