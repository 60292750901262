const bundleMeta = [
  {
    id: 'name',
    filterKey: 'Bundle.name',
    filterType: 'string',
    numeric: false,
    label: 'Name',
    showLink: 'view',
    linkPath: '/settings/productbundle',
    linkReference: 'id'
  },
  {
    id: 'description',
    filterKey: 'Bundle.description',
    filterType: 'string',
    numeric: false,
    label: 'Description'
  },
  {
    id: 'category',
    filterKey: 'Bundle.category',
    filterType: 'string',
    numeric: false,
    label: 'Category'
  },
  {
    id: 'subCategory',
    filterKey: 'Bundle.subCategory',
    filterType: 'string',
    numeric: false,
    label: 'Sub Category'
  },
  {
    id: 'templateTypeValue',
    filterKey: 'Bundle.type',
    filterType: 'string',
    numeric: false,
    label: 'Type'
  },
  {
    id: 'isActive',
    filterKey: 'Bundle.isActive',
    filterType: 'boolean',
    options: [
      { label: 'Active', value: true },
      { label: 'Deactivated', value: false }
    ],
    type: 'boolean',
    label: 'Active'
  }
];

export default bundleMeta;
