import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';

const GET_CREW_MEMBERS_FROM_LEADER = gql`
  query getMembersFromLeader($leaderId: String!, $date: Int!) {
    getMembersFromLeader(leaderId: $leaderId, date: $date) {
      crewMemberIds
    }
  }
`;

const transformCrewMembers = data => data?.getMembersFromLeader?.crewMemberIds ?? [];

export const useCrewMembersFromLeader = (leaderId = '', date, options = {}) => {
  return useExtendedQuery(GET_CREW_MEMBERS_FROM_LEADER, {
    variables: { leaderId, date },
    transform: transformCrewMembers,
    fetchPolicy: 'cache-and-network',
    defaultData: [],
    ...options
  });
};
