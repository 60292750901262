import React, { useState } from 'react';

import { Box } from '@material-ui/core';
import { bool, func } from 'prop-types';

import { Button, ButtonType, Modal, ThemeProvider } from '@buildhero/sergeant';

import { useStyles } from './CreateInvoiceModal.styles';

const CreateInvoiceModal = ({ renderBody, open, canCreateInvoice, onSubmit, onClose }) => {
  const styles = useStyles();
  const [submitting, setSubmitting] = useState(false);

  const onModalClose = () => {
    onClose();
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      const invoice = await onSubmit();
      if (invoice?.id) {
        onModalClose();
        window.open(`/invoice/view/${invoice.id}`, '_blank');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ThemeProvider>
      <Modal
        open={open}
        title="Create Invoice"
        onClose={onModalClose}
        actions={
          <Button
            type={ButtonType.PRIMARY}
            disabled={!canCreateInvoice}
            loading={submitting}
            onClick={handleSubmit}
          >
            Create Invoice
          </Button>
        }
        PaperProps={{ style: { overflowY: 'unset' } }}
      >
        <Box css={styles.modalBody}>{renderBody()}</Box>
      </Modal>
    </ThemeProvider>
  );
};

CreateInvoiceModal.propTypes = {
  open: bool.isRequired,
  canCreateInvoice: bool.isRequired,
  renderBody: func.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired
};

export default CreateInvoiceModal;
