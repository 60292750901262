import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function AdjustmentsIcon(props) {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SvgIcon {...props}>
      <path d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23V1Z" />
      <circle cx="12" cy="12" r="10" stroke="currentColor" fill="none" strokeWidth="2" />
      <path
        d="M6.59632 15.0746H7.81979V12.6095H10.2849V11.386H7.81979V8.92542H6.59632V11.386H4.13574V12.6095H6.59632V15.0746Z"
        fill="white"
      />
      <path d="M19.5291 11.411H13.793V12.589H19.5291V11.411Z" />
    </SvgIcon>
  );
}
