import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Timesheets } from 'assets';

const TimesheetsIcon = props => (
  <SvgIcon {...props}>
    <Timesheets />
  </SvgIcon>
);

export default TimesheetsIcon;
