import gql from 'graphql-tag';

const ApplyClosedPeriod = gql`
  mutation applyClosedPeriod($data: ApplyClosedPeriodInput!) {
    applyClosedPeriod(data: $data) {
      id
      isActive
      closeDate
      accountingRefId
      accountingApplication
    }
  }
`;

export default ApplyClosedPeriod;
