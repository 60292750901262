import * as R from 'ramda';
import { useMemo } from 'react';
import { LineItemBillingStatus } from 'utils/AppConstants';
import { getJobCloseoutType } from '../../utils';
import { JobCloseoutTypes } from '../../constants';
import {
  filterBillableVisits,
  filterNonBilledItems,
  filterNonBilledLabourItems
} from '../JobCloseoutHeader.helpers';

const useHasNonBilledItems = job => {
  return useMemo(() => {
    const jobCloseoutType = getJobCloseoutType(job);
    if (jobCloseoutType === JobCloseoutTypes.QUOTED) {
      return R.pipe(
        R.filter(quoteJob => quoteJob?.quote?.billingStatus !== LineItemBillingStatus.BILLED),
        R.length,
        Boolean
      )(job?.quoteJobs?.items || []);
    }

    const notBilledItems = R.pipe(
      filterBillableVisits,
      R.map(visit => {
        const inventoryItems = filterNonBilledItems(visit.inventoryParts?.items || []);
        const laborItems = filterNonBilledLabourItems(visit.labourRateLineItems?.items || []);
        return [...inventoryItems, ...laborItems];
      }),
      R.flatten
    )(job?.visits?.items || []);

    const purchasedItems = R.pipe(
      R.map(billLine => billLine?.purchaseOrderLine || billLine),
      filterNonBilledItems
    )(job?.billLines?.items || []);

    const inventoryItems = filterNonBilledItems(job?.inventoryParts?.items || []);

    return R.pipe(
      R.uniqBy(R.prop('id')),
      R.length,
      Boolean
    )([...notBilledItems, ...purchasedItems, ...inventoryItems]);
  }, [job]);
};

export default useHasNonBilledItems;
