import gql from 'graphql-tag';

export const GetCompanyServiceAgreementSettings = gql`
  query($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      companySettings(
        entityConnection: "CompanySetting"
        filter: { type: { eq: "SERVICE_AGREEMENT" }, isActive: { eq: true } }
      ) {
        items {
          id
          version
          settings
        }
      }
    }
  }
`;

export const CreateCompanyServiceAgreementSettings = gql`
  mutation($partitionKey: String!, $companyId: String!, $settings: String!) {
    addCompanySettingsToCompany(
      partitionKey: $partitionKey
      data: {
        companyId: $companyId
        companySettings: { type: SERVICE_AGREEMENT, settings: $settings, isActive: true }
      }
    ) {
      id
      version
      settings
    }
  }
`;

export const UpdateCompanyServiceAgreementSettings = gql`
  mutation($partitionKey: String!, $settings: String!, $id: String!, $version: Int!) {
    updateCompanySetting(
      partitionKey: $partitionKey
      data: {
        type: SERVICE_AGREEMENT
        settings: $settings
        isActive: true
        id: $id
        version: $version
      }
    ) {
      id
      version
      settings
    }
  }
`;

export default {};
