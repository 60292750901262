import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ImageCarousel,
  ThumbnailSize,
  ThemeProvider,
  Button,
  ButtonType,
  Typography,
  TW
} from '@buildhero/sergeant';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import { makeStyles } from '@material-ui/core/styles';
import { Mode } from 'utils/constants';
import AttachmentModal from '../AttachmentSection/AddAttachment';
import { sentryException } from '../../services/Logger/index';

const useStyles = makeStyles({
  addImageButton: {
    marginTop: '10px'
  },
  muiIcon: {
    color: 'white'
  },
  fileName: {
    weight: TW.MEDIUM,
    color: 'white'
  },
  navContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  leftNavContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '60px',
    alignItems: 'center'
  },
  rightNavContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '60px'
  },
  paperProps: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden'
  }
});

const deleteConfirmContent = {
  body: 'Are you sure you want to delete this attachment?',
  title: 'Delete Attachment',
  buttonLabel: 'Delete Attachment',
  buttonType: ButtonType.ERROR
};

const EntityImagesCarousel = ({
  images,
  thumbnailSize,
  onSave,
  onDelete,
  parentId,
  disableEdit,
  onEdit
}) => {
  const classes = useStyles();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState({ isOpen: false, mode: '' });
  const [selectedImage, setSelectedImage] = useState(0);
  const confirmContext = useConfirmModal();

  useEffect(() => {
    if (!images.length) {
      setOpenImageModal(false);
    }
  }, [images]);

  const getFileName = () => {
    if (images[selectedImage]?.customFileName) {
      const fileNameSplit = images[selectedImage]?.fileName?.split('.');
      const fileType = fileNameSplit[fileNameSplit.length - 1];
      const customFileName = `${images[selectedImage]?.customFileName}.${fileType}`;
      return customFileName;
    }
    return images[selectedImage]?.fileName || '';
  };

  const download = () => {
    fetch(images[selectedImage]?.url, {
      method: 'GET',
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(buffer => {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', getFileName());
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        sentryException(err, 'Unable to get file for download');
      });
  };

  return (
    <>
      <ThemeProvider>
        <ImageCarousel
          images={images}
          thumbnailSize={thumbnailSize}
          handleClickMainImage={
            images.length
              ? index => {
                  setSelectedImage(index);
                  setOpenImageModal(true);
                }
              : null
          }
          onSelectionChanged={setSelectedImage}
        />
        {onSave && !disableEdit && (
          <div className={classes.addImageButton}>
            <Button
              type={ButtonType.LEADING}
              onClick={() => setOpenUploadModal({ isOpen: true, mode: Mode.NEW })}
              startIcon={<AddCircleOutlineIcon />}
              key="addButton"
            >
              Add Image
            </Button>
          </div>
        )}
        <Dialog
          open={openImageModal}
          onClose={() => setOpenImageModal(false)}
          PaperProps={{
            className: classes.paperProps
          }}
          maxWidth="md"
          scroll="body"
        >
          <Box className={classes.navContainer}>
            <Box className={classes.leftNavContainer}>
              <IconButton onClick={() => setOpenImageModal(false)}>
                <CloseIcon className={classes.muiIcon} />
              </IconButton>
              <Typography className={classes.fileName}>{getFileName()}</Typography>
            </Box>
            <Box className={classes.rightNavContainer}>
              <IconButton onClick={e => download(e)}>
                <GetAppIcon className={classes.muiIcon} />
              </IconButton>
              {!disableEdit && (
                <IconButton
                  onClick={async () => {
                    setOpenUploadModal({ isOpen: true, mode: Mode.EDIT });
                  }}
                >
                  <EditIcon className={classes.muiIcon} />
                </IconButton>
              )}
              {!disableEdit && (
                <IconButton
                  onClick={async () => {
                    if (await confirmContext.confirm(deleteConfirmContent)) {
                      setSelectedImage(0);
                      onDelete(images[selectedImage]?.id, selectedImage);
                    }
                  }}
                >
                  <DeleteIcon className={classes.muiIcon} />
                </IconButton>
              )}
            </Box>
          </Box>
          <ImageCarousel
            images={images}
            thumbnailSize={ThumbnailSize.LARGE}
            showNavArrows
            initialImageIndex={selectedImage}
            onSelectionChanged={setSelectedImage}
          />
        </Dialog>
        <AttachmentModal
          open={openUploadModal.isOpen}
          mutationService={record => {
            if (openUploadModal.mode === Mode.EDIT) {
              onEdit(images[selectedImage]?.id, selectedImage, record);
            } else if (openUploadModal.mode === Mode.NEW) {
              onSave(parentId, record);
            }
          }}
          handleClose={() => setOpenUploadModal({ isOpen: false, mode: '' })}
          mode={openUploadModal.mode}
          data={openUploadModal.mode === Mode.EDIT ? images[selectedImage] : {}}
        />
      </ThemeProvider>
    </>
  );
};

EntityImagesCarousel.propTypes = {
  images: PropTypes.array,
  thumbnailSize: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  parentId: PropTypes.string.isRequired,
  disableEdit: PropTypes.bool
};

EntityImagesCarousel.defaultProps = {
  images: [],
  disableEdit: false
};

export default EntityImagesCarousel;
