import gql from 'graphql-tag';

const deleteSkill = gql`
  mutation deleteSkill($input: DeleteSkillInput!) {
    deleteSkill(input: $input) {
      id
    }
  }
`;

export default deleteSkill;
