import React from 'react';

import { bool, func, object, string } from 'prop-types';

import { SergeantModal } from 'components';

const TableRowModal = ({
  open,
  data,
  layout,
  mode,
  title,
  onAction,
  onClose,
  customComponents,
  formVersion
}) => {
  return (
    <SergeantModal
      open={open}
      data={data}
      dataType=""
      mode={mode}
      formVersion={formVersion}
      layout={layout}
      customComponents={customComponents}
      handlePrimaryAction={async (values, stopLoading) => {
        try {
          await onAction(values);
        } finally {
          stopLoading();
        }
      }}
      handleClose={onClose}
      title={title}
    />
  );
};

TableRowModal.propTypes = {
  open: bool.isRequired,
  data: object.isRequired,
  layout: object.isRequired,
  title: string.isRequired,
  onAction: func.isRequired,
  onClose: func.isRequired,
  customComponents: object,
  mode: string.isRequired,
  formVersion: string
};

TableRowModal.defaultProps = {
  customComponents: {},
  formVersion: null
};

export default TableRowModal;
