export const useStyles = () => {
  return {
    header: theme => ({
      display: 'flex',
      marginBottom: theme.spacing(1)
    }),
    title: theme => ({
      marginBottom: theme.spacing(1)
    }),
    button: {
      marginLeft: 'auto'
    }
  };
};
