import AmplifyService from '../../../AmplifyService';
import SubscriptionClient from '../helper';

import updateTenantSettingGql from '../../graphql/common/mutations/updateTenantSetting';

export default class TenantSettingService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
    this.subscriptionClient = SubscriptionClient.getClient(AmplifyService.config);
  }

  updateTenantSetting = async data => {
    const params = {
      data
    };

    const response = await this.api.mutate(updateTenantSettingGql, params);
    return response;
  };
}
