import gql from 'graphql-tag';

const updateLabourRate = gql`
  mutation updateLabourRate($partitionKey: String, $data: UpdatePricebookLabourEntryInput!) {
    updatePricebookLabourEntry(partitionKey: $partitionKey, data: $data) {
      id
      rate
      version
      isActive
      costCodeId
      labourTypeId
      revenueTypeId
      billingHourTypeId
    }
  }
`;

export default updateLabourRate;
