import gql from 'graphql-tag';

const updatePayrollBillingHourType = gql`
  mutation updatePayrollBillingHourType($partitionKey: String, $data: UpdateBillingHourTypeInput!) {
    updateBillingHourType(partitionKey: $partitionKey, data: $data) {
      id
      version
      isActive
      hourType
      hourTypeAbbreviation
    }
  }
`;

export default updatePayrollBillingHourType;
