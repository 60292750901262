import React from 'react';

import { func, shape } from 'prop-types';
import { useParams } from 'react-router-dom';

import SelectOnlyFileManager from '@pm/components/FileManagement/SelectOnlyFileManager';

const FileManagerFormComponentWrapper = ({ options }) => {
  const { projectId } = useParams();
  const { onSelectFile } = options;
  return <SelectOnlyFileManager projectId={projectId} onSelectFile={onSelectFile} />;
};

FileManagerFormComponentWrapper.propTypes = {
  options: shape({
    onSelectFile: func.isRequired
  }).isRequired
};

export default FileManagerFormComponentWrapper;
