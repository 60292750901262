import gql from 'graphql-tag';

const getBankAccounts = gql`
  query getCompany($partitionKey: String!, $sortKey: String!, $nextToken: String) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      bankAccounts(entityConnection: "BankAccount", nextToken: $nextToken) {
        nextToken
        items {
          id
          name
          accountingRefId
          currencyCode
          isActive
        }
      }
    }
  }
`;

export default getBankAccounts;
