import React from 'react';

import { ThemeProvider } from '@buildhero/sergeant';
import { any, array, bool, func, number, string } from 'prop-types';

import WrapTable from 'components/WrapTable';
import { TablePlaceholderVariant } from 'components/WrapTable/WrapTable.constants';
import ConditionalWrapper from 'utils/ConditionalWrapper';

import ReportSection from '../ReportSection';

const ReportTable = ({
  title,
  renderTitle,
  columns,
  rows,
  loading,
  error,
  lockRowHeight,
  noDataMessage,
  tableOnly,
  loadingRows
}) => {
  return (
    <ThemeProvider>
      <ConditionalWrapper
        condition={!tableOnly}
        wrapper={children => {
          return (
            <ReportSection title={title} renderTitle={renderTitle}>
              {children}
            </ReportSection>
          );
        }}
      >
        <WrapTable
          columns={columns}
          rows={rows}
          loading={loading}
          error={error}
          lockRowHeight={lockRowHeight}
          loadingRows={loadingRows}
          noDataMessage={noDataMessage}
          placeholderVariant={TablePlaceholderVariant.TEXT}
        />
      </ConditionalWrapper>
    </ThemeProvider>
  );
};

ReportTable.propTypes = {
  title: string,
  renderTitle: func,
  columns: array.isRequired,
  rows: array.isRequired,
  loading: bool.isRequired,
  error: any,
  lockRowHeight: bool,
  // eslint-disable-next-line react/require-default-props
  noDataMessage: string,
  tableOnly: bool,
  loadingRows: number
};

ReportTable.defaultProps = {
  error: undefined,
  lockRowHeight: false,
  title: '',
  renderTitle: undefined,
  tableOnly: false,
  loadingRows: 5
};

export default ReportTable;
