import { useTheme } from '@material-ui/core';

export const useStyles = () => {
  const theme = useTheme();
  return {
    container: ({ disabled }) => {
      return {
        display: 'flex',
        backgroundColor: theme.palette.grayscale(20),
        opacity: disabled ? 0.5 : 'unset',
        borderRadius: 4,
        '& > *': {
          color: theme.palette.common.white,
          opacity: 'unset'
        }
      };
    },
    button: {
      background: 'none'
    },
    menu: {
      padding: 0,
      width: 30
    },
    divider: {
      height: 30,
      borderRightColor: theme.palette.common.white,
      borderRightWidth: 1,
      borderRightStyle: 'solid'
    }
  };
};
