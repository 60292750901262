import { generateDefaultFieldsObject } from '@pm/components/formattingUtils';

const margin = '0px 15px 21px 0px';

const notesFields = {
  title: { required: false },
  workCompleted: { required: false },
  issues: { required: false },
  outOfScopeWork: { required: false },
  safetyConcerns: { required: false },
  otherObservations: { required: false },
  internalNotes: { required: false },
  attachments: { required: false }
};

const notesContents = (attachments, handleFormAttachments) => {
  return [
    // the 1st row
    {
      options: {
        size: 1,
        direction: 'row',
        grow: 1,
        flexWrap: 'wrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            margin
          },
          contents: [
            {
              component: {
                default: 'TextInput',
                edit: 'TextInput'
              },
              source: 'workCompleted',
              options: {
                label: 'Work Performed',
                placeholder: 'Enter Work Performed',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                lines: 4
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            margin
          },
          contents: [
            {
              component: {
                default: 'TextInput',
                edit: 'TextInput'
              },
              source: 'issues',
              options: {
                label: 'Delays/Issues',
                placeholder: 'Enter Delays/Issues',
                lines: 4,
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            margin
          },
          contents: [
            {
              component: {
                default: 'TextInput',
                edit: 'TextInput'
              },
              source: 'outOfScopeWork',
              options: {
                label: 'Out of Scope Work',
                placeholder: 'Enter Out of Scope Work',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                lines: 4
              }
            }
          ]
        }
      ]
    },
    {
      options: {
        direction: 'row'
      },
      contents: [
        {
          options: {
            direction: 'column',
            margin
          },
          contents: [
            {
              component: {
                default: 'TextInput',
                edit: 'TextInput'
              },
              source: 'safetyConcerns',
              options: {
                label: 'Safety Concerns',
                placeholder: 'Enter Safety Concerns',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                lines: 4
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            margin
          },
          contents: [
            {
              component: {
                default: 'TextInput',
                edit: 'TextInput'
              },
              source: 'otherObservations',
              options: {
                label: 'Other Observations',
                placeholder: 'Enter Other Observations',
                lines: 4,
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            margin
          },
          contents: [
            {
              component: {
                default: 'TextInput',
                edit: 'TextInput'
              },
              source: 'internalNotes',
              options: {
                label: 'Internal Notes',
                placeholder: 'Enter Internal Notes',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                lines: 4
              }
            }
          ]
        }
      ]
    },
    {
      options: {
        direction: 'row'
      },
      contents: [
        {
          options: {
            direction: 'column',
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomAttachmentsComponent',
                edit: 'CustomAttachmentsComponent'
              },
              source: 'attachments',
              options: {
                label: 'Attachments',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                attachments,
                handleFormAttachments
              }
            }
          ]
        }
      ]
    }
  ];
};

const notesLayout = (attachments, handleFormAttachments) => {
  return {
    fields: {
      ...generateDefaultFieldsObject(notesFields)
    },
    layouts: {
      default: {
        options: {
          grow: 0,
          width: '100%'
        },
        contents: [...notesContents(attachments, handleFormAttachments)]
      },
      edit: {
        options: {
          grow: 0,
          width: '100%'
        },
        contents: [...notesContents(attachments, handleFormAttachments)]
      }
    }
  };
};

export { notesFields, notesLayout };
