import { first, isNumber, mergeWith, pick, sortBy } from 'lodash';
import moment from 'moment';

import { roundCurrency } from 'utils';
import Constants from 'utils/AppConstants';

const CUSTOMER_SIGNATURE_TYPE = 'customerSignature';

export const selectDepartmentName = visit => visit?.departmentName;

export const selectDepartmentId = visit => visit?.departmentId;

export const selectTechnicianNames = visit =>
  [
    ...(visit?.extraTechs?.items?.map(tech => tech?.mappedEntity?.name) || []),
    ...(visit?.primaryTechs?.items?.map(tech => tech?.mappedEntity?.name) || [])
  ].join(', ');

export const selectTechReportSubmittedBy = visit =>
  visit?.submittedBy || visit?.primaryTechs?.items?.[0]?.mappedEntity?.name;

export const selectTechReportSubmittedDate = visit =>
  visit?.submittedDate
    ? moment
        .unix(visit?.submittedDate || parseInt(visit?.lastUpdatedDateTime, 10) / 1000)
        .format(Constants.DATETIME_FORMAT)
    : undefined;

export const selectVisitDescription = visit => visit?.description;

export const selectTechnicianNotes = visit => visit?.notes?.items;

export const selectVisitSummaries = visit => visit?.summaries?.items;

export const selectAssets = visit =>
  visit?.assets?.items?.map(item => item?.asset).filter(asset => !!asset);

export const selectForms = visit => {
  return visit?.formData?.items || [];
};

export const selectAttachments = visit =>
  visit?.attachments?.items?.filter(item => item?.type !== CUSTOMER_SIGNATURE_TYPE);

export const selectCustomerSignatures = visit => visit?.customerSignatures?.items;

export const selectInventoryParts = inventoryParts =>
  inventoryParts?.items?.map(item => {
    const localUnitOfMeasure = item?.product?.unitOfMeasure?.name;
    return {
      ...item,
      addedBy: item.addedBy || item.createdBy,
      amount:
        isNumber(item.quantity) && isNumber(item.unitPrice)
          ? roundCurrency(item.quantity * item.unitPrice)
          : undefined,
      unitOfMeasure: localUnitOfMeasure,
      costCode: item?.costCode?.name,
      costType: item?.jobCostType?.name,
      revenueType: item?.revenueType?.name
    };
  });

export const selectPurchasesItems = visit => visit?.purchaseOrders?.items;

export const selectTimesheets = ({ visit, propertyAddress }) => {
  const VisitActionTypes = ['Work', 'Travel', 'Break', 'Pause', 'BillableNonVisitEvent'];
  const rows =
    visit?.timesheetEntries?.items.map(item => {
      const startTime = item?.actualStartTimeOverrideUTC || item?.actualStartTimeUTC;
      const endTime = item?.actualEndTimeOverrideUTC || item?.actualEndTimeUTC;
      const eventType = item?.timekeepingLedger?.eventType;
      const delta = startTime && endTime ? endTime - startTime : 0;
      let eventActionTime = {};
      if (eventType?.name === 'Visit') {
        eventActionTime = { [item.userActionType]: delta };
      } else if (eventType?.isBillable) {
        eventActionTime = { BillableNonVisitEvent: delta };
      }

      const technicianLocation = pick(item, [
        'startLatitude',
        'endLatitude',
        'startLongitude',
        'endLongitude'
      ]);
      const propertyLocation = pick(propertyAddress, ['latitude', 'longitude']);

      return {
        id: item?.id,
        name: item?.timekeepingLedger?.employeeName,
        startTime: startTime * 1000, // data is in seconds - convert to ms
        endTime: endTime * 1000,
        technicianLocation,
        propertyLocation,
        ...eventActionTime
      };
    }) || [];

  if (rows.length) {
    const totals = rows.reduce(
      (result, row) => {
        const eventActionTime = pick(row, VisitActionTypes);
        return mergeWith(
          result,
          eventActionTime,
          (resultValue, rowValue) => resultValue + rowValue
        );
      },
      VisitActionTypes.reduce((result, actionType) => ({ ...result, [actionType]: 0 }), {})
    );

    return [
      ...rows,
      {
        id: 'totals',
        name: 'Totals',
        ...totals
      }
    ];
  }
};

export const selectVisitTimesheetNotes = visit =>
  visit?.timesheetNotes?.items.map(item => ({ ...item, eventName: 'Visit' }));

export const selectNonVisitTimesheetNotes = ({ visit, isAutomatedTimeTracking }) => {
  let nveTimesheetNotes = [];
  /*
    When automated time tracking is turned on, the nve notes will be included in timesheetEntries
    When manual time tracking is turned on, the nve notes will be included in nonVisitEvents
  */
  if (isAutomatedTimeTracking) {
    nveTimesheetNotes = visit?.timesheetEntries?.items?.reduce((acc, entry) => {
      const eventType = entry?.timekeepingLedger?.eventType;
      const eventEntity = entry?.timekeepingLedger?.eventEntity || {};
      if (eventType?.name !== 'Visit' && eventType?.isBillable) {
        const notes =
          eventEntity?.timesheetNotes?.items.map(note => ({
            ...note,
            eventName: 'Billable Event'
          })) || [];
        return [...acc, ...notes];
      }
      return [...acc];
    }, []);
  } else {
    nveTimesheetNotes = visit?.nonVisitEvents?.items?.reduce((acc, nve) => {
      const eventType = nve?.eventType;
      if (eventType?.name !== 'Visit' && eventType?.isBillable) {
        const notes =
          nve?.timesheetNotes?.items.map(note => ({ ...note, eventName: 'Billable Event' })) || [];
        return [...acc, ...notes];
      }
      return [...acc];
    }, []);
  }
  return nveTimesheetNotes;
};

export const selectReviewReportId = visit => first(visit?.reviewReports?.items)?.id;

export const selectCanBeReviewed = visit =>
  ['complete', 'on hold', 'closed'].includes((visit?.status || '').toLowerCase());

export const selectTimesheetEntries = visit => visit?.timesheetEntries?.items;

const visitActionTypeConverter = {
  Working: () => 'Work',
  Traveling: () => 'Travel',
  OnABreak: () => 'Break',
  Paused: () => 'Pause'
};

export const selectTimesheetManualEntry = ({ visit, propertyAddress, locationData = [] }) => {
  const VisitActionTypes = ['Work', 'Travel', 'Break', 'Pause', 'BillableNonVisitEvent'];
  const visitRows =
    visit?.timeSheets?.items.map(item => {
      const startTime = item?.clockInTime;
      const endTime = item?.clockOutTime;
      const eventType = visitActionTypeConverter[item?.labourType]?.() ?? item?.labourType;

      // Find matched technician location data from TimekeepingLedger
      const technicianLocation = pick(
        locationData.find(
          ledger =>
            ledger?.actualStartTimeUTC === startTime &&
            ledger?.actualEndTimeUTC === endTime &&
            ledger?.userActionType === eventType
        ),
        ['startLatitude', 'endLatitude', 'startLongitude', 'endLongitude']
      );

      const propertyLocation = pick(propertyAddress, ['latitude', 'longitude']);
      const delta = startTime && endTime ? endTime - startTime : 0;
      const eventActionTime = { [eventType]: delta };

      return {
        id: item?.id,
        name: item?.createdBy,
        startTime: startTime * 1000,
        endTime: endTime * 1000,
        technicianLocation,
        propertyLocation,
        ...eventActionTime
      };
    }) || [];

  const billableNonVisitEventRows =
    visit?.nonVisitEvents?.items.reduce((acc, nve) => {
      if (nve?.eventType?.name !== 'Visit' && nve?.eventType?.isBillable) {
        const nveSheets = nve?.timekeepingLedgersView?.items.map(item => ({
          id: item?.id,
          name: item?.employeeName,
          startTime: item?.actualStartTimeUTC * 1000,
          endTime: item?.actualEndTimeUTC * 1000,
          BillableNonVisitEvent: item?.actualEndTimeUTC - item?.actualStartTimeUTC
        }));
        return [...acc, ...nveSheets];
      }
      return acc;
    }, []) || [];

  const rows = sortBy([...visitRows, ...billableNonVisitEventRows], 'startTime');

  if (rows.length) {
    const totals = rows.reduce(
      (result, row) => {
        const eventActionTime = pick(row, VisitActionTypes);
        return mergeWith(
          result,
          eventActionTime,
          (resultValue, rowValue) => resultValue + rowValue
        );
      },
      VisitActionTypes.reduce((result, actionType) => ({ ...result, [actionType]: 0 }), {})
    );

    return [
      ...rows,
      {
        id: 'totals',
        name: 'Totals',
        ...totals
      }
    ];
  }
};
