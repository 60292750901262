/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getBillLinesByBillId(id, include) {
  return await instance
    .get(`billLines/getBillLinesByBillId/${id}`, {
      params: {
        // eslint-disable-next-line prettier/prettier
        include: include || [
          'Department',
          'Product',
          'PurchaseOrderLine',
          'PurchaseOrder',
          'JcCostType',
          'JcPhase',
          'Job'
        ]
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getReviewReportBillItemByBillLine(id, include) {
  return await instance
    .get(`billLines/reviewreportitem/${id}`, {
      params: {
        include: include || []
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getBillLinesByProjectPhase(projectPhaseId) {
  return await instance
    .get(`billlines/byprojectphase/${projectPhaseId}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
