import { useEffect, useState } from 'react';

import { Logger } from 'services/Logger';

import { getServiceAgreementSettings } from '../scenes/ServiceAgreements/DetailView/service';

export const useServiceAgreementsSettings = user => {
  const [settings, setSettings] = useState({});
  useEffect(() => {
    (async () => {
      const response = await getServiceAgreementSettings(user);
      try {
        if (response?.settings) {
          const parsedSettings = JSON.parse(response.settings);
          setSettings(parsedSettings);
        }
      } catch (error) {
        Logger.warn(`Cannot parse service agreement settings: ${error}`);
      }
      return {};
    })();
  }, [user]);
  return settings;
};

export default useServiceAgreementsSettings;
