const getTentativeDateAndTimeForExistingVisit = visit => {
  const { minimumDuration, tentativeDate, tentativePeriod, tentativeTime, scheduledFor } =
    visit || {};
  return {
    minimumDuration,
    tentativeDate,
    tentativeTime,
    tentativePeriod,
    scheduledFor
  };
};

export default getTentativeDateAndTimeForExistingVisit;
