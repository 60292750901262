/* eslint-disable no-useless-concat */
/* eslint-disable no-template-curly-in-string */
const defaultQuoteForm = (
  projectManagerValues,
  soldByValues,
  departmentsOptions,
  serviceAgreementOptions,
  priceBooksOptions,
  handlePricebookDropdown,
  hasServiceAgreements,
  hasMultiplePricebooks,
  isDueDateDisabled,
  accountManagerOptions,
  isReadOnly
) => {
  return {
    fields: {
      Id: {
        default: '${id}'
      }
    },
    layouts: {
      // forms container
      default: {
        options: {
          direction: 'column',
          grow: 0,
          padding: [0]
          // debug: true
        },
        // boxing
        contents: [
          {
            options: {
              direction: 'column',
              padding: [10, 8, 9, 8]
            },
            contents: [
              {
                options: {
                  direction: 'row',
                  padding: [0, 0]
                },
                contents: [
                  {
                    options: {
                      direction: 'row',
                      shrink: 0,
                      size: '100%'
                    },
                    contents: [
                      {
                        options: {},
                        contents: [
                          {
                            options: {
                              grow: 0,
                              size: 'auto',
                              direction: 'column',
                              justify: 'flex-start',
                              padding: [0, 0, 10]
                            },
                            contents: [
                              {
                                component: {
                                  default: 'FieldLabel'
                                },
                                source: 'QUOTE TITLE'
                              },
                              {
                                options: {
                                  disabled: isReadOnly
                                },
                                component: {
                                  default: 'TextInput'
                                },
                                source: 'name'
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                options: {
                  direction: 'row',
                  padding: [0, 0]
                },
                contents: [
                  {
                    options: {
                      direction: 'row',
                      shrink: 0,
                      size: '50%'
                    },
                    contents: [
                      {
                        options: {},
                        contents: [
                          {
                            options: {
                              grow: 0,
                              size: 'auto',
                              direction: 'column',
                              justify: 'flex-start',
                              padding: [0, 0, 10]
                            },
                            contents: [
                              {
                                component: {
                                  default: 'FieldLabel'
                                },
                                source: 'VERSION TITLE'
                              },
                              {
                                options: {
                                  disabled: isReadOnly
                                },
                                component: {
                                  default: 'TextInput'
                                },
                                source: 'versionLabel'
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    options: {
                      direction: 'column',
                      shrink: 0,
                      size: '50%',
                      padding: [0, 0, 10, 5]
                    },
                    contents: [
                      {
                        component: {
                          default: 'FieldLabel'
                        },
                        source: 'PROJECT MANAGER'
                      },
                      {
                        options: {},
                        contents: [
                          {
                            options: {
                              disabled: isReadOnly,
                              isSearchable: true,
                              valueSet: projectManagerValues
                            },
                            component: {
                              default: 'SelectInput'
                            },
                            source: 'ownerId'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                options: {
                  direction: 'row',
                  padding: [0, 0]
                },
                contents: [
                  {
                    options: {
                      direction: 'column',
                      shrink: 0,
                      size: '50%',
                      padding: [0, 5, 10, 0]
                    },
                    contents: [
                      {
                        component: {
                          default: 'FieldLabel'
                        },
                        source: 'ACCOUNT MANAGER'
                      },
                      {
                        options: {},
                        contents: [
                          {
                            options: {
                              disabled: isReadOnly,
                              isSearchable: true,
                              valueSet: accountManagerOptions
                            },
                            component: {
                              default: 'SelectInput'
                            },
                            source: 'accountManagerId'
                          }
                        ]
                      }
                    ]
                  },
                  {
                    options: {
                      direction: 'column',
                      shrink: 0,
                      size: '50%',
                      padding: [0, 0, 10, 5]
                    },
                    contents: [
                      {
                        component: {
                          default: 'FieldLabel'
                        },
                        source: 'SOLD BY'
                      },
                      {
                        options: {},
                        contents: [
                          {
                            options: {
                              disabled: isReadOnly,
                              isSearchable: true,
                              valueSet: soldByValues
                            },
                            component: {
                              default: 'SelectInput'
                            },
                            source: 'salesById'
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                options: {
                  direction: 'row',
                  padding: [0, 0]
                },
                contents: [
                  {
                    options: {
                      direction: 'column',
                      shrink: 0,
                      size: '50%',
                      padding: [0, 5, 0, 0]
                    },
                    contents: [
                      {
                        component: {
                          default: 'FieldLabel'
                        },
                        source: 'Department'
                      },
                      {
                        options: {
                          disabled: isReadOnly,
                          isSearchable: true,
                          valueSet: departmentsOptions
                        },
                        component: {
                          default: 'SelectInput'
                        },
                        source: 'departmentId'
                      }
                    ]
                  },
                  {
                    options: {
                      direction: 'column',
                      shrink: 0,
                      size: '50%',
                      padding: [0, 0, 0, 5]
                    },
                    contents: [
                      {
                        component: {
                          default: 'FieldLabel'
                        },
                        source: 'CUSTOMER PO NUMBER'
                      },
                      {
                        options: {
                          disabled: isReadOnly
                        },
                        component: {
                          default: 'TextInput'
                        },
                        source: 'customerPoNumber'
                      }
                    ]
                  }
                ]
              },
              {
                options: {
                  alignItems: 'end',
                  direction: 'row',
                  padding: [0, 0]
                },
                contents: [
                  {
                    options: {
                      direction: 'column',
                      shrink: 0,
                      size: '50%',
                      padding: [10, 5, 0, 0],
                      hide: !hasServiceAgreements
                    },
                    contents: [
                      {
                        component: {
                          default: 'FieldLabel'
                        },
                        source: 'SERVICE AGREEMENT'
                      },
                      {
                        options: {},
                        contents: [
                          {
                            component: {
                              default: 'SelectInput'
                            },
                            source: 'serviceAgreementId',
                            options: {
                              label: '',
                              disabled: isReadOnly,
                              valueSet: serviceAgreementOptions,
                              isSearchable: true,
                              onChange: (valueSet, form) => handlePricebookDropdown(valueSet, form)
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    options: {
                      direction: 'column',
                      shrink: 0,
                      size: '50%',
                      padding: [10, 0, 0, 5],
                      hide: !hasMultiplePricebooks,
                      position: 'relative'
                    },
                    contents: [
                      {
                        options: {
                          position: 'absolute',
                          top: 5
                        },
                        component: {
                          default: 'FieldLabel'
                        },
                        source: 'PRICEBOOK'
                      },
                      {
                        options: {},
                        contents: [
                          {
                            component: {
                              default: 'SelectInput'
                            },
                            source: 'priceBookId',
                            options: {
                              label: '',
                              valueSet: priceBooksOptions,
                              isSearchable: true,
                              isRequired: true,
                              disabled: isReadOnly
                            }
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                options: {
                  direction: 'row',
                  padding: [0, 0],
                  alignItems: 'end'
                },
                contents: [
                  {
                    options: {
                      direction: 'column',
                      shrink: 0,
                      size: '50%',
                      padding: [10, 5, 0, 0]
                    },
                    contents: [
                      {
                        component: {
                          default: 'FieldLabel'
                        },
                        source: 'Quote Due By'
                      },
                      {
                        options: { type: 'date' },
                        contents: [
                          {
                            options: { disabled: isDueDateDisabled || isReadOnly },
                            component: {
                              default: 'DateInput'
                            },
                            source: 'dueDate'
                          }
                        ]
                      }
                    ]
                  },
                  {
                    options: {
                      direction: 'column',
                      shrink: 0,
                      size: '50%',
                      padding: [10, 0, 0, 5],
                      position: 'relative'
                    },
                    contents: [
                      {
                        options: {
                          position: 'absolute',
                          top: 5
                        },
                        component: {
                          default: 'FieldLabel'
                        },
                        source: 'Expiration (Number of Days after Receiving)'
                      },
                      {
                        options: {},
                        contents: [
                          {
                            component: {
                              default: 'TextInput'
                            },
                            options: {
                              disabled: isReadOnly,
                              isRequired: true,
                              type: 'numeric'
                            },
                            source: 'expirationLength'
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  };
};

export default defaultQuoteForm;
