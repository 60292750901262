import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { snackbarOn } from 'redux/actions/globalActions';
import { getPurchaseOrderTags } from 'services/API/purchaseOrderTag';
import CreatePurchaseOrder from '../../index';

const AddPurchaseOrder = ({
  user,
  isModalOpen,
  setModalOpen,
  onCloseParentCallback,
  addPurchaseOrderData,
  jobProjectFieldReadOnly,
  generalInfoCustomField,
  parentData
}) => {
  const [tagOptions, setTagOptions] = useState([]);

  useEffect(() => {
    getPurchaseOrderTags().then(purchaseOrderTags => {
      setTagOptions(
        purchaseOrderTags.map(tag => {
          return { label: tag.tagName, value: tag.id };
        })
      );
    });
  }, []);

  const handleClose = po => {
    setModalOpen(false);
    if (po.id) {
      onCloseParentCallback({ po, parentData });
    }
  };

  return (
    <CreatePurchaseOrder
      open={isModalOpen}
      handleClose={handleClose}
      user={user}
      initialData={addPurchaseOrderData}
      tagOptions={tagOptions}
      jobProjectFieldReadOnly={jobProjectFieldReadOnly}
      generalInfoCustomField={generalInfoCustomField}
    />
  );
};

const mapDispatchToProps = dispatch => ({
  snackbar: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

export default connect(null, mapDispatchToProps)(AddPurchaseOrder);
