/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { bool, arrayOf, object, array } from 'prop-types';
import { TV, TW, Typography } from '@buildhero/sergeant';

import { ColDef } from '../WrapTable.types';
import Column, { LoadingColumn } from '../Column';

import { useRowColumns } from './Row.hooks';
import { useRowStyles } from './Row.styles';

const RowColumn = ({ colDef, ...props }) => {
  const updatedColDef = useMemo(
    () => ({
      ...colDef,
      ...(colDef.editable && colDef.renderEditCell
        ? { renderCell: colDef.renderEditCell, noPadding: true }
        : {})
    }),
    [colDef]
  );

  return <Column {...props} colDef={updatedColDef} noPadding={false} />;
};

const Row = ({
  columns,
  enableGroupHeaders,
  isFirstLoad,
  isFirstRow,
  isGroupedRow,
  isLastRow,
  lockRowHeight,
  maxGroupingDepth,
  noWrap,
  row,
  rowGroupingColumnMode,
  rowGroupingModel,
  rows,
  scrollX
}) => {
  const [groupExpanded, setGroupExpanded] = useState(false);
  const groupData = row?.['__groupData'];
  const groupField = row?.['__groupField'];
  const groupDepth = row?.['__groupDepth'];
  const groupHierarchy = row?.['__groupHierarchy'];
  const isGroupRow = Boolean(groupData);

  const styles = useRowStyles({ isGroupedRow, isLastRow, groupExpanded, isGroupRow });

  const rowColumns = useRowColumns({
    columns,
    groupField,
    rowGroupingModel,
    rowGroupingColumnMode
  });

  return (
    <>
      {groupHierarchy && isFirstRow && enableGroupHeaders && (
        <Typography css={styles.groupHeader} weight={TW.BOLD} variant={TV.S2}>
          {groupHierarchy}
        </Typography>
      )}
      <div css={styles.row}>
        {rowColumns.map((colDef, index) =>
          isFirstLoad ? (
            <LoadingColumn
              flex={colDef.flex}
              minWidth={colDef.minWidth}
              maxWidth={colDef.maxWidth}
              width={colDef.width}
              scrollX={scrollX}
            />
          ) : (
            <RowColumn
              key={colDef.field}
              value={row[colDef.field]}
              sortIndex={index}
              {...{
                colDef,
                groupDepth,
                groupExpanded,
                isFirstRow,
                isGroupRow,
                isLastRow,
                lockRowHeight,
                maxGroupingDepth,
                noWrap,
                row,
                rows,
                scrollX,
                setGroupExpanded
              }}
            />
          )
        )}
      </div>
      {groupExpanded &&
        groupData?.map((groupRow, index) => (
          <Row
            key={groupRow.id}
            {...{
              columns,
              enableGroupHeaders,
              isFirstLoad,
              isFirstRow: index === 0,
              isGroupedRow: true,
              isLastRow: index === groupData.length - 1,
              lockRowHeight,
              maxGroupingDepth,
              noWrap,
              row: groupRow,
              rowGroupingColumnMode,
              rowGroupingModel,
              rows: groupData,
              scrollX
            }}
          />
        ))}
    </>
  );
};

Row.propTypes = {
  row: object.isRequired,
  rows: array.isRequired,
  columns: arrayOf(ColDef).isRequired,
  scrollX: bool,
  noWrap: bool,
  lockRowHeight: bool,
  isFirstRow: bool.isRequired,
  isLastRow: bool.isRequired
};

Row.defaultProps = {
  scrollX: false,
  noWrap: false,
  lockRowHeight: false
};

export default memo(Row);
