import gql from 'graphql-tag';

const updateQuoteLineTask = gql`
  mutation updateQuoteLineTask($partitionKey: String!, $data: UpdateQuoteLineTaskInput!) {
    updateQuoteLineTask(partitionKey: $partitionKey, data: $data) {
      id
      version
      name
      description
      unitPrice
      unitCost
      markupType
      markupValue
      quantity
    }
  }
`;

export default updateQuoteLineTask;
