import { bundleIndex as defaultProductIndex } from 'constants/algoliaIndex';
export const truckInventoryForm = {
  fields: {
    name: { default: 'name' },
    description: { default: 'description' },
    unitOfMeasureValue: { default: 'unitOfMeasureValue' },
    quantityOnHand: { default: 'quantityOnHand' },
    minQuantity: { default: 'minQuantity' },
    maxQuantity: { default: 'maxQuantity' }
  },
  layouts: {
    default: {
      options: { pageSize: 'LETTER', grow: 0, padding: [10, 0] },
      contents: [
        {
          options: { size: 2, direction: 'row', grow: 0, margin: [0, 0, 10, 0] },
          contents: [
            {
              options: { direction: 'column', grow: 1, padding: [2, 2, 2, 0] },
              contents: [
                {
                  component: { default: 'ProductSearch' },
                  source: 'productName',
                  options: {
                    label: 'ITEM NAME',
                    placeholder: '',
                    required: true,
                    alignment: 'left',
                    valuesToSet: [
                      // array because objects are getting stripped
                      {
                        productName: 'name',
                        productId: 'id',
                        productDescription: 'description',
                        unitOfMeasureValue: 'unitOfMeasure'
                      }
                    ],
                    searchIndexName: defaultProductIndex,
                    searchResultDisplayFields: ['name', 'description'],
                    filters: [
                      {
                        attribute: 'entityType',
                        valueArray: ['Product']
                      }
                    ]
                  }
                }
              ]
            },
            {
              options: { direction: 'column', grow: 1, padding: [2, 2, 2, 18] },
              contents: [
                {
                  component: { default: 'FieldWithLabel' },
                  source: 'productDescription',
                  options: {
                    label: 'DESCRIPTION',
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        },
        {
          options: { size: 2, direction: 'row', grow: 0, margin: [0, 0, 10, 0] },
          contents: [
            {
              options: { direction: 'column', grow: 1, padding: [2, 20, 2, 0] },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  options: {
                    label: 'UOM'
                  },
                  source: 'unitOfMeasureValue'
                }
              ]
            },
            {
              options: { direction: 'column', grow: 1, padding: 2 },
              contents: [
                {
                  component: { default: 'TextInput' },
                  source: 'quantityOnHand',
                  options: {
                    label: 'QUANTITY ON HAND',
                    placeholder: '',
                    type: 'number',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        },
        {
          options: { size: 2, direction: 'row', grow: 0, margin: [0, 0, 10, 0] },
          contents: [
            {
              options: { direction: 'column', grow: 1, padding: [2, 20, 2, 0] },
              contents: [
                {
                  component: { default: 'TextInput' },
                  source: 'minQuantity',
                  options: {
                    label: 'MINIMUM QUANTITY',
                    placeholder: '',
                    type: 'number',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: { direction: 'column', grow: 1, padding: 2 },
              contents: [
                {
                  component: { default: 'TextInput' },
                  source: 'maxQuantity',
                  options: {
                    label: 'MAXIMUM QUANTITY',
                    type: 'number',
                    placeholder: '',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    title: 'Truck Inventory item',
    type: 'object',
    properties: {
      productName: { type: 'string', required: true }
    }
  },
  validationErrors: {
    productName: {
      required: 'Please select an item'
    }
  }
};

export default truckInventoryForm;
