const styles = theme => ({
  buttons: {
    width: 205.4,
    height: 36,
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.32,
    textAlign: 'center',
    color: '#ffffff',
    backgroundColor: theme.palette.brand.logoBlue,
    marginBottom: 25
  },
  pricebooksName: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 1500,
    letterSpacing: 0.13,
    color: theme.palette.grayscale(25),
    marginBottom: 10
  },
  pricebooksDescription: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    letterSpacing: 0.24,
    color: theme.palette.grayscale(25),
    marginBottom: 10
  },
  settingButton: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.brand.logoBlue,
    marginBottom: 20
  },
  arrow: {
    verticalAlign: 'middle',
    display: 'inline'
  },
  helpText: {
    color: theme.palette.grayscale(40),
    fontSize: 12
  }
});

export default styles;
