import moment from 'moment';
import _ from 'lodash';
import listPropertyAssets from 'services/core/graphql/crm/customer-property/queries/listPropertyAssets';
import AmplifyService from 'services/AmplifyService';
import getPastVisitsByCustomerProperty from '../../graphql/crm/customer-property/queries/getPastVisitsByCustomerProperty';
import getUpcomingVisitsByCustomerProperty from '../../graphql/crm/customer-property/queries/getUpcomingVisitsByCustomerProperty';
import getCustomerProperty from '../../graphql/crm/customer-property/queries/getCustomerProperty';
import getCustomerPropertyInfo from '../../graphql/crm/customer-property/queries/getCustomerPropertyInfo';
import getCustomerPropertyInfoById from '../../graphql/crm/customer-property/queries/getCustomerPropertyInfoById';
import getPropertyAssetById from '../../graphql/crm/customer-property/queries/getPropertyAssetById';
import getAssetsByCustomerProperty from '../../graphql/crm/customer-property/queries/getAssetsByCustomerProperty';
import getAssetsByCustomerPropertyById from '../../graphql/crm/customer-property/queries/getAssetsByCustomerPropertyById';
import getAttachmentByCustomerProperty from '../../graphql/crm/customer-property/queries/getAttachmentByCustomerProperty';
import getAttachmentByCustomerPropertyById from '../../graphql/crm/customer-property/queries/getAttachmentByCustomerPropertyById';
import getCustomerRepsByCustomerProperty from '../../graphql/crm/customer-property/queries/getCustomerRepsByCustomerProperty';
import getCustomerRepsByCustomerPropertyById from '../../graphql/crm/customer-property/queries/getCustomerRepsByCustomerPropertyById';
import getQuotesByCustomerPropertyById from '../../graphql/crm/customer-property/queries/getQuotesByCustomerPropertyById';
import getTenantRepsByCustomerProperty from '../../graphql/crm/customer-property/queries/getTenantRepsByCustomerProperty';
import getTenantRepsByCustomerPropertyById from '../../graphql/crm/customer-property/queries/getTenantRepsByCustomerPropertyById';
import getAuditLogsByCustomerPropertyById from '../../graphql/crm/customer-property/queries/getAuditLogsByCustomerPropertyById';
import propertySchema from '../../mutation-schema/customer-and-property/property-schema';
import tenantRepSchema from '../../mutation-schema/customer-and-property/tenantRep-schema';
import propertyAssetSchema from '../../mutation-schema/customer-and-property/propertyAsset-schema';
import attachmentSchema from '../../mutation-schema/common/attachment-schema';
import noteMutationSchema from '../../mutation-schema/common/note-schema';
import mutationQuery from '../../graphql/common/mutations/batchCreateEntityData';
import savePropertyAssets from '../../graphql/crm/customer-property/mutations/savePropertyAssets';
import savePropertyAttachments from '../../graphql/crm/customer-property/mutations/savePropertyAttachments';
import savePropertyNotes from '../../graphql/crm/customer-property/mutations/savePropertyNotes';
import updateProperty from '../../graphql/crm/customer-property/mutations/updateCustomerProperty';
import CommonService from '../Common/CommonService';
import propertyNoteUpdateNotification from '../../graphql/crm/customer-property/subscriptions/propertyNoteUpdateNotification';
import propertyAssetUpdateNotification from '../../graphql/crm/customer-property/subscriptions/propertyAssetUpdateNotification';
import propertyAttachmentUpdateNotification from '../../graphql/crm/customer-property/subscriptions/propertyAttachmentUpdateNotification';
import updatePropertyAsset from '../../graphql/crm/customer-property/mutations/updatePropertyAsset';
import transformToCustomerPropertySchema from '../../mutation-schema/customer-and-property/updatePropertySchema';
import assignCustomerRepsToProperty from '../../graphql/crm/customer-property/mutations/assignCustomerRepToCustomerProperty';
import updateCustomerPropertyAndRelated from '../../graphql/crm/customer-property/mutations/updateCustomerPropertyAndRelated';
import addCustomerPropertiesToCustomer from '../../graphql/crm/customer-property/mutations/addCustomerPropertiesToCustomer';
import addTasksToCustomerProperty from '../../graphql/crm/customer-property/mutations/addTaskToCustomerProperty';
import updateTask from '../../graphql/crm/customer-property/mutations/updateTask';
import getTasksByCustomerPropertyById from '../../graphql/crm/customer-property/queries/getTasksByCustomerPropertyId';
import getTaskById from '../../graphql/crm/customer-property/queries/getTaskById';
import getTaskNumber from '../../graphql/crm/customer-property/queries/getTaskNumber';
import addTaskEntriesToTask from '../../graphql/crm/customer-property/mutations/addTaskEntriesToTask';
import updateTaskEntriesForTask from '../../graphql/crm/customer-property/mutations/updateTaskEntriesForTask';
import deleteTaskEntry from '../../graphql/crm/customer-property/mutations/deleteTaskEntry';
import deleteTask from '../../graphql/crm/customer-property/mutations/deleteTask';
import getAddressesForCustomerProperty from '../../graphql/crm/customer-property/queries/getAddressesForCustomerProperty';
import getJobsByProperty from '../../graphql/crm/customer-property/queries/getJobsByProperty';
import addNotesToCustomerProperty from '../../graphql/crm/customer-property/mutations/addNotesToCustomerProperty';
import getMaintenanceRecordsByProperty from '../../graphql/crm/customer-property/queries/getMaintenanceRecordsByProperty';
import updateCustomerRepOnProperty from '../../graphql/crm/customer-property/mutations/updateCustomerRepOnProperty';

import {
  addWarrantiesToPropertyAsset,
  updateWarranty,
  softDeleteWarranty
} from '../../graphql/crm/customer-property/mutations/asset-warranty';
import addAttachmentsToPropertyAsset from '../../graphql/crm/customer-property/mutations/addAttachmentsToPropertyAsset';
import addNotesToPropertyAsset from '../../graphql/crm/customer-property/mutations/addNotesToPropertyAsset';
import updateAttachment from '../../graphql/common/mutations/updateAttachment';
import deleteAttachmentById from '../../graphql/review-report/mutations/DeleteAttachmentById';
import updateNote from '../../graphql/common/mutations/updateNote';
import deleteNoteById from '../../graphql/review-report/mutations/DeleteNoteById';
import deletePropertyAssetById from '../../graphql/crm/customer-property/mutations/deletePropertyAssetById';
import SubscriptionClient from '../helper';
import { convertAddressObjToString, getMappedEntityJobTagname } from '../../utils';
import customerRepSchema from '../../mutation-schema/customer-and-property/customerRep-schema';

export default class CustomerPropertyService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
    this.CommonService = new CommonService();
    this.subscriptionClient = SubscriptionClient.getClient(AmplifyService.config);
  }

  getDepartments = visits => {
    let departmentNames = new Set();
    let deparmentsNamesWithCommaSeperated = '';
    if (visits) {
      visits.forEach(visit => {
        if (visit.departmentName) {
          const deptName = visit.departmentName;
          departmentNames.add(deptName.charAt(0).toUpperCase() + deptName.slice(1));
        }
      });
    }

    if (departmentNames.size > 0) {
      departmentNames = Array.from(departmentNames);
      deparmentsNamesWithCommaSeperated = departmentNames.join(', ');
    }
    return deparmentsNamesWithCommaSeperated || '-';
  };

  getLatestSchedule = schedules => {
    let latestSchedule = null;
    if (schedules.length > 0) {
      latestSchedule = Math.min(...schedules);
    }
    return latestSchedule;
  };

  // Capitalize first letter method

  capitalizeFirstLetter = inputString => {
    let capitalizedString = '';
    if (inputString) capitalizedString = inputString.charAt(0).toUpperCase() + inputString.slice(1);
    return capitalizedString;
  };

  getCustomerProperty = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getCustomerProperty, params);
    return response;
  };

  getCustomerPropertyInfo = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getCustomerPropertyInfo, params);
    return response;
  };

  getCustomerPropertyInfoById = async id => {
    const params = {
      id
    };

    const response = await this.api.query(getCustomerPropertyInfoById, params);
    return response;
  };

  getCustomerRepsByCustomerProperty = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getCustomerRepsByCustomerProperty, params);
    return response;
  };

  getCustomerRepsByCustomerPropertyById = async id => {
    const params = {
      id
    };

    const response = await this.api.query(getCustomerRepsByCustomerPropertyById, params);
    return response;
  };

  getQuotesByCustomerPropertyById = async (id, filter, limit, offset, sortBy, sortOrder) => {
    const params = {
      id,
      filter: filter || null,
      limit: limit || null,
      offset: offset || null,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'asc'
        }
      ]
    };

    const response = await this.api.query(getQuotesByCustomerPropertyById, params);
    if (response?.data?.getCustomerPropertyById) {
      const result = response.data.getCustomerPropertyById.quotes;
      return result;
    }
    return response;
  };

  getTenantRepsByCustomerProperty = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getTenantRepsByCustomerProperty, params);
    return response;
  };

  getTenantRepsByCustomerPropertyById = async id => {
    const params = {
      id
    };

    const response = await this.api.query(getTenantRepsByCustomerPropertyById, params);
    return response;
  };

  getAssetsByCustomerProperty = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getAssetsByCustomerProperty, params);
    return response;
  };

  getAssetsByCustomerPropertyById = async id => {
    const params = {
      id
    };

    const response = await this.api.query(getAssetsByCustomerPropertyById, params);
    return response;
  };

  listPropertyAssets = async id => {
    const params = {
      id
    };
    const response = await this.api.query(listPropertyAssets, params);
    return response;
  };

  getAttachmentByCustomerProperty = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getAttachmentByCustomerProperty, params);
    return response;
  };

  getAttachmentByCustomerPropertyById = async id => {
    const params = {
      id
    };

    const response = await this.api.query(getAttachmentByCustomerPropertyById, params);
    return response;
  };

  getMappedEntityTagname = entityItems => {
    return (
      entityItems &&
      !_.isEmpty(entityItems.items) &&
      entityItems.items.map(value => value.jobTag?.tagName).join(', ')
    );
  };

  getUpcomingVisitsByCustomerProperty = async (
    partitionKey,
    sortKey,
    filter,
    limit,
    offset,
    sortBy,
    sortOrder
  ) => {
    const params = {
      partitionKey,
      sortKey,
      filter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    const response = await this.api.query(getUpcomingVisitsByCustomerProperty, params);
    return response;
  };

  getPastVisitsByCustomerProperty = async (
    partitionKey,
    sortKey,
    filter,
    limit,
    offset,
    sortBy,
    sortOrder
  ) => {
    const params = {
      partitionKey,
      sortKey,
      filter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    const response = await this.api.query(getPastVisitsByCustomerProperty, params);
    return response;
  };

  getJobsByProperty = async (partitionKey, sortKey, filter, limit, offset, sortBy, sortOrder) => {
    const params = {
      partitionKey,
      sortKey,
      filter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        },
        {
          sortField: 'dueDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    let result;

    const response = await this.api.query(getJobsByProperty, params);
    const formattedResult = { items: [] };
    if (response && response.data) {
      result = response.data.getCustomerProperty.job;
      if (result?.items?.length) {
        result.items.forEach(item => {
          let ownerValue = '';

          if (item.owner) {
            ownerValue =
              item.owner.name || `${item.owner.firstName || ''} ${item.owner.lastName || ''}`;
          }

          const propertyName = (item.properties || {}).companyName;
          const { customerName } = (item.properties || {}).customer || {};
          const propertyAddress = item?.properties?.companyAddresses?.items?.[0];
          const fullAddress = propertyAddress ? convertAddressObjToString(propertyAddress) : '-';

          const start = moment.unix(item.createdDate);
          const end = moment.unix(Date.now() / 1000);
          const age = Math.ceil(moment.duration(end.diff(start)).asDays());

          const visitLabel = item?.visits?.items?.length;
          const visits = item?.visits?.items;

          const scheduleArray = [];

          visits.forEach(visit => {
            // Pushing all visit schedule dates to an array for computing latestSchedule for Job
            if (visit.scheduledFor && moment().unix() <= visit.scheduledFor) {
              scheduleArray.push(visit.scheduledFor);
            }

            const localVisit = visit;
            if (visit?.primaryTechs?.items?.[0]?.mappedEntity) {
              const tech = visit.primaryTechs.items[0].mappedEntity;
              localVisit.primaryTechName = `${tech.firstName}, ${tech.lastName}`;
            }
          });

          const formattedVisits = { visitLabel, visitDetails: visits };

          const departmentNames = this.getDepartments(visits);
          const jobTags = getMappedEntityJobTagname(item.jobJobTags);
          formattedResult.items.push({
            id: item.id,
            partitionKey: item.partitionKey,
            sortKey: item.sortKey,
            customIdentifier: item.customIdentifier || item.jobNumber || '',
            jobNumber: item.jobNumber,
            jobSortKey: item.sortKey,
            customerId: item.customerId,
            customerSortKey: item.customerSortKey,
            customerName: customerName || item.customerName,
            customerPropertyName: propertyName || item.customerPropertyName,
            customerPropertyId: item.customerPropertyId,
            customerPropertySortKey: item.customerPropertySortKey,
            issueDescription: item.issueDescription,
            jobTypeName: item.jobTypeName,
            jobTags,
            createdDate: item.createdDate,
            amountQuoted: item.amountQuoted,
            amountNotToExceed: item.amountNotToExceed,
            status: this.capitalizeFirstLetter(item.status),
            createdBy: item.createdBy,
            ownerValue: ownerValue || '',
            departmentNames,
            propertyAddress: fullAddress,
            age,
            noOfVisits: formattedVisits.visitDetails.length,
            visits: formattedVisits,
            latestScheduled: (scheduleArray && this.getLatestSchedule(scheduleArray)) || '',
            outstandingBalance: item.outstandingBalanceComputed,
            overdueBalance: item.overdueBalanceComputed,
            jobTypeInternal: item.jobTypeInternal
          });
        });
      }

      if (result.nextToken) {
        formattedResult.nextToken = result.nextToken;
      }
    }
    return formattedResult;
  };

  getMaintenanceRecordsByProperty = async (
    partitionKey,
    sortKey,
    filter,
    limit,
    offset,
    sortBy,
    sortOrder
  ) => {
    const params = {
      partitionKey,
      sortKey,
      filter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'dueDate',
          sortDirection: sortOrder || 'asc'
        }
      ]
    };

    let result;
    const response = await this.api.query(getMaintenanceRecordsByProperty, params);
    const formattedResult = { items: [] };
    if (response && response.data) {
      result = response.data.getCustomerProperty.job;
      if (result?.items?.length) {
        result.items.forEach(item => {
          const interval = item?.maintenanceTemplate?.schedule;
          const jobTags = this.getMappedEntityTagname(item.jobJobTags);
          const taskItems =
            item?.jobTasks?.items.map(jobTask => jobTask.task).filter(task => task.isActive) || [];
          const tasks = `${taskItems.length} Tasks`;
          let partsCount = 0;
          if (taskItems.length) {
            taskItems.forEach(ti => {
              partsCount += ti.taskEntries.items.length;
            });
          }
          const partsAndMaterials = `${partsCount} Parts and Materials`;

          formattedResult.items.push({
            id: item.id,
            partitionKey: item.partitionKey,
            maintenanceNumber: item.jobNumber,
            agreementName: item.serviceAgreement?.agreementName,
            agreementId: item.serviceAgreement?.id,
            dueDate: item.dueDate,
            interval,
            tags: jobTags,
            status: item.status,
            numTasks: tasks,
            numParts: partsAndMaterials
          });
        });
      }
      if (result.nextToken) {
        formattedResult.nextToken = result.nextToken;
      }
    }
    return formattedResult;
  };

  getTasksByCustomerPropertyById = async (id, filter, limit, offset, sortBy, sortOrder) => {
    const { booleanFilters, ...rest } = filter || {};
    let customizedFilter = {
      booleanFilters: [
        { fieldName: 'Task.isActive', filterInput: { eq: true } },
        { fieldName: 'Task.taskTypeInternal', filterInput: { eq: 'Recommended' } },
        { fieldName: 'Task.status', filterInput: { eq: 'Open' } }
      ]
    };

    let combinedBooleanFilterArr = customizedFilter.booleanFilters;
    if (booleanFilters) {
      combinedBooleanFilterArr = combinedBooleanFilterArr.concat(booleanFilters);
    }
    customizedFilter.booleanFilters = combinedBooleanFilterArr;

    if (rest) {
      customizedFilter = { ...customizedFilter, ...rest };
    }
    const params = {
      id,
      filter: customizedFilter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'taskNumber',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };
    const response = await this.api.query(getTasksByCustomerPropertyById, params);
    const formattedResult = { items: [] };
    if (response?.data?.getCustomerPropertyById) {
      const result = response.data.getCustomerPropertyById.tasks;

      result.items.forEach(item => {
        let totalValue = 0.0;
        if (item.total && item.total.items && item.total.items.length > 0) {
          totalValue = item.total.items.reduce((a, b) => (a.total || 0) + (b.total || 0), 0);
          totalValue = typeof totalValue === 'string' ? parseFloat(totalValue) : totalValue;
        }
        formattedResult.items.push({
          id: item.id,
          version: item.version,
          taskNumber: item.taskNumber,
          name: item.name,
          nextDueDate: item.nextDueDate,
          departmentId: item.departmentId,
          departmentValue: item.departmentValue,
          taskTypeId: item.taskTypeId,
          taskTypeValue: item.taskTypeValue,
          jobTypeId: item.jobTypeId,
          jobTypeValue: item.jobTypeValue,
          assetId: item.assetId,
          assetValue: item.assetValue,
          description: item.description,
          productBundleValue: item.productBundleValue,
          productBundleId: item.productBundleId,
          isActive: item.isActive,
          totalPrice: totalValue || 0.0,
          countOfProducts: item?.products?.nextToken || 0,
          products: item?.products?.items || [],
          status: item.status,
          createdBy: item.createdBy,
          createdDateTime: item.createdDateTime,
          lastUpdatedDateTime: item.lastUpdatedDateTime,
          lastUpdatedBy: item.lastUpdatedBy,
          jobs: item.jobs,
          quoteNumber: item.quote?.quoteNumber,
          customIdentifier: item.quote?.customIdentifier,
          quoteId: item.quote?.id,
          auditLogEntries: item.auditLogEntries
        });
      });
      if (result.nextToken) {
        formattedResult.nextToken = result.nextToken;
      }
    }
    return formattedResult;
  };

  getTaskById = async id => {
    const params = {
      id
    };
    const response = await this.api.query(getTaskById, params);
    const formattedResult = { items: [] };
    if (response?.data?.getTaskById) {
      const result = response.data.getTaskById;
      if (result?.products?.items) {
        formattedResult.items = result.products.items.map(item => ({
          id: item.id,
          version: item.version,
          entityType: item.entityType,
          name: item.name,
          description: item.description,
          unitCost: item.unitCost,
          quantity: item.quantity,
          productId: item.productId,
          taxable: item.taxable
        }));
      }

      if (result.nextToken) {
        formattedResult.nextToken = result.nextToken;
      }
    }
    return formattedResult;
  };

  mutateProperty = async values => {
    const structuredValues = propertySchema(values);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };

    const response = await this.api.mutate(mutationQuery, dataSet);
    return response;
  };

  deactivateProperty = async values => {
    const propertyData = {};
    propertyData.status = 'inactive';
    propertyData.isActive = false;
    propertyData.sortKey = values.sortKey;
    propertyData.version = values.version;

    const params = {
      input: propertyData
    };
    if (propertyData.id) {
      delete propertyData.id;
    }
    const response = await this.api.mutate(updateProperty, params);
    return response;
  };

  togglePropertyActivity = async values => {
    const params = {
      input: values
    };
    const response = await this.api.mutate(updateProperty, params);
    return response;
  };

  updateCustomerPropertyAndRelated = async (
    partitionKey,
    formData,
    customerId,
    syncImmedietlySettingEnabled
  ) => {
    const payload = transformToCustomerPropertySchema(
      formData,
      customerId,
      syncImmedietlySettingEnabled
    );
    const params = {
      partitionKey,
      data: payload
    };

    const response = await this.api.mutate(updateCustomerPropertyAndRelated, params);
    return response?.data?.updateCustomerPropertyAndRelated;
  };

  addCustomerPropertiesToCustomer = async (
    partitionKey,
    formData,
    customerId,
    syncImmediatelySettingEnabled
  ) => {
    const payload = transformToCustomerPropertySchema(
      formData,
      customerId,
      syncImmediatelySettingEnabled,
      true
    );
    const params = {
      partitionKey,
      data: payload
    };

    const response = await this.api.mutate(addCustomerPropertiesToCustomer, params);
    return response?.data?.addCustomerPropertiesToCustomer;
  };

  mutateTenantRep = async values => {
    const structuredValues = tenantRepSchema(values);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };

    const response = await this.api.mutate(mutationQuery, dataSet);
    return response;
  };

  mutatePropertyAsset = async values => {
    const structuredValues = propertyAssetSchema(values);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };

    const { data } = await this.api.mutate(savePropertyAssets, dataSet);
    return data.savePropertyAssets;
  };

  mutatePropertyAttachment = async values => {
    const structuredValues = attachmentSchema(values);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };

    const { data } = await this.api.mutate(savePropertyAttachments, dataSet);
    return data.savePropertyAttachments;
  };

  mutatePropertyNotes = async values => {
    const structuredValues = noteMutationSchema(values);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };

    const { data } = await this.api.mutate(savePropertyNotes, dataSet);
    return data.savePropertyNotes;
  };

  deleteCustomerRep = async (partitionKey, sortKey, invertedSortKey) =>
    this.CommonService.deleteSystemEntityMap(partitionKey, sortKey, invertedSortKey);

  deleteTenantRep = async (partitionKey, sortKey, invertedSortKey) =>
    this.CommonService.deleteSystemEntityMap(partitionKey, sortKey, invertedSortKey);

  deletePropertyAsset = async (partitionKey, id) => {
    const input = {
      partitionKey,
      id
    };

    const response = await this.api.mutate(deletePropertyAssetById, input);
    return response;
  };

  propertyNoteUpdated = async tenantId => {
    const params = { partitionKey: tenantId };
    return this.subscriptionClient.subscribe({
      query: propertyNoteUpdateNotification,
      variables: params
    });
  };

  addNotesToCustomerProperty = async (partitionKey, values) => {
    const payload = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(addNotesToCustomerProperty, payload);
    return response;
  };

  propertyAssetUpdated = async tenantId => {
    const params = { partitionKey: tenantId };
    return this.subscriptionClient.subscribe({
      query: propertyAssetUpdateNotification,
      variables: params
    });
  };

  propertyAttachmentUpdated = async tenantId => {
    const params = { partitionKey: tenantId };
    return this.subscriptionClient.subscribe({
      query: propertyAttachmentUpdateNotification,
      variables: params
    });
  };

  getTaskNumber = async () => {
    const response = await this.api.client.query({
      query: getTaskNumber,
      fetchPolicy: 'network-only'
    });
    return response;
  };

  addTasksToCustomerProperty = async (partitionKey, values) => {
    const dataSet = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(addTasksToCustomerProperty, dataSet);
    return response;
  };

  updateTask = async (partitionKey, values) => {
    const dataSet = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(updateTask, dataSet);
    return response;
  };

  addTaskEntriesToTask = async (partitionKey, values) => {
    const dataSet = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(addTaskEntriesToTask, dataSet);
    return response;
  };

  updateTaskEntriesForTask = async (partitionKey, values) => {
    const dataSet = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(updateTaskEntriesForTask, dataSet);
    return response;
  };

  deleteTaskFromProperty = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };

    const { data } = await this.api.mutate(deleteTask, params);
    return data && data.softDeleteTask;
  };

  deleteTaskEntryFromTask = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };

    const { data } = await this.api.mutate(deleteTaskEntry, params);
    return data && data.deleteTaskEntry;
  };

  getCustomerPropertyAddresses = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getAddressesForCustomerProperty, params);
    return response;
  };

  getAuditLogsByCustomerPropertyById = async idValue => {
    const params = {
      id: idValue
    };
    const { data, error } = await this.api.query(getAuditLogsByCustomerPropertyById, params);
    if (data && data.getCustomerPropertyById && data.getCustomerPropertyById.auditLogs) {
      return data.getCustomerPropertyById.auditLogs.items || [];
    }
    if (error) {
      throw error;
    }
  };

  updatePropertyAsset = async formData => {
    const cleaned = (({
      modelNumber,
      assetName,
      make,
      serialNo,
      installDate,
      imageUrl,
      sortOrder,
      file,
      id,
      version,
      location,
      assetTypeId,
      propertyZone,
      ownerIdentifier,
      internalIdentifier
    }) => ({
      modelNumber,
      assetName,
      make,
      serialNo,
      installDate,
      imageUrl,
      sortOrder,
      file,
      id,
      version,
      location,
      assetTypeId,
      propertyZone,
      ownerIdentifier,
      internalIdentifier
    }))(formData);
    const input = {
      partitionKey: formData.partitionKey,
      data: cleaned
    };

    const response = await this.api.mutate(updatePropertyAsset, input);
    return response;
  };

  getPropertyAssetById = async id => {
    const params = {
      id
    };
    const response = await this.api.query(getPropertyAssetById, params);
    if (!response || !response.data || !response.data.getPropertyAssetById) return response;

    const data = response.data.getPropertyAssetById;
    data.warranties.items = data.warranties.items.filter(
      warranty => warranty.isActive === true || warranty.isActive === null
    );
    return data;
  };

  addWarrantyOfPropertyAsset = async ({ propertyAssetId, data }) => {
    const cleaned = (({ startDate, endDate, warrantyInfo }) => ({
      startDate,
      endDate,
      warrantyInfo
    }))(data);
    const input = {
      partitionKey: data.partitionKey,
      data: {
        propertyAssetId,
        warranties: [cleaned]
      }
    };

    const response = await this.api.mutate(addWarrantiesToPropertyAsset, input);
    return response;
  };

  updateWarrantyOfPropertyAsset = async data => {
    const cleaned = (({ startDate, endDate, warrantyInfo, id, version }) => ({
      startDate,
      endDate,
      warrantyInfo,
      id,
      version
    }))(data);
    const input = {
      partitionKey: data.partitionKey,
      data: cleaned
    };

    const response = await this.api.mutate(updateWarranty, input);
    return response;
  };

  deleteWarrantyOfPropertyAsset = async id => {
    const input = {
      id
    };

    const response = await this.api.mutate(softDeleteWarranty, input);
    return response;
  };

  addAttachmentOfPropertyAsset = async ({ propertyAssetId, data }) => {
    const cleaned = (({ customFileName, fileName, fileUrl, type, comment }) => ({
      customFileName,
      fileName,
      fileUrl,
      type,
      comment
    }))(data);
    const input = {
      partitionKey: data.partitionKey,
      data: {
        propertyAssetId,
        attachments: [cleaned]
      }
    };

    const response = await this.api.mutate(addAttachmentsToPropertyAsset, input);
    return response;
  };

  updateAttachmentOfPropertyAsset = async data => {
    const cleaned = (({
      id,
      version,
      customFileName,
      fileName,
      description,
      fileUrl,
      type,
      comment
    }) => ({
      id,
      version,
      customFileName,
      fileName,
      description,
      fileUrl,
      type,
      comment
    }))(data);
    const input = {
      partitionKey: data.partitionKey,
      data: cleaned
    };

    const response = await this.api.mutate(updateAttachment, input);
    return response;
  };

  deleteAttachmentOfPropertyAsset = async id => {
    const input = {
      id
    };

    const response = await this.api.mutate(deleteAttachmentById, input);
    return response;
  };

  addNoteOfPropertyAsset = async ({ propertyAssetId, data }) => {
    const cleaned = (({ note, subject }) => ({
      note,
      subject
    }))(data);
    const input = {
      partitionKey: data.partitionKey,
      data: {
        propertyAssetId,
        notes: [cleaned]
      }
    };

    const response = await this.api.mutate(addNotesToPropertyAsset, input);
    return response;
  };

  updateNoteOfPropertyAsset = async data => {
    const cleaned = (({ id, version, note, subject }) => ({
      id,
      version,
      note,
      subject
    }))(data);
    const input = {
      partitionKey: data.partitionKey,
      data: cleaned
    };

    const response = await this.api.mutate(updateNote, input);
    return response;
  };

  deleteNoteOfPropertyAsset = async id => {
    const input = {
      id
    };

    const response = await this.api.mutate(deleteNoteById, input);
    return response;
  };

  assignCustomerRepsToCustomerProperty = async (customerPropertyId, customerRepIdsArray) => {
    const params = {
      data: {
        customerPropertyId,
        customerRepIds: customerRepIdsArray
      }
    };
    const response = await this.api.mutate(assignCustomerRepsToProperty, params);
    return response;
  };

  updateCustomerRepOnProperty = async values => {
    const payload = customerRepSchema(values);
    const params = {
      paritionKey: values.parent.tenantId,
      data: {
        customerId: values.parent.customerId || values.parent.id,
        customerPropertyId: values.parent.customerPropertyId,
        customerRep: payload
      }
    };

    const response = await this.api.mutate(updateCustomerRepOnProperty, params);
    return response;
  };
}
