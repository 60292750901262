/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { CompanyService } from 'services/core';
import Grid from '@material-ui/core/Grid';
import StorageService from 'services/StorageService';
import { Logger } from 'services/Logger';
import { formBuilderPageTitleButtons } from 'meta/Settings/Forms';
import { PageHeader, Divider, Spinner, FormBuilder } from 'components';
import Labels from 'meta/labels';
import isTouchDevice from 'components/DnDProvider/isTouchDevice';
import { setPageMode, snackbarOn } from 'redux/actions/globalActions';
import FormPropertiesModal from '../FormPropertiesModal';

class FormBuilderPage extends Component {
  constructor(props) {
    super(props);
    const { mode } = props.computedMatch.params;
    this.CompanyService = new CompanyService();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.formBuilderPageTitle = [
      { link: '/settings/forms', title: Labels.Forms[this.props.user.locale] }
    ];

    this.useTouch = isTouchDevice();

    const { search } = this.props.history.location;
    const isAdmin = search && search.toLowerCase().indexOf('isadmin') !== -1;

    this.state = {
      name: '',
      description: '',
      associatedEntityType: 'Job',
      viewType: 'Document',
      formDefinitionJson: '',
      assets: {},
      showFormPropertiesModal: false,
      isFormLoading: mode !== 'new',
      isSaving: false,
      availableHeight: 0,
      isAdmin: isAdmin || false
    };
  }

  componentDidMount() {
    const { id, mode } = this.props.computedMatch.params;
    if (mode !== 'new' && this.props.user.tenantId) {
      this.getForm(id, mode);
    } else {
      this.setState({ showFormPropertiesModal: true });
    }
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  getForm = async (id, mode) => {
    try {
      const sortKey = `${this.props.user.tenantId}_${this.props.user.tenantCompanyId}_Form_${id}`;
      const { data } = await this.CompanyService.getForm(this.props.user.tenantId, sortKey);

      if (data) {
        const {
          name,
          description,
          associatedEntityType,
          viewType,
          latestPublishedFormDefinition,
          latestDraftFormDefinition,
          version
        } = data.getForm;

        const { formDefinitionJson } = latestDraftFormDefinition || latestPublishedFormDefinition;
        const assetMap = {};
        let formMetaData;
        let formAssetList;
        try {
          formMetaData = JSON.parse(formDefinitionJson);
          formAssetList = Array.isArray(formMetaData) ? formMetaData[2] : formMetaData.assetMap;
        } catch (e) {
          return {};
        }

        if (formAssetList) {
          const retrievedAssets = await this.getAllFormAssets(formAssetList);
          retrievedAssets.forEach(item => {
            assetMap[item.id] = item.itemSources;
          });
        }

        this.setState({
          name: mode === 'copy' ? `${name}(Copy)` : name,
          description,
          associatedEntityType,
          viewType,
          assets: assetMap,
          version: version || 0,
          formDefinitionJson: formMetaData,
          isFormLoading: false
        });

        return data;
      }
    } catch (error) {
      Logger.error(`Error in fetching form ${JSON.stringify(error)}`);
      this.props.snackbarOn('error', 'Unable to fetch form, please try again later', error);
    }
    return [];
  };

  updateFormProperties = formValues => {
    this.setState(formValues);
    this.closeFormPropertiesModal();
  };

  updateFormData = () => {
    const { name, associatedEntityType } = this.state;
    let canSave = true;

    if (!name) {
      this.props.snackbarOn('error', 'Forms must have a Name.');
      canSave = false;
    }

    if (!associatedEntityType) {
      this.props.snackbarOn('error', 'Forms must be associated with a type.');
      canSave = false;
    }

    this.setState({ isSaving: canSave });
  };

  submitForm = async formBuilderData => {
    const { meta, assetList, ...formMetaData } = formBuilderData;
    let assetMap = {};
    if (assetList.length) {
      const uploadList = assetList.filter(file => file.new);
      const uploadedAssets = await this.uploadAssets(uploadList);
      const newAssetsList = [...assetList, ...uploadedAssets];
      assetMap = newAssetsList.reduce((map, item) => {
        const newMap = { ...map };
        const { id, itemSources } = item;
        newMap[id] = itemSources;
        return newMap;
      }, {});
    }
    const formMetaDataWithAssets = this.swapFormAssetsURLs(meta, assetMap);
    this.saveForm({ ...formMetaDataWithAssets, ...formMetaData });
  };

  saveForm = async formMetaData => {
    const { name, description, associatedEntityType, viewType, version } = this.state;
    const { tenantId, tenantCompanyId } = this.props.user;
    const { id, mode } = this.props.computedMatch.params;
    const payload = {
      name,
      description,
      associatedEntityType,
      viewType,
      formDefinitionJson: JSON.stringify(formMetaData),
      publish: false,
      tenantId,
      tenantCompanyId
    };
    try {
      if (mode === 'edit') {
        payload.id = id;
        payload.version = version || 0;
        const { data } = await this.CompanyService.updateForm(payload);
        if (data) {
          this.props.snackbarOn('success', 'Successfully updated form draft.');
        }
      } else {
        const { data } = await this.CompanyService.addForm(payload);
        if (data) {
          this.props.snackbarOn('success', 'Successfully created form as draft.');
        }
      }
      this.props.history.push('/settings/forms');
    } catch (error) {
      Logger.error(`Error in fetching forms ${JSON.stringify(error)}`);
      this.props.snackbarOn('error', 'Unable to create forms, please try again later', error);
      this.setState({ isSaving: false });
    }
    this.setState({ isSaving: false });
  };

  uploadFile = async file => {
    const { tenantId } = this.props.user;
    if (!file) return '';
    try {
      const storageService = new StorageService();
      const filename = `${tenantId}/${Date.now()}-FB-formAsset-${file.name}`;
      const fileUrl = await storageService.uploadFile(file, filename);
      return fileUrl;
    } catch (error) {
      Logger.info(`Error uploading image ${error}`);
      return '';
    }
  };

  uploadAssets = async fileList => {
    const promises = fileList.map(async itemObj => {
      const { id, itemSources } = itemObj;
      const updatedItemSources = await Promise.all(
        itemSources.map(async fileObj => {
          const { file, ...updatedFileObj } = fileObj;
          updatedFileObj.fileUrl = await this.uploadFile(file);
          return updatedFileObj;
        })
      );
      return { id, itemSources: updatedItemSources };
    });
    const newFiles = await Promise.all(promises);
    return newFiles;
  };

  getAssetUrl = async fileName => {
    if (!fileName) return '';
    try {
      const storageService = new StorageService();
      return await storageService.getFile(fileName);
    } catch (error) {
      Logger.error(`Error reading image ${error}`);
    }
    return '';
  };

  getAllFormAssets = async fileMap => {
    const keys = Object.keys(fileMap);
    if (keys === 0) return fileMap;

    const promises = keys.map(async key => {
      const itemSources = fileMap[key];
      const updatedItemSources = await Promise.all(
        itemSources.map(async asset => {
          const { label, fileUrl } = asset;
          return { label, fileUrl: await this.getAssetUrl(fileUrl) };
        })
      );
      return { id: key, itemSources: updatedItemSources };
    });
    const newFiles = await Promise.all(promises);
    return newFiles;
  };

  swapFormAssetsURLs = (meta, assetMap) => {
    const [layout, data] = meta;
    const newAssetMap = { ...assetMap };
    const { layouts } = layout;
    const { contents } = layouts.default;

    for (const row of contents) {
      for (const column of row.contents) {
        const itemContent = column.contents[0];
        if (newAssetMap[itemContent.source]) {
          itemContent.options.source = newAssetMap[itemContent.source];
        }
      }
    }
    return { meta: [layout, data], assetMap: newAssetMap };
  };

  openFormPropertiesModal = () => this.setState({ showFormPropertiesModal: true });

  closeFormPropertiesModal = () => this.setState({ showFormPropertiesModal: false });

  updateWindowDimensions() {
    this.setState({ availableHeight: Math.max(window.innerHeight - 250, 400) });
  }

  render() {
    const { mode } = this.props.computedMatch.params;

    const buttonMode = mode !== 'new' ? 'edit' : mode;
    const {
      name,
      description,
      associatedEntityType,
      viewType,
      formDefinitionJson,
      assets,
      showFormPropertiesModal,
      isFormLoading,
      isSaving,
      availableHeight,
      isAdmin
    } = this.state;

    formBuilderPageTitleButtons.save.action = this.updateFormData;
    formBuilderPageTitleButtons.cancel.action = () => this.props.history.goBack();

    return (
      <>
        <PageHeader
          title={Labels.FormBuilder[this.props.user.locale]}
          breadcrumbsArray={this.formBuilderPageTitle}
          buttons={formBuilderPageTitleButtons}
          mode={buttonMode}
          buttonClicked={isSaving}
          showButtons
        />
        <Divider />
        <Grid container direction="row" spacing={3} style={{ height: availableHeight }}>
          {isFormLoading ? (
            <Spinner />
          ) : (
            <FormBuilder
              name={name}
              description={description}
              associatedEntityType={associatedEntityType}
              formDefinitionJson={formDefinitionJson}
              assets={assets}
              updateFormProperties={this.openFormPropertiesModal}
              updateMetaLayout={this.updateFormData}
              isUpdating={isSaving}
              windowHeight={availableHeight}
              onComplete={formData => this.submitForm(formData)}
              isTouchDevice={this.useTouch}
              developerVersion={isAdmin}
            />
          )}
        </Grid>
        {showFormPropertiesModal && (
          <FormPropertiesModal
            name={name}
            description={description}
            associatedEntityType={associatedEntityType}
            viewType={viewType}
            open={showFormPropertiesModal}
            confirm={this.updateFormProperties}
            cancel={this.closeFormPropertiesModal}
            isAdmin={isAdmin}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});

const mapFormBuilderToProps = dispatch => ({
  changePageMode: pageMode => dispatch(setPageMode(pageMode)),
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const reduxConnectedFormBuilder = compose(
  withRouter,
  connect(mapStateToProps, mapFormBuilderToProps)
)(FormBuilderPage);

export default reduxConnectedFormBuilder;
