const dailyReportTableMeta = (projectMode, projectId) => {
  return [
    {
      id: 'number',
      label: 'Number',
      showLink: 'view',
      linkPath: `/project/${projectMode}/${projectId}/fieldreport/dailyreport`,
      linkReference: 'id',
      type: 'number',
      numeric: true
    },
    {
      id: 'date',
      label: 'Date',
      type: 'dateInCompanyTZ',
      isCustom: true
    },
    {
      id: 'employeeCount',
      label: '# of Employees',
      type: 'number'
    },
    {
      id: 'totalHoursSum',
      type: 'number',
      label: 'Total Hours'
    },
    {
      id: 'unproductiveHoursSum',
      type: 'number',
      label: 'Unproductive Hours'
    },
    {
      id: 'workPerformedText',
      label: 'Work Performed',
      type: 'string'
    }
  ];
};

export default dailyReportTableMeta;
