import gql from 'graphql-tag';

const getVisitsInRangePastWithCache = gql`
  query getVisitsInRangePastWithCache(
    $partitionKey: String!
    $sortKey: String!
    $startTime: Int!
    $endTime: Int!
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      visitsInRange(
        entityConnection: "Visit"
        rangeFilter: { startTime: $startTime, endTime: $endTime }
        filter: { status: { in: ["Complete", "Working", "On hold", "Converted"] } }
      ) {
        items {
          id
          sortKey
          version
        }
      }
    }
  }
`;

export default getVisitsInRangePastWithCache;
