import { AppConstants, PermissionConstants } from 'utils/AppConstants';
import { CommonService } from 'services/core';

const customFieldPrefix = 'Custom_';

export const getHeaderButtons = (data, mode, showServiceAgreement) => {
  if (mode !== 'view') return {};
  const buttons = {};
  if (!(data.isActive || data.isActive === null)) {
    buttons.activate = {
      label: 'Activate Customer',
      caslKey: PermissionConstants.OBJECT_CUSTOMER,
      caslAction: 'edit',
      icon: 'CheckCircleOutlined'
    };
  } else {
    buttons.deactivate = {
      label: 'Deactivate Customer',
      caslKey: PermissionConstants.OBJECT_CUSTOMER,
      caslAction: 'edit',
      icon: 'Block'
    };
  }
  if (data.status?.toLowerCase() === AppConstants.ACTIVE && data.noOfProperties > 0) {
    buttons.createJob = {
      label: 'Create Job',
      caslKey: PermissionConstants.OBJECT_JOB,
      caslAction: 'create',
      icon: 'jobsIcon',
      lookUpIconMap: true
    };
    if (showServiceAgreement) {
      buttons.createServiceAgreement = {
        label: 'New Agreement',
        color: 'primary',
        caslKey: PermissionConstants.OBJECT_SERVICE_AGREEMENT,
        caslAction: 'create',
        icon: 'serviceAgreementIcon',
        lookUpIconMap: true
      };
    }
  }
  return buttons;
};

export const convertInlineForm = (formObj = {}) => {
  const { meta, searchableFields } = formObj;

  // when no inline form is defined return
  if (!meta) return {};

  const formMeta = meta?.[0];
  const { fields, layouts, validation, validationErrors } = formMeta;
  /**
   * To support exisitng query structure
   * 
   * Converting fields meta from 
   *    "custom_job_message": {
          "default": "custom_job_message",
          "edit": "custom_job_message"
        }"
        to 
        "custom_job_message": {
          default: 'custom_text1'
        }
   */
  const customizedFields = {};
  Object.keys(fields).forEach(fieldItem => {
    customizedFields[fieldItem] = { default: `${customFieldPrefix}${searchableFields[fieldItem]}` };
  });

  const convertedValidationProperties = {};
  Object.keys(validation?.properties || {}).forEach(fieldItem => {
    convertedValidationProperties[`${customFieldPrefix}${searchableFields[fieldItem]}`] =
      validation.properties[fieldItem];
  });

  const convertedRequiredValidation = validation?.required?.map(
    item => `${customFieldPrefix}${searchableFields[item]}`
  );

  const convertedValidationErrorMessage = {};
  Object.keys(validationErrors || {}).forEach(fieldItem => {
    convertedValidationErrorMessage[`${customFieldPrefix}${searchableFields[fieldItem]}`] =
      validationErrors[fieldItem];
  });

  const customizedLayoutArr = layouts.default.contents;
  return {
    fields: customizedFields,
    componentArr: customizedLayoutArr,
    validation: {
      ...validation,
      properties: convertedValidationProperties,
      required: convertedRequiredValidation
    },
    validationErrors: { ...convertedValidationErrorMessage }
  };
};

export const fetchInlineForm = async (tenantId, companySortKey, entityName) => {
  const CommonServiceObj = new CommonService();
  const { data } = await CommonServiceObj.getFormsAvailableToEntity(
    tenantId,
    companySortKey,
    entityName
  );

  const form = data.getCompany.forms.items.find(item => item?.viewType === 'Inline');
  const formMeta = form?.latestPublishedFormDefinition?.formDefinitionJson || '{}';

  return {
    formAttributes: {
      formDefinitionSortKey: form?.latestPublishedFormDefinitionSortKey || '',
      formSortKey: form?.sortKey || '',
      formId: form?.id || '',
      formDefinitionId: form?.latestPublishedFormDefinition?.id || ''
    },
    inlineForm: {
      formMeta: convertInlineForm(JSON.parse(formMeta)),
      form
    }
  };
};
