import { EnumType } from 'utils/constants';

const submittalItemRowMeta = [
  {
    id: 'specSection',
    label: 'Spec',
    type: 'string'
  },
  {
    id: 'subSection',
    label: 'Sub Section',
    type: 'string'
  },
  /*
  {
    id: 'version',
    label: 'Version'
  },
  */
  {
    id: 'description',
    label: 'Description',
    type: 'string'
  },
  {
    id: 'type',
    label: 'Type',
    type: 'string'
  },
  {
    id: 'status',
    label: 'Status',
    enumType: EnumType.PROJECT_MANAGEMENT_SUBMITTAL_STATUS
  },
  {
    id: 'submitBy',
    label: 'Submit By',
    type: 'date'
  },
  {
    id: 'packageNumber',
    label: 'Package',
    showLink: 'view',
    linkPath: 'submittal',
    linkReference: 'packageId'
  }
];

export default submittalItemRowMeta;
