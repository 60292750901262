import gql from 'graphql-tag';

const updateCustomerTags = gql`
  mutation updateCustomerAndRelated($data: UpdateCustomerAndRelatedInput!) {
    updateCustomerAndRelated(data: $data) {
      id
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      version
      status
      syncStatus
      syncLog
      isTaxable
      isActive
      customerTags(entityConnection: "CustomerTag") {
        items {
          id
          version
          mappedEntityId
          mappedEntityType
          mappedEntityValue
          systemEntityId
          systemEntityType
          hierarchy
          sortKey
          invertedSortKey
          partitionKey
          mappedEntity {
            id
            ... on CustomerTag {
              tagType
              tagName
              sortKey
              partitionKey
              hierarchy
              id
              entityType
            }
          }
        }
      }
    }
  }
`;

export default updateCustomerTags;
