import { useTheme } from '@material-ui/core';

export const useStyles = () => {
  const theme = useTheme();
  const indent = theme.spacing(3);
  return {
    container: {
      borderBottom: `1px solid ${theme.palette.grayscale(85)}`,
      padding: `${indent}px 0px`
    },
    title: { marginBottom: indent }
  };
};
