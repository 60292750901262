import React from 'react';
import { shape, arrayOf, string } from 'prop-types';
import { Typography, Field, FieldType } from '@buildhero/sergeant';

export default function Refunds({ refunds }) {
  if (!refunds?.length) return null;

  return (
    <Typography style={{ display: 'flex' }}>
      Refunded by Refund{refunds.length === 1 ? ' ' : 's '}
      {refunds.map((r, i) => (
        <>
          <Field key={r.to} type={FieldType.LINK} value={r} />
          {i !== refunds.length - 1 && ', '}
        </>
      ))}
    </Typography>
  );
}

Refunds.propTypes = shape({
  refunds: arrayOf(
    shape({
      label: string,
      to: string
    })
  )
});
