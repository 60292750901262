import gql from 'graphql-tag';

const bulkUpdateTimesheetEntries = gql`
  mutation bulkUpdateTimesheetEntries(
    $partitionKey: String
    $data: AddTimesheetEntriesToEmployeeInput!
  ) {
    bulkUpdateTimesheetEntries(partitionKey: $partitionKey, data: $data) {
      id
      version
    }
  }
`;

export default bulkUpdateTimesheetEntries;
