import uuid from 'uuid';
import removeObjProperties from 'utils/removeObjProperties';

export const addHourTypeToMeta = (tableMeta, hourTypes = []) =>
  hourTypes.reduce(
    (existingMeta, type) => {
      const { hourType, id } = type;
      const meta = {
        id: uuid.v4(),
        hourType: hourType.hourType,
        label: `${hourType} Rate`,
        billingHourTypeId: id,
        isCustom: true,
        numeric: true,
        disableSort: true,
        type: 'RateText'
      };
      existingMeta.push(meta);
      return existingMeta;
    },
    [...tableMeta]
  );

export const constructLabourData = ({
  costCodes,
  jobCostTypes,
  labourTypes = [],
  billingHourTypes = [],
  labourRates = []
}) => {
  const details = labourTypes.map(type => {
    const { id: labourTypeId, ...labourType } = type;
    const notUsedFields = ['version', 'isActive', '__typename'];
    const costCodeDetail = costCodes.find(({ id }) => id === labourType.costCodeId);
    const jobCostTypeDetail = jobCostTypes.find(({ id }) => id === labourType.jobCostTypeId);

    const data = {
      costCodeName: costCodeDetail?.name,
      jobCostName: jobCostTypeDetail?.name,
      ...removeObjProperties(labourType, notUsedFields),
      labourTypeId
    };

    billingHourTypes.forEach(payrollHour => {
      const rateInfo = labourRates.find(
        labourRate =>
          labourRate.labourTypeId === labourTypeId &&
          labourRate.billingHourTypeId === payrollHour.id
      );
      if (rateInfo) {
        data[payrollHour.id] = rateInfo;
      }
    });
    return data;
  });
  return details;
};

export const getLaborRowsAndCols = (data, initialData = []) => {
  const laborMetadata = [
    {
      id: 'name',
      type: 'text',
      label: 'Labor Type'
    }
  ];

  const billingHourTypeItems = data?.getCompany?.billingHourTypes?.items || [];
  billingHourTypeItems.sort((a, b) => a.sortOrder - b.sortOrder);
  const laborTypeItems = data?.getCompany?.labourTypes?.items || [];
  laborTypeItems.sort((a, b) => a.sortOrder - b.sortOrder);

  const rows = [
    ...laborTypeItems.map(laborType => {
      const res = {
        id: laborType.id,
        name: laborType.name
      };
      billingHourTypeItems.forEach(billingHourType => {
        const cell =
          initialData.find(
            d => d.labourTypeId === laborType.id && d.billingHourTypeId === billingHourType.id
          ) || {};
        res[billingHourType.id] = {
          ...cell,
          labourTypeId: laborType.id,
          rate: cell.rate || 0,
          isActive: true
        };
      });
      return res;
    })
  ];

  const cols = [
    ...laborMetadata,
    ...billingHourTypeItems.map((item, i) => ({
      id: i,
      hourType: item.hourType,
      label: item.hourType,
      billingHourTypeId: item.id,
      isCustom: true,
      numeric: true,
      disableSort: true,
      type: 'RateText'
    }))
  ];

  return [rows, cols];
};
