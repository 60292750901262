import gql from 'graphql-tag';

const createProjectSubType = gql`
  mutation saveProjectSubTypes($partitionKey: String!, $data: SaveProjectSubTypesToCompanyInput!) {
    saveProjectSubTypes(partitionKey: $partitionKey, data: $data) {
      id
      name
      sortOrder
    }
  }
`;

export default createProjectSubType;
