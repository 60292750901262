import { getPriceBookById } from 'services/API/priceBook';
import { getPriceBookEntryOverridesByPriceBookId } from 'services/API/priceBookEntryOverride';
import { pb2OverrideArrayToMap } from 'utils/pricebooks';
import { logErrorWithCallback } from 'utils';

export const getPriceBook2Helper = async (priceBook2Id, snackbarOn) => {
  try {
    const [pb2, overrides] = await Promise.all([
      getPriceBookById(priceBook2Id),
      getPriceBookEntryOverridesByPriceBookId(priceBook2Id)
    ]);
    return { priceBook2: pb2?.data, overridesMap: pb2OverrideArrayToMap(overrides?.data) };
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to fetch pricebook, please try again later');
  }
  return { priceBook2: undefined, overridesMap: undefined };
};
