import React, { useState } from 'react';

import { calculateMarkupFromMargin } from '@buildhero/math';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import Labels from 'meta/labels';
import { sentryException } from 'services/Logger';
import { PricingStrategy } from 'utils/constants';
import { convertForMathLib } from 'utils/mathLibrary';

import { snackbarOn } from '../../../redux/actions/globalActions';
import { CompanyService } from '../../../services/core';
import { logErrorWithCallback } from '../../../utils';
import ModifyPricebookCommon from '../ModifyPricebook';

const pricebookWithMaterialMarkup = pricebook => {
  let materialRate;
  try {
    materialRate = JSON.parse(pricebook.materialMarkupJSON);
  } catch (e) {
    sentryException(e, 'Unable to parse material markup JSON');
    materialRate = undefined;
  }

  return {
    ...pricebook,
    description: pricebook.description || '',
    defaultMarkup: pricebook.markupValue,
    materialRate
  };
};

const EditPricebookPopup = ({ pricebook, open, handleClose, user, onSubmit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const marginEnabled = useSelector(s => s.settings.pricingStrategy === PricingStrategy.MARGIN);
  const markupAndMarginEnabled = useSelector(
    s => s.settings.pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN
  );

  const pricebookWithRanges = pricebookWithMaterialMarkup(pricebook);

  const handleEditPricebook = async data => {
    setIsSubmitting(true);
    const markupValue = marginEnabled
      ? convertForMathLib(calculateMarkupFromMargin, data.defaultMarkup)
      : data.defaultMarkup;

    try {
      const payload = {
        tenantId: user.tenantId,
        id: pricebook.id,
        name: data.name,
        description: data.description,
        markupType: data.markupType,
        markupValue: markupValue || 0,
        materialMarkupEntries: data.materialRate || null
      };

      const companyService = new CompanyService();
      await companyService.updatePriceBook(user.tenantId, payload);
      onSubmit();
    } catch (error) {
      logErrorWithCallback(error, snackbarOn, 'Unable to update pricebook, please try again later');
    } finally {
      setIsSubmitting(false);
      handleClose();
    }
  };

  return (
    <ModifyPricebookCommon
      title={Labels.editPricebook[user.locale]}
      open={open}
      handleClose={handleClose}
      user={user}
      pricebook={pricebookWithRanges}
      marginEnabled={marginEnabled}
      showLabourRateSection={false}
      showConfigSection={markupAndMarginEnabled}
      allowFormatChange
      onSubmit={handleEditPricebook}
      isSubmitting={isSubmitting}
    />
  );
};

EditPricebookPopup.propTypes = {
  pricebook: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    tenantCompanyId: PropTypes.string.isRequired,
    tenantId: PropTypes.string.isRequired
  }).isRequired,
  onSubmit: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = { snackbarOn };
export default connect(mapStateToProps, mapDispatchToProps)(EditPricebookPopup);
