import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { snackbarOn } from 'redux/actions/globalActions';
import PropTypes from 'prop-types';
import moment from 'moment';
import DefaultButton from 'components/Buttons/DefaultButton';
import withMultipleFormsDynamic from 'components/WithMultipleForms/dynamic';
import FullScreenModal from 'components/FullScreenModal';
import { useFetchDailyReport } from 'services/APIHooks/dailyReport';
import { useParams } from 'react-router-dom';
import DailyReportsForm from './DailyReportsForm';
import { useDailyReportDates } from './GenerateDailyReport.hooks';
import { useStyles } from './GenerateDailyReport.styles';

const GenerateDailyReport = props => {
  const {
    dailyReportId,
    open,
    handleClose,
    update,
    getHandleCreateService,
    setOnSubmitFinal,
    getHandleComplete,
    handleSubmitStart,
    getHandleRemoveService,
    getHandleRemoveAllServices
  } = props;
  const styles = useStyles();
  const { projectId } = useParams();
  const [isValidTimesheets, setIsValidTimesheets] = useState(true);
  const dailyReportDates = useDailyReportDates({ projectId, currentReportId: dailyReportId });
  const [submitting, setSubmitting] = useState(false);
  const [removeAllFormService, setRemoveAllFormService] = useState(false);
  const [{ loading: editReportLoading, data: editReportData }] = useFetchDailyReport(
    dailyReportId,
    {
      skip: !dailyReportId,
      depends: [open]
    }
  );

  const [reportDate, setReportDate] = useState(moment.unix().toDate());
  useEffect(() => {
    if (editReportData?.date) {
      setReportDate(moment.unix(editReportData.date).toDate());
    }
  }, [editReportData.date]);

  useEffect(() => {
    if (removeAllFormService) {
      getHandleRemoveAllServices();
    }
  }, [getHandleRemoveAllServices, removeAllFormService]);

  useEffect(() => {
    if (open) {
      setRemoveAllFormService(false);
    }
  }, [open]);

  return (
    <FullScreenModal
      title={dailyReportId ? 'Edit Daily Report' : 'Generate Daily Report'}
      open={open}
      handleClose={() => {
        setSubmitting(false);
        setRemoveAllFormService(true);
        handleClose();
      }}
      modalHeaderButtons={[
        <>
          {!isValidTimesheets && (
            <div css={styles.warning}>Please check if timesheets are correctly populated</div>
          )}
          <DefaultButton
            label="Save Daily Report"
            variant="containedPrimary"
            key="dailyreportsavebutton"
            disabled={submitting || !isValidTimesheets}
            showSpinner={submitting}
            onClick={handleSubmitStart}
          />
        </>
      ]}
    >
      <DailyReportsForm
        data={
          dailyReportId
            ? editReportData
            : {
                date: reportDate
              }
        }
        dailyReportDates={dailyReportDates}
        mode={dailyReportId ? 'edit' : 'default'}
        dailyReportId={dailyReportId}
        onSubmitComplete={() => {
          setSubmitting(false);
          setRemoveAllFormService(true);
          update();
          handleClose();
        }}
        onSubmitting={setSubmitting}
        loading={editReportLoading || submitting}
        loadingReport={editReportLoading}
        open={open}
        getHandleCreateService={getHandleCreateService}
        getHandleComplete={getHandleComplete}
        getHandleRemoveService={getHandleRemoveService}
        setOnSubmitFinal={setOnSubmitFinal}
        setIsValidTimesheets={setIsValidTimesheets}
      />
    </FullScreenModal>
  );
};

GenerateDailyReport.propTypes = {
  dailyReportId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  getHandleCreateService: PropTypes.func.isRequired,
  setOnSubmitFinal: PropTypes.func.isRequired,
  getHandleComplete: PropTypes.func.isRequired,
  handleSubmitStart: PropTypes.func.isRequired,
  getHandleRemoveService: PropTypes.func.isRequired,
  getHandleRemoveAllServices: PropTypes.func.isRequired
};
GenerateDailyReport.defaultProps = {
  dailyReportId: ''
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatcherToProps = { snackbarOn };
const withMultiple = withMultipleFormsDynamic(GenerateDailyReport);
export default connect(mapStateToProps, mapDispatcherToProps)(withMultiple);
