/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getItems(path) {
  return await instance
    .get(path)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function itemCreate(item, path) {
  return await instance
    .post(path, item)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function itemChange(id, item, path) {
  return await instance
    .put(`${path}/${id}`, item)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
export async function itemSearch(term, searchColumns, path) {
  if (!searchColumns || (Array.isArray(searchColumns) && searchColumns.length === 0)) {
    throw new Error("Can't use empty searchColumns array in generic search.");
  }
  if (term.length > 0) {
    return await instance
      .post(`${path}/${term}`, { searchColumns })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  }
  return await getItems(path);
}
