import gql from 'graphql-tag';

const addQuoteQuoteTagsToVersionedQuote = gql`
  mutation addQuoteQuoteTagsToVersionedQuote(
    $partitionKey: String
    $data: AddQuoteQuoteTagsToQuoteInput!
  ) {
    addQuoteQuoteTagsToVersionedQuote(partitionKey: $partitionKey, data: $data) {
      id
      quoteTag(entityConnection: "QuoteTag") {
        tagType
        tagName
        id
      }
    }
  }
`;

export default addQuoteQuoteTagsToVersionedQuote;
