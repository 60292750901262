import React from 'react';
import _ from 'lodash';
import { MUIForm } from '@buildhero/sergeant';
import {
  CrewsPropType,
  DepartmentsPropType,
  TechniciansPropType,
  PreferredTechniciansPropType
} from 'components/PreferredTechniciansForm/PropTypes';
import getLayout from './PreferredTechniciansSidebarViewLayout';

function getLayoutData({ preferredTechnicians, departments, crews, technicians }) {
  const {
    departmentId,
    crewId,
    primaryTechnicianId,
    additionalTechnicianIds
  } = preferredTechnicians;
  const department = _.find(departments, obj => obj?.id === departmentId)?.tagName;
  const crew = _.find(crews, obj => obj?.id === crewId)?.name;
  const primaryTechnician = _.find(technicians, obj => obj?.id === primaryTechnicianId)?.name;
  const additionalTechnicianIdsMap = _.reduce(
    additionalTechnicianIds,
    (result, id) => {
      return {
        ...result,
        [id]: true
      };
    },
    {}
  );
  const additionalTechnicians = _.filter(
    technicians,
    obj => obj?.id && additionalTechnicianIdsMap[obj?.id]
  )
    .map(obj => obj?.name)
    .join(', ');

  return {
    department,
    crew,
    primaryTechnician,
    additionalTechnicians
  };
}

const PreferredTechniciansSidebarView = ({
  preferredTechnicians,
  departments,
  crews,
  technicians
}) => {
  const data = getLayoutData({
    preferredTechnicians,
    departments,
    crews,
    technicians
  });
  const layout = getLayout();
  return <MUIForm layout="view" data={data} configuration={layout} onComplete={_.noop} />;
};

PreferredTechniciansSidebarView.propTypes = {
  preferredTechnicians: PreferredTechniciansPropType.isRequired,
  departments: DepartmentsPropType.isRequired,
  crews: CrewsPropType.isRequired,
  technicians: TechniciansPropType.isRequired
};

export default PreferredTechniciansSidebarView;
