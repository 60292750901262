import AmplifyService from 'services/AmplifyService';
import * as Query from '../../graphql/timeTracking/query';
import * as Mutations from '../../graphql/timeTracking/mutation';

export default class TimeTrackingService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
  }

  getTimesheetPeriodById = async (employee, timeperiodId) => {
    const { tenantId } = employee;
    const params = {
      partitionKey: tenantId,
      id: timeperiodId
    };

    const response = await this.api.query(Query.getTimesheetPeriodById, params);
    return response;
  };

  getTimesheetPeriods = async (partitionKey, sortKey, filter) => {
    const params = {
      partitionKey,
      sortKey,
      filter
    };

    const response = await this.api.query(Query.getTimesheetPeriods, params);
    return response;
  };

  getTimesheetByVisitId = async id => {
    const params = {
      id
    };

    const response = await this.api.query(Query.getTimesheetByVisitId, params);
    return response;
  };

  getTimesheetEntries = async (partitionKey, sortKey, filter) => {
    const params = {
      partitionKey,
      sortKey,
      filter
    };

    const response = await this.api.query(Query.getTimesheetEntries, params);
    return response;
  };

  getTimesheetPeriodsHourSummary = async (
    partitionKey,
    sortKey,
    timesheetPeriodFilter,
    dailyInput,
    overAllInput
  ) => {
    const params = {
      partitionKey,
      sortKey,
      timesheetPeriodFilter,
      dailyInput,
      overAllInput
    };

    const response = await this.api.query(Query.getTimesheetPeriodsHourSummary, params);
    return response;
  };

  addTimesheetDailyOverrides = async (partitionKey, data) => {
    const params = {
      partitionKey,
      data
    };
    const response = await this.api.mutate(Mutations.addTimesheetDailyOverrides, params);
    return response;
  };

  updateTimesheetDailyOverrides = async (partitionKey, data) => {
    const params = {
      data,
      partitionKey
    };
    const response = await this.api.mutate(Mutations.updateTimesheetDailyOverrides, params);
    return response;
  };

  approveTimesheetPeriod = async (partitionKey, data) => {
    const params = {
      data,
      partitionKey
    };
    const response = await this.api.mutate(Mutations.approveTimesheetPeriod, params);
    return response;
  };

  bulkUpdateTimesheetEntries = async (partitionKey, data) => {
    const params = {
      data,
      partitionKey
    };
    const response = await this.api.mutate(Mutations.bulkUpdateTimesheetEntries, params);
    return response;
  };

  reviewTimesheetEntries = async (partitionKey, data) => {
    const params = {
      data,
      partitionKey
    };
    const response = await this.api.mutate(Mutations.reviewTimesheetEntries, params);
    return response;
  };

  deleteTimesheetEntry = async (partitionKey, id) => {
    const params = {
      id,
      partitionKey
    };
    const response = await this.api.mutate(Mutations.deleteTimesheetEntry, params);
    return response;
  };
}
