import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import StorageService from 'services/StorageService';
import { Logger } from 'services/Logger';
import CardMedia from '@material-ui/core/CardMedia';
import ImageComponent from 'components/ImageComponent';
import ImageUpload from 'components/ImageUpload';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import MapViewStatic from 'components/MapViewStatic';

export const ImageDisplay = ({ field, options }) => {
  const imageUrl = field.value;
  return imageUrl ? (
    <ImageComponent
      image={{ fileUrl: imageUrl, name: 'Company Image' }}
      height={options.height}
      style={{ objectFit: 'contain' }}
    />
  ) : (
    '-'
  );
};

const getImage = async (imageUrl, setLogoUrl) => {
  try {
    const storageService = new StorageService();
    const logoUrl = await storageService.getFile(imageUrl);
    setLogoUrl(logoUrl);
  } catch (error) {
    Logger.info(`Error fetching image ${error}`);
  }
};

export const Logo = ({ field }) => {
  const imageUrl = field.value;
  const [logoUrl, setLogoUrl] = useState('');
  useEffect(() => {
    getImage(imageUrl, setLogoUrl);
  }, []);
  return imageUrl ? (
    <CardMedia
      style={{
        height: 200,
        width: '100%',
        minWidth: 100,
        maxWidth: 200,
        resizeMode: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
      }}
      image={logoUrl}
      title="Company Logo"
    />
  ) : (
    '-'
  );
};

const LogoButton = ({ form, field, user, options }) => (
  <ImageUpload form={form} field={field} user={user} label={options.label} />
);

const mapStateToProps = state => ({
  user: state.user
});

export const LogoButtonWrapper = connect(mapStateToProps)(LogoButton);

const useHighlightTextStyles = makeStyles(theme => ({
  label: {
    fontSize: 10,
    letterSpacing: 0.01,
    fontWeight: 'normal',
    textTransform: 'uppercase'
  },
  boldContent: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.brand.logoBlue,
    fontSize: 18,
    letterSpacing: 0.1,
    fontWeight: 600,
    lineHeight: 'normal',
    paddingTop: 4
  },
  normalContent: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.brand.logoBlue,
    fontSize: 15,
    letterSpacing: 0.3,
    fontWeight: 500,
    lineHeight: 'normal',
    paddingTop: 4
  }
}));
export const HighlightText = ({ form, field, options }) => {
  const classes = useHighlightTextStyles();
  const { value } = field;
  let fieldLabel = options.label;
  if (fieldLabel && fieldLabel.charAt(0) === '*') {
    fieldLabel = fieldLabel.slice(1);
  }
  return (
    <>
      <Typography variant="caption" className={classes.label}>
        {fieldLabel}
      </Typography>
      <Typography className={classes.boldContent}>{value || '-'}</Typography>
    </>
  );
};

const useCombinedAddressStyles = makeStyles(theme => ({
  label: {
    fontSize: 10,
    letterSpacing: 0.01,
    fontWeight: 'normal',
    textTransform: 'uppercase'
  },
  content: {
    fontFamily: theme.typography.fontFamily,
    color: '#3f3f3f',
    fontSize: 14,
    letterSpacing: 0.24,
    fontWeight: 'normal',
    lineHeight: 'normal',
    paddingTop: 7,
    overflow: 'auto'
  }
}));

export const CombinedAddress = ({ form, field, options }) => {
  const classes = useCombinedAddressStyles();

  const addressType = field.name;
  const billTo = form.values[`${addressType}BillTo`] || '';
  const shipTo = form.values[`${addressType}ShipTo`] || '';
  const addressLine1 = form.values[`${addressType}AddressLine1`] || '';
  const addressLine2 = form.values[`${addressType}AddressLine2`] || '';
  const city = form.values[`${addressType}City`] || '';
  const state = form.values[`${addressType}State`] || '';
  const zipcode = form.values[`${addressType}Zipcode`] || '';
  const longitude = form.values[`${addressType}Longitude`];
  const latitude = form.values[`${addressType}Latitude`];

  const addressLines = addressLine1 || addressLine2;
  let cityInfo = city ? `${city}, ` : '';
  cityInfo += state ? `${state}, ` : '';
  cityInfo += zipcode;

  let fieldLabel = options.label;
  if (fieldLabel && fieldLabel.charAt(0) === '*') {
    fieldLabel = fieldLabel.slice(1);
  }

  let textStyles = latitude && longitude ? { marginBottom: 30 } : {};
  textStyles = options.padding ? { ...textStyles, marginTop: options.padding } : textStyles;

  return (
    <Grid container spacing={3}>
      <Grid item style={textStyles}>
        <Typography variant="caption" className={classes.label}>
          {fieldLabel}
        </Typography>
        {billTo && (
          <Typography className={classes.content}>
            <b>Bill to: </b>
            {billTo}
          </Typography>
        )}
        {shipTo && (
          <Typography className={classes.content}>
            <b>Ship to: </b>
            {shipTo}
          </Typography>
        )}
        {addressLines && cityInfo ? (
          <>
            {addressLine1 && (
              <Typography className={classes.content}>{`${addressLine1},`}</Typography>
            )}
            {addressLine2 && (
              <Typography className={classes.content}>{`${addressLine2},`}</Typography>
            )}
            <Typography className={classes.content}>{cityInfo}</Typography>
          </>
        ) : (
          <Typography className={classes.content}>-</Typography>
        )}
      </Grid>
      {latitude && longitude && (
        <Grid item>
          <MapViewStatic coordinates={{ longitude, latitude }} />
        </Grid>
      )}
    </Grid>
  );
};
