import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveTable, Placeholder, ConfirmModal } from 'components';
import InputFilter from 'components/InputFilter';
import bundleTable from 'meta/Settings/Inventory/bundle/bundleTable';
import { PermissionConstants } from 'utils/AppConstants';
import { bundleIndex } from 'constants/algoliaIndex';
import { algoliaFilters } from 'constants/bundle';
import filterByIds from 'utils/filterByIds';

const bundleAction = {
  edit: {
    label: 'Edit item',
    icon: 'EditOutlined',
    caslKey: PermissionConstants.OBJECT_INVENTORTY,
    caslAction: 'update'
  },
  delete: {
    label: 'Delete',
    icon: 'DeleteOutlineOutlined',
    caslKey: PermissionConstants.OBJECT_INVENTORTY,
    caslAction: 'delete'
  }
};

export default function List({ refreshCount, getBundles, handleEdit, handleDelete }) {
  const [bundles, setBundles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmModalSetting, setConfirmModalSetting] = React.useState({});

  const deleteConfirmationHandler = bundle =>
    setConfirmModalSetting({
      confirmMessage: bundle.name,
      open: true,
      cancelAction: () => setConfirmModalSetting({ open: false }),
      action: async () => {
        await handleDelete(bundle);
        setConfirmModalSetting({ open: false });
      }
    });

  const bundleActionHandler = (actionType, bundle) => {
    const actionHandlerMap = {
      delete: deleteConfirmationHandler,
      edit: handleEdit
    };
    const action = actionHandlerMap[actionType];
    if (action) action(bundle);
  };

  const handleGetBundles = async (filter, limit, offset, sortBy, sortOrder) => {
    setIsLoading(true);
    const { items } = await getBundles({ filter, limit, offset, sortBy, sortOrder });
    setIsLoading(false);
    setBundles(items);
  };

  useEffect(() => {
    handleGetBundles();
    // eslint-disable-next-line
  }, [refreshCount]);

  return (
    <>
      <InputFilter
        searchIndex={bundleIndex}
        placeholder="Filter inventory bundle..."
        refreshCount={refreshCount}
        filters={algoliaFilters}
        overrideVariant="outlined"
      >
        {({ ids, hasSearch }) => {
          if (isLoading) return <Placeholder variant="card" repeatCount={4} />;
          return (
            <ResponsiveTable
              showToolbars
              disableFilter
              data={filterByIds(bundles, ids, hasSearch)}
              rowMetadata={bundleTable}
              rowActionButtons={bundleAction}
              rowActions={bundleActionHandler}
              caslKey={PermissionConstants.OBJECT_INVENTORTY}
              noDataMsg="No Bundles Found"
            />
          );
        }}
      </InputFilter>
      <ConfirmModal
        open={confirmModalSetting.open}
        confirm={confirmModalSetting.action}
        cancel={confirmModalSetting.cancelAction}
        message={confirmModalSetting.confirmMessage}
      />
    </>
  );
}

List.propTypes = {
  hasSearchText: PropTypes.bool,
  refreshCount: PropTypes.number.isRequired,
  getBundles: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
};

List.defaultProps = {
  hasSearchText: false
};
