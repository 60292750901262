export const useStyles = () => ({
  root: theme => ({
    marginTop: theme.default.spacing(2),
    marginBottom: theme.default.spacing(1),
    paddingTop: theme.default.spacing(1),
    paddingBottom: theme.default.spacing(1)
  }),
  warning: theme => ({
    padding: theme.default.spacing(1),
    marginTop: theme.default.spacing(2),
    marginBottom: theme.default.spacing(1),
    backgroundColor: theme.palette.grayscale(92),
    '& > *': {
      color: theme.palette.error.main,
      textAlign: 'center'
    }
  })
});
