import React, { useState } from 'react';

import {
  Field,
  FieldType,
  InlineAlert,
  InlineAlertTypes,
  TV,
  TW,
  Typography
} from '@buildhero/sergeant';
import { jsx } from '@emotion/react';
import { useTheme } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import WarningIcon from '@material-ui/icons/Warning';
import moment from 'moment-timezone';

import { CorruptData } from 'components';
import AttachmentModal from 'components/AttachmentSection/AddAttachment';
import { secondsToHour } from 'scenes/Payroll/TimeTrackingReport/helpers';
import AppConstants, { APRIL_30_2021 } from 'utils/AppConstants';
import { Mode } from 'utils/constants';

import { useUpsertTimesheetNotes } from '../../customHooks';
import { bindersEnabled, upsertTimesheetNote } from '../../services';

import WorkEvent from './WorkEvent';
import WorkEventDetails from './WorkEventDetails';

const useStyles = theme => ({
  daySummaryLine: {
    paddingBottom: 24,
    display: 'flex',
    justifyContent: 'space-between'
  },
  dayContainer: {
    boxShadow: '0 3px 11px 0 #f3f3f3ff',
    padding: 24,
    border: '2px solid #999999',
    borderRadius: '4px'
  },
  payrollHourTypes: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  subject: {
    fontSize: 14,
    flex: '1 0 auto',
    maxWidth: '150px',
    marginLeft: theme.spacing(0.8)
  },
  dayTotals: {
    justifyContent: 'flex-end',
    width: '55%',
    display: 'flex'
  },
  timesheetDate: {
    marginTop: 10
  }
});

export const DayCard = ({
  day,
  payrollHourTypes,
  updateTimesheetEntry,
  isEditable,
  payrollSetting,
  newAuditLogs = [],
  children,
  tenantId,
  refetchTimesheetPeriod,
  employeeId
}) => {
  const [notesModalData, setNotesModalData] = useState();

  const theme = useTheme();
  const styles = useStyles(theme);
  const { dayStartUTC, workEvents, dailyTotals, validHourTypes, dailyUnsubmittedEvents } = day;

  if (workEvents.length === 0) return null;

  const [upsertTimesheetNotes] = useUpsertTimesheetNotes();

  return (
    <>
      <div css={styles.dayContainer}>
        <div css={styles.daySummaryLine}>
          <Typography variant={TV.L} weight={TW.BOLD} css={styles.timesheetDate}>
            {moment
              .tz(moment.unix(dayStartUTC).format(), payrollSetting.timeZone)
              .format(AppConstants.TIMESHEET_DATE_FORMAT)}
          </Typography>
          <div css={styles.dayTotals}>
            {payrollHourTypes.map(
              ({ hourTypeAbbreviation }, i) =>
                validHourTypes.includes(hourTypeAbbreviation) && (
                  <Field
                    style={
                      i === 0
                        ? { width: '100%', maxWidth: 100 }
                        : { marginLeft: 24, width: '100%', maxWidth: 100 }
                    }
                    label={hourTypeAbbreviation}
                    type={FieldType.NUMBER}
                    value={secondsToHour(dailyTotals[hourTypeAbbreviation])}
                    innerStyle={{ fontWeight: 700 }}
                  />
                )
            )}
          </div>
          {children}
        </div>

        <Divider />

        {dailyUnsubmittedEvents?.map(workEvent => (
          <WorkEventDetails workEvent={workEvent}>
            <InlineAlert
              Icon={WarningIcon}
              type={InlineAlertTypes.YELLOW}
              style={{ marginBottom: 40 }}
            >
              Incomplete timesheet. Technician needs to submit hours
            </InlineAlert>
            <Divider />
          </WorkEventDetails>
        ))}

        {workEvents.map((workEvent, i) => {
          if (workEvent?.corruptData?.length > 0) {
            if (
              workEvent.corruptData.every(
                data =>
                  Number.isInteger(data?.entry?.actualStartTimeUTC) &&
                  data?.entry?.actualStartTimeUTC < APRIL_30_2021
              )
            ) {
              return null;
            }

            const entryWithReason = workEvent.corruptData.find(corruptData => corruptData.reason);
            let reason;
            if (entryWithReason) {
              reason = entryWithReason.reason;
            } else {
              reason = workEvent.corruptData.find(d => typeof d.hourType === 'undefined')
                ? 'Timesheet entry(ies) have hourTypeId not in company PayrollHourTypes'
                : 'Multiple timesheet entries for the same payroll hour type';
            }

            return <CorruptData reason={reason} corruptData={workEvent.corruptData} />;
          }

          return (
            <WorkEventDetails workEvent={workEvent}>
              <WorkEvent
                workEvent={workEvent}
                payrollHourTypes={payrollHourTypes}
                updateTimesheetEntry={updateTimesheetEntry}
                isEditable={isEditable}
                isIncomplete={dailyUnsubmittedEvents?.length > 0}
                payrollSetting={payrollSetting}
                newAuditLogs={newAuditLogs}
                setNotesModalData={setNotesModalData}
                tenantId={tenantId}
                employeeId={employeeId}
              />
              {i !== workEvents.length - 1 && <Divider />}
            </WorkEventDetails>
          );
        })}
      </div>
      <AttachmentModal
        open={notesModalData}
        mode={notesModalData?.mode}
        data={notesModalData?.data}
        parent={notesModalData?.parent}
        mutationService={bindersEnabled() ? upsertTimesheetNotes : upsertTimesheetNote}
        handleClose={success => {
          if (success) refetchTimesheetPeriod();
          setNotesModalData();
        }}
        title={`${notesModalData?.mode === Mode.EDIT ? 'Edit' : 'New'} Timesheet Note`}
        subjectRequired
        attachmentRequired={false}
        subjectLabel="Subject"
        subjectPlaceholder="Enter Note Subject..."
        descriptionLabel="Content"
        descriptionPlaceholder="Enter Note Content..."
      />
    </>
  );
};
