import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { PriorityTypes } from '@dispatch/Dispatch.constants';
import { useStyles } from '@dispatch/components/VisitsTabs/VisitsTabs.styles';

const Priority = ({ record }) => {
  const classes = useStyles();
  if (record?.priority === PriorityTypes.HIGH.value.serverValue) {
    return <Typography className={classes.HighPriority}>{record?.priority}</Typography>;
  }
  if (record?.priority) {
    return <Typography className={classes.Priority}>{record?.priority}</Typography>;
  }
  return null;
};

Priority.propTypes = {
  record: PropTypes.object.isRequired
};

export default memo(Priority);
