const layout = {
  fields: {},
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                flex: '0 1 133px',
                paddingTop: '16px',
                paddingRight: '16px'
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  options: {
                    label: 'Visit Date',
                    inputOptions: []
                  },
                  source: 'visitDate'
                }
              ]
            },
            {
              options: {
                direction: 'column',
                flex: '0 1 101px',
                paddingTop: '16px',
                paddingRight: '16px'
              },
              contents: [
                {
                  component: {
                    edit: 'FieldWithLabel'
                  },
                  options: {
                    label: 'Starting Time',
                    inputOptions: []
                  },
                  source: 'startingTime'
                }
              ]
            },
            {
              options: {
                direction: 'column',
                flex: '0 1 219px',
                paddingTop: '16px',
                paddingRight: '16px'
              },
              contents: [
                {
                  component: {
                    edit: 'FieldWithLabel'
                  },
                  options: {
                    label: 'Visit Description',
                    inputOptions: []
                  },
                  source: 'visitDescription'
                }
              ]
            },
            {
              options: {
                direction: 'column',
                flex: '0 1 542px',
                paddingTop: '16px',
                paddingRight: '16px'
              },
              contents: [
                {
                  component: {
                    edit: 'FieldWithLabel'
                  },
                  options: {
                    label: 'Forms',
                    inputOptions: []
                  },
                  source: 'forms'
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                flex: '0 1 271px',
                paddingTop: '16px',
                paddingRight: '16px'
              },
              contents: [
                {
                  component: {
                    edit: 'FieldWithLabel'
                  },
                  options: {
                    label: 'Department',
                    inputOptions: []
                  },
                  source: 'department'
                }
              ]
            },
            {
              options: {
                direction: 'column',
                flex: '0 1 219px',
                paddingTop: '16px',
                paddingRight: '16px'
              },
              contents: [
                {
                  component: {
                    edit: 'FieldWithLabel'
                  },
                  options: {
                    label: 'Crew',
                    inputOptions: []
                  },
                  source: 'selectedCrewName'
                }
              ]
            },
            {
              options: {
                direction: 'column',
                flex: '0 1 271px',
                paddingTop: '16px',
                paddingRight: '16px'
              },
              contents: [
                {
                  component: {
                    edit: 'FieldWithLabel'
                  },
                  options: {
                    label: 'Primary Technician',
                    inputOptions: []
                  },
                  source: 'primaryTechnicianId'
                }
              ]
            },
            {
              options: {
                direction: 'column',
                flex: '0 1 271px',
                paddingTop: '16px',
                paddingRight: '16px'
              },
              contents: [
                {
                  component: {
                    edit: 'FieldWithLabel'
                  },
                  options: {
                    label: 'Additional Technicians',
                    inputOptions: []
                  },
                  source: 'additionalTechnicianIds'
                }
              ]
            }
          ]
        }
      ]
    }
  }
};

export default layout;
