import gql from 'graphql-tag';

export const createRecurringVisit = gql`
  mutation createRecurringVisit($partitionKey: String, $input: AddRecurringVisitInput!) {
    createRecurringVisit(partitionKey: $partitionKey, data: $input) {
      id
      visitNumber
      scheduledFor
      tentativeDate
      status
    }
  }
`;
