import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { AppConstants } from 'utils/AppConstants';
import { Text, View } from '@react-pdf/renderer';
import styles from '../style';

const ApplicationCertification = props => {
  const {
    project,
    payApplication,
    repCompany,
    street,
    cityState,
    customerStreet,
    customerCityState,
    customerCompany
  } = props;

  return (
    <View style={[styles.displayFlex, { width: '96%' }]}>
      <View style={[styles.displayFlexDirectionColumn, { width: '25%', marginRight: 20 }]}>
        <View style={styles.displayFlex}>
          <Text style={[styles.text, styles.fieldName]}>TO:</Text>
          <Text style={styles.text}>{repCompany}</Text>
        </View>
        <View style={[styles.displayFlex, styles.textwrap]}>
          <Text style={[styles.text, styles.fieldName]}>ADDRESS:</Text>
          <Text style={styles.text}>{street}</Text>
        </View>
        <View style={[styles.displayFlex, styles.textwrap]}>
          <Text style={[styles.text, styles.fieldName]} />
          <Text style={styles.text}>{cityState}</Text>
        </View>
        <View style={[styles.displayFlex, styles.textwrap, { marginTop: 5 }]}>
          <Text style={[styles.text, styles.fieldName]}>Contractor:</Text>
          <Text style={styles.text}>{customerCompany}</Text>
        </View>
        <View style={[styles.displayFlex, styles.textwrap]}>
          <Text style={[styles.text, styles.fieldName]}>ADDRESS:</Text>
          <Text style={styles.text}>{customerStreet}</Text>
        </View>
        <View style={[styles.displayFlex, styles.textwrap]}>
          <Text style={[styles.text, styles.fieldName]} />
          <Text style={styles.text}>{customerCityState}</Text>
        </View>
        <View style={[styles.displayFlex, styles.textwrap, { marginTop: 5 }]}>
          <Text style={[styles.text, { width: 90, textAlign: 'left', paddingRight: 10 }]}>
            CONTRACT FOR:
          </Text>
          <Text style={styles.text}>{payApplication?.contractFor || null}</Text>
        </View>
      </View>
      <View style={[styles.displayFlexDirectionColumn, { width: '25%' }]}>
        <View style={[styles.displayFlex, styles.textwrap]}>
          <Text style={[styles.text, styles.fieldName]}>PROJECT: </Text>
          <Text style={styles.text}>{project?.name || null}</Text>
        </View>
        <View style={[styles.displayFlex, styles.textwrap]}>
          <Text style={[styles.text, styles.fieldName]}>ADDRESS:</Text>
          <Text style={styles.text}>
            {project?.address1 || null}
            {project?.address2 ? ' ' : ','}
            {project?.address2 || null}
            {project?.address2 ? ',' : null}
          </Text>
        </View>
        <View style={styles.displayFlex}>
          <Text style={[styles.text, styles.fieldName]} />
          <Text style={styles.text}>
            {project?.addressCity || null}
            {project?.addressCity ? ', ' : null}
            {project?.addressState || null} {project?.addressPostal || null}
          </Text>
        </View>
      </View>
      <View style={[styles.displayFlexDirectionColumn, { width: '33%', paddingLeft: 13 }]}>
        <View style={styles.displayFlex}>
          <Text style={[styles.text, styles.longFieldName]}>APPLICATION NO:</Text>
          <Text style={styles.text}>
            {Number(payApplication?.number) || ''}
            {'  '}OF{'  '}
            {project?.PayApplication?.length || ''}
          </Text>
        </View>
        <View style={[styles.displayFlex, { marginTop: 10 }]}>
          <Text style={[styles.text, styles.longFieldName]}>PERIOD:</Text>
          <Text style={styles.text}>
            {payApplication?.periodFrom
              ? moment.unix(payApplication?.periodFrom).format('L')
              : null}
            {'  '}TO{'  '}
            {payApplication?.periodTo ? moment.unix(payApplication?.periodTo).format('L') : null}
          </Text>
        </View>
        <View style={[styles.displayFlex, { marginTop: 30 }]}>
          <Text style={[styles.text, styles.longFieldName]}>PROJECT NUMBER:</Text>
          <Text style={styles.text}>{project?.number || null}</Text>
        </View>
        <View style={[styles.displayFlex, { marginTop: 15 }]}>
          <Text style={[styles.text, styles.longFieldName]}>CONTRACT DATE:</Text>
          <Text style={styles.text}>
            {project.contractDate
              ? moment.unix(project.contractDate).format(AppConstants.DATE_FORMAT)
              : null}
          </Text>
        </View>
      </View>
      <View style={styles.displayFlexDirectionColumn}>
        <View style={styles.displayFlex}>
          <Text style={[styles.text, styles.longFieldName]}>DISTRIBUTION TO:</Text>
        </View>
        <View style={[styles.displayFlex, { marginTop: 10 }]}>
          <Text style={styles.boxBorder}>
            {payApplication?.distributeTo === 'owner' ? 'X' : null}
          </Text>
          <Text style={styles.smallText}>OWNER</Text>
        </View>
        <View style={styles.displayFlex}>
          <Text style={styles.boxBorder}>
            {payApplication?.distributeTo === 'architect' ? 'X' : null}
          </Text>
          <Text style={styles.smallText}>ARCHITECT</Text>
        </View>
        <View style={styles.displayFlex}>
          <Text style={styles.boxBorder}>
            {payApplication?.distributeTo === 'mechanical engineer' ? 'X' : null}
          </Text>
          <Text style={styles.smallText}>MECHANICAL ENGINEER</Text>
        </View>
        <View style={styles.displayFlex}>
          <Text style={styles.boxBorder}>
            {payApplication?.distributeTo === 'electrical engineer' ? 'X' : null}
          </Text>
          <Text style={styles.smallText}>ELECTRICAL ENGINEER</Text>
        </View>
        <View style={styles.displayFlex}>
          <Text style={styles.boxBorder}>
            {payApplication?.distributeTo === 'general contractor' ? 'X' : null}
          </Text>
          <Text style={styles.smallText}>GENERAL CONTRACTOR</Text>
        </View>
        <View style={styles.displayFlex}>
          <Text style={styles.boxBorder}>
            {payApplication?.distributeTo === 'mechanical contractor' ? 'X' : null}
          </Text>
          <Text style={styles.smallText}>MECHANICAL CONTRACTOR</Text>
        </View>
        <View style={styles.displayFlex}>
          <Text style={styles.boxBorder}>
            {payApplication?.distributeTo === 'electrical contractor' ? 'X' : null}
          </Text>
          <Text style={styles.smallText}>ELECTRICAL CONTRACTOR</Text>
        </View>
      </View>
    </View>
  );
};

ApplicationCertification.propTypes = {
  project: PropTypes.object.isRequired,
  payApplication: PropTypes.object.isRequired,
  repCompany: PropTypes.string.isRequired,
  street: PropTypes.string.isRequired,
  cityState: PropTypes.string.isRequired,
  customerStreet: PropTypes.string.isRequired,
  customerCityState: PropTypes.string.isRequired,
  customerCompany: PropTypes.string.isRequired
};

export default ApplicationCertification;
