import gql from 'graphql-tag';

const getProducts = gql`
  query getProducts($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      products(entityConnection: "Product") {
        items {
          id
          name
          taxable
          isActive
        }
      }
    }
  }
`;

export default getProducts;
