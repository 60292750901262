const chipStatuses = {
  Scheduled: {
    name: {
      en: 'Scheduled',
      es: 'Programado'
    },
    fontColor: '#fafafa',
    backgroudColor: '#2dce89'
  },
  Working: {
    name: {
      en: 'Working',
      es: 'Programado'
    },
    fontColor: '#fafafa',
    backgroudColor: '#2dce89'
  },
  Unassigned: {
    name: {
      en: 'Unassigned',
      es: 'Sin asignar'
    },
    fontColor: '#fafafa',
    backgroundColor: '#d82d51'
  },
  'On hold': {
    name: {
      en: 'On Hold',
      es: 'Sin asignar'
    },
    fontColor: '#fafafa',
    backgroundColor: '#959595'
  },
  Complete: {
    name: {
      en: 'Complete',
      es: 'Sin asignar'
    },
    fontColor: '#fafafa',
    backgroundColor: '#2dce89'
  },
  Canceled: {
    name: {
      en: 'Canceled',
      es: 'Sin asignar'
    },
    fontColor: '#fafafa',
    backgroundColor: '#3f3f3f'
  },
  'Late start': {
    name: {
      en: 'Late start',
      es: 'Sin asignar'
    },
    fontColor: '#fafafa',
    backgroundColor: '#b00020'
  },
  Traveling: {
    name: {
      en: 'Traveling',
      es: 'De viaje'
    },
    fontColor: '#fafafa',
    backgroundColor: '#0194ff'
  },
  Paused: {
    name: {
      en: 'Pause',
      es: 'Pausa'
    },
    fontColor: '#fafafa',
    backgroundColor: '#959595'
  },
  Converted: {
    name: {
      en: 'Converted',
      es: 'Convertido'
    },
    fontColor: '#fafafa',
    backgroundColor: '#2dce89'
  },
  Closed: {
    name: {
      en: 'Closed',
      es: 'Cerrada'
    },
    fontColor: '#fafafa',
    backgroundColor: '#959595'
  }
};

export default chipStatuses;
