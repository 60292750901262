import React, { useMemo } from 'react';
import { jsx } from '@emotion/react';
import { sortBy } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Divider, Box, useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import { Typography, TV, TW, ThemeProvider } from '@buildhero/sergeant';
import { isTenantSettingEnabled } from 'utils';
import FeatureFlagsConstants from 'utils/FeatureFlagConstants';
import { TaskStatus, TaskTypeInternal } from 'utils/AppConstants';
import TaskTable, {
  TaskTableLayout
} from '../../Jobs/JobDetail/componentsV2/TasksSection/TasksTable';
import { getVisitDisplayText } from '../utils';
import { SectionHeader } from '../Components';

import { getStyles } from '../styles';

const addVisitTextToTasks = (tasks, visits, companyTimezone) =>
  sortBy(
    tasks.map(task => {
      const visit = visits.find(v => task?.visitId && v.id === task.visitId);
      return {
        ...task,
        visit: getVisitDisplayText(visit, companyTimezone),
        visitNumber: visit.visitNumber
      };
    }),
    'visitNumber'
  );

const JobCloseoutTasksSection = ({ job, visits, isMultiVisits, companyTimezone, isLoading }) => {
  const selectedVisitIds = visits.map(v => v.id);
  const ldFlags = useFlags();
  const theme = useTheme();
  const styles = getStyles(theme);

  const isPendingTasksEnabled = isTenantSettingEnabled('mobilePendingTasks');
  const isPendingTasksTableEnabled = ldFlags[FeatureFlagsConstants.PENDING_TASKS_TABLE];

  const completedTasks = useMemo(() => {
    return (
      job?.jobTasks?.items?.reduce?.((tasks, { task }) => {
        if (selectedVisitIds.includes(task?.visitId) && task.status === TaskStatus.COMPLETED) {
          return [...tasks, task];
        }
        return tasks;
      }, []) || []
    );
  }, [job, selectedVisitIds]);

  const remainingTasks = useMemo(() => {
    return (
      job?.jobTasks?.items?.reduce?.((tasks, { task }) => {
        if (task.status === TaskStatus.IN_JOB) return [...tasks, task];
        return tasks;
      }, []) || []
    );
  }, [job]);

  const recommendedTasks = useMemo(() => {
    return (
      job?.customerProperty?.tasks?.items?.filter(
        task =>
          selectedVisitIds.includes(task?.visitId) &&
          task?.taskTypeInternal === TaskTypeInternal.RECOMMENDED
      ) || []
    );
  }, [job, selectedVisitIds]);

  const pendingTasks = useMemo(() => {
    return (
      job?.customerProperty?.tasks?.items?.filter(
        task =>
          selectedVisitIds.includes(task?.visitId) &&
          task?.taskTypeInternal === TaskTypeInternal.PENDING
      ) || []
    );
  }, [job, selectedVisitIds]);

  return (
    <ThemeProvider>
      <Divider css={styles.divider} />
      <Typography variant={TV.L} weight={TW.BOLD}>
        Tasks
      </Typography>

      <SectionHeader
        title="Completed Tasks"
        titleIcon={() => <CheckIcon css={styles.greenCheckIcon} />}
      />
      <TaskTable
        tasks={
          isMultiVisits
            ? addVisitTextToTasks(completedTasks, visits, companyTimezone)
            : completedTasks
        }
        taskTableLayout={
          isMultiVisits
            ? TaskTableLayout.JOB_CLOSEOUT_MULTI_JOB_TASK
            : TaskTableLayout.JOB_CLOSEOUT_JOB_TASK
        }
        noDataMessage="No Completed Tasks"
        loading={isLoading}
      />

      <SectionHeader
        title="Remaining Tasks"
        titleIcon={() => <WarningIcon css={styles.redWarningIcon} />}
      />
      <TaskTable
        tasks={remainingTasks}
        taskTableLayout={TaskTableLayout.JOB_CLOSEOUT_JOB_TASK}
        noDataMessage="No Remaining Tasks"
        loading={isLoading}
      />

      <SectionHeader title="Recommended Tasks Created" />
      <TaskTable
        tasks={isMultiVisits ? addVisitTextToTasks(recommendedTasks, visits) : recommendedTasks}
        taskTableLayout={
          isMultiVisits
            ? TaskTableLayout.JOB_CLOSEOUT_MULTI_VISIT_TASK
            : TaskTableLayout.JOB_CLOSEOUT_VISIT_TASK
        }
        noDataMessage="No Recommended Tasks"
        loading={isLoading}
      />

      {isPendingTasksEnabled && isPendingTasksTableEnabled && (
        <>
          <SectionHeader title="Pending Tasks Created" />
          <TaskTable
            tasks={isMultiVisits ? addVisitTextToTasks(pendingTasks, visits) : pendingTasks}
            taskTableLayout={
              isMultiVisits
                ? TaskTableLayout.JOB_CLOSEOUT_MULTI_VISIT_TASK
                : TaskTableLayout.JOB_CLOSEOUT_VISIT_TASK
            }
            noDataMessage="No Pending Tasks"
            loading={isLoading}
          />
        </>
      )}
    </ThemeProvider>
  );
};

export default JobCloseoutTasksSection;
