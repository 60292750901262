import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  lineItemDescription: {
    overflowX: 'hidden',
    maxWidth: 'calc(82% - 38px)', // 38px is to offset the more menu button width, so in total each line ~ 100% width
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  lineItemAmount: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '18%',
    '& [class*=MuiTypography]': {
      fontSize: 14,
      fontWeight: 'bold'
    },
    '&.Discount::before': {
      content: '"-"'
    }
  },
  label: {
    marginRight: '48px'
  },
  labelWithInfo: {
    marginRight: '5px'
  },
  subtotalDivider: {
    width: '25%'
  },
  tooltipIcon: {
    marginRight: '48px',
    width: '12px'
  }
}));
