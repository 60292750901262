import gql from 'graphql-tag';

const addQuotesToCustomer = gql`
  mutation addQuotesToCustomer($partitionKey: String!, $data: AddQuotesToCustomerInput!) {
    addQuotesToCustomer(partitionKey: $partitionKey, data: $data) {
      id
      version
      quoteNumber
      customIdentifier
      name
      description
      departmentId
      departmentValue
      serviceAgreementId
      priceBookId
      parentId
      dateSent
      expirationDate
      jobTypeId
      jobTypeValue
      ownerId
      ownerValue
      salesById
      salesByValue
    }
  }
`;

export default addQuotesToCustomer;
