const styles = theme => ({
  checkboxInList: {
    width: 16,
    height: 16
  },
  checkboxGroup: { padding: 8 },
  filterLabels: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: 1.85,
    letterSpacing: 0.04,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  refinementContainer: { padding: 10 },
  showMoreBtn: {
    paddingLeft: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: 13,
    fontWeight: 'bold',
    letterSpacing: 0.04,
    color: theme.palette.secondary.main
  },
  margin: {
    // paddingTop: 3,
    top: '50%',
    right: -3
  },
  badge: {
    width: 100
  }
});
export default styles;
