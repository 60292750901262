import React from 'react';
import _ from 'lodash';
import { assetsRows } from 'meta/Jobs/Invoice/review-report-tables';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { ResponsiveTable } from 'components';

const AssetsWorkedOn = ({ classes, current }) => {
  const { context } = current;
  let visitAssets = [];
  if (!_.isEmpty(context.visitAssets)) {
    visitAssets = context.visitAssets.map(asset => asset.propertyAsset);
  }

  return (
    <>
      <Typography className={classes.sectionTitle}>Assets Worked On</Typography>
      <Divider variant="fullWidth" classes={{ root: classes.blueDivider }} />
      <ResponsiveTable
        rowMetadata={assetsRows}
        data={visitAssets}
        noDataMsg="No assets"
        disableFilter
        disablePagination
        noMaxHeight
      />
    </>
  );
};

export default AssetsWorkedOn;
