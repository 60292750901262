import gql from 'graphql-tag';

const companyAddressFragment = `
    addressLine1
    addressLine2
    state
    city
    zipcode
    latitude
    longitude
    addressType
    __typename
`;

const getAllPropertyByCompany = gql`
  query getCompany(
    $partitionKey: String!
    $sortKey: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      property: customerPropertiesView(
        entityConnection: "CustomerProperty"
        pagination: { limit: $limit, offset: $offset }
        filter: $filter
        sorting: $sort
        includeComputedColumns: true
      ) {
        nextToken
        items {
          id
          sortKey
          companyName
          customerPropertyTypeValue
          isActive
          billingCustomerId
          billingCustomer(entityConnection: "Customer") {
            customerName
            customerTypeValue
            companyAddresses(entityConnection: "CompanyAddress") {
              items {
                ${companyAddressFragment}
              }
              __typename
            }
          }
          customerReps(entityConnection: "CustomerRep") {
            items {
              mappedEntity {
                ... on CustomerRep {
                  id
                  name
                  cellPhone
                  landlinePhone
                  bestContact
                }
              }
            }
          }
          companyAddresses(entityConnection: "CompanyAddress") {
            items {
              ${companyAddressFragment}
              __typename
            }
            __typename
          }
          openJobsComputed
          openJobsValueComputed
          outstandingBalanceComputed
          overdueBalanceComputed
          customer: parentEntity {
            ... on Customer {
              customerName
              id
              sortKey
              customerReps(entityConnection: "CustomerRep") {
                items {
                  id
                  name
                  bestContact
                }
              }
              customerTypeValue
            }
          }
          accountNumber
          createdBy
          createdDate
        }
      }
    }
  }
`;

export default getAllPropertyByCompany;
