import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Modal, Button, SgtForm, ButtonSize, ButtonType, ThemeProvider } from '@buildhero/sergeant';
import { Skeleton } from '@material-ui/lab';
import { AddCircleOutlineOutlined } from '@material-ui/icons';
import moment from 'moment';
import { useSelector } from 'react-redux';

import Notes from './Notes';
import useMutateNotes, { isNewNote } from './useMutateNotes';
import useLazyQueryNotes from './useLazyQueryNotes';

const Configuration = (hasShareWithTechsOption, setDisableSave) => ({
  layouts: {
    default: {
      contents: [
        {
          source: 'notes',
          component: 'Notes',
          props: {
            hasShareWithTechsOption,
            setDisableSave
          }
        }
      ]
    }
  }
});

const generateNewNote = () => ({
  id: `-${Math.random()}`,
  subject: '',
  note: '',
  hideFromTechniciansOnMobile: false
});

const changeNoteDisplayLastUpdated = ({ candidateNote, oldNotes }) => {
  if (isNewNote(candidateNote)) return true;

  const existingNote = oldNotes.find(n => n.id === candidateNote.id);

  if (existingNote.note !== candidateNote.note || existingNote.subject !== candidateNote.subject)
    return true;

  return false;
};

export default function useEagerMultiNotes(onSave, hasShareWithTechsOption = false) {
  const [open, setOpen] = useState(false);
  const [parent, setParent] = useState();
  const [formService, setFormService] = useState();
  const [disableSave, setDisableSave] = useState(true);
  const user = useSelector(s => s.user);

  const [getNotes, { loading, data: notes }] = useLazyQueryNotes();

  const [mutateNotes, { loading: mutateLoading }] = useMutateNotes(parent, user.tenantId);

  useEffect(() => {
    if (parent?.id) getNotes({ parent });
  }, [getNotes, parent]);

  const handleSave = useCallback(
    async ({ notes: newNotes }) => {
      // do not fill out displayLastUpdatedBy and displayLastUpdatedDateTime until FF is turned on.
      // this is to coordinate release with mobile.
      const newNotesWithDisplayAuditHistory = newNotes.map(n => {
            if (changeNoteDisplayLastUpdated({ candidateNote: n, oldNotes: notes })) {
              return {
                ...n,
                displayLastUpdatedBy: user.displayName,
                displayLastUpdatedDateTime: moment().unix()
              };
            }

            // historic data may note have a displayLastUpdatedBy or displayLastUpdatedDateTime populated
            // in which case initialize them to the lastUpdatedBy and lastUpdatedDate values
            if (!n.displayLastUpdatedBy || !n.displayLastUpdatedDateTime) {
              return {
                ...n,
                displayLastUpdatedBy: n.lastUpdatedBy,
                displayLastUpdatedDateTime: n.lastUpdatedDate
              };
            }
            return n;
          })

      try {
        const { data } = await mutateNotes({
          newNotes: newNotesWithDisplayAuditHistory,
          parent
        });
        setParent(oldParent => ({
          ...oldParent,
          version: oldParent.version + 1
        }));
        if (onSave) onSave(data.mutateNotesByParentId, parent);
        setOpen(false);
      } catch (err) {}
    },
    [mutateNotes, parent, onSave, notes, user]
  );

  const openMultiNoteModal = useCallback(givenData => {
    setParent(oldParent => {
      // if reopening the same modal, the outside context may have data that is version(s) behind.
      const reopeningTheSameModal =
        oldParent?.id === givenData.id && oldParent?.version > givenData.version;

      if (reopeningTheSameModal) return oldParent;
      return givenData;
    });

    setOpen(true);
  }, []);

  const MultiNoteModal = useMemo(
    () => (
      <ThemeProvider>
        <Modal
          fullWidth
          title={
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              {`Notes for ${parent?.displayEntityType || parent?.entityType || ''} ${parent?.name ||
                ''}`}
              <Button
                onClick={() =>
                  formService.formikContext.setValues(v => ({
                    notes: [generateNewNote(), ...v.notes]
                  }))
                }
                size={ButtonSize.SMALL}
                type={ButtonType.SECONDARY}
              >
                <AddCircleOutlineOutlined fontSize="small" /> Add Note
              </Button>
            </div>
          }
          open={open}
          onClose={() => setOpen(false)}
          actions={
            <Button
              loading={mutateLoading}
              onClick={formService?.formikContext.handleSubmit}
              fullWidth
              disabled={disableSave}
            >
              Save
            </Button>
          }
        >
          {loading || !notes ? (
            <>
              {[...Array(parent?.notesCount || 1)].map(() => (
                <Skeleton
                  style={{ marginBottom: 8 }}
                  variant="rect"
                  height={parent?.notesCount === 0 ? 116 : 68}
                />
              ))}
            </>
          ) : (
            <SgtForm
              configuration={Configuration(hasShareWithTechsOption, setDisableSave)}
              initialValues={{ notes: notes.length === 0 ? [generateNewNote()] : notes }}
              onCreateService={setFormService}
              onSubmit={handleSave}
              customComponents={{ Notes }}
            />
          )}
        </Modal>
      </ThemeProvider>
    ),
    [parent, open, mutateLoading, formService, loading, notes, handleSave, disableSave]
  );

  return { MultiNoteModal, openMultiNoteModal };
}
