import { generateDefaultFieldsObject } from '@pm/components/formattingUtils';

const margin = '0px 15px 21px 0px';

const formFields = {
  name: { required: true },
  description: { required: true }
};

const formSelectContents = [
  {
    options: {
      direction: 'row'
    },
    contents: [
      {
        options: {
          direction: 'column',
          margin
        },
        contents: [
          {
            component: 'customFormSelector'
          }
        ]
      }
    ]
  }
];

const formSelectLayout = {
  fields: generateDefaultFieldsObject(formFields),
  layouts: {
    edit: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: formSelectContents
    }
  }
};

export default formSelectLayout;
