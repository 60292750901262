import gql from 'graphql-tag';

const updateWorkflowSteps = gql`
  mutation updateSteps(
      $stepId: String!,
      $isRequired: Boolean,
      $version: Int!,
      $workflowSubSteps: [WorkflowSubStepInput]
  ) {
    updateWorkflowStep(
      data: {
        id: $stepId,
        isRequired: $isRequired,
        version: $version,
        workflowSubSteps: $workflowSubSteps 
      }
    ) {
      id
      isRequired
      version
      workflowSubSteps {
        key
        label
        enabled
      }
    }
  }
`;

export default updateWorkflowSteps;
