import gql from 'graphql-tag';

const getAuditLogsByCustomerId = gql`
  query getAuditLogsByCustomerId($id: String!) {
    getCustomerById(id: $id) {
      id
      auditLogs(entityConnection: "AuditLogEntry") {
        items {
          auditedEntityType
          auditedEntityDisplayName
          executionType
          executedBy
          executedDateTime
          auditedEntityId
          auditedEntitySortKey
          auditedEntityParentId
          auditedEntityParentSortKey
          auditedEntityParentEntityType
          auditedEntityParentDisplayName
          changeLog
          customMessage
        }
      }
    }
  }
`;

export default getAuditLogsByCustomerId;
