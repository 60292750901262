export const formatAddress = address => {
  if (!address) {
    return '';
  }
  const { addressLine1, addressLine2, city, state, zipcode } = address;
  return `
        ${addressLine1 ? `${addressLine1}, ` : ''}
        ${addressLine2 ? `${addressLine2}, ` : ''}
        ${city ? `${city}, ` : ''}
        ${state ? `${state}, ` : ''} ${zipcode}
    `;
};

export const formatBillToAddressSmartfield = (propertyDetails, HTML) => {
  const billTo = propertyDetails?.property?.billTo;
  const billingAddress = HTML.getBillingAddress(
    propertyDetails?.property?.billingCustomer?.companyAddresses?.items
  );

  if (billTo && billingAddress) {
    return `${billTo}, ${billingAddress}`;
  }
  if (billTo) {
    return billTo;
  }
  if (billingAddress) {
    return billingAddress;
  }
  return '';
};

export const getBillingAddress = (addresses = []) =>
  formatAddress(addresses.find(address => address.addressType === 'billingAddress'));

export const getAddress = (addresses = []) =>
  formatAddress(
    addresses.find(
      address =>
        address.addressType === 'businessAddress' || address.addressType === 'propertyAddress'
    )
  );
