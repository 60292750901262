import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { Divider, Typography, TW } from '@buildhero/sergeant';

import { formatDisplayCurrency } from 'utils';
import toFixedNumber from 'utils/toFixedNumber';

import InfoField from '@pm/components/InfoField';
import CustomLinearProgress from '@pm/components/CustomLinearProgress';

import { useStyles } from './ProjectInfo.styles';

const ProgressBar = ({ value }) => {
  const styles = useStyles();

  return (
    <Box css={styles.progressBarContainer}>
      <CustomLinearProgress value={value > 100 ? 100 : value || 0} />
      <Typography>{parseFloat(value) || 0}%</Typography>
    </Box>
  );
};

const ProjectInfo = ({ project, projectKpis }) => {
  const styles = useStyles();

  return (
    <>
      <Grid css={styles.root} container justify="space-between" spacing={3} md={12}>
        <Grid item md={3}>
          <InfoField
            label="Department"
            value={project?.projectManagerDepartment?.tagName}
            loading={!project.detailsLoaded}
            weight={TW.BOLD}
          />
        </Grid>
        <Grid item md={3}>
          <InfoField
            label="Project Manager"
            value={project?.projectManager?.name}
            loading={!project.detailsLoaded}
            weight={TW.BOLD}
          />
        </Grid>
        <Grid item md={4}>
          <InfoField
            label="Percent Complete"
            value={toFixedNumber(projectKpis?.percentComplete)}
            component={ProgressBar}
            loading={!project.summaryLoaded}
            weight={TW.BOLD}
          />
        </Grid>
        <Grid item md={2}>
          <InfoField
            label="AR Balance"
            value={formatDisplayCurrency(projectKpis?.openAr)}
            loading={!projectKpis.loaded}
            weight={TW.BOLD}
          />
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

export default ProjectInfo;
