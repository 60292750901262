import gql from 'graphql-tag';

const getPastVisitsByCustomerProperty = gql`
  query getCustomerProperty(
    $partitionKey: String!
    $sortKey: String!
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getCustomerProperty(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      parentId
      parentSortKey
      companyName
      visits: visitsView(
        entityConnection: "Visit"
        pagination: { limit: $limit, offset: $offset }
        filter: {
          stringFilters: [
            { fieldName: "Visit.status", filterInput: { in: ["Complete", "Converted", "On hold"] } }
          ]
        }
        sorting: $sort
      ) {
        nextToken
        items {
          id
          description
          status
          scheduledFor
          tentativeDate
          parentEntity {
            ... on Job {
              id
              customIdentifier
              jobNumber
              jobTypeName
              jobTypeInternal
              sortKey
              customerSortKey
              customerPropertyName
              entityType
            }
          }
          primaryTechs(entityConnection: "VisitPrimaryTech") {
            items {
              id
              sortKey
              invertedSortKey
              mappedEntity {
                ... on Employee {
                  id
                  hierarchy
                  tenantId
                  tenantCompanyId
                  sortKey
                  partitionKey
                  entityType
                  name
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default getPastVisitsByCustomerProperty;
