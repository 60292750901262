import gql from 'graphql-tag';

const addAttachmentsToCustomer = gql`
  mutation addAttachmentsToCustomer($partitionKey: String!, $data: AddAttachmentsToCustomerInput!) {
    addAttachmentsToCustomer(partitionKey: $partitionKey, data: $data) {
      id
      version
      customFileName
      fileUrl
      fileName
      description
      comment
      type
      createdDate
    }
  }
`;

export default addAttachmentsToCustomer;
