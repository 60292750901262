import React from 'react';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import SettingsButton from './settingsButton';
import Column from '../column';

const Row = ({
  rowNumber,
  columnWidth,
  items,
  availableColumns,
  numberOfColumns,
  setColumns,
  handleDrop,
  copyRow,
  deleteRow,
  deleteItem,
  openEditItemModal,
  updateDragging,
  draggingItem,
  classes,
  blankWorkspace,
  getLocalAssets
}) => {
  const isEmpty = items && items.length === 1 && items[0] === null;
  const rowClass = isEmpty ? classes.rowEmpty : classes.row;
  let rowColumnsClass = isEmpty ? classes.rowEmptyColumnsContainer : classes.rowColumnsContainer;

  const [isHovered, setHover] = React.useState(false);
  if (blankWorkspace) {
    rowColumnsClass = classes.blankWorkspaceRow;
  }

  const hoverIn = () => setHover(true);
  const hoverOut = () => setHover(false);

  const handleColumnClick = number => {
    setColumns(rowNumber, number);
  };
  const layoutOptions = [1, 2, 3, 4];
  const actionOptions = [
    {
      label: 'Duplicate Row',
      icon: 'copy',
      callback: () => copyRow(rowNumber)
    },
    {
      label: 'Delete Row',
      icon: 'delete',
      callback: () => deleteRow(rowNumber)
    }
  ];

  const toolbarOpacity = isHovered ? 'visible' : 'hidden';

  return (
    <div className={rowClass} onMouseEnter={hoverIn} onMouseLeave={hoverOut}>
      {!isEmpty && (
        <div className={classes.rowDragHandle} style={{ visibility: toolbarOpacity }}>
          <DragIndicatorIcon />
        </div>
      )}
      <div className={rowColumnsClass}>
        {items.map((item, index) => (
          <Column
            key={`column-${index}`}
            classes={classes}
            item={item}
            row={rowNumber}
            column={index}
            width={columnWidth}
            availableColumns={availableColumns}
            handleDrop={handleDrop}
            openEditItemModal={openEditItemModal}
            draggingItem={draggingItem}
            updateDragging={updateDragging}
            blankWorkspace={blankWorkspace}
            getLocalAssets={getLocalAssets}
          />
        ))}
      </div>
      {!isEmpty && (
        <div className={classes.rowToolbar} style={{ visibility: toolbarOpacity }}>
          <div className={classes.rowControlButtonWrapper}>
            <SettingsButton
              filledColumns={numberOfColumns - availableColumns}
              handleColumnClick={handleColumnClick}
              layoutOptions={layoutOptions}
              actionOptions={actionOptions}
            />
            {/* <SettingsButton options={settings} /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Row;
