import gql from 'graphql-tag';

const updateTimeCardAndRelated = gql`
  mutation updateTimeCardAndRelated($partitionKey: String, $data: UpdateTimeCardAndRelatedInput!) {
    updateTimeCardAndRelated(partitionKey: $partitionKey, data: $data) {
      id
    }
  }
`;

export default updateTimeCardAndRelated;
