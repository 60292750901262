import gql from 'graphql-tag';

const GET_PURCHASE_ORDER_LIST = gql`
  query getPurchaseOrderList(
    $tenantId: String
    $filter: TableFilterInput
    $pagination: PaginationInput
    $sorting: [TableSortingInput]
  ) {
    data: getPurchaseOrderList(
      tenantId: $tenantId
      filter: $filter
      pagination: $pagination
      sorting: $sorting
    ) {
      rowCount
      items
    }
  }
`;

export default GET_PURCHASE_ORDER_LIST;
