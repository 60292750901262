import { makeStyles } from '@material-ui/core/styles';
import theme from 'themes/BuildHeroTheme';

const useStyles = makeStyles(() => ({
  root: {
    padding: '17px',
    '& .react-loading-skeleton': {
      marginBottom: 10
    }
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  titleBar: {
    display: 'flex'
  },
  mailIcon: {
    fontSize: 20,
    marginRight: 4
  },
  title: {
    fontSize: '20px',
    lineHeight: '20px',
    fontWeight: 700,
    letterSpacing: '-0.03em'
  },
  titleButton: {
    float: 'right'
  },
  showLabelContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    borderTop: `1px solid ${theme.palette.grayscale(90)}`
  },
  dividerContainer: {
    marginTop: theme.spacing(1.5)
  },
  showLabel: {
    width: 80,
    height: 20,
    marginTop: -10,
    fontSize: '10px',
    textTranform: 'uppercase',
    textDecoration: 'none',
    color: theme.palette.grayscale(60),
    background: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.grayscale(95)
    }
  },
  spacer: {
    paddingBottom: '30px'
  }
}));

export default useStyles;
