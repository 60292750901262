import gql from 'graphql-tag';

const updateCustomerRepOnProperty = gql`
  mutation updateCustomerRepOnProperty(
    $paritionKey: String!
    $data: UpdateCustomerRepAndRealatedInput!
  ) {
    updateCustomerRepOnProperty(partitionKey: $paritionKey, data: $data) {
      id
      name
    }
  }
`;

export default updateCustomerRepOnProperty;
