import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultButton } from 'components';

const useStyles = makeStyles({
  root: {
    padding: 8
  },
  label: {
    lineHeight: '12px'
  }
});

export default function ShowMoreButton(props) {
  const { disabled, loading, ...attr } = props;
  const classes = useStyles();

  return (
    <DefaultButton
      label="Show More"
      variant="outlinedSecondary"
      showSpinner={loading}
      disabled={disabled}
      buttonProps={{
        classes: {
          root: classes.root,
          label: classes.label
        }
      }}
      {...attr}
    />
  );
}
