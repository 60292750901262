import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  errorText: ({ isError }) => ({
    '& .Mui-error[class^="MuiFormHelperText"]': {
      paddingTop: theme.spacing(0.5),
      color: isError ? theme.palette.error.focus : 'inherit'
    }
  })
}));
