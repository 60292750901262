import React from 'react';
import { func, node, object, oneOfType, string } from 'prop-types';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Field, FieldType, ThemeProvider, Typography, TV } from '@buildhero/sergeant';

import withLazyMount from 'utils/withLazyMount';
import { useStyles } from './ReportInfo.styles';
import ReportSection from '../ReportSection';
import {
  selectBillTo,
  selectBillingCustomerId,
  selectBillingCustomerName,
  selectCustomerId,
  selectCustomerName,
  selectCustomerPropertyAddress,
  selectCustomerBillingAddress,
  selectDepartmentName,
  selectJobType,
  selectProjectManager,
  selectPropertyName,
  selectTechReportSubmittedDate,
  selectTechReportSubmittedBy,
  selectTechnicianNames,
  selectTotalBudgetedHours,
  selectVisitDescription,
  selectJobDescription,
  selectPropertyId,
  selectTotalUsedHours,
  selectReviewReportCreatedBy,
  selectReviewReportCreatedDate
} from '../../selectors';

const combineNameAndDate = (name, date) =>
  name && date ? `${name}, ${date}` : name || date || '-';

const GridTextField = ({ label, value }) => (
  <Grid item xs={6} sm={4} md={2} lg={2}>
    <Field type={FieldType.TEXT} label={label} value={value} />
  </Grid>
);

GridTextField.propTypes = {
  label: string.isRequired,
  value: oneOfType([node, func, string]).isRequired
};

const getNoDataMessage = message => <Typography variant={TV.S1}>{message}</Typography>;

const ReportInfo = ({ job, visit, reviewReport }) => {
  const styles = useStyles();

  const billTo = selectBillTo(job) || '-';
  const billingCustomerAddress = selectCustomerBillingAddress(job) || '-';
  const billingCustomerId = selectBillingCustomerId(job);
  const billingCustomerName = selectBillingCustomerName(job) || '-';
  const propertyAddress = selectCustomerPropertyAddress(job) || '-';
  const customerId = selectCustomerId(job);
  const customerName = selectCustomerName(job) || '-';
  const departmentName = selectDepartmentName(visit) || '-';
  const jobDescription = selectJobDescription(job) || getNoDataMessage('No Job Description');
  const jobType = selectJobType(job) || '-';
  const projectManager = selectProjectManager(job) || '-';
  const propertyId = selectPropertyId(job);
  const propertyName = selectPropertyName(job) || '-';
  const techReportSubmittedBy = selectTechReportSubmittedBy(visit);
  const techReportSubmittedDate = selectTechReportSubmittedDate(visit);
  const reviewReportSubmittedBy = selectReviewReportCreatedBy(reviewReport);
  const reviewReportSubmittedDate = selectReviewReportCreatedDate(reviewReport);
  const technicians = selectTechnicianNames(visit) || '-';
  const totalBudgetedHours = selectTotalBudgetedHours(job) || '-';
  const totalUsedHours = selectTotalUsedHours(job) || '-';
  const visitDescription =
    selectVisitDescription(visit) || getNoDataMessage('No Visit Description');

  const techReportCreator = combineNameAndDate(techReportSubmittedBy, techReportSubmittedDate);
  const reviewReportCreator = combineNameAndDate(
    reviewReportSubmittedBy,
    reviewReportSubmittedDate
  );

  const customerLink = customerId ? (
    <Link to={`/customer/view/${customerId}`}>{customerName}</Link>
  ) : (
    customerName
  );

  const billingCustomerLink = billingCustomerId ? (
    <Link to={`/customer/view/${billingCustomerId}`}>{billingCustomerName}</Link>
  ) : (
    billingCustomerName
  );

  const propertyLink = propertyId ? (
    <Link to={`/property/view/${propertyId}`}>{propertyName}</Link>
  ) : (
    propertyName
  );

  return (
    <ThemeProvider>
      <div css={styles.container}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid container spacing={1}>
              <GridTextField label="Customer" value={customerLink} />
              <GridTextField label="Property" value={propertyLink} />
              <GridTextField label="Property Address" value={propertyAddress} />
              <GridTextField label="Billing Customer" value={billingCustomerLink} />
              <GridTextField label="Bill To" value={billTo} />
              <GridTextField label="Billing Address" value={billingCustomerAddress} />
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <GridTextField label="Department" value={departmentName} />
              <GridTextField label="Project Manager" value={projectManager} />
              <GridTextField label="Technician(s)" value={technicians} />
              <GridTextField label="Job Type" value={jobType} />
              <GridTextField label="Total budgeted hours for job" value={totalBudgetedHours} />
              <GridTextField label="Total used hours" value={totalUsedHours} />
            </Grid>
          </Grid>
          {reviewReport ? (
            <Grid item>
              <Grid container spacing={1}>
                <GridTextField label="Technician report created by" value={techReportCreator} />
                <GridTextField label="Review report created by" value={reviewReportCreator} />
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={8} sm={6} md={4} lg={4}>
              <Field
                type={FieldType.TEXT}
                label="Technician report created by"
                value={techReportCreator}
              />
            </Grid>
          )}
        </Grid>
      </div>
      <ReportSection title="Job Description">{jobDescription}</ReportSection>
      <ReportSection title="Visit Description">{visitDescription}</ReportSection>
    </ThemeProvider>
  );
};

ReportInfo.propTypes = {
  job: object.isRequired,
  visit: object.isRequired,
  reviewReport: object
};

ReportInfo.defaultProps = {
  reviewReport: {}
};

export default withLazyMount(ReportInfo);
