import React from 'react';
import { DndProvider } from 'react-dnd';
import TouchBackend from 'react-dnd-touch-backend';
import HTML5Backend from 'react-dnd-html5-backend';
import { withStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

import Toolbar from './toolbar';
import PreviewModal from './modals/preview';
import EditItemModal from './modals/editItem';
import Row from './row';
import Card from './row/card';
import { Toolbox } from './toolbox';
import { generateMetaLayout } from './GenerateMetaLayout';
import { deconstructMetaLayout } from './DeconstructMetaLayout';

import styles from './styles';

export class FormBuilderApp extends React.Component {
  constructor(props) {
    super(props);
    const { formDefinitionJson, assets, isTouchDevice } = props;
    const initialData = {
      items: {},
      rows: [],
      layout: [0],
      formAssetList: [],
      assets: assets || {},
      editingItem: null,
      previewForm: null,
      workspacePadding: 0
    };

    initialData.rows.push(this.createNewRow([null]));
    let newState = initialData;

    if (formDefinitionJson) {
      newState = deconstructMetaLayout(initialData, formDefinitionJson);
    }
    this.state = newState;
    this.workspaceRef = React.createRef();
    this.itemRegistry = new Set(Object.keys(newState.items));
    this.useTouch = isTouchDevice;
  }

  componentDidUpdate() {
    const { isUpdating, onComplete } = this.props;
    if (isUpdating) {
      const meta = generateMetaLayout(this.state);
      onComplete(meta);
    }
  }

  updateDragging = item => {
    this.setState({
      isDragging: item.dragging,
      draggingType: item.item
    });
  };

  addItem = (dropEvent, row, column) => {
    const { items, rows, layout } = this.state;
    const newItems = { ...items };
    const newRows = Array.from(rows);
    const newLayout = Array.from(layout);
    const { item } = dropEvent;
    const selectedRow = newRows[layout[row]];
    const selectedRowItems = selectedRow.itemIds;
    const isNewRow = !!(selectedRowItems.length === 1 && selectedRowItems[0] === null);
    const isEmptyDropSpot = selectedRowItems[column] === null;
    let itemID = item.id;

    // Create New Item
    if (itemID === null) {
      const newItem = this.createNewItem(item);
      newItems[newItem.id] = newItem;
      itemID = newItem.id;
    }

    // Delete Item in old spot
    if (dropEvent.row !== undefined && selectedRow.availableColumns > 0) {
      this.removeItemFromRow(dropEvent.row, dropEvent.column);
    }

    if (isEmptyDropSpot) {
      // Adding to empty column
      selectedRowItems[column] = itemID;
      selectedRow.availableColumns -= 1;
    } else if (selectedRow.availableColumns > 0) {
      // Add to row by shifting columns
      const firstEmptySpot = selectedRowItems.indexOf(null);
      selectedRowItems.splice(firstEmptySpot, 1);
      selectedRowItems.splice(column, 0, itemID);
      selectedRow.availableColumns -= 1;
    }

    if (isNewRow) {
      // Create new Rows and add them to Item list
      const addRows = [this.createNewRow([null]), this.createNewRow([null])];
      newRows.push(...addRows);

      // Add Empty Row After last clicked Row
      newLayout.splice(row + 1, 0, newRows.length - 1);

      // Add Empty Row Before last clicked row
      newLayout.splice(row, 0, newRows.length - 2);
    }

    const newState = this.reorderLayout(newRows, newLayout);
    newState.items = newItems;

    this.setState(newState);
  };

  setColumns = (row, number) => {
    const { rows, layout } = this.state;
    const newRows = Array.from(rows);
    const selectedRow = newRows[layout[row]];
    const filtered = selectedRow.itemIds.filter(el => el != null);

    selectedRow.numberOfColumns = number;
    selectedRow.columnWidth = `${100 / number}%`;
    selectedRow.availableColumns = number - filtered.length;

    if (selectedRow.availableColumns > 0) {
      // Expand Row Columns
      for (let i = 0; i < selectedRow.availableColumns; i++) {
        selectedRow.itemIds.push(null);
      }
    } else {
      selectedRow.itemIds = filtered;
    }

    this.setState({ rows: newRows });
  };

  createNewRow = itemIds => {
    const newRowTemplate = {
      itemIds
    };
    const nonEmptyColumns = itemIds.filter(el => el != null);
    newRowTemplate.numberOfColumns = itemIds.length;
    newRowTemplate.columnWidth = `${100 / newRowTemplate.numberOfColumns}%`;
    newRowTemplate.availableColumns = newRowTemplate.numberOfColumns - nonEmptyColumns.length;
    return newRowTemplate;
  };

  copyRow = row => {
    const { items, rows, layout } = this.state;
    const newItems = { ...items };
    const newRows = Array.from(rows);
    const newLayout = Array.from(layout);
    const selectedRowID = layout[row];
    const selectedRowItems = newRows[selectedRowID].itemIds;
    const copiedItems = selectedRowItems.map(item =>
      item === null ? item : this.createNewItem(items[item])
    );
    const copiedItemsIds = [];

    for (const item of copiedItems) {
      if (item === null) {
        copiedItemsIds.push(null);
      } else {
        newItems[item.id] = item;
        copiedItemsIds.push(item.id);
      }
    }
    const addRows = [this.createNewRow([null]), this.createNewRow(copiedItemsIds)];
    newRows.push(...addRows);
    newLayout.splice(row + 1, 0, newRows.length - 1);
    newLayout.splice(row + 1, 0, newRows.length - 2);

    const newState = this.reorderLayout(newRows, newLayout);
    newState.items = newItems;

    this.setState(newState);
  };

  deleteRow = row => {
    const { items, rows, layout, assets } = this.state;
    const newItems = { ...items };
    const newAssets = { ...assets };
    const newRows = Array.from(rows);
    const newLayout = Array.from(layout);
    const selectedRowID = layout[row];
    const selectedRow = newRows[selectedRowID];

    // Delete items
    selectedRow.itemIds.forEach(item => {
      if (item !== null) {
        newItems[item] = undefined;
        if (newAssets[item]) newAssets[item] = null;
        this.itemRegistry.delete(item);
      }
    });

    // Set Row to be empty
    selectedRow.itemIds = [null];

    const newState = this.reorderLayout(newRows, newLayout);
    newState.items = newItems;
    newState.assets = newAssets;
    this.setState(newState);
  };

  generateItemID = string => {
    // Make lowercase, removes special characters, replace ' ' with '_'
    const tempId = string
      .toLowerCase()
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .split(' ')
      .join('_');

    return this.incrementItemID(tempId);
  };

  incrementItemID = id => {
    let tempId = id;
    let idAvailable = false;
    let counter = 1;

    // Check if tempId is available
    while (!idAvailable) {
      if (!this.itemRegistry.has(tempId)) {
        idAvailable = true;
      } else {
        const label = tempId.split('-');
        tempId = `${label[0]}-${counter}`;
        counter += 1;
      }
    }
    this.itemRegistry.add(tempId);
    return tempId;
  };

  createNewItem = template => {
    const newItem = { ...template };
    const oldId = newItem.id;
    let newItemId;
    if (!oldId) {
      newItemId = this.generateItemID(newItem.label);
    } else {
      newItemId = this.incrementItemID(oldId);
    }
    newItem.id = newItemId;
    return newItem;
  };

  copyItem = (row, column) => {
    const { items, rows, layout } = this.state;
    const newItems = { ...items };
    const newRows = Array.from(rows);
    const newLayout = Array.from(layout);
    const selectedRow = newRows[layout[row]];
    const oldItem = items[selectedRow.itemIds[column]];
    const newItem = this.createNewItem(oldItem);

    newItems[newItem.id] = newItem;

    if (selectedRow.availableColumns > 0) {
      // Put in the first 'null' column
      const firstEmptyColumn = selectedRow.itemIds.indexOf(null);
      selectedRow.itemIds[firstEmptyColumn] = newItem.id;
      selectedRow.availableColumns -= 1;
    } else {
      // Create new row
      const addRows = [this.createNewRow([null]), this.createNewRow([newItem.id])];
      newRows.push(...addRows);
      newLayout.splice(row + 1, 0, newRows.length - 1);
      newLayout.splice(row + 1, 0, newRows.length - 2);
    }

    const newState = this.reorderLayout(newRows, newLayout);
    newState.items = newItems;

    this.setState(newState);
  };

  updateItem = item => {
    const { items, assets } = this.state;
    const newItem = { ...item };
    const newItems = { ...items };
    const newAssets = { ...assets };

    // Update item attributes
    newItems[newItem.id] = newItem;
    // Update preloaded assets
    if (item.source && item.source[0].file) {
      newAssets[item.id] = null;
    }

    this.setState({ items: newItems, assets: newAssets });
    this.closeEditItemModal();
  };

  removeItemFromRow = (row, column) => {
    const { rows, layout } = this.state;
    const newRows = Array.from(rows);
    const newLayout = Array.from(layout);
    const selectedRow = newRows[layout[row]];
    selectedRow.itemIds[column] = null;
    selectedRow.availableColumns += 1;

    const newState = this.reorderLayout(newRows, newLayout);
    this.setState(newState);
  };

  deleteItem = (row, column) => {
    const { items, rows, layout, assets } = this.state;
    const newItems = { ...items };
    const newRows = Array.from(rows);
    const newLayout = Array.from(layout);
    const newAssets = { ...assets };
    const selectedRow = newRows[layout[row]];
    const deletedItemId = selectedRow.itemIds[column];
    if (newAssets[deletedItemId]) {
      newAssets[deletedItemId] = null;
    }
    selectedRow.itemIds[column] = null;
    selectedRow.availableColumns += 1;
    newItems[deletedItemId] = undefined;
    this.itemRegistry.delete(deletedItemId);
    const newState = this.reorderLayout(newRows, newLayout);
    newState.items = newItems;
    newState.assets = newAssets;
    this.setState(newState);
  };

  reorderLayout = (rows, layout) => {
    const newRows = [];
    const newLayout = [];
    let lastRowEmpty = false;

    for (const rowIndex of layout) {
      const currentRow = rows[rowIndex];
      const filtered = currentRow.itemIds.filter(el => el != null);
      const isEmpty = filtered.length === 0;
      currentRow.length = currentRow.numberOfColumns;

      if (lastRowEmpty !== isEmpty) {
        newRows.push(currentRow);
        newLayout.push(newRows.length - 1);
      }

      lastRowEmpty = isEmpty;
    }

    const newState = {
      rows: newRows,
      layout: newLayout
    };
    this.updateWorkspaceHeight();

    return newState;
  };

  getLocalAssets = id => {
    const { assets } = this.state;
    return assets[id];
  };

  openEditItemModal = (item, row, column) => {
    this.setState({ editingItem: [item, row, column] });
  };

  closeEditItemModal = () => this.setState({ editingItem: null });

  openPreviewFormModal = type => {
    const { meta } = generateMetaLayout(this.state);
    this.setState({ previewModalType: type, previewForm: meta });
  };

  closePreviewFormModal = () => this.setState({ previewForm: null });

  updateWorkspaceHeight = () => {
    const element = this.workspaceRef;
    const { windowHeight } = this.props;
    if (element) {
      const height = element.current.clientHeight;
      const bottomPadding = Math.min(height / 2, windowHeight / 2);
      this.setState({ workspacePadding: bottomPadding });
    }
  };

  moveCard = (dragIndex, hoverIndex) => {
    const { rows, layout } = this.state;
    const newRows = Array.from(rows);
    const newLayout = Array.from(layout);
    const rowID = newLayout[dragIndex];

    // Remove Row from layout and then insert at new spot
    newLayout.splice(dragIndex, 1);
    newLayout.splice(hoverIndex, 0, rowID);

    // Add surrounding empty rows
    const addRows = [this.createNewRow([null]), this.createNewRow([null])];
    newRows.push(...addRows);
    newLayout.splice(hoverIndex + 1, 0, newRows.length - 1);
    newLayout.splice(hoverIndex, 0, newRows.length - 2);

    this.setState(this.reorderLayout(newRows, newLayout));
  };

  render() {
    const {
      classes,
      name,
      description,
      associatedEntityType,
      assets,
      updateFormProperties,
      developerVersion
    } = this.props;

    const {
      items,
      rows,
      layout,
      editingItem,
      previewForm,
      previewModalType,
      isDragging,
      workspacePadding
    } = this.state;

    let blankWorkspace = false;

    if (rows.length === 1 && rows[0].numberOfColumns === 1 && rows[0].itemIds[0] === null) {
      blankWorkspace = true;
    }

    return (
      <DndProvider backend={this.useTouch ? TouchBackend : HTML5Backend}>
        <CssBaseline />
        <div className={classes.root}>
          <div className={classes.board}>
            <Toolbar
              classes={classes}
              name={name}
              description={description}
              associatedEntityType={associatedEntityType}
              updateFormProperties={updateFormProperties}
              openPreviewFormModal={this.openPreviewFormModal}
              developerVersion={developerVersion}
            />
            <div
              ref={this.workspaceRef}
              style={{ marginBottom: workspacePadding }}
              className={classes.workspace}
            >
              {layout.map((rowID, index) => {
                const row = rows[rowID];
                const rowItems = row.itemIds.map(id => {
                  if (id === null) return null;
                  return items[id];
                });
                const isEmpty = rowItems.length === 1 && rowItems[0] === null;
                const rowElement = (
                  <Row
                    key={`row-${index}`}
                    classes={classes}
                    blankWorkspace={blankWorkspace}
                    rowNumber={index}
                    setColumns={this.setColumns}
                    handleDrop={this.addItem}
                    copyRow={this.copyRow}
                    deleteRow={this.deleteRow}
                    draggingItem={isDragging}
                    updateDragging={this.updateDragging}
                    deleteItem={this.deleteItem}
                    items={rowItems}
                    openEditItemModal={this.openEditItemModal}
                    getLocalAssets={this.getLocalAssets}
                    {...row}
                  />
                );
                return isEmpty ? (
                  rowElement
                ) : (
                  <Card key={`row-${index}`} index={index} id={index} moveCard={this.moveCard}>
                    {rowElement}
                  </Card>
                );
              })}
            </div>
          </div>
          <Toolbox
            updateDragging={this.updateDragging}
            addItem={this.addItem}
            classes={classes}
            developerVersion={developerVersion}
          />
          {editingItem && (
            <EditItemModal
              item={editingItem}
              submit={this.updateItem}
              copyItem={this.copyItem}
              deleteItem={this.deleteItem}
              handleClose={this.closeEditItemModal}
              currentSmartField={associatedEntityType}
              classes={classes}
            />
          )}
          {previewForm && (
            <PreviewModal
              classes={classes}
              meta={previewForm}
              assets={assets}
              handleClose={this.closePreviewFormModal}
              modal={previewModalType}
            />
          )}
        </div>
      </DndProvider>
    );
  }
}

const styled = withStyles(styles)(FormBuilderApp);
export { styled as StyledFormBuilder };
