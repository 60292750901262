import React from 'react';

import Typography from '@material-ui/core/Typography';

import { array, bool } from 'prop-types';

import { useStyles } from './EmptyAttachmentsList.styles';

const EmptyAttachmentsList = ({ images, files, noTitle }) => {
  const styles = useStyles();

  if (images.length !== 0 || files.length !== 0 || noTitle) {
    return null;
  }
  return <Typography css={styles.noAttachment}>No Attachments</Typography>;
};

EmptyAttachmentsList.propTypes = {
  images: array.isRequired,
  files: array.isRequired,
  noTitle: bool.isRequired
};

export default EmptyAttachmentsList;
