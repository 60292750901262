import gql from 'graphql-tag';

const addAttachmentsToQuote = gql`
  mutation addAttachmentsToQuote($partitionKey: String!, $data: AddAttachmentsToQuoteInput!) {
    addAttachmentsToQuote(partitionKey: $partitionKey, data: $data) {
      id
      version
      customFileName
      fileUrl
      fileName
      description
      comment
      type
      createdDate
    }
  }
`;

export default addAttachmentsToQuote;
