import gql from 'graphql-tag';

export const GET_QUOTE_PRESETS = gql`
  query getQuotePresets($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      userSettings(
        entityConnection: "UserSetting"
        filter: { type: { eq: "QUOTE_PRESET_SETTING" } }
      ) {
        nextToken
        items {
          type
          displayName
          settings
          access
          id
        }
      }
    }
  }
`;

export const SAVE_QUOTE_PRESET_SETTING = gql`
  mutation SaveQuotePresetSetting($data: SavePresetSettingInput!) {
    saveQuotePresetSetting(data: $data) {
      id
      displayName
      settings
      type
      name
    }
  }
`;
