export const useStyles = () => ({
  greenCheckIcon: theme => ({
    color: theme.palette.support.green.dark,
    fontSize: 18,
    marginRight: theme.default.spacing(0.5)
  }),
  redWarningIcon: theme => ({
    color: theme.palette.support.red.dark,
    fontSize: 18,
    marginRight: theme.default.spacing(0.5)
  }),
  iconHeader: theme => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.default.spacing(1)
  }),
  sectionSpacing: theme => ({
    marginTop: theme.default.spacing(3)
  })
});
