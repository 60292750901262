import { ElementSizes } from '@dispatch/Dispatch.styles';

import { CardTypes } from '../../DispatchBoard.constants';

export const LOADING_TECHS = new Array(10).fill({
  techLoading: true,
  techEvents: Array.from({ length: 7 }).map(() => [])
});

// modified to account for 2px bottom margin
export const WeekCardHeights = {
  [CardTypes.AvailableTime]: ElementSizes.availableTimeHeight,
  [CardTypes.NonVisitEvent]: ElementSizes.cellHeight + 1,
  [CardTypes.Visit]: ElementSizes.cellHeight + 1,
  [CardTypes.ManDay]: ElementSizes.cellHeight + 1
};
