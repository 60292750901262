import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import {
  getContentText,
  getCODescriptionTitle,
  calculateCOContractValue
} from '@pm/components/utils';
import {
  getTotalCompletedAndStoredToDate,
  getPercentageOfTotalCompletedAndStoredToDate,
  getBalanceToFinish
} from './calculations';
import styles from '../style';

const ContinuationSheetTableRowsForChangeOrders = ({ coLineItems }) => {
  return (
    <View>
      {coLineItems?.map(coLineItem => (
        <View style={styles.displayFlex} key={coLineItem.id}>
          <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '5%' }]}>
            {coLineItem.itemNumber}
          </Text>
          <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '27%' }]}>
            {coLineItem.description}
          </Text>
          <Text style={[styles.text, styles.innerCell, styles.numberAlign, { width: '12%' }]}>
            {getContentText('currency', coLineItem.contractValue, 2)}
          </Text>
          <Text style={[styles.text, styles.innerCell, styles.numberAlign, { width: '8%' }]}>
            {getContentText('currency', coLineItem.totals?.totalCompletedAndStored || 0, 2)}
          </Text>
          <Text style={[styles.text, styles.innerCell, styles.numberAlign, { width: '8%' }]}>
            {getContentText('currency', coLineItem.workCompleted, 2)}
          </Text>
          <Text style={[styles.text, styles.innerCell, styles.numberAlign, { width: '10%' }]}>
            {getContentText('currency', coLineItem.materialsStored, 2)}
          </Text>
          <Text style={[styles.text, styles.innerCell, styles.numberAlign, { width: '10%' }]}>
            {getContentText(
              'currency',
              getTotalCompletedAndStoredToDate(
                coLineItem.totals?.totalCompletedAndStored || 0,
                coLineItem.workCompleted,
                coLineItem.materialsStored
              ),
              2
            )}
          </Text>
          <Text style={[styles.text, styles.innerCell, styles.numberAlign, { width: '8%' }]}>
            {getContentText(
              'percentage',
              getPercentageOfTotalCompletedAndStoredToDate(coLineItem),
              2
            )}
          </Text>
          <Text
            style={[
              styles.text,
              styles.innerCell,
              styles.numberAlign,
              { width: '12%', borderRight: 0 }
            ]}
          >
            {getContentText(
              'currency',
              getBalanceToFinish(coLineItem, coLineItem.contractValue),
              2
            )}
          </Text>
        </View>
      ))}
    </View>
  );
};

ContinuationSheetTableRowsForChangeOrders.propTypes = {
  coLineItems: PropTypes.array.isRequired
};

export default ContinuationSheetTableRowsForChangeOrders;
