import { makeStyles } from '@material-ui/core';

import { ElementSizes, DispatchTheme } from '@dispatch/Dispatch.styles';

const cellMixin = {
  width: ElementSizes.cellWidth,
  height: '100%',
  borderRight: DispatchTheme.mixins.mainBorder,
  borderBottom: DispatchTheme.mixins.mainBorder
};

export const useStyles = makeStyles(theme => ({
  timeCell: {
    ...cellMixin
  },
  timeCellPassed: {
    ...cellMixin,
    backgroundColor: theme.palette.grayscale(96),
    mixBlendMode: 'multiply'
  },
  timeCellPassedOverlay: {
    height: '100%',
    backgroundColor: theme.palette.grayscale(96),
    mixBlendMode: 'multiply'
  }
}));
