import { FieldType } from '@buildhero/sergeant';

const padding = 8;
const fieldWidth = 240;
const fieldStyle = {
  style: {
    padding,
    width: fieldWidth
  }
};

const rowStyle = {
  style: {
    gap: 16,
    flexDirection: 'row',
    marginBottom: 16,
    justifyContent: 'space-evently'
  }
};

export const layout = ({
  selectedProperties = [],
  propertyRepOptions = [],
  maintenanceTypeOptions,
  handleOpenCustomerRepPopUp,
  fetchingReps
}) => ({
  layoutsList: 'default',
  layouts: {
    default: {
      contents: [
        {
          props: {
            style: { paddingBottom: 64, width: 752, margin: 'auto' }
          },
          contents: [
            {
              props: {
                ...rowStyle
              },
              contents: [
                {
                  component: 'Field',
                  source: 'agreementInfo.agreementNumber',
                  props: {
                    label: 'Service Agreement Number',
                    type: FieldType.TEXT,
                    ...fieldStyle
                  }
                },
                {
                  component: 'Select',
                  source: 'property',
                  props: {
                    label: 'Property',
                    type: FieldType.TEXT,
                    required: true,
                    options: selectedProperties,
                    placeholder: 'Select Property',
                    ...fieldStyle
                  }
                },
                {
                  component: 'Input',
                  source: 'dueDate',
                  props: {
                    label: 'Due Date',
                    type: FieldType.DATE,
                    required: true,
                    placeholder: 'Select Due Date',
                    ...fieldStyle
                  }
                }
              ]
            },
            {
              props: {
                ...rowStyle
              },
              contents: [
                {
                  component: 'Select',
                  source: 'maintenanceType',
                  props: {
                    label: 'Maintenance Type',
                    required: true,
                    options: maintenanceTypeOptions,
                    placeholder: 'Select Maintenance Type',
                    enableSort: false,
                    ...fieldStyle
                  }
                },
                {
                  component: 'Select',
                  source: 'propertyRep',
                  required: true,
                  resolveProps: ({ property }) => ({
                    disabled: !property?.value || fetchingReps
                  }),
                  props: {
                    creatable: true,
                    onCreateOption: handleOpenCustomerRepPopUp,
                    isSearchable: true,
                    label: 'Property Rep',
                    placeholder: 'Select Property Rep',
                    options: propertyRepOptions,
                    ...fieldStyle
                  }
                },
                {
                  component: 'Input',
                  source: 'poNumber',
                  resolveProps: () => ({
                    disabled: fetchingReps
                  }),
                  props: {
                    label: 'PO Number',
                    type: FieldType.TEXT,
                    placeholder: 'Enter PO Number',
                    ...fieldStyle
                  }
                }
              ]
            },
            {
              props: {
                ...rowStyle,
                marginLeft: 1,
                marginRight: 1
              },
              contents: [
                {
                  component: 'Input',
                  source: 'serviceDescription',
                  resolveProps: () => ({
                    disabled: fetchingReps
                  }),
                  props: {
                    label: 'Service Description',
                    fullWidth: true,
                    placeholder: 'Enter Service Description'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  }
});
