import { PermissionAction, PermissionCategory, PermissionSubcategory } from 'utils/constants';
import PermissionsUtils from 'utils/PermissionsUtils';

class AddButtonUtils {
  static hasQuoteToPoPermissions(appPermissionRules) {
    const convertedAppPermissionRules = JSON.parse(appPermissionRules);
    return (
      Array.isArray(convertedAppPermissionRules) &&
      (PermissionsUtils.hasTenantAdminPermissions(convertedAppPermissionRules) ||
        (PermissionsUtils.hasRequiredUserPermissions({
          convertedAppPermissionRules,
          action: PermissionAction.ALLOW,
          category: PermissionCategory.DATA,
          subcategory: PermissionSubcategory.QUOTE_TO_PO
        }) &&
          PermissionsUtils.hasRequiredUserPermissions({
            convertedAppPermissionRules,
            action: PermissionAction.UPDATE,
            category: PermissionCategory.OBJECT,
            subcategory: PermissionSubcategory.QUOTES
          }) &&
          PermissionsUtils.hasRequiredUserPermissions({
            convertedAppPermissionRules,
            action: PermissionAction.CREATE,
            category: PermissionCategory.OBJECT,
            subcategory: PermissionSubcategory.PURCHASE_ORDER
          })))
    );
  }

  static hasProjectPermissions(appPermissionRules) {
    const convertedAppPermissionRules = JSON.parse(appPermissionRules);
    return (
      Array.isArray(convertedAppPermissionRules) &&
      (PermissionsUtils.hasTenantAdminPermissions(convertedAppPermissionRules) ||
        PermissionsUtils.hasRequiredUserPermissions({
          convertedAppPermissionRules,
          action: PermissionAction.ALLOW,
          category: PermissionCategory.MENU,
          subcategory: PermissionSubcategory.PROJECT_MANAGEMENT
        }))
    );
  }
}

export default AddButtonUtils;
