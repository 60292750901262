import React from 'react';
import { jsx } from '@emotion/react';
import { useTheme } from '@material-ui/core/styles';
import { Typography, TV, TW, NumberInput, Input } from '@buildhero/sergeant';
import { IconButton } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

const style = {
  row: {
    height: 36,
    marginTop: 4,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerRow: {
    marginTop: 8,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  name: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(34% - 4px)',
    marginRight: 4
  },
  nameText: {},
  nameHeader: {
    width: 'calc(34% - 4px)',
    marginRight: 4,
    paddingLeft: 30
  },
  code: {
    width: '12%'
  },
  codeText: {
    width: 'calc(12% - 4px)',
    marginRight: 4
  },
  description: {
    width: '47%'
  },
  quantity: {
    width: '8%',
    paddingLeft: 4
  },
  icon: {
    marginRight: 8
  },
  closeButton: {
    width: 14,
    height: 14
  }
};

const Header = props => {
  const { palette } = useTheme();

  return <Typography color={palette.text.tertiary} variant={TV.S2} weight={TW.MEDIUM} {...props} />;
};

const PartsAndMaterialsLineItem = ({ option, selectedOptions, partsAndMaterialsChange }) => {
  const showHeaders = option.id === selectedOptions[0].id;

  return (
    <>
      {showHeaders && (
        <div css={style.headerRow}>
          <div css={style.nameHeader}>
            <Header>ITEM NAME </Header>
          </div>
          <div css={style.code}>
            <Header>ITEM CODE </Header>
          </div>
          <div css={style.description}>
            <Header>ITEM DESCRIPTION </Header>
          </div>
          <div css={style.quantity}>
            <Header>QUANTITY</Header>
          </div>
        </div>
      )}

      <div css={style.row}>
        <div css={style.name}>
          <div css={style.icon}>
            <IconButton
              size="small"
              aria-label="close"
              onClick={() => {
                const newItems = selectedOptions.filter(it => it.id !== option.id);
                partsAndMaterialsChange(newItems);
              }}
            >
              <CloseIcon css={style.closeButton} />
            </IconButton>
          </div>
          <Typography variant={TV.BASE} weight={TW.MEDIUM}>
            {option.value.name}
          </Typography>
        </div>
        <div css={style.codeText}>
          <Typography variant={TV.BASE} weight={TW.MEDIUM}>
            {option.value.code}
          </Typography>
        </div>
        <div css={style.description}>
          <Input
            placeholder="Description"
            onBlur={e => {
              const newItems = selectedOptions.map(o => {
                if (o.id !== option.id) return o;
                return { ...o, value: { ...o.value, description: e.target.value } };
              });
              partsAndMaterialsChange(newItems);
            }}
            style={{ width: '100%' }}
            inputProps={{ style: { height: 15 } }}
            defaultValue={option.value.description}
            key={option.id}
          />
        </div>
        <div css={style.quantity}>
          <NumberInput
            value={option.value.quantity}
            onBlur={value => {
              const newItems = selectedOptions.map(o => {
                if (o.id !== option.id) return o;
                return { ...o, value: { ...o.value, quantity: value } };
              });
              partsAndMaterialsChange(newItems);
            }}
            inputProps={{ style: { height: 15 } }}
          />
        </div>
      </div>
    </>
  );
};

export default PartsAndMaterialsLineItem;
