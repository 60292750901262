/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';

import { Button, ButtonType, MUIForm, ThemeProvider } from '@buildhero/sergeant';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ActionsMenu, PageHeader, StatusChip, withLoading, withMultipleForms } from 'components';
import { checkPermission } from 'utils';

import NamedIcon from '../ResponsiveTable/NamedIcon';

import styles from './LeftSidebarWithContent.styles';

const loadingParams = {
  leftSection: {
    variant: 'table',
    repeatCount: 5,
    paddingTop: 12,
    paddingLeft: 8,
    paddingRight: 8
  },
  mainSection: {
    paddingLeft: 24,
    paddingTop: 24
  }
};

const MuiFormWithLoading = withLoading(MUIForm);

const LeftSidebarWithContent = props => {
  const {
    classes,
    showCancelButton,
    headerProps,
    mainSectionProps,
    leftSectionProps,
    children,
    buttonLabel,
    TitleSubText,
    TitlePostText,
    handleFormsSubmit,
    handleSubmitStart,
    actionButtons,
    showNewButton,
    newButtonLabel,
    actionButtonHandler,
    statusBackgroundColor,
    actionIconName,
    handleEdit,
    handleCancel,
    TagButtons,
    getHandleCreateService,
    getHandleComplete,
    setOnSubmitFinal,
    isLoading,
    isSubmitting,
    showSubmitButton,
    mode,
    shouldDisallowEditing,
    statusLabel,
    statusIcon,
    statusLabelColor,
    defaultSubmitButton: SubmitBtn,
    useNewMenuStyle = false,
    customHeaderButtons,
    caslKey,
    ServiceChannelButton
  } = props;
  const user = useSelector(s => s.user);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const setMode = React.useCallback(
    newMode => {
      if (mode === 'new') return;
      const { location } = history;
      const newPath = location.pathname.replace(mode, newMode);
      history.push(newPath);
    },
    [history, mode]
  );

  const showActionMenu = event => {
    setAnchorEl({ ref: event.currentTarget });
  };

  // Effect to set final submit callback for multiple forms HOC.
  useEffect(() => {
    setOnSubmitFinal(handleFormsSubmit);
  }, [handleFormsSubmit, setOnSubmitFinal]);

  const OverrideSubmitBtn = () => (
    <SubmitBtn handle={handleSubmitStart} disabled={isSubmitting} showSpinner={isSubmitting} />
  );

  const haveAccessToEdit = caslKey ? checkPermission('edit', caslKey, user) : true;
  const haveAccessToCreate = caslKey ? checkPermission('create', caslKey, user) : true;

  const headerButtons = customHeaderButtons
    ? customHeaderButtons({
        handleSubmitStart,
        isSubmitting,
        shouldDisallowEditing
      })
    : [
        <>
          {mode === 'edit' && showCancelButton && (
            <ThemeProvider>
              <Button
                type={ButtonType.TERTIARY}
                onClick={() => {
                  handleCancel ? handleCancel() : setMode('view');
                }}
                style={{ marginRight: 8 }}
              >
                Cancel
              </Button>
            </ThemeProvider>
          )}
          {showSubmitButton && haveAccessToEdit && (
            <ThemeProvider>
              <Button
                type={mode === 'edit' ? ButtonType.PRIMARY : ButtonType.TERTIARY}
                disabled={isSubmitting || shouldDisallowEditing}
                loading={isSubmitting}
                style={{ marginRight: 8 }}
                onClick={() => {
                  if (mode === 'edit') {
                    handleSubmitStart();
                  } else {
                    handleEdit ? handleEdit() : setMode('edit');
                  }
                }}
              >
                {buttonLabel[mode] || buttonLabel.default}
              </Button>
            </ThemeProvider>
          )}
          <OverrideSubmitBtn />
          {showNewButton && haveAccessToCreate && (
            <ThemeProvider>
              <Button
                type={ButtonType.PRIMARY}
                style={{ marginLeft: 8 }}
                onClick={() => setMode('new')}
              >
                {newButtonLabel}
              </Button>
            </ThemeProvider>
          )}
          {headerProps.customButtons && <ThemeProvider>{headerProps.customButtons}</ThemeProvider>}
          {!isEmpty(actionButtons) && haveAccessToEdit && (
            <>
              <IconButton aria-label="More Icon" onClick={showActionMenu} testingid="pageActions">
                <NamedIcon name={actionIconName} className={classes.iconButton} />
              </IconButton>

              <ActionsMenu
                rowActions={actionButtonHandler}
                handleMenuClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                rowActionButtons={actionButtons}
                useNewMenuStyle={useNewMenuStyle}
              />
            </>
          )}
        </>
      ];

  const additionalTitleComponents = [
    TitleSubText,
    ServiceChannelButton,
    TagButtons,
    TitlePostText
  ].filter(Boolean);

  return (
    <Box display="flex" flexDirection="column">
      <PageHeader
        statusLabel={
          statusLabel && (
            <StatusChip
              label={statusLabel}
              className={classes.statusChip}
              backgroundColor={statusBackgroundColor}
              showIcon={!!statusIcon}
              icon={statusIcon}
              textColor={statusLabelColor}
              css={{ borderRadius: 2 }}
            />
          )
        }
        additionalTitleComponents={additionalTitleComponents}
        overrideHeaderButtons={buttonLabel || customHeaderButtons ? headerButtons : null}
        {...headerProps}
      />
      <div className={classes.body}>
        <div className={classes.sidebar}>
          <div className={classes.sidebarInner}>
            {leftSectionProps && (
              <MuiFormWithLoading
                {...leftSectionProps}
                layout={mode}
                loadingParams={loadingParams.leftSection}
                onCreateService={getHandleCreateService('sidebar')}
                onComplete={getHandleComplete('sidebar')}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
        <div className={classes.detail}>
          <div className={classes.detailForm}>
            {mainSectionProps && (
              <MuiFormWithLoading
                {...mainSectionProps}
                layout={mode}
                loadingParams={loadingParams.mainSection}
                onCreateService={getHandleCreateService('main')}
                onComplete={getHandleComplete('main')}
                isLoading={isLoading}
              />
            )}
          </div>
          <div className={classes.childContainer}>{children}</div>
        </div>
      </div>
    </Box>
  );
};

const MultipleForms = withMultipleForms(LeftSidebarWithContent, ['main', 'sidebar']);
const WithWidth = withWidth()(MultipleForms);
const Styled = withStyles(styles)(WithWidth);

LeftSidebarWithContent.propTypes = {
  mode: PropTypes.string,
  headerProps: PropTypes.object,
  mainSectionProps: PropTypes.object,
  leftSectionProps: PropTypes.object,
  shouldDisallowEditing: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  showNewButton: PropTypes.bool,
  showSubmitButton: PropTypes.bool,
  buttonLabel: PropTypes.object,
  newButtonLabel: PropTypes.string,
  actionButtonHandler: PropTypes.func,
  handleSubmitStart: PropTypes.func,
  actionButtons: PropTypes.object,
  statusLabel: PropTypes.string,
  statusBackgroundColor: PropTypes.string,
  statusIcon: PropTypes.object,
  statusLabelColor: PropTypes.string,
  actionIconName: PropTypes.string,
  defaultSubmitButton: PropTypes.func,
  caslKey: PropTypes.string
};

LeftSidebarWithContent.defaultProps = {
  mode: 'new',
  headerProps: {},
  buttonLabel: { edit: 'Edit', default: 'Submit' },
  shouldDisallowEditing: false,
  showNewButton: false,
  showSubmitButton: true,
  showCancelButton: true,
  mainSectionProps: {},
  leftSectionProps: {},
  actionButtons: {},
  statusLabel: '',
  statusBackgroundColor: '#333333ff',
  statusIcon: {},
  statusLabelColor: null,
  newButtonLabel: '',
  actionIconName: 'MoreVert',
  handleSubmitStart: () => {},
  actionButtonHandler: () => {},
  defaultSubmitButton: () => null,
  caslKey: ''
};

export default Styled;
