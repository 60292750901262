import React, { Component, Fragment } from 'react';
import Moment from 'react-moment';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import styles from './styles';
import { getNumberValue } from 'utils';
import LinkButton from 'components/Buttons/BlueText';
import Modal from 'components/Modal';
import MultiNotes from '../../../../MultiNotes';
import AppConstants from 'utils/AppConstants';

class CondensedNotesWithData extends Component {
  state = {
    open: false,
    titleName: '',
    data: [],
    parent: ''
  };

  handleClosePopUp = () => {
    this.setState({ open: false, data: [] });
  };

  handleOpenPopUp = (data, titleName, parent) => {
    this.setState({ open: true, data: data, titleName: titleName, parent: parent });
  };

  render() {
    const { classes, fieldProps, specialbehaviour, mode } = this.props;
    const itemList = this.props.form.values[this.props.fieldProps.value];

    // TODO: filtering logic for latest note
    let displayNote = '';
    if (itemList && itemList.length > 0) {
      displayNote = itemList[0];
    }

    // TODO: always refer to object reference field name, departments are not having these defs
    const refFieldName =
      specialbehaviour && specialbehaviour.objectReferenceFieldName
        ? specialbehaviour.objectReferenceFieldName
        : 'tagName';
    // construct table
    // when values exist build rows
    // if string one text cell
    // if JSON, get tableCols and iterate
    let smValue;

    let mdValue;

    let lgValue = 12;
    if (fieldProps.behavior[mode]) {
      smValue = getNumberValue(fieldProps.behavior[mode].sm) || 12;
      mdValue = getNumberValue(fieldProps.behavior[mode].md) || 4;
      lgValue = getNumberValue(fieldProps.behavior[mode].lg) || 2;
    }
    return (
      <Grid item xs={smValue} sm={smValue} md={mdValue} lg={lgValue} xl={lgValue}>
        <Typography variant="caption" className={classes.label}>
          {this.props.label}
        </Typography>

        {displayNote && (
          <Grid item xs={12}>
            <Typography className={classes.content} key="latestNote">
              {displayNote[refFieldName]}
            </Typography>
          </Grid>
        )}
        {displayNote.lastUpdatedDateTime && (
          <Grid item xs={12} className={classes.footer}>
            <Typography variant="caption" className={classes.label} key="footer">
              Last edited by {displayNote.lastUpdatedBy} on &nbsp;
              <Moment unix format={AppConstants.DATETIME_FORMAT}>
                {displayNote.lastUpdatedDateTime / 1000}
              </Moment>
            </Typography>
          </Grid>
        )}
        {specialbehaviour.moreLinks.map((noteLink, index) => (
          <Fragment key={`frag${index}`}>
            <Grid item xs={12} key={`grd${index}`}>
              <LinkButton
                label={noteLink.linkName}
                style={{ paddingLeft: 0 }}
                handle={() =>
                  this.handleOpenPopUp(
                    (this.props.queryResult || {})[noteLink.dataPath],
                    noteLink.titleName,
                    (this.props.queryResult || {})[noteLink.parent]
                  )
                }
                key={`linkbtn${index}`}
              />
            </Grid>
            <Modal
              open={this.state.open}
              handleClose={() => {
                this.handleClosePopUp();
                (this.props.queryResult || {})[noteLink.refetch]();
              }}
              width="696"
              key={`modal${index}`}
            >
              <MultiNotes
                notesData={this.state.data}
                title={this.state.titleName}
                parent={this.state.parent}
                key={`mutlinote${index}`}
              />
            </Modal>
          </Fragment>
        ))}
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CondensedNotesWithData);
