import { useEffect, useMemo } from 'react';
import { makeUseAxios } from 'axios-hooks';

import useApiLifeCycle from 'customHooks/useApiLifeCycle';

// TODO: Hoist to global scope
import { handleError } from '@dispatch/Dispatch.utils';

const defaultOptions = { manual: false, useCache: false, ssr: false };

export function createUseExtendedFetch(axiosInstance, cache) {
  /**
   * A wrapper around the base `useAxios` (https://github.com/simoneb/axios-hooks#useaxiosurlconfig-options) utility that adds additional options
   * and feature support.
   *
   * @param urlOrConfig
   * @param onSuccess - callback that returns the request data on network request success
   * @param onRequest - callback fired on network request start
   * @param onError - callback that returns an error if the network request fails
   * @param onResponse - callback that returns an object { error, data } and fires on request response regardless of success or failure
   * @param hideError - boolean - disable automatic UI error toast
   * @param loadingData - will be returned as data when the query is loading
   * @param errorData - will be returned as data when there is an error
   * @param defaultData - will be returned if data is falsy. errorData and loadingData take priority
   * @param skip - boolean - skips network requests if false. Manual executions unaffected.
   * @param depends - an array of dependencies that will trigger a request if changed. Much like a `useEffect` deps array.
   * @param options - additional options described in axios hooks docs
   * @returns {[{data: *, response?: AxiosResponse<any>, loading: boolean, error?: AxiosError<any>}, ((config?: AxiosRequestConfig, options?: RefetchOptions) => AxiosPromise<any>), (() => void)]}
   */
  return function useExtendedFetch(
    urlOrConfig,
    {
      onSuccess,
      onRequest,
      onError,
      onResponse,
      hideError,
      loadingData,
      errorData,
      defaultData,
      skip,
      depends,
      transform = data => data,
      ...options
    } = {}
  ) {
    const manual = Boolean(options.manual || skip || depends);
    const useAxios = makeUseAxios({ axios: axiosInstance, cache, defaultOptions });

    const [response, execute, manualCancel] = useAxios(urlOrConfig, {
      ...options,
      manual
    });

    const data = (() => {
      if (response.loading && loadingData) return loadingData;
      if (response.error && errorData) return errorData;
      if (response.data) return transform(response.data);

      return defaultData;
    })();

    const updatedResponse = useMemo(
      () => ({
        ...response,
        data
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [data, response.loading, response.error]
    );

    useEffect(() => {
      if (depends && !skip) execute();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, depends || []);

    useApiLifeCycle(response, {
      onError: err => {
        options?.onError?.(err);
        handleError({ err, hideError });
      },
      onRequest,
      onSuccess,
      onResponse
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => [updatedResponse, execute, manualCancel], [updatedResponse]);
  };
}
