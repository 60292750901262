import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import * as Yup from 'yup';
import { MUIFormComponentNames } from '@buildhero/sergeant';

import { getState } from 'redux/store';
import { customerRepSearch } from 'services/API/customerRep';
import { employeeSearch } from 'services/API/employee';

const defaultFlexStyle = '0 1 219px';
const margin = '0px 15px 21px 0px';
const centerMargin = '0px 20px 10px 5px';

export const payApplicationFields = (previousPeriodTo, givenProjectStartDate) => {
  const project = getState()?.pm?.project;
  const projectStartDate =
    givenProjectStartDate || Number(project?.dateStart || project?.createdDate);
  const compareDate = previousPeriodTo || projectStartDate;
  const errMessage = previousPeriodTo
    ? `Must be after last Pay Application's Period To (${moment
        .unix(previousPeriodTo)
        .format('L')})`
    : `Must be at or after project start date (${moment.unix(projectStartDate).format('L')})`;

  return Yup.object().shape({
    sendTo: Yup.string().required('Field is required'),
    returnTo: Yup.string().required('Field is required'),
    contractFor: Yup.string().required('Field is required'),
    number: Yup.string().required('Field is required'),
    periodFrom: Yup.number()
      .required('Field is required')
      .min(compareDate, errMessage)
      .nullable(),
    periodTo: Yup.number()
      .required('Field is required')
      .moreThan(Yup.ref('periodFrom'), 'Must be after Period From')
      .nullable(),
    distributeTo: Yup.string().required('Field is required'),
    applicationDate: Yup.number()
      .required('Field is required')
      .nullable()
  });
};

const generatePayApplicationContents = ({ customerId, disabled = true }) => [
  // the 1st row
  {
    options: {
      disabled,
      size: 3,
      direction: 'row',
      grow: 0,
      flexWrap: 'nowrap'
    },
    contents: [
      {
        options: {
          disabled,
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar'
            },
            source: 'sendTo',
            options: {
              disabled,
              resultFormatFunction: customerRep => customerRep.name,
              searchFunction: (value, searchCols) =>
                customerRepSearch(value, searchCols, customerId),
              sortFunction: (a, b) => (a.name > b.name ? 1 : -1),
              label: 'Send To',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              placeholder: 'Search contacts',
              isRequired: true,
              errorText: 'Field is required'
            }
          }
        ]
      },
      {
        options: {
          disabled,
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'SearchBar'
            },
            source: 'returnTo',
            options: {
              disabled,
              useId: true,
              resultFormatFunction: employee => employee.name,
              searchFunction: employeeSearch,
              filterFunction: data => data.filter(item => item.status === 'active'),
              sortFunction: (a, b) => (a.name > b.name ? 1 : -1),
              searchColumn: ['name'],
              label: 'Return To',
              multiple: false,
              placeholder: 'Search contacts',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true,
              errorText: 'Field is required'
            }
          }
        ]
      },
      {
        options: {
          disabled,
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'TextInput'
            },
            source: 'contractFor',
            options: {
              disabled,
              label: 'Contract For',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true
            }
          }
        ]
      },
      {
        options: {
          disabled,
          direction: 'column',
          flex: defaultFlexStyle,
          alignItems: 'center',
          margin
        },
        contents: [
          {
            component: {
              default: 'FieldWithLabel'
            },
            source: 'number',
            options: {
              disabled,
              label: 'Number',
              fullWidth: true,
              alignment: 'left',
              isRequired: true
            }
          }
        ]
      }
    ]
  },

  // the 2nd row
  {
    options: {
      disabled,
      size: 2,
      direction: 'row',
      grow: 0,
      flexWrap: 'nowrap',
      alignItems: 'center'
    },
    contents: [
      {
        options: {
          disabled,
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'DateInput'
            },
            source: 'periodFrom',
            options: {
              disabled,
              label: 'Period From',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true,
              minDate: new Date(2021, 4, 20)
            }
          }
        ]
      },
      {
        options: {
          disabled,
          direction: 'row',
          flex: '0 1 14px',
          margin: centerMargin
        },
        contents: [
          {
            component: {
              default: MUIFormComponentNames.BodyText
            },
            source: 'to'
          }
        ]
      },
      {
        options: {
          disabled,
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'DateInput'
            },
            source: 'periodTo',
            options: {
              disabled,
              label: 'Period To',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true
            }
          }
        ]
      }
    ]
  },

  // the 3rd row
  {
    options: {
      disabled,
      size: 2,
      direction: 'row',
      grow: 0,
      flexWrap: 'nowrap',
      alignItems: 'flex-start'
    },
    contents: [
      {
        options: {
          disabled,
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'SelectInput'
            },
            source: 'distributeTo',
            options: {
              disabled,
              label: 'Distribute To',
              placeholder: '',
              valueSet: [
                { label: 'Owner', value: 'owner' },
                { label: 'Architect', value: 'architect' },
                { label: 'Mechanical Engineer', value: 'mechanical engineer' },
                { label: 'Electrical Engineer', value: 'electrical engineer' },
                { label: 'General Contractor', value: 'general contractor' },
                { label: 'Mechanical Contractor', value: 'mechanical contractor' },
                { label: 'Electrical Contractor', value: 'electrical contractor' }
              ],
              enableSort: false,
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true
            }
          }
        ]
      },
      {
        options: {
          disabled,
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'DateInput'
            },
            source: 'applicationDate',
            options: {
              disabled,
              label: 'Application Date',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true
            }
          }
        ]
      }
    ]
  }
];

export const useConfiguration = ({ disabled }) => {
  const customerId = useSelector(state => state.pm.project.customerId);

  return useMemo(
    () => ({
      fields: payApplicationFields,
      layouts: {
        default: {
          options: {
            pageSize: 'LETTER',
            grow: 0
          },
          contents: generatePayApplicationContents({ customerId, disabled })
        }
      }
    }),
    [disabled, customerId]
  );
};
