import { useSnackbar } from 'customHooks/useSnackbar';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import { logErrorWithCallback } from 'utils';

import gql from 'graphql-tag';

export const getPricebookEntriesByProductSortKeys = gql`
  query($pricebookId: String!, $productSortKeys: [String!]) {
    getPricebookEntriesByProductSortKeys(
      pricebookId: $pricebookId
      productSortKeys: $productSortKeys
    ) {
      id
      productSortKey
      unitPrice
      markupValue
      markupType
      materialMarkup
      costCodeId
      revenueTypeId
      jobCostTypeId
    }
  }
`;

const usePricebookEntries = ({ pricebookId, productSortKeys }, options = {}) => {
  const snackbar = useSnackbar();

  const { data, loading, error, refetch } = useExtendedQuery(getPricebookEntriesByProductSortKeys, {
    variables: {
      pricebookId,
      productSortKeys
    },
    transform: result => result?.getPricebookEntriesByProductSortKeys,
    skip: !pricebookId,
    ...options
  });
  if (error) {
    logErrorWithCallback(error, snackbar, 'Unable to get the pricebook entries');
  }

  return [data || [], loading, error, refetch];
};

export default usePricebookEntries;
