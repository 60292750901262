import { EmployeeStatus } from 'utils/AppConstants';
import { filterSkillsOptions } from './helpers';

export const EmployeeLayout = ({
  skillsOptions,
  rolesOptions,
  departmentOptions,
  skillsDropdown,
  setSkillsDropdown,
  employeeCreated
}) => ({
  fields: {
    customFileName: {
      default: 'customFileName',
      edit: 'customFileName'
    },
    newFiles: {
      default: 'newFiles',
      edit: 'newFiles'
    },
    comment: {
      default: 'comment',
      edit: 'comment'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        direction: 'column',
        height: 'auto'
      },
      contents: [
        {
          options: {
            padding: 12,
            flex: 'none',
            direction: 'row',
            justify: 'space-between'
          },
          contents: [
            {
              component: {
                default: 'SectionTitle'
              },
              options: {
                label: 'Personal info'
              }
            }
          ]
        },
        {
          options: {
            direction: 'row',
            align: 'center',
            padding: 12,
            flex: 'none'
          },
          contents: [
            {
              options: {
                width: 150,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'LogoButton',
                    view: 'LogoButton'
                  },
                  source: 'profilePictureUrl',
                  options: {
                    label: 'Profile picture',
                    multiple: false
                  }
                }
              ]
            },
            {
              options: {
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: employeeCreated ? 'FieldWithLabel' : 'TextInput',
                    view: 'FieldWithLabel'
                  },
                  source: 'firstName',
                  options: {
                    label: 'First Name',
                    placeholder: 'Enter first name',
                    fullWidth: true,
                    alignment: 'left',
                    isRequired: true
                  }
                }
              ]
            },
            {
              options: {
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: employeeCreated ? 'FieldWithLabel' : 'TextInput',
                    view: 'FieldWithLabel'
                  },
                  source: 'lastName',
                  options: {
                    label: 'Last Name',
                    placeholder: 'Enter Last name',
                    fullWidth: true,
                    alignment: 'left',
                    isRequired: true
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row',
            padding: 12,
            flex: 'none'
          },
          contents: [
            {
              options: {
                padding: 12
              },
              contents: [
                {
                  options: {
                    placeholder: 'Enter Address Line 1',
                    valuesToSet: [
                      {
                        city: 'city.shortName',
                        county: 'county.shortName',
                        state: 'state.shortName',
                        zipcode: 'zipcode.shortName',
                        latitude: 'latitude',
                        longitude: 'longitude',
                        addressLine1: 'addressLine1'
                      }
                    ],
                    label: 'Address Line 1',
                    fullWidth: true,
                    setSource: false
                  },
                  component: {
                    default: 'PlacesSearch',
                    edit: 'PlacesSearch',
                    view: 'FieldWithLabel'
                  },
                  source: 'addressLine1'
                }
              ]
            },
            {
              options: {
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'TextInput',
                    edit: 'TextInput',
                    view: 'FieldWithLabel'
                  },
                  source: 'addressLine2',
                  options: {
                    label: 'Address Line 2',
                    placeholder: 'Enter Address Line 2',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'TextInput',
                    edit: 'TextInput',
                    view: 'FieldWithLabel'
                  },
                  source: 'city',
                  options: {
                    label: 'City',
                    placeholder: 'Enter City',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'TextInput',
                    edit: 'TextInput',
                    view: 'FieldWithLabel'
                  },
                  source: 'state',
                  options: {
                    label: 'State/Province',
                    placeholder: 'Enter state',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'TextInput',
                    edit: 'TextInput',
                    view: 'FieldWithLabel'
                  },
                  source: 'zipcode',
                  options: {
                    label: 'Zip/Postal code',
                    placeholder: 'Enter zip code',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row',
            padding: 12,
            flex: 'none',
            paddingTop: 0
          },
          contents: [
            {
              options: {
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'TextInput',
                    edit: 'TextInput',
                    view: 'FieldWithLabel'
                  },
                  source: 'cellPhone',
                  options: {
                    label: 'Personal Phone',
                    placeholder: 'Enter personal phone',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                padding: 12,
                flex: 'none'
              },
              contents: [
                {
                  component: {
                    default: 'TextInput',
                    edit: 'TextInput',
                    view: 'FieldWithLabel'
                  },
                  source: 'personalEmail',
                  options: {
                    label: 'Personal Email',
                    placeholder: 'Enter Email Id',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'TextInput',
                    edit: 'TextInput',
                    view: 'FieldWithLabel'
                  },
                  source: 'code',
                  options: {
                    label: 'Code',
                    placeholder: 'Enter Code',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            padding: 12,
            flex: 'none'
          },
          contents: [
            {
              component: {
                default: 'SectionTitle'
              },
              options: {
                label: 'Employee settings'
              }
            }
          ]
        },
        {
          options: {
            direction: 'row',
            padding: 12,
            flex: 'none'
          },
          contents: [
            {
              options: {
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'TextInput',
                    edit: 'TextInput',
                    view: 'FieldWithLabel'
                  },
                  source: 'landlinePhone',
                  options: {
                    label: 'Work Phone',
                    placeholder: 'Enter Work Phone',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: employeeCreated ? 'FieldWithLabel' : 'TextInput',
                    view: 'FieldWithLabel'
                  },
                  source: 'email',
                  options: {
                    label: 'Work Email',
                    placeholder: 'Enter Work Email',
                    fullWidth: true,
                    alignment: 'left',
                    isRequired: true
                  },
                  dependentField: {
                    fieldName: 'status',
                    expectedValue: EmployeeStatus.ACTIVE,
                    operation: 'equal',
                    action: 'DISABLE'
                  }
                }
              ]
            },
            {
              options: {
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: employeeCreated ? 'FieldWithLabel' : 'TextInput',
                    view: 'FieldWithLabel'
                  },
                  source: 'userName',
                  options: {
                    label: 'User Name',
                    placeholder: 'Enter User Name',
                    fullWidth: true,
                    alignment: 'left',
                    isRequired: true
                  },
                  dependentField: {
                    fieldName: 'status',
                    expectedValue: EmployeeStatus.ACTIVE,
                    operation: 'equal',
                    action: 'DISABLE'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row',
            padding: 12,
            flex: 'none',
            paddingTop: 0
          },
          contents: [
            {
              options: {
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'SelectInput',
                    edit: 'SelectInput',
                    view: 'SelectInputInfoView'
                  },
                  source: 'appRole',
                  options: {
                    label: 'Role',
                    valueSet: rolesOptions,
                    placeholder: 'Select Role',
                    fullWidth: true,
                    alignment: 'left',
                    isRequired: true
                  }
                }
              ]
            },
            {
              options: {
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'SelectInput',
                    edit: 'SelectInput',
                    view: 'SelectInputInfoView'
                  },
                  source: 'departments',
                  options: {
                    label: 'Select Departments',
                    valueSet: departmentOptions,
                    fullWidth: true,
                    isMultipleSelection: true,
                    alignment: 'left',
                    isRequired: true,
                    onChange: valueSet => {
                      const filteredSkillsOptions = filterSkillsOptions(skillsOptions, valueSet);
                      setSkillsDropdown(filteredSkillsOptions);
                    }
                  }
                }
              ]
            },
            {
              options: {
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'SelectInput',
                    edit: 'SelectInput',
                    view: 'SelectInputInfoView'
                  },
                  source: 'skills',
                  options: {
                    label: 'Select Skills',
                    valueSet: skillsDropdown,
                    isMultipleSelection: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row',
            padding: 12,
            flex: 'none'
          },
          contents: [
            {
              options: {
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'CheckboxInput',
                    view: 'ReadOnlyCheckboxInput'
                  },
                  source: 'isTech',
                  options: {
                    label: 'Technician user',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                flex: 'none',
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'EmptyElement',
                    tenantAdmin: 'CheckboxInput'
                  },
                  source: 'isAdmin',
                  options: {
                    label: 'Admin Rights in BuildOps',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'CheckboxInput',
                    view: 'ReadOnlyCheckboxInput'
                  },
                  source: 'isSales',
                  options: {
                    label: 'Sales User',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                padding: 12
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    justify: 'space-between'
                  },
                  contents: [
                    {
                      component: {
                        default: 'Status'
                      },
                      source: 'status',
                      options: {
                        dataKey: 'status',
                        placeholder: 'Login Status',
                        label: 'InActive',
                        fullWidth: true,
                        alignment: 'left'
                      }
                    },
                    {
                      component: {
                        default: 'Status'
                      },
                      source: 'isActive',
                      options: {
                        dataKey: 'isActive',
                        placeholder: 'Profile Status',
                        label: 'Active',
                        isBoolean: true,
                        fullWidth: true,
                        alignment: 'left'
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  }
});

export default EmployeeLayout;
