import gql from 'graphql-tag';

const serviceAgreement = gql`
  query serviceAgreement($partitionKey: String, $id: String!) {
    serviceAgreement(partitionKey: $partitionKey, id: $id) {
      id
      companyId
      startDate
      endDate
      status
      paymentTerms
      termPrice
      agreementNumber
      agreementName
      firstBillDate
      nextBillDate
      lastBilledDate
      customerPoNumber
      billingCustomerId
      customerId
      isActive
      notes(entityConnection: "Note") {
        items {
          id
          note
          subject
          version
        }
      }
      attachments(entityConnection: "Attachment") {
        items {
          id
          customFileName
          fileName
          fileUrl
          fileSize
          dateAdded
          addedDateTime
          addedBy
          description
          comment
          includeInInvoice
          type
          sortOrder
        }
      }
      maintenanceTemplates(entityConnection: "MaintenanceTemplate") {
        items {
          id
          serviceAgreementId
          propertyId
          customerProperty {
            id
            companyName
          }
          schedule
          firstDueDate
          jobTagsJSON
          serviceDescription
          budgetedHours
          estimatedVisits
          maintenanceTaskTemplates(entityConnection: "MaintenanceTaskTemplate") {
            items {
              id
              maintenanceTemplateId
              assetId
              assetTypeId
              maintenanceTaskName
              maintenanceTaskDescription
              maintenanceTaskRequiredParts(entityConnection: "MaintenanceTaskRequiredPart") {
                items {
                  id
                  quantity
                  pricebookEntryId
                  pricebookEntry {
                    id
                    product(entityConnection: "Product") {
                      id
                      name
                      description
                    }
                  }
                }
              }
            }
          }
          version
        }
      }
      version
    }
  }
`;

export default serviceAgreement;
