import { useStyles } from '@dispatch/components/VisitsTabs/VisitsTabs.styles';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import { AppConstants } from 'utils/AppConstants';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const TentativeDate = ({ record }) => {
  const classes = useStyles();
  const format = /\d{2}\/\d{2}\/\d{4}/.test(record?.tentativeDate) ? 'MM/DD/YYYY' : undefined;
  if (moment(record?.tentativeDate, format).isValid()) {
    const date = moment(record?.tentativeDate, format);
    return (
      <Typography className={classes.TableCell}>{date.format(AppConstants.DATE_FORMAT)}</Typography>
    );
  }
  return null;
};

TentativeDate.propTypes = {
  record: PropTypes.object.isRequired
};

export default memo(TentativeDate);
