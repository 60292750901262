import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

function Hit(props) {
  const { formProps, hit, classes, isFieldEdited } = props;
  const { specialbehaviour, getDependentFieldData } = formProps;
  const {
    displayText,
    // valueField,
    delimiter
    // additionalFormField,
    // additionalFormFieldValue
  } = specialbehaviour;
  let resultText = '';
  if (displayText && Array.isArray(displayText)) {
    const displayValueArr = [];
    displayText.forEach(text => {
      if (hit[text]) {
        displayValueArr.push(hit[text]);
      }
    });
    resultText = displayValueArr.join(delimiter);
  } else if (displayText && typeof displayText === 'string') {
    resultText = hit[displayText];
  }
  // const valueText = hit[valueField] || displayText[valueField];
  // const { values } = form;
  // let newValues = {
  //   ...values,
  //   [field.name]: ''
  // };

  // if (additionalFormField && additionalFormFieldValue) {
  //   newValues = { ...newValues, [additionalFormField]: hit[additionalFormFieldValue] };
  // }

  return (
    <ListItem button disableGutters className={classes.results}>
      <ListItemText
        onClick={() => {
          if (getDependentFieldData) {
            getDependentFieldData(hit);
          }
          isFieldEdited();
        }}
        className={classes.resultsText}
      >
        {resultText}
      </ListItemText>
    </ListItem>
  );
}

export default withStyles(styles)(Hit);
