import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { LinkButton, LinkList } from 'components';
import { camelCaseToTitleCase } from 'utils';

const useStyles = makeStyles(({ palette, spacing }) => ({
  fieldTitle: {
    color: palette.grayscale(20),
    marginBottom: '0.35em'
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start'
  },
  linkText: {
    fontSize: 16
  },
  inputLabel: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'normal',
    letterSpacing: '0.01px',
    lineHeight: '14px',
    marginBottom: spacing(0.3)
  }
}));

export const LinkListForm = ({ field, options }) => {
  const classes = useStyles();

  const meta = {
    id: field.name,
    itemLabel: '',
    maxAmountOfLinks: 9999, // TODO: add real limit and ability to see the rest
    ...options
  };
  const record = { [field.name]: { items: field.value?.map(p => ({ id: p.value, ...p })) } };

  return (
    <div className={classes.listContainer}>
      {!field.value?.length ? (
        <Typography>No selected properties</Typography>
      ) : (
        <LinkList meta={meta} record={record} classes={{ root: classes.linkText }} />
      )}
    </div>
  );
};

export const LinkButtonForm = ({ form, field }) => {
  const classes = useStyles();
  const fieldValue = form?.values?.[field?.name];
  const { label, path } = fieldValue || {};
  return (
    <div className={classes.fieldContainer}>
      <Typography variant="overline" className={classes.fieldTitle}>
        {camelCaseToTitleCase(field?.name)}
      </Typography>
      {path ? (
        <LinkButton label={label} path={path} classes={{ root: classes.linkText }} />
      ) : (
        <Typography>-</Typography>
      )}
    </div>
  );
};
