import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { snackbarOn } from 'redux/actions/globalActions';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import AlgoliaSearch from 'components/AlgoliaSearch';
import { useLazyFetchPricebookEntry } from 'components/Tasks/components/useLazyFetchPricebookEntry';
import ErrorBoundaries from 'scenes/Error';
import { logErrorWithCallback } from 'utils';
import TableView from './SelectAndTable/TableView/TableView';
import { updateTasksAndGroups } from '../TaskGroups/TaskGroup.utils';

const useStyles = makeStyles(() => ({
  inputLabel: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'normal',
    letterSpacing: '0.01px',
    lineHeight: '14px'
  },
  requiredLabel: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    fontSize: '8px',
    letterSpacing: 0,
    lineHeight: '14px'
  },
  searchbarGrid: {
    '& .MuiInputBase-root': {
      fontSize: 14,
      padding: '0 11px'
    }
  }
}));

function PartsSelect(props) {
  const {
    options,
    updateTaskEntriesForTaskService,
    addTaskEntriesToTaskService,
    deleteTaskEntryFromTaskService,
    task,
    required = true,
    setTaskGroups,
    preferredPricebookId
  } = props;
  const classes = useStyles();

  const {
    isReadOnly,
    searchParams: [searchParams],
    config: [config]
  } = options;
  const [taskEntries, setTaskEntries] = useState(
    task.products?.items || task.quoteLineProducts?.items || []
  );

  const fetchPricebookEntry = useLazyFetchPricebookEntry();

  const getTaskEntryPayload = (newItem, pEntry) => ({
    priceBookEntryId: pEntry.id,
    name: newItem.name,
    description: newItem.description,
    unitPrice: pEntry.unitPrice ?? 0,
    unitCost: newItem.unitCost ?? 0,
    markupType: pEntry.markupType || 'Percentage',
    markupValue: pEntry.markupValue ?? 0,
    productId: newItem.id,
    quantity: newItem.quantity ?? 0,
    taxable: newItem.taxable,
    sortOrder: taskEntries.length + 1
  });

  const updateTask = async (newValue, fieldName, item) => {
    if (!item?.id) {
      return;
    }
    const taskEntryPayload = {
      id: item.id,
      markupType: item.markupType,
      markupValue: item.markupValue,
      name: item.name,
      priceBookEntryId: item.priceBookEntryId,
      productId: item.productId,
      quantity: item.quantity || 0,
      taxable: item.taxable,
      unitPrice: item.unitPrice,
      unitCost: item.unitCost,
      description: item.description
    };

    if (config.showPricing) {
      taskEntryPayload.unitCost = item.unitCost;
      taskEntryPayload.markupValue = Number(item.markupValue);
      taskEntryPayload.unitPrice = item.unitPrice;
    }

    if (fieldName) {
      taskEntryPayload[fieldName] = newValue;
    }

    try {
      const taskEntryResponse = await updateTaskEntriesForTaskService(taskEntryPayload, task);
      if (taskEntryResponse) {
        updateTasksAndGroups({
          groups: taskEntryResponse.quoteTransition.quoteTaskGroups.items,
          tasks: taskEntryResponse.quoteTransition.quoteLineTasks.items,
          setTaskGroups
        });
        const editedTask = taskEntryResponse.quoteTransition.quoteLineTasks.items.find(
          taskElem => taskElem.id === task.id
        );
        setTaskEntries(editedTask?.quoteLineProducts?.items);
      }
      return;
    } catch (error) {
      logErrorWithCallback(error, snackbarOn, 'Unable to update part, please try again later');
    }
  };

  const handleSearchChange = async newItem => {
    if (!newItem?.id) {
      return;
    }
    const pEntry = await fetchPricebookEntry({
      pricebookId: preferredPricebookId,
      productSortKey: newItem.sortKey
    });
    try {
      const existingTaskIndex = taskEntries.findIndex(elem => elem?.id === pEntry?.id);
      if (existingTaskIndex !== -1) {
        updateTask(
          taskEntries[existingTaskIndex].quantity + 1,
          'quantity',
          taskEntries[existingTaskIndex],
          existingTaskIndex
        );
      } else {
        const taskEntryPayload = getTaskEntryPayload({ ...newItem, quantity: 1 }, pEntry);
        const addedTaskResponse = await addTaskEntriesToTaskService(taskEntryPayload, task);
        if (addedTaskResponse) {
          updateTasksAndGroups({
            groups: addedTaskResponse.quoteTransition.quoteTaskGroups.items,
            tasks: addedTaskResponse.quoteTransition.quoteLineTasks.items,
            setTaskGroups
          });
          const editedTask = addedTaskResponse.quoteTransition.quoteLineTasks.items.find(
            taskElem => taskElem.id === task.id
          );
          setTaskEntries(editedTask?.quoteLineProducts?.items);
        }
      }
    } catch (error) {
      logErrorWithCallback(error, snackbarOn, 'Unable to add part, please try again later');
    }
  };

  const handleDelete = async (taskEntry, onCompleteCallback) => {
    try {
      const deleteResp = await deleteTaskEntryFromTaskService(taskEntry.id);
      if (deleteResp) {
        updateTasksAndGroups({
          groups: deleteResp.quoteTransition.quoteTaskGroups.items,
          tasks: deleteResp.quoteTransition.quoteLineTasks.items,
          setTaskGroups
        });
        const editedTask = deleteResp.quoteTransition.quoteLineTasks.items.find(
          taskElem => taskElem.id === task.id
        );
        setTaskEntries(editedTask?.quoteLineProducts?.items);
      }
    } catch (error) {
      logErrorWithCallback(error, snackbarOn, 'Unable to delete part, please try again later');
    } finally {
      onCompleteCallback(); // to stop spinner
    }
  };

  return (
    <ErrorBoundaries>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableView
          isReadOnly={isReadOnly}
          handleDelete={handleDelete}
          data={taskEntries}
          entityName="Task Entry"
          handleChange={updateTask}
          config={config}
          labels={{
            name: 'ITEM NAME',
            title: 'Parts & Materials',
            total: 'PARTS TOTAL'
          }}
        />
      </Grid>
      <div style={{ position: 'relative', paddingRight: required ? 60 : 0 }}>
        <Typography className={classes.inputLabel} variant="caption" gutterBottom>
          {searchParams?.label || ''}
        </Typography>
      </div>
      <Grid
        className={classes.searchbarGrid}
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        styles={{ padding: 0 }}
      >
        {searchParams && (
          <AlgoliaSearch
            searchIndex={searchParams.searchIndex}
            label=""
            name={searchParams.name}
            placeholder={searchParams.placeholder}
            value=""
            displayText={searchParams.displayText}
            delimiter={searchParams.delimiter}
            filters={searchParams.filters}
            overrideVariant="standard"
            color="secondary"
            handleChange={handleSearchChange}
          />
        )}
      </Grid>
    </ErrorBoundaries>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedPartsSelect = connect(
  mapStateToProps,
  mapDispatcherToProps
)(withTheme(PartsSelect));

export default reduxConnectedPartsSelect;
