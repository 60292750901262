import compose from 'utils/compose';
import { withDispatchStore, withQueryStringStore } from '@dispatch/Dispatch.store';
import Drawer from './Drawer.component';

const mapDispatchToProps = store => ({
  eventType: store.state.eventType
});

const mapQueryStringToProps = store => ({
  visitId: store.state.visitId,
  billableEventId: store.state.billableEventId,
  nonBillableEventId: store.state.nonBillableEventId,
  manDayId: store.state.manDayId
});

export default compose(
  withQueryStringStore(mapQueryStringToProps),
  withDispatchStore(mapDispatchToProps)
)(Drawer);
