import { logErrorWithCallback } from 'utils';
import gql from 'graphql-tag';
import AmplifyService from 'services/AmplifyService';
import { errorMessage } from './constants';

const getAssetsForPropertyQuery = gql`
  query getAssetsForPropertyQuery($partitionKey: String, $id: String!) {
    getCustomerPropertyById(partitionKey: $partitionKey, id: $id) {
      propertyAssets(
        entityConnection: "PropertyAsset" # filter: { integerFilters: [{ fieldName: "deletedDate", filterInput: { is: null } }] }
      ) {
        items {
          id
          assetName
          assetTypeId
          assetType {
            tagName
          }
          isActive
          make
          serialNo
          modelNumber
        }
      }
    }
  }
`;

export const getAssetOptionsForProperty = async ({ id, partitionKey, snackbar }) => {
  try {
    const { client } = AmplifyService.appSyncClient();
    const { data } = await client.query({
      query: getAssetsForPropertyQuery,
      variables: {
        partitionKey,
        id
      }
    });
    return (data.getCustomerPropertyById?.propertyAssets?.items || []).filter(
      ({ isActive }) => isActive
    );
  } catch (error) {
    logErrorWithCallback(error, snackbar, errorMessage.getAssetsForProperty);
  }
};

export default getAssetOptionsForProperty;
