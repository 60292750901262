import gql from 'graphql-tag';

const addLabourTypesToCompany = gql`
  mutation addLabourTypesToCompany($partitionKey: String, $data: AddLabourTypesToCompanyInput!) {
    addLabourTypesToCompany(partitionKey: $partitionKey, data: $data) {
      id
      name
      version
      isActive
      createdDate
      createdDateTime
      sortOrder
      payrollCosts(entityConnection: "PayrollCost") {
        items {
          id
          cost
          hourTypeId
          costCodeId
          costCode {
            id
            name
          }
          jobCostTypeId
          jobCostType {
            id
            name
          }
        }
      }
      labourTypeBillingHourTypeProducts(entityConnection: "LabourTypeBillingHourTypeProduct") {
        items {
          id
          productId
          billingHourTypeId
          product {
            id
            name
          }
        }
      }
    }
  }
`;

export default addLabourTypesToCompany;
