import { gql } from '@apollo/client/core';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { formFragment } from '../queries/queryFragment';

const UPDATE_FORM_DATA = gql`
  mutation updateFormData($partitionKey: String!, $data: UpdateFormDataInput!) {
    updateFormData(partitionKey: $partitionKey, data: $data) {
      ${formFragment}
    }
  }
`;

export const useUpdateFormData = () => {
  return useExtendedMutation(UPDATE_FORM_DATA, {
    serializer: ({ tenantId, ...data }) => ({
      variables: {
        partitionKey: tenantId,
        data
      }
    })
  });
};
