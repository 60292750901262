import React from 'react';
import { func, object, string, array } from 'prop-types';

import { EventEntities, VisitActions, VisitStatuses } from '@dispatch/Dispatch.constants';
import { useFormService } from '@dispatch/hooks';
import { useModalContext } from '@dispatch/hocs';
import { selectVisitData } from '@dispatch/components/Drawer/Drawer.selectors';
import { isFullyScheduled } from '@dispatch/stateMachines/getVisitStateMachine/getVisitStateMachine.guards';

import DrawerLayout from '../DrawerLayout';
import EventBadge from '../EventBadge';
import JobDetailsHeader from '../JobDetailsHeader';
import VisitDetailsForm from '../VisitDetailsForm';

import ConfirmContinueModalBody from './components/ConfirmContinueModalBody';

const continueAsNewVisit = ({ visit, onConfirm }) => ({
  children: <ConfirmContinueModalBody visit={visit} />,
  buttons: {
    confirm: {
      buttonType: 'contained',
      color: 'primary',
      label: 'Confirm changes',
      action: onConfirm
    }
  }
});

const ContinueVisit = ({
  closeDrawer,
  eventData,
  jobNumber,
  setJobNumber,
  jobDetailsResponse,
  visitTransitionTuple
}) => {
  const { isDirty, onCreateService, onDirtyChange, resetForm, submitForm } = useFormService();
  const { openModal, closeModal } = useModalContext();
  const { data: job, loading: fetchingJobDetails } = jobDetailsResponse;
  const [triggerVisitTransition, visitTransitionResponse] = visitTransitionTuple;
  const { loading: submittingVisit } = visitTransitionResponse;
  const loading = submittingVisit || fetchingJobDetails;
  const disabled = !job || loading;
  const hasJobSelected = !!jobNumber && !!job;
  const initialVisitData = {
    ...selectVisitData(eventData),
    scheduledFor: null
  };

  const handleFormReset = () => {
    setJobNumber(null);
    resetForm();
  };

  const continueVisit = visit => {
    const fullyScheduled = isFullyScheduled(
      {},
      {
        originalVisit: eventData,
        inputData: visit
      }
    );

    const newVisitStatus = fullyScheduled
      ? VisitStatuses.SCHEDULED.key
      : VisitStatuses.UNASSIGNED.key;

    openModal(
      continueAsNewVisit({
        visit: {
          ...eventData,
          ...visit,
          status: newVisitStatus
        },
        classes: {},
        onConfirm: () => {
          closeModal();
          triggerVisitTransition({
            transitionAction: VisitActions.CONTINUE_IN_NEW_VISIT.key,
            originalVisit: eventData,
            inputData: visit
          });
          closeDrawer();
        }
      })
    );
  };

  const renderHeader = () => {
    return (
      <>
        <EventBadge eventType={EventEntities.VISIT.value.clientValue} />
        <JobDetailsHeader job={job} />
      </>
    );
  };

  const renderBody = () => (
    <VisitDetailsForm
      visit={initialVisitData}
      onCreateService={onCreateService}
      onDirtyChange={onDirtyChange}
      onComplete={continueVisit}
      visitLoading={loading}
      disabled={disabled}
      collapseDetails
    />
  );

  return (
    <DrawerLayout
      renderHeader={renderHeader}
      renderBody={renderBody}
      onResetForm={handleFormReset}
      onSubmitForm={submitForm}
      isDirty={hasJobSelected || isDirty}
      loading={loading}
      disabledSave={!job || loading}
      disabledClose={loading}
    />
  );
};

ContinueVisit.propTypes = {
  closeDrawer: func.isRequired,
  eventData: object,
  jobNumber: string.isRequired,
  setJobNumber: func.isRequired,
  jobDetailsResponse: object.isRequired,
  visitTransitionTuple: array.isRequired
};

ContinueVisit.defaultProps = {
  eventData: null
};

export default ContinueVisit;
