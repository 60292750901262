import React, { useState } from 'react';
import { ThemeProvider, Modal } from '@buildhero/sergeant';
import PropTypes from 'prop-types';
import ShowMoreText from 'react-show-more-text';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { getCloudinaryImageUrl } from 'scenes/ProjectManagement/components/utils';
import useStyles from './TimesheetNotes.styles';

export const AttachmentImages = ({ attachments }) => {
  const [url, setUrl] = useState('');

  return (
    attachments?.items.length > 0 && (
      <div>
        {attachments.items
          .filter(a => !a._deleted)
          .map((item, i) => (
            <div
              role="button"
              key={item.id}
              onClick={() => setUrl(item.fileUrl)}
              onKeyDown={() => null}
              tabIndex={i}
            >
              <img
                style={{
                  width: 'auto',
                  height: 'auto',
                  maxWidth: 64,
                  maxHeight: 64,
                  marginTop: 4,
                  marginLeft: i === 0 ? 0 : 8,
                  cursor: 'pointer'
                }}
                src={getCloudinaryImageUrl(item.fileUrl)}
                alt="Attachment"
              />
            </div>
          ))}
        <ThemeProvider>
          <Modal open={!!url} onClose={() => setUrl('')}>
            <img
              style={{
                width: 'auto',
                height: 'auto',
                maxWidth: 'calc(100vh - 96px)',
                maxHeight: 'calc(100vh - 96px)'
              }}
              src={getCloudinaryImageUrl(url)}
              alt="Attachment"
            />
          </Modal>
        </ThemeProvider>
      </div>
    )
  );
};

const TimesheetNotes = ({ notes }) => {
  const classes = useStyles();
  return (
    <Box flexDirection="column" className={classes.noteWrapper}>
      <Box display="flex" flexDirection="column">
        <Typography className={classes.notesTitle} variant="caption">
          Notes
        </Typography>
        {notes.map((info, i) => {
          return (
            <Box key={i} display="flex" marginBottom={2} flexDirection="column">
              <Typography className={classes.note} variant="body1">
                {info.subject}
              </Typography>
              <Typography className={classes.note} variant="body2">
                <ShowMoreText more="Show more" less="Show less">
                  {info.note}
                </ShowMoreText>
              </Typography>
              <AttachmentImages attachments={info.attachments} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

TimesheetNotes.propTypes = {
  notes: PropTypes.array.isRequired
};

export default TimesheetNotes;
