import React from 'react';
import { connect } from 'react-redux';
import { Grid, Button, TextField, Checkbox, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { snackbarOn } from 'redux/actions/globalActions';
import Spinner from 'components/Spinners/CircularIndeterminate';
// eslint-disable-next-line import/no-cycle
import { Context, ResponsiveTable, AlgoliaSearch } from 'components';
import { productItemsRows, viewProductItemsRows } from 'meta/Products/tables';
import ErrorBoundaries from 'scenes/Error';
import ConfirmModal from 'components/Modal/ConfirmDialog';
import { checkPermission } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';
import CostMarkup from '../CostMarkup';

import { bundleIndex as defaultProductIndex } from '../../constants/algoliaIndex';

const styles = makeStyles(theme => ({
  pageActionButton: {
    float: 'right',
    width: '176px',
    height: '36px',
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(3.75),
    marginLeft: theme.spacing(2.5)
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

function AddProductsFromSearch(props) {
  const classes = styles();
  const { handleSave, handleClose, handleDeleteProduct } = props;
  const [refreshCounter, setRefreshCounter] = React.useState(0);
  const [addToProductTable, setAddToProductTable] = React.useState(props.productArray || []);
  const [oldProdArray, setOldProdArray] = React.useState();
  const [confirmDelete, setConfirmDelete] = React.useState({});
  const [btnClicked, setBtnClicked] = React.useState(false);
  const [totals, setTotals] = React.useState(0);
  const priceBookId = Context.getCompanyContext().getCompany?.defaultPriceBookId;

  const handleCancelConfirmation = () =>
    setConfirmDelete({ confirmMessage: '', confirmAction: '', confirmDialog: '' });

  React.useEffect(() => {
    if (oldProdArray !== props.productArray) {
      setAddToProductTable(props.productArray);
      setOldProdArray(props.productArray);
      setRefreshCounter(refreshCounter + 1);
      const productTotal = props.productArray.reduce(
        (sum, item) => sum + (item.unitPrice || 0) * (item.quantity || 0),
        0
      );
      setTotals(productTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
    }
  }, [oldProdArray, props.productArray, refreshCounter]);

  const formatAlgoliaSelection = ({
    id,
    sortKey,
    partitionKey,
    description,
    entityType,
    hierarchy,
    isActive,
    markupValue,
    name,
    priceBookId: priceBookEntryId,
    productSortKey,
    productEntryUnitCost,
    productEntryUnitPrice,
    productEntryMarkupType,
    productEntryMarkupValue,
    productEntryIsActive,
    quantity,
    costCodeId,
    revenueTypeId,
    jobCostTypeId,
    taxable,
    productId,
    productName,
    productDescription,
    productCode,
    unitOfMeasure,
    unitCost,
    unitPrice,
    version
  }) => ({
    id,
    sortKey,
    partitionKey,
    entityType,
    hierarchy,
    priceBookEntryId,
    productSortKey,
    unitCost: unitCost || productEntryUnitCost,
    unitPrice: unitPrice || productEntryUnitPrice,
    markupType: productEntryMarkupType,
    markupValue: markupValue || productEntryMarkupValue,
    isActive: isActive || productEntryIsActive,
    costCodeId,
    revenueTypeId,
    jobCostTypeId,
    taxable,
    productId,
    name: name || productName,
    description: description || productDescription,
    code: productCode,
    quantity,
    unitOfMeasure,
    version
  });

  const updateProductTable = selection => {
    if (!selection) return;
    const value = formatAlgoliaSelection(selection);
    let isValueUpdated = false;
    const updatedProdTable = addToProductTable.map(row => {
      let localRow = row;
      if (row.id === value.id) {
        localRow = value;
        isValueUpdated = true;
      }
      return localRow;
    });
    if (!isValueUpdated) {
      updatedProdTable.unshift(value);
    }
    if (props.handleChange) {
      props.handleChange(updatedProdTable);
    } else {
      setAddToProductTable(updatedProdTable);
      setRefreshCounter(refreshCounter + 1);
    }
  };

  const InputComponent = valueProps => {
    const { record, meta } = valueProps;
    const [item, setItem] = React.useState(record[meta.id]);
    return (
      <TextField
        name={meta.id}
        label={meta.label}
        value={item}
        variant="filled"
        onChange={event => {
          setItem(event.target.value);
        }}
        onBlur={event => {
          record[meta.id] = event.target.value || 0;
          updateProductTable(record);
          return record;
        }}
        style={{ width: 90, paddingRight: 4, marginBottom: -6 }}
      />
    );
  };

  const CheckBoxComponent = valueProps => {
    const { record, meta } = valueProps;
    const [item, setItem] = React.useState(record[meta.id]);
    return (
      <Checkbox
        value={item}
        onChange={event => {
          setItem(event.target.checked);
          record[meta.id] = event.target.checked;
          updateProductTable(record);
          return record;
        }}
        className={classes.checkboxStyle}
        checked={item}
      />
    );
  };

  const CostMarkupComponent = valueProps => {
    const { record } = valueProps;
    return <CostMarkup values={record} handleChange={updateProductTable} />;
  };

  const deleteAction = async record => {
    if (handleDeleteProduct && record.entityType !== 'PriceBookEntry') {
      await handleDeleteProduct(record);
    }

    setConfirmDelete({ confirmMessage: '', confirmAction: '', confirmDialog: '' });
    const modifiedData = addToProductTable.filter(item => item.id !== record.id);
    if (props.handleChange) {
      props.handleChange(modifiedData);
    } else {
      setAddToProductTable(modifiedData);
      setRefreshCounter(refreshCounter + 1);
    }
  };

  const DeleteButton = valueProps => {
    const { record } = valueProps;
    if (!props.viewOnly) {
      return (
        <IconButton
          aria-label="Delete"
          onClick={() =>
            setConfirmDelete({
              confirmAction: () => deleteAction(record),
              confirmMessage: 'product',
              confirmDialog: true
            })
          }
        >
          <DeleteIcon color="secondary" className={classes.iconColor} />
        </IconButton>
      );
    }
    return null;
  };

  return (
    <ErrorBoundaries>
      <Grid container alignItems="center">
        {!props.viewOnly && (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <AlgoliaSearch
              searchIndex={defaultProductIndex}
              label={props.overrideLabel || 'Select products'}
              name="selectProduct"
              locatedInPopUp
              value=""
              displayText={['productName', 'productCode', 'productDescription']}
              delimiter=" - "
              filters={[
                {
                  attribute: 'entityType',
                  valueArray: ['PriceBookEntry']
                },
                {
                  attribute: 'priceBookId',
                  valueArray: [priceBookId]
                }
              ]}
              handleChange={updateProductTable}
            />
          </Grid>
        )}
        {checkPermission('allow', PermissionConstants.DATA_VIEW_PRICE_DATA, props.user) && (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography style={!props.viewOnly ? { marginLeft: 10 } : null}>
              Items total: $ {totals}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ResponsiveTable
            rowMetadata={props.viewOnly ? viewProductItemsRows : productItemsRows}
            data={addToProductTable}
            disablePagination
            customCellComponents={{
              input: InputComponent,
              costMarkup: CostMarkupComponent,
              deleteButton: DeleteButton,
              checkbox: CheckBoxComponent
            }}
            key={refreshCounter}
            noDataMsg="No items"
            noEmptyRows
            disableFilter
          />
        </Grid>
        {!props.viewOnly && props.handleSave && props.handleClose && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 0 }}>
            <Grid container direction="row-reverse" spacing={1}>
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={e => {
                    e.preventDefault();
                    setBtnClicked(true);
                    handleSave(addToProductTable);
                  }}
                  className={classes.pageActionButton}
                  disabled={btnClicked}
                >
                  {btnClicked && <Spinner className={classes.buttonProgress} size={24} />}
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={handleClose}
                  className={classes.pageActionButton}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <ConfirmModal
        open={confirmDelete.confirmDialog}
        confirm={confirmDelete.confirmAction}
        cancel={handleCancelConfirmation}
        message={confirmDelete.confirmMessage}
      />
    </ErrorBoundaries>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const ReduxConnectedAddProductsFromSearch = connect(
  mapStateToProps,
  mapDispatcherToProps
)(AddProductsFromSearch);

export default ReduxConnectedAddProductsFromSearch;
