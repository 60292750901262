import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { sum } from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  ThemeProvider,
  TV,
  TW,
  Typography,
  MUIForm,
  Button,
  InlineAlert,
  InlineAlertTypes
} from '@buildhero/sergeant';
import { withLoading } from 'components';
import { useHistory } from 'react-router-dom';

import { logErrorWithCallback } from 'utils';
import { Mode } from 'utils/constants';

import { RecurringMaintenanceBilling } from 'scenes/Settings/ServiceAgreement/RecurringMaintenanceBilling';
import { serviceAgreementSetting } from './ServiceAgreementsMeta';
import {
  updateServiceAgreementSettingsMutation,
  getRecurringBillingItems
} from './ServiceAgreementInvoiceSettings.gql';

const useStyles = makeStyles(() => ({
  heading: {
    marginBottom: 8
  }
}));

const MuiFormWithLoading = withLoading(MUIForm);

const loadingParams = {
  variant: 'table',
  repeatCount: 5,
  paddingTop: 12,
  paddingLeft: 8,
  paddingRight: 8
};

export const InvoiceSetting = ({ serviceAgreementsSettings, agreementInfo, showSnackbar }) => {
  const [disableEdit, setDisableEdit] = useState(true);
  const [isTotalPercentageError, setIsTotalPercentageError] = useState(false);
  const history = useHistory();
  const [muiService, setMuiService] = useState();

  const { data: rawSA, loading } = useQuery(getRecurringBillingItems, {
    variables: { id: agreementInfo.id },
    fetchPolicy: 'no-cache'
  });

  const [updateSetting, { loading: mutationLoading, error: updateError }] = useMutation(
    updateServiceAgreementSettingsMutation
  );

  if (updateError) {
    logErrorWithCallback(
      updateError,
      showSnackbar,
      `Unable to update invoice settings, please try later`
    );
  }

  const formData = {
    recurringMaintenanceBillingItems:
      rawSA?.serviceAgreement?.recurringMaintenanceBillingItems ||
      serviceAgreementsSettings?.recurringMaintenanceBillingItems ||
      []
  };

  const classes = useStyles();

  const validateSettings = data => {
    const { recurringMaintenanceBillingItems } = data;
    const percentages = recurringMaintenanceBillingItems.map(({ percentage }) =>
      Number(percentage)
    );
    const percentageError = sum(percentages) !== 100;
    return !percentageError;
  };

  const saveServiceAgreementSettings = async values => {
    if (!validateSettings(values)) {
      setIsTotalPercentageError(true);
      showSnackbar(
        'error',
        `Unable to save changes because total percentage allocated isn’t 100%. 
        Please ensure that you’ve entered all values correctly before saving.`
      );
      return;
    }
    const payload = values?.recurringMaintenanceBillingItems.map(
      ({ id, name, description, percentage, sortOrder }) => ({
        id,
        name,
        description,
        percentage,
        sortOrder
      })
    );
    await updateSetting({
      variables: {
        data: {
          id: agreementInfo.id,
          recurringMaintenanceBillingItems: payload
        }
      }
    });
    showSnackbar('success', 'Successfuly saved invoice settings');
  };
  return (
    <>
      <ThemeProvider>
        <InlineAlert
          type={InlineAlertTypes.BLUE}
          message="This information is pre-populated from your settings"
          style={{ margin: '16px 0', cursor: 'pointer' }}
          onClick={() => history.push('/settings/serviceAgreement')}
        />
        <Box display="flex" flexDirection="row">
          <Box width="85%">
            <Typography variant={TV.BASE} weight={TW.BOLD} className={classes.heading}>
              Recurring Maintenance Billing
            </Typography>
            <Typography variant={TV.S1} weight={TW.REGULAR}>
              Determine how a service agreement’s term price will be divided into line items on an
              invoice. Select line items and allocate a percentage of the term price towards each.
            </Typography>
            <Typography variant={TV.S1} weight={TW.BOLD}>
              Note: Total Percentage Allocated must be 100%
            </Typography>
          </Box>
          <Box>
            {disableEdit && (
              <Button type="tertiary" onClick={() => setDisableEdit(false)}>
                Edit Settings
              </Button>
            )}
            {!disableEdit && (
              <Button
                type="secondary"
                loading={mutationLoading}
                onClick={() => muiService?.submit()}
              >
                Save Settings
              </Button>
            )}
          </Box>
        </Box>
      </ThemeProvider>
      <Box width="85%" marginTop={2}>
        <MuiFormWithLoading
          configuration={serviceAgreementSetting(isTotalPercentageError, disableEdit)}
          data={formData}
          loadingParams={loadingParams}
          layout={Mode.EDIT}
          onCreateService={service => setMuiService(service)}
          onComplete={saveServiceAgreementSettings}
          isLoading={loading}
          customComponents={{ RecurringMaintenanceBilling }}
        />
      </Box>
    </>
  );
};

InvoiceSetting.propTypes = {
  serviceAgreementsSettings: PropTypes.shape({ recurringMaintenanceBillingItems: PropTypes.array }),
  agreementInfo: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  showSnackbar: PropTypes.func.isRequired
};

InvoiceSetting.defaultProps = {
  serviceAgreementsSettings: { recurringMaintenanceBillingItems: [] }
};
