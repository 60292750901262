/* eslint-disable no-useless-concat */
/* eslint-disable no-template-curly-in-string */

const service1stForm = () => ({
  fields: {
    senderInfoLogoUrl: {
      pdf: '${senderInfoLogo}'
    },
    tenantAddress: {
      pdf:
        'P.O. Box ${senderInfo.addressLine1}  *  ${senderInfo.addressLine3}  *  ${senderInfo.phoneNumber}'
    },
    senderInfoAddressLine1: {
      pdf: 'Address: ${senderInfo.addressLine1}'
    },
    senderInfoAddressLine2: {
      pdf: '${senderInfo.addressLine2}'
    },
    senderInfoAddressLine3: {
      pdf: '${senderInfo.addressLine3}'
    },
    // Customer Detail
    customerContactNameTitle: {
      pdf: 'Attn: '
    },
    customerContactName: {
      pdf: '${quoteInfo.Custom_text4}'
    },
    customerNameTitle: {
      pdf: 'Company: '
    },
    customerName: {
      pdf: '${quoteInfo.Custom_text1}'
    },
    billingAddressLine1Title: {
      pdf: 'Address: '
    },
    billingBillTo: {
      pdf: '${quoteInfo.billTo}'
    },
    billingAddressLine1: {
      pdf: '${billingAddressLine1}'
    },
    billingAddressLine2: {
      pdf: '${billingAddressLine2}'
    },
    billingAddressLine3: {
      pdf: '${billingAddressLine3}'
    },
    customerContactPhoneTitle: {
      pdf: 'Phone: '
    },
    customerContactPhone: {
      pdf: '${quoteInfo.Custom_text2}'
    },
    customerContactEmailTitle: {
      pdf: 'Via Email: '
    },
    customerContactEmail: {
      pdf: '${quoteInfo.Custom_text3}'
    },
    // Tenant Detail
    createdDateTitle: {
      pdf: 'Date: '
    },
    createdDate: {
      pdf: '${createdDate}'
    },
    quoteNameTitle: {
      pdf: 'Re: '
    },
    quoteName: {
      pdf: '${quoteInfo.name}'
    },
    propertyLabel: {
      pdf: 'Site: '
    },
    propertyName: {
      pdf: '${property.companyName}'
    },
    propertyAddress1: {
      pdf: '${propertyDetail.propertyAddressLine1}'
    },
    propertyAddress2: {
      pdf: '${propertyDetail.propertyAddressLine2}'
    },
    propertyAddress3: {
      pdf: '${propertyDetail.propertyAddressLine3}'
    },
    quoteNumberValueTitle: {
      pdf: 'File Number: '
    },
    quoteNumberValue: {
      pdf: '${customizedQuoteNumber}'
    },
    ownerValueTitle: {
      pdf: 'From: '
    },
    ownerValue: {
      pdf: '${quoteInfo.ownerValue}'
    },
    senderInfoEmailTitle: {
      pdf: 'Email: '
    },
    senderInfoEmail: {
      pdf: '${owner.email}'
    },
    // Introduction
    introduction: {
      pdf: '${quoteInfo.Custom_longText1}'
    },
    // Bid Basis
    bidBasis: {
      pdf: '${quoteInfo.Custom_longText2}'
    },
    // Specific Inclusions
    specificInclusion: {
      pdf: '${quoteInfo.Custom_longText3}'
    },
    // Specific Exclusions
    specificExclusions: {
      pdf: '${quoteInfo.Custom_longText4}'
    },
    // Qualifications
    qualifications: {
      pdf: '${quoteInfo.Custom_longText5}'
    },
    // Total
    totalTitle: {
      pdf: 'Total Proposal as Outlined Above ...................... '
    },
    totalText: {
      pdf: '$${finalAmount}'
    },
    // Closing Remarks
    closingRemarks: {
      pdf: '${quoteInfo.Custom_longText6}'
    },
    // Authorized Signature
    estimator: {
      pdf: '${quoteInfo.ownerValue}\nAuthorized Signature\n${senderInfo.companyName}'
    },
    // Legal
    legal: {
      pdf: '${quoteInfo.Custom_longText7}'
    }
  },
  layouts: {
    default: {
      options: {
        pdfTitle: 'Quote'
      },
      contents: [
        // Logo Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [6, 12, 6]
            // debug: true
          },
          contents: [
            {
              options: {
                direction: 'column',
                size: 'auto',
                grow: 0,
                shrink: 1
                // debug: true
              },
              contents: [
                {
                  component: {
                    default: 'Logo'
                  },
                  options: {
                    width: 340,
                    height: 200,
                    alt: 'Company logo',
                    size: 'auto',
                    grow: 0,
                    shrink: 1
                  },
                  source: 'senderInfoLogoUrl'
                }
              ]
            }
          ]
        },
        // Address Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [0, 0, 12]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'BodyText'
              },
              source: 'tenantAddress'
            }
          ]
        },
        // Customer Detail Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'space-between',
            padding: [6, 12, 6]
            // debug: true
          },
          contents: [
            // Customer Details
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                direction: 'column'
              },
              contents: [
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactName'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerName'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'billingAddressLine1Title'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'billingBillTo'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactPhoneTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactPhone'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactEmailTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactEmail'
                    }
                  ]
                }
              ]
            },
            // Tenant Details
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                direction: 'column'
              },
              contents: [
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'createdDateTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'createdDate'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'quoteNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'quoteName'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'quoteNumberValueTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'quoteNumberValue'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'ownerValueTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'ownerValue'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'senderInfoEmailTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'senderInfoEmail'
                    }
                  ]
                }
              ]
            }
          ]
        },
        // Divider
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [6, 12, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                pdf: 'Divider'
              }
            }
          ]
        },
        // Intro
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 12, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'BodyText'
              },
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1
              },
              source: 'introduction'
            }
          ]
        },
        // Description
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 12, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'SectionWithLabel'
              },
              options: {
                label: 'Description:',
                size: 'auto',
                grow: 0,
                shrink: 1
              },
              source: 'bidBasis'
            }
          ]
        },
        // Inclusions
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 12, 6]
            // debug: true
          },
          contents: [
            {
              options: {
                direction: 'column',
                justify: 'start',
                size: 'auto',
                grow: 0,
                shrink: 1,
                padding: [0, 0, 0, 0]
                // debug: true
              },
              contents: [
                {
                  component: {
                    default: 'Text',
                    pdf: 'SectionWithLabel'
                  },
                  options: {
                    label: 'Cost Includes:',
                    size: 'auto',
                    grow: 0,
                    shrink: 1
                  },
                  source: 'specificInclusion'
                },
                {
                  component: {
                    pdf: 'NumberedList'
                  },
                  options: {
                    key: 'name',
                    size: 'auto',
                    grow: 0,
                    shrink: 1
                  },
                  source: 'quoteTasks'
                }
              ]
            }
          ]
        },
        // Exclusions
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 12, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'SectionWithLabel'
              },
              options: {
                label: 'Cost Excludes:',
                size: 'auto',
                grow: 0,
                shrink: 1
              },
              source: 'specificExclusions'
            }
          ]
        },
        // Qualifications
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 12, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'SectionWithLabel'
              },
              options: {
                label: 'Qualifications:',
                size: 'auto',
                grow: 0,
                shrink: 1
              },
              source: 'qualifications'
            }
          ]
        },
        // Total
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'flex-end',
            align: 'stetch',
            padding: [0, 12, 0]
            // debug: true
          },
          contents: [
            {
              component: {
                pdf: 'Subtitle'
              },
              source: 'totalTitle'
            },
            {
              component: {
                pdf: 'Subtitle'
              },
              source: 'totalText'
            }
          ]
        },
        // Closing Remarks
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 12, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'SectionWithLabel'
              },
              source: 'closingRemarks'
            }
          ]
        },
        // Estimator Name
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 12, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'SectionWithLabel'
              },
              source: 'estimator'
            }
          ]
        },
        // Divider
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [6, 12, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                pdf: 'Divider'
              }
            }
          ]
        },
        // Acceptance of Proposal
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 12, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'SectionWithLabel'
              },
              options: {
                label: 'ACCEPTANCE OF PROPOSAL'
              },
              source: 'legal'
            }
          ]
        }
      ]
    }
  }
});

export default service1stForm;
