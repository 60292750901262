import gql from 'graphql-tag';

const updateInvoiceStatus = gql`
  mutation updateInvoiceStatus($partitionKey: String, $data: UpdateInvoiceStatusInput!) {
    updateInvoiceStatus(partitionKey: $partitionKey, data: $data) {
      id
      partitionKey
      tenantId
      entityType
      version
      status
      syncStatus
    }
  }
`;

export default updateInvoiceStatus;
