import React from 'react';
import PropTypes from 'prop-types';
import { searchIndex } from 'constants/algoliaIndex';
import { Typography, TV } from '@buildhero/sergeant';
import { useTheme } from '@material-ui/core';
import AlgoliaSearch from '../AlgoliaSearch';

export default function PropertySearch({ field, form, props: givenProps }) {
  const { palette } = useTheme();
  const props = {
    indexName: searchIndex,
    formatHitLabel: hit => hit.companyName,
    filters: 'entityType:CustomerProperty AND status:active',
    onChange: ({ id, companyName }) => {
      form.setValues(
        prev => ({
          ...prev,
          [field.name.split('.')[0]]: { id, companyName }
        }),
        true
      );
      // act like blur event -> can't use actual blur b/c mousedown on popper
      // will run the blur event before the selection
      form.setFieldTouched(field.name, true, false);
    },
    onClickAway: () => form.setFieldTouched(field.name, true),
    handleRenderOption: ({ label, value }) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: 24
        }}
      >
        <Typography variant={TV.BASE}>{label}</Typography>
        <Typography variant={TV.S2} color={palette.text.tertiary}>
          {value.customerName}
        </Typography>
      </div>
    ),
    ...givenProps,
    name: field.name,
    value: field.value
  };

  return <AlgoliaSearch {...props} />;
}

PropertySearch.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  // any input props or anything to override
  props: PropTypes.shape({
    style: PropTypes.object,
    label: PropTypes.string,
    required: PropTypes.bool
  }).isRequired
};
