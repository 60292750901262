import React from 'react';
import { PricebookService } from 'services/core';
import { logErrorWithCallback } from 'utils';

export const getLabourRateItems = pricebookItems =>
  pricebookItems.reduce((items, pricebook) => {
    const { pricebookLabourEntries } = pricebook;
    items = [...items, ...(pricebookLabourEntries?.items || [])];
    return items;
  }, []);

const getLabourRates = async (tenantId, companyId, snackbarOn) => {
  try {
    const Service = new PricebookService();
    const response = await Service.getLabourRates(tenantId, companyId);
    const pricebookItems = response?.data?.getCompany?.priceBooks?.items || [];
    const items = getLabourRateItems(pricebookItems);
    return items;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to fetch labour rates, please try again later');
  }
};

const useLabourRates = (tenantId, tenantCompanyId, snackbarOn, priceBookId) => {
  const [LabourRates, setLabourRates] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const getData = async () => {
    setLoaded(false);
    const labourRates = await getLabourRates(tenantId, tenantCompanyId, snackbarOn);
    if (priceBookId) {
      const laborRatesByPricebookId = labourRates?.filter(rate => rate.parentId === priceBookId);
      setLabourRates(laborRatesByPricebookId);
    } else {
      setLabourRates(labourRates);
    }
    setLoaded(true);
  };
  React.useEffect(() => {
    if (tenantId && tenantCompanyId) {
      getData();
    }
    // Disable lint - getData function not needed
    // eslint-disable-next-line
  }, [tenantId, tenantCompanyId, priceBookId]);

  return [LabourRates, getData, loaded];
};

export default useLabourRates;
