import gql from 'graphql-tag';

const updatePurchaseOrder = gql`
  mutation updatePurchaseOrder($partitionKey: String, $data: UpdatePurchaseOrderInput!) {
    updatePurchaseOrder(partitionKey: $partitionKey, data: $data) {
      id
      sortKey
      vendorName
      receiptNumber
      poNumber
      dateOfPurchase
      totalAmountPreTax
      departmentName
      tax
      addedBy
      createdBy
      version
      purchaseOrderReceipts(entityConnection: "PurchaseOrderReceipt") {
        items {
          id
          imageUrl
          fileName
          version
        }
      }
    }
  }
`;

export default updatePurchaseOrder;
