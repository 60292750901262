import { sortBy } from 'lodash';

import { COMPANY_SETTING_TYPE, payrollSettingFilter } from 'constants/common';
import { CompanyService, TimesheetsService } from 'services/core';
import { Logger } from 'services/Logger';
import { logErrorWithCallback } from 'utils';
import { TimeTrackingSettings } from 'utils/AppConstants';

/* Backend format
    settings: JSON.stringify({
    dailyShift: Bool
    enableTimestampsForEventTypes: ['NonBillable', 'BillableNonVisit']
    allowTechToCreateEventTypes:['NonBillable', 'BillableNonVisit']
    dailyShiftTimestampsOnly: Bool,
    weekStartDay: 'MONDAY',
    enableAutomatedTimeTracking: Bool
  })
*/
const convertSettingsJSONtoString = formData => {
  const enableTimestampsForEventTypes = [];
  const allowTechToCreateEventTypes = [];

  if (formData.billableNonvisitEvents) {
    enableTimestampsForEventTypes.push(TimeTrackingSettings.BILLABLE_NON_VISIT);
    if (formData.allowTechCreateBillableNonvisitEvents) {
      allowTechToCreateEventTypes.push(TimeTrackingSettings.BILLABLE_NON_VISIT);
    }
  }

  if (formData.nonBillableNonvisitEvents) {
    enableTimestampsForEventTypes.push(TimeTrackingSettings.NON_BILLABLE);
    if (formData.allowTechCreateNonBillableNonvisitEvents) {
      allowTechToCreateEventTypes.push(TimeTrackingSettings.NON_BILLABLE);
    }
  }

  const settingsJSON = {
    dailyShift: formData.dailyShift || false,
    enableTimestampsForEventTypes,
    allowTechToCreateEventTypes,
    dailyShiftTimestampsOnly: formData.timestamps === TimeTrackingSettings.DAILY_TIMESTAMPS,
    timeZone: formData.timeZone,
    enableAutomatedTimeTracking: formData.enableAutomatedTimeTracking || false,
    weekStartDay: formData.weekStartDay,
    defaultProductId: formData.defaultProductId,
    defaultProductName: formData.defaultProductName,
    mapPayrollHourToBilling: formData.mapPayrollHourToBilling
  };

  return JSON.stringify(settingsJSON);
};

const convertStringToSettingsJSON = payrollSettingObj => {
  const payrollSettingStr = payrollSettingObj?.settings;
  const settingJSON = {
    id: payrollSettingObj?.id,
    version: payrollSettingObj?.version
  };

  if (!payrollSettingStr) return settingJSON;

  try {
    const settingObj = JSON.parse(payrollSettingStr);
    settingJSON.dailyShift = settingObj.dailyShift === true;
    settingJSON.timeZone = settingObj?.timeZone || '';
    settingJSON.timestamps = settingObj.dailyShiftTimestampsOnly
      ? TimeTrackingSettings.DAILY_TIMESTAMPS
      : TimeTrackingSettings.ALL_TIMESTAMPS;
    settingJSON.billableNonvisitEvents = settingObj.enableTimestampsForEventTypes.includes(
      TimeTrackingSettings.BILLABLE_NON_VISIT
    );
    settingJSON.allowTechCreateBillableNonvisitEvents = settingObj.allowTechToCreateEventTypes.includes(
      TimeTrackingSettings.BILLABLE_NON_VISIT
    );
    settingJSON.nonBillableNonvisitEvents = settingObj.enableTimestampsForEventTypes.includes(
      TimeTrackingSettings.NON_BILLABLE
    );
    settingJSON.allowTechCreateNonBillableNonvisitEvents = settingObj.allowTechToCreateEventTypes.includes(
      TimeTrackingSettings.NON_BILLABLE
    );

    settingJSON.enableAutomatedTimeTracking = settingObj.enableAutomatedTimeTracking;
    settingJSON.weekStartDay = settingObj.weekStartDay;
    settingJSON.defaultProductId = settingObj.defaultProductId;
    settingJSON.defaultProductName = settingObj.defaultProductName;
    settingJSON.mapPayrollHourToBilling = settingObj.mapPayrollHourToBilling;
  } catch (err) {
    Logger.error('Unable to parse time tracking settings');
  }
  return settingJSON;
};

export const addSettings = async (formData = {}, tenantId, tenantCompanyId, snackbarOn) => {
  const Service = new CompanyService();
  const settings = convertSettingsJSONtoString(formData);
  const payload = {
    companyId: tenantCompanyId,
    companySettings: [
      {
        settings,
        type: COMPANY_SETTING_TYPE.PAYROLL
      }
    ]
  };

  let responseData = {};

  try {
    const response = await Service.addCompanySettings(tenantId, payload);
    snackbarOn('success', 'Successfully saved settings');
    responseData = response?.data?.addCompanySettingsToCompany;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to update settings, please try again later');
  }
  return convertStringToSettingsJSON(responseData);
};

export const updateSettings = async (formData = {}, tenantId, snackbarOn) => {
  const Service = new CompanyService();
  const settings = convertSettingsJSONtoString(formData);
  const payload = {
    id: formData.id,
    type: COMPANY_SETTING_TYPE.PAYROLL,
    version: formData.version,
    settings
  };

  let responseData = {};

  try {
    const response = await Service.updateCompanySettings(tenantId, payload);
    snackbarOn('success', 'Successfully updated settings');
    responseData = response?.data?.updateCompanySetting;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to update settings, please try again later');
  }
  return convertStringToSettingsJSON(responseData);
};

export const getSettings = async (tenantId, companyId, snackbarOn) => {
  const Service = new CompanyService();
  let responseData = {};
  try {
    const response = await Service.getCompanySettings(tenantId, companyId, payrollSettingFilter);
    responseData = response?.data?.getCompany?.companySettings?.items?.[0];
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to fetch settings, please try again later');
  }
  return convertStringToSettingsJSON(responseData);
};

export const updatePayrollHourTypeOrder = async (data, tenantId, tenantCompanyId, snackbarOn) => {
  const TimesheetsServiceObj = new TimesheetsService();
  const payload = {
    companyId: tenantCompanyId,
    payrollHourTypes: data
  };

  let responseData = {};

  try {
    const response = await TimesheetsServiceObj.addPayrollHourTypes(tenantId, payload);
    snackbarOn('success', `Successfully updated the order`);
    responseData = response?.data?.addPayrollHourTypesToCompany;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to update the order, please try again later');
  }
  return responseData;
};

export const addPayrollHourType = async (formData = {}, tenantId, tenantCompanyId, snackbarOn) => {
  const TimesheetsServiceObj = new TimesheetsService();
  const payload = {
    companyId: tenantCompanyId,
    payrollHourTypes: [
      {
        hourType: formData.hourType,
        hourTypeAbbreviation: formData.hourTypeAbbreviation,
        isAutofill: formData.isAutofill,
        intacctAccountNumber: formData.intacctAccountNumber,
        intacctNonBillableAccountNumber: formData.intacctNonBillableAccountNumber,
        billingHourTypeId: formData.billingHourTypeId
      }
    ]
  };

  let responseData = {};

  try {
    const response = await TimesheetsServiceObj.addPayrollHourTypes(tenantId, payload);
    snackbarOn('success', `Successfully saved type - ${formData.hourType}`);
    responseData = response?.data?.addPayrollHourTypesToCompany;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to add type, please try again later');
  }
  return responseData;
};

export const updatePayrollHourType = async (formData = {}, tenantId, snackbarOn) => {
  const TimesheetsServiceObj = new TimesheetsService();
  const payload = {
    id: formData.id,
    version: formData.version,
    hourType: formData.hourType,
    hourTypeAbbreviation: formData.hourTypeAbbreviation,
    hourTypeTag: formData.hourTypeTag,
    isAutofill: formData.isAutofill,
    intacctAccountNumber: formData.intacctAccountNumber,
    intacctNonBillableAccountNumber: formData.intacctNonBillableAccountNumber,
    billingHourTypeId: formData.billingHourTypeId
  };

  let responseData = {};

  try {
    const response = await TimesheetsServiceObj.updatePayrollHourTypes(tenantId, payload);
    snackbarOn('success', `Successfully updated ${formData.hourType} type`);
    responseData = response?.data?.updatePayrollHourType;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to update type, please try again later');
  }
  return responseData;
};

export const deletePayrollHourType = async (formData = {}, tenantId, snackbarOn) => {
  const TimesheetsServiceObj = new TimesheetsService();
  let responseData = {};

  try {
    const response = await TimesheetsServiceObj.deletePayrollHourType(tenantId, formData.id);
    snackbarOn('success', `Successfully deleted ${formData.hourType} type`);
    responseData = response?.data?.softDeletePayrollHourType;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to delete type, please try again later');
  }
  return responseData;
};

export const getPayrollHourTypes = async (tenantId, companyId, snackbarOn) => {
  const TimesheetsServiceObj = new TimesheetsService();
  let responseData = {};
  try {
    const response = await TimesheetsServiceObj.getPayrollHourTypes(tenantId, companyId);
    const hourTypes = response?.data?.getCompany?.payrollHourTypes?.items;
    responseData = sortBy(hourTypes, 'sortOrder');
  } catch (error) {
    logErrorWithCallback(
      error,
      snackbarOn,
      'Unable to fetch payroll types, please try again later'
    );
  }
  return responseData;
};

export default getSettings;
