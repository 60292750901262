import React, { useEffect, useReducer, useRef, useState } from 'react';

import { PDFDocument } from '@buildhero/sergeant';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { pdf } from '@react-pdf/renderer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { UserPermission, withMultipleForms } from 'components';
import AlgoliaSearch from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import DefaultButton from 'components/Buttons/DefaultButton';
import ResponsiveTable from 'components/ResponsiveTable';
import SergeantModal from 'components/SergeantModal';

import Spinner from 'components/Spinners/CircularIndeterminate';

import Labels from 'meta/labels';
import {
  addNewListItemFields,
  addNewListItemLayout
} from 'meta/Procurement/PurchaseOrders/addNewListItemForm';
import PDFDocumentLayout from 'meta/Procurement/PurchaseOrders/poReceiptPdf';
import rbDetailTableMeta from 'meta/Procurement/PurchaseOrders/rbDetailTableMeta';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import CustomCurrency from 'scenes/Procurement/component/CustomCurrency';
import ItemSearchBar from 'scenes/Procurement/component/ItemSearchBar';
import HeaderLine from 'scenes/Procurement/component/ModalHeaderLine';
import Title from 'scenes/Procurement/component/ModalTitle';
import PurchaseOrderCost from 'scenes/Procurement/component/PurchaseOrderCost';
import ReceiptBillDetailFrame from 'scenes/Procurement/component/ReceiptBillDetailFrame';
import SendEmailPopUp from 'scenes/Procurement/component/SendEmailPopUp';
import {
  downloadFile,
  getFormattedPOLinesData,
  getJobOrProjectLink,
  getLastLineNumber,
  getPurchaseOrderSideBarShipping,
  getReceiptDueDate,
  getReceiptSideBarVendor,
  getSanitizedLineItem,
  GOOGLE_MAPS_BASE_URL
} from 'scenes/Procurement/component/utils';
import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';
import { getCloudinaryImageUrl } from 'scenes/ProjectManagement/components/utils';
import { getBillByReceipt } from 'services/API/bill';
import { getReviewReportBillItemByBillLine } from 'services/API/billLine';
import { getCompanyBytenantId } from 'services/API/companies';
import { getPaymentTermById } from 'services/API/paymentTerm';
import { getPurchaseOrderById, purchaseOrderChange } from 'services/API/purchaseOrder';
import { purchaseOrderLineChange } from 'services/API/purchaseOrderLine';
import {
  getReceiptsById,
  postReceiptPost,
  purchaseOrderReceiptChange
} from 'services/API/purchaseOrderReceipt';
import {
  purchaseOrderReceiptLineCreate,
  purchaseOrderReceiptLineDelete
} from 'services/API/purchaseOrderReceiptLines';
import { getTaxRateById } from 'services/API/taxRate';
import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';
import { formatAddress, parseFloatAndRound, roundCurrency, roundFloat } from 'utils';
import {
  GOOGLE_MAPS_API_KEY,
  PermissionConstants,
  ProcurementPurchaseOrderStatus
} from 'utils/AppConstants';
import { AccountingApp, Mode, SyncStatus } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { generateDefaultValidationSchema } from '../../../ProjectManagement/components/formattingUtils';

import { NoteDetail } from '../../component/NoteDetail';

import AddReceiptItem from './AddReceiptItem';

const useStyles = makeStyles(() => ({
  pocContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 8
  }
}));

const getHeaderButtons = {
  emailReceipt: {
    label: 'Email Receipt',
    icon: 'MailOutline',
    caslAction: 'view',
    caslKey: ''
  },
  downloadReceipt: {
    label: 'Download Receipt',
    icon: 'CloudDownload',
    caslAction: 'view',
    caslKey: ''
  }
};

const initialState = {
  sidebarVendor: {},
  sidebarShipping: {}
};

const defaultRowActionButtons = {
  edit: {
    label: 'Edit',
    icon: 'Edit',
    caslAction: 'update',
    caslKey: ''
  },
  remove: {
    label: 'Remove',
    icon: 'Delete',
    caslAction: 'delete',
    caslKey: ''
  }
};

const receiptFrameDataReducer = (state, action) => {
  switch (action.type) {
    case 'updateAll':
      return {
        ...state,
        ...action.payload,
        sidebarVendor: {
          ...state.sidebarVendor,
          ...getReceiptSideBarVendor(action.mode, action.payload)
        },
        sidebarShipping: {
          ...state.sidebarShipping,
          ...getPurchaseOrderSideBarShipping(
            action.mode,
            action.payload?.PurchaseOrder,
            action.payload?.shipToEmployee
          )
        }
      };
    case 'updateApprovalStatus':
      return {
        ...state,
        ...action.payload,
        approvalNoteByName: action.user?.displayName
      };
    case 'updateTopbar':
      return {
        ...state,
        ...action.payload
      };
    case 'updateSidebarVendor':
      return { ...state, sidebarVendor: { ...state.sidebarVendor, ...action.payload } };
    case 'updateSidebarShipping':
      return {
        ...state,
        sidebarShipping: { ...state.sidebarShipping, ...action.payload }
      };
    case 'updateSidebarAttachments':
      return {
        ...state,
        ...action.payload
      };
    case 'initialize':
      return { topbar: {}, sidebar: {}, cost: {} };
    default:
      return state;
  }
};

const ReceiptsDetail = props => {
  // withMultipleForms HOC props
  const {
    getHandleCreateService,
    getHandleComplete,
    handleSubmitStart,
    setOnSubmitFinal,
    isSubmitting,
    user
  } = props;
  const { accountingApp: tenantAccountingApp } = useSelector(state => state.settings);
  const isVistaEnabled = tenantAccountingApp === AccountingApp.VISTA;
  const canSetEmptyJobAndProject =
    tenantAccountingApp !== AccountingApp.VISTA && tenantAccountingApp !== AccountingApp.SPECTRUM;
  const flags = useFlags();
  const departmentsAreLimitedByJob = flags[FeatureFlags.DEPARTMENTS_ARE_LIMITED_BY_JOB];

  const { receiptId, mode } = props.computedMatch?.params || {};
  const classes = useStyles();
  const history = useHistory();
  const [receiptFrameState, dispatchReceiptFrameData] = useReducer(
    receiptFrameDataReducer,
    initialState
  );

  const [loaded, setLoaded] = useState(false);
  const [receiptItems, setReceiptItems] = useState([]);
  const [receipt, setReceipt] = useState({});
  const [selectedEditItem, setSelectedEditItem] = useState({});
  const [receiptAddItemModal, setReceiptAddItemModal] = useState(false);
  const [receiptEditItemModal, setEditReceiptItemModal] = useState(false);
  const [isOpendEmailModal, setIsOpendEmailModal] = useState(false);
  const [uploadedPdfUrl, setUploadedPdfUrl] = useState('');
  const [uploadedPdfFileName, setUploadedPdfFileName] = useState('');
  const [orderEmailRecipient, setOrderEmailRecipient] = useState('');
  const [emailSubject, setEmailSubject] = useState('Purchase Order Receipt');
  const [companyInfo, setCompanyInfo] = useState({});
  const [isFileDownloading, setIsFileDownloading] = useState(false);
  const [allowEdit, setAllowEdit] = useState(true);
  const [associatedProject, setAssociatedProject] = useState(undefined);
  const [notAllowedZeroQty, setNotAllowedZeroQty] = useState(false);
  const [isPurchaseOrderVoid, setIsPurchaseOrderVoid] = useState(false);
  const isVistaReceiptSynced =
    isVistaEnabled &&
    (receipt.syncStatus === SyncStatus.SYNCING || receipt.syncStatus === SyncStatus.IN_SYNC);
  const [isApprovalRejectionModalOpen, setIsApprovalRejectionModalOpen] = useState(false);
  const approvalRejectionModalRef = useRef({
    status: null,
    mode: Mode.ADD
  });

  async function loadReceipt() {
    const bill = await getBillByReceipt(receiptId);
    if (bill && bill[0] && bill[0]?.BillLine) {
      await Promise.all(
        bill[0].BillLine.map(line => {
          return getReviewReportBillItemByBillLine(line.id).then(item => {
            if (item?.id || item[0]?.id) {
              setAllowEdit(false);
            }
          });
        })
      );
    }
    const result = await getReceiptsById(receiptId);
    const paymentTerm = await getPaymentTermById(result.paymentTermId);
    setReceiptItems(getFormattedPOLinesData(result.PurchaseOrderReceiptLine || []));
    if (paymentTerm && result) result.paymentTerm = paymentTerm;

    getPurchaseOrderById(result.PurchaseOrder?.id, null, ['company']).then(po => {
      if (po?.poType?.id) {
        result.poType = po.poType;
      }
      if (po?.assignedTo?.id) {
        result.PurchaseOrder.assignedTo = po.assignedTo;
      }
      if (po?.shipToEmployee?.id) {
        result.PurchaseOrder.shipToEmployee = po.shipToEmployee;
      }
      if (po?.project?.id) {
        setAssociatedProject(po.project);
      }
      if (po?.company?.purchaseOrderTermsAndConditions) {
        result.PurchaseOrder.termsAndConditions = po.company.purchaseOrderTermsAndConditions;
      }
    });
    setReceipt(result);
    setLoaded(true);
  }

  useEffect(() => {
    if (!receiptId) return;
    setLoaded(false);
    loadReceipt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (receiptFrameState.PurchaseOrder?.status === ProcurementPurchaseOrderStatus.VOID) {
      setIsPurchaseOrderVoid(true);
    }
  }, [receiptFrameState.PurchaseOrder?.status]);

  useEffect(() => {
    if (receipt?.id) {
      getPurchaseOrderById(receipt.PurchaseOrder.id || receipt.purchaseOrderId).then(po => {
        dispatchReceiptFrameData({
          type: 'updateAll',
          payload: {
            ...receipt,
            ...getJobOrProjectLink(receipt),
            jobAndProject: receipt.Job || receipt.Project,
            issuedBy: receipt.issuedBy,
            poType: po.poType,
            shipToEmployee: po.shipToEmployee
          },
          mode
        });
      });
    }
  }, [mode, receipt]);

  const sanitizeItems = items => {
    if (!items) return [];

    return items.map(item => {
      return {
        itemName: item.Product?.name || '',
        description: item.description || '',
        costCode: item.Product?.code || '',
        quantity: parseFloatAndRound(item.quantity, 2) || 0,
        unitCost: roundFloat(item.unitCost) || 0.00000, // prettier-ignore
        totalCost: roundCurrency(item.unitCost * item.quantity) || 0
      };
    });
  };

  const getPdfCosts = taxRate => {
    let lineItemsTotal = 0;
    let newTaxAmount = 0;
    const taxableItems = receiptItems?.filter(line => line.taxable);

    if (receiptItems?.length > 0) {
      lineItemsTotal = receiptItems
        .map(line => roundCurrency(line.quantity * line.unitCost))
        .reduce((prev, next) => prev + next);

      if (taxRate && taxableItems?.length) {
        newTaxAmount = taxableItems
          .map(line => roundCurrency(line.quantity * line.unitCost))
          .reduce((prev, next) => prev + next);
        newTaxAmount *= parseFloat(taxRate / 100);
        newTaxAmount = roundCurrency(newTaxAmount);
      }
    }
    const newTotal =
      lineItemsTotal + newTaxAmount + parseFloatAndRound(receiptFrameState?.freight, 2);
    return { total: newTotal, subtotal: lineItemsTotal, taxAmount: newTaxAmount };
  };

  const getFormattedPdfData = (companyData, taxRate) => {
    const pdfCostData = getPdfCosts(taxRate);
    return {
      senderLogoUrl: getCloudinaryImageUrl(companyData?.logoUrl || null),
      senderAddress: formatAddress(companyData?.companyAddresses || null, true) || null,
      senderPhoneNumber: companyData?.phonePrimary || null,
      receiptNumber: receiptFrameState?.receiptNumber || null,
      date: moment().unix(),
      issuedBy: receiptFrameState?.issuedBy || null,
      dueDate: getReceiptDueDate(receiptFrameState),
      vendorAddress: receiptFrameState?.sidebarVendor?.vendorAddress || null,
      vendorPhoneNumber: receiptFrameState?.sidebarVendor?.phoneNumber || null,
      shippingAddress: receiptFrameState?.sidebarShipping?.shippingAddress || null,
      shippingPhoneNumber: receiptFrameState?.sidebarShipping?.phoneNumber || null,
      shipToName: receiptFrameState?.sidebarShipping?.shipToName || null,
      shipToInstructions: receiptFrameState?.sidebarShipping?.shipToInstructions || null,
      termsAndConditions: receiptFrameState?.PurchaseOrder?.termsAndConditions || null,
      description: receiptFrameState?.description || 'No description',
      poItems: sanitizeItems(receiptItems) || [],
      subtotal: parseFloatAndRound(pdfCostData.subtotal, 2) || 0,
      freightCost: parseFloatAndRound(receiptFrameState?.freight, 2) || 0,
      taxRate: parseFloatAndRound(taxRate, 3) || 0,
      taxAmount: parseFloatAndRound(pdfCostData.taxAmount, 2) || 0,
      total: parseFloatAndRound(pdfCostData.total, 2) || 0
    };
  };

  const renderPDF = (companyData, taxData) => {
    return (
      <PDFDocument
        layout="pdf"
        configuration={PDFDocumentLayout(receiptFrameState)}
        initialValues={getFormattedPdfData(companyData, taxData)}
      />
    );
  };

  const uploadPDF = async pdfFile => {
    const currentDayFileFormat = moment().format('MM-DD-YYYY');
    const poUploadFileName = `Purchase_Order_Receipt_${currentDayFileFormat}.pdf`;

    try {
      const storageService = new StorageService();
      const s3Response = await storageService.uploadFile(
        pdfFile,
        `${user.tenantId}/${poUploadFileName}`,
        e => e,
        'application/pdf'
      );
      setOrderEmailRecipient(receiptFrameState?.generalInfo?.vendor?.email || '');
      setEmailSubject(
        companyInfo.companyName
          ? `Purchase Order Receipt - ${companyInfo.companyName}`
          : 'Purchase Order Receipt'
      );
      setUploadedPdfFileName(poUploadFileName);
      return s3Response;
    } catch (error) {
      Logger.error(error);
    }
  };

  const generateAndUploadPDF = async (companyData, taxData) => {
    const pdfDoc = renderPDF(companyData, taxData);
    const blobData = await pdf(pdfDoc).toBlob();
    let pdfFileUrl;
    if (blobData) {
      pdfFileUrl = await uploadPDF(blobData);
      setUploadedPdfUrl(pdfFileUrl);
    }
    return pdfFileUrl;
  };

  const handleHeaderActionButtons = async (buttonKey = '') => {
    setIsFileDownloading(true);
    const [companyData, taxData] = await Promise.all([
      getCompanyBytenantId(user.tenantId),
      getTaxRateById(receipt.taxRateId)
    ]);
    setCompanyInfo(companyData[0]);

    switch (buttonKey) {
      case 'emailReceipt':
        await generateAndUploadPDF(companyData[0], taxData?.taxRate || 0);
        setIsOpendEmailModal(true);
        break;
      case 'downloadReceipt':
        downloadFile(await generateAndUploadPDF(companyData[0], taxData?.taxRate || 0));
        break;
      case 'editApprovalNote':
        approvalRejectionModalRef.current = {
          status: receiptFrameState?.approvalStatus,
          mode: Mode.EDIT
        };
        setIsApprovalRejectionModalOpen(true);
        break;
      default:
        throw new Error('Wrong button key');
    }
    setIsFileDownloading(false);
  };

  const openAddReceiptItemModal = () => {
    const item = {
      Department: receipt.Department,
      jobAndProject: receipt.Job || receipt.Project || associatedProject || null,
      jobNumber: receipt.Job?.jobNumber || associatedProject?.number || null
    };

    setSelectedEditItem(item);
    setReceiptAddItemModal(true);
  };

  const handleChangeQuantity = ({ form, currentValue }) => {
    const { unitCost } = form.values;
    const newQuantity = parseFloatAndRound(currentValue, 2);
    newQuantity === 0 ? setNotAllowedZeroQty(true) : setNotAllowedZeroQty(false);
    form.setFieldValue('quantity', parseFloatAndRound(currentValue, 2));
    form.setFieldValue('totalCost', roundCurrency(unitCost * currentValue));
  };

  const saveNewItem = async items => {
    const newItems = await Promise.all(
      items.map(async (item, index) => {
        const updatedData = await purchaseOrderReceiptLineCreate({
          id: null,
          purchaseOrderReceiptId: receipt.id,
          lineNumber: getLastLineNumber(receiptItems, 'lineNumber') + 1 + index,
          projectPhaseId: item.projectPhase?.id || null,
          projectCostCodeId: item.projectCostCode?.id || null,
          projectCostType: item.projectCostType || null,
          jcPhaseId: item.jcPhase?.id || null,
          jcCostTypeId: item.jcCostType?.id || null,
          costCodeId: item.costCode?.id || null,
          departmentId: item.phaseDepartment?.departmentId || item.Department?.id || null,
          productId: item.Product?.id || null,
          description: item.description || null,
          quantity: parseFloatAndRound(item.quantity, 2),
          taxable: item.taxable || false,
          unitCost: roundFloat(item.unitCost) || 0.00000, // prettier-ignore
          unitOfMeasure: item?.Product?.unitOfMeasure?.name || item?.unitOfMeasure || null,
          jobId: receipt.jobId || null,
          projectId: receipt.projectId || null,
          jobCostTypeId: item.jobCostType?.id || null,
          revenueTypeId: item.revenueType?.id || null,
          purchaseOrderId: receipt.PurchaseOrder.id || receipt.purchaseOrderId || null
        });
        return {
          ...updatedData,
          totalCost: roundCurrency(Number(item.unitCost ?? 0) * Number(item.quantity ?? 0)) || 0,
          Department: item.phaseDepartment?.Department || item.Department,
          phaseDepartment: item.phaseDepartment || null,
          Product: item.Product,
          JcPhase: item.jcPhase,
          JcCostType: item.jcCostType,
          Job: receipt.Job,
          Project: receipt.Project,
          projectPhase: item.projectPhase,
          projectCostCode: item.projectCostCode,
          jobAndProject: item.jobAndProject,
          jobNumber: item.jobNumber,
          costCode: item.costCode,
          jobCostType: item.jobCostType,
          revenueType: item.revenueType
        };
      })
    );
    setReceiptItems([...receiptItems, ...newItems]);
    setReceiptAddItemModal(false);
  };

  const updatePOLineItem = async newReceiptItem => {
    const sanitizedLineItem = getSanitizedLineItem(newReceiptItem, true, canSetEmptyJobAndProject);
    await purchaseOrderLineChange(newReceiptItem.purchaseOrderLineId, sanitizedLineItem);
  };

  const saveEditedItem = async (updatedItem, stopLoading) => {
    if (!receiptItems.find(item => item.id === updatedItem.id)) {
      return saveNewItem([...updatedItem]);
    }

    const newLine = {
      jcPhaseId: updatedItem.jcPhase?.id || null,
      jcCostTypeId: updatedItem.jcCostType?.id || null,
      projectCostCodeId: updatedItem.projectCostCode?.id || null,
      projectCostType: updatedItem.projectCostType || null,
      projectPhaseId: updatedItem.projectPhase?.id || undefined,
      costCodeId: updatedItem.costCode?.id || null,
      departmentId: updatedItem.Department?.id || null,
      productId: updatedItem.Product?.id || null,
      description: updatedItem.description || null,
      quantity: parseFloatAndRound(updatedItem.quantity, 2) || 0,
      taxable: updatedItem.taxable || false,
      unitCost: roundFloat(updatedItem.unitCost) || 0.00000, // prettier-ignore
      unitOfMeasure: updatedItem?.Product?.unitOfMeasure?.name || null,
      jobCostTypeId: updatedItem.jobCostType?.id || null,
      revenueTypeId: updatedItem.revenueType?.id || null
    };

    const allLines = receiptItems.map(line => {
      if (line.id === updatedItem.id) {
        return {
          id: line.id,
          lineNumber: line.lineNumber,
          Product: {
            id: updatedItem.Product?.id,
            name: updatedItem.Product.name
          },
          Department: {
            id: updatedItem.Department?.id,
            tagName: updatedItem.Department?.tagName
          },
          ...newLine,
          unitOfMeasure:
            newLine.productId === line.productId ? line.unitOfMeasure : newLine.unitOfMeasure
        };
      }
      return {
        id: line.id,
        lineNumber: line.lineNumber,
        departmentId: line.Department?.id,
        costCodeId: line.costCode?.id,
        jcPhaseId: line.jcPhase?.id || line.jcPhaseId,
        jcCostTypeId: line.jcCostType?.id || line.jcCostTypeId,
        projectCostCodeId: line.projectCostCode?.id || line.projectCostCodeId,
        projectCostType: line.projectCostType || '',
        projectPhaseId: line.projectPhase?.id || line.projectPhaseId,
        productId: line.Product?.id,
        description: line.description,
        quantity: parseFloatAndRound(line.quantity, 2),
        taxable: line.taxable,
        unitCost: roundFloat(line.unitCost),
        unitOfMeasure: line.unitOfMeasure,
        jobCostTypeId: line.jobCostType?.id || line.jobCostTypeId || null,
        revenueTypeId: line.revenueType?.id || line.revenueTypeId || null
      };
    });

    await purchaseOrderReceiptChange(receipt.id, {
      PurchaseOrderReceiptLine: allLines
    })
      // eslint-disable-next-line no-return-await
      .then(async () => {
        const updatedItemWithJobAndProject = {
          ...updatedItem,
          jobAndProject: updatedItem.Job || updatedItem.Project
        };
        await updatePOLineItem(updatedItemWithJobAndProject);
      })
      .finally(async () => {
        await loadReceipt();
        setEditReceiptItemModal(false);
        stopLoading();
      });
  };

  const handleOnComplete = async newReceiptData => {
    const postData = {
      vendorId: newReceiptData.Vendor?.id || newReceiptData.vendorId,
      freight: newReceiptData.freightCost,
      departmentId: newReceiptData.Department?.id || newReceiptData.departmentId,
      taxRateId: newReceiptData.taxRate?.id || null,
      paymentTermId: newReceiptData.paymentTerm?.id || newReceiptData.paymentTermId,
      vendorDocumentNumber: newReceiptData.vendorDocumentNumber,
      orderedById: newReceiptData.OrderedBy?.id || newReceiptData.orderedById,
      issuedBy: newReceiptData.issuedBy || null,
      status: newReceiptData.status === 'Posted' ? 'Pending' : newReceiptData.status, // back to pending status
      syncStatus: newReceiptData.status === 'Posted' ? null : newReceiptData.syncStatus // back to pending status
    };

    await purchaseOrderReceiptChange(receiptId, postData);
    await purchaseOrderChange(newReceiptData.PurchaseOrder.id, {
      assignedToId:
        newReceiptData.PurchaseOrder.assignedTo?.id || newReceiptData.PurchaseOrder.assignedToId
    });

    if (newReceiptData.status === 'Exported') {
      await postReceiptPost(receiptId);
    }

    history.push(`/procurement/receipts-bills/receipt/view/${receiptId}`);
  };

  const handleRowactions = async (actionType, record) => {
    if (record.syncStatus === 'Exported' || record.syncStatus === 'Invoiced') {
      props.snackbarOn('error', "Can't edit or delete a receipt that has been exported");
      return;
    }
    if (actionType === 'remove') {
      const finalData = receiptItems.filter(item => item.id !== record.id);
      await purchaseOrderReceiptLineDelete(record.id);
      setReceiptItems([...finalData]);
    } else if (actionType === Mode.EDIT) {
      const editItem = { ...record };

      if (!editItem.costCode?.id && editItem.costCodeId) {
        editItem.costCode = { id: editItem.costCodeId || null, name: editItem.name || null };
      }
      editItem.jobOrProjectDisplay =
        editItem?.Job?.customIdentifier ||
        editItem?.Job?.jobNumber ||
        editItem?.Project?.name ||
        editItem?.Project?.number ||
        '';

      editItem.unitCost = roundFloat(record.unitCost);
      editItem.totalCost = roundCurrency(record.totalCost);
      const projectPhaseDepartment = editItem?.ProjectPhaseDepartment?.find(
        phaseDep => phaseDep.departmentId === editItem?.departmentId
      );
      editItem.phaseDepartmentId = projectPhaseDepartment?.id || editItem?.phaseDepartment?.id;

      setSelectedEditItem(editItem);
      setEditReceiptItemModal(true);
    }
  };

  return (
    <ErrorBoundaries>
      <UserPermission I="read" action={PermissionConstants.OBJECT_PURCHASE_ORDER}>
        <ReceiptBillDetailFrame
          googleMapURL={GOOGLE_MAPS_BASE_URL + GOOGLE_MAPS_API_KEY}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          id={receiptId}
          rbData={receiptFrameState}
          receiptItems={receiptItems}
          type="Receipt"
          mode={mode === 'view' ? 'default' : 'edit'}
          getHandleCreateService={getHandleCreateService}
          getHandleComplete={getHandleComplete}
          handleSubmitStart={handleSubmitStart}
          setOnSubmitFinal={setOnSubmitFinal}
          handleFormsSubmit={handleOnComplete}
          actionButtonHandler={handleHeaderActionButtons}
          actionButtons={mode === 'view' ? getHeaderButtons : {}}
          frameDataReducer={dispatchReceiptFrameData}
          isLoading={loaded}
          isSubmitting={isSubmitting}
          isFileDownloading={isFileDownloading}
          disableEdit={!allowEdit || isVistaReceiptSynced}
          isPurchaseOrderVoid={isPurchaseOrderVoid}
          isApprovalRejectionModalOpen={isApprovalRejectionModalOpen}
          setIsApprovalRejectionModalOpen={setIsApprovalRejectionModalOpen}
          approvalRejectionModalRef={approvalRejectionModalRef}
        >
          <NoteDetail
            name={receiptFrameState?.approvalNoteByName || receiptFrameState?.ApprovalNoteBy?.name}
            dateTime={receiptFrameState?.approvalNoteDateTime}
            note={receiptFrameState?.approvalNote}
            status={receiptFrameState?.approvalStatus}
          />
          {receipt?.status !== 'Posted' &&
            receipt?.status !== 'Exported' &&
            !isPurchaseOrderVoid &&
            allowEdit && (
              <div className={classes.buttonContainer}>
                <DefaultButton
                  label={Labels.addNewReceiptItem[user.locale]}
                  variant="containedSecondary"
                  style={{ height: 30, fontSize: 12 }}
                  onClick={openAddReceiptItemModal}
                  buttonProps={{ startIcon: <AddCircleOutlineIcon style={{ fontSize: 14 }} /> }}
                />
              </div>
            )}
          <ResponsiveTable
            fullScreen
            rowMetadata={rbDetailTableMeta(isVistaEnabled)}
            data={receiptItems}
            noDataMsg={loaded ? 'No Receipts' : <Spinner />}
            disableFilter
            defaults={{
              sortBy: 'itemNumber',
              sortOrder: 'asc'
            }}
            rowActionButtons={
              receipt?.status !== 'Posted' &&
              receipt?.syncStatus !== 'Invoiced' &&
              !isPurchaseOrderVoid &&
              allowEdit
                ? defaultRowActionButtons
                : []
            }
            customCellComponents={{
              CustomCurrency
            }}
            rowActions={handleRowactions}
          />
          <div className={classes.pocContainer}>
            <PurchaseOrderCost
              formLayout={mode === 'view' ? 'default' : 'edit'}
              lineItems={receiptItems}
              freightCosts={receipt.freight || 0}
              taxRateId={receipt.taxRateId || ''}
              taxRate={receipt.TaxRate}
              onFormChange={() => {}}
              getHandleCreateService={getHandleCreateService}
              getHandleComplete={getHandleComplete}
              useMultipleForm
            />
          </div>
        </ReceiptBillDetailFrame>
        <AddReceiptItem
          open={receiptAddItemModal}
          Department={receipt.Department}
          jobAndProject={receipt.Job}
          associatedProject={associatedProject}
          handleSave={saveNewItem}
          handleClose={() => setReceiptAddItemModal(false)}
          isVistaEnabled={isVistaEnabled}
        />
        <SergeantModal
          open={receiptEditItemModal}
          title={Labels.editPurchaseOrderReceiptItem[user.locale]}
          customPrimaryButtonLabel={Labels.saveChangesButtonText[user.locale]}
          disablePrimaryButton={notAllowedZeroQty}
          data={{
            ...selectedEditItem,
            Product: {
              id: selectedEditItem?.Product?.id,
              name: selectedEditItem?.Product?.name
            },
            jobOrProjectDisplay: selectedEditItem?.jobOrProjectDisplay,
            jcPhase: selectedEditItem?.JcPhase || null,
            jcCostType: selectedEditItem?.JcCostType || null
          }}
          dataType="Item"
          mode={Mode.EDIT}
          layout={addNewListItemLayout({
            handleChangeQuantity,
            selectedPhaseId: selectedEditItem.projectPhase?.id,
            selectedProjectId: selectedEditItem.projectId,
            selectedPhaseDepartmentId: selectedEditItem.phaseDepartmentId,
            projectCostCode: selectedEditItem.projectCostCode,
            jobOrProjectReadOnly: true,
            isVistaEnabled,
            selectedEditItem,
            selectedJobId: selectedEditItem.jobId,
            departmentsAreLimitedByJob,
            isVistaReceiptSynced,
            setSelectedEditItem
          })}
          handlePrimaryAction={(updatedItem, stopLoading) => {
            saveEditedItem(updatedItem, stopLoading);
          }}
          handleClose={() => setEditReceiptItemModal(false)}
          customComponents={{ SearchBar, ItemSearchBar, AlgoliaSearch, Title, HeaderLine }}
          alignCloseRight
          validationSchema={generateDefaultValidationSchema(
            addNewListItemFields(selectedEditItem.projectId, isVistaEnabled)
          )}
        />
        <SendEmailPopUp
          open={isOpendEmailModal}
          title="Email Purchase Order Receipt"
          btnLabel="Send Email"
          pdfData={getFormattedPdfData()}
          uploadedPdfUrl={uploadedPdfUrl}
          uploadedPdfFileName={uploadedPdfFileName}
          orderEmailRecipient={orderEmailRecipient}
          emailSubject={emailSubject}
          handleModalClose={() => setIsOpendEmailModal(false)}
        />
      </UserPermission>
    </ErrorBoundaries>
  );
};

ReceiptsDetail.propTypes = {
  getHandleCreateService: PropTypes.func.isRequired,
  getHandleComplete: PropTypes.func.isRequired,
  handleSubmitStart: PropTypes.func.isRequired,
  setOnSubmitFinal: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  computedMatch: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  snackbarOn: PropTypes.func.isRequired
};

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});
const mapStateToProps = state => ({ user: state.user });
const ReduxConnectedReceiptsDetail = connect(mapStateToProps, mapDispatcherToProps)(ReceiptsDetail);
const MultipleFormsPurchaseOrderDetail = withMultipleForms(ReduxConnectedReceiptsDetail, [
  'sidebarVendor',
  'sidebarShipping',
  'mainTop',
  'purchaseOrderCost'
]);

export default MultipleFormsPurchaseOrderDetail;
