const billLineMeta = [
  {
    id: 'billNumber',
    label: 'Bill #',
    type: 'TextField'
  },
  {
    id: 'poNumber',
    label: 'PO #',
    type: 'TextField'
  },
  {
    id: 'name',
    label: 'Item Name',
    type: 'TextField'
  },
  {
    id: 'description',
    label: 'Item Description',
    type: 'TextField'
  },
  {
    id: 'remainingQuantity',
    label: 'Qty Not Yet Invoiced',
    type: 'TextField'
  },
  {
    id: 'usedQuantityOnVisit',
    label: 'Qty Used on This Visit',
    type: 'TextField'
  },
  {
    id: 'quantityToBill',
    isCustom: true,
    label: 'Qty to Bill',
    type: 'CustomQuantityToBill'
  }
];

export default billLineMeta;
