import { PermissionConstants } from 'utils/AppConstants';

const PageTitles = {
  dashboard: {
    en: 'Dashboard',
    es: 'Tablero'
  },
  fieldReports: {
    en: 'Field Reports',
    es: 'Informes de campo'
  },
  rfis: {
    en: 'RFIs',
    es: 'RFIs'
  },
  changeOrders: {
    en: 'Change Orders',
    es: 'Cambiar ordenes'
  },
  submittals: {
    en: 'Submittals',
    es: 'Entregas'
  },
  purchasing: {
    en: 'Purchasing',
    es: 'Adquisitiva'
  },
  todo: {
    en: 'To-Do',
    es: 'Que hacer'
  },
  scheduling: {
    en: 'Scheduling',
    es: 'Planificación'
  },
  finance: {
    en: 'Finance',
    es: 'Finanzas'
  },
  projectSettings: {
    en: 'Project Settings',
    es: 'Configuración del proyecto'
  },
  files: {
    en: 'Files',
    es: 'Archivos'
  }
};

const SideBarMenuMap = {
  dashboard: {
    title: PageTitles.dashboard,
    link: 'dashboard',
    caslAction: 'read',
    caslKey: PermissionConstants.OBJECT_PM_DASHBOARD
  },
  fieldReports: {
    title: PageTitles.fieldReports,
    link: 'fieldreport',
    caslAction: 'read',
    caslKey: PermissionConstants.OBJECT_PM_FIELD_REPORT
  },
  rfis: {
    title: PageTitles.rfis,
    link: 'rfi/',
    caslAction: 'read',
    caslKey: PermissionConstants.OBJECT_PM_RFI
  },
  changeOrders: {
    title: PageTitles.changeOrders,
    link: 'changeorder',
    caslAction: 'read',
    caslKey: PermissionConstants.OBJECT_PM_CHANGE_ORDER
  },
  submittals: {
    title: PageTitles.submittals,
    link: 'submittal',
    caslAction: 'read',
    caslKey: PermissionConstants.OBJECT_PM_SUBMITTALS
  },
  purchasing: {
    title: PageTitles.purchasing,
    link: 'purchasing',
    caslAction: 'read',
    caslKey: PermissionConstants.OBJECT_PM_PURCHASING
  },
  finance: {
    title: PageTitles.finance,
    link: 'finance',
    caslAction: 'read',
    caslKey: PermissionConstants.OBJECT_PM_FINANCE
  },
  projectSettings: {
    title: PageTitles.projectSettings,
    link: 'projectsettings',
    caslAction: 'read',
    caslKey: PermissionConstants.OBJECT_PM_PROJECT_SETTINGS
  }
};

export const getSidebarMenuItems = isFileSystemEnabled => {
  const items = Object.values(SideBarMenuMap);
  if (isFileSystemEnabled) {
    return [
      ...items,
      {
        title: PageTitles.files,
        link: 'files'
      }
    ];
  }
  return items;
};
