import { Logger } from 'services/Logger';

export const propertyRepExpandComponent = (propertiesAndRepsData, addRep) => ({
  type: 'component',
  key: 'representative',
  props: {
    btn: {
      saveLabel: 'SAVE',
      saveAction: addRep,
      cancelLabel: 'CANCEL',
      cancelAction: () => {}
    }
  },
  data: { representativeData: propertiesAndRepsData.representativeData }
});

export const customerRepExpandComponent = (propertiesAndRepsData, addRep) => ({
  type: 'component',
  key: 'representative',
  props: {
    btn: {
      saveLabel: 'SAVE',
      saveAction: addRep,
      cancelLabel: 'CANCEL',
      cancelAction: () => {}
    }
  },
  data: { customerRepData: propertiesAndRepsData.customerRepData }
});

export const elementProps = ({
  repType,
  propertiesAndRepsData,
  user,
  setPropertiesAndRepsData,
  quoteInfo,
  updateQuoteRep
}) => {
  let key;
  let updateKey;
  if (repType === 'customerRep') {
    key = 'customerReps';
    updateKey = 'orderedById';
  } else {
    key = 'reps';
    updateKey = 'propertyRepId';
  }

  return {
    options: {
      valueSet: (propertiesAndRepsData?.[key] || []).map(r => ({
        label: `${r.firstName}${r.lastName ? ` ${r.lastName}` : ''}`,
        value: r.id,
        ...r
      }))
    },
    name: repType,
    label: '',
    defaultValue: '',
    helperText: '',
    createLabel: 'New rep',
    form: { values: { [repType]: propertiesAndRepsData[repType] }, errors: {} },
    [repType]: propertiesAndRepsData[repType],
    user,
    onChange: async repId => {
      if (quoteInfo.id) {
        await updateQuoteRep({ [updateKey]: repId });
      }
      setPropertiesAndRepsData({
        ...propertiesAndRepsData,
        [repType]: repId,
        customerRepData: propertiesAndRepsData[key].find(r => r.id === repId)
      });
    }
  };
};

export const getCustomerRepDetails = async ({
  customerPropertyService,
  propertyData,
  quoteInfo,
  setPropertiesAndRepsData,
  snackbarOn,
  updatedPropertiesAndRepsData,
  user
}) => {
  if (!user?.tenantId || !propertyData.id) {
    return null;
  }
  try {
    const { data } = await customerPropertyService.getCustomerRepsByCustomerPropertyById(
      propertyData.id
    );
    if (data?.getCustomerPropertyById) {
      const localProperty = data.getCustomerPropertyById;
      // preparing customer reps
      if (localProperty?.customerReps?.items.length > 0) {
        localProperty.customerReps.items.forEach((rep, index) => {
          let localData = rep;
          if (!rep.parent) {
            const { mappedEntity, ...parent } = rep;
            localData = { ...mappedEntity, parent };
            localProperty.customerReps.items[index] = localData;
          }
        });
      }

      let repData = {};
      // when property change the user its quoteinfo orderedById does not match customerReps because its new property
      const repExist = localProperty?.customerReps?.items.some(
        pr => pr.id === quoteInfo?.propertyRepId
      );
      if (quoteInfo && !_.isEmpty(quoteInfo) && (repExist || quoteInfo?.propertyRepId === '')) {
        repData = { id: quoteInfo?.propertyRepId } || {};
      } else {
        repData =
          localProperty?.customerReps?.items.length > 0 ? localProperty.customerReps.items[0] : {};
      }
      const newState = {
        ...updatedPropertiesAndRepsData,
        reps: localProperty.customerReps.items,
        propertyRep: repData.id || '',
        representativeData: repData
      };
      setPropertiesAndRepsData(newState);
      return newState;
    }
  } catch (error) {
    Logger.error(`Error in fetching property customer rep data ${JSON.stringify(error)}`);
    snackbarOn('error', 'Unable to fetch Customer Reps, please try again later', error);
  }
};
