const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%'
  },
  linkIcon: {
    fontSize: 12
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: '#646464'
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%'
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#f2f2f2',
    border: '1px solid',
    borderColor: '#979797',
    borderRadius: '4px',
    opacity: 0.4
  },
  imageTitle: {
    position: 'relative',
    color: '#646464'
  },
  attachment: {
    paddingBottom: 28,
    paddingLeft: 8
  },
  attachmentFile: {
    padding: 5,
    minWidth: 260,
    backgroundColor: theme.palette.grayscale(90)
  },
  noFile: {
    width: 0,
    padding: 0
  },
  buttonOutlinedSecondary: {
    border: 'none',
    textTransform: 'unset',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 'normal',
    backgroundColor: 'inherit',
    padding: 0,
    letterSpacing: 0.3,
    '&:hover': {
      border: 'none',
      backgroundColor: 'inherit'
    },
    '&:disabled': {
      border: 'none',
      backgroundColor: 'inherit'
    }
  },
  errorMsg: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    color: '#b00020'
  }
});

export default styles;
