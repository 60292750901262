import gql from 'graphql-tag';

const createJobFromQuote = gql`
  mutation createJobFromQuote($partitionKey: String!, $data: CreateJobFromQuoteInput!) {
    createJobFromQuote(partitionKey: $partitionKey, data: $data) {
      id
      jobNumber
    }
  }
`;

export default createJobFromQuote;
