import uuid from 'uuid';
import { uniqBy, map } from 'lodash';
import removeObjProperties from 'utils/removeObjProperties';
import { convertStringToFloat, convertToCurrencyString } from 'utils';
import { lineItemTemplate, unUsedLineItemProperties } from './constants';

export const modifyLineItemMeta = (tableMeta, hourTypes = []) => {
  const hourTypesLength = hourTypes.length;
  return hourTypes.reduce(
    (existingMeta, type, index) => {
      const { hourType, hourTypeAbbreviation, id } = type;
      const commonMeta = {
        hourType: hourType.hourType,
        billingHourTypeId: id,
        isCustom: true,
        disableSort: true
      };

      const isLastItem = index === hourTypesLength - 1;
      const border = '2px solid #000000';

      const rateBorderStyle = {
        borderRight: isLastItem ? border : ''
      };

      const hourBorderStyle = {
        borderLeft: border
      };

      const hourTypeMeta = {
        id: uuid.v4(),
        numeric: true,
        dataKey: 'rate',
        label: hourTypeAbbreviation,
        type: 'BillingDetailInput',
        cellStyle: rateBorderStyle,
        headerStyle: rateBorderStyle,
        ...commonMeta
      };
      const hourMeta = {
        id: uuid.v4(),
        numeric: true,
        dataKey: 'totalHours',
        label: 'Hours',
        cellStyle: hourBorderStyle,
        headerStyle: hourBorderStyle,
        type: 'BillingDetailInput',
        ...commonMeta
      };
      existingMeta.push(hourMeta);
      existingMeta.push(hourTypeMeta);
      return existingMeta;
    },
    [...tableMeta]
  );
};

export const newItemTemplate = hourTypes => {
  const labourRateBillingHourLines = hourTypes.reduce((acc, type) => {
    acc.push({
      rate: null,
      totalHours: null,
      hourTypeId: type.id
    });
    return acc;
  }, []);
  return { ...lineItemTemplate, temporaryId: uuid.v4(), labourRateBillingHourLines };
};

export const lineItemSubTotal = billingHourLines =>
  billingHourLines.reduce((sum, hourItem) => {
    const { totalHours = 0, rate = 0 } = hourItem;
    const total = Number(totalHours) * Number(rate);
    return sum + total;
  }, 0);

export const getLineItems = (data = []) =>
  data.reduce((acc, lineItem) => {
    const { labourRateBillingHourLines, ...rest } = lineItem;
    const labourLineItem = removeObjProperties(rest, unUsedLineItemProperties);
    const billingLineItems = labourRateBillingHourLines?.items || [];
    const billingHourLines = billingLineItems.map(item =>
      removeObjProperties(item, unUsedLineItemProperties)
    );
    const subTotal = lineItemSubTotal(billingHourLines);
    acc.push({ ...labourLineItem, subTotal, labourRateBillingHourLines: billingHourLines });
    return acc;
  }, []);

const modifyHourRate = (
  itemKey,
  itemValue,
  labourRates,
  billingHourLines,
  lineItem,
  overwriteRate = false
) => {
  if (['labourTypeId', 'employeeId'].includes(itemKey)) {
    const value = itemKey === 'employeeId' ? lineItem.labourTypeId : itemValue;
    // when billing hours are not entered by the user, based is not creating entries for all billing hour typs
    // in this case, the labor rate to be fetched from pricebook or default labor rate
    if (billingHourLines?.length === 0) {
      const labourRatePopulated = labourRates.map(item => {
        if (item.labourTypeId === value) {
          return { hourTypeId: item.billingHourTypeId, rate: item?.rate || 0 };
        }
      });
      return labourRatePopulated.filter(item => !!item);
    }
    const modifiedItems = billingHourLines.reduce((acc, item) => {
      if (!overwriteRate && Number.isInteger(parseInt(item?.rate, 10))) {
        acc.push({ ...item });
        return acc;
      }

      const foundInfo =
        labourRates.find(
          ({ labourTypeId, billingHourTypeId }) =>
            labourTypeId === value && billingHourTypeId === item.hourTypeId
        ) || {};
      acc.push({ ...item, rate: foundInfo?.rate });
      return acc;
    }, []);
    return modifiedItems;
  }
  return billingHourLines;
};

const getEmployeeBillingHours = ({ selectedEmployee, employees }) => {
  const employee = employees.find(({ id }) => selectedEmployee === id);

  if (!employee) return [];
  const { labourTypeId, billingRates } = employee;
  const billingRatesItems = billingRates?.items || [];
  const items = billingRatesItems.map(billingRate => ({
    ...billingRate,
    billingHourTypeId: billingRate.hourTypeId,
    labourTypeId
  }));
  return items;
};

export const getHourRate = ({ employees, lineItem, labourTypes }) => {
  const { employeeId, labourTypeId } = lineItem;
  const { payrollCosts: employeeRate } = employees.find(({ id }) => id === employeeId) || {};

  if (employeeRate?.items?.length) {
    return employeeRate.items;
  }

  const { payrollCosts } = labourTypes.find(({ id }) => id === labourTypeId) || {};
  return payrollCosts?.items || [];
};

export const getLineItemsForLabourRateSheet = ({
  lineItems,
  employees,
  labourRates,
  labourTypes,
  isDefaultRate,
  visitTimesheet,
  overwriteRates = false
}) => {
  const values = lineItems.map(lineItem => {
    const employeesBillingRates = getEmployeeBillingHours({
      selectedEmployee: lineItem.employeeId,
      employees
    });
    const rates = isDefaultRate ? employeesBillingRates : labourRates;
    const employeeDetail = employees.find(({ id }) => id === lineItem.employeeId);
    const labourTypeId = isDefaultRate
      ? getEmployeeLabourType(employeeDetail, labourTypes)
      : lineItem.labourTypeId;

    lineItem.labourTypeId = labourTypeId;
    const modifiedBillingHour = modifyHourRate(
      'employeeId',
      labourTypeId,
      rates,
      lineItem.labourRateBillingHourLines,
      lineItem,
      overwriteRates
    );
    const subTotal = lineItemSubTotal(modifiedBillingHour);

    const itemWithBillingHour = {
      ...lineItem,
      labourTypeId,
      subTotal,
      labourRateBillingHourLines: modifiedBillingHour
    };
    return itemWithBillingHour;
  });
  return values;
};

const updateEmployeeJobCostingProperties = ({ labourTypes, rowValue, labourTypeId }) => {
  const labourType = labourTypes.find(({ id }) => id === labourTypeId);
  rowValue.labourTypeId = labourType?.id;
  rowValue.costCodeId = labourType?.costCodeId;
  rowValue.revenueTypeId = labourType?.revenueTypeId;
  rowValue.jobCostTypeId = labourType?.jobCostTypeId;
  return rowValue;
};

const handleLineValueChange = ({
  rowValue,
  key,
  visitTimesheet,
  value,
  labourTypes,
  employees,
  isDefaultRate,
  labourRates,
  labourRateBillingHourLines
}) => {
  const employeesBillingRates = getEmployeeBillingHours({
    selectedEmployee: rowValue.employeeId,
    employees
  });

  const rates = isDefaultRate ? employeesBillingRates : labourRates;

  const modifiedBillingHour = modifyHourRate(
    key,
    value,
    rates,
    labourRateBillingHourLines,
    rowValue,
    true
  );

  const itemWithBillingHour = {
    ...rowValue,
    labourRateBillingHourLines: modifiedBillingHour
  };
  return itemWithBillingHour;
};

const getEmployeeLabourType = (employee, labourTypes) => {
  const [defaultLabourType] = labourTypes;
  const selectedLabourType = employee?.labourTypeId;
  return selectedLabourType || defaultLabourType?.id;
};

export const editLineItem = ({
  key,
  value,
  rowIndex,
  itemIndex,
  lineItems,
  employees,
  hourTypeId,
  labourTypes,
  labourRates,
  visitTimesheet,
  isDefaultRate = false
}) => {
  const clonedItems = [...lineItems];
  let rowValue = lineItems[rowIndex];
  const { labourRateBillingHourLines } = rowValue;
  if (itemIndex === undefined) {
    rowValue[key] = value;

    if (key === 'employeeId') {
      const employeeDetail = employees.find(({ id }) => id === value);
      const labourTypeId = getEmployeeLabourType(employeeDetail, labourTypes);
      rowValue = updateEmployeeJobCostingProperties({ labourTypes, rowValue, labourTypeId });
    }
    if (key === 'labourTypeId') {
      rowValue = updateEmployeeJobCostingProperties({ labourTypes, rowValue, labourTypeId: value });
    }

    clonedItems[rowIndex] = handleLineValueChange({
      rowValue,
      key,
      value,
      employees,
      labourTypes,
      labourRates,
      isDefaultRate,
      visitTimesheet,
      labourRateBillingHourLines
    });
  }

  if (itemIndex !== undefined) {
    const itemValue = labourRateBillingHourLines[itemIndex] || {};
    labourRateBillingHourLines[itemIndex] = { ...itemValue, hourTypeId, [key]: value };
    clonedItems[rowIndex] = { ...rowValue, labourRateBillingHourLines };
  }
  const newLines = clonedItems[rowIndex].labourRateBillingHourLines;

  const subTotal = lineItemSubTotal(newLines);
  clonedItems[rowIndex] = { ...clonedItems[rowIndex], subTotal };

  return clonedItems;
};

export const makeCustomComponentDetails = ({ employees, labourTypes, isDefaultRate }) => [
  { key: 'Technician', options: employees },
  { key: 'LabourType', options: labourTypes, disableEdit: isDefaultRate }
];

export const handleRemove = (items, index) => {
  const clonedReport = [...items];
  clonedReport.splice(index, 1);
  return clonedReport;
};

export const overAllTotal = items => {
  const total = items.reduce((acc, item) => {
    return acc + Number(item.subTotal || 0);
  }, 0);
  return convertToCurrencyString(total);
};

export const addTimesheetEmployeeToLineItem = ({
  visitTimesheet,
  employees,
  hourTypes,
  labourTypes,
  labourRates,
  isDefaultRate
}) => {
  const items = [];
  const timesheetEmployees = map(visitTimesheet, 'employeeId');
  employees.forEach(employee => {
    if (timesheetEmployees.includes(employee.id)) {
      const { id: employeeId } = employee;
      const labourTypeId = getEmployeeLabourType(employee, labourTypes);
      let rowValue = { ...newItemTemplate(hourTypes), employeeId, labourTypeId };
      rowValue = updateEmployeeJobCostingProperties({ labourTypes, rowValue, labourTypeId });
      const { labourRateBillingHourLines } = rowValue;
      const employeeValue = handleLineValueChange({
        rowValue,
        key: 'employeeId',
        visitTimesheet,
        labourTypes,
        employees,
        isDefaultRate,
        labourRates,
        temporaryId: uuid.v4(),
        labourRateBillingHourLines
      });
      items.splice(timesheetEmployees.indexOf(employeeId), 0, employeeValue); // add raw at exact position recieved from BE
    }
  });
  return uniqBy(items, 'employeeId');
};

export const formatManualTimesheetEntries = (timesheetEntries = []) =>
  timesheetEntries.map(timesheet => {
    const totalHours = timesheet.timeMinutes;
    const data = {
      totalHours,
      hourTypeId: timesheet.hourTypeId,
      employeeId: timesheet.employeeId
    };
    return data;
  });

export const formatTimesheetEntries = (timesheetEntries = []) =>
  timesheetEntries.map(entry => {
    const employeeId = entry?.timesheetPeriod?.parentId;
    const { hourTypeId, actualTotalDurationOverride, actualTotalDuration } = entry;
    const duration = Number.isInteger(actualTotalDurationOverride)
      ? actualTotalDurationOverride
      : actualTotalDuration;
    const totalHours = duration / 3600;
    const data = {
      totalHours,
      hourTypeId,
      employeeId
    };
    return data;
  });

export const checkLineItemPropEmpty = lineItems =>
  lineItems.every(lineItem => {
    const { labourTypeId, jobCostTypeId, costCodeId } = lineItem;
    const filteredProps = [labourTypeId, jobCostTypeId, costCodeId].filter(Boolean);
    return filteredProps.length !== 0;
  });
