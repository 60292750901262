import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinners/CircularIndeterminate';
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveTable from 'components/ResponsiveTable';
import poBillListTableMeta from 'meta/Procurement/PurchaseOrders/poBillListTableMeta';
import { getPOBills } from 'services/API/bill';
import { calcTotalCost, getJobOrProjectLink } from 'scenes/Procurement/component/utils';

const useStyles = makeStyles(theme => ({
  root: {},
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 533,
    border: '3px dashed #00874D'
  },
  title: {
    color: theme.palette.grayscale(20),
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '-0.05em'
  },
  text: {
    color: theme.palette.grayscale(60),
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '-0.02em',
    textAlign: 'center',
    maxWidth: 190,
    marginTop: 4
  }
}));
const BillsList = ({ poId }) => {
  const classes = useStyles();
  const [billItems, setBillItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!poId) return;

    async function getBills() {
      const bills = await getPOBills(poId, ['Vendor', 'Department', 'BillLine', 'Job', 'Project']);
      // eslint-disable-next-line no-restricted-syntax
      for (const bill of bills) {
        // eslint-disable-next-line no-await-in-loop
        bill.totalCost = await calcTotalCost(bill, 'BillLine');
        const { jobOrProjectLink, jobOrProjectText } = getJobOrProjectLink(bill);
        bill.jobOrProjectLink = jobOrProjectLink;
        bill.jobOrProjectText = jobOrProjectText;
      }
      setBillItems(bills);
      setIsLoading(false);
    }

    getBills();
  }, [poId]);

  return (
    <div className={classes.root}>
      {billItems.length > 0 ? (
        <ResponsiveTable
          fullScreen
          rowMetadata={poBillListTableMeta}
          // TODO: replace stub data with server's data using API
          data={billItems}
          noDataMsg="No Bills"
          disableFilter
          defaults={{
            sortBy: 'billNumber',
            sortOrder: 'asc'
          }}
        />
      ) : (
        <div>
          {isLoading ? (
            <Spinner />
          ) : (
            <div className={classes.emptyContainer}>
              <div className={classes.title}>Nothing here.</div>
              <div className={classes.text}>Please generate a receipt.</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

BillsList.propTypes = {
  poId: PropTypes.string.isRequired
};

export default BillsList;
