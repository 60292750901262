import gql from 'graphql-tag';

const getTaxRates = gql`
  query getClasses($partitionKey: String!, $sortKey: String!, $nextToken: String) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      classes(
        entityConnection: "Class"
        filter: { isActive: { eq: true } }
        nextToken: $nextToken
      ) {
        nextToken
        items {
          id
          name
          accountingRefId
          accountingApplication
          isActive
          lastUpdatedDateTime
        }
      }
    }
  }
`;

export default getTaxRates;
