import React from 'react';
import { useDragLayer } from 'react-dnd';
import { makeStyles } from '@material-ui/core';

import VisitCard from '@dispatch/components/VisitCard';
import NonVisitCard from '@dispatch/components/NonVisitCard';

import { dragOffset, ItemTypes } from './dnd.constants';

const useDragLayerStyles = makeStyles(() => ({
  dragLayer: {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%'
  }
}));

const getItemStyles = (initialOffset, currentOffset, isSnapToGrid) => {
  if (!initialOffset || !currentOffset) return { display: 'none' };
  let { x, y } = currentOffset;

  if (isSnapToGrid) {
    x -= initialOffset.x;
    y -= initialOffset.y;
    // [x, y] = snapToGrid(x, y);
    x += initialOffset.x;
    y += initialOffset.y;
  }

  const transform = `translate(${x - dragOffset.x}px, ${y - dragOffset.y}px)`;

  return {
    transform,
    WebkitTransform: transform,
    display: 'inline-block'
  };
};

const DragLayer = () => {
  const classes = useDragLayerStyles();

  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging()
  }));

  const renderItem = () => {
    switch (itemType) {
      case ItemTypes.BOARD_VISIT:
      case ItemTypes.TABLE_VISIT:
        return <VisitCard isDragging visit={item.data} />;
      case ItemTypes.NON_VISIT_EVENT:
        return <NonVisitCard isDragging event={item.data} />;
      default:
        return null;
    }
  };

  if (!isDragging) return null;

  return (
    <div className={classes.dragLayer}>
      <div style={getItemStyles(initialOffset, currentOffset)}>{renderItem()}</div>
    </div>
  );
};

export const withDragLayer = Component => props => (
  <>
    <DragLayer />
    <Component {...props} />
  </>
);

export default DragLayer;
