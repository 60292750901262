import React, { memo, useCallback } from 'react';
import { any, bool, func } from 'prop-types';
import { isNumber } from 'lodash/lang';
import { Box } from '@material-ui/core';
import { CurrencyInput } from '@buildhero/sergeant';
import { useStyles } from './NumberInputCell.styles';

const coerceNullToUndefined = value => (value === null ? undefined : value);

const NumberInputCell = memo(({ value, onChange, showCurrencySymbol, disabled }) => {
  const classes = useStyles();
  const displayValue = value?.toFixed(2);
  return (
    <Box display="inline-flex">
      <CurrencyInput
        value={displayValue}
        symbol={isNumber(value) && showCurrencySymbol ? '$' : null}
        onBlur={newValue => {
          if (coerceNullToUndefined(newValue) !== coerceNullToUndefined(value)) {
            onChange(newValue);
          }
        }}
        css={classes.container}
        disabled={disabled}
        placeholder="-"
      />
    </Box>
  );
});

export const HoursCell = memo(({ value, row, hourType, onChangeHours, disabled }) => {
  const handleChange = useCallback(
    hourValue => {
      // eslint-disable-next-line react/prop-types
      const lineItemId = row?.id;
      const hourTypeId = hourType.id;
      onChangeHours({ lineItemId, hourTypeId, value: hourValue });
    },
    [row, hourType, onChangeHours]
  );

  return <NumberInputCell disabled={disabled} value={value} onChange={handleChange} />;
});

export const LaborRateCell = memo(({ value, row, onChangeRate, hourType, disabled }) => {
  const handleChange = useCallback(
    rateValue => {
      // eslint-disable-next-line react/prop-types
      const lineItemId = row?.id;
      const hourTypeId = hourType.id;
      onChangeRate({ lineItemId, hourTypeId, value: rateValue });
    },
    [row, onChangeRate, hourType]
  );

  return (
    <NumberInputCell showCurrencySymbol disabled={disabled} value={value} onChange={handleChange} />
  );
});

NumberInputCell.propTypes = {
  value: any.isRequired,
  onChange: func.isRequired,
  disabled: bool.isRequired,
  showCurrencySymbol: bool
};

NumberInputCell.defaultProps = {
  showCurrencySymbol: false
};

export default NumberInputCell;
