import gql from 'graphql-tag';

const jobUpdateNotification = gql`
  subscription jobUpdateNotification($partitionKey: String!) {
    jobUpdateNotification(partitionKey: $partitionKey) {
      id
      version
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      tenantId
      hierarchy
      entityType
      jobNumber
      customIdentifier
      customerProvidedPONumber
      jobTypeName
      jobTypeInternal
      priority
      customerId
      customerName
      customerSortKey
      customerRepName
      customerPropertyId
      customerPropertyName
      customerPropertyTypeName
      customerPropertySortKey
      amountNotToExceed
      amountQuoted
      quoteId
      issueDescription
      bestContact
      parentId
      parentSortKey
      createdDateTime
      createdBy
      status
      departments(entityConnection: "JobDepartment") {
        items {
          id
          mappedEntityId
          mappedEntityType
          mappedEntityValue
          systemEntityId
          systemEntityType
          hierarchy
          sortKey
          invertedSortKey
          partitionKey
          mappedEntity {
            ... on Department {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              tagName
            }
          }
        }
      }
      notes(entityConnection: "Note") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          subject
          note
          createdBy
          lastUpdatedDate
          lastUpdatedDateTime
          lastUpdatedBy
          version
        }
      }
    }
  }
`;

export default jobUpdateNotification;
