import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import * as R from 'ramda';
import AddNote from 'components/Buttons/BlueText';
import { snackbarOn } from 'redux/actions/globalActions';
import styles from './styles';
import SectionHeader from 'components/SectionHeader';
import Note from './Note';
import ErrorBoundaries from 'scenes/Error';

class MultiNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notesData: props.notesData || []
    };
  }

  handleAddNote = () => {
    const newData = {
      note: ' ',
      entityType: 'Note',
      tenantId: this.props.user.tenantId,
      tenantCompanyId: this.props.user.tenantCompanyId
    };
    const dataList = this.state.notesData;
    const modifiedNotesData = R.insert(0, newData, dataList);
    this.setState({ notesData: modifiedNotesData });
  };

  handleDelete = record => {
    const localNotesData = this.state.notesData;
    const modifiedNoteData = [];
    localNotesData.forEach(note => {
      if (note.id && note.id !== record.id) {
        modifiedNoteData.push(note);
      }
    });
    this.setState({ notesData: modifiedNoteData });
  };

  render() {
    const { title, parent, relationName, mutationService } = this.props;
    const { notesData } = this.state;
    if (notesData && notesData.length === 0) {
      this.handleAddNote();
    } else if (notesData && notesData.length > 0 && notesData[0].lastUpdatedDateTime) {
      notesData.sort((left, right) => right.lastUpdatedDateTime - left.lastUpdatedDateTime);
    }
    return (
      <ErrorBoundaries>
        <SectionHeader title={title} />
        <Grid container alignItems="center" style={{ paddingLeft: 40 }}>
          <Grid item xs={10}>
            <AddNote label="Add note" icon={AddIcon} handle={() => this.handleAddNote()} />
          </Grid>
          {notesData.map(note => (
            <Note
              data={note}
              parent={parent}
              key={note.id}
              relationName={relationName}
              deleteHandle={this.handleDelete}
              mutationService={mutationService}
            />
          ))}
        </Grid>
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

// utilizes global spinner actions
const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const connectedMultiNotes = connect(mapStateToProps, mapDispatchToProps)(MultiNotes);

export default withStyles(styles, { withTheme: true })(connectedMultiNotes);
