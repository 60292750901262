import React from 'react';
import PropTypes from 'prop-types';
import { Button, MoreButton, ThemeProvider } from '@buildhero/sergeant';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import UserPermission from 'components/AppPermissions';

const CreateEntryButton = ({
  label,
  handleAdd,
  caslKey,
  style,
  moreOptions = [],
  startIcon,
  ...rest
}) => {
  return (
    <UserPermission I="create" action={caslKey} key="permAddRow">
      <ThemeProvider>
        <Button
          style={style || { float: 'right' }}
          onClick={() => {
            handleAdd();
          }}
          startIcon={startIcon !== undefined ? startIcon : <AddCircleOutlineIcon />}
          {...rest}
        >
          {label}
        </Button>
        {!!moreOptions.length && <MoreButton disabled={rest?.disabled} options={moreOptions} />}
      </ThemeProvider>
    </UserPermission>
  );
};

export default CreateEntryButton;

CreateEntryButton.propTypes = {
  caslKey: PropTypes.string.isRequired,
  moreOptions: PropTypes.arrayOf(PropTypes.object),
  handleAdd: PropTypes.func.isRequired,
  style: PropTypes.object,
  label: PropTypes.string.isRequired
};

CreateEntryButton.defaultProps = {
  moreOptions: [],
  style: undefined
};
