import compose from 'utils/compose';
import { withDispatchActions, withDispatchStore } from '@dispatch/Dispatch.store';
import CreateManDay from './CreateManDay.component';

const mapActionsToProps = ({ closeDrawer }) => ({
  closeDrawer
});

const mapDispatchToProps = store => ({
  eventType: store.state.eventType,
  eventData: store.state.eventData
});

export default compose(
  withDispatchActions(mapActionsToProps),
  withDispatchStore(mapDispatchToProps)
)(CreateManDay);
