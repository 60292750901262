import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';

import { WIP_REPORT_FRAGMENT } from '../fragments/wipReportFragment';

const GET_WIP_REPORT = gql`
  ${WIP_REPORT_FRAGMENT}
  query getWIPReport($id: number) {
    getWIPReport(id: $id)
      @rest(type: "WIPReport", path: "wipreports/{args.id}", endpoint: "pmapi") {
      ...WIPReportFragment
    }
  }
`;

export const useGetWIPReport = (wipReportId, options = {}) => {
  return useExtendedQuery(GET_WIP_REPORT, {
    variables: { id: wipReportId },
    transform: data => data?.getWIPReport,
    defaultData: {},
    ...options
  });
};
