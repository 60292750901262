import gql from 'graphql-tag';

const addCostCodes = gql`
  mutation addCostCodes($partitionKey: String!, $data: AddCostCodesToCompanyInput!) {
    addCostCodesToCompany(partitionKey: $partitionKey, data: $data) {
      id
      name
      description
      isActive
      version
    }
  }
`;

export default addCostCodes;
