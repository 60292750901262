import React from 'react';
import Moment from 'react-moment';
import Grid from '@material-ui/core/Grid';
import { Typography, TV, TW, ThemeProvider } from '@buildhero/sergeant';
import Linkify from 'react-linkify';
import AppConstants from 'utils/AppConstants';
import LinkButton from 'components/Buttons/BlueText';
import useEagerMultiNotes from 'customHooks/useEagerMultiNotes/useEagerMultiNotes';
import ViewableIcon from 'customHooks/useEagerMultiNotes/ViewableIcon';

const style = {
  inputLabel: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'normal',
    letterSpacing: '0.01px',
    lineHeight: '14px'
  },
  label: {
    fontSize: 10,
    letterSpacing: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal'
  },
  bigContent: {
    color: '#3f3f3f',
    fontSize: 14,
    letterSpacing: 0.24,
    fontWeight: 'normal',
    lineHeight: 'normal',
    paddingTop: 7,
    whiteSpace: 'pre-line',
    wordBreak: 'break-word'
  },
  footer: {
    fontSize: 12,
    letterSpacing: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    paddingTop: 7,
    paddingBottom: 7
  },
  subject: {
    fontSize: 16,
    paddingTop: 7,
    paddingBottom: 7
  },
  subjectContainer: {
    display: 'flex'
  },
  icon: {
    marginTop: 7
  }
};

const Note = ({ hasShareWithTechsOption, ...note }) => (
  <ThemeProvider>
    <Linkify>
      <Grid item xs={12} sm={12} md={10} lg={10} xl={9} css={style.subjectContainer}>
        <Typography variant={TV.PRINT} weight={TW.REGULAR} css={style.subject}>
          {note?.subject || ''}
        </Typography>
        {hasShareWithTechsOption && (
          <div css={style.icon}>
            <ViewableIcon viewable={!note?.hideFromTechniciansOnMobile} />
          </div>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={10} lg={10} xl={9}>
        <Typography variant={TV.S2} weight={TW.REGULAR} css={style.bigContent}>
          {note?.note || ''}
        </Typography>
      </Grid>
      {note?.lastUpdatedDateTime && (
        <Grid item xs={12} sm={12} md={10} lg={10} xl={9} css={style.footer}>
          <Typography css={style.label}>
            Last edited by {note.displayLastUpdatedBy || note.lastUpdatedBy} on{' '}
            <Moment unix format={AppConstants.DATETIME_FORMAT}>
              {note.displayLastUpdatedDateTime || note.lastUpdatedDateTime / 1000}
            </Moment>
          </Typography>
        </Grid>
      )}
    </Linkify>
  </ThemeProvider>
);

const Notes = ({
  title,
  notesData,
  showAllNotes,
  noDataMessage,
  linkName,
  parent,
  refetch,
  isActive = true,
  shareWithTechsOption = true
}) => {
  const notes = (showAllNotes ? notesData : notesData?.slice(0, 2)) || [];
  const notesCountText = notesData?.length ? `(${notesData.length})` : '';

  const hasShareWithTechsOption = shareWithTechsOption;
  const { MultiNoteModal, openMultiNoteModal } = useEagerMultiNotes(
    refetch,
    hasShareWithTechsOption
  );

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant={TV.S3} weight={TW.REGULAR} css={style.inputLabel}>
            {title}
          </Typography>
          {notes.length === 0 && (
            <Typography variant={TV.S3} weight={TW.REGULAR} css={style.label}>
              {noDataMessage}
            </Typography>
          )}

          {notes.map(note => (
            <Note key={note.id} hasShareWithTechsOption={hasShareWithTechsOption} {...note} />
          ))}
          <Grid item xs={12} sm={12} md={10} lg={10} xl={9}>
            <LinkButton
              label={
                isActive ? `${linkName} ${notesCountText}` : `View all notes ${notesCountText}`
              }
              style={{ paddingLeft: 0, textAlign: 'left' }}
              handle={() =>
                openMultiNoteModal({
                  name: parent.name,
                  id: parent.id,
                  entityType: parent.entityType,
                  notesCount: notesData?.length,
                  displayEntityType: parent.displayEntityType,
                  version: parent.version
                })
              }
            />
          </Grid>
        </Grid>
      </Grid>
      {MultiNoteModal}
    </>
  );
};

export default Notes;
