const transformUpdateCustomerSchema = (data, tenantId, tenantCompanyId, pageState) => {
  const { customerTypes, syncNow, customerData, formAttributes, customerTags } = pageState;
  let selectedCustomerType;
  if (customerTypes && customerTypes.items && customerTypes.items.length > 0) {
    selectedCustomerType = customerTypes.items.filter(
      item => item.tagName === data.customerTypeValue
    );
  }

  const customFields = {};
  if (data && formAttributes) {
    Object.keys(data).forEach(dataItem => {
      if (dataItem.startsWith('Custom_')) {
        customFields[dataItem.replace('Custom_', '')] = data[dataItem] || null;
      }
    });
    customFields.formId = formAttributes.formId;
    customFields.formSortKey = formAttributes.formSortKey;
    customFields.formDefinitionId = formAttributes.formDefinitionId;
    customFields.formDefinitionSortKey = formAttributes.formDefinitionSortKey;
    if (data.formDataId) {
      customFields.id = data.formDataId;
      customFields.version = data.formDataVersion;
    }
  }

  const payload = {
    companyId: tenantCompanyId,
    customers: [
      {
        id: customerData.id,
        version: customerData.version,
        customerName: data.customerName || null,
        accountNumber: data.accountNumber || null,
        customerTypeSortKey:
          (selectedCustomerType &&
            selectedCustomerType.length > 0 &&
            selectedCustomerType[0].sortKey) ||
          null,
        customerTypeValue: data.customerTypeValue || null,
        email: data.email || null,
        isActive: true,
        isTaxable: data.isTaxable === 'true' || data.isTaxable === true || false,
        sameAddress: data.sameAddress === 'true' || data.sameAddress === true || false,
        phonePrimary: data.phonePrimary || null,
        receiveSMS: data.receiveSMS || false,
        syncStatus: 'InSync',
        taxExemptIdValue: data.taxExemptIdValue || null,
        taxRateId: data.taxRateId || undefined,
        paymentTermId: data.paymentTermId || null,
        invoicePresetId: data.invoicePresetId || null,
        priceBookId: data.priceBookId || null
      }
    ]
  };
  // when the data is present, send else skip
  const companyAddresses = [];

  if (data.billingAddressLine1) {
    companyAddresses.push({
      billTo: data.billingBillTo || null,
      addressLine1: data.billingAddressLine1 || null,
      addressLine2: data.billingAddressLine2 || null,
      city: data.billingCity || null,
      state: data.billingState || null,
      zipcode: data.billingZipcode || null,
      latitude: data.billingLatitude || null,
      longitude: data.billingLongitude || null,
      id: data.billingId,
      version: data.billingVersion,
      status: 'active',
      addressType: 'billingAddress'
    });
  }

  if (data.businessAddressLine1) {
    companyAddresses.push({
      billTo: data.businessBillTo || null,
      shipTo: data.businessShipTo || null,
      addressLine1: data.businessAddressLine1 || null,
      addressLine2: data.businessAddressLine2 || null,
      city: data.businessCity || null,
      state: data.businessState || null,
      zipcode: data.businessZipcode || null,
      latitude: data.businessLatitude || null,
      longitude: data.businessLongitude || null,
      id: data.businessId,
      version: data.businessVersion,
      status: 'active',
      addressType: 'businessAddress'
    });
  }

  if (companyAddresses.length > 0) payload.customers[0].companyAddresses = companyAddresses;

  if (data.processedCustomerTags) {
    const selectedTags = ((customerTags || {}).items || []).filter(tag =>
      data.processedCustomerTags.includes(tag.id)
    );
    const updateTags = [];
    selectedTags.forEach(tag =>
      updateTags.push({
        id: tag.id,
        version: tag.version || null,
        sortKey: tag.sortKey || null,
        tagType: tag.tagType || null,
        tagName: tag.tagName || null,
        note: tag.note || null,
        color: tag.color || null,
        sortOrder: tag.sortOrder || null
      })
    );
    payload.customers[0].customerTags = updateTags;
  }
  const isSyncing = customerData && customerData.syncStatus === 'Syncing';
  if (syncNow && !isSyncing) {
    payload.customers[0].syncStatus = 'Syncing';
  } else {
    payload.customers[0].syncStatus = null;
  }

  if (data && formAttributes) {
    payload.customers[0].formData = customFields;
  }

  return payload;
};

export default transformUpdateCustomerSchema;
