import React, { useState, useMemo } from 'react';
import {
  Modal,
  SgtForm,
  Button,
  ButtonType,
  Typography,
  Table,
  FieldType
} from '@buildhero/sergeant';
import { Mode } from 'utils/constants';
import { SgtPriceBookEntrySearch as PriceBookEntrySearch } from 'components';
import AdjustmentItemConfig from './AdjustmentItem.config';
import GetValidationSchema from './AdjustmentItem.validation';

export default function AdjustmentItemModal({
  open,
  title,
  onClose,
  mode,
  data,
  handlePrimaryAction
}) {
  const [formService, setFormService] = useState();

  const configuration = useMemo(() => data && AdjustmentItemConfig(data), [data]);
  const validationSchema = useMemo(() => data && GetValidationSchema(data.accountingApp), [data]);

  if (!data) return null;
  return (
    <Modal
      open={open}
      title={title}
      actions={
        <Button
          type={mode === Mode.DELETE ? ButtonType.ERROR : undefined}
          onClick={
            mode === Mode.DELETE
              ? () => handlePrimaryAction(data)
              : formService?.formikContext?.handleSubmit
          }
          fullWidth
        >
          {mode === Mode.DELETE ? 'Delete' : 'Save'}
        </Button>
      }
      onClose={onClose}
      maxWidth={false}
    >
      {mode === Mode.DELETE ? (
        <>
          <Typography style={{ paddingBottom: 24 }}>
            Are you sure you want to remove this line item?
          </Typography>
          <Table
            columns={[
              { id: 'name', label: 'Item Name', width: '67.487685%' },
              {
                id: 'quantity',
                label: 'Quantity',
                type: FieldType.NUMBER,
                width: '12.807882%'
              },
              { id: 'amount', label: 'Subtotal', type: FieldType.CURRENCY, width: '20.689655%' }
            ]}
            data={[data]}
            style={{ minWidth: 325 }}
          />
        </>
      ) : (
        <SgtForm
          configuration={configuration}
          validationSchema={validationSchema}
          initialValues={data}
          onCreateService={setFormService}
          onSubmit={handlePrimaryAction}
          customComponents={{ PriceBookEntrySearch }}
          formikProps={{
            validateOnChange: false,
            validateOnBlur: true
          }}
        />
      )}
    </Modal>
  );
}
