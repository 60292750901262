import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
    fontFamily: 'Times-Roman'
  },
  bodySecond: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20
  },
  root: {
    width: '100%',
    height: '20%',
    padding: 5,
    borderWidth: 2,
    borderColor: 'black'
  },
  secondRoot: {
    width: '100%',
    height: '78%',
    marginTop: -2,
    padding: 5,
    borderWidth: 2,
    borderColor: 'black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  normal: {
    width: '100%',
    height: '97%',
    padding: 5,
    borderWidth: 2,
    borderColor: 'black'
  },
  secondInnerLeft: {
    width: '50%',
    height: '100%',
    paddingRight: 10
  },
  secondInnerRight: {
    width: '50%',
    height: '100%',
    paddingLeft: 10
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: 'Times-Bold'
  },
  text: {
    fontSize: 10
  },
  smallText: {
    fontSize: 8
  },
  paddingLeft10: {
    paddingLeft: 10
  },
  marginTop5: {
    marginTop: 5
  },
  marginTop10: {
    marginTop: 10
  },
  marginTop30: {
    marginTop: 30
  },
  // ApplicationCertification
  textwrap: {
    maxWidth: 130
  },
  displayFlexDirectionColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  fieldName: {
    fontSize: 10,
    width: 60,
    paddingRight: 10,
    textAlign: 'right'
  },
  longFieldName: {
    fontSize: 10,
    width: 100,
    paddingRight: 10,
    textAlign: 'right'
  },
  longestFieldName: {
    fontSize: 10,
    width: 120,
    paddingRight: 10,
    textAlign: 'right'
  },
  boxBorder: {
    fontSize: 10,
    width: 15,
    height: 12,
    textAlign: 'center',
    borderWidth: 1,
    borderColor: 'black',
    marginRight: 2,
    marginTop: -1
  },
  // ContractApplicationLeftside
  displayFlexSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  textAlignRight: {
    fontSize: 10,
    width: 130,
    paddingRight: 10,
    textAlign: 'right'
  },
  shortUnderline: {
    width: 30,
    paddingRight: 5,
    textAlign: 'right'
  },
  // ContractApplicationLeftside-2
  tableBorder: {
    borderWidth: 2,
    borderColor: 'black'
  },
  innerCell: {
    paddingTop: 2,
    paddingBottom: 2,
    borderBottom: 1,
    borderRight: 1,
    borderColor: 'black'
  },
  innerCellWithoutBorder: {
    paddingTop: 2,
    paddingBottom: 2
  },
  rightLastCell: {
    paddingTop: 2,
    paddingBottom: 2,
    borderBottom: 1,
    borderColor: 'black'
  },
  textCenter: {
    textAlign: 'center'
  },
  numberAlign: {
    textAlign: 'right',
    paddingRight: 5
  },
  // ContractApplicationRightside
  contractApplicationRightside: {
    paddingLeft: 3
  },
  displayFlex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  underline: {
    fontSize: 10,
    width: 130,
    borderBottom: 1,
    borderColor: 'black'
  },
  // verifierCertificate
  verifierCertificate: {
    paddingLeft: 3,
    borderTop: 2,
    borderColor: 'black'
  },
  borderDoubleTop: {
    paddingTop: 2,
    paddingBottom: 2,
    borderTopStyle: 'double',
    borderTop: 1,
    borderRight: 1,
    borderColor: 'black'
  },
  borderDoubleBottom: {
    paddingTop: 2,
    paddingBottom: 2,
    borderBottomStyle: 'double',
    borderBottom: 1,
    borderRight: 1,
    borderColor: 'black'
  }
});

export default styles;
