import { gql } from '@apollo/client/core';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import useTenantId from 'customHooks/useTenantId';

const GET_TECH_VISIT_COST = gql`
  query getTechVisitCost($partitionKey: String!, $visitId: String!) {
    getTechVisitCost(partitionKey: $partitionKey, visitId: $visitId) {
      approvalStatus
      approvedByList
      approvedDateList
      duration
      employeeId
      hourCost
      hourTypeId
      name
      reviewStatus
      reviewedByList
      reviewedDateList
      totalCost
      __typename
    }
  }
`;

export const useTechVisitCost = ({ visitId }) => {
  return useExtendedQuery(GET_TECH_VISIT_COST, {
    variables: {
      partitionKey: useTenantId(),
      visitId
    },
    defaultData: [],
    transform: data => data?.getTechVisitCost,
    skip: !visitId
  });
};
