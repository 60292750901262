import useExtendedMutation from 'customHooks/useExtendedMutation';
import { GET_REVIEW_REPORT_BY_ID } from 'scenes/Jobs/JobDetail/queries';
import ADD_FEE_TO_REVIEW_REPORT from 'services/core/graphql/review-report/mutations/AddFeesToReviewReport';

const serializer = ({ tenantId, reviewReportId, fee }) => ({
  variables: {
    partitionKey: tenantId,
    data: {
      reviewReportId,
      ...fee,
      taxable: fee.taxable === null ? false : fee.taxable
    }
  }
});

export const useAddReviewReportFee = reviewReportId => {
  return useExtendedMutation(ADD_FEE_TO_REVIEW_REPORT, {
    serializer,
    update: (cache, { data: { addFeeToReviewReport } }) => {
      const newFee = addFeeToReviewReport;
      if (!newFee) {
        return;
      }
      const cachedData = cache.readQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        }
      });
      cache.writeQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        },
        data: {
          getReviewReportById: {
            ...(cachedData?.getReviewReportById || {}),
            fees: {
              __typename: 'FeeConnection',
              items: [...(cachedData.getReviewReportById?.fees?.items || []), newFee]
            }
          }
        }
      });
    }
  });
};
