import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';
import theme from 'themes/BuildHeroTheme';

import FeatureFlags from 'utils/FeatureFlagConstants';

import { PaymentAccountTypeDisplay } from '../constants';

export const addPaymentToInvoiceRowsMeta = flags => [
  {
    id: 'invoiceNumber',
    filterKey: 'Invoice.invoiceNumber',
    filterType: 'number',
    label: 'Invoice',
    showLink: 'view',
    linkPath: '/invoice/view',
    linkReference: 'id',
    linkStateKey: 'recordSortKey',
    linkStateValue: 'sortKey',
    bold: true
  },
  {
    id: 'jobNumber',
    filterKey: 'Job.jobNumber',
    filterType: 'number',
    label: 'Job',
    type: 'jobLink'
  },
  {
    id: 'customerProvidedWONumber',
    filterKey: 'Invoice.customerProvidedWONumber',
    filterType: 'string',
    label: 'Customer WO#'
  },
  {
    id: 'createdDate',
    disableFilter: true,
    disableSort: true,
    filterKey: 'Invoice.createdDate',
    filterType: 'string',
    type: 'dateOnly',
    label: 'Invoice Date'
  },
  {
    id: 'dueDate',
    disableFilter: true,
    filterKey: 'Invoice.dueDate',
    filterType: 'date',
    type: 'dateOnly',
    label: 'Due date'
  },
  {
    id: 'totalAmount',
    filterKey: 'Invoice.totalAmount',
    filterType: 'currency',
    type: 'currency',
    label: 'Amount'
  },
  {
    id: 'balance',
    filterKey: 'Invoice.balance',
    filterType: 'currency',
    disableFilter: true,
    disableSort: true,
    type: 'balance',
    bold: true,
    label: 'Balance'
  },
  ...(flags?.[FeatureFlags.ADJUSTMENTS]
    ? [
        {
          id: 'adjustedBalance',
          disableFilter: true,
          disableSort: true,
          filterKey: 'Invoice.adjustedBalance',
          filterType: 'currency',
          numeric: true,
          type: 'balance',
          bold: true,
          label: 'Adjusted Balance'
        }
      ]
    : []),
  {
    id: 'amountToApply',
    disableFilter: true,
    disableSort: true,
    isCustom: true,
    type: 'applyPaymentToInvoice',
    label: 'Amount to Apply',
    headerStyle: {
      width: '20%',
      minWidth: 240
    }
  }
];

const PaymentDetailForm = ({
  paymentTypeOptions,
  bankAccounts,
  glAccounts,
  isIntacctEnabled,
  requiredFields,
  adjustmentsEnabled,
  isPaymentAppliedToInvoices
}) => ({
  fields: {
    paymentNumber: {
      default: 'paymentNumber',
      edit: 'paymentNumber'
    },
    billingCustomerName: {
      default: 'billingCustomerName',
      edit: 'billingCustomerName'
    },
    paymentStatus: {
      default: 'paymentStatus',
      edit: 'paymentStatus'
    },
    paymentType: {
      default: 'paymentType',
      edit: 'paymentType'
    },
    invoices: {
      default: 'appliedPaymentInvoices',
      edit: 'invoices',
      editForInvoice: 'invoices'
    },
    adjustments: {
      default: 'adjustmentTransactions'
    },
    paymentAmount: {
      default: 'paymentAmount',
      edit: 'paymentAmount'
    },
    paymentDate: {
      default: 'paymentDate',
      edit: 'paymentDate'
    },
    invoiceBalance: {
      editForInvoice: 'invoiceBalance'
    },
    accountTypeDisplay: {
      default: 'accountTypeDisplay'
    },
    bankAccountId: {
      default: 'bankAccountId'
    },
    glAccountId: {
      default: 'glAccountId'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        // Billing Customer
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'billingCustomerName',
                  options: {
                    testingid: 'billingCustomer',
                    label: 'Billing Customer',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Type
        {
          options: {
            size: 4,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'paymentTypeName',
                  options: {
                    testingid: 'paymentType',
                    label: 'Payment Type',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Account Information
        isIntacctEnabled && {
          options: {
            size: 4,
            direction: 'row',
            marginLeft: 10,
            grow: 0
          },
          contents: [
            {
              component: 'RadioButtonsGroup',
              source: 'accountTypeDisplay',
              options: {
                btnDirection: 'Horizontal',
                inputOptions: Object.values(PaymentAccountTypeDisplay).join(', '),
                disabled: true,
                // label styles are array because it gets stripped when forms are processed for finding contents []
                labelStyle: [{ fontSize: 14, color: theme.palette.grayscale(20) }]
              }
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        isIntacctEnabled && {
          options: {
            size: 4,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'bankAccountName',
                  options: {
                    label: 'Bank Account',
                    fullWidth: true,
                    alignment: 'left'
                  },
                  dependentField: {
                    fieldName: 'accountTypeDisplay',
                    expectedValue: PaymentAccountTypeDisplay.BANK_ACCOUNT,
                    operation: 'notEqual',
                    action: 'HIDE'
                  }
                },
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'glAccountName',
                  options: {
                    testingid: 'GL_account',
                    label: 'GL Account',
                    fullWidth: true,
                    alignment: 'left'
                  },
                  dependentField: {
                    fieldName: 'accountTypeDisplay',
                    expectedValue: PaymentAccountTypeDisplay.UNDEPOSITED_FUNDS_ACCOUNT,
                    operation: 'notEqual',
                    action: 'HIDE'
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Amount
        {
          options: {
            size: 4,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'paymentAmount',
                  options: {
                    testingid: 'amount',
                    label: 'Amount',
                    type: 'currency',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'availableAmount',
                  options: {
                    testingid: 'amountAvailable',
                    label: 'Amount Available',
                    type: 'currency',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Date
        {
          options: {
            size: 4,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'paymentDate',
                  options: {
                    label: 'Date',
                    placeholder: '',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left',
                    searchableCategory: 'date',
                    testingid: 'Date',
                    inputPropsArr: {
                      testingid: 'date-payment'
                    }
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Invoices (appliedPaymentInvoices)
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'ChipsList'
                  },
                  source: 'invoices',
                  options: {
                    testingid: 'invoices-payment',
                    label: 'Invoices'
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Adjustments
        adjustmentsEnabled && {
          options: {
            size: 2,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'ChipsList'
                  },
                  source: 'adjustments',
                  options: {
                    label: 'Adjustments'
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        }
      ].filter(Boolean)
    },
    edit: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        // Payment Number
        {
          options: {
            size: 4,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: null
                  },
                  source: 'paymentNumber',
                  options: {
                    testingid: 'paymentNumber',
                    label: 'Payment Number',
                    type: 'numeric',
                    placeholder: '',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Billing Customer
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: { default: 'BillingCustomerSelect' },
                  source: 'billingCustomerName',
                  options: {
                    testingid: 'billingCustomer',
                    label: 'Billing Customer',
                    placeholder: '',
                    alignment: 'left',
                    valuesToSet: [
                      {
                        billingCustomerName: 'customerName',
                        billingCustomerId: 'id'
                      }
                    ],
                    required: true,
                    disabled: isPaymentAppliedToInvoices,
                    searchIndexName: defaultSearchIndex,
                    searchResultDisplayFields: ['customerName'],
                    filters: [
                      {
                        attribute: 'entityType',
                        valueArray: ['Customer']
                      }
                    ]
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Type
        {
          options: {
            size: 4,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'SelectInput'
                  },
                  source: 'paymentType',
                  options: {
                    testingid: 'paymentType',
                    label: 'Payment Type',
                    placeholder: 'Select...',
                    valueSet: paymentTypeOptions,
                    isRequired: true
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Account Information
        isIntacctEnabled && {
          options: {
            size: 4,
            direction: 'row',
            marginLeft: 10,
            grow: 0
          },
          contents: [
            {
              component: 'RadioButtonsGroup',
              source: 'accountTypeDisplay',
              options: {
                btnDirection: 'Horizontal',
                inputOptions: Object.values(PaymentAccountTypeDisplay).join(', '),
                isRequired: true,
                // label styles are array because it gets stripped when forms are processed for finding contents []
                labelStyle: [{ fontSize: 14, color: theme.palette.grayscale(20) }]
              }
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        isIntacctEnabled && {
          options: {
            size: 4,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'SelectInput'
                  },
                  source: 'bankAccountId',
                  options: {
                    label: 'Bank Account',
                    placeholder: 'Select...',
                    valueSet: bankAccounts,
                    isRequired: true,
                    isSearchable: true
                  },
                  dependentField: {
                    fieldName: 'accountTypeDisplay',
                    expectedValue: PaymentAccountTypeDisplay.BANK_ACCOUNT,
                    operation: 'notEqual',
                    action: 'HIDE'
                  }
                },
                {
                  component: {
                    edit: 'SelectInput'
                  },
                  source: 'glAccountId',
                  options: {
                    testingid: 'GL_account',
                    label: 'GL Account',
                    placeholder: 'Select...',
                    valueSet: glAccounts,
                    isRequired: true,
                    isSearchable: true
                  },
                  dependentField: {
                    fieldName: 'accountTypeDisplay',
                    expectedValue: PaymentAccountTypeDisplay.UNDEPOSITED_FUNDS_ACCOUNT,
                    operation: 'notEqual',
                    action: 'HIDE'
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Amount
        {
          options: {
            size: 4,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'CurrencyInput'
                  },
                  source: 'paymentAmount',
                  options: {
                    testingid: 'paymentAmount',
                    label: 'Amount',
                    placeholder: '',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'availableAmount',
                  options: {
                    testingid: 'amountAvailable',
                    label: 'Amount Available',
                    type: 'currency',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Date
        {
          options: {
            size: 4,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'DateInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'paymentDate',
                  options: {
                    label: 'Date',
                    placeholder: '',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left',
                    searchableCategory: 'date',
                    inputPropsArr: {
                      testingid: 'date-payment'
                    },
                    testingid: 'Date'
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Invoices
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'InvoiceApplicationTable'
                  },
                  source: 'invoices',
                  options: {
                    testingid: 'invoices-payment',
                    label: 'Invoices'
                  }
                }
              ]
            }
          ]
        }
      ].filter(Boolean)
    },
    editForInvoice: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        // Payment Number
        {
          options: {
            size: 4,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    editForInvoice: 'TextInput',
                    default: null
                  },
                  source: 'paymentNumber',
                  options: {
                    testingid: 'paymentNumber',
                    label: 'Payment Number',
                    type: 'numeric',
                    placeholder: '',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Billing Customer
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: 'FieldWithLabel',
                  source: 'billingCustomerName',
                  options: {
                    testingid: 'billingCustomer',
                    label: 'Billing Customer',
                    alignment: 'left',
                    required: true
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Type
        {
          options: {
            size: 4,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    editForInvoice: 'SelectInput'
                  },
                  source: 'paymentType',
                  options: {
                    testingid: 'paymentType',
                    label: 'Payment Type',
                    placeholder: 'Select...',
                    valueSet: paymentTypeOptions,
                    isRequired: true
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Account Information
        isIntacctEnabled && {
          options: {
            size: 4,
            direction: 'row',
            marginLeft: 10,
            grow: 0
          },
          contents: [
            {
              component: 'RadioButtonsGroup',
              source: 'accountTypeDisplay',
              options: {
                btnDirection: 'Horizontal',
                inputOptions: Object.values(PaymentAccountTypeDisplay).join(', '),
                isRequired: true,
                // label styles are array because it gets stripped when forms are processed for finding contents []
                labelStyle: [{ fontSize: 14, color: theme.palette.grayscale(20) }]
              }
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        isIntacctEnabled && {
          options: {
            size: 4,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    editForInvoice: 'SelectInput'
                  },
                  source: 'bankAccountId',
                  options: {
                    label: 'Bank Account',
                    placeholder: 'Select...',
                    valueSet: bankAccounts,
                    isRequired: true,
                    isSearchable: true
                  },
                  dependentField: {
                    fieldName: 'accountTypeDisplay',
                    expectedValue: PaymentAccountTypeDisplay.BANK_ACCOUNT,
                    operation: 'notEqual',
                    action: 'HIDE'
                  }
                },
                {
                  component: {
                    editForInvoice: 'SelectInput'
                  },
                  source: 'glAccountId',
                  options: {
                    label: 'GL Account',
                    placeholder: 'Select...',
                    valueSet: glAccounts,
                    isRequired: true,
                    isSearchable: true
                  },
                  dependentField: {
                    fieldName: 'accountTypeDisplay',
                    expectedValue: PaymentAccountTypeDisplay.UNDEPOSITED_FUNDS_ACCOUNT,
                    operation: 'notEqual',
                    action: 'HIDE'
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Amount
        {
          options: {
            size: 4,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    editForInvoice: 'CurrencyInput'
                  },
                  source: 'paymentAmount',
                  options: {
                    label: 'Amount',
                    placeholder: '',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  source: 'invoiceBalance',
                  options: {
                    label: 'Outstanding Balance',
                    type: 'currency',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Date
        {
          options: {
            size: 4,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    editForInvoice: 'DateInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'paymentDate',
                  options: {
                    testingid: 'Date',
                    label: 'Date',
                    placeholder: '',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left',
                    searchableCategory: 'date',
                    inputPropsArr: {
                      testingid: 'date-payment'
                    }
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Invoices
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'InvoiceApplicationTable'
                  },
                  source: 'invoices',
                  options: {
                    label: 'Invoices'
                  }
                }
              ]
            }
          ]
        }
      ].filter(Boolean)
    }
  },
  validation: {
    type: 'object',
    properties: {
      paymentNumber: {
        type: 'number'
      },
      billingCustomerName: {
        type: 'string'
      },
      paymentType: {
        type: 'string'
      },
      paymentAmount: {
        type: 'number'
      },
      paymentDate: {
        type: 'number'
      },
      accountTypeDisplay: {
        type: 'string'
      },
      bankAccountId: {
        type: 'string',
        nullable: true
      },
      glAccountId: {
        type: 'string',
        nullable: true
      }
    },
    required: [
      'paymentNumber',
      'billingCustomerName',
      'paymentType',
      'paymentAmount',
      'paymentDate'
    ].concat(requiredFields || [])
  },
  validationErrors: {
    paymentNumber: {
      required: 'Field is required.'
    },
    billingCustomerName: {
      required: 'Field is required.'
    },
    paymentType: {
      required: 'Field is required.'
    },
    paymentAmount: {
      required: 'Field is required.'
    },
    paymentDate: {
      format: 'Must be a valid date.',
      required: 'Field is required.'
    },
    accountTypeDisplay: {
      required: 'Field is required.'
    },
    bankAccountId: {
      required: 'Field is required.'
    },
    glAccountId: {
      required: 'Field is required.'
    }
  }
});

export default PaymentDetailForm;
