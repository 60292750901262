import React from 'react';
import { useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Divider, ThemeProvider } from '@buildhero/sergeant';
import ErrorBoundary from 'scenes/Error';
import { PermissionConstants } from 'utils/AppConstants';
import { UserPermission, PageHeader } from 'components';

import { IntegrationsList } from './components';

const Integrations = () => {
  const user = useSelector(state => state.user);
  const { spacing } = useTheme();

  return (
    <UserPermission I="read" action={PermissionConstants.MENU_INTEGRATIONS}>
      <ErrorBoundary>
        <PageHeader pageMapKey="integrations" userLocale={user.locale} />
        <ThemeProvider>
          <Divider marginLeft={-spacing(3)} width={`calc(100% + ${spacing(6)}px)`} />
          <IntegrationsList />
        </ThemeProvider>
      </ErrorBoundary>
    </UserPermission>
  );
};

export default Integrations;
