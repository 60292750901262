import React from 'react';
import { func, string } from 'prop-types';
import { Button, TV, Typography, TypographyWeight } from '@buildhero/sergeant';

import { useReviewReportDisabled } from '../ReviewReport/ReviewReport.contexts';

import { useStyles } from './SectionHeader.styles';

const SectionHeader = ({ title, subTitle, buttonText, onButtonClick }) => {
  const styles = useStyles();
  const { disabled } = useReviewReportDisabled();

  return (
    <div css={styles.header}>
      <div>
        <Typography variant={TV.L} weight={TypographyWeight.BOLD} css={styles.title}>
          {title}
        </Typography>
        {subTitle && <Typography>{subTitle}</Typography>}
      </div>
      {onButtonClick && buttonText && (
        <div css={styles.button}>
          <Button disabled={disabled} type="secondary" size="small" onClick={onButtonClick}>
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};

SectionHeader.propTypes = {
  title: string.isRequired,
  subTitle: string,
  buttonText: string.isRequired,
  onButtonClick: func.isRequired
};

SectionHeader.defaultProps = {
  subTitle: undefined
};

export default SectionHeader;
