import { checkPermission } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';
import { searchIndex } from 'constants/algoliaIndex';

const padding = 8;

export const NewJobLayout = options => {
  const {
    accelerateVisitProcess,
    customerPropertyId,
    customerReps,
    customInlineForm,
    customJobNumber,
    departments,
    differentBillingCustomer,
    disableCustomJobNumber,
    employeeOptions,
    handleOpenCustomerRepPopUp,
    hasMultiplePricebooks,
    hasServiceAgreements,
    isSageEnabled,
    isSpectrumEnabled,
    isVistaEnabled,
    jobTypes,
    priceBooks,
    priorities,
    properties,
    sageJobs,
    salesEmployees,
    ourReps,
    serviceAgreementsForProperty,
    setPriceBookIdFromSA,
    showCertifiedPayroll,
    forceCertifiedPayrollJobs,
    showTotalBudgetedHours,
    user
  } = options;

  const isDepartmentRequired = isVistaEnabled || isSpectrumEnabled;

  return {
    fields: customInlineForm?.fields
      ? {
          ...customInlineForm.fields
        }
      : {},
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER'
        },
        contents: [
          {
            options: {
              padding
            },
            contents: [
              {
                component: 'Subtitle',
                source: 'General Information',
                options: {
                  fontWeight: 700,
                  marginBotton: 0
                }
              }
            ]
          },
          {
            options: {
              direction: 'row'
            },
            contents: [
              {
                options: {
                  grow: 1,
                  padding
                },
                contents: [
                  {
                    component: 'AlgoliaSearchWrapper',
                    source: 'customerName',
                    options: {
                      label: 'Customer',
                      placeholder: 'Search customers',
                      searchIndexName: searchIndex,
                      searchResultDisplayFields: ['customerName'],
                      isRequired: true,
                      filters: [
                        {
                          attribute: 'entityType',
                          valueArray: ['Customer']
                        }
                      ],
                      valuesToSet: [
                        {
                          customerName: 'customerName',
                          customerId: 'id'
                        }
                      ]
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 1,
                  padding
                },
                contents: [
                  {
                    component: 'SelectInput',
                    source: 'customerPropertyId',
                    options: {
                      label: 'Property Name',
                      valueSet: properties,
                      isSearchable: true,
                      isRequired: true,
                      isClearable: false
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 1,
                  padding
                },
                contents: [
                  {
                    component: 'SelectInput',
                    source: 'customerRepId',
                    options: {
                      creatable: true,
                      onCreate: handleOpenCustomerRepPopUp,
                      label: 'Property Rep',
                      isSearchable: true,
                      valueSet:
                        (!customerPropertyId &&
                          properties.length &&
                          properties[0]?.customerReps?.map(item => ({
                            label: item.name,
                            value: item.id
                          }))) ||
                        properties
                          .find(p => p.value === customerPropertyId)
                          ?.customerReps?.map(item => ({
                            label: item.name,
                            value: item.id
                          })) ||
                        []
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              direction: 'row'
            },
            contents: [
              {
                options: {
                  grow: 1.4,
                  padding
                },
                contents: [
                  {
                    component: 'AlgoliaSearchWrapper',
                    source: 'billingCustomerName',
                    options: {
                      label: 'Billing Customer',
                      placeholder: 'Search customers',
                      disabled: !differentBillingCustomer,
                      searchIndexName: searchIndex,
                      searchResultDisplayFields: ['customerName'],
                      isRequired: true,
                      filters: [
                        {
                          attribute: 'entityType',
                          valueArray: ['Customer']
                        }
                      ],
                      valuesToSet: [
                        {
                          billingCustomerName: 'customerName',
                          billingCustomerId: 'id'
                        }
                      ]
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 1.2,
                  padding
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'differentBillingCustomer',
                    options: {
                      label: 'Billing Customer is different than the Customer requesting work'
                    },
                    dependentField: {
                      fieldName: 'billingCustomerFromProperty',
                      expectedValue: true,
                      operation: 'bool',
                      action: 'DISABLE'
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 0.8,
                  padding
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'billingCustomerFromProperty',
                    options: {
                      label: 'Billing Customer from property'
                    },
                    dependentField: {
                      fieldName: 'differentBillingCustomer',
                      expectedValue: true,
                      operation: 'bool',
                      action: 'DISABLE'
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 0.7,
                  padding
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'detailedJobCostingEnabled',
                    options: {
                      label: 'Enable Detailed Job Costing'
                    }
                  }
                ]
              },
              showCertifiedPayroll && {
                options: {
                  grow: 0.7,
                  padding
                },
                contents: [
                  {
                    component: forceCertifiedPayrollJobs
                      ? 'ReadOnlyCheckboxInput'
                      : 'CheckboxInput',
                    source: 'certifiedPayroll',
                    options: {
                      label: 'Enable Certified Payroll'
                    }
                  }
                ]
              }
            ].filter(Boolean)
          },
          {
            options: {
              direction: 'row'
            },
            contents: [
              {
                options: {
                  grow: 1,
                  padding
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'customerProvidedPONumber',
                    options: {
                      label: 'Customer provided PO #'
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 1,
                  padding,
                  hide: !hasServiceAgreements
                },
                contents: [
                  {
                    component: 'SelectInput',
                    source: 'serviceAgreementId',
                    options: {
                      label: 'Service Agreement',
                      isSearchable: true,
                      valueSet: serviceAgreementsForProperty,
                      onChange: setPriceBookIdFromSA
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 1,
                  padding,
                  hide: !hasMultiplePricebooks
                },
                contents: [
                  {
                    component: 'SelectInput',
                    source: 'priceBookId',
                    options: {
                      label: 'Pricebook',
                      isSearchable: true,
                      valueSet: priceBooks
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 1,
                  padding,
                  hide: !isSageEnabled
                },
                contents: [
                  {
                    component: 'SelectInput',
                    source: 'sageJobId',
                    options: {
                      label: 'Sage Job',
                      isSearchable: true,
                      valueSet: sageJobs
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 1,
                  padding,
                  hide: !isSpectrumEnabled
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'accountingJobNumber',
                    options: {
                      label: 'Spectrum Job'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              direction: 'row'
            },
            contents: [
              {
                options: {
                  grow: 1,
                  padding
                },
                contents: [
                  {
                    component: 'SelectInput',
                    source: 'jobTypeId',
                    options: {
                      label: 'Job Type',
                      isSearchable: true,
                      valueSet: jobTypes,
                      isClearable: false,
                      enableSort: false
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 1,
                  padding,
                  hide: !customJobNumber
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'customIdentifier',
                    options: {
                      label: 'Job Number',
                      disabled: disableCustomJobNumber
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 1,
                  padding
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'customerProvidedWONumber',
                    options: {
                      label: 'Customer WO #'
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 1,
                  padding
                },
                contents: [
                  {
                    component: 'SelectInput',
                    source: 'authorizedById',
                    options: {
                      creatable: true,
                      onCreate: handleOpenCustomerRepPopUp,
                      label: 'Authorized By',
                      isSearchable: true,
                      valueSet: customerReps
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              direction: 'row'
            },
            contents: [
              {
                options: {
                  grow: 1,
                  padding,
                  hide: !checkPermission('allow', PermissionConstants.DATA_VIEW_NTE_DATA, user)
                },
                contents: [
                  {
                    component: 'CurrencyInput',
                    source: 'amountNotToExceed',
                    options: {
                      label: 'NTE'
                    }
                  }
                ]
              },

              {
                options: {
                  grow: 1,
                  padding,
                  hide: !checkPermission(
                    'allow',
                    PermissionConstants.DATA_SHOW_FINANCIAL_DATA,
                    user
                  )
                },
                contents: [
                  {
                    component: 'CurrencyInput',
                    source: 'amountQuoted',
                    options: {
                      label: 'Sales Amount'
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 1,
                  padding,
                  hide: !checkPermission(
                    'allow',
                    PermissionConstants.DATA_SHOW_FINANCIAL_DATA,
                    user
                  )
                },
                contents: [
                  {
                    component: 'CurrencyInput',
                    source: 'costAmount',
                    options: {
                      label: 'Cost Amount'
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 1,
                  padding,
                  hide: !showTotalBudgetedHours
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'totalBudgetedHours',
                    options: {
                      label: 'Total Budgeted Hours',
                      type: 'number',
                      min: 1,
                      step: '.01'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              direction: 'row'
            },
            contents: [
              {
                options: {
                  grow: 1,
                  padding
                },
                contents: [
                  {
                    component: 'SelectInput',
                    source: 'ownerId',
                    options: {
                      label: 'Project Manager',
                      isSearchable: true,
                      valueSet: salesEmployees
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 1,
                  padding
                },
                contents: [
                  {
                    component: 'SelectInput',
                    source: 'accountManagerId',
                    options: {
                      label: 'Account Manager',
                      isSearchable: true,
                      valueSet: ourReps
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 1,
                  padding
                },
                contents: [
                  {
                    component: 'SelectInput',
                    source: 'soldById',
                    options: {
                      label: 'Sold By',
                      isSearchable: true,
                      valueSet: employeeOptions
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              direction: 'row'
            },
            contents: [
              {
                options: {
                  grow: 1,
                  padding
                },
                contents: [
                  {
                    component: 'SelectInput',
                    source: 'departmentsAssigned',
                    options: {
                      label: 'Departments Needed',
                      isSearchable: true,
                      valueSet: departments,
                      isMultipleSelection: true,
                      isRequired: isDepartmentRequired
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 1,
                  padding
                },
                contents: [
                  {
                    component: 'SelectInput',
                    source: 'priority',
                    options: {
                      label: 'Priority',
                      isSearchable: true,
                      valueSet: priorities
                    }
                  }
                ]
              },
              {
                options: {
                  grow: 1,
                  padding
                },
                contents: []
              }
            ]
          },
          {
            options: {
              direction: 'row'
            },
            contents: [
              {
                options: {
                  grow: 1,
                  padding
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'issueDescription',
                    options: {
                      label: 'Issue description',
                      lines: 3
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              margin: -4,
              hide: !customInlineForm
            },
            contents: [...(customInlineForm?.componentArr || [])]
          },
          {
            options: {
              direction: 'row',
              hide: !accelerateVisitProcess
            },
            contents: [
              {
                options: {
                  grow: 1,
                  padding
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'createFirstVisit',
                    options: {
                      label: 'Create visit #1 along with job: '
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    },
    validation: {
      type: 'object',
      properties: {
        customerId: {
          type: 'string'
        },
        customerPropertyId: {
          type: 'string'
        },
        departmentsAssigned: {
          type: 'string'
        },
        ...(customInlineForm?.validation?.properties || {})
      },
      required: ['customerId', 'customerPropertyId']
        .concat(isDepartmentRequired ? ['departmentsAssigned'] : [])
        .concat(customInlineForm?.validation?.required || [])
    },
    validationErrors: {
      ...(customInlineForm?.validationErrors || {}),
      customerId: {
        required: 'Field is required.'
      },
      customerPropertyId: {
        required: 'Field is required.'
      },
      departmentsAssigned: {
        required: 'Field is required.'
      }
    }
  };
};
