import { sortBy } from 'lodash';

function formatMaterialItemsForDisplay({
  HTML,
  SettingConstants,
  taskMaterialsHeader,
  materialTaskItems,
  settingsJSON,
  styles,
  fontFamilyStyle
}) {
  const materialItemsToDisplay = taskMaterialsHeader
    ? sortBy(materialTaskItems, 'sortOrder')
        .map(
          item => `<tr>
                  ${HTML.itemizedElements({
                    styles,
                    fontFamilyStyle,
                    description: item.description || item.name || '',
                    shouldDisplayItem:
                      settingsJSON.Tasks.Material[SettingConstants.SHOW_ITEMIZED_MATERIAL_ITEMS]
                  })}
                  ${HTML.itemizedQuantity({
                    styles,
                    fontFamilyStyle,
                    quantity: item.quantity,
                    shouldDisplayQuantity:
                      settingsJSON.Tasks.Material[SettingConstants.SHOW_ITEMIZED_MATERIAL_QUANTITY]
                  })}
                  ${HTML.itemizedPricing(
                    item,
                    settingsJSON.Tasks.Material[
                      SettingConstants.SHOW_ITEMIZED_MATERIAL_ITEM_PRICING
                    ],
                    styles,
                    fontFamilyStyle
                  )}
                </tr>`
        )
        .join('')
    : '';

  return materialItemsToDisplay;
}

export default formatMaterialItemsForDisplay;
