const styles = theme => ({
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 10,
    letterSpacing: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal'
  },
  footer: {
    fontFamily: theme.typography.fontFamily,
    color: '#959595',
    fontSize: 12,
    letterSpacing: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal'
    //  paddingTop: 7
  },
  content: {
    fontFamily: theme.typography.fontFamily,
    color: '#3f3f3f',
    fontSize: 14,
    letterSpacing: 0.24,
    fontWeight: 'normal',
    lineHeight: 'normal',
    paddingTop: 24
  },
  iconStyle: {
    fontSize: 20,
    color: '#646464'
  },
  iconButton: {
    paddingTop: 24
  },
  noteHeading: {
    color: '#3f3f3f',
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.1,
    marginTop: 32,
    marginBottom: 15
  },
  noteSubHeading: {
    color: '#3f3f3f',
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.1,
    marginTop: 25,
    marginBottom: 5
  }
});

export default styles;
