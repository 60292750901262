import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { useSnackbar } from 'customHooks/useSnackbar';
import { logErrorWithCallback } from 'utils';

import { timesheetPeriodBindersFragment } from '../gql';

export const getTimesheetPeriodsOfStatusForEmployee = gql`
  query getTimesheetPeriodsOfStatusForEmployee($employeeId: String!, $manualStatus: String!) {
    getTimesheetPeriodsOfStatusForEmployee(employeeId: $employeeId, manualStatus: $manualStatus) {
      ${timesheetPeriodBindersFragment}
    }
  }
`;

const useTimesheetPeriodsOfStatus = ({ employeeId, manualStatus }, options = {}) => {
  const snackbar = useSnackbar();

  const { data, loading, error, refetch } = useExtendedQuery(
    getTimesheetPeriodsOfStatusForEmployee,
    {
      variables: { employeeId, manualStatus },
      transform: result => result?.getTimesheetPeriodsOfStatusForEmployee,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: !employeeId,
      ...options
    }
  );

  if (error) {
    logErrorWithCallback(error, snackbar, `Unable to fetch timesheet periods of status ${status}`);
  }
  return [data || {}, loading, error, refetch];
};

export default useTimesheetPeriodsOfStatus;
