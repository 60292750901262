const layout = (quoteAttachments, isCustomJobNumberEnabled) => ({
  fields: {},
  layouts: {
    default: {
      contents: [
        isCustomJobNumberEnabled && {
          props: {
            style: {
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }
          },
          contents: [
            {
              component: 'Input',
              props: {
                label: 'Job Number'
              },
              source: 'jobNumber'
            }
          ]
        },
        quoteAttachments.items.length && {
          props: {
            style: {
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingTop: 24
            }
          },
          contents: [
            {
              component: 'CustomCheckbox',
              props: {
                label: 'Select All',
                value: 'selectAll'
              },
              source: 'selectAll'
            },
            {
              component: 'Typography',
              props: { value: 'Share with Techs on Mobile' }
            }
          ]
        },
        quoteAttachments.items.length && {
          contents: [{ component: 'Divider' }]
        },
        ...Object.values(quoteAttachments.items).map((item, i) => ({
          props: {
            style: {
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }
          },
          contents: [
            {
              component: 'CustomCheckbox',
              props: {
                label: item.customFileName || item.fileName,
                value: item.id
              },
              source: `attachments[${i}]`
            },
            {
              component: 'Typography',
              props: { value: item.shareWithTechniciansOnMobile }
            }
          ]
        }))
      ]
    }
  }
});

export default layout;
