import React, { Fragment, useCallback, useMemo, useState } from 'react';

import { Button, ButtonType, MoreButton, ThemeProvider, TV, TW } from '@buildhero/sergeant';
import { Box, Divider, Grid, Menu, MenuItem, Typography, useTheme } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { uniq } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import InvoicesIcon from 'assets/Icons/Invoices';
import { PageHeader, StatusChip, TagButtons } from 'components';
import { useSnackbar } from 'customHooks/useSnackbar';
import ErrorBoundaries from 'scenes/Error';
import useUpdateJob from 'scenes/JobCloseout/JobCloseoutHeader/hooks/useUpdateJob';
import {
  generateProcurementStatusTooltip,
  generateQuoteStatusTooltip,
  getJobCloseoutType,
  getProcurementStatus,
  getQuoteStatus
} from 'scenes/JobCloseout/utils';
import { formatExistingDepartmentsForUpdate } from 'scenes/Jobs/DetailView/formattingUtils';
import { JobService } from 'services/core';
import { Logger } from 'services/Logger';
import {
  JOB_CLOSEOUT_STATUS,
  JobBillingStatus,
  JobProcurementStatus,
  jobQuoteStatus,
  JobTypes,
  TagType
} from 'utils/AppConstants';

import { EnumType } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { DEFAULT_PRESET } from '../../Jobs/DetailView/JobTabs/InvoiceAndReport';
import { generateJobReportPDFs } from '../../Jobs/DetailView/JobTabs/InvoiceAndReport/exportJobReportService';
import { JobCloseoutTypes } from '../constants';

import QuotedAmountJobCreateInvoiceModal from './CreateInvoice/components/QuotedAmountJobCreateInvoiceModal';
import QuotedJobCreateInvoiceModal from './CreateInvoice/components/QuotedJobCreateInvoiceModal';
import TimeAndMaterialsJobCreateModal from './CreateInvoice/components/TimeAndMaterialsJobCreateModal';
import useHasNonBilledItems from './hooks/useHasNonBilledItems';
import { useJobStatusHelper } from './hooks/useJobStatusHelper';
import useMarkDoNotInvoice from './hooks/useMarkDoNotInvoice';
import useMarkJobReadyForInvoicing from './hooks/useMarkReadyForInvoicing';
import JobBillingStatusChip from './JobBillingStatusChip';
import { StatusButtons } from './StatusButtons';

const getStyles = theme => ({
  breadcrumbStyle: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.grayscale(20)
  },
  breadcrumbSlashStyle: {
    color: theme.palette.brand.logoGreen,
    fontWeight: 700,
    marginLeft: 5,
    marginRight: 5
  },
  headerContainer: {
    marginTop: theme.spacing(1)
  },
  icons: {
    color: theme.palette.brand.logoGreen
  },
  pageTitle: {
    fontWeight: 'bold',
    marginRight: theme.spacing(2)
  },
  pageTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(1)
    },
    justifyContent: 'flex-start'
  },
  pageTitleContainerEnd: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(1)
    },
    justifyContent: 'flex-end'
  },
  popoverMenu: {
    '& .MuiPaper-root': {
      marginTop: theme.spacing(1),
      boxShadow: '0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.24)'
    }
  }
});

const chipStyle = { borderRadius: 2, height: 24 };

const JobCloseoutHeader = props => {
  const { jobData, refetchJob, visits, jobCloseoutTypeFromUrl } = props;
  const theme = useTheme();
  const snackbar = useSnackbar();
  const user = useSelector(s => s.user);

  const [markDoNotInvoice] = useMarkDoNotInvoice();
  const [markJobReadyForInvoicing] = useMarkJobReadyForInvoicing();
  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);
  const [updateJob] = useUpdateJob();
  const styles = getStyles(theme);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const hasNotBilledItems = useHasNonBilledItems(jobData);
  const [jobStatusService, jobStatusActions, actionButtons] = useJobStatusHelper({
    job: jobData,
    visits: jobData?.visits?.items
  });

  const jobStatusOptions = useMemo(
    () =>
      actionButtons &&
      uniq(
        Object.values(actionButtons)
          ?.map(btn => jobStatusActions.includes(btn.actionConstant) && btn.targetStatus)
          ?.filter(Boolean)
      ),
    [actionButtons, jobStatusActions]
  );

  const deriveJobStatus = async statusObj => {
    const actionButton = Object.values(actionButtons).find(
      btn => btn.targetStatus === statusObj.status
    );

    let currentState;
    try {
      currentState = await jobStatusService.send(actionButton.actionConstant);
    } catch (e) {
      Logger.error(e);
      snackbar('warning', actionButton.errorMessage);
    }
    return currentState?.value;
  };

  const flags = useFlags();

  const jobCloseoutType = useMemo(() => getJobCloseoutType(jobData, jobCloseoutTypeFromUrl), [
    jobData
  ]);
  const entityDisplayName =
    jobCloseoutType === JobCloseoutTypes.MAINTENANCE ? JobTypes.MAINTENANCE : JobTypes.JOB;

  const [loadingBillingStatus, setLoadingBillingStatus] = useState();
  const handleInvoiceClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleJobBillingStatusChange = async billingStatus => {
    setLoadingBillingStatus(billingStatus);
    if (billingStatus === JobBillingStatus.DO_NOT_INVOICE) {
      await markDoNotInvoice({ jobId: jobData.id, tenantId: jobData.tenantId });
    } else if (billingStatus === JobBillingStatus.READY_TO_INVOICE) {
      await markJobReadyForInvoicing({ jobId: jobData.id, tenantId: jobData.tenantId });
    }
    handleMenuClose();
    setLoadingBillingStatus(null);
  };

  const handleMarkAsReadyToInvoice = () =>
    handleJobBillingStatusChange(JobBillingStatus.READY_TO_INVOICE);

  const handleMarkAsDoNotInvoice = async () =>
    handleJobBillingStatusChange(JobBillingStatus.DO_NOT_INVOICE);

  const handleCreateInvoiceModalClose = useCallback(() => setShowCreateInvoiceModal(false), [
    setShowCreateInvoiceModal
  ]);

  const pageBreadcrumbs = [
    { link: '', title: 'Operations' },
    { link: `/${entityDisplayName}/list`, title: `${entityDisplayName}s` },
    {
      link: jobData.jobNumber
        ? `/${jobData.jobTypeInternal?.toLowerCase()}/view/${jobData.jobNumber}`
        : '',
      title: `${entityDisplayName} ${jobData.customIdentifier || ''}`
    }
  ];

  if (!jobData || Object.keys(jobData).length === 0) {
    return (
      <ErrorBoundaries>
        <PageHeader
          title={`Report: ${entityDisplayName}`}
          pageMapKey="jobCloseOut"
          userLocale={user.locale}
          breadcrumbsArray={pageBreadcrumbs}
        />
      </ErrorBoundaries>
    );
  }

  const renderCreateInvoiceModal = () => {
    if (jobCloseoutType === JobCloseoutTypes.QUOTED) {
      if (jobData?.quoteJobs?.items?.length) {
        return (
          <QuotedJobCreateInvoiceModal
            open={showCreateInvoiceModal}
            onClose={handleCreateInvoiceModalClose}
            onSuccess={refetchJob}
            quotes={jobData.quoteJobs.items}
            jobData={jobData}
          />
        );
      }

      return (
        <QuotedAmountJobCreateInvoiceModal
          open={showCreateInvoiceModal}
          onClose={handleCreateInvoiceModalClose}
          jobData={jobData}
          onSuccess={refetchJob}
        />
      );
    }

    return (
      <TimeAndMaterialsJobCreateModal
        open={showCreateInvoiceModal}
        onClose={handleCreateInvoiceModalClose}
        onSuccess={refetchJob}
        jobData={jobData}
      />
    );
  };

  const formatReviewStatusText = reviewStatus => {
    return reviewStatus?.replaceAll(' ', '_')?.toUpperCase();
  };

  const generatePDFReport = async () => {
    await generateJobReportPDFs({
      visitIds: visits.map(v => v.id),
      viewSettings: JSON.parse(DEFAULT_PRESET.settings),
      jobId: jobData.id,
      isJobCloseoutReport: jobData.closeoutReport,
      snackbarOn: snackbar
    });
    snackbar(
      'success',
      'Generating Job Report PDF, please visit the "Reports & Invoices" tab on the Job page.'
    );
  };

  const handleStatusChange = async status => {
    await updateJob({
      id: jobData?.id,
      version: jobData?.version,
      ...status
    });
  };

  const restoreStatusLabel = () => (
    <Typography variant={TV.S2} weight={TW.MEDIUM} style={{ marginBottom: theme.spacing(0.5) }}>
      Restore automated status
    </Typography>
  );

  const getAdditionalStatusLabels = () => {
    // Use Manual status or get automated status
    const automatedQuoteLabel = getQuoteStatus(jobData?.quoteJobs?.items);
    const quoteStatusLabel = jobData.manualQuoteStatus || automatedQuoteLabel;
    const automatedLabel = getProcurementStatus(jobData?.purchaseOrders?.items);
    const procurementStatusLabel =
      jobData.procurementStatus || getProcurementStatus(jobData?.purchaseOrders?.items);
    return (
      <>
        {flags[FeatureFlags.JOB_QUOTE_STATUS] && (
          <StatusButtons
            enumType={EnumType.JOB_QUOTE_STATUS}
            value={quoteStatusLabel}
            overrideOptions={[
              jobData.manualQuoteStatus ? automatedQuoteLabel : jobQuoteStatus.QUOTE_NEEDED
            ]}
            overrideHandleStatusChange={() =>
              handleStatusChange({
                manualQuoteStatus: jobData.manualQuoteStatus ? null : jobQuoteStatus.QUOTE_NEEDED
              })
            }
            jobData={jobData}
            statusCue={jobData.manualQuoteStatus && restoreStatusLabel()}
            tooltipContent={generateQuoteStatusTooltip(jobData?.quoteJobs?.items, theme)}
            noLabelFormat
          />
        )}
        {flags[FeatureFlags.JOB_PROCUREMENT_STATUS] && (
          <StatusButtons
            enumType={EnumType.JOB_PROCUREMENT_STATUS}
            value={procurementStatusLabel}
            overrideOptions={[
              jobData.procurementStatus ? automatedLabel : JobProcurementStatus.POS_NEEDED
            ]}
            overrideHandleStatusChange={() =>
              handleStatusChange({
                procurementStatus: jobData.procurementStatus
                  ? null
                  : JobProcurementStatus.POS_NEEDED
              })
            }
            jobData={jobData}
            statusCue={jobData.procurementStatus && restoreStatusLabel()}
            noLabelFormat
            tooltipContent={generateProcurementStatusTooltip(jobData?.purchaseOrders?.items, theme)}
          />
        )}
      </>
    );
  };

  const additionalTitleComponents = () => (
    <>
      {jobCloseoutType === JobCloseoutTypes.MAINTENANCE ? (
        <StatusChip
          label={jobData.status}
          enumType={EnumType.MAINTENANCE_STATUS}
          enumValue={jobData.status}
          showIcon
          css={chipStyle}
        />
      ) : (
        <StatusButtons
          enumType={EnumType.JOB_STATUS}
          value={jobData.status}
          deriveStatus={deriveJobStatus}
          overrideOptions={jobStatusOptions}
          jobData={jobData}
          useStatusLabelMap
        />
      )}
      {getAdditionalStatusLabels()}
      {jobData.reviewStatus && (
        <StatusChip
          label={JOB_CLOSEOUT_STATUS[formatReviewStatusText(jobData?.reviewStatus)]}
          enumType={EnumType.JOB_CLOSEOUT_STATUS}
          enumValue={JOB_CLOSEOUT_STATUS[formatReviewStatusText(jobData?.reviewStatus)]}
          showIcon
          css={chipStyle}
        />
      )}
      {jobCloseoutType !== JobCloseoutTypes.MAINTENANCE && <JobBillingStatusChip job={jobData} />}
    </>
  );

  const pageButtons = () => (
    <Box>
      <ThemeProvider>
        <Button
          onClick={handleInvoiceClick}
          endIcon={<ArrowDropDown />}
          type={ButtonType.PRIMARY}
          variant="contained"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          disableElevation
        >
          Invoice
        </Button>
        <Menu
          id="demo-customized-menu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          open={open}
          onClose={handleMenuClose}
          css={styles.popoverMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <MenuItem onClick={() => setShowCreateInvoiceModal(true)} disableRipple>
            <InvoicesIcon />
            Create Invoice
          </MenuItem>
          <Divider variant="middle" />
          <MenuItem
            onClick={handleMarkAsReadyToInvoice}
            disableRipple
            disabled={
              jobData?.billingStatus === JobBillingStatus.READY_TO_INVOICE ||
              loadingBillingStatus === JobBillingStatus.READY_TO_INVOICE ||
              !hasNotBilledItems
            }
          >
            Mark as Ready to Invoice
            {loadingBillingStatus === JobBillingStatus.READY_TO_INVOICE && (
              <CircularProgress size="1em" style={{ marginLeft: 4 }} />
            )}
          </MenuItem>
          <MenuItem
            onClick={handleMarkAsDoNotInvoice}
            disableRipple
            disabled={
              jobData?.billingStatus === JobBillingStatus.DO_NOT_INVOICE ||
              loadingBillingStatus === JobBillingStatus.DO_NOT_INVOICE
            }
          >
            Mark as Do Not Invoice
            {loadingBillingStatus === JobBillingStatus.DO_NOT_INVOICE && (
              <CircularProgress size="1em" style={{ marginLeft: 4 }} />
            )}
          </MenuItem>
        </Menu>
        <MoreButton
          style={{ padding: 0, fontSize: 20 }}
          options={[
            {
              label: 'Generate Job Report PDF',
              onClick: () => generatePDFReport()
            }
          ]}
        />
      </ThemeProvider>
    </Box>
  );

  return (
    <ErrorBoundaries>
      <PageHeader
        title={`Report: ${entityDisplayName} ${jobData.customIdentifier}`}
        pageMapKey="jobCloseOut"
        userLocale={user.locale}
        breadcrumbsArray={pageBreadcrumbs}
        additionalTitleComponents={additionalTitleComponents()}
        overrideHeaderButtons={pageButtons()}
      />
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        css={{ marginTop: -8, marginBottom: 8 }}
      >
        <Grid item container css={styles.pageTitleContainer} xs>
          <TagButtons
            info={{
              id: jobData.id,
              version: jobData.version,
              customerPropertyId: jobData.customerPropertyId,
              departments: formatExistingDepartmentsForUpdate(jobData.departments?.items)
            }}
            tags={jobData.jobJobTags?.items}
            getService={() => new JobService()}
            TagType={TagType.JOB}
          />
        </Grid>
      </Grid>
      {renderCreateInvoiceModal()}
    </ErrorBoundaries>
  );
};

JobCloseoutHeader.propTypes = {
  jobData: PropTypes.shape({
    id: PropTypes.string,
    version: PropTypes.string,
    jobNumber: PropTypes.string,
    customIdentifier: PropTypes.string,
    customerPropertyId: PropTypes.string,
    jobTypeInternal: PropTypes.string,
    jobJobTags: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.object)
    }),
    quoteJobs: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.object)
    }),
    status: PropTypes.string,
    procurementStatus: PropTypes.string,
    reviewStatus: PropTypes.string,
    billingStatus: PropTypes.string
  }),
  refetchJob: PropTypes.func.isRequired,
  visits: PropTypes.arrayOf(PropTypes.object).isRequired
};

JobCloseoutHeader.defaultProps = {
  jobData: undefined
};

export default JobCloseoutHeader;
