import React from 'react';
import { useSelector } from 'react-redux';
import PageHeader from 'components/PageHeader';
import Labels from 'meta/labels';
import ReportHeaderLayout from '../../../ReportHeaderLayout';
import ReviewReportActionButton from '../ReviewReportActionButton';

const ReviewReportHeader = props => {
  const locale = useSelector(state => state?.user?.locale);

  const renderHeader = ({ jobTitle, jobPath }) => (
    <PageHeader
      title={`Review Report for ${jobTitle}`}
      breadcrumbsArray={[
        { title: 'Operations', link: '' },
        {
          title: Labels.reviewReport[locale],
          link: '/reviewreport/list'
        },
        {
          link: jobPath,
          title: jobTitle
        }
      ]}
    />
  );

  const renderButtons = props => <ReviewReportActionButton {...props} />;

  const formatReportTitle = ({ jobTitle, visitNumber }) =>
    `Reviewed Report for ${jobTitle} (Visit ${visitNumber})`;

  return (
    <ReportHeaderLayout
      {...props}
      renderHeader={renderHeader}
      renderButtons={renderButtons}
      formatReportTitle={formatReportTitle}
    />
  );
};

export default ReviewReportHeader;
