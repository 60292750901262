export const useStyles = () => ({
  container: theme => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.default.spacing(0.5),
    marginTop: theme.default.spacing(0.5)
  }),
  rowLabel: {
    fontSize: 10,
    textTransform: 'uppercase'
  }
});
