import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect, useSelector } from 'react-redux';
import { CompanyService } from 'services/core';
import { getPriceBooksByCompany } from 'services/API/priceBook';
import AmplifyService from 'services/AmplifyService';
import { Logger } from 'services/Logger';
import { snackbarOn } from 'redux/actions/globalActions';
import { Select, Spinner } from 'components';
import { Typography, Box } from '@material-ui/core';
import Labels from 'meta/labels';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import LinkButton from 'components/Buttons/BlueText';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import LabourRate from './LabourRates';
import { getLaborRowsAndCols } from './LabourRateHelpers';
import styles from './style';

import { getCompany } from './queries';

const LaborRateSection = props => {
  const {
    user,
    form,
    setRates,
    dropDownColWidth,
    rateCardData,
    isReadOnly,
    onChangeFormat = () => {}
  } = props;
  const pricebookVersion = useSelector(s => s.company.pricebookVersion);
  const [openLabourList, setOpenLabourList] = useState(false);
  const [laborRatePricebook, setLaborRatePriceBook] = useState('');
  const [pricebooks, setPricebooks] = useState([]);
  const [isLoadingPricebooks, setIsLoadingPricebooks] = useState(false);
  const [laborItems, setLaborItems] = useState([]);
  const [laborMetaData, setLaborMetaData] = useState();

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  useEffect(() => {
    setOpenLabourList(!rateCardData?.labourPricebookId && rateCardData?.labourEntries?.length);
    const option = pricebooks.find(o => o.value === rateCardData?.labourPricebookId);
    setLaborRatePriceBook(option);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricebooks]);

  const changeFormat = () => {
    setOpenLabourList(false);
    setLaborItems(items => items.map(item => ({ ...item, rate: 0 })));
    onChangeFormat();
  };
  const appSyncClient = AmplifyService.appSyncClient();

  const queryPricebooksV1 = async () => {
    const companyService = new CompanyService();

    try {
      const company = await companyService.getPricebooksForCompany(
        user.tenantId,
        `${user.tenantId}_Company_${user.tenantCompanyId}`
      );
      setPricebooks(constructSelectOptions(company.data?.getCompany?.priceBooks?.items || []));
    } catch (e) {
      Logger.error(e);
      snackbarOn('error', 'Failed to fetch company pricebook information');
    }
  };

  const queryPricebooksV2 = async () => {
    try {
      const response = await getPriceBooksByCompany();
      setPricebooks(constructSelectOptions(response?.data?.priceBooks || []));
    } catch (e) {
      Logger.error(e);
      snackbarOn('error', 'Failed to fetch company pricebook information');
    }
  };

  useEffect(() => {
    (async () => {
      // TODO: this should be passed in from parent, refactoring needed here and in SAs.
      setIsLoadingPricebooks(true);
      if (pricebookVersion >= 2) {
        await queryPricebooksV2();
      } else {
        await queryPricebooksV1();
      }

      try {
        const { data } = await appSyncClient.client.query({
          query: getCompany,
          variables: {
            partitionKey: user.tenantId,
            sortKey: `${user.tenantId}_Company_${user.tenantCompanyId}`
          }
        });
        const [rows, cols] = getLaborRowsAndCols(data, rateCardData?.labourEntries);
        setLaborItems(rows);
        setLaborMetaData(cols);
      } catch (e) {
        Logger.error(e);
        snackbarOn('error', 'Failed to fetch company pricebook information');
      }

      setIsLoadingPricebooks(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    })();
  }, [user.tenantId, user.tenantCompanyId]);

  const handleSelectPricebook = option => {
    form ? form.setFieldValue('laborRate', option) : setRates('labourPricebookId', option.value);

    setLaborRatePriceBook(option);
  };

  const handleLabourRateChanges = detail => {
    const copy = [...laborItems];
    const newItem = copy.find(item => item.id === detail.labourTypeId);
    newItem[detail.billingHourTypeId].rate = detail.rate;
    setLaborItems(copy);

    form ? form.setFieldValue('laborRate', copy) : setRates('laborRate', copy);
  };

  return (
    <Grid item xs={12} className={classes.mb5}>
      <Grid item xs={12}>
        <Box component="div" className={`${classes.dFlex} ${classes.alignItemsCenter}`}>
          <Typography variant="h6" className={`${classes.width100}`}>
            {Labels.laborRates[user.locale]}
          </Typography>

          {openLabourList && (
            <div className={`${classes.dFlex} ${classes.justifyContentBetween} ${classes.w100}`}>
              <LinkButton
                className={`${classes.ml2} ${classes.auditText}`}
                label={Labels.changeFormat[user.locale]}
                handle={() => changeFormat()}
              />
            </div>
          )}
        </Box>

        {!openLabourList && (
          <Grid item xs={dropDownColWidth}>
            <Typography variant="body2" className={classes.rateDescription}>
              {Labels.createFromExistingPricebook[user.locale].toUpperCase()}
            </Typography>
            {isLoadingPricebooks ? (
              <Spinner styles={{ margin: 5 }} size={19.6} />
            ) : (
              <Select
                name="name"
                label={Labels.selectPricebook[user.locale]}
                value={laborRatePricebook}
                onChange={handleSelectPricebook}
                isDisabled={isReadOnly}
                options={pricebooks}
                menuHeight={100}
              />
            )}
            {!isReadOnly && (
              <Typography
                variant="body1"
                className={`${classes.mt2} ${classes.handIcon}`}
                onClick={() => {
                  setLaborRatePriceBook(null);
                  if (setRates) {
                    setRates('labourPricebookId', '');
                  }
                  setOpenLabourList(true);
                }}
              >
                <AddCircleOutlineIcon className={classes.wrapIcon} />
                Set New Labor Rates
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
      {openLabourList && (
        <Grid item xs={12}>
          <LabourRate
            metaData={laborMetaData}
            data={laborItems}
            handleLabourRateChanges={handleLabourRateChanges}
            disableFilter
            showDefaults
            isLoading={isLoadingPricebooks}
          />
        </Grid>
      )}
    </Grid>
  );
};

LaborRateSection.propTypes = {
  user: PropTypes.object.isRequired,
  dropDownColWidth: PropTypes.number,
  classes: PropTypes.object,
  rateCardData: PropTypes.object
};

LaborRateSection.defaultProps = {
  dropDownColWidth: 12,
  rateCardData: {},
  classes: {},
  isReadOnly: false
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

export default connect(mapStateToProps, mapDispatcherToProps)(LaborRateSection);
