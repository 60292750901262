import useExtendedLazyQuery from 'customHooks/useExtendedLazyQuery';
import { useSnackbar } from 'customHooks/useSnackbar';
import { logErrorWithCallback } from 'utils';

import { prevNextTimesheetPeriodQuery } from '../gql';

const serializer = data => {
  const { employeeId, statuses, date, dir } = data;
  return {
    variables: {
      employeeId,
      data: {
        statuses,
        date,
        dir
      }
    }
  };
};

const usePrevNextTimesheetPeriod = ({ onCompleted }) => {
  const snackbar = useSnackbar();

  return useExtendedLazyQuery(prevNextTimesheetPeriodQuery, {
    serializer,
    transform: result => result?.prevNextTimesheetPeriod,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onError: error => {
      logErrorWithCallback(error, snackbar, 'Unable to fetch next timesheet period');
    },
    onCompleted
  });
};

export default usePrevNextTimesheetPeriod;
