import React from 'react';

import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';

import { GOOGLE_MAPS_API_KEY } from 'utils/AppConstants';

import './styles.css';
import styles from './styles';

/**
 * Map view using the Google JS API that lets you interact with the map (like
 * dragging around to move).
 *
 * Refer to `components/MapViewStatic` for static version of this component.
 */
class MapView extends React.Component {
  render() {
    const { coordinates } = this.props;
    const { latitude, longitude } = coordinates;
    const dimensions = this.props.dimensions ?? { width: '100px', height: '100px' };
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    return (
      <div className="mapContainer" style={dimensions}>
        <Map
          google={this.props.google}
          zoom={12}
          style={styles.container}
          containerStyle={{ position: 'static' }}
          initialCenter={{ lat: latitude, lng: longitude }}
          center={{ lat: latitude, lng: longitude }}
          disableDefaultUI
          onClick={() => window.open(url, '_blank')}
        >
          <Marker position={{ lat: latitude, lng: longitude }} />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAPS_API_KEY
})(MapView);
