import { round as _round } from 'lodash';

// custom rounding method b/c negative numbers should round up too
// i.e. Math.round(-1.5) = -1 when it should be -2.
const round = (value, precision) => Math.sign(value) * _round(Math.abs(value), precision);

export const parseFloatAndRound = (f, precision) => {
  const parsed = parseFloat(f);
  return !Number.isNaN(parsed) ? round(parsed, precision) : null;
};

// used for currency displays where we only want 2 decimal places
export const roundCurrency = f => parseFloatAndRound(f, 2);
