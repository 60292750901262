import gql from 'graphql-tag';

const getNotesByQuoteId = gql`
  query getNotesByQuoteId($id: String!) {
    getQuoteById(id: $id) {
      id
      notes(entityConnection: "Note") {
        items {
          id
          partitionKey
          sortKey
          version
          note
          subject
          createdBy
          lastUpdatedDate
          lastUpdatedDateTime
          lastUpdatedBy
        }
      }
    }
  }
`;
export default getNotesByQuoteId;
