import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  savedFilterWrapper: {
    marginBottom: 20
  },
  filterCaption: {
    fontSize: 10,
    lineHeight: '12px',
    marginBottom: 4,
    fontWeight: 500,
    color: theme.palette.grayscale(20),
    letterSpacing: '0.02em'
  },
  filtersTitle: {
    marginLeft: 8,
    marginTop: 16,
    marginBottom: 16,
    color: theme.palette.grayscale(20),
    fontWeight: 500,
    fontSize: 14
  },
  sectionTitle: {
    marginLeft: 8,
    marginBottom: 6,
    color: theme.palette.grayscale(60),
    fontWeight: 500,
    fontSize: 10
  },
  selectWrapper: {
    position: 'relative',
    width: '100%',
    height: 36,
    borderRadius: 2,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '-0.011px',
    border: 0,
    marginRight: 8,
    '&.MuiSelect-root': {
      borderRadius: theme.spacing(0.5),
      // backgroundColor: theme.palette.background.inputBaseColor,
      alignSelf: 'flex-start'
    },
    '&.MuiSelect-select.MuiSelect-select': {
      fontSize: 13,
      display: 'flex',
      flexWrap: 'wrap',
      alignSelf: 'center',
      flexDirection: 'row',
      width: theme.spacing(15),
      paddingLeft: theme.spacing(2),
      // color: theme.palette.grayscale(20)
    }
  },
  addBtn: {
    height: 48,
    width: '100%',
    paddingLeft: 16,
    alignItems: 'center',
    color: theme.palette.secondary.main,
    borderTop: `1px solid ${theme.palette.grayscale(90)}`,
    justifyContent: 'left'
  },
  moreIcon: {
    color: theme.palette.grayscale(20),
    position: 'absolute',
    right: 10,
    fontSize: 16,
    minWidth: 0
  },
  menuText: {
    color: theme.palette.grayscale(20),
    fontSize: 12,
    fontWeight: 400
  },
  menuIcon: {
    color: theme.palette.grayscale(60),
    paddingRight: 10
  },
  menuTopBorder: {
    borderTop: `1px solid ${theme.palette.grayscale(90)}`
  },
  defaultBtnContainer: {
    paddingLeft: 8,
    paddingRight: 8
  },
  changeLabelStyles: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8
  },
  button: {
    height: 48,
    width: '100%',
    paddingLeft: 16,
    alignItems: 'center',
    color: theme.palette.secondary.main,
    borderTop: `1px solid ${theme.palette.grayscale(90)}`,
    justifyContent: 'left'
  },
  iconStyle: {
    fontSize: 14,
    marginRight: 6
  },
  moreIconStyle: {
    fontSize: 16
  }
}));
