import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  body: {
    padding: 4
  },
  header: {
    display: 'flex',
    color: 'white',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4
  },
  priority: {
    borderTopLeftRadius: 4,
    backgroundColor: theme.palette.grayscale(20),
    color: 'white',
    flexGrow: 0,
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5)
  },
  status: {
    flexGrow: 1,
    paddingLeft: 4
  },
  property: {
    fontSize: 18,
    fontWeight: 700
  }
}));
