import gql from 'graphql-tag';

const getUnitOfMeasures = gql`
  query getUnitOfMeasures($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      entityType
      unitOfMeasures(entityConnection: "UnitOfMeasure") {
        items {
          id
          name
          unitOfMeasureType
          unitOfMeasureCategory
        }
      }
    }
  }
`;

export default getUnitOfMeasures;
