import gql from 'graphql-tag';

const listTenantSettings = gql`
  query listTenantSettings($partitionKey: String!, $sortKey: String!) {
    listTenantSettings(partitionKey: $partitionKey, sortKey: $sortKey) {
      # mappedEntity {
      #   ... on TenantSetting {
      settingKey
      settingValue
      settingPath
      parentId
      parentSortKey
      hierarchy
      id
      entityType
      version
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      #   }
      # }
    }
  }
`;

export default listTenantSettings;
