import buildHeroMuiFormOverrides from '../../../../components/buildHeroMuiFormOverrides';

const styles = theme => {
  return {
    contentContainer: {
      minWidth: 550,
      padding: 24
    },
    attachmentTitle: {
      fontSize: 12,
      fontWeight: 700,
      marginBottom: 10
    },
    addButton: {
      color: '#333333',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '17px',
      textDecoration: 'none',
      '& .MuiButton-iconSizeMedium': {
        marginLeft: 0,
        marginRight: 4
      },
      '& .MuiButton-iconSizeMedium > *:first-child': {
        fontSize: 14
      }
    },
    sectionTitle: {
      color: theme.palette.grayscale(20),
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: '-0.03em',
      marginBottom: 15
    },
    iconTextContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    uploadFileIcon: {
      color: theme.palette.grayscale(20),
      fontSize: 20
    },
    uploadFileText: {
      color: theme.palette.grayscale(20),
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: '-0.02em',
      textDecoration: 'none',
      marginLeft: theme.spacing(0.5)
    },
    carouselNoImages: {
      color: theme.palette.grayscale(60),
      fontSize: 14
    },
    fileListContainer: {
      marginBottom: 15
    },
    formContainer: buildHeroMuiFormOverrides(theme)
  };
};

export default styles;
