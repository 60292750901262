import gql from 'graphql-tag';

const updatePricebookEntry = gql`
  mutation updatePriceBookEntry($partitionKey: String!, $data: UpdatePriceBookEntryInput!) {
    updatePriceBookEntry(partitionKey: $partitionKey, data: $data) {
      id
      version
    }
  }
`;

export default updatePricebookEntry;
