export const attachmentLayout = ({ isMultiVisits = false, visitOptions = [] }) => ({
  fields: {},
  layouts: {
    default: {
      options: {
        direction: 'row',
        width: 450
      },
      contents: [
        {
          options: {
            direction: 'column'
          },
          contents: [
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FileUpload'
                  },
                  source: 'attachments',
                  options: {
                    label: 'Attachment',
                    isRequired: true,
                    multiple: false,
                    accept: 'image/*,video/*'
                  }
                }
              ]
            },
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'TextInput'
                  },
                  source: 'customFileName',
                  options: {
                    label: 'File Name',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                grow: 1,
                padding: 12,
                direction: 'row'
              },
              contents: [
                {
                  options: {
                    direction: 'column',
                    paddingRight: isMultiVisits ? 8 : null
                  },
                  contents: [
                    {
                      component: {
                        default: 'SelectInput'
                      },
                      source: 'type',
                      options: {
                        label: 'Type',
                        isRequired: true,
                        fullWidth: true,
                        alignment: 'left',
                        inputOptions: [
                          {
                            label: 'Before',
                            value: 'before'
                          },
                          {
                            label: 'After',
                            value: 'after'
                          }
                        ],
                        enableSort: false
                      }
                    }
                  ]
                },
                isMultiVisits && {
                  options: {
                    direction: 'column',
                    paddingLeft: 8
                  },
                  contents: [
                    {
                      component: {
                        edit: null,
                        add: 'SelectInput'
                      },
                      source: 'visitId',
                      options: {
                        valueSet: visitOptions,
                        label: 'Visit',
                        isRequired: true,
                        fullWidth: true,
                        enableSort: false
                      }
                    }
                  ]
                }
              ].filter(Boolean)
            },
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'TextInput'
                  },
                  source: 'comment',
                  options: {
                    label: 'Comment',
                    fullWidth: true,
                    alignment: 'left',
                    lines: 3
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      attachments: {
        type: 'array'
      },
      customFileName: {
        type: 'string'
      },
      type: {
        type: 'string',
        nullable: true
      },
      comment: {
        type: 'string',
        nullable: true
      },
      visitId: {
        type: 'string'
      }
    },
    required: ['attachments', 'customFileName', 'type', isMultiVisits && 'visitId'].filter(Boolean)
  },
  validationErrors: {
    attachments: {
      required: 'File is required.'
    },
    customFileName: {
      required: 'File name is required.'
    },
    type: {
      required: 'Type is required.'
    },
    visitId: {
      required: 'Visit is required'
    }
  }
});
