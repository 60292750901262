import React, { useCallback, useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useGetProjectById, useGetProjectKPIValues } from 'services/APIHooks/project';

import CreateProject from '@pm/components/CreateProject';

import { useCompanyTimezone } from '@pm/components/hooks';
import { useStyles } from './ProjectLayout.styles';
import PMSidebar from './components/PMSidebar';
import PMHeader from './components/PMHeader';
import ProjectInfo from './components/ProjectInfo';

const ProjectLayout = ({
  children,
  user,
  project,
  setProjectKpis,
  setProjectDetails,
  projectDetailsRefetch,
  refetchProjectDetails,
  setTimezone
}) => {
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const styles = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [projectResponse] = useGetProjectById(projectId, {
    depends: [projectDetailsRefetch, pathname],
    onSuccess: data => {
      setProjectDetails(data);
    }
  });

  const [projectKpiResponse] = useGetProjectKPIValues(projectId, {
    depends: [projectDetailsRefetch, pathname],
    onSuccess: data => {
      setProjectKpis(data);
    }
  });

  const [{ data, loading }] = useCompanyTimezone();
  useEffect(() => {
    if (!loading && data) {
      setTimezone(data, loading);
    }
  }, [data, loading, setTimezone]);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleModalSubmit = useCallback(() => {
    refetchProjectDetails();
    setIsModalOpen(false);
  }, [refetchProjectDetails]);

  return (
    <div css={styles.root}>
      <PMHeader handleOpenModal={handleOpenModal} />
      <div css={styles.body}>
        <PMSidebar />
        <div css={styles.mainContent}>
          <ProjectInfo />
          {children}
        </div>
      </div>
      <CreateProject
        initialData={{ type: 'edit', project }}
        open={isModalOpen}
        user={user}
        onSubmit={handleModalSubmit}
        handleClose={handleCloseModal}
      />
    </div>
  );
};

export default ProjectLayout;
