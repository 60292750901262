import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FullScreenModal from 'components/FullScreenModal';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

/**
 * This component generates a PDF of a given item such as an RFI, submittal, change order, etc.
 * The fileName and URL are returned when the file is successfully generated and uploaded.
 * This component is also a modal that displays the PDF.
 *
 * This component uses PDFDocument, which generates a PDF using the same "configuration" as MUIForms.
 *
 * @param {Boolean} open If the modal should be open or not.
 * @param {Function} handleClose Function that's called when the modal is closed.
 * @param {Object} pdfComponent A React-pdf component
 * @param {String} title Title for the modal.
 * @returns
 */
const ProjectPDF = ({ open, handleClose, title, pdfBlob }) => {
  const classes = useStyles();
  const pdfUrl = useMemo(() => (pdfBlob ? URL.createObjectURL(pdfBlob) : null), [pdfBlob]);

  return (
    <div className={classes.root}>
      <FullScreenModal
        title={title}
        open={open}
        handleClose={handleClose}
        style={{ display: 'flex', flex: '1 1 auto' }}
        contentStyle={{ marginTop: 0, marginBottom: 0, flex: '1 1 auto' }}
        modalHeaderButtons={[]}
      >
        {pdfUrl && open && <iframe src={pdfUrl} width="100%" height="100%" title="merged" />}
      </FullScreenModal>
    </div>
  );
};

ProjectPDF.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  pdfBlob: PropTypes.object,
  handleClose: PropTypes.func.isRequired
};

ProjectPDF.defaultProps = {
  title: 'PDF',
  pdfBlob: undefined
};

const mapStateToProps = state => ({ user: state.user });
export default connect(mapStateToProps)(ProjectPDF);
