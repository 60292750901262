/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { calculateMarginFromMarkup } from '@buildhero/math';
import { AddRecordButton, ResponsiveTable, SergeantModal } from 'components';
import { PermissionConstants } from 'utils/AppConstants';
import { validations } from 'services/core';
import { purchasedItemsRows } from 'meta/Jobs/Invoice/review-report-tables';
import PurchasedItemLayout from 'meta/Jobs/Invoice/PurchasedItemForm';
import { PurchasedItemSgtLayout } from 'meta/Jobs/Invoice/PurchasedItemSgtForm';
import { useLazyFetchPricebookEntry } from 'components/Tasks/components/useLazyFetchPricebookEntry';
import AlgoliaSearchWrapper from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import StorageService from 'services/StorageService';
import { convertForMathLib } from 'utils/mathLibrary';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import {
  getCostCodes,
  getJobCostTypes,
  splitJobCostTypes
} from 'customHooks/useLabourTypeDependency';
import { Logger } from 'services/Logger';
import { roundCurrency, roundFloat } from 'utils';
import { getMarkupFromMargin } from 'utils/onCalcChange';
import RowActions from './ReviewReportRowActions';

const PurchaseOrders = ({
  current,
  send,
  receiptItem,
  isReviewReport,
  setStopLoading,
  user,
  snackbarOn,
  marginEnabled
}) => {
  const { context, value } = current;
  const [s3Url, setS3Url] = useState('');
  const [formData, setFormData] = useState({});
  const imageUrl = value.purchase_order_line_item_new
    ? context.modalRecord?.purchaseOrderReceipts?.items?.[0]?.imageUrl
    : context.modalRecord?.image?.[0]?.imageUrl;

  useEffect(() => {
    async function fetchS3ImageUrl() {
      try {
        const storageService = new StorageService();
        const url = await storageService.getFile(imageUrl);
        setS3Url([{ fileUrl: url }]);
        setFormData(f => ({
          ...f,
          includeImage: url
        }));
      } catch (error) {
        Logger.error(error);
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          snackbarOn('error', error.graphQLErrors[0].message);
        } else {
          snackbarOn('error', 'Unable to load receipt image, please try again later');
        }
        setS3Url([{ fileUrl: null }]);
        setFormData(f => ({
          ...f,
          includeImage: false
        }));
      }
    }

    const getJobCostingFields = async () => {
      const costCodes = await getCostCodes(user.tenantId, user.tenantCompanyId, snackbarOn);
      const costCodeTypeDetails = await getJobCostTypes(
        user.tenantId,
        user.tenantCompanyId,
        snackbarOn
      );
      const { jobCostTypes, revenueTypes } = splitJobCostTypes(costCodeTypeDetails);
      setFormData(f => ({
        ...f,
        costCodes: [...constructSelectOptions(costCodes)],
        costTypes: constructSelectOptions(jobCostTypes),
        revenueTypes: constructSelectOptions(revenueTypes)
      }));
    };

    fetchS3ImageUrl();
    getJobCostingFields();
  }, [imageUrl, user.tenantId, user.tenantCompanyId, snackbarOn]);

  const PurchasedLayoutMeta = PurchasedItemLayout.entity.layouts.web;
  PurchasedLayoutMeta.buttons.cancel.action = () => send('CLOSE');

  const isEditable = isReviewReport && !context.freezeEdit;

  const PurchaseOrderItemActions = ({ record }) => {
    return (
      <RowActions
        editAction={() =>
          send('EDIT_PURCHASE_ORDER_LINE_ITEM', { data: record, parent: receiptItem })
        }
        deleteAction={() =>
          send('DELETE_PURCHASE_ORDER_LINE_ITEM', { data: record, parent: receiptItem })
        }
        checkboxAction={event =>
          send('INCLUDE_PURCHASE_ORDER_LINE_INVOICE', {
            data: record,
            checked: event.target.checked,
            parent: receiptItem
          })
        }
        record={record}
        isEditable={isEditable}
      />
    );
  };

  const purchasedItemsTableMeta = [...purchasedItemsRows(marginEnabled)];
  if (isEditable) {
    purchasedItemsTableMeta.push({
      id: 'actions',
      isCustom: true,
      type: 'actions',
      label: ''
    });
  }

  const fetchPricebookEntry = useLazyFetchPricebookEntry();
  const handleOnItemSelection = async (selectedItem, selectedItemName, form) => {
    const { values } = form;
    if (!selectedItem)
      return form.setValues({
        ...values,
        itemName: selectedItem.name,
        productId: selectedItem.id,
        productSortKey: selectedItem.sortKey,
        description: selectedItem.description,
        accountingRefId: selectedItem.accountingRefId,
        unitOfMeasure: selectedItem.unitOfMeasure,
        quantity: 1,
        taxable: selectedItem.taxable
      });
    const pbEntry = await fetchPricebookEntry({
      pricebookId: context.reviewReportPriceBookId,
      productSortKey: selectedItem.sortKey
    });
    const markupValue =
      roundFloat(pbEntry.markupValue) || roundFloat(selectedItem.markupValue) || null;
    const valuesToSet = {
      ...values,
      itemName: selectedItem.name,
      productId: selectedItem.id,
      productSortKey: selectedItem.sortKey,
      description: selectedItem.description,
      accountingRefId: selectedItem.accountingRefId,
      unitOfMeasure: selectedItem.unitOfMeasure,
      taxable: selectedItem.taxable,
      quantity: 1,
      unitPrice: roundCurrency(pbEntry.unitPrice),
      unitCost: roundFloat(pbEntry.unitCost ?? selectedItem.unitCost),
      marginValue: convertForMathLib(calculateMarginFromMarkup, markupValue),
      markupValue,
      markupType: pbEntry.markupType,
      amount: pbEntry.unitPrice,
      costCodeId: pbEntry.costCodeId,
      revenueTypeId: pbEntry.revenueTypeId,
      jobCostTypeId: pbEntry.jobCostTypeId,
      priceBookEntryId: pbEntry.id
    };
    form.setValues(valuesToSet);
  };

  return (
    <>
      <Grid style={{ marginLeft: 100, marginBottom: 10 }}>
        <ResponsiveTable
          rowMetadata={purchasedItemsTableMeta}
          data={receiptItem?.purchaseOrderLines?.items || []}
          noDataMsg="No items"
          disableFilter
          customCellComponents={{ actions: PurchaseOrderItemActions }}
          disablePagination
          noMaxHeight
        />
      </Grid>

      {isEditable && (
        <AddRecordButton
          style={{ marginLeft: 168 }}
          label="+ Add Item"
          onClick={() => {
            send('ADD_PURCHASE_ORDER_LINE_ITEM', {
              data: receiptItem
            });
          }}
        />
      )}
      <SergeantModal
        open={value?.purchase_order_line_edited || value?.purchase_order_line_item_new || false}
        layout={PurchasedItemSgtLayout(
          context.productFilter,
          formData,
          context.isPriceBookEnabled,
          handleOnItemSelection,
          marginEnabled
        )}
        dataType="receipt line item"
        data={
          value.purchase_order_line_item_new
            ? {
                s3Url,
                departmentName: context.visit.departmentName,
                productFilter: context.productFilter,
                accountingRefIdOfClass: context.visit.accountingRefIdOfClass || '',
                isPriceBookEnabled: context.isPriceBookEnabled
              }
            : {
                ...context.modalRecord,
                s3Url,
                markupValue: context.modalRecord.markup,
                marginValue: convertForMathLib(
                  calculateMarginFromMarkup,
                  context.modalRecord.markup
                )
              }
        }
        handleClose={() => send('CLOSE')}
        handlePrimaryAction={async (values, stopLoading) => {
          const formattedValues = {
            ...values,
            markup: marginEnabled ? getMarkupFromMargin(values.marginValue) : values.markupValue
          };
          setStopLoading(stopLoading);
          send('SAVE', { saveData: formattedValues });
        }}
        customComponents={{ AlgoliaSearchWrapper }}
        validationSchema={validations.reviewReportPurchaseOrderLinesSchema}
        mode={value.purchase_order_line_item_new ? 'new' : 'edit'}
      />
    </>
  );
};

export default PurchaseOrders;
