import React from 'react';
import Settings from '@material-ui/icons/Settings';
import { func, bool } from 'prop-types';

import FilterButton, { FilterTypes, PopOverButtonTypes } from '@dispatch/components/FilterButton';

const filterText = {
  showVisits: { label: 'Show Visits' },
  showTechs: { label: 'Show Techs' },
  showLocations: { label: 'Show Locations' }
};

const filterOptions = {
  showVisits: { type: FilterTypes.switch },
  showTechs: { type: FilterTypes.switch },
  showLocations: { type: FilterTypes.switch }
};

const MapSettings = ({ filterBy, showVisits, showTechs, showLocations }) => {
  const handleFilterChange = (field, value) => {
    filterBy({ [field]: value });
  };

  const handleFiltersClear = () => {
    filterBy({
      showVisits: undefined,
      showTechs: undefined,
      showLocations: undefined
    });
  };

  return (
    <FilterButton
      buttonText={<Settings />}
      buttonType={PopOverButtonTypes.icon}
      filterState={{ showVisits, showTechs, showLocations }}
      filterOptions={filterOptions}
      filterText={filterText}
      type={PopOverButtonTypes.icon}
      onFilterChange={handleFilterChange}
      onFiltersClear={handleFiltersClear}
    />
  );
};

MapSettings.propTypes = {
  filterBy: func.isRequired,
  showVisits: bool.isRequired,
  showTechs: bool.isRequired,
  showLocations: bool.isRequired
};

export default MapSettings;
