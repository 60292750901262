import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import Linkify from 'react-linkify';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinkButton from 'components/Buttons/BlueText';
import { snackbarOn } from 'redux/actions/globalActions';
import Labels from 'meta/labels';
import AppConstants from 'utils/AppConstants';
import Modal from 'components/Modal';
import SectionHeader from 'components/SectionHeader';
import styles from './styles';
import NotesModal from './NotesModal';

class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAllNotesModal: false,
      open: false,
      requireRefresh: false
    };
  }

  handleViewAllNotes = ({ isAllNotesModal }) => {
    this.setState({ open: true, isAllNotesModal });
  };

  handleClosePopUp = () => {
    this.setState({ open: false });
  };

  setRefreshRequired = () => {
    this.setState({ requireRefresh: true });
  };

  renderNote = (note, islastNote) => {
    const { noteLimit, classes, addSpacingBetweenNotes } = this.props;
    const noteLength = note?.length || note?.note?.length;
    const isBigContent = noteLimit && note ? noteLength > noteLimit : false;
    return (
      <Linkify>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={9}>
          <Typography variant="subtitle2">{note?.subject || ''}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={9}>
          {!isBigContent && (
            <Typography variant="body2" className={classes.content}>
              {note?.note || ''}
            </Typography>
          )}
          {isBigContent && (
            <div style={{ height: 100, overflowY: 'auto' }}>
              <Typography className={classes.bigContent}>
                {note.note}
                ...
              </Typography>
            </div>
          )}
        </Grid>
        {note?.lastUpdatedDateTime && (
          <Grid item xs={12} sm={12} md={10} lg={10} xl={9} className={classes.footer}>
            <Typography variant="caption" className={classes.label}>
              Last edited by {note.lastUpdatedBy} on &nbsp;
              <Moment unix format={AppConstants.DATETIME_FORMAT}>
                {note.lastUpdatedDateTime / 1000}
              </Moment>
            </Typography>
          </Grid>
        )}
        {/* Add spacing between notes when there is no lastUpdatedDateTime */}
        {!note?.lastUpdatedDateTime && addSpacingBetweenNotes && (
          <Grid item xs={12}>
            <div style={{ height: islastNote ? 8 : 16 }} />
          </Grid>
        )}
      </Linkify>
    );
  };

  render() {
    const {
      classes,
      notesData,
      allNotesFor,
      noDataMessage,
      title,
      subtitle,
      parent,
      mutateService,
      deleteService,
      showAllNotes,
      isActive = true,
      useAddCircleOutlineIcon = false
    } = this.props;

    let latestNotes;
    if (notesData && notesData.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      latestNotes = showAllNotes ? notesData : notesData.slice(0, 2);
    }

    const notesCountText = notesData.length ? `(${notesData.length})` : '';
    const modalTitle = this.state.isAllNotesModal
      ? `${Labels.allNotesFor[this.props.user.locale]} ${allNotesFor}`
      : `${Labels.addNoteFor[this.props.user.locale]} ${allNotesFor}`;
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="caption" className={classes.inputLabel}>
            {title}
          </Typography>
        </Grid>
        {latestNotes?.length === 0 && (
          <Typography variant="caption" className={classes.label}>
            {noDataMessage}
          </Typography>
        )}
        {latestNotes?.map((nt, index) => this.renderNote(nt, latestNotes.length - index === 1))}
        <Grid item xs={12} sm={12} md={10} lg={10} xl={9}>
          {isActive && (
            <LinkButton
              label="Add note"
              icon={useAddCircleOutlineIcon ? AddCircleOutlineIcon : AddIcon}
              handle={() => this.handleViewAllNotes({ isAllNotesModal: false })}
              addIconClass={useAddCircleOutlineIcon && classes.addIcon}
              style={{ paddingLeft: 0, paddingRight: 36 }}
            />
          )}
          {!showAllNotes && notesData?.length > 0 && (
            <LinkButton
              label={
                isActive
                  ? `${this.props.linkName} ${notesCountText}`
                  : `View all notes ${notesCountText}`
              }
              style={{ paddingLeft: 0, textAlign: 'left' }}
              handle={() => this.handleViewAllNotes({ isAllNotesModal: true })}
            />
          )}
        </Grid>
        <Modal
          open={this.state.open}
          handleClose={() => {
            if (this.state.requireRefresh && this.props.refetch) {
              this.props.refetch();
            }
            this.handleClosePopUp();
          }}
          width="880"
        >
          <SectionHeader title={modalTitle} />
          <NotesModal
            isAllNotesModal={this.state.isAllNotesModal}
            notesData={notesData}
            subtitle={subtitle}
            parent={parent}
            setRefresh={this.setRefreshRequired}
            refetch={() => {
              if (this.props.refetch) {
                this.props.refetch();
              }
              this.handleClosePopUp();
            }}
            handleCancel={() => {
              this.handleClosePopUp();
            }}
            mutateService={mutateService}
            deleteService={deleteService}
            isActive={isActive}
          />
        </Modal>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

// utilizes global spinner actions
const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const connectedNotes = connect(mapStateToProps, mapDispatchToProps)(Notes);

export default withStyles(styles, { withTheme: true })(connectedNotes);
