import React, { Component } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Configure,
  connectStateResults,
  connectSearchBox,
  connectCurrentRefinements
} from 'react-instantsearch-dom';
import { CommonService } from 'services/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import {
  Popper,
  ClickAwayListener,
  Fade,
  Paper,
  TextField,
  List,
  IconButton,
  InputAdornment,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import configForEnvironment from 'configs/aws-exports';
import ENV from 'configs/env';
import { MUIFormComponents } from '@buildhero/sergeant';
import { Logger } from 'services/Logger';

import Hit from './Hit';
import RefinementList from './RefinementList';
import styles from './styles';
import './style.css';

class AlgoliaSearchDropdown extends Component {
  // eslint-disable-next-line no-confusing-arrow

  constructor(props) {
    super(props);
    this.mounted = true;
    this.CommonService = new CommonService();
    this.state = {
      searchKey: '',
      searchClient: ''
    };
    this.inputRef = React.createRef();
  }

  componentDidMount = async () => {
    try {
      const { data } = await this.CommonService.getAlgoliaSecuredKey();
      const searchKey = data.getAlgoliaSecuredKey;
      const searchClient = algoliasearch(configForEnvironment(ENV).algoliaAppKey, searchKey);
      this.searchClient = searchClient;
      if (this.mounted) {
        this.setState({ searchKey, searchClient });
      }
    } catch (error) {
      Logger.error(error);
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  clearRefinementsComponent = ({ items, refine }) => {
    const { onClearRefinement } = this.props;
    if (onClearRefinement) onClearRefinement({ items, refine });
    return (
      <IconButton
        style={{ padding: 0, backgroundColor: 'transparent' }}
        aria-label="Close"
        onClick={() => {
          refine(items);
          this.props.handleChange('');
        }}
      >
        <CloseIcon
          style={{ color: this.props.theme.palette.grayscale(60), fontSize: 20 }}
          disabled={!items || (items && !items.length)}
        />
      </IconButton>
    );
  };

  renderResults = list => {
    let processedList = list;
    if (this.props.handleFilter) {
      processedList = this.props.handleFilter(list);
    }
    return (
      <List component="nav">
        {(processedList?.length > 0 &&
          processedList.map((hit, index) => (
            <Hit
              formProps={this.props}
              hit={hit}
              key={`searchPopperListItem-${hit.id}`}
              testingid={`searchresult-${index}`}
            />
          ))) || <Hit noResults noResultsMessage="No results" />}
      </List>
    );
  };

  searchResults = props => {
    const { searchState, searchResults } = props;
    const { onSearchResults, popoverProps = {} } = this.props;
    if (onSearchResults) return <>{onSearchResults(searchResults)}</>;
    return searchState && searchState.query ? (
      <Popper
        id="searchResults"
        testingid="searchResults"
        open={searchState !== ''}
        disablePortal={Boolean(this.props.locatedInPopUp)}
        anchorEl={this.inputRef.current}
        placement="bottom-start"
        transition
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={this.props.locatedInPopUp ? { zIndex: 5, width: 350 } : { zIndex: 5 }}
        modifiers={{
          preventOverflow: {
            boundariesElement: '#searchResults'
          }
        }}
        {...popoverProps}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={this.props.classes.popper}>
                {searchResults && this.renderResults(searchResults.hits)}
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    ) : null;
  };

  SearchBox = props => {
    const { currentRefinement, refine } = props;
    const {
      testingid,
      color,
      name,
      label,
      value,
      placeholder,
      errorMsg,
      customStyles,
      customInputProps,
      overrideVariant,
      isRequired,
      disableSearch = false
    } = this.props;
    const ClearRefinementsComponent = connectCurrentRefinements(this.clearRefinementsComponent);
    return (
      <>
        {label && <MUIFormComponents.LabelControl value={label} required={isRequired} />}
        <TextField
          color={color || 'default'}
          id={name}
          fullWidth
          variant={overrideVariant || 'outlined'}
          autoComplete="off"
          placeholder={placeholder}
          className={`${this.props.classes.inputRoot} ${this.props.inputClassNames || ''}`}
          value={value || currentRefinement}
          error={Boolean(errorMsg)}
          onChange={event => refine(event.currentTarget.value)}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            'data-testid': `text-` + testingid + `-create-payment`
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment className={this.props.iconClassNames || ''} position="start">
                <SearchIcon
                  style={{ color: this.props.theme.palette.grayscale(60), fontSize: 16 }}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment className={this.props.iconClassNames || ''} position="end">
                {!this.props.disabled && <ClearRefinementsComponent clearsQuery />}
              </InputAdornment>
            ),
            disabled: this.props.disabled || disableSearch,
            style: this.props.greyBackground
              ? {
                  color: this.props.theme.palette.grayscale(20),
                  backgroundColor: this.props.theme.palette.grayscale(94),
                  borderColor: this.props.theme.palette.grayscale(100),
                  padding: 8,
                  height: 36
                }
              : {},
            disableUnderline: this.props.greyBackground,
            ...customInputProps
          }}
          style={customStyles}
        />
        <MUIFormComponents.ErrorMessage value={errorMsg} />
      </>
    );
  };

  handleClickAway = () => this.props.handleChange('');

  render() {
    const { searchClient, searchKey } = this.state;
    const { searchIndex, name, filters, testingid } = this.props;

    const CustomSearchBox = connectSearchBox(this.SearchBox);
    const CustomResults = connectStateResults(this.searchResults);

    const orConditionArr = [];
    const andConditionArr = [];
    if (filters) {
      filters.forEach(filterKey => {
        const predicate = filterKey.negate ? 'NOT ' : '';
        if (filterKey.valueArray && filterKey.valueArray.length > 1) {
          if (filterKey.parenthetical) {
            const parenFilters = filterKey.attributeArray.map((attributeValue, index) => {
              return `${predicate}${attributeValue}:"${filterKey.valueArray[index]}"`;
            });
            andConditionArr.push(`(${parenFilters.join(` ${filterKey.operand} `)}) `);
          } else {
            filterKey.valueArray.forEach(filterValue => {
              orConditionArr.push(`${predicate}${filterKey.attribute}:"${filterValue}"`);
            });
          }
        }
        if (filterKey.valueArray && filterKey.valueArray.length === 1) {
          andConditionArr.push(`${predicate}${filterKey.attribute}:"${filterKey.valueArray[0]}"`);
        }
      });
    }

    const orConditionStr = orConditionArr.join(' OR ');
    if (orConditionStr) {
      andConditionArr.push(orConditionStr);
    }
    const filterStr = andConditionArr.join(' AND ');
    const { onLoading } = this.props;
    if (!searchKey || !searchClient) {
      onLoading(true);
      return <Typography>Loading...</Typography>;
    }
    onLoading(false);

    return (
      <InstantSearch indexName={searchIndex} searchClient={this.searchClient}>
        <CustomSearchBox />
        <div id={`alogliaSearchResults${name}`} ref={this.inputRef} />
        <CustomResults />
        {filterStr !== ' AND ' && <Configure filters={filterStr} />}
        {this.props.refineList && (
          <RefinementList
            list={this.props.refineList}
            onRefinementHandler={this.props.onRefinementHandler}
          />
        )}
      </InstantSearch>
    );
  }
}

AlgoliaSearchDropdown.propTypes = {
  onLoading: PropTypes.func
};

AlgoliaSearchDropdown.defaultProps = {
  onLoading: () => {}
};

export default withStyles(styles, { withTheme: true })(AlgoliaSearchDropdown);
