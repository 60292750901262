import gql from 'graphql-tag';
import useExtendedMutation from 'customHooks/useExtendedMutation';

const ADD_LABOUR_RATE_LINE_ITEMS_TO_VISIT = gql`
  mutation AddLabourRateLineItemsToVisit(
    $partitionKey: String
    $data: AddLabourRateLineItemsToVisitInput!
  ) {
    addLabourRateLineItemsToVisit(partitionKey: $partitionKey, data: $data) {
      id
      labourRateBillingHourLines {
        items {
          id
        }
      }
    }
  }
`;

const serializer = ({ tenantId, visitId, labourRateLineItems }) => ({
  variables: {
    partitionKey: tenantId,
    data: {
      visitId,
      labourRateLineItems
    }
  }
});

const update = (cache, { data: { addLabourRateLineItemsToVisit } }, ref) => {
  cache.modify({
    id: `Visit:${ref.visitId}`,
    fields: {
      labourRateLineItems(existingSummariesRef) {
        const newRefs = addLabourRateLineItemsToVisit.map(labourRateLineItem => {
          return {
            __ref: `${labourRateLineItem.__typename}:${labourRateLineItem.id}`
          };
        });

        return {
          items: [...existingSummariesRef.items, ...newRefs]
        };
      }
    }
  });
};

export const useAddLabourRateLineItemsToVisit = () => {
  return useExtendedMutation(ADD_LABOUR_RATE_LINE_ITEMS_TO_VISIT, {
    serializer,
    update
  });
};
