import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Box, Typography } from '@material-ui/core';
import useBillingHourTypes from 'customHooks/usePayrollBillingHourTypes';
import ProductSearch from 'components/AlgoliaSearch';
import { bundleIndex } from 'constants/algoliaIndex';
import styles from './styles';

const BillingTypeProductMapping = ({
  user,
  snackbarOn,
  field,
  form,
  defaultBillingProduct,
  ...props
}) => {
  const mappingArray = field.value || [];
  const { tenantId, tenantCompanyId } = user;
  const [billingHourTypes, , loaded] = useBillingHourTypes(tenantId, tenantCompanyId, snackbarOn);

  useEffect(() => {
    if (isEmpty(form.values) && !isEmpty(defaultBillingProduct) && loaded) {
      const initialProductValues = billingHourTypes.map(ht => ({
        billingHourTypeId: ht.id,
        product: defaultBillingProduct,
        productId: defaultBillingProduct.id
      }));
      form.setFieldValue('labourTypeBillingHourTypeProducts', initialProductValues);
    }
  }, [billingHourTypes, defaultBillingProduct, form, loaded]);

  const handleOnChange = (value, hourTypeId) => {
    const modifiedItem = mappingArray.find(item => item.billingHourTypeId === hourTypeId);
    if (modifiedItem) {
      modifiedItem.productId = value?.id;
      modifiedItem.product = value;
    } else {
      mappingArray.push({ productId: value?.id, product: value, billingHourTypeId: hourTypeId });
    }
    form.setFieldValue('labourTypeBillingHourTypeProducts', mappingArray);
  };

  return (
    <Box display="flex" flexDirection="column" paddingTop={3}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box width="25%" alignItems="left" paddingRight={2}>
          <Typography variant="caption" css={styles.greyCaption}>
            HOUR TYPE
          </Typography>
        </Box>
        <Box width="25%" alignItems="left" paddingRight={2}>
          <Typography variant="caption" css={styles.greyCaption}>
            PRODUCT
          </Typography>
        </Box>
      </Box>
      {billingHourTypes.map(hourType => {
        const existingValue = mappingArray.find(item => item.billingHourTypeId === hourType.id);
        return (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box key={`box1${hourType.id}`} width="25%" alignItems="left" paddingRight={2}>
              <Typography variant="body2">{hourType.hourTypeAbbreviation}</Typography>
            </Box>
            <Box key={`box2${hourType.id}`} width="25%" alignItems="left" paddingBottom={1}>
              <ProductSearch
                overrideVariant="standard"
                placeholder="Search"
                value={existingValue?.product?.name}
                greyBackground
                locatedInPopUp
                name={`productSearch${hourType.id}`}
                searchIndex={bundleIndex}
                delimiter="-"
                displayText={['name', 'description']}
                filters={[
                  {
                    attribute: 'entityType',
                    valueArray: ['Product']
                  }
                ]}
                handleChange={selectedItem => handleOnChange(selectedItem, hourType.id)}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default BillingTypeProductMapping;
