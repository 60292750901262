export const defaultMaintenanceData = {
  maintenanceTaskTemplates: [{ maintenanceTaskName: 'Scheduled Maintenance' }]
};

export const errorMessage = {
  deleteMaintenanceTemplate: 'Unable to delete maintenance template, please try again later',
  updateMaintenanceTemplate: 'Unable to update maintenance template, please try again later',
  createMaintenanceTemplate: 'Unable to create maintenance template, please try again later',
  getMaintenanceTemplate: 'Unable to get maintenance template, please try again later',
  getServiceAgreement: 'Unable to get service agreement, please try again later'
};
