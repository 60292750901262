import { round, roundCurrency } from '.';

// Note the PriceBook2 table has the entities rangedMaterialMarkupJSON and baseMaterialMarkup but some of the code in
// the settings/pricebooks scene uses the pricebook1 names materialMarkupJSON and markupValue.
// Once pricebook1 is phased out we can remove these conditionals.

export const getTotalMarkupRate = (pricebookMarkup, materialMarkup) => {
  return round((1 + pricebookMarkup / 100) * (1 + materialMarkup / 100) - 1, 8);
};

export const computeUnitPrice = (unitCost, pricebookMarkup, materialMarkup) => {
  const price = unitCost * (1 + getTotalMarkupRate(pricebookMarkup, materialMarkup));
  return roundCurrency(price);
};

export const getRateFromRange = (materialMarkupRanges, cost) => {
  if (materialMarkupRanges && materialMarkupRanges.length > 0) {
    return (
      materialMarkupRanges.find(m => m.start <= cost && (m.end === null || m.end >= cost))?.rate ||
      0
    );
  }

  return 0;
};

export const pb2OverrideArrayToMap = pricebook2EntryOverrides =>
  pricebook2EntryOverrides.reduce((map, obj) => {
    map.set(obj.productId, obj);
    return map;
  }, new Map());

export const getMaterialMarkup = (pricebook2, pricebook2EntryOverridesMap, productId, unitCost) => {
  // Check if there's an override for the product, use that if so.
  const override = pricebook2EntryOverridesMap.get(productId);
  if (override) {
    return override.materialMarkup;
  }

  // Otherwise find it in the range
  return getRateFromRange(
    pricebook2.rangedMaterialMarkupJSON || pricebook2.materialMarkupJSON,
    unitCost
  );
};

export const getTotalMarkup = (pricebook2, pricebook2EntryOverridesMap, productId, unitCost) => {
  const materialMarkup = getMaterialMarkup(
    pricebook2,
    pricebook2EntryOverridesMap,
    productId,
    unitCost
  );
  return getTotalMarkupRate(
    pricebook2.baseMaterialMarkup || pricebook2.markupValue,
    materialMarkup
  );
};

export const getUnitPrice = (pricebook2, pricebook2EntryOverridesMap, productId, unitCost) => {
  const materialMarkup = getMaterialMarkup(
    pricebook2,
    pricebook2EntryOverridesMap,
    productId,
    unitCost
  );

  return computeUnitPrice(
    unitCost,
    pricebook2.baseMaterialMarkup || pricebook2.markupValue,
    materialMarkup
  );
};
