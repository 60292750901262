import gql from 'graphql-tag';

const SyncClosedPeriod = gql`
  mutation syncClosedPeriod($partitionKey: String!, $data: SyncClosedPeriodInput!) {
    syncClosedPeriod(partitionKey: $partitionKey, data: $data) {
      affectedPaymentCount
      affectedInvoiceCount
      closeDate
      isActive
    }
  }
`;

export default SyncClosedPeriod;
