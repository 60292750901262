import React, { memo, useRef } from 'react';

import { withQueryStringStore } from '@dispatch/Dispatch.store';
import { useCrews, useDepartments, useDispatchTechs } from '@dispatch/queries';

import TechsFilterButton from './TechsFilterButton.component';

const mapQueryStringToProps = ({ filterBy, state: { departments, crews, technicians } }) => ({
  filterBy,
  departments,
  crews,
  technicians
});

const DataProvider = props => {
  const Memoized = useRef(memo(TechsFilterButton)).current;

  const departmentsResponse = useDepartments();
  const crewsResponse = useCrews();
  const techniciansResponse = useDispatchTechs();

  return (
    <Memoized
      {...props}
      departmentsResponse={departmentsResponse}
      crewsResponse={crewsResponse}
      techniciansResponse={techniciansResponse}
    />
  );
};

export default withQueryStringStore(mapQueryStringToProps)(DataProvider);
