import gql from 'graphql-tag';

const noteFragment = gql`
  fragment noteFields on Note {
    id
    partitionKey
    sortKey
    hierarchy
    entityType
    subject
    note
    lastUpdatedDate
    lastUpdatedDateTime
    lastUpdatedBy
  }
`;

const customerRepFragment = gql`
  ${noteFragment}

  fragment customerRepFields on CustomerRep {
    id
    tenantId
    tenantCompanyId
    partitionKey
    sortKey
    hierarchy
    entityType
    name
    firstName
    lastName
    email
    cellPhone
    landlinePhone
    isSmsOptOut
    bestContact
    contactRole
    version
    notes(entityConnection: "Note") {
      items {
        ...noteFields
      }
    }
  }
`;

const getPropertiesAndRepsByCustomer = gql`
  ${noteFragment}

  ${customerRepFragment}

  query getCustomer(
    $partitionKey: String!
    $sortKey: String!
    $billingCustomerId: String!
    $hasBillingCustomer: Boolean!
    $includeOurReps: Boolean!
  ) {
    getCustomer(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      customerName
      amountNotToExceed
      priceBookId
      priceBook {
        name
        id
      }
      customerProperties(
        entityConnection: "CustomerProperty"
        filter: { status: { ne: "inactive" } }
        limit: 10000
      ) {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          companyName
          amountNotToExceed
          customerPropertyTypeValue
          status
          billingCustomerId
          billingCustomer(entityConnection: "Customer") {
            customerName
          }
          customerReps(entityConnection: "CustomerRep") {
            items {
              id
              sortKey
              mappedEntity {
                ... on CustomerRep {
                  ...customerRepFields
                }
              }
            }
          }
          tenantReps(entityConnection: "Employee") @include(if: $includeOurReps) {
            items {
              id
              mappedEntity {
                ... on Employee {
                  id
                  firstName
                  lastName
                  name
                  status
                }
              }
            }
          }
        }
      }
      customerReps(entityConnection: "CustomerRep", filter: { status: { eq: "active" } }) {
        items {
          ...customerRepFields
        }
      }
      tenantReps(entityConnection: "Employee") @include(if: $includeOurReps) {
        items {
          id
          mappedEntity {
            ... on Employee {
              id
              firstName
              lastName
              name
              status
            }
          }
        }
      }
    }
    getBillingCustomerById: getCustomerById(id: $billingCustomerId)
      @include(if: $hasBillingCustomer) {
      id
      customerName
      customerReps(
        entityConnection: "CustomerRep"
        filter: { status: { eq: "active" } }
        limit: 100
      ) {
        items {
          ...customerRepFields
        }
      }
    }
  }
`;
export default getPropertiesAndRepsByCustomer;
