const styles = () => ({
  thumbnailContainer: {
    alignItems: 'center',
    display: 'flex',
    height: 36,
    justifyContent: 'center',
    marginRight: 12,
    width: 36
  },
  thumbnail: {
    maxHeight: '100%',
    maxWidth: '100%'
  }
});

export default styles;
