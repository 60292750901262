import gql from 'graphql-tag';

const getBillLineListForReviewReport = gql`
  query getBillLineListForReviewReport(
    $columnField: String!
    $operatorValue: String!
    $value: String!
    $visitId: String
  ) {
    getBillLineListForReviewReport(
      filter: { items: { columnField: $columnField, operatorValue: $operatorValue, value: $value } }
      visitId: $visitId
    ) {
      items
    }
  }
`;

export default getBillLineListForReviewReport;
