import React from 'react';
import NumberInput from './NumberInput';

const BillingDetailInput = ({ meta, isEditable, record, onEdit }) => {
  const { billingHourTypeId, dataKey } = meta;
  const { labourRateBillingHourLines } = record;

  const billingHourIndex = labourRateBillingHourLines.findIndex(
    ({ hourTypeId }) => hourTypeId === billingHourTypeId
  );
  const itemIndex = billingHourIndex >= 0 ? billingHourIndex : 0;
  const info = labourRateBillingHourLines?.[billingHourIndex] || {};
  const value = info[dataKey];

  const showPriceSymbol = dataKey === 'rate';

  const onEditHandler = currentValue => onEdit(dataKey, currentValue, itemIndex);

  return (
    <NumberInput
      value={value}
      isEditable={isEditable}
      onEditHandler={onEditHandler}
      showPriceSymbol={showPriceSymbol}
    />
  );
};

export default BillingDetailInput;
