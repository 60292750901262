import moment from 'moment';

import { dragOffset } from '@dispatch/dnd';
import { ElementSizes } from '@dispatch/Dispatch.styles';

import { selectBoardVisits } from '../../DispatchBoard.selectors';
import { sortByStart } from '../../DispatchBoard.utils';

const { techCellWidth, cellWidth } = ElementSizes;

export const selectSnappedOffset = (scrollOffset, cursorOffset, dragOffsetX) => {
  const timeOffset = scrollOffset + cursorOffset - techCellWidth - (dragOffsetX ?? dragOffset.x);
  return (Math.round((timeOffset / cellWidth) * 4) / 4) * cellWidth;
};

export const selectUnixFromOffset = (offset, day) => {
  const offsetInMin = (offset / cellWidth) * 60;

  return moment(day)
    .startOf('day')
    .add(offsetInMin, 'minutes')
    .unix();
};

export const selectTechEvents = ({ techVisits, techNonVisitEvents, techManDayItems }) => {
  const events = [...selectBoardVisits(techVisits), ...techNonVisitEvents, ...techManDayItems].sort(
    sortByStart
  );
  const positionMap = {};

  const selectPosition = event => {
    const positions = Object.keys(positionMap);

    for (let i = 0; i < positions.length; i += 1) {
      const positionRange = positionMap[i];
      if (!event.range.overlaps(positionRange)) return i;
    }

    return positions.length;
  };

  return events.map(event => {
    const position = selectPosition(event);

    if (!positionMap[position] || positionMap[position].end.isBefore(event.range.end)) {
      positionMap[position] = event.range;
    }

    return { ...event, position };
  });
};

export const selectLaneHeight = (i, itemData) => {
  if (i === 0) return ElementSizes.stickyHeaderHeight;

  let maxPosition = 0;

  if (itemData[i]?.techEvents) {
    itemData[i].techEvents.forEach(event => {
      if (event.position > maxPosition) maxPosition = event.position;
    });
  }

  return (maxPosition + 1) * ElementSizes.cellHeight;
};
