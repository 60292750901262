/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMachine } from '@xstate/react';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import Linkify from 'react-linkify';

import { Field, FieldType, ThemeProvider } from '@buildhero/sergeant';
import Context from 'components/Context';
import {
  CompanyService,
  TechnicianReportService,
  ReviewReportService,
  TimesheetsService
} from 'services/core';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Logger } from 'services/Logger';
import ErrorBoundaries from 'scenes/Error';
import Labels from 'meta/labels';
import invoiceLabels from 'meta/Jobs/Invoice/labels';
import { PageHeader, SectionHeader, Spinner, UserPermission } from 'components';
import FormSection from 'components/FormSection';
import SummaryLayout from 'meta/Jobs/Invoice/SummaryForm';
import PackingSlipLayout from 'meta/Jobs/Invoice/PackingSlipForm';
import { PathName, DisplayName } from 'utils/constants';
import { snackbarOn } from 'redux/actions/globalActions';
import { AppConstants, PermissionConstants, FeatureGateConstants } from 'utils/AppConstants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import {
  checkPermission,
  formatAddress,
  addressObjectToString,
  getTechniciansFromVisit,
  isTenantSettingEnabled,
  findLastUpdatedByAndDate
} from 'utils';

import TechNotes from '../components/TechnicianNotes';
import AssetsWorkedOn from '../components/AssetsWorkedOn';
import PhotosAndVideos from '../components/PhotosAndVideos';
import Signatures from '../components/Signature';
import PartsAndMaterials from '../components/PartsAndMaterials';
import PurchaseOrders from '../components/PurchaseOrders';
import Timesheet from '../components/Timesheet';
import TechSummary from '../components/TechSummary';
import TimeTracking from '../components/TimeTrackingTable';
import ManualTimeTracking from '../components/ManualTimetracking';
import styles from './styles';
import PageStateMachine from './state-model';

const TechnicalReport = props => {
  const companyService = new CompanyService();
  const technicianReportService = new TechnicianReportService();
  const reviewReportService = new ReviewReportService();
  const timesheetsService = new TimesheetsService();
  const flags = useFlags();

  const { classes, location, computedMatch, theme } = props;
  const techReportId = computedMatch?.params?.id;

  const lastUpdatedMap = new Map();

  const invoicingStateMachine = PageStateMachine.withContext({
    parentData: props.jobPageData,
    user: props.user,
    services: {
      companyService,
      technicianReportService,
      reviewReportService,
      timesheetsService,
      Logger
    },
    reduxActions: {
      snackbarOn: props.snackbarOn
    },
    reportSortKey: location?.state?.reportSortKey,
    reportId: techReportId,
    closeTechReport: props.backToJob,
    reviewReportId: '',
    handleReviewReport: props.handleReviewReport,
    history: props.history,
    summaryNotes: [],
    inventoryParts: [],
    attachments: [],
    discounts: [],
    purchaseOrders: [],
    modalRecord: {},
    modalRecordIndex: '',
    timeSheets: [],
    timeCardLines: [],
    timeCardVisits: [],
    notes: [],
    visitAssets: [],
    visitCreatedDate: '',
    visitNo: '',
    customerSignatures: [],
    departmentName: '',
    primaryTechs: [],
    lastUpdatedBy: '',
    showContent: false,
    timesheetEntries: [],
    nonVisitEvents: []
  });

  const [current, send] = useMachine(invoicingStateMachine);
  const [isSticky, setSticky] = useState(false);
  const oldReport = useRef();
  const sectionHeaderRef = useRef();
  const isAssetTrackingEnabled = isTenantSettingEnabled(FeatureGateConstants.ASSET_TRACKING);

  let isDynamicFormsEnabled = false;
  let showTotalBudgetedHours = false;
  if (Context.getCompanyContext() && Context.getCompanyContext().getCompany) {
    isDynamicFormsEnabled = isTenantSettingEnabled('dynamicForms');
    showTotalBudgetedHours = isTenantSettingEnabled('budgetLaborHoursAtJobLevel');
  }

  const handleScroll = () => {
    const position = sectionHeaderRef.current && sectionHeaderRef.current.getBoundingClientRect();
    if (position && position.top && position.top <= 128 && !isSticky) {
      setSticky(true);
    } else if (position && position.top && position.top > 128 && isSticky) {
      setSticky(false);
    }
  };

  useEffect(() => {
    if (oldReport && oldReport.current && oldReport.current !== props.reportId) {
      send('RELOAD', { reportId: props.reportId });
    }

    oldReport.current = props.reportId;
    send('LOADING');
    window.addEventListener('scroll', handleScroll);
  });

  const { context, value } = current;
  const {
    description,
    jobInfoData,
    visitNo,
    primaryTechs,
    extraTechs,
    lastUpdatedDateTime,
    submittedBy,
    submittedDate,
    timesheetEntries
  } = context;

  const reviewReport = context.reviewReports?.[0] || {};
  const propertyInfo = jobInfoData?.parentEntity || {};
  const customerInfo = jobInfoData?.parentEntity?.parentEntity || {};
  const billingCustomerInfo = jobInfoData?.billingCustomer || {};

  if (jobInfoData && jobInfoData.customIdentifier && visitNo) {
    document.title = `BuildOps - Tech report - Job #${jobInfoData.customIdentifier} - ${visitNo}`;
  }

  const summaryLayoutButtons = SummaryLayout.entity.layouts.web.buttons;
  summaryLayoutButtons.cancel.action = () => send('CLOSE');

  const packingSlipLayoutButtons = PackingSlipLayout.entity.layouts.web.buttons;
  packingSlipLayoutButtons.cancel.action = () => send('CLOSE');

  const arraysToScan = [context.inventoryParts, context.summaryNotes, context.notes];
  arraysToScan.forEach(items =>
    findLastUpdatedByAndDate(
      items,
      lastUpdatedMap,
      primaryTechs,
      lastUpdatedDateTime,
      'lastUpdatedBy'
    )
  );
  if (_.isEmpty(lastUpdatedMap) && primaryTechs?.items?.length) {
    const name = `${primaryTechs.items[0].mappedEntity.firstName} ${primaryTechs.items[0].mappedEntity.lastName}`;
    lastUpdatedMap.set(name, lastUpdatedDateTime);
  }

  let propertyAddresses = '';
  let billingAddress = '';
  let customerBillingAddressObj;

  if (propertyInfo && propertyInfo.companyAddresses) {
    propertyAddresses = propertyInfo.companyAddresses?.items || [];
    propertyAddresses =
      propertyAddresses && !_.isEmpty(propertyAddresses)
        ? propertyAddresses.find(address => address.addressType === 'propertyAddress')
        : null;
    propertyAddresses = propertyAddresses && formatAddress(propertyAddresses);
  }

  if (customerInfo && customerInfo.companyAddresses) {
    const customerAddresses = customerInfo.companyAddresses?.items || [];
    customerBillingAddressObj =
      customerAddresses && !_.isEmpty(customerAddresses)
        ? customerAddresses.find(address => address.addressType === 'billingAddress')
        : null;
    billingAddress = customerBillingAddressObj && formatAddress(customerBillingAddressObj);
  }

  if (billingCustomerInfo?.companyAddresses) {
    const bcAddresses = billingCustomerInfo.companyAddresses.items || [];
    const bcBillingAddress = bcAddresses
      .filter(address => address.addressType === 'billingAddress')
      .pop();
    billingAddress = addressObjectToString(bcBillingAddress);
  }

  if (jobInfoData && jobInfoData.closeoutReport) return null;

  return context.showContent ? (
    <ErrorBoundaries>
      <UserPermission I="read" action={PermissionConstants.OBJECT_VISIT}>
        {jobInfoData && jobInfoData.jobNumber && (
          <PageHeader
            title={`Technician Report for ${
              jobInfoData.jobTypeInternal === 'Maintenance'
                ? DisplayName.MAINTENANCE
                : DisplayName.JOB
            } ${jobInfoData.customJobNumber}`}
            breadcrumbsArray={[
              { title: 'Operations', link: '' },
              {
                title: Labels.technicianReport[props.user.locale],
                link: '/technicianreport/list'
              },
              {
                link:
                  jobInfoData.jobTypeInternal === 'Maintenance'
                    ? `/${PathName.MAINTENANCE}/view/${encodeURIComponent(
                        jobInfoData.jobNumber || ''
                      )}`
                    : `/${PathName.JOB}/view/${encodeURIComponent(jobInfoData.jobNumber || '')}`,
                title: `${
                  jobInfoData.jobTypeInternal === 'Maintenance'
                    ? DisplayName.MAINTENANCE
                    : DisplayName.JOB
                } ${jobInfoData.customJobNumber || ''}`
              }
            ]}
          />
        )}
        <Box
          display="flex"
          flexDirection="row"
          paddingBottom={2}
          className={isSticky ? classes.stickyStyle : ''}
        >
          <ThemeProvider>
            <Field
              value={`Report for ${
                jobInfoData.jobTypeInternal && jobInfoData.jobTypeInternal === 'Maintenance'
                  ? DisplayName.MAINTENANCE
                  : DisplayName.JOB
              } ${jobInfoData && jobInfoData.customJobNumber} (Visit ${context?.visitNo})`}
            />
            <Field value={`   |   `} color={theme.palette.grayscale(60)} />
            <Field type={FieldType.DATETIME} value={+context.visitScheduledFor * 1000} />
          </ThemeProvider>
        </Box>
        <Box className={isSticky ? classes.stickyButtons : ''}>
          <Grid container ref={sectionHeaderRef}>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <Grid container direction="column">
                {checkPermission('create', PermissionConstants.OBJECT_REVIEWREPORT, props.user) && (
                  <ErrorBoundaries>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => send('CREATE_REVIEW_REPORT')}
                        className={props.classes.pageActionButton}
                        disabled={
                          !['complete', 'on hold', 'closed'].includes(
                            (context.visitStatus || '').toLowerCase()
                          ) ||
                          (context.reviewReports && context.reviewReports.length > 0) ||
                          (value && value.review_report_new === 'saving')
                        }
                      >
                        Review
                        {value && value.review_report_new === 'saving' && (
                          <Spinner className={props.classes.buttonProgress} size={24} />
                        )}
                      </Button>
                    </Grid>
                  </ErrorBoundaries>
                )}
                <UserPermission I="view" action={PermissionConstants.OBJECT_REVIEWREPORT}>
                  <ErrorBoundaries>
                    <Grid item style={{ marginLeft: 20 }}>
                      {context.reviewReports && context.reviewReports.length > 0 && (
                        <Typography className={classes.linkPtag}>
                          <Link
                            to={{
                              pathname: `/reviewreport/view/${context.reviewReports[0].id}`
                            }}
                            key="reportLink"
                            className={classes.linkText}
                          >
                            Reviewed Report
                          </Link>
                        </Typography>
                      )}
                    </Grid>
                  </ErrorBoundaries>
                </UserPermission>
                <ErrorBoundaries>
                  <Grid item>
                    {reviewReport?.createdBy && (
                      <>
                        <Typography className={classes.auditText}>
                          Technician report submitted by:
                        </Typography>
                        <Typography className={classes.auditTextValue}>
                          {` ${reviewReport.createdBy}, ${reviewReport?.createdDateTime &&
                            moment(+reviewReport.createdDateTime).format(
                              AppConstants.DATETIME_FORMAT
                            )}
                      `}
                        </Typography>
                      </>
                    )}
                    <Typography className={classes.auditText}>
                      Technician report created by:
                    </Typography>

                    {Array.from(lastUpdatedMap).map(([key, values]) => (
                      <Typography className={classes.auditText} key={key}>
                        {`${submittedBy || key}, ${moment
                          .unix(submittedDate || parseInt(values, 10) / 1000)
                          .format(AppConstants.DATETIME_FORMAT)}`}
                      </Typography>
                    ))}
                  </Grid>
                </ErrorBoundaries>
              </Grid>
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10} style={{ paddingLeft: 50 }}>
              <ThemeProvider>
                <Grid container className={classes.reportInfoRow} spacing={2}>
                  <Grid item xs={2}>
                    <Field
                      label="Customer:"
                      type={FieldType.TEXT}
                      value={customerInfo.customerName}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Field
                      label="Property:"
                      type={FieldType.TEXT}
                      value={propertyInfo.companyName}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      label="Billing address:"
                      type={FieldType.TEXT}
                      value={billingAddress ?? '-'}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      label="Property address:"
                      type={FieldType.TEXT}
                      value={propertyAddresses ?? '-'}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Field
                      label="Project manager:"
                      type={FieldType.TEXT}
                      value={jobInfoData?.owner?.name ?? '-'}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Field
                      label="Technician(s):"
                      type={FieldType.TEXT}
                      value={getTechniciansFromVisit(primaryTechs, extraTechs) ?? '-'}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      label="Bill To:"
                      type={FieldType.TEXT}
                      value={propertyInfo?.billTo ?? customerBillingAddressObj?.billTo ?? '-'}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      label="Billing Customer:"
                      type={FieldType.TEXT}
                      value={billingCustomerInfo?.customerName ?? '-'}
                    />
                  </Grid>
                  {showTotalBudgetedHours && (
                    <Grid item xs={2}>
                      <Field
                        label={`Total Budgeted Hours for ${jobInfoData.jobTypeInternal || 'Job'}`}
                        type={FieldType.TEXT}
                        value={jobInfoData.totalBudgetedHours}
                      />
                    </Grid>
                  )}
                </Grid>
              </ThemeProvider>
              <Linkify>
                <Grid>
                  <Typography className={classes.sectionTitle}>Job description</Typography>
                  <Divider variant="fullWidth" classes={{ root: classes.blueDivider }} />
                  <Typography className={classes.addresses}>
                    {jobInfoData?.issueDescription ?? '-'}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography className={classes.sectionTitle}>Visit description</Typography>
                  <Divider variant="fullWidth" classes={{ root: classes.blueDivider }} />
                  <Typography className={classes.addresses}>{description ?? '-'}</Typography>
                </Grid>
                <Typography className={classes.sectionTitle}>Visit summary</Typography>
                <Divider variant="fullWidth" classes={{ root: classes.blueDivider }} />
                <SectionHeader />
                {context.summaryNotes.map(item => (
                  <TechSummary summary={item} key={item.id} />
                ))}
                <TechNotes current={current} send={send} classes={classes} />
                {isAssetTrackingEnabled && (
                  <AssetsWorkedOn classes={classes} current={current} send={send} />
                )}
              </Linkify>
              {isDynamicFormsEnabled && jobInfoData?.sortKey && (
                <Grid>
                  <Typography className={classes.sectionTitle}>Forms</Typography>
                  <Divider variant="fullWidth" classes={{ root: classes.blueDivider }} />
                  {context.visitNo && (
                    <FormSection
                      parent={{
                        entityType: 'Job',
                        sortKey: jobInfoData.sortKey,
                        id: jobInfoData.id
                      }}
                      filter={{
                        integerFilters: [
                          { fieldName: 'Visit.VisitNumber', filterInput: { eq: context.visitNo } }
                        ]
                      }}
                      caslKey={[PermissionConstants.OBJECT_JOB]}
                      readOnly
                      hideHeader
                    />
                  )}
                </Grid>
              )}
              <PhotosAndVideos classes={classes} current={current} send={send} />
              <Signatures classes={classes} current={current} send={send} user={props.user} />
              <Grid
                item
                style={{
                  paddingTop: 50,
                  paddingBottom: 30,
                  paddingLeft: 5
                }}
              >
                {context.departmentName && (
                  <Chip
                    label={context.departmentName}
                    className={classes.departmentsChips}
                    key={context.id}
                  />
                )}
              </Grid>
              <PartsAndMaterials
                classes={classes}
                current={current}
                send={send}
                user={props.user}
              />
              <Typography className={classes.greySectionTitle}>
                {invoiceLabels.purchasedItems[props.user.locale]}
              </Typography>
              <PurchaseOrders
                classes={classes}
                current={current}
                send={send}
                user={props.user}
                isProcurementEnabled={flags[FeatureFlags.PROCUREMENT]}
              />
              <Typography className={classes.sectionTitle}>
                {invoiceLabels.labour[props.user.locale]}
              </Typography>
              <Divider variant="fullWidth" classes={{ root: classes.blueDivider }} />

              {/* <TimeTracking /> component is only for automatic timesheets, in manual returns null */}
              <TimeTracking timesheetEntries={timesheetEntries} context={context} />

              {/* <ManualTimeTracking /> and <Timesheet /> is only for manual timesheets, in automatic returns null */}
              <ManualTimeTracking context={context} />
              <Timesheet classes={classes} current={current} user={props.user} />
            </Grid>
          </Grid>
        </Box>
      </UserPermission>
    </ErrorBoundaries>
  ) : (
    <Spinner />
  );
};

export const styledTechnicalReport = withStyles(styles, { withTheme: true })(TechnicalReport);

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = { snackbarOn };

const connectedTechnicalReport = connect(
  mapStateToProps,
  mapDispatcherToProps
)(styledTechnicalReport);

export default connectedTechnicalReport;
