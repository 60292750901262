export const PaymentTypeColumns = isIntegrated =>
  [
    {
      id: 'name',
      maxTextLen: 30,
      label: 'Payment Type'
    },
    isIntegrated && {
      id: 'ledgerAccount.name',
      maxTextLen: 30,
      label: 'GL Account'
    }
  ].filter(Boolean);

export const PaymentTypeLayout = (
  isIntegrated,
  isSageEnabled,
  isInacctEnabled,
  glAccountOptions
) => ({
  fields: {
    name: {
      default: 'name',
      edit: 'name'
    },
    ledgerAccountId: {
      default: 'ledgerAccountId',
      edit: 'ledgerAccountId'
    }
  },
  layouts: {
    edit: {
      options: {
        pageSize: 'LETTER',
        direction: 'row',
        grow: 1,
        alignItems: 'center'
      },
      contents: [
        {
          options: {
            direction: 'column',
            marginRight: isIntegrated ? 10 : 0
          },
          contents: [
            {
              options: {
                label: 'Payment Type',
                isRequired: true
              },
              component:
                isIntegrated && !isSageEnabled && !isInacctEnabled ? 'FieldWithLabel' : 'TextInput',
              source: 'name'
            }
          ]
        },
        isIntegrated &&
          !isInacctEnabled && {
            options: {
              direction: 'column'
            },
            contents: [
              {
                options: {
                  label: 'GL Account',
                  isRequired: true,
                  placeholder: 'Select...',
                  inputOptions: glAccountOptions
                },
                component: 'SelectInput',
                source: 'ledgerAccountId'
              }
            ]
          }
      ].filter(Boolean)
    }
  },
  validation: {
    type: 'object',
    properties: {
      name: {
        type: 'string'
      }
    },
    required: ['name']
  },
  validationErrors: {
    name: {
      required: 'Name is required.'
    }
  }
});

export default PaymentTypeLayout;
