import { useMemo } from 'react';
import Context from 'components/Context';

export const getTrucksFromEmployeeList = techArray => {
  const availableTrucks = [];
  techArray.forEach(tech => {
    const truckEmployeesArray = tech?.mappedEntity?.truckEmployeeView?.items || [];
    truckEmployeesArray.forEach(truckEmp => {
      // when the is active, add to the list. Truck delete is a soft delete
      if (truckEmp?.parentEntity?.isActive) {
        availableTrucks.push(truckEmp.parentEntity);
      }
    });
  });
  return availableTrucks;
};

export const useAvailableTrucks = ({ primaryTechs, extraTechs } = {}) => {
  return useMemo(() => {
    const primaryTechsTrucks = getTrucksFromEmployeeList(primaryTechs?.items || []);
    const extraTechsTrucks = getTrucksFromEmployeeList(extraTechs?.items || []);

    return [...primaryTechsTrucks, ...extraTechsTrucks];
  }, [primaryTechs, extraTechs]);
};

export const useGetProductFilter = ({ job }) => {
  return useMemo(() => {
    return {
      entityType: ['Product']
    };
  }, [job]);
};
