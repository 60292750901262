// must be old function type for correct lexical binding of this
/* eslint-disable func-names */
import Enum from 'enum';
import { omit } from 'lodash';
import { oneOf } from 'prop-types';

Enum.prototype.assign = function(map) {
  return new Enum({ ...this.toJSON(), ...map });
};

Enum.prototype.assignLeft = function(map) {
  return new Enum({ ...map, ...this.toJSON() });
};

Enum.prototype.getBy = function(field, value) {
  const foundEnum = this.enums.find(item => item.value[field] && item.value[field] === value);

  if (foundEnum) return { key: foundEnum.key, ...foundEnum.value };
};

Enum.prototype.keyMap = function() {
  return Object.keys(this.toJSON()).reduce(
    (keyMap, key) => ({
      ...keyMap,
      [key]: key
    }),
    {}
  );
};

Enum.prototype.keys = function() {
  return Object.keys(this.toJSON());
};

Enum.prototype.omit = function(keys) {
  return new Enum(omit(this.toJSON(), keys));
};

Enum.prototype.propType = function() {
  return oneOf(this.keys());
};

Enum.prototype.values = function() {
  const enumJson = this.toJSON();

  return Object.keys(enumJson).map(key => ({
    key,
    ...enumJson[key]
  }));
};

export default Enum;
