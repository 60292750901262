import * as Actions from '../constants';

export const logout = () => ({
  type: Actions.LOGOUT
});

export const spinnerOn = () => ({
  type: Actions.SPINNER_ON,
  spinner: true
});

export const spinnerOff = () => ({
  type: Actions.SPINNER_OFF,
  spinner: false
});

export const setPageMode = mode => ({
  type: Actions.PAGE_MODE,
  pageMode: mode
});

export const snackbarOn = (mode, message, errorLog) => ({
  type: Actions.SNACKBAR_ON,
  pageNotification: [mode, message, errorLog]
});

export const snackbarOff = () => ({
  type: Actions.SNACKBAR_OFF,
  pageNotification: ['off', '']
});

export const alertOn = (title, message, buttonArray, callBackArray) => ({
  type: Actions.ALERT_ON,
  alert: {
    title,
    message,
    buttonArray,
    callBackFunctions: callBackArray
  }
});

export const alertOff = () => ({
  type: Actions.ALERT_OFF,
  alert: { title: null, message: null, buttonArray: [], callBackFunctions: [] }
});

export const setAppName = appName => ({
  type: Actions.APP_MENU,
  menu: appName
});

export const menuOpen = open => ({
  type: Actions.MENU_OPEN,
  isOpen: open
});

export const setSettings = settings => ({
  type: Actions.SET_SETTINGS,
  settings
});

export const updateSetting = setting => ({
  type: Actions.UPDATE_SETTING,
  setting
});

export const setUserTenantSettings = tenantSettings => ({
  type: Actions.SET_USER_TENANT_SETTINGS,
  tenantSettings
});

export const updateUserTenantSetting = tenantSetting => ({
  type: Actions.UPDATE_USER_TENANT_SETTING,
  tenantSetting
});

export const setOptions = options => ({
  type: Actions.SET_OPTIONS,
  options
});

export const setCompany = company => ({
  type: Actions.SET_COMPANY,
  company
});

export const setCompanySettings = companySettings => ({
  type: Actions.SET_COMPANY_SETTINGS,
  companySettings
});

export const setOpenQuickAddModal = (openQuickAddModal, data = {}, onSubmit) => ({
  type: Actions.OPEN_QUICK_ADD_MODAL,
  openQuickAddModal: { type: openQuickAddModal, data, onSubmit }
});

export const setLaunchDarklyFlags = state => ({
  type: Actions.SET_LD_FLAGS,
  launchDarklyFlags: state
});

export const showMutationConfirmation = (payload = {}) => ({
  type: Actions.SHOW_MUTATION_CONFIRMATION,
  payload
});

export const hideMutationConfirmation = () => ({
  type: Actions.HIDE_MUTATION_CONFIRMATION
});
