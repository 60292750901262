const styles = theme => ({
  selected: {
    backgroundColor: theme.palette.grayscale(100)
  },
  menuItem: {
    fontSize: 14,
    fontWeight: 'bold',
    backgroundColor: theme.palette.grayscale(100),
    borderLeft: '8px solid',
    borderColor: theme.palette.grayscale(100),
    color: theme.palette.brand.logoBlue,
    '&$selected': {
      backgroundColor: theme.palette.grayscale(100),
      borderLeft: '8px solid',
      borderColor: theme.palette.brand.logoGreen
    }
  },
  iconColor: {
    color: theme.palette.brand.logoGreen
  }
});
export default styles;
