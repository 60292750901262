import gql from 'graphql-tag';

const updateTimeCard = gql`
  mutation updateTimeCard($partitionKey: String, $data: UpdateTimeCardInput!) {
    updateTimeCard(partitionKey: $partitionKey, data: $data) {
      id
    }
  }
`;

export default updateTimeCard;
