import React, { memo, useRef } from 'react';

import compose from 'utils/compose';

import { withDispatchActions, withQueryStringStore } from '@dispatch/Dispatch.store';
import useBillableNonVisitEventDetails from '@dispatch/queries/useBillableNonVisitEventDetails';
import useDeleteBillableNonVisit from '@dispatch/mutations/useDeleteBillableNonVisit';
import useUpdateBillableNonVisit from '@dispatch/mutations/useUpdateBillableNonVisit';

import EditBillableEvent from './EditBillableEvent.component';

const mapActionsToProps = ({ closeDrawer }) => ({
  closeDrawer
});

const mapQueryStringToProps = store => ({
  billableEventId: store.state.billableEventId
});

const DataProvider = props => {
  const Memoized = useRef(memo(EditBillableEvent)).current;

  // eslint-disable-next-line react/prop-types
  const { billableEventId } = props;
  const billableNonVisitEventDetailsResponse = useBillableNonVisitEventDetails(billableEventId);
  const deleteBillableNonVisitTuple = useDeleteBillableNonVisit();
  const updateBillableNonVisitTuple = useUpdateBillableNonVisit();

  return (
    <Memoized
      {...props}
      billableNonVisitEventDetailsResponse={billableNonVisitEventDetailsResponse}
      deleteBillableNonVisitTuple={deleteBillableNonVisitTuple}
      updateBillableNonVisitTuple={updateBillableNonVisitTuple}
    />
  );
};

export default compose(
  withDispatchActions(mapActionsToProps),
  withQueryStringStore(mapQueryStringToProps)
)(DataProvider);
