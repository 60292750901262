import gql from 'graphql-tag';

const addProductEntriesToBundle = gql`
  mutation addProductBundleEntriesToProductBundle(
    $partitionKey: String!
    $data: AddProductBundleEntriesToProductBundleInput!
  ) {
    addProductBundleEntriesToProductBundle(partitionKey: $partitionKey, data: $data) {
      id
    }
  }
`;
export default addProductEntriesToBundle;
