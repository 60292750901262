/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { MUIForm } from '@buildhero/sergeant';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  formatDateInTimezone,
  getCurrentDateInTimezone,
  getNoteSectionsDataFromFormData
} from '@pm/components/utils';
import {
  createProjectTimesheetsBatch,
  projectTimesheetsUrl,
  updateProjectTimesheet,
  useFetchProjectTimesheets
} from '@pm/ProjectDetails/FieldReport/DailyReportList/DailyReport.api';
import { useSnackbar } from 'customHooks/useSnackbar';
import {
  addDailyReportNote,
  addDailyReportNoteSection,
  dailyReportChange,
  dailyReportCreate,
  getNextNumber,
  updateDailyReportNoteSection
} from 'services/API/dailyReport';

import ReportDateInput from './components/ReportDateInputField';
import { useStyles } from './DailyReportsForm.styles';
import {
  doesEmployeeHaveApprovedOrPendingTimesheets,
  getTimesheetKey,
  getTimesheetStatus,
  getUniqTimesheetsMap,
  serializeProjectTimesheets
} from './DailyReportsForm.utils';
import { generalInformationValidationSchema } from './DailyReportsForm.validationSchema';
import EquipmentFieldForm from './EquipmentFieldForm';
import LaborFieldForm from './LaborFieldForm';
import { getGeneralInformationLayout } from './layout';
import MaterialFieldForm from './MaterialFieldForm';
import NotesFieldForm from './NotesFieldForm';
import ProductivityFieldForm from './ProductivityFieldForm';
import SignatureFieldForm from './SignatureFieldForm';
import SubcontractorFieldForm from './SubcontractorFieldForm';
import {
  formatCommonForSave,
  formatGeneralInfoForSave,
  formatMaterialsForSave,
  formatNotesForSave,
  formatProductivityForSave
} from './utils';

let attachmentData = [];

const DailyReportsForm = props => {
  const {
    data,
    mode,
    dailyReportId,
    dailyReportDates,
    onSubmitComplete,
    onSubmitting,
    loading,
    loadingReport,
    open,
    getHandleCreateService,
    getHandleComplete,
    setOnSubmitFinal,
    getHandleRemoveService,
    setIsValidTimesheets
  } = props;
  const { tenantId, tenantCompanyId } = useSelector(state => state?.user) || {};
  const { projectId, id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [nextNumber, setNextNumber] = useState('');
  const [projectTimesheets, setProjectTimesheets] = useState([]);
  const projectTimesheetsRef = useRef([]);
  projectTimesheetsRef.current = projectTimesheets;
  const dailyReportDatesRef = useRef();
  dailyReportDatesRef.current = dailyReportDates;

  const snackbar = useSnackbar();

  const updateProjectTimesheets = useCallback((updatedTimesheets, allTimesheets) => {
    const timesheetsMap = getUniqTimesheetsMap(updatedTimesheets);
    setProjectTimesheets(
      R.map(item => {
        const totalDuration = R.sum(
          R.map(entry => entry?.duration ?? 0, item?.timesheetEntries || [])
        );
        const matchingTimesheetId = timesheetsMap[getTimesheetKey(item)];
        const isDuplicated =
          matchingTimesheetId !== item?.id &&
          matchingTimesheetId !== item?.key &&
          totalDuration > 0;
        const isValid = Boolean(
          item?.projectPhaseId &&
            item?.projectPhaseDepartmentId &&
            item?.projectPhaseDepartmentCostCodeId
        );
        return {
          ...item,
          isDuplicated,
          isValid,
          isDisabled: doesEmployeeHaveApprovedOrPendingTimesheets(item?.employeeId, allTimesheets),
          timesheetStatus: getTimesheetStatus(item)
        };
      }, updatedTimesheets || []) || []
    );
  }, []);

  useEffect(() => {
    const noDuplicatedTimesheets = R.not(R.any(R.prop('isDuplicated'))(projectTimesheets));
    const allTimesheetsValid = R.all(item => item?.isValid || item?.isDisabled)(projectTimesheets);
    setIsValidTimesheets(noDuplicatedTimesheets && allTimesheetsValid);
  }, [projectTimesheets, setIsValidTimesheets]);

  const timezone = useSelector(s => s.pm.timezone.timezone);
  const timezoneRef = useRef();
  timezoneRef.current = timezone;

  const currDate = useMemo(() => (timezone ? getCurrentDateInTimezone(timezone) : undefined), [
    timezone
  ]);

  const minDate = Number(useSelector(val => val?.pm?.project?.dateStart));
  const date = data?.id ? data?.date : currDate;

  const [reportDate, setReportDate] = useState();
  useEffect(() => {
    setReportDate(date);
  }, [date]);

  const handleDateChange = fields => {
    setReportDate(fields?.date);
  };

  const fetchDateRef = useRef();
  fetchDateRef.current = formatDateInTimezone(reportDate, timezone);

  const [
    { loading: loadingProjectTimesheets, data: projectTimesheetsData },
    fetchProjectTimesheets
  ] = useFetchProjectTimesheets(
    projectTimesheetsUrl(projectId, fetchDateRef.current, dailyReportId),
    {
      skip: loadingReport || !reportDate || !open,
      useCache: false
    }
  );

  const allTimesheets = useMemo(
    () =>
      projectTimesheetsData?.map(item => ({
        ...item,
        timesheetStatus: getTimesheetStatus(item)
      })) || [],
    [projectTimesheetsData]
  );

  useEffect(() => {
    updateProjectTimesheets(
      allTimesheets?.filter(
        item =>
          (item?.dailyReportId === dailyReportId || !item?.dailyReportId) &&
          item?.projectId === projectId
      ) || [],
      allTimesheets
    );
  }, [allTimesheets, updateProjectTimesheets, dailyReportId, projectId]);

  const formatProductivityData = drId =>
    R.pipe(R.omit(['index', 'serviceName', 'phase']), R.mergeLeft({ dailyReportId: drId }));

  const getFormattedPayload = (formData, drId) => {
    const payload = {};
    // making data of each section
    payload.materials = Object.values(formData.materials ?? {}).map(item => ({
      ...item,
      dailyReportId: drId
    }));
    payload.equipment = Object.values(formData.equipment ?? {}).map(item => {
      delete item.index;
      delete item.serviceName;
      return { ...item, dailyReportId: drId };
    });
    payload.subcontractors = Object.values(formData.subcontractors ?? {}).map(item => {
      delete item.index;
      delete item.serviceName;
      return { ...item, dailyReportId: drId };
    });
    payload.customerSignatures = Object.values(formData.customerSignatures ?? {}).map(item => {
      delete item.index;
      return { ...item, dailyReportId: drId };
    });
    payload.foremanSignatures = Object.values(formData.foremanSignatures ?? {}).map(item => {
      delete item.index;
      return { ...item, dailyReportId: drId };
    });

    const formatProductivityDataWithId = formatProductivityData(drId);
    payload.ProductivityPhases = Object.values(formData.productivity ?? {}).map(
      formatProductivityDataWithId
    );

    const totalHours = R.pipe(
      R.map(item => item.timesheetEntries),
      R.flatten,
      R.map(item => (item.duration ? Math.round(item.duration / 3600) : 0)),
      R.sum
    )(formData?.projectTimesheets || []);

    payload.totalHours = totalHours;

    return payload;
  };

  const setAttachmentData = newValue => {
    attachmentData = newValue;
  };

  const onSubmitFinal = async submittedData => {
    let newDailyReportId;
    onSubmitting(true);

    let noteSections = [];

    try {
      if (!dailyReportId) {
        // to create a new daily report with only general Information
        const newDailyReport = await dailyReportCreate({ ...submittedData.generalInfo, projectId });
        newDailyReportId = newDailyReport.id;

        // add note
        const newDailyReportNote = await addDailyReportNote({ dailyReportId: newDailyReportId });

        // add sections
        noteSections = getNoteSectionsDataFromFormData(submittedData, newDailyReportNote.id);
        await Promise.all(noteSections.map(section => addDailyReportNoteSection(section)));
      } else {
        // to update an existing daily report
        newDailyReportId = dailyReportId;

        // it's possible, due to bad data, we have a daily report but no dailyReportNote record - add here if needed
        let noteId = submittedData?.notes?.['notes-0']?.id;
        if (!noteId) {
          const noteRecord = await addDailyReportNote({ dailyReportId: newDailyReportId });
          noteId = noteRecord.id;
        }

        noteSections = getNoteSectionsDataFromFormData(submittedData, noteId);
      }

      const dailyReportDate = submittedData.generalInfo.date;
      const serializedProjectTimesheets = serializeProjectTimesheets({
        projectTimesheets: projectTimesheetsRef.current,
        dailyReportDate
      });

      // update a daily report using other section Information
      if (newDailyReportId) {
        const payload = getFormattedPayload(
          { ...submittedData, projectTimesheets: projectTimesheetsRef.current },
          newDailyReportId
        );

        const [existingTimesheets, newTimesheets] = R.partition(
          R.prop('id'),
          serializedProjectTimesheets
        );

        await Promise.all([
          ...(newTimesheets.length
            ? [
                createProjectTimesheetsBatch(
                  projectTimesheetsUrl(projectId),
                  {
                    dailyReportId: newDailyReportId,
                    dailyReportDate,
                    timesheets: newTimesheets
                  },
                  {
                    tenantId,
                    tenantCompanyId
                  }
                )
              ]
            : []),
          ...existingTimesheets
            .filter(item => !item?.isDisabled)
            .map(item =>
              updateProjectTimesheet(
                projectTimesheetsUrl(projectId, item.id),
                {
                  ...item,
                  dailyReportId: item?.dailyReportId || newDailyReportId,
                  dailyReportDate
                },
                {
                  tenantId,
                  tenantCompanyId
                }
              )
            )
        ]);

        await dailyReportChange(newDailyReportId, {
          id: newDailyReportId,
          ...{ ...submittedData.generalInfo, ...payload }
        });

        await fetchProjectTimesheets({
          url: projectTimesheetsUrl(projectId, fetchDateRef.current, dailyReportId)
        });

        await Promise.all(
          noteSections.map(section => {
            if (section.id) {
              return updateDailyReportNoteSection(section.id, section);
            }

            // mobile only creates section records for sections that have data
            // thus we need to create new note section record
            if (dailyReportId) {
              return addDailyReportNoteSection(section);
            }

            return Promise.resolve();
          })
        );
      }
    } catch (error) {
      snackbar('error', error?.response?.data || 'Can not save report');
    }

    onSubmitting(false);
    onSubmitComplete();
    if (!id && newDailyReportId) {
      history.push(`/project/view/${projectId}/fieldreport/dailyreport/${newDailyReportId}`);
    }
  };

  const handleFormAttachments = (list, selectedImages, selectedFiles, setAttachments) => {
    const remainingAttachments = [];
    let i = 0;
    while (i < attachmentData.length) {
      const currAttachment = attachmentData[i];
      const isImage = selectedImages.find(element => currAttachment.fileUrl === element.url);
      const isFile = selectedFiles.find(element => currAttachment.fileUrl === element.url);
      if (isFile || isImage) {
        remainingAttachments.push(currAttachment);
      } else {
        remainingAttachments.push({
          ...currAttachment,
          deletedDate: moment().unix(),
          deletedDateTime: moment().valueOf(),
          _deleted: true
        });
      }
      if (isFile) {
        selectedFiles.splice(selectedFiles.indexOf(isFile), 1);
      }
      if (isImage) {
        selectedImages.splice(selectedImages.indexOf(isImage), 1);
      }
      i += 1;
    }
    i = 0;
    const ret = [];
    while (i < list.length) {
      if (list[i].fileName && list[i].fileType && list[i].fileUrl) {
        ret.push({
          fileUrl: list[i].fileUrl || null,
          fileType: list[i].fileType || null,
          fileName: list[i].fileName || null
        });
      }
      i += 1;
    }
    const retAttach = ret.concat(remainingAttachments);
    setAttachmentData(retAttach);
    setAttachments(retAttach);
  };

  const handleProjectTimesheetsChange = newProjectTimesheets => {
    updateProjectTimesheets(newProjectTimesheets, allTimesheets);
  };

  useEffect(() => {
    if (mode === 'edit')
      setAttachmentData(
        data?.DailyReportAttachment?.filter(item => item.deletedDate === null) || []
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data?.DailyReportAttachment)]);

  useEffect(() => {
    if (open) {
      setOnSubmitFinal(onSubmitFinal);
    } else {
      setAttachmentData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    getNextNumber(projectId).then(number => setNextNumber(number));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = generalInformationValidationSchema({
    dailyReportDatesRef,
    minDate,
    timezoneRef
  });

  return (
    <div className={classes.root}>
      <div className={classes.formSectionContainer}>
        <Typography className={classes.formTitle}>General Information</Typography>
        <div className={classes.formContainer}>
          <MUIForm
            configuration={getGeneralInformationLayout({
              loading: loading || loadingProjectTimesheets,
              timezone
            })}
            data={{
              number: data?.id ? data?.number : nextNumber,
              date
            }}
            layout="default"
            onCreateService={getHandleCreateService('generalInfo')}
            onComplete={getHandleComplete('generalInfo', formatGeneralInfoForSave)}
            validationSchema={validationSchema}
            onFormChange={handleDateChange}
            customComponents={{ ReportDateInput }}
          />
        </div>
      </div>
      <div className={classes.formSectionContainer}>
        <NotesFieldForm
          initialData={data || {}}
          mode={mode}
          loading={loading}
          onCreateService={getHandleCreateService}
          onComplete={getHandleComplete}
          formatFuncForSave={formatNotesForSave}
          attachments={attachmentData}
          handleFormAttachments={handleFormAttachments}
        />
      </div>
      <div className={classes.formSectionContainer}>
        <LaborFieldForm
          allTimesheets={allTimesheets}
          projectTimesheets={projectTimesheets}
          projectId={projectId}
          loading={loading}
          onFormDataChange={handleProjectTimesheetsChange}
        />
      </div>
      <div className={classes.formSectionContainer}>
        <ProductivityFieldForm
          initialData={data?.ProductivityPhases || []}
          projectId={projectId}
          onCreateService={getHandleCreateService}
          onComplete={getHandleComplete}
          onDeleteService={getHandleRemoveService}
          formatFuncForSave={formatProductivityForSave}
          loading={loading}
        />
      </div>
      <div className={classes.formSectionContainer}>
        <MaterialFieldForm
          initialData={data?.materials || []}
          onCreateService={getHandleCreateService}
          onComplete={getHandleComplete}
          onDeleteService={getHandleRemoveService}
          formatFuncForSave={formatMaterialsForSave}
          loading={loading}
        />
      </div>
      <div className={classes.formSectionContainer}>
        <EquipmentFieldForm
          initialData={data?.equipment || []}
          onCreateService={getHandleCreateService}
          onComplete={getHandleComplete}
          onDeleteService={getHandleRemoveService}
          formatFuncForSave={formatCommonForSave}
          loading={loading}
        />
      </div>
      <div className={classes.formSectionContainer}>
        <SubcontractorFieldForm
          initialData={data?.subcontractors || []}
          onCreateService={getHandleCreateService}
          onComplete={getHandleComplete}
          onDeleteService={getHandleRemoveService}
          formatFuncForSave={formatCommonForSave}
          loading={loading}
        />
      </div>
      <div className={classes.formSectionContainer}>
        <SignatureFieldForm
          initialData={data?.customerSignatures}
          signatureType="customer"
          onCreateService={getHandleCreateService}
          onComplete={getHandleComplete}
          loading={loading}
        />
      </div>
      <div className={classes.formSectionContainer}>
        <SignatureFieldForm
          initialData={data?.foremanSignatures}
          signatureType="foreman"
          onCreateService={getHandleCreateService}
          onComplete={getHandleComplete}
          loading={loading}
        />
      </div>
    </div>
  );
};

DailyReportsForm.propTypes = {
  data: PropTypes.object.isRequired,
  mode: PropTypes.string,
  dailyReportId: PropTypes.string,
  dailyReportDates: PropTypes.array,
  onSubmitComplete: PropTypes.func.isRequired,
  onSubmitting: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingReport: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  getHandleCreateService: PropTypes.func.isRequired,
  getHandleComplete: PropTypes.func.isRequired,
  getHandleRemoveService: PropTypes.func.isRequired,
  setOnSubmitFinal: PropTypes.func.isRequired,
  setIsValidTimesheets: PropTypes.func.isRequired
};
DailyReportsForm.defaultProps = {
  dailyReportId: '',
  dailyReportDates: [],
  mode: 'default'
};

export default DailyReportsForm;
