import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import { PricebookService } from 'services/core';
import { Logger } from 'services/Logger';

import styles from './styles';

const relatedInfoFieldsMapping = {
  costField: 'productEntryUnitCost',
  priceField: 'productEntryUnitPrice',
  entryPriceField: 'productEntryUnitPrice',
  markupField: 'productEntryMarkupValue',
  markupTypeField: 'productEntryMarkupType',
  taxField: 'taxable',
  priceBookEntryId: 'id',
  unitOfMeasure: 'unitOfMeasure',
  descriptionField: 'productDescription',
  costCodeId: 'costCodeId',
  jobCostTypeId: 'jobCostTypeId',
  revenueTypeId: 'revenueTypeId'
};

function Hit(props) {
  const { formProps, hit, classes, isFieldEdited } = props;
  const { form, field, specialbehaviour } = formProps;
  const {
    displayText,
    valueField,
    delimiter,
    additionalFormField,
    additionalFormFieldValue,
    filterString,
    ...others
  } = specialbehaviour;

  const handleSelect = async () => {
    try {
      const Service = new PricebookService();
      const productInfo = await Service.getProductById(hit.productId);
      const valueText = hit[valueField] || displayText[valueField];
      const { values } = form;
      const newValues = {
        ...values,
        [field.name]: valueText,
        [additionalFormField]: hit[additionalFormFieldValue],
        productSortKey: hit.productSortKey,
        productId: hit.productId
      };
      const specialbehaviourKeys = Object.keys(others);
      const { costCodeId, jobCostTypeId, revenueTypeId } = productInfo || {};
      const product = { ...(hit || {}), costCodeId, jobCostTypeId, revenueTypeId };

      specialbehaviourKeys.forEach(fieldKey => {
        const fieldName = specialbehaviour?.[fieldKey];
        const fieldValueKey = relatedInfoFieldsMapping?.[fieldKey];
        const fieldValue = product?.[fieldValueKey];
        if (fieldName) newValues[fieldName] = fieldValue || '';
      });
      form.setValues(newValues);
      isFieldEdited();
    } catch (error) {
      Logger.error(error);
    }
  };

  let resultText = '';
  if (displayText && Array.isArray(displayText)) {
    const displayValueArr = [];
    displayText.forEach(text => {
      if (hit[text]) {
        displayValueArr.push(hit[text]);
      }
    });
    resultText = displayValueArr.join(delimiter);
  } else if (displayText && typeof displayText === 'string') {
    resultText = hit[displayText];
  }

  return (
    <ListItem button disableGutters className={classes.results} onClick={handleSelect}>
      <ListItemText className={classes.resultsText}>{resultText}</ListItemText>
    </ListItem>
  );
}

export default withStyles(styles)(Hit);
