import gql from 'graphql-tag';

const addVendor = gql`
  mutation addVendor($partitionKey: String!, $data: AddVendorInput!) {
    addVendor(partitionKey: $partitionKey, data: $data) {
      id
      version
    }
  }
`;

export default addVendor;
