import React, { Component } from 'react';

// eslint-disable-next-line import/prefer-default-export
export function makeMountAware(WrappedComponent) {
  return class extends Component {
    mounted = false;

    componentDidMount() {
      this.mounted = true;
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    render() {
      return <WrappedComponent mounted={this.mounted} {...this.props} {...this.state} />;
    }
  };
}
