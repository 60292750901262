import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Calendar from '@material-ui/icons/CalendarToday';
import Map from '@material-ui/icons/Map';

import DatePicker from 'components/DatePicker';
import DefaultButton from 'components/Buttons/DefaultButton';
import SergeantModal from 'components/SergeantModal';
import { AlgoliaSearch } from 'components';

import SearchInput from './SearchInput';
import { getDateRangeFilter } from './helpers';
import useStyles from './styles';
import timeFilterLayout from './timeFilterLayout';

import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';

const DateButton = ({ filterName }) => {
  const classes = useStyles();
  return (
    <DefaultButton
      label={filterName}
      buttonProps={{ type: 'span', startIcon: <Calendar />, className: classes.button }}
    />
  );
};

const Algolia = ({ form, options }) => {
  const [searchValue, setSearchValue] = React.useState('');
  const { setAlgoliaProperty } = options;

  return (
    <AlgoliaSearch
      searchIndex={defaultSearchIndex}
      placeholder="Select a Property"
      name="property"
      value={searchValue}
      displayText={['customerPropertyName', 'address']}
      delimiter=" "
      locatedInPopUp
      label="Property"
      overrideVariant="outlined"
      isRequired
      errorMsg={form.errors.address}
      filters={[
        {
          attribute: 'entityType',
          valueArray: ['CompanyAddress']
        },
        {
          attribute: 'parentEntityType',
          valueArray: ['CustomerProperty']
        },
        {
          attribute: 'addressType',
          valueArray: ['propertyAddress']
        }
      ]}
      suggestionMarginTop={156}
      handleFilter={list => {
        // only return properties that can be queried by lat/lng
        return list.filter(property => property.latitude && property.longitude);
      }}
      handleChange={property => {
        setAlgoliaProperty({
          ...property,
          radius: form.values.radius
        });
        if (property) {
          const { customerPropertyName, addressLine1, city, state, zipcode } = property;
          setSearchValue(`${customerPropertyName}: ${addressLine1}, ${city}, ${state}, ${zipcode}`);
        } else {
          setSearchValue('');
        }
      }}
    />
  );
};

const Filter = ({ onChangeDateRange, dueDateRange, filterName = 'Date', onChangeLocation }) => {
  const [showLocationFilter, setShowLocationFilter] = React.useState(false);
  const [radioSelect, setRadioSelect] = React.useState('Property'); // or 'Address'
  const [algoliaProperty, setAlgoliaProperty] = React.useState({});
  const [radius, setRadius] = React.useState();

  const classes = useStyles();

  const handleModalClose = () => {
    setShowLocationFilter(false);
    setRadioSelect('Property');
    setAlgoliaProperty({});
    setRadius();
  };
  const handleSave = (filter, stopLoading) => {
    onChangeLocation(filter);

    setShowLocationFilter(false);
    setRadioSelect('Property');
    setAlgoliaProperty({});
    setRadius();

    stopLoading();
  };

  return (
    <Box display="flex">
      <DatePicker
        isDateRange
        defaultValue={dueDateRange || {}}
        onChange={onChangeDateRange}
        classNames={classes.dateInput}
        rangeFilters={getDateRangeFilter()}
        CustomSelectorElement={<DateButton filterName={filterName} />}
      />
      <DefaultButton
        label="Location"
        onClick={() => setShowLocationFilter(true)}
        buttonProps={{ startIcon: <Map />, className: classes.button }}
      />
      <SergeantModal
        data={{
          radio: radioSelect, // default radio button select is Property
          billingLatitude: algoliaProperty.latitude,
          billingLongitude: algoliaProperty.longitude,
          address: algoliaProperty.customerPropertyName,
          radius
        }}
        mode=""
        open={showLocationFilter}
        layout={timeFilterLayout({
          radioSelect,
          setAlgoliaProperty
        })}
        handleClose={handleModalClose}
        handlePrimaryAction={handleSave}
        customComponents={{ SearchInput, Algolia }}
        dataType="Apply Location Filter"
        customPrimaryButtonLabel="Apply location filter"
        onFieldChange={(field, value) => {
          if (field === 'radio') {
            setRadioSelect(value);
            setAlgoliaProperty({});
          }
          if (field === 'radius') {
            setRadius(value);
          }
        }}
      />
    </Box>
  );
};

Filter.propTypes = {
  /**
   * @typedef {Object} DateRange
   * @prop {Date} startDate - Date object representing selected start date
   * @prop {Date} endDate - Date object representing selected end date
   * @prop {string} key - not sure what this does but it's always 'selection'
   */

  /**
   * @func onChangeDateRange - when date range selected, cb func called with
   *  below object containing startDate and endDate
   * @param {DateRange}
   */
  onChangeDateRange: PropTypes.func.isRequired,
  /** @type {DateRange} */
  dueDateRange: PropTypes.object.isRequired
};

export default Filter;
