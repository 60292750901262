import { getBooleanValue, roundCurrency, roundFloat } from 'utils';

const constructPayload = ({
  name,
  description,
  departmentName,
  priceBookEntry,
  accountingRefId,
  includeInInvoice,
  unitOfMeasure,
  taxable,
  markupValue,
  unitCost,
  unitPrice,
  // amount,
  quantity,
  jobCostType,
  costCode,
  revenueType,
  truck
}) => ({
  description,
  itemName: name,
  includeInInvoice,
  departmentName,
  priceBookEntryId: priceBookEntry.id,
  accountingRefId,
  unitOfMeasure,
  taxable,
  markup: roundFloat(markupValue),
  unitCost: roundFloat(unitCost),
  unitPrice: roundCurrency(unitPrice),
  // amount: roundCurrency(amount),
  quantity,
  jobCostTypeId: jobCostType?.value || null,
  costCodeId: costCode?.value || null,
  revenueTypeId: revenueType?.value || null,
  truckId: truck?.value || null
});

const addLocalFields = item => {
  const localUnitOfMeasure = item?.priceBookEntry?.product?.unitOfMeasure?.name;
  return {
    ...item,
    // existing logic from PartsAndMaterials moved to here
    addedBy: item.addedBy || item.createdBy,
    amount: roundCurrency(item.quantity * item.unitPrice),
    unitOfMeasure: localUnitOfMeasure
  };
};

export const saveInventory = async (context, event) => {
  const { services, id, visit } = context;
  const { reviewReportService } = services;
  const { saveData } = event;
  const formattedData = constructPayload(saveData);

  if (!formattedData?.departmentName && visit.departmentName) {
    formattedData.departmentName = visit.departmentName;
  }

  const mutateData = {
    reviewReportId: id,
    inventoryParts: [{ ...formattedData }]
  };

  const data = await reviewReportService.addInventoryPartsToReviewReport(
    context.user.tenantId,
    mutateData
  );

  const { addInventoryPartsToReviewReport } = data.data;
  const { inventoryParts } = context;

  if (addInventoryPartsToReviewReport) {
    const processedInventoryItem = addLocalFields(addInventoryPartsToReviewReport[0]);
    inventoryParts.push(processedInventoryItem);
    return { inventoryParts: [...inventoryParts], modalRecord: {}, modalIndex: '' };
  }
};

export const editInventory = async (context, event) => {
  const { services, user, visit } = context;
  const { reviewReportService } = services;
  const { saveData } = event;
  const formattedData = constructPayload(saveData);
  formattedData.id = saveData.id;
  formattedData.version = saveData.version;
  formattedData.includeInInvoice = getBooleanValue(saveData.includeInInvoice);

  if (!formattedData?.departmentName && visit.departmentName) {
    formattedData.departmentName = visit.departmentName;
  }

  const queryData = await reviewReportService.updateInventoryPart(user.tenantId, formattedData);

  const { updateInventoryPart } = queryData.data;
  const { inventoryParts } = context;

  const modifiedInventoryParts = inventoryParts.map(inventoryItem => {
    let localInventoryItem = inventoryItem;
    if (localInventoryItem.id === updateInventoryPart?.id) {
      localInventoryItem = updateInventoryPart;
    }
    localInventoryItem = addLocalFields(localInventoryItem);
    return localInventoryItem;
  });
  return { inventoryParts: modifiedInventoryParts, modalRecord: {}, modalIndex: '' };
};

export const deleteInventoryPart = async context => {
  const { services, user, modalRecord } = context;
  const { reviewReportService } = services;
  const { data } = await reviewReportService.deleteInventoryPart(user.tenantId, modalRecord.id);
  const { inventoryParts } = context;

  if (data?.deleteInventoryPart) {
    const recordIndex = inventoryParts.findIndex(
      inventoryPart => inventoryPart.id === modalRecord.id
    );
    inventoryParts.splice(recordIndex, 1);
  }

  return { inventoryParts: [...inventoryParts], modalRecord: {}, modalIndex: '' };
};

/* Code to include an inventory item to invoice from Review Report */
export const includeInventory = async (context, event) => {
  const { services, user, inventoryParts } = context;
  const { reviewReportService } = services;
  const { data, checked } = event;

  const formattedData = (({ id, version }) => ({ id, version }))(data);
  formattedData.includeInInvoice = checked;

  const queryData = await reviewReportService.updateInventoryPart(user.tenantId, formattedData);
  if (queryData) {
    const { updateInventoryPart } = queryData.data;

    const modifiedInventoryParts = inventoryParts.map(inventoryItem => {
      let localInventoryItem = inventoryItem;
      if (localInventoryItem.id === updateInventoryPart.id) {
        localInventoryItem = addLocalFields(updateInventoryPart);
      }
      return localInventoryItem;
    });

    return { inventoryParts: modifiedInventoryParts };
  }
};
