import initialState from '../state';
import * as Actions from '../constants';

export const openQuickAddModal = (state = initialState.openQuickAddModal, action) => {
  switch (action.type) {
    case Actions.OPEN_QUICK_ADD_MODAL:
      return action.openQuickAddModal;
    default:
      return state;
  }
};

export const pm = (state = initialState.pm, action) => {
  switch (action.type) {
    case Actions.SET_PROJECT_SUMMARY: {
      return {
        ...state,
        project: {
          summaryLoaded: true,
          detailsLoaded: false,
          ...action.payload
        }
      };
    }
    case Actions.SET_PROJECT_DETAILS: {
      return {
        ...state,
        project: {
          summaryLoaded: true,
          detailsLoaded: true,
          ...action.payload
        }
      };
    }
    case Actions.REFETCH_PROJECT_DETAILS: {
      return {
        ...state,
        projectDetailsRefetch: !state.projectDetailsRefetch
      };
    }
    case Actions.SET_PROJECT_KPIS: {
      return {
        ...state,
        projectKpis: {
          loaded: true,
          ...action.payload
        }
      };
    }
    case Actions.SET_COMPANY_TIMEZONE: {
      return {
        ...state,
        timezone: {
          timezone: action.payload.timezone,
          loading: action.payload.loading
        }
      };
    }
    default: {
      return state;
    }
  }
};
