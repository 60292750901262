export const getPreferredTechnicians = ({
  maintenanceTemplate,
  serviceAgreement,
  companySettings
} = {}) => {
  let departmentId;
  const { numberOfTechs = 0 } = maintenanceTemplate || {};
  const extraTechsNumber = numberOfTechs > 1 ? numberOfTechs - 1 : 0;
  if (
    companySettings?.SERVICE_AGREEMENT?.enableTechnicianSelectionAtMaintenanceTemplateLevel &&
    maintenanceTemplate?.preferredTechnicians
  ) {
    const preferredTechs = maintenanceTemplate?.preferredTechnicians || {};
    departmentId = preferredTechs?.departmentId || serviceAgreement?.departmentId;
    return { ...preferredTechs, extraTechsNumber, departmentId };
  }
  departmentId =
    serviceAgreement?.preferredTechnicians?.departmentId || serviceAgreement?.departmentId;
  return { ...serviceAgreement?.preferredTechnicians, extraTechsNumber, departmentId } || {};
};
