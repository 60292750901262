import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  subtitle: {
    fontSize: '10px',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  }
}));
