/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getReceiptLines() {
  return await instance
    .get('purchaseorderreceipt')
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getLinesByReceipt(id) {
  return await instance
    .get(`purchaseorderreceiptline/receipt/${id}`, {
      params: {
        include: [
          'Job',
          'Product',
          'Product.[unitOfMeasure]',
          'Department',
          'costCode',
          'PurchaseOrderLine',
          'jobCostType',
          'revenueType',
          'id'
        ]
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getReceiptLinesByProjectPhase(projectPhaseId) {
  return await instance
    .get(`purchaseorderreceiptline/byprojectphase/${projectPhaseId}`, {
      params: { include: '*' }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function purchaseOrderReceiptLineDelete(id) {
  return await instance
    .delete(`purchaseorderreceiptline/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function purchaseOrderReceiptLineCreate(receiptData) {
  return await instance
    .post(`purchaseorderreceiptline`, receiptData)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
