import { object } from 'yup';
import { Mode, EntityType, BILLING_INFO_TOOLTIP } from 'utils/constants';
import { merge } from 'lodash';
import { addressValidationFields, nameValidationField } from 'meta/validations';

const minFirstRowItemWidth = 200;
const minItemWidth = 200;
const padding = 10;

export const CustomerMainLayout = (options, customLayouts = []) => ({
  fields: {},
  layouts: {
    view: {
      options: {
        pageSize: 'LETTER',
        direction: 'column',
        grow: 0
      },
      contents: [
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                padding: 12,
                grow: 1
              },
              contents: [
                {
                  component: 'FieldWithLabel',
                  source: 'customerTypeValue',
                  options: {
                    label: 'Customer Type',
                    isRequired: false
                  }
                }
              ]
            },
            {
              options: {
                padding: 12,
                grow: 1
              },
              contents: [
                {
                  component: 'FieldWithLabel',
                  source: 'priceBook.name',
                  options: {
                    label: 'Pricebook Name',
                    isRequired: false
                  }
                }
              ]
            },
            {
              options: {
                padding: 12,
                grow: 1
              },
              contents: [
                {
                  component: 'FieldWithLabel',
                  source: 'taxRateLabel',
                  options: {
                    label: 'Tax Code',
                    isRequired: false
                  }
                },
                {
                  component: 'ReadOnlyCheckboxInput',
                  source: 'isTaxable',
                  options: {
                    testingid: 'taxable',
                    label: 'Taxable'
                  }
                }
              ]
            },
            {
              options: {
                padding
              },
              contents: [
                {
                  component: 'FieldWithLabel',
                  source: 'taxExemptIdValue',
                  options: {
                    label: 'Tax exempt ID'
                  }
                }
              ]
            },
            {
              options: {
                padding: 12,
                grow: 1
              },
              contents: [
                {
                  component: 'FieldWithLabel',
                  source: 'paymentTermLabel',
                  options: {
                    label: 'Payment Terms'
                  }
                }
              ]
            },
            {
              options: {
                padding: 12,
                grow: 1
              },
              contents: [
                {
                  component: 'FieldWithLabel',
                  source: 'invoicePresetLabel',
                  options: {
                    label: 'Invoice Preset'
                  }
                }
              ]
            },
            {
              options: {
                padding: 12,
                grow: 1
              },
              contents: [
                {
                  component: 'FieldWithLabel',
                  source: 'arBalance',
                  options: {
                    label: 'Outstanding Balance',
                    type: 'currency'
                  }
                }
              ]
            },
            {
              options: {
                padding: 12,
                grow: 2
              },
              contents: [
                {
                  component: 'AddNotesView',
                  source: 'customerNotes'
                }
              ]
            }
          ]
        },
        ...customLayouts
      ]
    },
    edit: {
      options: {
        pageSize: 'LETTER',
        direction: 'column',
        grow: 0
      },
      contents: [
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'SelectInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'customerTypeValue',
                  options: {
                    label: 'Customer type',
                    valueSet: options.customerTypeOptions,
                    isSearchable: true,
                    enableSort: false
                  }
                }
              ]
            },
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'SelectInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'priceBookId',
                  options: {
                    label: 'Pricebook Name',
                    valueSet: options.priceBookOptions,
                    isSearchable: true
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: 'SelectInput',
                  source: 'taxRateId',
                  options: {
                    label: 'Tax code',
                    isSearchable: true,
                    enableSort: false,
                    valueSet: options.taxOptions
                  }
                },
                {
                  component: 'CheckboxInput',
                  source: 'isTaxable',
                  options: {
                    label: 'Taxable'
                  }
                }
              ]
            },
            {
              options: {
                padding
              },
              contents: [
                {
                  component: 'TextInput',
                  source: 'taxExemptIdValue',
                  options: {
                    label: 'Tax exempt ID'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: 'SelectInput',
                  source: 'paymentTermId',
                  options: {
                    label: 'Payment Terms',
                    isSearchable: true,
                    valueSet: options.paymentTermOptions
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: 'SelectInput',
                  source: 'invoicePresetId',
                  options: {
                    label: 'Invoice Preset',
                    isSearchable: true,
                    valueSet: options.invoicePresetOptions
                  }
                }
              ]
            },
            {
              options: {
                padding: 12,
                grow: 1
              },
              contents: [
                {
                  component: 'FieldWithLabel',
                  source: 'arBalance',
                  options: {
                    label: 'Outstanding Balance',
                    type: 'currency'
                  }
                }
              ]
            },
            {
              options: {
                padding: 12,
                grow: 2
              },
              contents: [
                {
                  component: 'AddNotesView',
                  source: 'customerNotes'
                }
              ]
            }
          ]
        },
        ...customLayouts
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      customerTypeValue: {
        type: 'string',
        nullable: true
      },
      noOfProperties: {
        type: 'number'
      },
      taxRateId: {
        type: 'string'
      },
      isTaxable: {
        type: 'boolean'
      }
    }
  },
  validationErrors: {}
});

export const mainLayoutWithInlineForm = (options, customForm = {}, isCustomerPriceBookEnabled) => {
  const rawLayout = CustomerMainLayout(
    options,
    customForm.componentArr,
    isCustomerPriceBookEnabled
  );
  const mergedValidation = merge(rawLayout.validation, customForm.validation);
  const mergedValidationErrors = merge(rawLayout.validationErrors, customForm.validationErrors);
  return {
    fields: { ...rawLayout.fields, ...customForm.fields },
    layouts: rawLayout.layouts,
    validation: mergedValidation,
    validationErrors: mergedValidationErrors
  };
};

const AddressFormItems = (addressType, taxOptions) => {
  const [toSource, toLabel] =
    addressType === 'billing' ? ['billTo', 'billing information'] : ['shipTo', 'ship to'];
  return [
    {
      options: {
        direction: 'column',
        minWidth: minItemWidth * 2
      },
      contents:
        addressType !== 'property'
          ? [
              {
                component: {
                  edit: 'TextInput'
                },
                source: toSource,
                options: {
                  testingid: 'textarea-billToCustomer',
                  label: toLabel,
                  linesMax: 4,
                  ...(toSource === 'billTo' && {
                    lines: 2,
                    tooltip: BILLING_INFO_TOOLTIP,
                    placeholder: 'Customer Name\nBill To'
                  })
                }
              }
            ]
          : []
    },
    {
      options: {
        direction: 'row',
        marginTop: 12
      },
      contents: [
        {
          options: {
            minWidth: minItemWidth * 2
          },
          contents: [
            {
              component: {
                default: 'PlacesSearch'
              },
              source: 'addressLine1',
              options: {
                label: 'Address Line 1',
                placeholder: '',
                required: true,
                valuesToSet: [
                  {
                    city: 'city.shortName',
                    state: 'state.shortName',
                    zipcode: 'zipcode.shortName',
                    latitude: 'latitude',
                    longitude: 'longitude',
                    addressLine1: 'addressLine1',
                    // Record whether we have selected a new address
                    addressChanged: 'addressChanged'
                  }
                ],
                fieldsToMatch: ['billing', 'property'].includes(addressType)
                  ? [{ taxRateId: [taxOptions, 'id'] }]
                  : null,
                fullWidth: true,
                setSource: false
              }
            }
          ]
        },
        {
          options: {
            minWidth: minItemWidth,
            paddingLeft: padding * 2
          },
          contents: [
            {
              component: {
                edit: 'TextInput'
              },
              source: 'addressLine2',
              options: {
                testingid: 'addressLine-2',
                label: 'Address Line 2'
              }
            }
          ]
        }
      ]
    },
    {
      options: {
        direction: 'row',
        marginTop: 12
      },
      contents: [
        {
          options: {
            direction: 'column',
            minWidth: minItemWidth,
            paddingRight: padding
          },
          contents: [
            {
              component: {
                edit: 'TextInput'
              },
              source: 'city',
              options: {
                testingid: 'city',
                label: 'City'
              }
            }
          ]
        },
        {
          options: {
            minWidth: minItemWidth,
            paddingRight: padding
          },
          contents: [
            {
              component: {
                edit: 'TextInput'
              },
              source: 'state',
              options: {
                testingid: 'state',
                label: 'State/Province'
              }
            }
          ]
        },
        {
          options: {
            minWidth: minItemWidth
          },
          contents: [
            {
              component: {
                edit: 'TextInput'
              },
              source: 'zipcode',
              options: {
                testingid: 'zipcode',
                label: 'zip/postal code'
              }
            }
          ]
        }
      ]
    }
  ];
};

export const EditAddressForm = (addressType, taxOptions) => {
  const completeForm = {
    fields: {},
    layouts: {
      [Mode.EDIT]: {
        options: {
          direction: 'column'
        },
        contents: AddressFormItems(addressType, taxOptions)
      }
    },
    validation: object({
      ...addressValidationFields()
    })
  };
  return completeForm;
};

export const EditCustomerNameForm = () => ({
  fields: {},
  layouts: {
    default: {
      options: {
        direction: 'column'
      },
      contents: [
        {
          options: {
            direction: 'column',
            minWidth: minItemWidth * 2
          },
          contents: [
            {
              component: {
                edit: 'TextInput'
              },
              source: 'customerName',
              options: {
                label: 'Customer',
                isRequired: true
              }
            }
          ]
        }
      ]
    }
  },
  validation: object(
    nameValidationField('customerName', EntityType.CUSTOMER, 'Customer Name is required.')
  )
});

const sidebarItems = ({
  handleBillingAddressEdit,
  handleBusinessAddressEdit,
  billingAddressProps,
  businessAddressProps
}) => {
  return [
    {
      options: {
        padding,
        paddingTop: 0,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          component: {
            default: 'AddressEditAndView',
            edit: 'AddressEditAndView'
          },
          source: { default: 'billingAddress', edit: 'billingAddressFields' },
          options: {
            label: 'Billing Address',
            onEdit: handleBillingAddressEdit,
            enableEdit: { default: false, edit: true },
            useSideSectionStyling: true,
            ...billingAddressProps
          }
        }
      ]
    },
    {
      options: {
        padding,
        paddingTop: 0,
        minWidth: minItemWidth
      },
      contents: [
        {
          component: {
            default: 'LocationView'
          },
          source: 'billingLocation'
        }
      ]
    },
    {
      options: {
        padding,
        minWidth: minFirstRowItemWidth
      },
      contents: [
        {
          component: {
            default: 'AddressEditAndView',
            edit: 'AddressEditAndView'
          },
          source: { default: 'businessAddress', edit: 'businessAddressFields' },
          options: {
            label: 'Business Address',
            onEdit: handleBusinessAddressEdit,
            enableEdit: { default: false, edit: true },
            useSideSectionStyling: true,
            ...businessAddressProps
          }
        }
      ]
    },
    {
      options: {
        padding,
        paddingTop: 0,
        minWidth: minItemWidth
      },
      contents: [
        {
          component: {
            default: 'LocationView'
          },
          source: 'businessLocation'
        }
      ]
    },
    {
      options: {
        padding,
        minWidth: minItemWidth
      },
      contents: [
        {
          component: 'FieldWithLabel',
          source: 'noOfProperties',
          options: {
            label: 'No of Properties',
            isRequired: false
          }
        }
      ]
    },
    {
      options: {
        padding,
        minWidth: minItemWidth
      },
      contents: [
        {
          component: {
            view: 'FieldWithLabel',
            edit: 'TextInput'
          },
          source: 'email',
          options: {
            label: 'email address',
            type: 'string'
          }
        }
      ]
    },
    {
      options: {
        padding,
        minWidth: minItemWidth
      },
      contents: [
        {
          component: {
            view: 'FieldWithLabel',
            edit: 'TextInput'
          },
          source: 'phonePrimary',
          options: {
            label: 'phone'
          }
        }
      ]
    }
  ];
};
export const sidebarSection = options => ({
  fields: {},
  layouts: {
    edit: {
      options: {
        direction: 'column',
        grow: 0
      },
      contents: sidebarItems(options)
    },
    view: {
      options: {
        direction: 'column',
        grow: 0
      },
      contents: sidebarItems(options)
    }
  }
});
