import moment from 'moment';
import { isEmpty } from 'lodash';
import AppConstants from 'utils/AppConstants';

export const getDefaultDate = (isDateRange, selectedDate) => {
  const currentDate = new Date();
  if (!isDateRange) return selectedDate || currentDate;
  const { startDate = currentDate, endDate = currentDate } = selectedDate || {};
  return { startDate, endDate, key: 'selection' };
};

const formatDate = date => {
  if (date) return moment(date).format(AppConstants.DATE_FORMAT);
};

export const getFormatedDate = (isDateRange, selectedDate) => {
  if (isEmpty(selectedDate)) return '';
  if (isDateRange) {
    const { startDate, endDate } = selectedDate || {};
    return `${formatDate(startDate)} - ${formatDate(endDate)}`;
  }
  return formatDate(selectedDate);
};
