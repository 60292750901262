import gql from 'graphql-tag';

const updateTaskEntriesForTask = gql`
  mutation updateTaskEntriesForTask($partitionKey: String!, $data: UpdateTaskEntriesForTaskInput!) {
    updateTaskEntriesForTask(partitionKey: $partitionKey, data: $data) {
      id
      version
      entityType
      priceBookEntryId
      productId
      markupType
      markupValue
      unitPrice
      unitCost
      quantity
      product(entityConnection: "Product") {
        name
        code
        description
      }
    }
  }
`;

export default updateTaskEntriesForTask;
