import gql from 'graphql-tag';

const createInvoiceForCustomer = gql`
  mutation createInvoiceForCustomer($partitionKey: String, $data: CreateInvoiceForCustomer) {
    createInvoiceForCustomer(partitionKey: $partitionKey, data: $data) {
      id
      invoiceNumber
      sortKey
    }
  }
`;

export default createInvoiceForCustomer;
