import React, { memo, useRef } from 'react';
import { string } from 'prop-types';
import compose from 'utils/compose';
import { withDispatchActions, withQueryStringStore } from '@dispatch/Dispatch.store';
import useManDayDetails from '@dispatch/queries/useManDayDetails';
import useUpdateManDay from '@dispatch/mutations/useUpdateManDay';
import useDeleteManDay from '@dispatch/mutations/useDeleteManDay';
import useDeleteManDayEventsGroup from '@dispatch/mutations/useDeleteManDayEventsGroup';
import useCompleteProjectVisit from '@dispatch/mutations/useCompleteProjectVisit';
import EditManDay from './EditManDay.component';

const mapActionsToProps = ({ closeDrawer }) => ({
  closeDrawer
});

const mapQueryStringToProps = store => ({
  manDayId: store.state.manDayId
});

const DataProvider = props => {
  const Memoized = useRef(memo(EditManDay)).current;
  const manDayDetailsResponse = useManDayDetails(props.manDayId);
  const updateManDayTuple = useUpdateManDay();
  const deleteManDayTuple = useDeleteManDay();
  const deleteManDayEventsGroupTuple = useDeleteManDayEventsGroup();
  const completeProjectVisitTuple = useCompleteProjectVisit();
  return (
    <Memoized
      {...props}
      manDayDetailsResponse={manDayDetailsResponse}
      updateManDayTuple={updateManDayTuple}
      deleteManDayTuple={deleteManDayTuple}
      deleteManDayEventsGroupTuple={deleteManDayEventsGroupTuple}
      completeProjectVisitTuple={completeProjectVisitTuple}
    />
  );
};

DataProvider.propTypes = {
  manDayId: string.isRequired
};

export default compose(
  withDispatchActions(mapActionsToProps),
  withQueryStringStore(mapQueryStringToProps)
)(DataProvider);
