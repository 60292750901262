export const dateRanges = [
  {
    label: 'Today',
    numberOfDays: 0
  },
  {
    label: 'Tomorrow',
    numberOfDays: 1
  },
  {
    label: 'This Week',
    numberOfDays: 7
  },
  {
    label: 'Next 2 Weeks',
    numberOfDays: 14
  },
  {
    label: 'Next Month',
    numberOfDays: 30
  },
  {
    label: 'Next Quarter',
    numberOfDays: 90
  }
];

export const searchInputProps = {
  transition: true,
  placement: 'bottom-start',
  modifiers: {
    preventOverflow: {
      enabled: true,
      boundariesElement: 'window'
    },
    flip: {
      enabled: false
    }
  }
};
