import { useEffect, useState } from 'react';
import * as R from 'ramda';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import { generateCompanySortKey } from 'services/core/utils';
import getPayrollLabourTypes from 'services/core/graphql/timesheets/queries/getPayrollLabourTypes';
import { logErrorWithCallback } from 'utils';

const useLabourTypes = (tenantId, companyId, snackbarOn) => {
  const [labourTypes, setLabourTypes] = useState([]);

  const { loading, data, error } = useExtendedQuery(getPayrollLabourTypes, {
    variables: {
      filter: {},
      partitionKey: tenantId,
      sortKey: generateCompanySortKey(tenantId, companyId)
    }
  });

  useEffect(() => {
    if (error) {
      logErrorWithCallback(
        error,
        snackbarOn,
        'Unable to fetch payroll types, please try again later'
      );
    }

    if (data) {
      setLabourTypes(R.sortBy(R.prop('createdDate'), data.getCompany.labourTypes.items));
    }
  }, [data, error, snackbarOn]);

  return {
    loading,
    data: labourTypes
  };
};

export default useLabourTypes;
