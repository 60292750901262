import gql from 'graphql-tag';

import { dispatchEmployeeFragment, dispatchVisitFragment } from './visitFragments';

const VISITS_LIMIT = 250;

const getCompanyDispatchOverview = gql`
  query getCompanyDispatchOverview(
    $partitionKey: String!
    $sortKey: String!
    $visitsAfter: Int!
  ) {
    overview: getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      companyAddresses(entityConnection: "CompanyAddress", limit: 5) {
        items {
          addressType
          addressLine1
          addressLine2
          city
          county
          state
          zipcode
          country
          latitude
          longitude
        }
      }
      departments: departmentsView(entityConnection: "Department") {
        items {
          id
          entityType
          sortKey
          tagName
          tagType
          skills(entityConnection: "Skill") {
            items {
              mappedEntity {
                ... on Skill {
                  id
                  tagName
                  tagType
                }
              }
            }
          }
        }
      }
      
      pms: employees(entityConnection: "Employee", filter: {
        isSales: {
          eq: true 
        }
      }) {
        items {
          ${dispatchEmployeeFragment}
        }
      }

      # Scheduled visits are fetched via getCompanyDispatchScheduledVisits.js
      visits: visitsView(
        entityConnection: "Visit"
        pagination: { limit: ${VISITS_LIMIT} }
        filter: {
          filterRelatedEntityData: false
          integerFilters : [{
            fieldName: "Visit.createdDate"
            filterInput: { gt: $visitsAfter }
          }]
          stringFilters : [{
            fieldName: "Visit.status"
            filterInput: { in: ["Scheduled" "Working" "On hold" "Traveling" "Paused" "Unassigned"] }
          }]
        }
      ) {
        items {
          ${dispatchVisitFragment}
        }
      }
    }
  }
`;

export default getCompanyDispatchOverview;
