import React, { memo, useRef } from 'react';

import { withDispatchActions, withQueryStringStore } from '@dispatch/Dispatch.store';

import { useVisitTransition } from '@dispatch/mutations';
import { useDepartments, useVisitDetails } from '@dispatch/queries';
import compose from 'utils/compose';

import EditVisit from './EditVisit.component';

const mapActionsToProps = ({ closeDrawer, createEvent }) => ({
  closeDrawer,
  createEvent
});

const mapQueryStringToProps = store => ({
  visitId: store.state.visitId,
  openVisit: store.openVisit
});

const DataProvider = props => {
  const Memoized = useRef(memo(EditVisit)).current;
  // eslint-disable-next-line react/prop-types
  const { visitId } = props;
  const departmentsResponse = useDepartments();
  const { refetch, ...visitDetailsResponse } = useVisitDetails(visitId);
  const visitTransitionTuple = useVisitTransition();

  return (
    <Memoized
      {...props}
      departmentsResponse={departmentsResponse}
      visitDetailsResponse={visitDetailsResponse}
      visitTransitionTuple={visitTransitionTuple}
      refetchVisitData={refetch}
    />
  );
};

export default compose(
  withQueryStringStore(mapQueryStringToProps),
  withDispatchActions(mapActionsToProps)
)(DataProvider);
