import gql from 'graphql-tag';

const getInvoicesByJob = gql`
  query getInvoicesByJob($partitionKey: String!, $sortKey: String!) {
    getJob(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      sortKey
      invoices(entityConnection: "Invoice", filter: { isActive: { eq: true } }) {
        items {
          id
          sortKey
          status
          issuedDate
          dueDate
          entityType
          totalAmount
          invoiceNumber
          isFinalInvoice
          createdBy
          version
          invoiceVisits(entityConnection: "InvoiceVisit") {
            items {
              id
              sortKey
              visitSortKey
              visitId
              visit(entityConnection: "visit") {
                visitNumber
              }
            }
          }
        }
      }
    }
  }
`;

export default getInvoicesByJob;
