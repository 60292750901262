import gql from 'graphql-tag';

const addVisitAssetsToVisit = gql`
  mutation addVisitAssetsToVisit($partitionKey: String, $data: AddVisitAssetsToVisitInput!) {
    addVisitAssetsToVisit(partitionKey: $partitionKey, data: $data) {
      id
    }
  }
`;

export default addVisitAssetsToVisit;
