import React from 'react';
import { object } from 'prop-types';
import { Typography } from '@material-ui/core';
import { useStyles } from './LunchTimeValidationField.styles';

const LunchTimeValidationField = ({ form }) => {
  const styles = useStyles();
  const { lunchInTime, lunchOutTime } = form.values;
  if (lunchOutTime < lunchInTime) {
    return (
      <div css={styles.root}>
        <Typography css={styles.error}>Lunch In time must be before Lunch Out time.</Typography>
      </div>
    );
  }

  return null;
};

LunchTimeValidationField.propTypes = {
  form: object.isRequired
};

export default LunchTimeValidationField;
