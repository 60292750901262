import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { generateDefaultValidationSchema } from '@pm/components/formattingUtils';
import MUIFormListView from '@pm/components/MUIFormListView';
import FormWithAccordion from '@pm/components/FormWithAccordion';
import Attachments from '@pm/components/Attachments';
import { getNotesDataFromDailyReport } from '@pm/components/utils';
import { notesFields, notesLayout } from './layout';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 24
  },
  accordionFooter: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '21px 28px'
  },
  hideElement: {
    display: 'none'
  },
  addButton: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '14px',
    textDecoration: 'none'
  },
  footerForm: {
    width: 702
  },
  footerTotal: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 21
  }
}));

const CustomAttachmentsComponent = props => {
  const classes = useStyles();
  const { options, field } = props;
  const [attachments, setAttachments] = useState(
    options.attachments?.length > 0 ? options.attachments : field.value
  );

  const attachment = () => {
    return (
      <div className={classes.attachmentsContainer}>
        <Attachments
          noAddButton
          attachedFiles={
            attachments.filter(
              element => element.deletedDate === null || element.deletedDate === undefined
            ) || []
          }
          attachedFileCount={
            attachments.filter(element => element.deletedDate === null).length || 0
          }
          uploadFileList={(list, selectedImages, selectedFiles) =>
            options.handleFormAttachments(list, selectedImages, selectedFiles, setAttachments)
          }
          chunkSize={5}
          imageWidth={200}
          imageHeight={180}
        />
      </div>
    );
  };

  return attachment();
};

const NotesFieldForm = props => {
  const {
    initialData,
    mode,
    loading,
    onCreateService,
    onComplete,
    formatFuncForSave,
    attachments,
    handleFormAttachments
  } = props;
  const classes = useStyles();
  const [panelExpanded, setPanelExpanded] = useState(false);

  const handlePanelExpand = (_, newExpanded) => {
    setPanelExpanded(newExpanded);
  };

  const formData = getNotesDataFromDailyReport(initialData);

  const muiForms = () => {
    return (
      <MUIFormListView
        keyName="notes"
        formData={[formData]}
        onComplete={onComplete}
        formatFuncForSave={formatFuncForSave}
        mode="default"
        key="notes"
        layout={mode}
        configuration={notesLayout(attachments, handleFormAttachments)}
        onCreateService={onCreateService}
        customComponents={{ CustomAttachmentsComponent }}
        validationSchema={generateDefaultValidationSchema(notesFields)}
      />
    );
  };

  return (
    <div className={classes.root}>
      <FormWithAccordion
        sectionName="note"
        formComponent={muiForms}
        handlePanelExpand={handlePanelExpand}
        loading={loading}
        expanded={panelExpanded}
      />
      <Divider />
    </div>
  );
};

CustomAttachmentsComponent.propTypes = {
  options: PropTypes.object.isRequired
};

NotesFieldForm.propTypes = {
  attachments: PropTypes.array.isRequired,
  onCreateService: PropTypes.func.isRequired,
  initialData: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  formatFuncForSave: PropTypes.func.isRequired,
  handleFormAttachments: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

NotesFieldForm.defaultProps = {
  loading: false
};

export default NotesFieldForm;
