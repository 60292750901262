import { Machine } from 'xstate';
import { JobStatus, VisitStatus } from 'utils/AppConstants';
import JobService from '../../graphql-services/Jobs/JobService';
import { JobActions } from '../../utils/AppConstants';

const finalVisitStatuses = [
  VisitStatus.COMPLETE,
  VisitStatus.CLOSED,
  VisitStatus.CONVERTED,
  VisitStatus.CANCELED
];

const isOnCompleteValid = context => {
  const { visits } = context;
  const isValid = visits && visits.every(visit => finalVisitStatuses.includes(visit.status));
  if (!isValid) throw new Error();
  return true;
};

const isCancelledValid = context => {
  const { visits } = context;
  const isValid =
    visits &&
    visits.every(visit =>
      [
        VisitStatus.CANCELED,
        VisitStatus.COMPLETE,
        VisitStatus.CLOSED,
        VisitStatus.CONVERTED
      ].includes(visit.status)
    );

  if (!isValid) throw new Error();
  return true;
};

const isOnHoldValid = context => {
  const { visits } = context;
  const isValid =
    visits &&
    !visits.some(visit => [VisitStatus.WORKING, VisitStatus.SCHEDULED].includes(visit.status));
  if (!isValid) throw new Error();
  return true;
};

const isInProgressValid = context => {
  const { visits, status } = context;
  return (
    (visits && visits.length > 0) || status === JobStatus.OPEN || status === JobStatus.CANCELED
  );
};

const isOpenValid = context => {
  const { visits, status } = context;
  return (
    (visits && visits.length === 0) || status === JobStatus.ON_HOLD || status === JobStatus.CANCELED
  );
};

const updateJobStatusTo = async (context, actionName) => {
  const { id } = context;
  try {
    const jobService = new JobService();
    await jobService.updateJobStatus(id, actionName);
  } catch (e) {
    console.error(`Error: Cannot update the job status to ${actionName}`);
  }
};

const NEW_VISIT_CREATED = {
  target: JobStatus.IN_PROGRESS,
  cond: isInProgressValid,
  actions: ['markJobAsInProgress']
};

const MARKED_AS_COMPLETE = {
  target: JobStatus.COMPLETE,
  cond: isOnCompleteValid,
  actions: ['markJobAsComplete']
};

const PUT_ON_HOLD = {
  target: JobStatus.ON_HOLD,
  cond: isOnHoldValid,
  actions: ['markJobAsOnHold']
};

const MARKED_AS_CANCELED = {
  target: JobStatus.CANCELED,
  cond: isCancelledValid,
  actions: ['markJobAsCancelled']
};

const ON_CONTINUE = {
  target: JobStatus.IN_PROGRESS,
  actions: ['markJobAsInProgress']
};

const ON_REOPEN = {
  target: JobStatus.OPEN,
  actions: ['markJobAsOpen']
};

const JobStateMachine = initialState =>
  Machine(
    {
      context: {
        job: {
          id: '',
          version: '',
          status: '',
          visits: []
        }
      },
      states: {
        [JobStatus.UNSCHEDULED]: {
          on: {
            ON_WEB_HOLD: PUT_ON_HOLD,
            ON_CANCEL: MARKED_AS_CANCELED
          }
        },
        [JobStatus.SCHEDULED]: {
          on: {
            ON_CANCEL: MARKED_AS_CANCELED,
            ON_WEB_HOLD: PUT_ON_HOLD,
            ON_COMPLETE: MARKED_AS_COMPLETE
          }
        },
        [JobStatus.OPEN]: {
          on: {
            NEW_VISIT: NEW_VISIT_CREATED,
            ON_COMPLETE: MARKED_AS_COMPLETE,
            ON_WEB_HOLD: PUT_ON_HOLD,
            ON_CANCEL: MARKED_AS_CANCELED
          }
        },
        [JobStatus.IN_PROGRESS]: {
          on: {
            ON_WEB_HOLD: PUT_ON_HOLD,
            ON_COMPLETE: MARKED_AS_COMPLETE,
            ON_CANCEL: MARKED_AS_CANCELED
          }
        },
        [JobStatus.ON_HOLD]: {
          on: {
            ON_CONTINUE: ON_CONTINUE,
            ON_REOPEN: ON_REOPEN,
            ON_CANCEL: MARKED_AS_CANCELED,
            ON_COMPLETE: MARKED_AS_COMPLETE
          }
        },
        [JobStatus.COMPLETE]: {
          on: {
            ON_CONTINUE: ON_CONTINUE,
            ON_REOPEN: ON_REOPEN
          }
        },
        [JobStatus.CANCELED]: {
          on: {
            ON_CONTINUE: ON_CONTINUE,
            ON_REOPEN: ON_REOPEN
          }
        },
        [JobStatus.EXPORTED]: {
          ON_REOPEN: ON_REOPEN
        }
      },
      initial: initialState
    },
    {
      actions: {
        markJobAsOpen: context => updateJobStatusTo(context, JobActions.JOB_REOPEN),
        markJobAsInProgress: context => updateJobStatusTo(context, JobActions.JOB_CONTINUE),
        markJobAsOnHold: context => updateJobStatusTo(context, JobActions.JOB_ON_HOLD),
        markJobAsComplete: context => updateJobStatusTo(context, JobActions.JOB_COMPLETE),
        markJobAsCancelled: context => updateJobStatusTo(context, JobActions.JOB_CANCEL)
      },
      guards: {
        isOnCompleteValid: context => isOnCompleteValid(context),
        isCancelledValid: context => isCancelledValid(context),
        isOnHoldValid: context => isOnHoldValid(context),
        isInProgressValid: context => isInProgressValid(context),
        isOpenValid: context => isOpenValid(context)
      }
    }
  );

export default JobStateMachine;
