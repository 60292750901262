import gql from 'graphql-tag';

const getCompany = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      companyAddresses(entityConnection: "CompanyAddress") {
        items {
          addressType
          addressLine1
          addressLine2
          city
          state
          zipcode
          id
          tenantId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      logoUrl
      companyName
      email
      fax
      phonePrimary
      websiteUrl
      status
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      version
      employees(entityConnection: "Employee") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          firstName
          lastName
          name
          userName
          email
          personalEmail
          status
          cellPhone
          landlinePhone
          profilePictureUrl
          isAdmin
          nickName
          sameAddress
          contactAddresses(entityConnection: "ContactAddress") {
            items {
              addressType
              addressLine1
              addressLine2
              city
              state
              zipcode
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
            }
          }
          appRoles(entityConnection: "EmployeeAppRole") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              invertedSortKey
              hierarchy
              entityType
              mappedEntityValue
              mappedEntityType
              mappedEntityId
              systemEntityValue
              systemEntityType
              systemEntityId
            }
          }
          skills(entityConnection: "EmployeeSkill") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              invertedSortKey
              hierarchy
              entityType
              mappedEntityValue
              mappedEntityType
              mappedEntityId
              mappedEntity {
                ... on Skill {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  tagType
                  tagName
                }
              }
              systemEntityValue
              systemEntityType
              systemEntityId
            }
          }
          departments(entityConnection: "EmployeeDepartment") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              invertedSortKey
              hierarchy
              entityType
              mappedEntityValue
              mappedEntityType
              mappedEntityId
              systemEntityValue
              systemEntityType
              systemEntityId
            }
          }
        }
      }
      departments(entityConnection: "Department", limit: 100) {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          tagName
          accountingRefIdOfClass
          skills(entityConnection: "DepartmentSkill") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              invertedSortKey
              hierarchy
              entityType
              mappedEntityValue
              mappedEntityType
              mappedEntityId
              mappedEntity {
                ... on Skill {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  tagType
                  tagName
                }
              }
              systemEntityValue
              systemEntityType
              systemEntityId
            }
          }
          contacts(entityConnection: "DepartmentEmployee") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              invertedSortKey
              hierarchy
              entityType
              mappedEntityValue
              mappedEntityType
              mappedEntityId
              mappedEntity {
                ... on Employee {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  name
                  firstName
                  lastName
                  nickName
                  profilePictureUrl
                }
              }
              systemEntityValue
              systemEntityType
              systemEntityId
            }
          }
        }
      }
      skills(entityConnection: "Skill") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          tagName
          contacts(entityConnection: "SkillEmployee") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              invertedSortKey
              hierarchy
              entityType
              mappedEntityValue
              mappedEntityType
              mappedEntityId
              mappedEntity {
                ... on Employee {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  name
                  firstName
                  lastName
                  nickName
                  profilePictureUrl
                }
              }
              systemEntityValue
              systemEntityType
              systemEntityId
            }
          }
        }
        nextToken
      }
      appRoles(entityConnection: "AppRole") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          tagName
          tagType
          entityType
          appPermissions
          contacts(entityConnection: "AppRoleEmployee") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              invertedSortKey
              hierarchy
              entityType
              mappedEntityValue
              mappedEntityType
              mappedEntityId
              mappedEntity {
                ... on Employee {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  name
                  firstName
                  lastName
                  nickName
                  profilePictureUrl
                }
              }
              systemEntityValue
              systemEntityType
              systemEntityId
            }
          }
        }
      }
    }
  }
`;

export default getCompany;
