import React from 'react';
import { useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import DropdownCreatable from '../DropdownCreatable';
import { getCustomerRepsByCustomerById } from './gql';

const JobAuthorizedBy = props => {
  const { queryResult, queryPath } = props?.specialbehaviour || {};
  const { customerId } = queryResult;
  const { billingCustomerId } = props.form.values;

  let customerAndBillingCustomerRepsMapping = queryResult?.customerRepsByCustomerLevel || [];

  const { data: billingCustomer, loading } = useQuery(getCustomerRepsByCustomerById, {
    variables: { id: billingCustomerId },
    fetchPolicy: 'network-only',
    skip: customerId === billingCustomerId
  });

  if (billingCustomer) {
    const billingCustomerReps = billingCustomer?.getCustomerById?.customerReps?.items || [];
    customerAndBillingCustomerRepsMapping = customerAndBillingCustomerRepsMapping.concat(
      billingCustomerReps
    );
  }
  queryResult[queryPath] = customerAndBillingCustomerRepsMapping;

  return loading ? <Skeleton height={84} /> : <DropdownCreatable {...props} />;
};

export default JobAuthorizedBy;
