export const bundlesLayout = {
  entity: {
    name: 'item',
    tenant: 'default',
    layouts: {
      web: {
        sections: [
          {
            contextTitle: {
              new: 'Add product bundle',
              edit: 'Edit product bundle'
            },
            sm: '12',
            md: '12',
            lg: '12',
            rows: [
              {
                cols: [
                  {
                    type: 'dbHidden',
                    value: 'id',
                    name: 'id',
                    label: 'id',
                    behavior: {
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      }
                    }
                  },
                  {
                    type: 'dbHidden',
                    value: 'version',
                    name: 'version',
                    label: 'version',
                    behavior: {
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'name',
                    name: 'name',
                    label: 'name',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'description',
                    name: 'description',
                    label: 'description',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      }
                    }
                  }
                ]
              },
              {
                cols: [
                  {
                    type: 'dbField',
                    value: 'category',
                    name: 'category',
                    label: 'category',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'subCategory',
                    name: 'subCategory',
                    label: 'subCategory',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      }
                    }
                  }
                ]
              },
              {
                cols: [
                  {
                    type: 'dbField',
                    value: 'type',
                    name: 'type',
                    label: 'type',
                    specialBehaviour: {
                      includeQueryResult: true,
                      queryPath: 'bundleType',
                      fieldName: 'name',
                      fieldValue: 'value'
                    },
                    behavior: {
                      new: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'isActive',
                    name: 'isActive',
                    label: 'active',
                    behavior: {
                      edit: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      }
                    }
                  }
                ]
              }
            ]
          }
        ],
        buttons: {
          save: {
            label: 'Save',
            color: 'primary',
            buttonType: 'contained',
            behavior: {
              new: true,
              edit: true
            }
          },
          cancel: {
            label: 'Cancel',
            color: 'secondary',
            buttonType: 'contained',
            behavior: {
              new: true,
              edit: true
            }
          },
          deleteItem: {
            label: 'Delete',
            validateKey: 'employees'
          }
        }
      },
      search: null
    }
  }
};

export default bundlesLayout;
