export const dragOffset = {
  x: 20,
  y: 30
};

export const DragScenarios = {
  FULLY_ASSIGNED_SINGLE_TECH: 'FULLY_ASSIGNED_SINGLE_TECH',
  UNASSIGNED_SINGLE_TECH: 'UNASSIGNED_SINGLE_TECH',
  VISIT_BOARD_TO_BOARD: 'VISIT_BOARD_TO_BOARD',
  NON_VISIT_BOARD_TO_BOARD: 'NON_VISIT_BOARD_TO_BOARD',
  FULLY_ASSIGNED_MULTI_TECH: 'FULLY_ASSIGNED_MULTI_TECH',
  VISIT_START: 'VISIT_START',
  VISIT_END: 'VISIT_END',
  EVENT_START: 'EVENT_START',
  EVENT_END: 'EVENT_END',
  MAN_DAY_BOARD_TO_BOARD: 'MAN_DAY_BOARD_TO_BOARD',
  MAN_DAY_START: 'MAN_DAY_START',
  MAN_DAY_END: 'MAN_DAY_END',
  NO_DRAG: 'NO_DRAG'
};

export const ItemTypes = {
  TABLE_VISIT: 'TABLE_VISIT',
  VISIT_START: 'VISIT_START',
  VISIT_END: 'VISIT_END',
  EVENT_START: 'EVENT_START',
  EVENT_END: 'EVENT_END',
  BOARD_VISIT: 'BOARD_VISIT',
  NON_VISIT_EVENT: 'NON_VISIT_EVENT',
  MAN_DAY: 'MAN_DAY',
  MAN_DAY_START: 'MAN_DAY_START',
  MAN_DAY_END: 'MAN_DAY_END'
};
