const visitFormLayout = options => ({
  fields: {
    visitFormIds: {
      edit: 'visitFormIds'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                paddingTop: 12,
                paddingBottom: 12
              },
              contents: [
                {
                  component: {
                    edit: 'SelectInput'
                  },
                  source: 'visitFormIds',
                  options: {
                    label: 'Forms',
                    placeholder: 'Select Forms',
                    valueSet: options,
                    isRequired: false,
                    fullWidth: true,
                    alignment: 'left',
                    isMultipleSelection: true
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  }
});

export default visitFormLayout;
