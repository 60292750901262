const CrewDetailForm = settings => ({
  fields: {
    name: {
      default: 'name',
      edit: 'name'
    },
    departmentId: {
      default: 'departmentId',
      edit: 'departmentId'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          options: {
            size: 3,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'name',
                  options: {
                    label: 'Crew Name',
                    placeholder: '',
                    isRequired: true
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 3,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'SelectInput',
                    default: null
                  },
                  source: 'departmentId',
                  options: {
                    label: 'Department',
                    placeholder: 'Select...',
                    valueSet: settings.departmentOptions,
                    valuesToSet: [
                      {
                        foremanId: null,
                        techIds: []
                      }
                    ],
                    isRequired: true
                  }
                },
                {
                  component: {
                    edit: null,
                    default: 'FieldWithLabel'
                  },
                  source: 'departmentName',
                  options: {
                    label: 'Department'
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 3,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: null,
                    edit: 'ForemanSelector'
                  },
                  source: 'foremanId',
                  options: {
                    label: 'Foreman',
                    isRequired: false,
                    fullWidth: true,
                    alignment: 'left',

                    filterOptions: settings.foremanFilterOptions
                  },
                  dependentField: {
                    fieldName: 'departmentId',
                    expectedValue: false,
                    operation: 'bool',
                    action: 'DISABLE'
                  }
                },
                {
                  component: {
                    default: 'FieldWithLabel',
                    edit: null
                  },
                  source: 'foremanName',
                  options: {
                    label: 'Foreman',
                    isRequired: false,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 3,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 2,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'MultiEmployeeSelector',
                    default: null
                  },
                  source: 'techIds',
                  options: {
                    label: 'Technicians',
                    isRequired: false,
                    fullWidth: true,
                    alignment: 'left',

                    filterOptions: settings.employeesFilterOptions
                  },
                  dependentField: {
                    fieldName: 'departmentId',
                    expectedValue: false,
                    operation: 'bool',
                    action: 'DISABLE'
                  }
                },
                {
                  component: {
                    edit: null,
                    default: 'FieldWithLabel'
                  },
                  source: 'techNames',
                  options: {
                    label: 'Technicians',
                    isRequired: false,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      name: {
        type: 'string'
      }
    },
    required: ['name']
  },
  validationErrors: {
    name: {
      required: 'Crew name is required.'
    },
    departmentId: {
      required: 'Department is required.'
    }
  }
});

export default CrewDetailForm;
