import React, { useEffect, useMemo, useState } from 'react';

import {
  InlineAlert,
  InlineAlertTypes,
  MoreButton,
  Typography as SgtTypography,
  ThemeProvider,
  TV,
  TW
} from '@buildhero/sergeant';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import checkedIcon from 'assets/Icons/Outline.svg';
import { ConfirmModal, StatusChip, TagButtons } from 'components';
import Context from 'components/Context';
import IconMap from 'meta/IconMap';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { getProjectById } from 'services/API/project';
import { purchaseOrderChange } from 'services/API/purchaseOrder';
import { JobService, QuoteService } from 'services/core';
import { getTenantSettingValueForKey, logErrorWithCallback } from 'utils';
import { QuoteAddedItem, QuoteConstants, QuoteStatus, TagType } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import AddPurchaseOrder from '../../../Procurement/PurchaseOrders/CreatePurchaseOrder/components/AddPurchaseOrder';
import AddProject from '../../../ProjectManagement/components/CreateProject/components/AddProject';
import { SettingConstants } from '../../constants';
import QuotesUtils from '../../index.utils';
import { updateQuotePurchaseOrders, useGetJobsQuery } from '../../service';
import { getButtonsForQuote } from '../../utils/helper';
import { changeVersionsView } from '../../utils/utils.versions';

import VersionComp from '../versionComponent';

import AddButton from './AddButton';

import AssociatedQuoteAddedItem from './AssociatedQuoteAddedItem';
import PageHeaderHandlers from './index.handlers';
import PageHeaderUtils from './index.utils';
import ChangeQuoteStatusModal from './Modals/ChangeQuoteStatusModal';
import ExistingJobModal from './Modals/ExistingJobModal';
import NewJobModal from './Modals/NewJobModal';
import quoteToPoGeneralInfoTitle from './quoteToPoGeneralInfoTitle';
import { useStyles } from './styles';

const propTypes = {
  addExistingJobToQuote: PropTypes.func,
  cancelQuoteMutation: PropTypes.func,
  cloneOrReopenQuoteMutation: PropTypes.func,
  fetchQuoteInfo: PropTypes.func,
  fetchVersionedQuoteInfo: PropTypes.func,
  handleActionIfDeactivated: PropTypes.func,
  styles: PropTypes.shape({ buttonProgress: PropTypes.string }),
  quoteVersions: PropTypes.array.isRequired,
  createNewVersion: PropTypes.func,
  poNumber: PropTypes.string,
  setPoNumber: PropTypes.func,
  setPrimaryVersion: PropTypes.func,
  quoteInfo: PropTypes.shape({
    id: PropTypes.string,
    departmentId: PropTypes.string,
    status: PropTypes.string,
    name: PropTypes.string,
    displayJobNumber: PropTypes.string,
    jobPath: PropTypes.string,
    orderedById: PropTypes.string,
    ownerId: PropTypes.string,
    propertyRepId: PropTypes.string
  }).isRequired,
  addToJob: PropTypes.func,
  primaryQuoteId: PropTypes.string,
  isLoading: PropTypes.bool,
  hasPermissionToEditQuote: PropTypes.bool
};

const defaultProps = {
  addExistingJobToQuote: () => {},
  styles: {},
  createNewVersion: () => {},
  fetchQuoteInfo: () => {},
  fetchVersionedQuoteInfo: () => {},
  handleActionIfDeactivated: () => {},
  setPrimaryVersion: () => {},
  addToJob: () => {},
  cancelQuoteMutation: () => {},
  cloneOrReopenQuoteMutation: () => {},
  poNumber: '',
  primaryQuoteId: '',
  isLoading: false,
  hasPermissionToEditQuote: false,
  setPoNumber: () => {}
};

const Header = ({
  addToJob,
  addExistingJobToQuote,
  cancelQuoteMutation,
  cloneOrReopenQuoteMutation,
  createNewVersion,
  customerPropertyId,
  fetchQuoteInfo,
  fetchVersionedQuoteInfo,
  handleActionIfDeactivated,
  hasPermissionToEditQuote,
  headerButtons,
  isLoading,
  poNumber,
  primaryQuoteId,
  propertyDetails,
  quoteAttachments,
  quoteInfo,
  quotePurchaseOrders,
  quoteTags,
  quoteVersions,
  setPoNumber,
  setPrimaryVersion,
  snackbar,
  user,
  serviceProps
}) => {
  const module = 'Quotes';
  const title = quoteInfo?.name || 'Quote';
  const classes = useStyles();
  const [validButtons, setValidButtons] = useState([]);
  const versionOptions = { data: { items: [...quoteVersions] } };
  const [confirmData, setConfirmData] = useState({
    confirmDialog: '',
    confirmMessage: '',
    confirmAction: ''
  });
  const [loading, setLoading] = useState(false);
  const [openReasonsModal, setOpenReasonsModal] = useState(false);
  const [quoteStatus, setQuoteStatus] = useState('');
  const [statusReason, setStatusReason] = useState('');
  const [openExistingJobModal, setOpenExistingJobModal] = useState(false);
  const [openNewJobModal, setOpenNewJobModal] = useState(false);
  const [existingJobOptions, setExistingJobOptions] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState(false);
  const [isAddPurchaseOrderModalOpen, setIsAddPurchaseOrderModalOpen] = useState(false);
  const [project, setProject] = useState(null);
  const { palette } = useTheme();
  const flags = useFlags();
  const hasTotalBudgetedHoursOn = PageHeaderUtils.hasTotalBudgetedHoursOn({
    getTenantSettingValueForKey,
    quoteSettings: quoteInfo.settingsJSON || '{}',
    SettingConstants
  });
  const isCustomJobNumberEnabled =
    (Context.getCompanyContext()?.getCompany &&
      getTenantSettingValueForKey('job_customJobNumber') === 'true' &&
      !getTenantSettingValueForKey('custom_job_expression')?.trim()?.length > 0) ||
    false;
  const propertyName = propertyDetails?.property?.companyName;
  const companyName = propertyDetails?.customer?.customerName;
  const filters = {
    items: [
      {
        columnField: 'status',
        operatorValue: 'notContains',
        value: 'Canceled'
      },
      {
        columnField: 'status',
        operatorValue: 'notContains',
        value: 'Complete'
      },
      {
        columnField: 'customerName',
        operatorValue: 'equals',
        value: companyName
      },
      {
        columnField: 'propertyName',
        operatorValue: 'equals',
        value: propertyName
      }
    ]
  };

  const { data: existingJobs } = useGetJobsQuery(
    user.tenantId,
    { limit: 250, offset: 0 },
    filters,
    snackbar,
    {
      skip:
        !(quoteInfo.status === QuoteConstants.Approved && quoteInfo.id === primaryQuoteId) ||
        !companyName ||
        !propertyName
    }
  );

  useEffect(() => {
    if (existingJobs) {
      const formattedOptions = existingJobs.map(option => ({
        label: option.customIdentifier ?? option.jobNumber,
        value: option.id,
        status: option.status,
        jobNumber: option.jobNumber
      }));
      setExistingJobOptions(formattedOptions);
    }
  }, [existingJobs]);

  useEffect(() => {
    const setDotMenu = async () => {
      const moreOptionBtns = getButtonsForQuote({
        updateQuoteStatusMutation: status =>
          PageHeaderHandlers.updateQuoteNonProjectStatus({ status, poNumber, serviceProps }),
        cancelQuote: () => cancelQuoteMutation(setConfirmData),
        cloneQuote: cloneOrReopenQuoteMutation,
        handleActionIfDeactivated,
        quoteInfo,
        reopenQuote: cloneOrReopenQuoteMutation,
        setOpenReasonsModal,
        setQuoteStatus,
        setConfirmData
      });
      if (!_.isEmpty(moreOptionBtns)) {
        const validButtonsArr = Object.keys(moreOptionBtns).map(btn => ({
          type: btn,
          ...moreOptionBtns[btn],
          onClick: () => moreOptionBtns[btn].action()
        }));
        setValidButtons(validButtonsArr);
      }
    };
    setDotMenu();
  }, [quoteInfo, primaryQuoteId]);

  useEffect(() => {
    if (
      !project &&
      quoteInfo &&
      quoteInfo.addedItem === QuoteAddedItem.PROJECT &&
      quoteInfo.projectId
    ) {
      getProjectById(quoteInfo.projectId).then(proj => {
        setProject(proj);
      });
    }
  }, [quoteInfo]);

  const addJobToPO = jobId => {
    if (!jobId) return;
    return quotePurchaseOrders.forEach(async po => {
      await purchaseOrderChange(po.poId, { jobId });
    });
  };

  const updateJobAttachments = (jobData, attachments) => {
    if (!attachments) return;
    const formattedAttachments = attachments.map(a => ({
      id: a.id,
      fileName: a.fileName,
      fileUrl: a.fileUrl,
      type: a.type,
      comment: a.comment,
      customFileName: a.customFileName,
      isUploaded: a.isUploaded,
      hideFromTechniciansOnMobile: a.hideFromTechniciansOnMobile
    }));

    try {
      new JobService().addJobAttachments(
        jobData?.job?.tenantId,
        jobData?.job?.id,
        formattedAttachments
      );
    } catch (error) {
      logErrorWithCallback(error, snackbar, 'Unable to add attachments to job');
    }
  };

  const handleAddToJob = async formData => {
    setLoading(true);
    const attachments = formData?.attachments.filter(a => a.checked);
    const totalBudgetedLaborHours = PageHeaderUtils.calcTotalBudgetedLaborHours(
      quoteInfo.quoteLineTasks,
      hasTotalBudgetedHoursOn
    );

    if (isCustomJobNumberEnabled) {
      const jobService = new JobService();
      if (!formData.jobNumber) {
        setLoading(false);
        setOpenNewJobModal(false);
        return snackbar('error', 'Job number is required');
      }
      const doesCustomNumberExist = await jobService.checkCustomJobNumberExists(formData.jobNumber);
      if (doesCustomNumberExist) {
        setLoading(false);
        setOpenNewJobModal(false);
        return snackbar('error', 'Another job with that number already exists.');
      }
    }
    const response = await addToJob(formData?.jobNumber, totalBudgetedLaborHours);
    const jobData = response?.quoteJobs?.items[0];
    addJobToPO(jobData?.job?.id);
    updateJobAttachments(jobData, attachments);
    setLoading(false);
    setOpenNewJobModal(false);
  };

  const handleStatusChangeSubmit = async () => {
    setLoading(true);
    quoteStatus === 'Approve'
      ? await PageHeaderHandlers.updateQuoteNonProjectStatus({
          status: QuoteStatus.APPROVED,
          statusReason,
          poNumber,
          serviceProps
        })
      : await PageHeaderHandlers.updateQuoteNonProjectStatus({
          status: QuoteStatus.REJECTED,
          statusReason,
          poNumber,
          serviceProps
        });
    setQuoteStatus('');
    setOpenReasonsModal(false);
    setLoading(false);
  };

  const handleAddExistingJobToQuote = async formData => {
    const totalBudgetedLaborHours = PageHeaderUtils.calcTotalBudgetedLaborHours(
      quoteInfo.quoteLineTasks,
      hasTotalBudgetedHoursOn
    );
    setLoading(true);
    const response = await addExistingJobToQuote(selectedJob.value, totalBudgetedLaborHours);
    const jobData = response?.quoteJobs?.items[0];
    addJobToPO(jobData?.job?.id);
    const attachments = formData?.attachments?.filter(a => a.checked);
    if (attachments?.length) {
      updateJobAttachments(jobData, attachments);
    }
    setLoading(false);
    setOpenExistingJobModal(false);
  };

  const formattedQuoteTitle = useMemo(() => {
    if (quoteInfo?.name) {
      return `Quote: ${quoteInfo.name} - ${quoteInfo?.customIdentifier ||
        quoteInfo?.quoteNumber ||
        ''}`;
    }
    return `Quote ${quoteInfo?.customIdentifier || quoteInfo?.quoteNumber || ''}`;
  }, [quoteInfo.name, quoteInfo.customIdentifier, quoteInfo.quoteNumber]);

  return (
    <ErrorBoundaries>
      <Box style={{ paddingBottom: 24 }}>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className={classes.breadCrumb}>
              {' '}
              {`${module}`} <span className={classes.breadCrumbSlash}>/</span>{' '}
              {`${title} ${quoteInfo?.customIdentifier || quoteInfo?.quoteNumber || ''}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-between" style={{ flexWrap: 'nowrap' }}>
          <Box container direction="row" maxWidth="80%">
            <Box display="flex" alignItems="center" flexWrap="wrap" style={{ marginBottom: 0 }}>
              <IconMap
                icon="quotesIcon"
                style={{ color: palette.brand.logoGreen, marginRight: 5.25 }}
              />
              <SgtTypography
                variant={TV.XL}
                weight={TW.BOLD}
                color={palette.grayscale(20)}
                style={{ marginRight: 12 }}
              >
                {formattedQuoteTitle}
              </SgtTypography>
              <Box>
                <StatusChip
                  label={QuotesUtils.mapQuoteStatusLabel(quoteInfo.status, quoteInfo)}
                  enumType={EnumType.QUOTE_STATUS}
                  enumValue={
                    QuotesUtils.quoteHasJob(quoteInfo) && quoteInfo.status === QuoteStatus.APPROVED
                      ? QuoteStatus.JOB_ADDED
                      : quoteInfo.status
                  }
                />
              </Box>
              <VersionComp
                createNewService={() => createNewVersion()}
                hasPermissionToEditQuote={hasPermissionToEditQuote}
                optionUpdateService={() => {}}
                getOptions={versionOptions}
                quoteInfo={quoteInfo}
                newCreationLabel="Create New Version"
                setPrimaryService={selectedValue => setPrimaryVersion(selectedValue)}
                changeVersionsView={(selectedValue, versionOptions) =>
                  changeVersionsView(
                    selectedValue,
                    versionOptions,
                    fetchQuoteInfo,
                    fetchVersionedQuoteInfo
                  )
                }
              />
              {!hasPermissionToEditQuote && (
                <InlineAlert
                  type={InlineAlertTypes.BLUE}
                  message="You do not have permission to update quotes."
                  style={{ margin: 0 }}
                />
              )}
              {quoteInfo.id && hasPermissionToEditQuote && (
                <TagButtons
                  info={{
                    id: quoteInfo.id,
                    version: quoteInfo.version,
                    customerPropertyId,
                    primaryQuoteId
                  }}
                  tags={quoteTags}
                  getService={() => new QuoteService()}
                  TagType={TagType.QUOTE}
                />
              )}
            </Box>
            <AssociatedQuoteAddedItem quoteInfo={quoteInfo} locale={user.locale} />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-end" flexShrink={0}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              {(flags[FeatureFlags.QUOTE_TO_PROJECT] || flags[FeatureFlags.PROJECT_MANAGEMENT]) && (
                <AddProject
                  data-testid="add-project"
                  user={user}
                  addProjectDetails={PageHeaderUtils.composeAddProjectDetails(
                    propertyDetails,
                    quoteInfo
                  )}
                  addProjectButtonStyle={{ display: 'none' }}
                  isModalOpenExternally={isAddProjectModalOpen}
                  parentCallback={setIsAddProjectModalOpen}
                  updateWithProjectIdHandler={projectId => {
                    PageHeaderHandlers.updateQuoteProjectStatus({
                      projectId,
                      poNumber,
                      serviceProps
                    });
                  }}
                />
              )}
              <AddPurchaseOrder
                user={user}
                isModalOpen={isAddPurchaseOrderModalOpen}
                setModalOpen={setIsAddPurchaseOrderModalOpen}
                onCloseParentCallback={updateQuotePurchaseOrders}
                addPurchaseOrderData={PageHeaderUtils.composeAddPurchaseOrderData(quoteInfo)}
                generalInfoCustomField={quoteToPoGeneralInfoTitle(quoteInfo)}
                parentData={serviceProps}
                jobProjectFieldReadOnly
              />
              {quoteInfo.displayJobNumber && quoteInfo.jobPath ? (
                <p>
                  <img
                    src={checkedIcon}
                    alt=""
                    height="16px"
                    width="16px"
                    style={{ marginRight: '4px' }}
                  />
                  ADDED TO{' '}
                  <Link to={quoteInfo.jobPath} style={{ color: '#00874d' }}>
                    JOB {quoteInfo.displayJobNumber}
                  </Link>
                </p>
              ) : (
                <AddButton
                  handleAddToJob={handleAddToJob}
                  isLoading={isLoading}
                  quoteAttachments={quoteAttachments}
                  quoteStatus={quoteInfo?.status}
                  setIsAddProjectModalOpen={setIsAddProjectModalOpen}
                  setOpenExistingJobModal={setOpenExistingJobModal}
                  setOpenNewJobModal={setOpenNewJobModal}
                  setIsAddPurchaseOrderModalOpen={setIsAddPurchaseOrderModalOpen}
                  quoteInfo={quoteInfo}
                />
              )}
              {headerButtons}

              {!isLoading && !_.isEmpty(validButtons) && hasPermissionToEditQuote && (
                <ThemeProvider>
                  <MoreButton options={validButtons} style={{ fontSize: '1.2rem' }} />
                </ThemeProvider>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
      <ConfirmModal
        open={confirmData.confirmDialog}
        confirm={confirmData.confirmAction}
        cancel={() =>
          setConfirmData({ confirmDialog: false, confirmMessage: '', confirmAction: '' })
        }
        message={confirmData.confirmMessage}
        override
      />
      <ChangeQuoteStatusModal
        handleStatusChangeSubmit={handleStatusChangeSubmit}
        loading={loading}
        openReasonsModal={openReasonsModal}
        poNumber={poNumber}
        quoteStatus={quoteStatus}
        setOpenReasonsModal={setOpenReasonsModal}
        setPoNumber={setPoNumber}
        setStatusReason={setStatusReason}
        statusReason={statusReason}
      />
      <ExistingJobModal
        classes={classes}
        existingJobOptions={existingJobOptions}
        flags={flags}
        handleAddExistingJobToQuote={handleAddExistingJobToQuote}
        loading={loading}
        openExistingJobModal={openExistingJobModal}
        propertyDetails={propertyDetails}
        quoteAttachments={quoteAttachments}
        quoteInfo={quoteInfo}
        selectedJob={selectedJob}
        setOpenExistingJobModal={setOpenExistingJobModal}
        setSelectedJob={setSelectedJob}
        snackbar={snackbar}
      />
      <NewJobModal
        classes={classes}
        handleAddToJob={handleAddToJob}
        isCustomJobNumberEnabled={isCustomJobNumberEnabled}
        loading={loading}
        openNewJobModal={openNewJobModal}
        quoteAttachments={quoteAttachments}
        setOpenNewJobModal={setOpenNewJobModal}
      />
    </ErrorBoundaries>
  );
};

Header.propTypes = propTypes;

Header.defaultProps = defaultProps;
const mapDispatchToProps = dispatch => ({
  snackbar: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

export default connect(null, mapDispatchToProps)(Header);
