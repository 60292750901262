import gql from 'graphql-tag';

const jobAttachmentUpdateNotification = gql`
  subscription jobAttachmentUpdateNotification($partitionKey: String!) {
    jobAttachmentUpdateNotification(partitionKey: $partitionKey) {
      fileName
      customFileName
      fileUrl
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      description
      createdDate
      createdDateTime
      createdBy
      lastUpdatedDateTime
      parentId
    }
  }
`;

export default jobAttachmentUpdateNotification;
