/* eslint-disable import/prefer-default-export */
import { Storage } from 'aws-amplify';

export const VisitAssetDetailLayout = {
  fields: {
    description: {
      default: 'description'
    },
    attachments: {
      default: 'attachments'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 1,
        direction: 'row'
      },
      contents: [
        {
          options: {
            grow: 1
          },
          contents: [
            {
              component: {
                default: 'FieldWithLabel'
              },
              source: 'description',
              options: {
                label: 'Summary'
              }
            }
          ]
        },
        {
          options: {
            grow: 2
          },
          contents: [
            {
              component: {
                default: 'AttachmentList'
              },
              source: 'attachments',
              options: {
                Storage
              }
            }
          ]
        }
      ]
    }
  }
};
