import gql from 'graphql-tag';

const getTrucksList = gql`
  query getTrucksList(
    $partitionKey: String!
    $sortKey: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      trucks: trucksView(
        entityConnection: "Truck"
        filter: $filter
        pagination: { limit: $limit, offset: $offset }
        sorting: $sort
      ) {
        nextToken
        items {
          id
          version
          name
          productBundleId
          productBundle(entityConnection: "ProductBundle") {
            id
            version
            name
            category
            subCategory
            type
            isActive
          }
          truckEmployees(entityConnection: "TruckEmployee") {
            items {
              id
              version
              employee(entityConnection: "Employee") {
                id
                firstName
                lastName
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default getTrucksList;
