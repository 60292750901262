export const tempFormFields = ['viewOnlyName', 'tempDescription'];

const labelComponent = {
  edit: 'FieldWithLabel',
  default: 'FieldWithLabel'
};

export const viewOnlyFields = [
  {
    label: 'name',
    source: 'viewOnlyName',
    component: labelComponent
  },
  {
    label: 'description',
    source: 'tempDescription',
    component: {
      edit: 'TextInput',
      default: 'FieldWithLabel'
    }
  }
];
