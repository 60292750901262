const formTableRowActionButton = {
  pdf: {
    label: 'PDF',
    icon: 'PictureAsPdf',
    caslAction: 'update',
    caslKey: ''
  },
  edit: {
    label: 'Edit',
    icon: 'Edit',
    caslAction: 'update',
    caslKey: ''
  },
  remove: {
    label: 'Remove',
    icon: 'Delete',
    caslAction: 'delete',
    caslKey: ''
  }
};

export default formTableRowActionButton;
