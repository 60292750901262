import React, { useState } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Placeholder, SergeantModal } from 'components';
import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import MaintenanceList from 'scenes/Maintenance/MaintenanceList';
import { ServiceAgreementStatus } from 'utils/AppConstants';
import { EntityType, Mode } from 'utils/constants';

import { AGREEMENT_ACTIONS } from '../../constants';
import { updateServiceAgreementPayload } from '../../helpers';
import { transitionServiceAgreement } from '../../service';
import AddNewView from '../Components/AddNewView';

import { SA_MAINTENANCE_LIST_DATA } from '../queries';

import MaintenanceTemplate from './Components/MaintenanceTemplate';
import MaintenanceTemplateV2 from './Components/MaintenanceTemplate/MaintenanceTemplateV2';
import Templates from './Components/Templates';
import { constructMaintenanceTemplatePayload } from './helpers';
import {
  deleteMaintenanceTemplate,
  getMaintenanceTemplates,
  saveMaintenanceTemplates,
  updateMaintenanceTemplate
} from './services';
import styles from './style';

const RecurringMaintenance = props => {
  const {
    agreementInfo,
    user,
    selectedProperties,
    isAgreementActive,
    isReadOnly,
    onTemplateUpdate,
    snackbarOn: snackbar,
    serviceAgreementsSettings,
    companyTimeZone
  } = props;
  const [modal, setModal] = useState({ open: false, data: {} });
  const [confirmModal, setConfirmModal] = useState({ open: false, data: {} });
  const [loading, setLoading] = useState(false);
  const [maintenanceTemplates, setMaintenanceTemplates] = useState([]);

  const { maintenanceTemplatesV2 } = useFlags();

  const fetchMaintenanceTemplates = React.useCallback(async () => {
    if (!agreementInfo?.id) return;

    setLoading(true);
    await getMaintenanceTemplates({
      user,
      snackbar,
      successCallback: setMaintenanceTemplates,
      id: agreementInfo?.id
    });
    setLoading(false);
  }, [agreementInfo.id, user, snackbar]);

  const successCallback = () => {
    fetchMaintenanceTemplates();
    setModal({ open: false, data: {} });
    setConfirmModal({ open: false, data: {} });
    onTemplateUpdate({ status: ServiceAgreementStatus.DRAFT });
    transitionServiceAgreement(
      AGREEMENT_ACTIONS.UPDATE,
      updateServiceAgreementPayload(agreementInfo),
      user.tenantId
    );
  };

  const handleSaveTemplate = async data => {
    const payload = constructMaintenanceTemplatePayload(data, maintenanceTemplatesV2);
    if (payload?.id) {
      await updateMaintenanceTemplate({
        data: payload,
        snackbar,
        successCallback,
        maintenanceTemplatesV2
      });
    } else {
      await saveMaintenanceTemplates({
        data: payload,
        snackbar,
        serviceAgreementId: agreementInfo?.id,
        successCallback,
        maintenanceTemplatesV2
      });
    }
  };

  const handleDeleteTemplate = async (data, modalCallback) => {
    await deleteMaintenanceTemplate({
      data,
      snackbar,
      successCallback
    });
    modalCallback();
  };

  React.useEffect(() => {
    fetchMaintenanceTemplates();
  }, [fetchMaintenanceTemplates]);

  return !agreementInfo?.id || loading ? (
    <Placeholder />
  ) : (
    <div>
      {!(isAgreementActive || isReadOnly) ? (
        <>
          <Templates
            handleAddTemplate={() => setModal({ open: true, data: {} })}
            handleEditTemplate={data => {
              setModal({ open: true, data });
            }}
            handleDeleteTemplate={data => {
              const deleteItemLabel = `${data.customerProperty?.companyName}${
                data.serviceDescription ? `: ${data.serviceDescription}` : ''
              }`;
              setConfirmModal({ open: true, deleteItemLabel, data });
            }}
            data={maintenanceTemplates}
            shouldShowAddButton={!isReadOnly && maintenanceTemplates.length !== 0}
            isReadOnly={isReadOnly}
            companyTimeZone={companyTimeZone}
          />
          {maintenanceTemplates.length === 0 && (
            <AddNewView
              label={Labels.addMaintenanceRecord[user.locale]}
              disabled={isReadOnly}
              handleAddNew={() => setModal({ open: true, data: {} })}
              key="addmaintenancetemplate"
              description="Please add maintenance template."
            />
          )}
          <SergeantModal
            open={confirmModal.open}
            dataType={EntityType.MAINTENANCE_TEMPLATE}
            confirmRemoveItemLabel={confirmModal.deleteItemLabel}
            mode={Mode.DELETE}
            data={confirmModal.data}
            handlePrimaryAction={handleDeleteTemplate}
            handleClose={() => setConfirmModal({ open: false })}
          />
          <ErrorBoundaries>
            {maintenanceTemplatesV2 ? (
              <MaintenanceTemplateV2
                isOpen={modal.open}
                data={modal.data}
                agreementInfo={agreementInfo}
                selectedProperties={selectedProperties}
                onSaveTemplate={handleSaveTemplate}
                serviceAgreementsSettings={serviceAgreementsSettings}
                handleClose={() => {
                  setModal({ open: false, data: {} });
                }}
                companyTimeZone={companyTimeZone}
              />
            ) : (
              <MaintenanceTemplate
                open={modal.open}
                data={modal.data}
                agreementInfo={agreementInfo}
                selectedProperties={selectedProperties}
                onSaveTemplate={handleSaveTemplate}
                serviceAgreementsSettings={serviceAgreementsSettings}
                handleClose={() => {
                  setModal({ open: false, data: {} });
                }}
                companyTimeZone={companyTimeZone}
              />
            )}
          </ErrorBoundaries>
        </>
      ) : (
        <div style={{ marginTop: -36 }}>
          <MaintenanceList
            serviceAgreementId={agreementInfo?.id}
            agreementInfo={agreementInfo}
            overrideQuery={SA_MAINTENANCE_LIST_DATA}
            companyTimeZone={companyTimeZone}
            isInlineTable
            resultNode="serviceAgreementMaintenanceListData"
          />
        </div>
      )}
    </div>
  );
};

RecurringMaintenance.propTypes = {
  isReadOnly: PropTypes.bool,
  user: PropTypes.shape({
    tenantId: PropTypes.string,
    tenantCompanyId: PropTypes.string
  }).isRequired,
  serviceAgreementsSettings: PropTypes.object.isRequired
};

RecurringMaintenance.defaultProps = {
  isReadOnly: false
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatcherToProps = { snackbarOn };

export default connect(
  mapStateToProps,
  mapDispatcherToProps
)(withStyles(styles)(RecurringMaintenance));
