import gql from 'graphql-tag';

const getPropertyInfoById = gql`
  query getCustomerPropertyInfoById($id: String!) {
    getCustomerPropertyById(id: $id) {
      id
      propertyAssets {
        items {
          id
          assetName
          assetTypeId
          assetType {
            tagName
          }
          isActive
          make
          serialNo
          modelNumber
          imageUrl
          forms {
            id
            name
            description
          }
          maintenanceTaskRequiredParts {
            items {
              id
              quantity
              priceBookEntryDescription
              pricebookEntry {
                id
                product {
                  id
                  name
                  description
                  code
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default getPropertyInfoById;
