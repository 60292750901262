const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5),
    margin: '15px 0',
    width: '100s%'
  },
  actionButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    float: 'left',
    width: '100%',
    marginLeft: '-15px'
  }
});

export default styles;
