export const truckTemplate = 'TruckTemplate';
export const taskTemplate = 'TaskTemplate';

const templateTypes = [
  { label: 'Task Template', value: taskTemplate },
  { label: 'Truck Template', value: truckTemplate }
];

const formType = {
  newBundle: 'newBundle',
  addToBundle: 'addToBundle'
};

const algoliaFilters = [
  {
    attribute: 'entityType',
    valueArray: ['ProductBundle']
  }
];

export { templateTypes, formType, algoliaFilters };
