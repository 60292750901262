/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getSubmittalItems(projectId, noInclude) {
  return await instance
    .get(`submittalitems/byproject/${projectId}`, {
      params: { include: noInclude ? [] : ['submittalPackages', 'submittalItemAttachments'] }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getSubmittalItemById(submittalItemId, noInclude) {
  return await instance
    .get(`submittalitems/${submittalItemId}`, {
      params: { include: noInclude ? [] : ['submittalPackages', 'submittalItemAttachments'] }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return {};
    });
}

export async function getNextNumber(projectId) {
  let paramsClause = {};
  if (projectId) {
    paramsClause = { projectId };
  }
  return await instance
    .get('submittalitems/next/number', {
      params: paramsClause
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return {};
    });
}

export async function submittalItemCreate(submittalItem) {
  return await instance
    .post('submittalitems', submittalItem)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function submittalItemChange(id, submittalItem) {
  return await instance
    .put(`submittalitems/${id}`, submittalItem)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function submittalItemDelete(id) {
  return await instance
    .delete(`submittalitems/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
