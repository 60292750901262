import gql from 'graphql-tag';

const addQuoteQuoteTagsToQuote = gql`
  mutation addQuoteQuoteTagsToQuote($partitionKey: String, $data: AddQuoteQuoteTagsToQuoteInput!) {
    addQuoteQuoteTagsToQuote(partitionKey: $partitionKey, data: $data) {
      id
      quoteTag(entityConnection: "QuoteTag") {
        tagType
        tagName
        id
      }
    }
  }
`;

export default addQuoteQuoteTagsToQuote;
