export const labelSchema = {
  'Access rights to': {
    en: 'Access rights to',
    es: 'Derechos de acceso para'
  },
  company: {
    en: 'Company',
    es: 'Company'
  },
  people: {
    en: 'People',
    es: 'People'
  },
  customers: {
    en: 'Customers',
    es: 'Customers'
  },
  jobs: {
    en: 'Jobs',
    es: 'Jobs'
  },
  dispatch: {
    en: 'Dispatch',
    es: 'Dispatch'
  },
  mobile: {
    en: 'Mobile',
    es: 'Mobile'
  },
  companyinfo: {
    en: 'Company info',
    es: 'Company info'
  },
  departmentSkills: {
    en: 'Department and Skills',
    es: 'Department and Skills'
  },
  roleAndAccessRights: {
    en: 'Roles and access Rights',
    es: 'Roles and access Rights'
  },
  employeePersonalInfo: {
    en: 'Employee personal info',
    es: 'Employee personal info'
  },
  employeeSettings: {
    en: 'Employee settings for BuildOps',
    es: 'Employee settings for BuildOps'
  },
  customerInformation: {
    en: 'Customer Information',
    es: 'Customer Information'
  },
  customerNewCustomer: {
    en: 'Customer new customer',
    es: 'Customer new customer'
  },
  customerPropertyInformation: {
    en: 'Customer property information',
    es: 'Customer property information'
  },
  createNewProperty: {
    en: 'Customer new property',
    es: 'Customer new property'
  },
  jobGeneralInformation: {
    en: 'Job general information',
    es: 'Job general information'
  },
  jobSchedulingInformation: {
    en: 'Visit scheduling information',
    es: 'Visit scheduling information'
  },
  createNewJob: {
    en: 'Create new job',
    es: 'Create new job'
  },
  dispatchBoard: {
    en: 'Dispatch board',
    es: 'Dispatch board'
  },
  map: {
    en: 'Map',
    es: 'Map'
  },
  chart: {
    en: 'Chart',
    es: 'Chart'
  },
  jobsAssigned: {
    en: 'Only jobs assigned to that employee',
    es: 'Only jobs assigned to that employee'
  },
  employeeJobInfo: {
    en: 'Job info',
    es: 'Job info'
  },
  employeeCustomerContacts: {
    en: 'Customer contacts',
    es: 'Customer contacts'
  },
  employeePropertyJobHistory: {
    en: 'Property job history',
    es: 'Property job history'
  },
  employeeFinancialInfo: {
    en: 'Financial info',
    es: 'Financial info'
  },
  allEmployeeJobInfo: {
    en: 'Job info',
    es: 'Job info'
  },
  allEmployeeCustomerContacts: {
    en: 'Customer contacts',
    es: 'Customer contacts'
  },
  allEmployeePropertyJobHistory: {
    en: 'Customer contacts',
    es: 'Customer contacts'
  },
  allEmployeeFinancialInfo: {
    en: 'Financial info',
    es: 'Financial info'
  },
  appAccess: {
    en: 'Access to app',
    es: 'Acceso a la aplicación'
  },
  mobVisitFullAccess: {
    en: 'Full access to visit',
    es: 'Acceso completo a la visita'
  },
  Data: {
    en: 'Data',
    es: 'Data'
  },
  'View Cost Data': {
    en: 'View Cost Data',
    es: 'View Cost Data'
  },
  'Data-> View Cost Data': {
    en: 'Data-> View Cost Data',
    es: 'Data-> View Cost Data'
  },
  'Access rights to View Cost Data': {
    en: 'Access rights to View Cost Data',
    es: 'Access rights to View Cost Data'
  },
  'View Price Data': {
    en: 'View Price Data',
    es: 'View Price Data'
  },
  'Data-> View Price Data': {
    en: 'Data-> View Price Data',
    es: 'Data-> View Price Data'
  },
  'Access rights to View Price Data': {
    en: 'Access rights to View Price Data',
    es: 'Access rights to View Price Data'
  },
  'Show Financial Data': {
    en: 'Show Financial Data',
    es: 'Show Financial Data'
  },
  'Data-> Show Financial Data': {
    en: 'Data-> Show Financial Data',
    es: 'Data-> Show Financial Data'
  },
  'Access rights to Show Financial Data': {
    en: 'Access rights to Show Financial Data',
    es: 'Access rights to Show Financial Data'
  },
  Menu: {
    en: 'Menu',
    es: 'Menu'
  },
  Settings: {
    en: 'Settings',
    es: 'Settings'
  },
  'Menu-> Settings': {
    en: 'Menu-> Settings',
    es: 'Menu-> Settings'
  },
  'Access rights to Settings': {
    en: 'Access rights to Settings',
    es: 'Access rights to Settings'
  },
  'Reports And Dashboards': {
    en: 'Reports And Dashboards',
    es: 'Reports And Dashboards'
  },
  'Menu-> Reports And Dashboards': {
    en: 'Menu-> Reports And Dashboards',
    es: 'Menu-> Reports And Dashboards'
  },
  'Access rights to Reports And Dashboards': {
    en: 'Access rights to Reports And Dashboards',
    es: 'Access rights to Reports And Dashboards'
  },
  'Follow ups': {
    en: 'Follow ups',
    es: 'Follow ups'
  },
  'Menu-> Follow ups': {
    en: 'Menu-> Follow ups',
    es: 'Menu-> Follow ups'
  },
  'Access rights to Follow ups': {
    en: 'Access rights to Follow ups',
    es: 'Access rights to Follow ups'
  },
  Dispatch: {
    en: 'Dispatch',
    es: 'Dispatch'
  },
  'Menu-> Dispatch': {
    en: 'Menu-> Dispatch',
    es: 'Menu-> Dispatch'
  },
  'Access rights to Dispatch': {
    en: 'Access rights to Dispatch',
    es: 'Access rights to Dispatch'
  },
  Lists: {
    en: 'Lists',
    es: 'Lists'
  },
  'Menu-> Lists': {
    en: 'Menu-> Lists',
    es: 'Menu-> Lists'
  },
  'Access rights to Lists': {
    en: 'Access rights to Lists',
    es: 'Access rights to Lists'
  },
  Accounting: {
    en: 'Accounting',
    es: 'Accounting'
  },
  'Menu-> Accounting': {
    en: 'Menu-> Accounting',
    es: 'Menu-> Accounting'
  },
  'Access rights to Accounting': {
    en: 'Access rights to Accounting',
    es: 'Access rights to Accounting'
  },
  Functions: {
    en: 'Functions',
    es: 'Functions'
  },
  Mobile: {
    en: 'Mobile',
    es: 'Mobile'
  },
  'Functions-> Mobile': {
    en: 'Functions-> Mobile',
    es: 'Functions-> Mobile'
  },
  'Access rights to Mobile': {
    en: 'Access rights to Mobile',
    es: 'Access rights to Mobile'
  },
  'Access rights for job tags': {
    en: 'Access rights for job tags',
    es: 'Access rights for job tags'
  },
  'Add Assigned Tasks (Mobile)': {
    en: 'Add Assigned Tasks (Mobile)',
    es: 'Add Assigned Tasks (Mobile)'
  },
  'Access right for adding tasks to current job/maintenance on mobile': {
    en: 'Access right for adding tasks to current job/maintenance on mobile',
    es: 'Access right for adding tasks to current job/maintenance on mobile'
  },
  'Add Pending Tasks (Mobile)': {
    en: 'Add Pending Tasks (Mobile)',
    es: 'Add Pending Tasks (Mobile)'
  },
  'Access right for adding pending tasks on mobile': {
    en: 'Access right for adding pending tasks on mobile',
    es: 'Access right for adding pending tasks on mobile'
  },
  'Add Pending Tasks to Work (Mobile)': {
    en: 'Add Pending Tasks to Work (Mobile)',
    es: 'Add Pending Tasks to Work (Mobile)'
  },
  'Access right for adding pending tasks to maintenance, job, or next maintenance on mobile': {
    en: 'Access right for adding pending tasks to maintenance, job, or next maintenance on mobile',
    es: 'Access right for adding pending tasks to maintenance, job, or next maintenance on mobile'
  },
  Dispatchable: {
    en: 'Dispatchable',
    es: 'Dispatchable'
  },
  'Functions-> Dispatchable': {
    en: 'Functions-> Dispatchable',
    es: 'Functions-> Dispatchable'
  },
  'Access rights to Dispatchable': {
    en: 'Access rights to Dispatchable',
    es: 'Access rights to Dispatchable'
  },
  Admin: {
    en: 'Admin',
    es: 'Admin'
  },
  'Functions-> Admin': {
    en: 'Functions-> Admin',
    es: 'Functions-> Admin'
  },
  'Access rights to Admin': {
    en: 'Access rights to Admin',
    es: 'Access rights to Admin'
  },
  Object: {
    en: 'Object',
    es: 'Object'
  },
  Customer: {
    en: 'Customer',
    es: 'Customer'
  },
  'Object-> Customer': {
    en: 'Object-> Customer',
    es: 'Object-> Customer'
  },
  'Access rights to Customer': {
    en: 'Access rights to Customer',
    es: 'Access rights to Customer'
  },
  Property: {
    en: 'Property',
    es: 'Property'
  },
  'Object-> Property': {
    en: 'Object-> Property',
    es: 'Object-> Property'
  },
  'Access rights to Property': {
    en: 'Access rights to Property',
    es: 'Access rights to Property'
  },
  Job: {
    en: 'Job',
    es: 'Job'
  },
  'Object-> Job': {
    en: 'Object-> Job',
    es: 'Object-> Job'
  },
  'Access rights to Job': {
    en: 'Access rights to Job',
    es: 'Access rights to Job'
  },
  Task: {
    en: 'Task',
    es: 'Task'
  },
  'Object-> Task': {
    en: 'Object-> Task',
    es: 'Object-> Task'
  },
  'Access rights to Task': {
    en: 'Access rights to Task',
    es: 'Access rights to Task'
  },
  Visit: {
    en: 'Visit',
    es: 'Visit'
  },
  'Object-> Visit': {
    en: 'Object-> Visit',
    es: 'Object-> Visit'
  },
  'Access rights to Visit': {
    en: 'Access rights to Visit',
    es: 'Access rights to Visit'
  },
  Asset: {
    en: 'Asset',
    es: 'Asset'
  },
  'Object-> Asset': {
    en: 'Object-> Asset',
    es: 'Object-> Asset'
  },
  'Access rights to Asset': {
    en: 'Access rights to Asset',
    es: 'Access rights to Asset'
  },
  Quotes: {
    en: 'Quotes',
    es: 'Quotes'
  },
  'Object-> Quotes': {
    en: 'Object-> Quotes',
    es: 'Object-> Quotes'
  },
  'Access rights to Quotes': {
    en: 'Access rights to Quotes',
    es: 'Access rights to Quotes'
  },
  Employee: {
    en: 'Employee',
    es: 'Employee'
  },
  'Object-> Employee': {
    en: 'Object-> Employee',
    es: 'Object-> Employee'
  },
  'Access rights to Employee': {
    en: 'Access rights to Employee',
    es: 'Access rights to Employee'
  },
  Invoice: {
    en: 'Invoice',
    es: 'Invoice'
  },
  'Object-> Invoice': {
    en: 'Object-> Invoice',
    es: 'Object-> Invoice'
  },
  'Access rights to Invoice': {
    en: 'Access rights to Invoice',
    es: 'Access rights to Invoice'
  },
  ReviewReport: {
    en: 'ReviewReport',
    es: 'ReviewReport'
  },
  'Object-> ReviewReport': {
    en: 'Object-> ReviewReport',
    es: 'Object-> ReviewReport'
  },
  'Access rights to ReviewReport': {
    en: 'Access rights to ReviewReport',
    es: 'Access rights to ReviewReport'
  },
  Timesheets: {
    en: 'Timesheets',
    es: 'Hojas de horas'
  },
  'Object-> Timesheets': {
    en: 'Object-> Invoice',
    es: 'Object-> Invoice'
  },
  'Access rights to Timesheets': {
    en: 'Access rights to Timesheets',
    es: 'Access rights to Timesheets'
  },
  Workflows: {
    en: 'Workflows',
    es: 'Workflows'
  },
  'Object-> Workflows': {
    en: 'Object-> Workflows',
    es: 'Object-> Workflows'
  },
  'Access rights to Workflows': {
    en: 'Access rights to Workflows',
    es: 'Access rights to Workflows'
  },
  'Access rights to Inventory Truck/Template': {
    en: 'Access rights to Inventory Truck/Template',
    es: 'Derechos de acceso a Inventario Camión / Plantilla'
  },
  'Object-> Inventory Truck/Template': {
    en: 'Object-> Inventory Truck/Template',
    es: 'Objeto-> Inventario Camión / Plantilla'
  },
  'View Labor Rates': {
    en: 'View Payroll Rates on Reports',
    es: 'Ver tarifas de nómina en informes'
  },
  'Create Purchase Orders directly on Quotes': {
    en: 'Create Purchase Orders directly on Quotes',
    es: 'Cree órdenes de compra directamente en cotizaciones'
  }
};

export default labelSchema;
