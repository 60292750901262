import gql from 'graphql-tag';

const deleteDepartment = gql`
  mutation deleteDepartment($input: DeleteDepartmentInput!) {
    deleteDepartment(input: $input) {
      id
    }
  }
`;

export default deleteDepartment;
