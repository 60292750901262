import gql from 'graphql-tag';

export const getCustomerRepsByCustomerById = gql`
  query getCustomerRepsByCustomerById($id: String!) {
    getCustomerById(id: $id) {
      id
      customerReps(entityConnection: "CustomerRep", filter: { status: { eq: "active" } }) {
        items {
          id
          name
          firstName
          lastName
        }
      }
    }
  }
`;
