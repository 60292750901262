import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  detailsSection: {
    '& .MuiInputBase-root': {
      padding: 0
    },
    '& .MuiInputBase-root textarea': {
      padding: '8px'
    }
  },
  oneLineText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '& a': {
      color: 'initial',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },
  selectField: {
    '& .MuiInputBase-input': {
      height: 24,
      padding: '6px 10px'
    },
    '& .MuiInputBase-root *': {
      fontWeight: 400
    }
  }
}));
