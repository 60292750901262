/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo, useState } from 'react';

import { SplitButton, ThemeProvider } from '@buildhero/sergeant';
import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { UserPermission, XGrid } from 'components';
import PageHeader from 'components/PageHeader';
import StatusFilters from 'components/StatusFilter';
import purchaseOrderTableColumns from 'meta/Procurement/PurchaseOrders/purchaseOrdersTable';
import ErrorBoundaries from 'scenes/Error';
import { getPurchaseOrderTags } from 'services/API/purchaseOrderTag';
import { PermissionConstants, ProcurementPurchaseOrderStatus } from 'utils/AppConstants';

import { EnumType } from 'utils/constants';

import OverallTotalCostDisplay from '../component/OverallTotalCostDisplay';
import GET_PURCHASE_ORDERS_LIST from '../queries/getPurchaseOrderList';

import CreateFieldOrder from './CreateFieldOrder';
import CreatePurchaseOrder from './CreatePurchaseOrder';

import PurchaseOrdersUtils from './index.utils';

const splitButtons = {
  PO: 'Add Purchase Order',
  FO: 'Add Field Order'
};

const useStyles = makeStyles(() => ({
  divider: {
    margin: '8px -24px 32px -24px'
  },
  quickFilters: {
    marginBottom: '32px'
  }
}));

const PurchaseOrdersList = () => {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const history = useHistory();
  const [isOpendPOModal, setIsOpendPOModal] = useState(false);
  const [isOpendFOModal, setIsOpendFOModal] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);
  const appPermissionRules = useSelector(state => state.user.appPermissionRules);

  useEffect(() => {
    getPurchaseOrderTags().then(purchaseOrderTags => {
      setTagOptions(
        purchaseOrderTags.map(tag => {
          return { label: tag.tagName, value: tag.id };
        })
      );
    });
  }, []);

  const handleCreatePO = po => {
    if (po.id) {
      history.push(`/procurement/purchaseorders/view/${po.id}`);
    }
    setIsOpendPOModal(false);
  };

  const handleCreateFO = po => {
    if (po?.id) {
      history.push(`/procurement/purchaseorders/view/${po.id}`);
    }
    setIsOpendFOModal(false);
  };

  const startPO = () => {
    const kickoffPO = async () => {
      // force the modal and flow to open
      setIsOpendPOModal(true);
    };
    kickoffPO();
  };

  const startFO = () => {
    const kickoffFO = async () => {
      // force the modal and flow to open
      setIsOpendFOModal(true);
    };
    kickoffFO();
  };

  const pageHeaderButtons = useMemo(
    () => (
      <ThemeProvider>
        {PurchaseOrdersUtils.hasCreatePurchaseOrderPermissions(appPermissionRules) && (
          <SplitButton
            data-testid="add-purchase-order-split-button"
            color="primary"
            onClick={() => startPO()}
            options={[
              {
                label: splitButtons.PO,
                onClick: () => startPO()
              },
              {
                label: splitButtons.FO,
                onClick: () => startFO()
              }
            ]}
          >
            {splitButtons.PO}
          </SplitButton>
        )}
      </ThemeProvider>
    ),
    []
  );

  const quickFilters = ({ setFilterModel, filterModel }) => (
    <Box display="flex" alignItems="center" flexDirection="row" className={classes.quickFilters}>
      <StatusFilters
        filterData={ProcurementPurchaseOrderStatus}
        filterEnumType={EnumType.PROCUMENT_PURCHASE_ORDER_STATUS}
        onClickStatusFilter={status => {
          setFilterModel({
            // set the filter item id fixed to 1 as filters from the xgrid filter toolbar are always 5 digits
            linkOperator: 'and',
            items: [
              ...filterModel.items?.filter(item => item.id !== 1),
              { columnField: 'status', operatorValue: 'contains', value: status, id: 1 }
            ]
          });
        }}
      />
    </Box>
  );

  return (
    <ErrorBoundaries>
      <UserPermission I="read" action={PermissionConstants.OBJECT_PURCHASE_ORDER}>
        <PageHeader
          breadcrumbsArray={[
            {
              link: '',
              title: 'Procurement'
            }
          ]}
          pageMapKey="procurement"
          userLocale={user.locale}
          overrideHeaderButtons={pageHeaderButtons}
        />
        <Divider className={classes.divider} />
        <XGrid
          columns={purchaseOrderTableColumns}
          entityTypeName="Purchase Orders"
          tableName="PurchaseOrdersXGrid"
          query={GET_PURCHASE_ORDERS_LIST}
          rowHeight={38}
          customComponent={quickFilters}
          toolbarCustomComponent={rows =>
            OverallTotalCostDisplay(rows, status => status !== 'Void')
          }
          enableExport
        />
        <CreatePurchaseOrder
          open={isOpendPOModal}
          user={user}
          handleClose={handleCreatePO}
          tagOptions={tagOptions}
          initialData={null}
        />
        <CreateFieldOrder
          open={isOpendFOModal}
          user={user}
          handleClose={handleCreateFO}
          tagOptions={tagOptions}
        />
      </UserPermission>
    </ErrorBoundaries>
  );
};

export default PurchaseOrdersList;
