import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CustomerPropertyService } from 'services/core';
import ErrorBoundaries from 'scenes/Error';
import { snackbarOn } from 'redux/actions/globalActions';
import { convertStringToFloat, logErrorWithCallback } from 'utils';
import SearchAndTable from './SelectAndTable/SearchAndTable';

function PartsSelect(props) {
  const { options, user } = props;
  const {
    taskData: [taskData],
    searchParams: [searchParams],
    taskGroupAndSet: [taskGroupAndSet]
  } = options;
  const { taskGroups, setTaskGroups, groupIndex, taskIndex } = taskGroupAndSet;
  const customerPropertyService = new CustomerPropertyService();
  const [taskEntries, setTaskEntries] = useState(taskData.taskEntries?.items || []);

  const getTaskEntryPayload = newItem => ({
    isActive: true,
    priceBookEntryId: newItem.id,
    productId: newItem.productId,
    name: newItem.name,
    description: newItem.description,
    unitPrice: newItem.unitPrice || 0,
    unitCost: newItem.unitCost || 0,
    markupType: newItem.markupType || 'Percentage',
    markupValue: newItem.markupValue || 0,
    quantity: newItem.quantity || 0,
    taxable: newItem.taxable
  });

  const updateTaskGroups = item => {
    const newState = [...taskGroups];
    newState[groupIndex][taskIndex] = item;
    setTaskGroups(newState);
  };

  const updateTask = async (change, index, item) => {
    if (!item?.id) {
      return;
    }
    const taskEntryPayload = {
      id: item.id,
      version: item.version,
      productId: item.productId,
      ...change
    };

    try {
      const newTaskEntries = [...taskEntries];
      const response = await customerPropertyService.updateTaskEntriesForTask(user.tenantId, {
        taskEntries: [taskEntryPayload]
      });
      const taskEntry = response?.data?.updateTaskEntriesForTask?.[0];
      if (taskEntry) {
        const {
          markupType,
          markupValue,
          priceBookEntryId,
          product,
          productId,
          quantity,
          description,
          unitCost,
          unitValue,
          version
        } = taskEntry;
        const formattedTaskEntry = {
          id: taskEntry.id,
          priceBookEntryId,
          priceBookEntry: {
            markupType,
            markupValue,
            product,
            unitCost,
            unitValue,
            __typename: 'PriceBookEntry'
          },
          productId,
          quantity,
          description,
          version,
          __typename: 'TaskEntry'
        };
        newTaskEntries[index] = formattedTaskEntry;
      }
      setTaskEntries(newTaskEntries);
      updateTaskGroups({ ...taskData, taskEntries: newTaskEntries });
    } catch (error) {
      logErrorWithCallback(error, snackbarOn, 'Unable to update part, please try again later');
    }
  };

  const handleSearchChange = async newItem => {
    if (!newItem?.id) {
      return;
    }
    try {
      const partIndex = taskEntries.findIndex(elem => elem.productId === newItem.productId);
      if (partIndex !== -1) {
        updateTask(
          { target: { value: `${taskEntries[partIndex].quantity + 1}` } },
          partIndex,
          taskEntries[partIndex]
        );
      } else {
        const newTaskEntries = [...taskEntries];
        const taskEntryPayload = getTaskEntryPayload({ ...newItem, quantity: 1 });
        const response = await customerPropertyService.addTaskEntriesToTask(user.tenantId, {
          taskId: taskData.id,
          taskEntries: [taskEntryPayload]
        });
        const taskEntry = response?.data?.addTaskEntriesToTask?.[0];
        if (taskEntry) {
          newTaskEntries.push(taskEntry);
        }
        setTaskEntries(newTaskEntries);
        const updatedTaskData = {
          ...taskData,
          taskEntries: {
            items: newTaskEntries
          }
        };
        updateTaskGroups({ ...updatedTaskData, taskEntries: newTaskEntries });
      }
    } catch (error) {
      logErrorWithCallback(error, snackbarOn, 'Unable to add part, please try again later');
    }
  };

  const handleDelete = async taskEntry => {
    try {
      const deleteResp = await customerPropertyService.deleteTaskEntryFromTask(
        user.tenantId,
        taskEntry.id
      );
      if (deleteResp) {
        let newTaskEntries = [...taskEntries];
        newTaskEntries = newTaskEntries.filter(elem => elem.id !== taskEntry.id);
        setTaskEntries(newTaskEntries);
        updateTaskGroups({ ...taskData, taskEntries: newTaskEntries });
      }
    } catch (error) {
      logErrorWithCallback(error, snackbarOn, 'Unable to delete part, please try again later');
    }
  };

  const headers = {
    head: { label: 'Part' },
    middle: [{ label: 'Description' }, { label: 'Code' }, { label: 'Quantity' }]
  };
  const fields = [
    { fieldName: 'priceBookEntry.product.name', or: 'name' },
    {
      fieldName: 'priceBookEntry.product.description',
      or: 'description',
      editable: true,
      onChange: updateTask
    },
    { fieldName: 'priceBookEntry.product.code' },
    {
      fieldName: 'quantity',
      editable: true,
      onChange: updateTask
    }
  ];

  return (
    <ErrorBoundaries>
      <SearchAndTable
        required
        tableHeaders={headers}
        tableFields={fields}
        searchParams={searchParams}
        listedItems={taskEntries}
        handleDelete={handleDelete}
        handleSearchChange={handleSearchChange}
      />
    </ErrorBoundaries>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedPartsSelect = connect(mapStateToProps, mapDispatcherToProps)(PartsSelect);

export default reduxConnectedPartsSelect;
