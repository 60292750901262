/* eslint-disable no-useless-concat */
/* eslint-disable no-template-curly-in-string */
const defaultQuoteNotesForm = () => {
  return {
    fields: {},
    layouts: {
      // forms container
      default: {
        options: {
          direction: 'column',
          grow: 0,
          padding: [0]
        },
        // boxing
        contents: [
          {
            options: {
              direction: 'column',
              grow: 0,
              size: '100%',
              padding: [10, 8, 9, 8]
            },
            // container
            contents: [
              // issue description field
              {
                options: {
                  direction: 'column',
                  shrink: 0,
                  padding: [0, 0, 18, 0]
                },
                contents: [
                  {
                    component: {
                      default: 'FieldLabel'
                    },
                    source: 'ISSUE DESCRIPTION'
                  },
                  {
                    options: {
                      lines: 5
                    },
                    component: {
                      default: 'TextInput'
                    },
                    source: 'issueDescription'
                  }
                ]
              },
              // description field
              {
                options: {
                  direction: 'column',
                  shrink: 0,
                  grow: 0,
                  padding: [0, 0, 5, 0]
                },
                contents: [
                  {
                    component: {
                      default: 'FieldLabel'
                    },
                    source: 'NOTES'
                  },
                  {
                    options: {
                      lines: 8
                    },
                    component: {
                      default: 'TextInput'
                    },
                    source: 'description'
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  };
};

export default defaultQuoteNotesForm;
