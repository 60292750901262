import gql from 'graphql-tag';

const getCompanyServiceAgreements = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      serviceAgreements(
        entityConnection: "ServiceAgreement"
        filter: { stringFilters: [{ fieldName: "status", filterInput: { eq: "active" } }] }
      ) {
        items {
          id
          agreementName
          propertiesJSON
          priceBook {
            name
            id
          }
        }
      }
    }
  }
`;

export default getCompanyServiceAgreements;
