import gql from 'graphql-tag';

const getAllPaymentByCompany = gql`
  query getCompany(
    $partitionKey: String!
    $sortKey: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      payments: paymentsView(
        entityConnection: "Payment"
        pagination: { limit: $limit, offset: $offset }
        filter: $filter
        sorting: $sort
      ) {
        nextToken
        items {
          id
          version
          sortKey
          paymentNumber
          paymentAmount
          paymentDate
          paymentStatus
          exportStatus
          createdDate
          createdBy
          deletedDate
          syncStatus
          lastUpdatedDateTime
          availableAmount: adjustedBalance
          paymentType(entityConnection: "PaymentType") {
            id
            name
            ledgerAccountId
            ledgerAccount(entityConnection: "LedgerAccount") {
              name
              accountNumber
              accountType
              accountingRefId
              accountingApplication
              id
            }
          }
          billingCustomer(entityConnection: "BillingCustomer") {
            id
            customerName
          }
          paymentInvoices(entityConnection: "PaymentInvoice") {
            items {
              id
              parentId
              invoiceId
              appliedAmount
              invoice(entityConnection: "Invoice") {
                id
                invoiceNumber
                createdDate
              }
              version
              deletedDate
            }
          }
        }
      }
    }
  }
`;

export default getAllPaymentByCompany;
