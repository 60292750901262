import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  label: {
    fontSize: '10px',
    fontWeight: 'normal',
    lineHeight: '14px',
    letterSpacing: '0.01px',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(0.3)
  },
  datePicker: {
    border: `1px solid ${theme.palette.grayscale(80)}`,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0.7),
    paddingBottom: theme.spacing(0.7)
  }
}));
