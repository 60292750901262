import gql from 'graphql-tag';
import useExtendedMutation from 'customHooks/useExtendedMutation';

const CREATE_INVOICE_FROM_QUOTED_AMOUNT_GQL = gql`
  mutation createInvoiceFromQuotedAmountJob($data: CreateInvoiceFromQuotedAmountJobInput!) {
    createInvoiceFromQuotedAmountJob(data: $data) {
      id
    }
  }
`;

const serializer = ({ jobId, departmentId, amountQuoted }) => ({
  variables: {
    data: {
      jobId,
      departmentId,
      amountQuoted
    }
  }
});

const useCreateInvoiceFromQuotedAmountJob = () =>
  useExtendedMutation(CREATE_INVOICE_FROM_QUOTED_AMOUNT_GQL, {
    serializer
  });

export default useCreateInvoiceFromQuotedAmountJob;
