import taskGroupsTitle from './TaskGroupsTitle';

const groupedTaskData = ({
  tasksWithGroups,
  settingsJSON,
  SettingConstants,
  groupName,
  groupPricing,
  smartFieldInfo,
  HTML,
  styles,
  fontFamilyStyle
}) =>
  tasksWithGroups
    .map(
      group =>
        `<tr>
          <td style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);">
            ${taskGroupsTitle({
              settingsJSON,
              SettingConstants,
              group,
              groupName,
              groupPricing,
              styles,
              fontFamilyStyle
            })}
            ${HTML.tasks({
              HTML,
              group,
              smartFieldInfo,
              settingsJSON,
              removeTaskSmartfield: true,
              styles,
              fontFamilyStyle
            })}
          </td>
        </tr>`
    )
    .join('');

export default groupedTaskData;
