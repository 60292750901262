import { theme } from '@buildhero/sergeant';

import { VisitStatuses } from '@dispatch/Dispatch.constants';

export const ALL_VISITS = {
  key: 'ALL',
  value: {
    serverValue: '',
    displayValue: 'All Visits',
    primaryColor: theme.palette.grayscale(1),
    secondaryColor: theme.palette.grayscale(99)
  }
};

export const statusTabs = [
  VisitStatuses.UNASSIGNED,
  VisitStatuses.SCHEDULED,
  VisitStatuses.TRAVELING,
  VisitStatuses.WORKING,
  VisitStatuses.PAUSED,
  VisitStatuses.ON_HOLD,
  VisitStatuses.CANCELED,
  VisitStatuses.CLOSED,
  VisitStatuses.COMPLETE,
  ALL_VISITS
];
