import { MUIFormComponents } from '@buildhero/sergeant';
import React from 'react';

// Checkbox for property has same address as customer
export const shippingAddressCheckbox = ({ form, field, options }) => {
  const handleOnChange = (value, formData) => {
    if (value) {
      formData.setValues({
        ...formData.values,
        propertyAddressLine1: formData.values.customerBillingAddressLine1,
        propertyAddressLine2: formData.values.customerBillingAddressLine2,
        propertyCity: formData.values.customerBillingCity,
        propertyState: formData.values.customerBillingState,
        propertyZipcode: formData.values.customerBillingZipcode,
        propertyLatitude: formData.values.customerBillingLatitude,
        propertyLongitude: formData.values.customerBillingLongitude,
        taxRateId: formData.values.customerTaxRateId,
        shippingAddressCheckbox: value
      });
    } else {
      formData.setValues({
        ...formData.values,
        propertyAddressLine1: '',
        propertyAddressLine2: '',
        propertyCity: '',
        propertyState: '',
        propertyZipcode: '',
        propertyLatitude: undefined,
        propertyLongitude: undefined,
        taxRateId: '',
        shippingAddressCheckbox: value
      });
    }
  };
  const adaptedOptions = { ...options, onChange: handleOnChange };
  return <MUIFormComponents.CheckboxInput form={form} field={field} options={adaptedOptions} />;
};

// Checkbox for property has the same billing address as customer
export const billingAddressCheckbox = ({ form, field, options }) => {
  const handleOnChange = (value, formData) => {
    if (value) {
      formData.setValues({
        ...formData.values,
        billingCustomerName: formData.values.customerName,
        billingCustomerId: formData.values.parentId,
        billTo: formData.values.billTo ? formData.values.billTo : formData.values.customerBillTo,
        billingAddressLine1: formData.values.customerBillingAddressLine1,
        billingAddressLine2: formData.values.customerBillingAddressLine2,
        billingCity: formData.values.customerBillingCity,
        billingState: formData.values.customerBillingState,
        billingZipcode: formData.values.customerBillingZipcode,
        billingLatitude: formData.values.customerBillingLaitude,
        billingLongitude: formData.values.customerBillingLongitude,
        billingAddressCheckbox: value
      });
    } else {
      formData.setValues({
        ...formData.values,
        billingCustomerName: '',
        billingCustomerId: '',
        billingAddressLine1: '',
        billingAddressLine2: '',
        billingCity: '',
        billingState: '',
        billingZipcode: '',
        billingLatitude: '',
        billingLongitude: '',
        billingAddressCheckbox: value
      });
    }
  };
  const adaptedOptions = { ...options, onChange: handleOnChange };
  return <MUIFormComponents.CheckboxInput form={form} field={field} options={adaptedOptions} />;
};
