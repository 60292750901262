import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { snackbarOn } from 'redux/actions/globalActions';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveTable from 'components/ResponsiveTable';
import Spinner from 'components/Spinners/CircularIndeterminate';
import SergeantModal from 'components/SergeantModal';
import Labels from 'meta/labels';
import { Mode, PurchaseOrderStatus } from 'utils/constants';
import poReceiptListTableMeta from 'meta/Procurement/PurchaseOrders/poReceiptListTableMeta';
import { editItemLayout } from 'meta/Procurement/PurchaseOrders/editReceitItemForm';
import {
  purchaseOrderReceiptChange,
  getPOReceiptsByPoId,
  purchaseOrderReceiptDelete
} from 'services/API/purchaseOrderReceipt';
import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';

import { calcTotalCost, getJobOrProjectLink } from 'scenes/Procurement/component/utils';
import { getBillByReceipt } from 'services/API/bill';
import { getReviewReportBillItemByBillLine } from 'services/API/billLine';

const defaultRowActionButtons = {
  edit: {
    label: 'Edit',
    icon: 'Edit',
    caslAction: 'update',
    caslKey: ''
  },
  remove: {
    label: 'Remove',
    icon: 'Delete',
    caslAction: 'delete',
    caslKey: ''
  }
};

const useStyles = makeStyles(theme => ({
  root: {},
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 533,
    border: '3px dashed #00874D'
  },
  title: {
    color: theme.palette.grayscale(20),
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '-0.05em'
  },
  text: {
    color: theme.palette.grayscale(60),
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '14px',
    letterSpacing: '-0.02em',
    marginTop: 4
  }
}));

const ReceiptsList = ({ user, poId, updatePurchaseOrderLines, poStatus, ...props }) => {
  const classes = useStyles();
  const [receiptItems, setReceiptItems] = useState([]);
  const [selectedEditItem, setSelectedEditItem] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!poId) return;

    async function getReceipts() {
      const receipts = await getPOReceiptsByPoId(poId);
      // eslint-disable-next-line no-restricted-syntax
      for (const receipt of receipts) {
        receipt.totalCost = await calcTotalCost(receipt, 'PurchaseOrderReceiptLine');
        const { jobOrProjectLink, jobOrProjectText } = getJobOrProjectLink(receipt);
        receipt.jobOrProjectLink = jobOrProjectLink;
        receipt.jobOrProjectText = jobOrProjectText;
      }
      setReceiptItems(receipts);
      setIsLoading(false);
    }
    getReceipts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditItemSave = async (completed, stopLoading) => {
    const finalData = receiptItems.map(item =>
      completed.receiptNumber === item.receiptNumber
        ? {
            ...item,
            ...{ Department: completed.Department },
            ...{ description: completed.description }
          }
        : item
    );

    // persist to the database
    await purchaseOrderReceiptChange(completed.id, {
      departmentId: completed.Department.id,
      description: completed.description
    });

    // set the local screen
    setReceiptItems([...finalData]);
    stopLoading();
    setEditModalOpen(false);
  };

  const handleRowActions = async (actionType, record) => {
    const bill = await getBillByReceipt(record.id);
    if (bill && bill[0] && bill[0]?.BillLine) {
      let noEdit = false;
      await Promise.all(
        bill[0].BillLine.map(line => {
          return getReviewReportBillItemByBillLine(line.id).then(item => {
            if (item?.id || item[0]?.id) {
              noEdit = true;
            }
          });
        })
      );
      if (noEdit) {
        props.snackbarOn(
          'error',
          "Can't edit or delete a receipt that has been added to a review report"
        );
        return;
      }
    }
    if (actionType === 'remove') {
      const finalData = filter(receiptItems, item => item.receiptNumber !== record.receiptNumber);

      await purchaseOrderReceiptDelete(record.id);
      setReceiptItems([...finalData]);
      updatePurchaseOrderLines(poId);
    } else if (actionType === 'edit') {
      setSelectedEditItem(record);
      setEditModalOpen(true);
    }
  };

  if (receiptItems.length === 0) {
    return (
      <div>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className={classes.emptyContainer}>
            <div className={classes.title}>Nothing here.</div>
            <div className={classes.text}>Please generate a receipt.</div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <ResponsiveTable
        fullScreen
        rowMetadata={poReceiptListTableMeta}
        data={receiptItems}
        noDataMsg="No Receipts"
        disableFilter
        rowActionButtons={poStatus === PurchaseOrderStatus.VOID ? null : defaultRowActionButtons}
        rowActions={handleRowActions}
        defaults={{
          sortBy: 'receiptNumber',
          sortOrder: 'asc'
        }}
      />
      <SergeantModal
        open={editModalOpen}
        title={Labels.editPurchaseOrderReceipt[user.locale]}
        customPrimaryButtonLabel="Save"
        data={selectedEditItem}
        dataType="Item"
        mode={Mode.EDIT}
        layout={editItemLayout()}
        handlePrimaryAction={handleEditItemSave}
        handleClose={() => setEditModalOpen(false)}
        customComponents={{ SearchBar }}
        alignCloseRight
      />
    </div>
  );
};

ReceiptsList.propTypes = {
  user: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  poId: PropTypes.string.isRequired,
  updatePurchaseOrderLines: PropTypes.func.isRequired,
  poStatus: PropTypes.string.isRequired
};

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const mapStateToProps = state => ({ user: state.user });
const ReduxConnectedReceiptsList = connect(mapStateToProps, mapDispatcherToProps)(ReceiptsList);
export default ReduxConnectedReceiptsList;
