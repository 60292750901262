const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5)
  },
  title: {
    color: `${theme.palette.grayscale(25)} !important`,
    fontSize: '16px !important',
    height: '19px',
    letterSpacing: '0.15px !important',
    fontWeight: '600 !important'
  },
  subtitle1: theme.typography.subtitle1,
  icon: {
    marginTop: '6px'
  },
  edit: {
    right: '65px',
    position: 'absolute',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    backgroundColor: 'inherit',
    textAlign: 'center',
    verticalAlign: 'middle',
    '&:hover': {
      backgroundColor: theme.palette.grayscale(60),
      color: theme.palette.text.primary
    }
  },
  leftSide: {
    height: 'auto',
    width: '35%',
    displays: 'flex',
    float: 'left'
  },
  rightSide: {
    height: 'auto',
    width: '65%',
    displays: 'flex',
    float: 'left'
  },
  headersText: {
    color: theme.palette.grayscale(60),
    fontSize: '12px',
    fontFamily: theme.typography.fontFamily,
    width: '100%',
    float: 'left',
    height: '23px'
  },
  dataText: {
    color: theme.palette.grayscale(25),
    fontSize: '14px',
    width: '100%',
    float: 'left',
    fontFamily: theme.typography.fontFamily,
    overflowWrap: 'break-word'
  },
  techCell: {
    width: 'calc((100% - 40px) / 3)',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left'
  }
});

export default styles;
