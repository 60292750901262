/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { bool, func, string, object } from 'prop-types';
import classNames from 'classnames';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { camelCaseToTitleCase } from 'utils';
import buildHeroMuiFormOverrides from '../buildHeroMuiFormOverrides';

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles(theme => ({
  root: {
    minHeight: 20,
    maxHeight: 24,
    padding: '0px 24px',
    '& .expandIcon': {
      fontSize: '1.3em',
      transition: 'transform 0.2s',
      '&.expanded': {
        transform: 'rotate(180deg)'
      }
    },
    '&$expanded': {
      minHeight: 20,
      maxHeight: 24
    },
    '& .MuiAccordionSummary-expandIcon': {
      position: 'absolute',
      top: '15%',
      left: 0,
      color: theme.palette.grayscale(20),
      fontSize: 20,
      padding: 0
    }
  },

  content: {
    display: 'flex',
    alignItems: 'center',
    margint: 0,
    '&$expanded': { margin: 0 }
  },
  expanded: { minHeight: 20 }
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: '16px 28px',
    flexDirection: 'column'
  }
}))(MuiAccordionDetails);

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 16,
    backgroundColor: `${theme.palette.background.default} !important`
  },
  accordionTitle: {
    color: theme.palette.grayscale(20),
    fontWeight: 700,
    fontSize: 20,
    letterSpacing: '-0.03em',
    marginLeft: 4,
    marginBottom: 0
  },
  addbtnContainer: {
    marginLeft: 4
  },
  accordionFooter: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 21,
    marginLeft: 4
  },
  formContainer: buildHeroMuiFormOverrides(theme)
}));

const FormWithAccordion = props => {
  const classes = useStyles();
  const {
    sectionName,
    formComponent,
    buttonComponent,
    footerComponent,
    handlePanelExpand,
    expanded,
    loading
  } = props;

  return (
    <Accordion
      square
      disabled={loading}
      onChange={handlePanelExpand}
      expanded={expanded}
      className={classes.root}
    >
      <AccordionSummary>
        <ExpandMoreIcon className={`expandIcon ${expanded ? 'expanded' : ''}`} />
        <Typography className={classes.accordionTitle} variant="h6">
          {camelCaseToTitleCase(sectionName)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={classes.formContainer}>{formComponent}</Box>
        {buttonComponent && <Box className={classes.addbtnContainer}>{buttonComponent}</Box>}
        {footerComponent && (
          <Box className={classNames(classes.formContainer, classes.accordionFooter)}>
            {footerComponent}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

FormWithAccordion.propTypes = {
  sectionName: string.isRequired,
  formComponent: func,
  buttonComponent: object,
  footerComponent: object,
  handlePanelExpand: func.isRequired,
  expanded: bool,
  loading: bool
};

FormWithAccordion.defaultProps = {
  formComponent: null,
  footerComponent: null,
  buttonComponent: null,
  expanded: undefined,
  loading: false
};

export default FormWithAccordion;
