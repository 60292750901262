import React, { memo, useEffect } from 'react';

import PropTypes from 'prop-types';

import { TECHS_RESPONSE_PROP } from '@dispatch/queries';

import DailyView from './components/DailyView';
import MapView from './components/MapView';
import WeekView from './components/WeekView';
import {
  BOARD_MAN_DAY_ITEM_RESPONSE_PROPS,
  BOARD_NON_VISIT_EVENT_RESPONSE_PROP,
  BOARD_VISIT_RESPONSE_PROP
} from './queries';

const DispatchBoard = ({
  day,
  weekView,
  mapView,
  techsResponse,
  boardVisitsResponse,
  boardNonVisitEventsResponse,
  boardManDayItemsResponse,
  customWeekStartDay
}) => {
  useEffect(() => {
    boardVisitsResponse.refetch();
    boardNonVisitEventsResponse.refetch();
    boardManDayItemsResponse.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day, mapView, weekView]);

  if (mapView) {
    return <MapView techsResponse={techsResponse} boardVisitsResponse={boardVisitsResponse} />;
  }

  if (weekView) {
    return (
      <WeekView
        techsResponse={techsResponse}
        boardVisitsResponse={boardVisitsResponse}
        boardNonVisitEventsResponse={boardNonVisitEventsResponse}
        boardManDayItemsResponse={boardManDayItemsResponse}
        customWeekStartDay={customWeekStartDay}
      />
    );
  }

  return (
    <DailyView
      techsResponse={techsResponse}
      boardVisitsResponse={boardVisitsResponse}
      boardNonVisitEventsResponse={boardNonVisitEventsResponse}
      boardManDayItemsResponse={boardManDayItemsResponse}
    />
  );
};

DispatchBoard.propTypes = {
  // eslint-disable-next-line react/require-default-props
  day: PropTypes.string,
  mapView: PropTypes.bool.isRequired,
  weekView: PropTypes.bool.isRequired,
  techsResponse: TECHS_RESPONSE_PROP.isRequired,
  boardVisitsResponse: BOARD_VISIT_RESPONSE_PROP.isRequired,
  boardNonVisitEventsResponse: BOARD_NON_VISIT_EVENT_RESPONSE_PROP.isRequired,
  boardManDayItemsResponse: BOARD_MAN_DAY_ITEM_RESPONSE_PROPS.isRequired,
  customWeekStartDay: PropTypes.string.isRequired
};

export default memo(DispatchBoard);
