export const labelSchema = {
  signInTitle: {
    en: 'Sign in',
    es: 'Sign in'
  },
  usernameLabel: {
    en: 'Username',
    es: 'Username'
  },
  emailLabel: {
    en: 'Email',
    es: 'Email'
  },
  passwordLabel: {
    en: 'Password',
    es: 'Password'
  },
  signInButtonText: {
    en: 'Sign In',
    es: 'Sign In'
  },
  forgotPasswordButtonText: {
    en: 'Forgot Password',
    es: 'Forgot Password'
  },
  forgotPasswordTitle: {
    en: 'Forgot your password?',
    es: 'Forgot your password?'
  },
  resetPasswordTitle: {
    en: 'Reset your password',
    es: 'Reset your password'
  },
  userPassword: {
    en: 'User Password',
    es: 'Contraseña de usuario'
  },
  enterYourUsername: {
    en: 'BuildOps username',
    es: 'BuildOps username'
  },
  getVerificationCodeButtonText: {
    en: 'Get your verification code',
    es: 'Get your verification code'
  },
  signInInsteadButtonText: {
    en: 'Back to Sign in',
    es: 'Back to Sign in'
  },
  codeSentSuccessMessage: {
    en: 'Verification code sent! Please check your email.',
    es: 'Verification code sent! Please check your email.'
  },
  codeSentFailMessage: {
    en: 'There was an issue. Please try again later.',
    es: 'There was an issue. Please try again later.'
  },
  resendCodeButtonText: {
    en: 'Resend my verification code',
    es: 'Resend my verification code'
  },
  enterNewPasswordLabel: {
    en: 'Enter your new password',
    es: 'Enter your new password'
  },
  enterVerificationCodeLabel: {
    en: 'Enter your verification code',
    es: 'Enter your verification code'
  },
  confirmNewPasswordLabel: {
    en: 'Confirm the new password',
    es: 'Confirm the new password'
  },
  changePasswordButtonText: {
    en: 'Change Password',
    es: 'Change Password'
  },
  previousStepButtonText: {
    en: 'Previous step',
    es: 'Previous step'
  },
  passwordResetExceptionSuccessMessage: {
    en: 'Congratulations! Your password has been updated. Please sign in.',
    es: 'Congratulations! Your password has been updated. Please sign in.'
  },
  passwordResetSuccessMessage: {
    en: 'Congratulations! Your password has been updated.',
    es: 'Congratulations! Your password has been updated.'
  },
  invalidCodeHelperText: {
    en: 'At least 1 character.',
    es: 'At least 1 character.'
  },
  invalidPasswordHelperText: {
    en: 'At least 6 characters.',
    es: 'At least 6 characters.'
  },
  invalidConfirmPasswordHelperText: {
    en: 'Passwords are not matching.',
    es: 'Passwords are not matching.'
  },
  invalidUsernameHelperText: {
    en: 'Your username is too short.',
    es: 'Your username is too short.'
  }
};

export default labelSchema;
