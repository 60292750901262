import React, { useState } from 'react';

import { Button, ThemeProvider } from '@buildhero/sergeant';
import { makeStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';

import ResponsiveTable from 'components/ResponsiveTable';
import useEmployees from 'customHooks/useEmployees';
import { quoteRowMeta } from 'meta/Jobs/quoteTable';
import { snackbarOn } from 'redux/actions/globalActions';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { addQuoteFromJob } from './QuotesFromJobHelper/index';
// seperated out as a seperate componenet for futuristic purpose
const QuoteList = ({ data, jobData, currentProperty, history, snackbar, user }) => {
  const processedData = data.map(quote => ({
    ...quote,
    displayQuoteNumber: quote?.customIdentifier || quote?.quoteNumber,
    totalAmountQuoted: quote?.totalAmountQuotedOverride || quote?.totalAmountQuoted
  }));
  const [companyEmployees] = useEmployees();
  const [addQuoteLoading, setAddQuoteLoading] = useState(false);
  const flags = useFlags();

  const useStyles = makeStyles(theme => ({
    quoteButton: {
      width: 86,
      padding: theme.spacing(1),
      alignSelf: 'flex-end',
      marginBottom: theme.spacing(1),
      '& *': {
        fontSize: 12
      }
    }
  }));

  const classes = useStyles();

  const handleAddQuote = async () => {
    setAddQuoteLoading(true);
    await addQuoteFromJob({ currentProperty, jobData, history, user, companyEmployees, snackbar });
    setAddQuoteLoading(false);
  };

  return (
    <>
      {flags[FeatureFlags.JOB_QUOTE_STATUS] && (
        <ThemeProvider>
          <Button
            type="secondary"
            onClick={handleAddQuote}
            className={classes.quoteButton}
            loading={addQuoteLoading}
          >
            Add Quote
          </Button>
        </ThemeProvider>
      )}
      <ResponsiveTable
        rowMetadata={quoteRowMeta(flags)}
        data={processedData}
        fullScreen
        noDataMsg="No quotes"
        disableFilter
        disableSort
      />
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  snackbar: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedQuote = connect(mapStateToProps, mapDispatchToProps)(QuoteList);

export default reduxConnectedQuote;
