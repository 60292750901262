import Context from 'components/Context';
import { getPreferredTechnicians } from 'components/PreferredTechniciansForm';

const getPreferredTechniciansForNewVisit = job => {
  const { maintenanceTemplate, serviceAgreement } = job || {};
  const companySettings = Context.getCompanySettingsMap();
  return getPreferredTechnicians({
    maintenanceTemplate,
    serviceAgreement,
    companySettings
  });
};

export default getPreferredTechniciansForNewVisit;
