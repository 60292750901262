import gql from 'graphql-tag';

const SyncBankAccounts = gql`
  mutation syncBankAccounts($tenantId: String!, $data: SyncBankAccountInput!) {
    syncBankAccounts(tenantId: $tenantId, data: $data) {
      id
      name
      accountingRefId
      currencyCode
    }
  }
`;

export default SyncBankAccounts;
