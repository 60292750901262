import gql from 'graphql-tag';

const deleteTruckInventory = gql`
  mutation deleteTruckInventory($id: String!) {
    deleteTruckInventory(id: $id) {
      id
    }
  }
`;

export default deleteTruckInventory;
