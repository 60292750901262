export default (tasks, handleCheckboxChange, checkedTasks) => {
  return {
    fields: {
      default: 'name'
    },
    validation: {
      type: 'object',
      properties: {
        name: {
          type: 'string'
        }
      },
      required: ['name']
    },
    validationErrors: {
      name: {
        required: 'Group name is required.'
      }
    },
    layouts: {
      default: {
        contents: [
          {
            options: {},
            contents: [
              {
                options: {
                  color: 'secondary',
                  isRequired: true,
                  variant: 'standard',
                  label: 'Group Name',
                  placehholder: 'Enter a Group Name'
                },
                component: 'TextInput',
                source: 'name'
              }
            ]
          },
          {
            options: { paddingTop: 24, fontSize: 10 },
            contents: [
              {
                component: 'customLabel'
              }
            ]
          },
          {
            contents: tasks.map((task, i) => ({
              contents: [
                {
                  options: {
                    label: task.name || `Unnamed Task ${i + 1}`,
                    onChange: props => handleCheckboxChange(props, task)
                  },
                  component: 'CheckboxInput',
                  source: checkedTasks.includes(task.id) ? null : task.id
                }
              ]
            }))
          }
        ]
      }
    }
  };
};
