import pick from 'lodash/pick';
import gql from 'graphql-tag';
import useExtendedMutation from 'customHooks/useExtendedMutation';
import updateReviewReport from 'services/core/graphql/review-report/mutations/UpdateReviewReport';
import deleteLabourRateLineItem from 'services/core/graphql/review-report/mutations/DeleteLabourRateLineItem';
import { GET_REVIEW_REPORT_BY_ID } from '../../../../queries';
import { laborLineItemFragment } from '../../../../queries/queryFragment';

export const useUpdatePriceBookMutation = () =>
  useExtendedMutation(updateReviewReport, {
    serializer: ({ tenantId, priceBookId, reviewReportId, version }) => ({
      variables: {
        partitionKey: tenantId,
        data: {
          id: reviewReportId,
          priceBookId,
          version
        }
      }
    })
  });

export const useDeleteLaborLineItemMutation = reviewReportId =>
  useExtendedMutation(deleteLabourRateLineItem, {
    showConfirmation: () => true,
    confirmationModalOptions: {
      title: 'Delete Entry',
      warn: true,
      modalText: 'Are you sure you want to delete this entry?'
    },
    serializer: ({ tenantId, lineItemId }) => {
      return {
        variables: {
          partitionKey: tenantId,
          id: lineItemId
        }
      };
    },
    update: (cache, { data }) => {
      const cachedData = cache.readQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        }
      });
      cache.writeQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        },
        data: {
          getReviewReportById: {
            ...cachedData.getReviewReportById,
            labourRateLineItems: {
              __typename: 'LabourRateLineItemConnection',
              items:
                cachedData.getReviewReportById?.labourRateLineItems?.items?.filter(
                  lineItem => lineItem?.id !== data?.deleteLabourRateLineItem?.id
                ) || []
            }
          }
        }
      });
    }
  });

const ADD_LABOUR_RATE_LINE_ITEMS_TO_REVIEW_REPORT = gql`
  mutation addLabourRateLineItemsToReviewReport(
    $partitionKey: String
    $data: AddLabourRateLineItemsToReviewReportInput!
  ) {
    addLabourRateLineItemsToReviewReport(partitionKey: $partitionKey, data: $data) {
      ${laborLineItemFragment}
    }
  }
`;

export const useUpdateLaborItemsMutation = reportId =>
  useExtendedMutation(ADD_LABOUR_RATE_LINE_ITEMS_TO_REVIEW_REPORT, {
    serializer: ({ tenantId, reviewReportId, laborLineItems }) => ({
      variables: {
        partitionKey: tenantId,
        data: {
          reviewReportId,
          labourRateLineItems: laborLineItems.map(lineItem => ({
            ...pick(lineItem, ['id', 'employeeId', 'labourTypeId', 'departmentId']),
            labourRateBillingHourLines:
              lineItem?.labourRateBillingHourLines?.items?.map(billingHour =>
                pick(billingHour, [
                  'id',
                  'hourTypeId',
                  'totalHours',
                  'costCodeId',
                  'revenueTypeId',
                  'rate'
                ])
              ) || []
          }))
        }
      }
    }),
    update: (cache, { data }) => {
      const cachedData = cache.readQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reportId
        }
      });
      cache.writeQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reportId
        },
        data: {
          getReviewReportById: {
            ...cachedData.getReviewReportById,
            labourRateLineItems: {
              __typename: 'LabourRateLineItemConnection',
              items: data?.addLabourRateLineItemsToReviewReport
            }
          }
        }
      });
    }
  });
