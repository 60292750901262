import React from 'react';
import { number, shape, string } from 'prop-types';
import { Button } from '@material-ui/core';

import { excelFileDownload } from 'utils/fileDownload';
import { useExportWIPReport } from '../../mutations/useExportWIPReport';

const DownloadWIPReport = ({ wipReport }) => {
  const [exportWipReport, exportWipResponse] = useExportWIPReport({
    onCompleted: data => {
      excelFileDownload({
        fileName: `WIPReport${wipReport.recordNumber}`,
        base64Data: data?.exportWIPReport?.base64Data
      });
    }
  });

  const handleDownload = () => {
    exportWipReport(wipReport.id);
  };

  return (
    <Button onClick={handleDownload} disabled={exportWipResponse.loading}>
      Record #{wipReport.recordNumber}
    </Button>
  );
};

DownloadWIPReport.propTypes = {
  wipReport: shape({
    id: string.isRequired,
    recordNumber: number.isRequired
  }).isRequired
};

export default DownloadWIPReport;
