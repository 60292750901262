import gql from 'graphql-tag';

const getPaymentTypesForCompany = gql`
  query getCompany(
    $partitionKey: String!
    $sortKey: String!
    $filter: TablePaymentTypeFilterInput
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      paymentTypes(entityConnection: "PaymentType", filter: $filter) {
        items {
          id
          name
          version
          accountingRefId
          lastUpdatedDateTime
          ledgerAccountId
          ledgerAccount(entityConnection: "LedgerAccount") {
            name
            accountNumber
            accountType
            accountingRefId
            accountingApplication
            id
          }
        }
      }
    }
  }
`;

export default getPaymentTypesForCompany;
