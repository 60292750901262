const taskTable = (removeTaskSmartfield, tasks) =>
  `<figure>
      <table style="width:95%;border-color:hsl(207, 90%, 54%, 0);border-style:solid;" data-smartfield=${
        removeTaskSmartfield ? 'RemoveItem' : 'Tasks'
      }>
        ${tasks}
      </table>
    </figure>`;

export default taskTable;
