export const changeOrderFormatLabels = {
  ALL_DETAILS: 'All Details',
  SECTION_SUBTOTALS: 'Section Subtotals',
  GRAND_TOTAL: 'Grand Total'
};

export const changeOrderFormatValues = {
  ALL_DETAILS: 'ALL_DETAILS',
  SECTION_SUBTOTALS: 'SECTION_SUBTOTALS',
  GRAND_TOTAL: 'GRAND_TOTAL'
};

export const changeOrderFormatDescriptions = {
  ALL_DETAILS: 'Show line items, their quantity, unit prices, and extension value',
  SECTION_SUBTOTALS: 'Show subtotals for each cost type on the change order',
  GRAND_TOTAL: 'Show a single total for all cost types on the change order'
};
