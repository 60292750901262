import Amplify from 'aws-amplify';
import configForEnvironment from 'configs/aws-exports';
import ENV from 'configs/env';
import AppSyncClient from '../AppSyncClient';
import AuthService from '../AuthServices';
import StorageService from '../StorageService';

export default class AmplifyService {
  static storageService = new StorageService();

  static APIClient = null;

  static config = null;

  static configure(env = ENV, additionalOptions, asyncStorage) {
    console.log(`AmplifyService configured with ${env} environment.`);

    this.config = configForEnvironment(env);
    if (!this.config) {
      throw new Error('AmplifyService must be initialized with Auth and Storage configurations.');
    }
    this.additionalOptions = additionalOptions;
    const amplifyObj = {
      Auth: {
        mandatorySignIn: true,
        region: this.config.cognito.REGION,
        userPoolId: this.config.cognito.USER_POOL_ID,
        identityPoolId: this.config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: this.config.cognito.APP_CLIENT_ID
      },
      Storage: {
        bucket: this.config.s3.BUCKET,
        region: this.config.s3.REGION,
        identityPoolId: this.config.cognito.IDENTITY_POOL_ID
      }
    };
    if (asyncStorage) {
      amplifyObj.Auth.storage = asyncStorage;
    }
    Amplify.configure(amplifyObj);
  }

  static startServices(store = null) {
    return {
      auth: this.startAuthService(store),
      storage: this.storageService
    };
  }

  static startAuthService(store = null) {
    const auth = new AuthService(store);
    auth.startAuthListener();

    return auth;
  }

  static resetClient() {
    this.APIClient = null;
  }

  static appSyncClient(status = 'online') {
    const { config, additionalOptions } = this;
    if (!this.APIClient) {
      this.APIClient = new AppSyncClient({
        options: additionalOptions,
        ...config
      });
    }

    this.APIClient.status = status;

    return this.APIClient;
  }
}
