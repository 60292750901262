const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  internalHeader: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: '1',
    width: '100%'
  }
});

export default styles;
