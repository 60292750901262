import React from 'react';
import { Box, TextField, Checkbox, Typography, Divider } from '@material-ui/core';
import { MUIFormComponents } from '@buildhero/sergeant';
import { makeStyles } from '@material-ui/core/styles';

export const SuggestedParts = () => <Typography variant="h6">Suggested Parts</Typography>;
export const AddAdditionalParts = () => <Typography variant="h6">Add Additional Parts</Typography>;
export const DividerWithPadding = () => (
  <Box py={2}>
    <Divider />
  </Box>
);

export const CheckboxList = ({ field, form, options }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { setFieldValue } = form;
  const { checkboxItems } = field?.value;
  const { associatedPartsAndMaterials } = options;

  const handleEdit = (value, id) => {
    const index = checkboxItems.map(({ pricebookEntryId }) => pricebookEntryId).indexOf(id);
    checkboxItems[index] = value;
    setFieldValue('checkboxItems', checkboxItems);
  };
  const onChange = (part, checked) => {
    setFieldValue(
      'checkboxItems',
      checked
        ? [...checkboxItems, part]
        : checkboxItems.filter(({ pricebookEntryId }) => pricebookEntryId !== part.pricebookEntryId)
    );
  };

  return (
    <Box width="850px" display="flex" flexDirection="column">
      {associatedPartsAndMaterials.map(part => {
        return (
          <Box key={part.id} display="flex" py={1}>
            <Box width="5%">
              <Checkbox
                classes={{ root: classes.root, checked: classes.checked }}
                onChange={e => onChange(part, e.target.checked)}
                defaultChecked
              ></Checkbox>
            </Box>
            <DisplayValue
              width="40%"
              options={{ label: 'Item' }}
              field={{ value: part.priceBookEntryName }}
            />
            <DisplayValue
              width="40%"
              options={{ label: 'Description' }}
              field={{ value: part.priceBookEntryDescription }}
            />
            <Box width="15%">
              <MUIFormComponents.LabelControl value="Quantity" />
              <TextField
                type="number"
                variant="filled"
                InputProps={{
                  className: classes.quantityInput,
                  disableUnderline: true,
                  inputProps: { min: 0 }
                }}
                value={part.quantity}
                onChange={e =>
                  handleEdit(
                    { ...part, quantity: Number(e.target.value || 0) },
                    part.pricebookEntryId
                  )
                }
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const DisplayValue = ({ width, ...props }) => (
  <Box width={width} display="flex" flexDirection="column" flexWrap="wrap">
    <MUIFormComponents.FieldWithLabel {...props} />
  </Box>
);

const styles = theme => ({
  quantityInput: {
    '& input': {
      padding: theme.spacing(0.5)
    }
  },
  root: {
    '&$checked': {
      color: '#000'
    }
  },
  checked: {}
});
