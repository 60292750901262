import React from 'react';
import { bool, func, number, shape, object, oneOf } from 'prop-types';
import { MUIForm } from '@buildhero/sergeant';

import { shouldDisableScheduling, validateScheduling } from '../../NonVisitEvents.helpers';

import AccordionSection from '../AccordionSection';
import DateTimeInput from '../DateTimeInput';

import { useNonBillableEventFormConfig } from './NonBilllableEventForm.config';
import { Modes } from './NonBillableEventForm.constants';

const NonBillableEventForm = ({
  departmentsResponse,
  disabled,
  enableDepartment,
  eventData,
  mode,
  payrollHourTypesResponse,
  onComplete,
  onCreateService,
  onDirtyChange
}) => {
  const { data: departments, loading: departmentsLoading } = departmentsResponse;
  const { data: payrollHourTypes, loading: payrollHourTypesLoading } = payrollHourTypesResponse;

  const formConfig = useNonBillableEventFormConfig({
    departments,
    departmentsLoading,
    disableScheduling: shouldDisableScheduling(eventData),
    disablePayrollHourType: eventData?.status !== 'Scheduled',
    disabled,
    enableDepartment,
    mode,
    payrollHourTypes,
    payrollHourTypesLoading
  });

  const customComponents = {
    AccordionSection,
    DateTimeInput
  };

  return (
    <MUIForm
      configuration={formConfig}
      customComponents={customComponents}
      data={eventData}
      onCreateService={onCreateService}
      onDirtyChange={onDirtyChange}
      onComplete={onComplete}
      validate={validateScheduling}
    />
  );
};

NonBillableEventForm.propTypes = {
  departmentsResponse: object.isRequired,
  disabled: bool.isRequired,
  enableDepartment: bool,
  eventData: shape({ from: number }).isRequired,
  mode: oneOf(Object.values(Modes)),
  onComplete: func.isRequired,
  onCreateService: func.isRequired,
  onDirtyChange: func.isRequired,
  payrollHourTypesResponse: object.isRequired
};

NonBillableEventForm.defaultProps = {
  enableDepartment: false,
  mode: Modes.ADD
};

export default NonBillableEventForm;
