import { generateDefaultFieldsObject } from '../../../../../components/formattingUtils';

const margin = '0px 15px 21px 0px';

// Equipment Information
export const budgetFields = {
  name: { required: true },
  description: { required: false },
  status: { required: false },
  billingType: { required: false },
  startDate: { required: true, nullable: true },
  endDate: { required: true, nullable: true },
  ProjectPhaseDepartment: { required: false }
};

export const costFields = {
  costCode: { required: true },
  description: { required: true }
};

const smallItemFlexStyle = '0 0 182px';
const numberItemFlexStyle = '0 0 102px';
const wideItemFlexStyle = '0 0 453px';

const phaseContents = () => [
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1
    },
    contents: [
      {
        options: {
          margin
        },
        contents: [
          {
            component: 'sectionTitle',
            options: {
              label: 'General Information'
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      size: 4,
      direction: 'row',
      grow: 0,
      flexWrap: 'wrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: smallItemFlexStyle,
          margin
        },
        contents: [
          {
            source: 'name',
            component: 'TextInput',
            options: {
              isRequired: true,
              label: 'Name',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: smallItemFlexStyle,
          margin
        },
        contents: [
          {
            component: 'SelectInput',
            source: 'status',
            options: {
              label: 'Status',
              variant: 'standard',
              color: 'secondary',
              enableSort: false,
              valueSet: [
                {
                  label: 'Not Started',
                  value: 'Not Started'
                },
                {
                  label: 'In Progress',
                  value: 'In Progress'
                },
                {
                  label: 'Complete',
                  value: 'Complete'
                }
              ]
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: smallItemFlexStyle,
          margin
        },
        contents: [
          {
            component: 'DateInput',
            source: 'startDate',
            options: {
              isRequired: true,
              variant: 'standard',
              color: 'secondary',
              label: 'Start Date',
              errorText: 'Field is required'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: smallItemFlexStyle,
          margin
        },
        contents: [
          {
            component: 'DateInput',
            source: 'endDate',
            options: {
              isRequired: true,
              variant: 'standard',
              color: 'secondary',
              label: 'End Date',
              errorText: 'Field is required'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: numberItemFlexStyle,
          marginLeft: '200px'
        },
        contents: [
          {
            source: 'number',
            component: 'CustomFieldWithLabel',
            options: {
              label: 'Number',
              fullWidth: true,
              alignment: 'right',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      }
    ]
  },
  {
    options: {
      size: 2,
      direction: 'row',
      grow: 0
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: wideItemFlexStyle,
          margin
        },
        contents: [
          {
            source: 'description',
            component: 'TextInput',
            options: {
              lines: 4,
              label: 'Description',
              variant: 'standard',
              color: 'secondary',
              placeholder: 'Enter Description'
            }
          }
        ]
      }
    ]
  }
];

export const phaseLayout = () => {
  return {
    fields: {
      ...generateDefaultFieldsObject(budgetFields)
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [...phaseContents()]
      },
      edit: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [...phaseContents()]
      }
    }
  };
};

export const phaseTableMeta = [
  {
    id: 'number',
    label: 'Number',
    type: 'number'
  },
  {
    id: 'name',
    label: 'Phase',
    showLink: 'view',
    linkPath: 'projectsettings/phase',
    linkReference: 'phase',
    type: 'string'
  },
  {
    id: 'startDate',
    label: 'Start Date',
    type: 'date'
  },
  {
    id: 'endDate',
    label: 'End Date',
    type: 'date'
  },
  {
    id: 'percentComplete',
    label: 'Progress',
    type: 'percentage'
  }
];
