import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Table, TableBody, TableRow, TableCell, Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './styles';
import ConfirmModal from 'components/Modal/ConfirmDialog';
import UserPermission from 'components/AppPermissions';

class InlineTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: ''
    };
  }

  // On button click, render row with text box
  handleButtonClick = (refFieldName, arraySize) => {
    this.props.unshift({ [refFieldName]: '', sortOrder: arraySize + 1 });
    this.props.form.setFieldTouched(this.props.fieldProps.value, true, false);
  };

  handleDeleteListRow = value => {
    this.setState(prevState => ({
      ...prevState,
      confirmDialog: true,
      confirmAction: () => this.removeRow(value),
      confirmMessage: ''
    }));
  };

  removeRow = async value => {
    if (this.props.buttons.deleteItem) {
      const result = await this.props.buttons.deleteItem.action(value);
      if (result) {
        const itemList = this.props.form.values[this.props.fieldProps.value];
        let modifiedList;
        if (value.listItem.id) {
          modifiedList = itemList.filter(item => item.id !== value.listItem.id);
        } else {
          modifiedList = itemList.filter((item, index) => index !== value.index);
        }

        // await this.props.remove(value.index);
        this.props.form.setFieldValue(this.props.fieldProps.name, modifiedList || [], false);
        //  this.props.form.setFieldTouched(this.props.fieldProps.value, true, false);
      }
    }
    await this.setState(prevState => ({
      ...prevState,
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: ''
    }));
  };

  handleCancelConfirmation = () => {
    this.setState(prevState => ({
      ...prevState,
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: ''
    }));
  };

  handleChangeInput = (index, listItem, refFieldName) => event => {
    this.props.replace(index, {
      ...listItem,
      [refFieldName]: event.target.value
    });
    this.props.form.setFieldTouched(this.props.fieldProps.value, true, false);
  };

  handleChangeSortOrderInput = (index, listItem) => event => {
    this.props.replace(index, {
      ...listItem,
      sortOrder: event.target.value ? parseInt(event.target.value, 10) : 0
    });
    this.props.form.setFieldTouched(this.props.fieldProps.value, true, false);
  };

  render() {
    const { classes, fieldProps, specialbehaviour, caslKey } = this.props;
    const fieldName = fieldProps.value;
    const itemList = this.props.form.values[this.props.fieldProps.value];

    // TODO: always refer to object reference field name, departments are not having these defs
    const refFieldName =
      specialbehaviour && specialbehaviour.objectReferenceFieldName
        ? specialbehaviour.objectReferenceFieldName
        : 'tagName';

    // construct table
    // when values exist build rows
    // if string one text cell
    // if JSON, get tableCols and iterate

    return (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Table size="small">
          <TableBody>
            <UserPermission I="new" action={caslKey}>
              {fieldProps.allowAdd && this.props.mode !== 'view' && (
                <TableRow>
                  <TableCell>
                    <Button onClick={() => this.handleButtonClick(refFieldName, itemList.length)}>
                      <AddIcon className={classes.linkIcon} />
                      {fieldProps.addButtonName}
                    </Button>
                  </TableCell>
                  {!fieldProps.skipSort && <TableCell align="right" />}
                  <TableCell align="right" />
                </TableRow>
              )}
            </UserPermission>
            {itemList &&
              itemList.map((listItem, index) => (
                <TableRow key={`row${index}`}>
                  {typeof listItem === 'string' && (
                    <TableCell key={`itemCell${index}`}>{listItem}</TableCell>
                  )}
                  {typeof listItem === 'object' && (
                    <TableCell key={`itemObjectCell${index}`}>
                      {listItem && (
                        <TextField
                          variant="standard"
                          name={`${fieldName}.${index}.${refFieldName}`}
                          value={listItem[refFieldName]}
                          onChange={this.handleChangeInput(index, listItem, refFieldName)}
                          className={classes.inputStyle}
                          InputProps={{
                            classes: {
                              underline: classes.cssUnderline
                            }
                          }}
                          disabled={this.props.mode === 'view'}
                          key={`text${index}`}
                        />
                      )}
                    </TableCell>
                  )}
                  {!fieldProps.skipSort && (
                    <TableCell align="right">
                      <TextField
                        variant="standard"
                        type="number"
                        onKeyPress={this.handleSortOrder}
                        name={`${fieldName}.${index}.${refFieldName}sort`}
                        value={listItem.sortOrder || ''}
                        onChange={this.handleChangeSortOrderInput(index, listItem)}
                        className={classes.inputStyle}
                        InputProps={{
                          classes: {
                            underline: classes.cssUnderline
                          },
                          inputProps: { min: 0, max: itemList.length }
                        }}
                        style={{ width: 50 }}
                        disabled={this.props.mode === 'view'}
                        key={`textSort${index}`}
                      />
                    </TableCell>
                  )}
                  <UserPermission I="delete" action={caslKey}>
                    <TableCell align="right">
                      <IconButton
                        aria-label="Delete"
                        className={this.props.classes.listIcon}
                        onClick={() =>
                          this.handleDeleteListRow({ listItem: listItem, index: index })
                        }
                        key={`deliconbtn${index}`}
                      >
                        <DeleteIcon
                          className={this.props.classes.deleteIconStyle}
                          key={`delicon${index}`}
                        />
                      </IconButton>
                    </TableCell>
                  </UserPermission>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {this.state.confirmAction && (
          <ConfirmModal
            open={this.state.confirmDialog}
            confirm={this.state.confirmAction}
            cancel={this.handleCancelConfirmation}
            message={this.state.confirmMessage}
          />
        )}
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(InlineTable);
