import gql from 'graphql-tag';
import useExtendedMutation from 'customHooks/useExtendedMutation';
import { visitFragment } from 'customHooks/useJob';

const GENERATE_TECH_REPORT = gql`
  mutation generateTechReport($visitId: String!) {
    generateTechReport(visitId: $visitId) {
      ${visitFragment}
    }
  }
`;

export const useGenerateTechReport = () =>
  useExtendedMutation(GENERATE_TECH_REPORT, {
    serializer: id => ({
      variables: {
        visitId: id
      }
    })
  });
