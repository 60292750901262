import AmplifyService from 'services/AmplifyService';
import CommonService from '../Common/CommonService';
import getReviewReportById from '../../graphql/review-report/queries/getReviewReportById';
import getBillLineListForReviewReport from '../../graphql/review-report/queries/getBillLineListForReviewReport';

import getTechVisitCost from '../../graphql/review-report/queries/getTechVisitCost';

import addInventoryPartsToReviewReport from '../../graphql/review-report/mutations/AddInventoryPartsToReviewReport';
import addDiscountToReviewReport from '../../graphql/review-report/mutations/AddDiscountsToReviewReport';
import addFeeToReviewReport from '../../graphql/review-report/mutations/AddFeesToReviewReport';

import addSummariesToReviewReport from '../../graphql/review-report/mutations/AddSummariesToReviewReport';
import addPurchaseOrderToReviewReport from '../../graphql/review-report/mutations/AddPurchaseOrderToReviewReport';
import addReviewReportPurchaseOrderLines from '../../graphql/review-report/mutations/AddReviewReportPurchaseOrderLines';
import addAttachmentsToReviewReport from '../../graphql/review-report/mutations/AddAttachmentsToReviewReport';
import addLaborLineItemToReviewReport from '../../graphql/review-report/mutations/AddLaborLineItemToReviewReport';

import updateSummary from '../../graphql/review-report/mutations/UpdateSummary';
import updateDiscount from '../../graphql/review-report/mutations/UpdateDiscount';
import updateFee from '../../graphql/review-report/mutations/UpdateFee';

import updateInventoryPart from '../../graphql/review-report/mutations/UpdateInventoryPart';
import updatePurchaseOrder from '../../graphql/review-report/mutations/UpdatePurchaseOrder';
import updatePurchaseOrderLine from '../../graphql/review-report/mutations/UpdatePurchaseOrderLine';
import updatePurchaseOrderReceipt from '../../graphql/review-report/mutations/UpdatePurchaseOrderReceipt';

import addPurchaseOrderReceipt from '../../graphql/review-report/mutations/AddPurchaseOrderReceipt';

import updateAttachment from '../../graphql/review-report/mutations/UpdateAttachment';
import updateLaborLineItem from '../../graphql/review-report/mutations/UpdateLaborLineItem';
import updateNote from '../../graphql/review-report/mutations/UpdateNote';
import updateCustomerSignature from '../../graphql/review-report/mutations/UpdateCustomerSignature';

import deleteInventoryPart from '../../graphql/review-report/mutations/DeleteInventoryPart';
import deletePurchaseOrder from '../../graphql/review-report/mutations/DeletePurchaseOrder';
import deletePurchaseOrderLine from '../../graphql/review-report/mutations/DeletePurchaseOrderLine';
import deletePurchaseOrderReceipt from '../../graphql/review-report/mutations/DeletePurchaseOrderReceipt';
import deleteSummary from '../../graphql/review-report/mutations/DeleteSummary';
import deleteAttachmentById from '../../graphql/review-report/mutations/DeleteAttachmentById';
import deleteDiscount from '../../graphql/review-report/mutations/DeleteDiscount';
import deleteFee from '../../graphql/review-report/mutations/DeleteFee';

import deleteLaborLineItem from '../../graphql/review-report/mutations/DeleteLaborLineItem';
import deleteNoteById from '../../graphql/review-report/mutations/DeleteNoteById';

import AddLabourRateLineItemsToReviewReport from '../../graphql/review-report/mutations/AddLabourRateLineItemsToReviewReport';
import DeleteLabourRateLineItem from '../../graphql/review-report/mutations/DeleteLabourRateLineItem';

import createReviewReportMutation from '../../graphql/review-report/mutations/CreateReviewReport';
import updateReviewReport from '../../graphql/review-report/mutations/UpdateReviewReport';
import UpdateReviewReportStatusMutation from '../../graphql/review-report/mutations/UpdateReviewReportStatus';
import saveReviewReportBillItemMutation from '../../graphql/review-report/mutations/SaveReviewReportBillItem';
import deleteReviewReportBillItemMutation from '../../graphql/review-report/mutations/DeleteReviewReportBillItem';

export default class ReviewReportService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
    this.CommonService = new CommonService();
  }

  createReviewReport = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(createReviewReportMutation, data);
    return response;
  };

  addDiscountToReviewReport = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(addDiscountToReviewReport, data);
    return response;
  };

  addFeeToReviewReport = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(addFeeToReviewReport, data);
    return response;
  };

  addInventoryPartsToReviewReport = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(addInventoryPartsToReviewReport, data);
    return response;
  };

  addSummariesToReviewReport = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(addSummariesToReviewReport, data);
    return response;
  };

  addPurchaseOrderToReviewReport = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(addPurchaseOrderToReviewReport, data);
    return response;
  };

  addLaborLineItemToReviewReport = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(addLaborLineItemToReviewReport, data);
    return response;
  };

  addReviewReportPurchaseOrderLines = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(addReviewReportPurchaseOrderLines, data);
    return response;
  };

  addAttachmentsToReviewReport = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(addAttachmentsToReviewReport, data);
    return response;
  };

  updateSummary = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(updateSummary, data);
    return response;
  };

  updatePurchaseOrder = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(updatePurchaseOrder, data);
    return response;
  };

  updateLaborLineItem = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(updateLaborLineItem, data);
    return response;
  };

  updatePurchaseOrderLine = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(updatePurchaseOrderLine, data);
    return response;
  };

  updatePurchaseOrderReceipt = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(updatePurchaseOrderReceipt, data);
    return response;
  };

  addPurchaseOrderReceipt = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(addPurchaseOrderReceipt, data);
    return response;
  };

  updateAttachment = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(updateAttachment, data);
    return response;
  };

  updateCustomerSignature = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(updateCustomerSignature, data);
    return response;
  };

  updateAttachment = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(updateAttachment, data);
    return response;
  };

  updateNote = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(updateNote, data);
    return response;
  };

  updateDiscount = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(updateDiscount, data);
    return response;
  };

  updateFee = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(updateFee, data);
    return response;
  };

  updateInventoryPart = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(updateInventoryPart, data);
    return response;
  };

  updateReviewReport = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(updateReviewReport, data);
    return response;
  };

  updateReviewReportStatus = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(UpdateReviewReportStatusMutation, data);
    return response;
  };

  saveReviewReportBillItem = async (partitionKey, values) => {
    const data = {
      partitionKey,
      items: values
    };
    const response = await this.api.mutate(saveReviewReportBillItemMutation, data);
    return response;
  };

  deleteReviewReportBillItem = async (partitionKey, id) => {
    const data = {
      partitionKey,
      id
    };
    return this.api.mutate(deleteReviewReportBillItemMutation, data);
  };

  deleteAttachmentById = async (partitionKey, id) => {
    const data = {
      partitionKey,
      id
    };
    const response = await this.api.mutate(deleteAttachmentById, data);
    return response;
  };

  deleteInventoryPart = async (partitionKey, id) => {
    const data = {
      partitionKey,
      id
    };
    const response = await this.api.mutate(deleteInventoryPart, data);
    return response;
  };

  deletePurchaseOrder = async (partitionKey, id) => {
    const data = {
      partitionKey,
      id
    };
    const response = await this.api.mutate(deletePurchaseOrder, data);
    return response;
  };

  deletePurchaseOrderLine = async (partitionKey, id) => {
    const data = {
      partitionKey,
      id
    };
    const response = await this.api.mutate(deletePurchaseOrderLine, data);
    return response;
  };

  deletePurchaseOrderReceipt = async (partitionKey, id) => {
    const data = {
      partitionKey,
      id
    };
    const response = await this.api.mutate(deletePurchaseOrderReceipt, data);
    return response;
  };

  deleteNoteById = async (partitionKey, id) => {
    const data = {
      partitionKey,
      id
    };
    const response = await this.api.mutate(deleteNoteById, data);
    return response;
  };

  deleteSummary = async (partitionKey, id) => {
    const data = {
      partitionKey,
      id
    };
    const response = await this.api.mutate(deleteSummary, data);
    return response;
  };

  deleteLaborLineItem = async (partitionKey, id) => {
    const data = {
      partitionKey,
      id
    };
    const response = await this.api.mutate(deleteLaborLineItem, data);
    return response;
  };

  deleteDiscount = async (partitionKey, id) => {
    const data = {
      partitionKey,
      id
    };
    const response = await this.api.mutate(deleteDiscount, data);
    return response;
  };

  deleteFee = async (partitionKey, id) => {
    const data = {
      partitionKey,
      id
    };
    const response = await this.api.mutate(deleteFee, data);
    return response;
  };

  getReviewReportById = async id => {
    const params = {
      id
    };

    const response = await this.api.query(getReviewReportById, params);
    return response;
  };

  getBillLineListForReviewReport = async (columnField, operatorValue, value, visitId) => {
    const params = {
      columnField,
      operatorValue,
      value,
      visitId
    };

    const response = await this.api.query(getBillLineListForReviewReport, params);
    return response;
  };

  getTechVisitCost = async (partitionKey, visitId) => {
    if (!partitionKey || !visitId) return {};
    const params = {
      partitionKey,
      visitId
    };

    const response = await this.api.query(getTechVisitCost, params);
    return response;
  };

  addLabourRateLineItemsToReviewReport = async (partitionKey, values) => {
    const data = {
      partitionKey,
      data: values
    };
    const response = await this.api.mutate(AddLabourRateLineItemsToReviewReport, data);
    return response;
  };

  deleteLabourRateLineItem = async (partitionKey, id) => {
    const data = {
      partitionKey,
      id
    };
    const response = await this.api.mutate(DeleteLabourRateLineItem, data);
    return response;
  };
}
