import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    padding: '12px 0'
  },
  noMsgText: {
    fontSize: '20px',
    lineHeight: '20px',
    fontWeight: 500,
    letterSpacing: '-0.03em'
  }
});

export default useStyles;
