import axios from 'axios';

export const fetchFiles = files =>
  Promise.all(
    files.map(async file => {
      const response = await axios.get(file.s3Url, {
        responseType: 'blob',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0'
        }
      });
      return new File([response.data], file.name, { type: response.headers['content-type'] });
    })
  );
