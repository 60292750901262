import gql from 'graphql-tag';

const updateForm = gql`
  mutation updateForm($partitionKey: String!, $data: UpdateFormInput!) {
    updateForm(partitionKey: $partitionKey, data: $data) {
      id
      version
      sortKey
    }
  }
`;

export default updateForm;
