import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import { Logger } from 'services/Logger';
import SectionHeader from 'components/SectionHeader';
import StorageService from 'services/StorageService';
import AppConstants from 'utils/AppConstants';
import { StyledSectionDivider } from '../../Divider';
import OCRDataDisplay from '../../OCRDataDisplay';
import FormRow from './FormRows';

/**
 * Renders form section with styling and Grid alignment as defined in the layout meta
 * @param {section} props.section
 */

const imageStyle = {
  height: 136,
  width: '100%',
  minWidth: 85,
  maxWidth: 136,
  resizeMode: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat'
};

class Sections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: '',
      fileName: '',
      oldFileName: ''
    };
  }

  render() {
    const sectionItem = this.props.section;
    let smValue = 12;
    let mdValue = 12;
    let lgValue = 12;

    if (sectionItem.showImage) {
      const localFileName = this.props.data[sectionItem.showImage];
      if (localFileName !== this.state.fileName) {
        this.setState(prevState => ({
          ...prevState,
          fileName: this.props.data[sectionItem.showImage]
        }));
      }
    }

    const titleText = sectionItem.title || sectionItem.mergeTitle;

    // when there is no title, grid values will be set in pageForm file (i.e wrapper)
    if (titleText) {
      smValue = sectionItem.sm ? parseInt(sectionItem.sm, 10) : 12;
      mdValue = sectionItem.md ? parseInt(sectionItem.md, 10) : 12;
      lgValue = sectionItem.lg ? parseInt(sectionItem.lg, 10) : 12;
    }

    const imageGridValue = sectionItem.showImage && this.props.mode === 'view' ? 2 : 0;
    const contentWithImageGridvalueSM = smValue - imageGridValue;
    const contentWithImageGridvalueMD = mdValue - imageGridValue;
    const contentWithImageGridvalueLG = lgValue - imageGridValue;

    const renderSectionImage = (classes, url) => (
      <Grid item xs={12} sm={12} md={imageGridValue} lg={imageGridValue} xl={imageGridValue}>
        {url && this.state.imageUrl && (
          <CardMedia style={imageStyle} image={this.state.imageUrl} title="Company Logo" />
        )}
      </Grid>
    );

    const renderSectionTitle = () => {
      // merge title with data input ex: Plumbing department
      if (sectionItem.contextTitle) {
        return sectionItem.contextTitle[this.props.mode] || sectionItem.title || null;
      }
      if (sectionItem.mergeTitle && this.props.mode !== 'new') {
        return sectionItem.mergeTitle.replace('###', this.props.data[sectionItem.mergeField]);
      }
      if (sectionItem.title) {
        return sectionItem.title;
      }
      return null;
    };

    const renderAuditMessage = () => {
      let auditMergedMessage;
      const { auditMessage, auditBy, auditTimeStamp } = this.props.section;
      const { data } = this.props;
      if (auditMessage && auditBy && auditTimeStamp) {
        auditMergedMessage = `${auditMessage} ${data[auditBy]} ${moment
          .unix(data[auditTimeStamp] / 1000)
          .format(AppConstants.DATETIME_FORMAT)}`;
      }
      return auditMergedMessage;
    };

    const sectionSpacing = sectionItem.spacing ? parseInt(sectionItem.spacing, 10) : 3;
    const { classes, ...rest } = this.props;

    const getImageUrl = async fileName => {
      if (!fileName) {
        return;
      }
      try {
        if (this.state.oldFileName !== this.state.fileName) {
          const storageService = new StorageService();
          const url = await storageService.getFile(fileName);

          this.setState({ imageUrl: url, fileName, oldFileName: fileName });
        }
      } catch (error) {
        Logger.info(`Error uploading image ${error}`);
      }
    };

    if (this.state.fileName) {
      getImageUrl(this.state.fileName);
    }

    // OCR Data and Helper Functions
    const { openOCRModal, dataOCRInput } = this.props;

    return (
      <Grid
        container
        direction="row"
        key={`secParentContainer${sectionItem}`}
        spacing={sectionSpacing}
        style={sectionItem.style}
      >
        {(sectionItem.title ||
          sectionItem.contextTitle ||
          sectionItem.mergeTitle ||
          sectionItem.auditMessage) && (
          <Grid item xs={12} sm={12} md={12} lg={12} key={`secTitleContainer${sectionItem}`}>
            <SectionHeader
              title={renderSectionTitle()}
              audit={renderAuditMessage()}
              enablePadding={false}
              icon={sectionItem.icon}
              infoToolTip={sectionItem.infoToolTip}
              ocrEnabled={sectionItem.ocrEnabled}
              openOCRModal={openOCRModal}
            />
          </Grid>
        )}
        {dataOCRInput && <OCRDataDisplay dataOCRInput={dataOCRInput} />}
        {sectionItem.showImage &&
          this.props.mode === 'view' &&
          renderSectionImage(this.props.classes, this.props.data[sectionItem.showImage])}
        <Grid
          item
          xs={contentWithImageGridvalueSM}
          sm={contentWithImageGridvalueSM}
          md={contentWithImageGridvalueMD}
          lg={contentWithImageGridvalueLG}
          xl={contentWithImageGridvalueLG}
        >
          {sectionItem.rows.map((rowItem, index) => {
            const rowSpacing = rowItem.spacing ? parseInt(rowItem.spacing, 10) : 5;
            return (
              <Fragment key={`sectionRowFrag${index}`}>
                <Grid
                  container
                  direction="row"
                  spacing={rowSpacing}
                  justify="flex-start"
                  alignItems={this.props.mode === 'view' ? 'flex-start' : 'flex-end'}
                  key={`secContainer${index}`}
                >
                  <FormRow row={rowItem} {...rest} key={`FormRow${index}`} />
                </Grid>
                {this.props.mode === 'view' && rowItem.showBottomDivider && (
                  <StyledSectionDivider
                    customStyle={{ marginTop: 22, marginBottom: 22 }}
                    key={`secDivider${index}`}
                  />
                )}
              </Fragment>
            );
          })}
        </Grid>
      </Grid>
    );
  }
}

// User state from Redux is accessed to retrive the user locale
const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps, null)(Sections);
