import gql from 'graphql-tag';

export const SERVICE_AGREEMENTS_LIST_DATA = gql`
  query serviceAgreementsList(
    $columns: [String]
    $filter: GenericFilterInput
    $sorting: [SortingInput]
    $pagination: PaginationInput
  ) {
    serviceAgreementsListData(
      columns: $columns
      filter: $filter
      sorting: $sorting
      pagination: $pagination
    ) {
      meta
      items
      totalRecordCount
    }
  }
`;

export const GetServiceAgreementNextCounterValue = gql`
  query {
    getServiceAgreementNextCounterValue
  }
`;
