import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  dateInput: {
    minWidth: 'auto'
  },
  button: {
    textTransform: 'capitalize',
    boxShadow: 'none !important',
    background: 'transparent !important',
    color: `${theme.palette.text.primary} !important`
  },
  results: {
    padding: 5,
    width: 315,
    display: 'flex',
    maxHeight: 240,
    overflowY: 'auto',
    flexDirection: 'column',
    background: theme.palette.grayscale(100)
  },
  resultsText: {
    cursor: 'pointer',
    padding: theme.spacing(1)
  }
}));
