import gql from 'graphql-tag';

const updateFormData = gql`
  mutation updateFormData($partitionKey: String!, $data: UpdateFormDataInput!) {
    updateFormData(partitionKey: $partitionKey, data: $data) {
      id
      version
    }
  }
`;

export default updateFormData;
