import gql from 'graphql-tag';

const getAllCustomers = gql`
  query getCompany($partitionKey: String!, $sortKey: String!, $nextToken: String, $limit: Int) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      companyName
      email
      logoUrl
      tenantId
      partitionKey
      sortKey
      hierarchy
      entityType
      lastUpdatedDateTime
      customers(
        entityConnection: "Customer"
        filter: { status: { eq: "active" } }
        nextToken: $nextToken
        limit: $limit
      ) {
        nextToken
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          customerName
          customerTypeValue
          accountNumber
          status
          email
          phonePrimary
          version
          isActive
          customerProperties(entityConnection: "CustomerProperty", limit: 500) {
            items {
              companyName
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              status
              version
            }
          }
        }
      }
    }
  }
`;

export default getAllCustomers;
