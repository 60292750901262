import React, { useState } from 'react';

import { Button, Modal, MUIForm, ThemeProvider, TV, TW, Typography } from '@buildhero/sergeant';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import SearchBar from '@pm/components/APISearchComponents/SearchBar';
import WrapTable from 'components/WrapTable';
import useForceUpdate from 'customHooks/useForceUpdate';
import {
  useGetNextInvoiceNumber,
  useGetPayApplicationById,
  usePayApplicationSummaryTotals,
  usePayAppTableData,
  usePutPayApplication
} from 'services/APIHooks';

import { BuildHeroThemeProvider } from 'themes/BuildHeroTheme';

import { usePayAppLineItemColumns, useRetainageColumns } from './GeneratePayApplication.columns';
import { payApplicationFields, useConfiguration } from './GeneratePayApplication.configuration';
import { formatInvoiceNumber, getPayAppBillableRetainage } from './GeneratePayApplication.helpers';
import {
  useFilteredRows,
  useInitializeCurrentBilledRetainage,
  usePaymentTerm,
  useRetainageRows
} from './GeneratePayApplication.hooks';
import {
  selectPayAppBilledRetainage,
  selectPayAppRetainedAmount,
  selectTotalBilledRetainage,
  selectTotalRetainedAmount
} from './GeneratePayApplication.selectors';
import { serializePayApplication } from './GeneratePayApplication.serializer';
import { useStyles } from './GeneratePayApplication.styles';

const GeneratePayApplication = ({
  open,
  payApplicationId,
  onClose,
  onSuccess,
  snackbar,
  initialData,
  previousPeriodTo,
  refetchProjectDetails,
  ...props
}) => {
  const styles = useStyles();
  const { tenantId, tenantCompanyId } = useSelector(state => state.user);
  const retainagePercent = useSelector(
    state => state?.pm?.project?.ScheduleOfValue?.retainage || 0
  );
  const { projectId } = useParams();
  const [currentBilledRetainage, setCurrentBilledRetainage] = useState();
  const [payAppLineItems, setPayAppLineItems] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [formService, setFormService] = useState();
  const isEdit = Boolean(payApplicationId);
  const forceUpdate = useForceUpdate();

  const paymentTerm = usePaymentTerm();

  const [payAppResponse] = useGetPayApplicationById(
    { id: payApplicationId },
    { skip: !payApplicationId, depends: [initialData], defaultData: {} }
  );

  // It is a workaround to get data from the trigger function
  // refetch() in useQuery hook returns data as a Promise that can be awaited
  const { refetch: getNextInvoiceNumber } = useGetNextInvoiceNumber({
    tenantId,
    tenantCompanyId
  });

  const paNumber = props.paNumber || payAppResponse?.data?.number;

  const [payAppTableDataResponse] = usePayAppTableData(
    { projectId, paNumber },
    {
      skip: !(open && projectId && paNumber),
      depends: [open, projectId, paNumber],
      defaultData: [],
      onSuccess: data => {
        setPayAppLineItems(data);
        setTableLoading(false);
      }
    }
  );

  const [putPayAppResponse, putPayApp] = usePutPayApplication({
    onSuccess: () => {
      snackbar('success', `${paNumber} posted successfully.`);
      onClose?.();
      onSuccess?.();
      refetchProjectDetails();
    }
  });
  const [summaryTotalsResponse] = usePayApplicationSummaryTotals(
    {
      projectId,
      paNumber
    },
    {
      useCache: false
    }
  );

  const payAppLineItemColumns = usePayAppLineItemColumns({ formService });
  const retainageColumns = useRetainageColumns({
    onBillableRetainageChange: value => {
      formService?.formikContext?.setFieldValue('billedRetainage', value);
      setCurrentBilledRetainage(value);
    }
  });

  const formConfiguration = useConfiguration({ disabled: payAppResponse.loading });

  const filteredRows = useFilteredRows({
    payAppLineItems,
    periodTo: formService?.getValue?.('periodTo')
  });

  const currentPayAppRetainedAmount = getPayAppBillableRetainage({
    payAppLineItems: filteredRows,
    retainagePercent
  });

  const originalPayAppRetainedAmount = selectPayAppRetainedAmount(payAppResponse?.data);
  const originalPayAppBilledRetainage = selectPayAppBilledRetainage(payAppResponse?.data);
  const totalRetainedAmount = selectTotalRetainedAmount(summaryTotalsResponse?.data);
  const totalBilledRetainage = selectTotalBilledRetainage(summaryTotalsResponse?.data);
  const previouslyBilledRetainage = totalBilledRetainage - originalPayAppBilledRetainage;
  const currentTotalRetainedAmount =
    totalRetainedAmount - originalPayAppRetainedAmount + currentPayAppRetainedAmount || 0;

  const retainageRows = useRetainageRows({
    currentTotalRetainedAmount,
    previouslyBilledRetainage,
    currentBilledRetainage
  });

  const handlePayAppLineItemChange = (field, payload) => {
    if (field === 'payAppLineItemRows') {
      setPayAppLineItems(payload);
    }

    // force update to trigger change order row filtering
    if (field === 'periodTo') forceUpdate();
  };

  useInitializeCurrentBilledRetainage({
    payAppData: payAppResponse?.data,
    currentBilledRetainage,
    setCurrentBilledRetainage
  });

  const handleSubmit = async payApplication => {
    const response = await getNextInvoiceNumber();
    const nextInvoiceNumber = formatInvoiceNumber(response);

    putPayApp({
      url: `PayApplication/${payApplication.id || ''}`,
      method: payApplication.id ? 'put' : 'post',
      data: serializePayApplication({
        payApplication,
        paymentTerm,
        nextInvoiceNumber,
        currentPayAppRetainedAmount
      })
    });
  };

  const handleSubmitClick = () => {
    formService?.submit();
  };

  const handleFormNotification = (type, message) => {
    if (type === 'failure') snackbar(type, message);
  };

  return (
    <ThemeProvider>
      <Modal
        open={open}
        actions={
          <Button
            css={{ lineHeight: 1 }}
            onClick={handleSubmitClick}
            loading={putPayAppResponse.loading}
          >
            {isEdit ? 'Save' : 'Add'} Pay Application
          </Button>
        }
        onClose={onClose}
        title={isEdit ? 'Edit Pay Application' : 'Add Pay Application'}
        fullScreen
      >
        <BuildHeroThemeProvider>
          <div css={styles.formRoot}>
            <MUIForm
              configuration={formConfiguration}
              data={{
                ...initialData,
                ...payAppResponse.data,
                sendTo: payAppResponse.data.sendToCustomer,
                returnTo: payAppResponse.data.returnToObject,
                payAppLineItemRows: payAppTableDataResponse.data
              }}
              customComponents={{ SearchBar }}
              onCreateService={setFormService}
              onFieldChange={handlePayAppLineItemChange}
              validationSchema={payApplicationFields(previousPeriodTo)}
              onComplete={handleSubmit}
              onFormNotification={handleFormNotification}
            />
          </div>
        </BuildHeroThemeProvider>
        <WrapTable
          columns={payAppLineItemColumns}
          rows={filteredRows}
          error={payAppTableDataResponse.error}
          loading={tableLoading || payAppTableDataResponse.loading}
          enableTotalsRow
        />
        <Typography variant={TV.XL} weight={TW.BOLD} css={styles.title}>
          Retainage Billing
        </Typography>
        <WrapTable
          columns={retainageColumns}
          rows={retainageRows}
          error={summaryTotalsResponse.error}
          loading={tableLoading || summaryTotalsResponse.loading}
          hideFooter
        />
      </Modal>
    </ThemeProvider>
  );
};

GeneratePayApplication.defaultProps = {
  initialData: {}
};

export default GeneratePayApplication;
