import gql from 'graphql-tag';

const updateReviewReportStatus = gql`
  mutation updateReviewReportStatus($partitionKey: String, $data: UpdateReviewReportStatusInput!) {
    updateReviewReportStatus(partitionKey: $partitionKey, data: $data) {
      status
      discount
      messageToCustomerInvoice
      id
      version
      includeMessageToCustomerInInvoice
      lastUpdatedDateTime
    }
  }
`;

export default updateReviewReportStatus;
