import gql from 'graphql-tag';

const getPayrollLabourTypes = gql`
  query getPayrollLabourTypes(
    $partitionKey: String!
    $sortKey: String!
    $filter: TableLabourTypeFilterInput
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      labourTypes(entityConnection: "LabourType", filter: $filter) {
        items {
          id
          name
          version
          isActive
          createdDate
          createdDateTime
          sortOrder
          payrollCosts(entityConnection: "PayrollCost") {
            items {
              id
              cost
              hourTypeId
              costCodeId
              costCode {
                id
                name
              }
              jobCostTypeId
              jobCostType {
                id
                name
              }
            }
          }
          labourTypeBillingHourTypeProducts(entityConnection: "LabourTypeBillingHourTypeProduct") {
            items {
              id
              billingHourTypeId
              productId
              product {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default getPayrollLabourTypes;
