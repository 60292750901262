import React, { Component } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import AddProductsWithSearch from '../../../../AddProductsWithSearch';
import ErrorBoundaries from 'scenes/Error';

// Formik wrapper for Add products with search to be used in pageform (formik)
class AddProductsWithSearchWrapper extends Component {
  deleteTaskEntry = async record => {
    const { queryResult } = this.props.specialbehaviour;
    const deleteFn = queryResult.deleteService;
    if (deleteFn) {
      await deleteFn(record);
    }
  };

  handleArrayChange = values => {
    const { form, fieldProps } = this.props;
    const fieldName = fieldProps.value;
    form.setFieldValue(fieldName, values, false);
  };

  render() {
    const { form, fieldProps } = this.props;
    const fieldName = fieldProps.value;
    const itemList = form.values[fieldName];
    return (
      <ErrorBoundaries>
        <AddProductsWithSearch
          productArray={itemList || []}
          overrideLabel="Search and add items"
          handleDeleteProduct={this.deleteTaskEntry}
          handleChange={values => this.handleArrayChange(values)}
        />
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const connectedAddProductsWithSearchWrapper = connect(
  mapStateToProps,
  null
)(AddProductsWithSearchWrapper);

export default connectedAddProductsWithSearchWrapper;
