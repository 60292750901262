const styles = theme => ({
  listStyle: {
    borderColor: theme.palette.grayscale(85),
    border: '1px solid',
    borderLeft: 'none',
    borderRight: 'none',
    '&:hover': {
      backgroundColor: theme.palette.grayscale(95)
    }
  },
  listInputStyle: {
    padding: 5
  },
  listTextStyle: {
    fontSize: 16,
    color: theme.palette.grayscale(25)
  },
  listIcon: {
    fontSize: 40,
    color: theme.palette.grayscale(85),
    marginLeft: 10,
    marginRight: 10
  }
});

export default styles;
