import AmplifyService from 'services/AmplifyService';
import getItemGLGroups from './query/getItemGLGroups';

function AccountingService() {
  const api = AmplifyService.appSyncClient();
  return {
    fetchGLGroups: async (partitionKey, sortKey, nextToken) => {
      const params = {
        partitionKey,
        sortKey,
        nextToken
      };
      const response = await api.query(getItemGLGroups, params);
      return response;
    }
  };
}

export default AccountingService;
