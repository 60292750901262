/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getVendors() {
  return await instance
    .get('vendors')
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getVendorById(id) {
  return await instance
    .get(`Vendors/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function vendorSearch(term, searchColumns) {
  if (term.length > 0) {
    return await instance
      .post(`vendors/search/${term}`, { searchColumns: searchColumns || ['name'] })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  }
  return await getVendors();
}

export async function vendorCreate(vendor) {
  return await instance
    .post('vendors', vendor)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function vendorChange(id, vendor) {
  return await instance
    .put(`vendors/${id}`, vendor)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
