import gql from 'graphql-tag';

const SyncIntacctDimensions = gql`
  mutation syncDimensions($tenantId: String!, $data: SyncDimensionInput!) {
    syncDimensions(tenantId: $tenantId, data: $data) {
      id
      name
      type
    }
  }
`;

export default SyncIntacctDimensions;
