/* eslint-disable camelcase */
import React from 'react';
import { attachmentsRows } from 'meta/Jobs/Invoice/review-report-tables';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { ResponsiveTable, Modal, AddRecordButton } from 'components';
import { validations } from 'services/core';
import { capitalizeFirstLetter } from 'utils';
import AttachmentModal from './AddAttachment';
import RowActions from './ReviewReportRowActions';
import { useSetStopLoading } from '../ReviewReport/ReviewReport.hook';

const PhotosAndVideos = ({ classes, current, send, service, isReviewReport }) => {
  const { context, value } = current;
  const setStopLoading = useSetStopLoading(service);
  const attachments = (context.attachments || []).map(attachment => {
    const localAttachment = attachment;
    if (localAttachment.type) {
      localAttachment.displayType = capitalizeFirstLetter(localAttachment.type);
    }
    if (!attachment.addedBy) {
      localAttachment.addedBy = attachment.createdBy;
    }
    return localAttachment;
  });

  const attachmentMeta = [...attachmentsRows];

  if (isReviewReport) {
    attachmentMeta.push({
      id: 'actions',
      isCustom: true,
      type: 'actions',
      label: ''
    });
  }

  const isEditable = isReviewReport && !context.freezeEdit;
  const AttachmentActions = ({ record }) => {
    return (
      <RowActions
        editAction={() => send('EDIT_ATTACHMENT', { data: record })}
        deleteAction={() => send('DELETE_ATTACHMENT', { data: record })}
        checkboxAction={event =>
          send('INCLUDE_ATTACHMENT_INVOICE', {
            data: record,
            checked: event.target.checked
          })
        }
        record={record}
        isEditable={isEditable}
      />
    );
  };

  const open = value?.attachment_new || value?.attachment_edited || false;

  return (
    <>
      <Typography className={classes.sectionTitle}>Photos and Videos</Typography>
      <Divider variant="fullWidth" classes={{ root: classes.blueDivider }} />
      {isEditable && (
        <AddRecordButton label="+ Add photo or video" handle={() => send('ADD_ATTACHMENT')} />
      )}
      <ResponsiveTable
        maxHeight="auto"
        rowMetadata={attachmentMeta}
        data={attachments}
        noDataMsg="No attachment"
        disableFilter
        customCellComponents={{ actions: AttachmentActions }}
        disablePagination
        noMaxHeight
      />
      {open && (
        <AttachmentModal
          open={open}
          data={value.attachment_new ? null : context.modalRecord}
          mode={value.attachment_new ? 'new' : 'edit'}
          parent={context.parentData}
          onComplete={values => send('SAVE', { saveData: values })}
          handleClose={() => send('CLOSE')}
          source={isReviewReport ? 'ReviewReport' : ''}
          validationSchema={validations.reviewReportAttachementSchema}
          title={value.attachment_new ? 'Add Photo or Video' : 'Edit Photo or Video'}
          setStopLoading={setStopLoading}
        />
      )}
    </>
  );
};

export default PhotosAndVideos;
