import gql from 'graphql-tag';
import orderBy from 'lodash/orderBy';

import useExtendedQuery from 'customHooks/useExtendedQuery';

import { getState } from 'redux/store';
import { logErrorWithCallback } from 'utils';

const GET_DEPARTMENTS = gql`
  query getDepartments($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      departments: departmentsView(
        entityConnection: "Department"
        filter: {
          integerFilters: [{ fieldName: "Department.deletedDate", filterInput: { is: null } }]
        }
      ) {
        items {
          id
          tagName
        }
      }
    }
  }
`;

const transformDepartments = data => {
  const departments = data.getCompany.departments?.items?.map(department => ({
    id: department.id,
    name: department.tagName
  }));

  return orderBy(departments, department => department.name.toLowerCase());
};

const useDepartments = () => {
  const state = getState();
  const { tenantId, tenantCompanyId } = state.user;
  const sortKey = `${tenantId}_Company_${tenantCompanyId}`;

  const { data, loading } = useExtendedQuery(GET_DEPARTMENTS, {
    variables: { partitionKey: tenantId, sortKey },
    defaultData: [],
    transform: transformDepartments,
    onError: (err, snackbarOn) => {
      logErrorWithCallback(err, snackbarOn, 'Unable to get departments');
    }
  });

  return [data, loading];
};

export default useDepartments;
