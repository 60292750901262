const styles = theme => ({
  content: {
    fontFamily: theme.typography.fontFamily,
    color: '#3f3f3f',
    fontSize: 14,
    letterSpacing: 0.24,
    fontWeight: 'normal',
    lineHeight: 'normal',
    paddingTop: 7,
    whiteSpace: 'pre-line'
  },
  buttonHover: {
    '&:hover': {
      backgroundColor: '#f6f6f6'
    }
  },
  iconColor: {
    color: '#646464',
    fontSize: 18,
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  footer: {
    minWidth: 238,
    height: 15,
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#959595',
    paddingBottom: 11
  }
});

export default styles;
