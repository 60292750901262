import gql from 'graphql-tag';

const softDeleteTimeCardLine = gql`
  mutation softDeleteTimeCardLine($partitionKey: String, $id: String!) {
    softDeleteTimeCardLine(partitionKey: $partitionKey, id: $id) {
      id
    }
  }
`;

export default softDeleteTimeCardLine;
