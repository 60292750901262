export const skillsModalLayout = () => {
  return {
    fields: {},
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          {
            options: {
              direction: 'column',
              grow: 1,
              padding: 12
            },
            contents: [
              {
                component: 'SeargentSubtitle',
                source: 'Skills Offered'
              },
              {
                // here
                component: {
                  edit: 'SkillsEdit',
                  default: 'SkillsEdit'
                },
                source: 'skills',
                options: {
                  label: 'Add department skill',
                  fullWidth: true,
                  alignment: 'left',
                  readOnly: false,
                  isRequired: false
                }
              }
            ]
          }
        ]
      }
    },
    validation: {
      type: 'object',
      properties: {},
      required: ['tagName']
    },
    validationErrors: {}
  };
};

export const skillsListMeta = [
  {
    id: 'id',
    label: 'Skills Offered',
    isCustom: true,
    type: 'TextInput'
  }
];
