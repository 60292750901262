export const updateRateInfo = (existingValue = [], value) => {
  const cloneValues = [...existingValue];

  const index = existingValue.findIndex(({ hourTypeId }) => hourTypeId === value.hourTypeId);
  if (index >= 0) {
    // id is required for backend to overwrite
    cloneValues[index] = { id: cloneValues[index]?.id, ...value };
  } else {
    cloneValues.push(value);
  }
  return cloneValues;
};

export const getValue = (items = [], id, dataKey, labelKey = 'label') => {
  const value = items.find(({ [dataKey]: itemId }) => itemId === id);
  return value?.[labelKey] || '';
};
