import gql from 'graphql-tag';

const addLabourRate = gql`
  mutation addLabourRate($partitionKey: String, $data: AddPricebookLabourEntriesToPriceBookInput!) {
    addPricebookLabourEntriesToPriceBook(partitionKey: $partitionKey, data: $data) {
      id
      rate
      version
      isActive
      costCodeId
      labourTypeId
      revenueTypeId
      billingHourTypeId
    }
  }
`;

export default addLabourRate;
