import gql from 'graphql-tag';

const getCompanySettings = gql`
  query getCompanySettings(
    $partitionKey: String!
    $sortKey: String!
    $filter: TableCompanySettingFilterInput
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      companySettings(entityConnection: "CompanySetting", filter: $filter) {
        items {
          id
          type
          version
          settings
          isActive
        }
      }
    }
  }
`;

export default getCompanySettings;
