import React, { useState, useRef } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTheme, Box } from '@material-ui/core';
import {
  Button,
  ThemeProvider,
  formatValue,
  FieldType,
  ButtonType,
  Typography,
  TV,
  TW
} from '@buildhero/sergeant';
import { noop } from 'lodash';
import { jsx } from '@emotion/react';

import { UserPermission } from 'components/AppPermissions';
import { TaskListType, PermissionConstants } from 'utils/AppConstants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import { ColumnType } from 'components/XGrid/columnTypes';
import Quotes from './index';
import TaskTable from '../Tasks/TaskTable';
import AddTasksToQuoteModal from '../Tasks/AddTaskToQuote';

const QUOTE_TAB = 'quotes';
const TASK_TAB = 'tasks';

export const QuoteTab = props => {
  const [currentTab, setCurrentTab] = useState(QUOTE_TAB);
  const [fetchOpenTask, toggleFetchOpenTask] = useState(false);
  const [selectedTaskIds, setselectedTaskIds] = useState();

  const [addTasksToQuoteModalOpen, setAddTasksToQuoteModalOpen] = useState(false);

  const getSelectedRowsRef = useRef(noop);

  const isQuoteTab = currentTab === QUOTE_TAB;
  const isTaskTab = currentTab === TASK_TAB;
  const theme = useTheme();
  const flags = useFlags();
  const isMaintenanceV2Enabled = flags[FeatureFlags.MAINTENANCE_TEMPLATES_V2];

  if (!isMaintenanceV2Enabled) return <Quotes {...props} />;

  const grayButtonStyling = {
    backgroundColor: theme.palette.grayscale(94),
    color: theme.palette.grayscale(20)
  };

  const associatedQuoteColumn = {
    field: 'associatedQuote',
    headerName: 'Associated Quote',
    width: 250,
    ...ColumnType.LINK,
    renderCell: ({ api, row }) => {
      const quote = row?.quote;
      if (!quote) return '-';

      const linkValue = {
        label: `Quote ${quote.customIdentifier || quote.quoteNumber}`,
        to: `/quote/${quote.id}`
      };
      return formatValue[FieldType.LINK](linkValue, {
        containerProps: {
          style: { flexWrap: 'unset', gap: '8px' }
        },
        linkProps: { testingid: `record-associatedQuote-${api.getRowIndex()}` }
      });
    }
  };

  return (
    <>
      <ThemeProvider>
        <Box marginBottom={2}>
          <Button
            type={isQuoteTab ? ButtonType.PRIMARY : ButtonType.LEADING}
            onClick={() => setCurrentTab(QUOTE_TAB)}
            css={{
              marginRight: 8,
              ...(isQuoteTab ? {} : grayButtonStyling)
            }}
          >
            Quotes
          </Button>
          <Button
            type={isTaskTab ? ButtonType.PRIMARY : ButtonType.LEADING}
            onClick={() => setCurrentTab(TASK_TAB)}
            css={isTaskTab ? {} : grayButtonStyling}
          >
            Recommended Tasks
          </Button>
        </Box>
      </ThemeProvider>

      {currentTab === QUOTE_TAB && (
        <Box marginTop={-1}>
          <Quotes {...props} />
        </Box>
      )}
      {currentTab === TASK_TAB && (
        <>
          <ThemeProvider>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-end"
              marginBottom={1}
            >
              <Typography variant={TV.BASE} weight={TW.BOLD}>
                Recommended Tasks
              </Typography>
              <UserPermission I="edit" action={PermissionConstants.OBJECT_QUOTES}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Button
                    type={ButtonType.SECONDARY}
                    onClick={() => setAddTasksToQuoteModalOpen(true)}
                    size="small"
                    disabled={!selectedTaskIds || selectedTaskIds.length === 0}
                  >
                    Add To Quote
                  </Button>
                </Box>
              </UserPermission>
            </Box>
          </ThemeProvider>
          <Box height={300}>
            <TaskTable
              customerPropertyId={props.propertyId}
              listType={TaskListType.RECOMMENDED_TASKS}
              showRowActions
              showQuoteTaskStatus
              includeQuote
              associatedWorkXgridColumn={associatedQuoteColumn}
              isActive={props.isActive}
              key={`rTasks${fetchOpenTask}`}
              onSelectionModelChange={newTaskArray => {
                setselectedTaskIds(newTaskArray);
              }}
              selectionModel={selectedTaskIds}
              isRowSelectable={params => !params?.row?.quote}
              getSelectedRowsRef={getSelectedRowsRef}
            />
          </Box>
          <AddTasksToQuoteModal
            open={addTasksToQuoteModalOpen}
            selectedTasks={getSelectedRowsRef.current()}
            onClose={() => setAddTasksToQuoteModalOpen(false)}
            refetchTasks={() => {
              toggleFetchOpenTask(prevState => !prevState);
              setselectedTaskIds([]);
            }}
            customerPropertyId={props.propertyId}
            key={`addTask${fetchOpenTask}`} // to reset the form
          />
        </>
      )}
    </>
  );
};

export default QuoteTab;
