import moment from 'moment';

/* eslint-disable-next-line import/prefer-default-export */
export const getDueDateRangeFromFilter = filter => {
  if (filter.dueDate?.condition === 'between') {
    const values = filter.dueDate.value;
    if (values?.length === 2) {
      return {
        key: 'selection',
        startDate: moment.unix(values[0]).toDate(),
        endDate: moment.unix(values[1]).toDate()
      };
    }
  }
  return {};
};

export const getMinutesFromHrs = (budgetedHours = 0) => Math.round(budgetedHours * 60);
