import gql from 'graphql-tag';

const UpdateCustomerSignature = gql`
  mutation updateCustomerSignature($partitionKey: String, $data: UpdateCustomerSignatureInput!) {
    updateCustomerSignature(partitionKey: $partitionKey, data: $data) {
      id
      nameOfSignee
      signatureImageUrl
      signedDateTime
      capturedBy
      includeInInvoice
      createdBy
      createdDate
      version
    }
  }
`;

export default UpdateCustomerSignature;
