const styles = theme => ({
  auditText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#959595',
    padding: '5px',
    width: 230
  },
  iconButton: {
    color: '#646464',
    fontSize: 18,
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  buttonHover: {
    '&:hover': {
      backgroundColor: '#f6f6f6'
    }
  },
  viewActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

export default styles;
