import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { bool } from 'prop-types';

import DispatchIcon from 'assets/Icons/Dispatch';
import Routes from 'scenes/Routes';
import FeatureFlags from 'utils/FeatureFlagConstants';

import DaySelector from './components/DaySelector';
import DayRangeSelector from './components/DayRangeSelector';
import DayWeekSelector from './components/DayWeekSelector';
import MapViewSelector from './components/MapViewSelector';
import MapSettings from './components/MapSettings';
import VisitsFilterButton from './components/VisitsFilterButton';
import { useStyles } from './DispatchHeader.styles';

const DispatchHeader = ({ mapView }) => {
  const classes = useStyles();
  const isMapViewEnabled = useFlags()[FeatureFlags.DISPATCH_MAP_VIEW];

  return (
    <div className={classes.root}>
      <div className={classes.leftContent}>
        <Link className={classes.header} to={Routes.dispatch()}>
          <DispatchIcon className={classes.icon} />
          <Typography variant="h5" className={classes.pageTitle}>
            Dispatch
          </Typography>
        </Link>
        {isMapViewEnabled && <MapViewSelector />}
        {mapView && (
          <>
            <MapSettings />
            <VisitsFilterButton />
          </>
        )}
      </div>
      <div className={classes.rightContent}>
        <DayWeekSelector />
        {mapView ? <DayRangeSelector /> : <DaySelector />}
      </div>
    </div>
  );
};

DispatchHeader.propTypes = {
  mapView: bool.isRequired
};

export default DispatchHeader;
