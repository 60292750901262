import { useEffect, useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation, useSubscription } from '@apollo/client';
import { isEmpty, omit } from 'lodash';
import { Mode, EntityType } from 'utils/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { formatInvoiceData, formatChangesForUpdate } from './InvoiceDetail.utils';
import { INVOICE_ITEM_FRAGMENT } from './InvoiceItem/InvoiceItem.gql';

export const UPDATE_INVOICE = gql`
  mutation updateInvoice($partitionKey: String, $data: UpdateInvoiceInput!) {
    updateInvoice(partitionKey: $partitionKey, data: $data) {
      id
      status
      syncStatus
      paymentTermName
      paymentTermValue
      issuedDate
      dueDate
      customerProvidedPONumber
      customerProvidedWONumber
      amountNotToExceed
      subtotal
      taxableSubtotal
      totalAmount
      syncLog
      priceBookId
      taxRateId
      salesTaxRate
      accountingRefIdOfSalesTaxRate
      summary
      authorizedBy
      termsOfService
      settingsJSON
      version
    }
  }
`;

const DELETE_INVOICE_TAG = gql`
  mutation deleteInvoiceInvoiceTag($partitionKey: String!, $id: String!) {
    deleteInvoiceInvoiceTag(partitionKey: $partitionKey, id: $id) {
      id
      invoiceTag {
        id
        tagType
        tagName
      }
    }
  }
`;

const UPDATE_INVOICE_TAGS = gql`
  mutation addInvoiceInvoiceTagsToInvoice(
    $partitionKey: String
    $data: AddInvoiceInvoiceTagsToInvoiceInput!
  ) {
    addInvoiceInvoiceTagsToInvoice(partitionKey: $partitionKey, data: $data) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      invoiceTagId
      invoiceTag {
        tagType
        tagName
        sortOrder
        id
        tenantId
        tenantCompanyId
        partitionKey
        sortKey
        hierarchy
        entityType
      }
    }
  }
`;

/**
 * Wrapper around useMutation for updating invoice
 * @param user Authenticated user with tenantId property
 * @param id ID of the invoice to update
 * @param options Optional additional options for useMutation
 */
export const useInvoiceUpdate = (id, options = undefined) => {
  const [updateInvoice, { loading, error }] = useMutation(UPDATE_INVOICE, {
    ...options
  });

  const update = useCallback(
    async data => {
      const formattedData = formatChangesForUpdate(data);
      if (
        !formattedData.syncStatus &&
        !isEmpty(omit(formattedData, ['settingsJSON', 'id', 'version']))
      ) {
        // on invoice change (excluding settings) set syncStatus to null
        formattedData.syncStatus = null;
      }
      return updateInvoice({ variables: { data: { id, ...formattedData } } });
    },
    [updateInvoice, id]
  );

  return [update, loading, error]; // passing the error back is mostly useless since on update failure the error is thrown
};

export const GET_INVOICE = gql`
  query getInvoiceById($id: String!, $adjustmentsFlag: Boolean = false) {
    getInvoiceById(id: $id) {
      id
      tenantId
      tenantCompanyId
      accountingRefId
      jobNumber
      invoiceNumber
      paymentTermName
      paymentTermValue
      issuedDate
      dueDate
      status
      customerProvidedPONumber
      customerProvidedWONumber
      amountNotToExceed
      subtotal
      taxableSubtotal
      totalAmount
      adjustedAmount @include(if: $adjustmentsFlag)
      syncLog
      syncStatus
      priceBookId
      salesTaxRate
      accountingRefIdOfSalesTaxRate
      summary
      authorizedBy
      termsOfService
      version
      settingsJSON
      projectId
      projectName
      adjustmentTransactions @include(if: $adjustmentsFlag) {
        items {
          adjustment {
            number
            id
            transactionType
          }
        }
      }
      serviceAgreement(entityConnection: "ServiceAgreement") {
        id
        agreementNumber
      }
      invoiceInvoiceTags(entityConnection: "InvoiceInvoiceTag", limit: 50) {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          invoiceTag(entityConnection: "InvoiceTag") {
            tagType
            tagName
            sortOrder
            id
            tenantId
            tenantCompanyId
            partitionKey
            sortKey
            hierarchy
            entityType
          }
        }
      }
      taxRateId
      taxRate(entityConnection: "TaxRate") {
        id
        name
        taxRate
        accountingRefId
      }
      amountPaid: aggregatedField(
        input: {
          entityConnection: "PaymentInvoice"
          relationshipType: LookupChild
          aggregations: [{ aggregationType: SUM, aggregationField: "PaymentInvoice.appliedAmount" }]
          pagination: { limit: 1000000000 }
        }
      ) {
        items {
          total: aggregation1
        }
      }
      invoiceItems(entityConnection: "InvoiceItem") {
        items {
          ...ItemFields
        }
      }
      paymentInvoiceView(entityConnection: "PaymentInvoice") {
        items {
          parentId
          invoiceId
          appliedAmount
          parentEntity {
            ... on Payment {
              id
              paymentNumber
              paymentAmount
              paymentDate
              paymentStatus
              paymentType(entityConnection: "PaymentType") {
                id
                name
                ledgerAccountId
                ledgerAccount(entityConnection: "LedgerAccount") {
                  name
                  accountNumber
                  accountType
                  accountingRefId
                  accountingApplication
                  id
                }
              }
            }
          }
          invoice(entityConnection: "Invoice") {
            id
            invoiceNumber
          }
          version
          deletedDate
        }
      }
      companyAddresses(entityConnection: "CompanyAddress") {
        items {
          id
          addressType
          billTo
          addressLine1
          addressLine2
          city
          state
          zipcode
        }
      }
      customer(entityConnection: "Customer") {
        id
        sortKey
        customerName
        invoicePresetId
        notes(entityConnection: "Note") {
          items {
            id
            subject
            note
            lastUpdatedBy
            lastUpdatedDateTime
          }
        }
        customerTags(entityConnection: "CustomerTag", limit: 50) {
          items {
            id
            mappedEntity {
              id
              ... on CustomerTag {
                tagType
                tagName
                sortKey
              }
            }
          }
        }
      }
      billingCustomer(entityConnection: "BillingCustomer") {
        id
        sortKey
        customerName
        invoicePresetId
        companyAddresses(entityConnection: "CompanyAddress") {
          items {
            id
            addressType
            addressLine1
            addressLine2
            billTo
            city
            state
            zipcode
            longitude
            latitude
          }
        }
        notes(entityConnection: "Note") {
          items {
            id
            subject
            note
            lastUpdatedBy
            lastUpdatedDateTime
          }
        }
        customerTags(entityConnection: "CustomerTag", limit: 50) {
          items {
            id
            mappedEntity {
              id
              ... on CustomerTag {
                tagType
                tagName
                sortKey
              }
            }
          }
        }
      }
      customerProperty(entityConnection: "CustomerProperty") {
        id
        sortKey
        companyName
        customerPropertyTypeValue
        notes(entityConnection: "Note") {
          items {
            id
            subject
            note
            lastUpdatedBy
            lastUpdatedDateTime
          }
        }
      }
      job(entityConnection: "Job") {
        id
        jobNumber
        jobTypeName
        jobTypeInternal
        issueDescription
        customerRepName
        costAmount
        amountQuoted
        jobJobTags(entityConnection: "JobJobTag") {
          items {
            id
            entityType
            mappedEntity: jobTag {
              id
              entityType
              tagName
            }
          }
        }
        notes(entityConnection: "Note") {
          items {
            id
            subject
            note
            lastUpdatedBy
            lastUpdatedDateTime
          }
        }
        jcContractContractItems {
          jcContractItemId
          jcContractItemName
        }
      }
      sageJob(entityConnection: "SageJob") {
        name
        code
        parentCode
        isActive
        id
      }
      department(entityConnection: "Department") {
        id
        tagName
        logoUrl
        phonePrimary
        email
        accountingRefIdOfClass
        companyAddresses {
          items {
            addressLine1
            addressLine2
            addressType
            city
            state
            zipcode
          }
        }
      }
      auditLogs(entityConnection: "AuditLogEntry") {
        items {
          auditedEntityType
          auditedEntityDisplayName
          executionType
          executedBy
          executedDateTime
          auditedEntityId
          auditedEntitySortKey
          auditedEntityParentId
          auditedEntityParentSortKey
          auditedEntityParentEntityType
          auditedEntityParentDisplayName
          changeLog
          customMessage
        }
      }
      customerSignatures(entityConnection: "CustomerSignature") {
        items {
          id
          nameOfSignee
          signatureImageUrl
          capturedBy
          signedDateTime
          createdDate
          visit {
            visitNumber
          }
        }
      }
      latestEmail {
        id
        status
        sendCount
        openCount
        bouncedEmails
      }
    }
  }
  ${INVOICE_ITEM_FRAGMENT}
`;

/**
 * Wrapper around useQuery for fetching invoice
 * @param id ID of the invoice to update
 * @param options Optional additional options for the useQuery call
 */
export const useInvoiceQuery = (id, options = undefined) => {
  const { adjustments: adjustmentsFlag } = useFlags();
  const [data, setData] = useState();

  const { loading, error, data: { getInvoiceById: rawData } = {}, ...rest } = useQuery(
    GET_INVOICE,
    {
      variables: { id, adjustmentsFlag },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      ...options
    }
  );

  useEffect(() => {
    const run = async () => {
      const result = await formatInvoiceData({ ...rawData, adjustmentsFlag }, data);
      setData(result);
    };
    if (rawData) run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData]);

  return { loading: loading || !data, error, data, ...rest };
};

const INVOICE_SUBSCRIPTION = gql`
  subscription mutationNotification(
    $partitionKey: String!
    $entityType: String
    $entityId: String
  ) {
    mutationNotification(
      partitionKey: $partitionKey
      entityType: $entityType
      entityId: $entityId
    ) {
      id
      sortKey
      entityType
      mutationType
      changeLog
    }
  }
`;

// only update following field (for accounting integration)
const SUBSCRIPTION_FIELDS = ['status', 'syncStatus', 'syncLog'];

export const useInvoiceSubscription = async (tenantId, id) => {
  const { adjustments: adjustmentsFlag } = useFlags();

  useSubscription(INVOICE_SUBSCRIPTION, {
    fetchPolicy: 'no-cache',
    variables: {
      partitionKey: tenantId,
      entityType: EntityType.INVOICE,
      entityId: id
    },
    onSubscriptionData: ({ subscriptionData, client }) => {
      const changeLog = JSON.parse(subscriptionData.data.mutationNotification.changeLog);
      const newData = changeLog?.reduce(
        (changes, change) =>
          SUBSCRIPTION_FIELDS.includes(change.field)
            ? { ...changes, [change.field]: change.new }
            : changes,
        {}
      );
      if (!isEmpty(newData)) {
        client.writeQuery({
          query: GET_INVOICE,
          variables: { id, adjustmentsFlag },
          data: { getInvoiceById: { ...newData, __typename: 'Invoice' } }
        });
      }
    }
  });
};

/**
 * Wrapper around useMutation for updating invoice tags
 * @param user Authenticated user with tenantId property
 * @param id ID of the invoice to update
 * @param options Optional additional options for useMutation
 */
export const useMutateInvoiceTags = (id, options = undefined) => {
  const [updateInvoiceTags, { loading: addingTags, error: errorAddingTags }] = useMutation(
    UPDATE_INVOICE_TAGS,
    {
      update: (cache, { data: { addInvoiceInvoiceTagsToInvoice } }) => {
        cache.modify({
          id: `Invoice:${id}`,
          fields: {
            invoiceInvoiceTags: ({ items: existingItems = [] }, { readField }) => {
              const newTags = addInvoiceInvoiceTagsToInvoice
                .map(t => {
                  return cache.writeFragment({
                    id: `InvoiceInvoiceTag:${t.id}`,
                    fragment: gql`
                      fragment TagFields on InvoiceInvoiceTag {
                        id
                      }
                    `
                  });
                })
                .filter(
                  t => !existingItems.some(item => readField('id', item) === readField('id', t))
                );

              return { items: [...existingItems, ...newTags] };
            }
          }
        });
      },
      ...options
    }
  );

  const update = useCallback(async data => updateInvoiceTags({ variables: data }), [
    updateInvoiceTags
  ]);

  const [deleteInvoiceTag, { loading: deletingTag, error: errorDeletingTag }] = useMutation(
    DELETE_INVOICE_TAG,
    {
      update: (cache, { data: { deleteInvoiceInvoiceTag } }) => {
        cache.modify({
          id: `Invoice:${id}`,
          fields: {
            invoiceInvoiceTags: ({ items: existingItems = [] }, { readField }) => ({
              items: existingItems.filter(
                item => deleteInvoiceInvoiceTag?.id !== readField('id', item)
              )
            })
          }
        });
      },
      ...options
    }
  );

  const del = useCallback(
    async (partitionKey, tagId) => deleteInvoiceTag({ variables: { partitionKey, id: tagId } }),
    [deleteInvoiceTag]
  );

  const mutateInvoiceTags = mode => {
    switch (mode) {
      case Mode.ADD: {
        return update;
      }
      case Mode.DELETE: {
        return del;
      }
      default: {
        return () => {};
      }
    }
  };

  return [mutateInvoiceTags, addingTags || deletingTag, errorAddingTags || errorDeletingTag]; // passing the error back is mostly useless since on update failure the error is thrown
};
