import gql from 'graphql-tag';

const deleteQuoteQuoteTag = gql`
  mutation deleteQuoteQuoteTag($partitionKey: String!, $id: String!, $deletedFromQuoteId: String!) {
    deleteQuoteQuoteTag(
      partitionKey: $partitionKey
      id: $id
      deletedFromQuoteId: $deletedFromQuoteId
    ) {
      id
      quoteTag {
        id
        tagType
        tagName
      }
    }
  }
`;

export default deleteQuoteQuoteTag;
