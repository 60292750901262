import gql from 'graphql-tag';

const updateProductBundleEntry = gql`
  mutation updateProductBundleEntry($partitionKey: String!, $data: UpdateProductBundleEntryInput!) {
    updateProductBundleEntry(partitionKey: $partitionKey, data: $data) {
      id
    }
  }
`;
export default updateProductBundleEntry;
