import gql from 'graphql-tag';

const getCompanyClosedPeriod = gql`
  query getEntityById($partitionKey: String!, $id: String!) {
    getEntityById(partitionKey: $partitionKey, id: $id, entityConnection: "Company") {
      ... on Company {
        closedPeriods(entityConnection: "ClosedPeriod") {
          items {
            id
            closeDate
            accountingRefId
          }
        }
      }
    }
  }
`;

export default getCompanyClosedPeriod;
