import _ from 'lodash';

export const mapToOptions = employees =>
  _.sortBy(
    employees.map(employee => ({
      label: employee.code ? `${employee.name} (${employee.code})` : employee.name,
      value: employee
    })),
    'label'
  );

export const timesheetFilter = ({ filter, employees, crews }) =>
  mapToOptions(
    employees.filter(e => {
      if (filter?.technicians?.length && !filter.technicians.includes(e.id)) {
        return false;
      }
      if (
        filter?.departments?.length &&
        !filter.departments.find(d => e.departments.items.map(ed => ed.id).includes(d))
      ) {
        return false;
      }

      if (filter?.crews?.length) {
        const employeeCrews = crews
          .filter(c => c.techs.map(t => t.id).includes(e.id))
          .map(c => c.id);

        if (!filter.crews.find(c => employeeCrews.includes(c))) return false;
      }

      return true;
    })
  );
