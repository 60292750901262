import React from 'react';
import { bool, array, func } from 'prop-types';

import FilterButton, { FilterTypes } from '@dispatch/components/FilterButton';
import { VisitStatuses } from '@dispatch/Dispatch.constants';

const filterText = {
  visitStatuses: {
    label: 'Visit Status',
    placeholder: 'All Statuses'
  },
  showUnscheduled: {
    label: 'Show Unassigned Visits with no Date'
  }
};

const filterOptions = {
  visitStatuses: {
    data: VisitStatuses.enums.reduce((valueSet, Status) => {
      if (Status.value.showOnMap) {
        return [
          ...valueSet,
          {
            id: Status.key,
            name: Status.value.displayValue
          }
        ];
      }

      return valueSet;
    }, [])
  },
  showUnscheduled: {
    type: FilterTypes.switch
  }
};

const VisitsFilterButton = ({ filterBy, showUnscheduled, visitStatuses }) => {
  const handleFilterChange = (field, value) => {
    filterBy({ [field]: value });
  };

  const handleFiltersClear = () => {
    filterBy({
      visitStatuses: undefined,
      showUnscheduled: undefined
    });
  };

  return (
    <FilterButton
      buttonText="Filter Visits By..."
      filterState={{ showUnscheduled, visitStatuses }}
      filterOptions={filterOptions}
      filterText={filterText}
      onFilterChange={handleFilterChange}
      onFiltersClear={handleFiltersClear}
    />
  );
};

VisitsFilterButton.propTypes = {
  filterBy: func.isRequired,
  showUnscheduled: bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  visitStatuses: array
};

export default VisitsFilterButton;
