import React from 'react';

import Typography from '@material-ui/core/Typography';
// eslint-disable-next-line import/imports-first
import { bool } from 'prop-types';

import { useStyles } from './Title.styles';

const Title = ({ noTitle, smallType }) => {
  const styles = useStyles({ smallType });
  if (noTitle) {
    return null;
  }

  return <Typography css={styles.title}>Attachments</Typography>;
};

Title.propTypes = {
  noTitle: bool.isRequired,
  smallType: bool.isRequired
};

export default Title;
