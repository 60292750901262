import React, { useCallback, useState } from 'react';

import { Button, ThemeProvider, TV, Typography } from '@buildhero/sergeant';
import { DeleteOutlined, EditOutlined } from '@material-ui/icons';
import { any, array, bool, func, string } from 'prop-types';

import WrapTable, { RowActionsMenu } from 'components/WrapTable';
import { TablePlaceholderVariant } from 'components/WrapTable/WrapTable.constants';

import { selectReviewReportPurchasedLineItemRows } from '../../../../../../selectors';
import PurchasedItem from '../../../../../PartsAndMaterialsSection/components/PurchasedItems/components/PurchasedItem';
import PurchasedLineItemsTable from '../../../../../PartsAndMaterialsSection/components/PurchasedItems/components/PurchasedLineItemsTable';

import PurchaseOrderLineModal from './components/PurchaseOrderLineModal';
import PurchaseOrderModal from './components/PurchaseOrderModal';
import { usePurchaseOrderLinesColumns } from './ReviewReportPurchasedItems.columns';
import { convertToFileInput, purchaseOrderHasItems } from './ReviewReportPurchasedItems.helpers';
import { useStyles } from './ReviewReportPurchasedItems.styles';

const PurchaseOrders = ({
  priceBookId,
  items,
  departmentName,
  loading,
  updating,
  disabled,
  error,
  onAddPurchaseOrder,
  onEditPurchaseOrder,
  onDeletePurchaseOrder,
  onAddPurchaseOrderLine,
  onEditPurchaseOrderLine,
  onDeletePurchaseOrderLine,
  onAddPurchaseOrderLineToInvoice,
  readOnly
}) => {
  const [purchaseOrderToAdd, setPurchaseOrderToAdd] = useState();
  const closeAddModal = () => setPurchaseOrderToAdd(undefined);

  const [purchaseOrderToEdit, setPurchaseOrderToEdit] = useState();
  const closeEditModal = () => setPurchaseOrderToEdit(undefined);

  const [purchaseOrderLineToAdd, setPurchaseOrderLineToAdd] = useState();
  const closeAddLineModal = () => setPurchaseOrderLineToAdd(undefined);

  const [purchaseOrderLineToEdit, setPurchaseOrderLineToEdit] = useState();
  const closeEditLineModal = () => setPurchaseOrderLineToEdit(undefined);

  const openEditModal = async purchaseOrder => {
    const receipt = purchaseOrder?.purchaseOrderReceipts?.items?.[0];
    const fileName = receipt?.fileName;
    const fileUrl = receipt?.imageUrl;
    setPurchaseOrderToEdit({
      ...purchaseOrder,
      customFileName: fileName
    });

    if (fileUrl) {
      const fileInput = await convertToFileInput({ fileName, fileUrl });
      setPurchaseOrderToEdit({
        ...purchaseOrder,
        customFileName: receipt?.fileName,
        newFiles: fileInput?.newFiles
      });
    }
  };

  const styles = useStyles();
  const purchaseItemActions = purchaseOrder => [
    {
      icon: EditOutlined,
      label: 'Edit',
      onClick: openEditModal,
      disabled
    },
    {
      icon: DeleteOutlined,
      label: 'Delete',
      onClick: onDeletePurchaseOrder,
      disabled: disabled || purchaseOrderHasItems(purchaseOrder)
    }
  ];

  const handleAddPurchaseOrderClick = () => {
    setPurchaseOrderToAdd({ departmentName });
  };

  const handleAddPurchaseOrder = async purchaseOrder => {
    await onAddPurchaseOrder(purchaseOrder);
    closeAddModal();
  };

  const handleEditPurchaseOrder = async purchaseOrder => {
    await onEditPurchaseOrder(purchaseOrder, purchaseOrderToEdit);
    closeEditModal();
  };

  const handleAddPurchaseOrderItemClick = purchaseOrder => () => {
    setPurchaseOrderLineToAdd({ departmentName, parentId: purchaseOrder?.id });
  };

  const handleAddPurchaseOrderLine = async purchaseOrderLine => {
    await onAddPurchaseOrderLine(purchaseOrderLine);
    closeAddLineModal();
  };

  const handleEditPurchaseOrderItemClick = purchaseOrderLine => {
    setPurchaseOrderLineToEdit({
      ...purchaseOrderLine,
      departmentName
    });
  };

  const handleEditPurchaseOrderLine = async purchaseOrderLine => {
    await onEditPurchaseOrderLine(purchaseOrderLine);
    closeEditLineModal();
  };

  const isPurchaseOrderLineAddedToInvoice = useCallback(
    purchaseOrderLine => Boolean(purchaseOrderLine?.includeInInvoice),
    []
  );

  const purchaseOrderLinesColumns = usePurchaseOrderLinesColumns({
    disabled,
    updating,
    onEditPurchaseOrderLine: handleEditPurchaseOrderItemClick,
    onDeletePurchaseOrderLine,
    onAddPurchaseOrderLineToInvoice,
    isPurchaseOrderLineAddedToInvoice
  });

  return (
    <>
      {!readOnly && (
        <div css={styles.headerContainer}>
          <div>
            <Typography css={styles.title}>Purchased items</Typography>
          </div>
          <ThemeProvider>
            <Button
              type="secondary"
              size="small"
              disabled={disabled}
              onClick={handleAddPurchaseOrderClick}
            >
              Add Receipt
            </Button>
          </ThemeProvider>
        </div>
      )}
      {items.map(item => {
        const lineItems = selectReviewReportPurchasedLineItemRows(item);
        return (
          <div key={item?.id}>
            <div css={styles.container}>
              <ThemeProvider>
                <PurchasedItem item={item} />
                <RowActionsMenu row={item} actions={purchaseItemActions(item)} />
              </ThemeProvider>
            </div>
            <div css={styles.tableHeader}>
              <ThemeProvider>
                <Typography css={styles.tableHeaderText} variant={TV.S1}>
                  Selected items will be added to the invoice
                </Typography>
                {!readOnly && (
                  <Button
                    css={styles.tableButtons}
                    type="tertiary"
                    size="small"
                    disabled={disabled}
                    onClick={handleAddPurchaseOrderItemClick(item)}
                  >
                    Add Purchase Line Item
                  </Button>
                )}
              </ThemeProvider>
            </div>
            <div css={styles.table}>
              <WrapTable
                placeholderVariant={TablePlaceholderVariant.TEXT}
                columns={purchaseOrderLinesColumns}
                rows={lineItems}
                loading={loading}
                error={error}
                noDataMessage="No Purchased Items"
              />
            </div>
          </div>
        );
      })}

      {!items.length && <PurchasedLineItemsTable lineItems={[]} loading={loading} error={error} />}

      <PurchaseOrderLineModal
        priceBookId={priceBookId}
        purchaseOrders={items}
        purchaseOrderLine={purchaseOrderLineToAdd}
        open={Boolean(purchaseOrderLineToAdd)}
        mode="add"
        onClose={closeAddLineModal}
        onAction={handleAddPurchaseOrderLine}
      />

      <PurchaseOrderLineModal
        priceBookId={priceBookId}
        departmentName={departmentName}
        purchaseOrders={items}
        purchaseOrderLine={purchaseOrderLineToEdit}
        open={Boolean(purchaseOrderLineToEdit)}
        mode="edit"
        onClose={closeEditLineModal}
        onAction={handleEditPurchaseOrderLine}
      />

      <PurchaseOrderModal
        departmentName={departmentName}
        mode="add"
        onAction={handleAddPurchaseOrder}
        onClose={closeAddModal}
        open={Boolean(purchaseOrderToAdd)}
        purchaseOrder={purchaseOrderToAdd}
      />

      <PurchaseOrderModal
        departmentName={departmentName}
        mode="edit"
        onAction={handleEditPurchaseOrder}
        onClose={closeEditModal}
        open={Boolean(purchaseOrderToEdit)}
        purchaseOrder={purchaseOrderToEdit}
      />
    </>
  );
};

PurchaseOrders.propTypes = {
  priceBookId: string,
  items: array.isRequired,
  departmentName: string.isRequired,
  loading: bool.isRequired,
  updating: bool.isRequired,
  error: any,
  disabled: bool.isRequired,
  onAddPurchaseOrder: func.isRequired,
  onEditPurchaseOrder: func.isRequired,
  onDeletePurchaseOrder: func.isRequired,
  onAddPurchaseOrderLine: func.isRequired,
  onEditPurchaseOrderLine: func.isRequired,
  onDeletePurchaseOrderLine: func.isRequired,
  onAddPurchaseOrderLineToInvoice: func.isRequired,
  readOnly: bool
};

PurchaseOrders.defaultProps = {
  priceBookId: undefined,
  error: undefined,
  // Used in reports having both simple purchase orders and procurement POs. This can only happen during the transition period
  readOnly: false
};

export default PurchaseOrders;
