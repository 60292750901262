import React, { Component } from 'react';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import moment from 'moment';
import { Typography, Button, ButtonType, ThemeProvider } from '@buildhero/sergeant';
import { withStyles } from '@material-ui/core/styles';
import { QuickbooksService, PaymentService, PurchaseOrderService } from 'services/core';
import AmplifyService from 'services/AmplifyService';
import { TaxRateLayout, TaxRateColumns } from 'meta/Settings/Accounting/TaxRateLayout';
import { PaymentTypeLayout, PaymentTypeColumns } from 'meta/Settings/Accounting/PaymentTypeLayout';
import { PaymentTermLayout, PaymentTermColumns } from 'meta/Settings/Accounting/PaymentTermLayout';
import {
  PurchaseOrderTypeLayout,
  PurchaseOrderTypeColumns
} from 'meta/Settings/Accounting/PurchaseOrderTypeLayout';
import {
  AdjustmentTypeLayout,
  GetAdjustmentTypeColumns
} from 'meta/Settings/Accounting/AdjustmentTypeLayout';
import {
  PageHeader,
  UserPermission,
  ResponsiveTable,
  SergeantModal,
  Tabs,
  Tab,
  Context
} from 'components';
import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import {
  glAccountsRows,
  syncLogRows,
  qbClassesRows,
  dimensionMeta,
  bankAccountMeta,
  itemGLGroupMeta,
  sageJobRows
} from 'meta/Settings/Accounting/tables';
import { PermissionConstants } from 'utils/AppConstants';
import { checkPermission, logErrorWithCallback } from 'utils';
import ErrorBoundaries from 'scenes/Error';
import { AccountingApp, Mode, PaymentTermType } from 'utils/constants';
import PlacesSearch from 'components/BuildHeroFormComponents/PlacesSearch';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import DisplayData from 'components/ResponsiveTable/DisplayData';
import { updateCompany } from './gql';
import AccountingService from './accountingService';
import ClosedPeriodModal from './ClosedPeriodModal';
import styles from './styles';

const DataType = {
  AdjustmentType: 'Adjustment Type',
  PaymentType: 'Payment Type',
  PaymentTerm: 'Payment Term',
  TaxRate: 'Tax Rate',
  PurchaseOrderType: 'Purchase Order Type'
};

class Accounting extends Component {
  modalAction = {
    [DataType.AdjustmentType]: {
      [Mode.ADD]: data =>
        this.accountingService.upsertAdjustmentType(
          this.props.user.tenantId,
          this.formatAdjustmentType(data)
        ),
      [Mode.EDIT]: data =>
        this.accountingService.upsertAdjustmentType(
          this.props.user.tenantId,
          this.formatAdjustmentType(data)
        ),
      [Mode.DELETE]: data =>
        this.accountingService.deleteAdjustmentType(this.props.user.tenantId, data.id)
    },
    [DataType.PaymentType]: {
      [Mode.ADD]: data => {
        const payload = {
          companyId: this.props.user.tenantCompanyId,
          paymentTypes: [this.formatPaymentType(data)]
        };
        return this.paymentService.addPaymentTypesToCompany(this.props.user.tenantId, payload);
      },
      [Mode.EDIT]: data =>
        this.paymentService.updatePaymentType(
          this.props.user.tenantId,
          this.formatPaymentType(data)
        ),
      [Mode.DELETE]: data =>
        this.paymentService.deletePaymentType(this.props.user.tenantId, data.id)
    },
    [DataType.PaymentTerm]: {
      [Mode.ADD]: data =>
        this.paymentService.upsertPaymentTerm(
          this.props.user.tenantId,
          this.formatPaymentTerm(data)
        ),
      [Mode.EDIT]: data =>
        this.paymentService.upsertPaymentTerm(
          this.props.user.tenantId,
          this.formatPaymentTerm(data)
        ),
      [Mode.DELETE]: data =>
        this.paymentService.deletePaymentTerm(this.props.user.tenantId, data.id)
    },
    [DataType.TaxRate]: {
      [Mode.ADD]: async data => {
        const { user } = this.props;
        const payload = this.formatTaxRate(data);
        payload.tenantCompanyId = user.tenantCompanyId;

        return this.quickbooksService.addTaxRate(user.tenantId, payload);
      },
      [Mode.EDIT]: async data =>
        this.quickbooksService.updateTaxRate(this.props.user.tenantId, this.formatTaxRate(data)),
      [Mode.DELETE]: async () => {
        const { modal } = this.state;
        const payload = this.formatTaxRate(modal.data);
        payload.isActive = false;

        // We don't have a mutation for delete tax rate for some reason..
        return this.quickbooksService.updateTaxRate(this.props.user.tenantId, payload);
      }
    },
    [DataType.PurchaseOrderType]: {
      [Mode.ADD]: purchaseOrderType =>
        this.purchaseOrderService.savePurchaseOrderTypeToCompany(
          this.props.user.tenantId,
          this.formatPurchaseOrderType(purchaseOrderType)
        ),
      [Mode.EDIT]: purchaseOrderType =>
        this.purchaseOrderService.savePurchaseOrderTypeToCompany(
          this.props.user.tenantId,
          this.formatPurchaseOrderType(purchaseOrderType)
        ),
      [Mode.DELETE]: purchaseOrderType =>
        this.purchaseOrderService.deletePurchaseOrderType(
          this.props.user.tenantId,
          purchaseOrderType.id
        )
    }
  };

  entitiesOrder = {
    PAYMENT_TYPE: 0,
    TAX_RATE: 1,
    GL_ACCOUNT: 2,
    CLASS: 3,
    SYNC_LOG: 4,
    SAGE_JOB: 5,
    DIMENSION: 6,
    ITEM_GL_GROUP: 7,
    BANK_ACCOUNT: 8,
    PAYMENT_TERM: 9,
    ADJUSTMENT_TYPE: 10,
    PURCHASE_ORDER_TYPE: 11
  };

  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.client = AmplifyService.appSyncClient().client;
    this.quickbooksService = new QuickbooksService();
    this.paymentService = new PaymentService();
    this.accountingService = new AccountingService();
    this.purchaseOrderService = new PurchaseOrderService();
    this.departmentNames = new Map();
    this.state = {
      isIntegrationEnabled: null,
      isQuickbooksEnabled: false,
      isSageEnabled: false,
      showClosedPeriod: false,
      company: {},
      isLoadingDefault: false,
      modal: {
        open: false,
        data: null,
        layout: {},
        mode: Mode.ADD,
        confirmRemoveItemLabel: null,
        dataType: '',
        importing: false
      },
      departmentOptions: []
    };
  }

  componentDidMount = async () => {
    document.title = 'BuildOps - Accounting';
    const { user, flags, settings } = this.props;
    const accountingIntegration = settings.accountingApp;
    const isIntegrationEnabled = !!accountingIntegration;
    const isSageEnabled = accountingIntegration === AccountingApp.SAGE;
    const isQuickbooksEnabled = accountingIntegration === AccountingApp.QUICKBOOKS;
    const isSpectrumEnabled = accountingIntegration === AccountingApp.SPECTRUM;
    const isIntacctEnabled = accountingIntegration === AccountingApp.INTACCT;
    let entities;
    if (
      user.tenantId &&
      user.tenantCompanyId &&
      checkPermission('allow', PermissionConstants.MENU_ACCOUNTING, this.props.user)
    ) {
      entities = [
        {
          syncFn: this.quickbooksService.syncPaymentTypes,
          queryFn: this.paymentService.getActivePaymentTypesForCompany,
          name: 'paymentTypes',
          displayName: 'payment types',
          index: this.entitiesOrder.PAYMENT_TYPE
        },
        {
          syncFn: this.quickbooksService.syncTaxRates,
          queryFn: this.quickbooksService.getTaxRates,
          name: 'taxRates',
          displayName: 'tax rates',
          index: this.entitiesOrder.TAX_RATE
        },
        isIntegrationEnabled && {
          syncFn: this.quickbooksService.syncLedgerAccounts,
          queryFn: this.quickbooksService.getGLAccounts,
          name: 'ledgerAccounts',
          displayName: 'GL Accounts',
          index: this.entitiesOrder.GL_ACCOUNT
        },
        (isQuickbooksEnabled || isIntacctEnabled || isSpectrumEnabled) && {
          syncFn: this.quickbooksService.syncClasses,
          queryFn: this.quickbooksService.getClasses,
          name: 'classes',
          displayName: 'classes',
          index: this.entitiesOrder.CLASS
        },
        isIntegrationEnabled && {
          queryFn: this.quickbooksService.getSyncLogs,
          name: 'syncEntriesInRange',
          displayName: 'sync log entries',
          index: this.entitiesOrder.SYNC_LOG
        },
        isSageEnabled && {
          syncFn: this.quickbooksService.syncSageJobs,
          queryFn: this.quickbooksService.getSageJobs,
          name: 'sageJobs',
          displayName: 'sage jobs',
          index: this.entitiesOrder.SAGE_JOB
        },
        isIntacctEnabled && {
          syncFn: this.accountingService.syncIntacctDimensions,
          queryFn: this.accountingService.fetchIntact,
          name: 'dimensions',
          displayName: 'Intacct',
          index: this.entitiesOrder.DIMENSION
        },
        isIntacctEnabled && {
          syncFn: this.accountingService.syncBankAccounts,
          queryFn: this.accountingService.fetchBankAccounts,
          name: 'bankAccounts',
          displayName: 'Intacct',
          index: this.entitiesOrder.BANK_ACCOUNT
        },
        isIntacctEnabled && {
          syncFn: this.accountingService.syncItemGLGroups,
          queryFn: this.accountingService.fetchGLGroups,
          name: 'itemGlGroups',
          displayName: 'Intacct',
          index: this.entitiesOrder.ITEM_GL_GROUP
        },
        {
          queryFn: this.paymentService.getPaymentTermsForCompany,
          name: 'paymentTerms',
          displayName: 'payment terms',
          index: this.entitiesOrder.PAYMENT_TERM
        },
        (isIntacctEnabled || isQuickbooksEnabled || isSpectrumEnabled) && {
          queryFn: this.accountingService.getAdjustmentTypes,
          name: 'adjustmentTypes',
          displayName: 'adjustment types',
          index: this.entitiesOrder.ADJUSTMENT_TYPE
        },
        {
          queryFn: this.purchaseOrderService.getPurchaseOrderTypesForCompany,
          name: 'purchaseOrderTypes',
          displayName: 'purchase order types',
          index: this.entitiesOrder.PURCHASE_ORDER_TYPE
        }
      ];
      entities.filter(Boolean).forEach(this.query);
    }

    const {
      id,
      version,
      defaultPaymentTermId,
      defaultLedgerAccountId,
      defaultBankAccountId,
      departments
    } = this.props.company;

    const departmentOptions = departments.items.map(({ tagName, id }) => {
      this.departmentNames.set(id, tagName);
      return {
        label: tagName,
        value: id
      };
    });

    this.setState({
      company: { id, version, defaultPaymentTermId, defaultLedgerAccountId, defaultBankAccountId },
      accountingIntegration,
      isIntegrationEnabled,
      entities,
      isQuickbooksEnabled,
      isSpectrumEnabled,
      isIntacctEnabled,
      isSageEnabled,
      departmentOptions
    });
  };

  query = async ({ queryFn, name, displayName }) => {
    const { user } = this.props;
    let nextToken = null;

    try {
      do {
        // eslint-disable-next-line no-await-in-loop
        const { data } = await queryFn(
          user.tenantId,
          `${user.tenantId}_Company_${user.tenantCompanyId}`,
          moment()
            .subtract(7, 'd')
            .unix(),
          moment().unix(),
          nextToken
        );
        if (data && this.mounted) {
          const items = data.getCompany?.[name]?.items || [];
          // when nextToken is avaiable, that is when we need to mindfull of prev states
          if (nextToken) {
            this.setState(prevState => ({ [name]: [...items, ...(prevState[name] || [])] }));
          } else {
            this.setState({ [name]: [...items] });
          }
          // override with new next token
          nextToken = data.getCompany?.[name]?.nextToken;
        }
      } while (nextToken);
    } catch (error) {
      logErrorWithCallback(
        'error',
        this.props.snackbarOn,
        `Unable to fetch ${displayName}, please try again later`,
        error
      );
    }
  };

  sync = async ({ syncFn, queryFn, name, displayName }) => {
    const { user } = this.props;
    this.setState({ importing: true });
    try {
      const { data } = await syncFn(user.tenantId, user.tenantCompanyId);
      if (data && this.mounted) {
        const items = data[Object.keys(data)[0]] || [];
        await this.query({ queryFn, name, displayName });
        this.props.snackbarOn('success', `Synced ${items.length} ${displayName}`);
      }
    } catch (error) {
      logErrorWithCallback(
        error,
        this.props.snackbarOn,
        `Unable to sync ${displayName}, please try again later`
      );
    } finally {
      this.setState({ importing: false });
    }
  };

  formatAdjustmentType = values => ({
    companyId: this.props.user.tenantCompanyId,
    name: values.name,
    type: values.type,
    id: values.id,
    ledgerAccountId: values.ledgerAccountId,
    ledgerOffsetAccountId: values.ledgerOffsetAccountId
  });

  formatPaymentType = values => ({
    name: values?.name,
    id: values?.id,
    version: values?.version,
    ledgerAccountId: values?.ledgerAccountId
  });

  formatPaymentTerm = values => ({
    companyId: this.props.user.tenantCompanyId,
    id: values.id,
    name: values.name.trim(),
    value: values.value,
    type: values.type
  });

  formatTaxRate = values => ({
    name: values?.name,
    description: values?.description,
    taxRate: values?.taxRate || 0,
    accountType: values?.accountType || null,
    city: values?.city || null, // set to null to inidicate delete b/c undefined won't save
    state: values?.state || null,
    county: values?.county || null,
    id: values?.id,
    version: values?.version
  });

  formatPurchaseOrderType = purchaseOrderType => ({
    companyId: this.props.user.tenantCompanyId,
    purchaseOrderTypes: [
      {
        name: purchaseOrderType.name,
        departmentIds: purchaseOrderType.departmentIds,
        autoReceive: purchaseOrderType.autoReceive || false,
        id: purchaseOrderType.id
      }
    ]
  });

  getDepartmentNamesFromMap = ids => {
    const tagNames = [];
    ids.forEach(id => {
      const tagName = this.departmentNames.get(id);
      if (tagName) tagNames.push(tagName);
    });
    return tagNames.join(', ');
  };

  modalMeta = data => ({
    [DataType.AdjustmentType]: {
      layout: AdjustmentTypeLayout(
        this.state.ledgerAccounts?.map(glAccount => ({
          value: glAccount.id,
          label: glAccount.name
        })),
        this.state.accountingIntegration
      ),
      confirmRemoveItemLabel: data?.name
    },
    [DataType.PaymentType]: {
      layout: PaymentTypeLayout(
        this.state.isIntegrationEnabled,
        this.state.isSageEnabled,
        this.state.isIntacctEnabled,
        // filtering by type since paymentType "account to deposit" to must be a bank or other current asset account
        this.state.ledgerAccounts
          ?.filter(glAccount =>
            ['Bank', 'OtherCurrentAsset', 'Other Current Asset'].includes(glAccount.accountType)
          )
          ?.map(glAccount => ({
            value: glAccount.id,
            label: glAccount.name
          }))
      ),
      confirmRemoveItemLabel: data?.name
    },
    [DataType.PaymentTerm]: {
      layout: PaymentTermLayout,
      confirmRemoveItemLabel: data?.name
    },
    [DataType.TaxRate]: {
      layout: TaxRateLayout(this.state.isIntegrationEnabled && !this.state.isIntacctEnabled),
      confirmRemoveItemLabel: data?.name
    },
    [DataType.PurchaseOrderType]: {
      layout: PurchaseOrderTypeLayout(this.state.departmentOptions),
      confirmRemoveItemLabel: data?.name
    }
  });

  updateLocally = (newData, dataType, mode) => {
    const arrayName = `${dataType.charAt(0).toLowerCase()}${dataType.replace(/ /g, '').slice(1)}s`;

    this.setState(prev => {
      const itemArray = prev[arrayName];

      if (mode === Mode.ADD) itemArray.unshift(newData?.[0] || newData);
      else {
        const indexOfItem = itemArray.findIndex(i => i.id === newData.id);
        if (mode === Mode.EDIT) itemArray[indexOfItem] = newData;
        else if (mode === Mode.DELETE) itemArray.splice(indexOfItem, 1);
      }

      return {
        [arrayName]: [...itemArray]
      };
    });
  };

  handlePrimaryAction = async (data, modalCallback) => {
    const { user } = this.props;
    const { dataType, mode } = this.state.modal;
    try {
      const { data: responseData } = await this.modalAction[dataType][mode](data);
      // flatten gql response
      const flatData = responseData[Object.keys(responseData)[0]];
      this.updateLocally(flatData, dataType, mode);
      // to update context cache which is cache locally in browser
      Context.setCompanyContext(
        user.tenantId,
        Context.generateCompanyContextSortKey(user),
        () => console.log('refreshed'),
        true
      );
      this.props.snackbarOn('success', `Successfully ${Mode.past(mode)} ${dataType}.`);
      this.handleModalClose();
    } catch (error) {
      logErrorWithCallback(
        error,
        this.props.snackbarOn,
        `Unable to ${mode} ${dataType}, please try again later.`
      );
    } finally {
      modalCallback();
    }
  };

  handleModalOpen = async (dataType, mode, data = {}) => {
    const { layout, confirmRemoveItemLabel } = this.modalMeta(data)[dataType];

    this.setState({
      modal: { open: true, data, dataType, mode, layout, confirmRemoveItemLabel }
    });
  };

  handleModalClose = () => {
    const { modal } = this.state;
    modal.open = false;
    this.setState({ modal });
  };

  setDefaultLedgerAccount = async id => {
    const { user, snackbarOn } = this.props;
    this.setState(s => ({
      isLoadingDefault: true,
      company: { ...s.company, defaultLedgerAccountId: id }
    }));
    try {
      const { data } = await this.client.mutate({
        mutation: updateCompany,
        variables: {
          partitionKey: user.tenantId,
          data: {
            ...this.state.company,
            defaultLedgerAccountId: id
          }
        }
      });
      const { __typename, ...company } = data.updateCompany;
      Context.setCompanyContext(
        user.tenantId,
        Context.generateCompanyContextSortKey(user),
        () => console.log('refreshed'),
        true
      );
      this.setState({ company });
    } catch (error) {
      logErrorWithCallback(
        error,
        snackbarOn,
        'Unable to set default Ledger Account, please try again later.'
      );
    } finally {
      this.setState({ isLoadingDefault: false });
    }
  };

  setDefaultLedgerButton = ({ record }) => {
    const { company, isLoadingDefault } = this.state;
    return record.id === company.defaultLedgerAccountId ? (
      <ThemeProvider>
        <Typography>Default Ledger Account</Typography>
      </ThemeProvider>
    ) : (
      <ThemeProvider>
        <Button
          type={ButtonType.TERTIARY}
          loading={isLoadingDefault}
          onClick={() => this.setDefaultLedgerAccount(record.id)}
        >
          Set as Default
        </Button>
      </ThemeProvider>
    );
  };

  setDefaultBankAccount = async id => {
    const { user, snackbarOn } = this.props;
    this.setState(s => ({
      isLoadingDefault: true,
      company: { ...s.company, defaultBankAccountId: id }
    }));
    try {
      const { data } = await this.client.mutate({
        mutation: updateCompany,
        variables: {
          partitionKey: user.tenantId,
          data: {
            ...this.state.company,
            defaultBankAccountId: id
          }
        }
      });
      const { __typename, ...company } = data.updateCompany;
      Context.setCompanyContext(
        user.tenantId,
        Context.generateCompanyContextSortKey(user),
        () => console.log('refreshed'),
        true
      );
      this.setState({ company });
    } catch (error) {
      logErrorWithCallback(
        error,
        snackbarOn,
        'Unable to set default Bank Account, please try again later.'
      );
    } finally {
      this.setState({ isLoadingDefault: false });
    }
  };

  setDefaultBankButton = ({ record }) => {
    const { company, isLoadingDefault } = this.state;
    return record.id === company.defaultBankAccountId ? (
      <ThemeProvider>
        <Typography>Default Bank Account</Typography>
      </ThemeProvider>
    ) : (
      <ThemeProvider>
        <Button
          type={ButtonType.TERTIARY}
          loading={isLoadingDefault}
          onClick={() => this.setDefaultBankAccount(record.id)}
        >
          Set as Default
        </Button>
      </ThemeProvider>
    );
  };

  setDefaultPaymentTerm = async id => {
    const { user, snackbarOn } = this.props;
    this.setState(s => ({
      isLoadingDefault: true,
      company: { ...s.company, defaultPaymentTermId: id }
    }));
    try {
      const { data } = await this.client.mutate({
        mutation: updateCompany,
        variables: {
          partitionKey: user.tenantId,
          data: {
            ...this.state.company,
            defaultPaymentTermId: id
          }
        }
      });
      const { __typename, ...company } = data.updateCompany;
      Context.setCompanyContext(
        user.tenantId,
        Context.generateCompanyContextSortKey(user),
        () => console.log('refreshed'),
        true
      );
      this.setState({ company });
    } catch (error) {
      logErrorWithCallback(
        error,
        snackbarOn,
        'Unable to set default payment term, please try again later.'
      );
    } finally {
      this.setState({ isLoadingDefault: false });
    }
  };

  setDefaultPaymentTermButton = ({ record }) => {
    const { company, isLoadingDefault } = this.state;
    return record.id === company.defaultPaymentTermId ? (
      <ThemeProvider>
        <Typography>Default Terms</Typography>
      </ThemeProvider>
    ) : (
      <ThemeProvider>
        <Button
          type={ButtonType.TERTIARY}
          loading={isLoadingDefault}
          onClick={() => this.setDefaultPaymentTerm(record.id)}
        >
          Set as Default
        </Button>
      </ThemeProvider>
    );
  };

  formatPurchaseOrderTypeList = purchaseOrderTypes => {
    return purchaseOrderTypes?.map(el => ({
      departmentIds: el.departments?.map(item => item.id) || [],
      ...el
    }));
  };

  render() {
    const { user, flags } = this.props;
    const {
      accountingIntegration,
      classes,
      dimensions,
      bankAccounts,
      itemGlGroups,
      importing,
      isIntegrationEnabled,
      isQuickbooksEnabled,
      isSpectrumEnabled,
      isIntacctEnabled,
      isSageEnabled,
      ledgerAccounts,
      modal,
      adjustmentTypes,
      paymentTypes,
      paymentTerms,
      sageJobs,
      showClosedPeriod,
      syncEntriesInRange,
      taxRates,
      purchaseOrderTypes
    } = this.state;
    const accountingAppName = accountingIntegration?.toUpperCase();
    const customMetaReset = meta => ({ ...meta, type: 'text', isCustom: false });
    const quickbooksImportButton = order => {
      const { entities } = this.state;
      const entity = entities.find(x => x.index === order);
      return (
        isIntegrationEnabled && {
          label: `Import from ${accountingAppName}`,
          handleAdd: () => this.sync(entity),
          isRefreshing: importing
        }
      );
    };

    const rowButtons = ({ enableDelete } = {}) => ({
      edit: {
        label: 'Edit',
        caslAction: 'edit',
        caslKey: PermissionConstants.MENU_ACCOUNTING,
        icon: 'Edit'
      },
      ...((!isIntegrationEnabled || enableDelete) && {
        delete: {
          label: 'Delete',
          caslAction: 'delete',
          caslKey: PermissionConstants.MENU_ACCOUNTING,
          icon: 'Delete'
        }
      })
    });

    return (
      <ErrorBoundaries>
        <UserPermission I="allow" action={PermissionConstants.MENU_ACCOUNTING}>
          {!isSageEnabled && (
            <PageHeader pageMapKey="accountingSettings" userLocale={user.locale}>
              <Box display="flex">
                <ThemeProvider>
                  <Button
                    type={ButtonType.SECONDARY}
                    onClick={() => this.setState({ showClosedPeriod: true })}
                    key="new"
                  >
                    Set Closed Period
                  </Button>
                </ThemeProvider>
              </Box>
            </PageHeader>
          )}
          <ErrorBoundaries>
            {isIntegrationEnabled !== null && (
              <Tabs disableBottomPadding>
                {isIntegrationEnabled && (
                  <Tab label="GL Accounts" tabKey="gl-accounts">
                    <ResponsiveTable
                      isLoading={!ledgerAccounts}
                      disableFilter
                      fullScreen
                      rowMetadata={glAccountsRows}
                      data={ledgerAccounts || []}
                      noDataMsg="No GL accounts"
                      addRow={quickbooksImportButton(this.entitiesOrder.GL_ACCOUNT)}
                      customCellComponents={
                        isIntacctEnabled
                          ? { setDefaultLedgerButton: this.setDefaultLedgerButton }
                          : null
                      }
                    />
                  </Tab>
                )}
                {isIntacctEnabled && (
                  <Tab label="Item GL Groups" tabKey="item-gl-groups">
                    <ResponsiveTable
                      isLoading={!itemGlGroups}
                      disableFilter
                      fullScreen
                      rowMetadata={itemGLGroupMeta}
                      data={itemGlGroups || []}
                      noDataMsg="No Item GL groups"
                      addRow={quickbooksImportButton(this.entitiesOrder.ITEM_GL_GROUP)}
                    />
                  </Tab>
                )}
                {isSageEnabled && (
                  <Tab label="Sage" tabKey="sage">
                    <ResponsiveTable
                      isLoading={!sageJobs}
                      disableFilter
                      fullScreen
                      rowMetadata={sageJobRows}
                      data={sageJobs || []}
                      noDataMsg="No Sage jobs"
                      addRow={quickbooksImportButton(this.entitiesOrder.SAGE_JOB)}
                    />
                  </Tab>
                )}
                {(isQuickbooksEnabled || isIntacctEnabled || isSpectrumEnabled) && (
                  <Tab label="Classes" tabKey="qa-classes">
                    <ResponsiveTable
                      isLoading={!classes}
                      disableFilter
                      fullScreen
                      rowMetadata={qbClassesRows}
                      data={classes || []}
                      noDataMsg="No classes"
                      addRow={quickbooksImportButton(this.entitiesOrder.CLASS)}
                    />
                  </Tab>
                )}
                {isIntacctEnabled && (
                  <Tab label="Dimensions" tabKey="dimensions">
                    <ResponsiveTable
                      isLoading={!dimensions}
                      disableFilter
                      fullScreen
                      rowMetadata={dimensionMeta}
                      data={dimensions || []}
                      noDataMsg="No dimensions"
                      addRow={quickbooksImportButton(this.entitiesOrder.DIMENSION)}
                    />
                  </Tab>
                )}
                {isIntacctEnabled && (
                  <Tab label="Bank Accounts" tabKey="bankAccounts">
                    <ResponsiveTable
                      isLoading={!bankAccounts}
                      disableFilter
                      fullScreen
                      rowMetadata={bankAccountMeta}
                      data={bankAccounts || []}
                      noDataMsg="No bank accounts"
                      addRow={quickbooksImportButton(this.entitiesOrder.BANK_ACCOUNT)}
                      customCellComponents={{ setDefaultBankButton: this.setDefaultBankButton }}
                    />
                  </Tab>
                )}
                <Tab label={Labels.salesTax[this.props.user.locale]} tabKey="sales-tax">
                  <ResponsiveTable
                    isLoading={!taxRates}
                    disableFilter
                    fullScreen
                    rowMetadata={TaxRateColumns}
                    data={taxRates || []}
                    noDataMsg="No tax rates"
                    addRow={
                      isIntegrationEnabled && !isIntacctEnabled
                        ? quickbooksImportButton(this.entitiesOrder.TAX_RATE)
                        : {
                            label: 'Add Tax Rate',
                            handleAdd: () => this.handleModalOpen(DataType.TaxRate, Mode.ADD)
                          }
                    }
                    rowActionButtons={rowButtons()}
                    rowActions={(action, data) =>
                      this.handleModalOpen(DataType.TaxRate, action, data)
                    }
                  />
                </Tab>
                <Tab label={Labels.paymentType[this.props.user.locale]} tabKey="payment-type">
                  <ResponsiveTable
                    isLoading={!paymentTypes}
                    disableFilter
                    fullScreen
                    rowMetadata={PaymentTypeColumns(
                      this.state.isIntegrationEnabled && !isIntacctEnabled
                    )}
                    data={paymentTypes || []}
                    noDataMsg="No payment type"
                    addRow={
                      isIntegrationEnabled &&
                      !isSageEnabled &&
                      !isIntacctEnabled &&
                      !isSpectrumEnabled
                        ? quickbooksImportButton(this.entitiesOrder.PAYMENT_TYPE)
                        : {
                            label: 'Add Payment Type',
                            handleAdd: () => this.handleModalOpen(DataType.PaymentType, Mode.ADD)
                          }
                    }
                    rowActionButtons={rowButtons()}
                    rowActions={(action, data) =>
                      this.handleModalOpen(DataType.PaymentType, action, data)
                    }
                  />
                </Tab>
                {(isIntacctEnabled || isQuickbooksEnabled || isSpectrumEnabled) && (
                  <Tab
                    label={Labels.adjustmentTypes[this.props.user.locale]}
                    tabKey="adjustment-types"
                  >
                    <ResponsiveTable
                      isLoading={!adjustmentTypes}
                      disableFilter
                      fullScreen
                      rowMetadata={GetAdjustmentTypeColumns(accountingIntegration)}
                      data={adjustmentTypes || []}
                      noDataMsg="No adjustment types"
                      addRow={{
                        label: 'Add Adjustment Type',
                        handleAdd: () => this.handleModalOpen(DataType.AdjustmentType, Mode.ADD)
                      }}
                      rowActionButtons={rowButtons({ enableDelete: true })}
                      rowActions={(action, data) =>
                        this.handleModalOpen(DataType.AdjustmentType, action, data)
                      }
                    />
                  </Tab>
                )}
                <Tab label={Labels.paymentTerms[this.props.user.locale]} tabKey="payment-terms">
                  <ResponsiveTable
                    isLoading={!paymentTerms}
                    disableFilter
                    fullScreen
                    rowMetadata={PaymentTermColumns}
                    data={paymentTerms || []}
                    noDataMsg="No payment terms"
                    addRow={{
                      label: 'Add Payment Term',
                      handleAdd: () => this.handleModalOpen(DataType.PaymentTerm, Mode.ADD)
                    }}
                    rowActionButtons={rowButtons({ enableDelete: true })}
                    rowActions={(action, data) =>
                      this.handleModalOpen(DataType.PaymentTerm, action, data)
                    }
                    customCellComponents={{
                      setDefaultPaymentTermButton: this.setDefaultPaymentTermButton,
                      paymentTermType: ({ record }) => (
                        <ThemeProvider>
                          <Typography>
                            {record.type === PaymentTermType.BOTH
                              ? `${PaymentTermType.INVOICING} & ${PaymentTermType.PURCHASING}`
                              : record.type}
                          </Typography>
                        </ThemeProvider>
                      )
                    }}
                  />
                </Tab>
                {isIntegrationEnabled && (
                  <Tab label={Labels.syncLogHistory[this.props.user.locale]} tabKey="sync-logs">
                    <ResponsiveTable
                      disableFilter
                      fullScreen
                      rowMetadata={syncLogRows}
                      isLoading={!syncEntriesInRange}
                      data={syncEntriesInRange || []}
                      defaults={{ sortOrder: 'desc', sortBy: 'createdDateTime' }}
                      noDataMsg="No Sync Logs"
                    />
                  </Tab>
                )}
                {flags.procurement && (
                  <Tab
                    label={Labels.purchaseOrderType[this.props.user.locale]}
                    tabKey="purchase-order-type"
                  >
                    <ResponsiveTable
                      isLoading={!purchaseOrderTypes}
                      disableFilter
                      fullScreen
                      rowMetadata={PurchaseOrderTypeColumns}
                      data={this.formatPurchaseOrderTypeList(purchaseOrderTypes) || []}
                      noDataMsg="No purchase order types"
                      addRow={{
                        label: 'Add PO Type',
                        handleAdd: () => this.handleModalOpen(DataType.PurchaseOrderType, Mode.ADD)
                      }}
                      rowActionButtons={rowButtons({ enableDelete: true })}
                      rowActions={(action, data) =>
                        this.handleModalOpen(DataType.PurchaseOrderType, action, data)
                      }
                      customCellComponents={{
                        setAutoReceive: ({ meta, record, ...rest }) => {
                          const updatedRecord = {
                            ...record,
                            autoReceive: record.autoReceive ? 'Yes' : 'No'
                          };
                          return (
                            <DisplayData
                              meta={customMetaReset(meta)}
                              record={updatedRecord}
                              {...rest}
                            />
                          );
                        },
                        setDepartmentList: ({ meta, record, ...rest }) => {
                          const updatedRecord = {
                            ...record,
                            departmentNames: this.getDepartmentNamesFromMap(record.departmentIds)
                          };
                          return (
                            <DisplayData
                              meta={customMetaReset(meta)}
                              record={updatedRecord}
                              {...rest}
                            />
                          );
                        }
                      }}
                    />
                  </Tab>
                )}
              </Tabs>
            )}
          </ErrorBoundaries>
          {showClosedPeriod && (
            <ClosedPeriodModal
              open={showClosedPeriod}
              handleClose={() => this.setState({ showClosedPeriod: false })}
            />
          )}
          <SergeantModal
            {...modal} // eslint-disable-line react/jsx-props-no-spreading
            handlePrimaryAction={this.handlePrimaryAction}
            handleClose={this.handleModalClose}
            customComponents={{ PlacesSearch }}
          />
        </UserPermission>
      </ErrorBoundaries>
    );
  }
}

const styledAccounting = withStyles(styles, { withTheme: true })(Accounting);

const mapStateToProps = state => ({
  user: state.user,
  settings: state.settings,
  company: state.company
});

const mapDispatcherToProps = { snackbarOn };

const reduxConnectedAccounting = connect(
  mapStateToProps,
  mapDispatcherToProps
)(withLDConsumer()(styledAccounting));

export default reduxConnectedAccounting;
