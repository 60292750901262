export const formatAdjustmentItem = data => ({
  id: data.id,
  name: data.name,
  ledgerAccount: data.ledgerAccount,
  department: data.department,
  description: data.description,
  taxable: data.taxable,
  quantity: data.quantity,
  unitCost: data.unitCost,
  unitPrice: data.unitPrice,
  amount: data.amount,
  product: data.product,
  priceBookEntry: data.priceBookEntry,
  costCode: data.costCode,
  jobCostType: data.jobCostType,
  revenueType: data.revenueType,
  sortOrder: data.sortOrder
});

export const formatAdjustmentItemForMutation = data => ({
  // remove temporary id's
  id: data.id?.charAt(0) === '-' ? undefined : data.id,
  name: data.name,
  ledgerAccountId: data.ledgerAccount?.value,
  departmentId: data.department?.value,
  description: data.description || null,
  taxable: data.taxable,
  quantity: data.quantity,
  unitCost: data.unitCost,
  unitPrice: data.unitPrice,
  markupValue: data.markupValue,
  amount: data.amount,
  productId: data.product?.id,
  priceBookEntryId: data.priceBookEntry?.id,
  costCodeId: data.costCode?.value || null,
  revenueTypeId: data.revenueType?.value || null,
  jobCostTypeId: data.jobCostType?.value || null,
  sortOrder: data.sortOrder
});
