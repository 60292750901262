import React from 'react';
import { string } from 'prop-types';
import ImageThumbnail from 'components/ImageThumbnail';

const ThumbnailCell = ({ formattedValue }) => (
  <ImageThumbnail image={{ fileUrl: formattedValue }} />
);

ThumbnailCell.propTypes = {
  formattedValue: string.isRequired
};

export default ThumbnailCell;
