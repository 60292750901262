import InstructionHelper from '../InstructionHelper';
import { removeNestedJson } from '../../utils';

const roleSchema = (roleName, permissionsString, company) => {
  const newRoleRecord = {
    tagName: roleName.trim() || '',
    tagType: 'role',
    appPermissions: permissionsString,
    tenantId: company.tenantId,
    tenantCompanyId: company.id,
    partitionKey: company.tenantId,
    entityType: 'AppRole'
  };

  const data = [{ ...removeNestedJson(company) }, { ...newRoleRecord }];
  const ins = new InstructionHelper();
  const { instructionSet } = ins.oneToMany(0, 1);
  return { data: data, instructionSet: instructionSet };
};

export default roleSchema;
