import { makeStyles } from '@material-ui/core';

import { ElementSizes, ElementZIndex } from '@dispatch/Dispatch.styles';

const boxWidth = ElementSizes.tickerWidth;
const boxHeight = ElementSizes.tickerHeight;

export const useStyles = makeStyles(({ palette }) => ({
  box: {
    backgroundColor: palette.support.red.dark,
    borderRadius: 4,
    color: palette.background.default,
    position: 'absolute',
    width: boxWidth,
    height: boxHeight,
    textAlign: 'center',
    bottom: 0,
    zIndex: ElementZIndex.timeOverlay
  },
  line: {
    borderLeft: `2px dashed ${palette.support.red.dark}`,
    position: 'absolute',
    top: ElementSizes.stickyHeaderHeight,
    zIndex: ElementZIndex.timeLine
  }
}));
