export const START_GET_JOB = 'START_GET_JOB';
export const START_GET_JOB_SUCCESS = 'START_GET_JOB_SUCCESS';
export const START_GET_JOB_FAILURE = 'START_GET_JOB_FAILURE';
export const START_JOB_UPDATE = 'START_JOB_UPDATE';
export const START_JOB_UPDATE_SUCCESS = 'START_JOB_UPDATE_SUCCESS';
export const START_JOB_UPDATE_FAILURE = 'START_JOB_UPDATE_FAILURE';

export const startGetJob = () => ({
  type: START_GET_JOB
});

export const startGetJobSuccess = currentJob => ({
  type: START_GET_JOB_SUCCESS,
  payload: currentJob
});

export const startGetJobFailure = error => ({
  type: START_GET_JOB_FAILURE,
  payload: error
});

export const startJobUpdate = () => ({
  type: START_JOB_UPDATE
});

export const startJobUpdateSuccess = updatedJobDetails => ({
  type: START_JOB_UPDATE_SUCCESS,
  payload: updatedJobDetails
});

export const startJobUpdateFailure = error => ({
  type: START_JOB_UPDATE_FAILURE,
  payload: error
});
