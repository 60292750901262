import { withQueryStringStore } from '@dispatch/Dispatch.store';

import DaySelector from './DaySelector.component';

const mapQueryStringToProps = store => ({
  selectDay: store.selectDay,
  day: store.state.day,
  weekView: Boolean(store.state.weekView)
});

export default withQueryStringStore(mapQueryStringToProps)(DaySelector);
