import { gql } from '@apollo/client/core';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { ReviewReportStatus } from 'utils/AppConstants';

import { GET_REVIEW_REPORT_BY_ID } from '../queries';

const CREATE_INVOICE_FROM_VISITS = gql`
  mutation createInvoiceFromVisits($partitionKey: String!, $data: CreateInvoiceFromVisitsInput!) {
    createInvoiceFromVisits(partitionKey: $partitionKey, data: $data) {
      id
      invoiceNumber
      sortKey
      entityType
    }
  }
`;

export const useCreateInvoiceFromVisits = (options = {}) => {
  return useExtendedMutation(CREATE_INVOICE_FROM_VISITS, {
    update: cache => {
      const cachedData = cache.readQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: options.reviewReportId
        }
      });

      cache.writeQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: options.reviewReportId
        },
        data: {
          getReviewReportById: {
            ...(cachedData?.getReviewReportById || {}),
            status: ReviewReportStatus.INVOICED
          }
        }
      });
    },
    ...options
  });
};
