import { Machine, assign } from 'xstate';
import _ from 'lodash';
import { ReviewReportStatus } from 'utils/AppConstants';
import { roundCurrency } from 'utils';
import actions, { guards } from './page-actions';

export const getTrucksFromEmployeeList = techArray => {
  const availableTrucks = [];
  techArray.forEach(tech => {
    const truckEmployeesArray = tech?.mappedEntity?.truckEmployeeView?.items || [];
    truckEmployeesArray.forEach(truckEmp => {
      // when the is active, add to the list. Truck delete is a soft delete
      if (truckEmp?.parentEntity?.isActive) {
        availableTrucks.push(truckEmp.parentEntity);
      }
    });
  });
  return availableTrucks;
};
const changeAttachmentMetaData = attachmentData => {
  const filteredAttchmentData = (attachmentData?.items || []).filter(
    item => item.type && ['before', 'after'].includes(item.type.toLowerCase())
  );
  filteredAttchmentData.forEach(item => {
    item.createdBy = item.addedBy || item.createdBy;
    item.createdDate = parseInt(item.addedDateTime, 10) / 1000 || item.createdDate;
  });
  return filteredAttchmentData;
};
const changeTechNotesMetaData = technicianNotes => {
  const techNotes = technicianNotes?.items || [];
  techNotes.forEach(item => {
    item.createdBy = item.addedBy || item.createdBy;
  });
  return techNotes;
};
const changeCustomerSignatureMetaData = signatureData => {
  const customerSignatures = signatureData?.items || [];
  customerSignatures.forEach(item => {
    item.createdDate = parseInt(item.signedDateTime, 10) / 1000 || item.createdDate;
  });
  return customerSignatures;
};
const pageStateMachine = Machine(
  {
    context: {
      canView: true,
      pageStatus: ''
    },
    states: {
      idle: {
        id: 'ef58c1a5',
        on: {
          LOADING: {
            target: 'loading'
          }
        }
      },
      access_denied: {
        type: 'final'
      },
      page_error: {
        type: 'final'
      },
      loading: {
        id: 'page_state_loading',
        invoke: {
          id: 'fetchReviewReportDetails',
          src: actions.fn_fetchReviewReportDetails,
          onDone: {
            target: 'loaded',
            actions: [
              assign((context, event) => {
                let purchaseOrders;
                let inventoryParts;
                let summaries;
                let laborLineItems;
                let attachments;
                let technicianNotes;
                let discounts;
                let fees;
                let customerSignatures;
                let createdDateTime;
                let version;
                let parentEntity;
                let reviewReport;
                let id;
                let status;
                let availableTrucks = [];
                let priceBookId = '';
                let payrollSetting;
                let isAutomatedTimeTrackingEnabled;
                let payrollHourTypes;

                const { defaultPriceBookId } = context;
                priceBookId = defaultPriceBookId;
                if (event.data) {
                  let result = {};
                  event.data.forEach(item => {
                    const { reportId } = context;
                    if (reportId) {
                      result = { ...result, ...item.data.getReviewReportById };
                    } else {
                      result = { ...result, ...item.data.getReviewReport };
                    }

                    // if default pricebook not found then use first pricebook id
                    if (!priceBookId && item?.data?.getCompany?.priceBooks?.items?.length) {
                      priceBookId = item.data.getCompany.priceBooks.items[0].id;
                    }

                    if (item?.data?.getCompany?.companySettings?.items) {
                      // extracting settings obj
                      const payrollSettingStr =
                        item.data.getCompany?.companySettings.items?.[0]?.settings || '{}';
                      payrollSetting = JSON.parse(payrollSettingStr);
                      isAutomatedTimeTrackingEnabled =
                        payrollSetting?.enableAutomatedTimeTracking || false;
                    }

                    payrollHourTypes = item?.data.getCompany?.payrollHourTypes?.items;

                    ({
                      purchaseOrders,
                      inventoryParts,
                      summaries,
                      laborLineItems,
                      attachments,
                      discounts,
                      fees,
                      technicianNotes,
                      version,
                      id,
                      status,
                      customerSignatures,
                      createdDateTime,
                      parentEntity,
                      ...reviewReport
                    } = result);
                  });
                }

                // Unit of measure is defined at the product level. It should always referred from the product
                const processedInventoryItems = (inventoryParts?.items || []).map(item => {
                  const localUnitOfMeasure = item?.priceBookEntry?.product?.unitOfMeasure?.name;
                  return {
                    ...item,
                    // existing logic from PartsAndMaterials moved to here
                    addedBy: item.addedBy || item.createdBy,
                    amount: roundCurrency(item.quantity * item.unitPrice),
                    unitOfMeasure: localUnitOfMeasure
                  };
                });

                // Get trucks for the emplyoees from primary tech and secondary tech

                const submittedBy = parentEntity?.submittedBy;
                const submittedDate = parentEntity?.submittedDate;
                const primaryTechs = parentEntity?.primaryTechs?.items;
                const techReportLastUpdated = parentEntity?.lastUpdatedDateTime;
                const nonVisitEvents = parentEntity?.nonVisitEvents;

                const primaryTechsTrucks = getTrucksFromEmployeeList(
                  parentEntity?.primaryTechs?.items || []
                );
                const extraTechsTrucks = getTrucksFromEmployeeList(
                  parentEntity?.extraTechs?.items || []
                );

                availableTrucks = availableTrucks.concat(primaryTechsTrucks, extraTechsTrucks);
                const uniqAvailableTrucks = _.uniqBy(availableTrucks, 'id');

                return {
                  purchaseOrders: purchaseOrders?.items || [],
                  inventoryParts: processedInventoryItems || [],
                  summaryNotes: summaries?.items,
                  laborLineItems: laborLineItems?.items,
                  attachments: changeAttachmentMetaData(attachments),
                  visitAssets: parentEntity?.visitAssets?.items,
                  technicianNotes: changeTechNotesMetaData(technicianNotes),
                  customerSignatures: changeCustomerSignatureMetaData(customerSignatures),
                  discounts: discounts?.items,
                  fees: fees?.items,
                  visitCreatedDate: parentEntity?.createdDateTime || createdDateTime,
                  version,
                  primaryTechs,
                  submittedBy,
                  submittedDate,
                  techReportLastUpdated,
                  visit: parentEntity,
                  jobInfoData: parentEntity && {
                    ...parentEntity.parentEntity,
                    customJobNumber:
                      parentEntity.parentEntity.customIdentifier ||
                      parentEntity.parentEntity.jobNumber
                  },
                  availableTrucks: uniqAvailableTrucks,
                  companyAddresses: parentEntity?.parentEntity?.parentEntity?.companyAddresses,
                  timeSheets: parentEntity?.timeSheets?.items || [],
                  timeCardLines: parentEntity?.timeCardLinesView?.items || [],
                  timeCardVisits: parentEntity?.timeCardVisitsView?.items || [],
                  reviewReport,
                  id,
                  status,
                  createdDateTime,
                  freezeEdit: [
                    ReviewReportStatus.SUBMITTED,
                    ReviewReportStatus.INVOICED,
                    ReviewReportStatus.DISMISSED
                  ].includes(status?.toLowerCase()),
                  productFilter: {
                    entityType: ['Product']
                  },
                  reviewReportPriceBookId:
                    parentEntity?.parentEntity?.priceBookId || `${priceBookId}`,
                  payrollSetting,
                  isAutomatedTimeTrackingEnabled,
                  timesheetEntries: parentEntity?.timesheetEntries?.items || [],
                  payrollHourTypes,
                  visitTimesheetNotes: (parentEntity?.timesheetNotes?.items || []).map(note => ({
                    ...note,
                    eventName: 'Visit'
                  })),
                  showContent: true,
                  nonVisitEvents: nonVisitEvents?.items.filter(i => i.isActive) || []
                };
              })
            ]
          },
          onError: {
            target: 'page_error',
            actions: [actions.fn_queryErrorNotification]
          }
        }
      },
      loaded: {
        id: '7860429e',
        on: {
          ACCESS_DENIED: 'access_denied',
          DELETE_JOB_SUMMARY: {
            target: 'delete_summary_confirmation',
            actions: assign({
              modalRecord: (context, event) => event.data
            })
          },
          ADD_JOB_SUMMARY: 'job_summary_new',
          EDIT_JOB_SUMMARY: {
            target: 'job_summary_edited',
            actions: assign({
              modalRecord: (context, event) => event.data
            })
          },
          INCLUDE_SUMMARY_INVOICE: {
            target: 'job_summary_included',
            actions: assign({
              modalRecord: (context, event) => event.data,

              modalChecked: (context, event) => event.checked
            })
          },
          VIEW_TECH_NOTES: {
            target: 'view_tech_notes',
            actions: assign({
              modalRecord: (context, event) => event.record
            })
          },
          ADD_ATTACHMENT: 'attachment_new',
          EDIT_ATTACHMENT: {
            target: 'attachment_edited',
            actions: assign({
              modalRecord: (context, event) => event.data
            })
          },
          DELETE_ATTACHMENT: {
            target: 'delete_attachment_confirmation',
            actions: assign({
              modalRecord: (context, event) => event.data
            })
          },
          INCLUDE_ATTACHMENT_INVOICE: {
            target: 'attachment_included',
            actions: assign({
              modalRecord: (context, event) => event.data,

              modalChecked: (context, event) => event.checked
            })
          },
          INCLUDE_CUSTOMER_SIGNATURE: {
            target: 'customer_signature_included',
            actions: assign({
              modalRecord: (context, event) => event.data,

              modalChecked: (context, event) => event.checked
            })
          },
          EDIT_INVENTORY: {
            target: 'inventory_edited',
            actions: assign({
              modalRecord: (context, event) => ({
                ...event.data
              })
            })
          },
          DELETE_INVENTORY: {
            target: 'delete_inventory_confirmation',
            actions: assign({
              modalRecord: (context, event) => event.data
            })
          },
          INCLUDE_INVENTORY_INVOICE: 'inventory_included',
          EDIT_PO_ITEM: {
            target: 'po_item_edited',
            actions: assign({
              modalRecord: (context, event) => event.data
            })
          },
          DELETE_PO_ITEM: {
            target: 'delete_po_confirmation',
            actions: assign({
              modalRecord: (context, event) => event.data
            })
          },
          EDIT_LABOR_LINE: {
            target: 'labor_line_edited',
            actions: assign({
              modalRecord: (context, event) => ({
                ...event.data,
                isPriceBookEnabled: context.isPriceBookEnabled,
                productFilter: context.productFilter,
                reviewReportPricebookId: context.reviewReportPricebookId
              })
            })
          },
          DELETE_LABOR_LINE: {
            target: 'delete_labor_line_confirmation',
            actions: assign({
              modalRecord: (context, event) => event.data
            })
          },
          INCLUDE_LABOR_LINE_INVOICE: 'labour_line_included',
          EDIT_DISCOUNT: {
            target: 'discount_edited',
            actions: assign({
              modalRecord: (context, event) => ({
                ...event.data,
                isPriceBookEnabled: context.isPriceBookEnabled,
                productFilter: context.productFilter,
                reviewReportPricebookId: context.reviewReportPricebookId
              })
            })
          },
          EDIT_FEE: {
            target: 'fee_edited',
            actions: assign({
              modalRecord: (context, event) => ({
                ...event.data,
                isPriceBookEnabled: context.isPriceBookEnabled,
                productFilter: context.productFilter,
                reviewReportPricebookId: context.reviewReportPricebookId
              })
            })
          },
          UPDATE_REPORT_VERSION: {
            actions: assign({
              version: (context, event) => event.version
            })
          },
          DELETE_DISCOUNT: {
            target: 'delete_discount_confirmation',
            actions: assign({
              modalRecord: (context, event) => event
            })
          },
          DELETE_FEE: {
            target: 'delete_fee_confirmation',
            actions: assign({
              modalRecord: (context, event) => event
            })
          },
          INCLUDE_DISCOUNT_INVOICE: 'discount_included',
          INCLUDE_FEE_INVOICE: 'fee_included',
          ADD_INVENTORY: {
            target: 'inventory_new',
            actions: assign({
              modalRecord: context => ({
                departmentName: context.visit.departmentName || '',
                accountingRefIdOfClass: context.visit.accountingRefIdOfClass || ''
              })
            })
          },
          ADD_BILL: {
            target: 'bill_new',
            actions: assign({
              modalRecord: context => ({
                departmentName: context.visit.departmentName || '',
                accountingRefIdOfClass: context.visit.accountingRefIdOfClass || ''
              })
            })
          },
          EDIT_BILL_LINE_ITEM: {
            target: 'bill_line_edited',
            actions: assign({
              modalRecord: (context, event) => ({
                ...event.data,
                isPriceBookEnabled: context.isPriceBookEnabled,
                productFilter: context.productFilter,
                reviewReportPricebookId: context.reviewReportPricebookId
              }),

              parentOrder: (context, event) => event.parent
            })
          },
          DELETE_BILL_LINE_ITEM: {
            target: 'bill_line_delete',
            actions: assign({
              modalRecord: (context, event) => event.data,
              parentOrder: (context, event) => event.parent
            })
          },
          ADD_PO_ITEM: {
            target: 'po_item_new',
            actions: assign({
              modalRecord: context => ({
                departmentName: context.visit.departmentName || '',
                accountingRefIdOfClass: context.visit.accountingRefIdOfClass || ''
              })
            })
          },
          ADD_PO_ITEM_PROCUREMENT: {
            target: 'po_item_new_procurement',
            actions: assign({
              modalRecord: context => {
                return {
                  department: {
                    tagName: context.visit.departmentName || '',
                    id: context.visit.departmentId
                  },
                  jobAndProject: {
                    id: context.jobInfoData.id,
                    jobNumber:
                      context.jobInfoData.customIdentifier || context.jobInfoData.jobNumber || '',
                    customerPropertyId: context.jobInfoData.parentEntity.id
                  },
                  assignedTo: null
                };
              }
            })
          },
          INCLUDE_PO_INVOICE: {
            target: 'po_included',
            actions: assign({
              modalRecord: (context, event) => event.data,
              modalChecked: (context, event) => event.checked
            })
          },
          ADD_PURCHASE_ORDER_LINE_ITEM: {
            target: 'purchase_order_line_item_new',
            actions: assign({
              modalRecord: (context, event) => ({
                ...event.data,
                productFilter: context.productFilter,
                reviewReportPricebookId: context.reviewReportPricebookId,
                accountingRefIdOfClass: context.visit.accountingRefIdOfClass || '',
                isPriceBookEnabled: context.isPriceBookEnabled
              })
            })
          },
          EDIT_PURCHASE_ORDER_LINE_ITEM: {
            target: 'purchase_order_line_edited',
            actions: assign({
              modalRecord: (context, event) => {
                const localRecord = event.data;
                if (
                  event.parent &&
                  event.parent.purchaseOrderReceipts &&
                  event.parent.purchaseOrderReceipts.items
                ) {
                  const receipt = event.parent.purchaseOrderReceipts.items;
                  localRecord.image = receipt;
                }
                localRecord.isPriceBookEnabled = context.isPriceBookEnabled;
                localRecord.productFilter = context.productFilter;
                return localRecord;
              },

              parentOrder: (context, event) => event.parent
            })
          },
          DELETE_PURCHASE_ORDER_LINE_ITEM: {
            target: 'purchaseLine_deleted',
            actions: assign({
              modalRecord: (context, event) => event.data,

              parentOrder: (context, event) => event.parent
            })
          },
          INCLUDE_PURCHASE_ORDER_LINE_INVOICE: {
            target: 'purchaseorderline_included',
            actions: assign({
              modalRecord: (context, event) => event.data,

              modalChecked: (context, event) => event.checked,
              parentOrder: (context, event) => event.parent
            })
          },
          ADD_LABOR_LINE: {
            target: 'labor_line_new',
            actions: assign({
              modalRecord: context => ({
                isPriceBookEnabled: context.isPriceBookEnabled,
                productFilter: context.productFilter,
                reviewReportPricebookId: context.reviewReportPricebookId
              })
            })
          },
          ADD_DISCOUNT: {
            target: 'discount_new',
            actions: assign({
              modalRecord: context => ({
                isPriceBookEnabled: context.isPriceBookEnabled,
                departmentName: context.visit.departmentName || '',
                accountingRefIdOfClass: context.visit.accountingRefIdOfClass || '',
                productFilter: context.productFilter,
                reviewReportPricebookId: context.reviewReportPricebookId
              })
            })
          },
          ADD_FEE: {
            target: 'fee_new',
            actions: assign({
              modalRecord: context => ({
                isPriceBookEnabled: context.isPriceBookEnabled,
                departmentName: context.visit.departmentName || '',
                accountingRefIdOfClass: context.visit.accountingRefIdOfClass || '',
                productFilter: context.productFilter,
                reviewReportPricebookId: context.reviewReportPricebookId
              })
            })
          },
          ADD_TO_INVOICE: {
            target: 'loaded',
            actions: assign({
              modalRecord: (context, event) => event.data
            })
          },
          SAVE_REPORT: {
            target: 'update_review_report',
            actions: assign({
              buttonClicked: 'save'
            })
          },
          EDIT_REPORT: {
            target: 'update_review_report',
            actions: assign({
              buttonClicked: 'edit'
            })
          },
          SUBMIT_REPORT: {
            target: 'update_review_report',
            actions: assign({
              buttonClicked: 'submit'
            })
          },
          DISMISS_REPORT: {
            target: 'update_review_report',
            actions: assign({
              buttonClicked: 'dismiss'
            })
          },
          RELOAD: {
            target: 'loading',
            actions: [
              assign((context, event) => ({
                reportId: event.reportId,
                reportIndex: event.reportIndex,
                prevReportId: event.prevReportId,
                nextReportId: event.nextReportId
              }))
            ]
          }
        }
      },
      view_tech_notes: {
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'view',
        states: {
          view: {
            on: {
              CLOSE: {
                target: 'closed',
                actions: assign({
                  modalRecord: ''
                })
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      delete_summary_confirmation: {
        id: 'delete_summary_confirmation',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'delete',
        states: {
          delete: {
            on: {
              CONFIRM: {
                target: 'confirmed'
              },
              CANCEL: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          confirmed: {
            invoke: {
              id: 'deleteInventoryPart',
              src: actions.fn_deleteReviewSummary,
              onDone: {
                target: 'closed',
                actions: [
                  assign({
                    modalRecord: {}
                  }),
                  actions.fn_deleteSuccessMessage
                ]
              },
              onError: {
                target: 'closed',
                actions: [actions.fn_deleteErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },

      job_summary_new: {
        id: 'newJobSummary',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'edited',
        states: {
          edited: {
            on: {
              SAVE: {
                target: 'saving'
              },
              CLOSE: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          saving: {
            invoke: {
              id: 'saveReviewSummary',
              src: actions.fn_saveReviewSummary,
              onDone: {
                target: 'closed',
                actions: [
                  assign({
                    modalRecord: {}
                  }),
                  actions.fn_addSuccessMessage
                ]
              },
              onError: {
                target: 'closed',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },

      job_summary_edited: {
        id: 'editJobSummary',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'edited',
        states: {
          edited: {
            on: {
              SAVE: {
                target: 'saving'
              },
              CLOSE: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          saving: {
            invoke: {
              id: 'saveReviewSummary',
              src: actions.fn_editReviewSummary,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_editSuccessMessage
                ]
              },
              onError: {
                target: 'edited',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },

      purchase_order_line_edited: {
        id: 'purchase_order_line_edited',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'edited',
        states: {
          edited: {
            on: {
              SAVE: {
                target: 'saving'
              },
              CLOSE: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          saving: {
            invoke: {
              id: 'fn_editPurchaseOrderLine',
              src: actions.fn_editPurchaseOrderLine,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_editSuccessMessage
                ]
              },
              onError: {
                target: 'edited',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },

      job_summary_included: {
        id: 'job_summary_included',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'saving',
        states: {
          saving: {
            invoke: {
              id: 'includeReviewSummary',
              src: actions.fn_includeReviewSummary,
              onDone: {
                target: 'closed',
                actions: [assign((context, event) => ({ ...context, ...event.data }))]
              },
              onError: {
                target: 'closed',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },

      attachment_new: {
        id: 'fe54d7b8',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'edited',
        states: {
          edited: {
            on: {
              SAVE: {
                target: 'saving'
              },
              CLOSE: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          saving: {
            invoke: {
              id: 'saveAttachment',
              src: actions.fn_saveAttachment,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_addSuccessMessage
                ]
              },
              onError: {
                target: 'edited',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },

      attachment_edited: {
        id: 'attachment_edited',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'edited',
        states: {
          edited: {
            on: {
              SAVE: {
                target: 'saving'
              },
              CLOSE: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          saving: {
            invoke: {
              id: 'editAttachment',
              src: actions.fn_editAttachment,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_editSuccessMessage
                ]
              },
              onError: {
                target: 'edited',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },

      delete_attachment_confirmation: {
        id: 'delete_attachment_confirmation',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'delete',
        states: {
          delete: {
            on: {
              CONFIRM: {
                target: 'confirmed'
              },
              CANCEL: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          confirmed: {
            invoke: {
              id: 'deleteAttachment',
              src: actions.fn_deleteAttachment,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_deleteSuccessMessage
                ]
              },
              onError: {
                target: 'closed',
                actions: [actions.fn_deleteErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },

      delete_inventory_confirmation: {
        id: 'delete_inventory_confirmation',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'delete',
        states: {
          delete: {
            on: {
              CONFIRM: {
                target: 'confirmed'
              },
              CANCEL: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          confirmed: {
            invoke: {
              id: 'deleteInventoryPart',
              src: actions.fn_deleteInventoryPart,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_deleteSuccessMessage
                ]
              },
              onError: {
                target: 'closed',
                actions: [actions.fn_deleteErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      inventory_edited: {
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'edited',
        states: {
          edited: {
            on: {
              SAVE: {
                target: 'saving'
              },
              CLOSE: 'closed'
            }
          },
          saving: {
            invoke: {
              id: 'saveToDatabase',
              src: actions.fn_editInventory,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_editSuccessMessage
                ]
              },
              onError: {
                target: 'closed',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      po_item_edited: {
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'edited',
        states: {
          edited: {
            on: {
              SAVE: {
                target: 'saving'
              },
              CLOSE: 'closed'
            }
          },
          saving: {
            invoke: {
              id: 'editPurchaseOrder',
              src: actions.fn_editPurchaseOrder,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_editSuccessMessage
                ]
              },
              onError: {
                target: 'closed',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      delete_labor_line_confirmation: {
        id: 'delete_labor_line_confirmation',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'delete',
        states: {
          delete: {
            on: {
              CONFIRM: {
                target: 'confirmed'
              },
              CANCEL: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          confirmed: {
            invoke: {
              id: 'deleteLaborLine',
              src: actions.fn_deleteLaborLine,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_deleteSuccessMessage
                ]
              },
              onError: {
                target: 'closed',
                actions: [actions.fn_deleteErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      delete_po_confirmation: {
        id: 'delete_po_confirmation',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'delete',
        states: {
          delete: {
            on: {
              CONFIRM: {
                target: 'confirmed'
              },
              CANCEL: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          confirmed: {
            invoke: {
              id: 'deletePurchaseOrder',
              src: actions.fn_deletePurchaseOrder,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_deleteSuccessMessage
                ]
              },
              onError: {
                target: 'closed',
                actions: [actions.fn_deleteErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },

      labor_line_edited: {
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'edited',
        states: {
          edited: {
            on: {
              SAVE: {
                target: 'saving'
              },
              CLOSE: 'closed'
            }
          },
          saving: {
            invoke: {
              id: 'fn_editLaborLine',
              src: actions.fn_editLaborLine,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_editSuccessMessage
                ]
              },
              onError: {
                target: 'edited',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      inventory_new: {
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'edited',
        states: {
          edited: {
            on: {
              SAVE: {
                target: 'saving'
              },
              CLOSE: 'closed'
            }
          },
          saving: {
            invoke: {
              id: 'saveToDatabase',
              src: actions.fn_saveInventory,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_addSuccessMessage
                ]
              },
              onError: {
                target: 'edited',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      bill_new: {
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'edited',
        states: {
          edited: {
            on: {
              SAVE: {
                target: 'saving'
              },
              CLOSE: 'closed'
            }
          },
          saving: {
            invoke: {
              id: 'addBill',
              src: actions.fn_addBill,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_addSuccessMessage
                ]
              },
              onError: {
                target: 'edited',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },

      bill_line_edited: {
        id: 'bill_line_edited',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'edited',
        states: {
          edited: {
            on: {
              SAVE: {
                target: 'saving'
              },
              CLOSE: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          saving: {
            invoke: {
              id: 'fn_editBillLineItem',
              src: actions.fn_editBillLineItem,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_editSuccessMessage
                ]
              },
              onError: {
                target: 'closed',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },

      bill_line_delete: {
        id: 'bill_line_delete',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'delete',
        states: {
          delete: {
            on: {
              CONFIRM: {
                target: 'confirmed'
              },
              CANCEL: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          confirmed: {
            invoke: {
              id: 'deleteBillLine',
              src: actions.fn_deleteBillLine,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_deleteSuccessMessage
                ]
              },
              onError: {
                target: 'closed',
                actions: [actions.fn_deleteErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      po_item_new: {
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'edited',
        states: {
          edited: {
            on: {
              SAVE: {
                target: 'saving'
              },
              CLOSE: 'closed'
            }
          },
          saving: {
            invoke: {
              id: 'addPurchaseOrder',
              src: actions.fn_addPurchaseOrder,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_addSuccessMessage
                ]
              },
              onError: {
                target: 'edited',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      po_item_new_procurement: {
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'edited',
        states: {
          edited: {
            on: {
              CLOSE: {
                target: 'closed',
                actions: assign({
                  modalRecord: {
                    department: null,
                    jobAndProject: null
                  }
                })
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      purchase_order_line_item_new: {
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'edited',
        states: {
          edited: {
            on: {
              SAVE: {
                target: 'saving'
              },
              CLOSE: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          saving: {
            invoke: {
              id: 'addPurchaseOrderLineItem',
              src: actions.fn_addPurchaseOrderLineItem,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_addSuccessMessage
                ]
              },
              onError: {
                target: 'edited',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      labor_line_new: {
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'edited',
        states: {
          edited: {
            on: {
              SAVE: {
                target: 'saving'
              },
              CLOSE: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          saving: {
            invoke: {
              id: 'fn_saveLaborLine',
              src: actions.fn_saveLaborLine,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_addSuccessMessage
                ]
              },
              onError: {
                target: 'edited',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      discount_new: {
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'edited',
        states: {
          edited: {
            on: {
              SAVE: {
                target: 'saving'
              },
              CLOSE: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          saving: {
            invoke: {
              id: 'fn_saveDiscount',
              src: actions.fn_saveDiscount,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_addSuccessMessage
                ]
              },
              onError: {
                target: 'edited',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      fee_new: {
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'edited',
        states: {
          edited: {
            on: {
              SAVE: {
                target: 'saving'
              },
              CLOSE: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          saving: {
            invoke: {
              id: 'fn_saveFee',
              src: actions.fn_saveFee,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_addSuccessMessage
                ]
              },
              onError: {
                target: 'edited',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      discount_edited: {
        id: 'editDiscount',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'edited',
        states: {
          edited: {
            on: {
              SAVE: {
                target: 'saving'
              },
              CLOSE: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          saving: {
            invoke: {
              id: 'saveDiscount',
              src: actions.fn_editDiscount,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_editSuccessMessage
                ]
              },
              onError: {
                target: 'edited',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      fee_edited: {
        id: 'editFee',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'edited',
        states: {
          edited: {
            on: {
              SAVE: {
                target: 'saving'
              },
              CLOSE: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          saving: {
            invoke: {
              id: 'saveFee',
              src: actions.fn_editFee,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_editSuccessMessage
                ]
              },
              onError: {
                target: 'edited',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      delete_discount_confirmation: {
        id: 'delete_discount_confirmation',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'delete',
        states: {
          delete: {
            on: {
              CONFIRM: {
                target: 'confirmed'
              },
              CANCEL: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          confirmed: {
            invoke: {
              id: 'deleteDiscount',
              src: actions.fn_deleteDiscount,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_deleteSuccessMessage
                ]
              },
              onError: {
                target: 'delete',
                actions: [actions.fn_deleteErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      delete_fee_confirmation: {
        id: 'delete_fee_confirmation',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'delete',
        states: {
          delete: {
            on: {
              CONFIRM: {
                target: 'confirmed'
              },
              CANCEL: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          confirmed: {
            invoke: {
              id: 'deleteFee',
              src: actions.fn_deleteFee,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_deleteSuccessMessage
                ]
              },
              onError: {
                target: 'delete',
                actions: [actions.fn_deleteErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      attachment_included: {
        id: 'attachment_included',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'saving',
        states: {
          saving: {
            invoke: {
              id: 'fn_includeAttachment',
              src: actions.fn_includeAttachment,
              onDone: {
                target: 'closed',
                actions: [assign((context, event) => ({ ...context, ...event.data }))]
              },
              onError: {
                target: 'saving',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      customer_signature_included: {
        id: 'customer_signature_included',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'saving',
        states: {
          saving: {
            invoke: {
              id: 'customer_signature_included',
              src: actions.fn_includeCustomerSignature,
              onDone: {
                target: 'closed',
                actions: [assign((context, event) => ({ ...context, ...event.data }))]
              },
              onError: {
                target: 'saving',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },

      inventory_included: {
        id: 'inventory_included',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'saving',
        states: {
          saving: {
            invoke: {
              id: 'includeInventory',
              src: actions.fn_includeInventory,
              onDone: {
                target: 'closed',
                actions: [assign((context, event) => ({ ...context, ...event.data }))]
              },
              onError: {
                target: 'saving',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },

      labour_line_included: {
        id: 'includeLaborLine',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'saving',
        states: {
          saving: {
            invoke: {
              id: 'includeLaborLine',
              src: actions.fn_includeLaborLine,
              onDone: {
                target: 'closed',
                actions: [assign((context, event) => ({ ...context, ...event.data }))]
              },
              onError: {
                target: 'saving',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      fee_included: {
        id: 'fee_included',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'saving',
        states: {
          saving: {
            invoke: {
              id: 'includeFee',
              src: actions.fn_includeFee,
              onDone: {
                target: 'closed',
                actions: [assign((context, event) => ({ ...context, ...event.data }))]
              },
              onError: {
                target: 'saving',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      discount_included: {
        id: 'discount_included',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'saving',
        states: {
          saving: {
            invoke: {
              id: 'includeDiscount',
              src: actions.fn_includeDiscount,
              onDone: {
                target: 'closed',
                actions: [assign((context, event) => ({ ...context, ...event.data }))]
              },
              onError: {
                target: 'saving',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      po_included: {
        id: 'po_included',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'saving',
        states: {
          saving: {
            invoke: {
              id: 'includePurchaseOrder',
              src: actions.fn_includePurchaseOrder,
              onDone: {
                target: 'closed',
                actions: [assign((context, event) => ({ ...context, ...event.data }))]
              },
              onError: {
                target: 'closed',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      purchaseorderline_included: {
        id: 'purchaseorderline_included',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'saving',
        states: {
          saving: {
            invoke: {
              id: 'includePurchaseOrderLine',
              src: actions.fn_includePurchaseOrderLine,
              onDone: {
                target: 'closed',
                actions: [assign((context, event) => ({ ...context, ...event.data }))]
              },
              onError: {
                target: 'closed',
                actions: [actions.fn_saveErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      purchaseLine_deleted: {
        id: 'purchaseLine_deleted',
        on: {
          BACK_TO_LOADED: 'loaded'
        },
        initial: 'delete',
        states: {
          delete: {
            on: {
              CONFIRM: {
                target: 'confirmed'
              },
              CANCEL: {
                target: 'closed',
                actions: assign({
                  modalRecord: {}
                })
              }
            }
          },
          confirmed: {
            invoke: {
              id: 'deletePurchaseOrderLine',
              src: actions.fn_deletePurchaseOrderLine,
              onDone: {
                target: 'closed',
                actions: [
                  assign((context, event) => ({ ...context, ...event.data })),
                  actions.fn_deleteSuccessMessage
                ]
              },
              onError: {
                target: 'closed',
                actions: [actions.fn_deleteErrorNotification]
              }
            }
          },
          closed: { entry: 'fn_backToLoaded' }
        }
      },
      update_review_report: {
        target: 'loaded',
        invoke: {
          id: 'fn_updateReviewReport',
          src: actions.fn_updateReviewReport,
          onDone: {
            target: 'loaded',
            actions: [assign((context, event) => ({ ...context, ...event.data }))]
          },
          onError: {
            target: 'loaded',
            actions: [actions.fn_saveErrorNotification]
          }
        }
      }
    },
    initial: 'idle'
  },
  { actions, guards }
);

export default pageStateMachine;
