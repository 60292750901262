import gql from 'graphql-tag';

const getAllAuditLogsByJobNumber = gql`
  query getAllAuditLogsByJobNumber($jobNumber: String!) {
    getJobByJobNumber(jobNumber: $jobNumber) {
      id
      auditLogs(entityConnection: "AuditLogEntry") {
        items {
          auditedEntityType
          auditedEntityDisplayName
          executionType
          executedBy
          executedDateTime
          auditedEntityId
          auditedEntitySortKey
          auditedEntityParentId
          auditedEntityParentSortKey
          auditedEntityParentEntityType
          auditedEntityParentDisplayName
          changeLog
          customMessage
        }
      }
      visitsView {
        items {
          auditLogs {
            items {
              auditedEntityType
              auditedEntityDisplayName
              executionType
              executedBy
              executedDateTime
              auditedEntityId
              auditedEntitySortKey
              auditedEntityParentId
              auditedEntityParentSortKey
              auditedEntityParentEntityType
              auditedEntityParentDisplayName
              changeLog
              customMessage
            }
          }
        }
      }
    }
  }
`;

export default getAllAuditLogsByJobNumber;
