import AmplifyService from 'services/AmplifyService';
import getQuoteNumber from '../../graphql/quote/queries/getQuoteNumber';
import getCurrentCounterQuote from '../../graphql/quote/queries/getCurrentCounterQuote';
import addQuoteToCustomer from '../../graphql/quote/mutations/addQuoteToCustomer';
import cloneQuote from '../../graphql/quote/mutations/cloneQuote';
import addQuoteToCustomerProperty from '../../graphql/quote/mutations/addQuoteToCustomerProperty';
import updateQuoteAndRelated from '../../graphql/quote/mutations/updateQuoteAndRelated';
import getQuoteInfoById from '../../graphql/quote/queries/getQuoteInfoByQuoteId';
import getVersionedQuoteById from '../../graphql/quote/queries/getVersionedQuoteById';
import getQuoteCustomerFormDataByQuoteId from '../../graphql/quote/queries/getQuoteCustomerFormDataByQuoteId';
import getVersionedQuoteCustomerFormDataByQuoteId from '../../graphql/quote/queries/getVersionedQuoteCustomerFormDataByQuoteId';
import getQuoteProductsById from '../../graphql/quote/queries/getQuoteProductsByQuoteId';
import getQuoteProductBundlesById from '../../graphql/quote/queries/getQuoteProductBundlesByQuoteId';
import getQuoteNotesById from '../../graphql/quote/queries/getNotesByQuoteId';
import getQuoteTasksById from '../../graphql/quote/queries/getQuoteTasksByQuoteId';
import getAllAuditLogByVersionedQuoteId from '../../graphql/quote/queries/getAuditLogsByVersionedQuoteId';
import getAllAuditLogByQuoteId from '../../graphql/quote/queries/getAuditLogsByQuoteId';
import getAttachmentsByQuoteId from '../../graphql/quote/queries/getAttachmentsByQuoteId';
import addTasksToQuote from '../../graphql/quote/mutations/addTasksToQuote';
import addNotesToQuote from '../../graphql/quote/mutations/addNotesToQuote';
import updateNote from '../../graphql/common/mutations/updateNote';
import addProductsToQuoteLineTask from '../../graphql/quote/mutations/addProductsToQuoteLineTask';
import updateQuoteLineProduct from '../../graphql/quote/mutations/updateQuoteLineProduct';
import batchUpdateQuoteLineProducts from '../../graphql/quote/mutations/batchUpdateQuoteLineProducts';
import updateQuoteLineTask from '../../graphql/quote/mutations/updateQuoteLineTask';
import deleteQuoteLineTask from '../../graphql/quote/mutations/deleteQuoteTask';
import deleteQuoteLineProduct from '../../graphql/quote/mutations/deleteQuoteLineProduct';
import addAttachmentsToQuote from '../../graphql/quote/mutations/addAttachmentsToQuote';
import sendQuoteToCustomer from '../../graphql/quote/mutations/sendQuoteToCustomer';
import createJobFromQuote from '../../graphql/quote/mutations/createJobFromQuote';
import getQuoteTaskLevelTotal from '../../graphql/quote/queries/getQuoteTaskLevelTotal';
import getQuoteProductLevelTotal from '../../graphql/quote/queries/getQuoteProductLevelTotal';
import getQuoteProductBundleLevelTotal from '../../graphql/quote/queries/getQuoteProductBundleLevelTotal';
import quoteUpdateNotification from '../../graphql/quote/subscriptions/quoteUpdateNotification';
import createQuoteSchema from '../../mutation-schema/quotes/create-quote-schema';
import updateQuoteSchema from '../../mutation-schema/quotes/update-quote-schema';
import addTasksToCustomerPropertyAndJob from '../../graphql/quote/mutations/addTasksToCustomerPropertyAndJob';
import updateQuoteUsingTransition from '../../graphql/quote/mutations/updateQuoteUsingTransition';
import SubscriptionClient from '../helper';
import deleteQuoteQuoteTag from '../../graphql/quote/mutations/deleteQuoteQuoteTag';
import { getFormattedQuoteAuditLogs } from '../../../../scenes/Quotes/utils/helper';
import addQuoteQuoteTagsToQuote from '../../graphql/quote/mutations/addQuoteQuoteTagsToQuote';
import addQuoteQuoteTagsToVersionedQuote from '../../graphql/quote/mutations/addQuoteQuoteTagsToVersionedQuote';
import deleteAttachmentFromQuote from '../../graphql/quote/mutations/deleteAttachmentFromQuote';
import addPurchaseOrdersToQuote from '../../graphql/quote/mutations/addPurchaseOrdersToQuote';
import addPurchaseOrdersToVersionedQuote from '../../graphql/quote/mutations/addPurchaseOrdersToVersionedQuote';

export default class QuoteService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
    this.subscriptionClient = SubscriptionClient.getClient(AmplifyService.config);
  }

  getQuoteNumber = async () => {
    const response = await this.api.client.query({
      query: getQuoteNumber,
      fetchPolicy: 'no-cache'
    });
    return response;
  };

  getCurrentCounterQuote = async () => {
    const response = await this.api.client.query({
      query: getCurrentCounterQuote,
      fetchPolicy: 'network-only'
    });
    return response;
  };

  addQuoteToCustomer = async (partitionKey, values) => {
    const payload = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(addQuoteToCustomer, payload);
    return response;
  };

  cloneQuote = async (partitionKey, quoteId) => {
    const payload = {
      partitionKey,
      data: {
        quoteId
      }
    };

    const response = await this.api.mutate(cloneQuote, payload);
    return response;
  };

  addQuoteToCustomerProperty = async (partitionKey, values, pageState) => {
    const payload = createQuoteSchema(values, pageState);
    const params = {
      partitionKey,
      data: payload
    };

    const response = await this.api.mutate(addQuoteToCustomerProperty, params);
    return response;
  };

  addTasksToQuote = async (partitionKey, values) => {
    const payload = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(addTasksToQuote, payload);
    return response;
  };

  updateQuoteLineTask = async (partitionKey, values) => {
    const payload = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(updateQuoteLineTask, payload);
    return response;
  };

  addProductsToQuoteLineTask = async (partitionKey, values) => {
    const payload = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(addProductsToQuoteLineTask, payload);
    return response;
  };

  updateProductForQuoteLineTask = async (partitionKey, values) => {
    const payload = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(updateQuoteLineProduct, payload);
    return response;
  };

  updateQuoteLineProductsForTask = async (partitionKey, values) => {
    // As the addProducts is common and the backend call is diff structure for updateTaskEntry and batchUpdateQuoteLineProducts
    // Handling here
    const payload = {
      partitionKey,
      data: values.quoteLineProducts
    };

    const response = await this.api.mutate(batchUpdateQuoteLineProducts, payload);
    return response;
  };

  updateQuote = async (partitionKey, values, pageState) => {
    const payload = updateQuoteSchema(values, pageState);
    const params = {
      partitionKey,
      data: payload
    };

    const response = await this.api.mutate(updateQuoteAndRelated, params);
    return response;
  };

  updateQuoteUsingTransition = async data => {
    const params = {
      ...data,
      sort: [
        {
          sortField: 'sortOrder',
          sortDirection: 'asc'
        },
        {
          sortField: 'createdDateTime',
          sortDirection: 'asc'
        }
      ]
    };

    const response = await this.api.mutate(updateQuoteUsingTransition, params);
    return response;
  };

  updateQuoteTags = async data => {
    const { params, id, primaryQuoteId } = data || {};
    const updateQuoteQuoteTagsMutation =
      id === primaryQuoteId ? addQuoteQuoteTagsToQuote : addQuoteQuoteTagsToVersionedQuote;
    const response = await this.api.mutate(updateQuoteQuoteTagsMutation, params);
    return response;
  };

  updateQuotePurchaseOrders = async data => {
    const { quoteId, purchaseOrderId, primaryQuoteId, tenantId } = data || {};
    const params = {
      data: {
        quoteId,
        quotePurchaseOrders: [
          {
            purchaseOrderId,
            quoteId
          }
        ]
      },
      partitionKey: tenantId
    };
    const updateQuotePurchaseOrdersMutation =
      quoteId === primaryQuoteId ? addPurchaseOrdersToQuote : addPurchaseOrdersToVersionedQuote;
    const response = await this.api.mutate(updateQuotePurchaseOrdersMutation, params);
    return (
      response.data?.addPurchaseOrdersToQuote?.[0] ||
      response.data?.addPurchaseOrdersToVersionedQuote?.[0]
    );
  };

  createJobFromQuote = async (partitionKey, quoteId) => {
    const payload = {
      partitionKey,
      data: {
        quoteId
      }
    };

    const response = await this.api.mutate(createJobFromQuote, payload);
    if (response && response.data && response.data.createJobFromQuote) {
      return response.data.createJobFromQuote;
    }
    return response;
  };

  getQuoteInfoById = async id => {
    const params = {
      id
    };

    const response = await this.api.query(getQuoteInfoById, params);
    if (response && response.data && response.data.getQuoteById) {
      return response.data.getQuoteById;
    }
    return response;
  };

  getVersionedQuoteById = async id => {
    const params = {
      id,
      sort: [
        {
          sortField: 'sortOrder',
          sortDirection: 'asc'
        },
        {
          sortField: 'createdDateTime',
          sortDirection: 'asc'
        }
      ]
    };

    const response = await this.api.query(getVersionedQuoteById, params);
    if (response && response.data && response.data.getVersionedQuoteById) {
      return response.data.getVersionedQuoteById;
    }
  };

  getQuoteCustomFormDataById = async (id, isVersionedQuote = false) => {
    const params = {
      id
    };
    let data;
    if (isVersionedQuote) {
      const response = await this.api.query(getVersionedQuoteCustomerFormDataByQuoteId, params);
      data = response?.data?.getVersionedQuoteById;
    } else {
      const response = await this.api.query(getQuoteCustomerFormDataByQuoteId, params);
      data = response?.data?.getQuoteById;
    }

    return data;
  };

  getQuoteNotesById = async id => {
    const params = {
      id
    };

    const response = await this.api.query(getQuoteNotesById, params);
    if (response && response.data && response.data.getQuoteById) {
      return response.data.getQuoteById.notes;
    }
    return response;
  };

  getQuoteProductsById = async id => {
    const params = {
      id,
      sort: [
        {
          sortField: 'sortOrder',
          sortDirection: 'asc'
        },
        {
          sortField: 'createdDateTime',
          sortDirection: 'asc'
        }
      ]
    };

    const response = await this.api.query(getQuoteProductsById, params);
    if (response && response.data && response.data.getQuoteById) {
      const result = response.data.getQuoteById.quoteLineProducts;
      return { nextToken: result.nextToken || 0, items: result.items || [] };
    }
    return response;
  };

  getQuoteProductBundlesById = async id => {
    const params = {
      id
    };

    const response = await this.api.query(getQuoteProductBundlesById, params);
    if (response && response.data && response.data.getQuoteById) {
      const result = response.data.getQuoteById.quoteLineProductBundles;
      return { nextToken: result.nextToken || 0, items: result.items || [] };
    }
    return response;
  };

  getQuoteTasksById = async idValue => {
    const params = {
      id: idValue,
      sort: [
        {
          sortField: 'sortOrder',
          sortDirection: 'asc'
        },
        {
          sortField: 'createdDateTime',
          sortDirection: 'asc'
        }
      ]
    };

    const response = await this.api.query(getQuoteTasksById, params);
    const formattedResult = { items: [] };
    if (response && response.data && response.data.getQuoteById) {
      const result = response.data.getQuoteById;
      ((result.quoteLineTasks || {}).items || []).forEach(item => {
        let subtotalValue = 0.0;
        if (item.subtotal && item.subtotal.items && item.subtotal.items.length > 0) {
          subtotalValue = item.subtotal.items.reduce((a, b) => (a.total || 0) + (b.total || 0), 0);
          subtotalValue =
            typeof subtotalValue === 'string' ? parseFloat(subtotalValue) : subtotalValue;
        }

        let taxableSubtotal = 0.0;
        if (item.taxableTotal && item.taxableTotal.items && item.taxableTotal.items.length > 0) {
          taxableSubtotal = item.taxableTotal.items.reduce(
            (a, b) => (a.total || 0) + (b.total || 0),
            0
          );
          taxableSubtotal =
            typeof taxableSubtotal === 'string' ? parseFloat(taxableSubtotal) : taxableSubtotal;
        }

        const formattedTask = {
          id: item.id,
          version: item.version,
          entityType: item.entityType,
          name: item.name,
          description: item.description,
          source: item.taskId ? 'Property' : 'Quote',
          markupType: item.markupType,
          markupValue: item.markupValue,
          unitPrice: item.unitPrice,
          unitCost: item.unitCost,
          quantity: item.quantity,
          taxable: item.taxable,
          sortOrder: item.sortOrder,
          subtotalValue:
            (item.unitPrice && item.quantity && item.quantity * item.unitPrice) || subtotalValue,
          taxableSubtotal: item.taxable
            ? (item.unitPrice && item.quantity && item.quantity * item.unitPrice) || taxableSubtotal
            : 0
        };
        let countOfProducts = 0;
        if (item.products && item.products.items && item.products.items.length > 0) {
          countOfProducts = item.products.items.length;
          const products = item.products.items;
          const formattedProduct = [];
          products.forEach(product => {
            formattedProduct.push({
              id: product.id,
              version: product.version,
              entityType: product.entityType,
              name: product.name,
              sortOrder: product.sortOrder,
              description: product.description,
              markupType: product.markupType,
              markupValue: product.markupValue,
              unitPrice: product.unitPrice,
              unitCost: product.unitCost,
              quantity: product.quantity,
              taxable: product.taxable,
              productId: product.productId,
              amount: (product.quantity || 0) * (product.unitPrice || 0),
              priceBookEntryId: product.priceBookEntryId
            });
          });
          formattedTask.products = { items: formattedProduct };
          formattedTask.countOfProducts = countOfProducts;
        } else {
          formattedTask.products = { items: [] };
          formattedTask.countOfProducts = 0;
        }

        formattedResult.items.push(formattedTask);
      });
      if (result.nextToken) {
        formattedResult.nextToken = result.nextToken;
      }
    }
    return formattedResult;
  };

  getQuoteAttachmentsById = async idValue => {
    const params = {
      id: idValue
    };

    const response = await this.api.query(getAttachmentsByQuoteId, params);
    if (response && response.data && response.data.getQuoteById) {
      const result = response.data.getQuoteById;
      return result.attachments;
    }
  };

  addNotesToQuote = async (partitionKey, quoteId, values) => {
    const params = {
      partitionKey,
      data: {
        quoteId,
        notes: [
          {
            subject: values.subject || null,
            note: values.note || null
          }
        ]
      }
    };

    const { data } = await this.api.mutate(addNotesToQuote, params);
    return data && data.addNotesToQuote;
  };

  addAttachmentsToQuote = async (partitionKey, quoteId, values) => {
    let fileext = '';
    if (values.fileUrl) {
      const splitStr = values.fileUrl.split('.') || [];
      fileext = splitStr.length > 0 ? splitStr[splitStr.length - 1] : null;
    }
    const params = {
      partitionKey,
      data: {
        quoteId,
        attachments: [
          {
            fileName: values.fileName,
            customFileName: values.customFileName || undefined,
            fileUrl: values.fileUrl,
            type: values.type || fileext || 'img',
            comment: values.description || values.comment || undefined,
            description: values.description || undefined,
            fileSize: values.fileSize,
            isInternalFile: values.isInternalFile || false,
            hideFromTechniciansOnMobile: values.hideFromTechniciansOnMobile || false
          }
        ]
      }
    };

    const { data } = await this.api.mutate(addAttachmentsToQuote, params);
    return data && data.addAttachmentsToQuote;
  };

  updateNote = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: {
        id: values.id,
        version: values.version,
        note: values.note,
        subject: values.subject
      }
    };

    const { data } = await this.api.mutate(updateNote, params);
    return data && data.updateNote;
  };

  deleteQuoteLineTask = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };

    const { data } = await this.api.mutate(deleteQuoteLineTask, params);
    return data && data.deleteQuoteLineTask;
  };

  deleteQuoteLineProduct = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };

    const { data } = await this.api.mutate(deleteQuoteLineProduct, params);
    return data && data.deleteQuoteLineProduct;
  };

  sendQuoteToCustomer = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };
    const { data } = await this.api.mutate(sendQuoteToCustomer, params);
    return data && data.sendQuoteToCustomer;
  };

  /**
   * "taskLevelGrouping": {
        "items": [
          {
            "totalAmount": "29.2800000000000000",
            "taxable": "false",
            "taskId": "b7ccd236-1875-4126-b55f-d367ec56d079",
            "entityType": "QuoteLineTask"
          }
        ]
      }
   *  "productLevelGrouping": {
        "items": [
          {
            "totalAmount": "110.0000000000000000",
            "taxable": null,
            "parentId": "3e5c9c30-2ece-4cac-8a56-86d11543a084",
            "entityType": "QuoteLineProduct"
          },
          {
            "totalAmount": "22.0000000000000000",
            "taxable": "true",
            "parentId": "6cf82e49-50db-4f96-9992-d6ac697aaad1",
            "entityType": "QuoteLineProduct"
          },
          {
            "totalAmount": "13.2000000000000000",
            "taxable": "false",
            "parentId": "6cf82e49-50db-4f96-9992-d6ac697aaad1",
            "entityType": "QuoteLineProduct"
          }
        ]
   */
  getQuoteSummary = async quoteId => {
    let summary = {};
    const productLevelTotalResponse = this.api.query(getQuoteTaskLevelTotal, {
      id: quoteId
    });
    const productBundleTotalResponse = this.api.query(getQuoteProductBundleLevelTotal, {
      id: quoteId
    });
    const taskLevelTotalResponse = this.api.query(getQuoteProductLevelTotal, {
      id: quoteId
    });

    // For performance running 3 queries in parallel
    await Promise.all([
      productLevelTotalResponse,
      productBundleTotalResponse,
      taskLevelTotalResponse
    ]).then(responsesArray => {
      responsesArray.forEach(response => {
        // getting each group level reponses and gathering total amounts
        // if the price is overwritten at task level only those values is to be considered. Product lines total to be ignored
        // hence using taskId, productBundleId, matching it with parent of product id
        if (response && response.data && response.data.getQuoteById) {
          // under each group there will be amount json object with taxable flag
          const result = response.data.getQuoteById;
          if (
            result.taskLevelGrouping &&
            result.taskLevelGrouping.items &&
            result.taskLevelGrouping.items.length > 0
          ) {
            const groupingSummary = result.taskLevelGrouping.items;
            groupingSummary.forEach(item => {
              const totalAmountValue = item.totalAmount && parseFloat(item.totalAmount);
              const isTaxable = item.taxable === 'true';
              if (!summary[item.taskId]) {
                summary[item.taskId] = isTaxable
                  ? { taxableAmount: totalAmountValue, totalAmount: totalAmountValue }
                  : { taxableAmount: 0, totalAmount: totalAmountValue };
              } else {
                const localSummaryItem = summary[item.taskId];
                if (isTaxable) {
                  localSummaryItem.taxableAmount = totalAmountValue;
                }
                localSummaryItem.totalAmount = totalAmountValue;
              }
            });
          }

          if (
            result.productBundleLevelGrouping &&
            result.productBundleLevelGrouping.items &&
            result.productBundleLevelGrouping.items.length > 0
          ) {
            const groupingSummary = result.productBundleLevelGrouping.items;
            groupingSummary.forEach(item => {
              const totalAmountValue = item.totalAmount && parseFloat(item.totalAmount);
              const isTaxable = item.taxable === 'true';
              if (!summary[item.productBundleId]) {
                summary[item.productBundleId] = isTaxable
                  ? { taxableAmount: totalAmountValue, totalAmount: totalAmountValue }
                  : { taxableAmount: 0, totalAmount: totalAmountValue };
              } else {
                const localSummaryItem = summary[item.productBundleId];
                if (isTaxable) {
                  localSummaryItem.taxableAmount = totalAmountValue;
                }
                localSummaryItem.totalAmount = totalAmountValue;
              }
            });
          }

          if (
            result.productLevelGrouping &&
            result.productLevelGrouping.items &&
            result.productLevelGrouping.items.length > 0
          ) {
            // maintaining local product summary because taxable and not taxable item will be seperate items
            const productSummary = {};
            const groupingSummary = result.productLevelGrouping.items;
            groupingSummary.forEach(item => {
              const totalAmountValue = item.totalAmount && parseFloat(item.totalAmount);
              const isTaxable = item.taxable === 'true';
              // ignore if the task level or product bundle level is overwritten
              if (!summary[item.parentId]) {
                if (!productSummary[item.parentId]) {
                  productSummary[item.parentId] = isTaxable
                    ? { taxableAmount: totalAmountValue, totalAmount: totalAmountValue }
                    : { taxableAmount: 0, totalAmount: totalAmountValue };
                } else {
                  const localSummaryItem = productSummary[item.parentId];
                  if (isTaxable) {
                    localSummaryItem.taxableAmount = totalAmountValue;
                  }
                  localSummaryItem.totalAmount = totalAmountValue;
                }
                // productSummary[item.parentId] = isTaxable
                //   ? { taxableAmount: totalAmountValue }
                //   : { totalAmount: totalAmountValue }
              }
              // no else part as the parent (task or product bundle) has value overwritten
            });
            summary = { ...summary, ...productSummary };
          }
        }
      });
    });
    // {b7ccd236-1875-4126-b55f-d367ec56d079: {…}, 3e5c9c30-2ece-4cac-8a56-86d11543a084: {…}, 6cf82e49-50db-4f96-9992-d6ac697aaad1: {…}}
    // iterate item total values and generate the finalSummary
    let subtotalAmount = 0;
    let taxableAmount = 0;

    Object.values(summary).forEach(groupTotals => {
      if (groupTotals.totalAmount) {
        subtotalAmount += groupTotals.totalAmount;
      }
      if (groupTotals.taxableAmount) {
        taxableAmount += groupTotals.taxableAmount;
      }
    });

    return { subtotalAmount, taxableAmount };
  };

  quoteUpdated = async tenantId => {
    const params = { partitionKey: tenantId };
    return this.subscriptionClient.subscribe({ query: quoteUpdateNotification, variables: params });
  };

  addTasksToCustomerPropertyAndJob = async (partitionKey, values) => {
    const dataSet = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(addTasksToCustomerPropertyAndJob, dataSet);
    return response;
  };

  getAllAuditLogByQuoteId = async idValue => {
    const params = {
      id: idValue
    };
    const { data, error } = await this.api.query(getAllAuditLogByQuoteId, params);
    if (data?.getQuoteById?.auditLogs) {
      return getFormattedQuoteAuditLogs(data.getQuoteById);
    }
    if (error) {
      throw error;
    }
  };

  getAllAuditLogByVersionedQuoteId = async idValue => {
    const params = {
      id: idValue
    };
    const { data, error } = await this.api.query(getAllAuditLogByVersionedQuoteId, params);
    if (data?.getVersionedQuoteById?.auditLogs) {
      return getFormattedQuoteAuditLogs(data.getVersionedQuoteById);
    }
    if (error) {
      throw error;
    }
  };

  deleteQuoteQuoteTag = async (partitionKey, id, deletedFromQuoteId) => {
    const params = { partitionKey, id, deletedFromQuoteId };
    await this.api.mutate(deleteQuoteQuoteTag, params);
    return { success: true };
  };

  deleteAttachmentFromQuote = async (partitionKey, sortKey) => {
    const params = {
      input: {
        partitionKey,
        sortKey
      }
    };
    const response = await this.api.mutate(deleteAttachmentFromQuote, params);
    return response;
  };
}
