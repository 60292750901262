import React from 'react';
import MuiDivider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

/**
 * Divider for page header. Has margins defined
 */
const PageHeaderDivider = props => (
  <MuiDivider variant="fullWidth" className={props.classes.pageHeader} />
);

// styled divider for section header, uses secondary colour of the application
const SectionHeaderDivider = props => (
  <MuiDivider
    variant="fullWidth"
    className={props.classes.sectionHeader}
    style={props.enablePadding ? { marginBottom: 15 } : null}
  />
);

// styled divider for section header, uses secondary colour of the application
const SectionDivider = props => {
  return (
    <MuiDivider
      variant="fullWidth"
      className={props.classes.sectionDivider}
      style={props.customStyle ? props.customStyle : { marginTop: 15, marginBottom: 15 }}
    />
  );
};

export default withStyles(styles, { withTheme: true })(PageHeaderDivider);
export const StyledSectionHeaderDivider = withStyles(styles, { withTheme: true })(
  SectionHeaderDivider
);
export const StyledSectionDivider = withStyles(styles, { withTheme: true })(SectionDivider);
