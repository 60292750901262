import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PopperMenu from 'components/PopperMenu';

const useStyles = makeStyles(() => ({
  vertIconButton: {
    padding: 0,
    cursor: 'pointer'
  },
  labelMargin: {
    marginTop: 17.5
  }
}));

const MoreAction = ({ actionsList, options, style }) => {
  const classes = useStyles();
  const theme = useTheme();
  const anchorRef = useRef();
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={() => setIsPopperOpen(true)}
        className={classNames(classes.vertIconButton, options.label && classes.labelMargin)}
        style={style}
        disableRipple
      >
        <MoreVertIcon style={{ fontSize: 24, color: theme.palette.grayscale(20) }} />
      </IconButton>
      <PopperMenu
        itemList={actionsList}
        open={isPopperOpen}
        onClose={() => setIsPopperOpen(false)}
        anchorEl={anchorRef.current}
      />
    </>
  );
};

export default MoreAction;
