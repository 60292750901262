import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { useSnackbar } from 'customHooks/useSnackbar';
import { logErrorWithCallback } from 'utils';

// TODO: write a new mutation that will update data on the binder with timesheetEntries
export const updateTimesheetEntryHoursMutation = gql`
  mutation updateTimesheetEntryHours(
    $partitionKey: String!
    $data: AddTimesheetEntriesToEmployeeInput!
  ) {
    bulkUpdateTimesheetEntries(partitionKey: $partitionKey, data: $data) {
      id
      hourTypeAbbreviation
      actualTotalDuration
      actualTotalDurationOverride
      actualStartTimeUTC
      actualEndTimeUTC
      auditLogs {
        items {
          id
          executedBy
          changeLog
          executedDateTime
          executionType
        }
      }
    }
  }
`;

const serializer = ({ employee, timesheetEntries }) => {
  const { tenantId, id } = employee;
  return {
    variables: {
      partitionKey: tenantId,
      data: {
        employeeId: id,
        timesheetEntries: timesheetEntries.map(e => {
          const { extra, ...props } = e;
          return props;
        })
      }
    }
  };
};

const useUpdateTimesheetEntryHours = () => {
  const snackbar = useSnackbar();

  return useExtendedMutation(updateTimesheetEntryHoursMutation, {
    serializer,
    // TODO: update cache
    onError: error => {
      logErrorWithCallback(error, snackbar, 'Unable to update time entries hours');
    },
    onCompleted: () => {
      snackbar('success', 'Updated timesheet successfully');
    }
  });
};

export default useUpdateTimesheetEntryHours;
