const actions = {
  PUT_ON_HOLD: {
    title: 'Put on Hold',
    color: '#646464',
    icon: 'onHold'
  },
  RESCHEDULE: {
    title: 'Reschedule',
    icon: 'reschedule'
  },
  COMPLETE: {
    title: 'Mark Completed',
    color: '#2dce89',
    icon: 'completed'
  },
  CANCEL: {
    title: 'Cancel',
    color: '#b00020',
    icon: 'cancel'
  },
  CONTINUE_IN_NEW_VISIT: {
    title: 'Continue in New Visit',
    icon: 'continueNew'
  },
  SCHEDULE: {
    title: 'Schedule',
    icon: 'schedule'
  },
  REOPEN: {
    title: 'Reopen',
    icon: 'reopen'
  }
};

export default actions;
