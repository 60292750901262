export const summaryLayout = ({ isMultiVisits = false, visitOptions = [] }) => ({
  fields: {},
  layouts: {
    add: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        isMultiVisits && {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel',
                    add: 'SelectInput'
                  },
                  source: 'visitId',
                  options: {
                    valueSet: visitOptions,
                    label: 'Visit',
                    isRequired: true,
                    fullWidth: true,
                    variant: 'standard',
                    color: 'secondary',
                    enableSort: false
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel',
                    add: 'TextInput'
                  },
                  source: 'content',
                  options: {
                    label: 'Summary Note',
                    isRequired: true,
                    lines: 3,
                    linesMax: 9
                  }
                }
              ]
            }
          ]
        }
      ].filter(Boolean)
    },
    edit: {
      options: {
        direction: 'row'
      },
      contents: [
        {
          options: {
            direction: 'column'
          },
          contents: [
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel',
                    edit: 'TextInput'
                  },
                  source: 'content',
                  options: {
                    label: 'Summary Note',
                    isRequired: true,
                    lines: 3,
                    linesMax: 9
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      content: {
        type: 'string'
      },
      visitId: {
        type: 'string'
      }
    },
    required: ['content', isMultiVisits && 'visitId'].filter(Boolean)
  },
  validationErrors: {
    content: {
      required: 'Summary is required'
    },
    visitId: {
      required: 'Visit is required'
    }
  }
});
