import React from 'react';

import { Button, ThemeProvider } from '@buildhero/sergeant';
import { Divider, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/ArrowForward';
import classnames from 'classnames';
import { bool, func } from 'prop-types';

import { useModalContext } from '@dispatch/hocs';
import { VISIT_DETAILS_PROP } from '@dispatch/queries';

import DrawerMenu from './components/Menu';
import { deleteItem, unsavedChanges } from './DrawerLayout.helpers';
import { useStyles } from './DrawerLayout.styles';

const DrawerLayout = ({
  closeDrawer,
  renderHeader,
  renderBody,
  onResetForm,
  onSubmitForm,
  onClone,
  onContinueAsNew,
  onDelete,
  onDeleteAll,
  onReleaseToTech,
  isDirty,
  loading,
  disabledSave,
  disabledClose,
  disabledDelete,
  disabledComplete,
  visit,
  showProcurement,
  onCompleteProjectVisit,
  refetchVisitData
}) => {
  const classes = useStyles();
  const { openModal, closeModal } = useModalContext();

  const handleCloseVisit = () => {
    if (!isDirty) return closeDrawer();

    openModal(
      unsavedChanges({
        onConfirm: () => {
          onResetForm();
          closeModal();
          closeDrawer();
        },
        onCancel: () => {
          closeModal();
        }
      })
    );
  };

  const handleFormReset = () => {
    openModal(
      unsavedChanges({
        onConfirm: () => {
          onResetForm();
          closeModal();
        },
        onCancel: () => {
          closeModal();
        }
      })
    );
  };

  const handleClone = () => {
    if (!isDirty) return onClone();

    openModal(
      unsavedChanges({
        onConfirm: () => {
          onResetForm();
          closeModal();
          onClone();
        },
        onCancel: () => {
          closeModal();
        }
      })
    );
  };

  const handleContinueAsNew = () => {
    onContinueAsNew();
  };

  const handleReleaseToTech = async () => {
    await onReleaseToTech();
    closeDrawer();
  };

  const handleDelete = () => {
    openModal(
      deleteItem({
        onConfirm: () => {
          onDelete();
          closeModal();
        },
        onCancel: () => {
          closeModal();
        }
      })
    );
  };

  return (
    <div className={classes.formContainer}>
      <div className={classnames(loading ? classes.headerLoading : classes.header)}>
        <div className={classes.buttonContainer}>
          <div>
            <IconButton edge="start" disabled={disabledClose} onClick={handleCloseVisit}>
              <CloseIcon />
            </IconButton>
          </div>
          <DrawerMenu
            disabled={disabledClose}
            disabledDelete={disabledDelete}
            disabledComplete={disabledComplete}
            onClone={onClone ? handleClone : undefined}
            onContinueAsNew={onContinueAsNew ? handleContinueAsNew : undefined}
            onDelete={onDelete ? handleDelete : undefined}
            onDeleteAll={onDeleteAll}
            onReleaseToTech={onReleaseToTech ? handleReleaseToTech : undefined}
            showProcurement={showProcurement}
            visit={visit}
            onCompleteProjectVisit={onCompleteProjectVisit}
            refetchVisitData={refetchVisitData}
          />
        </div>
        <Divider className={classes.divider} />
        <div className={classes.customHeader}>{renderHeader()}</div>
      </div>
      <div className={classes.scrollContent}>{renderBody()}</div>
      <div className={classes.footer}>
        <ThemeProvider>
          <Button
            className={classes.resetButton}
            disabled={!isDirty}
            loading={loading}
            type="tertiary"
            onClick={handleFormReset}
          >
            Reset
          </Button>
          <Button
            className={classes.saveButton}
            loading={loading}
            disabled={disabledSave}
            onClick={onSubmitForm}
          >
            Save
          </Button>
        </ThemeProvider>
      </div>
    </div>
  );
};

DrawerLayout.propTypes = {
  closeDrawer: func.isRequired,
  renderBody: func.isRequired,
  renderHeader: func.isRequired,
  onResetForm: func.isRequired,
  onSubmitForm: func.isRequired,
  onClone: func,
  onContinueAsNew: func,
  onDelete: func,
  onDeleteAll: func,
  onReleaseToTech: func,
  isDirty: bool.isRequired,
  loading: bool.isRequired,
  disabledSave: bool.isRequired,
  disabledClose: bool.isRequired,
  disabledDelete: bool,
  disabledComplete: bool,
  visit: VISIT_DETAILS_PROP,
  showProcurement: bool,
  onCompleteProjectVisit: func,
  refetchVisitData: func
};

DrawerLayout.defaultProps = {
  onClone: null,
  onContinueAsNew: null,
  onDelete: null,
  onDeleteAll: null,
  onReleaseToTech: null,
  showProcurement: false,
  visit: null,
  onCompleteProjectVisit: null,
  disabledDelete: false,
  disabledComplete: false,
  refetchVisitData: () => {}
};

export default DrawerLayout;
