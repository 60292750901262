import { useRef } from 'react';

const defaultLifeCycle = {
  onRequest: null,
  onResponse: null,
  onSuccess: null,
  onError: null
};

const defaultResponse = {
  data: null,
  loading: null,
  error: null
};

const useApiLifeCycle = (response = defaultResponse, lifeCycle = defaultLifeCycle) => {
  const { onRequest, onResponse, onError, onSuccess } = lifeCycle;
  const { data, error, loading } = response;
  const wasLoading = useRef(false);

  if (onRequest || onResponse || onError || onSuccess) {
    if (loading && !wasLoading.current) {
      onRequest?.();
      wasLoading.current = true;
    }

    if (!loading && wasLoading.current) {
      if (error) {
        onError?.(error);
      } else if (data) {
        onSuccess?.(data);
      }

      onResponse?.({ error, data });
      wasLoading.current = false;
    }
  }
};

export default useApiLifeCycle;
