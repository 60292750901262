import React, { useCallback } from 'react';
import { array, func } from 'prop-types';
import { Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Button as SgtBtn, ThemeProvider, Typography, TW } from '@buildhero/sergeant';
import TimesheetCard from '../TimesheetCard';
import { useStyles } from './LaborPhasesForm.styles';

const LaborPhases = ({
  phases,
  projectPhases,
  payrollHourTypes,
  onPhaseAdd,
  onPhaseChange,
  onPhaseDelete
}) => {
  const styles = useStyles();
  const getHeader = useCallback(
    phase => (
      <div css={styles.headerContainer}>
        <ThemeProvider>
          <Typography weight={TW.BOLD} component="span">
            Phase / Department / Cost Code / Hours
          </Typography>
          {onPhaseDelete && (
            <SgtBtn type="tertiary" size="small" onClick={() => onPhaseDelete(phase)}>
              delete
            </SgtBtn>
          )}
        </ThemeProvider>
      </div>
    ),
    [onPhaseDelete, styles]
  );

  return (
    <div css={styles.root}>
      {phases?.map(phase => (
        <>
          {getHeader(phase)}
          <TimesheetCard
            key={phase.phaseIndex}
            employeeTimesheet={phase}
            projectPhases={projectPhases}
            payrollHourTypes={payrollHourTypes}
            onChange={onPhaseChange}
            variant="outlined"
          />
        </>
      ))}
      <div css={styles.addButtonContainer}>
        <Button startIcon={<AddCircleOutlineIcon />} css={styles.addButton} onClick={onPhaseAdd}>
          <Typography>Add Phase / Department / Cost Code / Hours</Typography>
        </Button>
      </div>
    </div>
  );
};

LaborPhases.propTypes = {
  phases: array.isRequired,
  projectPhases: array.isRequired,
  payrollHourTypes: array.isRequired,
  onPhaseAdd: func.isRequired,
  onPhaseChange: func.isRequired,
  onPhaseDelete: func.isRequired
};

export default LaborPhases;
