import gql from 'graphql-tag';

const createInvoiceForJob = gql`
  mutation createInvoiceForJob($partitionKey: String, $data: CreateInvoiceForJob) {
    createInvoiceForJob(partitionKey: $partitionKey, data: $data) {
      id
      invoiceNumber
      sortKey
    }
  }
`;

export default createInvoiceForJob;
