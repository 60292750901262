/* eslint-disable no-template-curly-in-string */
const powerlinkPDFForm = settings => ({
  fields: {
    senderInfoLogoUrl: {
      pdf: '${senderInfoLogo}'
    },
    tenantAddress: {
      pdf: '${senderInfo.addressLine1}  *  ${senderInfo.addressLine3}  *  ${senderInfo.phoneNumber}'
    },
    // Customer Detail
    customerContactNameTitle: {
      pdf: 'Attn: '
    },
    customerContactName: {
      pdf: '${customerContactNameTitle}'
    },
    customerNameTitle: {
      pdf: 'Company: '
    },
    customerName: {
      pdf: '${customer.customerName}'
    },
    AddressTitle: {
      pdf: 'Address: '
    },
    billingAddressLine1: {
      pdf: '${billingAddressLine1}'
    },
    billingAddressLine2: {
      pdf: '${billingAddressLine2}'
    },
    billingAddress3: {
      pdf: '${billingAddressLine3}'
    },
    customerContactPhoneTitle: {
      pdf: 'Phone: '
    },
    customerContactPhone: {
      pdf: '${customerContactPhoneTitle}'
    },
    customerContactEmailTitle: {
      pdf: 'Via Email: '
    },
    customerContactEmail: {
      pdf: '${customerContactEmailTitle}'
    },
    // Tenant Detail
    createdDateTitle: {
      pdf: 'Date: '
    },
    createdDate: {
      pdf: '${createdDate}'
    },
    quoteNameTitle: {
      pdf: 'Re: '
    },
    quoteName: {
      pdf: '${quoteInfo.name}'
    },
    propertyLabel: {
      pdf: 'Property: '
    },
    propertyName: {
      pdf: '${property.companyName}'
    },
    propertyAddressLine1: {
      pdf: '${propertyDetail.propertyAddress1}'
    },
    propertyAddressLine2: {
      pdf: '${propertyDetail.propertyAddress2}'
    },
    propertyAddressLine3: {
      pdf: '${propertyDetail.propertyAddress3}'
    },
    quoteNumberValueTitle: {
      pdf: 'File Number: '
    },
    quoteNumberValue: {
      pdf: '${customizedQuoteNumber}'
    },
    // Introduction
    introduction: {
      pdf: '${quoteCustomFieldsData.Custom_longText1}'
    },
    // Total
    totalTitle: {
      pdf: 'Total Proposal: '
    },
    totalText: {
      pdf: '$${finalAmount}'
    },
    // Closing Remarks
    closingRemarks: {
      pdf: '${quoteCustomFieldsData.Custom_longText2}'
    }
  },
  layouts: {
    default: {
      options: {
        pdfTitle: 'Quote'
      },
      contents: [
        // Logo Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              options: {
                direction: 'column',
                size: 'auto',
                grow: 0,
                shrink: 1
                // debug: true
              },
              contents: [
                {
                  component: {
                    default: 'Logo'
                  },
                  options: {
                    width: 500,
                    height: 200,
                    alt: 'Company logo',
                    size: 'auto',
                    grow: 0,
                    shrink: 1
                  },
                  source: 'senderInfoLogoUrl'
                }
              ]
            }
          ]
        },
        // Address Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [0, 0, 12]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'BodyText'
              },
              source: 'tenantAddress'
            }
          ]
        },
        // Customer Detail Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'space-between',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            // Customer Details
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                direction: 'column',
                width: '50%',
                padding: [0, 5, 0, 0]
              },
              contents: [
                // Contact Name
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactName'
                    }
                  ]
                },
                // Constact Phone
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactPhoneTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactPhone'
                    }
                  ]
                },
                // Contact Email
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactEmailTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactEmail'
                    }
                  ]
                },
                // Customer Name
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerName'
                    }
                  ]
                },
                // Customer Address
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'AddressTitle'
                    },
                    {
                      options: {
                        size: 'auto',
                        grow: 0,
                        shrink: 1,
                        direction: 'column'
                      },
                      contents: [
                        {
                          component: { pdf: 'BoldText' },
                          source: 'billingAddressLine1'
                        },
                        {
                          component: { pdf: 'BoldText' },
                          source: 'billingAddressLine2'
                        },
                        {
                          component: { pdf: 'BoldText' },
                          source: 'billingAddressLine3'
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            // Tenant Details
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                direction: 'column',
                width: '35%'
              },
              contents: [
                // Created Date
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'createdDateTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'createdDate'
                    }
                  ]
                },
                // Quote Name
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'quoteNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'quoteName'
                    }
                  ]
                },
                // Quote Number
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'quoteNumberValueTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'quoteNumberValue'
                    }
                  ]
                },
                // Property Name
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'propertyLabel'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'propertyName'
                    }
                  ]
                },
                // Property Address
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'AddressTitle'
                    },
                    {
                      options: {
                        size: 'auto',
                        grow: 0,
                        shrink: 1,
                        direction: 'column'
                      },
                      contents: [
                        {
                          component: { pdf: 'BoldText' },
                          source: 'propertyAddressLine1'
                        },
                        {
                          component: { pdf: 'BoldText' },
                          source: 'propertyAddressLine2'
                        },
                        {
                          component: { pdf: 'BoldText' },
                          source: 'propertyAddressLine3'
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        // Divider
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              options: {
                color: '#333'
              },
              component: {
                pdf: 'Divider'
              }
            }
          ]
        },
        // Intro
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'RichContentViewer'
              },
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1
              },
              source: 'introduction'
            }
          ]
        },
        // Tasks and Products
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              options: {
                direction: 'column',
                justify: 'start',
                size: 'auto',
                grow: 0,
                shrink: 1,
                padding: [0, 0, 0, 0]
                // debug: true
              },
              contents: [
                {
                  component: {
                    pdf: 'TaskList'
                  },
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    width: 480
                    // debug: true
                  },
                  source: 'quoteTasks'
                }
              ]
            }
          ]
        },
        // Total
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'flex-end',
            align: 'stetch',
            padding: [6, 36, 6],
            hide: settings.hideTotalAmount
            // debug: true
          },
          contents: [
            {
              component: {
                pdf: 'Subtitle'
              },
              source: 'totalTitle'
            },
            {
              component: {
                pdf: 'Subtitle'
              },
              source: 'totalText'
            }
          ]
        },
        // Closing Remarks
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'RichContentViewer'
              },
              source: 'closingRemarks'
            }
          ]
        },
        // Terms and conditions Row
        {
          options: {
            direction: 'row',
            breakPage: 'before',
            width: '100%'
            // debug: true
          },
          contents: [
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                justify: 'flex-start'
                // debug: true
              },
              // For centering title
              contents: [
                {
                  options: {
                    direction: 'row',
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    justify: 'center',
                    padding: [6, 36, 6]
                    // debug: true
                  },
                  contents: [
                    {
                      options: {
                        direction: 'column',
                        size: 'auto',
                        grow: 0,
                        shrink: 1
                        // debug: true
                      },
                      contents: [
                        {
                          component: {
                            default: 'BoldText'
                          },
                          source: 'Terms and Conditions'
                        }
                      ]
                    }
                  ]
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  source: '1. Contract Terms'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'All contracts for the sale of goods and services concluded between Powerlink Solutions Inc., and the purchaser, (herein after called the “Purchase Order” are subject to the following terms and conditions. Any variation from the terms and conditions herein contained will require written consent of Powerlink Solutions Inc. signed by its duty authorized representative.Purchase orders received from the purchaser for Powerlink Solutions Inc. products will be subject to the standard Powerlink Solutions Inc. terms and conditions. Powerlink Solutions Inc. will not be bound by any terms and conditions or pricing stated on the purchase order, unless agreed to in writing by Powerlink Solutions Inc.'
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  source: '2. Prices'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'Unless otherwise stated, prices quoted are valid for acceptance for a period of 30 days from quoted date. Should the decision to accept our offer be delayed to a later date, we would be pleased to either confirm our offer or rebid.  Unless otherwise stated, prices do not include the arrangement and cost of a utility shutdown. The shutdown must be arranged directly with the Utility by the purchaser.Prices quoted are based on Canadian funds.  Additional procurement and shipping costs are excluded from quote and will be charged as extra.  Any changes in foreign exchange rates, sales taxes, customs tariffs or other taxes shall be for the account of the purchaser. Unless otherwise stated, prices do not include any external wiring or cabling from the equipment. Unless otherwise stated, prices are FOB site. Shipping charges are extra.'
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  source: '3. Taxes'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source: 'HST not included.'
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  source: '4. Terms of Payment'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'Terms are net 30 days from date of invoice. Late payments will be subject to interest charges at the rate of two (2%) per month.Any equipment shipped to customer site is a billable expense.'
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  source: '5. Delivery'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'Delivery dates quoted are subject to confirmation at the time of contract award. All confirmed dates are based on the prompt receipt by Powerlink Solutions Inc. of all necessary information enabling work to proceed without interruption. When the contract is for the supply and installation of equipment, a delivery date will be established at the time of contract award. Should the installation of the equipment be delayed beyond that date, Powerlink Solutions Inc. reserves the right to invoice for any costs associated to the project.'
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  source: '6. Force Majeure'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'Powerlink Solutions Inc. will not be liable for any non-performance of a contract if such delay or non-performance is due to any cause beyond the reasonable control of Powerlink Solutions Inc. and/or which Powerlink Solutions Inc. could not reasonably foresee and/or reasonably provide against and which prevents Powerlink Solutions Inc. from carrying out the terms of the contract. This includes but is not limited to the following: war, revolution, insurrection or hostilities (whether declared or not), riot, civil commotion or uprising, flood, earthquake, tempest, hurricane, lightning, or other natural disaster; fire or explosion; strike, lockout or other industrial disturbance whether at Powerlink Solutions Inc. or one of its suppliers; sabotage, accident, embargo, car shortage, wrecks, or delays in transportation, non-delivery of materials or order or action of government authority. Any delay resulting from such cause shall extend the date of delivery accordingly. Powerlink Solutions Inc. reserves the right to cancel the contract, if in its opinion such circumstances threaten or cause extended delay in the performance thereof.'
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  source: '7. Cancellation'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'Purchase orders placed by the purchaser and accepted by Powerlink Solutions Inc. may be canceled only with the consent of Powerlink Solutions Inc. A cancellation charge may be payable by the purchaser to cover costs, including design and engineering, materials purchased and production costs incurred to a maximum of 100% of the selling price.'
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  source: '8. Warranty'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'Powerlink Solutions Inc. warrants the goods and services described herein, of its own manufacture against defects in material or workmanship arising under normal use and service for a period of one year from the date of shipment or completion of the work. The obligation of Powerlink Solutions Inc. under this warranty is limited to the replacement or repair without charge FOB the factory of Powerlink Solutions Inc. provided that the purchaser will give Powerlink Solutions Inc. written notice of the defect immediately after the defect has come to the purchaser’s attention.'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'Powerlink Solutions Inc. shall not be responsible for defects in material or workmanship of, or work done, goods furnished or repairs made by third parties.'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'These conditions shall supersede all warranties and conditions whether legal, conventional or implied, and Powerlink Solutions Inc. assumes no responsibility for any excess warranty additional hereto, unless in writing and signed by an authorized officer of Powerlink Solutions Inc.'
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  source: '9. Safety'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'Site and working conditions must meet conditions as laid out in the applicable Health and Safety Act and Regulations. '
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  source: '10. Governing Law'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'The terms, provisions and conditions hereof and all matters arising out of the execution, construction, interpretation      or breach thereof, are to be governed by the laws of Ontario, Canada. Powerlink Solutions Inc. agrees to bring any action claims or legal proceedings in any way pertaining to this order, or the execution, construction, interpretation or branch thereof in the courts of the jurisdiction specified above and in no other court or tribunal whatsoever.'
                },
                // to stick TOC in one page
                {
                  options: {
                    padding: 20
                  },
                  contents: []
                }
              ]
            }
          ]
        }
      ]
    }
  }
});

export default powerlinkPDFForm;
