import React from 'react';
import Attachment from '../../../AttachmentSection';

const controlMap = (controlText, props) => {
  const controlStrategy = {
    attachment: <Attachment {...props} />
  };
  return controlStrategy[controlText];
};

export default controlMap;
