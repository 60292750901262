/* eslint-disable no-template-curly-in-string */
const thermotechPDFForm = settings => ({
  fields: {
    senderInfoLogoUrl: {
      pdf: '${senderInfoLogo}'
    },
    tenantAddress: {
      pdf: '${senderInfo.addressLine1}  *  ${senderInfo.addressLine3}  *  ${senderInfo.phoneNumber}'
    },
    // Customer Detail
    customerContactNameTitle: {
      pdf: 'Attn: '
    },
    customerContactName: {
      pdf: '${customerContactNameTitle}'
    },
    customerNameTitle: {
      pdf: 'Company: '
    },
    customerName: {
      pdf: '${customer.customerName}'
    },
    AddressTitle: {
      pdf: 'Address: '
    },
    billingAddressLine1: {
      pdf: '${billingAddressLine1}'
    },
    billingAddressLine2: {
      pdf: '${billingAddressLine2}'
    },
    billingAddress3: {
      pdf: '${billingAddressLine3}'
    },
    customerContactPhoneTitle: {
      pdf: 'Phone: '
    },
    customerContactPhone: {
      pdf: '${customerContactPhoneTitle}'
    },
    customerContactEmailTitle: {
      pdf: 'Via Email: '
    },
    customerContactEmail: {
      pdf: '${customerContactEmailTitle}'
    },
    // Tenant Detail
    createdDateTitle: {
      pdf: 'Date: '
    },
    createdDate: {
      pdf: '${createdDate}'
    },
    quoteNameTitle: {
      pdf: 'Re: '
    },
    quoteName: {
      pdf: '${quoteInfo.name}'
    },
    propertyLabel: {
      pdf: 'Property: '
    },
    propertyName: {
      pdf: '${property.companyName}'
    },
    propertyAddressLine1: {
      pdf: '${propertyDetail.propertyAddress1}'
    },
    propertyAddressLine2: {
      pdf: '${propertyDetail.propertyAddress2}'
    },
    propertyAddressLine3: {
      pdf: '${propertyDetail.propertyAddress3}'
    },
    quoteNumberValueTitle: {
      pdf: 'File Number: '
    },
    quoteNumberValue: {
      pdf: '${customizedQuoteNumber}'
    },
    // Introduction
    introduction: {
      pdf: '${quoteCustomFieldsData.Custom_longText1}'
    },
    // Total
    totalTitle: {
      pdf: 'Total Proposal: '
    },
    totalText: {
      pdf: '$${finalAmount}'
    },
    // Closing Remarks
    closingRemarks: {
      pdf: '${quoteCustomFieldsData.Custom_longText2}'
    }
  },
  layouts: {
    default: {
      options: {
        pdfTitle: 'Quote'
      },
      contents: [
        // Logo Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              options: {
                direction: 'column',
                size: 'auto',
                grow: 0,
                shrink: 1
                // debug: true
              },
              contents: [
                {
                  component: {
                    default: 'Logo'
                  },
                  options: {
                    width: 500,
                    height: 200,
                    alt: 'Company logo',
                    size: 'auto',
                    grow: 0,
                    shrink: 1
                  },
                  source: 'senderInfoLogoUrl'
                }
              ]
            }
          ]
        },
        // Address Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [0, 0, 12]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'BodyText'
              },
              source: 'tenantAddress'
            }
          ]
        },
        // Customer Detail Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'space-between',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            // Customer Details
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                direction: 'column',
                width: '50%',
                padding: [0, 5, 0, 0]
              },
              contents: [
                // Contact Name
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactName'
                    }
                  ]
                },
                // Constact Phone
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactPhoneTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactPhone'
                    }
                  ]
                },
                // Contact Email
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactEmailTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactEmail'
                    }
                  ]
                },
                // Customer Name
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerName'
                    }
                  ]
                },
                // Customer Address
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'AddressTitle'
                    },
                    {
                      options: {
                        size: 'auto',
                        grow: 0,
                        shrink: 1,
                        direction: 'column'
                      },
                      contents: [
                        {
                          component: { pdf: 'BoldText' },
                          source: 'billingAddressLine1'
                        },
                        {
                          component: { pdf: 'BoldText' },
                          source: 'billingAddressLine2'
                        },
                        {
                          component: { pdf: 'BoldText' },
                          source: 'billingAddressLine3'
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            // Tenant Details
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                direction: 'column',
                width: '35%'
              },
              contents: [
                // Created Date
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'createdDateTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'createdDate'
                    }
                  ]
                },
                // Quote Name
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'quoteNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'quoteName'
                    }
                  ]
                },
                // Quote Number
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'quoteNumberValueTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'quoteNumberValue'
                    }
                  ]
                },
                // Property Name
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'propertyLabel'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'propertyName'
                    }
                  ]
                },
                // Property Address
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'AddressTitle'
                    },
                    {
                      options: {
                        size: 'auto',
                        grow: 0,
                        shrink: 1,
                        direction: 'column'
                      },
                      contents: [
                        {
                          component: { pdf: 'BoldText' },
                          source: 'propertyAddressLine1'
                        },
                        {
                          component: { pdf: 'BoldText' },
                          source: 'propertyAddressLine2'
                        },
                        {
                          component: { pdf: 'BoldText' },
                          source: 'propertyAddressLine3'
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        // Divider
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              options: {
                color: '#333'
              },
              component: {
                pdf: 'Divider'
              }
            }
          ]
        },
        // Intro
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'RichContentViewer'
              },
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1
              },
              source: 'introduction'
            }
          ]
        },
        // Tasks and Products
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              options: {
                direction: 'column',
                justify: 'start',
                size: 'auto',
                grow: 0,
                shrink: 1,
                padding: [0, 0, 0, 0]
                // debug: true
              },
              contents: [
                {
                  component: {
                    pdf: 'TaskList'
                  },
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    width: 480
                    // debug: true
                  },
                  source: 'quoteTasks'
                }
              ]
            }
          ]
        },
        // Total
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'flex-end',
            align: 'stetch',
            padding: [6, 36, 6],
            hide: settings.hideTotalAmount
            // debug: true
          },
          contents: [
            {
              component: {
                pdf: 'Subtitle'
              },
              source: 'totalTitle'
            },
            {
              component: {
                pdf: 'Subtitle'
              },
              source: 'totalText'
            }
          ]
        },
        // Closing Remarks
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'RichContentViewer'
              },
              source: 'closingRemarks'
            }
          ]
        },
        // Terms and conditions Row
        {
          options: {
            direction: 'row',
            breakPage: 'before',
            width: '100%'
            // debug: true
          },
          contents: [
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                justify: 'flex-start'
                // debug: true
              },
              // For centering title
              contents: [
                {
                  options: {
                    direction: 'row',
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    justify: 'center',
                    padding: [6, 36, 6]
                    // debug: true
                  },
                  contents: [
                    {
                      options: {
                        direction: 'column',
                        size: 'auto',
                        grow: 0,
                        shrink: 1
                        // debug: true
                      },
                      contents: [
                        {
                          component: {
                            default: 'BoldText'
                          },
                          source: 'Terms and Conditions'
                        }
                      ]
                    }
                  ]
                },
                {
                  options: {
                    padding: [6, 36, 6],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '1. FINANCING IS AVAILABLE THROUGH AN ONLINE PROCESS AT HEARTH FINANCING. APPLICATIONS CAN BE SUBMITTED AT WWW.GETHEARTH.COM OR THROUGH OUR WEBSITE AT WWW.THERMOTECHHBG.COM'
                },
                {
                  options: {
                    padding: [6, 36, 6],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '2. THERMOTECH, INC. ACCEPTS PAYMENTS BY CHECK, CASH, AND MONEY ORDER GIVEN DIRECTLY TO OUR TECHNICIAN UPON THE COMPLETION OF OUR SERVICES. MAJOR CREDIT CARDS ARE ALSO ACCEPTED BY CALLING INTO OUR ACCOUNTS RECEIVABLE AT THE TIME OF SERVICES RENDERED. ALL PAYMENTS BY CREDIT / DEBIT CARDS ARE SUBJECT TO AN ADDITIONAL SURCHARGE OF 3%.'
                },
                {
                  options: {
                    padding: [6, 36, 6],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '4. ALL MATERIALS REMAIN THE PROPERTY OF THERMOTECH, INC. UNTIL PAID FOR IN FULL. THERMOTECH, INC. RESERVES THE RIGHT TO REPOSESS EQUIPMENT, PARTS AND / OR MATERIAL UNTIL PAYMENT ARRANGEMENTS HAVE BEEN MADE ON PAST DUE INVOICE(S).'
                },
                {
                  options: {
                    padding: [6, 36, 6],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '5. CERTAIN PROJECTS WILL REQUIRE A 50% DEPOSIT TO BE PAID BEFORE WORK IS TO BE STARTED, CONTINUED OR COMPLETED. THE REQUEST IS DETERMINED BY THE FINANCIAL COSTS INVOLVED. A DEPOSIT WILL BE ASKED TO BE PAID IN ADVANCE WHEN COSTS WILL EXCEED $750. THE REMAINING BALANCE WILL BE DUE UPON COMPLETION'
                },
                {
                  options: {
                    padding: [6, 36, 6],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '6. THERMOTECH DOES NOT ACCEPT RETURNS AFTER 30 DAYS OF SERVICE. ALL PARTS AND MATERIALS ARE MANUFACTURED TO CUSTOMER’S SPECIFICATIONS AND CANNOT BE RETURNED FOR A REFUND. ALL SALES ARE CONSIDERED FINAL AND NO RETURNS WILL BE ACCEPTED.'
                },
                {
                  options: {
                    padding: [6, 36, 6],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '7. THERMOTECH, INC. AGREES TO MAINTAIN LIABILITY INSURANCE COVERING PERSONAL INJURY IN THE AMOUNT NOT LESS THAN $50,000 AND INSURANCE COVERING PROPERTY DAMAGE CAUSED BY THE WORK OF A HOME IMPROVEMENT CONTRACTOR IN AN AMOUNT NOT LESS THAN $50,000 AND THE CURRENT AMOUNT OF INSURANCE COVERAGE MAINTAINED IS $1,000,000 FOR PERSONAL INJURY, AND $2,000,000 FOR DAMAGED CAUSED. $4,000,000 UMBRELLA LIABILITY LIMITED PROVIDED. ALL WORKMANSHIP BY INSTALLATION HAS A ONE YEAR WARRANTY'
                },
                {
                  options: {
                    padding: [6, 36, 6],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '8. DUE TO FLUCTUATING PRICES OF STEEL, ALUMINUM, AND COPPER. QUOTES WILL EXPIRE AFTER TWENTY DAYS OF RECEIVING. DUE TO SECTION 232 PLACED INTO ACTION BY FEDERAL GOVERNMENT, WE CAN ONLY HOLD PRICING AS INDICATED.'
                },
                // to stick TOC in one page
                {
                  options: {
                    padding: 100
                  },
                  contents: []
                }
              ]
            }
          ]
        }
      ]
    }
  }
});

export default thermotechPDFForm;
