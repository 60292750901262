import InstructionHelper from '../InstructionHelper';
import { removeNestedJson } from '../../utils';

const roleSchema = (permission, company) => {
  const data = [{ ...removeNestedJson(company) }, { ...permission }];
  const ins = new InstructionHelper();
  const { instructionSet } = ins.oneToMany(0, 1);
  return { data: data, instructionSet: instructionSet };
};

export default roleSchema;
