import { useEffect, useState } from 'react';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import { generateCompanySortKey } from 'services/core/utils';
import getPricebookForCompany from 'services/core/graphql/common/queries/getPricebooksForCompany';
import { logErrorWithCallback } from 'utils';

const usePricebooks = (tenantId, companyId, snackbarOn) => {
  const [priceBooks, setPriceBooks] = useState([]);

  const { loading, data, error } = useExtendedQuery(getPricebookForCompany, {
    variables: {
      partitionKey: tenantId,
      sortKey: generateCompanySortKey(tenantId, companyId),
      nextToken: null,
      filter: {},
      sort: [
        {
          sortField: 'createdDate',
          sortDirection: 'desc'
        }
      ]
    }
  });

  useEffect(() => {
    if (error) {
      logErrorWithCallback(
        error,
        snackbarOn,
        'Unable to fetch pricebook information, please try again later'
      );
    }

    if (data) {
      setPriceBooks(data.getCompany.priceBooks.items);
    }
  }, [data, error, snackbarOn]);

  return {
    loading,
    data: priceBooks
  };
};

export default usePricebooks;
