import gql from 'graphql-tag';

const updateNote = gql`
  mutation updateNote($partitionKey: String!, $data: UpdateNoteInput!) {
    updateNote(partitionKey: $partitionKey, data: $data) {
      id
      version
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      subject
      note
      createdBy
      lastUpdatedDate
      lastUpdatedDateTime
      lastUpdatedBy
    }
  }
`;

export default updateNote;
