import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  SgtCustomerSearch as CustomerSearch,
  SgtPropertySearch as PropertySearch
} from 'components';
import { Modal, Button, ThemeProvider, ButtonType, SgtForm } from '@buildhero/sergeant';
import { useTheme } from '@material-ui/core';
import { SyncStatus, ExportStatus } from 'utils/constants';
import Skeleton from 'react-loading-skeleton';
import { snackbarOn } from 'redux/actions/globalActions';
import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import { GetEditConfiguration, exportConfirmContent } from './Adjustment.config';
import GetValidation from './Adjustment.validation';
import { InputOrField, AdjustmentApplication, AdjustmentItem } from './components';
import { handleFieldChange, formatAdjustmentData } from './Adjustment.utils';
import { useUpsertAdjustment } from './Adjustment.gql';

const SgtFormWrapper = React.memo(SgtForm);

function AdjustmentModal({ user, snackbar, open, onClose, data: givenData }) {
  const { spacing } = useTheme();
  const [upsert, { loading, error }] = useUpsertAdjustment();
  const [formService, setFormService] = useState();
  const [data, setData] = useState();
  const [lastClickedButton, setLastClickedButton] = useState();
  const confirmContext = useConfirmModal();

  useEffect(() => {
    const getFormattedData = async () => setData(await formatAdjustmentData(givenData, user));
    getFormattedData();
  }, [givenData, user]);

  useEffect(() => {
    if (error) snackbar('error', `Error updating adjustment: ${error?.message}. Please try again.`);
  }, [error, snackbar]);

  const configuration = useMemo(() => data && GetEditConfiguration(data), [data]);

  const handleSave = useCallback(
    async e => {
      const buttonName = e.currentTarget.name;
      setLastClickedButton(buttonName);
      if (buttonName === 'post') {
        if (!(await confirmContext.confirm(exportConfirmContent))) return;
        formService.formikContext.setFieldValue('syncStatus', SyncStatus.SYNCING);
        formService.formikContext.setFieldValue('exportStatus', ExportStatus.POSTED);
      }
      // since setFieldValue is async, we wait to make sure the new value
      // is included in the submit action
      // https://github.com/formium/formik/issues/529
      setTimeout(() => formService.formikContext.handleSubmit(), 1);
    },
    [formService]
  );

  const handleSubmit = useCallback(
    async newData => {
      const {
        data: {
          upsertAdjustment: { id }
        }
      } = await upsert(newData);
      onClose(id);
    },
    [upsert, onClose]
  );

  const sgtForm = useMemo(
    () =>
      !data ? (
        <Skeleton height={900} />
      ) : (
        <SgtFormWrapper
          layout="default"
          configuration={configuration}
          initialValues={data}
          onCreateService={setFormService}
          onSubmit={handleSubmit}
          validationSchema={GetValidation(data.accountingApp)}
          formikProps={{
            validateOnChange: false,
            validateOnBlur: true
          }}
          customComponents={{
            CustomerSearch,
            PropertySearch,
            InputOrField,
            AdjustmentApplication,
            AdjustmentItem
          }}
          onFieldChange={handleFieldChange}
        />
      ),
    [configuration, data, handleSubmit]
  );

  return (
    <ThemeProvider>
      <Modal
        title={`${data?.id ? 'Edit' : 'New'} Adjustment`}
        open={open}
        onClose={() => onClose()}
        actions={
          <div style={{ display: 'flex', flexDirection: 'row', gap: spacing(1) }}>
            <Button
              testingid="save-export-adjustment"
              type={ButtonType.SECONDARY}
              loading={loading && lastClickedButton === 'post'}
              disabled={loading}
              name="post"
              onClick={handleSave}
            >
              Save & Export Adjustment
            </Button>
            {![ExportStatus.POSTED, ExportStatus.EXPORTED].includes(data?.exportStatus) && (
              <Button
                testingid="save-adjustment"
                loading={loading && lastClickedButton === 'save'}
                disabled={loading}
                name="save"
                onClick={handleSave}
              >
                Save Adjustment
              </Button>
            )}
          </div>
        }
        fullScreen
      >
        {sgtForm}
      </Modal>
    </ThemeProvider>
  );
}

AdjustmentModal.propTypes = {
  user: PropTypes.object.isRequired, // redux
  snackbar: PropTypes.func.isRequired, // redux
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object
};

AdjustmentModal.defaultProps = {
  data: {
    number: null,
    billingCustomer: null,
    property: null,
    adjustmentType: null,
    transactionType: null,
    ledgerAccount: null,
    ledgerOffsetAccount: null,
    department: null,
    amount: null,
    availableAmount: 0,
    appliedAmount: 0,
    taxAmount: 0,
    taxLedgerAccount: null,
    taxRate: null,
    date: moment().unix(),
    creditCardTransaction: false,
    adjustmentItems: { items: [] },
    transactions: { items: [] },
    exportStatus: ExportStatus.PENDING
  }
};

export default connect(state => state, { snackbar: snackbarOn })(AdjustmentModal);
