import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { useSnackbar } from 'customHooks/useSnackbar';
import { logErrorWithCallback } from 'utils';

import { timesheetPeriodBindersFragment } from '../gql';

export const getTimesheetBindersByPeriodId = gql`
  query getTimesheetPeriodById($id: String!) {
    getTimesheetPeriodById(id: $id) {
      ${timesheetPeriodBindersFragment}
    }
  }
`;

const useTimesheetPeriod = ({ id }, options = {}) => {
  const snackbar = useSnackbar();

  const { data, loading, error, refetch } = useExtendedQuery(getTimesheetBindersByPeriodId, {
    variables: { id },
    transform: result => result?.getTimesheetPeriodById,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !id,
    ...options
  });

  if (error) {
    logErrorWithCallback(error, snackbar, 'Unable to fetch timesheet period');
  }
  return [data || {}, loading, error, refetch];
};

export default useTimesheetPeriod;
