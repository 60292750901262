import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Typography, TW } from '@buildhero/sergeant';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';

import { getTenantSettingValueForKey } from 'utils';
import withLazyMount from 'utils/withLazyMount';

import FeatureFlagsConstants from 'utils/FeatureFlagConstants';
import { ReportTableSectionDefaultProps, ReportTableSectionPropTypes } from '../../propTypes';

import ReportSection from '../ReportSection';

import TasksTable, { TaskTableLayout } from './TasksTable';
import { useStyles } from './TasksSection.styles';

import {
  useCompletedTasks,
  useRemainingTasks,
  useRecommendedTasks,
  usePendingTasks
} from './TasksSection.hooks';

const TasksSection = ({ visit, job, loading, error }) => {
  const ldFlags = useFlags();
  const styles = useStyles();
  const completedTasks = useCompletedTasks({ job, visit });
  const remainingTasks = useRemainingTasks({ job });
  const recommendedTasks = useRecommendedTasks({ job, visit });
  const pendingTasks = usePendingTasks({ job, visit });
  const isPendingTasksEnabled = getTenantSettingValueForKey('mobilePendingTasks') === 'true';
  const isPendingTasksTableEnabled = ldFlags[FeatureFlagsConstants.PENDING_TASKS_TABLE];

  return (
    <>
      <ReportSection title="Tasks">
        <div css={styles.iconHeader}>
          <CheckIcon css={styles.greenCheckIcon} />
          <Typography weight={TW.BOLD}>Tasks completed during this visit</Typography>
        </div>
        <TasksTable
          tasks={completedTasks}
          loading={loading}
          error={error}
          noDataMessage="No Tasks Completed"
        />
        <div css={[styles.iconHeader, styles.sectionSpacing]}>
          <WarningIcon css={styles.redWarningIcon} />
          <Typography weight={TW.BOLD}>Tasks remaining on this job</Typography>
        </div>
        <TasksTable
          tasks={remainingTasks}
          loading={loading}
          error={error}
          noDataMessage="No Tasks Remaining"
        />
      </ReportSection>
      <ReportSection title="Tasks Created On This Visit">
        <div css={styles.iconHeader}>
          <Typography weight={TW.BOLD}>Recommended Tasks</Typography>
        </div>
        <TasksTable
          tasks={recommendedTasks}
          loading={loading}
          error={error}
          taskTableLayout={TaskTableLayout.VISIT_TASK}
          noDataMessage="No Recommended Tasks"
        />
        {isPendingTasksEnabled && isPendingTasksTableEnabled && (
          <>
            <div css={[styles.iconHeader, styles.sectionSpacing]}>
              <Typography weight={TW.BOLD}>Pending Tasks</Typography>
            </div>
            <TasksTable
              tasks={pendingTasks}
              loading={loading}
              error={error}
              taskTableLayout={TaskTableLayout.VISIT_TASK}
              noDataMessage="No Pending Tasks"
            />
          </>
        )}
      </ReportSection>
    </>
  );
};

TasksSection.propTypes = ReportTableSectionPropTypes;
TasksSection.defaultProps = ReportTableSectionDefaultProps;

export default withLazyMount(TasksSection);
