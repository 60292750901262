import React from 'react';
import { makeStyles } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import * as AllIcons from '@material-ui/icons/';
import IconMap from 'meta/IconMap';

const useStyles = makeStyles(theme => ({
  listItemIcon: {
    color: theme.palette.grayscale(60),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  }
}));

const NamedIcon = props => {
  const { lookUpIconMap } = props;
  const classes = useStyles();
  const IconWithName = AllIcons[props.name];
  if (!IconWithName && !lookUpIconMap) return null;

  const renderIcon = () =>
    !lookUpIconMap ? (
      <IconWithName fontSize="small" />
    ) : (
      <IconMap icon={props.name} fontSize="small" />
    );

  return props.listItem ? (
    <ListItemIcon className={`${classes.listItemIcon} list-icon`}>{renderIcon()}</ListItemIcon>
  ) : (
    renderIcon()
  );
};

export default NamedIcon;
