import InstructionHelper from '../InstructionHelper';

const noteMutationSchema = record => {
  const note = record;
  if (note.note && note.note.trim() === '') {
    return {};
  }

  const keysToRemove = [
    '__typename',
    'parent',
    'createdDate',
    'createdDateTime',
    'createdBy',
    'lastUpdatedDate',
    'lastUpdatedDateTime',
    'lastUpdatedBy'
  ];

  const localNote = {};
  Object.keys(note).forEach(key => {
    if (!keysToRemove.includes(key) && note[key]) {
      localNote[key] = note[key];
    }
  });

  const { parent } = note;
  if (parent.__typename) {
    delete parent.__typename;
  }
  const data = [{ ...parent }, { ...localNote }];
  const ins = new InstructionHelper();
  const { instructionSet } = ins.oneToMany(0, 1);

  return { data: data, instructionSet: instructionSet };
};

export default noteMutationSchema;
