const styles = theme => ({
  banner: {
    height: 164,
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(5)
  },
  authSection: {
    width: 340,
    margin: theme.spacing(2)
  }
});

export default styles;
