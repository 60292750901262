const toMap = arr => {
  return arr.reduce(
    (map, record) => ({
      ...map,
      [record.id]: record
    }),
    {}
  );
};

export default toMap;
