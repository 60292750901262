import ENV from 'configs/env';
import configForEnvironment from 'configs/aws-exports';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import fileDownload from 'utils/fileDownload';
import { logErrorWithCallback } from 'utils';
import { serviceErrorMessage } from './constants';

/**
 * @param {Object} variables - in the backend, the export function will use
 *    the same query as the table query function. So we provide variables from
 *    the frontend here.
 *    Note that you must provide a dueDate variable as well for Customer and Property list exports.
 */
const exportToFile = async (exportFileName, variables, exportFunctionType, columns, snackbarOn) => {
  try {
    const data = {
      variables,
      columns,
      exportType: 'excel',
      exportFunction: exportFunctionType,
      exportFileName
    };
    const baseURL = configForEnvironment(ENV).appsync.aws_exportEndpoint;
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    const res = await axios({
      method: 'post',
      url: `${baseURL}/data/exportTable`,
      data,
      responseType: 'blob',
      headers: {
        Accept: '*/*',
        Authorization: token,
        'Content-Type': 'application/json'
      }
    });
    fileDownload(res, res.headers['content-type'], exportFileName);
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, serviceErrorMessage.TABLE);
  }
};

export default exportToFile;
