import { roundCurrency } from '@buildhero/math';
import { isEmpty } from 'lodash';

import { getDepartmentsByJob } from 'services/API/department';
import { EntityType } from 'utils/constants';

import { SelectedItem } from './constants';
import { unitCostValidate } from 'utils/unitCostValidation.js';

class ProcurementUtils {
  static handleSetDefaultValue = ({ searchResults, selection, isOpen, setSelection }) => {
    if (searchResults.length === 1 && isEmpty(selection) && !isOpen) {
      setSelection(searchResults[0]);
    }
  };

  static handleSetDeptDefaultValue = ({
    searchResults,
    selection,
    isOpen,
    setSelection,
    form,
    departmentsAreLimitedByJob
  }) => {
    const match = searchResults.find(result => result.id === form.values.depId);
    // Maintains the existing department if it also exists on the newly selected job, or if no job is selected (as then the search fn returns all departments)
    if (match && departmentsAreLimitedByJob) {
      setSelection(match);
    } else {
      this.handleSetDefaultValue({ searchResults, selection, isOpen, setSelection });
    }
  };

  static onFieldSelected = (field, data, type) => field.name === type && !isEmpty(data);

  static handleSearchSelect = (data, field, form, setSelectedEditItem, selectedEditItem) => {
    if (
      form.values.projectCostType !== SelectedItem.MATERIALS &&
      field.name !== SelectedItem.PROJECT_COST_TYPE
    ) {
      form.setFieldValue(SelectedItem.PROJECT_COST_TYPE, SelectedItem.MATERIALS);
    }

    // Logic to handle reseting fields bc of progressive filtering: phase --> dept --> cost code --> cost type
    if (this.onFieldSelected(field, data, SelectedItem.PROJECT_PHASE)) {
      form.setFieldValue(SelectedItem.PHASE_DEPARTMENT, null);
      form.setFieldValue(SelectedItem.PROJECT_COST_CODE, null);
    } else if (this.onFieldSelected(field, data, SelectedItem.PHASE_DEPARTMENT)) {
      form.setFieldValue(SelectedItem.PROJECT_COST_CODE, null);
    }

    if (field.name === SelectedItem.JC_PHASE && field.value?.name !== data?.name) {
      form.setFieldValue(SelectedItem.JC_COST_TYPE, null);
      if (setSelectedEditItem)
        setSelectedEditItem({ ...selectedEditItem, JcPhase: data, JcCostType: null });
    }
  };

  static handleChangeUnitCost = ({ form, currentValue }) => {
    const { quantity } = form.values;
    const [newUnitCost, validationMessage] = unitCostValidate(currentValue);
    form.setFieldError('unitCost', validationMessage);
    form.setFieldValue('unitCost', newUnitCost);
    form.setFieldValue('totalCost', roundCurrency(String(currentValue * quantity)));
  };

  static handlePhaseSelection = (
    selection,
    _selectedItemName,
    form,
    setSelectedEditItem,
    selectedEditItem
  ) => {
    let modifiedValues;
    if (selection?.id) {
      // if phase is changed, force user to reselect cost code field
      if (form.values.projectPhase?.id !== selection.id) {
        modifiedValues = {
          ...form.values,
          projectPhase: selection,
          projectCostCode: null,
          projectCostType: 'materials',
          Department: null,
          departmentId: null,
          departmentName: null,
          phaseDepartmentId: null
        };
        form.setValues(modifiedValues);
        setSelectedEditItem({ ...selectedEditItem, ...modifiedValues });
      }
    } else {
      modifiedValues = {
        ...form.values,
        projectPhase: null,
        Department: null,
        departmentName: null,
        departmentId: null,
        projectCostCode: null,
        phaseDepartmentId: null,
        projectCostType: 'materials'
      };
      form.setValues(modifiedValues);
      setSelectedEditItem({ ...selectedEditItem, ...modifiedValues });
    }
  };

  static handleDepartmentSelection = (
    selection,
    _selectedItemName,
    form,
    setSelectedEditItem,
    selectedEditItem
  ) => {
    // handler is triggered on modal open without departmentId
    if (selection && !selection.departmentId) return;
    let modifiedValues;
    if (selection?.id) {
      if (form.values.departmentId !== selection.departmentId) {
        modifiedValues = {
          ...form.values,
          Department: selection.Department,
          departmentId: selection.departmentId,
          departmentName: selection.tagName,
          phaseDepartmentId: selection.id,
          projectCostCode: null
        };
        form.setValues(modifiedValues);
        setSelectedEditItem({ ...selectedEditItem, ...modifiedValues });
      }
    } else {
      modifiedValues = {
        ...form.values,
        Department: null,
        departmentId: null,
        departmentName: null,
        projectCostCode: null,
        phaseDepartmentId: null
      };
      setSelectedEditItem({ ...selectedEditItem, ...modifiedValues });
      form.setValues(modifiedValues);
    }
  };

  static handleCostCodeSelection = (
    selection,
    _selectedItemName,
    form,
    setSelectedEditItem,
    selectedEditItem
  ) => {
    if (selection?.id) {
      if (form.values.projectCostCode?.id !== selection.id) {
        const modifiedValues = {
          ...form.values,
          projectCostCode: selection,
          projectCostCodeId: selection.id,
          projectCostType: 'materials'
        };
        form.setValues(modifiedValues);
        setSelectedEditItem({ ...selectedEditItem, ...modifiedValues });
      }
    } else {
      const modifiedValues = {
        ...form.values,
        projectCostCode: null,
        projectCostCodeId: null,
        projectCostType: 'materials'
      };
      form.setValues(modifiedValues);
      setSelectedEditItem({ ...selectedEditItem, ...modifiedValues });
    }
  };

  static handleJobOrProjectIdChange = async (
    selectedItem,
    _selectedItemName,
    form,
    departmentsAreLimitedByJob,
    setSelectedEditItem,
    selectedEditItem
  ) => {
    const projectId = selectedItem?.projectName ? selectedItem?.id : null;
    const modifiedValues = {
      ...form.values,
      jobAndProject: { ...selectedItem },
      jobOrProjectDisplay:
        selectedItem?.customIdentifier ||
        selectedItem?.jobNumber ||
        selectedItem?.projectName ||
        selectedItem?.projectNumber ||
        '',
      ...(projectId && {
        projectPhase: null,
        projectCostCode: null,
        projectCostCodeId: null,
        projectCostType: 'materials',
        jobId: null,
        jobCostType: null,
        jobCostTypeId: null,
        costCode: null,
        costCodeId: null,
        revenueType: null,
        revenueTypeId: null,
        Department: null,
        departmentId: null,
        departmentName: null,
        projectId
      })
    };
    if (departmentsAreLimitedByJob && selectedItem?.entityType === EntityType.JOB) {
      const deptsOnSelectedJob = await getDepartmentsByJob(selectedItem.id);
      const defaultDepartment = deptsOnSelectedJob.length === 1 ? deptsOnSelectedJob[0] : null;
      modifiedValues.department = defaultDepartment;
      modifiedValues.jobId = selectedItem.id;
      modifiedValues.Department = null;
      modifiedValues.departmentId = null;
      modifiedValues.departmentName = null;
      modifiedValues.projectId = null;

      form.setValues(modifiedValues);
      setSelectedEditItem({ ...selectedEditItem, ...modifiedValues });
    } else {
      form.setValues(modifiedValues);
      setSelectedEditItem({ ...selectedEditItem, ...modifiedValues });
    }
  };
}

export default ProcurementUtils;
