import React from 'react';
import { Typography, TextField, InputAdornment } from '@material-ui/core';

const RangeInput = props => {
  const {
    handleOnChange,
    validateValues,
    addAdornment,
    inputClassName,
    adornmentClassName,
    value,
    index,
    keyName,
    type
  } = props;

  return (
    <>
      <TextField
        value={value}
        type={type}
        onChange={e => handleOnChange(keyName, e, index)}
        placeholder="-"
        onBlur={e => validateValues(keyName, e, index)}
        InputProps={{
          className: inputClassName,
          disableUnderline: true,
          startAdornment: addAdornment && (
            <InputAdornment className={adornmentClassName} position="start">
              <Typography variant="subtitle1"> $</Typography>
            </InputAdornment>
          )
        }}
      />
    </>
  );
};

export default RangeInput;
