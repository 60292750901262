import gql from 'graphql-tag';

const getTenantRepsByCustomerPropertyById = gql`
  query getTenantRepsByCustomerPropertyById($id: String!) {
    getCustomerPropertyById(id: $id) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      parentId
      parentSortKey
      tenantReps(entityConnection: "Employee") {
        items {
          id
          mappedEntityId
          mappedEntityType
          systemEntityId
          systemEntityType
          hierarchy
          partitionKey
          sortKey
          invertedSortKey
          mappedEntity {
            ... on Employee {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              firstName
              lastName
              name
              status
              notes(entityConnection: "Note") {
                items {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  subject
                  note
                  createdBy
                  lastUpdatedDate
                  lastUpdatedDateTime
                  lastUpdatedBy
                }
              }
              appRoles(entityConnection: "AppRole") {
                items {
                  id
                  mappedEntity {
                    ... on AppRole {
                      id
                      tagName
                    }
                  }
                }
              }
              createdDateTime
            }
          }
        }
      }
    }
  }
`;
export default getTenantRepsByCustomerPropertyById;
