import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useMachine } from '@xstate/react';
import { Checkbox, FormControlLabel, Grid, Typography, Box, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import { Field, FieldType, ThemeProvider } from '@buildhero/sergeant';
import Linkify from 'react-linkify';
import {
  CompanyService,
  ReviewReportService,
  TimesheetsService,
  InvoiceService
} from 'services/core';
import { Logger } from 'services/Logger';
import ErrorBoundaries from 'scenes/Error';
import {
  Context,
  PageHeader,
  AddRecordButton,
  SectionHeader,
  Modal,
  Spinner,
  SergeantModal,
  ConfirmModal,
  UserPermission
} from 'components';
import Labels from 'meta/labels';
import StorageService from 'services/StorageService';
import PurchasedItemLayout from 'meta/Jobs/Invoice/PurchasedItemForm';
import SummaryLayout from 'meta/Jobs/Invoice/SummaryFormSergeant';
import { snackbarOn, spinnerOn, spinnerOff } from 'redux/actions/globalActions';
import { PermissionConstants, FeatureGateConstants } from 'utils/AppConstants';
import {
  formatAddress,
  findAddressByAddressType,
  getTechniciansFromVisit,
  isTenantSettingEnabled,
  findLastUpdatedByAndDate
} from 'utils';
import invoiceLabels from 'meta/Jobs/Invoice/labels';
import FormSection from 'components/FormSection';
import _ from 'lodash';
import { PathName, DisplayName } from 'utils/constants';
import ResponsiveTable from 'components/ResponsiveTable';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import ReviewSummary from '../components/ReviewSummary';
import TechNotes from '../components/TechnicianNotes';
import AssetsWorkedOn from '../components/AssetsWorkedOn';
import Bills from '../components/Bills';
import OldBills from '../components/Bills_old';
import PhotosAndVideos from '../components/PhotosAndVideos';
import PartsAndMaterials from '../components/PartsAndMaterials';
import PurchaseOrders from '../components/PurchaseOrders';
import Timesheet from '../components/Timesheet';
import Discount from '../components/Discount';
import Fee from '../components/Fee';

import Signatures from '../components/Signature';
import TimeTracking from '../components/TimeTrackingTable';
import TimeTrackingReport from '../components/TimeTrackingReport';
import LabourLineItems from './LabourLineItems';
import ManualTimeTracking from '../components/ManualTimetracking';
import ReviewReportButtons from '../components/ReviewReportButtons';
import PageStateMachine from './state-model';
import styles from './styles';
import { visitListMeta } from './VisitsMeta';
import { useSetStopLoading } from './ReviewReport.hook';

const ReviewReport = props => {
  const companyService = new CompanyService();
  const reviewReportService = new ReviewReportService();
  const timesheetsService = new TimesheetsService();
  const storageService = new StorageService();
  const lastUpdatedMap = new Map();

  const { classes, location, jobPageData, reportSortKey, computedMatch, theme } = props;
  const flags = useFlags();

  /* Check if price books are active for the account .
  UI will be rendered accordingly */

  let defaultPriceBookId = '';
  let isPriceBookEnabled = false;
  let isDynamicFormsEnabled = false;
  let showTotalBudgetedHours = false;
  let uomList = null;
  const isAssetTrackingEnabled = isTenantSettingEnabled(FeatureGateConstants.ASSET_TRACKING);
  if (Context.getCompanyContext() && Context.getCompanyContext().getCompany) {
    uomList = Context.getCompanyContext().getCompany?.uoms?.items || [];
    defaultPriceBookId = Context.getCompanyContext().getCompany?.defaultPriceBookId;
    isPriceBookEnabled = isTenantSettingEnabled('pricebook');
    isDynamicFormsEnabled = isTenantSettingEnabled('dynamicForms');
    showTotalBudgetedHours = isTenantSettingEnabled('budgetLaborHoursAtJobLevel');
  }

  const revReportId = computedMatch && computedMatch.params && computedMatch.params.id;
  const jobData = location && location.state && location.state.jobPageData;

  const invoicingStateMachine = PageStateMachine.withContext({
    parentData: jobData || jobPageData || '',
    user: props.user,
    reduxActions: {
      snackbarOn: props.snackbarOn
    },
    services: {
      reviewReportService,
      companyService,
      storageService,
      timesheetsService,
      Logger
    },
    reportId: revReportId,
    reportSortKey,
    summaryNotes: [],
    inventoryParts: [],
    laborLineItems: [],
    attachments: [],
    visitAssets: [],
    discounts: [],
    fees: [],
    purchaseOrders: [],
    customerSignatures: [],
    timeSheets: [],
    timeCardLines: [],
    timeCardVisits: [],
    technicianNotes: [],
    modalRecord: {},
    modalRecordIndex: '',
    visitNo: '',
    version: '',
    id: '',
    openAddPurchaseItemPopup: false,
    imageUrl: '',
    image: '',
    buttonClicked: '',
    isPriceBookEnabled,
    defaultPriceBookId,
    showContent: false,
    uomList,
    timesheetEntries: [],
    nonVisitEvents: []
  });

  const [current, send, service] = useMachine(invoicingStateMachine);
  const setStopLoading = useSetStopLoading(service);
  const [isSticky, setSticky] = useState(false);
  const oldReport = useRef();
  const sectionHeaderRef = useRef();
  const [openMultipleVisits, setOpenMultipleVisits] = useState(false);
  const [multipleVisits, setMultipleVisits] = useState([]);
  const [visitsToInvoice, setVisitsToInvoice] = useState([]);
  const [finalInvoiceFlag, setFinalInvoiceFlag] = useState(false);

  useEffect(() => {
    if (oldReport && oldReport.current && oldReport.current !== props.reportId) {
      send('RELOAD', { reportId: props.reportId });
    }

    oldReport.current = props.reportId;
    send('LOADING');
    window.addEventListener('scroll', handleScroll);
  });

  const handleScroll = () => {
    const position = sectionHeaderRef.current && sectionHeaderRef.current.getBoundingClientRect();
    if (position && position.top && position.top <= 128 && !isSticky) {
      setSticky(true);
    } else if (position && position.top && position.top > 128 && isSticky) {
      setSticky(false);
    }
  };

  const { context, value } = current;

  const { jobInfoData, visit } = context || '';

  useEffect(() => {
    if (openMultipleVisits) {
      setVisitsToInvoice([visit.id]);
    }
  }, [openMultipleVisits, visit]);

  const propertyInfo = jobInfoData?.parentEntity || {};
  const customerInfo = jobInfoData?.parentEntity?.parentEntity || {};
  const billingCustomerInfo = jobInfoData?.billingCustomer || {};
  const jobType = jobInfoData?.jobTypeName || '-';
  const department = context?.visit?.departmentName || '-';

  if (jobInfoData && jobInfoData.customIdentifier && visit && visit.visitNumber) {
    document.title = `BuildOps - Review report - Job #${jobInfoData.customIdentifier} - ${visit.visitNumber}`;
  }

  /* Added by field for Technician Notes */
  const summaryNotes = context.summaryNotes && [...context.summaryNotes];

  if (summaryNotes) {
    summaryNotes.forEach(summaryNote => {
      const localItem = summaryNote;
      if (summaryNote.addedBy === '' || !summaryNote.addedBy) {
        localItem.addedBy = localItem.createdBy;
      }
    });
  }

  const { primaryTechs, lastUpdatedDateTime } = visit || {};
  const arraysToScan = [context.inventoryParts, summaryNotes];
  arraysToScan.forEach(items =>
    findLastUpdatedByAndDate(items, lastUpdatedMap, primaryTechs, lastUpdatedDateTime, 'addedBy')
  );
  if (_.isEmpty(lastUpdatedMap) && primaryTechs?.items?.length) {
    const name = `${primaryTechs.items[0].mappedEntity.firstName} ${primaryTechs.items[0].mappedEntity.lastName}`;
    lastUpdatedMap.set(name, lastUpdatedDateTime);
  }

  const PurchasedLayoutMeta = PurchasedItemLayout.entity.layouts.web;
  PurchasedLayoutMeta.buttons.cancel.action = () => send('CLOSE');

  const propertyAddressList = propertyInfo?.companyAddresses?.items || [];
  const propertyAddress = formatAddress(
    findAddressByAddressType(propertyAddressList, 'propertyAddress')
  );

  const billingCustomerAddressList = billingCustomerInfo?.companyAddresses?.items || [];
  const billingCustomerAddress = findAddressByAddressType(
    billingCustomerAddressList,
    'billingAddress'
  );
  const customerAddressList = customerInfo?.companyAddresses?.items || [];
  const customerAddress = findAddressByAddressType(customerAddressList, 'billingAddress');

  const billingAddressObject = billingCustomerAddress || customerAddress || {};
  const billingAddress = formatAddress(billingAddressObject, true);

  const handleRowActions = (mode, record) => {
    const visitIds = record.map(item => item.id);
    setVisitsToInvoice(visitIds);
  };

  const toggleInvoiceFlag = () => {
    setFinalInvoiceFlag(!finalInvoiceFlag);
  };

  const createInvoice = async () => {
    const { user } = props;
    const invoiceService = new InvoiceService();
    try {
      const { data } = await invoiceService.createInvoiceFromVisits(jobInfoData.tenantId, {
        tenantCompanyId: user.tenantCompanyId,
        jobId: jobInfoData.id,
        isFinalInvoice: finalInvoiceFlag,
        visitIds: visitsToInvoice
      });

      if (data && data.createInvoiceFromVisits) {
        props.history.push(`/invoice/view/${data.createInvoiceFromVisits.id}`, {
          recordSortKey: data.createInvoiceFromVisits.sortKey
        });
      }
    } catch (error) {
      Logger.error(error);
      let message;
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        message = error.graphQLErrors[0].message;
      } else if (error.message) {
        message = error.message;
      } else {
        message = 'Unabled to create invoice, please try again later';
      }
      props.snackbarOn('error', message);
    }
  };

  let FlaggedComponent;

  if (flags[FeatureFlags.PROCUREMENT_USAGE] && flags[FeatureFlags.PROCUREMENT]) {
    FlaggedComponent = (
      <Bills
        classes={classes}
        current={current}
        send={send}
        service={service}
        user={props.user}
        history={props.history}
        isReviewReport
      />
    );
  } else if (flags[FeatureFlags.PROCUREMENT]) {
    FlaggedComponent = (
      <OldBills
        classes={classes}
        current={current}
        send={send}
        service={service}
        user={props.user}
        history={props.history}
        isReviewReport
      />
    );
  }

  // TODO optimize the state model
  const showConfirmModal =
    value?.bill_line_delete ||
    value?.delete_summary_confirmation ||
    value?.delete_inventory_confirmation ||
    value?.delete_labor_line_confirmation ||
    value?.delete_discount_confirmation ||
    value?.delete_attachment_confirmation ||
    value?.delete_po_confirmation ||
    value?.purchaseLine_deleted ||
    value?.delete_fee_confirmation ||
    false;

  let summaryTitle = ' ';
  if (value?.job_summary_new) summaryTitle = 'Add Summary Note';
  if (value?.job_summary_edited) summaryTitle = 'Edit Summary Note';
  return context.showContent ? (
    <ErrorBoundaries>
      <UserPermission I="read" action={PermissionConstants.OBJECT_REVIEWREPORT}>
        {jobInfoData && jobInfoData.jobNumber && (
          <PageHeader
            title={`Reviewed report for ${
              jobInfoData.jobTypeInternal === 'Maintenance'
                ? DisplayName.MAINTENANCE
                : DisplayName.JOB
            } ${jobInfoData.customJobNumber}`}
            breadcrumbsArray={[
              { title: 'Operations', link: '' },
              { title: Labels.reviewReport[props.user.locale], link: '/reviewreport/list' },
              {
                link:
                  jobInfoData.jobTypeInternal === 'Maintenance'
                    ? `/${PathName.MAINTENANCE}/view/${encodeURIComponent(
                        jobInfoData.jobNumber || ''
                      )}`
                    : `/${PathName.JOB}/view/${encodeURIComponent(jobInfoData.jobNumber || '')}`,
                title: `${
                  jobInfoData.jobTypeInternal === 'Maintenance'
                    ? DisplayName.MAINTENANCE
                    : DisplayName.JOB
                }  ${jobInfoData.customJobNumber || ''}`
              }
            ]}
          />
        )}
        <Box
          display="flex"
          flexDirection="row"
          paddingBottom={2}
          className={isSticky ? classes.stickyStyle : ''}
        >
          <Field
            value={`Reviewed Report for ${
              jobInfoData.jobTypeInternal && jobInfoData.jobTypeInternal === 'Maintenance'
                ? DisplayName.MAINTENANCE
                : DisplayName.JOB
            } ${jobInfoData && jobInfoData.customJobNumber} (Visit ${context.visit.visitNumber})`}
          />
          <Field color={theme.palette.grayscale(60)} value={`   |   `} />
          <Field type={FieldType.DATETIME} value={+context.visit.scheduledFor * 1000} />
        </Box>
        <Box style={isSticky ? { paddingTop: '46px' } : {}}>
          <Grid container spacing={1} ref={sectionHeaderRef}>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <ReviewReportButtons
                current={current}
                send={send}
                classes={props.classes}
                spinnerOn={props.spinnerOn}
                spinnerOff={props.spinnerOff}
                history={props.history}
                billingAddressObject={billingAddressObject}
                lastUpdatedMap={lastUpdatedMap}
                setOpenMultipleVisits={setOpenMultipleVisits}
                setMultipleVisits={setMultipleVisits}
              />
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <ThemeProvider>
                <Grid container className={classes.reportInfoRow}>
                  <Grid item xs={2}>
                    <Field
                      label="Customer:"
                      type={FieldType.TEXT}
                      value={customerInfo.customerName}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Field
                      label="Property:"
                      type={FieldType.TEXT}
                      value={propertyInfo.companyName}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      label="Billing address:"
                      type={FieldType.TEXT}
                      value={billingAddress ?? '-'}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      label="Property address:"
                      type={FieldType.TEXT}
                      value={propertyAddress ?? '-'}
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.reportInfoRow}>
                  <Grid item xs={2}>
                    <Field
                      label="Project manager:"
                      type={FieldType.TEXT}
                      value={jobInfoData?.owner?.name ?? '-'}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Field
                      label="Technician(s):"
                      type={FieldType.TEXT}
                      value={getTechniciansFromVisit(visit?.primaryTechs, visit?.extraTechs) ?? '-'}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field label="Department:" type={FieldType.TEXT} value={department} />
                  </Grid>
                  <Grid item xs={4}>
                    <Field label="Job Type:" type={FieldType.TEXT} value={jobType} />
                  </Grid>
                </Grid>
                <Grid container className={classes.reportInfoRow}>
                  <Grid item xs={4}>
                    <Field
                      label="Bill To:"
                      type={FieldType.TEXT}
                      value={propertyInfo?.billTo || billingCustomerAddress?.billTo || '-'}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      label="Billing Customer:"
                      type={FieldType.TEXT}
                      value={billingCustomerInfo?.customerName || '-'}
                    />
                  </Grid>
                </Grid>
                {showTotalBudgetedHours && (
                  <Grid item xs={2}>
                    <Field
                      label={`Total Budgeted Hours for ${jobInfoData.jobTypeInternal || 'Job'}:`}
                      type={FieldType.TEXT}
                      value={jobInfoData?.totalBudgetedHours}
                    />
                  </Grid>
                )}
              </ThemeProvider>
              <Linkify>
                <Grid>
                  <Typography className={classes.sectionTitle}>Job description</Typography>
                  <Divider variant="fullWidth" classes={{ root: classes.blueDivider }} />
                  <Typography className={classes.addresses}>
                    {jobInfoData?.issueDescription ?? '-'}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography className={classes.sectionTitle}>Visit description</Typography>
                  <Divider variant="fullWidth" classes={{ root: classes.blueDivider }} />
                  <Typography className={classes.addresses}>{visit?.description ?? '-'}</Typography>
                </Grid>
                <Typography className={classes.sectionTitle}>Visit summary</Typography>
                <Grid container direction="row" justify="space-between" alignItems="center">
                  <Grid item>
                    {!context.freezeEdit && (
                      <AddRecordButton
                        label="+ Add summary note"
                        handle={() => send('ADD_JOB_SUMMARY')}
                      />
                    )}
                  </Grid>
                  <Grid item>
                    <Typography className={classes.includeInvoice}>Include in invoice</Typography>
                  </Grid>
                </Grid>
                <Divider variant="fullWidth" classes={{ root: classes.blueDivider }} />
                {context.summaryNotes?.map((item, index) => (
                  <ReviewSummary
                    summary={item}
                    defChecked={item.includeInInvoice || false}
                    editAction={() => send('EDIT_JOB_SUMMARY', { data: item, dataIndex: index })}
                    deleteAction={() =>
                      send('DELETE_JOB_SUMMARY', { data: item, dataIndex: index })
                    }
                    changeIncludeInvoice={checkBoxStatus =>
                      send('INCLUDE_SUMMARY_INVOICE', {
                        data: item,
                        dataIndex: index,
                        checked: checkBoxStatus
                      })
                    }
                    freezeEdit={context.freezeEdit}
                    key={`summary${item.id}`}
                  />
                ))}
                <TechNotes current={current} send={send} classes={classes} isReviewReport />
                {isAssetTrackingEnabled && (
                  <AssetsWorkedOn classes={classes} current={current} send={send} isReviewReport />
                )}
                {isDynamicFormsEnabled && jobInfoData?.sortKey && (
                  <Grid>
                    <Typography className={classes.sectionTitle}>Forms</Typography>
                    <Divider variant="fullWidth" classes={{ root: classes.blueDivider }} />
                    {visit && (
                      <FormSection
                        parent={{
                          entityType: 'Job',
                          sortKey: jobInfoData.sortKey,
                          id: jobInfoData.id
                        }}
                        filter={{
                          integerFilters: [
                            {
                              fieldName: 'Visit.VisitNumber',
                              filterInput: { eq: visit.visitNumber }
                            }
                          ]
                        }}
                        caslKey={[PermissionConstants.OBJECT_JOB]}
                        readOnly
                        hideHeader
                      />
                    )}
                  </Grid>
                )}
              </Linkify>
              <PhotosAndVideos
                classes={classes}
                current={current}
                send={send}
                service={service}
                isReviewReport
              />
              <Signatures
                classes={classes}
                current={current}
                send={send}
                user={props.user}
                isReviewReport
              />
              <PartsAndMaterials
                classes={classes}
                current={current}
                send={send}
                user={props.user}
                service={service}
                isReviewReport
                snackbarOn={props.snackbarOn}
              />
              <Grid item style={{ paddingTop: 50, paddingBottom: 30, paddingLeft: 5 }}>
                {context.visit && context.visit.departmentName && (
                  <Chip
                    label={context.visit.departmentName}
                    className={classes.departmentsChips}
                    key={context.visit.id}
                  />
                )}
              </Grid>
              <Grid item>
                <Typography className={classes.greySectionTitle}>
                  {invoiceLabels.purchasedItems[props.user.locale]}
                </Typography>
                <PurchaseOrders
                  classes={classes}
                  current={current}
                  send={send}
                  service={service}
                  user={props.user}
                  snackbarOn={props.snackbarOn}
                  isReviewReport
                  isProcurementEnabled={flags[FeatureFlags.PROCUREMENT]}
                />
                {FlaggedComponent}
              </Grid>
              <Typography className={classes.sectionTitle}>
                {invoiceLabels.labour[props.user.locale]}
              </Typography>
              <Divider variant="fullWidth" classes={{ root: classes.blueDivider }} />
              <TimeTracking timesheetEntries={context.timesheetEntries} context={context} />
              <ManualTimeTracking context={context} />
              <Timesheet classes={classes} current={current} user={props.user} />
              <TimeTrackingReport context={context} user={props.user} classes={classes} />
              <Grid className={classes.spacer} />
              <LabourLineItems
                current={current}
                send={send}
                context={context}
                reviewReportId={revReportId}
              />
              <Discount
                classes={classes}
                current={current}
                send={send}
                service={service}
                user={props.user}
                isReviewReport
              />
              <Fee
                classes={classes}
                current={current}
                send={send}
                service={service}
                user={props.user}
                isReviewReport
              />
              <Grid style={{ marginBottom: 40 }} />

              <SergeantModal
                open={value?.job_summary_new || value?.job_summary_edited || false}
                data={context.modalRecord}
                mode={value?.job_summary_new ? 'new' : 'edit'}
                formVersion="edit"
                layout={SummaryLayout}
                handlePrimaryAction={(values, stopLoading) => {
                  setStopLoading(stopLoading);
                  send('SAVE', { saveData: values });
                }}
                handleClose={() => send('CLOSE')}
                title={summaryTitle}
              />

              <Modal
                open={openMultipleVisits}
                handleClose={() => setOpenMultipleVisits(false)}
                width="1056"
              >
                <div>
                  <SectionHeader
                    style={{ fontSize: 18, paddingTop: 18 }}
                    title={`${Labels.createInvoiceFromJobs[props.user.locale]}`}
                    enablePadding
                  />
                  <Typography style={{ fontSize: 16, paddingBottom: 14 }}>Visit(s) :</Typography>
                  <ResponsiveTable
                    disableFilter
                    rowMetadata={visitListMeta}
                    data={multipleVisits}
                    noDataMsg="No tasks added to property"
                    rowActions={handleRowActions}
                    rowActionButtons={{
                      select: {
                        referenceKey: 'id',
                        defaultSelectedRow: visit
                      }
                    }}
                  />
                  <div style={{ paddingTop: 18, paddingBottom: 0 }}>
                    <FormControlLabel
                      control={<Checkbox onChange={toggleInvoiceFlag} />}
                      label={`${Labels.finalInvoice[props.user.locale]}`}
                      labelPlacement="start"
                    />
                  </div>
                  <Grid container style={{ paddingTop: 10 }} justify="flex-end" direction="row">
                    <Grid item style={{ paddingRight: 16 }}>
                      <Button
                        color="secondary"
                        variant="outlined"
                        className={classes.invoiceCancelButton}
                        onClick={() => setOpenMultipleVisits(false)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item style={{ paddingLeft: 16 }}>
                      <Button
                        className={classes.invoiceNextButton}
                        variant="contained"
                        color="secondary"
                        onClick={async () => {
                          setOpenMultipleVisits(false);
                          await createInvoice();
                        }}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Modal>

              <ConfirmModal
                open={showConfirmModal}
                confirm={values => send('CONFIRM', { saveData: values })}
                cancel={() => send('CANCEL')}
                message=""
              />
            </Grid>
          </Grid>
        </Box>
      </UserPermission>
    </ErrorBoundaries>
  ) : (
    <Spinner />
  );
};

export const styledReviewReport = withStyles(styles, { withTheme: true })(ReviewReport);

const mapStateToProps = state => ({
  user: state.user
});
const mapNewCustomerToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message)),
  spinnerOn: () => dispatch(spinnerOn()),
  spinnerOff: () => dispatch(spinnerOff())
});

const connectedReviewReport = connect(mapStateToProps, mapNewCustomerToProps)(styledReviewReport);

export default connectedReviewReport;
