import gql from 'graphql-tag';

const getInvoiceInfo = gql`
  query getInvoiceInfo($partitionKey: String!, $sortKey: String!) {
    getInvoice(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      sortKey
      jobNumber
      invoiceNumber
      invoicePdfUrl
      paymentTermName
      paymentTermValue
      issuedDate
      dueDate
      status
      isActive
      customerProvidedPONumber
      amountNotToExceed
      tenantCompanyAddress
      tenantCompanyPhone
      tenantCompanyName
      tenantCompanyLogoUrl
      tenantCompanyId
      tenantId
      subtotal
      taxableSubtotal
      createdBy
      createdDateTime
      syncLog
      syncStatus
      version
      companyAddresses(entityConnection: "CompanyAddress") {
        items {
          addressType
          addressLine1
          addressLine2
          city
          state
          zipcode
        }
      }
      subtotal
      taxableSubtotal
      salesTaxRate
      accountingRefIdOfSalesTaxRate
      totalAmount
      summaries(entityConnection: "Summary") {
        items {
          id
          sortKey
          includeInInvoice
          summary
          lastUpdatedDateTime
        }
      }
      attachments(entityConnection: "Attachment") {
        items {
          id
          fileUrl
          fileName
          customFileName
          includeInInvoice
        }
      }
      customerSignatures(entityConnection: "CustomerSignature") {
        items {
          id
          nameOfSignee
          signedDateTime
        }
      }
      notes(entityConnection: "Note") {
        items {
          id
          sortKey
          note
        }
      }
      termsOfService
      invoiceItems(entityConnection: "InvoiceItem") {
        items {
          id
          name
          description
          quantity
          unitCost
          unitPrice
          amount
          taxable
          lineItemType
          accountingRefIdOfEntity
          accountingRefIdOfClass
          createdBy
          version
        }
      }
      invoiceVisits(entityConnection: "InvoiceVisit") {
        items {
          visitId
        }
      }
    }
  }
`;

export default getInvoiceInfo;
