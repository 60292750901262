import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { snackbarOn } from 'redux/actions/globalActions';
import AddIcon from '@material-ui/icons/Add';
import StorageService from 'services/StorageService';
import { Logger } from 'services/Logger';
import Spinner from 'components/Spinners/CircularIndeterminate';
import styles from './styles';

const image = {
  url: '',
  title: '+ Upload attachment',
  name: ''
};

class ButtonBases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadPercentage: 0
    };
  }

  updateProgress = progress => {
    const { loaded, total } = progress;
    const percentage = (loaded / total) * 100;
    this.setState({
      uploadPercentage: Math.trunc(percentage)
    });
  };

  uploadFile = async (event, tenantId) => {
    const file = event.target.files[0];
    image.name = file.name;
    if (!file) {
      return;
    }

    try {
      const storageService = new StorageService();
      const filename = `${tenantId}/${Date.now()}-${file.name}`;
      const s3Key = await storageService.uploadFile(file, filename, this.updateProgress);
      return s3Key;
    } catch (error) {
      this.props.snackbarOn('error', `${error}`, error);
      Logger.info(`Error uploading image ${error}`);
    }
  };

  downloadFile = async fileName => {
    const storageService = new StorageService();
    const data = await storageService.getFile(fileName, false);
    return data;
  };

  render() {
    const { field, form, classes, specialbehaviour, ...rest } = this.props;
    const { uploadPercentage } = this.state;
    image.url = field.value;
    const { allowedTypes } = specialbehaviour || {};

    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={2}
        className={classes.attachment}
      >
        {field.value && (
          <Grid item xs={6} className={classes.attachmentFile}>
            <Typography variant="body1" color="inherit" style={{ paddingLeft: 10 }}>
              <Button
                variant="outlined"
                color="secondary"
                classes={{
                  outlinedSecondary: classes.buttonOutlinedSecondary
                }}
                onClick={() => this.downloadFile(form.values.fileUrl)}
              >
                {field.value}
              </Button>
            </Typography>
          </Grid>
        )}
        <Grid item xs={6}>
          <Button
            focusRipple
            key={image.title}
            focusVisibleClassName={classes.buttonOutlinedSecondary}
            style={image.style}
          >
            <label htmlFor={field.name} id="attachmentLabel">
              <input
                accept={allowedTypes || ''}
                type="file"
                id={field.name}
                style={{ visibility: 'hidden', display: 'none' }}
                onChange={async event => {
                  const logoURL = await this.uploadFile(event, rest.user.tenantId);
                  if (logoURL) {
                    form.setFieldValue('fileUrl', logoURL, false);
                    form.setFieldValue(field.name, image.name, false);
                  }
                }}
              />
              <Typography component="span" variant="body1">
                {!image.url && <AddIcon className={classes.linkIcon} />}
                {image.url ? 'Replace file' : rest.label}
              </Typography>
            </label>
            {uploadPercentage > 0 && uploadPercentage < 100 && (
              <Spinner completed={uploadPercentage} />
            )}
          </Button>
          {form.errors && form.errors[field.name] && (
            <Typography className={classes.errorMsg}>{form.errors[field.name]}</Typography>
          )}

          <br />
        </Grid>
      </Grid>
    );
  }
}
const styledAttachement = withStyles(styles)(ButtonBases);

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const connectedAttachment = connect(mapStateToProps, mapDispatchToProps)(styledAttachement);

export default connectedAttachment;
