import { useTheme } from '@material-ui/core/styles';
import { DispatchTheme, ElementSizes, ElementZIndex } from '@dispatch/Dispatch.styles';
import { isScheduledProjectVisitOrNonVisit } from './NonVisitCard.helpers';

const getCursor = ({ event, isOptimistic, draggableCursor }) => {
  if (isOptimistic) {
    return 'initial';
  }

  if (isScheduledProjectVisitOrNonVisit(event)) {
    return draggableCursor;
  }

  return 'initial';
};

export const useStyles = ({ event, backgroundColor, weekView, isOptimistic, isDragging }) => {
  const theme = useTheme();

  let width = event?.width || ElementSizes.cellWidth - 1;

  if (weekView) {
    if (isDragging) width = `calc((100vw - ${ElementSizes.techCellWidth}px) / 7)`;
    else width = '100%';
  }

  return {
    container: {
      backgroundColor,
      borderRadius: 4,
      overflow: 'hidden',
      position: weekView ? 'initial' : 'absolute',
      height: ElementSizes.cellHeight - 1,
      flex: weekView ? `0 0 ${ElementSizes.cellHeight - 1}px` : undefined,
      marginBottom: weekView ? 2 : 0,
      cursor: getCursor({ event, isOptimistic, draggableCursor: 'grab' }),
      textAlign: 'left',
      boxShadow: isDragging ? theme.shadows[4] : 'none',
      width,
      zIndex: ElementZIndex.eventCard
    },
    dragStart: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: 5,
      height: '100%',
      cursor: getCursor({ event, isOptimistic, draggableCursor: 'ew-resize' })
    },
    dragEnd: {
      position: 'absolute',
      right: 0,
      top: 0,
      width: 5,
      height: '100%',
      cursor: getCursor({ event, isOptimistic, draggableCursor: 'ew-resize' })
    },
    icon: {
      position: 'relative',
      top: theme.spacing(0.6),
      marginBottom: theme.spacing(0.4),
      marginLeft: theme.spacing(0.6),
      height: 15
    },
    name: {
      color: 'white',
      fontSize: 12,
      padding: '2px 6px',
      ...DispatchTheme.mixins.oneLineClamp
    },
    timeRange: {
      color: 'white',
      padding: '0 6px',
      ...DispatchTheme.mixins.oneLineClamp
    }
  };
};
