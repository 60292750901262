import gql from 'graphql-tag';
import { useSubscription } from '@apollo/client';

import { getState } from 'redux/store';

import { nonVisitDetailsFragment } from '@dispatch/fragments';

export const NON_VISIT_EVENT_SUBSCRIPTION = gql`
  subscription nonVisitEventUpdateNotification($partitionKey: String!) {
    nonVisitEventUpdateNotification(partitionKey: $partitionKey) {
      ${nonVisitDetailsFragment}
    }
  }
`;

export const useNonVisitEventSubscription = () => {
  const { tenantId } = getState().user;

  return useSubscription(NON_VISIT_EVENT_SUBSCRIPTION, {
    variables: { partitionKey: tenantId }
  });
};
