import React from 'react';
import { ProjectService } from 'services/core';
import { logErrorWithCallback } from 'utils';

export const getCostCodes = async (tenantId, companyId, snackbarOn) => {
  let responseData = [];
  try {
    const Service = new ProjectService();
    const response = await Service.getCostCodes(tenantId, companyId);
    responseData = response?.data?.getCompany?.costCodes?.items;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to fetch cost code, please try again later');
  }
  return responseData;
};

const useCostCode = (tenantId, tenantCompanyId, snackbarOn) => {
  const [costCodes, setCostCodes] = React.useState([]);
  const getData = async () => {
    const costCodeDetails = await getCostCodes(tenantId, tenantCompanyId, snackbarOn);
    setCostCodes(costCodeDetails);
  };
  React.useEffect(() => {
    if (tenantId && tenantCompanyId) {
      getData();
    }
    // Disable lint - getData function not needed
    // eslint-disable-next-line
  }, [tenantId, tenantCompanyId]);
  return [costCodes, setCostCodes, getData];
};

export default useCostCode;
