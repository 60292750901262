import { isEqual, find, filter, map, reduce, has } from 'lodash';
import { useEffect, useState } from 'react';
import Labels from 'meta/labels';
import moment from 'moment';
import * as yup from 'yup';

import entityRouteMappings from 'meta/entityRouteMappings';
import { CustomerService } from 'services/core';
import { Mode } from 'utils/constants';
import { ServiceAgreementStatus, AppConstants } from 'utils/AppConstants';
import { addressObjectToString } from 'utils';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import { getCrews } from './service';
import Context from '../../../components/Context';
import { BILLING_TYPES } from './constants';

export const getHeaderProps = (agreementName, locale) => ({
  breadcrumbsArray: [
    { link: '', title: 'Operations' },
    { link: '/serviceAgreement/list', title: Labels.serviceAgreements[locale] }
  ],
  pageMapKey: 'serviceAgreements',
  mode: 'edit',
  title: agreementName || '',
  userLocale: locale
});

export const getPaymentTermsOptions = [
  {
    label: 'Monthly',
    value: 'Monthly'
  },
  {
    label: 'Bimonthly',
    value: 'Bimonthly'
  },
  {
    label: 'Quarterly',
    value: 'Quarterly'
  },
  {
    label: 'Biannually',
    value: 'Biannually'
  },
  {
    label: 'Annually',
    value: 'Annually'
  }
];

export const APPROVE_BTN = {
  approve: {
    label: 'Approve Agreement'
  }
};

export const CANCEL_BTN = {
  cancel: {
    label: 'Cancel Agreement'
  }
};

export const EDIT_BTN = {
  edit: {
    label: 'Edit Agreement'
  }
};

export const CLONE_BTN = {
  clone: {
    label: 'Clone Agreement'
  }
};

export const RENEW_BTN = {
  renew: {
    label: 'Renew Agreement'
  }
};

export const getStatusBasedMoreActionBtns = ({ status, alreadyRenewed }) => {
  const actionStatusMapping = {
    [ServiceAgreementStatus.DRAFT]: { ...APPROVE_BTN, ...CANCEL_BTN, ...CLONE_BTN },
    [ServiceAgreementStatus.APPROVED]: { ...CANCEL_BTN, ...CLONE_BTN },
    [ServiceAgreementStatus.ACTIVE]: alreadyRenewed
      ? { ...CANCEL_BTN, ...CLONE_BTN }
      : { ...CANCEL_BTN, ...CLONE_BTN, ...RENEW_BTN },
    [ServiceAgreementStatus.CANCELLED]: CLONE_BTN,
    [ServiceAgreementStatus.CANCELED]: CLONE_BTN,
    [ServiceAgreementStatus.EXPIRED]: alreadyRenewed ? {} : RENEW_BTN
  };
  return actionStatusMapping[status];
};

const formatDate = (date, timezone) =>
  moment.tz(moment.unix(date), timezone).format(AppConstants.DATE_FORMAT);

export const getBillDateFormatted = (
  { firstBillDate, nextBillDate, lastBilledDate },
  companyTimeZone
) => {
  if (!firstBillDate) return null;

  const firstBillDateFormatted = formatDate(firstBillDate, companyTimeZone);

  const nextBillDateFormatted = nextBillDate && formatDate(nextBillDate, companyTimeZone);

  const lastBilledDateFormatted = lastBilledDate && formatDate(lastBilledDate, companyTimeZone);

  return {
    firstBillDateFormatted,
    nextBillDateFormatted,
    lastBilledDateFormatted,
    nextBillDate: nextBillDate || moment(nextBillDateFormatted).unix(),
    lastBilledDate: lastBilledDate || moment(lastBilledDateFormatted).unix()
  };
};

export const getStartEndDateFormatted = ({ endDate, startDate }, companyTimeZone) => {
  return {
    startDateFormatted: startDate ? formatDate(startDate, companyTimeZone) : null,
    endDateFormatted: endDate ? formatDate(endDate, companyTimeZone) : null
  };
};

export const updateServiceAgreementPayload = (fullFormData, isActivated) => {
  const nextBillDate = isActivated ? fullFormData.nextBillDate : null;

  const {
    id,
    companyId,
    customerId,
    billingType,
    billingCustomerId,
    startDate,
    endDate,
    paymentTerms,
    totalContractValue,
    annualContractValue,
    termPrice,
    agreementNumber,
    firstBillDate,
    customerPoNumber,
    markup,
    selectedProperties,
    contractPdfUrl,
    departmentId,
    soldById
  } = fullFormData;
  return {
    id,
    companyId,
    customerId,
    billingType,
    billingCustomerId,
    startDate,
    endDate,
    paymentTerms,
    totalContractValue: totalContractValue || null,
    annualContractValue: annualContractValue || null,
    termPrice: termPrice || null,
    agreementNumber,
    firstBillDate,
    customerPoNumber,
    markup,
    properties: selectedProperties,
    contractPdfUrl,
    nextBillDate,
    departmentId,
    soldById
  };
};

export const getDepartments = () => {
  const company = Context.getCompanyContext();
  return company?.getCompany?.departments?.items || [];
};

export const useCrews = () => {
  const [crews, setCrews] = useState([]);
  const fetchCrews = async () => {
    const { items } = await getCrews();
    setCrews(items);
  };

  useEffect(() => {
    fetchCrews();
  }, []);

  return crews;
};

export const filterTechniciansByDepartment = (technicians, departmentId) => {
  return filter(
    technicians,
    technician =>
      technician?.status === 'active' &&
      find(technician?.departments?.items, department => department?.id === departmentId)
  );
};

export const filterCrewsByDepartment = (crews, departmentId) => {
  return filter(crews, crew => crew?.department?.id === departmentId);
};

export const getAdditionalTechnicians = (employees, primaryTechnicianId) => {
  return filter(employees, employee => employee?.id !== primaryTechnicianId);
};

export const hasPreferredTechnician = data => {
  return (
    data?.departmentId ||
    data?.crewId ||
    data?.primaryTechnicianId ||
    data?.additionalTechnicianIds?.length
  );
};

export const crewHasSameTechniciansAsSelected = (
  crews,
  crewId,
  primaryTechnicianId,
  additionalTechnicianIds
) => {
  if (!crewId) {
    return true;
  }

  const crew = find(crews, ({ id }) => id === crewId) || {};
  const crewPrimaryTechId = crew.foreman?.id;
  const crewTechs = map(crew.techs, tech => tech?.id).filter(id => !!id);
  const selectedTechs = filter(additionalTechnicianIds, id => !!id);
  return (
    isEqual(crewTechs.sort(), selectedTechs.sort()) && primaryTechnicianId === crewPrimaryTechId
  );
};

export const mapById = items =>
  reduce(
    items,
    (result, item) => {
      if (item && item.id) {
        return { ...result, [item.id]: item };
      }
      return result;
    },
    {}
  );

export default getHeaderProps;

export const generateAttachmentPayload = ({
  id,
  version,
  customFileName,
  comment,
  fileName,
  type,
  isUploaded,
  fileUrl
}) => ({
  id,
  version,
  customFileName: customFileName || null,
  comment: comment || null,
  fileName,
  type,
  isUploaded,
  fileUrl
});

export const serviceAgreementValidation = yup.object().shape({
  startDate: yup
    .string()
    .nullable()
    .test('startDateValidation', 'N/A', function(val) {
      if (Number(val) < moment('1900-1-1').unix()) {
        return this.createError({ message: 'Start date must be after January 1, 1900' });
      }
      if (Number(val) > moment('3000-1-1').unix()) {
        return this.createError({ message: 'Start date must be before January 1, 3000' });
      }
      return true;
    }),
  endDate: yup
    .string()
    .nullable()
    .test('startDateValidation', 'N/A', function(val) {
      if (Number(val) < moment('1900-1-1').unix()) {
        return this.createError({ message: 'End date must be after January 1, 1900' });
      }
      if (Number(val) > moment('3000-1-1').unix()) {
        return this.createError({ message: 'End date must be before January 1, 3000' });
      }
      return true;
    }),
  departmentId: yup
    .string()
    .nullable()
    .required('Department is required.')
});

export const formatForMainSection = (
  serviceAgreementInfo,
  snackbar,
  isCancelled,
  companyTimeZone
) => {
  const {
    firstBillDateFormatted,
    nextBillDateFormatted,
    lastBilledDateFormatted,
    nextBillDate,
    lastBilledDate
  } = getBillDateFormatted(serviceAgreementInfo, companyTimeZone) || {};
  const { startDateFormatted, endDateFormatted } =
    getStartEndDateFormatted(serviceAgreementInfo, companyTimeZone) || {};

  const structuredData = {
    ...serviceAgreementInfo,
    firstBillDateFormatted,
    nextBillDateFormatted,
    lastBilledDateFormatted,
    nextBillDate,
    lastBilledDate,
    startDateFormatted,
    endDateFormatted,
    serviceAgreementId: serviceAgreementInfo?.id,
    paymentTermsLabel:
      serviceAgreementInfo?.paymentTerms &&
      getPaymentTermsOptions.find(item => item.value === serviceAgreementInfo?.paymentTerms)?.label,
    markup: serviceAgreementInfo?.markup || 0, // added here as workaround. to be fixed in forms
    contract: serviceAgreementInfo?.attachments?.items?.filter(item => item.type === 'Contract'),
    snackbar,
    isCancelled,
    soldByName: serviceAgreementInfo.soldBy?.name
  };
  if (has(BILLING_TYPES, serviceAgreementInfo?.billingType)) {
    structuredData.billingTypeValue = BILLING_TYPES[serviceAgreementInfo?.billingType];
  }
  return structuredData;
};

export const formatForLeftSection = (
  { customer, billingCustomerId, billingCustomer, propertiesJSON, id },
  mode,
  user,
  snackbar,
  isCancelled
) => {
  const getRoute = (entityId, entityType) => {
    const baseRoute = entityRouteMappings[entityType];
    if (!baseRoute || !entityId) return undefined;
    return baseRoute + entityId;
  };

  const billingAddressObject = billingCustomer?.companyAddresses?.items?.find(
    address => address.addressType === 'billingAddress'
  );
  const billingAddress = addressObjectToString(billingAddressObject);
  const customerRoute = getRoute(customer?.id, 'Customer');
  const billingCustomerRoute = getRoute(billingCustomer?.id, 'Customer');

  const selectedCustomerProperties = (propertiesJSON && JSON.parse(propertiesJSON)) || [];
  const customerData = {
    ...customer,
    taxRateLabel: customer?.taxRate
      ? `${customer.taxRate.name} - ${customer.taxRate.taxRate}`
      : null
  };

  return {
    customerData,
    customer: { label: customer?.customerName, path: customerRoute },
    billingCustomer:
      mode === Mode.VIEW
        ? { label: billingCustomer?.customerName, path: billingCustomerRoute }
        : billingCustomer?.customerName,
    billingCustomerId,
    sameAsCustomer: customerData?.id === billingCustomerId,
    billingAddress,
    // required for attachment mutations
    serviceAgreementId: id,
    tenantId: user.tenantId,
    selectedProperties: selectedCustomerProperties,
    properties: constructSelectOptions(
      customer?.customerPropertiesView?.items,
      'companyName',
      'id'
    ),
    snackbar,
    isCancelled
  };
};

export const setSameAsCustomer = (value, form) => {
  const { customerName, id, companyAddresses } = form.values?.customerData;
  const billingAddressObject = companyAddresses?.items?.find(
    address => address.addressType === 'billingAddress'
  );
  const billingAddress = addressObjectToString(billingAddressObject);
  form.setValues({
    ...form.values,
    sameAsCustomer: value,
    billingCustomerId: value ? id : null,
    billingCustomer: value ? customerName : '',
    billingAddress: value ? billingAddress : ''
  });
};

export const setBillingAddress = async (value, valueName, form, field) => {
  if (!value) {
    form.setValues({
      ...form.values,
      billingAddress: '',
      billingCustomerId: null,
      sameAsCustomer: false,
      [field.name]: valueName
    });
    return;
  }
  const customerService = new CustomerService();
  const { data } = await customerService.getCustomerBillingAddress(
    value.partitionKey,
    value.sortKey
  );
  const billingAddressObject = data?.getCustomer?.companyAddresses?.items?.find(
    address => address.addressType === 'billingAddress'
  );
  const billingAddress = addressObjectToString(billingAddressObject);
  const { id } = form.values?.customerData;
  form.setValues({
    ...form.values,
    billingAddress,
    billingCustomerId: value.id,
    sameAsCustomer: value.id === id,
    [field.name]: valueName
  });
};
