import { axiosInstancePMAPI as instance } from 'configs/http-client';
import { useExtendedFetchForPMAPI as useExtendedFetch } from 'customHooks/useExtendedFetch';
import { useSelector } from 'react-redux';

export const projectTimesheetsUrl = (projectId, ...args) =>
  ['timesheets', projectId, ...(args || [])].join('/');

export const useFetchProjectTimesheets = (url, options) => {
  const { tenantId, tenantCompanyId } = useSelector(state => state?.user) || {};
  return useExtendedFetch(
    {
      url,
      params: {
        tenantId,
        tenantCompanyId
      }
    },
    options
  );
};

export const updateProjectTimesheet = (url, data, params) => instance.put(url, data, { params });

export const createProjectTimesheetsBatch = (url, data, params) =>
  instance.post(`${url}/batch`, data, { params });
