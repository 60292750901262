import React, { useMemo, useCallback, useRef, useState, createContext, useContext } from 'react';
import { Modal, Button, ThemeProvider, ButtonType, Typography } from '@buildhero/sergeant';

const ConfirmModalContext = createContext();

const defaultContent = {
  body: 'Are you sure?',
  title: 'Confirm',
  buttonLabel: 'Confirm',
  buttonType: ButtonType.PRIMARY,
  cancelButtonLabel: 'Cancel',
  cancelButtonType: ButtonType.SECONDARY
};

export function ConfirmModalProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState();
  const resolver = useRef();

  const handleClose = useCallback(() => {
    if (resolver.current) resolver.current(false);
    setOpen(false);
  }, []);

  const handleConfirm = useCallback(() => {
    if (resolver.current) resolver.current(true);
    setOpen(false);
  }, []);

  const value = useMemo(
    () => ({
      confirm: c => {
        setContent(c);
        setOpen(true);
        return new Promise(resolve => {
          resolver.current = resolve;
        });
      }
    }),
    []
  );

  return (
    <ConfirmModalContext.Provider value={value}>
      {children}
      <ThemeProvider>
        <Modal
          title={content?.title ?? defaultContent.title}
          open={open}
          onClose={handleClose}
          actions={
            <div style={{ width: '100%' }}>
              <Button name="confirm" onClick={handleConfirm} type={content?.buttonType} fullWidth>
                {content?.buttonLabel ?? defaultContent.buttonLabel}
              </Button>
              {content?.cancelButtonLabel && (
                <Button
                  style={{ marginTop: 8 }}
                  onClick={handleClose}
                  type={content?.cancelButtonType ?? defaultContent.cancelButtonType}
                  fullWidth
                >
                  {content?.cancelButtonLabel ?? defaultContent.cancelButtonLabel}
                </Button>
              )}
            </div>
          }
        >
          <Typography>{content?.body ?? defaultContent.body}</Typography>
          {content?.body2 && (
            <div style={{ marginTop: 8 }}>
              <Typography>{content?.body2}</Typography>
            </div>
          )}
        </Modal>
      </ThemeProvider>
    </ConfirmModalContext.Provider>
  );
}

export const useConfirmModal = () => useContext(ConfirmModalContext);
