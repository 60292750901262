/* eslint-disable no-underscore-dangle */
import React from 'react';
import { withTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { MUIForm } from '@buildhero/sergeant';
import { Logger } from 'services/Logger';
import { Modal, ConfirmModal } from 'components';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { FieldLabel } from 'components/BuildHeroFormComponents/Texts';
import defaultTruckform from 'meta/Inventory/defaultTruckform';
import { createTruck, updateTruck, getAllBundles } from '../truck-service';

function NewTruck(props) {
  const {
    data,
    activeEmployees,
    unassignedEmployees,
    openTruckForm,
    handleClose,
    refetchData,
    user,
    theme
  } = props;

  const [bundleList, setBundleList] = React.useState();
  const [confirmModalSetting, setConfirmModalSetting] = React.useState({});
  const [spinnerStatus, setSpinnerStatus] = React.useState(false);
  let service;

  const employeeOptions = (unassignedEmployees || []).map(emp => ({
    label: emp.name || `${emp.firstName} ${emp.lastName}`,
    value: emp.id
  }));

  React.useEffect(() => {
    const fetchBundles = async () => {
      const response = await getAllBundles(user);
      if (response?.items) setBundleList(response.items);
    };
    if (!bundleList && user.tenantId) {
      fetchBundles();
    }
    // The fetch query to be run only on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bundlesAsOption = (bundleList || []).map(bundle => ({
    label: bundle.name,
    value: bundle.id
  }));

  const getFormattedData = passedData => {
    if (passedData) {
      return {
        id: passedData.id || '',
        version: passedData.version || '',
        truckName: passedData.name || '',
        bundleId: passedData.productBundleId || '',
        employeeId: passedData.truckEmployees.map(truckEmp => truckEmp?.employee?.id) || []
      };
    }
    return {};
  };

  // after the confirmation box, we need to kick off mutation and handle errors
  const updateTruckPassThroughFn = async filledData => {
    try {
      await updateTruck(filledData, user.tenantId);
      refetchData();
      handleClose();
      props.snackbarOn('success', `Successfully updated truck - ${filledData.truckName}`);
      setSpinnerStatus(false);
    } catch (err) {
      Logger.error(err);
      props.snackbarOn(
        'error',
        `Unable to update truck - ${filledData.truckName}, please try again later`
      );
    }
  };

  const handleFormComplete = async filledData => {
    try {
      if (filledData?.id) {
        const productBundleIdFromForm = data.productBundleId || '';
        if (filledData.bundleId !== productBundleIdFromForm) {
          return setConfirmModalSetting({
            confirmMessage:
              'Warning: are you sure you want to change the truck template? Doing so will erase all inventory currently assigned to the truck',
            open: true,
            override: true,
            cancelAction: () =>
              setConfirmModalSetting({
                open: false,
                confirmMessage: '',
                override: true
              }),
            action: async () => {
              await updateTruckPassThroughFn(filledData);
              setConfirmModalSetting({
                open: false,
                confirmMessage: '',
                override: true
              });
            }
          });
        }
        return updateTruckPassThroughFn(filledData);
      }

      const response = await createTruck(filledData, user.tenantId, user.tenantCompanyId);

      if (response?.data) {
        handleClose();
        props.snackbarOn('success', `Successfuly saved truck - ${filledData.truckName}`);
        refetchData();
        setSpinnerStatus(false);
      }
    } catch (err) {
      Logger.error(err);
      props.snackbarOn(
        'error',
        `Unable to save truck - ${filledData.truckName}, please try again later`
      );
    }
  };

  const formattedData = getFormattedData(data);

  const getTruckMeta = currentTruckRecord => {
    let localEmployeeOptions = employeeOptions || [];
    const currentTruckEmployees = activeEmployees.filter(emp =>
      (currentTruckRecord?.employeeId || []).includes(emp.id)
    );

    if (currentTruckEmployees) {
      const currentlyAssignedOptions = currentTruckEmployees.map(emp => ({
        label: emp.name || `${emp.firstName} ${emp.lastName}`,
        value: emp.id
      }));
      localEmployeeOptions = localEmployeeOptions.concat(currentlyAssignedOptions || []);
    }
    return defaultTruckform(bundlesAsOption, localEmployeeOptions);
  };

  return (
    <ErrorBoundaries>
      <Modal
        open={openTruckForm || false}
        width="500"
        height="318"
        showModalHeader
        modalTitle={formattedData?.id ? 'Edit truck' : 'New truck'}
        handleClose={() => handleClose()}
        spinnerStatus={spinnerStatus}
        buttons={{
          cancel: {
            buttonType: 'contained',
            color: 'secondary',
            label: 'Cancel',
            action: () => handleClose(),
            style: {
              backgroundColor: theme.palette.grayscale(20),
              color: theme.palette.grayscale(100),
              fontWeight: 500
            }
          },
          save: {
            buttonType: 'contained',
            color: 'secondary',
            action: () => {
              if (service) {
                setSpinnerStatus(true);
                service.submit();
              } else {
                Logger.error('Service not available in New truck creation');
              }
            },
            label: 'Save',
            style: {
              backgroundColor: theme.palette.other.genoa,
              color: theme.palette.grayscale(100),
              fontWeight: 500
            }
          }
        }}
      >
        <MUIForm
          configuration={getTruckMeta(formattedData)}
          data={formattedData}
          mode={formattedData.id ? 'edit' : 'new'}
          customComponents={{
            FieldLabel
          }}
          onCreateService={formService => {
            if (formService) service = formService;
          }}
          onComplete={async completedData => {
            handleFormComplete(completedData);
          }}
        />
      </Modal>
      <ConfirmModal
        open={confirmModalSetting.open || false}
        confirm={confirmModalSetting.action}
        cancel={confirmModalSetting.cancelAction}
        message={confirmModalSetting.confirmMessage}
        override={confirmModalSetting.override || false}
      />
    </ErrorBoundaries>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const ReduxConnectedNewTruck = connect(mapStateToProps, mapDispatcherToProps)(NewTruck);

export default withTheme(ReduxConnectedNewTruck);
