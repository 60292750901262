import gql from 'graphql-tag';

const getAllInvoiceByCompanyForPayments = gql`
  query getCompany(
    $partitionKey: String!
    $sortKey: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
    $adjustmentsFlag: Boolean!
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      invoices: invoicesView(
        entityConnection: "Invoice"
        pagination: { limit: $limit, offset: $offset }
        filter: $filter
        sorting: $sort
      ) {
        nextToken
        items {
          id
          invoiceNumber
          adjustedBalance @include(if: $adjustmentsFlag)
          createdBy
          lastUpdatedDateTime
          issuedDate
          status
          totalAmount
          dueDate
          syncLog
          syncStatus
          invoicePdfUrl
          projectName
          projectId
          job(entityConnection: "Job") {
            id
            jobNumber
            status
            jobTypeInternal
            customIdentifier
          }
          createdDate
          customerProvidedPONumber
          customerProvidedWONumber
          amountNotToExceed
          balanceAmountComputed
          paymentInvoiceView(entityConnection: "PaymentInvoice") {
            items {
              parentId
              invoiceId
              appliedAmount
              deletedDate
            }
          }
        }
      }
    }
  }
`;

export default getAllInvoiceByCompanyForPayments;
