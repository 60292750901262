const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5),
    margin: '30px 0',
    width: '100%',
    color: theme.palette.grayscale(25),
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  scheduledText: {
    color: theme.palette.grayscale(60),
    fontSize: '12px',
    paddingLeft: '5px'
  },
  dateText: {
    color: theme.palette.grayscale(25),
    fontSize: '14px',
    paddingLeft: '20px',
    paddingTop: '5px'
  },
  left: {
    float: 'left'
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    float: 'left',
    width: '100%'
  }
});

export default styles;
