import moment from 'moment';
import React from 'react';
import { bool, func, number, object, shape } from 'prop-types';
import { MUIForm } from '@buildhero/sergeant';
import ErrorDisplay from 'components/SgtErrorDisplay';
import AccordionSection from '../../../AccordionSection';
import { useManDayCreateFormConfig } from './ManDayCreateForm.config';

const NUMBER_OF_EVENTS_TO_CREATE_THRESHOLD = 365;

const isWeekend = timestamp => {
  const SATURDAY = 6;
  const SUNDAY = 0;
  const date = moment.unix(timestamp);
  return date.weekday() === SATURDAY || date.weekday() === SUNDAY;
};

export const validateScheduling = values => {
  const errors = {};
  if (values?.fromTime >= values?.toTime) {
    errors.toTime = 'The end time of the event must be after its start';
  }
  if (values?.fromDate > values?.toDate) {
    errors.toDate = 'The end time of the event must be after its start';
  }

  const from = moment.unix(values?.fromDate).startOf('day');
  const to = moment.unix(values?.toDate).startOf('day');

  const numberOfItems = to.diff(from, 'days') + 1;
  if (numberOfItems > NUMBER_OF_EVENTS_TO_CREATE_THRESHOLD) {
    errors.toDate = 'Too many items to create. Please decrease the range';
  }

  return errors;
};

const ManDayCreateForm = ({
  data,
  disabled,
  onCreateService,
  onDirtyChange,
  onComplete,
  techsResponse,
  disableSave,
  setDisableSave
}) => {
  const { loading: techsLoading, data: techsData } = techsResponse;
  const techOptions = techsData?.map(tech => ({
    value: tech.id,
    label: tech.name
  }));
  const formConfig = useManDayCreateFormConfig({
    disabled,
    techsLoading,
    techOptions,
    disableSave
  });
  const customComponents = {
    AccordionSection,
    ErrorDisplay
  };

  const onFormChange = values => {
    setDisableSave(values?.excludeWeekends && isWeekend(values?.fromDate));
  };

  return (
    <>
      <MUIForm
        configuration={formConfig}
        customComponents={customComponents}
        data={data}
        onCreateService={onCreateService}
        onFormChange={onFormChange}
        onDirtyChange={onDirtyChange}
        onComplete={onComplete}
        validate={validateScheduling}
      />
    </>
  );
};

ManDayCreateForm.propTypes = {
  disabled: bool.isRequired,
  data: shape({
    start: number,
    end: number
  }).isRequired,
  onCreateService: func.isRequired,
  onDirtyChange: func.isRequired,
  onComplete: func.isRequired,
  techsResponse: object.isRequired,
  disableSave: bool.isRequired,
  setDisableSave: func.isRequired
};

export default ManDayCreateForm;
