export default isAssetEnabled => {
  return {
    'Quote Presets': { label: 'Default', value: 'default-value', isDefault: true },
    'Quote Mode': { 'Enable overrides': false },
    'Task Groups': { 'Show task group title': true, 'Show task group pricing': true },
    Tasks: {
      'Show task title': true,
      'Show task description': true,
      'Show task pricing': true,
      ...(isAssetEnabled && { 'Show asset': true }),
      Time: {
        'Show labor total': true,
        'Show itemized labor': true,
        'Show itemized labor pricing': true,
        'Show itemized labor quantity': true
      },
      Material: {
        'Show material total': true,
        'Show itemized material items': true,
        'Show itemized material item pricing': true,
        'Show itemized material quantity': true
      }
    },
    'Totals & Subtotals': {
      'Show task subtotal': true,
      'Show line item pricing': true,
      'Show discount': true,
      'Show subtotal': true,
      'Show tax': true,
      'Show total': true
    }
  };
};
