const unUsedItemPropPayload = [
  'code',
  'id',
  'version',
  'glIncomeAccountRef',
  'glIncomeAccountRefId',
  'sortKey',
  'unitOfMeasureId',
  'rowId',
  'markup',
  'vendorName',
  'vendorSortKey'
];

const algoliaFilters = [
  {
    attribute: 'entityType',
    valueArray: ['Product']
  }
];

export { unUsedItemPropPayload, algoliaFilters };
