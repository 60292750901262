import * as R from 'ramda';
import { roundFloat } from 'utils';

export const getPayAppBillableRetainage = ({ payAppLineItems, retainagePercent }) =>
  R.pipe(
    R.map(item =>
      roundFloat(
        ((item?.workCompleted || 0) + (item?.materialsStored || 0)) * (retainagePercent / 100 || 0)
      )
    ),
    R.sum
  )(payAppLineItems || []) || 0;

export const formatInvoiceNumber = invoiceData => {
  const jsonData = invoiceData?.data?.getCurrentCounterByPartitionKey;
  return JSON.parse(jsonData)?.invoice;
};
