import { useMemo } from 'react';

import moment from 'moment';

export const useWeekHeaders = ({ startDay, customWeekStartDay }) => {
  return useMemo(() => {
    const weekHeaders = [];
    if (customWeekStartDay) {
      if (
        moment().isoWeekday() >=
        moment()
          .isoWeekday(customWeekStartDay)
          .isoWeekday()
      ) {
        for (let i = 0; i < 7; i += 1) {
          const header = moment(startDay)
            .isoWeekday(customWeekStartDay)
            .add(i, 'days')
            .format('ddd D');
          weekHeaders.push(header);
        }
        return weekHeaders;
      }
      for (let i = 0; i < 7; i += 1) {
        const header = moment(startDay)
          .subtract(7, 'days')
          .isoWeekday(customWeekStartDay)
          .add(i, 'days')
          .format('ddd D');

        weekHeaders.push(header);
      }
      return weekHeaders;
    }

    for (let i = 0; i < 7; i += 1) {
      const header = moment(startDay)
        .startOf('isoWeek')
        .add(i, 'days')
        .format('ddd D');

      weekHeaders.push(header);
    }

    return weekHeaders;
  }, [startDay, customWeekStartDay]);
};
