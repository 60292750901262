import { convertToCurrencyStringCheck } from '../../CKEditor.utils';

// Handle conditional display of material and labor totals
function calculateTotal(items) {
  const productTotal = item => (item.quantity ? item.quantity * item.unitPrice : 0);
  const total = items.reduce((currentValue, product) => currentValue + productTotal(product), 0);
  return convertToCurrencyStringCheck(total);
}

function getTotalForDisplay({ taskItems, displayTotal, type, styles, fontFamilyStyle }) {
  return displayTotal
    ? `<tr>
          <td style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);text-align:right;padding:2px;" colspan="4">
            <span fontfamily=${fontFamilyStyle} class="text-small"><strong>${type} Total</strong></span>
          </td>
          <td colspan="1" style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);text-align:right;padding:2px;" class="restricted">
            <span fontfamily=${fontFamilyStyle} class="text-small" contenteditable="false">${calculateTotal(
        taskItems
      )}</span>
          </td>
        </tr>`
    : ``;
}

export default getTotalForDisplay;
