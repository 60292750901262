const submittalPackageRowMeta = [
  {
    id: 'number',
    label: 'Number',
    showLink: 'view',
    linkPath: 'submittal',
    linkReference: 'id'
  },
  /*
  {
    id: 'version',
    label: 'Version',
    type: 'string'
  },
  */
  {
    id: 'subject',
    label: 'Subject',
    type: 'string'
  },
  {
    id: 'specDescription',
    label: 'Notes',
    type: 'string'
  },
  {
    id: 'createdDate',
    label: 'Date Created',
    type: 'date'
  },
  {
    id: 'dueDate',
    label: 'Date Due',
    type: 'date'
  },
  {
    id: 'packageItems',
    label: 'Items'
  },
  {
    id: 'daysOverdue',
    label: 'Days Overdue',
    headerStyle: {
      maxWidth: 150
    }
  }
];

export default submittalPackageRowMeta;
