import React from 'react';
import { number, string } from 'prop-types';

import TimeMarker from '@dispatch/components/DispatchBoard/components/TimeMarker';

import { useStyles } from './DropPreview.styles';

const DropPreview = ({ left, width, startTime, endTime, top }) => {
  const styles = useStyles();
  if (!width) return null;

  return (
    <div css={styles.dropPreview} style={{ left, width, top }}>
      {startTime && <TimeMarker position={0} time={startTime} />}
      <div css={styles.greenBorder} />
      {endTime && <TimeMarker position={width} time={endTime} />}
    </div>
  );
};

DropPreview.propTypes = {
  left: number,
  width: number,
  top: number,
  // eslint-disable-next-line react/require-default-props
  startTime: string,
  // eslint-disable-next-line react/require-default-props
  endTime: string
};

DropPreview.defaultProps = {
  left: 0,
  width: 0,
  top: 0
};

export default DropPreview;
