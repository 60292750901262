import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

import { watchedQueries } from '@dispatch/Dispatch.store';

const DELETE_BILLABLE_NON_VISIT_MUTATION = gql`
  mutation SoftDeleteNonVisitEvent($partitionKey: String!, $id: String!) {
    softDeleteNonVisitEvent(partitionKey: $partitionKey, id: $id) {
      id
      isActive
    }
  }
`;

const serializer = ({ tenantId, eventId }) => ({
  variables: {
    partitionKey: tenantId,
    id: eventId
  }
});

const optimisticResponseFactory = ({ eventId }) => ({
  softDeleteNonVisitEvent: {
    id: eventId,
    isActive: false,
    __typename: 'NonVisitEvent'
  }
});

const useDeleteBillableNonVisit = () => {
  return useExtendedMutation(DELETE_BILLABLE_NON_VISIT_MUTATION, {
    serializer,
    optimisticResponseFactory,
    update: (cache, { data }) => {
      const deletedNonVisitId = data.softDeleteNonVisitEvent?.id;

      if (deletedNonVisitId && watchedQueries.useDispatchBoardNonVisitEvents) {
        const prevNonVisits = cache.readQuery(watchedQueries.useDispatchBoardNonVisitEvents);

        if (prevNonVisits) {
          const prevNonVisitsItems = prevNonVisits?.getCompany?.nonVisitEventsView?.items || [];
          const newItems = prevNonVisitsItems.filter(nonVisit => nonVisit.id !== deletedNonVisitId);

          const newData = {
            getCompany: {
              ...prevNonVisits.getCompany,
              nonVisitEventsView: {
                ...prevNonVisits.getCompany.nonVisitEventsView,
                items: newItems
              }
            }
          };

          cache.writeQuery({
            ...watchedQueries.useDispatchBoardNonVisitEvents,
            data: newData
          });
        }
      }
    }
  });
};

export default useDeleteBillableNonVisit;
