import React from 'react';
import { DragSource } from 'react-dnd';
import { MUIFormComponents } from '@buildhero/sergeant';
import ItemTypes from '../ItemTypes';

const Item = ({ item, isDragging, connectDragSource, classes, getLocalAssets }) => {
  const opacity = isDragging ? 0.4 : 1;
  const { id } = item;
  const localAsset = getLocalAssets(id);
  const textInputFieldData = {
    name: id,
    value: item.default_value || ''
  };

  const textInputFormData = {
    setFieldValue: () => {},
    errors: {},
    fields: {}
  };

  const ControlTypeElement = MUIFormComponents[item.controlType];
  if (localAsset) {
    item.source = localAsset;
  }

  return (
    <div className={classes.item} ref={connectDragSource} style={{ opacity }}>
      <ControlTypeElement field={textInputFieldData} options={item} form={textInputFormData} />
    </div>
  );
};
export default DragSource(
  ItemTypes.ITEM,
  {
    beginDrag: props => {
      props.updateDragging({ dragging: true, item: 'item' });
      return {
        item: props.item,
        row: props.row,
        column: props.column
      };
    },
    endDrag(props, monitor) {
      const item = monitor.getItem();
      const dropResult = monitor.getDropResult();
      props.updateDragging({ dragging: false, item: null });
      if (dropResult) {
        props.handleDrop(item, dropResult.row, dropResult.column);
      }
    }
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  })
)(Item);
