import { checkPermission } from 'utils';
import { Mode } from 'utils/constants';
import { PermissionConstants } from 'utils/AppConstants';

export const checkEditPermission = user =>
  checkPermission(Mode.EDIT, PermissionConstants.OBJECT_PM_PROJECT_SETTINGS, user);

export const checkAddPermission = user =>
  checkPermission(Mode.ADD, PermissionConstants.OBJECT_PM_PROJECT_SETTINGS, user);

export const replaceItem = (items, index, value) => {
  const newItems = [...items];
  newItems[index] = value;
  return newItems;
};

export const modifyListItem = (list, value, key, index) => {
  const newValue = { ...list[index], [key]: value, isEdited: true };
  const clonedList = [...list];
  clonedList[index] = newValue;
  return clonedList;
};

export const constructProjectJobSetting = (projectSetting, isEnabled = false) => {
  const settings = JSON.stringify({ jobByJob: isEnabled });
  return { ...projectSetting, settings };
};

export const getCostTypeLabel = (types, selected) => {
  const { label } = types.find(({ value }) => value === selected) || {};
  return label || '-';
};
