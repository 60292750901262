import React, { useMemo } from 'react';
import { DeleteOutlined } from '@material-ui/icons';

import { actionButtonColumn } from 'components/WrapTable';

import DropdownCell from './components/DropdownCell';
import SubTotalCell from './components/SubTotalCell/SubTotalCell.component';
import { HoursCell, LaborRateCell } from './components/NumberInputCell/NumberInputCell.component';

export const useLaborItemColumns = ({
  loading,
  disabled,
  employees,
  employeeMap,
  laborTypes,
  hourTypes,
  isTechnicianDefaultRates,
  visitDepartmentId,
  visitDepartmentName,
  onDelete,
  onChangeHours,
  onChangeRate,
  onChangeLaborType,
  onChangeTechnician,
  onChangeDepartment
}) => {
  return useMemo(() => {
    const employeeOptions = employees?.map(({ id, name }) => ({ value: id, label: name }));
    const laborTypeOptions = laborTypes?.map(({ id, name }) => ({ value: id, label: name })) ?? [];

    const getDepartmentOptions = employeeId => {
      const departments = employeeMap?.[employeeId]?.departments?.items || [];
      const departmentOptions =
        departments?.map(({ id, tagName }) => ({
          value: id,
          label: tagName
        })) ?? [];
      // Need to add the visit department as an option if it does not exist
      if (!departments.some(department => department.id === visitDepartmentId)) {
        return departmentOptions.concat([{ value: visitDepartmentId, label: visitDepartmentName }]);
      }
      return departmentOptions;
    };

    const hourTypesColumns =
      hourTypes?.reduce((result, hourType) => {
        return [
          ...result,
          {
            field: `rate_${hourType.id}`,
            width: 120,
            headerName: hourType.hourType,
            justify: 'center',
            cellCss: ({ isLastRow }) => {
              return isLastRow
                ? {
                    borderLeft: `1px solid #333333`,
                    borderBottom: `1px solid #333333`
                  }
                : {
                    borderLeft: `1px solid #333333`
                  };
            },
            headerCss: {
              borderTop: '1px solid #333333',
              borderLeft: '1px solid #333333'
            },
            renderCell: props => (
              <LaborRateCell
                {...props}
                disabled={disabled}
                hourType={hourType}
                onChangeRate={onChangeRate}
              />
            )
          },
          {
            field: `hours_${hourType.id}`,
            width: 120,
            headerName: 'Hours',
            justify: 'center',
            cellCss: ({ isLastRow }) => {
              return isLastRow
                ? {
                    borderBottom: '1px solid #333333'
                  }
                : {};
            },
            headerCss: {
              borderTop: '1px solid #333333'
            },
            renderCell: props => (
              <HoursCell
                {...props}
                disabled={disabled}
                hourType={hourType}
                onChangeHours={onChangeHours}
              />
            )
          }
        ];
      }, []) || [];

    const actionColumn = disabled
      ? []
      : actionButtonColumn({
          actions: [
            {
              icon: DeleteOutlined,
              label: 'Delete',
              onClick: onDelete
            }
          ]
        });

    return [
      {
        field: 'id',
        hide: true
      },
      {
        field: 'employeeId',
        width: 200,
        headerName: 'Technician',
        justify: 'center',
        // eslint-disable-next-line react/prop-types
        renderCell: ({ value, row }) => {
          // eslint-disable-next-line react/prop-types
          const lineItemId = row?.id;
          return (
            <DropdownCell
              loading={loading}
              disabled={disabled}
              value={value}
              options={employeeOptions}
              onChange={technicianId => onChangeTechnician({ lineItemId, technicianId })}
            />
          );
        }
      },
      {
        field: 'departmentId',
        width: 200,
        headerName: 'Department',
        justify: 'center',
        renderCell: ({ value, row }) => {
          // eslint-disable-next-line react/prop-types
          const lineItemId = row?.id;
          return (
            <DropdownCell
              loading={loading}
              disabled={disabled}
              value={value}
              options={getDepartmentOptions(row?.employeeId)}
              onChange={departmentId => onChangeDepartment({ lineItemId, departmentId })}
            />
          );
        }
      },
      {
        field: 'labourTypeId',
        width: 200,
        headerName: 'Labor Type',
        justify: 'center',
        // eslint-disable-next-line react/prop-types
        renderCell: ({ value, row }) => {
          // eslint-disable-next-line react/prop-types
          const lineItemId = row?.id;
          return (
            <DropdownCell
              loading={loading}
              disabled={disabled || isTechnicianDefaultRates}
              value={value}
              options={laborTypeOptions}
              onChange={laborTypeId => onChangeLaborType({ lineItemId, laborTypeId })}
            />
          );
        }
      },
      ...hourTypesColumns,
      {
        field: 'subTotal',
        width: 120,
        headerName: 'Subtotal',
        justify: 'center',
        cellCss: {
          borderLeft: '1px solid #333333'
        },
        headerCss: {
          borderLeft: '1px solid #333333'
        },
        // eslint-disable-next-line react/prop-types
        renderCell: ({ value }) => <SubTotalCell value={value} />
      },
      actionColumn
    ];
  }, [
    loading,
    disabled,
    employees,
    employeeMap,
    laborTypes,
    hourTypes,
    isTechnicianDefaultRates,
    visitDepartmentId,
    visitDepartmentName,
    onDelete,
    onChangeHours,
    onChangeRate,
    onChangeLaborType,
    onChangeTechnician,
    onChangeDepartment
  ]);
};
