const addToFilters = (item, existingFilter, updateHandler) =>
  updateHandler([...existingFilter, item]);

const clearRefine = ({ refine, items }, onClearFilter) => {
  refine(items);
  onClearFilter([]);
};

const removeFromFilters = (item, existingFilter = [], updateHandler) => {
  const filteredList = existingFilter.filter(({ label }) => label !== item.label);
  updateHandler(filteredList);
};

const isSelected = (item, list = []) => {
  const isFound = list.some(({ label }) => label === item.label);
  return isFound;
};

const addDefaultRefinement = (refineList, selectedFilters) => {
  const refinements = refineList.map(refine => {
    const { attribute } = refine;
    const refineItems = selectedFilters.filter(val => val.attribute === attribute);
    const defaultRefinement = refineItems.map(({ label }) => label);
    return { ...refine, defaultRefinement };
  });
  return refinements;
};

export { clearRefine, addToFilters, addDefaultRefinement, removeFromFilters, isSelected };
