import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FollowUps(props) {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SvgIcon {...props}>
      <path d="M11.9567 4.25977C16.9567 4.25977 20.9567 8.25977 20.9567 13.2598C20.9567 18.2598 16.9567 22.2598 11.9567 22.2598C6.95668 22.2598 2.95668 18.2598 2.95668 13.2598C2.95668 8.25977 6.95668 4.25977 11.9567 4.25977ZM12.9567 8.25977H10.9567V13.6598L14.2567 16.9598L15.6567 15.5598L12.9567 12.8598V8.25977ZM14.593 1.52749C18.2428 2.33514 21.2929 4.85192 22.7969 8.25717C22.9643 8.63607 22.7928 9.0789 22.4139 9.24625C22.035 9.41359 21.5921 9.24209 21.4248 8.86319C20.1129 5.89278 17.4507 3.69615 14.2689 2.99206C13.8645 2.90256 13.6092 2.50216 13.6987 2.09773C13.7881 1.6933 14.1886 1.43799 14.593 1.52749ZM9.51805 1.52749C9.92249 1.43799 10.3229 1.6933 10.4124 2.09773C10.5019 2.50216 10.2466 2.90256 9.84214 2.99206C6.66033 3.69615 3.99817 5.89278 2.68625 8.86319C2.51891 9.24209 2.07608 9.41359 1.69718 9.24625C1.31828 9.0789 1.14678 8.63607 1.31412 8.25717C2.8181 4.85192 5.86825 2.33514 9.51805 1.52749Z" />
    </SvgIcon>
  );
}
