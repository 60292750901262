import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { Mode } from 'utils/constants';
import useExtendedMutation from 'customHooks/useExtendedMutation';
import { formatForInvoiceItemMutation } from './InvoiceItem.utils';

export const INVOICE_ITEM_FRAGMENT = gql`
  fragment ItemFields on InvoiceItem {
    id
    sortOrder
    name
    description
    quantity
    unitCost
    unitPrice
    amount
    taxable
    markupValue
    lineItemType
    productId
    productSortKey
    accountingRefId
    accountingRefIdOfEntity
    accountingRefIdOfClass
    createdBy
    version
    source
    jobCostType(entityConnection: "JobCostType") {
      value: id
      label: name
    }
    costCode(entityConnection: "CostCode") {
      value: id
      label: name
    }
    revenueType(entityConnection: "RevenueType") {
      value: id
      label: name
    }
    department(entityConnection: "Department") {
      value: id
      label: tagName
      accountingRefIdOfClass
    }
    jcContractItem {
      value: id
      label: name
    }
    synced
    lastUpdatedDateTime
    product(entityConnection: "Product") {
      code
      unitOfMeasure(entityConnection: "UnitOfMeasure") {
        name
      }
    }
  }
`;

const CREATE_INVOICE_ITEM = gql`
  mutation addInvoiceItemsToInvoice($partitionKey: String, $data: AddInvoiceItemsToInvoice) {
    addInvoiceItemsToInvoice(partitionKey: $partitionKey, data: $data) {
      ...ItemFields
    }
  }
  ${INVOICE_ITEM_FRAGMENT}
`;

const UPDATE_INVOICE_ITEM = gql`
  mutation updateInvoiceItem($partitionKey: String, $data: UpdateInvoiceItemInput!) {
    updateInvoiceItem(partitionKey: $partitionKey, data: $data) {
      ...ItemFields
    }
  }
  ${INVOICE_ITEM_FRAGMENT}
`;

const DELETE_INVOICE_ITEM = gql`
  mutation deleteInvoiceItem($partitionKey: String, $id: String!) {
    deleteInvoiceItemById(partitionKey: $partitionKey, id: $id) {
      id
    }
  }
`;

export const useMutateInvoiceItem = (invoiceId, isMarginEnabled, options = undefined) => {
  const [createInvoiceItem, { loading: createLoading, error: createError }] = useMutation(
    CREATE_INVOICE_ITEM,
    {
      update: (cache, { data: { addInvoiceItemsToInvoice } }) => {
        cache.modify({
          id: `Invoice:${invoiceId}`,
          fields: {
            invoiceItems: ({ items: existingItems = [] }) => {
              const [data] = addInvoiceItemsToInvoice;
              const newItem = cache.writeFragment({
                data,
                fragment: INVOICE_ITEM_FRAGMENT
              });
              return { items: [...existingItems, newItem] };
            }
          }
        });
      },
      ...options
    }
  );
  const [updateInvoiceItem, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_INVOICE_ITEM,
    options
  );
  const [deleteInvoiceItem, { loading: deleteLoading, error: deleteError }] = useMutation(
    DELETE_INVOICE_ITEM,
    {
      update: (
        cache,
        {
          data: {
            deleteInvoiceItemById: { id }
          }
        }
      ) => {
        cache.modify({
          id: `Invoice:${invoiceId}`,
          fields: {
            invoiceItems: ({ items: existingItems = [] }, { readField }) => {
              return { items: existingItems.filter(item => id !== readField('id', item)) };
            }
          }
        });
      },
      ...options
    }
  );

  const mutateInvoiceItem = useCallback(
    async (mode, data) => {
      if (mode === Mode.ADD) {
        const response = await createInvoiceItem({
          variables: { data: { invoiceId, invoiceItems: [formatForInvoiceItemMutation(data, isMarginEnabled)] } }
        });
        return response.data.addInvoiceItemsToInvoice[0];
      }
      if (mode === Mode.EDIT) {
        const response = await updateInvoiceItem({
          variables: { data: formatForInvoiceItemMutation(data, isMarginEnabled) }
        });
        return response.data.updateInvoiceItem;
      }
      if (mode === Mode.DELETE) {
        const response = await deleteInvoiceItem({ variables: { id: data?.id } });
        return response.data.deleteInvoiceItemById;
      }
    },
    [createInvoiceItem, updateInvoiceItem, deleteInvoiceItem, invoiceId]
  );

  return [
    mutateInvoiceItem,
    {
      loading: createLoading || updateLoading || deleteLoading,
      error: createError || updateError || deleteError
    }
  ];
};

const UPDATE_INVOICE_ITEMS = gql`
  mutation updateInvoiceItems($partitionKey: String, $data: UpdateInvoiceItemsInput!) {
    updateInvoiceItems(partitionKey: $partitionKey, data: $data) {
      ...ItemFields
    }
  }
  ${INVOICE_ITEM_FRAGMENT}
`;

export const useBatchUpdateInvoiceItems = options => {
  const [update, { loading, error }] = useExtendedMutation(UPDATE_INVOICE_ITEMS, {
    serializer: data => ({ variables: { data } }),
    ...options
  });

  const batchUpdate = useCallback(
    async params => {
      const result = await update(params);
      return result.data?.updateInvoiceItems;
    },
    [update]
  );

  return [batchUpdate, { loading, error }];
};
