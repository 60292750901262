/* eslint-disable no-useless-concat */
/* eslint-disable no-template-curly-in-string */
const defaultTruckform = (bundles, employees) => ({
  fields: {
    truckName: {
      default: 'truckName'
    },
    bundleId: {
      default: 'bundleId'
    }
  },
  layouts: {
    default: {
      options: {
        margin: [10, 0]
      },
      contents: [
        {
          options: {
            width: '60%',
            padding: [10, 0]
          },
          contents: [
            {
              component: 'TextInput',
              source: 'truckName',
              options: {
                label: 'Truck name',
                isRequired: true
              }
            }
          ]
        },
        {
          options: {
            padding: [10, 0]
          },
          contents: [
            {
              options: {
                width: '40%',
                padding: [10, 0]
              },
              contents: [
                {
                  component: 'SelectInput',
                  source: 'bundleId',
                  options: {
                    label: 'Inventory bundle',
                    valueSet: bundles,
                    placeholder: 'Select bundle'
                  }
                }
              ]
            },
            {
              options: {
                width: '80%',
                padding: [10, 0]
              },
              contents: [
                {
                  component: 'SelectInput',
                  source: 'employeeId',
                  options: {
                    label: 'Technician(s)',
                    valueSet: employees,
                    placeholder: 'Select technician',
                    isMultipleSelection: true
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    $schema: 'http://json-schema.org/draft-07/schema#',
    title: 'Truck',
    type: 'object',
    properties: {
      truckName: { type: 'string', required: true }
    }
  },
  validationErrors: {
    truckName: {
      required: 'Truck name is required'
    }
  }
});

export default defaultTruckform;
