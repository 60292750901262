import gql from 'graphql-tag';

const deletePurchaseOrder = gql`
  mutation deletePurchaseOrder($partitionKey: String!, $id: String!) {
    deletePurchaseOrder(partitionKey: $partitionKey, id: $id) {
      id
      sortKey
    }
  }
`;

export default deletePurchaseOrder;
