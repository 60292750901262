import gql from 'graphql-tag';

const softDeleteInvoiceById = gql`
  mutation softDeleteInvoiceById($partitionKey: String!, $id: String!) {
    softDeleteInvoiceById(partitionKey: $partitionKey, id: $id) {
      id
      version
      status
      syncStatus
    }
  }
`;

export default softDeleteInvoiceById;
