import gql from 'graphql-tag';

const updateCustomerPropertyAndRelated = gql`
  mutation updateCustomerPropertyAndRelated($data: UpdateCustomerPropertyAndRelatedInput!) {
    updateCustomerPropertyAndRelated(data: $data) {
      id
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      version
      status
      isActive
      isTaxable
      syncStatus
      syncLog
    }
  }
`;

export default updateCustomerPropertyAndRelated;
