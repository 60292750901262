export const itemsLayout = {
  entity: {
    name: 'item',
    tenant: 'default',
    layouts: {
      web: {
        sections: [
          {
            // title: 'Add item',
            contextTitle: {
              new: 'Add item',
              edit: 'Edit item'
            },
            sm: '12',
            md: '12',
            lg: '12',
            rows: [
              {
                cols: [
                  {
                    type: 'dbHidden',
                    value: 'productId',
                    name: 'productId',
                    label: 'id',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      }
                    }
                  },
                  {
                    type: 'dbHidden',
                    value: 'productVersion',
                    name: 'productVersion',
                    label: 'productVersion',
                    behavior: {
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'name',
                    name: 'name',
                    label: 'name',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '4'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '4'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'description',
                    name: 'description',
                    label: 'description',
                    behavior: {
                      new: {
                        ui: 'TextArea',
                        sm: '12',
                        md: '8',
                        lg: '8'
                      },
                      edit: {
                        ui: 'TextArea',
                        sm: '12',
                        md: '8',
                        lg: '8'
                      }
                    }
                  }
                ]
              },
              {
                cols: [
                  {
                    type: 'dbField',
                    value: 'glIncomeAccountRefId',
                    name: 'glIncomeAccountRefId',
                    label: 'salesAccount',
                    showField: 'isQuickBooksEnabled',
                    specialBehaviour: {
                      includeQueryResult: true,
                      queryPath: 'salesAccount',
                      fieldName: 'name',
                      fieldValue: 'id'
                    },
                    behavior: {
                      new: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '4',
                        lg: '4'
                      },
                      edit: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '4',
                        lg: '4'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'vendorSortKey',
                    name: 'vendorSortKey',
                    label: 'vendorNonMandatory',
                    specialBehaviour: {
                      includeQueryResult: true,
                      queryPath: 'vendors',
                      fieldName: 'name',
                      fieldValue: 'sortKey'
                    },
                    behavior: {
                      new: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '4',
                        lg: '4'
                      },
                      edit: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '4',
                        lg: '4'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'code',
                    name: 'code',
                    label: 'productCode',
                    specialBehaviour: {
                      disabled: true
                    },
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '2',
                        lg: '2'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '2',
                        lg: '2'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'taxable',
                    name: 'taxable',
                    label: 'taxable',

                    behavior: {
                      new: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '2',
                        lg: '2'
                      },
                      edit: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '2',
                        lg: '2'
                      }
                    }
                  }
                  // {
                  //   type: 'dbField',
                  //   value: 'taxable',
                  //   name: 'taxable',
                  //   label: 'taxable',
                  //   specialBehaviour: {
                  //     disabled: true
                  //   },
                  //   behavior: {
                  //     edit: {
                  //       ui: 'Checkbox',
                  //       sm: '12',
                  //       md: '2',
                  //       lg: '2'
                  //     }
                  //   }
                  // },
                  // {
                  //   type: 'dbField',
                  //   value: 'productMarkupDisplayValue',
                  //   name: 'productMarkupDisplayValue',
                  //   label: 'itemMarkup',
                  //   behavior: {
                  //     edit: {
                  //       ui: 'ReadOnly',
                  //       sm: '12',
                  //       md: '4',
                  //       lg: '4'
                  //     }
                  //   }
                  // }
                ]
              },
              {
                cols: [
                  {
                    type: 'dbField',
                    value: 'costMarkup',
                    name: 'costMarkup',
                    label: 'costMarkup',
                    specialBehaviour: {
                      unitCostKey: 'unitCost',
                      unitPriceKey: 'unitPrice',
                      markupKey: 'markupValue',
                      markupTypeKey: 'markupType',
                      markupLabel: 'Item markup',
                      applyTierPricing: true
                    },
                    behavior: {
                      new: {
                        ui: 'CostMarkup',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'costMarkup',
                    name: 'costMarkup',
                    label: 'costMarkup',
                    specialBehaviour: {
                      unitCostKey: 'productUnitCost',
                      unitPriceKey: 'productUnitPrice',
                      markupKey: 'markupValue',
                      markupTypeKey: 'markupType',
                      markupLabel: 'Item markup',
                      applyTierPricing: true
                    },
                    behavior: {
                      edit: {
                        ui: 'CostMarkup',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      }
                    }
                  }
                ]
              }
            ]
          }
        ],
        buttons: {
          save: {
            label: 'Save',
            color: 'primary',
            buttonType: 'contained',
            behavior: {
              new: true,
              edit: true
            }
          },
          cancel: {
            label: 'Cancel',
            color: 'secondary',
            buttonType: 'contained',
            behavior: {
              new: true,
              edit: true
            }
          },
          deleteItem: {
            label: 'Delete',
            validateKey: 'employees'
          }
        }
      },
      search: null
    }
  }
};

export default itemsLayout;
