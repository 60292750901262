import gql from 'graphql-tag';

const savePurchaseOrderTypeToCompany = gql`
  mutation savePurchaseOrderTypes($partitionKey: String, $data: SavePurchaseOrderTypeInput!) {
    savePurchaseOrderTypes(partitionKey: $partitionKey, data: $data) {
      id
      name
      departments {
        id
        tagName
      }
      autoReceive
    }
  }
`;

export default savePurchaseOrderTypeToCompany;
