import gql from 'graphql-tag';

const getTechVisitCost = gql`
  query getTechVisitCost($partitionKey: String!, $visitId: String!) {
    getTechVisitCost(partitionKey: $partitionKey, visitId: $visitId) {
      approvalStatus
      reviewStatus
      approvedByList
      approvedDateList
      reviewedByList
      reviewedDateList
      duration
      hourTypeId
      employeeId
      name
      totalCost
      hourCost
    }
  }
`;

export default getTechVisitCost;
