import { makeStyles } from '@material-ui/core/styles';
import theme from 'themes/BuildHeroTheme';

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '25% auto 15%',
    alignItems: 'center',
    position: 'relative',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '-0.02em',
    cursor: 'pointer',
    borderRadius: '2px',
    filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2))',
    '&:hover': {
      filter: 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5))'
    },
    minHeight: 50,
    padding: '0 10px',
    marginBottom: 10
  },
  flexList: {
    display: 'flex',
    alignItems: 'center'
  },
  showContent: {
    filter: 'none',
    '&:hover': {
      filter: 'none'
    },
    cursor: 'default'
  },
  messageDetails: {
    width: '100%',
    height: '100%'
  },
  bodyContainer: {
    background: theme.palette.grayscale(90),
    padding: '5%'
  },
  body: {
    background: 'white',
    padding: '3%',
    borderRadius: '2px'
  },
  gridRow: {
    display: 'grid',
    gridTemplateColumns: '60px auto',
    alignItems: 'center',
    marginBottom: 10
  },
  subjectContainer: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    marginBottom: 10
  },
  messageUnread: {
    fontWeight: 700,
    background: theme.palette.common.white
  },
  messageRead: {
    background: theme.palette.grayscale(90)
  },
  profilePic: {
    width: '35px',
    height: '35px',
    overflow: 'hidden',
    borderRadius: '50%',
    bottom: '0px',
    fontSize: 14,
    marginRight: 10
  },
  nameplate: {
    fontSize: '16px',
    fontWeight: 500,
    marginRight: 4
  }
});

export default useStyles;
