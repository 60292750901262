import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function ScreenWidth(Component) {
  return function WrappedComponent(props) {
    const theme = useTheme();
    let matches = false;
    matches = useMediaQuery(theme.breakpoints.down('(min-width:600px)'));
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} isSmallScreen={matches} />;
  };
}

export default ScreenWidth;
