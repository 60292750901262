import gql from 'graphql-tag';
import useExtendedQuery from 'customHooks/useExtendedQuery';

const GET_JOB_DETAILS = gql`
  query getJobDetails($jobNumber: String!) {
    getJobByJobNumber(jobNumber: $jobNumber) {
      id
      jobNumber
      jobTypeInternal
      customIdentifier
      customerName
      customerId
      customerPropertyName
      customerPropertyId
      property: customerProperty {
        id
        companyName
        companyAddresses {
          items {
            id
            addressType
            addressLine1
            addressLine2
            city
            state
            zipcode
            latitude
            longitude
          }
        }
      }
      priority
    }
  }
`;

const transform = data => {
  return data?.getJobByJobNumber || {};
};

const useJobDetails = jobNumber => {
  return useExtendedQuery(GET_JOB_DETAILS, {
    transform,
    variables: {
      jobNumber
    },
    skip: !jobNumber
  });
};

export default useJobDetails;
