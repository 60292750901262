/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getBills(include, options = { limit: 10, offset: 0 }) {
  return await instance
    .get(`bills`, {
      params: {
        include: include || '*',
        ...options
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getBillById(id, include = '*') {
  return await instance
    .get(`bills/${id}`, {
      params: {
        // eslint-disable-next-line prettier/prettier
        include
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getPOBills(poid, include = []) {
  return await instance
    .get(`bills/po/${poid}`, {
      params: {
        // eslint-disable-next-line prettier/prettier
        include: include || ['BillLine']
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getBillByReceipt(receiptId, include = []) {
  return await instance
    .get(`bills/byreceipt/${receiptId}`, {
      params: {
        // eslint-disable-next-line prettier/prettier
        include: include || ['BillLine']
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getBillsForReviewReport(jobId) {
  return await instance
    .get(`bills/reviewreport/${jobId}`, {
      params: {}
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
