/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { snackbarOn } from 'redux/actions/globalActions';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ErrorBoundaries from 'scenes/Error';
import { UserPermission, DefaultButton } from 'components';
import ResponsiveTable from 'components/ResponsiveTable';
import SergeantModal from 'components/SergeantModal';
import { getCOByProjectId, changeOrderDelete } from 'services/API/changeorder';
import { PermissionConstants } from 'utils/AppConstants';
import Labels from 'meta/labels';
import simpleRowActionButtons from 'meta/ProjectManagement/global/simpleTableRowActionButton';
import changeOrderRowsMeta from 'meta/ProjectManagement/ChangeOrder/changeOrderTable';
import { ChangeOrderStatus } from '@pm/constants';
import SubmenuTitle from '@pm/components/SubmenuTitle';
import CustomHighlightCard from '@pm/components/CustomHighlightCard';
import CustomDataCard from '@pm/components/CustomDataCard';
import KpiElement from '@pm/components/KpiElement';
import ChangeOrderDetailView from './ChangeOrderDetailView';
import GenerateChangeOrder from './GenerateChangeOrder';
import styles from './styles';

const ChangeOrdersList = ({ classes, user, ...props }) => {
  const { projectId, mode, changeOrderId, projectData } = props || {};
  const [currentTable, setCurrentTable] = useState(0);
  const [isOpenedModal, setIsOpenedModal] = useState(false);
  const [isOpenedDetailModal, setIsOpenedDetailModal] = useState(changeOrderId !== undefined);
  const [selectEditOrder, setEditOrder] = useState({});
  const [orders, setOrders] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState({
    confirmAction: () => {},
    confirmDialog: false,
    confirmTitle: ''
  });

  const handleCancelConfirmation = () =>
    setConfirmDelete({ confirmAction: () => {}, confirmDialog: false, confirmTitle: '' });
  const orderBtnTitles = [
    ChangeOrderStatus.DRAFT,
    ChangeOrderStatus.SENT,
    ChangeOrderStatus.RESPONDED,
    ChangeOrderStatus.APPROVED
  ];
  const [kpiValues, setKpiValues] = useState({
    notApprovedValue: 0,
    revenue: 0,
    cost: 0,
    profit: 0,
    margin: 0
  });

  const getKPIValues = changeOrders => {
    const values = {
      notApprovedValue: 0,
      dollarsOutsanding: 0,
      revenue: 0,
      cost: 0,
      profit: 0,
      margin: 0
    };

    changeOrders.map(co => {
      if (co.status === ChangeOrderStatus.APPROVED) {
        values.revenue += Number(co.revenue ?? 0);
        values.cost += Number(co.cost ?? 0);
      } else if (
        co.status === ChangeOrderStatus.SENT ||
        co.status === ChangeOrderStatus.RESPONDED
      ) {
        values.dollarsOutsanding += Number(co.revenue ?? 0);
      } else {
        values.notApprovedValue += Number(co.revenue ?? 0);
      }
    });
    values.profit += values.revenue - values.cost;
    values.margin = values.revenue > 0 ? (values.profit / values.revenue) * 100 : 0;
    setKpiValues(values);
  };

  const update = () => {
    getCOByProjectId(projectId).then(changeOrders => {
      setOrders([
        changeOrders.filter(co => co.status === ChangeOrderStatus.DRAFT),
        changeOrders.filter(co => co.status === ChangeOrderStatus.SENT),
        changeOrders.filter(co => co.status === ChangeOrderStatus.RESPONDED),
        changeOrders.filter(co => co.status === ChangeOrderStatus.APPROVED)
      ]);
      getKPIValues(changeOrders);
    });
  };

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const deleteAction = async (record, stopLoading) => {
    const finalData = orders.filter(item => item.id !== record.id);
    await changeOrderDelete(record.id);
    setConfirmDelete({ confirmAction: () => {}, confirmDialog: false, confirmTitle: '' });
    setOrders([...finalData]);
    update();
    stopLoading();
  };

  const handleCORowactions = async (actionType, record) => {
    if (actionType === 'remove') {
      setConfirmDelete({
        confirmAction: (data, stopLoading) => deleteAction(record, stopLoading),
        confirmDialog: true,
        confirmTitle: `Delete Change Order ${record.number}`
      });
    } else if (actionType === 'edit') {
      setEditOrder(record);
      setIsOpenedModal(true);
    }
  };

  return (
    <ErrorBoundaries>
      {/* --------------- Change order modal detail --------------- */}
      <ChangeOrderDetailView
        open={isOpenedDetailModal}
        handleClose={() => {
          update();
          setIsOpenedDetailModal(false);
        }}
        projectId={projectId}
        projectData={projectData}
        changeOrderId={changeOrderId}
      />
      {/* --------------- Change order main view --------------- */}
      <UserPermission I="read" action={PermissionConstants.OBJECT_PM_CHANGE_ORDER}>
        <SubmenuTitle>Change Orders</SubmenuTitle>
        <Box className={classes.root}>
          <Box className={classes.kpiContainer}>
            <Typography className={classes.kpiSubTitle}>KPIs</Typography>
            <KpiElement
              type="currency"
              content={kpiValues.revenue}
              title="Change Order Revenue"
              arrowUp={false}
              key="Change Order Revenue"
            />
            <KpiElement
              type="currency"
              content={kpiValues.cost}
              title="Change Order Cost"
              arrowUp={false}
              key="Change Order Cost"
            />
            <KpiElement
              type="currency"
              content={kpiValues.profit}
              title="Change Order Profit"
              arrowUp={kpiValues.profit > 0}
              key="Change Order Profit"
            />
            <KpiElement
              type="percentage"
              content={kpiValues.margin}
              title="Change Order Gross Margin"
              arrowUp={kpiValues.margin > 0}
              key="Change Order Gross Margin"
            />
            <CustomDataCard
              items={[
                {
                  type: 'currency',
                  highlight: kpiValues?.dollarsOutsanding ? kpiValues.dollarsOutsanding : 0,
                  title: 'Dollars Outstanding'
                },
                {
                  highlight:
                    orders.length > 0
                      ? orders[1]?.filter(order => moment().isAfter(moment.unix(order.dueDate)))
                          .length +
                        orders[2]?.filter(order => moment().isAfter(moment.unix(order.dueDate)))
                          .length
                      : 0,
                  title: 'Overdue'
                }
              ]}
              style={{
                maxWidth: '300px',
                filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))'
              }}
            />
          </Box>
          <Divider />
          <Box className={classes.searchAndButtonContainer}>
            <div />
            <DefaultButton
              label={Labels.generateChageOrder[user.locale]}
              variant="containedPrimary"
              style={{ height: 30, fontSize: 12 }}
              key="changeOrderGenerateChangeOrderBtn"
              onClick={() => setIsOpenedModal(true)}
              buttonProps={{ startIcon: <AddCircleOutlineIcon style={{ fontSize: 14 }} /> }}
            />
          </Box>
          <Box className={classes.highlightCardContainer}>
            <Grid container spacing={2}>
              <Grid container direction="row">
                {orderBtnTitles.map((statusName, index) => (
                  <CustomHighlightCard
                    key={`changeOrderHighlightCard-${statusName}`}
                    title={statusName}
                    highlight={orders[index]?.length ? orders[index]?.length : 0}
                    handleClick={() => setCurrentTable(index)}
                    selected={currentTable === index}
                  />
                ))}
              </Grid>
              <Grid item xs={12}>
                <ResponsiveTable
                  disableFilter
                  rowMetadata={changeOrderRowsMeta(mode, projectId)}
                  data={orders[currentTable] ? orders[currentTable] : []}
                  noDataMsg="No Change Orders"
                  tableName="ProjectManagementChangeOrders"
                  rowActionButtons={simpleRowActionButtons}
                  rowActions={handleCORowactions}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <GenerateChangeOrder
          changeOrderData={selectEditOrder}
          open={isOpenedModal}
          projectData={projectData}
          user={user}
          handleClose={() => {
            setIsOpenedModal(false);
            setEditOrder({});
            update();
          }}
          projectId={projectId}
          update={update}
        />
        <SergeantModal
          open={confirmDelete.confirmDialog}
          title={confirmDelete.confirmTitle}
          customPrimaryButtonLabel="Delete"
          dataType="Item"
          mode="delete"
          customComponents={{}}
          handlePrimaryAction={confirmDelete.confirmAction}
          handleClose={handleCancelConfirmation}
          alignCloseRight
        />
      </UserPermission>
    </ErrorBoundaries>
  );
};

ChangeOrdersList.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => ({
  user: state.user,
  projectData: state.pm.project,
  mode: props.match.params.mode,
  projectId: props.match.params.projectId,
  changeOrderId: props.match.params.subpage
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const ReduxConnectedChangeOrdersList = connect(
  mapStateToProps,
  mapDispatcherToProps
)(ChangeOrdersList);
export default withStyles(styles)(ReduxConnectedChangeOrdersList);
