import gql from 'graphql-tag';

const saveEmployeeMutation = gql`
  mutation saveEmployee($input: SaveEmployeeInput!, $action: String) {
    saveEmployee(input: $input, action: $action) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      firstName
      lastName
      name
      userName
      email
      personalEmail
      status
      cellPhone
      landlinePhone
      profilePictureUrl
      isAdmin
      nickName
      isActive
      contactAddresses(entityConnection: "ContactAddress") {
        items {
          addressType
          addressLine1
          addressLine2
          city
          state
          zipcode
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      appRoles(entityConnection: "AppRole") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          invertedSortKey
          hierarchy
          entityType
          mappedEntity {
            ... on AppRole {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              tagName
              tagType
            }
          }
        }
      }
      skills(entityConnection: "Skill") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          invertedSortKey
          hierarchy
          entityType
          mappedEntity {
            ... on Skill {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              tagName
              tagType
            }
          }
        }
      }
      departments(entityConnection: "Department") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          invertedSortKey
          hierarchy
          entityType
          mappedEntity {
            ... on Department {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              tagName
              tagType
              skills(entityConnection: "Skill") {
                items {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  invertedSortKey
                  hierarchy
                  entityType
                  mappedEntity {
                    ... on Skill {
                      id
                      tenantId
                      tenantCompanyId
                      partitionKey
                      sortKey
                      hierarchy
                      entityType
                      tagName
                      tagType
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default saveEmployeeMutation;
