const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    float: 'left',
    marginLeft: '5px',
    padding: '4px !important',
    width: 'auto'
  },
  text: {
    width: 'auto',
    height: '15px',
    fontFamily: theme.typography.fontFamily,
    fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.2px'
  }
});

export default styles;
