import React from 'react';
import { bool, func } from 'prop-types';
import { ButtonGroup } from '@material-ui/core';
import { Button, ThemeProvider } from '@buildhero/sergeant';

const DayWeekSelector = ({ weekView, setWeekView }) => (
  <ThemeProvider>
    <ButtonGroup>
      <Button onClick={() => setWeekView(false)} type={!weekView ? 'secondary' : 'tertiary'}>
        Day
      </Button>
      <Button onClick={() => setWeekView(true)} type={weekView ? 'secondary' : 'tertiary'}>
        Week
      </Button>
    </ButtonGroup>
  </ThemeProvider>
);

DayWeekSelector.propTypes = {
  weekView: bool,
  setWeekView: func.isRequired
};

DayWeekSelector.defaultProps = {
  weekView: false
};

export default DayWeekSelector;
