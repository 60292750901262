import gql from 'graphql-tag';

export const bulkCreateInvoicesMutation = gql`
  mutation bulkCreateInvoicesFromServiceAgreements(
    $data: BulkCreateInvoicesFromServiceAgreementsInput!
  ) {
    bulkCreateInvoicesFromServiceAgreements(data: $data) {
      id
      totalAmount
      status
      invoiceNumber
      issuedDate
      dueDate
      customer {
        id
        customerName
      }
      serviceAgreement {
        id
        agreementName
        nextBillDate
      }
    }
  }
`;

export const ACTIVE_SERVICE_AGREEMENTS_LIST_DATA = gql`
  query activeServiceAgreementsListData(
    $columns: [String]
    $filter: GenericFilterInput
    $sorting: [SortingInput]
    $pagination: PaginationInput
  ) {
    activeServiceAgreementsListData(
      columns: $columns
      filter: $filter
      sorting: $sorting
      pagination: $pagination
    ) {
      items
      meta
      totalRecordCount
    }
  }
`;
