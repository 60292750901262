import React from 'react';

import { ThemeProvider } from '@buildhero/sergeant';
import { jsx } from '@emotion/react';

import WrapTable from 'components/WrapTable';
import { InvoiceItemType } from 'utils/constants';

import { SectionHeader } from '../Components';
import { getInvoiceLineItemsByLineItemType } from '../selectors';

import { OtherItemsColumns } from './OtherItemsColumns';

export const InvoiceOtherItems = ({ jobData }) => {
  const rows = getInvoiceLineItemsByLineItemType(jobData, [InvoiceItemType.FEE]);
  if (!rows.length) return null;

  return (
    <ThemeProvider>
      <SectionHeader title="Other Line Items" />
      <WrapTable
        columns={OtherItemsColumns}
        rows={rows}
        noDataMessage="No Other Line Items"
        enableTotalsRow
      />
    </ThemeProvider>
  );
};
