import React from 'react';
import PropTypes from 'prop-types';
import { Box, withStyles } from '@material-ui/core';
import _ from 'lodash';
import classNames from 'classnames';
import { MUIForm } from '@buildhero/sergeant';
import Context from 'components/Context';
import PreferredTechniciansFormLayoutComponent from 'components/PreferredTechniciansForm/PreferredTechniciansFormLayoutComponent';
import { selectActualDuration } from 'scenes/Dispatch/queries/useVisitDetails/useVisitDetails.selectors';
import moment from 'moment';
import { DurationInput } from './DurationInput';
import styles from './styles';
import getLayout from './layout';
import ErrorMessage from './ErrorMessage';

class VisitForm extends React.Component {
  constructor(props) {
    super(props);

    let enableAssetDropDown;
    if (Context.getCompanyContext() && !this.props.disableAssetSelection) {
      const { listTenantSettings } = Context.getCompanyContext();
      const assetDropDown =
        listTenantSettings &&
        listTenantSettings.filter(
          setting => setting.settingKey === 'assetTracking' && setting.settingValue === 'true'
        );
      enableAssetDropDown = assetDropDown && assetDropDown.length > 0;
    }

    this.state = {
      enableAssetDropDown
    };

    if (this.props.registerValidateCallback) {
      this.props.registerValidateCallback(this.validateForm);
    }
    this.formService = undefined;
  }

  visitData = () => {
    const { visit = {} } = this.props;
    const { scheduledFor, tentativeDate, tentativeTime, actualDuration, endDate } = visit;
    let visitDate = tentativeDate ? moment(tentativeDate).unix() : undefined;
    let visitTime = tentativeTime;

    if (scheduledFor) {
      const date = moment.unix(scheduledFor);
      visitDate = scheduledFor;
      this.visitDate = visitDate;
      visitTime = date.format('HH:mm');
    }
    return {
      description: visit.description || '',
      prerequisites: visit.prerequisites || '',
      assetIds:
        visit?.assetIds || visit.visitAssets?.items?.map(asset => asset?.propertyAsset?.id) || [],
      formIds: visit?.formIds || visit.formData?.items?.map(form => form?.formId) || [],
      preferredTechnicians: this.props.preferredTechnicians,
      visitDuration: actualDuration ? selectActualDuration(this.props.visit.actualDuration) : 60, // durationInput reqs mins
      visitDate,
      visitTime,
      extraTechsNumber: this.props.crewTimeTracking ? 0 : visit.extraTechsNumber,
      endDate
    };
  };

  validateForm = async () => {
    const errors = await this.formService?.validateForm();
    return Object.keys(errors).length === 0;
  };

  onCreateService = service => {
    this.formService = service;
  };

  onFormChange = data => {
    if (data?.visitDate) {
      this.visitDate = moment
        .tz(moment.unix(data.visitDate).format('YYYY-MM-DD'), '', this.props.companyTimezone)
        .unix();
    }
    this.props.onFormChange(data);
  };

  formOptions = () => {
    return this.props.availableForms?.map(({ id, name }) => ({ label: name, value: id })) || [];
  };

  assetOptions = () => {
    return (
      this.props.propertyAssets?.map(({ id, assetName }) => ({
        label: assetName,
        value: id
      })) || []
    );
  };

  validateStartAndEndTime = values => {
    if (!this.props.isMultiVisits) return;
    const errors = {};
    if (!values.visitDate) errors.visitDate = 'Start date is required';
    if (!values.endDate) errors.endDate = 'End date is required';
    if (!_.isEmpty(errors)) return errors;
  };

  render() {
    const {
      classes,
      locale,
      disableSchedule,
      restrictedEdits,
      departments,
      crews,
      techs: technicians,
      jobDepartments,
      isMultiVisits,
      crewTimeTracking,
      isEdit
    } = this.props;
    const { enableAssetDropDown } = this.state;
    const assetOptions = this.assetOptions();
    const formOptions = this.formOptions();
    const layout = getLayout({
      locale,
      enableAssetDropDown,
      departments,
      crews,
      technicians,
      formOptions,
      assetOptions,
      disableSchedule,
      restrictedEdits,
      jobDepartments,
      isMultiVisits
    });
    return (
      <div className={classNames(classes.root)}>
        <Box className={classes.formContainer}>
          <MUIForm
            layout="edit"
            configuration={layout}
            data={this.visitData()}
            customComponents={{
              PreferredTechniciansFormLayoutComponent: componentProps => (
                <PreferredTechniciansFormLayoutComponent
                  parentFormService={this.formService}
                  crewTimeTracking={crewTimeTracking}
                  visitDate={this.visitDate}
                  isEdit={isEdit}
                  {...componentProps}
                />
              ),
              DurationInput
            }}
            onFormChange={this.onFormChange}
            onCreateService={this.onCreateService}
            onComplete={_.noop}
            validate={this.validateStartAndEndTime}
          />
        </Box>
        <ErrorMessage message={this.props.errorMessage} />
      </div>
    );
  }
}

VisitForm.propTypes = {
  classes: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  visit: PropTypes.object.isRequired,
  techs: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
  jobDepartments: PropTypes.array,
  crews: PropTypes.array.isRequired,
  propertyAssets: PropTypes.array.isRequired,
  availableForms: PropTypes.array.isRequired,
  preferredTechnicians: PropTypes.object.isRequired,
  tentativeDateAndTime: PropTypes.object.isRequired,
  disableSchedule: PropTypes.bool.isRequired,
  restrictedEdits: PropTypes.bool.isRequired,
  disableAssetSelection: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onFormChange: PropTypes.func.isRequired,
  registerValidateCallback: PropTypes.func.isRequired,
  isMultiVisits: PropTypes.bool,
  crewTimeTracking: PropTypes.bool,
  companyTimezone: PropTypes.string,
  isEdit: PropTypes.bool
};

VisitForm.defaultProps = {
  jobDepartments: [],
  isMultiVisits: false,
  crewTimeTracking: false,
  companyTimezone: '',
  isEdit: false
};

export default withStyles(styles)(VisitForm);
