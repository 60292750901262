import gql from 'graphql-tag';

const invoiceMutationNotification = gql`
  subscription mutationNotification(
    $partitionKey: String!
    $entityType: String
    $entityId: String
  ) {
    mutationNotification(
      partitionKey: $partitionKey
      entityType: $entityType
      entityId: $entityId
    ) {
      id
      sortKey
      entityType
      mutationType
      changeLog
    }
  }
`;

export default invoiceMutationNotification;
