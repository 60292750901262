import gql from 'graphql-tag';
import useExtendedMutation from 'customHooks/useExtendedMutation';

const DELETE_VISIT_SUMMARY = gql`
  mutation deleteSummary($partitionKey: String!, $id: String!) {
    deleteSummary(partitionKey: $partitionKey, id: $id) {
      id
    }
  }
`;

const serializer = ({ visitId, tenantId, summaryId }) => ({
  visitId, // used for update
  variables: {
    partitionKey: tenantId,
    id: summaryId
  }
});

const optimisticResponseFactory = ({ summaryId }) => ({
  deleteSummary: {
    __typename: 'Summary',
    id: summaryId
  }
});

const update = (cache, { data: { deleteSummary } }, ref) => {
  cache.modify({
    id: `Visit:${ref.visitId}`,
    fields: {
      summaries(existingSummariesRef) {
        return {
          items: existingSummariesRef.items.filter(
            item => item.__ref !== cache.identify(deleteSummary)
          )
        };
      }
    }
  });
};

export const useDeleteVisitSummary = () => {
  return useExtendedMutation(DELETE_VISIT_SUMMARY, {
    serializer,
    optimisticResponseFactory,
    update
  });
};
