import { gql } from '@apollo/client/core';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { any, bool, number, shape, string } from 'prop-types';

import { DEFAULT_EVENT_DURATION_IN_MIN, ERROR_PROP } from '@dispatch/Dispatch.constants';
import { watchedQueries } from '@dispatch/Dispatch.store';
import { nonVisitDetailsFragment } from '@dispatch/fragments/nonVisitDetailsFragment';

import { NON_VISIT_EVENT_SUBSCRIPTION } from '@dispatch/subscription';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import { getState } from 'redux/store';

import {
  selectCardWidth,
  selectCardXPosition,
  selectEndTime,
  selectStartTime
} from '../DispatchBoard.selectors';

import { getPollInterval } from '../DispatchBoard.utils';

const moment = extendMoment(Moment);

export const GET_DISPATCH_BOARD_NON_VISIT_EVENTS = gql`
    query CompanyNonVisitEvents(
        $partitionKey: String!
        $companySortKey: String!
        $startTime: Int!
        $endTime: Int!
    ) {
        getCompany(partitionKey: $partitionKey, sortKey: $companySortKey) {
            id
            nonVisitEventsView(
                entityConnection: "NonVisitEvent"
                filter: {
                    integerFilters: [
                        { fieldName: "plannedEndTimeUTC", filterInput: { ge: $startTime } }
                        { fieldName: "plannedStartTimeUTC", filterInput: { le: $endTime } }
                    ]
                }
                pagination: { limit: 10000 }
            ) {
                items {
                    ${nonVisitDetailsFragment}
                }
            }
        }
    }
`;

const transform = data => {
  const events = data?.getCompany?.nonVisitEventsView?.items;
  if (!events) {
    return {};
  }

  return events.reduce((result, event) => {
    if (!event.isActive) {
      return result;
    }

    const { employeeId } = event;
    const employeeEvents = result[employeeId] || [];
    const startTime = event.plannedStartTimeUTC;

    const durationInMinutes =
      event.plannedStartTimeUTC && event.plannedEndTimeUTC
        ? (event.plannedEndTimeUTC - event.plannedStartTimeUTC) / 60
        : DEFAULT_EVENT_DURATION_IN_MIN;

    return {
      ...result,
      [employeeId]: [
        ...employeeEvents,
        {
          ...event,
          left: selectCardXPosition(startTime),
          width: selectCardWidth(durationInMinutes),
          range: moment.range(
            moment.unix(event.plannedStartTimeUTC),
            moment.unix(event.plannedEndTimeUTC)
          )
        }
      ]
    };
  }, {});
};

export const getNonVisitEventsQueryVariables = ({ day, weekView, customWeekStartDay }) => {
  const { tenantId: partitionKey, tenantCompanyId } = getState().user;
  const sortKey = `${partitionKey}_Company_${tenantCompanyId}`;

  const dispatchWindowStartTime = selectStartTime({ day, weekView, customWeekStartDay });
  const dispatchWindowEndTime = selectEndTime({ day, weekView, customWeekStartDay });

  return {
    partitionKey,
    companySortKey: sortKey,
    startTime: dispatchWindowStartTime,
    endTime: dispatchWindowEndTime
  };
};

const updateQuery = (prev, { subscriptionData, variables }) => {
  if (!subscriptionData.data) return prev;

  const updatedNonVisit = subscriptionData.data.nonVisitEventUpdateNotification;

  const updatedVisitRange = moment.range(
    moment.unix(updatedNonVisit.plannedStartTimeUTC),
    moment.unix(updatedNonVisit.plannedEndTimeUTC)
  );

  const boardRange = moment.range(moment.unix(variables.startTime), moment.unix(variables.endTime));

  const isInRange = updatedVisitRange.overlaps(boardRange);

  const prevNonVisits = prev?.getCompany?.nonVisitEventsView?.items || [];

  const newItems = isInRange
    ? [updatedNonVisit, ...prevNonVisits.filter(nonVisit => nonVisit.id !== updatedNonVisit.id)]
    : [...prevNonVisits.filter(nonVisit => nonVisit.id !== updatedNonVisit.id)];

  return {
    ...prev,
    getCompany: {
      ...prev.getCompany,
      nonVisitEventsView: {
        ...prev.getCompany.nonVisitEventsView,
        items: newItems
      }
    }
  };
};

export const useDispatchBoardNonVisitEvents = ({ day, weekView, customWeekStartDay }) => {
  const variables = getNonVisitEventsQueryVariables({ day, weekView, customWeekStartDay });

  watchedQueries.useDispatchBoardNonVisitEvents = {
    query: GET_DISPATCH_BOARD_NON_VISIT_EVENTS,
    variables
  };

  return useExtendedQuery(GET_DISPATCH_BOARD_NON_VISIT_EVENTS, {
    // pollInterval: getPollInterval(),
    transform,
    defaultData: {},
    variables,
    subscribeToMore: {
      document: NON_VISIT_EVENT_SUBSCRIPTION,
      variables: { partitionKey: variables.partitionKey },
      updateQuery
    }
  });
};

export const BOARD_NON_VISIT_EVENT_RESPONSE_PROP = shape({
  loading: bool,
  error: ERROR_PROP,
  data: shape({
    __typename: string,
    id: string,
    assignedEntityId: string,
    entityType: string,
    employeeId: string,
    eventTypeId: string,
    isActive: true,
    name: string,
    plannedStartTimeUTC: number,
    plannedEndTimeUTC: number,
    range: shape({
      start: any,
      end: any
    }),
    version: number
  })
});
