import { useExtendedFetchForPMAPI as useExtendedFetch } from 'customHooks/useExtendedFetch';

export const useFetchDailyReport = (id, options) => {
  return useExtendedFetch(
    {
      url: `dailyreports/${id}`
    },
    {
      errorData: [],
      defaultData: [],
      ...options
    }
  );
};
