/* eslint-disable import/prefer-default-export */
export const AttachmentLayout = ({
  maxFileSizeWarningLabel,
  maxFileSize,
  hasInternalFileOption,
  hasShareWithTechsOption,
  attachmentRequired = true,
  subjectLabel = 'Rename File',
  subjectPlaceholder = 'Enter another name for the file',
  descriptionLabel = 'Description',
  descriptionPlaceholder = 'Describe the file',
  subjectRequired = false
} = {}) => ({
  fields: {
    customFileName: {
      default: 'customFileName',
      edit: 'customFileName'
    },
    newFiles: {
      default: 'newFiles',
      edit: 'newFiles'
    },
    comment: {
      default: 'comment',
      edit: 'comment'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        direction: 'row',
        width: 450
      },
      contents: [
        {
          options: {
            direction: 'column'
          },
          contents: [
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FileLink',
                    edit: 'FileUpload'
                  },
                  source: 'newFiles',
                  options: {
                    label: 'Attachment',
                    isRequired: attachmentRequired,
                    multiple: false,
                    maxFileSize,
                    maxFileSizeWarningLabel
                  }
                }
              ]
            },
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel',
                    edit: 'TextInput'
                  },
                  source: 'customFileName',
                  options: {
                    label: subjectLabel,
                    placeholder: subjectPlaceholder,
                    fullWidth: true,
                    alignment: 'left',
                    isRequired: subjectRequired
                  }
                }
              ]
            },
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel',
                    edit: 'TextInput'
                  },
                  source: 'comment',
                  options: {
                    label: descriptionLabel,
                    placeholder: descriptionPlaceholder,
                    fullWidth: true,
                    alignment: 'left',
                    lines: 3
                  }
                }
              ]
            },
            {
              options: { direction: 'row' },
              contents: [
                hasShareWithTechsOption
                  ? {
                      options: {
                        grow: 2,
                        padding: 12
                      },
                      contents: [
                        {
                          component: {
                            default: 'ReadOnlyCheckboxInput',
                            edit: 'CheckboxInput'
                          },
                          source: 'shareWithTechniciansOnMobile',
                          options: {
                            label: 'Share with Technicians on Mobile'
                          }
                        }
                      ]
                    }
                  : { options: {}, contents: [] },
                hasInternalFileOption
                  ? {
                      options: {
                        grow: 1,
                        padding: 12
                      },
                      contents: [
                        {
                          component: {
                            default: 'ReadOnlyCheckboxInput',
                            edit: 'CheckboxInput'
                          },
                          source: 'internalFile',
                          options: {
                            label: 'Internal File'
                          }
                        }
                      ]
                    }
                  : { options: {}, contents: [] }
              ]
            }
          ].filter(Boolean)
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: attachmentRequired
      ? {
          newFiles: {
            type: 'array'
          }
        }
      : [],
    required: attachmentRequired ? ['newFiles'] : []
  },
  validationErrors: attachmentRequired
    ? {
        newFiles: {
          required: 'File is required.'
        }
      }
    : {}
});

export default AttachmentLayout;
