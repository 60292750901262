import { MUIForm } from '@buildhero/sergeant';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  sidebar: {
    minWidth: 242,
    paddingLeft: 24,
    paddingRight: 10
  },
  sidebarItem: {
    marginTop: theme.spacing(2),
    maxWidth: 208
  },
  sidebarTitle: {
    fontSize: '10px',
    color: '#999999',
    textTransform: 'uppercase'
  },
  sidebarContent: {
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#333333',
    wordBreak: 'break-all'
  },
  sidebarHighlightedContent: {
    height: '18px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    letterSpacing: '-0.02em',
    background: '#333333',
    borderRadius: '10px',
    color: 'white',
    textTransform: 'uppercase',
    padding: '0px 8px',
    width: 'fit-content'
  }
}));

const genericSidebarItems = fields => {
  const contents = [];
  fields.forEach(value => {
    contents.push({
      contents: [
        {
          component: value?.component ?? 'sidebarItem',
          source: value.source,
          options: {
            label: value.label
          }
        }
      ]
    });
  });
  return contents;
};

const genericSidebarLayout = fields => {
  return {
    fields: {},
    layouts: {
      view: {
        contents: genericSidebarItems(fields)
      }
    }
  };
};

const convertFormattedString = input => {
  if (input instanceof Date) {
    return input.toDateString().slice(4);
  }
  return input?.toString().length ? input : '-';
};

const sidebarItem = classes => ({ field, options }) => (
  <div className={classes.sidebarItem}>
    <Typography className={classes.sidebarTitle}>{options.label}</Typography>
    <Typography className={classes.sidebarContent}>
      {convertFormattedString(field.value)}
    </Typography>
  </div>
);

const sidebarHighlightedItem = classes => ({ field, options }) => (
  <div className={classes.sidebarItem}>
    <Typography className={classes.sidebarTitle}>{options.label}</Typography>
    <Typography
      className={field.value ? classes.sidebarHighlightedContent : classes.sidebarContent}
    >
      {field.value || '-'}
    </Typography>
  </div>
);

const Sidebar = ({ sidebarData, source, mode = 'view' }) => {
  const classes = useStyles();
  return (
    <div className={classes.sidebar}>
      <MUIForm
        configuration={genericSidebarLayout(sidebarData)}
        data={source}
        customComponents={{
          sidebarItem: sidebarItem(classes),
          sidebarHighlightedItem: sidebarHighlightedItem(classes)
        }}
        layout={mode}
        onCreateService={() => {}}
        onComplete={() => {}}
      />
    </div>
  );
};

export default Sidebar;
