import { PermissionAction, PermissionCategory, PermissionSubcategory } from 'utils/constants';
import PermissionsUtils from 'utils/PermissionsUtils';

class PurchaseOrdersUtils {
  static hasCreatePurchaseOrderPermissions(appPermissionRules) {
    return this.hasRequiredPurchaseOrderPermissions(appPermissionRules, PermissionAction.CREATE);
  }

  static hasUpdatePurchaseOrderPermissions(appPermissionRules) {
    return this.hasRequiredPurchaseOrderPermissions(appPermissionRules, PermissionAction.UPDATE);
  }

  static hasRequiredPurchaseOrderPermissions(appPermissionRules, permissionAction) {
    const convertedAppPermissionRules = JSON.parse(appPermissionRules);
    return (
      Array.isArray(convertedAppPermissionRules) &&
      (PermissionsUtils.hasTenantAdminPermissions(convertedAppPermissionRules) ||
        PermissionsUtils.hasRequiredUserPermissions({
          convertedAppPermissionRules,
          action: permissionAction,
          category: PermissionCategory.OBJECT,
          subcategory: PermissionSubcategory.PURCHASE_ORDER
        }))
    );
  }
}

export default PurchaseOrdersUtils;
