import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  settingIcon: {
    color: theme.palette.secondary.main
  },
  image: {
    width: '175px'
  },
  textEditor: {
    width: '80%'
  },
  // @see https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/content-styles.html
  documentEditorEditableContainer: {
    background: '#EBEBEB',

    /* Make it possible to scroll the "page" of the edited content. */
    overflowY: 'scroll',
    '& .ckEditorEditable ': {
      /* Set the dimensions of the "page". */
      width: '15.8cm',
      minHeight: '21cm',

      /* Keep the "page" off the boundaries of the container. */
      padding: '1cm 2cm 2cm',
      border: '1px hsl( 0,0%,82.7% ) solid',
      borderRadius: 'var(--ck-border-radius)',
      background: 'white',
      boxShadow: '0 0 5px hsla( 0,0%,0%,.1 )',
      margin: '0 auto'
    },
    '& .ck.ck-toolbar': {
      border: 'none',
      borderBottom: '1px solid var(--ck-color-toolbar-border)'
    }
  },
  documentEditor: {
    borderRadius: 'var(--ck-border-radius)',
    display: 'flex',
    flexFlow: 'column nowrap',
    border: '1px solid var(--ck-color-base-border)',
    '& .ck-content .image': {
      width: '150px'
    },
    '& .ck.ck-content.ck-editor__editable': {
      margin: '24px auto',
      /* US Letter size. */
      width: '215.9mm',
      minHeight: '750px',
      padding: '5mm 19mm 19mm 19mm',
      boxSizing: 'border-box',
      fontFamily: 'Inter, sans-serif',
      lineHeight: '1.6em',
      background: 'white'
    },
    '& .ck-content .table': {
      margin: '0.5em auto',
      display: 'table'
    },
    '& .ck-content hr': {
      margin: '.8em auto',
      height: '2px',
      background: '#dedede',
      border: '0'
    },
    '& .ck-content p': {
      fontSize: '1em',
      lineHeight: '1.3em',
      margin: 0,
      paddingTop: '0em',
      marginBottom: '0em'
    }
  },
  documentEditorToolbar: {
    zIndex: 1
  }
}));

export default styles;
