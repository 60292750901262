import React from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  headerSpacing: {
    paddingBottom: 36
  },
  dynamicTitle: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.brand.sectionHint
  }
}));

const CollapsibleSection = props => {
  const [collapsed, setCollapsed] = React.useState(props?.defaultState || false);
  const classes = styles();
  return (
    <>
      <Typography
        variant="h4"
        className={!props.useDynamicTitle ? classes.headerSpacing : classes.dynamicTitle}
      >
        {!props.useDynamicTitle ? props.title : props.getDynamicTitle(collapsed)}
        <IconButton
          size="small"
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          {collapsed ? <ExpandMore fontSize="small" /> : <ExpandLess fontSize="small" />}
        </IconButton>
      </Typography>
      {!collapsed && props.children}
    </>
  );
};

CollapsibleSection.propTypes = {
  title: PropTypes.string.isRequired,
  useDynamicTitle: PropTypes.bool,
  getDynamicTitle: PropTypes.func,
  defaultState: PropTypes.bool
};

CollapsibleSection.defaultProps = {
  useDynamicTitle: false,
  getDynamicTitle: () => '',
  defaultState: false
};

export default CollapsibleSection;
