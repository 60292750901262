import gql from 'graphql-tag';
import useExtendedLazyQuery from 'customHooks/useExtendedLazyQuery';

export const GET_NOTES = gql`
  query getNotes($id: String!) {
    getNotesByParentId(id: $id) {
      id
      subject
      note
      sortOrder
      lastUpdatedDate
      lastUpdatedBy
      hideFromTechniciansOnMobile
      displayLastUpdatedBy
      displayLastUpdatedDateTime
    }
  }
`;

const transform = data => data?.getNotesByParentId;

const serializer = ({ parent }) => ({
  variables: { id: parent?.id }
});

const useLazyQueryNotes = () =>
  useExtendedLazyQuery(GET_NOTES, {
    transform,
    serializer,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

export default useLazyQueryNotes;
