import gql from 'graphql-tag';

export const CREWS = gql`
  query Crews($limit: Int, $offset: Int, $sort: [SortingInput]) {
    crews(pagination: { limit: $limit, offset: $offset }, sorting: $sort) {
      items {
        id
        name
        department {
          id
          tagName
        }
        foreman {
          id
          name
        }
        techs {
          id
          name
        }
      }
      nextToken
    }
  }
`;

export const ADD_CREW = gql`
  mutation AddCrew($input: CrewInput!) {
    addCrewToCompany(input: $input) {
      id
    }
  }
`;

export const CREW = gql`
  query Crew($id: String!) {
    crew(id: $id) {
      id
      name
      department {
        id
        tagName
      }
      foreman {
        id
        name
      }
      techs {
        id
        name
      }
    }
  }
`;

export const UPDATE_CREW = gql`
  mutation UpdateCrew($input: CrewUpdateInput!) {
    updateCrew(input: $input) {
      id
      name
      department {
        id
        tagName
      }
      foreman {
        id
        name
      }
      techs {
        id
        name
      }
    }
  }
`;
