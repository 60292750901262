import React from 'react';
import moment from 'moment';
import { sortBy } from 'lodash';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SergeantModal, ImageUpload } from 'components';
import { PropertyAssetLayout } from 'meta/Customer/PropertyAsset';
import LinkFieldWithLabel from 'scenes/Maintenance/DetailView/common/LinkFieldWithLabel';
import { PermissionConstants } from 'utils/AppConstants';
import { checkPermission } from 'utils';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

export const defaultData = {
  imageUrl: '',
  assetName: '',
  make: '',
  modelNumber: '',
  serialNo: '',
  installDate: moment().unix(),
  location: '',
  propertyZone: ''
};

const AssetModal = ({
  open,
  data = {},
  mode,
  confirmRemoveItemLabel = '',
  user,
  ocrButton = () => null,
  handlePrimaryAction,
  handleClose,
  company
}) => {
  const launchDarklyFlags = useFlags();

  const LogoButton = ({ form, field }) => (
    <ImageUpload form={form} field={field} user={user} label="Add Image" />
  );

  const assetTypeOptions = constructSelectOptions(
    sortBy(company?.assetTypes?.items, 'sortOrder'),
    'tagName'
  );

  const hasServiceAgreements = checkPermission(
    'allow',
    PermissionConstants.OBJECT_SERVICE_AGREEMENT,
    user,
    null,
    null,
    FeatureFlags.SERVICE_AGREEMENTS,
    launchDarklyFlags
  );

  return (
    <SergeantModal
      open={open}
      data={{ ...defaultData, ...data }}
      dataType="Asset"
      mode={mode}
      confirmRemoveItemLabel={confirmRemoveItemLabel}
      layout={PropertyAssetLayout(assetTypeOptions, true, hasServiceAgreements)}
      customComponents={{
        LogoButton,
        OcrButton: ocrButton,
        LinkFieldWithLabel
      }}
      handlePrimaryAction={handlePrimaryAction}
      handleClose={handleClose}
    />
  );
};

const mapStateToProps = state => ({
  user: state.user,
  company: state.company
});

export default connect(mapStateToProps)(AssetModal);
