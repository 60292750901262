const tag = (tagList, source) => ({
  fields: {},
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0,
            padding: [0, 6]
          },
          contents: [
            {
              component: 'BodyText',
              source: 'Tags are used to identify custom statuses'
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel',
                    edit: 'SelectInput'
                  },
                  source,
                  options: {
                    label: 'Tags',
                    placeholder: '',
                    valueSet: tagList,
                    isMultipleSelection: true,
                    enableSort: false
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  }
});

export default tag;
