import { useEffect, useState } from 'react';
import getLabourRates from 'services/core/graphql/pricebook/queries/getLabourRates';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import { generateCompanySortKey } from 'services/core/utils';
import { logErrorWithCallback } from 'utils';

const getLabourRateItems = pricebookItems =>
  pricebookItems.reduce((items, pricebook) => {
    const { pricebookLabourEntries } = pricebook;
    return [...items, ...(pricebookLabourEntries?.items || [])];
  }, []);

const useLabourRates = (tenantId, companyId, snackbarOn, priceBookId) => {
  const [labourRates, setLabourRates] = useState([]);

  const { loading, data, error } = useExtendedQuery(getLabourRates, {
    variables: {
      filter: {},
      partitionKey: tenantId,
      sortKey: generateCompanySortKey(tenantId, companyId)
    }
  });

  useEffect(() => {
    if (error) {
      logErrorWithCallback(
        error,
        snackbarOn,
        'Unable to fetch labour rates, please try again later'
      );
    }

    if (data) {
      const rates = getLabourRateItems(data?.getCompany?.priceBooks?.items || []);
      setLabourRates(priceBookId ? rates?.filter(rate => rate.parentId === priceBookId) : rates);
    }
  }, [data, error, priceBookId, snackbarOn]);

  return {
    loading,
    data: labourRates
  };
};

export default useLabourRates;
