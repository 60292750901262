import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';

const defaultFlexStyle = '0 0 406px';

const generateReceiptFormFields = {
  receiptNumber: { required: true }
};

const generateReceiptContents = [
  // the 1st row
  {
    options: {
      direction: 'row',
      flexWrap: 'nowrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin: 0
        },
        contents: [
          {
            component: {
              edit: 'CustomFieldWithLabelStyled'
            },
            source: 'receiptNumber',
            options: {
              label: 'Receipt Number',
              fullWidth: true,
              numeric: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      }
    ]
  }
];

const generateReceiptFormLayout = {
  fields: generateDefaultFieldsObject(generateReceiptFormFields),
  layouts: {
    edit: {
      options: {
        pageSize: 'LETTER'
      },
      contents: generateReceiptContents
    }
  },
  validation: {
    type: 'object',
    properties: {
      receiptNumber: { type: 'string' }
    },
    required: ['receiptNumber']
  },
  validationErrors: {
    receiptNumber: { required: 'Field is required.' }
  }
};

export { generateReceiptFormFields, generateReceiptFormLayout };
