/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export const useScrollToCurrentTime = ({ boardRef, position, positionOffset }) => {
  useEffect(() => {
    if (boardRef) {
      // eslint-disable-next-line no-param-reassign
      boardRef.scrollLeft = position - positionOffset - (boardRef.clientWidth - positionOffset) / 2;
    }
  }, [boardRef]);
};

export const useSetBoardHeight = ({ setInnerHeight, innerRef, itemData }) => {
  useEffect(() => {
    setInnerHeight(innerRef?.clientHeight);
  }, [itemData, innerRef]);
};

export const useResetListItemSize = ({ listRef, itemData }) => {
  useEffect(() => {
    listRef.current?.resetAfterIndex(0);
  }, [itemData]);
};
