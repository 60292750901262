import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react';

import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withBackgroundColor from 'components/WithBackgroundColor';
import ErrorBoundaries from 'scenes/Error';
import { MAX_DEFAULT_TABLE_HEIGHT } from 'themes/BuildHeroTheme';
import { checkPermission, toggleElementInMap } from 'utils';

import { preprocessFilter } from './Filter';
import ItemCount from './ItemCount';
import ResponsiveAccordions from './ResponsiveAccordions';
import ResponsiveTableToolbar from './ResponsiveTableToolbar';
import ShowMoreButton from './ShowMoreButton';
import styles from './styles';
import TableContent from './TableContent';
import {
  backendToFrontendFilter,
  enforceMetadataDefaults,
  frontendToBackendFilter,
  getComparator,
  getRowId,
  getSubQueryFilters,
  matchesFilterField,
  stableSort,
  updateColumnMetadata
} from './tableUtils';
import ViewContext from './ViewContext';
import { getSettingObjectFromView, queryViews } from './ViewHelper';

const DEFAULT_ROWS_PER_PAGE = 25;

const ACTION = {
  SET_FILTER: 'SET_FILTER',
  SET_ROWS_PER_PAGE: 'SET_ROWS_PER_PAGE',
  SET_PAGE: 'SET_PAGE',
  CLICK_SORT: 'CLICK_SORT',
  SHOW_MORE: 'SHOW_MORE',
  ADD_MORE_ROWS: 'ADD_MORE_ROWS',
  SET_FETCHED_ROWS: 'SET_FETCHED_ROWS',
  SET_VIEWS: 'SET_VIEWS',
  RESET_PAGE: 'RESET_PAGE'
};

const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc'
};

// TODO: `nextToken` is being used in the backend as a total row count rather
// than to fetch the next page.

/**
 *  There are 2 ways this table accesses data -
 *  1. Server data (default) - Let's call this a server data table - all data
 *     loaded using props.service. all sorting/filtering/paging done via backend
 *  2. Local data - Let's call this a local data table - all data passed into
 *     table props. all sorting/filtering/paging done locally via state.
 *     condition: props.isLoading || props.data
 */

/**
 * - there are 3 types of tables
 *  1. Page Table (default) - previous and next buttons in footer
 *     supports both local and server data tables
 *  2. Show More Table - show more button in footer
 *     currently only supports server data tables
 *  3. Unrestricted Table - no footer, all data displayed
 *     currently only supports local data tables
 */

/**
 * @func ResponsiveTable -
 *    Server table: !props.data && !props.isLoading
 *    Local table: props.data || props.isLoading
 *
 *    Page table: default
 *    Show More table: props.fullScreen
 *    Unrestricted table: props.disablePagination
 */

const ResponsiveTable = forwardRef((props, ref) => {
  const {
    // TODO move comments to PropTypes
    classes,
    filter: filterProp,
    defaults,
    service,
    listDataService,
    caslKey,
    rowActionButtons,
    rowActions,
    multiline,
    handleExport,
    exportButtonTitle,
    enableRefreshBtn,
    /**
     * @async
     * @prop {func} refreshAction - cb function called during refresh
     */
    refreshAction,
    rowDetailLayout,
    rowCollapsible,
    getNonEditableList,
    tableName, // Used to store and retrieve the views from database
    refreshTable,
    refreshData,
    refreshQuietly,
    resetPagination,
    /**
     * @prop {boolean} fullScreen - determines if it's a "show more" table where
     *    the footer containing the "show more" button is scrollable with the rest
     *    of the table. Dynamically grows.
     *    Does not handle local data (show more will expect to query database)
     */
    fullScreen,
    /**
     * @prop {boolean} disablePagination - determines if it's an unrestricted
     *    meaning no "show more" and no pages. Should show all data available.
     *    Handles local data, does not handle data that needs to be queried
     */
    showFilters,
    disablePagination,
    noMaxHeight,
    noDataMsg: noDataMsgProp,
    isLoading: isLoadingProp,
    /**
     * @prop {Array} data - if this or isLoading is provided, this is a local data table,
     *  instead of a dynamically loading table
     */
    data,
    onSort,
    resetPage,
    totalRows,
    rowMetadata,
    allowDynamicRowMetadata,
    disableFilter,
    user,
    topPanel,
    actionPanel,
    onChangePage = () => {},
    onChangeRowsPerPage = () => {},
    onRefreshCompleted,
    /**
     * @prop {Boolean} allRowsAreSelectable - enable to use selectAll for tables with rows with selectionDisabled
     */
    allRowsAreSelectable = true,
    timezoneSensitiveColumnIds = []
  } = props;

  // const metadata = props.rowMetadata
  //   .filter(meta => checkPermission(meta.caslAction || 'read', meta.caslKey, props.user))
  //   .map(meta => enforceMetadataDefaults(meta));

  // /** * SECTION: FILTERING AND SORTING *** */
  // // Collection of filter criteria (in server-side format)
  // const [filter, setFilter] = useState(props.filter || {});
  // const [sortOrder, setSortOrder] = useState(defaults?.sortOrder);
  // const [sortBy, setSortBy] = useState(defaults?.sortBy);
  // const sortType = metadata.find(columnMeta => columnMeta.id === sortBy)?.sortType;
  // const [filteredLocalRows, setFilteredLocalRows] = useState(props.data);

  // rows per query, used for both page and "show more" server-data tables
  const noDataMsg = noDataMsgProp || 'No data to display.';
  // need to have columns metadata state to enable showing/hiding columns
  const [metadata, setColumnsMetadata] = useState(rowMetadata);

  // intentionally setting default state as null to avoid using another state varaible
  // when null views are note fetched. the queries will be delayed.
  // empty array there are no records in backend
  // views should always be null checked for the above reason
  const [views, setViews] = React.useState(null);
  const [viewsLoaded, setViewsLoaded] = React.useState(false);
  const [needToUpdateColumnMeta, setNeedToUpdateColumnMeta] = React.useState(true);
  const [totalNumRows, setTotalNumsRows] = React.useState();

  /**
   * Views will have meta, filter, sortBy, sortOrder, noOfRows in a stringfied JSON in userSettings object's setting attribute
   */

  // set columns metadata from props.rowMetadata after some validation/filtering/adding
  // default column properties
  useEffect(() => {
    // isNewView || disableFilter do not want to overwrite meta filters if they were set by custom views
    // if custom views are disabled (by disabledFilter prop) then allow overwrite
    if (rowMetadata && (needToUpdateColumnMeta || disableFilter)) {
      setNeedToUpdateColumnMeta(false);
      const processedColumnsMetadata = _.cloneDeep(metadata || rowMetadata)
        .filter(meta => checkPermission(meta.caslAction || 'read', meta.caslKey, user))
        .map(meta => enforceMetadataDefaults(meta));
      setColumnsMetadata(updateColumnMetadata(processedColumnsMetadata));
    }
  }, [user, rowMetadata, needToUpdateColumnMeta, setNeedToUpdateColumnMeta, disableFilter]);

  useEffect(() => {
    if (allowDynamicRowMetadata) setNeedToUpdateColumnMeta(true);
  }, [rowMetadata]);

  const reorderColumn = columnsMetadata => {
    setColumnsMetadata([...columnsMetadata]);
  };

  /** * SECTION: FETCHING AND PAGINATION *** */

  // To render table toolbar, at least one of filter, add row button, or refresh button, or export button should be enabled.
  const showTableToolbar =
    !disableFilter || props.addRow || enableRefreshBtn || handleExport || actionPanel;

  // List of id's of deactivated entities that are not editable.
  // uses prop.getNonEditableList
  const [nonEditableList, setNonEditableList] = useState([]);

  /** * SECTION: ROW SELECTION *** */
  const isSelectionEnabled = !!rowActionButtons?.select;

  const { select: selectButton, ...actionButtons } = rowActionButtons || {};
  const defaultSelectedRow =
    isSelectionEnabled && rowActionButtons.select?.defaultSelectedRow
      ? rowActionButtons.select?.defaultSelectedRow
      : null;

  // Maps have row id as key and object containing all row data (including id) as value
  const [selectedRows, setSelectedRows] = useState(
    defaultSelectedRow ? new Map([[defaultSelectedRow.id, defaultSelectedRow]]) : new Map()
  );
  const [expandedRows, setExpandedRows] = useState(new Map());

  const executeSelectAction = newSelectedRows => {
    if (isSelectionEnabled && rowActions) {
      // Most clients of this component expect a regular array of selected items.
      // Hide our Map implementation from these clients for simplicity.
      rowActions('select', Array.from(newSelectedRows.values()));
    }
  };

  const handleSelectRowClick = (event, row) => {
    event.stopPropagation(); // Prevent row itself from also registering a click
    const updatedRows = new Map(selectedRows);
    selectedRows.forEach((row, selectedRowId) => {
      const notFound = data?.findIndex(row => row?.id === selectedRowId) === -1;
      if (notFound) {
        updatedRows.delete(selectedRowId);
      }
    });
    setSelectedRows(updatedRows);
    if (!row?.selectionDisabled) {
      toggleElementInMap(updatedRows, getRowId(row), row);
      setSelectedRows(updatedRows);
      executeSelectAction(updatedRows);
    }
  };

  // Uses the id of the selected row to determine if a row wasn't found
  // instead of the index of the forEach loop. The standard function would
  // only allow for one row to be selected at a time.
  const handleSelectRowClickAlt = (event, selectedRow) => {
    event.stopPropagation(); // Prevent row itself from also registering a click
    const updatedRows = new Map(selectedRows);
    selectedRows.forEach((row, index) => {
      const notFound = data?.findIndex(r => r?.id === selectedRow.id) === -1;
      if (notFound) {
        updatedRows.delete(index);
      }
    });
    setSelectedRows(updatedRows);
    if (!selectedRow?.selectionDisabled) {
      toggleElementInMap(updatedRows, getRowId(selectedRow), selectedRow);
      setSelectedRows(updatedRows);
      executeSelectAction(updatedRows);
    }
  };

  let selectHandler = () => {};
  if (isSelectionEnabled) {
    selectHandler = rowActionButtons.alternativeSelect
      ? handleSelectRowClickAlt
      : handleSelectRowClick;
  }

  // For tables with expandable content
  const handleRowClick = (event, row, isDnd) => {
    props.onRowClick?.(event, row, isDnd);
    const newExpandedRows = new Map(expandedRows);
    toggleElementInMap(newExpandedRows, getRowId(row), row);
    setExpandedRows(newExpandedRows);
  };

  // does this component handle querying the server internally within the component?
  const isServerTable = !data && !isLoadingProp;

  // Total count of entries (including those not yet fetched from the server)
  // that match the current applied filters
  // only used for server data tables
  const totalServerRowCount = useRef(0);
  // Ref that keeps track of refreshData over time.
  const refreshDataRef = useRef(refreshData);
  const needsRefetch = useRef(true);
  const needsToFetchMoreIfNeeded = useRef(false);
  // is fetching more records on top of existing records
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  // is refetching all records
  const [isRefetching, setIsRefetching] = useState(false);

  // total number of items is data.length, else, grab it from the server
  // If no server result, then the query is not passing back the total count,
  //  so we don't know the total count. Set it to null.

  useEffect(() => {
    let rowsTotal;
    if (isServerTable) {
      rowsTotal = totalServerRowCount.current ? parseInt(totalServerRowCount.current, 10) : null;
    } else if (totalRows) {
      rowsTotal = totalRows;
    } else {
      rowsTotal = data?.length;
    }
    // at some places data is coming as false. objects
    if (Array.isArray(data) && data?.findIndex(r => r.totalRows) >= 0) {
      rowsTotal -= 1;
    }

    setTotalNumsRows(rowsTotal);
  }, [isServerTable, data, totalRows, isRefetching, isLoadingMore]);

  const initialState = {
    sortOrder: defaults?.sortOrder,
    sortBy: defaults?.sortBy,
    page: 0,
    rowsPerPage: defaults?.rowsPerPage || DEFAULT_ROWS_PER_PAGE,
    // only used by server table
    fetchedRows: [],
    // Collection of filter criteria (in server-side format)
    filter: filterProp || {},
    // number of rows visible. only used for "show more" table
    numRowsVisible: defaults?.rowsPerPage || DEFAULT_ROWS_PER_PAGE
  };

  // this is not a pure reducer. If this is a problem, we can move the ref
  // variable changes to right before the related dispatch is called.
  const reducer = (state, action) => {
    switch (action.type) {
      case ACTION.SET_FILTER:
        needsRefetch.current = true;
        return {
          ...state,
          page: 0,
          filter: action.payload,
          numRowsVisible: Math.max(state.numRowsVisible, state.rowsPerPage),
          fetchedRows: []
        };
      case ACTION.SET_ROWS_PER_PAGE:
        if (action.payload > state.fetchedRows.length || action.payload > state.numRowsVisible)
          needsRefetch.current = true;
        return {
          ...state,
          rowsPerPage: action.payload,
          page: 0, // there is dependant code on this being zero (see comment labeled as "Dependant on reducer math")
          numRowsVisible: action.payload
        };
      case ACTION.CLICK_SORT: {
        // If the selected sort attribute was already the sort attribute,
        // the user is toggling the sort direction; otherwise always sort descending by default
        const newSortBy = action.payload;
        const isDesc = state.sortBy === newSortBy && state.sortOrder === SORT_ORDER.DESC;
        const newSortOrder = isDesc ? SORT_ORDER.ASC : SORT_ORDER.DESC;
        // don't do anything if sort state is unchanged
        if (newSortBy === state.sortBy && newSortOrder === state.sortOrder) return state;

        needsRefetch.current = true;
        if (onSort) {
          onSort(newSortBy, newSortOrder);
        }
        return {
          ...state,
          sortOrder: newSortOrder,
          sortBy: newSortBy,
          numRowsVisible: Math.max(state.numRowsVisible, state.rowsPerPage),
          page: 0,
          fetchedRows: []
        };
      }
      case ACTION.SET_PAGE:
        needsToFetchMoreIfNeeded.current = true;
        return {
          ...state,
          page: action.payload
        };
      case ACTION.SHOW_MORE: {
        needsToFetchMoreIfNeeded.current = true;
        return {
          ...state,
          numRowsVisible: Math.min(state.numRowsVisible + state.rowsPerPage, action.payload)
        };
      }
      case ACTION.ADD_MORE_ROWS: {
        const newRows = [...state.fetchedRows, ...action.payload];
        return {
          ...state,
          fetchedRows: newRows,
          numRowsVisible: newRows.length
        };
      }
      case ACTION.SET_FETCHED_ROWS: {
        return {
          ...state,
          fetchedRows: action.payload,
          numRowsVisible: action.payload.length
        };
      }
      case ACTION.SET_VIEWS: {
        needsRefetch.current = true;
        return {
          ...state,
          page: 0,
          filter: action.payload.filter,
          sortOrder: action.payload.sortOrder || state.sortOrder,
          sortBy: action.payload.sortBy || state.sortBy,
          rowsPerPage: action.payload.rowsPerPage || DEFAULT_ROWS_PER_PAGE,
          numRowsVisible: action.payload.rowsPerPage || DEFAULT_ROWS_PER_PAGE
        };
      }
      case ACTION.RESET_PAGE: {
        return {
          ...state,
          page: 0
        };
      }
      default:
        throw new Error(`Unhandled action type ${action.type}`);
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  /**
   * @abstract change in @constant filter should trigger refetch even if @constant disableFilter is not set.
   * this is needed for components with both @function ResponsiveTableToolbar filter and parent component filters. eg: (FollowUp)
   */
  // PLEASE DEPRECATE THIS - THIS IS VERY DANGEROUS AND PROMOTES BAD CODE!
  // DO NOT HAVE THE FILTER STATE BE IN 2 PLACES!
  // IF USED WRONG THIS WILL CAUSE INFINITE TABLE REFRESH IF YOU DON'T KNOW THE
  // RENDER LIFECYCLE
  // USE props.topPanel TO APPLY FILTERS INSTEAD
  useEffect(() => {
    if (filterProp) dispatch({ type: ACTION.SET_FILTER, payload: filterProp });
  }, [filterProp]);

  useEffect(() => {
    dispatch({ type: ACTION.RESET_PAGE });
  }, [resetPage]);

  const setViewForTable = (setting, standardMeta) => {
    const { meta, numberOfRows, filters, sortOrder, sortBy } = setting;

    // need to account for changes that have been made through custom fields and meta updates after the custom view was created
    const mergedMetaData = meta?.map(savedColumn => ({
      ...savedColumn,
      ...standardMeta?.find(standardColumn => standardColumn.id === savedColumn.id)
    }));

    setColumnsMetadata(mergedMetaData);

    const processedFilter = Object.keys(filters).reduce(
      (processedFilterConditions, filterKey) =>
        preprocessFilter(
          filters[filterKey].value,
          filters[filterKey].type,
          filterKey,
          {
            [filterKey]: filters[filterKey],
            ...processedFilterConditions
          },
          () => {},
          meta
        ),
      {}
    );

    const combinedFilter = filterProp
      ? { ...frontendToBackendFilter(processedFilter), ...filterProp }
      : frontendToBackendFilter(processedFilter);
    dispatch({
      type: ACTION.SET_VIEWS,
      payload: {
        filter: combinedFilter,
        sortOrder,
        sortBy,
        rowsPerPage: numberOfRows,
        numRowsVisible: numberOfRows
      }
    });
  };

  React.useEffect(() => {
    const queryViewsFromServer = async () => {
      setViewsLoaded(false);
      const queriedViews = await queryViews(user, tableName);
      // * Using Boolean to convert when isDefault is set as 1 instead of true
      const defaultViewForUser = queriedViews?.find(
        v => Boolean(v?.userSettingEmployees?.items?.[0]?.isDefault) === true
      );
      if (defaultViewForUser) {
        const defaultViewSetting = getSettingObjectFromView(defaultViewForUser);
        setViewForTable(defaultViewSetting, rowMetadata);
      }
      setViews(queriedViews);
      setViewsLoaded(true);
    };

    if (tableName && user.tenantId) {
      queryViewsFromServer();
    } else {
      setViews([]);
      setViewsLoaded(true);
    }
  }, [tableName, user]);

  // only used with listDataService
  const columns = useMemo(() => {
    if (metadata == null) return null;
    return metadata.map(col => col.id);
  }, [metadata]);

  const fetchMoreIfNeeded = useCallback(
    async (filter, numRowsToFetch, sortBy, sortOrder) => {
      if (!isServerTable) return;
      if (
        !fullScreen &&
        Math.min(totalServerRowCount.current, (state.page + 1) * state.rowsPerPage) <
          state.fetchedRows.length
      ) {
        return;
      }
      setIsLoadingMore(true);
      let newRows; /* @type {Array.<Object>} */
      let newTotalRowCount; /* @type {string} */
      if (listDataService) {
        const response = await listDataService(
          columns,
          filter,
          sortBy ? [{ sortField: sortBy, sortDirection: sortOrder }] : null,
          { limit: numRowsToFetch, offset: state.fetchedRows.length }
        );
        const { items, totalRecordCount } = response;
        newRows = items;
        newTotalRowCount = totalRecordCount.toString();
      } else {
        const { items, nextToken } = await service(
          filter,
          numRowsToFetch,
          state.fetchedRows.length,
          sortBy,
          sortOrder
        );
        newRows = items;
        newTotalRowCount = nextToken;
      }
      setIsLoadingMore(false);

      // ignore response if needs to refetch/in the process of refetching
      if (needsRefetch.current) return;
      totalServerRowCount.current = newTotalRowCount;
      dispatch({ type: ACTION.ADD_MORE_ROWS, payload: newRows });
    },
    [service, state, fullScreen, isServerTable, columns, listDataService]
  );

  useEffect(() => {
    if (typeof resetPagination !== 'undefined') {
      dispatch({ type: ACTION.RESET_PAGE });
    }
  }, [resetPagination]);

  const [isRefreshing, setIsRefreshing] = useState();
  const refresh = () => {
    setIsRefreshing(true);
    needsRefetch.current = true;
  };

  const refetch = useCallback(
    async (filter, numRowsToFetch, sortBy, sortOrder) => {
      if (!isServerTable) return;
      if (!refreshQuietly) {
        setIsRefetching(true);
      }

      if (listDataService) {
        const response = await listDataService(
          columns,
          filter,
          sortBy ? [{ sortField: sortBy, sortDirection: sortOrder }] : null,
          { limit: numRowsToFetch, offset: 0 }
        );
        const { items, meta, totalRecordCount = '' } = response;

        // @TODO remove this sort when it is implemented in the backend.
        // Currently:
        // meta columns come back from BE query in a fixed order.
        // Needs to be reordered to match user's preference which is reflected in the columns variable
        // Ideally:
        // { meta } = response comes back with columns already sorted in the same order as the `columns` query paramter

        const metaWithInternalData = [
          ...(meta || []),
          ...(metadata || [])?.filter(record => record.internal)
        ];

        const sortedMeta = Array.isArray(columns)
          ? metaWithInternalData?.slice()?.sort((a, b) => {
              const AIndex = columns.findIndex(c => c === a.id);
              const BIndex = columns.findIndex(c => c === b.id);
              return AIndex - BIndex;
            })
          : meta;

        const sortedMetaWithHiddenColumns = Array.isArray(metadata)
          ? sortedMeta?.map((m, i) => ({
              ...m,
              hide: metadata[i]?.hide || sortedMeta[i]?.hide
            }))
          : sortedMeta;

        const mergedMetaData = sortedMetaWithHiddenColumns?.map(serverMeta => {
          const localMeta = (metadata || []).find(m => serverMeta.id === m.id);
          if (!localMeta) return serverMeta;

          return localMeta;
        });

        setColumnsMetadata(mergedMetaData);

        totalServerRowCount.current = totalRecordCount?.toString() || '0';
        dispatch({ type: ACTION.SET_FETCHED_ROWS, payload: items || [] });
      } else {
        /*
         * SOME QUERIES ARE NOT RETURNING NEW TOTAL ROW COUNT, AND SOME ARE
         * RETURNING UNDEFINED INSTEAD OF EMPTY LIST IF NO RECORDS FOUND
         * TODO: MAKE QUERYING PATTERNS CONSISTENT ACROSS THE APP
         */
        const { items: newRows = [], nextToken: newTotalRowCount } = await service(
          filter,
          numRowsToFetch,
          0,
          sortBy,
          sortOrder
        );
        // nextToken from the service call is a stringified number (see proptypes for service)
        totalServerRowCount.current = newTotalRowCount ?? newRows.length.toString();
        dispatch({ type: ACTION.SET_FETCHED_ROWS, payload: newRows });

        if (getNonEditableList) {
          const list = await getNonEditableList.service(newRows, getNonEditableList.statusPath);
          setNonEditableList(list);
        }
      }

      if (isRefreshing) {
        await refreshAction();
        setIsRefreshing(false);
      }
      setIsRefetching(false);
    },
    [
      service,
      listDataService,
      getNonEditableList,
      isRefreshing,
      refreshQuietly,
      refreshAction,
      isServerTable,
      columns,
      metadata
    ]
  );

  useEffect(() => {
    // Use getNonEditableList for local data table if available
    if (getNonEditableList && data && !service) {
      const list = getNonEditableList.service(data, getNonEditableList.statusPath);
      setNonEditableList(list);
    }
  }, [getNonEditableList, data, service]);

  // FILTER LOGIC

  const applyBackendFilter = useCallback(backendFilter => {
    if (!backendFilter) return;
    dispatch({ type: ACTION.SET_FILTER, payload: backendFilter });
  }, []);

  const applyFrontendFilter = useCallback(
    (frontendFilter, persistSubquery = true) => {
      if (!frontendFilter) return;
      applyBackendFilter(
        frontendToBackendFilter(
          frontendFilter,
          persistSubquery && getSubQueryFilters(state?.filter, metadata)
        )
      );
    },
    [applyBackendFilter, metadata, state.filter]
  );

  // refetch if needed
  useEffect(() => {
    if (views === null) return;
    if (needsRefetch.current || refreshDataRef.current !== refreshData) {
      needsRefetch.current = false;
      // TODO: implement this for page tables
      const numRowsToFetch = Math.max(state.numRowsVisible, state.rowsPerPage);
      refetch(state.filter, numRowsToFetch, state.sortBy, state.sortOrder).finally(() => {
        onRefreshCompleted?.();
        refreshDataRef.current = refreshData;
      });
      // Reset checkboxes if no default selected checkbox
      if (!rowActionButtons?.select?.defaultSelectedRow) {
        setSelectedRows(new Map());
      }
    }
  }, [state, refetch, refreshData, views]);

  // fetch more if needed
  useEffect(() => {
    if (views === null) return;
    if (needsToFetchMoreIfNeeded.current && !needsRefetch.current) {
      // by default, fetch "rowsPerPage" number of records.
      // If "rowsPerPage" > the number of records left to fetch, then only fetch
      // the remaining records.
      const numRowsToFetch = Math.min(
        state.rowsPerPage,
        totalServerRowCount.current - state.fetchedRows.length
      );

      if (!numRowsToFetch) {
        return;
      }

      // once triggered the refetch, mark current as false, so that this hook is not executed again
      needsToFetchMoreIfNeeded.current = false;
      fetchMoreIfNeeded(state.filter, numRowsToFetch, state.sortBy, state.sortOrder);
    }
  }, [state, fetchMoreIfNeeded, views, isLoadingMore]);

  const visibleRows = useMemo(() => {
    const sortType = metadata?.find(columnMeta => columnMeta.id === state.sortBy)?.sortType;
    const rows = isServerTable
      ? state.fetchedRows
      : stableSort(
          _.compact(data),
          getComparator(onSort ? null : state.sortOrder, onSort ? null : state.sortBy, sortType)
        );

    let filteredRows = [...rows];

    // apply filter locally if it's a local data table
    if (!isServerTable) {
      const frontendFilter = backendToFrontendFilter(state.filter, metadata);
      Object.keys(frontendFilter).forEach(fieldName => {
        filteredRows = rows.filter(row =>
          matchesFilterField(row, frontendFilter[fieldName], fieldName)
        );
      });
    }

    const totalsIndex = filteredRows.findIndex(row => row.totalsRow);
    const totalsRow = filteredRows[totalsIndex];
    if (totalsIndex >= 0) {
      filteredRows.splice(totalsIndex, 1);
    }

    let visRows;
    // unrestricted table
    if (disablePagination) {
      visRows = filteredRows;
      // show more table
    } else if (fullScreen) {
      visRows = filteredRows.slice(0, state.numRowsVisible);
      // page table
    } else {
      visRows = filteredRows.slice(
        state.page * state.rowsPerPage,
        (state.page + 1) * state.rowsPerPage
      );
    }

    // put totals row on the end of all visible rows
    if (totalsIndex >= 0) {
      visRows.push(totalsRow);
    }

    return visRows;
  }, [state, isServerTable, data, disablePagination, fullScreen, metadata]);

  const handleSelectAllClick = event => {
    const newSelectedRows = new Map();
    if (event.target.checked)
      visibleRows.forEach(row => {
        if (!row?.selectionDisabled) {
          newSelectedRows.set(getRowId(row), row);
        }
      });
    setSelectedRows(newSelectedRows);
    executeSelectAction(newSelectedRows);
  };

  const isLoading = isRefetching || isLoadingProp || isRefreshing || (tableName && views === null);
  const isTableContentLoading = isLoading || (!fullScreen && isLoadingMore);

  const handleRequestSort = (event, newSortBy) => {
    dispatch({ type: ACTION.CLICK_SORT, payload: newSortBy });
  };

  const handleChangePage = page => {
    onChangePage(page);
    dispatch({ type: ACTION.SET_PAGE, payload: page });
  };

  const handleChangeRowsPerPage = rowsPerPage => {
    onChangeRowsPerPage(rowsPerPage);
    onChangePage(0); // Dependant on reducer math
    dispatch({ type: ACTION.SET_ROWS_PER_PAGE, payload: rowsPerPage });
  };

  // If entirely new data is loading (i.e. spinner is showing), pagination
  // has been disabled, or all data satisfying the filter criteria is
  // already visible to the user, no need for the Show More button.
  const totalRowCount = isServerTable ? totalServerRowCount.current : data?.length;
  const isShowMoreDisabled = state.numRowsVisible >= totalRowCount;

  const handleShowMoreClick = () => {
    dispatch({ type: ACTION.SHOW_MORE, payload: totalRowCount });
  };

  const renderShowMoreButton = () => (
    <ShowMoreButton
      disabled={isShowMoreDisabled || isLoadingMore || isRefreshing}
      loading={isLoadingMore}
      onClick={() => handleShowMoreClick()}
    />
  );

  /** * SECTION: STYLING *** */
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));

  // If content should fill rest of viewport (for fullscreen tables), calculate
  // the appropriate maximum height.
  const mainPadding = theme.mixins.main.padding || 0;
  const [maxContentHeight, setMaxContentHeight] = useState(window.innerHeight - 2 * mainPadding);
  const topYRef = useRef();
  const updateMaxContentHeight = () => {
    const contentTopY = topYRef.current?.getBoundingClientRect()?.y + window.scrollY || 0;
    const newMaxContentHeight = window.innerHeight - contentTopY - mainPadding;
    setMaxContentHeight(newMaxContentHeight);
  };
  useEffect(() => {
    if (fullScreen) window.addEventListener('resize', updateMaxContentHeight);
    return () => {
      window.removeEventListener('resize', updateMaxContentHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!fullScreen || !topYRef.current) return;
    updateMaxContentHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topYRef, fullScreen]);

  // All in pixels
  const defaultMinHeight = 150;
  const defaultMaxHeight = noMaxHeight ? undefined : MAX_DEFAULT_TABLE_HEIGHT;
  const computedMaxHeight = fullScreen ? maxContentHeight : defaultMaxHeight;
  let { maxHeight } = props;
  if (computedMaxHeight) {
    maxHeight = Math.max(defaultMinHeight, computedMaxHeight);
  }

  const renderFooter = () => {
    if (disablePagination) return null;
    return (
      <div className={classes.footer}>
        {isBigScreen ? (
          <div className={classes.footerRow}>
            <ItemCount
              numItemsDisplayed={visibleRows?.length}
              totalNumItems={totalNumRows || 0}
              limitPerPage={state.rowsPerPage}
              rowsPerPageOptions={[25, 50, 75]}
              disabled={isRefetching || isLoadingMore}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {renderShowMoreButton()}
          </div>
        ) : (
          renderShowMoreButton()
        )}
      </div>
    );
  };

  // metadata can be fetched from views stored in backend or from the metadata passed
  // defaultSetting is created to revert back to system defaults
  const defaultSetting = {
    meta: props.rowMetadata,
    numberOfRows: defaults?.rowsPerPage || DEFAULT_ROWS_PER_PAGE,
    filters: filterProp || {},
    sortOrder: defaults?.sortOrder,
    sortBy: defaults?.sortBy
  };

  return (
    <ErrorBoundaries>
      {topPanel({
        filter: backendToFrontendFilter(state.filter, metadata),
        setFilter: applyFrontendFilter,
        loading: isLoading
        // can extend this to have more properties if necessary
      })}
      <div className={classes.outerContainer}>
        {showTableToolbar && (
          <ViewContext.Provider
            value={{
              views,
              setViews,
              viewsLoaded,
              setViewsLoaded
            }}
          >
            <ResponsiveTableToolbar
              actionPanel={actionPanel}
              addRow={props.addRow}
              applyFilter={frontendFilter => {
                applyFrontendFilter(frontendFilter);
              }}
              defaultSetting={defaultSetting}
              disableFilter={disableFilter}
              enableRefreshBtn={enableRefreshBtn}
              exportButtonTitle={exportButtonTitle}
              filter={backendToFrontendFilter(state.filter, metadata)}
              fullScreen={fullScreen || showFilters}
              handleExport={handleExport}
              handleViewChange={(setting, switchedToStandardView = false) => {
                if (switchedToStandardView) {
                  setNeedToUpdateColumnMeta(true);
                }
                setViewForTable(setting, rowMetadata);
              }}
              isListDataTable={!!listDataService}
              isRefreshing={isRefreshing}
              metadata={metadata || []}
              noOfRows={Math.max(state.numRowsVisible, state.rowsPerPage)}
              refresh={refresh}
              reorderColumn={reorderColumn}
              selectedRecords={Array.from(selectedRows.values())}
              shouldUseQueries={isServerTable}
              sortBy={state.sortBy}
              sortOrder={state.sortOrder}
              tableName={tableName}
              user={props.user}
            />
          </ViewContext.Provider>
        )}
        <div ref={topYRef} />
        <div ref={ref}>
          {isBigScreen ? (
            <TableContent
              backgroundColor={props.selfBackgroundColor}
              caslKey={caslKey}
              customCellComponents={props.customCellComponents}
              dragEligible={props.dragEligible}
              dragItemName={props.dragItemName}
              dragOptions={props.dragOptions}
              dragPreviewImg={props.dragPreviewImg}
              dragTransform={props.dragTransform}
              expandedRows={expandedRows}
              footerComponent={() => renderFooter()}
              hasSummaryRow={props.hasSummaryRow}
              isFullScreen={fullScreen}
              isListDataTable={!!listDataService}
              isLoading={isTableContentLoading}
              isPaginationDisabled={disablePagination}
              isSelectionEnabled={isSelectionEnabled}
              isRowClickAction={Boolean(props.onRowClick)}
              rowCollapsible={rowCollapsible}
              maxHeight={maxHeight}
              metadata={metadata || []}
              multiline={multiline}
              noDataMsg={noDataMsg}
              nonEditableList={nonEditableList}
              page={state.page}
              refreshTable={refreshTable}
              reorderColumn={reorderColumn}
              rowActionButtons={actionButtons}
              rowActions={rowActions}
              rowDetailLayout={rowDetailLayout}
              rows={visibleRows}
              rowsPerPage={state.rowsPerPage}
              rowsTotalCount={totalNumRows}
              selectedRows={selectedRows}
              shouldUseQueries={isServerTable}
              sortBy={state.sortBy}
              sortOrder={state.sortOrder}
              user={props.user}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              onMouseEnterRow={props.onMouseEnterRow}
              onMouseLeaveRow={props.onMouseLeaveRow}
              onRequestSort={handleRequestSort}
              onRowClick={handleRowClick}
              onSelectAllClick={handleSelectAllClick}
              onSelectRowClick={selectHandler}
              allRowsAreSelectable={allRowsAreSelectable}
              timezoneSensitiveColumnIds={timezoneSensitiveColumnIds}
            />
          ) : (
            <ResponsiveAccordions
              caslKey={caslKey}
              customCellComponents={props.customCellComponents}
              footerComponent={() => renderFooter()}
              isLoading={isLoading}
              isSelectionEnabled={isSelectionEnabled}
              maxHeight={maxHeight}
              metadata={metadata || []}
              noDataMsg={noDataMsg}
              onSelectRowClick={selectHandler}
              rowActionButtons={actionButtons}
              rowActions={rowActions}
              rows={visibleRows}
              selectedRows={selectedRows}
              user={props.user}
            />
          )}
        </div>
      </div>
    </ErrorBoundaries>
  );
});

ResponsiveTable.propTypes = {
  // default table initial params
  defaults: PropTypes.shape({
    // metadata id of the column to sort on
    sortBy: PropTypes.string,
    // "asc" or "desc"
    sortOrder: PropTypes.string,
    /**
     * @prop {number} rowsPerPage -
     *  for a "show more" table , this is the default number of rows to load at once.
     *  for a page table, this is the default number of rows in one page
     */
    rowsPerPage: PropTypes.number
  }),
  // hide the default filter control?
  disableFilter: PropTypes.bool,

  /**
   * Please check `input GenericFilterInput` in backend schema for more details
   * @typedef {Object} GenericFilterInput
   * @prop {bool} filterRelatedEntityData - not sure what this does, may
   *    be self-explanatory
   * @prop {Array.<StringFilter>} stringFilters
   * @prop {Array.<FloatFilter>} floatFilters
   * @prop {Array.<IntegerFilter>} integerFilters
   * @prop {Array.<BooleanFilter>} booleanFilters
   * @prop {Array.<SubQueryFilter>} subQueryFilter
   */

  /**
   * @type {GenericFilterInput}
   * pass in filter as props. Used for initializing filter, and if props.disableFilter,
   * then every time props.filter changes, also change table filter. -
   * TODO(sean) deprecate this behavior in favor of quickFilterHeader
   * e.g.
   *  {
   *    stringFilters: [
   *      {
   *        fieldName: 'status',
   *        filterInput: { eq: quickStatusFilter }
   *      }
   *    ]
   *  }
   */
  filter: PropTypes.objectOf(
    PropTypes.arrayOf({
      // metadata id of column
      fieldName: PropTypes.string,
      filterInput: PropTypes.object
    })
  ),
  /**
   * @prop {boolean} isLoading - loading all data externally before passing it
   *  into the table component. Since the data prop would/could be falsy before
   *  the data loads for the table, this is also used to indicate that this is
   *  a local data table, not a dynamically loading table.
   */
  isLoading: PropTypes.bool,
  // message to display if no data found
  noDataMsg: PropTypes.string,
  /**
   * @prop {hook} refreshData - a state var/hook passed in that, when changed,
   *    triggers a refetch of the dynamic table. refreshDataRef is initialized
   *    to keep track of this and detect the change.
   */
  refreshData: PropTypes.any,
  /**
   * @func refreshTable - a function to refresh the table (eg. local data table)
   *    passed down to DisplayData to use in custom cells (eg. refresh on add/delete note)
   */
  refreshTable: PropTypes.func,
  /**
   * @prop refreshQuietly - a boolean flag that controls whether a table should switch to the loading state
   *    when refetch happens
   */
  refreshQuietly: PropTypes.bool,
  // adds refresh button to toolbar. only used for server-data tables.
  enableRefreshBtn: PropTypes.bool,

  // When table name is added in the table, Views and adjusting column will be enabled
  // The table name will be used in userSetting and stored in the attribute 'name'
  tableName: PropTypes.string,

  /**
   * @typedef {Object} ServiceResponse
   * @prop {Array.<Object>} items - data records, usually have an id
   * @prop {string} nextToken - stringified number of total records in the
   *    database matching filters. If not passed in, the number of total records
   *    will default to items.length.toString()
   */

  /**
   * NOTE: hopefully we can move away from this to the python lambda for all
   * exportable tables
   *
   * @func service
   * @param {GenericFilterInput} filter - filter options
   * @param {int} numRowsToFetch - num rows to fetch (limit)
   * @param {int} offset - offset to start from
   * @param {string} sortBy - backend column name to sort on
   * @param {string} sortOrder - "asc" or "desc"
   * @returns {ServiceResponse}
   */
  service: PropTypes.func,

  /**
   * @typedef {Object} SortInput
   * @prop {string} sortField - column identifier to sort on
   * @prop {string} sortDirection - "asc" || "desc"
   */

  /**
   * @typedef {Object} PaginationInput
   * @prop {int} limit - limit how many records to return
   * @prop {int} offset - used for pagination - last index of query results
   */

  /**
   * Only to be used instead of props.service if python lambda is built out -
   *   purpose is to store meta and transformation logic in the BE, simplifying
   *   the work that this table has to do, and DRY methodology for export
   *   function
   * @typedef {Object} ListDataServiceResponse
   * @prop {Object} meta - expected to be a sorted array of object describing
   *   each column, where meta<N>.id is the column identifier
   * @prop {Array.<Object>} items - expected to be items returned
   * @prop {int} totalRecordCount - total record count from database matching
   *   filters, not items.length, to account for pagination.limit
   */

  /**
   * @func listDataService
   * @param {string} columns - list of column identifiers requested
   * @param {GenericFilterInput} filter - filter options
   * @param {Array.<SortInput>} sorting - sort options
   * @param {PaginationInput} pagination - pagination options
   * @returns {ListDataServiceResponse}
   */
  listDataService: PropTypes.func,

  // provide this if backend is built out to export this list for this entity
  // type. e.g. "CustomerProperty"
  handleExport: PropTypes.func,
  exportButtonTitle: PropTypes.string,
  /**
   * @any resets the pagination to 0 when changed.
   */
  resetPagination: PropTypes.bool,

  /**
   * @prop {Array} rowMetadata -
   *    NOTE this is actually an array of column metadata
   *    Each column metadata object has a meta.id, where id is the key of the
   *    Object property on each record.
   *    The metadata object describes how each column should be rendered, e.g.
   *    label: will be its label on the table.
   *    Not used if props.listDataService, since listDataService returns the
   *    meta from the backend.
   */
  rowMetadata: PropTypes.array,

  /**
   * @prop {boolean} allowDyanmicRowMetadata -
   *    Allow changes to rowMetadata to take effect. Disabled by default.
   *    Only enable if necessary. Built for the case where some portion of row
   *    metadata is dependent on the result of an API call.
   */
  allowDynamicRowMetadata: PropTypes.bool,

  /**
   * @prop {Object} rowActionButtons -
   * will take each key and render it as a button on the right hand side
   * e.g. {
   *  view: {
   *    label: 'View',
   *    caslAction: 'read'
   *    caslKey: 'Object_CUSTOMER'
   *    icon: 'Launch',
   *  }
   * }
   * a "select" key is reserved and will add a multi-select checkbox on the
   * left side
   */
  rowActionButtons: PropTypes.objectOf(
    PropTypes.arrayOf({
      // label displayed to the right of the icon
      label: PropTypes.string,
      caslAction: PropTypes.string,
      caslKey: PropTypes.string,
      // key for MUI icon.
      // e.g. if you want to use @material-ui/icons/AccessAlarm
      //  then icon: 'AccessAlarm'
      icon: PropTypes.string
    })
  ),

  /**
   * @async
   * @func rowActions
   * @param {string} mode - key of button pressed as specified in
   * rowActionButtons
   * @param {Object | Array.<Object>} item - item record pressed. if pressing the
   * multi-select checkbox, it will be a list of items.
   */
  rowActions: PropTypes.func,

  /**
   * there are other props and values needing documentation
   * @typedef Filter - filters as accepted by Filter modal before being
   *    converted into the shape accepted in the API call
   * @prop {string} condition - e.g. "eq" | "ne"
   * @prop {string} type - e.g. stringFilters
   * @prop {string | maybe others} value - e.g. "3"
   */

  /**
   * @func topPanel - react component put above the table and the
   *  table header containing the filters/columns/views/refresh. Has access to
   *  the internal table's filter and setFilter.
   * @param {Object} - see below for properties
   * @prop {Object<string>, Filter} filters - currently set filters.
   *    key is the column identifier.
   * @prop {func} setFilters - set filters
   * can be extended to pass in more table vars and functions
   * @returns {React.Component}
   */
  topPanel: PropTypes.func,
  /**
   * @bool showFilters - set to true to show table filters in non-fullscreen tables.
   */
  showFilters: PropTypes.bool,

  /**
   * @func actionPanel - react component in-line with views/filters/columns but
   *  aligned to the right. Has access to the records selected for multi-select.
   * @param {Array.<Object>} items - selected items from multi-select
   * @returns {React.Component}
   */
  actionPanel: PropTypes.func,

  /**
   * @prop {string} dragItemName - the name of the row item for react-dnd drag
   * and drop tables. Providing this prop enabled a dnd table that passes the
   * rowData back as the specified item.
   */
  // eslint-disable-next-line react/require-default-props
  dragItemName: PropTypes.string,

  /**
   * @prop {func} dragEligible - an optional function for drag and drop tables
   * that receives the rowData and returns either true or false to determine
   * row drag eligibility.
   */
  // eslint-disable-next-line react/require-default-props
  dragEligible: PropTypes.func,

  /**
   * @prop {string} dragPreviewImg - an optional impage to show instead of the
   * row when dragging. For custom component previews use a custom drag layer
   * and pass in getEmptyImage here.
   */
  // eslint-disable-next-line react/require-default-props
  dragPreviewImg: PropTypes.object,

  /**
   * @prop {Object} dragOptions - options to be provided to the table row's
   * useDrag hook.
   */
  // eslint-disable-next-line react/require-default-props
  dragOptions: PropTypes.object,

  /**
   * @prop {func} dragTransform - an optional function to transform rowData before
   * being passed to useDrag.
   */
  // eslint-disable-next-line react/require-default-props
  dragTransform: PropTypes.func,

  /**
   * @func {func} onMouseLeaveRow - callback fired onMouseLeave of the table row.
   * @returns {Event, rowData, isDnd}
   */
  // eslint-disable-next-line react/require-default-props
  onMouseLeaveRow: PropTypes.func,

  /**
   * @func {func} onMouseEnterRow - callback fired onMouseEnter of the table row.
   * @returns {Event, rowData, isDnd}
   */
  // eslint-disable-next-line react/require-default-props
  onMouseEnterRow: PropTypes.func,

  /**
   * @func {func} onRowClick - callback fired onClick of the table row.
   * @returns {Event, rowData, isDnd}
   */
  // eslint-disable-next-line react/require-default-props
  onRowClick: PropTypes.func,

  /**
   * @func {func} onRefreshCompleted - callback fired when a table refresh completes.
   * @returns {void}
   */
  // eslint-disable-next-line react/require-default-props
  onRefreshCompleted: PropTypes.func
};

ResponsiveTable.defaultProps = {
  defaults: {},
  disableFilter: false,
  filter: undefined,
  isLoading: false,
  noDataMsg: undefined,
  enableRefreshBtn: false,
  service: undefined,
  listDataService: undefined,
  refreshData: undefined,
  refreshQuietly: false,
  tableName: undefined,
  handleExport: undefined,
  exportButtonTitle: 'Export',
  rowMetadata: undefined,
  allowDynamicRowMetadata: false,
  rowActionButtons: undefined,
  rowActions: undefined,
  topPanel: () => null,
  actionPanel: undefined
};

const mapStateToProps = state => ({
  user: state.user
});

const ResponsiveTableWrapper = connect(mapStateToProps)(
  withBackgroundColor(withStyles(styles)(ResponsiveTable))
);

ResponsiveTableWrapper.propTypes = ResponsiveTable.propTypes;

ResponsiveTableWrapper.defaultProps = ResponsiveTable.defaultProps;

export default ResponsiveTableWrapper;
