import gql from 'graphql-tag';

const saveVisitDetails = gql`
  mutation saveVisitDetails($data: String!, $instructionSet: String!) {
    saveVisitDetails(data: $data, instructionSet: $instructionSet) {
      visitNumber
      departmentName
      description
      prerequisites
      prerequisitesAcknowledged
      version
      status
      startTime
      endTime
      scheduledFor
      hierarchy
      partitionKey
      sortKey
      onHold
      onHoldReason
      onRoute
      delayed
      delayedReason
      detailsSent
      tentativeDate
      extraTechsRequired
      extraTechsNumber
      minimumDuration
      actualDuration
      tentativePeriod
      tentativeDate
      tentativeTime
      id
      entityType
      parentId
      parentEntity {
        ... on Job {
          id
          tenantId
          tenantCompanyId
          partitionKey
          entityType
          sortKey
          hierarchy
          entityType
          jobNumber
          customIdentifier
          customerSortKey
          customerName
          priority
          customerPropertyName
          jobTypeName
          customerRepName
          amountNotToExceed
          customerProvidedPONumber
          departments(entityConnection: "JobDepartment") {
            items {
              id
              mappedEntity {
                ... on Department {
                  id
                  tagName
                }
              }
            }
          }
          parentEntity {
            ... on CustomerProperty {
              id
              companyName
              companyAddresses(entityConnection: "CompanyAddress") {
                items {
                  id
                  addressType
                  addressLine1
                  addressLine2
                  city
                  state
                  zipcode
                }
              }
            }
          }
        }
      }
      schedules(entityConnection: "Schedule") {
        items {
          id
          employeeSortKey
          parentId
          hierarchy
          entityType
          version
          tenantId
          tenantCompanyId
          partitionKey
          parentSortKey
          sortKey
          entityType
          scheduledFor
          startTime
          endTime
          onRoute
          delayed
          delayedReason
          onHold
          onHoldReason
          timeSheets(entityConnection: "TimeSheet") {
            items {
              visitDate
              clockInTime
              clockOutTime
              parentId
              hierarchy
              id
              entityType
              version
              tenantId
              tenantCompanyId
              partitionKey
              parentSortKey
              sortKey
            }
          }
        }
      }
      primaryTechs(entityConnection: "VisitPrimaryTech") {
        items {
          id
          sortKey
          invertedSortKey
          mappedEntity {
            ... on Employee {
              id
              hierarchy
              tenantId
              tenantCompanyId
              sortKey
              partitionKey
              entityType
              firstName
              lastName
              email
            }
          }
        }
      }
      extraTechs(entityConnection: "VisitExtraTech") {
        items {
          id
          sortKey
          invertedSortKey
          mappedEntity {
            ... on Employee {
              id
              hierarchy
              tenantId
              tenantCompanyId
              sortKey
              partitionKey
              entityType
              firstName
              lastName
              email
            }
          }
        }
      }
      visitAssets(entityConnection: "VisitAsset", filter: { propertyAssetId: { ne: "null" } }) {
        items {
          id
          propertyAsset(entityConnection: "PropertyAsset") {
            id
            assetName
            make
            modelNumber
            serialNo
            installDate
            location
            propertyZone
            imageUrl
          }
        }
      }
    }
  }
`;
const saveVisitMutation = gql`
  mutation moveVisit($data: String!) {
    moveVisit(data: $data) {
      visitNumber
      departmentName
      departmentId
      description
      prerequisites
      prerequisitesAcknowledged
      version
      status
      startTime
      endTime
      scheduledFor
      hierarchy
      partitionKey
      sortKey
      onHold
      onHoldReason
      onRoute
      delayed
      delayedReason
      detailsSent
      tentativeDate
      extraTechsRequired
      extraTechsNumber
      minimumDuration
      actualDuration
      tentativePeriod
      tentativeDate
      tentativeTime
      id
      entityType
      parentId
      parentEntity {
        ... on Job {
          id
          tenantId
          tenantCompanyId
          partitionKey
          entityType
          sortKey
          hierarchy
          entityType
          jobNumber
          customIdentifier
          customerSortKey
          customerName
          priority
          customerPropertyName
          jobTypeName
          customerRepName
          amountNotToExceed
          customerProvidedJobNumber
          departments(entityConnection: "JobDepartment") {
            items {
              id
              mappedEntity {
                ... on Department {
                  id
                  tagName
                }
              }
            }
          }
          parentEntity {
            ... on CustomerProperty {
              id
              companyName
              companyAddresses(entityConnection: "CompanyAddress") {
                items {
                  id
                  addressType
                  addressLine1
                  addressLine2
                  city
                  state
                  zipcode
                }
              }
            }
          }
        }
      }
      schedules(entityConnection: "Schedule") {
        items {
          id
          employeeSortKey
          parentId
          hierarchy
          entityType
          version
          tenantId
          tenantCompanyId
          partitionKey
          parentSortKey
          sortKey
          entityType
          scheduledFor
          startTime
          endTime
          onRoute
          delayed
          delayedReason
          onHold
          onHoldReason
          timeSheets(entityConnection: "TimeSheet") {
            items {
              visitDate
              clockInTime
              clockOutTime
              parentId
              hierarchy
              id
              entityType
              version
              tenantId
              tenantCompanyId
              partitionKey
              parentSortKey
              sortKey
            }
          }
        }
      }
      primaryTechs(entityConnection: "VisitPrimaryTech") {
        items {
          id
          sortKey
          invertedSortKey
          mappedEntity {
            ... on Employee {
              id
              hierarchy
              tenantId
              tenantCompanyId
              sortKey
              partitionKey
              entityType
              firstName
              lastName
              email
            }
          }
        }
      }
      extraTechs(entityConnection: "VisitExtraTech") {
        items {
          id
          sortKey
          invertedSortKey
          mappedEntity {
            ... on Employee {
              id
              hierarchy
              tenantId
              tenantCompanyId
              sortKey
              partitionKey
              entityType
              firstName
              lastName
              email
            }
          }
        }
      }
    }
  }
`;
export default saveVisitDetails;
export { saveVisitMutation };
