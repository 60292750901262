import gql from 'graphql-tag';

const techHeartbeatNotification = gql`
  subscription HBNotify($partitionKey: String!) {
    heartbeatNotification: childMutationNotification(
      partitionKey: $partitionKey
      parentEntityType: "Employee"
    ) {
      id
      entityType
      version
    }
  }
`;

export default techHeartbeatNotification;
