/* eslint-disable react/prop-types */
import React, { useState, useRef, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { filter, pickBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { MUIForm } from '@buildhero/sergeant';
import Labels from 'meta/labels';
import { addItemsFields, addItemsLayout } from 'meta/Procurement/PurchaseOrders/addItemsForm';
import { editItemLayout } from 'meta/Procurement/PurchaseOrders/editItemForm';
import FullScreenModal from 'components/FullScreenModal';
import DefaultButton from 'components/Buttons/DefaultButton';
import { productSearch, getProducts } from 'services/API/product';
import { roundCurrency, roundFloat, parseFloatAndRound } from 'utils';
import { generateDefaultValidationSchema } from 'scenes/ProjectManagement/components/formattingUtils';
import buildHeroMuiFormOverridesWithCheckbox from 'scenes/ProjectManagement/components/buildHeroMuiFormOverridesWithCheckbox';
import CustomFieldWithLabel from 'scenes/ProjectManagement/components/CustomFieldWithLabel';
import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';
import MoreAction from 'scenes/Procurement/component/MoreAction';
import EditItemModal from 'scenes/Procurement/PurchaseOrders/CreatePurchaseOrder/components/EditItemModal';
import ProcurementUtils from 'scenes/Procurement/Procurement.utils';
import { checkRequiredFieldsFilled } from 'scenes/ProjectManagement/components/utils';
import { unitCostValidate } from 'utils/unitCostValidation.js';
import { roundFive } from '@buildhero/math';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    left: '0%',
    right: '0px',
    top: '60px',
    padding: '80px 24px'
  },
  itemsContainer: {
    minWidth: 600,
    marginRight: 8,
    [theme.breakpoints.up('lg')]: {
      minWidth: 1140
    }
  },
  pocContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 7,
    maxWidth: 220,
    alignItems: 'flex-end'
  },
  searchBox: {
    marginRight: 16
  },
  formContainer: buildHeroMuiFormOverridesWithCheckbox(theme)
}));

const purchaseOrderItemReducer = (state, action) => {
  switch (action.type) {
    case 'addItem':
      return [...state, action.payload];
    case 'editItems':
      return [
        ...state.map(item =>
          action.payload.lineNumber === item.lineNumber ? action.payload : item
        )
      ];
    case 'deleteItem':
      return [...filter(state, item => item.lineNumber !== action.payload.lineNumber)];
    case 'initialize':
      return [];
    default:
      return state;
  }
};

const AddPurchaseOrderItem = props => {
  const classes = useStyles();
  const {
    open,
    Department,
    jobAndProject,
    associatedProject,
    handleSave,
    handleClose,
    user
  } = props;
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedEditItem, setSelectedEditItem] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const muiFormRef = useRef();
  const [purchaseOrderItemState, dispatchPurchaseOrderItem] = useReducer(
    purchaseOrderItemReducer,
    []
  );
  const [selectedPhaseIds, setSelectedPhaseIds] = useState([]);
  const isVistaEnabled = useSelector(state => state.settings.isVistaEnabled);
  const requiredFields = pickBy(
    addItemsFields(associatedProject, isVistaEnabled),
    value => value?.required === true
  );

  useEffect(() => {
    const validated = purchaseOrderItemState.every(item =>
      checkRequiredFieldsFilled(item, requiredFields)
    );
    if (validated) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [purchaseOrderItemState, requiredFields]);

  const CustomMoreAction = ({ options, field }) => {
    const handleEdit = async () => {
      setSelectedEditItem(field.value);
      setEditModalOpen(true);
    };
    const handleDelete = () => {
      setSelectedPhaseIds(selectedPhaseIds.splice(field.value.itemNumber - 1, 1));
      dispatchPurchaseOrderItem({ type: 'deleteItem', payload: field.value });
    };

    const actions = [{ label: 'Delete', icon: 'Delete', onClick: handleDelete }];
    if (!associatedProject?.id) {
      actions.push({ label: 'Edit', icon: 'Edit', onClick: handleEdit });
    }

    return <MoreAction actionsList={actions} options={options} style={{ height: 41, width: 41 }} />;
  };

  const getFormattedData = (data, index = null) => {
    return {
      id: data?.id || '',
      lineNumber: data?.lineNumber || index + 1,
      itemName: data?.itemName || '',
      description: data?.description || '',
      unitOfMeasure: data?.unitOfMeasure?.name || data?.unitOfMeasure || '',
      unitCost: roundFive(String(data?.unitCost)) || '',
      quantity: parseFloatAndRound(data?.quantity, 2) || '0.00',
      Product: data?.Product || null,
      Department: data?.Department || null,
      costCodeObj: data?.costCodeObj || null,
      revenueTypeObj: data?.revenueTypeObj || null,
      costCode: data?.costCodeObj || null, // Additional information for convenience when storing data
      revenueType: data?.revenueTypeObj || null, // Additional information for convenience when storing data
      jobCostType: data?.jobCostType || null,
      totalCost: roundCurrency(data?.unitCost * data?.quantity) || '0.00',
      costCodeId: data?.costCodeId || data?.costCodeObj?.id || '',
      jobCostTypeId: data?.jobCostType?.id || '',
      jcPhase: data?.jcPhase || null,
      jcCostType: data?.jcCostType || null,
      revenueTypeId: data?.revenueType?.id || '',
      phaseDepartment: data?.phaseDepartment || null,
      projectPhase: data?.projectPhase || null,
      projectCostCode: data?.projectCostCode || null,
      projectCostType: data?.projectCostType || null,
      // for Algolia search, not overwriting jobAndProject as the full data is used else where
      // TODO: When Project is added to Algolia, add attribute
      jobNumber:
        data?.jobAndProject?.customIdentifier ||
        data?.jobAndProject?.jobNumber ||
        jobAndProject?.jobNumber ||
        associatedProject?.number,
      jobId: data?.jobAndProject?.id || data?.jobId || '',
      jobAndProject:
        data?.jobAndProject ||
        jobAndProject ||
        (associatedProject && { ...associatedProject, entityType: 'Project' }) ||
        '',
      taxable: data?.taxable || false
    };
  };

  const handleItemsFormChange = data => {
    dispatchPurchaseOrderItem({ type: 'editItems', payload: data });
  };

  const handleChangeUnitCost = ({ form, currentValue }) => {
    const { quantity } = form.values;
    const [newUnitCost, validationMessage] = unitCostValidate(currentValue);
    setTimeout(() => form.setFieldError('unitCost', validationMessage), 150);
    form.setFieldValue('unitCost', newUnitCost);
    form.setFieldValue('totalCost', roundCurrency(String(currentValue * quantity)));
  };

  const handleChangeQuantity = ({ form, currentValue }) => {
    const { unitCost } = form.values;

    form.setFieldValue('quantity', parseFloatAndRound(currentValue, 2));
    form.setFieldValue('totalCost', roundCurrency(unitCost * currentValue));
  };

  const handleEditItemSave = (completed, stopLoading) => {
    stopLoading();
    setEditModalOpen(false);
    dispatchPurchaseOrderItem({ type: 'editItems', payload: completed });
  };

  const handleAddItem = item => {
    if (!item) return;

    let nextNumber = 1;
    let itemWithNumber = purchaseOrderItemState.filter(i => i.lineNumber === nextNumber);
    while (itemWithNumber.length) {
      // eslint-disable-next-line no-plusplus
      nextNumber++;
      // eslint-disable-next-line no-loop-func
      itemWithNumber = purchaseOrderItemState.filter(i => i.lineNumber === nextNumber);
    }

    const addedItem = {
      lineNumber: nextNumber,
      itemName: item?.name || '',
      description: item?.description || '',
      quantity: item?.quantity || '0.00',
      taxable: item?.taxable || false,
      unitOfMeasure: item?.unitOfMeasure?.name || item?.unitOfMeasure || '',
      unitCost: roundFive(String(item?.unitCost)) || '',
      totalCost: roundCurrency(item?.unitCost * item?.quantity) || '0.00',
      Department: Department || null,
      Product: item || null,
      costCodeObj: item?.costCode || null,
      jobCostType: item?.jobCostType || null,
      revenueTypeObj: item?.revenueType || null,
      costCodeId: item?.costCodeId || '',
      revenueTypeId: item?.revenueTypeId || '',
      productId: item?.id || '',
      jobId: item?.jobAndProject?.id || jobAndProject?.id || '',
      jobNumber:
        item?.jobAndProject?.customIdentifier ||
        item?.jobAndProject?.jobNumber ||
        jobAndProject?.jobNumber ||
        associatedProject?.number
    };

    setSelectedPhaseIds([...selectedPhaseIds, '']);

    dispatchPurchaseOrderItem({ type: 'addItem', payload: addedItem });

    setTimeout(() => {
      if (muiFormRef.current) {
        // to force focus on the Description field
        muiFormRef.current.children[0].children[0].children[0].children[1].children[1].children[0].children[0].focus();
      }
    }, 100);
  };

  const handleSaveItems = async () => {
    setIsSubmitting(true);
    await handleSave(purchaseOrderItemState);
    setIsSubmitting(false);
    setSelectedEditItem({});
    dispatchPurchaseOrderItem({ type: 'initialize', payload: null });
  };

  const handleCloseModal = () => {
    setSelectedEditItem({});
    dispatchPurchaseOrderItem({ type: 'initialize', payload: null });
    handleClose();
  };

  return (
    <>
      <FullScreenModal
        title={Labels.addNewLineItem[user.locale]}
        modalHeaderButtons={[
          <DefaultButton
            label="Save Items"
            variant="containedPrimary"
            key="save"
            disabled={disableSubmit || isSubmitting}
            showSpinner={isSubmitting}
            onClick={handleSaveItems}
          />
        ]}
        open={open}
        handleClose={handleCloseModal}
      >
        <div className={classes.root}>
          <div className={classes.itemsContainer}>
            <div className={classes.formContainer}>
              {purchaseOrderItemState?.map((item, index) => (
                <div ref={muiFormRef} key={`procurement-add-po-items${item.lineNumber}`}>
                  <MUIForm
                    configuration={addItemsLayout({
                      showLabel: index === 0,
                      handleChangeUnitCost,
                      handleChangeQuantity,
                      associatedProject,
                      handleSearchSelect: ProcurementUtils.handleSearchSelect,
                      handleSetDefaultValue: ProcurementUtils.handleSetDefaultValue,
                      isVistaEnabled,
                      item
                    })}
                    data={getFormattedData(item, index)}
                    layout="edit"
                    onCreateService={() => {}}
                    onComplete={() => {}}
                    customComponents={{
                      CustomFieldWithLabel,
                      CustomMoreAction,
                      SearchBar
                    }}
                    validationSchema={generateDefaultValidationSchema(addItemsFields)}
                    onFormChange={data => handleItemsFormChange(data)}
                  />
                </div>
              ))}
              <SearchBar
                key="itemSearch"
                className={classes.searchBox}
                options={{
                  placeholder: 'Search Product',
                  searchFunction: productSearch,
                  emptySearchFunction: getProducts,
                  hideOptions: false,
                  useId: false,
                  resultFormatFunction: product => product.name,
                  searchColumn: ['name'],
                  variant: 'standard',
                  color: 'secondary',
                  clearOnSelect: true
                }}
                onSelectionChange={results => handleAddItem(results)}
              />
            </div>
          </div>
        </div>
      </FullScreenModal>
      <EditItemModal
        open={editModalOpen}
        title="Edit Purchase Order Item"
        btnLabel={Labels.saveChangesButtonText[user.locale]}
        formData={getFormattedData(selectedEditItem)}
        formLayout={editItemLayout()}
        handleSaveChange={handleEditItemSave}
        handleModalClose={() => setEditModalOpen(false)}
      />
    </>
  );
};

AddPurchaseOrderItem.propTypes = {
  open: PropTypes.bool.isRequired,
  Department: PropTypes.instanceOf(Object).isRequired,
  jobAndProject: PropTypes.instanceOf(Object).isRequired,
  associatedProject: PropTypes.instanceOf(Object).isRequired,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired
};

const mapStateToProps = state => ({ user: state.user });
const ReduxConnectedAddReceiptItem = connect(mapStateToProps)(AddPurchaseOrderItem);
export default ReduxConnectedAddReceiptItem;
