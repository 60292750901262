export const PaymentTermColumns = [
  {
    id: 'name',
    maxTextLen: 30,
    label: 'Name',
    filterKey: 'PaymentTerm.name',
    filterType: 'string'
  },
  {
    id: 'type',
    maxTextLen: 30,
    label: 'Payment Term Type',
    filterKey: 'PaymentTerm.type',
    filterType: 'string',
    isCustom: true,
    type: 'paymentTermType'
  },
  {
    id: 'value',
    label: 'Days Until Due',
    filterKey: 'PaymentTerm.value',
    filterType: 'number'
  },
  {
    headerStyle: {
      width: '5%',
      minWidth: 190
    },
    id: 'isDefault',
    disableFilter: true,
    disableSort: true,
    isCustom: true,
    type: 'setDefaultPaymentTermButton',
    label: 'Default Terms'
  }
];

export const PaymentTermLayout = {
  fields: {},
  layouts: {
    edit: {
      options: {
        pageSize: 'LETTER',
        direction: 'column',
        grow: 1,
        alignItems: 'center'
      },
      contents: [
        {
          options: { width: '100%', marginBottom: 16 },
          contents: [
            {
              options: {
                label: 'Name',
                isRequired: true
              },
              component: 'TextInput',
              source: 'name'
            }
          ]
        },
        {
          options: { direction: 'row', width: '100%' },
          contents: [
            {
              options: {
                direction: 'column',
                marginRight: 16
              },
              contents: [
                {
                  options: {
                    label: 'Payment Term Type',
                    isRequired: true,
                    placeholder: 'Select...',
                    inputOptions: [
                      { label: 'Invoicing', value: 'Invoicing' },
                      { label: 'Purchasing', value: 'Purchasing' },
                      { label: 'Both', value: 'Both' }
                    ]
                  },
                  component: 'SelectInput',
                  source: 'type'
                }
              ]
            },
            {
              options: {
                direction: 'column'
              },
              contents: [
                {
                  options: {
                    label: 'Day(s) Until Due',
                    type: 'number',
                    endAdornmentText: 'day(s)',
                    isRequired: true
                  },
                  component: 'TextInput',
                  source: 'value'
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        pattern: /\S/, // needs at least one non-whitespace char
        required: true
      },
      value: {
        type: 'number',
        minimum: 0,
        required: true
      },
      type: {
        type: 'string',
        required: true
      }
    }
  },
  validationErrors: {
    name: {
      required: 'Name is required.',
      pattern: 'Name is required.'
    },
    value: {
      required: 'Day(s) until due is required.',
      minimum: '0'
    },
    type: {
      required: 'Payment term type is required.'
    }
  }
};

export default PaymentTermLayout;
