import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { sortBy, orderBy } from 'lodash';
import { useTheme } from '@material-ui/core';

import WrapTable, { BoldCell } from 'components/WrapTable';
import { Typography, TV, TW, ThemeProvider } from '@buildhero/sergeant';
import { convertToCurrencyString } from 'utils';
import { tableCurrencyFormatter } from '../utils';
import { SectionHeader, VisitSubjectField } from '../Components';

const getColumns = isMultiVisits => {
  const columns = [
    {
      field: 'receipt',
      headerName: 'Receipt',
      renderCell: ({ row }) => {
        return (
          <Link to={`/procurement/receipts-bills/receipt/view/${row?.id}`}>{row?.receipt}</Link>
        );
      },
      flex: 1
    },
    {
      field: 'purchaseOrder',
      headerName: 'Purchase Order',
      renderCell: ({ row }) => {
        return (
          <Link
            to={`/procurement/purchaseorders/view/${row?.poId}`}
          >{`PO${row?.purchaseOrder}`}</Link>
        );
      },
      flex: 1
    },
    { field: 'vendor', headerName: 'Vendor', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 11 },
    { field: 'department', headerName: 'Department', flex: 1 },
    { field: 'createdBy', headerName: 'Created By', flex: 1 },
    {
      field: 'subtotal',
      headerName: 'Subtotal',
      width: 100,
      valueFormatter: tableCurrencyFormatter,
      renderCell: BoldCell,
      totalGetter: ({ rows }) => rows.reduce((acc, r) => acc + r.subtotal, 0),
      renderTotal: props => (
        <Typography
          css={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}
          variant={TV.BASE}
          weight={TW.BOLD}
          numeric
        >
          {convertToCurrencyString(props.formattedValue ?? 0)}
        </Typography>
      )
    }
  ];

  if (isMultiVisits) {
    return [
      {
        field: 'subject',
        headerName: 'Visit',
        renderCell: ({ row }) => VisitSubjectField({ ...row.subject }),
        width: 200
      },
      ...columns
    ];
  }

  return columns;
};

const mapVisitsToRows = (visits, isMultiVisits) => {
  const mappedRows = [];
  visits.forEach(({ visitNumber, scheduledFor, reviewStatus, visitPurchaseOrders: { items } }) => {
    items.forEach(({ id: poId, poNumber, purchaseOrderReceipts: { items: receiptItems } }) => {
      if (receiptItems.length > 0) {
        receiptItems.forEach(
          ({
            id,
            description,
            purchaseOrderReceiptLines,
            createdBy,
            vendor,
            department,
            receiptNumber
          }) => {
            const { items: receiptCosts } = purchaseOrderReceiptLines;
            let totalCost = 0;
            receiptCosts.forEach(({ quantity, unitCost }) => {
              totalCost += quantity * unitCost;
            });
            mappedRows.push({
              receipt: receiptNumber,
              id,
              poId,
              vendor: vendor.name,
              description,
              purchaseOrder: poNumber,
              department: department?.tagName,
              subtotal: totalCost,
              createdBy,
              subject: {
                reviewStatus,
                visitNumber,
                scheduledFor
              },
              visitNumber
            });
          }
        );
      }
    });
  });
  if (isMultiVisits) {
    return orderBy(mappedRows, ['visitNumber', 'receipt'], ['asc', 'asc']);
  }
  return sortBy(mappedRows, 'receipt');
};

const VisitReceiptsTable = ({ visits, isMultiVisits, isLoading }) => {
  const theme = useTheme();
  const columns = useMemo(() => getColumns(isMultiVisits), [isMultiVisits]);
  const rows = useMemo(() => mapVisitsToRows(visits, isMultiVisits), [visits, isMultiVisits]);

  return (
    <ThemeProvider>
      <SectionHeader title="Receipts Created" />
      <WrapTable
        columns={columns}
        rows={rows}
        noDataMessage="No receipts data"
        hideFooter={rows?.length <= 10}
        enableTotalsRow
        loading={isLoading}
        loadingRows={3}
      />
    </ThemeProvider>
  );
};

VisitReceiptsTable.defaultProps = {
  visits: [],
  isLoading: false
};

export default VisitReceiptsTable;
