import useExtendedMutation from 'customHooks/useExtendedMutation';
import useExtendedQuery from 'customHooks/useExtendedQuery';

import {
  AddCompanyDispatchSettings,
  GetCompanyDispatchSettings,
  UpdateCompanyDispatchSettings
} from './DispatchSettings.queries';

export const useGetCompanyDispatchSettings = ({ tenantId, tenantCompanyId, snackbarOn }) => {
  return useExtendedQuery(GetCompanyDispatchSettings, {
    variables: { partitionKey: tenantId, sortKey: `${tenantId}_Company_${tenantCompanyId}` },
    transform: response => {
      const { items } = response.getCompany.companySettings;
      if (items.length > 1) {
        snackbarOn(
          'error',
          `Corrupted Dispatch Settings Data Detected. The Company Setting can only contain one object.`
        );
      }
      return items?.[0] ?? undefined;
    },
    fetchPolicy: 'network-only',
    skip: !tenantId || !tenantCompanyId
  });
};

export const useSaveCompanyDispatchSettings = ({
  tenantId,
  tenantCompanyId,
  isUpdate,
  setIsDirty
}) =>
  useExtendedMutation(isUpdate ? UpdateCompanyDispatchSettings : AddCompanyDispatchSettings, {
    serializer: ({ formData, prevSettingData }) => {
      const payload = {
        variables: prevSettingData
          ? {
              id: prevSettingData.id,
              version: prevSettingData.version,
              partitionKey: tenantId,
              settings: JSON.stringify(formData)
            }
          : {
              partitionKey: tenantId,
              companyId: tenantCompanyId,
              settings: JSON.stringify(formData)
            }
      };
      return payload;
    },
    onSuccess: (data, snackbarOn) => {
      setIsDirty(false);
      snackbarOn('success', 'Successfully saved company dispatch settings');
    }
  });
