import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid';
import { Typography, TV, TW } from '@buildhero/sergeant';
import { makeStyles } from '@material-ui/core';

export const CustomRadioButton = props => {
  const { disabled, onChange, value, label, description } = props;
  const useStyles = makeStyles(theme => ({
    colorSecondary: {
      color: theme.palette.grayscale(20),
      '&$checked': {
        color: '#00854B'
      }
    },
    checked: {}
  }));
  const radioButtonClasses = useStyles();
  return (
    <div css={{ marginTop: 4, marginBottom: 4 }}>
      <Grid container spacing={0} alignItems="center">
        <Grid item>
          <Radio
            classes={radioButtonClasses}
            disabled={disabled}
            onChange={onChange}
            value={value}
            size="small"
          />
        </Grid>
        <Grid item style={{ maxWidth: 250 }}>
          <Typography variant={TV.BASE} weight={TW.BOLD}>
            {label}
          </Typography>
          <Typography variant={TV.S1}>{description}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export const CustomRadioGroup = ({ handleChange, value, options = [] }) => {
  return (
    <RadioGroup row value={value} onChange={handleChange}>
      {options.map(option => {
        const { label, description } = option;
        return (
          <CustomRadioButton
            key={option.value}
            label={label}
            value={option.value}
            description={description}
          />
        );
      })}
    </RadioGroup>
  );
};
