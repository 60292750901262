// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag';

const moveVisit = gql`
  mutation visitTransition($visitAction: String!, $data: visitTransitionInput!) {
    visitTransition(visitAction: $visitAction, data: $data) {
      visitNumber
      departmentName
      version
      status
      startTime
      endTime
      scheduledFor
      hierarchy
      partitionKey
      sortKey
      onHold
      onHoldReason
      onRoute
      delayed
      delayedReason
      detailsSent
      tentativeDate
      extraTechsRequired
      extraTechsNumber
      minimumDuration
      actualDuration
      tentativePeriod
      tentativeDate
      tentativeTime
      id
      entityType
      parentId
    }
  }
`;

export default moveVisit;
