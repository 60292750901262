const taskAssetHeader = (settingsJSON, SettingConstants, task, styles, fontFamilyStyle) =>
  settingsJSON.Tasks[SettingConstants.SHOW_ASSET] && task?.propertyAsset?.id
    ? `<tr>
<td style="${styles}width:65%;border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;" colspan="3" class="restricted">
  <span fontfamily=${fontFamilyStyle} class="text-small" contenteditable="false"><strong>Asset</strong></span>
</td>
<td style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;" colspan="2" class="restricted">
  <span fontfamily=${fontFamilyStyle} class="text-small" contenteditable="false" style="text-align:right;"><strong>Type</strong></span>
</td>
</tr>
<tr>
<td style="${styles}width:65%;border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;" colspan="3" class="restricted">
<span fontfamily=${fontFamilyStyle} class="text-small" contenteditable="false">${task.propertyAsset
        .assetName || ''}</span>
</td>
<td style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;" colspan="2" class="restricted">
<span fontfamily=${fontFamilyStyle} class="text-small" contenteditable="false" style="text-align:right;">${task
        .propertyAsset.assetType?.tagName || ''}</span>
</td>
</tr>`
    : '';

export default taskAssetHeader;
