const table = [
  {
    id: 'employeeId',
    dataKey: 'employeeId',
    isCustom: true,
    disableSort: true,
    label: 'Technician',
    type: 'Technician',
    cellStyle: {
      minWidth: 180,
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  {
    id: 'department',
    isCustom: true,
    disableSort: true,
    label: 'Department',
    type: 'Department'
  },
  {
    id: 'labourTypeId',
    dataKey: 'labourTypeId',
    isCustom: true,
    disableSort: true,
    label: 'Labor Type',
    type: 'LabourType',
    cellStyle: {
      minWidth: 180,
      paddingTop: 0,
      paddingBottom: 0
    }
  }
];

export const deleteMeta = {
  id: 'delete',
  numeric: false,
  disableSort: true,
  isCustom: true,
  type: 'Delete',
  label: '',
  cellStyle: {
    paddingLeft: 0
  }
};

export const subtotalMeta = {
  id: 'subTotal',
  type: 'currency',
  disableSort: true,
  label: 'Subtotal',
  cellStyle: {
    fontSize: 16
  }
};

export const costMeta = [
  {
    id: 'jobCostTypeId',
    dataKey: 'jobCostTypeId',
    isCustom: true,
    disableSort: true,
    label: 'Cost Type',
    type: 'CostType',
    cellStyle: {
      minWidth: 180,
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  {
    id: 'costCodeId',
    dataKey: 'costCodeId',
    isCustom: true,
    disableSort: true,
    label: 'Cost Code',
    type: 'CostCode',
    cellStyle: {
      minWidth: 180,
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  {
    id: 'cost',
    isCustom: true,
    numeric: true,
    dataKey: 'cost',
    type: 'CostDetailInput',
    disableSort: true,
    label: 'Cost'
  },
  {
    id: 'revenueTypeId',
    dataKey: 'revenueTypeId',
    isCustom: true,
    disableSort: true,
    label: 'Revenue Type',
    type: 'RevenueType',
    cellStyle: {
      minWidth: 180,
      paddingTop: 0,
      paddingBottom: 0
    }
  }
];

export default table;
