import InstructionHelper from '../InstructionHelper';

// with multiple notes in the same object, this schema is used for e.g.: Task has notes and partNotes
const noteWithRelationNameMutationSchema = (record, parent, relationName) => {
  const localNote = record;

  const parentObj = parent;
  if (parentObj.__typename) {
    delete parentObj.__typename;
  }
  if (localNote.__typename) {
    delete localNote.__typename;
  }
  const data = [{ ...parentObj }, { ...localNote }];
  const ins = new InstructionHelper();
  let temp;
  if (relationName) {
    temp = ins.oneToMany(0, 1, relationName);
  } else {
    temp = ins.oneToMany(0, 1);
  }
  const { instructionSet } = temp;

  return { data: data, instructionSet: instructionSet };
};

export default noteWithRelationNameMutationSchema;
