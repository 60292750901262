import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { closedPeriodForm } from 'meta/Settings/Accounting/forms';
import { Typography } from '@material-ui/core/';
import { AccountingServices } from 'services/core';
import { logErrorWithCallback } from 'utils';
import { SergeantModal } from 'components';
import { snackbarOn } from 'redux/actions/globalActions';

class ClosedPeriodModal extends Component {
  constructor(props) {
    super(props);
    this.AccountingServices = new AccountingServices();
    const today = moment().unix();
    this.state = {
      isUpToDate: false,
      data: {
        closedPeriodCopy: 'Set a new Closed Period for BuildOps',
        closedPeriodDate: today,
        numberOfClosingRecords: null
      },
      primaryAction: this.addClosedPeriodToCompany,
      buttonLabel: 'Update'
    };
  }

  addClosedPeriodToCompany = async (formData, loaded) => {
    const { tenantId, tenantCompanyId } = this.props.user;
    if (formData) {
      const updatedData = { ...formData };
      const payload = {
        companyId: tenantCompanyId,
        closedPeriods: [
          {
            closeDate: updatedData.closedPeriodDate
          }
        ]
      };
      try {
        const resp = await this.AccountingServices.addClosedPeriodToCompany(tenantId, payload);
        if (resp) {
          const { addClosedPeriodsToCompany } = resp.data;
          const {
            affectedAdjustmentCount,
            affectedInvoiceCount,
            affectedPaymentCount
          } = addClosedPeriodsToCompany;
          const numberOfClosingRecords =
            affectedAdjustmentCount + affectedInvoiceCount + affectedPaymentCount;
          updatedData.numberOfClosingRecords = numberOfClosingRecords;
          this.setState({
            buttonLabel: 'OK',
            isUpToDate: true,
            primaryAction:
              numberOfClosingRecords > 0 ? this.applyClosedPeriod : this.props.handleClose,
            data: updatedData
          });
        }
      } catch (error) {
        logErrorWithCallback(
          error,
          this.props.snackbarOn,
          'Unable to set Closed Period, please try again later'
        );
      }
    }
    loaded();
  };

  applyClosedPeriod = async (formData, loaded) => {
    const { handleClose, user } = this.props;
    const { tenantCompanyId } = user;
    try {
      await this.AccountingServices.applyClosedPeriod(tenantCompanyId);
      this.props.snackbarOn('success', `Successfully updated Closed Period`);
    } catch (error) {
      logErrorWithCallback(
        error,
        this.props.snackbarOn,
        'Unable to set Closed Period, please try again later'
      );
    }
    loaded();
    handleClose();
  };

  renderAffectedRecords = props => {
    const { value } = props.field;
    if (!value && value !== 0) return null;
    const warning = `This operation will update ${value} entries.`;
    return (
      <Typography variant="body1" color="error">
        <b>Warning! </b>
        {warning}
      </Typography>
    );
  };

  render() {
    const { open, handleClose } = this.props;
    const { data, primaryAction, buttonLabel, isUpToDate } = this.state;

    return (
      <SergeantModal
        open={open}
        data={data}
        formVersion={isUpToDate ? 'default' : 'edit'}
        layout={closedPeriodForm}
        handlePrimaryAction={primaryAction}
        handleClose={handleClose}
        customComponents={{ AffectedRecords: this.renderAffectedRecords }}
        primaryButtonLabelOverride={buttonLabel}
        hideTitle
      />
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapSnackBarToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const reduxConnectedClosedPeriodModal = connect(
  mapStateToProps,
  mapSnackBarToProps
)(ClosedPeriodModal);

export default reduxConnectedClosedPeriodModal;
