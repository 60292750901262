import React, { useRef } from 'react';

import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { func, shape } from 'prop-types';
import { useSelector } from 'react-redux';
import uuid from 'uuid';

import Labels from 'meta/labels';

import { useStyles } from './AddAttachmentsButton.styles';

const AddAttachmentsButton = ({ options: { onChange } }) => {
  const styles = useStyles();
  const refId = useRef(uuid.v4());
  const user = useSelector(store => store.user);
  return (
    <ButtonBase focusRipple>
      <label htmlFor={refId.current}>
        <input
          type="file"
          accept=".pdf,.doc,.docx,image/*"
          id={refId.current}
          style={{
            display: 'none'
          }}
          onChange={onChange}
        />
        <div css={styles.container}>
          <AddCircleOutlineIcon css={styles.icon} />
          <Typography css={styles.text}>{Labels.uploadAttachment[user.locale]}</Typography>
        </div>
      </label>
    </ButtonBase>
  );
};

AddAttachmentsButton.propTypes = {
  options: shape({
    onChange: func.isRequired
  }).isRequired
};

export default AddAttachmentsButton;
