const bundleForm = templateTypes => ({
  fields: {
    name: {
      default: 'name'
    },
    description: {
      default: 'description'
    },
    category: {
      default: 'category'
    },
    subCategory: {
      default: 'subCategory'
    },
    type: {
      default: 'type'
    }
  },
  layouts: {
    default: {
      options: {
        direction: 'column',
        grow: 0
      },
      contents: [
        {
          options: {
            direction: 'column',
            shrink: 0,
            padding: [0, 0, 18, 0]
          },
          contents: [
            {
              options: {
                direction: 'row',
                shrink: 0,
                padding: [18, 0, 18, 0]
              },
              contents: [
                {
                  options: {
                    direction: 'column',
                    padding: [2, 10, 0, 0]
                  },
                  contents: [
                    {
                      component: { default: 'TextInput' },
                      source: 'name',
                      options: {
                        label: 'Bundle Name',
                        isRequired: true
                      }
                    }
                  ]
                },
                {
                  options: {
                    direction: 'column',
                    padding: [0, 0, 0, 10]
                  },
                  contents: [
                    {
                      component: { default: 'TextInput' },
                      source: 'description',
                      options: {
                        label: 'DESCRIPTION'
                      }
                    }
                  ]
                }
              ]
            },
            {
              options: {
                direction: 'row',
                shrink: 0,
                padding: [0, 0, 18, 0]
              },
              contents: [
                {
                  options: {
                    direction: 'column',
                    padding: [0, 10, 0, 0]
                  },
                  contents: [
                    {
                      component: { default: 'TextInput' },
                      source: 'category',
                      options: {
                        label: 'CATEGORY'
                      }
                    }
                  ]
                },
                {
                  options: {
                    direction: 'column',
                    padding: [0, 0, 0, 10]
                  },
                  contents: [
                    {
                      component: { default: 'TextInput' },
                      source: 'subCategory',
                      options: {
                        label: 'SUB CATEGORY'
                      }
                    }
                  ]
                }
              ]
            },
            {
              options: {
                direction: 'row',
                shrink: 0,
                width: '49%',
                padding: [0, 0, 18, 0]
              },
              contents: [
                {
                  options: {
                    direction: 'column',
                    padding: [0, 0, 18, 0]
                  },
                  contents: [
                    {
                      component: { default: 'SelectInput' },
                      source: 'type',
                      options: {
                        label: 'TEMPLATE TYPE',
                        valueSet: templateTypes,
                        placeholder: 'Select bundle',
                        isRequired: true
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    $schema: 'http://json-schema.org/draft-07/schema#',
    title: 'Line Item',
    type: 'object',
    properties: {
      name: { type: 'string', required: true },
      type: { type: 'string', required: true }
    }
  },
  validationErrors: {
    name: {
      required: 'Bundle name is required'
    },
    type: {
      required: 'Template Type is required'
    }
  }
});

export default bundleForm;
