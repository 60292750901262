import gql from 'graphql-tag';

const getBillItemsByJobId = gql`
  query getBillItemsByJobId($partitionKey: String!, $id: String!) {
    getJobById(partitionKey: $partitionKey, id: $id) {
      id
      jobNumber
      billLines(limit: 100000) {
        items {
          id
          invoicedStatus
          quantity
          jobId
          description
          productId
          remainingQuantity
          bill {
            billNumber
            id
          }
        }
      }
    }
  }
`;

export default getBillItemsByJobId;
