import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { getContentText } from '../utils';

const useStyles = makeStyles(() => ({
  root: {
    width: 219,
    marginRight: 15
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  textAlignRight: {
    width: 109,
    textAlign: 'end'
  },
  title: {
    fontSize: 10,
    textTransform: 'uppercase',
    color: '#999999',
    letterSpacing: '-0.01em',
    whiteSpace: 'nowrap',
    height: 14,
    marginBottom: 3.5
  },
  content: {
    height: 41,
    padding: '11px 8px 11px 0px',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '14px',
    color: '#333333',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'zero' on, 'salt' on, 'ss01' on"
  }
}));

const SimpleTitleContent = props => {
  const classes = useStyles();
  const { type, content, title } = props.data;

  return (
    <div className={classes.root}>
      <div className={classes.itemContainer}>
        <span
          className={classNames(classes.title, type === 'percentage' && classes.textAlignRight)}
        >
          {title}
        </span>
        <span
          className={classNames(classes.content, type === 'percentage' && classes.textAlignRight)}
        >
          {getContentText(type, content, 2)}
        </span>
      </div>
    </div>
  );
};

export default SimpleTitleContent;
