import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { PageHeader, UserPermission, ResponsiveTable } from 'components';
import { PermissionConstants } from 'utils/AppConstants';
import ErrorBoundaries from 'scenes/Error';
import { Tabs, Tab } from 'components/Tabs';
import CreateEntryButton from 'components/Buttons/CreateEntryButton';
import useAppRoles from './useAppRoles';
import EmployeeSection from './EmployeeSection';
import CreateCrew from './CreateCrew';
import { getAllCrews } from './utils';

const PersonnelTabs = {
  EMPLOYEES: 'EMPLOYEES',
  CREWS: 'CREWS'
};

const tabIndexMap = {
  0: PersonnelTabs.EMPLOYEES,
  1: PersonnelTabs.CREWS
};

const People = props => {
  document.title = 'BuildOps - People';
  const [selectedTab, setSelectedTab] = useState(tabIndexMap[0]);
  const [isCreatingCrew, setIsCreatingCrew] = useState(false);
  const user = useSelector(s => s.user);

  const [appRoles] = useAppRoles();

  const pageHeaderButtons = {
    [PersonnelTabs.CREWS]: [
      <CreateEntryButton
        handleAdd={() => setIsCreatingCrew(true)}
        label="New Crew"
        key="createCrew"
      />
    ]
  };

  const crewListMeta = [
    {
      id: 'name',
      filterKey: 'Crews.name',
      label: 'Name',
      showLink: 'view',
      linkPath: '/settings/crew/view',
      linkReference: 'id'
    },
    {
      id: 'departmentName',
      filterKey: 'Department.tagName',
      label: 'Department'
    },
    {
      id: 'foremanName',
      filterKey: 'foreman.name',
      label: 'Foreman'
    },
    {
      id: 'techNames',
      label: 'Crew Members',
      disableSort: true
    },
    {
      id: 'numTechs',
      label: 'Number of Crew Members',
      disableSort: true
    }
  ];

  return (
    <ErrorBoundaries>
      <PageHeader
        pageMapKey="people"
        userLocale={user.locale}
        overrideHeaderButtons={pageHeaderButtons[selectedTab]}
      />
      <UserPermission I="allow" action={PermissionConstants.MENU_SETTINGS}>
        <Grid container direction="row" spacing={3}>
          <UserPermission I="read" action={PermissionConstants.OBJECT_EMPLOYEE}>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 50 }}>
              <Tabs
                onChange={(event, value) => {
                  setSelectedTab(tabIndexMap[value]);
                }}
              >
                <Tab label="Employees" tabKey="employees">
                  <EmployeeSection
                    mounted={props.mounted}
                    appRoles={appRoles}
                    openCurrentEmployee={props.location.openCurrentEmployee}
                    key={appRoles?.length}
                  />
                </Tab>
                <Tab label="Crews" tabKey="crews">
                  <ResponsiveTable
                    rowMetadata={crewListMeta}
                    disableFilter
                    useServerQueries
                    service={getAllCrews}
                    noDataMsg="No Crews"
                  />
                </Tab>
              </Tabs>
            </Grid>
          </UserPermission>
        </Grid>
      </UserPermission>
      <CreateCrew
        open={isCreatingCrew}
        handleClose={() => setIsCreatingCrew(false)}
        history={props.history}
      />
    </ErrorBoundaries>
  );
};

export default People;
