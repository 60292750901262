import gql from 'graphql-tag';

const getPONumberForJob = gql`
  query getPONumberForJob($partitionKey: String!, $sortKey: String!, $poNumber: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      jobsView(
        entityConnection: "Job"
        filter: {
          stringFilters: {
            fieldName: "Job.customerProvidedPONumber"
            filterInput: { eq: $poNumber }
          }
        }
      ) {
        nextToken
        items {
          id
          jobNumber
        }
      }
    }
  }
`;

export default getPONumberForJob;
