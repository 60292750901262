import React, { memo } from 'react';

import TechCell from '../../../TechCell';

import DayCell from './components/DayCell';
import { useStyles } from './WeekSwimLane.styles';

const WeekSwimLane = ({ data, index, style }) => {
  // TODO: make nice loading visit state
  const { tech = {}, techLoading, techEvents = [] } = data[index] || {};

  const styles = useStyles();

  return (
    <div css={styles.swimLane} style={style}>
      <TechCell tech={tech} techLoading={techLoading} />
      <div css={styles.daysContainer}>
        {techEvents.map((dayEvents, dayIndex) => (
          <DayCell dayEvents={dayEvents} tech={tech} laneIndex={index} dayIndex={dayIndex} />
        ))}
      </div>
    </div>
  );
};

const SwimLaneWrapper = props => {
  // index 0 is virtualized list sticky header offset
  if (props.index === 0) return null;
  return <WeekSwimLane {...props} />;
};

SwimLaneWrapper.propTypes = WeekSwimLane.propTypes;

export default memo(SwimLaneWrapper);
