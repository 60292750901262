import gql from 'graphql-tag';

const cloneQuote = gql`
  mutation cloneQuote($partitionKey: String!, $data: CloneQuoteInput!) {
    cloneQuote(partitionKey: $partitionKey, data: $data) {
      id
      version
      quoteNumber
      customIdentifier
      name
      description
      departmentId
      departmentValue
      serviceAgreementId
      priceBookId
      parentId
      expirationDate
      jobTypeId
      jobTypeValue
      ownerId
      ownerValue
      salesById
      salesByValue
    }
  }
`;

export default cloneQuote;
