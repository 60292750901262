import gql from 'graphql-tag';

const getDispatchedVisitsInRange = gql`
  query getDispatchedVisitsInRange(
    $partitionKey: String!
    $sortKey: String!
    $startTime: Int!
    $endTime: Int!
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      visitsInRange(
        entityConnection: "Visit"
        rangeFilter: { startTime: $startTime, endTime: $endTime }
        filter: {
          status: {
            in: ["Scheduled", "Complete", "Working", "On hold", "Traveling", "Paused", "Converted"]
          }
        }
      ) {
        items {
          id
          sortKey
          version
        }
      }
    }
  }
`;

export default getDispatchedVisitsInRange;
