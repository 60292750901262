import { Auth } from 'aws-amplify';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { split } from '@apollo/client/link/core';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { fromPromise } from '@apollo/client/link/utils';

import { CONFIG } from '@dispatch/Dispatch.constants';
import { handleError } from '@dispatch/Dispatch.utils';

let client;
let wsClient;
let currentAuthTimeout;

export const logoutClient = () => {
  wsClient?.close?.();
  clearTimeout(currentAuthTimeout);
  client = null;
  wsClient = null;
};

const getJwtToken = async () => {
  return (await Auth.currentSession()).getIdToken().getJwtToken();
};

const AuthLink = () => {
  return setContext(async (_, { headers }) => ({
    headers: {
      ...headers,
      authorization: await getJwtToken()
    }
  }));
};

const AuthErrorRetryLink = () => {
  return onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError) {
      if (String(networkError).match(/Received status code 401/i)) {
        return fromPromise(getJwtToken())
          .filter(Boolean)
          .flatMap(token => {
            const oldHeaders = operation.getContext().headers;
            // modify the operation context with a new token
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: token
              }
            });
            return forward(operation);
          });
      }
    }
  });
};

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

let isWsReauthing = false;

const reauthWsSession = async ({ watchWsAccessToken, shouldResetStore = false }) => {
  if (isWsReauthing) return;
  isWsReauthing = true;
  Auth.currentSession()
    .then(session => {
      const newAccessToken = session.getAccessToken();
      // close then reconnect with the same operations
      wsClient.close(false, false);
      watchWsAccessToken(newAccessToken);
    })
    .catch(err => {
      handleError({ err, errContext: 'dispatch subscription error', hideError: true });
    })
    .finally(() => {
      if (shouldResetStore) client?.resetStore?.();
      isWsReauthing = false;
    });
};

export const getHttpClient = () => {
  if (client) return client;

  const httpLink = createHttpLink({
    uri: CONFIG.appsync.aws_appsync_graphqlEndpoint
  });

  const authLink = AuthLink();

  const authErrorRetryLink = AuthErrorRetryLink();

  const retryLink = new RetryLink({
    delay: {
      initial: 500,
      max: Infinity,
      jitter: true
    },
    attempts: {
      max: 5,
      retryIf: (error, operation) => {
        const definition = getMainDefinition(operation.query);
        return Boolean(error && definition.operation === 'query');
      }
    }
  });

  const restLink = new RestLink({ endpoints: { pmapi: CONFIG.pmapi, restapi: CONFIG.restapi } });

  const wsLink = new WebSocketLink({
    uri: CONFIG.subscriptionEndpoint,
    options: {
      reconnect: true,
      minTimeout: 5000,
      connectionParams: async () => {
        return { authorization: await getJwtToken() };
      },
      connectionCallback: err => {
        if (err) {
          handleError({ err, errContext: 'dispatch subscription error', hideError: true });
        }
      }
    }
  });

  const watchWsAccessToken = accessToken => {
    wsClient = wsLink.subscriptionClient;
    const originalOnMessage = wsClient.client.onmessage.bind({});
    wsClient.client.onmessage = message => {
      originalOnMessage(message);
      const data = message.data && JSON.parse(message.data);
      if (data?.type === 'error') {
        if (data.payload?.message === 'TokenExpiredError') {
          console.log('Detected expired ws token. Attempting re-auth.');
          reauthWsSession({ watchWsAccessToken, shouldResetStore: true });
        }
      }
    };

    // Accessing the private subscriptionClient is the cleanest way to handle async
    // re-authentication as of apollo/client 3.3.15
    // Watch the following issues for better options in the future:
    // https://github.com/apollographql/subscriptions-transport-ws/issues/171
    // https://github.com/apollographql/subscriptions-transport-ws/issues/777
    // https://the-guild.dev/blog/graphql-over-websockets
    const expiresInMs = accessToken.payload.exp * 1000 - Date.now();

    currentAuthTimeout = setTimeout(async () => {
      try {
        reauthWsSession({ watchWsAccessToken });
      } catch (err) {
        // Issue should be fixed soon. For now we can just swallow the error.
        // https://github.com/apollographql/subscriptions-transport-ws/issues/808
        // https://github.com/apollographql/subscriptions-transport-ws/pull/829
        if (
          ![
            `Cannot set property 'onopen' of null`,
            `Cannot set properties of null (setting 'onopen')`
          ].includes(err?.message)
        ) {
          throw err;
        }
      }
    }, expiresInMs);
  };

  Auth.currentSession().then(session => {
    const accessToken = session.getAccessToken();
    watchWsAccessToken(accessToken);
  });

  const composedLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    authLink
      .concat(restLink)
      .concat(retryLink)
      .concat(authErrorRetryLink)
      .concat(errorLink)
      .concat(httpLink)
  );

  client = new ApolloClient({
    link: composedLink,
    cache: new InMemoryCache()
  });

  return client;
};
