import React from 'react';
import ErrorBoundaries from 'scenes/Error';
import { PermissionConstants } from 'utils/AppConstants';
import { PageHeader, UserPermission, Tabs, Tab, XGrid } from 'components';
import { useSelector } from 'react-redux';
import rbReceiptListTableColumns from 'meta/Procurement/PurchaseOrders/rbReceiptListTableMeta';
import rbBillListTableColumns from 'meta/Procurement/PurchaseOrders/rbBillListTableMeta';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import GET_PURCHASE_ORDER_RECEIPT_LIST from '../queries/getPurchaseOrderReceiptList';
import GET_BILL_LIST from '../queries/getBillList';
import OverallTotalCostDisplay from '../component/OverallTotalCostDisplay';

const useStyles = makeStyles(() => ({
  divider: {
    margin: '8px -24px 32px -24px'
  }
}));

const ReceiptsBills = () => {
  const classes = useStyles();
  const user = useSelector(state => state.user);

  return (
    <ErrorBoundaries>
      <UserPermission I="read" action={PermissionConstants.OBJECT_PURCHASE_ORDER}>
        <PageHeader
          breadcrumbsArray={[
            {
              link: '',
              title: 'Procurement'
            }
          ]}
          pageMapKey="procurementReceiptBills"
          userLocale={user.locale}
        />
        <Divider className={classes.divider} />
        <Tabs showDivider>
          <Tab label="Receipts">
            <XGrid
              columns={rbReceiptListTableColumns}
              entityTypeName="Receipts"
              tableName="PurchaseOrdersReceiptXGrid"
              query={GET_PURCHASE_ORDER_RECEIPT_LIST}
              rowHeight={38}
              toolbarCustomComponent={rows => OverallTotalCostDisplay(rows)}
              enableExport
            />
          </Tab>
          <Tab label="Bills">
            <XGrid
              columns={rbBillListTableColumns}
              entityTypeName="Bills"
              tableName="BillXGrid"
              query={GET_BILL_LIST}
              rowHeight={38}
              toolbarCustomComponent={rows => OverallTotalCostDisplay(rows)}
              enableExport
            />
          </Tab>
        </Tabs>
      </UserPermission>
    </ErrorBoundaries>
  );
};

export default ReceiptsBills;
