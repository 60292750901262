/* eslint-disable no-template-curly-in-string */
const dormatechPDFForm = settings => ({
  fields: {
    senderInfoLogoUrl: {
      pdf: '${senderInfoLogo}'
    },
    tenantAddress: {
      pdf: '${senderInfo.addressLine1}  *  ${senderInfo.addressLine3}  *  ${senderInfo.phoneNumber}'
    },
    // Customer Detail
    customerContactNameTitle: {
      pdf: 'Attn: '
    },
    customerContactName: {
      pdf: '${customerContactNameTitle}'
    },
    customerNameTitle: {
      pdf: 'Company: '
    },
    customerName: {
      pdf: '${customer.customerName}'
    },
    AddressTitle: {
      pdf: 'Address: '
    },
    billingAddressLine1: {
      pdf: '${billingAddressLine1}'
    },
    billingAddressLine2: {
      pdf: '${billingAddressLine2}'
    },
    billingAddress3: {
      pdf: '${billingAddressLine3}'
    },
    customerContactPhoneTitle: {
      pdf: 'Phone: '
    },
    customerContactPhone: {
      pdf: '${customerContactPhoneTitle}'
    },
    customerContactEmailTitle: {
      pdf: 'Via Email: '
    },
    customerContactEmail: {
      pdf: '${customerContactEmailTitle}'
    },
    // Tenant Detail
    createdDateTitle: {
      pdf: 'Date: '
    },
    createdDate: {
      pdf: '${createdDate}'
    },
    quoteNameTitle: {
      pdf: 'Re: '
    },
    quoteName: {
      pdf: '${quoteInfo.name}'
    },
    propertyLabel: {
      pdf: 'Property: '
    },
    propertyName: {
      pdf: '${property.companyName}'
    },
    propertyAddressLine1: {
      pdf: '${propertyDetail.propertyAddress1}'
    },
    propertyAddressLine2: {
      pdf: '${propertyDetail.propertyAddress2}'
    },
    propertyAddressLine3: {
      pdf: '${propertyDetail.propertyAddress3}'
    },
    quoteNumberValueTitle: {
      pdf: 'File Number: '
    },
    quoteNumberValue: {
      pdf: '${customizedQuoteNumber}'
    },
    // Introduction
    introduction: {
      pdf: '${quoteCustomFieldsData.Custom_longText1}'
    },
    // Total
    totalTitle: {
      pdf: 'Total Proposal: '
    },
    totalText: {
      pdf: '$${finalAmount}'
    },
    // Closing Remarks
    closingRemarks: {
      pdf: '${quoteCustomFieldsData.Custom_longText2}'
    }
  },
  layouts: {
    default: {
      options: {
        pdfTitle: 'Quote'
      },
      contents: [
        // Logo Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              options: {
                direction: 'column',
                size: 'auto',
                grow: 0,
                shrink: 1
                // debug: true
              },
              contents: [
                {
                  component: {
                    default: 'Logo'
                  },
                  options: {
                    width: 500,
                    height: 200,
                    alt: 'Company logo',
                    size: 'auto',
                    grow: 0,
                    shrink: 1
                  },
                  source: 'senderInfoLogoUrl'
                }
              ]
            }
          ]
        },
        // Address Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [0, 0, 12]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'BodyText'
              },
              source: 'tenantAddress'
            }
          ]
        },
        // Customer Detail Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'space-between',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            // Customer Details
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                direction: 'column',
                width: '50%',
                padding: [0, 5, 0, 0]
              },
              contents: [
                // Contact Name
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactName'
                    }
                  ]
                },
                // Constact Phone
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactPhoneTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactPhone'
                    }
                  ]
                },
                // Contact Email
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactEmailTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactEmail'
                    }
                  ]
                },
                // Customer Name
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerName'
                    }
                  ]
                },
                // Customer Address
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'AddressTitle'
                    },
                    {
                      options: {
                        size: 'auto',
                        grow: 0,
                        shrink: 1,
                        direction: 'column'
                      },
                      contents: [
                        {
                          component: { pdf: 'BoldText' },
                          source: 'billingAddressLine1'
                        },
                        {
                          component: { pdf: 'BoldText' },
                          source: 'billingAddressLine2'
                        },
                        {
                          component: { pdf: 'BoldText' },
                          source: 'billingAddressLine3'
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            // Tenant Details
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                direction: 'column',
                width: '35%'
              },
              contents: [
                // Created Date
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'createdDateTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'createdDate'
                    }
                  ]
                },
                // Quote Name
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'quoteNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'quoteName'
                    }
                  ]
                },
                // Quote Number
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'quoteNumberValueTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'quoteNumberValue'
                    }
                  ]
                },
                // Property Name
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'propertyLabel'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'propertyName'
                    }
                  ]
                },
                // Property Address
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'AddressTitle'
                    },
                    {
                      options: {
                        size: 'auto',
                        grow: 0,
                        shrink: 1,
                        direction: 'column'
                      },
                      contents: [
                        {
                          component: { pdf: 'BoldText' },
                          source: 'propertyAddressLine1'
                        },
                        {
                          component: { pdf: 'BoldText' },
                          source: 'propertyAddressLine2'
                        },
                        {
                          component: { pdf: 'BoldText' },
                          source: 'propertyAddressLine3'
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        // Divider
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              options: {
                color: '#333'
              },
              component: {
                pdf: 'Divider'
              }
            }
          ]
        },
        // Intro
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'RichContentViewer'
              },
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1
              },
              source: 'introduction'
            }
          ]
        },
        // Tasks and Products
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              options: {
                direction: 'column',
                justify: 'start',
                size: 'auto',
                grow: 0,
                shrink: 1,
                padding: [0, 0, 0, 0]
                // debug: true
              },
              contents: [
                {
                  component: {
                    pdf: 'TaskList'
                  },
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    width: 480
                    // debug: true
                  },
                  source: 'quoteTasks'
                }
              ]
            }
          ]
        },
        // Total
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'flex-end',
            align: 'stetch',
            padding: [6, 36, 6],
            hide: settings.hideTotalAmount
            // debug: true
          },
          contents: [
            {
              component: {
                pdf: 'Subtitle'
              },
              source: 'totalTitle'
            },
            {
              component: {
                pdf: 'Subtitle'
              },
              source: 'totalText'
            }
          ]
        },
        // Closing Remarks
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'RichContentViewer'
              },
              source: 'closingRemarks'
            }
          ]
        },
        // Terms and conditions Row
        {
          options: {
            direction: 'row',
            breakPage: 'before',
            width: '100%'
            // debug: true
          },
          contents: [
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                justify: 'flex-start'
                // debug: true
              },
              // For centering title
              contents: [
                {
                  component: {
                    default: 'BoldText'
                  },
                  options: {
                    padding: [0, 0, 3],
                    fontSize: 10
                  },
                  source: 'Warranty Terms:'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'See Terms and Conditions #2,3,4 and manufacturer’s warranty as per terms and conditions'
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  options: {
                    padding: [0, 0, 3],
                    fontSize: 10
                  },
                  source: 'Payment Terms:'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'Net due upon receipt of invoice. 2% per month added to invoices 30 days past due. See Terms and Condition Exhibit A attached. This propsal may be withdrawn by us if not accepted within 30 days. Payments are accepted in the form of check or credit card (Visa/MasterCard/AMEX). Payments made by credit card may incur additional fees. Payments made by check are payable to Dormatech Mechanical Systems, Inc.'
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  options: {
                    padding: [0, 0, 3],
                    fontSize: 10
                  },
                  source: 'Terms:'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source: 'Customer understands and agrees that:'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '1. Dormatech Mechanical Systems, Inc. (DMSI) shall provide only trained and qualified technicians employed or supervised by DMSI'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '2. Services performed are not a guarantee against obsolescence or normal wear;'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '3. DMSI shall warrant all parts and materials provided by DMSI to the extent they are warranted by the supplier or manufacturer;'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '4. Unless otherwise agreed in writing, labor is warranted for thirty (30) days after completion of service or installation. Warranty work is performed during DMSI normal business hours, but can be performed after normal hours if Customer pays the difference between standard labor rate and overtime labor rate;'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '5. Until final payment is made, DMSI will retain the title to all materials and equipment it installs;'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '6. Unless prior arrangements have been made, payment is due in full upon completion and receipt of invoice. A monthly finance charge, equal to the highest allowable interest rate, may be levied on Customer’s account if delinquent over thirty (30) days;'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '7. In the event Customer’s account must be referred to attorneys for collection, Customer agrees to pay reasonable attorney fees, court costs and full collection costs;'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '8. Customer will carry fire, extended coverage and other necessary insurance;'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source: '9. Hourly rate subject to change based on union raises;'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '10. All Service appointments will be scheduled in advance at times that are mutually convenient for both parties;'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '11. Customer shall operate the subject equipment per the manufacturer’s instruction. Promptly notify us of any unusual operating conditions of the subject equipment; permit our personnel the use of your common building maintenance tools, such as ladders, etc.; permit only our personnel to work on the subject equipment. DMSI will not be responsible for alterations, additions, adjustments or repairs by others.'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '12. This contract shall be for an initial period of 12 months from the commencement date with an automatic renewal. Either party will have the right to terminate the contract by giving at least 30 days’ notice in writing to the other party to expire at the end of the initial period or at any time after that. Either party may terminate this contract by written notice to the other at any time if that other party:'
                },
                {
                  options: {
                    padding: [0, 0, 6, 12],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '(a) commits a breach of this contract and, in the case of a breach capable of remedy, he fails to remedy the breach within 14 days of being required to do so in writing; or (b) becomes insolvent, or has a liquidator, receiver, manager or administrative receiver appointed.'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'Dormatech Mechanical Systems, Inc. shall use ordinary care in performing all service and installation, but shall not be liable for:'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '1. Damage or loss resulting from corrosion, freezing, electrolysis, drain stoppage, plumbing leaks or malfunctions, failure of a utility service, low voltage conditions, lighting, single phasing or other electrical abnormalities;'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '2. Damage, loss or delays resulting from fire, explosion, flooding, the elements, labor troubles, or any other cause beyond our control;'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '3. Damage or loss resulting from failure to discover a condition not within the Scope of Work requiring repair or replacement;'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '4. Damage or loss resulting from improper operation or misuse of equipment by Customer, Customer’s employees, agents or tenants;'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '5. Replacements or repairs caused by negligence by others, abuse, misuse or by any other cause beyond our control, including faulty design of the equipment or system;'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '6. Injuries to persons or damage to property except those directly caused by negligent acts of DMSI employees;'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '7. Expense incurred in removing, replacing or refinishing part of the building structure necessary for the performance of any service or installation;'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '8. Any present and future taxes, charges orother governmental authorities;'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '9. Any items of equipment, labor or special test required or recommended by insurance companies, equipment vendors or governmental authorities;'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '10. Lost profits or other consequential damages, even if DMSI has been advised of the possibility of such damages;'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 9,
                    lineHeight: '11px'
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '11. The identification, detection, abatement, encapsulation, storage, removal or transportation of any regulated or hazardous substance. Regulated or hazardous substance may include, but are not limited to asbestos, certain refrigerants, and refrigerant oils. If any such products or materials are encountered during the course of work, DMSI can discontinue work until regulated or hazardous materials have been removed or hazardor liability is eliminated. DMSI shall receive an extension equal to the time of delay to complete the work and reserves the right to be compensated for any loss due to a delay.'
                },
                // to stick TOC in one page
                {
                  options: {
                    padding: 10
                  },
                  contents: []
                }
              ]
            }
          ]
        }
      ]
    }
  }
});

export default dormatechPDFForm;
