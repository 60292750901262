import useExtendedMutation from 'customHooks/useExtendedMutation';
import UpdateCustomerSignature from 'services/core/graphql/review-report/mutations/UpdateCustomerSignature';

const serializer = ({ tenantId, signatureId, version, includeInInvoice }) => ({
  variables: {
    partitionKey: tenantId,
    data: {
      id: signatureId,
      version,
      includeInInvoice
    }
  }
});

export const useToggleCustomerSignature = () => {
  return useExtendedMutation(UpdateCustomerSignature, {
    serializer
  });
};
