import gql from 'graphql-tag';
import { useSubscription } from '@apollo/client';

import { dispatch, getState } from 'redux/store';
import { quietRefreshTableData } from 'redux/actions/dispatchActions';

import { visitDetailsFragment } from '@dispatch/fragments';

export const VISIT_SUBSCRIPTION = gql`
  subscription visitUpdateNotification($partitionKey: String!) {
    visitUpdateNotification(partitionKey: $partitionKey) {
      ${visitDetailsFragment}
    }
  }
`;

export const useVisitSubscription = () => {
  const { tenantId } = getState().user;

  return useSubscription(VISIT_SUBSCRIPTION, {
    variables: { partitionKey: tenantId },
    onSubscriptionData: () => {
      dispatch(quietRefreshTableData());
    }
  });
};
