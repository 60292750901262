/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import { round, roundCurrency, roundFive } from '@buildhero/math';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import AlgoliaSearch from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import DefaultButton from 'components/Buttons/DefaultButton';
import ResponsiveTable from 'components/ResponsiveTable';
import SergeantModal from 'components/SergeantModal';
import Spinner from 'components/Spinners/CircularIndeterminate';

import Labels from 'meta/labels';

import {
  addNewListItemFields,
  addNewListItemLayout
} from 'meta/Procurement/PurchaseOrders/addNewListItemForm';
import poOrderListTableMeta from 'meta/Procurement/PurchaseOrders/poOrderListTableMeta';
import CustomCurrency from 'scenes/Procurement/component/CustomCurrency';
import ItemSearchBar from 'scenes/Procurement/component/ItemSearchBar';
import HeaderLine from 'scenes/Procurement/component/ModalHeaderLine';
import Title from 'scenes/Procurement/component/ModalTitle';
import { getJobOrProjectLink } from 'scenes/Procurement/component/utils';
import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';
import { generateDefaultValidationSchema } from 'scenes/ProjectManagement/components/formattingUtils';
import { ProcurementPurchaseOrderStatus } from 'utils/AppConstants';
import { Mode, SyncStatus } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import PurchaseOrdersUtils from '../../index.utils';

import AddPurchaseOrderItem from './AddPurchaseOrderItem';

const defaultRowActionButtons = hasRequiredPermission => {
  return {
    ...(hasRequiredPermission && {
      edit: {
        label: 'Edit',
        icon: 'Edit',
        caslAction: 'update',
        caslKey: ''
      }
    }),
    remove: {
      label: 'Remove',
      icon: 'Delete',
      caslAction: 'delete',
      caslKey: ''
    }
  };
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 8
  }
}));

const OrderList = ({
  po,
  user,
  itemData,
  isLoading = false,
  handleSaveNewItem,
  handleEditItemSave,
  handleDeleteItem,
  associatedProject,
  isVistaEnabled
}) => {
  const classes = useStyles();
  const flags = useFlags();
  const [orderItems, setOrderItems] = useState([]);
  const [selectedEditItem, setSelectedEditItem] = useState({});
  const [addNewItemModal, setAddNewItemModal] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const departmentsAreLimitedByJob = flags[FeatureFlags.DEPARTMENTS_ARE_LIMITED_BY_JOB];
  const isVistaReceiptSynced =
    isVistaEnabled &&
    po?.purchaseOrderReceipts.some(
      receipt =>
        receipt.syncStatus === SyncStatus.SYNCING || receipt.syncStatus === SyncStatus.IN_SYNC
    );
  const appPermissionRules = useSelector(state => state.user.appPermissionRules);
  const hasUpdatePurchaseOrderPermissions = PurchaseOrdersUtils.hasUpdatePurchaseOrderPermissions(
    appPermissionRules
  );

  useEffect(() => {
    setOrderItems(itemData.sort((a, b) => a.lineNumber - b.lineNumber));
  }, [itemData]);

  const getFormattedOrderLineItemData = (data = null) => {
    return {
      id: data?.id || '',
      lineNumber: data?.lineNumber || orderItems?.length + 1,
      itemName: data?.itemName || '',
      description: data?.description || '',
      unitOfMeasure: data?.unitOfMeasure || '',
      unitCost: roundFive(String(data?.unitCost)) || '0.00000',
      quantity: round(String(data?.quantity), 2) || '0.00',
      Product: data?.Product || null,
      Department: data?.Department || null,
      JcCostType: data?.JcCostType || null,
      JcPhase: data?.JcPhase || null,
      revenueType: data?.revenueType || null,
      costCode: data?.costCode?.description ? data?.costCode : null,
      jobCostType: data?.jobCostType || null,
      totalCost: roundCurrency(String((data?.unitCost ?? 0) * (data?.quantity ?? 0))) || '0.00',
      costCodeId: data?.costCodeId || '',
      jobCostTypeId: data?.jobCostTypeId || '',
      revenueTypeId: data?.revenueTypeId || '',
      projectPhase: data?.projectPhase || null,
      projectCostCode: data?.projectCostCode || null,
      projectCostType: data?.projectCostType || '',
      taxable: data?.taxable || false,
      quantityFulfilled: Number(data?.quantityFulfilled) || 0,
      departmentId: data?.departmentId || '',
      departmentName: data?.departmentName || '',
      status: data?.status,
      parentId: data?.parentId,
      ...getJobOrProjectLink(data, associatedProject),
      jcPhaseName: data?.JcPhase?.name,
      jcCostTypeName: data?.JcCostType?.name
    };
  };

  const getFormattedData = (data = null) => {
    return {
      id: data?.id || '',
      lineNumber: data?.lineNumber,
      itemName: data?.itemName || '',
      description: data?.description || '',
      unitOfMeasure: data?.unitOfMeasure || '',
      unitCost: roundFive(String(data?.unitCost)) || '0.0000',
      parentId: data?.parentId,
      quantity: round(String(data?.quantity), 2) || '0.00',
      Product: data?.Product || null,
      Department: data?.Department || null,
      revenueType: data?.revenueType || null,
      costCode: data?.costCode?.description ? data?.costCode : null,
      jobCostType: data?.jobCostType || null,
      totalCost: roundCurrency(String(data?.unitCost * data?.quantity)) || 0,
      costCodeId: data?.costCodeId || '',
      jobCostTypeId: data?.jobCostTypeId || '',
      revenueTypeId: data?.revenueTypeId || '',
      projectPhase: data?.projectPhase || null,
      projectCostCode: data?.projectCostCode || null,
      projectCostType: data?.projectCostType || '',
      // for Algolia search, not overwriting jobAndProject as the full data is used else where
      // TODO: When Project is added to Algolia, add attribute
      jobOrProjectDisplay:
        data?.jobAndProject?.customIdentifier ||
        data?.jobAndProject?.jobNumber ||
        data?.jobAndProject?.name ||
        data?.jobAndProject?.number,
      jobAndProject:
        data?.jobAndProject ||
        (associatedProject && { ...associatedProject, entityType: 'Project' }) ||
        '',
      taxable: data?.taxable || false,
      quantityFulfilled: Number(data?.quantityFulfilled) || 0,
      departmentId: data?.departmentId || '',
      departmentName: data?.departmentName || '',
      status: data?.status,
      jcPhase: data?.JcPhase || null,
      jcCostType: data?.JcCostType || null
    };
  };

  const handleChangeQuantity = ({ form, currentValue }) => {
    const { unitCost } = form.values;

    form.setFieldValue('quantity', round(String(currentValue), 2));
    form.setFieldValue('totalCost', roundCurrency(String(currentValue * unitCost)));
  };

  const handleRowactions = async (actionType, record) => {
    if (actionType === 'remove') {
      await handleDeleteItem(record);
    } else if (actionType === 'edit') {
      const fullItemData = orderItems.filter(line => line.lineNumber === record.lineNumber)[0];

      fullItemData.jobAndProject = fullItemData?.Project || fullItemData?.Job || null;
      const projectPhaseDepartment = fullItemData?.ProjectPhaseDepartment?.find(
        phaseDep => phaseDep.departmentId === fullItemData?.departmentId
      );
      fullItemData.phaseDepartmentId = projectPhaseDepartment?.id;

      setSelectedEditItem(fullItemData);
      setEditModalOpen(true);
    }
  };

  const handleAddPurchaseOrderItem = () => {
    const item = {
      Department: po.department,
      jobAndProject: po.project || po.job || null
    };

    setSelectedEditItem(item);
    setAddNewItemModal(true);
  };

  return (
    <div className={classes.root}>
      <div className={classes.buttonContainer}>
        {po.status !== ProcurementPurchaseOrderStatus.VOID && hasUpdatePurchaseOrderPermissions && (
          <DefaultButton
            label={Labels.addNewLineItem[user.locale]}
            variant="containedSecondary"
            style={{ height: 30, fontSize: 12 }}
            onClick={handleAddPurchaseOrderItem}
            buttonProps={{ startIcon: <AddCircleOutlineIcon style={{ fontSize: 14 }} /> }}
          />
        )}
      </div>
      <ResponsiveTable
        rowMetadata={poOrderListTableMeta(isVistaEnabled)}
        data={orderItems.map(item => getFormattedOrderLineItemData(item))}
        noDataMsg={isLoading ? <Spinner /> : 'No Orders'}
        disableFilter
        rowActionButtons={
          po.status === ProcurementPurchaseOrderStatus.VOID
            ? null
            : defaultRowActionButtons(hasUpdatePurchaseOrderPermissions)
        }
        customCellComponents={{
          CustomCurrency
        }}
        rowActions={handleRowactions}
        defaults={{
          sortBy: 'lineNumber',
          sortOrder: 'asc'
        }}
      />
      <AddPurchaseOrderItem
        open={addNewItemModal}
        Department={po.department}
        jobAndProject={po.project || po.job || null}
        associatedProject={associatedProject}
        handleSave={newItems => {
          handleSaveNewItem(newItems);
          setAddNewItemModal(false);
        }}
        handleClose={() => setAddNewItemModal(false)}
      />

      <SergeantModal
        open={editModalOpen}
        title={Labels.editPurchaseOrderItem[user.locale]}
        customPrimaryButtonLabel={Labels.saveChangesButtonText[user.locale]}
        data={getFormattedData({
          ...selectedEditItem,
          Product: { id: selectedEditItem?.Product?.id, name: selectedEditItem?.Product?.name }
        })}
        dataType="Item"
        mode={Mode.EDIT}
        layout={addNewListItemLayout({
          handleChangeQuantity,
          selectedPhaseId: selectedEditItem.projectPhase?.id,
          selectedProjectId: selectedEditItem.projectId,
          selectedPhaseDepartmentId: selectedEditItem.phaseDepartmentId,
          projectCostCode: selectedEditItem.projectCostCode,
          isVistaEnabled,
          selectedEditItem,
          selectedJobId: selectedEditItem.jobId,
          departmentsAreLimitedByJob,
          isVistaReceiptSynced,
          setSelectedEditItem
        })}
        handlePrimaryAction={(updatedItem, stopLoading) => {
          handleEditItemSave(updatedItem, stopLoading);
          setEditModalOpen(false);
        }}
        handleClose={() => setEditModalOpen(false)}
        customComponents={{ SearchBar, ItemSearchBar, AlgoliaSearch, Title, HeaderLine }}
        alignCloseRight
        validationSchema={generateDefaultValidationSchema(
          addNewListItemFields(selectedEditItem.projectId, isVistaEnabled)
        )}
      />
    </div>
  );
};

OrderList.propTypes = {
  po: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  itemData: PropTypes.array.isRequired,
  handleSaveNewItem: PropTypes.func.isRequired,
  handleEditItemSave: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ user: state.user });
const ReduxConnectedOrderList = connect(mapStateToProps)(OrderList);
export default ReduxConnectedOrderList;
