export const flattenBillingHours = lineItems =>
  lineItems.labourRateBillingHourLines?.items?.reduce(
    (result, { hourTypeId, totalHours, rate }) => ({
      ...result,
      [`hours_${hourTypeId}`]: totalHours,
      [`rate_${hourTypeId}`]: rate,
      subTotal: (result.subTotal || 0) + (totalHours || 0) * (rate || 0)
    }),
    {}
  ) || {};

const patchLineItemField = ({ lineItemId, fieldKey, fieldValue }) => lineItems =>
  lineItems.map(lineItem => {
    if (lineItem.id === lineItemId) {
      return {
        ...lineItem,
        [fieldKey]: fieldValue
      };
    }
    return lineItem;
  });

export const patchLineItemHoursField = ({
  lineItemId,
  hourTypeId,
  fieldKey,
  fieldValue
}) => lineItems =>
  lineItems.map(lineItem => {
    if (lineItem.id === lineItemId) {
      const { items } = lineItem.labourRateBillingHourLines;
      const billingHourIndex = items.findIndex(item => item.hourTypeId === hourTypeId);
      return {
        ...lineItem,
        labourRateBillingHourLines: {
          ...lineItem.labourRateBillingHourLines,
          items:
            billingHourIndex < 0
              ? [...items, { hourTypeId, [fieldKey]: fieldValue }]
              : items.map((billingHour, index) => {
                  if (index === billingHourIndex) {
                    return {
                      ...billingHour,
                      [fieldKey]: fieldValue
                    };
                  }
                  return billingHour;
                })
        }
      };
    }
    return lineItem;
  });

export const patchLineItemHourRates = ({
  lineItemId,
  laborTypeId,
  laborRates,
  employeeMap,
  isTechnicianDefaultRates
}) => lineItems => {
  const hoursRates = laborRates.filter(laborRate => laborRate.labourTypeId === laborTypeId);

  return lineItems.map(lineItem => {
    if (lineItem.id === lineItemId) {
      const hoursRateMap = isTechnicianDefaultRates
        ? employeeMap[lineItem.employeeId]?.billingRates?.items?.reduce(
            (result, techBillingRate) => ({
              ...result,
              [techBillingRate.hourTypeId]: techBillingRate?.rate
            }),
            {}
          ) || {}
        : hoursRates?.reduce(
            (result, hourRate) => ({
              ...result,
              [hourRate?.billingHourTypeId]: hourRate?.rate
            }),
            {}
          ) || {};

      const items = lineItem?.labourRateBillingHourLines?.items?.map(item => ({
        ...item,
        rate: hoursRateMap[item?.hourTypeId]
      }));

      return {
        ...lineItem,
        labourRateBillingHourLines: {
          ...lineItem.labourRateBillingHourLines,
          items
        }
      };
    }
    return lineItem;
  });
};

export const patchLineItemLaborType = ({ lineItemId, laborTypeId }) =>
  patchLineItemField({ lineItemId, fieldKey: 'labourTypeId', fieldValue: laborTypeId });

export const patchLineItemTechnician = ({ lineItemId, technicianId }) =>
  patchLineItemField({ lineItemId, fieldKey: 'employeeId', fieldValue: technicianId });

export const patchLineItemDepartment = ({ lineItemId, departmentId }) =>
  patchLineItemField({ lineItemId, fieldKey: 'departmentId', fieldValue: departmentId });
