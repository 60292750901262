import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { InvoiceStatus } from 'utils/constants';
import { GET_INVOICE } from 'scenes/Invoices/InvoiceDetail/InvoiceDetail.gql';
import {
  downloadInvoice,
  formatInvoiceData
} from 'scenes/Invoices/InvoiceDetail/InvoiceDetail.utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { snackbarOn } from 'redux/actions/globalActions';
import { connect } from 'react-redux';
import { Button, ButtonType, ButtonSize, ThemeProvider } from '@buildhero/sergeant';

const propTypes = {
  record: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired
};

const DownloadInvoice = ({ record, snackbar }) => {
  const { adjustments: adjustmentsFlag } = useFlags();
  const [loading, setLoading] = useState(false);
  const [getInvoice, { data, error }] = useLazyQuery(GET_INVOICE, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    const download = async invoice => {
      await downloadInvoice(await formatInvoiceData({ ...invoice, adjustmentsFlag }));
      setLoading(false);
    };

    // data is not changed if cached before, so using loading as indicator that user wants to download again
    if (data && loading) {
      const { getInvoiceById: invoice } = data;
      download(invoice);
    }
  }, [adjustmentsFlag, data, loading]);

  useEffect(() => {
    if (error) snackbar('error', 'Failed to download the invoice. Please try again.');
  }, [error, snackbar]);

  return (
    record.invoiceNumber &&
    record.status.toLowerCase() !== InvoiceStatus.DRAFT && (
      <ThemeProvider>
        <Button
          type={ButtonType.TERTIARY}
          size={ButtonSize.SMALL}
          loading={loading}
          style={{ width: 'max-content' }}
          onClick={async () => {
            setLoading(true);
            await getInvoice({ variables: { id: record.id, adjustmentsFlag } });
          }}
        >
          Download
        </Button>
      </ThemeProvider>
    )
  );
};

DownloadInvoice.propTypes = propTypes;

export default connect(state => state, { snackbar: snackbarOn })(DownloadInvoice);
