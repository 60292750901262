import React from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import Placeholder from 'components/Placeholder';
import AutoComplete from 'components/AutoCompleteDropdown';
import UserPermisson from 'components/AppPermissions';
import useLabourType from 'customHooks/useLabourTypes';
import usePayrollHourTypes from 'customHooks/usePayrollHourTypes';
import usePayrollBillingHourTypes from 'customHooks/usePayrollBillingHourTypes';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import { PermissionConstants } from 'utils/AppConstants';
import Rates from './Rates';
import { getValue } from './helpers';
import useStyles from './styles';

const BillingRate = ({ billingRateInfo, user, snackbar, isViewMode, isEditMode, onChange }) => {
  const classes = useStyles();
  const { labourTypeId, billingRates, payrollCosts } = billingRateInfo;
  const { tenantId, tenantCompanyId } = user;
  const commonProps = [tenantId, tenantCompanyId, snackbar];
  const [labourTypes, , laborLoaded] = useLabourType(...commonProps);
  const [hourTypes, , payrollHourTypesLoading] = usePayrollHourTypes(...commonProps);
  const [billingHourTypes, , billingHourTypesloaded] = usePayrollBillingHourTypes(...commonProps);

  const handleOnChange = (key, value) => onChange({ ...billingRateInfo, [key]: value });
  const labourOptions = constructSelectOptions(labourTypes);

  let laborTypeValue = labourTypeId;
  // BUOP-7036 - labour type is essential for job costing at the emplyoee level. hence defaulting
  if (!labourTypeId && labourTypes?.length && !isEditMode) {
    laborTypeValue = labourTypes[0]?.id;
    onChange({ ...billingRateInfo, labourTypeId: laborTypeValue });
  }

  const isLoading = !laborLoaded || payrollHourTypesLoading || !billingHourTypesloaded;
  if (isLoading) return <Placeholder />;

  return (
    <Box display="flex" p={3} flexDirection="column">
      <Typography variant="h5">Technician Labor Type & Hour Rate Settings</Typography>
      <Divider variant="fullWidth" classes={{ root: classes.blueDivider }} />
      <Box display="flex" marginTop={4}>
        <Box display="flex" width={210} flexDirection="column">
          <Typography className={classes.subtitle} variant="body1">
            Labor Type
          </Typography>
          {isViewMode && (
            <Typography className={classes.noEditableValue} variant="body2">
              {getValue(labourOptions, labourTypeId, 'value') || '-'}
            </Typography>
          )}
          {!isViewMode && (
            <AutoComplete
              inputProps={{
                textFieldClassName: classes.selectionPlaceholder,
                inputClassNames: classes.selectionInput,
                className: classes.selectionWrapper,
                disableUnderline: true
              }}
              value={laborTypeValue}
              options={labourOptions}
              handleOnChange={newLabourTypeId => handleOnChange('labourTypeId', newLabourTypeId)}
            />
          )}
        </Box>
        <UserPermisson I="allow" action={PermissionConstants.DATA_VIEW_EDIT_EMPLOYEE_HOURLY_RATES}>
          <Rates
            dataKey="cost"
            hourTypes={hourTypes}
            isViewMode={isViewMode}
            title="Payroll Hour Rates"
            existingRates={payrollCosts}
            onChange={values => handleOnChange('payrollCosts', values)}
          />
          <Rates
            dataKey="rate"
            title="Billing Hour Rates"
            isViewMode={isViewMode}
            hourTypes={billingHourTypes}
            existingRates={billingRates}
            onChange={values => handleOnChange('billingRates', values)}
          />
        </UserPermisson>
      </Box>
    </Box>
  );
};

export default BillingRate;
