import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { ThemeProvider, Typography, TV, TW } from '@buildhero/sergeant';
import AttachFile from '@material-ui/icons/AttachFile';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import { isEmpty } from 'lodash';

import StorageService from 'services/StorageService';
import { ActionsMenu } from 'components';
import { attachmentToFileInput } from '../helpers';

const useStyles = makeStyles(theme => ({
  text: {
    paddingBottom: theme.spacing(0.2),
    borderBottom: `1px solid ${theme.palette.grayscale(20)}`
  },
  icon: {
    width: 16,
    height: 16,
    fill: theme.palette.grayscale(0),
    marginRight: theme.spacing(1),
    marginTop: 2
  },
  comment: {
    color: theme.palette.grayscale(60),
    marginTop: 3,
    lineHeight: '12px',
    whiteSpace: 'pre-line'
  },
  iconButton: {
    padding: 0,
    marginLeft: 'auto'
  },
  menuItem: {
    padding: 8
  }
}));

const AttachItem = ({ field, handleAttachmentActions, isReadonly }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const showActionMenu = event => {
    setAnchorEl({ ref: event.currentTarget });
  };

  const handleFileClick = async item => {
    const storageService = new StorageService();
    const attachmentUrl = await storageService.getFile(item.fileUrl);
    window.open(attachmentUrl);
  };

  const attachments = field?.value || [];

  return (
    <ThemeProvider>
      <Box pb={1.5} flexDirection="column" display="flex" width={1}>
        {attachments.map(item => (
          <Box key={item.id} py={0.5} alignItems="flex-start" display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" width="100%">
              <AttachFile
                className={classes.icon}
                variant="primary"
                onClick={() => handleFileClick(item)}
              />
              <Typography
                className={classes.text}
                variant={TV.BASE}
                weight={TW.REGULAR}
                style={{ cursor: 'pointer' }}
                onClick={() => handleFileClick(item)}
              >
                {item?.customFileName || item?.fileName || '-'}
              </Typography>
              {!isReadonly && (
                <>
                  <IconButton
                    aria-label="More Icon"
                    onClick={showActionMenu}
                    className={classes.iconButton}
                  >
                    <MoreVertIcon />
                  </IconButton>

                  <ActionsMenu
                    rowActions={async mode => {
                      const attachmentsWithPreview = await attachmentToFileInput(item);
                      return handleAttachmentActions({
                        mode,
                        attachmentItem: attachmentsWithPreview
                      });
                    }}
                    handleMenuClose={() => setAnchorEl(null)}
                    anchorEl={anchorEl}
                    rowActionButtons={{
                      edit: {
                        label: 'Edit',
                        icon: 'Edit'
                      },
                      delete: {
                        label: 'Remove',
                        icon: 'Delete'
                      }
                    }}
                    className={classes.menuItem}
                  />
                </>
              )}
            </Box>
            {item?.comment && (
              <Typography
                variant={TV.S1}
                weight={TW.REGULAR}
                className={classes.comment}
                gutterBottom
              >
                {item.comment}
              </Typography>
            )}
          </Box>
        ))}
        {isEmpty(attachments) && (
          <Typography variant={TV.BASE} weight={TW.REGULAR}>
            -
          </Typography>
        )}
      </Box>
    </ThemeProvider>
  );
};

AttachItem.propTypes = {
  field: PropTypes.object, // formik field
  handleAttachmentActions: PropTypes.func, // handle edit and delete attachments,
  isReadonly: PropTypes.bool
};

AttachItem.defaultProps = {
  field: {},
  handleAttachmentActions: () => {},
  isReadonly: true
};

export default AttachItem;
