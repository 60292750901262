import difference from 'lodash/difference';
import { getState } from 'redux/store';

import { VisitFormFields } from '@dispatch/components/Drawer/components/VisitDetailsForm/VisitDetailsForm.constants';

export const clearDetailsSent = {
  type: 'clearDetailsSent',
  formFields: {},
  serializer: ({ visitTransitionInput }) => {
    return {
      ...visitTransitionInput,
      visit: { ...visitTransitionInput.visit, detailsSent: false }
    };
  }
};

export const setDetailsSent = {
  type: 'setDetailsSent',
  formFields: {},
  serializer: ({ event: { inputData = {} }, visitTransitionInput }) => ({
    ...visitTransitionInput,
    extraTechIds: inputData.extraTechs,
    primaryTechIds: inputData.primaryTechId ? [inputData.primaryTechId] : [],
    visit: {
      ...visitTransitionInput.visit,
      detailsSent: false
    }
  })
};

export const setOnHold = {
  type: 'setOnHold',
  formFields: {
    [VisitFormFields.onHoldReason.key]: true
  },
  serializer: ({ event: { inputData = {} }, visitTransitionInput }) => ({
    ...visitTransitionInput,
    visit: { ...visitTransitionInput.visit, onHold: true, onHoldReason: inputData.onHoldReason }
  })
};

export const undoOnHold = {
  type: 'undoOnHold',
  formFields: {
    [VisitFormFields.onHoldReason.key]: true
  },
  serializer: ({ visitTransitionInput }) => ({
    ...visitTransitionInput,
    visit: { ...visitTransitionInput.visit, onHold: false, onHoldReason: null }
  })
};

export const updateScheduledFor = {
  type: 'updateScheduledFor',
  formFields: {
    [VisitFormFields.scheduledFor.key]: true
  },
  serializer: ({ event: { inputData = {} }, visitTransitionInput }) => ({
    ...visitTransitionInput,
    visit: { ...visitTransitionInput.visit, scheduledFor: inputData.scheduledFor }
  })
};

export const updateTechs = {
  type: 'updateTechs',
  formFields: {
    [VisitFormFields.extraTechs.key]: true,
    [VisitFormFields.primaryTechId.key]: true
  },
  serializer: ({ event: { inputData = {} }, visitTransitionInput }) => ({
    ...visitTransitionInput,
    extraTechIds: inputData.extraTechs,
    primaryTechIds: inputData.primaryTechId ? [inputData.primaryTechId] : []
  })
};

const selectActualDuration = duration => {
  if (duration === 0) return null;
  if (!duration) return;

  return `${duration} minutes`;
};

export const updateVisit = {
  type: 'updateVisit',
  formFields: {
    [VisitFormFields.actualDuration.key]: true,
    [VisitFormFields.departmentId.key]: true,
    [VisitFormFields.description.key]: true,
    [VisitFormFields.extraTechsNumber.key]: true,
    [VisitFormFields.prerequisites.key]: true,
    [VisitFormFields.prerequisitesAcknowledged.key]: true
  },
  serializer: ({ event: { inputData = {} }, visitTransitionInput }) => ({
    ...visitTransitionInput,
    visit: {
      ...visitTransitionInput.visit,
      actualDuration: selectActualDuration(inputData.actualDuration),
      departmentId: inputData.departmentId,
      departmentName: inputData.departmentName,
      description: inputData.description,
      extraTechsNumber: inputData.extraTechsNumber,
      extraTechsRequired: Boolean(inputData.extraTechsNumber),
      prerequisites: inputData.prerequisites,
      prerequisitesAcknowledged: inputData.prerequisitesAcknowledged
    }
  })
};

export const updateWorkingVisit = {
  type: 'updateWorkingVisit',
  formFields: {
    [VisitFormFields.actualDuration.key]: true,
    [VisitFormFields.description.key]: true,
    [VisitFormFields.extraTechs.key]: true,
    [VisitFormFields.prerequisites.key]: true,
    [VisitFormFields.prerequisitesAcknowledged.key]: true
  },
  serializer: ({ event: { inputData = {}, originalVisit = {} }, visitTransitionInput }) => ({
    ...visitTransitionInput,
    extraTechIds: inputData.extraTechs
      ? [...originalVisit.extraTechs, ...difference(inputData.extraTechs, originalVisit.extraTechs)]
      : undefined,
    visit: {
      ...visitTransitionInput.visit,
      actualDuration: inputData.actualDuration
        ? selectActualDuration(inputData.actualDuration)
        : undefined,
      description: inputData.description,
      prerequisites: inputData.prerequisites,
      prerequisitesAcknowledged: inputData.prerequisitesAcknowledged
    }
  })
};

// The actions below are handled by the server. We will need them later
// for the optimistic UI logic for tech status visualizations.

export const cancelSchedules = {
  type: 'cancelSchedules',
  formFields: {},
  serializer: ({ visitTransitionInput }) => {
    return visitTransitionInput;
  }
};

export const completeSchedules = {
  type: 'completeSchedules',
  formFields: {},
  serializer: ({ visitTransitionInput }) => {
    return visitTransitionInput;
  }
};

export const duplicateVisit = {
  type: 'duplicateVisit',
  formFields: {},
  serializer: ({ event: { inputData = {} }, visitTransitionInput }) => ({
    ...visitTransitionInput,
    visit: {
      ...visitTransitionInput.visit,
      departmentId: inputData.departmentId,
      description: inputData.description,
      prerequisites: inputData.prerequisites,
      prerequisitesAcknowledged: inputData.prerequisitesAcknowledged,
      scheduledFor: inputData.scheduledFor,
      actualDuration: selectActualDuration(inputData.actualDuration),
      departmentName: inputData.departmentName,
      extraTechsNumber: inputData.extraTechsNumber,
      extraTechsRequired: Boolean(inputData.extraTechsNumber)
    },
    extraTechIds: inputData.extraTechs,
    primaryTechIds: inputData.primaryTechId ? [inputData.primaryTechId] : []
  })
};

export const putSchedulesOnHold = {
  type: 'putSchedulesOnHold',
  formFields: {},
  serializer: ({ visitTransitionInput }) => {
    return visitTransitionInput;
  }
};

export const rescheduleSchedules = {
  type: 'rescheduleSchedules',
  formFields: {},
  serializer: ({ visitTransitionInput }) => {
    return visitTransitionInput;
  }
};
