import React from 'react';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { Box, Typography } from '@material-ui/core';
import { convertStringToFloat, convertNumberToFixed } from 'utils';
import useStyles from './NumberInput.styles';

const NumberInput = ({ isEditable, handleEdit, onEditHandler, showPriceSymbol, ...props }) => {
  const classes = useStyles();
  const propsValue = props.value || '';

  const onBlur = e => {
    const currentValue = convertStringToFloat(e.target.value || null);
    onEditHandler(currentValue);
  };

  const isPriceSymbolVisible = showPriceSymbol && propsValue;
  const Icon = <Typography variant="body2">$</Typography>;

  if (!isEditable) {
    return (
      <Box display="inline-flex" alignItems="center" justifyContent="space-between">
        {isPriceSymbolVisible && Icon}
        <Typography style={{ marginLeft: 5 }} variant="body2">
          {convertNumberToFixed(propsValue, 2) || '-'}
        </Typography>
      </Box>
    );
  }

  return (
    <Box display="inline-flex">
      <CurrencyTextField
        variant="filled"
        value={propsValue}
        outputFormat="number"
        decimalCharacter="."
        digitGroupSeparator=","
        placeholder="-"
        onBlur={onBlur}
        InputProps={{
          className: classes.container,
          disableUnderline: true,
          startAdornment: isPriceSymbolVisible && Icon
        }}
      />
    </Box>
  );
};

export default NumberInput;
