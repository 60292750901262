import moment from 'moment';

export const calcContractValue = (dept, startingValue, add = false) => {
  const deptTotal =
    parseFloat(dept?.materialCost || 0) +
    parseFloat(dept?.laborDollars || 0) +
    parseFloat(dept?.equipmentCost || 0) +
    parseFloat(dept?.otherCost || 0) +
    parseFloat(dept?.subcontractorCost || 0) +
    parseFloat(dept?.overheadCost || 0);

  const result = add
    ? parseFloat(startingValue) + deptTotal
    : parseFloat(startingValue) - deptTotal;
  return result;
};

export const getFormattedPhaseDeptCode = (code, isNew = false) => {
  return {
    name: code.name || undefined,
    description: code.description || undefined,
    id: isNew ? undefined : code.id,
    costCodeId: code.costCodeId || undefined,
    projectPhaseId: code.projectPhaseId || null,
    projectId: code.projectId || undefined,
    projectCostCodeId: isNew ? code.id : code.projectCostCodeId || undefined,
    installUnits: parseInt(code.installUnits, 10) || 0,
    laborHours: parseFloat(code.laborHours) || 0,
    laborDollars: parseFloat(code.laborDollars) || 0,
    equipmentCost: parseFloat(code.equipmentCost) || 0,
    materialCost: parseFloat(code.materialCost) || 0,
    overheadCost: parseFloat(code.overheadCost) || 0,
    otherCost: parseFloat(code.otherCost) || 0,
    subcontractorCost: parseFloat(code.subcontractorCost) || 0
  };
};

export const getFormattedPhaseDepartment = dept => {
  return {
    id: dept.id,
    departmentId: dept.Department?.id || dept.departmentId || undefined,
    tagName: dept.Department?.tagName || dept.tagName || undefined,
    equipmentCost: parseFloat(dept.equipmentCost) || 0,
    materialCost: parseFloat(dept.materialCost) || 0,
    subcontractorCost: parseFloat(dept.subcontractorCost) || 0,
    laborDollars: parseFloat(dept.laborDollars) || 0,
    otherCost: parseFloat(dept.otherCost) || 0,
    overheadCost: parseFloat(dept.overheadCost) || 0,
    projectPhaseId: dept.projectPhaseId,
    ProjectPhaseDepartmentCostCode:
      dept.ProjectPhaseDepartmentCostCode?.map(code => ({
        ...getFormattedPhaseDeptCode(code),
        projectPhaseId: dept.projectPhaseId
      })) || undefined
  };
};

function cleanBadDates(date) {
  if (!date || date === 0 || date < 0) return null;
  return date;
}

export const formatPhaseForForm = data => {
  return {
    name: data?.name || '',
    description: data?.description || '',
    status: data?.status || '',
    id: data.id,
    startDate:
      typeof data?.startDate === 'number' ? cleanBadDates(data?.startDate) : data?.startDate,
    endDate: typeof data?.endDate === 'number' ? cleanBadDates(data?.endDate) : data?.endDate
  };
};

export const getFormattedPhaseData = data => {
  return {
    name: data?.name || '',
    description: data?.description || '',
    status: data?.status || '',
    startDate: data?.startDate ? moment(data.startDate).format('MM/DD/YYYY') : '',
    endDate: data?.endDate ? moment(data.endDate).format('MM/DD/YYYY') : '',
    id: data?.id || null,
    ProjectPhaseDepartment: data?.ProjectPhaseDepartment
      ? data.ProjectPhaseDepartment.map(dept => getFormattedPhaseDepartment(dept))
      : {}
  };
};

export const formatPhaseForUpdate = data => {
  return {
    projectId: data?.projectId,
    id: data?.id || undefined,
    name: data?.name || '',
    description: data?.description || '',
    status: data?.status || '',
    startDate: data?.startDate,
    endDate: data?.endDate,
    weight: 0,
    ProjectPhaseDepartment: data?.ProjectPhaseDepartment
      ? data.ProjectPhaseDepartment.map(dept => getFormattedPhaseDepartment(dept))
      : {}
  };
};

export const getCodeTotal = code => {
  return parseFloat(
    parseFloat(code.materialCost || 0) +
      parseFloat(code.equipmentCost || 0) +
      parseFloat(code.subcontractorCost || 0) +
      parseFloat(code.overheadCost || 0) +
      parseFloat(code.otherCost || 0) +
      parseFloat(code.laborDollars || 0)
  );
};
