import React, { useState, useMemo } from 'react';
import { bool, func, object } from 'prop-types';
import { convertToCurrencyString } from 'utils';
import { JobBillingStatus } from 'utils/AppConstants';
import getJobIdentifier from 'utils/getJobIdentifier';
import { InventoryPartSourceType } from 'utils/constants';

import { CurrencyInput, PercentageInput, Typography } from '@buildhero/sergeant';
import { Chip } from '@material-ui/core';
import useCreateInvoiceFromQuotedAmountJob from '../../hooks/useCreateInvoiceFromQuotedAmountJob';
import useQuotedAmountSetting from '../../hooks/useQuotedAmountSetting';
import useDepartmentValue from '../../hooks/useDepartmentValue';
import CreateInvoiceModal from '../CreateInvoiceModal';
import JobLabel from '../JobLabel';
import { useStyles } from './QuotedAmountJobCreateInvoiceModal.styles';
import DepartmentSelect from '../DepartmentSelect';

const QuotedAmountJobCreateInvoiceModal = ({ jobData, open, onClose, onSuccess }) => {
  const { amountQuoted, inventoryParts } = jobData;
  const amountRemainingToBeInvoiced = useMemo(
    () => 
      amountQuoted -
      (inventoryParts?.items?.reduce((amount, part) => {
        if (part.source !== InventoryPartSourceType.AMOUNT_QUOTED || !part.invoiceItemId) return amount;
        return amount + part.unitPrice;
      }, 0) || 0),
    [inventoryParts, amountQuoted]
  );
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [departmentValue, setDepartmentValue] = useDepartmentValue(jobData);
  const [partialBilling, setPartialBilling] = useState(false);
  const [partialBillingAmount, setPartialBillingAmount] = useState(amountRemainingToBeInvoiced);
  const quotedAmountSetting = useQuotedAmountSetting();
  const [createInvoice] = useCreateInvoiceFromQuotedAmountJob();

  const jobIdentifier = getJobIdentifier(jobData);

  const isJobFullyInvoiced = jobData.billingStatus === JobBillingStatus.FULLY_INVOICED;

  const canCreateInvoice = !isJobFullyInvoiced && Boolean(departmentValue);

  const renderBody = () => {
    if (isJobFullyInvoiced) {
      return (
        <>
          <JobLabel jobIdentifier={jobIdentifier} />
          <div css={styles.container}>
            <Typography>All quoted amount was invoiced.</Typography>
          </div>
        </>
      );
    }

    if (quotedAmountSetting.length) {
      return (
        <>
          <JobLabel jobIdentifier={jobIdentifier} />
          <div css={styles.partialBillingContainer}>
            <Chip
              label="Entire Job"
              css={partialBilling ? styles.partialBillingChip : styles.selectedPartialBillingChip}
              onClick={() => setPartialBilling(false)}
            />
            <Chip
              label="Partial Billing"
              css={partialBilling ? styles.selectedPartialBillingChip : styles.partialBillingChip}
              onClick={() => setPartialBilling(true)}
            />
          </div>

          {quotedAmountSetting.map(setting => {
            const { id, name, percentage } = setting || {};
            let amount = (amountRemainingToBeInvoiced * (percentage || 0)) / 100;
            if (partialBilling) amount = (partialBillingAmount * (percentage || 0)) / 100;
            return (
              <div key={id} css={styles.container}>
                <Typography>{name || ''}</Typography>
                <Typography>{convertToCurrencyString(amount)}</Typography>
              </div>
            );
          })}
          {partialBilling && (
            <div css={styles.partialBillingInputContainer}>
              <div css={styles.partialBillingInput}>
                <CurrencyInput
                  label="Invoice Amount"
                  value={partialBillingAmount}
                  disabled={loading}
                  onBlur={val => {
                      const amount = Math.min(val ?? 0, amountRemainingToBeInvoiced)
                      setPartialBillingAmount(amount)
                    }
                  }
                />
              </div>
              <div css={styles.partialBillingInput}>
                <PercentageInput
                  label="Invoice Percent"
                  value={(partialBillingAmount / amountRemainingToBeInvoiced) * 100}
                  readOnly
                />
              </div>
            </div>
          )}
          <DepartmentSelect
            onChange={setDepartmentValue}
            value={departmentValue}
            disabled={loading}
          />
        </>
      );
    }

    return (
      <>
        <JobLabel jobIdentifier={jobIdentifier} />
        <div css={styles.container}>
          <Typography>Amount Quoted</Typography>
          <Typography>{convertToCurrencyString(amountRemainingToBeInvoiced)}</Typography>
        </div>
        <DepartmentSelect
          onChange={setDepartmentValue}
          value={departmentValue}
          disabled={loading}
        />
      </>
    );
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await createInvoice({
        jobId: jobData?.id,
        departmentId: departmentValue?.value,
        amountQuoted: partialBilling ? partialBillingAmount : amountRemainingToBeInvoiced
      });
      await onSuccess();
      return response?.data?.createInvoiceFromQuotedAmountJob;
    } finally {
      setLoading(false);
    }
  };

  return (
    <CreateInvoiceModal
      renderBody={renderBody}
      onSubmit={handleSubmit}
      canCreateInvoice={canCreateInvoice}
      onClose={onClose}
      open={open}
    />
  );
};

QuotedAmountJobCreateInvoiceModal.propTypes = {
  jobData: object.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
  onSuccess: func.isRequired
};

export default QuotedAmountJobCreateInvoiceModal;
