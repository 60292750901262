import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import { useStyles } from './styles';

const ErrorDisplay = props => {
  const styles = useStyles();
  const { message = 'Error', shouldDisplay = false } = props.options;

  return shouldDisplay ? (
    <div css={styles.error}>
      <div>
        <ErrorIcon />
      </div>
      <div>{message}</div>
    </div>
  ) : (
    <></>
  );
};

export default ErrorDisplay;
