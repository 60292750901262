import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from 'ckeditor5/packages/ckeditor5-build-decoupled-document/build/ckeditor';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import configForEnvironment from 'configs/aws-exports';
import ENV from 'configs/env';
import { PermissionConstants } from 'utils/AppConstants';
import { checkPermission } from 'utils';
import './styles.css';
import { smartFieldsDropdownList } from './CKEditor.constants';
import { addAttributesToElement, extendAttributesToElement } from './CKEditor.utils';
import { parseHTMLForSmartFields } from './CKEditor.smartfield.utils';
import useStyles from './CKEditor.styles';

const CKEditorTemplate = ({
  currentSettings,
  hasMultipleVersions,
  initialData,
  lockData,
  propertyDetails,
  isReadOnly,
  updateDataFn,
  smartFieldInfo,
  useParsedHTMLString,
  refreshCKEditor,
  setInitialEditorData,
  setRefreshCKEditor
}) => {
  const [htmlStr, setHTMLStr] = useState(initialData);
  const [ckEditor, setCKEditor] = useState(null);
  const user = useSelector(s => s.user);
  const hasPermissionToEditQuote = checkPermission('edit', PermissionConstants.OBJECT_QUOTES, user);

  useEffect(() => {
    async function parseHTML() {
      const parsedStr = await parseHTMLForSmartFields({
        hasMultipleVersions,
        htmlStr: initialData,
        smartFieldInfo,
        settingsJSON: currentSettings,
        propertyDetails
      });
      setHTMLStr(parsedStr);
    }

    if (useParsedHTMLString) {
      parseHTML();
    } else {
      setHTMLStr(initialData);
    }
    if (ckEditor) {
      setInitialEditorData(ckEditor.getData());
    }
  }, [smartFieldInfo, initialData, ckEditor]);
  const classes = useStyles();

  useEffect(() => {
    if (refreshCKEditor.isTrue) {
      ckEditor.setData(refreshCKEditor.data);
      setRefreshCKEditor({ isTrue: false, data: null });
    }
  }, [ckEditor, refreshCKEditor, setRefreshCKEditor]);

  /* 
  TEMPORARY fix for issues arising from using CKEditor within a modal 
  and the dynamic generation of the .ck-body-wrapper element outside of the modal.
  Functionality of inputs on pop-ups was impaired.
  TODO: fix issue in our custom instance of the editor
  */
  const moveElements = () => {
    const ckBodyElement = document.getElementsByClassName('ck-body-wrapper');
    const ckEditorElement = document.getElementsByClassName(
      classes.documentEditorEditableContainer
    );
    if (ckBodyElement.length && ckEditorElement.length) {
      ckEditorElement[0].appendChild(ckBodyElement[0]);
    }
  };

  // If 'Lock Default Scope and Pricing Information' turned on in quote settings, then restricted editing set here
  const setRestrictedEditing = editor => {
    // Table cells made non-editable if they have contenteditable attribute set in CKEditor.utils.js
    extendAttributesToElement({
      editor,
      element: 'tableCell',
      attributes: ['contentEditable', 'class']
    });

    // Span (total smart field) made non-editable if it has contenteditable attribute set in CKEditor.utils.js
    addAttributesToElement({
      editor,
      element: 'span',
      options: {
        allowWhere: '$block',
        allowContentOf: '$root'
      }
    });
  };

  const handleOnReady = useCallback(
    editor => {
      setCKEditor(editor);
      moveElements();
      if (editor) {
        const toolbarContainer = document.querySelector('#toolbar-container');
        toolbarContainer.parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          toolbarContainer
        );
        if (lockData) {
          setRestrictedEditing(editor);
        }
        if (useParsedHTMLString) {
          extendAttributesToElement({
            editor,
            element: 'table',
            attributes: ['data-smartfield']
          });

          extendAttributesToElement({
            editor,
            element: 'tableCell',
            attributes: ['style', 'fontfamily']
          });

          addAttributesToElement({
            editor,
            element: 'meta',
            options: {
              inheritAllFrom: '$text'
            }
          });
        }
        editor.setData(htmlStr);
        editor.isReadOnly = isReadOnly;
      }
    },
    [htmlStr]
  );

  const insertSmartFieldCallback = (editor, data) => {
    editor.model.change(async writer => {
      if (!hasPermissionToEditQuote) return;
      if (useParsedHTMLString) {
        // Parse smart field after selection so its parsed value is displayed
        const parsedField = await parseHTMLForSmartFields({
          htmlStr: data,
          settingsJSON: currentSettings,
          hasMultipleVersions,
          smartFieldInfo,
          propertyDetails
        });
        const viewFragment = editor.data.processor.toView(`<span>${parsedField}</span>`);
        const modelFragment = editor.data.toModel(viewFragment);
        editor.model.insertContent(modelFragment);
      } else {
        // Displays unparsed smart fields (i.e. [[companyName]])
        const smartField = writer.createElement('span');
        writer.insertText(data, smartField);
        editor.model.insertContent(smartField, editor.model.document.selection);
      }
    });
  };

  return (
    <>
      <div className={classes.textEditor}>
        <div className={classes.documentEditor}>
          <div className={classes.documentEditorToolbar} id="toolbar-container" />
          <div className={classes.documentEditorEditableContainer}>
            <div className={classes.documentEditorEditable} />
            <CKEditor
              config={{
                fontFamily: {
                  supportAllValues: true
                },
                fontSize: {
                  options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48],
                  supportAllValues: true
                },
                pagination: {
                  pageWidth: '215.9mm',
                  pageHeight: 'calc(279.4mm + 13px)',
                  pageMargins: {
                    right: '19mm',
                    left: '19mm'
                  }
                },
                smartFields: {
                  cbFn: insertSmartFieldCallback,
                  smartFieldsDropdownList
                },
                licenseKey: configForEnvironment(ENV).ckeditorLicenseKey
              }}
              onReady={handleOnReady}
              onChange={debounce((_, editor) => {
                // Prevent deletion of text within restricted elements
                editor.editing.view.document.on(
                  'delete',
                  (evt, data) => {
                    if (data.domTarget.className.includes('restricted')) {
                      evt.stop();
                    }
                  },
                  { priority: 'highest' }
                );

                // Prevent changes within restricted elements
                editor.editing.view.document.on(
                  'keydown',
                  (evt, data) => {
                    if (data.domTarget.className.includes('restricted')) {
                      evt.stop();
                    }
                  },
                  { priority: 'highest' }
                );

                console.log('Change.', editor.getData());

                if (updateDataFn) {
                  updateDataFn(editor.getData());
                }
              }, 500)}
              onFocus={(_, editor) => {
                console.log('Focus.', editor.getData());
              }}
              editor={DecoupledEditor}
            />
          </div>
        </div>
      </div>
    </>
  );
};

CKEditorTemplate.propTypes = {
  /* Quote configurattion settings */
  currentSettings: PropTypes.object,
  hasMultipleVersions: PropTypes.bool,
  /* Initial data string sent to editor */
  initialData: PropTypes.string,
  /* Boolean to determine whether certain smartfields should be non-editable */
  lockData: PropTypes.bool,
  propertyDetails: PropTypes.object,
  isReadOnly: PropTypes.bool,
  /* Refreshed data and boolean sent to CKeditor if smartfields have been refreshed, triggering need for UI update without restarting editor instance */
  refreshCKEditor: PropTypes.object,
  setInitialEditorData: PropTypes.func,
  setRefreshCKEditor: PropTypes.func,
  smartFieldInfo: PropTypes.object,
  /* Callback fn to return updated data after edits made in the editor */
  updateDataFn: PropTypes.func,
  /* Boolean to determine whether smart fields should be parsed */
  useParsedHTMLString: PropTypes.bool
};

CKEditorTemplate.defaultProps = {
  currentSettings: {},
  hasMultipleVersions: false,
  initialData: '',
  lockData: false,
  propertyDetails: {},
  isReadOnly: false,
  refreshCKEditor: { isTrue: false, data: null },
  setInitialEditorData: () => {},
  setRefreshCKEditor: () => {},
  smartFieldInfo: {},
  updateDataFn: () => {},
  useParsedHTMLString: false
};

export default CKEditorTemplate;
