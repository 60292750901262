import { Fragment } from 'react';
import TextInput from '../MuiTextInput';
import PriceTagTextInput from '../PriceTagMuiTextInput';
import ReadOnly from '../ReadOnlyTextInput';
import Number from '../MuiInputNumber';
import Checkbox, { ReadOnlyCheckboxInput } from '../MuiCheckBox';
import Date from '../MuiDate';
import Hidden from '../Hidden';
import Label from '../Label';
import MuiButton from '../Button';
import HighlightAddress from '../HighlightAddress';
import DynamicList from '../DynamicList';
import MuiCheckBoxArray from '../MuiCheckBoxArray';
import InlineTable from '../InlineTable';
import ChipArray from '../ChipArray';
import CondensedNotes from '../CondensedNotes';
import CondensedNotesWithData from '../CondensedNotesWithData';
import TextArea from '../MuiTextArea';
import Attachment from '../Attachment';
import Dropdown from '../Dropdown';
import DropdownCreatable from '../DropdownCreatable';
import DropdownMultiSelect from '../DropdownMultiSelect';
import AttachmentSection from '../AttachmentSection';
import EmployeeLoginInvite from '../EmployeeLoginInvite';
import EmployeeLoginToggle from '../EmployeeLoginToggle';
import EmployeeActivationToggle from '../EmployeeActivationToggle';
import BuildOpsMakeAdmin from '../BuildOpsMakeAdmin';
import LabelWithoutValue from '../LabelWithoutValue';
import PlacesAutoComplete from '../PlacesAutoComplete';
import ImageArrayDisplay from '../ImageArrayDisplay';
import ProductSearch from '../ProductSearch';
import AlgoliaSearchField from '../AlgoliaSearchField';
import CostMarkup from '../CostMarkup';
import AutoCompleteAddress from '../AutoCompleteAddress';
import PercentageInput from '../PercentageInput';
import CurrencyInput from '../CurrencyInput';
import CurrencyDisplay from '../CurrencyDisplay';
import NumberDisplay from '../NumberDisplay';
import PercentageDisplay from '../PercentageDisplay';
import AddProductsWithSearchWrapper from '../AddProductsWithSearchWrapper';
import PreferredContact from '../PreferredContact';
import QuoteCustomerAndPropertyAndAddress from '../QuoteCustomerAndPropertyAndAddress';
import CityCountyStatePicker from '../CityCountyStatePicker';
import JobAuthorizedBy from '../JobAuthorizedBy';

/**
 * Map of field types in the layout meta and actual Material UI controls
 */
const controlsStrategy = {
  TextInput,
  PriceTagTextInput,
  ReadOnly,
  TextArea,
  Date,
  Label,
  LabelWithoutValue,
  Number,
  Checkbox,
  Hidden,
  ButtonAction: MuiButton,
  Dropdown,
  MultiDropdown: DropdownMultiSelect,
  DropdownCreatable,
  None: Fragment,
  HighlightAddress,
  DynamicList,
  CheckBoxList: MuiCheckBoxArray,
  InlineTable,
  Chips: ChipArray,
  CondensedNotes,
  CondensedNotesWithData,
  Attachment,
  AttachmentSection,
  EmployeeLoginInvite,
  EmployeeLoginToggle,
  EmployeeActivationToggle,
  BuildOpsMakeAdmin,
  PlacesAutoComplete,
  AlgoliaSearchField,
  AlgoliaSearch: ProductSearch,
  ProductSearch,
  CostMarkup,
  PercentageInput,
  CurrencyInput,
  CurrencyDisplay,
  NumberDisplay,
  PercentageDisplay,
  AutoCompleteAddress,
  ImageArrayDisplay,
  AddProductsWithSearch: AddProductsWithSearchWrapper,
  // to support buildhero-forms converted meta
  FieldWithLabel: Label,
  SelectInput: Dropdown,
  DateInput: Date,
  CheckboxInput: Checkbox,
  // specialBehaviour needs to have disabled attribute
  ReadOnlyCheckboxInput,
  PreferredContact,
  QuoteCustomerAndPropertyAndAddress,
  CityCountyStatePicker,
  JobAuthorizedBy // Can be deprecated when Job is moved out of PageForm
};

export default controlsStrategy;
