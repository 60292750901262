import React from 'react';
import { func } from 'prop-types';
import { Button, TV, Typography, TypographyWeight } from '@buildhero/sergeant';

import { useReviewReportDisabled } from '../../../../ReviewReport.contexts';

import { useStyles } from './DiscountsSectionHeader.styles';

const DiscountsSectionHeader = ({ onAddDiscounts }) => {
  const styles = useStyles();
  const { disabled } = useReviewReportDisabled();

  return (
    <div css={styles.header}>
      <Typography variant={TV.L} weight={TypographyWeight.BOLD} style={{ marginBottom: 8 }}>
        Discounts
      </Typography>
      <Button disabled={disabled} type="secondary" size="small" onClick={onAddDiscounts}>
        Add Discount
      </Button>
    </div>
  );
};

DiscountsSectionHeader.propTypes = {
  onAddDiscounts: func.isRequired
};

export default DiscountsSectionHeader;
