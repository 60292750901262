import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import JobNumber from '@dispatch/components/JobNumber';
import { JobTypes, VisitStatuses } from '@dispatch/Dispatch.constants';
import { JobPropTypes } from '@dispatch/Dispatch.props';
import { Job, Maintenance } from '@dispatch/icons';

import Routes from 'scenes/Routes';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { useStyles } from './JobLink.styles';

const JobLink = ({ job, visit }) => {
  const isMaintenance = job?.jobTypeInternal === JobTypes.MAINTENANCE.value.serverValue;
  const route = isMaintenance ? Routes.maintenance : Routes.job;
  const routeTo = route({ mode: 'view', id: encodeURIComponent(job?.jobNumber) });
  const styles = useStyles();
  const flags = useFlags();
  const completedStates = [
    VisitStatuses.ON_HOLD.key,
    VisitStatuses.CANCELED.key,
    VisitStatuses.COMPLETE.key,
    VisitStatuses.CLOSED.key,
    VisitStatuses.CONVERTED.key
  ];

  let visitRouteTo;

  if (completedStates.includes(visit?.status)) {
    const visitRoute = Routes.jobCloseoutVisit;
    visitRouteTo = visitRoute({
      jobid: encodeURIComponent(job?.id),
      id: encodeURIComponent(visit?.id)
    });
  }

  return (
    <>
      <Link to={routeTo}>
        <img alt="job-type" css={styles.icon} src={isMaintenance ? Maintenance : Job} />
        <JobNumber job={job} />
      </Link>
      <span>, </span>
      {flags[FeatureFlags.JOB_CLOSEOUT] &&
      job.closeoutReport === true &&
      completedStates.includes(visit?.status) ? (
        <Link to={visitRouteTo}>Visit {visit?.visitNumber}</Link>
      ) : (
        <span>Visit {visit?.visitNumber}</span>
      )}
    </>
  );
};

JobLink.propTypes = {
  job: JobPropTypes.isRequired,
  // eslint-disable-next-line react/require-default-props
  visit: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    visitNumber: PropTypes.number
  })
};

export default JobLink;
