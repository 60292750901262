import gql from 'graphql-tag';

const addPurchaseOrdersToVersionedQuote = gql`
  mutation addPurchaseOrdersToVersionedQuote(
    $partitionKey: String!
    $data: AddPurchaseOrdersToQuoteInput!
  ) {
    addPurchaseOrdersToVersionedQuote(partitionKey: $partitionKey, data: $data) {
      purchaseOrder(entityConnection: "PurchaseOrder") {
        id
        poNumber
        status
      }
      versionedQuote(entityConnection: "VersionedQuote") {
        versionNumber
      }
    }
  }
`;

export default addPurchaseOrdersToVersionedQuote;
