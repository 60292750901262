import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, SgtForm as Form, Modal, Button } from '@buildhero/sergeant';
import { useSelector } from 'react-redux';
import { AccountingApp } from 'utils/constants';
import CustomComponents from './components';
import getConfiguration from './CreateInvoice.config';
import { useCreateInvoice } from './CreateInvoice.gql';

const SgtForm = React.memo(Form);

export default function CreateInvoice({ open, handleClose, history }) {
  const [formService, setFormService] = useState();

  const isMultiplePriceBooksEnabled = useSelector(state => state.settings.multiplePricebooks);
  const departmentOptions = useSelector(state => state.options.departments);
  const priceBookOptions = useSelector(state => state.options.priceBooks);
  const defaultPriceBook = useSelector(state => state.company.defaultPriceBook);
  const tenantCompanyId = useSelector(state => state.user.tenantCompanyId);
  const isVistaIntegrated =
    useSelector(state => state.settings.accountingApp) === AccountingApp.VISTA;

  const [create, { loading }] = useCreateInvoice();

  const configuration = useMemo(
    () =>
      getConfiguration({
        departmentOptions,
        priceBookOptions,
        isMultiplePriceBooksEnabled,
        isVistaIntegrated,
        defaultPriceBook: { label: defaultPriceBook?.name, value: defaultPriceBook?.id }
      }),
    [departmentOptions, isMultiplePriceBooksEnabled, priceBookOptions, isVistaIntegrated]
  );

  const handleSave = useCallback(
    async data => {
      const id = (await create(data))?.data?.createInvoice?.id;
      if (id) {
        history.push(`/invoice/view/${id}`);
        handleClose();
      }
    },
    [handleClose, create, history]
  );

  return (
    <ThemeProvider>
      <Modal
        open={open}
        onClose={handleClose}
        title="New Invoice"
        actions={
          <Button
            testingid="save-invoice"
            loading={loading}
            onClick={formService?.formikContext?.handleSubmit}
          >
            Save Invoice
          </Button>
        }
        fullScreen
      >
        <SgtForm
          layout="default"
          configuration={configuration}
          initialValues={{
            tenantCompanyId,
            parentName: null,
            billTo: null,
            billingAddress: null,
            propertyAddress: null,
            priceBook: { label: defaultPriceBook?.name, value: defaultPriceBook?.id },
            department: null
          }}
          onCreateService={setFormService}
          onSubmit={handleSave}
          formikProps={{
            validateOnChange: false,
            validateOnBlur: true
          }}
          customComponents={CustomComponents}
        />
      </Modal>
    </ThemeProvider>
  );
}

CreateInvoice.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  history: PropTypes.any.isRequired
};
