import { ProjectFinanceEntity } from './ProjectFinanceTable.constants';
import { calcMargin, calcVariance, safeDivide } from './ProjectFinanceTable.utils';

const totalSum = (rows, valueGetter) => {
  return rows.reduce((acc, row) => valueGetter({ row }, acc), 0);
};

export const getEntityId = ({ row }) => {
  return row.entityType ? `${row.entityType}:${row.id}` : '';
};

//-----------------------------------
// Cost Budget
//-----------------------------------

export const getStartingBudget = ({ row }, base = 0) => {
  return row.entityType === ProjectFinanceEntity.ProjectPhaseDepartmentCostCode
    ? base + row.costBudget
    : base;
};

export const getStartingBudgetTotal = ({ rows }) => totalSum(rows, getStartingBudget);

export const getCommittedAmount = ({ row }, base = 0) => {
  switch (row.entityType) {
    case ProjectFinanceEntity.PurchaseOrderLine: {
      return base + row.actualCost;
    }
    case ProjectFinanceEntity.BillLine: {
      return base + (row.actualCost || 0) * -1;
    }
    default: {
      return base;
    }
  }
};

export const getCommittedAmountTotal = ({ rows }) => {
  return totalSum(rows, getCommittedAmount);
};

export const getBudgetChangeOrders = ({ row }, base = 0) => {
  return row.entityType === ProjectFinanceEntity.ChangeOrderLineItem ? base + row.costBudget : base;
};

export const getBudgetChangeOrdersTotal = ({ rows }) => totalSum(rows, getBudgetChangeOrders);

export const getUpdatedBudget = ({ row }) => {
  return getStartingBudget({ row }) + getBudgetChangeOrders({ row });
};

export const getUpdatedBudgetTotal = ({ rows }) => {
  return getStartingBudgetTotal({ rows }) + getBudgetChangeOrdersTotal({ rows });
};

export const getActualCosts = ({ row }, base = 0) => {
  return [ProjectFinanceEntity.BillLine, ProjectFinanceEntity.TimesheetEntry].includes(
    row.entityType
  )
    ? base + row.actualCost
    : base;
};

export const getActualCostsTotal = ({ rows }) => totalSum(rows, getActualCosts);

export const getPercentCompleteTotal = ({ rows }) => {
  return safeDivide(getActualCostsTotal({ rows }), getUpdatedBudgetTotal({ rows })) * 100;
};

export const getCostVarianceTotal = ({ rows }) => {
  return calcVariance(getStartingBudgetTotal({ rows }), getUpdatedBudgetTotal({ rows }));
};

//-----------------------------------
// Revenue Budget
//-----------------------------------

export const getOriginalContractAmount = ({ row }, base = 0) => {
  return row.entityType === ProjectFinanceEntity.ProjectPhaseDepartment
    ? base + row.revenueBudget
    : base;
};

export const getOriginalContractTotal = ({ rows }) => totalSum(rows, getOriginalContractAmount);

export const getChangeOrderAmount = ({ row }, base = 0) => {
  return row.entityType === ProjectFinanceEntity.ChangeOrderLineItem
    ? base + row.revenueBudget
    : base;
};

export const getChangeOrderTotal = ({ rows }) => totalSum(rows, getChangeOrderAmount);

export const getUpdatedContractAmount = ({ row }) => {
  return getOriginalContractAmount({ row }) + getChangeOrderAmount({ row });
};

export const getUpdatedContractTotal = ({ rows }) => {
  return getOriginalContractTotal({ rows }) + getChangeOrderTotal({ rows });
};

export const getRevenueCompleted = ({ row }, base = 0) => {
  return [ProjectFinanceEntity.BillLine, ProjectFinanceEntity.TimesheetEntry].includes(
    row.entityType
  )
    ? base + row.actualRevenue
    : base;
};

export const getRevenueCompletedTotal = ({ rows }) => totalSum(rows, getRevenueCompleted);

export const getOriginalEstimatedMarginTotal = ({ rows }) => {
  return calcMargin(getOriginalContractTotal({ rows }), getStartingBudgetTotal({ rows }));
};

export const getUpdatedEstimatedMarginTotal = ({ rows }) => {
  return calcMargin(getUpdatedContractTotal({ rows }), getUpdatedBudgetTotal({ rows }));
};

//-----------------------------------
// Labor Report
//-----------------------------------
export const getEstimatedHours = ({ row }, base = 0) => {
  return row.entityType === ProjectFinanceEntity.ProjectPhaseDepartmentCostCode
    ? base + row.hourBudget
    : base;
};

export const getEstimatedHoursTotal = ({ rows }) => totalSum(rows, getEstimatedHours);

export const getChangeOrderHours = ({ row }, base = 0) => {
  return row.entityType === ProjectFinanceEntity.ChangeOrderLineItem ? base + row.hourBudget : base;
};

export const getChangeOrderHoursTotal = ({ rows }) => totalSum(rows, getChangeOrderHours);

export const getTotalHours = ({ row }) => {
  return getEstimatedHours({ row }) + getChangeOrderHours({ row });
};

export const getTotalHoursTotal = ({ rows }) => {
  return getEstimatedHoursTotal({ rows }) + getChangeOrderHoursTotal({ rows });
};
export const getHoursUsed = ({ row }, base = 0) => {
  return [ProjectFinanceEntity.BillLine, ProjectFinanceEntity.TimesheetEntry].includes(
    row.entityType
  )
    ? base + row.actualHours
    : base;
};

export const getHoursUsedTotal = ({ rows }) => totalSum(rows, getHoursUsed);

export const getHoursPercentCompleteTotal = ({ rows }) => {
  return safeDivide(getHoursUsedTotal({ rows }), getTotalHoursTotal({ rows })) * 100;
};

export const getHourVarianceTotal = ({ rows }) => {
  return (
    getPercentCompleteTotal({ rows }) -
    safeDivide(getHoursUsedTotal({ rows }), getTotalHoursTotal({ rows })) * 100
  );
};
