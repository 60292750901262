const styles = theme => ({
  inputRoot: {
    color: 'inherit',
    '& .MuiInput-root': {
      padding: '1px 8px'
    }
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(),
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  },
  iconButton: {
    color: theme.palette.grayscale(25),
    fontSize: 20
  },
  results: {
    padding: 5,
    borderBottom: '1px solid rgba(189, 189, 189, 0.3)'
  },
  resultsText: {
    paddingLeft: '10px !important'
  },
  popper: ({ suggestionHeight }) => ({
    width: '100%',
    maxHeight: suggestionHeight || 240,
    overflowY: 'auto'
  })
});
export default styles;
