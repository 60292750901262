const rowStyle = {
  gap: 16,
  flexDirection: 'row',
  marginBottom: 16
};

const colStyle = {
  width: 180
};

export const RenewLayout = {
  fields: {},
  layouts: {
    default: {
      contents: [
        {
          props: { style: rowStyle },
          contents: [
            {
              component: 'Typography',
              props: {
                value: 'Create a new draft agreement based on the current agreement'
              }
            }
          ]
        },
        {
          props: { style: rowStyle },
          contents: [
            {
              component: 'Input',
              source: 'agreementName',
              props: {
                label: 'Agreement Name',
                lines: 1,
                required: true
              }
            }
          ]
        },
        {
          props: { style: rowStyle },
          contents: [
            {
              component: 'AgreementNumber',
              source: 'agreementNumber',
              props: {
                required: true,
                lines: 1,
                label: 'Agreement Number'
              }
            }
          ]
        },
        {
          props: { style: rowStyle },
          contents: [
            {
              component: 'DateInput',
              source: 'startDate',
              props: {
                required: true,
                lines: 1,
                label: 'Start Date'
              }
            }
          ]
        },
        {
          props: { style: rowStyle },
          contents: [
            {
              component: 'Checkbox',
              source: 'carryEndDate',
              props: {
                style: colStyle,
                label: 'Carry end date forward',
                horizontalLabel: true
              }
            }
          ]
        },
        {
          props: { style: rowStyle },
          contents: [
            {
              component: 'Typography',
              props: {
                value:
                  'The new agreement will have the same end date as this agreement.\n Select this if you are revising the current agreement.'
              }
            }
          ]
        }
      ]
    }
  }
};
