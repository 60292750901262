import React, { memo, useMemo } from 'react';
import { arrayOf, number, object } from 'prop-types';

import VisitCard from '@dispatch/components/VisitCard';
import NonVisitCard from '@dispatch/components/NonVisitCard';
import { TECH_PROP } from '@dispatch/queries';

import { CardTypes } from '../../../../../../DispatchBoard.constants';

import AvailableTimeCard from '../AvailableTimeCard';

import { useStyles } from './DayCell.styles';

const DayCell = ({ dayEvents, tech, laneIndex, dayIndex }) => {
  const styles = useStyles();
  const cell = useMemo(() => ({ laneIndex, dayIndex }), [laneIndex, dayIndex]);

  return (
    <div css={styles.root}>
      {dayEvents.map((event, i) => {
        switch (event.__typename) {
          case CardTypes.AvailableTime: {
            return (
              <AvailableTimeCard
                key={event?.range?.start?.unix() || i}
                availableTime={event}
                tech={tech}
                cell={cell}
                isLastEvent={event.isLastEvent}
              />
            );
          }
          case CardTypes.Visit: {
            return <VisitCard key={event.id} visit={event} srcTech={tech.id} />;
          }
          case CardTypes.ManDay:
          case CardTypes.NonVisitEvent: {
            return <NonVisitCard key={event.id} event={event} srcTech={tech.id} weekView />;
          }
          default: {
            return null;
          }
        }
      })}
    </div>
  );
};

DayCell.propTypes = {
  dayEvents: arrayOf(object).isRequired,
  tech: TECH_PROP.isRequired,
  laneIndex: number.isRequired,
  dayIndex: number.isRequired
};

export default memo(DayCell);
