import { column, ColumnType, valueGetters } from 'components/XGrid/columnTypes';
import { MultiSelectTypes } from 'utils/AppConstants';

const rbReceiptListTableMeta = [
  {
    field: 'receiptNumber',
    headerName: 'Receipt Number',
    width: 170,
    valueGetter: valueGetters.receiptLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'poNumber',
    headerName: 'PO Number',
    width: 140,
    dataField: 'parentId',
    valueGetter: valueGetters.purchaseOrderLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    enumType: MultiSelectTypes.PURCHASE_ORDER_RECEIPT_STATUS,
    ...column[ColumnType.ENUM]
  },
  {
    field: 'vendorName',
    headerName: 'Vendor',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'vendorDocumentNumber',
    headerName: 'Vendor Document Number',
    width: 235,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 150,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'departmentName',
    headerName: 'Department',
    width: 150,
    enumType: MultiSelectTypes.DEPARTMENTS,
    ...column[ColumnType.TAGS]
  },
  {
    field: 'jobOrProjectName',
    headerName: 'Job / Project',
    width: 150,
    valueGetter: valueGetters.jobOrMaintenanceLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'totalCost',
    headerName: 'Total',
    width: 150,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'createdDate',
    headerName: 'Created On',
    width: 150,
    ...column[ColumnType.DATE]
  }
];

export default rbReceiptListTableMeta;
