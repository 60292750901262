import { PermissionConstants } from 'utils/AppConstants';

export const AddPropertyLayout = {
  entity: {
    name: 'CustomerProperty',
    tenant: 'default',
    layouts: {
      web: {
        sections: [
          {
            title: 'Property info',
            icon: 'storeIcon',
            sm: '12',
            md: '12',
            lg: '12',
            rows: [
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbField',
                    value: 'companyName',
                    label: 'propertyName',
                    name: 'companyName',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '3'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'customerPropertyTypeValue',
                    label: 'propertyType',
                    name: 'customerPropertyTypeValue',
                    specialBehaviour: {
                      includeQueryResult: true,
                      queryPath: 'propertyValues',
                      fieldName: 'tagName'
                    },
                    behavior: {
                      new: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '6',
                        lg: '3'
                      },
                      edit: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '6',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'accountNumber',
                    name: 'accountNumber',
                    label: 'accountNumber',
                    showField: 'isExtendedFieldsEnabled',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'copyCustomerAddressButton',
                    name: 'copyCustomerAddressButton',
                    label: 'copyCustomerAddress',
                    specialBehaviour: {
                      includeQueryResult: true,
                      controlField: true,
                      mappings: {
                        propertyBillTo: 'customerBillTo',
                        propertyLatitude: 'customerLatitude',
                        propertyLongitude: 'customerLongitude',
                        propertyAddressLine1: 'customerBillingAddressLine1',
                        propertyAddressLine2: 'customerBillingAddressLine2',
                        propertyCity: 'customerBillingCity',
                        propertyState: 'customerBillingState',
                        propertyZipcode: 'customerBillingZipcode',
                        taxRateId: 'customerTaxRateId'
                      }
                    },
                    behavior: {
                      new: {
                        ui: 'ButtonAction',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  }
                ]
              },
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbField',
                    value: 'propertyAddressLine1',
                    label: 'propertyAddressLine1',
                    name: 'propertyAddressLine1',
                    specialBehaviour: {
                      addressLine1: 'propertyAddressLine1',
                      addressLine2: 'propertyAddressLine2',
                      city: 'propertyCity',
                      state: 'propertyState',
                      zipcode: 'propertyZipcode',
                      latitude: 'propertyLatitude',
                      longitude: 'propertyLongitude',
                      regionOptionsPath: 'taxRates',
                      regionField: 'taxRateId',
                      regionOptionField: 'id',
                      includeQueryResult: true,
                      isMandatory: true
                    },
                    behavior: {
                      new: {
                        ui: 'AutoCompleteAddress',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      },
                      edit: {
                        ui: 'AutoCompleteAddress',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      }
                    }
                  }
                ]
              },
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbField',
                    value: 'taxRateId',
                    label: 'taxRate',
                    name: 'taxRateId',
                    specialBehaviour: {
                      includeQueryResult: true,
                      queryPath: 'taxRates',
                      fieldName: 'label',
                      fieldValue: 'id'
                    },
                    behavior: {
                      new: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'isTaxable',
                    name: 'isTaxable',
                    label: 'taxable',
                    behavior: {
                      new: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '3',
                        lg: '2'
                      },
                      edit: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '3',
                        lg: '2'
                      }
                    }
                  }
                ]
              }
            ]
          },
          {
            title: '',
            sm: '12',
            md: '12',
            lg: '12',
            rows: [
              {
                spacing: '0',
                cols: [
                  {
                    type: 'dbField',
                    value: 'sameAddress',
                    label: 'propertyHasBillingAddressAsCustomer',
                    name: 'sameAddress',
                    specialBehaviour: {
                      controlField: true,
                      mappings: {
                        billingBillTo: 'customerBillTo',
                        billingAddressLine1: 'customerBillingAddressLine1',
                        billingAddressLine2: 'customerBillingAddressLine2',
                        billingCity: 'customerBillingCity',
                        billingState: 'customerBillingState',
                        billingZipcode: 'customerBillingZipcode',
                        billinglatitude: 'customerBillingLatitude',
                        billinglongitude: 'customerBillingLongitude',
                        billingCustomerId: 'parentId',
                        billingCustomerName: 'customerName'
                      }
                    },
                    behavior: {
                      new: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      },
                      edit: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'billingCustomerName',
                    label: 'billingCustomer',
                    name: 'billingCustomerName',
                    behavior: {
                      new: {
                        ui: 'AlgoliaSearchField',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      },
                      edit: {
                        ui: 'AlgoliaSearchField',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      }
                    },
                    specialBehaviour: {
                      isMandatory: true,
                      includeQueryResult: true,
                      displayText: ['customerName'],
                      valueField: 'customerName',
                      filterString: 'customerFilter',
                      showAddress: true, // turns the search into address search
                      mappings: {
                        billingCustomerId: 'parentId'
                      }
                    }
                  }
                ]
              },
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbField',
                    value: 'billingAddressLine1',
                    name: 'billingAddressLine1',
                    label: 'customerBillingAddressLine1',
                    specialBehaviour: {
                      dependentValue: 'customerBillingAddressLine1',
                      addressLine1: 'billingAddressLine1',
                      addressLine2: 'billingAddressLine2',
                      city: 'billingCity',
                      state: 'billingState',
                      zipcode: 'billingZipcode',
                      latitude: 'billingLatitude',
                      longitude: 'billingLongitude',
                      includeQueryResult: true,
                      disableAll: true
                    },
                    behavior: {
                      new: {
                        ui: 'AutoCompleteAddress',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      },
                      edit: {
                        ui: 'AutoCompleteAddress',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      }
                    }
                  }
                ]
              }
            ]
          }
        ],
        buttons: {
          save: {
            label: 'Save',
            color: 'primary',
            buttonType: 'contained',
            caslKey: [PermissionConstants.OBJECT_PROPERTY],
            behavior: {
              new: true,
              edit: true
            }
          },
          cancel: {
            label: 'Cancel',
            color: 'secondary',
            buttonType: 'contained',
            caslKey: PermissionConstants.OBJECT_PROPERTY,
            behavior: {
              edit: true
            }
          }
        }
      }
    }
  }
};

export const customerRepRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'contactRole',
    numeric: false,
    disablePadding: true,
    label: 'Role'
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: 'Email'
  },
  {
    id: 'landlinePhone',
    numeric: false,
    disablePadding: true,
    label: 'Landline'
  },
  {
    id: 'cellPhone',
    numeric: false,
    disablePadding: true,
    label: 'Mobile phone'
  },
  {
    id: 'isSmsOptOut',
    numeric: false,
    type: 'boolean',
    disablePadding: true,
    label: 'Prefer SMS'
  },
  /* {
     Temporarily disabled, see https://buildops.atlassian.net/browse/BUOP-9307
    id: 'emailInvoiceLabel',
    numeric: false,
    disablePadding: true,
    label: 'Email Invoice Default' 
  }, */
  {
    id: 'bestContact',
    numeric: false,
    disablePadding: true,
    label: 'Best contact'
  },
  {
    id: 'notesCount',
    type: 'note',
    other: 'repNotes',
    numeric: false,
    disablePadding: true,
    label: 'Notes'
  }
];

export const tenantRepRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'contactRole',
    numeric: false,
    disablePadding: true,
    label: 'Title'
  },
  {
    id: 'notesCount',
    type: 'note',
    other: 'repNotes',
    numeric: false,
    disablePadding: true,
    label: 'Notes'
  }
];

export const propertyAssetRows = [
  {
    id: 'imageUrl',
    numeric: false,
    disablePadding: false,
    label: '',
    type: 'thumbnail'
  },
  {
    id: 'assetName',
    numeric: false,
    disablePadding: true,
    label: 'Asset name',
    showLink: 'view',
    linkPath: '/asset/view',
    linkReference: 'id'
  },
  {
    id: 'make',
    numeric: false,
    disablePadding: true,
    label: 'Make'
  },
  {
    id: 'modelNumber',
    numeric: false,
    disablePadding: true,
    label: 'Model No'
  },
  {
    id: 'serialNo',
    numeric: false,
    disablePadding: true,
    label: 'Serial No'
  },
  {
    id: 'installDate',
    numeric: false,
    disablePadding: true,
    label: 'Install date'
  },
  {
    id: 'notesCount',
    type: 'note',
    other: 'assetNotes',
    numeric: false,
    disablePadding: true,
    label: 'Notes'
  },
  {
    id: 'editAndDeleteButton',
    numeric: false,
    type: 'editAndDeleteButton',
    isCustom: true,
    label: ''
  },
  {
    id: 'subTableRows',
    type: 'subTableRows',
    numeric: false,
    disablePadding: false,
    title: 'View History',
    metaRows: [
      {
        id: 'scheduledFor',
        type: 'dateOnly',
        numeric: false,
        disablePadding: false,
        label: 'VisitDate'
      },
      {
        id: 'job',
        numeric: false,
        disablePadding: true,
        label: 'VisitJob',
        type: 'jobLink'
      },
      {
        id: 'visit',
        numeric: false,
        disablePadding: true,
        type: '',
        label: 'Visit'
      },
      {
        id: 'primaryTech',
        numeric: false,
        disablePadding: true,
        type: 'name',
        label: 'Technician'
      },
      {
        id: 'description',
        numeric: false,
        disablePadding: true,
        label: 'WorkDescription',
        type: 'description',
        ellipsis: true
      }
    ]
  }
];

export const propertyAttachmentRows = [
  {
    id: 'fileName',
    numeric: false,
    disablePadding: true,
    label: 'File name',
    type: 'attachment',
    other: 'fileUrl'
  },
  {
    id: 'createdDateTime',
    numeric: false,
    type: 'dateOnly',
    disablePadding: true,
    label: 'Date added'
  },
  {
    id: 'createdBy',
    numeric: false,
    disablePadding: true,
    label: 'Added by'
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: true,
    label: 'Description'
  }
];

export const eventRows = [
  {
    id: 'eventType',
    numeric: false,
    disablePadding: true,
    label: 'Event type'
  },
  {
    id: 'jobNumber',
    numeric: false,
    disablePadding: true,
    label: 'Job number',
    type: 'jobLink'
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status'
  },
  {
    id: 'date',
    numeric: false,
    type: 'dateOnly',
    disablePadding: true,
    label: 'Date'
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: true,
    label: 'Description'
  },
  {
    id: 'assignedTo',
    numeric: false,
    disablePadding: true,
    label: 'Assigned to'
  }
];

export const jobRowMetaByProp = [
  {
    id: 'jobNumber',
    filterKey: 'Job.jobNumber',
    filterType: 'number',
    numeric: false,
    label: 'Job #',
    type: 'jobLink'
  },
  {
    id: 'noOfVisits',
    disableFilter: true,
    disableSort: true,
    filterKey: 'Job.departmentNames',
    filterType: 'string',
    numeric: false,
    label: 'Visits',
    other: 'visits'
  },
  {
    id: 'outstandingBalance',
    disableFilter: true,
    disableSort: true,
    numeric: false,
    type: 'currency',
    label: 'Outstanding Balance',
    caslKey: [PermissionConstants.DATA_VIEW_PRICE_DATA, PermissionConstants.DATA_VIEW_COST_DATA],
    caslAction: 'allow'
  },
  {
    id: 'overdueBalance',
    disableSort: true,
    disableFilter: true,
    numeric: false,
    type: 'currency',
    label: 'Overdue Balance',
    caslKey: [PermissionConstants.DATA_VIEW_PRICE_DATA, PermissionConstants.DATA_VIEW_COST_DATA],
    caslAction: 'allow'
  },
  {
    id: 'jobTypeName',
    filterKey: 'Job.jobTypeName',
    filterType: 'string',
    numeric: false,
    label: 'Job type'
  },
  {
    id: 'jobTags',
    filterKey: 'JobTag.tagName',
    filterType: 'string',
    type: 'chip',
    disableSort: true,
    label: 'Tags'
  },
  {
    id: 'customerName',
    filterKey: 'Job.customerName',
    filterType: 'string',
    numeric: false,
    label: 'Customer',
    showLink: 'view',
    linkPath: '/customer/view',
    linkReference: 'customerId',
    linkStateKey: 'recordSortKey',
    linkStateValue: 'customerSortKey'
  },
  {
    id: 'customerPropertyName',
    filterKey: 'Job.customerPropertyName',
    filterType: 'string',
    numeric: false,
    label: 'Property',
    showLink: 'view',
    linkPath: '/property/view',
    linkReference: 'customerPropertyId',
    linkStateKey: 'recordSortKey',
    linkStateValue: 'customerPropertySortKey'
  },
  {
    id: 'createdDate',
    filterKey: 'Job.createdDate',
    filterType: 'date',
    numeric: false,
    type: 'dateOnly',
    label: 'Created on'
  },
  {
    id: 'amountQuoted',
    filterKey: 'Job.amountQuoted',
    filterType: 'number',
    numeric: false,
    label: 'salesAmount',
    type: 'currency',
    caslKey: [
      PermissionConstants.DATA_VIEW_PRICE_DATA,
      PermissionConstants.DATA_VIEW_COST_DATA,
      PermissionConstants.DATA_SHOW_FINANCIAL_DATA
    ],
    caslAction: 'allow'
  },
  {
    id: 'age',
    disableFilter: true,
    disableSort: true,
    numeric: false,
    label: 'Age(Days)'
  },
  {
    id: 'status',
    filterKey: 'Job.status',
    filterType: 'string',
    type: 'text',
    numeric: false,
    label: 'Status'
  },
  {
    id: 'ownerValue',
    filterKey: 'Employee.name',
    filterType: 'string',
    type: 'text',
    numeric: false,
    label: 'Project Manager'
  },
  {
    id: 'issueDescription',
    disableFilter: true,
    disableSort: true,
    label: 'Issue Description',
    maxTextLen: 50,
    type: 'bigtext',
    prewrap: true
  }
];
