const styles = theme => ({
  pageHeader: {
    marginBottom: theme.spacing(4),
    marginLeft: -theme.spacing(3),
    marginRight: -theme.spacing(6),
    height: 2
  },

  sectionHeader: {
    height: 2,
    backgroundColor: theme.palette.secondary.main
  },

  sectionDivider: {
    height: 2
  }
});

export default styles;
