const styles = theme => ({
  searchBox: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#F0F0F0'
    }
  },
  searchResults: {
    '& .MuiListItemText-root': {
      paddingLeft: 10
    }
  },
  chip: {
    marginTop: theme.spacing(0.7),
    backgroundColor: theme.palette.grayscale(90),
    color: theme.palette.grayscale(20),
    height: 24,
    letterSpacing: 0.24,
    borderRadius: 2,
    justifyContent: 'space-between',

    '& .MuiChip-deleteIcon': {
      heigth: 16,
      width: 16
    }
  }
});

export default styles;
