import gql from 'graphql-tag';

const getProductBundles = gql`
  query getProductBundles(
    $partitionKey: String!
    $sortKey: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      productBundles: productBundlesView(
        entityConnection: "ProductBundle"
        filter: $filter
        pagination: { limit: $limit, offset: $offset }
        sorting: $sort
      ) {
        nextToken
        items {
          id
          name
          description
          category
          subCategory
          type
          isActive
          createdDate
          version
          productBundleEntries: productBundleEntriesView(entityConnection: "ProductBundleEntry") {
            items {
              id
              version
              isActive
              name
              description
              unitCost
              quantity
              taxable
            }
          }
        }
      }
    }
  }
`;

export default getProductBundles;
