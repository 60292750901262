/* eslint-disable no-restricted-syntax */
import { Ability } from '@casl/ability';
import { unpackRules } from '@casl/ability/extra';
import { round as _round, includes, isEmpty, isString } from 'lodash';
import moment from 'moment-timezone';
import * as R from 'ramda';

import Context from 'components/Context';
import { getCloudinaryImageUrl } from 'scenes/ProjectManagement/components/utils';
import { Logger, sentryMessage } from 'services/Logger';

import StorageService from 'services/StorageService';

import { getTimesheetStatusPriorities } from './AppConstants';
import {
  BestContactType,
  momentShortHandMapping,
  ServiceAgreementScheduleTypes
} from './constants';

export const reorder = ({ arr, source, destination }) => {
  if (source < 0 || destination < 0) return arr;
  if (source >= arr.length || destination >= arr.length) return arr;
  return arr.map((_, index) => {
    if (index === destination) return arr[source];

    let newIndex = index;
    if (index > destination) newIndex -= 1;
    if (index > source || (index === source && source < destination)) newIndex += 1;

    return arr[newIndex];
  });
};

export const isObject = value => value !== null && typeof value === 'object';

// Remove object keys with null values from object. Also remove __typename key if it exists.
// (__typename key is added by React Apollo query component)
export const removeNullValues = values => {
  const finalValue = {};
  Object.keys(values).forEach(key => {
    if (values[key] !== null && key !== '__typename') {
      finalValue[key] = values[key];
    }
  });
  return finalValue;
};

// convert empty string values from the json values passed
export const removeEmptyValues = values => {
  const finalValue = {};
  Object.keys(values).forEach(key => {
    if (values[key] && values[key] !== '' && !Array.isArray(values[key])) {
      finalValue[key] = values[key];
    }
    if (Array.isArray(values[key]) && values[key].length > 0) {
      finalValue[key] = values[key];
    }
    // return finalValue;
  });
  return finalValue;
};

export const getNumberValue = value => {
  if (value) {
    return parseInt(value, 10);
  }
  return null;
};

export const getCombinedAddress = address => {
  const addressFields = ['addressLine1', 'addressLine2', 'city', 'state', 'zipcode'];
  return (
    address &&
    addressFields
      .filter(addressField => !!address[addressField])
      .map(populatedAddressField =>
        (
          (Array.isArray(address[populatedAddressField])
            ? address[populatedAddressField][0]
            : address[populatedAddressField]) || ''
        ).trim()
      )
      .join(', ')
      .trim()
  );
};

// address JSON is converted into combined address with address type as key
// "shippingAddress" : "line1, line2, city, state, zipcode"
export const processAddressArrayAsJson = addresses => {
  const addressesJson = {};
  addresses.forEach(address => {
    addressesJson[address.addressType] = getCombinedAddress(address);
  });
  return addressesJson;
};

const addressTypeToPrefix = {
  billingAddress: 'billing',
  shippingAddress: 'shipping',
  contactAddress: 'contact',
  homeAddress: 'home',
  propertyAddress: 'property',
  businessAddress: 'business',
  companyAddress: 'company'
};

// maps addresses json to individual address fields. To be used in forms
export const mapAddressJsonToFields = addresses => {
  if (!addresses) {
    return addresses;
  }
  const localAddress = {};
  addresses.forEach(address => {
    const prefix = addressTypeToPrefix[address.addressType];
    Object.keys(address).forEach(key => {
      if (key !== 'addressType' && key !== '__typename' && address[key]) {
        const keyText = prefix + (key.charAt(0).toUpperCase() + key.slice(1));
        localAddress[keyText] = address[key];
      }
    });
  });
  return localAddress;
};

export const convertSchemaTagsToString = values => {
  if (!values) {
    return '';
  }
  let result = '';
  values.forEach(item => {
    result += item.tagName;
  });
  return result;
};

export const removeNestedJson = jsonObject => {
  if (!jsonObject) {
    return jsonObject;
  }
  const filteredJson = {};
  Object.keys(jsonObject).map(key => {
    if (typeof jsonObject[key] !== 'object') {
      filteredJson[key] = jsonObject[key];
    }
    return '';
  });
  return filteredJson;
};

// Fires asynchronous callbacks for every item in the array simultaneously.
// NOTE: Catches errors thrown by callback and translates them into individual Promise rejections.
// Therefore, wrapping `asyncForEach` in a try/catch will not have the intended effect. Examine the returned result instead.
export async function asyncForEach(array, callback, continueAfterError = true) {
  if (!Array.isArray(array))
    return Promise.reject(new TypeError(`Expected array in \`asyncForEach\` but found ${array}`));
  const promiseAggregationType = continueAfterError
    ? Promise.allSettled.bind(Promise)
    : Promise.all.bind(Promise);
  return promiseAggregationType(
    array.map(
      entry =>
        new Promise((resolve, reject) => {
          try {
            const result = callback(entry);
            resolve(result);
          } catch (e) {
            reject(e);
          }
        })
    )
  );
}

export const getDatafromPath = (data, queryPath) => {
  const path = queryPath && queryPath.split('.');
  let displayDataArray = null;
  path.forEach(element => {
    if (displayDataArray === null) displayDataArray = data[element];
    else displayDataArray = displayDataArray[element];
  });
  return displayDataArray;
};

export const capitalizeFirstLetter = inputString =>
  inputString.charAt(0).toUpperCase() + inputString.slice(1);

export const unCapitalizeFirstLetter = inputString => {
  if (inputString && typeof inputString === 'string') {
    return inputString.charAt(0).toLowerCase() + inputString.slice(1);
  }
  return '';
};

export const getImageUrl = async fileName => {
  try {
    const storageService = new StorageService();
    const url = await storageService.getFile(fileName);

    return url;
  } catch (error) {
    // avoid sending to Sentry as not all images will have thumbnails
    Logger.info(`Error getting image ${error}`);
  }
  return null;
};

export const getThumbnailImageUrl = async fileName => {
  try {
    const storageService = new StorageService();
    const s3ImageUrl = await storageService.getFile(fileName, true);
    return s3ImageUrl;
  } catch (error) {
    Logger.info(`Error uploading image ${error}`);
  }
  return null;
};

export function getTenantSettingValueForKey(key) {
  const { listTenantSettings } = Context.getCompanyContext() || {};
  const tenantSetting = listTenantSettings?.find(setting => setting.settingKey === key);
  return (tenantSetting && tenantSetting.settingValue) || '';
}

export const checkPermission = (
  mode,
  action,
  user,
  scope,
  featureGate,
  featureFlag,
  launchDarklyFlags = {}
) => {
  // attributes changed towards the end, hence chaning values within the method
  let I = mode;

  // Overriding as casl supports 'create', 'update' 'read' and 'delete'. Initially backend supported alternate keys but during refactor its removed there
  // TODO: sticking to default supported, but involves MUI forms modes to support create etc
  if (I === 'new' || I === 'add') {
    I = 'create';
  }

  if (I === 'edit') {
    I = 'update';
  }

  if (I === 'view') {
    I = 'read';
  }
  I = user.cognitoRole === 'SystemAdmin' ? 'manage' : I;
  let localAction = action;
  let featureEnabled = true;
  let permission = false;

  try {
    // depregated from 2021, all new feature flagging tenant setting should go to launch darkly
    if (featureGate) {
      featureEnabled = getTenantSettingValueForKey(featureGate) === 'true';
    }

    if (featureFlag) {
      featureEnabled = launchDarklyFlags[featureFlag];
    }

    if (featureEnabled) {
      const ability = new Ability(unpackRules(JSON.parse(user.appPermissionRules || '[]')));
      if (scope && typeof action === 'object') {
        // if system or tenant admin, check against tenant ids
        if (user.cognitoRole === 'SystemAdmin') {
          localAction.tenantId = 'all';
        } else if (user.cognitoRole === 'TenantAdmin') {
          localAction.tenantId = user.tenantId;
        } else {
          // for non admin user, validation only against the permission
          localAction = localAction.caslKey;
        }
      }
      if (I && localAction && Array.isArray(localAction)) {
        permission = localAction.some(el => ability.can(I, el));
      } else if (localAction) {
        permission = I && localAction && ability.can(I, localAction);
      } else {
        permission = true;
      }
    }
  } catch (err) {
    Logger.debug(user.appPermissionRules);
    Logger.error(err);
  }
  return permission;
};

export const sortArrayWithCaseInsensitive = (attributeName, array) => {
  const sortExpressions = R.sortBy(R.compose(R.toLower, R.prop(attributeName)));

  return sortExpressions(array);
};

// in memory opertation for updating the list. Uses id field to update
export const upsertList = (items, newItem) => {
  const localItems = items;
  // when array is empty return a array with the item.
  if (!localItems || localItems.length === 0) {
    return [{ ...newItem }];
  }

  let isUpdate = false;
  localItems.forEach((item, index) => {
    if (item.id === newItem.id) {
      isUpdate = true;
      localItems[index] = newItem;
    }
  });

  // if unmatched add the newitem to the list and return
  if (!isUpdate) {
    localItems.unshift(newItem);
  }

  return localItems;
};

// Method for formatting a number as currency
export const formatCurrency = currencyStr => {
  let defaultValue = null;
  if (currencyStr) {
    defaultValue = currencyStr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return defaultValue;
};

export const formatDisplayCurrency = number => {
  return `$${
    number ? number.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0
  }`;
};

export function checkSettingEnabled(tenantSetting, key) {
  const { settings } = tenantSetting;
  if (!settings) return false;
  const settingObj = JSON.parse(settings);
  return settingObj?.[key] || false;
}

export const formatAddress = (data, hideBillTo) => {
  if (!data || !data.addressLine1) return '';
  const formattedAddress = `${data.billTo && !hideBillTo ? `${data.billTo}\n` : ''}${
    data.addressLine1 ? `${data.addressLine1}` : ''
  }${data.addressLine2 ? `, ${data.addressLine2}\n` : '\n'}${data.city ? `${data.city}, ` : ''}${
    data.state ? `${data.state} ` : ''
  }${data.zipcode ? `${data.zipcode}` : ''}`;
  return formattedAddress;
};

export const formatAddressOneLine = data => {
  const spaceApart = (s1, s2) => [s1, s2].filter(Boolean).join(' ');
  return [
    spaceApart(data.addressLine1, data.addressLine2),
    data.city,
    spaceApart(data.state, data.zipcode)
  ]
    .filter(Boolean)
    .join(', ');
};

export const convertStringToFloat = value => {
  if (value && typeof value === 'string') {
    const localValue = value.replace(',', '');
    return parseFloat(localValue);
  }
  return value;
};

/* Truncates a string (which could take on a null value) to the maximum specified
 * length, not including the three characters taken up by the postfix elipsis.
 */
export const truncateString = (value = '', maxLength) => {
  if (typeof value === 'string' && value.length > maxLength) {
    return `${value.slice(0, maxLength)}...`;
  }
  return value;
};

// Expects standard Javascript Map object
export const toggleElementInMap = (map = new Map(), key, value) => {
  if (map.has(key)) {
    map.delete(key);
  } else {
    map.set(key, value);
  }
};

export const getUniqueName = (tenantId, name) => `${tenantId}/${Date.now()}-${name}`;

export const logErrorWithCallback = (error, callback, defaultMsg) => {
  Logger.error(error);
  if (error?.graphQLErrors && error.graphQLErrors.length > 0) {
    callback('error', error.graphQLErrors[0].message, error);
  } else if (error) {
    callback('error', defaultMsg);
  }
};

export const convertNumberToFixed = (number, decimal) =>
  number && number !== 0 ? parseFloat(number.toFixed(decimal)) : number;

export const convertCurrencyStringToFloat = currencyStr =>
  (currencyStr && parseFloat(currencyStr.replace(/[^\d.]/g, ''))) || 0;

export const getTransparentBackgroundCss = () => {
  const dummy = document.createElement('div');
  dummy.style.position = 'absolute';
  dummy.style.top = '0px';
  dummy.style.left = '0px';
  dummy.style.visibility = 'hidden';
  document.body.appendChild(dummy);
  const transparentBackground = window.getComputedStyle(dummy).backgroundColor;
  document.body.removeChild(dummy);
  return transparentBackground;
};

/*
 * Get all of an element's parent elements up the DOM tree
 * Taken from https://vanillajstoolkit.com/helpers/getparents/
 * @param  {Node}   elem     The element
 * @param  {String} selector Selector to match against [optional]
 * @return {Array}           The parent elements
 */
export const getParents = (elem, selector) => {
  const parents = [];
  let currElem = elem;
  while (currElem && currElem !== document) {
    if (selector) {
      if (currElem.matches(selector)) {
        parents.push(currElem);
      }
    } else {
      parents.push(currElem);
    }
    currElem = currElem.parentNode;
  }
  return parents;
};

export const rgbaToHexCode = (rgbaString = 'rgb(0, 0, 0)') => {
  const dec256Channels = rgbaString
    .split?.('(')[1]
    ?.split?.(')')[0]
    ?.split?.(',');
  if (!dec256Channels) return null;
  const hexChannels = dec256Channels.map(channel => {
    const hex = parseInt(channel, 10).toString(16);
    // Prepend zero if a channel is only one char
    return hex.length === 1 ? `0${hex}` : hex;
  });
  return `#${hexChannels.join('')}`;
};

// Utility functions for flattening and unflattening
// data on the sales tax rates page
const addresses = {
  order: ['addressLine1', 'addressLine2', 'city', 'county', 'state', 'zipcode'],
  separator: ', '
};
// Convert { city: 'Gainesville', county: undefined, state: 'Florida' } to 'Gainesville, Florida'
export const addressObjectToString = addressObject => {
  if (!addressObject) return '';
  let result = '';
  for (const component of addresses.order) {
    if (addressObject?.[component]) {
      result = result + addressObject[component] + addresses.separator;
    }
  }
  return result.slice(0, result.length - addresses.separator.length);
};

export const getBooleanValue = value => {
  let localValue;
  if (value && typeof value === 'string') {
    localValue = value === 'true';
  } else {
    localValue = Boolean(value);
  }
  return localValue;
};

export const setTitleForPageForm = (mode, layout) => {
  const sections = layout[0];
  if (mode === 'edit') {
    sections.title = sections.title.replace('Add', 'Edit');
  } else {
    sections.title = sections.title.replace('Edit', 'Add');
  }
  return layout;
};

// custom rounding method b/c negative numbers should round up too
// i.e. Math.round(-1.5) = -1 when it should be -2.
export const round = (value, precision) =>
  Math.sign(value) * _round(Math.abs(value) + 0.0000000000001, precision);

// found this on stack overflow
export function getNumberOfDigits(a) {
  let e = 1;
  let p = 0;
  while (Math.round(a * e) / e !== a) {
    e *= 10;
    p += 1;
  }
  return p;
}

export const recursiveRound = (value, precision) => {
  if ((value || value === 0) && precision) {
    const digits = getNumberOfDigits(value);
    if (digits > precision + 1) {
      return round(recursiveRound(value, precision + 1), precision);
    }
    return round(value, precision);
  }
};

export const poRound = (value, precision) => {
  if ((value || value === 0) && precision) {
    return round(value, precision);
  }
};
// return 1 if bigger, -1 if smaller, 0 if equal
// hundreds place precision
export const compareCurrencyFloats = (f1, f2) => {
  const c1 = round(f1, 2);
  const c2 = round(f2, 2);
  if (c1 > c2) return 1;
  if (c1 < c2) return -1;
  return 0;
};

export const parseFloatAndRound = (f, precision) => {
  const parsed = parseFloat(f);
  return !Number.isNaN(parsed) ? round(parsed, precision) : null;
};

// used for currency displays where we only want 2 decimal places
export const roundCurrency = f => parseFloatAndRound(f, 2);

// used for currency float calculations where we only want 5 decimal places
export const roundFloat = f => parseFloatAndRound(f, 5);

export const convertToCurrencyString = (value, symbol = '$', precision = 2) => {
  const rounded = parseFloatAndRound(value, precision);
  return `${symbol}${rounded?.toLocaleString('en-US', {
    maximumFractionDigits: precision,
    minimumFractionDigits: 2
  })}`;
};

export const findAddressByAddressType = (addressArray, addressType) =>
  addressArray?.find(address => address.addressType === addressType);

export const isTenantSettingEnabled = tenantSetting => {
  const { listTenantSettings } = Context.getCompanyContext() || {};
  return listTenantSettings?.some(
    setting => setting.settingKey === tenantSetting && setting.settingValue === 'true'
  );
};

// Rounds to the given precision, padding with 0's
export const roundTo = (num, precision = 2) => {
  const base = 10 ** precision;
  return (Math.round(num * base) / base).toFixed(precision);
};

export const camelCaseToTitleCase = value => {
  if (typeof value !== 'string') return value;
  const delimiter = ' ';
  const tokenizedCamelCase = value
    .trim()
    .replace(/([A-Z])/g, `${delimiter}$1`)
    .split(delimiter);
  return tokenizedCamelCase
    .map(word => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
    .join(delimiter);
};

/**
 * Helper to transform a string to title case with spacing.
 *
 * "yourStringHere" -> "Your String Here"
 * "AnotherStringHere" -> "Another String Here"
 * "someones_string" -> "Someones String"
 * "Another-String-Here" -> "Another String Here"
 * "myAWESOMEString" -> "My AWESOME String"
 * @param {string} value String to transform to title case
 */
export const toTitleCase = value =>
  value && isString(value)
    ? value
        .replace(/(_|-)/g, ' ')
        .trim()
        .replace(/\w\S*/g, str => str.charAt(0).toUpperCase() + str.substr(1))
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    : value;

// When a many-to-many connection exists in our Dynamo/AppSync implementation, the actual entity
// is nested within a `mappedEntity` field. The parent object has a forward join (`sortKey`) and a
// reverse join (`invertedSortKey`) between the two entities. These two keys can used to delete
// a many-to-many connection (both directions/keys must be deleted).
export const getManyToManyConnections = mappedEntity => {
  if (!mappedEntity?.sortKey || !mappedEntity?.invertedSortKey) return {};
  return {
    manyToManyForward: mappedEntity.sortKey,
    manyToManyReverse: mappedEntity.invertedSortKey
  };
};

export const isAmplifyBaseEntityField = fieldName => {
  if (typeof fieldName !== 'string') return false;
  const baseEntityFields = [
    'parentId',
    'parentSortKey',
    'hierarchy',
    'id',
    'entityType',
    'version',
    'tenantId',
    'tenantCompanyId',
    'partitionKey',
    'sortKey',
    'createdBy',
    'createdDate',
    'createdDateTime',
    'deletedBy',
    'deletedDate',
    'deletedDateTime',
    'lastUpdatedBy',
    'lastUpdatedDate',
    'lastUpdatedDateTime',
    '_lastChangedAt'
  ];

  // Catch lsi1, lsi2, ..., gsi1, gsi2, ...
  const baseEntityFieldPrefixes = ['lsi', 'gsi'];
  return (
    baseEntityFields.includes(fieldName) ||
    baseEntityFieldPrefixes.some(prefix => fieldName.startsWith(prefix))
  );
};

export const backendDateToMoment = (date, companyTimezone) => {
  const MAX_UNIX_TIMESTAMP = 2 ** 31 - 1;
  let momentObj;
  if (Number.isNaN(date)) {
    // Server-side date object is encoded in a non-Unix format, e.g.
    // "MM-DD-YYYY"; let moment.js handle parsing
    momentObj = moment(date);
  } else {
    // TODO: Both of the below corrections do not fix the underlying backend bugs.
    // These are still bugs because they cause sorting to be done wrong.

    // If `date > MAX_UNIX_TIMESTAMP`, we know that the server-side
    // Unix timestamp is encoded with millisecond precision and
    // has been multiplied by 1000 relative to a normal Unix timestamp.
    let unixTimestamp = date > MAX_UNIX_TIMESTAMP ? date / 1000 : date;
    // TODO: For unknown reasons, certain dates stored in the backend
    // are erroneously already divided by a factor of 1000. Heurestically,
    // we can correct most of these cases: if `timestamp * 1000` is still a valid date,
    // the original timestamp is probably wrong.
    if (Math.abs(unixTimestamp) * 1000 <= MAX_UNIX_TIMESTAMP) unixTimestamp *= 1000;

    momentObj = moment.unix(unixTimestamp);
  }
  if (companyTimezone) {
    return moment.tz(momentObj, companyTimezone);
  }
  return momentObj;
};

export const flattenObject = (obj, prefix = '') =>
  Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? `${prefix}.` : '';
    if (typeof obj[k] === 'object') Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
  }, {});

export const arrayInsert = (array, insertIndex, newItem) => [
  ...array.slice(0, insertIndex),
  newItem,
  ...array.slice(insertIndex)
];

export const getTechniciansFromVisit = (primaryTechs, extraTechs) => {
  let technicians = [];
  if (primaryTechs?.items) {
    technicians = primaryTechs.items.map(
      x => `${x.mappedEntity.firstName} ${x.mappedEntity.lastName}`
    );
  }
  if (extraTechs?.items) {
    technicians = technicians.concat(
      extraTechs.items.map(x => `${x.mappedEntity.firstName} ${x.mappedEntity.lastName}`)
    );
  }
  return technicians.length ? technicians.join(', ') : null;
};

// Below are supported cloudinary. Some are little differrent than the usual. Like pdf
export const isCloudinaryVideoType = fileType => {
  const videoTypes = [
    '3g2',
    '3gp',
    'avi',
    'flv',
    'm3u8',
    'ts',
    'm2ts',
    'mts',
    'mov',
    'mkv',
    'mp4',
    'mpeg',
    'mpd',
    'mxf',
    'ogv',
    'webm',
    'wmv'
  ];
  return videoTypes.includes(fileType?.toLowerCase());
};

export const isCloudinaryImageType = fileType => {
  const imageTypes = [
    'ai',
    'gif',
    'webp',
    'avif',
    'bmp',
    'djvu',
    'ps',
    'ept',
    'eps',
    'eps3',
    'fbx',
    'flif',
    'gif',
    'glb',
    'gltf',
    'heif',
    'heic',
    'ico',
    'indd',
    'jpg',
    'jpe',
    'jpeg',
    'jp2 4',
    'wdp',
    'jxr',
    'hdp',
    'pdf',
    'png',
    'psd',
    'arw',
    'cr2',
    'svg',
    'tga',
    'tif',
    'tiff',
    'webp'
  ];
  return imageTypes.includes(fileType?.toLowerCase());
};

export const getFileExtension = filename => filename?.split('.')?.pop();

export const getGuardedFileName = (fileName, defaultThumbnailExtension = 'jpg') => {
  const extension = getFileExtension(fileName);
  if (typeof extension !== 'string' || !extension) {
    console.error('Invalid File Extension', { fileName });
    return;
  }
  const unSupportedExtensions = ['pdf', 'heic'];
  const isSupported = !includes(unSupportedExtensions, extension?.toLowerCase());
  const correctedFileName = isSupported
    ? fileName
    : fileName?.replace(extension, defaultThumbnailExtension);
  // eslint-disable-next-line consistent-return
  return correctedFileName;
};

// convert image files into supported version for pdf/renderer
export const getFileNameForPdf = (fileName, defaultThumbnailExtension = 'jpg') => {
  const extension = getFileExtension(fileName);
  if (typeof extension !== 'string' || !extension) {
    console.error('Invalid File Extension', { fileName });
    return;
  }
  const supportedExtensions = ['jpg', 'png'];
  const isSupported = includes(supportedExtensions, extension?.toLowerCase());
  const correctedFileName = isSupported
    ? fileName
    : fileName?.replace(extension, defaultThumbnailExtension);
  // eslint-disable-next-line consistent-return
  return correctedFileName;
};

export const findLastUpdatedByAndDate = (items, lastUpdatedMap, usernameProperty) => {
  if (!items) return null;
  items.forEach(item => {
    if (lastUpdatedMap && item[usernameProperty] && item.lastUpdatedDateTime) {
      if (lastUpdatedMap.has(item[usernameProperty])) {
        if (item.lastUpdatedDateTime > lastUpdatedMap.get(item[usernameProperty])) {
          lastUpdatedMap.set(item[usernameProperty], item.lastUpdatedDateTime);
        }
      } else {
        lastUpdatedMap.set(item[usernameProperty], item.lastUpdatedDateTime);
      }
    }
  });
  return lastUpdatedMap;
};

export const isJSONParsable = string => {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }
  return true;
};

export const isJSONParseableObjectOrArray = item => {
  if (typeof item !== 'string') return false;

  if (!isJSONParsable(item)) return false;

  const newItem = JSON.parse(item);
  if (typeof newItem === 'object' && newItem !== null) return true;

  return false;
};

/**
 * @function pickStatusByPriority
 * * picks the status with the highest priority
 *
 * @param {Array[String]} statusValues array of status strings
 * @param {Map} statusPriority map of priorities if each possible status
 *
 * @returns {string} first status by default
 */
export const pickStatusByPriority = (
  statusValues = [],
  statusPriority = getTimesheetStatusPriorities()
) => {
  if (!Array.isArray(statusValues) || !statusValues?.length) return;
  if (!statusValues.every(status => statusPriority.has(status))) return;
  return statusValues.reduce((previousStatus, currentStatus) => {
    if (statusPriority.get(previousStatus) <= statusPriority.get(currentStatus)) {
      return currentStatus;
    }
    return previousStatus;
  }, statusValues[0]);
};

export const computeUnitPrice = (unitCost = 0, markupValue = 0, markupType = 'Percentage') => {
  if (markupType === 'Percentage') {
    return unitCost + (unitCost * markupValue) / 100;
  }
  return unitCost + markupValue;
};

export const computeMarkup = (unitCost, unitPrice) => {
  let unitCostFloat;
  let unitPriceFloat;

  if (typeof unitCost === 'string') {
    unitCostFloat = parseFloat(unitCost);
  } else {
    unitCostFloat = unitCost;
  }

  if (typeof unitPrice === 'string') {
    unitPriceFloat = parseFloat(unitPrice);
  } else {
    unitPriceFloat = unitPrice;
  }
  const markup = (((unitPriceFloat - unitCostFloat) / unitCostFloat) * 100).toFixed(2);

  return { markupValue: markup, markupText: `${markup}`, markupType: 'Percentage' };
};

/**
 * @description Get number groups from a string
 * eg: parseNumbersFromString(123456,12345) => [123456, 12345]
 * @param {string} input
 * @returns {array[string]}
 */
export const parseNumbersFromString = input => {
  if (typeof input !== 'string' || !input) return;
  const NumRegex = new RegExp(/([\d]+)/g);
  return input.match(NumRegex);
};

export const getBestContact = (customerRep = {}) => {
  if (customerRep?.bestContact === BestContactType.EMAIL) {
    return customerRep?.email;
  }
  if (customerRep?.bestContact === BestContactType.LANDLINE) {
    return customerRep?.landlinePhone;
  }
  // defaulting to cellphone if no preffered way of contact is chosen
  return customerRep?.cellPhone || null;
};

/**
 * Gets the Address string and location object from an object
 * If an Array is passed , the address object is found from the provided addressType.
 * @param {Object or Array} source
 * @param {String} addressType
 */
export const getAddressAndLocation = (source, addressType) => {
  let addressObject = { ...source };
  if (Array.isArray(source)) {
    if (!addressType) return { address: '', location: {} };
    const addressCandidates = source.filter(address => address?.addressType === addressType);
    const addressCandidatesWithLine1 = source.filter(
      address => address?.addressType === addressType && address?.addressLine1
    );
    if (addressCandidates.length === 0) {
      // no address of this type
      return { address: '', location: {} };
    }

    if (addressCandidatesWithLine1.length !== addressCandidates.length) {
      sentryMessage('Business Address(es) Missing Line 1', { addressCandidates });
    }

    if (addressCandidatesWithLine1.length === 0) {
      // no good address of this type
      addressObject = { ...addressCandidates[0] };
    } else {
      addressObject = { ...addressCandidatesWithLine1[0] };
    }
  }
  if (isEmpty(addressObject)) return { address: '', location: {} };
  const location = {
    latitude: addressObject?.latitude,
    longitude: addressObject?.longitude
  };
  const address = addressObjectToString(addressObject);
  return { address, location };
};

/**
 * Get the next scheduled date
 *
 * @param {Number} dateUnix unix time stamp
 * @param {String} schedule {Daily, Monthly, Biannually, Annually}
 *
 */
export const getNextDateBySchedule = (dateUnix, schedule) => {
  if (!dateUnix) return;
  if (!schedule) return dateUnix;

  const momentKey = momentShortHandMapping[schedule];
  if (!momentKey) return dateUnix;
  const todayUnix = moment().unix();
  if (dateUnix > todayUnix) return dateUnix;

  let newDateUnix = dateUnix;
  let addBy = 1;
  while (newDateUnix < todayUnix) {
    addBy = schedule === ServiceAgreementScheduleTypes.BIANNUALLY ? 2 : 1;
    newDateUnix = moment
      .unix(newDateUnix)
      .add(addBy, momentKey)
      .unix();
  }
  return newDateUnix;
};

/**
 * * refresh the Context
 * @param {object} user
 */
export const refreshContext = user => {
  const handleContextRefresh = () => Logger.debug('Context is refreshed');
  Context.setCompanyContext(
    user.tenantId,
    Context.generateCompanyContextSortKey(user),
    handleContextRefresh,
    true
  );
};

/**
 * @description Get customer signature src
 * @param {object} signature
 * @returns {string} base64 or cloudinary url
 */
export const getCustomerSignatureSrc = signature => {
  const { signatureImageUrl: url } = signature || {};

  if (!url || typeof url !== 'string' || url.includes(';base64,')) return url;

  return getCloudinaryImageUrl(url);
};
