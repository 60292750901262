const sidebarData = [
  {
    source: 'sendTo',
    label: 'Send To'
  },
  {
    source: 'returnTo',
    label: 'Return To'
  },
  {
    source: 'period',
    label: 'Period'
  },
  {
    source: 'distributeTo',
    label: 'Distribute To'
  }
];

export default sidebarData;
