/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';
import { roundFloat, roundCurrency } from 'utils';

const ItemSearchBar = ({ options, field, form }) => {
  const [itemSelected, setItemSelected] = useState({});
  const [itemDisplayed, setItemDisplayed] = useState(false);

  if (field.value && !itemDisplayed) {
    form.values.unitCost = Number(roundFloat(itemSelected?.unitCost)) || form.values?.unitCost || 0;
    form.values.description = itemSelected?.description || form.values?.description || '';
    form.values.costCode = itemSelected?.costCode || form.values?.costCode || null;
    form.values.revenueType = itemSelected?.revenueType || form.values?.revenueType || null;
    form.values.jobCostType = itemSelected?.jobCostType || form.values?.jobCostType || null;
    setItemDisplayed(true);
  }
  form.values.totalCost = roundCurrency(
    parseFloat(itemSelected?.unitCost || form.values?.unitCost || 0) *
      parseFloat(form.values?.quantity || 0) || 0
  );

  return (
    <SearchBar
      options={options}
      field={field}
      form={form}
      onSelectionChange={value => {
        setItemSelected(value);
        setItemDisplayed(false);
      }}
    />
  );
};

export default ItemSearchBar;
