import React from 'react';
import { Mode } from 'utils/constants';
import List from '../MaintenanceTemplate/PartsAndMaterials/List';
import { SergeantModal } from 'components';

export const ViewPartsAndMaterials = ({ data, isVisible, onModalClose, showAddButton }) => {
  return (
    <SergeantModal
      mode={Mode.VIEW}
      open={isVisible}
      data={{ items: data }}
      layout={layout(showAddButton)}
      handleClose={onModalClose}
      customComponents={{ List }}
      dataType="Parts And Materials"
      customPrimaryButtonLabel={'Close'}
    />
  );
};

const layout = showAddButton => ({
  fields: {
    partsAndMaterials: {
      default: 'partsAndMaterials'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          options: {
            direction: 'column'
          },
          contents: [
            {
              component: {
                default: 'List'
              },
              options: {
                showAddButton
              }
            }
          ]
        }
      ]
    }
  }
});
