export const serviceAgreementSetting = (isTotalPercentageError, disableEdit) => ({
  fields: {},
  layouts: {
    default: {
      options: {
        grow: 0
      },
      contents: [
        {
          component: 'RecurringMaintenanceBilling',
          source: 'recurringMaintenanceBillingItems',
          options: {
            isTotalPercentageError,
            disableEdit
          }
        }
      ]
    }
  }
});

export default serviceAgreementSetting;
