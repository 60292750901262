import { combineReducers } from 'redux';

import { LOGOUT } from '../constants';
import initialState from '../state';
import {
  alert,
  application,
  company,
  companySettings,
  components,
  contextNotification,
  entities,
  launchDarklyFlags,
  menu,
  options,
  pageNotification,
  settings,
  user,
  visitUpdateNotification
} from './applicationReducers';

import { dispatch } from './dispatchReducers';

import { pm, openQuickAddModal } from './projectManagmentReducers';
import { jobs } from '../../scenes/Jobs/jobReducers';

const appReducer = combineReducers({
  alert,
  application,
  company,
  companySettings,
  components,
  contextNotification,
  dispatch,
  entities,
  launchDarklyFlags,
  menu,
  openQuickAddModal,
  options,
  pageNotification,
  pm,
  settings,
  user,
  visitUpdateNotification,
  jobs
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    return initialState;
  }

  return appReducer(state, action);
};

export default rootReducer;
