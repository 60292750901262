import gql from 'graphql-tag';

export const GET_INVOICE_PRESETS = gql`
  query getInvoicePresets($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      userSettings(
        entityConnection: "UserSetting"
        filter: { type: { eq: "INVOICE_PRESET_SETTING" } }
      ) {
        nextToken
        items {
          type
          displayName
          settings
          access
          id
        }
      }
    }
  }
`;

export const SAVE_INVOICE_PRESET_SETTING = gql`
  mutation SaveInvoicePresetSetting($data: SavePresetSettingInput!) {
    saveInvoicePresetSetting(data: $data) {
      id
      displayName
      settings
      type
      name
    }
  }
`;
