export const dayTableMeta = [
  {
    id: 'day',
    label: 'day',
    dayKey: 'day'
  },
  {
    id: 'startTime',
    label: 'Start Time',
    dayKey: 'startTime'
  },
  {
    id: 'endTime',
    label: 'End time',
    dayKey: 'endTime'
  },
  {
    id: 'employees',
    label: 'assigned Technician(s) (Optional)',
    dayKey: 'employees'
  }
];

export const timeFields = ['startTime', 'endTime'];

export const afterHourTimeFormat = 'hh:mm A';

export const dayIndexLabelMap = {
  0: 'SUN',
  1: 'MON',
  2: 'TUE',
  3: 'WED',
  4: 'THU',
  5: 'FRI',
  6: 'SAT'
};

export const INVALID_DATE = 'Invalid date';

export const weekDayFullName = {
  SUN: 'Sunday',
  MON: 'Monday',
  TUE: 'Tuesday',
  WED: 'Wednesday',
  THU: 'Thursday',
  FRI: 'Friday',
  SAT: 'Saturday'
};

export const DEFAULT_ERROR_MSG = 'Cannot save schedule';
