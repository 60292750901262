import React, { useState } from 'react';

import { makeStyles, Tab, Tabs, Tooltip } from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import AddButtonText from 'components/Buttons/AddButtonText';
import { Mode } from 'utils/constants';

import HourInput from '../../HourInput';

import AuditLogEntry from './AuditLogEntry';
import TimesheetBillingEntityDetailsView from './TimesheetBillingEntityDetailsView';
import TimesheetNoteEntry from './TimesheetNoteEntry';
import VisitTimeHistoryEntry from './VisitTimeHistoryEntry';

const useStyles = makeStyles(theme => ({
  payrollHourTypes: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  tabs: {
    boxSizing: 'border-box',
    minHeight: 'auto',
    backgroundColor: 'transparent',
    width: '100%'
  },
  indicator: {
    backgroundColor: theme.palette.grayscale(30)
  },
  tab: {
    minHeight: 'auto',
    textTransform: 'none',
    minWidth: 'fit-content',
    backgroundColor: 'transparent',
    color: theme.palette.grayscale(30)
  },
  break: {
    flexBasis: '100%',
    height: 0
  },
  workEventHours: {
    display: 'flex',
    width: '100%'
  },
  miniTabs: {
    width: '100%',
    margin: '24px 0'
  },
  addNoteButton: {
    marginTop: 24,
    marginLeft: 24
  }
}));

const WorkEvent = ({
  workEvent,
  payrollHourTypes,
  updateTimesheetEntry,
  isEditable,
  isIncomplete,
  newAuditLogs,
  payrollSetting,
  setNotesModalData,
  tenantId,
  employeeId
}) => {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);

  let renderDetailRow;
  let detailItems;
  let noDataMessage;

  switch (tabIndex) {
    case 0:
      detailItems = workEvent.timesheetNotes;
      renderDetailRow = note => (
        <TimesheetNoteEntry
          note={note}
          payrollSetting={payrollSetting}
          setNotesModalData={setNotesModalData}
          workEvent={workEvent}
          tenantId={tenantId}
          employeeId={employeeId}
          isEditable={isEditable}
        />
      );
      noDataMessage = 'No notes found';

      break;
    case 1:
      detailItems = workEvent.schedules;
      renderDetailRow = schedule => (
        <VisitTimeHistoryEntry schedule={schedule} payrollSetting={payrollSetting} />
      );
      noDataMessage = 'No timestamps found';
      break;
    case 2:
      detailItems = newAuditLogs
        .filter(l => l.entry.workEvent.id === workEvent.id)
        .concat(workEvent.auditLogs);
      renderDetailRow = (auditLog, index, previousLog) => (
        <AuditLogEntry
          previousLog={previousLog}
          key={auditLog.id}
          auditLog={auditLog}
          timesheetEntry={auditLog.entry}
          hourType={auditLog.hourType}
          payrollSetting={payrollSetting}
        />
      );
      noDataMessage = 'No activity logs found';
      break;
    default:
      detailItems = [];
      renderDetailRow = () => {};
      noDataMessage = 'No notes found';
  }

  return (
    <div className={classes.payrollHourTypes}>
      <div className={classes.workEventHours}>
        {payrollHourTypes?.map((t, i) => {
          const { hourTypeAbbreviation } = t;
          const showInput = Object.keys(workEvent.timesheetHours).includes(hourTypeAbbreviation);
          return (
            showInput && (
              <HourInput
                key={`${workEvent.identifier.id}_${hourTypeAbbreviation}`}
                workEvent={workEvent}
                hourTypeAbbreviation={hourTypeAbbreviation}
                updateTimesheetEntry={updateTimesheetEntry}
                isEditable={isEditable}
                isIncomplete={isIncomplete}
                hourType={t}
                style={{ marginRight: 24 }}
              />
            )
          );
        })}
      </div>
      <div className={classes.break} />

      <div className={classes.miniTabs}>
        <Tabs
          value={tabIndex}
          onChange={(event, index) => setTabIndex(index)}
          classes={{
            root: classes.tabs,
            indicator: classes.indicator
          }}
        >
          <Tab label="Notes" classes={{ root: classes.tab }} />

          <Tab
            classes={{ root: classes.tab }}
            icon={
              <span style={{ display: 'flex' }}>
                <Tooltip
                  title={
                    workEvent?.schedules?.find(item => item.clockInTimezone)
                      ? "Time stamps displayed in technician's time zone."
                      : "Time stamps displayed in office's time zone."
                  }
                  arrow
                  placement="top"
                >
                  <InfoOutlined
                    style={{
                      marginRight: 4,
                      color: 'black',
                      fontSize: 16,
                      marginTop: 4
                    }}
                  />
                </Tooltip>
                Visit Time History
              </span>
            }
          />

          <Tab label="Timesheet Activity Logs" classes={{ root: classes.tab }} />
        </Tabs>
        <div className={classes.break} />
        {isEditable && tabIndex === 0 && (
          <div className={classes.addNoteButton}>
            <AddButtonText
              label="Add Timesheet Note"
              onClick={() => {
                const data = {
                  mode: Mode.ADD,
                  parent: {
                    id: workEvent.id,
                    entityType: workEvent.entityType,
                    tenantId,
                    binderId: workEvent?.binderId
                  },
                  data: {
                    employeeId
                  }
                };
                setNotesModalData(data);
              }}
            />
          </div>
        )}
        <TimesheetBillingEntityDetailsView
          items={detailItems}
          renderItem={renderDetailRow}
          noDataMessage={noDataMessage}
        />
      </div>
    </div>
  );
};

export default WorkEvent;
