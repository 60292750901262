import { convertToCurrencyStringCheck } from '../../CKEditor.utils';

// Handle conditional display of itemized pricing for labor/materials
function itemizedPricing(item, shouldDisplayPricing, styles, fontFamilyStyle) {
  return shouldDisplayPricing
    ? `<td style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;text-align:right;" class="restricted">
          <span fontfamily=${fontFamilyStyle} class="text-small" contenteditable="false">${convertToCurrencyStringCheck(
        item.unitPrice
      )}</span>
        </td>
        <td style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;text-align:right;" class="restricted">
          <span fontfamily=${fontFamilyStyle} class="text-small" contenteditable="false">${convertToCurrencyStringCheck(
        item.unitPrice * item.quantity
      )}</span>
        </td>`
    : `<td style="border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;text-align:right;"><span class="text-small"></span></td>
        <td style="border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;text-align:right;"><span class="text-small"></span></td>`;
}

export default itemizedPricing;
