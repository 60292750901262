import React, { useState } from 'react';
import { Tabs, Tab, ThemeProvider } from '@material-ui/core';
import theme from 'themes/BuildHeroTheme';
import TenantSettingList from './TenantSettingList';
import Users from './Users';

const TabPanel = React.memo(props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      css={{ flex: '1 1 auto' }}
      {...other}
    >
      {value === index && children}
    </div>
  );
});

const TenantTabs = props => {
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        css={{ marginBottom: 16 }}
      >
        <Tab label="Settings" />
        <Tab label="Users" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <TenantSettingList {...props} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ThemeProvider theme={theme}>
          <Users auth={props.props.auth} tenantId={props.form.values.id} />
        </ThemeProvider>
      </TabPanel>
    </>
  );
};

export default React.memo(TenantTabs);
