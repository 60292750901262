const styles = theme => ({
  checkboxLabel: {
    marginLeft: 0,
    marginBottom: theme.spacing(3.25)
  },
  checkboxStyle: {
    padding: 0,
    paddingLeft: 10
    // marginBottom: theme.spacing(2)
  }
});

export default styles;
