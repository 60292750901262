import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { flatten, noop } from 'lodash';
import { jsx } from '@emotion/react';
import { useTrigger } from 'customHooks/useTrigger';
import { useQuery, useMutation } from '@apollo/client';
import ImageThumbnail from 'components/ImageThumbnail';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  ThemeProvider,
  MultiSelect,
  Button,
  Divider,
  Typography,
  TV,
  TW
} from '@buildhero/sergeant';

import { Mode } from 'utils/constants';
import { getTenantSettingValueForKey } from 'utils';

import AssetModal from 'scenes/Customer/PropertyDetail/Assets/AssetModal';
import { assetPayload } from 'scenes/Customer/PropertyDetail/helpers';
import { addPropertyAssetsToCustomerProperty } from 'scenes/Customer/Assets/gql';

import GET_PROPERTY_BY_ID from './gql';

import TasksBase from './components/TasksBase';
import BulkAddTaskCard from './components/BulkAddTaskCard';
import NoAssets from './components/NoAssets';

const formatMakeModelSerialNumber = o => {
  const make = o.make || '';
  const modelNumber = o.modelNumber || '';
  const serialNo = o.serialNo || '';
  return serialNo ? `${make} ${modelNumber} - ${serialNo}` : `${make} ${modelNumber}`;
};

export const formatAssetOption = o => ({
  id: o.id,
  label: o.assetName,
  rightLabel: formatMakeModelSerialNumber(o),
  value: o,
  group: o.assetType?.tagName
});

const style = {
  assetName: {
    marginRight: 8,
    marginLeft: 8
  },
  assetType: {},
  title: {
    paddingBottom: 8,
    display: 'flex',
    justifyContent: 'space-between'
  },
  subtitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 12
  },
  titleBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  leftTitle: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  rightTitle: {
    display: 'inline-flex'
  },
  topLine: {
    marginTop: 16
  },
  image: {
    cursor: 'pointer'
  },
  moreHeaderButtons: {
    marginLeft: 5,
    display: 'inline-flex'
  }
};

export const Title = ({ options }) => (
  <ThemeProvider>
    <Typography variant={TV.L} weight={TW.BOLD}>
      {options.label}
    </Typography>
  </ThemeProvider>
);

export const Subtitle = ({ options }) => (
  <ThemeProvider>
    <Typography variant="subTitle">{options.label}</Typography>
  </ThemeProvider>
);

const Tasks = ({
  propertyId,
  assetTasks,
  tasks,
  onSubmit = noop,
  setSubmitService = noop,
  showCheckmarks = false,
  setTouched = () => {},
  headerButtons = []
}) => {
  const [createAssetModalOpen, setCreateAssetModalOpen] = useState(false);
  const [newlyCreatedAssets, setNewlyCreatedAssets] = useState([]);
  const [showBulkAddTaskComponent, setShowBulkAddTaskComponent] = useState(false);
  const [rerender, triggerRerender] = useTrigger();

  const isAssetEnabled = getTenantSettingValueForKey('assetTrackingAgainstTask') === 'true';

  const { data: property, loading, error, refetch } = useQuery(GET_PROPERTY_BY_ID, {
    variables: {
      id: propertyId
    },
    fetchPolicy: 'cache-and-network'
  });

  const [addAsset, { loading: addAssetLoading }] = useMutation(addPropertyAssetsToCustomerProperty);

  const assetTasksRef = useRef(assetTasks);
  const setAssetTasks = newAssetTasks => {
    assetTasksRef.current = newAssetTasks;
    setTouched(true);
    triggerRerender();
  };
  const tasksRef = useRef(tasks);
  const setTasks = newTasks => {
    tasksRef.current = newTasks;
    setTouched(true);
    triggerRerender();
  };

  const handleCreateAsset = async (newData, stopLoading) => {
    const payload = assetPayload(newData);

    const { data: newAssetData } = await addAsset({
      variables: {
        data: {
          customerPropertyId: propertyId,
          propertyAssets: [{ ...payload }]
        }
      }
    });
    const asset = newAssetData.addPropertyAssetsToCustomerProperty[0];

    const newAssetTasks = { ...assetTasksRef.current, [asset.id]: [] };
    setNewlyCreatedAssets([...newlyCreatedAssets, asset]);
    setAssetTasks(newAssetTasks);

    stopLoading();
    setCreateAssetModalOpen(false);
  };

  const assetList = useMemo(
    () => [
      ...(property?.getCustomerPropertyById?.propertyAssets?.items || []).filter(p => p.isActive),
      ...newlyCreatedAssets
    ],
    [property, newlyCreatedAssets]
  );

  const assetMap = useMemo(
    () => assetList.reduce((acc, asset) => ({ ...acc, [asset.id]: { ...asset } }), {}),
    [assetList.length]
  );

  const company = useSelector(state => state.company);
  const forms = useMemo(
    () =>
      company?.forms?.items.filter(
        f => f.latestPublishedFormDefinitionSortKey && f.associatedEntityType === 'Task'
      ) || [],
    []
  );

  const formOptions = useMemo(() => {
    if (!property) return [];

    const options = [];

    forms.forEach(form => {
      const associatedAssets = assetList.filter(a =>
        (a?.forms ?? []).find(assetForm => assetForm.id === form.id)
      );

      const value = {
        name: form.name,
        required: false,
        asset: null,
        formDefinitionId: form.latestPublishedFormDefinition.id,
        formSortKey: form.sortKey,
        formDefinitionSortKey: form.latestPublishedFormDefinitionSortKey
      };

      if (associatedAssets.length === 0) {
        options.push({
          id: form.id,
          label: form.name,
          value
        });
      } else {
        associatedAssets.forEach(a => {
          options.push({
            id: form.id,
            label: form.name,
            value: { ...value, asset: a.id }
          });
        });
      }
    });
    return options;
    // form options only need to be calculated once after property load
    // 1) newly created assets cannot have associated forms
    // 2) interface to create forms is not in this view
  }, [property]);

  useEffect(() => {
    if (property) {
      setSubmitService({
        submit: () =>
          onSubmit({ assetTasks: assetTasksRef.current, tasks: tasksRef.current, assetMap })
      });
    }
  }, [property, newlyCreatedAssets]);

  const isLoading = !property || loading;

  return (
    <>
      {isAssetEnabled && (
        <>
          <ThemeProvider>
            <div css={style.title}>
              <Title options={{ label: 'Tasks' }} />
            </div>
            <div css={style.subtitle}>
              <Subtitle
                options={{
                  label:
                    'Select which assets will be worked on and what tasks will be performed for each asset.'
                }}
              />
              <div>
                <Button
                  type="secondary"
                  onClick={() => setShowBulkAddTaskComponent(true)}
                  disabled={
                    showBulkAddTaskComponent || Object.keys(assetTasksRef.current).length === 0
                  }
                >
                  Bulk Add Tasks
                </Button>
                {headerButtons.map(B => (
                  <div css={style.moreHeaderButtons}>
                    <B />
                  </div>
                ))}
              </div>
            </div>
            {isLoading ? (
              <Skeleton
                count={
                  flatten(Object.keys(assetTasks).map(assetId => assetTasks[assetId])).length || 1
                }
                height={32}
              />
            ) : (
              <MultiSelect
                label="Assets To Be Worked On"
                grouped
                lineItemComponent={(option, selectedOptions) => {
                  const asset = option.value;

                  const isFirstLine = option.id === selectedOptions[0].id;

                  return (
                    <div key={asset.id}>
                      {showBulkAddTaskComponent && isFirstLine && (
                        <Grid container css={style.topLine}>
                          <div>
                            <Typography variant={TV.L} weight={TW.BOLD}>
                              Bulk Add Tasks
                            </Typography>
                          </div>
                          <BulkAddTaskCard
                            assetOptions={selectedOptions}
                            assetMap={assetMap}
                            onCreateTasks={newAssetTasks => {
                              setAssetTasks(
                                Object.keys(assetTasksRef.current).reduce(
                                  (acc, assetId) => ({
                                    ...acc,
                                    [assetId]: [
                                      ...assetTasksRef.current[assetId],
                                      ...(newAssetTasks[assetId] || [])
                                    ]
                                  }),
                                  {}
                                )
                              );
                              setShowBulkAddTaskComponent(false);
                            }}
                            defaultPriceBookId={company.defaultPriceBookId}
                            formOptions={formOptions}
                            onCancel={() => setShowBulkAddTaskComponent(false)}
                          />
                        </Grid>
                      )}
                      <Grid container css={isFirstLine ? style.topLine : {}}>
                        <div css={style.titleBlock}>
                          <div css={style.leftTitle}>
                            <div css={style.image}>
                              <ImageThumbnail image={{ fileUrl: asset.imageUrl }} />
                            </div>
                            <Link to={`/asset/view/${asset.id}`} target="_blank">
                              <Typography weight={TW.BOLD} css={style.assetName}>
                                {asset.assetName}
                              </Typography>
                            </Link>
                            <Typography weight={TW.MEDIUM} css={style.assetType}>
                              {asset.assetType?.tagName}
                            </Typography>
                          </div>
                          <div css={style.rightTitle}>
                            <Typography weight={TW.MEDIUM}>
                              {formatMakeModelSerialNumber(asset)}
                            </Typography>
                          </div>
                        </div>

                        <TasksBase
                          asset={asset}
                          forms={formOptions}
                          tasks={assetTasksRef.current[asset.id] ?? []}
                          onTasksChange={newTasks => {
                            setAssetTasks({ ...assetTasksRef.current, [asset.id]: newTasks });
                          }}
                          showCheckmarks={showCheckmarks}
                        />
                      </Grid>
                    </div>
                  );
                }}
                onChange={assets => {
                  const newAssetTaskMap = assets.reduce((acc, asset) => {
                    if (assetTasksRef.current[asset.id]) {
                      // asset was already selected
                      return { ...acc, [asset.id]: assetTasksRef.current[asset.id] };
                    }
                    return { ...acc, [asset.id]: [] };
                  }, {});

                  setAssetTasks(newAssetTaskMap);
                }}
                onClickCreateOption={() => setCreateAssetModalOpen(true)}
                createOptionLabel="Create New Asset"
                options={assetList.map(formatAssetOption)}
                selectedOptions={Object.keys(assetTasksRef.current)
                  .filter(assetId => !!assetMap[assetId])
                  .map(assetId => formatAssetOption(assetMap[assetId]))}
                placeholder="Search Assets"
                showChips
                topLevelSelectAllLabel="Select All Assets"
              />
            )}
          </ThemeProvider>
          {!isLoading && Object.keys(assetTasksRef.current).length === 0 && <NoAssets />}
          <ThemeProvider>
            <Divider />
          </ThemeProvider>
          <AssetModal
            open={createAssetModalOpen}
            mode={Mode.ADD}
            handlePrimaryAction={handleCreateAsset}
            handleClose={() => setCreateAssetModalOpen(false)}
          />
        </>
      )}

      <>
        <div css={style.title}>
          <Title options={{ label: isAssetEnabled ? 'Tasks - No Asset Associated' : 'Tasks' }} />
        </div>
        {!isAssetEnabled && (
          <div css={style.subtitle}>
            <Subtitle options={{ label: 'Create the tasks that will be performed.' }} />
            <ThemeProvider>
              {headerButtons.map(B => (
                <div css={style.moreHeaderButtons}>
                  <B />
                </div>
              ))}
            </ThemeProvider>
          </div>
        )}
        {isLoading ? (
          <Skeleton count={tasks.length || 1} height={32} />
        ) : (
          <TasksBase
            forms={forms.map(form => ({
              id: form.id,
              label: form.name,
              value: {
                name: form.name,
                required: false,
                formDefinitionId: form.latestPublishedFormDefinition.id,
                formSortKey: form.sortKey,
                formDefinitionSortKey: form.latestPublishedFormDefinitionSortKey
              }
            }))}
            tasks={tasksRef.current}
            onTasksChange={setTasks}
            showCheckmarks={showCheckmarks}
          />
        )}
      </>
    </>
  );
};

export default Tasks;
