import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';
import { employeeSearch } from 'services/API/employee';

const margin = '0px 0px 16px 0px';

const poFrameSideFormShippingFields = {
  shipTo: { required: false },
  shippingAddress: { required: false },
  shippingLocation: { required: false },
  addressLine1: { required: false },
  addressLine2: { required: false },
  city: { required: false },
  state: { required: false },
  zipcode: { required: false }
};

const poFrameSideFormShippingContents = (
  shipTo,
  manualEntry,
  handleChangeShipToSelect,
  canEdit,
  shipToIsEmployee
) => {
  return [
    {
      contents: [
        {
          options: {
            margin: '0px 0px 10px 0px'
          },
          contents: [
            {
              component: {
                default: 'MuiFormSectionTitle',
                edit: 'MuiFormSectionTitle'
              },
              options: {
                label: 'Shipping Information'
              }
            }
          ]
        }
      ]
    },
    {
      contents: [
        {
          options: {
            direction: 'column',
            margin
          },
          contents: [
            {
              component: {
                default: null,
                edit: 'RadioButtonsGroup'
              },
              source: 'shipToNameType',
              options: {
                disabled: !canEdit,
                btnDirection: 'Horizontal',
                label: 'Ship to Name',
                inputOptions: 'Employee, Other'
              }
            }
          ]
        }
      ]
    },
    {
      contents: [
        {
          options: {
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelWrap',
                edit: shipToIsEmployee ? 'customSearchBar' : 'TextInput'
              },
              source: shipToIsEmployee ? 'shipToEmployee' : 'shipToName',
              options: {
                ...(shipToIsEmployee
                  ? {
                      resultFormatFunction: employee => `${employee.name}`,
                      searchFunction: employeeSearch,
                      searchColumns: ['name'],
                      placeholder: 'Select Employee',
                      className: 'employee-select'
                    }
                  : { placeholder: 'Enter Name', maxLength: 255, linesMax: 12 }),
                label: 'Ship to Name',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                disabled: !canEdit,
                marginRight: 16
              }
            }
          ]
        }
      ]
    },
    {
      contents: [
        {
          options: {
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelNarrow',
                edit: 'SelectInput'
              },
              source: 'shipTo',
              options: {
                label: 'Ship to',
                placeholder: 'Select Shipping Location',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                valueSet: shipTo,
                onChange: handleChangeShipToSelect,
                disabled: !canEdit
              }
            }
          ]
        }
      ]
    },
    {
      contents: [
        {
          options: {
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelWrap',
                edit: manualEntry ? 'PlacesSearch' : 'CustomFieldWithLabelWrap'
              },
              source: 'shippingAddress',
              options: {
                label: 'Address',
                placeholder: manualEntry ? 'Enter Shipping Address' : '',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                disabled: !manualEntry || !canEdit,
                valuesToSet: manualEntry
                  ? [
                      {
                        addressLine1: 'addressLine1',
                        addressLine2: 'addressLine2',
                        city: 'city',
                        state: 'state',
                        zipcode: 'zipcode'
                      }
                    ]
                  : undefined
              }
            }
          ]
        }
      ]
    },
    {
      contents: [
        {
          options: {
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelWrap',
                edit: 'TextInput'
              },
              source: 'shipToInstructions',
              options: {
                label: 'Shipping Instructions',
                placeholder: 'Enter Shipping Instructions',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                disabled: !canEdit,
                linesMax: 20,
                marginRight: 16
              }
            }
          ]
        }
      ]
    },
    {
      contents: [
        {
          options: {
            margin
          },
          contents: [
            {
              component: {
                default: 'LocationView',
                edit: 'LocationView'
              },
              source: 'shippingLocation'
            }
          ]
        }
      ]
    }
  ];
};

const poFrameSideFormShippingLayout = ({
  shipTo,
  manualEntry,
  handleChangeShipToSelect,
  canEdit,
  shipToIsEmployee
}) => {
  return {
    fields: generateDefaultFieldsObject(poFrameSideFormShippingFields),
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: poFrameSideFormShippingContents(
          shipTo,
          manualEntry,
          handleChangeShipToSelect,
          canEdit
        )
      },
      edit: {
        options: {
          pageSize: 'LETTER',
          width: 203,
          grow: 0
        },
        contents: poFrameSideFormShippingContents(
          shipTo,
          manualEntry,
          handleChangeShipToSelect,
          canEdit,
          shipToIsEmployee
        )
      }
    }
  };
};

export { poFrameSideFormShippingFields, poFrameSideFormShippingLayout };
