/* eslint-disable import/no-cycle */
import moment from 'moment';
import StorageService from 'services/StorageService';
import { getUniqueName } from 'utils';
import { ProjectStatus, SectionTypeMap } from '@pm/constants';
import { getCustomerRepById } from 'services/API/customerRep';
import { getEmployeeCompanyById } from 'services/API/employee';
import { Logger } from 'services/Logger';
import { pdf } from '@react-pdf/renderer';
import { PDFDocument } from 'pdf-lib';

export const getContentText = (textType, text, fractionDigit = 0) => {
  if (text === null || text === undefined) return '';

  switch (textType) {
    case 'currency':
      return `$${parseFloat(text).toLocaleString('en', {
        minimumFractionDigits: fractionDigit,
        maximumFractionDigits: fractionDigit
      })}`;
    case 'percentage':
      return `${parseFloat(text).toLocaleString('en', {
        minimumFractionDigits: fractionDigit,
        maximumFractionDigits: fractionDigit
      })}%`;
    case 'percentAndCurrency':
      return `${text[0]}% $${parseFloat(text[1]).toLocaleString('en', {
        minimumFractionDigits: fractionDigit,
        maximumFractionDigits: fractionDigit
      })}`;
    case 'date':
      return moment.unix(text).format('L');
    case 'utcDate':
      return moment
        .unix(text)
        .utc()
        .format('L');
    default:
      return text;
  }
};

// Rounds to two places, but leaves the value as is if it has less than two decimal places.
// IE: 2.5 -> 2.5; 2.556 -> 2.56; 2 -> 2
export const roundToTwoPlaces = value => {
  const valueToRound = typeof value !== 'number' ? Number(value) : value;
  return Math.round((valueToRound + Number.EPSILON) * 100) / 100;
};

export const getTaxCode = (name, taxRate, fractionDigit = 3) => {
  if (!name) return '';
  const taxRateStr = taxRate
    ? parseFloat(taxRate).toLocaleString('en', {
        minimumFractionDigits: fractionDigit,
        maximumFractionDigits: fractionDigit
      })
    : 0;

  return `${name} - ${taxRateStr}%`;
};

export const getImageUrl = async (imageUrl, isThubmnail = false) => {
  const storageService = new StorageService();
  return storageService.getFile(imageUrl, isThubmnail);
};

export const uploadFiletoCloud = async (fileInput, tenantId) => {
  try {
    const storageService = new StorageService();
    const uniqueName = getUniqueName(tenantId, fileInput.file.name);
    return await storageService.uploadFile(fileInput.file, uniqueName, null, null);
  } catch (error) {
    return null;
  }
};

export const checkRequiredFieldsFilled = (allFields, filteredFields) => {
  return Object.keys(filteredFields).every(field => {
    return typeof allFields[field] === 'string'
      ? allFields[field] !== null && allFields[field]?.length > 0
      : allFields[field] !== null && allFields[field] !== undefined;
  });
};

export const getLabelFromValues = data => {
  return Object.values(data).map(item => ({
    label: item,
    value: item
  }));
};

export const customSleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const getCombinedAddressFromProjectData = project => {
  const addressFields = ['address1', 'address2', 'addressCity', 'addressState', 'addressPostal'];
  return (
    project &&
    addressFields
      .filter(addressField => !!project[addressField])
      .map(populatedAddressField =>
        (
          (Array.isArray(project[populatedAddressField])
            ? project[populatedAddressField][0]
            : project[populatedAddressField]) || ''
        ).trim()
      )
      .join(', ')
      .trim()
  );
};

export const getFormattedAddresses = data => {
  const result = [];
  if (!data || !data?.length) return result;

  data.forEach(item => {
    if (item.isActive) {
      result.push({
        addressType: item.addressType ?? null,
        billTo: item.billTo ?? null,
        addressLine1: item.addressLine1 ?? null,
        addressLine2: item.addressLine2 ?? null,
        city: item.city ?? null,
        state: item.state ?? null,
        zipcode: item.zipcode ?? null
      });
    }
  });
  return result;
};

export const getFinalBillingCustomerAddress = data => {
  if (!data || !data?.length) return null;

  const billingAddress = data.filter(
    item => item.addressType === 'billingAddress' && item.isActive
  );
  const businessAddress = data.filter(
    item => item.addressType === 'businessAddress' && item.isActive
  );

  if (billingAddress.length) {
    return billingAddress[0];
  }
  if (businessAddress.length) {
    return businessAddress[0];
  }
  return null;
};

export const getCloudinaryImageUrl = url => {
  if (!url) return '';
  // Storage service has logic to check unsupported files or transformed files
  // mainly heic and pdf are stored as jpg
  return url.includes('https') ? url : new StorageService().getFile(url);
};

export const getBase64FromUrl = async url => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const getInitials = nameArray => {
  return nameArray.map(n => n[0]?.toUpperCase()).join('');
};

export const isStartBeforeEndDate = (startDate, endDate) => {
  // only check the values if both are present, as they're not required fields.
  if (!startDate || !endDate) return true;
  if (moment.unix(startDate).isBefore(moment.unix(endDate))) return true;
  return false;
};

export const getFormattedEmailData = (data, customerRepList, recipientName) => {
  const sortedData = data.sort((a, b) => (a.createdDateTime > b.createdDateTime ? -1 : 1));
  return sortedData.map(email => {
    const recipientEmails = email.sendTo?.split(';') || [];
    // loop through each email and see if it matches a customerrep/employee
    const finalRecipients = recipientEmails.map(recipientEmail => {
      const foundPerson = customerRepList.find(element => element.email === recipientEmail);
      if (foundPerson) {
        return foundPerson;
      }
      return { email: recipientEmail };
    });

    return {
      id: email?.id || '',
      subject: email?.subject || '',
      email: email?.sendTo || '',
      recipients: finalRecipients,
      recipient: finalRecipients.length
        ? finalRecipients[0]?.name || finalRecipients[0]?.email
        : recipientName || '',
      emailContent: email?.body || '',
      date: email.createdDate ? moment.unix(email.createdDate).toDate() : '',
      read: true,
      attachments: email.attachments,
      from: email.from,
      inReplyTo: email.inReplyTo
    };
  });
};

export const getFormattedActivityData = data => {
  if (!data?.AuditLogEntry) return [];

  const logs = data?.AuditLogEntry;
  logs.sort((a, b) => (a.createdDateTime > b.createdDateTime ? -1 : 1));

  return logs.map(log => {
    const filename = `${log.auditedEntityType.trim()} ${data?.number}`;
    const [firstName, lastName] = log?.executedBy ? log.executedBy.split(' ') : ['System', ''];
    return {
      user: {
        firstName,
        lastName,
        profilePic: '',
        userLink: ''
      },
      action: log?.executionType,
      filename,
      timestamp: new Date(log?.createdDateTime)
    };
  });
};

export const getCODescriptionTitle = item => {
  return `${`Change Order ${item.ChangeOrder?.number ?? ''} -`} ${item.description ?? ''}`;
};

export const getEmployeeOrCustomerRep = async id => {
  const customerRep = await getCustomerRepById(id);
  if (customerRep) return customerRep;

  const employee = await getEmployeeCompanyById(id);
  if (employee) return employee;
};

/**
 * Handles what happens after @pm/components/Attachment finishes uploading and is closed.
 * Removes duplicate attachments.
 * @param {Array} newAttachments List of any attachments uploaded while the modal was opened
 * @param {Array} selectedImages All images that are currently selected (including new ones)
 * @param {Array} selectedFiles All files are are currently selected (includes new ones)
 * @param {Array} attachmentsInState Attachments that are currently in state, for getting existing IDs
 * @returns
 */
export const handleUploadAttachments = (
  newAttachments,
  selectedImages,
  selectedFiles,
  attachmentsInState = [],
  user = {}
) => {
  // Removes duplicates from selected lists, since new attachments will be present in both
  // the newAttachments list and one of the selected lists.
  // Attachments can have fileName or name, fileUrl or url, etc since most PM tables have a corresponding
  // attachment table instead of the existing Attachment table. This will have to be fixed in the future.
  const filterOutDuplicates = listToFilter => {
    return listToFilter.filter(
      image =>
        !newAttachments.find(
          attachment =>
            (attachment.name && attachment.name === image.fileName) ||
            (attachment.fileName && attachment.fileName === image.fileName) ||
            (image.name && image.name === attachment.fileName) ||
            (image.fileName && image.fileName === attachment.fileName)
        )
    );
  };

  const remainingAttachments = [...attachmentsInState];
  const filteredImages = filterOutDuplicates(selectedImages);
  const filteredFiles = filterOutDuplicates(selectedFiles);

  // Combine all attachments that are currently selected
  const combinedAttachments = [...newAttachments, ...filteredFiles, ...filteredImages].map(
    newAttachment => {
      const existingAttachment = remainingAttachments.find(
        stateAttachment =>
          (stateAttachment.url && stateAttachment.url === newAttachment.fileUrl) ||
          (stateAttachment.fileUrl && stateAttachment.fileUrl === newAttachment.fileUrl) ||
          (newAttachment.url && newAttachment.url === stateAttachment.fileUrl) ||
          (newAttachment.fileUrl && newAttachment.fileUrl === stateAttachment.fileUrl)
      );
      if (existingAttachment) {
        const foundIndex = remainingAttachments.indexOf(existingAttachment);
        remainingAttachments.splice(foundIndex, 1);
      }
      return existingAttachment || newAttachment;
    }
  );

  // Add deleted metadata to any attachments that are present in the state, but not selected.
  return combinedAttachments.concat(
    remainingAttachments.map(deletedAttachment => ({
      ...deletedAttachment,
      deletedBy: user.id,
      deletedDate: moment().unix(),
      deletedDateTime: moment().valueOf(),
      _deleted: true
    }))
  );
};

export const calculateCOContractValue = item =>
  (item.sellPrice || 0) *
  (item.quantity || 0) *
  ((((item.taxable && Number(item.taxPercent)) || 0) +
    (Number(item.overheadPercent) || 0) +
    (Number(item.profitPercent) || 0)) /
    100 +
    1);

export const sortProjects = projects => {
  const sortedProjects = projects.sort((a, b) => b.createdDate - a.createdDate);
  const bids = sortedProjects.filter(p => p.status === ProjectStatus.BID);
  const accepted = sortedProjects.filter(p => p.status === ProjectStatus.ACCEPTED);
  const wip = sortedProjects.filter(p => p.status === ProjectStatus.WORK_IN_PROGRESS);
  const wc = sortedProjects.filter(p => p.status === ProjectStatus.WORK_COMPLETE);
  const closed = sortedProjects.filter(p => p.status === ProjectStatus.CLOSED);
  return [...bids, ...accepted, ...wip, ...wc, ...closed];
};

export const getProfileInitials = (firstName, lastName) => {
  return [firstName, lastName].map(n => n[0]?.toUpperCase()).join('');
};

export const getNotesDataFromDailyReport = dailyReport => {
  if (!dailyReport) return {};

  try {
    const data = dailyReport.notes?.[0] ?? {};
    const attachments = [];
    (data.noteSections || []).forEach(section => {
      const key = SectionTypeMap[section.sectionType];
      data[key] = section.summary || '';
      if (Array.isArray(section.attachments)) attachments.push(...section.attachments);
    });

    return { ...data, attachments };
  } catch (error) {
    Logger.error(`@pm/components/utils::getNotesDataFromDailyReport: ${error}`);
    return {};
  }
};

export const getNoteSectionsDataFromFormData = (formData, noteId) => {
  if (!formData) return [];

  try {
    const notes = Object.values(formData.notes ?? {});
    const data = notes?.[0] ?? {};
    const sections = [];

    Object.keys(SectionTypeMap).forEach(type => {
      const existingNoteSection = data?.noteSections?.find?.(x => x.sectionType === type);
      const key = SectionTypeMap[type];

      if (existingNoteSection) {
        sections.push({
          ...existingNoteSection,
          summary: data[key] || ''
        });
      } else {
        sections.push({
          isComplete: false,
          sectionType: type,
          summary: data[key],
          dailyReportNoteId: noteId
        });
      }
    });

    return sections;
  } catch (error) {
    Logger.error(`@pm/components/utils::getNoteSectionsDataFromFormData: ${error}`);
    return [];
  }
};

export const isImageAttachment = attachment => {
  if (!attachment || (!attachment.fileType && !attachment.type && !attachment.fileUrl))
    return false;

  const imageTypes = ['png', 'jpeg', 'jpg', 'gif', 'heic'];
  const isImage =
    imageTypes.includes(attachment.type?.toLowerCase()) ||
    imageTypes.includes(attachment.fileType?.toLowerCase()) ||
    imageTypes.includes(
      attachment.fileUrl
        ?.split('.')
        ?.pop()
        ?.toLowerCase()
    );

  return isImage;
};

export const getCurrentDateInTimezone = timezone =>
  timezone ? moment.tz(moment().format('YYYY-MM-DD'), timezone).unix() : undefined;

export const formatDateInTimezone = (date, timezone) =>
  timezone
    ? moment.tz(moment.unix(date), timezone).format('YYYY-MM-DD')
    : moment.unix(date).format('YYYY-MM-DD');

export const addAllPages = async (mainPdf, donorPdf) => {
  const indexArray = [...Array(donorPdf.getPageCount()).keys()];
  const pages = await mainPdf.copyPages(donorPdf, indexArray);
  pages.forEach(page => mainPdf.addPage(page));
};

export const convertPDFLibDocumentToBlob = async pdfDoc => {
  const pdfBytes = await pdfDoc.save();
  return new Blob([pdfBytes], { type: 'application/pdf' });
};

/**
 * Handles what happens after @pm/components/Attachment finishes uploading and is closed.
 * Removes duplicate attachments.
 * @param {ReactPDF.Document} mainPDFDocument The original PDF that the attachments will be add to
 * @param {Array.<ReactPDF.Document>} attachments An array of attachments to be
 * appended to the mainPDFDocument, each attachment should have a fileUrl field
 * that contains a url to fetch corresponding pdf
 * @param {boolean} returnAsPDFDocument If true, return PDFDocument object; if false, return Blob
 */
export const addAttachmentsToPDF = async ({
  mainPDFDocument,
  attachments,
  returnAsPDFDocument = false
}) => {
  let mergedPdfBlob = null;
  mergedPdfBlob = await pdf(mainPDFDocument).toBlob();

  const pdfDoc = await PDFDocument.load(await mergedPdfBlob.arrayBuffer());

  const pdfAttachments = attachments?.filter(
    attachment => attachment?.fileType === 'application/pdf'
  );

  // Use pdf-lib to merge Change Order PDF with PDF attachments
  if (pdfAttachments && pdfAttachments?.length !== 0) {
    await Promise.all(
      pdfAttachments.map(({ fileUrl }) =>
        fetch(fileUrl).then(async res => {
          const buffer = await res.arrayBuffer();
          const donorPdf = await PDFDocument.load(buffer);
          await addAllPages(pdfDoc, donorPdf);
        })
      )
    );
    mergedPdfBlob = await convertPDFLibDocumentToBlob(pdfDoc);
  }

  return returnAsPDFDocument ? pdfDoc : mergedPdfBlob;
};

/**
 * Merges a PDFDocument with an array of PDFDocument
 * @param {PDFDocument} originalDoc The original PDFDocument to be merged with
 * @param {Array.<PDFDocument>} donorDocArray An array of PDFDocument to be merged
 */
export const mergePDFLibDocument = async (originalDoc, donorDocArray) => {
  if (
    !(originalDoc instanceof PDFDocument) ||
    !donorDocArray?.every(donorDoc => donorDoc instanceof PDFDocument)
  ) {
    return;
  }
  await Promise.all(donorDocArray.map(donorDoc => addAllPages(originalDoc, donorDoc)));
};
