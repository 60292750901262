import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import pick from 'lodash/pick';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { snackbarOn } from 'redux/actions/globalActions';
import { Modal } from 'components';
import { Select, ThemeProvider } from '@buildhero/sergeant';
import { JobStatus, Mode, PropertyEntities } from 'utils/constants';
import usePropertyEntities from 'customHooks/usePropertyEntities';
import { QuoteStatus } from 'utils/AppConstants';
import { JobService } from 'services/core';
import { updateTaskService } from 'scenes/Quotes/service';

const { JOB, MAINTENANCE_JOB, QUOTE } = PropertyEntities;

const getQueryParams = (entityType, customerPropertyInfo) => {
  switch (entityType) {
    case QUOTE:
      return [
        customerPropertyInfo?.propertyId,
        {
          stringFilters: {
            fieldName: 'Quote.status',
            filterInput: {
              eq: QuoteStatus.DRAFT
            }
          }
        }
      ];
    case MAINTENANCE_JOB:
    case JOB:
    default:
      return [
        customerPropertyInfo?.partitionKey,
        customerPropertyInfo?.sortKey,
        {
          stringFilters: {
            fieldName: 'Job.status',
            filterInput: {
              eq: JobStatus.OPEN
            }
          }
        }
      ];
  }
};

const getMutationParams = (entityType, entity, tasks, partitionKey, propertyId) => {
  if ([JOB, MAINTENANCE_JOB].includes(entityType)) {
    return [
      partitionKey,
      { jobId: entity?.id, jobTasks: tasks.map(task => ({ taskId: task?.id })) }
    ];
  }
  if (entityType === QUOTE) {
    const task = tasks[0];
    const products = task?.products;
    const getProductInfo = product => {
      if (!product || !task) return;
      let taskProductInfo = pick(product, [
        'name',
        'description',
        'productId',
        'priceBookEntryId',
        'unitPrice',
        'unitCost',
        'taxable',
        'markupType',
        'markupValue',
        'quantity'
      ]);
      if (product?.priceBookEntry?.product) {
        const { costCodeId, revenueTypeId, jobCostTypeId } = product?.priceBookEntry?.product;
        taskProductInfo = { ...taskProductInfo, costCodeId, revenueTypeId, jobCostTypeId };
      }
      const { departmentId } = task;
      return {
        departmentId,
        ...taskProductInfo
      };
    };

    return [
      {
        taskToUpdate: {
          taskId: task?.id,
          name: task?.name,
          description: task?.description,
          quoteLineProducts: (products || []).map(getProductInfo)
        },
        propertyId,
        quoteInfo: {
          id: entity.id,
          version: entity.version
        },
        setQuoteLineTasks: () => {},
        snackbar: () => {},
        tenantId: partitionKey
      }
    ];
  }
};

const AddTaskToWorkModal = ({
  open,
  handleClose,
  refreshTasks,
  partitionKey,
  selectedTasks,
  customerPropertyInfo,
  snackbarOn: showSnackbar
}) => {
  const [entityType, setEntityType] = useState('');
  const [entity, setEntity] = useState({});
  const [loading, setLoading] = useState(false);
  const queryParams = useCallback(getQueryParams(entityType, customerPropertyInfo), [entityType]);

  const { propertyEntities, loaded: entitiesFetched } = usePropertyEntities(
    entityType,
    queryParams,
    showSnackbar
  );
  const LabelMap = {
    [JOB]: 'jobNumber',
    [MAINTENANCE_JOB]: 'maintenanceNumber',
    [QUOTE]: 'quoteNumber'
  };
  // when custom quote or job number is enable only then the customIdentifier field will have value
  const options = propertyEntities.map(item => ({
    label: `${entityType}-${item?.customIdentifier || get(item, LabelMap[entityType], '')}`,
    value: get(item, 'id')
  }));

  const addToWork = async () => {
    const selectedEntity = propertyEntities.find(item => item?.id === entity?.value);

    if (!selectedEntity) return;
    try {
      setLoading(true);
      if ([JOB, MAINTENANCE_JOB].includes(entityType)) {
        const params = getMutationParams(
          entityType,
          selectedEntity,
          selectedTasks,
          partitionKey,
          customerPropertyInfo.propertyId
        );
        await new JobService().addJobTasksToJob(...params);
      } else if (entityType === QUOTE) {
        const addTasksToQuotePromises = selectedTasks?.map(task => {
          const updateTaskServiceParams = getMutationParams(
            entityType,
            selectedEntity,
            [task],
            partitionKey,
            customerPropertyInfo.propertyId
          );
          return updateTaskService(...updateTaskServiceParams);
        });
        await Promise.all(addTasksToQuotePromises);
      }
      showSnackbar(
        'success',
        `Task${selectedTasks?.length > 1 ? 's' : ''} added to ${entity.label}`
      );
      refreshTasks();
    } catch (error) {
      showSnackbar(
        'error',
        `Unable to add the selected task${selectedTasks?.length > 1 ? 's' : ''}`,
        error
      );
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const submitButton = {
    buttonType: 'contained',
    color: 'primary',
    disabled: !entityType,
    action: addToWork,
    label: 'ADD TASK TO WORK',
    fullWidth: true
  };

  return (
    <Modal
      open={open}
      showModalHeader
      width={496}
      mode={Mode.ACTIVATE}
      modalTitle="Add Task To Work"
      handleClose={handleClose}
      handlePrimaryAction={addToWork}
      spinnerStatus={loading}
      buttons={{ submitButton }}
    >
      <FormControl>
        <RadioGroup
          aria-label="materialRates"
          name="materialRates"
          value={entityType}
          onChange={event => {
            setEntity(null);
            setEntityType(event.target.value);
          }}
        >
          <FormControlLabel value={JOB} control={<Radio />} label="Add To Job" />
          <FormControlLabel
            value={MAINTENANCE_JOB}
            control={<Radio />}
            label="Add To Maintenance"
          />
          <FormControlLabel value={QUOTE} control={<Radio />} label="Add To Quote" />
        </RadioGroup>
      </FormControl>
      {entityType && (
        <ThemeProvider>
          <Select
            clearable
            searchable
            value={entity}
            loading={!entitiesFetched}
            options={options}
            onChange={d => setEntity(d)}
            placeholder={`Search or Select ${entityType}`}
          />
        </ThemeProvider>
      )}
    </Modal>
  );
};
const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const withConnect = connect(null, mapDispatcherToProps)(AddTaskToWorkModal);

export default withConnect;
