/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
/* eslint-disable react/no-array-index-key */
import React, { memo } from 'react';
import { arrayOf, bool, number } from 'prop-types';
import { Typography } from '@buildhero/sergeant';

import { ColDef, GridRowsProp } from '../WrapTable.types';
import Header from '../Header';
import Row from '../Row';

import { useTableStyles } from './TableBody.styles';
import LoadingOverlay from './LoadingOverlay';
import TotalsRow from '../TotalsRow/TotalsRow.component';

const TableBody = ({
  columns,
  enableGroupHeaders,
  enableTotalsRow,
  isFirstLoad,
  loading,
  loadingRows,
  lockRowHeight,
  maxGroupingDepth,
  maxHeight,
  noDataMessage,
  noWrap,
  rowGroupingColumnMode,
  rowGroupingModel,
  rows,
  scrollX,
  scrollY,
  visibleRows
}) => {
  const styles = useTableStyles({ scrollX, scrollY, maxHeight, enableTotalsRow });

  return (
    <div css={styles.scrollContainer}>
      <div css={styles.tableBody}>
        <Header
          {...{
            columns,
            isFirstLoad,
            loading,
            noWrap,
            rowGroupingColumnMode,
            rowGroupingModel,
            scrollX,
            scrollY
          }}
        />
        <div css={styles.rowContainer}>
          <div>
            {loading && (
              <LoadingOverlay
                {...{
                  columns,
                  isFirstLoad,
                  loadingRows,
                  maxHeight,
                  rowGroupingColumnMode,
                  rowGroupingModel,
                  scrollX,
                  scrollY
                }}
              />
            )}
            {!visibleRows.length && !isFirstLoad && (
              <Typography css={styles.noDataMessage}>{noDataMessage}</Typography>
            )}
            {visibleRows.map((row, index) => (
              <Row
                key={row.id}
                isFirstRow={index === 0}
                isLastRow={index === visibleRows.length - 1}
                {...{
                  columns,
                  enableGroupHeaders,
                  lockRowHeight,
                  maxGroupingDepth,
                  maxHeight,
                  noWrap,
                  row,
                  rowGroupingColumnMode,
                  rowGroupingModel,
                  rows,
                  scrollX,
                  scrollY
                }}
              />
            ))}
          </div>
          {enableTotalsRow && (
            <TotalsRow
              {...{
                columns,
                isFirstLoad,
                lockRowHeight,
                noWrap,
                rowGroupingColumnMode,
                rowGroupingModel,
                rows,
                scrollX
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

TableBody.propTypes = {
  columns: arrayOf(ColDef).isRequired,
  loading: bool,
  loadingRows: number.isRequired,
  lockRowHeight: bool.isRequired,
  maxHeight: number,
  noWrap: bool,
  scrollX: bool,
  scrollY: bool,
  visibleRows: GridRowsProp.isRequired
};

TableBody.defaultProps = {
  loading: false,
  noWrap: false,
  scrollX: false,
  scrollY: false
};

export default memo(TableBody);
