import gql from 'graphql-tag';

const addPurchaseOrdersToQuote = gql`
  mutation addPurchaseOrdersToQuote($partitionKey: String!, $data: AddPurchaseOrdersToQuoteInput!) {
    addPurchaseOrdersToQuote(partitionKey: $partitionKey, data: $data) {
      purchaseOrder(entityConnection: "PurchaseOrder") {
        id
        poNumber
        status
      }
      quote(entityConnection: "Quote") {
        versionNumber
      }
    }
  }
`;

export default addPurchaseOrdersToQuote;
