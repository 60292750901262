import { partition } from 'lodash';

import { GroupingColumnMode } from '../WrapTable.constants';

export const useRowColumns = ({
  columns: baseColumns,
  groupField,
  rowGroupingModel,
  rowGroupingColumnMode
}) => {
  let columns = [...baseColumns];

  if (rowGroupingModel?.length && rowGroupingColumnMode === GroupingColumnMode.SINGLE) {
    const [groupColumns, otherColumns] = partition(columns, colDef => {
      return rowGroupingModel.includes(colDef.field);
    });

    const firstGroup = groupColumns.find(colDef => colDef.field === rowGroupingModel[0]);

    if (groupField) {
      const currentGroup = groupColumns.find(colDef => colDef.field === groupField);

      const normalizedCurrentGroup = {
        ...currentGroup,
        width: firstGroup.width,
        flex: firstGroup.flex,
        maxWidth: firstGroup.maxWidth,
        minWidth: firstGroup.minWidth,
        cellCss: firstGroup.cellCss,
        headerCss: firstGroup.headerCss
      };

      columns = [normalizedCurrentGroup, ...otherColumns];
    } else {
      const lastGroup = groupColumns.find(
        colDef => colDef.field === rowGroupingModel[groupColumns.length - 1]
      );

      columns = [lastGroup, ...otherColumns];
    }
  }

  return columns.filter(({ hide }) => !hide);
};
