import gql from 'graphql-tag';

const addForm = gql`
  mutation CreateForm($partitionKey: String!, $data: CreateFormInput!) {
    createForm(partitionKey: $partitionKey, data: $data) {
      id
      version
    }
  }
`;

export default addForm;
