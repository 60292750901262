import gql from 'graphql-tag';

const createQuoteTag = gql`
  mutation addQuoteTagsToCompany(
    $partitionKey: String!
    $companyId: String!
    $data: [CompanyQuoteTagInput]!
  ) {
    addQuoteTagsToCompany(
      partitionKey: $partitionKey
      data: { companyId: $companyId, quoteTags: $data }
    ) {
      id
      tagType
      tagName
      sortOrder
    }
  }
`;

export default createQuoteTag;
