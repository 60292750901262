import { MUIFormComponents } from '@buildhero/sergeant';

export const FieldLabel = MUIFormComponents.TextFactory({
  style: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    color: '#959595',
    fontSize: '12px',
    letterSpacing: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    margin: MUIFormComponents.getLocalizedSize([4, 0])
  }
});

export const Title = MUIFormComponents.TextFactory({
  style: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    color: '#17294d',
    fontSize: '24px',
    letterSpacing: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    paddingRight: 5,
    marginBottom: 5
  }
});

export const Subtitle = MUIFormComponents.TextFactory({
  style: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    color: '#17294d',
    fontSize: '16px',
    letterSpacing: 'normal',
    textTransform: 'uppercase',
    fontWeight: 600,
    lineHeight: 'normal',
    paddingRight: 5,
    marginBottom: 5
  }
});

export const GreySubtitle = MUIFormComponents.TextFactory({
  style: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    color: '#17294d',
    fontSize: '12px',
    letterSpacing: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    paddingRight: 5,
    marginBottom: 5
  }
});

export default FieldLabel;
