import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { snackbarOn, snackbarOff } from 'redux/actions/globalActions';
import Grid from '@material-ui/core/Grid';
import { Input, Button } from '@buildhero/sergeant';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PasswordInput from '../../PasswordInput';
import styles from '../../styles';

export class ForgotPasswordCode extends Component {
  constructor(props) {
    super(props);
    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.state = {
      spinner: false
    };
  }

  componentWillUnmount = () => {
    this.setState({ spinner: false });
  };

  saveAndContinue = async e => {
    e.preventDefault();
    this.setState({ spinner: true });
    const { showVerificationCodeInput } = this.props;
    const { code, newPassword, confirmPassword } = this.props.values;

    let errorMessage = '';
    if (showVerificationCodeInput) {
      if (!code?.trim()) {
        errorMessage += 'Verification code';
      }
    }
    if (!newPassword?.trim()) {
      errorMessage += errorMessage ? ' and your new password' : 'Your new password';
    }
    if (errorMessage) {
      errorMessage += ' must be filled in.';
    }

    if (!confirmPassword?.trim()) {
      errorMessage += errorMessage
        ? ' And you must confirm your new password.'
        : 'You must confirm your new password.';
    }

    if (errorMessage) {
      this.props.displayError(errorMessage);
      this.setState({ spinner: false });
      return;
    }

    const { codeValid, newPasswordValid, confirmPasswordValid } = this.props.validValues;

    if ((codeValid || !showVerificationCodeInput) && newPasswordValid && confirmPasswordValid) {
      await this.props.nextStep();
      this.setState({ spinner: false });
    } else {
      errorMessage = 'Please check that you filled in correctly.';
      this.setState({ spinner: false });
      this.props.displayError(errorMessage);
    }
  };

  render() {
    const { errorMessages, classes, i18nMessages, locale, showVerificationCodeInput } = this.props;

    return (
      <>
        {showVerificationCodeInput && (
          <Grid item xs={12} className={classes.authSection}>
            <Input
              label={i18nMessages.enterVerificationCodeLabel[locale]}
              key="code"
              name="code"
              id="code"
              autoComplete="one-time-code"
              onChange={this.props.handleChange('code')}
              fullWidth
              error={errorMessages.code?.length > 0}
              subtext={errorMessages.code}
              style={{ marginBottom: 12 }}
            />
            <Button
              type="leading"
              onClick={this.props.resendCode}
              startIcon={<AddCircleOutlineIcon />}
              size="small"
            >
              {i18nMessages.resendCodeButtonText[locale]}
            </Button>
          </Grid>
        )}
        <Grid item xs={12} className={classes.authSection}>
          <PasswordInput
            label={i18nMessages.enterNewPasswordLabel[locale]}
            placeholder=""
            key="newPassword"
            name="newPassword"
            id="newPassword"
            type="password"
            onChange={this.props.handleChange('newPassword')}
            fullWidth
            autoComplete="new-password"
            error={errorMessages.newPassword?.length > 0}
            subtext={errorMessages.newPassword}
            tooltip="The password must have a minimum of 8 characters"
          />
        </Grid>
        <Grid item xs={12} className={classes.authSection}>
          <PasswordInput
            label={i18nMessages.confirmNewPasswordLabel[locale]}
            placeholder=""
            key="confirmPassword"
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            onChange={this.props.handleChange('confirmPassword')}
            error={errorMessages.confirmPassword?.length > 0}
            subtext={errorMessages.confirmPassword}
          />
        </Grid>
        <Grid item xs={12} className={classes.authSection}>
          <Button
            onClick={this.saveAndContinue}
            type="primary"
            loading={this.state.spinner}
            fullWidth
          >
            {i18nMessages.changePasswordButtonText[locale]}
          </Button>
        </Grid>
        {showVerificationCodeInput && (
          <Grid item xs={12} className={classes.authSection}>
            <Button
              type="secondary"
              onClick={this.props.previousStep}
              startIcon={<ArrowBackIcon />}
              fullWidth
            >
              {i18nMessages.previousStepButtonText[locale]}
            </Button>
          </Grid>
        )}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message)),
  snackbarOff: () => dispatch(snackbarOff())
});
const reduxConnectedForgotPasswordCode = connect(null, mapDispatchToProps)(ForgotPasswordCode);

export default compose(withRouter, withStyles(styles))(reduxConnectedForgotPasswordCode);
