import { isEmpty } from 'lodash';

const emptyCheck = (list, keysToCheck) => {
  const nonEmptyItem = list.filter(item => {
    const notEmptyKeys = keysToCheck.filter(itemKey => {
      const value = item[itemKey];
      const unKnownList = [null, undefined];
      return !unKnownList.includes(value) && !isEmpty(`${value}`);
    });
    return notEmptyKeys.length !== keysToCheck.length;
  });

  return nonEmptyItem.length !== 0;
};

export default emptyCheck;
