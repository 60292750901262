import { connect } from 'react-redux';

import PMHeader from './PMHeader.component';

const mapStateToProps = state => ({
  user: state.user,
  project: state.pm.project
});

export default connect(mapStateToProps)(PMHeader);
