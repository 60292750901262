import InstructionHelper from '../InstructionHelper';
import { removeNestedJson } from '../../utils';

const updateVisitSchemaMapping = (visit, tech, type) => {
  let localProps = visit;
  localProps.id = visit.visitId;
  localProps = removeNestedJson(localProps);
  const fieldsToBeRemoved = [
    '__typename',
    'tasks',
    'primaryTechs',
    'extraTechs',
    'parentEntity',
    'directSortKey',
    'invertedSortKey',
    'eTechs',
    'pTech',
    'visitId',
    'resourceId',
    'jobId',
    'departments',
    'title',
    'resizable',
    'parent',
    'parentEntity'
  ];
  const removeFieldsMaps = ['directSortKey', 'invertedSortKey'];

  // let tasksArray = [];
  let primaryTechsArray = [];
  let extraTechsArray = [];
  const otherFields = {};
  Object.keys(localProps).forEach(key => {
    if (localProps[key] && localProps[key] !== '' && !fieldsToBeRemoved.includes(key)) {
      otherFields[key] = localProps[key];
    }
  });
  if (type === 'primary') {
    primaryTechsArray.push(removeNestedJson(tech));
  }
  if (type === 'extra') {
    extraTechsArray.push(removeNestedJson(tech));
  }

  const data = [otherFields];
  const ins = new InstructionHelper();
  let temp = ins;
  primaryTechsArray.forEach(techObj => {
    const primaryTech = {};
    Object.keys(techObj).forEach(key => {
      if (techObj[key] && techObj[key] !== '' && !removeFieldsMaps.includes(key)) {
        primaryTech[key] = techObj[key];
      }
    });
    data.push(primaryTech);
    temp = temp.manyToMany(
      0,
      data.length - 1,
      'visitNumber',
      'lastName',
      'PrimaryTechVisit',
      'PrimaryTech'
    );
  });
  extraTechsArray.forEach(techObj => {
    const extraTech = {};
    Object.keys(techObj).forEach(key => {
      if (techObj[key] && techObj[key] !== '' && !removeFieldsMaps.includes(key)) {
        extraTech[key] = techObj[key];
      }
    });
    data.push(extraTech);
    temp = temp.manyToMany(
      0,
      data.length - 1,
      'visitNumber',
      'lastName',
      'ExtraTechVisit',
      'ExtraTech'
    );
  });

  const { instructionSet } = temp;
  const ret = { data: JSON.stringify(data), instructionSet: JSON.stringify(instructionSet) };
  return ret;
};

export default updateVisitSchemaMapping;
