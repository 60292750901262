/* eslint-disable no-confusing-arrow */
import algoliasearch from 'algoliasearch/lite';
import React, { Component } from 'react';
import {
  InstantSearch,
  connectRefinementList,
  connectStateResults,
  connectCurrentRefinements,
  connectSearchBox
} from 'react-instantsearch-dom';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Grid, Typography, Popper, Paper, Fade } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from '../styles';
import './style.css';
import RefinementList from './RefinementList';
import ENV from 'configs/env';
import configForEnvironment from 'configs/aws-exports';
import Results from './Results';
import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';

const ClearRefinementsFn = ({ items, refine, classes, changeState }) => (
  <IconButton
    classes={{ root: classes.clearSearchButton }}
    style={{ display: !items.length ? 'none' : 'block', backgroundColor: 'transparent' }}
    aria-label="Close"
    onClick={() => {
      refine(items);
      changeState({ name: 'inputValue', value: '' });
    }}
  >
    <CloseIcon classes={{ root: classes.clearIcon }} disabled={!items.length} />
  </IconButton>
);
const CustomRefinementList = connectRefinementList(RefinementList);

const WrappedRefinementList = props => (
  <Grid item xs={12} style={{ height: '100%' }}>
    <Typography variant="body2" style={{ padding: 10 }}>
      {props.title}
    </Typography>
    <CustomRefinementList
      attribute={props.attribute}
      configure={props.configure}
      operator="or"
      showMore
      limit={4}
    />
  </Grid>
);

const CustomClearRefinements = connectCurrentRefinements(ClearRefinementsFn);

class SearchComponent extends Component {
  // eslint-disable-next-line react/sort-comp
  constructor(props) {
    super(props);
    const searchClient = algoliasearch(
      configForEnvironment(ENV).algoliaAppKey,
      this.props.searchKey
    );
    this.searchClient = searchClient;
    this.state = {
      closeModal: false,
      focus: false,
      inputValue: ''
    };
  }

  searchResults = connectStateResults(({ searchState }) => {
    const open = searchState !== '' && !this.state.closeModal;
    return searchState && searchState.query ? (
      <Popper
        id="searchResults"
        open={open}
        disablePortal
        anchorEl={() => document.getElementById('globalSearch')}
        placement="bottom-end"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={0}>
            <Paper
              style={{
                backgroundColor: '#fafafa',
                width: window.innerWidth < 600 ? '100%' : window.innerWidth * 0.64,
                maxHeight: window.innerHeight * 0.7,
                overflowY: 'auto',
                marginTop: 15,
                boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.5)'
              }}
            >
              <Results
                WrappedRefinementList={WrappedRefinementList}
                query={searchState.query}
                closeSearchResultModal={this.handleCloseModal}
              />
            </Paper>
          </Fade>
        )}
      </Popper>
    ) : null;
  });

  SearchBox = ({ currentRefinement, refine, classes, isSearchStalled }) => (
    <Grid item>
      <InputBase
        placeholder={isSearchStalled ? 'Loading...' : 'Search'}
        autoFocus={this.state.focus}
        classes={{
          root: classes.searchInputRoot,
          input: classes.searchInput
        }}
        value={currentRefinement || this.state.inputValue}
        onChange={event => refine(event.currentTarget.value)}
        onClick={() => {
          if (this.state.closeModal) {
            this.setState({ closeModal: false, focus: true });
          }
        }}
        inputProps={{ 'aria-label': 'Search' }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon className={classes.searchIcon} />
          </InputAdornment>
        }
        style={{ width: '100%' }}
      />
    </Grid>
  );

  handleCloseModal = () => {
    // adding the condition as if you click anywhere in the page, it will always trigger
    if (!this.state.closeModal) {
      this.setState({ closeModal: true, inputValue: '', focus: false });
    }
  };

  changeState = ({ name, value }) => this.setState({ [name]: value });

  render() {
    const { classes } = this.props;
    const CustomSearchBox = connectSearchBox(this.SearchBox);
    const CustomResults = this.searchResults;
    return (
      <ClickAwayListener onClickAway={() => this.handleCloseModal()} mouseEvent="onMouseDown">
        <InstantSearch indexName={defaultSearchIndex} searchClient={this.searchClient} showReset>
          <Grid
            id="globalSearch"
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <CustomSearchBox classes={classes} />
            </Grid>
            <CustomClearRefinements clearsQuery classes={classes} changeState={this.changeState} />
          </Grid>
          <CustomResults />
        </InstantSearch>
      </ClickAwayListener>
    );
  }
}

export default withStyles(styles, { withTheme: true })(React.memo(SearchComponent));
