import gql from 'graphql-tag';

export const SA_MAINTENANCE_LIST_DATA = gql`
  query serviceAgreementMaintenanceList(
    $serviceAgreementId: String!
    $filter: GenericFilterInput
    $sorting: [SortingInput]
    $pagination: PaginationInput
  ) {
    serviceAgreementMaintenanceListData(
      serviceAgreementId: $serviceAgreementId
      filter: $filter
      sorting: $sorting
      pagination: $pagination
    ) {
      meta
      items
      totalRecordCount
    }
  }
`;
