export const visitListMeta = [
  {
    id: 'visitNumber',
    filterKey: 'visitNumber',
    filterType: 'number',
    label: 'Visit Number'
  },
  {
    id: 'departmentName',
    label: 'Department'
  },
  {
    id: 'description',
    label: 'Description',
    maxTextLen: 50
  },
  {
    id: 'primaryTechs.items[0].mappedEntity.name',
    disableSort: true,
    type: 'text',
    label: 'Primary Tech'
  },
  {
    id: 'submittedDate',
    label: 'Visit Completed Date',
    type: 'dateOnly'
  }
];
