import { bundleIndex } from 'constants/algoliaIndex';
import { onItemCalcChange } from 'utils/onCalcChange';

export const PurchasedItemSgtLayout = (
  filter,
  formData,
  isPriceBookEnabled,
  handleOnItemSelection,
  marginEnabled
) => {
  const rowOptions = { direction: 'row', margin: [15, 0, 0, 0] };
  const horizontalSpacing = 20;
  return {
    fields: {},
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0,
          width: 750
        },
        contents: [
          {
            // row 0 - Receipt Image
            options: {
              ...rowOptions,
              marginTop: 0,
              alignItems: 'flex-end'
            },
            contents: formData.includeImage
              ? [
                  {
                    contents: [
                      {
                        component: 'ImageControl',
                        source: 's3Url',
                        options: {
                          maxWidth: 750,
                          maxHeight: 300
                        }
                      }
                    ]
                  }
                ]
              : []
          },
          {
            // row 1 - Product Name, Department
            options: {
              ...rowOptions,
              marginTop: formData.includeImage ? 15 : 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  marginRight: horizontalSpacing
                },
                contents: isPriceBookEnabled
                  ? [
                      {
                        options: {
                          label: 'Product',
                          required: true,
                          placeholder: 'Search products...',
                          searchIndexName: bundleIndex,
                          searchResultDisplayFields: ['name', 'code'],
                          delimiter: ' - ',
                          setItemName: 0,
                          filters: [
                            {
                              attribute: 'entityType',
                              valueArray: filter.entityType
                            },
                            {
                              attribute: 'priceBookId',
                              valueArray: filter.priceBookId
                            }
                          ],
                          onChange: handleOnItemSelection
                        },
                        component: 'AlgoliaSearchWrapper',
                        source: 'itemName'
                      }
                    ]
                  : [
                      {
                        options: {
                          label: 'Product',
                          required: true
                        },
                        source: 'itemName',
                        component: 'TextInput'
                      }
                    ]
              },
              {
                contents: [
                  {
                    options: {
                      label: 'Department',
                      disabled: true
                    },
                    component: 'FieldWithLabel',
                    source: 'departmentName'
                  }
                ]
              }
            ]
          },
          {
            // row 2 - Description
            options: rowOptions,
            contents: [
              {
                options: { direction: 'column' },
                contents: [
                  {
                    options: {
                      label: 'Description',
                      lines: 3
                    },
                    component: 'TextInput',
                    source: 'description'
                  }
                ]
              }
            ]
          },
          {
            // row 3 - Unit Cost, MarkupValue, Unit Price
            options: rowOptions,
            contents: [
              {
                options: { direction: 'column', marginRight: horizontalSpacing },
                contents: [
                  {
                    options: {
                      label: 'Quantity',
                      isRequired: true,
                      type: 'numeric',
                      onChange: onItemCalcChange,
                      endAdornmentRef: 'unitOfMeasure',
                      endAdornmentText: 'units'
                    },
                    component: 'TextInput',
                    source: 'quantity'
                  }
                ]
              },
              {
                options: { direction: 'column', marginRight: horizontalSpacing },
                contents: [
                  {
                    options: {
                      label: 'Unit Cost',
                      isRequired: true,
                      onChange: onItemCalcChange
                    },
                    component: 'CurrencyInput',
                    source: 'unitCost'
                  }
                ]
              },
              marginEnabled
                ? {
                    options: { direction: 'column', marginRight: horizontalSpacing },
                    contents: [
                      {
                        options: {
                          label: 'Margin',
                          type: 'numeric',
                          endAdornmentText: '%', // we only support % as of now
                          onChange: onItemCalcChange
                        },
                        component: 'TextInput',
                        source: 'marginValue'
                      }
                    ]
                  }
                : {
                    options: { direction: 'column', marginRight: horizontalSpacing },
                    contents: [
                      {
                        options: {
                          label: 'Markup',
                          type: 'numeric',
                          endAdornmentText: '%', // we only support % as of now
                          onChange: onItemCalcChange
                        },
                        component: 'TextInput',
                        source: 'markupValue'
                      }
                    ]
                  },
              {
                options: { direction: 'column' },
                contents: [
                  {
                    options: {
                      label: 'Unit Price',
                      isRequired: true,
                      onChange: onItemCalcChange
                    },
                    component: 'CurrencyInput',
                    source: 'unitPrice'
                  }
                ]
              }
            ]
          },
          // row 4 - Cost Code, Cost Type, Revenue Type
          {
            options: rowOptions,
            contents: [
              {
                options: { direction: 'column', marginRight: horizontalSpacing },
                contents: [
                  {
                    options: {
                      label: 'Cost Code',
                      marginRight: 10,
                      placeholder: 'Select...',
                      inputOptions: formData.costCodes
                    },
                    component: 'SelectInput',
                    source: 'costCodeId'
                  }
                ]
              },
              {
                options: { direction: 'column', marginRight: horizontalSpacing },
                contents: [
                  {
                    options: {
                      label: 'Cost Type',
                      marginRight: 10,
                      placeholder: 'Select...',
                      inputOptions: formData.costTypes
                    },
                    component: 'SelectInput',
                    source: 'jobCostTypeId'
                  }
                ]
              },
              {
                options: { direction: 'column' },
                contents: [
                  {
                    options: {
                      label: 'Revenue Type',
                      marginRight: 10,
                      placeholder: 'Select...',
                      inputOptions: formData.revenueTypes
                    },
                    component: 'SelectInput',
                    source: 'revenueTypeId'
                  }
                ]
              }
            ]
          },
          {
            // row 5 - Total Amount, Taxable
            options: rowOptions,
            contents: [
              {
                options: { direction: 'column', marginRight: horizontalSpacing },
                contents: [
                  {
                    options: {
                      label: 'Total Amount',
                      isRequired: true,
                      onChange: onItemCalcChange,
                      disabled: true
                    },
                    component: 'CurrencyInput',
                    source: 'amount'
                  }
                ]
              },
              {
                options: {
                  direction: 'column',
                  marginRight: horizontalSpacing,
                  alignSelf: 'center'
                },
                contents: [
                  {
                    options: {
                      label: 'Taxable'
                    },
                    component: 'CheckboxInput',
                    source: 'taxable'
                  }
                ]
              },
              {
                options: { direction: 'column' },
                contents: []
              }
            ]
          }
        ]
      }
    }
  };
};

export default PurchasedItemSgtLayout;
