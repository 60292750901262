import { gql } from '@apollo/client/core';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { getState } from 'redux/store';
import mergeDeep from 'utils/mergeDeep';

import { GET_REVIEW_REPORT_BY_ID } from '../queries';

const DELETE_INVENTORY_PART = gql`
  mutation deleteInventoryPart($partitionKey: String!, $id: String!) {
    deleteInventoryPart(partitionKey: $partitionKey, id: $id) {
      id
      itemName
    }
  }
`;

const optimisticResponseFactory = ({ inventoryItemId }) => ({
  deleteInventoryPart: {
    id: inventoryItemId,
    itemName: 'Weld',
    __typename: 'InventoryPart'
  }
});

export const useDeleteInventoryItem = reviewReportId => {
  return useExtendedMutation(DELETE_INVENTORY_PART, {
    serializer: ({ inventoryItemId }) => ({
      variables: {
        id: inventoryItemId,
        partitionKey: getState()?.company?.partitionKey
      }
    }),
    showConfirmation: true,
    confirmationModalOptions: {
      title: 'Delete Inventory Item',
      warn: true,
      modalText: 'Are you sure you want to delete this inventory item?'
    },
    optimisticResponseFactory,
    update: (cache, { data: { deleteInventoryPart } }) => {
      const query = {
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        }
      };

      const cachedData = cache.readQuery(query);

      const newItems = cachedData.getReviewReportById.inventoryParts.items.filter(
        part => part.id !== deleteInventoryPart.id
      );

      const update = { getReviewReportById: { inventoryParts: { items: newItems } } };

      cache.writeQuery({ ...query, data: mergeDeep(cachedData, update) });
    }
  });
};
