export const lineItemTemplate = {
  employeeId: null,
  labourTypeId: null,
  jobCostTypeId: null,
  revenueTypeId: null,
  cost: 0
};

export const maxRecordCount = 1000;

export const temporaryWorkMessageLabourRateSheetChange =
  'Must save temporary work before changing labour rate sheet';

export const emptyFieldMessageForDelete = 'Must save temporary work before deleting this item';

export const emptyFieldMessage = 'Unable to save the line item without job costing fields';

export const defaultHourRateId = 'eaf5fe6b-4fe4-46e2-9531-81947cf40f5b';

export const unUsedLineItemProperties = ['version', '__typename', 'createdDate', 'createdDateTime'];
