import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  withoutFilters: {
    color: theme.palette.grayscale(60),
    border: `1px solid ${theme.palette.grayscale(60)}`,
    '&:hover': {
      border: `1px solid ${theme.palette.grayscale(60)}`
    }
  },
  withFilters: {
    color: theme.palette.grayscale(20),
    border: `2px solid ${theme.palette.grayscale(20)}`,
    '&:hover': {
      border: `2px solid ${theme.palette.grayscale(20)}`
    }
  }
}));
