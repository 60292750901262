export const LABOUR_RATE_FIELDS = [
  'billingHourTypeId',
  'costCodeId',
  'labourTypeId',
  'revenueTypeId'
];

export const AGREEMENT_STATUS_LABELS = {
  DRAFT: 'Draft',
  APPROVE: 'Approved',
  ACTIVATE: 'Active',
  EXPIRE: 'Expired',
  CANCEL: 'Canceled'
};

export const ERROR_MESSAGES = [
  'Min range value must be less than Max range value',
  'Please enter unique range values',
  'Markup value must be less than equal 100',
  'End date must be greater than start date',
  'Billing customer id must be required field ',
  'Valid start date is required to approve agreement',
  'First bill date should be after start date'
];

export const AGREEMENT_ACTIONS = {
  UPDATE: 'UPDATE',
  APPROVE: 'APPROVE',
  ACTIVATE: 'ACTIVATE',
  EXPIRE: 'EXPIRE',
  CANCEL: 'CANCEL',
  CLONE: 'CLONE',
  RENEWAL: 'RENEWAL',
  UPDATE_INVOICE_SETTINGS: 'UPDATE_INVOICE_SETTINGS',
  GENERATE_MAINTENANCE_RECORDS: 'GENERATE_MAINTENANCE_RECORDS'
};

export const SUBMIT_BUTTON_LABELS = {
  edit: 'Activate Agreement',
  view: 'Edit Agreement'
};

export const MAX_FETCH_MORE = 3;

export const ATTACHMENT_LABELS = {
  CONTRACT: 'Add Contract',
  ATTACHMENT_FIRST: 'Attach Documents',
  ATTACHMENT_NEXT: 'Add Another Attachment'
};

export const BILLING_TYPES = {
  OneTimePayment: 'One Time Payment',
  RecurringBilling: 'Recurring Billing',
  BillOnServiceCompletion: 'Bill On Service Completion'
};
