import gql from 'graphql-tag';

const getSageJobs = gql`
  query getSageJobs($partitionKey: String!, $sortKey: String!, $nextToken: String) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      sageJobs(
        entityConnection: "SageJob"
        filter: { isActive: { eq: true } }
        nextToken: $nextToken
      ) {
        items {
          id
          code
          isActive
          name
          parentCode
        }
      }
    }
  }
`;

export default getSageJobs;
