/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import { MUIForm } from '@buildhero/sergeant';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import differenceWith from 'lodash/differenceWith';
import eq from 'lodash/eq';
import find from 'lodash/find';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DefaultButton from 'components/Buttons/DefaultButton';
import FullScreenModal from 'components/FullScreenModal';
import ResponsiveTable from 'components/ResponsiveTable';
import Labels from 'meta/labels';
import {
  createSubmittalPackageFields,
  createSubmittalPackageLayout
} from 'meta/ProjectManagement/Submittals/createSubmittalPackageForm';
import submittalItemRegistryCustomRowMeta from 'meta/ProjectManagement/Submittals/submittalItemRegistryCustomTable';
import submittalItemRegistryRowMeta from 'meta/ProjectManagement/Submittals/submittalItemRegistryTable';
import { snackbarOn } from 'redux/actions/globalActions';

import SearchBar from 'scenes/ProjectManagement/components/APISearchComponents/SearchBar';
import AttachmentsV1 from 'scenes/ProjectManagement/components/Attachments';
import AttachmentsV2 from 'scenes/ProjectManagement/components/AttachmentsV2';
import buildHeroMuiFormOverrides from 'scenes/ProjectManagement/components/buildHeroMuiFormOverrides';
import CustomFieldWithLabel from 'scenes/ProjectManagement/components/CustomFieldWithLabel';
import { generateDefaultValidationSchema } from 'scenes/ProjectManagement/components/formattingUtils';
import { SubmittalsPackageStatus } from 'scenes/ProjectManagement/constants';
import {
  getSubmittalItemById,
  getSubmittalItems,
  submittalItemChange
} from 'services/API/submittalItem';
import {
  getNextNumber,
  submittalPackageChange,
  submittalPackageCreate
} from 'services/API/submittalPackage';
import { submittalPackageSubmittalItemDelete } from 'services/API/submittalPackageSubmittalItem';
import { truncateString } from 'utils';
import { ProjectManagementSubmittalStatus } from 'utils/AppConstants';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

const useStyles = makeStyles(theme => ({
  root: {},
  heading: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: '-0.03em',
    marginRight: 20
  },
  addButton: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '14px',
    textDecoration: 'none',
    paddingLeft: 4
  },
  attachmentsContainer: {
    marginTop: '25px',
    left: 0
  },
  itemRegistryContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16
  },
  addRegistryItemBtn: {
    marginTop: 20
  },
  addSelectedBtn: {
    float: 'right',
    marginTop: 20,
    paddingLeft: 5
  },
  hideItemRegistryTable: {
    display: 'none'
  },
  customStatusContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    margin: 0
  },
  status: {
    padding: '4px 8px',
    textAlign: 'center',
    minWidth: 85
  },
  statusPending: {
    backgroundColor: '#F0CEAF'
  },
  statusPackaged: {
    backgroundColor: '#C9D8FF'
  },
  statusSent: {
    backgroundColor: theme.palette.grayscale(80)
  },
  statusRejected: {
    backgroundColor: '#E8AAAA'
  },
  statusApproved: {
    backgroundColor: '#BAE4B6'
  },
  formContainer: buildHeroMuiFormOverrides(theme)
}));

const simpleRowActionButtons = {
  remove: {
    label: 'Remove',
    icon: 'Delete',
    caslAction: 'delete',
    caslKey: ''
  }
};

const CustomFieldWithLabelStyled = ({ field, options }) => {
  return (
    <CustomFieldWithLabel
      field={field}
      options={options}
      style={{ background: '#f0f0f0', color: '#999999', padding: '1px 8px' }}
    />
  );
};

const CreateSubmittalPackage = props => {
  const {
    initialData,
    editMode,
    open,
    projectId,
    projectData,
    user,
    handleClose,
    snackbar
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const [formService, setFormService] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isItemDeleted, setIsItemDeleted] = useState(false);
  const [submittalItems, setSubmittalItems] = useState([]);
  const [showItemRegistryTable, setShowItemRegistryTable] = useState(true);
  const [nextNumber, setNextNumber] = useState('');
  const [addedSubmittalPackageItems, setAddedSubmittalPackageItems] = useState([]);
  const [livePackage, setLivePackage] = useState({
    submittalPackageSubmittalItems: [],
    submittalPackageAttachments: []
  });

  const updatePackageStatus = (items, prevStatus) => {
    if (
      items?.length &&
      items.every(item => item.status === ProjectManagementSubmittalStatus.APPROVED)
    ) {
      return prevStatus === SubmittalsPackageStatus.APPROVED_AS_NOTED
        ? prevStatus
        : SubmittalsPackageStatus.APPROVED;
    }
    return prevStatus;
  };

  const sanitizeData = data => {
    return {
      projectId,
      number: data?.number,
      version: data?.version,
      subject: truncateString(data?.subject, 250),
      dueDate: data?.dueDate ?? moment().unix(),
      status: updatePackageStatus(livePackage.submittalPackageSubmittalItems, data?.status) || null,
      sendToId: data?.SendTo?.id || null,
      sendToName: data?.SendTo?.name || null,
      returnToId: data?.ReturnTo?.id || null,
      returnToName: data?.ReturnTo?.name || null,
      specSection: data?.specSection || null,
      specDescription: data?.specDescription || null,
      submittalPackageSubmittalItems: livePackage.submittalPackageSubmittalItems.map(item => ({
        ...item,
        id: item?.id || null,
        submittalItemId: item?.submittalItemId,
        status: item?.status || null
      })),
      submittalPackageAttachments: livePackage.submittalPackageAttachments.map(file => ({
        ...file,
        id: file?.id || null,
        customFileName: file?.customFileName || null,
        fileName: file?.fileName || null,
        fileType: file?.fileType || null,
        fileUrl: file?.fileUrl || null
      }))
    };
  };

  const sanitizeSubmittalItemData = data => {
    return data
      .filter(item => item.status === ProjectManagementSubmittalStatus.TO_BE_SUBMITTED)
      .map((item, index) => {
        return {
          ...item,
          lineNumber: index + 1
        };
      });
  };

  const getFormattedData = data => {
    if (!data) return {};

    return {
      number: data?.number || nextNumber,
      version: editMode ? `${parseInt(data?.version, 10) + 1}` : data?.version || '1',
      subject: data?.subject || '',
      status: data?.status || SubmittalsPackageStatus.DRAFT,
      dueDate: data?.dueDate,
      SendTo: data?.SendTo,
      ReturnTo: data?.ReturnTo,
      specSection: data?.specSection || '',
      specDescription: data?.specDescription || '',
      submittalPackageSubmittalItems: data?.submittalPackageSubmittalItems || [],
      submittalPackageAttachments: data?.submittalPackageAttachments || []
    };
  };

  const getDeletedSubmittalItemLists = (orgList, currentList) => {
    if (!orgList || !orgList?.length) return [];
    if (!currentList || !currentList?.length) return orgList;

    const remainedItems = currentList.filter(item => item.id);
    return orgList.filter(orgItem => {
      return !remainedItems.some(currentItem => currentItem.id === orgItem.id);
    });
  };

  const getAllSubmittals = () => {
    getSubmittalItems(projectId).then(items => {
      let notSelectedItemList;

      if (editMode) {
        const packageItems = initialData?.itemData?.submittalPackageSubmittalItems?.map(
          packageItem => {
            return {
              ...find(items, { id: packageItem.submittalItemId }),
              id: packageItem.id || null,
              submittalItemId: packageItem.submittalItemId
              // Temporarily commented out to show item's original status
              // status: packageItem.status
            };
          }
        );
        setLivePackage({
          submittalPackageSubmittalItems: packageItems,
          submittalPackageAttachments: initialData?.itemData?.submittalPackageAttachments
        });

        // When items are added to a package, don't show it as a selection when editing that the same submittal package
        notSelectedItemList = differenceWith(
          items,
          initialData?.itemData?.submittalPackageSubmittalItems,
          ({ id }, { submittalItemId }) => eq(id, submittalItemId)
        );
      }
      setSubmittalItems(sanitizeSubmittalItemData(editMode ? notSelectedItemList : items));
    });
  };

  useEffect(() => {
    getNextNumber(projectId).then(number => setNextNumber(number));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllSubmittals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, open]);

  const handleUploadFileList = attachedList => {
    setLivePackage({
      ...livePackage,
      submittalPackageAttachments: attachedList
    });
  };

  const handleSelect = (mode, items) => {
    if (mode === 'select') {
      const updatedStatus = items.map(item => {
        return {
          ...item,
          id: null,
          submittalItemId: item.id,
          status:
            item.status === ProjectManagementSubmittalStatus.REJECTED
              ? ProjectManagementSubmittalStatus.REJECTED
              : ProjectManagementSubmittalStatus.PACKAGED
        };
      });
      setAddedSubmittalPackageItems(updatedStatus);
    }
  };

  const handleRowactions = async (actionType, record) => {
    if (actionType === 'remove') {
      const filteredItems = livePackage.submittalPackageSubmittalItems.filter(
        item => item.submittalItemId !== record.submittalItemId
      );

      setIsItemDeleted(true);
      setLivePackage({
        ...livePackage,
        submittalPackageSubmittalItems: [...filteredItems]
      });
    } else if (actionType === 'edit') {
      // TODO: check how to edit the selected data: record
    }
  };

  const handleAddSelected = () => {
    const newlyAddedItems = addedSubmittalPackageItems.filter(item =>
      livePackage.submittalPackageSubmittalItems.every(
        addedItem => addedItem.submittalItemId !== item.submittalItemId
      )
    );

    setLivePackage({
      ...livePackage,
      submittalPackageSubmittalItems: [
        ...(livePackage.submittalPackageSubmittalItems || []),
        ...newlyAddedItems
      ]
    });
    setShowItemRegistryTable(false);
  };

  const initializeAllStates = () => {
    setIsSubmitting(false);
    setSubmittalItems([]);
    setShowItemRegistryTable(true);
    setIsItemDeleted(false);
    setAddedSubmittalPackageItems([]);
    setLivePackage({
      submittalPackageSubmittalItems: [],
      submittalPackageAttachments: []
    });
  };

  const handleCloseButton = () => {
    initializeAllStates();
    handleClose();
  };

  const handleSubmit = async data => {
    setIsSubmitting(true);

    const payload = sanitizeData(data);
    let createdPackage;

    // change all submittal item's status
    if (payload.submittalPackageSubmittalItems?.length) {
      await Promise.all(
        payload.submittalPackageSubmittalItems.map(async item => {
          await submittalItemChange(item.submittalItemId, item);
        })
      );
    }

    if (editMode) {
      if (isItemDeleted) {
        const deletedItems = getDeletedSubmittalItemLists(
          initialData?.itemData?.submittalPackageSubmittalItems,
          livePackage.submittalPackageSubmittalItems
        );

        // Delete the SubmittalPackageSubmittalItem, and then check if the SubmittalItem belongs to any
        // other Packages. Set its status back to "To be Submitted" if it doesn't belong to other Packages.
        await Promise.all(
          deletedItems.map(packageItem => {
            return submittalPackageSubmittalItemDelete(packageItem.id).then(() => {
              getSubmittalItemById(packageItem.submittalItemId).then(submittalItem => {
                if (submittalItem?.submittalPackages && !submittalItem?.submittalPackages.length) {
                  submittalItemChange(submittalItem.id, {
                    ...submittalItem,
                    status: ProjectManagementSubmittalStatus.TO_BE_SUBMITTED
                  });
                }
              });
            });
          })
        );
      }
      await submittalPackageChange(initialData.itemData?.id, payload);
      snackbar('success', 'Updated successfully');
    } else {
      createdPackage = await submittalPackageCreate(payload);
      snackbar('success', 'Posted successfully');
    }

    initializeAllStates();
    if (editMode) {
      handleClose();
    } else {
      history.push(`/project/view/${projectId}/submittal/${createdPackage.id}`);
    }
  };

  // eslint-disable-next-line no-shadow
  const CustomStatusInput = props => {
    const { record } = props;
    const [status, setStatus] = useState(record?.status);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
      setAnchorEl(null);
    };

    const handleChangeStatus = changedStatus => {
      setAnchorEl(null);
      setStatus(changedStatus);

      // update each item's status if user changes item's status
      const updatedItems = livePackage.submittalPackageSubmittalItems.map(item => {
        return {
          ...item,
          submittalItemId: initialData?.itemData?.submittalPackageSubmittalItems.filter(
            element => element.id === item.id
          )[0].submittalItemId,
          status: item.id === record.id ? changedStatus : item.status
        };
      });

      setLivePackage({
        ...livePackage,
        submittalPackageSubmittalItems: [...updatedItems]
      });
    };

    return (
      <div className={classes.customStatusContainer}>
        <div
          className={classNames(
            classes.status,
            status === ProjectManagementSubmittalStatus.PENDING && classes.statusPending,
            status === ProjectManagementSubmittalStatus.PACKAGED && classes.statusPackaged,
            status === ProjectManagementSubmittalStatus.SENT && classes.statusSent,
            status === ProjectManagementSubmittalStatus.REJECTED && classes.statusRejected,
            status === ProjectManagementSubmittalStatus.APPROVED && classes.statusApproved
          )}
        >
          {status}
        </div>
        {editMode && initialData.itemData?.status !== SubmittalsPackageStatus.DRAFT ? (
          <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            <KeyboardArrowDownIcon fontSize="inherit" />
          </IconButton>
        ) : null}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <MenuItem onClick={() => handleChangeStatus(ProjectManagementSubmittalStatus.REJECTED)}>
            {ProjectManagementSubmittalStatus.REJECTED}
          </MenuItem>
          <MenuItem onClick={() => handleChangeStatus(ProjectManagementSubmittalStatus.APPROVED)}>
            {ProjectManagementSubmittalStatus.APPROVED}
          </MenuItem>
        </Menu>
      </div>
    );
  };

  const formattedData = getFormattedData(initialData.itemData);

  const Attachments = useFlags()[FeatureFlags.PM_FILE_SYSTEM] ? AttachmentsV2 : AttachmentsV1;

  return (
    <FullScreenModal
      title={
        editMode
          ? Labels.editSubmittalPackage[user.locale]
          : Labels.createSubmittalPackage[user.locale]
      }
      modalHeaderButtons={[
        <DefaultButton
          label={Labels.savePackage[user.locale]}
          variant="containedPrimary"
          key="saveSubmittalPackageBtn"
          disabled={isSubmitting}
          showSpinner={isSubmitting}
          onClick={() => formService.submit()}
        />
      ]}
      open={open}
      handleClose={handleCloseButton}
    >
      <div className={classes.formContainer}>
        <MUIForm
          configuration={createSubmittalPackageLayout(projectData)}
          data={formattedData}
          layout={editMode ? 'edit' : 'default'}
          onCreateService={service => setFormService(service)}
          onComplete={finalizeData => handleSubmit(finalizeData)}
          customComponents={{ CustomFieldWithLabelStyled, SearchBar }}
          validationSchema={generateDefaultValidationSchema(createSubmittalPackageFields)}
        />
      </div>
      {livePackage.submittalPackageSubmittalItems?.length > 0 && (
        <>
          <Typography className={classes.heading}>Submittal Package Items</Typography>
          <ResponsiveTable
            disableFilter
            rowMetadata={submittalItemRegistryCustomRowMeta}
            data={livePackage.submittalPackageSubmittalItems}
            noDataMsg="No Submittal Package Items"
            tableName="ProjectManagementSubmittalPackageItems"
            customCellComponents={{ CustomStatusInput }}
            rowActionButtons={simpleRowActionButtons}
            rowActions={handleRowactions}
          />
          <div className={classes.addRegistryItemBtn}>
            <Button
              startIcon={<AddCircleOutlineIcon />}
              className={classes.addButton}
              onClick={() => setShowItemRegistryTable(!showItemRegistryTable)}
            >
              Add Registry Item
            </Button>
          </div>
        </>
      )}
      <div className={classNames(!showItemRegistryTable && classes.hideItemRegistryTable)}>
        <div className={classes.itemRegistryContainer}>
          <Typography className={classes.heading}>Item Registry</Typography>
          {/* TODO: search function */}
          {/* <CustomSearchBar key="submittalsPackagesSearch" placeholder="Search Items" /> */}
          <div />
        </div>
        <ResponsiveTable
          disableFilter
          rowMetadata={submittalItemRegistryRowMeta}
          data={submittalItems}
          noDataMsg="No Submittal Items"
          tableName="ProjectManagementSubmittalItems"
          rowActionButtons={{ select: true }}
          rowActions={handleSelect}
        />
        <div className={classes.addSelectedBtn}>
          <DefaultButton
            label={Labels.addSelectedItems[user.locale]}
            variant="containedSecondary"
            style={{ height: 30, fontSize: 12 }}
            key="addSelectedItemsBtn"
            disabled={addedSubmittalPackageItems.length === 0}
            onClick={handleAddSelected}
            buttonProps={{ startIcon: <AddCircleOutlineIcon style={{ fontSize: 14 }} /> }}
          />
        </div>
      </div>
      <div className={classes.attachmentsContainer}>
        <Attachments
          attachedFiles={editMode ? initialData.itemData?.submittalPackageAttachments : []}
          attachedFileCount={
            editMode ? initialData.itemData?.submittalPackageAttachments?.length : 0
          }
          smallType
          downloadable={editMode}
          uploadFileList={handleUploadFileList}
        />
      </div>
    </FullScreenModal>
  );
};

CustomFieldWithLabelStyled.propTypes = {
  field: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

CreateSubmittalPackage.propTypes = {
  initialData: PropTypes.object,
  editMode: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  projectData: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  snackbar: PropTypes.func.isRequired
};

CreateSubmittalPackage.defaultProps = {
  initialData: {}
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  snackbar: (mode, message) => dispatch(snackbarOn(mode, message))
});
const ReduxConnectedCreateSubmittalPackage = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSubmittalPackage);
export default ReduxConnectedCreateSubmittalPackage;
