import gql from 'graphql-tag';

const upsertPaymentTerm = gql`
  mutation upsertPaymentTerm($partitionKey: String, $data: UpsertPaymentTermInput!) {
    upsertPaymentTerm(partitionKey: $partitionKey, data: $data) {
      id
      name
      value
      type
      lastUpdatedDateTime
    }
  }
`;

export default upsertPaymentTerm;
