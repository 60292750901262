import React from 'react';

import { Button, Modal, ThemeProvider, TV, TW, Typography } from '@buildhero/sergeant';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { PropTypes } from 'prop-types';

import StatusChip from 'components/StatusChip';

const ManulStatusChangeModal = ({
  title,
  statusEnumType,
  data,
  open,
  handleClose,
  handleSubmit
}) => {
  const useStyles = makeStyles(theme => ({
    procurementStatusModalContent: {
      fontSize: '16px',
      lineHeight: '22px',
      '&:first-child': {
        marginBottom: theme.spacing(2)
      }
    },
    procurementStatusModalHR: {
      margin: `${theme.spacing(3)}px 0px`,
      backgroundColor: '#E6E6E6',
      height: '1px',
      border: 'none'
    },
    procurementStatusModalButton: {
      width: '100%'
    },
    procurementStatusModalBtnContainer: {
      width: '100%',
      margin: theme.spacing(0)
    }
  }));
  const classes = useStyles();

  return (
    data && (
      <ThemeProvider>
        <Modal
          open={open}
          title={title}
          onClose={() => {
            handleClose(true);
          }}
          PaperProps={{ style: { minWidth: 454 } }}
        >
          <Typography
            className={classes.procurementStatusModalContent}
            variant={TV.BASE}
            weight={TW.REGULAR}
          >
            The associated job was manually set to{' '}
            <StatusChip
              label={data?.manualStatus}
              noLabelFormat
              enumType={statusEnumType}
              enumValue={data?.manualStatus}
              css={{ borderRadius: 0 }}
            />
          </Typography>
          <Typography
            className={classes.procurementStatusModalContent}
            variant={TV.BASE}
            weight={TW.REGULAR}
          >
            Do you want to update it to{' '}
            <StatusChip
              label={data?.automatedStatus}
              noLabelFormat
              enumType={statusEnumType}
              enumValue={data?.automatedStatus}
              css={{ borderRadius: 0 }}
            />{' '}
            ?
          </Typography>
          <hr className={classes.procurementStatusModalHR} />
          <Grid
            container
            direction="row"
            justify="space-evenly"
            spacing={1}
            className={classes.procurementStatusModalBtnContainer}
          >
            <Grid item xs={12} md={6} style={{ padding: '0 4px' }}>
              <Button
                type="tertiary"
                className={classes.procurementStatusModalButton}
                onClick={e => {
                  e.preventDefault();
                  handleClose();
                }}
              >
                DO NOT UPDATE
              </Button>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: '0 4px' }}>
              <Button
                color="secondary"
                variant="contained"
                className={classes.procurementStatusModalButton}
                onClick={e => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                UPDATE STATUS
              </Button>
            </Grid>
          </Grid>
        </Modal>
      </ThemeProvider>
    )
  );
};

ManulStatusChangeModal.propTypes = {
  title: PropTypes.string.isRequired,
  statusEnumType: PropTypes.string.isRequired,
  data: PropTypes.shape({
    automatedStatus: PropTypes.string,
    manualStatus: PropTypes.string
  }),
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func
};

ManulStatusChangeModal.defaultProps = {
  data: null,
  open: false,
  handleClose: () => {},
  handleSubmit: () => {}
};
export default ManulStatusChangeModal;
