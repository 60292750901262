import gql from 'graphql-tag';

export const getCompanyExternalMessagesQuery = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      externalMessages {
        items {
          id
          name
          type
          message
          version
          externalMessageDepartments {
            items {
              id
              department {
                id
                tagName
                version
              }
            }
          }
        }
      }
    }
  }
`;

export const getCompanyDepartmentsQuery = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      departments: departmentsView(
        entityConnection: "Department"
        filter: {
          integerFilters: [{ fieldName: "Department.deletedDate", filterInput: { is: null } }]
        }
      ) {
        items {
          id
          tagName
        }
      }
    }
  }
`;

export const addExternalMessagesToCompanyMutation = gql`
  mutation addExternalMessagesToCompany(
    $partitionKey: String!
    $data: AddExternalMessagesToCompanyInput!
  ) {
    addExternalMessagesToCompany(partitionKey: $partitionKey, data: $data) {
      id
      version
    }
  }
`;

export const updateExternalMessageMutation = gql`
  mutation updateExternalMessage($partitionKey: String, $data: UpdateExternalMessageInput!) {
    updateExternalMessage(partitionKey: $partitionKey, data: $data) {
      id
      version
    }
  }
`;

export const deleteExternalMessageMutation = gql`
  mutation deleteExternalMessage($partitionKey: String!, $id: String!) {
    deleteExternalMessage(partitionKey: $partitionKey, id: $id) {
      id
    }
  }
`;
