import React, { useState, useCallback, useEffect } from 'react';
import { Box, Typography, Chip } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CreateVisitsForm from './CreateVisitForm';
import { getOverAllSummary } from './helper';

function Forms({
  techs,
  departments,
  data,
  formsList,
  crewItems,
  classes,
  getFormService,
  getHandleCreateService,
  getHandleComplete,
  dueDateValidation,
  setScheduledVisits,
  preferredTechniciansConfig
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [checkformCompeleted, setcheckformCompeleted] = useState([]);
  const [completedVisits, setcompletedVisits] = useState([]);
  const ExpandIcon = isExpanded ? ArrowDropUpIcon : ArrowDropDownIcon;
  const renderTags = item => (
    <Typography className={classes.tags} variant="body2">
      {item}
    </Typography>
  );

  const handlecheckformCompeleted = useCallback(() => {
    if (checkformCompeleted) {
      const completedVisitss = checkformCompeleted.filter(
        (value, index, visit) => visit.indexOf(value) === index
      );
      setcompletedVisits(completedVisitss);
      if (completedVisits.length > 0) {
        setScheduledVisits(prev => [...prev, data.maintenanceId]);
        setIsReady(true);
      } else {
        setIsReady(false);
      }
    }
  }, [checkformCompeleted, completedVisits.length, data.maintenanceId, setScheduledVisits]);

  useEffect(() => {
    handlecheckformCompeleted();
  }, [handlecheckformCompeleted]);

  const onFormCompleted = visitIndex => {
    setcheckformCompeleted(prev => [...prev, visitIndex]);
  };
  return (
    <>
      <Box
        display="flex"
        className={isReady ? classes.containerMarked : classes.container}
        p={1.5}
        pr={2}
        flex={1}
      >
        <ExpandIcon className={classes.expandIcon} onClick={() => setIsExpanded(!isExpanded)} />
        <Box display="flex" flex={1} alignItems="flex-start" flexDirection="column">
          <Box display="flex" width="100%" pb={0.5} alignItems="center">
            <Typography variant="body2" style={{ marginRight: '10px' }}>
              <b>{data.propertyName}</b>
            </Typography>
            <Typography className={classes.overAllCount} variant="body2">
              {data.jobTypeName || '-'}
            </Typography>
            <Chip
              size="small"
              className={classes.titleChip}
              avatar={<CalendarTodayIcon className={classes.scheduleIcon} />}
              label={data.interval || '-'}
            />
            <Box display="flex" flex={1} justifyContent="space-between">
              <Box display="flex">{data.tags.map(renderTags)}</Box>
              <Box display="flex">
                <Typography variant="body2">
                  {`Due Date: `}
                  <b>{data.dueDate || '-'}</b>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Typography className={classes.overAllCount} variant="body2">
            {getOverAllSummary(data)}
          </Typography>
          <div style={{ display: isExpanded ? 'contents' : 'none' }}>
            {Array(data.estimatedVisits)
              .fill()
              .map((key, index) => {
                const MuiformName = `form-${data.maintenanceId}`;
                return (
                  <Box display="flex" width="100%" flexDirection="column">
                    <Box display="flex" flex={1}>
                      <Box key={`${data.dueDate}-${MuiformName}`} className={classes.formContainer}>
                        <CreateVisitsForm
                          techs={techs}
                          departments={departments}
                          data={data}
                          preferredTechniciansConfig={preferredTechniciansConfig}
                          visitIndex={index + 1}
                          formsList={formsList}
                          onFormCompleted={onFormCompleted}
                          dueDateValidation={dueDateValidation}
                          crewItems={crewItems}
                          getHandleCreateService={getHandleCreateService}
                          getFormService={getFormService}
                          getHandleComplete={getHandleComplete}
                        />
                      </Box>
                    </Box>
                  </Box>
                );
              })}
          </div>
        </Box>
      </Box>
      {isReady ? (
        <div className={classes.scheduleTextContainer}>
          <CheckCircleIcon className={classes.checkCircleIcon} />
          <Typography className={classes.scheduleText} variant="body2">
            Ready to schedule {completedVisits.length} visit
          </Typography>
        </div>
      ) : (
        <div className={classes.scheduleTextContainer}>
          <WarningIcon className={classes.warningIcon} />
          <Typography className={classes.scheduleText} variant="body2">
            Incomplete
          </Typography>
        </div>
      )}
    </>
  );
}

export default Forms;
