const formActions = (onSave, onCancel) => ({
  cancel: {
    buttonType: 'contained',
    color: 'secondary',
    label: 'Cancel',
    action: onCancel
  },
  save: {
    buttonType: 'contained',
    color: 'primary',
    action: onSave,
    label: 'Save'
  }
});

export default formActions;
