import { groupBy, sumBy, isEmpty } from 'lodash';
import { convertNumberToFixed } from 'utils';

const calculateTotal = (items = [], itemKey) =>
  items.reduce((acc, item) => {
    const itemValue = item[itemKey] || 0;
    const total = convertNumberToFixed(acc + itemValue, 2);
    return Number(total);
  }, 0);

const getJobCostTypeItems = (reports, jobCostType) => {
  const groupedValues = groupBy(reports, jobCostType);
  const costTypeIds = Object.keys(groupedValues);
  const values = costTypeIds.reduce((acc, id) => {
    acc.push(groupedValues[id]);
    return acc;
  }, []);

  return values;
};

export const getRevenueReport = (reports = []) => {
  const revenues = getJobCostTypeItems(reports, 'revenueTypeId');
  const summary = revenues.reduce((acc, revenueItems) => {
    const [revenueValue] = revenueItems;
    const total = calculateTotal(revenueItems, 'revenue');
    acc.push({ name: revenueValue.revenueType, total });
    return acc;
  }, []);

  if (isEmpty(summary)) return [];
  // removing $0 - when items are invoiced, the cost related info will not be there
  // before invoicing the revenue related info will not be there
  const nonZeroCostSummary = summary.filter(item => item.total !== 0);
  const overAllTotal = sumBy(nonZeroCostSummary, 'total');

  return [...nonZeroCostSummary, { isOverAllTotal: true, total: overAllTotal }];
};

export const getCostReport = (reports = []) => {
  const totalRevenue = calculateTotal(reports, 'revenue');
  const costs = getJobCostTypeItems(reports, 'jobCostTypeId');

  const summary = costs.reduce((acc, costItems) => {
    const [costValue] = costItems;
    const total = calculateTotal(costItems, 'cost');
    const revenueTotal = totalRevenue ? convertNumberToFixed((total / totalRevenue) * 100, 2) : 0;
    acc.push({ name: costValue.jobCostType, revenueTotal, total });
    return acc;
  }, []);

  if (isEmpty(summary)) return [];
  // removing $0 - when items are invoiced, the cost related info will not be there
  // before invoicing the revenue related info will not be there
  const overAllTotal = sumBy(summary, 'total');
  const nonZeroCostSummary = summary.filter(item => item.total !== 0);
  const overAllRevenueTotal = calculateTotal(nonZeroCostSummary, 'revenueTotal');

  return [
    ...nonZeroCostSummary,
    { isOverAllTotal: true, total: overAllTotal, revenueTotal: overAllRevenueTotal }
  ];
};

export const getReportsByCostCode = reports => {
  const reportsGroupByCodeId = groupBy(reports, 'costCodeId');
  const processedReport = Object.values(reportsGroupByCodeId).map(costCodeItemArr => {
    if (costCodeItemArr?.length > 0) {
      const revenueTotal = sumBy(costCodeItemArr, 'revenue');
      const costTotal = sumBy(costCodeItemArr, 'cost');
      const profitTotal = sumBy(costCodeItemArr, 'profit');
      const grossMarginTotal = revenueTotal
        ? convertNumberToFixed((profitTotal / revenueTotal) * 100, 2)
        : -100;
      return {
        costCodeId: costCodeItemArr?.[0]?.costCodeId,
        costCode: costCodeItemArr?.[0]?.costCode,
        revenue: revenueTotal,
        cost: costTotal,
        profit: profitTotal,
        grossMargin: grossMarginTotal
      };
    }
  });
  return processedReport?.filter(item => !!item) || [];
};

export const calculateGross = (reports = []) => {
  const costTotal = sumBy(reports, 'cost');
  const revenueTotal = sumBy(reports, 'revenue');

  const grossProfit = revenueTotal - costTotal;
  const marginValue = grossProfit / revenueTotal;

  const grossMargin = revenueTotal ? convertNumberToFixed(marginValue * 100, 2) : -100;
  return { grossProfit: convertNumberToFixed(grossProfit, 2), grossMargin };
};
