import React, { useEffect, useState } from 'react';

import uuid from 'uuid';

import sum from 'lodash/sum';
import compact from 'lodash/compact';
import round from 'lodash/round';

import { Box, IconButton } from '@material-ui/core';
import {
  Input,
  PercentageInput,
  ThemeProvider,
  Label,
  Button,
  TV,
  TW,
  Typography
} from '@buildhero/sergeant';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';

import { AlgoliaSearch } from 'components';
import { bundleIndex } from 'constants/algoliaIndex';

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12
  },
  totalPercentage: {
    width: 246
  }
});

export const RecurringMaintenanceBilling = ({ options, field, form }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const [remainingPercentage, setRemainingPercentage] = useState(100);

  const recurringMaintenanceBillingItems = field.value || [];

  const hasAtLeastOneItem = recurringMaintenanceBillingItems.length > 0;
  const percentages = recurringMaintenanceBillingItems.map(({ percentage }) => Number(percentage));
  const totalPercentageSum = sum(percentages);
  const nonZeroPercentages = compact(percentages);
  const placeholderPercentage = round(
    remainingPercentage /
      (recurringMaintenanceBillingItems.length - nonZeroPercentages.length || 1), // use || 1 to avoid dividing by 0
    2
  );
  const { isTotalPercentageError, disableEdit = false } = options;

  useEffect(() => {
    setRemainingPercentage(100 - sum(percentages));
  }, [recurringMaintenanceBillingItems]);

  const handleAddItem = () => {
    const totalItems = recurringMaintenanceBillingItems?.length || 0;
    // picking only required fields
    const existingItems = recurringMaintenanceBillingItems.map(
      ({ id, name, description, percentage, sortOrder }) => ({
        id,
        name,
        description,
        percentage,
        sortOrder
      })
    );
    form.setFieldValue('recurringMaintenanceBillingItems', [
      ...existingItems,
      {
        id: uuid.v4(), // set random id, gets overwritten in handleChangeItem()
        percentage: null,
        sortOrder: totalItems
      }
    ]);
  };

  const handleDeleteItem = itemIndex => {
    // reassigning the sortOrder
    const remainingItems = recurringMaintenanceBillingItems.filter(
      (item, index) => index !== itemIndex
    );
    const sortOrderedItem = remainingItems.map((item, index) => {
      const locaItem = item;
      locaItem.sortOrder = index;
      return locaItem;
    });
    form.setFieldValue('recurringMaintenanceBillingItems', sortOrderedItem);
  };

  const handleSelectedItem = (item, i) => {
    const clone = [...recurringMaintenanceBillingItems];
    clone[i].id = item.id;
    clone[i].name = item.name;
    clone[i].description = item.description;
    clone[i].sortOrder = i;
    form.setFieldValue('recurringMaintenanceBillingItems', clone);
  };

  const handleChangeItem = (value, key, i) => {
    const clone = [...recurringMaintenanceBillingItems];
    // if not cloned, the object is readonly
    const { __typename, ...rowValue } = clone[i];
    rowValue[key] = value;
    rowValue.sortOrder = i;
    clone[i] = rowValue;
    form.setFieldValue('recurringMaintenanceBillingItems', clone);
  };

  return (
    <Box className={classes.container}>
      {recurringMaintenanceBillingItems.map((lineItemRow, i) => {
        return (
          <Box
            className={classes.rowContainer}
            key={lineItemRow.id}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box display="flex" flex={1} marginRight={2}>
              <Box display="flex" minWidth={250} flexDirection="column" flex={1}>
                {i === 0 && (
                  <ThemeProvider>
                    <Label label="Invoice Line Item" />
                  </ThemeProvider>
                )}
                <AlgoliaSearch
                  overrideVariant="standard"
                  greyBackground
                  disabled={disableEdit}
                  searchIndex={bundleIndex}
                  value={lineItemRow.name}
                  name="selectItem"
                  displayText={['name', 'description']}
                  delimiter=" "
                  locatedInPopUp
                  filters={[
                    {
                      attribute: 'entityType',
                      valueArray: ['Product']
                    }
                  ]}
                  handleChange={item => handleSelectedItem(item, i)}
                />
              </Box>
            </Box>
            <ThemeProvider>
              <Box display="flex" flex={1.5} marginRight={2}>
                <Box display="flex" flexDirection="column" flex={1}>
                  {i === 0 && <Label label="Line Item Description" />}
                  <Input
                    fullWidth
                    disabled={disableEdit}
                    value={lineItemRow.description}
                    className={classes.descriptionField}
                    variant="outlined"
                    onChange={e => handleChangeItem(e.target.value, 'description', i)}
                  />
                </Box>
              </Box>
              <Box display="flex" flex={1}>
                <Box display="flex" flexDirection="column" flex={1}>
                  {i === 0 && <Label label="Percentage of Term Price" />}
                  <PercentageInput
                    fullWidth
                    disabled={disableEdit}
                    value={lineItemRow.percentage}
                    placeholder={placeholderPercentage >= 0 ? placeholderPercentage : 0}
                    className={classes.percentageField}
                    onChange={pvalue => handleChangeItem(pvalue, 'percentage', i)}
                  />
                </Box>
              </Box>
            </ThemeProvider>
            <IconButton onClick={() => handleDeleteItem(i)} disabled={disableEdit}>
              <CloseIcon />
            </IconButton>
          </Box>
        );
      })}

      {hasAtLeastOneItem && (
        <ThemeProvider>
          <Box className={classes.rowContainer}>
            <Box minWidth={250} marginRight={2}>
              <Typography variant={TV.BASE} weight={TW.MEDIUM}>
                Total Percentage Allocated:
              </Typography>
            </Box>
            <PercentageInput
              disabled={disableEdit}
              error={isTotalPercentageError}
              helperText={isTotalPercentageError ? 'Total Percentage must add up to 100%' : ''}
              className={classes.totalPercentage}
              value={totalPercentageSum}
            />
          </Box>
        </ThemeProvider>
      )}
      <ThemeProvider>
        <Box>
          <Button
            type="leading"
            startIcon={<AddCircleOutline />}
            onClick={handleAddItem}
            disabled={disableEdit}
          >
            Add Invoice Line Item
          </Button>
        </Box>
      </ThemeProvider>
    </Box>
  );
};
