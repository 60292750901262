import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.grayscale(100),
    borderRadius: 4,
    padding: theme.spacing(0.95)
  },
  dateLabel: {
    color: theme.palette.text.primary,
    cursor: 'pointer',
    fontSize: '20px',
    fontWeight: 400,
    flex: 1
  },
  item: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1)
  }
}));
