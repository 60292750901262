import React, { useEffect, useMemo, useState } from 'react';
import { string, func, bool } from 'prop-types';
import moment from 'moment';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { IconButton } from '@material-ui/core';

import { Button, DateInput, ThemeProvider } from '@buildhero/sergeant';

import { useStyles } from './DaySelector.styles';

const DaySelector = ({ selectDay, day, weekView }) => {
  const classes = useStyles();
  const [selectedDay, setSelectedDay] = useState(moment());

  const isToday = useMemo(() => {
    return moment()
      .startOf('day')
      .isSame(selectedDay, 'day');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay]);

  useEffect(() => {
    setSelectedDay(moment(day));
  }, [day]);

  const handleNextDayClick = () => {
    selectDay(selectedDay.add(weekView ? 7 : 1, 'day'));
  };

  const handlePrevDayClick = () => {
    selectDay(selectedDay.subtract(weekView ? 7 : 1, 'day'));
  };

  const handleDateChange = value => {
    selectDay(moment.unix(value));
  };

  const handleTodayClick = () => {
    selectDay(moment());
  };

  return (
    <ThemeProvider>
      <div className={classes.daySelector}>
        <IconButton className={classes.leftArrow} onClick={handlePrevDayClick}>
          <ArrowRightAltIcon />
        </IconButton>
        <DateInput
          className={classes.dateInput}
          value={selectedDay.unix()}
          onChange={handleDateChange}
        />
        <IconButton onClick={handleNextDayClick}>
          <ArrowRightAltIcon />
        </IconButton>
      </div>
      <Button type={isToday ? 'secondary' : 'tertiary'} onClick={handleTodayClick}>
        Today
      </Button>
    </ThemeProvider>
  );
};

DaySelector.propTypes = {
  // eslint-disable-next-line react/require-default-props
  day: string,
  selectDay: func.isRequired,
  weekView: bool.isRequired
};

export default DaySelector;
