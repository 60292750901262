import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';
import { Timezone } from 'scenes/Procurement/constants';
import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';
import { departmentSearch, getDepartmentsByJob } from 'services/API/department';
import { employeeSearch } from 'services/API/employee';
import { getItems, itemSearch } from 'services/API/generic';
import { vendorSearch } from 'services/API/vendor';
import { getTenantSettingValueForKey } from 'utils';

const defaultFlexStyle = '0 1 219px';
const wideItemFlexStyle = '0 0 453px';
const marginTitle = '0px 8px 0px 8px';
const margin = '0px 8px 16px 8px';

const generalInformationFields = {
  poNumber: { required: true },
  poType: { required: true },
  date: { required: true, nullable: true },
  department: { required: false },
  vendor: { required: true },
  assignedTo: { required: false },
  jobOrProjectDisplay: { required: false },
  description: { required: false, maxCharLimit: 2000 },
  requiredByDate: { required: false, nullable: true },
  purchaseOrderTags: { required: false }
};

const shippingInformationFields = {
  shipTo: { required: false },
  shippingAddress: { required: false },
  addressLine1: { required: false },
  addressLine2: { required: false },
  city: { required: false },
  state: { required: false },
  zipcode: { required: false }
};

const generalInformationContents = (
  tags,
  depId,
  associatedProject,
  assignedDepartments,
  handleJobIdChange,
  departmentsAreLimitedByJob,
  jobId,
  isVistaEnabled,
  jobProjectFieldReadOnly,
  handleSetDefaultValue
) => {
  return [
    // the 1st section title
    {
      options: {
        size: 1,
        direction: 'row',
        grow: 1
      },
      contents: [
        {
          options: {
            margin: marginTitle
          },
          contents: [
            {
              component: {
                edit: 'MuiFormSectionTitle'
              },
              options: {
                label: 'General Information'
              }
            }
          ]
        }
      ]
    },
    {
      options: {
        size: 1,
        direction: 'row',
        grow: 1
      },
      contents: [
        {
          options: {
            margin
          },
          contents: [
            {
              component: {
                edit: 'generalInfoCustomField'
              }
            }
          ]
        }
      ]
    },
    // the 1st row
    {
      options: {
        size: 3,
        direction: 'row',
        grow: 0,
        flexWrap: 'nowrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'customSearchBar',
                default: 'customSearchBar'
              },
              source: 'poType',
              options: {
                resultFormatFunction: type => `${type.name}`,
                emptySearchFunction: () => getItems('PurchaseOrderTypes'),
                searchFunction: (...args) => itemSearch(...args, 'PurchaseOrderTypes/search'),
                searchColumns: ['name'],
                label: 'PO Type',
                placeholder: 'Select PO Type',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: true,
                testingid: 'po-type'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'DateInput'
              },
              source: 'date',
              options: {
                label: 'Date',
                placeholder: '',
                fullWidth: false,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: true,
                timezone: Timezone.GMT
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'customSearchBar'
              },
              source: 'department',
              options: {
                resultFormatFunction: department => `${department.tagName}`,
                searchFunction: departmentsAreLimitedByJob
                  ? () => getDepartmentsByJob(jobId)
                  : departmentSearch,
                searchColumns: ['tagName'],
                label: 'Department',
                placeholder: 'Select Department',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: isVistaEnabled,
                additionalSearchData: assignedDepartments,
                searchOnOpen: departmentsAreLimitedByJob,
                setDefaultValue: handleSetDefaultValue,
                resetDependentDropdownsOnSelection: departmentsAreLimitedByJob,
                needRefresh: departmentsAreLimitedByJob,
                testingid: 'department'
              }
            }
          ]
        }
      ]
    },

    // the 2nd row
    {
      options: {
        size: 3,
        direction: 'row',
        grow: 0,
        flexWrap: 'nowrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'customSearchBar'
              },
              source: 'vendor',
              options: {
                resultFormatFunction: vendor => `${vendor.name}`,
                searchFunction: vendorSearch,
                searchColumns: ['name'],
                label: 'Vendor',
                placeholder: 'Select Vendor',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: true,
                disabled: !depId,
                errorText: 'Field is required',
                helperText: depId ? '' : 'Please select a department first',
                testingid: 'vendor'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'customSearchBar'
              },
              source: 'assignedTo',
              options: {
                resultFormatFunction: employee => employee?.name || null,
                searchFunction: employeeSearch,
                searchColumns: ['name'],
                searchParams: [depId],
                label: 'Assign To',
                placeholder: 'Select Assignee',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false,
                disabled: !depId,
                helperText: depId ? '' : 'Please select a department first',
                testingid: 'assigned-to'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'AlgoliaSearch'
              },
              source: 'jobOrProjectDisplay',
              options: {
                label: 'Job / Project',
                required: isVistaEnabled,
                searchIndexName: defaultSearchIndex,
                searchResultDisplayFields: ['jobNumber', 'projectName', 'customerName'],
                valueField: 'id',
                delimiter: ' - ',
                placeholder: jobProjectFieldReadOnly ? '-' : 'Search and Select',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                filters: [
                  {
                    attribute: 'entityType',
                    valueArray: ['Job', 'Project']
                  }
                ],
                attributes: [
                  getTenantSettingValueForKey('job_customJobNumber')
                    ? 'customIdentifier'
                    : 'jobNumber',
                  'projectName',
                  'customerName',
                  'projectNumber'
                ],
                valuesToSet: [{ jobId: 'id', jobNumber: 'jobNumber' }],
                onChange: handleJobIdChange,
                disabled: Boolean(associatedProject?.id) || jobProjectFieldReadOnly,
                select: true,
                testingid: 'job-or-project'
              }
            }
          ]
        }
      ]
    },

    // the 3rd row
    {
      options: {
        size: 3,
        direction: 'row',
        grow: 0,
        flexWrap: 'nowrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: '3',
            margin
          },
          contents: [
            {
              component: {
                edit: 'TextInput'
              },
              source: 'description',
              options: {
                label: 'Description',
                placeholder: 'Enter Description',
                fullWidth: true,
                variant: 'standard',
                color: 'secondary',
                lines: 5,
                testingid: 'description'
              }
            }
          ]
        }
      ]
    },

    // the 4th row
    {
      options: {
        size: 3,
        direction: 'row',
        grow: 0,
        flexWrap: 'nowrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'DateInput'
              },
              source: 'requiredByDate',
              options: {
                label: 'Required By',
                placeholder: 'Select Date',
                fullWidth: false,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false,
                timezone: Timezone.GMT
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: wideItemFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'SelectInput'
              },
              source: 'purchaseOrderTags',
              options: {
                label: 'Tags',
                placeholder: 'Select Tags',
                isMultipleSelection: true,
                enableSort: false,
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                valueSet: tags,
                testingid: 'tags'
              }
            }
          ]
        }
      ]
    },

    // Divider
    {
      options: {
        size: 1,
        direction: 'row',
        grow: 1,
        margin: '8px 0px 40px'
      },
      contents: [
        {
          component: {
            edit: 'CustomDividerStyled'
          }
        }
      ]
    }
  ];
};

const shippingInformationContents = (
  shipTo,
  showShippingAddress,
  manualEntry,
  handleChangeShipToSelect,
  shipToIsEmployee
) => {
  return [
    // the 2nd section title
    {
      options: {
        size: 1,
        direction: 'row',
        grow: 1
      },
      contents: [
        {
          options: {
            margin
          },
          contents: [
            {
              component: {
                edit: 'MuiFormSectionTitle'
              },
              options: {
                label: 'Shipping Information'
              }
            }
          ]
        }
      ]
    },

    // the 1st row
    {
      options: {
        size: 3,
        grow: 0,
        flexWrap: 'nowrap'
      },
      contents: [
        // Radio Buttons
        showShippingAddress
          ? {
              options: {
                direction: 'column',

                margin
              },
              contents: [
                {
                  component: {
                    edit: 'RadioButtonsGroup'
                  },
                  source: 'shipToNameType',
                  options: {
                    btnDirection: 'Horizontal',
                    label: 'Ship to Name',
                    inputOptions: 'Employee, Other'
                  }
                }
              ]
            }
          : { component: null },

        // Employee
        showShippingAddress
          ? {
              options: {
                margin
              },
              contents: [
                {
                  component: {
                    edit: shipToIsEmployee ? 'customSearchBar' : 'TextInput'
                  },
                  source: shipToIsEmployee ? 'shipToEmployee' : 'shipToName',
                  options: {
                    ...(shipToIsEmployee
                      ? {
                          resultFormatFunction: employee => `${employee.name}`,
                          searchFunction: employeeSearch,
                          searchColumns: ['name'],
                          placeholder: 'Select Employee',
                          className: 'employee-select'
                        }
                      : { placeholder: 'Enter Name', maxLength: 255 }),
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary'
                  }
                }
              ]
            }
          : { component: null },
        // Ship to
        {
          options: {
            width: showShippingAddress ? 'auto' : '219px',
            margin
          },
          contents: [
            {
              component: {
                edit: 'SelectInput'
              },
              source: 'shipTo',
              options: {
                label: 'Ship to',
                placeholder: 'Select Shipping Location',
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                valueSet: shipTo,
                onChange: handleChangeShipToSelect
              }
            }
          ]
        },
        // Address
        showShippingAddress
          ? {
              options: {
                margin
              },
              contents: [
                {
                  component: {
                    edit: manualEntry ? 'PlacesSearch' : 'TextInput'
                  },
                  source: 'shippingAddress',
                  options: {
                    label: 'Shipping Address',
                    placeholder: manualEntry ? 'Enter Shipping Address' : '',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary',
                    disabled: !manualEntry,
                    valuesToSet: manualEntry
                      ? [
                          {
                            addressLine1: 'addressLine1',
                            addressLine2: 'addressLine2',
                            city: 'city',
                            state: 'state',
                            zipcode: 'zipcode'
                          }
                        ]
                      : undefined
                  }
                }
              ]
            }
          : {
              component: null
            },
        // Shipping instructions
        showShippingAddress
          ? {
              options: {
                margin
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput'
                  },
                  source: 'shipToInstructions',
                  options: {
                    label: 'Shipping Instructions',
                    placeholder: 'Enter Shipping Instructions',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary',
                    maxLength: 2000
                  }
                }
              ]
            }
          : {
              component: null
            }
      ]
    }
  ];
};

const layout = ({
  tags,
  shipTo,
  showShippingAddress,
  manualEntry,
  handleJobIdChange,
  handleChangeShipToSelect,
  depId,
  associatedProject,
  assignedDepartments,
  departmentsAreLimitedByJob,
  jobId,
  isVistaEnabled,
  jobProjectFieldReadOnly,
  handleSetDefaultValue,
  shipToIsEmployee
}) => {
  return {
    fields: {
      ...generateDefaultFieldsObject(generalInformationFields),
      ...generateDefaultFieldsObject(shippingInformationFields)
    },
    layouts: {
      edit: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          ...generalInformationContents(
            tags,
            depId,
            associatedProject,
            assignedDepartments,
            handleJobIdChange,
            departmentsAreLimitedByJob,
            jobId,
            isVistaEnabled,
            jobProjectFieldReadOnly,
            handleSetDefaultValue
          ),
          ...shippingInformationContents(
            shipTo,
            showShippingAddress,
            manualEntry,
            handleChangeShipToSelect,
            shipToIsEmployee
          )
        ]
      }
    }
  };
};

export { generalInformationFields, shippingInformationFields, layout };
