import React, { useEffect, useState } from 'react';
import {
  Avatar,
  ListItem,
  Typography,
  ListItemSecondaryAction,
  withStyles
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import StorageService from 'services/StorageService';

const EmployeeMenuItem = ({
  profilePictureUrl,
  name,
  isFocused,
  innerRef,
  innerProps,
  id,
  onClickRemove,
  classes
}) => {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    async function getUrlFromKey(key) {
      const storageService = new StorageService();
      const imageUrl = await storageService.getFile(key);
      setUrl(imageUrl);
    }
    if (profilePictureUrl) {
      getUrlFromKey(profilePictureUrl);
    }
  }, [profilePictureUrl]);

  return (
    <ListItem
      buttonRef={innerRef}
      selected={isFocused}
      component="div"
      {...innerProps}
      className={classes.listItem}
    >
      {id && <Avatar src={url} style={{ marginRight: 10, width: 25, height: 25 }} />}
      <Typography variant="body2">{name}</Typography>
      {onClickRemove && (
        <ListItemSecondaryAction>
          <ClearIcon className={classes.clearIcon} onClick={onClickRemove} />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

const styles = theme => ({
  clearIcon: {
    color: theme.palette.grayscale(20),
    fontSize: 'unset',
    lineHeight: 'unset'
  },
  listItem: {
    display: 'inline-flex',
    width: '100%',
    borderWidth: 0,
    borderBottomWidth: 1,
    borderColor: theme.palette.grayscale(80),
    borderStyle: 'solid'
  }
});

export default withStyles(styles)(EmployeeMenuItem);
