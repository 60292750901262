import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

// Render Material UI text input as number field input
const MuiTextInput = ({ field, form, classes, user, specialbehaviour, ...rest }) => (
  <TextField
    variant="filled"
    type="number"
    fullWidth
    className={classes.textField}
    {...field}
    {...rest}
  />
);
// export default MuiTextInput;
export default withStyles(styles)(MuiTextInput);
