import offScheduleSchema from './offSchedules';

const employeeSchema = [
  'isActive',
  'isAdmin',
  'isDoNotCall',
  'isEmailOptOut',
  'isSmsOptOut',
  'salutation',
  'name',
  'firstName',
  'lastName',
  'middleName',
  'nickName',
  'profilePictureUrl',
  'userTitle',
  'email',
  'personalEmail',
  'cellPhone',
  'landlinePhone',
  'contactType',
  'status',
  'parentId',
  'parentSortKey',
  'hierarchy',
  'id',
  'entityType',
  'version',
  'tenantId',
  'tenantCompanyId',
  'partitionKey',
  'sortKey',
  'createdBy',
  'createdDate',
  'createdDateTime',
  'deletedBy',
  'deletedDate',
  'deletedDateTime',
  'lastUpdatedBy',
  'lastUpdatedDate',
  'lastUpdatedDateTime',
  'lsi1',
  'lsi2',
  'lsi3',
  'lsi4',
  'lsi5',
  'gsi1',
  'gsi2'
];

const employeeSchemaMappings = [
  {
    key: 'offSchedules',
    mapType: '1M',
    connection: offScheduleSchema
  }
];

export default employeeSchema;
export { employeeSchemaMappings };
