import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import Labels from 'meta/Authorization/labels';
import { Typography, TV, TW, Button, ThemeProvider } from '@buildhero/sergeant';
import PasswordInput from '../PasswordInput';
import styles from '../styles';

const MIN_PASSWORD_LEN = 8;

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userPasswordField: {
        value: '',
        error: ''
      },
      newPasswordField: {
        value: '',
        error: ''
      },
      confirmationPasswordField: {
        value: '',
        error: ''
      }
    };
  }

  handleChange = (field, event) => {
    event.persist();
    this.setState(prevState => {
      const existingField = prevState[field];
      existingField.value = event.target.value;
      return { [field]: existingField };
    });
  };

  validateFields = () => {
    const { userPasswordField, newPasswordField, confirmationPasswordField } = this.state;
    userPasswordField.error = '';
    newPasswordField.error = '';
    confirmationPasswordField.error = '';
    if (newPasswordField.value.length < MIN_PASSWORD_LEN) {
      this.setState({
        userPasswordField,
        newPasswordField: {
          ...newPasswordField,
          error: `Password must have minimum length of ${MIN_PASSWORD_LEN}`
        }
      });
      return false;
    }
    if (newPasswordField.value !== confirmationPasswordField.value) {
      this.setState({
        userPasswordField,
        confirmationPasswordField: {
          ...confirmationPasswordField,
          error: 'The passwords did not match'
        }
      });
      return false;
    }
    return true;
  };

  updatePassword = async () => {
    const { newPasswordField, userPasswordField } = this.state;

    if (this.validateFields()) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, userPasswordField.value, newPasswordField.value);
        this.props.handleClose();
      } catch (exception) {
        this.setState({
          userPasswordField: { ...userPasswordField, error: 'Wrong User Password' }
        });
      }
    }
  };

  render() {
    const { user, classes, handleClose } = this.props;
    const { userPasswordField, newPasswordField, confirmationPasswordField } = this.state;

    return (
      <Grid container direction="column" justify="center" alignItems="center">
        <ThemeProvider>
          <Grid item xs={12} className={classes.authSection}>
            <Typography variant={TV.L} weight={TW.BOLD}>
              {Labels.changePasswordButtonText[user.locale]}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.authSection}>
            <PasswordInput
              label={Labels.userPassword.en}
              autoComplete="current-password"
              value={userPasswordField.value}
              onChange={event => this.handleChange('userPasswordField', event)}
              error={userPasswordField.error?.length > 0}
              subtext={userPasswordField.error}
            />
          </Grid>
          <Grid item xs={12} className={classes.authSection}>
            <PasswordInput
              label={Labels.enterNewPasswordLabel.en}
              autoComplete="current-password"
              value={newPasswordField.value}
              onChange={event => this.handleChange('newPasswordField', event)}
              error={newPasswordField.error?.length > 0}
              subtext={newPasswordField.error}
            />
          </Grid>
          <Grid item xs={12} className={classes.authSection}>
            <PasswordInput
              label={Labels.confirmNewPasswordLabel.en}
              autoComplete="current-password"
              value={confirmationPasswordField.value}
              onChange={event => this.handleChange('confirmationPasswordField', event)}
              error={confirmationPasswordField.error?.length > 0}
              subtext={confirmationPasswordField.error}
            />
          </Grid>
          <Grid item xs={12} className={classes.authSection}>
            <Button type="primary" onClick={() => this.updatePassword()} fullWidth>
              Save
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.authSection}>
            <Button type="secondary" onClick={() => handleClose()} fullWidth>
              Cancel
            </Button>
          </Grid>
        </ThemeProvider>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ChangePassword);
