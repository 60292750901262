import { bundleIndex } from 'constants/algoliaIndex';

const layout = (
  defaultPriceBookId,
  showAddButton,
  modalToShow,
  associatedPartsAndMaterials = []
) => {
  const sharedComponents = [
    {
      component: {
        default: 'List'
      },
      options: {
        showAddButton
      }
    },
    {
      component: {
        default: 'ItemSearch'
      },
      source: 'productName',
      options: {
        label: '',
        alignment: 'left',
        placeholder: 'Search to add items',
        searchIndexName: bundleIndex,
        searchResultDisplayFields: ['productName', 'productCode'],
        delimiter: ' - ',
        setItemName: 0,
        filters: [
          {
            attribute: 'entityType',
            valueArray: ['PriceBookEntry']
          },
          {
            attribute: 'priceBookId',
            valueArray: [`${defaultPriceBookId}`]
          }
        ]
      }
    }
  ];

  return modalToShow === 'suggestedAndAddParts'
    ? {
        fields: {
          partsAndMaterials: {
            default: 'partsAndMaterials'
          }
        },
        layouts: {
          default: {
            options: {
              pageSize: 'LETTER',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column'
                },
                contents: [
                  {
                    component: {
                      default: 'SuggestedParts'
                    }
                  },
                  {
                    component: {
                      default: 'CheckboxList'
                    },
                    options: {
                      associatedPartsAndMaterials
                    }
                  },
                  {
                    component: {
                      default: 'DividerWithPadding'
                    }
                  },
                  {
                    component: {
                      default: 'AddAdditionalParts'
                    }
                  },
                  ...sharedComponents
                ]
              }
            ]
          }
        }
      }
    : {
        // modalToShow === 'addParts'
        fields: {
          partsAndMaterials: {
            default: 'partsAndMaterials'
          }
        },
        layouts: {
          default: {
            options: {
              pageSize: 'LETTER',
              grow: 0
            },
            contents: [...sharedComponents]
          }
        }
      };
};

export const layoutWithSuggestedParts = (defaultPriceBookId, showAddButton) => ({});

export default layout;
