const entityRouteMappings = {
  Customer: '/customer/view/',
  BillingCustomer: '/customer/view/',
  Property: '/property/view/',
  CustomerProperty: '/property/view/',
  Invoice: '/invoice/view/',
  Job: '/job/view/',
  Visit: '/job/view/',
  TechnicianReport: '/technicianreport/view/',
  ReviewReport: '/reviewreport/view/',
  Employee: '/settings/people/',
  AppRole: '/settings/people/',
  CustomerTag: '/settings/customFields/',
  CustomerType: '/settings/customFields/',
  JobType: '/settings/customFields/',
  CustomerPropertyType: '/settings/customFields/',
  Vendor: '/settings/vendors/',
  PriceBook: '/settings/pricebooks/',
  PriceBookView: '/settings/pricebooks/view/',
  PriceBookEntry: '/settings/pricebooks/',
  Product: '/settings/pricebooks/',
  ServiceAgreement: '/serviceAgreement/view/',
  Department: '/',
  Skill: '/',
  CompanyAddress: '/'
};

export default entityRouteMappings;
