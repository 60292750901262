import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  techContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));
