import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { getWeekDays, getSelectedText, getHourSettingValue } from './helpers';
import DefaultButton from 'components/Buttons/DefaultButton';
import { RenderHeader, RenderTitle, ItemWrapper } from './TinyComponents';
import { dayTableMeta, timeFields } from './constants';
import { getEmployeeIds } from './helpers';
import useStyles from './Setting.styles';

const Setting = ({ data, employeeOptions, departmentOptions, onEdit, isLoading }) => {
  const classes = useStyles();
  const weekDays = getWeekDays();
  const { afterHoursTimings = [], departmentIds, name } = data;

  const tableHeaders = [...dayTableMeta];
  tableHeaders.pop();

  const employeeIds = getEmployeeIds(afterHoursTimings);

  const employeeText = getSelectedText(employeeOptions, employeeIds);
  const departmentText = getSelectedText(departmentOptions, departmentIds);

  const selectedSection = [
    { title: 'Department(s)', flex: 1.5, value: departmentText },
    { title: 'Assigned technician(s)', flex: 2, value: employeeText }
  ];

  return (
    <Box display="flex" py={1} flexDirection="column">
      <Box display="flex" justifyContent="space-between">
        <Typography style={{ paddingTop: 6, paddingBottom: 6 }} variant="h6">
          {name}
        </Typography>
        <DefaultButton label="edit" onClick={onEdit} variant="outlined" disabled={isLoading} />
      </Box>
      <Box py={2} display="flex">
        {selectedSection.map((item, i) => (
          <Box key={i} display="flex" flexDirection="column" flex={item.flex}>
            <RenderTitle title={item.title} />
            <Typography style={{ fontWeight: 'normal' }} variant="body1">
              {item.value || '-'}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box display="flex" flexDirection="column">
        <RenderHeader items={tableHeaders} />
        <Box display="flex" flexDirection="column">
          {weekDays.map(weekDay => {
            const { dayIndex } = weekDay;
            const value = getHourSettingValue(afterHoursTimings, dayIndex);

            return (
              <Box key={dayIndex} alignItems="center" display="flex">
                <Box className={classes.itemBorder} alignItems="center" display="flex">
                  <ItemWrapper>
                    <Typography variant="body1">{weekDay.dayString}</Typography>
                  </ItemWrapper>
                  <Box display="flex">
                    {timeFields.map((timeField, i) => (
                      <ItemWrapper key={i}>
                        <Typography variant="body1">{value[timeField]}</Typography>
                      </ItemWrapper>
                    ))}
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
export default Setting;
