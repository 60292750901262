import React from 'react';
import { bool } from 'prop-types';
import { TECH_PROP } from '@dispatch/queries';
import TechCard from '@dispatch/components/TechCard';
import { useStyles } from './TechCell.styles';

const TechCell = ({ tech, techLoading, showStatus }) => {
  const { profilePictureUrl } = tech;
  const classes = useStyles({ profilePictureUrl });

  return (
    <div className={classes.techCell}>
      <div className={classes.content}>
        <TechCard tech={tech} techLoading={techLoading} showStatus={showStatus} />
      </div>
    </div>
  );
};

TechCell.propTypes = {
  tech: TECH_PROP.isRequired,
  techLoading: bool.isRequired,
  showStatus: bool
};

TechCell.defaultProps = {
  showStatus: false
};

export default React.memo(TechCell);
