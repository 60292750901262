import React from 'react';

import { ThemeProvider } from '@buildhero/sergeant';

import CostInvoiceItems from '../CostInvoiceItems';
import JobCloseoutInformation from '../InformationSection';
import NotesTable from '../NotesTable';

const TimeAndMaterialOverviewTab = ({
  jobData,
  visits,
  refetchJob,
  companyTimezone,
  isLoading
}) => (
  <ThemeProvider>
    <JobCloseoutInformation jobData={jobData} isLoading={isLoading} />
    <NotesTable
      notes={jobData?.notes?.items || []}
      job={jobData}
      refetchJob={refetchJob}
      isLoading={isLoading}
    />
    <CostInvoiceItems
      jobData={jobData}
      visits={visits}
      refetchJob={refetchJob}
      companyTimezone={companyTimezone}
      isLoading={isLoading}
    />
  </ThemeProvider>
);

TimeAndMaterialOverviewTab.defaultProps = {
  isLoading: false
};

export default TimeAndMaterialOverviewTab;
