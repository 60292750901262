export const useStyles = () => ({
  container: theme => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px 0px`
  }),
  subSectionTitle: theme => ({
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }),
  buttonsContainer: theme => ({
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    alignSelf: 'center',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  }),
  lineItemsTotal: theme => ({
    textAlign: 'right',
    paddingTop: theme.spacing(1)
  })
});
