/* eslint-disable jsx-a11y/click-events-have-key-events,no-nested-ternary,react/require-default-props */
import React, { useEffect } from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Typography, ThemeProvider, TV, TW } from '@buildhero/sergeant';
import { emptyNonVisitDetailsFragment } from '@dispatch/fragments';
import { ElementSizes, EventColors } from '@dispatch/Dispatch.styles';
import { EventIcons } from '@dispatch/Dispatch.icons';
import { selectRangeText } from '@dispatch/Dispatch.selectors';
import { useStyles } from './NonVisitCard.styles';
import { useNonVisitDrag, useEventBorderDrag } from './NonVisitCard.hooks';
import { getDnDEventTypes, getEventType } from './NonVisitCard.helpers';

const NonVisitEventCard = ({ event, openEvent, weekView, srcTech, isDragging }) => {
  const isOptimistic = event.id === emptyNonVisitDetailsFragment.id;
  const rangeText = selectRangeText(event);

  const eventType = getEventType(event);
  const [dragType, startType, endType] = getDnDEventTypes(event);
  const [, dragRef, dragPreview] = useNonVisitDrag({ type: dragType, event, srcTech });

  const [, startDragRef, startDragPreview] = useEventBorderDrag({
    type: startType,
    event,
    srcTech,
    weekView
  });

  const [, endDragRef, endDragPreview] = useEventBorderDrag({
    type: endType,
    event,
    srcTech,
    weekView
  });

  const styles = useStyles({
    event,
    backgroundColor: EventColors[eventType],
    weekView,
    isOptimistic,
    isDragging,
    eventWidth: event.width
  });

  useEffect(() => {
    startDragPreview(getEmptyImage(), { captureDraggingState: true });
    endDragPreview(getEmptyImage(), { captureDraggingState: true });
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const handleClick = e => {
    if (!isOptimistic) {
      e.stopPropagation();
      openEvent({ eventId: event.id, eventType });
    }
  };

  return (
    <ThemeProvider>
      <div
        css={styles.container}
        role="button"
        tabIndex="0"
        ref={dragRef}
        style={{
          left: isDragging ? undefined : event.left,
          top: isDragging ? undefined : event.position * ElementSizes.cellHeight
        }}
        onClick={handleClick}
        onKeyPress={handleClick}
      >
        <div css={styles.dragStart} ref={startDragRef} />
        <img alt="Non-Visit Event" css={styles.icon} src={EventIcons[eventType]} />
        <Typography css={styles.name} weight={TW.BOLD}>
          {event.name}
        </Typography>
        {event.status && <Typography css={styles.name}>{event.status}</Typography>}
        {weekView && (
          <Typography css={styles.timeRange} variant={TV.S2}>
            {rangeText}
          </Typography>
        )}
        <div css={styles.dragEnd} ref={endDragRef} />
      </div>
    </ThemeProvider>
  );
};

NonVisitEventCard.propTypes = {
  event: shape({
    name: string,
    left: number,
    width: number
  }).isRequired,
  openEvent: func.isRequired,
  // eslint-disable-next-line react/require-default-props
  weekView: bool,
  srcTech: string,
  isDragging: bool
};

export default NonVisitEventCard;
