const styles = theme => ({
  title: {
    color: theme.palette.grayscale(20),
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'normal',
    letterSpacing: '0.01px',
    lineHeight: '14px'
  },
  titleSubText: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  activityContainer: {
    paddingTop: theme.spacing(1.5),
    paddingLeft: theme.spacing(2)
  },
  activityTitle: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(2),
    letterSpacing: -1
  }
});

export default styles;
