import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: props => ({
    backgroundColor: theme.palette.grayscale(100),
    borderColor: theme.palette.grayscale(80),
    borderStyle: `${props.noBorder ? 'none' : 'solid'}`,
    borderWidth: '1px',
    fontFamily: theme.typography.fontFamily,
    paddingTop: theme.spacing(0.625),
    paddingBottom: theme.spacing(0.625),
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(1.25),
    '&:disabled': {
      borderStyle: `${props.noBorder ? 'none' : 'solid'}`
    }
  }),
  label: props => (props.smallText ? theme.typography.overline : theme.typography.body2),
  loadingSpinner: props => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -props.progressSize / 2,
    marginLeft: -props.progressSize / 2,
    color: theme.palette.grayscale(50)
  })
}));

const SubduedButton = React.forwardRef((props, ref) => {
  const { disabled, enableProgress, onClick, noBorder, smallText, ...rest } = props;
  const defaultProgressSize = 16;
  const progressSize = props.progressSize || defaultProgressSize;
  const classes = useStyles({ smallText, noBorder, progressSize });
  return (
    <Button
      variant="outlined"
      disabled={disabled}
      onClick={onClick}
      classes={{ root: classes.root, label: classes.label }}
      ref={ref}
      {...rest}
    >
      {props.children}
      {enableProgress && disabled && (
        <CircularProgress className={classes.loadingSpinner} size={progressSize} />
      )}
    </Button>
  );
});

export default SubduedButton;
