import React from 'react';
import Box from '@material-ui/core/Box';
import ProfileIcon from 'components/Navigation/UserControls/ProfileIcon/index';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';

const EmployeesList = ({ form }) => {
  const { employees } = form.values;
  return (
    <div>
      {employees?.length ? (
        <List>
          <Divider />
          {employees.map(
            item =>
              item.mappedEntity && (
                <EmployeeListItem
                  employeeName={`${item.mappedEntity.firstName?.trim() || ''} ${item.mappedEntity.lastName?.trim() || ''}`}
                  profilePictureUrl={item.mappedEntity.profilePictureUrl}
                  key={item.id}
                />
              )
          )}
        </List>
      ) : (
        <Typography variant="body2">There are no employees assigned to this department.</Typography>
      )}
    </div>
  );
};

const EmployeeListItem = ({ profilePictureUrl, employeeName, key }) => {
  const imageKey = profilePictureUrl || ' ';
  return (
    <ListItem key={key} button divider>
      <Box display="flex-start">
        <ProfileIcon employeeName={employeeName} alt={employeeName} imageKey={imageKey} />
      </Box>
      <ListItemText id={employeeName} primary={employeeName} />
    </ListItem>
  );
};

export default EmployeesList;
