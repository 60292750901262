import gql from 'graphql-tag';

const getCompanyWithDepartmentSKill = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      version
      skills(entityConnection: "Skill") {
        items {
          id
          version
          tagName
          sortKey
          tenantId
          contacts(entityConnection: "Employee") {
            items {
              id
              mappedEntity {
                ... on Employee {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
      departmentsView(
        filter: {
          integerFilters: [{ fieldName: "Department.deletedDate", filterInput: { is: null } }]
        }
      ) {
        items {
          id
          partitionKey
          sortKey
          tagName
          version
          logoUrl
          email
          phonePrimary
          deletedDate
          intacctEntityId
          entityDimension {
            name
          }
          intacctClassId
          classDimension {
            name
          }
          intacctDepartmentId
          departmentDimension {
            name
          }
          intacctLocationId
          locationDimension {
            name
          }
          ADPExportDepartmentId
          ADPExportClassId
          ADPExportLocationCode
          companyAddresses(entityConnection: "CompanyAddress") {
            items {
              id
              partitionKey
              addressLine1
              addressLine2
              city
              county
              state
              zipcode
              country
              billTo
              shipTo
              addressType
              version
            }
          }
          accountingRefIdOfClass
          skills(entityConnection: "Skill") {
            items {
              id
              partitionKey
              mappedEntity {
                ... on Skill {
                  tagName
                  version
                  id
                }
              }
            }
          }
          contacts(entityConnection: "Employee") {
            items {
              id
              partitionKey
              mappedEntity {
                ... on Employee {
                  id
                  firstName
                  lastName
                  name
                  profilePictureUrl
                  skills(entityConnection: "Skill") {
                    items {
                      id
                      mappedEntity {
                        ... on Skill {
                          tagName
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getCompanyWithDepartmentSKill;
