import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { ListService } from 'services/core';
import { Logger } from 'services/Logger';
import ResponsiveTable from 'components/ResponsiveTable';
import { PageHeader, Divider, UserPermission } from 'components';
import Labels from 'meta/labels';
import { assetRowMeta } from 'meta/ListViews';
import ErrorBoundaries from 'scenes/Error';
import styles from './styles';
import { snackbarOn } from 'redux/actions/globalActions';
import { PermissionConstants } from 'utils/AppConstants';

class AssetView extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    this.ListService = new ListService();
    this.state = {
      serviceFn: this.getAllAssets
    };
  }

  componentDidMount = async () => {
    document.title = 'BuildOps - Asset List';
  };

  getAllAssets = async (filter, limit, offset, sortBy, sortOrder) => {
    if (!this.props.user.tenantId) {
      return { items: [], nextToken: '0' };
    }
    let data;
    const sortKey = `${this.props.user.tenantId}_Company_${this.props.user.tenantCompanyId}`;
    try {
      data = await this.ListService.getAllAssets(
        this.props.user.tenantId,
        sortKey,
        filter,
        limit,
        offset,
        sortBy,
        sortOrder
      );

      return data;
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to fetch Assets, please try again later', error);
    }
    return data || [];
  };

  render() {
    const { user } = this.props;
    const { serviceFn } = this.state;
    return (
      <ErrorBoundaries>
        <UserPermission I="read" action={PermissionConstants.OBJECT_ASSET}>
          <PageHeader title={Labels.asset[user.locale]} />
          <Divider />
          <ResponsiveTable
            rowMetadata={assetRowMeta}
            useServerQueries
            service={serviceFn}
            noDataMsg="No Assets"
            caslKey={PermissionConstants.OBJECT_ASSET}
          />
        </UserPermission>
      </ErrorBoundaries>
    );
  }
}

const styledAssetView = withStyles(styles, { withTheme: true })(AssetView);

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const reduxConnectedAsset = connect(mapStateToProps, mapDispatcherToProps)(styledAssetView);

export default reduxConnectedAsset;
