import React, { useEffect } from 'react';
import { CompanyService } from 'services/core';
import { connect } from 'react-redux';
import moment from 'moment';
import { PageHeader } from 'components';
import AuditLogsSection from 'components/AuditLogs';
import _ from 'lodash';

function AuditLogs(props) {
  useEffect(() => {
    document.title = 'BuildOps - Logs';
  });

  const getLogs = async (start, end, auditEntityTypes) => {
    const { user } = props;
    const companySortKey = `${user.tenantId}_Company_${user.tenantCompanyId}`;
    const companyService = new CompanyService();
    let startTime = moment()
      .subtract(1, 'years')
      .startOf('day')
      .unix();
    let endTime = moment()
      .endOf('day')
      .unix();
    if (start && end) {
      startTime = start;
      endTime = end;
    }
    if (!_.isEmpty(auditEntityTypes)) {
      const auditFilter = {
        auditedEntityType: {
          in: auditEntityTypes
        }
      };
      return companyService.getAllAuditLog(user.tenantId, companySortKey, start, end, auditFilter);
    }
    return companyService.getAllAuditLog(user.tenantId, companySortKey, startTime, endTime);
  };

  return (
    <>
      <PageHeader pageMapKey="logs" userLocale={props.user.locale} />
      <AuditLogsSection dataService={getLogs} fullScreen />
    </>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mappedAuditLogs = connect(mapStateToProps)(AuditLogs);

export default mappedAuditLogs;
