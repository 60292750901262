import React, { useMemo, useRef, useState } from 'react';
import { object, string } from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Button, MoreButton, ThemeProvider } from '@buildhero/sergeant';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import Routes from 'scenes/Routes';
import { InvoiceStatus } from 'utils/constants';
import selectValidAddress from 'utils/selectValidAddress';
import { ReviewReportStatus, VisitStatus } from 'utils/AppConstants';
import { JobService } from 'services/core';

import { useStyles } from './ReviewReportActionButton.styles';
import MultipleVisitsModal from './components/MultipleVisitsModal';
import { useUpdateReviewReportStatus } from '../../../../mutations/useUpdateReviewReportStatus';
import { useCreateInvoiceFromVisits } from '../../../../mutations/useCreateInvoiceFromVisits';

const useInvoiceId = ({ job, visit }) => {
  return useMemo(() => {
    return (
      job?.invoices?.items?.reduce((id, invoice) => {
        if (invoice.status === InvoiceStatus.VOID) return id;

        const visitMatch = invoice.invoiceVisits.items.find(
          invoiceVisit => invoiceVisit.visit.id === visit.id
        );

        if (visitMatch) return invoice.id;

        return id;
      }, null) || null
    );
  }, [job, visit.id]);
};

const ReviewReportActions = {
  DISMISS: 'DISMISS',
  SUBMIT: 'SUBMIT',
  EDIT: 'EDIT'
};

const ReviewReportActionButton = ({ visit, job, reviewReport, buttonSize }) => {
  const styles = useStyles();
  const history = useHistory();
  const status = reviewReport?.status?.toLowerCase();
  const technicianReportId = visit?.id;
  const invoiceId = useInvoiceId({ job, visit });
  const action = useRef(null);
  const [addMultipleVisitsModalOpen, setAddMultipleVisitsModalOpen] = useState(false);
  const [multipleVisits, setMultipleVisits] = useState([]);
  const jobService = new JobService();

  const [updateStatus, updateStatusResponse] = useUpdateReviewReportStatus();

  const [createInvoice, createInvoiceResponse] = useCreateInvoiceFromVisits({
    // eslint-disable-next-line no-shadow
    serializer: ({ visitIds, job, finalInvoiceFlag }) => ({
      variables: {
        partitionKey: reviewReport.partitionKey,
        data: {
          tenantCompanyId: reviewReport.tenantCompanyId,
          jobId: job.id,
          visitIds,
          isFinalInvoice: finalInvoiceFlag ?? false,
          billingAddress: selectValidAddress(job?.billingCustomer?.companyAddresses?.items, {
            throwError: true,
            sanitizeAddressInput: true
          })
        }
      }
    }),
    onCompleted: ({ createInvoiceFromVisits: { id } }) => {
      history.push(Routes.invoice({ mode: 'view', id }));
    },
    showConfirmation: job.jobTypeInternal === 'Maintenance',
    confirmationModalOptions: {
      title: 'Create Invoice for Maintenance Visit',
      buttonText: 'Yes',
      modalText: `This maintenance is part of a Service Agreement with defined Billing Terms. 
        Would you like to proceed with creating an invoice for this individual maintenance?`
    },
    reviewReportId: reviewReport.id
  });

  const anyLoading = updateStatusResponse.loading || createInvoiceResponse.loading;

  const handleDismissClick = () => {
    action.current = ReviewReportActions.DISMISS;
    updateStatus({ id: reviewReport.id, status: ReviewReportStatus.DISMISSED });
  };

  const handleSubmitClick = () => {
    action.current = ReviewReportActions.SUBMIT;
    updateStatus({ id: reviewReport.id, status: ReviewReportStatus.SUBMITTED });
  };

  const handleEditClick = () => {
    action.current = ReviewReportActions.EDIT;
    updateStatus({ id: reviewReport.id, status: ReviewReportStatus.DRAFT });
  };

  const handleCreateInvoice = async () => {
    const { data } = await jobService.getVisitsByJobNumber(`${job.jobNumber}`);
    const visitsWithSubmittedReviewReports = data.getJobByJobNumber.visits.items.filter(
      jobVisit => {
        return (
          jobVisit.reviewReports.items.length > 0 &&
          jobVisit.reviewReports.items[0].status === 'submitted' &&
          [
            VisitStatus.COMPLETE,
            VisitStatus.CONVERTED,
            VisitStatus.ONHOLD,
            VisitStatus.CLOSED
          ].includes(jobVisit.status)
        );
      }
    );

    if (visitsWithSubmittedReviewReports.length > 1) {
      setAddMultipleVisitsModalOpen(true);
      setMultipleVisits(visitsWithSubmittedReviewReports);
    } else {
      createInvoice({ job, visitIds: [visit.id] });
    }
  };

  return (
    <>
      <ThemeProvider>
        <div css={styles.buttonContainer}>
          {[ReviewReportStatus.SUBMITTED, ReviewReportStatus.DISMISSED].includes(status) && (
            <Button
              disabled={anyLoading}
              size={buttonSize}
              loading={updateStatusResponse.loading && action.current === ReviewReportActions.EDIT}
              type="tertiary"
              onClick={handleEditClick}
            >
              Edit
            </Button>
          )}
          {technicianReportId && (
            <Button size={buttonSize} type="secondary">
              <Link to={Routes.techReport({ mode: 'view', id: technicianReportId })}>
                View Technician Report
              </Link>
            </Button>
          )}
          {status === ReviewReportStatus.INVOICED && invoiceId && (
            <Button endIcon={<OpenInNewIcon />} size={buttonSize} type="secondary">
              <Link target="_blank" to={Routes.invoice({ mode: 'view', id: invoiceId })}>
                View Invoice
              </Link>
            </Button>
          )}
          {status === ReviewReportStatus.DRAFT && (
            <Button
              disabled={anyLoading}
              size={buttonSize}
              loading={
                updateStatusResponse.loading && action.current === ReviewReportActions.SUBMIT
              }
              type="primary"
              onClick={handleSubmitClick}
            >
              Submit
            </Button>
          )}
          {status === ReviewReportStatus.SUBMITTED && (
            <Button
              disabled={anyLoading}
              size={buttonSize}
              loading={createInvoiceResponse.loading}
              type="primary"
              onClick={handleCreateInvoice}
            >
              Create Invoice
            </Button>
          )}
          {status === ReviewReportStatus.SUBMITTED && (
            <MoreButton
              options={[{ label: 'Dismiss', onClick: handleDismissClick, disabled: anyLoading }]}
            />
          )}
        </div>
      </ThemeProvider>
      <MultipleVisitsModal
        open={addMultipleVisitsModalOpen}
        handleClose={() => setAddMultipleVisitsModalOpen(false)}
        createInvoice={createInvoice}
        job={job}
        visit={visit}
        multipleVisits={multipleVisits}
      />
    </>
  );
};

ReviewReportActionButton.propTypes = {
  visit: object.isRequired,
  job: object.isRequired,
  reviewReport: object.isRequired,
  buttonSize: string
};

ReviewReportActionButton.defaultProps = {
  buttonSize: 'medium'
};

export default ReviewReportActionButton;
