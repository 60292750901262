const styles = theme => ({
  root: ({ inPopup }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    padding: inPopup ? theme.spacing(1) : 0
  }),
  formContainer: {
    width: '100%'
  }
});

export default styles;
