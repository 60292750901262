const styles = theme => ({
  headerContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  autoWidth: {
    width: 'auto'
  },
  pageTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(1)
    },
    justifyContent: 'flex-start'
  },
  pageTitle: {
    fontWeight: 'bold'
  },
  breadcrumbStyle: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.grayscale(20),
    marginBottom: theme.spacing(0.5)
  },
  breadcrumbSlashStyle: {
    color: theme.palette.brand.logoGreen,
    fontWeight: 700,
    marginLeft: 5,
    marginRight: 5
  },
  pageActionButton: {
    float: 'right',
    width: '176px',
    height: '36px',
    marginLeft: theme.spacing(2.5),
    textTransform: 'uppercase'
  },
  pageCreateButton: {
    float: 'right',
    height: '36px',
    borderRadius: 20,
    marginLeft: theme.spacing(2.5)
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  icons: {
    color: theme.palette.brand.logoGreen
  },
  headerButtons: {
    width: 'auto',
    alignSelf: 'flex-start'
  }
});

export default styles;
