import compose from 'utils/compose';
import { withDispatchActions, withQueryStringStore } from '@dispatch/Dispatch.store';

import NonVisitEventCard from './NonVisitCard.component';

const mapActionsToProps = ({ openEvent }) => ({
  openEvent
});

const mapQueryStringToProps = store => ({
  weekView: store.state.weekView
});

export default compose(
  withDispatchActions(mapActionsToProps),
  withQueryStringStore(mapQueryStringToProps)
)(NonVisitEventCard);
