import gql from 'graphql-tag';

const getGLAccountsByType = gql`
  query getGLAccountsByType($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      incomeAccounts: ledgerAccounts(
        entityConnection: "LedgerAccount"
        filter: { accountType: { eq: "Income" }, isActive: { eq: true } }
      ) {
        items {
          id
          accountNumber
          name
        }
      }
      expenseAccounts: ledgerAccounts(
        entityConnection: "LedgerAccount"
        filter: {
          accountType: { in: ["CostOfGoodsSold", "Cost Of Goods Sold", "Expense"] }
          isActive: { eq: true }
        }
      ) {
        items {
          id
          accountNumber
          name
        }
      }
    }
  }
`;

export default getGLAccountsByType;
