import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    color: theme.palette.grayscale(60),
    fontSize: 10,
    letterSpacing: 0.01,
    fontWeight: 'normal',
    textTransform: 'uppercase',
    lineHeight: '14px',
    marginBottom: '0.35em'
  },
  content: {
    paddingTop: theme.spacing(0.5)
  },
  error: {
    color: theme.palette.error.light,
    paddingTop: theme.spacing(0.5)
  }
}));
