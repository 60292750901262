import gql from 'graphql-tag';

const getPropertyAssetsByJob = gql`
  query getJobDetails($partitionKey: String!, $sortKey: String!) {
    getJob(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      tenantId
      hierarchy
      entityType
      jobNumber
      customIdentifier
      parentEntity {
        ... on CustomerProperty {
          companyName
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          propertyAssets(entityConnection: "PropertyAsset") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              assetName
              make
              modelNumber
              serialNo
              installDate
              version
              notes(entityConnection: "Note") {
                items {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  note
                  version
                  createdBy
                  lastUpdatedDate
                  lastUpdatedDateTime
                  lastUpdatedBy
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getPropertyAssetsByJob;
