/* eslint-disable import/prefer-default-export */
export const serviceHistoryColumns = [
  {
    id: 'displayJobNumber',
    label: 'Job/Maintenance',
    type: 'jobLink'
  },
  { id: 'visitNumber', label: 'Visit', filterType: 'number' },
  { id: 'department', label: 'Department' },
  { id: 'primaryTech', label: 'Primary Tech' },
  { id: 'jobType', label: 'Job Type' },
  { id: 'description', label: 'Summary', maxTextLen: 60 },
  { id: 'attachmentCount', label: 'Attachments', filterType: 'number' },
  {
    id: 'scheduledFor',
    type: 'dateOnly',
    label: 'Date'
  }
];

export const attachmentsColumns = [
  {
    id: 'displayName',
    label: 'Attachment',
    type: 'attachment',
    other: 'fileUrl',
    enableAttachmentIcon: true,
    headerStyle: {
      width: '20%',
      minWidth: 100
    }
  },
  {
    id: 'comment',
    label: 'Description',
    maxTextLen: 80,
    headerStyle: {
      width: '60%',
      minWidth: 250
    }
  },
  {
    id: 'createdBy',
    label: 'Uploaded by',
    headerStyle: {
      width: '10%',
      minWidth: 97
    }
  },
  {
    id: 'createdDate',
    label: 'Uploaded Date',
    type: 'dateOnly',
    headerStyle: {
      width: '10%',
      minWidth: 97
    }
  }
];

export const partsAndMaterialsColumns = [
  {
    id: 'productCode',
    label: 'Product Code',
    numeric: true
  },
  {
    id: 'itemName',
    label: 'Item Name',
    type: 'string'
  },
  {
    id: 'description',
    label: 'Description',
    type: 'string'
  },
  {
    id: 'itemQuantity',
    label: 'Item Quantity',
    numeric: true
  }
];

export const warrantyColumns = [
  {
    id: 'warrantyInfo',
    disablePadding: true,
    label: 'Warranty Text',
    maxTextLen: 2000,
    prewrap: true,
    headerStyle: {
      width: '70%',
      minWidth: 300
    }
  },
  {
    id: 'startDate',
    type: 'dateOnly',
    disablePadding: true,
    label: 'Start Date',
    headerStyle: {
      width: '15%',
      minWidth: 97
    }
  },
  {
    id: 'endDate',
    type: 'dateOnly',
    disablePadding: true,
    label: 'End Date',
    headerStyle: {
      width: '15%',
      minWidth: 97
    }
  }
];

export const notesColumns = [
  {
    id: 'subject',
    disablePadding: true,
    label: 'Subject',
    headerStyle: {
      width: '20%',
      minWidth: 100
    }
  },
  {
    id: 'note',
    label: 'Note',
    maxTextLen: 80,
    type: 'text',
    headerStyle: {
      width: '60%',
      minWidth: 200
    }
  },
  {
    id: 'lastUpdatedBy',
    label: 'Last Updated By',
    headerStyle: {
      width: '10%',
      minWidth: 100
    }
  },
  {
    id: 'lastUpdatedDateTime',
    type: 'datetime',
    label: 'Last Updated',
    headerStyle: {
      width: '10%',
      minWidth: 100
    }
  }
];
