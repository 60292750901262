import gql from 'graphql-tag';
import useExtendedMutation from '../../../../customHooks/useExtendedMutation';
import { GET_REVIEW_REPORT_BY_ID } from '../queries';

const ADD_SUMMARY_NOTE = gql`
  mutation addSummariesToReviewReport(
    $partitionKey: String
    $data: AddSummariesToReviewReportInput!
  ) {
    addSummariesToReviewReport(partitionKey: $partitionKey, data: $data) {
      id
      summary
      includeInInvoice
      createdDateTime
      lastUpdatedBy
      lastUpdatedDateTime
      version
    }
  }
`;

const serializer = ({ tenantId, reviewReportId, summary }) => ({
  variables: {
    partitionKey: tenantId,
    data: {
      reviewReportId,
      summaries: [summary]
    }
  }
});

export const useAddReviewReportSummaryNote = reviewReportId => {
  return useExtendedMutation(ADD_SUMMARY_NOTE, {
    serializer,
    update: (cache, { data: { addSummariesToReviewReport } }) => {
      const newSummaryNote = addSummariesToReviewReport?.[0];
      if (!newSummaryNote) {
        return;
      }

      const cachedData = cache.readQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        }
      });
      cache.writeQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        },
        data: {
          getReviewReportById: {
            ...(cachedData?.getReviewReportById || {}),
            summaries: {
              __typename: 'SummaryConnection',
              items: [
                ...(cachedData.getReviewReportById?.summaries?.items || []),
                {
                  ...newSummaryNote
                }
              ]
            }
          }
        }
      });
    }
  });
};
