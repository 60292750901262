import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import useStyles from './Messages.styles';
import Message from './components/Message';

const Messages = props => {
  const { messages, user } = props;
  const classes = useStyles();
  const [numRowsToShow, setNumRowsToShow] = useState(5);
  const [activeMessage, setActiveMessage] = useState();
  const [messageComponents, setMessageComponents] = useState([]);

  useEffect(() => {
    if (messages && messages.length) {
      if (activeMessage) {
        setMessageComponents([
          <Message
            user={user}
            messageData={activeMessage}
            onClose={() => setActiveMessage(null)}
            showContent
          />
        ]);
      } else {
        setMessageComponents(
          messages?.slice(0, numRowsToShow).map(message => (
            <Message
              user={user}
              messageData={message}
              onOpen={() => {
                setActiveMessage(message);
              }}
            />
          ))
        );
      }
    } else {
      setMessageComponents(
        <div className={classes.noMsgText}>
          <Typography>No Messages Found</Typography>
        </div>
      );
    }
  }, [messages, activeMessage, numRowsToShow]);

  return (
    <Box className={classes.root}>
      {messageComponents}
      {!activeMessage && numRowsToShow < messages.length ? (
        <>
          <div className={classes.showLabelContainer}>
            <Button
              onClick={() => setNumRowsToShow(numRowsToShow + 5)}
              className={classes.showLabel}
            >
              SHOW MORE
            </Button>
          </div>
          <div className={classes.spacer} />
        </>
      ) : (
        <div className={classes.spacer} />
      )}
    </Box>
  );
};

export default Messages;
