import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  greenHeading: {
    display: 'flex',
    fontWeight: 'bold',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  description: {
    color: theme.palette.grayscale(50),
    maxWidth: 355
  },
  itemBorder: {
    borderBottom: `1px solid ${theme.palette.grayscale(85)}`
  },
  notEditableValue: {
    display: 'flex',
    flex: 1,
    padding: theme.spacing(0.75),
    background: theme.palette.grayscale(100)
  },
  settingIcon: {
    color: theme.palette.secondary.main
  },
  warningIcon: {
    color: theme.palette.grayscale(60),
    marginLeft: theme.spacing(1),
    height: '16px',
    width: '16px'
  },
  formEndDivider: { marginTop: 40, marginBottom: 6 },
  descriptionInput: {
    flex: `2 !important`
  },
  textInputWrapper: {
    flex: 1
  },
  textInput: {
    fontWeight: 'bold',
    fontSize: '16px',
    padding: theme.spacing(0.75),
    border: `6px solid ${theme.palette.grayscale(100)}`,
    background: theme.palette.grayscale(100),
    margin: `0`,
    '&:hover': {
      background: theme.palette.grayscale(92)
    },
    '& input': {
      padding: 0
    }
  },
  selectionInput: {
    padding: theme.spacing(0.3),
    paddingBottom: theme.spacing(0.55),
    paddingTop: theme.spacing(0.55),
    '& p': {
      marginLeft: '5px',
      textTransform: 'lowercase',
      color: theme.palette.text.primary
    },
    '&:hover': {
      background: theme.palette.grayscale(92)
    }
  },
  selectionWrapper: {
    border: `5px solid ${theme.palette.grayscale(100)}`,
    backgroundColor: `${theme.palette.grayscale(100)} !important`,
    minWidth: '170px',
    padding: 0
  },
  selectionInputPlaceholder: {
    '& label': {
      fontSize: '20px',
      color: theme.palette.text.primary
    }
  }
}));

export default styles;
