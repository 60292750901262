import { useDrag } from 'react-dnd';
import { isScheduledProjectVisitOrNonVisit } from './NonVisitCard.helpers';

export const useNonVisitDrag = ({ type, event, srcTech }) => {
  return useDrag({
    item: { type, data: event, srcTech },
    canDrag: () => isScheduledProjectVisitOrNonVisit(event)
  });
};

export const useEventBorderDrag = ({ type, event, srcTech, weekView }) => {
  return useDrag({
    item: { type, data: event, srcTech },
    canDrag: () => !weekView && isScheduledProjectVisitOrNonVisit(event)
  });
};
