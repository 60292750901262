import gql from 'graphql-tag';

const createProjectType = gql`
  mutation saveProjectTypes($partitionKey: String!, $data: SaveProjectTypesToCompanyInput!) {
    saveProjectTypes(partitionKey: $partitionKey, data: $data) {
      id
      name
      sortOrder
    }
  }
`;

export default createProjectType;
