export const row = (contents, options) => ({
  options: {
    direction: 'row',
    ...options
  },
  contents
});

export const col = (contents, options) => ({
  options: {
    direction: 'column',
    ...options
  },
  contents
});
