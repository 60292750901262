import { useRef, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { deleteView } from '../components/ResponsiveTable/ViewHelper';
import { logErrorWithCallback } from '../utils';
import {
  GET_INVOICE_PRESETS,
  SAVE_INVOICE_PRESET_SETTING
} from '../scenes/Invoices/InvoiceDetail/InvoiceSetting/InvoiceSetting.gql';
import {
  GET_QUOTE_PRESETS,
  SAVE_QUOTE_PRESET_SETTING
} from '../scenes/Quotes/components/QuoteSettings/QuoteSetting.gql';
import { InvoiceSettingConstants } from '../scenes/Invoices/constants';
import { SettingConstants } from '../scenes/Quotes/constants';
import { usePresetsQuery, updatePresetPayload } from './usePresetsQuery';

/**
 * @param {function} update The service to wrap.
 */

const useMutatePresets = ({ user, snackbar, entityName, isAssetEnabled }) => {
  let query;
  let mutation;
  let accessType;
  if (entityName === InvoiceSettingConstants.INVOICE_PRESETS) {
    query = GET_INVOICE_PRESETS;
    mutation = SAVE_INVOICE_PRESET_SETTING;
    accessType = 'PUBLIC';
  } else if (entityName === SettingConstants.QUOTE_PRESETS) {
    query = GET_QUOTE_PRESETS;
    mutation = SAVE_QUOTE_PRESET_SETTING;
    accessType = 'PRIVATE';
  }

  const [updatePreset] = useMutation(mutation);
  const { data: presets, loading, refetch } = usePresetsQuery(
    user,
    query,
    entityName,
    isAssetEnabled
  );
  const presetsRef = useRef(presets);
  presetsRef.current = presets;
  const findPreset = id => {
    return presetsRef.current.find(v => v[entityName]?.value === id);
  };

  const doesPresetNameExist = name => {
    return presetsRef.current.find(v => v[entityName]?.label === name) !== undefined;
  };

  const handleSaveAsNewPreset = useCallback(
    async (newName, settings) => {
      if (doesPresetNameExist(newName)) {
        return snackbar('error', 'Preset name already exists!');
      }

      const newPreset = cloneDeep(settings);
      newPreset[entityName] = {
        label: newName,
        value: undefined
      };
      const payload = updatePresetPayload(
        user,
        undefined,
        newName,
        newPreset,
        accessType,
        entityName
      );

      try {
        const response = await updatePreset({
          variables: payload
        });
        if (entityName === SettingConstants.QUOTE_PRESETS) {
          newPreset[entityName].value = response.data.saveQuotePresetSetting.id;
        } else if (entityName === InvoiceSettingConstants.INVOICE_PRESETS) {
          newPreset[entityName].value = response.data.saveInvoicePresetSetting.id;
        }
        snackbar('success', 'Preset saved successfully!');
        refetch();
      } catch (error) {
        logErrorWithCallback(error, snackbar, 'Unable to save preset, please try again later');
      }
    },
    [snackbar, user, updatePreset]
  );

  const handleUpdatePreset = useCallback(
    async settings => {
      const presetName = settings[entityName]?.label;
      const presetId = settings[entityName]?.value;
      if (!presetId || !findPreset(presetId)) {
        return snackbar('error', 'Please save as a new preset or select an existing preset');
      }

      const newPreset = cloneDeep(settings);
      newPreset[entityName] = {
        label: presetName,
        value: presetId
      };

      const payload = updatePresetPayload(
        user,
        presetId,
        presetName,
        newPreset,
        accessType,
        entityName
      );
      try {
        await updatePreset({
          variables: payload
        });
        snackbar('success', 'Preset updated successfully!');
        refetch();
      } catch (error) {
        logErrorWithCallback(error, snackbar, 'Unable to update preset, please try again later');
      }
    },
    [snackbar, user, updatePreset]
  );

  const handleRenamePreset = async (newName, id) => {
    if (doesPresetNameExist(newName)) {
      return snackbar('error', 'Preset name already exists!');
    }

    const preset = findPreset(id);
    if (!preset) return;

    const renamedPreset = { ...preset };
    renamedPreset[entityName] = {
      label: newName,
      value: preset.value
    };
    const payload = updatePresetPayload(user, id, newName, renamedPreset, accessType, entityName);

    try {
      await updatePreset({
        variables: payload
      });
      snackbar('success', 'Preset renamed successfully!');
      refetch();
    } catch (error) {
      logErrorWithCallback(error, snackbar, 'Unable to rename preset, please try again later');
    }
  };

  const handleDuplicatePreset = async id => {
    const preset = findPreset(id);
    if (!preset) return;

    const copyPreset = cloneDeep(preset);
    const newName = `${preset[entityName]?.label} copy`;
    copyPreset[entityName] = {
      label: newName,
      value: undefined
    };
    const payload = updatePresetPayload(
      user,
      undefined,
      newName,
      copyPreset,
      accessType,
      entityName
    );

    try {
      const response = await updatePreset({
        variables: payload
      });
      if (entityName === SettingConstants.QUOTE_PRESETS) {
        preset[entityName].value = response.data.saveQuotePresetSetting.id;
      } else if (entityName === InvoiceSettingConstants.INVOICE_PRESETS) {
        preset[entityName].value = response.data.saveInvoicePresetSetting.id;
      }
      snackbar('success', 'Preset copied successfully!');
      refetch();
    } catch (error) {
      logErrorWithCallback(error, snackbar, 'Unable to copy preset, please try again later');
    }
  };

  const handleDeletePreset = async (id, label) => {
    await deleteView({ id, displayName: label || '' }, user, snackbar);
    refetch();
  };

  return {
    presets,
    loading,
    findPreset,
    createPreset: handleSaveAsNewPreset,
    updatePreset: handleUpdatePreset,
    renamePreset: handleRenamePreset,
    duplicatePreset: handleDuplicatePreset,
    deletePreset: handleDeletePreset
  };
};

export default useMutatePresets;
