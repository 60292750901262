import gql from 'graphql-tag';

const getJobDetails = gql`
  query getJobDetails($partitionKey: String!, $sortKey: String!) {
    getJob(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      tenantId
      hierarchy
      entityType
      customIdentifier
      jobNumber
      customerProvidedPONumber
      jobTypeId
      jobTypeName
      jobTypeInternal
      priority
      customerId
      customerName
      customerSortKey
      customerRepName
      customerPropertyId
      customerPropertyName
      customerPropertyTypeName
      customerPropertySortKey
      amountNotToExceed
      amountQuoted
      quoteId
      quote(entityConnection: "Quote") {
        id
        quoteNumber
        customIdentifier
      }
      bestContact
      parentId
      parentSortKey
      createdDateTime
      createdBy
      departments(entityConnection: "JobDepartment") {
        items {
          id
          mappedEntityId
          mappedEntityType
          mappedEntityValue
          systemEntityId
          systemEntityType
          hierarchy
          sortKey
          invertedSortKey
          partitionKey
          mappedEntity {
            ... on Department {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              tagName
            }
          }
        }
      }
      notes(entityConnection: "Note") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          subject
          note
          createdBy
          lastUpdatedDate
          lastUpdatedDateTime
          lastUpdatedBy
          version
        }
      }
      parentEntity {
        ... on CustomerProperty {
          companyName
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          notes(entityConnection: "Note") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              subject
              note
              createdBy
              lastUpdatedDate
              lastUpdatedDateTime
              lastUpdatedBy
            }
          }
          customerReps(entityConnection: "CustomerRep") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              invertedSortKey
              hierarchy
              entityType
              mappedEntity {
                ... on CustomerRep {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  name
                  firstName
                  lastName
                  email
                  cellPhone
                  landlinePhone
                  isSmsOptOut
                  bestContact
                  contactRole
                }
              }
            }
          }
          companyAddresses(entityConnection: "CompanyAddress") {
            items {
              id
              addressType
              addressLine1
              addressLine2
              city
              state
              zipcode
            }
          }
          propertyAssets(entityConnection: "PropertyAsset") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              assetName
              make
              modelNumber
              serialNo
              installDate
              version
              notes(entityConnection: "Note") {
                items {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  note
                  createdBy
                  version
                  lastUpdatedDate
                  lastUpdatedDateTime
                  lastUpdatedBy
                }
              }
            }
          }
          attachments(entityConnection: "Attachment") {
            items {
              fileName
              customFileName
              fileUrl
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              description
              comment
              createdDate
              createdDateTime
              createdBy
            }
          }
        }
      }
      visits: visitsView(entityConnection: "Visit") {
        items {
          visitNumber
          version
          status
          startTime
          endTime
          scheduledFor
          hierarchy
          partitionKey
          sortKey
          onHold
          onHoldReason
          onRoute
          delayed
          delayedReason
          detailsSent
          tentativeDate
          extraTechsRequired
          extraTechsNumber
          minimumDuration
          actualDuration
          tentativePeriod
          tentativeDate
          tentativeTime
          id
          entityType
          parentId
          schedules(entityConnection: "Schedule") {
            items {
              id
              employeeSortKey
              parentId
              hierarchy
              entityType
              version
              tenantId
              tenantCompanyId
              partitionKey
              parentSortKey
              sortKey
              entityType
              scheduledFor
              startTime
              endTime
              onRoute
              delayed
              delayedReason
              onHold
              onHoldReason
              timeSheets(entityConnection: "TimeSheet") {
                items {
                  visitDate
                  clockInTime
                  clockOutTime
                  parentId
                  hierarchy
                  id
                  entityType
                  version
                  tenantId
                  tenantCompanyId
                  partitionKey
                  parentSortKey
                  sortKey
                }
              }
            }
          }
          primaryTechs(entityConnection: "VisitPrimaryTech") {
            items {
              sortKey
              invertedSortKey
              mappedEntity {
                ... on Employee {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  partitionKey
                  hierarchy
                  entityType
                  firstName
                  lastName
                  email
                }
              }
            }
          }
          extraTechs(entityConnection: "VisitExtraTech") {
            items {
              id
              sortKey
              invertedSortKey
              mappedEntity {
                ... on Employee {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  entityType
                  sortKey
                  hierarchy
                  entityType
                  firstName
                  lastName
                  email
                }
              }
            }
          }
        }
      }
      attachments(entityConnection: "Attachment") {
        items {
          fileName
          customFileName
          fileUrl
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          description
          comment
          createdDate
          createdDateTime
          createdBy
        }
      }
      authorizedBy(entityConnection: "authorizedBy") {
        ... on CustomerRep {
          id
          firstName
          lastName
          version
          isActive
        }
      }
      authorizedById
      customerProvidedWONumber
    }
  }
`;

export default getJobDetails;
