import React from 'react';
import { connect } from 'react-redux';

import { snackbarOn, setOpenQuickAddModal } from 'redux/actions/globalActions';
import { PageHeader, UserPermission, JobsTable } from 'components';
import CreateEntryButton from 'components/Buttons/CreateEntryButton';
import ErrorBoundaries from 'scenes/Error';
import { PermissionConstants  } from 'utils/AppConstants';
import { QuickAddModal } from 'utils/constants';

import './JobList.css';

const JobsList = ({ user, addModal }) => {

  const pageHeaderButtons = [
    <CreateEntryButton
      handleAdd={() => addModal(QuickAddModal.CREATE_JOB)}
      label="New Job"
      caslKey={PermissionConstants.OBJECT_JOB}
      key="createJob"
    />
  ];

  return (
    <ErrorBoundaries>
      <UserPermission I="read" action={PermissionConstants.OBJECT_JOB}>
        <PageHeader
          pageMapKey="jobs"
          userLocale={user.locale}
          overrideHeaderButtons={pageHeaderButtons}
        />
        <JobsTable />
      </UserPermission>
    </ErrorBoundaries>
  );
};

const mapStateToProps = state => ({
  user: state.user
});
const mapDispatchToProps = { snackbar: snackbarOn, addModal: setOpenQuickAddModal };

const connectedJobs = connect(mapStateToProps, mapDispatchToProps)(JobsList);

export default connectedJobs;
