import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider, Button, MultiSelect, Select, Input, Modal } from '@buildhero/sergeant';
import { snackbarOn } from 'redux/actions/globalActions';

import { Logger } from 'services/Logger';
import { CustomerPropertyService } from 'services/core';

import { SgtAlgoliaMultiSelect } from 'components';
import FormLineItem from 'components/Tasks/components/FormLineItem';
import PartsAndMaterialsLineItem from 'components/Tasks/components/PartsAndMaterialsLineItem';
import { formatAssetOption } from 'components/Tasks';
import { Mode } from 'utils/constants';
import { OpenTaskStatus, TaskConstants } from 'utils/AppConstants';

import { getAssetsByCustomerPropertyById } from 'scenes/Customer/Assets/gql';

import { bundleIndex } from 'constants/algoliaIndex';

import { useLazyFetchPricebookEntry } from 'components/Tasks/components/useLazyFetchPricebookEntry';
import { batchMutatePropertyTasks } from './BatchMutatePropertyTasks.gql';

const AddEditTaskOnPropertyModal = ({
  mode = Mode.ADD,
  open,
  onClose,
  propertyId,
  refetchTasks,
  task = {
    // only used in Mode.EDIT
    taskId: '',
    assetId: '',
    taskName: '',
    description: '',
    forms: [],
    partsAndMaterials: [],
    version: 1
  }
}) => {
  const [asset, setAsset] = useState({});
  const [assets, setAssets] = useState([]);
  const [taskName, setTaskName] = useState('');
  const [description, setDescription] = useState('');
  const [forms, setForms] = useState([]);
  const [partsAndMaterials, setPartsAndMaterials] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const snackbar = useCallback((...args) => dispatch(snackbarOn(...args)), [dispatch]);
  const user = useSelector(s => s.user);
  const defaultPriceBookId = useSelector(s => s.company.defaultPriceBookId);
  const fetchPricebookEntry = useLazyFetchPricebookEntry();
  const formOptions = useSelector(s =>
    (s.company?.forms?.items || [])
      .filter(f => f.associatedEntityType === 'Task')
      .map(f => ({
        id: f.id,
        label: f.name,
        value: { ...f, required: false }
      }))
  );

  const { data } = useQuery(getAssetsByCustomerPropertyById, {
    variables: { id: propertyId },
    fetchPolicy: 'no-cache'
  });

  const assetOptions = useMemo(
    () => data?.getCustomerPropertyById.propertyAssets.items.map(formatAssetOption),
    [data]
  );

  useEffect(() => {
    setAsset(assetOptions?.find(({ id }) => id === task.assetId));
    setTaskName(task.taskName);
    setDescription(task.description);
    setForms(task.forms);
    setPartsAndMaterials(task.partsAndMaterials);
  }, [open]);

  const getTaskNumbers = async () => {
    const service = new CustomerPropertyService();

    const { data: taskNumberData, error } = await service.getTaskNumber();
    if (error) {
      snackbar('error', 'Unable to create task, please try again later', error);
      return null;
    }

    const startingTaskNumber = JSON.parse(taskNumberData.getCurrentCounter).task;
    return Array.from({ length: assets.length || 1 }).map((_, index) => startingTaskNumber + index);
  };

  const addTasksToProperty = async () => {
    if (taskName === '') {
      snackbar('error', 'Please enter a task name');
      return;
    }

    const promises = partsAndMaterials.map(part =>
      fetchPricebookEntry({
        pricebookId: defaultPriceBookId,
        productSortKey: part.value?.productSortKey
      })
    );

    const pricebookEntries = await Promise.all(promises);

    setLoading(true);

    const taskNumbers = await getTaskNumbers();
    if (!taskNumbers) return;

    const payload = {
      customerPropertyId: propertyId,
      tasks: taskNumbers.map((taskNumber, i) => ({
        name: taskName,
        taskNumber,
        taskTypeInternal: TaskConstants.PENDING,
        description,
        assetId: assets.length ? assets[i].id : undefined,
        assetValue: assets.length ? assets[i].label : undefined,
        status: OpenTaskStatus.OPEN,
        isActive: true,
        formData: forms.map(form => ({
          formId: form.value.id,
          formDefinitionId: form.value.latestPublishedFormDefinition.id,
          formDefinitionSortKey: form.value.latestPublishedFormDefinitionSortKey,
          formSortKey: form.value.sortKey,
          isRequired: form.value.required
        })),
        taskEntries: partsAndMaterials.map((pm, j) => ({
          productId: pm.value.productId,
          priceBookEntryId: pricebookEntries[j]?.id,
          name: pm.value.name,
          sortOrder: j,
          description: pm.value.description,
          quantity: pm.value.quantity
        }))
      }))
    };

    const service = new CustomerPropertyService();
    await service.addTasksToCustomerProperty(user.tenantId, payload);

    setLoading(false);
    refetchTasks();
    onClose();
  };

  const [updateTask] = useMutation(batchMutatePropertyTasks);

  const editTask = async () => {
    if (taskName === '') {
      snackbar('error', 'Please enter a task name');
      return;
    }

    setLoading(true);

    const params = {
      partitionKey: user.tenantId,
      data: {
        customerPropertyId: propertyId,
        tasks: [
          {
            id: task.taskId,
            assetId: asset?.id || null,
            name: taskName,
            description,
            formData: forms.map(form => ({
              id: form.value.updateId,
              formId: form.value.id,
              formDefinitionId: form.value.latestPublishedFormDefinition.id,
              formDefinitionSortKey: form.value.latestPublishedFormDefinitionSortKey,
              formSortKey: form.value.sortKey,
              isRequired: form.value.required
            })),
            taskEntries: partsAndMaterials.map((pm, j) => ({
              id: pm.value.updateId,
              productId: pm.value.productId,
              priceBookEntryId: pm.value.priceBookEntryId,
              name: pm.value.name,
              sortOrder: j,
              description: pm.value.description,
              quantity: pm.value.quantity
            }))
          }
        ]
      }
    };

    try {
      await updateTask({ variables: params });
      refetchTasks();
    } catch (e) {
      snackbar('error', e.message || 'Failed to edit task, please try again later', e);
      Logger.error(e);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <ThemeProvider>
      <Modal
        open={open}
        title={mode === Mode.ADD ? 'Add Task(s) to Property' : 'Edit Task'}
        onClose={() => {
          onClose();
          setAsset({});
          setAssets([]);
          setTaskName('');
          setDescription('');
          setForms([]);
          setPartsAndMaterials([]);
          setLoading(false);
        }}
        actions={
          <Button
            fullWidth
            onClick={mode === Mode.ADD ? addTasksToProperty : editTask}
            loading={loading}
          >
            {mode === Mode.ADD ? 'Add Task(s)' : 'Save'}
          </Button>
        }
        PaperProps={{ style: { minWidth: 800, overflowY: 'unset' } }}
      >
        {mode === Mode.ADD ? (
          <MultiSelect
            grouped
            label="Assets"
            placeholder="Search & Select Assets"
            showSearchIcon
            selectedOptions={assets}
            options={assetOptions}
            onChange={setAssets}
            showChips
          />
        ) : (
          <Select
            label="Asset"
            placeholder="Search & Select Asset"
            defaultValue={asset}
            options={assetOptions}
            onChange={setAsset}
            searchable
            clearable
          />
        )}
        <div style={{ height: 16 }} />
        <Input
          label="Task"
          placeholder="Enter Task"
          onBlur={e => setTaskName(e.target.value)}
          multiline
          required
          defaultValue={taskName}
        />
        <div style={{ height: 16 }} />
        <Input
          label="Task Description"
          placeholder="Enter Description"
          onBlur={e => setDescription(e.target.value)}
          multiline
          defaultValue={description}
        />
        <div style={{ height: 16 }} />
        <MultiSelect
          lineItemComponent={(option, selectedOptions) => (
            <FormLineItem
              formsChange={setForms}
              option={option}
              selectedOptions={selectedOptions}
            />
          )}
          onChange={setForms}
          options={formOptions}
          placeholder="Search & Select Forms"
          showChips
          showSearchIcon
          selectedOptions={forms}
          label="Forms"
        />
        <div style={{ height: 16 }} />
        <SgtAlgoliaMultiSelect
          options={{
            label: 'Parts & Materials',
            lineItemComponent: (option, selectedOptions) => (
              <PartsAndMaterialsLineItem
                partsAndMaterialsChange={setPartsAndMaterials}
                option={option}
                selectedOptions={selectedOptions}
              />
            ),
            onChange: setPartsAndMaterials,
            options: [],
            placeholder: 'Search & Select Parts & Materials',
            showChips: true,
            showSearchIcon: true,
            selectedOptions: partsAndMaterials,
            restructureAlgoliaHitToOptions: hit => {
              return [
                {
                  id: hit.id,
                  label: hit.name,
                  rightLabel: hit.description,
                  value: {
                    productId: hit.id,
                    productSortKey: hit.sortKey,
                    name: hit.name,
                    code: hit.code,
                    description: hit.description,
                    quantity: 1
                  }
                }
              ];
            },
            algoliaFilter: `entityType:Product`,
            algoliaIndex: bundleIndex
          }}
        />
      </Modal>
    </ThemeProvider>
  );
};

export default AddEditTaskOnPropertyModal;
