import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import AddCircle from '@material-ui/icons/AddCircle';
import StorageService from 'services/StorageService';
import { Logger } from 'services/Logger';

import CircularDeterminate from 'components/Spinners/CircularDeterminate';
import styles from './styles';

const image = {
  url: '',
  title: 'AddImage',
  style: {
    height: 136,
    width: '100%',
    minWidth: 85,
    maxWidth: 136,
    resizeMode: 'center'
  }
};

export const logoFunctions = {
  image
};

class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: '',
      progress: 0
    };
  }

  uploadFile = async (event, tenantId) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    image.name = file.name;
    try {
      const progressCallback = ({ loaded, total }) => {
        const progress = (loaded / total) * 100;
        this.setState({ progress: progress === 100 ? 99 : progress });
      };
      const storageService = new StorageService();
      const filename = `${tenantId}/${Date.now()}-${file.name}`;
      const fileURL = await storageService.uploadFile(file, filename, progressCallback);
      const imgUrl = await storageService.getFile(fileURL);
      this.setState({ progress: 100 });
      this.setState({ imageUrl: imgUrl });
      return fileURL;
    } catch (error) {
      Logger.info(`Error uploading image ${error}`);
      return '';
    }
  };

  getImageUrl = async fileName => {
    if (!fileName) {
      return;
    }
    try {
      if (!this.state.imageUrl) {
        const storageService = new StorageService();
        const url = await storageService.getFile(fileName);
        this.setState({ imageUrl: url });
      }
    } catch (error) {
      Logger.info(`Error reading image ${error}`);
    }
  };

  render() {
    const { field, form, classes, ...rest } = this.props;
    if (field.value) {
      this.getImageUrl(field.value);
    }
    const { progress } = this.state;
    const inProgress = progress > 0 && progress < 100;
    return (
      <div className={classes.root}>
        <ButtonBase
          focusRipple
          key={image.title}
          className={classes.image}
          focusVisibleClassName={classes.focusVisible}
          style={image.style}
        >
          <label htmlFor={field.name}>
            <input
              accept="image/jpeg, image/png"
              type="file"
              id={field.name}
              style={{ visibility: 'hidden' }}
              onChange={async event => {
                const logoURL = await this.uploadFile(event, rest.user.tenantId);
                form.setFieldValue(field.name, logoURL, false);
              }}
            />
            {!inProgress ? (
              <>
                <span
                  className={classes.imageSrc}
                  style={{
                    backgroundImage: `url(${this.state.imageUrl})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat'
                  }}
                />
                <span className={classes.imageBackdrop} />
                <span className={classes.imageButton}>
                  <AddCircle />
                  <Typography
                    component="span"
                    variant="body1"
                    color="inherit"
                    className={classes.imageTitle}
                  >
                    {rest.label}
                  </Typography>
                </span>
              </>
            ) : (
              <CircularDeterminate completed={progress || 0} />
            )}
          </label>
        </ButtonBase>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ImageUpload);
