import React from 'react';
import {
  TextField,
  withStyles,
  Typography,
  Checkbox,
  Grid,
  FormControlLabel
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import styles from './styles';

/* considered as one off component while developing */

function PreferredContact(props) {
  const { form, classes } = props;
  const { values } = form;

  const handleChange = (fieldName, value) => {
    const newValue = { ...values, [fieldName]: value };
    form.setValues(newValue);
  };

  const handlePrimaryContact = event => {
    const newValue = { ...values, bestContact: event.target.value };
    form.setValues(newValue);
  };
  return (
    <>
      <FormControl>
        <RadioGroup
          aria-label="primaryContact"
          name="primaryContact"
          value={values.bestContact}
          onChange={handlePrimaryContact}
        >
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="caption" className={classes.label}>
                Please add your contact information and indicate your preferred method of contact
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.gridContainer}>
            <Grid item>
              <TextField
                variant="filled"
                fullWidth
                className={classes.textField}
                name="email"
                error={form.errors && form.errors.email && form.errors.email !== ''}
                helperText={form.errors.email}
                value={values.email}
                label="Email address"
                onChange={event => handleChange('email', event.target.value)}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                className={classes.radioboxLabel}
                value="Email"
                control={<Radio />}
                label="Primary contact method"
              />
            </Grid>
          </Grid>
          <Grid container className={classes.gridContainer}>
            <Grid item>
              <TextField
                variant="filled"
                fullWidth
                error={form.errors && form.errors.landlinePhone && form.errors.landlinePhone !== ''}
                helperText={form.errors.landlinePhone}
                className={classes.textField}
                name="landlinePhone"
                value={values.landlinePhone}
                label="Landline"
                onChange={event => handleChange('landlinePhone', event.target.value)}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                className={classes.radioboxLabel}
                value="Landline"
                control={<Radio />}
                label="Primary contact method"
              />
            </Grid>
          </Grid>
          <Grid container className={classes.gridContainer}>
            <Grid item>
              <TextField
                variant="filled"
                fullWidth
                className={classes.textField}
                name="cellPhone"
                value={values.cellPhone}
                error={form.errors && form.errors.cellPhone && form.errors.cellPhone !== ''}
                helperText={form.errors.cellPhone}
                label="Cell phone"
                onChange={event => handleChange('cellPhone', event.target.value)}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                className={classes.radioboxLabel}
                value="Cell phone"
                control={<Radio />}
                label="Primary contact method"
              />
            </Grid>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={event => handleChange('isSmsOptOut', event.target.checked)}
                  checked={values.isSmsOptOut}
                />
              }
              label="SMS notifications?"
              labelPlacement="start"
              className={classes.checkboxLabel}
            />
          </Grid>
        </RadioGroup>
      </FormControl>
    </>
  );
}

const styledPreferredContact = withStyles(styles)(PreferredContact);
export default styledPreferredContact;
