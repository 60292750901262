import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Photo from '@material-ui/icons/Photo';
import { getThumbnailImageUrl } from 'utils';
import styles from './styles';

class TableThumbnail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      [this.props.fileName]: ''
    };
  }

  componentDidMount = async () => {
    const { fileName } = this.props;
    this._isMounted = true;

    if (fileName && !fileName.toLowerCase().includes('pdf')) {
      const url = await getThumbnailImageUrl(fileName);
      if (this._isMounted && !this.state[fileName]) this.setState({ [fileName]: url });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  getDefaultIcon = imageUrl => {
    const { fileName } = this.props;
    if (fileName && fileName.toLowerCase().includes('pdf')) {
      return <PictureAsPdfIcon />;
    }
    if (imageUrl === null) {
      return <Photo />;
    }
    return null;
  };

  render() {
    const { classes, fileName } = this.props;
    const imageUrl = this.state[fileName];

    return (
      <Grid item className={classes.thumbnailContainer}>
        {imageUrl ? (
          <img alt={this.props.alt} src={imageUrl} className={classes.thumbnail} />
        ) : (
          this.getDefaultIcon(imageUrl)
        )}
      </Grid>
    );
  }
}

TableThumbnail.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TableThumbnail);
