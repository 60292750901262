import { useEffect, useRef } from 'react';

/* This hook is used for modals in Review Report page to fix the spinner issue.
   It takes in a state machine service (third return from useMachine) and
   returns a function that registers stopLoading from SergeantModal as a callback. */
export const useSetStopLoading = service => {
  // If service is nullish, simply call stopLoading immediately
  // Technician Report should not use this hook
  if (!service) return stopLoading => stopLoading();

  const stopLoadingRef = useRef(null);

  /* subscribe to state changes */
  useEffect(() => {
    const subscription = service.subscribe(state => {
      // if state changes from 'saving' to 'loaded' and stopLoadingRef has a function saved
      // call stopLoading of the recently open modal and reset it
      if (state.value === 'loaded' && stopLoadingRef.current) {
        stopLoadingRef.current();
        stopLoadingRef.current = null;
      }
    });

    return subscription.unsubscribe;
  }, [service]);

  // return a function to set stopLoadingRef
  const setStopLoading = stopLoading => {
    stopLoadingRef.current = stopLoading;
  };

  return setStopLoading;
};
