const truckTemplateFields = {
  unitOfMeasure: {
    default: 'unitOfMeasure'
  },
  minQuantity: {
    default: 'minQuantity'
  },
  maxQuantity: {
    default: 'maxQuantity'
  }
};

const truckTemplateContent = () => [
  {
    options: { size: 2, direction: 'row', grow: 0, margin: [0, 0, 10, 0] },
    contents: [
      {
        options: {
          direction: 'column',
          grow: 1,
          padding: [2, 25, 2, 0]
        },
        contents: [
          {
            component: { default: 'TextInput' },
            source: 'quantity',
            options: {
              label: 'Quantity',
              placeholder: '',
              type: 'number'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          grow: 1,
          padding: 0
        },
        contents: [
          {
            component: { default: 'FieldWithLabel' },
            source: 'unitOfMeasure',
            options: {
              label: 'UOM'
            }
          }
        ]
      }
    ]
  },
  {
    options: { size: 2, direction: 'row', grow: 0, margin: [0, 0, 10, 0] },
    contents: [
      {
        options: {
          width: '50%',
          direction: 'column',
          grow: 1,
          padding: [2, 5, 2, 0]
        },
        contents: [
          {
            component: { default: 'TextInput', TaskTemplate: null },
            source: 'minQuantity',
            options: {
              label: 'Min Quantity',
              placeholder: '',
              type: 'number',
              default_value: null,
              isRequired: true
            }
          }
        ]
      },
      {
        options: {
          width: '50%',
          direction: 'column',
          grow: 1,
          padding: [2, 10, 2, 15]
        },
        contents: [
          {
            component: { default: 'TextInput', TaskTemplate: null },
            source: 'maxQuantity',
            options: {
              label: 'Max Quantity',
              placeholder: '',
              type: 'number',
              default_value: null,
              isRequired: true
            }
          }
        ]
      }
    ]
  }
];

const commonForm = () => ({
  fields: {
    quantity: {
      default: 'quantity'
    },
    ...truckTemplateFields
  },
  layouts: {
    default: {
      options: {
        direction: 'column',
        grow: 0
      },
      contents: [
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0,
            margin: [10, 0],
            wrap: 'wrap',
            align: 'center'
          },
          contents: [
            {
              options: {
                direction: 'column',
                paddingRight: 25
              },
              contents: [
                {
                  component: { default: 'CurrencyInput' },
                  source: 'unitCost',
                  options: {
                    label: 'Unit Cost'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                placeContent: 'center'
              },
              contents: [
                {
                  component: { default: 'CheckboxInput' },
                  source: 'taxable',
                  options: {
                    label: 'TAXABLE'
                  }
                }
              ]
            }
          ]
        },
        ...truckTemplateContent()
      ]
    }
  }
});

export default commonForm;
