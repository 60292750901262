import gql from 'graphql-tag';

const getReviewReportPartsByJob = gql`
  query getReviewReportPartsByJob($partitionKey: String!, $sortKey: String!) {
    getJob(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      jobNumber
      visits: visitsView(entityConnection: "Visit") {
        items {
          visitNumber
          id
          sortKey
          status
          reviewReports(entityConnection: "ReviewReport") {
            items {
              id
              parentId
              sortKey
              status
            }
          }
        }
      }
      inventoryParts(entityConnection: "InventoryPart") {
        items {
          id
          itemName
          description
          quantity
          unitCost
          unitPrice
          addedBy
          inventoryType
          vendorName
          unitOfMeasure
          markup
          parentId
          amount
          taxable
          totalAmountTaxable
          tax
          createdBy
          includeInInvoice
          version
        }
      }
    }
  }
`;

export default getReviewReportPartsByJob;
