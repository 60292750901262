import React from 'react';
import { Image as PDFImage, View } from '@react-pdf/renderer';
import { PDFFormComponents } from '@buildhero/sergeant';

export const PDFImageComp = React.memo(
  props => {
    const { url, options, key } = props;
    const { height, padding, objectFit } = options;
    return (
      <PDFImage
        key={key}
        style={{
          height: height ? height * 0.75 : undefined, // TODO: figure out better way to do this..
          padding,
          objectFit: objectFit || 'contain'
        }}
        src={url}
      />
    );
  },
  (prevProps, nextProps) => prevProps.url === nextProps.url
);

const AttachmentComp = props => {
  const { displayValue, options } = props;

  return (
    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
      {displayValue && displayValue.length > 0 ? (
        displayValue.map(
          attachment =>
            (attachment.fileUrl || attachment.url) && (
              <PDFImageComp
                key={attachment.id}
                url={attachment.url || attachment.fileUrl}
                options={options}
              />
            )
        )
      ) : (
        <PDFFormComponents.BodyText displayValue="No Attachments" options={options} />
      )}
    </View>
  );
};

export default AttachmentComp;
