import { EmailInvoiceType } from 'utils/constants';

const customerRepToCustomerSchema = props => {
  const { repNotes, ...rest } = props;

  rest.firstName = rest?.firstName?.trim();
  rest.lastName = rest?.lastName?.trim();
  rest.contactRole = rest?.contactRole?.trim();
  rest.email = rest?.email?.trim();
  rest.name = `${rest?.firstName || ''} ${rest?.lastName || ''}`;

  // Notes passed to this service has tenantId, sortkey, partition key, hirarchy etc which is not needed here
  // The system fields should be removed when all batch entity mutations are removed
  let processedNotes;
  if (repNotes?.length) {
    processedNotes = repNotes.map(note => ({
      id: note.id,
      note: note.note,
      subject: note.subject
    }));
  }

  const payload = {
    firstName: rest.firstName || null,
    lastName: rest.lastName || null,
    name: rest.name || null,
    contactRole: rest.contactRole || null,
    email: rest.email || null,
    landlinePhone: rest.landlinePhone || null,
    cellPhone: rest.cellPhone || null,
    bestContact: rest.bestContact || null,
    isSmsOptOut: rest.isSmsOptOut || null,
    emailInvoice: rest.emailInvoice || EmailInvoiceType.NonBilling,
    // soft delete will not come here and that is the only place where rep will be made inactive
    isActive: rest.status ? rest.status.toLowerCase() === 'active' : true,
    notes: processedNotes
  };

  if (rest.id) {
    payload.id = rest.id;
    payload.version = rest.version || 1;
  }

  return payload;
};

export default customerRepToCustomerSchema;
