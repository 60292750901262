import { useCallback, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryStringUtils from 'query-string';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';

export const useQueryString = (options = {}) => {
  const { pathname, search } = useLocation();
  const history = useHistory();

  const setQueryString = useCallback(
    (queryStringData = {}) => {
      const cleanedData = omitBy(queryStringData, val => isUndefined(val) || val === '');
      if (Object.keys(cleanedData).length) {
        const newSearch = `?${queryStringUtils.stringify(cleanedData, { encode: false })}`;
        if (search !== newSearch) {
          history.push(pathname + newSearch);
        }
      } else if (search) {
        history.push(pathname);
      }
    },
    [history, pathname, search]
  );

  return useMemo(
    () => ({
      data: search ? queryStringUtils.parse(search, options) : {},
      setQueryString
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  );
};
