import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MUIForm } from '@buildhero/sergeant';
import { DefaultButton, FullScreenModal } from 'components';
import formLayout from './layout';
import AfterHoursTimings from './AfterHoursTimings';
import { getAfterHoursTimings } from './helpers';

const filterEmployeesByDep = (selectedDepartments = [], options = []) =>
  options.filter(employee => {
    const employeeDepartments = employee.departmentIds;
    if (!employee.departmentIds?.length) {
      return false;
    }
    return employeeDepartments.some(departmentId => selectedDepartments.includes(departmentId));
  });

const getInitialEmployeeOptions = (afterHourSetting, employees) => {
  if (afterHourSetting?.departmentIds?.length) {
    return filterEmployeesByDep(afterHourSetting?.departmentIds, employees);
  }
  return [];
};

const Form = ({
  isOpen,
  afterHourSetting,
  employeeOptions: employees,
  departmentOptions,
  onClose,
  onSave
}) => {
  const [service, setService] = useState();
  const [showSpinner, setShowSpinner] = useState(false);
  const [formData, setFormData] = useState({
    employeeOptions: getInitialEmployeeOptions(afterHourSetting, employees),
    validEmployees: (filterEmployeesByDep(afterHourSetting.departmentIds, employees) || []).map(
      employee => employee?.value
    )
  });

  if (!isOpen) return null;

  const handleFieldChange = (fieldName, fieldValue) => {
    if (fieldName !== 'departmentIds') return;
    const updatedEmployeeOptions = filterEmployeesByDep(fieldValue, employees);
    const validEmployees = updatedEmployeeOptions.map(employee => employee.value);

    setFormData({
      employeeOptions: updatedEmployeeOptions,
      validEmployees
    });
  };

  const configuration = formLayout({
    departmentOptions,
    ...formData
  });

  return (
    <FullScreenModal
      title={`${afterHourSetting.id ? 'Edit' : 'New'} After Hours Schedule`}
      modalHeaderButtons={[
        <DefaultButton
          showSpinner={showSpinner}
          spinnerProps={{ styles: { margin: 0, marginLeft: 5 } }}
          color="primary"
          key="saveSchedule"
          label="Save schedule"
          handle={() => {
            setShowSpinner(true);
            service.submit();
          }}
        />
      ]}
      open={isOpen}
      handleClose={onClose}
      fixedHeader
    >
      <MUIForm
        layout="default"
        data={{
          ...afterHourSetting,
          afterHoursTimings: getAfterHoursTimings(afterHourSetting.afterHoursTimings)
        }}
        onCreateService={setService}
        configuration={configuration}
        onFieldChange={handleFieldChange}
        onComplete={data => onSave(data, setShowSpinner)}
        customComponents={{
          AfterHoursTimings: ({ ...afterHourProps }) => <AfterHoursTimings {...afterHourProps} />
        }}
      />
    </FullScreenModal>
  );
};

Form.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  afterHourSetting: PropTypes.object.isRequired,
  employeeOptions: PropTypes.array.isRequired,
  departmentOptions: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default Form;
