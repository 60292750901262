import { useExtendedFetchForPMAPI as useExtendedFetch } from 'customHooks/useExtendedFetch';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import gql from 'graphql-tag';

export const useGetPayApplication = ({ projectId, noInclude }, options = {}) => {
  return useExtendedFetch(
    {
      url: `PayApplication/byproject/${projectId}`,
      params: {
        include: noInclude ? [] : '*'
      }
    },
    {
      errorData: [],
      defaultData: [],
      ...options
    }
  );
};

export const useGetPayApplicationById = ({ id, noInclude }, options = {}) => {
  return useExtendedFetch(
    {
      url: `PayApplication/${id}`,
      params: {
        include: noInclude ? [] : '*'
      }
    },
    {
      ...options,
      errorData: {}
    }
  );
};

export const useGetNextPayApplicationNumber = (projectId, options = {}) => {
  return useExtendedFetch(
    {
      url: 'PayApplication/nextnumber',
      params: projectId ? { projectId } : {}
    },
    {
      errorData: [],
      ...options
    }
  );
};

export const usePayApplicationSummaryTotals = ({ projectId, paNumber }, options = {}) => {
  return useExtendedFetch(
    {
      url: `PayApplication/summary/totals`,
      params: { projectId, paNumber }
    },
    {
      ...options
    }
  );
};

export const usePayApplicationLineItemTotals = ({ projectId, paNumber }, options = {}) => {
  return useExtendedFetch(
    {
      url: `PayApplication/lineitem/totals`,
      params: { projectId, paNumber }
    },
    {
      ...options
    }
  );
};

export const useAvailableLineItems = ({ projectId }, options = {}) => {
  return useExtendedFetch(
    {
      url: `PayApplication/lineitem/available`,
      params: { projectId }
    },
    {
      ...options
    }
  );
};

export const usePayApplicationSummary = ({ payApplicationId, noInclude }, options = {}) => {
  return useExtendedFetch(
    {
      url: `PayApplication/summary/${payApplicationId}`,
      params: {
        include: noInclude ? [] : '*'
      }
    },
    {
      ...options
    }
  );
};

export const usePayApplicationLineItem = ({ id, noInclude }, options = {}) => {
  return useExtendedFetch(
    {
      url: `PayApplication/lineItem/${id}`,
      params: {
        include: noInclude ? [] : '*'
      }
    },
    {
      ...options
    }
  );
};

export const usePayApplicationSummaryByProject = ({ projectId, noInclude }, options = {}) => {
  return useExtendedFetch(
    {
      url: `PayApplication/summary/byproject/${projectId}`,
      params: {
        include: noInclude ? [] : '*',
        additionalRelations: [
          'PayApplication.[sendToCustomer, returnToObject, PayApplicationAttachment, Invoice]'
        ],
        orderBy: 'number'
      }
    },
    {
      ...options
    }
  );
};

export const usePayApplicationLineItemByProject = ({ projectId, noInclude }, options = {}) => {
  return useExtendedFetch(
    {
      url: `PayApplication/lineitem/byproject/${projectId}`,
      params: {
        include: noInclude ? [] : '*'
      }
    },
    {
      ...options
    }
  );
};

export const usePutPayApplication = (options = {}) => {
  return useExtendedFetch(
    {},
    {
      manual: true,
      ...options
    }
  );
};

export const usePayAppTableData = ({ projectId, paNumber }, options = {}) => {
  return useExtendedFetch(
    {
      url: `PayApplication/lineitem/tabledata/`,
      params: { projectId, paNumber }
    },
    {
      ...options
    }
  );
};

const GET_NEXT_INVOICE_NUMBER = gql`
  query getNextNumber($tenantId: String!, $tenantCompanyId: String!) {
    getCurrentCounterByPartitionKey(
      partitionKey: $tenantId
      tenantCompanyId: $tenantCompanyId
      counterType: "invoice"
    )
  }
`;

export const useGetNextInvoiceNumber = ({ tenantId, tenantCompanyId, onCompleted }) => {
  return useExtendedQuery(GET_NEXT_INVOICE_NUMBER, {
    variables: {
      tenantId,
      tenantCompanyId
    },
    onCompleted,
    skip: true,
    fetchPolicy: 'no-cache'
  });
};
