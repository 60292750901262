import React from 'react';
import PropTypes from 'prop-types';
import isNumber from 'lodash/isNumber';

import { boxHeight, boxWidth } from './TimeMarker.constants';
import { useStyles } from './TimeMarker.styles';

const TimeMarker = ({ position, time, horizontal }) => {
  const classes = useStyles({ horizontal });

  if (!isNumber(position)) return null;

  return (
    <>
      <div
        className={classes.box}
        style={{
          left: horizontal ? 0 : position - boxWidth / 2,
          top: horizontal ? position - boxHeight / 2 : 0
        }}
      >
        {time}
      </div>
      <div
        className={classes.line}
        style={{
          left: horizontal ? 0 : position,
          top: horizontal ? position : 0
        }}
      />
    </>
  );
};

TimeMarker.propTypes = {
  horizontal: PropTypes.bool,
  position: PropTypes.number,
  time: PropTypes.string
};

TimeMarker.defaultProps = {
  horizontal: false,
  position: 0,
  time: ''
};

export default TimeMarker;
