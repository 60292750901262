import React from 'react';
import ContactIcon from '@material-ui/icons/Contacts';
import ApplicationIcon from '@material-ui/icons/Tab';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SettingsIcon from '@material-ui/icons/Settings';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import StoreIcon from '@material-ui/icons/Store';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import QueryBuilderOutlined from '@material-ui/icons/QueryBuilderOutlined';
import AssignmentOutlined from '@material-ui/icons/AssignmentOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
// custom svg icons but can still apply mui styling
import AdjustmentsIcon from '../assets/Icons/AdjustmentsIcon';
import Jobs from '../assets/Icons/Jobs';
import Customers from '../assets/Icons/Customers';
import DispatchIcon from '../assets/Icons/Dispatch';
import Properties from '../assets/Icons/Properties';
import FollowUps from '../assets/Icons/FollowUps';
import Inventory from '../assets/Icons/Inventory';
import Invoices from '../assets/Icons/Invoices';
import Payments from '../assets/Icons/Payments';
import Quotes from '../assets/Icons/Quotes';
import Reports from '../assets/Icons/Reports';
import Timesheets from '../assets/Icons/Timesheets';
import Projects from '../assets/Icons/Projects';
import WIPReport from '../assets/Icons/WIP_Report';
import Procurement from '../assets/Icons/Procurement';
import ServiceAgreements from '../assets/Icons/ServiceAgreements';
import MaintenanceIcon from '../assets/Icons/Maintenance';
import ServiceChannel from '../assets/Icons/serviceChannel';

const IconMap = ({ icon, className, style }) => {
  const iconArray = {
    adjustments: <AdjustmentsIcon className={className} style={style} />,
    dispatch: <ApplicationIcon className={className} style={style} />,
    dispatchV2: <DispatchIcon className={className} style={style} />,
    addCircleIcon: <AddCircleIcon />,
    contactIcon: <ContactIcon className={className} style={style} />,
    applicationIcon: <ApplicationIcon className={className} style={style} />,
    customerIcon: <Customers className={className} style={style} />,
    localShippingIcon: <LocalShippingIcon className={className} style={style} />,
    settingsIcon: <SettingsIcon className={className} style={style} />,
    deviceHubIcon: <DeviceHubIcon className={className} style={style} />,
    permIdentityIcon: <PermIdentityIcon className={className} style={style} />,
    storeIcon: <StoreIcon className={className} style={style} />,
    jobsIcon: <Jobs className={className} style={style} />,
    invoicesIcon: <Invoices className={className} style={style} />,
    quotesIcon: <Quotes className={className} style={style} />,
    projectIcon: <Projects className={className} style={style} />,
    newProcurementIcon: <Procurement className={className} style={style} />,
    paymentIcon: <Payments className={className} style={style} />,
    followUpsIcon: <FollowUps className={className} style={style} />,
    dashboardIcon: <BarChartIcon className={className} style={style} />,
    settings: <SettingsIcon className={className} style={style} />,
    productsIcon: <MenuBookIcon className={className} style={style} />,
    historyIcon: <QueryBuilderOutlined className={className} style={style} />,
    techReportsIcon: <AssignmentOutlined className={className} style={style} />,
    reportsIcon: <Reports className={className} style={style} />,
    propertyIcon: <Properties className={className} style={style} />,
    inventoryIcon: <Inventory className={className} style={style} />,
    procurementIcon: <Procurement className={className} style={style} />,
    timesheetsIcon: <Timesheets className={className} style={style} />,
    personnelIcon: <PeopleIcon className={className} style={style} />,
    maintenanceIcon: <MaintenanceIcon className={className} style={style} />,
    serviceAgreementIcon: <ServiceAgreements className={className} style={style} />,
    wipReportIcon: <WIPReport className={className} style={style} />,
    serviceChannelIcon: <ServiceChannel className={className} style={style} />,
  };
  return iconArray[icon];
};

export default IconMap;
