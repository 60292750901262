import removeObjProperties from 'utils/removeObjProperties';
import { checkPermission } from 'utils';
import { Mode } from 'utils/constants';
import { PermissionConstants, EmployeeStatus } from 'utils/AppConstants';

const getRoles = (details = []) => details.map(({ mappedEntity }) => mappedEntity.id);
const getSkills = (details = []) => details.map(({ mappedEntity }) => mappedEntity.id);
const getDepartments = (details = []) => details.map(({ mappedEntity }) => mappedEntity.id);

export const constructIdObjItems = (ids = []) => ids.map(id => ({ id }));

export const getEmployeeDetail = (employee = {}) => {
  const appRoles = getRoles(employee.appRoles?.items);
  const departments = getDepartments(employee.departments?.items);
  const skills = getSkills(employee.skills?.items);
  const [appRole] = appRoles;
  const info = removeObjProperties({ ...employee, appRole, appRoles, departments, skills }, [
    '__typename'
  ]);
  return info;
};

const getItems = ({ items = [] }, doSort = false) => {
  if (doSort) items.sort((item1, item2) => item2.createdDate - item1.createdDate);
  return items.map(item => removeObjProperties(item, ['version', '__typename', 'createdDate']));
};

export const splitBillingRateAndEmployee = detail => {
  const { billingRates, payrollCosts, labourTypeId, contactAddresses, ...employeeInfo } = detail;
  const [contactAddressDetail = {}] = getItems(contactAddresses);

  const billingInfo = {
    labourTypeId,
    billingRates: getItems(billingRates, true),
    payrollCosts: getItems(payrollCosts, true)
  };
  return {
    billingInfo,
    employeeInfo: {
      ...contactAddressDetail,
      ...employeeInfo,
      contactAddressId: contactAddressDetail.id
    }
  };
};

export const checkAllowToInvite = ({ employee, user }) => {
  const { status, isActive } = employee;
  const hasPermission = checkPermission('update', [PermissionConstants.OBJECT_EMPLOYEE], user);
  const { INVITED, CREATED, DEACTIVATED, ACTIVE } = EmployeeStatus;
  return [CREATED, INVITED, DEACTIVATED, ACTIVE].includes(status) && isActive && hasPermission;
};

export const checkAllowToChangeStatus = ({ employee, user }) => {
  const hasPermission = checkPermission('update', [PermissionConstants.OBJECT_EMPLOYEE], user);
  const employeeIsSelf = employee.id === user.employeeId;
  return hasPermission || !employeeIsSelf;
};

export const getModalTitle = ({ mode }) => {
  if (mode === Mode.VIEW) {
    return 'Employee Info';
  }

  if (mode === Mode.EDIT) {
    return 'Edit Employee';
  }

  return 'New Employee';
};
