const quoteTotal = ({
  settingsTotals,
  SettingConstants,
  smartFieldInfo,
  smartFieldsMap,
  convertToCurrencyStringCheck,
  styles,
  fontFamilyStyle
}) =>
  settingsTotals[SettingConstants.SHOW_TOTAL]
    ? `<tr>
      <td style="${styles}width:50%;border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);" colspan="2"><strong>Total</strong></td>
      <td colspan="3" style="${styles}text-align:right;border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);" class="restricted">
      <span fontfamily=${fontFamilyStyle} contenteditable="false" >${convertToCurrencyStringCheck(
        smartFieldInfo[smartFieldsMap.TOTAL_OVERRIDE] ?? smartFieldInfo[smartFieldsMap.TOTAL]
      )}</span></td>
    </tr>`
    : '';

export default quoteTotal;
