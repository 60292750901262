import gql from 'graphql-tag';

const getPaymentTermsForCompany = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      paymentTerms(entityConnection: "PaymentTerm") {
        items {
          id
          name
          value
          type
          version
          lastUpdatedDateTime
        }
      }
    }
  }
`;

export default getPaymentTermsForCompany;
