import gql from 'graphql-tag';

const addTaskToCustomerProperty = gql`
  mutation addTasksToCustomerProperty(
    $partitionKey: String!
    $data: AddTasksToCustomerPropertyInput!
  ) {
    addTasksToCustomerProperty(partitionKey: $partitionKey, data: $data) {
      id
      version
      name
      description
      departmentId
      departmentValue
      taskTypeId
      taskTypeValue
      taskTypeInternal
      taskNumber
      status
      jobTypeId
      jobTypeValue
      assetId
      assetValue
      nextDueDate
      productBundleId
      productBundleValue
      isActive
    }
  }
`;

export default addTaskToCustomerProperty;
