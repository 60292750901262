const styles = theme => ({
  visitNavigation: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.29,
    color: theme.palette.secondary.main
  },
  pageActionButton: {
    maxWidth: '176px',
    width: '100%',
    height: '36px',
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(3.75),
    marginLeft: theme.spacing(2.5)
  },
  auditText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#959595',
    marginLeft: 20
  },
  auditTextValue: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#959595',
    marginBottom: 12,
    marginLeft: 20
  },
  sectionTitle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.15,
    color: theme.palette.primary.main,
    marginTop: 40
  },
  greySectionTitle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.13,
    color: theme.palette.grayscale(25),
    marginTop: 60,
    marginBottom: 5
  },
  noteSubject: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0.11,
    color: theme.palette.grayscale(25)
  },
  noteTimestamp: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    color: theme.palette.grayscale(60)
  },
  note: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    color: theme.palette.grayscale(25),
    fontWeight: 'normal'
  },
  blueDivider: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: 12
  },
  signature: {
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'row'
  },
  signatureImage: {
    width: 192,
    height: 60
  },
  signatureText: {
    padding: 10,
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#959595'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  linkText: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    fontWeight: 600
  },
  breakcrumbText: {
    textDecoration: 'none'
  },
  linkPtag: {
    maxWidth: 176,
    textAlign: 'center',
    marginBottom: 15
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.grayscale(60),
    fontSize: 12,
    letterSpacing: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal'
  },
  entityName: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.brand.logoBlue,
    fontSize: 15,
    letterSpacing: 0.3,
    fontWeight: 500,
    lineHeight: 'normal',
    paddingTop: 4
  },
  addresses: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.grayscale(25),
    fontSize: 14,
    letterSpacing: 0.24,
    fontWeight: 'normal',
    lineHeight: 'normal',
    paddingTop: 7,
    wordBreak: 'break-all',
    whiteSpace: 'pre-line'
  },
  stickyStyle: {
    position: 'fixed',
    top: '60px',
    paddingTop: '8px',
    width: '100%',
    backgroundColor: theme.palette.grayscale(94),
    zIndex: 5
  },
  stickyButtons: { paddingTop: '46px' },
  cardHeader: {
    height: 15,
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.1,
    color: '#3f3f3f',
    padding: 10
  },
  cardText: {
    height: 26,
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.24,
    color: '#3f3f3f',
    padding: 10,
    overflow: 'hidden'
  },
  footer: {
    minWidth: 238,
    height: 15,
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#959595',
    paddingBottom: 11,
    paddingTop: 10
  },
  bodyContent: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.24,
    color: '#3f3f3f',
    paddingTop: 14,
    paddingBottom: 10
  },
  subject: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: '900',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.24,
    color: '#3f3f3f',
    paddingTop: 14
  },
  reportInfoRow: {
    marginBottom: theme.spacing(2)
  }
});

export default styles;
