import { makeStyles } from '@material-ui/core/styles';

export const useStyles = () => ({
  sidebar: ({ palette, default: { spacing } }) => {
    return {
      borderRight: `1px solid ${palette.grayscale(90)}`,
      width: '218px',
      paddingRight: spacing(2)
    };
  },
  projectImg: ({ default: { spacing } }) => ({
    marginTop: spacing(2),
    width: '204px',
    height: '204px',
    objectFit: 'cover'
  })
});

// must be MUI styles to merge with existing mui classes
export const useMenuStyles = makeStyles(theme => ({
  menuItem: {
    padding: theme.spacing(1),
    height: '32px',
    color: theme.palette.text.primary,
    borderBottom: '2px solid transparent',
    backgroundColor: theme.palette.grayscale(100),
    '&:hover': {
      backgroundColor: theme.palette.grayscale(98),
      color: theme.palette.secondary.main
    }
  },
  menuSelected: {
    backgroundColor: theme.palette.grayscale(94),
    color: theme.palette.secondary.main
  },
  listTexts: {
    padding: 0,
    flex: 'none',
    flexGrow: 0,
    fontWeight: 'inherit',
    textTransform: 'capitalize',
    cursor: 'pointer',
    lineHeight: '16px'
  },
  listIcons: {
    color: 'inherit',
    margin: 0,
    marginLeft: 8
  }
}));
