import gql from 'graphql-tag';

const addDiscountToReviewReport = gql`
  mutation addDiscountToReviewReport(
    $partitionKey: String!
    $data: AddDiscountToReviewReportInput!
  ) {
    addDiscountToReviewReport(partitionKey: $partitionKey, data: $data) {
      id
      sortKey
      itemName
      createdDateTime
      reasonForDiscount
      departmentName
      accountingRefIdOfClass
      taxable
      version
      amount
      includeInInvoice
    }
  }
`;

export default addDiscountToReviewReport;
