import React, { useEffect, useState } from 'react';

import { Switch, ThemeProvider } from '@buildhero/sergeant';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';

import { DefaultButton, Spinner } from 'components';
import LaborRateSection from 'components/LabourRateSection';
import MaterialRateSection from 'components/MaterialRateSection';
import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { logErrorWithCallback } from 'utils';
import { PricingStrategy, TenantSetting } from 'utils/constants';
import { getMarginFromMarkup, getMarkupFromMargin } from 'utils/onCalcChange';

import { getRateCard, updateServiceAgreement } from '../../service';
import AddNewView from '../Components/AddNewView';

import styles from './style';

const shouldCreateNewPricebook = ({ noPricebookSelected, rateCard }) => {
  // weirdness where a materialMarkupEntry {end: null, rate: 0, start: 0} appears for cloned SA's for no apparent reason,
  // @TODO fix root cause in BUOP-12129 and then remove relevantMaterialMarupEntries from here.
  const relevantMaterialMarkupEntries =
    rateCard.materialMarkupEntries &&
    rateCard.materialMarkupEntries.filter(e => !(e.end === null && e.rate === 0 && e.start === 0))
      .length;

  if (
    relevantMaterialMarkupEntries ||
    (rateCard.labourEntries && rateCard.labourEntries.length) ||
    rateCard.laborRate ||
    rateCard.materialRate
  ) {
    // custom markup, need new pricebook
    return true;
  }

  if (noPricebookSelected) {
    // use the default pricebook
    return false;
  }
  if (
    rateCard.labourPricebookId === rateCard.materialMarkupPricebookId &&
    !rateCard.createNewPricebook
  ) {
    // use the common pricebook
    return false;
  }
  return true;
};

const RateCard = props => {
  const { user, classes, agreementInfo, onRateCardUpdate, isReadOnly } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isDefault, setIsDefault] = useState(true);
  const [rateCard, setRateCard] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [priceBookConfig, setPriceBookConfig] = useState(PricingStrategy.MARKUP);
  const pricingStrategy = useSelector(s => s.settings.pricingStrategy);
  const marginEnabled = pricingStrategy === PricingStrategy.MARGIN;
  const parseRateCardJSON = json => json && JSON.parse(json);

  useEffect(() => {
    const fetchRateCard = async () => {
      setIsLoading(true);
      const { rateCardJSON } = await getRateCard(agreementInfo.id, user);
      const parsedRateCard = parseRateCardJSON(rateCardJSON);
      if (marginEnabled) {
        parsedRateCard.materialMarkupEntries = parsedRateCard.materialMarkupEntries?.map(m => ({
          ...m,
          rate: getMarginFromMarkup(m.rate)
        }));
      }
      setRateCard(parsedRateCard);
      setIsDefault(!parsedRateCard || Object.keys(parsedRateCard).length === 0);
      setIsLoading(false);
    };
    if (agreementInfo.id && !rateCard) fetchRateCard();
  }, [user, agreementInfo.id, rateCard]);

  if (!rateCard || isLoading) return <Spinner />;

  const setRates = (key, value) => {
    const newRateCard = { ...rateCard, [key]: value };

    if (key === 'labourPricebookId' && value === rateCard.materialMarkupPricebookId) {
      newRateCard.createNewPricebook = false;
    }
    if (key === 'materialMarkupPricebookId' && value === rateCard.labourPricebookId) {
      newRateCard.createNewPricebook = false;
    }

    setRateCard(newRateCard);
  };

  const saveRates = async () => {
    setIsSubmitting(true);
    // get labourEntries from the laborRate object which has latest info
    // laborRate object only populated if the rates are edited so backup with existing rateCard data
    const labourEntries = rateCard.labourPricebookId
      ? []
      : rateCard.laborRate?.reduce((acc, d) => {
          const billingHourTypeIds = Object.keys(d).filter(str => str.includes('-'));
          return [
            ...acc,
            ...billingHourTypeIds.map(id => ({
              isActive: true,
              labourTypeId: d.id,
              costCodeId: d.costCodeId,
              revenueTypeId: d.revenueTypeId,
              billingHourTypeId: id,
              rate: d[id].rate || 0
            }))
          ];
        }, []) || rateCard.labourEntries;

    try {
      const noPricebookSelected =
        !rateCard.labourPricebookId && !rateCard.materialMarkupPricebookId;

      const rateCardJSON = {
        labourPricebookId: rateCard.labourPricebookId,
        materialMarkupPricebookId: rateCard.materialMarkupPricebookId,
        labourEntries,
        materialMarkupEntries: !rateCard.materialMarkupPricebookId
          ? rateCard.materialRate || rateCard.materialMarkupEntries
          : [{ start: 0, end: null, rate: 0 }],
        createNewPricebook: shouldCreateNewPricebook({ noPricebookSelected, rateCard })
      };

      if (priceBookConfig === PricingStrategy.MARGIN || marginEnabled) {
        rateCardJSON.materialMarkupEntries = rateCardJSON.materialMarkupEntries?.map(m => ({
          ...m,
          rate: getMarkupFromMargin(m.rate)
        }));
      }

      const payload = {
        id: agreementInfo?.id,
        rateCard: rateCardJSON
      };

      const updated = await updateServiceAgreement(payload);
      const parsedRateCard = parseRateCardJSON(updated.rateCardJSON);

      if (priceBookConfig === PricingStrategy.MARGIN || marginEnabled) {
        parsedRateCard.materialMarkupEntries = parsedRateCard.materialMarkupEntries?.map(m => ({
          ...m,
          rate: getMarginFromMarkup(m.rate)
        }));
      }
      onRateCardUpdate({ status: updated.status });
      setRateCard(parsedRateCard);
      props.showSnackbar('success', `Successfully updated ratecard.`);

      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      logErrorWithCallback(
        error,
        snackbarOn,
        'Unable to update rate card details, please try again later'
      );
    }
  };

  const PricingStrategyRadioButton = pStrategy => {
    return (
      <Typography className={classes.option}>
        {
          (pStrategy === PricingStrategy.MARKUP
            ? Labels.configureWithMarkup
            : Labels.configureWithMargin)[user.locale]
        }
      </Typography>
    );
  };

  const pricingStrategyUpdate = async event => {
    const settingValue = event.target.value;
    setPriceBookConfig(settingValue);
  };

  return (
    <div>
      {isDefault ? (
        <AddNewView
          label={Labels.addRateCard[user.locale]}
          handleAddNew={() => setIsDefault(false)}
          isReadOnly={isReadOnly}
          key="addratecard"
          description="Please add rate card."
        />
      ) : (
        <>
          <div className={classes.saveButton}>
            <DefaultButton
              variant="contained"
              label={Labels.saveRates[user.locale]}
              disabled={isReadOnly || isSubmitting}
              showSpinner={isSubmitting}
              handle={saveRates}
            />
          </div>
          <ErrorBoundaries>
            <LaborRateSection
              rateCardData={rateCard}
              isReadOnly={isReadOnly}
              showSaveButton
              setRates={setRates}
              dropDownColWidth={4}
              onChangeFormat={() => {
                setRateCard(rc => {
                  const newRateCard = { ...rc };
                  delete newRateCard.labourEntries;
                  delete newRateCard.laborRate;
                  return newRateCard;
                });
              }}
            />
          </ErrorBoundaries>

          {pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN && (
            <>
              <Typography variant="h6">Pricebook Configuration</Typography>
              <RadioGroup
                className={classes.priceBookConfiguration}
                value={priceBookConfig}
                onChange={pricingStrategyUpdate}
              >
                <FormControlLabel
                  value={PricingStrategy.MARKUP}
                  control={<Radio />}
                  label={PricingStrategyRadioButton(PricingStrategy.MARKUP)}
                />
                <FormControlLabel
                  value={PricingStrategy.MARGIN}
                  control={<Radio />}
                  label={PricingStrategyRadioButton(PricingStrategy.MARGIN)}
                />
              </RadioGroup>
            </>
          )}

          <ErrorBoundaries>
            <MaterialRateSection
              rateCardData={rateCard}
              isReadOnly={isReadOnly}
              setRates={setRates}
              dropDownColWidth={4}
              options={{ allowFormatChange: true }}
              priceBookConfig={
                pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN && priceBookConfig
              }
              onChangeFormat={() => {
                setRateCard(rc => {
                  const newRateCard = { ...rc };
                  delete newRateCard.materialMarkupEntries;
                  delete newRateCard.materialRate;
                  return newRateCard;
                });
              }}
            />
          </ErrorBoundaries>
          {rateCard?.labourPricebookId &&
            rateCard?.labourPricebookId === rateCard?.materialMarkupPricebookId && (
              <ThemeProvider>
                <div className={classes.createPricebookToggleContainer}>
                  <Switch
                    label="Create New Pricebook"
                    onChange={e =>
                      setRateCard({ ...rateCard, createNewPricebook: e.target.checked })
                    }
                    defaultChecked={rateCard.createNewPricebook}
                    horizontalLabel
                  />
                </div>
              </ThemeProvider>
            )}
        </>
      )}
    </div>
  );
};

RateCard.defaultProps = {
  isReadOnly: false
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  showSnackbar: (mode, message) => dispatch(snackbarOn(mode, message))
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatcherToProps)
)(RateCard);
