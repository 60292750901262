import React, { useMemo } from 'react';
import { MUIForm } from '@buildhero/sergeant';
import { Box } from '@material-ui/core';
import ErrorBoundaries from 'scenes/Error';
import { debounce, isEqual, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import defaultQuoteNotesForm from '../../meta/defaultQuoteNotesForm';

export default function Notes(props) {
  const {
    quoteInfo,
    handleCreateService,
    handleOnComplete,
    onQuoteInternalNotesSave,
    setUpdatedQuoteNotes,
    updatedQuoteNotes
  } = props;

  const getFormattedData = data => ({
    id: data?.id,
    issueDescription: data?.issueDescription || '',
    description: data?.description || ''
  });

  const quoteInternalNotesForm = useMemo(() => {
    const formattedData = getFormattedData(isEmpty(quoteInfo) ? updatedQuoteNotes : quoteInfo);
    return (
      <MUIForm
        configuration={defaultQuoteNotesForm()}
        data={formattedData}
        onCreateService={handleCreateService}
        onComplete={handleOnComplete}
        onFormChange={debounce(data => {
          if (data.id && !isEqual(formattedData, data)) {
            onQuoteInternalNotesSave(data);
            setUpdatedQuoteNotes(data);
          }
        }, 350)}
      />
    );
  }, [quoteInfo.id]);

  return (
    <Box width="50%">
      <ErrorBoundaries>{quoteInternalNotesForm}</ErrorBoundaries>
    </Box>
  );
}

Notes.propTypes = {
  quoteInfo: PropTypes.object.isRequired,
  onQuoteInternalNotesSave: PropTypes.func.isRequired,
  handleCreateService: PropTypes.func.isRequired,
  handleOnComplete: PropTypes.func.isRequired,
  setUpdatedQuoteNotes: PropTypes.func.isRequired,
  updatedQuoteNotes: PropTypes.object.isRequired
};
