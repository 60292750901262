import React from 'react';

import { connect } from 'react-redux';

import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';

function ReadonlyWrapper(props) {
  const { readOnly, style } = props;
  return (
    <>
      <ErrorBoundaries>
        <fieldset
          disabled={readOnly}
          style={{ padding: 0, border: 'none', width: '100%', ...style }}
        >
          {props.children}
        </fieldset>
      </ErrorBoundaries>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const ReduxConnectedReadonlyWrapper = connect(
  mapStateToProps,
  mapDispatcherToProps
)(ReadonlyWrapper);

export default ReduxConnectedReadonlyWrapper;
