/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getCompanies() {
  return await instance
    .get('companies')
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getCompanyById(id) {
  return await instance
    .get(`companies/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getCompanyBytenantId(tenantId) {
  if (!tenantId) return [];

  return await instance
    .get(`companies/bytenant/${tenantId}`, { params: { include: '*' } })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
