import { useState } from 'react';
import { useSubscription } from '@apollo/client';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';

const MUTATION_SUBSCRIPTION = gql`
  subscription mutationNotification(
    $partitionKey: String!
    $entityType: String
    $entityId: String
  ) {
    mutationNotification(
      partitionKey: $partitionKey
      entityType: $entityType
      entityId: $entityId
    ) {
      id
      sortKey
      entityType
      mutationType
      changeLog
    }
  }
`;

export const useMutationSubscription = (tenantId, id, entityType, fieldsToWatchArray) => {
  const [data, setData] = useState(null);
  useSubscription(MUTATION_SUBSCRIPTION, {
    fetchPolicy: 'no-cache',
    variables: {
      partitionKey: tenantId,
      entityType,
      entityId: id
    },
    onSubscriptionData: ({ subscriptionData }) => {
      const changeLog = JSON.parse(subscriptionData.data.mutationNotification.changeLog);
      const newData = changeLog?.reduce(
        (changes, change) =>
          // only update following field
          fieldsToWatchArray.includes(change.field)
            ? { ...changes, [change.field]: change.new }
            : changes,
        {}
      );
      if (!isEmpty(newData)) {
        setData(newData);
      }
    }
  });
  return data;
};
