const labelSchema = {
  newVisit_selectTask_Title: {
    en: 'Select task for visit',
    es: 'Seleccione la tarea para la visita'
  },
  newVisit_Title: {
    en: 'Add Visit',
    es: 'Agregar Visita'
  },
  editVisit_Title: {
    en: 'Edit Visit',
    es: 'Editar visita'
  },
  editVisit_Reschedule_Title: {
    en: 'Reschedule timeslot',
    es: 'Reschedule timeslot'
  },
  newVisit_selectTask_noTasks: {
    en: 'No tasks added to job',
    es: 'No hay tareas agregadas al trabajo'
  },
  newVisit_selecTech_Title: {
    en: 'Technician(s)',
    es: 'Técnico(s)'
  },
  newVisit_selectCrew_Title: {
    en: 'Crew',
    es: 'Equipo'
  },
  newVisit_selectCrew_selectLabel: {
    en: 'Select a Crew',
    es: 'Seleccione un equipo'
  },
  newVisit_selectForms_selectLabel_Title: {
    en: 'Forms',
    es: 'Formularios'
  },
  newVisit_selectForms_selectLabel: {
    en: 'Select Forms',
    es: 'Seleccione Formularios'
  },
  newVisit_selectTech_primaryLabel: {
    en: 'Primary tech',
    es: 'Tecnico primario'
  },
  newVisit_selectTech_moreTechsNeeded: {
    en: 'More techs are needed for the job:',
    es: 'Se necesitan más técnicos para el trabajo:'
  },
  newVisit_selectTech_noExtraLabel: {
    en: 'Number of extra techs',
    es: 'Número de técnicos adicionales'
  },
  newVisit_selectTech_selectLabel: {
    en: 'Select specific techs',
    es: 'Seleccionar técnicos específicos'
  },
  newVisit_selectAssets_Title: {
    en: 'Select assets',
    es: 'Seleccionar activos'
  },
  newVisit_selectAssets_Label: {
    en: 'Assets',
    es: 'activos'
  },
  newVisit_tentativeTimes_Title: {
    en: 'Date & Time',
    es: 'Date & Time'
  },
  newVisit_tentativeTimes_minDurationLabel: {
    en: 'Minimum duration',
    es: 'Duración mínima'
  },
  newVisit_tentativeTimes_periodLabel: {
    en: 'Choose',
    es: 'Escoger'
  },
  newVisit_tentativeTimes_dateLabel: {
    en: 'Date',
    es: 'Fecha'
  },
  newVisit_tentativeTimes_timeLabel: {
    en: 'Starting time',
    es: 'Hora de inicio'
  },
  viewVisit_scheduleInfo_scheduledFor: {
    en: 'Scheduled for',
    es: 'Programado para'
  },
  viewVisit_crew_primaryTechsLabel: {
    en: 'Primary technician',
    es: 'Tecnico primario'
  },
  viewVisit_crew_additionalTechsLabel: {
    en: 'Additional technicians',
    es: 'Tecnicos adicionales'
  },
  viewVisit_crew_tasksLabel: {
    en: 'Task(s)',
    es: 'Tarea(s)'
  },
  viewVisit_crew_notesLabel: {
    en: 'Notes',
    es: 'Notas'
  },
  viewVisit_departmentName: {
    en: 'Department name',
    es: 'Nombre de Departamento'
  },
  viewVisit_shortDescription: {
    en: 'Visit Description',
    es: 'Trabajo a realizar en esta visita'
  },
  viewVisit_assetsWorkedOn: {
    en: 'Assets worked on',
    es: 'Activos trabajados en'
  },
  viewVisit_crew_attachmentsLabel: {
    en: 'Attachments',
    es: 'Archivos adjuntos'
  },
  onHoldVisit_title: {
    en: '*Put visit on hold',
    es: 'Poner la visita en espera'
  },
  newVisit_department_title: {
    en: 'Department',
    es: 'Departamento'
  },
  newVisit_departmentNeeded: {
    en: '*Department needed',
    es: '*Departamento necesario'
  },
  newVisit_description_title: {
    en: 'Visit Description',
    es: 'Trabajo a realizar en esta visita'
  },
  newVisit_description_fieldName: {
    en: 'Short description',
    es: 'Breve descripción'
  }
};

export default labelSchema;
