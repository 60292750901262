import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';
import { departmentSearch } from 'services/API/department';

const defaultFlexStyle = '0 1 219px';
const wideItemFlexStyle = '0 0 454px';
const margin = '0px 8px 16px 8px';

const editItemFields = {
  department: { required: false },
  description: { required: false }
};

const editItemContents = () => {
  return [
    {
      options: {
        direction: 'row',
        flexWrap: 'nowrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                
                edit: 'SearchBar',
                
                default: 'Text'
              },
              source: { 

                edit: 'Department',

                default: 'Department.tagName'
              },
              options: {
                resultFormatFunction: department => `${department.tagName}`,
                searchFunction: departmentSearch,
                searchColumns: ['tagName'],
                label: 'Department',
                placeholder: 'Select Department',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false
              },
              
            }
          ]
        }
      ]
    },
    {
      options: {
        direction: 'row',
        flexWrap: 'nowrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: wideItemFlexStyle,
            margin: '0px 8px 0px 8px'
          },
          contents: [
            {
              component: {
                edit: 'TextInput'
              },
              source: 'description',
              options: {
                label: 'Description',
                placeholder: 'Enter Description',
                fullWidth: true,
                lines: 4,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary'
              }
            }
          ]
        }
      ]
    }
  ];
};

const editItemLayout = () => {
  return {
    fields: generateDefaultFieldsObject(editItemFields),
    layouts: {
      edit: {
        options: {
          pageSize: 'LETTER',
          grow: 0,
          width: 470
        },
        contents: editItemContents()
      }
    },
    validation: {
      type: 'object',
      properties: {},
      required: []
    },
    validationErrors: {}
  };
};

export { editItemFields, editItemLayout };
