import { sortBy } from 'lodash';
import getSelectOptions from 'utils/constructSelectOptions';
import { AccountType } from 'utils/constants';

export default function getDropDownOptions(company = {}) {
  let customerPropertyTypes = sortBy(company?.customerPropertyTypes?.items, 'sortOrder');
  customerPropertyTypes = getSelectOptions(customerPropertyTypes, 'tagName');

  let taxRateOptions = [{ label: 'None', value: '' }];
  taxRateOptions = taxRateOptions.concat(
    (company?.taxRates?.items || [])
      .filter(t => t.accountType !== AccountType.AP)
      .map(taxCode => {
        const label = `${taxCode?.name} - ${taxCode?.taxRate}`;
        return {
          value: taxCode?.id,
          label
        };
      })
  );
  return {
    customerPropertyTypes,
    taxRateOptions
  };
}

export const assetPayload = ({
  assetName,
  assetTypeId,
  make,
  modelNumber,
  serialNo,
  installDate,
  imageUrl,
  propertyZone,
  ownerIdentifier,
  internalIdentifier,
  location,
  id,
  version
}) => ({
  assetName,
  assetTypeId,
  make,
  modelNumber,
  serialNo,
  installDate,
  imageUrl,
  propertyZone,
  ownerIdentifier,
  internalIdentifier,
  location,
  id,
  version
});
