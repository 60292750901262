import gql from 'graphql-tag';
import useExtendedMutation from 'customHooks/useExtendedMutation';

const TRANSITION_MAN_DAY_MUTATION = gql`
  mutation transitionManDay($data: TransitionManDayInput!) {
    transitionManDay(data: $data) {
      id
      status
    }
  }
`;

const serializer = ({ id }) => ({
  variables: {
    data: {
      id,
      action: 'COMPLETE'
    }
  }
});

const optimisticResponseFactory = ({ id }) => ({
  transitionManDay: {
    id,
    status: 'COMPLETE'
  }
});

const CONFIRMATION_TEXT =
  'If any technicians working on this event ' +
  'are offline and have already started working on this event, ' +
  'any information that has not been uploaded from their mobile app ' +
  'may be lost. Are you sure you want to continue?';

const useCompleteProjectVisit = () => {
  return useExtendedMutation(TRANSITION_MAN_DAY_MUTATION, {
    serializer,
    optimisticResponseFactory,
    showConfirmation: true,
    confirmationModalOptions: {
      title: 'Complete Event',
      warn: true,
      modalText: CONFIRMATION_TEXT
    }
  });
};

export default useCompleteProjectVisit;
