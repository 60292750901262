import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';
import { customerRepSearch } from 'services/API/customerRep';
import { employeeSearch } from 'services/API/employee';

const margin = '0px 15px 21px 0px';

const createSubmittalPackageFields = {
  version: { required: false },
  subject: { required: true },
  dueDate: { required: true, nullable: true },
  status: { required: false },
  number: { required: false },
  SendTo: { required: true },
  ReturnTo: { required: false },
  specSection: { required: false },
  specDescription: { required: false }
};

const createSubmittalPackageContents = project => {
  return [
    // the 1st row
    {
      options: {
        direction: 'row'
      },
      contents: [
        // {
        //   options: {
        //     direction: 'column',
        //     margin
        //   },
        //   contents: [
        //     {
        //       component: {
        //         default: 'CustomFieldWithLabelStyled',
        //         edit: 'CustomFieldWithLabelStyled'
        //       },
        //       source: 'version',
        //       options: {
        //         label: 'Version',
        //         variant: 'standard',
        //         color: 'secondary'
        //       }
        //     }
        //   ]
        // },
        {
          options: {
            direction: 'column',
            margin
          },
          contents: [
            {
              component: {
                default: 'TextInput',
                edit: 'TextInput'
              },
              source: 'subject',
              options: {
                label: 'Subject',
                variant: 'standard',
                color: 'secondary',
                isRequired: true
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            margin
          },
          contents: [
            {
              component: {
                default: 'DateInput',
                edit: 'DateInput'
              },
              source: 'dueDate',
              options: {
                label: 'Due Date',
                variant: 'standard',
                color: 'secondary',
                isRequired: true
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            margin
          },
          contents: [
            {
              component: {
                default: 'CustomFieldWithLabelStyled',
                edit: 'SelectInput'
              },
              source: 'status',
              options: {
                label: 'Status',
                enableSort: false,
                valueSet: [
                  { label: 'Draft', value: 'Draft' },
                  { label: 'Sent', value: 'Sent' },
                  { label: 'Responded', value: 'Responded' },
                  { label: 'Approved', value: 'Approved' },
                  { label: 'Approved as Noted', value: 'Approved as Noted' }
                ],
                variant: 'standard',
                color: 'secondary'
              }
            }
          ]
        },
        {
          options: { direction: 'row' },
          contents: [
            { options: {}, contents: [] },
            {
              options: {
                direction: 'column',
                margin
              },
              contents: [
                {
                  component: {
                    default: 'CustomFieldWithLabelStyled',
                    edit: 'CustomFieldWithLabelStyled'
                  },
                  source: 'number',
                  options: {
                    label: 'Number',
                    variant: 'standard',
                    color: 'secondary',
                    type: 'number',
                    numeric: true
                  }
                }
              ]
            }
          ]
        }
      ]
    },

    // 2nd row
    {
      options: {
        direction: 'row'
      },
      contents: [
        {
          options: {
            direction: 'column',
            margin
          },
          contents: [
            {
              component: {
                default: 'SearchBar',
                edit: 'SearchBar'
              },
              source: 'SendTo',
              options: {
                resultFormatFunction: customerRep => customerRep.name,
                searchFunction: (value, searchCols) =>
                  customerRepSearch(value, searchCols, project?.customer?.id || undefined, project),
                sortFunction: (a, b) => (a.name > b.name ? 1 : -1),
                label: 'Send To',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                placeholder: 'Search contacts',
                isRequired: true
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            margin
          },
          contents: [
            {
              component: {
                default: 'SearchBar',
                edit: 'SearchBar'
              },
              source: 'ReturnTo',
              options: {
                resultFormatFunction: employee => employee.name,
                searchFunction: employeeSearch,
                filterFunction: data => data.filter(item => item.status === 'active'),
                sortFunction: (a, b) => (a.name > b.name ? 1 : -1),
                label: 'Return To',
                variant: 'standard',
                color: 'secondary',
                placeholder: 'Search contacts'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            margin
          },
          contents: [
            {
              component: {
                default: 'TextInput',
                edit: 'TextInput'
              },
              source: 'specSection',
              options: {
                label: 'Spec Section',
                variant: 'standard',
                color: 'secondary'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            margin,
            flex: '2'
          },
          contents: [
            {
              component: {
                default: 'TextInput',
                edit: 'TextInput'
              },
              source: 'specDescription',
              options: {
                label: 'Notes',
                variant: 'standard',
                color: 'secondary',
                lines: 4
              }
            }
          ]
        }
      ]
    }
  ];
};

const createSubmittalPackageLayout = project => {
  return {
    fields: generateDefaultFieldsObject(createSubmittalPackageFields),
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0,
          height: 'inherit'
        },
        contents: createSubmittalPackageContents(project)
      },
      edit: {
        options: {
          pageSize: 'LETTER',
          grow: 0,
          height: 'inherit'
        },
        contents: createSubmittalPackageContents(project)
      }
    }
  };
};

export { createSubmittalPackageFields, createSubmittalPackageLayout };
