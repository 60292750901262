import { SIGNED_IN, SIGNED_OUT } from '../constants';

export const logUser = (
  username,
  displayName,
  tenantId,
  tenantCompanyId,
  cognitoRole,
  employeeId,
  appPermissionRules,
  email
) => {
  const action = {
    type: SIGNED_IN,
    username,
    displayName,
    isAuthenticated: true,
    tenantId,
    tenantCompanyId,
    cognitoRole,
    employeeId,
    appPermissionRules,
    email,
    companySortKey: `${tenantId}_Company_${tenantCompanyId}`
  };
  return action;
};

export const signOut = () => {
  const action = {
    type: SIGNED_OUT,
    username: null,
    displayName: null,
    isAuthenticated: false,
    tenantId: null,
    tenantCompanyId: null,
    cognitoRole: null,
    appPermissionRules: null,
    email: null
  };
  return action;
};
