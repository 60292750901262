import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ThemeProvider, Button } from '@buildhero/sergeant';
import { makeStyles } from '@material-ui/core/styles';
import Context from 'components/Context';
import labels from 'meta/labels';
import { getAssignedFormDataFromForm } from 'utils/FormMetaConverter';
import { FullScreenModal } from 'components';
import { AppConstants, FormEntityType } from 'utils/AppConstants';
import { CREWS } from 'components/Visit/components/queries';
import { getPreferredTechnicians } from 'components/PreferredTechniciansForm';
import { CommonService } from 'services/core';
import AmplifyService from 'services/AmplifyService';
import { Logger } from 'services/Logger';
import { calculateScheduledUnixTime } from 'components/Visit/helper';
import moment from 'moment';
import { snackbarOn } from 'redux/actions/globalActions';
import { connect } from 'react-redux';
import { DEFAULT_VISIT_DURATION_IN_MIN } from '@dispatch/Dispatch.constants';
import useEmployees from 'customHooks/useEmployees';

import StepsStatus from './StepsStatus';
import VisitFormStep from './VisitFormStep';
import DateStep from './DateStep';
import TimeStep from './TimeStep';
import TechsStep from './TechsStep';
import Summary from './Summary';
import { BulkAddVisitsToJobs } from '../queries';
import { getMinutesFromHrs } from '../helpers';

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold'
  },
  formContainer: {
    flex: 1
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const getFirstAvailablePreferredTechniciansConfig = jobsPreferredTechniciansConfigMap => {
  const preferredTechniciansList = Object.entries(jobsPreferredTechniciansConfigMap).map(
    ([, config]) => {
      return getPreferredTechnicians(config);
    }
  );
  return preferredTechniciansList[0] || {};
};

const BulkVisitCreationModal = props => {
  const classes = useStyles();
  const { user, isOpen, snackbar, items, jobsPreferredTechniciansConfigMap } = props;
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [crews, setCrews] = useState([]);
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stepData, setData] = useState({});

  const [techs] = useEmployees({
    includeDepartments: true,
    filter: { isActive: { eq: true }, isTech: { eq: true } }
  });

  useEffect(() => {
    const preferredTechnicians = getFirstAvailablePreferredTechniciansConfig(
      jobsPreferredTechniciansConfigMap
    );
    const prefTechsData = _.pick(
      stepData,
      'departmentId',
      'crewId',
      'primaryTechnicianId',
      'additionalTechnicianIds'
    );
    if (_.isEmpty(prefTechsData) && !_.isEmpty(preferredTechnicians)) {
      setData(prevState => ({
        ...prevState,
        ...preferredTechnicians
      }));
    }
    // as we are using loadash to pick the eslint misses that. Having whole step data ends re-render till max limit
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    jobsPreferredTechniciansConfigMap,
    stepData.departmentId,
    stepData.crewId,
    stepData.primaryTechnicianId,
    stepData.additionalTechnicianIds
  ]);

  const getDepartmentsAndTech = useCallback(() => {
    const company = Context.getCompanyContext();
    const departmentList = company?.getCompany?.departments?.items || [];
    setDepartments(departmentList);
  }, []);

  const getCrewAndForms = useCallback(async () => {
    const appsyncClient = AmplifyService.appSyncClient();
    const commonService = new CommonService();

    const [crewsQueryResult, visitFormsQueryResult] = await Promise.all([
      appsyncClient.client.query({ query: CREWS }),
      commonService.getFormsAvailableToEntity(
        user.tenantId,
        `${user.tenantId}_Company_${user.tenantCompanyId}`,
        FormEntityType.VISIT
      )
    ]);

    const availableForms = (visitFormsQueryResult?.data?.getCompany?.forms?.items || []).filter(
      form => form.latestPublishedFormDefinition
    );
    setForms(availableForms);
    setCrews(crewsQueryResult?.data?.crews?.items ?? []);
  }, [user.tenantCompanyId, user.tenantId]);

  useEffect(() => {
    getDepartmentsAndTech();
    getCrewAndForms();
  }, [getDepartmentsAndTech, getCrewAndForms]);

  // used to keep track of the latest step that the user got to, so if the user
  // decides to go back a step, he can click back into any step up to that point
  const furthestStepIndex = useRef(currentStepIndex);
  const continueToNext = currentStepData => {
    const updatedData = { ...stepData, ...currentStepData };
    setData(updatedData);
    setCurrentStepIndex(index => {
      const newIndex = index + 1;
      furthestStepIndex.current = newIndex;
      return newIndex;
    });
  };

  const steps = [
    {
      label: 'Visit Forms',
      render: () => (
        <VisitFormStep
          pageTitle="Visit Forms"
          onClickNextStep={continueToNext}
          userLocale={user.locale}
          forms={forms}
          stepData={stepData}
        />
      )
    },
    {
      label: 'Date',
      render: () => (
        <DateStep
          pageTitle="Set Dates"
          onClickNextStep={continueToNext}
          userLocale={user.locale}
          stepData={stepData}
          items={items}
        />
      )
    },
    {
      label: 'Time',
      render: () => (
        <TimeStep
          pageTitle="Set Times"
          userLocale={user.locale}
          onClickNextStep={continueToNext}
          stepData={stepData}
        />
      )
    },
    {
      label: 'Technician(s)',
      render: () => (
        <TechsStep
          pageTitle="Assign Technician(s)"
          userLocale={user.locale}
          onClickNextStep={continueToNext}
          departments={departments}
          stepData={stepData}
          techs={techs}
          crews={crews}
        />
      )
    },
    {
      label: 'Summary',
      render: () => (
        <Summary
          pageTitle="Visit Creation Summary"
          departments={departments}
          techs={techs}
          stepData={stepData}
          items={items}
          jobsPreferredTechniciansConfigMap={jobsPreferredTechniciansConfigMap}
          forms={forms}
          crews={crews}
        />
      )
    }
  ];
  const isLastStep = currentStepIndex === steps.length - 1;

  const handleExited = () => {
    setCurrentStepIndex(0);
    furthestStepIndex.current = 0;
    setData({});
  };

  const prepareMutationPayload = visitData => {
    const visitFormsData = (stepData?.visitFormIds || [])
      .map(visitFormId => forms.find(form => form.id === visitFormId))
      .map(getAssignedFormDataFromForm);

    const payload = {
      tenantCompanyId: user.tenantCompanyId,
      departmentId: stepData?.departmentId || '',
      prerequisites: stepData?.prerequisites || '',
      prerequisitesAcknowledged: stepData?.prerequisitesAcknowledged || null,
      primaryTechnicianId: stepData?.primaryTechnicianId || '',
      extraTechnicians: {
        requiredCount: stepData?.extraTechsNumber || 0,
        technicianIds: stepData?.additionalTechnicianIds || []
      },
      formData: visitFormsData,
      isAutomatic: true
    };

    return visitData.map(item => {
      const dueDate = moment.unix(item?.dueDate).format(AppConstants.DATE_FORMAT) || '';
      const visitDate =
        stepData.days !== null
          ? moment(dueDate)
              .subtract(Number(stepData?.days), 'days')
              .format()
          : '';

      const visitDurationInMins = `${getMinutesFromHrs(
        jobsPreferredTechniciansConfigMap?.[item.jobNumber]?.maintenanceTemplate?.budgetedHours
      ) || DEFAULT_VISIT_DURATION_IN_MIN} minutes`;

      return {
        ...payload,
        description:
          jobsPreferredTechniciansConfigMap?.[item.jobNumber]?.maintenanceTemplate
            ?.serviceDescription, // there is not field to add description in bulk visit modal
        jobId: item?.id,
        visitDateTime: {
          tentativeDate: visitDate || '',
          actualDuration: visitDurationInMins, // if not present, it breaks mobile app, hence defaulting to 60 mins,
          minimumDuration: visitDurationInMins,
          scheduledFor: calculateScheduledUnixTime(visitDate, stepData?.selectedTime)
        },
        visitAssets: jobsPreferredTechniciansConfigMap?.[item.jobNumber]?.visitAssets
      };
    });
  };

  const createVisits = async data => {
    const appsyncClient = AmplifyService.appSyncClient();

    return appsyncClient.client.mutate({
      mutation: BulkAddVisitsToJobs,
      variables: {
        partitionKey: user.tenantId,
        input: {
          visits: data
        }
      }
    });
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      if (items) {
        const payload = prepareMutationPayload(items);
        await createVisits(payload);
        snackbar('success', `Successfully created visits.`);
        props.refreshList();
        props.handleClose();
      }
    } catch (error) {
      Logger.error(error);
      snackbar('error', `Unable to create visits. Please try again.`, error);
    } finally {
      setLoading(false);
      props.handleClose();
    }
  };

  return (
    <FullScreenModal
      title={labels.bulkVisitCreation[user.locale]}
      open={isOpen}
      handleClose={() => props.handleClose()}
      onExited={handleExited}
      modalHeaderButtons={[
        <ThemeProvider>
          <Button type="primary" loading={loading} disabled={!isLastStep} onClick={onSubmit}>
            Create Visits
          </Button>
        </ThemeProvider>
      ]}
      headerCenterComponent={
        <StepsStatus
          steps={steps.map(({ label }) => label)}
          currentStepIndex={currentStepIndex}
          onClickStep={index => setCurrentStepIndex(index)}
          furthestStepIndex={furthestStepIndex.current}
        />
      }
    >
      <div className={classes.container}>
        <div className={classes.formContainer}>{steps[currentStepIndex].render()}</div>
      </div>
    </FullScreenModal>
  );
};

BulkVisitCreationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    // e.g. "en" - options are "en" or "es" - used to decide on language
    locale: PropTypes.string.isRequired
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  refreshList: PropTypes.func.isRequired // refershing the maintenance list
};

export default connect(null, { snackbar: snackbarOn })(BulkVisitCreationModal);
