import gql from 'graphql-tag';

const updateTimesheetDailyOverrides = gql`
  mutation updateTimesheetDailyOverrides(
    $partitionKey: String
    $data: UpdateTimesheetDailyOverrideInput!
  ) {
    updateTimesheetDailyOverride(partitionKey: $partitionKey, data: $data) {
      id
      dateUTC
      hourTypeAbbreviation
      actualTotalDurationOverride
      hourTypeId
      version
    }
  }
`;

export default updateTimesheetDailyOverrides;
