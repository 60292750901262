import React, { useRef, useState } from 'react';
import { Button, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Labels from 'meta/labels';
import PreferredTechniciansForm from 'components/PreferredTechniciansForm';
import { getTenantSettingValueForKey } from 'utils';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: 20
  }
});

const TechsStep = props => {
  const { onClickNextStep, departments, techs, crews, userLocale, pageTitle, stepData } = props;
  const classes = useStyles();
  const formService = useRef({});
  const [preferredTechnicians, setPreferredTechnicians] = useState(stepData);
  const crewTimeTracking = getTenantSettingValueForKey('crewTimeTracking') === 'true';

  const onCreateService = service => {
    formService.current = service;
  };

  const onChange = data => {
    setPreferredTechnicians(data);
  };

  const isFormCompleted = !!preferredTechnicians?.departmentId;

  return (
    <>
      <Typography variant="h4" className={classes.title}>
        {pageTitle}
      </Typography>
      <PreferredTechniciansForm
        requiredFields={{ departmentId: true }}
        preferredTechnicians={stepData}
        departments={departments}
        crews={crews}
        technicians={techs}
        onCreateService={onCreateService}
        onComplete={onClickNextStep}
        onChange={onChange}
        crewTimeTracking={crewTimeTracking}
      />
      <div className={classes.buttonContainer}>
        <Button
          variant="containedSecondary"
          onClick={() => formService.current?.submit()}
          disabled={!isFormCompleted}
        >
          {Labels.nextStep[userLocale]}
        </Button>
      </div>
    </>
  );
};

TechsStep.propTypes = {
  onClickNextStep: PropTypes.func.isRequired,
  userLocale: PropTypes.string.isRequired,
  departments: PropTypes.array,
  techs: PropTypes.array,
  crews: PropTypes.array
};

TechsStep.defaultProps = {
  departments: [],
  crews: [],
  techs: []
};

export default TechsStep;
