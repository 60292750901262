import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  Button,
  CurrencyInput,
  Input,
  MoreButton,
  NumberInput,
  ThemeProvider,
  TV,
  TW,
  Typography
} from '@buildhero/sergeant';
import { jsx } from '@emotion/react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { debounce, orderBy } from 'lodash';
import { useSelector } from 'react-redux';

import { ConfirmLeave } from 'components';
import ConfirmModal from 'components/Modal/ConfirmDialog';
import WrapTable, { BoldCell, tableEmptyValueFormatter } from 'components/WrapTable';
import { InventoryItemFormModal } from 'scenes/Jobs/JobDetail/components/PartsAndMaterials';
import { getTrucksFromEmployeeList } from 'scenes/Jobs/JobDetail/componentsV2/ReviewReport/components/ReviewReportPartsAndMaterialsSection/components/ReviewReportInventoryItems/ReviewReportInventoryItems.hooks';
import addInventoryPartsToJob from 'services/core/graphql/jobs/mutations/addInventoryPartsToJob';
import deleteInventoryPart from 'services/core/graphql/jobs/mutations/deleteInventoryPart';
import addInventoryPartsToVisit from 'services/core/graphql/jobs/mutations/saveInventoryParts';
import updateInventoryPart from 'services/core/graphql/jobs/mutations/updateInventoryPart';
import { convertToCurrencyString, roundCurrency } from 'utils';
import { LineItemBillingStatus, VisitStatus } from 'utils/AppConstants';
import { InventoryPartSourceType, InvoiceStatus } from 'utils/constants';

import { SectionHeader, VisitSubjectField } from '../Components';
import { InventoryPartSelectionSource } from '../constants';
import {
  selectInventoryInput,
  selectInventoryItemFormData,
  selectInventoryItemUpdateInput
} from '../selectors';
import { getVisitDisplayText, tableCurrencyFormatter } from '../utils';

const trimFormatter = ({ value }) => value?.trim();

const getColumns = ({
  updateTableField,
  handleEditClick,
  handleDeleteClick,
  isVisitsTab,
  isMultiVisits,
  isEditable
}) =>
  [
    isMultiVisits && {
      field: 'subject',
      headerName: 'Visit',
      width: 200,
      align: 'center',
      renderCell: ({ row }) => VisitSubjectField({ ...row.subject })
    },
    !isVisitsTab && { field: 'source', headerName: 'Source', width: 175, align: 'center' },
    { field: 'itemName', headerName: 'Item Name', align: 'center' },
    isEditable
      ? {
          field: 'description',
          headerName: 'Description',
          renderCell: ({ row }) => {
            if (row.readOnly) {
              return (
                <div css={{ width: '100%', margin: 'auto' }}>
                  {tableEmptyValueFormatter({ value: trimFormatter({ value: row.description }) })}
                </div>
              );
            }

            const onBlur = debounce(updateTableField, 1000);
            return (
              <Input
                defaultValue={row.description}
                type="text"
                onBlur={e => {
                  const { value } = e.target;
                  if (!value || value === row.description) return;

                  const { id, version } = row;
                  onBlur({ id, version, description: value });
                }}
              />
            );
          }
        }
      : { field: 'description', headerName: 'Description', align: 'center' },
    isEditable
      ? {
          field: 'unitCost',
          headerName: 'Unit Cost',
          width: 100,
          align: 'left',
          renderCell: ({ row }) => {
            if (row.readOnly) {
              return (
                <div css={{ textAlign: 'right', width: '100%', margin: 'auto' }}>
                  {tableEmptyValueFormatter({ value: row.quantity })}
                </div>
              );
            }
            const onBlur = debounce(updateTableField, 1000);
            return (
              <CurrencyInput
                value={roundCurrency(row.unitCost)}
                onBlur={value => {
                  if (value === row.unitCost) return;

                  const { id, version } = row;
                  onBlur({ id, version, unitCost: value });
                }}
              />
            );
          }
        }
      : {
          field: 'unitCost',
          headerName: 'Unit Cost',
          width: 100,
          align: 'right',
          valueFormatter: tableCurrencyFormatter,
          renderCell: ({ row }) => (
            <div css={{ width: '100%', margin: 'auto' }}>
              {tableCurrencyFormatter({ value: row.unitCost })}
            </div>
          )
        },
    isEditable
      ? {
          field: 'quantity',
          headerName: 'Qty',
          width: 75,
          renderCell: ({ row }) => {
            if (row.readOnly) {
              return (
                <div css={{ textAlign: 'right', width: '100%', margin: 'auto' }}>
                  {tableEmptyValueFormatter({ value: row.quantity })}
                </div>
              );
            }
            const onBlur = debounce(updateTableField, 1000);
            return (
              <NumberInput
                value={row.quantity}
                onBlur={value => {
                  if (value === row.quantity) return;

                  const { id, version } = row;
                  onBlur({ id, version, quantity: value });
                }}
              />
            );
          }
        }
      : {
          field: 'quantity',
          headerName: 'Qty',
          width: 75,
          align: 'right',
          renderCell: ({ row }) => (
            <div css={{ width: '100%', margin: 'auto' }}>{tableEmptyValueFormatter({ value: row.quantity })}</div>
          )
        },
    { field: 'unitOfMeasure', headerName: 'UOM', width: 100, align: 'center' },
    {
      field: 'subtotal',
      headerName: 'Subtotal',
      width: 100,
      align: 'center',
      valueFormatter: tableCurrencyFormatter,
      renderCell: BoldCell,
      totalGetter: ({ rows }) => rows.reduce((acc, r) => acc + r.subtotal, 0),
      renderTotal: props => (
        <Typography
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'right'
          }}
          variant={TV.BASE}
          weight={TW.BOLD}
          numeric
        >
          {convertToCurrencyString(props.formattedValue ?? 0)}
        </Typography>
      )
    },
    isEditable && {
      field: 'actions',
      flex: 1,
      width: 32,
      headerName: '',
      noPadding: true,
      align: 'center',
      renderCell: ({ row }) => {
        if (row.readOnly) {
          return <></>;
        }
        return (
          <MoreButton
            options={[
              { label: 'Edit', icon: EditIcon, onClick: () => handleEditClick(row.id) },
              { label: 'Delete', icon: DeleteIcon, onClick: () => handleDeleteClick(row.id) }
            ]}
          />
        );
      }
    }
  ].filter(Boolean);

const mapInventoryPartsToRows = (visits, jobReportInventoryParts, companyTimezone) => {
  const visitPartRows = [];
  visits.forEach(visit => {
    const inventoryParts = [...(visit.inventoryParts?.items || [])];
    const visitTag = getVisitDisplayText(visit, companyTimezone);

    inventoryParts.forEach(part => {
      visitPartRows.push({
        ...part,
        visitNumber: visit.visitNumber,
        source: visitTag,
        subtotal: part.unitCost * part.quantity,
        subject: {
          reviewStatus: visit.reviewStatus,
          visitNumber: visit.visitNumber,
          scheduledFor: visit.scheduledFor
        },
        unitOfMeasure: part.unitOfMeasure || '-',
        readOnly: part.billingStatus === LineItemBillingStatus.BILLED
      });
    });
  });

  const jobReportPartRows = jobReportInventoryParts
    .filter(
      part =>
        !visitPartRows.find(row => part.id === row.id) &&
        part.source !== InventoryPartSourceType.AMOUNT_QUOTED
    )
    .map(part => ({
      ...part,
      source: part.source === InventoryPartSourceType.INVOICE ? 'Invoice' : 'Job Report',
      subtotal: part.unitCost * part.quantity,
      unitOfMeasure: part.unitOfMeasure || '-'
    }));

  return [
    ...orderBy(visitPartRows, ['visitNumber', 'itemName']),
    ...orderBy(jobReportPartRows, ['itemName'])
  ];
};

const InventoryModalModes = {
  ADD: 'ADD',
  EDIT: 'EDIT'
};

const JobCloseoutQuotedInventoryCosts = ({
  jobData,
  refetchJob,
  visits,
  isVisitsTab,
  isMultiVisits,
  companyTimezone,
  isLoading
}) => {
  const { defaultPriceBookId, tenantId, departments } = useSelector(state => state?.company);

  const departmentOptions = departments?.items.map(d => ({
    label: d.tagName,
    value: d.id
  }));

  const [addInventoryPartsToJobMutation, { loading: addJobPartLoading }] = useMutation(
    addInventoryPartsToJob
  );
  const [addInventoryPartsToVisitMutation, { loading: addVisitPartLoading }] = useMutation(
    addInventoryPartsToVisit
  );
  const [updateInventoryPartMutation, { loading: updatingInventoryPartLine }] = useMutation(
    updateInventoryPart
  );
  const [deleteInventoryPartMutation, { loading: deletingInventoryPartLine }] = useMutation(
    deleteInventoryPart
  );
  const [confirmDelete, setConfirmDelete] = useState({});
  const [inventoryItemModalOpen, setInventoryItemModalOpen] = useState(false);
  const modalMode = useRef(null);
  const modalInitialValues = useRef({});

  const isEditable = visits?.every(v => v.status === VisitStatus.CONVERTED) || !isVisitsTab;
  const handleCancelConfirmation = () =>
    setConfirmDelete({ confirmMessage: '', confirmAction: '', confirmDialog: '' });

  const jobVisits = jobData.visits?.items || [];

  const [jobReportDepartment, setJobReportDepartment] = useState(null);

  useEffect(() => {
    const firstJobDepartment = jobData?.departments?.items[0];
    if (firstJobDepartment) {
      setJobReportDepartment({
        label: firstJobDepartment.mappedEntity?.tagName,
        value: firstJobDepartment.mappedEntity?.id
      });
    } else {
      setJobReportDepartment(null);
    }
  }, [jobData]);

  const visitDepartments = useMemo(
    () =>
      visits?.map(({ id, departmentId }) => ({
        id,
        departmentId
      })),
    [visits]
  );

  const availableTrucks = useMemo(
    () =>
      visits
        ?.flatMap(({ primaryTechs, extraTechs }) => [
          ...getTrucksFromEmployeeList(primaryTechs?.items || []),
          ...getTrucksFromEmployeeList(extraTechs?.items || [])
        ])
        .filter((truck, index, currArr) => currArr.indexOf(truck) === index),
    [visits]
  );

  const jobInventoryParts = useMemo(
    () =>
      (jobData.inventoryParts?.items || [])
        .filter(p => !p.invoiceItemId || p.invoiceItem.invoice.status !== InvoiceStatus.VOID)
        .map(p => ({
          ...p,
          source: (() => {
            if (p.source === InventoryPartSourceType.AMOUNT_QUOTED) {
              return p.source;
            }
            if (p.invoiceItemId) {
              return InventoryPartSourceType.INVOICE;
            }
            return InventoryPartSourceType.JOB_REPORT.replace('_', ' ');
          })()
        })),
    [jobData]
  );

  const updateTableField = useCallback(
    async newData => {
      await updateInventoryPartMutation({
        variables: {
          partitionKey: tenantId,
          data: { ...newData }
        }
      });
    },
    [tenantId, updateInventoryPartMutation]
  );

  const deleteAction = useCallback(
    async inventoryItemId => {
      await deleteInventoryPartMutation({
        variables: {
          id: inventoryItemId,
          partitionKey: tenantId,
          jobId: jobData.id
        }
      });
      await refetchJob();
      setConfirmDelete({});
    },
    [deleteInventoryPartMutation, refetchJob, tenantId]
  );

  const handleDeleteClick = useCallback(
    inventoryItemId => {
      setConfirmDelete({
        confirmAction: async () => deleteAction(inventoryItemId),
        confirmMessage: 'inventory item',
        confirmDialog: true
      });
    },
    [deleteAction]
  );

  const handleAddInventoryPart = async inventoryItem => {
    if (inventoryItem.selectedSource.value === InventoryPartSelectionSource.JOB_REPORT) {
      await addInventoryPartsToJobMutation({
        variables: {
          data: {
            jobId: jobData.id,
            inventoryParts: [selectInventoryInput(inventoryItem)]
          },
          partitionKey: tenantId
        }
      });
    } else {
      await addInventoryPartsToVisitMutation({
        variables: {
          visitId: inventoryItem.selectedSource.value,
          inventoryParts: [selectInventoryInput(inventoryItem)],
          partitionKey: tenantId
        }
      });
    }
    refetchJob();
  };

  const handleEditClick = useCallback(
    inventoryItemId => {
      modalMode.current = InventoryModalModes.EDIT;
      const allInventoryParts = [
        ...jobVisits.map(v => {
          const departmentId = departmentOptions.find(d => v.departmentId === d.value)?.value;
          return v.inventoryParts?.items.map(part => ({ ...part, departmentId }));
        }),
        ...jobInventoryParts
      ].flat();
      modalInitialValues.current = selectInventoryItemFormData({
        inventoryItem: allInventoryParts.find(p => p.id === inventoryItemId),
        departmentOptions
      });
      setInventoryItemModalOpen(true);
    },
    [departmentOptions, jobInventoryParts, jobVisits]
  );

  const sourceOptions = useMemo(() => {
    const jobReportSource = !isVisitsTab
      ? [
          {
            label: InventoryPartSelectionSource.JOB_REPORT,
            value: InventoryPartSelectionSource.JOB_REPORT
          }
        ]
      : [];
    return [
      ...jobReportSource,
      ...visits?.map?.(visit => {
        return {
          label: `Visit ${visit.visitNumber}`,
          value: visit.id
        };
      })
    ];
  }, [isVisitsTab, visits]);

  const handleAddClick = () => {
    modalMode.current = InventoryModalModes.ADD;
    const visit = visitDepartments?.find(({ id }) => id === sourceOptions[0].value);
    const departmentOption = departmentOptions.find(option => visit?.departmentId === option.value);
    modalInitialValues.current = {
      department: departmentOption,
      selectedSource: sourceOptions[0],
      name: ''
    };
    setInventoryItemModalOpen(true);
  };

  const rows = useMemo(
    () =>
      mapInventoryPartsToRows(
        visits || jobVisits,
        isVisitsTab ? [] : jobInventoryParts || [],
        companyTimezone
      ),
    [visits, jobVisits, isVisitsTab, jobInventoryParts, companyTimezone]
  );

  const handleInventoryPartSubmit = inventoryItem => {
    if (modalMode.current === InventoryModalModes.ADD) {
      handleAddInventoryPart(inventoryItem);
    } else {
      updateTableField(selectInventoryItemUpdateInput(inventoryItem));
    }
    setInventoryItemModalOpen(false);
  };

  const columns = useMemo(
    () =>
      getColumns({
        updateTableField,
        handleEditClick,
        handleDeleteClick,
        isVisitsTab,
        isMultiVisits,
        isEditable
      }),
    [updateTableField, handleEditClick, handleDeleteClick, isVisitsTab, isMultiVisits, isEditable]
  );

  return (
    <div>
      <ThemeProvider>
        <SectionHeader title="Inventory Items">
          <Button
            onClick={handleAddClick}
            loading={addJobPartLoading || addVisitPartLoading || isLoading}
            type="secondary"
            size="small"
          >
            Add Items
          </Button>
        </SectionHeader>
        <WrapTable
          columns={columns}
          rows={rows}
          hideFooter={rows.length <= 10}
          noDataMessage="No inventory parts"
          enableTotalsRow
          loading={isLoading}
          loadingRows={3}
        />
        <InventoryItemFormModal
          initialValues={modalInitialValues.current}
          loading={false}
          open={inventoryItemModalOpen}
          priceBookId={jobData.priceBookId || defaultPriceBookId}
          sourceOptions={modalMode.current === InventoryModalModes.ADD ? sourceOptions : null}
          departmentOptions={departmentOptions}
          jobReportDepartment={jobReportDepartment}
          setJobReportDepartment={setJobReportDepartment}
          visitDepartments={visitDepartments}
          title={`${modalMode.current === InventoryModalModes.ADD ? 'Add' : 'Edit'} Inventory Item`}
          onClose={() => setInventoryItemModalOpen(false)}
          onSubmit={handleInventoryPartSubmit}
          disablePriceBookEntryRequired
          availableTrucks={availableTrucks}
        />
        <ConfirmModal
          open={confirmDelete.confirmDialog}
          confirm={confirmDelete.confirmAction}
          cancel={handleCancelConfirmation}
          message={confirmDelete.confirmMessage}
        />
      </ThemeProvider>
      <ConfirmLeave
        when={
          updatingInventoryPartLine ||
          addVisitPartLoading ||
          addJobPartLoading ||
          deletingInventoryPartLine
        }
      />
    </div>
  );
};

JobCloseoutQuotedInventoryCosts.defaultProps = {
  isVisitsTab: false,
  isLoading: false
};

export default JobCloseoutQuotedInventoryCosts;
