import gql from 'graphql-tag';

const getDefaultPricebookForCompany = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      priceBooks(entityConnection: "PriceBook") {
        items {
          id
          name
          description
          markupValue
          markupType
          isDefault
        }
      }
    }
  }
`;

export default getDefaultPricebookForCompany;
