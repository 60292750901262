import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const updateJob = gql`
  mutation updateJob($data: UpdateJobInput!) {
    updateJob(data: $data) {
      id
      priceBookId
      version
    }
  }
`;

export const useEditJobPricebook = () =>
  useExtendedMutation(updateJob, {
    serializer: ({ id, priceBookId, jobVersion }) => ({
      variables: {
        data: {
          id,
          priceBookId,
          version: jobVersion
        }
      }
    })
  });

export default useEditJobPricebook;
