/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getCustomers() {
  return await instance
    .get('Customers')
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getCustomerById(customerId) {
  return await instance
    .get(`customers/${customerId}`, { params: { include: '*' } })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function customerSearch(term, searchColumns) {
  return await instance
    .post(`Customers/search/${term}`, { searchColumns: searchColumns || ['customerName'] })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function customerCreate(customer) {
  return await instance
    .post('Customers', customer)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function customerChange(id, customer) {
  return await instance
    .put(`Customers/${id}`, customer)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
