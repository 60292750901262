import React from 'react';

import { Label, ThemeProvider, TV, TW, Typography } from '@buildhero/sergeant';

import { Box } from '@material-ui/core';

import AlgoliaSearch from 'components/SgtAlgoliaSearch/AlgoliaSearch';
import { bundleIndex } from 'constants/algoliaIndex';

const BillingHourRatesAndTypes = ({ styles, defaultBillingProduct, setDefaultBillingProduct }) => {
  return (
    <>
      <Typography variant="body2" css={styles.greenHeading}>
        Billing Hour Types
      </Typography>
      <Box width={400}>
        <ThemeProvider>
          <Label label="Default Billing Product for Labor Invoice Items" />
          <AlgoliaSearch
            placeholder="Search"
            value={defaultBillingProduct?.name}
            name="defaultProductSetting"
            indexName={bundleIndex}
            formatHitLabel={h => `${h.name || ''}${h.description ? `, ${h.description}` : ''}`}
            filters="entityType:Product"
            onChange={selectedItem =>
              setDefaultBillingProduct({
                ...selectedItem,
                unsaved: selectedItem?.id !== defaultBillingProduct?.id || ''
              })
            }
            disableClear
          />

          <Typography variant={TV.S2} weight={TW.REGULAR}>
            The new default will only be applied to new Billing Hour Types created
          </Typography>
        </ThemeProvider>
      </Box>
    </>
  );
};

export default BillingHourRatesAndTypes;
