import React from 'react';
import {
  tableCurrencyFormatter,
  tablePercentageFormatter,
  tableEmptyValueFormatter,
  BoldCell,
  sum
} from 'components/WrapTable';
import { Field, FieldType } from '@buildhero/sergeant';

export const OtherItemsColumns = [
  {
    headerName: 'Source',
    field: 'source',
    maxWidth: 150,
    flex: 1,
    renderCell: ({ row }) => (
      <Field type={FieldType.LINK} value={{ label: row.source, to: row.sourceLink }} />
    )
  },
  {
    headerName: 'Item Name',
    field: 'name',
    maxWidth: 240,
    valueFormatter: tableEmptyValueFormatter,
    flex: 1
  },
  {
    headerName: 'Description',
    field: 'description',
    valueFormatter: tableEmptyValueFormatter,
    flex: 1.5
  },
  {
    field: 'unitCost',
    flex: 1,
    maxWidth: 100,
    headerName: 'Unit cost',
    valueFormatter: tableCurrencyFormatter
  },
  {
    field: 'markup',
    flex: 1,
    maxWidth: 72,
    headerName: 'Markup',
    valueFormatter: tablePercentageFormatter
  },
  {
    field: 'unitPrice',
    flex: 1,
    maxWidth: 100,
    headerName: 'Unit price',
    valueFormatter: tableCurrencyFormatter
  },
  {
    field: 'quantity',
    flex: 1,
    maxWidth: 100,
    noWrap: true,
    headerName: 'Quantity',
    valueFormatter: tableEmptyValueFormatter
  },
  {
    field: 'subtotal',
    flex: 1,
    maxWidth: 100,
    headerName: 'Subtotal',
    renderCell: BoldCell,
    valueFormatter: tableCurrencyFormatter,
    totalGetter: sum,
    totalFormatter: tableCurrencyFormatter,
    renderTotal: BoldCell
  }
];
