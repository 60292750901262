/*
 * Utility function to make writing field names by form mode for our forms library less verbose.
 *
 * Accepts input of form: (same as input for validation schema util function)
 * { jobType: { type: 'number' },
 *   departments: { type: 'string', required: true },
 *   projectManager: {} } // assumes type is string and not required
 * Field type is not required and will be ignored by the function, but it is
 * convenient to include so the same input object can be used for the
 * validation schema generator function.
 *
 * Use as follows:
 * const formLayout = options => ({
 *   fields: generateDefaultFieldsObject(yourNewConciseFieldsObject),
 *   layouts: layoutsObject
 * })
 *
 * If no fields are required or have non-string types, you can just pass an array of field names:
 * ['jobType', 'departments', 'projectManager']
 */
export const generateDefaultFieldsObject = fields => {
  const result = {};
  if (typeof fields !== 'object' && !Array.isArray(fields)) return result;
  const iterableFields = Array.isArray(fields) ? fields : Object.keys(fields);
  // Automatically removes duplicate entries if array.
  iterableFields.forEach(field => {
    result[field] = {
      view: field,
      edit: field
    };
  });
  return result;
};

/*
 * Utility function to make writing validation schema for our forms library less verbose.
 *
 * Accepts input of form: (same as input for form object util function)
 * { jobType: { type: 'number' },
 *   departments: { type: 'string', required: true },
 *   projectManager: {} } // assumes type is string and not required
 *
 * Use as follows:
 * const formLayout = options => ({
 *   fields: fieldsObject,
 *   layouts: layoutsObject,
 *   ...generateDefaultValidationObject(yourNewConciseValidationObject),
 * })
 */
export const generateDefaultValidationObject = validationSchema => {
  if (!validationSchema || typeof validationSchema !== 'object') return {};
  const result = {
    validation: {
      type: 'object',
      properties: {},
      required: []
    },
    validationErrors: {}
  };
  Object.keys(validationSchema).forEach(field => {
    result.validation.properties[field] = { type: validationSchema[field]?.type || 'string' };
    if (validationSchema[field]?.required) {
      result.validation.required.push(field);
      result.validationErrors[field] = { required: 'Field is required.' };
    }
  });
  return result;
};
