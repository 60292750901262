import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { get, omit } from 'lodash';
import { Button, Checkbox, Tooltip, Chip, Grid } from '@material-ui/core/';
import { DescriptionOutlined } from '@material-ui/icons';
import Warning from '@material-ui/icons/Warning';
import { jsx } from '@emotion/react';

import StorageService from 'services/StorageService';
import { Logger } from 'services/Logger';
import ErrorBoundaries from 'scenes/Error';

import ImageThumbnail from 'components/ImageThumbnail';
import ImageComponent from 'components/Image';
import Spinner from 'components/Spinners/CircularIndeterminate';
import TooltipIcon from 'components/ToolTipIcon';
import LinkToImage from 'components/ImagesLink';
import Modal from 'components/Modal';
import TableThumbnail from 'components/Table/TableThumbnail';
import MultiNotes from 'components/MultiNotes';
import { StatusChip, LinkButton, LinkList } from 'components';
import { JobLink } from 'components/JobLink';

import {
  removeNestedJson,
  truncateString,
  convertToCurrencyString,
  convertNumberToFixed,
  backendDateToMoment
} from 'utils';
import { getDateFormat, ActiveStatus } from 'utils/AppConstants';
import { SyncStatus, CellDataTypes } from 'utils/constants';

import { dataTypeIsDate } from './tableUtils';

/* NOTE: When adding new data types:
 * 1. Ensure that the new data type is handled properly in filter conditions.
 * 2. If the data type is text, ensure that it is wrapped in a "no wrap" div
 *    (a div with `className={classes.textWrapper}`). This ensures that all
 *    table rows have consistent height.
 */

const useStyles = makeStyles(theme => ({
  attachmentIcon: {
    color: 'inherit',
    marginRight: theme.spacing(1),
    fontSize: theme.typography.body1
  },
  noteButton: {
    color: theme.palette.other.secondaryCyan,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontFamily: theme.typography.fontFamily,
    lineHeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: 0.2
  },
  chip: {
    borderRadius: 2
  },
  deactivatedChip: {
    backgroundColor: 'red',
    color: 'white',
    height: '20px'
  },
  chipLabel: {
    ...omit(theme.typography.overline, 'color'),
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '4px',
    paddingBottom: '4px',
    fontSize: '14px',
    textTransform: 'capitalize'
  },
  checkbox: {
    padding: 0,
    paddingLeft: 10
  },
  flexStart: {
    justifyContent: 'flex-start'
  },
  accountingRefCheck: {
    display: 'flex',
    alignItems: 'center'
  },
  textWrapper: props => ({
    whiteSpace: props.prewrap ? 'pre-wrap' : 'nowrap',
    fontWeight: props.bold ? 'bold' : 'normal',
    color: props.color || undefined,
    textDecorationColor: props.color || undefined
  }),
  textWrapperDefaultText: {
    whiteSpace: 'nowrap',
    color: theme.palette.grayscale(20)
  },
  chipTag: {
    borderRadius: 2,
    backgroundColor: '#2dce89',
    color: '#333333',
    margin: 2
  },
  chipText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 12
  }
}));

const downloadFile = async (fileName, actualFileName, showDownloadLoader) => {
  if (!fileName) {
    return;
  }

  const storageService = new StorageService();
  try {
    showDownloadLoader(true);
    const url = await storageService.getFile(fileName, false);
    if (url) {
      const aLink = document.createElement('a');
      aLink.target = '_blank';
      aLink.download = actualFileName;
      aLink.href = url;
      aLink.click();
    }
  } catch (error) {
    Logger.info(`Error uploading image ${error}`);
  } finally {
    showDownloadLoader(false);
  }
};

// HOC for tooltip
// WrappedComponent must spread props and forward ref if it's a custom React
// component
// https://material-ui.com/components/tooltips/#custom-child-element
const withTooltip = (WrappedComponent, tooltipText) => (
  <Tooltip title={tooltipText}>{WrappedComponent}</Tooltip>
);

// Assumes record is not `undefined`
export default function DisplayData({
  record,
  meta,
  refreshTable,
  rowIndex,
  timezoneSensitiveColumnIds = [],
  companyTimezone
}) {
  const [noteModal, setNoteModal] = useState({
    openNotePopUp: false,
    notePopupTitle: '',
    parent: ''
  });
  const [showDownloadLoader, setShowDownloadLoader] = useState(false);
  const theme = useTheme();

  const genericTestingId = `${meta.id}-record-${rowIndex}`;

  let cellData = get(record, meta.id); // allow nested id like 'employee.name'
  if (meta.valueFormatter) cellData = meta.valueFormatter(cellData);
  const cellLabel = meta.labelFormatter ? meta.labelFormatter(cellData) : cellData;
  const cellDataType = meta.type;
  const defaultCellText = meta.defaultCellText || '-';
  const maxTextLen = meta.maxTextLen || 50; // In # of characters
  let showErrorText = false;

  if (cellDataType === 'balance' && cellData > 0) {
    showErrorText = true;
  }

  // Currency data type is bold by default
  const boldText = cellDataType === 'currency' || meta.bold;
  const color = meta.error || showErrorText ? theme.palette.error.main : meta.color;
  const classes = useStyles({
    bold: boldText,
    color,
    prewrap: meta.prewrap
  });

  // Various identifiers for lack of data used by backend. This is legacy;
  // multiple identifiers (e.g. '', 'undefined', 'NULL') should not all be
  // used to indicate lack of data.
  const cellDataExists =
    (!!cellData || cellData === 0) && cellData !== 'undefined' && cellData !== 'NULL';

  // Cell data types that have special behavior when there is no cell data.
  // All other cell data types have default behavior upon no data.
  const exemptDataTypes = ['boolean', 'checkbox'];
  if (!cellDataExists && !exemptDataTypes.includes(cellDataType)) {
    return (
      <div className={classes.textWrapperDefaultText}>
        {record.totalsRow ? '' : defaultCellText}
      </div>
    );
  }

  const { DATE_ARRAY } = CellDataTypes;

  // by default, if cellData is an array, then string join it.
  // If you want to extend this to allow other behavior, you should
  // specify custom metadata type logic, as shown later in this file.
  const cellDataText =
    cellDataType === DATE_ARRAY && Array.isArray(cellData) ? cellData.join(', ') : cellData;

  // truncates if needed
  const truncatedCellText = truncateString(cellDataText, maxTextLen);
  const isTruncated = typeof cellDataText === 'string' && cellDataText.length > maxTextLen;
  const truncatedCellTextEl = <div className={classes.textWrapper}>{truncatedCellText}</div>;
  const cellTextEl = isTruncated
    ? withTooltip(truncatedCellTextEl, cellDataText)
    : truncatedCellTextEl;

  const getLinkParams = () => {
    if (meta.showLink && meta.linkParams) {
      return meta.linkParams.reduce((url, param) => `${url}/${get(record, param)}`, '');
    }
    return '';
  };

  if (meta.isHref && meta.showLink && meta.linkReference && get(record, meta.linkReference)) {
    return (
      <LinkButton
        label={truncatedCellText}
        target={meta.onNewTab ? '_blank' : undefined}
        component="a"
        href={`${meta.linkPath}${get(record, meta.linkReference)}${getLinkParams()}`}
        classes={{
          label: `${classes.flexStart} ${classes.textWrapper}`
        }}
        testingid={genericTestingId}
      />
    );
  }

  if (
    meta.showLink &&
    meta.baseLinkReference &&
    meta.detailLinkReference &&
    meta.baseLinkPath &&
    meta.linkConnector &&
    get(record, meta.baseLinkReference) &&
    get(record, meta.detailLinkReference)
  ) {
    const Component = (
      <LinkButton
        label={truncatedCellText}
        path={`${meta.baseLinkPath}/${get(record, meta.baseLinkReference)}/${
          meta.linkConnector
        }/${get(record, meta.detailLinkReference)}${getLinkParams()}`}
        classes={{
          label: `${classes.flexStart} ${classes.textWrapper}`
        }}
        testingid={genericTestingId}
      />
    );
    return isTruncated ? withTooltip(Component, cellData) : Component;
  }

  if (meta.showLink && meta.linkReference && get(record, meta.linkReference)) {
    const Component = (
      <LinkButton
        label={truncatedCellText}
        path={`${meta.linkPath}/${get(record, meta.linkReference)}${getLinkParams()}`}
        state={{ [meta.linkStateKey]: get(record, meta.linkStateValue) }}
        classes={{
          label: `${classes.flexStart} ${classes.textWrapper}`
        }}
        testingid={genericTestingId}
      />
    );
    return isTruncated ? withTooltip(Component, cellData) : Component;
  }

  if (dataTypeIsDate(cellDataType)) {
    const applyTimezone = timezoneSensitiveColumnIds?.includes(meta.id);
    const getDateText = data => {
      const momentObj =
        applyTimezone && companyTimezone
          ? backendDateToMoment(data, companyTimezone)
          : backendDateToMoment(data);
      return momentObj.isValid() ? momentObj.format(getDateFormat(cellDataType)) : '-';
    };

    const cellText =
      cellDataType === DATE_ARRAY ? cellData.map(getDateText).join(', ') : getDateText(cellData);

    return <div className={classes.textWrapper}>{cellText}</div>;
  }

  switch (cellDataType) {
    case 'text':
    case 'bigtext':
    case 'textWithThumbnail':
      return cellTextEl;

    case 'active': {
      return (
        <StatusChip
          label={cellData ? ActiveStatus.ACTIVE : ActiveStatus.INACTIVE}
          backgroundColor={
            cellData ? theme.palette.other.statusGreen : theme.palette.other.statusRed
          }
          classes={{
            root: classes.chip,
            label: classes.chipLabel
          }}
        />
      );
    }

    case 'boolean':
      return <div className={classes.textWrapper}>{cellDataExists ? 'Yes' : 'No'}</div>;

    case 'iconenum': {
      const toolTipText =
        meta?.enableToolTipFor?.length && (meta.enableToolTipFor || []).includes(cellData)
          ? meta.toolTipText?.[cellData]
          : '';

      return (
        <div
          css={{
            backgroundColor: meta.getBackgroundColor?.({ data: cellData, theme }),
            display: 'flex',
            padding: 4,
            borderRadius: '4px'
          }}
        >
          {meta.getIcon?.({ data: cellData, theme })}
          <StatusChip
            label={cellLabel}
            backgroundColor={meta.getBackgroundColor?.({ data: cellData, theme })}
            enumType={meta.enumType}
            enumValue={cellData}
            textColor={meta.getTextColor?.({ data: cellData, theme })}
            classes={{
              root: classes.chip,
              label: classes.chipLabel
            }}
            toolTipText={toolTipText}
          />
        </div>
      );
    }

    case 'enum': {
      const backgroundColor = cellData === 'deactivated' ? theme.palette.error.main : null;
      const textColor = ['deactivated', 'expired'].includes(cellData?.toLowerCase())
        ? 'white'
        : null;

      const toolTipText =
        meta?.enableToolTipFor?.length && (meta.enableToolTipFor || []).includes(cellData)
          ? meta.toolTipText?.[cellData]
          : '';

      return (
        <StatusChip
          label={cellLabel}
          backgroundColor={backgroundColor}
          enumType={meta.enumType}
          enumValue={cellData}
          textColor={textColor}
          showIcon={meta.showIcon}
          classes={{
            root: classes.chip,
            label: classes.chipLabel
          }}
          toolTipText={toolTipText}
        />
      );
    }

    case 'checkbox':
      return (
        <Checkbox
          checked={cellDataExists}
          className={classes.checkbox}
          disabled={meta.disabled}
          testingid={genericTestingId}
        />
      );

    case 'balance':
    case 'currency': {
      return (
        <div className={classes.textWrapper}>
          {convertToCurrencyString(cellData, meta.symbol, meta.precision)}
        </div>
      );
    }

    case 'decimal': {
      return (
        <div className={classes.textWrapper}>
          {convertNumberToFixed(cellData, meta.decimalDigits || 2)}
        </div>
      );
    }
    case 'percentage':
      return <div className={classes.textWrapper}>{`${cellData}%`}</div>;

    case 'image':
      return <ImageComponent image={{ fileUrl: cellData }} />;

    case 'thumbnail':
      return <ImageThumbnail image={{ fileUrl: cellData }} />;

    case 'jobLink':
      return <JobLink record={record} meta={meta} testingid={genericTestingId} />;

    case 'accountingRefCheck': {
      const showWarning =
        (record.syncStatus && record.syncStatus !== SyncStatus.IN_SYNC) ||
        (!record.syncStatus && !record.synced);
      return (
        <div className={classes.accountingRefCheck}>
          {showWarning && (
            <Tooltip title="Not synced yet">
              <Warning
                style={{ paddingRight: 5 }}
                color="error"
                className={classes.warningIconColor}
              />
            </Tooltip>
          )}
          {cellTextEl}
        </div>
      );
    }

    case 'note':
      return (
        <ErrorBoundaries>
          <Button
            disableFocusRipple
            disableTouchRipple
            disableRipple
            classes={{ root: classes.noteButton, label: classes.textWrapper }}
            onClick={e => {
              e.stopPropagation();
              setNoteModal({
                openNotePopUp: true,
                notePopupTitle: 'Notes',
                noteData: meta.other ? record[meta.other] : [],
                parent: record.mappedEntity || record
              });
            }}
            testingid={genericTestingId}
          >
            {cellData === 0 ? 'Add note' : cellData}
          </Button>
          <span onClick={e => e.stopPropagation()}>
            <Modal
              open={noteModal.openNotePopUp}
              handleClose={() => {
                setNoteModal({ openNotePopUp: false, notePopupTitle: '', parent: '' });
                if (refreshTable) {
                  refreshTable();
                }
              }}
              width="646"
            >
              <MultiNotes
                notesData={noteModal.noteData}
                title={noteModal.notePopupTitle}
                parent={removeNestedJson(noteModal.parent)}
              />
            </Modal>
          </span>
        </ErrorBoundaries>
      );

    case 'LinkList':
      return (
        <LinkList
          meta={meta}
          record={record}
          classes={{
            label: classes.textWrapper
          }}
          testingid={genericTestingId}
        />
      );

    case 'chip': {
      const dataArray = typeof cellData === 'string' ? cellData.split(',') : cellData;
      return (
        <Grid container direction="row" wrap="nowrap">
          {dataArray &&
            dataArray.map(data => (
              <Chip
                key={`chip-${data}`}
                label={data}
                classes={{ root: classes.chipTag, labelSmall: classes.chipText }}
                clickable={false}
                size="small"
              />
            ))}
        </Grid>
      );
    }

    case 'collapsibleRows':
      return (
        <>
          {record.collapsibleRows ? (
            <div>{cellData}</div>
          ) : (
            <div>
              <div style={{ marginLeft: 20 }}>{cellData}</div>
            </div>
          )}
        </>
      );

    default:
      // No-op
      break;
  }

  if (meta.other) {
    switch (cellDataType) {
      case 'attachment':
        return (
          <ErrorBoundaries>
            <Button
              classes={{
                label: `${classes.flexStart} ${classes.textWrapper}`
              }}
              onClick={() =>
                downloadFile(
                  record[meta.other],
                  `${record.customFileName || cellData}`,
                  setShowDownloadLoader
                )
              }
              testingid={genericTestingId}
            >
              {meta.enableAttachmentIcon && (
                <DescriptionOutlined className={classes.attachmentIcon} />
              )}
              {meta.enableThumbnails && <TableThumbnail fileName={record.fileUrl} />}
              {record.customFileName || cellData}
              {showDownloadLoader && <Spinner size={18} />}
            </Button>
          </ErrorBoundaries>
        );

      case 'tooltip':
        return <TooltipIcon hoverText={cellData} />;

      case 'linkToImage':
        return <LinkToImage images={record[meta.other]} classes={classes} />;

      default:
        // No-op
        break;
    }
  }

  return cellTextEl;
}
