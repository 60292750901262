import gql from 'graphql-tag';

const assignCustomerRepsToCustomerProperty = gql`
  mutation assignCustomerRepsToCustomerProperty($data: AssignCustomerRepsToCustomerPropertyInput!) {
    assignCustomerRepsToCustomerProperty(data: $data) {
      id
      version
    }
  }
`;

export default assignCustomerRepsToCustomerProperty;
