import theme from 'themes/BuildHeroTheme';
import { TimeTrackingSettings } from 'utils/AppConstants';

export const timeTrackingSetting = isAllowedToEdit => ({
  fields: {},
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    color: theme.palette.secondary.main,
                    fontWeight: 'bold',
                    fontSize: 14
                  },
                  component: 'BodyText',
                  source: 'Tracking'
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0,
            paddingBottom: 75,
            borderBottom: `1px solid ${theme.palette.grayscale(80)}`
          },
          contents: [
            // left column
            {
              options: {
                direction: 'column',
                padding: [12, 0],
                width: '50%'
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '80%',
                    justify: 'space-between'
                  },
                  contents: [
                    {
                      options: {
                        fontSize: 20,
                        marginTop: 5 // to align the text in middle of the switch
                      },
                      component: 'BodyText',
                      source: 'Enable timestamp based tracking'
                    },
                    {
                      component: {
                        edit: 'ReadOnlyCheckboxInput',
                        view: 'ReadOnlyCheckboxInput',
                        default: 'CheckboxInput'
                      },
                      source: 'enableAutomatedTimeTracking',
                      options: {
                        checked: false,
                        value: '',
                        fullWidth: true,
                        labelPlacement: 'start',
                        displayAsSwitch: true
                      }
                    }
                  ]
                }
              ]
            },
            {
              options: {
                direction: 'column',
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '335px'
                  },
                  contents: [
                    {
                      options: {
                        color: theme.palette.grayscale(60)
                      },
                      component: 'BodyText',
                      source:
                        'Enabling this feature will not prompt mobile app users to enter timesheets manually. This setting can not be reversed'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    color: theme.palette.secondary.main,
                    fontWeight: 'bold',
                    fontSize: 14
                  },
                  component: 'BodyText',
                  source: 'Payroll Hours'
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0,
            paddingBottom: 75,
            borderBottom: `1px solid ${theme.palette.grayscale(80)}`
          },
          contents: [
            // left column
            {
              options: {
                direction: 'column',
                padding: [12, 0],
                width: '50%'
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '80%',
                    justify: 'space-between'
                  },
                  contents: [
                    {
                      options: {
                        fontSize: 20,
                        marginTop: 5 // to align the text in middle of the switch
                      },
                      component: 'BodyText',
                      source: 'Enable daily shift',
                      dependentField: {
                        fieldName: 'enableAutomatedTimeTracking',
                        expectedValue: false,
                        operation: 'bool',
                        action: 'DISABLE'
                      }
                    },
                    {
                      component: {
                        default: 'CheckboxInput',
                        view: 'ReadOnlyCheckboxInput'
                      },
                      source: 'dailyShift',
                      options: {
                        // label: 'Enable daily shift',
                        checked: false,
                        value: '',
                        fullWidth: true,
                        labelPlacement: 'start',
                        displayAsSwitch: true
                      },
                      dependentField: {
                        fieldName: 'enableAutomatedTimeTracking',
                        expectedValue: false,
                        operation: 'bool',
                        action: 'DISABLE'
                      }
                    }
                  ]
                }
              ]
            },
            {
              options: {
                direction: 'column',
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '335px'
                  },
                  contents: [
                    {
                      options: {
                        color: theme.palette.grayscale(60)
                      },
                      component: 'BodyText',
                      source:
                        'Enabling this feature will prompt mobile app users to clock in and clock out each workday'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    color: theme.palette.secondary.main,
                    fontWeight: 'bold',
                    fontSize: 14
                  },
                  component: 'BodyText',
                  source: 'Timestamps'
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0,
            paddingBottom: 75,
            borderBottom: `1px solid ${theme.palette.grayscale(80)}`
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: [12, 0]
              },
              contents: [
                {
                  component: 'RadioButtonsGroup',
                  source: 'timestamps',
                  options: {
                    btnDirection: 'Horizontal',
                    inputOptions: `${TimeTrackingSettings.ALL_TIMESTAMPS}, ${TimeTrackingSettings.DAILY_TIMESTAMPS}`,
                    isRequired: false,
                    fullWidth: true,
                    alignment: 'left',
                    disabled: !isAllowedToEdit,
                    // label styles are array because it gets stripped when forms are processed for finding contents []
                    labelStyle: [{ fontSize: 20, color: theme.palette.grayscale(20) }]
                  },
                  dependentField: {
                    fieldName: 'dailyShift',
                    expectedValue: false,
                    operation: 'bool',
                    action: 'DISABLE'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    padding: [12, 0],
                    width: '335px'
                  },
                  contents: [
                    {
                      options: {
                        color: theme.palette.grayscale(60)
                      },
                      component: 'BodyText',
                      source:
                        'Enable this feature to record all timestamps including daily shift. Disable this feature to record daily shift timestamps only.'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    color: theme.palette.secondary.main,
                    fontWeight: 'bold',
                    fontSize: 14
                  },
                  component: 'BodyText',
                  source: 'Events'
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: [12, 0, 0, 0],
                width: '50%'
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '80%',
                    justify: 'space-between'
                  },
                  contents: [
                    {
                      options: {
                        fontSize: 20,
                        marginTop: 5 // to align the text in middle of the switch
                      },
                      component: 'BodyText',
                      source: 'Enable Billable events'
                    },
                    {
                      component: {
                        default: 'CheckboxInput',
                        view: 'ReadOnlyCheckboxInput'
                      },
                      source: 'billableNonvisitEvents',
                      options: {
                        checked: false,
                        value: '',
                        fullWidth: true,
                        labelPlacement: 'start',
                        displayAsSwitch: true
                      }
                    }
                  ]
                }
              ]
            },
            {
              options: {
                direction: 'column',
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    padding: [12, 0],
                    width: '335px'
                  },
                  contents: [
                    {
                      options: {
                        color: theme.palette.grayscale(60)
                      },
                      component: 'BodyText',
                      source:
                        'These selections determine the handling of non-visit and office event types'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: [0, 0, 12, 0],
                width: '50%'
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '80%',
                    justify: 'space-between'
                  },
                  contents: [
                    {
                      options: {
                        fontSize: 20,
                        marginTop: 5 // to align the text in middle of the switch
                      },
                      component: 'BodyText',
                      source: 'Allow technicians to create Billable events',
                      // for aligning all thw switch control on same column, labels of switch are not used
                      dependentField: {
                        fieldName: 'billableNonvisitEvents',
                        expectedValue: false,
                        operation: 'bool',
                        action: 'DISABLE'
                      }
                    },
                    {
                      component: {
                        default: 'CheckboxInput',
                        view: 'ReadOnlyCheckboxInput'
                      },
                      source: 'allowTechCreateBillableNonvisitEvents',
                      options: {
                        checked: false,
                        value: '',
                        fullWidth: true,
                        labelPlacement: 'start',
                        displayAsSwitch: true
                      },
                      dependentField: {
                        fieldName: 'billableNonvisitEvents',
                        expectedValue: false,
                        operation: 'bool',
                        action: 'DISABLE'
                      }
                    }
                  ]
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: [12, 0, 0, 0],
                width: '50%'
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '80%',
                    justify: 'space-between'
                  },
                  contents: [
                    {
                      options: {
                        fontSize: 20,
                        marginTop: 5 // to align the text in middle of the switch
                      },
                      component: 'BodyText',
                      source: 'Enable Non-Billable events'
                    },
                    {
                      component: {
                        default: 'CheckboxInput',
                        view: 'ReadOnlyCheckboxInput'
                      },
                      source: 'nonBillableNonvisitEvents',
                      options: {
                        checked: false,
                        value: '',
                        fullWidth: true,
                        labelPlacement: 'start',
                        displayAsSwitch: true
                      }
                    }
                  ]
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0,
            paddingBottom: 75,
            borderBottom: `1px solid ${theme.palette.grayscale(80)}`
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: [0, 0, 12, 0],
                width: '50%'
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '80%',
                    justify: 'space-between'
                  },
                  contents: [
                    {
                      options: {
                        fontSize: 20,
                        marginTop: 5 // to align the text in middle of the switch
                      },
                      component: 'BodyText',
                      source: 'Allow technicians to create Non-Billable events'
                    },
                    {
                      component: {
                        default: 'CheckboxInput',
                        view: 'ReadOnlyCheckboxInput'
                      },
                      source: 'allowTechCreateNonBillableNonvisitEvents',
                      options: {
                        checked: false,
                        value: '',
                        fullWidth: true,
                        labelPlacement: 'start',
                        displayAsSwitch: true
                      },
                      dependentField: {
                        fieldName: 'nonBillableNonvisitEvents',
                        expectedValue: false,
                        operation: 'bool',
                        action: 'DISABLE'
                      }
                    }
                  ]
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0,
            width: '20%',
            padding: [12, 0]
          },
          contents: [
            {
              options: {
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    color: theme.palette.secondary.main,
                    fontWeight: 'bold',
                    fontSize: 14
                  },
                  component: 'BodyText',
                  source: 'Technician Settings'
                }
              ]
            }
          ]
        },
        {
          options: {
            paddingBottom: 75,
            borderBottom: `1px solid ${theme.palette.grayscale(80)}`
          },
          contents: [
            {
              options: {
                size: 2,
                direction: 'row',
                grow: 0,
                width: '50%'
              },
              contents: [
                {
                  options: {
                    padding: [0, 24, 0, 0],
                    width: '50%'
                  },
                  contents: [
                    {
                      component: {
                        add: 'SelectInput',
                        default: 'FieldWithLabel'
                      },
                      source: 'timeZone',
                      options: {
                        label: 'Timezone',
                        placeholder: 'Select...',
                        valueSet: [
                          { label: 'PST', value: 'America/Los_Angeles' },
                          { label: 'CST', value: 'America/Chicago' },
                          { label: 'EST', value: 'America/New_York' }
                        ],
                        isRequired: true,
                        disabled: !isAllowedToEdit
                      }
                    }
                  ]
                },
                {
                  options: {
                    padding: [0, 0, 0, 24],
                    width: '50%'
                  },
                  contents: [
                    {
                      component: {
                        add: 'SelectInput',
                        default: 'FieldWithLabel'
                      },
                      source: 'weekStartDay',
                      options: {
                        label: 'Week start day',
                        placeholder: 'Select...',
                        valueSet: [
                          { label: 'Monday', value: 'MONDAY' },
                          { label: 'Tuesday', value: 'TUESDAY' },
                          { label: 'Wednesday', value: 'WEDNESDAY' },
                          { label: 'Thursday', value: 'THURSDAY' },
                          { label: 'Friday', value: 'FRIDAY' },
                          { label: 'Saturday', value: 'SATURDAY' },
                          { label: 'Sunday', value: 'SUNDAY' }
                        ],
                        isRequired: true,
                        disabled: !isAllowedToEdit
                      }
                    }
                  ]
                },
                {
                  contents: [
                    {
                      component: null
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0,
            width: '20%',
            padding: [12, 0]
          },
          contents: [
            {
              options: {
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    color: theme.palette.secondary.main,
                    fontWeight: 'bold',
                    fontSize: 14
                  },
                  component: 'BodyText',
                  source: 'Payroll Hour Types'
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0
          },
          contents: [
            // left column
            {
              options: {
                direction: 'column',
                padding: [12, 0],
                width: '50%'
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '80%',
                    justify: 'space-between'
                  },
                  contents: [
                    {
                      options: {
                        fontSize: 20,
                        marginTop: 5 // to align the text in middle of the switch
                      },
                      component: 'BodyText',
                      source: 'Map Payroll Hours to Billing Hours'
                    },
                    {
                      component: {
                        default: 'CheckboxInput',
                        view: 'ReadOnlyCheckboxInput'
                      },
                      source: 'mapPayrollHourToBilling',
                      options: {
                        checked: false,
                        value: '',
                        fullWidth: true,
                        labelPlacement: 'start',
                        displayAsSwitch: true
                      }
                    }
                  ]
                }
              ]
            },
            {
              options: {
                direction: 'column',
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '335px'
                  },
                  contents: [
                    {
                      options: {
                        color: theme.palette.grayscale(60)
                      },
                      component: 'BodyText',
                      source:
                        'Enabling this feature will allow payroll hour types to be mapped to billing hour types so that billing hours are automatically filled when labor line items are generated on review reports.'
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  }
});

export default timeTrackingSetting;
