const changeOrderRowsMeta = (mode, projectId) => [
  {
    id: 'number',
    label: 'Number',
    showLink: 'view',
    linkPath: `/project/${mode}/${projectId}/changeorder`,
    linkReference: 'id',
    type: 'string',
    numeric: true
  },
  {
    id: 'dueDate',
    label: 'Due Date',
    type: 'date'
  },
  {
    id: 'subject',
    label: 'Subject',
    type: 'string'
  },
  {
    id: 'scopeOfWork',
    type: 'string',
    label: 'Scope of Work'
  },
  {
    id: 'revenue',
    type: 'currency',
    label: 'Revenue',
    align: 'left'
  },
  {
    id: 'cost',
    type: 'currency',
    label: 'Cost',
    align: 'left'
  },
  {
    id: 'profit',
    type: 'currency',
    label: 'Profit',
    align: 'left'
  },
  {
    id: 'margin',
    type: 'percentage',
    label: 'Margin'
  },
  {
    id: 'dateApproved',
    type: 'date',
    label: 'Approved Date'
  }
];

export default changeOrderRowsMeta;
