import React, { useRef, memo } from 'react';
import { useDepartments, usePayrollHourTypes } from '@dispatch/queries';
import NonBillableEventForm from './NonBillableEventForm.component';

const DataProvider = props => {
  const Memoized = useRef(memo(NonBillableEventForm)).current;
  const departmentsResponse = useDepartments();
  const payrollHourTypesResponse = usePayrollHourTypes();
  return (
    <Memoized
      {...props}
      payrollHourTypesResponse={payrollHourTypesResponse}
      departmentsResponse={departmentsResponse}
    />
  );
};

export default DataProvider;
