/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const AddServiceAgreementToCompany = gql`
  mutation AddServiceAgreementToCompany($data: ServiceAgreementInput!) {
    addServiceAgreementToCompany(data: $data) {
      id
      companyId
      status
      agreementName
      agreementNumber
    }
  }
`;
