import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { snackbarOn } from 'redux/actions/globalActions';
import {
  PresetSelect,
  ThemeProvider,
  SideSheet,
  SgtForm,
  Typography,
  TV,
  TW
} from '@buildhero/sergeant';
import { generateSettingLayout } from 'utils/settingsHelper';
import { SergeantModal } from 'components';
import { Mode } from 'utils/constants';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import RemoveIcon from '@material-ui/icons/Remove';
import RenameModal from 'components/ResponsiveTable/RenameModal';
import useMutatePresets from 'customHooks/useMutatePresets';
import styles from './styles';
import { SettingConstants } from '../../constants';
import { updateConfigSettingsOnQuote } from '../../service';
import ReadonlyWrapper from '../ReadonlyWrapper';

const QUOTE_PRESETS = 'Quote Presets';

const title = 'Quote Configuration';
const subtext = 'These settings will change what is shown on the customer facing quote';

const initialRenameModalState = {
  open: false,
  mode: Mode.ADD,
  presetOption: null
};

/**
 * Renders the quote settings in a side sheet.
 *
 * @param props
 * @param props.onUpdateSettings Callback for updating the invoice settings
 * @param props.settings The object containing quote setting data
 */

const QuoteSettings = ({
  classes,
  isReadOnly,
  snackbar,
  user,
  serviceProps,
  onSettingsChange,
  currentSettings,
  isOverridesModalOpen,
  setIsOverridesModalOpen,
  setCurrentSettings,
  isAssetEnabled
}) => {
  const [formService, setFormService] = useState();
  const [renameModalState, setRenameModalState] = useState(initialRenameModalState);

  const {
    presets,
    loading,
    findPreset,
    createPreset,
    updatePreset,
    renamePreset,
    duplicatePreset,
    deletePreset
  } = useMutatePresets({
    user,
    snackbar,
    entityName: SettingConstants.QUOTE_PRESETS,
    isAssetEnabled
  });

  const maps = useMemo(
    () => ({
      dependentMap: {
        'Quote Mode': { fieldName: 'Quote Mode.Enable overrides', disableIf: true }
      },
      propsMap: {
        [QUOTE_PRESETS]: presets?.map(preset => preset[QuoteSettings.QUOTE_PRESETS])
      },
      changeHandlerMap: {
        'Quote Mode.Enable overrides': (key, value, form) => {
          // values is the previous state of the form
          const { setValues, values } = form;
          // set the overrides toggle back to false
          setValues(values);
          setFormService(form);
          setIsOverridesModalOpen(true);
        }
      }
    }),
    [presets, setIsOverridesModalOpen]
  );

  const presetsSelectChangeHandler = useCallback(
    (key, value, form) => {
      const settings = findPreset(value.value);
      if (form.values[SettingConstants.QUOTE_MODE][SettingConstants.ENABLE_OVERRIDES]) {
        settings[SettingConstants.QUOTE_MODE][SettingConstants.ENABLE_OVERRIDES] = true;
      }
      form.setValues(settings);
    },
    [findPreset]
  );

  const { [QUOTE_PRESETS]: presetsSetting, ...sectionsSettings } = currentSettings || {};
  const layout = useMemo(
    () =>
      generateSettingLayout(
        title,
        sectionsSettings,
        presets,
        maps,
        'PresetSelect',
        presetsSelectChangeHandler,
        subtext,
        null,
        SettingConstants.QUOTE_PRESETS
      ),
    [maps, presets, presetsSetting]
  );

  const customWarning = () => {
    return (
      <ThemeProvider>
        <Typography variant={TV.BASE}>
          By proceeding, you'll:
          <br />
          <br />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <RemoveIcon className={classes.removeIcon} />
            <Typography className={classes.loseText} weight={TW.BOLD}>
              Lose:{' '}
            </Typography>
            Calculated pricing information and profitability analysis
          </div>
          <br />
          Once enabled, overrides can't be disabled on this quote.
        </Typography>
      </ThemeProvider>
    );
  };
  const overrideWarningLayout = {
    fields: {},
    layouts: {
      default: {
        options: {
          paddingLeft: 8
        },
        contents: [
          {
            component: {
              default: 'customWarning'
            },
            source: ''
          }
        ]
      }
    }
  };

  const onSettingsSave = useCallback(
    (_, { values }) => {
      updateConfigSettingsOnQuote({
        ...serviceProps,
        settings: values
      });
    },
    [serviceProps]
  );

  const handleEnableOverrides = (_, stopLoading) => {
    const { setValues, values } = formService;
    const newSettings = {
      ...values,
      [SettingConstants.QUOTE_MODE]: {
        [SettingConstants.ENABLE_OVERRIDES]: true
      }
    };
    setValues(newSettings);
    setCurrentSettings(newSettings);
    setIsOverridesModalOpen(false);
    stopLoading();
  };

  const handleCloseOverridesModal = () => {
    setIsOverridesModalOpen(false);
  };

  const onClickCreateNewPreset = useCallback(
    async values =>
      setRenameModalState({
        open: true,
        mode: Mode.ADD,
        presetOption: {
          settings: values
        }
      }),
    []
  );

  const onClickRenamePreset = useCallback(
    async presetId =>
      setRenameModalState({
        open: true,
        mode: Mode.RENAME,
        presetOption: findPreset(presetId)
      }),
    [findPreset]
  );

  const onRenamePreset = useCallback(
    async name => {
      if (loading) return;

      if (!name) {
        snackbar('error', 'Preset name is required');
        return;
      }
      switch (renameModalState.mode) {
        case Mode.RENAME: {
          await renamePreset(name, renameModalState.presetOption[QUOTE_PRESETS].value);
          break;
        }
        case Mode.ADD: {
          await createPreset(name, renameModalState.presetOption.settings);
          break;
        }
        default: {
          // do nothing
        }
      }
    },
    [loading, renameModalState, snackbar, renamePreset, createPreset]
  );

  const sideSheet = useMemo(
    () => (
      <ThemeProvider>
        {currentSettings && (
          <>
            <SideSheet>
              <ReadonlyWrapper readOnly={isReadOnly}>
                <SgtForm
                  key={serviceProps.quoteInfo.id}
                  layout="default"
                  configuration={layout}
                  customComponents={{
                    PresetSelect: componentProps => (
                      <PresetSelect
                        selectedOption={{
                          label: componentProps.field.value.label,
                          id: componentProps.field.value.value
                        }}
                        options={componentProps.props.options.map(preset => ({
                          label: preset.label,
                          id: preset.value,
                          isDefault: preset.isDefault
                        }))}
                        onSelect={newValue => {
                          const key = componentProps.field.name;
                          const value = {
                            label: '',
                            value: newValue
                          };
                          componentProps.props.onChange(key, value, componentProps.form);
                        }}
                        onClickUpdate={() => updatePreset(componentProps.form.values)}
                        onClickCreateNew={() => onClickCreateNewPreset(componentProps.form.values)}
                        onClickDuplicate={duplicatePreset}
                        onClickRename={onClickRenamePreset}
                        onClickRemove={deletePreset}
                        {...componentProps}
                      />
                    )
                  }}
                  initialValues={currentSettings}
                  onCreateService={setFormService}
                  onSaveOnChange={onSettingsSave}
                  onFormChange={onSettingsChange}
                />
              </ReadonlyWrapper>
            </SideSheet>

            <RenameModal
              open={renameModalState.open}
              mode={renameModalState.mode}
              currentLabel={
                renameModalState.mode === Mode.ADD
                  ? ''
                  : renameModalState.presetOption[QUOTE_PRESETS].label ?? ''
              }
              handleViewRename={onRenamePreset}
              handleClose={() => setRenameModalState(initialRenameModalState)}
              dataType={QUOTE_PRESETS}
            />
          </>
        )}
      </ThemeProvider>
    ),
    [
      currentSettings,
      isReadOnly,
      layout,
      onSettingsSave,
      serviceProps.quoteInfo.id,
      renameModalState,
      onClickCreateNewPreset,
      onClickRenamePreset,
      onRenamePreset
    ]
  );

  return (
    <>
      {sideSheet}
      <SergeantModal
        titleIcon={() => <ReportProblemOutlinedIcon className={classes.warningIcon} />}
        customComponents={{ customWarning }}
        formVersion="default"
        open={isOverridesModalOpen}
        mode={Mode.ENABLE}
        dataType="Overrides"
        handlePrimaryAction={handleEnableOverrides}
        handleClose={handleCloseOverridesModal}
        customPrimaryButtonLabel="Enable Overrides"
        maxWidth={500}
        layout={overrideWarningLayout}
      />
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  snackbar: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

export default withStyles(styles)(connect(null, mapDispatchToProps)(QuoteSettings));

QuoteSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  snackbar: PropTypes.func,
  user: PropTypes.object.isRequired,
  serviceProps: PropTypes.object.isRequired,
  onSettingsChange: PropTypes.func.isRequired,
  currentSettings: PropTypes.object.isRequired,
  isOverridesModalOpen: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  setIsOverridesModalOpen: PropTypes.func.isRequired,
  setCurrentSettings: PropTypes.func.isRequired,
  isAssetEnabled: PropTypes.bool.isRequired
};

QuoteSettings.defaultProps = {
  snackbar: () => {}
};
