import Enum from 'enum';

export const VisitFormFields = new Enum({
  actualDuration: 'DURATION',
  customerName: 'CUSTOMER',
  departmentId: 'DEPARTMENT',
  description: 'VISIT DESCRIPTION',
  id: 'ID',
  onHoldReason: 'ON HOLD REASON',
  prerequisites: 'PRE-DISPATCH ACTIONS',
  prerequisitesAcknowledged: 'DONE',
  propertyAddress: 'PROPERTY ADDRESS',
  propertyRep: 'PROPERTY REPRESENTATIVE',
  scheduledFor: 'DATE & TIME',
  status: 'STATUS',
  primaryTechId: 'PRIMARY TECHNICIAN',
  extraTechsNumber: 'NUMBER OF ADDITIONAL TECHNICIANS REQUIRED',
  extraTechs: 'ADDITIONAL TECHNICIANS',
  endDate: 'END DATE',
  excludeWeekend: 'EXCLUDE WEEKENDS'
});

export const CustomFormComponents = {
  AccordionSection: 'AccordionSection',
  CreateMultipleVisitsSwitch: 'CreateMultipleVisitsSwitch',
  DateTimeInput: 'DateTimeInput',
  DurationInput: 'DurationInput',
  TechStatuses: 'TechStatuses'
};
