export const labelSchema = {
  'Add role': {
    en: 'Add role',
    es: 'Agregar rol'
  },
  'Access rights to': {
    en: 'Access rights to',
    es: 'Derechos de acceso para'
  },
  company: {
    en: 'Company',
    es: 'Compañia'
  },
  people: {
    en: 'People',
    es: 'Gente'
  },
  customers: {
    en: 'Customers',
    es: 'Clientes'
  },
  jobs: {
    en: 'Jobs',
    es: 'Empleos'
  },
  dispatch: {
    en: 'Dispatch',
    es: 'Despacho'
  },
  mobile: {
    en: 'Mobile',
    es: 'Móvil'
  },
  jobTitle: {
    en: 'Job title',
    es: 'Título profesional'
  },
  Data: {
    en: 'Data',
    es: 'Data'
  },
  'View Cost Data': {
    en: 'View Cost Data',
    es: 'View Cost Data'
  },
  'Data-> View Cost Data': {
    en: 'Data-> View Cost Data',
    es: 'Data-> View Cost Data'
  },
  'Access rights to View Cost Data': {
    en: 'Access rights to View Cost Data',
    es: 'Access rights to View Cost Data'
  },
  'View Price Data': {
    en: 'View Price Data',
    es: 'View Price Data'
  },
  'Data-> View Price Data': {
    en: 'Data-> View Price Data',
    es: 'Data-> View Price Data'
  },
  'Access rights to View Price Data': {
    en: 'Access rights to View Price Data',
    es: 'Access rights to View Price Data'
  },
  'Show Financial Data': {
    en: 'Show Financial Data',
    es: 'Show Financial Data'
  },
  'Data-> Show Financial Data': {
    en: 'Data-> Show Financial Data',
    es: 'Data-> Show Financial Data'
  },
  'Access rights to Show Financial Data': {
    en: 'Access rights to Show Financial Data',
    es: 'Access rights to Show Financial Data'
  },
  Menu: {
    en: 'Menu',
    es: 'Menu'
  },
  Settings: {
    en: 'Settings',
    es: 'Settings'
  },
  'Menu-> Settings': {
    en: 'Menu-> Settings',
    es: 'Menu-> Settings'
  },
  'Access rights to Settings': {
    en: 'Access rights to Settings',
    es: 'Access rights to Settings'
  },
  'Reports And Dashboards': {
    en: 'Reports And Dashboards',
    es: 'Reports And Dashboards'
  },
  'Menu-> Reports And Dashboards': {
    en: 'Menu-> Reports And Dashboards',
    es: 'Menu-> Reports And Dashboards'
  },
  'Access rights to Reports And Dashboards': {
    en: 'Access rights to Reports And Dashboards',
    es: 'Access rights to Reports And Dashboards'
  },
  'Follow ups': {
    en: 'Follow ups',
    es: 'Follow ups'
  },
  'Menu-> Follow ups': {
    en: 'Menu-> Follow ups',
    es: 'Menu-> Follow ups'
  },
  'Access rights to Follow ups': {
    en: 'Access rights to Follow ups',
    es: 'Access rights to Follow ups'
  },
  Dispatch: {
    en: 'Dispatch',
    es: 'Dispatch'
  },
  'Menu-> Dispatch': {
    en: 'Menu-> Dispatch',
    es: 'Menu-> Dispatch'
  },
  'Access rights to Dispatch': {
    en: 'Access rights to Dispatch',
    es: 'Access rights to Dispatch'
  },
  Lists: {
    en: 'Lists',
    es: 'Lists'
  },
  'Menu-> Lists': {
    en: 'Menu-> Lists',
    es: 'Menu-> Lists'
  },
  'Access rights to Lists': {
    en: 'Access rights to Lists',
    es: 'Access rights to Lists'
  },
  Accounting: {
    en: 'Accounting',
    es: 'Accounting'
  },
  'Menu-> Accounting': {
    en: 'Menu-> Accounting',
    es: 'Menu-> Accounting'
  },
  'Access rights to Accounting': {
    en: 'Access rights to Accounting',
    es: 'Access rights to Accounting'
  },
  Functions: {
    en: 'Functions',
    es: 'Functions'
  },
  Mobile: {
    en: 'Mobile',
    es: 'Mobile'
  },
  'Functions-> Mobile': {
    en: 'Functions-> Mobile',
    es: 'Functions-> Mobile'
  },
  'Access rights to Mobile': {
    en: 'Access rights to Mobile',
    es: 'Access rights to Mobile'
  },
  Dispatchable: {
    en: 'Dispatchable',
    es: 'Dispatchable'
  },
  'Functions-> Dispatchable': {
    en: 'Functions-> Dispatchable',
    es: 'Functions-> Dispatchable'
  },
  'Access rights to Dispatchable': {
    en: 'Access rights to Dispatchable',
    es: 'Access rights to Dispatchable'
  },
  Admin: {
    en: 'Admin',
    es: 'Admin'
  },
  'Functions-> Admin': {
    en: 'Functions-> Admin',
    es: 'Functions-> Admin'
  },
  'Access rights to Admin': {
    en: 'Access rights to Admin',
    es: 'Access rights to Admin'
  },
  Object: {
    en: 'Object',
    es: 'Object'
  },
  Customer: {
    en: 'Customer',
    es: 'Customer'
  },
  'Object-> Customer': {
    en: 'Object-> Customer',
    es: 'Object-> Customer'
  },
  'Access rights to Customer': {
    en: 'Access rights to Customer',
    es: 'Access rights to Customer'
  },
  Property: {
    en: 'Property',
    es: 'Property'
  },
  'Object-> Property': {
    en: 'Object-> Property',
    es: 'Object-> Property'
  },
  'Access rights to Property': {
    en: 'Access rights to Property',
    es: 'Access rights to Property'
  },
  Job: {
    en: 'Job',
    es: 'Job'
  },
  'Object-> Job': {
    en: 'Object-> Job',
    es: 'Object-> Job'
  },
  'Access rights to Job': {
    en: 'Access rights to Job',
    es: 'Access rights to Job'
  },
  Task: {
    en: 'Task',
    es: 'Task'
  },
  'Object-> Task': {
    en: 'Object-> Task',
    es: 'Object-> Task'
  },
  'Access rights to Task': {
    en: 'Access rights to Task',
    es: 'Access rights to Task'
  },
  Visit: {
    en: 'Visit',
    es: 'Visit'
  },
  'Object-> Visit': {
    en: 'Object-> Visit',
    es: 'Object-> Visit'
  },
  'Access rights to Visit': {
    en: 'Access rights to Visit',
    es: 'Access rights to Visit'
  },
  Asset: {
    en: 'Asset',
    es: 'Asset'
  },
  'Object-> Asset': {
    en: 'Object-> Asset',
    es: 'Object-> Asset'
  },
  'Access rights to Asset': {
    en: 'Access rights to Asset',
    es: 'Access rights to Asset'
  },
  Quotes: {
    en: 'Quotes',
    es: 'Quotes'
  },
  'Object-> Quotes': {
    en: 'Object-> Quotes',
    es: 'Object-> Quotes'
  },
  'Access rights to Quotes': {
    en: 'Access rights to Quotes',
    es: 'Access rights to Quotes'
  },
  Employee: {
    en: 'Employee',
    es: 'Employee'
  },
  'Object-> Employee': {
    en: 'Object-> Employee',
    es: 'Object-> Employee'
  },
  'Access rights to Employee': {
    en: 'Access rights to Employee',
    es: 'Access rights to Employee'
  },
  Invoice: {
    en: 'Invoice',
    es: 'Invoice'
  },
  'Object-> Invoice': {
    en: 'Object-> Invoice',
    es: 'Object-> Invoice'
  },
  'Access rights to Invoice': {
    en: 'Access rights to Invoice',
    es: 'Access rights to Invoice'
  },
  ReviewReport: {
    en: 'ReviewReport',
    es: 'ReviewReport'
  },
  'Object-> ReviewReport': {
    en: 'Object-> ReviewReport',
    es: 'Object-> ReviewReport'
  },
  'Access rights to ReviewReport': {
    en: 'Access rights to ReviewReport',
    es: 'Access rights to ReviewReport'
  }
};

export default labelSchema;
