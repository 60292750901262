export const RenameViewLayout = {
  fields: {},
  layouts: {
    default: {
      contents: [
        {
          component: 'TextInput',
          source: 'viewName',
          options: {
            label: 'New Label',
            lines: 1,
            isRequired: false
          }
        }
      ]
    }
  },
  validation: {
    $schema: 'http://json-schema.org/draft-07/schema#',
    title: 'Line Item',
    type: 'object',
    properties: {
      jobView: { type: 'string', required: false }
    }
  }
};
