const styles = theme => ({
  checkboxLabel: {
    marginLeft: 0
  },
  checkboxStyle: {
    paddingLeft: 5
  }
});

export default styles;
