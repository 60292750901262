import { column, ColumnType, valueGetters } from 'components/XGrid/columnTypes';
import { EnumType } from 'utils/constants';
import DownloadWIPReport from '../components/DownloadWIPReport';

export const WIP_LIST_COLUMNS = [
  {
    field: 'recordNumber',
    headerName: 'Record Number',
    width: 150,
    dataField: 'id',
    valueGetter: valueGetters.wipLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'createdDate',
    headerName: 'As Of Date',
    width: 150,
    ...column[ColumnType.DATE]
  },
  {
    field: 'syncStatus',
    headerName: 'Sync Status',
    width: 150,
    enumType: EnumType.SYNC_STATUS,
    ...column[ColumnType.ENUM]
  },
  {
    field: 'updatedContractsAmount',
    headerName: 'Updated Contract Amounts',
    width: 150,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'updatedCostBudgets',
    headerName: 'Updated Cost Budgets',
    width: 150,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'updatedEstimatedGrossProfits',
    headerName: 'Updated Estimated Gross Profits',
    width: 150,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'actualCostsToDate',
    headerName: 'Actual Costs To Date',
    width: 150,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'revenueCompletedToDate',
    headerName: 'Revenue Completed To Date',
    width: 150,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'billedToDate',
    headerName: 'Billed To Date',
    width: 150,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'costToComplete',
    headerName: 'Cost To Complete',
    width: 150,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'overBilled',
    headerName: 'Over Billed',
    width: 150,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'underBilled',
    headerName: 'Under Billed',
    width: 150,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'totalHours',
    headerName: 'Total Hours',
    width: 150,
    ...column[ColumnType.NUMBER]
  },
  {
    field: 'hoursUsed',
    headerName: 'Hours Used',
    width: 150,
    ...column[ColumnType.NUMBER]
  },
  {
    field: 'downloadWIPReport',
    headerName: 'Download',
    sortable: false,
    filterable: false,
    width: 150,
    renderCell: ({ row }) => <DownloadWIPReport wipReport={row} />
  }
];
