import gql from 'graphql-tag';

const addEmployeePayrollsToEmployee = gql`
  mutation AddEmployeePayrollsToEmployee(
    $partitionKey: String
    $data: AddEmployeePayrollsToEmployeeInput!
  ) {
    addEmployeePayrollsToEmployee(partitionKey: $partitionKey, data: $data) {
      id
      timeCards(entityConnection: "TimeCard") {
        items {
          id
        }
      }
    }
  }
`;

export default addEmployeePayrollsToEmployee;
