/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DescriptionIcon from '@material-ui/icons/Description';
// eslint-disable-next-line import/no-cycle
import { AddRecordButton, SergeantModal } from 'components';
import { sentryException } from 'services/Logger';
import validateEmail from 'utils/validateEmail';
import sendEmailForm from 'meta/Procurement/PurchaseOrders/sendEmailForm';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { downloadFile } from 'scenes/Procurement/component/utils';
import { sendEmail } from 'services/API/sendEmail';
import { getCloudinaryImageUrl } from 'scenes/ProjectManagement/components/utils';

const useStyles = makeStyles(() => ({
  pdfContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: 'none',
    backgroundColor: 'white',
    border: '1px solid #00854B'
  },
  iconNameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  viewPdfBtn: {
    marginRight: 8,
    padding: 8,
    backgroundColor: 'transparent'
  }
}));

const CustomFilContainer = ({ options }) => {
  const classes = useStyles();
  return (
    <Card className={classes.pdfContainer}>
      <div className={classes.iconNameContainer}>
        <IconButton onClick={() => downloadFile(options.fileUrl)}>
          <DescriptionIcon color="secondary" />
        </IconButton>
        <Typography>{options.fileName || 'No file attached'}</Typography>
      </div>
      <AddRecordButton
        label="View PDF"
        handle={() => downloadFile(options.fileUrl)}
        className={classes.viewPdfBtn}
      />
    </Card>
  );
};

const SendEmailPopUp = props => {
  const {
    open,
    title,
    btnLabel,
    pdfData,
    uploadedPdfUrl,
    uploadedPdfFileName,
    orderEmailRecipient,
    emailSubject,
    handleModalClose,
    user,
    snackbar,
    description
  } = props;

  const [recipients, setRecipients] = useState([]);

  useEffect(() => {
    const getEmailRecipient = () => {
      if (orderEmailRecipient && orderEmailRecipient.length > 0) {
        setRecipients([...recipients, { label: orderEmailRecipient, value: orderEmailRecipient }]);
      }
    };
    getEmailRecipient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderEmailRecipient]);

  const getFormattedData = () => {
    return {
      emailSubject: emailSubject || '',
      description,
      recipients: recipients.map(r => r.value)
    };
  };

  const onCreate = selectedOption => {
    const { form, field, value } = selectedOption;
    const validEmail = validateEmail(value);
    if (!validEmail) return;
    setRecipients([...recipients, { label: value, value }]);
    const newFieldValue = Array.isArray(field.value) ? [...field.value, value] : [value];
    form.setFieldValue(field.name, newFieldValue);
  };

  const handleClose = () => {
    setRecipients([{ label: orderEmailRecipient, value: orderEmailRecipient }]);
    handleModalClose();
  };

  const handleSendEmail = async (formData, stopLoading) => {
    try {
      const toEmails = recipients.map(recipient => recipient.value);

      const payload = {
        from: 'noreply@buildops.com',
        fromName: user.displayName || undefined,
        replyTo: user.email,
        importance: 'High',
        subject: formData.emailSubject || 'Purchase Order Receipt',
        to: toEmails,
        attachments: {},
        html: formData?.description || 'No description'
      };

      if (pdfData.senderLogoUrl) {
        payload.attachments.logo = {
          cid: 'logo',
          source: pdfData.senderLogoUrl
        };
      }

      if (uploadedPdfUrl) {
        payload.attachments[uploadedPdfFileName] = {
          source: getCloudinaryImageUrl(uploadedPdfUrl)
        };
      }

      const { data } = await sendEmail(payload);

      if (data) {
        snackbar('success', 'Email sent successfully');
      }
    } catch (e) {
      sentryException(e, 'Unable to send invoice email');
      snackbar('error', 'Unable to send email');
    } finally {
      handleModalClose();
      stopLoading();
    }
  };

  return (
    <ErrorBoundaries>
      <SergeantModal
        open={open}
        title={title}
        customPrimaryButtonLabel={btnLabel}
        dataType="Send invoice"
        mode="new"
        data={getFormattedData()}
        layout={sendEmailForm({
          recipients,
          emailSubject,
          onCreate,
          uploadedPdfFileName,
          uploadedPdfUrl
        })}
        handlePrimaryAction={handleSendEmail}
        handleClose={handleClose}
        customComponents={{ CustomFilContainer }}
      />
    </ErrorBoundaries>
  );
};

CustomFilContainer.propTypes = {
  options: PropTypes.object.isRequired
};

SendEmailPopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  btnLabel: PropTypes.string.isRequired,
  pdfData: PropTypes.object.isRequired,
  uploadedPdfUrl: PropTypes.string.isRequired,
  uploadedPdfFileName: PropTypes.string.isRequired,
  orderEmailRecipient: PropTypes.string.isRequired,
  emailSubject: PropTypes.string.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  snackbar: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  snackbar: (mode, message) => dispatch(snackbarOn(mode, message))
});
const connectedSendEmailPopUp = connect(mapStateToProps, mapDispatchToProps)(SendEmailPopUp);
export default connectedSendEmailPopUp;
