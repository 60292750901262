import gql from 'graphql-tag';

export const updateCompany = gql`
  mutation updateCompany($partitionKey: String!, $data: UpdateCompanyInput!) {
    updateCompany(partitionKey: $partitionKey, data: $data) {
      id
      version
      defaultPaymentTermId
      defaultLedgerAccountId
      defaultBankAccountId
    }
  }
`;
