import React from 'react';
import axios from 'axios';
import configForEnvironment from 'configs/aws-exports';
import ENV from 'configs/env';
import { Spinner } from 'components';
import Error from './Error';
import QuotesPage from './Quotes';
import actions from './actions';

class PublicPage extends React.Component {
  config = {
    'quotes/customer-approval': QuotesPage
  };

  constructor(props) {
    super(props);
    const tokenObject = new URLSearchParams(props.location.search);
    this.state = {
      token: tokenObject.get('token'),
      page: '',
      data: {},
      error: null,
      loading: true
    };
  }

  handleResponse = response => {
    let error = null;
    switch (response.status) {
      case 200:
        break;
      case 401:
        error = 'Error: The token you tried using is invalid. Please check the URL.';
        break;
      case 403:
        error =
          'The link to the Quote you are trying to access has expired. Please contact the sender to resend the Quote.';
        break;
      case 404:
        error = 'Error: The Page you tried to access is not found.';
        break;
      case 500:
        error = 'Error: Server Error! Please try again after sometime.';
        break;
      default:
        error = 'Error: Unknown Error! Sorry for the inconvenience.';
    }
    this.setState({ page: '', data: {}, error, loading: false });
  };

  getPageInformationToRender = token =>
    axios
      .post(configForEnvironment(ENV).publicUrlEndpoint, {
        token,
        action: actions.render.name
      })
      .then(response => this.setState({ ...response.data, error: null, loading: false }))
      .catch(error => this.handleResponse(error.response));

  componentDidMount = async () => {
    const { token } = this.state;
    if (!token) {
      this.setState({
        error: 'Error: Access token is empty. Please check the URL',
        loading: false
      });
      return;
    }
    this.getPageInformationToRender(token);
  };

  render() {
    const { page, data, error, loading, token } = this.state;
    const PageToRender = this.config[page];
    const renderComponent =
      !error && PageToRender ? (
        <PageToRender dataToRender={{ ...data }} token={token} />
      ) : (
        <Error message={error} />
      );

    return loading ? <Spinner type="FAST" /> : renderComponent;
  }
}

export default PublicPage;
