import gql from 'graphql-tag';

const updatePaymentType = gql`
  mutation updatePaymentType($partitionKey: String, $data: UpdatePaymentTypeInput!) {
    updatePaymentType(partitionKey: $partitionKey, data: $data) {
      id
      name
      version
      accountingRefId
      lastUpdatedDateTime
      ledgerAccountId
      ledgerAccount(entityConnection: "LedgerAccount") {
        name
        accountNumber
        accountType
        accountingRefId
        accountingApplication
        id
      }
    }
  }
`;

export default updatePaymentType;
