const styles = theme => ({
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(6)
  },
  pageActionButton: {
    float: 'right',
    width: '176px',
    height: '36px',
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(3.75),
    marginLeft: theme.spacing(2.5)
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  ocrButton: {
    marginBottom: theme.spacing(3.5),
    marginLeft: theme.spacing(3.5),
    padding: 0
  },
  ocrInputStyle: {
    marginTop: 5,
    paddingBottom: 2,
    width: '100%'
  }
});

export default styles;
