import gql from 'graphql-tag';

const getProductsByProductBundleId = gql`
  query getProductBundleById(
    $id: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getProductBundleById(id: $id) {
      id
      version
      isActive
      name
      description
      category
      subCategory
      type
      productBundleEntriesView(
        entityConnection: "ProductBundleEntry"
        filter: $filter
        pagination: { limit: $limit, offset: $offset }
        sorting: $sort
      ) {
        nextToken
        items {
          id
          version
          productId
          isActive
          name
          description
          unitCost
          quantity
          taxable
          quantityOnHand
          minQuantity
          maxQuantity
          product(entityConnection: "Product") {
            id
            name
            unitOfMeasure(entityConnection: "UnitOfMeasure") {
              id
              name
              unitOfMeasureCategory
              unitOfMeasureType
            }
          }
        }
      }
    }
  }
`;

export default getProductsByProductBundleId;
