import gql from 'graphql-tag';

export const GET_FOLLOWUPS = gql`
  query getFollowUpList(
    $tenantId: String
    $followUpType: FollowUpType!
    $filter: TableFilterInput
    $pagination: PaginationInput
    $sorting: [TableSortingInput]
  ) {
    data: getFollowUpList(
      tenantId: $tenantId
      filter: $filter
      pagination: $pagination
      sorting: $sorting
      followUpType: $followUpType
    ) {
      rowCount
      items
    }
  }
`;

export const GET_FOLLOW_UP_COUNT = gql`
  query getFollowUpCount($tenantId: String, $filter: FollowUpCountFilter) {
    getFollowUpCounts(tenantId: $tenantId, filter: $filter)
  }
`;
