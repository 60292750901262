const styles = theme => ({
  closeText: {
    fontWeight: 'normal',
    letterSpacing: 0.25
  },
  sizingContainer: ({ center, width, autoSize }) => ({
    minWidth: width,
    '& > *': {
      minWidth: width
    },
    '& *': {
      minHeight: autoSize ? 'unset' : undefined,
      textAlign: center ? 'center' : undefined
    }
  }),
  dialogContent: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 300,
      minHeight: 240
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 696,
      minHeight: 240
    },
    paddingBottom: 0
  },
  dialogContentGrid: {
    marginBottom: 90
  },
  dialogActions: ({ center }) => ({
    backgroundColor: theme.palette.other.ghostWhite,
    height: 70,
    position: 'absolute',
    width: '100%',
    left: 0,
    bottom: 0,
    ...(center
      ? {
          display: 'flex',
          justifyContent: 'center'
        }
      : {})
  }),
  pageActionButton: {
    float: 'right',
    minWidth: 70,
    height: '32px',
    textTransform: 'uppercase'
    // marginBottom: theme.spacing(2.5),
    // marginTop: theme.spacing(3.75),
    // marginLeft: theme.spacing(2.5)
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
});

export default styles;
