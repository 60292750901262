import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Redirect, Route, Switch } from 'react-router-dom';

import Files from '@pm/ProjectDetails/Files';
import ProjectSettings from '@pm/ProjectDetails/ProjectSettings';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import Adjustment from './Adjustments/Adjustment/Adjustment';
import Adjustments from './Adjustments/Adjustments';
import { Authenticator } from './Authenticator';
import { NoAccessPage } from './Authenticator/NoAccess';
import { WrongDomainPage } from './Authenticator/WrongDomain';
import AssetDetail from './Customer/Assets';
import CustomerDetail from './Customer/CustomerDetail';
import CustomerList from './Customer/CustomerList';
import PropertyDetail from './Customer/PropertyDetail';
import PropertyList from './Customer/PropertyList';
import Dispatch from './Dispatch';
import FollowUp from './FollowUp';
import Home from './Home';
import Inventory from './Inventory';
import TruckDetail from './Inventory/TruckPage';
import InvoiceDetail from './Invoices/InvoiceDetail/InvoiceDetail';
import InvoiceList from './Invoices/InvoiceList';
import JobCloseoutView from './JobCloseout/DetailView';
import ViewJob from './Jobs/DetailView';
import ViewReviewReport from './Jobs/JobDetail/ReviewReportRoute';
import ViewTechReport from './Jobs/JobDetail/TechnicianReportRoute';
import JobsView from './Jobs/JobList';
import NewJob from './Jobs/NewJob';
import ReviewReportView from './Jobs/ReviewReportList';
import TechReportView from './Jobs/TechReportList';
import AssetView from './ListViews/AssetView';
import FormView from './ListViews/FormView';
import MaintenanceDetailView from './Maintenance/DetailView';
import MaintenanceView from './Maintenance/MaintenanceList';
import MetabaseAppEmbed from './MetabaseAppEmbed';
import PaymentsView from './Payments';
import PaymentDetail from './Payments/PaymentDetail';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import PurchaseOrdersList from './Procurement/PurchaseOrders';
import PurchaseOrdersDetail from './Procurement/PurchaseOrders/PurchaseOrdersDetail';
import ReceiptsBillsList from './Procurement/ReceiptsBills';
import BillsDetail from './Procurement/ReceiptsBills/BillsDetail';
import ReceiptsDetail from './Procurement/ReceiptsBills/ReceiptsDetail';
import ChangeOrdersList from './ProjectManagement/ProjectDetails/ChangeOrdersList';
import FieldReport from './ProjectManagement/ProjectDetails/FieldReport';
import DailyReportDetailView from './ProjectManagement/ProjectDetails/FieldReport/DailyReportList/DailyReportDetailView';
import Finance from './ProjectManagement/ProjectDetails/Finance';
import PayApplicationList from './ProjectManagement/ProjectDetails/Finance/PayApplicationsList';
import ProjectShow from './ProjectManagement/ProjectDetails/ProjectShow';
import PurchasingList from './ProjectManagement/ProjectDetails/PurchasingList';
import RFIList from './ProjectManagement/ProjectDetails/RFIList';
import SubmittalsList from './ProjectManagement/ProjectDetails/SubmittalsList';
import ProjectLayout from './ProjectManagement/ProjectLayout';
import ProjectsView from './ProjectManagement/ProjectsView';
import WIPDetail from './ProjectManagement/WIP_Reports/WIPDetail';
import WIPList from './ProjectManagement/WIP_Reports/WIPList';
import PublicPage from './PublicPage';
import Quotes from './Quotes';
import QuoteList from './Quotes/QuoteList';
import RecurringMaintenanceBillingView from './RecurringMaintenanceBilling';
import ServiceAgreements from './ServiceAgreements';
import ServiceAgreementDetailView from './ServiceAgreements/DetailView';
import ServiceChannelList from './ServiceChannel';
import Settings from './Settings';
import Accounting from './Settings/Accounting';
import AuditLogs from './Settings/AuditLogs';
import Company from './Settings/Company';
import CustomFields from './Settings/CustomFields';
import DispatchSettings from './Settings/Dispatch';
import Forms from './Settings/Forms';
import FormBuilder from './Settings/Forms/FormBuilder';
import Integrations from './Settings/Integrations';
import { ServiceChannelRedirect } from './Settings/Integrations/components/IntegrationsList/ServiceChannel';
import Payroll from './Settings/Payroll';
import People from './Settings/People';
import CrewDetail from './Settings/People/CrewDetail';
import PricebookDetail from './Settings/Pricebooks/PricebookDetail';
import Pricebooks from './Settings/Pricebooks/PricebookList';
import Products from './Settings/Products';
import ProductBundleDetail from './Settings/Products/Bundle/BundleDetail';
import Project from './Settings/Project';
import Quote from './Settings/Quote';
import ServiceAgreement from './Settings/ServiceAgreement';
import Vendors from './Settings/Vendors';
import Workflow from './Settings/Workflows';
import styles from './styles';
import Tenant from './SystemAdmin/Tenant';
import TenantList from './SystemAdmin/TenantList';
import TimesheetsDecider from './Timesheets/TimesheetsDecider';
import VisitList from './Visits/VisitList';

// Alpha sort by route definition please :D
const routeDefinitions = {
  root: '/',
  adjustment: '/adjustment/:mode/:id',
  adjustments: '/adjustment/list',
  asset: '/asset/:mode/:id',
  customer: '/customer/:mode',
  customerId: '/customer/:mode/:id',
  customerList: '/customer/list',
  customerProperty: '/customers/:customerMode/:customerid/property/:mode/:id',
  dispatch: '/dispatch',
  followUp: '/followUp',
  forgotPassword: '/forgotpassword',
  home: '/home',
  inventoryList: '/inventory/list',
  invoice: '/invoice/:mode/:id',
  invoiceList: '/invoice/list',
  invoiceTest: '/invoiceTest',
  jobCloseout: '/jobreport/:jobid/:jobCloseoutType?',
  jobCloseoutVisit: '/jobreport/:jobid/visit/:id/:jobCloseoutType?',
  job: '/job/:mode/:id',
  jobList: '/job/list',
  jobList2: '/job/list2',
  jobNew: '/job/new',
  jobs: '/jobs/:mode/:id',
  jobsNew: '/jobs/newjob',
  visitList: '/visit/list',
  listAsset: '/list/asset',
  listAttachment: '/list/attachment',
  listForm: '/list/form',
  maintenance: '/maintenance/:mode/:id?',
  maintenanceList: '/maintenance/list',
  payment: '/payments/:mode/:id',
  paymentsList: '/payments/list',
  procurementPurchaseOrders: '/procurement/purchaseorders/',
  procurementPurchaseOrdersDetail: '/procurement/purchaseorders/:mode/:poId',
  procurementReceiptsBills: '/procurement/receipts-bills/',
  procurementBillsDetail: '/procurement/receipts-bills/bill/:mode/:billId',
  procurementReceiptsDetail: '/procurement/receipts-bills/receipt/:mode/:receiptId',
  payApplicationList: '/project/:mode/:projectId/payapplication/:id',
  projectDetails: '/project/:mode/:projectId/:page',
  projectDetailsId: '/project/:mode/:projectId/:page/:id',
  projectDetailsSubpage: '/project/:mode/:projectId/:page/:subPage/:id?',
  projectDailyReportShow: '/project/:mode/:projectId/daily-reports/:id',
  projectDailyReportDetailView: '/project/:mode/:projectId/fieldreport/dailyreport/:id',
  property: '/property/:mode/:id',
  propertyList: '/property/list',
  public: '/public',
  quote: '/quote/:id',
  quoteList: '/quote/list',
  quotesNew: '/quotes/new',
  recurringBilling: '/recurringMaintenanceBilling/:mode/:id?',
  recurringBillingList: '/recurringMaintenanceBilling/list',
  register: '/register',
  reports: '/reports',
  reviewReportList: '/reviewreport/list',
  reviewReport: '/reviewreport/view/:id',
  serviceAgreement: '/serviceAgreement/:mode/:id?',
  serviceAgreementList: '/serviceAgreement/list',
  serviceChannel: '/serviceChannel/list',
  settings: '/settings',
  settingsAccounting: '/settings/accounting',
  settingsCompany: '/settings/company',
  settingsCrew: '/settings/crew/:mode/:id',
  settingsCustomFields: '/settings/customFields',
  settingsForms: '/settings/forms',
  settingsFormsMode: '/settings/forms/:mode',
  settingsForm: '/settings/forms/:mode/:id',
  settingsInventory: '/settings/inventory',
  settingsLogs: '/settings/logs',
  settingsPayroll: '/settings/payroll',
  settingsIntegrations: '/settings/integrations',
  settingsPeople: '/settings/people',
  settingsPricebooks: '/settings/pricebooks',
  settingsPricebook: '/settings/pricebooks/:mode/:id',
  settingsProductbundle: '/settings/productbundle/:id',
  settingsProject: '/settings/project',
  settingsQuotes: '/settings/quote',
  settingsServiceAgreement: '/settings/serviceAgreement',
  settingsVendors: '/settings/vendors',
  settingsWorkflows: '/settings/workflows',
  settingsDispatch: '/settings/dispatch',
  serviceChannelRedirect: '/serviceChannel',
  signIn: '/signin',
  noAccess: '/noaccess',
  wrongDomain: '/wrongdomain',
  techReportList: '/technicianreport/list',
  techReport: '/technicianreport/view/:id',
  timesheets: '/timesheets',
  timesheetsVisitTech: '/timesheets/:employeeId/:dateUnix?',
  truck: '/truck/:id',
  projectWIPList: '/wipReports/list',
  projectWIPDetail: '/wipReports/view/:id'
};

const createRoutingFunction = (routeDefinition = '') => params => {
  if (!params) return routeDefinition;

  return Object.entries(params)
    .reduce((route, [key, val]) => {
      return route.replace(`:${key}`, val);
    }, routeDefinition)
    .replace(/(\/:.*?\?)/g, '')
    .replace(/\?/g, '');
};

const Routes =
  Object.entries(routeDefinitions).reduce(
    (routes, [key, val]) => ({
      ...routes,
      [key]: createRoutingFunction(val)
    }),
    {}
  ) || routeDefinitions;

export default Routes;

const routerConfig = props => {
  const { auth, user, flags } = props;
  return (
    <Switch>
      <Route
        exact
        path={routeDefinitions.signIn}
        component={() => <Authenticator type="signin" auth={auth} {...props} />}
      />
      <Route
        exact
        path={routeDefinitions.forgotPassword}
        component={() => <Authenticator type="forgotPassword" auth={auth} {...props} />}
      />
      <Route
        exact
        path={routeDefinitions.register}
        component={() => <Authenticator type="register" auth={auth} {...props} />}
      />
      <Route exact path={routeDefinitions.noAccess} auth={auth} component={NoAccessPage} />
      <Route exact path={routeDefinitions.wrongDomain} auth={auth} component={WrongDomainPage} />
      <Route mounted="true" path={routeDefinitions.public} component={PublicPage} />
      <PrivateRoute
        exact
        mounted="true"
        path={routeDefinitions.root}
        auth={auth}
        component={Home}
      />
      <PrivateRoute
        exact
        mounted="true"
        path={routeDefinitions.home}
        auth={auth}
        component={Home}
      />
      <PrivateRoute mounted="true" path="/admin/*" auth={auth} systemAdmin>
        <PrivateRoute
          mounted="true"
          exact
          path="/admin/tenant"
          auth={auth}
          component={TenantList}
        />
        <PrivateRoute
          mounted="true"
          exact
          path="/admin/tenant/:id"
          auth={auth}
          component={Tenant}
        />
      </PrivateRoute>
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.customerList}
        auth={auth}
        component={CustomerList}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.customer}
        auth={auth}
        component={CustomerDetail}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.customerId}
        auth={auth}
        component={CustomerDetail}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.propertyList}
        auth={auth}
        component={PropertyList}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.property}
        auth={auth}
        component={PropertyDetail}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.jobList}
        auth={auth}
        component={JobsView}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.jobsNew}
        auth={auth}
        component={NewJob}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.jobNew}
        auth={auth}
        component={NewJob}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.jobs}
        auth={auth}
        component={ViewJob}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.jobCloseout}
        auth={auth}
        component={JobCloseoutView}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.jobCloseoutVisit}
        auth={auth}
        component={JobCloseoutView}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.job}
        auth={auth}
        component={ViewJob}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.invoice}
        auth={auth}
        component={InvoiceDetail}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.serviceAgreementList}
        auth={auth}
        component={ServiceAgreements}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.serviceChannel}
        auth={auth}
        component={ServiceChannelList}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.recurringBillingList}
        auth={auth}
        component={RecurringMaintenanceBillingView}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.serviceAgreement}
        auth={auth}
        component={ServiceAgreementDetailView}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.recurringBilling}
        auth={auth}
        component={ServiceAgreementDetailView}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.followUp}
        auth={auth}
        component={FollowUp}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.reports}
        auth={auth}
        component={MetabaseAppEmbed}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.maintenanceList}
        auth={auth}
        component={MaintenanceView}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.maintenance}
        auth={auth}
        component={MaintenanceDetailView}
      />
      <PrivateRoute mounted="true" exact path={routeDefinitions.dispatch} auth={auth} user={user}>
        <Dispatch />
      </PrivateRoute>
      <PrivateRoute mounted="true" exact path={routeDefinitions.visitList} auth={auth} user={user}>
        <VisitList />
      </PrivateRoute>
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settings}
        auth={auth}
        component={Settings}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsCompany}
        auth={auth}
        component={Company}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsPeople}
        auth={auth}
        component={People}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsCustomFields}
        auth={auth}
        component={CustomFields}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsForms}
        auth={auth}
        component={Forms}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsCrew}
        component={CrewDetail}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsFormsMode}
        auth={auth}
        component={FormBuilder}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsForm}
        auth={auth}
        component={FormBuilder}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsAccounting}
        auth={auth}
        component={Accounting}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsPricebooks}
        auth={auth}
        component={Pricebooks}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsPricebook}
        auth={auth}
        component={PricebookDetail}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsInventory}
        auth={auth}
        component={Products}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsProductbundle}
        auth={auth}
        component={ProductBundleDetail}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsPayroll}
        auth={auth}
        component={Payroll}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsIntegrations}
        auth={auth}
        component={Integrations}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.serviceChannelRedirect}
        auth={auth}
        component={ServiceChannelRedirect}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsServiceAgreement}
        auth={auth}
        component={ServiceAgreement}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsQuotes}
        auth={auth}
        component={Quote}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsWorkflows}
        auth={auth}
        component={Workflow}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsDispatch}
        auth={auth}
        component={DispatchSettings}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsProject}
        auth={auth}
        component={Project}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.customerProperty}
        auth={auth}
        component={PropertyDetail}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.invoiceTest}
        auth={auth}
        component={InvoiceDetail}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.timesheets}
        auth={auth}
        component={TimesheetsDecider}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.timesheetsVisitTech}
        auth={auth}
        component={TimesheetsDecider}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsVendors}
        auth={auth}
        component={Vendors}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.reviewReport}
        auth={auth}
        component={ViewReviewReport}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.techReport}
        auth={auth}
        component={ViewTechReport}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.settingsLogs}
        auth={auth}
        component={AuditLogs}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.quoteList}
        auth={auth}
        component={QuoteList}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.listForm}
        auth={auth}
        component={FormView}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.techReportList}
        auth={auth}
        component={TechReportView}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.reviewReportList}
        auth={auth}
        component={ReviewReportView}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.invoiceList}
        auth={auth}
        component={InvoiceList}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.inventoryList}
        auth={auth}
        component={Inventory}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.truck}
        auth={auth}
        component={TruckDetail}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.paymentsList}
        auth={auth}
        component={PaymentsView}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.payment}
        auth={auth}
        component={PaymentDetail}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.adjustments}
        auth={auth}
        component={Adjustments}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.adjustment}
        auth={auth}
        component={Adjustment}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.listAsset}
        auth={auth}
        component={AssetView}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.quotesNew}
        auth={auth}
        component={flags[FeatureFlags.QUOTESV2] && Quotes}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.quote}
        auth={auth}
        component={flags[FeatureFlags.QUOTESV2] && Quotes}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.asset}
        auth={auth}
        component={AssetDetail}
      />
      {/* project management */}
      <PrivateRoute mounted="true" exact path="/project" auth={auth} component={ProjectsView} />
      <PrivateRoute
        mounted="true"
        path="/project/:mode/:projectId/:page/:subpage?/:id?"
        auth={auth}
      >
        <ProjectLayout>
          <Switch>
            <PrivateRoute
              mounted="true"
              exact
              path="/project/:mode/:projectId/dashboard"
              auth={auth}
              component={ProjectShow}
            />
            <PrivateRoute
              mounted="true"
              exact
              path="/project/:mode/:projectId/fieldreport"
              auth={auth}
              component={FieldReport}
            />
            <PrivateRoute
              mounted="true"
              exact
              path="/project/:mode/:projectId/fieldreport/dailyreport/:id"
              auth={auth}
              component={DailyReportDetailView}
            />
            <PrivateRoute
              mounted="true"
              exact
              path="/project/:mode/:projectId/rfi/:id?"
              auth={auth}
              component={RFIList}
            />
            <PrivateRoute
              mounted="true"
              exact
              path="/project/:mode/:projectId/changeorder/:id?"
              auth={auth}
              component={ChangeOrdersList}
            />
            <PrivateRoute
              mounted="true"
              exact
              path="/project/:mode/:projectId/submittal/:id?"
              auth={auth}
              component={SubmittalsList}
            />
            <PrivateRoute
              mounted="true"
              exact
              path="/project/:mode/:projectId/purchasing"
              auth={auth}
              component={PurchasingList}
            />
            <PrivateRoute
              mounted="true"
              exact
              path="/project/:mode/:projectId/finance"
              auth={auth}
              component={Finance}
            />
            <PrivateRoute
              mounted="true"
              exact
              path={routeDefinitions.payApplicationList}
              auth={auth}
              component={PayApplicationList}
            />
            <PrivateRoute
              mounted="true"
              exact
              path="/project/:mode/:projectId/projectsettings"
              auth={auth}
              component={ProjectSettings}
            />
            <PrivateRoute
              mounted="true"
              exact
              path="/project/:mode/:projectId/files"
              auth={auth}
              component={Files}
            />
          </Switch>
        </ProjectLayout>
      </PrivateRoute>
      <Redirect exact from="/project/:mode/:projectId" to="/project/:mode/:projectId/dashboard" />
      {/* Procurement */}
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.procurementPurchaseOrders}
        auth={auth}
        component={PurchaseOrdersList}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.procurementPurchaseOrdersDetail}
        auth={auth}
        component={PurchaseOrdersDetail}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.procurementReceiptsBills}
        auth={auth}
        component={ReceiptsBillsList}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.procurementReceiptsDetail}
        auth={auth}
        component={ReceiptsDetail}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.procurementBillsDetail}
        auth={auth}
        component={BillsDetail}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.projectWIPList}
        auth={auth}
        component={WIPList}
      />
      <PrivateRoute
        mounted="true"
        exact
        path={routeDefinitions.projectWIPDetail}
        auth={auth}
        component={WIPDetail}
      />
    </Switch>
  );
};

export const RouterConfig = withStyles(styles, { withTheme: true })(routerConfig);
