import { EnumType } from 'utils/constants';

const poOrderListTableMeta = isVistaEnabled => [
  {
    id: 'lineNumber',
    label: 'Line #',
    type: 'number',
    numeric: true,
    align: 'left'
  },
  {
    id: 'itemName',
    label: 'Item Name',
    type: 'string',
    align: 'left'
  },
  {
    id: 'departmentName',
    label: 'Department',
    type: 'string',
    align: 'left'
  },
  {
    id: 'jobOrProjectText',
    label: 'Job / Project',
    showLink: 'view',
    linkPath: '',
    linkReference: 'jobOrProjectLink',
    align: 'left'
  },
  {
    id: 'description',
    label: 'Description',
    type: 'string',
    align: 'left'
  },
  {
    id: 'unitOfMeasure',
    label: 'UOM',
    type: 'string',
    align: 'left',
    headerStyle: {
      maxWidth: 70
    }
  },
  {
    id: 'taxable',
    label: 'Taxable',
    type: 'boolean',
    align: 'left',
    headerStyle: {
      maxWidth: 90
    }
  },
  {
    id: 'unitCost',
    label: 'Unit Cost',
    type: 'CustomCurrency',
    isCustom: true,
    numeric: true,
    align: 'left'
  },
  {
    id: 'quantity',
    label: 'Qty Ordered',
    type: 'number',
    numeric: true,
    align: 'left',
    headerStyle: {
      maxWidth: 125
    }
  },
  {
    id: 'quantityFulfilled',
    label: 'Qty Received',
    type: 'number',
    numeric: true,
    align: 'left',
    headerStyle: {
      maxWidth: 130
    }
  },
  {
    id: 'totalCost',
    label: 'Total',
    type: 'currency',
    align: 'left',
    numeric: true
  },
  {
    id: 'status',
    label: 'Status',
    enumType: EnumType.PROCUMENT_PURCHASE_ORDER_STATUS,
    type: 'enum',
    filterType: 'string',
    filterKey: 'status'
  },
  ...(isVistaEnabled
    ? [
        {
          id: 'jcPhaseName',
          label: 'Job Phase',
          type: 'string',
          align: 'left'
        },
        {
          id: 'jcCostTypeName',
          label: 'Cost Type',
          type: 'string',
          align: 'left'
        }
      ]
    : [])
];

export default poOrderListTableMeta;
