import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    border: `1px solid ${theme.palette.grayscale(80)}`
  },
  titleChip: {
    background: theme.palette.grayscale(20),
    color: theme.palette.grayscale(100),
    textTransform: 'uppercase',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  expandIcon: {
    cursor: 'pointer',
    marginTop: theme.spacing(0.2),
    fill: theme.palette.grayscale(20)
  },
  vertIconButton: {
    padding: 0,
    paddingLeft: 4,
    cursor: 'pointer'
  },
  scheduleIcon: {
    fill: theme.palette.grayscale(100)
  },
  overAllCount: {
    marginBottom: theme.spacing(1),
    color: theme.palette.grayscale(60)
  },
  tags: {
    background: theme.palette.brand.logoGreen,
    color: theme.palette.grayscale(20),
    textTransform: 'capitalize',
    padding: theme.spacing(0.3),
    paddingLeft: theme.spacing(0.75),
    paddingRight: theme.spacing(0.75),
    borderRadius: 4,
    marginRight: theme.spacing(0.75)
  }
}));
