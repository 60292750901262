import gql from 'graphql-tag';

const getAllAttachmentByCompany = gql`
  query getCompany(
    $partitionKey: String!
    $sortKey: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      attachment: attachmentsView(
        entityConnection: "Attachment"
        pagination: { limit: $limit, offset: $offset }
        filter: $filter
        sorting: $sort
      ) {
        nextToken
        items {
          id
          customFileName
          fileName
          createdDate
          addedBy
          createdBy
          description
          comment
          job: parentEntity {
            ... on Job {
              id
              sortKey
              jobNumber
              customerId
              customerName
              customerSortKey
              customerPropertyId
              customerPropertyName
              customerPropertySortKey
            }
          }
        }
      }
    }
  }
`;

export default getAllAttachmentByCompany;
