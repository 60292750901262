import gql from 'graphql-tag';

const deletePaymentInvoice = gql`
  mutation softDeletePaymentInvoice($partitionKey: String!, $id: String!) {
    deletePaymentInvoice(partitionKey: $partitionKey, id: $id) {
      id
      version
      deletedDate
    }
  }
`;

export default deletePaymentInvoice;
