import React from 'react';

import { calculateMarginFromMarkup } from '@buildhero/math';
import { TV, Typography } from '@buildhero/sergeant';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

import { bundleIndex } from 'constants/algoliaIndex';

import { roundCurrency, roundFloat } from 'utils';
import { convertForMathLib } from 'utils/mathLibrary';

import { useLazyFetchPricebookEntry } from '../../Tasks/components/useLazyFetchPricebookEntry';
import AlgoliaSearch from '../AlgoliaSearch';

export default function PriceBookEntrySearch({
  field,
  form,
  props: { priceBookId, costCodeOptions, costTypeOptions, revenueTypeOptions, ...givenProps }
}) {
  const { palette } = useTheme();
  const fetchPricebookEntry = useLazyFetchPricebookEntry();

  const calculateMarkupValue = value => {
    return roundFloat(value);
  };

  const calculateMarginValue = value => {
    return convertForMathLib(calculateMarginFromMarkup, value);
  };

  const props = {
    indexName: bundleIndex,
    formatHitLabel: hit => hit.name,
    filters: `entityType:Product`,
    onChange: async value => {
      const pEntry = await fetchPricebookEntry({
        pricebookId: priceBookId,
        productSortKey: value.sortKey
      });

      const costCode = pEntry.costCodeId
        ? costCodeOptions.find(o => o.value === pEntry.costCodeId)
        : null;
      const jobCostType = pEntry.jobCostTypeId
        ? costTypeOptions.find(o => o.value === pEntry.jobCostTypeId)
        : null;
      const revenueType = pEntry.revenueTypeId
        ? revenueTypeOptions.find(o => o.value === pEntry.revenueTypeId)
        : null;
      form.setValues(
        prev => ({
          ...prev,
          name: value.name,
          productId: value.id,
          productSortKey: value.sortKey,
          description: value.description || '',
          // rounding here as short term fix/safe guard until price book has new requirements for rounding
          unitPrice: roundCurrency(pEntry.unitPrice),
          unitCost: roundFloat(pEntry.unitCost ?? value.unitCost),
          accountingRefIdOfEntity: value.productAccountingRefId,
          markupValue:
            calculateMarkupValue(pEntry.markupValue) ??
            calculateMarkupValue(value.markupValue) ??
            null,
          marginValue:
            calculateMarginValue(pEntry.markupValue) ??
            calculateMarginValue(value.markupValue) ??
            null,
          markupType: pEntry.markupType,
          unitOfMeasure: value.unitOfMeasure || null,
          quantity: 1,
          amount: roundCurrency(pEntry.unitPrice),
          taxable: !!value.taxable,
          costCode,
          jobCostType,
          revenueType,
          product: {
            id: value.id,
            code: value.code,
            unitOfMeasure: {
              name: value.unitOfMeasure
            }
          },
          priceBookEntry: {
            id: pEntry.id
          }
        }),
        true
      );
      // act like blur event -> can't use actul blur b/c mousedown on popper
      // will run the blur event before the selection
      form.setFieldTouched(field.name, true, false);
    },
    onClickAway: () => form.setFieldTouched(field.name, true),
    handleRenderOption: ({ label, value: { code, description } }) => (
      <div>
        <Typography variant={TV.BASE}>{label}</Typography>
        <Typography
          style={{ display: 'flex', flexDirection: 'row' }}
          variant={TV.S2}
          color={palette.text.tertiary}
        >
          <Typography variant={TV.S2} color={palette.text.primary}>
            {code}
          </Typography>
          {code && description && ' - '}
          {description}
        </Typography>
      </div>
    ),
    ...givenProps,
    name: field.name,
    value: field.value
  };

  return <AlgoliaSearch {...props} />;
}

PriceBookEntrySearch.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  // any input props
  props: PropTypes.shape({
    priceBookId: PropTypes.string.isRequired,
    costCodeOptions: PropTypes.array.isRequired,
    costTypeOptions: PropTypes.array.isRequired,
    revenueTypeOptions: PropTypes.array.isRequired
  }).isRequired
};
