const styles = theme => ({
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    // backgroundColor: "#f2f2f2",
    color: '#3f3f3f'
  }
});

export default styles;
