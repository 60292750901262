export default {
  container: {
    padding: 16,
    width: 219,
    height: 209,
    border: '1px solid #CCCCCC',
    boxSizing: 'border-box',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    container: { display: 'flex', paddingTop: 3, paddingBottom: 3, marginBottom: 16 },
    image: { width: 48, height: 42, marginRight: 8 },
    titleContainer: { display: 'flex', flexDirection: 'column', justifyContent: 'center' }
  },
  description: {
    height: 42
  },
  footer: {
    container: { marginTop: 'auto' }
  }
};
