import React, { useMemo } from 'react';
import moment from 'moment';
import useAutosaveManager from 'customHooks/useAutosaveManager';
import { Box, Grid } from '@material-ui/core';
import { jsx } from '@emotion/react';

import { SgtForm, Typography, TV, TW, ThemeProvider } from '@buildhero/sergeant';
import { ConfirmLeave } from 'components';
import CloudIcon from 'components/CloudIcon';

import { NetworkStatuses } from 'utils/constants';

import { useServiceAgreementUpdate } from './gql';

import configuration from './configuration';
import validation from './validation';
import { RenewalHistory } from './RenewalHistory';

const titleStyle = {
  marginRight: 16
};

const RenewalsTab = ({ agreementInfo, showSnackbar, onTabUpdate }) => {
  const [updateServiceAgreement, updating] = useServiceAgreementUpdate(agreementInfo?.id);

  const { autosave, status, setHasTempData, confirmLeave } = useAutosaveManager({
    update: updateServiceAgreement,
    initialVersion: agreementInfo.version,
    entityName: 'service agreement',
    destructureUpdateResult: result => result?.data?.updateServiceAgreement,
    snackbarOn: showSnackbar,
    versionIncrementedOnBackend: true
  });

  const blockInteractions = useMemo(() => status === NetworkStatuses.RETRYING, [status]);

  const minDate = Math.max(
    agreementInfo.startDate,
    moment()
      .startOf('day')
      .unix()
  );

  const schema = validation(minDate);
  return (
    <>
      <ThemeProvider>
        <ConfirmLeave when={confirmLeave} />
        <Grid container>
          <Typography variant={TV.L} weight={TW.BOLD} css={titleStyle}>
            Next Renewal
          </Typography>

          <CloudIcon status={status} />
        </Grid>

        <Box
          display="flex"
          marginTop={3}
          style={blockInteractions ? { cursor: 'progress' } : undefined}
        >
          <Box
            style={blockInteractions ? { pointerEvents: 'none' } : undefined}
            minWidth={816}
            marginRight={4}
          >
            <SgtForm
              layout="default"
              configuration={configuration}
              initialValues={{
                ...agreementInfo
              }}
              validationSchema={schema}
              onSaveOnChange={async (changes, { values }) => {
                const result = await autosave({ changes, values });
                onTabUpdate(result?.data?.updateServiceAgreement || {});
              }}
              onFieldChange={() => setHasTempData(true)}
            />
          </Box>
        </Box>
      </ThemeProvider>
      <RenewalHistory agreementInfo={agreementInfo} showSnackbar={showSnackbar} />
    </>
  );
};

export default RenewalsTab;
