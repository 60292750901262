import React from 'react';
import { bool, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';
import AsyncTypography from 'components/AsyncTypography';
import Routes from 'scenes/Routes';
import { useStyles } from './ProjectDetailsHeader.styles';

const ProjectDetailsHeader = ({ project, loading }) => {
  const styles = useStyles();
  const route = Routes.projectDetails;
  return (
    <AsyncTypography loading={loading} width={150} variant="body1">
      <Link
        css={styles.link}
        to={route({ mode: 'view', projectId: project?.id, page: 'dashboard' })}
      >
        Project #{project?.number} – {project?.name}
      </Link>
    </AsyncTypography>
  );
};

ProjectDetailsHeader.propTypes = {
  project: shape({
    name: string,
    number: string,
    id: string
  }).isRequired,
  loading: bool.isRequired
};

export default ProjectDetailsHeader;
