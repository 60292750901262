export const useStyles = () => ({
  container: theme => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'end',
    padding: theme.spacing(0.7),
    '& > *': {
      fontWeight: 'bold'
    }
  })
});
