import gql from 'graphql-tag';

const getAllAssetByCompany = gql`
  query getCompany(
    $partitionKey: String!
    $sortKey: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      asset: assetsView(
        entityConnection: "PropertyAsset"
        pagination: { limit: $limit, offset: $offset }
        filter: $filter
        sorting: $sort
      ) {
        nextToken
        items {
          id
          assetName
          make
          modelNumber
          serialNo
          installDate

          property: parentEntity {
            ... on CustomerProperty {
              id
              sortKey
              companyName
              customer: parentEntity {
                ... on Customer {
                  id
                  customerName
                  sortKey
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getAllAssetByCompany;
