import AmplifyService from 'services/AmplifyService';
import CommonService from '../Common/CommonService';
import getTechnicianReportById from '../../graphql/technicianReport/queries/getTechnicianReportById';

export default class TechnicianReportService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
    this.CommonService = new CommonService();
  }

  getTechnicianReportById = async id => {
    const params = {
      id
    };

    const response = await this.api.query(getTechnicianReportById, params);
    return response;
  };
}
