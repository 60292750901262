import InstructionHelper from '../InstructionHelper';
import { removeNestedJson } from '../../utils';
import AppConstants from '../../utils/AppConstants';

const deactivateCustomerRepSchema = record => {
  const localRecord = removeNestedJson(record);
  if (localRecord.__typename) {
    delete localRecord.__typename;
  }
  if (localRecord.rowId) {
    delete localRecord.rowId;
  }
  const data = [{ ...localRecord, status: AppConstants.INACTIVE, isActive: false }];
  // removing row id
  const ins = new InstructionHelper();
  const { instructionSet } = ins.flat(0);
  return { data: data, instructionSet: instructionSet };
};

export default deactivateCustomerRepSchema;
