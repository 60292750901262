import gql from 'graphql-tag';

const getTaskById = gql`
  query getTaskById($id: String!) {
    getTaskById(id: $id) {
      id
      sortKey
      products: taskEntriesView(entityConnection: "TaskEntry") {
        nextToken
        items {
          id
          sortKey
          version
          isActive
          entityType
          productId
          name
          description
          markupType
          markupValue
          unitPrice
          unitCost
          quantity
          taxable
        }
      }
    }
  }
`;

export default getTaskById;
