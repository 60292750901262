import React, { useState } from 'react';
import { jsx } from '@emotion/react';
import { Box } from '@material-ui/core';
import { Modal, Typography, theme } from '@buildhero/sergeant';

import ReportsIcon from 'assets/Icons/Reports';
import WrapTable from 'components/WrapTable';

import FormModalLink, { formatFormItem } from '../../FormModalLink/FormModalLink.component';

import FormTable from '../../FormTable';

import { PARTS_LIST_COLUMNS } from './TasksTable.constants';
import { useStyles } from './TasksTable.styles';

export const PartLinkList = ({ formattedValue }) => {
  const [partsModalOpen, setPartsModalOpen] = useState(false);
  const parts = formattedValue?.items || [];
  const styles = useStyles();

  if (parts.length) {
    return (
      <>
        <Typography css={styles.tableLink} underlined onClick={() => setPartsModalOpen(true)}>
          {parts.length} Part{parts.length > 1 && 's'}
        </Typography>
        <Modal
          open={partsModalOpen}
          onClose={() => setPartsModalOpen(false)}
          title="Parts Summary"
          fullWidth
        >
          <WrapTable placeholderVariant="text" columns={PARTS_LIST_COLUMNS} rows={parts} scrollY />
        </Modal>
      </>
    );
  }

  return <Typography>-</Typography>;
};

export const FormLinkList = ({ value }) => {
  const [formsSummaryModalOpen, setFormsSummaryModalOpen] = useState(false);
  const forms = value?.items || [];
  const styles = useStyles();

  if (!forms.length) return <Typography>-</Typography>;

  if (forms.length === 1) return <FormModalLink formData={formatFormItem(forms[0])} />;

  return (
    <>
      <Typography css={styles.tableLink} underlined onClick={() => setFormsSummaryModalOpen(true)}>
        {forms.length} Forms
      </Typography>
      <Modal
        open={formsSummaryModalOpen}
        onClose={() => setFormsSummaryModalOpen(false)}
        title="Forms Summary"
        fullWidth
        maxWidth="lg"
      >
        <FormTable forms={forms} tableOnly />
      </Modal>
    </>
  );
};

export const VisitDisplay = ({ value }) => (
  <Box display="flex" flexDirection="row" alignItems="center">
    <ReportsIcon css={{ width: 13, marginRight: 2, color: theme.palette.support.yellow.dark }} />
    {value}
  </Box>
);
