import { partition } from 'lodash';
import { useMemo } from 'react';

import { GroupingColumnMode } from './WrapTable.constants';
import { groupTableData } from './WrapTable.utils';

export const useNormalizedRows = ({ baseRows, rowGroupingModel, columns }) => {
  return useMemo(() => {
    const rows = baseRows.map((row, i) => ({ _rowIndex: i, ...row }));
    return rowGroupingModel?.length ? groupTableData({ rows, rowGroupingModel, columns }) : rows;
  }, [baseRows, rowGroupingModel, columns]);
};

export const useSortedColumns = ({ columns, rowGroupingModel }) => {
  return useMemo(() => {
    if (!rowGroupingModel?.length) return columns;
    return [...columns].sort((a, b) => {
      const ai = rowGroupingModel.findIndex(field => field === a.field);
      const bi = rowGroupingModel.findIndex(field => field === b.field);
      if (ai === -1 && bi === -1) return 0;
      if (ai === -1) return 1;
      if (bi === -1) return -1;
      return ai < bi ? -1 : 1;
    });
  }, [columns, rowGroupingModel]);
};

export const useHeaderColumns = ({
  columns: baseColumns,
  rowGroupingModel,
  rowGroupingColumnMode
}) => {
  let columns = [...baseColumns];

  if (rowGroupingModel?.length && rowGroupingColumnMode === GroupingColumnMode.SINGLE) {
    const [groupColumns, otherColumns] = partition(columns, colDef => {
      return rowGroupingModel.includes(colDef.field);
    });

    const primaryGroup = groupColumns.find(colDef => colDef.field === rowGroupingModel[0]);

    columns = [primaryGroup, ...otherColumns];
  }

  return columns.filter(({ hide }) => !hide);
};
