import React from 'react';

import { func, string } from 'prop-types';
import { connect, useSelector } from 'react-redux';

import { snackbarOn } from 'redux/actions/globalActions';
import { useGetCompanyDispatchSettings } from 'scenes/Settings/Dispatch/DispatchSettings.hooks';

import { useWeekHeaders } from './WeekViewHeader.hooks';
import { useStyles } from './WeekViewHeader.styles';

const WeekViewHeader = ({ startDay, snackbar }) => {
  const user = useSelector(s => s.user);
  const { tenantId, tenantCompanyId } = user;
  const classes = useStyles();
  const { data: dispatchSettingData } = useGetCompanyDispatchSettings({
    tenantId,
    tenantCompanyId,
    snackbar
  });
  const headers = useWeekHeaders({
    startDay,
    customWeekStartDay:
      dispatchSettingData && JSON.parse(dispatchSettingData.settings).weekViewStartDate
  });
  return (
    <div className={classes.weekViewHeader}>
      {headers.map(day => (
        <div key={day} className={classes.dayHeader}>
          {day}
        </div>
      ))}
    </div>
  );
};

WeekViewHeader.propTypes = {
  // eslint-disable-next-line react/require-default-props
  startDay: string,
  snackbar: func.isRequired
};

const mapDispatcherToProps = { snackbar: snackbarOn };

const connectedWeekViewHeader = connect(null, mapDispatcherToProps)(WeekViewHeader);

export default connectedWeekViewHeader;
