import { PermissionConstants } from 'utils/AppConstants';

const packingSlipLayout = {
  entity: {
    name: 'partAndMaterial',
    tenant: 'default',
    layouts: {
      web: {
        sections: [
          {
            title: 'Add receipt',
            sm: '12',
            md: '12',
            lg: '12',
            rows: [
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbField',
                    value: 'customFileName',
                    label: 'packingSlipFileName',
                    name: 'customFileName',
                    showField: 'fileName',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'fileName',
                    label: 'upload',
                    name: 'fileName',
                    behavior: {
                      new: {
                        ui: 'Attachment',
                        sm: '12',
                        md: '8',
                        lg: '9'
                      },
                      edit: {
                        ui: 'Attachment',
                        sm: '12',
                        md: '8',
                        lg: '9'
                      }
                    }
                  },
                  {
                    type: 'dbHidden',
                    value: 'fileUrl',
                    label: 'id',
                    name: 'fileUrl',
                    behavior: {
                      edit: {
                        ui: 'Hidden',
                        sm: '1',
                        md: '1',
                        lg: '1'
                      }
                    }
                  },
                  {
                    type: 'dbHidden',
                    value: 'id',
                    label: 'id',
                    name: 'id',
                    behavior: {
                      edit: {
                        ui: 'Hidden',
                        sm: '1',
                        md: '1',
                        lg: '1'
                      }
                    }
                  }
                ]
              },
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbField',
                    value: 'vendorName',
                    label: 'vendor',
                    name: 'vendorName',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'receiptNumber',
                    label: 'receiptNo',
                    name: 'receiptNumber',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '3',
                        lg: '2'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '3',
                        lg: '2'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'poNumber',
                    label: 'poNo',
                    name: 'poNumber',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '3',
                        lg: '2'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '3',
                        lg: '2'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'dateOfPurchase',
                    label: 'dateOfPurchase',
                    name: 'dateOfPurchase',
                    specialBehaviour: { saveUnixTimeStamp: true },
                    behavior: {
                      new: {
                        ui: 'Date',
                        sm: '12',
                        md: '6',
                        lg: '3'
                      },
                      edit: {
                        ui: 'Date',
                        sm: '12',
                        md: '6',
                        lg: '3'
                      }
                    }
                  }
                ]
              },
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbField',
                    value: 'totalAmountPreTax',
                    label: 'totalAmountPreTax',
                    name: 'totalAmountPreTax',
                    behavior: {
                      new: {
                        ui: 'CurrencyInput',
                        sm: '12',
                        md: '3',
                        lg: '3'
                      },
                      edit: {
                        ui: 'CurrencyInput',
                        sm: '12',
                        md: '3',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'tax',
                    label: 'tax',
                    name: 'tax',
                    behavior: {
                      new: {
                        ui: 'PercentageInput',
                        sm: '12',
                        md: '3',
                        lg: '2'
                      },
                      edit: {
                        ui: 'PercentageInput',
                        sm: '12',
                        md: '3',
                        lg: '2'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'departmentName',
                    label: 'departmentName',
                    name: 'departmentName',
                    behavior: {
                      new: {
                        ui: 'ReadOnly',
                        sm: '12',
                        md: '6',
                        lg: '3'
                      },
                      edit: {
                        ui: 'ReadOnly',
                        sm: '12',
                        md: '6',
                        lg: '3'
                      }
                    }
                  }
                ]
              }
            ]
          }
        ],
        buttons: {
          save: {
            label: 'Save',
            color: 'primary',
            buttonType: 'contained',
            caslKey: [PermissionConstants.OBJECT_REVIEWREPORT],
            behavior: {
              new: true,
              edit: true
            }
          },
          cancel: {
            label: 'Cancel',
            color: 'secondary',
            buttonType: 'contained',
            caslKey: [PermissionConstants.OBJECT_REVIEWREPORT],
            behavior: {
              edit: true
            }
          }
        }
      }
    }
  }
};

export default packingSlipLayout;
