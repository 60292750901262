import React, { useState } from 'react';

import { bool, func } from 'prop-types';

import SergeantModal from 'components/SergeantModal';

import { useSnackbar } from 'customHooks/useSnackbar';

import { Logger } from 'services/Logger';

import { fetchFiles } from './AddFilesFromFileManagerModal.helpers';

import { addFilesLayout } from './AddFilesFromFileManagerModal.layout';
import FileManagerFormComponentWrapper from './components/FileManagerFormComponentWrapper';

const AddFilesFromFileManagerModal = ({ open, onClose, onPrimaryAction }) => {
  const snackbar = useSnackbar();
  const [selectedFiles, setSelectFiles] = useState([]);

  const onSelectFile = (file, selectedItems) => {
    const files = [...selectedFiles.filter(({ id }) => id !== file.id), file];
    setSelectFiles(files.filter(({ id }) => selectedItems?.includes(id)));
  };

  return (
    <SergeantModal
      open={open}
      title="Select Files"
      dataType="Select Files"
      customPrimaryButtonLabel="Attach Files"
      layout={addFilesLayout({ onSelectFile })}
      handlePrimaryAction={async (_, stopLoading) => {
        try {
          const files = await fetchFiles(selectedFiles);
          await onPrimaryAction(
            files.map(file => ({
              file,
              name: file.name,
              type: file.type,
              url: URL.createObjectURL(file),
              selected: true
            })),
            stopLoading
          );
        } catch (error) {
          snackbar('error', 'Can not upload selected files');
          Logger.error(error);
        } finally {
          setSelectFiles([]);
          stopLoading();
        }
      }}
      handleClose={() => {
        setSelectFiles([]);
        onClose();
      }}
      customComponents={{
        FilesManagerComponent: FileManagerFormComponentWrapper
      }}
      alignCloseRight
    />
  );
};

AddFilesFromFileManagerModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  onPrimaryAction: func.isRequired
};

export default AddFilesFromFileManagerModal;
