import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 'auto',
    textAlign: 'right'
  },
  labelContainer: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5)
  },
  label: {
    fontSize: 10,
    textTransform: 'uppercase'
  }
}));
