import { PermissionConstants } from 'utils/AppConstants';

const addSummary = {
  entity: {
    name: 'jobSummary',
    tenant: 'default',
    layouts: {
      web: {
        sections: [
          {
            title: 'Add summary note',
            sm: '12',
            md: '12',
            lg: '12',
            rows: [
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbField',
                    value: 'summary',
                    label: 'note',
                    name: 'summary',
                    behavior: {
                      new: {
                        ui: 'TextArea',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      },
                      edit: {
                        ui: 'TextArea',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      }
                    }
                  }
                ]
              }
            ]
          }
        ],
        buttons: {
          save: {
            label: 'Save',
            color: 'primary',
            buttonType: 'contained',
            caslKey: [PermissionConstants.OBJECT_REVIEWREPORT],
            behavior: {
              new: true,
              edit: true
            }
          },
          cancel: {
            label: 'Cancel',
            color: 'secondary',
            buttonType: 'contained',
            caslKey: [PermissionConstants.OBJECT_REVIEWREPORT],
            behavior: {
              edit: true
            }
          }
        }
      }
    }
  }
};

export default addSummary;
