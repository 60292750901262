/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import { PopperMenu } from 'components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Spinner from 'components/Spinners/CircularIndeterminate';

const SplitButton = ({
  testingid,
  variant = 'contained',
  color = 'secondary',
  onClick,
  disabled,
  style,
  label,
  buttonProps = {},
  spinnerProps = {},
  showSpinner,
  options
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant={variant}
        color={color}
        disabled={disabled}
        style={style}
        ref={anchorRef}
        aria-label={`${label} split button`}
      >
        <Button
          testingid={testingid ? `button-${testingid}` : `button`}
          onClick={onClick}
          style={{ borderColor: 'white' }}
          {...buttonProps}
        >
          {label}
          {showSpinner && <Spinner styles={{ margin: 0 }} size={12} {...spinnerProps} />}
        </Button>
        {options?.length > 0 && (
          <Button
            testingid={testingid ? `more-button-${testingid}` : `more-button`}
            color={color}
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label={`${label} split button options`}
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ExpandMoreIcon />
          </Button>
        )}
      </ButtonGroup>
      <PopperMenu
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        itemList={options}
      />
    </>
  );
};

export default SplitButton;
