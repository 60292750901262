const costCode = {
  fields: {
    name: { default: 'name' },
    description: { default: 'description' }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0,
            padding: [0, 6]
          },
          contents: [
            {
              component: 'BodyText',
              source:
                'Cost codes contain a numeric code number and a description. Example: 10001-General Labor'
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'name',
                  options: {
                    label: '',
                    placeholder: 'Name',
                    default_value: '',
                    smartField: '',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'description',
                  options: {
                    toolLabel: 'Text',
                    toolIcon: 'TextFields',
                    label: '',
                    placeholder: 'Description',
                    default_value: '',
                    smartField: '',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      name: {
        type: 'string'
      },
      description: {
        type: 'string'
      }
    },
    required: ['name', 'description']
  },
  validationErrors: {
    name: {
      required: 'Field is required.'
    },
    description: {
      required: 'Field is required.'
    }
  }
};

export default costCode;
