import isNumber from 'lodash/isNumber';

export const tableDurationFormatter = ({ value }) => {
  if (!isNumber(value)) {
    return '-';
  }
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value % 3600) / 60);
  return `${hours || 0}h ${minutes || 0}m`;
};
