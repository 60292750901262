import gql from 'graphql-tag';
import useExtendedMutation from 'customHooks/useExtendedMutation';

const PROVISION_TENANT = gql`
  mutation TenantProvision(
    $companyName: String!
    $accountName: String!
    $ownerName: String!
    $tier: String!
    $email: String!
    $status: String!
    $userName: String!
    $firstName: String!
    $lastName: String!
    $type: String!
    $salesforceId: String!
  ) {
    tenantProvision(
      companyName: $companyName
      accountName: $accountName
      ownerName: $ownerName
      tier: $tier
      email: $email
      status: $status
      userName: $userName
      firstName: $firstName
      lastName: $lastName
      type: $type
      salesforceId: $salesforceId
    ) {
      id
    }
  }
`;

export const useProvisionTenant = () => {
  return useExtendedMutation(PROVISION_TENANT, {
    serializer: data => ({
      variables: data
    })
  });
};
