import React, { memo } from 'react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import AlgoliaSearch from '../AlgoliaSearch';

function areEqual(prevProps, nextProps) {
  return isEqual(JSON.stringify(prevProps), JSON.stringify(nextProps));
}

const SearchBox = ({ ...props }) => <AlgoliaSearch {...props} />;

SearchBox.propTypes = {
  handleChange: PropTypes.func
};

SearchBox.defaultProps = {
  handleChange: () => {}
};

export default memo(SearchBox, areEqual);
