import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, useTheme } from '@material-ui/core';
import { snackbarOn } from 'redux/actions/globalActions';
import { Button, ThemeProvider, Typography, TW, TV, ButtonType } from '@buildhero/sergeant';

import { Tasks as TasksNew, UserPermission } from 'components';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import ErrorBoundaries from 'scenes/Error';
import { checkPermission } from 'utils';
import { TaskListType, PermissionConstants } from 'utils/AppConstants';
import TaskTable from 'scenes/Customer/PropertyDetail/Tasks/TaskTable';
import { useCreateTaskForJob } from 'scenes/Customer/PropertyDetail/hooks/useCreateTaskForJob';
import { getPropertyTasksCount } from './getPropertyTasksCount.gql';

const PROPERTY_TASKS_TAB = 'propertyTasks';
const TASK_TAB = 'tasks';

export const TaskTab = ({
  shouldDisallowEditing,
  jobId,
  jobTypeInternal,
  fetchJobData,
  ...props
}) => {
  const theme = useTheme();
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const snackbar = useCallback((...args) => dispatch(snackbarOn(...args)), [dispatch]);
  const { data: propertyTaskCount, loading: fetchingCount } = useExtendedQuery(
    getPropertyTasksCount,
    {
      variables: {
        customerPropertyId: props.propertyId,
        listTypes: [TaskListType.READY_FOR_JOB, TaskListType.RECOMMENDED_TASKS_UNQUOTED]
      },
      fetchPolicy: 'no-cache',
      transform: data => data.getPropertyTasksCount
    }
  );

  const hasPermissionToEditTask = checkPermission('edit', PermissionConstants.OBJECT_TASK, user);
  const [currentTab, setCurrentTab] = useState(TASK_TAB);
  const [fetchReadyForJobTask, toggleFetchReadyForJobTask] = useState(false);
  const [fetchRecommendedTask, toggleFetchRecommendedTask] = useState(false);
  const [selectedTaskIds, setselectedTaskIds] = useState();
  const [selectedRecommendedTaskIds, setSelectedRecommendedTaskIds] = useState();
  const [addTaskToJob, { loading: mutationLoading }] = useCreateTaskForJob();

  const showPropertyTasks = currentTab === PROPERTY_TASKS_TAB;
  const showTaskTab = currentTab === TASK_TAB;

  const grayButtonStyling = {
    backgroundColor: theme.palette.grayscale(94),
    color: theme.palette.grayscale(20)
  };

  const handleAddTaskToJob = async (taskIds, isRecommendedTask = false) => {
    try {
      await addTaskToJob({
        partitionKey: user.tenantId,
        data: { jobId, jobTasks: taskIds.map(taskId => ({ taskId })) }
      });

      snackbar('success', `Task${taskIds?.length > 1 ? 's' : ''} added to ${jobTypeInternal}`);
      if (isRecommendedTask) {
        toggleFetchRecommendedTask(prevState => !prevState);
      } else {
        toggleFetchReadyForJobTask(prevState => !prevState);
      }
      fetchJobData();
    } catch (error) {
      snackbar('error', `Unable to add the selected task${taskIds?.length > 1 ? 's' : ''}`, error);
    }
  };

  return (
    <ErrorBoundaries>
      <ThemeProvider>
        <Box marginBottom={2}>
          <Button
            type={showTaskTab ? ButtonType.PRIMARY : ButtonType.LEADING}
            onClick={() => setCurrentTab(TASK_TAB)}
            css={{
              marginRight: 8,
              ...(showTaskTab ? {} : grayButtonStyling)
            }}
          >
            Assigned Tasks
          </Button>
          <Button
            type={showPropertyTasks ? ButtonType.PRIMARY : ButtonType.LEADING}
            onClick={() => setCurrentTab(PROPERTY_TASKS_TAB)}
            css={showPropertyTasks ? {} : grayButtonStyling}
          >
            {`Property Tasks ${fetchingCount ? '' : ` (${propertyTaskCount || 0})`}`}
          </Button>
        </Box>
      </ThemeProvider>
      {showTaskTab && <TasksNew {...props} />}
      {showPropertyTasks && (
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="flex-end"
            marginBottom={1}
          >
            <ThemeProvider>
              <Typography variant={TV.BASE} weight={TW.BOLD}>
                Ready for Job or Maintenance
              </Typography>
              <UserPermission
                I="edit"
                action={[PermissionConstants.OBJECT_JOB, PermissionConstants.OBJECT_TASK]}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Button
                    type={ButtonType.SECONDARY}
                    onClick={() => handleAddTaskToJob(selectedTaskIds)}
                    size="small"
                    loading={mutationLoading}
                    disabled={shouldDisallowEditing || !selectedTaskIds?.length}
                  >
                    {`Add to ${jobTypeInternal}`}
                  </Button>
                </Box>
              </UserPermission>
            </ThemeProvider>
          </Box>
          <Box height={300} marginBottom={4}>
            <TaskTable
              customerPropertyId={props.propertyId}
              listType={TaskListType.READY_FOR_JOB}
              isActive={!shouldDisallowEditing && hasPermissionToEditTask}
              key={`ReadyForJob${fetchReadyForJobTask}`}
              onSelectionModelChange={newTaskArray => {
                setselectedTaskIds(newTaskArray);
              }}
              selectionModel={selectedTaskIds}
            />
          </Box>
          <Box height={300} marginBottom={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-end"
              alignContent=""
              marginBottom={1}
            >
              <ThemeProvider>
                <Typography variant={TV.BASE} weight={TW.BOLD}>
                  Recommended Tasks
                </Typography>
                <UserPermission
                  I="edit"
                  action={[PermissionConstants.OBJECT_JOB, PermissionConstants.OBJECT_TASK]}
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Button
                      type={ButtonType.SECONDARY}
                      onClick={() => handleAddTaskToJob(selectedRecommendedTaskIds, true)}
                      size="small"
                      loading={mutationLoading}
                      disabled={shouldDisallowEditing}
                    >
                      {`Add to ${jobTypeInternal}`}
                    </Button>
                  </Box>
                </UserPermission>
              </ThemeProvider>
            </Box>
            <TaskTable
              customerPropertyId={props.propertyId}
              includeQuote
              onSelectionModelChange={newTaskArray => {
                setSelectedRecommendedTaskIds(newTaskArray);
              }}
              selectionModel={selectedRecommendedTaskIds}
              listType={TaskListType.RECOMMENDED_TASKS_UNQUOTED}
              includeJob
              isActive={!shouldDisallowEditing && hasPermissionToEditTask}
              key={`RecommendedTask${fetchRecommendedTask}`}
            />
          </Box>
        </Box>
      )}
    </ErrorBoundaries>
  );
};

export default TaskTab;
