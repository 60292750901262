import { arrayOf, bool, number, object, oneOfType, shape, string } from 'prop-types';

import { ERROR_PROP, RANGE_PROP, VisitActions } from '@dispatch/Dispatch.constants';

export const VISIT_DETAILS_PROP = shape({
  id: string,
  actualDuration: number,
  companyName: string,
  customerName: string,
  customerRep: shape({
    id: string,
    name: string,
    email: string,
    cellPhone: string
  }),
  departmentId: string,
  departmentName: string,
  description: string,
  extraTechs: arrayOf(string),
  extraTechsNumber: number,
  extraTechsRequired: bool,
  job: shape({
    id: string,
    jobNumber: string,
    priority: string
  }),
  nextEvents: arrayOf(VisitActions.propType()),
  prerequisites: string,
  prerequisitesAcknowledged: bool,
  primaryTechId: string,
  property: shape({
    id: string,
    customerPropertyName: string,
    address: shape({
      id: string,
      addressType: string,
      addressLine1: string,
      addressLine2: string,
      city: string,
      state: string,
      zipcode: string
    }),
    position: shape({
      lat: number,
      lng: number
    })
  }),
  range: RANGE_PROP,
  schedules: arrayOf(
    shape({
      id: string,
      status: string,
      onHoldReason: string,
      onHold: bool,
      timeSheets: arrayOf(
        shape({
          id: string
        })
      )
    })
  ),
  scheduledFor: number,
  status: string,
  version: number,
  visitNumber: oneOfType([number, string]),
  visitStateMachine: object
});

export const VISIT_DETAILS_RESPONSE = shape({
  loading: bool,
  error: ERROR_PROP,
  data: VISIT_DETAILS_PROP
});
