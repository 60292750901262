import gql from 'graphql-tag';

const getCustomer = gql`
  query getCustomer($partitionKey: String!, $sortKey: String!) {
    getCustomer(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      customerName
      customerTypeValue
      accountNumber
      customerNumber
      status
      email
      phonePrimary
      amountNotToExceed
      sameAddress
      receiveSMS
      paymentTermId
      paymentTerm(entityConnection: "PaymentTerm") {
        id
        name
        value
        type
      }
      taxRateId
      taxRate(entityConnection: "TaxRate") {
        name
        taxRate
      }
      companyAddresses(entityConnection: "CompanyAddress") {
        items {
          id
          tenantId
          partitionKey
          sortKey
          hierarchy
          addressLine1
          addressLine2
          state
          city
          zipcode
          entityType
          addressType
          billTo
          shipTo
        }
      }

      customerTags(entityConnection: "CustomerTag") {
        items {
          id
          mappedEntityId
          mappedEntityType
          mappedEntityValue
          systemEntityId
          systemEntityType
          hierarchy
          sortKey
          invertedSortKey
          partitionKey
          mappedEntity {
            id
            ... on CustomerTag {
              tagType
              tagName
              sortKey
              partitionKey
              hierarchy
              id
              entityType
            }
          }
        }
      }
      notes(entityConnection: "Note", limit: 100) {
        items {
          id
          partitionKey
          sortKey
          hierarchy
          entityType
          subject
          note
          createdBy
          lastUpdatedDate
          lastUpdatedDateTime
          lastUpdatedBy
        }
      }
      jobs(entityConnection: "Job", limit: 100) {
        items {
          id
          customIdentifier
          jobNumber
          jobTypeName
          jobTypeInternal
          priority
          customerName
          customerPropertyName
        }
      }
      customerProperties(
        entityConnection: "CustomerProperty"
        filter: { status: { ne: "inactive" } }
        limit: 100
      ) {
        items {
          id
          tenantId
          partitionKey
          sortKey
          hierarchy
          entityType
          companyName
          customerPropertyTypeValue
          amountNotToExceed
          sameAddress
          companyAddresses(entityConnection: "CompanyAddress") {
            items {
              id
              tenantId
              partitionKey
              sortKey
              hierarchy
              addressLine1
              addressLine2
              state
              city
              zipcode
              entityType
              addressType
            }
          }
          jobs(entityConnection: "Job", limit: 100) {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              customIdentifier
              jobNumber
              jobTypeName
              jobTypeInternal
              priority
              status
              customerName
              customerPropertyName
            }
          }
        }
      }
      customerReps(
        entityConnection: "CustomerRep"
        filter: { status: { eq: "active" } }
        limit: 100
      ) {
        items {
          id
          tenantId
          partitionKey
          sortKey
          hierarchy
          entityType
          name
          firstName
          lastName
          email
          contactRole
          cellPhone
          landlinePhone
          isSmsOptOut
          bestContact
          notes(entityConnection: "Note") {
            items {
              id
              partitionKey
              sortKey
              hierarchy
              entityType
              subject
              note
              lastUpdatedDate
              lastUpdatedDateTime
              lastUpdatedBy
            }
          }
        }
      }
      tenantReps(entityConnection: "Employee", limit: 100) {
        items {
          id
          mappedEntityId
          mappedEntityType
          systemEntityId
          systemEntityType
          partitionKey
          sortKey
          invertedSortKey
          hierarchy
          mappedEntity {
            ... on Employee {
              id
              tenantId
              partitionKey
              sortKey
              hierarchy
              entityType
              firstName
              lastName
              name
              status
              notes(entityConnection: "Note") {
                items {
                  id
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  tenantId
                  subject
                  note
                  createdBy
                  lastUpdatedDate
                  lastUpdatedDateTime
                  lastUpdatedBy
                }
              }
              appRoles(entityConnection: "AppRole") {
                items {
                  id
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  mappedEntity {
                    ... on AppRole {
                      id
                      tagName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getCustomer;
