import React, { useState } from 'react';

import { array, func, object } from 'prop-types';

import {
  EventEntities,
  JOB_COMPLETE_STATUS,
  VisitActions,
  VisitStatuses
} from '@dispatch/Dispatch.constants';
import { useFormService } from '@dispatch/hooks';
import { useDispatchSettings } from '@dispatch/settings';
import { getTenantSettingValueForKey } from 'utils';

import DrawerLayout from '../DrawerLayout';
import VisitDetailsForm from '../VisitDetailsForm';

import OnHoldReasonModal from './components/OnHoldReasonModal';
import VisitStatusHeader from './components/VisitStatusHeader';

const isOnHoldVisit = visit => visit?.status === VisitStatuses.ON_HOLD.key;

const canBeReleasedToTech = (visit, releaseToTechEnabled) =>
  releaseToTechEnabled && !visit?.detailsSent && visit?.status === VisitStatuses.SCHEDULED.key;

const shouldDisableVisitUpdate = visit => !visit.saveTransition || isOnHoldVisit(visit);

const EditVisit = ({
  createEvent,
  closeDrawer,
  departmentsResponse,
  visitDetailsResponse,
  visitTransitionTuple,
  refetchVisitData
}) => {
  const { releaseToTechEnabled } = useDispatchSettings();
  const { isDirty, onCreateService, onDirtyChange, resetForm, submitForm } = useFormService();
  const crewTimeTracking = getTenantSettingValueForKey('crewTimeTracking') === 'true';
  const [triggerVisitTransition, visitTransitionResponse] = visitTransitionTuple;
  const visitDetailsLoading = visitDetailsResponse.loading;
  const visit = visitDetailsResponse.data;
  const visitTransitionLoading = visitTransitionResponse.loading;

  const [onHoldReasonModalOpen, setOnHoldReasonModalOpen] = useState(false);

  const handleVisitTransition = ({ transitionAction, originalVisit = visit, inputData }) => {
    if (transitionAction === VisitActions.PUT_ON_HOLD.key) {
      setOnHoldReasonModalOpen(true);
      return;
    }

    const departmentName = departmentsResponse.data?.find(
      department => department.id === inputData?.departmentId
    )?.name;

    const extraTechsNumber = inputData?.extraTechsNumber === '' ? 0 : inputData?.extraTechsNumber;

    triggerVisitTransition({
      transitionAction,
      originalVisit,
      inputData: { ...inputData, departmentName, extraTechsNumber }
    });
  };

  const handlePutOnHold = ({ onHoldReason }) => {
    triggerVisitTransition({
      transitionAction: VisitActions.PUT_ON_HOLD.key,
      originalVisit: visit,
      inputData: { onHoldReason }
    });
    closeDrawer();
  };

  const handleFormComplete = inputData => {
    handleVisitTransition({ inputData });
    closeDrawer();
  };

  const handleClone = () => {
    createEvent({
      eventType: EventEntities.VISIT.value.clientValue,
      eventData: visit
    });
  };

  const handleContinueAsNew = () => {
    createEvent({
      eventType: EventEntities.CONTINUED_VISIT.value.clientValue,
      eventData: visit
    });
  };

  const handleReleaseToTech = () => {
    return triggerVisitTransition({
      transitionAction: VisitActions.RELEASE_TO_TECHS.key,
      originalVisit: visit,
      inputData: visit
    });
  };

  const renderHeader = () => {
    return (
      <VisitStatusHeader
        visit={visit}
        visitDetailsLoading={visitDetailsLoading}
        visitTransitionLoading={visitTransitionLoading}
        handleVisitTransition={handleVisitTransition}
      />
    );
  };

  const renderBody = () => {
    return (
      <VisitDetailsForm
        visit={visit}
        onCreateService={onCreateService}
        onDirtyChange={onDirtyChange}
        onComplete={handleFormComplete}
        disabled={visitTransitionLoading || visitDetailsLoading || !visit.saveTransition}
        visitLoading={visitDetailsLoading}
        crewTimeTracking={crewTimeTracking}
        showTechStatuses
      />
    );
  };

  const canCloneVisit = visit.job.status !== JOB_COMPLETE_STATUS;

  return (
    <>
      <DrawerLayout
        renderHeader={renderHeader}
        renderBody={renderBody}
        onResetForm={resetForm}
        onSubmitForm={submitForm}
        onClone={canCloneVisit ? handleClone : undefined}
        onContinueAsNew={isOnHoldVisit(visit) ? handleContinueAsNew : undefined}
        onReleaseToTech={
          canBeReleasedToTech(visit, releaseToTechEnabled) ? handleReleaseToTech : undefined
        }
        isDirty={isDirty}
        loading={visitTransitionLoading || visitDetailsLoading}
        disabledSave={shouldDisableVisitUpdate(visit)}
        disabledClose={visitTransitionLoading}
        visit={visit}
        showProcurement={visit.status !== VisitStatuses.CANCELED.key}
        refetchVisitData={refetchVisitData}
      />
      <OnHoldReasonModal
        open={onHoldReasonModalOpen}
        setOpen={setOnHoldReasonModalOpen}
        onConfirm={handlePutOnHold}
      />
    </>
  );
};

EditVisit.propTypes = {
  closeDrawer: func.isRequired,
  createEvent: func.isRequired,
  departmentsResponse: object.isRequired,
  visitDetailsResponse: object.isRequired,
  visitTransitionTuple: array.isRequired,
  refetchVisitData: func
};

EditVisit.defaultProps = {
  refetchVisitData: () => {}
};

export default EditVisit;
