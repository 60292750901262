import gql from 'graphql-tag';

const deleteForm = gql`
  mutation deleteForm($input: DeleteFormInput!) {
    deleteForm(input: $input) {
      id
    }
  }
`;

export default deleteForm;
