import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import EnterpriseLaborSettings from './EnterpriseLaborSettings';
import LaborSettings from './LaborSettings';

const Payroll = () => {
  const flags = useFlags();

  if (flags[FeatureFlags.ENTERPRISE_LABOR_COSTING]) return <EnterpriseLaborSettings />;

  return <LaborSettings />;
};

export default Payroll;
