import { useExtendedFetchForPMAPI as useExtendedFetch } from 'customHooks/useExtendedFetch';

export const useGetRfiById = (id, options, params) => {
  return useExtendedFetch(
    {
      url: `rfis/${id}`,
      params
    },
    {
      errorData: [],
      defaultData: [],
      ...options
    }
  );
};

export const useGetRfis = (options = {}, params) => {
  return useExtendedFetch(
    {
      url: `rfis`,
      params
    },
    {
      errorData: [],
      defaultData: [],
      ...options
    }
  );
};

// use by calling execute like so:
// execute({ url: 'rfis/${rfiId}', data: { ...rfiData } })
export const useChangeRfi = (options = {}) => {
  return useExtendedFetch(
    {
      method: 'PATCH'
    },
    {
      errorData: [],
      defaultData: [],
      manual: true,
      ...options
    }
  );
};

export const useDeleteRfi = (options = {}) =>
  useExtendedFetch({ method: 'DELETE' }, { ...options, manual: true });
