import gql from 'graphql-tag';

const getAllTasksByJobNumber = gql`
  query getAllTasksByJobNumber($jobNumber: String!) {
    getJobByJobNumber(jobNumber: $jobNumber) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      tenantId
      hierarchy
      entityType
      jobTasks: tasks(entityConnection: "QuoteLineTask") {
        items {
          id
          sortKey
          version
          name
          description
          departmentId
          departmentValue
          taskTypeId
          taskTypeValue
          taskTypeInternal
          taskNumber
          status
          jobTypeId
          jobTypeValue
          assetId
          assetValue
          nextDueDate
          productBundleId
          productBundleValue
          isActive
          products: taskEntriesView(entityConnection: "QuoteLineTaskEntry") {
            items {
              id
              sortKey
              version
              entityType
              productId
              name
              description
              markupType
              markupValue
              unitPrice
              unitCost
              quantity
            }
          }
        }
      }
    }
  }
`;
export default getAllTasksByJobNumber;
