export const anchorId = 'date-picker-popover';

export const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'bottom'
};

export const transformOrigin = {
  vertical: 'top',
  horizontal: 'bottom'
};
