const bundleForm = {
  fields: {
    name: {
      default: 'name'
    },
    description: {
      default: 'description'
    },
    category: {
      default: 'category'
    },
    subCategory: {
      default: 'subCategory'
    },
    type: {
      default: 'typeValue'
    },
    isActive: {
      default: 'isActive'
    }
  },
  layouts: {
    default: {
      options: {
        direction: 'row',
        shrink: 0,
        padding: 5
      },
      contents: [
        {
          options: {
            direction: 'column',
            shrink: 0,
            padding: 5
          },
          contents: [
            {
              options: {
                label: 'Name'
              },
              component: {
                default: 'FieldWithLabel'
              },
              source: 'name'
            }
          ]
        },
        {
          options: {
            direction: 'column',
            shrink: 0,
            padding: 5,
            grow: 2
          },
          contents: [
            {
              options: {
                label: 'Description'
              },
              component: {
                default: 'FieldWithLabel'
              },
              source: 'description'
            }
          ]
        },
        {
          options: {
            direction: 'column',
            shrink: 0,
            padding: 5
          },
          contents: [
            {
              options: {
                label: 'Category'
              },
              component: {
                default: 'FieldWithLabel'
              },
              source: 'category'
            }
          ]
        },
        {
          options: {
            direction: 'column',
            shrink: 0,
            padding: 5
          },
          contents: [
            {
              options: {
                label: 'Sub category'
              },
              component: {
                default: 'FieldWithLabel'
              },
              source: 'subCategory'
            }
          ]
        },
        {
          options: {
            direction: 'column',
            shrink: 0,
            padding: 5
          },
          contents: [
            {
              options: {
                label: 'Type'
              },
              component: {
                default: 'FieldWithLabel'
              },
              source: 'type'
            }
          ]
        }
      ]
    }
  }
};

export default bundleForm;
