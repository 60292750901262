import moment from 'moment';
import { ProjectStatus } from 'scenes/ProjectManagement/constants';

export const getFormattedData = data => {
  return {
    // General Information
    name: data.name || '',
    ProjectType: data.ProjectType || '',
    ProjectSubtype: data.ProjectSubtype || '',
    number: data.number || '',
    customer: data.customer || '',
    billingCustomer: data.billingCustomer || '',
    property: data.property || '',
    // TODO: migration needed
    projectManagerDepartment: data.projectManagerDepartment || '',
    address1: data.address1 || '',
    address2: data.address2 || '',
    addressCity: data.addressCity || '',
    addressState: data.addressState || '',
    addressPostal: data.addressPostal || '',
    description: data.description || '',
    status: data.status || ProjectStatus.BID, // default to Bid
    externalPONumber: data.externalPONumber || '',
    // Contacts
    projectOwner: data.projectOwner || '',
    entryForArchitect: data.entryForArchitect || '',
    gcSuperintendent: data.gcSuperintendent || '',
    gcProjectManager: data.gcProjectManager || '',
    projectManager: data.projectManager || '',
    soldBy: data.soldBy || '',
    foreman: data.foreman || '',
    // Dates
    dateCreated: data.dateCreated || moment().unix(),
    dateStart: data.dateStart,
    dateEnd: data.dateEnd,
    contractDate: data.contractDate
  };
};

export const getFormattedPayload = ({ data, user, uploadImageUrl }) => {
  // TODO: check final data'
  const finalData = {};
  // General Information
  finalData.name = data.name;
  finalData.number = data.number;
  finalData.projectTypeId = data.ProjectType?.id;
  finalData.projectSubtypeId = data.ProjectSubtype?.id;
  finalData.customerId = data.customer?.id || undefined;
  finalData.billingCustomerId = data.billingCustomer?.id || undefined;
  finalData.propertyId = data.property?.id || null;
  finalData.departmentId = data.projectManagerDepartment?.id || undefined;
  finalData.address1 = data.address1 || null;
  finalData.address2 = data.address2 || null;
  finalData.addressCity = data.addressCity || null;
  finalData.addressState = data.addressState || null;
  finalData.addressPostal = data.addressPostal || null;
  finalData.description = data.description || null;
  finalData.status = data.status;
  finalData.externalPONumber = data.externalPONumber || null;
  // Contacts
  finalData.projectOwnerId = data.projectOwner?.id || null;
  finalData.entryForArchitectId = data.entryForArchitect?.id || null;
  finalData.gcSuperintendentId = data.gcSuperintendent?.id || null;
  finalData.gcProjectManagerId = data.gcProjectManager?.id || null;
  finalData.projectManagerId = data.projectManager?.id || undefined;
  finalData.soldById = data.soldBy?.id || null;
  finalData.foremanId = data.foreman?.id || null;
  // Dates
  finalData.dateCreated = data.dateCreated;
  finalData.dateStart = data.dateStart;
  finalData.dateEnd = data.dateEnd;
  finalData.contractDate = data.contractDate;
  // Etc
  finalData.companyId = user.tenantCompanyId;
  finalData.imgUrl = uploadImageUrl.fileUrl || null;

  return finalData;
};
