import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const UPDATE_JOB_GQL = gql`
  mutation updateJob($data: UpdateJobInput!) {
    updateJob(data: $data) {
      id
      partitionKey
      status
      reviewStatus
      procurementStatus
      manualQuoteStatus
      billingStatus
      version
    }
  }
`;

const serializer = ({ id, version, ...jobInputParams }) => ({
  variables: {
    data: {
      id,
      version,
      ...jobInputParams
    }
  }
});

const useUpdateJob = () =>
  useExtendedMutation(UPDATE_JOB_GQL, {
    serializer
  });

export default useUpdateJob;
