import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  input: {
    '& textarea': {
      textAlign: 'left',
      minHeight: '4em'
    }
  }
}));
