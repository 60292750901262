import React from 'react';

import { FieldType, PDFComponents, ThemeProvider, TW } from '@buildhero/sergeant';
import { View } from '@react-pdf/renderer';

import { getTechniciansFromVisit } from 'utils';

const emptyValString = '-';
const leftGutterWidth = '40pt';
const spaceBetweenRows = 6;
const spaceBetweenColumns = 20;
const rowStyles = {
  marginLeft: leftGutterWidth,
  marginBottom: spaceBetweenRows
};

const styles = {
  checkmark: {
    display: 'inline-block',
    height: 22,
    width: 22,
    transform: 'rotate(45deg)'
  },
  checkmarkLongLine: {
    position: 'absolute',
    width: 3,
    height: 9,
    backgroundColor: '#ccc',
    left: 11,
    top: 6
  },
  checkmarkShortLine: {
    position: 'absolute',
    width: 3,
    height: 3,
    backgroundColor: '#ccc',
    left: 8,
    top: 12
  },
  checkboxDiv: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexGrow: 1.2
  }
};

const VisitSummary = ({ field, options }) => {
  const displayValue = field?.value || [];
  if (displayValue.length === 0 || !Array.isArray(displayValue)) return null;
  const visits = displayValue[0]?.value;
  const { showCustomerSignature } = options;

  return (
    <View style={{ alignContent: 'flex-start' }}>
      {visits.map(visit => {
        const reviewReports = visit?.reviewReports?.items;
        const hasCustomerSignature = visit?.customerSignaturesPdfs?.items?.length > 0;
        return (
          <View
            key={`visit_${visit.visitNumber}`}
            style={{
              flexDirection: 'column',
              marginBottom: spaceBetweenColumns,
              width: '100%'
            }}
          >
            <View style={{ flexDirection: 'row', marginBottom: spaceBetweenRows }}>
              <View style={{ width: leftGutterWidth }}>
                <PDFComponents.Field
                  props={{
                    label: 'VISIT',
                    value: `000${visit.visitNumber}`.slice('-3'),
                    weight: TW.BOLD
                  }}
                />
              </View>
              <View style={{ flexDirection: 'column' }}>
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ flexDirection: 'column', marginRight: spaceBetweenColumns }}>
                    <PDFComponents.Field
                      props={{
                        label: 'DATE PERFORMED',
                        value: visit.scheduledFor,
                        type: FieldType.DATE
                      }}
                    />
                  </View>
                  <View style={{ flexDirection: 'column', maxWidth: '75%' }}>
                    <PDFComponents.Field
                      props={{
                        label: 'TECHNICIAN(S)',
                        value: getTechniciansFromVisit(visit?.primaryTechs, visit?.extraTechs) ?? ''
                      }}
                    />
                  </View>
                </View>
              </View>
              {showCustomerSignature && hasCustomerSignature ? (
                <View style={styles.checkboxDiv}>
                  <View style={styles.checkmark}>
                    <View style={styles.checkmarkLongLine} />
                    <View style={styles.checkmarkShortLine} />
                  </View>
                  <PDFComponents.Typography
                    props={{
                      weight: TW.REGULAR,
                      value: 'Customer Signature'
                    }}
                  />
                </View>
              ) : null}
            </View>
            <View style={{ flexDirection: 'column', ...rowStyles }}>
              <PDFComponents.Field
                props={{
                  label: 'ASSET(S)',
                  value: visit.visitAssets?.items.length ? '' : emptyValString
                }}
              />
              {visit.visitAssets?.items.map(asset => (
                <View
                  key={`asset_${asset.propertyAsset.assetName}`}
                  style={{ marginBottom: spaceBetweenRows }}
                >
                  <PDFComponents.Typography
                    props={{
                      value: asset.propertyAsset.assetName,
                      weight: TW.BOLD
                    }}
                  />
                  <View style={{ flexDirection: 'row', marginBottom: spaceBetweenRows }}>
                    <ThemeProvider>
                      <PDFComponents.Field
                        props={{
                          label: 'Make',
                          value: asset.propertyAsset?.make ?? emptyValString,
                          style: { marginRight: spaceBetweenColumns }
                        }}
                      />
                      <PDFComponents.Field
                        props={{
                          label: 'Model Number',
                          value: asset.propertyAsset?.modelNumber ?? emptyValString,
                          style: { marginRight: spaceBetweenColumns }
                        }}
                      />
                      <PDFComponents.Field
                        props={{
                          label: 'Serial Number',
                          value: asset.propertyAsset?.serialNo ?? emptyValString,
                          style: { marginRight: spaceBetweenColumns }
                        }}
                      />
                    </ThemeProvider>
                  </View>
                </View>
              ))}
            </View>
            <View style={{ flexDirection: 'column', ...rowStyles }}>
              <PDFComponents.Field props={{ label: 'WORK SUMMARY' }} />
              {reviewReports.map(reviewReport => {
                const summaries = reviewReport.summaries?.items.map(
                  ({ summary, addedBy, createdBy }) => (
                    <View key={`summary_${summary}`}>
                      <PDFComponents.Typography
                        props={{ value: addedBy || createdBy, weight: TW.BOLD }}
                      />
                      <PDFComponents.Typography props={{ value: summary }} />
                    </View>
                  )
                );
                return summaries.length ? (
                  summaries
                ) : (
                  <PDFComponents.Typography props={{ value: emptyValString }} />
                );
              })}
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default VisitSummary;
