import { useMemo } from 'react';
import { useDragLayer, useDrop } from 'react-dnd';
import moment from 'moment';

import { DragScenarios, ItemTypes, selectDragScenario } from '@dispatch/dnd';
import { ElementSizes } from '@dispatch/Dispatch.styles';

import { canDropVisit, trackDrop } from '@dispatch/components/DispatchBoard/DispatchBoard.utils';
import { selectDropTech } from '@dispatch/components/DispatchBoard/DispatchBoard.selectors';

import { serializeDropData } from '../../DailyView.serializers';
import { selectSnappedOffset, selectUnixFromOffset } from '../../DailyView.selectors';

import { useFlags } from 'launchdarkly-react-client-sdk';

export const useShowDropPreview = ({ isOver, canDrop, dragScenario, isOverBoard }) => {
  return useMemo(() => {
    switch (dragScenario) {
      case DragScenarios.VISIT_START:
      case DragScenarios.VISIT_END:
      case DragScenarios.EVENT_START:
      case DragScenarios.EVENT_END:
      case DragScenarios.MAN_DAY_BOARD_TO_BOARD:
      case DragScenarios.MAN_DAY_START:
      case DragScenarios.MAN_DAY_END:
      case DragScenarios.NON_VISIT_BOARD_TO_BOARD:
      case DragScenarios.UNASSIGNED_SINGLE_TECH: {
        return isOver;
      }
      case DragScenarios.FULLY_ASSIGNED_MULTI_TECH:
      case DragScenarios.FULLY_ASSIGNED_SINGLE_TECH: {
        return canDrop && isOverBoard;
      }
      case DragScenarios.VISIT_BOARD_TO_BOARD: {
        return isOver && canDrop;
      }
      default: {
        return false;
      }
    }
  }, [isOver, canDrop, dragScenario, isOverBoard]);
};

export const useDropPreviewUpdate = ({
  shouldUpdatePreview,
  boardRef,
  offsetRef,
  itemRef,
  setDropPreview,
  techId
}) => {
  useDragLayer(monitor => {
    if (shouldUpdatePreview.current) {
      const xOffset = monitor.getClientOffset()?.x;

      if (xOffset) {
        const dragScenario = selectDragScenario(itemRef.current);

        const dragOffsetX = [DragScenarios.VISIT_START, DragScenarios.VISIT_END].includes(
          dragScenario
        )
          ? 0
          : undefined;

        const snappedOffset = selectSnappedOffset(
          boardRef.current.scrollLeft,
          xOffset,
          dragOffsetX
        );

        if (offsetRef.current !== snappedOffset) {
          const width = itemRef.current?.data?.width;
          const left = itemRef.current?.data?.left;
          const top =
            techId === itemRef.current?.srcTech
              ? itemRef.current?.data?.position * ElementSizes.cellHeight
              : 0;

          switch (dragScenario) {
            case DragScenarios.MAN_DAY_START:
            case DragScenarios.EVENT_START:
            case DragScenarios.VISIT_START: {
              const newWidth = itemRef.current.data.left + width - snappedOffset;
              if (newWidth > 0) {
                const startTime = moment.unix(selectUnixFromOffset(snappedOffset)).format('h:mm a');
                setDropPreview({
                  left: snappedOffset,
                  top,
                  startTime,
                  endTime: null,
                  width: newWidth
                });
              }
              break;
            }
            case DragScenarios.MAN_DAY_END:
            case DragScenarios.EVENT_END:
            case DragScenarios.VISIT_END: {
              const newWidth = snappedOffset - left;
              if (newWidth > 0) {
                const endTime = moment.unix(selectUnixFromOffset(snappedOffset)).format('h:mm a');
                setDropPreview({
                  left,
                  top,
                  startTime: null,
                  endTime,
                  width: newWidth
                });
              }
              break;
            }
            default: {
              setDropPreview({
                left: snappedOffset,
                top,
                startTime: null,
                endTime: null,
                width
              });
            }
          }

          // eslint-disable-next-line no-param-reassign
          offsetRef.current = snappedOffset;
        }
      }
    }
  });
};

export const useSwimLaneDrop = ({
  tech,
  triggerVisitTransition,
  triggerUpdateNonVisit,
  triggerUpdateManDay,
  dropPreview,
  day,
  clearVisitRowHover
}) => {
  const flags = useFlags();
  const [collected, dropRef] = useDrop({
    accept: [
      ItemTypes.TABLE_VISIT,
      ItemTypes.BOARD_VISIT,
      ItemTypes.VISIT_START,
      ItemTypes.VISIT_END,
      ItemTypes.NON_VISIT_EVENT,
      ItemTypes.MAN_DAY,
      ItemTypes.MAN_DAY_START,
      ItemTypes.MAN_DAY_END,
      ItemTypes.EVENT_START,
      ItemTypes.EVENT_END
    ],
    canDrop: () => canDropVisit({ ...collected, techId: tech.id, flags }),
    collect: monitor => {
      const item = monitor.getItem();
      const dragScenario = selectDragScenario(item);

      const canDrop = (() => {
        try {
          return dragScenario === DragScenarios.FULLY_ASSIGNED_MULTI_TECH
            ? true
            : monitor.canDrop();
        } catch (err) {
          // We must handle the "no drop target" error for assigned visits
          // that are not currently rendered in the virtualized list
          return false;
        }
      })();

      return {
        isOver: monitor.isOver({ shallow: true }),
        canDrop,
        dragScenario,
        primaryTech: selectDropTech(item),
        item,
        isDragging: Boolean(item)
      };
    },
    drop: item => {
      const dragScenario = collected?.dragScenario;
      const itemType = collected?.item?.type;
      trackDrop({ dragScenario, itemType });
      if (
        [
          DragScenarios.VISIT_BOARD_TO_BOARD,
          DragScenarios.UNASSIGNED_SINGLE_TECH,
          DragScenarios.VISIT_END,
          DragScenarios.VISIT_START
        ].includes(collected.dragScenario)
      ) {
        triggerVisitTransition(
          serializeDropData({
            item,
            dropPreview,
            hoveredTech: tech.id,
            day,
            ...collected
          })
        );
      }

      if (
        [
          DragScenarios.NON_VISIT_BOARD_TO_BOARD,
          DragScenarios.EVENT_START,
          DragScenarios.EVENT_END
        ].includes(collected.dragScenario)
      ) {
        triggerUpdateNonVisit(
          serializeDropData({
            item,
            dropPreview,
            hoveredTech: tech.id,
            day,
            ...collected
          })
        );
      }

      if (
        [
          DragScenarios.MAN_DAY_BOARD_TO_BOARD,
          DragScenarios.MAN_DAY_START,
          DragScenarios.MAN_DAY_END
        ].includes(collected.dragScenario)
      ) {
        triggerUpdateManDay(
          serializeDropData({
            item,
            dropPreview,
            hoveredTech: tech.id,
            day,
            ...collected
          })
        );
      }
      clearVisitRowHover();
    }
  });

  return [collected, dropRef];
};
