export const selectSnappedMin = (interval, time) => {
  const roundedMinutes = Math.round(time.clone().minute() / interval) * interval;

  return time
    .clone()
    .minute(roundedMinutes)
    .second(0);
};

export const selectTimeClosestToClient = ({ range: { start, end }, snappedTime, clientTime }) => {
  let closestTime;
  let smallestProximity;

  [start, end, snappedTime].forEach(time => {
    const proximity = Math.abs(clientTime.diff(time));

    if (!smallestProximity || smallestProximity > proximity) {
      closestTime = time;
      smallestProximity = proximity;
    }
  });

  return closestTime;
};
