import React, { useRef, memo, useState } from 'react';

import compose from 'utils/compose';

import { withDispatchActions, withDispatchStore } from '@dispatch/Dispatch.store';
import useAddVisit from '@dispatch/mutations/useAddVisit';
import useAddMultipleVists from '@dispatch/mutations/useAddMultipleVists';
import useJobDetails from '@dispatch/queries/useJobDetails';

import CreateVisit from './CreateVisit.component';

const mapActionsToProps = ({ closeDrawer }) => ({
  closeDrawer
});

const mapDispatchToProps = store => ({
  eventData: store.state.eventData
});

const DataProvider = props => {
  const Memoized = useRef(memo(CreateVisit)).current;
  // eslint-disable-next-line react/prop-types
  const { eventData } = props;
  const [jobNumber, setJobNumber] = useState(eventData?.job?.jobNumber);

  const addVisitTuple = useAddVisit();
  const addMultipleVisitsTuple = useAddMultipleVists();
  const jobDetailsResponse = useJobDetails(jobNumber);

  return (
    <Memoized
      {...props}
      jobNumber={jobNumber}
      setJobNumber={setJobNumber}
      addVisitTuple={addVisitTuple}
      addMultipleVisitsTuple={addMultipleVisitsTuple}
      jobDetailsResponse={jobDetailsResponse}
    />
  );
};

export default compose(
  withDispatchStore(mapDispatchToProps),
  withDispatchActions(mapActionsToProps)
)(DataProvider);
