import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  topBar: {
    padding: 20,
    borderColor: theme.palette.grayscale(85),
    background: theme.palette.grayscale(100),
    borderRadius: '4px 4px 0px 0px',
    boxShadow: '0px 2px 7px 0px rgba(0, 0, 0, 0.06)'
  },
  topBarDetails: {
    borderTop: `1px solid ${theme.palette.grayscale(85)}`,
    marginTop: 5
  },
  titleStyle: {
    color: theme.palette.brand.logoBlue,
    fontSize: 20,
    letterSpacing: -0.02,
    marginTop: 17,
    marginBottom: 17
  },
  inventoryTitleContainer: {
    marginTop: 15,
    marginBottom: 15
  },
  inputLabel: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'normal',
    letterSpacing: '0.01px',
    lineHeight: '14px'
  },
  requiredLabel: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: '8px',
    letterSpacing: 0,
    lineHeight: '14px'
  },
  iconButtonLeftStyles: {
    borderRadius: 3,
    borderColor: theme.palette.other.navyBlue,
    border: '1px solid',
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    borderRightWidth: 0,
    width: 43
  },
  iconButtonRightStyles: {
    borderRadius: 3,
    borderColor: theme.palette.other.navyBlue,
    border: '1px solid',
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    width: 43
  },
  searchBoxStyle: {
    width: 300
  },
  filterButtonStyle: {
    width: 30,
    height: 35,
    borderRadius: 0,
    borderColor: theme.palette.grayscale(80),
    border: '1px solid',
    borderLeft: 0,
    backgroundColor: theme.palette.grayscale(100)
  },
  checkbox: {
    width: 16,
    height: 16,
    fontSize: 12,
    paddingLeft: 0
  },
  filterMenu: {
    padding: 12,
    outline: 'none',
    paddingRight: 20
  },
  filterTitle: {
    fontWeight: 600,
    fontSize: 12
  },
  filterActionWrapper: {
    float: 'right',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 0
  },
  filterActionBtn: {
    padding: 2,
    minWidth: 'auto',
    fontSize: 12
  },
  filterSummary: {
    display: 'flex',
    paddingLeft: theme.spacing(1.5),
    alignItems: 'center'
  },
  filterCount: {
    fontSize: 12,
    color: theme.palette.grayscale(60),
    paddingRight: theme.spacing(1)
  },
  resultCount: {
    color: theme.palette.grayscale(20),
    fontWeight: 700
  },
  clear: {
    fontSize: 12,
    cursor: 'pointer',
    paddingLeft: theme.spacing(1),
    color: theme.palette.grayscale(60)
  },
  chip: {
    fontSize: 12,
    background: theme.palette.grayscale(90),
    color: theme.palette.grayscale(20),
    marginRight: theme.spacing(0.75),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  }
}));

export default styles;
