import gql from 'graphql-tag';

const deleteTagById = gql`
  mutation deleteTagById($partitionKey: String!, $id: String!) {
    deleteTagById(partitionKey: $partitionKey, id: $id) {
      id
      sortKey
      entityType
      version
    }
  }
`;

export default deleteTagById;
