import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Typography, Button } from '@material-ui/core';
import { MUIForm } from '@buildhero/sergeant';
import CustomFieldWithLabel from 'scenes/ProjectManagement/components/CustomFieldWithLabel';
import Divider from '@material-ui/core/Divider';
import { generateDefaultValidationSchema } from 'scenes/ProjectManagement/components/formattingUtils';
import { budgetFields, phaseLayout } from './layout';
import PhaseDepartmentForm from '../DepartmentForm';
import { formatPhaseForForm } from '../utils';

const PhaseForm = props => {
  const {
    phaseData,
    contractValue,
    projectId,
    classes,
    updateContractValue,
    getHandleCreateService,
    getHandleRemoveService,
    getHandleComplete,
    onAddDepartment,
    onDeleteDepartment,
    isEdit
  } = props;
  const phaseDeptIds = useMemo(
    () => phaseData.ProjectPhaseDepartment.map(dept => dept.Department?.id).filter(Boolean),
    [phaseData.ProjectPhaseDepartment]
  );

  const sectionTitle = ({ options }) => (
    <Typography className={classes.sectionTitle} variant="subtitle1">
      {options.label}
    </Typography>
  );

  const addNewDepartment = () => {
    onAddDepartment({
      tempId: uuid.v4()
    });
  };

  return (
    <>
      <MUIForm
        key={`phase-${phaseData.number}`}
        mode="default"
        configuration={phaseLayout()}
        data={formatPhaseForForm(phaseData)}
        onCreateService={getHandleCreateService('PhaseData')}
        validationSchema={generateDefaultValidationSchema({ ...budgetFields })}
        onComplete={getHandleComplete('PhaseData')}
        customComponents={{
          CustomFieldWithLabel,
          sectionTitle
        }}
      />
      {phaseData?.ProjectPhaseDepartment?.map((dept, deptIndex) => (
        <div key={`phase-form-container-${dept.id}-${dept.tempId}`}>
          <Divider style={{ opacity: 0, margin: '10px 0' }} />
          <PhaseDepartmentForm
            getHandleCreateService={getHandleCreateService}
            getHandleRemoveService={getHandleRemoveService}
            getHandleComplete={getHandleComplete}
            key={`${dept.id}-${dept.tempId}-deptform`}
            updateContractValue={updateContractValue}
            phaseDeptData={dept}
            phaseDeptIds={phaseDeptIds}
            index={deptIndex}
            deptTempId={dept.tempId}
            projectId={projectId}
            classes={classes}
            contractValue={contractValue}
            onDeptSelection={onAddDepartment}
            onDeleteDepartment={onDeleteDepartment}
            deleteDisabled={isEdit}
          />
        </div>
      ))}
      <Button
        startIcon={<AddCircleOutlineIcon />}
        className={classes.addButton}
        onClick={addNewDepartment}
      >
        Add Department Estimate
      </Button>
    </>
  );
};

PhaseForm.propTypes = {
  phaseData: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  contractValue: PropTypes.number.isRequired,
  updateContractValue: PropTypes.func.isRequired,
  getHandleCreateService: PropTypes.func.isRequired,
  getHandleRemoveService: PropTypes.func.isRequired,
  getHandleComplete: PropTypes.func.isRequired,
  onAddDepartment: PropTypes.func.isRequired,
  onDeleteDepartment: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired
};

export default PhaseForm;
