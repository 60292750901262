export const useStyles = () => {
  return {
    buttonWrapper: {
      maxHeight: 16,
      display: 'flex',
      alignItems: 'center',
      noPadding: true,
      align: 'center',
      justifyContent: 'center',
      maxWidth: 41,
      width: 41
    }
  };
};
