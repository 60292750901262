import { searchIndex } from 'constants/algoliaIndex';
import { AppConstants } from 'utils/AppConstants';

export default formData => {
  const rowOptions = { direction: 'row', padding: [15, 0, 0, 0] };

  return {
    fields: {
      agreementName: {
        default: 'agreementName'
      },
      agreementNumber: {
        default: 'agreementNumber'
      },
      customerName: {
        default: 'customerName'
      },
      customerId: {
        default: 'customerId'
      }
    },
    validation: {
      type: 'object',
      properties: {
        agreementName: {
          type: 'string'
        },
        agreementNumber: {
          type: 'string'
        },
        customerName: {
          type: 'string'
        }
      },
      required: ['agreementName', 'customerName']
    },
    validationErrors: {
      agreementName: {
        required: 'Agreement name is required.'
      },
      agreementNumber: {
        required: 'Agreement number is required.'
      },
      customerName: {
        required: 'Please select customer'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0,
          width: 392,
          padding: [0, 0, 0, 8]
        },
        contents: [
          {
            // row 1
            options: rowOptions,
            contents: [
              {
                options: { direction: 'column' },
                contents: [
                  {
                    options: {
                      label: 'Agreement Name',
                      isRequired: true
                    },
                    component: 'TextInput',
                    source: 'agreementName'
                  }
                ]
              }
            ]
          },
          {
            // row 2
            options: rowOptions,
            contents: [
              {
                options: { direction: 'column' },
                contents: [
                  {
                    options: {
                      label: 'Agreement Number',
                      isRequired: true
                    },
                    component: 'TextInput',
                    source: 'agreementNumber'
                  }
                ]
              }
            ]
          },
          // row 3
          {
            options: rowOptions,
            contents: [
              {
                options: { direction: 'column' },
                contents: [
                  {
                    options: {
                      label: 'Customer',
                      required: true,
                      marginRight: 10,
                      placeholder: 'Select Customer',
                      searchIndexName: searchIndex,
                      searchResultDisplayFields: ['customerName'],
                      setItemName: 0,
                      filters: [
                        {
                          attribute: 'entityType',
                          valueArray: ['Customer']
                        },
                        {
                          parenthetical: true,
                          operand: 'OR',
                          attributeArray: ['status', 'parentStatus'],
                          valueArray: [AppConstants.ACTIVE, AppConstants.ACTIVE]
                        }
                      ],
                      valuesToSet: [{ customerId: 'id', customerName: 'customerName' }]
                    },
                    component: 'AlgoliaSearchWrapper',
                    source: 'customerName'
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  };
};
