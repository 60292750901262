import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ceil } from 'lodash';
import { PDFViewer, Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { Modal } from '@buildhero/sergeant';

import { usePayAppTableData, usePayApplicationSummaryTotals } from 'services/APIHooks';

import { getFinalBillingCustomerAddress } from '@pm/components/utils';
import attachmentsToCanvas from 'scenes/ProjectManagement/components/attachmentsToCanvas';

import ApplicationCertification from './components/ApplicationCertification';
import ContractApplicationLeftside from './components/ContractApplicationLeftside';
import ContractApplicationRightside from './components/ContractApplicationRightside';
import VerifierCertificate from './components/VerifierCertificate';
import ContinuationSheetHeader from './components/ContinuationSheetHeader';
import ContinuationSheetDescriptions from './components/ContinuationSheetDescriptions';
import ContinuationSheetTableHeaders from './components/ContinuationSheetTableHeaders';
import ContinuationSheetTableRows from './components/ContinuationSheetTableRows';
import ContinuationSheetTableRowForChangeOrderTitle from './components/ContinuationSheetTableRowForChangeOrderTitle';
import ContinuationSheetTableRowsForChangeOrders from './components/ContinuationSheetTableRowsForChangeOrders';
import ContinuationSheetTableGrandTotal from './components/ContinuationSheetTableGrandTotal';

import styles from './GeneratePdfModal.styles';

import {
  getAddress1,
  getAddress2,
  getEmployeeAddress1,
  getEmployeeAddress2,
  getPayAppLineItemTotals
} from './GeneratePdfModal.selectors';

export const PayApplicationPdf = ({ payAppSummary, project, tableData, payAppTotals, company }) => {
  const payApplication = payAppSummary?.PayApplication;
  const title = `Pay Application ${payApplication.number || ''}`;
  const billingCustomerAddress = getFinalBillingCustomerAddress(project?.billingCustomerAddress);

  const companyAddress = company.companyAddresses.items.find(
    address => address.addressType === 'billingAddress'
  );

  const trimedData = tableData.map(data => ({
    ...data,
    description: data.description.replace(/\s+/g, ' ').trim()
  }));
  const sovLineItems = trimedData.filter(line => line.entityType === 'ScheduleOfValueLine');
  const coLineItems = trimedData.filter(line => line.entityType === 'ChangeOrderLineItem');

  const totals = getPayAppLineItemTotals({ tableData, payAppTotals });

  const onePageDisplay = sovLineItems.length + coLineItems.length < 12;
  const sovPages = 1 + ceil((sovLineItems.length - 11) / 14);
  const coPages = ceil(coLineItems.length / 18);
  return (
    <Document title={title}>
      {/* THE FIRST PAGE: APPLICATION AND CERTIFICATION FOR PAYMENT */}
      <Page size="LETTER" orientation="landscape" style={styles.body} wrap={false}>
        <Text style={[styles.title, { paddingLeft: 7 }]}>
          APPLICATION AND CERTIFICATION FOR PAYMENT
        </Text>
        <View style={styles.root}>
          <ApplicationCertification
            project={project}
            payApplication={payApplication}
            repCompany={project?.billingCustomer?.customerName || ''}
            street={getAddress1(billingCustomerAddress)}
            cityState={getAddress2(billingCustomerAddress)}
            customerStreet={getEmployeeAddress1(companyAddress)}
            customerCityState={getEmployeeAddress2(companyAddress)}
            customerCompany={company?.companyName || ''}
          />
        </View>
        <View style={styles.secondRoot}>
          <View style={styles.secondInnerLeft}>
            <ContractApplicationLeftside
              tableData={tableData}
              totals={totals}
              payAppSummary={payAppSummary}
            />
          </View>
          <View style={styles.secondInnerRight}>
            <ContractApplicationRightside />
            <VerifierCertificate />
          </View>
        </View>
      </Page>
      {/* THE SECOND PAGE: CONTINUATION SHEET */}
      <Page size="LETTER" orientation="landscape" style={styles.body}>
        {ContinuationSheetHeader}
        <View style={styles.normal}>
          <ContinuationSheetDescriptions project={project} payApplication={payApplication} />
          {/* TABLE */}
          <View style={[styles.tableBorder, styles.marginTop10]}>
            <ContinuationSheetTableHeaders />
            <ContinuationSheetTableRows sovLineItems={sovLineItems.splice(0, 11)} />
            {onePageDisplay && (
              <>
                {Boolean(coLineItems?.length) && <ContinuationSheetTableRowForChangeOrderTitle />}
                <ContinuationSheetTableRowsForChangeOrders coLineItems={coLineItems} />
                <ContinuationSheetTableGrandTotal tableData={tableData} totals={totals} />
              </>
            )}
          </View>
          <View style={[styles.displayFlex, styles.marginTop10, { flexWrap: 'wrap' }]}>
            {payApplication?.PayApplicationAttachment?.filter(
              attachment => attachment.fileType.includes('image') && !attachment.deletedDate
            ).map(image => (
              <Image
                src={image.fileUrl}
                style={{ maxWidth: '19%', maxHeight: 70, marginRight: 3, marginBottom: 3 }}
              />
            ))}
          </View>
        </View>
      </Page>
      {/* SOV PAGES: ORIGINAL CONTRACT FOLLOWUP (IF NOT ONE-PAGE DISPLAY) */}
      {sovPages > 1 &&
        [...Array(sovPages - 1)].map(() => (
          <Page size="LETTER" orientation="landscape" style={styles.body}>
            {ContinuationSheetHeader}
            <View style={styles.normal}>
              <View style={[styles.tableBorder, styles.marginTop10]}>
                <ContinuationSheetTableHeaders />
                <ContinuationSheetTableRows sovLineItems={sovLineItems.splice(0, 14)} />
                {sovLineItems.length === 0 && coPages === 0 && (
                  <ContinuationSheetTableGrandTotal tableData={tableData} totals={totals} />
                )}
              </View>
            </View>
          </Page>
        ))}
      {/* CO PAGES: CHANGE ORDER PAGES (IF NOT ONE-PAGE DISPLAY) */}
      {coPages > 0 &&
        !onePageDisplay &&
        [...Array(coPages)].map(() => (
          <Page size="LETTER" orientation="landscape" style={styles.body}>
            {ContinuationSheetHeader}
            <View style={styles.normal}>
              <View style={[styles.tableBorder, styles.marginTop10]}>
                <ContinuationSheetTableRowForChangeOrderTitle />
                <ContinuationSheetTableRowsForChangeOrders
                  coLineItems={coLineItems.splice(0, 18)}
                />
                {coLineItems.length === 0 && (
                  <ContinuationSheetTableGrandTotal tableData={tableData} totals={totals} />
                )}
              </View>
            </View>
          </Page>
        ))}
      {payAppSummary.canvasAttachments?.flat()?.map(attachment => (
        <Page size="letter" wrap={false}>
          <View style={styles.normal}>
            <Image src={attachment} />
          </View>
        </Page>
      ))}
    </Document>
  );
};

const GeneratePdfModal = ({ open, onClose, payAppSummary, project }) => {
  const payApplication = payAppSummary?.PayApplication;
  const title = `Pay Application ${payApplication.number || ''}`;
  const company = useSelector(state => state.company);

  const [payAppTableDataResponse] = usePayAppTableData(
    { projectId: project.id, paNumber: payApplication.number },
    {
      skip: !(open && project.id && payApplication.number),
      depends: [open, project.id, payApplication.number],
      defaultData: [],
      transform: data => {
        return data.filter(li => !li.dateApproved || li.dateApproved <= payApplication.periodTo);
      }
    }
  );

  const [payApplicationSummaryTotals] = usePayApplicationSummaryTotals(
    { projectId: project.id, paNumber: payApplication.number },
    {
      skip: !(open && project.id && payApplication.number),
      depends: [open, project.id, payApplication.number],
      defaultData: []
    }
  );

  const tableData = payAppTableDataResponse.data;
  const payAppTotals = payApplicationSummaryTotals.data;

  if (payAppTableDataResponse.loading || payApplicationSummaryTotals.loading) return null;

  return (
    <Modal open={open} fullScreen onClose={onClose} title={title}>
      <PDFViewer width="100%" height="100%">
        <PayApplicationPdf {...{ payAppSummary, project, tableData, payAppTotals, company }} />
      </PDFViewer>
    </Modal>
  );
};

export default GeneratePdfModal;
