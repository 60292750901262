import React from 'react';
import { func, bool } from 'prop-types';

import { STATUS_PROP } from '@dispatch/Dispatch.constants';

import { useStyles } from '../VisitsTabs.styles';

const TabHeader = ({ active, Status, onClick }) => {
  const styles = useStyles({ active, Status });

  return (
    <div css={styles.tabHeader} onClick={onClick} onKeyPress={onClick} role="button" tabIndex="0">
      {Status.value.displayValue}
    </div>
  );
};

TabHeader.propTypes = {
  active: bool.isRequired,
  Status: STATUS_PROP.isRequired,
  onClick: func.isRequired
};

export default TabHeader;
