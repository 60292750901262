export const LABOR_CONSTANTS = {
  estimationMsg:
    "Estimate the labor hours this task will cost you and enter the amount of labor hours you want to charge the customer for. This will help calculate the quote's profitability",
  billingHourTypeId: 'billingHourTypeId',
  billingRates: 'quoteLineLaborBillingHours',
  payrollCosts: 'quoteLineLaborPayrollHours',
  payrollHourTypeId: 'payrollHourTypeId'
};

export const LABOR_FIELDS = {
  AVERAGE_UNIT_COST: 'unitCost',
  AVERAGE_UNIT_PRICE: 'unitPrice',
  BILLING_RATES: 'quoteLineLaborBillingHours',
  COST_QUANTITY: 'costQuantity',
  MARKUP: 'markup',
  MARGIN: 'margin',
  QUANTITY: 'quantity',
  TOTAL_COST: 'totalCost',
  TOTAL_PRICE: 'totalPrice'
};
