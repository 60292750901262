import gql from 'graphql-tag';

const getReviewReportReceiptsByJobNumber = gql`
  query getReviewReportReceiptsByJobNumber($jobNumber: String!) {
    getJobByJobNumber(jobNumber: $jobNumber) {
      id
      jobNumber
      visits: visitsView(entityConnection: "Visit") {
        items {
          visitNumber
          id
          status
          reviewReports(entityConnection: "ReviewReport") {
            items {
              id
              parentId
              status
            }
          }
        }
      }
      purchaseOrderNumbers(entityConnection: "PurchaseOrderNumber") {
        items {
          id
          value
        }
      }
      purchaseOrders(entityConnection: "PurchaseOrder") {
        items {
          id
          vendorName
          receiptNumber
          poNumber
          createdBy
          parentId
          addedBy
          dateOfPurchase
          totalAmountPreTax
          tax
          version
          purchaseOrderReceipts(entityConnection: "PurchaseOrderReceipt") {
            items {
              id
              fileName
              imageUrl
            }
          }
          purchaseOrderLines(entityConnection: "PurchaseOrderLine") {
            items {
              id
              itemName
              quantity
              unitCost
              markup
              unitPrice
              amount
              createdBy
              includeInInvoice
              taxable
              version
              description
            }
          }
        }
      }
    }
  }
`;

export default getReviewReportReceiptsByJobNumber;
