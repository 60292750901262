const styles = theme => ({
  listStyle: {
    borderColor: theme.palette.grayscale(85),
    border: '1px solid',
    borderLeft: 'none',
    borderRight: 'none',
    '&:hover': {
      backgroundColor: theme.palette.grayscale(95)
    }
  },
  listInputStyle: {
    padding: 5
  },
  listTextStyle: {
    fontSize: 14,
    color: theme.palette.grayscale(25)
  },
  listIcon: {
    padding: 5
  },
  deleteIconStyle: {
    fontSize: 18
  },
  inputStyle: {
    height: 38
  },
  cssUnderline: {
    '&:before': {
      borderBottomColor: theme.palette.grayscale(95)
    },
    '&:after': {
      borderBottomColor: theme.palette.brand.logoBlue
    },
    '&&&&:hover:before': {
      borderBottom: `1px solid ${theme.palette.brand.logoBlue}`
    },
    backgroundColor: theme.palette.grayscale(94),
    paddingLeft: 8
  },
  linkStyles: {
    color: 'inherit',
    textDecoration: 'none'
  },
  linkText: {
    fontWeight: 'normal',
    paddingTop: 15.5
  },
  linkIcon: {
    fontSize: 12
  },

  buttonOutlinedSecondary: {
    border: 'none',
    textTransform: 'unset',
    padding: 0,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.3,
    '&:hover': {
      border: 'none',
      backgroundColor: theme.palette.grayscale(100)
    },
    '&:disabled': {
      border: 'none',
      backgroundColor: theme.palette.grayscale(100)
    }
  }
});

export default styles;
