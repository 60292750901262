import gql from 'graphql-tag';

const deleteQuoteLineTask = gql`
  mutation deleteQuoteLineTask($partitionKey: String!, $id: String!) {
    deleteQuoteLineTask(partitionKey: $partitionKey, id: $id) {
      id
    }
  }
`;

export default deleteQuoteLineTask;
