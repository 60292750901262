import labels from 'meta/Scheduler/labels';

const getLayout = ({
  locale,
  enableAssetDropDown,
  departments,
  crews,
  technicians,
  formOptions,
  assetOptions,
  disableSchedule,
  restrictedEdits,
  jobDepartments,
  isMultiVisits
}) => {
  const color = 'primary';
  const padding = 12;

  return {
    fields: {},
    layouts: {
      edit: {
        options: {
          pageSize: 'LETTER'
        },
        contents: [
          // start of Description
          {
            options: {
              padding
            },
            contents: [
              {
                component: 'TextInput',
                source: 'description',
                options: {
                  label: labels.newVisit_description_title[locale],
                  placeholder: labels.newVisit_description_fieldName[locale],
                  color
                }
              }
            ]
          },
          // end of Description

          // start of TO DO Actions
          {
            options: {
              padding
            },
            contents: [
              {
                component: 'TextInput',
                source: 'prerequisites',
                options: {
                  label: 'TO DO - Actions before dispatch. Leave empty if there are no actions',
                  placeholder: 'TO DO',
                  color,
                  lines: 3
                }
              }
            ]
          },
          // end of TO DO Actions

          // start of Assets and Forms row
          {
            options: {
              direction: 'row'
            },
            contents: [
              // start of Assets
              enableAssetDropDown
                ? {
                    options: {
                      padding,
                      grow: 1
                    },
                    contents: [
                      {
                        component: 'SelectInput',
                        source: 'assetIds',
                        options: {
                          label: labels.newVisit_selectAssets_Label[locale],
                          placeholder: labels.newVisit_selectAssets_Title[locale],
                          color,
                          isMultipleSelection: true,
                          isSearchable: true,
                          valueSet: assetOptions
                        }
                      }
                    ]
                  }
                : {
                    component: null
                  },
              // end of Assets

              // start of Forms
              {
                options: {
                  padding,
                  grow: 1
                },
                contents: [
                  {
                    component: 'SelectInput',
                    source: 'formIds',
                    options: {
                      label: labels.newVisit_selectForms_selectLabel_Title[locale],
                      placeholder: labels.newVisit_selectForms_selectLabel[locale],
                      color,
                      isMultipleSelection: true,
                      isSearchable: true,
                      valueSet: formOptions
                    }
                  }
                ]
              }
              // end of Forms
            ]
          },
          // end of Assets and Forms row

          // start of Preferred Technicians Form
          {
            options: {
              direction: 'row',
              padding
            },
            contents: [
              {
                component: 'PreferredTechniciansFormLayoutComponent',
                source: 'preferredTechnicians',
                options: {
                  departments,
                  crews,
                  technicians,
                  jobDepartments,
                  getRequiredFields: () => ({
                    departmentId: true
                  }),
                  restrictedEdits
                }
              }
            ]
          },
          // end of Preferred Technicians Form

          {
            options: {
              direction: 'row'
            },
            contents: [
              {
                options: {
                  padding,
                  hide: disableSchedule,
                  grow: isMultiVisits ? 1.58 : 1
                },
                contents: [
                  {
                    component: 'DateInput',
                    source: 'visitDate',
                    options: {
                      label: isMultiVisits ? 'Start Date' : 'Date',
                      type: 'date',
                      isRequired: isMultiVisits
                    }
                  }
                ]
              },
              {
                options: {
                  padding,
                  hide: disableSchedule || !isMultiVisits
                },
                contents: [
                  {
                    component: 'DateInput',
                    source: 'endDate',
                    options: {
                      label: 'End Date',
                      type: 'date',
                      isRequired: isMultiVisits
                    }
                  }
                ]
              },
              {
                options: {
                  padding,
                  hide: disableSchedule
                },
                contents: [
                  {
                    component: 'TimeInput',
                    source: 'visitTime',
                    options: {
                      label: 'Time',
                      color
                    }
                  }
                ]
              },
              {
                options: {
                  padding,
                  hide: disableSchedule
                },
                contents: [
                  {
                    component: 'DurationInput',
                    source: 'visitDuration',
                    options: {
                      label: 'DURATION',
                      color
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              direction: 'row',
              hide: !isMultiVisits
            },
            contents: [
              {
                options: {
                  padding,
                  hide: disableSchedule || !isMultiVisits
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'excludeWeekend',
                    options: {
                      label: 'Exclude Weekends',
                      color
                    }
                  }
                ]
              },
              // below creates empty div, otherwise the exclude weekends was clickable for the whole row - behavior of mui
              {
                options: {
                  padding,
                  grow: 3
                },
                contents: []
              }
            ]
          }
        ]
      }
    }
  };
};

export default getLayout;
