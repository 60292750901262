import gql from 'graphql-tag';

const getPartsPurchasingTablesData = gql`
  query getPartsPurchasingTablesData($jobId: String!, $jobSortKey: String!) {
    getJob(partitionKey: $jobId, sortKey: $jobSortKey) {
      billsTableData {
        items {
          createdBy
          billId
          billNumber
          dateOfPurchase
          invoicedStatus
          poNumber
          purchaseOrderId
          receiptId
          receiptNumber
          subtotal
          totalCost
          vendorName
          visitNumbers
        }
      }
      purchaseOrderLines(limit: 10000, filter: { status: { ne: "Void" } }) {
        items {
          id
          description
          syncStatus
          status
          quantity
          quantityFulfilled
          unitCost
          unitPrice
          parentId
          purchaseOrder {
            poNumber
            id
            vendorName
          }
        }
      }
      visits {
        items {
          visitNumber
          primaryTechs {
            items {
              employee: mappedEntity {
                ... on Employee {
                  firstName
                  lastName
                }
              }
            }
          }
          inventoryParts(limit: 10000) {
            items {
              id
              itemName
              description
              unitCost
              quantity
            }
          }
          reviewReports {
            items {
              inventoryParts(limit: 10000) {
                items {
                  id
                  itemName
                  description
                  unitCost
                  quantity
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getPartsPurchasingTablesData;
