import gql from 'graphql-tag';

const getVisitsByJobNumber = gql`
  query getVisitsByJobNumber($jobNumber: String!) {
    getJobByJobNumber(jobNumber: $jobNumber) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      tenantId
      hierarchy
      entityType
      jobNumber
      customIdentifier
      visits: visitsView(entityConnection: "Visit") {
        items {
          visitNumber
          departmentName
          departmentId
          accountingRefIdOfClass
          description
          prerequisites
          prerequisitesAcknowledged
          version
          status
          techReport
          startTime
          endTime
          scheduledFor
          hierarchy
          partitionKey
          sortKey
          onHold
          onHoldReason
          onRoute
          delayed
          delayedReason
          detailsSent
          tentativeDate
          extraTechsRequired
          extraTechsNumber
          minimumDuration
          actualDuration
          tentativePeriod
          tentativeDate
          tentativeTime
          lastUpdatedBy
          lastUpdatedDateTime
          submittedBy
          submittedDate
          id
          entityType
          parentId
          submittedBy
          submittedDate
          reviewStatus
          reviewedBy
          reviewedUnixDate
          schedules(entityConnection: "Schedule") {
            items {
              id
              employeeSortKey
              parentId
              hierarchy
              entityType
              version
              tenantId
              tenantCompanyId
              partitionKey
              parentSortKey
              sortKey
              entityType
              scheduledFor
              startTime
              endTime
              onRoute
              delayed
              delayedReason
              onHold
              onHoldReason
              timeSheets(entityConnection: "TimeSheet") {
                items {
                  visitDate
                  clockInTime
                  clockOutTime
                  parentId
                  hierarchy
                  id
                  entityType
                  version
                  tenantId
                  tenantCompanyId
                  partitionKey
                  parentSortKey
                  sortKey
                }
              }
            }
          }
          visitAssets(entityConnection: "VisitAsset", filter: { propertyAssetId: { ne: "null" } }) {
            items {
              id
              propertyAsset(entityConnection: "PropertyAsset") {
                id
                assetName
                make
                modelNumber
                serialNo
                installDate
                location
                propertyZone
                imageUrl
              }
            }
          }
          primaryTechs(entityConnection: "VisitPrimaryTech") {
            items {
              sortKey
              invertedSortKey
              mappedEntity {
                ... on Employee {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  partitionKey
                  hierarchy
                  entityType
                  firstName
                  lastName
                  name
                  email
                }
              }
            }
          }
          extraTechs(entityConnection: "VisitExtraTech") {
            items {
              id
              sortKey
              invertedSortKey
              mappedEntity {
                ... on Employee {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  entityType
                  sortKey
                  hierarchy
                  entityType
                  firstName
                  lastName
                  email
                }
              }
            }
          }
          summaries(entityConnection: "Summary") {
            items {
              id
              summary
              lastUpdatedBy
              lastUpdatedDateTime
            }
          }
          reviewReports(entityConnection: "ReviewReport") {
            items {
              id
              sortKey
              status
              lastUpdatedBy
              lastUpdatedDateTime
              submittedDate
            }
          }
          formData(entityConnection: "FormData") {
            items {
              id
              status
              formDataJson
              formSortKey
              formId
              formDefinitionSortKey
              formDefinitionId
            }
          }
        }
      }
    }
  }
`;

export default getVisitsByJobNumber;
