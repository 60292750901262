import React, { useEffect, useState } from 'react';

import { Button, ButtonType, Divider, ThemeProvider } from '@buildhero/sergeant';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useSelector } from 'react-redux';

import JobIcon from 'assets/Icons/Jobs';
import ProcurementIcon from 'assets/Icons/Procurement';
import ProjectIcon from 'assets/Icons/Projects';

import theme from 'themes/BuildHeroTheme';
import { QuoteStatus } from 'utils/AppConstants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import QuotesUtils from '../../../index.utils';

import AddButtonUtils from './index.utils';

const style = {
  iconRoot: {
    color: theme.palette.grayscale(20),
    '& [class^="MuiSvgIcon"]': {
      marginRight: 8
    }
  }
};

const AddButton = ({
  handleAddToJob,
  isLoading,
  quoteAttachments,
  quoteStatus,
  setIsAddProjectModalOpen,
  setOpenExistingJobModal,
  setOpenNewJobModal,
  setIsAddPurchaseOrderModalOpen,
  quoteInfo,
  isCustomJobNumberEnabled
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isPurchaseOrderDisabled, setIsPurchaseOrderedDisabled] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isQuoteToPoEnabled = useSelector(state => state.settings.isQuoteToPoEnabled);
  const appPermissionRules = useSelector(state => state.user.appPermissionRules);
  const flags = useFlags();
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const allowedStatesToCreatePO = [
      QuoteStatus.APPROVED,
      QuoteStatus.CUSTOMER_VIEWED,
      QuoteStatus.DRAFT,
      QuoteStatus.EMAIL_BOUNCED,
      QuoteStatus.EMAIL_READ,
      QuoteStatus.SENT_TO_CUSTOMER
    ];

    const quoteFromJobCompletedStates = [QuoteStatus.APPROVED, QuoteStatus.JOB_ADDED];

    const quoteJobExists =
      flags[FeatureFlags.JOB_QUOTE_STATUS] && QuotesUtils.quoteHasJob(quoteInfo);

    const quoteCreatedFromJobAndCompleted =
      quoteJobExists && quoteFromJobCompletedStates.includes(quoteStatus);

    if (
      !quoteCreatedFromJobAndCompleted ||
      (allowedStatesToCreatePO.includes(quoteStatus) && !quoteJobExists)
    ) {
      setIsPurchaseOrderedDisabled(false);
    } else {
      setIsPurchaseOrderedDisabled(true);
    }
    if (!quoteCreatedFromJobAndCompleted && quoteStatus === QuoteStatus.APPROVED) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [quoteStatus, quoteInfo]);

  const options = [
    {
      disabled: isDisabled || isLoading,
      icon: JobIcon(),
      label: 'New Job',
      onClick: () =>
        (flags[FeatureFlags.QUOTE_ATTACHMENTS_ENHANCEMENTS] && quoteAttachments?.items?.length) ||
        isCustomJobNumberEnabled
          ? setOpenNewJobModal(true)
          : handleAddToJob()
    },
    ...(flags[FeatureFlags.PROJECT_MANAGEMENT] &&
    AddButtonUtils.hasProjectPermissions(appPermissionRules)
      ? [
          {
            disabled: isDisabled,
            icon: ProjectIcon(),
            label: 'New Project',
            onClick: () => setIsAddProjectModalOpen(true)
          }
        ]
      : []),
    ...(isQuoteToPoEnabled && AddButtonUtils.hasQuoteToPoPermissions(appPermissionRules)
      ? [
          {
            disabled: isPurchaseOrderDisabled,
            icon: ProcurementIcon(),
            label: 'New Purchase Order',
            onClick: () => setIsAddPurchaseOrderModalOpen(true)
          }
        ]
      : []),
    {
      menuItemStyle: { '&:hover': { backgroundColor: 'transparent', cursor: 'default' } },
      listStyle: { width: '100%', cursor: 'default' },
      icon: <Divider margin={4} />
    },
    {
      disabled: isDisabled,
      label: 'Add to Existing Job',
      onClick: () => setOpenExistingJobModal(true)
    }
  ];

  return (
    <ThemeProvider>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        endIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
        style={{ marginRight: 12 }}
        type={ButtonType.SECONDARY}
      >
        Add
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {options.map(option => (
          <MenuItem disabled={option.disabled} onClick={option.onClick} css={option.menuItemStyle}>
            <ListItemIcon css={style.iconRoot} style={option.listStyle}>
              {option.icon}
              <ListItemText>{option.label}</ListItemText>
            </ListItemIcon>
          </MenuItem>
        ))}
      </Menu>
    </ThemeProvider>
  );
};

export default AddButton;
