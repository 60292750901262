const styles = theme => ({
  avatar: {
    marginLeft: 10,
    marginRight: 10
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60
  },
  profileIcon: {
    color: '#d8d8d8',
    fontSize: 40,
    paddingRight: 9
  }
});
export default styles;
