import React from 'react';
import { ThemeProvider } from '@buildhero/sergeant';
import { ReportTableSectionDefaultProps, ReportTableSectionPropTypes } from '../../propTypes';
import { selectPurchasesItems } from '../../selectors';
import ReportSection from '../ReportSection';
import InventoryItems from './components/InventoryItemsSection';
import PurchasedItems from './components/PurchasedItems';
import { useStyles } from './PartsAndMaterialsSection.styles';

const PartsAndMaterialsSection = ({ visit, loading, error }) => {
  const styles = useStyles();
  const title = 'Parts & Materials';
  const purchasedItems = selectPurchasesItems(visit) || [];

  return (
    <ThemeProvider>
      <ReportSection title={title}>
        <div css={styles.subSectionContainer}>
          <InventoryItems items={visit?.inventoryParts} loading={loading} error={error} />
        </div>
        <div css={styles.subSectionContainer}>
          <PurchasedItems items={purchasedItems} loading={loading} error={error} />
        </div>
      </ReportSection>
    </ThemeProvider>
  );
};

PartsAndMaterialsSection.propTypes = ReportTableSectionPropTypes;
PartsAndMaterialsSection.defaultProps = ReportTableSectionDefaultProps;

export default PartsAndMaterialsSection;
