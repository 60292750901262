import { useStyles } from '@dispatch/components/VisitsTabs/VisitsTabs.styles';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const TechniciansList = ({ record }) => {
  const classes = useStyles();
  if (Array.isArray(record?.extraTechs)) {
    return <Typography className={classes.TableCell}>{record.extraTechs.join(', ')}</Typography>;
  }
  return null;
};

TechniciansList.propTypes = {
  record: PropTypes.object.isRequired
};

export default memo(TechniciansList);
