import React, { useState, useCallback, useEffect, useRef } from 'react';
import compose from 'utils/compose';
import { DispatchTheme, ElementSizes } from '@dispatch/Dispatch.styles';
import { withDispatchStore, withQueryStringStore } from '@dispatch/Dispatch.store';
import { useStyles } from './BoardLayout.styles';

export const FullScreenContainer = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.fullScreenContainer} {...props}>
      {children}
    </div>
  );
};

export const Header = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <section className={classes.header} {...props}>
      <div className={classes.headerContents}>{children}</div>
    </section>
  );
};

export const Board = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <section className={classes.board} {...props}>
      {children}
    </section>
  );
};

const resizeTrayTable = () => window.dispatchEvent(new Event('resize'));

const mapBottomTrayStore = store => ({
  trayOpen: store.state.trayOpen,
  openTray: store.openTray,
  closeTray: store.closeTray
});

export const BottomTray = withQueryStringStore(mapBottomTrayStore)(
  ({ children, trayOpen, openTray, closeTray, ...props }) => {
    const classes = useStyles();
    const [trayHeight, setTrayHeight] = useState(ElementSizes.minTrayHeight);
    const isDragging = useRef(false);

    const handleMouseMove = useCallback(e => {
      if (isDragging.current) {
        const newHeight = document.body.offsetHeight - e.clientY;
        if (newHeight > ElementSizes.minTrayHeight && newHeight < ElementSizes.maxTrayHeight) {
          setTrayHeight(newHeight);
        }
      }
    }, []);

    const handleMouseDown = useCallback(() => {
      isDragging.current = true;
    }, []);

    const handleMouseUp = useCallback(() => {
      if (isDragging.current) {
        isDragging.current = false;
        resizeTrayTable();
      }
    }, []);

    useEffect(() => {
      document.addEventListener('mousemove', handleMouseMove, true);
      document.addEventListener('mouseup', handleMouseUp, true);

      return () => {
        document.removeEventListener('mousemove', handleMouseMove, true);
        document.removeEventListener('mouseup', handleMouseUp, true);
      };
    }, [handleMouseDown, handleMouseUp, handleMouseMove]);

    useEffect(() => {
      if (trayOpen) {
        if (!isDragging.current) {
          setTrayHeight(ElementSizes.defaultTrayHeight);
          setTimeout(resizeTrayTable, 300);
        }
      } else {
        setTrayHeight(ElementSizes.minTrayHeight);
      }
    }, [trayOpen]);

    useEffect(() => {
      if (isDragging.current && !trayOpen && trayHeight > ElementSizes.minTrayHeight + 10) {
        openTray();
      }
    }, [trayHeight, openTray, trayOpen]);

    useEffect(() => {
      if (isDragging.current && trayOpen && trayHeight < ElementSizes.minTrayHeight + 10) {
        closeTray();
      }
    }, [trayHeight, closeTray, trayOpen]);

    useEffect(() => {
      setTimeout(resizeTrayTable, 1000);
    }, []);

    return (
      <section
        className={classes.bottomTray}
        {...props}
        style={{
          flexBasis: trayHeight,
          height: trayHeight,
          transition: isDragging.current ? null : DispatchTheme.mixins.transitionSpeed
        }}
      >
        {children}
        <div className={classes.dragger} onMouseDown={handleMouseDown} />
      </section>
    );
  }
);

const mapDrawerStoreProps = store => ({
  eventType: store.state.eventType,
  eventData: store.state.eventData
});

const mapDrawerQueryStringProps = store => ({
  visitId: store.state.visitId,
  billableEventId: store.state.billableEventId,
  nonBillableEventId: store.state.nonBillableEventId,
  manDayId: store.state.manDayId
});

export const RightDrawer = compose(
  withDispatchStore(mapDrawerStoreProps),
  withQueryStringStore(mapDrawerQueryStringProps)
)(({ children, visitId, billableEventId, nonBillableEventId, manDayId, eventType, eventData }) => {
  const show =
    visitId || billableEventId || nonBillableEventId || manDayId || eventType || eventData;

  const classes = useStyles({ show });

  return (
    <div
      className={classes.drawerContainer}
      style={{
        flexBasis: show ? ElementSizes.rightDrawerWidth : 0,
        width: show ? ElementSizes.rightDrawerWidth : 0
      }}
    >
      <aside className={classes.rightDrawer}>{children}</aside>
    </div>
  );
});

export const LeftContent = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.leftContent}>{children}</div>;
};
