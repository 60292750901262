import { omit } from 'lodash';
import useExtendedMutation from 'customHooks/useExtendedMutation';
import UPDATE_FEE from 'services/core/graphql/review-report/mutations/UpdateFee';

const serializer = ({ tenantId, fee }) => {
  const payload = {
    variables: {
      partitionKey: tenantId,
      data: { ...omit(fee, ['__typename', 'createdDateTime', 'sortKey', '_rowIndex']) }
    }
  };
  return payload;
};

const optimisticResponseFactory = ({ fee }) => {
  return {
    updateFee: {
      ...fee
    }
  };
};

export const useUpdateReviewReportFee = () => {
  return useExtendedMutation(UPDATE_FEE, {
    serializer,
    optimisticResponseFactory
  });
};
