import get from 'lodash/get';
import { uniqueIdentifier } from 'utils/constants.js';

/**
 *  @description Construct dropdown / select options
 *  with the provided path values from an object array
 * *Default label is ''
 * @param {Array<object>} options
 * @param {string} labelKey
 * @param {string} valueKey
 * @returns {Array<object>}
 */
export const constructSelectOptions = (options = [], labelKey = 'name', valueKey = 'id') =>
  Array.isArray(options) &&
  options.map(option => ({
    label: get(option, labelKey, ''),
    value: get(option, valueKey),
    isActive: get(option, 'isActive')
  }));

export const filterIsActive = (list, existing) => {
  return list?.filter(x => x.isActive || x.value === existing);
};

/**
 * @description Construct dropdown / select options for contacts.
 * This is a more specialized variant of the above that assumes firstName, lastName and email address.
 * @param {Array<object>} options
 * @returns {Array<object>>}
 */
export const constructContactEmailOptions = options =>
  Array.isArray(options) &&
  options.map((option, index) => ({
    id: option.id,
    label: `${option.firstName}${option.lastName ? ` ${option.lastName}` : ''} - ${option.email}`,
    value: `${uniqueIdentifier}${index}-${option.email}`,
    isActive: option.isActive
  }));

export default constructSelectOptions;
