import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const UPDATE_LABOUR_RATE_BILLING_HOUR_LINE = gql`
  mutation bulkUpdateLabourRateBillingHourLines(
    $partitionKey: String
    $data: [UpdateLabourRateBillingHourLineInput]!
  ) {
    bulkUpdateLabourRateBillingHourLines(partitionKey: $partitionKey, data: $data) {
      id
      version
      rate
      productId
      hourTypeId
      totalHours
      costCodeId
      revenueTypeId
      timesheetEntryId
    }
  }
`;

const serializer = ({ tenantId, labourRateBillingHourLines }) => ({
  variables: {
    partitionKey: tenantId,
    data: labourRateBillingHourLines
  }
});

export const useBulkUpdateLabourRateBillingHourLines = () => {
  return useExtendedMutation(UPDATE_LABOUR_RATE_BILLING_HOUR_LINE, {
    serializer
  });
};
