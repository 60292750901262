/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getProducts() {
  return await instance
    .get('products')
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getProductById(id) {
  return await instance
    .get(`products/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function productSearch(term, searchColumns) {
  if (term.length > 0) {
    return await instance
      .post(`products/search/${encodeURIComponent(term)}`, {
        searchColumns: searchColumns || ['name']
      })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  }
  return await getProducts();
}

export async function productCreate(product) {
  return await instance
    .post('products', product)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function productChange(id, product) {
  return await instance
    .put(`products/${id}`, product)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
