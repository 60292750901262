export const useStyles = () => ({
  root: theme => ({
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: theme.default.palette.grayscale(60),
    marginTop: theme.default.spacing(2),
    marginBottom: theme.default.spacing(2),
    paddingTop: theme.default.spacing(2)
  }),
  employeeName: {
    fontWeight: 'bold'
  },
  approvedLabel: theme => ({
    margin: theme.default.spacing(1)
  })
});
