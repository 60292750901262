import gql from 'graphql-tag';

const getCompanyDepartments = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      skills(entityConnection: "Skill") {
        items {
          id
          tagName
          departments(entityConnection: "Department") {
            items {
              id
              mappedEntity {
                ... on Department {
                  id
                  tagName
                }
              }
            }
          }
        }
      }
      departments: departmentsView(
        entityConnection: "Department"
        filter: {
          integerFilters: [{ fieldName: "Department.deletedDate", filterInput: { is: null } }]
        }
      ) {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          tagName
          tagType
          accountingRefIdOfClass
          skills(entityConnection: "Skill") {
            items {
              mappedEntity {
                ... on Skill {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  tagName
                  tagType
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getCompanyDepartments;
