import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './SectionHeader.styles';

const SectionHeader = props => {
  const styles = useStyles();
  return <Typography css={styles.root}>{props.children}</Typography>;
};

export default SectionHeader;
