import gql from 'graphql-tag';

const getJobReportForVisitsByJobNumber = gql`
  query(
    $jobNumber: String!
    $visitIdList: [String]
    $showJobSummary: Boolean!
    $showTaskCompleted: Boolean!
    $showLabor: Boolean!
    $showInventoryItems: Boolean!
    $showPurchasedItems: Boolean!
    $showBeforeAfterPhotos: Boolean!
    $showForms: Boolean!
    $showTaskForms: Boolean!
    $showCustomerSignature: Boolean!
  ) {
    getJobByJobNumber(jobNumber: $jobNumber) {
      id
      jobNumber
      customIdentifier
      customerProvidedPONumber
      customerProvidedWONumber
      jobTypeId
      jobTypeName
      jobTypeInternal
      amountNotToExceed
      amountQuoted
      issueDescription
      customerName
      jobTasks @include(if: $showTaskCompleted) {
        items {
          id
          task {
            id
            name
            description
            status
            asset {
              id
              assetName
            }
            formData @include(if: $showTaskForms) {
              items {
                id
                formDefinition {
                  formDefinitionJson
                }
                form {
                  id
                  name
                  description
                }
                formDataJson
                lastUpdatedDate
                lastUpdatedBy
              }
            }
          }
        }
      }
      formData(entityConnection: "FormData") {
        items {
          id
          formDataJson
          version
          createdDateTime
          lastUpdatedDateTime
          lastUpdatedBy
          sortKey
          form(entityConnection: "form") {
            id
            name
            description
            viewType
          }
        }
      }
      billingCustomer(entityConnection: "BillingCustomer") {
        id
        sortKey
        customerName
        companyAddresses(entityConnection: "CompanyAddress") {
          items {
            id
            addressType
            addressLine1
            addressLine2
            billTo
            city
            state
            zipcode
            longitude
            latitude
          }
        }
      }
      customerProperty {
        id
        companyName
        companyAddresses(entityConnection: "CompanyAddress") {
          items {
            id
            addressLine1
            addressLine2
            state
            city
            zipcode
            addressType
          }
        }
      }
      authorizedBy(entityConnection: "authorizedBy") {
        ... on CustomerRep {
          id
          firstName
          lastName
          version
          isActive
        }
      }
      property: parentEntity {
        ... on CustomerProperty {
          id
          companyName
          customer: parentEntity {
            ... on Customer {
              customerName
              id
              companyAddresses(entityConnection: "CompanyAddress") {
                items {
                  id
                  version
                  addressLine1
                  addressLine2
                  state
                  city
                  zipcode
                  entityType
                  addressType
                  billTo
                  shipTo
                }
              }
            }
          }
          companyAddresses(entityConnection: "CompanyAddress") {
            items {
              id
              version
              addressLine1
              addressLine2
              state
              city
              zipcode
              entityType
              addressType
              billTo
              shipTo
            }
          }
        }
      }
      visits(entityConnection: "Visit", filter: { id: { in: $visitIdList } }) {
        items {
          visitNumber
          id
          description
          scheduledFor
          primaryTechs(entityConnection: "VisitPrimaryTech") {
            items {
              mappedEntity {
                ... on Employee {
                  firstName
                  lastName
                }
              }
            }
          }
          actualDuration
          startTime
          endTime
          detailsSent
          primaryTechs(entityConnection: "VisitPrimaryTech") {
            items {
              id
              mappedEntity {
                ... on Employee {
                  id
                  firstName
                  lastName
                  sortKey
                }
              }
            }
          }
          extraTechs(entityConnection: "VisitExtraTech") {
            items {
              id
              mappedEntity {
                ... on Employee {
                  id
                  firstName
                  lastName
                  sortKey
                }
              }
            }
          }
          visitAssets(entityConnection: "VisitAsset", filter: { propertyAssetId: { ne: "null" } }) {
            items {
              id
              propertyAsset(entityConnection: "PropertyAsset") {
                id
                assetName
                make
                modelNumber
                serialNo
                installDate
                location
                propertyZone
                imageUrl
              }
            }
          }
          customerSignaturesPdfs: attachments(filter: { type: { eq: "customerSignature" } })
            @include(if: $showCustomerSignature) {
            items {
              id
              customFileName
              description
              comment
              fileUrl
              fileName
              numPages
            }
          }
          timeCardLinesView(entityConnection: "TimeCardLine") @include(if: $showLabor) {
            items {
              lastUpdatedBy
              lastUpdatedDateTime
              timeType
              timeTypeAbbreviation
              timeMinutes
              employeeId
              employeeName
            }
          }
          timeCardVisitsView(entityConnection: "TimeCardVisit") @include(if: $showLabor) {
            items {
              approvedBy
              approvedDateTime
              status
              employeeId
              employeeName
              notes(entityConnection: "Note") {
                items {
                  note
                  subject
                  lastUpdatedBy
                  lastUpdatedDateTime
                }
              }
            }
          }
          formData(entityConnection: "FormData") @include(if: $showForms) {
            items {
              id
              formDataJson
              version
              createdDateTime
              lastUpdatedDateTime
              lastUpdatedBy
              sortKey
              form(entityConnection: "form") {
                id
                name
                description
                viewType
              }
            }
          }
          reviewReports(entityConnection: "ReviewReport") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              tenantId
              hierarchy
              entityType
              status
              version
              createdDateTime
              lastUpdatedBy
              messageToCustomerInvoice
              includeMessageToCustomerInInvoice
              lastUpdatedDateTime
              purchaseOrders(entityConnection: "PurchaseOrder") @include(if: $showPurchasedItems) {
                items {
                  id
                  sortKey
                  vendorName
                  receiptNumber
                  poNumber
                  dateOfPurchase
                  totalAmountPreTax
                  tax
                  departmentName
                  addedBy
                  createdBy
                  version
                  purchaseOrderLines(entityConnection: "PurchaseOrderLine") {
                    items {
                      id
                      sortKey
                      itemName
                      departmentName
                      accountingRefIdOfClass
                      vendorName
                      description
                      quantity
                      unitCost
                      unitPrice
                      markup
                      amount
                      createdBy
                      includeInInvoice
                      taxable
                      version
                      priceBookEntryId
                      priceBookEntry(entityConnection: "PriceBookEntry") {
                        id
                        version
                        product(entityConnection: "Product") {
                          id
                          name
                          code
                          version
                          unitOfMeasure(entityConnection: "UnitOfMeasure") {
                            id
                            name
                          }
                        }
                      }
                    }
                  }
                  purchaseOrderReceipts(entityConnection: "PurchaseOrderReceipt") {
                    items {
                      id
                      imageUrl
                      fileName
                      createdBy
                      version
                    }
                  }
                }
              }
              inventoryParts(entityConnection: "InventoryPart") @include(if: $showInventoryItems) {
                items {
                  id
                  sortKey
                  itemName
                  departmentName
                  accountingRefIdOfClass
                  accountingRefId
                  priceBookEntryId
                  description
                  quantity
                  unitCost
                  unitPrice
                  inventoryType
                  vendorName
                  unitOfMeasure
                  markup
                  amount
                  taxable
                  totalAmountTaxable
                  tax
                  createdBy
                  includeInInvoice
                  version
                  addedBy
                  priceBookEntry(entityConnection: "PriceBookEntry") {
                    id
                    version
                    product(entityConnection: "Product") {
                      id
                      name
                      code
                      version
                      unitOfMeasure(entityConnection: "UnitOfMeasure") {
                        id
                        name
                      }
                    }
                  }
                }
              }
              summaries(entityConnection: "Summary") @include(if: $showJobSummary) {
                items {
                  id
                  sortKey
                  summary
                  version
                  includeInInvoice
                  lastUpdatedBy
                  addedBy
                  createdBy
                  lastUpdatedDateTime
                }
              }
              technicianNotes(entityConnection: "TechnicianNote") {
                items {
                  id
                  sortKey
                  createdBy
                  subject
                  note
                  createdDate
                  createdDateTime
                  addedBy
                }
              }
              discounts(entityConnection: "Discount") {
                items {
                  id
                  sortKey
                  itemName
                  reasonForDiscount
                  departmentName
                  accountingRefIdOfClass
                  taxable
                  version
                  amount
                  includeInInvoice
                }
              }
              fees(entityConnection: "Fee") {
                items {
                  id
                  sortKey
                  itemName
                  reasonForFee
                  departmentName
                  accountingRefIdOfClass
                  taxable
                  version
                  amount
                  includeInInvoice
                }
              }
              attachments(entityConnection: "Attachment") @include(if: $showBeforeAfterPhotos) {
                items {
                  id
                  sortKey
                  customFileName
                  fileName
                  fileUrl
                  type
                  dateAdded
                  description
                  comment
                  sortOrder
                  createdBy
                  createdDate
                  addedBy
                  addedDateTime
                  version
                  includeInInvoice
                  addedBy
                }
              }
              customerSignatures(entityConnection: "CustomerSignature") {
                items {
                  id
                  nameOfSignee
                  signatureImageUrl
                  signedDateTime
                  capturedBy
                  includeInInvoice
                  createdBy
                  createdDate
                  version
                }
              }
              laborLineItems(entityConnection: "LaborLineItem") @include(if: $showLabor) {
                items {
                  id
                  sortKey
                  itemName
                  description
                  taxable
                  departmentName
                  accountingRefIdOfClass
                  billableTimeInHours
                  hourlyCharge
                  includeInInvoice
                  version
                }
              }
              labourRateLineItems(entityConnection: "LabourRateLineItem") @include(if: $showLabor) {
                items {
                  id
                  employee(entityConnection: "Employee") {
                    firstName
                    lastName
                  }
                  labourTypeId
                  labourType(entityConnection: "LabourType") {
                    name
                  }
                  version
                  createdDate
                  createdDateTime
                  labourRateBillingHourLines(entityConnection: "LabourRateBillingHourLine") {
                    items {
                      id
                      rate
                      version
                      hourTypeId
                      hourType(entityConnection: "BillingHourType") {
                        hourType
                        hourTypeAbbreviation
                        createdDate
                      }
                      totalHours
                    }
                  }
                }
              }
              reviewReportBillItems @include(if: $showPurchasedItems) {
                items {
                  quantity
                  unitCost
                  unitPrice
                  description
                  billLine {
                    product {
                      description
                    }
                    bill {
                      purchaseOrder {
                        vendorName
                        poNumber
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getJobReportForVisitsByJobNumber;
