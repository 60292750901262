import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import ResponsiveTable from 'components/ResponsiveTable';
import { DefaultButton, SergeantModal } from 'components';
import simpleRowActionButtons from 'meta/ProjectManagement/global/simpleTableRowActionButton';
import { getCostCodes } from 'services/API/costCode';
import {
  getProjectCostCodesByProject,
  projectCostCodeChange,
  projectCostCodeDelete,
  projectCostCodeCreate
} from 'services/API/projectCostCode';
import { getProjectById } from 'services/API/project';

import { costRowMeta, costLayout } from './layout';
import { getCostFieldsValidationSchema } from './utils';
import Phases from './Phases';

const useStyles = makeStyles(() => ({
  root: {
    margin: '31px 0px 20px 17px'
  },
  tableHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  tableHeader: {
    fontSize: '20px',
    fontWeight: 'bold'
  }
}));

const Budget = props => {
  const { projectId, contractValue } = props;
  const [costCodes, setCostCodes] = useState([]);
  const [costCodeModal, setCostCodeModal] = useState(false);
  const [isCostCodeEditMode, setIsCostCodeEditMode] = useState(false);
  const [selectEditCostCode, setEditCostCode] = useState({});

  const update = () => {
    getProjectById(projectId).then(project => {
      setCostCodes(project.costCodes || []);
    });
  };

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const classes = useStyles();

  const getAllCodes = async () => {
    const allCostCodes = await getCostCodes();
    const projectCostCodes = await getProjectCostCodesByProject(projectId);
    if (allCostCodes) {
      await Promise.all(
        allCostCodes
          .filter(code => !projectCostCodes.find(pCode => pCode.costCodeId === code.id))
          .map(code =>
            projectCostCodeCreate({
              name: code.name,
              description: code.description,
              projectId,
              costCodeId: code.id
            })
          )
      );
      update();
    }
  };

  const handleAddCostCodeModal = () => {
    setCostCodeModal(true);
    setIsCostCodeEditMode(false);
  };

  const [confirmDelete, setConfirmDelete] = useState({
    confirmAction: () => {},
    confirmDialog: false,
    confirmTitle: ''
  });

  const handleCancelConfirmation = () =>
    setConfirmDelete({ confirmAction: () => {}, confirmDialog: false, confirmTitle: '' });

  const deleteAction = async (record, stopLoading) => {
    await projectCostCodeDelete(record.id);
    setConfirmDelete({ confirmAction: () => {}, confirmDialog: false, confirmTitle: '' });
    update();
    stopLoading();
  };

  const handleCostCodeRowactions = async (actionType, record) => {
    if (actionType === 'remove') {
      setConfirmDelete({
        confirmAction: (data, stopLoading) => deleteAction(record, stopLoading),
        confirmDialog: true,
        confirmTitle: `Delete ${record.name}`
      });
    } else if (actionType === 'edit') {
      setEditCostCode(record);
      setCostCodeModal(true);
      setIsCostCodeEditMode(true);
    }
  };

  const handleEditCostCode = async (newItem, stopLoading) => {
    const newCode = {
      name: newItem?.name,
      description: newItem?.description
    };
    await projectCostCodeChange(selectEditCostCode.id, newCode);
    update();
    stopLoading();
  };

  const handleSaveNewCostCode = async (newItem, stopLoading) => {
    const newCode = {
      name: newItem?.name,
      description: newItem?.description
    };
    await projectCostCodeCreate({ ...newCode, projectId });
    update();
    stopLoading();
  };

  const getFormattedCostCode = data => ({
    name: data?.name || '',
    description: data?.description || ''
  });

  return (
    <div className={classes.root}>
      {/* Cost Code section */}
      <div className={classes.tableHeaderContainer}>
        <Typography className={classes.tableHeader}>Cost Code</Typography>
        <div>
          <DefaultButton
            label="Add Cost Code"
            onClick={handleAddCostCodeModal}
            variant="containedPrimary"
            style={{ float: 'right', height: 30, fontSize: 12 }}
            buttonProps={{ startIcon: <AddCircleOutlineIcon style={{ fontSize: 14 }} /> }}
          />
          <DefaultButton
            label="Import Cost Code"
            onClick={() => getAllCodes()}
            variant="containedSecondary"
            style={{ float: 'right', marginRight: '8px', height: 30, fontSize: 12 }}
          />
        </div>
      </div>
      <ResponsiveTable
        rowMetadata={costRowMeta}
        data={costCodes}
        disableFilter
        rowActionButtons={simpleRowActionButtons}
        rowActions={handleCostCodeRowactions}
        noDataMsg="No Budget Entries found"
        defaults={{
          sortBy: 'name',
          sortOrder: 'asc'
        }}
      />
      {/* Phase section */}
      <Phases projectId={projectId} update={update} startingContractValue={contractValue} />
      {/* Delete confirm modal */}
      <SergeantModal
        open={confirmDelete.confirmDialog}
        title={confirmDelete.confirmTitle}
        customPrimaryButtonLabel="Delete"
        dataType="Item"
        mode="delete"
        customComponents={{}}
        handlePrimaryAction={confirmDelete.confirmAction}
        handleClose={handleCancelConfirmation}
        alignCloseRight
      />
      {/* Cost Code modal */}
      <SergeantModal
        open={costCodeModal}
        title={isCostCodeEditMode ? 'Edit Cost Code' : 'Add New Cost Code'}
        customPrimaryButtonLabel="Save"
        dataType="Item"
        mode="edit"
        data={getFormattedCostCode(selectEditCostCode)}
        layout={costLayout}
        customComponents={{}}
        handlePrimaryAction={(newItem, stopLoading) => {
          isCostCodeEditMode
            ? handleEditCostCode(newItem, stopLoading)
            : handleSaveNewCostCode(newItem, stopLoading);
          setEditCostCode({});
          setCostCodeModal(false);
        }}
        handleClose={() => {
          setEditCostCode({});
          setCostCodeModal(false);
        }}
        validationSchema={getCostFieldsValidationSchema({ costCodes, selectEditCostCode })}
        alignCloseRight
      />
    </div>
  );
};

Budget.propTypes = {
  projectId: PropTypes.string.isRequired,
  contractValue: PropTypes.number.isRequired
};

export default Budget;
