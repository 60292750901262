/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getDepartments(relations = null) {
  return await instance
    .get(relations ? `departments?include=${relations}` : `departments`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getDepartmentById(id) {
  return await instance
    .get(`departments/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getDepartmentsByJob(id) {
  return await instance
    .get(`departments/byjob/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
export async function departmentSearch(term, searchColumns) {
  const relations = 'companyAddresses';

  if (term.length > 0) {
    return await instance
      .post(`departments/search/${term}?include=${relations}`, {
        searchColumns: searchColumns || ['tagName']
      })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  }
  return await getDepartments(relations);
}

export async function departmentCreate(department) {
  return await instance
    .post('departments', department)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function departmentChange(id, department) {
  return await instance
    .put(`departments/${id}`, department)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getDepartmentsByProjectPhase(phaseId) {
  return await instance
    .get(`departments/byphase/${phaseId}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
