/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';
import { sentryException } from '../Logger/index';

export async function getDailyReports(projectId) {
  return await instance
    .get(`DailyReports/byproject/${projectId}`, {
      params: {
        include: ',',
        additionalRelations: [
          'timesheetEntryBinders.[timesheetEntries]',
          'ProductivityPhases',
          'notes.[noteSections]'
        ]
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getDailyReportById(dailyReportId) {
  return await instance
    .get(`DailyReports/${dailyReportId}`, { params: { include: '*' } })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function dailyReportCreate(dailyReport) {
  return await instance
    .post('DailyReports', dailyReport)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function dailyReportChange(id, dailyReport) {
  return await instance
    .put(`DailyReports/${id}`, dailyReport)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function dailyReportDelete(id) {
  return await instance
    .delete(`DailyReports/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to delete report');
      return [];
    });
}

export async function getNextNumber(projectId) {
  let paramsClause = {};
  if (projectId) {
    paramsClause = { projectId };
  }
  return await instance
    .get('dailyreports/next/number', {
      params: paramsClause
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return {};
    });
}

export async function getHourTypes() {
  return await instance
    .get(`payrollhourtypes/`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function addDailyReportNote(note) {
  return await instance
    .post(`/DailyReportNotes`, note)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return {};
    });
}

export async function addDailyReportNoteSection(noteSection) {
  return await instance
    .post(`/dailyReportNoteSection`, noteSection)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return {};
    });
}

export async function updateDailyReportNoteSection(id, noteSection) {
  return await instance
    .put(`/dailyReportNoteSection/${id}`, noteSection)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return {};
    });
}
