import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { GoogleApiWrapper } from 'google-maps-react';
import { GOOGLE_MAPS_API_KEY } from 'utils/AppConstants';
import Labels from 'meta/labels';
import PlacesAutoComplete from '../PlacesAutoComplete';

class AutoCompleteAddress extends React.Component {
  render() {
    const { user, specialbehaviour, form } = this.props;
    const { disableAll } = specialbehaviour;
    const { errors } = form;
    let addressLine1Error = '';
    let addressLine2Error = '';
    let cityError = '';
    let stateError = '';
    let zipcodeError = '';

    if (errors) {
      addressLine1Error = errors[specialbehaviour.addressLine1];
      addressLine2Error = errors[specialbehaviour.addressLine2];
      cityError = errors[specialbehaviour.city];
      stateError = errors[specialbehaviour.state];
      zipcodeError = errors[specialbehaviour.zipcode];
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          {disableAll ? (
            <TextField
              variant="filled"
              fullWidth
              disabled={disableAll}
              label={Labels.addressLine1[user.locale]}
              value={form.values[specialbehaviour.addressLine1] || ''}
              onChange={event =>
                this.props.form.setFieldValue(specialbehaviour.addressLine1, event.target.value)
              }
              error={Boolean(addressLine1Error)}
              helperText={addressLine1Error}
            />
          ) : (
            <PlacesAutoComplete
              {...this.props}
              disableAll={disableAll}
              label={
                specialbehaviour.isMandatory
                  ? Labels.mandatoryAddressLine1[user.locale]
                  : Labels.addressLine1[user.locale]
              }
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <TextField
            variant="filled"
            fullWidth
            disabled={disableAll}
            label={Labels.addressLine2[user.locale]}
            value={form.values[specialbehaviour.addressLine2] || ''}
            onChange={event =>
              this.props.form.setFieldValue(specialbehaviour.addressLine2, event.target.value)
            }
            error={Boolean(addressLine2Error)}
            helperText={addressLine2Error}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <TextField
            variant="filled"
            disabled={disableAll}
            fullWidth
            label={
              specialbehaviour.isMandatory
                ? Labels.mandatoryCity[user.locale]
                : Labels.city[user.locale]
            }
            value={form.values[specialbehaviour.city] || ''}
            onChange={event =>
              this.props.form.setFieldValue(specialbehaviour.city, event.target.value)
            }
            error={Boolean(cityError)}
            helperText={cityError}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <TextField
            variant="filled"
            disabled={disableAll}
            fullWidth
            label={
              specialbehaviour.isMandatory
                ? Labels.mandatoryState[user.locale]
                : Labels.state[user.locale]
            }
            value={form.values[specialbehaviour.state] || ''}
            onChange={event =>
              this.props.form.setFieldValue(specialbehaviour.state, event.target.value)
            }
            error={Boolean(stateError)}
            helperText={stateError}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <TextField
            variant="filled"
            disabled={disableAll}
            fullWidth
            label={
              specialbehaviour.isMandatory
                ? Labels.mandatoryZipcode[user.locale]
                : Labels.zipcode[user.locale]
            }
            value={form.values[specialbehaviour.zipcode] || ''}
            onChange={event =>
              this.props.form.setFieldValue(specialbehaviour.zipcode, event.target.value)
            }
            error={Boolean(zipcodeError)}
            helperText={zipcodeError}
          />
        </Grid>
      </Grid>
    );
  }
}

// export default AutoCompleteAddress;
export default GoogleApiWrapper({
  apiKey: GOOGLE_MAPS_API_KEY
})(AutoCompleteAddress);
