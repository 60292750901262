import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  icon: {
    marginRight: 3,
    position: 'relative',
    top: 1,
    height: 10,
    width: 10
  },
  text: ({ color }) => ({
    display: 'inline-block',
    fontSize: 10,
    color
  })
}));
