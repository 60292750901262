import gql from 'graphql-tag';

const deletePurchaseOrderReceipt = gql`
  mutation deletePurchaseOrderReceipt($partitionKey: String!, $id: String!) {
    deletePurchaseOrderReceipt(partitionKey: $partitionKey, id: $id) {
      id
      sortKey
      parentId
    }
  }
`;

export default deletePurchaseOrderReceipt;
