import { EventEntities, ManDayStatuses } from '@dispatch/Dispatch.constants';
import { ItemTypes } from '@dispatch/dnd';
import { CardTypes } from '@dispatch/components/DispatchBoard/DispatchBoard.constants';

const isBillableEvent = event => !!event?.assignedEntityId;

export const getEventType = event => {
  if (event.__typename === CardTypes.ManDay) {
    return EventEntities.MAN_DAY.value.clientValue;
  }

  if (isBillableEvent(event)) {
    return EventEntities.BILLABLE_EVENT.value.clientValue;
  }

  return EventEntities.NON_BILLABLE_EVENT.value.clientValue;
};

export const getDnDEventTypes = event => {
  return event.__typename === CardTypes.ManDay
    ? [ItemTypes.MAN_DAY, ItemTypes.MAN_DAY_START, ItemTypes.MAN_DAY_END]
    : [ItemTypes.NON_VISIT_EVENT, ItemTypes.EVENT_START, ItemTypes.EVENT_END];
};

export const isScheduledProjectVisitOrNonVisit = event => {
  if (event.__typename === CardTypes.ManDay) {
    return event.status === ManDayStatuses.SCHEDULED.value;
  }
  return true;
};
