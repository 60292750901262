const styles = theme => ({
  title: {
    color: theme.palette.grayscale(20),
    lineHeight: '14px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2)
  },
  titleSubText: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  markUpPercentage: {
    paddingRight: theme.spacing(2)
  },
  markUpDescription: {
    display: 'flex',
    fontSize: '12px',
    flexDirection: 'column'
  }
});

export default styles;
