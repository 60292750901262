const fileDownload = ({ data }, header, fileName, fileUrl = '') => {
  if (!data && !fileUrl) return null;

  const blobUrl =
    fileUrl ||
    window.URL.createObjectURL(
      new Blob([data], {
        type: header
      })
    );
  const link = document.createElement('a');
  link.href = blobUrl;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const excelFileDownload = ({ fileName = 'worksheet', base64Data }) => {
  if (!base64Data) {
    throw new Error('Cannot find this report');
  }
  const dataUrl = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Data}`;
  fileDownload({}, null, `${fileName}.xlsx`, dataUrl);
};

export default fileDownload;
