import { isUndefined, isNull, isObject, isArray } from 'lodash';

const mergeDeep = (base, ...updates) => {
  return updates.reduce((merged, update) => {
    if (isUndefined(update)) return merged;
    if (isUndefined(merged)) return update;
    if (isNull(update)) return update;

    if (isArray(merged) && isArray(update) && !update.replace) {
      const mergedMap = merged.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id]: curr
        }),
        {}
      );

      return update.map(updateItem => mergeDeep(mergedMap[updateItem.id], updateItem));
    }

    if (isObject(merged) && isObject(update) && !update.replace) {
      return Object.keys({ ...merged, ...update }).reduce(
        (acc, key) => ({
          ...acc,
          [key]: mergeDeep(merged[key], update[key])
        }),
        {}
      );
    }

    return update ?? merged;
  }, base);
};

export default mergeDeep;
