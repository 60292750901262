import React, { useEffect, useState } from 'react';

import { Button, ButtonType, Modal, MUIForm, ThemeProvider } from '@buildhero/sergeant';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as R from 'ramda';
import { connect } from 'react-redux';

import attachmentLayout from 'meta/attachment-seargent-layout';
import { snackbarOn } from 'redux/actions/globalActions';

import { Logger } from 'services/Logger';
import { Mode } from 'utils/constants';
import { removeEmptyValues } from 'utils/index';

import { attachmentToFileInput, fileInputToAttachment } from '../helpers';

import { CircularProgressWithLabel } from './CircularProgressWithLabel';

const Attachment = props => {
  const {
    open,
    data,
    parent,
    mutationService,
    maxFileSizeWarningLabel,
    maxFileSize,
    mode,
    snackbarOn: snackbar,
    hasShareWithTechsOption = false,
    hasInternalFileOption = false,
    title = '',
    attachmentRequired = true,
    subjectRequired = false,
    subjectLabel = 'Rename File',
    subjectPlaceholder = 'Enter another name for the file',
    descriptionLabel = 'Description',
    descriptionPlaceholder = 'Describe the file'
  } = props;
  let mutationFlag = false;
  const [attachment, setAttachment] = useState({});
  const [formService, setFormService] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState();
  const [cancelUpload, setCancelUpload] = useState();
  const flags = useFlags();
  // Initialize the attachment state
  useEffect(() => {
    attachmentToFileInput(data).then(fileData => {
      setAttachment(fileData);
    });
  }, [data]);

  const prepareFormSubmission = values => {
    let nullRemovedFormData = removeEmptyValues(values);
    // TODO: fix this dirty fix properly
    if (props.sendAsJSON) {
      nullRemovedFormData = values;
    }
    // when no value is entered, return null to skip submission
    if (R.isEmpty(nullRemovedFormData)) {
      throw new Error('No values entered to save');
    }
    // adding user context values
    const valuesWithSystemInputs = {
      ...data,
      ...nullRemovedFormData,
      tenantId: props.user.tenantId,
      tenantCompanyId: props.user.tenantCompanyId,
      partitionKey: props.user.tenantId,
      lsi1: `${props.user.tenantCompanyId}_${props.entityName}`,
      entityType: 'Attachment',
      parent: props.parent
    };
    return valuesWithSystemInputs;
  };

  // Update the data record for existing attachment
  const updateFileData = ({
    customFileName,
    comment,
    fileUrl,
    fileName,
    newFiles,
    fileSize,
    shareWithTechniciansOnMobile,
    internalFile
  }) => {
    return {
      ...data,
      customFileName: customFileName || '',
      comment,
      fileUrl,
      fileName: newFiles ? newFiles[0].file.name : fileName,
      parent,
      fileSize,
      hideFromTechniciansOnMobile: !shareWithTechniciansOnMobile,
      isInternalFile: internalFile
    };
  };

  // Append name field to completeData to enable unique name generation
  const processFileData = fileData => {
    const completeData = removeEmptyValues(fileData);
    completeData.newFiles[0].file.name = attachment.fileName;
    return completeData;
  };

  const uploadProgressCallback = ({ loaded = 0, total = 1 }, public_id, cancelUploadFn) => {
    setProgress((loaded / total) * 100);
    setCancelUpload(cancelUploadFn);
  };

  const closePopUp = flag => {
    if (progress && progress < 100 && cancelUpload) {
      cancelUpload();
      setIsLoading();
    }
    props.handleClose(flag);
    setProgress();
  };

  // Corresponds to 3 cases: upload new attachment, edit and replace image, edit without replacing image
  const handleOnComplete = async fileData => {
    let finalData;
    try {
      if (!data?.id) {
        const attachmentPayload = await fileInputToAttachment(
          props.user.tenantId,
          fileData,
          null,
          uploadProgressCallback,
          flags
        );
        finalData = prepareFormSubmission(attachmentPayload);
      } else if (attachment.newFiles !== fileData.newFiles) {
        const attachmentPayload = await fileInputToAttachment(
          props.user.tenantId,
          fileData,
          null,
          uploadProgressCallback,
          flags
        );
        finalData = updateFileData(attachmentPayload);
      } else {
        const processedData = processFileData(fileData);
        finalData = updateFileData(processedData);
      }
      await mutationService(finalData);
      mutationFlag = true;
      closePopUp(mutationFlag);
    } catch (error) {
      Logger.error(error);
      snackbar('error', error.message || 'Unable to upload the attachment', error);
      closePopUp(mutationFlag);
    }
    setIsLoading(false);
    return fileData;
  };

  return (
    <ThemeProvider>
      <Modal
        open={open}
        onClose={() => closePopUp(false)}
        title={title || `${data?.id || mode === Mode.EDIT ? 'Edit' : 'New'} Attachment`}
        actions={
          <Button
            type={ButtonType.PRIMARY}
            fullWidth
            disabled={loading}
            onClick={async () => {
              const isValid = await formService.validateForm();
              if (R.isEmpty(isValid)) {
                setIsLoading(true);
                formService?.submit();
              }
            }}
          >
            {progress ? 'Saving..' : 'Save'}
            {progress && <CircularProgressWithLabel value={progress} />}
          </Button>
        }
      >
        <MUIForm
          data={
            data?.id || mode === Mode.EDIT
              ? attachment
              : { shareWithTechniciansOnMobile: true, internalFile: false }
          }
          open={open}
          layout={Mode.EDIT}
          configuration={attachmentLayout({
            maxFileSizeWarningLabel,
            maxFileSize,
            flags,
            hasInternalFileOption,
            hasShareWithTechsOption,
            attachmentRequired,
            subjectLabel,
            subjectPlaceholder,
            descriptionLabel,
            descriptionPlaceholder,
            subjectRequired
          })}
          confirmRemoveItemLabel={attachment?.customFileName || attachment?.fileName || ''}
          onCreateService={service => setFormService(service)}
          onComplete={handleOnComplete}
        />
      </Modal>
    </ThemeProvider>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

const mapAttachmentToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedAddAttachment = connect(mapStateToProps, mapAttachmentToProps)(Attachment);

export default reduxConnectedAddAttachment;
