import { getState } from 'redux/store';
import moment from 'moment';

import { InvoiceStatus } from 'utils/constants';
import { roundFloat } from 'utils';

export const serializePayApplication = ({
  payApplication,
  paymentTerm = {},
  nextInvoiceNumber,
  currentPayAppRetainedAmount
}) => {
  const {
    pm: { project },
    user
  } = getState();

  const normalizedLineItems = payApplication.payAppLineItemRows
    .filter(li => !li.approvedDate || li.approvedDate <= payApplication.periodTo)
    .map(line => ({
      id: line.id,
      entityType: line.entityType,
      materialsPresentlyStored: line.materialsStored,
      thisPeriod: line.workCompleted
    }));

  // NOTE: Must include 0.00 value pay app line items in invoice to support future edits.
  const items = payApplication.payAppLineItemRows.map((payAppRow, i) => {
    const unitPrice = roundFloat(
      ((payAppRow.workCompleted || 0) + (payAppRow.materialsStored || 0)) *
        (1 - project.ScheduleOfValue.retainage / 100 || 0)
    );

    const productId = payAppRow?.productId ?? project?.changeOrderProduct?.id;

    return {
      id: payAppRow.invoiceItemId,
      accountingRefIdOfClass: project.projectManagerDepartment?.accountingRefId,
      accountingRefIdOfEntity: project.billingCustomer?.accountingRefId,
      amount: unitPrice,
      departmentId: project.departmentId,
      description: payAppRow.description,
      entityType: 'InvoiceItem',
      hierarchy: `${payApplication.number}_${payAppRow.id}`,
      lineItemType: 'PurchaseOrderLine',
      name: `PA-${payApplication.number} ${payAppRow.itemNumber}`,
      parentId: payApplication.Invoice?.id,
      productId,
      productSortKey: productId
        ? `${project.tenantId}_${project.tenantCompanyId}_Product_${productId}`
        : null,
      quantity: 1,
      sortOrder: i,
      taxable: false,
      unitPrice
    };
  });

  const invoiceItems = payApplication?.billedRetainage
    ? [
        ...items,
        {
          id: null,
          accountingRefIdOfClass: project.projectManagerDepartment?.accountingRefId,
          accountingRefIdOfEntity: project.billingCustomer?.accountingRefId,
          amount: payApplication?.billedRetainage,
          departmentId: project.departmentId,
          entityType: 'InvoiceItem',
          hierarchy: `${payApplication.number}_retainage`,
          lineItemType: 'PurchaseOrderLine',
          name: 'Retainage',
          parentId: payApplication.Invoice?.id,
          productId: project?.retainageProduct?.id ?? undefined,
          productSortKey: null,
          quantity: 1,
          sortOrder: items?.length,
          taxable: false,
          unitPrice: payApplication?.billedRetainage
        }
      ]
    : items;

  const invoiceAmount = roundFloat(invoiceItems.reduce((acc, item) => acc + item.unitPrice, 0));

  const dueDate =
    payApplication.applicationDate && paymentTerm?.value
      ? moment
          .unix(payApplication.applicationDate)
          .add(paymentTerm.value, 'days')
          .unix()
      : payApplication.applicationDate || null;

  return {
    id: payApplication.id,
    applicationDate: payApplication.applicationDate,
    billingCustomerId: project.billingCustomerId,
    billedRetainage: payApplication?.billedRetainage,
    contractFor: payApplication.contractFor,
    customerId: project.customerId,
    customerPropertyId: project.propertyId,
    distributeTo: payApplication.distributeTo,
    entityType: 'PayApplication',
    number: payApplication.number,
    periodFrom: payApplication.periodFrom,
    periodTo: payApplication.periodTo,
    projectId: project.id,
    retainedAmount: currentPayAppRetainedAmount,
    returnTo: payApplication.returnTo.id || payApplication.returnTo,
    sendTo: 'customer',
    sendToId: payApplication.sendTo.id,
    version: (payApplication.version || 0) + 1,
    ScheduleOfValueLine: normalizedLineItems.filter(
      line => line.entityType === 'ScheduleOfValueLine'
    ),
    ChangeOrderLineItem: normalizedLineItems.filter(
      line => line.entityType === 'ChangeOrderLineItem'
    ),
    Invoice: {
      id: payApplication.Invoice?.id,
      accountingRefIdOfBillingCustomer: project.billingCustomer?.accountingRefId,
      accountingRefIdOfCustomer: project.customer?.accountingRefId,
      accountingRefIdOfCustomerProperty: project.property?.accountingRefId,
      billingCustomerId: project.billingCustomerId,
      customerPropertySortKey: project.property?.sortKey,
      customerName: project.customer?.name,
      customerSortKey: project.customer?.sortKey,
      departmentId: project.departmentId, // the department associated with the project
      dueDate,
      entityType: 'Invoice',
      invoiceItems,
      invoiceNumber: payApplication?.Invoice?.invoiceNumber || nextInvoiceNumber,
      isActive: true,
      isFinalInvoice: false,
      issuedDate: payApplication?.applicationDate || null,
      parentId: project.id,
      parentSortKey: `${user.tenantId}_Project_${project.id}`,
      partitionKey: user.tenantId,
      payApplicationId: payApplication.id,
      paymentTermName: paymentTerm?.name || 'DEFAULT 0', // name of term i.e. "Net 30"
      paymentTermValue: paymentTerm?.value || 0, // # of days until due (dueDate - issuedDate)
      priceBookId: null, // This is null because it keeps the invoice uneditable
      projectId: project.id,
      projectName: project.name,
      sortKey: `${user.tenantId}_Project_${project.id}`,
      status: InvoiceStatus.DRAFT,
      subtotal: invoiceAmount, // sum of the line items
      taxableSubtotal: 0, // 0 since there's no tax
      tenantCompanyAddress: null,
      tenantCompanyId: user.tenantCompanyId,
      tenantCompanyLogoUrl: null,
      tenantCompanyName: user.companyName,
      tenantCompanyPhone: null,
      tenantId: user.tenantId,
      termsOfService: 'Must be paid in full',
      totalAmount: invoiceAmount, // sum of the line items
      version: payApplication.Invoice?.version + 1 || 1
    }
  };
};
