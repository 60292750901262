import gql from 'graphql-tag';

const softDeleteLabourType = gql`
  mutation softDeleteLabourType($partitionKey: String, $id: String!) {
    softDeleteLabourType(partitionKey: $partitionKey, id: $id) {
      id
      name
      version
      isActive
      revenueTypeId
      jobCostTypeId
      costCodeId
    }
  }
`;

export default softDeleteLabourType;
