import omit from 'lodash/omit';
import React, { useEffect, useRef, useState } from 'react';
import { bool, func, object } from 'prop-types';
import { MUIForm } from '@buildhero/sergeant';

import { VISIT_DETAILS_PROP } from '@dispatch/queries';
import { useVisitFormConfig } from './VisitDetailsForm.config';
import { VisitFormFields } from './VisitDetailsForm.constants';
import { useVisitFormData } from './VisitDetailsForm.data';
import { useTechOptions } from './VisitDetailsForm.hooks';
import * as customComponents from './components';

const VisitDetailsForm = ({
  visit,
  disabled,
  visitLoading,
  onCreateService,
  onDirtyChange,
  onComplete,
  snackbarOn,
  collapseDetails,
  showTechStatuses,
  departmentsResponse,
  techsResponse,
  allowMultipleVisitsCreation,
  createMultiVisitStart,
  multipleVisitsCreateInProcess,
  job,
  crewTimeTracking,
  crewMembersAdded
}) => {
  const [primaryTechId, setPrimaryTechId] = useState();
  const [extraTechIds, setExtraTechIds] = useState();
  const [multipleVisitsCreationEnabled, setMultipleVisitsCreationEnabled] = useState(false);

  useEffect(() => {
    setPrimaryTechId(visit.primaryTechId);
    setExtraTechIds(visit.extraTechs);
  }, [visit.primaryTechId, visit.extraTechs]);

  const visitLoadingRef = useRef(visitLoading);

  useEffect(() => {
    visitLoadingRef.current = visitLoading;
  }, [visitLoading]);

  const { primaryTechOptions, extraTechOptions, techsLoading } = useTechOptions({
    primaryTechId,
    extraTechIds,
    techsResponse
  });

  const onCreateMultipleVisitsChange = () => {
    setMultipleVisitsCreationEnabled(!multipleVisitsCreationEnabled);
  };

  const visitFormConfig = useVisitFormConfig({
    disabled,
    visitLoading,
    techs: techsResponse.data || [],
    techsLoading,
    primaryTechOptions,
    extraTechOptions,
    allowMultipleVisitsCreation,
    multipleVisitsCreationEnabled,
    onCreateMultipleVisitsChange,
    departmentsResponse,
    visit,
    collapseDetails,
    schedules: visit.schedules,
    showTechStatuses,
    multipleVisitsCreateInProcess,
    job,
    crewTimeTracking,
    crewMembersAdded
  });

  const visitFormData = useVisitFormData(visit, job, crewTimeTracking);

  const handleFormNotification = (type, message) => {
    if (type === 'success') return;
    snackbarOn('error', message);
  };

  const handleFormChange = data => {
    if (!visitLoadingRef.current) {
      setPrimaryTechId(data?.primaryTechId);
      setExtraTechIds(data?.extraTechs);
    }
  };

  const handleComplete = visitData => {
    if (multipleVisitsCreationEnabled) {
      createMultiVisitStart();
      snackbarOn('success', 'Creating your visits. This my take a while.');
      onComplete(visitData, multipleVisitsCreationEnabled);
    } else {
      onComplete(
        omit(visitData, [VisitFormFields.excludeWeekend.key, VisitFormFields.endDate.key])
      );
    }
  };

  return (
    <MUIForm
      configuration={visitFormConfig}
      customComponents={customComponents}
      data={visitFormData}
      onCreateService={onCreateService}
      onDirtyChange={onDirtyChange}
      onComplete={handleComplete}
      onFormNotification={handleFormNotification}
      onFormChange={handleFormChange}
    />
  );
};

VisitDetailsForm.propTypes = {
  visit: VISIT_DETAILS_PROP.isRequired,
  visitLoading: bool.isRequired,
  disabled: bool.isRequired,
  onCreateService: func.isRequired,
  onDirtyChange: func.isRequired,
  onComplete: func.isRequired,
  snackbarOn: func.isRequired,
  collapseDetails: bool,
  showTechStatuses: bool,
  departmentsResponse: object.isRequired,
  techsResponse: object.isRequired,
  allowMultipleVisitsCreation: bool,
  createMultiVisitStart: func.isRequired,
  multipleVisitsCreateInProcess: bool.isRequired,
  job: object.isRequired,
  crewTimeTracking: bool,
  crewMembersAdded: bool
};

VisitDetailsForm.defaultProps = {
  collapseDetails: false,
  showTechStatuses: false,
  allowMultipleVisitsCreation: false,
  crewTimeTracking: false,
  crewMembersAdded: false
};

export default VisitDetailsForm;
