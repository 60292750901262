import React, { useCallback } from 'react';

import {
  addAttachmentsToPDF,
  convertPDFLibDocumentToBlob,
  mergePDFLibDocument
} from 'scenes/ProjectManagement/components/utils';

import SubmittalItemPDF from '../ItemPDF/index';

import SubmittalPackagePDF from './index';

export const useGetSubmittalPackagePDFBlob = props =>
  useCallback(async () => {
    const { packageAttachments, packageItems, companyInfo } = props;
    // Step 1: Get PDF consists of Package Document + Package Attachment
    const packagePDFDocument = <SubmittalPackagePDF {...props} />;
    const packagePDFLibDocument = await addAttachmentsToPDF({
      mainPDFDocument: packagePDFDocument,
      attachments: packageAttachments,
      returnAsPDFDocument: true
    });
    // Step 2: Get PDF array consists of Item Cover Page + Item Attachment
    const itemPDFLibDocumentArray = await Promise.all(
      packageItems.map(packageItem => {
        const itemPDFDocument = (
          <SubmittalItemPDF companyInfo={companyInfo} packageItem={packageItem} />
        );
        return addAttachmentsToPDF({
          mainPDFDocument: itemPDFDocument,
          attachments: packageItem.pdfAttachments,
          returnAsPDFDocument: true
        });
      })
    );
    // Step 3: Merge the Package PDF and Item PDF together
    await mergePDFLibDocument(packagePDFLibDocument, itemPDFLibDocumentArray);

    return convertPDFLibDocumentToBlob(packagePDFLibDocument);
  }, [props]);
