import gql from 'graphql-tag';

const updatePriceBook = gql`
  mutation updatePriceBook($partitionKey: String, $data: UpdatePriceBookInput!) {
    updatePriceBook(partitionKey: $partitionKey, data: $data) {
      id
      entityType
      version
    }
  }
`;

export default updatePriceBook;
