import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    width: '100%',
    padding: 0
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 10,
    letterSpacing: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal'
  },
  tableHeader: {
    color: '#3f3f3f'
  },
  card: {
    background: theme.palette.grayscale(100),
    borderRadius: '0px',
    height: '36px',
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  taskDes: {
    padding: '0px 0px',
    height: 36,
    backgroundColor: theme.palette.background,
    width: '100%'
  },
  expandContainer: {
    textAlign: 'center',
    left: '0px',
    position: 'relative',
    top: '1px',
    cursor: 'pointer'
  },
  expand: {
    width: '100%',
    textAlign: 'center',
    position: 'relative',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: theme.palette.grayscale(80),
    backgroundColor: theme.palette.grayscale(100),
    marginTop: '-1px'
  },
  dragHandler: {
    padding: '0px',
    borderRadius: '0px',
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  taskBadge: {
    width: '33px'
  },
  noPadding: {
    margin: '0'
  },
  labelText: {
    backgroundColor: theme.palette.grayscale(100),
    height: 'auto',
    marginTop: '2px',
    borderRadius: '0px !important',
    border: 'none',
    '&:hover': {
      bordercolor: theme.palette.grayscale(100)
    }
  },
  amount: {
    backgroundColor: theme.palette.other.yellow,
    height: 'auto',
    width: 'fit-content',
    borderRadius: '5px',
    marginTop: '2px'
  },
  closeIcon: {
    color: theme.palette.grayscale(80),
    fontSize: 16,
    '&:hover': {
      color: '#3f3f3f'
    }
  },
  noData: {
    backgroundColor: theme.palette.grayscale(98),
    minHeight: '435px'
  },
  noDataTxt: {
    fontSize: '20px'
  },
  addTaskbtn: {
    width: '100%',
    marginRight: '4994px',
    marginTop: '13px',
    variant: 'outlined',
    color: theme.palette.grayscale(0),
    backgroundColor: theme.palette.grayscale(100)
  },
  addTaskToJobBtn: {
    marginTop: '13px',
    justify: 'flex-end',
    variant: 'outlined',
    color: theme.palette.grayscale(0),
    backgroundColor: theme.palette.grayscale(100)
  },
  dragIndicatorImgIcon: {
    height: '40px'
  },
  dragIndicatorContainer: {
    flexShrink: 1,
    width: 'auto',
    height: 'auto'
  },
  cssFocused: {},
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`
    }
  },
  notchedOutline: {
    borderWidth: '5px !important',
    borderColor: 'green !important'
  },
  checkIcon: {
    marginRight: '8px',
    minHeight: '24px',
    minWidth: '24px',
    padding: 0
  },
  cancelIcon: {
    minHeight: '24px',
    minWidth: '24px',
    padding: 0
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%'
    }
  }
}));
