const pickPropertiesFromList = (list = [], neededFields) => {
  if (!neededFields) return list;
  const items = list.map(item => {
    const value = neededFields.reduce((obj, { key, defaultValue = null }) => {
      const itemValue = item[key];
      const field = {};
      if (itemValue || Number.isInteger(itemValue)) {
        field[key] = itemValue;
      } else if (defaultValue) {
        field[key] = defaultValue;
      }

      return { ...obj, ...field };
    }, {});
    return value;
  });
  return items;
};

export default pickPropertiesFromList;
