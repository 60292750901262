/* eslint-disable no-confusing-arrow */
import nisp from 'nisp';
import args from 'nisp/lib/args';
import { parse } from 'nisp/parser';
import moment from 'moment';

const sandbox = {
  $: function(variableName) {
    // supports varaible to have .notation
    return variableName.split('.').reduce((o, i) => o[i], this.env);
    // return this.env[variableName];
  },
  offset: function(variableName, offset) {
    const val = variableName.split('.').reduce((o, i) => o[i], this.env);

    return val + offset;
  },
  now: () => new Date(),
  str: (...args) => args.map(x => x && x.toString()).join(''),
  lt: (inputValue, comparisonValue, returnValue) => {
    if (inputValue < comparisonValue) {
      return returnValue || true;
    }
    return false;
  },
  le: (inputValue, comparisonValue, returnValue) => {
    if (inputValue <= comparisonValue) {
      return returnValue || true;
    }
    return false;
  },
  gt: (inputValue, comparisonValue, returnValue) => {
    if (inputValue > comparisonValue) {
      return returnValue || true;
    }
    return false;
  },
  ge: (inputValue, comparisonValue, returnValue) => {
    if (inputValue >= comparisonValue) {
      return returnValue || true;
    }
    return false;
  },
  bw: (inputValue, startValue, lastValue, returnValue) => {
    if (inputValue > startValue && inputValue < lastValue) {
      return returnValue || true;
    }
    return false;
  },
  range: args(v => {
    let index = 0;

    while (true) {
      const valueToMatch = v(index);
      if (valueToMatch) {
        return valueToMatch;
      }
      if (valueToMatch === undefined) {
        return;
      }
      index += 1;
    }
  }),
  formatDate: format => moment().format(format),
  addDays: (numberOfDays, format) =>
    moment()
      .add(numberOfDays)
      .format(format),
  subtractDays: (numberOfDays, format) =>
    moment()
      .subtract(numberOfDays)
      .format(format),
  or: args(v => (v(0) || v(1) ? v(2) : v(3))),
  and: args(v => (v(0) && v(1) ? v(2) : v(3))),
  includes: args(v => (v(0).includes(v(1)) ? v(2) : v(3))),
  lowercase: args(v => v(0).toLowerCase()),
  uppercase: args(v => v(0).toUpperCase()),
  case: args(v => {
    const value = v(0);
    let index = 0;
    while (true) {
      const valueToMatch = v(index + 1);
      if (valueToMatch === 'DEFAULT') {
        return v(index + 2);
      }
      if (value === valueToMatch) {
        return v(index + 2);
      }
      if (v(index + 2) === undefined) {
        return;
      }
      index += 2;
    }
  })
};

export default (exp, env) =>
  typeof exp === 'string' ? nisp(parse(exp), sandbox, env) : nisp(exp, sandbox, env);
