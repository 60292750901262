import gql from 'graphql-tag';

const updateInvoiceTags = gql`
  mutation addInvoiceInvoiceTagsToInvoice(
    $partitionKey: String
    $data: AddInvoiceInvoiceTagsToInvoiceInput!
  ) {
    addInvoiceInvoiceTagsToInvoice(partitionKey: $partitionKey, data: $data) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      invoiceTagId
      invoiceTag {
        tagType
        tagName
        sortOrder
        id
        tenantId
        tenantCompanyId
        partitionKey
        sortKey
        hierarchy
        entityType
      }
    }
  }
`;

export default updateInvoiceTags;
