import { FeatureFlags } from 'utils/FeatureFlagConstants';

const RolesLayout = (launchDarklyFlags = {}, isQuoteToPoEnabled) => ({
  permissionsMaster: {
    allCategories: ['Data', 'Menu', 'Functions', 'Object'],
    allActions: ['allow', 'create', 'read', 'update', 'delete'],
    permissions: [
      {
        category: 'Data',
        subcategory: 'View all Job Notes on Mobile',
        tabs: 1,
        label: 'Data-> View all Job Notes on Mobile',
        helpText:
          '"Access rights to view job notes on mobile that are marked as "not to be shared with technicians"',
        actions: ['allow']
      },
      {
        category: 'Data',
        subcategory: 'View all Job Attachments on Mobile',
        tabs: 1,
        label: 'Data-> View all Job Attachments on Mobile',
        helpText:
          'Access rights to view files attached to jobs on mobile that are marked as "not to be shared with technicians"',
        actions: ['allow']
      },
      {
        category: 'Data',
        subcategory: 'View Labor Rates',
        tabs: 1,
        label: 'Data-> Show/Hide Payroll Rate information',
        helpText: 'Show/Hide Payroll Rate information',
        actions: ['allow']
      },
      {
        category: 'Data',
        subcategory: 'View Cost Data',
        tabs: 1,
        label: 'Data-> View Cost Data',
        helpText: 'Access rights to View Cost Data',
        actions: ['allow']
      },
      {
        category: 'Data',
        subcategory: 'View Price Data',
        tabs: 1,
        label: 'Data-> View Price Data',
        helpText: 'Access rights to View Price Data',
        actions: ['allow']
      },
      {
        category: 'Data',
        subcategory: 'View NTE Data',
        tabs: 1,
        label: 'Data-> View NTE Data',
        helpText: 'Access rights to View NTE Data',
        actions: ['allow']
      },
      {
        category: 'Data',
        subcategory: 'Show Financial Data',
        tabs: 1,
        label: 'Data-> Show Financial Data',
        helpText: 'Access rights to Show Financial Data',
        actions: ['allow']
      },
      {
        category: 'Data',
        subcategory: 'View and Edit Employee Hourly Rates',
        tabs: 1,
        label: 'Data-> View and Edit Employee Hourly Rates',
        helpText: 'Access rights to View and Edit Employee Hourly Rates',
        actions: ['allow']
      },
      isQuoteToPoEnabled && {
        category: 'Data',
        subcategory: 'Create Purchase Orders directly on Quotes',
        tabs: 1,
        label: 'Data-> Create Purchase Orders directly on Quotes',
        helpText: 'Access rights to create Purchase Orders directly on Quotes',
        actions: ['allow']
      },
      {
        category: 'Data',
        subcategory: 'View all Quote Attachments on Mobile',
        tabs: 1,
        label: 'Data-> View all Quote Attachments on Mobile',
        helpText: `Access rights to view quote attachments on mobile that are marked as "not to be shared with technicians" `,
        actions: ['allow']
      },
      {
        category: 'Menu',
        subcategory: 'Settings',
        tabs: 1,
        label: 'Menu-> Settings',
        helpText: 'Access rights to Settings',
        actions: ['allow']
      },
      {
        category: 'Menu',
        subcategory: 'Reports And Dashboards',
        tabs: 1,
        label: 'Menu-> Reports And Dashboards',
        helpText: 'Access rights to Reports And Dashboards',
        actions: ['allow']
      },
      {
        category: 'Menu',
        subcategory: 'Follow ups',
        tabs: 1,
        label: 'Menu-> Follow ups',
        helpText: 'Access rights to Follow ups',
        actions: ['allow']
      },
      {
        category: 'Menu',
        subcategory: 'Dispatch',
        tabs: 1,
        label: 'Menu-> Dispatch',
        helpText: 'Access rights to Dispatch',
        actions: ['allow']
      },
      {
        category: 'Menu',
        subcategory: 'Lists',
        tabs: 1,
        label: 'Menu-> Lists',
        helpText: 'Access rights to Lists',
        actions: ['allow']
      },
      {
        category: 'Menu',
        subcategory: 'Accounting',
        tabs: 1,
        label: 'Menu-> Accounting',
        helpText: 'Access rights to Accounting',
        actions: ['allow']
      },
      {
        category: 'Menu',
        subcategory: 'Timesheets',
        tabs: 1,
        label: 'Menu-> Timesheets',
        helpText: 'Access rights to Timesheets',
        actions: ['allow']
      },
      {
        category: 'Menu',
        subcategory: 'Payroll Settings',
        tabs: 1,
        label: 'Menu-> Payroll Settings',
        helpText: 'Access rights to Payroll Settings',
        actions: ['allow']
      },
      {
        category: 'Menu',
        subcategory: 'Workflows',
        tabs: 1,
        label: 'Menu-> Workflows',
        helpText: 'Access rights to Workflows',
        actions: ['allow']
      },
      {
        category: 'Menu',
        subcategory: 'Procurement',
        tabs: 1,
        label: 'Menu-> Procurement',
        helpText: 'Access rights to Procurement',
        actions: ['allow']
      },
      {
        category: 'Menu',
        subcategory: 'Project Management',
        tabs: 1,
        label: 'Menu-> Project Management',
        helpText: 'Access rights to Project Management',
        actions: ['allow']
      },
      {
        category: 'Menu',
        subcategory: 'Inventory',
        tabs: 1,
        label: 'Menu-> Inventory',
        helpText: 'Access rights to Inventory settings',
        actions: ['allow']
      },
      {
        category: 'Menu',
        subcategory: 'Service Agreements',
        tabs: 1,
        label: 'Menu-> Service Agreements',
        helpText: 'Access rights to Service Agreement',
        actions: ['allow']
      },
      {
        category: 'Menu',
        subcategory: 'Pricebooks',
        tabs: 1,
        label: 'Menu-> Pricebooks',
        helpText: 'Access rights to Pricebooks',
        actions: ['allow']
      },
      launchDarklyFlags[FeatureFlags.SERVICE_CHANNEL_INTEGRATION] && {
        category: 'Menu',
        subcategory: 'Integrations',
        tabs: 1,
        label: 'Menu-> Integrations',
        helpText: 'Access rights to Integrations',
        actions: ['allow']
      },
      launchDarklyFlags[FeatureFlags.SERVICE_CHANNEL_INTEGRATION] && {
        category: 'Menu',
        subcategory: 'Service Channel',
        tabs: 1,
        label: 'Menu-> Service Channel',
        helpText: 'Access rights to Service Channel',
        actions: ['allow']
      },
      launchDarklyFlags[FeatureFlags.ASSIGNED_PENDING_TASKS_MOBILE] && {
        category: 'Functions',
        subcategory: 'Add Assigned Tasks (Mobile)',
        tabs: 1,
        label: 'Function-> Add Assigned Tasks',
        helpText: 'Access right for adding tasks to current job/maintenance on mobile',
        actions: ['allow']
      },
      launchDarklyFlags[FeatureFlags.ASSIGNED_PENDING_TASKS_MOBILE] && {
        category: 'Functions',
        subcategory: 'Add Pending Tasks (Mobile)',
        tabs: 1,
        label: 'Function-> Add Pending Tasks (Mobile)',
        helpText: 'Access right for adding pending tasks on mobile',
        actions: ['allow']
      },
      launchDarklyFlags[FeatureFlags.ASSIGNED_PENDING_TASKS_MOBILE] && {
        category: 'Functions',
        subcategory: 'Add Pending Tasks to Work (Mobile)',
        tabs: 1,
        label: 'Function-> Add Pending Tasks to Work (Mobile)',
        helpText:
          'Access right for adding pending tasks to maintenance, job, or next maintenance on mobile',
        actions: ['allow']
      },
      {
        category: 'Functions',
        subcategory: 'TimeTrackingReport',
        tabs: 1,
        label: 'Function-> TimeTrackingReport ',
        helpText: 'Access rights to TimeTrackingReport',
        actions: ['allow']
      },
      {
        category: 'Functions',
        subcategory: 'Job complete from mobile',
        tabs: 1,
        label: 'Functions-> Job complete from mobile',
        helpText: 'Access rights to allow user to complete the job',
        actions: ['allow']
      },
      {
        category: 'Functions',
        subcategory: 'Mobile',
        tabs: 1,
        label: 'Functions-> Mobile',
        helpText: 'Access rights to Mobile',
        actions: ['allow']
      },
      {
        category: 'Functions',
        subcategory: 'Web',
        tabs: 1,
        label: 'Functions-> Web',
        helpText: 'Access rights to Web',
        actions: ['allow']
      },
      {
        category: 'Functions',
        subcategory: 'Dispatchable',
        tabs: 1,
        label: 'Functions-> Dispatchable',
        helpText: 'Access rights to Dispatchable',
        actions: ['allow']
      },
      {
        category: 'Functions',
        subcategory: 'Admin',
        tabs: 1,
        label: 'Functions-> Admin',
        helpText: 'Access rights to Admin',
        actions: ['allow']
      },
      {
        category: 'Functions',
        subcategory: 'Job tag on mobile',
        tabs: 1,
        label: 'Function-> Job tag on mobile',
        helpText: 'Access rights for job tags',
        actions: ['allow', null, 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'Customer',
        tabs: 1,
        label: 'Object-> Customer',
        helpText: 'Access rights to Customer',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'Property',
        tabs: 1,
        label: 'Object-> Property',
        helpText: 'Access rights to Property',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'Job',
        tabs: 1,
        label: 'Object-> Job',
        helpText: 'Access rights to Job',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'Task',
        tabs: 1,
        label: 'Object-> Task',
        helpText: 'Access rights to Task',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'Visit',
        tabs: 1,
        label: 'Object-> Visit',
        helpText: 'Access rights to Visit',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'Asset',
        tabs: 1,
        label: 'Object-> Asset',
        helpText: 'Access rights to Asset',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'Quotes',
        tabs: 1,
        label: 'Object-> Quotes',
        helpText: 'Access rights to Quotes',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'Employee',
        tabs: 1,
        label: 'Object-> Employee',
        helpText: 'Access rights to Employee',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'Invoice',
        tabs: 1,
        label: 'Object-> Invoice',
        helpText: 'Access rights to Invoice',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'ReviewReport',
        tabs: 1,
        label: 'Object-> ReviewReport',
        helpText: 'Access rights to ReviewReport',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'Timesheets',
        tabs: 1,
        label: 'Object-> Timesheets',
        helpText: 'Access rights to Timesheets',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'Workflows',
        tabs: 1,
        label: 'Object-> Workflows',
        helpText: 'Access rights to Workflows',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'Product',
        tabs: 1,
        label: 'Object-> Inventory ',
        helpText: 'Access rights to Item List',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'Truck',
        tabs: 1,
        label: 'Object-> Inventory Truck/Template',
        helpText: 'Access rights to Inventory Truck/Template',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'TimetrackingSetting',
        tabs: 1,
        label: 'Object-> TimetrackingSetting',
        helpText: 'Access rights to modify timetracking setting',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'ServiceAgreement',
        tabs: 1,
        label: 'Object-> ServiceAgreement',
        helpText: 'Access rights to modify service agreement',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'PurchaseOrder',
        tabs: 1,
        label: 'Object-> PurchaseOrder',
        helpText: 'Access rights to modify purchase order',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'PM Dashboard',
        tabs: 1,
        label: 'Object-> PM Dashboard',
        helpText: 'Access rights to view Dashboard',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'PM FieldReport',
        tabs: 1,
        label: 'Object-> PM FieldReport',
        helpText: 'Access rights to view FieldReport',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'PM RFI',
        tabs: 1,
        label: 'Object-> PM RFI',
        helpText: 'Access rights to view RFI',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'PM ChangeOrder',
        tabs: 1,
        label: 'Object-> PM ChangeOrder',
        helpText: 'Access rights to view ChangeOrder',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'PM Submittals',
        tabs: 1,
        label: 'Object-> PM Submittals',
        helpText: 'Access rights to view Submittals',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'PM Purchasing',
        tabs: 1,
        label: 'Object-> PM Purchasing',
        helpText: 'Access rights to view Purchasing',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'PM Finance',
        tabs: 1,
        label: 'Object-> PM Finance',
        helpText: 'Access rights to view Finance',
        actions: ['create', 'read', 'update', 'delete']
      },
      {
        category: 'Object',
        subcategory: 'PM ProjectSettings',
        tabs: 1,
        label: 'Object-> PM ProjectSettings',
        helpText: 'Access rights to view ProjectSettings',
        actions: ['create', 'read', 'update', 'delete']
      }
    ].filter(Boolean)
  }
});

export default RolesLayout;
