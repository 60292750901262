/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { memo } from 'react';
import { Typography } from '@material-ui/core';

import compose from 'utils/compose';
import withErrorBoundary from 'utils/withErrorBoundary';

import { ColumnProps } from '../WrapTable.types';

import { useHeaderColumnStyles } from './HeaderColumn.styles';

const HeaderColumn = props => {
  const { colDef } = props;
  const styles = useHeaderColumnStyles(props);
  const { headerName, renderHeader } = colDef;

  return (
    <div css={styles.headerColumn}>
      {renderHeader ? renderHeader(props) : <Typography>{headerName}</Typography>}
    </div>
  );
};

HeaderColumn.propTypes = ColumnProps;

const ErrorCell = props => {
  const styles = useHeaderColumnStyles(props);

  return (
    <div css={[styles.headerColumn, styles.error]}>
      <Typography>CELL ERROR</Typography>
    </div>
  );
};

export default compose(withErrorBoundary(ErrorCell), memo)(HeaderColumn);
