import React, { useMemo, useState } from 'react';
import { Modal, Typography } from '@buildhero/sergeant';
import { func, bool, string, object } from 'prop-types';
import StorageService from 'services/StorageService';

import FormViewer from 'components/FormSection/FormViewer';
import { Logger } from 'services/Logger';
import { useSnackbar } from 'customHooks/useSnackbar';
import { BuildHeroThemeProvider } from 'themes/BuildHeroTheme';

import { useUpdateFormData } from '../../mutations/useUpdateFormData';
import { REPORT_FORM_PROP } from '../../propTypes';

const b64Rgx = '(?:[A-Za-z0-9+\\/]{4})*(?:[A-Za-z0-9+\\/]{2}==|[A-Za-z0-9+\\/]{3}=)?';
const mimeRgx = '(data:\\w+\\/[a-zA-Z\\+\\-\\.]+;base64,)';
const base64ImageRegex = new RegExp(`^${mimeRgx}?${b64Rgx}$`, 'i');

const useStyles = () => ({
  tableLink: {
    cursor: 'pointer'
  }
});

/*
  this is an analogue to editFormData from src/components/FormSection/index.js
  which is still used in the old review report / tech report / job detail page
  when <FormSection /> is refactored to a functional component 
  the two editFormData functions should be conslidated into on
  common utility function.
  See BUOP-12355 for more details.
*/
const editFormData = async ({
  formMetaData,
  editedDescription,
  FormViewerRecord,
  user,
  setIsSaving,
  updateFormData,
  snackbar,
  handleClose
}) => {
  if (!formMetaData) return;

  const { id, name, description, version } = FormViewerRecord;
  const { tenantId } = user;
  const formDataJson = {
    name,
    description: editedDescription || description,
    formData: formMetaData
  };

  setIsSaving(true);
  const { meta, searchableFields } = formMetaData;

  const formData = {};
  if (searchableFields) {
    Object.keys(searchableFields).forEach(dataId => {
      const mappingId = searchableFields[dataId];
      const dataValue = meta[1][dataId];
      if (dataValue) {
        const isNumber = mappingId.indexOf('number') >= 0;
        formData[mappingId] = isNumber ? +meta[1][dataId] : meta[1][dataId];
      }
    });
  }

  // 1. Get all fields that use Signature Component
  // 2. grab all the base64 values for those fields
  // 3. upload those images
  // 4. replace the image with the base64
  const signatureFieldNames = [];
  const layout = meta?.[0]?.layouts?.default || meta?.[0]?.layouts?.edit;
  layout?.contents?.forEach(content => {
    content?.contents?.forEach(row => {
      row?.contents?.forEach(column => {
        if (
          column?.component?.default === 'SignatureScreen' ||
          column?.component?.edit === 'SignatureScreen'
        ) {
          signatureFieldNames.push(column.source);
        }
      });
    });
  });

  const values = formMetaData?.meta?.[1];
  const storageService = new StorageService();
  await Promise.all(
    signatureFieldNames.map(async fieldName => {
      if (values[fieldName]) {
        const signatureValue = JSON.parse(values[fieldName]);
        if (base64ImageRegex.test(signatureValue.signature)) {
          const filename = `${tenantId}/${Date.now()}-signature.png`;
          const uri = await storageService.uploadFile(
            signatureValue.signature,
            filename,
            e => e,
            'image/png'
          );
          values[fieldName] = JSON.stringify({
            ...signatureValue,
            signature: uri
          });
        }
      }
    })
  );

  const formPayload = {
    id,
    formDataJson: JSON.stringify(formDataJson),
    version,
    tenantId
  };
  const payload = { ...formPayload, ...formData };

  try {
    const { data } = await updateFormData(payload);
    if (data) {
      snackbar('success', `Successfully submitted form ${name || ''}`);
      handleClose();
      setIsSaving(false);
    }
  } catch (error) {
    Logger.error(error);
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      snackbar('error', error.graphQLErrors[0].message);
    } else {
      snackbar('error', 'Unable to submit form, please try again later');
    }
    setIsSaving(false);
  }
};

/* 
  analogue to formatting done in CommonService.getAllFormDataFromEntity
  which formatted formData in legacy code such as Review Reports V1.
  @TODO consider if we want to keep and hoist this or remove it.
  see BUOP-12357 for more info
*/
export const formatFormItem = item => {
  const { form } = item;
  if (form !== null && form.viewType !== 'Inline') {
    try {
      if (item.formDataJson) {
        const formDataJson = JSON.parse(item.formDataJson);
        const { name, description, formData } = formDataJson || {};
        return {
          id: item.id,
          name,
          description,
          formDataJson: formData,
          sortKey: item.sortKey,
          lastUpdatedDateTime: item.lastUpdatedDateTime,
          lastUpdatedBy: item.lastUpdatedBy,
          version: item.version
        };
      }
      const formData = JSON.parse(
        item.formDefinition?.formDefinitionJson ||
          form.latestPublishedFormDefinition?.formDefinitionJson
      );

      const { name, description } = form;
      return {
        id: item.id,
        name,
        description,
        formDataJson: formData,
        sortKey: item.sortKey,
        lastUpdatedDateTime: item.lastUpdatedDateTime,
        lastUpdatedBy: item.lastUpdatedBy
      };
    } catch (e) {
      console.warn('Issue parsing form data', e);
    }
  }
};

export const FormLink = ({ onClick, formData }) => {
  const styles = useStyles();
  return (
    <Typography css={styles.tableLink} underlined onClick={onClick}>
      {formData?.name || 'Form'}
    </Typography>
  );
};

export const FormModal = ({ open, handleClose, formData, mode = 'view', user = {} }) => {
  const [update] = useUpdateFormData();
  const snackbar = useSnackbar();
  const [isSaving, setIsSaving] = useState(false);

  return (
    <Modal open={open} onClose={handleClose} fullWidth>
      <BuildHeroThemeProvider>
        <FormViewer
          name={formData?.name}
          description={formData?.description}
          formMetaData={formData?.formDataJson}
          handleClose={handleClose}
          isEditMode={mode === 'edit'}
          onSubmit={(meta, editedDescription) =>
            editFormData({
              formMetaData: meta,
              editedDescription,
              FormViewerRecord: formData,
              user,
              setIsSaving,
              updateFormData: update,
              snackbar,
              handleClose
            })
          }
          user={user}
          isSaving={isSaving}
        />
      </BuildHeroThemeProvider>
    </Modal>
  );
};

const FormModalLink = ({ formData }) => {
  const [formDetailModalOpen, setFormDetailModalOpen] = useState(false);

  return (
    <>
      <FormLink onClick={() => setFormDetailModalOpen(true)} formData={formData} />
      <FormModal
        open={formDetailModalOpen}
        handleClose={() => setFormDetailModalOpen(false)}
        formData={formData}
      />
    </>
  );
};

FormModalLink.propTypes = {
  formData: REPORT_FORM_PROP.isRequired
};

FormLink.propTypes = {
  onClick: func.isRequired,
  formData: REPORT_FORM_PROP.isRequired
};

FormModal.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  formData: REPORT_FORM_PROP,
  mode: string,
  user: object
};

FormModal.defaultProps = {
  formData: {},
  mode: 'view',
  user: {}
};

export default FormModalLink;
