import { useTheme } from '@material-ui/core';

export const useStyles = () => {
  const theme = useTheme();
  return {
    header: {
      display: 'flex',
      marginBottom: theme.spacing(1)
    },
    button: {
      marginLeft: 'auto'
    }
  };
};
