import isString from 'lodash/isString';

export const formatDurationValue = durationStr => {
  if (!isString(durationStr)) {
    return null;
  }

  const minutesStr = durationStr.match(/(.*)minute(s)/);
  if (!minutesStr) {
    return null;
  }

  const durationInMinutes = parseInt(minutesStr[1], 10);
  if (!durationInMinutes) {
    return null;
  }

  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;

  const displayMinutes = minutes > 1 ? `${minutes} minutes` : `${minutes} minute`;
  const displayHours = hours > 1 ? `${hours} hrs` : `${hours} hr`;

  if (hours && minutes) {
    return `${displayHours} ${displayMinutes}`;
  }

  if (minutes) {
    return displayMinutes;
  }

  return displayHours;
};

export const serializeDurationValue = value => {
  if (!value || !isString(value)) {
    return value;
  }

  const match = value
    .replace(/\s/g, '')
    .match(/^((\d+)(hours|hour|hrs|hr|h))?((\d+)(minutes|minute|mins|min|m))?$/i);

  if (!match) {
    return value;
  }

  const [, , hours, , , minutes] = match;
  if (!hours && !minutes) {
    return value;
  }

  const totalMinutes = (parseInt(hours, 10) || 0) * 60 + (parseInt(minutes, 10) || 0);
  return `${totalMinutes} minutes`;
};
