import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SergeantModal } from 'components';
import { PreferredTechniciansPropType } from 'components/PreferredTechniciansForm/PropTypes';
import { hasPreferredTechnician } from '../../helpers';

const PreferredTechniciansModal = ({
  onAction,
  renderContent,
  renderOpenButton,
  preferredTechnicians
}) => {
  const [isOpenModal, setOpenModal] = useState(false);
  const openModal = () => setOpenModal(true);
  const closeModal = () => setOpenModal(false);
  const handlePrimaryAction = async (modalData, stopLoading) => {
    await onAction(modalData);
    closeModal();
    stopLoading();
  };
  const mode = hasPreferredTechnician(preferredTechnicians) ? 'edit' : 'add';
  return (
    <>
      {renderOpenButton({ openModal })}
      <SergeantModal
        open={isOpenModal}
        data={preferredTechnicians}
        dataType="Preferred Technicians"
        mode={mode}
        renderContent={renderContent}
        handlePrimaryAction={handlePrimaryAction}
        handleClose={closeModal}
        customPrimaryButtonLabel="Add Preferred Technicians"
      />
    </>
  );
};

PreferredTechniciansModal.propTypes = {
  onAction: PropTypes.func.isRequired,
  renderContent: PropTypes.func.isRequired,
  renderOpenButton: PropTypes.func.isRequired,
  preferredTechnicians: PreferredTechniciansPropType.isRequired
};
export default PreferredTechniciansModal;
