import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Divider, MUIForm } from '@buildhero/sergeant';
import { withLoading } from 'components';
import PropTypes from 'prop-types';
import { logErrorWithCallback } from 'utils';
import { QuickbooksService, ProjectService } from 'services/core';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import Labels from 'meta/labels';
import { isEmpty } from 'lodash';
import wipReportSettingsLayout from './wipReportSettingsLayout';
import { updateWIPReportSettings } from './services';

const MuiFormWithLoading = withLoading(MUIForm);

const WIPReportSettings = ({ classes, user, snackbar, accountingIntegration }) => {
  const isIntegrationEnabled = !!accountingIntegration;
  const isFormLoaded = useRef(false);

  const [glAccountOptions, setGlAccountOptions] = useState();
  const [classOptions, setClassOptions] = useState();
  const [isLoading, setIsLoading] = useState();

  const [wipReportSettings, setWIPReportSettings] = useState();

  const getWIPReportOptions = async ({ queryFn, name, displayName, prevState, updateState }) => {
    let nextToken = null;
    try {
      do {
        // eslint-disable-next-line no-await-in-loop
        const { data } = await queryFn(
          user.tenantId,
          `${user.tenantId}_Company_${user.tenantCompanyId}`,
          null,
          null,
          nextToken
        );
        if (data) {
          const items = data.getCompany?.[name]?.items || [];
          const itemOptions = constructSelectOptions(items);
          // when nextToken is available, we will integrate previous state
          if (nextToken) {
            updateState([...itemOptions, ...(prevState || [])]);
          } else {
            updateState([...itemOptions]);
          }
          // replace with updated nextToken
          nextToken = data.getCompany?.[name]?.nextToken;
        }
      } while (nextToken);
    } catch (error) {
      logErrorWithCallback(
        'error',
        snackbar,
        `Unable to fetch ${displayName}, please try again later`,
        error
      );
    }
  };

  const getWIPReportSettings = async () => {
    try {
      const Service = new ProjectService();
      const response = await Service.getWIPReportSettings(user.tenantId);
      setWIPReportSettings(response?.data.getWIPReportSettings);
    } catch (error) {
      logErrorWithCallback(
        error,
        snackbar,
        'Unable to fetch WIP Report Settings, please try again later'
      );
    }
  };

  const fetchAllWIPReportSettings = async () => {
    const quickbooksService = new QuickbooksService();
    await getWIPReportOptions({
      queryFn: quickbooksService.getGLAccounts,
      name: 'ledgerAccounts',
      displayName: 'GL Accounts',
      prevState: glAccountOptions,
      updateState: setGlAccountOptions
    });
    await getWIPReportOptions({
      queryFn: quickbooksService.getClasses,
      name: 'classes',
      displayName: 'classes',
      prevState: classOptions,
      updateState: setClassOptions
    });
    await getWIPReportSettings();
    setIsLoading(false);
  };

  useEffect(() => {
    if (isIntegrationEnabled) {
      setIsLoading(true);
      fetchAllWIPReportSettings();
    }
  }, [isIntegrationEnabled, user.tenantCompanyId, user.tenantId]);

  const customComponents = {
    SectionTitle: ({ options }) => (
      <Typography variant="body2" className={classes.greenHeading}>
        {options.label}
      </Typography>
    ),
    Divider: () => <Divider />
  };

  const handleSave = async settings => {
    if (isFormLoaded.current) {
      const {
        overBillingGlAccountId,
        overBillingGlOffsetAccountId,
        underBillingGlAccountId,
        underBillingGlOffsetAccountId,
        accountingClassId
      } = settings;
      updateWIPReportSettings({
        wipReportSettings: {
          overBillingGlAccountId,
          overBillingGlOffsetAccountId,
          underBillingGlAccountId,
          underBillingGlOffsetAccountId,
          accountingClassId
        },
        successCallback: setWIPReportSettings,
        tenantId: user.tenantId,
        snackbarOn: snackbar
      });
    } else if (!isEmpty(settings)) {
      isFormLoaded.current = true;
    }
  };

  return (
    <Box display="flex" py={2} flexDirection="column" flex={1}>
      <Divider fullwidth />
      <Typography style={{ margin: '16px 0' }} variant="h6">
        {Labels.wipReports[user.locale]}
      </Typography>
      <Divider fullwidth />
      <MuiFormWithLoading
        configuration={wipReportSettingsLayout(glAccountOptions, classOptions)}
        customComponents={customComponents}
        data={wipReportSettings || {}}
        onFormChange={handleSave}
        isLoading={isLoading}
      />
    </Box>
  );
};

WIPReportSettings.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  snackbar: PropTypes.func,
  accountingIntegration: PropTypes.string
};

WIPReportSettings.defaultProps = {
  classes: {},
  user: {},
  snackbar: () => {},
  accountingIntegration: null
};

export default WIPReportSettings;
