import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 400,
    color: '#333333'
  },
  title: {},
  number: {
    fontWeight: 700,
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'zero' on, 'salt' on, 'ss01' on"
  }
}));

const ProjectNumberTitle = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span className={classes.title}>Project Number: </span>
      <span className={classes.number}>{children}</span>
    </div>
  );
};

export default ProjectNumberTitle;
