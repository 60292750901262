import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginTop: -15
  },
  startDay: {
    width: 165,
    marginRight: theme.spacing(2),
    '& svg': {
      position: 'absolute'
    }
  },
  endDay: {
    width: 165,
    '& svg': {
      position: 'absolute'
    }
  }
}));
