const ungroupedTaskData = ({
  HTML,
  tasksWithNoGroup,
  settingsJSON,
  smartFieldInfo,
  styles,
  fontFamilyStyle
}) =>
  tasksWithNoGroup.length > 0
    ? `<tr>
    <td style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);width:65%;" colspan="1">
    ${HTML.tasks({
      group: { tasks: tasksWithNoGroup },
      smartFieldInfo,
      settingsJSON,
      removeTaskSmartfield: true,
      HTML,
      styles,
      fontFamilyStyle
    })}
    </td>
  </tr>`
    : '';

export default ungroupedTaskData;
