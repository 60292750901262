import { bundleIndex } from 'constants/algoliaIndex';
import { SettingConstants } from 'scenes/Quotes/constants';

const fieldValues = (settings, assetsList, isAssetEnabled, taskGroupAndSet) => ({
  options: {
    pageSize: 'LETTER',
    grow: 0
  },
  contents: [
    // DND Component
    {
      options: {
        size: 1,
        direction: 'row',
        grow: 0
      },
      contents: [
        {
          options: {
            direction: 'column',
            grow: 1,
            padding: [2, 10, 2, 10]
          },
          contents: [
            {
              component: {
                default: 'CustomInput'
              },
              source: 'description',
              options: {
                striped: !settings.config?.Tasks?.[SettingConstants.SHOW_TASK_DESCRIPTION]
              }
            }
          ]
        }
      ]
    },
    isAssetEnabled && {
      options: {
        size: 1,
        direction: 'row',
        grow: 0
      },
      contents: [
        {
          options: {
            direction: 'column',
            grow: 1,
            padding: 2
          },
          contents: [
            {
              component: {
                edit: 'AssetSelect'
              },
              source: 'assetName',
              options: {
                label: 'Asset',
                taskGroupAndSet: [taskGroupAndSet],
                updateService: settings.handleTaskChange,
                updateServiceFields: [settings.taskChangeActions],
                placeholder: 'Search and select asset',
                taskData: [settings.item],
                valueSet: assetsList,
                isRequired: true,
                striped: !settings.config?.Tasks?.[SettingConstants.SHOW_ASSET]
              }
            }
          ]
        }
      ]
    },
    {
      options: {
        direction: 'column',
        grow: 1,
        padding: 2
      },
      contents: [
        {
          component: {
            default: 'LaborSelect'
          },
          options: {
            isReadOnly: settings.isReadOnly,
            taskGroupAndSet: [taskGroupAndSet],
            config: [settings.config]
          },
          source: 'description'
        }
      ]
    },

    // Department Select and Bill To
    {
      options: {
        size: 3,
        direction: 'row',
        grow: 0
      },
      contents: [
        {
          options: {
            direction: 'column',
            grow: 1,
            padding: 2
          },
          contents: [
            {
              options: {
                label: 'Parts',
                taskGroupAndSet: [taskGroupAndSet],
                taskData: [settings.item],
                isReadOnly: settings.isReadOnly,
                jobData: [settings.jobData],
                placeholder: 'Search and Select',
                searchIndexName: 'bh-searchIndex',
                searchParams: [
                  {
                    name: 'selectProduct',
                    placeholder: 'Search and Select',
                    searchIndex: bundleIndex,
                    displayText: ['name', 'code'],
                    delimiter: ' - ',
                    filters: [
                      {
                        attribute: 'entityType',
                        valueArray: ['Product']
                      }
                    ]
                  }
                ],
                config: [settings.config]
              },
              component: 'PartsSelect',
              source: 'name'
            }
          ]
        }
      ]
    }
  ].filter(Boolean)
});

const createTasksLayout = (settings, assetsList, isAssetEnabled, taskGroupAndSet) => ({
  fields: {
    searchEntity: {
      default: 'searchEntity'
    },
    tasks: {
      default: 'tasks'
    },
    departmentId: {
      default: 'departmentId'
    }
  },
  layouts: {
    default: fieldValues(settings, assetsList, isAssetEnabled, taskGroupAndSet),
    edit: fieldValues(settings, assetsList, isAssetEnabled, taskGroupAndSet)
  },
  validation: {
    type: 'object',
    properties: {
      searchEntity: {
        type: 'string'
      },
      billTo: {
        type: 'string'
      }
    },
    required: ['searchEntity', 'billTo']
  },
  validationErrors: {
    searchEntity: {
      required: 'Field is required.'
    },
    billTo: {
      required: 'Field is required.'
    }
  }
});

export default createTasksLayout;

export const taskListMeta = [
  {
    id: 'name',
    filterKey: 'name',
    filterType: 'string',
    disablePadding: true,
    label: 'Task Name'
  },
  {
    id: 'createdDateTime',
    type: 'datetime',
    label: 'Date Added'
  },
  {
    id: 'createdBy',
    filterKey: 'createdBy',
    filterType: 'number',
    label: 'Added By'
  }
];
