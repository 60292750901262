import { object, string } from 'yup';
import { EntityType } from 'utils/constants';
import { email } from 'services/core/validations/Schema';
import { addressValidationFields, nameValidationField } from 'meta/validations';
import labels from 'meta/labels';

export default (vendorTypes, locale) => ({
  fields: {
    addressLine1: {
      default: 'addressLine1'
    },
    addressLine2: {
      default: 'addressLine2'
    },
    city: {
      default: 'city'
    },
    email: {
      default: 'email'
    },
    fax: {
      default: 'fax'
    },
    isActive: {
      default: 'isActive'
    },
    name: {
      default: 'name'
    },
    notes: {
      default: 'notes'
    },
    phone: {
      default: 'phone'
    },
    primaryContact: {
      default: 'primaryContact'
    },
    state: {
      default: 'state'
    },
    type: {
      default: 'type'
    },
    zipcode: {
      default: 'zipcode'
    }
  },
  layouts: {
    default: {
      options: {
        direction: 'column',
        grow: 1
      },
      contents: [
        // 1st row
        {
          options: {
            direction: 'row',
            grow: 1
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'TextInput'
                  },
                  source: 'name',
                  options: {
                    label: labels.name[locale],
                    isRequired: true
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'SelectInput'
                  },
                  source: 'type',
                  options: {
                    label: labels.vendorType[locale],
                    isRequired: true,
                    inputOptions: vendorTypes.map(type => ({ value: type, label: type })),
                    isSearchable: true
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12,
                justify: 'flex-end'
              },
              contents: [
                {
                  component: {
                    new: null,
                    edit: 'CheckboxInput'
                  },
                  source: 'isActive',
                  options: {
                    label: labels.active[locale]
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // 2nd row
        {
          options: {
            direction: 'row',
            grow: 1
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'TextInput'
                  },
                  source: 'primaryContact',
                  options: {
                    label: labels.primaryContact[locale],
                    isRequired: true
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'TextInput'
                  },
                  source: 'phone',
                  options: {
                    label: labels.vendorPhone[locale]
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'TextInput'
                  },
                  source: 'email',
                  options: {
                    label: labels.vendorEmail[locale],
                    isRequired: true
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'TextInput'
                  },
                  source: 'fax',
                  options: {
                    label: labels.fax[locale]
                  }
                }
              ]
            }
          ]
        },
        // 3rd row
        {
          options: {
            direction: 'row',
            grow: 1
          },
          contents: [
            {
              options: {
                direction: 'row',
                grow: 1
              },
              contents: [
                {
                  options: {
                    direction: 'column',
                    grow: 1,
                    padding: 12
                  },
                  contents: [
                    {
                      component: {
                        default: 'PlacesSearch'
                      },
                      source: 'addressLine1',
                      options: {
                        label: labels.addressLine1[locale],
                        setSource: false,
                        valuesToSet: [
                          {
                            addressLine1: 'addressLine1',
                            addressLine2: 'addressLine2',
                            city: 'city.shortName',
                            state: 'state.shortName',
                            zipcode: 'zipcode.shortName'
                          }
                        ]
                      }
                    }
                  ]
                },
                {
                  options: {
                    direction: 'column',
                    grow: 1,
                    padding: 12
                  },
                  contents: [
                    {
                      component: {
                        default: 'TextInput'
                      },
                      source: 'addressLine2',
                      options: {
                        label: labels.addressLine2[locale]
                      }
                    }
                  ]
                }
              ]
            },
            {
              options: {
                direction: 'row',
                grow: 1
              },
              contents: [
                {
                  options: {
                    direction: 'column',
                    grow: 1,
                    padding: 12
                  },
                  contents: [
                    {
                      component: {
                        default: 'TextInput'
                      },
                      source: 'city',
                      options: {
                        label: labels.city[locale]
                      }
                    }
                  ]
                },
                {
                  options: {
                    direction: 'column',
                    grow: 1,
                    padding: 12
                  },
                  contents: [
                    {
                      component: {
                        default: 'TextInput'
                      },
                      source: 'state',
                      options: {
                        label: labels.state[locale]
                      }
                    }
                  ]
                },
                {
                  options: {
                    direction: 'column',
                    grow: 1,
                    padding: 12
                  },
                  contents: [
                    {
                      component: {
                        default: 'TextInput'
                      },
                      source: 'zipcode',
                      options: {
                        label: labels.zipcode[locale]
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        // 4th row
        {
          options: {
            direction: 'row',
            grow: 1
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'TextInput'
                  },
                  source: 'notes',
                  options: {
                    label: labels.notes[locale]
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: object({
    ...nameValidationField('name', EntityType.VENDOR, 'Name is required.'),
    ...addressValidationFields('', false),
    type: string().required('Vendor type is required'),
    primaryContact: string().required('Primary contact is required'),
    email: string()
      .trim()
      .required('Email address is required')
      .matches(email, 'Invalid email')
  })
});
