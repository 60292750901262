import React from 'react';

import { MUIForm } from '@buildhero/sergeant';
import { Box } from '@material-ui/core';

import { useSelector } from 'react-redux';

import useLabourTypeDependency from 'customHooks/useLabourTypeDependency';
import { constructSelectOptions } from 'utils/constructSelectOptions';

import defaultInventoryItemsForm from '../defaultInventoryItemsForm';

const Form = ({
  data,
  onCreateService,
  isIntacctEnabled,
  vendorsList,
  groupList,
  incomeAccountsList,
  expenseAccountList,
  unitOfMeasureAsOptions,
  onSave,
  tenantId,
  tenantCompanyId,
  snackbarOn,
  departments,
  isProductCategoriesEnabled
}) => {
  const commonArgs = [tenantId, tenantCompanyId, snackbarOn];
  const [costCodes, costType, revenueTypes] = useLabourTypeDependency(...commonArgs);
  const costCodeOptions = constructSelectOptions(costCodes);
  const costTypeOptions = constructSelectOptions(costType);
  const revenueTypeOptions = constructSelectOptions(revenueTypes);
  const isVistaEnabled = useSelector(state => state.settings.isVistaEnabled);
  const productCategoryList = useSelector(state => state?.company?.productCategories?.items);
  const productCategoryOptions = constructSelectOptions(productCategoryList, 'name');
  const departmentOptions = constructSelectOptions(departments, 'tagName', 'id');
  const configuration = defaultInventoryItemsForm({
    data,
    vendorsList,
    isIntacctEnabled,
    incomeAccountsList,
    expenseAccountList,
    groupList,
    unitOfMeasureAsOptions,
    costCodeOptions,
    costTypeOptions,
    revenueTypeOptions,
    isVistaEnabled,
    departmentOptions,
    isProductCategoriesEnabled,
    productCategoryOptions
  });
  return (
    <Box display="flex" style={{ minHeight: 350 }}>
      <MUIForm
        configuration={configuration}
        data={data}
        onCreateService={onCreateService}
        onComplete={onSave}
      />
    </Box>
  );
};

export default Form;
