import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';

import { visitDetailsFragment } from '@dispatch/fragments';

import { DEFAULT_VISIT_DETAIL } from './useVisitDetails.default';
import { transformVisitDetails } from './useVisitDetails.transform';

export const GET_VISIT_DETAILS = gql`
  query getVisitDetails($id: String!) {
    visit: getVisitById(id: $id) {
      ${visitDetailsFragment}
    }
  }
`;

export const useVisitDetails = (visitId, options) => {
  return useExtendedQuery(GET_VISIT_DETAILS, {
    variables: { id: visitId },
    defaultData: DEFAULT_VISIT_DETAIL,
    skip: !visitId,
    transform: transformVisitDetails,
    ...options
  });
};
