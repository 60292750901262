import gql from 'graphql-tag';
import { inventoryFragment } from 'customHooks/useJob';

const updateInventoryPart = gql`
  mutation updateInventoryPart($partitionKey: String!, $data: UpdateInventoryPartInput!) {
    updateInventoryPart(partitionKey: $partitionKey, data: $data) {
      ${inventoryFragment}
    }
  }
`;

export default updateInventoryPart;
