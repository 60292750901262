import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import DatePicker from 'components/DatePicker';
import { SergeantModal, DefaultButton } from 'components';
import ENV from 'configs/env';
import { Auth } from 'aws-amplify';
import configForEnvironment from 'configs/aws-exports';
import { logErrorWithCallback } from 'utils';
import { Mode } from 'utils/constants';
import fileDownload from 'utils/fileDownload';
import exportLayout from './exportLayout';
import useStyles from './ExportInvoice.styles';

const exportValue = {
  byJob: 'JobCostingByJob',
  byCustomer: 'JobCostingByCustomer'
};

export const handleDownload = async ({
  invoiceDateRange,
  snackbarOn,
  type,
  successCallback,
  stopLoading
}) => {
  try {
    const integerFilters = [
      {
        fieldName: 'Job.createdDate',
        filterInput: {
          between: [
            moment(invoiceDateRange.startDate)
              .startOf('day')
              .unix(),
            moment(invoiceDateRange.endDate)
              .endOf('day')
              .unix()
          ]
        }
      }
    ];

    const data = {
      mode: 'SELECT',
      table: 'JobCosting',
      filter: {
        integerFilters
      },
      exportType: 'excel',
      exportFunction: type,
      exportFileName: 'export.xlsx'
    };
    const { exportFileName } = data;
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    const baseURL = configForEnvironment(ENV).restapi;

    const options = {
      method: 'post',
      url: `${baseURL}/data/exportExcel`,
      data,
      responseType: 'blob',
      headers: {
        Accept: '*/*',
        Authorization: token,
        'Content-Type': 'application/json'
      }
    };

    const res = await axios(options);
    
    fileDownload(res, res.headers['content-type'], exportFileName);
    successCallback();
  } catch (error) {
    logErrorWithCallback(
      error,
      snackbarOn,
      'Unable to download the report, please try again later'
    );
  } finally {
    stopLoading();
  }
};

const ExportInvoice = ({ snackbarOn }) => {
  const classes = useStyles();
  const [modal, setModal] = React.useState({
    open: false,
    data: null,
    mode: Mode.ADD,
    label: null
  });

  const handleModalOpen = mode =>
    setModal({
      open: true,
      mode,
      data: { type: exportValue.byCustomer },
      label: 'Export'
    });

  const handleModalClose = () => {
    setModal({ ...modal, open: false });
  };

  const handlePrimaryAction = (value = {}, stopLoading) => {
    const successCallback = () => {
      handleModalClose();
    };
    handleDownload({ snackbarOn, ...value, successCallback, stopLoading });
  };

  return (
    <>
      <DefaultButton
        label="EXPORT JOB COSTING"
        handle={() => handleModalOpen(Mode.ADD)}
        color="secondary"
        style={{ marginRight: 8 }}
        key="exportButton"
      />

      <SergeantModal
        mode=""
        open={modal.open}
        data={modal.data}
        dataType="EXPORT JOB COSTING"
        customPrimaryButtonLabel="Export"
        confirmRemoveItemLabel={modal.label}
        layout={exportLayout()}
        customComponents={{
          DatePicker: ({ field, form, options }) => {
            return (
              <Box display="flex" flexDirection="column">
                <Typography className={classes.label}>{options.label}</Typography>
                <DatePicker
                  isDateRange
                  placeholder=" "
                  labelVariant="body2"
                  classNames={classes.datePicker}
                  defaultValue={field.value}
                  onChange={selectedDate => form.setFieldValue(field.name, selectedDate, false)}
                />
              </Box>
            );
          },
          ExportType: ({ field, form, options }) => {
            return (
              <FormControl component="fieldset">
                <Typography className={classes.label}>{options.label}</Typography>
                <RadioGroup
                  name="exportType"
                  value={field.value}
                  aria-label="exportType"
                  style={{ flexDirection: 'row' }}
                  onChange={e => form.setFieldValue(field.name, e.target.value, false)}
                >
                  <FormControlLabel
                    value={exportValue.byCustomer}
                    control={<Radio />}
                    label="By Customer"
                  />
                  <FormControlLabel value={exportValue.byJob} control={<Radio />} label="By Job" />
                </RadioGroup>
              </FormControl>
            );
          }
        }}
        handlePrimaryAction={handlePrimaryAction}
        handleClose={handleModalClose}
      />
    </>
  );
};

export default ExportInvoice;
