import gql from 'graphql-tag';

export const getAllTableViewsByName = gql`
  query getTableUserSetting($partitionKey: String!, $sortKey: String!, $name: String) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      userSettings(
        entityConnection: "UserSetting"
        filter: { name: { eq: $name }, type: { eq: "TABLE_SETTING" } }
      ) {
        items {
          id
          access
          name
          displayName
          settings
          version
          type
          userSettingEmployees(
            entityConnection: "UserSettingEmployee"
            filter: { isDefault: { eq: true } }
          ) {
            items {
              id
              isDefault
            }
          }
        }
      }
    }
  }
`;

export const saveViewMutation = gql`
  mutation saveViews($partitionKey: String!, $data: RDSFilterSettingInput!) {
    saveTableSettingToCompany(partitionKey: $partitionKey, data: $data) {
      id
      access
      name
      displayName
      settings
      version
      type
      userSettingEmployees(
        entityConnection: "UserSettingEmployee"
        filter: { isDefault: { eq: true } }
      ) {
        items {
          id
          isDefault
        }
      }
    }
  }
`;

export const deleteViewMutation = gql`
  mutation deleteView($partitionKey: String!, $id: String!) {
    deleteUserSettingById(partitionKey: $partitionKey, id: $id) {
      id
      access
      name
      displayName
    }
  }
`;

export default getAllTableViewsByName;
