export default {
  loadingBlock: {
    position: 'absolute',
    zIndex: 10,
    height: '100%',
    width: '100%',
    cursor: 'wait'
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  tableContainer: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  filters: {
    container: {
      display: 'flex',
      marginBottom: 14
    }
  }
};
