import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { CustomerService } from 'services/core';
import useEmployees from 'customHooks/useEmployees';
import SergeantModal from 'components/SergeantModal';
import ErrorBoundaries from 'scenes/Error';
import { TenantRepLayout } from 'meta/Customer/TenantRep/layout';
import { snackbarOn } from 'redux/actions/globalActions';
import { sortArrayWithCaseInsensitive, logErrorWithCallback } from 'utils';
import NotesWrapper from '../NotesWrapper';

const TenantRep = ({ open, mode, parent, data, ...props }) => {
  const user = useSelector(s => s.user);
  const localData = data;
  const [employees, loading] = useEmployees({ filter: { isActive: { eq: true } } });

  const dispatch = useDispatch();
  const snackbar = useCallback((...args) => dispatch(snackbarOn(...args)), [dispatch]);

  const handleOnComplete = async (values, stopLoading) => {
    const { tenantRep, ...rest } = values;
    const filledFormData = { ...rest, ...tenantRep };
    if (parent) {
      filledFormData.parent = { ...parent, employees };
    }

    if (!filledFormData.tenantId) filledFormData.tenantId = user.tenantId;
    if (!filledFormData.tenantCompanyId) filledFormData.tenantCompanyId = user.tenantCompanyId;
    try {
      const response = await new CustomerService().mutateTenantRep(filledFormData);

      if (response?.data) {
        snackbar('success', 'Successfuly added representative on our side');
        props.handleClose(true);
      }
    } catch (error) {
      logErrorWithCallback(
        error,
        (msg, errorObj) => snackbar('error', msg, errorObj),
        'Unable to create representative, please try again later'
      );
    } finally {
      stopLoading();
    }
  };

  if (localData) {
    // copy notes to customerNote as nested reference is not support in meta
    localData.customerNotes = data?.notes?.items;
    if (!localData.tenantRep) localData.tenantRep = '';
  }
  if (loading) return <Skeleton count={3} />;
  return (
    <ErrorBoundaries>
      <SergeantModal
        open={open}
        data={localData}
        dataType="representative on our side"
        mode={mode}
        formVersion={mode}
        layout={TenantRepLayout(sortArrayWithCaseInsensitive('name', employees))}
        handlePrimaryAction={handleOnComplete}
        handleClose={props.handleClose}
        customComponents={{
          NotesWrapper
        }}
      />
    </ErrorBoundaries>
  );
};

export default TenantRep;
