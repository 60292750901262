import React, { useCallback, useMemo, useState } from 'react';

import { Input } from '@buildhero/sergeant';
import { Search } from '@material-ui/icons';
import { debounce } from 'lodash';

const QuickFilter = (_, apiRef) => {
  const url = useMemo(() => new URL(window.location), []);

  const debouncedUpsertFilter = useCallback(
    debounce(value => {
      apiRef.current.upsertFilter({
        id: 1,
        columnField: 'quickFilter',
        operatorValue: 'contains',
        value
      });
    }, 500),
    [apiRef]
  );

  const [query, setQuery] = useState(() => {
    const q = url.searchParams.get('q');
    if (q) setTimeout(() => debouncedUpsertFilter(q), 100);
    return q;
  });

  const handleChange = useCallback(
    e => {
      const { value } = e.target;
      setQuery(value);
      debouncedUpsertFilter(value);
      url.searchParams.set('q', value);
      window.history.replaceState(null, '', url.toString());
    },
    [setQuery, debouncedUpsertFilter, url]
  );

  return (
    <Input
      placeholder="Search..."
      startAdornment={<Search fontSize="small" />}
      value={query}
      onChange={handleChange}
    />
  );
};

export default QuickFilter;
