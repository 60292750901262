import React, { useState, useEffect, useCallback } from 'react';
import { withTheme } from '@material-ui/core/styles';
import { Modal, DefaultButton } from 'components';
import { Divider } from '@material-ui/core';
import ErrorBoundaries from 'scenes/Error';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import Labels from 'meta/labels';
import { VisitCreationType } from 'utils/constants';
import { WarningMessage, VisitMessage } from '../constant';

const CreateVisitsModal = ({
  handleClose,
  user,
  openCreateVisitsModal,
  handleCreateVisits,
  classes,
  bulkVisitOption,
  disabledBulkVisit,
  isBulkFeatureDisabled
}) => {
  const [selectedValue, selectValue] = useState('');

  const setFormValue = useCallback(() => {
    bulkVisitOption || disabledBulkVisit || isBulkFeatureDisabled
      ? selectValue(VisitCreationType.MANUAL)
      : selectValue('');
  }, [bulkVisitOption, disabledBulkVisit, isBulkFeatureDisabled]);

  useEffect(() => {
    setFormValue();
  }, [setFormValue]);
  return (
    <ErrorBoundaries>
      <Modal
        open={openCreateVisitsModal || false}
        handleClose={() => {
          selectValue('');
          handleClose();
        }}
        classes={{
          dialogContent: classes.dialogContent,
          dialogContentGrid: classes.dialogContentGrid
        }}
      >
        <Typography variant="h3" classes={{ h3: classes.header }}>
          Create Visits
        </Typography>
        <FormControl>
          <RadioGroup
            aria-label="materialRates"
            name="materialRates"
            value={selectedValue}
            onChange={event => {
              selectValue(event.target.value);
            }}
          >
            <FormControlLabel
              value={VisitCreationType.BULK}
              control={<Radio disabled={bulkVisitOption || disabledBulkVisit} />}
              label="Bulk Visit Creation"
              classes={{ label: classes.formControlLabel }}
            />
            {bulkVisitOption && (
              <Typography variant="body2" className={classes.visitDescription}>
                <WarningIcon className={classes.warningIcon} />
                {WarningMessage.bulkVisitOption}
              </Typography>
            )}
            {disabledBulkVisit && (
              <Typography variant="body2" className={classes.visitDescription}>
                <WarningIcon className={classes.warningIcon} />
                {WarningMessage.disabledBulkVisit}
              </Typography>
            )}
            {!bulkVisitOption && !disabledBulkVisit && (
              <Typography variant="body2" className={classes.visitDescription}>
                {VisitMessage.BulkVisitMessage}
              </Typography>
            )}
            <FormControlLabel
              value={VisitCreationType.MANUAL}
              control={<Radio />}
              label="Manual Visit Creation"
              classes={{ label: classes.formControlLabel }}
            />
            <Typography variant="body2" className={classes.visitDescription}>
              {VisitMessage.ManualVisitMessage}
            </Typography>
          </RadioGroup>
        </FormControl>
        <Divider variant="fullWidth" style={{ marginTop: 24 }} />
        <DefaultButton
          variant="contained"
          color="primary"
          disabled={selectedValue === ''}
          label={Labels.createVisits[user.locale].toUpperCase()}
          style={{ width: '100%', marginTop: '20px' }}
          key="newserviceagreement"
          handle={event => {
            handleCreateVisits(event, selectedValue);
          }}
        />
      </Modal>
    </ErrorBoundaries>
  );
};

CreateVisitsModal.propTypes = {
  user: PropTypes.object.isRequired,
  openCreateVisitsModal: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  bulkVisitOption: PropTypes.bool.isRequired,
  disabledBulkVisit: PropTypes.bool.isRequired,
  handleCreateVisits: PropTypes.func.isRequired,
  classes: PropTypes.object,
  isBulkFeatureDisabled: PropTypes.bool
};

CreateVisitsModal.defaultProps = {
  openCreateVisitsModal: false,
  classes: {},
  isBulkFeatureDisabled: false
};

export default withTheme(CreateVisitsModal);
