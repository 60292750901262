export const useStyles = () => ({
  root: ({ default: { spacing } }) => ({
    marginTop: spacing(2)
  }),
  label: {
    fontSize: '10px',
    color: '#999999',
    textTransform: 'uppercase'
  }
});
