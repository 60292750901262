import { useCallback } from 'react';
import { pick } from 'ramda';
import useExtendedMutation from 'customHooks/useExtendedMutation';
import updatePurchaseOrder from 'services/core/graphql/review-report/mutations/UpdatePurchaseOrder';

const useUpdatePurchaseOrder = () =>
  useExtendedMutation(updatePurchaseOrder, {
    serializer: ({ tenantId, purchaseOrder }) => {
      const { dateOfPurchase, ...data } = purchaseOrder;
      return {
        variables: {
          partitionKey: tenantId,
          data: {
            ...data,
            dateOfPurchase: `${dateOfPurchase || ''}`
          }
        }
      };
    }
  });

export const useEditReviewReportPurchaseOrder = () => {
  const [updatePurchaseOrderMutation] = useUpdatePurchaseOrder();
  const updateMutation = useCallback(
    async ({ tenantId, purchaseOrder }) => {
      const data = pick(
        [
          'id',
          'version',
          'vendorName',
          'receiptNumber',
          'poNumber',
          'dateOfPurchase',
          'totalAmountPreTax',
          'tax',
          'departmentName'
        ],
        purchaseOrder
      );
      await updatePurchaseOrderMutation({ tenantId, purchaseOrder: data });
    },
    [updatePurchaseOrderMutation]
  );
  return [updateMutation];
};
