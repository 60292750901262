const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    boxShadow: 'none'
  },
  table: {
    minWidth: 600
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  locationText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    color: '#646464'
  },
  tableFooterToolbar: {
    backgroundColor: '#fff',
    fontSize: 12,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold'
  },
  greySectionTitle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.13,
    color: theme.palette.grayscale(25),
    marginTop: 60,
    marginBottom: 5
  },
  textStyle: {
    whiteSpace: 'nowrap',
    fontWeight: 'normal'
  },
  locationStyle: {
    fontSize: 11
  }
});

export default styles;
