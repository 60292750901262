import gql from 'graphql-tag';
import useExtendedLazyQuery from 'customHooks/useExtendedLazyQuery';

const defaultFragment = `
id
status
version
techReport
reviewStatus
reviewedBy
reviewedUnixDate
`;

const getVisitQuery = (fragment = defaultFragment) => gql`
  query getVisitById($id: String!) {
    getVisitById(id: $id) {
      ${fragment}
    }
  }
`;

const transform = data => {
  return data?.getVisitById;
};

export const useLazyVisitDetails = fields => {
  return useExtendedLazyQuery(getVisitQuery(fields), {
    serializer: id => ({
      variables: {
        id
      }
    }),
    fetchPolicy: 'no-cache', // since only select fields are queried not updating cache
    transform,
    defaultData: {
      visit: {
        job: {}
      }
    }
  });
};
