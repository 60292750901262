import React, { useEffect, useState } from 'react';

import { Marker } from '@react-google-maps/api';
import { func, number, shape, string } from 'prop-types';

import { useGeocoder } from '../../MapView.hooks';

import { selectAddressString } from './CustomMarker.selectors';
import { useStyles } from './CustomMarker.styles';

const CustomMarker = props => {
  const classes = useStyles();
  const [position, setPosition] = useState(props.position);
  const { loading, geocode } = useGeocoder();

  useEffect(() => {
    setPosition(props.position);
  }, [props.position]);

  const handlePositionUpdate = newPosition => {
    setPosition(newPosition);
    props.updateBounds(newPosition);
  };

  if (!(position?.lat && position?.lng)) {
    if (!loading) geocode(selectAddressString(props.address), handlePositionUpdate);
    return null;
  }

  return (
    <Marker
      position={position}
      icon={{
        url: props.image,
        size: new google.maps.Size(25, 75),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, 0),
        scaledSize: new google.maps.Size(25, 25)
      }}
      zIndex={props.zIndex}
      label={{
        text: props.label,
        className: classes.label
      }}
    />
  );
};

const ADDRESS_PROP = shape({
  addressLine1: string,
  addressLine2: string,
  addressType: string,
  city: string,
  latitude: number,
  longitude: number,
  state: string,
  zipcode: string
});

CustomMarker.propTypes = {
  address: ADDRESS_PROP,
  label: string,
  position: shape({
    lat: number,
    lng: number
  }),
  updateBounds: func.isRequired,
  // eslint-disable-next-line react/require-default-props
  zIndex: number
};

CustomMarker.defaultProps = {
  address: {},
  label: '',
  position: {}
};

export default CustomMarker;
