/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getScheduleOfValueLines(noInclude) {
  return await instance
    .get(`scheduleofvaluelines`, {
      params: {
        include: noInclude ? [] : '*'
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getScheduleOfValueLineById(id) {
  return await instance
    .get(`scheduleofvaluelines/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function scheduleOfValueLineCreate(payload) {
  return await instance
    .post('scheduleofvaluelines', payload)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function scheduleOfValueLineChange(id, payload) {
  return await instance
    .put(`scheduleofvaluelines/${id}`, payload)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
