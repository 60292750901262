import gql from 'graphql-tag';

const getCustomerRepsByCustomerById = gql`
  query getCustomerRepsByCustomerById($id: String!) {
    getCustomerById(id: $id) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      customerName
      customerTypeValue
      status
      email
      phonePrimary
      amountNotToExceed
      sameAddress
      receiveSMS
      customerReps(entityConnection: "CustomerRep", filter: { status: { eq: "active" } }) {
        items {
          id
          version
          tenantId
          partitionKey
          sortKey
          hierarchy
          entityType
          name
          firstName
          lastName
          email
          contactRole
          cellPhone
          landlinePhone
          isSmsOptOut
          bestContact
          emailInvoice
          status
          isActive
          notes(entityConnection: "Note") {
            items {
              id
              partitionKey
              sortKey
              hierarchy
              entityType
              subject
              note
              lastUpdatedBy
              lastUpdatedDate
              lastUpdatedDateTime
            }
          }
        }
      }
    }
  }
`;

export default getCustomerRepsByCustomerById;
