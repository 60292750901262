import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  label: {
    color: theme.palette.grayscale(60),
    fontSize: 10,
    letterSpacing: 0.01,
    fontWeight: 'normal',
    textTransform: 'uppercase',
    lineHeight: '14px',
    marginBottom: '0.35em'
  },
  requiredLabel: {
    fontSize: '8px',
    letterSpacing: 0,
    lineHeight: '14px'
  }
}));
