import gql from 'graphql-tag';

// The max size of the $invoiceIds array, due to an (undocumented, apparently)
// restriction in RDS Data Service API, on either the total number of params or
// the size of IN condition lists.
export const MAX_PAGE_SIZE = 10;

const getInvoiceBalanceData = gql`
  query getInvoiceBalanceData($partitionKey: String!, $sortKey: String!, $invoiceIds: [String]!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      paymentTotals: aggregatedField(
        input: {
          entityConnection: "PaymentInvoice"
          relationshipType: HierarchyChild
          aggregations: [
            { aggregationType: MIN, aggregationField: "PaymentInvoice.invoiceId" }
            { aggregationType: SUM, aggregationField: "PaymentInvoice.appliedAmount" }
          ]
          groupByFields: ["PaymentInvoice.invoiceId"]
          filter: {
            # Remove as soon as BUOP-3024 is fixed.
            filterRelatedEntityData: false
            integerFilters: [
              # Exclude deleted records
              { fieldName: "PaymentInvoice.deletedDate", filterInput: { is: null } }
            ]
            stringFilters: [
              { fieldName: "PaymentInvoice.invoiceId", filterInput: { in: $invoiceIds } }
            ]
          }
          # No way to make an unbounded query
          pagination: { limit: 1000000000 }
        }
      ) {
        items {
          invoiceId: aggregation1
          total: aggregation2
        }
      }
    }
  }
`;

export default getInvoiceBalanceData;
