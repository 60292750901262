import { useMemo } from 'react';
import gql from 'graphql-tag';

import useExtendedQuery from 'customHooks/useExtendedQuery';

import { JobTypes } from '@dispatch/Dispatch.constants';
import { GET_VISIT_DETAILS, selectDisplayAddress } from '@dispatch/queries';
import { nonVisitDetailsFragment } from '@dispatch/fragments/nonVisitDetailsFragment';
import { transformNonVisitEventData } from '@dispatch/Dispatch.utils';

const GET_NON_VISIT_EVENT = gql`
  query getNonVisitEventById($id: String!) {
    getNonVisitEventById(id: $id) {
      ${nonVisitDetailsFragment}
    }
  }
`;

const transformVisitResponse = data => {
  const propertyAddress = selectDisplayAddress(data?.visit);
  return {
    visitNumber: data?.visit?.visitNumber,
    property: {
      address: propertyAddress
    },
    job: {
      customIdentifier: data?.visit?.job?.customIdentifier,
      jobNumber: data?.visit?.job?.jobNumber,
      jobTypeInternal: data?.visit?.job?.jobTypeInternal || JobTypes.JOB.value.serverValue
    }
  };
};

const useBillableNonVisitEventDetails = eventId => {
  const eventResponse = useExtendedQuery(GET_NON_VISIT_EVENT, {
    transform: transformNonVisitEventData,
    variables: {
      id: eventId
    }
  });

  const visitId = eventResponse.data?.assignedEntityId;
  const visitResponse = useExtendedQuery(GET_VISIT_DETAILS, {
    transform: transformVisitResponse,
    skip: !visitId,
    variables: {
      id: visitId
    }
  });

  return useMemo(
    () => ({
      ...eventResponse,
      data: {
        ...eventResponse.data,
        visit: visitResponse.data
      }
    }),
    [eventResponse, visitResponse]
  );
};

export default useBillableNonVisitEventDetails;
