import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SettingsIcon from '@material-ui/icons/Settings';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
));

const LayoutMenuItem = withStyles(theme => ({
  root: {
    fontWeight: 'bold',
    float: 'left',
    textAlign: 'center',
    width: '25%',
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

const StyledIconButton = withStyles(theme => ({
  root: {
    minWidth: 24,
    width: 24,
    padding: 0
  }
}))(Button);

const ColumnLayoutButton = props => {
  const { filledColumns, handleColumnClick, layoutOptions, actionOptions } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLayoutItemClick = (e, option) => {
    e.stopPropagation();
    handleColumnClick(option);
    setSelectedIndex(option);
    setAnchorEl(null);
  };

  const handleActionItemClick = (e, index) => {
    e.stopPropagation();
    actionOptions[index].callback();
    setAnchorEl(null);
  };

  return (
    <div>
      <StyledIconButton
        aria-label="layout button"
        aria-controls="layout-button"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <SettingsIcon />
      </StyledIconButton>
      <StyledMenu
        id="layout-button"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disabled>
          <ListItemText>Number of Columns:</ListItemText>
        </MenuItem>
        {layoutOptions.map(option => (
          <LayoutMenuItem
            key={option}
            disabled={option < filledColumns}
            selected={option === selectedIndex}
            onClick={event => handleLayoutItemClick(event, option)}
          >
            <ListItemText primary={option} />
          </LayoutMenuItem>
        ))}
        <MenuItem disabled>
          <ListItemText>Actions:</ListItemText>
        </MenuItem>
        {actionOptions.map((option, index) => (
          <MenuItem key={option.label} onClick={event => handleActionItemClick(event, index)}>
            {option.icon === 'copy' && <FileCopyIcon style={{ fontSize: '1em', marginRight: 6 }} />}
            {option.icon === 'delete' && <DeleteIcon style={{ fontSize: '1em', marginRight: 6 }} />}
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

export default ColumnLayoutButton;
