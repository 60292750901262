export const useStyles = () => ({
  error: theme => {
    return {
      padding: theme.default.spacing(1),
      marginTop: theme.default.spacing(1),
      marginBottom: theme.default.spacing(1),
      backgroundColor: theme.palette.error.main,
      '& > *': {
        color: theme.palette.error.contrastText,
        textAlign: 'center',
        whiteSpace: 'pre-wrap'
      }
    };
  }
});
