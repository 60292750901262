import gql from 'graphql-tag';

const updateJobStatus = gql`
  mutation updateJobStatus($data: UpdateJobStatusInput!) {
    updateJobStatus(data: $data) {
      id
      status
      version
    }
  }
`;

export default updateJobStatus;
