import gql from 'graphql-tag';

const getPropertyAttachmentsByJob = gql`
  query getJobDetails($partitionKey: String!, $sortKey: String!) {
    getJob(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      tenantId
      hierarchy
      entityType
      jobNumber
      customIdentifier
      parentEntity {
        ... on CustomerProperty {
          companyName
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          attachments(entityConnection: "Attachment") {
            items {
              fileName
              customFileName
              fileUrl
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              description
              comment
              createdDate
              createdDateTime
              createdBy
            }
          }
        }
      }
    }
  }
`;

export default getPropertyAttachmentsByJob;
