import gql from 'graphql-tag';

export const getRecurringBillingItems = gql`
  query serviceAgreementId($id: String!) {
    serviceAgreement(id: $id) {
      id
      recurringMaintenanceBillingItems {
        id
        description
        name
        percentage
      }
    }
  }
`;

export const updateServiceAgreementSettingsMutation = gql`
  mutation serviceAgreementTransition($data: ServiceAgreementUpdateInput!) {
    serviceAgreementTransition(action: UPDATE_INVOICE_SETTINGS, data: $data) {
      id
      status
      version
      recurringMaintenanceBillingItems {
        id
        description
        name
        percentage
      }
    }
  }
`;
