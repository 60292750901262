/* eslint-disable no-restricted-globals */
import React from 'react';
import {
  TextField,
  withStyles,
  InputAdornment,
  Tooltip,
  Typography,
  Grid
} from '@material-ui/core';
import { calculateMarginFromMarkup, calculateMarkupFromMargin } from '@buildhero/math';
import { connect } from 'react-redux';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { Logger } from 'services/Logger';
import { checkPermission, getTenantSettingValueForKey, roundFloat } from 'utils';
import { PricingStrategy } from 'utils/constants';
import { PermissionConstants } from 'utils/AppConstants';
import { convertForMathLib } from 'utils/mathLibrary';
import evaluator from 'utils/evaluator';
import Context from 'components/Context';

import styles from './styles';

const FieldWrapper = props => {
  const { children, variant, classes, label, isNonMandatory, isMarkup } = props;
  if (!variant) return <>{children}</>;
  return (
    <div
      style={{
        flexDirection: 'column',
        width: '48%',
        marginRight: isMarkup ? 0 : 15,
        marginLeft: isMarkup ? 5 : 0
      }}
    >
      <Grid container justify="space-between">
        <Typography variant="caption" className={classes.labelStyle}>
          {label}
        </Typography>
        <Typography variant="caption" className={classes.labelStyle}>
          {!!isNonMandatory && 'Required'}
        </Typography>
      </Grid>

      {children}
    </div>
  );
};
// TODO: avoid using state and make it as fully controlled component
class CostMarkup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unitCost: null,
      unitCostText: '',
      markupValue: null,
      markupType: 'Percentage',
      unitPrice: null,
      unitPriceText: '',
      isLocalChange: false
    };
  }

  componentDidMount() {
    const { specialbehaviour, form } = this.props;
    const { unitPriceKey, markupTypeKey, markupKey, unitCostKey } = specialbehaviour;
    const { values } = form;
    const unitCost = unitCostKey && values && values[unitCostKey];
    const unitPrice = unitPriceKey && values && values[unitPriceKey];
    const markupType = (markupTypeKey && values && values[markupTypeKey]) || 'Percentage';
    const markupValue = (markupKey && values && values[markupKey]) || 0;
    const markupText = markupValue;

    let tieredPricingEnabled = null;
    let tieredPricingExpression = null;
    let isMarginEnabled = null;

    if (Context.getCompanyContext() && Context.getCompanyContext().getCompany) {
      const tiredPricingSetting = getTenantSettingValueForKey('enableTieredPricing');
      tieredPricingEnabled = tiredPricingSetting === 'true' || tiredPricingSetting === true;
      tieredPricingExpression = getTenantSettingValueForKey('tieredPricingExpression');
      isMarginEnabled = getTenantSettingValueForKey('pricingStrategy') === PricingStrategy.MARGIN;
      try {
        tieredPricingExpression =
          tieredPricingExpression && typeof tieredPricingExpression === 'string'
            ? JSON.parse(tieredPricingExpression)
            : tieredPricingExpression;
      } catch (err) {
        Logger.error('Unable to parse tiered pricing expression');
      }
    }
    const applyTierPricing = (specialbehaviour.applyTierPricing && tieredPricingEnabled) || false;

    this.setState({
      unitCost,
      unitCostText: `${unitCost || ''}`,
      unitPrice,
      unitPriceText: `${unitPrice || ''}`,
      markupText: isMarginEnabled
        ? convertForMathLib(calculateMarginFromMarkup, markupText)
        : markupText,
      markupValue,
      markupType,
      applyTierPricing,
      tieredPricingExpression,
      isMarginEnabled
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.isLocalChange) return;
    const { specialbehaviour, form } = this.props;
    const { unitPriceKey, markupKey, unitCostKey } = specialbehaviour;
    const { values } = form;
    const unitCost = (unitCostKey && values && values[unitCostKey]) || 0;
    const unitPrice = (unitPriceKey && values && values[unitPriceKey]) || 0;
    // const markupType = (markupTypeKey && values && values[markupTypeKey]) || 'Percentage';
    const markupValue = (markupKey && values && values[markupKey]) || 0;
    const markupText = this.state.isMarginEnabled
      ? convertForMathLib(calculateMarginFromMarkup, markupValue)
      : markupValue;
    let needUpdateState = false;
    let newStateValue = {};

    if (markupValue !== this.state.markupValue) {
      needUpdateState = true;
      newStateValue = { ...newStateValue, markupText, markupValue };
    }
    if (unitCost !== this.state.unitCost) {
      needUpdateState = true;
      newStateValue = { ...newStateValue, unitCost, unitCostText: `${unitCost}` };
    }
    if (unitPrice !== this.state.unitPrice) {
      needUpdateState = true;
      newStateValue = { ...newStateValue, unitPrice, unitPriceText: `${unitPrice}` };
    }
    if (needUpdateState) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ ...newStateValue });
    }
  }

  computeMarkupWithExpression = unitCost => {
    const { form } = this.props;
    const { values } = form;
    const { tieredPricingExpression } = this.state;
    if (!tieredPricingExpression) {
      return;
    }
    // if a user enters the unit cost value at first, before the value is reflected in formik, the tiered pricing is called without cost
    if (!values.unitCost) {
      values.unitCost = unitCost;
    }

    const markup = evaluator(tieredPricingExpression, values);
    this.setState({ unitPriceMsg: 'Tiered markup applied' });
    return markup;
  };

  setValuesInForm = (unitCost, unitPrice, markupValue, markupType) => {
    const { form, specialbehaviour } = this.props;
    const { values } = form;
    if (
      specialbehaviour &&
      (specialbehaviour.unitCostKey || specialbehaviour.markupKey || specialbehaviour.unitPriceKey)
    ) {
      const unitCostKey = specialbehaviour.unitCostKey || 'unitCost';
      const markupKey = specialbehaviour.markupKey || 'markupValue';
      const markupTypeKey = specialbehaviour.markupTypeKey || 'markupType';
      const unitPriceKey = specialbehaviour.unitPriceKey || 'unitPrice';

      form.setValues({
        ...values,
        [unitCostKey]: unitCost,
        [markupKey]: markupValue,
        [markupTypeKey]: markupType,
        [unitPriceKey]: unitPrice
      });
    } else {
      form.setValues({
        ...values,
        unitCost,
        unitPrice,
        markupType,
        markupValue
      });
    }
  };

  computeUnitPrice = (unitCost, markupValue, markupType) => {
    let unitPrice = parseFloat(unitCost);
    let updatedMarkupValue = markupValue;
    if (this.state.isMarginEnabled) {
      updatedMarkupValue = convertForMathLib(calculateMarkupFromMargin, markupValue);
    }
    if (markupValue && markupValue !== 0) {
      unitPrice =
        markupType === 'Percentage'
          ? (
              parseFloat(unitCost) +
              parseFloat(unitCost) * (parseFloat(updatedMarkupValue) / 100)
            ).toFixed(2)
          : (parseFloat(unitCost) + parseFloat(updatedMarkupValue)).toFixed(2);
    }

    return { unitPrice, unitPriceText: unitPrice };
  };

  computeMarkup = (unitCost, unitPrice) => {
    let markup = 0;
    const { applyTierPricing } = this.state;

    if (applyTierPricing && !unitPrice) {
      markup = this.computeMarkupWithExpression(unitCost);
    } else if (unitCost) {
      // to avoid infinity
      markup = (
        ((parseFloat(unitPrice) - parseFloat(unitCost)) / parseFloat(unitCost)) *
        100
      ).toFixed(2);
    }
    if (isNaN(markup)) {
      markup = 0;
    }

    if (this.state.isMarginEnabled) {
      markup = convertForMathLib(calculateMarginFromMarkup, markup);
    }

    return { markupValue: markup, markupText: `${markup}`, markupType: 'Percentage' };
  };

  handleUnitCostChange = event => {
    let changedUnitCost = event.target.value;
    changedUnitCost = changedUnitCost.replace(/,/g, '');
    const floatUnitCost = parseFloat(changedUnitCost || 0);
    this.setState({
      unitCost: floatUnitCost,
      unitCostText: event.target.value,
      isLocalChange: true
    });
  };

  handleUnitPriceChange = event => {
    this.setState({ unitPriceText: event.target.value, isLocalChange: true, unitPriceMsg: '' });
  };

  handleMarkupChange = event => {
    this.setState({ markupText: event.target.value, isLocalChange: true, unitPriceMsg: '' });
  };

  formatMarkup = event => {
    const { unitCost } = this.state;
    // replacing 0 in the front
    const markup =
      (event.target.value && event.target.value.replace(/^0+/, '')) || event.target.value;
    const type = 'Percentage';

    const markupValue = markup.endsWith('%')
      ? parseFloat(markup.substring(0, markup.length - 1))
      : parseFloat(markup);
    const { unitPrice, unitPriceText } = this.computeUnitPrice(unitCost, markupValue, type);
    this.setValuesInForm(unitCost, unitPrice, markupValue, type);
    this.setState({
      isLocalChange: false,
      markupValue,
      markupType: type,
      markupText: markup,
      unitPriceText,
      unitPriceMsg: ''
    });
  };

  formatUnitPrice = event => {
    const rawValue = event.target.value;
    const currentValue = (rawValue && parseFloat(rawValue.replace(/[^\d.]/g, ''))) || 0;
    let { markupValue, markupType, unitCost, markupText, unitCostText } = this.state;

    ({ markupValue, markupText, markupType } = this.computeMarkup(unitCost || 0, currentValue));

    const currencyFloat = currentValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    this.setValuesInForm(unitCost || 0, currentValue, markupValue, markupType);

    this.setState({
      isLocalChange: false,
      unitPriceText: currencyFloat,
      markupValue,
      markupType,
      markupText,
      unitCost,
      unitCostText,
      unitPriceMsg: ''
    });
  };

  formatUnitCost = event => {
    const { specialbehaviour } = this.props;
    const rawValue = event.target.value;
    const currentValue = (rawValue && parseFloat(rawValue.replace(/[^\d.]/g, ''))) || 0;
    const { applyTierPricing } = this.state;
    let { markupValue, markupType, unitCost, unitPrice, markupText, unitPriceText } = this.state;
    unitCost = currentValue === '' ? 0 : currentValue;

    if (unitCost !== 0) {
      if (applyTierPricing) {
        markupValue = this.computeMarkupWithExpression(unitCost);
        if (isNaN(markupValue)) {
          markupValue = 0;
        }
        markupText = `${markupValue}`;
        unitPrice = ''; // will always recalculate the price
      }

      // compute unit price only when it is null
      ({ unitPrice, unitPriceText } = this.computeUnitPrice(unitCost, markupValue, markupType));
    }
    unitPriceText = parseFloat(unitPrice)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    this.setValuesInForm(unitCost, unitPrice, markupValue, markupType);

    const currencyFloat = specialbehaviour?.customDecimals
      ? roundFloat(currentValue)
      : currentValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    this.setState({
      isLocalChange: false,
      unitCostText: `${currencyFloat || ''}`,
      unitPriceText,
      markupText
    });
  };

  render() {
    const { specialbehaviour, variant } = this.props;
    const { unitCostLabel, markupLabel, unitPriceLabel } = specialbehaviour;
    const { unitCostText, unitPriceText, markupText, unitPriceMsg, isMarginEnabled } = this.state;
    const finalMarkupLabel = isMarginEnabled ? 'Margin' : markupLabel || 'Markup';
    const finalUnitPriceLabel = unitPriceLabel || 'Unit price';
    const finalUnitCostLabel = unitCostLabel || 'Unit cost';
    const { classes, form } = this.props;
    const unitCostKey = (specialbehaviour && specialbehaviour.unitCostKey) || 'unitCost';
    const unitPriceKey = (specialbehaviour && specialbehaviour.unitPriceKey) || 'unitPrice';
    const markupKey = (specialbehaviour && specialbehaviour.markupKey) || 'markupValue';
    const { isNonMandatory } = specialbehaviour;

    return (
      <>
        {checkPermission('allow', PermissionConstants.DATA_VIEW_COST_DATA, this.props.user) &&
          !specialbehaviour.disableCostAndPrice && (
            <FieldWrapper
              variant={variant}
              label={finalUnitCostLabel}
              isNonMandatory={isNonMandatory}
              classes={classes}
            >
              <TextField
                margin="normal"
                variant={variant || 'filled'}
                label={!variant && (isNonMandatory ? finalUnitCostLabel : `*${finalUnitCostLabel}`)}
                helperText={form.errors && form.errors[unitCostKey]}
                error={form.errors && form.errors[unitCostKey]}
                value={unitCostText}
                fullWidth={!!variant}
                onChange={this.handleUnitCostChange}
                onBlur={this.formatUnitCost}
                className={!variant && classes.textField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      disableTypography
                      style={variant ? null : { marginTop: 22 }}
                    >
                      $
                    </InputAdornment>
                  )
                }}
              />
            </FieldWrapper>
          )}
        {checkPermission('allow', PermissionConstants.DATA_VIEW_PRICE_DATA, this.props.user) && (
          <FieldWrapper variant={variant} label={finalMarkupLabel} isMarkup classes={classes}>
            <TextField
              margin="normal"
              variant={variant || 'filled'}
              label={!variant && (isNonMandatory ? finalMarkupLabel : `*${finalMarkupLabel}`)}
              value={markupText}
              fullWidth={!!variant}
              onChange={this.handleMarkupChange}
              onBlur={this.formatMarkup}
              helperText={form.errors && form.errors[markupKey]}
              className={!variant && classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    disableTypography
                    style={variant ? null : { marginTop: 22 }}
                  >
                    %
                  </InputAdornment>
                )
              }}
            />
          </FieldWrapper>
        )}
        {checkPermission('allow', PermissionConstants.DATA_VIEW_PRICE_DATA, this.props.user) &&
          !specialbehaviour.disableCostAndPrice && (
            <FieldWrapper variant={variant} label={finalUnitPriceLabel} classes={classes}>
              <TextField
                margin="normal"
                variant={variant || 'filled'}
                label={
                  !variant && (isNonMandatory ? finalUnitPriceLabel : `*${finalUnitPriceLabel}`)
                }
                fullWidth={!!variant}
                helperText={unitPriceMsg || (form.errors && form.errors[unitPriceKey])}
                error={form.errors && form.errors[unitPriceKey]}
                value={unitPriceText}
                onChange={event => this.handleUnitPriceChange(event)}
                onBlur={this.formatUnitPrice}
                className={!variant && classes.textField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      disableTypography
                      style={variant ? null : { marginTop: 22 }}
                    >
                      $
                    </InputAdornment>
                  )
                }}
              />
            </FieldWrapper>
          )}
        <Tooltip
          title="Unit price will be calculated as Unit cost * Markup"
          style={{ width: 16, marginLeft: 5 }}
        >
          <InfoOutlined className={classes.infoIcon} />
        </Tooltip>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const styledCostMarkup = withStyles(styles, { withTheme: true })(CostMarkup);

export default connect(mapStateToProps)(styledCostMarkup);
