import gql from 'graphql-tag';

const createReviewReport = gql`
  mutation createReviewReport($partitionKey: String, $data: CreateReviewReportFromVisitInput!) {
    createReviewReportFromVisit(partitionKey: $partitionKey, data: $data) {
      id
      sortKey
    }
  }
`;

export default createReviewReport;
