import gql from 'graphql-tag';

const getAuditLogsByCustomerPropertyById = gql`
  query getAuditLogsByCustomerPropertyById($id: String!) {
    getCustomerPropertyById(id: $id) {
      id
      auditLogs(entityConnection: "AuditLogEntry") {
        items {
          auditedEntityType
          auditedEntityDisplayName
          executionType
          executedBy
          executedDateTime
          auditedEntityId
          auditedEntitySortKey
          auditedEntityParentId
          auditedEntityParentSortKey
          auditedEntityParentEntityType
          auditedEntityParentDisplayName
          changeLog
          customMessage
        }
      }
    }
  }
`;

export default getAuditLogsByCustomerPropertyById;
