import React, { useState, useRef } from 'react';
import { jsx } from '@emotion/react';
import uuidV4 from 'uuid/v4';
import { Draggable } from 'react-beautiful-dnd';
import { useTheme, Box, Grid, IconButton, InputBase } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { MultiSelect, Input, ButtonType } from '@buildhero/sergeant';
import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import Divider from '@material-ui/core/Divider';

import { TaskConstants } from 'utils/AppConstants';

import { PopperMenu, SgtAlgoliaMultiSelect } from 'components';

import CheckIcon from '@material-ui/icons/Check';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { bundleIndex } from 'constants/algoliaIndex';

import FormLineItem from './FormLineItem';
import PartsAndMaterialsLineItem from './PartsAndMaterialsLineItem';
import { useLazyFetchPricebookEntry } from './useLazyFetchPricebookEntry';

const style = {
  accordionContainer: {
    border: '1px solid #808080',
    borderRadius: '2px'
  },
  accordionDetailsContainer: {
    display: 'block',
    width: '100%',
    padding: '0px 8px'
  },
  dragHandler: {
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    cursor: 'grab'
  },
  dragIcon: {
    height: 24,
    width: 24,
    cursor: 'grab'
  },
  checkIcon: {
    height: 24,
    width: 24
  },
  checkContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  disabled: {
    cursor: 'not-allowed'
  },
  accordionSummary: {
    marginTop: 0,
    marginBottom: 0,
    minHeight: '32px !important',
    backgroundColor: '#fff !important',
    height: 32,
    boxShadow: 'none',
    paddingLeft: 12,
    paddingRight: 4
  },
  divider: {
    marginTop: 16,
    marginBottom: 16
  },
  partsAndMaterialsContainer: {
    marginBottom: 8
  }
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  marginBottom: '5px',

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'rgb(240, 240, 240)',

  // styles we need to apply on draggables
  ...draggableStyle
});

const TaskCard = ({
  item,
  index,
  tasks,
  initialTasks,
  setTasks,
  forms,
  suggestedForms,
  associatedParts,
  defaultPriceBookId,
  asset,
  showCheckmarks = false
}) => {
  const hasAssetAssociation = !!asset;

  const { palette } = useTheme();

  const [expanded, setExpanded] = useState(false);
  const [moreOptionPopperOpen, setMoreOptionPopperOpen] = useState(false);

  const fetchPricebookEntry = useLazyFetchPricebookEntry();

  const anchorRef = useRef();

  const confirmContext = useConfirmModal();

  const nameChange = ({ value }) => {
    if (tasks[index].name === value) return;
    const newTasks = tasks.map((t, i) => {
      if (i === index) return { ...t, name: value };
      return t;
    });
    setTasks(newTasks);
  };

  const descriptionChange = ({ currentValue }) => {
    if (tasks[index].description === currentValue) return;
    const newTasks = tasks.map((t, i) => {
      if (i === index) return { ...t, description: currentValue };
      return t;
    });
    setTasks(newTasks);
  };

  const checkChange = () => {
    const newTasks = tasks.map((t, i) => {
      const status =
        t.status === TaskConstants.COMPLETED ? TaskConstants.INJOB : TaskConstants.COMPLETED;
      if (i === index) return { ...t, status };
      return t;
    });
    setTasks(newTasks);
  };

  const removeTask = async () => {
    const task = tasks[index];
    if (initialTasks.some(t => t.id === task.id)) {
      if (
        await confirmContext.confirm({
          body: 'Are you sure you want to delete this task?',
          body2: task.name,
          title: 'Delete Task',
          buttonLabel: 'Delete',
          buttonType: ButtonType.ERROR
        })
      ) {
        const newTasks = tasks.filter((t, i) => i !== index);
        setTasks(newTasks);
      }
    } else {
      const newTasks = tasks.filter((t, i) => i !== index);
      setTasks(newTasks);
    }
  };

  const formsChange = newForms => {
    const newTasks = tasks.map((t, i) => {
      if (i === index) return { ...t, forms: newForms };
      return t;
    });
    setTasks(newTasks);
  };

  const partsAndMaterialsChange = async newPartsAndMaterials => {
    const localNewPartsAndMaterials = [...newPartsAndMaterials];
    const newlyAddedProductIndex = localNewPartsAndMaterials?.findIndex(
      i => i.value && !i?.value?.pricebookEntryId
    );
    const newlyAddedProduct = newPartsAndMaterials[newlyAddedProductIndex];
    if (newlyAddedProduct) {
      const pEntry = await fetchPricebookEntry({
        pricebookId: defaultPriceBookId,
        productSortKey: newlyAddedProduct.value.productSortKey
      });

      newlyAddedProduct.value.pricebookEntryId = pEntry.id;
      // find pricebookEntry
      localNewPartsAndMaterials.splice(newlyAddedProductIndex, 1, newlyAddedProduct);
    }

    const newTasks = tasks.map((t, i) => {
      if (i === index) return { ...t, partsAndMaterials: localNewPartsAndMaterials };
      return t;
    });
    setTasks(newTasks);
  };

  const addTask = () => {
    const task = { id: uuidV4(), name: '' };
    setTasks([...tasks, asset ? { ...task, assetId: asset.id } : task]);
  };

  const deleteDisabledExplaination = ({ taskStatus, originalTaskStatus }) => {
    if (taskStatus === TaskConstants.COMPLETED) {
      return '(cannot delete completed tasks)';
    }
    if (taskStatus !== TaskConstants.COMPLETED && originalTaskStatus === TaskConstants.COMPLETED) {
      return '(save the status change before deleting)';
    }
    return '';
  };

  const rowActionButtons = ({ taskStatus, originalTaskStatus }) => [
    {
      label: `Delete ${deleteDisabledExplaination({ taskStatus, originalTaskStatus })}`,
      icon: 'Delete',
      onClick: () => removeTask(),
      disabled:
        taskStatus === TaskConstants.COMPLETED || originalTaskStatus === TaskConstants.COMPLETED
    }
  ];

  const checkmarkDisabled = !tasks[index].originalStatus;

  return (
    <Draggable draggableId={`${item.id}`} index={index}>
      {(provided, snapshot) => (
        <div style={{ opacity: snapshot.isDragging ? 0.5 : 1 }}>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            <Box m={0} p={0} css={style.accordionContainer}>
              <MuiAccordion square expanded={expanded}>
                <MuiAccordionSummary css={style.accordionSummary}>
                  <div {...provided.dragHandleProps} css={style.dragHandler}>
                    <IconButton disableRipple css={style.dragIcon}>
                      <DragIndicatorIcon css={style.dragIcon} />
                    </IconButton>
                  </div>

                  {showCheckmarks && (
                    <Tooltip title={checkmarkDisabled ? 'Save Tasks before changing status' : ''}>
                      <div
                        css={{
                          ...style.checkContainer,
                          ...(checkmarkDisabled ? style.disabled : {})
                        }}
                      >
                        <IconButton
                          onClick={checkChange}
                          disableRipple
                          css={style.checkIcon}
                          disabled={checkmarkDisabled}
                        >
                          <CheckIcon
                            css={style.checkIcon}
                            style={{
                              color:
                                tasks[index].status === TaskConstants.COMPLETED
                                  ? palette.primary.main
                                  : palette.grayscale(80)
                            }}
                          />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  <Grid item xs={12} justify="flex-start">
                    <Grid container>
                      <Box component="div" width={1}>
                        <InputBase
                          label=""
                          autoFocus={index === tasks.length - 1 && tasks[index].name === ''}
                          defaultValue={item.name ?? ''}
                          placeholder="Enter Task..."
                          id={`scope-input-${item.id}`}
                          style={{ width: '100%', marginLeft: '20px', height: '32px' }}
                          onBlur={e => {
                            if (!e.target.value) {
                              removeTask();
                            } else {
                              nameChange({ value: e.target.value });
                            }
                            e.relatedTarget?.click();
                          }}
                          onKeyDown={e => {
                            if (e.key === 'Enter') addTask();
                          }}
                          inputProps={{ testingid: `${item.name ?? 'enter-task'}-${index}` }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item justify="flex-end">
                    <Tooltip title="Expand Task">
                      <IconButton
                        onClick={() => setExpanded(!expanded)}
                        disableRipple
                        style={{ padding: 0, marginTop: 4 }}
                        testingid={`task-${index}-openToggle`}
                      >
                        {expanded ? (
                          <ExpandLessIcon style={{ fontSize: 24 }} />
                        ) : (
                          <ExpandMoreIcon style={{ fontSize: 24 }} />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item justify="flex-end">
                    <IconButton
                      ref={anchorRef}
                      onClick={() => setMoreOptionPopperOpen(!moreOptionPopperOpen)}
                      disableRipple
                      style={{ padding: 0, marginTop: 4, marginLeft: 5 }}
                      testingid={`task-${index}-moreTaskActions`}
                    >
                      <MoreVertIcon style={{ fontSize: 24 }} />
                    </IconButton>
                    <PopperMenu
                      itemList={rowActionButtons({
                        originalTaskStatus: tasks[index].originalStatus,
                        taskStatus: tasks[index].status
                      })}
                      open={moreOptionPopperOpen}
                      onClose={() => setMoreOptionPopperOpen(false)}
                      anchorEl={anchorRef.current}
                    />
                  </Grid>
                </MuiAccordionSummary>
                <MuiAccordionDetails css={style.accordionDetailsContainer}>
                  <Input
                    label="Task Description"
                    placeholder="Task Description"
                    defaultValue={item.description}
                    style={{ width: '100%' }}
                    onBlur={e => descriptionChange({ currentValue: e.target.value })}
                    multiline
                    inputProps={{ testingid: `${item.name ?? 'task'}-description-${index}` }}
                  />
                  <Divider css={style.divider} />
                  <MultiSelect
                    grouped={hasAssetAssociation}
                    lineItemComponent={(option, selectedOptions) => (
                      <FormLineItem
                        key={option.id}
                        formsChange={formsChange}
                        option={option}
                        selectedOptions={selectedOptions}
                      />
                    )}
                    onChange={formsChange}
                    options={forms}
                    placeholder="Search & Select Forms"
                    showChips
                    showSearchIcon
                    suggestedOptions={suggestedForms}
                    selectedOptions={item.forms ?? []}
                    suggestedOptionsLabel="Suggested forms"
                    topLevelSelectAllLabel="Select All Forms"
                    label="Forms"
                  />
                  <Divider css={style.divider} />
                  <div css={style.partsAndMaterialsContainer}>
                    <SgtAlgoliaMultiSelect
                      options={{
                        label: 'Parts & Materials',
                        grouped: hasAssetAssociation,
                        lineItemComponent: (option, selectedOptions) => (
                          <PartsAndMaterialsLineItem
                            key={option.id}
                            partsAndMaterialsChange={partsAndMaterialsChange}
                            option={option}
                            selectedOptions={selectedOptions}
                          />
                        ),
                        onChange: partsAndMaterialsChange,
                        options: [],
                        placeholder: 'Search & Select Parts & Materials',
                        showChips: true,
                        showSearchIcon: true,
                        suggestedOptions: associatedParts,
                        suggestedOptionsLabel: 'Associated Parts & Materials',
                        selectedOptions: item.partsAndMaterials ?? [],
                        topLevelSelectAllLabel: 'Select All Parts & Materials',
                        persistSuggestions: true,
                        restructureAlgoliaHitToOptions: hit => {
                          return [
                            {
                              id: hit.id,
                              label: hit.name,
                              rightLabel: hit.description,
                              value: {
                                productId: hit.id,
                                name: hit.name,
                                code: hit.code,
                                description: hit.description,
                                productSortKey: hit.sortKey,
                                quantity: 1
                              }
                            }
                          ];
                        },
                        algoliaFilter: `entityType:Product`,
                        algoliaIndex: bundleIndex
                      }}
                    />
                  </div>
                </MuiAccordionDetails>
              </MuiAccordion>
            </Box>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default TaskCard;
