import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sentryException } from 'services/Logger';

import { logErrorWithCallback, checkPermission, isJSONParseableObjectOrArray } from 'utils';

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { Grid, Divider } from '@material-ui/core';

import AmplifyService from 'services/AmplifyService';
import { MUIForm } from '@buildhero/sergeant';

import { snackbarOn } from 'redux/actions/globalActions';

import ErrorBoundaries from 'scenes/Error';

import { PageHeader, withLoading, DefaultButton } from 'components';

import Labels from 'meta/labels';
import { serviceAgreementSetting } from 'meta/Settings/ServiceAgreements/ServiceAgreementsMeta';

import { Mode } from 'utils/constants';
import { PermissionConstants } from 'utils/AppConstants';

import sum from 'lodash/sum';
import styles from './styles';
import {
  GetCompanyServiceAgreementSettings,
  CreateCompanyServiceAgreementSettings,
  UpdateCompanyServiceAgreementSettings
} from './gql';

import { RecurringMaintenanceBilling } from './RecurringMaintenanceBilling';

const loadingParams = {
  variant: 'table',
  repeatCount: 5,
  paddingTop: 12,
  paddingLeft: 8,
  paddingRight: 8
};

const MuiFormWithLoading = withLoading(MUIForm);

const defaultSettings = {
  enableTechnicianSelectionAtMaintenanceTemplateLevel: false,
  enableAutoVisitCreation: false,
  autoDateAssignment: 'Do Not Assign a Date',
  enableBulkVisitCreation: false,
  recurringMaintenanceBillingItems: []
};

const ServiceAgreementPage = ({ user, ...props }) => {
  const { client } = AmplifyService.appSyncClient();
  const [muiService, setMuiService] = useState();
  const [companySettingItem, setCompanySettingItem] = useState();
  const [isTotalPercentageError, setIsTotalPercentageError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { tenantId, tenantCompanyId } = user;

  const classes = styles();
  document.title = 'BuildOps - Service Agreement';

  const isAllowedToEdit = checkPermission(
    Mode.EDIT,
    PermissionConstants.OBJECT_SERVICE_AGREEMENT,
    user
  );

  useEffect(() => {
    setLoaded(false);
    const getServiceAgreementSettings = async () => {
      try {
        const { data } = await client.query({
          query: GetCompanyServiceAgreementSettings,
          variables: {
            partitionKey: tenantId,
            sortKey: `${tenantId}_Company_${tenantCompanyId}`
          }
        });
        const itemZero = data?.getCompany?.companySettings?.items[0];
        setCompanySettingItem(itemZero);
        if (data?.getCompany?.companySettings?.items?.length > 1) {
          sentryException('Conflicting Service Agreement Settings Data', {
            conflictingItems: data?.getCompany?.companySettings?.items
          });
        }

        if (itemZero && !isJSONParseableObjectOrArray(itemZero.settings)) {
          sentryException('Corrupt Service Agreement Settings Data', {
            conflictingData: itemZero.settings
          });
          props.snackbarOn('error', 'Corrupt Settings Data - Contact Support');
        }

        setLoaded(true);
      } catch (err) {
        setLoaded(true);
        logErrorWithCallback(
          err,
          props.snackbarOn,
          err.message ? err.message : 'Failed to load company service agreement settings'
        );
      }
    };

    getServiceAgreementSettings();
    // only want to run this useEffect code on page load.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateSettings = formData => {
    const { recurringMaintenanceBillingItems } = formData;
    const percentages = recurringMaintenanceBillingItems.map(({ percentage }) =>
      Number(percentage)
    );
    const isTotalPercentageError = sum(percentages) !== 100;

    setIsTotalPercentageError(isTotalPercentageError);

    return !isTotalPercentageError;
  };

  const saveServiceAgreementSettings = async formData => {
    setLoaded(false);

    if (!validateSettings(formData)) {
      props.snackbarOn(
        'error',
        `Unable to save changes because total percentage allocated isn’t 100%. 
        Please ensure that you’ve entered all values correctly before saving.`
      );
      setCompanySettingItem({ ...companySettingItem, settings: JSON.stringify(formData) });
      setLoaded(true);
      return;
    }

    try {
      if (!companySettingItem) {
        const { data } = await client.mutate({
          mutation: CreateCompanyServiceAgreementSettings,
          variables: {
            partitionKey: tenantId,
            companyId: tenantCompanyId,
            settings: JSON.stringify(formData)
          }
        });
        setCompanySettingItem(data?.addCompanySettingsToCompany[0]);
      } else {
        /* 
          @TODO - BACKEND - BLOCKER - getting error GraphQL error:
          The address https://sqs.us-east-1.amazonaws.com/
          is not valid for this endpoint.

          However, the mutation is still being applied and the setting are
          updated & correct settings are shown on page refresh

          May just be an issue with local backend
        */
        const { data } = await client.mutate({
          mutation: UpdateCompanyServiceAgreementSettings,
          variables: {
            ...companySettingItem,
            partitionKey: tenantId,
            settings: JSON.stringify(formData)
          }
        });
        setCompanySettingItem(data?.updateCompanySetting);
      }
    } catch (err) {
      logErrorWithCallback(
        err,
        props.snackbarOn,
        err.message ? err.message : 'Failed to create company service agreement settings'
      );
    } finally {
      setLoaded(true);
    }
  };

  const headerButtons = (
    <DefaultButton
      disabled={!loaded}
      color="primary"
      label="Save"
      style={{ float: 'right' }}
      handle={() => muiService?.submit()}
    />
  );

  const formEditLayout = companySettingItem ? Mode.EDIT : Mode.ADD;

  const formData =
    companySettingItem && isJSONParseableObjectOrArray(companySettingItem.settings)
      ? JSON.parse(companySettingItem.settings)
      : defaultSettings;

  return (
    <ErrorBoundaries>
      <Grid container direction="column">
        <PageHeader
          title={Labels.serviceAgreementSettings[user.locale]}
          breadcrumbsArray={[
            { link: '', title: Labels.settings[user.locale] },
            { link: '', title: Labels.serviceAgreements[user.locale] }
          ]}
          iconComponent={<SettingsOutlinedIcon className={classes.settingIcon} />}
          overrideHeaderButtons={isAllowedToEdit ? headerButtons : ''}
        />
        <Grid item>
          <Divider />
          <MuiFormWithLoading
            configuration={serviceAgreementSetting(isTotalPercentageError)}
            data={formData}
            loadingParams={loadingParams}
            layout={isAllowedToEdit ? formEditLayout : Mode.VIEW}
            onCreateService={service => setMuiService(service)}
            onComplete={saveServiceAgreementSettings}
            isLoading={!loaded}
            customComponents={{ RecurringMaintenanceBilling }}
          />
        </Grid>
      </Grid>
    </ErrorBoundaries>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = { snackbarOn };

const reduxConnectedServiceAgreementPage = connect(
  mapStateToProps,
  mapDispatcherToProps
)(ServiceAgreementPage);

export default reduxConnectedServiceAgreementPage;
