import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';

const GENERATE_WIP_REPORT = gql`
  mutation generateWIPReport {
    generateWIPReport(input: {})
      @rest(type: "WIPReport", path: "wipreports/generate", endpoint: "pmapi", method: "POST") {
      id
      actualCostsToDate
      billedToDate
      costToComplete
      createdBy
      createdDate
      overBilled
      recordNumber
      revenueCompletedToDate
      status
      syncStatus
      tenantId
      tenantCompanyId
      underBilled
      updatedContractsAmount
      updatedCostBudgets
      updatedEstimatedGrossProfits
    }
  }
`;

export const useGenerateWIPReport = (options = {}) => {
  return useExtendedMutation(GENERATE_WIP_REPORT, {
    transform: data => data?.generateWIPReport,
    ...options
  });
};
