import gql from 'graphql-tag';

const saveAssetTypeToCompany = gql`
  mutation saveAssetTypeToCompany($partitionKey: String!, $data: AddAssetTypeToCompanyInput!) {
    saveAssetTypeToCompany(partitionKey: $partitionKey, data: $data) {
      id
      version
      tagName
      sortOrder
    }
  }
`;

export default saveAssetTypeToCompany;
