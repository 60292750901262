import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Typography, TV, TW } from '@buildhero/sergeant';
import { AuditLogs } from 'components';
import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.grayscale(0)
  }
}));

const ActivityList = ({ data, loading }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Typography variant={TV.BASE} weight={TW.BOLD} className={classes.title}>
        Activity
      </Typography>
      {loading ? (
        <Skeleton height={100} count={7} />
      ) : (
        <AuditLogs auditLogData={data?.auditLogs?.items || []} variant="singleEntity" />
      )}
    </Box>
  );
};

export default ActivityList;
