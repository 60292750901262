const initialState = {
  settings: {
    accountingApp: null
  },
  options: {
    departments: [],
    priceBooks: []
  },
  company: {
    id: null,
    defaultPriceBookId: null,
    tenant: {}
  },
  companySettings: {},
  user: {
    username: null,
    email: null,
    displayName: '',
    phone: null,
    locale: 'en',
    timeZone: null,
    isAuthenticated: false,
    tenantId: '',
    tenantCompanyId: '',
    appPermissionRules: '',
    cognitoRole: '',
    employeeId: '',
    tenantSettings: null
  },
  launchDarklyFlags: {},
  application: {
    spinner: null,
    pageMode: null,
    mutationConfirmation: {
      open: false
    }
  },
  dispatch: {
    refreshTrigger: true,
    refreshQuietly: false,
    multipleVisitsCreateInProcess: false
  },
  pageNotification: ['off', ''],
  alert: {
    title: null,
    message: null,
    buttonArray: [],
    callBackFunctions: []
  },
  entities: null,
  components: null,
  menu: {
    appName: 'crm',
    isOpen: true,
    breadcrumb: 'home'
  },
  contextNotification: null,
  visitUpdateNotification: null,
  openQuickAddModal: null,
  pm: {
    projectDetailsRefetch: false,
    project: {
      summaryLoaded: false,
      detailsLoaded: false
    },
    projectKpis: {
      loaded: false
    },
    timezone: {
      timezone: '',
      loading: false
    }
  },
  jobs: {
    currentJob: null,
    error: null,
    isLoading: false
  }
};
export default initialState;
