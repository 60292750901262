import gql from 'graphql-tag';

export const addTagsToServiceAgreementMutation = gql`
  mutation addTagsToServiceAgreementMutation($data: AddServiceAgreementTagToServiceAgreement!) {
    addTagsToServiceAgreement(data: $data) {
      id
      serviceAgreementTag {
        id
        tagName
      }
    }
  }
`;

export const deleteServiceAgreementServiceAgreementTagMutation = gql`
  mutation deleteServiceAgreementServiceAgreementTagMutation($id: String!) {
    deleteServiceAgreementServiceAgreementTag(id: $id) {
      id
    }
  }
`;

export const addServiceAgreementToCompanyMutation = gql`
  mutation addServiceAgreementToCompanyMutation($data: [ServiceAgreementTagInput]!) {
    saveServiceAgreementTag(data: $data) {
      id
      tagName
      sortOrder
      isActive
      tenantId
      tenantCompanyId
    }
  }
`;

export const softDeleteServiceAgreementTagMutation = gql`
  mutation softDeleteServiceAgreementTagMutation($id: String!) {
    softDeleteServiceAgreementTag(id: $id) {
      id
    }
  }
`;
