import React from 'react';
import { Logger } from 'services/Logger';

const withErrorBoundary = ErrorComponent => Component => {
  class ErrorBoundary extends React.Component {
    // eslint-disable-next-line react/state-in-constructor
    state = { error: null };

    static getDerivedStateFromError(error) {
      return { error };
    }

    componentDidCatch(error) {
      Logger.error(error);
    }

    render() {
      const error = this.state.error || (this.props.error && !this.props.loading);

      if (ErrorComponent && error) return <ErrorComponent {...this.props} error={error} />;

      return <Component {...this.props} error={error} />;
    }
  }

  return ErrorBoundary;
};

export default withErrorBoundary;
