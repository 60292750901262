import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import simpleRowActionButtons from 'meta/ProjectManagement/global/simpleTableRowActionButton';
import { DefaultButton, SergeantModal } from 'components';
import ResponsiveTable from 'components/ResponsiveTable';
import { getPhasesByProject, phaseDelete } from 'services/API/projectPhases';
import buildHeroMuiFormOverrides from 'scenes/ProjectManagement/components/buildHeroMuiFormOverrides';
import { budgetRowMeta } from '../layout';
import PhaseModal from './PhaseModal';
import { calcContractValue } from './utils';

const useStyles = makeStyles(theme => ({
  addButton: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '14px',
    textDecoration: 'none',
    marginTop: '10px',
    marginLeft: '5px'
  },
  sectionTitle: {
    color: '#000000',
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: '-0.03em',
    marginBottom: 5
  },
  tableHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 30,
    marginBottom: 20
  },
  tableHeader: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  customInput: {
    '& input': {
      textAlign: 'right',
      paddingRight: '0.5em'
    }
  },
  customFormContainer: {
    margin: '4px 0px',
    paddingLeft: 4,
    background: '#F0F0F0',
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
      marginTop: 0
    },
    '& .MuiTypography-colorTextSecondary': {
      color: '#333333',
      fontSize: 14
    },
    '& .MuiInputBase-inputMarginDense': {
      fontSize: 14,
      color: '#333333',
      lineHeight: '16px',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'right'
    }
  },
  formContainer: buildHeroMuiFormOverrides(theme),
  deleteButton: {
    background: theme?.palette?.error?.light
  }
}));

const Phases = props => {
  const { projectId, startingContractValue } = props;
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [phaseToEdit, setPhaseToEdit] = useState({});
  const [allPhases, setAllPhases] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState({
    confirmAction: () => {},
    confirmDialog: false,
    confirmTitle: ''
  });
  const [contractValue, setContractValue] = useState(startingContractValue);
  const classes = useStyles();

  const getPhaseData = useCallback(async () => {
    const newPhases = await getPhasesByProject(projectId);
    setAllPhases(newPhases);
  }, [projectId]);

  useEffect(() => {
    getPhaseData();
  }, [getPhaseData, projectId, openCreateModal, openEditModal]);

  useEffect(() => {
    let newContractValue = startingContractValue;
    // contract value remaining is determined by all phase dept values
    allPhases.forEach(phase => {
      const phaseDepts = phase.ProjectPhaseDepartment || [];
      const deptIdsToIgnore = phaseToEdit?.ProjectPhaseDepartment?.map(dept => dept.id) || [];
      phaseDepts.forEach(dept => {
        // ignore the depts that will be edited as their values will be calculated in the
        // PhaseForm component.
        if (deptIdsToIgnore.includes(dept.id)) return;
        newContractValue = calcContractValue(dept, newContractValue);
      });
    });
    setContractValue(newContractValue);
  }, [allPhases, phaseToEdit, startingContractValue]);

  const handleCancelConfirmation = () =>
    setConfirmDelete({ confirmAction: () => {}, confirmDialog: false, confirmTitle: '' });

  const deleteAction = async (record, stopLoading) => {
    await phaseDelete(record.id);
    setConfirmDelete({ confirmAction: () => {}, confirmDialog: false, confirmTitle: '' });
    getPhaseData();
    stopLoading();
  };

  const handlePhaseRowactions = async (actionType, record) => {
    if (actionType === 'remove') {
      setConfirmDelete({
        confirmAction: (data, stopLoading) => deleteAction(record, stopLoading),
        confirmDialog: true,
        confirmTitle: `Delete Phase ${record.number} (${record.name})`
      });
    } else if (actionType === 'edit') {
      setPhaseToEdit(record);
      setOpenEditModal(true);
    }
  };

  return (
    <div>
      <div>
        <div className={classes.tableHeaderContainer}>
          <Typography className={classes.tableHeader}>Phases</Typography>
          <DefaultButton
            label="Add Phase"
            onClick={() => {
              setPhaseToEdit({});
              setOpenCreateModal(true);
            }}
            variant="containedPrimary"
            style={{ float: 'right', height: 30, fontSize: 12 }}
            buttonProps={{ startIcon: <AddCircleOutlineIcon style={{ fontSize: 14 }} /> }}
          />
        </div>
        <ResponsiveTable
          rowMetadata={budgetRowMeta}
          data={allPhases}
          disableFilter
          rowActionButtons={simpleRowActionButtons}
          rowActions={handlePhaseRowactions}
          noDataMsg="No Budget Entries found"
          defaults={{
            sortBy: 'number',
            sortOrder: 'asc'
          }}
        />
      </div>
      <PhaseModal
        isOpen={openCreateModal}
        setIsOpen={setOpenCreateModal}
        projectId={projectId}
        phaseData={{}}
        startingContractValue={contractValue}
        onSubmit={getPhaseData}
        classes={classes}
      />
      <PhaseModal
        isOpen={openEditModal}
        setIsOpen={setOpenEditModal}
        projectId={projectId}
        phaseData={phaseToEdit}
        startingContractValue={contractValue}
        onSubmit={getPhaseData}
        classes={classes}
        mode="edit"
      />
      <SergeantModal
        open={confirmDelete.confirmDialog}
        title={confirmDelete.confirmTitle}
        customPrimaryButtonLabel="Delete"
        dataType="Item"
        mode="delete"
        customComponents={{}}
        handlePrimaryAction={confirmDelete.confirmAction}
        handleClose={handleCancelConfirmation}
        alignCloseRight
      />
    </div>
  );
};

Phases.propTypes = {
  projectId: PropTypes.string.isRequired,
  startingContractValue: PropTypes.number.isRequired
};

export default Phases;
