import { connect } from 'react-redux';

import ProjectInfo from './ProjectInfo.component';

const mapStateToProps = state => ({
  project: state.pm.project,
  projectKpis: state.pm.projectKpis
});

export default connect(mapStateToProps)(ProjectInfo);
