import gql from 'graphql-tag';
import { getHttpClient } from 'client';
import { handleError } from 'scenes/Dispatch/Dispatch.utils';
import { sentryMessage } from 'services/Logger';

const CUSTOMER_QUERY = gql`
  query Get($id: String!) {
    related: getCustomerById(id: $id) {
      priceBook {
        value: id
        label: name
      }
      companyAddresses(filter: { addressType: { eq: "billingAddress" } }) {
        items {
          addressType
          billTo
          addressLine1
          addressLine2
          city
          state
          zipcode
        }
      }
    }
  }
`;

const CUSTOMER_PROPERTY_QUERY = gql`
  query Get($id: String!) {
    related: getCustomerPropertyById(id: $id) {
      billTo
      customer: parentEntity {
        ... on Customer {
          priceBook {
            label: name
            value: id
          }
        }
      }
      companyAddresses(filter: { addressType: { eq: "propertyAddress" } }) {
        items {
          addressType
          addressLine1
          addressLine2
          city
          state
          zipcode
        }
      }
      billingCustomer {
        companyAddresses(filter: { addressType: { eq: "billingAddress" } }) {
          items {
            addressType
            billTo
            addressLine1
            addressLine2
            city
            state
            zipcode
          }
        }
      }
    }
  }
`;

const JOB_QUERY = gql`
  query Get($id: String!) {
    related: getJobById(id: $id) {
      departments {
        items {
          mappedEntity {
            ... on Department {
              value: id
              label: tagName
            }
          }
        }
      }
      priceBook {
        value: id
        label: name
      }
      property: customerProperty {
        billTo
        companyAddresses(filter: { addressType: { eq: "propertyAddress" } }) {
          items {
            addressType
            addressLine1
            addressLine2
            city
            state
            zipcode
          }
        }
      }
      billingCustomer {
        companyAddresses(filter: { addressType: { eq: "billingAddress" } }) {
          items {
            addressType
            billTo
            addressLine1
            addressLine2
            city
            state
            zipcode
          }
        }
      }
    }
  }
`;

const SERVICE_AGREEMENT_QUERY = gql`
  query Get($id: String!) {
    related: serviceAgreement(id: $id) {
      department {
        value: id
        label: tagName
      }
      priceBook {
        value: id
        label: name
      }
      propertiesJSON
      billingCustomer {
        companyAddresses(filter: { addressType: { eq: "billingAddress" } }) {
          items {
            addressType
            billTo
            addressLine1
            addressLine2
            city
            state
            zipcode
          }
        }
      }
    }
  }
`;

const PROJECT_QUERY = gql`
  query Get($id: String!) {
    related: getProjectById(id: $id) {
      address1
      address2
      addressCity
      addressState
      addressPostal
      department {
        value: id
        label: tagName
      }
      property {
        billTo
        companyAddresses(filter: { addressType: { eq: "propertyAddress" } }) {
          items {
            addressType
            addressLine1
            addressLine2
            city
            state
            zipcode
          }
        }
      }
      billingCustomer {
        companyAddresses(filter: { addressType: { eq: "billingAddress" } }) {
          items {
            addressType
            billTo
            addressLine1
            addressLine2
            city
            state
            zipcode
          }
        }
      }
    }
  }
`;

const PROPERTY_ADDRESS_BY_PROPERTYID = gql`
  query getPropertyAddress($id: String!) {
    getCustomerPropertyById(id: $id) {
      id
      companyAddresses(filter: { addressType: { eq: "propertyAddress" } }) {
        items {
          addressType
          addressLine1
          addressLine2
          city
          state
          zipcode
        }
      }
    }
  }
`;

const queries = {
  Customer: CUSTOMER_QUERY,
  CustomerProperty: CUSTOMER_PROPERTY_QUERY,
  Job: JOB_QUERY,
  ServiceAgreement: SERVICE_AGREEMENT_QUERY,
  Project: PROJECT_QUERY
};

const getAddress = a => {
  if (!a?.companyAddresses?.items) return;

  const goodAddressCandidates = a.companyAddresses.items.filter(address => !!address.addressLine1);
  if (a?.companyAddresses?.items.length !== goodAddressCandidates.length) {
    sentryMessage('Address(es) Missing Line 1', {
      addressCandidates: a?.companyAddresses?.items
    });
  }
  if (!goodAddressCandidates.length) return a.companyAddresses.items?.[0];

  return goodAddressCandidates[0];
};

const getProjectAddressObj = p => ({
  addressLine1: p.address1,
  addressLine2: p.address2,
  city: p.addressCity,
  state: p.addressState,
  zipcode: p.addressPostal
});

const getPropertyAddressForSA = async ({ propertiesJSON = '[]' }) => {
  try {
    const propertyArr = JSON.parse(propertiesJSON);
    if (propertyArr.length === 1) {
      const { data } = await getHttpClient().query({
        query: PROPERTY_ADDRESS_BY_PROPERTYID,
        variables: { id: propertyArr[0].value },
        fetchPolicy: 'no-cache'
      });
      return data?.getCustomerPropertyById?.companyAddresses?.items?.[0];
    }
  } catch (e) {
    handleError({ e, errContext: 'propertyAddressForSA', hideError: true });
  }
};

const parseAddresses = {
  Customer: c => [getAddress(c)],
  CustomerProperty: p => [getAddress(p.billingCustomer), getAddress(p)],
  Job: j => [getAddress(j.billingCustomer), getAddress(j.property)],
  ServiceAgreement: s => [getAddress(s.billingCustomer), s.propertyAddress], // property address is fetched and set in data
  Project: p => [
    getAddress(p.billingCustomer),
    p.addressPostal ? getProjectAddressObj(p) : getAddress(p.property)
  ]
};

export const getRelatedData = async (entityType, id, defaultPriceBook) => {
  try {
    const {
      data: { related }
    } = await getHttpClient().query({
      query: queries[entityType],
      variables: { id },
      fetchPolicy: 'no-cache'
    });
    const data = { ...related };

    // used for vista: limit department selection to those of the selected job
    const jobDepartmentOptions = data.departments?.items?.map(({ mappedEntity }) => mappedEntity);

    if (data.departments?.items?.[0]) {
      // jobs can have multiple departments. Get first one
      data.department = data.departments.items[0].mappedEntity;
    }

    if (data.propertiesJSON) {
      // if there is only one property in SA, populate the property address
      data.propertyAddress = await getPropertyAddressForSA(data);
    }

    const [billingAddress, propertyAddress] = parseAddresses[entityType](data);

    return {
      department: data.department,
      priceBook: data.priceBook || data?.customer?.priceBook || defaultPriceBook,
      billTo: data.billTo || data.property?.billTo || billingAddress?.billTo,
      parentId: id,
      parentType: entityType,
      billingAddress,
      propertyAddress,
      jobDepartmentOptions
    };
  } catch (err) {
    handleError({ err, errContext: 'relatedData' });
  }
};
