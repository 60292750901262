import gql from 'graphql-tag';

const updateTaxRate = gql`
  mutation updateTaxRate($partitionKey: String!, $data: UpdateTaxRateInput!) {
    updateTaxRate(partitionKey: $partitionKey, data: $data) {
      id
      name
      city
      county
      state
      taxRate
      accountType
      version
      lastUpdatedDateTime
    }
  }
`;

export default updateTaxRate;
