import gql from 'graphql-tag';

const getCompanyAttributes = gql`
  query getCompany(
    $partitionKey: String!
    $sortKey: String!
    $tenantPartitionKey: String!
    $tenantSortKey: String!
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      logoUrl
      companyName
      email
      fax
      tenant {
        type
      }
      forms {
        items {
          id
          name
          latestPublishedFormDefinition {
            id
          }
          latestPublishedFormDefinitionSortKey
          sortKey
          associatedEntityType
        }
      }
      userSettings(
        entityConnection: "UserSetting"
        filter: { type: { eq: "INVOICE_PRESET_SETTING" } }
      ) {
        nextToken
        items {
          type
          displayName
          id
          settings
        }
      }
      phonePrimary
      websiteUrl
      status
      termsOfService
      tenantId
      partitionKey
      sortKey
      hierarchy
      entityType
      version
      defaultPaymentTermId
      defaultPaymentTerm {
        id
        name
        value
        type
      }
      pricebookVersion
      defaultPriceBookId
      defaultPriceBook {
        name
        id
      }
      companySettings(entityConnection: "CompanySetting", filter: { isActive: { eq: true } }) {
        items {
          id
          type
          version
          settings
        }
      }
      priceBooks(entityConnection: "PriceBook", limit: 1000) {
        items {
          id
          sortKey
          name
          description
          isDefault
          markupType
          markupValue
        }
      }
      companyAddresses(
        entityConnection: "CompanyAddress"
        filter: { addressType: { in: ["shippingAddress", "billingAddress"] } }
      ) {
        items {
          id
          addressType
          addressLine1
          addressLine2
          city
          state
          zipcode
        }
      }
      departments: departmentsView(
        entityConnection: "Department"
        filter: {
          integerFilters: [{ fieldName: "Department.deletedDate", filterInput: { is: null } }]
        }
      ) {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          tagName
          tagType
          accountingRefIdOfClass
        }
      }
      customerTags(entityConnection: "CustomerTag", limit: 50) {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      customerTypes(entityConnection: "CustomerType", limit: 50) {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      assetTypes(entityConnection: "AssetType") {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      customerPropertyTypes(entityConnection: "CustomerPropertyType", limit: 50) {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      jobTypes(entityConnection: "JobType", limit: 50) {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      jobTags(entityConnection: "JobTag", filter: { isActive: { ne: false } }) {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      invoiceTagsInactive: invoiceTags(
        entityConnection: "InvoiceTag"
        limit: 10
        filter: { isActive: { eq: false } }
      ) {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      invoiceTags(entityConnection: "InvoiceTag", limit: 50, filter: { isActive: { ne: false } }) {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      quoteTagsInactive: quoteTags(
        entityConnection: "QuoteTag"
        limit: 10
        filter: { isActive: { eq: false } }
      ) {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      serviceAgreementTags(filter: { isActive: { ne: false } }) {
        items {
          tagName
          sortOrder
          id
        }
      }
      quoteTags(entityConnection: "QuoteTag", limit: 50, filter: { isActive: { ne: false } }) {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      purchaseOrderTags(limit: 50) {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
        }
      }
      taskTypes(entityConnection: "TaskType", limit: 50) {
        items {
          tagType
          tagName
          sortOrder
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
        }
      }
      unitOfMeasures(entityConnection: "UnitOfMeasure") {
        items {
          id
          name
          unitOfMeasureType
          unitOfMeasureCategory
        }
      }
      productCategories(entityConnection: "ProductCategory") {
        items {
          id
          name
          sortOrder
          entityType
        }
      }
      paymentTerms(
        entityConnection: "PaymentTerm"
        sorting: { sortField: "name", sortDirection: "ASC" }
      ) {
        items {
          id
          name
          value
          type
        }
      }
      adjustmentTypes(
        entityConnection: "AdjustmentType"
        filter: { booleanFilters: { fieldName: "isActive", filterInput: { eq: true } } }
      ) {
        items {
          id
          name
          type
          ledgerAccount(entityConnection: "LedgerAccount") {
            id
            name
          }
          ledgerOffsetAccount(entityConnection: "LedgerAccount") {
            id
            name
          }
        }
      }
      taxRates(entityConnection: "TaxRate", filter: { isActive: { eq: true } }, limit: 9999) {
        items {
          id
          name
          city
          state
          county
          taxRate
          accountType
          accountingRefId
        }
      }
    }
    listTenantSettings(partitionKey: $tenantPartitionKey, sortKey: $tenantSortKey) {
      id
      partitionKey
      sortKey
      hierarchy
      tenantId
      tenantCompanyId
      settingKey
      settingPath
      settingValue
    }
  }
`;

export default getCompanyAttributes;
