import React from 'react';

import { Grid, Tooltip, Typography } from '@material-ui/core/';
import Chip from '@material-ui/core/Chip';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PropTypes from 'prop-types';

import { toTitleCase } from 'utils';

import { enumBackgroundColor, enumIcon, enumTextColor } from './helper';

const useStyles = makeStyles({
  root: ({ backgroundColor }) => ({
    height: 24,
    backgroundColor
  }),
  clickable: ({ backgroundColor }) =>
    backgroundColor
      ? {
          cursor: 'pointer',
          '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.08)
          },
          '&:active': {
            backgroundColor: emphasize(backgroundColor, 0.12)
          }
        }
      : undefined,
  label: ({ textColor, fontSize }) => ({
    paddingLeft: 8,
    paddingRight: 8,
    color: textColor,
    fontSize
  }),
  tooltipStatusBackground: ({ theme }) => ({
    backgroundColor: theme?.palette?.support?.grey?.dark,
    padding: theme.spacing(1)
  }),
  tooltipStatusArrow: ({ theme }) => ({
    color: theme?.palette?.support?.grey?.dark
  })
});

function StatusChip({
  type,
  label,
  enumType,
  enumValue,
  locale = 'en',
  style,
  backgroundColor,
  textColor,
  classes,
  onClick,
  className,
  fontSize,
  toolTipText = '',
  disabled = false,
  showIcon = false,
  icon,
  noLabelFormat
}) {
  const theme = useTheme();

  const finalBackgroundColor =
    type?.backgroundColor || backgroundColor || enumBackgroundColor(theme)[enumType]?.[enumValue];
  const finalTextColor = type?.textColor || textColor;
  const finalClasses = useStyles({
    backgroundColor: finalBackgroundColor,
    textColor: finalTextColor,
    fontSize,
    theme
  });

  if (className) finalClasses.root = `${finalClasses.root} ${className}`;
  if (classes?.root) finalClasses.root = `${finalClasses.root} ${classes.root}`;
  if (classes?.label) finalClasses.label = `${finalClasses.label} ${classes.label}`;

  // Color is one of "default", "primary", or "secondary", while backgroundColor is any RGB value
  const { name, color } = type || {};
  const labelName = name?.[locale] || label || '-';
  let finalLabel = noLabelFormat ? labelName : toTitleCase(labelName);

  if (typeof toolTipText === 'string' && typeof finalLabel === 'string' && toolTipText) {
    finalLabel = (
      <Grid container wrap="nowrap" direction="row" alignItems="center">
        <Typography display="inline" variant="body2">
          {finalLabel}
        </Typography>
        <Tooltip title={toolTipText}>
          <InfoOutlinedIcon fontSize="inherit" style={{ marginLeft: 4 }} />
        </Tooltip>
      </Grid>
    );
  }
  let statusChip = (
    <Chip
      icon={showIcon ? icon || enumIcon(theme)[enumType] : undefined}
      color={enumTextColor?.[enumType]?.[enumValue] || color}
      label={finalLabel}
      classes={finalClasses}
      style={style}
      onClick={onClick}
      disabled={disabled}
    />
  );
  if (typeof toolTipText === 'object') {
    statusChip = (
      <Tooltip
        title={toolTipText}
        arrow
        classes={{
          tooltip: finalClasses.tooltipStatusBackground,
          arrow: finalClasses.tooltipStatusArrow
        }}
      >
        <Chip
          icon={showIcon ? icon || enumIcon(theme)[enumType] : undefined}
          color={enumTextColor?.[enumType]?.[enumValue] || color}
          label={finalLabel}
          classes={finalClasses}
          style={style}
          onClick={onClick}
          disabled={disabled}
        />
      </Tooltip>
    );
  }
  return statusChip;
}

StatusChip.propTypes = {
  // which options list are we looking at for colors/styling?
  enumType: PropTypes.string,
  // what's the option value? unused if !enumType
  enumValue: PropTypes.string,
  // cb fired when on click. no args.
  onClick: PropTypes.func
};

StatusChip.defaultProps = {
  enumType: undefined,
  enumValue: undefined,
  onClick: undefined
};

export default StatusChip;
