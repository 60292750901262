import React from 'react';

import {
  Button,
  ButtonType,
  Label,
  MUIForm,
  Select,
  ThemeProvider,
  TV,
  TW,
  Typography
} from '@buildhero/sergeant';
import { Box, Divider, Grid } from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import { ConfirmLeave, PageHeader, Spinner } from 'components';
import AlgoliaSearch from 'components/SgtAlgoliaSearch/AlgoliaSearch';
import { bundleIndex } from 'constants/algoliaIndex';
import useLabourTypeDependency from 'customHooks/useLabourTypeDependency';
import Labels from 'meta/labels';
import { payrollBillingHourType } from 'meta/Settings/Payroll/PayrollBillingHourType';
import { payrollHourType } from 'meta/Settings/Payroll/PayrollHourType';
import payrollLabourType from 'meta/Settings/Payroll/PayrollLabourType';
import { timeTrackingSetting } from 'meta/Settings/Payroll/TimetrackingSettingMeta';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { checkPermission } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';
import { Mode } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import TooltipLabel from '../components/TooltipLabel';

import {
  addPayrollBillingHourType,
  deletePayrollBillingHourType,
  getPayrollBillingHourTypes,
  updatePayrollBillingHourType,
  updatePayrollBillingHourTypeOrder
} from './billingHourTypeService';
import BillingTypeProductMapping from './BillingTypeProductMapping';
import { DynamicItemWrapper } from './DynamicItemWrapper';
import { formatList } from './helpers';
import {
  addPayrollLabourType,
  deletePayrollLabourType,
  getPayrollLabourTypes,
  updatePayrollLabourTypeOrder
} from './labourTypeService';
import PayrollHourRate from './PayrollHourRate';

import {
  addPayrollHourType,
  addSettings,
  deletePayrollHourType,
  getPayrollHourTypes,
  getSettings,
  updatePayrollHourType,
  updatePayrollHourTypeOrder,
  updateSettings
} from './payrollSettings-service';
import styles from './styles';

function Payroll(props) {
  const flags = useFlags();
  const { user } = props;
  const { tenantId, tenantCompanyId } = user;

  document.title = 'BuildOps - Payroll';

  const isAllowedToEdit = checkPermission(
    Mode.EDIT,
    PermissionConstants.OBJECT_TIMETRACKINGSETTING,
    user
  );

  const isAllowedToAdd = checkPermission(
    Mode.ADD,
    PermissionConstants.OBJECT_TIMETRACKINGSETTING,
    user
  );

  const [isLoading, setIsLoading] = React.useState(true);
  const [formChanged, setFormChanged] = React.useState(false);
  const [settingService, setSettingService] = React.useState();
  const [payrollSettings, setPayrollSettings] = React.useState();
  const [payrollHourTypeOptions, setPayrollHourTypeOptions] = React.useState([]);
  const [billingHourTypeOptions, setBillingHourTypeOptions] = React.useState([]);
  const [payrollRegHourTypeSelect, setPayrollRegHourTypeSelect] = React.useState({});
  const [payrollOTHourTypeSelect, setPayrollOTHourTypeSelect] = React.useState({});
  const [defaultBillingProduct, setDefaultBillingProduct] = React.useState('');
  const [refetchPayrollHourType, setRefetchPayrollHourType] = React.useState(false);
  const [costCodes, jobCostTypes, revenueTypes] = useLabourTypeDependency(
    tenantId,
    tenantCompanyId,
    props.snackbarOn
  );

  React.useEffect(() => {
    const getPayrollSettings = async () => {
      const settings = await getSettings(tenantId, tenantCompanyId, props.snackbarOn);
      setPayrollSettings(settings);
      setDefaultBillingProduct({
        id: settings.defaultProductId,
        name: settings.defaultProductName
      });
    };

    const getPayrollHourTypeOptions = async () => {
      const types = await getPayrollHourTypes(tenantId, tenantCompanyId, props.snackbarOn);
      const options = types.map(t => ({
        label: `${t.hourType} (${t.hourTypeAbbreviation})`,
        value: t
      }));
      setPayrollHourTypeOptions(options);
      setPayrollRegHourTypeSelect(options.find(o => o.value.hourTypeTag === 'REG'));
      setPayrollOTHourTypeSelect(options.find(o => o.value.hourTypeTag === 'OT'));
    };

    const getBillingHourTypeOptions = async () => {
      const types = await getPayrollBillingHourTypes(tenantId, tenantCompanyId, props.snackbarOn);
      const options = types.map(type => ({
        label: `${type.hourType} (${type.hourTypeAbbreviation})`,
        value: type.id
      }));
      setBillingHourTypeOptions(options);
    };

    const initialize = async () => {
      setIsLoading(true);
      await getPayrollSettings();
      await getPayrollHourTypeOptions();
      await getBillingHourTypeOptions();
      setIsLoading(false);
    };

    initialize();

    // Want this function to be called only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormComplete = async filledFormData => {
    const settingsJsonPayload = {
      ...filledFormData,
      defaultProductId: defaultBillingProduct.id,
      defaultProductName: defaultBillingProduct.name
    };

    let data;
    if (filledFormData?.id) {
      data = await updateSettings(settingsJsonPayload, user.tenantId, props.snackbarOn);
    } else {
      data = await addSettings(
        settingsJsonPayload,
        user.tenantId,
        user.tenantCompanyId,
        props.snackbarOn
      );
    }
    setPayrollSettings(data);
    setDefaultBillingProduct({
      id: data.defaultProductId,
      name: data.defaultProductName
    });
  };

  const handleUpdateHourTypeOrder = data =>
    updatePayrollHourTypeOrder(data, user.tenantId, user.tenantCompanyId, props.snackbarOn);

  const handleAddLabourType = data =>
    addPayrollLabourType(data, user.tenantId, user.tenantCompanyId, props.snackbarOn);

  const headerButtons = (
    <ThemeProvider>
      <Button
        type={ButtonType.PRIMARY}
        css={{ float: 'right' }}
        onClick={() => settingService?.submit()}
      >
        Save
      </Button>
    </ThemeProvider>
  );
  const isTouched = Boolean(defaultBillingProduct?.unsaved) || formChanged;
  const formEditLayout = payrollSettings?.id ? Mode.EDIT : Mode.ADD;

  const customLabourTypeLayout = payrollLabourType({
    costCodes: formatList(costCodes),
    jobCostTypes: formatList(jobCostTypes),
    revenueTypes: formatList(revenueTypes)
  });

  const handlePayrollExportSettingsSelect = async (type, option) => {
    const optionToClear = payrollHourTypeOptions.find(o => o.value.hourTypeTag === type);
    if (optionToClear !== undefined) {
      await updatePayrollHourType(
        { ...optionToClear.value, hourTypeTag: null },
        tenantId,
        snackbarOn
      );
    }

    if (type === 'REG') {
      setPayrollRegHourTypeSelect(option);
      await updatePayrollHourType({ ...option.value, hourTypeTag: 'REG' }, tenantId, snackbarOn);
    }
    if (type === 'OT') {
      setPayrollOTHourTypeSelect(option);
      await updatePayrollHourType({ ...option.value, hourTypeTag: 'OT' }, tenantId, snackbarOn);
    }
  };

  return (
    <ErrorBoundaries>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ConfirmLeave when={isTouched} />
          <Grid container>
            <Grid item css={styles.stickyHeader}>
              <PageHeader
                title={Labels.timeTrackingSettings[user.locale]}
                breadcrumbsArray={[
                  { link: '', title: Labels.settings[user.locale] },
                  { link: '', title: Labels.payroll[user.locale] }
                ]}
                iconComponent={<SettingsOutlinedIcon css={styles.settingIcon} />}
                overrideHeaderButtons={isAllowedToEdit ? headerButtons : ''}
              />
            </Grid>
          </Grid>
          <Grid container direction="column">
            <Grid item css={{ marginTop: 150 }}>
              <Divider fullwidth />
              <MUIForm
                configuration={timeTrackingSetting(isAllowedToEdit)}
                data={payrollSettings}
                layout={isAllowedToEdit ? formEditLayout : Mode.VIEW}
                onCreateService={formService => {
                  setSettingService(formService);
                }}
                onDirtyChange={isDirty => setFormChanged(isDirty)}
                onComplete={async completedData => handleFormComplete(completedData)}
              />
            </Grid>
            <DynamicItemWrapper
              getAction={getPayrollHourTypes}
              addAction={addPayrollHourType}
              updateAction={updatePayrollHourType}
              deleteAction={deletePayrollHourType}
              layout={payrollHourType({
                settings: {
                  showAccountNumberOnPayrollHourType: flags[FeatureFlags.ADP_EXPORT],
                  mapPayrollHourToBilling: payrollSettings.mapPayrollHourToBilling
                },
                options: { billingHourTypes: billingHourTypeOptions }
              })}
              user={user}
              title="Payroll Hour Types"
              addActionLabel="Add Hour type"
              snackbarOn={props.snackbarOn}
              isAllowedToAdd={isAllowedToAdd}
              getDisplayLabel={hourType =>
                `${hourType?.hourType} (${hourType?.hourTypeAbbreviation})`
              }
              isAllowedToEdit={isAllowedToEdit}
              orderUpdateAction={handleUpdateHourTypeOrder}
              header={Labels.payrollHourTypes[user.locale]}
              validationSchema={Yup.object({
                hourType: Yup.string().required('Field is required'),
                hourTypeAbbreviation: Yup.string().required('Field is required'),
                intacctAccountNumber: Yup.string()
                  .nullable()
                  .matches(/^[a-z0-9]+$/i, 'Account number must be an alphanumeric value.'),
                intacctNonBillableAccountNumber: Yup.string()
                  .nullable()
                  .matches(/^[a-z0-9]+$/i, 'Account number must be an alphanumeric value.')
              })}
              customComponents={{ TooltipLabel }}
            />
            {flags[FeatureFlags.ADP_EXPORT] && (
              <ThemeProvider>
                <Typography style={{ margin: '20px 0' }}>Payroll Export Settings</Typography>
                <Select
                  label="Payroll Reg Hour Type"
                  style={{ width: 222, marginBottom: 20 }}
                  placeholder="Select payroll hour type"
                  defaultValue={payrollRegHourTypeSelect}
                  options={payrollHourTypeOptions.filter(
                    o => o.value.id !== payrollOTHourTypeSelect?.value?.id
                  )}
                  onChange={option => handlePayrollExportSettingsSelect('REG', option)}
                />
                <Select
                  label="Payroll O/T Hour Type"
                  style={{ width: 222 }}
                  placeholder="Select payroll hour type"
                  defaultValue={payrollOTHourTypeSelect}
                  options={payrollHourTypeOptions.filter(
                    o => o.value.id !== payrollRegHourTypeSelect?.value?.id
                  )}
                  onChange={option => handlePayrollExportSettingsSelect('OT', option)}
                />
              </ThemeProvider>
            )}
            <Divider fullwidth css={styles.formEndDivider} />
            <Typography variant="body2" css={styles.greenHeading}>
              Billing Hour Types
            </Typography>
            <Box width={400}>
              <ThemeProvider>
                <Label label="Default Billing Product for Labor Invoice Items" />
                <AlgoliaSearch
                  placeholder="Search"
                  value={defaultBillingProduct?.name}
                  name="defaultProductSetting"
                  indexName={bundleIndex}
                  formatHitLabel={h =>
                    `${h.name || ''}${h.description ? `, ${h.description}` : ''}`
                  }
                  filters="entityType:Product"
                  onChange={selectedItem =>
                    setDefaultBillingProduct({
                      ...selectedItem,
                      unsaved: selectedItem?.id !== defaultBillingProduct?.id || ''
                    })
                  }
                  disableClear
                />

                <Typography variant={TV.S2} weight={TW.REGULAR}>
                  The new default will only be applied to new Billing Hour Types created
                </Typography>
              </ThemeProvider>
            </Box>
            <DynamicItemWrapper
              getAction={getPayrollBillingHourTypes}
              addAction={addPayrollBillingHourType}
              updateAction={updatePayrollBillingHourType}
              deleteAction={deletePayrollBillingHourType}
              layout={payrollBillingHourType}
              user={user}
              title="Billing Hour Types"
              addActionLabel="Add Billing Hour type"
              snackbarOn={props.snackbarOn}
              isAllowedToAdd={isAllowedToAdd}
              getDisplayLabel={hourType =>
                `${hourType?.hourType} (${hourType?.hourTypeAbbreviation})`
              }
              isAllowedToEdit={isAllowedToEdit}
              orderUpdateAction={updatePayrollBillingHourTypeOrder}
              header={Labels.billingHourTypes[user.locale]}
              disableAdd={!defaultBillingProduct?.id}
              refetchFn={() => setRefetchPayrollHourType(prevValue => !prevValue)}
            />
            <Divider fullwidth css={styles.formEndDivider} />
            <Typography variant="body2" css={styles.greenHeading}>
              Labor Types
            </Typography>
            <DynamicItemWrapper
              user={user}
              title="Labor Types"
              snackbarOn={props.snackbarOn}
              getAction={getPayrollLabourTypes}
              addAction={handleAddLabourType}
              updateAction={handleAddLabourType}
              deleteAction={deletePayrollLabourType}
              layout={customLabourTypeLayout}
              isAllowedToAdd={isAllowedToAdd}
              addActionLabel="Add Labor type"
              getDisplayLabel={({ name }) => name || '-'}
              isAllowedToEdit={isAllowedToEdit}
              customComponents={{
                BillingTypeProductMapping: ({ ...customProps }) => (
                  <BillingTypeProductMapping
                    user={user}
                    snackbarOn={props.snackbarOn}
                    defaultBillingProduct={defaultBillingProduct}
                    {...customProps}
                  />
                ),
                PayrollHourRate: ({ ...customProps }) => (
                  <PayrollHourRate user={user} snackbarOn={props.snackbarOn} {...customProps} />
                )
              }}
              isFullScreenForm
              header={Labels.labourTypes[user.locale]}
              orderUpdateAction={updatePayrollLabourTypeOrder}
              disableAdd={!defaultBillingProduct?.id}
              key={refetchPayrollHourType}
            />
          </Grid>
        </>
      )}
    </ErrorBoundaries>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = { snackbarOn };

export default connect(mapStateToProps, mapDispatcherToProps)(Payroll);
