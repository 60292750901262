import gql from 'graphql-tag';

const getCompany = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      version
      employees(entityConnection: "Employee") {
        nextToken
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          firstName
          lastName
          code
          userName
          name
          email
          personalEmail
          code
          isTech
          isSales
          isCrewMember
          status
          cellPhone
          landlinePhone
          profilePictureUrl
          isAdmin
          nickName
          lastUpdatedDateTime
          version
          isActive
          labourTypeId
          appRoles(entityConnection: "AppRole") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              invertedSortKey
              hierarchy
              entityType
              mappedEntity {
                ... on AppRole {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  tagType
                  tagName
                }
              }
            }
          }
          billingRates(entityConnection: "BillingRate") {
            items {
              id
              rate
              version
              hourTypeId
            }
          }
          payrollCosts(entityConnection: "PayrollCost") {
            items {
              id
              cost
              version
              hourTypeId
            }
          }
          departments(entityConnection: "Department") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              invertedSortKey
              hierarchy
              entityType
              mappedEntityValue
              mappedEntityType
              mappedEntity {
                ... on Department {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  tagType
                  tagName
                }
              }
              mappedEntityId
            }
          }
        }
      }
    }
  }
`;

export default getCompany;
