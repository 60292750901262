import { makeStyles } from '@material-ui/core';
import theme from 'themes/BuildHeroTheme';

const useStyles = makeStyles(() => ({
  sidebarContainer: {
    borderRight: `1px solid ${theme.palette.grayscale(90)}`,
    width: '242px',
    bottom: '0px',
    top: '0px'
  },
  mainContainer: {
    display: 'flex',
    height: '100%'
  },
  container: {
    width: '100%'
  },
  detailTop: {
    marginTop: 24,
    paddingBottom: 10
  },
  button: {
    margin: 3,
    padding: 3
  },
  spacer: {
    margin: '10px 0',
    height: 1,
    background: theme.palette.grayscale(90)
  }
}));

export default useStyles;
