import moment from 'moment';
import AppConstants from 'utils/AppConstants';
import { Logger } from 'services/Logger';

const zeroAmt = '0.00';

export const formatVersionOptionData = async (
  quoteData,
  setQuoteVersionsOptionData,
  snackbarOn,
  setHasMultipleVersions,
  setQuoteTags
) => {
  try {
    let quoteTags = [];
    const quoteVersions = [];
    const primaryQuoteVersion = {
      id: quoteData?.id || '',
      emails: quoteData?.emails?.items || [],
      name: `Version ${quoteData?.versionNumber || ''}`,
      amount:
        (quoteData?.totalAmountQuotedOverride
          ? quoteData.totalAmountQuotedOverride
          : quoteData?.totalAmountQuoted) || zeroAmt,
      option: quoteData?.versionLabel || '',
      status: quoteData?.status || '',
      lastModifiedBy: quoteData?.lastUpdatedBy || '',
      date:
        moment
          .unix(quoteData?.lastUpdatedDateTime / 1000)
          .format(AppConstants.QUOTE_VERSIONS_DATE_FORMAT) || '',
      isPrimary: true
    };
    quoteTags = quoteData?.quoteQuoteTags?.items;
    quoteVersions.push(primaryQuoteVersion);
    const versionedQuotesExist =
      (quoteData?.opportunity?.versionedQuotes?.items &&
        !_.isEmpty(quoteData.opportunity.versionedQuotes.items)) ||
      (quoteData?.versionedQuoteView?.items && !_.isEmpty(quoteData.versionedQuoteView.items));
    if (versionedQuotesExist) {
      (
        quoteData?.opportunity?.versionedQuotes?.items || quoteData.versionedQuoteView.items
      ).forEach(versionedQuote => {
        const quoteVersion = {
          id: versionedQuote?.id || '',
          emails: versionedQuote?.emails?.items || [],
          version: versionedQuote?.versionNumber,
          name: `Version ${versionedQuote?.versionNumber || ''}`,
          amount:
            (versionedQuote?.totalAmountQuotedOverride
              ? versionedQuote.totalAmountQuotedOverride
              : versionedQuote?.totalAmountQuoted) || zeroAmt,
          option: versionedQuote?.versionLabel || '',
          status: versionedQuote?.status || '',
          LastModifiedBy: versionedQuote?.lastUpdatedBy || '',
          date:
            moment
              .unix(versionedQuote?.lastUpdatedDateTime / 1000)
              .format(AppConstants.QUOTE_VERSIONS_DATE_FORMAT) || ''
        };
        quoteTags = [...quoteTags, ...versionedQuote?.quoteQuoteTags?.items];
        quoteVersions.push(quoteVersion);
      });
    }
    const sortQuoteVersionOptions = quoteVersions.sort((a, b) => {
      const firstObj = parseInt(a.name.split(' ')[1], 10);
      const secondObj = parseInt(b.name.split(' ')[1], 10);
      return firstObj - secondObj;
    });
    setQuoteVersionsOptionData([...sortQuoteVersionOptions]);
    setHasMultipleVersions(sortQuoteVersionOptions.length > 1);
    if (setQuoteTags) {
      setQuoteTags(quoteTags);
    }
    return sortQuoteVersionOptions;
  } catch (error) {
    Logger.error(error);
    snackbarOn('error', 'Unable to fetch quote info, please try again later', error);
  }
};

export const changeVersionsView = async (
  selectedValue,
  versionsOptions,
  fetchQuoteInfo,
  fetchVersionedQuoteInfo
) => {
  const selectedItem = versionsOptions.find(option => option.id === selectedValue);
  if (selectedItem) {
    if (selectedItem.isPrimary) {
      fetchQuoteInfo(selectedItem.id);
    } else {
      fetchVersionedQuoteInfo(selectedItem.id);
    }
  }
};

export const updateVersionDropdown = async (
  responseData,
  quoteVersionsOptionData,
  setQuoteVersionsOptionData,
  snackbar,
  setHasMultipleVersions,
  primaryQuoteId,
  tenantId,
  getQuoteById
) => {
  const quoteId = responseData?.data?.quoteTransition?.id;
  const selectedVersion = quoteVersionsOptionData.find(version => version.id === quoteId);
  let versionOptionsData;
  if (selectedVersion?.isPrimary) {
    versionOptionsData = responseData.data.quoteTransition;
  } else {
    const primaryQuoteResponse = await getQuoteById(primaryQuoteId, { tenantId }, snackbar);
    versionOptionsData = primaryQuoteResponse;
  }
  formatVersionOptionData(
    versionOptionsData,
    setQuoteVersionsOptionData,
    snackbar,
    setHasMultipleVersions
  );
};
