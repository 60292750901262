import { calculateCOContractValue } from '@pm/components/utils';

export const getSumbOfOriginalContract = data => {
  if (!data || !data?.length) return 0;
  return data.reduce((sum, item) => sum + Number(item?.contractValue || 0), 0);
};

export const getNetChanges = data => {
  if (!data || !data?.length) return 0;
  return data.reduce((sum, item) => sum + calculateCOContractValue(item), 0);
};

export const getTotalCompletedAndStoredToDate = (
  workCompletedFromPrevious,
  workCompletedThisPeriod,
  materialsStored
) => {
  return (
    Number(workCompletedFromPrevious) + Number(workCompletedThisPeriod) + Number(materialsStored)
  );
};

export const getPercentageOfTotalCompletedAndStoredToDate = item => {
  if (!item.contractValue) return 0;
  const total = getTotalCompletedAndStoredToDate(
    item.totals?.totalCompletedAndStored || 0,
    item.workCompleted,
    item.materialsStored
  );
  return (total / Number(item.contractValue)) * 100;
};

export const getBalanceToFinish = (item, contractValue = null) => {
  const total = getTotalCompletedAndStoredToDate(
    item.totals?.totalCompletedAndStored || 0,
    item.workCompleted,
    item.materialsStored
  );
  return Number(contractValue ?? item.contractValue) - total;
};

export const getCompletedWorkFromPrevious = data => {
  if (!data || !data?.length) return 0;
  return data.reduce((sum, item) => sum + Number(item?.fromPrevApplication || 0), 0);
};

export const getCompletedWorkThisPeriod = data => {
  if (!data || !data?.length) return 0;
  return data.reduce((sum, item) => sum + Number(item?.thisPeriod || 0), 0);
};

export const getMaterialStored = data => {
  if (!data || !data?.length) return 0;
  return data.reduce((sum, item) => sum + Number(item?.materialsPresentlyStored || 0), 0);
};

export const getTotalCompletedStoredToDate = (sovData, coData) => {
  return (
    getCompletedWorkFromPrevious(sovData) +
    getCompletedWorkThisPeriod(sovData) +
    getMaterialStored(sovData) +
    getCompletedWorkFromPrevious(coData) +
    getCompletedWorkThisPeriod(coData) +
    getMaterialStored(coData)
  );
};

export const getAllCompletedWork = (sovData, coData, retainage) => {
  const value =
    getCompletedWorkFromPrevious(sovData) +
    getCompletedWorkThisPeriod(sovData) +
    getCompletedWorkFromPrevious(coData) +
    getCompletedWorkThisPeriod(coData);
  return (value * retainage) / 100;
};

export const getRetainagedMaterialStored = (sovData, coData, retainage) => {
  const value = getMaterialStored(sovData) + getMaterialStored(coData);
  return (value * retainage) / 100;
};
