import { sortBy } from 'lodash';

// Itemized task labor
function formatLaborItemsForDisplay({
  HTML,
  SettingConstants,
  taskLaborHeader,
  laborTaskItems,
  settingsJSON,
  styles,
  fontFamilyStyle
}) {
  const laborItemsToDisplay = taskLaborHeader
    ? sortBy(laborTaskItems, 'sortOrder')
        .map(
          item => `<tr>
          ${HTML.itemizedElements({
            styles,
            fontFamilyStyle,
            description: item.description || item?.labourType?.name || '',
            shouldDisplayItem: settingsJSON.Tasks.Time[SettingConstants.SHOW_ITEMIZED_LABOR]
          })}   
          ${HTML.itemizedQuantity({
            styles,
            fontFamilyStyle,
            quantity: item.quantity,
            shouldDisplayQuantity:
              settingsJSON.Tasks.Time[SettingConstants.SHOW_ITEMIZED_LABOR_QUANTITY]
          })}             
          ${HTML.itemizedPricing(
            item,
            settingsJSON.Tasks.Time[SettingConstants.SHOW_ITEMIZED_LABOR_PRICING],
            styles,
            fontFamilyStyle
          )}
           </tr>`
        )
        .join('')
    : '';

  return laborItemsToDisplay;
}

export default formatLaborItemsForDisplay;
