import gql from 'graphql-tag';

export const GET_PROJECTS = gql`
  query getProjects(
    $tenantId: String
    $filter: TableFilterInput
    $pagination: PaginationInput
    $sorting: [TableSortingInput]
  ) {
    data: getProjects(input: { filter: $filter, pagination: $pagination, sorting: $sorting })
      @rest(type: "ProjectsResponse", path: "projects/list", endpoint: "pmapi", method: "post") {
      items @type(name: "Project") {
        id
        dateEnd
        dateStart
        name
        number
        status
        ProjectSubtype {
          name
        }
        projectManager {
          name
        }
      }
      rowCount
    }
  }
`;
