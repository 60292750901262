import { statusToNoteLabel } from 'scenes/Procurement/constants';

export const ApprovalRejectionLayout = status => ({
  fields: {
    approvalNote: {
      default: 'approvalNote',
      edit: 'approvalNote'
    }
  },
  layouts: {
    default: {
      options: {
        direction: 'row',
        width: 454
      },
      contents: [
        {
          options: {
            direction: 'column',
            grow: 1,
            padding: 12
          },
          contents: [
            {
              component: {
                default: 'TextInput',
                edit: 'TextInput'
              },
              source: 'approvalNote',
              options: {
                label: statusToNoteLabel[status],
                placeholder: `Enter ${statusToNoteLabel[status]}`,
                fullWidth: true,
                alignment: 'left',
                lines: 6
              }
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      approvalNote: {
        type: 'string',
        nullable: true
      }
    }
  }
});

export default ApprovalRejectionLayout;
