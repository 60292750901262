/* eslint-disable no-loop-func */
import renderDataTemplate from './RenderDataTemplate';

export const generateMetaLayout = data => {
  const { items, rows, layout, formAssetList } = data;
  const metaLayout = { ...renderDataTemplate };
  const newMeta = [];
  const newData = {};
  const newFields = {};
  const validation = {
    type: 'object',
    properties: {},
    required: []
  };
  const validationMessages = {};
  const assetList = [];
  const smartFieldReferences = [];
  const searchableFieldsCounter = {
    text: [1, 15],
    longText: [1, 10],
    number: [1, 15],
    pickList: [1, 15],
    lookup: [1, 10],
    date: [1, 10],
    richText: [1, 5],
    checkBox: [1, 10]
  };

  const searchableFields = {};

  for (const rowIndex of layout) {
    const currentRow = rows[rowIndex];
    const isNewRow = !!(currentRow.itemIds.length === 1 && currentRow.itemIds[0] === null);

    if (!isNewRow) {
      const metaRow = {
        options: {
          size: currentRow.numberOfColumns,
          direction: 'row',
          grow: 0
        }
      };
      const metaColumns = currentRow.itemIds.map(itemID => {
        let metaItem = { contents: [{ component: null }] };
        if (itemID !== null && items[itemID]) {
          const item = items[itemID];
          const { id, controlType, viewControlType, errorMsg, ...options } = item;

          if (options.source) {
            const updatedSources = options.source.filter(src => src.file);
            const existingSource = formAssetList[id];
            if (updatedSources.length) {
              assetList.push({ id: id, itemSources: updatedSources, new: true });
            } else if (existingSource) {
              assetList.push({ id: id, itemSources: existingSource });
            }
          }

          if (!options.readOnly) {
            newData[itemID] = options.default_value || '';
            newFields[itemID] = { default: `${itemID}`, edit: `${itemID}` };
          }

          if (options.searchableField) {
            const category = options.searchableCategory;
            const [available, limit] = searchableFieldsCounter[category];
            if (available <= limit) {
              searchableFields[id] = options.searchableCategory + available;
              searchableFieldsCounter[category][0] = available + 1;
            }
          }

          if (options.smartField) {
            smartFieldReferences.push({ id: id, source: options.smartField });
          }

          metaItem = {
            options: { direction: 'column', grow: 1, padding: 12 },
            contents: [
              {
                component: {
                  edit: controlType,
                  default: viewControlType
                },
                source: id,
                options: options
              }
            ]
          };
          if (options.isRequired) {
            validation.properties[id] = { type: 'string' };
            validation.required.push(id);
          }
          if (errorMsg) {
            validationMessages[id] = {
              required: errorMsg
            };
          }
        }

        return metaItem;
      });
      metaRow.contents = metaColumns;
      newMeta.push(metaRow);
    }
  }
  metaLayout.validation = validation;
  metaLayout.validationErrors = validationMessages;
  metaLayout.fields = newFields;
  metaLayout.layouts.default.contents = newMeta;

  return {
    meta: [metaLayout, newData],
    assetList: assetList,
    smartFieldReferences: smartFieldReferences,
    searchableFields: searchableFields
  };
};
