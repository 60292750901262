import React from 'react';
import { ThemeProvider, TV, TW, Typography } from '@buildhero/sergeant';

const ConfigHeader = () => {
  return (
    <ThemeProvider>
      <Typography variant={TV.BASE} weight={TW.BOLD} style={{ letterSpacing: -1 }}>
        Pricebook Configuration
      </Typography>
    </ThemeProvider>
  );
};

export default ConfigHeader;
