export const quoteRowMeta = () => [
  {
    id: 'quoteNumber',
    filterKey: 'Quote.quoteNumber',
    filterType: 'string',
    numeric: false,
    label: 'Quote #',
    showLink: 'view',
    linkPath: '/quote',
    linkReference: 'id'
  },
  {
    id: 'name',
    filterKey: 'Quote.name',
    filterType: 'string',
    numeric: false,
    label: 'Name'
  },
  {
    id: 'description',
    filterKey: 'Quote.description',
    filterType: 'string',
    type: 'bigtext',
    numeric: false,
    label: 'Description'
  },
  {
    id: 'createdDate',
    filterKey: 'Quote.createdDate',
    filterType: 'number',
    numeric: false,
    type: 'dateOnly',
    label: 'Date added'
  },
  {
    id: 'ownerValue',
    filterKey: 'Quote.ownerValue',
    filterType: 'string',
    numeric: false,
    label: 'Assigned to'
  },
  {
    id: 'lastUpdatedDate',
    filterKey: 'Quote.lastUpdatedDate',
    filterType: 'number',
    numeric: false,
    type: 'dateOnly',
    label: 'Last updated'
  },
  {
    id: 'expirationDate',
    filterKey: 'Quote.expirationDate',
    filterType: 'string',
    type: 'dateOnly',
    numeric: false,
    disableFilter: true,
    label: 'Expiry Date'
  },
  {
    id: 'status',
    filterKey: 'Quote.status',
    filterType: 'string',
    numeric: false,
    label: 'Status'
  },
  {
    id: 'versionNumber',
    filterKey: 'versionNumber',
    filterType: 'number',
    label: 'Version'
  },
  {
    id: 'versionLabel',
    filterKey: 'versionLabel',
    filterType: 'string',
    type: 'string',
    label: 'Version Title'
  }
];

export default quoteRowMeta;
