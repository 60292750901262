import React from 'react';
import { withTheme } from '@material-ui/core/styles';
import { DefaultButton } from 'components';
import ErrorBoundaries from 'scenes/Error';
import { Dialog, Typography, DialogContent, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import Labels from 'meta/labels';

const ScheduleModal = ({
  handleClose,
  openScheduleModal,
  handleScheduleVisits,
  classes,
  isSubmitting,
  user
}) => {
  return (
    <Dialog
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={openScheduleModal}
      onClose={handleClose}
      maxWidth="lg"
      scroll="paper"
    >
      <DialogContent className={classes.dialogContent}>
        <ErrorBoundaries>
          <div style={{ display: 'flex' }}>
            <CloseIcon className={classes.closeIcon} onClick={handleClose} />
            <Typography variant="h3" classes={{ h3: classes.header }}>
              Incomplete Scheduling
            </Typography>
          </div>
          <Typography variant="body2" className={classes.description}>
            Some visits haven't been scheduled. If you proceed, only scheduled visits will be
            created.
          </Typography>
          <Divider variant="fullWidth" style={{ marginTop: '24px' }} />
          <DefaultButton
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            showSpinner={isSubmitting}
            label={Labels.proceedCreateVisit[user.locale].toUpperCase()}
            style={{ width: '100%', marginTop: '20px', height: '38px' }}
            handle={handleScheduleVisits}
          />
          <DefaultButton
            variant="contained"
            color="secondary"
            label={Labels.continueScheduling[user.locale].toUpperCase()}
            style={{ width: '100%', marginTop: '8px', height: '38px' }}
            handle={handleClose}
          />
        </ErrorBoundaries>
      </DialogContent>
    </Dialog>
  );
};

ScheduleModal.propTypes = {
  user: PropTypes.object.isRequired,
  openScheduleModal: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleScheduleVisits: PropTypes.func.isRequired,
  classes: PropTypes.object
};

ScheduleModal.defaultProps = {
  openScheduleModal: false,
  classes: {}
};

export default withTheme(ScheduleModal);
