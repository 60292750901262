import { shape, string, number, oneOf } from 'prop-types';
import { JobTypes } from '@dispatch/Dispatch.constants';

export const EventDataPropTypes = shape({
  scheduledFor: number,
  actualDuration: number,
  primaryTechId: string
});

export const JobPropTypes = {
  job: shape({
    jobNumber: string,
    customIdentifier: string,
    jobTypeInternal: oneOf([JobTypes.JOB.value.serverValue, JobTypes.MAINTENANCE.value.serverValue])
  }).isRequired
};
