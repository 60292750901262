import { sortBy } from 'lodash';
import { TaskStatus } from 'utils/AppConstants';
import { InvoiceStatus } from 'utils/constants';

// BUOP-4886 - sum all invoices except those that are deleted or void
const nonbillableInvoices = [InvoiceStatus.VOID];

export const getBillableInvoices = invoices =>
  invoices.filter(item => !nonbillableInvoices.includes(item.status?.toLowerCase()));

export const formatJobTaskForJobReport = ({ jobTasks = { items: [] } }) => {
  const naText = 'N/A';
  const formattedTasks = jobTasks.items.map(jt => {
    if (jt?.task?.status !== TaskStatus.COMPLETED) return;
    return {
      assetName: jt?.task?.asset?.assetName || naText,
      taskName: jt?.task?.name,
      taskDescription: jt?.task?.description
    };
  });

  const nonAssetTask = formattedTasks?.filter(item => item?.assetName === naText) || [];
  const assetTask = sortBy(
    formattedTasks.filter(item => item && item.assetName !== naText),
    'assetName'
  );

  return [...assetTask, ...nonAssetTask];
};
