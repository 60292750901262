import React from 'react';

import { Divider } from '@buildhero/sergeant';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { object } from 'prop-types';
import { Link, useParams } from 'react-router-dom';

import InfoField from '@pm/components/InfoField';
import { UserPermission } from 'components';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { getSidebarMenuItems } from './PMSidebar.constants';
import { useMenuStyles, useStyles } from './PMSidebar.styles';

const MenuItem = ({ item }) => {
  const classes = useMenuStyles();
  const { projectId } = useParams();
  const menuTitle = item.title?.en;
  const menuLink = item.link;
  const { caslKey } = item;
  const { caslAction } = item;
  const isSelected = window.location.pathname.includes(item.link);

  return (
    <UserPermission I={caslAction} action={caslKey}>
      <ListItem
        to={`/project/view/${projectId}/${menuLink}`}
        classes={{
          root: classes.menuItem,
          selected: classes.menuSelected
        }}
        component={Link}
        key={`menuitem${menuTitle}`}
        selected={isSelected}
      >
        <ListItemText
          primary={menuTitle}
          disableTypography
          key={`menutext${menuTitle}`}
          classes={{ root: classes.listTexts }}
        />
        {isSelected ? (
          <ListItemIcon key={`menuicon${menuTitle}`} classes={{ root: classes.listIcons }}>
            <ArrowForwardIcon style={{ fontSize: 16 }} />
          </ListItemIcon>
        ) : null}
      </ListItem>
    </UserPermission>
  );
};

MenuItem.propTypes = {
  item: object.isRequired
};

const PMSidebar = ({ project }) => {
  const styles = useStyles();
  const isFileSystemEnabled = useFlags()[FeatureFlags.PM_FILE_SYSTEM];
  const sideBarMenuItems = getSidebarMenuItems(isFileSystemEnabled);
  return (
    <aside css={styles.sidebar}>
      {project.imgUrl ? (
        <img css={styles.projectImg} alt="project" src={project.imgUrl} />
      ) : (
        <Box css={styles.projectImg} />
      )}
      {sideBarMenuItems.map(item => (
        <MenuItem key={item.link} item={item} />
      ))}
      <Divider />
      <InfoField
        label="Customer"
        loading={!project.summaryLoaded}
        value={project?.customer?.customerName}
      />
      <InfoField
        label="GC Project Manager"
        loading={!project.summaryLoaded}
        value={project?.gcProjectManager?.name}
      />
      <InfoField
        label="GC Superintendent"
        loading={!project.summaryLoaded}
        value={project?.gcSuperintendent?.name}
      />
      <InfoField
        label="Contact Info"
        loading={!project.summaryLoaded}
        value={project?.customer?.email || project?.customer?.primaryPhone}
      />
      <InfoField label="Sold By" loading={!project.summaryLoaded} value={project?.soldBy?.name} />
    </aside>
  );
};

PMSidebar.propTypes = {
  project: object.isRequired
};

export default PMSidebar;
