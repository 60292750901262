import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { PLACEHOLDER_TABLE_HEIGHT } from '../WrapTable.constants';

export const TextContainer = styled.div`
  display: flex;
  justify-content: left;
  ${props => css`
    color: ${props.color};
  `};
`;

export const BorderBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${PLACEHOLDER_TABLE_HEIGHT}px;
  ${props => css`
    color: ${props.color};
    border: dashed 3px ${props.color || 'black'};
  `};
`;
