import React from 'react';
import { ThemeProvider, Typography, TV, TW } from '@buildhero/sergeant';
import { CustomRadioGroup } from './ChangeOrderFormatSelect.component';
import {
  changeOrderFormatValues,
  changeOrderFormatLabels,
  changeOrderFormatDescriptions
} from './ChangeOrderFormatSelect.constants';

const ChangeOrderFormatSelect = ({ onChange, value }) => (
  <ThemeProvider>
    <Typography variant={TV.L} weight={TW.BOLD} style={{ marginBottom: 10 }}>
      Change Order Format
    </Typography>
    <CustomRadioGroup
      handleChange={onChange}
      value={value}
      options={[
        ...Object.keys(changeOrderFormatLabels).map(formatName => ({
          value: changeOrderFormatValues[formatName],
          label: changeOrderFormatLabels[formatName],
          description: changeOrderFormatDescriptions[formatName]
        }))
      ]}
    />
  </ThemeProvider>
);

export default React.memo(ChangeOrderFormatSelect);