import { useTheme } from '@material-ui/core';
import { DispatchTheme, ElementSizes } from '@dispatch/Dispatch.styles';

export const useStyles = ({ canDrop, dropTargetActive, isLastEvent }) => {
  const theme = useTheme();

  const height = dropTargetActive
    ? ElementSizes.hoveredWeekDropTarget
    : ElementSizes.availableTimeHeight;

  return {
    root: {
      position: 'relative',
      width: '100%',
      transition: `flex-basis ${DispatchTheme.mixins.transitionSpeed}`,
      flex: isLastEvent ? `1 1 ${height}px` : `0 0 ${height}px`,
      cursor: 'pointer'
    },
    border: () => ({
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      textAlign: 'center',
      paddingTop: theme.spacing(1),
      background: canDrop ? theme.palette.support.green.light : undefined,
      border: dropTargetActive ? `solid ${theme.palette.support.green.medium} 8px` : undefined
    })
  };
};
