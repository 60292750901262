import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { isEqual } from 'lodash';
// eslint-disable-next-line no-unused-vars
import { ButtonType, Field, MoreButton } from '@buildhero/sergeant';
import { XGrid, HighlightCard, Spinner } from 'components';
import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import { column, ColumnType, valueGetters } from 'components/XGrid/columnTypes';
import { EnumType, ServiceChannelWorkOrderStatus } from 'utils/constants';
import { formatAddressOneLine } from 'utils';

import { getServiceChannelExternalLink } from './utils';
import {
  GET_WO,
  useAssociateJob,
  useLazyGetWOById,
  useQuickFilterCount,
  useDiscardWorkOrder,
  useReopenWorkOrder,
  useDeleteWorkOrder
} from './ServiceChannelList.api';
import styles from './ServiceChannelList.styles';
import useAddToJobModal from './useAddToJobModal';
import useAddToNewJobModal from './useAddToNewJobModal';

const columns = [
  {
    field: 'id',
    width: 38,
    headerName: ' ',
    sortable: false,
    metaOnly: true,
    cellClassName: 'MuiDataGrid-cellMoreButton'
  },
  {
    field: 'workOrderId',
    headerName: 'Request Number',
    width: 136,
    renderCell: ({ value }) => (
      <a target="_blank" rel="noreferrer" href={getServiceChannelExternalLink(value)}>
        {value}
      </a>
    )
  },
  {
    field: 'workOrderNumber',
    headerName: 'Customer Work Order Number',
    width: 122
  },
  {
    field: 'workOrderPurchaseNumber',
    headerName: 'Customer Provided PO Number',
    width: 145
  },
  {
    field: 'subscriberName',
    headerName: 'Subscriber Name',
    width: 160
  },
  {
    field: 'subscriberId',
    headerName: 'Subscriber ID',
    width: 160
  },
  {
    field: 'locationName',
    headerName: 'Location Name',
    width: 160
  },
  {
    field: 'locationStoreId',
    headerName: 'Location ID',
    width: 160
  },
  {
    field: 'locationAddress1',
    headerName: 'Location Address',
    valueGetter: ({ row }) => {
      return formatAddressOneLine(
        {
          addressLine1: row.locationAddress1,
          addressLine2: row.locationAddress2,
          city: row.locationCity,
          state: row.locationState,
          zipcode: row.locationZip
        },
        true
      );
    },
    width: 160
  },
  {
    field: 'nte',
    headerName: 'NTE',
    width: 104,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'priority',
    headerName: 'Priority',
    width: 120
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 221
  },
  {
    field: 'boStatus',
    headerName: 'Status',
    enumType: EnumType.SERVICE_CHANNEL_WORK_ORDER_STATUS,
    width: 59,
    ...column[ColumnType.ENUM]
  },
  {
    field: 'customIdentifier',
    headerName: 'Job',
    width: 100,
    valueGetter: valueGetters.jobLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'createdDateTime',
    headerName: 'Created On',
    width: 180,
    ...column[ColumnType.DATETIME]
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 160
  },
  {
    field: 'lastUpdatedDateTime',
    headerName: 'Updated On',
    width: 180,
    ...column[ColumnType.DATETIME]
  },
  {
    field: 'lastUpdatedBy',
    headerName: 'Updated By',
    width: 160
  }
];

const getCreateJobConfirmContent = (customerName, propertyName, locationStoreId) => ({
  body: (
    <>
      This is will create a new job associated with this work order.
      <br />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 16,
          gap: 16
        }}
      >
        <Field style={{ flex: '1 1 auto' }} label="Customer" value={customerName} />
        <Field style={{ flex: '1 1 auto' }} label="Property" value={propertyName} />
        <Field style={{ flex: '1 1 auto' }} label="Location ID" value={locationStoreId} />
      </div>
    </>
  ),
  title: 'Create New Job From Work Order',
  buttonLabel: 'Create Job'
});

const deleteWorkOrderConfirmContent = {
  body: (
    <>
      <b>Are you sure you want to permanently delete this record?</b>
      <br />
      You can’t undo this action.
    </>
  ),
  title: 'Delete Permanently',
  buttonLabel: 'Delete Permanently',
  buttonType: ButtonType.ERROR
};

const reopenWorkOrderConfirmContent = {
  body: 'Do you wish to reopen this work order?',
  title: 'Reopen Work Order',
  buttonLabel: 'Reopen'
};

const filterStatus = (value, filterModel) => ({
  linkOperator: 'and',
  items: [
    ...filterModel.items?.filter(item => item.id !== 1),
    { columnField: 'boStatus', operatorValue: 'equals', value, id: 1 }
  ]
});

const isSelected = (value, filterModel) =>
  !!filterModel.items?.some(i =>
    isEqual(i, { columnField: 'boStatus', operatorValue: 'equals', value, id: 1 })
  );

const GLOBAL_XGRID_SERVICE_CHANNEL_WORK_ORDER_LIST_FILTER_ID = -1;
const globalFilters = [
  {
    columnField: 'boStatus',
    operatorValue: 'notContains',
    value: ServiceChannelWorkOrderStatus.Discarded,
    id: GLOBAL_XGRID_SERVICE_CHANNEL_WORK_ORDER_LIST_FILTER_ID
  }
];

const ServiceChannelList = () => {
  const [isDiscardedOnly, setIsDiscardedOnly] = useState(false);
  const [refetchCount, refetchTrigger] = useReducer(c => c + 1, 0);
  const { associateJob, associateJobLoading } = useAssociateJob();
  const { counts, refetchCounts, loadingCounts } = useQuickFilterCount();
  const confirmContext = useConfirmModal();

  useEffect(() => {
    refetchCounts();
  }, [refetchCount, refetchCounts]);

  // mutations for actions on work orders
  const { AddToJobModal, triggerAddToJob } = useAddToJobModal();
  const { AddToNewJobModal, triggerAddToNewJob } = useAddToNewJobModal();
  const { discardWorkOrder, discardWorkOrderLoading } = useDiscardWorkOrder();
  const { reopenWorkOrder, reopenWorkOrderLoading } = useReopenWorkOrder();
  const { deleteWorkOrder, deleteWorkOrderLoading } = useDeleteWorkOrder();

  const singleWOCallback = useMemo(
    () => ({
      onCompleted: async ({ getServiceChannelWorkOrderById: data }) => {
        const { customer, customerProperty: property, locationStoreId, id } = data;
        if (!customer || !property) {
          const result = await triggerAddToNewJob(data);
          if (result) {
            associateJob({
              serviceChannelWorkOrderId: id,
              customerId: result.customer.id,
              customerPropertyId: result.property.id
            });
          }
        } else if (
          await confirmContext.confirm(
            getCreateJobConfirmContent(customer.customerName, property.companyName, locationStoreId)
          )
        ) {
          associateJob({ serviceChannelWorkOrderId: id });
        }
      }
    }),
    [associateJob, triggerAddToNewJob, confirmContext]
  );

  const [getWOById, { loading: getByIdLoading }] = useLazyGetWOById(singleWOCallback);

  const loading =
    loadingCounts ||
    getByIdLoading ||
    deleteWorkOrderLoading ||
    reopenWorkOrderLoading ||
    discardWorkOrderLoading;

  const cols = useMemo(() => {
    const actionCol = columns[0];
    actionCol.renderCell = params => (
      <MoreButton
        options={[
          params.row.boStatus === ServiceChannelWorkOrderStatus.Discarded
            ? [
                {
                  label: 'Delete',
                  onClick: async () => {
                    if (await confirmContext.confirm(deleteWorkOrderConfirmContent)) {
                      await deleteWorkOrder(params.row.id);
                      refetchTrigger();
                    }
                  }
                },
                {
                  label: 'Reopen',
                  onClick: async () => {
                    if (await confirmContext.confirm(reopenWorkOrderConfirmContent)) {
                      await reopenWorkOrder(params.row.id);
                      refetchTrigger();
                    }
                  }
                }
              ]
            : [
                {
                  label: 'Create New Job',
                  onClick: async () => {
                    getWOById(params.value);
                  }
                },
                {
                  label: 'Add to Existing Job',
                  onClick: async () => {
                    const jobId = await triggerAddToJob(params.row);
                    if (jobId) {
                      associateJob({ serviceChannelWorkOrderId: params.value, jobId });
                    }
                  }
                }
              ],
          params.row.boStatus === ServiceChannelWorkOrderStatus.Open && {
            label: 'Discard',
            onClick: async () => {
              await discardWorkOrder(params.row.id);
              refetchTrigger();
            }
          }
        ]
          .filter(Boolean)
          .flat()}
      />
    );
    return [actionCol, ...columns.slice(1)];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const quickFilters = useMemo(
    () => ({ setFilterModel, filterModel }) => (
      <div css={styles.filters.container}>
        {Object.entries(ServiceChannelWorkOrderStatus).map(([key, status]) => (
          <HighlightCard
            selected={isSelected(status, filterModel)}
            highlight={counts[status] || 0}
            title={status}
            handleClick={() => {
              setIsDiscardedOnly(status === ServiceChannelWorkOrderStatus.Discarded);
              setFilterModel(filterStatus(status, filterModel));
            }}
          />
        ))}
      </div>
    ),
    [counts]
  );

  if (associateJobLoading) {
    return (
      <div css={styles.spinnerContainer}>
        <Spinner />
      </div>
    );
  }

  return (
    <div css={styles.tableContainer}>
      {loading && <div css={styles.loadingBlock} />}
      {AddToJobModal}
      {AddToNewJobModal}
      <XGrid
        columns={cols}
        entityTypeName="Work Orders"
        tableName="ServiceChannelXGrid"
        query={GET_WO}
        customComponent={quickFilters}
        globalFilters={isDiscardedOnly ? undefined : globalFilters}
        refetchTrigger={refetchCount}
      />
    </div>
  );
};

export default ServiceChannelList;
