import { OpenTaskStatus } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';

const TOOL_TIP_TEXT =
  'This task has been assigned to a quote, job, or maintenance and is no longer pending.';

export const openTaskRowsMeta = [
  {
    id: 'name',
    filterKey: 'Task.name',
    fitlerType: 'string',
    numeric: false,
    label: 'Task Name'
  },
  {
    id: 'openStatus',
    disableSort: true,
    disableFilter: true,
    filterKey: 'status',
    type: 'enum',
    enumType: EnumType.OPEN_TASK_STATUS,
    label: 'Status',
    enableToolTipFor: [OpenTaskStatus.ADDED_TO_WORK],
    toolTipText: {
      [OpenTaskStatus.ADDED_TO_WORK]: TOOL_TIP_TEXT
    }
  },
  {
    id: 'description',
    filterKey: 'Task.description',
    fitlerType: 'string',
    numeric: false,
    type: 'bigtext',
    label: 'Description'
  },
  {
    id: 'completedDate',
    filterKey: 'Task.lastUpdatedDate',
    filterType: 'date',
    numeric: false,
    type: 'dateOnly',
    label: 'Date Completed'
  },
  {
    id: 'completedBy',
    filterKey: 'Task.lastUpdatedBy',
    fitlerType: 'string',
    numeric: false,
    type: 'text',
    label: 'Completed By'
  },
  {
    id: 'associatedWork',
    disableFilter: true,
    disableSort: true,
    type: 'CustomLink',
    label: 'Associated Work',
    isCustom: true
  },
  {
    id: 'CustomActionButtons',
    disableFilter: true,
    disableSort: true,
    type: 'CustomActionButtons',
    isCustom: true
  }
];

export const completedTaskRowsMeta = [
  {
    id: 'name',
    filterKey: 'Task.name',
    fitlerType: 'string',
    numeric: false,
    label: 'Task Name'
  },
  {
    id: 'taskTypeValue',
    filterKey: 'Task.taskTypeValue',
    fitlerType: 'string',
    numeric: false,
    label: 'Task type'
  },
  {
    id: 'description',
    filterKey: 'Task.description',
    fitlerType: 'string',
    numeric: false,
    type: 'bigtext',
    label: 'Description'
  },
  {
    id: 'associatedWork',
    filterKey: 'Task.associatedWork',
    filterType: 'string',
    numeric: false,
    type: 'CustomLink',
    label: 'Associated Work',
    isCustom: true
  },
  {
    id: 'completedDate',
    filterKey: 'Task.lastUpdatedDate',
    filterType: 'date',
    numeric: false,
    type: 'dateTime',
    label: 'Date Completed'
  },
  {
    id: 'completedBy',
    filterKey: 'Task.lastUpdatedBy',
    fitlerType: 'string',
    numeric: false,
    type: 'text',
    label: 'Completed By'
  }
];

export default openTaskRowsMeta;
