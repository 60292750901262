import React from 'react';
import {
  tableCurrencyFormatter,
  tableEmptyValueFormatter,
  BoldCell,
  sum
} from 'components/WrapTable';
import { Field, FieldType } from '@buildhero/sergeant';

export const DiscountsColumns = [
  {
    headerName: 'Source',
    field: 'source',
    maxWidth: 150,
    flex: 1,
    renderCell: ({ row }) => (
      <Field type={FieldType.LINK} value={{ label: row.source, to: row.sourceLink }} />
    )
  },
  {
    headerName: 'Item Name',
    field: 'name',
    valueFormatter: tableEmptyValueFormatter,
    flex: 1
  },
  {
    headerName: 'Description',
    field: 'description',
    valueFormatter: tableEmptyValueFormatter,
    flex: 2
  },
  {
    field: 'subtotal',
    flex: 1,
    maxWidth: 100,
    headerName: 'Subtotal',
    renderCell: BoldCell,
    valueFormatter: tableCurrencyFormatter,
    totalGetter: sum,
    totalFormatter: tableCurrencyFormatter,
    renderTotal: BoldCell
  }
];
