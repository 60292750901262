import React from 'react';

import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  getCloudinaryImageUrl,
  getCombinedAddressFromProjectData
} from 'scenes/ProjectManagement/components/utils';
import { getCombinedAddress } from 'utils';
import { AppConstants } from 'utils/AppConstants';

import styles from './RFIPdf.styles';

const PDFDocument = props => {
  const { companyInfo, projectData, rfiData, sendTo, returnTo } = props;

  const fieldWithTitle = (label, value, containerStyles = styles.marginBottom6) => {
    return (
      <View style={[styles.displayFlexColumn, containerStyles]}>
        <Text style={styles.labelNormal}>{label}</Text>
        <Text style={styles.text}>{value}</Text>
      </View>
    );
  };

  return (
    <Document title={`Request For Information ${rfiData?.number}`}>
      <Page size="letter" style={styles.root}>
        <View>
          {/* Logo and address */}
          {companyInfo && (
            <View style={[styles.displayFlex, { alignItems: 'center' }]}>
              {companyInfo?.logoUrl ? (
                <Image style={styles.logoImage} src={getCloudinaryImageUrl(companyInfo?.logoUrl)} />
              ) : (
                <View />
              )}
              <View style={[styles.displayFlexColumn, { minWidth: 150, alignItems: 'flex-end' }]}>
                <Text style={[styles.text]}>
                  {`${companyInfo?.companyAddresses?.addressLine1}, ${companyInfo?.companyAddresses?.addressLine2}`}
                </Text>
                <Text style={[styles.text]}>
                  {`${companyInfo?.companyAddresses?.city}, ${companyInfo?.companyAddresses?.state}, ${companyInfo?.companyAddresses?.zipcode}`}
                </Text>
                <Text style={[styles.text]}>{companyInfo?.phonePrimary || null}</Text>
              </View>
            </View>
          )}
          {/* package number and date */}
          <View style={[styles.displayFlex, { marginTop: 20 }]}>
            <View style={styles.displayFlex}>
              <Text style={styles.numberTitle}>Request For Information </Text>
              <Text style={styles.numberSubtitle}>
                {rfiData?.number ? `${projectData.number} - ${rfiData?.number}` : null}
              </Text>
            </View>
            <Text style={styles.date}>{moment().format(AppConstants.DATE_FORMAT)}</Text>
          </View>
          {/* project data */}
          <View style={[styles.displayFlex, { marginTop: 20, justifyContent: 'flex-start' }]}>
            {fieldWithTitle('PROJECT NAME', projectData.name, { minWidth: '15%', marginRight: 8 })}
            {fieldWithTitle(
              'PROJECT ADDRESS',
              getCombinedAddressFromProjectData(projectData) || '-',
              { minWidth: '35%', marginRight: 8 }
            )}
          </View>
          {/* To | From */}
          <View style={[styles.displayFlex, { marginTop: 20, alignItems: 'flex-start' }]}>
            <View style={[styles.displayFlexColumn, { width: '20%', marginRight: 8 }]}>
              <Text style={styles.sectionTitle}>To</Text>
              {fieldWithTitle('NAME', sendTo?.name || '-')}
              {fieldWithTitle('COMPANY', sendTo?.parentEntity?.customerName || '-')}
            </View>
            <View style={[styles.displayFlexColumn, { width: '28%', marginRight: 8 }]}>
              <Text style={styles.sectionTitle}> </Text>
              {fieldWithTitle('EMAIL', sendTo?.email || rfiData?.sendTo?.email || '-')}
              {fieldWithTitle(
                'ADDRESS',
                getCombinedAddress(sendTo?.parentEntity?.companyAddresses[0]) || '-'
              )}
            </View>
            <View style={[styles.displayFlexColumn, { width: '20%', marginRight: 8 }]}>
              <Text style={styles.sectionTitle}>From</Text>
              {fieldWithTitle('NAME', returnTo?.name || '-')}
              {fieldWithTitle('COMPANY', returnTo?.company?.companyName || '-')}
            </View>
            <View style={[styles.displayFlexColumn, { width: '28%' }]}>
              <Text style={styles.sectionTitle}> </Text>
              {fieldWithTitle('EMAIL', returnTo?.email || '-')}
              {fieldWithTitle(
                'ADDRESS',
                getCombinedAddress(returnTo?.company?.companyAddresses) || '-'
              )}
            </View>
          </View>
          {/* Divider */}
          <View style={styles.divider} />
          {/* Subject */}
          <View style={styles.displayFlexColumn}>
            <Text style={styles.sectionTitle}>Subject</Text>
            <Text style={styles.text}>{rfiData?.subject || 'No subject'}</Text>
          </View>
          {/* Divider */}
          <View style={styles.divider} />
          <View style={[styles.displayFlex, { marginBottom: 12, justifyContent: 'space-between' }]}>
            {fieldWithTitle('IMPACT TO TIMELINE', rfiData?.impactToTimeline || '-', {
              width: '20%'
            })}
            {fieldWithTitle('IMPACT TO COST', rfiData?.impactToCost || '-', {
              width: '20%'
            })}
            {fieldWithTitle('SPEC REF', rfiData?.specRef || '-', {
              width: '20%'
            })}
            {fieldWithTitle('DRAWING REF', rfiData?.drawingRef || '-', {
              width: '20%'
            })}
            {fieldWithTitle('DETAIL REF', rfiData?.detailRef || '-', {
              width: '20%'
            })}
          </View>
          <View style={[styles.displayFlexColumn, { marginBottom: 12 }]}>
            <Text style={[styles.sectionTitle, { marginBottom: 10 }]}>
              TO MITIGATE DELAYS, RETURN BY{' '}
              {rfiData?.dueDate
                ? moment.unix(rfiData?.dueDate).format(AppConstants.DATE_FORMAT)
                : '-'}
            </Text>
            <Text style={styles.sectionTitle}>Information Requested</Text>
            <Text style={styles.text}>{rfiData?.informationRequested || '-'}</Text>
          </View>
          {rfiData?.recommendation && (
            <View style={[styles.displayFlexColumn, { marginBottom: 12 }]}>
              <Text style={[styles.sectionTitle, { marginBottom: 10 }]}>RECOMMENDATION</Text>
              <Text style={styles.text}>{rfiData?.recommendation}</Text>
            </View>
          )}
          {rfiData?.rfiAnswer &&
            rfiData?.rfiAnswerDate && [
              <View style={styles.divider} />,
              <View style={[styles.displayFlexColumn, { marginBottom: 12 }]}>
                <View style={[styles.displayFlex, { justifyContent: 'space-between' }]}>
                  <Text style={[styles.numberTitle, { marginBottom: 10 }]}>RFI Response</Text>
                  <Text style={styles.date}>
                    {moment.unix(rfiData?.rfiAnswerDate).format(AppConstants.DATE_FORMAT)}
                  </Text>
                </View>
                <Text style={styles.text}>{rfiData?.rfiAnswer}</Text>
              </View>
            ]}
          {/* EOD */}
        </View>
      </Page>
      {rfiData?.RfiAttachment?.filter(
        attachment => attachment.fileType.includes('image') && !attachment._deleted
      ).map(image => (
        <Page size="letter" wrap={false} style={styles.imgAttachment}>
          <View style={styles.root}>
            <Image src={image.fileUrl} />
          </View>
        </Page>
      ))}
    </Document>
  );
};

PDFDocument.propTypes = {
  companyInfo: PropTypes.object.isRequired,
  rfiData: PropTypes.object.isRequired,
  projectData: PropTypes.object.isRequired,
  sendTo: PropTypes.object.isRequired,
  returnTo: PropTypes.object.isRequired
};

export default PDFDocument;
