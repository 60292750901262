import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  headerLine: {
    border: 'none',
    color: 'black',
    backgroundColor: 'black',
    height: 1,
    width: '100%',
    margin: '0px 8px 4px 8px'
  }
}));

const HeaderLine = () => {
  const classes = useStyles();
  return <hr className={classes.headerLine} />;
};

export default HeaderLine;
