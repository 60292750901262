import gql from 'graphql-tag';

const getFormDataByJob = gql`
  query getFormDataByJob($partitionKey: String!, $sortKey: String!, $filter: GenericFilterInput) {
    getJob(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      tenantId
      hierarchy
      entityType
      jobNumber
      customIdentifier
      formData(entityConnection: "FormData") {
        items {
          id
          formDataJson
          version
          createdDateTime
          lastUpdatedDateTime
          lastUpdatedBy
          sortKey
          text1
          text2
          text3
          text4
          text5
          text6
          text7
          text8
          text9
          text10
          text11
          text12
          text13
          text14
          text15
          longText1
          longText2
          longText3
          longText4
          longText5
          longText6
          longText7
          longText8
          longText9
          longText10
          number1
          number2
          number3
          number4
          number5
          number6
          number7
          number8
          number9
          number10
          number11
          number12
          number13
          number14
          number15
          pickList1
          pickList2
          pickList3
          pickList4
          pickList5
          pickList6
          pickList7
          pickList8
          pickList9
          pickList10
          pickList11
          pickList12
          pickList13
          pickList14
          pickList15
          lookup1
          lookup2
          lookup3
          lookup4
          lookup5
          lookup6
          lookup7
          lookup8
          lookup9
          lookup10
          date1
          date2
          date3
          date4
          date5
          date6
          date7
          date8
          date9
          date10
          richText1
          richText2
          richText3
          richText4
          richText5
          checkBox1
          checkBox2
          checkBox3
          checkBox4
          checkBox5
          checkBox6
          checkBox7
          checkBox8
          checkBox9
          checkBox10
          form(entityConnection: "form") {
            id
            name
            description
            viewType
          }
        }
      }
      visits: visitsView(entityConnection: "Visit", filter: $filter) {
        items {
          id
          visitNumber
          formData(entityConnection: "FormData") {
            items {
              id
              formDataJson
              version
              createdDateTime
              lastUpdatedDateTime
              lastUpdatedBy
              sortKey
              form(entityConnection: "form") {
                id
                name
                description
                viewType
              }
            }
          }
        }
      }
    }
  }
`;

export default getFormDataByJob;
