import React from 'react';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import ErrorBoundaries from 'scenes/Error';
import { compose } from 'redux';
import { AuditLogs } from 'components';
import { withStyles } from '@material-ui/core/styles';
import { JobService } from 'services/core';
import styles from '../styles';

const ActivityList = props => {
  const { classes, maintenanceNumber } = props;

  return (
    <div className={classes.activityContainer}>
      <ErrorBoundaries>
        <Typography variant="h4" className={classes.activityTitle}>
          Activity
        </Typography>
        <AuditLogs
          variant="singleEntity"
          dataService={() => new JobService().getAllAuditLogByJobNumber(maintenanceNumber)}
        />
      </ErrorBoundaries>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, null)
)(ActivityList);
