import gql from 'graphql-tag';

const updateServiceAgreement = gql`
  mutation($data: ServiceAgreementUpdateInput!) {
    updateServiceAgreement(input: $data) {
      id
      companyId
      customerId
      billingCustomerId
      startDate
      endDate
      status
      paymentTerms
      termPrice
      agreementNumber
      agreementName
      firstBillDate
      nextBillDate
      lastBilledDate
      customerPoNumber
      rateCardJSON
    }
  }
`;

export default updateServiceAgreement;
