import React from 'react';
import { pdf } from '@react-pdf/renderer';
import { PDFDocument } from '@buildhero/sergeant';
import StorageService from 'services/StorageService';
import { Logger } from 'services/Logger';
import getConfiguration from './components/PaymentReceipt/PaymentReceipt.config';

export const getPaymentPdfName = payment => `Payment${payment.paymentNumber}.pdf`;

const getPaymentPdfBlob = async payment =>
  pdf(
    <PDFDocument layout="pdf" configuration={getConfiguration(payment)} initialValues={payment} />
  ).toBlob();

export const downloadPayment = async payment => {
  const url = URL.createObjectURL(await getPaymentPdfBlob(payment));
  const a = document.createElement('a');
  a.href = url;
  a.download = getPaymentPdfName(payment);
  a.click();
  URL.revokeObjectURL(url);
};

export const uploadPaymentPdf = async payment => {
  try {
    const storageService = new StorageService();
    return await storageService.uploadFile(
      await getPaymentPdfBlob(payment),
      `${payment.tenantId}/${getPaymentPdfName(payment)}`,
      e => e,
      'application/pdf'
    );
  } catch (error) {
    Logger.error(error);
    return null;
  }
};
