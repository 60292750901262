import gql from 'graphql-tag';
import useExtendedMutation from 'customHooks/useExtendedMutation';

const EDIT_SUMMARY_NOTE = gql`
  mutation updateSummary($partitionKey: String, $data: UpdateSummaryInput!) {
    updateSummary(partitionKey: $partitionKey, data: $data) {
      id
      summary
      includeInInvoice
      createdDateTime
      lastUpdatedBy
      lastUpdatedDateTime
      version
    }
  }
`;

const serializer = ({ tenantId, summaryId, version, summary, includeInInvoice }) => ({
  variables: {
    partitionKey: tenantId,
    data: {
      id: summaryId,
      version,
      summary,
      includeInInvoice
    }
  }
});

const optimisticResponseFactory = ({ summaryId, version, ...summary }) => ({
  updateSummary: {
    __typename: 'Summary',
    id: summaryId,
    version: version + 1,
    ...summary
  }
});

export const useEditReviewReportSummaryNote = () => {
  return useExtendedMutation(EDIT_SUMMARY_NOTE, {
    serializer,
    optimisticResponseFactory
  });
};
