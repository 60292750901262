export const DEFAULT_VISIT_DETAIL = {
  companyName: '',
  customerName: '',
  customerRep: {
    name: '',
    email: '',
    cellPhone: ''
  },
  departmentId: '',
  departmentName: '',
  description: '',
  extraTechs: [],
  extraTechsRequired: false,
  id: '',
  job: {
    id: '',
    jobNumber: '',
    priority: ''
  },
  nextEvents: [],
  prerequisites: '',
  prerequisitesAcknowledged: false,
  primaryTechId: '',
  property: {
    id: '',
    customerPropertyName: '',
    address: {
      addressType: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipcode: ''
    }
  },
  schedules: [],
  status: '',
  visitNumber: '',
  visitStateMachine: {}
};
