import gql from 'graphql-tag';

const listPropertyAssets = gql`
  query listPropertyAssets($id: String!) {
    getCustomerPropertyById(id: $id) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      parentId
      parentSortKey
      propertyAssets(entityConnection: "PropertyAsset") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          assetName
          isActive
        }
      }
    }
  }
`;
export default listPropertyAssets;
