// const defaultFlexStyle = '0 1 219px';
// const wideItemFlexStyle = '0 0 453px';
const margin = '0px 15px 18px 0px';

const AttachmentModalFormLayout = {
  fields: {
    customFileName: {
      default: 'customFileName',
      edit: 'customFileName'
    },
    newFiles: {
      default: 'newFiles',
      edit: 'newFiles'
    },
    comment: {
      default: 'comment',
      edit: 'comment'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0,
        maxWidth: 811,
        minWidth: 453
      },
      contents: [
        // files lists
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'row'
              },
              contents: [
                {
                  component: {
                    default: 'formFilesLists',
                    edit: 'formFilesLists'
                  }
                }
              ]
            }
          ]
        },
        // image carousel
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'row',
                margin
              },
              contents: [
                {
                  component: {
                    default: 'formImageCarousel',
                    edit: 'formImageCarousel'
                  }
                }
              ]
            }
          ]
        },
        // upload attachment section title
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 1
          },
          contents: [
            {
              contents: [
                {
                  component: {
                    default: 'sectionTitle',
                    edit: 'sectionTitle'
                  },
                  options: {
                    label: 'Upload New Attachment'
                  }
                }
              ]
            }
          ]
        },
        // upload file and description
        {
          options: {
            size: 3,
            direction: 'row',
            grow: 1,
            flexWrap: 'wrap'
          },
          contents: [
            // Temporarily commented out
            // {
            //   options: {
            //     direction: 'column',
            //     flex: defaultFlexStyle,
            //     margin
            //   },
            //   contents: [
            //     {
            //       component: {
            //         default: 'FieldWithLabel',
            //         edit: 'TextInput'
            //       },
            //       source: 'customFileName',
            //       options: {
            //         label: 'Rename File',
            //         fullWidth: true,
            //         alignment: 'left'
            //       }
            //     }
            //   ]
            // },
            // {
            //   options: {
            //     direction: 'column',
            //     flex: wideItemFlexStyle,
            //     margin
            //   },
            //   contents: [
            //     {
            //       component: {
            //         default: 'FieldWithLabel',
            //         edit: 'TextInput'
            //       },
            //       source: 'comment',
            //       options: {
            //         label: 'Comments',
            //         placeholder: 'Enter Comments',
            //         fullWidth: true,
            //         alignment: 'left'
            //       }
            //     }
            //   ]
            // }
          ]
        },
        // upload attachment button
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'row'
              },
              contents: [
                {
                  component: {
                    default: 'formUploadAttachmentBtn',
                    edit: 'formUploadAttachmentBtn'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      customFileName: {
        type: 'string'
      },
      comment: {
        type: 'string'
      },
      newFiles: {
        type: 'array'
      }
    },
    required: ['newFiles']
  },
  validationErrors: {
    newFiles: {
      required: 'File is required.'
    }
  }
};

export default AttachmentModalFormLayout;
