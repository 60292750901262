import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  selectionInput: {
    height: 30,
    borderRadius: 2,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grayscale(80),
    padding: 0,
    boxSizing: 'border-box',
    '& p': {
      fontSize: '14px',
      marginLeft: '5px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 'auto',
      color: theme.palette.text.primary,
      marginTop: '0px'
    }
  },
  selectionWrapper: {
    height: '100%',
    padding: 0,
    background: theme.palette.grayscale(92),
    '&:hover': {
      background: theme.palette.grayscale(92)
    },
    minWidth: 180
  },
  disabled: {
    alignSelf: 'center'
  }
}));
