import gql from 'graphql-tag';

const addNotesToQuote = gql`
  mutation addNotesToQuote($partitionKey: String!, $data: AddNotesToQuoteInput!) {
    addNotesToQuote(partitionKey: $partitionKey, data: $data) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      subject
      note
      createdBy
      lastUpdatedDate
      lastUpdatedDateTime
      lastUpdatedBy
    }
  }
`;

export default addNotesToQuote;
