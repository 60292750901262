/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { calculateMarginFromMarkup, calculateMarkupFromMargin } from '@buildhero/math';
import { Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { isEmpty } from 'lodash';
import { connect, useSelector } from 'react-redux';

import { compose } from 'redux';

import { Spinner } from 'components';
import LinkButton from 'components/Buttons/BlueText';
import Select from 'components/Select';
import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';

import ErrorBoundaries from 'scenes/Error';
import { getPriceBooksByCompany } from 'services/API/priceBook';
import { CompanyService } from 'services/core';

import { Logger } from 'services/Logger';
import arrayToObject from 'utils/arrayToObject';
import { MaterialMarkupType, PricingStrategy } from 'utils/constants';
import { constructSelectOptions } from 'utils/constructSelectOptions';

import { convertForMathLib } from 'utils/mathLibrary';

import FlatMarkup from './FlatMarkup';
import MaterialRateModal from './MaterialRateModal';
import MaterialRateRangeInput from './MaterialRateRangeInput';
import styles from './style';

const MaterialRateSection = props => {
  const {
    user,
    classes,
    form,
    setRates,
    dropDownColWidth,
    isReadOnly,
    options,
    priceBookConfig,
    onChangeFormat = () => {}
  } = props;
  const pricebookVersion = useSelector(s => s.company.pricebookVersion);
  const [openMatRateModal, setOpenMatRateModal] = useState(false);
  const [selectedMaterialRate, selectMaterialRate] = useState('');
  const [materialRatePricebook, setMaterialRatePriceBook] = useState('');
  const [rangeData, setRangeData] = useState([]);
  const [flatMarkup, setFlatMarkup] = useState([]);
  const [pricebooks, setPricebooks] = useState([]);
  const [isLoadingPricebooks, setIsLoadingPricebooks] = useState(false);
  const marginEnabledSetting = useSelector(
    s => s.settings.pricingStrategy === PricingStrategy.MARGIN
  );
  const marginEnabled = options?.showConfigSection
    ? form.values?.pricebookConfig === PricingStrategy.MARGIN
    : marginEnabledSetting;

  // have to fully think through the various initialization scenarios
  const rateCardData = {
    materialMarkupPricebookId: props.rateCardData?.materialMarkupPricebookId,
    materialMarkupEntries: props.rateCardData?.materialMarkupEntries || props.field?.value
  };

  const handleRateSave = (event, selectedValue) => {
    event.preventDefault();

    if (selectedValue === MaterialMarkupType.RANGE && rangeData.length < 2) {
      const defaultRange = arrayToObject(['start', 'end', 'rate']);
      const newData = [...rangeData, { ...defaultRange }, { ...defaultRange }];
      setRangeData(newData);
    } else if (selectedValue === MaterialMarkupType.NONE) {
      const data = [{ start: 0, end: null, rate: 0 }];
      setFlatMarkup(data);
      form ? form.setFieldValue('materialRate', data) : setRates('materialRate', data);
    }
    if (setRates) {
      setRates('materialMarkupPricebookId', '');
    }
    setMaterialRatePriceBook(null);
    selectMaterialRate(selectedValue);
    setOpenMatRateModal(false);
  };

  useEffect(() => {
    if (rateCardData) {
      const { materialMarkupPricebookId, materialMarkupEntries } = rateCardData;
      let type;
      const len = !materialMarkupPricebookId && materialMarkupEntries?.length;
      if (len === 1) {
        type = MaterialMarkupType.FLAT;
        setFlatMarkup(materialMarkupEntries);
      } else if (len > 1) {
        type = MaterialMarkupType.RANGE;
        setRangeData(materialMarkupEntries);
      }
      selectMaterialRate(type);
      const option = pricebooks.find(o => o.value === rateCardData?.materialMarkupPricebookId);
      setMaterialRatePriceBook(option);
    }
  }, [pricebooks]);

  const convertDataBasedOnConfig = data => {
    return data.map(markup => {
      return {
        ...markup,
        rate:
          (priceBookConfig || form.values.pricebookConfig) === PricingStrategy.MARGIN
            ? convertForMathLib(calculateMarginFromMarkup, markup.rate)
            : convertForMathLib(calculateMarkupFromMargin, markup.rate)
      };
    });
  };

  useEffect(() => {
    let convertedData;
    if (flatMarkup.length > 0) {
      convertedData = convertDataBasedOnConfig(flatMarkup);
      if (convertedData) setFlatMarkup(convertedData);
    } else if (rangeData && rangeData.length > 0) {
      convertedData = convertDataBasedOnConfig(rangeData);
      if (convertedData) setRangeData(convertedData);
    }
    if (convertedData) {
      form
        ? form.setFieldValue('materialRate', convertedData)
        : setRates('materialRate', convertedData);
    }
  }, [priceBookConfig, form?.values?.pricebookConfig]);

  const handleSetFlatMarkup = rate => {
    const data = [
      {
        start: 0,
        end: null,
        rate
      }
    ];

    setFlatMarkup(data);
    form ? form.setFieldValue('materialRate', data) : setRates('materialRate', data);
  };

  const changeFormat = () => {
    setRangeData([]);
    setFlatMarkup([]);
    selectMaterialRate('');
    onChangeFormat();
  };

  const handleOnChangeRange = data => {
    data[0].start = 0;
    data[data.length - 1].end = null;
    setRangeData(data);

    form ? form.setFieldValue('materialRate', data) : setRates('materialRate', data);
  };

  const handleSelectPricebook = option => {
    form
      ? form.setFieldValue('materialRate', option)
      : setRates('materialMarkupPricebookId', option.value);

    setMaterialRatePriceBook(option);
  };

  const queryPricebooksV1 = () => {
    const companyService = new CompanyService();
    setIsLoadingPricebooks(true);

    try {
      companyService
        .getPricebooksForCompany(user.tenantId, `${user.tenantId}_Company_${user.tenantCompanyId}`)
        .then(company => {
          setPricebooks(constructSelectOptions(company.data?.getCompany?.priceBooks?.items || []));
          setIsLoadingPricebooks(false);
        });
    } catch (e) {
      Logger.error(e);
      snackbarOn('error', 'Failed to fetch company pricebook information');
    }
  };

  const queryPricebooksV2 = async () => {
    setIsLoadingPricebooks(true);

    try {
      const response = await getPriceBooksByCompany();
      setPricebooks(constructSelectOptions(response?.data?.priceBooks || []));
    } catch (e) {
      Logger.error(e);
      snackbarOn('error', 'Failed to fetch company pricebook information');
    }
    setIsLoadingPricebooks(false);
  };

  useEffect(() => {
    // TODO: this should be passed in from parent but will require a fair bit of rejiggering in multiple parents.
    // The pricebook list might be best stored in redux even.
    if (pricebookVersion >= 2) {
      queryPricebooksV2();
    } else {
      queryPricebooksV1();
    }
  }, [user.tenantId, user.tenantCompanyId]);

  return (
    <ErrorBoundaries>
      <Grid item xs={12} className={props.classes.mb5}>
        <Grid item xs={12}>
          <Box component="div" className={classes.dFlex}>
            <Typography variant="h6">
              {
                (priceBookConfig === PricingStrategy.MARGIN || marginEnabled
                  ? Labels.materialMarginRates
                  : Labels.materialMarkupRates)[user.locale]
              }
            </Typography>

            {options?.allowFormatChange && !isEmpty(selectedMaterialRate) && (
              <LinkButton
                className={`${classes.ml2} ${classes.auditText}`}
                label={Labels.changeFormat[user.locale]}
                handle={() => changeFormat()}
              />
            )}
          </Box>

          {isEmpty(selectedMaterialRate) && (
            <Grid xs={dropDownColWidth}>
              <Typography variant="body2" className={classes.rateDescription}>
                {Labels.createFromExistingPricebook[user.locale].toUpperCase()}
              </Typography>
              {isLoadingPricebooks ? (
                <Spinner styles={{ margin: 5 }} size={19.6} />
              ) : (
                <Select
                  name="name"
                  label={Labels.selectPricebook[user.locale]}
                  value={materialRatePricebook}
                  isDisabled={isReadOnly}
                  onChange={handleSelectPricebook}
                  options={pricebooks}
                  menuHeight={100}
                />
              )}
              {!isReadOnly && (
                <Typography
                  variant="body1"
                  className={`${classes.mt2} ${classes.handIcon}`}
                  onClick={() => setOpenMatRateModal(true)}
                >
                  <AddCircleOutlineIcon className={classes.wrapIcon} />
                  Set New Material Rates
                </Typography>
              )}
            </Grid>
          )}
        </Grid>

        <MaterialRateModal
          openMatRateModal={openMatRateModal}
          handleClose={() => setOpenMatRateModal(false)}
          handleRateSave={handleRateSave}
          classes={classes}
          user={user}
          marginEnabled={priceBookConfig === PricingStrategy.MARGIN || marginEnabled}
        />

        {!openMatRateModal && (
          <Grid item xs={12}>
            {selectedMaterialRate === MaterialMarkupType.RANGE && (
              <MaterialRateRangeInput
                classes={classes}
                startRangeKey="start"
                endRangeKey="end"
                markupKey="rate"
                isViewMode={false}
                minRangeLabel="$0.00"
                maxRangeLabel="MAX $"
                rangeData={rangeData}
                setRangeData={handleOnChangeRange}
                marginSetting={priceBookConfig === PricingStrategy.MARGIN || marginEnabled}
                isPricebookConfig
              />
            )}

            {[MaterialMarkupType.FLAT, MaterialMarkupType.NONE].includes(selectedMaterialRate) && (
              <FlatMarkup
                marginSetting={priceBookConfig === PricingStrategy.MARGIN || marginEnabled}
                flatMarkup={flatMarkup}
                setFlatMarkup={handleSetFlatMarkup}
                adornmentSymbol="%"
                isViewMode={selectedMaterialRate === MaterialMarkupType.NONE}
              />
            )}
          </Grid>
        )}
      </Grid>
    </ErrorBoundaries>
  );
};

MaterialRateSection.defaultProps = {
  classes: {},
  dropDownColWidth: 12,
  rateCardData: {},
  isReadOnly: false
};

const mapStateToProps = state => ({
  user: state.user
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, { snackbarOn })
)(MaterialRateSection);
