import gql from 'graphql-tag';
import { customFormFragment } from './queryFragments';

export const getCustomFormDataByQuoteId = gql`
  query getQuoteById($id: String!) {
    getQuoteById(id: $id) {
      ${customFormFragment}
    }
}`;

export const getCustomFormDataByVersionedQuoteId = gql`
  query getVersionedQuoteById($id: String!) {
    getVersionedQuoteById(id: $id) {
      ${customFormFragment}
    }
}`;
