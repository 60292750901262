import gql from 'graphql-tag';
import { inventoryFragment } from 'customHooks/useJob';

const addInventoryPartsToJob = gql`
  mutation addInventoryPartsToJob($partitionKey: String!, $data: AddInventoryPartsToJobInput!) {
    addInventoryPartsToJob(partitionKey: $partitionKey, data: $data) {
      ${inventoryFragment}
    }
  }
`;

export default addInventoryPartsToJob;
