import { useMemo } from 'react';
import { useDrop } from 'react-dnd';

import { DragScenarios, ItemTypes, selectDragScenario } from '@dispatch/dnd';

import { trackDrop } from '@dispatch/components/DispatchBoard/DispatchBoard.utils';

import { selectTechVisits } from '../../DispatchBoard.selectors';

import { LOADING_TECHS } from './DailyView.constants';
import { serializeDropData } from './DailyView.serializers';
import { selectSnappedOffset, selectTechEvents } from './DailyView.selectors';

export const useItemData = ({
  techsResponse,
  boardVisitsResponse,
  boardNonVisitEventsResponse,
  boardManDayItemsResponse,
  boardRef
}) => {
  return useMemo(() => {
    if (techsResponse.loading) return LOADING_TECHS;

    const techsVisits = selectTechVisits(boardVisitsResponse.data);

    const itemData = techsResponse.data.map(tech => {
      const techVisits = techsVisits[tech.id] || [];
      const techNonVisitEvents = boardNonVisitEventsResponse.data?.[tech.id] || [];
      const techManDayItems = boardManDayItemsResponse.data?.[tech.id] || [];
      const techEvents = selectTechEvents({ techVisits, techNonVisitEvents, techManDayItems });
      return {
        tech,
        techLoading: false,
        eventsLoading:
          boardVisitsResponse.loading ||
          boardNonVisitEventsResponse.loading ||
          boardManDayItemsResponse.loading,
        techEvents,
        board: boardRef
      };
    });

    return [{}, ...itemData];
  }, [
    techsResponse.loading,
    techsResponse.data,
    boardVisitsResponse.loading,
    boardVisitsResponse.data,
    boardNonVisitEventsResponse.loading,
    boardNonVisitEventsResponse.data,
    boardManDayItemsResponse.data,
    boardManDayItemsResponse.loading,
    boardRef
  ]);
};

export const useVisitDrop = ({
  clientOffsetRef,
  triggerVisitTransition,
  boardRef,
  day,
  clearVisitRowHover
}) => {
  const [collected, dropRef] = useDrop({
    accept: [ItemTypes.TABLE_VISIT, ItemTypes.BOARD_VISIT],
    collect: monitor => ({
      isOver: monitor.isOver(),
      dragScenario: selectDragScenario(monitor.getItem()),
      primaryTech: monitor.getItem()?.data?.primaryTechId,
      extraTechs: monitor.getItem()?.data?.extraTechs
    }),
    hover: (item, monitor) => {
      // workaround for getClientOffset returning null on drop monitor
      const clientOffset = monitor.getClientOffset();
      // eslint-disable-next-line no-param-reassign
      if (clientOffset) clientOffsetRef.current = clientOffset;
    },
    drop: item => {
      const dragScenario = collected?.dragScenario;
      const itemType = collected?.item?.type;
      trackDrop({ dragScenario, itemType });
      if (
        collected.dragScenario === DragScenarios.FULLY_ASSIGNED_MULTI_TECH ||
        collected.dragScenario === DragScenarios.FULLY_ASSIGNED_SINGLE_TECH
      ) {
        triggerVisitTransition(
          serializeDropData({
            item,
            dropPreview: {
              left: selectSnappedOffset(boardRef.scrollLeft, clientOffsetRef.current.x)
            },
            day,
            ...collected
          })
        );
      }
      clearVisitRowHover();
    }
  });

  return [collected, dropRef];
};
