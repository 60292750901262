import buildHeroMuiFormOverrides from 'scenes/ProjectManagement/components/buildHeroMuiFormOverrides';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 24
  },
  sectionTitle: {
    color: theme.palette.grayscale(20),
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: '-0.03em',
    marginBottom: 20
  },
  iconTextContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  addPhotoIcon: {
    color: '#333333',
    fontSize: 20
  },
  addPhotoText: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '-0.02em',
    textDecoration: 'none',
    marginLeft: theme.spacing(0.5)
  },
  changePhotoIcon: {
    color: theme.palette.common.white,
    fontSize: 20
  },
  changePhotoText: {
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '-0.02em',
    textDecoration: 'none',
    marginLeft: theme.spacing(0.5)
  },
  uploadImageContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  imageInputContainer: {
    minWidth: 420,
    height: 255,
    '&:hover': {
      zIndex: 1,
      '& $imageBackdrop': {
        backgroundColor: '#C0C0C0',
        opacity: 0.5
      },
      '& $addPhotoIcon, $changePhotoIcon': {
        fontSize: 21
      },
      '& $addPhotoText, $changePhotoText': {
        fontSize: 15
      }
    }
  },
  hideElement: {
    display: 'none'
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 420,
    height: 255
  },
  selectedImage: {
    width: '100%',
    height: '100%',
    display: 'block',
    backgroundSize: 'cover',
    backgroundPosition: 'center, center'
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#F0F0F0',
    opacity: 1
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  validationError: {
    color: 'rgb(176, 0, 32)',
    fontSize: 12,
    marginTop: 3,
    fontWeight: 500
  },
  formContainer: buildHeroMuiFormOverrides(theme)
}));
