import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';
import { Timezone } from 'scenes/Procurement/constants';
import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';
import { departmentSearch } from 'services/API/department';
import { employeeSearch } from 'services/API/employee';
import { purchaseOrderTypeSearch } from 'services/API/purchaseOrderType';
import { vendorSearch } from 'services/API/vendor';

const defaultFlexStyle = '0 1 219px';
const wideItemFlexStyle = '0 0 453px';
const margin = '0px 8px 16px 8px';

const fieldOrderGeneralInformationFields = {
  poType: { required: true },
  date: { required: true, nullable: true },
  department: { required: false },
  vendor: { required: true },
  assignTo: { required: false },
  jobOrProjectDisplay: { required: true },
  description: { required: false, maxCharLimit: 2000 },
  requiredByDate: { required: false, nullable: true },
  tags: { required: false }
};

const fieldOrderGeneralInformationContents = (tags, department, handleJobOrProjectIdChange) => {
  return [
    // the 1st section title
    {
      options: {
        size: 1,
        direction: 'row',
        grow: 0
      },
      contents: [
        {
          options: {
            margin
          },
          contents: [
            {
              component: {
                edit: 'MuiFormSectionTitle'
              },
              options: {
                label: 'General Information'
              }
            }
          ]
        }
      ]
    },

    // the 1st row
    {
      options: {
        size: 3,
        direction: 'row',
        grow: 0,
        flexWrap: 'nowrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'SearchBar'
              },
              source: 'poType',
              options: {
                resultFormatFunction: type => `${type.name}`,
                searchFunction: purchaseOrderTypeSearch,
                searchColumns: ['name'],
                searchParams: [({ autoReceive }) => !autoReceive],
                label: 'PO Type',
                placeholder: 'Select PO Type',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: true,
                errorText: 'Field is required'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'DateInput'
              },
              source: 'date',
              options: {
                label: 'Date',
                placeholder: '',
                fullWidth: false,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: true,
                timezone: Timezone.GMT
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'SearchBar'
              },
              source: 'department',
              options: {
                resultFormatFunction: dept => `${dept.tagName}`,
                searchFunction: departmentSearch,
                searchColumns: ['tagName'],
                label: 'Department',
                placeholder: 'Select Department',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false,
                errorText: 'Field is required'
              }
            }
          ]
        }
      ]
    },

    // the 2nd row
    {
      options: {
        size: 3,
        direction: 'row',
        grow: 0,
        flexWrap: 'nowrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'SearchBar'
              },
              source: 'vendor',
              options: {
                resultFormatFunction: vendor => `${vendor.name}`,
                searchFunction: vendorSearch,
                searchColumns: ['name'],
                label: 'Vendor',
                placeholder: 'Select Vendor',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: true,
                disabled: !department,
                errorText: 'Field is required',
                helperText: 'Please select a department first'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'SearchBar'
              },
              source: 'assignedTo',
              options: {
                resultFormatFunction: employee => `${employee.name}`,
                searchFunction: employeeSearch,
                searchColumns: ['name'],
                searchParams: [department],
                label: 'Assign To',
                placeholder: 'Select Assignee',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false,
                disabled: !department,
                helperText: 'Please select a department first x'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'AlgoliaSearch'
              },
              // source will not used by algolia search wrapper, it uses only valuesToSet
              source: 'jobOrProjectDisplay',
              options: {
                required: true,
                searchIndexName: defaultSearchIndex,
                searchResultDisplayFields: ['jobNumber', 'projectName', 'customerName'],
                valueField: 'id',
                delimiter: ' - ',
                label: 'Job / Project',
                placeholder: 'Search and Select',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                filters: [
                  {
                    attribute: 'entityType',
                    valueArray: ['Job', 'Project']
                  }
                ],
                valuesToSet: [{ jobId: 'id', jobNumber: 'jobNumber' }],
                onChange: handleJobOrProjectIdChange,
                errorText: 'Field is required',
                select: true
              }
            }
          ]
        }
      ]
    },

    // the 3rd row
    {
      options: {
        size: 3,
        direction: 'row',
        grow: 0,
        flexWrap: 'nowrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: 3,
            margin
          },
          contents: [
            {
              component: {
                edit: 'TextInput'
              },
              source: 'description',
              options: {
                label: 'Description',
                placeholder: 'Enter Description',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                lines: 5
              }
            }
          ]
        }
      ]
    },

    // the 4th row
    {
      options: {
        size: 3,
        direction: 'row',
        grow: 0,
        flexWrap: 'nowrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'DateInput'
              },
              source: 'requiredByDate',
              options: {
                label: 'Required By',
                placeholder: 'Select Date',
                fullWidth: false,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false,
                timezone: Timezone.GMT
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: wideItemFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'SelectInput'
              },
              source: 'tags',
              options: {
                label: 'Tags',
                placeholder: 'Select Tags',
                isMultipleSelection: true,
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                valueSet: tags,
                enableSort: false
              }
            }
          ]
        }
      ]
    }
  ];
};

const fieldOrderGeneralInformationLayout = ({ tags, department, handleJobOrProjectIdChange }) => {
  return {
    fields: generateDefaultFieldsObject(fieldOrderGeneralInformationFields),
    layouts: {
      edit: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: fieldOrderGeneralInformationContents(tags, department, handleJobOrProjectIdChange)
      }
    }
  };
};

export { fieldOrderGeneralInformationFields, fieldOrderGeneralInformationLayout };
