export const useStyles = () => ({
  container: theme => ({
    padding: theme.spacing(1),
    background: theme.palette.grayscale(98),
    border: 'none',
    '&:hover': {
      background: theme.palette.grayscale(92)
    },
    '& input': {
      color: theme.palette.grayscale(20),
      textAlign: 'right'
    }
  })
});
