import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Tool from './tool';
import toolboxTools, { developerVersionTools } from './ToolboxData';

const ExpansionPanel = withStyles({
  root: {
    borderBottom: '1px solid rgba(0,0,0,.2)',
    borderRadius: 0,
    boxShadow: 'none',
    '&:first-child': {
      borderRadius: 0
    },
    '&:last-child': {
      borderRadius: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles(theme => ({
  root: {
    padding: `0 ${theme.spacing(1)}px`,
    minHeight: 45,
    '&$expanded': {
      minHeight: 45
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
}))(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    boxShadow:
      'inset 0px 4px 2px -2px rgba(0,0,0,0.2), inset 0px 1px 1px 0px rgba(0,0,0,0.14), inset 0px 1px 3px 0px rgba(0,0,0,0.12)',
    display: 'block',
    float: 'left',
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    width: '100%'
  }
}))(MuiExpansionPanelDetails);

export const Toolbox = ({ addItem, updateDragging, classes, developerVersion }) => {
  const [expanded, setExpanded] = React.useState(0);
  const availableTools = [...toolboxTools];
  if (developerVersion) availableTools.push(developerVersionTools);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className={classes.toolbox}>
      {availableTools.map((section, index) => {
        const { label, tools } = section;
        return (
          <ExpansionPanel key={label} expanded={expanded === index} onChange={handleChange(index)}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="textNumber-content"
              id="textNumber-header"
            >
              <Typography className={classes.heading}>{label}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {tools.map(tool => (
                <Tool
                  key={tool.toolLabel}
                  classes={classes}
                  tool={tool}
                  handleDrop={addItem}
                  updateDragging={updateDragging}
                />
              ))}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </div>
  );
};
