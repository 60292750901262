import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import FormWithAccordion from '@pm/components/FormWithAccordion';
import {
  useEmployees,
  usePayrollHourTypes,
  useProjectPhases
} from '@pm/ProjectDetails/FieldReport/DailyReportList/DailyReport.hooks';
import { useSnackbar } from 'customHooks/useSnackbar';
import GenerateTimesheetsButton from './components/GenerateTimesheetsButton';
import LaborFormHeader from './components/LaborFormHeader';
import LaborProductivityForm from './components/LaborProductivityForm';
import LaborPhasesForm from './components/LaborPhasesForm';
import SectionHeader from './components/SectionHeader';
import EmployeesTimesheets from './components/EmployeesTimesheets';
import {
  isValidLaborFormData,
  phaseTemplate,
  convertFormDataToEmployeesTimesheets,
  mergeTimesheets,
  getEmployeesWithApprovedOrPendingTimesheets
} from './LaborFieldForm.helpers';
import { useStyles } from './LaborFieldForm.styles';

const LaborFieldForm = ({
  allTimesheets,
  projectTimesheets,
  projectId,
  loading,
  onFormDataChange
}) => {
  const snackbar = useSnackbar();
  const classes = useStyles();
  const [formLaborData, setFormLaborData] = useState({});
  const formLaborDataRef = useRef([]);
  formLaborDataRef.current = formLaborData;

  const employeesTimesheets = projectTimesheets;
  const employeesTimesheetsRef = useRef([]);
  employeesTimesheetsRef.current = employeesTimesheets;

  const allTimesheetsRef = useRef([]);
  allTimesheetsRef.current = allTimesheets;

  const [{ data: projectPhases }] = useProjectPhases(projectId);
  const [{ data: payrollHourTypes }] = usePayrollHourTypes();
  const [{ data: employees }] = useEmployees();
  const employeesRef = useRef([]);
  employeesRef.current = employees;

  const initFormLaborData = useCallback(
    payrollHourTypesData => {
      setFormLaborData({
        phases: [
          phaseTemplate({
            phaseIndex: 0,
            payrollHourTypes: payrollHourTypesData,
            projectPhases
          })
        ]
      });
    },
    [projectPhases]
  );

  useEffect(() => {
    if (payrollHourTypes?.length) {
      initFormLaborData(payrollHourTypes);
    }
  }, [payrollHourTypes, initFormLaborData]);

  useEffect(() => {
    const readOnlyEmployees = getEmployeesWithApprovedOrPendingTimesheets({
      timesheets: allTimesheetsRef.current,
      employees: employeesRef.current
    });
    const { workers } = formLaborData || {};
    const readonlyEmployees = readOnlyEmployees?.filter(employee => {
      return R.includes(employee?.id)(workers || []);
    });
    if (readonlyEmployees.length) {
      const names = readonlyEmployees.map(({ name }) => name).join(', ');
      const statuses = readonlyEmployees.map(({ status }) => status).join(', ');
      const selectedReadonlyIds = readonlyEmployees.map(({ id }) => id);
      const isNotReadonlyEmployee = id => !R.includes(id)(selectedReadonlyIds);
      const newWorkers = R.filter(isNotReadonlyEmployee)(workers || []);
      setFormLaborData({
        ...formLaborData,
        workers: newWorkers
      });
      snackbar('error', `Time cannot be entered for ${names} since their timesheet is ${statuses}`);
    }
  }, [formLaborData, snackbar]);

  const handleFormLaborProductivityChange = useCallback(
    laborProductivityData => {
      setFormLaborData({
        ...formLaborData,
        ...laborProductivityData
      });
    },
    [formLaborData]
  );

  const handleFormPhaseChange = updatedPhase => {
    const laborDataCurrent = formLaborDataRef.current;
    setFormLaborData({
      ...laborDataCurrent,
      phases: laborDataCurrent.phases.map(phase => {
        if (phase.phaseIndex === updatedPhase.phaseIndex) {
          return updatedPhase;
        }
        return phase;
      })
    });
  };

  const handleFormPhaseAdd = () => {
    const laborDataCurrent = formLaborDataRef.current;
    const phases = laborDataCurrent?.phases || [];
    setFormLaborData({
      ...laborDataCurrent,
      phases: [
        ...phases,
        phaseTemplate({
          phaseIndex: phases?.length ?? 0,
          payrollHourTypes,
          projectPhases
        })
      ]
    });
  };

  const handleFormPhaseDelete = useCallback(phaseToDelete => {
    const laborDataCurrent = formLaborDataRef.current;
    const { phases } = laborDataCurrent;
    const deletePhaseIndex = phaseToDelete.phaseIndex;
    const updatedPhases = phases
      .filter(phase => phase.phaseIndex !== deletePhaseIndex)
      .map(phase => {
        const { phaseIndex: index } = phase;
        return {
          ...phase,
          phaseIndex: index > deletePhaseIndex ? index - 1 : index
        };
      });
    setFormLaborData({
      ...laborDataCurrent,
      phases: updatedPhases
    });
  }, []);

  const handleTimesheetChange = updatedTimesheet => {
    onFormDataChange(
      employeesTimesheetsRef.current?.map(timesheet => {
        if (
          (timesheet?.id && timesheet.id === updatedTimesheet?.id) ||
          (timesheet?.key && timesheet.key === updatedTimesheet?.key)
        ) {
          return updatedTimesheet;
        }
        return timesheet;
      }) || []
    );
  };

  const handleGenerateTimesheets = useCallback(() => {
    const nextEmployeesTimesheets = convertFormDataToEmployeesTimesheets({
      formLaborData
    });

    const newEmployeesTimesheets = mergeTimesheets(
      employeesTimesheets || [],
      nextEmployeesTimesheets || []
    );

    onFormDataChange(newEmployeesTimesheets);
    initFormLaborData(payrollHourTypes);
  }, [employeesTimesheets, formLaborData, onFormDataChange, payrollHourTypes, initFormLaborData]);

  const disableGenerateTimesheets = !isValidLaborFormData(formLaborData);
  const muiForms = () => (
    <>
      <div className={classes.container}>
        <LaborFormHeader />
        <LaborProductivityForm
          employees={employees}
          laborData={formLaborData}
          onLaborProductivityChange={handleFormLaborProductivityChange}
        />
        <Divider />
        <LaborPhasesForm
          projectId={projectId}
          projectPhases={projectPhases}
          payrollHourTypes={payrollHourTypes}
          phases={formLaborData.phases}
          onPhaseAdd={handleFormPhaseAdd}
          onPhaseChange={handleFormPhaseChange}
          onPhaseDelete={handleFormPhaseDelete}
        />
        <GenerateTimesheetsButton
          disabled={disableGenerateTimesheets}
          onClick={handleGenerateTimesheets}
        />
      </div>
      <div className={classes.timesheetsContainer}>
        {Boolean(employeesTimesheets?.length) && (
          <SectionHeader>Individual Timesheet Entries</SectionHeader>
        )}
        <EmployeesTimesheets
          employees={employees}
          employeesTimesheets={employeesTimesheets}
          projectPhases={projectPhases}
          payrollHourTypes={payrollHourTypes}
          onTimesheetChange={handleTimesheetChange}
        />
      </div>
    </>
  );

  const [expanded, setExpanded] = useState(false);
  const handlePanelExpand = (event, newExpanded) => {
    setExpanded(newExpanded);
  };

  return (
    <div className={classes.root}>
      <FormWithAccordion
        sectionName="Labor Productivity"
        formComponent={muiForms}
        handlePanelExpand={handlePanelExpand}
        loading={loading}
        expanded={expanded}
      />
      <Divider />
    </div>
  );
};

LaborFieldForm.propTypes = {
  allTimesheets: PropTypes.array.isRequired,
  projectTimesheets: PropTypes.array.isRequired,
  projectId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onFormDataChange: PropTypes.func.isRequired
};

LaborFieldForm.defaultProps = {
  loading: false
};

export default LaborFieldForm;
