import React from 'react';
import { func, bool } from 'prop-types';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import { withQueryStringStore } from '@dispatch/Dispatch.store';

const mapQueryStringToProps = store => ({
  openTray: store.openTray,
  closeTray: store.closeTray,
  trayOpen: Boolean(store.state.trayOpen)
});

const VisitsToggle = ({ trayOpen, openTray, closeTray }) => (
  <IconButton onClick={trayOpen ? closeTray : openTray}>
    {trayOpen ? <ExpandMore /> : <ExpandLess />}
  </IconButton>
);

VisitsToggle.propTypes = {
  trayOpen: bool,
  openTray: func.isRequired,
  closeTray: func.isRequired
};

VisitsToggle.defaultProps = {
  trayOpen: true
};

export default withQueryStringStore(mapQueryStringToProps)(VisitsToggle);
