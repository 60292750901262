import React from 'react';
import { connect } from 'react-redux';
import { ResponsiveTable } from 'components';
import { snackbarOn } from 'redux/actions/globalActions';
import PropTypes from 'prop-types';
import useLabourTypeDependency from 'customHooks/useLabourTypeDependency';
import useBillingHourTypes from 'customHooks/usePayrollBillingHourTypes';
import useLabourTypes from 'customHooks/useLabourTypes';
import useLabourRates from 'customHooks/useLabourRates';
import { isEmpty } from 'lodash';
import { addHourTypeToMeta, constructLabourData } from './LabourRateHelpers';
import LabourRateInput from './LabourRateInput';

const labourRatesMeta = [
  {
    id: 'name',
    type: 'text',
    label: 'Labor Type'
  }
];

const LabourRate = ({ user, ...props }) => {
  const { tenantId, tenantCompanyId } = user;
  const serviceArgs = [tenantId, tenantCompanyId, props.snackbarOn];
  const { metaData, data, isReadOnly, isLoading = false } = props;
  const [costCodes, costTypes, revenueTypes, , labourTypeDependecyLoaded] = useLabourTypeDependency(
    ...serviceArgs
  );

  const [billingHourTypes, , billingHourTypesLoaded] = useBillingHourTypes(...serviceArgs);
  const [labourTypes, , labourTypesLoaded] = useLabourTypes(...serviceArgs);
  const [labourRates, , labourRateLoaded] = useLabourRates(...serviceArgs);

  const tableMeta = addHourTypeToMeta([...labourRatesMeta], billingHourTypes);
  const tableData = constructLabourData({
    costCodes,
    labourTypes,
    labourRates,
    billingHourTypes,
    jobCostTypes: [...revenueTypes, ...costTypes]
  });
  const isLoaded =
    labourRateLoaded &&
    billingHourTypesLoaded &&
    labourTypeDependecyLoaded &&
    labourTypesLoaded &&
    !isLoading;
  const laborData = !isEmpty(data) ? data : tableData;
  const laborMetaData = !isEmpty(metaData) ? metaData : tableMeta;

  return (
    <ResponsiveTable
      data={laborData}
      rowMetadata={laborMetaData}
      isLoading={!isLoaded}
      disableFilter={props.disableFilter}
      customCellComponents={{
        RateText: ({ ...recordProps }) => (
          <LabourRateInput
            {...recordProps}
            isReadOnly={isReadOnly}
            handleInputChanges={props.handleLabourRateChanges}
          />
        )
      }}
      key={`${data?.length}-${laborMetaData?.length}`}
    />
  );
};

const mapStateToProps = ({ user }) => ({ user });
const mapDispatcherToProps = { snackbarOn };

LabourRate.propTypes = {
  user: PropTypes.object.isRequired,
  handleLabourRateChanges: PropTypes.func,
  addNewRates: PropTypes.bool,
  disableFilter: PropTypes.bool,
  showDefaults: PropTypes.bool
};

LabourRate.defaultProps = {
  addNewRates: false,
  disableFilter: false,
  showDefaults: true,
  handleLabourRateChanges: () => {}
};

const connectedLabourRate = connect(mapStateToProps, mapDispatcherToProps)(LabourRate);

export default connectedLabourRate;
