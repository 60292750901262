import gql from 'graphql-tag';

const getAuditLogsByVersionedQuoteId = gql`
  query getAuditLogsByVersionedQuoteId($id: String!) {
    getVersionedQuoteById(id: $id) {
      id
      auditLogs(entityConnection: "AuditLogEntry", limit: 100) {
        items {
          auditedEntityType
          auditedEntityDisplayName
          executionType
          executedBy
          executedDateTime
          auditedEntityId
          auditedEntitySortKey
          auditedEntityParentId
          auditedEntityParentSortKey
          auditedEntityParentEntityType
          auditedEntityParentDisplayName
          changeLog
          customMessage
        }
      }
      quoteTaskGroups(entityConnection: "QuoteTaskGroups") {
        items {
          auditLogs(entityConnection: "AuditLogEntry", limit: 100) {
            items {
              auditedEntityType
              auditedEntityDisplayName
              executionType
              executedBy
              executedDateTime
              auditedEntityId
              auditedEntitySortKey
              auditedEntityParentId
              auditedEntityParentSortKey
              auditedEntityParentEntityType
              auditedEntityParentDisplayName
              changeLog
              customMessage
            }
          }
        }
      }
      quoteLineTasks(entityConnection: "QuoteLineTask") {
        items {
          auditLogs(entityConnection: "AuditLogEntry", limit: 100) {
            items {
              auditedEntityType
              auditedEntityDisplayName
              executionType
              executedBy
              executedDateTime
              auditedEntityId
              auditedEntitySortKey
              auditedEntityParentId
              auditedEntityParentSortKey
              auditedEntityParentEntityType
              auditedEntityParentDisplayName
              changeLog
              customMessage
            }
          }
          quoteLineProducts(entityConnection: "QuoteLineProduct") {
            items {
              auditLogs(entityConnection: "AuditLogEntry", limit: 100) {
                items {
                  auditedEntityType
                  auditedEntityDisplayName
                  executionType
                  executedBy
                  executedDateTime
                  auditedEntityId
                  auditedEntitySortKey
                  auditedEntityParentId
                  auditedEntityParentSortKey
                  auditedEntityParentEntityType
                  auditedEntityParentDisplayName
                  changeLog
                  customMessage
                }
              }
            }
          }
          quoteLineLabors(entityConnection: "QuoteLineProduct") {
            items {
              auditLogs(entityConnection: "AuditLogEntry", limit: 100) {
                items {
                  auditedEntityType
                  auditedEntityDisplayName
                  executionType
                  executedBy
                  executedDateTime
                  auditedEntityId
                  auditedEntitySortKey
                  auditedEntityParentId
                  auditedEntityParentSortKey
                  auditedEntityParentEntityType
                  auditedEntityParentDisplayName
                  changeLog
                  customMessage
                }
              }
            }
          }
        }
      }
      quoteLineProducts(entityConnection: "QuoteLineProduct") {
        items {
          auditLogs(entityConnection: "AuditLogEntry", limit: 100) {
            items {
              auditedEntityType
              auditedEntityDisplayName
              executionType
              executedBy
              executedDateTime
              auditedEntityId
              auditedEntitySortKey
              auditedEntityParentId
              auditedEntityParentSortKey
              auditedEntityParentEntityType
              auditedEntityParentDisplayName
              changeLog
              customMessage
            }
          }
        }
      }
      attachments(entityConnection: "Attachment") {
        items {
          auditLogs(entityConnection: "AuditLogEntry", limit: 100) {
            items {
              auditedEntityType
              auditedEntityDisplayName
              executionType
              executedBy
              executedDateTime
              auditedEntityId
              auditedEntitySortKey
              auditedEntityParentId
              auditedEntityParentSortKey
              auditedEntityParentEntityType
              auditedEntityParentDisplayName
              changeLog
              customMessage
            }
          }
        }
      }
    }
  }
`;

export default getAuditLogsByVersionedQuoteId;
