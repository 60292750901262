export const CheckboxProperties = {
  toolLabel: 'Checkbox',
  toolIcon: 'CheckBox',
  label: 'Checkbox Item',
  checked: false,
  value: '',
  isRequired: false,
  errorMsg: 'Field is required.',
  searchableField: true,
  searchableCategory: 'checkBox',
  fullWidth: true,
  alignment: 'left',
  editFormLayout: 'CheckboxPropertiesLayout',
  controlType: 'CheckboxInput',
  viewControlType: 'ReadOnlyCheckboxInput'
};

export const CheckboxPropertiesLayout = [
  {
    fields: {
      label: {
        default: 'label',
        edit: 'label'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'label',
                    options: {
                      label: 'Label'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'searchableField',
                    options: {
                      label: 'Searchable Item',
                      checked: false,
                      value: 'searchableField'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'isRequired',
                    options: {
                      label: 'Required Item',
                      checked: false,
                      value: 'isRequired'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'errorMsg',
                    options: {
                      label: 'Error Message'
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  {
    label: '',
    searchableField: '',
    isRequired: '',
    errorMsg: ''
  }
];

export const CheckboxListProperties = {
  toolLabel: 'Checkbox List',
  toolIcon: 'CheckBox',
  label: 'Checkbox List',
  btnDirection: 'Horizontal',
  inputOptions: 'Choice #1, Choice #2',
  isRequired: false,
  errorMsg: 'Field is required.',
  editFormLayout: 'CheckboxListPropertiesLayout',
  controlType: 'CheckboxList',
  viewControlType: 'ReadOnlyCheckboxList'
};

export const CheckboxListPropertiesLayout = [
  {
    fields: {
      label: {
        default: 'label',
        edit: 'label'
      },
      inputOptions: {
        default: 'inputOptions',
        edit: 'inputOptions'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'label',
                    options: {
                      label: 'Label'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'inputOptions',
                    options: {
                      label: 'Options',
                      lines: 4
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'RadioButtonsGroup',
                    source: 'btnDirection',
                    options: {
                      label: 'Button Direction',
                      inputOptions: 'Horizontal, Vertical'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'isRequired',
                    options: {
                      label: 'Required Item',
                      checked: false,
                      value: 'isRequired'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'errorMsg',
                    options: {
                      label: 'Error Message'
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  {
    label: '',
    inputOptions: '',
    btnDirection: '',
    isRequired: '',
    errorMsg: ''
  }
];

export const RadioButtonProperties = {
  toolLabel: 'Radio Buttons',
  toolIcon: 'RadioButtonChecked',
  label: 'Radio Buttons',
  btnDirection: 'Horizontal',
  inputOptions: 'Choice #1, Choice #2',
  isRequired: false,
  errorMsg: 'Field is required.',
  fullWidth: true,
  alignment: 'left',
  editFormLayout: 'RadioButtonPropertiesLayout',
  controlType: 'RadioButtonsGroup',
  viewControlType: 'FieldWithLabel'
};

export const RadioButtonPropertiesLayout = [
  {
    fields: {
      label: {
        default: 'label',
        edit: 'label'
      },
      inputOptions: {
        default: 'inputOptions',
        edit: 'inputOptions'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'label',
                    options: {
                      label: 'Label'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'inputOptions',
                    options: {
                      label: 'Options',
                      lines: 4
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'RadioButtonsGroup',
                    source: 'btnDirection',
                    options: {
                      label: 'Button Direction',
                      inputOptions: 'Horizontal, Vertical'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'isRequired',
                    options: {
                      label: 'Required Item',
                      checked: false,
                      value: 'isRequired'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'errorMsg',
                    options: {
                      label: 'Error Message'
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  {
    label: '',
    inputOptions: '',
    btnDirection: '',
    isRequired: '',
    errorMsg: ''
  }
];

export const SignatureProperties = {
  toolLabel: 'Signature',
  toolIcon: 'HowToReg',
  label: 'Signature',
  maxWidth: 600,
  maxHeight: 200,
  value: '',
  isRequired: false,
  errorMsg: 'Field is required.',
  fullWidth: true,
  alignment: 'left',
  editFormLayout: 'SignaturePropertiesLayout',
  controlType: 'SignatureScreen',
  viewControlType: 'SignatureScreen'
};

export const SignaturePropertiesLayout = [
  {
    fields: {
      label: {
        default: 'label',
        edit: 'label'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'label',
                    options: {
                      label: 'Label'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'RadioButtonsGroup',
                    source: 'alignment',
                    options: {
                      btnDirection: 'Horizontal',
                      label: 'Alignment',
                      inputOptions: 'Left, Center, Right'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'isRequired',
                    options: {
                      label: 'Required Item',
                      checked: false,
                      value: 'isRequired'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'errorMsg',
                    options: {
                      label: 'Error Message'
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  {
    label: '',
    isRequired: '',
    errorMsg: ''
  }
];
