const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5),
    margin: '30px 0',
    width: '100%',
    color: theme.palette.grayscale(25),
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  headersText: {
    color: theme.palette.grayscale(60),
    fontSize: '12px',
    width: '100%',
    float: 'left',
    height: '23px'
  },
  dataText: {
    color: theme.palette.grayscale(25),
    fontSize: '14px',
    width: '100%',
    float: 'left'
  },
  techCell: {
    width: 'calc((100% - 40px) / 3)',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left'
  },
  left: {
    float: 'left'
  },
  icon: {
    width: '20px',
    marginRight: '10px',
    marginLeft: '10px'
  }
});

export default styles;
