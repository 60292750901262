import React from 'react';

import { MUIForm } from '@buildhero/sergeant';

import { timeTrackingSetting } from 'meta/Settings/Payroll/TimetrackingSettingMeta';
import { Mode } from 'utils/constants';

const GeneralSettings = ({
  isAllowedToEdit,
  payrollSettings,
  setSettingService,
  setFormChanged,
  handleFormComplete
}) => {
  const formEditLayout = payrollSettings?.id ? Mode.EDIT : Mode.ADD;

  return (
    <MUIForm
      configuration={timeTrackingSetting(isAllowedToEdit)}
      data={payrollSettings}
      layout={isAllowedToEdit ? formEditLayout : Mode.VIEW}
      onCreateService={formService => {
        setSettingService(formService);
      }}
      onDirtyChange={isDirty => setFormChanged(isDirty)}
      onComplete={async completedData => handleFormComplete(completedData)}
    />
  );
};

export default GeneralSettings;
