import React from 'react';

import { Button, Input, Modal, ThemeProvider } from '@buildhero/sergeant';

const ChangeQuoteStatusModal = ({
  handleStatusChangeSubmit,
  loading,
  openReasonsModal,
  poNumber,
  quoteStatus,
  setOpenReasonsModal,
  setPoNumber,
  setStatusReason,
  statusReason
}) => {
  return (
    <ThemeProvider>
      <Modal
        title={`${quoteStatus} Quote`}
        open={openReasonsModal}
        onClose={() => setOpenReasonsModal(false)}
        PaperProps={{ style: { minWidth: 454 } }}
        actions={
          <Button
            fullWidth
            loading={loading}
            style={{ marginTop: '1rem' }}
            onClick={handleStatusChangeSubmit}
          >
            Confirm
          </Button>
        }
      >
        {quoteStatus === 'Approve' && (
          <Input
            label="PO Number"
            value={poNumber}
            onChange={event => setPoNumber(event.target.value)}
            style={{ paddingBottom: 16 }}
          />
        )}
        <Input
          label={quoteStatus === 'Approve' ? 'Approval Comments' : 'Rejection Comments'}
          multiline
          rows="4"
          value={statusReason}
          onChange={event => setStatusReason(event.target.value)}
        />
      </Modal>
    </ThemeProvider>
  );
};

export default ChangeQuoteStatusModal;
