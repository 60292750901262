import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import styles from './styles';

const ChipArray = props => {
  const { classes, dataArr, handleClick, clickable, selectedValue, disabled = false } = props;
  return (
    <Grid container>
      <Grid item xs={12}>
        {dataArr &&
          dataArr.map(data => (
            <Chip
              key={`chip-${data}`}
              label={data}
              classes={{
                root: selectedValue && selectedValue === data ? classes.selectedChip : classes.chip,
                clickable: classes.hoverChip
              }}
              clickable={clickable || true}
              onClick={() => handleClick(`${data}`)}
              disabled={disabled}
            />
          ))}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ChipArray);
