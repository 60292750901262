const styles = theme => ({
  sectionHeader: {
    paddingTop: 70
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderTop: `1px solid ${theme.palette.grayscale(90)}`
  }
});

export default styles;
