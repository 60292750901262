import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import AppConstants from 'utils/AppConstants';
import styles from './styles';

/**
 * Renders Material UI datepicker with BuildHero styles
 */
const DatePickerField = ({ field, form, classes, user, specialbehaviour, theme, ...other }) => {
  const currentError = (form.errors && form.errors[field.name]) || ' ';

  let fieldValue = field.value;

  if (fieldValue) {
    fieldValue =
      specialbehaviour && specialbehaviour.saveUnixTimeStamp
        ? moment.unix(field.value).format(AppConstants.DATE_FORMAT)
        : moment(field.value).format(AppConstants.DATE_FORMAT);
  } else if (field.value === '') {
    fieldValue = null; //
    form.setFieldValue(field.name, fieldValue, true);
  }

  return (
    <KeyboardDatePicker
      allowKeyboardControl
      disableToolbar
      inputVariant="filled"
      variant="inline"
      invalidLabel=""
      fullWidth
      autoOk
      openTo="date"
      name={field.name}
      value={fieldValue}
      helperText={currentError}
      error={(currentError && currentError !== ' ') || false}
      onError={error => {
        // handle as a side effect
        if (error && error !== currentError) {
          form.setFieldError(field.name, error);
        }
      }}
      // if you are using custom validation schema you probably want to pass `true` as third argument
      onChange={date => {
        let formattedDate = null;
        if (date) {
          formattedDate =
            specialbehaviour && specialbehaviour.saveUnixTimeStamp
              ? date.unix()
              : date.format(AppConstants.DATE_FORMAT);
        }

        if (formattedDate !== fieldValue) {
          let isValidDate;
          if (specialbehaviour && specialbehaviour.saveUnixTimeStamp) {
            isValidDate = !isNaN(formattedDate);
          } else if (typeof formattedDate === 'string') {
            isValidDate =
              moment(formattedDate, AppConstants.DATE_FORMAT).format(AppConstants.DATE_FORMAT) ===
              formattedDate;
          }

          if (isValidDate) {
            form.setFieldError(field.name, '');
          }
          form.setFieldValue(field.name, formattedDate, false);
        }
      }}
      format={AppConstants.DATE_FORMAT}
      {...other}
    />
  );
};

class DatePickers extends Component {
  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePickerField {...this.props} />
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(DatePickers);
