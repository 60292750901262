import React from 'react';
import { convertStringToFloat } from 'utils';
import { Box, Typography, TextField, InputAdornment } from '@material-ui/core';
import { updateRateInfo, getValue } from './helpers';
import useStyles from './styles';

const Rates = ({ existingRates, isViewMode, hourTypes = [], title, onChange, dataKey }) => {
  const classes = useStyles();

  return (
    <Box display="flex" pl={4} pr={4} width={300} flexDirection="column">
      <Typography style={{ textAlign: 'center' }} className={classes.subtitle} variant="body2">
        {title}
      </Typography>
      <Box display="flex" flexDirection="column">
        {hourTypes.map(hourType => {
          const { id: hourTypeId, hourTypeAbbreviation } = hourType;
          const handleOnChange = e => {
            const value = { hourTypeId, [dataKey]: convertStringToFloat(e.target.value || null) };
            const updateRates = updateRateInfo(existingRates, value);
            onChange(updateRates);
          };

          const value = getValue(existingRates, hourTypeId, 'hourTypeId', dataKey);

          return (
            <Box key={hourTypeId} alignItems="center" display="flex">
              <Typography className={classes.hourAbbreviation} variant="body2">
                {hourTypeAbbreviation}
              </Typography>
              {isViewMode && (
                <Typography className={classes.noEditableValue} variant="body2">
                  {value || '-'}
                </Typography>
              )}
              {!isViewMode && (
                <TextField
                  value={value}
                  type="number"
                  onChange={handleOnChange}
                  placeholder="-"
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment className={classes.inputIcon} position="start">
                        <Typography variant="subtitle1">$</Typography>
                      </InputAdornment>
                    )
                  }}
                />
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Rates;
