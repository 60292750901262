import React from 'react';
import { ProjectService } from 'services/core';
import { logErrorWithCallback } from 'utils';

export const getJobCostTypes = async (tenantId, companyId, snackbarOn) => {
  let responseData = [];
  try {
    const Service = new ProjectService();
    const response = await Service.getJobCostTypes(tenantId, companyId);
    responseData = response?.data?.getCompany?.jobCostTypes?.items;
  } catch (error) {
    logErrorWithCallback(
      error,
      snackbarOn,
      'Unable to fetch job cost types, please try again later'
    );
  }
  return responseData;
};

const useCostType = (tenantId, tenantCompanyId, snackbarOn) => {
  const [jobCostTypes, setJobCostTypes] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const getData = async () => {
    setLoaded(false);
    const costCodeTypeDetails = await getJobCostTypes(tenantId, tenantCompanyId, snackbarOn);
    setJobCostTypes(costCodeTypeDetails);
    setLoaded(true);
  };
  React.useEffect(() => {
    if (tenantId && tenantCompanyId) {
      getData();
    }
    // Disable lint - getData function not needed
    // eslint-disable-next-line
  }, [tenantId, tenantCompanyId]);
  return [jobCostTypes, setJobCostTypes, getData, loaded];
};

export default useCostType;
