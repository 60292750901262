import gql from 'graphql-tag';

const addTrucksToCompany = gql`
  mutation addTrucksToCompany($partitionKey: String!, $data: AddTrucksToCompanyInput!) {
    addTrucksToCompany(partitionKey: $partitionKey, data: $data) {
      id
      version
      name
      productBundle(entityConnection: "ProductBundle") {
        id
        version
        name
        category
        subCategory
        type
        isActive
      }
      truckEmployees(entityConnection: "TruckEmployee") {
        items {
          id
          version
          employee(entityConnection: "Employee") {
            id
            firstName
            lastName
            name
          }
        }
      }
    }
  }
`;

export default addTrucksToCompany;
