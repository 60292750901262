import gql from 'graphql-tag';

const updateQuoteAndRelated = gql`
  mutation updateQuoteAndRelated($partitionKey: String!, $data: UpdateQuoteAndRelatedInput!) {
    updateQuoteAndRelated(partitionKey: $partitionKey, data: $data) {
      id
      sortKey
      hierarchy
      entityType
      version
      status
      isActive
    }
  }
`;

export default updateQuoteAndRelated;
