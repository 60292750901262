const transformUpdateQuoteSchema = (values, pageState) => {
  const { employees, taxRates, formAttributes, property } = pageState;
  let selectedOwner;
  let selectedSoldBy;

  const customFields = {};
  let haveCustomFields = false;
  if (values && formAttributes) {
    Object.keys(values).forEach(dataItem => {
      if (dataItem.startsWith('Custom_')) {
        customFields[dataItem.replace('Custom_', '')] = values[dataItem] || null;
      }
    });
    if (values.formDataId) {
      haveCustomFields = true;
    }

    customFields.formId = formAttributes.formId;
    customFields.formSortKey = formAttributes.formSortKey;
    customFields.formDefinitionId = formAttributes.formDefinitionId;
    customFields.formDefinitionSortKey = formAttributes.formDefinitionSortKey;
    customFields.id = values.formDataId;
    customFields.version = values.formDataVersion;
  }

  if (values.ownerId) {
    const selectedEmployee = employees.filter(emp => emp.id === values.ownerId);
    selectedOwner =
      selectedEmployee.length > 0 &&
      (selectedEmployee[0].name ||
        `${selectedEmployee[0].firstName} ${selectedEmployee[0].lastName}`);
  }

  if (values.salesById) {
    const selectedEmployee = employees.filter(emp => emp.id === values.salesById);
    selectedSoldBy =
      selectedEmployee.length > 0 &&
      (selectedEmployee[0].name ||
        `${selectedEmployee[0].firstName} ${selectedEmployee[0].lastName}`);
  }

  let { totalAmountQuotedOverride } = values;

  if (totalAmountQuotedOverride && totalAmountQuotedOverride === values.totalAmount) {
    totalAmountQuotedOverride = null;
  }

  const payload = {
    customerPropertyId: property.id,
    quotes: [
      {
        id: values.id,
        version: values.version,
        name: values.name || null,
        versionLabel: values.versionLabel || null,
        orderedById: values.orderedById || null,
        propertyRepId: values.propertyRepId || null,
        totalAmountQuoted: values.totalAmountQuoted || null,
        billTo: values.billTo || null,
        description: values.description || null,
        departmentId: values.departmentId || null,
        departmentValue: values.departmentValue || null,
        dueDate: values.dueDate || null,
        serviceAgreementId: values.serviceAgreementId || null,
        priceBookId: values.priceBookId || null,
        expirationDate: values.expirationDate || null,
        expirationLength: values.expirationLength || null,
        dateSent: values.dateSent || null,
        jobTypeId: values.jobTypeId || null,
        jobTypeValue: values.jobTypeValue || null,
        ownerId: values.ownerId || null,
        ownerValue: selectedOwner || null,
        salesById: values.salesById || null,
        salesByValue: selectedSoldBy || null,
        taxRateValue: values.taxRateValue || null,
        customerPoNumber: values.customerPoNumber || null,
        status: values.status,
        discount: values.discount || 0.0,
        isActive: true,
        totalAmountQuotedOverride: totalAmountQuotedOverride || null,
        pdfTemplateKey: values.pdfTemplateKey || undefined,
        rejectedReason: values.rejectedReason || null
      }
    ]
  };

  if (haveCustomFields) {
    payload.quotes[0].formData = customFields;
  }
  return payload;
};

export default transformUpdateQuoteSchema;
