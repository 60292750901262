import React from 'react';
import { sortBy } from 'lodash';
import { TimesheetsService } from 'services/core';
import { logErrorWithCallback } from 'utils';

export const getPayrollLabourTypes = async (tenantId, companyId, snackbarOn) => {
  let responseData = [];
  try {
    const Service = new TimesheetsService();
    const response = await Service.getPayrollLabourTypes(tenantId, companyId);
    const labourTypes = response?.data?.getCompany?.labourTypes?.items;
    responseData = sortBy(labourTypes, 'sortOrder');
  } catch (error) {
    logErrorWithCallback(
      error,
      snackbarOn,
      'Unable to fetch payroll types, please try again later'
    );
  }
  return responseData;
};

const useLabourTypes = (tenantId, tenantCompanyId, snackbarOn) => {
  const [payrollLabourTypes, setPayrollLabourTypes] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const getData = async () => {
    setLoaded(false);
    const hourTypes = await getPayrollLabourTypes(tenantId, tenantCompanyId, snackbarOn);
    setPayrollLabourTypes(hourTypes);
    setLoaded(true);
  };
  React.useEffect(() => {
    if (tenantId && tenantCompanyId) {
      getData();
    }
    // Disable lint - getData function not needed
    // eslint-disable-next-line
  }, [tenantId, tenantCompanyId]);
  return [payrollLabourTypes, getData, loaded];
};

export default useLabourTypes;
