import gql from 'graphql-tag';

const updateNote = gql`
  mutation updateNote($partitionKey: String, $data: UpdateNoteInput!) {
    updateNote(partitionKey: $partitionKey, data: $data) {
      createdBy
      subject
      note
      createdDate
    }
  }
`;

export default updateNote;
