export const MAINTENANCE_ACTION_BUTTONS = {
  JOB_COMPLETE: {
    label: 'Complete'
  },
  JOB_SKIPPED: {
    label: 'Skip'
  }
};

export const SUBMIT_BUTTON_LABELS = {
  view: 'Edit',
  edit: 'Submit'
};

export const REOPEN_ACTION_BUTTON = {
  JOB_REOPEN: {
    label: 'Reopen'
  }
};

export const WarningMessage = {
  bulkVisitOption:
    'Cannot use Bulk Visit Creation as some Maintenances selected are already Completed, Skipped, or Scheduled',
  disabledBulkVisit:
    'Cannot use Bulk Visit Creation as some Maintenances selected are already Completed or Skipped'
};

export const VisitMessage = {
  ManualVisitMessage: 'Manually assign time, date and techs for each visit.',
  BulkVisitMessage: 'Create multiple visits based on one set of inputs.'
};
