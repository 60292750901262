import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { Typography, Grid, Menu, MenuItem, Button, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { UserPermission } from 'components/AppPermissions';
import Spinner from 'components/Spinners/CircularIndeterminate';
import PageMap from 'meta/PageMap';
import IconMap from 'meta/IconMap';
import ErrorBoundaries from 'scenes/Error';
import styles from './styles';

// buttons and showbuttons are legacy - do not use - To be removed when all PageForm components are removed
// overrideHeaderButtons are to be used for buttons across pages. It will be an array of react components that can be placed within Grid (div)

// breadcrumbsArray = {[{ link: '/procurement/list', title: Labels.Procurement[user.locale] }]}
// Icon referes to an icon featured in the Globabl IconMap
// Iconcomponent must be a React component

const getBreadcrumbsFromParents = (startingPage, userLocale) => {
  const breadcrumbs = [];
  let { parent } = startingPage;
  const pages = new Set();
  if (!parent) return breadcrumbs;
  while (parent && !pages.has(parent)) {
    pages.add(parent);
    const { title, link } = PageMap[parent];
    breadcrumbs.unshift({ title: title[userLocale], link });
    parent = PageMap[parent].parent;
  }
  return breadcrumbs;
};

const PageHeader = props => {
  const {
    buttons,
    overrideHeaderButtons,
    mode,
    caslKey,
    title,
    breadcrumbsArray,
    classes,
    headerContainerStyle,
    icon,
    iconComponent,
    pageMapKey,
    userLocale,
    additionalTitleComponents = null,
    statusLabel = null,
    additionalStatusLabels = null,
    justifyChildren = 'flex-end',
    editTitleAction = null
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [createAnchorEl, setCreateAnchorEl] = React.useState(false);
  const buttonRef = React.useRef();
  const createRef = React.useRef();
  const validButtons = [];
  let pageTitle = title;
  let pageIcon = icon;
  let pageBreadcrumbs = breadcrumbsArray;

  // Use values from PageMap
  if (!!pageMapKey && !!userLocale) {
    pageTitle = PageMap[pageMapKey]?.title?.[userLocale] || title;
    pageIcon = PageMap[pageMapKey]?.icon || icon;
    pageBreadcrumbs =
      getBreadcrumbsFromParents(PageMap[pageMapKey], userLocale) || breadcrumbsArray;
  }
  // Override MapKeys if set in props
  if (title) pageTitle = title;
  if (icon) pageIcon = icon;
  if (breadcrumbsArray) pageBreadcrumbs = breadcrumbsArray;

  if (buttons) {
    Object.keys(buttons).forEach(btn => {
      if (buttons[btn] && buttons[btn].behavior && buttons[btn].behavior[mode]) {
        validButtons.push({ type: btn, ...buttons[btn] });
      }
    });
  }

  document.title = `BuildOps - ${pageTitle}`;

  const BreadcrumbComp = () => (
    <Typography variant="body2" className={classes.breadcrumbStyle}>
      {pageBreadcrumbs.map((el, index) => {
        if (index === 0) {
          if (el.link !== '') {
            return (
              <Link
                key={el.title}
                to={el.link}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {el.title}
              </Link>
            );
          }
          return el.title;
        }

        if (el.link !== '') {
          return (
            <Link
              key={el.title}
              to={el.link}
              style={{ textDecoration: 'none', color: 'inherit' }}
              className={classes.breadcrumbStyle}
            >
              <span className={classes.breadcrumbSlashStyle} key={`${el.title}slash`}>
                /
              </span>
              {el.title}
            </Link>
          );
        }
        return (
          <Fragment key={`${el.title}Frag`}>
            <span className={classes.breadcrumbSlashStyle} key={`${el.title}slash`}>
              /
            </span>
            {el.title}
          </Fragment>
        );
      })}
    </Typography>
  );

  const renderCreateMenu = btns => {
    const createMenus = btns.map(btn => (
      <UserPermission I={btn.caslAction} action={btn.caslKey} key={`permcreatemenu${btn.label}`}>
        <MenuItem
          onClick={() => {
            btn.action();
            setCreateAnchorEl(null);
          }}
          key={btn.label}
        >
          {btn.label}
        </MenuItem>
      </UserPermission>
    ));
    return (
      <Menu
        id="add--create-menu"
        anchorEl={() => createRef.current}
        open={Boolean(createAnchorEl)}
        keepMounted
        onClose={() => setCreateAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        {createMenus}
      </Menu>
    );
  };

  const renderMoreActionsButton = btns => (
    <>
      <UserPermission
        I={btns[0].caslAction || mode}
        action={btns[0].caslKey || caslKey}
        key={`perm${btns[0].label}`}
      >
        <Button
          variant={btns[0].buttonType}
          color={btns[0].color}
          style={btns[0].style}
          onClick={btns[0].action}
          className={classes.pageActionButton}
          disabled={props.buttonClicked}
          type={btns[0].type === 'save' ? 'submit' : 'button'}
        >
          {mode !== 'new' && btns[0].label}
          {mode === 'new' && (btns[0].newLabel || btns[0].label)}
          {props.buttonClicked && btns[0].type === 'save' && (
            <Spinner className={classes.buttonProgress} size={24} />
          )}
        </Button>
      </UserPermission>
      <Button
        variant="outlined"
        color="secondary"
        ref={buttonRef}
        onClick={() => setAnchorEl(true)}
        className={classes.pageActionButton}
        type="button"
      >
        More actions
      </Button>
      <Menu
        id="add-menu"
        anchorEl={() => buttonRef.current}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        style={{ width: 196 }}
      >
        {btns.slice(1, btns.length).map(button => (
          <UserPermission
            I={button.caslAction}
            action={button.caslKey}
            key={`permmenu${button.label}`}
          >
            <MenuItem
              onClick={() => {
                button.action();
                setAnchorEl(null);
              }}
              key={button.label}
            >
              {button.label}
            </MenuItem>
          </UserPermission>
        ))}
      </Menu>
    </>
  );

  return (
    <ErrorBoundaries>
      {pageBreadcrumbs && <BreadcrumbComp />}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.headerContainer}
        style={headerContainerStyle}
        wrap="nowrap"
      >
        <Grid item container className={classes.pageTitleContainer} xs>
          {pageIcon && <IconMap icon={pageIcon} className={classes.icons} />}
          {(pageTitle || iconComponent) && (
            <Typography variant="h5" className={classes.pageTitle}>
              {iconComponent || null} {typeof pageTitle === 'string' ? pageTitle : ''}
            </Typography>
          )}
          {editTitleAction ? (
            <IconButton edge="start" size="small" onClick={editTitleAction}>
              <EditIcon />
            </IconButton>
          ) : null}
          {statusLabel}
          {additionalStatusLabels}
          {additionalTitleComponents}
        </Grid>
        {props.children && (
          <Grid item container justify={justifyChildren} xs>
            {props.children}
          </Grid>
        )}
        {props.showButtons && !overrideHeaderButtons && (
          <Grid
            item
            container
            className={classes.headerButtons}
            wrap="nowrap"
            justify="flex-end"
            xs
          >
            {validButtons.length > 2 && renderMoreActionsButton(validButtons)}
            {validButtons.length < 3 &&
              validButtons.map(
                btn =>
                  btn.behavior &&
                  btn.behavior[mode] && (
                    <UserPermission
                      I={btn.caslAction || mode}
                      action={btn.caslKey || caslKey}
                      key={`perm${btn.type}`}
                    >
                      <Button
                        variant={btn.buttonType}
                        color={btn.color}
                        key={btn.type}
                        style={btn.style}
                        onClick={btn.action}
                        className={classes.pageActionButton}
                        disabled={props.buttonClicked}
                        type={btn.type === 'save' ? 'submit' : 'button'}
                      >
                        {mode !== 'new' && btn.label}
                        {mode === 'new' && (btn.newLabel || btn.label)}
                        {props.buttonClicked && btn.type === 'save' && (
                          <Spinner className={classes.buttonProgress} size={24} />
                        )}
                      </Button>
                    </UserPermission>
                  )
              )}
            {props.createActions && (
              <Button
                variant="contained"
                color="secondary"
                ref={createRef}
                onClick={() => setCreateAnchorEl(true)}
                className={classes.pageCreateButton}
                type="button"
              >
                <AddIcon color="secondary" size="small" style={{ color: '#fafafa' }} />
                Create
              </Button>
            )}
            {props.createActions && renderCreateMenu(props.createActions)}
          </Grid>
        )}
        {overrideHeaderButtons && (
          <Grid
            item
            container
            className={classes.headerButtons}
            justify="flex-end"
            wrap="nowrap"
            xs={5}
          >
            {overrideHeaderButtons}
          </Grid>
        )}
      </Grid>
    </ErrorBoundaries>
  );
};

PageHeader.propTypes = {
  createActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
      caslAction: PropTypes.string,
      caslKey: PropTypes.string
    })
  ),
  showButtons: PropTypes.array, // deprecated
  overrideHeaderButtons: PropTypes.func
};

PageHeader.defaultProps = {
  createActions: null,
  overrideHeaderButtons: null,
  showButtons: null
};

export default withStyles(styles)(PageHeader);
