import gql from 'graphql-tag';

const addCustomersToCompany = gql`
  mutation addCustomersToCompany($data: AddCustomersToCompanyInput!) {
    addCustomersToCompany(data: $data) {
      id
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      version
      status
      isTaxable
      isActive
      invoicePresetId
      priceBookId
      customerProperties(entityConnection: "CustomerProperty") {
        items {
          id
          version
          companyName
          accountNumber
          customerPropertyTypeValue
          billTo
          billingCustomerId
          isActive
          status
          isTaxable
          sameAddress
          accountingRefId
          taxRateId
          companyAddresses(entityConnection: "CompanyAddress") {
            items {
              addressLine1
              addressLine2
              city
              state
              zipcode
              latitude
              longitude
              id
              sortKey
              addressType
            }
          }
        }
      }
    }
  }
`;

export default addCustomersToCompany;
