import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeProvider, Typography } from '@buildhero/sergeant';
import { IconButton, Box } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ResponsiveTable } from 'components';
import { snackbarOn } from 'redux/actions/globalActions';
import StorageService from 'services/StorageService';
import { EnumType } from 'utils/constants';
import { availableJobReportPDFs } from './exportJobReportService';

const JobReportsPDFTableMeta = [
  {
    id: 'reportURL',
    numeric: false,
    type: 'download',
    isCustom: true,
    label: 'Download'
  },
  {
    id: 'status',
    numeric: false,
    type: 'enum',
    enumType: EnumType.JOB_REPORT_STATUS,
    label: 'Report Status',
    disableFilter: true
  },
  {
    id: 'visitNumbers',
    type: 'visits',
    isCustom: true,
    label: 'Visits',
    disableFilter: true
  },
  {
    id: 'createdDateTime',
    numeric: true,
    type: 'datetime',
    label: 'Created On',
    disableFilter: true
  },
  {
    id: 'createdBy',
    numeric: true,
    type: 'text',
    label: 'Created By',
    disableFilter: true
  }
];

const DownloadJobReportPDF = ({ storageService, meta, record }) => {
  if (!record[meta.id]) return '-';
  const download = async () => {
    const url = await storageService.getFileUrl(record[meta.id]);
    window.open(url);
  };
  return (
    <ThemeProvider>
      <Typography
        onClick={() => download()}
        css={{ textDecoration: 'underline', cursor: 'pointer' }}
      >
        Download
      </Typography>
    </ThemeProvider>
  );
};

const DisplayVisits = ({ meta, record }) => {
  const visits = record[meta.id]?.split(',')?.sort() || [];
  return visits.map(v => `#${v}`).join(', ');
};

export const JobReportsPDFTable = ({ jobId, newAsyncJobReport, refresh }) => {
  const [reports, setReports] = useState();
  const dispatch = useDispatch();
  const snackbar = useCallback((...args) => dispatch(snackbarOn(...args)), [dispatch]);

  const storageService = new StorageService();

  const fetchData = useCallback(async () => {
    const reportsData = await availableJobReportPDFs(jobId, snackbar);
    if (newAsyncJobReport?.status) reportsData.splice(0, 0, newAsyncJobReport);
    setReports(reportsData);
  }, [jobId, newAsyncJobReport, snackbar]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Box display="flex" justifyContent="end" marginTop={-4}>
        <IconButton aria-label="refresh" onClick={() => refresh()}>
          <RefreshIcon fontSize="small" />
        </IconButton>
      </Box>
      <ResponsiveTable
        rowMetadata={JobReportsPDFTableMeta}
        data={reports}
        isLoading={!reports}
        customCellComponents={{
          download: props => <DownloadJobReportPDF storageService={storageService} {...props} />,
          visits: DisplayVisits
        }}
        noDataMsg="No items"
        noEmptyRows
        disableFilter
      />
    </>
  );
};
