import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { ListService } from 'services/core';
import { Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ResponsiveTable, DefaultButton, Spinner } from 'components';
import { logErrorWithCallback, roundCurrency } from 'utils';
import { Mode, InvoiceStatus } from 'utils/constants';
import { snackbarOn } from 'redux/actions/globalActions';
import theme from 'themes/BuildHeroTheme';
import { addPaymentToInvoiceRowsMeta } from './layout';
import ApplyPaymentInput from './ApplyPaymentInput';
import FeatureFlags from 'utils/FeatureFlagConstants';

const RouterLink = React.forwardRef((props, ref) => <Link {...props} ref={ref} />);

const InvoiceApplicationTable = ({ form, field, user }) => {
  const {
    billingCustomerId,
    billingCustomerName,
    availableAmount,
    paymentAmount,
    invoiceId
  } = form?.values;
  const [showResults, setShowResults] = useState(false);
  const [isFetchingInvoices, setIsFetchingInvoices] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [amountMap, setAmountMap] = useState({});
  const [appliedPaymentsMap, setAppliedPaymentsMap] = useState({});
  const flags = useFlags();

  let initialSpentPayment = 0;
  if (paymentAmount && availableAmount) {
    initialSpentPayment = paymentAmount - availableAmount;
  } else if (availableAmount === 0) {
    initialSpentPayment = paymentAmount;
  }
  const [totalSpentPayment, setTotalSpentPayment] = useState(roundCurrency(initialSpentPayment));
  const localAmountMap = {};

  // Update the 'availableAmount' field when paymentAmount or totalSpentPayment
  // has been updated.
  useEffect(() => {
    let newAvailable;
    if (paymentAmount) newAvailable = roundCurrency(paymentAmount - totalSpentPayment);
    if (newAvailable !== availableAmount) {
      form.setFieldValue('availableAmount', newAvailable);
      if (invoiceId) form.setFieldValue('invoiceBalance', flags?.[FeatureFlags.ADJUSTMENTS] ? roundCurrency(invoices?.[0]?.adjustedBalance) : roundCurrency(invoices?.[0]?.balance));
    }
  }, [form, totalSpentPayment, availableAmount, paymentAmount]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomerInvoices = async (limit, offset, sortBy, sortOrder, status) => {
    const { id } = form?.values;
    const sortKey = `${user.tenantId}_Company_${user.tenantCompanyId}`;
    const listService = new ListService();
    const hardFilter = {
      stringFilters: [
        {
          fieldName: 'BillingCustomer.id',
          filterInput: { eq: billingCustomerId }
        },
        {
          fieldName: 'Invoice.status',
          filterInput: { ne: InvoiceStatus.VOID }
        }
      ]
    };
    if (invoiceId) {
      hardFilter.stringFilters.push({
        fieldName: 'Invoice.id',
        filterInput: { eq: invoiceId }
      });
    }

    setIsFetchingInvoices(true);
    try {
      const { items } = await listService.getAllInvoiceJobForPayment(
        user.tenantId,
        sortKey,
        hardFilter,
        limit,
        offset,
        sortBy,
        sortOrder,
        status,
        id,
        [],
        flags?.[FeatureFlags.ADJUSTMENTS],
      );
      setInvoices(items);
      setShowResults(true);
      if (invoiceId) form.setFieldValue('invoiceBalance', flags?.[FeatureFlags.ADJUSTMENTS] ? roundCurrency(items?.[0]?.adjustedBalance) : roundCurrency(items?.[0]?.balance));
    } catch (error) {
      logErrorWithCallback(error, snackbarOn, 'Unable to fetch invoices, please try again later');
      setInvoices([]);
    }
    setIsFetchingInvoices(false);
  };

  // if invoiceId is provided, load right away
  useEffect(() => {
    if (invoiceId) getCustomerInvoices();
  }, [invoiceId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showResults || !billingCustomerId) {
      setInvoices([]);
      setShowResults(false);
    }
    if (showResults) {
      getCustomerInvoices();
    }
  }, [billingCustomerId]); // eslint-disable-line react-hooks/exhaustive-deps

  const addAppliedPaymentsMap = useCallback(
    ({ invoiceId, amount, removedAmount }) => {
      const newAmountMap = { ...amountMap };
      newAmountMap[invoiceId] = amount;
      const newAppliedPaymentsMap = { ...appliedPaymentsMap };
      newAppliedPaymentsMap[invoiceId] = amount;
      let updateBalanceAmount;

      // If we are canceling an amount, add it to the total. Otherwise, remove it.
      if (availableAmount || availableAmount === 0) {
        let newTotalSpentPayment = totalSpentPayment;
        if (amount === Mode.DELETE) {
          newTotalSpentPayment -= removedAmount;
          updateBalanceAmount = removedAmount;
        } else {
          newTotalSpentPayment += amount;
          updateBalanceAmount = -1 * amount;
        }
        setTotalSpentPayment(roundCurrency(newTotalSpentPayment));
      }
      // Update the Balance cell
      const newInvoices = invoices.map(record => {
        const updatedRecord = { ...record };
        if (updatedRecord.id === invoiceId) {
          updatedRecord.balance = roundCurrency(updatedRecord.balance + updateBalanceAmount);
          updatedRecord.adjustedBalance = roundCurrency(
            updatedRecord.adjustedBalance + updateBalanceAmount
          );
        }
        return updatedRecord;
      });

      setInvoices(newInvoices);
      setAmountMap({ ...newAmountMap, ...localAmountMap });
      setAppliedPaymentsMap(newAppliedPaymentsMap);
      form.setFieldValue(field.name, newAppliedPaymentsMap);
    },
    [
      form,
      field,
      appliedPaymentsMap,
      localAmountMap,
      amountMap,
      availableAmount,
      totalSpentPayment,
      invoices
    ]
  );

  const customCellComponents = {
    applyPaymentToInvoice: ({ record }) => {
      const { id, amountToApply } = record;
      const appliedValue = appliedPaymentsMap[id] || amountToApply;
      const value = amountMap[id] || amountToApply;
      const isApplied = appliedValue !== null && appliedValue !== Mode.DELETE;

      return (
        <ApplyPaymentInput
          id={id}
          record={record}
          value={value}
          isApplied={isApplied}
          addAppliedPaymentsMap={addAppliedPaymentsMap}
          updateAmountMap={({ invoiceId, amount }) => {
            localAmountMap[invoiceId] = amount;
          }}
          availableAmount={availableAmount}
        />
      );
    }
  };

  return (
    <>
      {!invoiceId && (
        <DefaultButton
          testingid="button-viewInvoices"
          disabled={isFetchingInvoices || !billingCustomerId || showResults}
          label="View Invoices"
          handle={() => getCustomerInvoices()}
          style={{ width: '25%' }}
          showSpinner={isFetchingInvoices}
        />
      )}
      {showResults ? (
        <>
          <Typography variant="h6" style={{ marginTop: theme.spacing(3) }}>
            Available Invoices
          </Typography>
          <div style={{ display: 'flex', marginBottom: theme.spacing(2) }}>
            <Typography variant="body2" style={{ marginRight: 4 }}>
              for
            </Typography>
            <Button
              disableRipple
              component={RouterLink}
              to={{ pathname: `/customer/view/${billingCustomerId}` }}
            >
              {billingCustomerName}
            </Button>
          </div>
          <ResponsiveTable
            rowMetadata={addPaymentToInvoiceRowsMeta(flags)}
            data={invoices || []}
            customCellComponents={customCellComponents}
            showToolbars
            disableFilter
          />
        </>
      ) : (
        invoiceId && <Spinner />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const connectedInvoiceApplicationTable = connect(
  mapStateToProps,
  mapDispatcherToProps
)(InvoiceApplicationTable);

export default connectedInvoiceApplicationTable;
