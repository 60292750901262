import gql from 'graphql-tag';

const getAssetsByCustomerPropertyById = gql`
  query getAssetsByCustomerPropertyById($id: String!) {
    getCustomerPropertyById(id: $id) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      parentId
      parentSortKey
      propertyAssets(entityConnection: "PropertyAsset") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          assetName
          isActive
          imageUrl
          make
          version
          modelNumber
          serialNo
          installDate
          location
          propertyZone
          lastUpdatedDateTime
          assetTypeId
          assetType(entityConnection: "AssetType") {
            id
            tagName
          }
          # can remove visitAssets - leaving for backwards compatibility and mobile
          visitAssets: visitAssetView(entityConnection: "VisitAsset") {
            items {
              id
              visit: parentEntity {
                ... on Visit {
                  id
                  visitNumber
                  scheduledFor
                  description
                  job: parentEntity {
                    ... on Job {
                      id
                      jobNumber
                      customIdentifier
                      issueDescription
                    }
                  }
                  primaryTechs(entityConnection: "VisitPrimaryTech") {
                    items {
                      id
                      mappedEntity {
                        ... on Employee {
                          id
                          firstName
                          lastName
                        }
                      }
                    }
                  }
                  extraTechs(entityConnection: "VisitExtraTech") {
                    items {
                      id
                      mappedEntity {
                        ... on Employee {
                          id
                          firstName
                          lastName
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          # can remove notes - leaving for backwards compatibility and mobile
          notes(entityConnection: "Note") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              subject
              note
              createdBy
              lastUpdatedDate
              lastUpdatedDateTime
              lastUpdatedBy
            }
          }
        }
      }
    }
  }
`;
export default getAssetsByCustomerPropertyById;
