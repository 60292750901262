export const closedPeriodForm = {
  fields: {
    closedPeriodCopy: {
      default: 'closedPeriodCopy',
      edit: 'closedPeriodCopy'
    },
    closedPeriodDate: {
      default: 'closedPeriodDate',
      edit: 'closedPeriodDate'
    },
    numberOfClosingRecords: {
      default: 'numberOfClosingRecords',
      edit: 'numberOfClosingRecords'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 1,
            padding: [6, 0]
          },
          contents: [
            {
              component: 'BodyText',
              source: 'closedPeriodCopy'
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'column',
            grow: 1,
            padding: [6, 0]
          },
          contents: [
            {
              component: {
                default: 'FieldWithLabel',
                edit: 'DateInput'
              },
              source: 'closedPeriodDate',
              options: {
                label: 'Closed Period Date',
                searchableCategory: 'date'
              }
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 1,
            padding: [6, 0]
          },
          contents: [
            {
              component: 'AffectedRecords',
              source: 'numberOfClosingRecords'
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      closedPeriodDate: {
        type: 'number'
      }
    },
    required: []
  },
  validationErrors: {}
};

export default closedPeriodForm;
