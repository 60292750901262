import React, { useEffect, useMemo, useState } from 'react';

import {
  CurrencyInput,
  NumberInput,
  Select,
  ThemeProvider,
  TV,
  TW,
  Typography
} from '@buildhero/sergeant';
import { jsx } from '@emotion/react';
import { Box, useTheme } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { debounce, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import WrapTable, { tableEmptyValueFormatter } from 'components/WrapTable';
import useLabourRates from 'customHooks/useLabourRates';
import useLabourTypes from 'customHooks/useLabourTypes';
import usePayrollBillingHourTypes from 'customHooks/usePayrollBillingHourTypes';
import usePayrollHourTypes from 'customHooks/usePayrollHourTypes';
import { snackbarOn } from 'redux/actions/globalActions';
import { dispatch } from 'redux/store';
import { usePayrollSettings } from 'scenes/Jobs/JobDetail/queries/usePayrollSettings';
import Routes from 'scenes/Routes';
import { convertToCurrencyString } from 'utils';
import { LineItemBillingStatus } from 'utils/AppConstants';
import { InvoiceItemType, InvoiceStatus, LaborLineSourceType } from 'utils/constants';

import { InvoiceItemsEditedField, LineItemWithAsterisk, SectionHeader } from '../../Components';
import {
  useAddLabourRateLineItemsToVisit,
  useUpdateLabourRateBillingHourLine,
  useUpdateLabourRateLineItem
} from '../../mutations';
import {
  calculateLaborRate,
  getBillableEventDisplayText,
  getVisitDisplayText,
  tableCurrencyFormatter,
  timeSubmittedDisplay
} from '../../utils';

const getTimesheetDuration = timesheet => {
  return Number.isInteger(timesheet.actualTotalDurationOverride)
    ? timesheet.actualTotalDurationOverride
    : timesheet.actualTotalDuration;
};

const WarningComponent = () => {
  const theme = useTheme();
  return (
    <WarningIcon
      fontSize="small"
      css={{ marginRight: 8, color: theme.palette.support.yellow.dark }}
    />
  );
};

const getColumns = (updateTable, payrollBillingHourTypes, labourTypes) => {
  const payrollOptions = payrollBillingHourTypes.map(p => ({ label: p.hourType, value: p.id }));
  const labourOptions = labourTypes.map(l => ({ label: l.name, value: l.id }));

  return [
    {
      field: 'source',
      headerName: 'Source',
      width: 200,
      align: 'center',
      flex: 1,
      renderCell: ({ row }) => {
        const showWarning = row.labourRate === null;
        if (row.invoiceNumber) {
          return (
            <>
              {showWarning && <WarningComponent />}
              <Typography variant={TV.BASE} weight={TW.REGULAR}>
                <Link to={Routes.invoice({ mode: 'view', id: row.invoiceId })}>{row.source}</Link>
              </Typography>
            </>
          );
        }

        if (row.sourceAsteriskNeeded) {
          return (
            <>
              {showWarning && <WarningComponent />}
              <LineItemWithAsterisk content={row.source} />
            </>
          );
        }

        return (
          <>
            {showWarning && <WarningComponent />}
            <Typography variant={TV.BASE} weight={TW.REGULAR}>
              {row.source}
            </Typography>
          </>
        );
      }
    },
    { field: 'technician', headerName: 'Technician', align: 'center', flex: 1 },
    { field: 'timeSubmitted', headerName: 'Time Submitted', width: 125, align: 'center', flex: 1 },
    {
      field: 'payrollHourType',
      headerName: 'Payroll Hour Type',
      width: 140,
      align: 'center',
      flex: 1
    },
    {
      field: 'billingHourType',
      headerName: 'Billing Hour Type',
      width: 200,
      overflow: 'visible',
      align: 'center',
      renderCell: ({ row }) => {
        if (row.doNotInvoice || !row.billingHourType) return <></>;
        return (
          <ThemeProvider>
            <Select
              value={row.billingHourType}
              onChange={value => {
                if (value === row.billingHourType) return;

                updateTable({ data: { ...row, billingHourType: value }, resetRate: true });
              }}
              options={payrollOptions}
              disabled={row.readOnly}
            />
          </ThemeProvider>
        );
      },
      cellCss: ({ row }) => {
        if (row?.doNotInvoice) return { backgroundColor: '#E6E6E6' };

        return {};
      }
    },
    {
      field: 'labourType',
      headerName: 'Labor Type',
      width: 200,
      overflow: 'visible',
      align: 'center',
      renderCell: ({ row }) => {
        if (row.doNotInvoice || !row.labourType) return <></>;
        return (
          <ThemeProvider>
            <Select
              value={row.labourType}
              onChange={value => {
                if (value === row.labourType) return;

                updateTable({ data: { ...row, labourType: value }, labourTypeChanged: true });
              }}
              options={labourOptions}
              disabled={row.readOnly}
            />
          </ThemeProvider>
        );
      },
      cellCss: ({ row }) => {
        if (row?.doNotInvoice) return { backgroundColor: '#E6E6E6' };

        return {};
      }
    },
    {
      field: 'hours',
      headerName: 'Hours',
      width: 60,
      align: 'center',
      renderCell: ({ row }) => {
        if (row.doNotInvoice) return <></>;
        if (row.readOnly) {
          return (
            <div css={{ width: '100%', textAlign: 'right' }}>
              {tableEmptyValueFormatter({ value: row.hours })}
            </div>
          );
        }
        return (
          <ThemeProvider>
            <NumberInput
              value={row.hours}
              onBlur={value => {
                if (value === row.hours) return;

                updateTable({ data: { ...row, hours: value } });
              }}
              isEditable
            />
          </ThemeProvider>
        );
      },
      cellCss: ({ row }) => {
        if (row?.doNotInvoice) return { backgroundColor: '#E6E6E6' };

        return {};
      }
    },
    {
      field: 'labourRate',
      headerName: 'Labor Rate',
      width: 100,
      align: 'center',
      renderCell: ({ row }) => {
        if (row.doNotInvoice) return <></>;
        if (row.readOnly || row.bulkUpdateLabourRateBillingHourLinesLoading) {
          return (
            <div css={{ width: '100%', textAlign: 'right' }}>
              <div css={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}>
                {tableCurrencyFormatter({ value: row.labourRate })}
              </div>
            </div>
          );
        }
        return (
          <ThemeProvider>
            <CurrencyInput
              readOnly={row.readOnly}
              value={row.labourRate}
              onBlur={value => {
                if (value === row.labourRate) return;

                updateTable({ data: { ...row, labourRate: value } });
              }}
              isEditable
            />
          </ThemeProvider>
        );
      },
      cellCss: ({ row }) => {
        if (row?.doNotInvoice) return { backgroundColor: '#E6E6E6' };

        return {};
      }
    },
    {
      field: 'subtotal',
      headerName: 'Subtotal',
      width: 100,
      align: 'center',
      flex: 1,
      valueFormatter: tableCurrencyFormatter,
      renderCell: ({ row, formattedValue }) => {
        if (row.doNotInvoice) return <></>;
        return (
          <Typography variant={TV.BASE} numeric css={{ width: '100%', textAlign: 'right' }}>
            {formattedValue}
          </Typography>
        );
      },
      totalGetter: ({ rows }) =>
        rows.filter(r => !r.doNotInvoice).reduce((acc, r) => acc + r.subtotal, 0),
      renderTotal: ({ formattedValue }) => (
        <Typography
          css={{ width: '100%', textAlign: 'right' }}
          variant={TV.BASE}
          weight={TW.BOLD}
          numeric
        >
          {convertToCurrencyString(formattedValue ?? 0)}
        </Typography>
      ),
      cellCss: ({ row }) => {
        if (row?.doNotInvoice) return { backgroundColor: '#E6E6E6' };

        return {};
      }
    }
  ];
};

const getLaborDataForTimesheet = ({
  timesheet,
  employee,
  visit,
  labourRateLineItems,
  labourTypes,
  payrollBillingHourTypes,
  invoices
}) => {
  const duration = getTimesheetDuration(timesheet);
  const durationHours = duration / 3600;
  const durationTag = durationHours > 0 ? `${durationHours} hr` : 'N/A';

  const labourRateLineItem = labourRateLineItems.find(lineItem =>
    lineItem.labourRateBillingHourLines?.items
      ?.flat()
      .find(hourLine => hourLine.timesheetEntryId === timesheet.id)
  );

  const labourRateBillingHourLine = labourRateLineItems
    .map(lineItem => lineItem.labourRateBillingHourLines?.items)
    ?.flat()
    ?.find(hourLine => hourLine.timesheetEntryId === timesheet.id);

  const labourType = labourTypes.find(({ id }) => id === labourRateLineItem?.labourTypeId);
  const payrollBillingHourType = payrollBillingHourTypes.find(
    ({ id }) => id === labourRateBillingHourLine?.hourTypeId
  );

  const invoiceItem = invoices
    .flatMap(invoice => invoice.invoiceItems?.items || [])
    .find(({ id }) => id === labourRateBillingHourLine?.invoiceItemId);

  const sourceAsteriskNeeded = invoiceItem
    ? invoiceItem.quantity !== labourRateBillingHourLine?.totalHours ||
      invoiceItem.unitPrice !== labourRateBillingHourLine?.rate
    : false;

  return {
    sourceAsteriskNeeded,
    technician: `${employee?.name} (${employee?.labourType?.name})`,
    timeSubmitted: timeSubmittedDisplay(timesheet, durationTag, duration, visit.scheduledFor),
    payrollHourType: timesheet.hourType?.hourType || '-',
    payrollHourTypeSortOrder: timesheet.hourType?.sortOrder,
    billingHourType: {
      label: payrollBillingHourType?.hourType || '',
      value: payrollBillingHourType?.id || ''
    },
    labourType: { label: labourType?.name || '', value: labourType?.id || '' },
    isInvoiced: labourRateBillingHourLine?.billingStatus === LineItemBillingStatus.BILLED,
    readOnly: labourRateBillingHourLine?.billingStatus === LineItemBillingStatus.BILLED,
    doNotInvoice: labourRateBillingHourLine?.billingStatus === LineItemBillingStatus.DO_NOT_INVOICE,
    hours: labourRateBillingHourLine?.totalHours,
    labourRate: labourRateBillingHourLine?.rate,
    subtotal: labourRateBillingHourLine?.rate * labourRateBillingHourLine?.totalHours,
    visitNumber: visit.visitNumber,
    labourRateBillingHourLineId: labourRateBillingHourLine?.id,
    labourRateBillingHourLineVersion: labourRateBillingHourLine?.version,
    labourRateLineItemId: labourRateLineItem?.id,
    labourRateLineItemVersion: labourRateLineItem?.version
  };
};

const mapVisitsToRows = ({
  visits,
  invoices,
  hideInvoiced,
  bulkUpdateLabourRateBillingHourLinesLoading,
  labourTypes,
  payrollBillingHourTypes,
  companyTimezone
}) => {
  const visitRows = [];
  const billableEventRows = [];
  visits.forEach(visit => {
    const timesheetEntries = visit.timesheetEntriesView?.items || [];
    const labourRateLineItems = visit.labourRateLineItems?.items || [];
    const allTechs = [...visit.primaryTechs?.items, ...visit.extraTechs?.items].map(
      tech => tech.mappedEntity
    );
    timesheetEntries
      .filter(t => t.actualTotalDuration || t.actualTotalDurationOverride)
      .forEach(timesheet => {
        const visitTag = getVisitDisplayText(visit, companyTimezone);
        const tech = allTechs.find(at => at.id === timesheet.timesheetPeriod.parentId);

        visitRows.push({
          source: visitTag,
          bulkUpdateLabourRateBillingHourLinesLoading,
          ...getLaborDataForTimesheet({
            timesheet,
            employee: tech,
            visit,
            labourRateLineItems,
            labourTypes,
            payrollBillingHourTypes,
            invoices
          })
        });
      });

    visit.nonVisitEvents?.items?.forEach(event => {
      const eventTimesheetEntries = event.timesheetEntries?.items || [];
      eventTimesheetEntries
        .filter(t => t.actualTotalDuration || t.actualTotalDurationOverride)
        .forEach(timesheet => {
          const billableEventTag = getBillableEventDisplayText(event, companyTimezone);

          billableEventRows.push({
            source: billableEventTag,
            bulkUpdateLabourRateBillingHourLinesLoading,
            ...getLaborDataForTimesheet({
              timesheet,
              employee: event.employee,
              visit,
              labourRateLineItems,
              labourTypes,
              payrollBillingHourTypes,
              invoices
            })
          });
        });
    });
  });

  const invoiceRows = invoices
    .filter(({ status }) => status !== InvoiceStatus.VOID)
    .map(invoice => {
      const invoiceItems = invoice?.invoiceItems?.items || [];
      return invoiceItems
        .filter(
          ({ lineItemType, source }) =>
            lineItemType === InvoiceItemType.LABOR_LINE_ITEM &&
            source === LaborLineSourceType.INVOICE
        )
        .map(item => ({
          source: `Invoice ${invoice.invoiceNumber}`,
          isInvoiced: true,
          hours: item.quantity,
          labourRate: item.unitPrice,
          subtotal: (item.quantity ?? 0) * (item.unitPrice ?? 0),
          invoiceNumber: invoice.invoiceNumber,
          invoiceId: invoice.id,
          readOnly: true
        }));
    })
    .flat();
  return [
    ...orderBy(visitRows, ['visitNumber', 'technician', 'payrollHourTypeSortOrder']),
    ...orderBy(billableEventRows, ['visitNumber', 'technician', 'payrollHourTypeSortOrder']),
    ...orderBy(invoiceRows, ['invoiceNumber'])
  ].filter(({ isInvoiced }) => !hideInvoiced || !isInvoiced);
};

const TimeAndMaterialLaborCosts = ({
  invoices,
  visits,
  priceBookId,
  hideInvoiced,
  bulkUpdateLabourRateBillingHourLines,
  bulkUpdateLabourRateBillingHourLinesLoading,
  companyTimezone,
  isLoading
}) => {
  const user = useSelector(state => state?.user);
  const { tenantId, tenantCompanyId } = user;
  const serviceArgs = [tenantId, tenantCompanyId, snackbarOn];
  const { data: settings } = usePayrollSettings(user);
  const [labourTypes, , labourTypesLoaded] = useLabourTypes(...serviceArgs);
  const [labourRates, , labourRatesLoaded] = useLabourRates(...serviceArgs);
  const [payrollHourTypes, , payrollHourTypesLoading] = usePayrollHourTypes(...serviceArgs);
  const [payrollBillingHourTypes, , payrollBillingHourTypesLoaded] = usePayrollBillingHourTypes(
    ...serviceArgs
  );

  const [
    addLabourRateLineItemsToVisit,
    { loading: addLabourRateLineItemsToVisitLoading }
  ] = useAddLabourRateLineItemsToVisit();
  const [updateLabourRateBillingHourLine] = useUpdateLabourRateBillingHourLine();
  const [updateLabourRateLineItem] = useUpdateLabourRateLineItem();

  const [priceBookIdDiff, setPriceBookIdDiff] = useState(null);

  const dataLoaded =
    labourTypesLoaded &&
    labourRatesLoaded &&
    payrollBillingHourTypesLoaded &&
    !!priceBookId &&
    !payrollHourTypesLoading &&
    !isLoading;

  const updateTable = debounce(async ({ data, labourTypeChanged = false, resetRate = false }) => {
    if (labourTypeChanged) {
      const updatedLineItem = await updateLabourRateLineItem({
        tenantId,
        lineItemId: data.labourRateLineItemId,
        version: data.labourRateLineItemVersion,
        labourTypeId: data.labourType.value
      });

      const labourTypeId = updatedLineItem?.data?.updateLabourRateLineItem?.labourTypeId;
      const hourLines =
        updatedLineItem?.data?.updateLabourRateLineItem?.labourRateBillingHourLines?.items || [];

      await Promise.all(
        hourLines.map(hourLine =>
          updateLabourRateBillingHourLine({
            tenantId,
            hourLineId: hourLine.id,
            version: hourLine.version,
            rate: calculateLaborRate({
              labourRates,
              labourTypeId,
              payrollBillingHourTypeId: hourLine.hourTypeId,
              priceBookId
            }),
            totalHours: hourLine.totalHours,
            hourTypeId: hourLine.hourTypeId,
            productId: labourTypes
              .find(({ id }) => id === labourTypeId)
              ?.labourTypeBillingHourTypeProducts?.items?.find(
                ({ billingHourTypeId }) => billingHourTypeId === hourLine.hourTypeId
              )?.productId
          })
        )
      );
    } else {
      await updateLabourRateBillingHourLine({
        tenantId,
        hourLineId: data.labourRateBillingHourLineId,
        version: data.labourRateBillingHourLineVersion,
        rate: resetRate
          ? calculateLaborRate({
              labourRates,
              labourTypeId: data.labourType.value,
              payrollBillingHourTypeId: data.billingHourType.value,
              priceBookId
            })
          : data.labourRate,
        totalHours: data.hours,
        hourTypeId: data.billingHourType.value,
        productId: labourTypes
          .find(({ id }) => id === data.labourType.value)
          ?.labourTypeBillingHourTypeProducts?.items?.find(
            ({ billingHourTypeId }) => billingHourTypeId === data.billingHourType.value
          )?.productId
      });
    }
  }, 1000);

  const rows = useMemo(() => {
    return dataLoaded
      ? mapVisitsToRows({
          visits,
          invoices,
          hideInvoiced,
          bulkUpdateLabourRateBillingHourLinesLoading,
          labourTypes,
          payrollBillingHourTypes,
          companyTimezone
        })
      : [];
  }, [
    dataLoaded,
    visits,
    invoices,
    hideInvoiced,
    bulkUpdateLabourRateBillingHourLinesLoading,
    labourTypes,
    payrollBillingHourTypes,
    companyTimezone
  ]);
  const columns = useMemo(() => getColumns(updateTable, payrollBillingHourTypes, labourTypes), [
    labourTypes,
    payrollBillingHourTypes,
    updateTable
  ]);

  const doTechTimesheetsHaveBillingHourLines = (timesheets, labourRateLineItems) => {
    const labourRateBillingHourLines =
      labourRateLineItems?.map(lineItem => lineItem.labourRateBillingHourLines?.items)?.flat() ||
      [];

    return timesheets.every(({ id }) =>
      labourRateBillingHourLines.find(hourLine => hourLine.timesheetEntryId === id)
    );
  };

  const labourRateLineItemData = (timesheetEntries, employee) => ({
    employeeId: employee.id,
    costCodeId: employee.labourType?.costCodeId,
    jobCostTypeId: employee.labourType?.jobCostTypeId,
    revenueTypeId: employee.labourType?.revenueTypeId,
    labourTypeId: employee.labourType?.id,
    labourRateBillingHourLines: timesheetEntries.map(timesheet => {
      const billingHourTypeId = settings?.mapPayrollHourToBilling
        ? payrollHourTypes.find(({ id }) => id === timesheet.hourTypeId)?.billingHourTypeId
        : payrollBillingHourTypes[0].id;
      const productId = employee.labourType?.labourTypeBillingHourTypeProducts?.items?.find(
        product => product.billingHourTypeId === billingHourTypeId
      )?.productId;
      return {
        timesheetEntryId: timesheet.id,
        totalHours: getTimesheetDuration(timesheet) / 3600,
        hourTypeId: billingHourTypeId,
        productId,
        rate: calculateLaborRate({
          labourRates,
          labourTypeId: employee.labourType?.id,
          payrollBillingHourTypeId: billingHourTypeId,
          priceBookId
        }),
        source: LaborLineSourceType.VISIT
      };
    })
  });

  // use effect hook that modifies labour rates on pricebookId change
  useEffect(() => {
    if (!priceBookId || !dataLoaded || addLabourRateLineItemsToVisitLoading) return;

    // used to check if the priceBookID is changing due to a first render or user change
    if (priceBookIdDiff !== null && priceBookIdDiff !== priceBookId) {
      const hourLinesToBeUpdated = visits
        .flatMap(v => v?.labourRateLineItems?.items || [])
        .flatMap(({ labourRateBillingHourLines, labourTypeId }) => {
          return (labourRateBillingHourLines?.items || [])
            .filter(({ billingStatus }) => billingStatus !== LineItemBillingStatus.BILLED)
            .map(hourLine => {
              return {
                id: hourLine.id,
                version: hourLine.version,
                rate: calculateLaborRate({
                  labourRates,
                  labourTypeId,
                  payrollBillingHourTypeId: hourLine.hourTypeId,
                  priceBookId
                })
              };
            });
        });

      bulkUpdateLabourRateBillingHourLines({
        tenantId,
        labourRateBillingHourLines: hourLinesToBeUpdated
      });
    }

    setPriceBookIdDiff(priceBookId);
  }, [priceBookId]);

  // use effect hook that is used to populate the labour rates for timesheets without
  // corresponding labourRateLineItems/labourRateBillingHourLines
  useEffect(() => {
    if (!dataLoaded || addLabourRateLineItemsToVisitLoading) return;

    visits.forEach(visit => {
      const visitLabourRateLineItems = visit.labourRateLineItems?.items || [];
      const allTechs = [...visit.primaryTechs?.items, ...visit.extraTechs?.items].map(
        ({ mappedEntity }) => mappedEntity
      );
      const lineItemsToAddToVisit = [];
      allTechs.forEach(tech => {
        const techTimesheets =
          visit.timesheetEntriesView?.items?.filter(
            ({ timesheetPeriod }) => timesheetPeriod?.parentId === tech.id
          ) || [];
        if (!tech.labourType) {
          dispatch(
            snackbarOn(
              'error',
              `No labor type set for employee ${tech?.name}. Please set employee labor type in the company's personnel settings`
            )
          );
        } else if (
          techTimesheets.length > 0 &&
          !doTechTimesheetsHaveBillingHourLines(techTimesheets, visitLabourRateLineItems)
        ) {
          lineItemsToAddToVisit.push(labourRateLineItemData(techTimesheets, tech));
        }
      });

      const billableEvents = visit.nonVisitEvents?.items || [];
      billableEvents.forEach(event => {
        const eventTimesheets = event.timesheetEntries?.items || [];
        if (!event.employee.labourType) {
          dispatch(
            snackbarOn(
              'error',
              `No labor type set for employee ${event.employee?.name}. Please set employee labor type in the company's personnel settings`
            )
          );
        } else if (
          eventTimesheets.length > 0 &&
          !doTechTimesheetsHaveBillingHourLines(eventTimesheets, visitLabourRateLineItems)
        ) {
          lineItemsToAddToVisit.push(labourRateLineItemData(eventTimesheets, event.employee));
        }
      });

      if (lineItemsToAddToVisit.length > 0) {
        addLabourRateLineItemsToVisit({
          tenantId,
          visitId: visit.id,
          labourRateLineItems: lineItemsToAddToVisit
        });
      }
    });
  }, [dataLoaded, visits]);

  const rowsWithWarnings = rows?.filter(item => item.labourRate === null) || [];

  return (
    <ThemeProvider>
      <SectionHeader title="Labor" />
      <WrapTable
        columns={columns}
        rows={rows}
        noDataMessage="No Labor Data"
        hideFooter={rows.length <= 10}
        enableTotalsRow
        loading={isLoading}
        loadingRows={3}
      />
      {rowsWithWarnings.length > 0 && (
        <Box display="flex" flexDirection="row" alignItems="center" paddingY={0.5}>
          <WarningComponent />
          <Typography variant={TV.S1} weight={TW.REGULAR}>
            Labor line is missing Billing Hour Type or Labor Rate. This labor line will not
            propagate to an invoice and job report pdf until the issue is resolved.
          </Typography>
        </Box>
      )}

      {rows?.some(({ sourceAsteriskNeeded }) => sourceAsteriskNeeded === true) && (
        <InvoiceItemsEditedField />
      )}
    </ThemeProvider>
  );
};

export default TimeAndMaterialLaborCosts;
