import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { excelFileDownload } from 'utils/fileDownload';

const EXPORT_WIP_REPORT = gql`
  mutation exportWIPReport($input: any) {
    exportWIPReport(input: $input)
      @rest(type: "WIPReportExport", path: "wipreports/export", endpoint: "pmapi", method: "POST") {
      base64Data
    }
  }
`;

export const useExportWIPReport = (options = {}) => {
  return useExtendedMutation(EXPORT_WIP_REPORT, {
    serializer: wipReportId => ({ variables: { input: { wipReportId } } }),
    onCompleted: data => {
      excelFileDownload({
        fileName: 'WIPReport',
        base64Data: data?.exportWIPReport?.base64Data
      });
    },
    ...options
  });
};
