import { processQuoteResponse } from './utils/helper';

export const ACTION = {
  SET_QUOTE_INFO: 'SET_QUOTE_INFO',
  SET_QUOTE_VERSION: 'SET_QUOTE_VERSION',
  SET_PROPERTY_INFO: 'SET_PROPERTY_INFO',
  SET_QUOTE_ATTACHMENTS: 'SET_QUOTE_ATTACHMENTS',
  CREATE_NEW_VERSION: 'CREATE_NEW_VERSION',
  UPDATE_QUOTE_INFO: 'UPDATE_QUOTE_INFO',
  ADD_TO_JOB: 'ADD_TO_JOB',
  GENERATE_QUOTE: 'GENERATE_QUOTE',
  UPDATE_QUOTE_PURCHASE_ORDERS: 'UPDATE_QUOTE_PURCHASE_ORDERS'
};

export default function reducer(state, action) {
  switch (action.type) {
    case ACTION.SET_QUOTE_INFO: {
      const {
        quoteInfo,
        customer,
        property,
        quoteAttachments,
        quoteJobs,
        quotePurchaseOrders
      } = processQuoteResponse(action.payload);
      return {
        ...state,
        loading: true,
        quoteInfo,
        property,
        customer,
        quoteAttachments: quoteAttachments ?? state.quoteAttachments,
        quoteJobs,
        quotePurchaseOrders: state.quotePurchaseOrders || quotePurchaseOrders
      };
    }
    case ACTION.SET_QUOTE_ATTACHMENTS:
      return {
        ...state,
        quoteAttachments: action.payload
      };
    case ACTION.UPDATE_QUOTE_PURCHASE_ORDERS:
      return {
        ...state,
        quotePurchaseOrders: [...state.quotePurchaseOrders, action.payload]
      };
    default:
      break;
  }
}
