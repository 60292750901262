import { useTheme } from '@material-ui/core';

export const useStyles = () => {
  const theme = useTheme();
  const indent = theme.spacing(2);
  return {
    subSectionTitle: {
      fontWeight: 'bold',
      marginTop: indent,
      marginBottom: indent
    },
    technicianTimeTable: {
      minWidth: 175
    }
  };
};
