import moment from 'moment';
import AmplifyService from 'services/AmplifyService';
import getARBalanceData from '../../graphql/crm/customer/queries/getARBalanceData';
import getCustomerNumber from '../../graphql/crm/customer/queries/getCustomerNumber';
import getCustomer from '../../graphql/crm/customer/queries/getCustomer';
import getCustomerInfo from '../../graphql/crm/customer/queries/getCustomerInfo';
import getCustomerById from '../../graphql/crm/customer/queries/getCustomerById';
import getCustomerFieldDataById from '../../graphql/crm/customer/queries/getCustomerFieldDataById';
import getCustomerFieldData from '../../graphql/crm/customer/queries/getCustomerFieldData';
import getUpcomingVisitsByCustomer from '../../graphql/crm/customer/queries/getUpcomingVisitsByCustomer';
import getPastVisitsByCustomer from '../../graphql/crm/customer/queries/getPastVisitsByCustomer';
import getJobsByCustomer from '../../graphql/crm/customer/queries/getJobsByCustomer';
import getPropertiesByCustomer from '../../graphql/crm/customer/queries/getPropertiesByCustomer';
import getPropertiesByCustomerById from '../../graphql/crm/customer/queries/getPropertiesByCustomerById';
import getCustomerRepsByCustomer from '../../graphql/crm/customer/queries/getCustomerRepsByCustomer';
import getCustomerRepsByCustomerById from '../../graphql/crm/customer/queries/getCustomerRepsByCustomerById';
import getCustomerReps from '../../graphql/crm/customer/queries/getCustomerReps';
import getTenantRepsByCustomer from '../../graphql/crm/customer/queries/getTenantRepsByCustomer';
import getTenantRepsByCustomerById from '../../graphql/crm/customer/queries/getTenantRepsByCustomerById';
import getAttachmentsByCustomer from '../../graphql/crm/customer/queries/getAttachmentsByCustomer';
import getAttachmentsByCustomerId from '../../graphql/crm/customer/queries/getAttachmentsByCustomerId';
import getAllAuditLogByCustomerId from '../../graphql/crm/customer/queries/getAuditLogsByCustomerId';
import getCustomerList from '../../graphql/crm/customer/queries/getAllCustomers';
import getCustomerListView from '../../graphql/crm/customer/queries/getAllCustomersView';
import getPropertiesAndRepsByCustomer from '../../graphql/crm/customer/queries/getPropertiesAndRepsByCustomer';
import getNotesByCustomer from '../../graphql/crm/customer/queries/getNotesByCustomer';
import createCustomerSchema from '../../mutation-schema/customer-and-property/create-customer-schema';
import customerRepSchema from '../../mutation-schema/customer-and-property/customerRep-schema';
import updateCustomerSchema from '../../mutation-schema/customer-and-property/update-customer-schema';
import updateCustomer from '../../graphql/crm/customer/mutations/updateCustomer';
import updateCustomerTags from '../../graphql/crm/customer/mutations/updateCustomerTags';
import updateCustomerStatus from '../../graphql/crm/customer/mutations/updateCustomerStatus';
import updateCustomerProperty from '../../graphql/crm/customer-property/mutations/updateCustomerProperty';
import tenantRepSchema from '../../mutation-schema/customer-and-property/tenantRep-schema';
import mutationQuery from '../../graphql/common/mutations/batchCreateEntityData';
import CommonService from '../Common/CommonService';
import addCustomerToCompany from '../../graphql/crm/customer/mutations/addCustomersToCompany';
import addCustomerRepToCustomer from '../../graphql/crm/customer/mutations/addCustomerRepToCustomer';
import addCustomerRepToProperty from '../../graphql/crm/customer/mutations/addCustomerRepToProperty';
import updateCustomerRep from '../../graphql/crm/customer/mutations/updateCustomerRepToCustomer';
import updateCustomerAndRelated from '../../graphql/crm/customer/mutations/updateCustomerAndRelated';
import addAttachmentsToCustomer from '../../graphql/crm/customer/mutations/addAttachmentsToCustomer';
import getBillingAddressForCustomer from '../../graphql/crm/customer/queries/getBillingAddressForCustomer';
import getServiceAgreementsByCustomer from '../../graphql/crm/customer/queries/getServiceAgreementsByCustomer';
import { QuoteConstants } from '../../utils/AppConstants';
import { convertAddressObjToString, getMappedEntityJobTagname } from '../../utils';

// Expects customer argument fetched with the fragment in
// ../../../graphql/crm/customer/queries/getARBalanceData.js
function arBalance({
  invoiceTotal: {
    items: [{ total: credits }]
  },
  paymentTotal: {
    items: [{ total: debits }]
  }
}) {
  return parseFloat(credits || 0) - parseFloat(debits || 0);
}

function annotateARBalance(customer) {
  customer.arBalance = arBalance(customer);
}

function getPropertiesWithOpenQuotes(customerProperties) {
  const propertiesWithOpenQuotes = [];
  if (customerProperties?.items) {
    const properties = customerProperties.items || {};
    if (properties) {
      properties.forEach(property => {
        if (property?.quotes?.items) {
          const count = property.quotes.items.filter(
            quote =>
              quote.status &&
              quote.status !== QuoteConstants.CANCELLED &&
              quote.status !== QuoteConstants.JOBADDED
          )?.length;
          propertiesWithOpenQuotes.push({
            name: property.companyName,
            count
          });
        }
      });
    }
  }
  return propertiesWithOpenQuotes;
}

export default class CustomerService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
    this.CommonService = new CommonService();
  }

  // AR = accounts recievable
  // Balance is from AR's perspective, so our customer owing us money would be
  // our asset and therefore a positive balance.
  getARBalance = async (partitionKey, sortKey) => {
    const response = await this.api.query(getARBalanceData, { partitionKey, sortKey });
    return arBalance(response.data.getCustomer);
  };

  getDepartments = visits => {
    let departmentNames = new Set();
    let deparmentsNamesWithCommaSeperated = '';
    if (visits) {
      visits.forEach(visit => {
        if (visit.departmentName) {
          const deptName = visit.departmentName;
          departmentNames.add(deptName.charAt(0).toUpperCase() + deptName.slice(1));
        }
      });
    }

    if (departmentNames.size > 0) {
      departmentNames = Array.from(departmentNames);
      deparmentsNamesWithCommaSeperated = departmentNames.join(', ');
    }
    return deparmentsNamesWithCommaSeperated || '-';
  };

  getLatestSchedule = schedules => {
    let latestSchedule = null;
    if (schedules.length > 0) {
      latestSchedule = Math.min(...schedules);
    }
    return latestSchedule;
  };

  // Capitalize first letter method

  capitalizeFirstLetter = inputString => {
    let capitalizedString = '';
    if (inputString) capitalizedString = inputString.charAt(0).toUpperCase() + inputString.slice(1);
    return capitalizedString;
  };

  getCustomerNumber = async () => {
    const response = await this.api.client.query({
      query: getCustomerNumber,
      fetchPolicy: 'no-cache'
    });
    return response;
  };

  getCustomer = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getCustomer, params);
    return response;
  };

  getCustomerInfo = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getCustomerInfo, params);

    annotateARBalance(response.data.getCustomer);

    return response;
  };

  getCustomerFieldData = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getCustomerFieldData, params);
    return response;
  };

  getPropertiesByCustomer = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getPropertiesByCustomer, params);
    const propertiesWithOpenQuotes = getPropertiesWithOpenQuotes(
      response?.data?.getCustomer?.customerProperties
    );
    response.data.getCustomer.customerProperties.propertiesWithOpenQuotes = propertiesWithOpenQuotes
      .filter(pair => pair.count)
      .map(pair => `${pair.name} has ${pair.count} open quotes`);
    return response;
  };

  getPropertiesByCustomerById = async id => {
    const params = {
      id
    };

    const response = await this.api.query(getPropertiesByCustomerById, params);
    const propertiesWithOpenQuotes = getPropertiesWithOpenQuotes(
      response?.data?.getCustomerById?.customerProperties
    );
    response.data.getCustomerById.customerProperties.propertiesWithOpenQuotes = propertiesWithOpenQuotes
      .filter(pair => pair.count)
      .map(pair => `${pair.name} has ${pair.count} open quotes`);
    return response;
  };

  getCustomerRepsByCustomer = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getCustomerRepsByCustomer, params);
    return response;
  };

  getCustomerRepsByCustomerById = async id => {
    const params = {
      id
    };

    const response = await this.api.query(getCustomerRepsByCustomerById, params);
    return response;
  };

  getCustomerReps = async (customerId, propertyId, billingCustomerId) => {
    const params = {
      customerId,
      propertyId,
      billingCustomerId
    };

    const response = await this.api.query(getCustomerReps, params);
    return response;
  };

  getTenantRepsByCustomer = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getTenantRepsByCustomer, params);
    return response;
  };

  getTenantRepsByCustomerById = async id => {
    const params = {
      id
    };

    const response = await this.api.query(getTenantRepsByCustomerById, params);
    return response;
  };

  getCustomerById = async id => {
    const params = {
      id
    };

    const response = await this.api.query(getCustomerById, params);
    return response;
  };

  getCustomerFieldDataById = async id => {
    const params = {
      id
    };

    const response = await this.api.query(getCustomerFieldDataById, params);
    return response;
  };

  getCustomerAttachmentsById = async idValue => {
    const params = {
      id: idValue
    };

    const response = await this.api.query(getAttachmentsByCustomerId, params);
    if (response && response.data && response.data.getCustomerById) {
      const result = response.data.getCustomerById;
      return result.attachments;
    }
  };

  getCustomerAttachments = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getAttachmentsByCustomer, params);
    if (response && response.data && response.data.getCustomer) {
      const result = response.data.getCustomer;
      return result.attachments;
    }
  };

  getCustomerList = async (partitionKey, sortKey, nextToken, limit) => {
    const params = {
      partitionKey,
      sortKey,
      nextToken,
      limit
    };

    const response = await this.api.query(getCustomerList, params);
    return response;
  };

  getCustomerListView = async (partitionKey, sortKey, filter, limit, offset, sortBy, sortOrder) => {
    const params = {
      partitionKey,
      sortKey,
      filter,
      limit,
      offset,
      sortBy,
      sortOrder
    };

    let result;
    const response = await this.api.query(getCustomerListView, params);
    if (response && response.data) {
      result = response.data.getCompany.customersView;
    }
    return result;
  };

  getPropertiesAndRepsByCustomer = async (
    partitionKey,
    sortKey,
    billingCustomerId = '',
    includeOurReps = false
  ) => {
    const params = {
      partitionKey,
      sortKey,
      billingCustomerId,
      hasBillingCustomer: !!billingCustomerId,
      includeOurReps
    };

    const response = await this.api.query(getPropertiesAndRepsByCustomer, params);
    return response;
  };

  getNotesByCustomer = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getNotesByCustomer, params);
    return response;
  };

  getUpcomingVisitsByCustomer = async (
    partitionKey,
    sortKey,
    filter,
    limit,
    offset,
    sortBy,
    sortOrder
  ) => {
    const params = {
      partitionKey,
      sortKey,
      filter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    const response = await this.api.query(getUpcomingVisitsByCustomer, params);
    return response;
  };

  getPastVisitsByCustomer = async (
    partitionKey,
    sortKey,
    filter,
    limit,
    offset,
    sortBy,
    sortOrder
  ) => {
    const params = {
      partitionKey,
      sortKey,
      filter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };
    const response = await this.api.query(getPastVisitsByCustomer, params);
    return response;
  };

  getJobsByCustomer = async (partitionKey, sortKey, filter, limit, offset, sortBy, sortOrder) => {
    const params = {
      partitionKey,
      sortKey,
      filter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        },
        {
          sortField: 'dueDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    let result;

    const response = await this.api.query(getJobsByCustomer, params);
    const formattedResult = { items: [] };
    if (response?.data) {
      result = response?.data?.getCustomer?.job;
      if (result?.items?.length) {
        result.items.forEach(item => {
          const ownerValue =
            item?.owner?.name || `${item?.owner?.firstName || ''} ${item?.owner?.lastName || ''}`;

          const propertyName = item?.properties?.companyName;
          const { customerName } = item?.properties?.customer || {};
          const propertyAddress = item?.properties?.companyAddresses?.items?.[0];
          const fullAddress = propertyAddress ? convertAddressObjToString(propertyAddress) : '-';

          const start = moment.unix(item.createdDate);
          const end = moment.unix(Date.now() / 1000);
          const age = Math.ceil(moment.duration(end.diff(start)).asDays());

          const visitLabel = item?.visits?.items?.length;
          const visits = item?.visits?.items;

          const scheduleArray = [];

          visits.forEach(visit => {
            // Pushing all visit schedule dates to an array for computing latestSchedule for Job
            if (visit.scheduledFor && moment().unix() <= visit.scheduledFor) {
              scheduleArray.push(visit.scheduledFor);
            }

            const localVisit = visit;
            const primaryTech = visit?.primaryTechs?.items[0]?.mappedEntity;
            // avoiding name as undefined undefined
            localVisit.primaryTechName =
              primaryTech && `${primaryTech?.firstName},${primaryTech.lastName}`;
          });

          const formattedVisits = { visitLabel, visitDetails: visits };

          const departmentNames = this.getDepartments(visits);
          const jobTags = getMappedEntityJobTagname(item.jobJobTags);
          formattedResult.items.push({
            id: item.id,
            sortKey: item.sortKey,
            customIdentifier: item.customIdentifier || item.jobNumber || '',
            jobNumber: item.jobNumber,
            jobSortKey: item.sortKey,
            jobTypeInternal: item.jobTypeInternal,
            customerId: item.customerId,
            customerSortKey: item.customerSortKey,
            customerName: customerName || item.customerName,
            customerPropertyName: propertyName || item.customerPropertyName,
            customerPropertyId: item.customerPropertyId,
            customerPropertySortKey: item.customerPropertySortKey,
            issueDescription: item.issueDescription,
            jobTypeName: item.jobTypeName,
            jobTags,
            createdDate: item.createdDate,
            amountQuoted: item.amountQuoted,
            amountNotToExceed: item.amountNotToExceed,
            status: this.capitalizeFirstLetter(item.status),
            createdBy: item.createdBy,
            ownerValue: ownerValue || '',
            departmentNames,
            propertyAddress: fullAddress,
            age,
            noOfVisits: formattedVisits.visitDetails.length,
            visits: formattedVisits,
            latestScheduled: (scheduleArray && this.getLatestSchedule(scheduleArray)) || '',
            outstandingBalance: item.outstandingBalanceComputed,
            overdueBalance: item.overdueBalanceComputed
          });
        });
      }

      if (result.nextToken) {
        formattedResult.nextToken = result.nextToken;
      }
    }
    return formattedResult;
  };

  getServiceAgreementsByCustomer = async (
    partitionKey,
    sortKey,
    filter,
    limit,
    offset,
    sortBy,
    sortOrder
  ) => {
    const params = {
      partitionKey,
      sortKey,
      filter,
      limit,
      offset,
      sort: [
        {
          sortField: sortBy || 'createdDate',
          sortDirection: sortOrder || 'desc'
        }
      ]
    };

    const response = await this.api.query(getServiceAgreementsByCustomer, params);
    let result;
    if (response && response.data) {
      result = response.data.getCompany.serviceAgreements;
    }
    return result;
  };

  addCustomer = async (paritionKey, tenantCompanyId, values, pageState) => {
    const payload = createCustomerSchema(values, paritionKey, tenantCompanyId, pageState);
    const params = {
      paritionKey,
      data: payload
    };

    const response = await this.api.mutate(addCustomerToCompany, params);
    return response;
  };

  updateCustomerAndRelated = async (paritionKey, tenantCompanyId, values, pageState) => {
    const payload = updateCustomerSchema(values, paritionKey, tenantCompanyId, pageState);
    const params = {
      paritionKey,
      data: payload
    };

    const response = await this.api.mutate(updateCustomerAndRelated, params);
    return response;
  };

  updateCustomerTags = async params => {
    if (params?.paritionKey || !params?.data) return;
    const response = await this.api.mutate(updateCustomerTags, params);
    return response;
  };

  updateCustomerStatus = async (paritionKey, data) => {
    const params = {
      paritionKey,
      data
    };

    const response = await this.api.mutate(updateCustomerStatus, params);
    return response;
  };

  addCustomerRepToCustomer = async values => {
    const payload = customerRepSchema(values);
    const params = {
      paritionKey: values.parent.tenantId,
      data: {
        customerId: values.parent.customerId || values.parent.id,
        customerPropertyId: values.parent.customerPropertyId,
        customerRep: payload
      }
    };
    const response = await this.api.mutate(addCustomerRepToCustomer, params);
    return response;
  };

  addCustomerRepToProperty = async values => {
    const payload = customerRepSchema(values);
    const params = {
      paritionKey: values.parent.tenantId,
      data: {
        customerId: values.parent.customerId || values.parent.id,
        customerPropertyId: values.parent.customerPropertyId,
        customerRep: payload
      }
    };
    const response = await this.api.mutate(addCustomerRepToProperty, params);
    return response;
  };

  updateCustomerRep = async values => {
    const payload = customerRepSchema(values);
    const params = {
      paritionKey: values.parent.tenantId,
      data: {
        customerId: values.parent.customerId || values.parent.id,
        customerPropertyId: values.parent.customerPropertyId,
        customerRep: payload
      }
    };

    const response = await this.api.mutate(updateCustomerRep, params);
    return response;
  };

  mutateTenantRep = async values => {
    const structuredValues = tenantRepSchema(values);
    const dataSet = {
      instructionSet: JSON.stringify(structuredValues.instructionSet),
      data: JSON.stringify(structuredValues.data)
    };

    const response = await this.api.mutate(mutationQuery, dataSet);
    return response;
  };

  deactivateCustomer = async values => {
    const customerData = {};
    customerData.status = 'inactive';
    customerData.isActive = false;
    customerData.sortKey = values.sortKey;
    customerData.version = values.version;
    customerData.syncStatus = 'Syncing';

    const params = {
      input: customerData
    };
    const response = await this.api.mutate(updateCustomer, params);
    return response;
  };

  toggleCustomerActivity = async (partitionKey, data) => {
    const params = {
      partitionKey,
      data
    };
    const response = await this.api.mutate(updateCustomer, params);
    return response;
  };

  deleteCustomerRep = async customerRepRecord =>
    this.CommonService.deactivateRecord(customerRepRecord);

  deleteTenantRep = async (partitionKey, sortKey, invertedSortKey) =>
    this.CommonService.deleteSystemEntityMap(partitionKey, sortKey, invertedSortKey);

  deleteCustomerProperty = async customerPropertyRecord => {
    const customerPropertyData = {};
    customerPropertyData.status = 'inactive';
    customerPropertyData.isActive = false;
    customerPropertyData.sortKey = customerPropertyRecord.sortKey;
    customerPropertyData.version = customerPropertyRecord.version;
    customerPropertyData.syncStatus = 'Syncing';
    const params = {
      input: customerPropertyData
    };
    const response = await this.api.mutate(updateCustomerProperty, params);
    return response;
  };

  getCustomerBillingAddress = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getBillingAddressForCustomer, params);
    return response;
  };

  addAttachmentsToCustomer = async (partitionKey, customerId, values) => {
    let fileext = '';
    if (values.fileUrl) {
      const splitStr = values.fileUrl.split('.') || [];
      fileext = splitStr.length > 0 ? splitStr[splitStr.length - 1] : null;
    }
    const params = {
      partitionKey,
      data: {
        customerId,
        attachments: [
          {
            fileName: values.fileName,
            customFileName: values.customFileName || undefined,
            fileUrl: values.fileUrl,
            type: values.type || fileext || 'img',
            description: values.description || undefined,
            comment: values.comment || undefined
          }
        ]
      }
    };

    const { data } = await this.api.mutate(addAttachmentsToCustomer, params);
    return data && data.addAttachmentsToCustomer;
  };

  getAllAuditLogByCustomerId = async idValue => {
    const params = {
      id: idValue
    };
    const { data, error } = await this.api.query(getAllAuditLogByCustomerId, params);
    if (data && data.getCustomerById && data.getCustomerById.auditLogs) {
      return data.getCustomerById.auditLogs.items || [];
    }
    if (error) {
      throw error;
    }
  };
}
