import InstructionHelper from './InstructionHelper';
import { removeNestedJson, removeNullValues, removeEmptyValues } from '../utils';

const createSchemaMapping = (props, connectionName) => {
  const localProps = removeEmptyValues(removeNullValues(props));
  const strippedJob = removeNestedJson(localProps.parent);

  const fieldsToBeRemoved = ['parent'];

  const otherFields = {};
  Object.keys(localProps).forEach(key => {
    if (localProps[key] && localProps[key] !== '' && !fieldsToBeRemoved.includes(key)) {
      otherFields[key] = localProps[key];
    }
  });

  const data = [{ ...strippedJob }, { ...otherFields }];
  const ins = new InstructionHelper();
  let temp = ins.oneToMany(0, 1, connectionName);

  const { instructionSet } = temp;
  const ret = { data: JSON.stringify(data), instructionSet: JSON.stringify(instructionSet) };
  return ret;
};

export default createSchemaMapping;
