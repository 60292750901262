const formRowsMeta = [
  {
    id: 'Form.name',
    label: 'Name',
    showLink: 'view',
    linkPath: 'fieldreport/form',
    linkReference: 'name',
    type: 'string'
  },
  {
    id: 'Form.description',
    label: 'Description',
    type: 'string'
  },
  {
    id: 'lastUpdatedDate',
    type: 'date',
    label: 'Last Updated'
  }
];

export default formRowsMeta;
