import { intersection } from 'lodash';

import { useDrag } from 'react-dnd';

import { VisitActions } from '@dispatch/Dispatch.constants';
import { ItemTypes } from '@dispatch/dnd';

const canDragTime = (nextEvents, weekView, mapView, wrinkleTimeFF) =>
  [
    nextEvents?.includes(VisitActions.RESCHEDULE.key),
    ...(wrinkleTimeFF ? [!mapView] : [!(weekView || mapView)])
  ].every(x => x);

export const useVisitDrag = ({ visit, srcTech, isDrawer }) => {
  return useDrag({
    item: { type: isDrawer ? ItemTypes.TABLE_VISIT : ItemTypes.BOARD_VISIT, data: visit, srcTech },
    canDrag: () => {
      return Boolean(
        intersection(visit.nextEvents, [VisitActions.SCHEDULE.key, VisitActions.RESCHEDULE.key])[0]
      );
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag()
    })
  });
};

export const useVisitStartDrag = ({ visit, srcTech, weekView, mapView, wrinkleTimeFF }) => {
  return useDrag({
    item: { type: ItemTypes.VISIT_START, data: visit, srcTech },
    canDrag: () => canDragTime(visit.nextEvents, weekView, mapView, wrinkleTimeFF),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag()
    })
  });
};

export const useVisitEndDrag = ({ visit, srcTech, weekView, mapView, wrinkleTimeFF }) => {
  return useDrag({
    item: { type: ItemTypes.VISIT_END, data: visit, srcTech },
    canDrag: () => canDragTime(visit.nextEvents, weekView, mapView, wrinkleTimeFF),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag()
    })
  });
};
