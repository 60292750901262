import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Button, ButtonType, ThemeProvider } from '@buildhero/sergeant';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { attachmentRows } from 'meta/attachment-layout';
import ConfirmModal from 'components/Modal/ConfirmDialog';
import { ResponsiveTable, SectionHeader, UserPermission } from 'components';
import Labels from 'meta/labels';
import { Mode } from 'utils/constants';
import AttachmentModal from './AddAttachment';

const defaultModalState = {
  openAttachment: false,
  dataRecord: '',
  modalMode: '',
  confirmDialog: false,
  confirmAction: '',
  confirmMessage: ''
};

/**
 * Component to show attachments as list table which uses SG modals
 */
export const AttachmentList = props => {
  const {
    user,
    isLoading,
    dataArray,
    caslKey,
    handleAttachmentMutations,
    disabledDocumentHeader,
    readOnly
  } = props;
  const [modalState, setModalState] = useState(defaultModalState);
  const handleOpenPopUp = (popUpKey, mode, record) => {
    setModalState({ [popUpKey]: true, modalMode: mode, dataRecord: record });
  };

  const handleClosePopUp = () => {
    setModalState(defaultModalState);
  };

  const performAttachmentDelete = async record => {
    if (!record) {
      return;
    }
    await handleAttachmentMutations(Mode.DELETE, record);
    setModalState(defaultModalState);
  };

  const handleAttachmentRowActions = (mode, record = {}) => {
    if (mode === Mode.DELETE) {
      setModalState({
        confirmDialog: true,
        confirmAction: async () => performAttachmentDelete(record),
        confirmMessage: 'attachment'
      });
    } else {
      handleOpenPopUp('openAttachment', mode, record);
    }
  };

  const handleCancelConfirmation = () => {
    setModalState(defaultModalState);
  };

  return (
    <Grid item xs={12}>
      <UserPermission I="new" action={caslKey}>
        {!disabledDocumentHeader && (
          <SectionHeader
            title={Labels.Documents[user.locale]}
            enablePadding
            overrideHeaderButtons={[
              !readOnly && (
                <ThemeProvider>
                  <Button
                    type={ButtonType.SECONDARY}
                    onClick={() => handleOpenPopUp('openAttachment', 'new', '')}
                    startIcon={<AddCircleOutlineIcon />}
                    key="addButton"
                  >
                    Add Attachment
                  </Button>
                </ThemeProvider>
              )
            ]}
          />
        )}
      </UserPermission>
      <ResponsiveTable
        isLoading={isLoading}
        rowMetadata={attachmentRows({})}
        data={dataArray}
        disableFilter
        rowActions={handleAttachmentRowActions}
        rowActionButtons={
          readOnly
            ? null
            : {
                [Mode.EDIT]: {
                  label: 'Edit',
                  icon: 'BorderColor'
                },
                [Mode.DELETE]: {
                  label: 'Delete',
                  icon: 'Delete'
                }
              }
        }
        caslKey={caslKey}
      />
      <AttachmentModal
        open={modalState.openAttachment}
        mode={modalState.modalMode}
        data={modalState.dataRecord}
        mutationService={payload => handleAttachmentMutations(modalState.modalMode, payload)}
        handleClose={() => {
          handleClosePopUp('openAttachment');
        }}
        caslKey={caslKey}
      />
      <ConfirmModal
        open={modalState.confirmDialog}
        confirm={modalState.confirmAction}
        cancel={handleCancelConfirmation}
        message={modalState.confirmMessage}
      />
    </Grid>
  );
};

AttachmentList.propTypes = {
  // injected from redux, set while user logs in
  user: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  dataArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  caslKey: PropTypes.string,
  handleAttachmentMutations: PropTypes.func.isRequired,
  disabledDocumentHeader: PropTypes.bool,
  readOnly: PropTypes.bool
};

AttachmentList.defaultProps = {
  isLoading: false,
  caslKey: '',
  disabledDocumentHeader: false,
  readOnly: false
};

const connectedAttachmentList = connect(state => ({ user: state.user }))(AttachmentList);

export default connectedAttachmentList;
