import React, { useMemo, useState } from 'react';

import { ThemeProvider } from '@buildhero/sergeant';
import { any, arrayOf, bool, number } from 'prop-types';
import { useSelector } from 'react-redux';

import withLazyMount from 'utils/withLazyMount';

import { REPORT_FORM_PROP, ReportTableSectionDefaultProps } from '../../propTypes';
import { formatFormItem, FormModal } from '../FormModalLink/FormModalLink.component';
import ReportTable from '../ReportTable';

import { useFormTableColumns } from './FormTable.hooks';
import PDFDialog from './PDFDialog';

const mergeColumnData = (form, columns) => {
  const formItem = formatFormItem(form);
  const columnData = {};
  columns.forEach(col => {
    columnData[col.field] = form[col.field];
  });
  return { ...formItem, ...columnData };
};

const FormTable = ({
  forms: unformattedForms,
  loading,
  error,
  tableOnly,
  editable,
  title,
  columns = [],
  loadingRows
}) => {
  const forms = useMemo(
    () => unformattedForms.map(f => mergeColumnData(f, columns)).filter(f => f),
    [unformattedForms]
  );

  const user = useSelector(s => s.user);
  const [PDFDialogOpen, setPDFDialogOpen] = useState(false);
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [formModalMode, setFormModalMode] = useState('view');
  const [formData, setFormData] = useState(null);

  const tableTitle = title ?? 'Forms';

  const columnsWithActions = useFormTableColumns({
    setFormData,
    setFormModalOpen,
    setPDFDialogOpen,
    setFormModalMode,
    editable
  });

  return (
    <ThemeProvider>
      <ReportTable
        title={tableTitle}
        columns={columns.concat(columnsWithActions)}
        rows={forms}
        loading={loading}
        error={error}
        tableOnly={tableOnly}
        noDataMessage="No Forms Completed"
        lockRowHeight
        loadingRows={loadingRows}
      />
      <PDFDialog
        open={PDFDialogOpen}
        setOpen={setPDFDialogOpen}
        formData={formData}
        onClose={() => setPDFDialogOpen(false)}
      />
      <FormModal
        open={formModalOpen}
        handleClose={() => setFormModalOpen(false)}
        formData={formData}
        mode={formModalMode}
        user={user}
      />
    </ThemeProvider>
  );
};

FormTable.propTypes = {
  forms: arrayOf(REPORT_FORM_PROP),
  loading: bool,
  error: any,
  tableOnly: bool,
  editable: bool,
  title: String,
  columns: arrayOf(any), // used in job closeout to display visit number
  loadingRows: number
};
FormTable.defaultProps = ReportTableSectionDefaultProps;

export default withLazyMount(FormTable);
