import React, { useCallback, useMemo } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import SergeantModal from 'components/SergeantModal';
import AlgoliaSearchWrapper from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import { PurchasedItemSgtLayout } from 'meta/Jobs/Invoice/PurchasedItemSgtForm';
import { isTenantSettingEnabled } from 'utils';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import { validations } from 'services/core';
import { useCostCodes, useCostTypes, useS3ImageUrl } from './PurchaseOrderLineModal.hooks';

const PurchaseOrderLineModal = ({
  priceBookId,
  purchaseOrders,
  purchaseOrderLine,
  open,
  mode,
  onClose,
  onAction
}) => {
  const isPriceBookEnabled = isTenantSettingEnabled('pricebook');

  const productFilter = {
    entityType: ['PriceBookEntry'],
    priceBookId: [priceBookId]
  };

  const purchaseOrder = useMemo(
    () => purchaseOrders.find(item => item?.id === purchaseOrderLine?.parentId),
    [purchaseOrders, purchaseOrderLine]
  );
  const imageUrl = purchaseOrder?.purchaseOrderReceipts?.items?.[0]?.imageUrl;
  const options = useMemo(() => ({ skip: !open }), [open]);
  const s3Url = useS3ImageUrl(imageUrl, options);
  const { data: costCodes } = useCostCodes(options);
  const {
    data: { jobCostTypes, revenueTypes }
  } = useCostTypes(options);

  const data = useMemo(
    () => ({
      ...purchaseOrderLine,
      markupValue: purchaseOrderLine?.markup,
      amount: purchaseOrderLine?.totalAmount,
      s3Url
    }),
    [purchaseOrderLine, s3Url]
  );

  const formData = useMemo(
    () => ({
      includeImage: Boolean(s3Url),
      costCodes: constructSelectOptions(costCodes),
      costTypes: constructSelectOptions(jobCostTypes),
      revenueTypes: constructSelectOptions(revenueTypes)
    }),
    [costCodes, jobCostTypes, revenueTypes, s3Url]
  );

  const handlePrimaryAction = useCallback(
    async (newData, stopLoading) => {
      await onAction(newData);
      stopLoading();
    },
    [onAction]
  );

  const layout = useMemo(
    () => PurchasedItemSgtLayout(productFilter, formData, isPriceBookEnabled),
    [productFilter, formData, isPriceBookEnabled]
  );

  return (
    <SergeantModal
      mode={mode}
      dataType="receipt line item"
      open={open}
      handleClose={onClose}
      data={data}
      layout={layout}
      customComponents={{ AlgoliaSearchWrapper }}
      handlePrimaryAction={handlePrimaryAction}
      validationSchema={validations.reviewReportPurchaseOrderLinesSchema}
    />
  );
};

PurchaseOrderLineModal.propTypes = {
  priceBookId: string.isRequired,
  purchaseOrders: array.isRequired,
  purchaseOrderLine: object.isRequired,
  open: bool.isRequired,
  mode: string.isRequired,
  onClose: func.isRequired,
  onAction: func.isRequired
};

export default PurchaseOrderLineModal;
