export const glAccountsRows = [
  {
    id: 'accountNumber',
    maxTextLen: 30,
    disablePadding: true,
    label: 'Account Number'
  },
  {
    id: 'name',
    maxTextLen: 30,
    disablePadding: true,
    label: 'Account Description'
  },
  {
    id: 'accountType',
    maxTextLen: 30,
    disablePadding: true,
    label: 'Account Type'
  },
  {
    id: 'isDefault',
    disableFilter: true,
    disableSort: true,
    isCustom: true,
    maxTextLen: 30,
    disablePadding: true,
    type: 'setDefaultLedgerButton',
    label: 'Default'
  }
];

export const syncLogRows = [
  {
    id: 'syncEntityType',
    disablePadding: true,
    label: 'Object Type'
  },
  {
    id: 'syncEntityValue',
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'createdDateTime',
    disablePadding: true,
    label: 'Sync Date',
    type: 'dateTime'
  },
  {
    id: 'syncType',
    disablePadding: true,
    label: 'Type'
  },
  {
    id: 'syncLog',
    disablePadding: true,
    label: '',
    type: 'tooltip',
    other: 'syncLog'
  }
];

export const qbClassesRows = [
  {
    id: 'name',
    maxTextLen: 30,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'accountingApplication',
    maxTextLen: 30,
    disablePadding: true,
    label: 'Application'
  }
];

export const dimensionMeta = [
  {
    id: 'name',
    maxTextLen: 30,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'type',
    maxTextLen: 30,
    disablePadding: true,
    label: 'Type'
  },
  {
    id: 'application',
    maxTextLen: 30,
    disablePadding: true,
    label: 'Application',
    defaultCellText: 'Intacct'
  }
];

export const bankAccountMeta = [
  {
    id: 'accountingRefId',
    maxTextLen: 30,
    disablePadding: true,
    label: 'Ref Id'
  },
  {
    id: 'name',
    maxTextLen: 30,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'currencyCode',
    maxTextLen: 30,
    disablePadding: true,
    label: 'Currency Code'
  },
  {
    id: 'isDefault',
    disableFilter: true,
    disableSort: true,
    isCustom: true,
    maxTextLen: 30,
    disablePadding: true,
    type: 'setDefaultBankButton',
    label: 'Default'
  }
];

export const itemGLGroupMeta = [
  {
    id: 'name',
    maxTextLen: 30,
    disablePadding: true,
    label: 'Name'
  }
];

export const sageJobRows = [
  {
    id: 'name',
    maxTextLen: 30,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'code',
    maxTextLen: 30,
    disablePadding: true,
    label: 'Code'
  }
];

export default glAccountsRows;
