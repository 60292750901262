import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { getContentText } from 'scenes/ProjectManagement/components/utils';
import {
  getTotalCompletedAndStoredToDate,
  getPercentageOfTotalCompletedAndStoredToDate,
  getBalanceToFinish
} from './calculations';
import styles from '../style';

const ContinuationSheetTableRows = ({ sovLineItems }) => {
  return (
    <View>
      {sovLineItems?.map(sovLineItem => (
        <View style={styles.displayFlex} key={sovLineItem.id}>
          <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '5%' }]}>
            {sovLineItem.itemNumber}
          </Text>
          <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '27%' }]}>
            {sovLineItem.description}
          </Text>
          <Text style={[styles.text, styles.innerCell, styles.numberAlign, { width: '12%' }]}>
            {getContentText('currency', sovLineItem.contractValue, 2)}
          </Text>
          <Text style={[styles.text, styles.innerCell, styles.numberAlign, { width: '8%' }]}>
            {getContentText('currency', sovLineItem.totals?.totalCompletedAndStored || 0, 2)}
          </Text>
          <Text style={[styles.text, styles.innerCell, styles.numberAlign, { width: '8%' }]}>
            {getContentText('currency', sovLineItem.workCompleted, 2)}
          </Text>
          <Text style={[styles.text, styles.innerCell, styles.numberAlign, { width: '10%' }]}>
            {getContentText('currency', sovLineItem.materialsStored, 2)}
          </Text>
          <Text style={[styles.text, styles.innerCell, styles.numberAlign, { width: '10%' }]}>
            {getContentText(
              'currency',
              getTotalCompletedAndStoredToDate(
                sovLineItem.totals?.totalCompletedAndStored || 0,
                sovLineItem.workCompleted,
                sovLineItem.materialsStored
              ),
              2
            )}
          </Text>
          <Text style={[styles.text, styles.innerCell, styles.numberAlign, { width: '8%' }]}>
            {getContentText(
              'percentage',
              getPercentageOfTotalCompletedAndStoredToDate(sovLineItem),
              2
            )}
          </Text>
          <Text
            style={[
              styles.text,
              styles.innerCell,
              styles.numberAlign,
              { width: '12%', borderRight: 0 }
            ]}
          >
            {getContentText('currency', getBalanceToFinish(sovLineItem), 2)}
          </Text>
        </View>
      ))}
    </View>
  );
};

ContinuationSheetTableRows.propTypes = {
  sovLineItems: PropTypes.array.isRequired
};

export default ContinuationSheetTableRows;
