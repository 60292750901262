import gql from 'graphql-tag';

const deleteClosedPeriod = gql`
  mutation deleteClosedPeriod($partitionKey: String!, $id: String!) {
    deleteClosedPeriod(partitionKey: $partitionKey, id: $id) {
      id
      closeDate
    }
  }
`;

export default deleteClosedPeriod;
