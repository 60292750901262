import React, { useState, useRef } from 'react';
import { Grid, Button, Popover, List, ListItem, ListItemText, Switch } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ReorderIcon from '@material-ui/icons/Reorder';
import labels from 'meta/labels';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles = theme => ({
  checked: {
    '& + $track': {
      backgroundColor: theme.palette.grayscale(94)
    }
  },
  colorSecondary: {
    '&$checked + $track': {
      backgroundColor: theme.palette.grayscale(94)
    },
    '&$checked': {
      top: '-2px'
    }
  },
  dragIconRoot: {
    color: theme.palette.grayscale(60),
    display: 'flex'
  },
  listItemPrimary: {
    color: theme.palette.grayscale(20),
    fontSize: 13,
    fontWeight: 400
  },
  listItemRoot: {
    height: '30px',
    paddingLeft: '8px',
    paddingRight: '2px'
  },
  switchBase: {
    color: theme.palette.grayscale(60)
  },
  track: {
    backgroundColor: theme.palette.grayscale(94)
  }
});

function ColumnsAction(props) {
  const { classes, metadata, user, reorderColumn, toolbarClasses } = props;
  const buttonRef = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleDragEnd = (result, provided) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    // internal data must be moved to the end so it does not interfere with sorting logic
    const sortedMetaData = [...metadata].sort((a, b) => (!a.internal && b.internal ? -1 : 1));
    if (sortedMetaData[result.destination.index].isDragDisabled) return;
    const element = sortedMetaData[result.source.index];
    sortedMetaData.splice(result.source.index, 1);
    sortedMetaData.splice(result.destination.index, 0, element);
    reorderColumn(sortedMetaData);
  };

  const toggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleChange = colMetadata => {
    colMetadata.hide = !colMetadata.hide;
    reorderColumn(metadata);
  };

  const buttonClass = isMenuOpen
    ? `${toolbarClasses.toolbarButton} ${toolbarClasses.toolbarButtonActive}`
    : toolbarClasses.toolbarButton;

  // react-beautiful-dnd docs tell you to props spread
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <>
      <Button
        className={buttonClass}
        ref={buttonRef}
        onClick={toggle}
        endIcon={<ArrowDropDownIcon />}
      >
        ADJUST COLUMNS
      </Button>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppableMenu" direction="vertical">
          {droppableProvided => (
            <Popover
              id="add-menu"
              anchorEl={() => buttonRef.current}
              open={isMenuOpen}
              keepMounted
              onClose={() => setIsMenuOpen(false)}
              getContentAnchorEl={null}
              ref={droppableProvided.innerRef}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
            >
              <List {...droppableProvided.droppableProps}>
                {metadata
                  .filter(col => !col.internal)
                  .map((colMetadata, colIndex) => {
                    const columnName =
                      labels[colMetadata.label]?.[user.locale] || colMetadata.label;
                    const background = colMetadata?.headerStyle?.background;
                    const { hide } = colMetadata;
                    return (
                      <Draggable
                        key={`dragMenu${colMetadata.id}`}
                        draggableId={`dragMenu${colMetadata.id}`}
                        index={colIndex}
                        isDragDisabled={colMetadata.isDragDisabled}
                      >
                        {(draggableProvided, snapshot) => {
                          return (
                            <ListItem
                              classes={{
                                root: classes.listItemRoot
                              }}
                              key={`menu${colMetadata.id}`}
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              style={{
                                ...draggableProvided.draggableProps.style,
                                background: snapshot.isDragging
                                  ? 'rgba(245,245,245, 0.75)'
                                  : background
                              }}
                            >
                              {!colMetadata.isDragDisabled && (
                                <Grid
                                  {...draggableProvided.dragHandleProps}
                                  classes={{ root: classes.dragIconRoot }}
                                >
                                  <ReorderIcon />
                                </Grid>
                              )}
                              <ListItemText
                                classes={{
                                  primary: classes.listItemPrimary
                                }}
                                style={{ paddingLeft: 5 }}
                              >
                                {columnName}
                              </ListItemText>
                              <Switch
                                classes={{
                                  switchBase: classes.switchBase,
                                  track: classes.track,
                                  colorSecondary: classes.colorSecondary,
                                  checked: classes.checked
                                }}
                                checkedIcon={<CheckCircleIcon />}
                                checked={!hide}
                                onChange={() => handleChange(colMetadata)}
                                name={`switch${columnName}`}
                              />
                            </ListItem>
                          );
                        }}
                      </Draggable>
                    );
                  })}
              </List>
            </Popover>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default withStyles(styles)(ColumnsAction);
