import gql from 'graphql-tag';

const deleteProjectSubType = gql`
  mutation deleteProjectSubType($partitionKey: String!, $id: String!) {
    deleteProjectSubType(partitionKey: $partitionKey, id: $id) {
      id
      name
      sortOrder
    }
  }
`;

export default deleteProjectSubType;
