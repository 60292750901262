import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styles from '../styles';

const ModalHeader = ({
  title,
  iconComponent,
  modalHeaderButtons,
  handleClose,
  classes,
  centerComponent
}) => (
  <AppBar className={classes.appBar}>
    <Toolbar className={classes.toolbar}>
      <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
        <CloseIcon />
      </IconButton>
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item>
          {(title || iconComponent) && (
            <Typography className={classes.title} variant="h6" gutterBottom>
              {iconComponent || null} {typeof title === 'string' ? title : ''}
            </Typography>
          )}
        </Grid>
        <Grid item style={{ flex: 1 }}>
          {centerComponent}
        </Grid>
        <Grid item className={classes.buttonContainer}>
          {modalHeaderButtons}
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar>
);

ModalHeader.propTypes = {
  centerComponent: PropTypes.elementType
};

ModalHeader.defaultProps = {
  centerComponent: null
};

export default withStyles(styles)(ModalHeader);
