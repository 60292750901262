import React from 'react';

import PlacesSearch from 'components/BuildHeroFormComponents/PlacesSearch';

const SearchInput = props => {
  const { form, field, options } = props;

  const valuesToSet = [
    {
      billingLatitude: 'latitude',
      billingLongitude: 'longitude'
    }
  ];
  const adaptedOptions = { ...options, valuesToSet };
  return (
    <PlacesSearch
      form={form}
      field={field}
      value={field.value}
      options={adaptedOptions}
      style={{ width: '460px' }}
    />
  );
};

export default SearchInput;
