/* eslint-disable import/prefer-default-export */
export const NoteLayout = {
  fields: {
    subject: {
      default: 'subject',
      edit: 'subject'
    },
    note: {
      default: 'note',
      edit: 'note'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        direction: 'row',
        width: 450
      },
      contents: [
        {
          options: {
            direction: 'column'
          },
          contents: [
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel',
                    edit: 'TextInput'
                  },
                  source: 'subject',
                  options: {
                    label: 'Subject',
                    placeholder: 'Enter subject',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel',
                    edit: 'TextInput'
                  },
                  source: 'note',
                  options: {
                    label: 'Note',
                    placeholder: 'Enter note text',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left',
                    lines: 10
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      subject: {
        type: 'string'
      },
      note: {
        type: 'string'
      }
    },
    required: ['subject', 'note']
  },
  validationErrors: {
    subject: {
      required: 'Subject is required.'
    },
    note: {
      required: 'Note is required.'
    }
  }
};
