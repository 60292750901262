import { getBestContact } from 'utils';

const jobSchemaMapping = (data, pageState) => {
  const {
    properties,
    jobTypes,
    customerId,
    customerSortKey,
    formAttributes,
    departments,
    syncNow,
    customJobNumber
  } = pageState;

  const chosenProperty = properties.find(property => property.id === data.customerPropertyId);
  const chosenJobType = (jobTypes || []).find(type => type.id === data.jobTypeId);
  const chosenPropertyRep = chosenProperty?.processedCustomerReps?.find(
    rep => data.customerRepId === rep.id
  );

  const bestContact = getBestContact(chosenPropertyRep);
  const customFields = {};
  let haveCustomFields = false;

  const departmentsInput = [];
  if (data.departmentsAssigned) {
    data.departmentsAssigned.forEach(chosenDepartmentId => {
      const selectedDepartment = (departments || []).find(dep => dep.id === chosenDepartmentId);
      if (selectedDepartment) {
        departmentsInput.push({
          id: selectedDepartment.id || undefined,
          sortKey: selectedDepartment.sortKey || undefined,
          entityType: selectedDepartment.entityType || undefined,
          tenantId: selectedDepartment.tenantId || undefined,
          tagName: selectedDepartment.tagName || undefined,
          tagType: selectedDepartment.tagType || undefined,
          accountingRefId: selectedDepartment.accountingRefId || undefined,
          accountingRefIdOfClass: selectedDepartment.accountingRefIdOfClass || undefined
        });
      }
    });
  }

  if (data && formAttributes) {
    Object.keys(data).forEach(dataItem => {
      if (dataItem.startsWith('Custom_')) {
        haveCustomFields = true;
        customFields[dataItem.replace('Custom_', '')] = data[dataItem] || undefined;
      }
    });
    customFields.formDefinitionSortKey = formAttributes.formDefinitionSortKey;
    customFields.formSortKey = formAttributes.formSortKey;
    customFields.formId = formAttributes.formId;
    customFields.formDefinitionId = formAttributes.formDefinitionId;
  }

  const payload = {
    customerPropertyId: chosenProperty.id || undefined,
    jobs: [
      {
        accountingJobNumber: data.accountingJobNumber,
        accountManagerId: data.accountManagerId,
        amountNotToExceed: data.amountNotToExceed || undefined,
        amountQuoted: data.amountQuoted || undefined,
        authorizedById: data.authorizedById || undefined,
        bestContact,
        billingCustomerId: data.billingCustomerId,
        billingCustomerName: data.billingCustomerName,
        certifiedPayroll: data.certifiedPayroll,
        costAmount: data.costAmount || undefined,
        customerId,
        customerName: data.customerName || undefined,
        customerPropertyId: (chosenProperty || {}).id,
        customerPropertyName: (chosenProperty || {}).companyName || undefined,
        customerPropertySortKey: (chosenProperty || {}).sortKey || undefined,
        customerPropertyTypeName: (chosenProperty || {}).customerPropertyTypeValue || undefined,
        customerProvidedPONumber: data.customerProvidedPONumber || undefined,
        customerProvidedWONumber: data.customerProvidedWONumber || undefined,
        customerRepName: chosenPropertyRep?.name || undefined,
        customerRepSortKey: chosenPropertyRep?.sortKey || undefined,
        customerSortKey,
        detailedJobCostingEnabled: data.detailedJobCostingEnabled,
        issueDescription: data.issueDescription || undefined,
        // jobNumber: jobNumber || undefined,
        jobTypeId: (chosenJobType || {}).id || undefined,
        jobTypeName: (chosenJobType || {}).tagName || undefined,
        jobTypeSortKey: (chosenJobType || {}).sortKey || undefined,
        ownerId: data.ownerId || undefined,
        priceBookId: data.priceBookId || undefined,
        priority: data.priority || undefined,
        sageJobId: data.sageJobId,
        serviceAgreementId: data.serviceAgreementId || undefined,
        soldById: data.soldById || undefined,
        status: 'Open',
        procurementStatus: data.procurementStatus || undefined,
        totalBudgetedHours: data.totalBudgetedHours || undefined
      }
    ]
  };

  // custom identifier only when custom job number is specified
  // when jobType sequence is enabled, custom identifer should not be sent
  if (customJobNumber && data.customIdentifier && !data.customJobNumberExpression) {
    payload.jobs[0].customIdentifier = data.customIdentifier;
  }

  if (syncNow) {
    payload.jobs[0].syncStatus = 'Syncing';
  } else {
    payload.jobs[0].syncStatus = null;
  }

  if (haveCustomFields) {
    payload.jobs[0].formData = customFields;
  }

  if (departmentsInput.length) {
    payload.jobs[0].departments = departmentsInput;
  }

  return payload;
};

export default jobSchemaMapping;
