const toolTips = {
  departmentAndSkills:
    'Organize your company by department, add employees and skills. If applicable, include department-specific contact information including phone, email, shipping, and billing address.',
  department: 'You can edit departments anytime',
  skills: 'The Skills listed here can be assigne to employees and used to describe jobs',
  tasks:
    'Creating tasks is not mandatory but can help to better track a job and guide technicians. The use of tasks is recommended for bigger jobs',
  scheduling:
    'Multiple visits can be scheduled for a single job. BuildHero will use any information you input below to search and propose suitable dates and times',
  lineItems: 'Line items'
};

export default toolTips;
