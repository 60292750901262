import gql from 'graphql-tag';

const getHeartbeatById = gql`
  query GetHeartbeat($id: String!, $partitionKey: String!) {
    getHeartbeatById(partitionKey: $partitionKey, id: $id) {
      latitude
      longitude
      timestamp
      expiresAt
      id
      parentEntity {
        ... on Employee {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
export default getHeartbeatById;
