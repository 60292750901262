import React, { useMemo } from 'react';
import { CurrencyInput } from '@buildhero/sergeant';

import toFixedNumber from 'utils/toFixedNumber';

import {
  BoldCell,
  tableCurrencyFormatter,
  tablePercentageFormatter,
  sum
} from 'components/WrapTable';

import CurrencyPercentageInput from './components/CurrencyPercentageInput.component';

const getTotalToDate = ({ row }) => {
  return (
    (row.materialsStored || 0) +
    (row.workCompleted || 0) +
    (row.totals?.totalCompletedAndStored || 0)
  );
};

export const usePayAppLineItemColumns = ({ formService }) => {
  return useMemo(
    () => [
      {
        field: 'id',
        hide: true
      },
      {
        headerName: 'Item Number',
        field: 'itemNumber',
        maxWidth: 120
      },
      {
        headerName: 'Description of Work',
        field: 'description',
        flex: 4,
        noWrap: true,
        showTooltip: true,
        totalGetter: () => 'GRAND TOTALS',
        renderTotal: BoldCell
      },
      {
        headerName: 'Contract Value',
        field: 'contractValue',
        valueFormatter: tableCurrencyFormatter,
        renderCell: BoldCell,
        totalGetter: sum,
        totalFormatter: tableCurrencyFormatter,
        renderTotal: BoldCell
      },
      {
        headerName: 'Work Completed Since Previous Applications',
        field: 'previousWorkCompleted',
        valueGetter: ({ row }) => row.totals?.totalCompletedAndStored,
        valueFormatter: tableCurrencyFormatter,
        renderCell: BoldCell,
        totalGetter: sum,
        totalFormatter: tableCurrencyFormatter,
        renderTotal: BoldCell
      },
      {
        headerName: 'Work Completed This Period',
        field: 'workCompleted',
        valueFormatter: tableCurrencyFormatter,
        totalGetter: sum,
        totalFormatter: tableCurrencyFormatter,
        renderTotal: BoldCell,
        editable: true,
        renderEditCell: ({ value, cellAddress, row }) => (
          <CurrencyPercentageInput
            value={value}
            row={row}
            testingid={cellAddress}
            onChange={newValue => {
              formService.formikContext.setFieldValue(`payAppLineItemRows${cellAddress}`, newValue);
            }}
          />
        )
      },
      {
        headerName: 'Materials Presently Stored',
        field: 'materialsStored',
        valueFormatter: tableCurrencyFormatter,
        totalGetter: sum,
        totalFormatter: tableCurrencyFormatter,
        renderTotal: BoldCell,
        editable: true,
        renderEditCell: ({ value, cellAddress }) => (
          <CurrencyInput
            value={value}
            testingid={cellAddress}
            onChange={newValue => {
              formService.formikContext.setFieldValue(`payAppLineItemRows${cellAddress}`, newValue);
            }}
          />
        )
      },
      {
        headerName: 'Total Completed and Stored to Date',
        field: 'totalToDate',
        valueGetter: getTotalToDate,
        valueFormatter: tableCurrencyFormatter,
        renderCell: BoldCell,
        totalGetter: sum,
        totalFormatter: tableCurrencyFormatter,
        renderTotal: BoldCell
      },
      {
        headerName: '%',
        field: 'completedPercent',
        valueGetter: ({ row }) =>
          toFixedNumber((getTotalToDate({ row }) / row.contractValue) * 100),
        valueFormatter: tablePercentageFormatter,
        maxWidth: 80,
        totalGetter: ({ rows }) => {
          return toFixedNumber(
            (sum({ rows, valueGetter: getTotalToDate }) / sum({ rows, field: 'contractValue' })) *
              100
          );
        },
        totalFormatter: tablePercentageFormatter,
        renderTotal: BoldCell
      },
      {
        headerName: 'Balance To Finish',
        field: 'balanceToFinish',
        valueGetter: ({ row }) => row.contractValue - getTotalToDate({ row }),
        valueFormatter: tableCurrencyFormatter,
        renderCell: BoldCell,
        totalGetter: sum,
        totalFormatter: tableCurrencyFormatter,
        renderTotal: BoldCell
      }
    ],
    [formService]
  );
};

export const useRetainageColumns = ({ onBillableRetainageChange }) =>
  useMemo(
    () => [
      {
        headerName: 'Total Billable Retainage',
        field: 'totalBillableRetainage',
        valueFormatter: tableCurrencyFormatter,
        renderCell: BoldCell
      },
      {
        headerName: 'Retainage Billed Previously',
        field: 'previouslyBilledRetainage',
        valueFormatter: tableCurrencyFormatter,
        renderCell: BoldCell
      },
      {
        headerName: 'Billed on this Pay App',
        field: 'billedRetainage',
        editable: true,
        valueFormatter: tableCurrencyFormatter,
        renderEditCell: ({ value, cellAddress, row }) => (
          <CurrencyPercentageInput
            value={value}
            row={row}
            testingid={cellAddress}
            onChange={onBillableRetainageChange}
            min={0}
            max={row.contractValue - row.previouslyBilledRetainage}
          />
        )
      },
      {
        headerName: 'Total Amount of Retainage Billed',
        field: 'totalAmountOfBilledRetainage',
        valueFormatter: tableCurrencyFormatter,
        renderCell: BoldCell
      }
    ],
    [onBillableRetainageChange]
  );
