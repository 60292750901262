import React, { useMemo } from 'react';
import * as R from 'ramda';
import { useTheme } from '@material-ui/core';
import { toArray } from 'xstate/lib/utils';

import { MUIFormComponentNames, InlineAlertTypes } from '@buildhero/sergeant';

import PropertyAddress from './components/PropertyAddress';
import PropertyRep from './components/PropertyRep';
import CustomerName from './components/CustomerName';
import { VisitFormFields, CustomFormComponents } from './VisitDetailsForm.constants';
import { useStyles } from './VisitDetailsForm.styles';

const selectEnabledFields = ({ visitStateMachine, status, saveTransition }) => {
  if (!(visitStateMachine && status && saveTransition)) return {};
  const transitions = toArray(visitStateMachine.config.states[status].on[saveTransition]);

  return transitions.reduce(
    (acc, cur) => ({
      ...acc,
      ...cur.actions.reduce((fields, action) => ({ ...fields, ...action.formFields }), {})
    }),
    {}
  );
};

export const useVisitFormConfig = ({
  departmentsResponse,
  disabled,
  primaryTechOptions,
  extraTechOptions,
  allowMultipleVisitsCreation,
  multipleVisitsCreationEnabled,
  onCreateMultipleVisitsChange,
  techs,
  techsLoading,
  visit,
  visitLoading,
  collapseDetails,
  schedules,
  showTechStatuses,
  multipleVisitsCreateInProcess,
  job,
  crewTimeTracking,
  crewMembersAdded
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return useMemo(() => {
    const row = contents => ({
      options: {
        marginBottom: theme.spacing(2)
      },
      contents
    });

    const colRow = contents => ({
      options: {
        marginBottom: theme.spacing(2),
        direction: 'row'
      },
      contents
    });

    const col = (width, contents) => ({
      options: {
        size: 'auto',
        paddingRight: theme.spacing(2),
        grow: 0,
        shrink: 1,
        direction: 'column',
        width
      },
      contents
    });

    const enabledFields = selectEnabledFields(visit);
    const isFieldDisabled = field => disabled || (visit.id && !enabledFields[field]);

    const DESCRIPTION_FIELD = {
      component: MUIFormComponentNames.TextInput,
      source: VisitFormFields.description.key,
      options: {
        disabled: isFieldDisabled(VisitFormFields.description.key),
        label: VisitFormFields.description.value,
        lines: 4,
        linesMax: 4
      }
    };

    const TODO_FIELD = {
      component: MUIFormComponentNames.TextInput,
      source: VisitFormFields.prerequisites.key,
      options: {
        disabled: isFieldDisabled(VisitFormFields.prerequisites.key),
        label: VisitFormFields.prerequisites.value,
        lines: 4
      }
    };

    const TODO_DONE_FIELD = {
      component: MUIFormComponentNames.CheckboxInput,
      source: VisitFormFields.prerequisitesAcknowledged.key,
      options: {
        disabled: isFieldDisabled(VisitFormFields.prerequisitesAcknowledged.key),
        label: VisitFormFields.prerequisitesAcknowledged.value,
        labelPlacement: 'top'
      }
    };

    const CUSTOMER_NAME = {
      component: MUIFormComponentNames.FieldWithLabel,
      options: {
        label: VisitFormFields.customerName.value,
        type: 'custom',
        transform: () => <CustomerName loading={visitLoading} visit={visit} />
      }
    };

    const PROPERTY_ADDRESS = {
      component: MUIFormComponentNames.FieldWithLabel,
      options: {
        label: VisitFormFields.propertyAddress.value,
        type: 'custom',
        transform: () => <PropertyAddress loading={visitLoading} visit={visit} job={job} />
      }
    };

    const PROPERTY_REP = {
      component: MUIFormComponentNames.FieldWithLabel,
      options: {
        label: VisitFormFields.propertyRep.value,
        type: 'custom',
        transform: () => <PropertyRep loading={visitLoading} visit={visit} />
      }
    };

    const CREATE_MULTIPLE_VISITS_SWITCH = [
      {
        component: allowMultipleVisitsCreation
          ? CustomFormComponents.CreateMultipleVisitsSwitch
          : null,
        options: {
          disabled: disabled || multipleVisitsCreateInProcess,
          checked: multipleVisitsCreationEnabled,
          onChange: onCreateMultipleVisitsChange
        }
      }
    ];

    const SCHEDULED_FIELD = [
      {
        component: MUIFormComponentNames.LabelControl,
        source: VisitFormFields.scheduledFor.value
      },
      {
        component: CustomFormComponents.DateTimeInput,
        source: VisitFormFields.scheduledFor.key,
        options: {
          disabled: isFieldDisabled(VisitFormFields.scheduledFor.key)
        }
      }
    ];

    const START_DATE_FIELD = [
      {
        component: MUIFormComponentNames.LabelControl,
        source: 'Start Date & Time'
      },
      {
        component: CustomFormComponents.DateTimeInput,
        source: VisitFormFields.scheduledFor.key
      }
    ];

    const END_DATE_FIELD = [
      {
        component: MUIFormComponentNames.LabelControl,
        source: VisitFormFields.endDate.value
      },
      {
        component: MUIFormComponentNames.DateInput,
        source: VisitFormFields.endDate.key
      }
    ];

    const EXCLUDE_WEEKENDS_FIELD = [
      {
        component: MUIFormComponentNames.CheckboxInput,
        source: VisitFormFields.excludeWeekend.key,
        options: {
          label: VisitFormFields.excludeWeekend.value
        }
      }
    ];

    const DURATION_FIELD = [
      {
        component: MUIFormComponentNames.LabelControl,
        source: VisitFormFields.actualDuration.value
      },
      {
        component: CustomFormComponents.DurationInput,
        source: VisitFormFields.actualDuration.key,
        options: {
          disabled: isFieldDisabled(VisitFormFields.actualDuration.key)
        }
      }
    ];

    const schedulingSectionContent = multipleVisitsCreationEnabled
      ? [
          row(CREATE_MULTIPLE_VISITS_SWITCH),
          row(START_DATE_FIELD),
          row(END_DATE_FIELD),
          row(EXCLUDE_WEEKENDS_FIELD),
          row(DURATION_FIELD)
        ]
      : [row(CREATE_MULTIPLE_VISITS_SWITCH), row(SCHEDULED_FIELD), row(DURATION_FIELD)];

    const requiredFields = multipleVisitsCreationEnabled
      ? [
          VisitFormFields.departmentId.key,
          VisitFormFields.scheduledFor.key,
          VisitFormFields.endDate.key
        ]
      : [VisitFormFields.departmentId.key];

    const departmentsValueSet = departmentsResponse.data.map(department => ({
      value: department.id,
      label: department.name
    }));

    const DEPARTMENT_FIELD = {
      component: MUIFormComponentNames.SelectInput,
      source: VisitFormFields.departmentId.key,
      options: {
        disabled: departmentsResponse.loading || isFieldDisabled(VisitFormFields.departmentId.key),
        isRequired: true,
        className: classes.selectField,
        label: VisitFormFields.departmentId.value,
        placeholder: '-',
        valueSet: departmentsValueSet,
        isSearchable: true
      }
    };

    const PRIMARY_TECH_FIELD = {
      component: MUIFormComponentNames.SelectInput,
      source: VisitFormFields.primaryTechId.key,
      options: {
        disabled: techsLoading || isFieldDisabled(VisitFormFields.primaryTechId.key),
        className: classes.selectField,
        label: VisitFormFields.primaryTechId.value,
        placeholder: '-',
        valueSet: primaryTechOptions,
        isSearchable: true
      }
    };

    const EXTRA_TECHS_NUMBER_FIELD = {
      component: MUIFormComponentNames.TextInput,
      source: VisitFormFields.extraTechsNumber.key,
      options: {
        disabled: isFieldDisabled(VisitFormFields.extraTechsNumber.key),
        label: VisitFormFields.extraTechsNumber.value,
        type: 'integer',
        min: 0,
        step: 1
      }
    };

    // TODO: implement fixed fields for working update scenario
    // https://react-select.com/home#fixed-options
    const EXTRA_TECHS_FIELD = {
      component: MUIFormComponentNames.SelectInput,
      source: VisitFormFields.extraTechs.key,
      options: {
        disabled: techsLoading || isFieldDisabled(VisitFormFields.extraTechs.key),
        label: VisitFormFields.extraTechs.value,
        placeholder: '-',
        valueSet: extraTechOptions,
        isMultipleSelection: true,
        height: 'auto',
        isSearchable: true
      }
    };

    const TECH_STATUSES = {
      component: CustomFormComponents.TechStatuses,
      options: {
        techs,
        techsLoading,
        visit,
        schedules
      }
    };

    // prettier flattens the hierarchy making it hard to read
    /* eslint-disable prettier/prettier */
    let configObj = {
      fields: {},
      layouts: {
        default: {
          contents: [
            {
              component: CustomFormComponents.AccordionSection,
              options: {
                heading: 'Visit Details',
                expanded: !collapseDetails,
                className: classes.detailsSection
              },
              contents: [
                row([DESCRIPTION_FIELD]),
                colRow([col('80%', [TODO_FIELD]), col('20%', [TODO_DONE_FIELD])]),
                row([CUSTOMER_NAME]),
                colRow([col('50%', [PROPERTY_ADDRESS]), col('50%', [PROPERTY_REP])])
              ]
            },
            {
              component: CustomFormComponents.AccordionSection,
              options: {
                heading: 'Scheduling',
                expanded: true
              },
              contents: schedulingSectionContent
            },
            {
              component: CustomFormComponents.AccordionSection,
              options: {
                heading: 'Department & Technicians',
                expanded: true
              },
              contents: [
                row([DEPARTMENT_FIELD]),
                row([PRIMARY_TECH_FIELD]),
                row([EXTRA_TECHS_NUMBER_FIELD]),
                row([EXTRA_TECHS_FIELD])
              ]
            },
            {
              component: showTechStatuses ? CustomFormComponents.AccordionSection : null,
              options: {
                maxHeight: 'none',
                heading: 'Technician Statuses',
                bottomBorder: false,
                expanded: true
              },
              contents: [row([TECH_STATUSES])]
            }
          ]
        }
      },
      validation: {
        type: 'object',
        properties: {
          [VisitFormFields.departmentId.key]: {
            type: 'string',
            nullable: true
          },
          [VisitFormFields.scheduledFor.key]: {
            type: 'string',
            nullable: true
          },
          [VisitFormFields.endDate.key]: {
            type: 'string',
            nullable: true
          }
        },
        required: requiredFields
      },
      validationErrors: {
        [VisitFormFields.departmentId.key]: {
          required: 'Department is required',
          type: 'Department must be a string'
        },
        [VisitFormFields.scheduledFor.key]: {
          required: 'Start Date is required',
          type: 'Start Date must be a string'
        },
        [VisitFormFields.endDate.key]: {
          required: 'End Date is required',
          type: 'End Date must be a string'
        }
      }
    };

    // the following 2 if statements are ugly. Conditionally adding the below inline didn't work
    if (crewMembersAdded) {
      configObj = R.evolve({
        layouts: {
          default: {
            contents: R.insert(2, {
              component: MUIFormComponentNames.InlineAlert,
              options: {
                type: InlineAlertTypes.BLUE,
                message: `${visit.primaryTechName} crew members were added as additional technicians.`
              }
            })
          }
        }
      }, configObj);
    }

    if (crewTimeTracking) {
      const contentIndex = crewMembersAdded ? 3 : 2

      configObj = R.evolve({
        layouts: {
          default: {
            contents: R.update(contentIndex, R.evolve({
              contents: R.remove(2, 1)
            }, configObj.layouts.default.contents[contentIndex]))
          }
        }
      }, configObj);
    }

    return configObj;
    /* eslint-enable prettier/prettier */
  }, [
    visit,
    allowMultipleVisitsCreation,
    disabled,
    multipleVisitsCreateInProcess,
    multipleVisitsCreationEnabled,
    onCreateMultipleVisitsChange,
    departmentsResponse.data,
    departmentsResponse.loading,
    classes.selectField,
    classes.detailsSection,
    techsLoading,
    primaryTechOptions,
    extraTechOptions,
    techs,
    schedules,
    collapseDetails,
    crewMembersAdded,
    crewTimeTracking,
    showTechStatuses,
    theme,
    visitLoading,
    job
  ]);
};
