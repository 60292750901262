import gql from 'graphql-tag';

const deleteFormData = gql`
  mutation deleteFormData($input: DeleteFormDataInput!) {
    deleteFormData(input: $input) {
      id
    }
  }
`;

export default deleteFormData;
