export const deconstructMetaLayout = (initialData, formMetaData) => {
  const newData = { ...initialData };
  let meta;
  let assetMap;
  let searchableFields;
  if (Array.isArray(formMetaData)) {
    [meta, , assetMap] = formMetaData;
  } else {
    [meta] = formMetaData.meta;
    assetMap = formMetaData.assetMap;
    searchableFields = formMetaData.searchableFields;
  }

  newData.formAssetList = assetMap;
  newData.searchableFields = searchableFields;
  const { layouts, validation, validationErrors } = meta;
  const { contents } = layouts.default;

  for (const row of contents) {
    const rowIndex = initialData.rows.length;
    const rowItems = [];
    let numberOfItems = 0;
    for (const column of row.contents) {
      const itemContent = column.contents[0];
      const { component } = itemContent;
      if (itemContent.options) {
        const item = {
          id: itemContent.source,
          controlType: component.edit,
          viewControlType: component.default || component.edit,
          ...itemContent.options
        };
        rowItems.push(item.id);
        numberOfItems += 1;
        newData.items[item.id] = item;
      } else {
        rowItems.push(null);
      }
    }
    newData.layout.push(rowIndex, rowIndex + 1);
    newData.rows.push({
      itemIds: rowItems,
      numberOfColumns: rowItems.length,
      columnWidth: `${100 / rowItems.length}%`,
      availableColumns: rowItems.length - numberOfItems
    });
    newData.rows.push({
      itemIds: [null],
      numberOfColumns: 1,
      columnWidth: '100%',
      availableColumns: 1
    });
  }

  for (const itemID of validation.required) {
    newData.items[itemID].isRequired = true;
  }
  for (const itemID in validationErrors) {
    if (validationErrors[itemID] !== undefined) {
      const item = validationErrors[itemID];
      newData.items[itemID].errorMsg = item.required;
    }
  }

  return newData;
};
