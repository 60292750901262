import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { snackbarOn } from 'redux/actions/globalActions';
import { PageHeader, ConfirmModal, UserPermission, Spinner } from 'components';
import CreateEntryButton from 'components/Buttons/CreateEntryButton';
import { PermissionConstants } from 'utils/AppConstants';
import InputFilter from 'components/InputFilter';
import ResponsiveTable from 'components/ResponsiveTable';
import ErrorBoundaries from 'scenes/Error';
import { truckListMeta } from 'meta/Inventory/tables';
import useEmployees from 'customHooks/useEmployees';
import filterByIds from 'utils/filterByIds';
import { searchIndex } from 'constants/algoliaIndex';
import { deleteTruck, getAllActiveTrucks } from './truck-service';
import TruckForm from './TruckModal';

function InventoryLayout(props) {
  document.title = 'BuildOps - Inventory';
  const { user } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [trucks, setTrucks] = useState([]);
  const [openTruckForm, setOpenTruckForm] = useState({ open: false, data: '' });
  const [confirmModalSetting, setConfirmModalSetting] = useState({});
  const [globalFilters, setGlobalFilters] = React.useState({});
  const [refreshCounter, setRefreshCounter] = React.useState(0);
  const [unassignedEmployeeIds, setUnassignedEmployeeIds] = React.useState([]);

  const pageButtons = [
    <UserPermission I="create" action={PermissionConstants.OBJECT_INVENTORTY_TRUCK}>
      <CreateEntryButton
        label="New truck"
        handleAdd={() => setOpenTruckForm({ open: true, data: '' })}
        key="newtruck"
      />
    </UserPermission>
  ];

  const fetchTrucks = async (filter, limit, offset, sortBy, sortOrder) => {
    if (!user.tenantId) {
      return { items: [], nextToken: '0' };
    }

    if (!_.isEqual(globalFilters?.filterConditions, filter)) {
      setGlobalFilters({ filterConditions: '', globalFilterName: 'All' });
    }
    setIsLoading(true);
    const companySortKey = `${user.tenantId}_Company_${user.tenantCompanyId}`;
    const { items } = await getAllActiveTrucks(
      user.tenantId,
      companySortKey,
      filter,
      limit,
      offset,
      sortBy,
      sortOrder,
      props.snackbarOn
    );

    const usedEmployeeIds = [];
    items.forEach(truck => {
      const truckEmployees = truck?.truckEmployees || [];
      truckEmployees.forEach(truckEmp => {
        if (truckEmp?.employee?.id) {
          usedEmployeeIds.push(truckEmp.employee.id);
        }
      });
    });

    const uniqEmployeeIds = _.uniq(usedEmployeeIds);
    setUnassignedEmployeeIds(uniqEmployeeIds);
    setTrucks(items);
    setIsLoading(false);
  };

  const handleRowActions = (actionName, record) => {
    if (actionName === 'delete') {
      setConfirmModalSetting({
        confirmMessage: `truck - ${record.name}`,
        open: true,
        cancelAction: () => setConfirmModalSetting({ open: false, confirmMessage: '' }),
        action: async () => {
          await deleteTruck(record.id, record.name, props.snackbarOn);
          setConfirmModalSetting({ open: false, confirmMessage: '', cancelAction: '', action: '' });
          setRefreshCounter(refreshCounter + 1);
        }
      });
    }

    if (actionName === 'edit') {
      setOpenTruckForm({ open: true, data: record });
    }
  };

  const [activeEmployees] = useEmployees({
    filter: { isActive: { eq: true } }
  });

  useEffect(() => {
    fetchTrucks();
    // eslint-disable-next-line
  }, [refreshCounter]);

  const getUnassignedEmplyoees = () => {
    const unassignedEmp = activeEmployees.filter(emp => !unassignedEmployeeIds.includes(emp.id));
    return unassignedEmp;
  };

  return (
    <ErrorBoundaries>
      <UserPermission I="view" action={PermissionConstants.OBJECT_INVENTORTY_TRUCK}>
        <Grid container>
          <PageHeader
            pageMapKey="inventory"
            userLocale={user.locale}
            overrideHeaderButtons={pageButtons}
          />
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <InputFilter
              refreshCounter={refreshCounter}
              searchIndex={searchIndex}
              user={user}
              placeholder="Filter truck list..."
              filters={[
                {
                  attribute: 'entityType',
                  valueArray: ['Truck']
                },
                {
                  attribute: 'isActive',
                  valueArray: [true]
                },
                {
                  attribute: '_tags',
                  valueArray: [user.tenantId]
                }
              ]}
              refineList={[
                {
                  attribute: 'departments',
                  label: 'Departments'
                },
                {
                  attribute: 'productBundleName',
                  label: 'Inventory Bundle'
                }
              ]}
              overrideVariant="outlined"
            >
              {({ ids, hasSearch }) => {
                if (isLoading) return <Spinner />;
                return (
                  <ResponsiveTable
                    rowMetadata={truckListMeta}
                    fullScreen
                    data={filterByIds(trucks, ids, hasSearch)}
                    filter={
                      globalFilters?.filterConditions
                        ? { ...globalFilters?.filterConditions }
                        : null
                    }
                    disableFilter
                    noDataMsg="No trucks"
                    caslKey={PermissionConstants.OBJECT_INVENTORTY_TRUCK}
                    rowActions={handleRowActions}
                    rowActionButtons={{
                      edit: {
                        label: 'Edit',
                        icon: 'BorderColor',
                        caslAction: 'update',
                        caslKey: PermissionConstants.OBJECT_INVENTORTY_TRUCK
                      },
                      delete: {
                        label: 'Delete',
                        icon: 'Delete',
                        caslAction: 'delete',
                        caslKey: PermissionConstants.OBJECT_INVENTORTY_TRUCK
                      }
                    }}
                    key={refreshCounter}
                  />
                );
              }}
            </InputFilter>
          </Grid>
        </Grid>
        <TruckForm
          openTruckForm={openTruckForm.open}
          refetchData={() => setRefreshCounter(refreshCounter + 1)}
          data={openTruckForm.data}
          activeEmployees={activeEmployees}
          unassignedEmployees={getUnassignedEmplyoees()}
          handleClose={() => setOpenTruckForm({ open: false, data: '' })}
        />

        <ConfirmModal
          open={confirmModalSetting.open}
          confirm={confirmModalSetting.action}
          cancel={confirmModalSetting.cancelAction}
          message={confirmModalSetting.confirmMessage}
        />
      </UserPermission>
    </ErrorBoundaries>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const ReduxConnectedInventoryLayout = connect(
  mapStateToProps,
  mapDispatcherToProps
)(InventoryLayout);

export default withStyles({ withTheme: true })(ReduxConnectedInventoryLayout);
