import { PermissionConstants } from 'utils/AppConstants';

const employeeLayout = {
  entity: {
    name: 'employee',
    tenant: 'default',
    layouts: {
      web: {
        sections: [
          {
            title: 'Personal info',
            sm: '12',
            md: '12',
            lg: '12',
            showImage: 'profilePictureUrl',
            rows: [
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbHidden',
                    value: 'addPhoto',
                    name: 'addPhoto',
                    label: 'addPhoto',
                    behavior: {
                      view: {
                        ui: 'Image',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'profilePictureUrl',
                    name: 'profilePictureUrl',
                    label: 'addPhoto',
                    behavior: {
                      new: {
                        ui: 'LogoButton',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'LogoButton',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'firstName',
                    name: 'firstName',
                    label: 'mandatoryFirstName',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      view: {
                        ui: 'HighlightText',
                        sm: '12',
                        md: '4',
                        lg: '4'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'lastName',
                    name: 'lastName',
                    label: 'mandatoryLastName',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      view: {
                        ui: 'HighlightText',
                        sm: '12',
                        md: '4',
                        lg: '7'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'nickName',
                    name: 'nickName',
                    label: 'bhName',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'home',
                    name: 'home',
                    label: 'address',
                    behavior: {
                      view: {
                        ui: 'CombinedAddress',
                        sm: '12',
                        md: '4',
                        lg: '4'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'cellPhone',
                    name: 'cellPhone',
                    label: 'personalPhone',
                    behavior: {
                      view: {
                        ui: 'Label',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'personalEmail',
                    name: 'personalEmail',
                    label: 'personalEmail',
                    behavior: {
                      view: {
                        ui: 'Label',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'code',
                    name: 'code',
                    label: 'employeeCode',
                    behavior: {
                      view: {
                        ui: 'Label',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  }
                ]
              }
            ]
          },
          {
            title: '',
            sm: '12',
            md: '12',
            lg: '12',
            rows: [
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbField',
                    value: 'homeAddressLine1',
                    name: 'homeAddressLine1',
                    label: 'homeAddressLine1',
                    specialBehaviour: {
                      addressLine1: 'homeAddressLine1',
                      addressLine2: 'homeAddressLine2',
                      city: 'homeCity',
                      state: 'homeState',
                      zipcode: 'homeZipcode',
                      latitude: 'homeLatitude',
                      longitude: 'homeLongitude',
                      isMandatory: false
                    },
                    behavior: {
                      new: {
                        ui: 'AutoCompleteAddress',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      },
                      edit: {
                        ui: 'AutoCompleteAddress',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      }
                    }
                  }
                ]
              }
            ]
          },
          {
            title: '',
            sm: '12',
            md: '12',
            lg: '12',
            rows: [
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbField',
                    value: 'cellPhone',
                    name: 'cellPhone',
                    label: 'optionalPhone',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },

                  {
                    type: 'dbField',
                    value: 'personalEmail',
                    name: 'personalEmail',
                    label: 'optionalEmail',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'code',
                    name: 'code',
                    label: 'employeeCode',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  }
                ]
              }
            ]
          },
          {
            title: 'Employee settings',
            sm: '12',
            md: '12',
            lg: '12',
            rows: [
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbField',
                    value: 'landlinePhone',
                    name: 'landlinePhone',
                    label: 'workPhone',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      view: {
                        ui: 'Label',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },

                  {
                    type: 'dbField',
                    value: 'email',
                    name: 'email',
                    label: 'workEmail',
                    specialBehaviour: {
                      disableFieldWhen: 'status',
                      disableFieldValue: ['invited', 'activated', 'deactivated', 'active']
                    },
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      view: {
                        ui: 'Label',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'userName',
                    name: 'userName',
                    label: 'userName',
                    specialBehaviour: {
                      includeQueryResult: true,
                      disableFieldWhen: 'status',
                      disableFieldValue: ['invited', 'activated', 'deactivated', 'active'],
                      validation: 'checkUsernameExists'
                    },
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'processedRole',
                    name: 'processedRole',
                    label: 'mandatoryRole',
                    specialBehaviour: {
                      includeQueryResult: true,
                      queryPath: 'appRolesList',
                      fieldName: 'tagName',
                      selectedValueKey: 'roleString',
                      databaseId: 'roleId',
                      databaseMapping: 'employee.roleMapping'
                    },
                    behavior: {
                      new: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'roleString',
                    name: 'roleString',
                    label: 'role',
                    specialBehaviour: {
                      includeQueryResult: true,
                      queryPath: 'appRolesList',
                      fieldName: 'tagName',
                      selectedValueKey: 'roleString'
                    },
                    behavior: {
                      view: {
                        ui: 'Label',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'departmentString',
                    name: 'departmentString',
                    label: 'departments',
                    specialBehaviour: {
                      includeQueryResult: true,
                      queryPath: 'departmentList',
                      fieldName: 'departmentName',
                      selectedValueKey: 'departmentString'
                    },
                    behavior: {
                      view: {
                        ui: 'Label',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  }
                ]
              },
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbArrayField',
                    value: 'processedDepartments',
                    name: 'processedDepartments',
                    label: 'departments',
                    specialBehaviour: {
                      includeQueryResult: true,
                      queryPath: 'departmentList',
                      fieldName: 'tagName',
                      // reference to the hidden field in the form
                      databaseMapping: 'departmentSkillMapping',
                      databaseId: 'deparmentIdsinDB',
                      deleteService: 'deleteDepartmentForEmployee'
                    },
                    behavior: {
                      new: {
                        ui: 'MultiDropdown',
                        sm: '12',
                        md: '12',
                        lg: '6'
                      },
                      edit: {
                        ui: 'MultiDropdown',
                        sm: '12',
                        md: '12',
                        lg: '6'
                      }
                    }
                  },
                  // Note: due to UI position the field will be repeated 3 times
                  {
                    type: 'dbArrayField',
                    value: 'processedSkills',
                    name: 'processedSkills',
                    label: 'skills',
                    specialBehaviour: {
                      includeQueryResult: true,
                      // Taken from dependent field data
                      queryPath: 'skillsMap',
                      fieldName: 'tagName',
                      // field name in meta
                      dependentFieldName: 'processedDepartments',
                      dependentQueryPath: 'departmentsList',
                      dependentReferenceKey: 'id'
                    },
                    behavior: {
                      new: {
                        ui: 'MultiDropdown',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'nickName',
                    name: 'nickName',
                    label: 'bhName',
                    behavior: {
                      view: {
                        ui: 'Label',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'status',
                    name: 'loginStatusDisplay',
                    label: 'loginStatus',
                    behavior: {
                      edit: {
                        ui: 'ReadOnly',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      view: {
                        ui: 'Label',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'activeStatus',
                    name: 'activeStatus',
                    label: 'status',
                    behavior: {
                      edit: {
                        ui: 'ReadOnly',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      view: {
                        ui: 'Label',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'isAdmin',
                    name: 'isAdmin',
                    label: 'makeAdmin',
                    behavior: {
                      view: {
                        ui: 'Label',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'isTech',
                    name: 'isTech',
                    label: 'isTech',
                    behavior: {
                      view: {
                        ui: 'Label',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'isSales',
                    name: 'isSales',
                    label: 'isSales',
                    behavior: {
                      view: {
                        ui: 'Label',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'inviteToBh',
                    name: 'inviteToBh',
                    label: 'inviteToBh',
                    showField: 'isCurrentUserAdmin',
                    specialBehaviour: {
                      includeQueryResult: true
                    },
                    behavior: {
                      edit: {
                        ui: 'EmployeeLoginInvite',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbArrayField',
                    value: 'processedSkills',
                    name: 'processedSkills',
                    label: 'skills',
                    specialBehaviour: {
                      includeQueryResult: true,
                      // Taken from dependent field data
                      queryPath: 'skillsMap',
                      fieldName: 'tagName',
                      // field name in meta
                      dependentFieldName: 'processedDepartments',
                      dependentQueryPath: 'departmentsList',
                      dependentReferenceKey: 'id',
                      // reference to the hidden field in the form
                      databaseMapping: 'skillMapping',
                      databaseId: 'skillIdsinDB'
                    },
                    behavior: {
                      edit: {
                        ui: 'MultiDropdown',
                        sm: '12',
                        md: '6',
                        lg: '6'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'isAdmin',
                    name: 'isAdmin',
                    label: 'makeAdmin',
                    showField: 'isCurrentUserAdmin',
                    specialBehaviour: {
                      includeQueryResult: true,
                      validate: false
                    },
                    behavior: {
                      edit: {
                        ui: 'BuildOpsMakeAdmin',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'status',
                    name: 'status',
                    showField: 'isCurrentUserAdmin',
                    specialBehaviour: {
                      includeQueryResult: true,
                      validate: true
                    },
                    behavior: {
                      edit: {
                        ui: 'EmployeeLoginToggle',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'isActive',
                    name: 'isActive',
                    showField: 'isCurrentUserAdmin',
                    specialBehaviour: {
                      includeQueryResult: true,
                      validate: true
                    },
                    behavior: {
                      edit: {
                        ui: 'EmployeeActivationToggle',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'isTech',
                    name: 'isTech',
                    label: 'isTech',
                    behavior: {
                      new: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'isSales',
                    name: 'isSales',
                    label: 'isSales',
                    behavior: {
                      new: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  /*
                  {
                    type: 'dbField',
                    value: 'isCrewMember',
                    name: 'isCrewMember',
                    label: 'isCrewMember',
                    behavior: {
                      new: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      },
                      edit: {
                        ui: 'Checkbox',
                        sm: '12',
                        md: '4',
                        lg: '3'
                      }
                    }
                  },
                  */
                  {
                    type: 'dbHidden',
                    value: 'homeAddressId',
                    name: 'homeAddressId',
                    label: 'id',
                    behavior: {
                      new: {
                        ui: 'Hidden',
                        sm: '1',
                        md: '1',
                        lg: '1'
                      },
                      edit: {
                        ui: 'Hidden',
                        sm: '1',
                        md: '1',
                        lg: '1'
                      }
                    }
                  },
                  {
                    type: 'dbHidden',
                    value: 'roleId',
                    name: 'roleId',
                    label: 'id',
                    behavior: {
                      new: {
                        ui: 'Hidden',
                        sm: '1',
                        md: '1',
                        lg: '1'
                      },
                      edit: {
                        ui: 'Hidden',
                        sm: '1',
                        md: '1',
                        lg: '1'
                      }
                    }
                  },
                  {
                    type: 'dbHidden',
                    value: 'departmentId',
                    name: 'departmentId',
                    label: 'id',
                    behavior: {
                      new: {
                        ui: 'Hidden',
                        sm: '1',
                        md: '1',
                        lg: '1'
                      },
                      edit: {
                        ui: 'Hidden',
                        sm: '1',
                        md: '1',
                        lg: '1'
                      }
                    }
                  },
                  {
                    type: 'dbHidden',
                    value: 'skillsId',
                    name: 'skillsId',
                    label: 'id',
                    behavior: {
                      new: {
                        ui: 'Hidden',
                        sm: '1',
                        md: '1',
                        lg: '1'
                      },
                      edit: {
                        ui: 'Hidden',
                        sm: '1',
                        md: '1',
                        lg: '1'
                      }
                    }
                  },
                  {
                    type: 'dbHidden',
                    value: 'id',
                    name: 'id',
                    label: 'id',
                    behavior: {
                      new: {
                        ui: 'Hidden',
                        sm: '1',
                        md: '1',
                        lg: '1'
                      },
                      edit: {
                        ui: 'Hidden',
                        sm: '1',
                        md: '1',
                        lg: '1'
                      }
                    }
                  },
                  {
                    type: 'rowHeading',
                    value: 'skills',
                    name: 'skills',
                    label: 'skills',
                    behavior: {
                      view: {
                        sm: '12',
                        md: '12',
                        lg: '12'
                      }
                    }
                  },
                  {
                    type: 'dbArrayField',
                    value: 'processedSkillsForView',
                    name: 'processedSkillsForView',
                    label: 'skills',
                    specialBehaviour: {
                      includeQueryResult: true,
                      // Taken from dependent field data
                      queryPath: 'skillsMap',
                      fieldName: 'tagName',
                      // field name in meta
                      dependentFieldName: 'processedDepartments',
                      dependentQueryPath: 'departmentsList',
                      dependentReferenceKey: 'id'
                    },
                    behavior: {
                      view: {
                        ui: 'Label',
                        sm: '12',
                        md: '4',
                        lg: '2'
                      }
                    }
                  }
                ]
              }
            ]
          }
        ],
        buttons: {
          save: {
            label: 'Save Changes',
            color: 'primary',
            buttonType: 'contained',
            caslKey: PermissionConstants.OBJECT_EMPLOYEE,
            behavior: {
              new: true,
              edit: true
            }
          },
          cancel: {
            label: 'Cancel',
            color: 'secondary',
            buttonType: 'contained',
            behavior: {
              new: true,
              edit: true
            }
          }
        }
      },
      search: null
    }
  }
};

export const employeeRows = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: true,
    label: 'First name'
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: true,
    label: 'Last name'
  },
  {
    id: 'roleString',
    numeric: false,
    disablePadding: true,
    label: 'Role'
  },
  {
    id: 'departmentString',
    numeric: false,
    disablePadding: true,
    label: 'Department(s)'
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: 'Email'
  },
  {
    id: 'userName',
    numeric: false,
    disablePadding: true,
    label: 'User name'
  },
  {
    id: 'landlinePhone',
    numeric: false,
    disablePadding: true,
    label: 'Work phone'
  },
  {
    id: 'loginStatusDisplay',
    numeric: false,
    disablePadding: true,
    label: 'Login Status'
  },
  {
    id: 'activeStatus',
    numeric: false,
    disablePadding: true,
    label: 'Status'
  }
];

export const tableProperties = {
  dataPathInQueryResult: 'employeeWithDepartmentSkillAndRoles',
  idField: 'id',
  captionAttributes: employeeRows,
  noDataMsg: 'No employees'
};

export default employeeLayout;
