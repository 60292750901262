import gql from 'graphql-tag';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import { generateCompanySortKey } from 'services/core/utils';

const GET_AUTOMATED_TIME_TRACKING_PAYROLL_SETTINGS = gql`
  query getAutomatedTimeTrackingSettings($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      companySettings(
        entityConnection: "CompanySetting"
        filter: { type: { eq: "PAYROLL" }, isActive: { eq: true } }
      ) {
        items {
          id
          version
          settings
        }
      }
    }
  }
`;

const transform = data => {
  const settings = data?.getCompany?.companySettings?.items?.[0]?.settings;
  return settings ? JSON.parse(settings) : {};
};

export const usePayrollSettings = user => {
  return useExtendedQuery(GET_AUTOMATED_TIME_TRACKING_PAYROLL_SETTINGS, {
    transform,
    variables: {
      partitionKey: user.tenantId,
      sortKey: generateCompanySortKey(user.tenantId, user.tenantCompanyId)
    }
  });
};
