import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '99%',
    '& [class*="MuiAutocomplete-inputRoot"]': {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 0,
      height: 45,
      borderRadius: 0
    },
    '& .Mui-disabled': {
      color: theme.palette.grayscale(20)
    }
  }
}));
