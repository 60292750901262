/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import { TV, Typography } from '@buildhero/sergeant';

const useStyles = ({ color }) => ({
  tag: ({ palette }) => ({
    backgroundColor: color || palette.grayscale(90),
    borderRadius: 2,
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 4,
    paddingLeft: 4,
    width: 'fit-content'
  })
});

export const TagCell = ({ formattedValue, color }) => {
  const styles = useStyles({ color });

  if (!formattedValue) return null;

  return (
    <div style={{ maxHeight: 16, display: 'flex', alignItems: 'center' }}>
      <Typography variant={TV.S1} css={styles.tag}>
        {formattedValue}
      </Typography>
    </div>
  );
};
