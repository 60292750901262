import gql from 'graphql-tag';

const addPaymentTypesToCompany = gql`
  mutation addPaymentTypesToCompany($partitionKey: String!, $data: AddPaymentTypesToCompanyInput!) {
    addPaymentTypesToCompany(partitionKey: $partitionKey, data: $data) {
      id
      name
      version
      accountingRefId
      lastUpdatedDateTime
      ledgerAccountId
      ledgerAccount(entityConnection: "LedgerAccount") {
        name
        accountNumber
        accountType
        accountingRefId
        accountingApplication
        id
      }
    }
  }
`;

export default addPaymentTypesToCompany;
