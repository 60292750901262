import React, { useCallback, useMemo, useRef, useState } from 'react';

import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/MoreVert';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { bool, func } from 'prop-types';

import { VISIT_DETAILS_PROP } from '@dispatch/queries';
import { PopperMenu } from 'components';
import { getState } from 'redux/store';
import CreateFieldOrderModal from 'scenes/Procurement/PurchaseOrders/CreateFieldOrder';
import CreatePurchaseOrderModal from 'scenes/Procurement/PurchaseOrders/CreatePurchaseOrder';
import { checkPermission } from 'utils';
import { PermissionConstants } from 'utils/AppConstants';

import {
  CloneVisitMenuItem,
  CompleteProjectVisit,
  ContinueVisitAsNewMenuItem,
  CreateFieldOrderMenuItem,
  CreatePurchaseOrderMenuItem,
  DeleteAllMenuItem,
  DeleteMenuItem,
  ReleaseToTechMenuItem
} from '../MenuItems';

import { useInitialData } from './Menu.hooks';
import { useStyles } from './Menu.styles';

const insertIf = (condition, ...items) => (condition ? items : []);

const Menu = ({
  disabled,
  disabledDelete,
  disabledComplete,
  onClone,
  onContinueAsNew,
  onDelete,
  onDeleteAll,
  onReleaseToTech,
  visit,
  showProcurement,
  onCompleteProjectVisit,
  refetchVisitData
}) => {
  const classes = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPoModalOpen, setIsPoModalOpen] = useState(false);
  const [isFoModalOpen, setIsFoModalOpen] = useState(false);
  const menuIconRef = useRef();
  const initialData = useInitialData(visit);
  const { procurement: procurementEnabled } = useFlags();
  const canReadPurchaseOrder = checkPermission(
    'read',
    PermissionConstants.OBJECT_PURCHASE_ORDER,
    getState().user
  );
  const shouldRenderProcurementLinks =
    showProcurement && procurementEnabled && canReadPurchaseOrder;

  const handleMenuClick = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  const handleMenuClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const handleCreatePo = useCallback(() => {
    setIsPoModalOpen(true);
  }, []);

  const handleClosePo = useCallback(po => {
    if (po?.entityType === 'PurchaseOrder') {
      refetchVisitData();
    }
    setIsPoModalOpen(false);
  }, []);

  const handleCreateFo = useCallback(() => {
    setIsFoModalOpen(true);
  }, []);

  const handleCloseFo = useCallback(fo => {
    if (fo?.entityType === 'PurchaseOrder') {
      refetchVisitData();
    }
    setIsFoModalOpen(false);
  }, []);

  const menuButtons = useMemo(
    () => [
      ...insertIf(onReleaseToTech, {
        label: ReleaseToTechMenuItem,
        key: 'ReleaseToTechMenuItem',
        onClick: onReleaseToTech,
        className: classes.itemWithBottomDivider
      }),
      ...insertIf(onClone, {
        label: CloneVisitMenuItem,
        onClick: onClone
      }),
      ...insertIf(onContinueAsNew, {
        label: ContinueVisitAsNewMenuItem,
        key: 'ContinueVisitAsNewMenuItem',
        onClick: onContinueAsNew
      }),
      ...insertIf(shouldRenderProcurementLinks, {
        label: CreatePurchaseOrderMenuItem,
        key: 'CreatePurchaseOrderMenuItem',
        onClick: handleCreatePo,
        className: onClone || onContinueAsNew ? classes.itemWithTopDivider : undefined
      }),
      ...insertIf(shouldRenderProcurementLinks, {
        label: CreateFieldOrderMenuItem,
        key: 'CreateFieldOrderMenuItem',
        onClick: handleCreateFo
      }),
      ...insertIf(onDelete, {
        label: DeleteMenuItem,
        key: 'DeleteMenuItem',
        onClick: onDelete,
        disabled: disabledDelete
      }),
      ...insertIf(onDeleteAll, {
        label: DeleteAllMenuItem,
        key: 'DeleteAllMenuItem',
        onClick: onDeleteAll,
        disabled: disabledDelete
      }),
      ...insertIf(onCompleteProjectVisit, {
        label: CompleteProjectVisit,
        key: 'CompleteProjectVisit',
        onClick: onCompleteProjectVisit,
        disabled: disabledComplete
      })
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      onClone,
      onContinueAsNew,
      onDelete,
      onReleaseToTech,
      shouldRenderProcurementLinks,
      disabledDelete,
      disabledComplete
    ]
  );

  if (!menuButtons.length) {
    return null;
  }

  return (
    <div>
      <IconButton ref={menuIconRef} onClick={handleMenuClick} disabled={disabled} disableRipple>
        <MenuIcon />
      </IconButton>
      <PopperMenu
        itemList={menuButtons}
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorEl={menuIconRef.current}
      />
      {isPoModalOpen && (
        <CreatePurchaseOrderModal
          open={isPoModalOpen}
          handleClose={handleClosePo}
          initialData={initialData}
        />
      )}
      {isFoModalOpen && (
        <CreateFieldOrderModal
          open={isFoModalOpen}
          handleClose={handleCloseFo}
          initialData={initialData}
        />
      )}
    </div>
  );
};

Menu.propTypes = {
  disabled: bool,
  disabledDelete: bool,
  disabledComplete: bool,
  onClone: func,
  onContinueAsNew: func,
  onDelete: func,
  onDeleteAll: func,
  onReleaseToTech: func,
  onCompleteProjectVisit: func,
  visit: VISIT_DETAILS_PROP,
  showProcurement: bool,
  refetchVisitData: func
};

Menu.defaultProps = {
  disabled: false,
  disabledDelete: false,
  disabledComplete: false,
  onClone: null,
  onContinueAsNew: null,
  onDelete: null,
  onDeleteAll: null,
  onReleaseToTech: null,
  onCompleteProjectVisit: null,
  visit: null,
  showProcurement: false,
  refetchVisitData: () => {}
};

export default Menu;
