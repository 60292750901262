import gql from 'graphql-tag';

const updateAttachment = gql`
  mutation updateAttachment($partitionKey: String, $data: UpdateAttachmentInput!) {
    updateAttachment(partitionKey: $partitionKey, data: $data) {
      id
      sortKey
      customFileName
      fileName
      fileUrl
      type
      dateAdded
      description
      comment
      sortOrder
      createdBy
      createdDate
      addedBy
      addedDateTime
      version
      includeInInvoice
    }
  }
`;

export default updateAttachment;
