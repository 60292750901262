import gql from 'graphql-tag';

const syncJobCostCodes = gql`
  mutation syncJobCostCodes($partitionKey: String!, $data: SyncJobCostCodesInput!) {
    syncJobCostCodes(partitionKey: $partitionKey, data: $data) {
      id
      entityType
    }
  }
`;

export default syncJobCostCodes;
