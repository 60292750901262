export const ReasonLayout = {
  fields: {
    reason: {
      default: 'reason'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        direction: 'row',
        width: 450
      },
      contents: [
        {
          options: {
            direction: 'column'
          },
          contents: [
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'TextInput'
                  },
                  source: 'reason',
                  options: {
                    label: 'Reason',
                    isRequired: true,
                    lines: 4
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      reason: {
        type: 'string'
      }
    },
    required: ['reason']
  },
  validationErrors: {
    reason: {
      required: 'Reason is required.'
    }
  }
};

export default ReasonLayout;
