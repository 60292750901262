import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import Linkify from 'react-linkify';
import { CommonService } from 'services/core';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Logger } from 'services/Logger';
import { snackbarOn } from 'redux/actions/globalActions';
import AppConstants from 'utils/AppConstants';
import Spinner from 'components/Spinners/CircularIndeterminate';
import ConfirmModal from 'components/Modal/ConfirmDialog';

import styles from './styles';

class DisplayNotes extends Component {
  constructor(props) {
    super(props);
    this.CommonService = new CommonService();
    this.modalTop = React.createRef();
    this.state = {
      notesData: props.notesData,
      note: {},
      btnClicked: '',
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: ''
    };
  }

  handleSubjectChange = event => {
    let { note } = this.state;
    note = { ...note, subject: event.target.value };
    this.setState(prevState => ({ ...prevState, note: note }));
  };

  handleNoteChange = event => {
    let { note } = this.state;
    note = { ...note, note: event.target.value };
    this.setState(prevState => ({ ...prevState, note: note }));
  };

  handleOnEdit = noteItem => {
    this.modalTop.current.scrollIntoView();
    this.setState(prevState => ({ ...prevState, note: noteItem }));
  };

  deleteFromList = record => {
    const localNotesData = this.state.notesData;
    const modifiedNoteData = [];
    localNotesData.forEach(note => {
      if (note.id && note.id !== record.id) {
        modifiedNoteData.push(note);
      }
    });
    this.setState({ notesData: modifiedNoteData });
  };

  showDeleteWarning = note => {
    this.setState(prevState => ({
      ...prevState,
      confirmDialog: true,
      confirmAction: () => this.handleOnDelete(note),
      confirmMessage: 'note'
    }));
  };

  handleOnDelete = async note => {
    if (note.id) {
      try {
        const { partitionKey, sortKey } = note;
        const data = await this.CommonService.deleteNote(partitionKey, sortKey);

        if (data) {
          this.props.snackbarOn('success', 'Successfully deleted');
          this.deleteFromList(note);
          this.props.setRefresh();
          this.setState(prevState => ({
            ...prevState,
            confirmDialog: false,
            confirmAction: '',
            confirmMessage: ''
          }));
        }
      } catch (error) {
        Logger.error(error);
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          this.props.snackbarOn('error', error.graphQLErrors[0].message);
        } else {
          this.props.snackbarOn('error', 'Unable to delete, please try again later');
        }
      }
    } else if (this.props.deleteService) {
      try {
        const data = await this.props.deleteService(note);
        if (data) {
          this.props.snackbarOn('success', 'Successfully deleted');
          this.props.setRefresh();
          this.setState({
            notesData: data,
            confirmDialog: false,
            confirmAction: '',
            confirmMessage: ''
          });
        }
      } catch (error) {
        Logger.error(error);
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          this.props.snackbarOn('error', error.graphQLErrors[0].message);
        } else {
          this.props.snackbarOn('error', 'Unable to delete, please try again later');
        }
      }
    } else {
      this.deleteFromList(note);
    }
  };

  handleSave = async () => {
    let { note } = this.state;

    if (!note.note || (note.note && note.note.trim() === '')) {
      this.props.snackbarOn('error', 'Cannot save an empty note');
      this.setState(prevState => ({ ...prevState, btnClicked: '' }));
      return;
    }

    note = {
      ...note,
      parent: this.props.parent,
      entityType: 'Note',
      tenantId: this.props.user.tenantId,
      tenantCompanyId: this.props.user.tenantCompanyId
    };

    try {
      let data;

      if (this.props.mutateService) {
        data = await this.props.mutateService(note);
      } else {
        ({ data } = await this.CommonService.mutateNote(note));
      }

      if (data) {
        this.props.snackbarOn('success', 'Successfully posted');
        // const savedNote = { ...note, id: id, partitionKey: partitionKey, sortKey: sortKey };
        // this.setState({ mode: 'view', note: savedNote, oldNote: savedNote });
        this.props.refetch();
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to save changes, please try again later', error);
    }
  };

  handleCancelConfirmation = () => {
    this.setState(prevState => ({
      ...prevState,
      confirmDialog: false,
      confirmAction: '',
      confirmMessage: ''
    }));
  };

  render() {
    const { notesData } = this.state;
    const { classes, subtitle, isActive, isAllNotesModal } = this.props;
    return (
      <>
        {isActive && (
          <Grid container>
            <div ref={this.modalTop} />
            <Grid item xs={12} style={{ paddingTop: 4, paddingBottom: 20 }}>
              {subtitle && (
                <Typography variant="caption" className={classes.subtitle}>
                  {subtitle}
                </Typography>
              )}
            </Grid>
            <Grid item xs={4} style={{ paddingTop: 4, paddingBottom: 20 }}>
              <TextField
                variant="filled"
                fullWidth
                name="noteInput"
                disabled={!isActive}
                label="Subject"
                value={this.state.note.subject || ''}
                onChange={this.handleSubjectChange}
              />
            </Grid>
            <Grid item xs={10} style={{ paddingTop: 4, paddingBottom: 24 }}>
              <TextField
                variant="filled"
                multiline
                fullWidth
                rows="3"
                rowsMax="3"
                name="noteInput"
                label="Note"
                disabled={!isActive}
                value={this.state.note.note || ''}
                InputProps={{ style: { height: 88 } }}
                onChange={this.handleNoteChange}
              />
            </Grid>
            <Grid item style={{ padding: 5, paddingBottom: 30, marginLeft: '68%' }}>
              <Button
                onClick={() => this.props.handleCancel()}
                classes={{
                  outlinedSecondary: classes.buttonOutlinedSecondary
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  this.setState(prevState => ({ ...prevState, btnClicked: true }));
                  this.handleSave(this.state.note);
                }}
                disabled={this.state.btnClicked || !isActive || false}
                classes={{
                  outlinedSecondary: classes.buttonOutlinedSecondary
                }}
              >
                Save
                {this.state.btnClicked && <Spinner className={classes.buttonProgress} size={24} />}
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={1}>
          {isAllNotesModal &&
            notesData?.map(noteItem => (
              <Fragment key={`Frag${noteItem.id}`}>
                <Linkify>
                  <Grid item xs={3} sm={3} md={3} lg={3} xl={3} key={`GLeft${noteItem.id}`}>
                    <Grid container direction="column" key={`gLeftContainer${noteItem.id}`}>
                      <Grid item key={`gSub${noteItem.id}`}>
                        <Typography
                          variant="caption"
                          className={classes.subject}
                          key={`subject${noteItem.id}`}
                        >
                          {noteItem.subject || 'No subject'}
                        </Typography>
                      </Grid>
                      {noteItem && noteItem.lastUpdatedDateTime && (
                        <Grid item className={classes.footer} key={`footer${noteItem.id}`}>
                          <Typography
                            variant="caption"
                            className={classes.label}
                            key={`dt${noteItem.id}`}
                          >
                            {noteItem.lastUpdatedBy}, &nbsp;
                            <Moment
                              unix
                              format={AppConstants.DATETIME_FORMAT}
                              key={`dtconvert${noteItem.id}`}
                            >
                              {noteItem.lastUpdatedDateTime / 1000}
                            </Moment>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={7} sm={7} md={7} lg={7} xl={7} key={`GRight${noteItem.id}`}>
                    <Typography
                      variant="caption"
                      className={classes.content}
                      key={`note${noteItem.id}`}
                    >
                      {noteItem.note}
                    </Typography>
                  </Grid>
                  {isActive && (
                    <Grid item xs={2} key={`editNote${noteItem.id}`}>
                      <IconButton
                        aria-label="Edit"
                        className={classes.iconButton}
                        onClick={() => this.handleOnEdit(noteItem)}
                      >
                        <EditIcon className={classes.iconStyle} />
                      </IconButton>
                      <IconButton
                        aria-label="Delete"
                        className={classes.iconButton}
                        onClick={() => this.showDeleteWarning(noteItem)}
                      >
                        <DeleteIcon className={classes.iconStyle} />
                      </IconButton>
                    </Grid>
                  )}
                </Linkify>
              </Fragment>
            ))}
        </Grid>
        <ConfirmModal
          open={this.state.confirmDialog}
          confirm={this.state.confirmAction}
          cancel={this.handleCancelConfirmation}
          message={this.state.confirmMessage}
        />
      </>
    );
  }
}
const mapStateToProps = state => ({
  user: state.user
});

// utilizes global spinner actions
const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const connectedDisplayNotes = connect(mapStateToProps, mapDispatchToProps)(DisplayNotes);

export default withStyles(styles, { withTheme: true })(connectedDisplayNotes);
