const bundleEntryForm = (vendorList, salesAccountList) => ({
  fields: {
    name: { default: 'name' },
    description: { default: 'description' },
    glIncomeAccountRefId: {
      Taskedit: 'glIncomeAccountRefId',
      Tasknew: 'glIncomeAccountRefId'
    },
    vendorSortKey: {
      Taskedit: 'vendorSortKey',
      Tasknew: 'vendorSortKey'
    },
    code: {
      Taskedit: 'code',
      Tasknew: 'code'
    },
    taxable: {
      Taskedit: 'taxable',
      Tasknew: 'taxable'
    },
    unitCost: {
      Taskedit: 'unitCost',
      Tasknew: 'unitCost'
    },
    unitOfMeasureValue: { default: 'unitOfMeasureValue', Truckedit: 'unitOfMeasure' },
    minQuantity: { Truckedit: 'minQuantity', Trucknew: 'minQuantity' },
    maxQuantity: { Truckedit: 'maxQuantity', Trucknew: 'maxQuantity' }
  },
  layouts: {
    default: {
      options: { pageSize: 'LETTER', grow: 0, padding: [10, 0] },
      contents: [
        {
          options: { size: 3, direction: 'row', grow: 0, margin: [0, 0, 10, 0] },
          contents: [
            {
              options: { direction: 'column', grow: 1, padding: [2, 20, 2, 0] },
              contents: [
                {
                  component: { default: 'FieldWithLabel' },
                  source: 'name',
                  options: {
                    label: 'ITEM NAME',
                    placeholder: '',
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: { direction: 'column', grow: 1, padding: [2, 20, 2, 0] },
              contents: [
                {
                  component: { default: 'FieldWithLabel' },
                  source: 'description',
                  options: {
                    label: 'DESCRIPTION',
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: { direction: 'column', grow: 1, padding: [2, 20, 2, 0] },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel'
                  },
                  options: {
                    label: 'UOM'
                  },
                  source: 'unitOfMeasureValue'
                }
              ]
            }
          ]
        },
        {
          options: { size: 2, direction: 'row', grow: 0, padding: [0, 10, 0, 0] },
          contents: [
            {
              options: { direction: 'column', grow: 1, padding: [2, 20, 2, 0] },
              contents: [
                {
                  contents: [
                    {
                      component: { default: null, Tasknew: 'SelectInput', Taskedit: 'SelectInput' },
                      source: 'glIncomeAccountRefId',
                      options: {
                        label: 'SALES ACCOUNTS',
                        valueSet: salesAccountList,
                        placeholder: 'Select Sales account'
                      }
                    }
                  ]
                }
              ]
            },
            {
              contents: [
                {
                  contents: [
                    {
                      component: { default: null, Tasknew: 'SelectInput', Taskedit: 'SelectInput' },
                      source: 'vendorSortKey',
                      options: {
                        label: 'VENDOR',
                        valueSet: vendorList,
                        placeholder: 'Select vendor'
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          options: { size: 2, direction: 'row', grow: 0, padding: [0, 10, 0, 0] },
          contents: [
            {
              options: { direction: 'column', grow: 1, padding: [2, 20, 2, 0] },
              contents: [
                {
                  component: { default: null, Tasknew: 'TextInput', Taskedit: 'TextInput' },
                  source: 'code',
                  options: {
                    label: 'PRODUCT CODE'
                  }
                }
              ]
            },
            {
              options: { direction: 'column', grow: 1, align: 'center' },
              contents: [
                {
                  component: { default: null, Tasknew: 'TextInput', Taskedit: 'TextInput' },
                  source: 'quantity',
                  options: {
                    label: 'QUANTITY',
                    placeholder: '',
                    isRequired: true,
                    fullWidth: false,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0,
            margin: [10, 0, 0, 0],
            wrap: 'wrap',
            align: 'center'
          },
          contents: [
            {
              component: { default: null, Tasknew: 'CheckboxInput', Taskedit: 'CheckboxInput' },
              source: 'taxable',
              options: {
                label: 'TAXABLE'
              }
            }
          ]
        },
        {
          options: { size: 2, direction: 'row', grow: 0, margin: [-10, 0, 0, 0] },
          contents: [
            {
              options: { direction: 'column', grow: 1, padding: [2, 20, 2, 0] },
              contents: [
                {
                  component: { default: null, Trucknew: 'TextInput', Truckedit: 'TextInput' },
                  source: 'minQuantity',
                  options: {
                    label: 'MINIMUM QUANTITY',
                    placeholder: '',
                    type: 'number',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: { direction: 'column', grow: 1, padding: 2 },
              contents: [
                {
                  component: { default: null, Trucknew: 'TextInput', Truckedit: 'TextInput' },
                  source: 'maxQuantity',
                  options: {
                    label: 'MAXIMUM QUANTITY',
                    type: 'number',
                    placeholder: '',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  }
});

export default bundleEntryForm;
