import InstructionHelper from '../InstructionHelper';
import { removeNestedJson, removeNullValues, removeEmptyValues } from '../../utils';
import moment from 'moment';

const createScheduleWithTimesheetSchemaMapping = visit => {
  // added while processing query result
  const { employee } = visit;

  const processedVisit = removeEmptyValues(removeNullValues(visit));
  const strippedVisit = removeNestedJson(processedVisit);
  const schedule = {
    scheduledFor: visit.scheduledFor,
    startTime: visit.startTime,
    endTime: visit.endTime,
    onRoute: false,
    version: 1,
    employeeSortKey: employee.sortKey,
    entityType: 'Schedule',
    tenantId: visit.tenantId,
    tenantCompanyId: visit.tenantCompanyId
  };

  const timeSheet = {
    clockInTime: moment().unix(),
    visitDate: moment(new Date()).unix(),
    version: 1,
    entityType: 'TimeSheet',
    tenantId: schedule.tenantId,
    tenantCompanyId: schedule.tenantCompanyId,
    parentSortKey: schedule.sortKey
  };

  const data = [{ ...strippedVisit }, { ...schedule }, { ...timeSheet }];
  const ins = new InstructionHelper();
  let temp = ins.oneToMany(0, 1);
  temp = temp.oneToMany(1, 2);

  const { instructionSet } = temp;
  const ret = { data: JSON.stringify(data), instructionSet: JSON.stringify(instructionSet) };
  return ret;
};

export default createScheduleWithTimesheetSchemaMapping;
