import { theme } from '@buildhero/sergeant';

export default {
  redirect: {
    container: {
      display: 'flex',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 10000,
      backgroundColor: 'white'
    },
    logo: {
      margin: 'auto',
      marginTop: '13.33%',
      marginBottom: 0,
      width: 186.73,
      height: 32
    },
    center: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    progress: {
      marginBottom: 24,
      color: theme.palette.primary.main
    }
  }
};
