import * as React from 'react';
import {
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  useGridSlotComponentProps
} from '@mui/x-data-grid-pro';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { Grid, IconButton } from '@material-ui/core';
import { Button, ButtonType } from '@buildhero/sergeant';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { connect } from 'react-redux';
import { snackbarOn } from 'redux/actions/globalActions';
import { css, keyframes } from '@emotion/react';

import ViewsToolbarButton from './ViewsToolbarButton';
import ViewContext from '../ResponsiveTable/ViewContext';
import ColumnsToolbarButton from './ColumnsToolbarButton';
import { queryViews, getSettingObjectFromView } from '../ResponsiveTable/ViewHelper';
import FilterChips, { getValidFilters } from './FilterChips';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const paused = {
  'animation-play-state': 'paused'
};

const Toolbar = ({
  tableName,
  entityTypeName,
  handleViewChange,
  defaultSetting,
  user,
  refetch,
  enableRefreshBtn,
  enableExport = false,
  disableToolbar = false,
  onRefresh = async () => {},
  toolbarCustomComponent,
  rows
}) => {
  const { apiRef } = useGridSlotComponentProps();
  const [views, setViews] = React.useState(null);
  const [viewsLoaded, setViewsLoaded] = React.useState(false);
  const [refreshing, setRefereshing] = React.useState(false);
  const buttonMargin = { margin: '16px 8px 0 0' };
  const buttonWidth = enableRefreshBtn ? { width: 250 } : {};

  React.useEffect(() => {
    const queryViewsFromServer = async () => {
      setViewsLoaded(false);
      const queriedViews = await queryViews(user, tableName);
      // * Using Boolean to convert when isDefault is set as 1 instead of true
      const defaultViewForUser = queriedViews?.find(
        v => Boolean(v?.userSettingEmployees?.items?.[0]?.isDefault) === true
      );
      if (defaultViewForUser) {
        const defaultViewSetting = getSettingObjectFromView(defaultViewForUser);
        handleViewChange(defaultViewSetting);
      }
      setViews(queriedViews);
      setViewsLoaded(true);
    };

    if (tableName && user.tenantId) {
      queryViewsFromServer();
    } else {
      setViews([]);
      setViewsLoaded(true);
    }
  }, [tableName, user]);

  const validFilters = getValidFilters(apiRef.current.state.filter.items);
  if (disableToolbar) return null;
  return (
    <ViewContext.Provider value={{ views, setViews, viewsLoaded, setViewsLoaded }}>
      <GridToolbarContainer style={{ marginBottom: 0, alignItems: 'flex-end' }}>
        {tableName && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ViewsToolbarButton
              tableName={tableName}
              entityTypeName={entityTypeName}
              handleViewChange={handleViewChange}
              numberOfRows={250}
              defaultSetting={defaultSetting}
            />
          </div>
        )}
        <div style={{ ...buttonMargin, ...buttonWidth }}>
          <ColumnsToolbarButton />
        </div>
        <div style={{ ...buttonMargin, ...buttonWidth }}>
          <GridToolbarDensitySelector
            color="secondary"
            size="medium"
            startIcon={null}
            endIcon={<ArrowDropDownIcon />}
          />
        </div>
        <div style={{ ...buttonMargin, ...buttonWidth }}>
          <Button
            type={ButtonType.LEADING}
            color="secondary"
            endIcon={<ArrowDropDownIcon />}
            onClick={() =>
              apiRef.current.state.preferencePanel.open
                ? apiRef.current.hideFilterPanel()
                : apiRef.current.showFilterPanel()
            }
          >
            {validFilters.length ? `Filters (${validFilters.length})` : 'Filters'}
          </Button>
        </div>
        <div style={{ flex: '1 1 auto' }} /> {/* right align the rest */}
        {enableRefreshBtn && (
          <Grid container item justify="flex-end" key="refresh-grid">
            <IconButton
              onClick={async () => {
                setRefereshing(true);
                const promises = [onRefresh(), refetch()];
                await Promise.all(promises);
                setRefereshing(false);
              }}
              css={css`
                animation: ${spin} 2s linear infinite;
                ${!refreshing && paused}
              `}
              disabled={refreshing}
            >
              <AutorenewIcon color="primary" />
            </IconButton>
          </Grid>
        )}
        {toolbarCustomComponent && (
          <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', marginTop: 20 }}>
            {toolbarCustomComponent(rows, apiRef)}
          </div>
        )}
        {enableExport && (
          <div style={{ marginTop: 10, marginLeft: 16 }}>
            <GridToolbarExport
              variant="contained"
              color="secondary"
              size="medium"
              disableElevation
            />
          </div>
        )}
      </GridToolbarContainer>
      <FilterChips />
    </ViewContext.Provider>
  );
};

const mapStateToProps = state => ({
  user: state.user
});
const mapDispatchToProps = { snackbarOn };

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
