import AmplifyService from 'services/AmplifyService';
import CommonService from '../Common/CommonService';
import upsertPaymentsToCompany from '../../graphql/payment/mutations/upsertPaymentsToCompany';
import softDeletePaymentById from '../../graphql/payment/mutations/deletePayment';
import getPaymentById from '../../graphql/payment/queries/getPaymentById';
import getPaymentTypesForCompany from '../../graphql/payment/queries/getPaymentTypesForCompany';
import addPaymentTypesToCompany from '../../graphql/payment/mutations/addPaymentTypesToCompany';
import updatePaymentType from '../../graphql/payment/mutations/updatePaymentType';
import getPaymentTermsForCompany from '../../graphql/payment/queries/getPaymentTermsForCompany';
import upsertPaymentTerm from '../../graphql/payment/mutations/upsertPaymentTerm';
import deletePaymentTerm from '../../graphql/payment/mutations/deletePaymentTerm';
import softDeletePaymentType from '../../graphql/payment/mutations/softDeletePaymentType';
import deletePaymentInvoice from '../../graphql/payment/mutations/deletePaymentInvoice';

export default class PaymentService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
    this.CommonService = new CommonService();
  }

  getPaymentById = async id => {
    const params = {
      id
    };

    const response = await this.api.query(getPaymentById, params);
    return response;
  };

  upsertPaymentsToCompany = async (partitionKey, data) => {
    const params = {
      partitionKey,
      data
    };

    const response = await this.api.mutate(upsertPaymentsToCompany, params);
    return response?.data?.upsertPaymentsToCompany;
  };

  deletePayment = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };
    // first, delete all associated payment invoices
    const paymentResponse = await this.api.query(getPaymentById, { id });
    const { paymentInvoices } = paymentResponse.data.getPaymentById;
    paymentInvoices.items.forEach(async item => {
      const paymentInvoiceId = item.id;
      await this.api.mutate(deletePaymentInvoice, {
        partitionKey,
        id: paymentInvoiceId
      });
    });

    const response = await this.api.mutate(softDeletePaymentById, params);
    return response;
  };

  getPaymentTypesForCompany = async (partitionKey, sortKey, filter) => {
    const params = {
      partitionKey,
      sortKey,
      filter
    };

    const response = await this.api.query(getPaymentTypesForCompany, params);
    return response;
  };

  getActivePaymentTypesForCompany = async (partitionKey, sortKey) => {
    const response = await this.getPaymentTypesForCompany(partitionKey, sortKey, {
      isActive: { ne: false }
    });
    return response;
  };

  getAllPaymentTypesForCompany = async (partitionKey, sortKey) => {
    const response = await this.getPaymentTypesForCompany(partitionKey, sortKey, {});
    return response;
  };

  getPaymentTermsForCompany = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getPaymentTermsForCompany, params);
    return response;
  };

  upsertPaymentTerm = async (partitionKey, data) => {
    const params = {
      partitionKey,
      data
    };

    const response = await this.api.mutate(upsertPaymentTerm, params);
    return response;
  };

  deletePaymentTerm = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };

    const response = await this.api.mutate(deletePaymentTerm, params);
    return response;
  };

  addPaymentTypesToCompany = async (partitionKey, data) => {
    const params = {
      partitionKey,
      data
    };

    const response = await this.api.mutate(addPaymentTypesToCompany, params);
    return response;
  };

  updatePaymentType = async (partitionKey, data) => {
    const params = {
      partitionKey,
      data
    };

    const response = await this.api.mutate(updatePaymentType, params);
    return response;
  };

  deletePaymentType = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };

    const response = await this.api.mutate(softDeletePaymentType, params);
    return response;
  };
}
