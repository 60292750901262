import React from 'react';
import PropTypes from 'prop-types';
import { MUIForm } from '@buildhero/sergeant';
import LaborTimeField from '../LaborTimeField/LaborTimeField.component';
import LaborTimeRemainingField from '../LaborTimeRemainingField';
import LunchTimeValidationField from '../LunchTimeValidationField';
import { useLaborProductivityFormConfig } from './LaborProductivityForm.config';

const LaborProductivityForm = ({ employees, laborData, onLaborProductivityChange }) => {
  const configuration = useLaborProductivityFormConfig({ employees });
  return (
    <div>
      <MUIForm
        configuration={configuration}
        data={laborData}
        customComponents={{
          LaborTimeField,
          LaborTimeRemainingField,
          LunchTimeValidationField
        }}
        onFormChange={onLaborProductivityChange}
      />
    </div>
  );
};

LaborProductivityForm.propTypes = {
  employees: PropTypes.array.isRequired,
  laborData: PropTypes.object.isRequired,
  onLaborProductivityChange: PropTypes.func.isRequired
};

export default LaborProductivityForm;
