import gql from 'graphql-tag';

const getTechMultipleVisitCost = gql`
  query getTechMultipleVisitCost($visitIdArray: [String]!) {
    getTechMultipleVisitCost(visitIdArray: $visitIdArray) {
      visit {
        id
        visitNumber
      }
      labourTypeId
      labourTypeName
      approvalStatus
      reviewStatus
      approvedByList
      approvedDateList
      reviewedByList
      reviewedDateList
      duration
      hourTypeId
      hourTypeAbbreviation
      hourTypeDescription
      employeeId
      name
      totalCost
      hourCost
    }
  }
`;

export default getTechMultipleVisitCost;
