import { makeStyles } from '@material-ui/core';
import { ElementSizes } from '@dispatch/Dispatch.styles';
import { DEFAULT_EVENT_DURATION_IN_MIN } from '@dispatch/Dispatch.constants';

export const useStyles = makeStyles(theme => ({
  placeholder: ({ isWeek }) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    height: isWeek ? '100%' : ElementSizes.cellHeight,
    backgroundColor: 'white',
    border: `solid ${theme.palette.support.green.medium} 8px`,
    width: isWeek ? '100%' : ElementSizes.cellWidth / (60 / DEFAULT_EVENT_DURATION_IN_MIN)
  }),
  popover: {
    '& .MuiPaper-root': {
      borderRadius: 2,
      backgroundColor: theme.palette.grayscale(90),
      padding: '2px 4px'
    }
  },
  option: ({ hoverColor }) => ({
    backgroundColor: theme.palette.grayscale(60),
    color: 'white',
    padding: 5,
    margin: '2px 0',
    borderRadius: 2,
    fontSize: 12,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: hoverColor
    },
    '&:focus': {
      outline: 'none'
    },
    '& img': {
      marginRight: 4
    }
  })
}));
