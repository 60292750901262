import gql from 'graphql-tag';

const addQuotesToCustomerProperty = gql`
  mutation addQuotesToCustomerProperty(
    $partitionKey: String!
    $data: AddQuotesToCustomerPropertyInput!
  ) {
    addQuotesToCustomerProperty(partitionKey: $partitionKey, data: $data) {
      id
      version
      quoteNumber
      customIdentifier
      name
      description
      departmentId
      departmentValue
      serviceAgreementId
      priceBookId
      parentId
      expirationDate
      jobTypeId
      jobTypeValue
      customerPoNumber
      ownerId
      ownerValue
      salesById
      salesByValue
      issueDescription
      description
    }
  }
`;

export default addQuotesToCustomerProperty;
