import React from 'react';
import { Box } from '@material-ui/core';

export const convertPriceToString = value => {
  let returnValue = '0';
  const str = value ? value.toString() : '0';
  returnValue = str.replace(',', '');
  // round about way to correct formatting. better to handle at forms library level
  returnValue = parseFloat(returnValue).toFixed(2);
  return returnValue;
};

export const getCombinedAddress = (address, billTo) => {
  if (!address?.addressType) {
    return address;
  }
  const addressLine1 = address.addressLine1 || '';
  const addressLine2 = address.addressLine2 || '';
  const city = address.city || '';
  const state = address.state || '';
  const zipcode = address.zipcode || '';

  return (
    <>
      {billTo && <Box>{billTo}</Box>}
      <Box>{addressLine1 ? `${addressLine1}, ` : ''}</Box>
      <Box>{addressLine2 ? `${addressLine2}, ` : ''}</Box>
      <Box>{city ? `${city}, ` : ''}</Box>
      <Box>
        {state ? `${state}, ` : ''} {zipcode}
      </Box>
    </>
  );
};

export const getAddressFigure = (data = {}, isBillAddress) => {
  let billTo = '';
  const noFoundMsg = isBillAddress ? 'No Address' : '';
  if (data?.companyAddresses?.items) {
    data.companyAddresses.items.forEach(address => {
      if (isBillAddress) {
        if (address.addressType === 'billingAddress') {
          billTo = getCombinedAddress(address, address.billTo);
        }
      } else if (
        address.addressType === 'businessAddress' ||
        address.addressType === 'propertyAddress'
      ) {
        const customerBillingAddress = getCombinedAddress(address);
        billTo = customerBillingAddress || '';
      }
    });
  }
  if (isBillAddress && data.billingCustomer) {
    const billingAddress = data.billingCustomer.companyAddresses?.items?.find(
      address => address.addressType === 'billingAddress'
    );
    billTo = getCombinedAddress(
      billingAddress,
      data?.billTo || billingAddress?.billTo || data.companyName
    );
  }

  return billTo || (isBillAddress ? billTo : noFoundMsg);
};

export const getContactFigure = (data = {}) => {
  if (!data.cellPhone && !data.landlinePhone && !data.email) {
    return 'No Contacts';
  }

  return (
    <Box>
      {' '}
      <Box>{data.cellPhone ? `${data.cellPhone} Mobile,\n` : ''}</Box>
      <Box>{data.landlinePhone ? `${data.landlinePhone} Desk,\n` : ''}</Box>
      <Box>{data.email ? `${data.email}` : ''}</Box>
    </Box>
  );
};

export const getEmployeeName = (employees, id) => {
  const ownerValue = id ? employees.find(se => se.id === id) : {};
  const ownerName =
    ownerValue?.firstName && ownerValue?.lastName
      ? `${ownerValue.firstName} ${ownerValue.lastName}`
      : null;
  return ownerName;
};
