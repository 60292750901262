import React from 'react';
import { string } from 'prop-types';
import { TW, Typography } from '@buildhero/sergeant';
import { useStyles } from './JobLabel.styles';

const JobLabel = ({ jobIdentifier }) => {
  const styles = useStyles();
  return (
    <Typography weight={TW.BOLD} css={styles.label}>
      Invoicing for Job {jobIdentifier}
    </Typography>
  );
};

JobLabel.propTypes = {
  jobIdentifier: string.isRequired
};

export default JobLabel;
