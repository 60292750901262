import { ProjectService } from 'services/core';
import { logErrorWithCallback } from 'utils';
import removeObjProperties from 'utils/removeObjProperties';
import pickPropertiesFromList from 'utils/pickPropertiesFromList';
import {
  costTypeNeededFields,
  costCodeNeededFields,
  costTypeUpdateUnUsedFields,
  costCodeUpdateUnUsedFields
} from './constants';

export const saveCostCodes = async ({
  costCodes,
  successCallback,
  tenantId,
  companyId,
  snackbarOn
}) => {
  try {
    const Service = new ProjectService();
    const payload = {
      companyId: companyId,
      costCodes: pickPropertiesFromList(costCodes, costCodeNeededFields)
    };
    const { data } = await Service.addCostCodes(tenantId, payload);
    successCallback(data?.addCostCodesToCompany || []);
    snackbarOn('success', 'Cost Code Successfully Added');
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable Save cost code, please try again later');
  }
};

export const updateCostCode = async ({ costCode, successCallback, tenantId, snackbarOn }) => {
  try {
    const Service = new ProjectService();
    const payload = removeObjProperties(costCode, costCodeUpdateUnUsedFields);
    const { data } = await Service.updateCostCode(tenantId, payload);
    successCallback(data?.updateCostCode || costCode);
    snackbarOn('success', 'Cost Code Successfully updated');
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable update cost code, please try again later');
  }
};

export const saveCostTypes = async ({
  costTypes,
  successCallback,
  tenantId,
  companyId,
  snackbarOn
}) => {
  try {
    const Service = new ProjectService();
    const payload = {
      companyId: companyId,
      jobCostTypes: pickPropertiesFromList(costTypes, costTypeNeededFields)
    };
    const { data } = await Service.addJobCostTypes(tenantId, payload);
    successCallback(data?.addJobCostTypesToCompany || []);
    snackbarOn('success', 'Cost Type Successfully Added');
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable Save cost types, please try again later');
  }
};

export const updateCostType = async ({ costType, successCallback, tenantId, snackbarOn }) => {
  try {
    const Service = new ProjectService();
    const payload = removeObjProperties(costType, costTypeUpdateUnUsedFields);
    const { data } = await Service.updateJobCostType(tenantId, payload);
    successCallback(data?.updateJobCostType || costType);
    snackbarOn('success', 'Cost Code Successfully Updated');
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable Update cost types, please try again later');
  }
};

export const updateWIPReportSettings = async ({
  wipReportSettings,
  successCallback,
  tenantId,
  snackbarOn
}) => {
  try {
    const Service = new ProjectService();
    const { data } = await Service.updateWIPReportSettings(tenantId, wipReportSettings);
    successCallback(data?.updateWIPReportSettings || wipReportSettings);
    snackbarOn('success', 'WIP Reports setting successfully updated');
  } catch (error) {
    logErrorWithCallback(
      error,
      snackbarOn,
      'Unable to update WIP Reports setting, please try again later'
    );
  }
};
