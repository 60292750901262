import React from 'react';

import { LoadScript } from '@react-google-maps/api';

import { GOOGLE_MAPS_API_KEY } from './AppConstants';

export const withGoogleMapScript = Component => props => (
  <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
    <Component {...props} />
  </LoadScript>
);
