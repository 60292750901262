import gql from 'graphql-tag';

const presetFragment = `
id
type
displayName
settings
`;

export const SAVE_JOB_REPORT_PRESET_SETTING = gql`
  mutation SaveJobReportPresetSetting($partitionKey: String!, $data: SavePresetSettingInput!) {
    saveJobReportPresetSetting(partitionKey: $partitionKey, data: $data) {
      ${presetFragment}
    }
  }
`;

export const DELETE_USER_SETTING_BY_ID = gql`
  mutation deleteUserSettingById($partitionKey: String!, $id: String!) {
    deleteUserSettingById(partitionKey: $partitionKey, id: $id) {
      ${presetFragment}
    }
  }
`;

export const GET_JOB_REPORT_PRESETS = gql`
  query getJobReportPresets($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      userSettings(
        entityConnection: "UserSetting"
        filter: { type: { eq: "JOB_REPORT_PRESET_SETTING" } }
      ) {
        items {
          ${presetFragment}
        }
      }
    }
  }
`;

export default GET_JOB_REPORT_PRESETS;
