import React, { useMemo, useState } from 'react';
import { array, func } from 'prop-types';
import { sortBy } from 'lodash';
import { convertToCurrencyString } from 'utils';
import { MultiSelect } from '@buildhero/sergeant';

const VisitsSelect = ({ visits, onVisitsSelect }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = visitOptions => {
    setSelectedOptions(visitOptions);
    onVisitsSelect(visitOptions?.map(({ id }) => id));
  };

  const options = useMemo(
    () =>
      sortBy(visits, 'visitNumber').map(visit => ({
        id: visit?.id,
        value: visit?.id,
        label: `Visit ${visit?.visitNumber} - ${convertToCurrencyString(visit?.amount)}`
      })),
    [visits]
  );

  return (
    <MultiSelect
      placeholder="Select visits"
      label="Visits"
      options={options}
      selectedOptions={selectedOptions}
      onChange={handleChange}
      popperHeight={130}
      showChips
    />
  );
};

VisitsSelect.propTypes = {
  visits: array.isRequired,
  onVisitsSelect: func.isRequired
};

export default VisitsSelect;
