import gql from 'graphql-tag';

const addMaintenanceTemplatesToServiceAgreement = gql`
  mutation addMaintenanceTemplatesToServiceAgreement(
    $data: AddMaintenanceTemplatesToServiceAgreementInput!
  ) {
    addMaintenanceTemplatesToServiceAgreement(data: $data) {
      id
      serviceAgreementId
      propertyId
      schedule
      firstDueDate
      budgetedHours
      estimatedVisits
      version
    }
  }
`;

export default addMaintenanceTemplatesToServiceAgreement;
