import gql from 'graphql-tag';

const getPayrollBillingHourTypes = gql`
  query getPayrollBillingHourTypes(
    $partitionKey: String!
    $sortKey: String!
    $filter: TableBillingHourTypeFilterInput
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      billingHourTypes(entityConnection: "BillingHourType", filter: $filter) {
        items {
          id
          version
          hourType
          hourTypeAbbreviation
          sortOrder
        }
      }
    }
  }
`;

export default getPayrollBillingHourTypes;
