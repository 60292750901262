import PropTypes from 'prop-types';

export const PreferredTechniciansPropType = PropTypes.shape({
  departmentId: PropTypes.string,
  crewId: PropTypes.string,
  primaryTechnicianId: PropTypes.string,
  additionalTechnicianIds: PropTypes.array
});

export const DepartmentsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    tagName: PropTypes.string
  })
);

export const CrewsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    department: PropTypes.shape({
      id: PropTypes.string
    })
  })
);

export const TechniciansPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    departments: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          mappedEntity: PropTypes.shape({
            id: PropTypes.string
          })
        })
      )
    })
  })
);
