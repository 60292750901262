import React from 'react';

import { array, func, object, string } from 'prop-types';

import EventBadge from '@dispatch/components/Drawer/components/EventBadge';
import { EVENT_TYPES } from '@dispatch/Dispatch.constants';
import { isNVEDeleteDisabled } from '@dispatch/Dispatch.utils';

import { useFormService } from '@dispatch/hooks';
import { useDispatchSettings } from '@dispatch/settings';
import { getState } from 'redux/store';

import DrawerLayout from '../DrawerLayout';
import NonBillableEventForm, { Modes as NonBillableFormModes } from '../NonBillableEventForm';
import NonVisitStatusHeader from '../NonVisitStatusHeader';

const EditNonBillableEvent = ({
  nonBillableEventId,
  closeDrawer,
  updateBillableNonVisitTuple,
  deleteBillableNonVisitTuple,
  nonVisitEventDetailsResponse
}) => {
  const { tenantId } = getState().user;
  const { isDirty, onCreateService, onDirtyChange, resetForm, submitForm } = useFormService();
  const { data, loading: fetching } = nonVisitEventDetailsResponse;
  const [triggerDelete, deleteResponse] = deleteBillableNonVisitTuple;
  const { loading: deleting } = deleteResponse;
  const [triggerUpdate, updateResponse] = updateBillableNonVisitTuple;
  const { loading: updating } = updateResponse;
  const { showDepartmentForNonBillable } = useDispatchSettings();

  const loading = fetching || deleting || updating;

  const handleDelete = () => {
    triggerDelete({
      eventId: nonBillableEventId,
      tenantId
    });
    closeDrawer();
  };

  const updateBillableNonVisitEvent = eventData => {
    triggerUpdate({
      eventId: nonBillableEventId,
      tenantId,
      originalEvent: data,
      data: {
        ...eventData,
        version: data.version
      }
    });
    closeDrawer();
  };

  const renderHeader = () => (
    <>
      <EventBadge eventType={EVENT_TYPES.NON_BILLABLE_EVENT} />
      <NonVisitStatusHeader event={data} />
    </>
  );

  const renderBody = () => (
    <NonBillableEventForm
      onDirtyChange={onDirtyChange}
      onComplete={updateBillableNonVisitEvent}
      onCreateService={onCreateService}
      eventData={data}
      disabled={loading}
      enableDepartment={showDepartmentForNonBillable}
      mode={NonBillableFormModes.EDIT}
    />
  );

  return (
    <DrawerLayout
      renderHeader={renderHeader}
      renderBody={renderBody}
      onResetForm={resetForm}
      onSubmitForm={submitForm}
      isDirty={isDirty}
      loading={loading}
      disabledClose={loading}
      disabledDelete={isNVEDeleteDisabled(data)}
      onDelete={handleDelete}
    />
  );
};

EditNonBillableEvent.propTypes = {
  nonBillableEventId: string.isRequired,
  closeDrawer: func.isRequired,
  updateBillableNonVisitTuple: array.isRequired,
  deleteBillableNonVisitTuple: array.isRequired,
  nonVisitEventDetailsResponse: object.isRequired
};

export default EditNonBillableEvent;
