const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    boxShadow: 'none'
  },
  table: {
    minWidth: 600
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  buttonHover: {
    '&:hover': {
      backgroundColor: '#f6f6f6'
    }
  },
  tableFooterToolbar: {
    backgroundColor: '#fff',
    fontSize: 12,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold'
  },
  iconColor: {
    color: '#646464',
    fontSize: 18,
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  highlightFirstColumn: {
    borderRight: '1px solid #e0e0e4'
  },
  column: {
    maxWidth: 400
  },
  noData: {
    color: '#959595',
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 'normal',
    letterSpacing: 0.3,
    paddingTop: 15,
    paddingLeft: 10
  },
  buttonOutlinedSecondary: {
    border: 'none',
    textTransform: 'unset',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    backgroundColor: 'inherit',
    padding: 0,
    letterSpacing: 0.3,
    '&:hover': {
      border: 'none',
      backgroundColor: 'inherit'
    },
    '&:disabled': {
      border: 'none',
      backgroundColor: 'inherit'
    }
  },
  buttonLabel: {
    justifyContent: 'flex-start'
  }
});

export default styles;
