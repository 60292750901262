import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  saveAction: {
    background: theme.palette.other.cosmicLatte,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`
  },
  textInput: {
    background: 'red'
  },
  totalHours: {
    fontWeight: 700
  },
  splitButton: {
    fontSize: '12px',
    color: theme.palette.grayscale(60),
    borderColor: theme.palette.grayscale(60),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`
  },
  splitIcon: {
    fill: theme.palette.grayscale(60),
    width: '16px',
    height: '16px',
    transform: 'rotate(90deg)'
  },
  icon: {
    width: '16px',
    height: '16px',
    cursor: 'pointer',
    marginLeft: theme.spacing(0.7),
    marginRight: theme.spacing(0.7),
    fill: theme.palette.grayscale(50)
  },
  warningMessage: {
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'flex-end',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& svg': {
      fill: theme.palette.grayscale(50),
      marginRight: theme.spacing(1)
    }
  },
  locationDialog: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(1),
    flexDirection: 'column',
    width: '350px'
  },
  mapWrapper: {
    marginTop: theme.spacing(2)
  },
  closeIcon: {
    margin: theme.spacing(1),
    cursor: 'pointer'
  },
  deleteIcon: {
    margin: theme.spacing(1),
    fill: theme.palette.grayscale(60),
    cursor: 'pointer'
  },
  searchBox: {
    width: '200px'
  }
}));
