import { VisitStatuses } from '@dispatch/Dispatch.constants';

export const selectAddressString = ({ addressLine1, city, state, zipcode }) => {
  return [addressLine1, city, state, zipcode]
    .map(s => s?.trim() || '')
    .join(' ')
    .trim();
};

export const selectPropertyPin = visits => {
  return visits.reduce((prev, visit) => {
    const Status = VisitStatuses.get(visit.status)?.value || VisitStatuses.UNKNOWN.value;

    return prev.displayPriority < Status.displayPriority ? prev : Status;
  }, {}).mapPin;
};
