import { useTheme } from '@material-ui/core';

export const useStyles = ({ headerYOffset, stickyHeaderHeight }) => {
  const theme = useTheme();
  const indent = theme.spacing(3);
  const title = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.grayscale(50),
    '& > *': {
      marginRight: 8
    }
  };
  const buttonsContainer = {
    marginLeft: 'auto',
    alignSelf: 'center',
    '& a': {
      color: theme.palette.common.white,
      lineHeight: 'normal',
      textDecoration: 'none'
    }
  };
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      borderBottom: `1px solid ${theme.palette.grayscale(85)}`,
      padding: `20px ${indent}px`,
      margin: `-${indent}px -${indent}px 0 -${indent}px`
    },
    stickyContainer: {
      position: 'fixed',
      display: 'flex',
      top: headerYOffset,
      left: 0,
      right: 0,
      height: stickyHeaderHeight,
      paddingLeft: 16,
      paddingRight: 16,
      backgroundColor: theme.palette.grayscale(94),
      zIndex: 20
    },
    stickyTitleContainer: {
      ...title,
      height: '100%'
    },
    title,
    buttonsContainer,
    stickyButtonsContainer: {
      ...buttonsContainer,
      marginRight: theme.spacing(3)
    }
  };
};
