import gql from 'graphql-tag';

const deleteAttachment = gql`
  mutation deleteAttachment($input: DeleteAttachmentInput!) {
    deleteAttachment(input: $input) {
      id
    }
  }
`;

export default deleteAttachment;
