import gql from 'graphql-tag';

const updateJobTags = gql`
  mutation updateJobAndRelated($partitionKey: String, $data: UpdateJobAndRelatedInput!) {
    updateJobAndRelated(partitionKey: $partitionKey, data: $data) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      tenantId
      hierarchy
      entityType
      jobJobTags(entityConnection: "JobJobTag", limit: 50) {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          jobTag(entityConnection: "JobTag") {
            tagType
            tagName
            sortOrder
            id
            tenantId
            tenantCompanyId
            partitionKey
            sortKey
            hierarchy
            entityType
          }
        }
      }
    }
  }
`;

export default updateJobTags;
