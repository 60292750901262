/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const addWarrantiesToPropertyAsset = gql`
  mutation addWarrantiesToPropertyAsset(
    $partitionKey: String!
    $data: AddWarrantiesToPropertyAssetInput!
  ) {
    addWarrantiesToPropertyAsset(partitionKey: $partitionKey, data: $data) {
      id
      version
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      startDate
      endDate
      warrantyInfo
      createdBy
      lastUpdatedDate
      lastUpdatedDateTime
      lastUpdatedBy
    }
  }
`;

export const updateWarranty = gql`
  mutation updateWarranty($partitionKey: String, $data: UpdateWarrantyInput!) {
    updateWarranty(partitionKey: $partitionKey, data: $data) {
      id
      version
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      startDate
      endDate
      warrantyInfo
      createdBy
      lastUpdatedDate
      lastUpdatedDateTime
      lastUpdatedBy
    }
  }
`;

export const softDeleteWarranty = gql`
  mutation softDeleteWarranty($partitionKey: String, $id: String!) {
    softDeleteWarranty(partitionKey: $partitionKey, id: $id) {
      id
    }
  }
`;
