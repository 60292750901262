import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { Box, Typography, FormControl } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import Labels from 'meta/labels';
import AutoComplete from 'components/AutoCompleteDropdown';
import AccessTime from '@material-ui/icons/AccessTime';
import styles from './styles';

const TimeStep = props => {
  const { onClickNextStep, userLocale, classes, pageTitle, stepData } = props;
  const [selectedTime, setTime] = useState('');

  const getTimePeriods = () => {
    const times = [];
    let id = 4;
    for (let i = 6; i <= 12; i += 1, id += 1) {
      times.push({
        id,
        name: i === 12 ? '12:00 pm' : `${i}:00 am`
      });
    }

    for (let i = 1; i <= 12; i += 1, id += 1) {
      times.push({
        id,
        name: i === 12 ? '12:00 am' : `${i}:00 pm`
      });
    }
    for (let i = 1; i < 6; i += 1, id += 1) {
      times.push({
        id,
        name: `${i}:00 am`
      });
    }

    const timeList = [];
    times.map(item => timeList.push({ label: item.name, value: item.name }));

    return timeList;
  };

  const timeValue = selectedTime || stepData?.selectedTime;

  return (
    <Box maxWidth="500px" margin="auto" display="flex" pr={4} flexDirection="column">
      <Typography variant="h4" className={classes.title}>
        {pageTitle}
      </Typography>
      <Typography variant="body2" className={classes.inputLabel}>
        {Labels.startingTime[userLocale]}
      </Typography>
      <Box display="flex" pr={6} flexDirection="row" alignItems="center">
        <FormControl variant="filled" className={classes.formControlTS}>
          <AutoComplete
            value={timeValue}
            name="timeSelection"
            label={Labels.enterTime[userLocale]}
            handleOnChange={setTime}
            options={getTimePeriods()}
            DropdownIndicator={() => <AccessTime className={classes.timeIcon} />}
            menuPlacement="bottom"
          />
        </FormControl>
        <Button
          variant="containedSecondary"
          onClick={() => onClickNextStep({ selectedTime: timeValue })}
          className={classes.nextButton}
        >
          {Labels.nextStep[userLocale]}
        </Button>
      </Box>
    </Box>
  );
};

TimeStep.propTypes = {
  onClickNextStep: PropTypes.func.isRequired,
  userLocale: PropTypes.string.isRequired
};

export default withStyles(styles)(TimeStep);
