const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    boxShadow: 'none'
  },
  table: {
    minWidth: 600
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  buttonHover: {
    '&:hover': {
      backgroundColor: theme.palette.grayscale(96)
    }
  },

  iconColor: {
    color: theme.palette.grayscale(40),
    fontSize: 18,
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  highlightFirstColumn: {
    borderRight: `1px solid ${theme.palette.grayscale(88)}`
  },
  popperPaper: {
    padding: 5
  },
  cssUnderline: {
    '&:before': {
      borderBottomColor: theme.palette.grayscale(95)
    },
    '&:after': {
      borderBottomColor: theme.palette.brand.logoBlue
    },
    '&&&&:hover:before': {
      borderBottom: `1px solid ${theme.palette.brand.logoBlue}`
    }
  },
  noData: {
    color: '#959595',
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 'normal',
    letterSpacing: 0.3,
    paddingTop: 15,
    paddingLeft: 10
  }
});
export default styles;
