import React from 'react';

import { Box, InputAdornment, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';

import { calculateMarginFromMarkup } from '@buildhero/math';
import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';

import { PricingStrategy } from 'utils/constants';
import { convertForMathLib } from 'utils/mathLibrary';

import styles from './style';

const FlatMarkup = props => {
  const {
    user,
    classes,
    isViewMode,
    flatMarkup,
    setFlatMarkup,
    showAdornment,
    adornmentSymbol,
    marginSetting
  } = props;
  const pricingStrategy = useSelector(s => s.settings.pricingStrategy);
  const isMarginEnabled =
    pricingStrategy === PricingStrategy.MARGIN ||
    pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN;
  const isMarkupEnabled =
    pricingStrategy === PricingStrategy.MARKUP ||
    pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN;

  const handleOnChange = value => {
    if (value === '') {
      setFlatMarkup(value);
      return;
    }
    if (+value < 0) return;
    setFlatMarkup(+value);
  };
  const marginValue = convertForMathLib(calculateMarginFromMarkup, flatMarkup?.[0]?.rate);


  return (
    <Box display="flex" pr={4} width={300} flexDirection="column">
      {!isViewMode && (
        <Typography className={classes.auditText} style={{ paddingLeft: 0 }} variant="body2">
          {(marginSetting ? Labels.flatMargin : Labels.flatMarkup)[user.locale].toUpperCase()}
        </Typography>
      )}
      <Box component="div" className={classes.dFlex}>
        {isViewMode && isMarkupEnabled && !marginSetting && (
          <Typography className={classes.auditText} style={{ paddingLeft: 0 }} variant="body2">
            {Labels.flatMarkup[user.locale].toUpperCase()}
          </Typography>
        )}
        {isViewMode && isMarginEnabled && (
          <Typography
            className={classes.auditText}
            style={isMarkupEnabled ? { paddingLeft: '10px' } : { paddingLeft: 0 }}
            variant="body2"
          >
            {Labels.flatMargin[user.locale].toUpperCase()}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection="column">
        <Box alignItems="center" display="flex">
          {isViewMode && isMarkupEnabled && (
            <Typography
              className={classes.noEditableValue}
              style={{ marginLeft: 0 }}
              variant="body2"
            >
              {flatMarkup?.[0]?.rate ?? '-'}
              {adornmentSymbol}
            </Typography>
          )}
          {isViewMode && isMarginEnabled && (
            <Typography
              className={classes.noEditableValue}
              style={isMarkupEnabled ? { marginLeft: '20px' } : { marginRight: 0 }}
              variant="body2"
            >
              {marginValue ?? '-'}
              {adornmentSymbol}
            </Typography>
          )}

          {!isViewMode && (
            <>
              <TextField
                value={flatMarkup?.[0]?.rate ?? ''}
                type="number"
                onChange={e => handleOnChange(e.target.value)}
                InputProps={{
                  className: classes.markupInput,
                  disableUnderline: true,
                  startAdornment: showAdornment && (
                    <InputAdornment className={classes.inputIcon} position="start">
                      <Typography variant="subtitle1">{adornmentSymbol}</Typography>
                    </InputAdornment>
                  )
                }}
              />
              <Typography variant="body2" className={classes.markupLabel}>
                %
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

FlatMarkup.propTypes = {
  flatMarkup: PropTypes.number,
  showAdornment: PropTypes.bool,
  setFlatMarkup: PropTypes.func,
  isViewMode: PropTypes.bool.isRequired,
  adornmentSymbol: PropTypes.string,
  classes: PropTypes.object
};

FlatMarkup.defaultProps = {
  flatMarkup: 0,
  showAdornment: false,
  adornmentSymbol: '',
  setFlatMarkup: () => {},
  classes: {}
};

const mapStateToProps = state => ({
  user: state.user
});
const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatcherToProps)
)(FlatMarkup);
