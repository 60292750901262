const form = ({ departmentOptions, employeeOptions, validEmployees }) => ({
  fields: {
    name: {
      default: 'name'
    },
    departmentIds: {
      default: 'departmentIds'
    },
    afterHoursTimings: {
      default: 'afterHoursTimings'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          options: {
            width: '50%',
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'TextInput'
                  },
                  source: 'name',
                  options: {
                    label: 'Schedule name',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            width: '50%',
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'SelectInput'
                  },
                  source: 'departmentIds',
                  options: {
                    label: 'Department(s)',
                    placeholder: 'Select...',
                    valueSet: departmentOptions,
                    isMultipleSelection: true
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'AfterHoursTimings'
                  },
                  source: 'afterHoursTimings',
                  options: { employeeOptions, validEmployees }
                }
              ]
            }
          ]
        }
      ]
    }
  }
});

export default form;
