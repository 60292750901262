import gql from 'graphql-tag';

const updateLabourType = gql`
  mutation updateLabourType($partitionKey: String, $data: UpdateLabourTypeInput!) {
    updateLabourType(partitionKey: $partitionKey, data: $data) {
      id
      name
      version
      isActive
      revenueTypeId
      jobCostTypeId
      costCodeId
    }
  }
`;

export default updateLabourType;
