import gql from 'graphql-tag';

const getQuoteProductLevelTotal = gql`
  query getQuoteProductLevelTotal($id: String!) {
    getQuoteById(id: $id) {
      id
      productLevelGrouping: aggregatedField(
        input: {
          entityConnection: "QuoteLineProduct"
          isDirectChildren: false
          groupByFields: ["taxable", "parentId", "entityType"]
          aggregations: [{ aggregationType: SUM, aggregationField: "unitPrice * quantity" }]
        }
      ) {
        items {
          totalAmount: aggregation1
          taxable: groupByField1
          parentId: groupByField2
          entityType: groupByField3
        }
      }
    }
  }
`;
export default getQuoteProductLevelTotal;
