import React from 'react';

import { Field, TV, TW, Typography } from '@buildhero/sergeant';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { StatusChip } from 'components';
import Routes from 'scenes/Routes';
import { Logger } from 'services/Logger';

import { JobTypes } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';

import { isTenantSettingEnabled } from '../../../../utils';
import { eventTypes } from '../../constants';

const styles = {
  eventIdentifiers: {
    display: 'flex',
    width: '100%',
    marginBottom: 24,
    marginTop: 24
  },
  eventHeader: {
    marginTop: '2em',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  eventLink: {
    fontSize: 16,
    marginRight: '1em'
  },
  disabledLink: {
    pointerEvents: 'none',
    textDecoration: 'none'
  },
  statusChip: {
    borderRadius: '0.25em'
  }
};

const EventIdentifierColumns = [
  {
    key: 'customer',
    label: 'customer'
  },
  {
    key: 'property',
    label: 'property'
  },
  {
    key: 'phase',
    label: 'phase'
  },
  {
    key: 'department',
    label: 'department'
  },
  {
    key: 'costCode',
    label: 'cost code'
  },
  {
    key: 'scheduledTime',
    label: 'scheduled time'
  }
];

const getJobRoute = event => {
  return event.jobType === JobTypes.MAINTENANCE
    ? Routes.maintenance({ mode: 'view', id: event.jobNumber })
    : Routes.job({ mode: 'view', id: event.jobNumber });
};

const getEventLinkInfo = event => {
  switch (event.type) {
    case eventTypes.MAN_DAY:
      return event.dailyReportNumber && event.dailyReportId
        ? {
            route: Routes.projectDailyReportDetailView({
              mode: 'view',
              projectId: event.projectId,
              id: event.dailyReportId
            }),
            label: `Project ${event.projectNumber}, ${event.project}, Daily Report ${event.dailyReportNumber}`
          }
        : {
            route: Routes.projectDetails({
              mode: 'view',
              projectId: event.projectId,
              page: 'dashboard'
            }),
            label: `Project ${event.projectNumber}, ${event.project}`
          };
    case eventTypes.VISIT:
      return {
        route: getJobRoute(event),
        label: `Job ${event.job}, Visit ${event.visit}`
      };
    case eventTypes.NON_VISIT_EVENT:
      return event.jobNumber && event.visit
        ? {
            route: getJobRoute(event),
            label: `${event.name} - Job ${event.job}, Visit ${event.visit}`
          }
        : {
            route: '', // no link for non-billable events
            label: event.name
          };
    default:
      Logger.error(`Attempting to parse invalid timesheet event ${JSON.stringify(event)}`);
      return {
        route: '',
        label: 'Event'
      };
  }
};

const WorkEventDetails = ({ workEvent, children }) => {
  const { identifier } = workEvent;

  const showDepartmentForNonBillableEvents = isTenantSettingEnabled(
    'showDepartmentForNonBillableEvents'
  );

  const isNonVisitEventDepartmentLabel = ({ label, event }) =>
    showDepartmentForNonBillableEvents &&
    label === 'department' &&
    event.type === eventTypes.NON_VISIT_EVENT;

  const eventLinkInfo = getEventLinkInfo(identifier);

  return (
    <div key={workEvent.id}>
      <Box css={styles.eventHeader}>
        <Typography variant={TV.BASE} weight={TW.BOLD} css={styles.eventLink}>
          <Link
            to={eventLinkInfo.route}
            target="_blank"
            rel="noopener noreferrer"
            css={eventLinkInfo.route ? '' : styles.disabledLink}
          >
            {eventLinkInfo.label}
          </Link>
        </Typography>
        {identifier.status && (
          <StatusChip
            label={workEvent.status}
            enumType={EnumType.VISIT_STATUS}
            enumValue={workEvent.status}
            textColor="white"
            style={styles.statusChip}
          />
        )}
      </Box>
      <div css={styles.eventIdentifiers}>
        {EventIdentifierColumns.filter(
          column =>
            workEvent.identifier[column.key] ||
            isNonVisitEventDepartmentLabel({ label: column.key, event: identifier })
        ).map((column, i) => (
          <Field
            key={`${column.key}_${workEvent.id}`}
            label={column.label}
            value={identifier[column.key] || '-'}
            style={{ marginRight: 24, width: '100%', maxWidth: 100 }}
            innerStyle={{ fontWeight: 700 }}
          />
        ))}
      </div>
      {children}
    </div>
  );
};

export default WorkEventDetails;
