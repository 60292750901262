import { EnumType } from 'utils/constants';

export const purchaseOrdersMeta = [
  {
    id: 'poNumber',
    numeric: false,
    label: 'PO Number',
    showLink: 'view',
    linkPath: '/procurement/purchaseorders/view',
    linkReference: 'id'
  },
  {
    id: 'status',
    label: 'Status',
    enumType: EnumType.PROCUMENT_PURCHASE_ORDER_STATUS,
    type: 'enum'
  },
  {
    id: 'isFieldOrder',
    label: 'Field Order',
    type: 'boolean'
  },
  {
    id: 'vendorName',
    label: 'Vendor',
    showLink: 'view',
    numeric: false
  },
  {
    id: 'description',
    label: 'Description',
    numeric: false
  },
  {
    id: 'departmentName',
    label: 'Department',
    numeric: false
  },
  {
    id: 'purchaseOrderTags',
    type: 'chip',
    label: 'Tags'
  },
  {
    id: 'assignedToName',
    label: 'Assigned To',
    numeric: false
  },
  {
    id: 'createdBy',
    label: 'Created By',
    numeric: false
  },
  {
    id: 'createdDate',
    label: 'Created On',
    type: 'date'
  },
  {
    id: 'totalCost',
    label: 'Total',
    type: 'currency',
    numeric: true
  }
];

export const billRowsMeta = ({ closeoutReport }) => {
  const billsArray = [
    !closeoutReport && {
      id: 'visitNumbers',
      numeric: false,
      disablePadding: true,
      label: 'Visit',
      isCustom: true,
      type: 'viewVisitNumbers'
    },
    {
      id: 'billNumber',
      label: 'Bill Number',
      filterKey: 'billNumber',
      filterType: 'string',
      linkPath: '/procurement/receipts-bills/bill/view',
      linkReference: 'billId',
      numeric: true,
      showLink: 'view',
      type: 'number'
    },
    !closeoutReport && {
      id: 'invoicedStatus',
      label: 'Invoiced Status',
      disablePadding: true,
      enumType: EnumType.BILL_STATUS,
      type: 'enum'
    },
    {
      id: 'receiptNumber',
      filterKey: 'receiptNumber',
      filterType: 'string',
      label: 'Receipt Number',
      linkPath: '/procurement/receipts-bills/receipt/view',
      linkReference: 'receiptId',
      numeric: true,
      showLink: 'view',
      type: 'number'
    },
    {
      id: 'vendorName',
      numeric: false,
      disablePadding: true,
      label: 'Vendor'
    },
    {
      id: 'poNumber',
      label: 'PO Number',
      filterKey: 'poNumber',
      filterType: 'string',
      linkPath: '/procurement/purchaseorders/view',
      linkReference: 'purchaseOrderId',
      numeric: true,
      showLink: 'view',
      type: 'number'
    },
    {
      id: 'dateOfPurchase',
      numeric: false,
      disablePadding: true,
      label: 'Date Issued',
      type: 'date'
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: true,
      label: 'Created By'
    },
    {
      id: 'totalCost',
      numeric: false,
      disablePadding: true,
      label: 'Total',
      type: 'currency'
    }
  ];
  return billsArray.filter(bill => bill);
};

export const purchaseOrderItemRowsMeta = [
  {
    id: 'poNumber',
    label: 'PO Number',
    linkPath: '/procurement/purchaseorders/view',
    linkReference: 'poNumberId',
    numeric: true,
    showLink: 'view',
    type: 'number'
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: true,
    label: 'Item Description'
  },
  {
    id: 'vendorName',
    numeric: false,
    disablePadding: true,
    label: 'Vendor'
  },
  {
    id: 'status',
    label: 'Fulfillment Status',
    disablePadding: true,
    enumType: EnumType.PROCUMENT_PURCHASE_ORDER_STATUS,
    type: 'enum'
  },
  {
    id: 'quantity',
    numeric: true,
    disablePadding: true,
    label: 'Quantity Ordered',
    type: 'number'
  },
  {
    id: 'quantityFulfilled',
    numeric: true,
    disablePadding: true,
    label: 'Quantity Received',
    type: 'number'
  },
  {
    id: 'totalCost',
    numeric: false,
    disablePadding: true,
    label: 'Total Cost',
    type: 'currency'
  }
];

export const inventoryItemRowsMeta = [
  {
    id: 'visitNumber',
    numeric: true,
    disablePadding: true,
    label: 'Visit',
    type: 'number'
  },
  {
    id: 'primaryTechnician',
    numeric: false,
    disablePadding: true,
    label: 'Technician'
  },
  {
    id: 'itemName',
    numeric: false,
    disablePadding: true,
    label: 'Item'
  },
  {
    id: 'itemDescription',
    numeric: false,
    disablePadding: true,
    label: 'Item Description'
  },
  {
    id: 'unitCost',
    numeric: false,
    disablePadding: true,
    label: 'Unit Cost',
    type: 'currency'
  },
  {
    id: 'quantityUsed',
    numeric: true,
    disablePadding: true,
    label: 'Quantity Used',
    type: 'number'
  },
  {
    id: 'totalCost',
    numeric: false,
    disablePadding: true,
    label: 'Total Cost',
    type: 'currency'
  }
];
