import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SpinnerStrategy from './Strategy';

/**
 * Spinner with slow and fast speeds
 */
class Spinner extends Component {
  retrieveSpinner = () => SpinnerStrategy[this.props.type];

  render() {
    if (this.props.show) {
      const { renderSpinner } = this.retrieveSpinner();
      return <div>{renderSpinner}</div>;
    }
    return null;
  }
}
const mapStateToProps = state => ({
  show: state.application.spinner
});

Spinner.propTypes = {
  type: PropTypes.oneOf(['SLOW', 'FAST']).isRequired,
  show: PropTypes.bool
};

export default connect(mapStateToProps)(Spinner);
