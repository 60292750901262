export const manDayFragment = `
  id
  startDateTime
  endDateTime
  description
  project {
    id
    name
    number
  }
  technicianIds
  isActive
  status
`;
