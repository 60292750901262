import { convertToCurrencyStringCheck } from '../../CKEditor.utils';

function parseLaborSubtotal({ quoteLineTasks }) {
  const quoteLaborTotal = quoteLineTasks.reduce((acc, task) => {
    const taskLaborTotal = task.quoteLineLabors.items.reduce(
      (taskAcc, laborItem) => taskAcc + laborItem.totalPrice,
      0
    );
    return acc + taskLaborTotal;
  }, 0);

  return convertToCurrencyStringCheck(quoteLaborTotal);
}

export default parseLaborSubtotal;
