import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WarningIcon from '@material-ui/icons/Warning';
import { useTheme } from '@material-ui/core';
import { Button, ButtonType, Modal } from '@buildhero/sergeant';
import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import CKEditor from 'components/CKEditor';
import { mockData } from 'components/CKEditor/mockData';

const CKEditorModal = ({
  ckEditorData,
  companyQuoteSettings,
  companyQuoteTemplate,
  currentSettings,
  handleQuoteTemplateSave,
  handleRefreshSmartfieldsClick,
  hasMultipleVersions,
  hasPermissionToEditQuote,
  isLoading,
  isReadOnly,
  openModal,
  propertyDetails,
  quoteInfo,
  refreshCKEditor,
  setCKEditorData,
  setOpenModal,
  setRefreshCKEditor,
  setSendEmailModalOpen
}) => {
  const { palette } = useTheme();
  const confirmContext = useConfirmModal();
  const [initialEditorData, setInitialEditorData] = useState('');

  const confirmConfig = {
    body: 'There are unsaved changes on this page. Do you want to save?',
    title: (
      <>
        <WarningIcon style={{ marginRight: 9, color: palette.support.yellow.dark }} />
        Unsaved Changes
      </>
    ),
    buttonLabel: 'Save & Exit',
    cancelButton: true,
    cancelButtonLabel: 'Exit Without Saving',
    cancelButtonType: 'tertiary'
  };

  const handleClose = async () => {
    if (ckEditorData !== initialEditorData) {
      const shouldSaveChanges = await confirmContext.confirm(confirmConfig);
      if (shouldSaveChanges) {
        handleQuoteTemplateSave({});
      }
    }
    setOpenModal(false);
  };

  return (
    <Modal
      open={openModal}
      title={`Quote ${quoteInfo?.customIdentifier || quoteInfo?.quoteNumber || ''}`}
      onClose={handleClose}
      actions={
        <>
          <Button
            tooltip="Saving changes will freeze smartfield values in the PDF to their current values, unless you later refresh smartfields"
            type={ButtonType.SECONDARY}
            onClick={handleQuoteTemplateSave}
            disabled={isReadOnly}
          >
            Save Changes
          </Button>
          <Button
            tooltip="Refresh smartfields with current data from quote if you have saved changes on the PDF"
            type={ButtonType.TERTIARY}
            onClick={handleRefreshSmartfieldsClick}
            disabled={!quoteInfo.template || isReadOnly}
          >
            Refresh SmartFields
          </Button>

          <Button
            type={ButtonType.PRIMARY}
            onClick={() => setSendEmailModalOpen(true)}
            disabled={!hasPermissionToEditQuote || isLoading}
          >
            Generate PDF and Send
          </Button>
        </>
      }
      fullScreen
    >
      <CKEditor
        setInitialEditorData={setInitialEditorData}
        refreshCKEditor={refreshCKEditor}
        setRefreshCKEditor={setRefreshCKEditor}
        hasMultipleVersions={hasMultipleVersions}
        hasPermissionToEditQuote={hasPermissionToEditQuote}
        propertyDetails={propertyDetails}
        initialData={quoteInfo?.template || companyQuoteTemplate?.template || mockData}
        isReadOnly={isReadOnly}
        updateDataFn={setCKEditorData}
        smartFieldInfo={quoteInfo}
        useParsedHTMLString
        currentSettings={currentSettings}
        lockData={
          companyQuoteSettings?.settings
            ? JSON.parse(companyQuoteSettings.settings)?.enableLockScopeAndPricingData
            : false
        }
      />
    </Modal>
  );
};

CKEditorModal.propTypes = {
  ckEditorData: PropTypes.string,
  companyQuoteSettings: PropTypes.object,
  companyQuoteTemplate: PropTypes.object,
  currentSettings: PropTypes.object,
  handleQuoteTemplateSave: PropTypes.func,
  handleRefreshSmartfieldsClick: PropTypes.func,
  hasMultipleVersions: PropTypes.bool,
  hasPermissionToEditQuote: PropTypes.bool,
  isLoading: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  openModal: PropTypes.bool,
  propertyDetails: PropTypes.object,
  quoteInfo: PropTypes.object,
  refreshCKEditor: PropTypes.object,
  setCKEditorData: PropTypes.func,
  setOpenModal: PropTypes.func,
  setRefreshCKEditor: PropTypes.func,
  setSendEmailModalOpen: PropTypes.func
};

CKEditorModal.defaultProps = {
  ckEditorData: '',
  companyQuoteSettings: {},
  companyQuoteTemplate: {},
  currentSettings: {},
  handleQuoteTemplateSave: () => {},
  handleRefreshSmartfieldsClick: () => {},
  hasPermissionToEditQuote: false,
  hasMultipleVersions: false,
  isLoading: false,
  isReadOnly: false,
  openModal: false,
  propertyDetails: {},
  quoteInfo: {},
  refreshCKEditor: {},
  setCKEditorData: () => {},
  setOpenModal: () => {},
  setRefreshCKEditor: () => {},
  setSendEmailModalOpen: () => {}
};

export default CKEditorModal;
