import React from 'react';

import { StatusChip } from 'components';
import { PermissionConstants, QuoteStatus } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import QuotesUtils from '../../scenes/Quotes/index.utils';

export const quoteRowMeta = flags => [
  {
    id: 'displayQuoteNumber',
    bold: true,
    label: 'Quote',
    showLink: 'view',
    linkPath: '/quote',
    linkReference: 'id'
  },
  {
    id: 'name',
    label: 'Title'
  },
  {
    id: 'status',
    filterKey: 'Quote.status',
    filterType: 'string',
    label: 'Status',
    type: 'enum',
    enumType: EnumType.QUOTE_STATUS,
    valueFormatter: value => (value === QuoteStatus.APPROVED ? QuoteStatus.JOB_ADDED : value),
    labelFormatter: value => {
      return value ? (
        <StatusChip
          label={QuotesUtils.mapQuoteStatusLabel(
            value === QuoteStatus.APPROVED ? QuoteStatus.JOB_ADDED : value
          )}
          enumType={EnumType.QUOTE_STATUS}
          enumValue={value === QuoteStatus.APPROVED ? QuoteStatus.JOB_ADDED : value}
        />
      ) : (
        ''
      );
    },
    internal: !flags[FeatureFlags.JOB_QUOTE_STATUS]
  },
  {
    id: 'createdDate',
    type: 'dateOnly',
    label: 'Created On'
  },
  {
    id: 'totalAmountQuoted',
    filterKey: 'Quote.totalAmountQuoted',
    filterType: 'number',
    type: 'currency',
    numeric: true,
    label: 'Amount',
    caslKey: [PermissionConstants.DATA_VIEW_PRICE_DATA, PermissionConstants.DATA_VIEW_COST_DATA],
    caslAction: 'allow'
  },
  {
    id: 'ownerValue',
    filterKey: 'Quote.ownerValue',
    filterType: 'string',
    label: 'Owner'
  }
];

export default quoteRowMeta;
