const styles = theme => ({
  card: {
    width: 114,
    height: 114,
    borderRadius: 4,
    border: 'none',
    borderShadow: 'none',
    backgroundColor: '#e0e0e4'
  },
  popoverHeader: {
    paddingTop: 14,
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 1,
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#959595'
  },
  popoverContent: {
    paddingTop: 1,
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 8,
    fontFamily: theme.typography.fontFamily,
    fontSize: '14',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.24,
    color: '#3f3f3f'
  },
  popoverComment: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 4,
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#959595'
  }
});
export default styles;
