import gql from 'graphql-tag';

export const CREWS = gql`
  query Crews($limit: Int, $offset: Int, $sort: [SortingInput]) {
    crews(pagination: { limit: $limit, offset: $offset }, sorting: $sort) {
      items {
        id
        name
        department {
          id
          tagName
        }
        foreman {
          id
          name
        }
        techs {
          id
          name
        }
      }
      nextToken
    }
  }
`;

export const SERVICE_AGREEMENTS_SETTINGS = gql`
  query getServiceAgreementsSettings($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      companySettings(
        entityConnection: "CompanySetting"
        filter: { type: { eq: "SERVICE_AGREEMENT" }, isActive: { eq: true } }
      ) {
        items {
          id
          version
          settings
        }
      }
    }
  }
`;
