const styles = theme => ({
  root: {
    width: '100%',
    justifyContent: 'left',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5)
  },
  title: {
    height: '32px',
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    fontWeight: '600',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.1px',
    paddingBottom: '10px',
    width: '100%'
  },
  subtitle1: theme.typography.subtitle1,
  pageActionButton: {
    float: 'right',
    width: '176px',
    height: '36px',
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(3.75),
    marginLeft: theme.spacing(2.5),
    color: theme.palette.grayscale(100)
  },
  inputRoot: {
    height: '100%'
  },
  textField: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: theme.palette.grayscale(40)
  }
});

export default styles;
