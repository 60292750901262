import { Mode, ExportStatus, SyncStatus } from 'utils/constants';
import { PaymentAccountTypeDisplay, PaymentAccountType } from '../constants';

const handlePaymentInvoices = (invoiceAmountMap = {}, paymentInvoices = []) => {
  const createPaymentInvoices = [];
  const updatePaymentInvoices = [];
  const deletePaymentInvoices = [];

  const appliedMap = paymentInvoices.reduce((map, item) => {
    const obj = { ...map };
    obj[item.invoiceId] = item;
    return obj;
  }, {});

  Object.entries(invoiceAmountMap).forEach(([invoiceId, appliedAmount]) => {
    const paymentInvoice = appliedMap[invoiceId];
    if (paymentInvoice) {
      if (appliedAmount === Mode.DELETE) {
        deletePaymentInvoices.push(paymentInvoice.id);
      } else {
        updatePaymentInvoices.push({
          id: paymentInvoice.id,
          version: paymentInvoice.version,
          appliedAmount
        });
      }
    } else if (appliedAmount !== Mode.DELETE) {
      createPaymentInvoices.push({ invoiceId, appliedAmount });
    }
  });

  return { createPaymentInvoices, updatePaymentInvoices, deletePaymentInvoices };
};

const handleAccountDetail = (accountTypeDisplay, bankAccountId, glAccountId) => {
  if (accountTypeDisplay) {
    const accountType =
      PaymentAccountTypeDisplay.BANK_ACCOUNT === accountTypeDisplay
        ? PaymentAccountType.BANK_ACCOUNT
        : PaymentAccountType.UNDEPOSITED_FUNDS_ACCOUNT;
    return {
      accountType,
      bankAccountId: accountType === PaymentAccountType.BANK_ACCOUNT ? bankAccountId : null,
      glAccountId: accountType === PaymentAccountType.UNDEPOSITED_FUNDS_ACCOUNT ? glAccountId : null
    };
  }
  return {};
};

const handlePayments = (payments = {}, paymentMap = {}, paymentInvoices = [], invoiceId) => {
  const appliedMap = paymentInvoices.reduce((map, item) => {
    const obj = { ...map };
    obj[item.parentId] = item;
    return obj;
  }, {});

  return Object.entries(payments)
    .map(([paymentId, appliedAmount]) => {
      const paymentInvoice = appliedMap[paymentId];
      const temp = paymentMap[paymentId];
      const payment = {
        id: temp.id,
        version: temp.version,
        syncStatus:
          temp.exportStatus === ExportStatus.EXPORTED
            ? SyncStatus.SYNCING
            : temp.syncStatus || undefined
      };

      if (paymentInvoice && paymentInvoice?.appliedAmount !== appliedAmount) {
        if (appliedAmount === Mode.DELETE) {
          payment.deletePaymentInvoices = [paymentInvoice.id];
        } else {
          payment.updatePaymentInvoices = [
            {
              id: paymentInvoice.id,
              version: paymentInvoice.version,
              appliedAmount
            }
          ];
        }
      } else if (!paymentInvoice && appliedAmount !== Mode.DELETE) {
        payment.createPaymentInvoices = [{ invoiceId, appliedAmount }];
      } else {
        // ignore payments with no change in appliedAmount
        return false;
      }
      return payment;
    })
    .filter(Boolean);
};

export default handlePaymentInvoices;
export { handlePaymentInvoices, handleAccountDetail, handlePayments };
