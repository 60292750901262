import React from 'react';
import ProductSearch from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';

export const ItemSearch = props => {
  const { options } = props;
  const onChange = item => {
    if (item?.id) {
      options.addItem({
        productId: item.id,
        sortKey: item.sortKey,
        quantity: null,
        priceBookEntryName: item.name,
        priceBookEntryDescription: item.description
      });
    }
  };

  return <ProductSearch {...props} options={{ ...options, onChange }} />;
};
