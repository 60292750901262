import gql from 'graphql-tag';

const getAllTechReportByCompany = gql`
  query getCompany(
    $partitionKey: String!
    $sortKey: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      sortKey
      visit: visitsView(
        entityConnection: "Visit"
        filter: $filter
        pagination: { limit: $limit, offset: $offset }
        sorting: $sort
      ) {
        nextToken
        items {
          id
          visitNumber
          description
          summaries {
            items {
              id
              summary
            }
          }
          departmentName
          status
          createdDate
          scheduledFor
          lastUpdatedBy
          lastUpdatedDateTime
          submittedDate
          submittedBy
          reviewReports(entityConnection: "ReviewReport") {
            items {
              id
              createdBy
              createdDate
            }
          }
          primaryTechs(entityConnection: "VisitPrimaryTech") {
            items {
              employee: mappedEntity {
                ... on Employee {
                  id
                  name
                  firstName
                  lastName
                }
              }
            }
          }
          job: parentEntity {
            ... on Job {
              id
              sortKey
              customIdentifier
              jobNumber
              closeoutReport
              customerName
              customerId
              customerSortKey
              customerPropertyName
              customerPropertyId
              customerPropertySortKey
              jobTypeName
              jobTypeInternal
              status
              completedDate
              issueDescription
              customerProperty {
                sameAddress
                companyAddresses {
                  items {
                    addressType
                    addressLine1
                    addressLine2
                    city
                    state
                    zipcode
                  }
                }
              }
              owner {
                id
                name
                firstName
                lastName
              }
              jobJobTags(entityConnection: "JobJobTag") {
                items {
                  id
                  entityType
                  # Using mappedEntity as 'getMappedEntityTagname' excepts data to be in same format
                  mappedEntity: jobTag {
                    id
                    entityType
                    tagName
                  }
                }
              }
              customer {
                companyAddresses {
                  items {
                    addressType
                    addressLine1
                    addressLine2
                    city
                    state
                    zipcode
                  }
                }
              }
              billingCustomer {
                companyAddresses {
                  items {
                    addressType
                    addressLine1
                    addressLine2
                    city
                    state
                    zipcode
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getAllTechReportByCompany;
