import gql from 'graphql-tag';

const getNonVisitEventsForEmployee = gql`
  query getNonVisitEventsForEmployee(
    $partitionKey: String!
    $sortKey: String!
    $employeeId: String!
    $startTime: Int!
    $endTime: Int!
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      nonVisitEventsView(
        entityConnection: "NonVisitEvent"
        filter: {
          stringFilters: [{ fieldName: "employeeId", filterInput: { eq: $employeeId } }]
          integerFilters: [
            { fieldName: "plannedStartTimeUTC", filterInput: { between: [$startTime, $endTime] } }
          ]
          booleanFilters: [{ fieldName: "isActive", filterInput: { ne: false } }]
        }
        sorting: [{ sortField: "plannedStartTimeUTC", sortDirection: "DESC" }]
        pagination: { limit: 300 }
      ) {
        items {
          id
          name
          description
          status
          entityType
          plannedStartTimeUTC
          isActive
          eventType(entityConnection: "EventType") {
            id
            name
          }
          timesheetNotes(entityConnection: "TimesheetNote") {
            items {
              id
              note
              subject
            }
          }
        }
      }
    }
  }
`;

export default getNonVisitEventsForEmployee;
