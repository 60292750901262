import gql from 'graphql-tag';

export const GetCompanyQuoteSettings = gql`
  query($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      companySettings(
        entityConnection: "CompanySetting"
        filter: { type: { eq: "QUOTE" }, isActive: { eq: true } }
      ) {
        items {
          id
          version
          settings
        }
      }
    }
  }
`;

export const CreateCompanyQuoteSettings = gql`
  mutation($partitionKey: String!, $companyId: String!, $settings: String!) {
    addCompanySettingsToCompany(
      partitionKey: $partitionKey
      data: {
        companyId: $companyId
        companySettings: { type: QUOTE, settings: $settings, isActive: true }
      }
    ) {
      id
      version
      settings
    }
  }
`;

export const UpdateCompanyQuoteSettings = gql`
  mutation($partitionKey: String!, $settings: String!, $id: String!, $version: Int!) {
    updateCompanySetting(
      partitionKey: $partitionKey
      data: { type: QUOTE, settings: $settings, isActive: true, id: $id, version: $version }
    ) {
      id
      version
      settings
    }
  }
`;

export const GetCompanyQuoteTemplate = gql`
  query($tenantId: String!) {
    getQuoteTemplates(tenantId: $tenantId) {
      items {
        id
        template
        version
      }
    }
  }
`;

export const SaveCompanyQuoteTemplate = gql`
  mutation($tenantId: String!, $items: [QuoteTemplateInput]) {
    saveQuoteTemplate(tenantId: $tenantId, items: $items) {
      id
      template
      version
    }
  }
`;
