import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { AutoCompleteDropdown } from 'components';
import {
  getWeekDays,
  timeValidation,
  getHourSettingValue,
  getAutoCompleteProps,
  getKeyboardTimePickerProps,
  getEmployeeIds
} from './helpers';
import { RenderHeader, ItemWrapper } from './TinyComponents';
import { dayTableMeta, timeFields, afterHourTimeFormat } from './constants';
import useStyles from './AfterHoursTimings.styles';

const AfterHoursTimings = ({ form, options }) => {
  const { employeeOptions = [], validEmployees = [] } = options;
  const { afterHoursTimings = [] } = form?.values || {};
  const weekDays = getWeekDays();
  const classes = useStyles();

  const handleItemEdit = (value, index) => {
    const clonedAfterHours = [...afterHoursTimings];
    clonedAfterHours[index] = value;
    form.setFieldValue('afterHoursTimings', clonedAfterHours);
  };

  const shouldUpdateAfterHoursTechs = getEmployeeIds(afterHoursTimings).some(
    techId => !validEmployees.includes(techId)
  );

  if (shouldUpdateAfterHoursTechs) {
    const updatedAfterHoursTimings = afterHoursTimings.reduce((timings, timing) => {
      const { technicianIds = [] } = timing;
      return [
        ...timings,
        {
          ...timing,
          technicianIds: technicianIds.filter(techId => validEmployees.includes(techId))
        }
      ];
    }, []);
    form.setFieldValue('afterHoursTimings', updatedAfterHoursTimings);
  }

  return (
    <Box display="flex" flexDirection="column">
      <RenderHeader items={dayTableMeta} />
      <Box display="flex" flexDirection="column">
        {weekDays.map(weekDay => {
          const { dayIndex } = weekDay;
          const value = getHourSettingValue(afterHoursTimings, dayIndex);
          return (
            <Box key={dayIndex} alignItems="center" display="flex">
              <Box className={classes.itemBorder} alignItems="center" display="flex">
                <ItemWrapper>
                  <Typography variant="body1">{weekDay.dayString}</Typography>
                </ItemWrapper>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                  <Box display="flex">
                    {timeFields.map((timeField, i) => {
                      const validationError = timeValidation(value, timeField);
                      const timeValue = value[timeField];
                      return (
                        <ItemWrapper key={i}>
                          <KeyboardTimePicker
                            error
                            helperText={validationError}
                            validationError={validationError}
                            value={timeValue ? moment(timeValue, afterHourTimeFormat) : null}
                            {...getKeyboardTimePickerProps(classes)}
                            onChange={time =>
                              handleItemEdit(
                                {
                                  ...value,
                                  [timeField]: time ? time.format(afterHourTimeFormat) : null
                                },
                                dayIndex
                              )
                            }
                          />
                        </ItemWrapper>
                      );
                    })}
                  </Box>
                </MuiPickersUtilsProvider>
                <ItemWrapper>
                  <AutoCompleteDropdown
                    isMulti
                    name="technicianIds"
                    value={value.technicianIds}
                    options={employeeOptions}
                    {...getAutoCompleteProps(classes, false)}
                    handleOnChange={values =>
                      handleItemEdit({ ...value, technicianIds: values }, dayIndex)
                    }
                  />
                </ItemWrapper>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default AfterHoursTimings;
