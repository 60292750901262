import gql from 'graphql-tag';

const createInvoiceForCustomerProperty = gql`
  mutation createInvoiceForCustomerProperty(
    $partitionKey: String
    $data: CreateInvoiceForCustomerProperty
  ) {
    createInvoiceForCustomerProperty(partitionKey: $partitionKey, data: $data) {
      id
      invoiceNumber
      sortKey
    }
  }
`;

export default createInvoiceForCustomerProperty;
