export const TenantRepLayout = existingReps => ({
  fields: {
    name: {
      default: 'name'
    },
    repNotes: {
      default: 'repNotes'
    },
    tenantRep: {
      default: 'tenantRep'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 1
      },
      contents: [
        {
          options: {
            direction: 'row',
            grow: 1
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    new: 'SelectInput',
                    edit: 'FieldWithLabel'
                  },
                  source: {
                    new: 'name',
                    edit: 'name'
                  },
                  options: {
                    label: {
                      new: 'Choose Representative',
                      edit: 'Name'
                    },
                    inputOptions: {
                      new: existingReps.map((rep, idx) => ({ value: `${idx}`, label: rep.name }))
                    },
                    // isRequired: true,
                    fullWidth: true,
                    alignment: 'left',
                    isSearchable: true,
                    onChange: {
                      new: (selectedOption, form) => {
                        form.setFieldValue(
                          'tenantRep',
                          selectedOption ? existingReps[selectedOption.value] : null
                        );
                      }
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'column',
            grow: 1,
            padding: 10
          },
          contents: [
            {
              component: {
                new: 'NotesWrapper',
                edit: 'NotesWrapper'
              },
              source: 'repNotes',
              options: {
                label: 'Notes',
                mode: {
                  new: 'edit',
                  edit: 'edit'
                },
                isRequired: false,
                fullWidth: true,
                alignment: 'left'
              }
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        required: true
      }
    }
  },
  validationErrors: {
    name: {
      required: 'Name is required'
    }
  }
});
