import { makeStyles } from '@material-ui/core/styles';
import buildHeroMuiFormOverrides from '@pm/components/buildHeroMuiFormOverrides';

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: '0px',
    right: '0px'
  },
  formSectionContainer: {
    marginLeft: '25px',
    marginRight: '25px'
  },
  formTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    paddingBottom: '10px'
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px'
  },
  searchBar: {
    float: 'left'
  },
  generateButton: {
    float: 'right',
    marginBottom: 20
  },
  formContainer: {
    ...buildHeroMuiFormOverrides(theme),
    '& [class*="MuiInputBase-root"]': {
      height: 41
    }
  }
}));
