import { any, bool, object } from 'prop-types';

export const ReportTableSectionPropTypes = {
  visit: object.isRequired,
  loading: bool.isRequired,
  error: any
};

export const ReportTableSectionDefaultProps = {
  error: undefined,
  editable: false
};
