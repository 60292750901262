import gql from 'graphql-tag';

const getVisitDetails = gql`
  query getVisitDetails($partitionKey: String!, $sortKey: String!) {
    getVisit(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      tenantId
      hierarchy
      entityType
      createdDateTime
      visitNumber
      prerequisites
      prerequisitesAcknowledged
      status
      version
      visitAssets(entityConnection: "VisitAsset", filter: { propertyAssetId: { ne: "null" } }) {
        items {
          id
          propertyAsset(entityConnection: "PropertyAsset") {
            id
            assetName
            make
            modelNumber
            serialNo
            installDate
            location
            propertyZone
            imageUrl
          }
        }
      }
      purchaseOrders(entityConnection: "PurchaseOrder") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          tenantId
          hierarchy
          entityType
          vendorName
          receiptNumber
          poNumber
          createdBy
          dateOfPurchase
          totalAmountPreTax
          tax
          purchaseOrderReceipts(entityConnection: "PurchaseOrderReceipt") {
            items {
              id
              fileName
              imageUrl
              order
              version
            }
          }
          purchaseOrderLines(entityConnection: "PurchaseOrderLine") {
            items {
              id
              entityType
              itemName
              quantity
              unitCost
              markup
              unitPrice
              amount
              createdBy
              version
            }
          }
        }
      }
      inventoryParts(entityConnection: "InventoryPart") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          tenantId
          hierarchy
          entityType
          itemName
          description
          quantity
          unitCost
          unitPrice
          inventoryType
          vendorName
          unitOfMeasure
          markup
          amount
          taxable
          totalAmountTaxable
          tax
          createdBy
        }
      }
      summaries(entityConnection: "Summary") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          tenantId
          hierarchy
          entityType
          summary
          createdBy
          lastUpdatedDateTime
          lastUpdatedBy
        }
      }
      attachments(entityConnection: "Attachment") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          tenantId
          hierarchy
          entityType
          customFileName
          fileName
          fileUrl
          dateAdded
          description
          comment
          sortOrder
        }
      }
      notes(entityConnection: "Note") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          tenantId
          hierarchy
          entityType
          createdBy
          subject
          createdDate
          version
        }
      }
    }
  }
`;

export default getVisitDetails;
