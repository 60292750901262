import { Actions } from './Dispatch.actions';

const defaultState = {};

const DRAWER_EMPTY_PARAMS = {
  visitId: '',
  billableEventId: '',
  nonBillableEventId: '',
  manDayId: '',
  eventId: '',
  eventType: ''
};

export const querystringReducer = (state = defaultState, action) => {
  switch (action.type) {
    case Actions.OPEN_VISIT: {
      return {
        ...state,
        ...DRAWER_EMPTY_PARAMS,
        visitId: action.payload.visitId
      };
    }
    case Actions.OPEN_BILLABLE_EVENT: {
      return {
        ...state,
        ...DRAWER_EMPTY_PARAMS,
        billableEventId: action.payload.billableEventId
      };
    }
    case Actions.OPEN_NON_BILLABLE_EVENT: {
      return {
        ...state,
        ...DRAWER_EMPTY_PARAMS,
        nonBillableEventId: action.payload.nonBillableEventId
      };
    }
    case Actions.OPEN_MAN_DAY: {
      return {
        ...state,
        ...DRAWER_EMPTY_PARAMS,
        manDayId: action.payload.manDayId
      };
    }
    case Actions.CLOSE_VISIT_OR_EVENT: {
      return {
        ...state,
        ...DRAWER_EMPTY_PARAMS
      };
    }
    case Actions.OPEN_TRAY: {
      return {
        ...state,
        trayOpen: true
      };
    }
    case Actions.CLOSE_TRAY: {
      return {
        ...state,
        trayOpen: undefined
      };
    }
    case Actions.OPEN_TAB: {
      return {
        ...state,
        tab: action.payload.tab,
        trayOpen: true
      };
    }
    case Actions.FILTER_BY: {
      return {
        ...state,
        ...action.payload
      };
    }
    case Actions.SELECT_DAY: {
      return {
        ...state,
        day: action.payload
      };
    }
    case Actions.SELECT_END_DAY: {
      return {
        ...state,
        endDay: action.payload
      };
    }
    case Actions.SET_WEEK_VIEW: {
      return {
        ...state,
        weekView: action.payload ? true : undefined
      };
    }
    case Actions.SET_MAP_VIEW: {
      return {
        ...state,
        mapView: action.payload ? true : undefined
      };
    }
    default: {
      return state;
    }
  }
};

export const appReducer = (state, action) => {
  switch (action.type) {
    case Actions.HOVER_VISIT_ROW: {
      return {
        ...state,
        highlightedTechs: [...action.payload.primaryTechIds, ...action.payload.extraTechIds]
      };
    }
    case Actions.CLEAR_VISIT_ROW_HOVER: {
      return {
        ...state,
        highlightedTechs: []
      };
    }
    case Actions.CREATE_EVENT_WITH_DATA: {
      return {
        ...state,
        eventType: action.payload.eventType,
        eventData: action.payload.eventData
      };
    }
    case Actions.CLEAR_DRAWER_DATA: {
      return {
        ...state,
        eventType: undefined,
        eventData: undefined
      };
    }
    default: {
      return state;
    }
  }
};
