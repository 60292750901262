import gql from 'graphql-tag';
import taskFragment from '../../jobs/queries/taskFragment';

const addTasksToCustomerPropertyAndJob = gql`
  mutation addTasksToCustomerPropertyAndJob(
    $partitionKey: String!
    $data: AddTasksToCustomerPropertyAndJobInput!
  ) {
    addTasksToCustomerPropertyAndJob(partitionKey: $partitionKey, data: $data) {
      ...taskFields
      sortKey
      partitionKey
      departmentId
      departmentValue
      taskTypeId
      taskTypeValue
      taskTypeInternal
      assetValue
      nextDueDate
      isActive
      lastUpdatedDate
    }
  }
  ${taskFragment}
`;

export default addTasksToCustomerPropertyAndJob;
