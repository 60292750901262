import compose from 'utils/compose';
import { withQueryStringStore } from 'customHooks/useQueryStringStore';
import { withDispatchActions } from '@dispatch/Dispatch.store';
import CreateEventMenu from './CreateEventMenu.component';

const mapActionsToProps = ({ createEvent }) => ({
  createEvent
});

const mapQueryStringToProps = store => ({
  day: store.state.day
});

export default compose(
  withQueryStringStore(mapQueryStringToProps),
  withDispatchActions(mapActionsToProps)
)(CreateEventMenu);
