import { calculateMarginFromMarkup } from '@buildhero/math';

import { roundCurrency, roundFloat } from 'utils';
import { convertForMathLib } from 'utils/mathLibrary';
import { getMarkupFromMargin } from 'utils/onCalcChange';

export const selectInventoryInput = (formData, marginEnabled) => ({
  costCodeId: formData?.costCode?.value || null,
  departmentName: formData?.departmentName,
  description: formData?.description,
  itemName: formData?.name,
  jobCostTypeId: formData?.jobCostType?.value || null,
  markup: marginEnabled
    ? getMarkupFromMargin(formData?.marginValue)
    : roundFloat(formData?.markupValue),
  productId: formData?.productId,
  quantity: formData?.quantity,
  revenueTypeId: formData?.revenueType?.value,
  taxable: formData?.taxable,
  truckId: formData?.truck?.value,
  unitCost: roundFloat(formData?.unitCost),
  unitOfMeasure: formData?.product?.unitOfMeasure?.name,
  unitPrice: roundCurrency(formData?.unitPrice)
});

export const selectInventoryItemUpdateInput = (formData, marginEnabled) => ({
  ...selectInventoryInput(formData, marginEnabled),
  id: formData?.id,
  version: formData?.version,
  includeInInvoice: formData?.includeInInvoice
});

export const selectInventoryItemFormData = inventoryItem => ({
  ...inventoryItem,
  markupValue: inventoryItem?.markup,
  marginValue: convertForMathLib(calculateMarginFromMarkup, inventoryItem?.markup),
  addedBy: inventoryItem.addedBy || inventoryItem.createdBy,
  amount: roundCurrency(inventoryItem.quantity * inventoryItem.unitPrice),
  unitOfMeasure: inventoryItem?.product?.unitOfMeasure?.name,
  costCode: !inventoryItem?.costCode?.id
    ? null
    : {
        value: inventoryItem.costCode.id,
        label: inventoryItem.costCode.name
      },
  jobCostType: !inventoryItem?.jobCostType?.id
    ? null
    : {
        value: inventoryItem.jobCostType.id,
        label: inventoryItem.jobCostType.name,
        type: inventoryItem.jobCostType.type
      },
  revenueType: !inventoryItem?.revenueType?.id
    ? null
    : {
        value: inventoryItem.revenueType.id,
        label: inventoryItem.revenueType.name,
        type: inventoryItem.revenueType.type
      },
  truck: !inventoryItem?.truck?.id
    ? null
    : {
        value: inventoryItem.truck.id,
        label: inventoryItem.truck.name
      }
});
