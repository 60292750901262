import {
  BillLineItemStatus,
  JOB_CLOSEOUT_STATUS,
  JOB_REPORT_STATUS,
  JobBillingStatus,
  JobProcurementStatus,
  jobQuoteStatus,
  JobStatus,
  MaintenanceStatus,
  OpenTaskStatus,
  ProcurementPurchaseOrderReceiptStatus,
  ProcurementPurchaseOrderStatus,
  ProjectManagementPayApplicationStatus,
  ProjectManagementSubmittalStatus,
  QuoteStatus,
  ServiceAgreementStatus,
  TimesheetReviewStatusTypes,
  VendorApprovalStatus,
  VISIT_REVIEW_STATUS,
  VisitStatus
} from 'utils/AppConstants';
import {
  AdjustmentStatus,
  EnumType,
  ExportStatus,
  InvoiceStatus,
  PaymentStatus,
  ServiceChannelWorkOrderStatus,
  SyncStatus,
  WIPReportStatus
} from 'utils/constants';

import InvoicesIcon from '../../assets/Icons/Invoices';
import JobsIcon from '../../assets/Icons/Jobs';
import ProcurementIcon from '../../assets/Icons/Procurement';
import QuoteIcon from '../../assets/Icons/Quotes';
import ReportsIcon from '../../assets/Icons/Reports';

export const enumBackgroundColor = theme => ({
  // add support for sergeant theme

  [EnumType.BILL_STATUS]: {
    [BillLineItemStatus.INVOICED]:
      theme.palette.other?.statusGreen ?? theme.palette.support.green.medium,
    [BillLineItemStatus.PARTIALLY_INVOICED]:
      theme.palette.other?.statusOrange ?? theme.palette.support.orange.medium,
    [BillLineItemStatus.NOT_INVOICED]: theme.palette.grayscale(80)
  },
  [EnumType.MAINTENANCE_STATUS]: {
    [MaintenanceStatus.OVERDUE]: theme.palette.other?.statusRed ?? theme.palette.support.red.medium,
    [MaintenanceStatus.SCHEDULED]:
      theme.palette.other?.statusGreen ?? theme.palette.support.green.medium,
    [MaintenanceStatus.UNSCHEDULED]:
      theme.palette.other?.statusBlue ?? theme.palette.support.blue.medium,
    [MaintenanceStatus.SKIPPED]:
      theme.palette.other?.statusOrange ?? theme.palette.support.orange.medium,
    [MaintenanceStatus.COMPLETED]: theme.palette.grayscale(80)
  },
  [EnumType.SERVICE_AGREEMENT_STATUS]: {
    [ServiceAgreementStatus.ACTIVE]:
      theme.palette.other?.statusGreen ?? theme.palette.support.green.medium,
    [ServiceAgreementStatus.DRAFT]: theme.palette.grayscale(80),
    [ServiceAgreementStatus.APPROVED]:
      theme.palette.other?.statusBlue ?? theme.palette.support.blue.medium,
    [ServiceAgreementStatus.EXPIRED]:
      theme.palette.other?.greyScale ?? theme.palette.support.grey.dark,
    [ServiceAgreementStatus.CANCELLED]:
      theme.palette.other?.statusOrange ?? theme.palette.support.orange.medium,
    [ServiceAgreementStatus.CANCELED]:
      theme.palette.other?.statusOrange ?? theme.palette.support.orange.medium
  },
  [EnumType.JOB_STATUS]: {
    [JobStatus.OPEN]: theme.palette.grayscale(20),
    [JobStatus.IN_PROGRESS]: theme.palette.support.blue.dark,
    [JobStatus.ON_HOLD]: theme.palette.support.yellow.dark,
    [JobStatus.CANCELED]: theme.palette.text.primary,
    [JobStatus.COMPLETE]: theme.palette.support.green.dark,
    [JobStatus.SCHEDULED]: theme.palette.grayscale(85),
    [JobStatus.EXPORTED]: theme.palette.grayscale(85),
    [JobStatus.UNSCHEDULED]: theme.palette.grayscale(85)
  },
  [EnumType.JOB_PROCUREMENT_STATUS]: {
    [JobProcurementStatus.POS_IN_DRAFT]: theme.palette.support.yellow.dark,
    [JobProcurementStatus.POS_ORDERED]: theme.palette.support.orange.dark,
    [JobProcurementStatus.POS_PARTIALLY_RECEIVED]: theme.palette.support.blue.dark,
    [JobProcurementStatus.POS_RECEIVED]: theme.palette.support.green.dark,
    [JobProcurementStatus.NO_POS]: theme.palette.grayscale(20),
    [JobProcurementStatus.POS_NEEDED]: theme.palette.support.yellow.dark
  },
  [EnumType.JOB_QUOTE_STATUS]: {
    [jobQuoteStatus.NO_QUOTES]: theme.palette.grayscale(20),
    [jobQuoteStatus.QUOTE_NEEDED]: theme.palette.support.yellow.dark,
    [jobQuoteStatus.QUOTE_IN_DRAFT]: theme.palette.support.orange.dark,
    [jobQuoteStatus.QUOTE_SENT]: theme.palette.support.blue.dark,
    [jobQuoteStatus.QUOTE_APPROVED]: theme.palette.support.green.dark,
    [jobQuoteStatus.QUOTE_REJECTED]: theme.palette.support.red.dark
  },
  [EnumType.JOB_REPORT_STATUS]: {
    [JOB_REPORT_STATUS.PROCESSING]: theme.palette.support.yellow.medium,
    [JOB_REPORT_STATUS.READY]: theme.palette.support.green.medium,
    [JOB_REPORT_STATUS.ERROR]: theme.palette.support.red.medium
  },
  [EnumType.TIMESHEET_REVIEW_STATUS]: {
    [TimesheetReviewStatusTypes.PENDING]:
      theme.palette.other?.statusRed ?? theme.palette.support.red.medium,
    [TimesheetReviewStatusTypes.REVIEWED]:
      theme.palette.other?.statusYellow ?? theme.palette.support.yellow.medium,
    [TimesheetReviewStatusTypes.APPROVED]:
      theme.palette.other?.statusGreen ?? theme.palette.support.green.medium
  },
  [EnumType.INVOICE_STATUS]: {
    [InvoiceStatus.DRAFT]: theme.palette.grayscale(80),
    [InvoiceStatus.POSTED]:
      theme.palette.other?.statusOrange ?? theme.palette.support.orange.medium,
    [InvoiceStatus.EXPORTED]: theme.palette.other?.statusBlue ?? theme.palette.support.blue.medium,
    [InvoiceStatus.CLOSED]: theme.palette.other?.statusGreen ?? theme.palette.support.green.medium,
    [InvoiceStatus.VOID]: theme.palette.other?.statusRed ?? theme.palette.support.red.medium
  },
  [EnumType.QUOTE_STATUS]: {
    [QuoteStatus.APPROVED]:
      theme.palette.other?.statusLightGreen ?? theme.palette.support.green.medium,
    [QuoteStatus.CANCELLED]:
      theme.palette.other?.statusLightRed ?? theme.palette.support.red.medium,
    [QuoteStatus.CUSTOMER_VIEWED]:
      theme.palette.other?.statusLightBlue ?? theme.palette.support.blue.medium,
    [QuoteStatus.DISCARDED]: theme.palette.other?.statusRed ?? theme.palette.support.red.medium,
    [QuoteStatus.DRAFT]: theme.palette.grayscale(80),
    [QuoteStatus.EMAIL_BOUNCED]:
      theme.palette.other?.statusLightYellow ?? theme.palette.support.yellow.medium,
    [QuoteStatus.EMAIL_READ]:
      theme.palette.other?.statusLightBlue ?? theme.palette.support.blue.medium,
    [QuoteStatus.JOB_ADDED]:
      theme.palette.other?.statusOrange ?? theme.palette.support.orange.medium,
    [QuoteStatus.SENT_TO_CUSTOMER]:
      theme.palette.other?.statusLightBlue ?? theme.palette.support.blue.medium,
    [QuoteStatus.REJECTED]: theme.palette.other?.statusLightRed ?? theme.palette.support.red.medium
  },
  [EnumType.SYNC_STATUS]: {
    [SyncStatus.NOT_SYNCED]: theme.palette.grayscale(80),
    [SyncStatus.SYNCING]: theme.palette.other?.statusBlue ?? theme.palette.support.blue.medium,
    [SyncStatus.IN_SYNC]: theme.palette.other?.statusGreen ?? theme.palette.support.green.medium,
    [SyncStatus.SYNC_FAILED]: theme.palette.other?.statusRed ?? theme.palette.support.red.medium,
    [SyncStatus.BYPASSED]: theme.palette.other?.statusOrange ?? theme.palette.support.orange.medium
  },
  [EnumType.EXPORT_STATUS]: {
    [ExportStatus.PENDING]: theme.palette.grayscale(80),
    [ExportStatus.POSTED]: theme.palette.other?.statusOrange ?? theme.palette.support.orange.medium,
    [ExportStatus.EXPORTED]: theme.palette.other?.statusBlue ?? theme.palette.support.blue.medium,
    [ExportStatus.BYPASSED]:
      theme.palette.other?.statusOrange ?? theme.palette.support.orange.medium,
    [ExportStatus.CLOSED]: theme.palette.other?.statusGreen ?? theme.palette.support.green.medium,
    [ExportStatus.VOIDED]: theme.palette.other?.statusRed ?? theme.palette.support.red.medium
  },
  [EnumType.ADJUSTMENT_STATUS]: {
    [AdjustmentStatus.UNAPPLIED]: theme.palette.grayscale(80),
    [AdjustmentStatus.PARTIALLY_APPLIED]:
      theme.palette.other?.statusBlue ?? theme.palette.support.blue.medium,
    [AdjustmentStatus.APPLIED]:
      theme.palette.other?.statusGreen ?? theme.palette.support.green.medium
  },
  [EnumType.PAYMENT_STATUS]: {
    [PaymentStatus.UNAPPLIED]: theme.palette.grayscale(80),
    [PaymentStatus.PARTIALLY_APPLIED]:
      theme.palette.other?.statusBlue ?? theme.palette.support.blue.medium,
    [PaymentStatus.APPLIED]: theme.palette.other?.statusGreen ?? theme.palette.support.green.medium
  },
  [EnumType.PROJECT_MANAGEMENT_SUBMITTAL_STATUS]: {
    [ProjectManagementSubmittalStatus.PENDING]:
      theme.palette.other?.statusOrange ?? theme.palette.support.orange.medium,
    [ProjectManagementSubmittalStatus.TO_BE_SUBMITTED]:
      theme.palette.other?.statusOrange ?? theme.palette.support.orange.medium,
    [ProjectManagementSubmittalStatus.PACKAGED]:
      theme.palette.other?.statusBlue ?? theme.palette.support.blue.medium,
    [ProjectManagementSubmittalStatus.SENT]: theme.palette.grayscale(80),
    [ProjectManagementSubmittalStatus.REJECTED]:
      theme.palette.other?.statusRed ?? theme.palette.support.red.medium,
    [ProjectManagementSubmittalStatus.APPROVED ||
    ProjectManagementSubmittalStatus.APPROVED_AS_NOTED]:
      theme.palette.other?.statusGreen ?? theme.palette.support.green.medium
  },
  [EnumType.PROJECT_MANAGEMENT_PAY_APPLICATION_STATUS]: {
    [ProjectManagementPayApplicationStatus.POSTED]:
      theme.palette.other?.statusBlue ?? theme.palette.support.blue.medium,
    [ProjectManagementPayApplicationStatus.VOID]:
      theme.palette.other?.statusRed ?? theme.palette.support.red.medium,
    [ProjectManagementPayApplicationStatus.DRAFT]:
      theme.palette.other?.statusGreen ?? theme.palette.support.green.medium
  },
  [EnumType.PROCUMENT_PURCHASE_ORDER_STATUS]: {
    [ProcurementPurchaseOrderStatus.DRAFT]: theme.palette.grayscale(80),
    [ProcurementPurchaseOrderStatus.ORDERED]:
      theme.palette.other?.statusBlue ?? theme.palette.support.blue.medium,
    [ProcurementPurchaseOrderStatus.PARTIALLY_FULFILLED]:
      theme.palette.other?.statusOrange ?? theme.palette.support.orange.medium,
    [ProcurementPurchaseOrderStatus.FULFILLED]:
      theme.palette.other?.statusGreen ?? theme.palette.support.green.medium,
    [ProcurementPurchaseOrderStatus.VOID]:
      theme.palette.other?.statusRed ?? theme.palette.support.red.medium
  },
  [EnumType.PROCUMENT_PURCHASE_ORDER_RECEIPT_STATUS]: {
    [ProcurementPurchaseOrderReceiptStatus.CLOSED]: theme.palette.grayscale(80),
    [ProcurementPurchaseOrderReceiptStatus.PENDING]:
      theme.palette.other?.statusOrange ?? theme.palette.support.orange.medium,
    [ProcurementPurchaseOrderReceiptStatus.POSTED]:
      theme.palette.other?.statusGreen ?? theme.palette.support.green.medium,
    [ProcurementPurchaseOrderReceiptStatus.EXPORTED]:
      theme.palette.other?.statusBlue ?? theme.palette.support.blue.medium,
    [ProcurementPurchaseOrderStatus.ORDERED]:
      theme.palette.other?.statusBlue ?? theme.palette.support.blue.medium,
    [ProcurementPurchaseOrderStatus.PARTIALLY_FULFILLED]:
      theme.palette.other?.statusOrange ?? theme.palette.support.orange.medium,
    [ProcurementPurchaseOrderStatus.FULFILLED]:
      theme.palette.other?.statusGreen ?? theme.palette.support.green.medium
  },
  [EnumType.OPEN_TASK_STATUS]: {
    [OpenTaskStatus.OPEN]: theme.palette.grayscale(80),
    [OpenTaskStatus.ADDED_TO_WORK]:
      theme.palette.other?.statusBlue ?? theme.palette.support.blue.medium,
    [OpenTaskStatus.UNQUOTED]:
      theme.palette.other?.statusLightGreen ?? theme.palette.support.green.medium,
    [OpenTaskStatus.ADDED_TO_QUOTE]:
      theme.palette.other?.supportYellow1 ?? theme.palette.support.yellow.medium
  },
  [EnumType.WIP_REPORT_STATUS]: {
    [WIPReportStatus.PENDING]: theme.palette.grayscale(80),
    [WIPReportStatus.POSTED]:
      theme.palette.other?.statusOrange ?? theme.palette.support.orange.medium,
    [WIPReportStatus.EXPORTED]:
      theme.palette.other?.statusBlue ?? theme.palette.support.blue.medium,
    [WIPReportStatus.CLOSED]: theme.palette.other?.statusGreen ?? theme.palette.support.green.medium
  },
  [EnumType.SERVICE_CHANNEL_WORK_ORDER_STATUS]: {
    [ServiceChannelWorkOrderStatus.Open]: theme.palette.grayscale(80),
    [ServiceChannelWorkOrderStatus.JobCreated]:
      theme.palette.other?.statusGreen ?? theme.palette.support.green.medium,
    [ServiceChannelWorkOrderStatus.Error]:
      theme.palette.other?.statusRed ?? theme.palette.support.red.medium,
    [ServiceChannelWorkOrderStatus.Discarded]:
      theme.palette.other?.statusOrange ?? theme.palette.support.orange.medium
  },
  [EnumType.JOB_CLOSEOUT_STATUS]: {
    [JOB_CLOSEOUT_STATUS.REVIEWED]:
      theme.palette.support.green.dark ?? theme.palette.support.green.medium,
    [JOB_CLOSEOUT_STATUS.NO_REVIEW_NEEDED]: theme.palette.grayscale(20),
    [JOB_CLOSEOUT_STATUS.REVIEW_NEEDED]: theme.palette.support.yellow.dark
  },
  [EnumType.VISIT_REVIEW_STATUS]: {
    [VISIT_REVIEW_STATUS.REVIEWED]:
      theme.palette.other?.statusGreen ?? theme.palette.support.green.medium,
    [VISIT_REVIEW_STATUS.UNREVIEWED]: theme.palette.support.yellow.dark
  },
  [EnumType.VISIT_STATUS]: {
    [VisitStatus.UNASSIGNED]: theme.palette.grayscale(50),
    [VisitStatus.SCHEDULED]: theme.palette.grayscale(50),
    [VisitStatus.TRAVELING]: theme.palette.support.blue.dark,
    [VisitStatus.WORKING]: theme.palette.support.blue.dark,
    [VisitStatus.PAUSED]: theme.palette.support.yellow.dark,
    [VisitStatus.ONHOLD]: theme.palette.support.orange.dark,
    [VisitStatus.COMPLETE]: theme.palette.support.green.dark,
    [VisitStatus.CONVERTED]: theme.palette.support.green.dark,
    [VisitStatus.CANCELED]: theme.palette.support.red.dark,
    [VisitStatus.CLOSED]: theme.palette.support.red.dark
  },
  [EnumType.JOB_BILLING_STATUS]: {
    [JobBillingStatus.NOT_INVOICED]: theme.palette.grayscale(50),
    [JobBillingStatus.DO_NOT_INVOICE]: theme.palette.grayscale(20),
    [JobBillingStatus.READY_TO_INVOICE]: theme.palette.support.yellow.dark,
    [JobBillingStatus.PARTIALLY_INVOICED]: theme.palette.support.blue.dark,
    [JobBillingStatus.FULLY_INVOICED]: theme.palette.support.green.dark
  },
  [EnumType.VENDOR_APPROVAL_STATUS]: {
    [VendorApprovalStatus.APPROVED]: theme.palette.support.green.dark,
    [VendorApprovalStatus.REVIEW_NEEDED]: theme.palette.support.yellow.dark,
    [VendorApprovalStatus.UNREVIEWED]: theme.palette.grayscale(20),
    [VendorApprovalStatus.REJECTED]: theme.palette.support.red.dark
  }
  // add other enum types here like visit or payment or whatever
});

export const enumIcon = theme => ({
  [EnumType.JOB_PROCUREMENT_STATUS]: <ProcurementIcon css={{ width: 14, marginRight: 0 }} />,
  [EnumType.JOB_QUOTE_STATUS]: <QuoteIcon css={{ width: 14, marginRight: 0 }} />,
  [EnumType.JOB_STATUS]: <JobsIcon css={{ width: 14, marginRight: 0 }} />,
  [EnumType.JOB_CLOSEOUT_STATUS]: (
    <ReportsIcon css={{ width: 14, marginRight: 0, color: theme.palette.grayscale(100) }} />
  ),
  [EnumType.VISIT_REVIEW_STATUS]: <ReportsIcon css={{ width: 14, marginRight: 0 }} />,
  [EnumType.INVOICE_STATUS]: <InvoicesIcon css={{ width: 14, marginRight: 0 }} />,
  [EnumType.INVOICE_BILLING_STATUS]: (
    <InvoicesIcon css={{ width: 14, marginRight: 0, color: theme.palette.grayscale(100) }} />
  ),
  [EnumType.JOB_BILLING_STATUS]: (
    <InvoicesIcon css={{ width: 14, marginRight: 0, color: theme.palette.grayscale(100) }} />
  ),
  [EnumType.VENDOR_APPROVAL_STATUS]: (
    <ProcurementIcon css={{ width: 14, marginRight: 0, color: theme.palette.grayscale(100) }} />
  )
});

// override default text color. for e.g: black backgroud should have white text
export const enumTextColor = {
  [EnumType.SERVICE_AGREEMENT_STATUS]: {
    [ServiceAgreementStatus.EXPIRED]: 'secondary'
  },
  [EnumType.JOB_PROCUREMENT_STATUS]: {
    [JobProcurementStatus.POS_IN_DRAFT]: 'secondary',
    [JobProcurementStatus.POS_ORDERED]: 'secondary',
    [JobProcurementStatus.POS_PARTIALLY_RECEIVED]: 'secondary',
    [JobProcurementStatus.POS_RECEIVED]: 'secondary',
    [JobProcurementStatus.NO_POS]: 'secondary',
    [JobProcurementStatus.POS_NEEDED]: 'secondary'
  },
  [EnumType.JOB_QUOTE_STATUS]: {
    [jobQuoteStatus.NO_QUOTES]: 'secondary',
    [jobQuoteStatus.QUOTE_NEEDED]: 'secondary',
    [jobQuoteStatus.QUOTE_IN_DRAFT]: 'secondary',
    [jobQuoteStatus.QUOTE_SENT]: 'secondary',
    [jobQuoteStatus.QUOTE_APPROVED]: 'secondary',
    [jobQuoteStatus.QUOTE_REJECTED]: 'secondary'
  },
  [EnumType.JOB_CLOSEOUT_STATUS]: {
    [JOB_CLOSEOUT_STATUS.REVIEW_NEEDED]: 'secondary',
    [JOB_CLOSEOUT_STATUS.NO_REVIEW_NEEDED]: 'secondary',
    [JOB_CLOSEOUT_STATUS.REVIEWED]: 'secondary'
  },
  [EnumType.JOB_STATUS]: {
    [JobStatus.OPEN]: 'secondary',
    [JobStatus.IN_PROGRESS]: 'secondary',
    [JobStatus.ON_HOLD]: 'secondary',
    [JobStatus.CANCELED]: 'secondary',
    [JobStatus.COMPLETE]: 'secondary'
  },
  [EnumType.VISIT_REVIEW_STATUS]: {
    [VISIT_REVIEW_STATUS.UNREVIEWED]: 'secondary'
  },
  [EnumType.JOB_BILLING_STATUS]: {
    [JobBillingStatus.PARTIALLY_INVOICED]: 'secondary',
    [JobBillingStatus.DO_NOT_INVOICE]: 'secondary',
    [JobBillingStatus.NOT_INVOICED]: 'secondary'
  },
  [EnumType.VENDOR_APPROVAL_STATUS]: {
    [VendorApprovalStatus.APPROVED]: 'secondary',
    [VendorApprovalStatus.REVIEW_NEEDED]: 'secondary',
    [VendorApprovalStatus.UNREVIEWED]: 'secondary',
    [VendorApprovalStatus.REJECTED]: 'secondary'
  }
};
