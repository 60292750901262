import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { getContentText } from 'scenes/ProjectManagement/components/utils';
import styles from '../style';

const ContractApplicationTable = ({ tableData, payAppSummary }) => {
  const payApplication = payAppSummary?.PayApplication;

  let prevAdditions = 0;
  let prevDeductions = 0;
  let additions = 0;
  let deductions = 0;

  tableData.forEach(lineItem => {
    if (lineItem.entityType !== 'ChangeOrderLineItem') return;
    if (
      lineItem.dateApproved > payApplication.periodFrom &&
      lineItem.dateApproved <= payApplication.periodTo
    ) {
      if (lineItem.contractValue > 0) {
        additions += lineItem.contractValue;
      } else {
        deductions += lineItem.contractValue;
      }
    } else if (lineItem.dateApproved <= payApplication.periodFrom) {
      if (lineItem.contractValue > 0) {
        prevAdditions += lineItem.contractValue;
      } else {
        prevDeductions += lineItem.contractValue;
      }
    }
  });

  const row1ColumnA = prevAdditions;
  const row1ColumnB = prevDeductions;
  const row2ColumnA = additions;
  const row2ColumnB = deductions;
  const row3ColumnA = prevAdditions + additions;
  const row3ColumnB = prevDeductions + deductions;
  const row4 = row3ColumnA + row3ColumnB;

  return (
    <View style={[styles.tableBorder, styles.marginTop30]}>
      {/* Table header */}
      <View style={styles.displayFlex}>
        <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '50%' }]}>
          CHANGE ORDER SUMMARY
        </Text>
        <Text style={[styles.text, styles.innerCell, styles.textCenter, { width: '25%' }]}>
          ADDITIONS
        </Text>
        <Text style={[styles.text, styles.rightLastCell, styles.textCenter, { width: '25%' }]}>
          DEDUCTIONS
        </Text>
      </View>
      {/* 1st row: total changes */}
      <View style={styles.displayFlex}>
        <Text style={[styles.text, styles.innerCell, styles.paddingLeft10, { width: '50%' }]}>
          Total changes approved{'\n'}in previous months by Owner
        </Text>
        <Text
          style={[
            styles.text,
            styles.innerCell,
            styles.numberAlign,
            { width: '25%', paddingTop: 8 }
          ]}
        >
          {getContentText('currency', row1ColumnA, 2)}
        </Text>
        <Text
          style={[
            styles.text,
            styles.rightLastCell,
            styles.numberAlign,
            { width: '25%', paddingTop: 8 }
          ]}
        >
          {getContentText('currency', row1ColumnB, 2)}
        </Text>
      </View>
      {/* 2nd row: total approved */}
      <View style={styles.displayFlex}>
        <Text style={[styles.text, styles.innerCell, styles.paddingLeft10, { width: '50%' }]}>
          Total approved in this Period
        </Text>
        <Text style={[styles.text, styles.innerCell, styles.numberAlign, { width: '25%' }]}>
          {getContentText('currency', row2ColumnA, 2)}
        </Text>
        <Text style={[styles.text, styles.rightLastCell, styles.numberAlign, { width: '25%' }]}>
          {getContentText('currency', row2ColumnB, 2)}
        </Text>
      </View>
      {/* 3rd row: totals */}
      <View style={styles.displayFlex}>
        <Text style={[styles.text, styles.innerCell, styles.paddingLeft10, { width: '50%' }]}>
          Totals
        </Text>
        <Text style={[styles.text, styles.innerCell, styles.numberAlign, { width: '25%' }]}>
          {getContentText('currency', row3ColumnA, 2)}
        </Text>
        <Text style={[styles.text, styles.rightLastCell, styles.numberAlign, { width: '25%' }]}>
          {getContentText('currency', row3ColumnB, 2)}
        </Text>
      </View>
      {/* 4th row: net changes */}
      <View style={styles.displayFlex}>
        <Text
          style={[
            styles.text,
            styles.innerCell,
            styles.paddingLeft10,
            { width: '50%', borderBottom: 0 }
          ]}
        >
          NET CHANGES by Change Order
        </Text>
        <Text
          style={[styles.text, styles.innerCellWithoutBorder, styles.numberAlign, { width: '25%' }]}
        >
          {getContentText('currency', row4, 2)}
        </Text>
      </View>
    </View>
  );
};

ContractApplicationTable.propTypes = {
  paData: PropTypes.object.isRequired,
  coData: PropTypes.array.isRequired
};

export default ContractApplicationTable;
