import { object, string } from 'yup';
import { searchIndex } from 'constants/algoliaIndex';
import { nameValidationField, addressValidationFields, toLengthTest } from 'meta/validations';
import { BILLING_INFO_TOOLTIP, EntityType } from 'utils/constants';

/* eslint-disable import/prefer-default-export */
export const AddPropertyLayout = (taxRates, propertyTypes, isTaxRateAutoAssignDisabled) => ({
  fields: {},
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER'
      },
      contents: [
        // Property Name & Property Type & Account Number
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 3,
                padding: 8
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'companyName',
                  options: {
                    label: 'Property Name',
                    isRequired: true
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 3,
                padding: 8
              },
              contents: [
                {
                  component: {
                    edit: 'SelectInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'customerPropertyTypeSortKey',
                  options: {
                    label: 'Property Type',
                    placeholder: 'Select...',
                    valueSet: propertyTypes,
                    valuesToSet: [
                      {
                        customerPropertyTypeId: 'id',
                        customerPropertyTypeValue: 'label'
                      }
                    ],
                    isRequired: false,
                    enableSort: false
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 3,
                padding: 8
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'accountNumber',
                  options: {
                    label: 'Account Number',
                    isRequired: false
                  }
                }
              ]
            },
            {
              options: {
                grow: 2,
                padding: 8
              },
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              options: {
                grow: 2,
                padding: 8
              },
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Property address same as customer checkbox
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 8
              },
              contents: [
                {
                  component: {
                    edit: 'shippingAddressCheckbox',
                    default: 'ReadOnlyCheckboxInput'
                  },
                  source: 'shippingAddressCheckbox',
                  options: {
                    label: 'Property has same address as customer',
                    checked: false,
                    isRequired: false
                  }
                }
              ]
            }
          ]
        },
        // Property Address (Line1, Line2, City, State, Zipcode)
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 3,
                padding: 8
              },
              contents: [
                {
                  component: {
                    edit: 'PlacesSearch',
                    default: 'FieldWithLabel'
                  },
                  source: 'propertyAddressLine1',
                  options: {
                    label: 'Address Line 1',
                    fullWidth: true,
                    isRequired: true,
                    setSource: false,
                    valuesToSet: [
                      {
                        propertyAddressLine1: 'addressLine1',
                        propertyCity: 'city.shortName',
                        propertyState: 'state.shortName',
                        propertyZipcode: 'zipcode.shortName',
                        propertyLatitude: 'latitude',
                        propertyLongitude: 'longitude'
                      }
                    ],
                    ...(!isTaxRateAutoAssignDisabled && {
                      fieldsToMatch: [{ taxRateId: [taxRates, 'id'] }]
                    })
                  },
                  dependentField: {
                    fieldName: 'shippingAddressCheckbox',
                    expectedValue: true,
                    operation: 'bool',
                    action: 'DISABLE'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 3,
                padding: 8
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'propertyAddressLine2',
                  options: {
                    label: 'Address Line 2'
                  },
                  dependentField: {
                    fieldName: 'shippingAddressCheckbox',
                    expectedValue: true,
                    operation: 'bool',
                    action: 'DISABLE'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 3,
                padding: 8
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'propertyCity',
                  options: {
                    label: 'city',
                    isRequired: true
                  },
                  dependentField: {
                    fieldName: 'shippingAddressCheckbox',
                    expectedValue: true,
                    operation: 'bool',
                    action: 'DISABLE'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 2,
                padding: 8
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'propertyState',
                  options: {
                    label: 'state/province',
                    isRequired: true
                  },
                  dependentField: {
                    fieldName: 'shippingAddressCheckbox',
                    expectedValue: true,
                    operation: 'bool',
                    action: 'DISABLE'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 2,
                padding: 8
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'propertyZipcode',
                  options: {
                    label: 'zip/postal code',
                    isRequired: true
                  },
                  dependentField: {
                    fieldName: 'shippingAddressCheckbox',
                    expectedValue: true,
                    operation: 'bool',
                    action: 'DISABLE'
                  }
                }
              ]
            }
          ]
        },
        // Tax Options
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 3,
                padding: 8
              },
              contents: [
                {
                  component: {
                    edit: 'SelectInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'taxRateId',
                  options: {
                    label: 'Tax code',
                    isRequired: false,
                    isSearchable: true,
                    valueSet: taxRates,
                    valuesToSet: [
                      {
                        taxRateId: 'id'
                      }
                    ]
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 3,
                padding: [20, 8, 8, 8]
              },
              contents: [
                {
                  component: {
                    edit: 'CheckboxInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'isTaxable',
                  options: {
                    label: 'Taxable',
                    isRequired: false
                  }
                }
              ]
            },
            {
              options: {
                grow: 3,
                padding: 8
              },
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              options: {
                grow: 2,
                padding: 8
              },
              contents: [
                {
                  component: null
                }
              ]
            },
            {
              options: {
                grow: 2,
                padding: 8
              },
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Billing Customer & Same Billing Address CheckBox
        {
          options: {
            padding: [8, 0, 0, 8]
          },
          component: 'BodyText',
          source: 'Select a billing customer to fill the property’s billing address'
        },
        {
          options: { direction: 'row' },
          contents: [
            {
              options: {
                grow: 1,
                padding: [8, 96, 8, 8]
              },
              contents: [
                {
                  component: 'TextInput',
                  source: 'billTo',
                  options: {
                    label: 'Billing Information',
                    lines: 2,
                    tooltip: BILLING_INFO_TOOLTIP,
                    placeholder: 'Customer Name\nBill To',
                    linesMax: 4
                  }
                }
              ]
            },
            {
              options: {
                grow: 1,
                minWidth: 420,
                padding: 8
              },
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 6,
                padding: 8
              },
              contents: [
                {
                  component: {
                    edit: 'AlgoliaSearchWrapper',
                    default: 'FieldWithLabel'
                  },
                  source: 'billingCustomerName',
                  options: {
                    label: 'Billing Customer',
                    placeholder: 'Search billing customer',
                    searchIndexName: searchIndex,
                    delimiter: ', ',
                    searchResultDisplayFields: ['customerName', 'addressLine1', 'addressLine2'],
                    setItemName: false,
                    isRequired: true,
                    filters: [
                      {
                        attribute: 'addressType',
                        valueArray: ['billingAddress']
                      },
                      {
                        attribute: 'parentEntityType',
                        valueArray: ['Customer']
                      }
                    ],
                    valuesToSet: [
                      {
                        billTo: 'billTo',
                        billingCustomerName: 'customerName',
                        billingCustomerId: 'parentId',
                        billingAddressLine1: 'addressLine1',
                        billingAddressLine2: 'addressLine2',
                        billingCity: 'city',
                        billingState: 'state',
                        billingZipcode: 'zipcode',
                        billingLatitude: 'latitude',
                        billingLongitude: 'longitude'
                      }
                    ]
                  },
                  dependentField: {
                    fieldName: 'billingAddressCheckbox',
                    expectedValue: true,
                    operation: 'bool',
                    action: 'DISABLE'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 5,
                padding: [20, 8, 8, 8]
              },
              contents: [
                {
                  component: {
                    edit: 'billingAddressCheckbox',
                    default: 'ReadOnlyCheckboxInput'
                  },
                  source: 'billingAddressCheckbox',
                  options: {
                    label: 'Same billing address as customer',
                    fullWidth: true
                  }
                }
              ]
            },
            {
              options: {
                grow: 2,
                padding: 8
              },
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // Billing Address
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 3,
                padding: 8
              },
              contents: [
                {
                  component: {
                    edit: 'FieldWithLabel',
                    default: 'FieldWithLabel'
                  },
                  source: 'billingAddressLine1',
                  options: {
                    label: 'Address Line 1',
                    isRequired: false
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 3,
                padding: 8
              },
              contents: [
                {
                  component: {
                    edit: 'FieldWithLabel',
                    default: 'FieldWithLabel'
                  },
                  source: 'billingAddressLine2',
                  options: {
                    label: 'Address Line 2',
                    isRequired: false
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 3,
                padding: 8
              },
              contents: [
                {
                  component: {
                    edit: 'FieldWithLabel',
                    default: 'FieldWithLabel'
                  },
                  source: 'billingCity',
                  options: {
                    label: 'city'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 2,
                padding: 8
              },
              contents: [
                {
                  component: {
                    edit: 'FieldWithLabel',
                    default: 'FieldWithLabel'
                  },
                  source: 'billingState',
                  options: {
                    label: 'state/province'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 2,
                padding: 8
              },
              contents: [
                {
                  component: {
                    edit: 'FieldWithLabel',
                    default: 'FieldWithLabel'
                  },
                  source: 'billingZipcode',
                  options: {
                    label: 'zip/postal code'
                  }
                }
              ]
            }
          ]
        },
        // Property Instructions
        {
          options: {
            direction: 'row',
            hidden: true
          },
          contents: [
            {
              options: {
                direction: 'column',
                padding: [8, 96, 8, 8],
                grow: 1
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'propertyInstruction',
                  options: {
                    label: 'property instructions',
                    lines: 6
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 8
              },
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: object({
    ...nameValidationField(
      'companyName',
      EntityType.CUSTOMER_PROPERTY,
      'Property Name is required.'
    ),
    ...addressValidationFields('property'),
    billTo: string()
      .nullable()
      .test(toLengthTest()),
    accountNumber: string(),
    billingCustomerName: string().required('A billing customer is required.')
  })
});

export default AddPropertyLayout;
