import React, { useEffect, useReducer, useRef, useState } from 'react';

import { SplitButton, ThemeProvider } from '@buildhero/sergeant';
import { makeStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { filter, isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

// eslint-disable-next-line import/no-cycle

import FullScreenModal from 'components/FullScreenModal';
import ManulStatusChangeModal from 'components/ManualStatusChangeModal/index';
import Labels from 'meta/labels';
import { snackbarOn } from 'redux/actions/globalActions';
import { getProcurementStatus } from 'scenes/JobCloseout/utils';
import { getShippingAddress } from 'scenes/Procurement/component/utils';
import { purchaseOrderCreate } from 'services/API/purchaseOrder';
import { getLinesByPurchaseOrder } from 'services/API/purchaseOrderLine';
import {
  getNextReceiptNumber,
  purchaseOrderReceiptCreate
} from 'services/API/purchaseOrderReceipt';
import { JobService } from 'services/core';
import { truncateString } from 'utils';
import { ProcurementPurchaseOrderStatus } from 'utils/AppConstants';
import { EnumType, PaymentTermType, PurchaseOrderStatus } from 'utils/constants';

import { FeatureFlags } from 'utils/FeatureFlagConstants';

import StepsStatus from '../../component/StepsStatus';

// eslint-disable-next-line import/no-cycle
import AddItemsStep from './components/AddItemsStep';
import GeneralStep from './components/GeneralStep';
// eslint-disable-next-line import/no-cycle
import GeneratePdfModal from './components/GeneratePdfModal';
import SummaryStep from './components/SummaryStep';

const splitButtons = {
  markAsOrdered: 'Mark as Ordered',
  orderViaEmail: 'Order Via Email'
};

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 26,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: 14,
    fontWeight: 700
  }
}));

const initialState = {
  generalInfo: {},
  items: [],
  costs: {}
};

const poDataReducer = (state, action) => {
  switch (action.type) {
    case 'putGeneralInfo':
      return {
        ...state,
        generalInfo: {
          ...state.generalInfo,
          ...action.payload
        }
      };
    case 'setItems':
      return { ...state, items: action.payload };
    case 'addItem':
      return { ...state, items: [...state.items, action.payload] };
    case 'editItems':
      return {
        ...state,
        items: [
          ...state.items.map(item =>
            action.payload.itemNumber === item.itemNumber ? action.payload : item
          )
        ]
      };
    case 'deleteItem':
      return {
        ...state,
        items: [...filter(state.items, item => item.itemNumber !== action.payload.itemNumber)]
      };
    case 'editCosts':
      return { ...state, costs: { ...state.costs, ...action.payload } };
    case 'initialize':
      return { generalInfo: {}, items: [], costs: {} };
    default:
      return state;
  }
};

const CreatePurchaseOrder = props => {
  const {
    open,
    handleClose,
    user,
    initialData,
    tagOptions,
    associatedProject,
    projectId,
    jobProjectFieldReadOnly,
    generalInfoCustomField,
    jobData
  } = props;

  // Defensive code against a bug caused by setting the initial value of the Review Reports view
  if (isEmpty(initialData)) {
    initialState.generalInfo = {
      department: null,
      jobAndProject: null
    };
    initialState.items = [];
  } else {
    initialState.generalInfo = {
      ...initialState.generalInfo,
      ...initialData
    };
    initialState.items = initialData?.quoteProducts || [];
  }

  const classes = useStyles();
  const flags = useFlags();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const furthestStepIndex = useRef(currentStepIndex);
  const [poState, dispatchPoData] = useReducer(poDataReducer, initialState);
  const [saveAsDraftEnabled, setSaveAsDraftEnabled] = useState(false);
  const [isOpendPdfModal, setIsOpendPdfModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const company = useSelector(state => state.company);
  const [generalInfoFormService, setGeneralInfoFormService] = useState(null);
  const { jobId, jobOrProjectDisplay } = poState?.generalInfo;
  const associatedProjectId =
    associatedProject?.id || poState?.generalInfo?.projectId
      ? { id: poState?.generalInfo?.projectId }
      : undefined;
  const [openProcurementStatusModal, setOpenProcurementStatusModal] = useState(false);
  const [procurementStatusModalData, setProcurementStatusModalData] = useState(null);
  const [updatedJobData, setUpdatedJobData] = useState(null);

  const continueToNext = () => {
    setCurrentStepIndex(index => {
      const newIndex = index + 1;
      furthestStepIndex.current = newIndex;
      return newIndex;
    });
  };

  useEffect(() => {
    dispatchPoData({ type: 'putGeneralInfo', payload: { ...initialState.generalInfo } });
    dispatchPoData({ type: 'setItems', payload: initialState.items });
  }, [initialData]);

  useEffect(() => {
    const itemsWithUpdatedAssociations = poState.items.map(item => ({
      ...item,
      jobId,
      projectId,
      jobOrProjectDisplay
    }));
    dispatchPoData({ type: 'setItems', payload: itemsWithUpdatedAssociations });
  }, [jobId, projectId, jobOrProjectDisplay]);

  const steps = [
    {
      label: 'General Information',
      pageTitle: 'General Information',
      render: () => (
        <GeneralStep
          tagOptions={tagOptions}
          tenantCompanyId={user.tenantCompanyId}
          tenantId={user.tenantId}
          userLocale={user.locale}
          onClickNextStep={continueToNext}
          generalData={poState.generalInfo}
          dataReducer={dispatchPoData}
          setSaveAsDraftEnabled={setSaveAsDraftEnabled}
          associatedProject={associatedProject}
          formService={generalInfoFormService}
          setFormService={setGeneralInfoFormService}
          jobProjectFieldReadOnly={jobProjectFieldReadOnly}
          generalInfoCustomField={generalInfoCustomField}
          handleJobUpdated={setUpdatedJobData}
        />
      )
    },
    {
      label: 'Add Items To Order',
      pageTitle: 'Add Items',
      render: () => (
        <AddItemsStep
          userLocale={user.locale}
          onClickNextStep={continueToNext}
          itemsData={poState.items}
          costsData={poState.costs}
          poInfo={poState}
          dataReducer={dispatchPoData}
          jobAndProject={poState?.generalInfo?.jobOrProjectDisplay || null}
          associatedProject={associatedProjectId}
          setSaveAsDraftEnabled={setSaveAsDraftEnabled}
        />
      )
    },
    {
      label: 'Summary',
      render: () => (
        <SummaryStep
          generalData={poState.generalInfo}
          itemsData={poState.items}
          costsData={poState.costs}
          allTags={tagOptions}
          associatedProject={associatedProjectId}
        />
      )
    }
  ];

  const submitCompleted = purchaseOrder => {
    props.snackbarOn('success', 'Successfully Posted');
    dispatchPoData({ type: 'initialize', payload: null });
    handleClose(purchaseOrder);
  };

  const handleExited = () => {
    dispatchPoData({ type: 'initialize', payload: null });
    setCurrentStepIndex(0);
    furthestStepIndex.current = 0;
  };

  const createPayload = async status => {
    const { generalInfo, items, costs } = poState;

    const shippingAddress = await getShippingAddress(generalInfo.shipTo, generalInfo, true);

    const payload = {
      orderedById: status === 'Ordered' ? user?.employeeId : undefined,
      vendorName: generalInfo.vendor?.name || null,
      vendorId: generalInfo.vendor?.id || null,
      totalAmountPreTax: Number(costs.subtotal ?? 0),
      departmentName: generalInfo.department?.tagName || null,
      departmentId: generalInfo.department?.id || null,
      assignedToId: generalInfo.assignedTo?.id || null,
      poTypeId: generalInfo.poType?.id || null,
      description: generalInfo.description || null,
      dateOfPurchase: generalInfo.date || moment.utc(moment().format('L')).unix() || null,
      requiredByDate: generalInfo.requiredByDate || null,
      freight: Number(costs.freightCost ?? 0),
      shipTo: generalInfo.shipTo || null,
      shipToEmployeeId: generalInfo.shipToEmployee?.id || null,
      shipToName: generalInfo.shipToName || null,
      shipToNameType: generalInfo.shipToNameType || null,
      shipToInstructions: generalInfo.shipToInstructions || null,
      addressLine1: associatedProject?.address1 || shippingAddress?.addressLine1 || null,
      addressLine2: associatedProject?.address2 || shippingAddress?.addressLine2 || null,
      city: associatedProject?.addressCity || shippingAddress?.city || null,
      state: associatedProject?.addressState || shippingAddress?.state || null,
      zipcode: associatedProject?.addressPostal || shippingAddress?.zipcode || null,
      jobId: generalInfo.jobId || null,
      projectId: projectId || generalInfo.projectId || null,
      dateAdded: moment().unix(),
      status,
      entityType: 'PurchaseOrder',
      tenantCompanyId: user.tenantCompanyId,
      tax: Number(costs.taxAmount ?? 0),
      taxRateId: costs.taxRateId || null,
      purchaseOrderLines: items.map((item, index) => ({
        lineNumber: index + 1,
        itemName: item.itemName || null,
        vendorName: generalInfo.vendor?.name || null,
        description: truncateString(item.description, 250) || null,
        departmentName: generalInfo.department?.tagName || null,
        quantity: item.quantity || 0,
        unitCost: item.unitCost || item.unitPrice || 0,
        costCodeId: item.costCodeObj?.id || null,
        revenueTypeId: item.revenueTypeObj?.id || null,
        jobCostTypeId: item.jobCostType?.id || null,
        jcPhaseId: item?.jcPhase?.id || null,
        jcCostTypeId: item?.jcCostType?.id || null,
        projectPhaseId: item?.projectPhase?.id || null,
        projectCostCodeId: item?.projectCostCode?.id || null,
        projectCostType: item?.projectCostType || null,
        jobId: item?.jobId || null,
        projectId: item?.projectId || null,
        unitOfMeasure: item.unitOfMeasure || null,
        departmentId: generalInfo.department?.id || null,
        productId: item?.productId || null,
        taxable: item?.taxable || null,
        entityType: 'PurchaseOrderLine',
        tenantCompanyId: user.tenantCompanyId
      })),
      purchaseOrderTags: generalInfo.purchaseOrderTags?.map(tag => ({ id: tag })),
      totalCost:
        Number(costs.subtotal ?? 0) + Number(costs.freightCost ?? 0) || Number(costs.subtotal ?? 0),
      isFieldOrder: 0
    };
    return payload;
  };

  const formatPOReceiptPayload = (purchaseOrder, purchaseOrderLines, receiptNumber) => {
    const receiptLines = purchaseOrderLines?.map(line => {
      return {
        lineNumber: line.lineNumber,
        purchaseOrderLineId: line.id,
        quantity: line.quantity || 0,
        purchaseOrderId: purchaseOrder.id,
        productId: line.productId,
        description: line.description,
        departmentId: line.departmentId || null,
        unitCost: line.unitCost || 0,
        unitOfMeasure: line.unitOfMeasure,
        taxable: line.taxable,
        jcPhaseId: line.jcPhaseId || line.jcPhase?.id || null,
        jcCostTypeId: line.jcCostTypeId || line.jcCostType?.id || null,
        projectCostCodeId: line.projectCostCodeId || line.projectCostCode?.id || null,
        projectPhaseId: line.projectPhaseId || line.projectPhase?.id || null,
        jobId: line.Job?.id || line.jobId || null,
        projectId: line.Project?.id || line.projectId || null,
        projectCostType: line.projectCostType || null,
        costCodeId: line.costCode?.id || line.costCodeId || null,
        revenueTypeId: line.revenueType?.id || line.revenueTypeId || null,
        jobCostTypeId: line.jobCostType?.id || line.jobCostTypeId || null
      };
    });

    return {
      parentId: purchaseOrder.id,
      departmentId: purchaseOrder.departmentId || null,
      vendorId: purchaseOrder.vendorId || null,
      jobId: purchaseOrder.jobId || null,
      projectId: purchaseOrder.projectId || null,
      description: purchaseOrder.description,
      status: 'Pending',
      freight: purchaseOrder.freight || 0,
      taxRateId: purchaseOrder.taxRateId,
      receiptNumber,
      tax: purchaseOrder.tax || 0,
      PurchaseOrderReceiptLine: receiptLines,
      issuedBy: moment.utc(moment().format('L')).unix(),
      paymentTermId:
        company.defaultPaymentTerm?.type === PaymentTermType.BOTH ||
        company.defaultPaymentTerm?.type === PaymentTermType.PURCHASING
          ? company.defaultPaymentTermId
          : null
    };
  };

  const handleSaveAsDraft = async jobProcStatusToUpdate => {
    const isFormValid = isEmpty(await generalInfoFormService.validateForm());

    if (isFormValid) {
      setIsOpendPdfModal(false);
      setIsSubmitting(true);
      const payload = await createPayload('Draft');
      const purchaseOrder = await purchaseOrderCreate(payload);

      if (poState.generalInfo.poType.autoReceive) {
        const purchaseOrderLines = await getLinesByPurchaseOrder(purchaseOrder.id);
        let receiptNumber = await getNextReceiptNumber(purchaseOrder.id);
        if (!Array.isArray(receiptNumber) && receiptNumber?.search(purchaseOrder.poNumber) < 0) {
          receiptNumber = `${purchaseOrder.poNumber}-${receiptNumber}`;
        }
        const purchaseOrderReceipt = formatPOReceiptPayload(
          purchaseOrder,
          purchaseOrderLines,
          receiptNumber
        );

        purchaseOrderReceiptCreate(purchaseOrderReceipt).then(() => submitCompleted(purchaseOrder));
      } else {
        submitCompleted(purchaseOrder);
      }
      if (jobProcStatusToUpdate) {
        const service = new JobService();
        const updatedProcurementStatusPayload = {
          customerPropertyId: jobProcStatusToUpdate?.customerPropertyId,
          ignoreDepartmentUpdates: true,
          jobs: [
            {
              id: jobProcStatusToUpdate?.id,
              version: jobProcStatusToUpdate?.version + 1 || 1,
              procurementStatus: null
            }
          ]
        };
        await service.updateJobAndRelated(user.tenantId, updatedProcurementStatusPayload);
      }
      setIsSubmitting(false);
    }
  };

  const handlePlaceOrder = async jobProcStatusToUpdate => {
    const isFormValid = isEmpty(await generalInfoFormService.validateForm());
    if (isFormValid) {
      setIsOpendPdfModal(false);
      setIsSubmitting(true);
      const payload = await createPayload('Ordered');
      const purchaseOrder = await purchaseOrderCreate(payload);
      if (poState.generalInfo.poType.autoReceive) {
        const purchaseOrderLines = await getLinesByPurchaseOrder(purchaseOrder.id);
        let receiptNumber = await getNextReceiptNumber(purchaseOrder.id);
        if (receiptNumber.search(purchaseOrder.poNumber) < 0) {
          receiptNumber = `${purchaseOrder.poNumber}-${receiptNumber}`;
        }
        const purchaseOrderReceipt = formatPOReceiptPayload(
          purchaseOrder,
          purchaseOrderLines,
          receiptNumber
        );

        purchaseOrderReceiptCreate(purchaseOrderReceipt).then(() => submitCompleted(purchaseOrder));
      } else {
        submitCompleted(purchaseOrder);
      }
      if (jobProcStatusToUpdate) {
        const service = new JobService();
        const updatedProcurementStatusPayload = {
          customerPropertyId: jobProcStatusToUpdate?.customerPropertyId,
          ignoreDepartmentUpdates: true,
          jobs: [
            {
              id: jobProcStatusToUpdate?.id,
              version: jobProcStatusToUpdate?.version + 1 || 1,
              procurementStatus: null
            }
          ]
        };
        await service.updateJobAndRelated(user.tenantId, updatedProcurementStatusPayload);
      }
      setIsSubmitting(false);
    }
  };

  const jobManualProcurementStatus = job => {
    return job.entityType === 'Job' && job.procurementStatus;
  };

  const openProcurementStatusChangedModal = (job, status) => {
    const automatedStatus = getProcurementStatus([
      ...(job?.purchaseOrders?.items || []),
      { status: poState.generalInfo.poType.autoReceive ? PurchaseOrderStatus.FULFILLED : status }
    ]);
    setProcurementStatusModalData({
      manualStatus: job?.procurementStatus || 'POs Needed',
      automatedStatus,
      status,
      jobProcStatusToUpdate: job
    });
    setOpenProcurementStatusModal(true);
  };

  const checkForPrefilledJob = async job => {
    if (isEmpty(job) && poState?.generalInfo?.jobAndProject?.jobNumber) {
      const jobService = new JobService();
      const jobDetails = await jobService.getJobDetailsByJobNumber(
        `${poState?.generalInfo?.jobAndProject?.jobNumber}`
      );
      if (jobDetails?.data?.getJobByJobNumber) {
        return jobDetails?.data?.getJobByJobNumber;
      }
    }
    return job;
  };

  const handleSave = async status => {
    if (flags[FeatureFlags.JOB_PROCUREMENT_STATUS]) {
      const job = await checkForPrefilledJob(updatedJobData || jobData);
      if (jobManualProcurementStatus(job)) {
        openProcurementStatusChangedModal(job, status);
        return;
      }
      setProcurementStatusModalData(null);
    }
    status === 'Draft' ? handleSaveAsDraft() : handlePlaceOrder();
  };
  return (
    <>
      <FullScreenModal
        title={Labels.createPurchaseOrder[user.locale]}
        open={open}
        handleClose={handleClose}
        onExited={handleExited}
        modalHeaderButtons={[
          <ThemeProvider>
            <SplitButton
              color="primary"
              onClick={() => handleSave('Draft')}
              disabled={!saveAsDraftEnabled || isSubmitting}
              showSpinner={isSubmitting}
              options={[
                {
                  label: splitButtons.markAsOrdered,
                  onClick: () => handleSave('Ordered')
                }
              ]}
              testingid="create-purchase-order"
            >
              Create Order
            </SplitButton>
          </ThemeProvider>
        ]}
        headerCenterComponent={
          <StepsStatus
            steps={steps.map(({ label }) => label)}
            currentStepIndex={currentStepIndex}
            onClickStep={index => setCurrentStepIndex(index)}
            furthestStepIndex={furthestStepIndex.current}
            clickable
            style={{ maxWidth: '570px' }}
          />
        }
      >
        <div className={classes.root}>{steps[currentStepIndex].render()}</div>
        {flags[FeatureFlags.JOB_PROCUREMENT_STATUS] && (
          <ManulStatusChangeModal
            title="Update Procurement Status"
            statusEnumType={EnumType.JOB_PROCUREMENT_STATUS}
            data={procurementStatusModalData}
            open={openProcurementStatusModal}
            handleClose={xButtonClosed => {
              if (!xButtonClosed) {
                procurementStatusModalData.status === ProcurementPurchaseOrderStatus.DRAFT
                  ? handleSaveAsDraft()
                  : handlePlaceOrder();
              }
              setOpenProcurementStatusModal(false);
            }}
            handleSubmit={() => {
              procurementStatusModalData.status === ProcurementPurchaseOrderStatus.DRAFT
                ? handleSaveAsDraft(procurementStatusModalData?.jobProcStatusToUpdate)
                : handlePlaceOrder(procurementStatusModalData?.jobProcStatusToUpdate);
              setOpenProcurementStatusModal(false);
            }}
          />
        )}
      </FullScreenModal>
      <GeneratePdfModal
        open={isOpendPdfModal}
        user={user}
        pdfData={poState}
        costsData={poState.costs}
        handleClose={() => setIsOpendPdfModal(false)}
        handleSaveAsDraft={handleSaveAsDraft}
        handlePlaceOrder={handlePlaceOrder}
      />
    </>
  );
};

CreatePurchaseOrder.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  snackbarOn: PropTypes.func.isRequired,
  initialData: PropTypes.object,
  tagOptions: PropTypes.array,
  projectId: PropTypes.string,
  associatedProject: PropTypes.object,
  jobProjectFieldReadOnly: PropTypes.bool,
  generalInfoCustomField: PropTypes.func,
  jobData: PropTypes.object
};

CreatePurchaseOrder.defaultProps = {
  initialData: {},
  tagOptions: [],
  projectId: null,
  associatedProject: {},
  jobData: {},
  jobProjectFieldReadOnly: false,
  generalInfoCustomField: () => <div />
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});
const ReduxConnectedCreatePurchaseOrder = connect(
  mapStateToProps,
  mapDispatcherToProps
)(CreatePurchaseOrder);
export default ReduxConnectedCreatePurchaseOrder;
