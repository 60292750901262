import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

export const useDepartmentOptions = () => {
  const departments = useSelector(state => state?.options?.departments || []);
  return useMemo(() => {
    return R.pipe(R.sortBy(R.prop('label')), R.map(R.pick(['label', 'value'])))(departments);
  }, [departments]);
};
