export default {
  render: {
    name: 'onRender'
  },
  approveQuote: {
    name: 'onApproveQuote',
    addReason: true
  },
  rejectQuote: {
    name: 'onRejectQuote',
    addReason: true
  }
};
