import React, { Component } from 'react';
import { connect } from 'react-redux';
import SergeantModal from 'components/SergeantModal';
import ErrorBoundaries from 'scenes/Error';
import { snackbarOn } from 'redux/actions/globalActions';
import { CustomerService, CustomerPropertyService } from 'services/core';
import { logErrorWithCallback } from 'utils';
import { ExclusiveCheckboxInput, ExclusiveReadOnlyCheckboxInput } from './ExclusiveCheckbox';
import NotesWrapper from '../NotesWrapper';

export const RepType = {
  CUSTOMER: 'customer',
  PROPERTY: 'property'
};

export const CustomerRepOperations = {
  ADD_CUSTOMER_REP: 'ADD_CUSTOMER_REP',
  ADD_PROPERTY_REP: 'ADD_PROPERTY_REP',
  EDIT_CUSTOMER_REP: 'EDIT_CUSTOMER_REP',
  EDIT_PROPERTY_REP: 'EDIT_PROPERTY_REP',
  MAP_CUSTOMER_REP: 'MAP_CUSTOMER_REP'
};

class CustomerRep extends Component {
  constructor(props) {
    super(props);
    this.CustomerServiceObj = new CustomerService();
    this.CustomerPropertyServiceObj = new CustomerPropertyService();
  }

  // There are 3 mutations, updateRep, addRep and mapAnExistingRep to a customer property
  // This should also check for props.repType and generate EDIT_CUSTOMER_REP or ADD_CUSTOMER_REP
  // as appropriate, but we are temporarily disabling that functionality pending further review of
  // the broader feature. See https://buildops.atlassian.net/browse/BUOP-9306
  getTypeOfCustomerRepOperation = filledFormData => {
    const { mode } = this.props;
    if (mode === 'edit') {
      return CustomerRepOperations.EDIT_CUSTOMER_REP;
    }

    const { customerRep } = filledFormData;
    if (customerRep?.length) {
      return CustomerRepOperations.MAP_CUSTOMER_REP;
    }

    return CustomerRepOperations.ADD_CUSTOMER_REP;
  };

  handleOnComplete = async (values, stopLoading) => {
    const repType = this.props.parent?.customerPropertyId ? RepType.PROPERTY : RepType.CUSTOMER;

    const filledFormData = values;
    const { parent } = this.props;
    if (parent) {
      filledFormData.parent = parent;
    }
    try {
      let response;
      const operationType =
        this.props.overrideOperationType || this.getTypeOfCustomerRepOperation(filledFormData);
      switch (operationType) {
        case CustomerRepOperations.ADD_CUSTOMER_REP:
          response = await this.CustomerServiceObj.addCustomerRepToCustomer(filledFormData);
          break;
        case CustomerRepOperations.ADD_PROPERTY_REP:
          response = await this.CustomerServiceObj.addCustomerRepToProperty(filledFormData);
          break;
        case CustomerRepOperations.EDIT_CUSTOMER_REP:
          response = await this.CustomerServiceObj.updateCustomerRep(filledFormData);
          break;
        case CustomerRepOperations.EDIT_PROPERTY_REP:
          response = await this.CustomerPropertyServiceObj.updateCustomerRepOnProperty(
            filledFormData
          );
          break;
        case CustomerRepOperations.MAP_CUSTOMER_REP:
          response = await this.CustomerPropertyServiceObj.assignCustomerRepsToCustomerProperty(
            parent.customerPropertyId,
            filledFormData.customerRep
          );
          break;
        default:
          break;
      }

      if (response?.data) {
        this.props.snackbarOn('success', `Successfully added ${repType} representative`);
        this.props.handleClose(true, response?.data);
      }
    } catch (error) {
      logErrorWithCallback(
        error,
        (msg, errorObj) => this.props.snackbarOn('error', msg, errorObj),
        'Unable to create representative, please try again later'
      );
    } finally {
      stopLoading();
    }
  };

  render() {
    const repType = this.props.parent?.customerPropertyId ? RepType.PROPERTY : RepType.CUSTOMER;
    const { open, mode, layout, existingReps, data } = this.props;
    if (data) {
      // copy notes to customerNote as nested reference is not support in meta
      data.customerNotes = (data.notes || {}).items;
    }

    let title = `Add ${repType} representative`;
    if (mode === 'new') {
      title = title.replace('Edit', 'Add');
      title = title.replace('View', 'Add');
    }
    if (mode === 'edit') {
      title = title.replace('Add', 'Edit');
      title = title.replace('View', 'Edit');
    }
    if (mode === 'view') {
      title = title.replace('Add', 'View');
      title = title.replace('Edit', 'View');
    }

    return (
      <ErrorBoundaries>
        <SergeantModal
          open={open}
          data={data}
          dataType="rep"
          title={title}
          mode={mode}
          formVersion={mode === 'new' || mode === 'edit' ? 'edit' : mode}
          layout={layout(existingReps)}
          handlePrimaryAction={this.handleOnComplete}
          handleClose={this.props.handleClose}
          customComponents={{
            ExclusiveCheckboxInput,
            ExclusiveReadOnlyCheckboxInput,
            NotesWrapper
          }}
        />
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapNewCustomerRepToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedCustomerRep = connect(mapStateToProps, mapNewCustomerRepToProps)(CustomerRep);

export default reduxConnectedCustomerRep;
