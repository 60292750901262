import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  daySelector: {
    display: 'flex',
    alignItems: 'center'
  },
  leftArrow: {
    transform: 'rotate(180deg)'
  },
  dateInput: {
    '& svg': {
      position: 'absolute'
    }
  }
}));
