export const styles = theme => ({
  header: {
    fontSize: 12,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    letterSpacing: 0.1,
    color: '#3f3f3f'
  },
  headerContainer: {
    paddingVertical: 3
  },
  warningIcon: {
    color: '#B89900',
    marginRight: 5,
    verticalAlign: 'sub',
    width: 22,
    height: 19,
    top: 2,
    position: 'relative'
  },
  rowTitle: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'normal',
    letterSpacing: 0.24,
    color: '#3f3f3f'
  },

  checkboxSecondary: {
    color: '#959595',
    fontSize: 14,
    padding: 0
  },
  checkboxChecked: {
    color: theme.palette.secondary.main
  },
  gridBorder: {
    borderBottom: '1px solid #e0e0e4',
    height: 46
  },
  footerButton: {
    paddingTop: 50
  },
  rowIcon: {
    color: '#646464'
  }
});
export default styles;
