import { noop } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { bool, func, number, oneOf, string } from 'prop-types';
import { Popover } from '@material-ui/core';
import {
  EVENT_NAMES,
  DEFAULT_EVENT_DURATION_IN_MIN,
  EventEntities,
  DEFAULT_START_TIME
} from '@dispatch/Dispatch.constants';
import { useDispatchSettings } from '@dispatch/settings';
import { EventIcons } from '@dispatch/Dispatch.icons';
import { EventColors } from '@dispatch/Dispatch.styles';
import { useStyles } from './CreateEventMenu.styles';

const VISIT_TYPE = EventEntities.VISIT.value.clientValue;
const BILLABLE_EVENT_TYPE = EventEntities.BILLABLE_EVENT.value.clientValue;
const NON_BILLABLE_EVENT_TYPE = EventEntities.NON_BILLABLE_EVENT.value.clientValue;
const MAN_DAY_TYPE = EventEntities.MAN_DAY.value.clientValue;

const Option = ({ eventType, onClick }) => {
  const icon = EventIcons[eventType];
  const text = EVENT_NAMES[eventType];
  const hoverColor = EventColors[eventType];
  const classes = useStyles({ hoverColor });
  return (
    <div className={classes.option} onClick={onClick} onKeyPress={noop} role="button" tabIndex="0">
      <img alt={text} src={icon} />
      <span>{text}</span>
    </div>
  );
};

Option.propTypes = {
  eventType: oneOf([
    EventEntities.VISIT.value.clientValue,
    EventEntities.BILLABLE_EVENT.value.clientValue,
    EventEntities.NON_BILLABLE_EVENT.value.clientValue,
    EventEntities.MAN_DAY.value.clientValue
  ]).isRequired,
  onClick: func.isRequired
};

const generateEventData = ({ techId, techName, day, hours, minutes }) => {
  const date = day ? moment(day, 'MM-DD-YYYY') : moment();
  date.set({
    hours,
    seconds: 0,
    milliseconds: 0,
    minutes
  });

  return {
    scheduledFor: date.unix(),
    actualDuration: DEFAULT_EVENT_DURATION_IN_MIN,
    primaryTechId: techId,
    primaryTechName: techName
  };
};

const CreateEventMenu = ({
  techId,
  techName,
  position,
  day,
  minutes,
  open,
  onClose,
  createEvent,
  weekViewDate
}) => {
  const classes = useStyles({ isWeek: !!weekViewDate });

  const [anchorEl, setAnchorEl] = useState();

  const { enabledEvents } = useDispatchSettings();

  if (!open || !position) return null;

  const createEventOfType = eventType => () => {
    createEvent({
      eventType,
      eventData: generateEventData({
        techId,
        techName,
        day: weekViewDate ?? day,
        hours: weekViewDate ? DEFAULT_START_TIME : 0,
        minutes: weekViewDate ? 0 : minutes
      })
    });
  };

  return (
    <>
      <div
        className={classes.placeholder}
        style={{
          left: position
        }}
        ref={setAnchorEl}
      />
      {anchorEl && (
        <Popover
          anchorEl={() => anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          keepMounted
          open={open}
          onClose={onClose}
          className={classes.popover}
        >
          <Option eventType={VISIT_TYPE} onClick={createEventOfType(VISIT_TYPE)} />

          {enabledEvents[BILLABLE_EVENT_TYPE]?.enabled && (
            <Option
              eventType={BILLABLE_EVENT_TYPE}
              onClick={createEventOfType(BILLABLE_EVENT_TYPE)}
            />
          )}

          {enabledEvents[NON_BILLABLE_EVENT_TYPE]?.enabled && (
            <Option
              eventType={NON_BILLABLE_EVENT_TYPE}
              onClick={createEventOfType(NON_BILLABLE_EVENT_TYPE)}
            />
          )}
          {enabledEvents[MAN_DAY_TYPE]?.enabled && (
            <Option eventType={MAN_DAY_TYPE} onClick={createEventOfType(MAN_DAY_TYPE)} />
          )}
        </Popover>
      )}
    </>
  );
};

CreateEventMenu.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  createEvent: func.isRequired,
  position: number,
  techId: string,
  techName: string,
  minutes: number,
  day: string,
  weekViewDate: string
};

CreateEventMenu.defaultProps = {
  position: 0,
  techId: '',
  techName: '',
  minutes: 0,
  day: '',
  weekViewDate: null
};

export default CreateEventMenu;
