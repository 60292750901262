import React from 'react';

import { Typography } from '@buildhero/sergeant';
import { useTheme } from '@material-ui/core/styles';

import TooltipIcon from 'components/ToolTip';

const TooltipLabel = ({ options: { label, message } }) => {
  const theme = useTheme();
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography
        style={{ fontSize: '10px', textTransform: 'uppercase', color: theme.palette.grayscale }}
      >
        {label}
      </Typography>
      <TooltipIcon helpText={message} />
    </div>
  );
};

export default TooltipLabel;
