import gql from 'graphql-tag';

const updateFee = gql`
  mutation updateFee($partitionKey: String!, $data: UpdateFeeInput!) {
    updateFee(partitionKey: $partitionKey, data: $data) {
      id
      sortKey
      itemName
      createdDateTime
      reasonForFee
      departmentName
      accountingRefIdOfClass
      taxable
      version
      amount
      includeInInvoice
    }
  }
`;

export default updateFee;
