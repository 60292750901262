function parseTotal({
  smartFieldInfo,
  settingsJSON,
  smartFieldsMap,
  SettingConstants,
  convertToCurrencyStringCheck
}) {
  const settingsTotals = settingsJSON[SettingConstants.TOTALS_AND_SUBTOTALS];
  return settingsTotals[SettingConstants.SHOW_TOTAL]
    ? convertToCurrencyStringCheck(
        smartFieldInfo[smartFieldsMap.TOTAL_OVERRIDE] ?? smartFieldInfo[smartFieldsMap.TOTAL]
      )
    : '';
}

export default parseTotal;
