import gql from 'graphql-tag';

const updateWIPReportSettings = gql`
  mutation updateWIPReportSettings($partitionKey: String!, $settings: WIPReportSettingsInput!) {
    updateWIPReportSettings(partitionKey: $partitionKey, settings: $settings) {
      overBillingGlAccountId
      overBillingGlOffsetAccountId
      underBillingGlAccountId
      underBillingGlOffsetAccountId
      accountingClassId
    }
  }
`;

export default updateWIPReportSettings;
