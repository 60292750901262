const tenantSchema = props => {
  const tenantJson = {
    firstName: props.firstName,
    lastName: props.lastName,
    companyName: props.companyName.trim(),
    accountName: props.accountName ? props.accountName.trim() : props.companyName.trim(),
    userName: props.userName
      ? props.userName.trim().toLowerCase()
      : props.email.trim().toLowerCase(),
    email: props.email.trim().toLowerCase(),
    tier: props.tier ? props.tier : 'free',
    status: props.status ? props.status : 'active',
    ownerName: props.ownerName ? props.ownerName : 'BuildOps'
  };
  return tenantJson;
};
export default tenantSchema;
