const styles = theme => ({
  topBar: {
    padding: 20,
    marginBottom: 30,
    borderColor: theme.palette.grayscale(85),
    background: theme.palette.grayscale(100),
    borderRadius: '4px 4px 0px 0px',
    boxShadow: '0px 2px 7px 0px rgba(0, 0, 0, 0.06)'
  },
  topBarDetails: {
    borderTop: `1px solid ${theme.palette.grayscale(85)}`,
    marginTop: 5
  },
  titleStyle: {
    color: theme.palette.brand.logoBlue,
    fontSize: 20,
    letterSpacing: -0.02,
    marginTop: 17,
    marginBottom: 17
  },
  inventoryTitleContainer: {
    marginTop: 15,
    marginBottom: 15
  }
});

export default styles;
