import React from 'react';

import moment from 'moment-timezone';

import LogRow from './LogRow';

const formatDuration = time => {
  const d = moment.duration(time, 'seconds');
  const h = d.hours();
  const m = d.minutes();
  const s = d.seconds();

  let hours = '';
  let minutes = '';
  let seconds = '';
  if (h > 0) hours = `${h} hour${h === 1 ? '' : 's'}`;
  if (m > 0) minutes = `${m} min${m === 1 ? '' : 's'}`;
  if (s > 0) seconds = `${s} sec${s === 1 ? '' : 's'}`;

  return [hours, minutes, seconds].filter(str => str !== '').join(' ');
};

const VisitTimeHistoryEntry = ({ schedule, payrollSetting }) => {
  return (
    <LogRow
      key={schedule.id}
      date={`Clock In: ${moment
        .unix(schedule.clockInTime)
        .tz(schedule.clockInTimezone || payrollSetting.timeZone)
        .format('MMM D, YYYY hh:mm A z')}`}
      subject={schedule.labourType}
      author={null}
      message={`Duration: ${formatDuration(schedule.totalDuration)}`}
    />
  );
};

export default VisitTimeHistoryEntry;
