import gql from 'graphql-tag';

const getInvoicesByJobNumber = gql`
  query getInvoicesByJobNumber($jobNumber: String!) {
    getJobByJobNumber(jobNumber: $jobNumber) {
      id
      sortKey
      invoices(entityConnection: "Invoice", filter: { isActive: { eq: true } }) {
        items {
          id
          sortKey
          status
          issuedDate
          dueDate
          totalAmount
          entityType
          invoiceNumber
          createdBy
          isFinalInvoice
          version
          invoiceVisits(entityConnection: "InvoiceVisit") {
            items {
              id
              sortKey
              visitSortKey
              visitId
              visit(entityConnection: "visit") {
                visitNumber
              }
            }
          }
        }
      }
    }
  }
`;

export default getInvoicesByJobNumber;
