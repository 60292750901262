import React, { useState } from 'react';
import { withTheme } from '@material-ui/core/styles';
import { Modal, DefaultButton } from 'components';
import ErrorBoundaries from 'scenes/Error';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import Labels from 'meta/labels';
import { MaterialMarkupType } from 'utils/constants';

const MaterialRateModal = props => {
  const [selectedValue, selectValue] = useState('');
  const { openMatRateModal, handleClose, classes, handleRateSave, user, marginEnabled } = props;

  return (
    <ErrorBoundaries>
      <Modal
        open={openMatRateModal || false}
        handleClose={() => {
          selectValue('');
          handleClose();
        }}
        classes={{ dialogContent: classes.dialogContent }}
      >
        <Typography variant="h3" classes={{ h3: classes.header }}>
          {`Add Material ${marginEnabled ? 'Margin' : 'Markup'} Rates`}
        </Typography>
        <FormControl>
          <RadioGroup
            aria-label="materialRates"
            name="materialRates"
            value={selectedValue}
            onChange={event => {
              selectValue(event.target.value);
            }}
          >
            <FormControlLabel
              value={MaterialMarkupType.NONE}
              control={<Radio />}
              label={`No Additional ${marginEnabled ? 'Margin' : 'Markup'}`}
              classes={{ label: classes.formControlLabel }}
            />
            <Typography variant="body2" className={classes.rateDescription}>
              {`Applies no additional ${
                marginEnabled ? 'margin' : 'markup'
              }. Standard pricebook will be replicated.`}
            </Typography>
            <FormControlLabel
              value={MaterialMarkupType.FLAT}
              control={<Radio />}
              label={marginEnabled ? 'Flat Margin' : 'Flat Markup'}
              classes={{ label: classes.formControlLabel }}
            />
            <Typography variant="body2" className={classes.rateDescription}>
              {`Overrides standard pricebook with a single ${
                marginEnabled ? 'margin' : 'markup'
              } rate.`}
            </Typography>
            <FormControlLabel
              value={MaterialMarkupType.RANGE}
              control={<Radio />}
              label={`Range-based ${marginEnabled ? 'Margin' : 'Markup'}`}
              classes={{ label: classes.formControlLabel }}
            />
            <Typography variant="body2" className={classes.rateDescription}>
              Overrides standard pricebook with markup rates for different price brackets.
            </Typography>
          </RadioGroup>
        </FormControl>
        <DefaultButton
          variant="contained"
          color="primary"
          disabled={selectedValue === ''}
          label={(marginEnabled ? Labels.addMaterialMarginRates : Labels.addMaterialMarkupRates)[
            user.locale
          ].toUpperCase()}
          style={{ width: '100%', marginTop: '20px' }}
          key="newserviceagreement"
          handle={event => {
            handleRateSave(event, selectedValue);
          }}
        />
      </Modal>
    </ErrorBoundaries>
  );
};

MaterialRateModal.propTypes = {
  user: PropTypes.object.isRequired,
  openMatRateModal: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleRateSave: PropTypes.func.isRequired,
  classes: PropTypes.object,
  marginEnabled: PropTypes.bool.isRequired
};

MaterialRateModal.defaultProps = {
  openMatRateModal: false,
  classes: {}
};

export default withTheme(MaterialRateModal);
