export const DEFAULT_EMPTY_MESSAGE = 'NO DATA';

export const DEFAULT_ERROR_MESSAGE = 'TABLE ERROR';

export const DEFAULT_COLUMN_WIDTH = 100;

export const ROW_HEIGHT = 41;

export const DEFAULT_MAX_HEIGHT = 410;

export const DEFAULT_ROWS_PER_PAGE = 10;

export const PLACEHOLDER_TABLE_HEIGHT = 120;

export const TablePlaceholderVariant = {
  TABLE: 'TABLE',
  BOX: 'BOX',
  TEXT: 'TEXT'
};

export const GroupingColumnMode = {
  SINGLE: 'single',
  MULTIPLE: 'multiple'
};
