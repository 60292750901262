import React from 'react';
import { orderBy } from 'lodash';
import {
  Modal,
  Button,
  Typography,
  Table,
  FieldType,
  ThemeProvider,
  TV
} from '@buildhero/sergeant';
import routeMapping from 'meta/entityRouteMappings';

const formatData = (data = []) => {
  const orderedInvoices = orderBy(data, ['invoiceNumber'], ['asc']);

  return orderedInvoices.map(invoice => ({
    invoiceNumber: {
      to: `${routeMapping.Invoice}${invoice.id}`,
      label: invoice.invoiceNumber
    },
    invoiceId: invoice.id,
    serviceAgreementName: {
      to: `${routeMapping.ServiceAgreement}${invoice.serviceAgreement.id}`,
      label: invoice.serviceAgreement.agreementName
    },
    customerName: {
      to: `${routeMapping.Customer}${invoice.customer.id}`,
      label: invoice.customer.customerName
    },
    issuedDate: invoice.issuedDate,
    nextBillableDate: invoice.serviceAgreement.nextBillDate
  }));
};

export default function ConfirmModal({ open, title, onClose, data }) {
  const structuredData = formatData(data);

  return (
    <ThemeProvider>
      <Modal
        open={open}
        title={title}
        actions={
          <Button onClick={onClose} fullWidth>
            Confirm
          </Button>
        }
        onClose={onClose}
        maxWidth={800}
      >
        <>
          <Typography style={{ paddingBottom: 16 }}>You created the following invoices:</Typography>
          <Table
            columns={[
              {
                id: 'invoiceNumber',
                label: 'INVOICE',
                type: FieldType.LINK,
                width: `${73 / 5.76}%`,
                variant: TV.BASE
              },
              {
                id: 'serviceAgreementName',
                label: 'SERVICE AGREEMENT',
                type: FieldType.LINK,
                width: `${144 / 5.76}%`,
                variant: TV.BASE
              },
              {
                id: 'customerName',
                label: 'CUSTOMER',
                type: FieldType.LINK,
                width: `${162 / 5.76}%`,
                variant: TV.BASE
              },
              {
                id: 'issuedDate',
                label: 'ISSUED DATE',
                type: FieldType.DATE,
                width: `${95 / 5.76}%`,
                variant: TV.BASE
              },
              {
                id: 'nextBillableDate',
                label: 'NEXT BILLABLE DATE',
                type: FieldType.DATE,
                width: `${102 / 5.76}%`,
                variant: TV.BASE
              }
            ]}
            data={structuredData}
            style={{ minWidth: 650 }}
          />
        </>
      </Modal>
    </ThemeProvider>
  );
}
