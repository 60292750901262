import React, { useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CompanyService } from 'services/core';
import { connect } from 'react-redux';
import { snackbarOn } from 'redux/actions/globalActions';
import Button from '@material-ui/core/Button';
import { Logger } from 'services/Logger';
import Spinner from 'components/Spinners/CircularIndeterminate';
import { PermissionConstants, EmployeeAction } from 'utils/AppConstants';
import { checkPermission } from 'utils';

import styles from './styles';

const labels = {
  [EmployeeAction.DEACTIVATE]: 'Deactivate Employee',
  [EmployeeAction.ACTIVATE]: 'Activate Employee'
};

function EmployeeActivationToggle(props) {
  const [loading, setLoading] = useState(false);
  const companyService = useMemo(() => new CompanyService(), []);

  const saveEmployee = (employeeDetails, action) =>
    companyService.saveEmployee({
      input: {
        id: employeeDetails.id,
        isAdmin: !!employeeDetails.isAdmin,
        email: employeeDetails.email,
        userName: employeeDetails.userName
      },
      action
    });

  const handleOnClick = async (employeeDetails, action) => {
    setLoading(true);
    try {
      const { data } = await saveEmployee(employeeDetails, action);
      if (!data) throw new Error('No data returned from saveEmployee');
      const actionMsg =
        action === EmployeeAction.DEACTIVATE ? 'deactivated employee' : 'activated employee';
      props.snackbarOn('success', `Successfully ${actionMsg}`);
      props.specialbehaviour.queryResult.refetch();
    } catch (err) {
      Logger.error(err);
      const actionMsg =
        action === EmployeeAction.DEACTIVATE ? 'deactivate employee' : 'activate employee';
      props.snackbarOn('error', `Unable to ${actionMsg}, please try again later`);
    } finally {
      setLoading(false);
    }
  };

  const employeeDetails = props.specialbehaviour.queryResult.employee;
  const hasPermission = checkPermission(
    'update',
    [PermissionConstants.OBJECT_EMPLOYEE],
    props.user
  );
  const employeeIsSelf = employeeDetails.id === props.user.employeeId;
  const action = employeeDetails.isActive ? EmployeeAction.DEACTIVATE : EmployeeAction.ACTIVATE;

  if (!hasPermission || employeeIsSelf) return null;

  return (
    <Button
      variant="outlined"
      color="secondary"
      className={props.classes.inlineFormButton}
      onClick={() => handleOnClick(employeeDetails, action)}
      disabled={loading}
      // fullWidth={true}
    >
      {labels[action]}
      {loading && <Spinner className={props.classes.buttonProgress} size={18} />}
    </Button>
  );
}

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(EmployeeActivationToggle));
