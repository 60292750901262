import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => {
  const padding = theme.spacing(1);
  const iconSize = 22;
  return {
    badge: ({ backgroundColor }) => ({
      backgroundColor,
      borderRadius: 4,
      color: 'white',
      fontSize: 12,
      padding,
      paddingLeft: padding + iconSize,
      marginBottom: theme.spacing(1),
      position: 'relative',
      '& img': {
        position: 'absolute',
        width: iconSize,
        height: iconSize,
        left: padding / 2,
        top: 5
      }
    })
  };
});
