import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { Button, ThemeProvider, Typography, TW, TV, ButtonType } from '@buildhero/sergeant';
import { CustomerPropertyService } from 'services/core';
import { Logger } from 'services/Logger';
import ResponsiveTable from 'components/ResponsiveTable';
import { quoteRowMeta } from 'meta/Customer/CustomerProperty/tables';
import ErrorBoundaries from 'scenes/Error';
import { snackbarOn } from 'redux/actions/globalActions';
import { UserPermission } from 'components';
import { PermissionConstants, QuoteConstants } from 'utils/AppConstants';

const QuoteList = ({ isActive, propertyId, history }) => {
  const dispatch = useDispatch();
  const snackbar = useCallback((...args) => dispatch(snackbarOn(...args)), [dispatch]);

  const getAllQuotesForProperty = async (filter, limit, offset, sortBy, sortOrder) => {
    if (!propertyId) {
      return { items: [], nextToken: '0' };
    }
    let data;
    try {
      data = await new CustomerPropertyService().getQuotesByCustomerPropertyById(
        propertyId,
        filter || null,
        limit || null,
        offset || null,
        sortBy,
        sortOrder
      );
      // Quote status will be like InQuote, it needs to be shown as In quote
      (data?.items || []).map(dataItem => {
        const localDataItem = dataItem;
        localDataItem.quoteNumber = dataItem.customIdentifier || dataItem.quoteNumber;
        if (dataItem.status) {
          localDataItem.status = QuoteConstants[dataItem.status] || dataItem.status;
        }
        return localDataItem;
      });
      return data;
    } catch (error) {
      Logger.error(error);
      snackbar('error', 'Unable to fetch Quotes, please try again later', error);
    }
    return data || [];
  };

  const handleAddQuote = () => {
    history.push('/quotes/new', {
      propertyId
    });
  };
  return (
    <ErrorBoundaries>
      <UserPermission I="read" action={PermissionConstants.OBJECT_QUOTES}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
          marginBottom={1}
        >
          <ThemeProvider>
            <Typography variant={TV.BASE} weight={TW.BOLD}>
              Quotes for this Property
            </Typography>
            <UserPermission I="create" action={PermissionConstants.OBJECT_QUOTES}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Button type={ButtonType.SECONDARY} onClick={handleAddQuote} disabled={!isActive}>
                  Create Quote
                </Button>
              </Box>
            </UserPermission>
          </ThemeProvider>
        </Box>
        <ResponsiveTable
          rowMetadata={quoteRowMeta()}
          service={getAllQuotesForProperty}
          defaults={{
            sortBy: 'quoteNumber',
            sortOrder: 'desc'
          }}
          noDataMsg="No Quotes"
          caslKey={PermissionConstants.OBJECT_QUOTES}
        />
      </UserPermission>
    </ErrorBoundaries>
  );
};

export default QuoteList;
