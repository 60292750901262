import gql from 'graphql-tag';

const companyAddressFragment = `
    addressLine1
    addressLine2
    state
    city
    zipcode
    latitude
    longitude
    addressType
    __typename
`;

const getAllInvoiceByCompany = gql`
  query getCompany(
    $partitionKey: String!
    $sortKey: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
    $adjustmentsFlag: Boolean!
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      invoices: invoicesView(
        entityConnection: "Invoice"
        pagination: { limit: $limit, offset: $offset }
        filter: $filter
        sorting: $sort
      ) {
        nextToken
        items {
          id
          invoiceNumber
          adjustedBalance @include(if: $adjustmentsFlag)
          createdBy
          lastUpdatedDateTime
          issuedDate
          status
          invoiceInvoiceTags {
            items {
              id
              parentId
              entityType
              invoiceTagId
              mappedEntity: invoiceTag {
                id
                entityType
                tagName
              }
            }
          }
          totalAmount
          dueDate
          syncLog
          syncStatus
          invoicePdfUrl
          projectName
          projectId
          customer(entityConnection: "Customer") {
            id
            sortKey
            customerName
          }
          billingCustomer(entityConnection: "BillingCustomer") {
            id
            sortKey
            customerName
            companyAddresses(entityConnection: "CompanyAddress") {
              items {
                ${companyAddressFragment}
              }
            }
          }
          customerProperty(entityConnection: "CustomerProperty") {
            id
            sortKey
            companyName
          }
          job(entityConnection: "Job") {
            id
            sortKey
            jobNumber
            status
            jobTypeName
            jobTypeInternal
            completedDate
            customIdentifier
            customerName
            customerSortKey
            customerId
            customerPropertyName
            customerPropertySortKey
            customerPropertyId
            owner(entityConnection: "owner") {
              id
              name
              firstName
              lastName
            }
            jobJobTags(entityConnection: "JobJobTag") {
              items {
                id
                entityType
                mappedEntity: jobTag {
                  id
                  entityType
                  tagName
                }
              }
            }
            billingCustomerName
            billingCustomerId
            billingCustomer(entityConnection: "Customer") {
              id
              sortKey
              customerName
            }
          }
          department(entityConnection: "Department") {
            id
            tagName
          }
          paymentInvoiceView(entityConnection: "PaymentInvoice") {
            items {
              parentId
              invoiceId
              appliedAmount
              invoice(entityConnection: "Invoice") {
                id
                invoiceNumber
              }
              deletedDate
            }
          }
          aggregatedField(
            input: {
              entityConnection: "PaymentInvoice"
              aggregations: [
                { aggregationType: SUM, aggregationField: "PaymentInvoice.appliedAmount" }
              ]
            }
          ) {
            items {
              aggregation1
            }
          }
          RelatedVisit: invoiceVisits(entityConnection: "InvoiceVisit") {
            items {
              id
              visit(entityConnection: "visit") {
                id
                sortKey
                visitNumber
                departmentName
                submittedBy
                reviewReports(entityConnection: "ReviewReport") {
                  items {
                    id
                  }
                }
                job: parentEntity {
                  ... on Job {
                    id
                    sortKey
                    customIdentifier
                    jobNumber
                    customerName
                    customerId
                    customerSortKey
                    customerPropertyName
                    customerPropertyId
                    customerPropertySortKey
                    jobTypeName
                    jobTypeInternal
                    status
                    owner(entityConnection: "owner") {
                      id
                      name
                      firstName
                      lastName
                    }
                    billingCustomer(entityConnection: "BillingCustomer") {
                      id
                      sortKey
                      customerName
                    }
                    invoices: invoicesView(entityConnection: "Invoice") {
                      items {
                        id
                        invoiceNumber
                        isActive
                        status
                        invoiceVisits(entityConnection: "InvoiceVisit") {
                          items {
                            visit(entityConnection: "visit") {
                              visitNumber
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          createdDate
          customerProvidedPONumber
          customerProvidedWONumber
          amountNotToExceed
          balanceAmountComputed
          companyAddresses(entityConnection: "CompanyAddress") {
            items {
              ${companyAddressFragment}
            }
          }
        }
      }
    }
  }
`;

export default getAllInvoiceByCompany;
