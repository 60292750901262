import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography, Grid, Card, CardActionArea, Popover, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import ErrorBoundaries from 'scenes/Error';
import { snackbarOn } from 'redux/actions/globalActions';
import ImgThumbnail from 'components/ImageThumbnail';
import ImageFullScreenDisplay from 'components/ImageFullScreenDisplay';
import AppConstants from 'utils/AppConstants';
import styles from './styles';

/**
 * Renders images in a Thumbnail view. On click of image , navigates to fullScreen component
 * labels are fetched from application level
 * locale of the user is referred from user context
 */
class ImageThumbnail extends Component {
  state = {
    fullScreenActive: false,
    currentIndex: 0,
    anchorEI: '',
    openedPopOverId: null
  };

  toggleView = index => {
    this.setState(prevState => ({
      fullScreenActive: !prevState.fullScreenActive,
      currentIndex: index
    }));
  };

  handlePopoverOpen = (event, popoverId) => {
    this.setState({ openedPopOverId: popoverId, anchorEI: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ openedPopOverId: null, anchorEI: null });
  };

  render() {
    const { classes, images, caption } = this.props;
    const { openedPopOverId, anchorEI } = this.state;

    // const open = Boolean(anchorEI);

    let thumbnailImages = [];
    if (images) {
      thumbnailImages = images.slice();
      if (thumbnailImages) {
        thumbnailImages.map(thumbnail => {
          let currentThumbnail = thumbnail;
          if (thumbnail.fileUrl) {
            currentThumbnail += '.medium';
          }
          return currentThumbnail;
        });
      }
    }

    return (
      <ErrorBoundaries>
        {thumbnailImages && thumbnailImages.length > 0 && (
          <>
            <Typography style={{ fontSize: 16, paddingBottom: 14 }}>{caption}</Typography>
            <Grid container justify="flex-start" alignItems="center" direction="row">
              {thumbnailImages &&
                thumbnailImages.map((image, index) => (
                  <Grid item style={{ padding: 8 }}>
                    <Card
                      className={classes.card}
                      onClick={() => this.toggleView(index)}
                      key={image.id}
                    >
                      <CardActionArea
                        aria-owns={openedPopOverId ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={e => this.handlePopoverOpen(e, image.id)}
                        onMouseLeave={this.handlePopoverClose}
                      >
                        <ImgThumbnail image={image} height={140} location="Gallery" />
                      </CardActionArea>
                      <Popover
                        id="mouse-over-popover"
                        className={classes.popover}
                        classes={{
                          paper: classes.paper
                        }}
                        open={openedPopOverId === image.id}
                        anchorEl={anchorEI}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left'
                        }}
                        onClose={this.handlePopoverClose}
                        disableRestoreFocus
                        style={{ pointerEvents: 'none' }}
                      >
                        <Paper>
                          <Grid container alignItems="flex-start" direction="row">
                            <Grid Item>
                              <Typography className={classes.popoverComment}>Comment</Typography>
                            </Grid>
                          </Grid>
                          <Grid container alignItems="flex-start" direction="row">
                            <Grid Item>
                              <Typography className={classes.popoverContent}>
                                {image.comment}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container direction="row">
                            <grid item lg={4}>
                              <grid container direction="column">
                                <grid item>
                                  <Typography className={classes.popoverHeader}>
                                    File name
                                  </Typography>
                                </grid>
                                <grid item>
                                  <Typography className={classes.popoverContent}>
                                    {image.customFileName || image.fileName}
                                  </Typography>
                                </grid>
                              </grid>
                            </grid>
                            <grid item lg={4}>
                              <grid container direction="column">
                                <grid item>
                                  <Typography className={classes.popoverHeader}>
                                    Date Added
                                  </Typography>
                                </grid>
                                <grid item>
                                  <Typography className={classes.popoverContent}>
                                    {moment
                                      .unix(image.createdDate)
                                      .format(AppConstants.DATE_FORMAT)}
                                  </Typography>
                                </grid>
                              </grid>
                            </grid>
                            <grid item lg={4}>
                              <grid container direction="column">
                                <grid item>
                                  <Typography className={classes.popoverHeader}>
                                    Added by
                                  </Typography>
                                </grid>
                                <grid item>
                                  <Typography className={classes.popoverContent}>
                                    {image.addedBy || image.createdBy}
                                  </Typography>
                                </grid>
                              </grid>
                            </grid>
                          </Grid>
                        </Paper>
                      </Popover>
                    </Card>
                  </Grid>
                ))}
            </Grid>
            {this.state.fullScreenActive ? (
              <ImageFullScreenDisplay
                toggle={this.toggleView}
                images={images}
                index={this.state.currentIndex}
              />
            ) : null}
          </>
        )}
      </ErrorBoundaries>
    );
  }
}

const styledImageThumbnail = withStyles(styles)(ImageThumbnail);

const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});

// utilizes global spinner actions
const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const connectedAllNotes = connect(mapStateToProps, mapDispatchToProps)(styledImageThumbnail);

export default connectedAllNotes;
