import gql from 'graphql-tag';

const updateVendor = gql`
  mutation updateVendor($partitionKey: String!, $data: UpdateVendorInput!) {
    updateVendor(partitionKey: $partitionKey, data: $data) {
      id
      version
    }
  }
`;

export default updateVendor;
