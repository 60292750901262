import { removeEmptyValues } from '../../utils';
import InstructionHelper from '../InstructionHelper';

const tenantServiceSchema = (valuesArray, parent) => {
  const data = [{ ...removeEmptyValues(parent) }];
  const ins = new InstructionHelper();
  let temp;
  valuesArray &&
    valuesArray.forEach(value => {
      data.push(value);
      temp = ins.oneToMany(0, data.length - 1);
    });

  const { instructionSet } = temp;
  return { data: data, instructionSet: instructionSet };
};

export default tenantServiceSchema;
