import React from 'react';
import { bool, func } from 'prop-types';
import { ButtonGroup } from '@material-ui/core';
import { Button, ThemeProvider } from '@buildhero/sergeant';

const MapViewSelector = ({ mapView, setMapView }) => (
  <ThemeProvider>
    <ButtonGroup>
      <Button onClick={() => setMapView(false)} type={!mapView ? 'secondary' : 'tertiary'}>
        Schedule
      </Button>
      <Button onClick={() => setMapView(true)} type={mapView ? 'secondary' : 'tertiary'}>
        Map
      </Button>
    </ButtonGroup>
  </ThemeProvider>
);

MapViewSelector.propTypes = {
  mapView: bool,
  setMapView: func.isRequired
};

MapViewSelector.defaultProps = {
  mapView: false
};

export default MapViewSelector;
