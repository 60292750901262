import { useExtendedFetchForPMAPI as useExtendedFetch } from 'customHooks/useExtendedFetch';

export const useGetChangeOrderById = (id, options, params) => {
  return useExtendedFetch(
    {
      url: `changeorder/${id}`,
      params
    },
    {
      errorData: [],
      ...options
    }
  );
};
