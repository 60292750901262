import React from 'react';
import { ResponsiveTable, SyncStatusIcon } from 'components';
import { ListService } from 'services/core';
import { invoiceRowsMeta } from 'meta/ListViews';
import DownloadInvoice from 'components/DownloadInvoice';
import { logErrorWithCallback, getTenantSettingValueForKey } from 'utils';

export const RelatedInvoices = ({ agreementInfo, user, showSnackbar }) => {
  const listServiceObj = new ListService();
  const getInvoices = async (filter, limit, offset, sortBy, sortOrder) => {
    if (!user.tenantId) return { items: [], nextToken: '0' };

    const sortKey = `${user.tenantId}_Company_${user.tenantCompanyId}`;
    const hardFilter = {
      stringFilters: [
        {
          fieldName: 'Invoice.serviceAgreementId',
          filterInput: { eq: agreementInfo.id }
        }
      ]
    };
    let data;
    try {
      data = await listServiceObj.getAllInvoices(
        user.tenantId,
        sortKey,
        hardFilter,
        limit,
        offset,
        sortBy,
        sortOrder
      );
      return data;
    } catch (error) {
      logErrorWithCallback(error, showSnackbar, 'Unable to fetch invoices, please try again later');
    }
    return data || [];
  };

  const accountingIntegration = getTenantSettingValueForKey('accountingApp');
  const isAccountIntegrationEnabled = !!accountingIntegration;

  return (
    <ResponsiveTable
      rowMetadata={invoiceRowsMeta(isAccountIntegrationEnabled)}
      service={getInvoices}
      customCellComponents={{
        download: ({ record }) => <DownloadInvoice record={record} user={user} />,
        syncStatus: ({ record }) => <SyncStatusIcon status={record.syncStatus} />
      }}
      showToolbars
      disableFilter
    />
  );
};
