import React from 'react';
import PropTypes from 'prop-types';
import { searchIndex } from 'constants/algoliaIndex';
import { Typography, TV, TW } from '@buildhero/sergeant';
import { useTheme } from '@material-ui/core';
import { renderFor } from 'scenes/Invoices/CreateInvoice/components/InvoiceParentSearch/InvoiceParentSearch.utils';
import AlgoliaSearch from '../AlgoliaSearch';

export default function JobSearch({ field, form, props: givenProps }) {
  const { palette } = useTheme();
  const props = {
    indexName: searchIndex,
    formatHitLabel: hit => hit.customIdentifier,
    filters: `entityType:Job AND customerStatus:active`,
    onChange: ({ id, customIdentifier }) => {
      form.setValues(
        prev => ({
          ...prev,
          [field.name.split('.')[0]]: { id, customIdentifier }
        }),
        true
      );
      // act like blur event -> can't use actul blur b/c mousedown on popper
      // will run the blur event before the selection
      form.setFieldTouched(field.name, true, false);
    },
    onClickAway: () => form.setFieldTouched(field.name, true),
    handleRenderOption: ({ label, value }) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: 24
        }}
      >
        <Typography variant={TV.S3} weight={TW.MEDIUM} caps>
          {value.jobTypeInternal}
        </Typography>
        {renderFor[value.jobTypeInternal](label, value, palette)}
      </div>
    ),
    ...givenProps,
    name: field.name,
    value: field.value
  };

  return <AlgoliaSearch {...props} />;
}

JobSearch.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  // any input props
  props: PropTypes.shape({
    style: PropTypes.object,
    label: PropTypes.string,
    required: PropTypes.bool
  }).isRequired
};
