import { string } from 'yup';
import camelCase from 'lodash/camelCase';
import { getState } from 'redux/store';
import { AccountingApp } from 'utils/constants';

const accountingAppAddressLineLimit = {
  [AccountingApp.QUICKBOOKS]: 41,
  [AccountingApp.INTACCT]: 200,
  [AccountingApp.COMPUTER_EASE]: 200,
  [AccountingApp.RYVIT_VISTA]: 200,
  [AccountingApp.SAGE]: 200,
  null: 255 // default
};

const getMax = () => accountingAppAddressLineLimit[getState().settings.accountingApp] || 255;

export const cityStateZipcodeTest = (prefix, max = getMax()) => ({
  name: 'cityStateZipcodeTest',
  exclusive: false,
  params: {},
  message: `"City, state zipcode" cannot exceed ${max} characters.`,
  test() {
    const {
      [camelCase(`${prefix}City`)]: city,
      [camelCase(`${prefix}State`)]: state,
      [camelCase(`${prefix}Zipcode`)]: zipcode
    } = this.parent;
    return `${city}, ${state} ${zipcode}`.length <= max;
  }
});

export const toLengthTest = (max = getMax()) => ({
  name: 'toTest',
  exclusive: false,
  params: {},
  message: `Each line cannot exceed ${max} characters.`,
  test(to = '') {
    return to === null || to.split('\n').every(line => line.length <= max);
  }
});

export const addressValidationFields = (prefix = '', required = true) => {
  const max = getMax();
  const cityStateZipcode = cityStateZipcodeTest(prefix, max);
  const to = toLengthTest(max);

  const [addressLine1, addressLine2, city, state, zipcode, billTo, shipTo] = [
    'AddressLine1',
    'AddressLine2',
    'City',
    'State',
    'Zipcode',
    'BillTo',
    'ShipTo'
  ].map(field => camelCase(`${prefix}${field}`));

  const fields = {
    [addressLine1]: string()
      .nullable()
      .max(max, `Cannot exceed ${max} characters.`),
    [addressLine2]: string()
      .nullable()
      .max(max, `Cannot exceed ${max} characters.`),
    [city]: string()
      .nullable()
      .test(cityStateZipcode)
      .when(addressLine1, {
        is: val => val?.length > 0,
        then: string().required('City is required.')
      }),
    [state]: string()
      .nullable()
      .test(cityStateZipcode)
      .when(addressLine1, {
        is: val => val?.length > 0,
        then: string().required('State/Province is required.')
      }),
    [zipcode]: string()
      .nullable()
      .test(cityStateZipcode)
      .when(addressLine1, {
        is: val => val?.length > 0,
        then: string().required('Zip/Postal Code is required.')
      }),
    [billTo]: string()
      .nullable()
      .test(to),
    [shipTo]: string()
      .nullable()
      .test(to)
  };

  if (required) {
    fields[addressLine1] = fields[addressLine1].required('Address Line 1 is required.');
    fields[city] = fields[city].required('City is required.');
    fields[state] = fields[state].required('State/Province is required.');
    fields[zipcode] = fields[zipcode].required('Zip/Postal Code is required.');
  }

  return fields;
};
