import {
  TextInputProperties,
  NumberInputProperties,
  DatePickerProperties,
  TextAreaProperties,
  SectionHeaderProperties,
  TextBlockProperties,
  ImageProperties,
  CheckboxProperties,
  CheckboxListProperties,
  RadioButtonProperties,
  SelectInputProperties,
  // FileUploadProperties,
  RichTextEditorProperties,
  SignatureProperties
} from '../controls';

// List of supported Controls and their properties
const contentControls = {
  label: 'Content and Images',
  tools: [SectionHeaderProperties, TextBlockProperties, ImageProperties]
};

const inputControls = {
  label: 'Input Controls',
  tools: [
    TextInputProperties,
    NumberInputProperties,
    DatePickerProperties,
    TextAreaProperties,
    SelectInputProperties
  ]
};

const interactionControls = {
  label: 'Interactive Controls',
  tools: [CheckboxProperties, CheckboxListProperties, RadioButtonProperties, SignatureProperties]
};

const toolboxToolSections = [contentControls, inputControls, interactionControls];

export const developerVersionControl = [
  {
    label: 'Developer Only',
    tools: [RichTextEditorProperties]
  }
];

const formatToolboxTools = toolSections =>
  toolSections.map(sections => {
    const { label, tools } = sections;
    const updatedTools = tools.map(tool => {
      const toolProps = { ...tool };
      toolProps.id = null;
      toolProps.isRequired = false;
      return toolProps;
    });
    return { label, tools: updatedTools };
  });

export const developerVersionTools = formatToolboxTools(developerVersionControl).pop();

const toolboxTools = formatToolboxTools(toolboxToolSections);

export default toolboxTools;
