import React, { useMemo } from 'react';
import gql from 'graphql-tag';
import { Box } from '@material-ui/core';
import moment from 'moment';

import ErrorBoundaries from 'scenes/Error';
import { PermissionConstants, MultiSelectTypes } from 'utils/AppConstants';
import { UserPermission, XGrid } from 'components';
import { EnumType } from 'utils/constants';
import { column, ColumnType, valueGetters } from 'components/XGrid/columnTypes';

const GET_JOBS = gql`
  query getJobsList(
    $tenantId: String
    $filter: TableFilterInput
    $pagination: PaginationInput
    $sorting: [TableSortingInput]
  ) {
    data: getJobsList(
      tenantId: $tenantId
      filter: $filter
      pagination: $pagination
      sorting: $sorting
    ) {
      rowCount
      items
    }
  }
`;

/**
 * We can either massage the data here through valueGetter
 * or we can format the data before it goes into the table.
 */
const jobColumns = [
  {
    field: 'customIdentifier',
    headerName: 'Job',
    width: 100,
    valueGetter: valueGetters.jobLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'visitCount',
    headerName: 'Visits',
    width: 100,
    ...column[ColumnType.NUMBER]
  },
  {
    field: 'outstandingBalance',
    headerName: 'Outstanding Balance',
    width: 200,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'overdueBalance',
    headerName: 'Overdue Balance',
    width: 200,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'totalBilledJobComputed',
    headerName: 'Total Billed for Job',
    width: 200,
    ...column[ColumnType.CURRENCY]
  },
  {
    field: 'jobTypeName',
    headerName: 'Job Type',
    width: 150,
    enumType: MultiSelectTypes.JOB_TYPES,
    ...column[ColumnType.TAG]
  },
  {
    field: 'jobTags',
    headerName: 'Tags',
    width: 100,
    enumType: MultiSelectTypes.JOB_TAGS,
    ...column[ColumnType.TAGS]
  },
  {
    field: 'propertyName',
    headerName: 'Property',
    width: 150,
    valueGetter: valueGetters.propertyLink,
    ...column[ColumnType.LINK]
  },
  {
    field: 'createdDate',
    headerName: 'Created On',
    width: 150,
    ...column[ColumnType.DATE]
  },
  {
    field: 'age',
    headerName: 'Age (Days)',
    width: 150,
    valueGetter: ({ id, getValue }) => {
      // using same logic as in property
      const start = moment.unix(getValue(id, 'createdDate'));
      const end = moment.unix(Date.now() / 1000);
      return Math.ceil(moment.duration(end.diff(start)).asDays());
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    enumType: EnumType.JOB_STATUS,
    showIcon: true,
    ...column[ColumnType.ENUM]
  },
  {
    field: 'projectManagerName',
    headerName: 'Project Manager',
    width: 175,
    type: 'string'
  },
  {
    field: 'issueDescription',
    headerName: 'Issue Description',
    width: 300,
    type: 'string'
  },
  {
    field: 'serviceAgreementId',
    hide: true,
    metaOnly: true,
    headerName: 'serviceAgreementId',
    type: 'string'
  }
];

const JobsList = ({ agreementInfo }) => {
  const silentFilter = useMemo(
    () => [
      {
        columnField: 'serviceAgreementId',
        operatorValue: '=',
        value: agreementInfo?.id
      }
    ],
    [agreementInfo?.id]
  );
  return (
    <Box height={400}>
      <ErrorBoundaries>
        <UserPermission I="read" action={PermissionConstants.OBJECT_JOB}>
          <XGrid columns={jobColumns} query={GET_JOBS} silentFilter={silentFilter} disableToolbar />
        </UserPermission>
      </ErrorBoundaries>
    </Box>
  );
};

export default JobsList;
