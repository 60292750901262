export const attachmentLayout = {
  fields: {},
  layouts: {
    edit: {
      options: {
        direction: 'row',
        width: 450
      },
      contents: [
        {
          options: {
            direction: 'column'
          },
          contents: [
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'FileUpload'
                  },
                  source: 'attachments',
                  options: {
                    label: 'Attachment',
                    isRequired: true,
                    multiple: false,
                    accept: 'image/*,video/*'
                  }
                }
              ]
            },
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput'
                  },
                  source: 'customFileName',
                  options: {
                    label: 'File Name',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'SelectInput'
                  },
                  source: 'type',
                  options: {
                    label: 'Type',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left',
                    inputOptions: [
                      {
                        label: 'Before',
                        value: 'before'
                      },
                      {
                        label: 'After',
                        value: 'after'
                      }
                    ],
                    enableSort: false
                  }
                }
              ]
            },
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput'
                  },
                  source: 'comment',
                  options: {
                    label: 'Comment',
                    fullWidth: true,
                    alignment: 'left',
                    lines: 3
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      attachments: {
        type: 'array'
      },
      customFileName: {
        type: 'string'
      },
      type: {
        type: 'string',
        nullable: true
      },
      comment: {
        type: 'string',
        nullable: true
      }
    },
    required: ['attachments', 'customFileName', 'type']
  },
  validationErrors: {
    attachments: {
      required: 'File is required.'
    },
    customFileName: {
      required: 'File name is required.'
    },
    type: {
      required: 'Type is required.'
    }
  }
};
