import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  title: {
    lineHeight: '16px',
    fontWeight: 'bold',
    margin: '0px 8px 16px 8px'
  }
}));

const Title = ({ options }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.title} variant="subTitle">
      <b>{options.label}</b>
    </Typography>
  );
};

export default Title;
