import gql from 'graphql-tag';

const getAllInvoices = gql`
  query getInvoices($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      invoices: invoicesView(entityConnection: "Invoice") {
        items {
          id
          version
          status
          invoiceNumber
          invoicePdfUrl
        }
      }
    }
  }
`;

export default getAllInvoices;
