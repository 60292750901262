const styles = theme => ({
  invoiceNextButton: {
    marginLeft: 0,
    marginRight: 0,
    height: 36,
    minWidth: 170
  },
  invoiceCancelButton: {
    minWidth: 170,
    height: 36,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1
  },
  uncheckedVisits: {
    width: 90,
    height: 24,
    borderRadius: 12,
    borderColor: '#2dce89',
    borderStyle: 'solid',
    borderWidth: 1,
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.25,
    textAlign: 'center',
    color: '#959595',
    paddingTop: 2
  },
  checkedVisits: {
    width: 90,
    height: 24,
    borderRadius: 12,
    borderColor: '#2dce89',
    borderStyle: 'solid',
    borderWidth: 1,
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.25,
    textAlign: 'center',
    color: '#ffffff',
    backgroundColor: '#2dce89',
    paddingTop: 2
  }
});
export default styles;
