import React from 'react';

import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';

import { EventEntities } from '@dispatch/Dispatch.constants';
import { selectEndDay } from '@dispatch/Dispatch.selectors';
import { withQueryStringStore } from '@dispatch/Dispatch.store';
import { useDispatchSettings } from '@dispatch/settings';

import { snackbarOn } from 'redux/actions/globalActions';
import { useGetCompanyDispatchSettings } from 'scenes/Settings/Dispatch/DispatchSettings.hooks';

import DispatchBoard from './DispatchBoard.component';
import { useFilteredTechnicians } from './DispatchBoard.hooks';
import {
  useDispatchBoardManDayItems,
  useDispatchBoardNonVisitEvents,
  useDispatchBoardVisits
} from './queries';

const mapQueryStringToProps = ({ state }) => {
  const { mapView, weekView, crews, departments, technicians, day } = state;

  return {
    mapView: Boolean(mapView),
    weekView: Boolean(weekView),
    technicians,
    departments,
    crews,
    endDay: selectEndDay(state.endDay, state.weekView, state.day),
    day
  };
};

const DataProvider = props => {
  const user = useSelector(s => s.user);
  const { tenantId, tenantCompanyId } = user;
  const { data: dispatchSettingData } = useGetCompanyDispatchSettings({
    tenantId,
    tenantCompanyId,
    snackbar: props.snackbar
  });
  const customWeekStartDay =
    dispatchSettingData && JSON.parse(dispatchSettingData.settings).weekViewStartDate;
  const { enabledEvents } = useDispatchSettings();
  const techsResponse = useFilteredTechnicians(props);
  const boardVisitsResponse = useDispatchBoardVisits({
    ...props,
    customWeekStartDay
  });
  const boardNonVisitEventsResponse = useDispatchBoardNonVisitEvents({
    ...props,
    customWeekStartDay
  });
  const boardManDayItemsResponse = useDispatchBoardManDayItems({
    ...props,
    customWeekStartDay,
    skip: !enabledEvents[EventEntities.MAN_DAY.value.clientValue]?.enabled
  });

  return (
    <DispatchBoard
      {...props}
      techsResponse={techsResponse}
      boardVisitsResponse={boardVisitsResponse}
      boardNonVisitEventsResponse={boardNonVisitEventsResponse}
      boardManDayItemsResponse={boardManDayItemsResponse}
      customWeekStartDay={customWeekStartDay}
    />
  );
};

DataProvider.propTypes = {
  crews: PropTypes.array,
  departments: PropTypes.array,
  technicians: PropTypes.array,
  snackbar: PropTypes.func.isRequired
};

DataProvider.defaultProps = {
  crews: [],
  departments: [],
  technicians: []
};

const mapDispatcherToProps = { snackbar: snackbarOn };

export default compose(
  connect(null, mapDispatcherToProps),
  withQueryStringStore(mapQueryStringToProps)
)(DataProvider);
