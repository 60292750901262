import React from 'react';
import { ProjectService } from 'services/core';
import { logErrorWithCallback } from 'utils';
import { COST_TYPE } from 'constants/common';

export const splitJobCostTypes = items =>
  items.reduce(
    (acc, jobCost) => {
      const { jobCostTypes, revenueTypes } = acc;
      const { type } = jobCost;
      if ([COST_TYPE.REVENUE, COST_TYPE.BOTH].includes(type)) {
        revenueTypes.push(jobCost);
      }
      if ([COST_TYPE.JOB_COST, COST_TYPE.BOTH].includes(type)) {
        jobCostTypes.push(jobCost);
      }
      return { jobCostTypes, revenueTypes };
    },
    { jobCostTypes: [], revenueTypes: [] }
  );

export const getJobCostTypes = async (tenantId, companyId, snackbarOn) => {
  let responseData = [];
  try {
    const Service = new ProjectService();
    const response = await Service.getJobCostTypes(tenantId, companyId);
    responseData = response?.data?.getCompany?.jobCostTypes?.items;
  } catch (error) {
    logErrorWithCallback(
      error,
      snackbarOn,
      'Unable to fetch job cost types, please try again later'
    );
  }
  return responseData;
};

export const getCostCodes = async (tenantId, companyId, snackbarOn) => {
  let responseData = [];
  try {
    const Service = new ProjectService();
    const response = await Service.getCostCodes(tenantId, companyId);
    responseData = response?.data?.getCompany?.costCodes?.items;
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, 'Unable to fetch cost code, please try again later');
  }
  return responseData;
};

const useLabourTypeDependency = (tenantId, tenantCompanyId, snackbarOn) => {
  const [costCodes, setCostCodes] = React.useState([]);
  const [jobCostTypes, setJobCostTypes] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const splittedJobCost = splitJobCostTypes(jobCostTypes);
  const getDependency = async () => {
    setLoaded(false);
    const costCodeDetails = await getCostCodes(tenantId, tenantCompanyId, snackbarOn);
    const costCodeTypeDetails = await getJobCostTypes(tenantId, tenantCompanyId, snackbarOn);
    setCostCodes(costCodeDetails);
    setJobCostTypes(costCodeTypeDetails);
    setLoaded(true);
  };
  React.useEffect(() => {
    if (tenantId && tenantCompanyId) {
      getDependency();
    }
    // Disable lint - getDependency function not needed
    // eslint-disable-next-line
  }, [tenantId, tenantCompanyId]);
  return [
    costCodes,
    splittedJobCost.jobCostTypes,
    splittedJobCost.revenueTypes,
    getDependency,
    loaded
  ];
};

export default useLabourTypeDependency;
