import React from 'react';
import { jsx } from '@emotion/react';
import { ThemeProvider, Typography, TV, TW, Switch } from '@buildhero/sergeant';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const style = {
  row: {
    height: 28,
    marginTop: 4,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  formName: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: 8
  },
  closeButton: {
    width: 14,
    height: 14
  },
  requiredToggleContainer: {
    width: 125
  }
};

const FormLineItem = ({ option, selectedOptions, formsChange }) => (
  <ThemeProvider key={option.id}>
    <div css={style.row}>
      <div css={style.formName}>
        <div css={style.icon}>
          <IconButton
            size="small"
            aria-label="close"
            onClick={() => {
              const newItems = selectedOptions.filter(it => it.id !== option.id);
              formsChange(newItems);
            }}
          >
            <CloseIcon css={style.closeButton} />
          </IconButton>
        </div>

        <Typography variant={TV.BASE} weight={TW.REGULAR} css={style.label}>
          {option.label}
        </Typography>
      </div>
      <div css={style.requiredToggleContainer}>
        <Switch
          label="       Required" // leave start spaces, bug in label
          horizontalLabel
          checked={option.value.required}
          onChange={() => {
            const newItems = selectedOptions.map(it => {
              if (it.id === option.id) {
                return {
                  ...it,
                  value: {
                    ...it.value,
                    required: !it.value.required
                  }
                };
              }
              return it;
            });
            formsChange(newItems);
          }}
        />
      </div>
    </div>
  </ThemeProvider>
);

export default FormLineItem;
