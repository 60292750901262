import gql from 'graphql-tag';

const addTaxRate = gql`
  mutation addTaxRate($partitionKey: String!, $data: AddTaxRateInput!) {
    addTaxRate(partitionKey: $partitionKey, data: $data) {
      id
      name
      city
      county
      state
      taxRate
      accountType
      version
      lastUpdatedDateTime
    }
  }
`;

export default addTaxRate;
