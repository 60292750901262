import AmplifyService from 'services/AmplifyService';
import { logErrorWithCallback } from 'utils';

import { errorMessage } from './constants';
import {
  addMaintenanceTemplatesToServiceAgreementMutation,
  deleteMaintenanceTemplateMutation,
  getMaintenanceTemplatesQuery,
  updateMaintenanceTemplateMutation
} from './gql';
import { formatMaintenanceTemplates } from './helpers';

const flatten = o => (Array.isArray(o) ? o : o?.items);

const formatTags = tags =>
  tags.map(({ id, tagType, tagName, note, color, sortOrder, isActive }) => ({
    id,
    tagType,
    tagName,
    note,
    color,
    sortOrder,
    isActive
  }));

const formatTaskRequiredParts = parts =>
  parts.map(
    ({
      id,
      maintenanceTaskTemplateId,
      quantity,
      pricebookEntryId,
      priceBookEntryName,
      priceBookEntryDescription
    }) => ({
      id,
      maintenanceTaskTemplateId,
      quantity,
      pricebookEntryId,
      priceBookEntryName,
      priceBookEntryDescription
    })
  );

const formatMaintenanceTaskTemplates = templates =>
  templates.map(
    ({
      id,
      maintenanceTemplateId,
      asset,
      maintenanceTaskName,
      maintenanceTaskDescription,
      maintenanceTaskRequiredParts = []
    }) => ({
      id,
      maintenanceTemplateId,
      assetId: asset?.value || undefined, // react-select option
      maintenanceTaskName,
      maintenanceTaskDescription,
      maintenanceTaskRequiredParts: formatTaskRequiredParts(flatten(maintenanceTaskRequiredParts))
    })
  );

const formatMaintenanceTemplate = (
  {
    id,
    customerPropertyRepId,
    propertyId,
    schedule,
    firstDueDate,
    endDate,
    numberOfOccurrences,
    totalBudgetedHours,
    budgetedHours,
    numberOfTechs,
    estimatedVisits,
    serviceDescription,
    tags = [],
    maintenanceTaskTemplates = [],
    maintenanceTypeId,
    preferredTechnicians
  },
  maintenanceTemplatesV2
) => ({
  id,
  customerPropertyRepId,
  propertyId,
  schedule,
  firstDueDate,
  endDate,
  numberOfOccurrences,
  totalBudgetedHours,
  budgetedHours,
  numberOfTechs,
  estimatedVisits,
  serviceDescription,
  tags: formatTags(flatten(tags)),
  maintenanceTaskTemplates: maintenanceTemplatesV2
    ? maintenanceTaskTemplates
    : formatMaintenanceTaskTemplates(flatten(maintenanceTaskTemplates)),
  maintenanceTypeId,
  preferredTechnicians: preferredTechnicians
    ? {
        ...preferredTechnicians,
        additionalTechnicianIds: preferredTechnicians.additionalTechnicianIds || []
      }
    : undefined
});

export const getMaintenanceTemplates = async ({ user, snackbar, successCallback, id }) => {
  const { client } = AmplifyService.appSyncClient();

  try {
    const { tenantId } = user;

    const { data } = await client.query({
      query: getMaintenanceTemplatesQuery,
      variables: {
        partitionKey: tenantId,
        id
      }
    });
    const maintenanceTemplates = formatMaintenanceTemplates(
      data?.serviceAgreement?.maintenanceTemplates?.items || []
    );
    successCallback(maintenanceTemplates);
  } catch (error) {
    logErrorWithCallback(error, snackbar, errorMessage.getMaintenanceTemplate);
  }
};

export const saveMaintenanceTemplates = async ({
  snackbar,
  successCallback,
  serviceAgreementId,
  data: maintenanceTemplate,
  maintenanceTemplatesV2
}) => {
  const { client } = AmplifyService.appSyncClient();

  try {
    const payload = {
      serviceAgreementId,
      maintenanceTemplates: [formatMaintenanceTemplate(maintenanceTemplate, maintenanceTemplatesV2)]
    };
    const { data } = await client.mutate({
      mutation: addMaintenanceTemplatesToServiceAgreementMutation,
      variables: {
        data: payload
      }
    });
    const savedMaintenanceTemplate = data?.addMaintenanceTemplatesToServiceAgreement || {};
    snackbar('success', 'Successfuly saved maintenance templates.');
    successCallback(savedMaintenanceTemplate);
  } catch (error) {
    snackbar('error', 'Failed to create maintenance template.');
    logErrorWithCallback(error, snackbar, errorMessage.createMaintenanceTemplate);
  }
};

export const deleteMaintenanceTemplate = async ({ snackbar, successCallback, data: { id } }) => {
  const { client } = AmplifyService.appSyncClient();

  try {
    await client.mutate({
      mutation: deleteMaintenanceTemplateMutation,
      variables: {
        id
      }
    });
    successCallback();
  } catch (error) {
    snackbar('error', 'Failed to delete maintenance template.');
    logErrorWithCallback(error, snackbar, errorMessage.deleteMaintenanceTemplate);
  }
};

export const updateMaintenanceTemplate = async ({
  snackbar,
  successCallback,
  data: maintenanceTemplate,
  maintenanceTemplatesV2
}) => {
  const { client } = AmplifyService.appSyncClient();
  try {
    const variables = {
      data: formatMaintenanceTemplate(maintenanceTemplate, maintenanceTemplatesV2)
    };
    const { data } = await client.mutate({
      mutation: updateMaintenanceTemplateMutation,
      variables
    });
    const savedMaintenanceTemplate = data?.updateServiceAgreementTemplate || {};
    snackbar('success', 'Successfuly saved maintenance templates.');
    successCallback(savedMaintenanceTemplate);
  } catch (error) {
    snackbar('error', 'Failed to update maintenance template.');
    logErrorWithCallback(error, snackbar, errorMessage.updateMaintenanceTemplate);
  }
};
