import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import styles from '../style';

const ContinuationSheetTableRowForChangeOrderTitle = () => {
  return (
    <View>
      {/* title of the Change order row */}
      <View style={styles.displayFlex}>
        <Text style={[styles.text, styles.borderDoubleBottom, { width: '5%' }]} />
        <Text
          style={[
            styles.text,
            styles.borderDoubleBottom,
            styles.textCenter,
            { width: '27%', fontFamily: 'Times-Bold' }
          ]}
        >
          APPROVED CHANGE ORDERS
        </Text>
        <Text style={[styles.text, styles.borderDoubleBottom, { width: '12%' }]} />
        <Text style={[styles.text, styles.borderDoubleBottom, { width: '8%' }]} />
        <Text style={[styles.text, styles.borderDoubleBottom, { width: '8%' }]} />
        <Text style={[styles.text, styles.borderDoubleBottom, { width: '10%' }]} />
        <Text style={[styles.text, styles.borderDoubleBottom, { width: '10%' }]} />
        <Text style={[styles.text, styles.borderDoubleBottom, { width: '8%' }]} />
        <Text style={[styles.text, styles.borderDoubleBottom, { width: '12%', borderRight: 0 }]} />
      </View>
    </View>
  );
};

export default ContinuationSheetTableRowForChangeOrderTitle;
