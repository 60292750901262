import React, { Component } from 'react';

/*
 * HOC that abstracts the functionality required to have multiple <MUIForm /> components as children (of any level)
 * within the same React component.
 *
 * Wrap your component as follows:
 * `const WithForms = withMultipleForms(Component, ['myForm1', 'formName2']);
 *  export default WithForms;`
 *
 * Then the HOC will inject the following props into your component:
 * ----------------------------------------------------------------
 *
 * `getHandleCreateService` and `getHandleComplete`. These generator functions supply handles to each
 * MUIForm in your component, given its unique name or ID.
 * Example usage:
 * `<MUIForm onCreateService={getHandleCreateService('myForm1')} onComplete={getHandleComplete('myForm1')} ...otherProps />`
 *
 * `handleSubmitStart`. Call this function when you want to submit all forms in your component. For example,
 * you can call this function from the onClick callback of a submit button.
 *
 * `setOnSubmitFinal`. This function allows you to pass a callback upwards from your component to this wrapper,
 * 'against' the standard flow of data in React. Call this function once when your component mounts. For example:
 * `useEffect(() => {
 *    setOnSubmitFinal(async (allFormsData) => {
 *      await myResultingServerCall(allFormsData);
 *    })
 *  }, []);`
 * This function is called after all forms have successfully submitted (no validation errors), and it is passed
 * the combined data from all submitted forms. After this function completes, `isSubmitting` is set of false.
 * For this reason if your callback has asynchronous parts it is important to await those results; otherwise `isSubmitting` will
 * immediately be set to false, which could allow submission too frequently.
 *
 * `isSubmitting`. Boolean; true when the HOC is in the process of submitting any of the forms in your component, false otherwise.
 * Use this to disable the master button that submits all forms for a better user experience.
 *
 * See 'scenes/Jobs/DetailView/index.js' for an example.
 */
function withMultipleForms(WrappedComponent, formList) {
  if (!Array.isArray(formList) || formList.length === 0) {
    throw new Error(
      `Expected non-empty array of type ['formName1', 'formName2', ...] for \`formList\` argument to \`withMultipleForms\` HOC; 
       instead got ${JSON.stringify(formList)}`
    );
  }
  return class extends Component {
    constructor(props) {
      super(props);
      const emptyFormObject = formList?.reduce(
        (accumulator, currentValue) => ({ ...accumulator, [currentValue]: null }),
        {}
      );
      this.state = {
        formServiceList: emptyFormObject,
        isSubmitting: false,
        combinedFormData: {},
        combinedFormDataArr: []
      };

      this.setOnSubmitFinal = this.setOnSubmitFinal.bind(this);
      this.getFormService = this.getFormService.bind(this);
      this.getHandleCreateService = this.getHandleCreateService.bind(this);
      this.getHandleComplete = this.getHandleComplete.bind(this);
      this.handleSubmitStart = this.handleSubmitStart.bind(this);
      this.handleSubmitForm = this.handleSubmitForm.bind(this);
      this.areFormsInitialized = this.areFormsInitialized.bind(this);
    }

    setOnSubmitFinal(onSubmitFinal) {
      this.setState({ onSubmitFinal });
    }

    /**
     * 
     * @param {formKey} formName;
     * @returns Form service object of the relevant form. Can be use to trigger the validate form etc
     */
    getFormService(formName) {
      return this.state?.formServiceList?.[formName];
    }

    getHandleCreateService(formName) {
      return formService => {
        this.setState(prevState => ({
          formServiceList: {
            ...prevState.formServiceList,
            [formName]: formService
          }
        }));
      };
    }

    getHandleComplete(formName, formDataInArr) {
      return formData => {
        this.handleSubmitForm(formData, formName, formDataInArr);
      };
    }

    handleSubmitStart(formDataInArr) {
      const { formServiceList } = this.state;
      if (!formList || !this.areFormsInitialized()) return;
      // Clear previously stored data, then start "submit cascade"
      const stateToUpdate = formDataInArr ? 'combinedFormDataArr' : 'combinedFormData';
      this.setState({ [stateToUpdate]: [] }, () => {
        formServiceList[formList[0]].submit();
      });
    }

    // Aggregates form data from all forms in list and submits only when all forms submit successfully
    handleSubmitForm(formData, formName, formDataInArr) {
      const { formServiceList, onSubmitFinal, isSubmitting } = this.state;
      const stateToUpdate = formDataInArr ? 'combinedFormDataArr' : 'combinedFormData';

      if (!formList || !onSubmitFinal || isSubmitting) return;

      const nextFormIndex = formList.findIndex(formListItem => formListItem === formName) + 1;

      const submitNext = () => {
        // Momentarily set submitting to false so that if the next submission fails (e.g. validation error),
        // we don't get stuck with a disabled "submitting in process" button for the user.
        this.setState({ isSubmitting: false }, () => {
          // Submit next form in "cascade" - basically a loop where each iteration is another run through this callback function.
          const nextFormName = formList[nextFormIndex];
          formServiceList[nextFormName].submit();
        });
      };

      const submitFinal = async () => {
        // No next form - assume that means current form was the final one needed.
        // await onSubmitFinal(this.state.combinedFormData);
        await onSubmitFinal(this.state[stateToUpdate]);

        // NOTE: Clients of this HOC should make the onSubmitFinal function await all network requests rather than firing them
        // in the background. Otherwise, `isSubmitting` will be set to false too soon.
        this.setState({ isSubmitting: false });
      };

      const hasNextForm = nextFormIndex > 0 && nextFormIndex < formList.length;
      const nextSubmit = hasNextForm ? submitNext : submitFinal;

      // Inject data from current form submission. Only *after* state has been updated, call next form submit.
      const updatedStateObject = prevState =>
        formDataInArr
          ? [...prevState.combinedFormDataArr, formData]
          : { ...prevState.combinedFormData, ...formData };

      this.setState(
        prevState => ({
          isSubmitting: true,
          [stateToUpdate]: updatedStateObject(prevState)
        }),
        () => {
          nextSubmit();
        }
      );
    }

    areFormsInitialized() {
      return formList?.every(formName => Boolean(this.state.formServiceList[formName]));
    }

    render() {
      return (
        <WrappedComponent
          getHandleCreateService={this.getHandleCreateService}
          getFormService={this.getFormService}
          getHandleComplete={this.getHandleComplete}
          handleSubmitStart={this.handleSubmitStart}
          setOnSubmitFinal={this.setOnSubmitFinal}
          isSubmitting={this.state.isSubmitting}
          {...this.props}
        />
      );
    }
  };
}

export default withMultipleForms;
