import { VisitStatuses } from '@dispatch/Dispatch.constants';

export const shouldDisableScheduling = eventData => {
  if (!eventData) {
    return true;
  }

  if (!eventData.id || eventData.status === VisitStatuses.SCHEDULED.value.serverValue) {
    return false;
  }

  return true;
};

export const validateScheduling = values => {
  if (values?.from >= values?.to) {
    return {
      to: 'The end of the event must be after its start'
    };
  }
};

export const getFormValidation = () => ({
  type: 'object',
  properties: {
    eventName: {
      type: 'string'
    },
    from: {
      type: 'string'
    },
    to: {
      type: 'string'
    }
  },
  required: ['eventName', 'from', 'to']
});

export const getFormValidationErrors = () => ({
  eventName: {
    required: 'Event Name is required',
    type: 'Value must be a string'
  },
  from: {
    required: 'Date and time is required',
    type: 'Value must be a string'
  },
  to: {
    required: 'Date and time is required',
    type: 'Value must be a string'
  }
});
