import React from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PropTypes from 'prop-types';
import Labels from 'meta/labels';
import { cloneDeep, isEmpty } from 'lodash';
import { Box, Typography } from '@material-ui/core';
import { snackbarOn } from 'redux/actions/globalActions';
import { connect, useSelector } from 'react-redux';
import minMaxValidation from 'utils/minMaxValidation';
import { PricingStrategy } from 'utils/constants';
import { convertForMathLib } from 'utils/mathLibrary';
import { calculateMarginFromMarkup } from '@buildhero/math';
import { ERROR_MESSAGES } from './constants';
import RangeInput from './RangeInput';

const LabelInput = props => {
  return (
    <Typography className={`${props.className}`} variant="body2">
      {props.value || '-'}
    </Typography>
  );
};

const MaterialRateRangeInput = props => {
  const {
    user,
    classes,
    isViewMode,
    rangeData,
    minRangeLabel,
    maxRangeLabel,
    startRangeKey,
    endRangeKey,
    markupKey,
    setRangeData,
    marginSetting,
    isPricebookConfig
  } = props;
  const pricingStrategy = useSelector(s => s.settings.pricingStrategy);
  const isMarginEnabled =
    pricingStrategy === PricingStrategy.MARGIN ||
    pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN;
  const isMarkupEnabled =
    pricingStrategy === PricingStrategy.MARKUP ||
    pricingStrategy === PricingStrategy.MARKUP_AND_MARGIN;

  const addNewRange = () => {
    const newData = cloneDeep(rangeData);
    newData.push({ start: 0, end: 0, rate: 0 });
    setRangeData(newData);
  };

  const findDuplicatesRange = (list, item) => {
    const filteredItem = list.filter(range => {
      return (
        range[startRangeKey] === item[startRangeKey] && range[endRangeKey] && item[endRangeKey]
      );
    });

    return filteredItem;
  };

  const setNextInitials = index => {
    const newData = cloneDeep(rangeData);
    const lastInputedRange = newData[index];
    newData[index + 1][startRangeKey] = Number(lastInputedRange[endRangeKey]) + 0.01;
    setRangeData(newData);
  };

  const validateValues = (key, event, index) => {
    const { value } = event.target;
    const newData = cloneDeep(rangeData);
    const rangeItem = newData[index];
    rangeItem[key] = value;

    if (rangeItem[key] < 0) return null;

    if (
      [startRangeKey, endRangeKey].indexOf(key) > -1 &&
      !isEmpty(rangeItem[startRangeKey]) &&
      !isEmpty(rangeItem[endRangeKey])
    ) {
      const { isValid } = minMaxValidation({
        minQuantity: rangeItem[startRangeKey],
        maxQuantity: rangeItem[endRangeKey]
      });

      if (!isValid) {
        return props.snackbarOn('error', ERROR_MESSAGES[0]);
      }

      const duplicatesRange = findDuplicatesRange(newData, rangeItem);

      if (duplicatesRange.length > 1) {
        return props.snackbarOn('error', ERROR_MESSAGES[1]);
      }
    }

    if (key === endRangeKey) {
      setNextInitials(index);
    }

    return { isValid: true };
  };

  const handleOnChange = (key, event, index) => {
    const newData = cloneDeep(rangeData);
    newData[index][key] = Number(event.target.value);

    return setRangeData(newData);
  };

  return (
    <Box display="flex" pr={4} flexDirection="column">
      <Box component="div" className={classes.dFlex}>
        <Typography className={classes.auditText} variant="body2">
          {Labels.range[user.locale].toUpperCase()}
        </Typography>
        {isMarkupEnabled && !marginSetting && (
          <Typography className={`${classes.auditText} ${classes.ml4}`} variant="body2">
            {Labels.rangeMarkup[user.locale].toUpperCase()}
          </Typography>
        )}
        {((isMarginEnabled && !isPricebookConfig) || marginSetting) && (
          <Typography
            className={`${classes.auditText} ${
              isMarkupEnabled && !marginSetting ? classes.ml5 : classes.ml4
            }`}
            variant="body2"
          >
            {Labels.rangeMargin[user.locale].toUpperCase()}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection="column">
        {rangeData.map((range, index) => {
          const startValue = range[startRangeKey];
          const endValue = range[endRangeKey];
          const markUpValue = range[markupKey];

          return (
            <Box key={`Range${range.id || index}`} alignItems="center" display="flex">
              {isViewMode || index === 0 ? (
                <LabelInput
                  className={`${classes.noEditableValue} ${classes.width150}`}
                  value={index === 0 ? minRangeLabel : `$${startValue}` || '-'}
                />
              ) : (
                <RangeInput
                  index={index}
                  value={startValue === 0 ? '' : startValue}
                  type="number"
                  handleOnChange={handleOnChange}
                  validateValues={validateValues}
                  keyName={startRangeKey}
                  inputClassName={`${classes.input} ${classes.width150}`}
                  adornmentClassName={classes.inputIcon}
                  addAdornment
                />
              )}
              <Typography variant="body2">-</Typography>

              {isViewMode || index === rangeData.length - 1 ? (
                <LabelInput
                  className={`${classes.noEditableValue} ${classes.width150}`}
                  value={
                    index === rangeData.length - 1
                      ? maxRangeLabel
                      : `$${(+endValue).toFixed(2)}` || '-'
                  }
                />
              ) : (
                <RangeInput
                  index={index}
                  value={endValue === 0 ? '' : endValue}
                  type="number"
                  handleOnChange={handleOnChange}
                  validateValues={validateValues}
                  keyName={endRangeKey}
                  inputClassName={`${classes.input} ${classes.width150}`}
                  adornmentClassName={classes.inputIcon}
                  addAdornment
                />
              )}

              {isMarkupEnabled &&
                !marginSetting &&
                (isViewMode ? (
                  <LabelInput
                    className={`${classes.noEditableValue} ${classes.width100}`}
                    value={markUpValue}
                  />
                ) : (
                  <RangeInput
                    index={index}
                    value={markUpValue === 0 ? '' : markUpValue}
                    type="number"
                    handleOnChange={handleOnChange}
                    validateValues={validateValues}
                    keyName={markupKey}
                    inputClassName={`${classes.markupInput}`}
                    addAdornment={false}
                  />
                ))}
              {isMarkupEnabled && !marginSetting && (
                <Typography variant="body2" className={classes.markupLabel}>
                  %
                </Typography>
              )}
              {((isMarginEnabled && !isPricebookConfig) || marginSetting) &&
                (isViewMode ? (
                  <LabelInput
                    className={`${classes.noEditableValue} ${classes.width100}`}
                    value={convertForMathLib(calculateMarginFromMarkup, markUpValue)}
                  />
                ) : (
                  <RangeInput
                    index={index}
                    value={markUpValue === 0 ? '' : markUpValue}
                    type="number"
                    handleOnChange={handleOnChange}
                    validateValues={validateValues}
                    keyName={markupKey}
                    inputClassName={`${classes.markupInput}`}
                    addAdornment={false}
                  />
                ))}
              {((isMarginEnabled && !isPricebookConfig) || marginSetting) && (
                <Typography variant="body2" className={classes.markupLabel}>
                  %
                </Typography>
              )}
            </Box>
          );
        })}
      </Box>
      {!isViewMode && (
        <Typography
          className={`${classes.wrapIcon} ${classes.mt2} ${classes.handIcon}`}
          variant="body2"
          onClick={addNewRange}
        >
          <AddCircleOutlineIcon className={classes.mr2} />
          {Labels.addNewRange[user.locale]}
        </Typography>
      )}
    </Box>
  );
};

MaterialRateRangeInput.propTypes = {
  user: PropTypes.object.isRequired,
  isViewMode: PropTypes.bool.isRequired,
  startRangeKey: PropTypes.string,
  endRangeKey: PropTypes.string,
  markupKey: PropTypes.string,
  minRangeLabel: PropTypes.string,
  maxRangeLabel: PropTypes.string,
  rangeData: PropTypes.array,
  setRangeData: PropTypes.func,
  classes: PropTypes.object,
  marginSetting: PropTypes.bool,
  isPricebookConfig: PropTypes.bool
};

MaterialRateRangeInput.defaultProps = {
  rangeData: [],
  startRangeKey: '',
  endRangeKey: '',
  markupKey: '',
  classes: {},
  minRangeLabel: '',
  maxRangeLabel: '',
  marginSetting: false,
  setRangeData: () => {},
  isPricebookConfig: false
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

export default connect(mapStateToProps, mapDispatcherToProps)(MaterialRateRangeInput);
