import { createStore } from 'redux';
import Reducer from './reducers';
import initialState from './state';
import ENV from '../configs/env';

const store = createStore(
  Reducer,
  initialState,
  // Explicitly un-set enhancer param in production envs; Redux's `createStore`
  // currently crashes even when the non-function value passed for this arg is falsy.
  ['production', 'prod', 'live'].includes(ENV?.toLowerCase())
    ? undefined
    : window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const { dispatch, getState } = store;

export default store;
