import React, { Component } from 'react';
import _ from 'lodash';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Hits,
  connectStateResults,
  connectSearchBox,
  connectCurrentRefinements,
  Configure
} from 'react-instantsearch-dom';
import { CommonService } from 'services/core';
// import CloseIcon from '@material-ui/icons/Close';
import {
  Popper,
  // ClickAwayListener,
  Fade,
  Paper,
  TextField,
  List,
  // IconButton,
  InputAdornment
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import configForEnvironment from 'configs/aws-exports';
import ENV from 'configs/env';
import { Logger } from 'services/Logger';
import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';

import Hit from './Hit';
import styles from './styles';
import './style.css';

class AlgoliaSearchDropdown extends Component {
  // eslint-disable-next-line no-confusing-arrow

  constructor(props) {
    super(props);
    this.CommonService = new CommonService();
    this.state = {
      searchKey: '',
      searchClient: ''
    };
    this.isEdited = false;
    this.currentRefinement = '';
    this.inputRef = React.createRef();
  }

  componentDidMount = async () => {
    try {
      const { data } = await this.CommonService.getAlgoliaSecuredKey();
      const searchKey = data.getAlgoliaSecuredKey;
      const searchClient = algoliasearch(configForEnvironment(ENV).algoliaAppKey, searchKey);
      this.searchClient = searchClient;
      this.setState({ searchKey: searchKey, searchClient: searchClient });
    } catch (error) {
      Logger.error(error);
    }
  };

  clearRefinementsComponent = ({ items, refine }) => {
    return null;
    //   return (
    //   <IconButton
    //     style={{ backgroundColor: 'transparent' }}
    //     aria-label="Close"
    //     onClick={() => {
    //       this.props.form.setFieldValue(this.props.field.name, '', false);
    //       return refine(items);
    //     }}
    //   >
    //     <CloseIcon
    //       style={{ color: '#3f3f3f', fontSize: 20 }}
    //       disabled={!items || (items && !items.length)}
    //     />
    //   </IconButton>
    // );
  };

  HitComp = props => (
    <Hit
      formProps={this.props}
      isFieldEdited={() => {
        if (this.isEdited) {
          this.isEdited = false;
        }

        return this.isFieldEdited;
      }}
      {...props}
    />
  );

  searchResults = props => {
    const { searchState, searchResults } = props;
    const { HitComp } = this;
    const { specialbehaviour } = this.props;
    const { warning } = specialbehaviour;

    return searchState && searchState.query ? (
      <Popper
        id="searchResults"
        open={searchState !== ''}
        disablePortal
        anchorEl={() => this.inputRef.current}
        placement="bottom-start"
        transition
        style={{ zIndex: 5, width: 350 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            {/* <ClickAwayListener onClickAway={this.handleClickAway}> */}
            <Paper
              style={{
                backgroundColor: '#fafafa',
                width: '100%',
                maxHeight: 240,
                // width: window.innerWidth < 600 ? '100%' : window.innerWidth * 0.64,
                // maxHeight: window.innerHeight * 0.7,
                overflowY: 'auto',
                marginTop: -20
              }}
            >
              <List component="nav">
                <Hits hitComponent={HitComp} />
                {searchResults && searchResults.nbHits === 0 && (
                  <p style={{ paddingLeft: 5 }}>{warning ? warning : 'No option'}</p>
                )}
              </List>
            </Paper>
            {/* </ClickAwayListener> */}
          </Fade>
        )}
      </Popper>
    ) : null;
  };

  SearchBox = props => {
    const { currentRefinement, refine } = props;
    const { field, label, form } = this.props;
    const { errors } = form;
    const { value } = field;
    this.currentRefinement = currentRefinement;
    const valueToDisplay = this.isEdited ? currentRefinement : value;
    const ClearRefinementsComponent = connectCurrentRefinements(this.clearRefinementsComponent);
    const hasError = errors && errors[field.name] && errors[field.name] !== '';
    return (
      <TextField
        id={this.props.field.name}
        label={label}
        fullWidth
        variant="filled"
        autoComplete="off"
        error={hasError}
        helperText={errors[field.name] || ''}
        className={hasError ? null : this.props.classes.inputRoot}
        value={valueToDisplay}
        disabled={this.props.isDisabled}
        onChange={event => {
          refine(event.currentTarget.value);
          if (!this.isEdited) {
            this.isEdited = true;
          }
        }}
        onBlur={() =>
          setTimeout(() => {
            if (value !== this.currentRefinement && this.isEdited) {
              this.isEdited = false;
              form.setFieldValue(field.name, this.currentRefinement, false);
            }
          }, 1000)
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ClearRefinementsComponent clearsQuery />
            </InputAdornment>
          )
        }}
      />
    );
  };

  // handleClickAway = () => {};

  render() {
    const { searchClient, searchKey } = this.state;
    const { specialbehaviour, form } = this.props;
    const { searchIndex, filterString } = specialbehaviour;
    const CustomSearchBox = connectSearchBox(this.SearchBox);
    const CustomResults = connectStateResults(this.searchResults);
    const filterCriteria = form.values[filterString];
    let filterStr = '';
    const filterStrArr = [];
    if (filterCriteria && filterCriteria.entityType) {
      filterStrArr.push(`entityType:${filterCriteria.entityType}`);
    }
    // if (filterCriteria && filterCriteria.priceBookId) {
    //   filterStrArr.push(`priceBookId:${filterCriteria.priceBookId}`);
    // }

    if (!_.isEmpty(filterStrArr) && filterStrArr.length === 1) {
      filterStr = filterStrArr.join(' AND ');
    }
    if (!searchKey || !searchClient) {
      return <p>Loading...</p>;
    }
    return (
      <InstantSearch
        indexName={searchIndex ? searchIndex : defaultSearchIndex}
        searchClient={this.searchClient}
      >
        <CustomSearchBox />
        <div id="alogliaSearchResultsFormik" ref={this.inputRef} />
        <CustomResults />
        {filterStr !== ' AND ' && <Configure filters={filterStr} />}
      </InstantSearch>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AlgoliaSearchDropdown);
