export const saveReviewSummary = async (context, event) => {
  const { services, user, id } = context;
  const { reviewReportService } = services;
  const { saveData } = event;

  const { summary } = saveData;

  const mutateData = {
    reviewReportId: id,
    summaries: [{ summary }]
  };
  const data = await reviewReportService.addSummariesToReviewReport(user.tenantId, mutateData);
  const { addSummariesToReviewReport } = data.data;
  const { summaryNotes } = context;

  if (addSummariesToReviewReport) summaryNotes.unshift(addSummariesToReviewReport[0]);

  return { summaryNotes, modalRecord: {}, modalIndex: '' };
};

export const editReviewSummary = async (context, event) => {
  const { services, user } = context;
  const { reviewReportService } = services;
  const { saveData } = event;

  const formattedData = {};

  formattedData.id = saveData.id;
  formattedData.version = saveData.version;
  formattedData.summary = saveData.summary;

  const queryData = await reviewReportService.updateSummary(user.tenantId, formattedData);
  const { updateSummary } = queryData.data;
  const { summaryNotes } = context;

  const modifiedSummaryNotes = summaryNotes.map(summary => {
    let localSummary = summary;
    if (summary.id === updateSummary.id) {
      localSummary = updateSummary;
    }
    return localSummary;
  });

  return { summaryNotes: modifiedSummaryNotes, modalRecord: {}, modalIndex: '' };
};

export const deleteReviewSummary = async context => {
  const { services, user, modalRecord } = context;
  const { reviewReportService } = services;
  const queryData = await reviewReportService.deleteSummary(user.tenantId, modalRecord.id);

  // eslint-disable-next-line no-unused-vars
  const { summaryNotes } = context;
  const { data } = queryData;
  if (data?.deleteSummary) {
    const notesIndex = summaryNotes.findIndex(reviewSummary => reviewSummary.id === modalRecord.id);
    summaryNotes.splice(notesIndex, 1);
  }

  return { summaryNotes, modalRecord: {}, modalIndex: '' };
};

/* Code to include a Summary Note item to invoice from Review Report */

export const includeReviewSummary = async (context, event) => {
  const { services, user, modalRecord, modalChecked } = context;
  const { reviewReportService } = services;

  const formattedData = {};

  formattedData.id = modalRecord.id;
  formattedData.version = modalRecord.version;
  formattedData.includeInInvoice = modalChecked;
  formattedData.summary = modalRecord.summary;

  const queryData = await reviewReportService.updateSummary(user.tenantId, formattedData);
  const { updateSummary } = queryData.data;
  const { summaryNotes } = context;

  const modifiedSummaryNotes = summaryNotes.map(summary => {
    let localSummary = summary;
    if (summary.id === updateSummary.id) {
      localSummary = updateSummary;
    }
    return localSummary;
  });

  return { summaryNotes: modifiedSummaryNotes, modalRecord: {}, modalIndex: '' };
};
