import { bundleIndex, searchIndex } from 'constants/algoliaIndex';

const fieldValues = (settings, assetsList, taskGroupAndSet, isAssetEnabled) => ({
  options: {
    pageSize: 'LETTER',
    grow: 0
  },
  contents: [
    // DND Component
    {
      options: {
        size: 1,
        direction: 'row',
        grow: 0
      },
      contents: [
        {
          options: {
            direction: 'column',
            grow: 1,
            padding: 2
          },
          contents: [
            {
              component: {
                edit: 'TextInput'
              },
              source: 'description',
              options: {
                onChange: settings.onTextChange,
                defaultValue: settings.item.description,
                label: 'Task Description',
                placeholder: 'Enter task description'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            grow: 1,
            padding: 2,
            marginLeft: '10px'
          },
          contents: isAssetEnabled
            ? [
                {
                  component: {
                    edit: 'AssetSelect'
                  },
                  source: 'assetName',
                  options: {
                    label: 'Assets',
                    taskGroupAndSet: [taskGroupAndSet],
                    updateService: settings.handleTaskChange,
                    updateServiceFields: [settings.taskChangeActions],
                    placeholder: 'Search to add asset',
                    taskData: [settings.item],
                    valueSet: assetsList,
                    isRequired: true
                  }
                }
              ]
            : []
        }
      ]
    },
    // Department Select and Bill To
    {
      options: {
        size: 3,
        direction: 'row',
        grow: 0
      },
      contents: [
        {
          options: {
            direction: 'column',
            grow: 1,
            padding: 2
          },
          contents: [
            {
              options: {
                label: 'Parts',
                taskGroupAndSet: [taskGroupAndSet],
                taskData: [settings.item],
                jobData: [settings.jobData],
                required: true,
                placeholder: 'Search to add parts',
                searchIndexName: searchIndex,
                searchParams: [
                  {
                    label: 'Parts',
                    name: 'selectProduct',
                    required: true,
                    placeholder: 'Search to add parts',
                    searchIndex: bundleIndex,
                    displayText: ['productName', 'productCode'],
                    delimiter: ' - ',
                    filters: [
                      {
                        attribute: 'entityType',
                        valueArray: ['PriceBookEntry']
                      },
                      {
                        attribute: 'priceBookId',
                        valueArray: [settings.priceBookId]
                      }
                    ]
                  }
                ]
              },
              component: 'PartsSelect',
              source: 'name'
            }
          ]
        }
      ]
    }
  ]
});

const createTasksLayout = (settings, assetsList, taskGroupAndSet, isAssetEnabled) => ({
  fields: {
    searchEntity: {
      edit: 'searchEntity'
    },
    tasks: {
      edit: 'tasks'
    },
    departmentId: {
      edit: 'departmentId'
    }
  },
  layouts: {
    default: fieldValues(settings, assetsList, taskGroupAndSet, isAssetEnabled),
    edit: fieldValues(settings, assetsList, taskGroupAndSet, isAssetEnabled)
  },
  validation: {
    type: 'object',
    properties: {
      searchEntity: {
        type: 'string'
      },
      billTo: {
        type: 'string'
      }
    },
    required: ['searchEntity', 'billTo']
  },
  validationErrors: {
    searchEntity: {
      required: 'Field is required.'
    },
    billTo: {
      required: 'Field is required.'
    }
  }
});

export default createTasksLayout;

export const taskListMeta = [
  {
    id: 'name',
    filterKey: 'name',
    filterType: 'string',
    label: 'Task'
  },
  {
    id: 'description',
    filterKey: 'description',
    filterType: 'string',
    disablePadding: true,
    label: 'Description'
  },
  {
    id: 'status',
    filterKey: 'status',
    fitlerType: 'string',
    numeric: false,
    type: 'text',
    label: 'Status'
  },
  {
    id: 'createdDateTime',
    type: 'datetime',
    label: 'Date added'
  },
  {
    id: 'createdBy',
    filterKey: 'createdBy',
    filterType: 'number',
    label: 'Created By'
  }
];
