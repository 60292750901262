import Labels from 'meta/labels';
import { isObject } from 'utils';

import { getFilterType } from './tableUtils';

export const getFilterParamFromMetadata = (item, user) => {
  if (item.disableFilter) {
    return null;
  }
  let showDateField;
  let showDateStringField;
  let showMultiSelectField;
  let showSelect;
  let showTextField;
  const { options } = item;

  if (
    (item.type && item.type.includes('date')) ||
    (item.filterType && typeof item.filterType === 'string' && item.filterType.includes('date'))
  ) {
    showDateField = true;
  } else if (item?.filterType === 'dateString') {
    showDateStringField = true;
  } else if (
    item.filterType === 'multi-select' ||
    (isObject(item.filterType) && item.filterType.uiType === 'multi-select')
  ) {
    showMultiSelectField = true;
  } else if (item.filterType === 'select') {
    showSelect = true;
  } else if (item.filterType === 'boolean' && options) {
    showSelect = true;
  } else if (item.type !== 'boolean') {
    showTextField = true;
  }
  const filterKey = item.filterKey || item.id;
  const filterLabel = (Labels[item.label] || {})[user?.locale] || item.label;
  const { conditionOptions, filterType } = getFilterType(item.filterType || item.type);
  const { convertToSubQuery, subQueryCondition } = item;
  return {
    showDateField,
    showDateStringField,
    filterKey,
    filterLabel,
    filterType,
    showTextField,
    showMultiSelectField,
    showSelect,
    conditionOptions,
    convertToSubQuery,
    subQueryCondition
  };
};

export const getDefaultValueAndCondition = (filterParams, filterConditions) => {
  const { filterKey, showDateField, showMultiSelectField } = filterParams;
  const defaultCondition = filterConditions?.[filterKey]?.condition;
  const defaultLabel = filterConditions?.[filterKey]?.label;
  const defaultId = filterConditions?.[filterKey]?.id;

  let defaultValue;
  let incomingValue = null;
  if (filterConditions && filterConditions[filterKey]) {
    if (filterConditions[filterKey].type === 'integerFilters') {
      incomingValue =
        !showDateField && filterConditions[filterKey].value > -1
          ? filterConditions[filterKey].value
          : null;
      if (showDateField) {
        incomingValue = filterConditions[filterKey].value;
      }
    } else if (
      Array.isArray(filterConditions?.[filterKey]?.labels) &&
      Array.isArray(filterConditions?.[filterKey]?.value)
    ) {
      incomingValue = filterConditions?.[filterKey]?.labels?.map((label, index) => ({
        label,
        value: filterConditions?.[filterKey]?.value[index]
      }));
    } else if (filterConditions?.[filterKey]?.value !== undefined) {
      incomingValue = filterConditions?.[filterKey]?.value;
    } else {
      incomingValue = null;
    }
  }

  if (showMultiSelectField && !Array.isArray(incomingValue)) {
    defaultValue = [];
  } else {
    // input required empty string, where as date picker needs null to default to today
    defaultValue = incomingValue !== null ? incomingValue : '';
  }
  const { options } = filterParams;
  const multiSelectOptions =
    options?.length && typeof options[0] === 'object'
      ? options
      : options?.map(option => ({
          label: option,
          value: option
        }));
  if (defaultCondition === 'empty') defaultValue = 'EMPTY';
  else if (defaultCondition === 'notEmpty') defaultValue = 'NOT EMPTY';

  return { defaultValue, defaultCondition, multiSelectOptions, defaultLabel, defaultId };
};

export const getAvailableFilterKeys = (metadataWithFilterParam, currentFilter) => {
  const filteredKeys = currentFilter.map(col => col.filterKey);
  return metadataWithFilterParam
    .filter(item => filteredKeys.indexOf(item.filterKey) < 0 && !item?.hide)
    .map(item => {
      return { value: item.filterKey, label: item.filterLabel };
    });
};

export const getCurrentFilterFromSavedState = (savedFilter, metadataWithFilterParam) => {
  const newCurrentFilter = [];
  const appliedfilters = Object.keys(savedFilter);
  if (appliedfilters.length) {
    appliedfilters.forEach(key => {
      const filterMeta = metadataWithFilterParam.find(item => item.filterKey === key);
      if (filterMeta && !isObject(filterMeta?.subQueryCondition?.fieldComparator)) {
        newCurrentFilter.push(filterMeta);
      }
    });
  }
  if (!newCurrentFilter.length && metadataWithFilterParam.length) {
    // if nothing then set first available filter condition
    newCurrentFilter.push(metadataWithFilterParam[0]);
  }
  return newCurrentFilter;
};
