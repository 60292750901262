/* eslint-disable no-param-reassign */
import axios from 'axios';

import ENV from 'configs/env';
import StorageService from 'services/StorageService';
import { convertToCurrencyString, logErrorWithCallback } from 'utils';
import { QuoteStatus } from 'utils/AppConstants';

import { pdfCSS } from './CKEditor.styles';

const getURL = () => {
  const API_URL_LOCAL = 'https://exportpdf.dev.buildops.com/v1/convert';
  const API_URL_DEV = 'https://exportpdf.dev.buildops.com/v1/convert';
  const API_URL_QA = 'https://exportpdf.qa.buildops.com/v1/convert';
  const API_URL_STAGE = 'https://exportpdf.staging.buildops.com/v1/convert';
  const API_URL_LIVE = 'https://exportpdf.live.buildops.com/v1/convert';

  switch (ENV) {
    case 'local':
      return API_URL_LOCAL;

    case 'dev':
      return API_URL_DEV;

    case 'qa':
      return API_URL_QA;

    case 'uat':
      return API_URL_LOCAL;

    case 'tra':
      return API_URL_LOCAL;

    case 'prod':
      return API_URL_LIVE;

    case 'staging':
      return API_URL_STAGE;

    default:
      return API_URL_LOCAL;
  }
};

export const generatePDF = ({
  data,
  fileName,
  user,
  updateQuoteMutation,
  status,
  snackbar,
  generatedPDFSizeRef
}) => {
  return axios({
    method: 'post',
    url: getURL(),
    data: {
      html: `<link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet">
      <div class="ck-content">${data}</div>`,
      css: pdfCSS,
      options: {
        format: 'Letter',
        margin_top: '12mm',
        margin_bottom: '12mm',
        margin_left: '19mm',
        margin_right: '19mm'
      }
    },
    responseType: 'blob'
  })
    .then(async response => {
      const file = new Blob([response.data], {
        type: 'application/pdf'
      });
      generatedPDFSizeRef.current = file.size;

      const storageService = new StorageService();
      const s3Response = await storageService.uploadFile(
        file,
        `${user.tenantId}/${fileName}`,
        e => e,
        'application/pdf'
      );
      const url = await storageService.getFile(s3Response);

      // Do not try to update quotes with this status
      const quoteStatus = [
        QuoteStatus.APPROVED,
        QuoteStatus.REJECTED,
        QuoteStatus.SENT_TO_CUSTOMER,
        QuoteStatus.CUSTOMER_VIEWED
      ];
      if (url && !quoteStatus.includes(status)) {
        await updateQuoteMutation(url);
      }
      return s3Response;
    })
    .catch(error => {
      logErrorWithCallback(
        error,
        snackbar,
        'Error occurred while generating PDF. Please try again.'
      );
    });
};

export function convertToCurrencyStringCheck(amt) {
  if (!amt) return convertToCurrencyString('0');
  return convertToCurrencyString(amt);
}

export function convertNewLines(text) {
  if (!text) return '';
  return text.replace(/\n/g, '<br>');
}

export function boldText(text) {
  if (!text) return '';
  return `<strong>${text}</strong>`;
}

export function addDataSmartfield(smartfield, content) {
  return `<meta data-smartfield=${smartfield}>${content || '&nbsp;'}`;
}

// Looks for parsed smartfields in the html String and converts them back into smartfields
// Example - <p><meta data-smartfield="CustomerName">Walmart</p> ---->  <p>[[CustomerName]]</p>
export function reinsertSmartfields(htmlStr, companyQuoteTemplate) {
  /*  metaPattern is searching for <meta> element which contains a data-smartfield attribute and either its sibling <span> element (if the smartfield has been added within a table cell) or the sibling text of the meta element
   -data-smartfield tag is saved within the meta element because adding it to the span prompted ckeditor to generate a surrounding <p> and mess up the UI
  Example)
   original: <meta data-smartfield="QuoteCreationDate" bold="true" fontsize="small"><span class="text-small"><strong>Jul 21, 2021</strong></span>
   converted: <span class="text-small"><strong>[[QuoteCreationDate]]</strong></span>
   original: <p><meta data-smartfield="PropertyAddress">New York, NY, USA, New York, NY, 11</p>
   converted: <p>[[PropertyAddress]]</p>
  Regex breakdown:
  <meta.+? matches the characters <meta and any additional characters, as few times as possible, (so other <meta is not consumed)
  Positive Lookahead (?=data-smartfield.+?"(.+?(?=\\|"))) matches data-smartfield until the first " (i.e. data-smartfield="CompanyName")
  1st Capturing Group (.+?(?=\\|")) CAPTURES THE DATA-SMARTFIELD VALUE (i.e. CompanyName - used to replace converted content back to [[DATA SMARTFIELD VALUE]])
  Positive Lookahead (?=\\|") captures the data smartfield value until the second " or \ (in case " had escape character \)
  .+?> looking for end of meta element
  2nd Capturing Group (<span.+?>(?!<)|.*?) - used in replace method so that the sibling <span ...> and its classes and any other non-structural elements auto generated by ckeditor (like <strong>) will precede the [[SMARTFIELD]] to maintain UI consistency.
    - Negative Lookahead (?!<) ensures that only one <span is matched and not others in the html str
    - |.*? - if no span then match with sibling text of meta tag
  Positive Lookahead (?=<\/.+>) end match when first closing tag reached */
  const metaPattern = /<meta.+?(?=data-smartfield.+?"(.+?(?=\\|"))).+?>(<span.+?>(?!<)|.*?).+?(?=<\/.+>)/g;

  /*  figurePattern is searching for <figure> elements which contain a data-smartfield attribute.  This applies to smartfields displayed as tables (like tasks) as CkEditor automatically generates a surrounding <figure> tag
  Example)
   original: <figure class=\"table\" style=\"width:95%;\" data-smartfield=\"Tasks\"><table><tbody><tr><td style=\"border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;width:65%;\" colspan=\"3\"><strong>1. Task One</strong></td><td style=\"border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;\" colspan=\"2\"><p style=\"text-align:right;\">$0.00</p></td></tr><tr><td style=\"border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;\" colspan=\"5\">&nbsp;</td></tr><tr><td style=\"border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;\" colspan=\"5\">&nbsp;</td></tr></tbody></table></figure>
   converted: [[Tasks]]
  Regex breakdown:
  Positive Lookahead (?=(?:(?!>).)*data-smartfield.+?"(.+?(?=\\|")))
  Negative Lookahead (?!>) positive lookahead for data-smartfield tag only matches if found within the figure's closing >.  this prevents data-smartfields that appear as children of the figure from being matched
  1st Capturing Group (.+?(?=\\|")) THIS CAPTURES THE DATA-SMARTFIELD VALUE (i.e. Task - used to replace converted content back to [[DATA SMARTFIELD VALUE]])
  .+?<\/figure> - matches all data until and including the closing </figure> tag */
  const figurePattern = /<figure(?=(?:(?!>).)*data-smartfield.+?"(.+?(?=\\|"))).+?<\/figure>/g;

  // This includes styling from the company quote template on figure elements (i.e. tasks, totals table) so the styles are maintained when smartfields are refreshed
  function replaceWithTemplateStyles(match, p1) {
    const pat = new RegExp(`<p(?=(?:(?!</p).)*\\[\\[(?=${p1}\\]\\])).+?</p>`);
    const smartFieldMatch = companyQuoteTemplate?.template.match(pat);
    if (!smartFieldMatch) return `[[${p1}]]`;
    const stylePat = new RegExp('style="(.+?(?="))');
    const stylesPresent = stylePat.test(smartFieldMatch[0]);
    if (stylesPresent) {
      return smartFieldMatch[0];
    }
    return `[[${p1}]]`;
  }
  const htmlStrWithSmartfields = htmlStr
    .replace(metaPattern, '$2[[$1]]')
    .replace(figurePattern, replaceWithTemplateStyles);

  return htmlStrWithSmartfields;
}

// CKEditor does not allow adding attributes on elements by default.  They must be specifically added using the the editor.model.schema property
export function addAttributesToElement({ editor, element, options = {} }) {
  editor.model.schema.register(element, options);
  editor.model.schema.addAttributeCheck(context => {
    if (context.endsWith(element)) {
      return true;
    }
  });

  editor.conversion.for('upcast').elementToElement({
    model: (viewElement, { writer: modelWriter }) => {
      return modelWriter.createElement(element, viewElement.getAttributes());
    },
    view: element
  });

  editor.conversion.for('downcast').elementToElement({
    model: element,
    view: element
  });

  editor.conversion.for('downcast').add(dispatcher => {
    dispatcher.on('attribute', (evt, data, conversionApi) => {
      if (data.item.name !== element) {
        return;
      }

      const viewWriter = conversionApi.writer;
      const viewSpan = conversionApi.mapper.toViewElement(data.item);

      if (data.attributeNewValue) {
        viewWriter.setAttribute(data.attributeKey, data.attributeNewValue, viewSpan);
      } else {
        viewWriter.removeAttribute(data.attributeKey, viewSpan);
      }
    });
  });
}

export function extendAttributesToElement({ editor, element, attributes }) {
  editor.model.schema.extend(element, {
    allowAttributes: attributes
  });
  attributes.forEach(attribute => {
    editor.conversion.for('upcast').attributeToAttribute({
      model: {
        name: element,
        key: attribute
      },
      view: attribute
    });

    editor.conversion.for('downcast').add(dispatcher => {
      dispatcher.on(`attribute:${attribute}:${element}`, (evt, data, conversionApi) => {
        const viewElement = conversionApi.mapper.toViewElement(data.item);

        conversionApi.writer.setAttribute(attribute, data.attributeNewValue, viewElement);
      });
    });
  });
}
