import gql from 'graphql-tag';

const getVisitVersion = gql`
  query getVisit($partitionKey: String!, $sortKey: String!) {
    getVisit(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      version
      status
    }
  }
`;

export default getVisitVersion;
