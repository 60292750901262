import theme from 'themes/BuildHeroTheme';

export const payrollLaborType = ({ isIntegrationEnabled }) => ({
  fields: {},
  layouts: {
    default: {
      options: {
        direction: 'column',
        grow: 0
      },
      contents: [
        // top border line
        {
          options: {
            borderTop: `1px solid ${theme.palette.grayscale(90)}`,
            paddingBottom: 8
          },
          contents: []
        },
        {
          options: {
            color: theme.palette.text.secondary
          },
          component: 'Subtitle',
          source: 'Labor Type Name'
        },
        {
          options: {
            color: theme.palette.grayscale(60)
          },
          component: 'BodyText',
          source: 'Enter a Labor Type name such as XXXXX, YYYYY, or ZZZZZ'
        },
        {
          options: {
            padding: [40, 10, 64, 0],
            width: '30%'
          },
          contents: [
            {
              component: {
                default: 'TextInput'
              },
              source: 'name',
              options: {
                label: 'Labor Type Name',
                placeholder: 'Enter a name for this Labor Type',
                default_value: '',
                smartField: '',
                isRequired: true,
                fullWidth: false,
                alignment: 'left',
                isGreyBackground: true
              }
            }
          ]
        },
        // top border line
        {
          options: {
            borderTop: `1px solid ${theme.palette.grayscale(90)}`,
            paddingBottom: 8
          },
          contents: []
        },
        {
          options: {
            color: theme.palette.text.secondary
          },
          component: 'Subtitle',
          source: 'Billing'
        },
        {
          options: {
            color: theme.palette.grayscale(60)
          },
          component: 'BodyText',
          source: 'Select a Product for each Billing Hour Type'
        },
        {
          options: {
            color: theme.palette.grayscale(60)
          },
          component: 'BodyText',
          source: 'Note: Configure your Billing Hour Types in Settings / Labor'
        },
        {
          options: {
            paddingBottom: 64
          },
          contents: [
            {
              component: { default: 'BillingTypeProductMapping' },
              source: 'labourTypeBillingHourTypeProducts'
            }
          ]
        },
        // top border line
        {
          options: {
            borderTop: `1px solid ${theme.palette.grayscale(90)}`,
            paddingBottom: 8
          },
          contents: []
        },
        {
          options: {
            color: theme.palette.text.secondary
          },
          component: 'Subtitle',
          source: 'Payroll'
        },
        {
          options: {
            color: theme.palette.grayscale(60)
          },
          component: 'BodyText',
          source: 'Configure Cost Code and Cost Type for each Payroll Hour Type'
        },
        {
          options: {
            color: theme.palette.grayscale(60)
          },
          component: 'BodyText',
          source: 'Note: Configure Payroll Hour Types in Settings / Labor'
        },
        {
          component: { default: 'PayrollHourRate' },
          source: 'payrollCosts',
          options: {
            label: 'Payroll Hour Rate',
            placeholder: 'Payroll Hour Rate',
            isRequired: true
          }
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      name: {
        type: 'string'
      },
      productName: {
        type: 'string'
      }
    },
    required: ['name']
  },
  validationErrors: {
    name: {
      required: 'Field is required.'
    }
  }
});

export default payrollLaborType;
