import { tableDateTimeFormatter, tableEmptyValueFormatter } from 'components/WrapTable';

export const notesColumns = [
  {
    field: 'id',
    hide: true
  },
  {
    field: 'subject',
    flex: 1,
    headerName: 'Note Subject',
    valueFormatter: tableEmptyValueFormatter
  },
  {
    field: 'note',
    flex: 6,
    headerName: 'Note content',
    valueFormatter: tableEmptyValueFormatter
  },
  {
    field: 'addedBy',
    flex: 1,
    headerName: 'Last Edited By',
    valueFormatter: tableEmptyValueFormatter
  },
  {
    field: 'addedDateTime',
    flex: 1,
    type: 'dateTime',
    headerName: 'Last Edited',
    valueFormatter: tableDateTimeFormatter
  }
];
