const timeFilterLayout = ({ radioSelect, setAlgoliaProperty }) => {
  return {
    fields: {
      radius: {
        default: 'radius'
      },
      property: {
        default: 'property'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER'
        },
        contents: [
          {
            options: {
              direction: 'row'
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      default: 'TextInput'
                    },
                    source: 'radius',
                    options: {
                      type: 'number',
                      label: 'Filter Radius',
                      placeholder: '-',
                      isRequired: true,
                      endAdornmentText: 'miles'
                    }
                  }
                ]
              },
              {
                options: {
                  direction: 'column',
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      default: 'RadioButtonsGroup'
                    },
                    source: 'radio',
                    options: {
                      label: 'From',
                      inputOptions: 'Property,Address',
                      btnDirection: 'Horizontal'
                    }
                  }
                ]
              }
            ]
          },
          radioSelect === 'Property'
            ? {
                options: {
                  direction: 'row'
                },
                contents: [
                  {
                    options: {
                      direction: 'column',
                      padding: 12
                    },
                    contents: [
                      {
                        component: {
                          default: 'Algolia'
                        },
                        source: 'address', // both are named 'address' so that form can complete if either is populated
                        options: {
                          label: 'Property',
                          isRequired: true,
                          placeholder: 'Select a Property',
                          setAlgoliaProperty
                        }
                      }
                    ]
                  }
                ]
              }
            : {
                options: {
                  direction: 'row'
                },
                contents: [
                  {
                    options: {
                      direction: 'column',
                      padding: 12
                    },
                    contents: [
                      {
                        component: {
                          default: 'SearchInput'
                        },
                        source: 'address', // both are named 'address' so that form can omplete if either is populated
                        options: {
                          label: 'Address',
                          isRequired: true,
                          placeholder: 'Enter Address'
                        }
                      }
                    ]
                  }
                ]
              }
        ]
      }
    },
    validation: {
      type: 'object',
      properties: {
        radius: {
          type: 'string'
        },
        address: {
          type: 'string'
        }
      },
      required: ['radius', 'address']
    },
    validationErrors: {
      radius: {
        required: 'Radius is required.'
      },
      address: {
        required: 'This field is required.'
      }
    }
  };
};

export default timeFilterLayout;
