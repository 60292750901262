import useExtendedMutation from 'customHooks/useExtendedMutation';
import { GET_REVIEW_REPORT_BY_ID } from 'scenes/Jobs/JobDetail/queries';
import DELETE_DISCOUNT from 'services/core/graphql/review-report/mutations/DeleteDiscount';

const serializer = ({ tenantId, discount }) => {
  return {
    variables: {
      partitionKey: tenantId,
      id: discount.id
    }
  };
};

export const useDeleteReviewReportDiscount = reviewReportId => {
  return useExtendedMutation(DELETE_DISCOUNT, {
    serializer,
    showConfirmation: true,
    confirmationModalOptions: {
      title: 'Delete Discount',
      warn: true,
      modalText: 'Are you sure you want to delete this discount?'
    },
    update: (cache, { data: { deleteDiscount } }) => {
      const deletedDiscount = deleteDiscount;
      if (!deletedDiscount) {
        return;
      }
      const cachedData = cache.readQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        }
      });
      cache.writeQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        },
        data: {
          getReviewReportById: {
            ...(cachedData?.getReviewReportById || {}),
            discounts: {
              __typename: 'DiscountConnection',
              items: [
                ...cachedData.getReviewReportById?.discounts?.items.filter(
                  discount => discount.id !== deletedDiscount.id
                )
              ]
            }
          }
        }
      });
    }
  });
};
