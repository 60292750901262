import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CX from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import PersonOutlineOutlined from '@material-ui/icons/PersonOutlineOutlined';
import StorageService from 'services/StorageService';
import { Logger } from 'services/Logger';
import styles from './styles';

const ProfileIcon = ({ imageKey, employeeName, alt, classes, classNames }) => {
  const [initials, setInitials] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  useEffect(() => {
    const getImageUrl = async fileName => {
      if (!imageUrl) {
        try {
          const storageService = new StorageService();
          const url = await storageService.getFile(fileName);
          setImageUrl(url);
        } catch (error) {
          // avoid sending to Sentry as not all images will have thumbnails
          Logger.info(`Error getting image ${error}`);
        }
      }
    };
    getImageUrl(imageKey);
  }, [imageKey, imageUrl]);

  useEffect(() => {
    if (employeeName) {
      const letters = employeeName
        .split(' ')
        .map(n => n[0]?.toUpperCase())
        .join('');
      setInitials(letters);
    }
  }, [employeeName]);

  if (!imageUrl) {
    return (
      <div className={CX(classes.profileIconWrapper, classNames)}>
        {initials ? (
          <div className={classes.profileIconLetters}>{initials}</div>
        ) : (
          <PersonOutlineOutlined className={classes.profileIcon} />
        )}
      </div>
    );
  }

  return (
    <Grid container justify="center" alignItems="center">
      <Avatar src={imageUrl} alt={alt} className={CX(classes.avatar, classNames)} />
    </Grid>
  );
};

ProfileIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  classNames: PropTypes.object
};

ProfileIcon.defaultProps = {
  classNames: {}
};

export default withStyles(styles)(ProfileIcon);
