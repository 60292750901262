import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getDatafromPath } from 'utils';
import styles from './styles';

const ChipArray = ({ field, fieldProps, form, classes, ...rest }) => {
  const { fieldName, queryResult, queryPath, disableHashTag = false } = fieldProps.specialBehaviour;
  const result = getDatafromPath(queryResult, queryPath);
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="caption" className={classes.label}>
          {rest.label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {form.values[fieldProps.name] &&
          form.values[fieldProps.name].length > 0 &&
          form.values[fieldProps.name].map(data => {
            const displayData = result.filter(val => val.id === data);
            return displayData.map(item => (
              <Chip
                key={item[fieldName]}
                label={`${(disableHashTag ? '' : '# ') + item[fieldName]}`}
                className={classes.chip}
              />
            ));
          })}
        {form.values[fieldProps.name].length === 0 && <Typography>-</Typography>}
      </Grid>
    </>
  );
};

export default withStyles(styles)(ChipArray);
