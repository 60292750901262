import gql from 'graphql-tag';

const updateJob = gql`
  mutation updateTenantSetting($data: UpdateTenantSettingInput!) {
    updateTenantSetting(data: $data) {
      id
      partitionKey
      sortKey
      tenantId
      tenantCompanyId
      settingKey
      settingPath
      settingValue
    }
  }
`;

export default updateJob;
