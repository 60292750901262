export const vendorTableRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: true,
    label: 'Vendor Type'
  },
  {
    id: 'primaryContact',
    numeric: false,
    disablePadding: true,
    label: 'Primary Contact'
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: true,
    label: 'Phone'
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: 'Email'
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status'
  }
];

export const vendorsTable = {
  dataPathInQueryResult: '',
  idField: 'id',
  captionAttributes: vendorTableRows,
  noDataMsg: 'No vendors'
};
