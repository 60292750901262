import axios from 'axios';
import { Auth } from 'aws-amplify';
import ENV from 'configs/env';
import { sortBy, orderBy, uniq } from 'lodash';
import configForEnvironment from 'configs/aws-exports';
import { TimeTrackingService, TimesheetsService, CompanyService } from 'services/core';
import { logErrorWithCallback } from 'utils';
import removeObjProperties from 'utils/removeObjProperties';
import fileDownload from 'utils/fileDownload';
import pickPropertiesFromList from 'utils/pickPropertiesFromList';
import { payrollSettingFilter } from 'constants/common';
import {
  exportDetail,
  serviceErrorMessage,
  serviceSuccessMessage,
  timesheetNeededProperties
} from './constants';
import { getTimesheetCsvData, addShiftEndToEntry } from './helpers';

export const getTimesheetPeriods = async ({ setLoader, employee, snackbarOn, successCallback }) => {
  try {
    setLoader(true);
    if (!employee) return null;
    const { tenantId, sortKey } = employee;
    const Service = new TimeTrackingService();
    const { data } = await Service.getTimesheetPeriods(tenantId, sortKey);
    const timesheetPeriods = data?.getEmployee?.timesheetPeriods?.items;
    successCallback(orderBy(timesheetPeriods, 'dateStartUTC', 'desc'));
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, serviceErrorMessage.timePeriods);
  } finally {
    setLoader(false);
  }
};

export const getTimesheetPeriodById = async ({
  setLoader,
  employee,
  id,
  snackbarOn,
  successCallback
}) => {
  try {
    setLoader(true);
    if (!employee || !id) return null;
    const Service = new TimeTrackingService();
    const { data } = await Service.getTimesheetPeriodById(employee, id);
    successCallback(data.getTimesheetPeriodById);
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, serviceErrorMessage.timePeriod);
  } finally {
    setLoader(false);
  }
};

export const getPayrollHourTypes = async ({ user, snackbarOn, successCallback }) => {
  try {
    const { tenantId, tenantCompanyId } = user;
    const Service = new TimesheetsService();
    const { data } = await Service.getPayrollHourTypes(tenantId, tenantCompanyId);
    const hourTypes = data?.getCompany?.payrollHourTypes?.items;
    successCallback(sortBy(hourTypes, 'sortOrder'));
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, serviceErrorMessage.payrollHourTypes);
  }
};

export const getPayrollSettings = async ({ user, snackbarOn, successCallback }) => {
  let responseData = {};
  try {
    const Service = new CompanyService();
    const { tenantId, tenantCompanyId } = user;
    const { data } = await Service.getCompanySettings(
      tenantId,
      tenantCompanyId,
      payrollSettingFilter
    );

    responseData = data?.getCompany?.companySettings?.items?.[0];
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, serviceErrorMessage.payrollSetting);
  } finally {
    const payrollSettingStr = responseData?.settings;
    const settingObj = payrollSettingStr ? JSON.parse(payrollSettingStr) : {};
    successCallback(settingObj);
  }
};

export const handleTimesheetDailyOverrides = async ({
  data,
  snackbarOn,
  partitionKey,
  successCallback,
  timesheetPeriodId
}) => {
  try {
    const { actualTotalDurationOverride, version } = data;

    const payload = {
      actualTotalDurationOverride: data.actualTotalDurationOverride,
      dateUTC: data.dateUTC,
      hourTypeAbbreviation: data.hourTypeAbbreviation,
      hourTypeId: data.hourTypeId,
      id: data.id
    };

    payload.actualTotalDurationOverride = Number.isInteger(actualTotalDurationOverride)
      ? actualTotalDurationOverride
      : 0;

    const Service = new TimeTrackingService();
    let response;
    if (data.id) {
      const updateServiceResponse = await Service.updateTimesheetDailyOverrides(partitionKey, {
        ...payload,
        version
      });
      response = updateServiceResponse?.data.updateTimesheetDailyOverride;
    } else {
      const addServiceResponse = await Service.addTimesheetDailyOverrides(partitionKey, {
        timesheetPeriodId,
        timesheetDailyOverrides: [payload]
      });
      response = addServiceResponse?.data.addTimesheetDailyOverridesToTimesheetPeriod[0];
    }
    if (successCallback) successCallback(response);
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, serviceErrorMessage.dailyOverride);
  }
};

export const bulkUpdateTimesheetEntries = async ({
  data,
  employeeId,
  snackbarOn,
  partitionKey,
  setShowLoader,
  successCallback
}) => {
  let response = {};
  try {
    const editedData = data.filter(({ isEdited }) => isEdited);
    if (editedData.length === 0) {
      return snackbarOn('error', serviceErrorMessage.nonEditedTimesheetEntry);
    }
    setShowLoader(true);
    const timesheetEntries = pickPropertiesFromList(editedData, timesheetNeededProperties);
    const payload = { employeeId, timesheetEntries };
    const Service = new TimeTrackingService();
    response = await Service.bulkUpdateTimesheetEntries(partitionKey, payload);
    snackbarOn('success', serviceSuccessMessage.bulkSave);
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, serviceErrorMessage.bulkSave);
  } finally {
    setShowLoader(false);
    if (successCallback) successCallback(response);
  }
};

export const deleteTimesheetEntry = async ({
  timesheetId,
  snackbarOn,
  partitionKey,
  successCallback
}) => {
  let response = {};
  try {
    const Service = new TimeTrackingService();
    response = await Service.deleteTimesheetEntry(partitionKey, timesheetId);
    snackbarOn('success', serviceSuccessMessage.deleteTimesheetEntry);
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, serviceErrorMessage.deleteTimesheetEntry);
  } finally {
    if (successCallback) successCallback(response);
  }
};

export const reviewTimesheetEntries = async ({
  data = [],
  setShowLoader,
  employeeId,
  snackbarOn,
  partitionKey,
  successCallback
}) => {
  try {
    const timeheetEntryIds = uniq(data.map(({ id }) => id));
    if (timeheetEntryIds.length === 0) {
      return snackbarOn('error', serviceErrorMessage.noTimesheetEntry);
    }
    setShowLoader(true);
    const payload = { employeeId, timeheetEntryIds };
    const Service = new TimeTrackingService();
    const response = await Service.reviewTimesheetEntries(partitionKey, payload);
    if (successCallback) successCallback(response);
    snackbarOn('success', serviceSuccessMessage.reviewTimesheet);
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, serviceErrorMessage.reviewTimesheet);
  } finally {
    setShowLoader(false);
  }
};

export const approveTimesheetPeriod = async ({
  timesheetPeriodId,
  snackbarOn,
  partitionKey,
  successCallback
}) => {
  try {
    const payload = { timesheetPeriodId };
    const Service = new TimeTrackingService();
    const response = await Service.approveTimesheetPeriod(partitionKey, payload);
    if (successCallback) successCallback(response);
    snackbarOn('success', serviceSuccessMessage.approveTimesheet);
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, serviceErrorMessage.approveTimesheet);
  }
};

export const handleTimesheetDownload = async ({
  selectedTimesheetPeriod,
  snackbarOn,
  summaryType,
  settings = {}
}) => {
  try {
    const stringFilters = [
      {
        fieldName: 'actualStartTimeUTC',
        filterInput: { ge: selectedTimesheetPeriod.dateStartUTC }
      },
      { fieldName: 'actualEndTimeUTC', filterInput: { lt: selectedTimesheetPeriod.dateEndUTC } }
    ];

    const data = getTimesheetCsvData({
      stringFilters,
      exportFileName: `${settings?.ADPSettings?.setting?.ADPFilename}.xlsx`,
      ...exportDetail[summaryType]
    });

    const { exportFileName } = data;
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    const baseURL = configForEnvironment(ENV).restapi;

    const options = {
      method: 'post',
      url: `${baseURL}/data/exportExcel`,
      data,
      responseType: 'blob',
      headers: {
        Accept: '*/*',
        Authorization: token,
        'Content-Type': 'application/json'
      }
    };

    const res = await axios(options);

    fileDownload(res, res.headers['content-type'], exportFileName);
  } catch (error) {
    logErrorWithCallback(error, snackbarOn, serviceErrorMessage.downloadReport);
  }
};
