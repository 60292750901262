import moment from 'moment-timezone';
import * as Yup from 'yup';

export const updateItemInArray = ({ index = 0, list = [], value }) => {
  const clonedList = [...list];
  clonedList[index] = {
    ...list[index],
    ...value
  };
  return clonedList;
};

export const formatPartsAndMaterial = ({ productDescription, id, productName }) => ({
  pricebookEntryId: id, // backend should have name it as pricebookEntryId
  quantity: null,
  priceBookEntryName: productName,
  priceBookEntryDescription: productDescription
});

const getTimezoneMoment = (number, timezone) => moment.tz(moment.unix(Number(number)), timezone);

const mapFrequencyToMomentIncrement = {
  Daily: { days: 1 },
  TwiceAWeek: { weeks: 0.5 },
  Weekly: { weeks: 1 },
  BiWeekly: { weeks: 2 },
  Monthly: { months: 1 },
  AlternateMonths: { months: 2 },
  Quarterly: { quarters: 1 },
  BiAnnually: { quarters: 2 },
  Annually: { years: 1 }
};

const maintenanceJobsEndDate = (firstDueDate, frequency, numberOfOccurrences) => {
  if (!firstDueDate || !frequency || !numberOfOccurrences) return null;

  const incrementObject = mapFrequencyToMomentIncrement[frequency];
  if (!incrementObject) return null;

  const increment = Object.entries(incrementObject)?.flat();
  const endDate = moment.unix(Number(firstDueDate));

  endDate.add({ [increment[0]]: increment[1] * numberOfOccurrences });
  return endDate;
};

export const templateValidation = (agreementInfo, companyTimeZone) =>
  Yup.object().shape({
    propertyId: Yup.string().required('Property is required'),
    schedule: Yup.string().required('Property is required'),
    firstDueDate: Yup.string()
      .required('Property is required')
      .test('firstDueDateValidation', 'N/A', function(val) {
        if (Number(val) < moment('1900-1-1').unix()) {
          return this.createError({ message: 'First due date must be after January 1, 1900' });
        }
        if (Number(val) > moment('3000-1-1').unix()) {
          return this.createError({ message: 'First due date must be before January 1, 3000' });
        }
        if (
          getTimezoneMoment(val, companyTimeZone).isBefore(
            getTimezoneMoment(agreementInfo.startDate, companyTimeZone)
          )
        ) {
          return this.createError({
            message: 'Maintenance first due date should not be earlier than contract start date'
          });
        }

        if (
          agreementInfo.endDate &&
          getTimezoneMoment(val, companyTimeZone).isAfter(
            getTimezoneMoment(agreementInfo.endDate, companyTimeZone)
          )
        ) {
          return this.createError({
            message: 'Maintenance first due date should not be later than contract end date'
          });
        }
        return true;
      }),
    endDate: Yup.string()
      .nullable()
      .test('endDateValidation', 'N/A', function(val) {
        // If no value, don't validate
        if (!val) return true;
        if (Number(val) < moment('1900-1-1').unix()) {
          return this.createError({ message: 'End date must be after January 1, 1900' });
        }
        if (Number(val) > moment('3000-1-1').unix()) {
          return this.createError({ message: 'End date must be before January 1, 3000' });
        }
        if (
          getTimezoneMoment(val, companyTimeZone).isBefore(
            getTimezoneMoment(agreementInfo.startDate, companyTimeZone)
          )
        ) {
          return this.createError({
            message: 'Maintenance end date should not be earlier than contract start date'
          });
        }

        if (
          agreementInfo.endDate &&
          getTimezoneMoment(val, companyTimeZone).isAfter(
            getTimezoneMoment(agreementInfo.endDate, companyTimeZone)
          )
        ) {
          return this.createError({
            message: 'Maintenance end date should not be later than contract end date'
          });
        }
        if (Number(val) < Number(this.parent.firstDueDate)) {
          return this.createError({
            message: 'Maintenance end date should not be earlier than Maintenance start date'
          });
        }
        return true;
      }),
    numberOfOccurrences: Yup.number().test('numberOfOccurrencesValidation', 'N/A', function(val) {
      if (!val) return true;

      if (val < 1) {
        return this.createError({
          message: 'Number of Occurrences should be at least 1'
        });
      }

      // Note: arbitrary maximum number of jobs a user can generate as a guard
      if (val > 64) {
        return this.createError({
          message: 'Number of Occurrences should be at most 64'
        });
      }

      const maintenanceEndDate = maintenanceJobsEndDate(
        this.parent.firstDueDate,
        this.parent.schedule,
        val
      );

      // number of occurrences * frequency should not place the final job due date
      // after the service agreement due date
      if (
        agreementInfo.endDate &&
        maintenanceEndDate &&
        getTimezoneMoment(maintenanceEndDate, companyTimeZone).isAfter(
          getTimezoneMoment(moment.unix(agreementInfo.endDate), companyTimeZone)
        )
      ) {
        return this.createError({
          message: 'Final maintenance due date should not be later than contract end date'
        });
      }

      return true;
    })
  });
