import gql from 'graphql-tag';

const updateJobCostType = gql`
  mutation updateJobCostType($partitionKey: String!, $data: UpdateJobCostTypeInput!) {
    updateJobCostType(partitionKey: $partitionKey, data: $data) {
      id
      name
      type
      isActive
      version
    }
  }
`;

export default updateJobCostType;
