export const payrollBillingHourType = {
  fields: {
    hourType: { default: 'hourType' },
    hourTypeAbbreviation: { default: 'hourTypeAbbreviation' }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0,
            padding: [0, 6]
          },
          contents: [
            {
              component: 'BodyText',
              source: 'This type will be added to the Payroll Hour types. '
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0,
            padding: [0, 6]
          },
          contents: [
            {
              component: 'BodyText',
              source: 'Example: “Regular”, “REG”'
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'hourType',
                  options: {
                    label: '',
                    placeholder: 'REGULAR',
                    default_value: '',
                    smartField: '',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'hourTypeAbbreviation',
                  options: {
                    toolLabel: 'Text',
                    toolIcon: 'TextFields',
                    label: '',
                    placeholder: 'REG',
                    default_value: '',
                    smartField: '',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      hourType: {
        type: 'string'
      },
      hourTypeAbbreviation: {
        type: 'string'
      }
    },
    required: ['hourType', 'hourTypeAbbreviation']
  },
  validationErrors: {
    hourType: {
      required: 'Field is required.'
    },
    hourTypeAbbreviation: {
      required: 'Field is required.'
    }
  }
};

export default payrollBillingHourType;
