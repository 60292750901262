/* eslint-disable no-template-curly-in-string */
const jacksonMechPDFForm = settings => ({
  fields: {
    senderInfoLogoUrl: {
      pdf: '${senderInfoLogo}'
    },
    tenantAddress: {
      pdf: '${senderInfo.addressLine1}  *  ${senderInfo.addressLine3}  *  ${senderInfo.phoneNumber}'
    },
    // Customer Detail
    customerContactNameTitle: {
      pdf: 'Attn: '
    },
    customerContactName: {
      pdf: '${customerContactNameTitle}'
    },
    customerNameTitle: {
      pdf: 'Company: '
    },
    customerName: {
      pdf: '${customer.customerName}'
    },
    AddressTitle: {
      pdf: 'Address: '
    },
    billingAddressLine1: {
      pdf: '${billingAddressLine1}'
    },
    billingAddressLine2: {
      pdf: '${billingAddressLine2}'
    },
    billingAddress3: {
      pdf: '${billingAddressLine3}'
    },
    customerContactPhoneTitle: {
      pdf: 'Phone: '
    },
    customerContactPhone: {
      pdf: '${customerContactPhoneTitle}'
    },
    customerContactEmailTitle: {
      pdf: 'Via Email: '
    },
    customerContactEmail: {
      pdf: '${customerContactEmailTitle}'
    },
    // Tenant Detail
    createdDateTitle: {
      pdf: 'Date: '
    },
    createdDate: {
      pdf: '${createdDate}'
    },
    quoteNameTitle: {
      pdf: 'Re: '
    },
    quoteName: {
      pdf: '${quoteInfo.name}'
    },
    propertyLabel: {
      pdf: 'Property: '
    },
    propertyName: {
      pdf: '${property.companyName}'
    },
    propertyAddressLine1: {
      pdf: '${propertyDetail.propertyAddress1}'
    },
    propertyAddressLine2: {
      pdf: '${propertyDetail.propertyAddress2}'
    },
    propertyAddressLine3: {
      pdf: '${propertyDetail.propertyAddress3}'
    },
    quoteNumberValueTitle: {
      pdf: 'File Number: '
    },
    quoteNumberValue: {
      pdf: '${customizedQuoteNumber}'
    },
    // Introduction
    introduction: {
      pdf: '${quoteCustomFieldsData.Custom_longText1}'
    },
    // Total
    totalTitle: {
      pdf: 'Total Proposal: '
    },
    totalText: {
      pdf: '$${finalAmount}'
    },
    // Closing Remarks
    closingRemarks: {
      pdf: '${quoteCustomFieldsData.Custom_longText2}'
    }
  },
  layouts: {
    default: {
      options: {
        pdfTitle: 'Quote'
      },
      contents: [
        // Logo Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              options: {
                direction: 'column',
                size: 'auto',
                grow: 0,
                shrink: 1
                // debug: true
              },
              contents: [
                {
                  component: {
                    default: 'Logo'
                  },
                  options: {
                    width: 500,
                    height: 200,
                    alt: 'Company logo',
                    size: 'auto',
                    grow: 0,
                    shrink: 1
                  },
                  source: 'senderInfoLogoUrl'
                }
              ]
            }
          ]
        },
        // Address Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [0, 0, 12]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'BodyText'
              },
              source: 'tenantAddress'
            }
          ]
        },
        // Customer Detail Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'space-between',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            // Customer Details
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                direction: 'column',
                width: '50%',
                padding: [0, 5, 0, 0]
              },
              contents: [
                // Contact Name
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactName'
                    }
                  ]
                },
                // Constact Phone
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactPhoneTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactPhone'
                    }
                  ]
                },
                // Contact Email
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactEmailTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactEmail'
                    }
                  ]
                },
                // Customer Name
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerName'
                    }
                  ]
                },
                // Customer Address
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'AddressTitle'
                    },
                    {
                      options: {
                        size: 'auto',
                        grow: 0,
                        shrink: 1,
                        direction: 'column'
                      },
                      contents: [
                        {
                          component: { pdf: 'BoldText' },
                          source: 'billingAddressLine1'
                        },
                        {
                          component: { pdf: 'BoldText' },
                          source: 'billingAddressLine2'
                        },
                        {
                          component: { pdf: 'BoldText' },
                          source: 'billingAddressLine3'
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            // Tenant Details
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                direction: 'column',
                width: '35%'
              },
              contents: [
                // Created Date
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'createdDateTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'createdDate'
                    }
                  ]
                },
                // Quote Name
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'quoteNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'quoteName'
                    }
                  ]
                },
                // Quote Number
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'quoteNumberValueTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'quoteNumberValue'
                    }
                  ]
                },
                // Property Name
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'propertyLabel'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'propertyName'
                    }
                  ]
                },
                // Property Address
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'AddressTitle'
                    },
                    {
                      options: {
                        size: 'auto',
                        grow: 0,
                        shrink: 1,
                        direction: 'column'
                      },
                      contents: [
                        {
                          component: { pdf: 'BoldText' },
                          source: 'propertyAddressLine1'
                        },
                        {
                          component: { pdf: 'BoldText' },
                          source: 'propertyAddressLine2'
                        },
                        {
                          component: { pdf: 'BoldText' },
                          source: 'propertyAddressLine3'
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        // Divider
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              options: {
                color: '#333'
              },
              component: {
                pdf: 'Divider'
              }
            }
          ]
        },
        // Intro
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'RichContentViewer'
              },
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1
              },
              source: 'introduction'
            }
          ]
        },
        // Tasks and Products
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              options: {
                direction: 'column',
                justify: 'start',
                size: 'auto',
                grow: 0,
                shrink: 1,
                padding: [0, 0, 0, 0]
                // debug: true
              },
              contents: [
                {
                  component: {
                    pdf: 'TaskList'
                  },
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    width: 480
                    // debug: true
                  },
                  source: 'quoteTasks'
                }
              ]
            }
          ]
        },
        // Total
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'flex-end',
            align: 'stetch',
            padding: [6, 36, 6],
            hide: settings.hideTotalAmount
            // debug: true
          },
          contents: [
            {
              component: {
                pdf: 'Subtitle'
              },
              source: 'totalTitle'
            },
            {
              component: {
                pdf: 'Subtitle'
              },
              source: 'totalText'
            }
          ]
        },
        // Closing Remarks
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'RichContentViewer'
              },
              source: 'closingRemarks'
            }
          ]
        },
        // Terms and conditions Row
        {
          options: {
            direction: 'row',
            breakPage: 'before',
            width: '100%'
            // debug: true
          },
          contents: [
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                justify: 'flex-start'
                // debug: true
              },
              // For centering title
              contents: [
                {
                  options: {
                    direction: 'row',
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    justify: 'center',
                    padding: [6, 36, 6]
                  },
                  contents: [
                    {
                      options: {
                        direction: 'column',
                        size: 'auto',
                        grow: 0,
                        shrink: 1
                      },
                      contents: [
                        {
                          component: {
                            default: 'BoldText'
                          },
                          options: {
                            padding: [0, 0, 6],
                            fontSize: 12
                          },
                          source: 'Terms and Conditions-Service and Repair'
                        }
                      ]
                    }
                  ]
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  options: {
                    padding: [0, 0, 3],
                    fontSize: 8
                  },
                  source: '1. OFFER AND ACCEPTANCE'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'Jackson Mechanical Service (JMS) offers to sell the materials, equipment and services indicated in strict accordance with the terms and conditions stated herein. Submittal of a Purchase Order or executions of this offer constitute a legally enforceable contract between Buyer and JMS. Any additional or differing terms and conditions contained on Buyers Purchase Order (whether or not such terms materially alter this offer) are hereby rejected by JMS and shall not become part of the contract between Buyer and JMS unless expressly consented to in writing by JMS. This offer is subject to acceptance within 30 days after date and is based on all work being performed during regular working hours.'
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  options: {
                    padding: [0, 0, 3],
                    fontSize: 8
                  },
                  source: '2. TERMS'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'Terms of Payment for goods shipped and/or services rendered hereunder shall be NET on RECEIPT of INVOICE. JMS reserves the right to add to any account outstanding more than thirty (30) days a charge of one and one-half (1 ½ %) percent of the principal amount due at the end of each thirty (30) day period.'
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  options: {
                    padding: [0, 0, 3],
                    fontSize: 8
                  },
                  source: '3. INVOICING'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'JMS reserves the right to issue partial or complete INVOICES as material is furnished and as services are rendered.'
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  options: {
                    padding: [0, 0, 3],
                    fontSize: 8
                  },
                  source: '4. PERFORMANCE'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    "JMS shall not be liable for failure to ship or delays in delivery of equipment or performance of services hereunder where such failure or delay is due to the disapproval of the JMS Credit Department, or due to strikes, fires, accidents, national emergency, failure to secure materials from the usual sources of supply, or any other circumstance beyond the control of JMS, whether of the class of causes enumerated above or not, which shall prevent JMS from making deliveries or performing services in theusualcourseofbusiness.In the event of the disapproval of JMS or the occurrence of any of the above. JMS may. at its sole option, cancel Buyer’s Purchase Order without any liability on the part of JMS, Alternatively JMS may extend the time for its performance by a period equal to the duration of the cause underlying JMS's failure or delay. Receipt of the equipment or services by Buyer upon its delivery- shall constitute a waiver of all claims for delay."
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  options: {
                    padding: [0, 0, 3],
                    fontSize: 8
                  },
                  source: '5. TAXES:'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'Prices quoted are exclusive of taxes.  The amount of any present or any future occupation, sales, use, service, excise or other similar tax which JMS shall be liable for either on its own behalf or on behalf of the buyer, with respect to any orders for machinery or services, shall be in addition to the billing prices and be paid by the buyer.'
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  options: {
                    padding: [0, 0, 3],
                    fontSize: 8
                  },
                  source: '6. WARRANTY:'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    "JMS guarantees service work and all materials against defects in workmanship and material for 90 days from date of completion of the work and will repair or replace F.O.B. point of manufacture or shipment such products or components as JMS finds defective. This warranty does not include the cost of labor to remove or reinstall any defective components, nor does this warranty include cost of handling, shipping or transportation involved in supplying replacements for defective components. This warranty does not include the replacement of refrigerant lost from the system after completion of the work. On machinery and materials furnished by JMS but manufactured by others. JMS will extend the same guarantee it receives from the manufacturer.  THE WARRANTY AND LIABILITY SET FORTH ABOVE ARE IN LIEU OF ALL OTHER WARRANTIES AND LIABILITIES, EXPRESS OR IMPLIED. IN LAW OR IN ACT, INCLUDING THE WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. THE WARRANTIES CONTAINED HEREIN SET FORTH BUYER'S SOLE AND EXCLUSIVE REMEDY IN THE EVENT OF A DEFECT IN WORKMANSHIP OR MATERIALS."
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  options: {
                    padding: [0, 0, 3],
                    fontSize: 8
                  },
                  source: '7. LIMITATION OF LIABILITY: '
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    "All claims, causes of action or legal proceedings against JMS arising from JMS’s performance under this contract must be commenced by Buyer within the express warranty period specified under Paragraph 6 hereof. Failure to commence any such claim, cause of action or legal proceeding within such period shall constitute a voluntary and knowing waiver thereof by Buyer. IN NO EVENT SHALL JMS's LIABILITY FOR DIRECT OR COMPENSATORY DAMAGES EXCEED THE PAYMENTS RECEIVED BY AMS FROM BUYER UNDER THE INSTANT CONTRACT, NOR SHALL AMS BE LIABLE FOR ANY SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES. THESE LIMITATIONS ON DAMAGES SHALL APPLY UNDER ALL THEORIES OF LIABILITY OR CAUSES OF ACTION. INCLUDING BUT NOT LIMITED TO CONTRACT, WARRANTY, TORT OR STRICT LIABILITY."
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  options: {
                    padding: [0, 0, 3],
                    fontSize: 8
                  },
                  source: '8. DELIVERY'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'Shipping dates are approximate only. No shipping date requested or specified by Buyer will be binding on JMS unless such request or specification is specifically agreed to in writing by an officer of JMS. Shipment shall be F.O.B. factory, with title passing to Buyer upon delivery to the carrier by JMS.'
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  options: {
                    padding: [0, 0, 3],
                    fontSize: 8
                  },
                  source: '9. CANCELLATION:'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'JMS reserves the right to collect cancellation charges (including but not limited to all costs and expenses incurred, plus reasonable overhead and profit against any cancelled order).'
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  options: {
                    padding: [0, 0, 3],
                    fontSize: 8
                  },
                  source: '10. DISPUTES AND CHOICE OF LAWS'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'This contract shall be deemed to have been entered into and shall be governed by the laws of Oklahoma. All claims, disputes and controversies arising out of or relating to this contract or the breach thereof, shall, in lieu of court action, be submitted to arbitration in accordance with the Commercial Arbitration Rules of the American Arbitration Association, and any judgment upon the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. The site of the arbitration shall be Oklahoma City, Oklahoma, unless another site is mutually agreed between the parties. The parties agree that  any  party  to  the  arbitration  shall  be entitled  to discovery of the other party as provided by the Federal Rules of Civil Procedure; provided, however, that any such discovery shall be completed within four (4) months from the date the Demand for Arbitration is filed with the American Arbitration Association.'
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  options: {
                    padding: [0, 0, 3],
                    fontSize: 8
                  },
                  source: '11. COSTS TO JMS'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    "In the event it becomes necessary for JMS to incur any costs or expenses in the collection of monies due JMS from Buyer, or to enforce any of its rights or privileges hereunder. Buyer, upon demand, shall reimburse JMS for all such costs and expenses (including, but not limited to, reasonable attorney's fees)."
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  options: {
                    padding: [0, 0, 3],
                    fontSize: 8
                  },
                  source: '12. ENTIRE AGREEMENT'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'These terms and conditions, and the matter set forth on the face of JMS’s offer to sell, constitute the entire agreement between JMS and Buyer. No course of dealings or performance, or prior, concurrent or subsequent understandings, agreements of representations become pai1 of this contract unless expressly agreed to in writing by an authorized representative of JMS.'
                },
                {
                  component: {
                    default: 'BoldText'
                  },
                  options: {
                    padding: [0, 0, 3],
                    fontSize: 8
                  },
                  source: '13.  ASSIGNMENT'
                },
                {
                  options: {
                    padding: [0, 0, 6],
                    fontSize: 8
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'Buyer shall not assign this contract or any interest therein without the prior written consent of JMS. Any actual or attempted assignment without JMS’s consent shall entitle JMS at its sole option, to cancel this contract and, in such event. JMS shall be entitled to payment for all work performed and materials furnished to the date of cancellation, as well as reasonable compensation for lost income and profits.'
                },
                // to stick TOC in one page
                {
                  options: {
                    padding: 10
                  },
                  contents: []
                }
              ]
            }
          ]
        }
      ]
    }
  }
});

export default jacksonMechPDFForm;
