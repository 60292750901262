/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getPayApplication(projectId, noInclude) {
  return await instance
    .get(`PayApplication/byproject/${projectId}`, {
      params: {
        include: noInclude ? [] : '*'
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getPayApplicationById(id, noInclude) {
  return await instance
    .get(`PayApplication/${id}`, {
      params: {
        include: noInclude ? [] : '*'
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return {};
    });
}

export async function payApplicationCreate(payApplication) {
  return await instance
    .post('PayApplication', payApplication)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getNextPayApplicationNumber(projectId) {
  let paramsClause = {};
  if (projectId) {
    paramsClause = { projectId };
  }
  return await instance
    .get('PayApplication/nextnumber', {
      params: paramsClause
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getNextInvoiceNumber() {
  return await instance
    .get('PayApplication/invoice/nextnumber')
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function payApplicationChange(id, payApplication) {
  return await instance
    .put(`PayApplication/${id}`, payApplication)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
