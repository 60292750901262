import gql from 'graphql-tag';

const deleteInventoryPart = gql`
  mutation deleteInventoryPart($partitionKey: String!, $id: String!, $jobId: String) {
    deleteInventoryPart(partitionKey: $partitionKey, id: $id, jobId: $jobId) {
      id
      entityType
    }
  }
`;

export default deleteInventoryPart;
