export const TextInputProperties = {
  toolLabel: 'Text',
  toolIcon: 'TextFields',
  label: 'Text Item',
  placeholder: '',
  default_value: '',
  smartField: '',
  isRequired: false,
  errorMsg: 'Field is required.',
  fullWidth: true,
  alignment: 'left',
  searchableField: true,
  searchableCategory: 'text',
  editFormLayout: 'TextInputPropertiesLayout',
  controlType: 'TextInput',
  viewControlType: 'FieldWithLabel'
};

export const TextInputPropertiesLayout = [
  {
    fields: {
      label: {
        default: 'label',
        edit: 'label'
      },
      placeholder: {
        default: 'placeholder',
        edit: 'placeholder'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'label',
                    options: {
                      label: 'Label'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'placeholder',
                    options: {
                      label: 'Placeholder'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'default_value',
                    options: {
                      label: 'Default Value'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'SelectInput',
                    source: 'smartField',
                    options: {
                      label: 'Smart Field Source',
                      isSmartfieldControl: true
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'searchableField',
                    options: {
                      label: 'Searchable Item',
                      checked: false,
                      value: 'searchableField'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'isRequired',
                    options: {
                      label: 'Required Item',
                      checked: false,
                      value: 'isRequired'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'errorMsg',
                    options: {
                      label: 'Error Message'
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  {
    label: '',
    placeholder: '',
    default_value: '',
    smartField: '',
    searchableField: '',
    isRequired: '',
    errorMsg: ''
  }
];

export const NumberInputProperties = {
  toolLabel: 'Number',
  toolIcon: 'Looks3',
  label: 'Number Item',
  type: 'numeric',
  placeholder: '',
  default_value: '',
  smartField: '',
  isRequired: false,
  errorMsg: 'Field is required.',
  fullWidth: true,
  alignment: 'left',
  searchableField: true,
  searchableCategory: 'number',
  editFormLayout: 'NumberInputPropertiesLayout',
  controlType: 'TextInput',
  viewControlType: 'FieldWithLabel'
};

export const NumberInputPropertiesLayout = [
  {
    fields: {
      label: {
        default: 'label',
        edit: 'label'
      },
      placeholder: {
        default: 'placeholder',
        edit: 'placeholder'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'label',
                    options: {
                      label: 'Label'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'placeholder',
                    options: {
                      label: 'Placeholder'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'default_value',
                    options: {
                      label: 'Default Value',
                      type: 'numeric'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'SelectInput',
                    source: 'smartField',
                    options: {
                      label: 'Smart Field Source',
                      isSmartfieldControl: true
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'searchableField',
                    options: {
                      label: 'Searchable Item',
                      checked: false,
                      value: 'searchableField'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'isRequired',
                    options: {
                      label: 'Required Item',
                      checked: false,
                      value: 'isRequired'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'errorMsg',
                    options: {
                      label: 'Error Message'
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  {
    label: '',
    placeholder: '',
    default_value: '',
    smartField: '',
    searchableField: '',
    isRequired: '',
    errorMsg: ''
  }
];

export const TextAreaProperties = {
  toolLabel: 'Multi-line Text',
  toolIcon: 'TextFields',
  label: 'Multi-line Text Item',
  placeholder: '',
  default_value: '',
  smartField: '',
  isRequired: false,
  lines: 4,
  errorMsg: 'Field is required.',
  fullWidth: true,
  alignment: 'left',
  searchableField: true,
  searchableCategory: 'longText',
  editFormLayout: 'TextAreaPropertiesLayout',
  controlType: 'TextInput',
  viewControlType: 'FieldWithLabel'
};

export const TextAreaPropertiesLayout = [
  {
    fields: {
      label: {
        default: 'label',
        edit: 'label'
      },
      placeholder: {
        default: 'placeholder',
        edit: 'placeholder'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'label',
                    options: {
                      label: 'Label'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'placeholder',
                    options: {
                      label: 'Placeholder'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'default_value',
                    options: {
                      label: 'Default Value',
                      lines: 4
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'SelectInput',
                    source: 'smartField',
                    options: {
                      label: 'Smart Field Source',
                      isSmartfieldControl: true
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'searchableField',
                    options: {
                      label: 'Searchable Item',
                      checked: false,
                      value: 'searchableField'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'isRequired',
                    options: {
                      label: 'Required Item',
                      checked: false,
                      value: 'isRequired'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'errorMsg',
                    options: {
                      label: 'Error Message'
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  {
    label: '',
    placeholder: '',
    default_value: '',
    smartField: '',
    searchableField: '',
    isRequired: '',
    errorMsg: ''
  }
];

export const DatePickerProperties = {
  toolLabel: 'Date',
  toolIcon: 'Today',
  label: 'Date Picker',
  placeholder: '',
  isRequired: false,
  errorMsg: 'Field is required.',
  fullWidth: true,
  alignment: 'left',
  searchableField: true,
  searchableCategory: 'date',
  editFormLayout: 'DatePickerPropertiesLayout',
  controlType: 'DateInput',
  viewControlType: 'FieldWithLabel'
};

export const DatePickerPropertiesLayout = [
  {
    fields: {
      label: {
        default: 'label',
        edit: 'label'
      },
      placeholder: {
        default: 'placeholder',
        edit: 'placeholder'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'label',
                    options: {
                      label: 'Label'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'placeholder',
                    options: {
                      label: 'Placeholder'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'searchableField',
                    options: {
                      label: 'Searchable Item',
                      checked: false,
                      value: 'searchableField'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'isRequired',
                    options: {
                      label: 'Required Item',
                      checked: false,
                      value: 'isRequired'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'errorMsg',
                    options: {
                      label: 'Error Message'
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  {
    label: '',
    placeholder: '',
    searchableField: '',
    isRequired: '',
    errorMsg: ''
  }
];

export const SelectInputProperties = {
  toolLabel: 'Multiple Options',
  toolIcon: 'ViewListOutlined',
  label: 'Multiple Options',
  placeholder: 'Select...',
  inputOptions: 'Option 1, Option 2, Option 3',
  isRequired: false,
  errorMsg: 'Field is required.',
  fullWidth: true,
  alignment: 'left',
  searchableField: true,
  searchableCategory: 'pickList',
  editFormLayout: 'SelectInputPropertiesLayout',
  controlType: 'SelectInput',
  viewControlType: 'FieldWithLabel'
};

export const SelectInputPropertiesLayout = [
  {
    fields: {
      label: {
        default: 'label',
        edit: 'label'
      },
      inputOptions: {
        default: 'inputOptions',
        edit: 'inputOptions'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'label',
                    options: {
                      label: 'Label'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'inputOptions',
                    options: {
                      label: 'Options',
                      lines: 4
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'searchableField',
                    options: {
                      label: 'Searchable Item',
                      checked: false,
                      value: 'searchableField'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'isRequired',
                    options: {
                      label: 'Required Item',
                      checked: false,
                      value: 'isRequired'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'errorMsg',
                    options: {
                      label: 'Error Message'
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  {
    label: '',
    inputOptions: '',
    placeholder: '',
    searchableField: '',
    isRequired: '',
    errorMsg: ''
  }
];

export const FileUploadProperties = {
  toolLabel: 'File Upload',
  toolIcon: 'Publish',
  label: 'File Upload Button',
  multiple: false,
  isRequired: false,
  errorMsg: 'Field is required.',
  editFormLayout: 'FileUploadPropertiesLayout',
  controlType: 'FileUpload',
  viewControlType: 'FileLink'
};

export const FileUploadPropertiesLayout = [
  {
    fields: {
      label: {
        default: 'label',
        edit: 'label'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'label',
                    options: {
                      label: 'Label'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'multiple',
                    options: {
                      label: 'Allow Multiple file selection',
                      checked: false,
                      value: 'multiple'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'isRequired',
                    options: {
                      label: 'Required Item',
                      checked: false,
                      value: 'isRequired'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'errorMsg',
                    options: {
                      label: 'Error Message'
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  {
    label: '',
    multiple: '',
    isRequired: '',
    errorMsg: ''
  }
];

export const RichTextEditorProperties = {
  toolLabel: 'Rich Text Editor',
  toolIcon: 'FormatAlignJustify',
  label: 'Rich Text Editor',
  value: '',
  placeholder: '"Type something here..."',
  default_value: '',
  isRequired: false,
  lines: 4,
  errorMsg: 'Field is required.',
  fullWidth: true,
  alignment: 'left',
  searchableField: true,
  searchableCategory: 'longText',
  editFormLayout: 'RichTextEditorPropertiesLayout',
  controlType: 'RichContentEditor',
  viewControlType: 'RichContentViewer'
};

export const RichTextEditorPropertiesLayout = [
  {
    fields: {
      label: {
        default: 'label',
        edit: 'label'
      },
      placeholder: {
        default: 'placeholder',
        edit: 'placeholder'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'label',
                    options: {
                      label: 'Label'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'placeholder',
                    options: {
                      label: 'Placeholder'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'RichContentEditor',
                    source: 'default_value',
                    options: {
                      label: 'Default Value'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'searchableField',
                    options: {
                      label: 'Searchable Item',
                      checked: false,
                      value: 'searchableField'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'CheckboxInput',
                    source: 'isRequired',
                    options: {
                      label: 'Required Item',
                      checked: false,
                      value: 'isRequired'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'errorMsg',
                    options: {
                      label: 'Error Message'
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  {
    label: '',
    placeholder: '',
    default_value: '',
    searchableField: '',
    isRequired: '',
    errorMsg: ''
  }
];
