export const useStyles = () => ({
  root: theme => ({
    paddingLeft: theme.default.spacing(2),
    paddingTop: theme.default.spacing(3)
  }),
  tableControls: theme => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.default.spacing(2)
  }),
  switchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: '0 0 250px'
  },
  switch: {
    marginLeft: 8,
    width: 125
  },
  departmentSelect: {
    maxWidth: 500,
    '& [class*="MuiInput-input"]': {
      height: 'unset',
      alignItems: 'center',
      paddingTop: 0,
      paddingBottom: 0
    },
    '& [class*="ValueContainer"]': {
      alignSelf: 'center'
    }
  },
  warn: {
    marginBottom: 20
  }
});
