import gql from 'graphql-tag';

const addInventoryPartsToReviewReport = gql`
  mutation addInventoryPartsToReviewReport(
    $partitionKey: String
    $data: AddInventoryPartsToReviewReportInput!
  ) {
    addInventoryPartsToReviewReport(partitionKey: $partitionKey, data: $data) {
      id
      sortKey
      name: itemName
      departmentName
      accountingRefIdOfClass
      description
      truck(entityConnection: "Truck") {
        value: id
        label: name
      }
      vendorName
      quantity
      unitCost
      unitPrice
      markupValue: markup
      amount
      taxable
      includeInInvoice
      version
      addedBy
      createdBy
      costCode(entityConnection: "CostCode") {
        value: id
        label: name
      }
      jobCostType(entityConnection: "jobCostType") {
        value: id
        label: name
        type
      }
      revenueType(entityConnection: "revenueType") {
        value: id
        label: name
        type
      }
      priceBookEntry(entityConnection: "PriceBookEntry") {
        id
        version
        product(entityConnection: "Product") {
          id
          name
          version
          unitOfMeasure(entityConnection: "UnitOfMeasure") {
            id
            name
          }
        }
      }
    }
  }
`;

export default addInventoryPartsToReviewReport;
