import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => {
  return {
    root: {
      marginTop: 24
    },
    container: {
      backgroundColor: theme.palette.grayscale(94),
      padding: theme.spacing(2),
      boxShadow: '0px 0px 12px -3px rgba(0, 0, 0, 0.25)'
    },
    accordionFooter: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: '21px 28px'
    },
    hideElement: {
      display: 'none'
    },
    footerForm: {
      width: 702
    },
    footerTotal: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginBottom: 21
    },
    label: {
      fontSize: 10,
      letterSpacing: 0.01,
      fontWeight: 'normal',
      textTransform: 'uppercase',
      lineHeight: '14px',
      marginBottom: '0.35em'
    },
    timesheetsContainer: {
      paddingTop: theme.spacing(2)
    }
  };
});
