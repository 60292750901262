import { AdjustmentStatus, EnumType, ExportStatus } from 'utils/constants';

export default [
  {
    id: 'number',
    filterKey: 'Adjustment.number',
    filterType: 'string',
    label: 'Adjustment',
    showLink: 'view',
    linkPath: '/adjustment/view',
    linkReference: 'id'
  },
  {
    id: 'billingCustomer.customerName',
    filterKey: 'BillingCustomer.customerName',
    filterType: 'string',
    label: 'Billing Customer',
    showLink: 'view',
    linkPath: '/customer/view',
    linkReference: 'billingCustomerId'
  },
  {
    id: 'status',
    filterKey: 'Adjustment.status',
    filterType: 'multi-select',
    options: Object.values(AdjustmentStatus),
    type: 'enum',
    enumType: EnumType.ADJUSTMENT_STATUS,
    label: 'Status'
  },
  {
    id: 'exportStatus',
    filterKey: 'Adjustment.exportStatus',
    filterType: 'multi-select',
    type: 'enum',
    options: Object.values(ExportStatus),
    enumType: EnumType.EXPORT_STATUS,
    numeric: false,
    label: 'Accounting Status'
  },
  {
    id: 'transactions',
    label: 'Payments/Invoices',
    type: 'TransactionList',
    filterOptions: [
      {
        label: 'Invoice Number',
        filterKey: 'AdjustmentInvoice.invoiceNumber',
        filterType: 'stringNoEmpty',
        convertToSubQuery: true,
        subQueryCondition: {
          fieldName: 'Adjustment.id',
          fieldComparator: 'exists',
          entityConnection: 'AdjustmentTransaction',
          subQueryFieldName: 'AdjustmentTransaction.adjustmentId',
          filter: {
            stringFilters: [
              {
                fieldName: 'AdjustmentTransaction.adjustmentId',
                filterInput: {
                  eq: 'Adjustment.id'
                },
                literal: true
              }
            ]
          }
        }
      },
      {
        label: 'Payment Number',
        filterKey: 'AdjustmentPayment.paymentNumber',
        filterType: 'stringNoEmpty',
        convertToSubQuery: true,
        subQueryCondition: {
          fieldName: 'Adjustment.id',
          fieldComparator: 'exists',
          entityConnection: 'AdjustmentTransaction',
          subQueryFieldName: 'AdjustmentTransaction.adjustmentId',
          filter: {
            stringFilters: [
              {
                fieldName: 'AdjustmentTransaction.adjustmentId',
                filterInput: {
                  eq: 'Adjustment.id'
                },
                literal: true
              }
            ]
          }
        }
      }
    ],
    disableSort: true,
    isCustom: true
  },
  {
    id: 'adjustmentType.name',
    filterKey: 'AdjustmentType.name',
    filterType: 'string',
    label: 'Adjustment Type'
  },
  {
    id: 'transactionType',
    filterKey: 'Adjustment.transactionType',
    filterType: 'string',
    label: 'Transaction Type'
  },
  {
    id: 'date',
    filterKey: 'Adjustment.date',
    filterType: 'number',
    type: 'dateOnly',
    label: 'Adjustment Date'
  },
  {
    id: 'amount',
    filterKey: 'Adjustment.amount',
    filterType: 'currency',
    numeric: true,
    type: 'currency',
    label: 'Amount',
    bold: true
  },
  {
    id: 'appliedAmount',
    filterKey: 'Adjustment.appliedAmount',
    filterType: 'currency',
    numeric: true,
    label: 'Applied Amount',
    type: 'currency',
    bold: true
  },
  {
    id: 'createdBy',
    filterKey: 'Adjustment.createdBy',
    filterType: 'string',
    label: 'Created By'
  },
  {
    id: 'createdDate',
    filterKey: 'Adjustment.createdDate',
    filterType: 'date',
    label: 'Created Date',
    type: 'dateOnly'
  },
  {
    id: 'syncStatus',
    disableFilter: true,
    disableSort: true,
    isCustom: true,
    type: 'syncStatus',
    label: 'Sync status'
  }
];
