import React, { useState } from 'react';
import { CompanyService } from 'services/core';
import { logErrorWithCallback } from 'utils';

export const getPricebooks = async ({ tenantId, tenantCompanyId }) => {
  const Service = new CompanyService();
  const { data } = await Service.getPricebooksForCompany(
    tenantId,
    `${tenantId}_Company_${tenantCompanyId}`
  );
  const pricebooks = data?.getCompany?.priceBooks?.items || [];
  return pricebooks;
};

const usePricebooks = (tenantId, tenantCompanyId, snackbarOn) => {
  const [pricebooks, setPricebooks] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const getData = async () => {
    try {
      setLoaded(false);
      const result = await getPricebooks({ tenantId, tenantCompanyId });
      setPricebooks(result);
      setLoaded(true);
    } catch (error) {
      logErrorWithCallback(
        error,
        snackbarOn,
        'Unable to fetch pricebook information, please try again later'
      );
      setLoaded(true);
    }
  };
  React.useEffect(() => {
    if (tenantCompanyId && tenantId) {
      getData();
    }
    // Disable lint - getData function not needed
    // eslint-disable-next-line
  }, [tenantCompanyId, tenantId]);

  return [pricebooks, loaded];
};

export default usePricebooks;
