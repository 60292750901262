import gql from 'graphql-tag';

const getJobCostTypes = gql`
  query getJobCostTypes(
    $partitionKey: String!
    $sortKey: String!
    $filter: TableJobCostTypeFilterInput
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      jobCostTypes(entityConnection: "JobCostType", filter: $filter) {
        items {
          id
          name
          type
          version
          isActive
          createdDate
        }
      }
    }
  }
`;

export default getJobCostTypes;
