import { EmailInvoiceType, EmailInvoiceTypeLabel, BestContactType } from 'utils/constants';

const emailInvoiceTypeSelectValues = [
  {
    value: EmailInvoiceType.NonBilling,
    label: EmailInvoiceTypeLabel.NonBilling
  },
  {
    value: EmailInvoiceType.EmailTo,
    label: EmailInvoiceTypeLabel.EmailTo
  },
  {
    value: EmailInvoiceType.EmailCC,
    label: EmailInvoiceTypeLabel.EmailCC
  }
];

export const CustomerRepLayout = () => ({
  fields: {
    firstName: {
      default: 'firstName',
      edit: 'firstName'
    },
    lastName: {
      default: 'lastName',
      edit: 'lastName'
    },
    contactRole: {
      default: 'contactRole',
      edit: 'contactRole'
    },
    emailInvoice: {
      default: 'emailInvoice',
      edit: 'emailInvoice'
    },
    email: {
      default: 'email',
      edit: 'email'
    },
    cellPhone: {
      default: 'cellPhone',
      edit: 'cellPhone'
    },
    landlinePhone: {
      default: 'landlinePhone',
      edit: 'landlinePhone'
    },
    bestContact: {
      default: 'bestContact',
      edit: 'bestContact'
    },
    isSmsOptOut: {
      default: 'isSmsOptOut',
      edit: 'isSmsOptOut'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 1
      },
      contents: [
        // 1st row
        {
          options: {
            direction: 'row',
            grow: 1
          },
          contents: [
            // First Name
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'firstName',
                  options: {
                    label: 'First Name',
                    isRequired: true,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            // Last Name
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'lastName',
                  options: {
                    label: 'Last Name',
                    isRequired: false,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        },
        // 2nd row
        {
          options: {
            direction: 'row',
            grow: 1
          },
          contents: [
            // Role
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'contactRole',
                  options: {
                    label: 'Role',
                    isRequired: false,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            // Email Invoice
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: null,
                  /* Temporarily disabled, see https://buildops.atlassian.net/browse/BUOP-9307
                  component: {
                    default: 'SelectInput'
                  }, */
                  source: 'emailInvoice',
                  options: {
                    label: 'Invoice Email Default',
                    inputOptions: emailInvoiceTypeSelectValues,
                    isRequired: false,
                    fullWidth: true,
                    alignment: 'left',
                    isSearchable: false,
                    isMultipleSelection: false
                  }
                }
              ]
            }
          ]
        },
        // 3rd row
        {
          options: {
            direction: 'row',
            grow: 1
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'BodyText',
                    default: null
                  },
                  source:
                    'Please add your contact information and indicate your preferred method of contact',
                  options: {
                    label: 'Text Block',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        },
        // 4th row: Email address
        {
          options: {
            direction: 'row',
            grow: 1
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'email',
                  options: {
                    label: 'Email address',
                    isRequired: false,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12,
                justify: 'flex-end'
              },
              contents: [
                {
                  component: {
                    edit: 'ExclusiveCheckboxInput',
                    default: 'ExclusiveReadOnlyCheckboxInput'
                  },
                  source: 'bestContact',
                  options: {
                    contact: BestContactType.EMAIL,
                    label: 'Preferred',
                    isRequired: false,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        },
        // 5th row: Mobile Phone
        {
          options: {
            direction: 'row',
            grow: 1
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'cellPhone',
                  options: {
                    label: 'Phone - Mobile',
                    isRequired: false,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12,
                justify: 'flex-end'
              },
              contents: [
                {
                  component: {
                    edit: 'ExclusiveCheckboxInput',
                    default: 'ExclusiveReadOnlyCheckboxInput'
                  },
                  source: 'bestContact',
                  options: {
                    contact: BestContactType.CELLPHONE,
                    label: 'Preferred',
                    isRequired: false,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        },
        // 6th row: Landline Phone
        {
          options: {
            direction: 'row',
            grow: 1
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    edit: 'TextInput',
                    default: 'FieldWithLabel'
                  },
                  source: 'landlinePhone',
                  options: {
                    label: 'Phone - Landline',
                    isRequired: false,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12,
                justify: 'flex-end'
              },
              contents: [
                {
                  component: {
                    edit: 'ExclusiveCheckboxInput',
                    default: 'ExclusiveReadOnlyCheckboxInput'
                  },
                  source: 'bestContact',
                  options: {
                    contact: BestContactType.LANDLINE,
                    label: 'Preferred',
                    isRequired: false,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'column',
            grow: 1,
            padding: 10
          },
          contents: [
            {
              component: {
                edit: 'NotesWrapper',
                default: 'NotesWrapper'
              },
              source: 'repNotes',
              options: {
                label: 'Notes',
                mode: {
                  default: 'view',
                  edit: 'edit'
                },
                isRequired: false,
                fullWidth: true,
                alignment: 'left'
              }
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      firstName: {
        type: 'string',
        required: true
      },
      lastName: {
        type: 'string',
        nullable: true
      },
      contactRole: {
        type: 'string',
        nullable: true
      },
      email: {
        type: 'string',
        format: 'email',
        nullable: true
      },
      landlinePhone: {
        type: 'string',
        matches: /(\+\d{1,2}\s?-?)??\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/,
        nullable: true
      },
      cellPhone: {
        type: 'string',
        matches: /(\+\d{1,2}\s?-?)??\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/,
        nullable: true
      }
    }
  },
  validationErrors: {
    firstName: {
      required: 'Firstname is required'
    },
    email: {
      email: 'Invalid email'
    },
    landlinePhone: {
      matches: 'Invalid phone number'
    },
    cellPhone: {
      matches: 'Invalid phone number'
    }
  }
});

export const customerPropertyCustomerRepLayout = existingReps => {
  const { fields, layouts, validation, validationErrors } = CustomerRepLayout();
  fields.customerRep = {
    default: 'customerRep',
    edit: 'customerRep'
  };
  layouts.default.contents = [
    // 1st row
    {
      options: {
        direction: 'row',
        grow: 1
      },
      contents: [
        {
          options: {
            direction: 'column',
            grow: 1,
            padding: 12
          },
          contents: [
            {
              component: {
                edit: 'BodyText',
                default: 'BodyText'
              },
              source: 'Choose from representatives assigned to this customer',
              options: {
                label: 'Text Block',
                fullWidth: true,
                alignment: 'left'
              }
            }
          ]
        }
      ]
    },
    // 2nd row
    {
      options: {
        direction: 'row',
        grow: 1
      },
      contents: [
        {
          options: {
            direction: 'column',
            grow: 1,
            padding: 12
          },
          contents: [
            {
              component: {
                default: 'SelectInput'
              },
              source: 'customerRep',
              options: {
                label: 'Choose Representatives',
                inputOptions: existingReps.map(rep => ({ value: rep.id, label: rep.name })),
                // isRequired: true,
                fullWidth: true,
                alignment: 'left',
                isSearchable: true,
                isMultipleSelection: true
              }
            }
          ]
        }
      ]
    },
    // 3rd row
    {
      options: {
        direction: 'row',
        grow: 1
      },
      contents: [
        {
          options: {
            direction: 'column',
            grow: 1,
            padding: 12
          },
          contents: [
            {
              component: {
                edit: 'SeargentSubtitle',
                default: 'SeargentSubtitle'
              },
              source: 'OR',
              options: {
                label: 'Text Block',
                fullWidth: true,
                alignment: 'left'
              }
            }
          ]
        }
      ]
    },
    // 4th row
    {
      options: {
        direction: 'row',
        grow: 1
      },
      contents: [
        {
          options: {
            direction: 'column',
            grow: 1,
            padding: 12
          },
          contents: [
            {
              component: {
                edit: 'BodyText',
                default: 'BodyText'
              },
              source: 'Add new customer representative just for this property',
              options: {
                label: 'Text Block',
                fullWidth: true,
                alignment: 'left'
              }
            }
          ]
        }
      ]
    },
    ...layouts.default.contents
  ];
  validation.properties.firstName = {
    type: 'string',
    when: {
      customerRep: {
        is: val => !val || val.length === 0,
        then: {
          required: true
        }
      }
    }
  };
  return { fields, layouts, validation, validationErrors };
};
