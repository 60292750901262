import gql from 'graphql-tag';

const getJobTypeCurrentCounterJob = gql`
  query getJobTypeCurrentCounterJob($jobTypeId: String!, $partitionKey: String) {
    getJobTypeCounterWithoutIncrement(jobTypeId: $jobTypeId, partitionKey: $partitionKey) {
      currentCounter
    }
  }
`;

export default getJobTypeCurrentCounterJob;
