const styles = theme => ({
  root: {
    backgroundSize: '40px 40px',
    backgroundColor: '#F4F4F4',
    backgroundImage:
      'linear-gradient(to right, lightgrey 1px, transparent 1px),linear-gradient(to bottom, lightgrey 1px, transparent 1px)',
    borderBottom: '1px solid lightgray',
    height: '100%',
    minHeight: 400,
    padding: 0,
    position: 'relative',
    width: '100%'
  },
  title: {
    borderBottom: '2px solid #13809e',
    lineHeight: '30px',
    margin: '0 0 2px'
  },
  toolbar: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '6px 0 12px',
    width: '100%'
  },
  toolbarComponent: {
    display: 'flex',
    flexBasis: '48%'
  },
  board: {
    height: '100%',
    overflow: 'scroll',
    width: '75%',
    padding: '0 30px'
  },
  workspace: {
    backgroundColor: 'white',
    boxShadow: '0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.24)',
    margin: '0 auto',
    padding: '30px 0'
  },
  toolbox: {
    backgroundColor: 'white',
    borderTop: '1px solid lightgray',
    borderRight: '1px solid lightgray',
    borderLeft: '1px solid #13809e',
    height: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '25%'
  },
  toolboxPanelSummary: {
    minHeight: 40,
    padding: '0 6%'
  },
  toolboxPanelDetail: {
    padding: '0 6%'
  },
  tool: {
    backgroundColor: '#F4F4F4',
    border: '1px solid lightgrey',
    borderRadius: 4,
    color: '#13809e',
    cursor: 'pointer',
    float: 'left',
    margin: '1.5%',
    minWidth: 70,
    minHeight: 70,
    overflow: 'hidden',
    paddingBottom: '30%',
    position: ' relative',
    textAlign: 'center',
    width: '30%'
  },
  toolContent: {
    height: '100%',
    left: 0,
    padding: '5%',
    position: 'absolute',
    top: 0,
    width: '100%'
  },
  toolContentWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center'
  },
  toolContentIcon: {
    fontSize: '2em'
  },
  toolContentLabel: {
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: '12px',
    margin: '5px 0 0'
  },
  row: {
    position: 'relative',
    width: '100%',
    '&:hover': {
      borderColor: '#13809e',
      boxShadow: '0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.24)'
    }
  },
  rowDragHandle: {
    background: '#f2f2f2',
    borderLeft: '3px solid #13809e',
    borderRight: '1px solid #f2f2f2',
    cursor: 'move',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    left: 0,
    justifyContent: 'center',
    top: 0,
    position: 'absolute',
    width: 22
  },
  rowToolbar: {
    height: '100%',
    right: 0,
    top: 0,
    position: 'absolute',
  },
  rowControlButtonWrapper: {
    alignItems: 'center',
    background: '#f2f2f2',
    borderLeft: '1px solid #f2f2f2',
    display: 'flex',
    width: 24,
    height: '100%',
    flexDirection: 'column',
    paddingTop: 8,
    textAlign: 'center',
  },
  rowControlButton: {
    cursor: 'pointer',
    height: 30,
    marginBottom: 10,
    minWidth: 30,
    padding: 5
  },
  rowControlIcon: {
    fontSize: '1.5em',
    marginBottom: 10
  },
  rowColumnsContainer: {
    background: 'white',
    display: 'inline-table',
    padding: '4px 24px',
    width: '100%'
  },
  rowEmpty: {
    lineHeight: 0,
    width: '100%'
  },
  rowEmptyColumnsContainer: {
    display: 'inline-flex',
    width: '100%'
  },
  column: {
    display: 'table-cell',
    maxHeight: 0,
    position: 'relative',
    verticalAlign: 'middle'
  },
  columnEmpty: {
    display: 'table-cell',
    position: 'relative'
  },
  emptyColumnPlaceholder: {
    backgroundColor: '#F2F2F2',
    borderRadius: 4,
    bottom: 0,
    left: '5%',
    height: '90%',
    position: 'absolute',
    width: '90%'
  },
  columnAvailable: {},
  columnEmptyAvailable: {},
  columnOver: {},
  columnFull: {
    display: 'table-cell',
    maxHeight: 0,
    position: 'relative'
  },
  columnEmptyFull: {
    display: 'table-cell',
    position: 'relative',
    transition: 'padding 0.1s ease-out',
    padding: 5
  },
  columnAvailableFull: {
    backgroundColor: 'lightblue',
    boxShadow: 'inset 0px 4px 4px rgba(0,0,0,0.5)',
    padding: 5
  },
  columnOverFull: {
    padding: 30
  },
  item: {
    cursor: 'pointer',
    borderRadius: 4,
    padding: '0 12px',
    position: 'relative'
  },
  itemControls: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    right: 12,
    textAlign: 'right',
    height: 30,
    opacity: '0.3',
    padding: 4,
    zIndex: 10,
    '&:hover': {
      opacity: 1
    }
  },
  itemControlButton: {
    minWidth: 35
  },
  itemDeleteButton: {
    color: 'white',
    backgroundColor: theme.palette.error.main
  },
  itemControlIcon: {
    fontSize: '1.5em'
  },
  blankWorkspaceRow: {
    display: 'table',
    width: '100%'
  },
  blankWorkspaceColumn: {
    border: '1px dashed #13809e',
    height: 130,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  blankWorkspaceColumnOver: {
    backgroundColor: 'lightblue',
    height: 130,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dialogContent: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 300,
      minHeight: 240,
      margin: 'auto'
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 696,
      minHeight: 240,
      margin: 'auto'
    }
  },
  sectionHeader: {
    fontSize: 18
  },
  sectionAudit: {
    fontSize: 12,
    color: '#959595',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontStretch: 'normal'
  },
  sectionDivider: {
    height: 2,
    backgroundColor: theme.palette.secondary.main
  }
});

export default styles;
