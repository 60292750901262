import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ModalHeader from './ModalHeader';
import styles from './styles';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const FullScreenModal = ({
  open,
  title,
  iconComponent,
  modalHeaderButtons,
  handleClose,
  onExited,
  classes,
  children,
  fixedHeader,
  headerCenterComponent,
  contentStyle,
  style
}) => (
  <Dialog
    fullScreen
    open={open}
    onClose={handleClose}
    TransitionComponent={Transition}
    onExited={onExited}
  >
    <Grid container direction="column" style={style}>
      <Grid item className={fixedHeader && classes.fixedHeader}>
        <ModalHeader
          title={title}
          iconComponent={iconComponent}
          modalHeaderButtons={modalHeaderButtons}
          classes={classes}
          handleClose={handleClose}
          centerComponent={headerCenterComponent}
          fixedHeader
        />
        <Divider />
      </Grid>
      <Grid
        item
        className={classes.modalContent}
        style={{ paddingTop: fixedHeader ? 60 : 0, ...contentStyle }}
      >
        {children}
      </Grid>
    </Grid>
  </Dialog>
);

FullScreenModal.propTypes = {
  headerCenterComponent: PropTypes.elementType,
  onExited: PropTypes.func,
  contentStyle: PropTypes.object,
  style: PropTypes.object
};

FullScreenModal.defaultProps = {
  headerCenterComponent: undefined,
  onExited: undefined,
  contentStyle: undefined,
  style: undefined
};

export default withStyles(styles)(FullScreenModal);
