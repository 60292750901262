import React, { useReducer, useEffect } from 'react';
import { ThemeProvider, Input } from '@buildhero/sergeant';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import { SergeantModal } from 'components';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ErrorBoundaries from 'scenes/Error';
import { snackbarOn } from 'redux/actions/globalActions';
import { get } from 'lodash';
import { convertStringToFloat } from 'utils';

const ACTION_TYPES = {
  ITEMS: 'STORE_ITEMS',
  CONFIRMATIONMODAL: 'CONFIRMATION_MODAL'
};

const styles = makeStyles(theme => ({
  pageActionButton: {
    marginBottom: theme.spacing(2.5)
  },
  table: {
    minWidth: '100%'
  },
  tableRow: {
    border: 'none'
  },
  imgIcon: {
    height: '100%'
  },
  icon: {
    flexShrink: 1,
    width: 'auto',
    height: 'auto',
    fontSize: '0px',
    background: theme.palette.grayscale(60)
  },
  removeOutline: {
    border: '1px solid',
    borderColor: theme.palette.grayscale(60)
  },
  removeIcon: {
    minHeight: '10px',
    minWidth: '10px',
    padding: 0
  },
  unitPrice: {}
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const initialState = {
  items: [],
  confirmationModal: {
    isOpen: false,
    message: '',
    productId: ''
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.ITEMS:
      return { ...state, items: action.payload };
    case ACTION_TYPES.CONFIRMATIONMODAL:
      return { ...state, confirmationModal: action.payload };
    default:
      return state;
  }
};

function LineItemTable(props) {
  const classes = styles();
  // const { data, deleteProduct, onCompleted, submitStatus, updateOverAllTotal } = props;
  const { data, entityName, tableHeaders, tableFields, handleDelete } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleActions = {
    openConfimationModal: (e, index, item) =>
      dispatch({
        type: ACTION_TYPES.CONFIRMATIONMODAL,
        payload: {
          isOpen: true,
          message: `${item.name} item`,
          productId: item.productId,
          toDelete: item
        }
      }),
    closeConfimationModal: () =>
      dispatch({
        type: ACTION_TYPES.CONFIRMATIONMODAL,
        payload: { isOpen: false, message: ``, productId: '' }
      })
  };

  useEffect(() => {
    if (data) {
      dispatch({ type: ACTION_TYPES.ITEMS, payload: [...data] });
    }
  }, [data]);

  const getTaskEntryField = (taskEntry, column) => {
    // this helper fetches taskEntry[column.fieldName]. if that is null,
    // it returns taskEntry[column.or]. if that is null, it returns 'N/A'.
    // an example of "column" would be { fieldName: 'priceBookEntry.product.name', or: 'name' }.
    const fieldValue = get(taskEntry, column.fieldName);
    const defaultValue = column?.or ? get(taskEntry, column.or) : 'N/A';
    return fieldValue || defaultValue;
  };

  return (
    <>
      <ErrorBoundaries>
        <Table className={classes.table} aria-label="product table">
          <TableHead>
            <TableRow className={classes.tableRow}>
              <StyledTableCell align="left">{tableHeaders.head.label}</StyledTableCell>
              {tableHeaders.middle.map(header => (
                <StyledTableCell align="left">{header.label}</StyledTableCell>
              ))}
              <StyledTableCell align="right">
                <IconButton aria-label="Close" style={{ padding: 0 }} disabled>
                  <DeleteIcon color="primary" style={{ padding: 0 }} />
                </IconButton>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.items.map((item, index) => {
              return (
                <TableRow key={index}>
                  {tableFields.map(field => {
                    return (
                      <>
                        {!field.editable && (
                          <StyledTableCell align="left">
                            {getTaskEntryField(item, field)}
                          </StyledTableCell>
                        )}

                        {field.editable && field.fieldName === 'quantity' && (
                          <StyledTableCell align="left">
                            <ThemeProvider>
                              <Input
                                style={{ width: 52 }}
                                defaultValue={get(item, field.fieldName) || 0}
                                onBlur={e =>
                                  field.onChange(
                                    { quantity: convertStringToFloat(e.target.value) || 0 },
                                    index,
                                    item
                                  )
                                }
                              />
                            </ThemeProvider>
                          </StyledTableCell>
                        )}

                        {field.editable && field.or === 'description' && (
                          <StyledTableCell align="left">
                            <ThemeProvider>
                              <Input
                                multiline
                                defaultValue={item.description ?? get(item, field.fieldName)}
                                style={{ width: 600 }}
                                onBlur={e =>
                                  field.onChange({ description: e.target.value }, index, item)
                                }
                              />
                            </ThemeProvider>
                          </StyledTableCell>
                        )}
                      </>
                    );
                  })}
                  <StyledTableCell align="right">
                    <IconButton
                      aria-label="Close"
                      onClick={e => handleActions.openConfimationModal(e, index, item)}
                      style={{ padding: 0 }}
                    >
                      <DeleteIcon color="primary" style={{ padding: 0 }} />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <SergeantModal
          open={state.confirmationModal.isOpen}
          mode="delete"
          dataType={entityName}
          handlePrimaryAction={() => {
            handleDelete(state.confirmationModal.toDelete);
            handleActions.closeConfimationModal();
          }}
          handleClose={handleActions.closeConfimationModal}
        />
      </ErrorBoundaries>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const ReduxConnectedLineItemTable = connect(mapStateToProps, mapDispatcherToProps)(LineItemTable);

export default ReduxConnectedLineItemTable;
