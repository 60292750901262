import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';
import ProcurementUtils from 'scenes/Procurement/Procurement.utils';
import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';
import { costCodeSearch } from 'services/API/costCode';
import { departmentSearch, getDepartments, getDepartmentsByJob } from 'services/API/department';
import { searchRevenues, searchTypes } from 'services/API/jobCostType';
import { getJcCostTypesByJobJcPhase, getJcPhasesByJob } from 'services/API/jobJcPhaseJcCosttype';
import { getProducts, productSearch } from 'services/API/product';
import { getPhaseDeptCostCodes, getPhaseDeptsByPhase } from 'services/API/projectPhaseDepartment';
import { getPhasesByProject } from 'services/API/projectPhases';

import costTypeOptionsForDropdown from './costTypeOptionsDropdown';

const defaultFlexStyle = '0 0 219px';
const wideItemFlexStyle = '0 0 689px';
const margin = '0px 8px 16px 8px';

const addNewListItemFields = (selectedProjectId, isVistaEnabled) => ({
  Product: { required: false },
  Department: { required: false },
  description: { required: false },
  taxable: { required: false },
  quantity: { required: false },
  unitCost: { required: false },
  costCode: { required: false },
  jobCostType: { required: false },
  revenueType: { required: false },
  totalCost: { required: false },
  item: { required: false },
  jcPhase: { required: Boolean(isVistaEnabled) },
  jcCostType: { required: Boolean(isVistaEnabled) },
  ...(selectedProjectId && {
    projectPhase: { required: true },
    projectCostCode: { required: true },
    projectCostType: { required: true, nullable: true },
    Department: { required: true }
  })
});

const addNewListItemContents = ({
  handleChangeQuantity,
  selectedPhaseId,
  jobOrProjectReadOnly,
  selectedProjectId,
  selectedPhaseDepartmentId,
  projectCostCode,
  isVistaEnabled,
  selectedJobId,
  departmentsAreLimitedByJob,
  selectedEditItem,
  setSelectedEditItem,
  isVistaReceiptSynced
}) => {
  return [
    // the 1st row
    {
      options: {
        direction: 'row',
        flexWrap: 'nowrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'ItemSearchBar'
              },
              source: 'Product',
              options: {
                placeholder: 'Select a Product',
                searchFunction: productSearch,
                emptySearchFunction: getProducts,
                hideOptions: false,
                useId: false,
                label: 'Product Name',
                resultFormatFunction: product => product.name,
                searchColumn: ['name'],
                variant: 'standard',
                color: 'secondary',
                errorText: 'Field is required'
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            selectedProjectId
              ? {
                  component: {
                    edit: 'SearchBar'
                  },
                  source: 'Department',
                  options: {
                    disabled: !selectedPhaseId,
                    label: 'Department',
                    searchFunction: () => getPhaseDeptsByPhase(selectedPhaseId),
                    resultFormatFunction: dept => dept.tagName,
                    placeholder: 'Select a Department',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary',
                    errorText: 'Field is required',
                    searchOnOpen: true,
                    resetDependentDropdownsOnSelection: true,
                    onSelect: (selection, _selectedItemName, form) => {
                      ProcurementUtils.handleDepartmentSelection(
                        selection,
                        _selectedItemName,
                        form,
                        setSelectedEditItem,
                        selectedEditItem
                      );
                    },
                    isRequired: true,
                    setDefaultValue: ProcurementUtils.handleSetDefaultValue
                  }
                }
              : {
                  component: {
                    edit: 'SearchBar'
                  },
                  source: 'Department',
                  options: {
                    label: 'Department',
                    searchFunction:
                      departmentsAreLimitedByJob && selectedJobId
                        ? () => getDepartmentsByJob(selectedJobId)
                        : departmentSearch,
                    emptySearchFunction:
                      departmentsAreLimitedByJob && selectedJobId
                        ? getDepartmentsByJob(selectedJobId)
                        : getDepartments,
                    resultFormatFunction: dept => dept.tagName,
                    placeholder: 'Select a Department',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary',
                    errorText: 'Field is required',
                    resetDependentDropdownsOnSelection: true,
                    searchOnOpen: true,
                    needRefresh: departmentsAreLimitedByJob && selectedJobId,
                    setDefaultValue: ProcurementUtils.handleSetDefaultValue
                  }
                }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            jobOrProjectReadOnly
              ? {
                  component: {
                    edit: 'FieldWithLabel'
                  },
                  source: 'jobOrProjectDisplay',
                  options: {
                    label: 'Job / Project',
                    isRequired: false,
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              : {
                  component: {
                    edit: 'AlgoliaSearch'
                  },
                  source: 'jobOrProjectDisplay',
                  options: {
                    required: false,
                    searchIndexName: defaultSearchIndex,
                    searchResultDisplayFields: ['jobNumber', 'projectName', 'customerName'],
                    valueField: 'id',
                    delimiter: ' - ',
                    label: 'Job / Project',
                    placeholder: 'Search and Select',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary',
                    filters: [
                      {
                        attribute: 'entityType',
                        valueArray: ['Job', 'Project']
                      }
                    ],
                    valuesToSet: [{ jobId: 'id', jobNumber: 'jobNumber' }],
                    onChange: (selection, _selectedItemName, form) => {
                      ProcurementUtils.handleJobOrProjectIdChange(
                        selection,
                        _selectedItemName,
                        form,
                        departmentsAreLimitedByJob,
                        setSelectedEditItem,
                        selectedEditItem
                      );
                    },
                    select: true
                  }
                }
          ]
        }
      ]
    },

    // the 2nd row
    {
      options: {
        direction: 'row',
        flexWrap: 'nowrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: wideItemFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'TextInput'
              },
              source: 'description',
              options: {
                label: 'Description',
                placeholder: 'Enter a description...',
                fullWidth: true,
                lines: 4,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: true
              }
            }
          ]
        }
      ]
    },

    // the 3rd row
    {
      options: {
        direction: 'row',
        flexWrap: 'nowrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'TextInput'
              },
              source: 'quantity',
              options: {
                label: 'Quantity',
                placeholder: '',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: true,
                type: 'numeric',
                endAdornmentText: 'units',
                onChange: handleChangeQuantity
              }
            }
          ]
        },
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'CurrencyInput'
              },
              source: 'unitCost',
              options: {
                allowExcessiveDigits: true,
                label: 'Unit Cost',
                placeholder: '',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: true,
                onChange: ProcurementUtils.handleChangeUnitCost
              }
            }
          ]
        },
        {
          options: {
            direction: 'row',
            flex: defaultFlexStyle,
            alignItems: 'center',
            padding: '18px 0px',
            margin: '0px 8px 16px'
          },
          contents: [
            {
              component: {
                edit: 'CheckboxInput'
              },
              source: 'taxable',
              options: {
                label: 'Taxable'
              }
            }
          ]
        }
      ]
    },

    // the 4th row
    {
      options: {
        direction: 'row',
        flexWrap: 'nowrap'
      },
      contents: [
        selectedProjectId
          ? {
              options: {
                direction: 'column',
                flex: defaultFlexStyle,
                margin
              },
              contents: [
                {
                  component: 'SearchBar',
                  source: 'projectPhase',
                  options: {
                    resultFormatFunction: phase => phase.name,
                    searchFunction: () => getPhasesByProject(selectedProjectId),
                    onSelect: (selection, _selectedItemName, form) => {
                      ProcurementUtils.handlePhaseSelection(
                        selection,
                        _selectedItemName,
                        form,
                        setSelectedEditItem,
                        selectedEditItem
                      );
                    },
                    label: 'Phase',
                    placeholder: 'Select Phase',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary',
                    isRequired: true,
                    resetDependentDropdownsOnSelection: true,
                    searchOnOpen: true
                  }
                }
              ]
            }
          : {
              options: {
                direction: 'column',
                flex: defaultFlexStyle,
                margin
              },
              contents: [
                {
                  component: {
                    edit: 'SearchBar'
                  },
                  source: 'costCode',
                  options: {
                    resultFormatFunction: costCode => `${costCode.description}`,
                    searchFunction: costCodeSearch,
                    searchColumns: ['description'],
                    label: 'Cost Code',
                    placeholder: 'Select Cost Code',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary',
                    isRequired: false,
                    resetDependentDropdownsOnSelection: true,
                    searchOnOpen: true
                  }
                }
              ]
            },
        selectedProjectId
          ? {
              options: {
                direction: 'column',
                flex: defaultFlexStyle,
                margin
              },
              contents: [
                {
                  component: 'SearchBar',
                  source: 'projectCostCode',
                  options: {
                    disabled: !selectedPhaseDepartmentId,
                    resultFormatFunction: costCode => `${costCode.name} - ${costCode.description}`,
                    searchFunction: () => getPhaseDeptCostCodes(selectedPhaseDepartmentId),
                    label: 'Cost Code',
                    placeholder: 'Select Cost Code',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary',
                    isRequired: true,
                    resetDependentDropdownsOnSelection: true,
                    searchOnOpen: true,
                    onSelect: (selection, _selectedItemName, form) => {
                      ProcurementUtils.handleCostCodeSelection(
                        selection,
                        _selectedItemName,
                        form,
                        setSelectedEditItem,
                        selectedEditItem
                      );
                    },
                    setDefaultValue: ProcurementUtils.handleSetDefaultValue
                  }
                }
              ]
            }
          : {
              options: {
                direction: 'column',
                flex: defaultFlexStyle,
                margin
              },
              contents: [
                {
                  component: {
                    edit: 'SearchBar'
                  },
                  source: 'jobCostType',
                  options: {
                    resultFormatFunction: obj => obj.name,
                    searchFunction: searchTypes,
                    searchColumns: ['name'],
                    label: 'Cost Type',
                    placeholder: 'Select Cost Type',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary',
                    isRequired: false,
                    resetDependentDropdownsOnSelection: true,
                    searchOnOpen: true
                  }
                }
              ]
            },
        selectedProjectId
          ? {
              options: {
                direction: 'column',
                flex: defaultFlexStyle,
                margin
              },
              contents: [
                {
                  contents: [
                    {
                      component: 'SelectInput',
                      source: 'projectCostType',
                      options: {
                        label: 'Cost Type',
                        isRequired: true,
                        fullWidth: false,
                        variant: 'standard',
                        color: 'secondary',
                        valueSet: costTypeOptionsForDropdown(projectCostCode)
                      }
                    }
                  ]
                }
              ]
            }
          : {
              options: {
                direction: 'column',
                flex: defaultFlexStyle,
                margin
              },
              contents: [
                {
                  component: {
                    edit: 'SearchBar'
                  },
                  source: 'revenueType',
                  options: {
                    resultFormatFunction: obj => obj.name,
                    searchFunction: searchRevenues,
                    searchColumns: ['name'],
                    label: 'Revenue Type',
                    placeholder: 'Select Type',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary',
                    isRequired: false,
                    resetDependentDropdownsOnSelection: true,
                    searchOnOpen: true
                  }
                }
              ]
            }
      ]
    },

    // the 5th row
    {
      options: {
        direction: 'row',
        flexWrap: 'nowrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'CurrencyInput'
              },
              source: 'totalCost',
              options: {
                label: 'Total Cost',
                placeholder: '',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false,
                type: 'numeric',
                disabled: true,
                endAdornmentText: 'units'
              }
            }
          ]
        }
      ]
    },

    // the 6th row
    ...(isVistaEnabled && !selectedProjectId
      ? [
          {
            options: {
              direction: 'row',
              flexWrap: 'nowrap'
            },
            contents: [
              {
                component: {
                  default: 'HeaderLine'
                }
              }
            ]
          },
          {
            options: {
              direction: 'row',
              flexWrap: 'nowrap'
            },
            contents: [
              {
                options: {
                  label: 'Vista'
                },
                component: {
                  default: 'Title'
                }
              }
            ]
          },
          {
            options: {
              direction: 'row',
              flexWrap: 'nowrap'
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  flex: defaultFlexStyle,
                  margin
                },
                contents: [
                  {
                    component: 'SearchBar',
                    source: 'jcPhase',
                    options: {
                      disabled: isVistaReceiptSynced,
                      isRequired: true,
                      resultFormatFunction: phase => phase?.name,
                      searchFunction: () => getJcPhasesByJob(selectedEditItem?.jobId),
                      filterFunction: results =>
                        Array.from(new Set(results.map(elem => elem.id))).map(jcPhaseId =>
                          results.find(elem => elem.id === jcPhaseId)
                        ),
                      onSelect: (selection, _selectedItemName, form) => {
                        ProcurementUtils.handleSearchSelect(
                          selection,
                          _selectedItemName,
                          form,
                          setSelectedEditItem,
                          selectedEditItem
                        );
                      },
                      label: 'Job Phase',
                      placeholder: 'Select',
                      fullWidth: true,
                      alignment: 'left',
                      variant: 'standard',
                      color: 'secondary',
                      searchOnOpen: true,
                      setDefaultValue: ProcurementUtils.handleSetDefaultValue,
                      resetDependentDropdownsOnSelection: true
                    }
                  }
                ]
              },
              {
                options: {
                  direction: 'column',
                  flex: defaultFlexStyle,
                  margin
                },
                contents: [
                  {
                    component: 'SearchBar',
                    source: 'jcCostType',
                    options: {
                      disabled: !selectedEditItem?.JcPhase || isVistaReceiptSynced,
                      isRequired: true,
                      resultFormatFunction: result => result?.name,
                      searchFunction: () =>
                        getJcCostTypesByJobJcPhase(
                          selectedEditItem?.jobId,
                          selectedEditItem?.JcPhase?.id
                        ),
                      onSelect: ProcurementUtils.handleSearchSelect,
                      label: 'Cost Type',
                      placeholder: 'Select',
                      fullWidth: true,
                      alignment: 'left',
                      variant: 'standard',
                      color: 'secondary',
                      searchOnOpen: true,
                      resetDependentDropdownsOnSelection: true
                    }
                  }
                ]
              }
            ]
          }
        ]
      : [])
  ];
};

const addNewListItemLayout = ({
  handleChangeQuantity,
  selectedPhaseId,
  jobOrProjectReadOnly,
  selectedProjectId,
  selectedPhaseDepartmentId,
  projectCostCode,
  isVistaEnabled,
  selectedJobId,
  departmentsAreLimitedByJob,
  selectedEditItem,
  setSelectedEditItem,
  isVistaReceiptSynced
}) => {
  return {
    fields: generateDefaultFieldsObject(addNewListItemFields(selectedProjectId, isVistaEnabled)),
    layouts: {
      edit: {
        options: {
          pageSize: 'LETTER',
          width: 703
        },
        contents: addNewListItemContents({
          handleChangeQuantity,
          selectedPhaseId,
          jobOrProjectReadOnly,
          selectedProjectId,
          selectedPhaseDepartmentId,
          projectCostCode,
          isVistaEnabled,
          selectedJobId,
          departmentsAreLimitedByJob,
          selectedEditItem,
          setSelectedEditItem,
          isVistaReceiptSynced
        })
      }
    },
    validation: {
      type: 'object',
      properties: {
        Product: { type: 'object' },
        Department: { type: 'object' },
        quantity: { type: 'number' },
        unitCost: { type: 'number' },
        ...(selectedProjectId && {
          projectPhase: { type: 'object' },
          projectCostCode: { type: 'object' },
          projectCostType: { type: 'string' }
        })
      },
      required: [
        ...(selectedProjectId ? ['projectPhase', 'projectCostCode', 'projectCostType'] : []),
        'Product',
        'Department',
        'quantity',
        'unitCost'
      ].filter(Boolean)
    },
    validationErrors: {
      Product: { required: 'Field is required.' },
      Department: { required: 'Field is required.' },
      quantity: { required: 'Field is required.' },
      unitCost: { required: 'Field is required.' },
      projectPhase: { required: 'Field is required.' },
      projectCostCode: { required: 'Field is required.' },
      projectCostType: { required: 'Field is required.' }
    }
  };
};

export { addNewListItemFields, addNewListItemLayout };
