const styles = theme => ({
  dialogContent: {
    [theme.breakpoints.up('md')]: {
      minWidth: 550,
      maxWidth: 550
    }
  },
  dialogContentGrid: {
    marginBottom: 25
  },
  header: {
    marginBottom: 20
  },
  formControlLabel: {
    cursor: 'pointer',
    color: 'black',
    fontWeight: 500,
    fontSize: 16
  },
  visitDescription: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#999999'
  },
  warningIcon: {
    width: '20px',
    fill: theme.palette.other.gold,
    marginRight: '5px',
    paddingTop: '5px'
  }
});

export default styles;
