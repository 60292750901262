import gql from 'graphql-tag';

const quoteUpdateNotification = gql`
  subscription quoteUpdateNotification($partitionKey: String!) {
    quoteUpdateNotification(partitionKey: $partitionKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      tenantId
      hierarchy
      entityType
      version
      name
      quoteNumber
      createdDate
      description
      departmentId
      departmentValue
      serviceAgreementId
      priceBookId
      parentId
      expirationDate
      status
      jobTypeId
      jobTypeValue
      ownerId
      ownerValue
      salesById
      salesByValue
      taxRateId
      taxRateValue
      customerPoNumber
      isActive
      discount
      termsOfService
      rejectedReason
      lastSubmissionDate
      lastUpdatedDateTime
    }
  }
`;

export default quoteUpdateNotification;
