import React from 'react';

import { TV, TW, Typography } from '@buildhero/sergeant';
import { jsx } from '@emotion/react';
import { Box } from '@material-ui/core';

import { Add } from '@material-ui/icons';
import PropTypes from 'prop-types';

const useStyles = () => ({
  addButton: theme => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.support.green.dark
  })
});

const AddButtonText = ({ label, onClick }) => {
  const styles = useStyles();
  return (
    <Box css={styles.addButton} onClick={onClick}>
      <Add />
      <Typography variant={TV.BASE} weight={TW.BOLD}>
        {label}
      </Typography>
    </Box>
  );
};

export default AddButtonText;

AddButtonText.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};
