import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { DAILY_VIEW_HEADERS } from '../../DailyView.constants';

import CurrentTimeTicker from '../CurrentTimeTicker';

import { useStyles } from './DailyViewHeader.styles';

const DailyViewHeader = ({ boardHeight }) => {
  const classes = useStyles();

  return (
    <div className={classes.dailyViewHeader}>
      <CurrentTimeTicker boardHeight={boardHeight} />
      {DAILY_VIEW_HEADERS.map((hour, i) => (
        <div
          key={hour}
          className={classnames(classes.hourHeader, {
            [classes.lastHeader]: DAILY_VIEW_HEADERS.length - 1 === i
          })}
        >
          <div className={classes.headerInner}>{hour}</div>
        </div>
      ))}
    </div>
  );
};

DailyViewHeader.propTypes = {
  boardHeight: PropTypes.number
};

DailyViewHeader.defaultProps = {
  boardHeight: 0
};

export default memo(DailyViewHeader);
