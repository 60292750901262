import React, { forwardRef, useEffect, useRef, useState } from 'react';

import { func, string } from 'prop-types';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

import { TECHS_RESPONSE_PROP } from '@dispatch/queries';

import { useVisitDragLifecycle } from '../../DispatchBoard.hooks';
import { useStyles } from '../../DispatchBoard.styles';
import {
  BOARD_MAN_DAY_ITEM_RESPONSE_PROPS,
  BOARD_NON_VISIT_EVENT_RESPONSE_PROP,
  BOARD_VISIT_RESPONSE_PROP
} from '../../queries';
import TechsFilterButton from '../TechsFilterButton';

import WeekSwimLane from './components/WeekSwimLane';
import WeekViewHeader from './components/WeekViewHeader';
import { WeekViewContext } from './WeekView.context';
import { useItemData, useVisitDrop } from './WeekView.hooks';
import { selectLaneHeight } from './WeekView.selectors';

const BoardFilterContainer = () => {
  const classes = useStyles();

  return (
    <div className={classes.boardFilterContainer}>
      <TechsFilterButton />
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const innerElementType = forwardRef(({ children, style, ...rest }, ref) => (
  <div ref={ref} style={{ ...style, width: '100%' }} {...rest}>
    <BoardFilterContainer />
    <WeekViewHeader />
    {children}
  </div>
));

const WeekView = ({
  techsResponse,
  boardVisitsResponse,
  boardNonVisitEventsResponse,
  boardManDayItemsResponse,
  startDay,
  filterBy,
  customWeekStartDay
}) => {
  const listRef = useRef();
  const [activeCell, setActiveCell] = useState(null);
  const [collected, dropRef] = useVisitDrop();

  const itemData = useItemData({
    techsResponse,
    boardVisitsResponse,
    boardNonVisitEventsResponse,
    boardManDayItemsResponse,
    startDay,
    customWeekStartDay
  });

  useEffect(() => {
    listRef.current?.resetAfterIndex(activeCell?.laneIndex || 0);
  }, [itemData, activeCell]);

  useVisitDragLifecycle({ ...collected, filterBy });

  return (
    <WeekViewContext.Provider value={{ setActiveCell }}>
      <AutoSizer>
        {({ height, width }) => (
          <VariableSizeList
            height={height}
            innerElementType={innerElementType}
            itemCount={itemData.length}
            itemData={itemData}
            itemKey={i => itemData[i]?.tech?.id || i}
            itemSize={i => selectLaneHeight(i, itemData, activeCell)}
            outerRef={dropRef}
            overscanCount={3}
            ref={listRef}
            width={width}
          >
            {WeekSwimLane}
          </VariableSizeList>
        )}
      </AutoSizer>
    </WeekViewContext.Provider>
  );
};

WeekView.propTypes = {
  techsResponse: TECHS_RESPONSE_PROP.isRequired,
  boardVisitsResponse: BOARD_VISIT_RESPONSE_PROP.isRequired,
  boardNonVisitEventsResponse: BOARD_NON_VISIT_EVENT_RESPONSE_PROP.isRequired,
  boardManDayItemsResponse: BOARD_MAN_DAY_ITEM_RESPONSE_PROPS.isRequired,
  // eslint-disable-next-line react/require-default-props
  startDay: string,
  filterBy: func.isRequired,
  // eslint-disable-next-line react/require-default-props
  customWeekStartDay: string
};

export default WeekView;
