import gql from 'graphql-tag';

const SyncItemGlGroups = gql`
  mutation syncItemGlGroups($tenantId: String!, $data: SyncItemGlGroupInput!) {
    syncItemGlGroups(tenantId: $tenantId, data: $data) {
      id
      name
    }
  }
`;

export default SyncItemGlGroups;
