const buildHeroMuiFormOverrides = theme => {
  return {
    width: '100%',
    '& .MuiInputBase-input': {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: '-0.02em'
    },
    '& .MuiTypography-caption': {
      color: theme.palette.grayscale(60),
      fontWeight: 400
    },
    '& .placeholder': {
      color: theme.palette.grayscale(60)
    },
    '& .MuiTypography-body1, .MuiTypography-body2': {
      fontSize: 14
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 12
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.grayscale(94),
      minHeight: 41,
      '& fieldset': {
        borderColor: theme.palette.grayscale(100),
        border: 'none'
      },
      '&:hover fieldset': {
        borderBottom: `2px solid ${theme.palette.grayscale(20)}`
      },
      '&.Mui-focused fieldset': {
        borderBottom: '2px solid #00874d'
      },
      '&.Mui-error': {
        borderBottom: '2px solid #990000'
      }
    },
    '& [class*="RawSelectInput-input"]': {
      minHeight: 25
    },
    '& [class*="RawSelectInput-disabled"]': {
      borderBottom: `1mm dotted ${theme.palette.grayscale(50)}`,
      boxSizing: 'border-box'
    },
    '& .Mui-error [class*="RawSelectInput-disabled"]': {
      border: 'none'
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.grayscale(60),
      fontSize: 16
    }
  };
};

export default buildHeroMuiFormOverrides;
