import { gql } from '@apollo/client/core';
import moment from 'moment';
import { arrayOf, bool, shape } from 'prop-types';

import { ERROR_PROP } from '@dispatch/Dispatch.constants';
import { watchedQueries } from '@dispatch/Dispatch.store';
import { visitDetailsFragment } from '@dispatch/fragments';
import { transformVisitDetails, VISIT_DETAILS_PROP } from '@dispatch/queries';
import { VISIT_SUBSCRIPTION } from '@dispatch/subscription';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import { getState } from 'redux/store';

import {
  selectEndTime,
  selectStartTime,
  selectVisitWidth,
  selectVisitXPosition
} from '../DispatchBoard.selectors';
import { getPollInterval } from '../DispatchBoard.utils';

const GET_DISPATCH_BOARD_VISITS = gql`
    query getDispatchBoardVisits(
        $partitionKey: String!
        $sortKey: String!
        $startTime: Int!
        $endTime: Int!
    ) {
        scheduledVisits: getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
            id
            visitsInRange: visitsView(
                entityConnection: "Visit"
                pagination: { limit: 10000 }
                filter: {
                    integerFilters: [
                        {
                            fieldName: "Visit.scheduledFor"
                            filterInput: { between: [$startTime, $endTime] }
                        }
                    ]
                }
            ) {
                items {
                    ${visitDetailsFragment}
                }
            }
        }
    }
`;

const DEFAULT_DATA = [];

// format to match VISIT_DETAILS_PROP
export const transformBoardVisits = data => {
  const visits = data?.scheduledVisits?.visitsInRange?.items;

  if (!visits) return DEFAULT_DATA;

  return visits.map(visit => {
    const transformedVisit = transformVisitDetails({ visit });

    return {
      ...transformedVisit,
      left: selectVisitXPosition(transformedVisit),
      width: selectVisitWidth(transformedVisit)
    };
  });
};

export const useDispatchBoardVisits = ({ day, endDay, mapView, weekView, customWeekStartDay }) => {
  const { tenantId: partitionKey, tenantCompanyId } = getState().user;
  const sortKey = `${partitionKey}_Company_${tenantCompanyId}`;

  const startTime = selectStartTime({ day, weekView, customWeekStartDay });
  const endTime = selectEndTime({ day, endDay, mapView, weekView, customWeekStartDay });

  const queryVariables = {
    partitionKey,
    sortKey,
    startTime,
    endTime
  };

  watchedQueries.useDispatchBoardVisits = {
    query: GET_DISPATCH_BOARD_VISITS,
    variables: queryVariables
  };

  return useExtendedQuery(GET_DISPATCH_BOARD_VISITS, {
    // pollInterval: getPollInterval(),
    transform: transformBoardVisits,
    defaultData: DEFAULT_DATA,
    variables: queryVariables,
    subscribeToMore: {
      document: VISIT_SUBSCRIPTION,
      variables: { partitionKey },
      updateQuery: (prev, { subscriptionData, variables }) => {
        if (!subscriptionData.data) return prev;

        const updatedVisit = subscriptionData.data.visitUpdateNotification;

        const isInRange = moment
          .unix(updatedVisit.scheduledFor)
          // fourth parameter dictates that start and end day are included
          .isBetween(moment.unix(variables.startTime), moment.unix(variables.endTime), 'day', '[]');

        const prevVisits = prev?.scheduledVisits?.visitsInRange?.items || [];

        const newItems = isInRange
          ? [updatedVisit, ...prevVisits.filter(visit => visit.id !== updatedVisit.id)]
          : [...prevVisits.filter(visit => visit.id !== updatedVisit.id)];

        return {
          ...prev,
          scheduledVisits: {
            ...prev.scheduledVisits,
            visitsInRange: {
              ...prev.scheduledVisits.visitsInRange,
              items: newItems
            }
          }
        };
      }
    }
  });
};

export const BOARD_VISIT_RESPONSE_PROP = shape({
  error: ERROR_PROP,
  loading: bool,
  data: arrayOf(VISIT_DETAILS_PROP)
});
