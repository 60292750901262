const wipReportSettingsLayout = (glAccountOptions = [], classOptions = []) => ({
  fields: {},
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        // Over Billings Section
        {
          options: {
            direction: 'row',
            width: '100%'
          },
          contents: [
            {
              options: {
                direction: 'column'
              },
              contents: [
                {
                  component: 'SectionTitle',
                  options: {
                    label: 'Over Billings'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row',
            width: 700
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: 'SelectInput',
                  source: 'overBillingGlAccountId',
                  options: {
                    label: 'G.L. Account',
                    placeholder: 'Search G.L. Accounts...',
                    isRequired: true,
                    isSearchable: true,
                    inputOptions: glAccountOptions
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: 'SelectInput',
                  source: 'overBillingGlOffsetAccountId',
                  options: {
                    label: 'G.L. OFFSET ACCOUNT',
                    placeholder: 'Search G.L. Accounts...',
                    isRequired: true,
                    isSearchable: true,
                    inputOptions: glAccountOptions
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row',
            width: '100%'
          },
          contents: [
            {
              options: {
                direction: 'column'
              },
              contents: [
                {
                  component: 'Divider'
                }
              ]
            }
          ]
        },
        // Under Billings Section
        {
          options: {
            direction: 'row',
            width: '100%'
          },
          contents: [
            {
              options: {
                direction: 'column'
              },
              contents: [
                {
                  component: 'SectionTitle',
                  options: {
                    label: 'Under Billings'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row',
            width: 700
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: 'SelectInput',
                  source: 'underBillingGlAccountId',
                  options: {
                    label: 'G.L. Account',
                    placeholder: 'Search G.L. Accounts...',
                    isRequired: true,
                    isSearchable: true,
                    inputOptions: glAccountOptions
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: 'SelectInput',
                  source: 'underBillingGlOffsetAccountId',
                  options: {
                    label: 'G.L. OFFSET ACCOUNT',
                    placeholder: 'Search G.L. Accounts...',
                    isRequired: true,
                    isSearchable: true,
                    inputOptions: glAccountOptions
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            direction: 'row',
            width: '100%'
          },
          contents: [
            {
              options: {
                direction: 'column'
              },
              contents: [
                {
                  component: 'Divider'
                }
              ]
            }
          ]
        },
        // Class Section
        {
          options: {
            direction: 'row',
            width: 350
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: 'SelectInput',
                  source: 'accountingClassId',
                  options: {
                    label: 'Class',
                    placeholder: 'Select Class...',
                    isRequired: true,
                    isSearchable: true,
                    inputOptions: classOptions
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  }
});

export default wipReportSettingsLayout;
