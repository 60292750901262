/* eslint-disable indent */
/* eslint-disable default-case */
import React, { useState } from 'react';
import { ThemeProvider, Modal as SgtModal, Typography } from '@buildhero/sergeant';
import { connect } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import { CommonService } from 'services/core';
import { Button } from '@material-ui/core';
import { sortBy } from 'lodash';
import { Logger } from 'services/Logger';
import ErrorBoundaries from 'scenes/Error';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PermissionConstants } from 'utils/AppConstants';
import {
  UserPermission,
  ResponsiveTable,
  SergeantModal,
  Modal,
  OCRModal,
  ImageUpload
} from 'components';
import { Mode } from 'utils/constants';
import { PropertyAssetLayout } from 'meta/Customer/PropertyAsset';
import { snackbarOn } from 'redux/actions/globalActions';
import { getUniqueName, logErrorWithCallback, checkPermission } from 'utils';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import Context from 'components/Context';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import LinkFieldWithLabel from 'scenes/Maintenance/DetailView/common/LinkFieldWithLabel';
import OCRDataDisplay from 'components/OCRDataDisplay';
import AmplifyService from 'services/AmplifyService';
import StorageService from 'services/StorageService';
import {
  addPropertyAssetsToCustomerProperty,
  deletePropertyAssetById,
  getAssetsByCustomerPropertyById,
  getPropertyAssetByIdWithAssociatedTasks,
  updatePropertyAsset
} from 'scenes/Customer/Assets/gql';
import propertyAssetColumns from './meta/columns';
import { assetPayload } from '../helpers';

const PropertyAssetList = ({ customerProperty, isActive, ocrOptions, snackbar, user }) => {
  const launchDarklyFlags = useFlags();
  const assetTypes = Context.getCompanyContext()?.getCompany?.assetTypes;
  const assetTypeOptions = constructSelectOptions(
    sortBy(assetTypes?.items, 'sortOrder'),
    'tagName'
  );
  // OCR
  const [ocrModalOpen, setOcrModalOpen] = useState(false);
  const commonService = new CommonService();

  const [deleteAssetWarningModalOpen, setDeleteAssetWarningModalOpen] = useState(false);

  const [modal, setModal] = useState({ open: false, mode: Mode.ADD });

  const { data: rawAssets, error: queryError, refetch, loading } = useQuery(
    getAssetsByCustomerPropertyById,
    {
      variables: { id: customerProperty.id },
      fetchPolicy: 'network-only'
    }
  );
  const [addAsset, { loading: addMutationLoading }] = useMutation(
    addPropertyAssetsToCustomerProperty
  );

  const [updateAsset, { loading: updateMutationLoading, error: mutateError }] = useMutation(
    updatePropertyAsset
  );

  const [deleteAsset, { loading: deleteMutationLoading, error: deleteError }] = useMutation(
    deletePropertyAssetById
  );

  let assets = rawAssets?.getCustomerPropertyById?.propertyAssets?.items?.filter(
    asset => asset.isActive
  );

  // same meta is used in asset detail and sgl modal, hence to support both adding this below key
  assets = assets?.map(asset => {
    const localAsset = { ...asset };
    localAsset.serviceAgreementDisplays = {
      labelArray: localAsset.serviceAgreements.map(sa => sa.agreementName),
      linkPathArray: localAsset.serviceAgreements.map(sa => `/serviceAgreement/view/${sa.id}`)
    };
    return localAsset;
  });

  const errorExists = queryError || mutateError || deleteError;

  if (errorExists) {
    logErrorWithCallback(queryError, snackbar, `Unable to ${queryError ? 'query' : 'save'} assets`);
  }

  const defaultData = {
    imageUrl: '',
    assetName: '',
    make: '',
    modelNumber: '',
    serialNo: '',
    installDate: moment().unix(),
    location: '',
    propertyZone: ''
  };

  const handleModalOpen = async (mode, data = defaultData) => {
    const modalData = {
      ...data,
      property: {
        companyName: customerProperty.companyName,
        customer: customerProperty.parentEntity
      }
    };
    if (mode === Mode.DELETE) {
      const appsyncClient = await AmplifyService.appSyncClient();
      try {
        const response = await appsyncClient.client.query({
          query: getPropertyAssetByIdWithAssociatedTasks,
          variables: { id: data.id }
        });

        const { jobs, quotes, serviceAgreements } = response.data.getPropertyAssetById;
        if (jobs.length > 0 || quotes.length > 0 || serviceAgreements.length > 0) {
          setModal({ deleteItemLabel: data?.assetName });
          setDeleteAssetWarningModalOpen(true);
        } else {
          setModal({ open: true, mode, data: modalData, deleteItemLabel: data?.assetName });
        }
      } catch (error) {
        Logger.error(error);
      }
    } else {
      setModal({ open: true, mode, data: modalData });
    }
  };
  const handleModalClose = () => {
    setModal({ ...modal, open: false });
  };

  const addRow = isActive
    ? {
        label: 'Add Asset',
        caslKey: PermissionConstants.OBJECT_ASSET,
        handleAdd: () => handleModalOpen(Mode.ADD)
      }
    : null;

  const rowButtons = isActive
    ? {
        edit: {
          label: 'Edit',
          caslAction: 'update',
          caslKey: PermissionConstants.OBJECT_ASSET
        },
        delete: {
          label: 'Remove',
          caslAction: 'delete',
          caslKey: PermissionConstants.OBJECT_ASSET
        }
      }
    : null;

  const LogoButton = ({ form, field }) => (
    <ImageUpload form={form} field={field} user={user} label="Add Image" />
  );

  const ocrButton = ocrOptions?.items?.length
    ? ({ field }) => (
        <>
          <Button style={{ width: 250 }} onClick={() => setOcrModalOpen(true)}>
            Scan Property Attachment for Text
          </Button>
          {field.value.ocrData && <OCRDataDisplay dataOCRInput={field.value.ocrData} />}
        </>
      )
    : () => null;

  const handlePrimaryAction = async (newData, modalCallback) => {
    let action;
    const { mode } = modal;
    const payload = assetPayload(newData);
    switch (mode) {
      case Mode.ADD:
        action = async () => {
          await addAsset({
            variables: {
              data: {
                customerPropertyId: customerProperty.id,
                propertyAssets: [{ ...payload }]
              }
            }
          });
          await refetch();
        };
        break;
      case Mode.EDIT: {
        action = async () => {
          await updateAsset({
            variables: {
              data: {
                ...payload
              }
            }
          });
          await refetch();
        };
        break;
      }
      case Mode.DELETE: {
        action = async () => {
          await deleteAsset({ variables: { partitionKey: user.tenantId, id: newData.id } });
          await refetch();
        };
      }
    }

    try {
      await action();
      snackbar('success', `Successfully ${Mode.past(mode)} Asset`);
      await refetch();
      handleModalClose();
    } catch (error) {
      logErrorWithCallback(error, snackbar, `Unable to ${mode} Asset, please try again later`);
    } finally {
      modalCallback();
    }
  };

  const handleOcrSelection = async (imageUrl, selectedFile, originalImageUrl) => {
    // Copied from PageForm
    const uniqueName = getUniqueName(user.tenantId, selectedFile.fileName);
    setModal({
      ...modal,
      data: { ...modal.data, ocrData: { imageURL: imageUrl, loading: true } }
    });
    setOcrModalOpen(false);
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const storageService = new StorageService();
      const s3Key = await storageService.uploadFile(blob, uniqueName, null, null);
      const fileUrl = await storageService.getFile(s3Key);
      const responseData = await commonService.getTextFromDocument(fileUrl);
      const { data } = JSON.parse(responseData.data.getTextFromDocument);
      setModal({
        ...modal,
        data: {
          ...modal.data,
          attachments: [
            {
              comment: selectedFile.comment,
              customFileName: selectedFile.customFileName,
              fileName: selectedFile.fileName,
              fileUrl: selectedFile.fileUrl
            }
          ],
          ocrData: { imageURL: imageUrl, loading: false, data: data[0] }
        }
      });
    } catch (error) {
      Logger.error(`Ocr failed: ${JSON.stringify(error)}`);
      snackbar('error', 'Failed to scan for text, please try again later', error);
      setModal({ ...modal, data: { ...modal.data, ocrData: null } });
    } finally {
      try {
        // TODO: Delete cloudinary images
      } catch (error) {
        Logger.error(`Ocr file cleanup failed for (${uniqueName}): ${JSON.stringify(error)}`);
      }
    }
  };

  const hasServiceAgreements = checkPermission(
    'allow',
    PermissionConstants.OBJECT_SERVICE_AGREEMENT,
    user,
    null,
    null,
    FeatureFlags.SERVICE_AGREEMENTS,
    launchDarklyFlags
  );

  return (
    <ErrorBoundaries>
      <UserPermission I="read" action={PermissionConstants.OBJECT_ASSET}>
        <ResponsiveTable
          isLoading={
            loading || addMutationLoading || updateMutationLoading || deleteMutationLoading
          }
          rowMetadata={propertyAssetColumns}
          caslKey={PermissionConstants.OBJECT_ASSET}
          noDataMsg="No Assets"
          data={assets || []}
          rowActionButtons={rowButtons}
          rowActions={handleModalOpen}
          addRow={addRow}
          noEmptyRows
        />

        <SergeantModal
          open={modal.open}
          data={modal.data}
          dataType="Asset"
          mode={modal.mode}
          confirmRemoveItemLabel={modal.deleteItemLabel}
          layout={PropertyAssetLayout(assetTypeOptions, true, hasServiceAgreements)}
          customComponents={{
            LogoButton,
            OcrButton: ocrButton,
            LinkFieldWithLabel
          }}
          handlePrimaryAction={handlePrimaryAction}
          handleClose={handleModalClose}
        />

        <ThemeProvider>
          <SgtModal
            open={deleteAssetWarningModalOpen}
            title={modal.deleteItemLabel}
            onClose={() => setDeleteAssetWarningModalOpen(false)}
          >
            <Typography>
              This asset has active work associations on a Service Agreement, Maintenance, or Job
              and therefore cannot be deleted.
            </Typography>
          </SgtModal>
        </ThemeProvider>

        <Modal open={ocrModalOpen} handleClose={() => setOcrModalOpen(false)}>
          <OCRModal optionList={ocrOptions} selectionHandler={handleOcrSelection} />
        </Modal>
      </UserPermission>
    </ErrorBoundaries>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbar: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const connectedPropertyAssetList = connect(
  mapStateToProps,
  mapDispatcherToProps
)(PropertyAssetList);

export default connectedPropertyAssetList;
