import { useExtendedFetchForPMAPI as useExtendedFetch } from 'customHooks/useExtendedFetch';

export const useGetPurchaseOrderLines = (options = {}, params) => {
  return useExtendedFetch(
    {
      url: `purchaseorderlines`,
      params
    },
    {
      errorData: [],
      defaultData: [],
      ...options
    }
  );
};
