import React from 'react';
import * as R from 'ramda';
import { Text, View } from '@react-pdf/renderer';
import { convertToCurrencyString } from 'utils';
import styles from './ChangeOrderPDF.styles';
import { nonTaxablePercents, percentLabelMapping } from './ChangeOrderPDF.constants';

export const getCostSectionView = ({ label, cost }) => (
  <View style={styles.displayFlexColumn}>
    <View style={styles.displayFlex}>
      <Text style={styles.sectionLabel}>{label}</Text>
      <Text style={styles.sectionLabel}>{convertToCurrencyString(cost)}</Text>
    </View>
    <View style={styles.divider} />
  </View>
);

export const getItemTablesDetailView = typeData => [
  <View style={styles.displayFlexColumn}>
    <Text style={[styles.sectionTitle, { marginBottom: 8 }]}>{typeData.label}</Text>
    <View style={styles.displayFlex}>
      <Text style={[styles.labelNormal, { width: '40%' }]}>Description</Text>
      <Text style={[styles.labelNormal, { width: '15%' }]}>Taxable</Text>
      <Text style={[styles.labelNormal, { width: '15%', textAlign: 'right' }]}>Quantity</Text>
      <Text style={[styles.labelNormal, { width: '15%', textAlign: 'right' }]}>Sell Price</Text>
      <Text style={[styles.labelNormal, { width: '15%', textAlign: 'right' }]}>Extension</Text>
    </View>
    {typeData.items?.map(item => (
      <View style={styles.displayFlex}>
        <Text style={[styles.text, { width: '40%' }]}>{item.description}</Text>
        <Text style={[styles.text, { width: '15%' }]}>{item.taxable ? 'Yes' : 'No'}</Text>
        <Text style={[styles.text, { width: '15%', textAlign: 'right' }]}>{item.quantity}</Text>
        <Text style={[styles.text, { width: '15%', textAlign: 'right' }]}>
          {convertToCurrencyString(item.sellPrice)}
        </Text>
        <Text style={[styles.text, { width: '15%', textAlign: 'right' }]}>
          {convertToCurrencyString(item.subtotal + item.taxAmount)}
        </Text>
      </View>
    ))}
    {typeData.items && (
      <View style={[styles.displayFlex, { flexDirection: 'row-reverse' }]}>
        <Text
          style={[
            styles.text,
            styles.textBold,
            {
              width: '12%',
              textAlign: 'right',
              paddingTop: 4,
              borderTop: 1,
              borderColor: 'black'
            }
          ]}
        >
          {convertToCurrencyString(typeData.total)}
        </Text>
      </View>
    )}
  </View>,
  <View style={styles.divider} />
];

export const getTotalsLineItem = ({ label, value, percent }) => (
  <View style={[styles.displayFlexColumn, { alignItems: 'flex-end' }]}>
    {!percent && label !== 'Subtotal' && <View style={styles.totalLinesDivider} />}
    <View style={[styles.displayFlex, { justifyContent: 'flex-end' }]}>
      <Text style={[styles.totalsLineItemText]}>
        {label}
        {percent && ` ${percent}%`}
      </Text>
      <Text style={[styles.totalsLineItemText]}>{convertToCurrencyString(value)}</Text>
    </View>
  </View>
);

export const getSubTotalsLines = subTotals =>
  subTotals.map(subtotalSection => {
    const { sectionSubtotal, label, taxAmount, taxPercent } = subtotalSection;
    const lines = [getTotalsLineItem({ label, value: sectionSubtotal })];
    if (taxAmount > 0)
      lines.push(getTotalsLineItem({ label: 'Tax', value: taxAmount, percent: taxPercent }));

    R.pipe(
      R.pick(nonTaxablePercents),
      R.keys(),
      R.filter(percent => subtotalSection[percent] > 0),
      R.map(key =>
        lines.push(
          getTotalsLineItem({
            label: percentLabelMapping[key],
            value: subtotalSection[key] * sectionSubtotal * 0.01,
            percent: subtotalSection[key]
          })
        )
      )
    )(subtotalSection);

    return lines;
  });

export const getFieldWithTitle = (label, value, containerStyles = styles.marginBottom6) => {
  if (typeof value !== 'string') console.log(label, value);
  return (
    <View style={[styles.displayFlexColumn, containerStyles]}>
      <Text style={styles.labelNormal}>{label}</Text>
      <Text style={styles.text}>{value}</Text>
    </View>
  );
};
