import gql from 'graphql-tag';

const updateCompany = gql`
  mutation updateCompany($partitionKey: String, $data: UpdateCompanyInput) {
    updateCompany(partitionKey: $partitionKey, data: $data) {
      id
      version
    }
  }
`;

export default updateCompany;
