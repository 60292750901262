/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { memo } from 'react';
import { bool, arrayOf, object } from 'prop-types';

import { ColDef } from '../WrapTable.types';
import { LoadingColumn } from '../Column';
import TotalColumn from '../TotalColumn';

import { useHeaderColumns } from '../WrapTable.hooks';
import { useRowStyles } from '../Row/Row.styles';

const TotalsRow = ({
  rows,
  columns,
  scrollX,
  noWrap,
  lockRowHeight,
  isFirstLoad,
  rowGroupingModel,
  rowGroupingColumnMode
}) => {
  const styles = useRowStyles();

  const rowColumns = useHeaderColumns({
    columns,
    rowGroupingModel,
    rowGroupingColumnMode
  });

  return (
    <div css={styles.totalsRow}>
      {rowColumns.map((colDef, i) =>
        isFirstLoad ? (
          <LoadingColumn
            flex={colDef.flex}
            minWidth={colDef.minWidth}
            maxWidth={colDef.maxWidth}
            width={colDef.width}
            scrollX={scrollX}
          />
        ) : (
          <TotalColumn
            key={colDef.field}
            isFirstColumn={i === 0}
            {...{ scrollX, noWrap, colDef, lockRowHeight, rows }}
          />
        )
      )}
    </div>
  );
};

TotalsRow.propTypes = {
  row: object.isRequired,
  columns: arrayOf(ColDef).isRequired,
  scrollX: bool,
  noWrap: bool,
  lockRowHeight: bool
};

TotalsRow.defaultProps = {
  scrollX: false,
  noWrap: false,
  lockRowHeight: false
};

export default memo(TotalsRow);
