import * as React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const GET_ALL_PRODUCTS = gql`
  query getProductsList(
    $tenantId: String
    $filter: TableFilterInput
    $pagination: PaginationInput
    $sorting: [TableSortingInput]
  ) {
    data: getProductsList(
      tenantId: $tenantId
      filter: $filter
      pagination: $pagination
      sorting: $sorting
    ) {
      rowCount
      items
    }
  }
`;

export const useProductListQuery = (tenantId, filter, sorting, queryVariables) => {
  const {
    data: { data: { rowCount, items: rows } } = { data: {} },
    loading,
    error,
    refetch
  } = useQuery(GET_ALL_PRODUCTS, {
    variables: {
      tenantId,
      filter,
      sorting,
      ...queryVariables
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  return { rowCount, rows, loading, error, refetch };
};
