import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  wrapper: { width: 'auto', minWidth: '350px', flexWrap: 'nowrap' },
  selectionContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  avatar: {
    textTransform: 'uppercase',
    background: theme.palette.grayscale(40)
  },
  selectionInput: {
    padding: theme.spacing(1),
    '& p': {
      fontSize: '20px',
      marginLeft: '5px',
      color: theme.palette.text.primary
    }
  },
  selectionWrapper: {
    backgroundColor: `${theme.palette.grayscale(100)} !important`,
    padding: 0,
    margin: theme.spacing(0.5)
  },
  selectionInputPlaceholder: {
    '& label': {
      fontSize: '20px',
      color: theme.palette.text.primary
    }
  }
}));
