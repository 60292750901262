import gql from 'graphql-tag';
import useExtendedMutation from 'customHooks/useExtendedMutation';

const UPDATE_ATTACHMENT = gql`
  mutation updateAttachment($partitionKey: String!, $data: UpdateAttachmentInput!) {
    updateAttachment(partitionKey: $partitionKey, data: $data) {
      id
      customFileName
      fileName
      fileUrl
      type
      comment
      addedBy
      addedDateTime
      createdBy
      createdDateTime
      includeInInvoice
      hideFromTechniciansOnMobile
      version
    }
  }
`;

const serializer = ({ tenantId, attachment }) => ({
  variables: {
    partitionKey: tenantId,
    data: {
      ...attachment
    }
  }
});

export const useUpdateAttachment = () => {
  return useExtendedMutation(UPDATE_ATTACHMENT, {
    serializer
  });
};
