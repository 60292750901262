import { useCallback, useMemo, useRef, useState } from 'react';
import { VisitStatuses } from '@dispatch/Dispatch.constants';

const patchPropertyVisit = ({ visit, visitStatuses, techMap, propertyMap }) => {
  if (!visit.Status.showOnMap) return;
  if (visitStatuses && !visitStatuses.includes(visit.status)) return;
  if (
    [visit.primaryTechId, ...visit.extraTechs].some(techId => techMap[techId]) ||
    visit.status === VisitStatuses.UNASSIGNED.key
  ) {
    // eslint-disable-next-line no-param-reassign
    propertyMap[visit.property.id] = {
      ...visit.property,
      visits: [...(propertyMap[visit.property.id]?.visits || []), visit]
    };
  }
};

// Without sorting google maps confuses pin focus on visit mutations
const sortById = (a, b) => {
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  return 0;
};

export const useMapProperties = ({
  boardVisitsResponse,
  techsResponse,
  unscheduledVisitsResponse,
  visitStatuses,
  showVisits
}) => {
  return useMemo(() => {
    if (!showVisits) return [];

    const techMap = techsResponse.data.reduce((map, tech) => ({ ...map, [tech.id]: true }), {});
    const propertyMap = {};

    boardVisitsResponse.data.forEach(visit =>
      patchPropertyVisit({ visit, visitStatuses, techMap, propertyMap })
    );

    unscheduledVisitsResponse.data.forEach(visit =>
      patchPropertyVisit({ visit, visitStatuses, techMap, propertyMap })
    );

    return Object.values(propertyMap).sort(sortById);
  }, [boardVisitsResponse, unscheduledVisitsResponse, techsResponse, visitStatuses, showVisits]);
};

export const useExtendableBounds = () => {
  const { LatLngBounds } = google.maps;
  const mapRef = useRef();
  const buffer = useRef(new LatLngBounds());

  const flush = () => {
    const { current: map } = mapRef;
    const { current: incoming } = buffer;

    // Nothing to do
    if (incoming.isEmpty() || !map) return;

    const displayed = map.getBounds();
    // If we catch `displayed` in exactly the right moment it's sometimes
    // still undefined
    if (!displayed) return;

    if (
      // fitBounds() will zoom out even if the new bounds are entirely within
      // the current ones, so we check ourselves and bail
      displayed.contains(incoming.getNorthEast()) &&
      displayed.contains(incoming.getSouthWest())
    ) {
      return;
    }

    // Write our new, combined bounds to the map
    map.fitBounds(displayed.union(incoming));

    // Clear our buffer, otherwise visits removed during our calling component's
    // lifetime will stick around forever, possibly keeping the map bounds too
    // big.
    buffer.current = new LatLngBounds();
  };

  // Collect bounds we want to extend to in a buffer and try to flush; if our
  // flush is unsuccessful because the map isn't initialized yet, onMapLoad will
  // take care of it.
  const extendBounds = latLng => {
    buffer.current.extend(latLng);
    flush();
  };

  const onMapLoad = map => {
    mapRef.current = map;
    flush();
  };

  return { extendBounds, onMapLoad };
};

export const useGeocoder = () => {
  const { Geocoder, GeocoderStatus } = google.maps;
  const cache = useRef({});

  const { current: geocoder } = useRef(new Geocoder());
  const [loading, setLoading] = useState(false);

  const geocode = useCallback(
    (address, callback) => {
      if (!address) return;
      if (cache.current[address]) {
        callback(cache.current[address]);
        return;
      }

      setLoading(true);

      geocoder.geocode({ address }, (results, status) => {
        setLoading(false);
        if (status === GeocoderStatus.OK) {
          const position = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          };
          cache.current[address] = position;
          callback(position);
        }
      });
    },
    [GeocoderStatus.OK, geocoder]
  );

  return { loading, geocode };
};
