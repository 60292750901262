import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import OutlineInfo from '@material-ui/icons/InfoOutlined';
// import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import styles from './styles';

const TooltipIcon = props => (
  <Tooltip title={props.helpText} style={props.style}>
    {/* <IconButton
        aria-label={props.helpText}
        className={props.classes.iconSize}
      > */}
    <OutlineInfo className={props.classes.iconSize} />
    {/* </IconButton> */}
  </Tooltip>
);

export default withStyles(styles)(TooltipIcon);
