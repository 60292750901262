import React, { useMemo } from 'react';

import { Divider, ThemeProvider } from '@buildhero/sergeant';
import { findLastIndex, flatten, isEmpty } from 'lodash';

import { Placeholder } from 'components';
import { TimeCardStatusTypes } from 'utils/AppConstants';

import { useTimesheetPeriodsOfStatus } from '../customHooks';

import { DayCard } from './components/DayCard';
import { generateWeekDataFromBinder } from './helpers';

const PendingRevision = ({ selectedEmployee, payrollHourTypes, payrollSetting }) => {
  const [periods, isLoading] = useTimesheetPeriodsOfStatus({
    employeeId: selectedEmployee?.id,
    manualStatus: TimeCardStatusTypes.DISPUTED
  });

  const weekDates = useMemo(() => {
    if (isEmpty(periods)) return [];

    return flatten(
      periods.map(period => {
        const {
          timesheetEntryBinders: { items: timesheetEntryBinders }
        } = period;

        return generateWeekDataFromBinder(
          timesheetEntryBinders,
          period,
          payrollSetting.timeZone,
          payrollHourTypes,
          [TimeCardStatusTypes.DISPUTED]
        );
      })
    ).filter(d => d.workEvents.length);
  }, [periods, payrollSetting.timeZone, payrollHourTypes]);

  if (isLoading) return <Placeholder variant="table" />;

  return (
    <div>
      {isLoading && <Placeholder />}
      <ThemeProvider>
        {weekDates.map((day, i) => (
          <>
            <DayCard
              key={day.dayStartUTC}
              day={day}
              payrollHourTypes={payrollHourTypes}
              payrollSetting={payrollSetting}
            />
            {day.workEvents.length !== 0 &&
              i !== findLastIndex(weekDates, w => w.workEvents.length !== 0) && <Divider />}
          </>
        ))}
      </ThemeProvider>
      {weekDates.length === 0 && !isLoading && <div style={{ height: 300 }} />}
    </div>
  );
};

export default PendingRevision;
