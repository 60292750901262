import gql from 'graphql-tag';

const addAfterHoursSettingsToCompany = gql`
  mutation AddAfterHoursSettingsToCompany(
    $partitionKey: String!
    $data: AfterHourSettingsToCompanyInput!
  ) {
    saveAfterHoursSettingsToCompany(partitionKey: $partitionKey, data: $data) {
      id
    }
  }
`;

export default addAfterHoursSettingsToCompany;
