import gql from 'graphql-tag';

const updateTimeCardVisit = gql`
  mutation updateTimeCardVisit($partitionKey: String, $data: UpdateTimeCardVisitInput!) {
    updateTimeCardVisit(partitionKey: $partitionKey, data: $data) {
      id
    }
  }
`;

export default updateTimeCardVisit;
