import React from 'react';
import { connect } from 'react-redux';
import ErrorBoundaries from 'scenes/Error';
import { PageHeader, SergeantModal } from 'components';
import { Grid, Box, Divider, Typography } from '@material-ui/core';
import Labels from 'meta/labels';
import { Mode } from 'utils/constants';
import Switch from '@material-ui/core/Switch';
import Warning from '@material-ui/icons/Error';
import SubduedButton from 'components/Buttons/SubduedButton';
import { getTenantSettingValueForKey, logErrorWithCallback } from 'utils';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { IntegrationConstants } from 'utils/AppConstants';
import useCostCodes, { getCostCodes } from 'customHooks/useCostCodes';
import useCostTypes from 'customHooks/useCostTypes';
import useCompanySettings from 'customHooks/useCompanySettings';
import { snackbarOn } from 'redux/actions/globalActions';
import costCodeLayout from 'meta/Settings/Project/costCode';
import costTypeLayout from 'meta/Settings/Project/costType';
import { checkSettingEnabled } from 'utils';
import { typeModalDefaultState, COST_TYPE, COMPANY_SETTING_TYPE } from 'constants/common';
import { QuickbooksService } from 'services/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from 'utils/FeatureFlagConstants';
import CostTypes from './CostTypes';
import CostCodes from './CostCodes';
import { saveCostCodes, saveCostTypes, updateCostCode, updateCostType } from './services';
import { description } from './constants';
import { checkEditPermission, checkAddPermission, constructProjectJobSetting } from './helpers';
import useStyles from './styles';
import WIPReportSettings from './WIPReportSettings';

const syncJobCostCodes = async (setCostCodes, user, snackbar, setLoading) => {
  setLoading(true);
  try {
    const quickbooksService = new QuickbooksService();
    const syncData = await quickbooksService.syncJobCostCodes(user.tenantId, user.tenantCompanyId);
    if (syncData?.data) {
      const items = syncData[Object.keys(syncData)[0]] || [];
      if (items) {
        const data = await getCostCodes(user.tenantId, user.tenantCompanyId, snackbar);
        if (data) {
          setCostCodes(data);
          snackbar('success', `Synced ${data.length} job cost codes`);
        }
      }
    }
  } catch (error) {
    logErrorWithCallback(error, snackbar, `Unable to sync cost codes, please try again later`);
  } finally {
    setLoading(false);
  }
};

const CostWrapper = ({
  isAllowedToAdd,
  classes,
  onAddNew,
  header,
  children,
  actionLabel,
  isJobCostingFromIntegrationEnabled,
  accountingAppName,
  setCostCodes,
  user,
  snackbar
}) => {
  const [loading, setLoading] = React.useState(false);
  return (
    <>
      <Divider fullwidth />
      <Typography variant="body2" className={classes.greenHeading}>
        {header}
        <Warning className={classes.warningIcon} />
      </Typography>
      {children}
      {isAllowedToAdd && (
        <>
          {!isJobCostingFromIntegrationEnabled && (
            <SubduedButton
              color="secondary"
              variant="outlined"
              onClick={onAddNew}
              style={{ margin: `16px 0` }}
            >
              <AddCircleOutlineOutlinedIcon style={{ marginRight: 5 }} fontSize="inherit" />{' '}
              {actionLabel}
            </SubduedButton>
          )}
          {isJobCostingFromIntegrationEnabled && (
            <SubduedButton
              color="secondary"
              variant="outlined"
              onClick={() => syncJobCostCodes(setCostCodes, user, snackbar, setLoading)}
              style={{ margin: `16px 0`, marginLeft: 5 }}
            >
              <AddCircleOutlineOutlinedIcon style={{ marginRight: 5 }} fontSize="inherit" />
              {loading ? `Syncing...` : `Import from ${accountingAppName}`}
            </SubduedButton>
          )}
        </>
      )}
    </>
  );
};

const RenderDescription = ({ classes, strongText, descriptionText, paddingX = 5 }) => (
  <Box display="flex" py={2} paddingX={paddingX} flex={1}>
    <Typography className={classes.description} variant="body2">
      <b>{strongText}</b> {descriptionText}
    </Typography>
  </Box>
);

const Project = ({ user, snackbarOn: snackbar }) => {
  const flags = useFlags();
  const classes = useStyles();
  const { tenantId, tenantCompanyId } = user;
  const [isJobSettingEnabled, setJobSettingEnabled] = React.useState(false);
  const commonServiceArgs = { tenantId, companyId: tenantCompanyId, snackbarOn: snackbar };
  const commonHooksArgs = [tenantId, tenantCompanyId, snackbar];
  const [costTypes, setCostTypes] = useCostTypes(...commonHooksArgs);
  const [costCodes, setCostCodes] = useCostCodes(...commonHooksArgs);
  const [projectSetting, saveSetting] = useCompanySettings(
    COMPANY_SETTING_TYPE.PROJECT,
    ...commonHooksArgs
  );

  const accountingIntegration = getTenantSettingValueForKey('accountingApp');
  const accountingAppName = accountingIntegration && accountingIntegration.toUpperCase();
  const isJobCostingFromIntegrationEnabled =
    !!accountingIntegration &&
    IntegrationConstants.JOB_COST_ACCOUNTING_APPS.includes(accountingAppName);

  const isJobByJobSettingEnabled = checkSettingEnabled(projectSetting, 'jobByJob');

  React.useEffect(() => {
    setJobSettingEnabled(isJobByJobSettingEnabled);
  }, [isJobByJobSettingEnabled]);

  const [openTypeModal, setOpenTypeModal] = React.useState(typeModalDefaultState);

  const isAllowedToEdit = checkEditPermission(user);
  const isAllowedToAdd = checkAddPermission(user);

  const types = Object.values(COST_TYPE).map(value => ({ label: value.replace('_', ' '), value }));

  const layoutMap = {
    costCodes: costCodeLayout,
    costTypes: costTypeLayout({ types })
  };

  const layout = layoutMap[openTypeModal.type];

  const handleSaveAction = (value, stopSpinner) => {
    const stateMap = {
      costCodes: {
        stateHandler: setCostCodes,
        serviceMethod: saveCostCodes,
        state: costCodes
      },
      costTypes: {
        stateHandler: setCostTypes,
        serviceMethod: saveCostTypes,
        state: costTypes
      }
    };
    const { stateHandler, state, serviceMethod } = stateMap[openTypeModal.type];
    const successCallback = response => {
      stateHandler([...state, ...response]);
      stopSpinner();
      setOpenTypeModal(typeModalDefaultState);
    };
    serviceMethod({ [openTypeModal.type]: [value], successCallback, ...commonServiceArgs });
  };

  const handleNewItem = (type, dataType) =>
    setOpenTypeModal({
      ...openTypeModal,
      type,
      dataType,
      open: true,
      mode: Mode.ADD
    });

  return (
    <ErrorBoundaries>
      <Grid container direction="column">
        <PageHeader
          title={Labels.projectSettings[user.locale]}
          breadcrumbsArray={[{ link: '', title: Labels.settings[user.locale] }]}
          iconComponent={<SettingsOutlinedIcon className={classes.settingIcon} />}
        />
        <Grid item>
          <Typography style={{ margin: '16px 0' }} variant="h6">
            {Labels.projectSetting[user.locale]}
          </Typography>
          <CostWrapper
            classes={classes}
            actionLabel="Add Cost Code"
            isAllowedToAdd={isAllowedToAdd}
            header={Labels.costCodes[user.locale]}
            onAddNew={() => handleNewItem('costCodes', 'Cost Codes')}
            isJobCostingFromIntegrationEnabled={isJobCostingFromIntegrationEnabled}
            accountingAppName={accountingAppName}
            setCostCodes={setCostCodes}
            snackbar={snackbar}
            user={user}
          >
            <Grid container direction="row" justify="space-between">
              <CostCodes
                items={costCodes}
                isAllowedToEdit={!isJobCostingFromIntegrationEnabled && isAllowedToEdit}
                onUpdateCostCodeState={setCostCodes}
                onUpdateCostCode={(costCode, successCallback) =>
                  updateCostCode({ costCode, successCallback, ...commonServiceArgs })
                }
              />
              <RenderDescription classes={classes} {...description.costCodes} />
            </Grid>
          </CostWrapper>
        </Grid>
        <Grid item>
          <CostWrapper
            classes={classes}
            actionLabel="Add Cost Types"
            isAllowedToAdd={isAllowedToAdd}
            header={Labels.costTypes[user.locale]}
            onAddNew={() => handleNewItem('costTypes', 'Cost Types')}
          >
            <Grid container direction="row" justify="space-between">
              <CostTypes
                types={types}
                isAllowedToEdit={isAllowedToEdit}
                onUpdateCostTypeState={setCostTypes}
                onUpdateCostType={(costType, successCallback) =>
                  updateCostType({ costType, successCallback, ...commonServiceArgs })
                }
                items={costTypes}
              />
              <RenderDescription classes={classes} {...description.costTypes} />
            </Grid>
          </CostWrapper>
        </Grid>
      </Grid>
      <Grid item>
        <Divider fullwidth />
        <Grid container direction="column" justify="space-between">
          <Typography variant="body2" className={classes.greenHeading}>
            Detailed Job Costing Settings
          </Typography>
          <Box display="flex" flex={1}>
            <Box display="flex" flex={1} flexDirection="column">
              <Box display="flex" py={2} width="80%" justifyContent="space-between" flex={1} pr={3}>
                <Typography variant="body1">On a Job-by-Job Basis</Typography>
                <Switch
                  checked={isJobSettingEnabled}
                  onChange={() => {
                    setJobSettingEnabled(!isJobSettingEnabled);
                    saveSetting(constructProjectJobSetting(projectSetting, !isJobSettingEnabled));
                  }}
                  name="detailedJobCosting"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </Box>
            </Box>
            <Box display="flex" flex={1}>
              <RenderDescription classes={classes} {...description.costingSetting} paddingX={0} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      {layout && (
        <SergeantModal
          open={openTypeModal.open}
          data={openTypeModal.data}
          mode={openTypeModal.mode}
          dataType={openTypeModal.dataType}
          handlePrimaryAction={handleSaveAction}
          layout={layout}
          maxWidth={500}
          handleClose={() => setOpenTypeModal(typeModalDefaultState)}
        />
      )}
      {flags[FeatureFlags.PROJECT_MANAGEMENT] && (
        <WIPReportSettings
          classes={classes}
          user={user}
          snackbar={snackbar}
          accountingIntegration={accountingIntegration}
        />
      )}
    </ErrorBoundaries>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = { snackbarOn };

const reduxConnectedProject = connect(mapStateToProps, mapDispatcherToProps)(Project);

export default reduxConnectedProject;
