import React, { useState, useMemo, useEffect, useCallback } from 'react';
import {
  Typography,
  TV,
  TW,
  SgtFormComponents,
  Divider,
  Field,
  FieldType,
  Select
} from '@buildhero/sergeant';
import Skeleton from 'react-loading-skeleton';
import { Box } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Mode, AccountingApp } from 'utils/constants';
import { QuickbooksService } from 'services/core';
import useJobCostingOptions from 'customHooks/useJobCostingOptions';
import { snackbarOn } from 'redux/actions/globalActions';
import AdjustmentItemModal from './AdjustmentItem.modal';
import { formatAdjustmentItem } from './AdjustmentItem.utils';

const Header = (
  <>
    <Divider margin={24} />
    <Typography variant={TV.L} weight={TW.BOLD} style={{ marginBottom: 4 }}>
      Add Items
    </Typography>
    <Typography style={{ maxWidth: 880, marginBottom: 24 }}>
      In order to record the accurate refund total with sales tax taken into consideration, BuildOps
      requires adding line items and indicate whether the added item is taxable or not. (Only
      applies when the transaction type is refund/write-off.)
    </Typography>
  </>
);

const TotalDivider = <Divider color="black" margin={15.5} alignSelf="stretch" />;

const modalTitle = {
  [Mode.ADD]: 'Add Item',
  [Mode.EDIT]: 'Edit Item',
  [Mode.DELETE]: 'Delete Item'
};

const getColumns = accountingApp => [
  accountingApp === AccountingApp.INTACCT && {
    id: 'ledgerAccount.label',
    label: 'GL Account',
    width: `${116 / 5.64}%`,
    weight: TW.BOLD
  },
  accountingApp !== AccountingApp.INTACCT && {
    id: 'name',
    label: 'Item Name',
    width: `${116 / 5.64}%`,
    weight: TW.BOLD
  },
  {
    id: 'department.label',
    label: 'Department',
    width: `${64 / 5.64}%`
  },
  {
    id: 'description',
    label: 'Description',
    width: `${132 / 5.64}%`
  },
  {
    id: 'taxable',
    label: 'Taxable',
    type: FieldType.BOOL,
    width: `${36 / 5.64}%`
  },
  {
    id: 'quantity',
    label: 'Quantity',
    type: FieldType.NUMBER,
    width: `${56 / 5.64}%`,
    numeric: true,
    sum: true
  },
  {
    id: 'unitPrice',
    label: 'Unit Price',
    type: FieldType.CURRENCY,
    width: `${80 / 5.64}%`
  },
  {
    id: 'amount',
    label: 'Price Subtotal',
    type: FieldType.CURRENCY,
    width: `${80 / 5.64}%`,
    sum: true
  }
];

export default function AdjustmentItem({ form, ...props }) {
  const [ledgerAccountOptions, setLedgerAccountOptions] = useState();
  const [itemModal, setItemModal] = useState({ open: false });

  const {
    costCodeOptions,
    jobCostTypeOptions: costTypeOptions,
    revenueTypeOptions
  } = useJobCostingOptions();

  const { setFieldValue, setFieldTouched, values, errors } = form;

  const {
    tenantCompanyId,
    tenantId,
    subtotal,
    taxableSubtotal,
    taxAmount,
    taxLedgerAccount,
    amount,
    taxRate,
    department,
    priceBook,
    taxRateOptions,
    departmentOptions,
    accountingApp
  } = values;

  useEffect(() => {
    const getGLAccounts = async () => {
      const service = new QuickbooksService();
      let accounts = [];
      let token;
      do {
        const {
          data: {
            getCompany: {
              ledgerAccounts: { items, nextToken }
            }
          }
          // eslint-disable-next-line no-await-in-loop
        } = await service.getGLAccounts(
          tenantId,
          `${tenantId}_Company_${tenantCompanyId}`,
          undefined,
          undefined,
          token
        );
        accounts = accounts.concat(items.map(l => ({ label: l.name, value: l.id })));
        token = nextToken;
      } while (token);
      setLedgerAccountOptions(accounts);
    };
    if (accountingApp === AccountingApp.INTACCT) getGLAccounts();
  }, [accountingApp, tenantCompanyId, tenantId]);

  const tableProps = useMemo(() => {
    const handleClose = () => setItemModal(prev => ({ ...prev, open: false }));
    const openModal = (mode, givenData, callback) =>
      setItemModal({
        open: true,
        mode,
        data: {
          department,
          name: null,
          ledgerAccount: null,
          taxable: false,
          quantity: null,
          unitCost: null,
          unitPrice: null,
          amount: null,
          ...givenData,
          priceBook,
          // temp id on create - removed during upsert
          id: givenData?.id ?? `-${givenData?.sortOrder}`,
          ledgerAccountOptions,
          costCodeOptions,
          costTypeOptions,
          revenueTypeOptions,
          departmentOptions,
          accountingApp
        },
        title: modalTitle[mode],
        handlePrimaryAction: newData => {
          callback(formatAdjustmentItem(newData));
          handleClose();
        },
        onClose: handleClose
      });

    return {
      columns: getColumns(accountingApp),
      onCreate: (sortOrder, callback) => openModal(Mode.ADD, { sortOrder }, callback),
      onUpdate: (data, callback) => openModal(Mode.EDIT, data, callback),
      onDelete: (data, callback) => openModal(Mode.DELETE, data, callback)
    };
  }, [
    accountingApp,
    department,
    priceBook,
    ledgerAccountOptions,
    costCodeOptions,
    costTypeOptions,
    revenueTypeOptions,
    departmentOptions
  ]);

  const taxRateSelect = useMemo(
    () => (
      <Select
        testingid="taxRate"
        options={taxRateOptions}
        value={taxRate}
        onChange={value => setFieldValue('taxRate', value, true)}
        clearable
        searchable
        style={{ marginBottom: 16 }}
      />
    ),
    [setFieldValue, taxRate, taxRateOptions]
  );

  const loading = accountingApp === AccountingApp.INTACCT && ledgerAccountOptions === undefined;
  return (
    <>
      {Header}
      {loading ? (
        <Skeleton height={126} />
      ) : (
        <SgtFormComponents.Table {...props} form={form} props={tableProps} />
      )}
      <Box display="flex" justifyContent="flex-end">
        {accountingApp === AccountingApp.INTACCT && (
          <Box marginTop={13.25} marginRight={2} minWidth={200}>
            {loading ? (
              <Skeleton height={56} />
            ) : (
              <Select
                testingid="GL-account"
                label="GL Account"
                required={!!taxRate?.taxRate}
                options={ledgerAccountOptions}
                value={taxLedgerAccount}
                onChange={value => setFieldValue('taxLedgerAccount', value, true)}
                onBlur={() => setTimeout(() => setFieldTouched('taxLedgerAccount', true, true))}
                error={!!errors.taxLedgerAccount}
                subtext={errors.taxLedgerAccount}
                tooltip="GL Account for tax item. Required if a tax rate is selected"
                clearable
                searchable
              />
            )}
          </Box>
        )}
        <Box display="flex" flexDirection="column" textAlign="right" width="105">
          <Typography style={{ marginBottom: 32 }}>Subtotal</Typography>
          <Typography style={{ marginBottom: 26 }}>Taxable Subtotal</Typography>
          <Typography style={{ marginBottom: 26 }}>Tax Rate</Typography>
          <Typography style={{ marginBottom: 32 }}>Tax Amount</Typography>
          <Typography weight={TW.BOLD}>Total</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          minWidth={200}
          marginLeft={5}
          alignItems="flex-end"
        >
          <Field testingid="subTotal" type={FieldType.CURRENCY} weight={TW.BOLD} value={subtotal} />
          {TotalDivider}
          <Field
            testingid="taxable-subTotal"
            type={FieldType.CURRENCY}
            weight={TW.BOLD}
            value={taxableSubtotal}
            style={{ marginBottom: 16 }}
          />
          {taxRateSelect}
          <Field
            testingid="taxAmount"
            type={FieldType.CURRENCY}
            weight={TW.BOLD}
            value={taxAmount}
          />
          {TotalDivider}
          <Field
            testingid="total-amount"
            type={FieldType.CURRENCY}
            weight={TW.BOLD}
            value={amount}
          />
        </Box>
      </Box>
      <AdjustmentItemModal {...itemModal} />
    </>
  );
}
