import { employeeSearch } from 'services/API/employee';
import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';
import { customerRepSearch } from 'services/API/customerRep';

const fields = {
  number: { required: true },
  status: { required: true },
  SendTo: { required: true, nullable: true },
  ReturnTo: { required: true },
  subject: { required: true, nullable: true },
  informationRequested: { required: true, nullable: true },
  recommendation: { required: false },
  impactToTimeline: { required: false },
  impactToCost: { required: false },
  specRef: { required: false },
  drawingRef: { required: false },
  detailRef: { required: false },
  rfiAnswer: { required: false },
  dueDateText: { required: true, nullable: true },
  rfiAnswerDateText: { required: false }
};

const getAlphabeticalSortFn = field => (a, b) =>
  a?.[field]?.toLowerCase() > b?.[field]?.toLowerCase() ? 1 : -1;

const defaultRFIForm = ({ editMode, project }) => {
  return {
    fields: { ...generateDefaultFieldsObject(fields) },
    layouts: {
      edit: {
        options: {
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '80%',
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          {
            options: {
              width: '100%',
              padding: [10, 0]
            },
            // subject, rfiNumber row start
            contents: [
              {
                options: {
                  direction: 'row',
                  paddingTop: '17px'
                },
                contents: [
                  {
                    options: {
                      width: '33%',
                      paddingRight: '15px'
                    },
                    contents: [
                      {
                        component: 'SelectInput',
                        source: 'status',
                        options: {
                          label: 'Status',
                          isRequired: true,
                          disabled: !editMode,
                          fullWidth: false,
                          variant: 'standard',
                          color: 'secondary',
                          enableSort: false,
                          valueSet: [
                            { label: 'Draft', value: 'draft' },
                            { label: 'Sent', value: 'sent' },
                            { label: 'Responded', value: 'responded' },
                            { label: 'Closed', value: 'closed' }
                          ]
                        }
                      }
                    ]
                  },
                  {
                    options: {
                      direction: 'column',
                      paddingRight: '15px'
                    },
                    contents: [
                      {
                        component: {
                          edit: 'TextInput'
                        },
                        source: 'number',
                        options: {
                          disabled: true,
                          label: 'Number',
                          isRequired: true,
                          variant: 'standard',
                          color: 'secondary'
                        }
                      }
                    ]
                  },
                  {
                    options: {},
                    contents: []
                  }
                ]
              },
              // send to, send from row start
              {
                options: {
                  direction: 'row',
                  paddingTop: '17px'
                },
                contents: [
                  {
                    options: {
                      width: '33%',
                      paddingRight: '15px'
                    },
                    contents: [
                      {
                        component: {
                          default: 'SearchBar',
                          edit: 'SearchBar'
                        },
                        source: 'SendTo',
                        options: {
                          resultFormatFunction: customerRep => customerRep.name,
                          searchFunction: (value, searchCols) =>
                            customerRepSearch(value, searchCols, project?.customer?.id),
                          sortFunction: getAlphabeticalSortFn('name'),
                          label: 'Send To',
                          placeholder: 'Search contacts',
                          fullWidth: true,
                          alignment: 'left',
                          variant: 'standard',
                          color: 'secondary',
                          isRequired: true,
                          errorText: 'Field is required'
                        }
                      }
                    ]
                  },
                  {
                    options: { direction: 'column', paddingRight: '15px' },
                    contents: [
                      {
                        component: {
                          default: 'SearchBar',
                          edit: 'SearchBar'
                        },
                        source: 'ReturnTo',
                        options: {
                          resultFormatFunction: employee => employee.name,
                          searchFunction: employeeSearch,
                          filterFunction: data => data.filter(item => item.status === 'active'),
                          sortFunction: getAlphabeticalSortFn('name'),
                          searchColumns: ['name'],
                          label: 'From',
                          placeholder: 'Search contacts',
                          fullWidth: true,
                          alignment: 'left',
                          variant: 'standard',
                          color: 'secondary',
                          isRequired: true,
                          errorText: 'Field is required'
                        }
                      }
                    ]
                  },
                  {
                    options: {},
                    contents: []
                  }
                ]
              },
              // subject row
              {
                options: {
                  direction: 'row',
                  paddingTop: '31px'
                },
                contents: [
                  {
                    options: {
                      direction: 'column',
                      paddingRight: '15px'
                    },
                    contents: [
                      {
                        component: {
                          edit: 'TextInput'
                        },
                        source: 'subject',
                        options: {
                          label: 'Subject',
                          isRequired: true,
                          variant: 'standard',
                          color: 'secondary'
                        }
                      }
                    ]
                  },
                  {
                    options: {},
                    contents: []
                  },
                  {
                    options: {},
                    contents: []
                  }
                ]
              },
              {
                options: {
                  direction: 'row',
                  width: '66%',
                  paddingTop: '31px'
                },
                contents: [
                  {
                    options: {
                      direction: 'column'
                    },
                    contents: [
                      {
                        component: { edit: 'TextInput' },
                        source: 'informationRequested',
                        options: {
                          label: 'Information requested',
                          isRequired: true,
                          variant: 'standard',
                          color: 'secondary',
                          lines: 4,
                          placeholder: 'Enter Information Requested'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                options: {
                  direction: 'row',
                  width: '66%',
                  paddingTop: '31px'
                },
                contents: [
                  {
                    options: {
                      direction: 'column'
                    },
                    contents: [
                      {
                        component: { edit: 'TextInput' },
                        source: 'recommendation',
                        options: {
                          label: 'Recommendation',
                          variant: 'standard',
                          color: 'secondary',
                          lines: 4,
                          placeholder: 'Enter Recommendation'
                        }
                      }
                    ]
                  }
                ]
              },
              // Impact row
              {
                options: {
                  direction: 'row',
                  width: '66%',
                  paddingTop: '31px'
                },
                contents: [
                  {
                    options: {
                      direction: 'column',
                      paddingRight: '15px'
                    },
                    contents: [
                      {
                        component: {
                          edit: 'RadioButtonsGroup'
                        },
                        source: 'impactToTimeline',
                        options: {
                          label: 'Impact to Timeline',
                          variant: 'standard',
                          isRequired: false,
                          color: 'secondary',
                          inputOptions: 'Yes,Probable,No'
                        }
                      }
                    ]
                  },
                  {
                    options: {
                      direction: 'column'
                    },
                    contents: [
                      {
                        component: {
                          edit: 'RadioButtonsGroup'
                        },
                        source: 'impactToCost',
                        options: {
                          label: 'Impact to cost',
                          isRequired: false,
                          variant: 'standard',
                          color: 'secondary',
                          inputOptions: 'Yes,Probable,No'
                        }
                      }
                    ]
                  }
                ]
              },
              // Refs row
              {
                options: {
                  direction: 'row',
                  paddingTop: '31px'
                },
                contents: [
                  {
                    options: {
                      direction: 'column',
                      paddingRight: '15px'
                    },
                    contents: [
                      {
                        component: {
                          edit: 'TextInput'
                        },
                        source: 'specRef',
                        options: {
                          label: 'Spec Ref',
                          isRequired: false,
                          variant: 'standard',
                          color: 'secondary'
                        }
                      }
                    ]
                  },
                  {
                    options: {
                      direction: 'column',
                      paddingRight: '15px'
                    },
                    contents: [
                      {
                        component: {
                          edit: 'TextInput'
                        },
                        source: 'drawingRef',
                        options: {
                          label: 'Drawing Ref',
                          isRequired: false,
                          variant: 'standard',
                          color: 'secondary'
                        }
                      }
                    ]
                  },
                  {
                    options: {
                      direction: 'column'
                    },
                    contents: [
                      {
                        component: {
                          edit: 'TextInput'
                        },
                        source: 'detailRef',
                        options: {
                          label: 'Detail Ref',
                          isRequired: false,
                          variant: 'standard',
                          color: 'secondary'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                options: {
                  width: '66%',
                  paddingTop: '31px'
                },
                contents: [
                  {
                    component: {
                      edit: 'TextInput'
                    },
                    source: 'rfiAnswer',
                    options: {
                      label: 'RFI Answer',
                      isRequired: false,
                      variant: 'standard',
                      color: 'secondary',
                      placeholder: 'Enter RFI Answer',
                      lines: 4
                    }
                  }
                ]
              },
              // Dates row
              {
                options: {
                  direction: 'row',
                  width: '66%',
                  paddingTop: '31px'
                },
                contents: [
                  {
                    options: { direction: 'column', paddingRight: '15px' },
                    contents: [
                      {
                        component: {
                          edit: 'TextInput'
                        },
                        source: 'dueDateText',
                        options: {
                          label: 'Due Date',
                          isRequired: true,
                          variant: 'standard',
                          color: 'secondary',
                          placeholder: 'Select date',
                          type: 'date'
                        }
                      }
                    ]
                  },
                  {
                    options: { direction: 'column' },
                    contents: [
                      {
                        component: {
                          edit: 'TextInput'
                        },
                        source: 'rfiAnswerDateText',
                        options: {
                          label: 'RFI Answer Date',
                          isRequired: false,
                          variant: 'standard',
                          color: 'secondary',
                          placeholder: 'Select date',
                          type: 'date'
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  };
};

export { defaultRFIForm, fields };
