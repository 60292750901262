import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { getImageUrl } from 'utils';
import styles from './styles';

class ImageAvatars extends Component {
  constructor(props) {
    super(props);

    this.state = {
      [this.props.fileName]: ''
    };
  }

  componentDidMount = async () => {
    const { fileName } = this.props;
    if (fileName) {
      const url = await getImageUrl(fileName);
      if (!this.state[fileName]) this.setState({ [fileName]: url });
    }
  };

  render() {
    const { classes } = this.props;
    const imageUrl = this.state[this.props.fileName];

    return imageUrl && <Avatar alt={this.props.alt} src={imageUrl} className={classes.avatar} />;
  }
}

export default withStyles(styles)(ImageAvatars);
