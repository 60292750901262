import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import Tooltip from '@material-ui/core/Tooltip';
import { Box, Grid } from '@material-ui/core';
import { CurrencyInput, Field, FieldType, Typography, TV, TW, Divider } from '@buildhero/sergeant';
import { overridesAreEnabled } from 'scenes/Quotes/utils/helper';
import { debounce } from 'lodash';
import { Logger } from 'services/Logger';
import useStyles from './TotalsPanel.styles';

export const TotalsPanelLine = ({
  value,
  labelName,
  labelWithInfo,
  boldLabel,
  tooltip,
  divider,
  overrideValue,
  updateOverrideAmountOnQuote,
  striped,
  config,
  quoteId
}) => {
  const classes = useStyles();

  const overrideVariables = {
    Total: 'totalAmountQuotedOverride',
    Subtotal: 'subTotalOverride',
    'Taxable Subtotal': 'subTotalAmountTaxableOverride'
  };

  const handleTotalsPanelInputChange = debounce(async val => {
    try {
      const overrideData = {
        value: val || 0,
        fieldToUpdate: overrideVariables[labelName]
      };
      await updateOverrideAmountOnQuote(overrideData);
    } catch (error) {
      Logger.error(error);
    }
  }, 1500);

  const totalPanelCurrencyInput = useMemo(
    () => (
      <CurrencyInput
        key={`${labelName}-${overrideValue ?? value}`}
        striped={striped}
        value={overrideValue ?? value}
        onChange={handleTotalsPanelInputChange}
      />
    ),
    [striped, value, quoteId]
  );

  return (
    <>
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        style={{ padding: 12, textAlign: 'right' }}
      >
        <Typography
          className={labelWithInfo ? classes.labelWithInfo : classes.label}
          variant={TV.BASE}
          weight={boldLabel ? TW.BOLD : TW.REGULAR}
        >
          {labelName}
        </Typography>
        {labelWithInfo && (
          <Tooltip title={tooltip}>
            <InfoRoundedIcon className={classes.tooltipIcon} />
          </Tooltip>
        )}
        <Box className={classes.lineItemAmount} style={{ width: '25%' }}>
          {labelName in overrideVariables && overridesAreEnabled(config) ? (
            totalPanelCurrencyInput
          ) : (
            <Field type={FieldType.CURRENCY} value={value} striped={striped} />
          )}
        </Box>
      </Grid>
      {divider && (
        <Box className={classes.subtotalDivider}>
          <Divider color="#333333" margin={3} />
        </Box>
      )}
    </>
  );
};

TotalsPanelLine.propTypes = {
  value: PropTypes.number,
  labelName: PropTypes.string,
  labelWithInfo: PropTypes.bool,
  boldLabel: PropTypes.bool,
  tooltip: PropTypes.string,
  divider: PropTypes.bool,
  overrideValue: PropTypes.number,
  updateOverrideAmountOnQuote: PropTypes.func,
  striped: PropTypes.bool,
  config: PropTypes.object,
  quoteId: PropTypes.string
};

TotalsPanelLine.defaultProps = {
  value: 0,
  labelName: '',
  labelWithInfo: false,
  boldLabel: false,
  tooltip: '',
  divider: false,
  overrideValue: null,
  updateOverrideAmountOnQuote: () => {},
  striped: false,
  config: {},
  quoteId: null
};
