import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AutoCompleteDropdown from 'components/AutoCompleteDropdown';
import useStyles from './AutoComplete.styles';

const AutoComplete = ({ valueKey, isEditable, labelKey, ...props }) => {
  const classes = useStyles();

  const options = props.options.map(option => ({
    label: option[labelKey],
    value: option[valueKey]
  }));

  const selectedOption = options.find(option => option.value === props.value);

  if (!isEditable) {
    return <Typography variant="body2">{selectedOption?.label || '-'}</Typography>;
  }

  return (
    <AutoCompleteDropdown
      inputProps={{
        textFieldClassName: classes.selectionPlaceholder,
        inputClassNames: classes.selectionInput,
        className: classes.selectionWrapper,
        disableUnderline: true
      }}
      DropdownIndicator={() => <ExpandMore />}
      {...props}
      options={options}
    />
  );
};

export default AutoComplete;

AutoComplete.propTypes = {
  options: PropTypes.array,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string
};

AutoComplete.defaultProps = {
  options: [],
  valueKey: 'id',
  labelKey: 'name'
};
