import { axiosInstanceRestAPI as instance } from 'configs/http-client';

export const getPriceBookEntryOverridesByPriceBookId = priceBookId => {
  return instance.get(`priceBooks/${priceBookId}/entryOverrides`);
};

export const upsertPriceBookEntryOverride = priceBook2EntryOverride => {
  return instance.post(`priceBookEntryOverrides`, priceBook2EntryOverride);
};

export const deletePriceBookEntryOverride = priceBook2EntryOverride => {
  return instance.delete(`priceBookEntryOverrides`, priceBook2EntryOverride);
};
