export const changeOrderDetailSidebarForm = [
  {
    source: 'date',
    label: 'Date'
  },
  {
    source: 'subject',
    label: 'Subject'
  },
  {
    source: 'status',
    label: 'Status',
    component: 'sidebarHighlightedItem'
  },
  {
    source: 'dateApproved',
    label: 'Date Approved'
  },
  {
    source: 'SendTo.name',
    label: 'Send To'
  },
  {
    source: 'ReturnTo.name',
    label: 'Return To'
  },
  {
    source: 'sentOn',
    label: 'Sent On'
  },
  {
    source: 'daysValid',
    label: 'Days Valid'
  },
  {
    source: 'scopeOfWork',
    label: 'Scope of Work'
  },
  {
    source: 'scheduleExtensionRequested',
    label: 'Schedule Extension Requested'
  }
];
