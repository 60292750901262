import { useMemo } from 'react';
import uniq from 'lodash/uniq';
import difference from 'lodash/difference';

export const useMissingVisitTechnicians = ({ laborLineItems, visit }) =>
  useMemo(() => {
    const reportTechnicianIds = laborLineItems.map(lineItem => lineItem?.employeeId);
    const visitTechnicianIds = uniq([
      ...(visit?.extraTechs?.items.map(technician => technician?.mappedEntity?.id) || []),
      ...(visit?.primaryTechs?.items.map(technician => technician?.mappedEntity?.id) || [])
    ]).filter(Boolean);
    return difference(visitTechnicianIds, reportTechnicianIds);
  }, [laborLineItems, visit]);
