const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    boxShadow: 'none'
  },
  table: {
    minWidth: 600
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  buttonHover: {
    '&:hover': {
      backgroundColor: theme.palette.grayscale(95)
    }
  },
  tableFooterToolbar: {
    backgroundColor: theme.palette.grayscale(100),
    fontSize: 12,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold'
  },
  iconColor: {
    color: theme.palette.grayscale(40),
    fontSize: 18,
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  highlightFirstColumn: {
    borderRight: `1px solid ${theme.palette.grayscale(90)}`
  },
  blueLinkText: {
    fontWeight: 'normal',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main
  },
  noData: {
    color: theme.palette.grayscale(60),
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 'normal',
    letterSpacing: 0.3,
    paddingTop: 15,
    paddingLeft: 10
  },
  linkText: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontFamily: theme.typography.fontFamily,
    lineHeight: 'normal',
    fontStretch: 'normal',
    letterSpacing: 0.2
  },
  circle: {
    width: 16,
    height: 16,
    borderRadius: '50%'
  },
  labelText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 10,
    fontWeight: 900,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 1.3,
    color: theme.palette.grayscale(60),
    textTransform: 'uppercase',
    marginBottom: 5
  },
  valueText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 15,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.36,
    color: theme.palette.primary.main
  },
  billingBox: {
    marginBottom: 20,
    marginTop: 10,
    marginLeft: 0,
    marginRight: 0,
    width: '100%'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  positionedHeaderCheckbox: {
    width: 80,
    position: 'relative',
    zIndex: 10,
    height: 0,
    top: 72
  }
});
export default styles;
