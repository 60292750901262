import React, { useState } from 'react';
import { CurrencyInput, PercentageInput, Typography, Button } from '@buildhero/sergeant';
import { isNumber, round } from 'lodash';

const useStyles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  toggleButton: {
    width: 38,
    height: 30,
    padding: 2,
    margin: 5
  }
});

const CurrencyPercentageInput = props => {
  const styles = useStyles();
  const [isPercent, setIsPercent] = useState(true);
  const percentValue = Math.round((props.value / props.row.contractValue) * 10000) / 100;

  return (
    <div css={styles.root}>
      {isPercent ? (
        <PercentageInput
          {...props}
          value={percentValue || 0}
          onChange={value => {
            props.onChange(Math.round(value * props.row.contractValue) / 100);
          }}
          min={
            isNumber(props.min) && props.row.contractValue
              ? round((props.min * 100) / props.row.contractValue, 5)
              : undefined
          }
          max={
            isNumber(props.max) && props.row.contractValue
              ? round((props.max * 100) / props.row.contractValue, 5)
              : undefined
          }
        />
      ) : (
        <CurrencyInput {...props} value={props.value || 0} />
      )}
      <Button
        type="tertiary"
        size="small"
        css={styles.toggleButton}
        onClick={() => setIsPercent(!isPercent)}
      >
        <Typography>$%</Typography>
      </Button>
    </div>
  );
};

export default CurrencyPercentageInput;
