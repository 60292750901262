/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getSubmittalPackages(projectId, noInclude) {
  return await instance
    .get(`submittalpackages/byproject/${projectId}`, {
      params: {
        include: noInclude ? [] : '*'
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getSubmittalPackageById(submittalPackageId, noInclude) {
  return await instance
    .get(`submittalpackages/${submittalPackageId}`, {
      params: {
        include: noInclude
          ? []
          : [
              'submittalPackageSubmittalItems',
              'submittalPackageAttachments',
              'sendToCompany',
              'sendTo',
              'returnToCompany',
              'returnTo',
              'AuditLogEntry'
            ]
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return {};
    });
}

export async function getNextNumber(projectId) {
  let paramsClause = {};
  if (projectId) {
    paramsClause = { projectId };
  }
  return await instance
    .get('submittalpackages/next/number', {
      params: paramsClause
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return {};
    });
}

export async function submittalPackageCreate(submittalPackage) {
  return await instance
    .post('submittalpackages', submittalPackage)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function submittalPackageChange(id, submittalPackage) {
  return await instance
    .put(`submittalpackages/${id}`, submittalPackage)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function submittalPackageDelete(id) {
  return await instance
    .delete(`submittalpackages/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
