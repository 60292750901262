import { makeStyles } from '@material-ui/core';
import { ElementSizes, DispatchTheme, ElementZIndex } from '@dispatch/Dispatch.styles';

export const useStyles = makeStyles(theme => ({
  techCell: {
    position: 'sticky',
    left: -1,
    background: theme.palette.background.default,
    width: ElementSizes.techCellWidth,
    height: '100%',
    borderRight: DispatchTheme.mixins.mainBorder,
    borderBottom: DispatchTheme.mixins.mainBorder,
    paddingRight: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: '0 0 200px',
    zIndex: ElementZIndex.techCell
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    height: ElementSizes.cellHeight,
    marginLeft: theme.spacing(3)
  }
}));
