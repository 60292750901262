import React, { Component } from 'react';

import { withStyles } from '@material-ui/core';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';

import { PageHeader, Spinner, Tab, Tabs, UserPermission } from 'components';
import Context from 'components/Context';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import { CompanyService, PricebookService, QuickbooksService } from 'services/core';
import { Logger } from 'services/Logger';

import { PermissionConstants } from 'utils/AppConstants';

import Bundle from './Bundle';
import ProductsList from './ProductsList';
import styles from './styles';

class Products extends Component {
  constructor(props) {
    super(props);
    document.title = 'BuildOps - Inventory';
    this.PricebookService = new PricebookService();
    this.QuickbooksService = new QuickbooksService();
    this.CompanyService = new CompanyService();
    this.mounted = props.mounted || true;
    this.state = {
      openAddItem: false,
      accounts: [],
      vendors: [],
      mode: 'new',
      record: '',
      updatedData: '',
      isQuickBooksEnabled: false,
      openAddProductsToBundle: false,
      spinner: true,
      productBundle: ''
    };
  }

  componentDidMount = async () => {
    await this.queryAndSetTenantSettings();
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  queryAndSetTenantSettings = async () => {
    let isQuickBooksEnabled = false;
    let departments = [];
    try {
      let listTenantSettings = null;
      let quickbooksSetting = null;
      if (Context.getCompanyContext() && Context.getCompanyContext().getCompany) {
        ({ listTenantSettings } = await Context.getCompanyContext());
        quickbooksSetting =
          listTenantSettings &&
          listTenantSettings.filter(
            setting => setting.settingKey === 'accountingApp' && setting.settingValue !== ''
          );

        isQuickBooksEnabled =
          quickbooksSetting &&
          quickbooksSetting.length > 0 &&
          quickbooksSetting[0].settingValue === 'quickbooks';

        departments = Context.getCompanyContext().getCompany?.departments?.items;
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable fetch settings');
    }

    if (this.mounted) {
      this.setState(prevState => ({
        ...prevState,
        isQuickBooksEnabled,
        spinner: false,
        departments
      }));
    }
  };

  render() {
    const { user, flags: launchDarklyFlags } = this.props;
    const { isQuickBooksEnabled, spinner, departments } = this.state;

    if (spinner) {
      return <Spinner />;
    }

    return (
      <ErrorBoundaries>
        <UserPermission I="allow" action={PermissionConstants.MENU_INVENTORY}>
          <PageHeader pageMapKey="inventorySettings" userLocale={user.locale} />
          <Tabs>
            <Tab label="Items" key="inventory" disableBottomPadding>
              <ProductsList
                isQuickBooksEnabled={isQuickBooksEnabled}
                flags={launchDarklyFlags}
                departments={departments}
              />
            </Tab>
            {launchDarklyFlags?.truckStockInventory ? (
              <Tab label="Bundle" key="bundle" disableBottomPadding>
                <Bundle />
              </Tab>
            ) : null}
          </Tabs>
        </UserPermission>
      </ErrorBoundaries>
    );
  }
}

const styledProducts = withStyles(styles)(Products);

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedProducts = connect(mapStateToProps, mapDispatcherToProps)(styledProducts);

export default withLDConsumer()(reduxConnectedProducts);
