export const useStyles = () => {
  const indent = 8;
  return {
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginBottom: indent
    },
    container: {
      display: 'flex',
      marginTop: 3 * indent,
      marginBottom: indent
    },
    table: {
      marginBottom: 2 * indent
    },
    tableHeader: {
      display: 'flex',
      marginBottom: indent
    },
    tableHeaderText: {
      alignSelf: 'flex-end'
    },
    tableButtons: {
      marginLeft: 'auto'
    },
    title: {
      fontWeight: 'bold',
      marginTop: indent,
      marginBottom: indent
    }
  };
};
