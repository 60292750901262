import React, { useCallback } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { MUIFormComponents } from '@buildhero/sergeant';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

export const ExclusiveCheckboxInput = ({ form, field, options }) => {
  const { contact, label, color, isRequired, labelPlacement, disabled, onChange } = options;
  const handleFieldChange = useCallback(() => {
    form.setFieldValue('bestContact', contact);
    if (onChange) onChange(contact, form, field);
  }, [form, field, contact, onChange]);

  const error = form.errors && form.errors[field.name];
  const isChecked = form.values.bestContact === contact;
  const text = isRequired ? `* ${label}` : label;

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<RadioButtonCheckedIcon />}
            checked={isChecked}
            onChange={handleFieldChange}
            color={color}
            disabled={disabled}
          />
        }
        label={text}
        labelPlacement={labelPlacement}
        error={error && error !== ''}
      />
      <MUIFormComponents.ErrorMessage value={error} />
    </>
  );
};

export const ExclusiveReadOnlyCheckboxInput = ({ form, options }) => {
  const { contact, label, color, labelPlacement } = options;
  const isChecked = form.values.bestContact === contact;
  return (
    <FormControlLabel
      control={<Checkbox checked={isChecked} color={color} />}
      label={label}
      labelPlacement={labelPlacement}
      disabled
    />
  );
};
