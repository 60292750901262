import React from 'react';
import { Dialog, Grid, Slide } from '@material-ui/core';
import { TV, TW, Typography } from '@buildhero/sergeant';
import { AddRecordButton } from 'components';
import FormPDF from 'components/FormSection/FormPDF';
import { bool, func } from 'prop-types';
import { REPORT_FORM_PROP } from 'scenes/Jobs/JobDetail/propTypes';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const PDFDialog = ({ open, setOpen, onClose, formData }) => {
  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <Grid container direction="row" spacing={2} justify="space-between" alignItems="center">
        <Grid item style={{ marginLeft: 30 }}>
          <Typography variant={TV.BASE} weight={TW.BOLD}>
            {`${formData?.name} - Preview`}
          </Typography>
        </Grid>
        <Grid item>
          <AddRecordButton label="CLOSE" handle={() => setOpen(false)} />
        </Grid>
      </Grid>
      <FormPDF data={{ formDataJson: formData?.formDataJson }} viewPdf />
    </Dialog>
  );
};

PDFDialog.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
  formData: REPORT_FORM_PROP,
  onClose: func.isRequired
};

PDFDialog.defaultProps = {
  formData: {}
};

export default PDFDialog;
