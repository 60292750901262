import React, { useCallback } from 'react';

import { addAttachmentsToPDF } from 'scenes/ProjectManagement/components/utils';

import ChangeOrderDocument from './index';

export const useGetChangeOrderPDFBlob = props =>
  useCallback(async () => {
    const { attachments } = props;
    const mainPDFDocument = <ChangeOrderDocument {...props} />;

    return addAttachmentsToPDF({ mainPDFDocument, attachments });
  }, [props]);
