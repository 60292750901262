import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';

const defaultFlexStyle = '0 1 219px';
const margin = '0px 8px 16px 8px';

const generalInformationFields = {
  poNumber: { required: true },
  poType: { required: true },
  date: { required: true, nullable: true },
  department: { required: true },
  vendor: { required: true },
  assignTo: { required: true },
  jobAndProject: { required: true },
  description: { required: false },
  requiredByDate: { required: true, nullable: true },
  tags: { required: false }
};

const shippingInformationFields = {
  shipTo: { required: true },
  shippingAddress: { required: true }
};

const generalInformationContents = () => [
  // the 1st section title
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1
    },
    contents: [
      {
        options: {
          margin
        },
        contents: [
          {
            component: {
              default: 'MuiFormSectionTitle'
            },
            options: {
              label: 'General Information'
            }
          }
        ]
      }
    ]
  },

  // the 1st row
  {
    options: {
      size: 3,
      direction: 'row',
      grow: 0,
      flexWrap: 'nowrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'CustomFieldWithLabelNarrow'
            },
            source: 'poType.name',
            options: {
              label: 'PO Type',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'CustomFieldWithLabelNarrow'
            },
            source: 'date',
            options: {
              label: 'Date',
              isUtcDate: true,
              fullWidth: false,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'CustomFieldWithLabelNarrow'
            },
            source: 'department.tagName',
            options: {
              label: 'Department',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      }
    ]
  },

  // the 2nd row
  {
    options: {
      size: 3,
      direction: 'row',
      grow: 0,
      flexWrap: 'nowrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'CustomFieldWithLabelNarrow'
            },
            source: 'vendor.name',
            options: {
              label: 'Vendor',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'CustomFieldWithLabelNarrow'
            },
            source: 'assignedTo.name',
            options: {
              label: 'Assign To',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'CustomFieldWithLabelNarrow'
            },
            source: 'jobOrProjectDisplay',
            options: {
              label: 'Job / Project',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              linkPath: '',
              linkReference: 'jobOrProjectLink'
            }
          }
        ]
      }
    ]
  },

  // the 3rd row
  {
    options: {
      size: 3,
      direction: 'row',
      grow: 0,
      flexWrap: 'nowrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          width: '453px',
          margin
        },
        contents: [
          {
            component: {
              default: 'CustomFieldWithLabelNarrow'
            },
            source: 'description',
            options: {
              label: 'Description',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              nowrap: false
            }
          }
        ]
      }
    ]
  },

  // the 4th row
  {
    options: {
      size: 3,
      direction: 'row',
      grow: 0,
      flexWrap: 'nowrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'CustomFieldWithLabelNarrow'
            },
            source: 'requiredByDate',
            options: {
              label: 'Required By',
              isUtcDate: true,
              fullWidth: false,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          width: '453px',
          margin
        },
        contents: [
          {
            component: {
              default: 'CustomFieldWithLabelNarrow'
            },
            source: 'tagNames',
            options: {
              label: 'Tags',
              isMultipleSelection: true,
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              showChip: true
            }
          }
        ]
      }
    ]
  },

  // Divider
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1,
      margin: '8px 0px 0px'
    },
    contents: [
      {
        component: {
          default: 'CustomDividerStyled'
        }
      }
    ]
  }
];

const shippingInformationContents = [
  // the 2nd section title
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1
    },
    contents: [
      {
        options: {
          margin
        },
        contents: [
          {
            component: {
              default: 'MuiFormSectionTitle'
            },
            options: {
              label: 'Shipping Information'
            }
          }
        ]
      }
    ]
  },
  // the 1st row
  {
    options: {
      size: 3,
      direction: 'row',
      grow: 0,
      flexWrap: 'nowrap',
      width: 714
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'CustomFieldWithLabelNarrow'
            },
            source: 'shipToName',
            options: {
              label: 'Ship To Name',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              nowrap: false
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          width: '453px',
          margin
        },
        contents: [
          {
            component: {
              default: 'CustomFieldWithLabelNarrow'
            },
            source: 'shipToInstructions',
            options: {
              label: 'Shipping Instructions',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              nowrap: false
            }
          }
        ]
      }
    ]
  },
  // the 2nd row
  {
    options: {
      size: 3,
      direction: 'row',
      grow: 0,
      flexWrap: 'nowrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'CustomFieldWithLabelNarrow'
            },
            source: 'shipTo',
            options: {
              label: 'Ship to',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          width: '453px',
          margin
        },
        contents: [
          {
            component: {
              default: 'CustomFieldWithLabelNarrow'
            },
            source: 'shippingAddress',
            options: {
              label: 'Shipping Address',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      }
    ]
  },

  // Divider
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 1,
      margin: '8px 0px 0px'
    },
    contents: [
      {
        component: {
          default: 'CustomDividerStyled'
        }
      }
    ]
  }
];

const layout = () => {
  return {
    fields: {
      ...generateDefaultFieldsObject(generalInformationFields),
      ...generateDefaultFieldsObject(shippingInformationFields)
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [...generalInformationContents(), ...shippingInformationContents]
      }
    }
  };
};

export { generalInformationFields, shippingInformationFields, layout };
