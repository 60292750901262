import React from 'react';

import Typography from '@material-ui/core/Typography';

import { useStyles } from './SectionTitle.styles';

const SectionTitle = () => {
  const styles = useStyles();
  return (
    <Typography css={styles.sectionTitle} variant="subtitle1">
      Upload New Attachment
    </Typography>
  );
};

export default SectionTitle;
