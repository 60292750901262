const sidebarData = [
  {
    source: 'status',
    label: 'Status',
    component: 'sidebarHighlightedItem'
  },
  {
    source: 'SendTo.name',
    label: 'Sent To'
  },
  {
    source: 'ReturnTo.name',
    label: 'From'
  },
  {
    source: 'subject',
    label: 'Subject'
  },
  {
    source: 'informationRequested',
    label: 'Information Requested'
  },
  {
    source: 'dueDateText',
    label: 'Due Date'
  },
  {
    source: 'impactToTimeline',
    label: 'Impact to Timeline'
  },
  {
    source: 'impactToCost',
    label: 'Impact to Cost'
  },
  {
    source: 'specRef',
    label: 'Spec Ref'
  },
  {
    source: 'drawingRef',
    label: 'Drawing Ref'
  },
  {
    source: 'detailRef',
    label: 'Detail Ref'
  }
];

export default sidebarData;
