import React from 'react';

import { array, bool, func } from 'prop-types';

import { isImageFile } from '@pm/components/AttachmentsV2/Attachments.helpers';
import SergeantModal from 'components/SergeantModal';

import attachmentsLayout from './AddAttachmentsModal.layout';
import AddAttachmentsButton from './components/AddAttachmentsButton';
import AddAttachmentsFromFilesButton from './components/AddAttachmentsFromFilesButton';
import FilesListWrapper from './components/FilesListWrapper';
import ImagesCarouselWrapper from './components/ImagesCarouselWrapper';
import SectionTitle from './components/SectionTitle';

const AddAttachmentsModal = ({
  open,
  images,
  files,
  disablePrimaryButton,
  onImagesSelected,
  onFilesSelected,
  onPrimaryAction,
  onOpenFilesModal,
  onClose
}) => {
  const handleAddFile = event => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const fileData = {
      file,
      name: file.name,
      description: '',
      newName: '',
      selected: true
    };

    if (isImageFile(file)) {
      onImagesSelected([
        ...images,
        {
          ...fileData,
          url: URL.createObjectURL(file)
        }
      ]);
    } else {
      onFilesSelected([...files, fileData]);
    }
  };

  const handleSelectImages = newSelectedImages => {
    const newSelectedImageUrls = newSelectedImages.map(({ url }) => url);
    onImagesSelected(
      images
        .filter(image => newSelectedImageUrls.includes(image.url))
        .map(image => ({
          ...image,
          selected: true
        }))
    );
  };

  const handleSelectFiles = newSelectedFiles => {
    const newSelectedFileUrls = newSelectedFiles.map(({ fileUrl }) => fileUrl);
    onFilesSelected(
      files
        .filter(file => newSelectedFileUrls.includes(file.fileUrl))
        .map(file => ({
          ...file,
          selected: true
        }))
    );
  };

  return (
    <SergeantModal
      open={open}
      title="Add Attachment(s)"
      customPrimaryButtonLabel="Add Selected Attachment(s)"
      data={{ images, files }}
      dataType="attachment"
      mode="activate"
      layout={attachmentsLayout({
        onSelectImages: handleSelectImages,
        onSelectFiles: handleSelectFiles,
        onAddFiles: handleAddFile,
        onOpenFilesModal
      })}
      handlePrimaryAction={onPrimaryAction}
      handleClose={onClose}
      maxWidth={859}
      customComponents={{
        AddAttachmentsButton,
        AddAttachmentsFromFilesButton,
        FilesList: FilesListWrapper,
        ImagesCarousel: ImagesCarouselWrapper,
        SectionTitle
      }}
      disablePrimaryButton={disablePrimaryButton}
      alignCloseRight
    />
  );
};

AddAttachmentsModal.propTypes = {
  open: bool.isRequired,
  images: array.isRequired,
  files: array.isRequired,
  disablePrimaryButton: bool.isRequired,
  onImagesSelected: func.isRequired,
  onFilesSelected: func.isRequired,
  onPrimaryAction: func.isRequired,
  onOpenFilesModal: func.isRequired,
  onClose: func.isRequired
};

export default AddAttachmentsModal;
