import gql from 'graphql-tag';

const updateCustomer = gql`
  mutation updateCustomer($partitionKey: String, $data: UpdateCustomerInput!) {
    updateCustomer(partitionKey: $partitionKey, data: $data) {
      id
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      version
      status
      isActive
    }
  }
`;

export default updateCustomer;
