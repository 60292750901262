export const useStyles = () => ({
  viewModeIconContainer: theme => ({
    height: 40,
    marginRight: theme.default.spacing(3),
    '& > *': {
      height: 40,
      marginRight: theme.default.spacing(0.5)
    }
  }),
  viewModeIcon: {
    cursor: 'pointer'
  }
});
