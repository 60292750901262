import React, { Fragment, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withBackgroundColor } from 'components';

/*
 * A skeleton loader component that can eventually replace almost all spinners in the application.
 * Accepts two optional props:
 * 1. `variant` (either 'card' or 'table'; 'card' by default). Determines the structure of the loading "skeleton."
 * 2. `repeatCount` (a positive integer; 1 by default). The number of cards or table skeleton loaders
 * to return in sequence in column layout. This is far preferable to multiple top-level <Placeholder />
 * components because even just a few shimmer animations are very computationally expensive and begin
 * to impact performance. It is much better to have a lot of masking divs over 1 or 2 underlying shimmer animations.
 */

const useStylesPlaceholder = makeStyles(theme => ({
  '@keyframes placeholderShimmer': {
    '0%': {
      backgroundPosition: '0vw 0'
    },
    '100%': {
      backgroundPosition: '100vw 0'
    }
  },
  placeholderRoot: ({ paddingLeft, paddingRight, paddingTop, paddingBottom }) => ({
    width: '100%',
    padding:
      paddingLeft || paddingRight || paddingTop || paddingBottom ? undefined : theme.spacing(4),
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom
  }),
  shimmerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  shimmer: {
    animationName: '$placeholderShimmer',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    background: 'linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)',
    backgroundSize: `100vw 100vh`,
    position: 'relative'
  },
  spacer: ({ backgroundColor, repeatSpacing }) => ({
    width: '100%',
    height: repeatSpacing,
    backgroundColor
  })
}));
const Placeholder = forwardRef((props, ref) => {
  const { selfBackgroundColor, paddingLeft, paddingRight, paddingTop, paddingBottom } = props;
  const variant = props.variant || 'card';
  const dimensionsByVariant = {
    tablehead: {
      height: 2
    },
    table: {
      height: 8,
      spacing: 22,
      repeatSpacing: 22
    },
    card: {
      height: 7,
      spacing: 7,
      repeatSpacing: 22
    }
  };
  const repeatCount = props.repeatCount || 1;
  const classes = useStylesPlaceholder({
    backgroundColor: selfBackgroundColor,
    repeatSpacing: dimensionsByVariant[variant].repeatSpacing,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom
  });

  const renderPlaceholderForVariant = type => {
    const placeholderTypes = {
      tablehead: (
        <TableHeadPlaceholder
          backgroundColor={selfBackgroundColor}
          lineParams={dimensionsByVariant.tablehead}
        />
      ),
      table: (
        <TablePlaceholder
          backgroundColor={selfBackgroundColor}
          lineParams={dimensionsByVariant.table}
        />
      ),
      card: (
        <CardPlaceholder
          backgroundColor={selfBackgroundColor}
          lineParams={dimensionsByVariant.card}
        />
      )
    };
    return placeholderTypes[type];
  };

  return (
    <div className={classes.placeholderRoot} ref={ref}>
      <div className={`${classes.shimmerWrapper} ${classes.shimmer}`}>
        {[...Array(repeatCount).keys()].map(index => (
          <Fragment key={`placeholder-${variant}-${index}`}>
            {renderPlaceholderForVariant(variant)}
            {index < repeatCount - 1 && <div className={classes.spacer} />}
          </Fragment>
        ))}
      </div>
    </div>
  );
});

function TablePlaceholder(props) {
  return (
    <LineRows
      rowWidthPercentages={[
        [30, 50, 20],
        [35, 35, 30],
        [32, 28, 40],
        [10, 50, 10, 20]
      ]}
      {...props.lineParams}
      backgroundColor={props.backgroundColor}
    />
  );
}

function TableHeadPlaceholder(props) {
  return (
    <LineRows
      rowWidthPercentages={[[10, 20, 10, 20]]}
      {...props.lineParams}
      backgroundColor={props.backgroundColor}
    />
  );
}

const useStylesCard = makeStyles(() => {
  const containerHeight = 40; // In px
  return {
    avatarRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: containerHeight
    },
    avatar: {
      width: containerHeight,
      height: containerHeight
    },
    avatarPaddingRight: ({ backgroundColor }) => ({
      height: containerHeight,
      width: 12,
      backgroundColor
    }),
    avatarLinesContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flexGrow: 1,
      height: containerHeight
    },
    avatarPaddingBottom: ({ backgroundColor }) => ({
      backgroundColor,
      width: '100%',
      height: 12
    }),
    detailLinesContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      minHeight: containerHeight
    }
  };
});
function CardPlaceholder(props) {
  const { backgroundColor, lineParams } = props;
  const classes = useStylesCard({ backgroundColor });
  return (
    <>
      <div className={classes.avatarRow}>
        <div className={classes.avatar} />
        <div className={classes.avatarPaddingRight} />
        <div className={classes.avatarLinesContainer}>
          <LineRows
            rowWidthPercentages={[[75], [55]]}
            {...lineParams}
            backgroundColor={backgroundColor}
          />
        </div>
      </div>
      <div className={classes.avatarPaddingBottom} />
      <div className={classes.detailLinesContainer}>
        <LineRows
          rowWidthPercentages={[[60], [45], [55]]}
          {...lineParams}
          backgroundColor={backgroundColor}
        />
      </div>
    </>
  );
}

/*
 * Expects a two-dimensional array of widths.
 * Generates a placeholder of the form:
 *
 * ------------------------------------ (100%)
 * ---------------------------          (70%)
 * -------------------------------      (80%)
 * --------------------                 (55%)
 *
 * For an array with shape [[100], [70], [80], [55]], or:
 *
 * ---------  ----------------  ------ (30, 50, 20)
 * ------------  -----------  -------- (35, 35, 30)
 * -----------  -------  ------------- (32, 28, 40)
 * ----  -------------  ----  -------- (10, 50, 10, 20)
 *
 * For an array with shape [[30, 50, 20], [35, 35, 30], [32, 28, 40], [10, 50, 10, 20]].
 */
const useStylesLineRows = makeStyles({
  outerPadding: ({ backgroundColor }) => ({
    flexGrow: 1,
    width: '100%',
    backgroundColor
  }),
  shimmerContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  betweenLineSpacing: ({ backgroundColor, lineSpacing }) => ({
    backgroundColor,
    width: '100%',
    height: lineSpacing
  })
});
function LineRows(props) {
  const { rowWidthPercentages, height, spacing, backgroundColor } = props;
  const defaultLineHeight = 10;
  const defaultSpacing = 5;
  const classes = useStylesLineRows({ backgroundColor, lineSpacing: spacing ?? defaultSpacing });
  const { outerPadding, shimmerContainer, betweenLineSpacing } = classes;
  if (!Array.isArray(rowWidthPercentages)) return null;

  // There is nothing unique about the items other than index (duplicate widths are allowable), so disable eslint warning.
  return (
    <>
      <div className={outerPadding} />
      {rowWidthPercentages.map((lineWidths, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`lineRows-${JSON.stringify(lineWidths)}-${index}`}>
          <div className={shimmerContainer}>
            <LineRow
              lineHeight={height ?? defaultLineHeight}
              lineWidths={lineWidths}
              backgroundColor={backgroundColor}
            />
          </div>
          {index < rowWidthPercentages.length - 1 && <div className={betweenLineSpacing} />}
        </Fragment>
      ))}
      <div className={outerPadding} />
    </>
  );
}

function LineRow(props) {
  const { lineHeight, lineWidths, backgroundColor } = props;
  if (!Array.isArray(lineWidths)) return null;
  if (lineWidths.length <= 1) {
    return (
      <>
        <div
          style={{
            width: `${lineWidths[0]}%`,
            height: lineHeight
          }}
        />
        <div
          style={{
            width: `${100 - lineWidths[0]}%`,
            height: lineHeight,
            backgroundColor
          }}
        />
      </>
    );
  }
  const betweenPaddingPercent = 6;
  return lineWidths.map((width, index) => {
    const isLastLine = index === lineWidths.length - 1;
    const actualWidthPercent =
      width - (index === 0 || isLastLine ? betweenPaddingPercent / 2 : betweenPaddingPercent);
    return (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={`placeholderRow-${index}`}>
        <div style={{ width: `${actualWidthPercent}%`, height: lineHeight }} />
        {!isLastLine && (
          <div
            style={{ width: `${betweenPaddingPercent}%`, height: lineHeight, backgroundColor }}
          />
        )}
      </Fragment>
    );
  });
}

export default withBackgroundColor(Placeholder);
