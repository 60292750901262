/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getPurchaseOrderTypes() {
  return await instance
    .get(`PurchaseOrderTypes`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getPurchaseOrderTypeById(id) {
  return await instance
    .get(`PurchaseOrderTypes/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function purchaseOrderTypeCreate(purchaseOrderType) {
  return await instance
    .post('PurchaseOrderTypes', purchaseOrderType)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function purchaseOrderTypeChange(id, purchaseOrder) {
  return await instance
    .put(`PurchaseOrderTypes/${id}`, purchaseOrder)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function purchaseOrderTypeSearch(term, searchColumns, filterFunc) {
  if (term.length > 0) {
    return await instance
      .post(`PurchaseOrderTypes/search/${term}`, { searchColumns: searchColumns || ['name'] })
      .then(({ data }) => {
        return filterFunc ? data.filter(filterFunc) : data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  }
  const poTypes = await getPurchaseOrderTypes();
  return filterFunc ? poTypes.filter(filterFunc) : poTypes;
}
