import gql from 'graphql-tag';

const deleteJobTag = gql`
  mutation deleteJobTag($partitionKey: String!, $id: String!) {
    softDeleteJobTag(partitionKey: $partitionKey, id: $id) {
      id
      tagName
    }
  }
`;

export default deleteJobTag;
