import gql from 'graphql-tag';

const getItemGLGroups = gql`
  query getCompany($partitionKey: String!, $sortKey: String!, $nextToken: String) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      itemGlGroups(entityConnection: "ItemGlGroup", nextToken: $nextToken) {
        nextToken
        items {
          id
          name
          isActive
        }
      }
    }
  }
`;

export default getItemGLGroups;
