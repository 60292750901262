import React from 'react';
import _ from 'lodash';
import { Cache } from 'aws-amplify';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ViewIcon from '@material-ui/icons/OpenInNew';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography, Chip, Button } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import * as R from 'ramda';
import moment from 'moment';
import { CheckCircle } from '@material-ui/icons';
import { connect } from 'react-redux';
import StorageService from 'services/StorageService';
import { Logger } from 'services/Logger';
import UserPermission from 'components/AppPermissions';
import Modal from 'components/Modal';
import { checkPermission, removeNestedJson } from 'utils';
import AppConstants from 'utils/AppConstants';
import ErrorBoundaries from 'scenes/Error';
import BlueTextButton from 'components/Buttons/BlueText';
import SectionHeader from 'components/SectionHeader';

import 'react-daterange-picker/dist/css/react-calendar.css';

import TableThumbnail from './TableThumbnail';
import styles from './styles';
import EnhancedTableHead from './TableHead';
import EnhancedTableToolbar from './TableToolbar';
import ChipArray from '../ChipArray';
import MultiNotes from '../MultiNotes';
import ImageThumbnail from '../ImageThumbnail';
import ImageComponent from '../Image';
import TooltipIcon from '../ToolTipIcon';
import LinkToImage from '../ImagesLink';

let counter = 0;

const buttons = {
  save: {
    label: 'Apply filter',
    newLabel: 'Next',
    color: 'secondary',
    buttonType: 'contained'
  },
  cancel: {
    label: 'Reset',
    color: 'secondary',
    buttonType: 'outlined'
  }
};

function insertIdColumn(jsonRecord) {
  counter += 1;
  return { ...jsonRecord, rowId: counter };
}

function desc(a, b, orderBy) {
  if (a[orderBy] && typeof a[orderBy] === 'string' && b[orderBy]) {
    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
      return -1;
    }
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
      return 1;
    }
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  if (!a[orderBy]) {
    return -1;
  }

  if (!b[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const RouterLink = React.forwardRef((props, ref) => <Link {...props} ref={ref} />);

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: this.props.sortOrder || 'asc',
      orderBy: this.props.defaultSort || this.props.captionAttributes[0].id || 'id',
      selected: [],
      selectedFilter: 'All',
      page: 0,
      rowsPerPage: Cache.getItem(this.props.tableId) || 5,
      data: [],
      noteData: [],
      openNotePopUp: false,
      notePopupTitle: '',
      parent: '',
      isOpenFilterModal: false
    };
  }

  componentWillUpdate = prevProps => {
    if (
      this.props.queryResult &&
      prevProps.queryResult &&
      this.props.queryResult.length !== prevProps.queryResult.length
    ) {
      // eslint-disable-next-line react/no-will-update-set-state
      this.setState({ page: 0 });
    }
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.rowId) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleSelectAll = (event, data) => {
    const { order, orderBy, page, rowsPerPage } = this.state;
    const records = event.target.checked
      ? stableSort(data, getSorting(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : [];
    this.props.handleSelectAll(records);
  };

  downloadFile = async (fileName, actualFileName) => {
    if (!fileName) {
      return;
    }
    try {
      const storageService = new StorageService();
      const url = await storageService.getFile(fileName, false);
      if (url) {
        const aLink = document.createElement('a');
        aLink.target = '_blank';
        aLink.download = actualFileName;
        aLink.href = url;
        aLink.click();
      }
    } catch (error) {
      // avoid sending to Sentry as not all images will have thumbnails
      Logger.info(`Error getting image ${error}`);
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
    if (this.props.tableId) {
      const timestamp = moment()
        .add(1, 'year')
        .valueOf();
      Cache.setItem(this.props.tableId, event.target.value, { expires: timestamp });
    }
  };

  isSelected = rowId => this.state.selected.indexOf(rowId) !== -1;

  handleValueRendering = (record, key, fieldType, other, rowData) => {
    if (record[key] === 'undefined') {
      return '-';
    }

    if (fieldType === 'text') {
      return record[key];
    }

    if (fieldType === 'textWithThumbnail') {
      return record[key];
    }

    if (fieldType === 'date') {
      if (!record[key]) {
        return '';
      }
      const momentObj = isNaN(record[key]) ? moment(record[key]) : moment.unix(record[key]);
      return momentObj.format(AppConstants.DATE_FORMAT);
    }

    if (fieldType === 'datetime') {
      if (!record[key]) {
        return '';
      }
      const momentObj = isNaN(record[key]) ? moment(record[key]) : moment.unix(record[key] / 1000);
      return momentObj.format(AppConstants.DATETIME_FORMAT);
    }

    if (fieldType === 'dateOnly') {
      if (!record[key]) {
        return '';
      }
      const momentObj = isNaN(record[key]) ? moment(record[key]) : moment.unix(record[key] / 1000);
      return momentObj.format(AppConstants.DATE_FORMAT);
    }

    if (fieldType === 'boolean') {
      return record[key] === true ? 'Yes' : 'No';
    }

    if (fieldType === 'checkbox') {
      return <Checkbox checked={!!record[key]} />;
    }

    if (fieldType === 'note' && rowData) {
      return (
        <Button
          disableFocusRipple
          disableTouchRipple
          disableRipple
          className={this.props.classes.linkText}
          onClick={() =>
            this.setState({
              openNotePopUp: true,
              notePopupTitle: 'Notes',
              noteData: other,
              parent: rowData.mappedEntity || rowData
            })
          }
        >
          {record[key] === 0 && 'Add note'}
          {record[key] !== 0 && record[key]}
        </Button>
      );
    }

    if (fieldType === 'attachment' && other) {
      return (
        <Button
          classes={{
            outlinedSecondary: this.props.classes.buttonOutlinedSecondary,
            label: this.props.classes.buttonLabel
          }}
          onClick={() => this.downloadFile(other, `${record.customFileName || record[key]}`)}
        >
          {this.props.enableThumbnails && <TableThumbnail fileName={record.fileUrl} />}
          {record.customFileName || record[key]}
        </Button>
      );
    }

    if (fieldType === 'currency') {
      if (!record[key]) {
        return (0).toFixed(2);
      }
      let value = record[key];
      if (typeof value !== 'number') {
        value = parseFloat(value);
      }

      return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    }

    if (fieldType === 'percentage') {
      if (record[key]) return `${record[key]}%`;
      return '-';
    }

    if (fieldType === 'image') {
      return <ImageComponent image={{ fileUrl: record[key] }} />;
    }
    if (fieldType === 'thumbnail') {
      return <ImageThumbnail image={{ fileUrl: record[key] }} />;
    }
    if (fieldType === 'dateTime') {
      if (!record[key]) {
        return '';
      }
      const momentObj = isNaN(record[key]) ? moment(record[key]) : moment.unix(record[key] / 1000);
      return momentObj.format(AppConstants.DATETIME_FORMAT);
    }

    if (fieldType === 'tooltip' && other) {
      return <TooltipIcon hoverText={record[key]} hoverTarget={other} />;
    }

    if (fieldType === 'linkToImage' && other) {
      return <LinkToImage images={other} classes={this.props.classes} />;
    }

    return record[key];
  };

  buttonAttributes = applyFilters => {
    buttons.save.action = () => {
      if (applyFilters) {
        applyFilters({ ...this.state.filter });
      }
    };
    buttons.cancel.action = () => {
      // applyFilters({});
      // setErrors({});
      // setFilterConditions({});
      // handleClose(false);
    };
    return buttons;
  };

  render() {
    const {
      classes,
      title,
      showRowButtons,
      showRowButtonsIfFn,
      showSelectableRows,
      disableRowSelection,
      selectableActionFn,
      // selectableRowAction,
      overrideRowButtonsToolTip,
      showTableHeader,
      filterBy,
      caslKey,
      refreshTable,
      customLinkText,
      width,
      queryResult,
      dataPathInQueryResult,
      nonEditableList,
      grandTotal,
      customSelectIcon,
      summary,
      user,
      isFilterModal,
      applyFilters,
      filterModalBody,
      byFilterChip,
      enhancedSearchTool,
      removeAFilter,
      isSearchFilter
    } = this.props;

    let displayDataArray = null;

    if (queryResult && !_.isEmpty(dataPathInQueryResult)) {
      const path = dataPathInQueryResult.split('.');
      path.forEach(element => {
        if (displayDataArray === null) displayDataArray = queryResult[element];
        else displayDataArray = displayDataArray[element];
      });
    } else {
      displayDataArray = queryResult;
    }

    const data = [];

    if (displayDataArray && displayDataArray.length > 0) {
      displayDataArray.forEach(dataElement => {
        data.push(insertIdColumn(dataElement));
      });
      //  this.setState(prevState => ({ ...prevState, data: data, displayData: data }));
    }

    const highlightFirstColumn = !!this.props.highlightFirstColumn;
    let filterCriterias;
    if (filterBy) {
      filterCriterias = R.uniq(R.pluck(filterBy, this.state.data));
      if (filterCriterias.length > 0) {
        filterCriterias = R.insert(0, 'All', filterCriterias);
      }
    }

    const captionAttributes = this.props.captionAttributes.filter(attribute =>
      checkPermission(attribute.caslAction || 'read', attribute.caslKey, this.props.user)
    );
    // const data = this.state.displayData;

    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const hasActionsInTable = Boolean(showRowButtons) || Boolean(showSelectableRows) || false;
    return (
      <Paper className={classes.root}>
        {isSearchFilter && (
          <Box bgcolor="background.paper" color="text.primary" pb={2} position="relative">
            {enhancedSearchTool}
          </Box>
        )}
        <ErrorBoundaries>
          {filterBy && (
            <div style={{ marginBottom: 10 }}>
              <ChipArray
                dataArr={filterCriterias}
                handleClick={this.handleChipFilter}
                selected={this.state.selectedFilter}
              />
            </div>
          )}
          {!_.isEmpty(byFilterChip) &&
            byFilterChip.map((filter, index) => (
              <Chip
                color="primary"
                label={filter.label}
                onDelete={() => removeAFilter(filter.fieldName, index)}
                style={{ margin: 3 }}
              />
            ))}
          {isFilterModal && (
            <>
              <BlueTextButton
                label="Filter"
                onClick={() => this.setState({ isOpenFilterModal: true })}
              />
              <Modal
                open={this.state.isOpenFilterModal}
                handleClose={() => this.setState({ isOpenFilterModal: false })}
                width={740}
                buttons={this.buttonAttributes(applyFilters)}
              >
                <ErrorBoundaries>
                  <SectionHeader title="Filter" enablePadding />
                  {filterModalBody}
                </ErrorBoundaries>
              </Modal>
            </>
          )}
          {showTableHeader && <EnhancedTableToolbar numSelected={selected.length} title={title} />}
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle" size="small">
              {width !== 'sm' && width !== 'xs' && (
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={data.length}
                  rows={captionAttributes}
                  highlightFirstColumn={highlightFirstColumn}
                  classes={classes}
                  showButtonsColumn={hasActionsInTable}
                  handleSelectAll={event => this.handleSelectAll(event, data)}
                  user={user}
                />
              )}
              <TableBody>
                {data &&
                  stableSort(data, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((dataItem, dataIndex) => {
                      const isSelected = this.isSelected(dataItem.rowId);
                      const isNonEditable =
                        (nonEditableList &&
                          nonEditableList.length > 0 &&
                          nonEditableList.includes(dataItem.id)) ||
                        false;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={dataItem.rowId}
                          selected={isSelected}
                        >
                          <ErrorBoundaries>
                            {width === 'sm' || width === 'xs' ? (
                              <TableCell key={dataItem.id}>
                                {captionAttributes.map((a, index) => (
                                  <Typography>
                                    {a.label} :{' '}
                                    {this.handleValueRendering(
                                      dataItem,
                                      a.id,
                                      a.type || 'text',
                                      a.other ? dataItem[a.other] : null,
                                      dataItem
                                    )}
                                  </Typography>
                                ))}
                              </TableCell>
                            ) : (
                              captionAttributes.map((a, index) => (
                                <TableCell
                                  key={dataItem.rowId + a.id}
                                  align={a.numeric ? 'right' : 'left'}
                                  className={
                                    index === 0 && highlightFirstColumn
                                      ? classes.highlightFirstColumn
                                      : classes.content
                                  }
                                  style={a.type === 'thumbnail' ? { height: 70, width: 70 } : null}
                                >
                                  {a.id === 'checkBox' && (
                                    <Checkbox
                                      checked={this.props.isSelected(dataItem)}
                                      onChange={() => this.props.handleSelectAction(dataItem)}
                                    />
                                  )}
                                  {a.showLink && a.linkPath && (
                                    <Button
                                      classes={{
                                        outlinedSecondary: this.props.classes
                                          .buttonOutlinedSecondary,
                                        label: this.props.classes.buttonLabel
                                      }}
                                      component={RouterLink}
                                      to={
                                        a.linkStateKey && a.linkStateValue
                                          ? {
                                              pathname: `${a.linkPath}/${
                                                dataItem[a.linkReference]
                                              }`,
                                              state: {
                                                [a.linkStateKey]: dataItem[a.linkStateValue]
                                              }
                                            }
                                          : {
                                              pathname: `${a.linkPath}/${dataItem[a.linkReference]}`
                                            }
                                      }
                                      // onClick={() => this.props.rowActions(a.showLink, n)}
                                    >
                                      {customLinkText &&
                                        a.linkText &&
                                        this.handleValueRendering(
                                          dataItem,
                                          a.linkText || a.id,
                                          a.type || 'text',
                                          a.other ? dataItem[a.other] : null,
                                          dataItem
                                        )}
                                      {!customLinkText &&
                                        dataItem[a.id] &&
                                        this.handleValueRendering(
                                          dataItem,
                                          a.id,
                                          a.type || 'text',
                                          a.other ? dataItem[a.other] : null,
                                          dataItem
                                        )}
                                      {a.checkIcon && dataItem[a.checkField] && (
                                        <CheckCircle
                                          style={{
                                            fontSize: '12',
                                            marginTop: '2',
                                            color: '#2dce89'
                                          }}
                                        />
                                      )}
                                    </Button>
                                  )}
                                  {a.showLink && !a.linkPath && (
                                    <Button
                                      classes={{
                                        outlinedSecondary: this.props.classes
                                          .buttonOutlinedSecondary,
                                        label: this.props.classes.buttonLabel
                                      }}
                                      onClick={() => this.props.rowActions(a.showLink, dataItem)}
                                    >
                                      {dataItem[a.id]
                                        ? this.handleValueRendering(
                                            dataItem,
                                            a.id,
                                            a.type || 'text',
                                            a.other ? dataItem[a.other] : null,
                                            dataItem
                                          )
                                        : ''}
                                    </Button>
                                  )}
                                  {!a.showLink &&
                                    (dataItem[a.id] !== undefined ||
                                      dataItem[a.id] !== '' ||
                                      dataItem[a.id] === 0) &&
                                    this.handleValueRendering(
                                      dataItem,
                                      a.id,
                                      a.type || 'text',
                                      a.other ? dataItem[a.other] : null,
                                      dataItem
                                    )}
                                </TableCell>
                              ))
                            )}
                          </ErrorBoundaries>
                          <ErrorBoundaries>
                            {hasActionsInTable && (
                              <TableCell align="right" className={classes.lastColumn}>
                                {showRowButtons &&
                                  showRowButtons.includes('view') &&
                                  !(
                                    Boolean(showRowButtonsIfFn) && !showRowButtonsIfFn(dataItem)
                                  ) && (
                                    <UserPermission I="view" action={caslKey}>
                                      <Tooltip
                                        title={
                                          (overrideRowButtonsToolTip &&
                                            overrideRowButtonsToolTip.view) ||
                                          'View details '
                                        }
                                      >
                                        <IconButton
                                          aria-label="View details"
                                          className={classes.buttonHover}
                                          onClick={() =>
                                            this.props.rowActions(
                                              'view',
                                              dataItem,
                                              this.props.refetch,
                                              dataIndex
                                            )
                                          }
                                        >
                                          <ViewIcon className={classes.iconColor} />
                                        </IconButton>
                                      </Tooltip>
                                    </UserPermission>
                                  )}
                                {showRowButtons &&
                                  showRowButtons.includes('edit') &&
                                  !isNonEditable &&
                                  !(
                                    Boolean(showRowButtonsIfFn) && !showRowButtonsIfFn(dataItem)
                                  ) && (
                                    <UserPermission I="edit" action={caslKey}>
                                      <Tooltip
                                        title={
                                          (overrideRowButtonsToolTip &&
                                            overrideRowButtonsToolTip.edit) ||
                                          'Edit'
                                        }
                                      >
                                        <IconButton
                                          aria-label="Edit"
                                          className={classes.buttonHover}
                                          onClick={() =>
                                            this.props.rowActions(
                                              'edit',
                                              dataItem,
                                              this.props.refetch,
                                              dataIndex
                                            )
                                          }
                                        >
                                          <EditIcon className={classes.iconColor} />
                                        </IconButton>
                                      </Tooltip>
                                    </UserPermission>
                                  )}
                                {showRowButtons &&
                                  showRowButtons.includes('copy') &&
                                  !isNonEditable &&
                                  !(
                                    Boolean(showRowButtonsIfFn) && !showRowButtonsIfFn(dataItem)
                                  ) && (
                                    <UserPermission I="copy" action={caslKey}>
                                      <Tooltip
                                        title={
                                          (overrideRowButtonsToolTip &&
                                            overrideRowButtonsToolTip.edit) ||
                                          'Copy'
                                        }
                                      >
                                        <IconButton
                                          aria-label="Copy"
                                          className={classes.buttonHover}
                                          onClick={() =>
                                            this.props.rowActions(
                                              'copy',
                                              dataItem,
                                              this.props.refetch,
                                              dataIndex
                                            )
                                          }
                                        >
                                          <FileCopyIcon className={classes.iconColor} />
                                        </IconButton>
                                      </Tooltip>
                                    </UserPermission>
                                  )}
                                {showRowButtons &&
                                  showRowButtons.includes('delete') &&
                                  !isNonEditable &&
                                  !(
                                    Boolean(showRowButtonsIfFn) && !showRowButtonsIfFn(dataItem)
                                  ) && (
                                    <UserPermission I="delete" action={caslKey}>
                                      <Tooltip
                                        title={
                                          (overrideRowButtonsToolTip &&
                                            overrideRowButtonsToolTip.delete) ||
                                          'Delete '
                                        }
                                      >
                                        <IconButton
                                          aria-label="Delete"
                                          className={classes.buttonHover}
                                          onClick={() =>
                                            this.props.rowActions(
                                              'delete',
                                              dataItem,
                                              this.props.refetch,
                                              dataIndex
                                            )
                                          }
                                        >
                                          <DeleteIcon className={classes.iconColor} />
                                        </IconButton>
                                      </Tooltip>
                                    </UserPermission>
                                  )}
                                {showRowButtons &&
                                  showRowButtons.includes('select') &&
                                  !(
                                    Boolean(showRowButtonsIfFn) && !showRowButtonsIfFn(dataItem)
                                  ) && (
                                    <UserPermission I="delete" action={caslKey}>
                                      <Tooltip
                                        title={
                                          (overrideRowButtonsToolTip &&
                                            overrideRowButtonsToolTip.select) ||
                                          'Select'
                                        }
                                      >
                                        <IconButton
                                          aria-label="Select"
                                          className={classes.buttonHover}
                                          onClick={() =>
                                            this.props.rowActions(
                                              'select',
                                              dataItem,
                                              this.props.refetch,
                                              dataIndex
                                            )
                                          }
                                        >
                                          {customSelectIcon ? (
                                            React.createElement(customSelectIcon, {
                                              color: 'secondary',
                                              className: classes.iconColor
                                            })
                                          ) : (
                                            <AddCircleIcon className={classes.iconColor} />
                                          )}
                                        </IconButton>
                                      </Tooltip>
                                    </UserPermission>
                                  )}
                                {showSelectableRows && (
                                  <Checkbox
                                    onChange={() =>
                                      selectableActionFn(
                                        dataItem,
                                        !dataItem[showSelectableRows],
                                        dataIndex
                                      )
                                    }
                                    className={classes.checkboxStyle}
                                    checked={dataItem[showSelectableRows]}
                                    disabled={disableRowSelection}
                                  />
                                )}
                              </TableCell>
                            )}
                          </ErrorBoundaries>
                        </TableRow>
                      );
                    })}

                {data.length !== 0 && summary && summary.length && (
                  <TableRow>
                    {summary.map((item, index) => {
                      const a = captionAttributes[index];

                      return (
                        <TableCell
                          align={a && a.numeric ? 'right' : 'left'}
                          style={styles.summary}
                          key={a.id}
                          className={classes.summary}
                        >
                          {item}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )}

                {data.length !== 0 && emptyRows > 0 && grandTotal && (
                  <ErrorBoundaries>
                    <TableRow>
                      <TableCell colSpan={3} />
                      <TableCell style={{ fontSize: '16px', fontWeight: 'bold' }}>
                        Grand Total
                      </TableCell>
                      <TableCell style={{ fontSize: '16px', fontWeight: 'bold' }}>
                        ${grandTotal.totalCost}
                      </TableCell>
                      <TableCell style={{ fontSize: '16px', fontWeight: 'bold' }}>
                        ${grandTotal.totalPrice}
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </ErrorBoundaries>
                )}

                {data.length !== 0 && emptyRows > 0 && (
                  <TableRow style={{ height: 49 }}>{/* <TableCell colSpan={6} /> */}</TableRow>
                )}
              </TableBody>
            </Table>
            {data.length === 0 && (
              <Typography className={classes.noData}>{this.props.noDataMsg}</Typography>
            )}
          </div>
          {data && data.length > 5 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page'
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page'
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              classes={{ toolbar: classes.tableFooterToolbar }}
            />
          )}
          <Modal
            open={this.state.openNotePopUp}
            handleClose={() => {
              this.setState({ openNotePopUp: false, notePopupTitle: '', parent: '' });
              refreshTable();
            }}
            width="646"
          >
            <ErrorBoundaries>
              <MultiNotes
                notesData={this.state.noteData}
                title={this.state.notePopupTitle}
                parent={removeNestedJson(this.state.parent)}
              />
            </ErrorBoundaries>
          </Modal>
        </ErrorBoundaries>
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  captionAttributes: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

const styledField = withWidth()(withStyles(styles, { withTheme: true })(EnhancedTable));

export default connect(mapStateToProps)(styledField);
