import React, { useState, useEffect } from 'react';
import { convertStringToFloat } from 'utils';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './style';

const LabourRateInput = ({ meta, record, isReadOnly, handleInputChanges, classes }) => {
  const [detail, setDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { billingHourTypeId } = meta;

  useEffect(() => {
    const { [billingHourTypeId]: rateInfo } = record;

    const data = { ...rateInfo, billingHourTypeId };
    setDetail(data);
  }, [record, billingHourTypeId]);

  const handleChanges = e => {
    const newRate = convertStringToFloat(e.target.value) || 0;
    if (detail.rate === newRate) return; // no change
    const modifiedDetail = { ...detail, rate: newRate };
    setIsLoading(true);
    handleInputChanges(modifiedDetail, updatedDetail => {
      setDetail(updatedDetail);
      setIsLoading(false);
    });
  };

  const detailRate = detail.rate ? `$${detail.rate}` : '-';

  return (
    <Box display="flex" justifyContent="flex-end">
      {isReadOnly ? (
        detailRate
      ) : (
        <CurrencyTextField
          variant="filled"
          value={detail.rate}
          outputFormat="number"
          decimalCharacter="."
          digitGroupSeparator=","
          placeholder="-"
          onBlur={handleChanges}
          modifyValueOnWheel={false}
          minimumValue={0}
          InputProps={{
            className: classes.container,
            disableUnderline: true,
            startAdornment: <Typography variant="subtitle1">$</Typography>
          }}
          disabled={isLoading}
        />
      )}
    </Box>
  );
};

export default withStyles(styles, { withTheme: true })(LabourRateInput);
