const styles = theme => ({
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 10,
    letterSpacing: 0.01,
    fontWeight: 'normal',
    textTransform: 'uppercase'
  },
  boldContent: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.brand.logoBlue,
    fontSize: 18,
    letterSpacing: 0.1,
    fontWeight: 600,
    lineHeight: 'normal',
    paddingTop: 4
  },
  normalContent: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.brand.logoBlue,
    fontSize: 15,
    letterSpacing: 0.3,
    fontWeight: 500,
    lineHeight: 'normal',
    paddingTop: 4
  }
});

export default styles;
