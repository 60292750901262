import React from 'react';
import { View, Image } from '@react-pdf/renderer';

const renderMutliplePages = (signature, numPages) => {
  const addPageNumberToSignatureUrl = (pageUrl, pageNo) =>
    pageUrl.replace('/upload', `/upload/pg_${pageNo}`);

  const signaturePDFPages = [];
  for (let i = 1; i <= numPages; i++) {
    const pageSignUrl = addPageNumberToSignatureUrl(signature.fileUrl, i);
    signaturePDFPages.push(
      <Image
        src={pageSignUrl}
        key={`${signature?.id}img`}
        style={{ objectFit: 'cover', width: '100%' }}
      />
    );
  }
  return signaturePDFPages;
};

export const SignaturesPDF = ({ displayValue }) => (
  <View style={{ flexDirection: 'column', padding: 0, margin: 0 }}>
    {Array.isArray(displayValue) &&
      displayValue?.map(signature => {
        if (!signature.fileUrl) return null;
        // when there are multiple pages in a PDF, each image to be shown in different pages
        const { numPages } = signature;
        if (numPages && numPages > 1) {
          return renderMutliplePages(signature, numPages);
        }
        return (
          <Image
            src={signature.fileUrl}
            key={`${signature?.id}img`}
            style={{ objectFit: 'cover', width: '100%' }}
          />
        );
      })}
  </View>
);

export default SignaturesPDF;
