import { generateDefaultFieldsObject } from '@pm/components/formattingUtils';

const margin = '0px 15px 21px 0px';

const signatureFields = {
  title: { required: false },
  firstName: { required: false },
  lastName: { required: false },
  company: { required: false },
  emailAddress: { required: false },
  signatureImageUrl: { required: false }
};

const signatureContents = (getCanvasRef, index, signatureType) => {
  return [
    {
      options: {
        direction: 'row'
      },
      contents: [
        {
          // column 1
          options: {
            direction: 'column'
          },
          contents: [
            {
              options: {
                direction: 'row'
              },
              contents: [
                {
                  options: {
                    margin,
                    direction: 'column'
                  },
                  contents: [
                    {
                      component: {
                        default: 'TextInput',
                        edit: 'TextInput'
                      },
                      source: 'title',
                      options: {
                        label: 'Title',
                        fullWidth: true,
                        alignment: 'left',
                        variant: 'standard',
                        color: 'secondary'
                      }
                    }
                  ]
                },
                {
                  options: {
                    margin,
                    direction: 'column'
                  },
                  contents: [
                    {
                      component: {
                        default: 'TextInput',
                        edit: 'TextInput'
                      },
                      source: 'firstName',
                      options: {
                        label: 'First Name',
                        fullWidth: true,
                        alignment: 'left',
                        variant: 'standard',
                        color: 'secondary'
                      }
                    }
                  ]
                }
              ]
            },
            {
              options: {
                margin,
                direction: 'column'
              },
              contents: [
                {
                  component: {
                    default: 'SignaturePadStyled',
                    edit: 'SignaturePadStyled'
                  },
                  source: 'signatureImageUrl',
                  options: {
                    getCanvasRef,
                    index,
                    signatureType
                  }
                }
              ]
            }
          ]
        },
        // column 2
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                margin,
                direction: 'column'
              },
              contents: [
                {
                  component: {
                    default: 'TextInput',
                    edit: 'TextInput'
                  },
                  source: 'lastName',
                  options: {
                    label: 'Last Name',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary'
                  }
                }
              ]
            },
            {
              options: {
                margin,
                direction: 'column'
              },
              contents: [
                {
                  component: {
                    default: 'TextInput',
                    edit: 'TextInput'
                  },
                  source: 'company',
                  options: {
                    label: 'Company',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary'
                  }
                }
              ]
            }
          ]
        },
        // Column 3
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                margin,
                direction: 'column'
              },
              contents: [
                {
                  component: {
                    default: 'TextInput',
                    edit: 'TextInput'
                  },
                  source: 'emailAddress',
                  options: {
                    label: 'Email Address',
                    fullWidth: true,
                    alignment: 'left',
                    variant: 'standard',
                    color: 'secondary'
                  }
                }
              ]
            },
            {
              options: {},
              contents: []
            }
          ]
        }
      ]
    }
  ];
};

const signatureLayout = (getCanvasRef, index, signatureType) => {
  return {
    fields: generateDefaultFieldsObject(signatureFields),
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: signatureContents(getCanvasRef, index, signatureType)
      },
      edit: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: signatureContents(getCanvasRef, index, signatureType)
      }
    }
  };
};

export { signatureFields, signatureLayout };
