import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { Switch, ThemeProvider } from '@buildhero/sergeant';
import { object } from 'prop-types';

const FilterSwitch = ({ field, options }) => {
  const { setFieldValue } = useFormikContext();

  const handleToggle = useCallback(
    event => {
      const { checked } = event.target;
      setFieldValue(field.name, checked);
    },
    [field.name, setFieldValue]
  );

  return (
    <ThemeProvider>
      <Switch value={field.value} label={options.label} onChange={handleToggle} />
    </ThemeProvider>
  );
};

FilterSwitch.propTypes = {
  field: object.isRequired,
  options: object
};

FilterSwitch.defaultProps = {
  options: {}
};

export default FilterSwitch;
