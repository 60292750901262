import React, { useState, useEffect } from 'react';
import { MUIFormComponents } from '@buildhero/sergeant';
import Selector from 'components/Select';
import { Paper, List, Button, Box } from '@material-ui/core';
import EmployeeMenuItem from '../EmployeeMenuItem';

const Option = ({ children, data, innerRef, isFocused, innerProps }) => {
  return (
    <div style={{ display: 'flex' }}>
      <EmployeeMenuItem
        id={data.value}
        profilePictureUrl={data.profilePictureUrl}
        name={data.label}
        isFocused={isFocused}
        innerProps={innerProps}
        innerRef={innerRef}
      />
    </div>
  );
};

const Menu = props => {
  const { selectProps, innerProps, children, getStyles, setValue, ...attr } = props;

  const selectedItems = selectProps.value ?? [];

  const removeItemWithValue = value => {
    setValue(selectedItems.filter(item => item.value !== value));
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10
      }}
    >
      <div style={{ width: '50%', position: 'relative', marginRight: 5 }}>
        <MUIFormComponents.LabelControl value="Available" />
        <Paper square className={selectProps.classes.paper} {...innerProps}>
          {children}
        </Paper>
      </div>
      <div style={{ width: '50%', position: 'relative', marginLeft: 5 }}>
        <MUIFormComponents.LabelControl value="Selected" />
        <Paper
          square
          className={selectProps.classes.paper}
          {...innerProps}
          style={getStyles('menuList', { ...attr, selectProps })}
        >
          <List style={{ paddingTop: 0, paddingBottom: 0, flex: 1 }}>
            {selectedItems.map(item => (
              <EmployeeMenuItem
                id={item.value}
                name={item.label}
                profilePictureUrl={item.profilePictureUrl}
                onClickRemove={() => removeItemWithValue(item.value)}
              />
            ))}
          </List>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 10,
              margin: 0
            }}
            boxShadow={3}
          >
            <Button variant="containedPrimary" onClick={() => selectProps.handleAdd()}>
              Add
            </Button>
          </Box>
        </Paper>
      </div>
    </div>
  );
};

function BulkSelect({ onAdd, options, ...props }) {
  const [pendingItems, setPendingItems] = useState([]);

  // if item is removed from options, also remove it from pendingOptions
  useEffect(() => {
    setPendingItems(items => {
      const employeeIdOptions = options.map(option => option.value);
      return items.filter(item => employeeIdOptions.includes(item.value));
    });
  }, [options]);

  return (
    <Selector
      inputWidth="50%"
      menuHeight={300}
      isMulti
      handleAdd={() => {
        onAdd(pendingItems);
        setPendingItems([]);
      }}
      value={pendingItems}
      onChange={items => setPendingItems(items)}
      components={{
        Menu,
        MultiValue: () => null,
        ClearIndicator: () => null,
        Option
      }}
      closeMenuOnSelect={false}
      options={options}
      onMenuClose={() => setPendingItems([])}
      {...props}
    />
  );
}

export default BulkSelect;
