const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  margin: {
    margin: theme.spacing()
  },
  textField: {
    flexBasis: 200
  },
  muiFilledInput: {
    backgroundColor: '#f6f6f6',
    borderRadius: 4
  }
});

export default styles;
