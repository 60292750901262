import React from 'react';
import { object, string } from 'prop-types';
import ImageThumbnail from 'components/ImageThumbnail';
import {
  tableCurrencyFormatter,
  tableDateFormatter,
  tableEmptyValueFormatter
} from 'components/WrapTable';
import { useStyles } from './PurchasedItem.styles';

const Field = ({ label, value }) => {
  const styles = useStyles();
  return (
    <div css={styles.labelContainer}>
      <div css={styles.label}>{label}</div>
      <div>{value}</div>
    </div>
  );
};

Field.propTypes = {
  label: string.isRequired,
  value: string.isRequired
};

const PurchasedItem = ({ item }) => {
  const fileUrl = item?.fileUrl;
  const addedBy = tableEmptyValueFormatter({ value: item?.createdBy });
  const vendor = tableEmptyValueFormatter({ value: item?.vendorName });
  const receiptNumber = tableEmptyValueFormatter({ value: item?.receiptNumber });
  const poNumber = tableEmptyValueFormatter({ value: item?.poNumber });
  const date = tableDateFormatter({ value: item?.dateOfPurchase });
  const total = tableCurrencyFormatter({ value: item?.totalAmountPreTax });
  return (
    <>
      <ImageThumbnail image={{ fileUrl }} />
      <Field label="Added By" value={addedBy} />
      <Field label="Vendor" value={vendor} />
      <Field label="Receipt NO" value={receiptNumber} />
      <Field label="PO No" value={poNumber} />
      <Field label="Date" value={date} />
      <Field label="Total" value={total} />
    </>
  );
};

PurchasedItem.propTypes = {
  item: object.isRequired
};

export default PurchasedItem;
