import gql from 'graphql-tag';

const updateTimeCardLine = gql`
  mutation updateTimeCardLine($partitionKey: String, $data: UpdateTimeCardLineInput!) {
    updateTimeCardLine(partitionKey: $partitionKey, data: $data) {
      id
    }
  }
`;

export default updateTimeCardLine;
