/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { calculateMarginFromMarkup } from '@buildhero/math';
import { Button, Typography, ThemeProvider, TV, TW } from '@buildhero/sergeant';
import invoiceLabels from 'meta/Jobs/Invoice/labels';
import { AddRecordButton } from 'components';
import { AppConstants, BillLineItemStatus } from 'utils/AppConstants';
import { billItemsRows } from 'meta/Jobs/Invoice/review-report-tables';
import PurchasedItemLayout from 'meta/Jobs/Invoice/PurchasedItemForm';
import ErrorBoundaries from 'scenes/Error';
import { Logger } from 'services/Logger';
import ImageThumbnail from 'components/ImageThumbnail';
import { isEmpty } from 'lodash';
import { PricingStrategy } from 'utils/constants';
import { convertForMathLib } from 'utils/mathLibrary';
import { getPurchaseOrderTags } from 'services/API/purchaseOrderTag';
import { constructSelectOptions } from 'utils/constructSelectOptions';
import { JobService } from 'services/core';
import BillModal from './BillModal_old';
import BillItemLine from './BillItemLine';
import ProcurementPurchaseOrderModal from '../../../Procurement/PurchaseOrders/CreatePurchaseOrder';

function getGroupedBillsOnJob(data) {
  return Object.values(
    data?.billLines?.items.reduce((acc, b) => {
      const formattedBillLineItems = {
        billLineId: b.id,
        quantity: b.quantity,
        description: b.description,
        jobId: b.jobId,
        invoicedStatus: b.invoicedStatus,
        remainingQuantity: b.remainingQuantity
      };
      // add edge case where bill is null when it has been deleted
      if (!b.bill) return acc;
      if (b.bill.id in acc) {
        acc[b.bill.id].billLineItems = [...acc[b.bill.id].billLineItems, formattedBillLineItems];
        return acc;
      }
      acc[b.bill.id] = {
        billId: b.bill.id,
        billNumber: b.bill.billNumber,
        billLineItems: [formattedBillLineItems]
      };
      return acc;
    }, {}) || []
  );
}

// Format data to group bill items under bill id in case review report has multiple bill items from the same bill
function getGroupedBills(context) {
  return Object.values(
    context?.reviewReport?.reviewReportBillItems?.items
      ?.filter(item => item && item.billLine)
      .reduce((acc, b) => {
        const formattedBillLineItems = {
          id: b.id,
          billLineId: b.billLineId,
          markup: b.markup,
          marginValue: convertForMathLib(calculateMarginFromMarkup, b.markup),
          quantity: b.quantity,
          unitCost: b.unitCost,
          unitPrice: b.unitPrice,
          description: b.description,
          itemName: b.billLine.product?.name,
          jobId: b.billLine.jobId,
          invoicedStatus: b.billLine.invoicedStatus,
          taxable: b.taxable,
          remainingQuantity: b.billLine.remainingQuantity,
          costCodeId: b?.costCodeId || '',
          jobCostTypeId: b?.jobCostTypeId || '',
          revenueTypeId: b?.revenueTypeId || ''
        };

        if (b.billLine.billId in acc) {
          acc[b.billLine.billId].billLineItems = [
            ...acc[b.billLine.billId].billLineItems,
            formattedBillLineItems
          ];
          return acc;
        }
        acc[b.billLine.billId] = {
          reviewReportBillItemId: b.id,
          id: b.billLine.billId,
          billNumber: b.billLine.bill.billNumber,
          purchaseOrderReceipt: b.billLine.bill.purchaseOrderReceipt,
          purchaseOrder: b.billLine.bill.purchaseOrder,
          billLineItems: [formattedBillLineItems]
        };
        return acc;
      }, {}) || []
  );
}

function addAmountsToBills(billsWithUninvoicedItems) {
  return billsWithUninvoicedItems.map(bill => {
    const editedBills = bill.billLineItems.map(lineItem => {
      const currentBillLineItem = lineItem;
      if (currentBillLineItem && currentBillLineItem.quantity && currentBillLineItem.unitPrice) {
        currentBillLineItem.amount = currentBillLineItem.quantity * currentBillLineItem.unitPrice;
        currentBillLineItem.amount = currentBillLineItem.amount.toFixed(2);
      }
      return currentBillLineItem;
    });

    return { ...bill, billLineItems: editedBills };
  });
}

function isBillLineNotOnReviewReport(billLineItem, existingBillLinesForBill) {
  // if the bill line already exists on the review report, return false
  return !existingBillLinesForBill.find(el => el.billLineId === billLineItem.billLineId);
}

const Bills = ({ current, send, service, user, classes, history, isReviewReport }) => {
  const { context, value } = current;
  const [billsForModal, setBillsForModal] = useState([]);
  const [billsForTable, setBillsForTable] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const marginEnabled = useSelector(s => s.settings.pricingStrategy === PricingStrategy.MARGIN);

  useEffect(() => {
    // Bills already on the reviewReport and that should be displayed in the TABLE
    const groupedBillItems = getGroupedBills(context);
    const billsOnJob = groupedBillItems.filter(
      item =>
        item.id && item.billLineItems.some(lineItem => lineItem.jobId === context?.jobInfoData?.id)
    );
    /* Amount Calculation for Bill Line Items */
    const billsWithAmounts = addAmountsToBills(billsOnJob);
    setBillsForTable(billsWithAmounts);

    async function fetchBillItemsOnJob() {
      try {
        /* Get bills to display as options in MODAL that
        1) that have some bill lines that do not exist on the review report
        2) that contain uninvoiced/partially invoiced line item(s)
        3) that have bill lines with quantity more or less than 0 
        4) that have a jobId not set to null 
        */
        const jobService = new JobService();
        const response = await jobService.getAllBillItemsByJobId(
          context?.jobInfoData?.id,
          context?.user?.tenantId
        );
        if (response) {
          const formattedBills = getGroupedBillsOnJob(response);

          const filteredBillOptions = [];
          formattedBills.forEach(bill => {
            const existingBillLinesForBill =
              billsOnJob.find(el => el.id === bill.billId)?.billLineItems || [];
            const billOption = [];
            bill.billLineItems.forEach(billLineItem => {
              if (
                isBillLineNotOnReviewReport(billLineItem, existingBillLinesForBill) &&
                (billLineItem.invoicedStatus === BillLineItemStatus.PARTIALLY_INVOICED ||
                  billLineItem.invoicedStatus === BillLineItemStatus.NOT_INVOICED) &&
                billLineItem.quantity &&
                billLineItem.jobId
              ) {
                billOption.push(billLineItem);
              }
            });
            if (billOption.length) filteredBillOptions.push({ ...bill, billLineItems: billOption });
          });
          // Using GQL until bug fetching bills using the rest API is resolved (empty array returned from call when there should be bills)
          // const bills = await getBillsForReviewReport(context?.jobInfoData?.id);
          setBillsForModal(filteredBillOptions);
        }
      } catch (error) {
        Logger.error(error);
      }
    }

    getPurchaseOrderTags().then(purchaseOrderTags => {
      setTagOptions(constructSelectOptions(purchaseOrderTags, 'tagName'));
    });

    fetchBillItemsOnJob();
  }, [context?.reviewReport]);

  const PurchasedLayoutMeta = PurchasedItemLayout.entity.layouts.web;
  PurchasedLayoutMeta.buttons.cancel.action = () => send('CLOSE');

  const isEditable = isReviewReport && !context.freezeEdit;

  const billItemsTableMeta = [...billItemsRows(marginEnabled)];
  if (isEditable) {
    billItemsTableMeta.push({
      id: 'actions',
      isCustom: true,
      type: 'actions',
      label: ''
    });
  }

  const open = value?.bill_new || value?.bill_edited || false;
  const title = value.bill_new ? 'Add Bill(s)' : 'Edit Bill';

  const startPO = () => {
    const kickoffPO = () => {
      // open the create purchase order modal
      send('ADD_PO_ITEM_PROCUREMENT');
    };
    kickoffPO();
  };

  const openProcurementPurchaseOrderModal = !!value?.po_item_new_procurement;

  const handleCreatePO = po => {
    if (po.id) history.push(`/procurement/purchaseorders/view/${po.id}`);
    send('CLOSE');
  };

  return (
    <>
      <Grid container justify="flex-start" style={{ marginTop: 20 }} />
      {isEditable && <AddRecordButton label="+ Add purchase order" handle={startPO} />}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ borderTop: '1px solid #e0e0e4' }}>
        <ThemeProvider>
          <Typography className={classes.sectionDescription}>
            {invoiceLabels.bills[user.locale]}
          </Typography>

          {isEditable && (
            <Button
              type="leading"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => send('ADD_BILL')}
            >
              Add bill(s)
            </Button>
          )}
        </ThemeProvider>
        <ErrorBoundaries>
          {billsForTable.map(billItem => (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ borderTop: '1px solid #e0e0e4' }}
            >
              <ThemeProvider>
                <Grid container direction="row" justify="center" alignItems="center">
                  <Grid item xs={10} sm={10} md={10} lg={10} xl={11}>
                    <Grid
                      container
                      style={{ marginTop: 20, marginBottom: 10 }}
                      spacing={4}
                      justify="space-around"
                    >
                      <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                        {billItem.purchaseOrderReceipt?.imageUrl && (
                          <ImageThumbnail
                            image={{
                              fileUrl: billItem.purchaseOrderReceipt?.imageUrl
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item>
                        <Typography variant={TV.S1} weight={TW.BOLD} style={{ padding: '5px 0' }}>
                          Added by
                        </Typography>
                        <Typography variant={TV.BASE}>
                          {billItem.purchaseOrder?.addedBy || billItem.purchaseOrder?.createdBy}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant={TV.S1} weight={TW.BOLD} style={{ padding: '5px 0' }}>
                          Vendor
                        </Typography>
                        <Typography variant={TV.BASE}>
                          {billItem.purchaseOrder?.vendorName}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant={TV.S1} weight={TW.BOLD} style={{ padding: '5px 0' }}>
                          Bill No
                        </Typography>
                        <Typography variant={TV.BASE}>{billItem.billNumber}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant={TV.S1} weight={TW.BOLD} style={{ padding: '5px 0' }}>
                          Receipt No
                        </Typography>
                        <Typography variant={TV.BASE}>
                          {billItem.purchaseOrderReceipt?.receiptNumber}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant={TV.S1} weight={TW.BOLD} style={{ padding: '5px 0' }}>
                          P.O No
                        </Typography>
                        <Typography variant={TV.BASE}>
                          {billItem.purchaseOrder?.poNumber}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant={TV.S1} weight={TW.BOLD} style={{ padding: '5px 0' }}>
                          Date
                        </Typography>
                        <Typography variant={TV.BASE}>
                          {isNaN(billItem.purchaseOrder?.dateOfPurchase)
                            ? Moment(billItem.purchaseOrder?.dateOfPurchase).format(
                                AppConstants.DATE_FORMAT
                              )
                            : Moment.unix(
                                parseInt(billItem.purchaseOrder?.dateOfPurchase, 10)
                              ).format(AppConstants.DATE_FORMAT)}
                        </Typography>
                      </Grid>
                      <Grid item style={{ textAlign: 'center' }}>
                        <Typography variant={TV.S1} weight={TW.BOLD} style={{ padding: '5px 0' }}>
                          Total
                        </Typography>
                        <Typography variant={TV.BASE}>
                          $
                          {billItem.billLineItems
                            .reduce((acc, lineItem) => {
                              return acc + (lineItem?.amount ? Number(lineItem?.amount) : 0);
                            }, 0)
                            .toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ThemeProvider>

              <BillItemLine
                billItem={billItem}
                current={current}
                send={send}
                isReviewReport={isReviewReport}
                user={user}
              />
            </Grid>
          ))}
        </ErrorBoundaries>
      </Grid>
      {open && (
        <BillModal
          open={open}
          data={context.modalRecord}
          mode="new"
          title={title}
          send={send}
          service={service}
          user={user}
          billOptions={billsForModal}
          reviewReportId={context.reportId}
        />
      )}
      {!isEmpty(context.modalRecord) && openProcurementPurchaseOrderModal && (
        <ProcurementPurchaseOrderModal
          open={openProcurementPurchaseOrderModal}
          user={user}
          handleClose={handleCreatePO}
          initialData={context.modalRecord}
          tagOptions={tagOptions}
        />
      )}
    </>
  );
};

export default Bills;
