import gql from 'graphql-tag';

const addPurchaseOrderNumberToJob = gql`
  mutation addPurchaseOrderNumberToJob(
    $partitionKey: String!
    $data: AddPurchaseOrderNumberToJobInput!
  ) {
    addPurchaseOrderNumberToJob(partitionKey: $partitionKey, data: $data) {
      id
      value
    }
  }
`;

export default addPurchaseOrderNumberToJob;
