export const FeatureFlags = {
  ADJUSTMENTS: 'adjustments',
  ADP_EXPORT: 'adpExport',
  ASSIGNED_PENDING_TASKS_MOBILE: 'assignedPendingTasksMobile',
  ASYNC_JOB_REPORT: 'jobReportV3',
  DEPARTMENTS_ARE_LIMITED_BY_JOB: 'departmentsAreLimitedByJob',
  DISPATCH_BOARD_LONG_POLL: 'dispatchBoardLongPoll',
  DISPATCH_MAP_VIEW: 'dispatchMapView',
  EMAIL_REPLIES: 'emailReplies',
  ENABLE_EDIT_PRICEBOOK: 'enableEditPricebook',
  ENTERPRISE_LABOR_COSTING: 'enterpriseLaborCosting',
  JOB_CLOSEOUT: 'jobCloseout',
  JOB_DISPATCH: 'jobDispatch',
  JOB_PROCUREMENT_STATUS: 'jobProcurementStatus',
  JOB_QUOTE_STATUS: 'jobQuoteStatus',
  MAINTENANCE_TEMPLATES_V2: 'maintenanceTemplatesV2',
  MARKUP_AND_MARGIN: 'markupAndMargin',
  METABASE: 'metabase',
  PENDING_TASKS_TABLE: 'pendingTasksTable',
  PM_FILE_SYSTEM: 'pmFileSystem',
  PRESET_JOB_REPORT: 'jobReportV2',
  PRICEBOOK_AUTO_UPDATE_SCALED_MARKUP: 'pricebookAutoUpdateScaledMarkup',
  PROCUREMENT: 'procurement',
  PROCUREMENT_USAGE: 'procurementUsage',
  PRODUCT_CATEGORIES_ENABLED: 'productCategoriesEnabled',
  PROJECT_MANAGEMENT: 'projectManagement',
  QUOTESV2: 'quotesV2',
  QUOTE_ATTACHMENTS_ENHANCEMENTS: 'quoteAttachmentsEnhancements',
  REPORTS_V2: 'reportsV2',
  SERVICE_AGREEMENTS: 'serviceAgreements',
  SERVICE_AGREEMENTS_JOBS_PREVIEW: 'serviceAgreementsJobsPreview',
  SERVICE_CHANNEL_INTEGRATION: 'serviceChannelIntegration',
  TRUCK_STOCK_INVENTORY: 'truckStockInventory',
  VERBOSE_ERRORS: 'verboseErrors',
  WRINKLE_IN_TIME: 'wrinkeInTime',
  VENDOR_INVOICE_APPROVAL: 'vendorInvoiceApproval'
};

export default FeatureFlags;
