import gql from 'graphql-tag';

export const GetCompanyDispatchSettings = gql`
  query($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      companySettings(
        entityConnection: "CompanySetting"
        filter: { type: { eq: "DISPATCH" }, isActive: { eq: true } }
      ) {
        items {
          id
          version
          settings
        }
      }
    }
  }
`;

export const AddCompanyDispatchSettings = gql`
  mutation($partitionKey: String!, $companyId: String!, $settings: String!) {
    addCompanySettingsToCompany(
      partitionKey: $partitionKey
      data: {
        companyId: $companyId
        companySettings: { type: DISPATCH, settings: $settings, isActive: true }
      }
    ) {
      id
      version
      settings
    }
  }
`;

export const UpdateCompanyDispatchSettings = gql`
  mutation($partitionKey: String!, $settings: String!, $id: String!, $version: Int!) {
    updateCompanySetting(
      partitionKey: $partitionKey
      data: { type: DISPATCH, settings: $settings, isActive: true, id: $id, version: $version }
    ) {
      id
      version
      settings
    }
  }
`;
