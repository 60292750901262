import gql from 'graphql-tag';

const getAllInvoicesByRangeKey = gql`
  query getInvoices(
    $partitionKey: String!
    $sortKey: String!
    $nextToken: String
    $limit: Int
    $startTime: Int!
    $endTime: Int!
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      invoicesInRange(
        entityConnection: "Invoice"
        nextToken: $nextToken
        limit: $limit
        rangeFilter: { startTime: $startTime, endTime: $endTime }
        filter: { isActive: { eq: true } }
      ) {
        nextToken
        items {
          id
          sortKey
          invoiceNumber
          jobNumber
          entityType
          totalAmount
          createdBy
          status
          dueDate
          issuedDate
          syncLog
          syncStatus
          invoiceVisits(entityConnection: "InvoiceVisit") {
            items {
              id
              sortKey
              visitSortKey
              visitId
              visit(entityConnection: "visit") {
                visitNumber
                parentEntity {
                  ... on Job {
                    customerName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getAllInvoicesByRangeKey;
