import gql from 'graphql-tag';

const getSyncLogs = gql`
  query getCompany(
    $partitionKey: String!
    $sortKey: String!
    $startTime: Int!
    $endTime: Int!
    $nextToken: String
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      syncEntriesInRange(
        entityConnection: "SyncEntry"
        sortDirection: "DESC"
        rangeFilter: { startTime: $startTime, endTime: $endTime }
        nextToken: $nextToken
      ) {
        nextToken
        items {
          id
          syncEntityType
          syncEntityValue
          syncEntitySortKey
          syncStatus
          syncType
          syncLog
          createdDateTime
        }
      }
    }
  }
`;

export default getSyncLogs;
