import React, { useEffect } from 'react';
import {
  Menu,
  MenuItem,
  Grid,
  ListItemIcon,
  Switch,
  Popover,
  TextField,
  Box,
  IconButton
} from '@material-ui/core';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RenameModal from 'components/ResponsiveTable/RenameModal';

import { saveView, deleteView, accessConstants, viewIsDefault } from './ViewHelper';
import { viewStyles } from './styles';

export const ChangeView = props => {
  const classes = viewStyles();
  const { id, open, currentLabel, handleViewRename, changeLabelRef, handleClose } = props;
  const [viewName, setViewName] = React.useState(currentLabel);

  return (
    <Popover
      id={id}
      open={open}
      keepMounted
      anchorEl={() => changeLabelRef.current}
      onClick={e => e.stopPropagation()}
      onClose={() => handleClose()}
      marginThreshold={32}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <Box className={classes.changeLabelStyles}>
        <TextField
          autoFocus
          value={viewName}
          label="New label"
          onChange={event => setViewName(event.currentTarget.value)}
        />
        <IconButton
          color="secondary"
          onClick={e => {
            e.stopPropagation();
            handleClose();
            handleViewRename(viewName);
          }}
        >
          <CheckCircleIcon />
        </IconButton>
      </Box>
    </Popover>
  );
};

const Submenu = props => {
  const {
    view,
    views,
    handleCloseSubmenu,
    updateViewsState,
    addToViewsState,
    removeFromViewsState,
    setSelectedView,
    selectedView,
    user,
    snackbar,
    anchor,
    disabled,
    setViewsLoaded,
    fallbackView
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(anchor);
  const [showRenameModal, setShowRenameModal] = React.useState();
  const changeLabelRef = React.useRef();

  useEffect(() => {
    setAnchorEl(anchor);
  }, [anchor]);

  const classes = viewStyles();
  const [isDefaultView, setIsDefaultView] = React.useState(viewIsDefault(view));
  const [isPublicView, setIsPublicView] = React.useState(view.access === accessConstants.PUBLIC);

  const getDuplicateViewName = viewName => {
    const regex = /\(([\d+]+)\)$/;
    const foundAppendedNumber = viewName.match(regex)?.[1];
    if (foundAppendedNumber) {
      const appendedNumber = Number(foundAppendedNumber);
      const newViewName = viewName.replace(regex, `(${appendedNumber + 1})`);
      return newViewName;
    }
    return `${viewName} (1)`;
  };

  const handleRenameView = async newDisplayName => {
    setViewsLoaded(false);
    const data = {
      id: view?.id,
      companyId: user?.tenantCompanyId,
      displayName: newDisplayName
    };

    const renamedView = await saveView(data, user?.tenantId, snackbar);

    if (renamedView) {
      updateViewsState([renamedView]);
      if (selectedView && selectedView.id === renamedView.id) {
        // need to set as selected view otherwise old name will be displaced in the selected field
        setSelectedView(renamedView);
      }
      snackbar('success', `${view?.displayName} has been renamed to ${renamedView.displayName}`);
    }
    setAnchorEl(null);
    setViewsLoaded(true);
  };

  const handleDuplicateView = async () => {
    setViewsLoaded(false);
    const newViewName = getDuplicateViewName(view.displayName);
    const data = {
      companyId: user?.tenantCompanyId,
      type: view?.type,
      settings: view?.settings,
      name: view?.name,
      displayName: newViewName,
      access: accessConstants.PRIVATE
    };
    const duplicateView = await saveView(data, user.tenantId, snackbar);
    if (duplicateView) {
      addToViewsState(duplicateView);
      snackbar('success', `Duplicated ${view?.displayName} as ${duplicateView?.displayName}`);
    }
    setAnchorEl(null);
    setViewsLoaded(true);
  };

  const handleDeleteView = async () => {
    setViewsLoaded(false);
    const deleted = await deleteView(view, user, snackbar);
    if (deleted) {
      if (selectedView && selectedView.id === view.id) {
        // need to set as fallback otherwise delete view name will still be displaced in the selected field
        setSelectedView(fallbackView);
      }
      removeFromViewsState(view);
    }

    setViewsLoaded(true);
  };

  const handleSetDefaultView = async () => {
    setViewsLoaded(false);
    const isCurrentlyDefault = viewIsDefault(view);
    // setIsDefaultView executed before network requests so that toggle animation plays
    setIsDefaultView(!isCurrentlyDefault);

    const requestDataForDefaultBeingChanged = {
      id: view?.id,
      companyId: user?.tenantCompanyId,
      userSettingEmployees: {
        id: isCurrentlyDefault ? view?.userSettingEmployees?.items[0].id : undefined,
        isDefault: !isCurrentlyDefault
      }
    };
    let noLongerDefaultView;
    if (!isCurrentlyDefault) {
      const currentDefaultView = views.find(v => viewIsDefault(v));
      if (currentDefaultView) {
        const requestDataForPreviousDefault = {
          id: currentDefaultView?.id,
          companyId: user?.tenantCompanyId,
          userSettingEmployees: {
            id: currentDefaultView?.userSettingEmployees?.items[0].id,
            isDefault: false
          }
        };

        noLongerDefaultView = await saveView(
          requestDataForPreviousDefault,
          user?.tenantId,
          snackbar
        );
        if (noLongerDefaultView) {
          // keeping this updateViewsState as redundancy incase this network request passed, but next one fails
          updateViewsState([noLongerDefaultView]);
        }
      }
    }

    const defaultUpdatedView = await saveView(
      requestDataForDefaultBeingChanged,
      user?.tenantId,
      snackbar
    );
    if (defaultUpdatedView) {
      updateViewsState([defaultUpdatedView, noLongerDefaultView]);
      snackbar(
        'success',
        `${defaultUpdatedView?.displayName} is ${
          viewIsDefault(defaultUpdatedView) ? 'now' : 'no longer'
        } the default view`
      );
    }
    setAnchorEl(null);
    setViewsLoaded(true);
  };

  const handleSetViewPublic = async () => {
    setViewsLoaded(false);

    // setIsPublicView executed before saveView  so that the toggle animation plays early enough
    setIsPublicView(true);

    const data = {
      id: view?.id,
      companyId: user?.tenantCompanyId,
      access: accessConstants.PUBLIC
    };
    const newPublicView = await saveView(data, user.tenantId, snackbar);

    if (newPublicView) {
      updateViewsState([newPublicView]);
      snackbar('success', `${newPublicView.displayName} has been made ${newPublicView.access}`);
    }
    setAnchorEl(null);
    setViewsLoaded(true);
  };

  return (
    <>
      <Menu
        id={`viewAction${view.displayName}`}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={e => e.stopPropagation()}
        onClose={handleCloseSubmenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem
          ref={changeLabelRef}
          aria-describedby={`${view.displayName}Rename`}
          onClick={e => {
            e.stopPropagation();
            setShowRenameModal(true);
          }}
          disabled={disabled || (isPublicView && user.cognitoRole !== 'TenantAdmin')}
          className={classes.menuText}
        >
          <ListItemIcon className={classes.menuIcon}>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Rename
        </MenuItem>
        <MenuItem
          onClick={async e => {
            e.stopPropagation();
            await handleDuplicateView();
            handleCloseSubmenu();
          }}
          className={classes.menuText}
          disabled={disabled}
        >
          <ListItemIcon className={classes.menuIcon}>
            <FileCopyOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Duplicate
        </MenuItem>
        <MenuItem
          onClick={async e => {
            e.stopPropagation();
            await handleDeleteView();
            handleCloseSubmenu();
          }}
          disabled={
            disabled || isDefaultView || (isPublicView && user.cognitoRole !== 'TenantAdmin')
          }
          className={classes.menuText}
        >
          <ListItemIcon className={classes.menuIcon}>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Remove
        </MenuItem>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={`${classes.menuTopBorder} ${classes.defaultBtnContainer}`}
          onClick={e => e.stopPropagation()}
        >
          <Grid item className={classes.menuText}>
            Make default
          </Grid>
          <Grid item>
            <Switch
              classes={{
                switchBase: classes.switchBase,
                track: classes.track,
                colorSecondary: classes.colorSecondary,
                checked: classes.checked
              }}
              checkedIcon={<CheckCircleIcon />}
              checked={isDefaultView}
              disabled={disabled}
              onChange={async () => {
                await handleSetDefaultView();
                handleCloseSubmenu();
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.defaultBtnContainer}
          onClick={e => e.stopPropagation()}
        >
          <Grid item className={classes.menuText}>
            Make public
          </Grid>
          <Grid item>
            <Switch
              classes={{
                switchBase: classes.switchBase,
                track: classes.track,
                colorSecondary: classes.colorSecondary,
                checked: classes.checked
              }}
              checkedIcon={<CheckCircleIcon />}
              checked={isPublicView}
              disabled={disabled || isPublicView}
              onChange={async event => {
                if (event.target.checked) {
                  await handleSetViewPublic();
                  handleCloseSubmenu();
                }
              }}
            />
          </Grid>
        </Grid>
      </Menu>
      <RenameModal
        open={showRenameModal}
        currentLabel={view.displayName}
        handleViewRename={async newDisplayName => {
          await handleRenameView(newDisplayName);
          handleCloseSubmenu();
        }}
        handleClose={() => setShowRenameModal(null)}
        onClick={e => e.stopPropagation()}
      />
    </>
  );
};

Submenu.propTypes = {
  view: PropTypes.object.isRequired,
  views: PropTypes.arrayOf(PropTypes.object).isRequired,
  anchor: PropTypes.object.isRequired,
  fallbackView: PropTypes.object.isRequired,
  snackbar: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  updateViewsState: PropTypes.func.isRequired,
  addToViewsState: PropTypes.func.isRequired,
  removeFromViewsState: PropTypes.func.isRequired,
  setSelectedView: PropTypes.func.isRequired,
  handleCloseSubmenu: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  setViewsLoaded: PropTypes.func,
  selectedView: PropTypes.object
};

Submenu.defaultProps = {
  disabled: false,
  setViewsLoaded: () => {},
  selectedView: null
};

export default Submenu;
