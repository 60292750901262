import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { Grid, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import MapViewStatic from 'components/MapViewStatic';
import { formatTime } from './helpers';
import useStyles from './WorkTable.style';

const TimestampDetail = ({ detail, isVisible, onClose }) => {
  const classes = useStyles();
  if (!isVisible) return null;
  const { meta } = detail;

  return (
    <Dialog open={isVisible} onClose={onClose} aria-labelledby="simple-dialog-title">
      <Close onClick={onClose} className={classes.closeIcon} />
      <Grid className={classes.locationDialog} container>
        <Typography variant="h3">Timestamp Location</Typography>
        <Typography className={classes.timeDetails} variant="body2">
          {`${meta.id === 'actualStartTimeUTC' ? 'Begin' : 'End'}  Shift`} -
          {formatTime(detail[meta.id], 'hh:mm A')}
        </Typography>
        <Grid className={classes.mapWrapper} item>
          <MapViewStatic
            coordinates={{
              latitude: detail[`${meta.locationIdPrefix}Latitude`],
              longitude: detail[`${meta.locationIdPrefix}Longitude`]
            }}
            dimensions={{ height: 250, width: 270 }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default TimestampDetail;

TimestampDetail.propTypes = {
  isVisible: PropTypes.bool,
  detail: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

TimestampDetail.defaultProps = {
  isVisible: false
};
