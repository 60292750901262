import { isEmpty } from 'lodash';

import Context from 'components/Context';
import { Logger } from 'services/Logger';
import { getTenantSettingValueForKey, round } from 'utils';
import { QuoteStatus } from 'utils/AppConstants';

import { SettingConstants } from '../constants';
import QuotesUtils from '../index.utils';
import entityMapAttributes from '../meta/entityMapAttributes';

const formatQuotePurchaseOrders = data => {
  const { quotePurchaseOrders, versionNumber, opportunity } = data;
  const versionedQuotePurchaseOrders =
    opportunity?.versionedQuotes?.items?.reduce((acc, curr) => {
      const currentQuotePurchaseOrders = curr.quotePurchaseOrders.items.map(item => ({
        poId: item.purchaseOrder.id,
        poNumber: item.purchaseOrder.poNumber,
        poStatus: item.purchaseOrder.status,
        quoteVersionNumber: curr.versionNumber
      }));
      return [...acc, ...currentQuotePurchaseOrders];
    }, []) || [];
  const primaryQuotePurchaseOrders =
    quotePurchaseOrders?.items?.map(item => ({
      poId: item.purchaseOrder.id,
      poNumber: item.purchaseOrder.poNumber,
      poStatus: item.purchaseOrder.status,
      quoteVersionNumber: versionNumber
    })) || [];
  return [...primaryQuotePurchaseOrders, ...versionedQuotePurchaseOrders];
};

export const processQuoteResponse = quoteResponse => {
  const { opportunity, attachments, formData, ...quoteInfo } = quoteResponse || {};
  const composedAttachments = QuotesUtils.composeQuoteAttachments(attachments);

  return {
    quoteInfo,
    property: opportunity?.property || {},
    customer: opportunity?.property?.customer || {},
    quoteAttachments: composedAttachments,
    customFormdata: formData,
    quotePurchaseOrders: formatQuotePurchaseOrders(quoteResponse)
  };
};

export const getFormattedDataForInfo = data => {
  if (data) {
    const formattedData = {
      id: data.id || '',
      name: data.name || '',
      versionLabel: data.versionLabel || '',
      ownerId: data.ownerId || '',
      salesById: data.salesById || '',
      totalAmountQuoted: data.totalAmountQuoted,
      totalAmountQuotedOverride:
        (data.totalAmountQuotedOverride
          ? data.totalAmountQuotedOverride
          : data.totalAmountQuoted) || '',
      customerPoNumber: data.customerPoNumber || '',
      departmentId: data.departmentId || ''
    };
    if (formattedData.totalAmountQuotedOverride) {
      formattedData.totalAmountQuotedOverride = parseFloat(
        formattedData.totalAmountQuotedOverride.toFixed(2)
      );
    }
    return formattedData;
  }
  return {};
};

export const constructSelectSettings = data => {
  return data?.map(item => {
    const parsed = JSON.parse(item.settings);
    parsed[SettingConstants.QUOTE_PRESETS] = {
      label: item.displayName,
      value: item.id
    };
    return parsed;
  });
};

export const overridesAreEnabled = config =>
  config?.[SettingConstants.QUOTE_MODE]?.[SettingConstants.ENABLE_OVERRIDES];

export default processQuoteResponse;

const getButtonMeta = label => ({ label });

const cancelQuotePrompt = (setConfirmData, cancelQuote) => {
  setConfirmData({
    confirmDialog: true,
    confirmAction: () => cancelQuote(setConfirmData),
    confirmMessage: 'Are you sure you want to cancel the quote?',
    handleCancelConfirmation: () =>
      setConfirmData({ confirmDialog: false, confirmMessage: '', confirmAction: '' })
  });
};

export const getButtonsForQuote = ({
  cancelQuote,
  cloneQuote,
  updateQuoteStatusMutation,
  handleActionIfDeactivated,
  quoteInfo,
  reopenQuote,
  setConfirmData,
  setOpenReasonsModal,
  setQuoteStatus
}) => {
  const buttons = {};

  // Need to change this way of code
  if (!_.isEmpty(quoteInfo)) {
    if (
      [
        QuoteStatus.DRAFT,
        QuoteStatus.EMAIL_READ,
        QuoteStatus.EMAIL_BOUNCED,
        QuoteStatus.CUSTOMER_VIEWED
      ].includes(quoteInfo.status)
    ) {
      buttons.approve = getButtonMeta('Approve quote');
      buttons.approve.action = () => {
        setOpenReasonsModal(true);
        setQuoteStatus('Approve');
      };
      buttons.reject = getButtonMeta('Reject quote');
      buttons.reject.action = () => {
        setOpenReasonsModal(true);
        setQuoteStatus('Reject');
      };
      buttons.cancel = getButtonMeta('Cancel quote');
      buttons.cancel.action = () => cancelQuotePrompt(setConfirmData, cancelQuote);
      buttons.clone = getButtonMeta('Clone quote');
      buttons.clone.action = () => cloneQuote('clone');
      buttons.markAsSent = getButtonMeta('Mark as Sent');
      buttons.markAsSent.action = () => {
        updateQuoteStatusMutation(QuoteStatus.SENT_TO_CUSTOMER);
        setQuoteStatus('SentToCustomer');
      };
    } else if ([QuoteStatus.JOB_ADDED, QuoteStatus.DISCARDED].includes(quoteInfo.status)) {
      buttons.clone = getButtonMeta('Clone quote');
      buttons.clone.action = () => handleActionIfDeactivated(cloneQuote, 'cloning', 'clone');
    } else if ([QuoteStatus.REJECTED, QuoteStatus.APPROVED].includes(quoteInfo.status)) {
      buttons.cancel = getButtonMeta('Cancel quote');
      buttons.cancel.action = () => cancelQuotePrompt(setConfirmData, cancelQuote);
      buttons.clone = getButtonMeta('Clone quote');
      buttons.clone.action = () => cloneQuote('clone');
    } else if (quoteInfo.status === QuoteStatus.CANCELLED) {
      buttons.clone = getButtonMeta('Clone quote');
      buttons.clone.action = () => handleActionIfDeactivated(cloneQuote, 'cloning', 'clone');
      buttons.reopen = getButtonMeta('Reopen quote');
      buttons.reopen.action = () => handleActionIfDeactivated(reopenQuote, 'reopening', 'reopen');
    } else if (quoteInfo.status === QuoteStatus.SENT_TO_CUSTOMER) {
      buttons.approve = getButtonMeta('Approve quote');
      buttons.approve.action = () => {
        setOpenReasonsModal(true);
        setQuoteStatus('Approve');
      };
      buttons.reject = getButtonMeta('Reject quote');
      buttons.reject.action = () => {
        setOpenReasonsModal(true);
        setQuoteStatus('Reject');
      };
      buttons.cancel = getButtonMeta('Cancel quote');
      buttons.cancel.action = () => cancelQuotePrompt(setConfirmData, cancelQuote);
      buttons.clone = getButtonMeta('Clone quote');
      buttons.clone.action = () => cloneQuote('clone');
    }
  }
  return buttons;
};

export const getQuoteName = (quoteInfo, hasMultipleVersions) => {
  const { customIdentifier, quoteNumber, versionNumber, name, versionLabel } = quoteInfo;
  return `${customIdentifier || quoteNumber}${
    hasMultipleVersions ? ` Version ${versionNumber}` : ''
  }${name ? ` ${name}` : ''}${versionLabel ? ` ${versionLabel}` : ''}`;
};

export const getQuoteNumberAndVersion = (quoteInfo, hasMultipleVersions) => {
  const { customIdentifier, quoteNumber, versionNumber } = quoteInfo;
  return `${customIdentifier || quoteNumber || ''}${
    hasMultipleVersions ? ` Version ${versionNumber}` : ''
  }`;
};

export const getServiceAgreementsByProperty = (serviceAgreements = [], propertyId) => {
  if (propertyId && serviceAgreements?.length) {
    const propertyServiceAgreements = serviceAgreements?.reduce((options, sa) => {
      if (!isEmpty(sa?.propertiesJSON)) {
        try {
          const properties = JSON.parse(sa.propertiesJSON);
          const isValid = properties?.some(property => property?.value === propertyId);
          if (isValid) return [...options, sa];
        } catch (e) {
          Logger.error(`Unable to parse property details for service agreement`, e);
        }
      }
      return options;
    }, []);
    return propertyServiceAgreements;
  }
  return [];
};

export const checkCustomQuoteNumber = () => {
  if (Context.getCompanyContext() && Context.getCompanyContext().getCompany) {
    const customExpressionValue = getTenantSettingValueForKey('custom_quote_expression');
    const isCustomQuoteNumberEnabled = !!customExpressionValue?.trim();
    return isCustomQuoteNumberEnabled;
  }
  return false;
};

export const checkCustomJobNumber = () => {
  if (Context.getCompanyContext() && Context.getCompanyContext().getCompany) {
    const customExpressionValue = getTenantSettingValueForKey('job_customJobNumber');
    const isCustomJobNumberEnabled = !!customExpressionValue?.trim();
    return isCustomJobNumberEnabled;
  }
  return false;
};

const isLogIncluded = (log, entityMapWithChange) => {
  const entityData = entityMapWithChange[log.entityType];

  if (!entityData) return false;

  return (
    (log.entityField in entityData.fields ||
      entityData.executionTypes?.includes(log.changeType) ||
      log.customMessage) &&
    round(log.old, 5) !== round(log.new, 5)
  );
};

export const filterQuoteLogs = changeList => {
  return changeList.reduce((result, change) => {
    const entityMapWithChange = entityMapAttributes(change);
    if (isLogIncluded(change, entityMapWithChange)) {
      const entityChange = entityMapWithChange[change.entityType];
      const fieldName = entityChange.fields[change.entityField] || change.field;
      const hideChanges = entityChange.hideChanges?.includes(change.entityField) || false;
      result.push({
        ...change,
        field: fieldName,
        hideChanges
      });
    }
    return result;
  }, []);
};

export const flattenAuditLogs = entityWithLogs => {
  return entityWithLogs?.items?.reduce((acc, cur) => [...acc, ...cur.auditLogs?.items], []);
};

export const getFormattedQuoteAuditLogs = quoteAuditLogData => {
  const {
    auditLogs,
    quoteTaskGroups,
    quoteLineTasks,
    quoteLineProducts,
    attachments
  } = quoteAuditLogData;

  const quoteTaskGroupLogs = flattenAuditLogs(quoteTaskGroups);
  const quoteLineProductLogs = flattenAuditLogs(quoteLineProducts);
  const attachmentLogs = flattenAuditLogs(attachments);
  const quoteLineTaskLogs = quoteLineTasks.items.reduce((acc, cur) => {
    const taskLineProductLogs = flattenAuditLogs(cur.quoteLineProducts);
    const taskLineLaborLogs = flattenAuditLogs(cur.quoteLineLabors);
    return [...acc, ...cur.auditLogs.items, ...taskLineProductLogs, ...taskLineLaborLogs];
  }, []);

  const combineAuditLogs = [
    ...auditLogs.items,
    ...quoteTaskGroupLogs,
    ...quoteLineTaskLogs,
    ...quoteLineProductLogs,
    ...attachmentLogs
  ];
  return combineAuditLogs;
};
