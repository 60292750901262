import React from 'react';
import { TextField, withStyles, InputAdornment } from '@material-ui/core';
import styles from './styles';
import { formatCurrency } from 'utils';
import AppConstants from 'utils/AppConstants';

/* This component is designed as a generic control for showing Percentage values in a PageForm */

class CurrencyInput extends React.Component {
  state = {
    currentValue: this.props.field.value
  };

  // componentDidMount() {
  //   const { form, field } = this.props;
  //   if (!field.value) {
  //     this.setState({ currentValue: '0.00' });
  //     setTimeout(() => {
  //       form.setFieldValue(field.name, 0, false);
  //     }, 1);
  //   }
  // }

  formatDecimal = event => {
    const { field, form, specialbehaviour } = this.props;
    const { disableDollorSign } = specialbehaviour;
    const rawValue = event.target.value;
    let currentValue = (rawValue && parseFloat(rawValue.replace(/,/g, ''))) || '';
    currentValue = Number.isNaN(currentValue) ? '' : currentValue;
    const currencyFloat = !disableDollorSign
      ? currentValue && currentValue.toFixed(AppConstants.ROUND_OFF_VALUE)
      : currentValue;
    this.setState({ currentValue: `${currencyFloat || ''}` });
    form.setFieldValue(field.name, parseFloat(currencyFloat) || '', false);
  };

  handleOnChange = event => {
    const currentValue = event.target.value;
    const { field, form } = this.props;
    /* Replace all alpha characters and $ symbols before setting form value */
    let formattedVal = '';
    formattedVal = currentValue && currentValue.replace(/[^0-9.]/g, '');
    // console.log(formattedVal, 'formattedVal');
    // let currencyFloat = parseFloat(formattedVal);
    // console.log(currencyFloat, 'currencyFloat');
    // currencyFloat = Number.isNaN(currencyFloat) ? '' : currencyFloat;
    // const commaSeparatedCurrency = currencyFloat && formatCurrency(currencyFloat);

    // // if (currencyFloat) {
    // //   currencyFloat = 0.0;
    // // } else {
    // //   currencyFloat = parseFloat(currencyFloat).toFixed(AppConstants.ROUND_OFF_VALUE);
    // // }

    // /* Setting state variable to display in desired format */
    this.setState({ currentValue: `${formattedVal}` });

    form.setFieldValue(field.name, formattedVal, false);
  };

  render() {
    const { field, form, classes, specialbehaviour, user, theme, ...rest } = this.props;
    const { currentValue } = this.state;
    const { disableDollorSign } = specialbehaviour;
    const helperText =
      form.errors && form.errors[field.name] ? form.errors && form.errors[field.name] : ' ';

    return (
      <TextField
        variant="filled"
        error={form.errors && form.errors[field.name] && form.errors[field.name] !== ''}
        className={classes.textField}
        name={field.name}
        value={formatCurrency(currentValue)}
        InputProps={
          !disableDollorSign && {
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }
        }
        onChange={this.handleOnChange}
        onBlur={this.formatDecimal}
        helperText={helperText}
        fullWidth
        {...rest}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(CurrencyInput);
