import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { Button, InlineAlert, InlineAlertTypes, ThemeProvider } from '@buildhero/sergeant';
import { Box, Grid } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty, sortBy } from 'lodash';
import { connect, useSelector } from 'react-redux';

import { AuditLogs, Context, Tab, Tabs, withMultipleForms } from 'components';
import Attachment from 'components/AttachmentSection';
import SendEmailPopUp from 'components/BuildHeroFormComponents/SendEmailPopUp';
import { parseHTMLForSmartFields } from 'components/CKEditor/CKEditor.smartfield.utils';
import { generatePDF, reinsertSmartfields } from 'components/CKEditor/CKEditor.utils';
import { getCompany } from 'components/LabourRateSection/queries';
import { searchIndex as defaultSearchIndex } from 'constants/algoliaIndex';
import { MIXPANEL_EVENT, MIXPANEL_PAGE_NAME } from 'constants/mixpanelEvents';
import useEmployees from 'customHooks/useEmployees';
import usePayrollHourTypes from 'customHooks/usePayrollHourTypes';
import { snackbarOn } from 'redux/actions/globalActions';
import { getLabourTypes } from 'scenes/Settings/Payroll/helpers';
import { getPricebookById } from 'scenes/Settings/Pricebooks/PricebookDetail/gql';
import AmplifyService from 'services/AmplifyService';
import {
  CustomerPropertyService,
  CustomerService,
  QuoteService,
  TimesheetsService
} from 'services/core';
import { sendMixpanelEvent } from 'services/mixpanel';
import {
  checkPermission,
  getCombinedAddress,
  getTenantSettingValueForKey,
  logErrorWithCallback
} from 'utils';
import { AppConstants, PermissionConstants, QuoteStatus } from 'utils/AppConstants';
import { EntityType } from 'utils/constants';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import { useExternalMessagesQuery } from '../Settings/Company/CommunicationsSection/service';

import CKEditorModal from './components/Modals/CKEditorModal';
import CustomerPropertyModal from './components/Modals/CustomerPropertyModal';
import PageHeader from './components/PageHeader';
import Profitability from './components/Profitability';
import QuoteSettings from './components/QuoteSettings';
import quoteSettings from './components/QuoteSettings/defaultQuoteSettings';
import ReadonlyWrapper from './components/ReadonlyWrapper';
import DocHistory from './components/Tabs/DocHistory';
import Info from './components/Tabs/Info/Info';
import { getCustomerRepDetails } from './components/Tabs/Info/utils/Info.reps.utils';
import { getEmployeeName } from './components/Tabs/Info/utils/Info.utils';
import InternalNotes from './components/Tabs/InternalNotes';
import Tasks from './components/Tasks';
import { SettingConstants } from './constants';
import QuotesUtils from './index.utils';
import reducer, { ACTION } from './reducer';
import {
  addQuoteLineProducts,
  addQuoteLineProductsOnTask,
  addTaskGroup,
  addTaskService,
  cancelQuoteMutation,
  cloneOrReopenQuoteMutation,
  createJobMutation,
  createQuoteVersion,
  deleteLaborLineItem,
  deleteLineItem,
  deleteProductEntry,
  deleteQuoteLineTask,
  deleteTaskGroup,
  existingJobMutation,
  getAttachmentsForQuote,
  getQuoteActivityLogs,
  getQuoteById,
  getVersionedQuoteById,
  handleQuoteAttachment,
  setPrimaryVersionMutation,
  shareQuoteMutation,
  updateLaborLineItems,
  updateOverrideAmountOnQuote,
  updateQuoteLineProductsOnTask,
  updateQuoteMutation,
  updateQuoteRep,
  updateTaskGroup,
  updateTaskService,
  updateTaxRateOnQuote,
  useQuoteSettingsQuery,
  useQuoteTemplateQuery,
  useServiceAgreementsQuery
} from './service';
import { styles } from './styles';
import { filterQuoteLogs, getQuoteName } from './utils/helper';
import { changeVersionsView, formatVersionOptionData } from './utils/utils.versions';

const QuoteDetail = props => {
  const { computedMatch, snackbar, history } = props;
  const quoteId = computedMatch?.params?.id;
  const initialState = {
    loading: false,
    quoteInfo: {},
    property: {},
    customFormdata: {},
    versions: {}
  };
  const quoteService = new QuoteService();
  const user = useSelector(s => s.user);
  const hasPermissionToEditQuote = checkPermission('edit', PermissionConstants.OBJECT_QUOTES, user);

  // withMultipleForms HOC props
  const { getHandleCreateService, getHandleComplete } = props;

  const [quoteState, dispatch] = useReducer(reducer, initialState);
  const [laborRates, setLaborRates] = useState([]);
  const [payrollHourTypes] = usePayrollHourTypes(user.tenantId, user.tenantCompanyId, snackbarOn);
  const [openModal, setOpenModal] = useState(false);
  const [ckEditorData, setCKEditorData] = useState('');
  const companyContext = Context.getCompanyContext();
  const [propertiesAndRepsData, setPropertiesAndRepsData] = useState({});
  const [propertyDetails, setPropertyDetails] = useState({});
  const [recordSortKey, setRecordSortKey] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [formService, setFormService] = useState({});
  const [quoteVersionsOptionData, setQuoteVersionsOptionData] = useState([]);
  const [hasMultipleVersions, setHasMultipleVersions] = useState(false);
  const [primaryQuoteId, setPrimaryQuoteId] = useState('');
  const [primaryQuoteInfo, setPrimaryQuoteInfo] = useState({});
  const [poNumber, setPoNumber] = useState('');
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [quoteName, setQuoteName] = useState('');
  const [isOverridesModalOpen, setIsOverridesModalOpen] = useState(false);
  const [currentSettings, setCurrentSettings] = useState();
  const [updatedQuoteInfo, setUpdatedQuoteInfo] = useState({});
  const [updatedQuoteNotes, setUpdatedQuoteNotes] = useState({});
  const [openPropertyModal, setOpenPropertyModal] = useState(false);
  const [quoteTags, setQuoteTags] = useState([]);
  const [refreshCKEditor, setRefreshCKEditor] = useState({ isTrue: false, data: null });
  const [accountManagerOptions, setAccountManagerOptions] = useState([]);
  const [defaultPriceBookId, setDefaultPriceBookId] = useState('');
  const companyPriceBookId = useSelector(s => s.company.defaultPriceBookId);
  // display the Quote's info tab by default
  const selectedTab = useRef(0);
  const generatedPDFSizeRef = useRef(0);
  const flags = useFlags();

  const {
    quoteAttachments,
    quoteInfo: { quoteLineTasks = {} },
    quoteInfo: { quoteLineProducts = {} },
    quoteInfo,
    property,
    quotePurchaseOrders
  } = quoteState || {};
  const serviceProps = {
    dispatch,
    tenantId: user.tenantId,
    quoteInfo,
    snackbar,
    propertyId: property.id,
    quoteState,
    setQuoteVersionsOptionData,
    setHasMultipleVersions,
    quoteVersionsOptionData,
    primaryQuoteId
  };

  const isReadOnly = QuotesUtils.isQuoteReadOnly(
    hasPermissionToEditQuote,
    quoteInfo.status,
    primaryQuoteInfo.status
  );

  const { data: companyQuoteTemplate } = useQuoteTemplateQuery(user.tenantId, snackbar);
  const { data: companyQuoteSettings } = useQuoteSettingsQuery(
    user.tenantId,
    user.tenantCompanyId,
    snackbar
  );
  const { data: companyServiceAgreements } = useServiceAgreementsQuery(
    user.tenantId,
    user.tenantCompanyId,
    snackbar
  );
  const { data: companyExternalMessages } = useExternalMessagesQuery(
    user.tenantId,
    user.tenantCompanyId,
    snackbar
  );
  const [companyEmployees] = useEmployees();
  const isAssetEnabled = getTenantSettingValueForKey('assetTrackingAgainstTask') === 'true';

  async function fetchQuoteInfo(localQuoteId) {
    const quoteResponse = await getQuoteById(localQuoteId, user, snackbar);
    if (quoteResponse) {
      const customSettings = JSON.parse(quoteResponse?.settingsJSON);
      setCurrentSettings(customSettings || quoteSettings(isAssetEnabled));
      dispatch({ type: ACTION.SET_QUOTE_INFO, payload: { ...quoteResponse } });
      setPrimaryQuoteId(quoteResponse?.id);
      setPrimaryQuoteInfo(quoteResponse);
      setPoNumber(quoteResponse?.customerPoNumber);
      formatVersionOptionData(
        quoteResponse,
        setQuoteVersionsOptionData,
        snackbarOn,
        setHasMultipleVersions,
        setQuoteTags
      );
    }
  }

  async function fetchVersionedQuoteInfo(id) {
    const quoteResponse = await getVersionedQuoteById(id, user, snackbar);
    if (quoteResponse) {
      const customSettings = JSON.parse(quoteResponse.settingsJSON);
      setCurrentSettings(customSettings || quoteSettings(isAssetEnabled));
      dispatch({ type: ACTION.SET_QUOTE_INFO, payload: { ...quoteResponse } });
    }
  }

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const versionId = urlParams.get('versionId');
    if (versionId) {
      (async () => {
        await fetchQuoteInfo(quoteId);
        fetchVersionedQuoteInfo(versionId);
      })();
    } else if (quoteId) {
      fetchQuoteInfo(quoteId);
    }
  }, [quoteId]);

  const performQuery = async (selectedRep, propertyData, isPropertyChange, customerService) => {
    if (!user?.tenantId || !propertyData.id) {
      return null;
    }
    try {
      const recordSortKeyResp = propertyData.parentSortKey;
      const { data } = await customerService.getPropertiesAndRepsByCustomer(
        user.tenantId,
        recordSortKeyResp
      );
      setRecordSortKey(recordSortKeyResp);
      if (data?.getCustomer) {
        let currentProperty = '';
        if (data.getCustomer.priceBookId) {
          setDefaultPriceBookId(data.getCustomer.priceBookId);
        } else {
          setDefaultPriceBookId(companyPriceBookId);
        }
        const customerProperties = data.getCustomer.customerProperties.items;
        const customerReps = data.getCustomer.customerReps.items;
        customerProperties.forEach(propertyItem => {
          const localProperty = propertyItem;
          const processedCustomerReps = propertyItem.customerReps.items.map(
            rep => rep.mappedEntity
          );
          localProperty.processedCustomerReps = processedCustomerReps;
        });

        if (selectedRep) {
          currentProperty = customerProperties.find(
            propertyItem => propertyItem?.id === propertyData.id
          );
        }

        let customerRepId = '';

        const repExist = customerReps.some(c => c.id === quoteInfo?.orderedById);
        if (repExist) {
          customerRepId = quoteInfo?.orderedById;
        } else if (selectedRep?.customerRep) {
          customerRepId = selectedRep.customerRep;
        } else if (isPropertyChange) {
          customerRepId = customerReps[0]?.id || '';
        } else {
          customerRepId = propertiesAndRepsData.customerRep || customerReps[0]?.id || '';
        }

        let newState = {
          properties: customerProperties,
          customer: data.getCustomer,
          recordSortKey,
          reps: currentProperty
            ? currentProperty.processedCustomerReps
            : propertiesAndRepsData.reps,
          customerReps: customerReps || [],
          customerRep: customerRepId
        };

        if (selectedRep) {
          // get the selected property rep object from the list of reps
          const propertyRepData = selectedRep.propertyRep
            ? newState.reps.find(r => r.id === selectedRep.propertyRep)
            : null;
          newState = {
            ...newState,
            ...selectedRep,
            // representativeData stores the selected property rep object, and propertyRep only stores the id
            representativeData: propertyRepData || propertiesAndRepsData.representativeData,
            propertyRep: propertyRepData?.id || propertiesAndRepsData.propertyRep,
            customerRepData: selectedRep.customerRep
              ? newState.customerReps.find(r => r.id === selectedRep.customerRep)
              : propertiesAndRepsData.customerRepData
          };
        } else if (isPropertyChange) {
          newState = {
            ...newState,
            propertyRep: newState.reps[0]?.id || '',
            customerRepData: newState.customerReps.find(r => r.id === customerRepId)
          };
        } else {
          newState = {
            ...newState,
            customerRepData: newState.customerReps.find(r => r.id === customerRepId)
          };
        }
        await setPropertiesAndRepsData(newState);
        return newState;
      }
    } catch (error) {
      logErrorWithCallback(
        error,
        snackbarOn,
        'Unable to retrieve customer property details, please try again later'
      );
    }
  };

  async function fetchPropertyData(propertyId, isPropertyChange) {
    setIsLoading(true);
    try {
      const customerPropertyService = new CustomerPropertyService();
      const customerService = new CustomerService();
      const { data } = await customerPropertyService.getCustomerPropertyInfoById(propertyId);
      if (data?.getCustomerPropertyById) {
        const propertyData = data.getCustomerPropertyById;
        const updatedPropertiesAndRepsData = await performQuery(
          null,
          propertyData,
          isPropertyChange,
          customerService
        );
        const composedPropertyDetails = QuotesUtils.composePropertyDetails(propertyData);
        setPropertyDetails(composedPropertyDetails);
        await QuotesUtils.composeAccountMgrOptions({
          customerPropertyService,
          customerService,
          propertyDetails: composedPropertyDetails,
          setAccountManagerOptions,
          snackbar
        });
        const newState = await getCustomerRepDetails({
          customerPropertyService,
          propertyData,
          quoteInfo,
          setPropertiesAndRepsData,
          snackbarOn,
          updatedPropertiesAndRepsData,
          user
        });
        return newState;
      }
    } catch (err) {
      logErrorWithCallback(err, snackbarOn, 'Unable to get property information');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const propertyId = isEmpty(property) ? history.location.state?.propertyId : property.id;
    if (!propertyId) return;
    fetchPropertyData(propertyId);
  }, [property.id, quoteInfo.versionNumber]);

  const addRep = async (rep, name, currentlyEditedNote) => {
    try {
      const customerService = new CustomerService();
      if (!rep?.id && rep?.firstName) {
        if (rep.note && rep.note.length > 0) {
          rep.repNotes = [{ subject: '', note: rep.note }];
        }
        const { data } = await customerService.addCustomerRepToCustomer({
          ...rep,
          parent: {
            id: propertyDetails?.property?.parentId || propertyDetails?.property?.parentEntity?.id,
            tenantId: propertyDetails?.property.tenantId,
            customerPropertyId: name === 'propertyRep' ? propertyDetails?.property?.id : null
          }
        });
        const { addCustomerRepToCustomer } = data;
        if (quoteInfo?.id) {
          const keyToUpdate = name === 'propertyRep' ? 'propertyRepId' : 'orderedById';
          await updateQuoteRep({
            ...serviceProps,
            dataToUpdate: { [keyToUpdate]: addCustomerRepToCustomer[0].id }
          });
        }

        if (recordSortKey) {
          await performQuery(
            {
              [name]: addCustomerRepToCustomer[0].id || ''
            },
            propertyDetails?.property,
            false,
            customerService
          );
        }

        snackbarOn('success', `${addCustomerRepToCustomer[0].name} rep created successfully`);
      } else if (rep?.id && rep?.firstName) {
        currentlyEditedNote.note = rep.note;
        if (currentlyEditedNote.id) {
          rep.repNotes = [
            {
              id: currentlyEditedNote.id,
              note: currentlyEditedNote.note,
              subject: currentlyEditedNote.subject
            }
          ];
        } else if (currentlyEditedNote.note?.length > 0) {
          rep.repNotes = [{ subject: '', note: currentlyEditedNote.note }];
        }

        if (rep.version) {
          rep.version = parseInt(rep.version);
        }

        const { data } = await customerService.updateCustomerRep({
          ...rep,
          parent: {
            id: (propertyDetails?.property?.parentEntity || {}).id,
            tenantId: propertyDetails?.property?.tenantId,
            customerPropertyId: propertyDetails?.property?.id
          }
        });
        if (data) {
          const { updateCustomerRepAndRealated } = data;
          if (recordSortKey) {
            fetchQuoteInfo(quoteId);
            await performQuery(
              {
                [name]: updateCustomerRepAndRealated?.id || ''
              },
              propertyDetails?.property,
              false,
              customerService
            );
          }
          snackbarOn('success', `${updateCustomerRepAndRealated?.name} rep updated successfully`);
        }
      }
    } catch (err) {
      logErrorWithCallback(err, snackbarOn, 'Unable to save the representative');
    }
  };

  const fetchPayrollCosts = useCallback(async () => {
    try {
      const service = new TimesheetsService();
      const response = await service.getPayrollLabourTypes(user.tenantId, user.tenantCompanyId);
      const labourTypes = response?.data?.getCompany?.labourTypes?.items;
      const responseData = getLabourTypes(labourTypes);
      const laborTypesWithPayrollCosts = responseData.map(type => ({
        ...type,
        quoteLineLaborPayrollHours: sortBy(
          type.payrollCosts.map(costs => {
            const hourTypeInfo = payrollHourTypes.find(
              hourType => hourType.id === costs.hourTypeId
            );
            // Add sorting info so labor rates and billing rates displayed in same order
            return {
              unitCost: costs.cost || 0,
              hourType: hourTypeInfo?.hourType,
              sortOrder: hourTypeInfo?.sortOrder,
              payrollHourTypeId: hourTypeInfo?.id,
              costCodeId: costs.costCodeId,
              jobCostTypeId: costs.jobCostTypeId
            };
          }),
          'sortOrder'
        )
      }));
      return sortBy(laborTypesWithPayrollCosts, 'sortOrder');
    } catch (error) {
      logErrorWithCallback(
        error,
        snackbarOn,
        'Unable to fetch payroll types, please try again later'
      );
    }
  }, [payrollHourTypes, user]);

  const fetchBillingRates = useCallback(
    async laborTypesWithLaborRates => {
      const { client } = AmplifyService.appSyncClient();
      const { data } = await client.query({
        query: getCompany,
        variables: {
          partitionKey: user.tenantId,
          sortKey: `${user.tenantId}_Company_${user.tenantCompanyId}`
        }
      });
      const billingHourTypeItems =
        sortBy(data?.getCompany?.billingHourTypes?.items, 'sortOrder') || [];
      let pricebookLaborEntryItems = [];
      if (quoteState.quoteInfo.priceBook) {
        pricebookLaborEntryItems = quoteState.quoteInfo.priceBook?.pricebookLabourEntries?.items;
      } else {
        // Use default pricebook if no PB associated with quote
        const pricebookResponse = await client.query({
          query: getPricebookById,
          variables: { id: defaultPriceBookId }
        });
        pricebookLaborEntryItems =
          pricebookResponse.data.getPricebookById.pricebookLabourEntries.items;
      }
      const laborTypesWithBillingAndLaborRates = laborTypesWithLaborRates.map(type => ({
        ...type,
        quoteLineLaborBillingHours: billingHourTypeItems.map(item => ({
          billingHourTypeId: item.id,
          hourType: item.hourType,
          sortOrder: item?.sortOrder,
          unitPrice:
            pricebookLaborEntryItems.find(
              elem => elem.labourTypeId === type.id && elem.billingHourTypeId === item.id
            )?.rate || 0,
          productId: type.labourTypeBillingHourTypeProducts?.find(
            el => el.billingHourTypeId === item.id
          )?.productId
        }))
      }));
      setLaborRates(laborTypesWithBillingAndLaborRates);
    },
    [quoteState, user]
  );

  useEffect(() => {
    async function fetchRateInfo() {
      const laborTypesWithPayrollCosts = await fetchPayrollCosts();
      fetchBillingRates(laborTypesWithPayrollCosts);
    }
    if (!isEmpty(quoteInfo)) {
      fetchRateInfo();
    }
  }, [fetchBillingRates, fetchPayrollCosts]);

  const classes = styles();

  const onQuoteInfoSave = async info => {
    const { departments } = companyContext.getCompany;
    const { billTo, propertyAddress } = propertyDetails;
    try {
      setIsButtonDisabled(true);
      const selectedDepartment = departments?.items.find(dept => dept.id === info.departmentId);
      const values = info
        ? {
            billTo,
            name: info.name,
            dueDate: info.dueDate,
            expirationLength: info.expirationLength,
            versionLabel: info.versionLabel,
            ownerId: info.ownerId,
            customerPoNumber: info.customerPoNumber,
            salesById: info.salesById,
            orderedById: propertiesAndRepsData?.customerRep,
            propertyRepId: propertiesAndRepsData?.propertyRep,
            departmentId: info.departmentId,
            departmentValue: selectedDepartment?.tagName,
            // set priceBookId to empty space if no selection is made for conditionals when formatting form data
            priceBookId: info.priceBookId || defaultPriceBookId || ' ',
            serviceAgreementId: info.serviceAgreementId || '',
            accountManagerId: info.accountManagerId,
            accountManagerValue:
              accountManagerOptions.find(option => option.value === info.accountManagerId)?.label ||
              null
          }
        : {};

      if (info?.id) {
        const updatedValues = {
          ...values,
          departmentValue: selectedDepartment?.tagName,
          id: info.id,
          version: quoteInfo?.version,
          ownerValue: getEmployeeName(companyEmployees, values.ownerId),
          salesByValue: getEmployeeName(companyEmployees, values.salesById)
        };
        updateQuoteMutation({
          ...serviceProps,
          updatedValues
        });
      } else if (info) {
        // Creating a new quote
        const createValues = {
          customerAndPropertyAndAddress: '',
          customerName: propertyDetails?.customer?.customerName,
          propertyName: propertyDetails?.property?.id,
          propertyAddress,
          customerId: propertyDetails?.customer?.id,
          customerPropertyId: propertyDetails?.property?.id,
          taxRateId: propertyDetails?.property?.taxRate?.id,
          taxRateValue: propertyDetails?.property?.taxRate?.taxRate,
          versionNumber: 1,
          ...values,
          detailsShownToCustomer: 'LineItemsWithGrandTotalOnly'
        };
        const response = await quoteService.addQuoteToCustomerProperty(
          user.tenantId,
          createValues,
          { employees: companyEmployees }
        );
        if (response?.data) {
          const result = response.data?.addQuotesToCustomerProperty;
          snackbarOn(
            'success',
            `Quote created successfuly ${result[0].customIdentifier || result[0].quoteNumber}`
          );
          sendMixpanelEvent(MIXPANEL_EVENT.CREATED_QUOTE, MIXPANEL_PAGE_NAME.QUOTES);
          if (result && result.length > 0) {
            history.push(`/quote/${result[0].id}`);
          }
        }
      }
    } catch (err) {
      logErrorWithCallback(err, snackbarOn, `Unable to save the quote's info`);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const onQuoteInternalNotesSave = data => {
    try {
      const updatedValues = {
        ...data,
        version: quoteInfo?.version
      };
      updateQuoteMutation({ ...serviceProps, updatedValues });
    } catch (err) {
      logErrorWithCallback(err, snackbarOn, `Unable to save the quote's internal notes`);
    }
  };

  const handleQuoteTemplateSave = ({ newData }) => {
    try {
      const updatedValues = {
        template: newData ?? ckEditorData,
        id: quoteInfo?.id,
        version: quoteInfo?.version
      };
      updateQuoteMutation({ ...serviceProps, updatedValues });
    } catch (err) {
      logErrorWithCallback(err, snackbarOn, `Unable to save the quote's template`);
    }
  };

  const tabOnChange = (_, tabIndex) => {
    if (quoteInfo?.id) {
      selectedTab.current = tabIndex;
    } else if (tabIndex !== 0) {
      // only allow user to select other tabs if the quote has already been created
      snackbar('warning', 'Please save the quote before selecting another tab.');
    }
  };

  const headerButtons =
    history.location.pathname === '/quotes/new'
      ? [
          <ThemeProvider>
            <Button
              disabled={isLoading || isButtonDisabled}
              type="primary"
              onClick={() => formService.submit()}
              style={{ marginRight: 8, padding: 8 }}
              readOnly={
                !!(
                  [QuoteStatus.JOB_ADDED, QuoteStatus.CANCELLED, QuoteStatus.APPROVED].includes(
                    quoteInfo.status
                  ) ||
                  (quoteInfo.status === QuoteStatus.DISCARDED &&
                    primaryQuoteInfo.status === QuoteStatus.JOB_ADDED)
                )
              }
            >
              SAVE{' '}
            </Button>
          </ThemeProvider>
        ]
      : [
          <ThemeProvider>
            <Button
              type="primary"
              onClick={() => setOpenModal(true)}
              disabled={isLoading || isButtonDisabled}
            >
              GENERATE QUOTE
            </Button>
          </ThemeProvider>
        ];

  // 1) Converts parsed html string back into an html string with smartfields
  // 2) Reparses the newly converted string so it contains updated smart field values
  // 3) Saves reparsed string to quote
  // 4) Triggers CkEditor refresh so UI updated with current smart field values
  const handleRefreshSmartfieldsClick = async () => {
    const htmlWithSmartfields = reinsertSmartfields(ckEditorData, companyQuoteTemplate);
    const parsedHTMLWithUpdatedSmartfieldValues = await parseHTMLForSmartFields({
      hasMultipleVersions,
      htmlStr: htmlWithSmartfields,
      smartFieldInfo: quoteInfo,
      settingsJSON: currentSettings,
      propertyDetails
    });
    await handleQuoteTemplateSave({ newData: parsedHTMLWithUpdatedSmartfieldValues });
    setRefreshCKEditor({ isTrue: true, data: parsedHTMLWithUpdatedSmartfieldValues });
  };

  const createNewVersion = async () => {
    if (!isEmpty(quoteVersionsOptionData)) {
      const primaryQuoteIdMatch = quoteVersionsOptionData.find(
        version => version.isPrimary === true
      ).id;
      const quoteResponse = await createQuoteVersion({
        ...serviceProps,
        primaryQuoteId: primaryQuoteIdMatch,
        quoteState
      });
      if (quoteResponse) {
        const versionOptions = await formatVersionOptionData(
          quoteResponse,
          setQuoteVersionsOptionData,
          snackbarOn,
          setHasMultipleVersions,
          setQuoteTags
        );

        // get the newest version id which is at the end of the sorted version options array
        const newVersionId = versionOptions[versionOptions?.length - 1].id;
        changeVersionsView(newVersionId, versionOptions, fetchQuoteInfo, fetchVersionedQuoteInfo);
      }
    }
  };

  const handleActionIfDeactivated = useMemo(
    () => (service, actionName, type) => {
      if (propertyDetails?.property?.status === AppConstants.INACTIVE) {
        snackbar('error', `Please activate this quote's property before ${actionName}.`);
      } else {
        service(type);
      }
    },
    [propertyDetails, snackbar]
  );

  const emailTemplateOptions = useMemo(
    () =>
      quoteInfo?.departmentId
        ? (companyExternalMessages || []).filter(
            template =>
              template?.type === EntityType.QUOTE &&
              (template?.externalMessageDepartments?.items || []).some(
                d => d?.department?.id === quoteInfo.departmentId
              )
          )
        : [],
    [quoteInfo.departmentId]
  );

  useEffect(() => {
    const name = getQuoteName(quoteInfo, hasMultipleVersions);
    setQuoteName(`Quote ${name}`);
  }, [
    quoteInfo.customIdentifier,
    quoteInfo.quoteNumber,
    hasMultipleVersions,
    quoteInfo.versionNumber,
    quoteInfo.name,
    quoteInfo.versionLabel
  ]);

  const onSettingsChange = (change, { values }) => {
    // Prevent update when user initally tries to enable overrides and show warning instead
    if (change?.[SettingConstants.QUOTE_MODE]?.[SettingConstants.ENABLE_OVERRIDES]) {
      return;
    }
    setCurrentSettings(values);
  };

  const handlePropertyChange = async customerProperty => {
    const propertyId = customerProperty.parentId;
    const { data } = await new CustomerPropertyService().getCustomerPropertyInfoById(propertyId);
    const billingAddress = data.getCustomerPropertyById?.billingCustomer?.companyAddresses?.items.find(
      address => address.addressType === 'billingAddress'
    );
    const customerBillingAddress = getCombinedAddress(billingAddress);
    const isPropertyChange = true;
    const updatedPropertiesAndRepsData = await fetchPropertyData(propertyId, isPropertyChange);
    const updatedPriceBookId =
      data.getCustomerPropertyById.parentEntity.priceBook?.id || companyPriceBookId;
    await updateQuoteMutation({
      ...serviceProps,
      propertyId,
      updatedValues: {
        id: quoteInfo.id,
        billTo: customerBillingAddress || null,
        orderedById: updatedPropertiesAndRepsData?.customerRep || null,
        propertyRepId: updatedPropertiesAndRepsData?.propertyRep || null,
        taxRateId: data?.getCustomerPropertyById?.taxRate?.id || null,
        taxRateValue: data?.getCustomerPropertyById?.taxRate?.taxRate ?? null,
        version: quoteInfo.version,
        priceBookId: updatedPriceBookId
      }
    });
    setOpenPropertyModal(false);
  };

  const getAlertColor = useMemo(() => {
    let color;
    switch (quoteInfo.status) {
      case QuoteStatus.REJECTED:
        color = InlineAlertTypes.RED;
        break;
      case QuoteStatus.APPROVED:
        color = InlineAlertTypes.GREEN;
        break;
      case QuoteStatus.CANCELLED:
        color = InlineAlertTypes.ORANGE;
        break;
      default:
        color = InlineAlertTypes.GREEN;
    }
    return color;
  }, [quoteInfo.status]);

  const SendEmailComponent = useMemo(() => {
    return (
      !isEmpty(quoteInfo) &&
      sendEmailModalOpen && (
        <SendEmailPopUp
          dataType="Share Quote"
          open={sendEmailModalOpen}
          quoteInfo={quoteInfo}
          onModalClose={() => setSendEmailModalOpen(false)}
          user={user}
          snackbar={snackbar}
          generatedPDFSizeRef={generatedPDFSizeRef}
          data={{
            attachments: quoteAttachments,
            ckEditorData,
            customer: propertyDetails.customer,
            customerProperty: propertyDetails.property,
            customerRep: { id: quoteInfo?.orderedById, email: quoteInfo?.orderedBy?.email },
            daysUntilExpiration: quoteInfo?.expirationLength,
            propertyRepId: quoteInfo?.propertyRepId,
            emailSubject: `${companyContext?.getCompany?.companyName} - Quote ${quoteInfo?.name ||
              ''}`,
            billingCustomer: propertyDetails?.property?.billingCustomer,
            pdfFileName: `${quoteName.replace(/\s/g, '_')}.pdf`,
            allowEditEmailSubject: true,
            shouldGeneratePDF: true,
            logoUrl: companyContext?.getCompany?.logoUrl,
            sendEmailFn: payload =>
              shareQuoteMutation({
                ...serviceProps,
                email: payload.email,
                expirationDate: payload.expirationDate,
                setQuoteVersionsOptionData,
                quoteVersionsOptionData
              }),
            uploadPDF: () =>
              generatePDF({
                data: ckEditorData,
                fileName: `${quoteName.replace(/\s/g, '_')}.pdf`,
                user,
                status: quoteInfo?.status,
                snackbar,
                generatedPDFSizeRef,
                updateQuoteMutation: pdfUrl =>
                  updateQuoteMutation({
                    ...serviceProps,
                    updatedValues: {
                      id: quoteInfo.id,
                      version: quoteInfo.version,
                      pdfUrl,
                      template: ckEditorData
                    },
                    successMessage: 'Successfully saved and updated quote'
                  })
              })
          }}
          templates={emailTemplateOptions}
          maxAttachmentSizeWarningLabel="Attachments over 10MB cannot be sent with the quote."
          maxAttachmentSize={10000000}
        />
      )
    );
  }, [quoteInfo, sendEmailModalOpen]);

  return (
    <>
      <PageHeader
        addToJob={async (jobCustomIdentifier, totalBudgetedHours) => {
          setIsLoading(true);
          const response = await createJobMutation({
            ...serviceProps,
            jobCustomIdentifier,
            primaryQuoteId,
            totalBudgetedHours
          });
          setIsLoading(false);
          return response;
        }}
        addExistingJobToQuote={async (jobId, totalBudgetedHours) => {
          setIsLoading(true);
          const response = await existingJobMutation({
            ...serviceProps,
            jobId,
            primaryQuoteId,
            totalBudgetedHours
          });
          setIsLoading(false);
          return response;
        }}
        cancelQuoteMutation={async setConfirmData => {
          setIsLoading(true);
          await cancelQuoteMutation({
            ...serviceProps,
            setConfirmData,
            primaryQuoteId
          });
          setIsLoading(false);
        }}
        cloneOrReopenQuoteMutation={type => {
          cloneOrReopenQuoteMutation({
            ...serviceProps,
            history,
            type
          });
        }}
        createNewVersion={() => createNewVersion()}
        customerPropertyId={property.id}
        fetchQuoteInfo={fetchQuoteInfo}
        fetchVersionedQuoteInfo={fetchVersionedQuoteInfo}
        handleActionIfDeactivated={handleActionIfDeactivated}
        headerButtons={headerButtons}
        isLoading={isLoading}
        hasPermissionToEditQuote={hasPermissionToEditQuote}
        poNumber={poNumber}
        primaryQuoteId={primaryQuoteId}
        propertyDetails={propertyDetails}
        quoteInfo={QuotesUtils.composePageHeaderQuoteInfo(quoteInfo, companyEmployees)}
        quoteTags={quoteTags}
        quoteAttachments={quoteAttachments}
        quoteVersions={quoteVersionsOptionData}
        setPoNumber={setPoNumber}
        setPrimaryVersion={selectedValue =>
          setPrimaryVersionMutation({ ...serviceProps, selectedValue })
        }
        user={user}
        serviceProps={serviceProps}
        quotePurchaseOrders={quotePurchaseOrders}
      />
      {quoteState.loading && (
        <QuoteSettings
          isReadOnly={isReadOnly}
          currentSettings={currentSettings}
          user={user}
          serviceProps={serviceProps}
          onSettingsChange={onSettingsChange}
          isOverridesModalOpen={isOverridesModalOpen}
          setIsOverridesModalOpen={setIsOverridesModalOpen}
          setCurrentSettings={setCurrentSettings}
          isAssetEnabled={isAssetEnabled}
        />
      )}

      {[QuoteStatus.REJECTED, QuoteStatus.APPROVED, QuoteStatus.CANCELLED].includes(
        quoteInfo.status
      ) && (
        <Grid style={{ padding: '12px 0' }}>
          <InlineAlert
            type={getAlertColor}
            message={QuotesUtils.createStatusAlertMsg(
              quoteInfo?.rejectedReason,
              quoteInfo?.customerSignatures?.items
            )}
          />
        </Grid>
      )}
      <Tabs disableBottomPadding onChange={tabOnChange} selectedTab={selectedTab}>
        <Tab label="Info" caslKey={PermissionConstants.OBJECT_QUOTES}>
          <Box className={`${classes.greyBorderTop} ${classes.container}`}>
            <ReadonlyWrapper readOnly={isReadOnly}>
              <Info
                accountManagerOptions={accountManagerOptions}
                primaryQuoteId={primaryQuoteId}
                openPropertyModal={openPropertyModal}
                setOpenPropertyModal={setOpenPropertyModal}
                updatedQuoteInfo={updatedQuoteInfo}
                setUpdatedQuoteInfo={setUpdatedQuoteInfo}
                addRep={addRep}
                infoSave={onQuoteInfoSave}
                isLoading={isLoading}
                companyEmployees={companyEmployees}
                propertiesAndRepsData={propertiesAndRepsData}
                propertyDetails={propertyDetails}
                quoteInfo={quoteInfo}
                setFormService={setFormService}
                setPropertiesAndRepsData={setPropertiesAndRepsData}
                updateQuoteRep={dataToUpdate =>
                  updateQuoteRep({
                    ...serviceProps,
                    dataToUpdate
                  })
                }
                companyServiceAgreements={companyServiceAgreements}
                user={user}
                isReadOnly={isReadOnly}
                quotePurchaseOrders={quotePurchaseOrders}
                defaultPriceBookId={defaultPriceBookId}
              />
            </ReadonlyWrapper>
          </Box>
        </Tab>
        <Tab label="Internal Notes" caslKey={PermissionConstants.OBJECT_QUOTES}>
          <Box className={classes.greyBorderTop}>
            <ReadonlyWrapper readOnly={isReadOnly}>
              <InternalNotes
                handleCreateService={() => getHandleCreateService('notes')}
                handleOnComplete={() => getHandleComplete('notes')}
                hasLoaded={!quoteState?.loading}
                quoteInfo={quoteInfo}
                onQuoteInternalNotesSave={onQuoteInternalNotesSave}
                updatedQuoteNotes={updatedQuoteNotes}
                setUpdatedQuoteNotes={setUpdatedQuoteNotes}
              />
            </ReadonlyWrapper>
          </Box>
        </Tab>
        <Tab label="Scope & Pricing" caslKey={PermissionConstants.OBJECT_QUOTES}>
          <Box className={`${classes.greyBorderTop} ${classes.pricingContainer}`}>
            <Grid container>
              <Grid item md={10} className={classes.taskContainer}>
                <Tasks
                  isReadOnly={isReadOnly}
                  payrollHourTypes={payrollHourTypes}
                  laborRates={laborRates}
                  quoteInfo={quoteInfo}
                  taskList={quoteLineTasks.items || []}
                  quoteLineProducts={
                    (quoteLineTasks.items && sortBy(quoteLineProducts.items, 'sortOrder')) || []
                  }
                  config={{
                    showTaskList: true,
                    addToBtnLabel: 'To Quote',
                    addToLabel: 'Quote',
                    showPricing: true,
                    showMarkAsComplete: false,
                    showAudit: false,
                    showActivity: false,
                    ...currentSettings
                  }}
                  customerPropertyId={property.id}
                  updateLaborLineItems={({ laborLineItemArr, taskId, version }) =>
                    updateLaborLineItems({
                      ...serviceProps,
                      laborLineItemArr,
                      taskId,
                      version
                    })
                  }
                  addTaskService={newTask =>
                    addTaskService({
                      ...serviceProps,
                      newTask
                    })
                  }
                  updateTaskService={taskToUpdate =>
                    updateTaskService({
                      ...serviceProps,
                      taskToUpdate
                    })
                  }
                  deleteTaskService={task =>
                    deleteQuoteLineTask({
                      ...serviceProps,
                      itemId: task
                    })
                  }
                  updateTaskEntriesForTaskService={(products, task) =>
                    updateQuoteLineProductsOnTask({
                      ...serviceProps,
                      task,
                      products
                    })
                  }
                  addTaskEntriesToTaskService={(products, task) =>
                    addQuoteLineProductsOnTask({
                      ...serviceProps,
                      products,
                      task
                    })
                  }
                  addQuoteLineProducts={product =>
                    addQuoteLineProducts({
                      ...serviceProps,
                      product
                    })
                  }
                  updateTaxRateOnQuote={taxRate =>
                    updateTaxRateOnQuote({
                      ...serviceProps,
                      taxRate
                    })
                  }
                  deleteTaskEntryFromTaskService={taskId =>
                    deleteProductEntry({
                      ...serviceProps,
                      itemId: taskId
                    })
                  }
                  deleteLaborLineItem={taskId =>
                    deleteLaborLineItem({
                      ...serviceProps,
                      itemId: taskId
                    })
                  }
                  deleteTaskGroup={taskGroupId =>
                    deleteTaskGroup({
                      ...serviceProps,
                      itemId: taskGroupId
                    })
                  }
                  deleteLineItem={taskId =>
                    deleteLineItem({
                      ...serviceProps,
                      itemId: taskId
                    })
                  }
                  addTaskGroup={(groupData, taskIds) =>
                    addTaskGroup({
                      ...serviceProps,
                      groupData,
                      taskIds
                    })
                  }
                  updateTaskGroup={(groupData, taskIds) =>
                    updateTaskGroup({
                      ...serviceProps,
                      groupData,
                      taskIds
                    })
                  }
                  updateOverrideAmountOnQuote={overrideData => {
                    updateOverrideAmountOnQuote({
                      ...serviceProps,
                      overrideData
                    });
                  }}
                />
              </Grid>
              {!currentSettings?.[SettingConstants.QUOTE_MODE][
                SettingConstants.ENABLE_OVERRIDES
              ] && <Profitability quoteInfo={quoteInfo} />}
            </Grid>
          </Box>
        </Tab>
        <Tab label="Attachments" caslKey={PermissionConstants.OBJECT_QUOTES}>
          <Box className={`${classes.greyBorderTop} ${classes.container}`}>
            <Attachment
              parent={{}} // not required, but the component to send data, passing empty obj
              data={quoteAttachments || { items: [] }}
              refresh={async () => {
                const { attachments } = await getAttachmentsForQuote(
                  primaryQuoteId,
                  user,
                  snackbar
                );
                const composedAttachments = QuotesUtils.composeQuoteAttachments(attachments);
                dispatch({
                  type: ACTION.SET_QUOTE_ATTACHMENTS,
                  payload: composedAttachments
                });
              }}
              mutationService={values => handleQuoteAttachment(values, primaryQuoteId, user)}
              customDeleteMutationService={quoteService.deleteAttachmentFromQuote}
              enableThumbnails
              readOnly={false}
              maxFileSizeWarningLabel="Attachments over 10MB cannot be sent with the quote."
              maxFileSize={10000000}
              hasPermissionToEdit={hasPermissionToEditQuote}
              hasShareWithTechsOption={flags[FeatureFlags.QUOTE_ATTACHMENTS_ENHANCEMENTS]}
              hasInternalFileOption={flags[FeatureFlags.QUOTE_ATTACHMENTS_ENHANCEMENTS]}
            />
          </Box>
        </Tab>
        <Tab label="Document History" caslKey={PermissionConstants.OBJECT_QUOTES}>
          <Box className={`${classes.greyBorderTop} ${classes.container}`}>
            <DocHistory quoteVersionsOptionData={quoteVersionsOptionData} />
          </Box>
        </Tab>
        <Tab label="Activity" caslKey={PermissionConstants.OBJECT_QUOTES}>
          <Box className={`${classes.greyBorderTop} ${classes.container}`}>
            <AuditLogs
              key={`${quoteInfo.id}-activity`}
              dataService={() => getQuoteActivityLogs(quoteInfo.id, snackbar, primaryQuoteId)}
              variant="singleEntity"
              showCount={30}
              customFilterLogFunc={filterQuoteLogs}
              loadingParams={{
                variant: 'table',
                repeatCount: 8,
                paddingRight: '70%'
              }}
            />
          </Box>
        </Tab>
      </Tabs>
      <ThemeProvider>
        <CKEditorModal
          ckEditorData={ckEditorData}
          companyQuoteSettings={companyQuoteSettings}
          companyQuoteTemplate={companyQuoteTemplate}
          currentSettings={currentSettings}
          handleQuoteTemplateSave={handleQuoteTemplateSave}
          handleRefreshSmartfieldsClick={handleRefreshSmartfieldsClick}
          hasMultipleVersions={hasMultipleVersions}
          hasPermissionToEditQuote={hasPermissionToEditQuote}
          isLoading={isLoading}
          isReadOnly={isReadOnly}
          openModal={openModal}
          propertyDetails={propertyDetails}
          quoteInfo={quoteInfo}
          refreshCKEditor={refreshCKEditor}
          setCKEditorData={setCKEditorData}
          setOpenModal={setOpenModal}
          setRefreshCKEditor={setRefreshCKEditor}
          setSendEmailModalOpen={setSendEmailModalOpen}
        />
        <CustomerPropertyModal
          defaultSearchIndex={defaultSearchIndex}
          handlePropertyChange={handlePropertyChange}
          openPropertyModal={openPropertyModal}
          setOpenPropertyModal={setOpenPropertyModal}
        />
      </ThemeProvider>
      {SendEmailComponent}
    </>
  );
};
const MultipleForms = withMultipleForms(QuoteDetail, ['info', 'notes', 'scope', 'quoteDefaults']);
export default connect(state => ({ user: state.user }), { snackbar: snackbarOn })(MultipleForms);
