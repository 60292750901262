import React from 'react';
import { bool, shape, string } from 'prop-types';
import { Typography } from '@buildhero/sergeant';
import AsyncTypography from 'components/AsyncTypography';
import { JobPropTypes } from '@dispatch/Dispatch.props';
import JobLink from '@dispatch/components/JobLink';
import { useStyles } from './VisitDetailsHeader.styles.';

const VisitDetailsHeader = ({ visit, job, loading }) => {
  const classes = useStyles();
  return (
    <div className={classes.headerInfo}>
      <AsyncTypography loading={loading} width={150} variant="body1">
        <JobLink job={job} />
        <Typography className={classes.visitNumber}>, Visit {visit?.visitNumber}</Typography>
      </AsyncTypography>
    </div>
  );
};

VisitDetailsHeader.propTypes = {
  visit: shape({
    visitNumber: string
  }).isRequired,
  job: JobPropTypes.isRequired,
  loading: bool
};

VisitDetailsHeader.defaultProps = {
  loading: false
};

export default VisitDetailsHeader;
