import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { UserPermission, Spinner } from 'components';
import ErrorBoundaries from 'scenes/Error';
import { PermissionConstants } from 'utils/AppConstants';

import { selectForms } from '../../selectors';
import { useVisitDetails } from '../../queries';

import ReportInfo from '../ReportInfo';
import AssetTable from '../AssetTable';
import TasksSection from '../TasksSection';
import FormTable from '../FormTable';
import PartsAndMaterialsSection from '../PartsAndMaterialsSection';
import LaborSection from '../LaborSection';

import TechnicianReportHeader from './components/TechnicianReportHeader';
import TechnicianReportAttachmentTable from './components/TechnicianReportAttachmentTable';
import TechnicianReportNotesTable from './components/TechnicianReportNotesTable';
import TechnicianReportSummaryNotesTable from './components/TechnicianReportSummaryNotesTable';
import SignatureSection from '../SignatureSection';

const TechnicianReport = () => {
  const { id } = useParams();
  const { loading, data, error, refetch } = useVisitDetails(id);
  const { job, ...visit } = data;

  useEffect(() => {
    refetch();
  }, []);

  if (job && job.closeoutReport) return null;

  return loading ? (
    <Spinner />
  ) : (
    <ErrorBoundaries>
      <UserPermission I="read" action={PermissionConstants.OBJECT_VISIT}>
        <TechnicianReportHeader reportId={id} job={job} visit={visit} />
        <ReportInfo job={job} visit={visit} />
        <TechnicianReportSummaryNotesTable visit={visit} loading={loading} error={error} />
        <TechnicianReportNotesTable visit={visit} loading={loading} error={error} />
        <AssetTable visit={visit} loading={loading} error={error} />
        <TasksSection visit={visit} job={job} loading={loading} error={error} />
        <FormTable forms={selectForms(visit)} loading={loading} error={error} />
        <TechnicianReportAttachmentTable visit={visit} loading={loading} error={error} />
        <SignatureSection visit={visit} />
        <PartsAndMaterialsSection visit={visit} loading={loading} error={error} />
        <LaborSection visit={visit} job={job} loading={loading} error={error} />
      </UserPermission>
    </ErrorBoundaries>
  );
};

export default TechnicianReport;
