import gql from 'graphql-tag';

const addNotesToPropertyAsset = gql`
  mutation addNotesToPropertyAsset($partitionKey: String!, $data: AddNotesToPropertyAssetInput!) {
    addNotesToPropertyAsset(partitionKey: $partitionKey, data: $data) {
      id
      version
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      subject
      note
      createdBy
      lastUpdatedDate
      lastUpdatedDateTime
      lastUpdatedBy
    }
  }
`;

export default addNotesToPropertyAsset;
