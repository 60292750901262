import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { MUIFormViewer } from '@buildhero/sergeant';
import { MetaDataViewer } from './metadata';

export default function PreviewModal({ classes, meta, handleClose, modal }) {
  let modalContent = <MUIFormViewer meta={meta} handleClose={handleClose} allowToggle />;
  if (modal === 'metadata') modalContent = <MetaDataViewer meta={meta} />;
  return (
    <Dialog
      open
      onClose={handleClose}
      fullWidth
      maxWidth={false}
      className={classes.dialogContent}
      style={{ maxWidth: 696 }}
    >
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
      <DialogContent>{modalContent}</DialogContent>
    </Dialog>
  );
}
