import isFunction from 'lodash/isFunction';
import React, { useState, useRef, useEffect } from 'react';
import { isObject } from 'utils';

import {
  Chip,
  Grid,
  Button as MUIButton,
  InputAdornment,
  Popover,
  List,
  ListItem,
  ListItemText,
  Select as MUISelect,
  MenuItem,
  Checkbox,
  Typography
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import moment from 'moment';

import { Input, DateInput, Select, Button, ThemeProvider, Label } from '@buildhero/sergeant';
import ErrorBoundaries from 'scenes/Error';
import { StyledSectionDivider } from 'components/Divider';
import _ from 'lodash';
import { filterTypes } from 'utils/constants';
import {
  getFilterParamFromMetadata,
  getDefaultValueAndCondition,
  getAvailableFilterKeys,
  getCurrentFilterFromSavedState
} from './FilterHelper';

const MenuProps = {
  PaperProps: {
    style: {
      minWidth: 250
    }
  }
};

const useStyles = makeStyles(theme => ({
  addFilter: {
    color: theme.palette.brand.green,
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    fontSize: 13
  },
  clearIcon: theme.typography.body1,
  gridItem: {
    alignItems: 'center',
    '& .css-b8ldur-Input': {
      margin: 0,
      paddingTop: 0,
      paddingBottom: 0
    },
    '& .dateField': {
      height: 'auto'
    },
    '& .dateField label': {
      fontSize: 13
    },
    '& .dateField input': {
      paddingTop: 15
    }
  },
  root: {
    width: '100%'
  },
  body2: {
    fontSize: 13,
    fontWeight: 400,
    color: theme.palette.grayscale(20)
  },
  textLabel: {
    fontSize: 13,
    '& input': {
      paddingTop: 19
    }
  },
  multiSelectClasses: {
    '&.MuiSelect-root': {
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.background.inputBaseColor,
      alignSelf: 'flex-start'
    },
    '&.MuiSelect-select.MuiSelect-select': {
      fontSize: 13,
      display: 'flex',
      flexWrap: 'wrap',
      alignSelf: 'center',
      flexDirection: 'row',
      width: theme.spacing(15),
      paddingLeft: theme.spacing(2),
      color: theme.palette.grayscale(20)
    }
  },
  selectedOption: {
    paddingRight: theme.spacing(1)
  },
  filterHint: {
    alignSelf: 'flex-start'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));

const dateDayToString = value => {
  return (
    value &&
    moment(value)
      .startOf('day')
      .format('YYYY-MM-DD')
  );
};

const mergeValueWithMeta = (value, filterKey, meta, filterCondition = {}, format = 'object') => {
  if (!meta) return value;

  const metaColumn = meta.find(m => m.filterKey === filterKey);

  if (!metaColumn || !Array.isArray(metaColumn.options)) return value;

  if (Array.isArray(value)) {
    const newValueArr = value.map(v => {
      const option = metaColumn.options.find(o => o.value === v || o.label === v);
      if (!option) return v;

      return format === 'object' ? { value: option.value, label: option.label } : option.value;
    });

    return newValueArr;
  }
  if (value === undefined && isObject(metaColumn.filterType) && filterCondition.condition) {
    const newValue = metaColumn.filterType.customFieldConditionOptions.find(
      o => o.value === filterCondition.condition
    )?.defaultValue;
    if (newValue) return newValue;
  }

  return value;
};

const mergeFilterConditionsWithMeta = (filterConditions, filterKey, meta, savedFilterKey) => {
  if (!meta) return filterConditions;

  const metaColumn = meta.find(m => m.filterKey === filterKey);

  if (!metaColumn) return filterConditions;

  const filterCondition = filterConditions[savedFilterKey];

  if (!filterCondition) return filterConditions;

  const newValue = mergeValueWithMeta(
    filterCondition.value,
    filterKey,
    meta,
    filterCondition,
    'string'
  );

  const metaConditionOptionLabel =
    Array.isArray(metaColumn.options) &&
    isObject(metaColumn.filterType) &&
    (metaColumn.filterType.customFieldConditionOptions.find(o => {
      return (
        (o.id !== undefined && o.id === filterCondition.conditionToTurnIntoText) ||
        (o.defaultValue !== undefined &&
          o.defaultValue === newValue &&
          filterCondition.condition === o.value)
      );
    })?.label ||
      metaColumn.filterType.customFieldConditionOptions.find(
        o => o.value === filterCondition.condition
      )?.label);

  const newFilterCondition = {
    ...filterCondition,
    value: newValue,
    label: metaConditionOptionLabel
  };

  const result = Object.keys(filterConditions).reduce((acc, cv) => {
    if (savedFilterKey !== filterKey && cv === savedFilterKey) return acc || {};

    if (!acc && cv === filterKey) return { [cv]: newFilterCondition };

    if (!acc) return { [cv]: filterConditions[cv] };

    if (cv !== filterKey) return { ...acc, [cv]: filterConditions[cv] };

    return { ...acc, [cv]: newFilterCondition };
  }, {});

  return {
    ...result,
    [filterKey]: newFilterCondition
  };
};

const getFilterKeyFromMeta = (filterKey, meta) => {
  if (!meta || meta.some(m => m.filterKey === filterKey)) return filterKey;

  const metaColumn = meta.find(m => {
    return m?.subQueryCondition?.filter?.stringFilters?.some(f => f?.fieldName === filterKey);
  });

  if (!metaColumn) return filterKey;

  return metaColumn.filterKey;
};

export const preprocessFilter = (
  val,
  filterType,
  filKey,
  filConds,
  onComplete = () => {},
  meta = null
) => {
  const filterKey = getFilterKeyFromMeta(filKey, meta);
  const value = mergeValueWithMeta(val, filterKey, meta, filConds[filKey]);
  const filterConditions = mergeFilterConditionsWithMeta(filConds, filterKey, meta, filKey, value);

  let formattedValue = '';
  let labels = [];

  const formatValueForFilterType = {
    integerFilters: _val => (Array.isArray(_val) ? _val : parseInt(_val, 10)),
    floatFilters: _val =>
      /* Store float value as is, and parse later when applying filter,
      in order to allow decimal values */
      _val,
    booleanFilters: _val => {
      if (_val === 'false' || !_val) return false;
      if (_val === 'true') return true;
      return _val;
    },
    computedColumnFilters: _val => parseInt(_val, 10)
  };

  if (filterType === 'booleanFilters' || value || value === 0) {
    const formatValueFn = formatValueForFilterType[filterType];
    formattedValue = formatValueFn ? formatValueFn(value) : value;
    labels = Array.isArray(formattedValue)
      ? formattedValue.map(entry => (entry?.label ? entry.label : entry))
      : undefined;
    formattedValue = Array.isArray(formattedValue)
      ? formattedValue.map(entry => (entry?.value ? entry.value : entry))
      : formattedValue;
  }

  const filterAttr = filterConditions[filterKey] || {};
  filterAttr.value = formattedValue;
  filterAttr.labels = labels;

  if (Array.isArray(meta)) {
    const metaColumn = meta.find(m => m.filterKey === filterKey);

    if (
      metaColumn &&
      metaColumn.convertToSubQuery &&
      metaColumn?.subQueryCondition &&
      isObject(metaColumn?.subQueryCondition?.fieldComparator)
    ) {
      filterAttr.convertToSubQuery = metaColumn.convertToSubQuery;
      filterAttr.subQueryCondition = metaColumn.subQueryCondition;
    }

    if (!filterAttr.type) {
      const filter = metaColumn?.subQueryCondition?.filter;
      if (isObject(filter)) {
        const fType = Object.keys(filter).find(k => filter[k].some(e => e.fieldName === filKey));
        if (fType) {
          filterAttr.type = fType;
        }
      }
    }
  }

  const result = {
    ...filterConditions,
    [filterKey]: filterAttr
  };
  onComplete(result);
  return result;
};

export default function Filter(props) {
  const { metadata, applyFilter, user, toolbarClasses } = props;
  const buttonRef = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [filterConditions, setFilterConditions] = useState({});
  const [errors, setErrors] = useState({});
  const [currentFilter, setCurrentFilter] = useState([]);
  const [metadataWithFilterParam, setMetadataWithFilterParam] = useState([]);
  const classes = useStyles();
  // This will be used to display list of column which are available for filter
  const availableFilterKeys = getAvailableFilterKeys(metadataWithFilterParam, currentFilter);

  useEffect(() => {
    // prepare meta information about columns to be used for filtering
    if (metadata.length) {
      const updatedMetaDataForFilter = metadata
        .filter(item => !(item.disableFilter || item.internal))
        .flatMap(meta => {
          if (meta.filterOptions && !meta.hide) {
            // allow multiple filter options from one column
            return meta.filterOptions.map(filterMeta => ({
              ...filterMeta,
              ...getFilterParamFromMetadata(filterMeta, user)
            }));
          }
          return { ...meta, ...getFilterParamFromMetadata(meta, user) };
        });
      setMetadataWithFilterParam(updatedMetaDataForFilter);
    }
  }, [metadata, user]);

  const apply = () => {
    if (applyFilter) {
      let hasError = false;
      const errorsObj = {};
      Object.keys(filterConditions).forEach(filterKey => {
        const hasFilterKey = metadataWithFilterParam.some(
          columnMeta => columnMeta.filterKey === filterKey
        );
        if (!hasFilterKey) {
          // In case where there's a global filter that's not in the filter key options
          return;
        }
        if (filterConditions[filterKey]?.type === filterTypes.FLOAT_FILTERS) {
          filterConditions[filterKey].value = parseFloat(filterConditions[filterKey].value);
        }
        const hasCondition = filterConditions[filterKey].condition;
        const hasValue =
          filterConditions[filterKey].value || !isNaN(filterConditions[filterKey].value);
        if (!hasCondition && hasValue) {
          hasError = true;
          errorsObj[filterKey] = 'Pick a condition';
        } else if (hasCondition && !hasValue) {
          hasError = true;
          errorsObj.textFieldError = true;
        } else {
          const meta = metadataWithFilterParam.find(
            columnMeta => columnMeta.filterKey === filterKey
          );
          if (meta) {
            filterConditions[filterKey].convertToSubQuery = meta.convertToSubQuery;
            // create a deep copy of subquery condition
            filterConditions[filterKey].subQueryCondition = meta.subQueryCondition
              ? _.cloneDeep(meta.subQueryCondition)
              : null;
            if (
              meta?.conditionalSubQuery &&
              meta?.conditionalSubQuery?.condition === filterConditions[filterKey].condition
            ) {
              Object.keys(meta.conditionalSubQuery.subQueryFields).forEach(field => {
                // Update fields with any changed fields for conditional subQuery (eg. 'notExists' fieldComparator)
                filterConditions[filterKey].subQueryCondition[field] =
                  meta.conditionalSubQuery.subQueryFields[field];
              });
            }
          }
        }
      });

      if (hasError) {
        setErrors(errorsObj);
      } else {
        setErrors({});

        const fixedFilterConditions = Object.keys(filterConditions).reduce((acc, cv) => {
          const filterValueSerializer = currentFilter?.find(filter => filter?.id === cv)
            ?.filterValueSerializer;
          let condition = filterConditions[cv];
          if (isFunction(filterValueSerializer)) {
            condition.value = filterValueSerializer(filterConditions[cv]?.value);
          }
          const { label, subQueryCondition, type } = condition;

          if (subQueryCondition && label && isObject(subQueryCondition.fieldComparator)) {
            const metaColumn = metadata.find(m => m.filterKey === cv);
            if (metaColumn) {
              const removeFilter = metaColumn.filterType.customFieldConditionOptions.some(
                o => o.label === label && o.defaultValue === 'noFilter'
              );
              if (removeFilter) {
                condition = {
                  ...condition,
                  subQueryCondition: {
                    ...condition.subQueryCondition,
                    filter: {
                      ...condition.subQueryCondition.filter,
                      [type]: condition.subQueryCondition.filter[type].filter(
                        f => f.fieldName !== cv
                      )
                    }
                  }
                };
              }
            }
          }

          if (!acc) return { [cv]: condition };

          return {
            ...acc,
            [cv]: condition
          };
        }, {});
        applyFilter(fixedFilterConditions);
        setIsMenuOpen(!isMenuOpen);
      }
    }
  };

  const clear = () => {
    applyFilter({});
    setErrors({});
    setFilterConditions({});
    setIsMenuOpen(!isMenuOpen);
  };

  const removeFilterCondition = filterKey => {
    delete filterConditions[filterKey];
    setFilterConditions({
      ...filterConditions
    });
  };
  const changeColumnName = (ind, newColumnOption) => {
    const newColumn = metadataWithFilterParam.find(
      item => item.filterKey === newColumnOption?.value
    );
    const prevFilterKey = currentFilter[ind].filterKey;
    if (newColumn && newColumn.filterKey !== prevFilterKey) {
      // asign the new filter on the same index and clear filter condition of previous filter
      currentFilter[ind] = newColumn;
      setCurrentFilter([...currentFilter]);
      removeFilterCondition(prevFilterKey);
    }
  };
  const addNewFilter = () => {
    // find the first available filter column to be added
    const newFilter = metadataWithFilterParam.find(
      item => item.filterKey === availableFilterKeys[0].value
    );
    setCurrentFilter([...currentFilter, newFilter]);
  };
  const deleteFilter = index => {
    const removedFilter = currentFilter.splice(index, 1);
    setCurrentFilter([...currentFilter]);
    removeFilterCondition(removedFilter[0].filterKey);
  };
  const toggle = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      const filteredFilters = Object.keys(props.filter).reduce((acc, key) => {
        const filterMeta = metadataWithFilterParam.find(item => item.filterKey === key);
        if (isObject(filterMeta?.subQueryCondition?.fieldComparator)) {
          return acc;
        }

        return { ...acc, [key]: props.filter[key] };
      }, {});

      setFilterConditions(filteredFilters);
      setCurrentFilter(getCurrentFilterFromSavedState(props.filter, metadataWithFilterParam));
    }
  };

  const handleOnChangeCondition = (val, filterType, filterKey, conditionOptions, label, id) => {
    const filterAttr = filterConditions[filterKey] || {};
    filterAttr.label = label;
    filterAttr.condition = val;
    filterAttr.type = filterType;
    filterAttr.id = id;
    const condition = conditionOptions.find(cond => cond.value === val);
    if (condition.defaultValue !== undefined) {
      filterAttr.value = condition.defaultValue;
    }
    setFilterConditions({
      ...filterConditions,
      [filterKey]: filterAttr
    });
  };

  const handleOnChangeDate = (val, filterType, filterKey, isStartDate, isStringDate) => {
    const startFormattedValue = isStringDate ? dateDayToString(val) : val;
    const endFormattedValue = moment
      .unix(startFormattedValue)
      .endOf('day')
      .unix();
    const filterAttr = filterConditions[filterKey] || {};
    // For date we can use eq comparator, hence overriding
    filterAttr.condition = 'between';
    filterAttr.type = filterType;
    if (filterAttr.value) {
      if (isStartDate) {
        filterAttr.value[0] = startFormattedValue;
      } else {
        filterAttr.value[1] = endFormattedValue;
      }
    } else {
      filterAttr.value = [startFormattedValue, endFormattedValue];
    }
    setFilterConditions({
      ...filterConditions,
      [filterKey]: filterAttr
    });
  };

  const buttonClass = isMenuOpen
    ? `${toolbarClasses.toolbarButton} ${toolbarClasses.toolbarButtonActive}`
    : toolbarClasses.toolbarButton;
  return (
    <>
      <MUIButton
        type="leading"
        className={buttonClass}
        ref={buttonRef}
        onClick={toggle}
        endIcon={<ArrowDropDownIcon />}
      >
        SET FILTERS
      </MUIButton>
      <Popover
        id="add-menu"
        anchorEl={() => buttonRef.current}
        open={isMenuOpen}
        keepMounted
        onClose={() => setIsMenuOpen(false)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        PaperProps={{ style: { overflow: 'visible', width: '810px' } }}
      >
        <List>
          {currentFilter.map((item, ind) => {
            const {
              showDateField,
              showDateStringField,
              filterKey,
              filterType,
              showTextField,
              numeric,
              type,
              showMultiSelectField,
              showSelect,
              conditionOptions
            } = item;
            const textFieldType = numeric ? 'number' : 'text';
            const isCurrency = type === 'currency';
            const {
              defaultValue,
              defaultCondition,
              defaultLabel,
              multiSelectOptions,
              defaultId
            } = getDefaultValueAndCondition(item, filterConditions);
            const filterDropdownOptions = [
              { value: item.filterKey, label: item.filterLabel }
            ].concat(availableFilterKeys);
            let minDate;
            let maxDate;
            if (showDateField && defaultValue?.length) {
              [minDate, maxDate] = defaultValue;
            }

            return (
              <ListItem style={{ paddingRight: '32px' }}>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  style={{
                    marginBottom: 10,
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                    width: '100%'
                  }}
                  key={`Frag${item.id}`}
                >
                  <ErrorBoundaries>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className={classes.filterHint}>
                      <Typography
                        variant="body2"
                        classes={{
                          body2: classes.body2
                        }}
                      >
                        {ind === 0 ? 'Display rows where' : 'and where'}
                      </Typography>
                    </Grid>
                    <Grid container spacing={2} style={{ display: 'flex' }}>
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        key={`G1${item.id}`}
                        className={classes.gridItem}
                      >
                        <ThemeProvider>
                          <Select
                            searchable
                            id="filterKey"
                            name={`${item.filterKey}Key`}
                            options={filterDropdownOptions}
                            value={{ label: item.filterLabel, value: item.filterKey }}
                            label="Column"
                            onChange={value => {
                              changeColumnName(ind, value);
                            }}
                            key={`cond${item.id}`}
                          />
                        </ThemeProvider>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        key={`G2${item.id}`}
                        className={classes.gridItem}
                      >
                        <ThemeProvider>
                          {showDateField || showDateStringField ? (
                            <DateInput
                              type="date"
                              name={`${item.label}StartFilterValue`}
                              label="Start date"
                              key={`valStart${item.id}`}
                              value={defaultValue[0]}
                              onChange={val =>
                                handleOnChangeDate(
                                  val,
                                  filterType,
                                  filterKey,
                                  true,
                                  showDateStringField
                                )
                              }
                              maxDate={maxDate}
                              handleError={error => error}
                            />
                          ) : (
                            <Select
                              searchable
                              id="condition"
                              name="condition"
                              options={conditionOptions}
                              value={
                                // using condition instead of label, as default label is coming undefined when the chips are cleared
                                conditionOptions?.find(opt =>
                                  defaultId
                                    ? opt.id === defaultId && opt.value === defaultCondition
                                    : opt.value === defaultCondition
                                ) || ''
                              }
                              errorMsg={errors && errors[filterKey]}
                              label="Condition"
                              onChange={val =>
                                handleOnChangeCondition(
                                  val?.value,
                                  filterType,
                                  filterKey,
                                  conditionOptions,
                                  val?.label,
                                  val?.id
                                )
                              }
                              key={`cond${item.id}`}
                            />
                          )}
                        </ThemeProvider>
                      </Grid>
                      {defaultCondition !== 'empty' && defaultCondition !== 'notEmpty' && (
                        <Grid
                          container
                          xs={4}
                          sm={4}
                          md={4}
                          lg={4}
                          xl={4}
                          key={`G3${item.id}`}
                          className={classes.gridItem}
                        >
                          <ThemeProvider>
                            {showTextField && (
                              <Input
                                InputProps={{
                                  className: classes.textLabel,
                                  startAdornment: isCurrency ? (
                                    <InputAdornment position="start">$</InputAdornment>
                                  ) : null
                                }}
                                InputLabelProps={{ className: classes.textLabel }}
                                name={`${item.label}FilterValue`}
                                error={errors && errors?.textFieldError}
                                label="value"
                                placeholder={
                                  filterType === 'integerFilters' || filterType === 'floatFilters'
                                    ? 'Type number'
                                    : 'Type'
                                }
                                variant="filled"
                                fullWidth
                                type={textFieldType}
                                key={`val${item.id}`}
                                value={defaultValue}
                                disabled={
                                  defaultCondition === 'empty' || defaultCondition === 'notEmpty'
                                }
                                onChange={event =>
                                  preprocessFilter(
                                    event.target.value,
                                    filterType,
                                    filterKey,
                                    filterConditions,
                                    setFilterConditions
                                  )
                                }
                              />
                            )}
                            {showSelect && (
                              <Select
                                searchable
                                id="filerValue"
                                options={multiSelectOptions}
                                value={
                                  multiSelectOptions?.find(opt => opt.value === defaultValue) || ''
                                }
                                label="value"
                                /*
                                Note - not setting errorMsg prop since Boolean (& Select which are not used anywhere)
                                filter type has this field autopopulated when the condition field is filled.
                              */
                                onChange={val =>
                                  preprocessFilter(
                                    val?.value,
                                    filterType,
                                    filterKey,
                                    filterConditions,
                                    setFilterConditions
                                  )
                                }
                                key={`cond${item.id}`}
                              />
                            )}
                          </ThemeProvider>
                          {showMultiSelectField && (
                            <Grid
                              container
                              spacing={2}
                              style={{ display: 'flex', paddingLeft: 8, paddingRight: 8 }}
                            >
                              <Grid item key={`G4${item.id}`} className={classes.gridItem}>
                                <ThemeProvider>
                                  <Label label="VALUE" style={{ alignSelf: 'flex-end' }} />
                                </ThemeProvider>
                                <MUISelect
                                  id="multiselect"
                                  multiple
                                  classes={{ selectMenu: classes.multiSelectClasses }}
                                  value={defaultValue}
                                  label="value"
                                  onChange={event => {
                                    if (
                                      Array.isArray(event?.target?.value) &&
                                      event?.target?.value.length > 0
                                    ) {
                                      const entries = event.target.value
                                        .sort((a, b) => {
                                          const labelA = a?.label;
                                          const labelB = b?.label;

                                          if (labelA < labelB) {
                                            return -1;
                                          }
                                          if (labelA > labelB) {
                                            return 1;
                                          }
                                          return 0;
                                        })
                                        .filter((entry, index, array) => {
                                          const label = entry?.label;
                                          if (index !== 0) {
                                            const prevLabel = array[index - 1]?.label;
                                            if (prevLabel === label) return false;
                                          }
                                          if (index !== array.length - 1) {
                                            const nextLabel = array[index + 1]?.label;
                                            if (nextLabel === label) return false;
                                          }
                                          return true;
                                        });

                                      preprocessFilter(
                                        entries,
                                        filterType,
                                        filterKey,
                                        filterConditions,
                                        setFilterConditions
                                      );
                                    } else {
                                      // filter is invalid, should not be applied.
                                      preprocessFilter(
                                        undefined,
                                        filterType,
                                        filterKey,
                                        filterConditions,
                                        setFilterConditions
                                      );
                                    }
                                  }}
                                  input={<Input id="select-multiple-chip" />}
                                  renderValue={selected => {
                                    return showMultiSelectField ? (
                                      <div className={classes.chips}>
                                        {selected.map(value => {
                                          const label = value?.label ? value.label : value;
                                          return (
                                            <Chip
                                              key={label}
                                              size="small"
                                              label={label}
                                              className={classes.chip}
                                            />
                                          );
                                        })}
                                      </div>
                                    ) : (
                                      selected
                                    );
                                  }}
                                  MenuProps={MenuProps}
                                >
                                  {multiSelectOptions.map(({ value, label }) => (
                                    <MenuItem key={label} value={{ value, label }}>
                                      {showMultiSelectField && (
                                        <Checkbox
                                          checked={defaultValue?.some(
                                            entry => entry.label === label
                                          )}
                                        />
                                      )}
                                      <ListItemText primary={label} />
                                    </MenuItem>
                                  ))}
                                </MUISelect>
                              </Grid>
                            </Grid>
                          )}
                          <ThemeProvider>
                            {(showDateField || showDateStringField) && (
                              <DateInput
                                name={`${item.label}EndFilterValue`}
                                label="End date"
                                key={`valEnd${item.id}`}
                                value={defaultValue?.[1]}
                                onChange={val =>
                                  handleOnChangeDate(
                                    val,
                                    filterType,
                                    filterKey,
                                    false,
                                    showDateStringField
                                  )
                                }
                                minDate={minDate}
                                handleError={error => error}
                              />
                            )}
                          </ThemeProvider>
                        </Grid>
                      )}
                    </Grid>
                  </ErrorBoundaries>
                </Grid>
                {currentFilter.length > 1 ? (
                  <ClearIcon
                    className={classes.clearIcon}
                    style={{ position: 'absolute', right: '.7em' }}
                    onClick={() => deleteFilter(ind)}
                  />
                ) : null}
              </ListItem>
            );
          })}
        </List>

        <StyledSectionDivider customStyle={{ marginTop: 0, marginBottom: 18 }} />

        {availableFilterKeys.length ? (
          <Grid className={classes.addFilter}>
            <ThemeProvider>
              <Button
                type="leading"
                onClick={() => addNewFilter()}
                startIcon={<AddCircleOutlineIcon />}
              >
                Add new filter
              </Button>
            </ThemeProvider>
          </Grid>
        ) : null}
        <ThemeProvider>
          <Grid
            container
            direction="row-reverse"
            spacing={1}
            style={{ width: '100%', padding: '12px', margin: 0 }}
          >
            <Grid item>
              <Button type="primary" onClick={() => apply()}>
                Apply
              </Button>
            </Grid>
            <Grid item>
              <Button type="secondary" onClick={() => clear()}>
                Clear
              </Button>
            </Grid>
          </Grid>
        </ThemeProvider>
      </Popover>
    </>
  );
}
