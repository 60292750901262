import gql from 'graphql-tag';

const deletePurchaseOrderLine = gql`
  mutation deletePurchaseOrderLine($partitionKey: String!, $id: String!) {
    deletePurchaseOrderLine(partitionKey: $partitionKey, id: $id) {
      id
      sortKey
      parentId
    }
  }
`;

export default deletePurchaseOrderLine;
