import { Timezone } from 'scenes/Procurement/constants';
import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';
import { paymentTermSearch } from 'services/API/paymentTerm';

const defaultFlexStyle = '0 0 219px';
const marginEdit = '0px 16px 0px 0px';

const generateReceiptModalFormFields = {
  vendorDocumentNumber: { required: false },
  issuedBy: { required: true, nullable: false },
  paymentTerm: { required: true }
};

const generateReceiptContents = [
  // the 1st row
  {
    options: {
      direction: 'row',
      grow: 0,
      flexWrap: 'nowrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin: marginEdit
        },
        contents: [
          {
            component: {
              edit: 'TextInput'
            },
            source: 'vendorDocumentNumber',
            options: {
              label: 'Vendor Document Number',
              placeholder: 'Enter Vendor Document Number',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin: marginEdit
        },
        contents: [
          {
            component: {
              edit: 'DateInput'
            },
            source: 'issuedBy',
            options: {
              label: 'Issued By',
              placeholder: 'Enter Date',
              fullWidth: false,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true,
              timezone: Timezone.GMT
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin: marginEdit
        },
        contents: [
          {
            component: {
              edit: 'SearchBar'
            },
            source: 'paymentTerm',
            options: {
              resultFormatFunction: paymentTerm => `${paymentTerm.name}`,
              searchFunction: paymentTermSearch,
              searchColumns: ['name'],
              label: 'Payment Terms',
              placeholder: 'Search Payment Terms',
              fullWidth: true,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary',
              isRequired: true
            }
          }
        ]
      }
    ]
  }
];

const generateReceiptModalFormLayout = {
  fields: generateDefaultFieldsObject(generateReceiptModalFormFields),
  layouts: {
    edit: {
      options: {
        pageSize: 'LETTER'
      },
      contents: generateReceiptContents
    }
  }
};

export { generateReceiptModalFormFields, generateReceiptModalFormLayout };
