import { makeStyles } from '@material-ui/core/styles';

const properties = theme => ({
  color: theme.palette.grayscale(20),
  lineHeight: '14px',
  fontWeight: 'bold',
  marginBottom: theme.spacing(2)
});

export default makeStyles(theme => ({
  title: {
    ...properties(theme),
    fontSize: '22px'
  },
  subtitle: {
    ...properties(theme),
    fontSize: '16px'
  }
}));
