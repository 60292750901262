import gql from 'graphql-tag';

const softDeleteProductBundle = gql`
  mutation softDeleteProductBundle($partitionKey: String!, $id: String!) {
    softDeleteProductBundle(partitionKey: $partitionKey, id: $id) {
      id
      name
      description
      category
      subCategory
      type
      isActive
      createdDate
      version
    }
  }
`;

export default softDeleteProductBundle;
