const layout = ({
  technicians,
  departments,
  crews,
  formsList,
  visitDateWarning,
  crewTimeTracking
}) => ({
  fields: {
    visitDescription: {
      default: 'visitDescription',
      edit: 'visitDescription'
    },
    visitDate: {
      default: 'visitDate',
      edit: 'visitDate'
    },
    selectedCrewId: {
      default: 'selectedCrewId',
      edit: 'selectedCrewId'
    },
    startingTime: {
      default: 'startingTime',
      edit: 'startingTime'
    },
    departmentId: {
      default: 'departmentId',
      edit: 'departmentId'
    },
    primaryTechnicianId: {
      default: 'primaryTechnicianId',
      edit: 'primaryTechnicianId'
    },
    additionalTechnicianIds: {
      default: 'additionalTechnicianIds',
      edit: 'additionalTechnicianIds'
    },
    visitFormIds: {
      default: 'visitFormIds',
      edit: 'visitFormIds'
    }
  },
  layouts: {
    edit: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                flex: '0 1 542px',
                padding: 8,
                paddingBottom: 8
              },
              contents: [
                {
                  component: {
                    default: 'TextInput',
                    edit: 'TextInput'
                  },
                  source: 'visitDescription',
                  options: {
                    label: 'Visit Description',
                    isRequired: false,
                    fullWidth: false,
                    variant: 'standard',
                    color: 'secondary'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                flex: '0 1 542px',
                padding: 8
              },
              contents: [
                {
                  component: {
                    edit: 'SelectInput'
                  },
                  source: 'visitFormIds',
                  options: {
                    label: 'Forms',
                    placeholder: 'Select Forms',
                    inputOptions: formsList,
                    isRequired: false,
                    fullWidth: true,
                    alignment: 'left',
                    isMultipleSelection: true,
                    variant: 'standard',
                    color: 'secondary'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                flex: '0 1 542px',
                padding: 8
              },
              contents: [
                {
                  component: {
                    edit: 'DateInput'
                  },
                  source: 'visitDate',
                  options: {
                    label: 'Visit Date',
                    searchableCategory: 'date',
                    fullWidth: false,
                    alignment: 'left',
                    placeholder: 'Select Date',
                    variant: 'standard',
                    color: 'secondary',
                    subtext: visitDateWarning
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                padding: 8,
                flex: '0 1 542px'
              },
              contents: [
                {
                  component: {
                    edit: 'TimeInput'
                  },
                  source: 'visitTime',
                  options: {
                    label: 'Starting Time',
                    fullWidth: false,
                    placeholder: 'Select Time',
                    variant: 'standard',
                    color: 'secondary'
                  }
                }
              ]
            }
          ]
        },
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0,
            flexWrap: 'wrap',
            padding: 8
          },
          contents: [
            {
              component: {
                edit: 'PreferredTechniciansFormLayoutComponent'
              },
              source: 'preferredTechnicians',
              options: {
                stylingVariant: 'standard',
                getRequiredFields: () => ({
                  departmentId: true
                }),
                departments,
                technicians,
                crews,
                crewTimeTracking
              }
            }
          ]
        }
      ]
    }
  }
});

export default layout;
