import { axiosInstanceRestAPI as instance } from 'configs/http-client';

export const getPriceBooksByCompany = (offset, limit) => {
  let url = 'pricebooks';
  url = `${url}?${offset ? `offset=${offset}&` : ''}${limit ? `limit=${limit}` : ''}`;
  return instance.get(url);
};

export const getPriceBookById = priceBookId => {
  return instance.get(`priceBooks/${priceBookId}`);
};

export const createPriceBook = async priceBook2 => {
  return instance.post('pricebooks', priceBook2);
};

export const updatePriceBook = (priceBookId, priceBook2) => {
  return instance.put(`priceBooks/${priceBookId}`, priceBook2);
};

export const deletePriceBook = priceBookId => {
  return instance.delete(`priceBooks/${priceBookId}`);
};
