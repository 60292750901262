import React from 'react';

import CloudDoneOutlinedIcon from '@material-ui/icons/CloudDoneOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import CloudOffOutlinedIcon from '@material-ui/icons/CloudOffOutlined';
import CloudRetryingIcon from 'assets/Icons/retryingCloud.svg';
import CloudUnsavedChangesIcon from 'assets/Icons/unsavedChangesCloud.svg';

import { makeStyles } from '@material-ui/core/styles';

import { NetworkStatuses } from 'utils/constants';

const useStyles = makeStyles(() => ({
  statusText: {
    padding: '2px',
    width: '125px'
  },
  loading: {
    marginLeft: '0px !important',
    overflow: 'hidden',
    display: 'inline-block',
    verticalAlign: 'bottom',
    animation: '$ellipsis steps(4,end) 900ms infinite',
    width: '0px',
    marginRight: '15px'
  },
  '@keyframes ellipsis': {
    to: {
      width: '15px',
      marginRight: '0px'
    }
  }
}));

const CloudIcon = ({ status }) => {
  const classes = useStyles();

  const statusIconMapping = {
    [NetworkStatuses.READY]: (
      <>
        <CloudDoneOutlinedIcon /> <span className={classes.statusText}>Ready</span>
      </>
    ),
    [NetworkStatuses.HAS_UNSAVED_DATA]: (
      <>
        <img src={CloudUnsavedChangesIcon} alt="" />{' '}
        <span className={classes.statusText}>
          Unsaved Data <span className={classes.loading}>...</span>
        </span>
      </>
    ),
    [NetworkStatuses.UPDATING]: (
      <>
        <CloudUploadOutlinedIcon />{' '}
        <span className={classes.statusText}>
          Updating <span className={classes.loading}>...</span>
        </span>
      </>
    ),
    [NetworkStatuses.RETRYING]: (
      <>
        <img src={CloudRetryingIcon} alt="" />{' '}
        <span className={classes.statusText}>
          Retrying <span className={classes.loading}>...</span>
        </span>
      </>
    ),
    [NetworkStatuses.ERROR]: (
      <>
        <CloudOffOutlinedIcon /> <span className={classes.statusText}>Error</span>
      </>
    )
  };

  return statusIconMapping[status];
};

export default CloudIcon;
