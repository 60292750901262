import gql from 'graphql-tag';

const SyncTaxRates = gql`
  mutation SyncTaxRates($partitionKey: String!, $data: SyncTaxRatesInput!) {
    syncTaxRates(partitionKey: $partitionKey, data: $data) {
      id
      entityType
    }
  }
`;

export default SyncTaxRates;
