/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { CommonService, CustomerService, CustomerPropertyService } from 'services/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Logger } from 'services/Logger';
import { getCombinedAddress } from 'utils';
import { snackbarOn } from 'redux/actions/globalActions';
import labels from 'meta/labels';

import AlgoliaSearch from './algoliasearch';
import Dropdown from './dropdown';
import Label from './label';

class QuoteCustomerAndPropertyAndAddress extends React.Component {
  constructor(props) {
    super(props);
    this.CommonService = new CommonService();
    this.CustomerService = new CustomerService();
    this.CustomerPropertyService = new CustomerPropertyService();
    this.state = {
      properties: '',
      isProcessing: false
    };
  }

  componentDidMount() {
    const { form, specialbehaviour } = this.props;
    const {
      customer,
      property,
      propertyAddress,
      billTo,
      customerBillingAddress,
      queryResult
    } = specialbehaviour;

    // intially set form values to local state
    this.setState({
      [customer.name]: form.values[customer.name],
      [property.name]: form.values[property.name],
      [propertyAddress.name]: form.values[propertyAddress.name],
      properties: queryResult[property.specialBehaviour.queryPath],
      [billTo.name]: form.values[billTo.name],
      [customerBillingAddress.name]: form.values[customerBillingAddress.name]
    });
  }

  getDependentFieldData = async hit => {
    this.setState({ isProcessing: true });
    const { form, specialbehaviour } = this.props;
    const {
      customer,
      propertyAddress,
      property,
      billTo,
      customerBillingAddress,
      queryResult
    } = specialbehaviour;
    let data;
    try {
      const customerDetails = await this.getCustomerDetails(hit);
      ({ data } = await this.CustomerService.getPropertiesAndRepsByCustomer(
        this.props.user.tenantId,
        hit.sortKey
      ));
      if (data) {
        data.getCustomer.customerProperties.items.forEach(property => {
          const localProperty = property;
          const processedCustomerReps = [];
          property.customerReps.items.forEach(rep => {
            processedCustomerReps.push(rep.mappedEntity);
          });
          localProperty.processedCustomerReps = processedCustomerReps;
        });
        const properties = data.getCustomer.customerProperties.items || [];
        if (!_.isEmpty(properties)) {
          let propAddress = await this.getPropertyAddress(properties[0].id);
          let billToAddress = '';
          if (
            customerDetails &&
            customerDetails.companyAddresses &&
            customerDetails.companyAddresses.items
          ) {
            customerDetails.companyAddresses.items.forEach(address => {
              if (address.addressType === 'billingAddress') {
                billToAddress = getCombinedAddress(address);
              }
            });
          }
          let values = {
            customer: customerDetails,
            customerId: hit.id,
            [customer.name]: hit.customerName,
            [propertyAddress.name]: propAddress,
            [property.name]: properties[0].id,
            property: properties[0],
            [billTo.name]: billToAddress,
            [customerBillingAddress.name]: billToAddress,
            properties: properties
          };
          //custom field need to change when customer change
          queryResult.customFieldValueChange &&
            queryResult.customFieldValueChange({ ...form.values, ...values });
          this.setState({ ...values });
          form.setValues({ ...form.values, ...values });
        } else {
          this.props.snackbarOn('error', 'Customer has no property,please create property first');
        }
      }
      this.setState({ isProcessing: false });
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn(
        'error',
        'Unable to retrieve customer property details, please try again later',
        error
      );
      this.setState({ isProcessing: false });
    }
  };

  getCustomerDetails = async customer => {
    try {
      const { data } = await this.CustomerService.getCustomerById(customer.id);
      if (data && data.getCustomerById) {
        return { ...data.getCustomerById };
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn(
        'error',
        'Unable to fetch customer info, please try again later',
        error
      );
    }
    return null;
  };

  getPropertyAddress = async propertyId => {
    let propAddress = '';
    try {
      const { data } = await this.CustomerPropertyService.getCustomerPropertyInfoById(propertyId);
      if (
        data &&
        data.getCustomerPropertyById &&
        data.getCustomerPropertyById.companyAddresses &&
        data.getCustomerPropertyById.companyAddresses.items
      ) {
        data.getCustomerPropertyById.companyAddresses.items.forEach(address => {
          if (address.addressType === 'propertyAddress') {
            propAddress = getCombinedAddress(address);
          }
        });
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn(
        'error',
        'Unable to retrieve customer property address, please try again later',
        error
      );
    }
    return propAddress;
  };

  setValues = async (name, propertyId) => {
    const { form, specialbehaviour } = this.props;
    const { customer, propertyAddress, billTo } = specialbehaviour;
    let propAddress = '';
    propAddress = await this.getPropertyAddress(propertyId);
    if (propertyId) {
      const values = {
        [customer.name]: this.state[customer.name] || form.values[customer.name],
        [name]: propertyId,
        [propertyAddress.name]: propAddress,
        [billTo.name]: this.state[billTo.name] || form.values[billTo.name],
        properties: [...this.state.properties]
      };
      await this.setState({ ...values });
      await form.setValues({ ...form.values, ...values });
    }
  };

  render() {
    const { field, form, specialbehaviour, user } = this.props;
    const { customer, property, propertyAddress, queryResult } = specialbehaviour;
    const { properties, isProcessing } = this.state;

    const customerField = { ...field };
    customerField.value = this.state[customer.name] || form.values[customer.name];
    customerField.name = customer.name;
    const customerProps = {
      ...this.props,
      ...customer,
      specialbehaviour: customer.specialBehaviour,
      field: { ...customerField },
      label: labels[customer.label][user.locale]
    };

    const propertyField = { ...field };
    propertyField.value = this.state[property.name] || form.values[property.name];
    propertyField.name = property.name;
    queryResult[property.specialBehaviour.queryPath] =
      properties || queryResult[property.specialBehaviour.queryPath];
    property.specialBehaviour['queryResult'] = queryResult;
    const propertyProps = {
      ...this.props,
      ...property,
      specialbehaviour: property.specialBehaviour,
      field: { ...propertyField },
      label: labels[property.label][user.locale]
    };

    const propertyAddressField = { ...field };
    propertyAddressField.value =
      this.state[propertyAddress.name] || form.values[propertyAddress.name];
    const propertyAddressProps = {
      ...this.props,
      ...propertyAddress,
      specialbehaviour: propertyAddress.specialBehaviour,
      field: { ...propertyAddressField },
      label: labels[propertyAddress.label][user.locale]
    };

    return (
      <>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box position="relative">
              {isProcessing && (
                <Box position="absolute" zIndex="modal" right="45%" top="20%">
                  <CircularProgress size={20} thickness={5} disableShrink />
                </Box>
              )}
              <AlgoliaSearch
                {...customerProps}
                getDependentFieldData={this.getDependentFieldData}
                isDisabled={isProcessing}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Dropdown {...propertyProps} setValues={this.setValues} />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Label {...propertyAddressProps} />
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  snackbarOn: (mode, message, errorLog) => dispatch(snackbarOn(mode, message, errorLog))
});

const QuoteCustomerAndPropertyAndLabel = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteCustomerAndPropertyAndAddress);

export default QuoteCustomerAndPropertyAndLabel;
