export const isMobileDevice = () => {
  const nav = window.navigator;
  const ua = window.navigator.userAgent;
  // Safari on iPadOS doesn't report as 'mobile' when requesting desktop site
  const isSafariIOSDesktopMode =
    nav.platform !== undefined &&
    nav.platform === 'MacIntel' &&
    nav.maxTouchPoints !== undefined &&
    nav.maxTouchPoints > 1;
  return isSafariIOSDesktopMode || /Mobi|Tablet|Android|iPad|iPhone/.test(ua);
};

export const isModernBrowser = () => window.Promise !== undefined;

export const isIE = () => 'ActiveXObject' in window;

export const isPDFSupportedByBrowser = () => {
  // Checking to see how react-pdf determines support internally at
  // https://github.com/pipwerks/PDFObject/blob/master/pdfobject.js#L94
  const nav = window.navigator;
  const ua = window.navigator.userAgent;
  const supportsPdfMimeType = nav.mimeTypes['application/pdf'] !== undefined;
  const isMobile = isMobileDevice();
  const isModern = isModernBrowser();
  const isIEBrowser = isIE();

  // Firefox started shipping PDF.js in Firefox 19. If this is Firefox 19 or greater, assume PDF.js is available
  const isFirefoxWithPDFJS =
    !isMobile && /irefox/.test(ua) && ua.split('rv:').length > 1
      ? parseInt(ua.split('rv:')[1].split('.')[0], 10) > 18
      : false;
  const createAXO = type => {
    let ax;
    try {
      ax = new ActiveXObject(type);
    } catch (e) {
      ax = null; // ensure ax remains null
    }
    return ax;
  };

  // If either ActiveX support for "AcroPDF.PDF" or "PDF.PdfCtrl" are found, return true
  // Constructed as a method (not a prop) to avoid unneccesarry overhead -- will only be evaluated if needed
  const supportsPdfActiveX = () => {
    return !!(createAXO('AcroPDF.PDF') || createAXO('PDF.PdfCtrl'));
  };

  const supportsPDFs =
    // As of Sept 2020 no mobile browsers properly support PDF embeds
    !isMobile &&
    // MIME-less browsers mostly all support PDF rendering without plugins.
    (isModern ||
      // Modern versions of Firefox come with PDFJS
      isFirefoxWithPDFJS ||
      // Browsers that still support the original MIME type check
      supportsPdfMimeType ||
      // Pity the poor souls still using IE
      (isIEBrowser && supportsPdfActiveX()));

  return supportsPDFs;
};
