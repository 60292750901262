import gql from 'graphql-tag';

const serviceAgreement = gql`
  query serviceAgreement($companyId: String) {
    serviceAgreements(companyId: $companyId) {
      items {
        id
        companyId
        agreementName
        agreementNumber
        customerId
        billingCustomerId
        customerPoNumber
        status
        startDate
        endDate
        termPrice
        paymentTerms
        firstBillDate
        nextBillDate
        lastBilledDate
        customerPoNumber
      }
    }
  }
`;

export default serviceAgreement;
