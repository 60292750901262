import * as Yup from 'yup';
import { getCurrentDateInTimezone } from '@pm/components/utils';

const ONE_DAY_IN_SECONDS = 24 * 60 * 60;

export const generalInformationValidationSchema = ({ dailyReportDatesRef, minDate, timezoneRef }) =>
  Yup.object({
    number: Yup.string()
      .nullable()
      .required(),
    date: Yup.string()
      .test('has-date', 'Field is required', value => value)
      .test('check-date-range', 'Date selected is out of range', value => {
        const reportDate = parseInt(value, 10);
        const currentDate = getCurrentDateInTimezone(timezoneRef.current);
        return minDate <= reportDate && reportDate <= currentDate;
      })
      .test('valid-date', 'A daily report already exists for selected date!', value => {
        const reportDate = parseInt(value, 10);
        return !dailyReportDatesRef.current.some(date => {
          return reportDate <= date && date < reportDate + ONE_DAY_IN_SECONDS;
        });
      })
  });
