import { DialogContent, withStyles } from '@material-ui/core';

const BODialogContent = withStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(5)
  },
  dividers: {
    borderTop: 'none'
  }
}))(DialogContent);

export default BODialogContent;
