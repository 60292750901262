/* eslint-disable no-param-reassign */
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { connect, useSelector, useDispatch } from 'react-redux';
import { snackbarOn } from 'redux/actions/globalActions';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Labels from 'meta/labels';
import { Mode } from 'utils/constants';
import { roundCurrency, truncateString } from 'utils';
import { MUIForm } from '@buildhero/sergeant';
import ResponsiveTable from 'components/ResponsiveTable';
import { DefaultButton, SergeantModal } from 'components';
import CustomFieldWithLabel from '@pm/components/CustomFieldWithLabel';
import SearchBar from '@pm/components/APISearchComponents/SearchBar';
import { generateDefaultValidationSchema } from '@pm/components/formattingUtils';
import {
  retainageFields,
  retainageFormLayout
} from 'meta/ProjectManagement/ProjectSettings/scheduleOfValuesRetainageForm';
import simpleRowActionButtons from 'meta/ProjectManagement/global/simpleTableRowActionButton';
import {
  sovAddItemFormLayout,
  sovFields
} from 'meta/ProjectManagement/ProjectSettings/scheduleOfValuesAddItemForm';
import {
  sovRowMeta,
  tableTotalRow
} from 'meta/ProjectManagement/ProjectSettings/scheduleOfValuesTable';
import {
  getScheduleOfValuesByProjectId,
  scheduleOfValueChange,
  scheduleOfValueCreate
} from 'services/API/scheduleOfValue';
import {
  scheduleOfValueLineChange,
  scheduleOfValueLineCreate
} from 'services/API/scheduleOfValueLine';
import { productSearch, getProducts } from 'services/API/product';
import { changeOrderLineChange, getChangeOrderLineItems } from 'services/API/changeOrderLineItem';
import { projectChange, getProjectById } from 'services/API/project';
import { setProjectDetails } from '../../../../../redux/actions/projectManagementActions';
import { layout } from './coLayout';
import AssociatedProjectQuote from './AssociatedProjectQuote';

const CustomFieldWithLabelStyled = ({ field, options }) => {
  return (
    <CustomFieldWithLabel
      field={field}
      options={options}
      style={{ background: '#f0f0f0', color: '#999999', padding: '1px 8px' }}
    />
  );
};

const formatProjectForUpdate = R.pipe(
  R.evolve({
    ChangeOrder: R.map(co => ({
      ...co,
      number: R.replace('CO-', '', co.number)
    }))
  }),
  R.omit(['weather'])
);

const useStyles = makeStyles(theme => ({
  root: {
    margin: '31px 0px 20px 17px',
    '& .MuiTypography-caption': {
      color: theme.palette.grayscale(60),
      fontWeight: 400
    },
    '& .MuiTypography-body2': {
      marginRight: 12
    }
  },
  subTitle: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '-0.03em',
    marginBottom: 4
  },
  topMargin: {
    marginTop: 30
  },
  toolbarContainer: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  searchbarAndProgress: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  tableContainer: {
    marginTop: 20
  },
  searchBox: {
    width: 219
  }
}));

const SoVs = props => {
  const classes = useStyles();
  const { user, projectId, updateTotalContractValue } = props;
  const [addOriginalContractItemModal, setAddOriginalContractItemModal] = useState(false);
  const [editOriginalContractItemModal, setEditOriginalContractItemModal] = useState(false);
  const [editChangeOrderModal, setEditChangeOrderModal] = useState(false);
  const [selectedEditItem, setSelectedEditItem] = useState({});
  const [originalContractTableData, setOriginalContractTableData] = useState([]);
  const [approvedChangeOrdersTableData, setApprovedChangeOrdersTableData] = useState([]);
  const [sovData, setSovData] = useState({});
  const [glAccount, setGlAccount] = useState({});
  const [coProduct, setCOProduct] = useState({});
  const dispatch = useDispatch();
  const project = useSelector(state => state.pm?.project);

  useEffect(() => {
    if (project.changeOrderProduct) {
      setCOProduct({
        value: {
          id: project?.changeOrderProduct?.id,
          name: project?.changeOrderProduct?.name
        }
      });
    }
  }, [project]);

  const getFormattedRetainageData = data => {
    return {
      retainageProduct: project?.retainageProduct ?? null,
      retainage: data?.retainage || 0
    };
  };

  // Messy way of handling the GLAccount data, but it's static so it's not too important.
  // Each SOV line has a `glAccountId` and `glAccountName`, which is just inserted into the Product open first load
  // to ensure the field is populated.
  const getFormattedModalData = data => {
    return {
      id: data?.id,
      number: data?.number || '',
      contractValue: data?.contractValue || '',
      department: { id: data?.departmentId || '', tagName: data?.departmentName || '' } || '',
      description: data?.description || data?.scopeOfWork || '',
      Product: data?.Product
        ? { ...data.Product, itemGlGroup: { id: data?.glAccountId, name: data?.glAccountName } }
        : null
    };
  };

  const getOriginalContractTableData = (items, total) => {
    if (!items || items?.length === 0) return [];

    let totalValue = 0;
    const filteredItems = items
      .filter(data => !data.deletedDate)
      .sort((a, b) => (a.number > b.number ? 1 : -1))
      .map((item, index) => {
        const newItem = { ...item };
        newItem.number = `SOV-${`${index + 1}`.padStart(3, '0')}`;
        totalValue += Number(newItem.contractValue);
        newItem.name = newItem.Product?.name;
        return newItem;
      });
    updateTotalContractValue(totalValue);
    total.contractValue = totalValue;
    filteredItems.push(total);
    return [...filteredItems];
  };

  const uniqChangeOrderIds = R.pipe(R.map(R.path(['ChangeOrder', 'id'])), R.uniq);

  const getChangeOrderTableData = (items, total) => {
    if (!items || items?.length === 0) return [];
    const filteredItems = items.filter(data => data?.ChangeOrder?.status === 'Approved');

    let totalValue = 0;

    const finalItems = uniqChangeOrderIds(filteredItems).reduce((acc, cur) => {
      const itemNames = R.pipe(
        R.filter(data => data.ChangeOrder.id === cur),
        R.map(R.pathOr('', ['description'])),
        R.filter(name => name)
      )(filteredItems);

      const item = filteredItems.find(d => d.ChangeOrder.id === cur);

      const contractValue = +(item?.ChangeOrder?.amountApproved ?? 0);
      totalValue += contractValue;
      return [
        ...acc,
        {
          ...item,
          number: `${item?.ChangeOrder?.number}`,
          name: item.ChangeOrder?.subject,
          description: `Change Order ${item?.ChangeOrder?.number} | ${itemNames.join(', ')}`,
          lineDescription: item.description,
          contractValue,
          departmentName: item?.ProjectPhaseDepartment ? item.ProjectPhaseDepartment.tagName : ''
        }
      ];
    }, []);

    return [...finalItems, ...[{ ...total, contractValue: totalValue }]];
  };

  const getScheduleOfValuesData = async () => {
    const scheduleOfValueData = await getScheduleOfValuesByProjectId(projectId);
    let formattedChangeOrderTableData;
    getChangeOrderLineItems(projectId).then(coLineItems => {
      formattedChangeOrderTableData = getChangeOrderTableData(
        coLineItems,
        tableTotalRow,
        scheduleOfValueData[0]?.scheduleOfValueLine?.length || 0
      );
      setApprovedChangeOrdersTableData(formattedChangeOrderTableData);
    });

    if (isEmpty(scheduleOfValueData[0])) {
      const payload = {
        retainage: 0,
        projectId
      };
      const result = await scheduleOfValueCreate(payload);
      setSovData(result);
    } else {
      setSovData(scheduleOfValueData[0]);
      const formattedOriginalContractTableData = getOriginalContractTableData(
        scheduleOfValueData[0]?.scheduleOfValueLine,
        tableTotalRow
      );
      setOriginalContractTableData(formattedOriginalContractTableData);
    }
  };

  useEffect(() => {
    getScheduleOfValuesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOriginalContractModal = async (item, stopLoading, type) => {
    const payload = {
      scheduleOfValueId: sovData.id || '',
      productId: item?.Product?.id || undefined,
      contractValue: roundCurrency(item.contractValue) || 0,
      glAccountId: glAccount?.id || '',
      glAccountName: glAccount?.name || '',
      departmentId: item.department?.id || '',
      departmentName: item.department?.tagName || '',
      description: truncateString(item.description, 250) || ''
    };

    if (type === 'add') {
      await scheduleOfValueLineCreate(payload);
      props.snackbarOn('success', 'Successfully Posted');
    } else if (type === 'edit') {
      await scheduleOfValueLineChange(item.id, payload);
      props.snackbarOn('success', 'Successfully Updated');
    }
    getScheduleOfValuesData();
    stopLoading();
  };

  const handleChangeRetainage = ({ form, currentValue }) => {
    let newRetainage = parseInt(currentValue, 10);
    if (newRetainage < 0) {
      newRetainage = 0;
    } else if (newRetainage > 100) {
      newRetainage = 100;
    }
    form.setFieldValue('retainage', newRetainage);

    const payload = {
      retainage: Number.isNaN(newRetainage) ? 0 : Number(newRetainage)
    };

    scheduleOfValueChange(sovData.id, payload);
  };

  const [confirmDelete, setConfirmDelete] = useState({
    confirmAction: () => {},
    confirmDialog: false,
    confirmTitle: ''
  });

  const handleCancelConfirmation = () =>
    setConfirmDelete({ confirmAction: () => {}, confirmDialog: false, confirmTitle: '' });

  const deleteSoVAction = async (record, stopLoading) => {
    const payload = {
      deletedDate: moment().unix()
    };
    await scheduleOfValueLineChange(record.id, payload);
    props.snackbarOn('success', 'Successfully Deleted');
    getScheduleOfValuesData();
    setConfirmDelete({ confirmAction: () => {}, confirmDialog: false, confirmTitle: '' });
    stopLoading();
  };

  const handleOriginalContactRowActions = async (actionType, record) => {
    if (!record.number || record.number === '-') return;

    const selectedItem = originalContractTableData.filter(line => line.number === record.number)[0];

    if (actionType === 'remove') {
      setConfirmDelete({
        confirmAction: (data, stopLoading) => deleteSoVAction(record, stopLoading),
        confirmDialog: true,
        confirmTitle: `Delete Schedule of Value ${record.number}`
      });
    } else if (actionType === 'edit') {
      setSelectedEditItem(getFormattedModalData(selectedItem));
      setEditOriginalContractItemModal(true);
    }
  };

  const updateProject = async updatedProject => {
    await projectChange(projectId, updatedProject);
    // reload so that complete object for changeOrderProduct or retainageProduct is loaded
    // this shouldn't be necessary, but despite changing PM backend it seems to be needed
    const refreshed = await getProjectById(projectId);
    dispatch(setProjectDetails(refreshed));
  };

  const handleCOProductSelection = async selection => {
    if (!project?.id || !selection?.id || project?.changeOrderProductId === selection?.id) {
      return;
    }

    setCOProduct({
      value: {
        id: selection?.id || project?.changeOrderProduct?.id,
        name: selection?.name || project?.changeOrderProduct?.name
      }
    });

    const newProj = R.pipe(
      formatProjectForUpdate,
      R.omit(['changeOrderProduct']),
      R.mergeLeft({ changeOrderProductId: selection?.id ?? null })
    )(project);

    updateProject(newProj);
  };

  const handleRetainageProductSelection = async selection => {
    if (!project?.id || !selection?.id || project?.retainageProductId === selection?.id) {
      return;
    }

    const newProj = R.pipe(
      formatProjectForUpdate,
      R.omit(['retainageProduct']),
      R.mergeLeft({ retainageProductId: selection?.id ?? null })
    )(project);

    updateProject(newProj);
  };

  const phaseSearchBar = ({ options, field, form }) => {
    return <SearchBar options={options} field={field} form={form} />;
  };

  const depSearchBar = ({ options, field, form }) => {
    return <SearchBar options={options} field={field} form={form} />;
  };

  const formatChangeOrderLineForSave = line => {
    let accountName = '';
    if (line?.LedgerAccount) {
      accountName = line?.LedgerAccount;
    } else if (line?.glAccountName) {
      accountName = line?.glAccountName?.name ? line?.glAccountName.name : line?.glAccountName;
    }

    return {
      id: line?.id,
      cost: line?.cost || 0,
      projectCostCodeId: line.ProjectCostCode?.id || undefined,
      description: line?.lineDescription ? line?.lineDescription : '',
      notes: line?.notes,
      sellPrice: line?.sellPrice,
      quantity: Number(line?.quantity ?? 0),
      projectPhaseId: line?.ProjectPhase?.id,
      taxable: line?.taxable || 0,
      phaseDepartmentId: line?.ProjectPhaseDepartment?.id || undefined,
      projectId,
      ProjectPhase: line?.ProjectPhase,
      ProjectPhaseDepartment: line?.ProjectPhaseDepartment,
      ProjectCostCode: line?.ProjectCostCode,
      ChangeOrder: line?.ChangeOrder,
      glAccountName: accountName,
      glAccountId: line?.glAccount ? line?.glAccount.id : line?.glAccountId || ''
    };
  };

  return (
    <div className={classes.root}>
      {/* Original Contract */}
      <Typography className={classes.subTitle}>Original Contract</Typography>
      <AssociatedProjectQuote locale={user.locale} />
      <div className={classes.toolbarContainer}>
        <div className={classes.searchbarAndProgress}>
          <MUIForm
            configuration={retainageFormLayout({
              handleChangeRetainage,
              handleRetainageProductSelection
            })}
            data={getFormattedRetainageData(sovData)}
            validationSchema={generateDefaultValidationSchema(retainageFields)}
            customComponents={{ SearchBar }}
          />
        </div>
        <div>
          <DefaultButton
            label={Labels.addSovItem[user.locale]}
            variant="containedPrimary"
            style={{ height: 30, fontSize: 12 }}
            key="SoVOriginalContractAddItemBtn"
            onClick={() => {
              setSelectedEditItem(getFormattedModalData(null));
              setAddOriginalContractItemModal(true);
            }}
            buttonProps={{ startIcon: <AddCircleOutlineIcon style={{ fontSize: 14 }} /> }}
          />
        </div>
      </div>
      <div className={classes.tableContainer}>
        <ResponsiveTable
          rowMetadata={sovRowMeta}
          data={originalContractTableData}
          noDataMsg="No Original Contract Items"
          tableName="SoVOriginalContract"
          disableFilter
          rowActionButtons={simpleRowActionButtons}
          rowActions={handleOriginalContactRowActions}
          defaults={{
            sortBy: 'number',
            sortOrder: 'asc'
          }}
        />
      </div>

      {/* Approved Change Orders */}
      <Typography className={classNames(classes.subTitle, classes.topMargin)}>
        Approved Change Orders
      </Typography>
      <div className={classes.toolbarContainer}>
        <div className={classes.searchbarAndProgress}>
          <SearchBar
            key="productSearch"
            className={classes.searchBox}
            field={coProduct}
            options={{
              label: 'Item Type',
              placeholder: 'Search Item Types',
              searchFunction: productSearch,
              emptySearchFunction: getProducts,
              resultFormatFunction: result => result?.name?.trim(),
              hideOptions: false,
              useId: false,
              variant: 'standard',
              color: 'secondary',
              clearOnSelect: false
            }}
            onSelectionChange={handleCOProductSelection}
          />
        </div>
      </div>
      <div className={classes.tableContainer}>
        <ResponsiveTable
          rowMetadata={sovRowMeta}
          data={approvedChangeOrdersTableData}
          noDataMsg="No Approved Change Orders"
          tableName="SoVApprovedChangeOrders"
          disableFilter
          defaults={{
            sortBy: 'number',
            sortOrder: 'asc'
          }}
        />
      </div>

      {/* Modals */}
      <SergeantModal
        open={addOriginalContractItemModal}
        title={Labels.addSovItem[user.locale]}
        customPrimaryButtonLabel={Labels.addSovItem[user.locale]}
        data={selectedEditItem}
        dataType="Item"
        mode={Mode.EDIT}
        layout={sovAddItemFormLayout(!!user.accountingApp)}
        handlePrimaryAction={(item, stopLoading) => {
          handleOriginalContractModal(item, stopLoading, 'add');
          setAddOriginalContractItemModal(false);
        }}
        handleClose={() => setAddOriginalContractItemModal(false)}
        validationSchema={sovFields(!!user.accountingApp)}
        customComponents={{ SearchBar, CustomFieldWithLabelStyled }}
      />
      <SergeantModal
        open={editOriginalContractItemModal}
        title={Labels.editSovItem[user.locale]}
        customPrimaryButtonLabel={Labels.saveChangesButtonText[user.locale]}
        data={selectedEditItem}
        dataType="Item"
        mode={Mode.EDIT}
        layout={sovAddItemFormLayout(!!user.accountingApp)}
        handlePrimaryAction={(item, stopLoading) => {
          handleOriginalContractModal(item, stopLoading, 'edit');
          setEditOriginalContractItemModal(false);
        }}
        validationSchema={sovFields(!!user.accountingApp)}
        handleClose={() => setEditOriginalContractItemModal(false)}
        customComponents={{ SearchBar, CustomFieldWithLabelStyled }}
      />
      <SergeantModal
        open={editChangeOrderModal}
        title={Labels.editSovItem[user.locale]}
        customPrimaryButtonLabel={Labels.saveChangesButtonText[user.locale]}
        data={formatChangeOrderLineForSave(selectedEditItem)}
        dataType="Item"
        mode={Mode.EDIT}
        layout={layout(projectId)}
        handlePrimaryAction={(item, stopLoading) => {
          changeOrderLineChange(item.id, formatChangeOrderLineForSave(item)).then(() => {
            getScheduleOfValuesData();
            stopLoading();
          });
          setEditChangeOrderModal(false);
        }}
        handleClose={() => setEditChangeOrderModal(false)}
        customComponents={{
          phaseSearchBar,
          depSearchBar,
          SearchBar
        }}
      />
      <SergeantModal
        open={confirmDelete.confirmDialog}
        title={confirmDelete.confirmTitle}
        customPrimaryButtonLabel="Delete"
        dataType="Item"
        mode="delete"
        customComponents={{}}
        handlePrimaryAction={confirmDelete.confirmAction}
        handleClose={handleCancelConfirmation}
        alignCloseRight
      />
    </div>
  );
};

CustomFieldWithLabelStyled.propTypes = {
  field: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

SoVs.propTypes = {
  user: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  snackbarOn: PropTypes.func.isRequired,
  updateTotalContractValue: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});
const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});
const ReduxConnectedSoVs = connect(mapStateToProps, mapDispatcherToProps)(SoVs);
export default ReduxConnectedSoVs;
