import React from 'react';
import { func, array, object } from 'prop-types';
import { getState } from 'redux/store';
import { useFormService } from '@dispatch/hooks';
import { EventEntities } from '@dispatch/Dispatch.constants';
import { prepareNonVisitData } from '@dispatch/Dispatch.utils';
import { EventDataPropTypes } from '@dispatch/Dispatch.props';
import BillableEventForm from '../BillableEventForm';
import EventBadge from '../EventBadge';
import DrawerLayout from '../DrawerLayout';
import VisitDetailsHeader from '../VisitDetailsHeader';
import VisitSearchHeader from './components/VisitSearchHeader';

const CreateBillableEvent = ({
  eventData,
  closeDrawer,
  addNonVisitTuple,
  jobDetailsResponse,
  visit,
  setVisit
}) => {
  const { tenantId, tenantCompanyId } = getState().user;
  const nonVisitEventData = prepareNonVisitData(eventData);
  const { isDirty, onCreateService, onDirtyChange, resetForm, submitForm } = useFormService();
  const { data: job, loading: fetchingJobDetails } = jobDetailsResponse;
  const [triggerMutation, mutationResponse] = addNonVisitTuple;
  const { loading: submittingBillableEvent } = mutationResponse;
  const disabled = !visit || fetchingJobDetails || submittingBillableEvent;
  const loading = fetchingJobDetails || submittingBillableEvent;

  const handleFormReset = () => {
    setVisit(null);
    resetForm();
  };

  const createBillableNonVisitEvent = data => {
    triggerMutation({
      tenantId,
      tenantCompanyId,
      data,
      visit,
      techId: eventData.primaryTechId
    });
    closeDrawer();
    handleFormReset();
  };

  const handleVisitSelect = selectedVisit => {
    setVisit(selectedVisit);
  };

  const renderHeader = () => {
    return (
      <>
        <EventBadge eventType={EventEntities.BILLABLE_EVENT.value.clientValue} />
        {visit ? (
          <VisitDetailsHeader visit={visit} job={job} loading={loading} />
        ) : (
          <VisitSearchHeader onVisitSelected={handleVisitSelect} />
        )}
      </>
    );
  };

  const renderBody = () => (
    <BillableEventForm
      onDirtyChange={onDirtyChange}
      onComplete={createBillableNonVisitEvent}
      onCreateService={onCreateService}
      eventData={nonVisitEventData}
      visit={visit}
      job={job}
      visitDataLoading={fetchingJobDetails}
      disabled={disabled}
    />
  );

  return (
    <DrawerLayout
      renderHeader={renderHeader}
      renderBody={renderBody}
      onResetForm={handleFormReset}
      onSubmitForm={submitForm}
      isDirty={!!visit || isDirty}
      loading={loading}
      disabledSave={disabled}
      disabledClose={loading}
    />
  );
};

CreateBillableEvent.propTypes = {
  eventData: EventDataPropTypes.isRequired,
  closeDrawer: func.isRequired,
  addNonVisitTuple: array.isRequired,
  jobDetailsResponse: object.isRequired,
  visit: object.isRequired,
  setVisit: func.isRequired
};

export default CreateBillableEvent;
