export const productsRowsMeta = [
  {
    id: 'name',
    filterKey: 'Product.name',
    filterType: 'string',
    numeric: false,
    label: 'Name'
  },
  {
    id: 'description',
    filterKey: 'Product.description',
    filterType: 'string',
    numeric: false,
    label: 'Description'
  },
  {
    id: 'isActive',
    type: 'boolean',
    filterKey: 'Product.isActive',
    filterType: 'boolean',
    options: [
      { label: 'Active', value: true },
      { label: 'Deactivated', value: false }
    ],
    numeric: false,
    label: 'Active'
  },
  {
    id: 'unitCost',
    type: 'currency',
    disableFilter: true,
    filterKey: 'Product.unitCost',
    filterType: 'number',
    numeric: true,
    label: 'Unit cost'
  },
  {
    id: 'taxable',
    type: 'boolean',
    filterKey: 'Product.isActive',
    filterType: 'boolean',
    options: [
      { label: 'Active', value: true },
      { label: 'Deactivated', value: false }
    ],
    numeric: false,
    label: 'Taxable'
  },
  {
    id: 'quantity',
    type: 'number',
    disableFilter: true,
    filterKey: 'Product.quantity',
    filterType: 'number',
    numeric: true,
    label: 'Quantity'
  },
  {
    id: 'unitOfMeasure',
    type: 'text',
    disableFilter: true,
    filterKey: 'UnitOfMeasure.name',
    filterType: 'string',
    numeric: false,
    label: 'UOM'
  }
];

export const productsRowsForTrucksMeta = [
  {
    id: 'name',
    filterKey: 'Product.name',
    filterType: 'string',
    numeric: false,
    label: 'Name'
  },
  {
    id: 'description',
    filterKey: 'Product.description',
    filterType: 'string',
    numeric: false,
    label: 'Description'
  },
  {
    id: 'unitOfMeasure',
    type: 'text',
    disableFilter: true,
    filterKey: 'UnitOfMeasure.name',
    filterType: 'string',
    numeric: false,
    label: 'UOM'
  },
  {
    id: 'minQuantity',
    type: 'number',
    disableFilter: true,
    filterKey: 'Product.minQuantity',
    filterType: 'number',
    numeric: true,
    label: 'Minimum Qty'
  },
  {
    id: 'maxQuantity',
    type: 'number',
    disableFilter: true,
    filterKey: 'Product.maxQuantity',
    filterType: 'number',
    numeric: true,
    label: 'Maximum Qty'
  }
];

export default productsRowsMeta;
