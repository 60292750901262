/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getLinesByPurchaseOrder(purchaseOrderId) {
  return await instance
    .get(`purchaseorderlines/bypurchaseorder/${purchaseOrderId}`, {
      params: { include: '*' }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getPOLinesByProjectPhase(projectPhaseId) {
  return await instance
    .get(`purchaseorderlines/byprojectphase/${projectPhaseId}`, {
      params: { include: '*' }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getPurchaseOrderLines() {
  return await instance
    .get(`purchaseorderlines`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function purchaseOrderLineCreate(purchaseOrderLine) {
  return await instance
    .post('purchaseorderlines/', purchaseOrderLine)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function purchaseOrderLineChange(id, purchaseOrderLine) {
  return await instance
    .put(`purchaseorderlines/${id}`, purchaseOrderLine)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function purchaseOrderLineSearch(term, searchColumns) {
  return await instance
    .post(`PurchaseOrderLine/search/${term}`, { searchColumns: searchColumns || ['itemName'] })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function purchaseOrderLineDelete(id) {
  return await instance
    .delete(`purchaseorderlines/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

// Params is essentially a "where" clause. Example:
// { costCodeId: 'asdasd89234', revenueTypeId: 'asdsa4332' }
export async function getPurchaseOrderLinesByProject(projectId, params = {}) {
  return await instance
    .get(`purchaseorderlines/byproject/${projectId}`, { params })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
