import gql from 'graphql-tag';
import { getState } from 'redux/store';
import { useSubscription } from '@apollo/client';
import { manDayFragment } from '@dispatch/fragments/manDayFragment';

export const MAN_DAY_SUBSCRIPTION = gql`
  subscription manDayUpdateNotification($partitionKey: String!) {
    manDayUpdateNotification(partitionKey: $partitionKey) {
      ${manDayFragment}
    }
  }
`;

export const useManDaySubscription = () => {
  const { tenantId } = getState().user;
  return useSubscription(MAN_DAY_SUBSCRIPTION, {
    variables: { partitionKey: tenantId }
  });
};
