import gql from 'graphql-tag';

const SyncPaymentTypes = gql`
  mutation SyncPaymentTypes($partitionKey: String!, $data: SyncPaymentTypesInput!) {
    syncPaymentTypes(partitionKey: $partitionKey, data: $data) {
      id
      entityType
    }
  }
`;

export default SyncPaymentTypes;
