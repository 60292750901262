import gql from 'graphql-tag';

const getForm = gql`
  query getForm($partitionKey: String!, $sortKey: String!) {
    getForm(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      name
      description
      viewType
      latestPublishedFormDefinitionSortKey
      latestPublishedFormDefinition(entityConnection: "latestPublishedFormDefinition") {
        formDefinitionJson
      }
      latestDraftFormDefinitionSortKey
      latestDraftFormDefinition(entityConnection: "latestDraftFormDefinition") {
        formDefinitionJson
      }
      associatedEntityType
      hierarchy
      tenantCompanyId
      version
    }
  }
`;

export default getForm;
