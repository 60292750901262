import gql from 'graphql-tag';

const softDeleteTruck = gql`
  mutation bulkDeleteTruckInventories($data: BulkDeleteTruckInventoriesInput!) {
    bulkDeleteTruckInventories(data: $data) {
      id
    }
  }
`;

export default softDeleteTruck;
