import { useExtendedFetchForPMAPI as useExtendedFetch } from 'customHooks/useExtendedFetch';

export const useGetAllProject = (userId, options) => {
  return useExtendedFetch(
    {
      url: `projects/user/${userId}`,
      params: { include: ['gcProjectManager', 'projectManager', 'ProjectSubtype'] }
    },
    options
  );
};

export const useGetProjectById = (projectId, options) => {
  return useExtendedFetch(
    {
      url: `projects/${projectId}`,
      params: { include: '*' }
    },
    options
  );
};

export const useGetProjectKPIValues = (projectId, options) => {
  return useExtendedFetch(
    {
      url: `projects/getkpis/${projectId}`
    },
    options
  );
};

export const useGetFinanceByDepartments = (projectId, departments, options) => {
  return useExtendedFetch(
    {
      url: `projects/calculatefinance/${projectId}/bydepartments`,
      method: 'POST',
      data: { departments }
    },
    options
  );
};

export const useGetProjectLaborReport = (projectId, options = {}) => {
  return useExtendedFetch(
    {
      url: `projects/laborreport/${projectId}`
    },
    options
  );
};

export const useGetProjectFinance = (projectId, options = {}) => {
  return useExtendedFetch(
    {
      url: `projects/calculatefinance/${projectId}`
    },
    options
  );
};
