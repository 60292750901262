import gql from 'graphql-tag';

const getCustomerProperty = gql`
  query getCustomerProperty($partitionKey: String!, $sortKey: String!) {
    getCustomerProperty(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      parentId
      parentSortKey
      companyName
      customerPropertyTypeValue
      customerPropertyTypeSortKey
      status
      email
      phonePrimary
      amountNotToExceed
      sameAddress
      version
      accountingRefId
      billTo
      companyAddresses(entityConnection: "CompanyAddress") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          addressLine1
          addressLine2
          state
          city
          zipcode
          addressType
          entityType
          sortKey
          billTo
        }
      }
      notes(entityConnection: "Note") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          subject
          note
          createdBy
          lastUpdatedDate
          lastUpdatedDateTime
          lastUpdatedBy
          version
        }
      }
      jobs: jobsView(entityConnection: "Job") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          customIdentifier
          jobNumber
          jobTypeName
          jobTypeInternal
          priority
          status
          customerName
          customerPropertyName
        }
      }
      parentEntity {
        ... on Customer {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          customerName
          accountNumber
          companyAddresses(entityConnection: "CompanyAddress") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              addressLine1
              addressLine2
              state
              city
              zipcode
              addressType
              entityType
              sortKey
              billTo
            }
          }
          customerReps(entityConnection: "CustomerRep") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              name
              firstName
              lastName
              email
              contactRole
              cellPhone
              landlinePhone
              isSmsOptOut
              bestContact
              emailInvoice
            }
          }
        }
      }
      customerReps(entityConnection: "CustomerRep") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          invertedSortKey
          hierarchy
          entityType
          mappedEntity {
            ... on CustomerRep {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              name
              firstName
              lastName
              email
              cellPhone
              landlinePhone
              isSmsOptOut
              bestContact
              emailInvoice
              contactRole
              notes(entityConnection: "Note") {
                items {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  subject
                  note
                  createdBy
                  lastUpdatedDate
                  lastUpdatedDateTime
                  lastUpdatedBy
                }
              }
            }
          }
        }
      }
      tenantReps(entityConnection: "Employee") {
        items {
          id
          mappedEntityId
          mappedEntityType
          systemEntityId
          systemEntityType
          hierarchy
          partitionKey
          sortKey
          invertedSortKey
          mappedEntity {
            ... on Employee {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              firstName
              lastName
              name
              status
              notes(entityConnection: "Note") {
                items {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  subject
                  note
                  createdBy
                  lastUpdatedDate
                  lastUpdatedDateTime
                  lastUpdatedBy
                }
              }
              appRoles(entityConnection: "AppRole") {
                items {
                  id
                  mappedEntity {
                    ... on AppRole {
                      id
                      tagName
                    }
                  }
                }
              }
              createdDateTime
            }
          }
        }
      }
      propertyAssets(entityConnection: "PropertyAsset") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          assetName
          make
          version
          modelNumber
          serialNo
          installDate
          location
          propertyZone
          lastUpdatedDateTime
          # can remove notes - leaving for backwards compatibility and mobile
          notes(entityConnection: "Note") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              hierarchy
              entityType
              subject
              note
              version
              createdBy
              lastUpdatedDate
              lastUpdatedDateTime
              lastUpdatedBy
            }
          }
        }
      }
      attachments(entityConnection: "Attachment") {
        items {
          fileName
          customFileName
          fileUrl
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          description
          comment
          createdDate
          createdDateTime
          createdBy
        }
      }
    }
  }
`;
export default getCustomerProperty;
