import { noop } from 'lodash';
import React from 'react';
import { bool, func, object } from 'prop-types';
import { convertToCurrencyString } from 'utils';
import { Checkbox } from '@material-ui/core';
import { Typography } from '@buildhero/sergeant';
import { useStyles } from './QuoteItem.styles';

const QuoteItem = ({ quote, checked, onChange, hideCheckbox }) => {
  const styles = useStyles();
  const amount = convertToCurrencyString(quote.amount);
  return (
    <div css={styles.rowContainer}>
      <div css={styles.labelContainer}>
        {!hideCheckbox && (
          <Checkbox css={styles.checkbox} checked={checked} onChange={() => onChange(quote.id)} />
        )}
        <Typography>{quote.label}</Typography>
      </div>
      <Typography>{amount}</Typography>
    </div>
  );
};

QuoteItem.propTypes = {
  quote: object.isRequired,
  checked: bool,
  onChange: func,
  hideCheckbox: bool
};

QuoteItem.defaultProps = {
  hideCheckbox: false,
  checked: false,
  onChange: noop
};

export default QuoteItem;
