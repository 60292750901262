export const SectionHeaderProperties = {
  toolLabel: 'Section Header',
  toolIcon: 'Title',
  label: 'Section Header',
  value: 'Example Header',
  fullWidth: true,
  alignment: 'left',
  editFormLayout: 'SectionHeaderPropertiesLayout',
  readOnly: true,
  controlType: 'SectionHeader',
  viewControlType: 'SectionHeader'
};

export const SectionHeaderPropertiesLayout = [
  {
    fields: {
      value: {
        default: 'value',
        edit: 'value'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'value',
                    options: {
                      label: 'Section Header Content'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'RadioButtonsGroup',
                    source: 'alignment',
                    options: {
                      btnDirection: 'Horizontal',
                      label: 'Alignment',
                      inputOptions: 'Left, Center, Right'
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  {
    value: ''
  }
];

export const TextBlockProperties = {
  toolLabel: 'Text Block',
  toolIcon: 'FormatAlignJustify',
  label: 'Text Block',
  value: '',
  placeholder: '',
  fullWidth: true,
  alignment: 'left',
  editFormLayout: 'TextBlockPropertiesLayout',
  readOnly: true,
  controlType: 'RichContentViewer',
  viewControlType: 'RichContentViewer'
};

export const TextBlockPropertiesLayout = [
  {
    fields: {
      value: {
        default: 'value',
        edit: 'value'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'RichContentEditor',
                    source: 'value',
                    options: {
                      label: 'Label',
                      isEdit: true
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  {
    value: ''
  }
];

export const ImageProperties = {
  toolLabel: 'Image',
  toolIcon: 'Image',
  label: 'Image Header',
  source: [],
  fullWidth: true,
  alignment: 'left',
  editFormLayout: 'ImagePropertiesLayout',
  readOnly: true,
  controlType: 'ImageControl',
  viewControlType: 'ImageControl'
};

export const ImagePropertiesLayout = [
  {
    fields: {
      source: {
        default: 'source',
        edit: 'source'
      },
      caption: {
        default: 'caption',
        edit: 'caption'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'ImageUpload',
                    source: 'source',
                    options: {
                      label: 'Image',
                      accept: '.jpg,.jpeg,.png',
                      multiple: false
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'TextInput',
                    source: 'caption',
                    options: {
                      label: 'Caption Text'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'RadioButtonsGroup',
                    source: 'alignment',
                    options: {
                      btnDirection: 'Horizontal',
                      label: 'Alignment',
                      inputOptions: 'Left, Center, Right'
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  {
    source: '',
    caption: ''
  }
];
