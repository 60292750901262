import { pick } from 'lodash';
import removeObjProperties from 'utils/removeObjProperties';
import { unUsedEmployeeFields, contactAddressesFields } from './constants';

export const getSkillDetails = (items = []) => {
  return items.map(item => {
    const departmentIds = item.departments?.items?.map(department => department.mappedEntity.id);
    return {
      id: item.id,
      tagName: item.tagName,
      departmentIds
    };
  });
};

const getSelectedItem = (selectedList = [], items) =>
  selectedList.reduce((result, id) => {
    const match = items.find(item => item.value === id);
    if (match) result.push({ id: match?.value });
    return result;
  }, []);

const getEmployeeDetail = employee => {
  const { contactAddressId: id } = employee;
  const contactAddress = pick(employee, contactAddressesFields);
  const employeeInfo = removeObjProperties(employee, [
    ...contactAddressesFields,
    'contactAddressesId'
  ]);
  return { ...employeeInfo, contactAddresses: [{ ...contactAddress, id }] };
};

export const getFormCompleteData = ({ employee, appSkills, appRoles, appDepartments }) => {
  const roles = getSelectedItem([employee.appRole], appRoles);
  const departments = getSelectedItem(employee.departments, appDepartments);
  const skills = getSelectedItem(employee.skills, appSkills);
  const employeeInfo = getEmployeeDetail(employee);
  const formDetail = removeObjProperties(
    { ...employeeInfo, appRoles: roles, departments, skills },
    unUsedEmployeeFields
  );
  return formDetail;
};

export const getLayout = ({ user, isViewMode }) => {
  if (isViewMode) return 'view';
  if (user.cognitoRole === 'TenantAdmin') {
    return 'tenantAdmin';
  }

  return 'edit';
};

// initial flag set to true when referencing initially loaded selected departments
// initial flag set to false when referencing dynamically selected departments
export const filterSkillsOptions = (skillsList, departments, initial = false) => {
  if (!departments || !skillsList) return [];
  return skillsList.filter(skill => {
    let isFiltered = false;
    departments.forEach(department => {
      if (initial && skill.departmentIds?.includes(department)) {
        isFiltered = true;
      }
      if (!initial && skill.departmentIds?.includes(department.value)) {
        isFiltered = true;
      }
    });
    return isFiltered;
  });
};
