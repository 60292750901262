import React from 'react';

import { GOOGLE_MAPS_API_KEY } from 'utils/AppConstants';

const BASE_URL = 'https://maps.googleapis.com/maps/api/staticmap';

/**
 * Map view using the Google static map API. Renders a simple image that you
 * can't interact with.
 *
 * Refer to `components/MapView` for JS version of this component.
 */
const MapViewStatic = ({
  coordinates: { latitude, longitude },
  dimensions = { width: 100, height: 100 }
}) => {
  const paramsObj = {
    center: `${latitude},${longitude}`,
    markers: `size:small|scale:2|${latitude},${longitude}`,
    zoom: 12,
    scale: 2,
    size: `${dimensions.width ?? dimensions.height * 2}x${dimensions.height}`,
    key: GOOGLE_MAPS_API_KEY
  };
  const params = Object.entries(paramsObj).reduce(
    (s, [key, value]) => `${s ? `${s}&` : ''}${key}=${encodeURIComponent(value)}`,
    ''
  );

  const popupUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;

  return (
    <div style={dimensions}>
      <a href={popupUrl}>
        <img
          src={`${BASE_URL}?${params}`}
          alt=""
          style={{ objectFit: 'cover', width: dimensions.width ? dimensions.width : '100%' }}
          {...dimensions}
        />
      </a>
    </div>
  );
};

export default MapViewStatic;
