export const getStyles = theme => ({
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5)
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    height: 2,
    backgroundColor: theme.palette.grayscale(20)
  },
  greenCheckIcon: {
    color: theme.palette.support.green.dark,
    fontSize: 18,
    marginRight: theme.spacing(0.5)
  },
  redWarningIcon: {
    color: theme.palette.support.red.dark,
    fontSize: 18,
    marginRight: theme.spacing(0.5)
  }
});
