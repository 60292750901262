export const getAddress1 = addr => {
  const addressLine =
    addr?.addressLine1 && addr?.addressLine2
      ? `${addr?.addressLine1 || ''}, ${addr?.addressLine2 || ''},`
      : `${addr?.addressLine1 || ''},`;

  return addressLine?.length > 1 ? addressLine : '-';
};

export const getAddress2 = addr => {
  return `${addr?.city || ''}, ${addr?.state || ''} ${addr?.zipcode || ''}`;
};

export const getEmployeeAddress1 = companyAddress => {
  let address = '-';
  if (companyAddress) {
    address = companyAddress?.addressLine2
      ? `${companyAddress?.addressLine1 || ''}, ${companyAddress?.addressLine2 || ''},`
      : `${companyAddress?.addressLine1 || ''},`;
  }

  return address;
};

export const getEmployeeAddress2 = companyAddress => {
  let address = '-';
  if (companyAddress) {
    address = companyAddress?.addressLine2
      ? ` ${companyAddress?.city || ''}, ${companyAddress?.state || ''} ${companyAddress?.zipcode ||
          ''}`
      : ` ${companyAddress?.city || ''}, ${companyAddress?.state || ''} ${companyAddress?.zipcode ||
          ''}`;
  }

  return address;
};

export const getPayAppLineItemTotals = ({ tableData, payAppTotals }) => ({
  contractValue: tableData.reduce((sum, { contractValue = 0 }) => sum + contractValue, 0),
  originalContractValue: tableData.reduce(
    (sum, { contractValue = 0, entityType }) =>
      entityType === 'ScheduleOfValueLine' ? sum + contractValue : sum,
    0
  ),
  coContractValue: tableData.reduce(
    (sum, { contractValue = 0, entityType }) =>
      entityType === 'ChangeOrderLineItem' ? sum + contractValue : sum,
    0
  ),
  totalCompletedAndStoredToDate: tableData.reduce(
    (sum, lineItem) =>
      sum +
      (lineItem.totalCompletedAndStored || 0) +
      (lineItem.totals?.totalCompletedAndStored || 0),
    0
  ),
  workCompletedToDate: tableData.reduce(
    (sum, lineItem) => sum + (lineItem.workCompleted || 0) + (lineItem.totals?.workCompleted || 0),
    0
  ),
  materialsStoredToDate: tableData.reduce(
    (sum, lineItem) =>
      sum + (lineItem.materialsStored || 0) + (lineItem.totals?.materialsStored || 0),
    0
  ),
  totalCompletedAndStored: tableData.reduce(
    (sum, { totalCompletedAndStored = 0 }) => sum + totalCompletedAndStored,
    0
  ),
  workCompleted: tableData.reduce((sum, { workCompleted = 0 }) => sum + workCompleted, 0),
  materialsStored: tableData.reduce((sum, { materialsStored = 0 }) => sum + materialsStored, 0),
  invoiceAmount: payAppTotals.invoiceAmount,
  retainedAmount: payAppTotals.retainedAmount,
  prevAmountCompleted: tableData.reduce(
    (sum, lineItem) => sum + (lineItem.totals?.totalCompletedAndStored || 0),
    0
  )
});
