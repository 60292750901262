import { useState } from 'react';
import { useSubscription } from '@apollo/client';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import { EntityType } from 'utils/constants';

const RECEIPT_SUBSCRIPTION = gql`
  subscription mutationNotification(
    $partitionKey: String!
    $entityType: String
    $entityId: String
  ) {
    mutationNotification(
      partitionKey: $partitionKey
      entityType: $entityType
      entityId: $entityId
    ) {
      id
      sortKey
      entityType
      mutationType
      changeLog
    }
  }
`;

export const useReceiptSubscription = (tenantId, id) => {
  const [newStatus, setNewStatus] = useState(null);
  const [newSyncStatus, setNewSyncStatus] = useState(null);
  const [newSyncLog, setNewSyncLog] = useState(null);
  useSubscription(RECEIPT_SUBSCRIPTION, {
    fetchPolicy: 'no-cache',
    variables: {
      partitionKey: tenantId,
      entityType: EntityType.PURCHASE_ORDER_RECEIPT,
      entityId: id
    },
    onSubscriptionData: ({ subscriptionData }) => {
      const changeLog = JSON.parse(subscriptionData.data.mutationNotification.changeLog);
      const newData = changeLog?.reduce(
        (changes, change) =>
          // only update following field (for accounting integration)
          ['status', 'syncStatus', 'syncLog'].includes(change.field)
            ? { ...changes, [change.field]: change.new }
            : changes,
        {}
      );

      if (!isEmpty(newData) && (newData.status || newData.syncStatus || newData.syncLog)) {
        setNewStatus(newData.status);
        setNewSyncStatus(newData.syncStatus);
        setNewSyncLog(newData.syncLog);
      }
    }
  });

  return { newStatus, newSyncStatus, newSyncLog };
};
