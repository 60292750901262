import gql from 'graphql-tag';

const updateProductBundle = gql`
  mutation updateProductBundle($partitionKey: String!, $data: UpdateProductBundleInput!) {
    updateProductBundle(partitionKey: $partitionKey, data: $data) {
      id
      name
      description
      category
      subCategory
      type
      isActive
      createdDate
      version
    }
  }
`;

export default updateProductBundle;
