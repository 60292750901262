import React from 'react';

import { Button } from '@buildhero/sergeant';

import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import entityRouteMappings from 'meta/entityRouteMappings';
import Labels from 'meta/labels';
import { getJobCloseoutType } from 'scenes/JobCloseout/utils';
import Routes from 'scenes/Routes';

import { addressObjectToString, getBestContact, removeNullValues } from 'utils';
import { AppConstants, JobStatus, MaintenanceStatus } from 'utils/AppConstants';

import { MAINTENANCE_ACTION_BUTTONS, REOPEN_ACTION_BUTTON } from '../constant';

export const getHeaderProps = (locale, maintenanceNumber, additionalStatusLabels, jobData) => ({
  breadcrumbsArray: [
    { link: '', title: 'Operations' },
    { link: '/maintenance/list', title: Labels.maintenance[locale] }
  ],
  pageMapKey: 'maintenance',
  mode: 'view',
  title: `Maintenance: ${maintenanceNumber || ''}`,
  userLocale: locale,
  additionalStatusLabels,
  customButtons: jobData.closeoutReport && (
    <Link
      to={Routes.jobCloseout({ jobid: jobData.id, jobCloseoutType: getJobCloseoutType(jobData) })}
    >
      <Button type="secondary">Open Maintenance Report</Button>
    </Link>
  )
});

export const getRoute = (id, entityType) => {
  const baseRoute = entityRouteMappings[entityType];
  if (!baseRoute || !id) return undefined;
  return baseRoute + id;
};

export const getName = mulitpleNamesEntity => {
  const finalName = mulitpleNamesEntity?.name
    ? mulitpleNamesEntity?.name
    : `${mulitpleNamesEntity?.firstName || ''} ${mulitpleNamesEntity?.lastName || ''}`;
  return finalName.trim() === '' ? null : finalName;
};

export const getLocation = companyAddresses => {
  const addressItems = companyAddresses?.items || [];
  const foundAddress = addressItems.find(item => {
    const { addressLine1, addressLine2 } = item;
    if (addressLine1 || addressLine2) {
      return item;
    }
    return null;
  });
  const { latitude = 0, longitude = 0 } = foundAddress || {};
  return { latitude, longitude };
};

export const sortNotes = notes => {
  if (!Array.isArray(notes)) return notes;
  return notes.sort((a, b) => {
    if (a.sortOrder || b.sortOrder) return a.sortOrder - b.sortOrder;
    return a.lastUpdatedDate - b.lastUpdatedDate;
  });
};

export const formatMaintenanceDataForSidebarForm = (maintenanceData, mode = 'view') => {
  if (!maintenanceData) return {};

  const {
    customer,
    billingCustomer,
    customerPropertyName,
    customerProperty,
    customerRep
  } = maintenanceData;

  const customerName = customer?.customerName;
  const propertyAddressObject = customerProperty?.companyAddresses?.items?.find(
    address => address.addressType === 'propertyAddress'
  );
  const propertyAddress = addressObjectToString(propertyAddressObject);
  const customerRoute = getRoute(customer?.id, 'Customer');
  const billingCustomerRoute = getRoute(billingCustomer?.id, 'Customer');
  const propertyRoute = getRoute(customerProperty?.id, 'Property');
  const propertyInstructions = sortNotes(customerProperty?.notes?.items);
  const location = getLocation(customerProperty?.companyAddresses);

  return removeNullValues({
    customer: { label: customerName, path: customerRoute },
    billingCustomer: { label: billingCustomer?.customerName, path: billingCustomerRoute },
    billingCustomerId: mode === 'edit' ? billingCustomer?.id : undefined,
    property: { label: customerPropertyName, path: propertyRoute },
    propertyType: customerProperty?.customerPropertyTypeValue,
    propertyRepresentative: mode === 'view' ? getName(customerRep) : customerRep?.id,
    location,
    bestContactMethod: getBestContact(customerRep),
    propertyInstructions,
    address: propertyAddress,
    customerProvidedPONumber: maintenanceData?.customerProvidedPONumber
  });
};

export const formatServiceAgreementDetails = (maintenanceData = {}, companyTimeZone) => {
  const { dueDate, serviceAgreement, jobTypeName, totalBudgetedHours } = maintenanceData;
  const attachments = serviceAgreement?.attachments?.items;
  const agreementRoute = getRoute(serviceAgreement?.id, 'ServiceAgreement');
  const contract = attachments?.filter(item => item.type === 'Contract');
  const officeNotes = sortNotes(maintenanceData?.notes?.items || []);

  return {
    agreementName: { label: serviceAgreement?.agreementName, path: agreementRoute },
    agreementNumber: { label: serviceAgreement?.agreementNumber, path: agreementRoute },
    serviceDescription:
      maintenanceData?.issueDescription || maintenanceData?.maintenanceTemplate?.serviceDescription,
    dueDateFormatted: dueDate
      ? moment.tz(moment.unix(dueDate), companyTimeZone).format(AppConstants.DATE_FORMAT)
      : null,
    dueDate,
    contract: contract || '',
    totalBudgetedHours,
    jobTypeName,
    jobNotes: officeNotes,
    departmentList: (maintenanceData.departments?.items || []).map(department => ({
      label: department.mappedEntity?.tagName
    })),
    customerProvidedPONumber: maintenanceData?.customerProvidedPONumber,
    procurementStatus: maintenanceData?.procurementStatus
  };
};

export const getStatusBasedAction = status => {
  const activeStatus = {
    [JobStatus.COMPLETE]: REOPEN_ACTION_BUTTON,
    [MaintenanceStatus.SKIPPED]: REOPEN_ACTION_BUTTON,
    [MaintenanceStatus.UNSCHEDULED]: MAINTENANCE_ACTION_BUTTONS,
    [MaintenanceStatus.SCHEDULED]: MAINTENANCE_ACTION_BUTTONS,
    [MaintenanceStatus.OVERDUE]: MAINTENANCE_ACTION_BUTTONS,
    [JobStatus.IN_PROGRESS]: MAINTENANCE_ACTION_BUTTONS
  };
  return activeStatus[status];
};
