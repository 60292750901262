import React, { useEffect, useReducer, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Spinner, Tab, Tabs, UserPermission, withMultipleForms } from 'components';
import SergeantModal from 'components/SergeantModal';
import Labels from 'meta/labels';
import { generateReceiptFormLayout } from 'meta/Procurement/PurchaseOrders/generateReceiptForm';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import useUpdateJob from 'scenes/JobCloseout/JobCloseoutHeader/hooks/useUpdateJob';
import PurchaseOrderFrame from 'scenes/Procurement/component/PurchaseOrderFrame';
import {
  getEntireStatus,
  getFormattedPOLinesData,
  getPurchaseOrderCost,
  getPurchaseOrderSideBarShipping,
  getPurchaseOrderSideBarVendor,
  getPurchaseOrderTopBar,
  getSanitizedLineItem,
  getShippingAddress,
  GOOGLE_MAPS_BASE_URL
} from 'scenes/Procurement/component/utils';
import { ShipToNameTypes } from 'scenes/Procurement/constants';
import CustomFieldWithLabel from 'scenes/ProjectManagement/components/CustomFieldWithLabel';
import {
  getPurchaseOrderById,
  getPurchaseOrderPdfData,
  purchaseOrderChange
} from 'services/API/purchaseOrder';
import {
  getLinesByPurchaseOrder,
  purchaseOrderLineChange,
  purchaseOrderLineCreate,
  purchaseOrderLineDelete
} from 'services/API/purchaseOrderLine';
import {
  addReceiptAttachment,
  getNextReceiptNumber,
  purchaseOrderReceiptChange,
  purchaseOrderReceiptCreate
} from 'services/API/purchaseOrderReceipt';
import { getLinesByReceipt } from 'services/API/purchaseOrderReceiptLines';
import { JobService } from 'services/core';
import { roundCurrency } from 'utils';
import { GOOGLE_MAPS_API_KEY, PermissionConstants, QuoteConstants } from 'utils/AppConstants';
import { AccountingApp, Mode } from 'utils/constants';

import PurchaseOrderCost from '../../component/PurchaseOrderCost';

import BillsList from './BillsList';
import GenerateReceiptModal from './GenerateReceiptModal';
import OrderList from './OrderList';
import ReceiptsList from './ReceiptsList';

const CustomFieldWithLabelStyled = ({ field, options }) => {
  return (
    <CustomFieldWithLabel
      field={field}
      options={options}
      style={{ background: '#f0f0f0', color: '#999999', padding: '1px 8px' }}
    />
  );
};

const useStyles = makeStyles(() => ({
  orderContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  pocContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16
  },
  label: {
    fontSize: 10,
    letterSpacing: 0.01,
    fontWeight: 'normal',
    textTransform: 'uppercase',
    lineHeight: '14px',
    marginBottom: '0.35em',
    display: 'block'
  }
}));

const initialState = {
  common: {},
  topbar: {},
  sidebarVendor: {},
  sidebarShipping: {},
  cost: {}
};

const poFrameDataReducer = (state, action) => {
  switch (action.type) {
    case 'updateAll':
      return {
        ...state,
        common: {
          ...state.common,
          ...{
            id: action.payload.id,
            poNumber: action.payload.poNumber,
            status: action.payload.status,
            isFieldOrder: action.payload.isFieldOrder,
            purchaseOrderTags: action.payload?.purchaseOrderTags?.map(tag => tag.id) || [],
            version: action?.payload?.poType?.version
          }
        },
        topbar: { ...state.topbar, ...getPurchaseOrderTopBar(action.mode, action.payload) },
        sidebarVendor: {
          ...state.sidebarVendor,
          ...getPurchaseOrderSideBarVendor(action.mode, action.payload)
        },
        sidebarShipping: {
          ...state.sidebarShipping,
          ...getPurchaseOrderSideBarShipping(action.mode, action.payload)
        },
        cost: { ...state.cost, ...getPurchaseOrderCost(action.payload) },
        version: action.payload.version
      };
    case 'updateTopbar':
      return {
        ...state,
        topbar: { ...state.topbar, ...action.payload },
        sidebarShipping: {
          ...state.sidebarShipping,
          jobAndProject: {
            ...state.sidebarShipping.jobAndProject,
            ...action.payload.jobAndProject
          }
        }
      };
    case 'updateTopbarAndSidebarShipping':
      return {
        ...state,
        topbar: { ...state.topbar, ...action.payload },
        sidebarShipping: {
          ...state.sidebarShipping,
          jobAndProject: {
            ...state.sidebarShipping.jobAndProject,
            ...action.payload.jobAndProject
          },
          shippingAddress: action.payload.shippingAddress,
          shippingAddressObj: action.payload.shippingAddressObj,
          shippingLocation: action.payload.shippingLocation
        }
      };
    case 'updateSidebarVendor':
      return { ...state, sidebarVendor: { ...state.sidebarVendor, ...action.payload } };
    case 'updateSidebarVendorAndSidebarShipping':
      return {
        ...state,
        sidebarVendor: { ...state.sidebarVendor, ...action.payload },
        sidebarShipping: {
          ...state.sidebarShipping,
          shippingAddress: action.payload.vendorAddress,
          shippingAddressObj: action.payload.shippingAddressObj,
          shippingLocation: action.payload.vendorLocation
        }
      };
    case 'updateSidebarShipping':
      return {
        ...state,
        sidebarShipping: { ...state.sidebarShipping, ...action.payload },
        topbar: { ...state.topbar, shipTo: action.payload.shipTo },
        sidebarVendor: { ...state.sidebarVendor, shipTo: action.payload.shipTo }
      };
    case 'updateCost':
      return { ...state, cost: { ...state.cost, ...action.payload } };
    case 'updateNotes':
      return {
        ...state,
        topbar: { ...state.topbar, note: action.payload }
      };
    case 'initialize':
      return { topbar: {}, sidebar: {}, cost: {} };
    default:
      return state;
  }
};

const PurchaseOrderDetail = props => {
  // withMultipleForms HOC props
  const {
    user,
    getHandleCreateService,
    getHandleComplete,
    handleSubmitStart,
    setOnSubmitFinal,
    isSubmitting
  } = props;
  const { poId, mode } = props.computedMatch?.params || {};
  const classes = useStyles();
  const history = useHistory();
  const [updateJob] = useUpdateJob();
  const tenantAccountingApp = useSelector(state => state.settings.accountingApp);
  const isVistaEnabled = tenantAccountingApp === AccountingApp.VISTA;
  const canSetEmptyJobAndProject =
    tenantAccountingApp !== AccountingApp.VISTA && tenantAccountingApp !== AccountingApp.SPECTRUM;

  const [poFrameState, dispatchPOFrameData] = useReducer(poFrameDataReducer, initialState);
  const [loaded, setLoaded] = useState(false);
  const [generateReceiptPopup, setGenerateReceiptPopup] = useState(false);
  const [generateReceiptModal, setGenerateReceiptModal] = useState(false);
  const [associatedProject, setAssociatedProject] = useState(undefined);
  const [receiptNumber, setReceiptNumber] = useState('');
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [purchaseOrderLines, setPOLines] = useState([]);
  const [purchaseOrderPdfData, setPurchaseOrderPdfData] = useState({
    costs: {},
    generalInfo: {},
    items: []
  });

  const getTotalCost = po => {
    let totalCost = 0;
    po?.purchaseOrderLines?.forEach(item => {
      totalCost += Number(item.quantity ?? 0) * Number(item.unitCost ?? 0);
    });
    return totalCost;
  };

  useEffect(() => {
    setLoaded(false);
    getPurchaseOrderById(poId).then(po => {
      if (po?.project?.id) {
        setAssociatedProject(po.project);
      }
      if (po.status === 'Unfulfilled') {
        // eslint-disable-next-line no-param-reassign
        po.status = 'Draft';
      }

      // eslint-disable-next-line no-use-before-define
      const sanitizedPo = sanitizePurchaseOrder(po);
      setPurchaseOrder(sanitizedPo);
      dispatchPOFrameData({ type: 'updateAll', payload: sanitizedPo, mode });
      setLoaded(true);
    });
    getLinesByPurchaseOrder(poId).then(po => {
      setPOLines(po);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePurchaseOrderPdfData = () => {
    if (purchaseOrder?.id) {
      getPurchaseOrderPdfData(purchaseOrder.id).then(pdfData => {
        setPurchaseOrderPdfData(pdfData);
      });
    }
  };

  useEffect(() => {
    updatePurchaseOrderPdfData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  const sanitizePurchaseOrder = poData => {
    return {
      accountingRefId: poData.accountingRefId || null,
      accountingRefIdOfClass: poData.accountingRefIdOfClass || null,
      addressLine1: poData.addressLine1 || null,
      addressLine2: poData.addressLine2 || null,
      assignedTo: poData.assignedTo || null,
      assignedToId: poData.assignedToId || null,
      associatedQuotes: [...(poData.quote || []), ...(poData.versionedQuote || [])].map(q => ({
        customIdentifier: q.customIdentifier,
        entityType: q.entityType,
        id: q.entityType === QuoteConstants.VersionedQuote ? q.versionOfEntityId : q.id,
        quoteNumber: q.quoteNumber,
        status: q.status,
        versionId: q.entityType === QuoteConstants.VersionedQuote ? q.id : null,
        versionNumber: q.versionNumber
      })),
      city: poData.city || null,
      dateOfPurchase: poData.dateOfPurchase || null,
      department: poData.department || null,
      departmentId: poData.department?.id || poData.departmentId || null,
      departmentName: poData.department?.tagName || poData.departmentName || null,
      description: poData.description || null,
      freight: roundCurrency(poData.freight),
      id: poData.id || null,
      isFieldOrder: !!poData.isFieldOrder,
      job: poData.job || null,
      jobId: poData.jobAndProject || poData.jobId || null,
      note: poData.note || null,
      orderType: poData.orderType || null,
      orderedById: poData.orderedById || null,
      parentId: poData.parentId || null,
      poNumber: poData.poNumber || null,
      poType: poData.poType || null,
      poTypeId: poData.poTypeId || null,
      project: poData.project || null,
      projectId: poData.projectId || null,
      purchaseOrderLines: poData.purchaseOrderLines || null,
      purchaseOrderReceipts: poData.purchaseOrderReceipts || null,
      purchaseOrderBills: poData.purchaseOrderBills || null,
      purchaseOrderTags: poData.purchaseOrderTags,
      receiptNumber: poData.receiptNumber || null,
      requiredByDate: poData.requiredByDate || null,
      shipTo: poData.shipTo || null,
      shipToInstructions: poData.shipToInstructions || null,
      shipToName: poData.shipToName || null,
      shipToNameType: poData.shipToNameType || null,
      shipToEmployeeId: poData.shipToEmployeeId || null,
      shipToEmployee: poData.shipToEmployee || null,
      state: poData.state || null,
      status: poData.status || null,
      tax: parseFloat(poData.tax || null),
      taxRateId: poData.taxRateId || null,
      taxRate: poData.taxRate || null,
      totalAmountPreTax: poData.totalAmountPreTax || null,
      totalCost: poData.totalCost || null,
      vendor: poData.vendor || null,
      vendorId: poData.vendorId || null,
      vendorName: poData.vendorName || null,
      zipcode: poData.zipcode || null,
      version: poData.version
    };
  };

  const getFormattedData = () => {
    return {
      receiptNumber
    };
  };

  const checkForJobsOrProjectsWarning = async (jobId, projectId) => {
    const po = await getPurchaseOrderById(poId);
    const conditions = [true, true, true];
    if (
      po?.purchaseOrderLines &&
      po?.purchaseOrderLines?.length > 0 &&
      !po?.purchaseOrderLines?.every(
        element => element.jobId === po.jobId && element.projectId === po.projectId
      ) &&
      // eslint-disable-next-line eqeqeq
      (po.jobId != jobId || po.projectId != projectId)
    ) {
      conditions[0] = false;
    }
    if (
      po?.purchaseOrderBills &&
      po?.purchaseOrderBills?.length > 0 &&
      !po?.purchaseOrderBills?.every(element => element.invoicedStatus === 'NotInvoiced')
    ) {
      conditions[1] = false;
    }
    if (
      po?.purchaseOrderReceipts &&
      po?.purchaseOrderReceipts?.length > 0 &&
      po?.purchaseOrderReceipts?.some(element => element.status === 'Exported')
    ) {
      conditions[2] = false;
    }
    return conditions;
  };

  const handleOnComplete = async data => {
    const po = sanitizePurchaseOrder(await getPurchaseOrderById(poId, true));
    let jobId;
    let projectId;
    if (data.jobAndProject && typeof data.jobAndProject === 'object') {
      if (data.jobAndProject.jobNumber) {
        jobId = data.jobAndProject.id;
        projectId = undefined;
      } else {
        projectId = data.jobAndProject.id;
        jobId = undefined;
      }
    } else {
      jobId = po.jobId;
      projectId = po.projectId;
    }
    if (po.jobId !== jobId) {
      const conditions = await checkForJobsOrProjectsWarning(jobId, projectId);
      if (!conditions.every(element => element)) {
        let snackbarText =
          'The job or project on this purchase order cannot be changed due to the following:';
        if (!conditions[0]) {
          snackbarText +=
            '\n - There are line items on this purchase order assigned to different jobs or projects';
        }
        if (!conditions[1]) {
          snackbarText +=
            '\n - One or more bill line items have been added to a report for invoicing on the job';
        }
        if (!conditions[2]) {
          snackbarText += '\n - One or more receipts have been exported ';
        }
        props.snackbarOn('error', snackbarText);
        history.push(`/procurement/purchaseorders/view/${poId}`);
        return;
      }
    }
    const shippingAddress = await getShippingAddress(data.shipTo, data);
    const payload = {
      ...po,
      jobAndProject: undefined,
      vendorName: data.vendor?.name || po.vendorName,
      vendorId: data.vendor?.id || po.vendorId,
      addressLine1: shippingAddress?.addressLine1,
      addressLine2: shippingAddress?.addressLine1 ? shippingAddress?.addressLine2 : null,
      city: shippingAddress?.city,
      state: shippingAddress?.state,
      zipcode: shippingAddress?.zipcode,
      departmentName: data.department?.tagName || po.departmentName || null,
      departmentId: data.department?.id || po.departmentId || null,
      assignedToId: data.assignedTo?.id || po.assignedToId || null,
      poTypeId: data.poType?.id || po.poTypeId || null,
      requiredByDate: data.requiredByDate || null,
      dateOfPurchase: data.dateOfPurchase || null,
      freight: Number.isNaN(data.freightCost) ? null : data.freightCost,
      shipTo: data.shipTo || po.shipTo || null,
      shipToEmployeeId:
        data.shipToNameType === ShipToNameTypes.EMPLOYEE ? data.shipToEmployee?.id : null,
      shipToName: data.shipToNameType === ShipToNameTypes.OTHER ? data.shipToName : null,
      shipToNameType: data.shipToNameType || null,
      shipToInstructions: data.shipToInstructions || null,
      jobId,
      projectId,
      description: data.description || po.description,
      taxRateId: data.taxRate?.id || data.taxRateId || null,
      tax: data.taxAmount || po.tax || 0,
      totalCost: data?.total || getTotalCost(data) || null,
      totalAmountPreTax: data?.subtotal || 0,
      purchaseOrderTags: data?.purchaseOrderTags?.length
        ? data.purchaseOrderTags.map(tag => ({ id: tag }))
        : []
    };
    purchaseOrderChange(poId, sanitizePurchaseOrder(payload)).then(async newData => {
      if (newData?.jobId !== po?.jobId) {
        if (Array.isArray(newData?.purchaseOrderReceipts)) {
          await Promise.all(
            newData.purchaseOrderReceipts.map(receipt => getLinesByReceipt(receipt.id))
          )
            .then(lines =>
              Promise.all([
                ...(newData?.purchaseOrderReceipts?.map(async receipt => {
                  await purchaseOrderReceiptChange(receipt.id, {
                    jobId: newData.jobId,
                    PurchaseOrderReceiptLine: lines
                      ?.filter(receiptLine => receiptLine.receiptId === receipt.id)
                      .map(receiptLine => ({
                        ...receiptLine,
                        jobId: newData.jobId
                      }))
                  });
                }) || []),
                ...(newData?.purchaseOrderLines?.map(async poLine => {
                  await purchaseOrderLineChange(poLine.id, { jobId: newData.jobId });
                }) || [])
              ])
            )
            .finally(async () => {
              const newPoLines = await getLinesByPurchaseOrder(poId);
              setPOLines(newPoLines);
            });
        }
        if (data?.jobProcStatusToUpdate) {
          await updateJob({
            id: data?.jobProcStatusToUpdate?.id,
            version: data?.jobProcStatusToUpdate?.version,
            procurementStatus: null
          });
        }
      }
      updatePurchaseOrderPdfData();
      history.push(`/procurement/purchaseorders/view/${poId}`);
    });
  };

  const handleGenerateReceipt = () => {
    getNextReceiptNumber(poId).then(receiptNum => {
      setReceiptNumber(receiptNum);
      setGenerateReceiptPopup(true);
    });
  };

  const sanitizeLinesForReceipt = lines => {
    return lines.map((line, index) => {
      return {
        lineNumber: index + 1,
        purchaseOrderLineId: line.id,
        quantity: line.quantityFulfilled,
        purchaseOrderId: poId,
        productId: line.productId,
        description: line.description,
        departmentId: line.departmentId || null,
        unitCost: line.unitCost || 0,
        unitOfMeasure: line.unitOfMeasure,
        taxable: line.taxable,
        jobId: line.jobId || null,
        projectId: line.projectId || null,
        tenantCompanyId: user.tenantCompanyId,
        projectCostCodeId: line.projectCostCodeId || line.projectCostCode?.id || null,
        projectPhaseId: line.projectPhaseId || line.projectPhase?.id || null,
        projectCostType: line.projectCostType || null,
        costCodeId: line.costCode?.id || line.costCodeId || null,
        revenueTypeId: line.revenueType?.id || line.revenueTypeId || null,
        jobCostTypeId: line.jobCostType?.id || line.jobCostTypeId || null,
        jcPhaseId: line.jcPhaseId || null,
        jcCostTypeId: line.jcCostTypeId || null
      };
    });
  };

  const handleGenerateReceiptPopup = (completed, stopLoading) => {
    setReceiptNumber(completed.receiptNumber);
    setGenerateReceiptModal(true);
    stopLoading();
    setGenerateReceiptPopup(false);
  };

  const handleSaveNewItem = async items => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      const sanitizedNewItem = getSanitizedLineItem(item, false, canSetEmptyJobAndProject);
      // eslint-disable-next-line no-await-in-loop
      await purchaseOrderLineCreate({ ...sanitizedNewItem, parentId: poId });
    }

    const newPo = await getPurchaseOrderById(poId);
    const newPoLines = await getLinesByPurchaseOrder(poId);
    newPo.purchaseOrderLines = newPoLines;
    setPOLines(newPoLines);
    setPurchaseOrder({
      ...purchaseOrder,
      status: newPo.status,
      totalCost: newPo.totalCost,
      totalAmountPreTax: newPo.totalAmountPreTax
    });
    dispatchPOFrameData({
      type: 'updateAll',
      payload: {
        ...purchaseOrder,
        status: newPo.status,
        totalCost: newPo.totalCost,
        totalAmountPreTax: newPo.totalAmountPreTax
      },
      mode
    });
    updatePurchaseOrderPdfData();
  };

  const updateCosts = lines => {
    if (!lines || !lines?.length) return 0;

    const lineItemsTotal = lines
      .map(line => Number(line.quantity ?? 0) * Number(line.unitCost ?? 0))
      .reduce((prev, next) => prev + next);
    return lineItemsTotal;
  };

  const handleDeleteItem = async lineToDelete => {
    await purchaseOrderLineDelete(lineToDelete.id);
    await updatePurchaseOrderPdfData();
    const newLines = await getLinesByPurchaseOrder(poId);
    const updatedPO = await getPurchaseOrderById(poId);
    const newPOData = { ...purchaseOrder, ...updatedPO };
    setPOLines(newLines);
    setPurchaseOrder(newPOData);
    dispatchPOFrameData({ type: 'updateAll', payload: newPOData, mode });
  };

  // eslint-disable-next-line no-unused-vars
  const handleGenerateReceiptModal = async ({
    vendorDocumentNumber,
    issuedBy,
    paymentTermId,
    lines,
    receiptImages
  }) => {
    let rn = receiptNumber ? `${receiptNumber}` : '';
    if (typeof rn === 'string' && !rn.includes(purchaseOrder.poNumber)) {
      rn = `${purchaseOrder.poNumber}-${rn}`;
    }
    const purchaseOrderReceipt = {
      vendorDocumentNumber,
      parentId: purchaseOrder.id,
      departmentId: purchaseOrder.departmentId || null,
      vendorId: purchaseOrder.vendorId || null,
      jobId: purchaseOrder.jobId || null,
      projectId: purchaseOrder.projectId || null,
      description: purchaseOrder.description,
      status: 'Pending',
      freight: purchaseOrder.freight || 0,
      taxRateId: purchaseOrder.taxRateId,
      receiptNumber: rn,
      tax: purchaseOrder.tax || 0,
      paymentTermId,
      PurchaseOrderReceiptLine: sanitizeLinesForReceipt(lines),
      issuedBy: issuedBy || moment.utc(moment().format('L')).unix()
    };

    const newReceipt = await purchaseOrderReceiptCreate(purchaseOrderReceipt);
    props.snackbarOn('success', 'Successfully Generated');

    if (newReceipt?.id) {
      await Promise.all(receiptImages.map(item => addReceiptAttachment(newReceipt.id, item)));
      history.push(`/procurement/receipts-bills/receipt/view/${newReceipt.id}`);
    } else {
      // determine status based on new purchaseOrderLines
      const newLines = await getLinesByPurchaseOrder(poId);
      // const status = calculateStatus(newLines, purchaseOrder.status === 'Ordered');

      setPOLines(newLines);

      const newPurchaseOrder = await getPurchaseOrderById(poId);
      setPurchaseOrder(sanitizePurchaseOrder(newPurchaseOrder));

      dispatchPOFrameData({ type: 'updateAll', payload: newPurchaseOrder, mode });

      getNextReceiptNumber(poId).then(receiptNum => {
        setReceiptNumber(receiptNum);
      });
      setGenerateReceiptModal(false);
    }
  };

  const handleEditItemSave = async (updatedItem, stopLoading) => {
    const sanitizedItem = getSanitizedLineItem(updatedItem, false, canSetEmptyJobAndProject);
    // TODO: send final data using API and call stopLoading and setEditModalOpen
    const allItemData = purchaseOrderLines.filter(
      item => item.lineNumber === sanitizedItem.lineNumber
    )[0];
    if (allItemData) {
      // eslint-disable-next-line no-param-reassign
      const finalData = {
        ...sanitizedItem,
        id: allItemData.id
      };

      // const newLines = purchaseOrderLines.map(item =>
      //   item.lineNumber === finalData.lineNumber ? finalData : item
      // );
      // const status = calculateStatus(newLines, purchaseOrder?.status === 'Ordered');

      await purchaseOrderLineChange(finalData.id, finalData);

      // Backend updates status/total when POLines are updated/created, so we just refresh the PO.
      const newPo = await getPurchaseOrderById(poId);

      // Only need updated status, totalCost, and totalAmountPreTax after line update.
      setPurchaseOrder({
        ...purchaseOrder,
        status: newPo.status,
        totalCost: newPo.totalCost,
        totalAmountPreTax: newPo.totalAmountPreTax
      });
      dispatchPOFrameData({
        type: 'updateAll',
        payload: {
          ...purchaseOrder,
          status: newPo.status,
          totalCost: newPo.totalCost,
          totalAmountPreTax: newPo.totalAmountPreTax
        },
        mode
      });
      const linesFromDb = await getLinesByPurchaseOrder(poId);
      setPOLines(linesFromDb);
    }
    stopLoading();
    // eslint-disable-next-line no-use-before-define
    updateCosts();
    updatePurchaseOrderPdfData();
  };

  return (
    <ErrorBoundaries>
      <UserPermission I="read" action={PermissionConstants.OBJECT_PURCHASE_ORDER}>
        {loaded ? (
          <PurchaseOrderFrame
            googleMapURL={GOOGLE_MAPS_BASE_URL + GOOGLE_MAPS_API_KEY}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            originalPO={purchaseOrder}
            purchaseOrder={poFrameState}
            purchaseOrderPdfData={purchaseOrderPdfData}
            setPurchaseOrder={setPurchaseOrder}
            mode={mode === 'view' ? 'default' : 'edit'}
            status={getEntireStatus(purchaseOrder.status)}
            isFieldOrder={purchaseOrder.isFieldOrder}
            getHandleCreateService={getHandleCreateService}
            getHandleComplete={getHandleComplete}
            handleSubmitStart={handleSubmitStart}
            setOnSubmitFinal={setOnSubmitFinal}
            isSubmitting={isSubmitting}
            handleFormsSubmit={handleOnComplete}
            handleGenerateReceipt={handleGenerateReceipt}
            frameDataReducer={dispatchPOFrameData}
            tags={purchaseOrder?.purchaseOrderTags}
            isLoading={loaded}
            canEdit={
              !purchaseOrder?.purchaseOrderReceipts ||
              !purchaseOrder?.purchaseOrderReceipts?.length > 0
            }
            checkForJobsOrProjectsWarning={checkForJobsOrProjectsWarning}
            isVistaEnabled={isVistaEnabled}
          >
            <Tabs>
              <Tab label="Order">
                <div className={classes.orderContainer}>
                  <OrderList
                    po={purchaseOrder}
                    mode={mode}
                    isLoading={!loaded}
                    dispatchFrame={dispatchPOFrameData}
                    itemData={getFormattedPOLinesData(purchaseOrderLines || [])}
                    handleSaveNewItem={handleSaveNewItem}
                    handleEditItemSave={handleEditItemSave}
                    handleDeleteItem={handleDeleteItem}
                    associatedProject={associatedProject}
                    isVistaEnabled={isVistaEnabled}
                  />
                  <div className={classes.pocContainer}>
                    <PurchaseOrderCost
                      formLayout={mode === 'view' ? 'default' : 'edit'}
                      freightCosts={purchaseOrder.freight || 0}
                      taxRateId={purchaseOrder.taxRateId || ''}
                      taxRate={purchaseOrder.taxRate || null}
                      lineItems={purchaseOrderLines}
                      onFormChange={data =>
                        setPurchaseOrder({
                          ...purchaseOrder,
                          totalAmountPreTax: data.subtotal ?? 0,
                          freight: data.freightCost,
                          taxRateId: data.taxRateId
                        })
                      }
                      getHandleCreateService={getHandleCreateService}
                      getHandleComplete={getHandleComplete}
                      useMultipleForm
                    />
                  </div>
                </div>
              </Tab>
              <Tab label="Receipts">
                <ReceiptsList
                  mode={mode}
                  poId={poId}
                  poStatus={getEntireStatus(purchaseOrder.status)}
                  updatePurchaseOrderLines={id => {
                    getLinesByPurchaseOrder(id).then(lines => {
                      setPOLines(getFormattedPOLinesData(lines));
                      getPurchaseOrderById(id).then(async updatedPo => {
                        setPurchaseOrder({
                          ...purchaseOrder,
                          status: updatedPo.status,
                          totalCost: updatedPo.totalCost,
                          totalAmountPreTax: updatedPo.totalAmountPreTax,
                          purchaseOrderReceipts: updatedPo.purchaseOrderReceipts,
                          purchaseOrderLines: lines
                        });
                        updatePurchaseOrderPdfData();
                      });
                    });
                  }}
                />
              </Tab>
              <Tab label="Bills">
                <BillsList poId={poId} />
              </Tab>
            </Tabs>
          </PurchaseOrderFrame>
        ) : (
          <Spinner styles={{ marginLeft: 30 }} />
        )}
        <SergeantModal
          open={generateReceiptPopup}
          title={Labels.generateReceipt[user.locale]}
          customPrimaryButtonLabel={Labels.generateReceipt[user.locale]}
          data={getFormattedData()}
          dataType="Item"
          mode={Mode.NEW}
          layout={generateReceiptFormLayout}
          handlePrimaryAction={handleGenerateReceiptPopup}
          handleClose={() => setGenerateReceiptPopup(false)}
          maxWidth={470}
          customComponents={{ CustomFieldWithLabelStyled }}
        />
        <GenerateReceiptModal
          receiptNumber={receiptNumber}
          open={generateReceiptModal}
          user={user}
          onSubmit={handleGenerateReceiptModal}
          handleClose={() => setGenerateReceiptModal(false)}
          poId={poId}
        />
      </UserPermission>
    </ErrorBoundaries>
  );
};

CustomFieldWithLabelStyled.propTypes = {
  field: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

PurchaseOrderDetail.propTypes = {
  user: PropTypes.object.isRequired,
  getHandleCreateService: PropTypes.func.isRequired,
  getHandleComplete: PropTypes.func.isRequired,
  handleSubmitStart: PropTypes.func.isRequired,
  setOnSubmitFinal: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  computedMatch: PropTypes.object.isRequired,
  snackbarOn: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ user: state.user });
const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});
const MultipleFormsPurchaseOrderDetail = withMultipleForms(PurchaseOrderDetail, [
  'sidebarVendor',
  'sidebarShipping',
  'mainTop',
  'purchaseOrderCost'
]);
const ReduxConnectedMultipleFormsPurchaseOrderDetail = connect(
  mapStateToProps,
  mapDispatcherToProps
)(MultipleFormsPurchaseOrderDetail);
export default ReduxConnectedMultipleFormsPurchaseOrderDetail;
