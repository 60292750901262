import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  root: {
    padding: 25
  },
  displayFlex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  displayFlexColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  logoImage: {
    maxWidth: '100px',
    maxHeight: '40px',
    objectFit: 'contain'
  },
  text: {
    fontSize: 9,
    marginBottom: 4,
    overflowWrap: 'break-word'
  },
  textBold: {
    fontWeight: 600,
    fontFamily: 'Helvetica-Bold'
  },
  numberTitle: {
    fontSize: 18,
    fontWeight: 500,
    fontFamily: 'Helvetica-Bold'
  },
  sectionTitle: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: 'Helvetica-Bold',
    marginBottom: 4
  },
  numberSubtitle: {
    fontSize: 14
  },
  date: {
    fontSize: 12
  },
  labelNormal: {
    color: '#999999',
    fontSize: 8,
    marginBottom: 4
  },
  marginBottom6: {
    marginBottom: 6
  },
  divider: {
    width: '100%',
    borderBottom: 1,
    borderColor: '#E6E6E6',
    marginTop: 12,
    marginBottom: 12
  },
  totalBorder: {
    width: '17%',
    borderBottom: 1,
    borderColor: '#000000',
    marginTop: 6,
    marginBottom: 6
  },
  sectionLabel: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: 'Helvetica-Bold'
  },
  totalsLineItemText: {
    fontSize: 10,
    textAlign: 'right',
    fontFamily: 'Inter',
    fontWeight: 400,
    width: '20%',
    lineHeight: 1.4
  },
  totalLinesDivider: {
    width: '12%',
    borderBottom: 1,
    borderColor: 'black',
    marginTop: 8,
    marginBottom: 8
  }
});

export default styles;
