import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { Button, ThemeProvider } from '@buildhero/sergeant';

import { RelatedInvoices } from './RelatedInvoices';
import { InvoiceSetting } from './ServiceAgreementInvoiceSettings';

const RELATED_INVOICE = 'relatedInvoice';
const INVOICE_SETTINGS = 'invoiceSettings';

const btnStyle = { marginRight: 8 };

export const InvoiceTab = props => {
  const { isAgreementActive, isExpired } = props;
  const [activeSection, setActiveSection] = useState(
    isAgreementActive || isExpired ? RELATED_INVOICE : INVOICE_SETTINGS
  );
  return (
    <Box>
      {(isAgreementActive || isExpired) && (
        <ThemeProvider>
          <Button
            type={activeSection === RELATED_INVOICE ? 'primary' : 'tertiary'}
            onClick={() => setActiveSection(RELATED_INVOICE)}
            style={btnStyle}
          >
            Related Invoices
          </Button>
          {props?.agreementInfo?.billingType === 'RecurringBilling' && (
            <Button
              type={activeSection === INVOICE_SETTINGS ? 'primary' : 'tertiary'}
              onClick={() => setActiveSection(INVOICE_SETTINGS)}
            >
              Invoice Settings
            </Button>
          )}
        </ThemeProvider>
      )}
      {activeSection === RELATED_INVOICE && <RelatedInvoices {...props} />}
      {activeSection === INVOICE_SETTINGS && <InvoiceSetting {...props} />}
    </Box>
  );
};

export default InvoiceTab;
