import gql from 'graphql-tag';
import { useQuery, useMutation, useSubscription } from '@apollo/client';
import { useCallback, useState, useEffect } from 'react';
import { EntityType } from 'utils/constants';
import { isEmpty } from 'lodash';
import { formatAdjustmentData, formatAdjustmentDataForMutation } from './Adjustment.utils';

const ADJUSTMENT_FRAGMENT = gql`
  fragment AdjustmentFields on Adjustment {
    id
    tenantId
    tenantCompanyId
    number
    date
    amount
    appliedAmount
    taxAmount
    taxRateValue
    taxRate(entityConnection: "TaxRate") {
      value: id
      label: name
      taxRate
    }
    taxLedgerAccount(entityConnection: "LedgerAccount") {
      value: id
      label: name
    }
    department(entityConnection: "Department") {
      value: id
      label: tagName
    }
    priceBook(entityConnection: "PriceBook") {
      value: id
      label: name
    }
    billingCustomer(entityConnection: "Customer") {
      id
      customerName
    }
    property {
      id
      companyName
    }
    adjustmentType(entityConnection: "AdjustmentType") {
      value: id
      label: name
    }
    ledgerAccount(entityConnection: "LedgerAccount") {
      value: id
      label: name
    }
    ledgerOffsetAccount(entityConnection: "LedgerAccount") {
      value: id
      label: name
    }
    transactionType
    creditCardTransaction
    status
    exportStatus
    syncStatus
    syncLog
    adjustmentItems(entityConnection: "AdjustmentItem") {
      items {
        id
        name
        ledgerAccount(entityConnection: "LedgerAccount") {
          value: id
          label: name
        }
        department(entityConnection: "Department") {
          value: id
          label: tagName
        }
        description
        taxable
        quantity
        unitCost
        unitPrice
        amount
        jobCostType(entityConnection: "JobCostType") {
          value: id
          label: name
        }
        costCode(entityConnection: "CostCode") {
          value: id
          label: name
        }
        revenueType(entityConnection: "RevenueType") {
          value: id
          label: name
        }
        product(entityConnection: "Product") {
          id
          code
          unitOfMeasure(entityConnection: "UnitOfMeasure") {
            name
          }
        }
        sortOrder
      }
    }
    transactions(entityConnection: "AdjustmentTransaction") {
      items {
        id
        appliedAmount
        transactionId
        transaction {
          ... on Invoice {
            invoiceNumber
            totalAmount
          }
          ... on Payment {
            paymentNumber
            paymentAmount
          }
        }
      }
    }
  }
`;

const GET_ADJUSTMENT = gql`
  query GetAdjustmentById($id: String!) {
    getAdjustmentById(id: $id) {
      ...AdjustmentFields
    }
  }
  ${ADJUSTMENT_FRAGMENT}
`;

export const useAdjustmentQuery = (id, options = undefined) => {
  const [data, setData] = useState();
  const { data: { getAdjustmentById: rawData } = {}, loading, error } = useQuery(GET_ADJUSTMENT, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options
  });

  useEffect(() => {
    const getFormattedData = async () => setData(await formatAdjustmentData(rawData));
    if (rawData) getFormattedData();
  }, [rawData]);

  return { data, loading: loading || !data, error };
};

const ADJUSTMENT_SUBSCRIPTION = gql`
  subscription mutationNotification(
    $partitionKey: String!
    $entityType: String
    $entityId: String
  ) {
    mutationNotification(
      partitionKey: $partitionKey
      entityType: $entityType
      entityId: $entityId
    ) {
      id
      sortKey
      entityType
      mutationType
      changeLog
    }
  }
`;

// only update following field (for accounting integration)
const SUBSCRIPTION_FIELDS = ['status', 'syncStatus', 'syncLog'];

export const useAdjustmentSubscription = async (tenantId, id) => {
  useSubscription(ADJUSTMENT_SUBSCRIPTION, {
    fetchPolicy: 'no-cache',
    variables: {
      partitionKey: tenantId,
      entityType: EntityType.ADJUSTMENT,
      entityId: id
    },
    onSubscriptionData: ({ subscriptionData, client }) => {
      const changeLog = JSON.parse(subscriptionData.data.mutationNotification.changeLog);
      const newData = changeLog?.reduce(
        (changes, change) =>
          SUBSCRIPTION_FIELDS.includes(change.field)
            ? { ...changes, [change.field]: change.new }
            : changes,
        {}
      );
      if (!isEmpty(newData)) {
        client.writeQuery({
          query: GET_ADJUSTMENT,
          variables: { id },
          data: { getAdjustmentById: { ...newData, __typename: 'Adjustment' } }
        });
      }
    }
  });
};

const UPSERT_ADJUSTMENT = gql`
  mutation UpsertAdjustment($partitionKey: String, $data: UpsertAdjustmentInput!) {
    upsertAdjustment(partitionKey: $partitionKey, data: $data) {
      ...AdjustmentFields
    }
  }
  ${ADJUSTMENT_FRAGMENT}
`;

export const useUpsertAdjustment = (options = undefined) => {
  const [upsertAdjustment, other] = useMutation(UPSERT_ADJUSTMENT, {
    ...options
  });

  const upsert = useCallback(
    async givenData => {
      const data = formatAdjustmentDataForMutation(givenData);
      return upsertAdjustment({ variables: { data } });
    },
    [upsertAdjustment]
  );

  return [upsert, other];
};
