import React, { useState, useCallback, useEffect } from 'react';
import update from 'immutability-helper';
import removeObjProperties from 'utils/removeObjProperties';
import DraggableItem from './DraggableItem';

export const DraggableContainer = props => {
  const [list, setList] = useState([]);
  const { typesList = [], updateOrder = () => {} } = props;

  useEffect(() => {
    setList(typesList);
  }, [typesList]);

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = list[dragIndex];
      const updatedList = update(list, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragItem]
        ]
      });
      const itemWithSortOrder = updatedList.map((hourType, i) => ({
        ...removeObjProperties(hourType, ['version', '__typename']),
        sortOrder: i
      }));
      setList(itemWithSortOrder);
      updateOrder(itemWithSortOrder);
    },
    [list, updateOrder]
  );
  const renderHourTypeCard = (hourType, index) => {
    return (
      <DraggableItem
        key={hourType.id}
        index={index}
        id={hourType.id}
        payrollHourType={hourType}
        moveItem={moveItem}
        className={props.className}
        getDisplayLabel={props.getDisplayLabel}
        isAllowedToEdit={props.isAllowedToEdit}
        setOpenTypeModal={props.setOpenTypeModal}
        openTypeModal={props.openTypeModal}
      />
    );
  };
  return <div>{list.map(renderHourTypeCard)}</div>;
};

export default DraggableContainer;
