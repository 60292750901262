import gql from 'graphql-tag';
import { arrayOf, bool, shape, string } from 'prop-types';
import compact from 'lodash/compact';
import orderBy from 'lodash/orderBy';

import useExtendedQuery from 'customHooks/useExtendedQuery';
import { getState } from 'redux/store';

import { ERROR_PROP } from '@dispatch/Dispatch.constants';
import { watchedQueries } from '@dispatch/Dispatch.store';

import { selectPosition } from './useVisitDetails';

const GET_DISPATCH_TECHS = gql`
  query getDispatchTechs($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      employees: employeesView(
        entityConnection: "Employee"
        filter: {
          stringFilters: [{ fieldName: "status", filterInput: { eq: "active" } }]
          booleanFilters: [{ fieldName: "isTech", filterInput: { eq: true } }]
        }
      ) {
        items {
          id
          name
          isTech
          isAdmin
          contactAddresses(entityConnection: "ContactAddress") {
            items {
              id
              addressType
              addressLine1
              addressLine2
              city
              state
              zipcode
              latitude
              longitude
            }
          }
          departments {
            items {
              id
            }
          }
          profilePictureUrl
          appRoles(entityConnection: "AppRole") {
            items {
              mappedEntity {
                ... on AppRole {
                  id
                  tagName
                }
              }
            }
          }
        }
      }
    }
  }
`;

const DEFAULT_DATA = [];

const transformDispatchTechs = data => {
  const techs = data.getCompany.employees.items.map(tech => {
    const address = tech.contactAddresses.items?.[0] || {};
    const position = selectPosition(address);

    return {
      ...tech,
      name: tech?.name ?? '',
      address,
      position,
      departments: compact(tech.departments?.items?.map(department => department?.id) || []),
      role: tech.appRoles.items[0]?.mappedEntity?.tagName,
      isTech: !!tech?.isTech,
      isAdmin: !!tech?.isAdmin
    };
  });

  return orderBy(techs, tech => tech.name.toLowerCase());
};

export const useDispatchTechs = () => {
  const state = getState();
  const { tenantId, tenantCompanyId } = state.user;
  const sortKey = `${tenantId}_Company_${tenantCompanyId}`;
  const variables = { partitionKey: tenantId, sortKey };

  watchedQueries.useDispatchTechs = {
    query: GET_DISPATCH_TECHS,
    variables
  };

  return useExtendedQuery(GET_DISPATCH_TECHS, {
    variables,
    defaultData: DEFAULT_DATA,
    errorData: [],
    transform: transformDispatchTechs
  });
};

export const TECH_PROP = shape({
  id: string,
  name: string,
  profilePictureUrl: string,
  role: string,
  status: string
});

export const TECHS_RESPONSE_PROP = shape({
  loading: bool,
  error: ERROR_PROP,
  data: arrayOf(TECH_PROP),
  status: null
});
