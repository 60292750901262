import React from 'react';

import { shape, string } from 'prop-types';

import { StatusChip } from 'components';
import { StatusValToLabelMapping } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';

import { useStyles } from './JobBillingStatusChip.styles';

const JobBillingStatusChip = ({ job }) => {
  // NOTE: Do not do any override logic on job billing status. This is no longer a thing that is neccesary in any case.
  const styles = useStyles();
  return (
    <StatusChip
      enumType={EnumType.JOB_BILLING_STATUS}
      showIcon
      css={styles.chip}
      enumValue={job.billingStatus}
      label={StatusValToLabelMapping(EnumType.JOB_BILLING_STATUS, job.billingStatus)}
    />
  );
};

JobBillingStatusChip.propTypes = {
  job: shape({
    billingStatus: string.isRequired
  }).isRequired,
};

export default JobBillingStatusChip;