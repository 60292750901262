import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from '@dispatch/components/VisitsTabs/VisitsTabs.styles';

import { getServiceChannelExternalLink } from 'scenes/ServiceChannel/utils';

const ServiceChannel = ({ record }) => {
  const classes = useStyles();

  return (
    <div className={classes.TableCell}>
      <a
        target="_blank"
        rel="noreferrer"
        href={getServiceChannelExternalLink(record.serviceChannelWorkOrderWOId)}
      >
        {record.serviceChannelWorkOrderWOId}
      </a>
    </div>
  );
};

ServiceChannel.propTypes = {
  record: PropTypes.object.isRequired
};

export default memo(ServiceChannel);
