import gql from 'graphql-tag';

const addPurchaseOrderReceipt = gql`
  mutation AddPurchaseOrderReceipt($partitionKey: String, $data: AddPurchaseOrderReceiptsInput!) {
    addPurchaseOrderReceipts(partitionKey: $partitionKey, data: $data) {
      id
      fileName
      imageUrl
      order
      tenantId
      partitionKey
      sortKey
      createdBy
      version
      parentId
    }
  }
`;

export default addPurchaseOrderReceipt;
