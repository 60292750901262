const styles = theme => ({
  auditText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#959595',
    paddingTop: 15.5
  }
});
export default styles;
