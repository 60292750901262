import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { getLocalizedSize, fontSize } from '../utils';

const styles = StyleSheet.create({
  taskWrapper: {
    width: '100%',
    marginBottom: 12
  },
  sectionTitle: {
    fontFamily: 'Helvetica-Bold',
    fontSize: fontSize(9),
    margin: getLocalizedSize([6, 0]),
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textDecoration: 'underline',
    flexDirection: 'row'
  },
  sectionField: {
    position: 'relative',
    fontFamily: 'Helvetica',
    fontSize: fontSize(8),
    flexDirection: 'row',
    alignSelf: 'flex-start',
    flexWrap: 'wrap',
    maxWidth: '75%',
    paddingLeft: 24
  },
  priceField: {
    fontFamily: 'Helvetica',
    fontSize: fontSize(8),
    flex: '0 1 auto',
    marginLeft: 'auto'
  },
  productWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 6,
    paddingLeft: 24
  },
  sectionWrapper: {
    display: 'flex',
    flexDirection: 'row'
  },
  lineItemIcon: {
    position: 'absolute',
    fontFamily: 'Helvetica',
    fontSize: fontSize(8),
    left: 0
  },
  boldText: {
    fontFamily: 'Helvetica-Bold'
  }
});

const TaskList = props => {
  const { field, options } = props;
  let tasks = field.value || [];
  let shouldShowLineItems;
  let shouldShowPricingForLineItems;
  let shouldShowParts;
  let shouldShowPricingForMaterials;
  const { detailsShownToCustomer, width = 400 } = options;

  tasks = _.sortBy(tasks, 'sortOrder');

  if (detailsShownToCustomer && !_.isEmpty(detailsShownToCustomer)) {
    shouldShowLineItems = [
      'LineItemsWithPrices',
      'LineItemsWithGrandTotalOnly',
      'TimeAndMaterialNoTotals',
      'TimeAndMaterialWithLineItemPrice',
      'TimeAndMaterialWithMaterialPrice',
      'AllItems'
    ].includes(detailsShownToCustomer);
    shouldShowPricingForLineItems = [
      'LineItemsWithPrices',
      'TimeAndMaterialWithLineItemPrice',
      'AllItems'
    ].includes(detailsShownToCustomer);
    shouldShowParts = [
      'TimeAndMaterialNoTotals',
      'TimeAndMaterialWithLineItemPrice',
      'TimeAndMaterialWithMaterialPrice',
      'AllItems'
    ].includes(detailsShownToCustomer);
    shouldShowPricingForMaterials = ['TimeAndMaterialWithMaterialPrice', 'AllItems'].includes(
      detailsShownToCustomer
    );
  } else {
    shouldShowLineItems = true;
  }

  // Set the task's unit price based on its existance

  const formattingPrice = task => {
    let unitPrice = 0;
    if (task && !_.isEmpty(task)) {
      if (task?.unitPrice) {
        // If the task has its unit price, then sets the unit price to show
        unitPrice = task?.unitPrice;
        return `$${unitPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
      }
      if (task?.products?.items && !_.isEmpty(task.products.items)) {
        // If the task has not its unit price, then set the total of the line items
        //  as a unit price to show
        unitPrice = task.products.items
          .map(item => item.amount)
          .reduce((prev, curr) => prev + curr, 0);
        return `$${unitPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
      }
      // If the task has not its unit price and the line items as well, then set
      // the 0 as a unit price to show
      return `$${unitPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    }
    return '-';
  };

  let title = '';
  if (options) title = options.label;

  return (
    <View style={{ width, flexDirection: 'column', alignSelf: 'flex-start' }}>
      {title && <Text style={styles.sectionTitle}>{title}</Text>}
      {shouldShowLineItems &&
        tasks &&
        tasks.map((task, index) => (
          <View style={styles.taskWrapper}>
            <View key={task.id} style={styles.sectionWrapper}>
              <Text style={styles.lineItemIcon}>{index + 1}.</Text>
              <Text style={{ ...styles.sectionField, ...styles.boldText }}>{task.name}</Text>
              {shouldShowPricingForLineItems && (
                <Text style={{ ...styles.priceField, ...styles.boldText }}>
                  {formattingPrice(task)}
                </Text>
              )}
            </View>
            {task.description !== '' && (
              <Text style={{ ...styles.sectionField, ...styles.taskDescription }}>
                {task.description}
              </Text>
            )}
            <View style={styles.productWrapper}>
              {shouldShowParts &&
                task?.products?.items &&
                task.products.items.map(product => (
                  <View key={product.id} style={styles.sectionWrapper}>
                    <Text style={styles.lineItemIcon}> - </Text>
                    <Text style={{ ...styles.sectionField, paddingLeft: 12 }}>{product.name}</Text>
                    {shouldShowPricingForMaterials && (
                      <Text style={styles.priceField}>
                        {`$${product?.amount?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}
                      </Text>
                    )}
                  </View>
                ))}
            </View>
          </View>
        ))}
    </View>
  );
};

TaskList.propTypes = {
  field: PropTypes.arrayOf(PropTypes.object).isRequired,
  options: PropTypes.shape({
    label: PropTypes.string,
    key: PropTypes.string
  }).isRequired
};

export default TaskList;
