const summaryFormLayout = {
  fields: {},
  layouts: {
    default: {
      options: {
        direction: 'row'
      },
      contents: [
        {
          options: {
            direction: 'column'
          },
          contents: [
            {
              options: {
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'FieldWithLabel',
                    edit: 'TextInput'
                  },
                  source: 'summary',
                  options: {
                    label: 'Note',
                    isRequired: true,
                    lines: 3,
                    linesMax: 9
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      summary: {
        type: 'string'
      }
    },
    required: ['summary']
  },
  validationErrors: {
    summary: {
      required: 'Summary is required'
    }
  }
};

export default summaryFormLayout;
