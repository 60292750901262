import { noop, omit } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import useEmployees from 'customHooks/useEmployees';
import PreferredTechniciansForm from 'components/PreferredTechniciansForm';
import PreferredTechniciansSidebarView from './PreferredTechniciansSidebarView';
import PreferredTechniciansModal from './PreferredTechniciansModal';
import PreferredTechniciansButton from './PreferredTechniciansButton';
import { useCrews, getDepartments } from '../../helpers';
import { updateServiceAgreement } from '../../service';

const PreferredTechnicians = ({ field, form, options }) => {
  const { canEdit, onUpdatePreferredTechnicians } = options;
  const { departmentId, primaryTechnicianId, additionalTechnicianIds, crewId } = field.value || {};
  const [preferredTechnicians, setPreferredTechnicians] = useState({
    departmentId,
    primaryTechnicianId,
    additionalTechnicianIds,
    crewId
  });
  const crews = useCrews();
  const departments = getDepartments();
  const [technicians] = useEmployees({
    includeDepartments: true,
    filter: { isActive: { eq: true }, isTech: { eq: true } }
  });

  const renderPreferredTechniciansForm = ({ setFormService, onComplete }) => (
    <PreferredTechniciansForm
      requiredFields={{ departmentId: true, primaryTechnicianId: true }}
      preferredTechnicians={preferredTechnicians}
      departments={departments}
      crews={crews}
      technicians={technicians}
      onCreateService={service => setFormService(service)}
      onComplete={onComplete}
      onChange={noop}
      hideExtraTechNumber
    />
  );

  const renderOpenButton = ({ openModal }) => {
    return (
      <PreferredTechniciansButton onClick={openModal} preferredTechnicians={preferredTechnicians} />
    );
  };

  const handleUpdatePreferredTechnicians = async newPreferredTechnicians => {
    const { serviceAgreementId, snackbar } = form.values;
    const result = await updateServiceAgreement(
      {
        id: serviceAgreementId,
        preferredTechnicians: newPreferredTechnicians
      },
      snackbar
    );

    if (result?.preferredTechnicians) {
      const updatedPreferredTechnicians = omit(result.preferredTechnicians, '__typename');
      setPreferredTechnicians(updatedPreferredTechnicians);
      onUpdatePreferredTechnicians(updatedPreferredTechnicians);
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <PreferredTechniciansSidebarView
        preferredTechnicians={preferredTechnicians}
        departments={departments}
        crews={crews}
        technicians={technicians}
      />
      {canEdit && (
        <PreferredTechniciansModal
          preferredTechnicians={preferredTechnicians}
          onAction={handleUpdatePreferredTechnicians}
          renderContent={renderPreferredTechniciansForm}
          renderOpenButton={renderOpenButton}
        />
      )}
    </Box>
  );
};

PreferredTechnicians.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.shape({
      departmentId: PropTypes.string,
      crewId: PropTypes.string,
      primaryTechnicianId: PropTypes.string,
      additionalTechnicianIds: PropTypes.array,
      canEdit: PropTypes.bool
    })
  }).isRequired,
  form: PropTypes.shape({
    values: PropTypes.shape({
      serviceAgreementId: PropTypes.string,
      snackbar: PropTypes.func
    })
  }).isRequired,
  options: PropTypes.shape({
    canEdit: PropTypes.bool.isRequired,
    onUpdatePreferredTechnicians: PropTypes.func.isRequired
  }).isRequired
};

export default PreferredTechnicians;
