import { sortBy } from 'lodash';

import { roundCurrency } from '@buildhero/math';
import { convertCurrencyStringToFloat } from 'utils';
import { convertForMathLib } from 'utils/mathLibrary';

import { LABOR_CONSTANTS } from './LaborModal.constants';

export const calculateQuantity = ({ modifiedFormValues, hourlyRateType }) =>
  modifiedFormValues[hourlyRateType].reduce(
    (acc, b) => (typeof b?.hours === 'number' ? b.hours + acc : acc),
    null
  );

export const getAvg = ({ sum, quantity }) => sum / quantity;

export const calculateAverageUnitAmount = ({ quantity, sum, costQuantity }) => {
  const unitAvg = getAvg({
    sum,
    quantity
  });
  return Number.isNaN(unitAvg) || quantity === 0 || costQuantity === null
    ? null
    : convertForMathLib(roundCurrency, unitAvg);
};

export const setNewBillingRates = ({ billingRates, newAvgUnitPrice, hours }) =>
  billingRates?.map(rate =>
    rate.hourType === 'Regular'
      ? { ...rate, unitPrice: newAvgUnitPrice, hours }
      : { ...rate, unitPrice: newAvgUnitPrice, hours: 0 }
  );

export const formatLaborLineItemsForDisplay = (laborLineItems, laborRates) =>
  laborLineItems?.map(laborItem => {
    const billingItems = laborItem?.quoteLineLaborBillingHours?.items
      ? laborItem?.quoteLineLaborBillingHours?.items
      : laborItem?.quoteLineLaborBillingHours;
    const billingHours = billingItems?.map(billingItem => ({
      totalPrice: billingItem.hours * billingItem.unitPrice,
      hours: billingItem.hours,
      billingHourTypeId: billingItem.billingHourTypeId,
      unitPrice: billingItem.unitPrice,
      hourType: billingItem.billingHourType?.hourType,
      sortOrder: billingItem.billingHourType?.sortOrder,
      id: billingItem.id,
      productId: billingItem.productId
    }));
    const payrollItems = laborItem?.quoteLineLaborPayrollHours?.items
      ? laborItem?.quoteLineLaborPayrollHours?.items
      : laborItem?.quoteLineLaborPayrollHours;
    const payrollHours = payrollItems?.map(payrollItem => ({
      totalCost: payrollItem.hours * payrollItem.unitCost,
      hours: payrollItem.hours || 0,
      payrollHourTypeId: payrollItem.payrollHourTypeId,
      unitCost: payrollItem.unitCost,
      hourType: payrollItem.payrollHourType?.hourType,
      sortOrder: payrollItem.payrollHourType?.sortOrder,
      id: payrollItem.id,
      costCodeId: payrollItem.costCodeId,
      jobCostTypeId: payrollItem.jobCostTypeId
    }));

    return {
      ...laborItem,
      markupValue: laborItem.markup,
      name: laborRates?.find(elem => elem.id === laborItem.labourTypeId)?.name,
      quoteLineLaborBillingHours: sortBy(billingHours, 'sortOrder'),
      quoteLineLaborPayrollHours: sortBy(payrollHours, 'sortOrder'),
      subtotal: laborItem.subtotal,
      totalCost: laborItem.totalCost,
      totalPrice: laborItem.totalPrice,
      unitPrice: laborItem.unitPrice,
      unitCost: laborItem.unitCost
    };
  });

export const formatLaborLineItemBeforeSaving = laborItem => {
  const billingHours = laborItem[LABOR_CONSTANTS.billingRates].map(rate => ({
    billingHourTypeId: rate.billingHourTypeId,
    hours: rate.hours ? rate.hours : 0,
    unitPrice: rate.unitPrice,
    id: rate.id,
    productId: rate.productId
  }));

  const payrollHours = laborItem[LABOR_CONSTANTS.payrollCosts].map(rate => ({
    hours: rate.hours ? rate.hours : 0,
    payrollHourTypeId: rate.payrollHourTypeId,
    unitCost: rate.unitCost,
    id: rate.id,
    jobCostTypeId: rate.jobCostTypeId,
    costCodeId: rate.costCodeId
  }));

  const laborLineItem = {
    description: laborItem?.description,
    id: laborItem?.id,
    isTaxable: Boolean(laborItem.isTaxable),
    labourTypeId: laborItem.labourTypeId,
    markup: Number(laborItem.markup),
    quantity: laborItem.quantity,
    quoteLineLaborBillingHours: billingHours,
    quoteLineLaborPayrollHours: payrollHours,
    subtotal: laborItem.totalPrice,
    totalCost: laborItem.totalCost,
    totalPrice: laborItem.totalPrice,
    unitCost:
      typeof laborItem.unitCost === 'string'
        ? convertCurrencyStringToFloat(laborItem.unitCost)
        : laborItem.unitCost,
    unitPrice:
      typeof laborItem.unitPrice === 'string'
        ? convertCurrencyStringToFloat(laborItem.unitPrice)
        : laborItem.unitPrice
  };

  return laborLineItem;
};
