import { actions } from 'xstate';
import * as AttachmentActions from './actions/attachmentActions';
import * as CustomerSignatureActions from './actions/customerSignatureActions';
import * as DiscountActions from './actions/discountActions';
import * as FeeActions from './actions/feeActions';
import * as InventoryActions from './actions/inventoryActions';
import * as LaborLineActions from './actions/laborLineActions';
import * as PurchaseOrderActions from './actions/purchaseOrderActions';
import * as ReviewReportActions from './actions/reviewReportActions';
import * as ReviewSummaryActions from './actions/reviewSummaryActions';

const { raise } = actions;

const queryErrorNotification = (context, event) => {
  const { snackbarOn } = context.reduxActions;
  const error = event.data;
  const { Logger } = context.services;
  Logger.error(error);
  let message;
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    message = error.graphQLErrors[0].message;
  } else if (error.message) {
    message = error.message;
  } else {
    message = 'Unable to query data, please try again later';
  }
  snackbarOn('error', message);
};

const saveErrorNotification = (context, event) => {
  const { snackbarOn } = context.reduxActions;
  const error = event.data;
  const { Logger } = context.services;
  Logger.error(error);
  let message;
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    message = error.graphQLErrors[0].message;
  } else if (error.message) {
    message = error.message;
  } else {
    message = 'Unable to save data, please try again later';
  }
  snackbarOn('error', message);
};

const deleteErrorNotification = (context, event) => {
  const { snackbarOn } = context.reduxActions;
  const error = event.data;
  const { Logger } = context.services;
  Logger.error(error);
  let message;
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    message = error.graphQLErrors[0].message;
  } else if (error.message) {
    message = error.message;
  } else {
    message = 'Unable to delete, please try again later';
  }
  snackbarOn('error', message);
};

const updateSuccessMessage = (context, event) => {
  const { snackbarOn } = context.reduxActions;
  snackbarOn('success', 'Successfully updated the review report');
};

const addSuccessMessage = (context, event) => {
  const { snackbarOn } = context.reduxActions;
  snackbarOn('success', 'Successfully saved');
};

const editSuccessMessage = (context, event) => {
  const { snackbarOn } = context.reduxActions;
  snackbarOn('success', 'Successfully updated');
};

const deleteSuccessMessage = (context, event) => {
  const { snackbarOn } = context.reduxActions;
  snackbarOn('success', 'Successfully deleted');
};

export const customActions = {
  fn_backToLoaded: raise('BACK_TO_LOADED'),
  fn_saveLaborLine: LaborLineActions.saveLaborLine,
  fn_editLaborLine: LaborLineActions.editLaborLine,
  fn_fetchReviewReportDetails: ReviewReportActions.fetchReviewReportDetails,
  fn_saveInventory: InventoryActions.saveInventory,
  fn_saveReviewSummary: ReviewSummaryActions.saveReviewSummary,
  fn_saveAttachment: AttachmentActions.saveAttachment,
  fn_saveDiscount: DiscountActions.saveDiscount,
  fn_saveFee: FeeActions.saveFee,
  fn_editReviewSummary: ReviewSummaryActions.editReviewSummary,
  fn_editDiscount: DiscountActions.editDiscount,
  fn_editFee: FeeActions.editFee,
  fn_editInventory: InventoryActions.editInventory,
  fn_deleteReviewSummary: ReviewSummaryActions.deleteReviewSummary,
  fn_deleteInventoryPart: InventoryActions.deleteInventoryPart,
  fn_deleteLaborLine: LaborLineActions.deleteLaborLine,
  fn_deleteDiscount: DiscountActions.deleteDiscount,
  fn_deleteFee: FeeActions.deleteFee,
  fn_addPurchaseOrder: PurchaseOrderActions.addPurchaseOrder,
  fn_addPurchaseOrderLineItem: PurchaseOrderActions.addPurchaseOrderLineItem,
  fn_updateReviewReport: ReviewReportActions.updateReviewReportFn,
  fn_addBill: ReviewReportActions.addBillFn,
  fn_editBillLineItem: ReviewReportActions.editBillLineItem,
  fn_deleteBillLine: ReviewReportActions.deleteBillLine,
  fn_editAttachment: AttachmentActions.editAttachment,
  fn_deleteAttachment: AttachmentActions.deleteAttachment,
  fn_includeReviewSummary: ReviewSummaryActions.includeReviewSummary,
  fn_includeAttachment: AttachmentActions.includeAttachment,
  fn_includeCustomerSignature: CustomerSignatureActions.includeCustomerSignature,
  fn_includeInventory: InventoryActions.includeInventory,
  fn_includeLaborLine: LaborLineActions.includeLaborLine,
  fn_includeDiscount: DiscountActions.includeDiscount,
  fn_includeFee: FeeActions.includeFee,
  fn_includePurchaseOrderLine: PurchaseOrderActions.includePurchaseOrderLine,
  fn_editPurchaseOrder: PurchaseOrderActions.editPurchaseOrder,
  fn_deletePurchaseOrder: PurchaseOrderActions.deletePurchaseOrder,
  fn_editPurchaseOrderLine: PurchaseOrderActions.editPurchaseOrderLine,
  fn_deletePurchaseOrderLine: PurchaseOrderActions.deletePurchaseOrderLine,
  fn_queryErrorNotification: queryErrorNotification,
  fn_saveErrorNotification: saveErrorNotification,
  fn_deleteErrorNotification: deleteErrorNotification,
  fn_updateSuccessMessage: updateSuccessMessage,
  fn_addSuccessMessage: addSuccessMessage,
  fn_editSuccessMessage: editSuccessMessage,
  fn_deleteSuccessMessage: deleteSuccessMessage
};

export const guards = {};

export default customActions;
