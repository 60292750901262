import { PermissionConstants } from 'utils/AppConstants';

export const productItemsRows = [
  {
    id: 'name',
    numeric: false,
    type: 'text',
    label: 'Name'
  },
  {
    id: 'description',
    numeric: false,
    type: 'text',
    label: 'Description'
  },
  {
    id: 'unitCost',
    numeric: false,
    type: 'costMarkup',
    isCustom: true,
    label: 'Unit cost',
    colSpan: 3,
    caslKey: [
      PermissionConstants.DATA_VIEW_PRICE_DATA,
      PermissionConstants.DATA_VIEW_COST_DATA,
      PermissionConstants.DATA_SHOW_FINANCIAL_DATA
    ],
    caslAction: 'allow'
  },
  {
    id: 'markup',
    disableFilter: true,
    numeric: false,
    isCustom: true,
    label: 'Markup',
    colSpan: 0,
    caslKey: [
      PermissionConstants.DATA_VIEW_PRICE_DATA,
      PermissionConstants.DATA_VIEW_COST_DATA,
      PermissionConstants.DATA_SHOW_FINANCIAL_DATA
    ],
    caslAction: 'allow'
  },
  {
    id: 'unitPrice',
    numeric: false,
    isCustom: true,
    label: 'Unit Price',
    colSpan: 0,
    caslKey: [
      PermissionConstants.DATA_VIEW_PRICE_DATA,
      PermissionConstants.DATA_VIEW_COST_DATA,
      PermissionConstants.DATA_SHOW_FINANCIAL_DATA
    ],
    caslAction: 'allow'
  },
  {
    id: 'taxable',
    numeric: false,
    type: 'checkbox',
    isCustom: true,
    label: 'Taxable'
  },
  {
    id: 'quantity',
    numeric: false,
    type: 'input',
    isCustom: true,
    label: 'Quantity'
  },
  {
    id: 'delete',
    numeric: false,
    type: 'deleteButton',
    isCustom: true,
    label: ''
  }
];

export const viewProductItemsRows = [
  {
    id: 'name',
    numeric: false,
    type: 'text',
    label: 'Name'
  },
  {
    id: 'description',
    numeric: false,
    type: 'text',
    label: 'Description',
    disableFilter: true
  },
  {
    id: 'unitCost',
    numeric: true,
    type: 'currency',
    label: 'Unit cost',
    disableFilter: true,
    caslKey: [
      PermissionConstants.DATA_VIEW_PRICE_DATA,
      PermissionConstants.DATA_VIEW_COST_DATA,
      PermissionConstants.DATA_SHOW_FINANCIAL_DATA
    ],
    caslAction: 'allow'
  },
  {
    id: 'markupValue',
    numeric: true,
    type: 'percentage',
    label: 'Markup',
    disableFilter: true,
    caslKey: [
      PermissionConstants.DATA_VIEW_PRICE_DATA,
      PermissionConstants.DATA_VIEW_COST_DATA,
      PermissionConstants.DATA_SHOW_FINANCIAL_DATA
    ],
    caslAction: 'allow'
  },
  {
    id: 'unitPrice',
    numeric: true,
    type: 'currency',
    label: 'Unit price',
    disableFilter: true,
    caslKey: [
      PermissionConstants.DATA_VIEW_PRICE_DATA,
      PermissionConstants.DATA_VIEW_COST_DATA,
      PermissionConstants.DATA_SHOW_FINANCIAL_DATA
    ],
    caslAction: 'allow'
  },
  {
    id: 'taxable',
    numeric: false,
    type: 'boolean',
    label: 'Taxable',
    disableFilter: true
  },
  {
    id: 'quantity',
    numeric: true,
    label: 'Quantity',
    disableFilter: true
  }
];

export default productItemsRows;
