import React from 'react';
import ContactIcon from '@material-ui/icons/Contacts';
import CustomerIcon from '@material-ui/icons/People';
import ApplicationIcon from '@material-ui/icons/Tab';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SettingsIcon from '@material-ui/icons/Settings';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PersonIcon from '@material-ui/icons/Person';
import StoreIcon from '@material-ui/icons/Store';
import BuildIcon from '@material-ui/icons/Build';
import FormatListIcon from '@material-ui/icons/FormatListBulleted';
import AttachmentIcon from '@material-ui/icons/Attachment';
import AssignmentLate from '@material-ui/icons/AssignmentLate';
import EventIcon from '@material-ui/icons/Event';
import PartsIcon from '@material-ui/icons/Toys';
import Pause from '@material-ui/icons/Pause';
import History from '@material-ui/icons/History';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import PieChartIcon from '@material-ui/icons/PieChart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import BarChartIcon from '@material-ui/icons/BarChart';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';

const IconElement = props => {
  const style = {
    fontSize: 22,
    ...props.style
  };

  const { icon } = props;
  const iconArray = {
    contactIcon: <ContactIcon style={style} />,
    applicationIcon: <ApplicationIcon style={style} />,
    customerIcon: <CustomerIcon style={style} />,
    localShippingIcon: <LocalShippingIcon style={style} />,
    settingsIcon: <SettingsIcon style={style} />,
    deviceHubIcon: <DeviceHubIcon style={style} />,
    permIdentityIcon: <PermIdentityIcon style={style} />,
    personIcon: <PersonIcon style={style} />,
    storeIcon: <StoreIcon style={style} />,
    buildIcon: <BuildIcon style={style} />,
    formatListIcon: <FormatListIcon style={style} />,
    attachmentIcon: <AttachmentIcon style={style} />,
    unassigned: <AssignmentLate style={style} />,
    onHold: <Pause style={style} />,
    eventIcon: <EventIcon style={style} />,
    partsIcon: <PartsIcon style={style} />,
    history: <History style={style} />,
    info: <InfoOutlined style={style} />,
    invoiceIcon: <DescriptionIcon style={style} />,
    pieChartIcon: <PieChartIcon style={style} />,
    trendingUpIcon: <TrendingUpIcon style={style} />,
    barChartIcon: <BarChartIcon style={style} />,
    assignmentIcon: <AssignmentIcon style={style} />,
    businessCenterIcon: <BusinessCenterIcon style={style} />,
    ChangeHistoryIcon: <ChangeHistoryIcon style={style} />
  };
  return iconArray[icon];
};

export default IconElement;
