import gql from 'graphql-tag';

const createTenant = gql`
  mutation ProvisionTenant(
    $companyName: String!
    $accountName: String!
    $ownerName: String!
    $tier: String!
    $email: String!
    $status: String!
    $userName: String!
    $firstName: String!
    $lastName: String!
  ) {
    tenantProvision(
      companyName: $companyName
      accountName: $accountName
      ownerName: $ownerName
      tier: $tier
      email: $email
      status: $status
      userName: $userName
      firstName: $firstName
      lastName: $lastName
    ) {
      id
    }
  }
`;

export default createTenant;
