import { useMemo } from 'react';
import { useTheme } from '@material-ui/core';
import { MUIFormComponentNames } from '@buildhero/sergeant';
import { Modes } from '@dispatch/components/Drawer/components/NonBillableEventForm/NonBillableEventForm.constants';
import { getFormValidation, getFormValidationErrors } from '../../NonVisitEvents.helpers';

export const useNonBillableEventFormConfig = ({
  disabled,
  disableScheduling,
  enableDepartment,
  departments = [],
  departmentsLoading,
  payrollHourTypesLoading,
  payrollHourTypes = [],
  disablePayrollHourType,
  mode
}) => {
  const theme = useTheme();
  return useMemo(() => {
    const validation = getFormValidation();
    const validationErrors = getFormValidationErrors();

    const row = contents => ({
      options: {
        marginBottom: theme.spacing(2)
      },
      contents
    });

    const EVENT_NAME_FIELD = [
      {
        component: MUIFormComponentNames.TextInput,
        source: 'eventName',
        options: {
          isRequired: true,
          disabled,
          label: 'EVENT NAME'
        }
    }];

    const DESCRIPTION_FIELD = [
      {
        component: MUIFormComponentNames.TextInput,
        source: 'description',
        options: {
          disabled,
          label: 'DESCRIPTION',
          lines: 4,
          maxTextLen: 80
        }
      }
    ];

    const DETAILS_SECTION_CONTENT = [row(EVENT_NAME_FIELD), row(DESCRIPTION_FIELD)];

    if (enableDepartment) {
      const departmentField = 'departmentId';
      const DEPARTMENT_FIELD = [
        {
          component: MUIFormComponentNames.SelectInput,
          source: departmentField,
          options: {
            isRequired: true,
            disabled: disabled || departmentsLoading,
            label: 'DEPARTMENT',
            placeholder: '-',
            valueSet: departments.map(({ id, name }) => ({ value: id, label: name })),
            isSearchable: true,
            enableSort: false
          }
        }
      ];

      DETAILS_SECTION_CONTENT.push(row(DEPARTMENT_FIELD));

      validation.properties[departmentField] = {
        type: 'string',
        nullable: false
      };
      validation.required.push(departmentField);
      validationErrors[departmentField] = {
        required: 'Department is required',
        type: 'Value must be a string'
      };
    }

    const PAYROLL_TYPES_FIELD = [
      {
        component: MUIFormComponentNames.SelectInput,
        source: 'payrollHourTypeId',
        options: {
          disabled:
            disabled || payrollHourTypesLoading || (mode === Modes.EDIT && disablePayrollHourType),
          label: 'PAYROLL HOUR TYPE',
          placeholder: '-',
          valueSet: payrollHourTypes.map(({ id, hourType }) => ({ value: id, label: hourType })),
          isSearchable: true,
          enableSort: false
        }
      }
    ];

    DETAILS_SECTION_CONTENT.push(row(PAYROLL_TYPES_FIELD));

    const FROM_FIELD = [
      {
        component: MUIFormComponentNames.LabelControl,
        source: 'FROM',
        options: {
          required: true
        }
      },
      {
        component: 'DateTimeInput',
        source: 'from',
        options: {
          disabled,
          disableDateEditing: disableScheduling
        }
      }
    ];

    const TO_FIELD = [
      {
        component: MUIFormComponentNames.LabelControl,
        source: 'TO',
        options: {
          required: true
        }
      },
      {
        component: 'DateTimeInput',
        source: 'to',
        options: {
          disabled,
          disableDateEditing: disableScheduling
        }
      }
    ];

    const SCHEDULING_SECTION_CONTENT = [row(FROM_FIELD), row(TO_FIELD)];

    return {
      fields: {},
      layouts: {
        default: {
          contents: [
            {
              component: 'AccordionSection',
              options: {
                heading: 'Details',
                expanded: true
              },
              contents: DETAILS_SECTION_CONTENT
            },
            {
              component: 'AccordionSection',
              options: {
                heading: 'Scheduling',
                expanded: true
              },
              contents: SCHEDULING_SECTION_CONTENT
            }
          ]
        }
      },
      validation,
      validationErrors
    };
  }, [
    disabled,
    disableScheduling,
    disablePayrollHourType,
    enableDepartment,
    departments,
    departmentsLoading,
    payrollHourTypes,
    payrollHourTypesLoading,
    theme,
    mode
  ]);
};
