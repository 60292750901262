const simpleRowActionButtons = {
  edit: {
    label: 'Edit',
    icon: 'Edit',
    caslAction: 'update',
    caslKey: ''
  }
};

export default simpleRowActionButtons;
