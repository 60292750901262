import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import labels from 'meta/Authorization/labels';
import SignInPage from './SignIn';
import ForgotPasswordPage from './ForgotPassword';

export class Authenticator extends Component {
  async componentDidMount() {
    const { auth } = this.props;

    try {
      await auth.checkAuthentication();
      this.props.history.push('/');
    } catch (error) {
      // console.error(error);
    }
  }

  render() {
    const { type } = this.props;

    switch (type) {
      case 'signin':
        return <SignInPage i18nMessages={labels} auth={this.props.auth} />;

      case 'forgotPassword':
        return <ForgotPasswordPage i18nMessages={labels} auth={this.props.auth} />;

      default:
        return <></>;
    }
  }
}

Authenticator.propTypes = {
  type: PropTypes.string.isRequired
};

export default withRouter(Authenticator);
