import gql from 'graphql-tag';

const getInvoiceInfoFromJob = gql`
  query getJob($partitionKey: String!, $sortKey: String!) {
    getJob(partitionKey: $partitionKey, sortKey: $sortKey) {
      priceBookId
      billingCustomer(entityConnection: "Customer") {
        companyAddresses(
          entityConnection: "CompanyAddress"
          filter: { addressType: { eq: "billingAddress" } }
        ) {
          items {
            billTo
            addressLine1
            addressLine2
            state
            city
            zipcode
            addressType
          }
        }
        taxRate(entityConnection: "TaxRate") {
          taxRate
          accountingRefId
        }
        customerName
      }
      departments(entityConnection: "JobDepartment") {
        items {
          id
          mappedEntity {
            ... on Department {
              id
              tagName
            }
          }
        }
      }
      customerProperty(entityConnection: "CustomerProperty") {
        companyAddresses(entityConnection: "CompanyAddress") {
          items {
            addressLine1
            addressLine2
            state
            city
            zipcode
            addressType
          }
        }
        taxRate(entityConnection: "TaxRate") {
          taxRate
          accountingRefId
        }
        isTaxable
      }
    }
  }
`;

export default getInvoiceInfoFromJob;
