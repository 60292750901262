import StorageService from 'services/StorageService';
import { getUniqueName } from 'utils';

const handleFileUpload = async (tenantId, fileInput, uploadProgressCallback) => {
  // FileInput from Form library returns: [{ key, label, file, fileUrl }]
  const storageService = new StorageService();
  const uniqueName = getUniqueName(tenantId, fileInput.file.name);
  return storageService.uploadFile(fileInput.file, uniqueName, uploadProgressCallback, null);
};

export const fileInputToAttachment = async (
  tenantId,
  data,
  type,
  uploadProgressCallback,
  flags = {}
) => {
  const fileInput = data.newFiles?.[0];
  return {
    id: data?.id,
    version: data?.version,
    customFileName: data.customFileName,
    comment: data.comment,
    hideFromTechniciansOnMobile: !data.shareWithTechniciansOnMobile,
    fileName: fileInput?.label,
    type: type || '',
    isUploaded: true,
    fileUrl: fileInput?.file?.name
      ? await handleFileUpload(tenantId, fileInput, uploadProgressCallback)
      : data.fileUrl,
    fileSize: fileInput?.file?.size,
    isInternalFile: data.internalFile
  };
};

const cloudinaryDomain = 'res.cloudinary.com';

export const attachmentToFileInput = async (attachmentItem, fileType) => {
  if (!attachmentItem) return attachmentItem;
  const storageService = new StorageService();
  // on editing the same item, the url will already be there
  const s3FileUrl = attachmentItem?.fileUrl?.includes(cloudinaryDomain)
    ? attachmentItem.fileUrl
    : await storageService.getFile(attachmentItem.fileUrl);
  return {
    newFiles: [
      {
        key: 0,
        label: attachmentItem.fileName,
        file: {
          // default to image
          type: fileType ?? 'image'
        },
        fileUrl: s3FileUrl
      }
    ],
    ...attachmentItem
  };
};
