/* eslint-disable no-console */

import * as Sentry from '@sentry/browser';

const inTest = process.env.NODE_ENV === 'test';

export const sentryMessage = (message, extra) => {
  Sentry.withScope(scope => {
    scope.setExtras(extra);
    Sentry.captureMessage(message);
  });
};

export const sentryException = (error, extra) => {
  Sentry.withScope(scope => {
    scope.setExtras(extra);
    Sentry.captureException(error);
  });
};

export const Logger = {
  info: (...msg) => {
    const [error, ...extra] = msg;
    const localMsg = `INFO :: ${new Date()} :: ${JSON.stringify(error, 0, 2)}`;
    console.info(localMsg, ...extra);
  },
  warn: (...msg) => {
    if (inTest) return;

    const [error, ...extra] = msg;
    sentryException(error, extra);
    const localMsg = `WARN :: ${new Date()} :: ${JSON.stringify(error, 0, 2)}`;
    console.warn('WARN NO BUGSNAG!', localMsg, ...extra);
  },
  debug: (...msg) => {
    const localMsg = `DEBUG :: ${new Date()} :: `;
    console.debug(localMsg, ...msg);
  },
  error: (...msg) => {
    const [error, ...extra] = msg;
    sentryException(error, extra);

    const localMsg = `ERROR :: ${new Date()}}`;
    console.error(localMsg, ...msg);
  },
  logObject: (...msg) => console.debug(...msg)
};

export default {};
