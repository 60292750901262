const taskTitle = (
  settingsJSON,
  SettingConstants,
  taskName,
  taskPricing,
  styles,
  fontFamilyStyle
) =>
  settingsJSON.Tasks[SettingConstants.SHOW_TASK_TITLE] ||
  settingsJSON.Tasks[SettingConstants.SHOW_TASK_PRICING]
    ? `<tr>
    <td style="${styles}width:65%;border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;" colspan="3" class="restricted">
      <span fontfamily=${fontFamilyStyle} contenteditable="false"><strong>${taskName}</strong></span>
    </td>
    <td style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;" colspan="2" class="restricted">
      <span fontfamily=${fontFamilyStyle} contenteditable="false" style="text-align:right;">${taskPricing}</span>
    </td>
  </tr>`
    : '';

export default taskTitle;
