import gql from 'graphql-tag';

const addLaborLineItemToReviewReport = gql`
  mutation AddLaborLineItemToReviewReport(
    $partitionKey: String
    $data: AddLaborLineItemToReviewReportInput!
  ) {
    addLaborLineItemToReviewReport(partitionKey: $partitionKey, data: $data) {
      id
      sortKey
      itemName
      description
      taxable
      departmentName
      accountingRefIdOfClass
      billableTimeInHours
      hourlyCharge
      includeInInvoice
      version
    }
  }
`;

export default addLaborLineItemToReviewReport;
