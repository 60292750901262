import gql from 'graphql-tag';

const addNotesToTimeCardVisit = gql`
  mutation AddNotesToTimeCardVisit($partitionKey: String!, $data: AddNotesToTimeCardVisitInput!) {
    addNotesToTimeCardVisit(partitionKey: $partitionKey, data: $data) {
      id
    }
  }
`;

export default addNotesToTimeCardVisit;
