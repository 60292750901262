import { convertStringToFloat, getBooleanValue } from 'utils';

const constructPurchaseOrderPayload = ({
  receiptNumber,
  tax,
  vendorName,
  poNumber,
  description,
  dateOfPurchase,
  itemName,
  departmentName,
  priceBookEntryId,
  accountingRefId
}) => ({
  receiptNumber,
  tax,
  vendorName,
  poNumber,
  dateOfPurchase,
  description,
  itemName,
  departmentName,
  priceBookEntryId,
  accountingRefId
});

const constructPurchaseOrderLinePayload = ({
  description,
  itemName,
  departmentName,
  priceBookEntryId,
  accountingRefId,
  accountingRefIdOfClass,
  costCodeId,
  jobCostTypeId,
  revenueTypeId
}) => ({
  description,
  itemName,
  departmentName,
  priceBookEntryId,
  accountingRefId,
  accountingRefIdOfClass,
  costCodeId,
  jobCostTypeId,
  revenueTypeId
});

const updatePurchaseOrderInList = (purchaseOrders, purchaseOrderLine) => {
  const purchaseOrderToUpdate = purchaseOrders.find(
    item => item.id === purchaseOrderLine?.parentId
  );
  const lineItems = purchaseOrderToUpdate?.purchaseOrderLines?.items || [];

  const updatedLineItems = lineItems?.map(lineItem => {
    let localLineItem = lineItem;
    if (localLineItem.id === purchaseOrderLine.id) {
      localLineItem = purchaseOrderLine;
    }
    return localLineItem;
  });
  purchaseOrderToUpdate.purchaseOrderLines.items = updatedLineItems;

  return purchaseOrders;
};

export const addPurchaseOrder = async (context, event) => {
  const { services, id, visit } = context;
  const { reviewReportService } = services;
  const { saveData } = event;
  const formattedData = constructPurchaseOrderPayload(saveData);
  formattedData.dateOfPurchase = `${saveData.dateOfPurchase || ''}`;
  formattedData.totalAmountPreTax = convertStringToFloat(saveData.totalAmountPreTax) || 0.0;
  formattedData.tax = convertStringToFloat(saveData.tax) || 0.0;
  if (!formattedData?.departmentName && visit.departmentName) {
    formattedData.departmentName = visit.departmentName;
  }

  if (saveData?.fileUrl) {
    const imageData = {};
    imageData.imageUrl = saveData.fileUrl;
    imageData.fileName = saveData.customFileName || saveData.fileName;
    formattedData.purchaseOrderReceipts = [{ ...imageData }];
  }
  const purchaseOrderPayload = {
    reviewReportId: id,
    purchaseOrder: { ...formattedData }
  };

  const data = await reviewReportService.addPurchaseOrderToReviewReport(
    context.user.tenantId,
    purchaseOrderPayload
  );

  const { addPurchaseOrderToReviewReport } = data.data;
  const { purchaseOrders } = context;

  // Data Massaging
  const [puchaseOrderReciepts] = addPurchaseOrderToReviewReport?.purchaseOrderReceipts?.items || [];
  if (puchaseOrderReciepts) {
    addPurchaseOrderToReviewReport.fileName = puchaseOrderReciepts.fileName;
    addPurchaseOrderToReviewReport.upload = saveData.upload;
  }

  purchaseOrders.push({ ...addPurchaseOrderToReviewReport, purchaseOrderLines: { items: [] } });

  return { purchaseOrders, modalRecord: {}, modalIndex: '' };
};

export const editPurchaseOrder = async (context, event) => {
  const { services, modalRecord, visit } = context;
  const { reviewReportService } = services;
  const { saveData } = event;
  const { id, version } = modalRecord;

  const formattedData = constructPurchaseOrderPayload(saveData);
  formattedData.dateOfPurchase = `${saveData.dateOfPurchase || ''}`;
  formattedData.totalAmountPreTax = convertStringToFloat(saveData.totalAmountPreTax) || 0.0;
  formattedData.tax = convertStringToFloat(saveData.tax) || 0.0;

  if (!formattedData?.departmentName && visit.departmentName) {
    formattedData.departmentName = visit.departmentName;
  }

  const response = await reviewReportService.updatePurchaseOrder(context.user.tenantId, {
    ...formattedData,
    id,
    version
  });
  const { updatePurchaseOrder } = response.data;
  const { purchaseOrders } = context;

  // Mutation to edit receipts

  let receiptResponse;
  let receiptItem;
  const receipt = saveData?.purchaseOrderReceipts?.items?.[0];
  if (receipt) {
    const receiptData = { id: receipt.id, version: receipt.version, imageUrl: saveData.fileUrl };
    receiptData.fileName = saveData.customFileName || saveData.fileName;
    receiptResponse = await reviewReportService.updatePurchaseOrderReceipt(
      context.user.tenantId,
      receiptData
    );
    receiptItem = receiptResponse?.data?.updatePurchaseOrderReceipt;
  } else if (saveData.fileUrl) {
    // update the image when image is added throught edit purchase order line
    const imageData = { imageUrl: saveData.fileUrl, fileName: saveData.fileName };
    const mutateData = {
      purchaseOrderId: updatePurchaseOrder.id,
      purchaseOrderReceipts: [{ ...imageData }]
    };

    const poReceiptResponse = await reviewReportService.addPurchaseOrderReceipt(
      context.user.tenantId,
      mutateData
    );
    receiptItem = poReceiptResponse?.data?.addPurchaseOrderReceipts?.[0];
  }

  if (receiptItem) {
    updatePurchaseOrder.purchaseOrderReceipts = [{ ...receiptItem }];
    updatePurchaseOrder.fileName = receiptItem.fileName;
    updatePurchaseOrder.fileUrl = receiptItem.imageUrl;
  }

  if (saveData.purchaseOrderLines) {
    updatePurchaseOrder.purchaseOrderLines = saveData.purchaseOrderLines;
  }

  const updatedPurchaseOrderList = purchaseOrders.map(purchaseOrderRecord => {
    let localPurchaseOrder = purchaseOrderRecord;
    if (localPurchaseOrder.id === updatePurchaseOrder.id) {
      localPurchaseOrder = updatePurchaseOrder;
    }
    return localPurchaseOrder;
  });
  return { purchaseOrders: updatedPurchaseOrderList, modalRecord: {}, modalIndex: '' };
};

export const deletePurchaseOrder = async context => {
  const { services, user, modalRecord, purchaseOrders } = context;
  const { reviewReportService } = services;

  // As backends doesnt all deletes in one mutation, we have to validate.
  // Success and error messages are handled by state machine, hence throwing error
  if (modalRecord?.purchaseOrderLines?.items?.length > 0) {
    throw new Error('Please delete items, before deleting purchase order');
  }
  const reciept = modalRecord?.purchaseOrderReceipts?.items?.[0];
  if (reciept?.id) {
    const orderReceiptId = reciept.id;
    await reviewReportService.deletePurchaseOrderReceipt(user.tenantId, orderReceiptId);
  }

  const deletePOResonponse = await reviewReportService.deletePurchaseOrder(
    user.tenantId,
    modalRecord.id
  );

  let modifiedPurchaseOrders = purchaseOrders;
  if (deletePOResonponse?.data?.deletePurchaseOrder) {
    modifiedPurchaseOrders = purchaseOrders.filter(item => item.id !== modalRecord.id);
  }
  return { purchaseOrders: modifiedPurchaseOrders, modalRecord: {}, modalIndex: '' };
};

export const addPurchaseOrderLineItem = async (context, event) => {
  const { services, modalRecord, visit } = context;
  const { reviewReportService } = services;
  const { saveData } = event;
  const formattedData = constructPurchaseOrderLinePayload(saveData);
  formattedData.taxable = getBooleanValue(saveData.taxable);

  if (!formattedData?.departmentName && visit.departmentName) {
    formattedData.departmentName = visit.departmentName;
  }

  formattedData.quantity = convertStringToFloat(saveData.quantity) || 0.0;
  formattedData.markup = convertStringToFloat(saveData.markupValue) || 0.0;
  formattedData.unitCost = convertStringToFloat(saveData.unitCost) || 0.0;
  formattedData.unitPrice = convertStringToFloat(saveData.unitPrice) || 0.0;

  const purchaseOrderLinePayload = {
    purchaseOrderId: modalRecord.id,
    orderLines: [{ ...formattedData }]
  };

  const { data } = await reviewReportService.addReviewReportPurchaseOrderLines(
    context.user.tenantId,
    purchaseOrderLinePayload
  );

  const { addReviewReportPurchaseOrderLines } = data;
  const { purchaseOrders } = context;
  const addedPurchaseOrderLine = addReviewReportPurchaseOrderLines[0];
  const purchaseOrderToUpdate = purchaseOrders.find(
    item => item.id === addedPurchaseOrderLine?.parentId
  );
  const lineItems = purchaseOrderToUpdate?.purchaseOrderLines?.items || [];
  lineItems.push(addedPurchaseOrderLine);

  return { purchaseOrders, modalRecord: {}, modalIndex: '', parentRecord: '' };
};

export const editPurchaseOrderLine = async (context, event) => {
  const { services, visit } = context;
  const { reviewReportService } = services;
  const { saveData } = event;
  const formattedData = constructPurchaseOrderLinePayload(saveData);

  if (!formattedData?.departmentName && visit.departmentName !== '') {
    formattedData.departmentName = visit.departmentName;
  }
  formattedData.id = saveData.id;
  formattedData.version = saveData.version;
  formattedData.taxable = getBooleanValue(saveData.taxable);
  formattedData.quantity = convertStringToFloat(saveData.quantity) || 0.0;
  formattedData.markup = convertStringToFloat(saveData.markupValue) || 0.0;
  formattedData.unitCost = convertStringToFloat(saveData.unitCost) || 0.0;
  formattedData.unitPrice = convertStringToFloat(saveData.unitPrice) || 0.0;
  formattedData.includeInInvoice = getBooleanValue(saveData.includeInInvoice);

  const response = await reviewReportService.updatePurchaseOrderLine(
    context.user.tenantId,
    formattedData
  );
  const { updatePurchaseOrderLine } = response.data;
  const { purchaseOrders } = context;

  return {
    purchaseOrders: updatePurchaseOrderInList(purchaseOrders, updatePurchaseOrderLine),
    modalRecord: {},
    modalIndex: '',
    parentRecord: ''
  };
};

export const deletePurchaseOrderLine = async context => {
  const { services, user, modalRecord, parentOrder } = context;
  const { reviewReportService } = services;

  await reviewReportService.deletePurchaseOrderLine(user.tenantId, modalRecord.id);

  const { purchaseOrders } = context;

  const purchaseOrderToUpdate = purchaseOrders.find(
    purchaseOrder => purchaseOrder.id === parentOrder.id
  );
  // Traverse line items and delete record with id matching
  const purchaseOrderLineIndex = purchaseOrderToUpdate?.purchaseOrderLines?.items?.findIndex(
    purchaseOrderLine => purchaseOrderLine.id === modalRecord.id
  );
  purchaseOrderToUpdate.purchaseOrderLines.items.splice(purchaseOrderLineIndex, 1);
};

/* Code to include a Purchase Order item to invoice from Review Report */
export const includePurchaseOrderLine = async (context, event) => {
  const { services } = context;
  const { reviewReportService } = services;
  const { data, checked } = event;
  const formattedData = (({ id, version }) => ({ id, version }))(data);
  formattedData.includeInInvoice = checked;

  const queryData = await reviewReportService.updatePurchaseOrderLine(
    context.user.tenantId,
    formattedData
  );

  const { updatePurchaseOrderLine } = queryData.data;
  const { purchaseOrders } = context;

  return {
    purchaseOrders: updatePurchaseOrderInList(purchaseOrders, updatePurchaseOrderLine),
    modalRecord: {},
    modalIndex: '',
    parentRecord: ''
  };
};
