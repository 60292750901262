import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';

const defaultFlexStyle = '0 1 219px';
// eslint-disable-next-line no-unused-vars
const smallItemFlexStyle = '0 0 102px';
const wideItemFlexStyle = '0 0 453px';
const margin = '0px 15px 21px 0px';

const addSubmittalItemFields = {
  version: { required: true },
  specSection: { required: false },
  subSection: { required: false },
  description: { required: true },
  type: { required: false },
  status: { required: false },
  submitBy: { required: false, nullable: true },
  notes: { required: false }
};

const submittalItemContents = [
  // commented out: QA requested
  // the 1st row
  // {
  //   options: {
  //     size: 1,
  //     direction: 'row',
  //     grow: 0,
  //     flexWrap: 'wrap'
  //   },
  //   contents: [
  //     {
  //       options: {
  //         direction: 'column',
  //         flex: smallItemFlexStyle,
  //         margin
  //       },
  //       contents: [
  //         {
  //           component: {
  //             default: 'CustomFieldWithLabelStyled',
  //             edit: 'CustomFieldWithLabelStyled'
  //           },
  //           source: 'version',
  //           options: {
  //             label: 'Version',
  //             fullWidth: true,
  //             alignment: 'left',
  //             variant: 'standard',
  //             color: 'secondary'
  //           }
  //         }
  //       ]
  //     }
  //   ]
  // },

  // the 2nd row
  {
    options: {
      size: 2,
      direction: 'row',
      grow: 0,
      flexWrap: 'nowrap'
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'TextInput',
              edit: 'TextInput'
            },
            source: 'specSection',
            options: {
              label: 'Spec Section',
              fullWidth: true,
              isRequired: false,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'TextInput',
              edit: 'TextInput'
            },
            source: 'subSection',
            options: {
              label: 'Sub Section',
              fullWidth: true,
              isRequired: false,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      }
    ]
  },

  // the 3rd row
  {
    options: {
      size: 2,
      direction: 'row',
      grow: 0
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: wideItemFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'TextInput',
              edit: 'TextInput'
            },
            source: 'description',
            options: {
              label: 'Description',
              fullWidth: true,
              lines: 4,
              alignment: 'left',
              placeholder: 'Enter Description',
              variant: 'standard',
              color: 'secondary',
              isRequired: true,
              maxLength: 255
            }
          }
        ]
      }
    ]
  },

  // the 4th row
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 0
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'SelectInput',
              edit: 'SelectInput'
            },
            source: 'type',
            options: {
              label: 'Type',
              valueSet: [
                { label: 'Product Data', value: 'Product Data' },
                { label: 'Shop Drawings', value: 'Shop Drawings' },
                { label: 'Samples', value: 'Samples' },
                { label: 'Other', value: 'Other' },
                { label: 'Closeout', value: 'Closeout' }
              ],
              enableSort: false,
              fullWidth: true,
              isRequired: false,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      },
      {
        options: {
          direction: 'column',
          flex: defaultFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'DateInput',
              edit: 'DateInput'
            },
            source: 'submitBy',
            options: {
              label: 'Submit By',
              placeholder: 'Select Date',
              fullWidth: false,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      }
    ]
  },

  // the 5th row
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 0
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: wideItemFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'CustomFieldWithLabelStyled',
              edit: 'SelectInput'
            },
            source: 'status',
            options: {
              label: 'Status',
              enableSort: false,
              valueSet: [
                { label: 'To be Submitted', value: 'To be Submitted' },
                { label: 'Packaged', value: 'Packaged' },
                { label: 'Sent', value: 'Sent' },
                { label: 'Rejected', value: 'Rejected' },
                { label: 'Approved', value: 'Approved' },
                { label: 'Approved as Noted', value: 'Approved as Noted' }
              ],
              fullWidth: true,
              isRequired: false,
              alignment: 'left',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      }
    ]
  },

  // the 6th row
  {
    options: {
      size: 1,
      direction: 'row',
      grow: 0
    },
    contents: [
      {
        options: {
          direction: 'column',
          flex: wideItemFlexStyle,
          margin
        },
        contents: [
          {
            component: {
              default: 'TextInput',
              edit: 'TextInput'
            },
            source: 'notes',
            options: {
              label: 'Notes',
              fullWidth: true,
              lines: 4,
              alignment: 'left',
              placeholder: 'Enter Notes',
              variant: 'standard',
              color: 'secondary'
            }
          }
        ]
      }
    ]
  }
];

const addSubmitatalItemlayout = {
  fields: generateDefaultFieldsObject(addSubmittalItemFields),
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: submittalItemContents
    },
    edit: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: submittalItemContents
    }
  }
};

export { addSubmittalItemFields, addSubmitatalItemlayout };
