import gql from 'graphql-tag';

const getServiceAgreementsByCustomer = gql`
  query getCompany(
    $partitionKey: String!
    $sortKey: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      serviceAgreements(
        entityConnection: "ServiceAgreement"
        pagination: { limit: $limit, offset: $offset }
        filter: $filter
        sorting: $sort
      ) {
        nextToken
        items {
          id
          startDate
          endDate
          status
          paymentTerms
          termPrice
          agreementNumber
          agreementName
          firstBillDate
          nextBillDate
          lastBilledDate
          isActive
          customerId
        }
      }
    }
  }
`;
export default getServiceAgreementsByCustomer;
