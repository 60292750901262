import { useMemo } from 'react';

import { TaskStatus, TaskTypeInternal } from 'utils/AppConstants';

export const useCompletedTasks = ({ job, visit }) => {
  return useMemo(() => {
    return (
      job?.jobTasks?.items?.reduce?.((tasks, { task }) => {
        if (task.visitId === visit.id && task.status === TaskStatus.COMPLETED) {
          return [...tasks, task];
        }
        return tasks;
      }, []) || []
    );
  }, [job, visit]);
};

export const useRemainingTasks = ({ job }) => {
  return useMemo(() => {
    return (
      job?.jobTasks?.items?.reduce?.((tasks, { task }) => {
        if (task.status === TaskStatus.IN_JOB) return [...tasks, task];
        return tasks;
      }, []) || []
    );
  }, [job]);
};

export const useRecommendedTasks = ({ job, visit }) => {
  return useMemo(() => {
    return (
      job?.customerProperty?.tasks?.items?.filter(
        task =>
          task?.visitId === visit?.id && task?.taskTypeInternal === TaskTypeInternal.RECOMMENDED
      ) || []
    );
  }, [job, visit]);
};

export const usePendingTasks = ({ job, visit }) => {
  return useMemo(() => {
    return (
      job?.customerProperty?.tasks?.items?.filter(
        task => task?.visitId === visit?.id && task?.taskTypeInternal === TaskTypeInternal.PENDING
      ) || []
    );
  }, [job, visit]);
};
