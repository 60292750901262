import gql from 'graphql-tag';

const softDeletePayrollBillingHourType = gql`
  mutation softDeletePayrollBillingHourType($partitionKey: String, $id: String!) {
    softDeletePayrollBillingHourType(partitionKey: $partitionKey, id: $id) {
      id
      version
      isActive
      hourType
      hourTypeAbbreviation
    }
  }
`;

export default softDeletePayrollBillingHourType;
