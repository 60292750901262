import gql from 'graphql-tag';

const listTenants = gql`
  query listTenants($partitionKey: String) {
    listTenants(partitionKey: $partitionKey) {
      id
      companyName
      firstName
      lastName
      email
      status
      tenantId
      tenantCompanyId
      sortKey
      entityType
      version
      tenantSettings(entityConnection: "TenantSetting") {
        items {
          id
          partitionKey
          sortKey
          hierarchy
          tenantId
          tenantCompanyId
          version
          settingKey
          settingPath
          settingValue
        }
      }
    }
  }
`;

export default listTenants;
