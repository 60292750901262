/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';
import { sentryException } from '../Logger/index';

export async function getFormDataByProject(projectId) {
  return await instance
    .get(`formdata/byproject/${projectId}`, {
      params: { include: '*' }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to change forms');
      return [];
    });
}

export async function formDataDelete(id) {
  return await instance
    .delete(`formdata/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function formDataChange(id, payload) {
  return await instance
    .put(`formdata/${id}`, payload)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function formDataCreate(formData) {
  return await instance
    .post('formdata', formData)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
