const sovRowMeta = [
  {
    id: 'number',
    label: 'Item Number',
    type: 'string',
    cellStyle: {
      width: '80px'
    },
    headerStyle: {
      width: '80px'
    },
    numeric: true
  },
  {
    id: 'name',
    label: 'Item Name',
    type: 'string'
  },
  {
    id: 'description',
    label: 'Description of Work',
    type: 'string'
  },
  {
    id: 'contractValue',
    label: 'Contract Value',
    type: 'currency',
    align: 'right'
  },
  {
    id: 'departmentName',
    label: 'Department',
    type: 'string'
  }
];

const tableTotalRow = {
  description: 'TOTAL',
  contractValue: 0,
  glAccount: '',
  department: 'TOTAL',
  totalsRow: 'totalsRow'
};

export { sovRowMeta, tableTotalRow };
