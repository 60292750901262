import gql from 'graphql-tag';

export const MAINTENANCE_LIST_DATA = gql`
  query maintenanceList(
    $columns: [String]
    $filter: GenericFilterInput
    $sorting: [SortingInput]
    $pagination: PaginationInput
  ) {
    maintenanceListData(
      columns: $columns
      filter: $filter
      sorting: $sorting
      pagination: $pagination
    ) {
      meta
      items
      totalRecordCount
    }
  }
`;

export const MAINTENANCE_LIST_DATA_BY_LOCATION = gql`
  query maintenanceList(
    $columns: [String]
    $filter: GenericFilterInput
    $sorting: [SortingInput]
    $pagination: PaginationInput
    $lat: Float
    $lng: Float
    $unit: String
  ) {
    maintenanceListData(
      columns: $columns
      filter: $filter
      sorting: $sorting
      pagination: $pagination
      lat: $lat
      lng: $lng
      unit: $unit
    ) {
      meta
      items
      totalRecordCount
    }
  }
`;

export const GetCompanyServiceAgreementSettings = gql`
  query($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      companySettings(
        entityConnection: "CompanySetting"
        filter: { type: { eq: "SERVICE_AGREEMENT" }, isActive: { eq: true } }
      ) {
        items {
          id
          version
          settings
        }
      }
    }
  }
`;

export const GetServiceAgreementAndMaintenanceTemplateByNumber = gql`
  query GetServiceAgreementAndMaintenanceTemplateByNumber($jobNumber: String!) {
    getJobByJobNumber(jobNumber: $jobNumber) {
      id
      serviceAgreement(entityConnection: "ServiceAgreement") {
        id
        departmentId
        preferredTechnicians {
          departmentId
          crewId
          primaryTechnicianId
          additionalTechnicianIds
        }
      }
      maintenanceTemplate {
        serviceDescription
        budgetedHours
        estimatedVisits
        numberOfTechs
        preferredTechnicians {
          departmentId
          crewId
          primaryTechnicianId
          additionalTechnicianIds
        }
        maintenanceTaskTemplates(entityConnection: "MaintenanceTaskTemplate") {
          items {
            id
            maintenanceTemplateId
            assetId
            asset {
              assetName
            }
            assetTypeId
          }
        }
      }
    }
  }
`;

export const BulkAddVisitsToJobs = gql`
  mutation bulkAddVisitsToJobs($partitionKey: String, $input: BulkAddVisitsToJobsInput!) {
    bulkAddVisitsToJobs(partitionKey: $partitionKey, data: $input) {
      id
    }
  }
`;
