import gql from 'graphql-tag';

const getPayrollHourTypes = gql`
  query getPayrollHourTypes($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      payrollHourTypes(entityConnection: "PayrollHourType") {
        items {
          id
          sortOrder
          hourType
          hourTypeAbbreviation
          billingHourTypeId
        }
      }
    }
  }
`;

export default getPayrollHourTypes;
