import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Modal from '../index';

const confirmDialogButtons = {
  save: {
    label: 'Confirm',
    style: { backgroundColor: '#b00020', color: '#ffffff' },
    buttonType: 'contained',
    behavior: {
      new: true,
      edit: true
    }
  },
  cancel: {
    label: 'Cancel',
    color: 'secondary',
    buttonType: 'outlined',
    behavior: {
      new: true,
      edit: true
    }
  }
};

export class RouteLeavingGuard extends Component {
  state = {
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false
  };

  showModal = location =>
    this.setState({
      modalVisible: true,
      lastLocation: location
    });

  closeModal = callback =>
    this.setState(
      {
        modalVisible: false
      },
      callback
    );

  handleBlockedNavigation = nextLocation => {
    const { confirmedNavigation } = this.state;
    // const { shouldBlockNavigation } = this.props;
    if (!confirmedNavigation) {
      this.showModal(nextLocation);
      return false;
    }

    return true;
  };

  handleConfirmNavigationClick = () =>
    this.closeModal(() => {
      const { navigate } = this.props;
      const { lastLocation } = this.state;
      if (lastLocation) {
        this.setState(
          {
            confirmedNavigation: true
          },
          () => {
            // Navigate to the previous blocked location with your navigate function
            navigate.push(lastLocation.pathname);
          }
        );
      }
    });

  render() {
    const { when } = this.props;
    const { modalVisible } = this.state;
    confirmDialogButtons.save.action = this.handleConfirmNavigationClick;
    confirmDialogButtons.cancel.action = this.closeModal;
    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        <Modal
          open={modalVisible}
          handleClose={() => this.closeModal()}
          buttons={confirmDialogButtons}
          spinnerStatus={this.state.spinnerStatus}
          width={696}
          keepMounted
          hideClose
        >
          {/* {this.state.spinnerStatus && <Spinner />} */}
          <Typography variant="subtitle1">
            You have unsaved data, Are you sure you want to leave?
          </Typography>
        </Modal>
      </>
    );
  }
}
export default RouteLeavingGuard;
