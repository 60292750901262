import gql from 'graphql-tag';

const addTimesheetDailyOverrides = gql`
  mutation addTimesheetDailyOverrides(
    $partitionKey: String
    $data: AddTimesheetDailyOverridesToTimesheetPeriodInput!
  ) {
    addTimesheetDailyOverridesToTimesheetPeriod(partitionKey: $partitionKey, data: $data) {
      id
      dateUTC
      hourTypeAbbreviation
      actualTotalDurationOverride
      hourTypeId
      version
    }
  }
`;

export default addTimesheetDailyOverrides;
