import React, { useMemo } from 'react';
import { bool, func, oneOf, shape } from 'prop-types';

import TimeAndMaterialOverviewTab from '../TimeAndMaterialOverviewTab';
import QuotedOverviewTab from '../QuotedOverviewTab';
import { JobCloseoutTypes, RelevantVisitStatuses } from '../constants';

const OverviewTab = ({ jobCloseoutType, jobData, refetchJob, companyTimezone, isLoading }) => {
  // Note: Separate from VisitsTab list to include all visits, not just user-selected ones
  const visits = useMemo(
    () =>
      jobData.visits?.items?.filter?.(visit => RelevantVisitStatuses.includes(visit.status)) || [],
    [jobData.visits]
  );

  return (
    <>
      {jobCloseoutType === JobCloseoutTypes.TIME_AND_MATERIAL && (
        <TimeAndMaterialOverviewTab
          jobData={jobData}
          visits={visits}
          refetchJob={refetchJob}
          companyTimezone={companyTimezone}
          isLoading={isLoading}
        />
      )}
      {jobCloseoutType === JobCloseoutTypes.QUOTED && (
        <QuotedOverviewTab
          data={jobData}
          visits={visits}
          refetchJob={refetchJob}
          companyTimezone={companyTimezone}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

OverviewTab.propTypes = {
  jobCloseoutType: oneOf([JobCloseoutTypes.TIME_AND_MATERIAL, JobCloseoutTypes.QUOTED]).isRequired,
  jobData: shape({}).isRequired,
  refetchJob: func.isRequired,
  isLoading: bool
};

OverviewTab.defaultProps = {
  isLoading: false
};

export default OverviewTab;
