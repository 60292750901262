import gql from 'graphql-tag';

const getGLAccounts = gql`
  query getGLAccounts($partitionKey: String!, $sortKey: String!, $nextToken: String) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      ledgerAccounts(entityConnection: "LedgerAccount", nextToken: $nextToken) {
        nextToken
        items {
          id
          accountNumber
          accountType
          name
          accountingRefId
          lastUpdatedDateTime
          isActive
        }
      }
    }
  }
`;

export default getGLAccounts;
