const styles = () => ({
  container: {
    position: 'relative',
    padding: 12
  },
  thumbnail: {
    height: 'auto',
    width: '100%'
  },
  ocrInputStyle: {
    height: '100%',
    marginTop: 5,
    paddingBottom: 2,
    width: '100%'
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -20,
    marginLeft: -20
  }
});

export default styles;
