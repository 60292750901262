import gql from 'graphql-tag';

const deleteNoteById = gql`
  mutation deleteNoteById($partitionKey: String, $id: String!) {
    deleteNoteById(partitionKey: $partitionKey, id: $id) {
      id
      sortKey
    }
  }
`;

export default deleteNoteById;
