import gql from 'graphql-tag';

const getPropertyAssetById = gql`
  query getPropertyAssetById($id: String!) {
    getPropertyAssetById(id: $id) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      assetName
      imageUrl
      make
      version
      modelNumber
      serialNo
      installDate
      version
      location
      propertyZone
      internalIdentifier
      ownerIdentifier
      forms {
        id
        name
        description
        latestPublishedFormDefinition {
          id
          formDefinitionJson
        }
      }
      serviceAgreements {
        id
        agreementName
      }
      assetTypeId
      assetType(entityConnection: "AssetType") {
        id
        tagName
      }
      warranties(entityConnection: "Warranty") {
        items {
          id
          version
          isActive
          startDate
          endDate
          warrantyInfo
          lastUpdatedBy
          lastUpdatedDate
          lastUpdatedDateTime
        }
      }
      attachments(entityConnection: "Attachment") {
        items {
          id
          version
          hierarchy
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          fileName
          customFileName
          fileUrl
          createdDate
          createdDateTime
          createdBy
          description
          comment
          lastUpdatedDate
          lastUpdatedDateTime
        }
      }
      property: parentEntity {
        ... on CustomerProperty {
          id
          companyName
          companyAddresses(entityConnection: "CompanyAddress") {
            items {
              id
              addressLine1
              addressLine2
              state
              city
              zipcode
              addressType
              longitude
              latitude
            }
          }
          customer: parentEntity {
            ... on Customer {
              id
              customerName
            }
          }
        }
      }
      visitAssets: visitAssetView(entityConnection: "VisitAsset") {
        items {
          id
          visit: parentEntity {
            ... on Visit {
              id
              visitNumber
              scheduledFor
              description
              departmentName
              job: parentEntity {
                ... on Job {
                  id
                  jobNumber
                  jobTypeName
                  jobTypeInternal
                  customIdentifier
                  issueDescription
                  serviceAgreement(entityConnection: "ServiceAgreement") {
                    id
                    companyId
                    startDate
                    endDate
                    status
                    agreementName
                    agreementNumber
                  }
                }
              }
              attachments(entityConnection: "Attachment") {
                items {
                  id
                  version
                  customFileName
                  fileName
                  fileUrl
                  comment
                  description
                  lastUpdatedDate
                  lastUpdatedDateTime
                }
              }
              primaryTechs(entityConnection: "VisitPrimaryTech") {
                items {
                  id
                  mappedEntity {
                    ... on Employee {
                      id
                      firstName
                      lastName
                    }
                  }
                }
              }
              extraTechs(entityConnection: "VisitExtraTech") {
                items {
                  id
                  mappedEntity {
                    ... on Employee {
                      id
                      firstName
                      lastName
                    }
                  }
                }
              }
            }
          }
        }
      }
      notes(entityConnection: "Note") {
        items {
          id
          version
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          subject
          note
          createdBy
          lastUpdatedDate
          lastUpdatedDateTime
          lastUpdatedBy
        }
      }
    }
  }
`;
export default getPropertyAssetById;
