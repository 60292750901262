import gql from 'graphql-tag';

const addAttachmentsToPropertyAsset = gql`
  mutation addAttachmentsToPropertyAsset(
    $partitionKey: String
    $data: AddAttachmentsToPropertyAssetInput!
  ) {
    addAttachmentsToPropertyAsset(partitionKey: $partitionKey, data: $data) {
      fileName
      customFileName
      fileUrl
      id
      version
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      description
      comment
      createdDate
      createdDateTime
      createdBy
    }
  }
`;

export default addAttachmentsToPropertyAsset;
