import gql from 'graphql-tag';

const getMaintenanceRecordsByProperty = gql`
  query getCustomerProperty(
    $partitionKey: String!
    $sortKey: String!
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getCustomerProperty(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      job: jobsView(
        entityConnection: "Job"
        pagination: { limit: $limit, offset: $offset }
        filter: {
          stringFilters: [
            { fieldName: "Job.jobTypeInternal", filterInput: { in: ["Maintenance"] } }
          ]
        }
        sorting: $sort
      ) {
        nextToken
        items {
          id
          partitionKey
          jobNumber
          dueDate
          status
          customIdentifier
          serviceAgreement(entityConnection: "ServiceAgreement") {
            id
            agreementName
          }
          maintenanceTemplate {
            schedule
          }
          jobTasks {
            items {
              id
              task {
                id
                name
                isActive
                taskEntries {
                  items {
                    id
                    productId
                    priceBookEntryId
                    quantity
                  }
                }
              }
            }
          }
          jobJobTags {
            items {
              jobTag {
                tagName
              }
            }
          }
        }
      }
    }
  }
`;
export default getMaintenanceRecordsByProperty;
