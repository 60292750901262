import React from 'react';

import Box from '@material-ui/core/Box';
import { array } from 'prop-types';

import CustomCarousel from '@pm/components/CustomCarousel';

import { useStyles } from './ImagesCarousel.styles';

const ImagesCarousel = ({ images, ...props }) => {
  const styles = useStyles();

  if (!images.length) {
    return null;
  }

  return (
    <Box css={styles.container}>
      <CustomCarousel
        imageData={images}
        imageDataLength={images.length}
        margin={15}
        timeout={200}
        {...props}
      />
    </Box>
  );
};

ImagesCarousel.propTypes = { images: array.isRequired };

export default ImagesCarousel;
