import React from 'react';
import { Typography } from '@buildhero/sergeant';
import { useStyles } from './MultipleQuoteMessage.styles';

const MultipleQuoteMessage = () => {
  const styles = useStyles();
  return (
    <Typography css={styles.text}>There are multiple quotes associated with this job</Typography>
  );
};

export default MultipleQuoteMessage;
