import React from 'react';
import { sortBy } from 'lodash';
import { TimesheetsService } from 'services/core';
import { logErrorWithCallback } from 'utils';

export const getPayrollHourTypes = async (tenantId, companyId, snackbarOn) => {
  const TimesheetsServiceObj = new TimesheetsService();
  let responseData = [];
  try {
    const response = await TimesheetsServiceObj.getPayrollHourTypes(tenantId, companyId);
    const hourTypes = response?.data?.getCompany?.payrollHourTypes?.items || [];
    responseData = sortBy(hourTypes, 'sortOrder');
  } catch (error) {
    logErrorWithCallback(
      error,
      snackbarOn,
      'Unable to fetch payroll types, please try again later'
    );
  }
  return responseData;
};

const usePayrollHourTypes = (tenantId, tenantCompanyId, snackbarOn) => {
  const [payrollHourTypes, setPayrollHourTypes] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const getData = async () => {
    const hourTypes = await getPayrollHourTypes(tenantId, tenantCompanyId, snackbarOn);
    setPayrollHourTypes(hourTypes);
    setLoading(false);
  };
  React.useEffect(() => {
    if (tenantId && tenantCompanyId) {
      getData();
    }
    // Disable lint - getData function not needed
    // eslint-disable-next-line
  }, [tenantId, tenantCompanyId]);
  return [payrollHourTypes, getData, loading];
};

export default usePayrollHourTypes;
