import React, { useCallback } from 'react';
import { array, object } from 'prop-types';
import { TH, ThemeProvider, TV, Typography, Button } from '@buildhero/sergeant';
import { VisitStatuses } from '@dispatch/Dispatch.constants';
import { useStyles } from './NonVisitStatusHeader.styles';

const NonVisitStatusHeader = ({ event, completeBillableNonVisitTuple }) => {
  const styles = useStyles();
  const isComplete = event?.status === VisitStatuses.COMPLETE.value.serverValue;
  const [triggerComplete, completeResponse] = completeBillableNonVisitTuple;

  const handleCompleteEvent = useCallback(() => {
    triggerComplete({
      eventId: event?.id,
      originalEvent: event
    });
  }, [event, triggerComplete]);

  return (
    <ThemeProvider>
      <div css={styles.root}>
        <Typography variant={TV.S1} height={TH.COMFORTABLE}>
          Status: {event?.status}
        </Typography>
        <Button
          disabled={!event || isComplete}
          loading={completeResponse.loading}
          size="small"
          type={isComplete ? 'secondary' : 'tertiary'}
          onClick={handleCompleteEvent}
        >
          Complete
        </Button>
      </div>
    </ThemeProvider>
  );
};

NonVisitStatusHeader.propTypes = {
  event: object.isRequired,
  completeBillableNonVisitTuple: array.isRequired
};
export default NonVisitStatusHeader;
