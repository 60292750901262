import React from 'react';
import { Select } from '@buildhero/sergeant';
import { bool, func, object } from 'prop-types';
import { useStyles } from './DepartmentSelect.styles';
import { useDepartmentOptions } from './DepartmentSelect.hooks';

const DepartmentSelect = ({ value, onChange, disabled }) => {
  const styles = useStyles();
  const options = useDepartmentOptions();
  return (
    <div css={styles.container}>
      <Select
        label="Department"
        options={options}
        value={value}
        disabled={disabled}
        required
        portal
        searchable
        onChange={onChange}
      />
    </div>
  );
};

DepartmentSelect.propTypes = {
  value: object.isRequired,
  onChange: func.isRequired,
  disabled: bool.isRequired
};

export default DepartmentSelect;
