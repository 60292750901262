import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useStyles from './HourTypeDropdown.styles';

const checkboxStyle = { height: '18px', width: '18px' };

const HourTypeDropdown = ({
  isSelected,
  showSelectedLabel,
  increaseRefreshCount,
  label,
  items,
  onSelect,
  onChange,
  hasEditPermission = true
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showCheckbox, setShowCheckbox] = React.useState(false);
  const open = Boolean(hasEditPermission && anchorEl);
  const classes = useStyles();

  React.useEffect(() => {
    setShowCheckbox(isSelected);
  }, [isSelected]);

  const handleOnChange = item => {
    onChange(item);
    increaseRefreshCount();
    setAnchorEl(null);
  };

  const handleOnCheckboxClick = status => {
    setShowCheckbox(status);
    onSelect(status);
  };

  const renderLabel = onClick => (
    <Typography variant="body2" className={classes.label} onClick={onClick}>
      {label}
    </Typography>
  );

  if (showSelectedLabel) return renderLabel();

  return (
    <Grid container>
      <Grid container alignItems="center" className={classes.container}>
        <FormControlLabel
          control={
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon style={checkboxStyle} />}
              checkedIcon={<CheckBoxIcon style={checkboxStyle} />}
              checked={showCheckbox}
              onClick={event => {
                event.preventDefault();
                handleOnCheckboxClick(!showCheckbox);
              }}
              disabled={!hasEditPermission}
            />
          }
          style={{ marginRight: 0, cursor: hasEditPermission ? 'pointer' : 'auto' }}
          disabled={!hasEditPermission}
        />
        {renderLabel(event => setAnchorEl(event.currentTarget))}
        <ArrowDropDown onClick={event => setAnchorEl(event.currentTarget)} color="primary" />
      </Grid>
      <Menu
        keepMounted
        open={open}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setAnchorEl(null)}
      >
        {items.map(item => (
          <MenuItem
            key={item.id}
            className={classes.menuItem}
            onClick={() => handleOnChange(item)}
          >{`${item.hourType} (${item.hourTypeAbbreviation})`}</MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};

export default HourTypeDropdown;

HourTypeDropdown.propTypes = {
  isSelected: PropTypes.bool,
  showSelectedLabel: PropTypes.bool,
  label: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  increaseRefreshCount: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

HourTypeDropdown.defaultProps = {
  isSelected: false,
  showSelectedLabel: false,
  label: '-'
};
