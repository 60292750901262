export const recommendedTaskLayout = {
  entity: {
    name: 'Task',
    tenant: 'default',
    layouts: {
      web: {
        sections: [
          {
            contextTitle: {
              new: 'Add task',
              edit: 'Edit task',
              view: 'Task'
            },
            sm: '12',
            md: '12',
            lg: '12',
            rows: [
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbHidden',
                    value: 'id',
                    label: 'id',
                    name: 'id',
                    behavior: {
                      edit: {
                        ui: 'Hidden',
                        sm: '1',
                        md: '1',
                        lg: '1'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'name',
                    label: 'taskNameMandatory',
                    name: 'name',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '8',
                        lg: '4'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '8',
                        lg: '4'
                      },
                      view: {
                        ui: 'Label',
                        sm: '12',
                        md: '8',
                        lg: '4'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'departmentId',
                    label: 'department',
                    name: 'departmentId',
                    specialBehaviour: {
                      includeQueryResult: true,
                      queryPath: 'departments',
                      fieldName: 'tagName',
                      fieldValue: 'id'
                    },
                    behavior: {
                      new: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '8',
                        lg: '4'
                      },
                      edit: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '8',
                        lg: '4'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'departmentValue',
                    label: 'department',
                    name: 'departmentValue',
                    behavior: {
                      view: {
                        ui: 'Label',
                        sm: '12',
                        md: '8',
                        lg: '4'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'taskTypeId',
                    label: 'taskType',
                    name: 'taskTypeId',
                    specialBehaviour: {
                      includeQueryResult: true,
                      queryPath: 'taskTypes',
                      fieldName: 'tagName',
                      fieldValue: 'id'
                    },
                    behavior: {
                      new: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '8',
                        lg: '4'
                      },
                      edit: {
                        ui: 'Dropdown',
                        sm: '12',
                        md: '8',
                        lg: '4'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'taskTypeValue',
                    label: 'taskType',
                    name: 'taskTypeValue',
                    behavior: {
                      view: {
                        ui: 'Label',
                        sm: '12',
                        md: '8',
                        lg: '4'
                      }
                    }
                  }
                ]
              },
              {
                spacing: '3',
                cols: [
                  // {
                  //   type: 'dbField',
                  //   value: 'jobTypeId',
                  //   label: 'jobType',
                  //   name: 'jobTypeId',
                  //   specialBehaviour: {
                  //     includeQueryResult: true,
                  //     queryPath: 'jobTypes',
                  //     fieldName: 'tagName',
                  //     fieldValue: 'id'
                  //   },
                  //   behavior: {
                  //     new: {
                  //       ui: 'Dropdown',
                  //       sm: '12',
                  //       md: '8',
                  //       lg: '4'
                  //     },
                  //     edit: {
                  //       ui: 'Dropdown',
                  //       sm: '12',
                  //       md: '8',
                  //       lg: '4'
                  //     }
                  //   }
                  // },
                  // {
                  //   type: 'dbField',
                  //   value: 'jobTypeValue',
                  //   label: 'jobType',
                  //   name: 'jobTypeValue',
                  //   behavior: {
                  //     view: {
                  //       ui: 'Label',
                  //       sm: '12',
                  //       md: '8',
                  //       lg: '4'
                  //     }
                  //   }
                  // },
                  {
                    type: 'dbField',
                    value: 'description',
                    label: 'description',
                    name: 'description',
                    behavior: {
                      new: {
                        ui: 'TextArea',
                        sm: '12',
                        md: '8',
                        lg: '8'
                      },
                      edit: {
                        ui: 'TextArea',
                        sm: '12',
                        md: '8',
                        lg: '8'
                      },
                      view: {
                        ui: 'Label',
                        sm: '12',
                        md: '8',
                        lg: '4'
                      }
                    }
                  }
                ]
              },
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbArrayField',
                    value: 'taskLineItems',
                    name: 'taskLineItems',
                    label: 'addItems',
                    specialBehaviour: {
                      includeQueryResult: true,
                      deleteService: 'deleteTaskEntry'
                    },
                    behavior: {
                      new: {
                        ui: 'AddProductsWithSearch',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      },
                      edit: {
                        ui: 'AddProductsWithSearch',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      }
                    }
                  }
                ]
              }
              // {
              //   spacing: '3',
              //   cols: [
              //     {
              //       type: 'dbField',
              //       value: 'productBundleValue',
              //       label: 'selectProductBundle',
              //       name: 'productBundleValue',
              //       showField: 'isPriceBookEnabled',
              //       specialBehaviour: {
              //         displayText: ['name', 'description'],
              //         valueField: 'name',
              //         delimiter: ' - ',
              //         additionalFormField: 'productBundleId',
              //         additionalFormFieldValue: 'id',
              //         filterString: 'productFilter'
              //       },
              //       behavior: {
              //         new: {
              //           ui: 'ProductSearch',
              //           sm: '12',
              //           md: '8',
              //           lg: '8'
              //         },
              //         edit: {
              //           ui: 'ProductSearch',
              //           sm: '12',
              //           md: '8',
              //           lg: '8'
              //         }
              //       }
              //     }
              //   ]
              // }
            ]
          }
        ],
        buttons: {
          save: {
            label: 'Save',
            color: 'primary',
            buttonType: 'contained',
            behavior: {
              new: true,
              edit: true
            }
          },
          cancel: {
            label: 'Cancel',
            color: 'secondary',
            buttonType: 'contained',
            behavior: {
              edit: true
            }
          }
        }
      }
    }
  }
};

export default recommendedTaskLayout;
