import React from 'react';
import { Popper, List, Paper, Fade, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '../MenuItem';
import MenuSubSection from '../MenuSubSection';
import UserPermisson from '../../AppPermissions';
import displayIconElement from '../icons';

const useStyles = makeStyles(theme => ({
  menuPopover: {
    paddingTop: theme.spacing(10),
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
    zIndex: 500
  },
  menuPopoverItem: {
    color: theme.palette.text.primary,
    borderBottom: '2px solid transparent',
    backgroundColor: theme.palette.grayscale(100),
    '&:hover': {
      backgroundColor: theme.palette.grayscale(98),
      color: theme.palette.secondary.main
    }
  },
  listIconWrapper: {
    color: 'inherit',
    margin: 0,
    marginRight: theme.spacing(1)
  },
  listIcon: {
    fontSize: '16px'
  },
  listTexts: {
    fontWeight: 'inherit',
    textTransform: 'capitalize',
    cursor: 'pointer'
  }
}));

const BHPopper = ({
  menuItems,
  tenantSettingProcessed,
  handleClosePopover,
  id,
  open,
  anchorEl,
  isSmallScreen,
  buttonElements,
  user,
  placement = 'bottom-start'
}) => {
  const classes = useStyles();
  const popoverMenuWidth = isSmallScreen ? 'auto' : 200;

  const renderSections = item =>
    item.subSection ? (
      <UserPermisson
        I={item.caslAction}
        action={item.menu}
        scope={item.scope}
        key={`perm${item.title || item.pageMapKey}`}
      >
        <MenuSubSection
          user={user}
          isPopover
          subSection={item.subSection}
          tenantSettingProcessed={tenantSettingProcessed}
          isSmallScreen={isSmallScreen}
          key={`perm${item.subSection.title || item.subSection.pageMapKey}`}
        />
      </UserPermisson>
    ) : (
      <UserPermisson
        I={item.menu.caslAction}
        action={item.menu} // When object, permission component will extract caslkey
        scope={item.menu.scope}
        key={`perm${item.menu.title || item.menu.pageMapKey}`}
      >
        <MenuItem
          data={item.menu}
          isPopover
          nested="true"
          key={`${item.menu.title || item.menu.pageMapKey}_${item.menu.url}`}
          tenantSettingProcessed={tenantSettingProcessed}
          handleClosePopover={handleClosePopover}
          isSmallScreen={isSmallScreen}
        />
      </UserPermisson>
    );

  return (
    <Popper
      id={id}
      classes={{ root: classes.menuPopover }}
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      transition
      style={{ zIndex: 500 }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={0}>
          <Paper
            style={{
              boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
              marginTop: 4,
              borderRadius: 4,
              width: popoverMenuWidth
            }}
          >
            <List component="div" disablePadding>
              {menuItems.map(item =>
                buttonElements ? (
                  <ButtonItem
                    title={item.menu.title || item.menu.pageMapKey}
                    onClick={item.menu.onClick}
                    iconKey={item.menu.iconKey}
                  />
                ) : (
                  renderSections(item)
                )
              )}
            </List>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

const ButtonItem = props => {
  const classes = useStyles();
  const { title, iconKey, onClick } = props;
  const icon = displayIconElement(iconKey);
  return (
    <ListItem
      classes={{
        root: classes.menuPopoverItem
      }}
      onClick={event => {
        if (onClick) onClick(event);
      }}
      key={`menuItem-${title}`}
    >
      {icon && (
        <ListItemIcon classes={{ root: classes.listIconWrapper }}>
          {React.cloneElement(displayIconElement('addCircleIcon'), { className: classes.listIcon })}
        </ListItemIcon>
      )}
      <ListItemText primary={title} disableTypography classes={{ root: classes.listTexts }} />
    </ListItem>
  );
};

export default BHPopper;
