import moment from 'moment';
import React, { useCallback } from 'react';
import { bool, object, shape } from 'prop-types';
import { useFormikContext } from 'formik';
import { DateInput, ThemeProvider, TimeInput } from '@buildhero/sergeant';
import { useStyles } from './DateTimeInput.styles';

const getTimeString = timestamp => {
  if (timestamp) {
    const date = moment.unix(timestamp);
    if (date.isValid()) {
      return date.format('HH:mm');
    }
  }
};

const DateTimeInput = React.memo(({ field, options }) => {
  const { setFieldValue, setFieldError, errors } = useFormikContext();
  const isError = Boolean(errors[field.name]);
  const classes = useStyles({ isError });

  const handleError = useCallback(
    err => {
      setFieldError(field.name, err);
    },
    [field.name, setFieldError]
  );

  const handleChange = useCallback(
    value => {
      setFieldValue(field.name, value ?? null);
    },
    [field.name, setFieldValue]
  );

  const handleTimeChange = useCallback(
    value => {
      const [hours, minutes] = value.split(':');
      const newValue = moment
        .unix(field.value)
        .set({ hours, minutes })
        .unix();
      setFieldValue(field.name, newValue);
    },
    [field.name, field.value, setFieldValue]
  );

  return (
    <ThemeProvider>
      <div className={classes.errorText}>
        {field.value !== undefined && options.disableDateEditing ? (
          <>
            <DateInput
              style={{ width: '70%' }}
              required={options.isRequired}
              error={isError}
              subtext={errors[field.name]}
              value={field.value || ''}
              onChange={handleChange}
              onError={handleError}
              type="date"
              disabled
            />
            {field.value && (
              <TimeInput
                css={{ backgroundColor: 'white' }}
                style={{ width: '30%' }}
                disabled={options.disabled}
                value={getTimeString(field.value)}
                onChange={handleTimeChange}
              />
            )}
          </>
        ) : (
          <DateInput
            required={options.isRequired}
            error={isError}
            subtext={errors[field.name]}
            value={field.value || ''}
            onChange={handleChange}
            onError={handleError}
            type="datetime"
            disabled={options.disabled}
          />
        )}
      </div>
    </ThemeProvider>
  );
});

DateTimeInput.propTypes = {
  field: object.isRequired,
  options: shape({
    disabled: bool,
    disableDateEditing: bool
  })
};

DateTimeInput.defaultProps = {
  options: {}
};

export default DateTimeInput;
