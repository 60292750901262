export const departmentRowsMeta = [
  {
    id: 'tagName',
    numeric: false,
    label: 'Name'
  },
  {
    id: 'employeeCount',
    numeric: true,
    label: 'Active members'
  },
  {
    id: 'skillCount',
    numeric: true,
    label: 'Skills offered'
  }
];

/**
 * @param options Contains { classes, isQuickbooksEnabled, isIntacctEnabled,
 *                           intactEntityOptions, intactClassOptions,
 *                           intactDepartmentOptions, intactLocationOptions,
 *                           companySkills }
 */
export const departmentModalForm = options => {
  return {
    fields: {
      logoUrl: {
        default: 'logoUrl',
        edit: 'logoUrl'
      },
      tagName: {
        default: 'tagName',
        edit: 'tagName'
      },
      phone: {
        default: 'phone',
        edit: 'phone'
      },
      email: {
        default: 'email',
        edit: 'email'
      },
      addressLine1: {
        default: 'addressLine1',
        edit: 'addressLine1'
      },
      checkbox_item: {
        default: 'checkbox_item',
        edit: 'checkbox_item'
      },
      accountingRefIdOfClass: {
        default: 'accountingRefIdOfClass'
      },
      intacctEntityId: {
        default: 'intacctEntityName',
        edit: 'intacctEntityId'
      },
      intacctLocationId: {
        default: 'intacctLocationName',
        edit: 'intacctLocationId'
      },
      intacctClassId: {
        default: 'intacctClassName',
        edit: 'intacctClassId'
      },
      intacctDepartmentId: {
        default: 'intacctDepartmentName',
        edit: 'intacctDepartmentId'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: [
          // Logo & Name
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              // Image Control
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      edit: 'LogoButton',
                      default: 'ImageControl'
                    },
                    source: 'logoUrl'
                  }
                ]
              },
              // Department Name
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      edit: 'TextInput',
                      default: 'FieldWithLabel'
                    },
                    source: 'tagName',
                    options: {
                      label: 'Department Name',
                      placeholder: '',
                      isRequired: true
                    }
                  }
                ]
              },
              (options.isQuickbooksEnabled || options.isSpectrumEnabled) && {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      edit: 'SelectInput',
                      default: null
                    },
                    source: 'accountingRefIdOfClass',
                    options: {
                      label: 'Class',
                      placeholder: '',
                      valueSet: options.classes
                    }
                  },
                  {
                    component: {
                      default: 'FieldWithLabel',
                      edit: null
                    },
                    source: 'className',
                    options: {
                      label: 'Class',
                      placeholder: '',
                      isRequired: true
                    }
                  }
                ]
              }
            ].filter(Boolean)
          },
          // Intacct entity and location
          {
            options: {
              size: 3,
              direction: 'row',
              grow: 0
            },
            contents: options.isIntacctEnabled
              ? [
                  {
                    options: {
                      direction: 'column',
                      grow: 1,
                      padding: 12
                    },
                    contents: [
                      {
                        component: {
                          edit: 'SelectInput',
                          default: 'FieldWithLabel'
                        },
                        source: 'intacctEntityId',
                        options: {
                          label: 'Intacct Entity',
                          placeholder: '',
                          valueSet: options.intactEntityOptions,
                          isRequired: true,
                          isSearchable: true
                        }
                      }
                    ]
                  },
                  {
                    options: {
                      direction: 'column',
                      grow: 1,
                      padding: 12
                    },
                    contents: [
                      {
                        component: {
                          edit: 'SelectInput',
                          default: 'FieldWithLabel'
                        },
                        source: 'intacctLocationId',
                        options: {
                          label: 'Intacct Location',
                          placeholder: '',
                          valueSet: options.intactLocationOptions,
                          isRequired: true,
                          isSearchable: true
                        }
                      }
                    ]
                  }
                ]
              : []
          },

          // Intacct class and department
          {
            options: {
              size: 3,
              direction: 'row',
              grow: 0
            },
            contents: options.isIntacctEnabled
              ? [
                  {
                    options: {
                      direction: 'column',
                      grow: 1,
                      padding: 12
                    },
                    contents: [
                      {
                        component: {
                          edit: 'SelectInput',
                          default: 'FieldWithLabel'
                        },
                        source: 'intacctClassId',
                        options: {
                          label: 'Intacct Class',
                          placeholder: '',
                          valueSet: options.classes,
                          isSearchable: true
                        }
                      }
                    ]
                  },
                  {
                    options: {
                      direction: 'column',
                      grow: 1,
                      padding: 12
                    },
                    contents: [
                      {
                        component: {
                          edit: 'SelectInput',
                          default: 'FieldWithLabel'
                        },
                        source: 'intacctDepartmentId',
                        options: {
                          label: 'Intacct Department',
                          placeholder: '',
                          valueSet: options.intactDepartmentOptions,
                          isSearchable: true
                        }
                      }
                    ]
                  }
                ]
              : []
          },
          {
            options: {
              direction: 'column',
              grow: 1,
              padding: 12
            },
            contents: options.isADPExportEnabled
              ? [
                  {
                    component: 'SeargentSubtitle',
                    source: 'Temp Code'
                  },
                  {
                    options: {
                      direction: 'row',
                      grow: 1
                    },
                    contents: [
                      {
                        options: {
                          direction: 'column',
                          grow: 1
                        },
                        contents: [
                          {
                            component: {
                              edit: 'TextInput',
                              default: 'FieldWithLabel'
                            },
                            source: 'ADPExportDepartmentId',
                            options: {
                              label: 'Dept ID',
                              placeholder: 'Enter ID'
                            }
                          }
                        ]
                      },
                      {
                        options: {
                          direction: 'column',
                          grow: 0,
                          padding: 16
                        },
                        contents: [
                          {
                            component: 'FieldWithLabel',
                            options: {
                              label: ' '
                            }
                          }
                        ]
                      },
                      {
                        options: {
                          direction: 'column',
                          grow: 1
                        },
                        contents: [
                          {
                            component: {
                              edit: 'TextInput',
                              default: 'FieldWithLabel'
                            },
                            source: 'ADPExportClassId',
                            options: {
                              label: 'Class ID',
                              placeholder: 'Enter ID'
                            }
                          }
                        ]
                      },
                      {
                        options: {
                          direction: 'column',
                          grow: 0,
                          padding: 16
                        },
                        contents: [
                          {
                            component: 'FieldWithLabel',
                            options: {
                              label: ' '
                            }
                          }
                        ]
                      },
                      {
                        options: {
                          direction: 'column',
                          grow: 1,
                          padding: 0
                        },
                        contents: [
                          {
                            component: 'FieldWithLabel',
                            source: 'accountNumber',
                            options: {
                              label: 'Account Number'
                            }
                          }
                        ]
                      },
                      {
                        options: {
                          direction: 'column',
                          grow: 0,
                          padding: 16
                        },
                        contents: [
                          {
                            component: 'FieldWithLabel',
                            options: {
                              label: ' '
                            }
                          }
                        ]
                      },
                      {
                        options: {
                          direction: 'column',
                          grow: 1
                        },
                        contents: [
                          {
                            component: {
                              edit: 'TextInput',
                              default: 'FieldWithLabel'
                            },
                            source: 'ADPExportLocationCode',
                            options: {
                              label: 'Location Code',
                              placeholder: 'Enter Code'
                            }
                          }
                        ]
                      }
                    ]
                  }
                ]
              : []
          },
          // Phone and Email
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              // Department Phone
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      edit: 'TextInput',
                      default: 'FieldWithLabel'
                    },
                    source: 'phonePrimary',
                    options: {
                      label: 'Phone',
                      placeholder: ''
                    }
                  }
                ]
              },
              // Department Email
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      edit: 'TextInput',
                      default: 'FieldWithLabel'
                    },
                    source: 'email',
                    options: {
                      label: 'Email',
                      placeholder: ''
                    }
                  }
                ]
              }
            ]
          },
          // Address
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'SeargentSubtitle',
                    source: 'Shipping Address'
                  },
                  {
                    component: {
                      edit: 'PlacesSearch',
                      default: 'FieldWithLabel'
                    },
                    source: 'addressLine1',
                    options: {
                      label: 'Address Line 1',
                      placeholder: '',
                      fullWidth: true,
                      setSource: false
                    }
                  }
                ]
              }
            ]
          },
          // Address Line 2
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      edit: 'TextInput',
                      default: 'FieldWithLabel'
                    },
                    source: 'addressLine2',
                    options: {
                      label: 'Address Line 2',
                      placeholder: ''
                    }
                  }
                ]
              }
            ]
          },
          // City, State, Zipcode
          {
            options: {
              size: 3,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      edit: 'TextInput',
                      default: 'FieldWithLabel'
                    },
                    source: 'city',
                    options: {
                      label: 'City'
                    }
                  }
                ]
              },
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      edit: 'TextInput',
                      default: 'FieldWithLabel'
                    },
                    source: 'state',
                    options: {
                      label: 'State'
                    }
                  }
                ]
              },
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      edit: 'TextInput',
                      default: 'FieldWithLabel'
                    },
                    source: 'zipcode',
                    options: {
                      label: 'Zipcode'
                    }
                  }
                ]
              }
            ]
          },
          // Billing Address
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: 'SeargentSubtitle',
                    source: 'Billing Address'
                  },
                  {
                    component: {
                      edit: 'BillingPlacesSearch',
                      default: 'FieldWithLabel'
                    },
                    source: 'billingAddressLine1',
                    options: {
                      label: 'Address Line 1',
                      placeholder: '',
                      fullWidth: true,
                      setSource: false
                    }
                  }
                ]
              }
            ]
          },
          // Billing Address Line 2
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      edit: 'TextInput',
                      default: 'FieldWithLabel'
                    },
                    source: 'billingAddressLine2',
                    options: {
                      label: 'Address Line 2',
                      placeholder: ''
                    }
                  }
                ]
              }
            ]
          },
          // Billing City, State, Zipcode
          {
            options: {
              size: 3,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      edit: 'TextInput',
                      default: 'FieldWithLabel'
                    },
                    source: 'billingCity',
                    options: {
                      label: 'City'
                    }
                  }
                ]
              },
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      edit: 'TextInput',
                      default: 'FieldWithLabel'
                    },
                    source: 'billingState',
                    options: {
                      label: 'State'
                    }
                  }
                ]
              },
              {
                options: {
                  direction: 'column',
                  grow: 1,
                  padding: 12
                },
                contents: [
                  {
                    component: {
                      edit: 'TextInput',
                      default: 'FieldWithLabel'
                    },
                    source: 'billingZipcode',
                    options: {
                      label: 'Zipcode'
                    }
                  }
                ]
              }
            ]
          },
          {
            options: {
              direction: 'column',
              grow: 1,
              padding: 12
            },
            contents: [
              {
                component: {
                  edit: 'CheckboxInput',
                  default: null
                },
                source: 'sameAddress',
                options: {
                  label: 'Billing address same as shipping',
                  checked: false,
                  isRequired: false,
                  fullWidth: true,
                  alignment: 'left',
                  onChange: (value, form) => {
                    const { addressLine1, addressLine2, city, state, zipcode } = form.values;
                    if (value) {
                      form.setValues({
                        ...form.values,
                        billingAddressLine1: addressLine1,
                        billingAddressLine2: addressLine2,
                        billingCity: city,
                        billingState: state,
                        billingZipcode: zipcode,
                        sameAddress: value
                      });
                    }
                  }
                }
              }
            ]
          },
          // Department Skills
          {
            options: {
              direction: 'column',
              grow: 1,
              padding: 12
            },
            contents: [
              {
                component: 'SeargentSubtitle',
                source: 'Department Skills'
              },
              {
                component: {
                  edit: 'SelectInput',
                  default: 'SkillsView'
                },
                source: 'skills',
                options: {
                  label: '',
                  fullWidth: true,
                  alignment: 'left',
                  isRequired: false,
                  valueSet: options.companySkills,
                  isMultipleSelection: true,
                  maxMenuHeight: 150
                }
              }
            ]
          },
          // Department Members
          {
            options: {
              direction: 'column',
              grow: 1,
              padding: 12
            },
            contents: [
              {
                component: 'SeargentSubtitle',
                source: 'Department Members'
              },
              {
                component: {
                  edit: 'EmployeesView',
                  default: 'EmployeesView'
                },
                source: 'employees',
                options: {
                  label: '',
                  value: '',
                  placeholder: '',
                  fullWidth: true,
                  alignment: 'left',
                  readOnly: false,
                  isRequired: false
                }
              }
            ]
          }
        ]
      }
    },
    validation: {
      type: 'object',
      properties: {
        tagName: {
          type: 'string'
        },
        intacctEntityId: {
          type: 'string',
          nullable: true
        },
        intacctLocationId: {
          type: 'string',
          nullable: true
        }
      },
      required: ['tagName'].concat(
        (options.isQuickbooksEnabled && ['accountingRefIdOfClass']) ||
          (options.isIntacctEnabled && ['intacctEntityId', 'intacctLocationId']) ||
          []
      )
    },
    validationErrors: {
      tagName: {
        required: 'Department name is required.'
      },
      intacctEntityId: {
        required: 'Intacct entity is required.'
      },
      intacctLocationId: {
        required: 'Intacct location is required.'
      }
    }
  };
};

// skillsList meta for responsive table
export const skillsListMeta = [
  {
    id: 'id',
    label: 'Skills Offered',
    isCustom: true,
    type: 'TextInput'
  }
];
