import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  newItem: {
    backgroundColor: theme.palette.other.genoa,
    borderRadius: 3,
    boxShadow: 'none',
    color: theme.palette.grayscale(100),
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    letterSpacing: 0,
    textAlign: 'center',
    textTransform: 'uppercase',
    height: 32,
    '&:hover': {
      backgroundColor: theme.palette.other.genoa,
      boxShadow: 'none'
    }
  },
  container: {
    marginBottom: 4
  }
}));
