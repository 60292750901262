import gql from 'graphql-tag';

import useExtendedMutation from 'customHooks/useExtendedMutation';
import { getState } from 'redux/store';
import mergeDeep from 'utils/mergeDeep';

import watchedQueries from '../queries/watchedQueries';

const DELETE_REVIEW_REPORT_BILL_ITEMS = gql`
  mutation deleteReviewReportBillItem($partitionKey: String, $id: String!) {
    deleteReviewReportBillItem(partitionKey: $partitionKey, id: $id) {
      id
      billLineId
    }
  }
`;

const optimisticResponseFactory = ({ id, billLineId }) => ({
  deleteReviewReportBillItem: { id, billLineId, __typename: 'ReviewReportBillItem' }
});

export const useDeleteReviewReportBillItem = () => {
  return useExtendedMutation(DELETE_REVIEW_REPORT_BILL_ITEMS, {
    serializer: ({ id }) => ({
      variables: {
        partitionKey: getState()?.company?.partitionKey,
        id
      }
    }),
    optimisticResponseFactory,
    showConfirmation: true,
    confirmationModalOptions: {
      title: 'Delete Bill Line Item',
      warn: true,
      modalText: 'Are you sure you want to delete this bill line item?'
    },
    update: (cache, { data }) => {
      const cachedReviewReport = cache.readQuery(watchedQueries.GET_REVIEW_REPORT_BY_ID);

      const update = cachedReviewReport.getReviewReportById.reviewReportBillItems.items.filter(
        billItem => billItem.billLineId !== data.deleteReviewReportBillItem.billLineId
      );

      update.replace = true;

      cache.writeQuery({
        ...watchedQueries.GET_REVIEW_REPORT_BY_ID,
        data: mergeDeep(cachedReviewReport, {
          getReviewReportById: {
            reviewReportBillItems: {
              items: update
            }
          }
        })
      });
    }
  });
};
