import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connectAutoComplete } from 'react-instantsearch-dom';
import AutoSuggest from 'react-autosuggest';
import { ThemeProvider, Typography, TV, TW } from '@buildhero/sergeant';
import theme from 'themes/BuildHeroTheme';
import { getHitName } from './utils';

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    const { formProps, currentRefinement } = props;
    this.state = {
      value: formProps.value || currentRefinement
    };
  }

  onChange = (_, { newValue }) => {
    if (!newValue) this.props.onSuggestionCleared();
    this.setState({ value: newValue });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.refine(value);
  };

  onSuggestionsClearRequested = () => {
    this.props.refine();
  };

  getSuggestionValue = hit => {
    // TODO find better way to do this
    const { formProps } = this.props;
    const attr = formProps.displayText[0];
    if (attr === 'jobNumber') {
      return hit.customIdentifier || hit.jobNumber || hit.projectName || hit.projectNumber;
    }
    return hit[attr];
  };

  renderSuggestion = hit => {
    const { formProps } = this.props;
    const { displayText, delimiter, customHitFormatter, showEntityNameInResults } = formProps;
    const hitName = getHitName(
      hit,
      displayText,
      delimiter,
      showEntityNameInResults,
      customHitFormatter
    );
    return <div testingid={hitName}>{hitName}</div>;
  };

  render() {
    const { hits, onSuggestionSelected, formProps } = this.props;
    const { value } = this.state;
    const inputProps = {
      ...formProps,
      onChange: this.onChange,
      value,
      error: !!formProps?.errorMsg
    };

    return (
      <>
        <AutoSuggest
          suggestions={hits}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
        />
        {(inputProps?.errorMsg || inputProps?.helperText) && (
          <ThemeProvider>
            <Typography
              variant={TV.S1}
              weight={TW.MEDIUM}
              color={
                inputProps?.errorMsg ? theme.palette.error.main : theme.palette.brand.sectionHint
              }
              style={{ marginTop: '-0.8rem', hyphens: 'none' }} // autosuggest container has margin bottom of 1 rem
            >
              {inputProps.errorMsg || inputProps.helperText}
            </Typography>
          </ThemeProvider>
        )}
      </>
    );
  }
}

AutoComplete.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentRefinement: PropTypes.string.isRequired,
  refine: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  onSuggestionCleared: PropTypes.func.isRequired
};

AutoComplete.defaultProps = {};

export default connectAutoComplete(AutoComplete);
