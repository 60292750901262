import React from 'react';
import ReportTable from '../../../ReportTable';
import { selectAttachments } from '../../../../selectors';
import { ReportTableSectionDefaultProps, ReportTableSectionPropTypes } from '../../../../propTypes';
import { useTechReportColumns } from './TechnicianReportAttachmentTable.hooks';

const TechnicianReportAttachmentTable = ({ visit, loading, error }) => {
  const title = 'Photos & Videos';
  const noDataMessage = 'No Photos & Videos';
  const rows = selectAttachments(visit) || [];
  const cols = useTechReportColumns();

  return (
    <ReportTable
      title={title}
      columns={cols}
      rows={rows}
      loading={loading}
      error={error}
      noDataMessage={noDataMessage}
    />
  );
};
TechnicianReportAttachmentTable.propTypes = ReportTableSectionPropTypes;
TechnicianReportAttachmentTable.defaultProps = ReportTableSectionDefaultProps;

export default TechnicianReportAttachmentTable;
