import gql from 'graphql-tag';

const getQuoteInfoById = gql`
  query getQuoteById($id: String!) {
    getQuoteById(id: $id) {
      id
      tenantId
      tenantCompanyId
      partitionKey
      sortKey
      tenantId
      hierarchy
      entityType
      version
      versionLabel
      versionNumber
      orderedById
      propertyRepId
      detailsShownToCustomer
      hideTotalAmount
      name
      quoteNumber
      dateSent
      customIdentifier
      createdDate
      createdFromMobile
      description
      issueDescription
      billTo
      departmentId
      departmentValue
      expirationDate
      parentId
      serviceAgreementId
      priceBookId
      quoteQuoteTags(entityConnection: "QuoteQuoteTag") {
        items {
          id
          entityType
          quoteTag(entityConnection: "QuoteTag") {
            id
            entityType
            tagName
          }
        }
      }
      department(entityConnection: "Department") {
        id
        tagName
        logoUrl
        phonePrimary
        email
        accountingRefIdOfClass
        companyAddresses {
          items {
            addressLine1
            addressLine2
            addressType
            city
            state
            zipcode
          }
        }
      }
      parentEntity {
        ... on Opportunity {
          id
          parentEntity {
            ... on CustomerProperty {
              id
              companyName
              partitionKey
              sortKey
              hierarchy
              entityType
              version
              tenantId
              tenantCompanyId
              parentId
              billingCustomer(entityConnection: "Customer") {
                id
                customerName
                isActive
                isTaxable
                taxRateId
                companyAddresses(entityConnection: "CompanyAddress") {
                  items {
                    id
                    tenantId
                    tenantCompanyId
                    partitionKey
                    sortKey
                    hierarchy
                    entityType
                    addressLine1
                    addressLine2
                    longitude
                    latitude
                    state
                    city
                    zipcode
                    addressType
                    entityType
                    sortKey
                    billTo
                  }
                }
              }
              notes(entityConnection: "Note", limit: 100) {
                items {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  subject
                  note
                  createdDate
                  createdDateTime
                  createdBy
                  lastUpdatedDate
                  lastUpdatedDateTime
                  lastUpdatedBy
                }
              }
              companyAddresses: companyAddresses(entityConnection: "CompanyAddress") {
                items {
                  billTo
                  addressType
                  addressLine1
                  addressLine2
                  city
                  state
                  zipcode
                  country
                  version
                }
              }
              attachments: attachments(entityConnection: "Attachment") {
                items {
                  customFileName
                  fileName
                  fileUrl
                  fileSize
                  description
                  comment
                  type
                  parentId
                  parentSortKey
                  hierarchy
                  id
                  version
                  sortKey
                  createdDateTime
                  createdDate
                  createdBy
                  lastUpdatedDate
                  lastUpdatedDateTime
                }
              }
              parentEntity {
                ... on Customer {
                  id
                  sortKey
                  customerName
                  companyAddresses(entityConnection: "CompanyAddress") {
                    items {
                      addressLine1
                      addressLine2
                      state
                      city
                      zipcode
                      addressType
                      entityType
                      sortKey
                      billTo
                    }
                  }
                }
              }
            }
          }
        }
      }
      quoteJobs(entityConnection: "QuoteJob") {
        items {
          id
          job(entityConnection: "Job") {
            id
            version
            jobNumber
            customIdentifier
          }
        }
      }
      attachments: attachments(entityConnection: "Attachment") {
        items {
          customFileName
          fileName
          fileUrl
          fileSize
          description
          comment
          type
          parentId
          parentSortKey
          hierarchy
          id
          version
          sortKey
          createdDateTime
        }
      }
      opportunity: parentEntity {
        ... on Opportunity {
          versionedQuotes(
            entityConnection: "VersionedQuote"
            filter: { versionOfEntityId: { eq: $id } }
          ) {
            items {
              id
              versionLabel
              versionNumber
              totalAmountQuoted
              totalAmountQuotedOverride
              lastUpdatedDateTime
              lastUpdatedBy
              status
              quoteQuoteTags(entityConnection: "QuoteQuoteTag") {
                items {
                  id
                  entityType
                  quoteTag(entityConnection: "QuoteTag") {
                    id
                    entityType
                    tagName
                  }
                }
              }
            }
          }
        }
      }
      status
      jobTypeId
      jobTypeValue
      ownerId
      ownerValue
      salesById
      salesByValue
      taxRateId
      taxRateValue
      customerPoNumber
      isActive
      discount
      termsOfService
      rejectedReason
      lastSubmissionDate
      lastUpdatedDateTime
      lastUpdatedBy
      totalAmountQuoted
      totalAmountQuotedOverride
      subTotalOverride
      subTotalAmountTaxableOverride
      totalTaxAmountOverride
      subTotalAmountTaxable
      subTotalAmountNonTaxable
      totalTaxAmount
      taskSubTotal
      subTotal
      owner(entityConnection: "owner") {
        id
        accountingRefId
        name
        email
        cellPhone
        landlinePhone
      }
    }
  }
`;
export default getQuoteInfoById;
