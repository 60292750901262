import { useExtendedFetchForPMAPI as useExtendedFetch } from 'customHooks/useExtendedFetch';

export const useFetchCustomerReps = (options = {}, params) => {
  return useExtendedFetch(
    {
      url: `customerrep`,
      params
    },
    {
      errorData: [],
      defaultData: [],
      ...options
    }
  );
};
