import React, { useState, useReducer } from 'react';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTheme } from '@material-ui/core';
import { PermissionConstants } from 'utils/AppConstants';
import AmplifyService from 'services/AmplifyService';
import ErrorBoundary from 'scenes/Error';
import { UserPermission, PageHeader, ResponsiveTable, SyncStatusIcon } from 'components';
import { snackbarOn } from 'redux/actions/globalActions';
import { Button, Divider, ThemeProvider } from '@buildhero/sergeant';
import RowMetadata from './Adjustments.columns';
import { GetAdjustments } from './Adjustments.gql';
import AdjustmentModal from './Adjustment/Adjustment.modal';
import { TransactionList } from './Adjustment/components';

export const TableTransactionList = ({
  record: {
    transactions: { items }
  }
}) => <TransactionList items={items} />;

function Adjustments({ user, snackbar, history }) {
  const { spacing } = useTheme();
  const { adjustments: flag } = useFlags();
  const [refreshCounter, refresh] = useReducer(x => x + 1, 0);
  const [open, setOpen] = useState(false);
  if (!flag) return null;

  const getAdjustments = async (
    filter,
    limit,
    offset,
    sortField = 'createdDate',
    sortDirection = 'DESC'
    // status
  ) => {
    const { client } = AmplifyService.appSyncClient();
    const { tenantId, tenantCompanyId } = user;
    try {
      const {
        data: {
          getCompany: { adjustments }
        }
      } = await client.query({
        query: GetAdjustments,
        variables: {
          partitionKey: tenantId,
          sortKey: `${tenantId}_Company_${tenantCompanyId}`,
          filter,
          limit,
          offset,
          sort: [{ sortField, sortDirection }]
        }
      });
      return adjustments;
    } catch (error) {
      snackbar('error', 'Failed to load adjustments. Please try again.');
    }
    return { items: [], nextToken: 0 };
  };

  return (
    <UserPermission I="read" action={PermissionConstants.OBJECT_INVOICE}>
      <ErrorBoundary>
        <PageHeader pageMapKey="adjustments" userLocale={user.locale}>
          <ThemeProvider>
            <Button onClick={() => setOpen(true)}>New Adjustment</Button>
          </ThemeProvider>
        </PageHeader>
        <ThemeProvider>
          <Divider marginLeft={-spacing(3)} width={`calc(100% + ${spacing(6)}px)`} />
        </ThemeProvider>
        <ResponsiveTable
          rowMetadata={RowMetadata}
          fullScreen
          service={getAdjustments}
          showToolbars
          tableName="Adjustments"
          noDataMsg="No Adjustments"
          caslKey={PermissionConstants.OBJECT_INVOICE}
          key={refreshCounter}
          customCellComponents={{
            TransactionList: TableTransactionList,
            syncStatus: ({ record }) => <SyncStatusIcon status={record.syncStatus} />
          }}
        />
        <AdjustmentModal
          open={open}
          onClose={id => {
            setOpen(false);
            if (id) history.push(`/adjustment/view/${id}`);
          }}
        />
      </ErrorBoundary>
    </UserPermission>
  );
}

export default connect(state => state, { snackbar: snackbarOn })(Adjustments);
