import React from 'react';
import { Typography } from '@material-ui/core';
import ErrorBoundaries from 'scenes/Error';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { SplitButton } from 'components';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from './style';

const AddNewView = props => {
  const { classes, handleAddNew, label, disabled, keyName, description, isReadOnly } = props;

  return (
    <div className={classes.addCardOuter}>
      <div className={classes.addCardInner}>
        <ErrorBoundaries>
          <Typography variant="h3">Nothing here...</Typography>
          {!isReadOnly && (
            <>
              <Typography variant="body2" className={`${classes.rateDescription} ${classes.mb2}`}>
                {description}
              </Typography>
              <SplitButton
                label={label}
                onClick={handleAddNew}
                disabled={disabled}
                key={keyName}
                buttonProps={{
                  startIcon: <AddCircleOutlineIcon />
                }}
              />
            </>
          )}
        </ErrorBoundaries>
      </div>
    </div>
  );
};

AddNewView.propTypes = {
  classes: PropTypes.object,
  handleAddNew: PropTypes.func.isRequired,
  label: PropTypes.string,
  keyName: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  isReadOnly: PropTypes.bool
};

AddNewView.defaultProps = {
  label: '',
  keyName: '',
  description: '',
  classes: {},
  disabled: false,
  isReadOnly: false
};

export default withStyles(styles, { withTheme: true })(AddNewView);
