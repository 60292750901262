export const useStyles = () => ({
  swimLane: {
    display: 'flex',
    backgroundColor: 'white'
  },
  daysContainer: {
    width: '100%',
    display: 'flex'
  }
});
