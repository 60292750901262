import React from 'react';

import isNumber from 'lodash/isNumber';
import { any, array, bool } from 'prop-types';

import {
  tableCurrencyFormatter,
  tableEmptyValueFormatter,
  tablePercentageFormatter
} from 'components/WrapTable';
import { TablePlaceholderVariant } from 'components/WrapTable/WrapTable.constants';
import WrapTable from 'components/WrapTable/WrapTable.container';

export const lineItemColumns = [
  {
    field: 'id',
    hide: true
  },
  {
    field: 'itemName',
    flex: 1,
    headerName: 'Name',
    valueFormatter: tableEmptyValueFormatter
  },
  {
    field: 'description',
    flex: 1,
    headerName: 'Description',
    valueFormatter: tableEmptyValueFormatter
  },
  {
    field: 'quantity',
    flex: 1,
    headerName: 'Quantity',
    valueFormatter: tableEmptyValueFormatter
  },
  {
    field: 'unitCost',
    flex: 1,
    headerName: 'Unit cost',
    valueFormatter: tableCurrencyFormatter
  },
  {
    field: 'markup',
    flex: 1,
    headerName: 'Markup',
    valueFormatter: tablePercentageFormatter
  },
  {
    field: 'unitPrice',
    flex: 1,
    headerName: 'Unit price',
    valueFormatter: tableCurrencyFormatter
  },
  {
    field: 'totalAmount',
    flex: 1,
    headerName: 'Total',
    valueFormatter: tableCurrencyFormatter
  }
];

export const getPurchasedLineItemRows = items =>
  items?.map(lineItem => ({
    ...lineItem,
    totalAmount:
      isNumber(lineItem?.quantity) && isNumber(lineItem?.unitPrice)
        ? lineItem.quantity * lineItem.unitPrice
        : undefined
  })) || [];

const PurchasedLineItemsTable = ({ lineItems, loading, error }) => {
  const rows = getPurchasedLineItemRows(lineItems);
  return (
    <WrapTable
      placeholderVariant={TablePlaceholderVariant.TEXT}
      columns={lineItemColumns}
      rows={rows}
      loading={loading}
      error={error}
      noDataMessage="No Purchased Line Items"
    />
  );
};

PurchasedLineItemsTable.propTypes = {
  lineItems: array.isRequired,
  loading: bool.isRequired,
  error: any
};

PurchasedLineItemsTable.defaultProps = {
  error: undefined
};

export default PurchasedLineItemsTable;
