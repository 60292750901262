export const materialRangesFromJSON = materialMarkupJSON => {
  let ranges;
  try {
    if (materialMarkupJSON) {
      ranges = JSON.parse(materialMarkupJSON);
    }
  } catch {
    ranges = undefined;
  }

  return ranges;
};

export const isFlatMaterialMarkupRange = materialMarkups => {
  if (!Array.isArray(materialMarkups)) {
    return false;
  }
  return materialMarkups.length === 1;
};

export const isMultipleMaterialMarkupRange = materialMarkups => {
  if (!Array.isArray(materialMarkups)) {
    return false;
  }
  return materialMarkups.length > 1;
};
