const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    //   marginTop: theme.spacing(),
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(2)
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  noOptionsMessage: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`
  },
  muiFilledInput: {
    backgroundColor: '#f6f6f6',
    borderRadius: 4
  },

  inputRoot: {
    flexWrap: 'wrap',
    color: '#646464'
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
    backgroundColor: '#f6f6f6',
    borderRadius: 4,
    paddingTop: 20,
    color: '#646464'
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    // backgroundColor: "#f2f2f2",
    color: '#3f3f3f'
  },
  input: {
    display: 'flex',
    padding: '25px 5px 5px'
  },
  valueContainer: {
    display: 'flex',
    //  flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    paddingLeft: 10,
    height: 19
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  singleValue: {
    fontSize: 16,
    //    padding: '25px 12px 5px',
    borderRadius: 4,
    font: 'inherit',
    color: 'currentColor',
    whiteSpace: 'nowrap'
  },
  clearIndicator: {
    padding: '2px'
  },
  dropdownIndicator: {
    padding: '2px'
  }
});

export default styles;
