import gql from 'graphql-tag';

const deleteReviewReportBillItem = gql`
  mutation deleteReviewReportBillItem($partitionKey: String, $id: String!) {
    deleteReviewReportBillItem(partitionKey: $partitionKey, id: $id) {
      id
      reviewReportId
      billLineId
    }
  }
`;

export default deleteReviewReportBillItem;
