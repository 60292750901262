const sendEmailForm = formData => {
  return {
    fields: {
      recipients: {
        default: 'recipients'
      },
      emailSubject: {
        default: 'emailSubject'
      },
      description: {
        default: 'description'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0,
          width: 642,
          padding: [0, 0, 0, 8]
        },
        contents: [
          {
            options: {
              direction: 'row',
              grow: 1
            },
            contents: [
              {
                component: 'CustomFilContainer',
                source: 'pdfcontainer',
                options: {
                  fileName: formData.uploadedPdfFileName,
                  fileUrl: formData.uploadedPdfUrl
                }
              }
            ]
          },
          {
            options: {
              direction: 'column',
              grow: 1,
              padding: [15, 0, 0, 0]
            },
            contents: [
              {
                component: 'SelectInput',
                source: 'recipients',
                options: {
                  creatable: true,
                  isSearchable: true,
                  label: 'Email To',
                  isRequired: true,
                  isMultipleSelection: true,
                  valueSet: formData.recipients,
                  onCreate: formData.onCreate
                }
              }
            ]
          },
          {
            options: {
              direction: 'column',
              grow: 1,
              padding: [15, 0, 0, 0]
            },
            contents: [
              {
                component: 'TextInput',
                source: 'emailSubject',
                options: {
                  label: 'Subject Line',
                  placeholder: 'Add a subject',
                  isRequired: true
                }
              }
            ]
          },
          {
            options: {
              direction: 'column',
              grow: 1,
              padding: [15, 0, 0, 0]
            },
            contents: [
              {
                component: 'TextInput',
                source: 'description',
                options: {
                  label: 'description',
                  fullWidth: true,
                  lines: 4,
                  isRequired: true,
                  alignment: 'left',
                  placeholder: 'Enter Description'
                }
              }
            ]
          }
        ]
      }
    },
    validation: {
      type: 'object',
      properties: {
        recipients: {
          type: 'string'
        },
        emailSubject: {
          type: 'string'
        },
        description: {
          type: 'string'
        }
      },
      required: ['recipients', 'emailSubject', 'description']
    }
  };
};

export default sendEmailForm;
