import { MIXPANEL_EVENT, MIXPANEL_PAGE_NAME } from 'constants/mixpanelEvents';
import { QuoteService } from 'services/core';

import { sendMixpanelEvent } from 'services/mixpanel';

import QuotesUtils from '../../../../Quotes/index.utils';

const getPropertyRepId = jobData => {
  const reps = jobData?.customerProperty?.customerReps?.items?.[0]?.mappedEntity;
  return reps?.id;
};

const jobToQuoteValueMapping = ({ jobData, currentProperty }) => {
  const composedPropertyDetails = QuotesUtils.composePropertyDetails(currentProperty);
  return {
    customerAndPropertyAndAddress: '',
    customerName: jobData?.customerName,
    propertyName: composedPropertyDetails?.property?.companyName,
    propertyAddress: composedPropertyDetails?.propertyAddress,
    customerId: jobData?.customerId,
    customerPropertyId: composedPropertyDetails?.property?.id,
    taxRateId: composedPropertyDetails?.property?.taxRateId,
    taxRateValue: composedPropertyDetails?.property?.taxRate?.taxRate,
    versionNumber: composedPropertyDetails?.property?.version,
    billTo: composedPropertyDetails.billTo,
    expirationLength: 30,
    ownerId: jobData?.ownerId,
    customerPoNumber: jobData?.customerProvidedPONumber || '',
    salesById: jobData?.soldById,
    orderedById: jobData?.authorizedById,
    propertyRepId: getPropertyRepId(jobData),
    departmentId: jobData?.departments?.items?.[0]?.id || '',
    departmentValue: jobData?.departments?.items?.[0]?.mappedEntityValue || '',
    priceBookId: jobData?.priceBookId,
    serviceAgreementId: jobData?.serviceAgreementId,
    accountManagerId: jobData?.accountManagerId,
    accountManagerValue: jobData?.accountManager?.name,
    detailsShownToCustomer: 'LineItemsWithGrandTotalOnly',
    issueDescription: jobData?.issueDescription || '',
    status: 'Draft'
  };
};

const addJobToQuotePayload = ({ jobData, currentProperty, user, quoteResult }) => {
  const composedPropertyDetails = QuotesUtils.composePropertyDetails(currentProperty);
  return {
    partitionKey: user.tenantId,
    action: 'ADD_JOB_FROM_QUOTE',
    data: {
      customerPropertyId: composedPropertyDetails?.property?.id,
      jobId: jobData?.id,
      quote: {
        id: quoteResult[0].id,
        version: quoteResult[0]?.version,
        totalBudgetedHours: 0,
        status: 'Draft'
      }
    }
  };
};

const navigateToQuote = (quoteResponse, history) => {
  if (quoteResponse && quoteResponse.length > 0) {
    history.push(`/quote/${quoteResponse[0].id}`);
  }
};

export const addQuoteFromJob = async ({
  currentProperty,
  jobData,
  history,
  user,
  companyEmployees,
  snackbar
}) => {
  const quoteService = new QuoteService();
  const quoteValues = jobToQuoteValueMapping({ jobData, currentProperty });
  let quoteResult = null;
  try {
    history.push(`/quote/new`);
    const response = await quoteService.addQuoteToCustomerProperty(user.tenantId, quoteValues, {
      employees: companyEmployees
    });
    if (response?.data) {
      quoteResult = response.data?.addQuotesToCustomerProperty;
      snackbar(
        'success',
        `Quote created successfuly ${quoteResult[0].customIdentifier || quoteResult[0].quoteNumber}`
      );
      sendMixpanelEvent(MIXPANEL_EVENT.CREATED_QUOTE, MIXPANEL_PAGE_NAME.QUOTES);

      const quoteJobPayload = addJobToQuotePayload({ jobData, currentProperty, user, quoteResult });
      const quoteJobResponse = await new QuoteService().updateQuoteUsingTransition(quoteJobPayload);
      if (quoteJobResponse?.data?.quoteTransition) {
        navigateToQuote(quoteResult, history);
        snackbar('success', 'Quote added to existing job successfully');
        sendMixpanelEvent(MIXPANEL_EVENT.CREATED_JOB_FROM_QUOTE, MIXPANEL_PAGE_NAME.QUOTES);
      }
    }
  } catch (error) {
    navigateToQuote(quoteResult, history);
    snackbar('error', `Unable to create quote from job: ${error}`);
  }
};
