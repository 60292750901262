import React from 'react';
import { useApolloClient } from '@apollo/client';

import { withDnd } from 'components/DnDProvider';
import { withQueryStringProvider } from 'customHooks/useQueryStringStore';
import compose from 'utils/compose';

import * as Actions from './Dispatch.actions';
import Dispatch from './Dispatch.component';
import { querystringReducer } from './Dispatch.reducer';
import { withDispatchProvider, withQueryStringStore } from './Dispatch.store';
import { withDragLayer } from './dnd';
import { withModal } from './hocs';
import { withDispatchSettings } from './settings';
import {
  useVisitSubscription,
  useNonVisitEventSubscription,
  useManDaySubscription
} from './subscription';

const DataProvider = props => {
  const client = useApolloClient();

  useVisitSubscription();
  useNonVisitEventSubscription();
  useManDaySubscription();

  return <Dispatch {...props} client={client} />;
};

const mapQueryStringToProps = store => ({
  mapView: Boolean(store.state.mapView)
});

// node querystring spec will return an array as a string if there is only one value
// supplying this enum will normalize the value back to an array
const ArrayValues = {
  crews: 'crews',
  departments: 'departments',
  visitStatuses: 'visitStatuses',
  technicians: 'technicians'
};

export default compose(
  withQueryStringProvider(querystringReducer, Actions, { arrayKeys: ArrayValues }),
  withDispatchProvider,
  withDnd,
  withDragLayer,
  withModal,
  withQueryStringStore(mapQueryStringToProps),
  withDispatchSettings
)(DataProvider);
