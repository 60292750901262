/* eslint-disable no-useless-concat */
/* eslint-disable no-template-curly-in-string */

const southwestMechanical = settings => ({
  fields: {
    senderInfoLogoUrl: {
      pdf: '${senderInfoLogo}'
    },
    tenantAddress: {
      pdf:
        '${senderInfo.addressLine1}. ${senderInfo.addressLine3}  *  O: ${senderInfo.phoneNumber}  *  F: ${senderInfo.faxNumber}  *  CA Lic #761417'
    },
    senderInfoAddressLine1: {
      pdf: 'Address: ${senderInfo.addressLine1}'
    },
    senderInfoAddressLine2: {
      pdf: '${senderInfo.addressLine2}'
    },
    senderInfoAddressLine3: {
      pdf: '${senderInfo.addressLine3}'
    },
    // Customer Detail
    customerContactNameTitle: {
      pdf: 'Attn: ${customerContactNameTitle}'
    },
    customerContactName: {
      pdf: '${quoteCustomFieldsData.Custom_text4}'
    },
    customerNameTitle: {
      pdf: 'Company:'
    },
    customerName: {
      pdf: '${quoteCustomFieldsData.Custom_text1}'
    },
    billingAddressLine1Title: {
      pdf: 'Address: '
    },
    billingBillTo: {
      pdf: '${quoteInfo.billTo}'
    },
    billingAddressLine1: {
      pdf: '${billingAddressLine1}'
    },
    billingAddressLine2: {
      pdf: '${billingAddressLine2}'
    },
    billingAddressLine3: {
      pdf: '${billingAddressLine3}'
    },
    customerContactPhoneTitle: {
      pdf: 'Phone: ${customerContactPhoneTitle}'
    },
    customerContactPhone: {
      pdf: '${quoteCustomFieldsData.Custom_text2}'
    },
    customerContactEmailTitle: {
      pdf: 'Via Email: ${customerContactEmailTitle}'
    },
    customerContactEmail: {
      pdf: '${quoteCustomFieldsData.Custom_text3}'
    },
    // Tenant Detail
    createdDateTitle: {
      pdf: 'Date: '
    },
    createdDate: {
      pdf: '${createdDate}'
    },
    quoteNameTitle: {
      pdf: 'Re: '
    },
    quoteName: {
      pdf: '${quoteInfo.name}'
    },
    propertyLabel: {
      pdf: 'Site: '
    },
    propertyName: {
      pdf: '${property.companyName}'
    },
    propertyAddress1: {
      pdf: '${propertyDetail.propertyAddressLine1}'
    },
    propertyAddress2: {
      pdf: '${propertyDetail.propertyAddressLine2}'
    },
    propertyAddress3: {
      pdf: '${propertyDetail.propertyAddressLine3}'
    },
    quoteNumberValueTitle: {
      pdf: 'File Number: '
    },
    quoteNumberValue: {
      pdf: '${customizedQuoteNumber}'
    },
    ownerValueTitle: {
      pdf: 'From: '
    },
    ownerValue: {
      pdf: '${quoteInfo.ownerValue}'
    },
    senderInfoEmailTitle: {
      pdf: 'Email: '
    },
    senderInfoEmail: {
      pdf: '${owner.email}'
    },
    // Introduction
    introduction: {
      pdf: '${quoteCustomFieldsData.Custom_longText1}'
    },
    // Current Conditions
    currentCondition: {
      pdf: '${quoteCustomFieldsData.Custom_longText2}'
    },
    // Proposed Solution
    proposedSolution: {
      pdf: '${quoteCustomFieldsData.Custom_longText3}'
    },
    // Scope of Work
    scopeOfWork: {
      pdf: '${quoteCustomFieldsData.Custom_longText4}'
    },
    // Quotation
    quotation: {
      pdf: '${quoteCustomFieldsData.Custom_longText5}'
    },
    pricing: {
      pdf: '${quoteCustomFieldsData.Custom_longText6}'
    },
    // Total
    totalTitle: {
      pdf: 'Total Proposal as Outlined Above .................. '
    },
    totalText: {
      pdf: '$${finalAmount}'
    },
    // Closing Remarks
    closingRemarks: {
      pdf: '${quoteCustomFieldsData.Custom_longText7}'
    },
    thankYou: {
      pdf: '\nThank you for the opportunity to be of service.\n'
    },
    signOff: {
      pdf: 'Sincerely,\n\n\n'
    },
    // Estimator
    estimator: {
      pdf: '${quoteInfo.ownerValue}\nSales Manager\n${senderInfo.companyName}'
    },
    // Legal
    legal: {
      pdf: '${quoteCustomFieldsData.Custom_longText7}'
    }
  },
  layouts: {
    default: {
      options: {
        pdfTitle: 'Quote'
      },
      contents: [
        // Logo Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              options: {
                direction: 'column',
                size: 'auto',
                grow: 0,
                shrink: 1
                // debug: true
              },
              contents: [
                {
                  component: {
                    default: 'Logo'
                  },
                  options: {
                    width: 600,
                    height: 120,
                    alt: 'Company logo',
                    size: 'auto',
                    grow: 0,
                    shrink: 1
                  },
                  source: 'senderInfoLogoUrl'
                }
              ]
            }
          ]
        },
        // Address Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [0, 0, 12]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'BodyText'
              },
              source: 'tenantAddress'
            }
          ]
        },
        // Customer Detail Row
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'space-between',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            // Customer Details
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                direction: 'column',
                padding: [0, 5, 0, 0],
                width: '65%'
              },
              contents: [
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactName'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerName'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'billingAddressLine1Title'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'billingBillTo'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactPhoneTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactPhone'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'customerContactEmailTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'customerContactEmail'
                    }
                  ]
                }
              ]
            },
            // Tenant Details
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                direction: 'column',
                width: '30%'
              },
              contents: [
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'createdDateTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'createdDate'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'quoteNameTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'quoteName'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'quoteNumberValueTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'quoteNumberValue'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'ownerValueTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'ownerValue'
                    }
                  ]
                },
                {
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1,
                    direction: 'row'
                  },
                  contents: [
                    {
                      component: { pdf: 'BodyText' },
                      source: 'senderInfoEmailTitle'
                    },
                    {
                      component: { pdf: 'BoldText' },
                      source: 'senderInfoEmail'
                    }
                  ]
                }
              ]
            }
          ]
        },
        // Divider
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'center',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                pdf: 'Divider'
              }
            }
          ]
        },
        // Intro
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                pdf: 'RichContentViewer'
              },
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1
              },
              source: 'introduction'
            }
          ]
        },
        // Current Condition
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                pdf: 'RichContentViewer'
              },
              options: {
                label: 'Current Condition',
                size: 'auto',
                grow: 0,
                shrink: 1
              },
              source: 'currentCondition'
            }
          ]
        },
        // Inclusions
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              options: {
                direction: 'column',
                justify: 'start',
                size: 'auto',
                grow: 0,
                shrink: 1,
                padding: [0, 0, 0, 0]
                // debug: true
              },
              contents: [
                {
                  component: {
                    default: 'Text',
                    pdf: 'RichContentViewer'
                  },
                  options: {
                    label: 'Proposed Solution',
                    size: 'auto',
                    grow: 0,
                    shrink: 1
                  },
                  source: 'proposedSolution'
                }
              ]
            }
          ]
        },
        // Scope of Work
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              options: {
                direction: 'column',
                justify: 'start',
                size: 'auto',
                grow: 0,
                shrink: 1,
                padding: [0, 0, 0, 0]
                // debug: true
              },
              contents: [
                {
                  component: {
                    default: 'Text',
                    pdf: 'RichContentViewer'
                  },
                  options: {
                    label: 'Scope of Work',
                    size: 'auto',
                    grow: 0,
                    shrink: 1
                  },
                  source: 'scopeOfWork'
                },
                {
                  component: {
                    pdf: 'TaskList'
                  },
                  options: {
                    size: 'auto',
                    grow: 0,
                    shrink: 1
                  },
                  source: 'quoteTasks'
                }
              ]
            }
          ]
        },
        // Quotation
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'RichContentViewer'
              },
              options: {
                label: 'Quotation',
                size: 'auto',
                grow: 0,
                shrink: 1
              },
              source: 'quotation'
            }
          ]
        },
        // Total
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'flex-end',
            align: 'stetch',
            padding: [6, 36, 6],
            hide: settings.hideTotalAmount
            // debug: true
          },
          contents: [
            {
              component: {
                pdf: 'Subtitle'
              },
              source: 'totalTitle'
            },
            {
              component: {
                pdf: 'Subtitle'
              },
              source: 'totalText'
            }
          ]
        },
        // Pricing
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'flex-start',
            align: 'stetch',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                pdf: 'RichContentViewer'
              },
              source: 'pricing'
            }
          ]
        },
        // Closing Remarks
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'RichContentViewer'
              },
              source: 'closingRemarks'
            }
          ]
        },
        // Thank You
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                pdf: 'BoldText'
              },
              source: 'thankYou'
            }
          ]
        },
        // Sign Off
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                pdf: 'BodyText'
              },
              source: 'signOff'
            }
          ]
        },
        // Estimator Name
        {
          options: {
            direction: 'row',
            size: 'auto',
            grow: 0,
            shrink: 1,
            justify: 'start',
            padding: [6, 36, 6]
            // debug: true
          },
          contents: [
            {
              component: {
                default: 'Text',
                pdf: 'SectionWithLabel'
              },
              source: 'estimator'
            }
          ]
        },
        // Terms and conditions Row
        {
          options: {
            direction: 'row',
            padding: [20, 36],
            breakPage: 'before',
            width: '100%'
            // debug: true
          },
          contents: [
            {
              options: {
                size: 'auto',
                grow: 0,
                shrink: 1,
                justify: 'flex-start'
                // debug: true
              },
              // For centering title
              contents: [
                {
                  options: {
                    direction: 'row',
                    justify: 'center',
                    grow: 0,
                    shrink: 1,
                    padding: 5
                  },
                  contents: [
                    {
                      component: {
                        default: 'BoldText'
                      },
                      source: 'TERMS AND CONDITIONS'
                    }
                  ]
                },
                {
                  options: {
                    padding: [5, 0],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    'By accepting this proposal, Purchaser agrees to be bound by the following terms and conditions:'
                },
                {
                  options: {
                    padding: [5, 0],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '1. SCOPE OF WORK. This proposal is based upon the use of straight time labor only. Plastering, patching and painting are excluded. “In-line” duct and piping devices, including, but not limited to, valves, dampers, humidifies, wells, taps, flow meters, orifices, etc., if required hereunder to be furnished by Southwest Mechanical, Inc, shall be distributed and installed by others under Southwest Mechanical, Inc, Inc supervision but at no additional cost to Southwest Mechanical, Inc. Purchaser agrees to provide Southwest Mechanical, Inc with required field utilities (electricity, toilets, drinking, water, project hoist, elevator service, etc.) without charge. Southwest Mechanical, Inc agrees to keep the job site clean of debris arising out of its own operations. Purchaser shall not back charge Southwest Mechanical, Inc for any costs or expenses without Southwest Mechanical, Inc written consent. Unless specifically noted in the statement of the scope of the work or services undertaken by SWM, Inc under this agreement, SWM obligations under this agreement expressly exclude any work or service of any nature associated or connected with the identification, abatement, clean up, control, removal, or disposal of environment Hazards or dangerous substances, to include but not to be limited to asbestos or PCBs, discovered in or on the premises. Any language or provision of the agreement elsewhere contained which may authorize or empower the Purchaser to change, modify, or alter the scope of work or services to be performed by SWM shall not operate to compel SWM, Inc to perform any work relating to Hazards without SWM, Inc express written consent.'
                },
                {
                  options: {
                    padding: [5, 0],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '2. INVOICING & PAYMENTS. Southwest Mechanical, Inc may invoice Purchaser monthly for all materials delivered to the job site or to an off-site storage facility and for the work performed-on-site and off-site. Purchaser shall pay Southwest Mechanical, Inc at the time purchaser signs this agreement an advance payment (but not any progress payment) due hereunder and purchaser agrees to pay Southwest Mechanical, Inc additional amounts invoiced upon receipt of the invoice. Waivers of lien will be furnished upon request, as the work progresses, to the extent payments are received. If Southwest Mechanical, Inc invoice is not paid within 30 days of its issuance, it is delinquent.'
                },
                {
                  options: {
                    padding: [5, 0],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '3. MATERIALS. If the materials or equipment included in this proposal become temporarily or permanently unavailability, the time for performance of the work shall be extended to the extent thereof, and in the case of permanent unavail- ability, Southwest Mechanical, Inc shall (a) be excused from furnishing said materials or equipment, and (b) be eimbursed for the difference between the cost of the materials or equipment permanently unavailable and the cost of a reasonably available substitute therefor.'
                },
                {
                  options: {
                    padding: [5, 0],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '4. WARRANTY. Southwest Mechanical, Inc will extend the same warranty and terms and conditions which Southwest Mechanical, Inc receives from the manufacturer of said equipment. For equipment installed by Southwest Mechanical, Inc, if Purchaser provides written notice to Southwest Mechanical, Inc of any such defect within thirty (30) days after the appearance or discovery of such defect, Southwest Mechanical, Inc shall, at its option, repair or replace the defec- tive equipment and return said equipment to Purchaser. All transportation charges incurred in connection with the warranty for equipment not installed by Southwest Mechanical, Inc shall be borne by Purchaser. These warranties do not extend to any equipment which has been repaired by others, abused altered or misused, or which has not been properly and reasonably maintained. THESE WARRANTIES ARE IN LIEU OF ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THOSE OF MERCHANTABILITY AND FITNESS FOR A SPECIFIC PURPOSE.'
                },
                {
                  options: {
                    padding: [5, 0],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '5. LIABILITY. Southwest Mechanical, Inc Southwest Mechanical, Inc shall not be liable for any special, indirect or consequential damages arising in any manner from the equipment or material furnished or the work performed pursuant to this agreement.'
                },
                {
                  options: {
                    padding: [5, 0],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '5. LIABILITY. Southwest Mechanical, Inc Southwest Mechanical, Inc shall not be liable for any special, indirect or consequential damages arising in any manner from the equipment or material furnished or the work performed pursuant to this agreement.'
                },
                {
                  options: {
                    padding: [5, 0],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '6. TAXES. The price of this proposal does not include duties, sales, use, excise, or other similar taxes, unless required by federal, state or local law. Purchaser shall pay, in addition to the stated price, all taxes not legally required to be paid by Southwest Mechanical, Inc or, alternatively, shall provide Southwest Mechanical, Inc with acceptable tax exemption certificates. Southwest Mechanical, Inc shall provide Purchaser with any tax payment certificate upon request and after completion and acceptance of the work.'
                },
                {
                  options: {
                    padding: [5, 0],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '7. DELAYS. Southwest Mechanical, Inc shall not be liable for any delay in the performance of the work resulting from or attributed to acts of circumstances beyond Southwest Mechanical, Inc control, including , but not limited to , acts of God, fire, riots, labor disputes, conditions of the premises, acts or omissions of the Purchaser, Owner, or the Contractors or delays caused by suppliers or subcontractors of Southwest Mechanical, Inc, etc.'
                },
                {
                  options: {
                    padding: [5, 0],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '8. COMPLIANCE WITH LAWS. Southwest Mechanical, Inc shall comply with all applicable federal, state and local laws and regulations and shall obtain all temporary licenses and permits required for the prosecution of the work. Licenses and permits of a permanent nature here shall l be procured and paid by the Purchaser.'
                },
                {
                  options: {
                    padding: [5, 0],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '9. DISPUTES. All disputes involving more than $15,000 shall be resolved by arbitration in accordance with the rules of the American Arbitration Association. The prevailing party shall recover all legal costs and attorney’s fees incurred as a result. Nothing here shall limit any rights under construction lien laws.'
                },
                {
                  options: {
                    padding: [5, 0],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '10. INSURANCE. Insurance coverage in excess of Southwest Mechanical, Inc standard limits will be furnished when requested and required. No credit will be given or premium paid by Southwest Mechanical, Inc for insurance afforded by others'
                },
                {
                  options: {
                    padding: [5, 0],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '11. INDEMNITY. The Parties hereto agree to indemnify each other from any and all liabilities, claims, expenses, losses or damages, including attorney’s fees, which may arise in connection with the execution of the work herein specified and which are caused, in whole or in part, by the negligent act or omission of the indemnifying Party.'
                },
                {
                  options: {
                    padding: [5, 0],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '12. OCCUPATIONAL SAFETY AND HEALTH. The Parties hereto agree to notify each other immediately upon becom- ing aware of an inspection under, or any alleged violation of, the Occupational Safety and Health Act relating in any way to the project or project site.'
                },
                {
                  options: {
                    padding: [5, 0],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '13. ENTIRE AGREEMENT. This proposal, upon acceptance, shall constitute the entire agreement between the parties and supersedes and prior representations or understandings.'
                },
                {
                  options: {
                    padding: [5, 0],
                    fontSize: 7
                  },
                  component: {
                    default: 'BodyText'
                  },
                  source:
                    '14. CHANGES. No change or modification of any of the terms and conditions slated herein shall be binding upon Southwest Mechanical, Inc unless accepted by Southwest Mechanical, Inc in writing.'
                },
                // to stick TOC in one page
                {
                  options: {
                    padding: 20
                  },
                  contents: []
                }
              ]
            }
          ]
        }
      ]
    }
  }
});

export default southwestMechanical;
