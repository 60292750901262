import React from 'react';
import PropTypes from 'prop-types';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from '@material-ui/core/Button';

export const MetaDataViewer = ({ meta }) => {
  const jsonString = JSON.stringify(meta, null, 2);
  return (
    <div style={{ position: 'relative', backgroundColor: '#F4F4F4', padding: 12 }}>
      <Button
        style={{ position: 'absolute', top: 12, right: 24 }}
        variant="contained"
        onClick={() => {
          navigator.clipboard.writeText(jsonString);
        }}
      >
        <FileCopyIcon />
        Copy
      </Button>
      <pre style={{ margin: 0 }}>{jsonString}</pre>
    </div>
  );
};

MetaDataViewer.propTypes = {
  meta: PropTypes.string.isRequired
};
