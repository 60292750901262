import React, { Component } from 'react';
import { Tooltip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

class ToolTipIcon extends Component {
  render() {
    const { hoverText } = this.props;

    return (
      hoverText !== 'N/A' && (
        <Tooltip title={hoverText} style={{ color: 'red' }}>
          <WarningIcon style={{ color: 'red' }} />
        </Tooltip>
      )
    );
  }
}

export default ToolTipIcon;
