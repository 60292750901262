/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { PricebookService, QuickbooksService, CompanyService } from 'services/core';
import { MUIForm } from '@buildhero/sergeant';
import { Grid } from '@material-ui/core';
import { Logger } from 'services/Logger';
import {
  PageHeader,
  ResponsiveTable,
  Modal,
  Spinner,
  ConfirmModal,
  UserPermission
} from 'components';
import FieldWithLabel from 'components/BuildHeroFormComponents/FieldWithLabel';
import BundleHeaderForm from 'meta/Settings/Inventory/bundle/bundleHeaderForm';
import BundleItemForm from 'meta/Settings/Inventory/bundle/bundleEntryForm';
import {
  truckTemplateItem,
  taskTemplateItem
} from 'meta/Settings/Inventory/bundle/bundleEntryValidation';
import AlgoliaSearch from 'components/AlgoliaSearch';
import { snackbarOn } from 'redux/actions/globalActions';
import ErrorBoundaries from 'scenes/Error';
import Labels from 'meta/labels';
import {
  productsRowsMeta,
  productsRowsForTrucksMeta
} from 'meta/Settings/Inventory/bundle/bundleDetailsTables';
import { itemsLayout } from 'meta/Items';
import { PermissionConstants } from 'utils/AppConstants';
import minMaxValidation from 'utils/minMaxValidation';
import { templateTypes } from 'constants/bundle';
import { convertStringToFloat, getBooleanValue } from 'utils';
import { bundleIndex as defaultProductIndex } from 'constants/algoliaIndex';
import styles from './BundleDetail.style';

class ProductBundleDetail extends Component {
  constructor(props) {
    super(props);
    document.title = 'BuildOps - Inventory bundle';
    this.PricebookService = new PricebookService();
    this.QuickbooksService = new QuickbooksService();
    this.CompanyService = new CompanyService();
    this.mounted = props.mounted || true;
    this.state = {
      openAddItem: false,
      record: '',
      mode: 'new',
      productBundle: '',
      isQuickBooksEnabled: false,
      spinner: true,
      refreshCounter: 0,
      confirmModalSetting: {}
    };
  }

  componentDidMount = async () => {
    if (this.props?.user?.tenantId) {
      const data = await this.queryAndSetProductBundle();
      if (data && this.mounted) {
        this.updateProductBundleState(data);
      }
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  updateProductBundleState = (data = {}) => {
    const { products, ...rest } = data;
    this.setState(prevState => ({
      ...prevState,
      products,
      productBundle: rest,
      spinner: false
    }));
  };

  queryAndSetProductBundle = async (filter, limit, offset, sortBy, sortOrder) => {
    try {
      const productBundleId = this.props.computedMatch.params && this.props.computedMatch.params.id;
      const data = await this.PricebookService.getProductsByProductBundleId(
        productBundleId,
        filter,
        limit,
        offset,
        sortBy,
        sortOrder
      );
      const { items } = data;
      // @TODO - revisit - getProductsByProductBundleId query should not be returning items with isActive: false. When this is fixed, remove this filter
      const activeItems = items.filter(item => item.isActive);

      const result = {
        ...data,
        items: activeItems
      };

      this.updateProductBundleState(result);
      return result;
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to fetch inventory bundle, please try again later');
    }
  };

  handleAdd = formName => {
    this.setState({ [formName]: true, mode: 'new' });
  };

  handleClose = formName => {
    this.setState({ [formName]: false, mode: 'new', record: '' });
  };

  deleteProductBundleEntry = async record => {
    try {
      const response = await this.PricebookService.softDeleteProductBundleEntry(
        this.props.user.tenantId,
        record.id
      );
      if (response && response.data) {
        const { refreshCounter } = this.state;
        this.props.snackbarOn('success', `Successfully deleted item - ${record.name}`);
        this.setState({ refreshCounter: refreshCounter + 1 });
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', `Unable delete item - ${record.name}`, error);
    }
  };

  handleProductRowactions = (actionName, record) => {
    if (actionName === 'edit') {
      this.setState({ openAddItem: true, mode: 'edit', record });
    }

    if (actionName === 'delete') {
      const handleClose = () =>
        this.setState({ ...this.state, confirmModalSetting: { open: false } });
      const confirmModalSetting = {
        confirmMessage: record.name,
        open: true,
        cancelAction: handleClose,
        action: async () => {
          await this.deleteProductBundleEntry(record);
          handleClose();
        }
      };
      this.setState({ ...this.state, confirmModalSetting });
    }
  };

  addProductEntriesToBundle = async values => {
    const payload = {
      productBundleId: this.state.productBundle.id,
      productBundleEntries: []
    };

    values.forEach(item => {
      payload.productBundleEntries.push({
        isActive: true,
        productId: item.id,
        name: item.name || null,
        description: item.description || null,
        unitCost: convertStringToFloat(item.unitCost) || 0,
        quantity: convertStringToFloat(item.quantity) || 0,
        taxable: getBooleanValue(item.taxable),
        minQuantity: convertStringToFloat(item.minQuantity) || null,
        maxQuantity: convertStringToFloat(item.maxQuantity) || null
      });
    });

    try {
      if (payload.productBundleEntries.length) {
        const response = await this.PricebookService.addProductEntriesToBundle(
          this.props.user.tenantId,
          payload
        );
        if (response && response.data && response.data.addProductBundleEntriesToProductBundle) {
          this.props.snackbarOn('success', 'Successfuly added to the bundle');
          const { refreshCounter } = this.state;
          this.setState({
            refreshCounter: refreshCounter + 1,
            openAddItem: false,
            record: '',
            mode: 'new'
          });
        }
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to add items to the bundle');
    }
  };

  handleProductSelection = async productObj => {
    let productData;
    if (productObj && productObj.id) {
      try {
        const response = await this.PricebookService.getProductById(productObj.id);
        productData = { ...response, unitOfMeasureValue: response?.unitOfMeasure?.name };
      } catch (error) {
        Logger.error(error);
        this.props.snackbarOn('error', 'Unable to fetch item info');
      }
    }
    const products = this.state.productBundle?.items ?? [];
    const isExistingProduct = products.some(({ productId }) => productId === productData?.id);

    if (isExistingProduct) {
      return this.props.snackbarOn('error', `${productData.name} already added to this bundle`);
    }

    if (productData) {
      this.setState({ record: productData, openAddItem: true, mode: 'new' });
    }
  };

  updateProductBundleEntry = async values => {
    const updatePayload = {
      id: values.id,
      version: values.version,
      isActive: true,
      productId: values.productId,
      name: values.name || null,
      description: values.description || null,
      unitCost: convertStringToFloat(values.unitCost) || 0,
      quantity: convertStringToFloat(values.quantity) || 0,
      minQuantity: convertStringToFloat(values.minQuantity) || null,
      maxQuantity: convertStringToFloat(values.maxQuantity) || null
    };

    try {
      const response = await this.PricebookService.updateProductEntriesInBundle(
        this.props.user.tenantId,
        updatePayload
      );
      if (response && response.data && response.data.updateProductBundleEntry) {
        this.setState(prevState => ({
          refreshCounter: prevState.refreshCounter + 1,
          openAddItem: false,
          record: '',
          mode: 'new'
        }));
        this.props.snackbarOn('success', `Successfuly modified  ${values.name} item in the bundle`);
      }
    } catch (error) {
      Logger.error(error);
      this.props.snackbarOn('error', 'Unable to modify items to the bundle');
    }
  };

  getBundleItemMode = (mode, record) => {
    let finalMode;
    switch (record?.type) {
      case 'TaskTemplate':
        finalMode = `Task${mode}`;
        break;
      case 'TruckTemplate':
        finalMode = `Truck${mode}`;
        break;
      default:
        // when there is no record, the page is still loading, render without the form
        finalMode = record ? mode : null;
        break;
    }
    return finalMode;
  };

  render() {
    const { user, classes } = this.props;
    const {
      mode,
      record,
      productBundle,
      spinner,
      refreshCounter,
      confirmModalSetting
    } = this.state;
    const itemsFormLayout = itemsLayout.entity.layouts.web;
    const isTruckTemplate = productBundle?.type === 'TruckTemplate';

    if (itemsFormLayout.buttons && itemsFormLayout.buttons.cancel) {
      itemsFormLayout.buttons.cancel.action = () =>
        this.setState({ openAddItem: false, mode: 'new', record: '' });
    }

    if (productBundle?.type) {
      productBundle.typeValue =
        templateTypes.find(type => type.value === productBundle.type)?.label || productBundle?.type;
    }

    if (spinner) {
      return <Spinner />;
    }

    let service;

    return (
      <UserPermission I="read" action={PermissionConstants.OBJECT_INVENTORTY}>
        <ErrorBoundaries>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.topBar}>
              <ConfirmModal
                open={confirmModalSetting.open}
                confirm={confirmModalSetting.action}
                cancel={confirmModalSetting.cancelAction}
                message={confirmModalSetting.confirmMessage}
              />
              <PageHeader
                breadcrumbsArray={[
                  {
                    link: '/settings/inventory',
                    title: Labels.products[user.locale]
                  },
                  {
                    link: '',
                    title:
                      (productBundle && productBundle.name) || Labels.productBundle[user.locale]
                  }
                ]}
              />
              <Grid container className={classes.topBarDetails} spacing={2}>
                <Grid item xs={12} sm={12} md={10} lg={8} xl={7}>
                  <MUIForm
                    configuration={BundleHeaderForm}
                    customComponents={FieldWithLabel}
                    data={productBundle || {}}
                    layout="view"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2} justify="space-between">
              <Grid item xs={8} sm={5} md={5} lg={4} xl={3}>
                {/* For filters */}
              </Grid>
              <UserPermission I="create" action={PermissionConstants.OBJECT_INVENTORTY}>
                <Grid item xs={8} sm={5} md={5} lg={4} xl={3}>
                  <AlgoliaSearch
                    searchIndex={defaultProductIndex}
                    placeholder="Add products from pricebook"
                    name="selectProduct"
                    locatedInPopUp
                    value=""
                    displayText={['name', 'description']}
                    delimiter=" - "
                    filters={[
                      {
                        attribute: 'entityType',
                        valueArray: ['Product']
                      }
                    ]}
                    handleChange={this.handleProductSelection}
                    caslKey={PermissionConstants.OBJECT_INVENTORTY}
                    overrideVariant="outlined"
                    style={{
                      color: this.props.theme.palette.grayscale(90)
                    }}
                  />
                </Grid>
              </UserPermission>
              <Grid item xs={12}>
                <ResponsiveTable
                  rowMetadata={isTruckTemplate ? productsRowsForTrucksMeta : productsRowsMeta}
                  disableFilter
                  useServerQueries
                  service={this.queryAndSetProductBundle}
                  showToolbars
                  rowActionButtons={{
                    edit: {
                      label: 'Edit ',
                      icon: 'BorderColor',
                      caslKey: PermissionConstants.OBJECT_INVENTORTY,
                      caslAction: 'update'
                    },
                    delete: {
                      label: 'Delete ',
                      icon: 'Delete',
                      caslKey: PermissionConstants.OBJECT_INVENTORTY,
                      caslAction: 'delete'
                    }
                  }}
                  rowActions={this.handleProductRowactions}
                  noDataMsg="No products"
                  key={refreshCounter}
                />
                <Modal
                  open={this.state.openAddItem}
                  handleClose={() => this.handleClose('openAddItem')}
                  width="500"
                  showModalHeader
                  modalTitle={mode === 'edit' ? 'Edit item' : 'New item'}
                  buttons={{
                    cancel: {
                      buttonType: 'contained',
                      color: 'secondary',
                      label: 'Cancel',
                      action: () => this.handleClose('openAddItem')
                    },
                    save: {
                      buttonType: 'contained',
                      color: 'primary',
                      action: () => {
                        if (service) {
                          service.submit();
                        } else {
                          Logger.error('Service not available in New truck creation');
                        }
                      },
                      label: 'Save'
                    }
                  }}
                >
                  <MUIForm
                    configuration={BundleItemForm()}
                    customComponents={{ FieldWithLabel }}
                    data={record || {}}
                    layout={this.getBundleItemMode(mode, productBundle)}
                    onCreateService={formService => {
                      if (formService) service = formService;
                    }}
                    onComplete={completedData => {
                      const { isValid, message } = minMaxValidation(completedData);
                      if (!isValid) return this.props.snackbarOn('error', message);
                      if (mode === 'new') {
                        this.addProductEntriesToBundle([completedData]);
                      } else {
                        this.updateProductBundleEntry(completedData);
                      }
                    }}
                    validationSchema={isTruckTemplate ? truckTemplateItem : taskTemplateItem}
                  />
                </Modal>
              </Grid>
            </Grid>
          </Grid>
        </ErrorBoundaries>
      </UserPermission>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedProductBundleDetail = connect(
  mapStateToProps,
  mapDispatcherToProps
)(ProductBundleDetail);

export default withStyles(styles, { withTheme: true })(reduxConnectedProductBundleDetail);
