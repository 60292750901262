export default [
  {
    id: 'imageUrl',
    type: 'thumbnail',
    label: 'Image',
    disableSort: true,
    disableFilter: true
  },
  {
    id: 'assetName',
    label: 'Asset',
    showLink: 'view',
    linkPath: '/asset/view',
    linkReference: 'id'
  },
  {
    id: 'make',
    label: 'Make'
  },
  {
    id: 'modelNumber',
    label: 'Model No'
  },
  {
    id: 'serialNo',
    label: 'Serial No'
  },
  {
    id: 'ownerIdentifier',
    label: 'Owner ID'
  },
  {
    id: 'internalIdentifier',
    label: 'Internal Asset ID'
  },
  {
    id: 'installDate',
    label: 'Install date',
    type: 'dateOnly'
  },
  {
    id: 'location',
    label: 'Location'
  },
  {
    id: 'propertyZone',
    label: 'Property Zone'
  },
  {
    id: 'assetType.tagName',
    label: 'Asset Type'
  }
];
