import gql from 'graphql-tag';

const getVisitCrew = gql`
  query getVisit($partitionKey: String!, $sortKey: String!) {
    getVisit(partitionKey: $partitionKey, sortKey: $sortKey) {
      visitNumber
      version
      status
      scheduledFor
      hierarchy
      partitionKey
      sortKey
      id
      entityType
      parentId
      primaryTechs(entityConnection: "VisitPrimaryTech") {
        items {
          id
          sortKey
          invertedSortKey
          mappedEntity {
            ... on Employee {
              id
              firstName
              lastName
            }
          }
        }
      }
      extraTechs(entityConnection: "VisitExtraTech") {
        items {
          id
          sortKey
          invertedSortKey
          mappedEntity {
            ... on Employee {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;
export default getVisitCrew;
