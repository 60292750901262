import gql from 'graphql-tag';

const updateCompanySetting = gql`
  mutation UpdateCompanySetting($partitionKey: String!, $data: UpdateCompanySettingInput!) {
    updateCompanySetting(partitionKey: $partitionKey, data: $data) {
      id
      version
      settings
    }
  }
`;

export default updateCompanySetting;
