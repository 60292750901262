import gql from 'graphql-tag';

const deleteAttachmentFromQuote = gql`
  mutation deleteAttachmentFromQuote($input: DeleteAttachmentInput!) {
    deleteAttachmentFromQuote(input: $input) {
      id
    }
  }
`;

export default deleteAttachmentFromQuote;
