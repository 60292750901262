const styles = theme => ({
  container: {
    flexGrow: 1,
    height: 35,
    position: 'relative'
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    //   marginTop: theme.spacing(),
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(2)
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  noOptionsMessage: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`
  },
  input: {
    display: 'flex',
    padding: '25px 5px 5px'
  },
  inputFilter: {
    display: 'flex',
    padding: '15px 5px 5px'
  },
  valueContainer: {
    display: 'flex',
    // commented as the default text goes down by 1 px
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    flexWrap: 'wrap',
    paddingLeft: 10,
    height: 'auto'
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  singleValue: {
    fontSize: 16,
    //    padding: '25px 12px 5px',
    borderRadius: 4,
    font: 'inherit',
    color: 'currentColor',
    marginTop: theme.spacing(1.8)
  },
  singleValueFilter: {
    fontSize: 12,
    borderRadius: 4,
    font: 'inherit',
    color: 'currentColor'
  },
  clearIndicator: {
    padding: '2px'
  },
  dropdownIndicator: {
    padding: '2px'
  },
  errorMsg: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    color: '#b00020'
  }
});

export default styles;
