import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { Mode } from 'utils/constants';
import EditIcon from '@material-ui/icons/Edit';

export const ItemTypes = {
  CARD: 'card'
};

export const PayrollTypeCard = ({
  id,
  payrollHourType,
  openTypeModal,
  setOpenTypeModal,
  className,
  getDisplayLabel,
  isAllowedToEdit,
  index,
  moveItem
}) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveItem(dragIndex, hoverIndex);

      item.index = hoverIndex;
      if (item?.payrollHourType) item.payrollHourType.sortOrder = index;
    }
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, id, index, payrollHourType },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Grid item ref={ref} style={{ opacity }}>
      <Grid container direction="row">
        <Grid item style={{ cursor: 'move' }} className={className}>
          <Typography variant="body2">{getDisplayLabel(payrollHourType)}</Typography>
        </Grid>
        {isAllowedToEdit && (
          <Grid item>
            <IconButton
              aria-label="edit"
              onClick={() =>
                setOpenTypeModal({
                  ...openTypeModal,
                  open: true,
                  mode: Mode.EDIT,
                  data: payrollHourType,
                  sortOrder: index + 1
                })
              }
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default PayrollTypeCard;
