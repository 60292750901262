import AmplifyService from 'services/AmplifyService';
import ApplyClosedPeriod from '../../graphql/quickbookIntegration/mutations/applyClosedPeriod';
import SyncClosedPeriod from '../../graphql/quickbookIntegration/mutations/syncClosedPeriod';
import AddClosedPeriodToCompany from '../../graphql/quickbookIntegration/mutations/addClosedPeriodToCompany';
import deleteClosedPeriod from '../../graphql/quickbookIntegration/mutations/deleteClosedPeriod';
import getCompanyClosedPeriod from '../../graphql/quickbookIntegration/queries/getCompanyClosedPeriod';

export default class AccountingServices {
  constructor() {
    this.api = AmplifyService.appSyncClient();
  }

  addClosedPeriodToCompany = async (partitionKey, data) => {
    const params = {
      partitionKey,
      data
    };

    // first, delete existing closed periods
    const companyClosedPeriodResponse = await this.api.query(getCompanyClosedPeriod, {
      partitionKey,
      id: data.companyId
    });
    const { closedPeriods } = companyClosedPeriodResponse.data.getEntityById;
    closedPeriods.items.forEach(async closedPeriod => {
      await this.api.mutate(deleteClosedPeriod, {
        partitionKey,
        id: closedPeriod.id
      });
    });
    const response = await this.api.mutate(AddClosedPeriodToCompany, params);
    return response;
  };

  syncClosedPeriod = async tenantCompanyId => {
    const params = {
      tenantCompanyId
    };
    const response = await this.api.query(SyncClosedPeriod, params);

    return response;
  };

  applyClosedPeriod = async tenantCompanyId => {
    const params = {
      data: { tenantCompanyId }
    };
    const response = await this.api.mutate(ApplyClosedPeriod, params);
    return response;
  };
}
