import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Typography, TV, TW } from '@buildhero/sergeant';
import { AuditLogs } from 'components';
import { Box } from '@material-ui/core';
import { jsx } from '@emotion/react';

const styles = {
  title: {
    color: 'black'
  }
};

const PaymentActivityList = ({ data, loading }) => {
  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Typography variant={TV.BASE} weight={TW.BOLD} css={styles.title}>
        Activity
      </Typography>
      {loading ? (
        <Skeleton height={100} count={7} />
      ) : (
        <AuditLogs auditLogData={data?.auditLogs?.items || []} variant="singleEntity" />
      )}
    </Box>
  );
};

export default PaymentActivityList;
