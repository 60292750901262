import { MUIForm } from '@buildhero/sergeant';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TableCell, TableRow } from '@material-ui/core';
import ErrorBoundaries from 'scenes/Error';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.grayscale(100),
    borderTopWidth: 0
  },
  cell: {
    padding: theme.spacing(4)
  }
});

const RowDetail = ({ rowData, layout, classes }) => {
  return (
    <ErrorBoundaries>
      <TableRow className={classes.root}>
        <TableCell colSpan="100%" className={classes.cell}>
          <MUIForm configuration={layout} data={rowData} layout="default" onComplete={() => {}} />
        </TableCell>
      </TableRow>
    </ErrorBoundaries>
  );
};

export default withStyles(styles)(RowDetail);
