import React, { useMemo } from 'react';

import { ThemeProvider } from '@buildhero/sergeant';
import WrapTable from 'components/WrapTable';

import { SectionHeader, VisitSubjectField } from '../Components';

const getColumns = () => [
  {
    field: 'subject',
    headerName: 'Visit',
    renderCell: ({ row }) => VisitSubjectField({ ...row.subject }),
    flex: 1
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 5
  },
  {
    field: 'primaryTechs',
    headerName: 'Primary Technician',
    flex: 1.5
  },
  {
    field: 'additionalTechs',
    headerName: 'Additional technicians',
    flex: 3
  }
];

const getTechNames = (systemEntityMaps = []) =>
  systemEntityMaps?.items
    ?.map(item => item?.mappedEntity?.name)
    ?.filter(Boolean)
    ?.join(', ');

const mapVisitsToRows = visits =>
  visits
    .map(v => ({
      subject: {
        reviewStatus: v.reviewStatus,
        visitNumber: v.visitNumber,
        scheduledFor: v.scheduledFor
      },
      description: v.description,
      primaryTechs: getTechNames(v.primaryTechs),
      additionalTechs: getTechNames(v.extraTechs)
    }))
    .sort((a, b) => a.subject.visitNumber - b.subject.visitNumber);

const VisitDescriptionTable = ({ visits, isLoading }) => {
  const rows = mapVisitsToRows(visits);
  const columns = useMemo(() => getColumns(), []);

  return (
    <ThemeProvider>
      <SectionHeader title="Visit Description" />
      <WrapTable
        columns={columns}
        rows={rows}
        noDataMessage="No Visit Description"
        hideFooter={rows.length < 11}
        loading={isLoading}
        loadingRows={3}
      />
    </ThemeProvider>
  );
};

export default VisitDescriptionTable;
