import { makeStyles } from '@material-ui/core';

import { ElementZIndex } from '@dispatch/Dispatch.styles';

import { boxHeight, boxWidth } from './TimeMarker.constants';

export const useStyles = makeStyles(({ palette }) => ({
  box: {
    backgroundColor: palette.grayscale(60),
    borderRadius: 4,
    fontSize: 12,
    color: palette.background.default,
    position: 'absolute',
    width: boxWidth,
    height: boxHeight,
    top: -boxHeight,
    padding: '4px 0px',
    textAlign: 'center',
    zIndex: ElementZIndex.timeMarker
  },
  line: ({ horizontal }) => ({
    borderLeft: horizontal ? 'none' : `1px dashed ${palette.grayscale(60)}`,
    borderTop: horizontal ? `1px dashed ${palette.grayscale(60)}` : 'none',
    position: 'absolute',
    top: 0,
    height: horizontal ? 0 : '100%',
    width: horizontal ? '100%' : 0
  })
}));
