import gql from 'graphql-tag';

const upsertAdjustmentType = gql`
  mutation upsertAdjustmentType($partitionKey: String, $data: UpsertAdjustmentTypeInput!) {
    upsertAdjustmentType(partitionKey: $partitionKey, data: $data) {
      id
      name
      type
      ledgerAccountId
      ledgerAccount(entityConnection: "LedgerAccount") {
        name
      }
      ledgerOffsetAccountId
      ledgerOffsetAccount(entityConnection: "LedgerAccount") {
        name
      }
      lastUpdatedDateTime
    }
  }
`;

export default upsertAdjustmentType;
