import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorBoundaries from 'scenes/Error';
import DefaultButton from '../Buttons/DefaultButton';
import IconMap from './IconMap';

const useStyles = makeStyles(theme => ({
  tooltip: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    width: 16
  },
  header: ({ additionalHeaderStyles, paddingTop }) => ({
    paddingTop: paddingTop || 0,
    marginBottom: theme.spacing(2),
    ...additionalHeaderStyles
  }),
  title: {
    fontSize: 18
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  }
}));

// buttons and showbuttons are legacy - do not use - To be removed when all PageForm components are removed
// overrideHeaderButtons are to be used for buttons across pages. It will be an array of react components that can be placed within Grid (div)
const SectionHeader = ({
  title,
  icon,
  infoToolTip,
  buttons,
  overrideHeaderButtons,
  audit,
  paddingTop,
  style,
  handleChange
}) => {
  const SectionIcon = <IconMap icon={icon} />;
  const classes = useStyles({ additionalHeaderStyles: style, paddingTop });

  return (
    <ErrorBoundaries>
      <Grid
        container
        direction="column"
        alignItems="center"
        onClick={handleChange}
        className={classes.header}
      >
        <Grid container direction="row" alignItems="center" justify="space-between">
          <div className={classes.titleContainer}>
            {title && (
              <Typography variant="h6" className={classes.title}>
                {title}
              </Typography>
            )}
            {icon && SectionIcon}
            {infoToolTip && (
              <Tooltip title={infoToolTip} aria-label={infoToolTip}>
                <div className={classes.tooltip}>
                  <IconMap icon="info" />
                </div>
              </Tooltip>
            )}
          </div>
          {buttons && Array.isArray(buttons) && (
            <Grid item>
              {buttons.map(({ action, label, color }, index) => (
                <DefaultButton
                  key={index}
                  handle={action}
                  label={label}
                  color={color}
                  style={{ float: 'right' }}
                />
              ))}
            </Grid>
          )}
          {overrideHeaderButtons && <Grid item>{overrideHeaderButtons}</Grid>}
        </Grid>
        {audit && (
          <Grid container direction="row">
            <Typography variant="caption">{audit}</Typography>
          </Grid>
        )}
      </Grid>
    </ErrorBoundaries>
  );
};

export default SectionHeader;
