/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getEmployees() {
  return await instance
    .get('Employees')
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getTechEmployees(depid) {
  return await instance
    .get('Employees?include=departments')
    .then(({ data }) => {
      return data.filter(emp => emp.departments.some(dep => dep.id === depid) && emp.isTech);
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function employeeDeptSearch(term, searchColumns, depid) {
  if (term.length > 0) {
    return await instance
      .post(`Employees/dept/departments/${term}`, {
        searchColumns: searchColumns || ['name'],
        depid: depid || ''
      })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  }
  if (depid) {
    return getTechEmployees(depid);
  }
  return [];
}

export async function employeeTechSearch(term, searchColumns, depid) {
  if (term.length > 0) {
    return await instance
      .post(`Employees/tech/departments/${term}`, {
        searchColumns: searchColumns || ['name'],
        depid: depid || ''
      })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  }
  if (depid) {
    return getTechEmployees(depid);
  }
  return [];
}

export async function getEmployeeById(id) {
  return await instance
    .get(`Employees/${id}`, { params: { include: '*' } })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return {};
    });
}

export async function getEmployeeCompanyById(id) {
  return await instance
    .get(`Employees/company/${id}`, { params: { include: '*' } })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return {};
    });
}

export async function employeeSearch(term, searchColumns) {
  if (term.length > 0) {
    return await instance
      .post(`Employees/search/${term}`, { searchColumns: searchColumns || ['name'] })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  }
  return await getEmployees();
}

export async function employeeCreate(employee) {
  return await instance
    .post('Employees', employee)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function employeeChange(id, employee) {
  return await instance
    .put(`Employees/${id}`, employee)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
