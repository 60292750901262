const sendEmailForm = formData => {
  return {
    fields: {
      recipients: {
        default: 'recipients'
      },
      body: {
        default: 'body'
      }
    },
    validation: {
      type: 'object',
      properties: {
        recipients: {
          type: 'array'
        },
        subject: {
          type: 'string'
        },
        body: {
          type: 'string'
        }
      },
      required: ['recipients', 'subject', 'body']
    },
    validationErrors: {
      recipients: {
        required: 'Email is required.'
      },
      subject: {
        required: 'Subject is required.'
      },
      body: {
        required: 'Message is required.'
      }
    },
    layouts: {
      default: {
        options: {
          pageSize: 'LETTER',
          grow: 0,
          width: 688,
          padding: [0, 0, 0, 8]
        },
        contents: [
          !formData.disableViewPdf && {
            options: {
              direction: 'column',
              grow: 1,
              padding: [15, 0, 0, 0]
            },
            contents: [
              {
                component: 'CustomPDFComponent',
                options: {
                  showSpinner: formData.showSpinner,
                  pdfKey: formData.pdfKey
                }
              }
            ]
          },

          !formData.disableExpirationDate && {
            options: {
              direction: 'column',
              grow: 1
            },
            contents: [
              {
                component: 'ExpirationDateComponent',
                options: {
                  daysUntilExpiration: formData.daysUntilExpiration
                }
              }
            ]
          },

          // Recipients
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'row',
                  grow: 1,
                  padding: [15, 0, 0, 0]
                },
                contents: [
                  {
                    component: 'SelectInput',
                    options: {
                      creatable: true,
                      isSearchable: true,
                      label: 'Email To',
                      isRequired: true,
                      isMultipleSelection: true,
                      valueSet: formData.recipients,
                      onCreate: formData.onCreate
                    },
                    source: 'recipients'
                  }
                ]
              }
            ]
          },

          // Cc Recipients
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'row',
                  grow: 1,
                  padding: [15, 0, 0, 0]
                },
                contents: [
                  {
                    component: 'SelectInput',
                    options: {
                      creatable: true,
                      isSearchable: true,
                      label: 'Cc',
                      isRequired: false,
                      isMultipleSelection: true,
                      valueSet: formData.recipients,
                      onCreate: formData.onCreate
                    },
                    source: 'ccRecipients'
                  }
                ]
              }
            ]
          },

          // Bcc Recipients
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'row',
                  grow: 1,
                  padding: [15, 0, 0, 0]
                },
                contents: [
                  {
                    component: 'SelectInput',
                    options: {
                      creatable: true,
                      isSearchable: true,
                      label: 'Bcc',
                      isRequired: false,
                      isMultipleSelection: true,
                      valueSet: formData.recipients,
                      onCreate: formData.onCreate
                    },
                    source: 'bccRecipients'
                  }
                ]
              }
            ]
          },

          formData.templates?.length && {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              {
                options: {
                  direction: 'row',
                  grow: 1,
                  padding: [15, 0, 0, 0]
                },
                contents: [
                  {
                    component: 'SelectInput',
                    options: {
                      creatable: false,
                      isSearchable: true,
                      label: 'Templates',
                      isRequired: false,
                      isMultipleSelection: false,
                      valueSet: formData.templates,
                      onChange: formData.onSelectTemplate
                    },
                    source: 'selectedTemplate'
                  }
                ]
              }
            ]
          },
          {
            options: {
              size: 1,
              direction: 'row',
              grow: 0
            },
            contents: [
              !formData.disableAttachments && {
                options: {
                  direction: 'row',
                  grow: 1,
                  padding: [15, 0, 0, 0]
                },
                contents: [
                  {
                    component: 'SelectInput',
                    options: {
                      creatable: false,
                      isSearchable: true,
                      label: 'Attachments',
                      isRequired: false,
                      isMultipleSelection: true,
                      valueSet: formData.attachments,
                      onChange: formData.onSelectAttachment
                    },
                    source: 'selectedAttachments'
                  }
                ]
              }
            ].filter(Boolean)
          },
          !formData.disableAttachments && {
            options: {
              direction: 'column'
            },
            contents: [
              {
                component: 'AttachmentSizeWarning',
                options: {
                  selectedAttachments: formData.selectedAttachments
                }
              }
            ]
          },
          formData.allowEditEmailSubject && {
            options: {
              direction: 'column',
              grow: 1,
              padding: [15, 0, 0, 0]
            },
            contents: [
              {
                component: 'TextInput',
                options: {
                  label: 'Subject',
                  placeholder: 'Add a subject',
                  isRequired: true
                },
                source: 'subject'
              }
            ]
          },
          {
            options: {
              direction: 'column',
              grow: 1,
              padding: [15, 0, 0, 0]
            },
            contents: [
              {
                component: 'TextInput',
                options: {
                  label: 'Message',
                  placeholder: 'Add a message',
                  isRequired: true,
                  isMultipleSelection: true,
                  lines: 15,
                  defaultValue: ''
                },
                source: 'body'
              }
            ]
          },
          !formData.disableAttachments && {
            options: {
              direction: 'column',
              grow: 1,
              padding: [24, 0, 0, 0]
            },
            contents: [
              {
                component: 'AttachmentThumbnailsComponent',
                options: {
                  selectedAttachments: formData.selectedAttachments
                }
              }
            ]
          }
        ].filter(Boolean)
      }
    }
  };
};

export default sendEmailForm;
