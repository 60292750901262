import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme => ({
  greyBorderTop: {
    borderTop: `1px solid ${theme.palette.grayscale(90)}`
  },
  container: {
    paddingTop: 32,
    paddingRight: 16
  },
  pricingContainer: {
    width: '100%',
    paddingTop: 32,
    paddingRight: 16
  },
  taskContainer: {
    '& .add-task-btn': {
      width: '100%',
      marginRight: '4994px',
      marginTop: '13px',
      variant: 'outlined',
      color: theme.palette.grayscale(100),
      backgroundColor: theme.palette.grayscale(20),
      '&:hover': {
        background: theme.palette.grayscale(40)
      }
    }
  }
}));

export default styles;
