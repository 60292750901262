import React from 'react';
import { func } from 'prop-types';
import { Button, TV, Typography, TypographyWeight } from '@buildhero/sergeant';

import { useReviewReportDisabled } from '../../../../ReviewReport.contexts';

import { useStyles } from './SummarySectionHeader.styles';

const VisitSummarySectionHeader = ({ onAddSummaryNote }) => {
  const styles = useStyles();
  const { disabled } = useReviewReportDisabled();

  return (
    <div css={styles.header}>
      <div>
        <Typography variant={TV.L} weight={TypographyWeight.BOLD} style={{ marginBottom: 8 }}>
          Visit Summary
        </Typography>
        <Typography>Selected items will be added to the invoice</Typography>
      </div>
      <div css={styles.button}>
        <Button disabled={disabled} type="secondary" size="small" onClick={onAddSummaryNote}>
          Add Summary Note
        </Button>
      </div>
    </div>
  );
};

VisitSummarySectionHeader.propTypes = {
  onAddSummaryNote: func.isRequired
};

export default VisitSummarySectionHeader;
