import gql from 'graphql-tag';

const getPurchaseOrderTypesForCompany = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      purchaseOrderTypes {
        items {
          id
          name
          departments {
            id
          }
          autoReceive
        }
      }
    }
  }
`;

export default getPurchaseOrderTypesForCompany;
