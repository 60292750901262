import React, { forwardRef, memo, useRef, useState } from 'react';

import { array, func, number } from 'prop-types';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

import { ElementSizes } from '@dispatch/Dispatch.styles';
import { TECHS_RESPONSE_PROP } from '@dispatch/queries';

import { useVisitDragLifecycle } from '../../DispatchBoard.hooks';
import { useStyles } from '../../DispatchBoard.styles';
import {
  BOARD_MAN_DAY_ITEM_RESPONSE_PROPS,
  BOARD_NON_VISIT_EVENT_RESPONSE_PROP,
  BOARD_VISIT_RESPONSE_PROP
} from '../../queries';

import TechsFilterButton from '../TechsFilterButton';

import DailyViewHeader from './components/DailyViewHeader';
import SwimLane from './components/SwimLane';
import { BoardContext, useBoardContext } from './DailyView.context';
import {
  useResetListItemSize,
  useScrollToCurrentTime,
  useSetBoardHeight
} from './DailyView.effects';
import { useItemData, useVisitDrop } from './DailyView.hooks';
import { selectLaneHeight } from './DailyView.selectors';
import { useTimePosition } from './hocs/withTimePosition';

const BoardFilterContainer = () => {
  const classes = useStyles();

  return (
    <div className={classes.boardFilterContainer}>
      <TechsFilterButton />
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const innerElementType = forwardRef(({ children, style, ...rest }, ref) => {
  const { boardHeight } = useBoardContext();

  return (
    <div ref={ref} style={{ ...style, width: ElementSizes.laneWidth }} {...rest}>
      <BoardFilterContainer />
      <DailyViewHeader boardHeight={boardHeight} />
      {children}
    </div>
  );
});

const DailyView = ({
  day,
  clearVisitRowHover,
  filterBy,
  techsResponse,
  boardVisitsResponse,
  boardNonVisitEventsResponse,
  boardManDayItemsResponse,
  visitTransitionTuple
}) => {
  const [boardRef, setRef] = useState();
  const [innerRef, setInnerRef] = useState();
  const [innerHeight, setInnerHeight] = useState(0);
  const listRef = useRef();
  const clientOffsetRef = useRef();
  const { position, positionOffset } = useTimePosition();
  const [triggerVisitTransition] = visitTransitionTuple;

  const itemData = useItemData({
    techsResponse,
    boardVisitsResponse,
    boardNonVisitEventsResponse,
    boardManDayItemsResponse,
    boardRef
  });

  const [collected, dropRef] = useVisitDrop({
    clientOffsetRef,
    triggerVisitTransition,
    boardRef,
    day,
    clearVisitRowHover
  });

  // effects
  useVisitDragLifecycle({ ...collected, filterBy });
  useScrollToCurrentTime({ boardRef, position, positionOffset });
  useSetBoardHeight({ setInnerHeight, innerRef, itemData });
  useResetListItemSize({ listRef, itemData });

  const handleSetOuterRef = ref => {
    dropRef(ref);
    setRef(ref);
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <BoardContext.Provider value={{ boardHeight: innerHeight, isOverBoard: collected?.isOver }}>
          <VariableSizeList
            height={height}
            innerElementType={innerElementType}
            innerRef={setInnerRef}
            itemCount={itemData.length}
            itemData={itemData}
            itemKey={i => itemData[i]?.tech?.id || i}
            itemSize={i => selectLaneHeight(i, itemData)}
            outerRef={handleSetOuterRef}
            overscanCount={5}
            ref={listRef}
            width={width}
          >
            {SwimLane}
          </VariableSizeList>
        </BoardContext.Provider>
      )}
    </AutoSizer>
  );
};

DailyView.propTypes = {
  // eslint-disable-next-line react/require-default-props
  day: number,
  clearVisitRowHover: func.isRequired,
  filterBy: func.isRequired,
  techsResponse: TECHS_RESPONSE_PROP.isRequired,
  boardVisitsResponse: BOARD_VISIT_RESPONSE_PROP.isRequired,
  boardNonVisitEventsResponse: BOARD_NON_VISIT_EVENT_RESPONSE_PROP.isRequired,
  boardManDayItemsResponse: BOARD_MAN_DAY_ITEM_RESPONSE_PROPS.isRequired,
  visitTransitionTuple: array.isRequired
};

export default memo(DailyView);
