import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { SergeantModal } from 'components';
import attachmentLayout from 'meta/attachment-seargent-layout';
import AttachItem from 'components/AttachmentSection/AttachItem';
import AttachFile from '@material-ui/icons/AttachFile';
import { Button, ThemeProvider } from '@buildhero/sergeant';
import { Mode } from 'utils/constants';
import { fileInputToAttachment } from 'components/AttachmentSection/helpers';
import { addAttachmentToServiceAgreement, updateAttachment, deleteAttachment } from '../service';
import { ATTACHMENT_LABELS } from '../constants';

const Attachments = ({ options, ...props }) => {
  const defaultAttachmentState = { open: false, mode: Mode.NEW, attachment: {} };
  const [openAttachment, setOpenAttachment] = useState(defaultAttachmentState);
  const { serviceAgreementId, tenantId, snackbar, isCancelled } = props?.form?.values || {};
  const { setFieldValue } = props?.form || {};
  const fieldName = props?.field?.name;
  const currentAttachments = props?.field?.value || [];
  let label;

  if (fieldName === 'contract') {
    label = ATTACHMENT_LABELS.CONTRACT;
  } else if (fieldName === 'attachments') {
    label = ATTACHMENT_LABELS.ATTACHMENT_FIRST;
  }

  const handleModalClose = () => {
    setOpenAttachment(defaultAttachmentState);
  };

  const handleAttachment = async (data, stopLoading) => {
    const attachmentPayload = await fileInputToAttachment(tenantId, data, options?.type);
    let uploadedAttachment;
    let updatedAttachmentList = currentAttachments;
    if (openAttachment.mode === Mode.EDIT && data?.id) {
      uploadedAttachment = await updateAttachment(attachmentPayload, snackbar);
      // to avoid reshuffling the order of attachments, using map
      updatedAttachmentList = currentAttachments.map(item => {
        if (item.id === uploadedAttachment.id) {
          return uploadedAttachment;
        }
        return item;
      });
      setFieldValue(fieldName, updatedAttachmentList);
    } else if (openAttachment.mode === Mode.DELETE) {
      uploadedAttachment = await deleteAttachment(data.id, snackbar);
      updatedAttachmentList = currentAttachments.filter(item => item.id !== data.id);
    } else {
      uploadedAttachment = await addAttachmentToServiceAgreement(
        serviceAgreementId,
        attachmentPayload,
        snackbar
      );
      updatedAttachmentList = [...currentAttachments, ...uploadedAttachment];
    }
    setFieldValue(fieldName, updatedAttachmentList);
    setOpenAttachment(defaultAttachmentState);
    stopLoading();
  };

  const hideAddButton =
    (options?.restrictSingleAttachment && props?.field?.value?.length > 0) || isCancelled;

  return (
    <Box display="flex" flexDirection="column">
      <AttachItem
        {...props}
        handleAttachmentActions={({ mode, attachmentItem }) =>
          setOpenAttachment({ open: true, mode, attachment: attachmentItem })
        }
        isReadonly={false}
      />
      {!hideAddButton && (
        <ThemeProvider>
          <Button
            onClick={() => setOpenAttachment({ open: true, mode: Mode.NEW })}
            type="tertiary"
            startIcon={<AttachFile variant="primary" />}
          >
            {label}
          </Button>
        </ThemeProvider>
      )}
      <SergeantModal
        open={openAttachment.open}
        data={openAttachment.attachment}
        dataType="attachment"
        mode={openAttachment.mode}
        confirmRemoveItemLabel={
          openAttachment.attachment?.customFileName || openAttachment.attachment?.fileName || ''
        }
        layout={attachmentLayout()}
        handlePrimaryAction={handleAttachment}
        handleClose={handleModalClose}
        key={openAttachment?.id}
      />
    </Box>
  );
};

export default Attachments;
