import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 17
  },
  kpiContainer: {
    height: 86,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'relative',
    padding: '0px 80px 0px 80px',
    marginBottom: '40px'
  },
  kpiSubTitle: {
    position: 'absolute',
    top: 12,
    left: 0,
    fontSize: 14,
    fontWeight: 700
  },
  chartContainer: {
    height: 406,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  performanceContainer: {
    width: '50%',
    padding: '12px 12px 12px 0px',
    borderRight: `1px solid ${theme.palette.grayscale(90)}`
  },
  utilizationContainer: {
    padding: 12
  },
  chartTitle: {
    fontSize: 14,
    fontWeight: 700
  },
  hidden: {
    display: 'none'
  }
}));
