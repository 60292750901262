const styles = theme => ({
  label: {
    fontSize: 10,
    letterSpacing: 0.01,
    fontWeight: 'normal',
    textTransform: 'uppercase'
  },
  content: {
    fontFamily: theme.typography.fontFamily,
    color: '#3f3f3f',
    fontSize: 14,
    letterSpacing: 0.24,
    fontWeight: 'normal',
    lineHeight: 'normal',
    paddingTop: 7,
    wordBreak: 'break-all'
  }
});

export default styles;
