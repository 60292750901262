import gql from 'graphql-tag';

const softDeleteProductBundleEntry = gql`
  mutation softDeleteProductBundleEntry($partitionKey: String!, $id: String!) {
    softDeleteProductBundleEntry(partitionKey: $partitionKey, id: $id) {
      id
      name
      description
      unitCost
      quantity
      version
    }
  }
`;

export default softDeleteProductBundleEntry;
