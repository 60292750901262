import React from 'react';

import moment from 'moment-timezone';

import AppConstants from 'utils/AppConstants';

import LogRow from './LogRow';

const TimesheetNoteEntry = ({
  note,
  payrollSetting,
  setNotesModalData,
  workEvent,
  tenantId,
  employeeId,
  isEditable
}) => {
  const executedDateTime = parseInt(parseInt(note.createdDateTime, 10) / 1000, 10);

  const date = moment
    .tz(moment.unix(executedDateTime).format(), payrollSetting?.timeZone)
    .format(AppConstants.DATETIME_FORMAT);

  return (
    <LogRow
      key={note.id}
      id={note.id}
      date={date}
      message={note.note}
      author={note.createdBy}
      subject={note.subject}
      attachments={note.attachments}
      version={note.version}
      setInteractiveModalData={setNotesModalData}
      workEvent={workEvent}
      interactive={isEditable}
      tenantId={tenantId}
      employeeId={employeeId}
    />
  );
};

export default TimesheetNoteEntry;
