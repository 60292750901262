/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function sendEmail(payload) {
  return await instance
    .post(`sendEmail`, payload)
    .then(resp => {
      return resp;
    })
    .catch(err => {
      console.log(err);
      return err;
    });
}

export async function insertEmail(payload) {
  return await instance
    .post(`sendEmail/insertEmail`, payload)
    .then(resp => {
      return resp;
    })
    .catch(err => {
      console.log(err);
      return err;
    });
}

export async function updateEmail(id, payload) {
  return await instance
    .put(`sendEmail/updateEmail/${id}`, payload)
    .then(resp => {
      return resp;
    })
    .catch(err => {
      console.log(err);
      return err;
    });
}

export async function getEmail(parentId, noInclude) {
  return await instance
    .get(`sendEmail/byparent/${parentId}`, {
      params: {
        include: noInclude ? [] : '*'
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}
