import React from 'react';

import Button from '@material-ui/core/Button';

import Spinner from 'components/Spinners/CircularIndeterminate';

const DefaultButton = ({
  testingid,
  variant = 'contained',
  color = 'secondary',
  spinnerProps = {},
  buttonProps = {},
  handle,
  onClick,
  disabled,
  style,
  label,
  showSpinner
}) => (
  <Button
    data-testid={testingid}
    testingid={testingid}
    variant={variant}
    color={color}
    onClick={handle || onClick}
    disabled={disabled}
    style={style}
    {...buttonProps}
  >
    {label}
    {showSpinner && <Spinner styles={{ margin: 0 }} size={12} {...spinnerProps} />}
  </Button>
);

export default DefaultButton;
