const auditLogsTable = [
  {
    id: 'executedDateTime',
    label: 'Action Date',
    type: 'dateTime',
    disableFilter: true
  },
  {
    id: 'executedBy',
    label: 'Action By',
    filterKey: 'executedBy'
  },
  {
    id: 'action',
    label: 'Action',
    type: 'action',
    isCustom: true,
    disableSort: true,
    disableFilter: true
  }
];

export default auditLogsTable;
