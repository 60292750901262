import { PermissionConstants } from 'utils/AppConstants';
import { EnumType } from 'utils/constants';

export const techReportRowsMeta = {
  permissions: PermissionConstants.OBJECT_VISIT,
  tableMeta: [
    {
      id: 'jobNumber',
      filterKey: 'Job.jobNumber',
      filterType: 'number',
      label: 'Job',
      type: 'jobLink'
    },
    {
      id: 'visitNumber',
      filterKey: 'Visit.visitNumber',
      filterType: 'number',
      numeric: true,
      label: 'Visit'
    },
    {
      id: 'departmentName',
      filterKey: 'Visit.departmentName',
      filterType: 'string',
      label: 'Department'
    },
    {
      id: 'customerName',
      disableFilter: true,
      filterKey: 'Customer.customerName',
      filterType: 'string',
      label: 'Customer'
    },
    {
      id: 'customerPropertyName',
      disableFilter: true,
      filterKey: 'CustomerProperty.companyName',
      filterType: 'string',
      label: 'Property'
    },
    {
      id: 'jobTypeName',
      filterKey: 'Job.jobTypeName',
      filterType: 'string',
      type: 'enum',
      label: 'Job Type'
    },
    {
      id: 'status',
      filterKey: 'Job.status',
      filterType: 'string',
      enumType: EnumType.JOB_STATUS,
      showIcon: true,
      label: 'Job Status'
    },
    {
      id: 'jobTags',
      filterKey: 'JobTag.tagName',
      filterType: 'string',
      convertToSubQuery: true,
      subQueryCondition: {
        fieldName: 'Visit.parentId',
        fieldComparator: 'exists',
        entityConnection: 'Job',
        subQueryFieldName: 'Job.id',
        filter: {
          stringFilters: [
            {
              fieldName: 'Job.id',
              filterInput: {
                eq: 'Visit.parentId'
              },
              literal: true
            }
          ]
        }
      },
      disableSort: true,
      label: 'Job Tags',
      type: 'chip'
    },
    {
      id: 'owner',
      filterKey: 'Employee.name',
      filterType: 'string',
      type: 'text',
      label: 'Project Manager'
    },
    {
      id: 'scheduledFor',
      filterKey: 'Visit.scheduledFor',
      filterType: 'date',
      type: 'dateOnly',
      label: 'Created on'
    },
    {
      id: 'completedDate',
      filterKey: 'Job.completedDate',
      filterType: 'date',
      type: 'dateOnly',
      label: 'Job Completion Date'
    },
    {
      id: 'linkName',
      disableFilter: true,
      label: 'Report',
      showLink: 'view',
      linkPath: 'technicianreport/view',
      linkReference: 'id'
    }
  ]
};

export const invoiceRowsMeta = (isAccountIntegrationEnabled = false) => [
  {
    id: 'invoiceNumber',
    filterKey: 'Invoice.invoiceNumber',
    filterType: 'number',
    label: 'Invoice',
    showLink: 'view',
    linkPath: '/invoice/view',
    linkReference: 'id',
    linkStateKey: 'recordSortKey',
    linkStateValue: 'sortKey',
    bold: true
  },
  // If custom job number is enabled, it will be overriden in the respective page
  // as the filter key also needs to change
  {
    id: 'jobNumber',
    filterKey: 'Job.jobNumber',
    filterType: 'number',
    label: 'Job',
    type: 'jobLink'
  },
  {
    id: 'billingCustomerName',
    filterKey: 'BillingCustomer.customerName',
    filterType: 'string',
    label: 'Billing Customer',
    showLink: 'view',
    linkPath: '/customer/view',
    linkReference: 'billingCustomerId',
    linkStateKey: 'recordSortKey',
    linkStateValue: 'customerSortKey'
  },
  {
    id: 'customerPropertyName',
    filterKey: 'CustomerProperty.companyName',
    filterType: 'string',
    label: 'Property',
    showLink: 'view',
    linkPath: '/property/view',
    linkReference: 'customerPropertyId',
    linkStateKey: 'recordSortKey',
    linkStateValue: 'customerPropertySortKey'
  },
  {
    id: 'departmentName',
    filterKey: 'Department.tagName',
    filterType: 'string',
    label: 'Department'
  },
  {
    id: 'status',
    filterKey: 'Invoice.status',
    filterType: 'string',
    label: 'Status'
  },
  {
    id: 'jobTags',
    filterKey: 'JobTag.tagName',
    filterType: 'string',
    convertToSubQuery: true,
    subQueryCondition: {
      fieldName: 'Invoice.jobId',
      fieldComparator: 'exists',
      entityConnection: 'Job',
      subQueryFieldName: 'Job.id',
      filter: {
        stringFilters: [
          {
            fieldName: 'Job.id',
            filterInput: {
              eq: 'Invoice.jobId'
            },
            literal: true
          }
        ]
      }
    },
    disableSort: true,
    label: 'Job Tags',
    type: 'chip'
  },
  {
    id: 'invoiceTags',
    filterKey: 'InvoiceTag.tagName',
    filterType: 'string',
    label: 'Invoice Tags',
    type: 'chip'
  },
  {
    id: 'dueDate',
    filterKey: 'Invoice.dueDate',
    filterType: 'date',
    type: 'dateOnly',
    label: 'Due'
  },
  {
    id: 'issuedDate',
    filterKey: 'Invoice.issuedDate',
    filterType: 'date',
    type: 'dateOnly',
    label: 'Issued'
  },
  {
    id: 'completedDate',
    filterKey: 'Job.completedDate',
    filterType: 'date',
    type: 'dateOnly',
    label: 'Job Completion Date'
  },
  {
    id: 'totalAmount',
    filterKey: 'Invoice.totalAmount',
    filterType: 'currency',
    numeric: true,
    type: 'currency',
    label: 'Amount'
  },
  {
    id: 'balance',
    filterKey: 'Invoice.balance',
    filterType: 'currency',
    numeric: true,
    disableFilter: true,
    disableSort: true,
    type: 'balance',
    bold: true,
    label: 'Balance'
  }
];

export const reviewReportRowMeta = {
  permissions: PermissionConstants.OBJECT_REVIEWREPORT,
  tableMeta: [
    {
      id: 'jobNumber',
      filterKey: 'Job.jobNumber',
      filterType: 'number',
      label: 'Job',
      type: 'jobLink'
    },
    {
      id: 'visitNumber',
      filterKey: 'Visit.visitNumber',
      filterType: 'number',
      label: 'Visit'
    },
    {
      id: 'departmentName',
      filterKey: 'Visit.departmentName',
      filterType: 'string',
      label: 'Department'
    },
    {
      id: 'customerName',
      filterKey: 'Customer.customerName',
      filterType: 'string',
      label: 'Customer'
    },
    {
      id: 'customerPropertyName',
      filterKey: 'CustomerProperty.companyName',
      filterType: 'string',
      label: 'Property'
    },
    {
      id: 'jobTypeName',
      filterKey: 'Job.jobTypeName',
      filterType: 'string',
      label: 'Job type'
    },
    {
      id: 'status',
      filterKey: 'Job.status',
      filterType: 'string',
      enumType: EnumType.JOB_STATUS,
      showIcon: true,
      label: 'Job status'
    },
    {
      id: 'jobTags',
      filterKey: 'JobTag.tagName',
      filterType: 'string',
      convertToSubQuery: true,
      subQueryCondition: {
        fieldName: 'Visit.parentId',
        fieldComparator: 'exists',
        entityConnection: 'Job',
        subQueryFieldName: 'Job.id',
        filter: {
          stringFilters: [
            {
              fieldName: 'Job.id',
              filterInput: {
                eq: 'Visit.parentId'
              },
              literal: true
            }
          ]
        }
      },
      disableSort: true,
      label: 'Job Tags',
      type: 'chip'
    },
    {
      id: 'owner',
      filterKey: 'Employee.name',
      filterType: 'string',
      type: 'text',
      label: 'Project Manager'
    },
    {
      id: 'createdDate',
      filterKey: 'ReviewReport.createdDate',
      filterType: 'date',
      type: 'dateOnly',
      label: 'Created on'
    },
    {
      id: 'completedDate',
      filterKey: 'Job.completedDate',
      filterType: 'date',
      type: 'dateOnly',
      label: 'Job Completion Date'
    },
    {
      id: 'linkName',
      disableFilter: true,
      label: 'Report',
      showLink: 'view',
      linkPath: 'reviewreport/view',
      linkReference: 'id'
    }
  ]
};

export const summaryTitles = {
  completedVisits: {
    title: 'Completed tech reports',
    meta: techReportRowsMeta
  },
  draftReviewReports: {
    title: 'Draft review reports',
    meta: reviewReportRowMeta
  },
  submittedReviewReports: {
    title: 'Submitted review reports',
    meta: reviewReportRowMeta
  },
  draftInvoices: {
    title: 'Draft invoices',
    meta: {
      permissions: PermissionConstants.OBJECT_INVOICE,
      tableMeta: invoiceRowsMeta()
    }
  },
  unpaidInvoices: {
    title: 'Unpaid invoices',
    meta: {
      permissions: PermissionConstants.OBJECT_INVOICE,
      tableMeta: invoiceRowsMeta()
    }
  }
};
