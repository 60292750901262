import gql from 'graphql-tag';

const getTaxRates = gql`
  query getTaxRates($partitionKey: String!, $sortKey: String!, $nextToken: String) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      taxRates(
        entityConnection: "TaxRate"
        filter: { isActive: { eq: true } }
        nextToken: $nextToken
        limit: 9999
      ) {
        nextToken
        items {
          id
          name
          city
          county
          state
          description
          taxRate
          accountType
          isActive
          accountingRefId
          lastUpdatedDateTime
          version
        }
      }
    }
  }
`;

export default getTaxRates;
