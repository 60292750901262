const transformCreateQuoteSchema = (values, pageState) => {
  const { employees, taxRates, formAttributes } = pageState;
  let selectedOwner;
  let selectedSoldBy;
  if (values.ownerId) {
    const selectedEmployee = employees.filter(emp => emp.id === values.ownerId);
    selectedOwner =
      selectedEmployee.length > 0 &&
      (selectedEmployee[0].name ||
        `${selectedEmployee[0].firstName} ${selectedEmployee[0].lastName}`);
  }

  if (values.salesById) {
    const selectedEmployee = employees.filter(emp => emp.id === values.salesById);
    selectedSoldBy =
      selectedEmployee.length > 0 &&
      (selectedEmployee[0].name ||
        `${selectedEmployee[0].firstName} ${selectedEmployee[0].lastName}`);
  }

  const customFields = {};

  let haveCustomFields = false;
  if (values && formAttributes) {
    Object.keys(values).forEach(dataItem => {
      if (dataItem.startsWith('Custom_')) {
        haveCustomFields = true;
        customFields[dataItem.replace('Custom_', '')] = values[dataItem] || undefined;
      }
    });
    customFields.formDefinitionSortKey = formAttributes.formDefinitionSortKey;
    customFields.formSortKey = formAttributes.formSortKey;
    customFields.formId = formAttributes.formId;
    customFields.formDefinitionId = formAttributes.formDefinitionId;
  }

  const payload = {
    customerPropertyId: values.customerPropertyId || undefined,
    quotes: [
      {
        customIdentifier: values.customIdentifier || undefined,
        name: values.name || undefined,
        versionLabel: values.versionLabel || undefined,
        versionNumber: values.versionNumber || undefined,
        orderedById: values.orderedById || undefined,
        propertyRepId: values.propertyRepId || undefined,
        totalAmountQuotedOverride: values.totalAmountQuotedOverride || undefined,
        billTo: values.billTo || undefined,
        description: values.description || undefined,
        issueDescription: values.issueDescription || undefined,
        departmentId: values.departmentId || undefined,
        departmentValue: values.departmentValue || undefined,
        dueDate: values.dueDate || undefined,
        serviceAgreementId: values.serviceAgreementId || undefined,
        priceBookId: values.priceBookId || undefined,
        expirationDate: values.expirationDate || undefined,
        expirationLength: values.expirationLength || undefined,
        dateSent: null,
        detailsShownToCustomer: values.detailsShownToCustomer || undefined,
        jobTypeId: values.jobTypeId || undefined,
        jobTypeValue: values.jobTypeValue || undefined,
        ownerId: values.ownerId || undefined,
        ownerValue: selectedOwner || undefined,
        salesById: values.salesById || undefined,
        salesByValue: selectedSoldBy || undefined,
        taxRateId: values.taxRateId || undefined,
        taxRateValue: values.taxRateValue || undefined,
        customerPoNumber: values.customerPoNumber || undefined,
        pdfTemplateKey: values.pdfTemplateKey || undefined,
        status: 'Draft',
        discount: values.discount || 0.0,
        isActive: true,
        accountManagerId: values.accountManagerId || undefined,
        accountManagerValue: values.accountManagerValue || undefined
      }
    ]
  };

  if (haveCustomFields) {
    payload.quotes[0].formData = customFields;
  }
  return payload;
};

export default transformCreateQuoteSchema;
