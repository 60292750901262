import useExtendedMutation from 'customHooks/useExtendedMutation';
import { GET_REVIEW_REPORT_BY_ID } from 'scenes/Jobs/JobDetail/queries';
import ADD_DISCOUNT_TO_REVIEW_REPORT from 'services/core/graphql/review-report/mutations/AddDiscountsToReviewReport'

const serializer = ({ tenantId, reviewReportId, discount }) => ({
  variables: {
    partitionKey: tenantId,
    data: {
      reviewReportId,
      ...discount,
      taxable: discount.taxable === null ? false : discount.taxable
    }
  }
});

export const useAddReviewReportDiscount = reviewReportId => {
  return useExtendedMutation(ADD_DISCOUNT_TO_REVIEW_REPORT, {
    serializer,
    update: (cache, { data: { addDiscountToReviewReport } }) => {
      const newDiscount = addDiscountToReviewReport;
      if (!newDiscount) {
        return;
      }
      const cachedData = cache.readQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        }
      });
      cache.writeQuery({
        query: GET_REVIEW_REPORT_BY_ID,
        variables: {
          id: reviewReportId
        },
        data: {
          getReviewReportById: {
            ...(cachedData?.getReviewReportById || {}),
            discounts: {
              __typename: 'DiscountConnection',
              items: [...(cachedData.getReviewReportById?.discounts?.items || []), newDiscount]
            }
          }
        }
      });
    }
  });
};
