import updateInvoiceTags from 'services/core/graphql/invoice/mutations/updateInvoiceTags';
import deleteInvoiceInvoiceTag from 'services/core/graphql/invoice/mutations/deleteInvoiceInvoiceTag';
import AmplifyService from 'services/AmplifyService';
import { MIXPANEL_EVENT, MIXPANEL_PAGE_NAME } from 'constants/mixpanelEvents';
import { sendMixpanelEvent } from 'services/mixpanel';
import CommonService from '../Common/CommonService';
import getAllInvoices from '../../graphql/invoice/queries/getAllInvoices';
import createInvoice from '../../graphql/invoice/mutations/CreateInvoice';
import getInvoiceInfo from '../../graphql/invoice/queries/getInvoiceInfoByInvoice';
import getInvoiceLineItems from '../../graphql/invoice/queries/getInvoiceLineItemByInvoice';
import getInvoiceAdditionalInfo from '../../graphql/invoice/queries/getInvoiceAdditionalInfoByInvoice';
import updateInvoice from '../../graphql/invoice/mutations/UpdateInvoice';
import updateInvoiceStatus from '../../graphql/invoice/mutations/UpdateInvoiceStatus';
import deleteInvoice from '../../graphql/invoice/mutations/DeleteInvoice';
import emailInvoice from '../../graphql/invoice/mutations/EmailInvoice';
import skipReviewReportForVisits from '../../graphql/invoice/mutations/SkipReviewReportForVisits';
import getAllInvoicePdfs from '../../graphql/invoice/queries/getAllInvoicePdfs';
import getAllInvoicesByRange from '../../graphql/invoice/queries/getAllInvoicesByRangeKey';
import createInvoiceForCustomer from '../../graphql/invoice/mutations/CreateInvoiceForCustomer';
import createInvoiceForCustomerProperty from '../../graphql/invoice/mutations/CreateInvoiceForCustomerProperty';
import createInvoiceForJob from '../../graphql/invoice/mutations/CreateInvoiceForJob';

import invoiceMutationNotification from '../../graphql/invoice/subscriptions/invoiceMutationNotification';
import SubscriptionClient from '../helper';

export default class InvoiceService {
  constructor() {
    this.api = AmplifyService.appSyncClient();
    this.CommonService = new CommonService();
    this.subscriptionClient = SubscriptionClient.getClient(AmplifyService.config);
  }

  invoiceSubscription = (partitionKey, entityId) => {
    const variables = {
      partitionKey,
      entityId,
      entityType: 'Invoice'
    };
    return this.subscriptionClient.subscribe({ query: invoiceMutationNotification, variables });
  };

  getAllInvoices = async (partitionKey, sortKey, nextToken, limit) => {
    const params = {
      partitionKey,
      sortKey,
      nextToken,
      limit
    };

    const response = await this.api.query(getAllInvoices, params);
    return response;
  };

  getAllInvoicesByRange = async (partitionKey, sortKey, nextToken, limit, startTime, endTime) => {
    const params = {
      partitionKey,
      sortKey,
      nextToken,
      limit,
      startTime,
      endTime
    };

    const response = await this.api.query(getAllInvoicesByRange, params);
    return response;
  };

  getAllInvoicePdfs = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getAllInvoicePdfs, params);
    return response;
  };

  createInvoiceFromVisits = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(createInvoice, params);
    sendMixpanelEvent(MIXPANEL_EVENT.CREATED_INVOICE, MIXPANEL_PAGE_NAME.INVOICES);
    return response;
  };

  createInvoiceForCustomer = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(createInvoiceForCustomer, params);
    sendMixpanelEvent(MIXPANEL_EVENT.CREATED_INVOICE, MIXPANEL_PAGE_NAME.INVOICES);
    return response;
  };

  createInvoiceForCustomerProperty = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(createInvoiceForCustomerProperty, params);
    sendMixpanelEvent(MIXPANEL_EVENT.CREATED_INVOICE, MIXPANEL_PAGE_NAME.INVOICES);
    return response;
  };

  createInvoiceForJob = async (partitionKey, values) => {
    const params = {
      partitionKey,
      data: values
    };

    const response = await this.api.mutate(createInvoiceForJob, params);
    sendMixpanelEvent(MIXPANEL_EVENT.CREATED_INVOICE, MIXPANEL_PAGE_NAME.INVOICES);
    return response;
  };

  getInvoiceInfo = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getInvoiceInfo, params);
    return response;
  };

  getInvoiceLineItems = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getInvoiceLineItems, params);
    return response;
  };

  getInvoiceAdditionalInfo = async (partitionKey, sortKey) => {
    const params = {
      partitionKey,
      sortKey
    };

    const response = await this.api.query(getInvoiceAdditionalInfo, params);
    return response;
  };

  updateInvoice = async (partitionKey, data) => {
    const params = {
      partitionKey,
      data
    };

    const response = await this.api.mutate(updateInvoice, params);
    return response;
  };

  updateInvoiceStatus = async (partitionKey, data) => {
    const params = {
      partitionKey,
      data
    };

    const response = await this.api.mutate(updateInvoiceStatus, params);
    return response;
  };

  deleteInvoice = async (partitionKey, id) => {
    const params = {
      partitionKey,
      id
    };

    const response = await this.api.mutate(deleteInvoice, params);
    return response;
  };

  emailInvoice = async (partitionKey, data) => {
    const params = {
      partitionKey,
      data
    };

    const response = await this.api.mutate(emailInvoice, params);
    return response;
  };

  skipReviewReportForVisits = async (partitionKey, data) => {
    const params = {
      partitionKey,
      data
    };

    const response = await this.api.mutate(skipReviewReportForVisits, params);
    return response;
  };

  updateInvoiceTags = async params => {
    if (params?.paritionKey || !params?.data) return;
    const response = await this.api.mutate(updateInvoiceTags, params);
    return response;
  };

  deleteInvoiceInvoiceTag = async (partitionKey, id) => {
    const params = { partitionKey, id };
    await this.api.mutate(deleteInvoiceInvoiceTag, params);
    return { success: true };
  };
}
