import { useMemo } from 'react';
import { ProjectFinanceTableView } from './ProjectFinanceTable.constants';

export const useDepartmentsValueSet = (departments = [], projectFinanceData = []) => {
  return useMemo(() => {
    const departmentMap = projectFinanceData.reduce(
      (acc, data) => ({
        ...acc,
        [data.departmentId]: true
      }),
      []
    );

    return departments
      .filter(department => departmentMap[department.id])
      .map(department => ({
        value: department.id,
        label: department.name
      }));
  }, [departments, projectFinanceData]);
};

export const useFilteredData = (projectFinanceData = [], selectedDepartments = [], view) => {
  return useMemo(() => {
    return projectFinanceData.filter(data => {
      if (
        selectedDepartments?.length &&
        !selectedDepartments.find(selection => selection.value === data.departmentId)
      ) {
        return false;
      }

      if (view === ProjectFinanceTableView.COST_BUDGET) {
        return Boolean(data.costBudget || data.actualCost);
      }

      if (view === ProjectFinanceTableView.REVENUE_BUDGET) {
        return Boolean(data.revenueBudget || data.actualRevenue || data.costBudget);
      }

      if (view === ProjectFinanceTableView.LABOR_REPORT) {
        return Boolean(data.hourBudget || data.actualHours);
      }

      return true;
    });
  }, [projectFinanceData, selectedDepartments, view]);
};

export const useMaxGroupingDepth = ({ view, debug }) => {
  return useMemo(() => {
    return debug ? undefined : view === ProjectFinanceTableView.COST_BUDGET ? 2 : 1;
  }, [view, debug]);
};
