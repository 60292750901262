import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { AddCircleOutline, Edit } from '@material-ui/icons';
import { hasPreferredTechnician } from '../../helpers';

const PreferredTechniciansButton = ({ onClick, preferredTechnicians }) => {
  let label;
  let startIcon;
  if (hasPreferredTechnician(preferredTechnicians)) {
    label = 'Edit Preferred Technicians';
    startIcon = <Edit />;
  } else {
    label = 'Add Preferred Technicians';
    startIcon = <AddCircleOutline />;
  }
  return (
    <Box display="flex" flexDirection="column">
      <Button
        onClick={onClick}
        startIcon={startIcon}
        component="button"
        style={{ display: 'flex', justifyContent: 'flex-start' }}
      >
        {label}
      </Button>
    </Box>
  );
};

PreferredTechniciansButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  preferredTechnicians: PropTypes.shape({
    departmentId: PropTypes.string,
    crewId: PropTypes.string,
    primaryTechnicianId: PropTypes.string,
    additionalTechnicianIds: PropTypes.array
  }).isRequired
};

export default PreferredTechniciansButton;
