import gql from 'graphql-tag';

const createInvoice = gql`
  mutation createInvoiceFromVisits($partitionKey: String!, $data: CreateInvoiceFromVisitsInput!) {
    createInvoiceFromVisits(partitionKey: $partitionKey, data: $data) {
      id
      invoiceNumber
      sortKey
      entityType
    }
  }
`;

export default createInvoice;
