import React, { useMemo } from 'react';
import { jsx } from '@emotion/react';
import { useTheme } from '@material-ui/core';
import { Field, FieldType, ThemeProvider, Typography, TV, TW } from '@buildhero/sergeant';

import WrapTable from 'components/WrapTable';

const getColumns = [
  {
    field: 'source',
    headerName: 'Source',
    width: 175,
    renderCell: ({ row }) => (
      <Field
        value={{
          label: row.quoteDisplayNumber,
          to: `/quote/${row.quoteId}`
        }}
        type={FieldType.LINK}
      />
    )
  },
  { field: 'taskGroupName', headerName: 'Task Group' },
  { field: 'taskName', headerName: 'Task' },
  { field: 'taskDescription', headerName: 'Task Description' },
  {
    field: 'assetName',
    headerName: 'Asset',
    width: 175,
    renderCell: ({ row }) => (
      <Field
        value={{
          label: row.assetName,
          to: `/asset/view/${row.assetId}`
        }}
        type={FieldType.LINK}
      />
    )
  },
  { field: 'assetType', headerName: 'Asset Type' }
];

export const InvoicedQuoteTasks = ({ jobData }) => {
  const theme = useTheme();
  const quoteJobs = jobData.quoteJobs?.items || [];

  const rows = useMemo(() => {
    const quoteTasks = quoteJobs.flatMap(qj =>
      qj?.quote?.quoteLineTasks?.items.map(qlt => ({
        taskName: qlt.name,
        taskDescription: qlt.description,
        taskGroupName: qlt.quoteTaskGroup?.name,
        assetId: qlt.propertyAsset?.id,
        assetName: qlt.propertyAsset?.assetName,
        assetType: qlt.propertyAsset?.assetType?.tagName,
        quoteId: qj.quote.id,
        quoteDisplayNumber: `Quote ${qj?.quote?.customIdentifier || qj?.quote?.quoteNumber}`
      }))
    );
    return quoteTasks;
  }, [quoteJobs]);

  const columns = useMemo(() => getColumns, []);
  if (!rows.length) return null;

  return (
    <ThemeProvider>
      <Typography
        variant={TV.BASE}
        weight={TW.BOLD}
        css={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}
      >
        Tasks
      </Typography>
      <WrapTable
        columns={columns}
        rows={rows}
        noDataMessage="No Tasks"
        hideFooter={rows.length <= 10}
      />
    </ThemeProvider>
  );
};
