export const AppConstants = {
  ONETOMANY: '1toM',
  MANYTOMANY: 'MtoM',
  ONETOONE: '1to1',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DEACTIVATED: 'deactivated',
  UPCOMING_EVENTS: ['Scheduled', 'Unassigned'],
  PAST_EVENTS: ['Canceled', 'Complete', 'Converted', 'On hold'],
  DATE_FORMAT: 'DD/MM/YYYY'
};

export const PermissionConstants = {
  CUSTOMER_INFO: 'customers_customerInformation',
  NEW_CUSTOMER: 'customers_customerNewCustomer',
  CUSTOMER_PROPERTY: 'customers_customerPropertyInformation',
  NEW_CUSTOMER_PROPERTY: 'customers_createNewProperty',
  JOB_INFO: 'customers_jobGeneralInformation',
  JOB_SCHEUDLING: 'customers_jobSchedulingInformation',
  NEW_JOB: 'customers_createNewJob',
  ROLES_AND_ACCESS: 'people_roleAndAccessRights',
  EMPLOYEE_INFO: 'people_employeePersonalInfo',
  EMPLOYEE_SETTINGS: 'people_employeeSettings',
  COMPANY_INFO: 'company_companyinfo',
  DEPARTMENT_AND_SKILLS: 'company_departmentSkills',
  DISPATCH_BOARD: 'dispatch_dispatchBoard',
  DISPATCH_MAP: 'dispatch_map'
};

export const InvoiceConstants = {
  DRAFT: 'draft',
  POSTED: 'posted',
  UNPAID: 'unpaid',
  EXPORTED: 'exported',
  CLOSED: 'closed',
  NUM_INVOICES_SEARCHED: 500
};

export const JobActions = {
  JOB_COMPLETE: 'JOB_COMPLETE',
  JOB_CONTINUE: 'JOB_CONTINUE',
  JOB_ON_HOLD: 'JOB_ON_HOLD',
  JOB_CANCEL: 'JOB_CANCEL',
  JOB_REOPEN: 'JOB_REOPEN'
};

export const QuoteConstants = {
  DRAFT: 'draft',
  POSTED: 'posted',
  CANCELLED: 'Cancelled',
  JOBADDED: 'JobAdded'
};

export const JobStatus = {
  OPEN: 'Open',
  IN_PROGRESS: 'In Progress',
  ON_HOLD: 'On Hold',
  CANCELED: 'Canceled',
  COMPLETE: 'Complete'
};

export default AppConstants;
