import { generateDefaultFieldsObject } from 'scenes/ProjectManagement/components/formattingUtils';
import { taxRateSearch } from 'services/API/taxRate';
import { getTaxCode } from 'scenes/ProjectManagement/components/utils';
import { AccountType } from '../../../utils/constants';

const defaultFlexStyle = '0 1 203px';
const margin = '0px 8px 16px 8px';

const purchaseOrderCostFields = {
  freightCost: { required: false },
  taxRate: { required: false }
};

async function filteredTaxRateSearch(term, searchColumns) {
  const result = await taxRateSearch(term, searchColumns);
  return result.filter(t => t.accountType !== AccountType.AP);
}

const purchaseOrderCostContents = (handleChangeFeightCost, handleChangeTaxRate) => {
  return [
    // the 1st row
    {
      options: {
        size: 1,
        direction: 'row',
        grow: 0,
        flexWrap: 'nowrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'CurrencyInput'
              },
              source: 'freightCost',
              options: {
                label: 'Freight Cost',
                placeholder: 'Enter Freight Cost',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                onChange: handleChangeFeightCost
              }
            }
          ]
        }
      ]
    },

    // the 2nd row
    {
      options: {
        size: 1,
        direction: 'row',
        grow: 0,
        flexWrap: 'nowrap'
      },
      contents: [
        {
          options: {
            direction: 'column',
            flex: defaultFlexStyle,
            margin
          },
          contents: [
            {
              component: {
                edit: 'SearchBar'
              },
              source: 'taxRate',
              options: {
                resultFormatFunction: taxRate => getTaxCode(taxRate.name, taxRate.taxRate, 3),
                searchFunction: filteredTaxRateSearch,
                searchColumns: ['name'],
                label: 'tax Rate',
                placeholder: 'Select Tax Rate',
                fullWidth: true,
                alignment: 'left',
                variant: 'standard',
                color: 'secondary',
                isRequired: false,
                onSelect: handleChangeTaxRate
              }
            }
          ]
        }
      ]
    }
  ];
};

const layout = ({ handleChangeFeightCost, handleChangeTaxRate }) => {
  return {
    fields: generateDefaultFieldsObject(purchaseOrderCostFields),
    layouts: {
      edit: {
        options: {
          pageSize: 'LETTER',
          grow: 0
        },
        contents: purchaseOrderCostContents(handleChangeFeightCost, handleChangeTaxRate)
      }
    }
  };
};

export { purchaseOrderCostFields, layout };
