/* eslint-disable no-await-in-loop */
import * as PDFJS from 'pdfjs-dist/build/pdf';
import PDFJSWorker from 'pdfjs-dist/build/pdf.worker.entry';

PDFJS.GlobalWorkerOptions.workerSrc = PDFJSWorker;
/**
 * This hook takes in a list of Attachments and converts each of their pages into an image on a Canvas,
 * then returns an array of the same length, where each element is each attachment's pages in this DataURL form.
 *
 * @param {array} attachmentFiles List of Attachment objects to use. Need fileUrl and fileName from each one.
 * @param {object} options
 * @returns
 */
const attachmentsToCanvas = attachmentFiles => {
  if (!attachmentFiles || !attachmentFiles.length) {
    return Promise.resolve([]);
  }
  // Converts the page into a Canvas, renders it, and returns the canvas dataURL
  const preparePage = (pdf, num) => {
    return pdf.getPage(num).then(pdfPage => {
      try {
        const viewport = pdfPage.getViewport({ scale: 1.5 });
        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const pdfResolve = pdfPage.render({
          canvasContext,
          viewport
        });
        const timeoutPromise = new Promise(resolve => {
          const wait = setTimeout(() => {
            clearTimeout(wait);
            resolve(true);
          }, 3000);
        });
        const renderPage = (resolve, numRetries) => {
          return Promise.race([resolve.promise, timeoutPromise]).then(result => {
            // Sometimes a PDF page will fail to render which will cause the PDF view to hang.
            // Using Promise.race with the timeout function above gives each page 3 seconds to load and then
            // automatically retries it.
            // This is a TEMPORARY fix which will be made better in the future.
            // pdfResolve.promise returns 'undefined' when it resolves, whereas timeoutPromise returns true.
            if (result && numRetries < 5) {
              return renderPage(result, numRetries + 1);
            }
            return canvas.toDataURL('img/jpeg');
          });
        };
        return renderPage(pdfResolve, 0);
      } catch (error) {
        console.log(`Page ${num} failed`, error);
      }
    });
  };

  // If first item has an "pdfAttachments" key, the data passed is a parent that contains pdf attachments.
  // EX: Submittal Package Items have pdf attachments that need to be displayed AFTER them in the PDF.
  const isParent = !!attachmentFiles[0].pdfAttachments;

  const processFile = file => {
    return PDFJS.getDocument(file.fileUrl).promise.then(loadedPdf => {
      const pages = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < loadedPdf.numPages; i++) {
        pages.push(preparePage(loadedPdf, i + 1));
      }
      return Promise.all(pages);
    }); // execute the loadingTask to generate a PDF
  };

  let conversionFunctions = [];
  if (isParent) {
    conversionFunctions = attachmentFiles.map(parent => {
      return Promise.all(parent.pdfAttachments?.map(processFile)).then(convertedImages => {
        return {
          ...parent,
          canvasAttachments: convertedImages
        };
      });
    });
  } else {
    conversionFunctions = attachmentFiles.map(processFile);
  }

  return Promise.all(conversionFunctions);
};

export default attachmentsToCanvas;
