// Please document changes to these definitions at
// https://buildops.atlassian.net/wiki/spaces/EN/pages/1177059376/Mixpanel+Events

export const MIXPANEL_EVENT = {
  PAGE_VIEW: 'Page View',
  UNSUCCESSFUL_LOGIN: 'Unsuccessful login',
  CREATED_CUSTOMER: 'Created Customer',
  CREATED_INVOICE: 'Created Invoice',
  CREATED_JOB: 'Created Job',
  CREATED_PAYMENT: 'Created Payment',
  CREATED_JOB_FROM_QUOTE: 'Created Job from Quote',
  CREATED_QUOTE: 'Created Quote'
};

export const MIXPANEL_PROPERTY_NAME = {
  PAGE_NAME: 'Page Name'
};

export const MIXPANEL_PAGE_NAME = {
  JOBS: 'Jobs',
  FOLLOW_UPS: 'Follow Ups',
  REVIEW_REPORTS: 'Review Reports',
  QUOTES: 'Quotes',
  INVOICES: 'Invoices',
  TIME_SHEETS: 'Time Sheets',
  CUSTOMER_DETAIL: 'Customer Detail',
  PAYMENTS: 'Payments'
};
