import pull from 'lodash/pull';

import { getState } from 'redux/store';

import { DragScenarios } from '@dispatch/dnd';
import { DEFAULT_VISIT_DURATION_IN_MIN } from '@dispatch/Dispatch.constants';

import { ElementSizes } from '@dispatch/Dispatch.styles';
import { selectUnixFromOffset } from './DailyView.selectors';

export const serializeDropData = ({ item = {}, hoveredTech, dropPreview, day, dragScenario }) => {
  const { srcTech, data } = item;

  switch (dragScenario) {
    case DragScenarios.FULLY_ASSIGNED_SINGLE_TECH: {
      return {
        originalVisit: data,
        inputData: {
          id: data.id,
          scheduledFor: selectUnixFromOffset(dropPreview.left, day),
          primaryTechId: data.primaryTechId,
          actualDuration: data.actualDuration || DEFAULT_VISIT_DURATION_IN_MIN
        }
      };
    }

    case DragScenarios.UNASSIGNED_SINGLE_TECH: {
      return {
        originalVisit: data,
        inputData: {
          id: data.id,
          scheduledFor: selectUnixFromOffset(dropPreview.left, day),
          primaryTechId: hoveredTech,
          actualDuration: data.actualDuration || DEFAULT_VISIT_DURATION_IN_MIN
        }
      };
    }

    case DragScenarios.VISIT_BOARD_TO_BOARD: {
      const srcIsPrimary = srcTech === data.primaryTechId;

      return {
        originalVisit: data,
        inputData: {
          id: data.id,
          scheduledFor: selectUnixFromOffset(dropPreview.left, day),
          primaryTechId: srcIsPrimary ? hoveredTech : data.primaryTechId,
          extraTechs: srcIsPrimary
            ? data.extraTechs
            : [...pull(data.extraTechs, srcTech), hoveredTech],
          actualDuration: data.actualDuration || DEFAULT_VISIT_DURATION_IN_MIN
        }
      };
    }

    case DragScenarios.VISIT_START:
    case DragScenarios.VISIT_END: {
      return {
        originalVisit: data,
        inputData: {
          id: data.id,
          scheduledFor: selectUnixFromOffset(dropPreview.left, day),
          actualDuration: (dropPreview.width / ElementSizes.cellWidth) * 60,
          primaryTechId: data.primaryTechId,
          extraTechs: data.extraTechs
        }
      };
    }

    case DragScenarios.FULLY_ASSIGNED_MULTI_TECH: {
      return {
        originalVisit: data,
        inputData: {
          id: data.id,
          scheduledFor: selectUnixFromOffset(dropPreview.left, day),
          actualDuration: data.actualDuration || DEFAULT_VISIT_DURATION_IN_MIN,
          primaryTechId: data.primaryTechId,
          extraTechs: data.extraTechs
        }
      };
    }

    case DragScenarios.EVENT_START:
    case DragScenarios.EVENT_END:
    case DragScenarios.NON_VISIT_BOARD_TO_BOARD: {
      const { tenantId } = getState().user;

      return {
        eventId: data.id,
        tenantId,
        originalEvent: data,
        data: {
          from: selectUnixFromOffset(dropPreview.left, day),
          to: selectUnixFromOffset(dropPreview.left + dropPreview.width, day),
          employeeId: hoveredTech,
          version: data.version
        }
      };
    }

    case DragScenarios.MAN_DAY_START:
    case DragScenarios.MAN_DAY_END:
    case DragScenarios.MAN_DAY_BOARD_TO_BOARD: {
      return {
        id: data.id,
        startDateTime: selectUnixFromOffset(dropPreview.left, day),
        endDateTime: selectUnixFromOffset(dropPreview.left + dropPreview.width, day),
        technicianIds: [...pull([...data.technicianIds], srcTech), hoveredTech]
      };
    }

    default: {
      return {};
    }
  }
};
