import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    minHeight: 15
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'top',
    justifyContent: 'left'
  },
  messageText: {
    color: theme.palette.text.primary,
    fontSize: 14
  }
});

const CrewMembersAddedMessage = ({ options, classes }) => {
  return (
    <div className={classes.container}>
      {options.primaryTechName && (
        <div className={classes.messageContainer}>
          <div className={classes.messageText}>
            {options.primaryTechName} crew members were added as additional technicians.
          </div>
        </div>
      )}
    </div>
  );
};

CrewMembersAddedMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.shape({
    primaryTechName: PropTypes.string
  }).isRequired
};

export default withStyles(styles)(CrewMembersAddedMessage);
