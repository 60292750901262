import { useMemo } from 'react';
import { tableDateFormatter, tableEmptyValueFormatter } from 'components/WrapTable';
import ThumbnailCell from '../../../ThumbnailCell';
import FileNameCell from '../../../FileNameCell';

export const useReviewReportColumns = () =>
  useMemo(
    () => [
      {
        field: 'id',
        flex: 1,
        hide: true
      },
      {
        field: 'fileUrl',
        flex: 1,
        headerName: ' ',
        align: 'center',
        renderCell: ThumbnailCell
      },
      {
        field: 'type',
        flex: 1,
        headerName: 'Type',
        valueFormatter: tableEmptyValueFormatter
      },
      {
        field: 'fileName',
        flex: 3,
        headerName: 'File name',
        valueFormatter: ({ row }) => {
          return row?.customFileName || row?.fileName;
        },
        renderCell: FileNameCell
      },
      {
        field: 'addedBy',
        flex: 2,
        headerName: 'Added by',
        valueFormatter: tableEmptyValueFormatter
      },
      {
        field: 'dateAdded',
        flex: 2,
        headerName: 'Date added',
        valueFormatter: tableDateFormatter
      },
      {
        field: 'comment',
        flex: 2,
        headerName: 'Comment',
        valueFormatter: tableEmptyValueFormatter
      }
    ],
    []
  );
