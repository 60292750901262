import React from 'react';
import moment from 'moment';
import AppConstants from 'utils/AppConstants';
import { View, Image } from '@react-pdf/renderer';
import { TV, TW, PDFComponents } from '@buildhero/sergeant';
import { getCustomerSignatureSrc } from 'utils';

export default function CustomerSignaturesDisplayPDF({ name, form }) {
  const customerSignatures = form.values[name] || [];
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    item: {
      width: '135pt',
      height: '87pt',
      marginBottom: '24pt',
      marginRight: '72pt'
    },
    item3: {
      width: '135pt',
      height: '87pt',
      marginBottom: '40pt'
    }
  };

  return (
    <View style={styles.container}>
      {customerSignatures.map((signature, index) => {
        return (
          <View
            wrap={false}
            style={(index + 1) % 3 === 0 ? styles.item3 : styles.item}
            key={`sigImageItem${signature.id}`}
          >
            {signature.visit && (
              <PDFComponents.Typography
                props={{
                  value: `Visit ${signature.visit.visitNumber}`,
                  variant: TV.S2,
                  weight: TW.BOLD
                }}
              />
            )}
            {signature.signatureImageUrl && (
              <Image
                alt="Customer Signature"
                src={getCustomerSignatureSrc(signature)}
                style={{ width: '135pt', height: '43.5pt' }}
              />
            )}
            <PDFComponents.Typography
              props={{
                value: `Captured By: ${signature.capturedBy}\n
                ${moment(parseInt(signature.signedDateTime, 10)).format(
                  AppConstants.DATETIME_FORMAT
                )}\n
                  Signee: ${signature.nameOfSignee}`
              }}
            />
          </View>
        );
      })}
    </View>
  );
}
