import gql from 'graphql-tag';

const CompanyAddressesFields = `
addressLine1
addressLine2
state
city
zipcode
latitude
longitude
addressType
__typename`;

const getAllQuoteByCompany = gql`
  query getCompany(
    $partitionKey: String!
    $sortKey: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      quote: quotesView(
        entityConnection: "Quote"
        pagination: { limit: $limit, offset: $offset }
        filter: $filter
        sorting: $sort
        includeComputedColumns: true
      ) {
        nextToken
        items {
          id
          quoteNumber
          customIdentifier
          description
          name
          createdDate
          dueDate
          createdBy
          expirationDate
          status
          email
          departmentValue
          totalAmountQuoted
          totalAmountQuotedOverride
          totalAmountComputed
          ownerValue
          salesByValue
          accountManagerValue
          customerPoNumber
          quoteQuoteTags(entityConnection: "QuoteQuoteTag") {
            items {
              id
              entityType
              # Using mappedEntity as 'getMappedEntityTagname' excepts data to be in same format
              mappedEntity: quoteTag {
                id
                entityType
                tagName
              }
            }
          }
          versionedQuoteView(entityConnection: "VersionedQuote") {
            items {
              id
              versionNumber
              status
              quoteQuoteTags(entityConnection: "QuoteQuoteTag", limit: 50) {
                items {
                  id
                  entityType
                  # Using mappedEntity as 'getMappedEntityTagname' excepts data to be in same format
                  mappedEntity: quoteTag {
                    id
                    entityType
                    tagName
                  }
                }
              }
            }
          }
          opportunity: parentEntity {
            ... on Opportunity {
              id
              property: parentEntity {
                ... on CustomerProperty {
                  id
                  sortKey
                  companyName
                  customer: parentEntity {
                    ... on Customer {
                      id
                      customerName
                      sortKey
                      companyAddresses(entityConnection: "CompanyAddress") {
                        items {
                          ${CompanyAddressesFields}
                        }
                      }
                    }
                  }
                  billingCustomer(entityConnection: "Customer") {
                    companyAddresses(entityConnection: "CompanyAddress") {
                      items {
                        ${CompanyAddressesFields}
                      }
                    }
                  }
                  companyAddresses(entityConnection: "CompanyAddress") {
                    items {
                      ${CompanyAddressesFields}
                    }
                  }
                }
              }
            }
          }
          propertyRep(entityConnection: "CustomerRep") {
            name
            firstName
            middleName
            lastName
          }
          orderedById
          orderedBy(entityConnection: "CustomerRep") {
            name
            firstName
            middleName
            lastName
          }
          jobs: quoteJobs(entityConnection: "QuoteJob") {
            items {
              job(entityConnection: "Job") {
                id
                jobNumber
                customIdentifier
              }
            }
          }
          issueDescription
          versionNumber
          versionLabel
          rejectedReason
        }
      }
    }
  }
`;

export default getAllQuoteByCompany;
