import React from 'react';

import { withDispatchStore, withQueryStringStore } from '@dispatch/Dispatch.store';
import { useVisitTransition } from '@dispatch/mutations';
import compose from 'utils/compose';

import DailyView from './DailyView.component';
import { withTimePosition } from './hocs/withTimePosition';

const mapQueryStringToProps = store => ({
  day: store.state.day,
  filterBy: store.filterBy
});

const mapStoreToProps = store => ({
  clearVisitRowHover: store.clearVisitRowHover
});

const DataProvider = props => {
  const visitTransitionTuple = useVisitTransition();

  return <DailyView {...props} visitTransitionTuple={visitTransitionTuple} />;
};

export default compose(
  withTimePosition,
  withQueryStringStore(mapQueryStringToProps),
  withDispatchStore(mapStoreToProps)
)(DataProvider);
