import React, { useState } from 'react';

import { MUIForm } from '@buildhero/sergeant';
import PropTypes from 'prop-types';

import { DefaultButton, FullScreenModal } from 'components';
import LaborRateSection from 'components/LabourRateSection';
import MaterialRateSection from 'components/MaterialRateSection';
import Labels from 'meta/labels';
import { PricingStrategy } from 'utils/constants';

import ConfigHeader from './components/ConfigHeader';
import DefaultRate from './components/DefaultRate';
import createPricebookLayout from './layout';

export const ModifyPricebookCommonPopup = ({
  title,
  open,
  handleClose,
  pricebook,
  marginEnabled,
  showLabourRateSection,
  showMaterialRateSection,
  allowFormatChange,
  onSubmit,
  isSubmitting,
  showConfigSection,
  user
}) => {
  const [formService, setFormService] = useState();
  const formattedData = {
    ...pricebook,
    pricebookConfig: PricingStrategy.MARKUP
  };

  return (
    <FullScreenModal
      title={title}
      modalHeaderButtons={[
        <DefaultButton
          key="save"
          color="primary"
          label="Save"
          disabled={isSubmitting}
          showSpinner={isSubmitting}
          onClick={() => formService?.submit()}
        />
      ]}
      open={open}
      handleClose={handleClose}
      fixedHeader
    >
      <MUIForm
        configuration={createPricebookLayout({
          marginEnabled,
          showLabourRateSection,
          showMaterialRateSection,
          allowFormatChange,
          showConfigSection,
          locale: user.locale
        })}
        customComponents={{
          LaborRateSection,
          MaterialRateSection,
          ConfigHeader,
          DefaultRate
        }}
        data={formattedData}
        layout="edit"
        onCreateService={setFormService}
        onComplete={onSubmit}
      />
    </FullScreenModal>
  );
};

ModifyPricebookCommonPopup.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  pricebook: PropTypes.object,
  marginEnabled: PropTypes.bool.isRequired,
  showLabourRateSection: PropTypes.bool,
  showMaterialRateSection: PropTypes.bool,
  allowFormatChange: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  showConfigSection: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    tenantCompanyId: PropTypes.string.isRequired,
    tenantId: PropTypes.string.isRequired
  }).isRequired
};

ModifyPricebookCommonPopup.defaultProps = {
  pricebook: undefined,
  showLabourRateSection: true,
  showMaterialRateSection: true,
  allowFormatChange: true
};

export default ModifyPricebookCommonPopup;
