import React, { useState } from 'react';
import { connect } from 'react-redux';
import { snackbarOn } from 'redux/actions/globalActions';
import { isEmpty } from 'lodash';
import { FullScreenModal } from 'components';
import DefaultButton from 'components/Buttons/DefaultButton';
import Context from 'components/Context';
import { Mode } from 'utils/constants';
import { EmployeeStatus, EmployeeAction } from 'utils/AppConstants';
import removeObjProperties from 'utils/removeObjProperties';
import EmployeeForm from './Form';
import BillingRate from './BillingRate';
import { getEmployee, saveEmployee } from './services';
import {
  getModalTitle,
  constructIdObjItems,
  splitBillingRateAndEmployee,
  checkAllowToInvite,
  checkAllowToChangeStatus
} from './helpers';
import { unUsedEmployeeFields, contactAddressesFields } from './Form/constants';

const { DEACTIVATE, ACTIVATE } = EmployeeAction;
const SAVE_ACTION = 'SAVE';

const contextUpdate = () => {};

const modalHeaderButtons = ({
  onSave,
  isViewMode,
  user,
  employee,
  handleSave,
  actionBeingHandled
}) => {
  if (isViewMode) {
    return null;
  }
  const { isActive, id } = employee;
  const activationButtonLabel = `${isActive ? 'Deactivate' : 'Activate'} Employee`;
  const isAllowToInvite = checkAllowToInvite({ employee, user }) && id;
  const isAllowToChangeStatus = checkAllowToChangeStatus({ employee, user }) && id;

  // map employee status to action
  const inviteActionsFromStatus = {
    [EmployeeStatus.ACTIVE]: EmployeeAction.DISABLE_LOGIN,
    [EmployeeStatus.DEACTIVATED]: EmployeeAction.ENABLE_LOGIN,
    [EmployeeStatus.CREATED]: EmployeeAction.INVITE,
    [EmployeeStatus.INVITED]: EmployeeAction.RESEND_CONFIRMATION_EMAIL
  };
  const inviteAction = inviteActionsFromStatus[employee.status];

  // map action to button label
  const loginButtonLabels = {
    [EmployeeAction.INVITE]: 'Invite to BuildOps',
    [EmployeeAction.RESEND_CONFIRMATION_EMAIL]: 'Resend Invitation',
    [EmployeeAction.DISABLE_LOGIN]: 'Disable BuildOps Login',
    [EmployeeAction.ENABLE_LOGIN]: 'Enable BuildOps Login'
  };
  const inviteButtonLabel = loginButtonLabels[inviteAction];

  const activateAction = employee.isActive ? DEACTIVATE : ACTIVATE;
  const departments = constructIdObjItems(employee.departments);
  const appRoles = constructIdObjItems(employee.appRoles);
  const skills = constructIdObjItems(employee.skills);

  const employeeBasicInfo = removeObjProperties({ ...employee, departments, appRoles, skills }, [
    ...unUsedEmployeeFields,
    ...contactAddressesFields
  ]);

  return [
    isAllowToInvite && (
      <DefaultButton
        label={inviteButtonLabel}
        style={{ marginRight: 8 }}
        handle={() => handleSave(employeeBasicInfo, inviteAction)}
        showSpinner={actionBeingHandled === inviteAction}
        disabled={actionBeingHandled}
      />
    ),
    isAllowToChangeStatus && (
      <DefaultButton
        style={{ marginRight: 8 }}
        label={activationButtonLabel}
        handle={() => handleSave(employeeBasicInfo, activateAction)}
        showSpinner={actionBeingHandled === activateAction}
        disabled={actionBeingHandled}
      />
    ),
    <DefaultButton
      label="Save"
      color="primary"
      handle={onSave}
      showSpinner={actionBeingHandled === SAVE_ACTION}
      disabled={actionBeingHandled}
    >
      Save
    </DefaultButton>
  ];
};

const Employee = ({ isOpen, user, mode, refetch, data, handleClose, snackbar }) => {
  const [formService, setFormService] = React.useState();
  const [employee, setEmployee] = React.useState({});
  const [billingRate, setBillingRate] = React.useState({});
  const [employeeCreated, setemployeeCreated] = React.useState(false);
  const isViewMode = mode === Mode.VIEW;
  const isNewMode = mode === Mode.NEW;
  // used to set spinner on button that was clicked, and disable all other
  // buttons
  const [actionBeingHandled, setActionBeingHandled] = useState(null);

  React.useEffect(() => {
    const { tenantId, sortKey } = data;
    const successCallback = info => {
      const { billingInfo, employeeInfo } = splitBillingRateAndEmployee(info);
      if (employeeInfo.id) setemployeeCreated(true);
      setBillingRate(billingInfo);
      setEmployee(employeeInfo);
    };
    getEmployee({ sortKey, tenantId, snackbarOn: snackbar, successCallback });
    // eslint-disable-next-line
  }, [data]);

  const onCancel = () => handleClose();

  const handleSave = async (employeeFormData, action) => {
    // if no action type specified, then it's a save
    setActionBeingHandled(action ?? SAVE_ACTION);
    const successCallback = () => {
      onCancel();
      refetch();
    };
    // Employee Status will be null, on Create. Otherwise use existing status
    if (!employeeFormData.status) employeeFormData.status = EmployeeStatus.CREATED;

    await saveEmployee({
      user,
      action,
      employee: {
        ...employeeFormData,
        userName: employeeFormData.userName?.toLowerCase(),
        ...billingRate
      },
      snackbarOn: snackbar,
      successCallback
    });
    Context.setCompanyContext(
      user.tenantId,
      Context.generateCompanyContextSortKey(user),
      contextUpdate,
      true
    );

    setActionBeingHandled(null);
  };
  const handleEdit = (key, value) => setEmployee({ ...employee, [key]: value });

  const modalTitle = getModalTitle({ employee, mode });

  return (
    <FullScreenModal
      fixedHeader
      open={isOpen}
      title={modalTitle}
      handleClose={onCancel}
      modalHeaderButtons={modalHeaderButtons({
        user,
        employee,
        isViewMode,
        handleSave,
        onChange: handleEdit,
        onSave: () => formService.submit(),
        actionBeingHandled
      })}
    >
      <EmployeeForm
        user={user}
        data={employee}
        loading={!isNewMode && isEmpty(employee)}
        snackbar={snackbar}
        onChange={handleEdit}
        isViewMode={isViewMode}
        onFormComplete={handleSave}
        employeeCreated={employeeCreated}
        onFormService={service => setFormService(service)}
      />
      <BillingRate
        user={user}
        snackbar={snackbar}
        isViewMode={isViewMode}
        isEditMode={Boolean(data)}
        onChange={setBillingRate}
        billingRateInfo={billingRate}
      />
    </FullScreenModal>
  );
};

const mapStateToProps = state => ({
  user: state.user,
  application: state.application,
  menu: state.menu
});

const mapDispatcherToProps = { snackbarOn };

const reduxConnectedEmployee = connect(mapStateToProps, mapDispatcherToProps)(Employee);

export default reduxConnectedEmployee;
