import gql from 'graphql-tag';

const getVisitsOnHoldWithCache = gql`
  query getVisitsOnHoldWithCache($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      visits(entityConnection: "Visit", filter: { onHold: { eq: true } }) {
        items {
          id
          sortKey
          version
        }
      }
    }
  }
`;

export default getVisitsOnHoldWithCache;
