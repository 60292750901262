import gql from 'graphql-tag';

const getCompanyTechs = gql`
  query getCompanyTechs($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      logoUrl
      companyName
      email
      fax
      phonePrimary
      websiteUrl
      status
      tenantId
      partitionKey
      sortKey
      hierarchy
      entityType
      employees(entityConnection: "Employee") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          firstName
          lastName
          name
          email
          personalEmail
          status
          cellPhone
          landlinePhone
          profilePictureUrl
          devices(entityConnection: "Device") {
            items {
              id
              isActive
            }
          }
          offSchedules(entityConnection: "OffSchedule") {
            items {
              offDate
              offReason
              version
              startTime
              endTime
              hierarchy
              sortKey
              partitionKey
              entityType
              id
              tenantCompanyId
            }
          }
          departments(entityConnection: "Department") {
            items {
              id
              tenantId
              tenantCompanyId
              partitionKey
              sortKey
              invertedSortKey
              hierarchy
              entityType
              mappedEntity {
                ... on Department {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  tagName
                  tagType
                }
              }
            }
          }
        }
      }
      departments(entityConnection: "Department") {
        items {
          id
          tenantId
          tenantCompanyId
          partitionKey
          sortKey
          hierarchy
          entityType
          tagName
          tagType
          skills(entityConnection: "Skill") {
            items {
              mappedEntity {
                ... on Skill {
                  id
                  tenantId
                  tenantCompanyId
                  partitionKey
                  sortKey
                  hierarchy
                  entityType
                  tagName
                  tagType
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getCompanyTechs;
