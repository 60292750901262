/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';
import { sentryException } from '../Logger/index';

export async function getScheduleOfValues() {
  return await instance
    .get(`scheduleofvalues`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getScheduleOfValueById(id, noInclude) {
  return await instance
    .get(`scheduleofvalues/${id}`, {
      params: {
        include: noInclude ? [] : ['scheduleOfValueLine']
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getScheduleOfValueByProjectId(id, noInclude) {
  return await instance
    .get(`scheduleofvalues/byproject/${id}`, {
      params: {
        include: noInclude ? [] : ['scheduleOfValueLine']
      }
    })
    .then(({ data }) => {
      return data[0];
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getScheduleOfValuesByProjectId(id) {
  return await instance
    .get(`scheduleofvalues/byproject/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getScheduleOfValueByChangeOrderId(id, noInclude) {
  return await instance
    .get(`scheduleofvalues/bychangeorder/${id}`, {
      params: {
        include: noInclude ? [] : ['scheduleOfValueLine']
      }
    })
    .then(({ data }) => {
      return data[0];
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function scheduleOfValueCreate(payload) {
  return await instance
    .post('scheduleofvalues', payload)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function scheduleOfValueChange(id, payload) {
  return await instance
    .put(`scheduleofvalues/${id}`, payload)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function scheduleOfValueDelete(id) {
  return await instance
    .delete(`scheduleofvalues/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      sentryException(err, 'Unable to delete scheduleOfValue');
      return [];
    });
}
