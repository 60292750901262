import gql from 'graphql-tag';

const getCustomerReps = gql`
  query getCustomerReps($customerId: String!, $propertyId: String!, $billingCustomerId: String!) {
    getCustomerById(id: $customerId) {
      id
      customerReps(
        entityConnection: "CustomerRep"
        filter: { status: { eq: "active" } }
        limit: 100
      ) {
        items {
          id
          firstName
          lastName
          email
          emailInvoice
        }
      }
    }
    getCustomerPropertyById(id: $propertyId) {
      id
      customerReps(entityConnection: "CustomerRep") {
        items {
          id
          mappedEntity {
            ... on CustomerRep {
              id
              firstName
              lastName
              email
              emailInvoice
            }
          }
        }
      }
    }
    getBillingCustomerById: getCustomerById(id: $billingCustomerId) {
      id
      customerReps(
        entityConnection: "CustomerRep"
        filter: { status: { eq: "active" } }
        limit: 100
      ) {
        items {
          id
          firstName
          lastName
          email
          emailInvoice
        }
      }
    }
  }
`;

export default getCustomerReps;
