import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Quotes(props) {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SvgIcon {...props}>
      <path d="M20.0147 1C20.4191 1 20.75 1.33 20.75 1.73333V22.2667C20.75 22.67 20.4191 23 20.0147 23H3.98529C3.58088 23 3.25 22.67 3.25 22.2667V1.73333C3.25 1.33 3.58088 1 3.98529 1H20.0147ZM17.25 13C16.2835 13 15.5 13.7835 15.5 14.75V19.25C15.5 20.2165 16.2835 21 17.25 21C18.2165 21 19 20.2165 19 19.25V14.75C19 13.7835 18.2165 13 17.25 13ZM12 17.5C11.0335 17.5 10.25 18.2835 10.25 19.25C10.25 20.2165 11.0335 21 12 21C12.9665 21 13.75 20.2165 13.75 19.25C13.75 18.2835 12.9665 17.5 12 17.5ZM6.75 17.5C5.7835 17.5 5 18.2835 5 19.25C5 20.2165 5.7835 21 6.75 21C7.7165 21 8.5 20.2165 8.5 19.25C8.5 18.2835 7.7165 17.5 6.75 17.5ZM12 13C11.0335 13 10.25 13.7835 10.25 14.75C10.25 15.7165 11.0335 16.5 12 16.5C12.9665 16.5 13.75 15.7165 13.75 14.75C13.75 13.7835 12.9665 13 12 13ZM6.75 13C5.7835 13 5 13.7835 5 14.75C5 15.7165 5.7835 16.5 6.75 16.5C7.7165 16.5 8.5 15.7165 8.5 14.75C8.5 13.7835 7.7165 13 6.75 13ZM17.25 8.5C16.2835 8.5 15.5 9.2835 15.5 10.25C15.5 11.2165 16.2835 12 17.25 12C18.2165 12 19 11.2165 19 10.25C19 9.2835 18.2165 8.5 17.25 8.5ZM12 8.5C11.0335 8.5 10.25 9.2835 10.25 10.25C10.25 11.2165 11.0335 12 12 12C12.9665 12 13.75 11.2165 13.75 10.25C13.75 9.2835 12.9665 8.5 12 8.5ZM6.75 8.5C5.7835 8.5 5 9.2835 5 10.25C5 11.2165 5.7835 12 6.75 12C7.7165 12 8.5 11.2165 8.5 10.25C8.5 9.2835 7.7165 8.5 6.75 8.5ZM19 3H5V7H19V3Z" />
    </SvgIcon>
  );
}
