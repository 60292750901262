import { useEffect, useState } from 'react';

import { interpret } from 'xstate';

import { JobStateMachine } from 'services/core';

import { allJobStatusActions, eventStatusMappings, JobStatus } from 'utils/constants';

const defaultStatusErrorMessage = 'Cannot update status for job. Please try again.';

export const useJobStatusHelper = ({ job, visits = [] }) => {
  const [jobStatusActions, setJobStatusActions] = useState([]);
  const [jobStatusService, setJobStatusService] = useState(null);

  useEffect(() => {
    const { id, version, status } = job;
    if (!status) return undefined;
    const machine = JobStateMachine(status).withContext({
      id,
      version,
      status,
      visits
    });
    const service = interpret(machine);
    service.start();
    setJobStatusService(service);

    let newJobStatusActions = [];
    const nextState = machine.transition(status, eventStatusMappings[status]);
    newJobStatusActions = nextState.nextEvents;
    setJobStatusActions(newJobStatusActions);

    return () => {
      service.stop();
    };
  }, [job, visits]);

  const actionButtons = {
    putOnHold: {
      label: 'Put Job on Hold',
      targetStatus: JobStatus.ON_HOLD,
      actionConstant: allJobStatusActions.ON_WEB_HOLD,
      errorMessage: 'Cannot put this job on hold. Some visits are still being scheduled or worked.'
    },
    reopen: {
      label: 'Reopen Job',
      targetStatus: JobStatus.IN_PROGRESS,
      actionConstant: allJobStatusActions.ON_CONTINUE,
      errorMessage: defaultStatusErrorMessage
    },
    continue: {
      label: [JobStatus.COMPLETE, JobStatus.CANCELED].includes(job.status)
        ? 'Reopen Job'
        : 'Continue Job',
      targetStatus: JobStatus.IN_PROGRESS,
      actionConstant: allJobStatusActions.ON_CONTINUE,
      errorMessage: defaultStatusErrorMessage
    },
    cancel: {
      label: 'Cancel Job',
      targetStatus: JobStatus.CANCELED,
      actionConstant: allJobStatusActions.ON_CANCEL,
      errorMessage: 'Cannot cancel this job. All visits must be cancelled, completed, or closed.'
    },
    complete: {
      label: 'Complete Job',
      targetStatus: JobStatus.COMPLETE,
      actionConstant: allJobStatusActions.ON_COMPLETE,
      errorMessage:
        'Cannot mark this job as complete. All visits must be cancelled, completed, or closed.'
    }
  };

  return [jobStatusService, jobStatusActions, actionButtons];
};
