export const calculateRenewalNumber = number => {
  if (!number) return;

  const [mainNumber, ...arr] = number.split('.');

  if (!Array.isArray(arr) || arr.length === 0) {
    return `${number}.1`;
  }
  const version = parseInt(arr[arr.length - 1], 10);
  if (!Number.isInteger(version)) return `${number}.1`;

  const tempArr = [mainNumber, ...arr];

  const result = tempArr.reduce((acc, cv, index) => {
    if (index === tempArr.length - 1) return acc.concat(version + 1);

    return acc.concat(cv).concat('.');
  }, '');

  return result;
};

export const omitTypename = obj =>
  JSON.parse(JSON.stringify(obj), (key, value) => (key === '__typename' ? undefined : value));

export default {};
