import { buildYup } from 'schema-to-yup';
import { CommonService } from 'services/core';
import { sentryException } from 'services/Logger';

// Converts Buildhero meta format to Page form meta. Only below types have wrapper
const supportedComponentTypes = [
  'TextInput',
  'DateInput',
  'FieldWithLabel',
  'SelectInput',
  'CheckboxInput',
  'ReadOnlyCheckboxInput'
];

const sectionMeta = () => ({
  title: 'Additional info',
  sm: '12',
  md: '12',
  lg: '10',
  rows: [
    {
      spacing: 3,
      cols: [
        {
          type: 'dbHidden',
          value: 'formSortKey',
          label: 'id',
          name: 'formSortKey',
          behavior: {
            edit: {
              ui: 'Hidden',
              sm: '1',
              md: '1',
              lg: '1'
            },
            new: {
              ui: 'Hidden',
              sm: '1',
              md: '1',
              lg: '1'
            }
          }
        },
        {
          type: 'dbHidden',
          value: 'formDefinitionSortKey',
          label: 'id',
          name: 'formDefinitionSortKey',
          behavior: {
            edit: {
              ui: 'Hidden',
              sm: '1',
              md: '1',
              lg: '1'
            },
            new: {
              ui: 'Hidden',
              sm: '1',
              md: '1',
              lg: '1'
            }
          }
        }
      ]
    }
  ]
});

const rowMeta = () => ({
  spacing: 3,
  lg: '10',
  cols: []
});

const fieldMeta = (fieldName, label, behaviours, variations) => {
  if (!fieldName) {
    return '';
  }
  const meta = {
    type: 'dbField',
    value: `Custom_${fieldName}`,
    name: `Custom_${fieldName}`,
    label: label,
    behavior: behaviours
  };
  // console.log(fieldName, variations);
  if (variations && variations.inputOptions) {
    const strArray = variations.inputOptions.split(',');
    const listArray = [];
    strArray.forEach(str => listArray.push({ value: str, label: str }));
    meta.specialBehaviour = { options: listArray, fieldName: 'label' };
  }
  if (variations && variations.searchableCategory === 'date') {
    meta.specialBehaviour = { saveUnixTimeStamp: true };
  }
  if (variations && variations.type === 'numeric') {
    meta.specialBehaviour = { disableDollorSign: true };
  }
  return meta;
};

const behaviors = (mode, componentName, columns, variations) => {
  let localComponentName = componentName;
  if (variations && componentName === 'TextInput') {
    // TODO: need better logic. Doing this was as we hope to use buildhero-forms across
    // We can also look at standardizing type options in buildhero forms
    // Consolidating page form components may help
    // console.log(variations, componentName);
    if (variations === 'numeric') {
      localComponentName = 'CurrencyInput';
    } else if (variations === 'longText') {
      localComponentName = 'TextArea';
    }
  }

  const meta = {
    ui: localComponentName,
    sm: '12',
    md: Math.round(12 / parseInt(columns, 10)),
    lg: Math.round(12 / parseInt(columns, 10))
  };

  return meta;
};

// helper method to convert the validation schema
const convertBOFormsValidationSchema = (searchableFields, validation, validationErrors) => {
  let convertedValidation;
  // converting validations to use custom_text1 kind fields names. This is what is used in pageform
  if (validation && validation.properties) {
    const localProperies = validation.properties;
    const localRequired = validation.required;
    const mappedProperties = {};
    const mappedRequired = [];
    Object.keys(localProperies).forEach(key => {
      const keyMapping = searchableFields[key];
      if (keyMapping) {
        mappedProperties[`Custom_${keyMapping}`] = localProperies[key];
      }
    });

    localRequired.forEach(requiredField => {
      if (searchableFields[requiredField]) {
        mappedRequired.push(`Custom_${searchableFields[requiredField]}`);
      }
    });

    convertedValidation = { ...validation, properties: mappedProperties, required: mappedRequired };
  }

  let convertedValidationErrors;
  // converting validations to use custom_text1 kind fields names. This is what is used in pageform
  if (validationErrors) {
    const localValidationErrors = validationErrors;
    const mappedValidationErrors = {};
    Object.keys(localValidationErrors).forEach(key => {
      const keyMapping = searchableFields[key];
      if (keyMapping) {
        mappedValidationErrors[`Custom_${keyMapping}`] = localValidationErrors[key];
      }
    });
    convertedValidationErrors = mappedValidationErrors;
  }
  let validationSchema;

  if (convertedValidation && convertedValidationErrors) {
    try {
      validationSchema = buildYup(convertedValidation, {
        errMessages: convertedValidationErrors
      });
    } catch (e) {
      sentryException(e, { message: 'Unable to convert validation schema for inline form' });
    }
  }
  return validationSchema;
};

const getCustomFormDataMapping = (formData, formSearchableFields, smartFieldReferences) => {
  const formDataMap = {};
  const newData = { ...formData };

  smartFieldReferences.forEach(field => {
    const { id, source } = field;
    newData[id] = source.split(':');
  });

  Object.keys(formSearchableFields).forEach(key => {
    const searchableKey = formSearchableFields[key];
    formDataMap[`Custom_${searchableKey}`] = newData[key];
  });

  return formDataMap;
};

// In buildhero forms fields like contact_name are mapped to database as 'text1' when they are searcable fields.
// For dynamically querying, all the inline form queries use 'Custom_text1' alias and the same will be use in pageform
// validation schemas are also merged in a similar fashion
export const getCustomFormDefiniton = async (tenantId, companySortKey, entityName) => {
  const CommonServiceObj = new CommonService();
  const convertedMeta = [];
  let convertedValidationSchema;
  const response = await CommonServiceObj.getFormsAvailableToEntity(
    tenantId,
    companySortKey,
    entityName
  );

  if (response && response.data && response.data.getCompany) {
    const { forms } = response.data.getCompany;
    const formsList = (forms || {}).items || [];
    // console.log(formsList);
    const [formDef] = formsList.filter(
      form => !!form.latestPublishedFormDefinition && form.viewType === 'Inline'
    );
    // console.log('formDef', formDef);
    const { latestPublishedFormDefinition, latestPublishedFormDefinitionSortKey, sortKey, id } =
      formDef || {};

    if (latestPublishedFormDefinition && latestPublishedFormDefinition.formDefinitionJson) {
      const customFormObj = JSON.parse(latestPublishedFormDefinition.formDefinitionJson);
      // console.log(customFormObj, 'customFormObj');
      const { searchableFields, smartFieldReferences } = customFormObj;
      const [customLayout, customFormData] = customFormObj.meta || [];
      const { fields, layouts, validation, validationErrors } = customLayout || {};

      convertedMeta.push({
        formDefinitionSortKey: latestPublishedFormDefinitionSortKey,
        formSortKey: sortKey,
        formId: id,
        formDefinitionId: latestPublishedFormDefinition.id,
        formData: getCustomFormDataMapping(customFormData, searchableFields, smartFieldReferences)
      });

      convertedValidationSchema = convertBOFormsValidationSchema(
        searchableFields,
        validation,
        validationErrors
      );
      // Iterating and converting the Buildhero form JSON to PageForm meta structure
      // Only to be used until buildhero-forms are powerful and can handle complex validations, UI rendering, CASL permisisons etc
      const sectionMetaItem = sectionMeta();
      if (fields && layouts) {
        // console.log('layouts', layouts);
        const fieldMap = {};
        Object.keys(fields).forEach(fieldKey => {
          fieldMap[fieldKey] = fields[fieldKey].default;
        });
        let rowMetaItem;
        layouts.default.contents.forEach(rowItem => {
          // console.log('rowItem', rowItem);
          const noOfColumns = (rowItem.options || {}).size || 1;
          rowMetaItem = rowMeta();
          let fieldMetaItem;
          if (rowItem.contents) {
            rowItem.contents.forEach(fieldContainter => {
              if (fieldContainter.contents) {
                fieldContainter.contents.forEach(fieldItem => {
                  // console.log('fieldItem', fieldItem);
                  const { component } = fieldItem;
                  if (component) {
                    Object.keys(component).forEach(fieldKey => {
                      // some components may not have searchable items like section header
                      let layoutFieldmap = [];
                      if (fieldItem.source && fields[fieldItem.source]) {
                        layoutFieldmap = fields[fieldItem.source];

                        // add variations
                        const variations =
                          (fieldItem.options || {}).type ||
                          (fieldItem.options || {}).searchableCategory;
                        const behaviourItem = {};
                        Object.keys(layoutFieldmap).forEach(behaviourKey => {
                          const modifiedKey = behaviourKey === 'default' ? 'view' : behaviourKey;
                          // omit non supported component types
                          if (
                            component &&
                            component[behaviourKey] &&
                            supportedComponentTypes.includes(component[behaviourKey])
                          ) {
                            behaviourItem[modifiedKey] = behaviors(
                              modifiedKey,
                              component[behaviourKey],
                              noOfColumns,
                              variations
                            );
                            if (modifiedKey === 'edit') {
                              behaviourItem.new = behaviors(
                                'new',
                                component[behaviourKey],
                                noOfColumns,
                                variations
                              );
                            }
                            fieldMetaItem = fieldMeta(
                              searchableFields[fieldItem.source],
                              (fieldItem.options || {}).label || '',
                              behaviourItem,
                              fieldItem.options
                            );
                          } else {
                            // have to clear field meta to avoid duplicates
                            fieldMetaItem = '';
                          }
                        });
                      } else {
                        // have to clear field meta to avoid duplicates
                        fieldMetaItem = '';
                      }
                    });
                  } else {
                    fieldMetaItem = '';
                  }
                });
              }

              if (fieldMetaItem) {
                rowMetaItem.cols.push(fieldMetaItem);
              }
            });
          }
          sectionMetaItem.rows.push(rowMetaItem);
        });
        convertedMeta.push(sectionMetaItem);
      }
    }
  }
  if (convertedValidationSchema) {
    convertedMeta.push(convertedValidationSchema);
  }
  // console.log('convertedMeta', convertedMeta);
  return convertedMeta;
};

export const getAssignedFormDataFromForm = form => {
  if (!form) return null;

  const {
    latestPublishedFormDefinition,
    id: formId,
    latestPublishedFormDefinitionSortKey: formDefinitionSortKey,
    sortKey: formSortKey,
    name,
    description
  } = form;

  // get latest form definition as formDataJson
  const formDefinitionJson = latestPublishedFormDefinition?.formDefinitionJson;
  const formDataJson = JSON.parse(formDefinitionJson);
  const formDataWithNameAndDescription = {
    name,
    description,
    formData: formDataJson
  };

  const formData = {
    formDataJson: JSON.stringify(formDataWithNameAndDescription),
    formId,
    formDefinitionId: latestPublishedFormDefinition.id,
    formDefinitionSortKey,
    formSortKey
  };

  return formData;
};

export default getCustomFormDefiniton;
