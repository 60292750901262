import React from 'react';
import PropTypes from 'prop-types';
import { EnumType, SyncStatus as Status } from 'utils/constants';
import { labelSchema } from 'meta/labels';
import { Typography, TV } from '@buildhero/sergeant';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useTheme } from '@material-ui/core';

import StatusChip from '../StatusChip';

export default function SyncStatus({
  locale,
  syncStatus = Status.NOT_SYNCED,
  syncLog,
  userFriendlyErrorMessage = undefined
}) {
  const { palette } = useTheme();
  return (
    <Typography>
      <b>Accounting Software Sync Status: </b>
      <StatusChip
        label={labelSchema[syncStatus][locale]}
        enumType={EnumType.SYNC_STATUS}
        enumValue={syncStatus}
      />
      <br />
      {syncLog && (
        <Typography
          variant={TV.S1}
          color={palette.error.main}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <ErrorOutlineIcon style={{ fontSize: 16, marginRight: 4 }} />
          <b>Sync Failed: </b>
          <Typography
            variant={TV.S1}
            color={palette.error.main}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            {userFriendlyErrorMessage && (
              <>
                {userFriendlyErrorMessage}
                <div style={{ fontSize: 9 }}>
                  {userFriendlyErrorMessage && 'Original error: '}
                  {syncLog}
                </div>
              </>
            )}
            {!userFriendlyErrorMessage && <> {syncLog} </>}
          </Typography>
        </Typography>
      )}
    </Typography>
  );
}

SyncStatus.propTypes = {
  locale: PropTypes.string,
  syncStatus: PropTypes.string,
  syncLog: PropTypes.string
};

SyncStatus.defaultProps = {
  locale: 'en',
  syncStatus: undefined,
  syncLog: undefined
};
