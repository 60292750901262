const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5)
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: '#2dce89',
    color: '#fff',
    height: 24,
    letterSpacing: 0.24,
    borderRadius: 12,
    width: 'auto'
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    color: '#959595',
    fontSize: 12,
    letterSpacing: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal'
  }
});

export default styles;
