export const InvoiceWarningLayout = {
  fields: {},
  layouts: {
    default: {
      contents: [
        {
          options: {
            fontSize: '14px',
            fontWeight: 400
          },
          component: {
            default: 'SeargentSubtitle'
          },
          source:
            'This maintenance is part of a Service Agreement with defined Billing Terms. Would you like to proceed with creating an invoice for this individual maintenance?'
        }
      ]
    }
  }
};

export default InvoiceWarningLayout;
