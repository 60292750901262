import gql from 'graphql-tag';

const getQuotesByCustomerPropertyById = gql`
  query getQuotesByCustomerPropertyById(
    $id: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getCustomerPropertyById(id: $id) {
      id
      quotes: quotesView(
        entityConnection: "Quote"
        pagination: { limit: $limit, offset: $offset }
        filter: $filter
        sorting: $sort
      ) {
        nextToken
        items {
          id
          version
          quoteNumber
          customIdentifier
          description
          name
          ownerValue
          lastUpdatedDate
          createdDate
          createdBy
          expirationDate
          status
          versionNumber
          versionLabel
        }
      }
    }
  }
`;
export default getQuotesByCustomerPropertyById;
