import React from 'react';
import { any, bool, object } from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ThemeProvider, TV, Typography, TypographyWeight } from '@buildhero/sergeant';

import FeatureFlagsConstants from 'utils/FeatureFlagConstants';
import withLazyMount from 'utils/withLazyMount';

import ReviewReportInventoryItems from './components/ReviewReportInventoryItems';
import ReviewReportPurchasedItems from './components/ReviewReportPurchasedItems';
import ReviewReportPurchasedItemsProcurementOff from './components/ReviewReportPurchasedItemsProcurementOff';
import { useStyles } from './ReviewReportPartsAndMaterialsSection.styles';
import { selectReviewReportPurchasesItems } from '../../../../selectors';

const ReviewReportPartsAndMaterialsSection = ({ reviewReport, visit, job, loading, error }) => {
  const styles = useStyles();
  const ldFlags = useFlags();
  const isProcurementOn = ldFlags[FeatureFlagsConstants.PROCUREMENT];

  // During transition from Simple Puchase Order (old flow) to Procurement, need to show both tables
  // https://buildops.atlassian.net/wiki/spaces/EN/pages/1589313537/Purchase+Order+Migration+Segmentation
  const simpleOrderPurchasedItems = selectReviewReportPurchasesItems(reviewReport) || [];

  return (
    <div css={styles.container}>
      <Typography variant={TV.L} weight={TypographyWeight.BOLD} css={styles.title}>
        Parts & Materials
      </Typography>
      <ThemeProvider>
        <ReviewReportInventoryItems
          reviewReport={reviewReport}
          visit={visit}
          job={job}
          loading={loading}
          error={error}
        />
      </ThemeProvider>
      {isProcurementOn ? (
        <>
          <ThemeProvider>
            <ReviewReportPurchasedItems
              reviewReport={reviewReport}
              visit={visit}
              job={job}
              loading={loading}
              error={error}
            />
          </ThemeProvider>
          {simpleOrderPurchasedItems.length > 0 ? (
            <ReviewReportPurchasedItemsProcurementOff
              reviewReport={reviewReport}
              visit={visit}
              job={job}
              loading={loading}
              error={error}
              readOnly
            />
          ) : null}
        </>
      ) : (
        <ReviewReportPurchasedItemsProcurementOff
          reviewReport={reviewReport}
          visit={visit}
          job={job}
          loading={loading}
          error={error}
        />
      )}
    </div>
  );
};

ReviewReportPartsAndMaterialsSection.propTypes = {
  reviewReport: object.isRequired,
  visit: object.isRequired,
  job: object.isRequired,
  loading: bool.isRequired,
  error: any
};

ReviewReportPartsAndMaterialsSection.defaultProps = {
  error: undefined
};

export default withLazyMount(ReviewReportPartsAndMaterialsSection);
