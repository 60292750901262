const removeObjProperties = (sourceObj, list = []) => {
  const objKeys = Object.keys(sourceObj);
  return objKeys.reduce((value, key) => {
    if (!list.includes(key)) {
      value[key] = sourceObj[key];
    }
    return value;
  }, {});
};

export default removeObjProperties;
