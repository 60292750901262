import { makeStyles } from '@material-ui/core';

import { DispatchTheme, ElementSizes, ElementZIndex } from '@dispatch/Dispatch.styles';

const footerHeight = 200;

const headerMixin = theme => ({
  position: 'sticky',
  top: 0,
  zIndex: ElementZIndex.rightDrawerHeader,
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  ...DispatchTheme.mixins.bottomShadow
});

export const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 64
  },
  formContainer: {
    position: 'relative',
    overflow: 'scroll',
    height: `calc(100vh - ${ElementSizes.mainNavHeight}px)`
  },
  header: {
    ...headerMixin(theme),
    background: theme.palette.grayscale(90)
  },
  headerLoading: {
    ...headerMixin(theme),
    background: theme.palette.background.default
  },
  customHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  closeText: {
    paddingLeft: theme.spacing(0.5)
  },
  scrollContent: {
    background: theme.palette.background.default,
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingBottom: footerHeight
  },
  footer: {
    position: 'fixed',
    display: 'flex',
    bottom: 0,
    width: ElementSizes.rightDrawerWidth,
    background: theme.palette.grayscale(90),
    padding: theme.spacing(3),
    ...DispatchTheme.mixins.topShadow
  },
  resetButton: {
    width: '100%',
    marginRight: theme.spacing(1)
  },
  saveButton: {
    width: '100%',
    marginLeft: theme.spacing(1)
  },
  divider: {
    height: 1,
    backgroundColor: 'white'
  }
}));
