const styles = theme => ({
  inlineFormButton: {
    marginBottom: theme.spacing(2.5)
  },
  redButton: {
    borderColor: '#d82d51',
    color: '#d82d51'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
});

export default styles;
