import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  selectionInput: {
    background: theme.palette.grayscale(96),
    padding: theme.spacing(0.5),
    paddingTop: theme.spacing(0.7),
    paddingBottom: theme.spacing(0.7),
    '& p': {
      fontSize: '20px',
      marginLeft: '5px',
      color: theme.palette.text.primary
    }
  },
  subtitle: {
    fontSize: '10px',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  selectionWrapper: {
    backgroundColor: `${theme.palette.grayscale(100)} !important`,
    padding: 0
  },
  selectionInputPlaceholder: {
    '& label': {
      fontSize: '20px',
      color: theme.palette.text.primary
    }
  },
  noEditableValue: {
    fontWeight: 'normal',
    fontSize: '16px',
    padding: theme.spacing(1),
    background: theme.palette.grayscale(96),
    margin: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    minWidth: 70
  },
  input: {
    fontWeight: 'normal',
    fontSize: '16px',
    padding: theme.spacing(1),
    background: theme.palette.grayscale(96),
    margin: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    '&:hover': {
      background: theme.palette.grayscale(92)
    },
    '& input': {
      padding: 0
    }
  },
  hourAbbreviation: {
    minWidth: 50
  },
  inputIcon: {
    color: theme.palette.grayscale(90)
  },
  blueDivider: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: 12
  }
}));
