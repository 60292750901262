import React from 'react';
import { EntityType } from 'utils/constants';
import { FieldType, Field, ThemeProvider } from '@buildhero/sergeant';
import { get } from 'lodash';
import { convertToCurrencyString } from 'utils';

/**
 * If using SgtForm, name and form will be provided from FieldArray (formik).
 * Otherwise, provide items array
 */
export default ({ name, form, items, props: { label } = {} }) => {
  return (
    <ThemeProvider>
      <Field
        value={(items ?? get(form?.values, name))?.map(t => {
          // eslint-disable-next-line no-shadow
          let label;
          let to;
          const appliedAmount = convertToCurrencyString(t.appliedAmount);
          if ((t.transactionEntity ?? t.transaction.__typename) === EntityType.INVOICE) {
            const { invoiceNumber, id } = t.transaction;
            label = `Invoice ${invoiceNumber} - ${appliedAmount}`;
            to = `/invoice/view/${id ?? t.transactionId}`;
          } else {
            const { paymentNumber, id } = t.transaction;
            label = `Payment ${paymentNumber} - ${appliedAmount}`;
            to = `/payments/view/${id ?? t.transactionId}`;
          }
          return { label, to };
        })}
        type={FieldType.LINK}
        label={label}
      />
    </ThemeProvider>
  );
};
