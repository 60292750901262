import React from 'react';
import ReportTable from '../../../ReportTable';
import { selectVisitSummaries } from '../../../../selectors';
import { ReportTableSectionDefaultProps, ReportTableSectionPropTypes } from '../../../../propTypes';
import { summaryNotesColumns } from '../../../../meta';

const TechnicianReportSummaryNotesTable = ({ visit, loading, error }) => {
  const title = 'Visit Summary';
  const rows = selectVisitSummaries(visit) || [];
  return (
    <ReportTable
      title={title}
      columns={summaryNotesColumns}
      rows={rows}
      loading={loading}
      error={error}
      noDataMessage="No Summary Note"
    />
  );
};

TechnicianReportSummaryNotesTable.propTypes = ReportTableSectionPropTypes;
TechnicianReportSummaryNotesTable.defaultProps = ReportTableSectionDefaultProps;

export default TechnicianReportSummaryNotesTable;
