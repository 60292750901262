import gql from 'graphql-tag';

const getAllReviewReportByCompany = gql`
  query getCompany(
    $partitionKey: String!
    $sortKey: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      reviewReport: reviewReportsView(
        entityConnection: "ReviewReport"
        pagination: { limit: $limit, offset: $offset }
        filter: $filter
        sorting: $sort
      ) {
        nextToken
        items {
          id
          sortKey
          createdBy
          createdDate
          lastUpdatedBy
          lastUpdatedDateTime
          status
          departmentName
          visit: parentEntity {
            ... on Visit {
              id
              visitNumber
              departmentName
              submittedBy
              submittedDate
              description
              summaries {
                items {
                  id
                  summary
                }
              }
              extraTechs {
                items {
                  mappedEntity {
                    ... on Employee {
                      id
                      name
                      firstName
                      lastName
                    }
                  }
                }
              }
              primaryTechs {
                items {
                  mappedEntity {
                    ... on Employee {
                      id
                      name
                      firstName
                      lastName
                    }
                  }
                }
              }
              job: parentEntity {
                ... on Job {
                  id
                  customIdentifier
                  sortKey
                  jobNumber
                  completedDate
                  customerName
                  customerId
                  customerSortKey
                  customerPropertyName
                  customerPropertyId
                  customerPropertySortKey
                  jobTypeName
                  jobTypeInternal
                  status
                  issueDescription
                  customerProperty {
                    companyAddresses {
                      items {
                        addressType
                        addressLine1
                        addressLine2
                        city
                        state
                        zipcode
                      }
                    }
                  }
                  customer {
                    companyAddresses {
                      items {
                        addressType
                        addressLine1
                        addressLine2
                        city
                        state
                        zipcode
                      }
                    }
                  }
                  billingCustomer {
                    companyAddresses {
                      items {
                        addressType
                        addressLine1
                        addressLine2
                        city
                        state
                        zipcode
                      }
                    }
                  }
                  jobJobTags(entityConnection: "JobJobTag") {
                    items {
                      id
                      entityType
                      mappedEntity: jobTag {
                        id
                        entityType
                        tagName
                      }
                    }
                  }
                  owner(entityConnection: "owner") {
                    id
                    name
                    firstName
                    lastName
                  }
                  invoice: invoicesView(entityConnection: "Invoice") {
                    items {
                      id
                      invoiceNumber
                      isActive
                      status
                      invoiceVisits(entityConnection: "InvoiceVisit") {
                        items {
                          visit(entityConnection: "visit") {
                            visitNumber
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getAllReviewReportByCompany;
