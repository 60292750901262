import React, { useState } from 'react';

import { any, bool, object } from 'prop-types';
import { useSelector } from 'react-redux';

import AlgoliaSearch from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import { useLazyFetchPricebookEntry } from 'components/Tasks/components/useLazyFetchPricebookEntry';
import discountLayoutSergeant from 'meta/Jobs/Invoice/DiscountFormSergeant';
import { selectReviewReportDiscounts } from 'scenes/Jobs/JobDetail/selectors';
import withLazyMount from 'utils/withLazyMount';

import ReportTable from '../../../ReportTable';

import { useReviewReportDisabled } from '../../ReviewReport.contexts';
import TableRowModal from '../TableRowModal';

import DiscountsSectionHeader from './components/DiscountsSectionHeader';
import {
  useAddReviewReportDiscount,
  useDeleteReviewReportDiscount,
  useUpdateReviewReportDiscount
} from './mutations';
import { useDiscountsTableColumns, useGetProductFilter } from './ReviewReportDiscounts.hooks';

const ReviewReportDiscounts = ({ reviewReport, job, loading, error }) => {
  const tenantId = useSelector(state => state.user)?.tenantId;
  const defaultPricebook = useSelector(state => state.company)?.defaultPricebook;
  const { disabled } = useReviewReportDisabled();
  const rows = selectReviewReportDiscounts(reviewReport) || [];

  const productFilters = useGetProductFilter({ job });
  const fetchPricebookEntry = useLazyFetchPricebookEntry();
  const handleOnItemSelection = async (selectedItem, selectedItemName, form) => {
    const { values } = form;
    if (!selectedItem)
      return form.setValues({
        ...values,
        itemName: '',
        taxable: false,
        amount: '',
        productId: ''
      });
    const pbEntry = await fetchPricebookEntry({
      pricebookId: job?.priceBookId || defaultPricebook.id,
      productSortKey: selectedItem.sortKey
    });
    const valuesToSet = {
      ...values,
      itemName: selectedItem.name,
      taxable: selectedItem?.taxable || false,
      amount: pbEntry.unitPrice,
      productId: selectedItem.id
    };
    form.setValues(valuesToSet);
  };

  // Functions and mutation for adding Discount
  const [handleAddDiscount, { loading: addLoading }] = useAddReviewReportDiscount(reviewReport.id);
  const [discountToAdd, setDiscountToAdd] = useState(null);
  const showAddModal = row => setDiscountToAdd(row);
  const closeAddModal = () => setDiscountToAdd(null);
  const handleAdd = async data => {
    await handleAddDiscount({
      tenantId,
      reviewReportId: reviewReport?.id,
      discount: data
    });
    closeAddModal();
  };

  // Functions and mutation for updating Discount
  const [handleUpdateDiscount, { loading: updateLoading }] = useUpdateReviewReportDiscount();
  const [discountToUpdate, setDiscountToUpdate] = useState(null);
  const showEditModal = row => setDiscountToUpdate(row);
  const closeEditModal = () => setDiscountToUpdate(null);
  const handleEdit = async ({ _rowIndex, ...data }) => {
    await handleUpdateDiscount({
      tenantId,
      discount: data
    });
    closeEditModal();
  };

  // Mutation for deleting Discount
  const [handleDelete, { loading: deleteLoading }] = useDeleteReviewReportDiscount(reviewReport.id);

  const handleIncludeInInvoiceChange = row => event =>
    handleUpdateDiscount({
      tenantId,
      discount: {
        ...row,
        includeInInvoice: event.target.checked
      }
    });

  const handleIncludeInInvoiceAllChange = event => {
    const { checked } = event.target;
    return Promise.all(
      rows
        .filter(row => (checked ? !row.includeInInvoice : row.includeInInvoice))
        .map(row =>
          handleUpdateDiscount({
            tenantId,
            discount: {
              ...row,
              includeInInvoice: event.target.checked
            }
          })
        )
    );
  };

  const columns = useDiscountsTableColumns({
    showAddModal,
    showEditModal,
    handleDelete,
    tenantId,
    updateLoading,
    rows,
    handleIncludeInInvoiceChange,
    handleIncludeInInvoiceAllChange,
    disabled
  });

  const renderTitle = () => (
    <DiscountsSectionHeader
      onAddDiscounts={() => {
        showAddModal({});
      }}
    />
  );

  return (
    <>
      <ReportTable
        renderTitle={renderTitle}
        columns={columns}
        rows={rows}
        loading={loading || addLoading || updateLoading || deleteLoading}
        error={error}
        noDataMessage="No Discounts"
      />
      <TableRowModal
        onAction={handleEdit}
        onClose={closeEditModal}
        open={Boolean(discountToUpdate)}
        layout={discountLayoutSergeant(productFilters, handleOnItemSelection)}
        data={discountToUpdate}
        customComponents={{ AlgoliaSearch }}
        title="Edit Discount"
      />
      <TableRowModal
        onAction={handleAdd}
        onClose={closeAddModal}
        open={Boolean(discountToAdd)}
        layout={discountLayoutSergeant(productFilters, handleOnItemSelection)}
        data={discountToAdd}
        customComponents={{ AlgoliaSearch }}
        title="Add Discount"
      />
    </>
  );
};

ReviewReportDiscounts.propTypes = {
  reviewReport: object.isRequired,
  job: object.isRequired,
  loading: bool.isRequired,
  error: any
};
ReviewReportDiscounts.defaultProps = {
  error: undefined
};

export default withLazyMount(ReviewReportDiscounts);
