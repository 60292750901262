import { QuickbooksService } from 'services/core';

const getSageJobs = async user => {
  const response = await new QuickbooksService().getSageJobs(
    user.tenantId,
    `${user.tenantId}_Company_${user.tenantCompanyId}`
  );
  return response.data?.getCompany?.sageJobs?.items || [];
};

export default getSageJobs;
