import gql from 'graphql-tag';

export const deleteJobTask = gql`
  mutation deleteJobTask($id: String!) {
    deleteJobTask(id: $id) {
      id
      task {
        id
        status
      }
    }
  }
`;
