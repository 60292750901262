import gql from 'graphql-tag';

const addNonInventoryProduct = gql`
  mutation addNonInventoryProduct($partitionKey: String!, $data: AddNonInventoryProductInput!) {
    addNonInventoryProduct(partitionKey: $partitionKey, data: $data) {
      id
      name
      taxable
    }
  }
`;

export default addNonInventoryProduct;
