import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { Typography, Grid, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { isEmpty } from 'lodash';
import Chip from '@material-ui/core/Chip';
import PrimaryCrown from 'assets/Icons/primaryCrown.svg';
import Crown from 'assets/Icons/Crown.svg';
import ErrorBoundaries from 'scenes/Error';
import { Spinner } from 'components';
import { convertPriceToString } from './Tabs/Info/utils/Info.utils';

const mapStatusLabel = {
  SentToCustomer: 'Sent To Customer',
  JobAdded: 'Job Added',
  EmailRead: 'Email Read',
  EmailedBounced: 'Email Bounced',
  CustomerViewed: 'Customer Viewed'
};

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    minWidth: '153px',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase);

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  menuItem: {
    margin: 0,
    padding: '5px 5px 5px 5px',
    background: theme.palette.grayscale(100)
  },
  menuItemNew: {
    marginTop: '15px',
    padding: '5px 5px 5px 5px',
    background: theme.palette.grayscale(100)
  },
  vCaption: {
    color: theme.palette.grayscale(20),
    fontSize: 11,
    fontWeight: 'bold',
    letterSpacing: '0.01px',
    lineHeight: '15.4px'
  },
  iconSize: {
    height: '12px',
    width: '12px'
  },
  grid: {
    display: 'grid'
  },
  optionTitle: {
    color: theme.palette.grayscale(20)
  },
  status: {
    color: theme.palette.grayscale(100),
    fontFamily: theme.typography.fontFamily,
    fontSize: 10,
    padding: '2px 4px',
    borderRadius: 10,
    width: 'fit-content',
    backgroundColor: theme.palette.grayscale(60),
    textTransform: 'uppercase'
  },
  middle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left'
  },
  primaryIcon: {
    height: 16,
    backgroundColor: theme.palette.grayscale(20),
    fontFamily: theme.typography.fontFamily,
    fontSize: 10,
    color: theme.palette.grayscale(100),
    fontWeight: '500',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: theme.palette.grayscale(20),
      color: theme.palette.grayscale(100)
    }
  }
}));

const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  anchorEl: () => versionRef.current,
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      background: '#ffffff'
    }
  }
};

const versionRef = React.createRef();
export default function VersionComp(props) {
  const {
    newCreationLabel,
    getOptions,
    createNewService,
    setPrimaryService,
    changeVersionsView,
    quoteInfo,
    buttonClicked,
    hasPermissionToEditQuote
  } = props;

  const classes = useStyles();
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const [isPrimary, setPrimary] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getAndSetPrimary = (opts, id) => {
    const selectedItem = opts.find(opt => opt.id === value);
    setPrimary((selectedItem && selectedItem.isPrimary) || false);
  };

  const handleChange = event => {
    const selectedValue = event.target.value;
    changeVersionsView(selectedValue, options);
    setValue(selectedValue);
    getAndSetPrimary(options, selectedValue);
  };

  const renderValue = selected => {
    if (selected && options && !isEmpty(options)) {
      const selectedItem = options.find(option => selected === option.id);
      if (selectedItem && selectedItem.name) {
        return selectedItem.name;
      }
    }
    return '';
  };

  const createNew = async () => {
    setIsLoading(true);
    const data = await createNewService();
    if (data && !isEmpty(data)) {
      // reassign currently create new version
      const latestOption = data[data.length - 1];
      changeVersionsView(latestOption.id, data);
      setValue(latestOption.id);
      getAndSetPrimary(data, latestOption.id);
    }
    setIsLoading(false);
  };

  const getAndSetOptions = async () => {
    const { data } = getOptions || { data: {} };
    if (data?.items && !isEmpty(data.items)) setOptions(data.items);

    if (quoteInfo && !isEmpty(quoteInfo)) {
      setValue(quoteInfo.id);
    }

    if (!value) {
      setValue(data?.items[0]?.id);
      setPrimary(data?.items[0]?.isPrimary || false);
      return;
    }
    getAndSetPrimary(data.items, value);
  };

  const primaryVersion = async () => {
    await setPrimaryService(value);
  };

  useEffect(() => {
    getAndSetOptions();
  });

  const quoteStatusOptions = ['JobAdded', 'Cancelled', 'Discarded', 'Rejected'];

  return (
    <ErrorBoundaries>
      {options && !isEmpty(options) && (
        <div className={classes.middle}>
          <FormControl className={classes.formControl}>
            <Select
              labelId="version"
              id="verison"
              value={value}
              ref={versionRef}
              onChange={handleChange}
              input={<BootstrapInput />}
              renderValue={selected => renderValue(selected)}
              MenuProps={MenuProps}
            >
              {options.map(version => (
                <MenuItem className={classes.menuItem} key={version.id} value={version.id}>
                  <Box component="div" width={1}>
                    <Grid container>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography className={classes.vCaption}>
                          {version.name}
                          {version.isPrimary && (
                            <Box component="span" position="relative" top={2}>
                              <img
                                src={PrimaryCrown}
                                alt=""
                                height="12px"
                                width="12px"
                                style={{ marginLeft: '4px' }}
                              />
                            </Box>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography className={classes.vCaption} align="right">
                          {`$${version.amount ? convertPriceToString(version.amount) : '00.00'}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <div className={classes.grid}>
                      <Typography
                        className={classes.optionTitle}
                        variant="overline"
                      >{`${version.option || ''}`}</Typography>
                    </div>
                    <div className={classes.grid}>
                      <Typography className={classes.status} variant="overline">
                        {mapStatusLabel[version.status]
                          ? `${mapStatusLabel[version.status]}`
                          : `${version.status}`}
                      </Typography>
                    </div>
                    <div className={classes.grid}>
                      <Typography variant="overline">{`Last Edit: ${version.LastModifiedBy ||
                        ''}`}</Typography>
                    </div>
                    <div className={classes.grid}>
                      <Typography variant="overline">{`${version.date}`}</Typography>
                    </div>
                  </Box>
                </MenuItem>
              ))}
              {quoteInfo &&
                !isEmpty(quoteInfo) &&
                hasPermissionToEditQuote &&
                !['JobAdded', 'Cancelled', 'Discarded'].includes(quoteInfo.status) && (
                  <MenuItem
                    className={classes.menuItem}
                    key={newCreationLabel}
                    value={newCreationLabel}
                    disabled={isLoading}
                  >
                    <Button variant="outlined" fullWidth onClick={e => createNew(e)}>
                      <Typography variant="overline">{`${newCreationLabel}`}</Typography>
                    </Button>
                  </MenuItem>
                )}
            </Select>
          </FormControl>
          {quoteInfo && !isEmpty(quoteInfo) && !quoteStatusOptions.includes(quoteInfo.status) && (
            <>
              <Chip
                icon={
                  isPrimary ? (
                    <img src={PrimaryCrown} alt="" height="16px" width="16px" />
                  ) : (
                    <img src={Crown} alt="" height="16px" width="16px" />
                  )
                }
                label="PRIMARY"
                className={classes.primaryIcon}
                onClick={isPrimary ? '' : e => primaryVersion(e)}
              />
              {buttonClicked && <Spinner size={20} />}
            </>
          )}
        </div>
      )}
    </ErrorBoundaries>
  );
}
