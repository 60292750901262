import { withQueryStringStore } from '@dispatch/Dispatch.store';

import VisitsFilterButton from './VisitsFilterButton.component';

const mapQueryStringToProps = ({ filterBy, state: { visitStatuses, showUnscheduled } }) => ({
  filterBy,
  visitStatuses,
  showUnscheduled: Boolean(showUnscheduled)
});

export default withQueryStringStore(mapQueryStringToProps)(VisitsFilterButton);
