import moment from 'moment';

import { OrderQtyStatus, ShippingInformation, ShipToNameTypes } from 'scenes/Procurement/constants';
import { getCombinedAddressFromProjectData } from 'scenes/ProjectManagement/components/utils';
import getCompanyAddressByParentId from 'services/API/companyAddress';
import { getJobById } from 'services/API/job';
import { getProjectById } from 'services/API/project';
import { getTaxRateById } from 'services/API/taxRate';
import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';
import { formatAddress, getCombinedAddress, roundCurrency, roundFloat } from 'utils';
import { JobTypes, ProcurementPurchaseOrderStatus } from 'utils/AppConstants';

// ADW test of our cherry picks
export const GOOGLE_MAPS_BASE_URL =
  'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=';

export const getEntireStatus = status => {
  switch (status) {
    case ProcurementPurchaseOrderStatus.ORDERED:
      return 'ordered';
    case ProcurementPurchaseOrderStatus.PARTIALLY_FULFILLED:
      return 'partiallyFulfilled';
    case ProcurementPurchaseOrderStatus.FULFILLED:
      return 'fulfilled';
    case ProcurementPurchaseOrderStatus.UNFULFILLED:
      return 'unfulfilled';
    case ProcurementPurchaseOrderStatus.VOID:
      return 'void';
    case ProcurementPurchaseOrderStatus.DRAFT:
    default:
      return 'draft';
  }
};

/**
 * Given order line, receipt line, or bill line, get the job or project link and text.
 *
 * @param {object} data The line item object
 * @param {object} associatedProject Associated project object
 */
export const getJobOrProjectLink = (data, associatedProject = undefined) => {
  let jobOrProjectLink = '';
  const jobOrProjectText =
    data?.Job?.customIdentifier ||
    data?.Job?.jobNumber ||
    data?.Project?.name ||
    associatedProject?.name ||
    '';
  if (data?.Job?.id) {
    const isMaintenanceJob = data?.Job?.jobTypeInternal === JobTypes.MAINTENANCE;
    jobOrProjectLink = `${isMaintenanceJob ? 'maintenance' : 'job'}/view/${encodeURIComponent(
      data.Job.jobNumber
    )}`;
  } else if (data?.Project?.id) {
    jobOrProjectLink = `project/view/${data.Project.id}/dashboard`;
  } else if (associatedProject?.id) {
    jobOrProjectLink = `project/view/${associatedProject.id}/dashboard`;
  }

  return { jobOrProjectLink, jobOrProjectText };
};

export const getJobAndProjectIds = (data, canSetEmptyFields) => {
  let projectId;
  let jobId;

  switch (data.jobAndProject?.entityType) {
    case 'Job': {
      jobId = data.jobAndProject?.id || data.jobId || undefined;
      projectId = null;
      break;
    }
    case 'Project': {
      projectId = data.jobAndProject?.id || data.projectId || undefined;
      jobId = null;
      break;
    }
    default: {
      if (canSetEmptyFields) {
        jobId = null;
        projectId = null;
      }
      break;
    }
  }
  return { jobId, projectId };
};

export const getSanitizedLineItem = (
  data,
  updatedFromReceipt = false,
  canSetEmptyJobAndProject
) => {
  const { jobId, projectId } = getJobAndProjectIds(data, canSetEmptyJobAndProject);
  return {
    ...(!updatedFromReceipt && { lineNumber: data?.lineNumber }),
    ...(!updatedFromReceipt && { parentId: data?.parentId }),
    ...(!updatedFromReceipt && { quantity: Number(data?.quantity ?? 0) || 0 }),
    quantityFulfilled: updatedFromReceipt
      ? Number(data?.quantity ?? 0)
      : Number(data?.quantityFulfilled ?? 0),
    itemName: data?.Product?.name || data?.itemName || null,
    description: data?.description || null,
    unitOfMeasure: data?.unitOfMeasure || data?.Product?.unitOfMeasure?.name || null,
    unitCost: roundFloat(data?.unitCost) || 0,
    productId: data?.Product?.id || data?.productId || null,
    amount: roundCurrency(data?.totalCost) || roundCurrency(data?.quantity * data?.unitCost) || 0,
    costCodeId: data?.costCode?.id || null,
    jobCostTypeId: data?.jobCostType?.id || null,
    revenueTypeId: data?.revenueType?.id || null,
    jobId,
    projectId,
    taxable: data?.taxable || false,
    departmentId:
      data?.phaseDepartment?.departmentId || data?.Department?.id || data?.departmentId || null,
    departmentName:
      data?.phaseDepartment?.Department?.tagName ||
      data?.Department?.tagName ||
      data?.departmentName ||
      null,
    projectPhaseId: data?.projectPhase?.id || undefined,
    projectCostCodeId: data?.projectCostCode?.id || undefined,
    projectCostType: data?.projectCostType || null,
    jcCostTypeId: data?.jcCostType?.id || null,
    jcPhaseId: data?.jcPhase?.id || null
  };
};

export const calculateStatus = (lines, isOrdered) => {
  if (lines?.length) {
    let totalFulfilled = 0;
    let totalQuantity = 0;

    let unfulfilledExists = false;
    lines.forEach(line => {
      totalFulfilled += parseFloat(line?.quantityFulfilled || 0);
      totalQuantity += parseFloat(line?.quantity || 0);
      if (line.quantity > 0) {
        if (parseFloat(line?.quantityFulfilled || 0) < parseFloat(line?.quantity || 0)) {
          unfulfilledExists = true;
        }
      } else if (line.quantity < 0) {
        if (parseFloat(line?.quantityFulfilled || 0) > parseFloat(line?.quantity || 0)) {
          unfulfilledExists = true;
        }
      }
    });

    if (totalFulfilled >= totalQuantity) {
      return unfulfilledExists
        ? ProcurementPurchaseOrderStatus.PARTIALLY_FULFILLED
        : ProcurementPurchaseOrderStatus.FULFILLED;
    }
    if (totalFulfilled === 0 && totalQuantity > 0) {
      return isOrdered
        ? ProcurementPurchaseOrderStatus.ORDERED
        : ProcurementPurchaseOrderStatus.DRAFT;
    }
    if (totalFulfilled < totalQuantity) {
      return ProcurementPurchaseOrderStatus.PARTIALLY_FULFILLED;
    }
    return isOrdered
      ? ProcurementPurchaseOrderStatus.ORDERED
      : ProcurementPurchaseOrderStatus.DRAFT;
  }
  return false;
};

// TODO: get latitude/longitude from address
export const validateAddress = ({ addressLine1, addressLine2, city, state, zipcode }) => {
  const valid = addressLine1 && city && state && zipcode;
  if (valid) {
    return `${addressLine1}${
      // eslint-disable-next-line prettier/prettier
      addressLine2 ? `, ${addressLine2}, ` : ', '
    }${city}, ${state}, ${zipcode}`;
  }
  return '-';
};

export const getFormattedDate = date => {
  if (!date || date === 'Invalid date') return '';
  // convert unix integer to UTC timestamp by using utc() in order to keep date consistent across all timezones
  // this is to prevent local zone from shifting date value, which is zoneless
  return moment
    .unix(parseInt(date, 10))
    .utc()
    .format('D MMM YYYY');
};

export const getReceiptDueDate = data => {
  let dueDate = '';
  if (data?.issuedBy && data?.paymentTerm) {
    dueDate = data.issuedBy;
    dueDate += 86400 * data.paymentTerm?.value;
  }
  return dueDate;
};

export const getPurchaseOrderTopBar = (mode, data) => {
  if (!data) return {};

  const result = {
    ...getJobOrProjectLink({
      Job: data?.job,
      Project: data?.project
    }),
    associatedQuotes: data.associatedQuotes,
    department: mode === 'edit' ? data?.department || {} : data?.departmentName || '',
    depId: mode === 'edit' ? data?.department?.id || '' : data?.departmentId || '',
    jobAndProject:
      mode === 'edit'
        ? data?.job || data?.project || {}
        : (data?.job?.customIdentifier ?? data?.job?.jobNumber) || data?.project?.name || '',
    jobNumber: data?.job?.jobNumber,
    assignedTo: mode === 'edit' ? data?.assignedTo || {} : data?.assignedTo?.name || '',
    poType: mode === 'edit' ? data?.poType || {} : data?.poType?.name || '',
    requiredByDate: data?.requiredByDate,
    dateOfPurchase: data?.dateOfPurchase,
    description: data?.description || '',
    purchaseOrderTags: data?.purchaseOrderTags?.map(tag => tag.id) || [],
    tagNames: data?.purchaseOrderTags?.map(tag => tag.tagName) || [],
    note: data?.note || []
  };
  return result;
};

export const getPurchaseOrderSideBarVendor = (mode, data) => {
  if (!data) return {};

  return {
    vendor: data?.vendor || {},
    vendorName: mode === 'edit' ? data?.vendor?.name || '' : data?.vendorName || '',
    vendorAddress: data?.vendor ? validateAddress(data?.vendor) : '',
    vendorLocation: {},
    phoneNumber: data?.vendor?.phone || '',
    vendorEmail: data?.vendor?.email || ''
  };
};

export const getReceiptSideBarVendor = (mode, data) => {
  if (!data) return {};
  return {
    vendor: data?.Vendor || {},
    vendorName: mode === 'edit' ? data?.Vendor?.name || '' : data?.PurchaseOrder?.vendorName || '',
    vendorAddress: data?.Vendor ? validateAddress(data?.Vendor) : '',
    vendorLocation: {},
    phoneNumber: data?.Vendor?.phone || '',
    vendorEmail: data?.Vendor?.email || ''
  };
};

export const getPurchaseOrderSideBarShipping = (mode, data, employeeData) => {
  if (!data) return {};
  const shipToEmployee = data.shipToEmployee || employeeData || '';
  const shipToName =
    data.shipToNameType === ShipToNameTypes.EMPLOYEE ? shipToEmployee?.name : data.shipToName || '';

  return {
    shipTo: data?.shipTo || '',
    shipToEmployee,
    shipToEmployeeId: data.shipToEmployeeId,
    shipToName,
    shipToNameType: data.shipToNameType || '',
    shipToInstructions: data.shipToInstructions,
    shippingAddress: validateAddress(data) || '',
    shippingLocation: {},
    shippingAddressObj: {},
    jobAndProject: {}
  };
};

export const getPurchaseOrderCost = data => {
  if (!data) return {};

  return {
    totalAmountPreTax: data?.totalAmountPreTax || 0,
    freight: data?.freight || 0,
    taxRateId: data?.taxRateId || '',
    tax: data?.tax || 0,
    taxRate: {},
    totalCost: data?.totalCost || 0
  };
};

export const getReceiptBillTopBar = (mode, data) => {
  if (!data) return {};

  return {
    // TODO: check orderedBy, paymentTerms
    createdBy: data?.createdBy || '',
    department: mode === 'edit' ? data?.department || {} : data?.departmentName || '',
    depId: mode === 'edit' ? data?.department?.id || '' : data?.departmentId || '',
    poType: mode === 'edit' ? data?.poType || {} : data?.poType?.name || '',
    assignedTo: mode === 'edit' ? data?.assignedTo || {} : data?.assignedTo?.name || '',
    jobAndProject: data?.job?.jobNumber || data?.project?.name || '',
    requiredByDate: data?.requiredByDate,
    dateOfPurchase: data?.dateOfPurchase,
    paymentTerms: data?.paymentTerms || ''
  };
};

export const getItemStatus = (quantityFulfilled, quantity) => {
  const fulfilled = Number(quantityFulfilled ?? 0);
  const totalQty = Number(quantity ?? 0);
  let status;

  if (fulfilled) {
    if (fulfilled !== 0) {
      status = OrderQtyStatus.PARTIALLY_FULFILLED;
      if (fulfilled > 0) {
        if (fulfilled >= totalQty) {
          status = OrderQtyStatus.FULFILLED;
        }
      } else if (fulfilled < 0) {
        if (fulfilled <= totalQty) {
          status = OrderQtyStatus.FULFILLED;
        }
      }
    } else {
      status = OrderQtyStatus.UNFULFILLED;
    }
  } else if (fulfilled === 0 && totalQty === 0) {
    status = OrderQtyStatus.FULFILLED;
  } else {
    status = OrderQtyStatus.UNFULFILLED;
  }

  return status;
};

export const getFormattedPOLinesData = data => {
  return [
    ...data
      ?.sort((a, b) => a.lineNumber - b.lineNumber)
      .map(item => {
        const currentJcCostType = Array.isArray(item.JcCostType)
          ? item.JcCostType.find(costType => costType.id === item.jcCostTypeId)
          : item.JcCostType;
        const currentJcPhase = Array.isArray(item.JcPhase)
          ? item.JcPhase.find(phase => phase.id === item.jcPhaseId)
          : item.JcPhase;

        return {
          ...item,
          JcCostType: currentJcCostType || null,
          JcPhase: currentJcPhase || null,
          unitCost: roundFloat(item.unitCost) || 0.00000, // prettier-ignore
          taxable: !!item.taxable, // MUIform boolean doesn't convert 0's/1's to false/true
          quantity: Number(item.quantity ?? 0),
          quantityFulfilled: Number(item.quantityFulfilled ?? 0),
          totalCost: roundCurrency(Number(item.unitCost ?? 0) * Number(item.quantity ?? 0)) || 0
        };
      })
  ];
};

export const calcTotalCost = async (baseObject, lineKey, taxRateObj) => {
  let taxPercent = 0;
  let taxAmount = 0;
  if (taxRateObj?.taxRate) {
    taxPercent = parseFloat(parseFloat(parseFloat(taxRateObj.taxRate ?? 0) / 100));
  } else if (baseObject.taxRateId) {
    const taxRate = await getTaxRateById(baseObject.taxRateId);
    taxPercent = parseFloat(parseFloat(parseFloat(taxRate.taxRate ?? 0) / 100));
  }
  let lineItemsTotal = 0;
  baseObject[lineKey]?.forEach(line => {
    taxAmount += line.taxable
      ? roundCurrency(parseFloat(line.quantity ?? 0) * parseFloat(line.unitCost ?? 0))
      : 0;
    lineItemsTotal += roundCurrency(
      parseFloat(line.quantity ?? 0) * parseFloat(line.unitCost ?? 0)
    );
  });
  taxAmount = roundCurrency(taxAmount * taxPercent);
  lineItemsTotal += taxAmount;
  if (parseFloat(baseObject.freight ?? 0))
    return lineItemsTotal + parseFloat(baseObject.freight ?? 0);
  return lineItemsTotal;
};

export const downloadFile = async (fileName, actualFileName) => {
  if (!fileName) {
    return;
  }
  try {
    const storageService = new StorageService();
    const url = await storageService.getFile(fileName, false);
    if (url) {
      const aLink = document.createElement('a');
      aLink.target = '_blank';
      aLink.download = actualFileName;
      aLink.href = url;
      aLink.click();
    }
  } catch (error) {
    // avoid sending to Sentry as not all images will have thumbnails
    Logger.info(`Error getting image ${error}`);
  }
};

export const getImage = async url => {
  if (!url) {
    return {};
  }
  let imageS3Url;
  try {
    const storageService = new StorageService();
    imageS3Url = await storageService.getFile(url);
  } catch (error) {
    Logger.info(`Error uploading image ${error}`);
  }
  return imageS3Url;
};

export const getFormattedShippingAddress = data => {
  switch (data.shipTo) {
    case ShippingInformation.JOB_SITE:
    case ShippingInformation.WAREHOUSE:
    case ShippingInformation.VENDOR_PICKUP:
      return formatAddress(data.shippingAddressObj, true) || null;
    case ShippingInformation.MANUALLY:
    default:
      return data.shippingAddress;
  }
};

export const getAddressByType = (addresses, type) => {
  if (!addresses || addresses.length === 0) return '';
  return addresses.filter(address => address.addressType === type);
};

export const getAddressObjUsingMapdata = data => {
  if (!data) return {};

  return {
    addressLine1: data?.addressLine1 || null,
    addressLine2: data?.addressLine2 || null,
    city: data?.city?.longName || null,
    state: data?.state?.shortName || null,
    zipcode: data?.zipcode?.longName || null
  };
};

export const getAddressObj = data => {
  if (!data) return {};

  return {
    addressLine1: data?.addressLine1 || null,
    addressLine2: data?.addressLine2 || null,
    city: data?.city || null,
    state: data?.state || null,
    zipcode: data?.zipcode || null
  };
};

export const getProjectAddressObj = data => {
  if (!data) return {};

  return {
    addressLine1: data?.address1 || null,
    addressLine2: data?.address2 || null,
    city: data?.addressCity || null,
    state: data?.addressState || null,
    zipcode: data?.addressPostal || null
  };
};

export const getProjectAddressById = async id => {
  if (!id) return {};

  const project = await getProjectById(id);
  const shippingAddress = getCombinedAddressFromProjectData(project);
  const shippingAddressObj = getProjectAddressObj(project);
  return { shippingAddress, shippingAddressObj };
};

export const getShippingAddress = async (shipType, info, useJobOrProjectId = false) => {
  if (!info) return {};

  let result;
  let propertyAddr;
  switch (shipType) {
    case ShippingInformation.JOB_SITE:
      if (useJobOrProjectId) {
        if (info?.jobId) {
          result = await getJobById(info?.jobId, 'propertyAddress');
        } else if (info?.projectId) {
          const { shippingAddressObj } = await getProjectAddressById(info?.projectId);
          return shippingAddressObj;
        }
      } else if (info.jobAndProject?.id && info.jobAndProject?.entityType === 'Project') {
        return info.shippingAddressObj;
      } else {
        result = await getJobById(info.jobAndProject?.id, 'propertyAddress');
      }
      propertyAddr = getAddressByType(result?.propertyAddress, 'propertyAddress');
      return getAddressObj(propertyAddr[0]);
    case ShippingInformation.VENDOR_PICKUP:
      return getAddressObj(info.vendor);
    case ShippingInformation.MANUALLY:
      return getAddressObjUsingMapdata(info);
    case ShippingInformation.WAREHOUSE:
      result = await getCompanyAddressByParentId(info.department?.id);
      propertyAddr = getAddressByType(result, 'shippingAddress');
      return getAddressObj(propertyAddr[0]);
    default:
      return getAddressObj(null);
  }
};

export const getJobPropertyAddressByJobId = async jobId => {
  const result = await getJobById(jobId, 'propertyAddress');
  const propertyAddr = getAddressByType(result?.propertyAddress, 'propertyAddress');
  return getCombinedAddress(propertyAddr[0] || '-');
};

export const getWarehouseAddress = department => {
  const warehouseAddress = getAddressByType(department?.companyAddresses, 'shippingAddress');
  return getCombinedAddress(warehouseAddress[0] || '-');
};

export const getLastLineNumber = (items, key) => {
  if (!items || !items?.length || typeof items !== 'object') return 0;

  const sortedData = items.map(item => item[key]).sort((a, b) => a - b);
  return sortedData[sortedData.length - 1];
};

export const getJobProjectOrMaintenanceName = poData => {
  if (!poData?.jobOrProjectLink || !poData?.jobOrProjectText) return;
  const entityType = poData.jobOrProjectLink.split('/')[0] || '';
  const capitalizedEntityName = entityType[0]?.toUpperCase() + entityType.slice(1);
  return `${capitalizedEntityName} ${poData.jobOrProjectText}`;
};
