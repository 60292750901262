import { useEffect, useState } from 'react';
import { useSyncErrorMappingQuery } from './syncErrorMapping.gql';

const useUserFriendlyError = (tenantId, syncLog) => {
  const [userFriendlyErrorMessage, setUserFriendlyErrorMessage] = useState();
  const { loading, data: syncErrorMapping } = useSyncErrorMappingQuery(tenantId);
  useEffect(() => {
    if (!syncLog || loading) {
      setUserFriendlyErrorMessage(null);
    }
    const foundMapping = syncErrorMapping?.find(m => {
      return m.errorMessage?.reduce(
        (acc, errorSubString) => acc && syncLog?.includes(errorSubString),
        true
      );
    });
    setUserFriendlyErrorMessage(
      foundMapping
        ? `[${foundMapping.buildOpsErrorCode}] ${foundMapping.userFriendlyMessage}`
        : null
    );
  }, [loading, syncErrorMapping, syncLog]);
  return { loading, userFriendlyErrorMessage };
};

export default useUserFriendlyError;
