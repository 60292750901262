import { useExtendedFetchForPMAPI as useExtendedFetch } from 'customHooks/useExtendedFetch';

export const useGetPurchaseOrders = (options, params) => {
  return useExtendedFetch(
    {
      url: `purchaseorder`,
      params
    },
    {
      errorData: [],
      defaultData: [],
      ...options
    }
  );
};
