import gql from 'graphql-tag';

const mutationQuery = gql`
  mutation batchCreateEntityData($data: String!, $instructionSet: String!) {
    batchCreateEntityData(data: $data, instructionSet: $instructionSet) {
      id
      tenantCompanyId
      partitionKey
      sortKey
      hierarchy
      entityType
      version
    }
  }
`;

export default mutationQuery;
