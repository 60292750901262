import gql from 'graphql-tag';

const addCustomerRepToCustomer = gql`
  mutation addCustomerRepToCustomer($paritionKey: String!, $data: AddCustomerRepToCustomerInput!) {
    addCustomerRepToCustomer(partitionKey: $paritionKey, data: $data) {
      id
      name
      status
      isActive
    }
  }
`;

export default addCustomerRepToCustomer;
