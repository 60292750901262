import gql from 'graphql-tag';
import useExtendedMutation from 'customHooks/useExtendedMutation';

const addJobTasksToJobMutation = gql`
  mutation addJobTasksToJobMutation($partitionKey: String!, $data: AddJobTasksToJobInput!) {
    addJobTasksToJob(partitionKey: $partitionKey, data: $data) {
      id
      taskId
      task {
        id
        name
        description
      }
    }
  }
`;

const serializer = ({ partitionKey, data }) => ({
  variables: {
    partitionKey,
    data
  }
});

export const useCreateTaskForJob = () =>
  useExtendedMutation(addJobTasksToJobMutation, {
    serializer
  });

export default useCreateTaskForJob;
