import React, { useEffect } from 'react';

import { bool, func, string } from 'prop-types';

import { useTrigger } from 'customHooks/useTrigger';

import { VisitStatuses } from '@dispatch/Dispatch.constants';
import ToggleButton from '@dispatch/components/ToggleButton/ToggleButton.component';

import TabHeader from './components/TabHeader.component';
import VisitsTable from './components/VisitsTable';
import { statusTabs } from './VisitsTabs.constants';
import { useStyles } from './VisitsTabs.styles';

const VisitsTabs = ({ tab, openTab, refreshTableData, trayOpen }) => {
  const styles = useStyles();
  const visitStatus = VisitStatuses.get(tab)?.value?.serverValue;
  const tableName =
    VisitStatuses.get(tab)?.value?.displayValue || VisitStatuses.UNASSIGNED.value.displayValue;
  const [resetPagination, triggerResetPagination] = useTrigger();

  useEffect(() => {
    refreshTableData();
    triggerResetPagination();
  }, [tab]);

  return (
    <div css={styles.container}>
      <div css={styles.header}>
        <div css={styles.tabsContainer}>
          {statusTabs.map(Status => (
            <TabHeader
              active={Status.key === tab}
              key={Status.key}
              Status={Status}
              onClick={() => openTab(Status.key)}
            />
          ))}
        </div>
        <ToggleButton />
      </div>
      <div css={styles.content}>
        <VisitsTable
          visitStatus={visitStatus}
          tableName={tableName}
          resetPagination={resetPagination}
        />
      </div>
    </div>
  );
};

VisitsTabs.propTypes = {
  tab: string,
  openTab: func.isRequired,
  refreshTableData: bool.isRequired,
  trayOpen: bool.isRequired
};

VisitsTabs.defaultProps = {
  tab: VisitStatuses.UNASSIGNED.key
};

export default VisitsTabs;
