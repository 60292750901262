import { actions } from 'xstate';
import { payrollSettingFilter } from 'constants/common';
import { logErrorWithCallback } from 'utils';

const { raise } = actions;

const queryVisitDetails = async (context, event) => {
  const { technicianReportService, timesheetsService, companyService } = context.services;
  const { reportId, reportSortKey } = context;
  let newPromise = '';
  if (reportId) {
    newPromise = Promise.all([
      technicianReportService.getTechnicianReportById(reportId),
      companyService.getCompanySettings(
        context.user.tenantId,
        context.user.tenantCompanyId,
        payrollSettingFilter
      ),
      timesheetsService.getPayrollHourTypes(context.user.tenantId, context.user.tenantCompanyId)
    ]);
  } else {
    newPromise = Promise.all([
      technicianReportService.getTechnicianReport(context.user.tenantId, reportSortKey)
    ]);
  }

  return newPromise;
};

const createReviewReport = async (context, event) => {
  const { reviewReportService } = context.services;
  const data = {
    visitSortKey: context.reportSortKey
  };
  const promise = Promise.all([
    reviewReportService.createReviewReport(context.user.tenantId, data)
  ]);
  return promise;
};

const showReviewReport = (context, event) => {
  const { reviewReportId, history } = context;
  history.push({
    pathname: `/reviewreport/view/${reviewReportId}`
  });
};

const queryErrorNotification = (context, event) => {
  const { snackbarOn } = context.reduxActions;
  const error = event.data;
  logErrorWithCallback(error, snackbarOn, 'Unable to query data, please try again later');
};

const saveErrorNotification = (context, event) => {
  const { snackbarOn } = context.reduxActions;
  const error = event.data;
  logErrorWithCallback(error, snackbarOn, 'Unable to save data, please try again later');
};

const successMessage = (context, event) => {
  const { snackbarOn } = context.reduxActions;
  snackbarOn('success', 'Successfully created review report');
};

export const customActions = {
  fn_backToLoaded: raise('BACK_TO_LOADED'),
  fn_loadPageFromDataBase: () => raise('loading'),
  fn_fetchVisitDetails: queryVisitDetails,
  fn_createReviewReport: createReviewReport,
  fn_redirect_to_reviewReport: showReviewReport,
  fn_queryErrorNotification: queryErrorNotification,
  fn_saveErrorNotification: saveErrorNotification,
  fn_successMessage: successMessage
};

export const guards = {};

export default customActions;
