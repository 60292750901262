import { ElementSizes, ElementZIndex } from '@dispatch/Dispatch.styles';

export const useStyles = () => ({
  dropPreview: {
    position: 'absolute',
    height: ElementSizes.cellHeight,
    backgroundColor: 'white',
    zIndex: ElementZIndex.dropPreview
  },
  greenBorder: ({ palette }) => ({
    border: `solid ${palette.support.green.medium} 8px`,
    height: '100%'
  })
});
