import React, { useEffect } from 'react';
// eslint-disable-next-line no-unused-vars
import { jsx } from '@emotion/react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { Typography, TW, TV } from '@buildhero/sergeant';
import { CircularProgress } from '@material-ui/core';
import BuildOpsSvg from 'assets/Images/BuildOps.svg';
import styles from './ServiceChannel.style';
import { useAuthWithCode } from './ServiceChannel.api';

const ServiceChannelRedirect = ({ location }) => {
  const authenticate = useAuthWithCode();
  const history = useHistory();

  useEffect(() => {
    const code = queryString.parse(location.search)?.code;
    if (code) authenticate(code);
    else history.push('/settings/integrations');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={styles.redirect.container}>
      <img css={styles.redirect.logo} src={BuildOpsSvg} alt="BuildOps" />
      <div css={styles.redirect.center}>
        <CircularProgress size={80} css={styles.redirect.progress} />
        <Typography variant={TV.L} weight={TW.BOLD}>
          Connecting to Service Channel
        </Typography>
      </div>
    </div>
  );
};

export default ServiceChannelRedirect;
