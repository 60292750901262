import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const useStyles = makeStyles(() => ({
  root: {},
  title: {
    fontSize: 14,
    fontWeight: 700,
    color: '#333333',
    letterSpacing: '-0.03em',
    marginBottom: 15
  },
  listLabel: {
    display: 'flex'
  },
  labelText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#999999',
    minWidth: 410
  },
  fileItem: {
    display: 'flex',
    alignItems: 'center'
  },
  downloadable: {
    cursor: 'pointer'
  },
  fileCheck: {
    padding: 0,
    marginRight: 35
  },
  fileName: {
    minWidth: 350
  },
  filePdfIcon: {
    fontSize: 20,
    marginRight: 4
  },
  fileInformation: {
    fontSize: 14,
    fontWeight: 400,
    color: '#333333',
    letterSpacing: '-0.02em'
  },
  displayNone: {
    display: 'none'
  }
}));

const CustomFileLists = ({
  fileData,
  fileDataLength,
  selectable,
  titleText,
  downloadable,
  onSelect
}) => {
  const classes = useStyles();
  const [attachmentLists, setAttachmentLists] = useState([]);

  const handleSelected = item => {
    const currentLists = [...attachmentLists];
    currentLists[item.id].selected = !item.selected;
    setAttachmentLists(currentLists);

    // eslint-disable-next-line no-shadow
    const selectedLists = currentLists.filter(item => item.selected === true);
    onSelect(selectedLists);
  };

  useEffect(() => {
    const newLists = fileData.map((file, index) => {
      return {
        ...file,
        id: index,
        selected: file.selected
      };
    });
    setAttachmentLists(newLists);
  }, [fileData, fileDataLength]);

  const handleClickFile = item => {
    if (downloadable) {
      const aLink = document.createElement('a');
      aLink.target = '_blank';
      // eslint-disable-next-line no-useless-escape
      aLink.download =
        item.fileName || item.fileUrl?.split(/[\\\/]/).pop() || item.url?.split(/[\\\/]/).pop();
      aLink.href = item.fileUrl || item.url;
      aLink.click();
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classNames(classes.title, !titleText && classes.displayNone)}>
        {titleText} {`(${fileDataLength})`}
      </Box>
      <Box className={classNames(classes.listLabel, !titleText && classes.displayNone)}>
        <Box className={classes.labelText}>Name</Box>
        {/* Temporarily commented out */}
        {/* <Box className={classes.labelText}>Description</Box> */}
      </Box>
      <Box className={classes.fileListContainer}>
        {attachmentLists.map(item => (
          <Box
            className={classNames(classes.fileItem, downloadable && classes.downloadable)}
            key={item.id}
            onClick={() => handleClickFile(item)}
          >
            <Checkbox
              color="default"
              size="small"
              onChange={() => handleSelected(item)}
              checked={item.selected}
              className={classNames(classes.fileCheck, !selectable && classes.displayNone)}
            />
            <Box className={classNames(classes.fileItem, classes.fileName)}>
              <PictureAsPdfIcon className={classes.filePdfIcon} />
              <Box className={classes.fileInformation}>{item.fileName || item.name}</Box>
            </Box>
            <Box
              className={classNames(classes.fileInformation, !selectable && classes.displayNone)}
            >
              {item.description}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

CustomFileLists.propTypes = {
  fileData: PropTypes.array.isRequired,
  fileDataLength: PropTypes.number.isRequired,
  selectable: PropTypes.bool,
  titleText: PropTypes.string,
  downloadable: PropTypes.bool,
  onSelect: PropTypes.func
};
CustomFileLists.defaultProps = {
  titleText: '',
  selectable: false,
  downloadable: false,
  onSelect: () => {}
};
export default CustomFileLists;
