import gql from 'graphql-tag';

const updatePurchaseOrderReceipt = gql`
  mutation UpdatePurchaseOrderReceipt(
    $partitionKey: String
    $data: UpdatePurchaseOrderReceiptInput!
  ) {
    updatePurchaseOrderReceipt(partitionKey: $partitionKey, data: $data) {
      fileName
      imageUrl
      order
      parentId
      parentSortKey
      hierarchy
      id
      entityType
      version
      parentId
    }
  }
`;

export default updatePurchaseOrderReceipt;
