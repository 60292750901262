/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { sortBy } from 'lodash';
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getPurchaseOrderTags() {
  return await instance
    .get(`purchaseordertags`)
    .then(({ data }) => {
      return sortBy(data, 'sortOrder');
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function purchaseOrderTagSearch(term, searchColumns) {
  if (term.length > 0) {
    return await instance
      .post(`purchaseordertags/search/${term}`, { searchColumns: searchColumns || ['name'] })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  }
  return await getPurchaseOrderTags();
}
