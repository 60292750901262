import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  summaryContainer: {
    flex: 1,
    width: 'auto',
    justifyContent: 'flex-start'
  },
  summaryItem: {
    width: '75px',
    display: 'flex',
    textTransform: 'uppercase',
    marginLeft: theme.spacing(1.5),
    flexDirection: 'column',
    alignItems: 'center'
  },
  summaryType: {
    color: theme.palette.grayscale(51),
    fontSize: '10px'
  },
  summaryValue: {
    fontWeight: 600,
    fontSize: '20px',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    color: theme.palette.grayscale(10)
  },
  summaryInfoWrapper: {
    display: 'flex',
    marginBottom: theme.spacing(0.75),
    justifyContent: 'space-between'
  },
  calculatedSummary: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(1.5)
  },
  calculatedValue: {
    fontWeight: 600,
    color: theme.palette.grayscale(53),
    fontSize: '20px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  calculatedAbbreviation: {
    color: theme.palette.grayscale(53),
    fontSize: '10px'
  },
  timeInputRoot: {
    '&:hover': {
      background: theme.palette.grayscale(92)
    },
    '& input': {
      textAlign: 'center',
      textTransform: 'uppercase'
    },
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none'
    },
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '20px',
    color: theme.palette.grayscale(10)
  },
  time: {
    marginTop: theme.spacing(1)
  },
  timeInputWrapper: {
    minWidth: '65px',
    height: 'auto',
    marginTop: theme.spacing(1),
    maxWidth: '75px',
    paddingRight: 0
  },
  valueLabel: {
    width: '75px',
    fontWeight: 600,
    fontSize: '20px',
    textAlign: 'center',
    padding: theme.spacing(0.7),
    marginTop: theme.spacing(1),
    color: theme.palette.grayscale(10)
  }
}));
