import React from 'react';
import { Box } from '@material-ui/core';
import ErrorBoundaries from 'scenes/Error';
import PropTypes from 'prop-types';
import ResponsiveTable from 'components/ResponsiveTable';
import { documentHistoryMeta } from 'meta/Quotes/documentHistory';
import { Typography, TV, TW, ThemeProvider } from '@buildhero/sergeant';
import StorageService from 'services/StorageService';

export default function DocHistory({ quoteVersionsOptionData }) {
  const getEmailPdfs = () => {
    const emailsFromAllQuoteVersions = quoteVersionsOptionData.reduce(
      (acc, b) => [...acc, ...b.emails],
      []
    );

    const files = emailsFromAllQuoteVersions.map(email => {
      const pdf = email.attachments.items
        .map(attachment => {
          // Urls from pre-QuotesV1 need to be converted to a usable format in order to open
          if (attachment?.fileUrl.startsWith('https://bh-tenant-content')) {
            const regex = /(?:https:\/\/bh-tenant-content-(.*?).s3.amazonaws.com\/public\/)(.*?\.pdf)/;
            const match = attachment?.fileUrl.match(regex);
            if (match) {
              const storageService = new StorageService();
              const renamedFile = storageService.getFile(match[2]);
              return {
                ...attachment,
                fileUrl: renamedFile
              };
            }
          }
          return attachment;
        })
        .find(attachment => {
          if (attachment.comment) {
            return attachment.comment === 'main-document' && attachment.fileUrl.endsWith('.pdf');
          }
          return attachment.fileUrl.endsWith('.pdf');
        });

      return {
        createdDate: email.createdDate,
        fileName: pdf?.fileName,
        fileUrl: pdf?.fileUrl
      };
    });
    return files;
  };
  const pdfsToDisplay = getEmailPdfs();

  const customPDFLink = ({ record }) => (
    <a
      style={{ color: 'black', fontWeight: 'normal' }}
      href={record.fileUrl}
      target="_blank"
      rel="noreferrer"
    >
      {record.fileName}
    </a>
  );

  if (pdfsToDisplay.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: 300,
          border: '3px dashed #00874D'
        }}
      >
        <>
          <ThemeProvider>
            <Typography variant={TV.XL} weight={TW.BOLD}>
              Nothing here.
            </Typography>
            <Typography variant={TV.BASE}>Generated quotes will appear here.</Typography>
          </ThemeProvider>
        </>
      </div>
    );
  }

  return (
    <Box>
      <ErrorBoundaries>
        <ResponsiveTable
          fullScreen
          customCellComponents={{ customPDFLink }}
          disableFilter
          rowMetadata={documentHistoryMeta}
          data={pdfsToDisplay}
        />
      </ErrorBoundaries>
    </Box>
  );
}

DocHistory.propTypes = {
  quoteVersionsOptionData: PropTypes.array.isRequired
};
