import moment from 'moment';
import React from 'react';
import { array, func, object, string } from 'prop-types';
import { useFormService } from '@dispatch/hooks';
import EventBadge from '@dispatch/components/Drawer/components/EventBadge';
import { EventEntities, ManDayStatuses } from '@dispatch/Dispatch.constants';
import { useModalContext } from '@dispatch/hocs';
import { Typography, TV, TW } from '@buildhero/sergeant';
import DrawerLayout from '../DrawerLayout/DrawerLayout.container';
import ProjectDetailsHeader from '../ProjectDetailsHeader';
import ManDayEditForm from './components/ManDayEditForm';
import { getTimestampFromDateAndTime } from '../../Drawer.utils';

const prepareFormData = data => {
  if (!data) {
    return;
  }

  const startTime = moment.unix(data.startDateTime);
  const endTime = moment.unix(data.endDateTime);
  const date = data.startDateTime;
  return {
    ...data,
    startTime: startTime.isValid() ? startTime.format('HH:mm') : undefined,
    endTime: endTime.isValid() ? endTime.format('HH:mm') : undefined,
    date
  };
};

const deleteAllItems = ({ onConfirm, onCancel }) => ({
  children: 'Are you sure you want delete ALL related items?',
  buttons: {
    confirm: {
      buttonType: 'contained',
      color: 'primary',
      label: 'Confirm',
      action: onConfirm
    },
    cancel: {
      buttonType: 'outlined',
      label: 'Cancel',
      action: onCancel
    }
  }
});

const EditManDay = ({
  manDayId,
  closeDrawer,
  manDayDetailsResponse,
  updateManDayTuple,
  deleteManDayTuple,
  deleteManDayEventsGroupTuple,
  completeProjectVisitTuple
}) => {
  const { isDirty, onCreateService, onDirtyChange, resetForm, submitForm } = useFormService();
  const { loading: fetching, data } = manDayDetailsResponse;
  const [triggerUpdate, updateResponse] = updateManDayTuple;
  const [triggerDelete, deleteResponse] = deleteManDayTuple;
  const [triggerDeleteAll, deleteAllResponse] = deleteManDayEventsGroupTuple;
  const [triggerProjectVisitUpdate] = completeProjectVisitTuple;
  const { loading: updating } = updateResponse;
  const { loading: deleting } = deleteResponse;
  const { loading: deletingAll } = deleteAllResponse;
  const loading = fetching || updating || deleting || deletingAll;

  const isScheduled = data?.status === ManDayStatuses.SCHEDULED.value;

  const { openModal, closeModal } = useModalContext();

  const handleDelete = async () => {
    await triggerDelete({ id: manDayId });
    closeDrawer();
  };

  const handleDeleteAll = async () => {
    openModal(
      deleteAllItems({
        onConfirm: async () => {
          closeModal();
          await triggerDeleteAll({ id: manDayId });
          closeDrawer();
        },
        onCancel: () => {
          closeModal();
        }
      })
    );
  };

  const handleCompleteProjectVisit = async () => {
    await triggerProjectVisitUpdate({ id: manDayId });
    closeDrawer();
  };

  const handleComplete = formData => {
    const { startTime, endTime, date, ...fields } = formData;
    const startDateTime = getTimestampFromDateAndTime(
      moment
        .unix(date)
        .startOf('day')
        .unix(),
      startTime
    );
    const endDateTime = getTimestampFromDateAndTime(
      moment
        .unix(date)
        .startOf('day')
        .unix(),
      endTime
    );
    triggerUpdate({
      ...fields,
      startDateTime,
      endDateTime
    });
    closeDrawer();
  };

  const renderHeader = () => (
    <>
      <EventBadge eventType={EventEntities.MAN_DAY.value.clientValue} />
      <ProjectDetailsHeader loading={loading} project={data?.project} />
      <Typography variant={TV.S1} weight={TW.REGULAR}>
        Status: {data?.status}
      </Typography>
    </>
  );

  const renderBody = () => (
    <ManDayEditForm
      onDirtyChange={onDirtyChange}
      onComplete={handleComplete}
      onCreateService={onCreateService}
      data={prepareFormData(data)}
      disabled={loading || !isScheduled}
    />
  );

  return (
    <DrawerLayout
      renderHeader={renderHeader}
      renderBody={renderBody}
      onResetForm={resetForm}
      onSubmitForm={submitForm}
      isDirty={isDirty}
      loading={loading}
      disabledClose={loading}
      onDelete={handleDelete}
      onDeleteAll={handleDeleteAll}
      onCompleteProjectVisit={handleCompleteProjectVisit}
      disabledSave={loading || !isScheduled}
      disabledDelete={data?.status !== ManDayStatuses.SCHEDULED.value}
      disabledComplete={data?.status === ManDayStatuses.COMPLETE.value}
    />
  );
};

EditManDay.propTypes = {
  manDayId: string.isRequired,
  closeDrawer: func.isRequired,
  manDayDetailsResponse: object.isRequired,
  updateManDayTuple: array.isRequired,
  deleteManDayTuple: array.isRequired,
  deleteManDayEventsGroupTuple: array.isRequired,
  completeProjectVisitTuple: array.isRequired
};

export default EditManDay;
