import gql from 'graphql-tag';

const getTimesheetByVisitId = gql`
  query getTimesheetByVisitId($id: String!) {
    getVisitById(id: $id) {
      timesheetEntries: timesheetEntriesView(entityConnection: "TimesheetEntry") {
        items {
          hourTypeId
          actualTotalDuration
          actualTotalDurationOverride
          timekeepingLedger(entityConnection: "TimekeepingLedger") {
            id
            employeeId
            employeeName
            billableEntityType
          }
          timesheetPeriod {
            parentId
          }
        }
      }
    }
  }
`;

export default getTimesheetByVisitId;
