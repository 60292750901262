import { useMemo } from 'react';

import { useDrop } from 'react-dnd';

import { ItemTypes, selectDragScenario } from '@dispatch/dnd';

import { selectBoardVisits, selectTechVisits } from '../../DispatchBoard.selectors';

import { LOADING_TECHS } from './WeekView.constants';
import { selectTechEvents } from './WeekView.selectors';

export const useItemData = ({
  techsResponse,
  boardVisitsResponse,
  boardNonVisitEventsResponse,
  boardManDayItemsResponse,
  startDay,
  customWeekStartDay
}) => {
  return useMemo(() => {
    if (
      techsResponse.loading ||
      boardVisitsResponse.loading ||
      boardNonVisitEventsResponse.loading ||
      boardManDayItemsResponse.loading
    ) {
      return LOADING_TECHS;
    }

    const techVisits = selectTechVisits(boardVisitsResponse.data);

    const itemData = techsResponse.data.map(tech => {
      return {
        tech,
        techLoading: false,
        visitsLoading: boardVisitsResponse.loading || boardNonVisitEventsResponse.loading,
        techEvents: selectTechEvents(
          selectBoardVisits(techVisits[tech.id]),
          [
            ...(boardNonVisitEventsResponse.data?.[tech.id] || []),
            ...(boardManDayItemsResponse.data?.[tech.id] || [])
          ],
          startDay,
          customWeekStartDay
        )
      };
    });

    return [{}, ...itemData];
  }, [
    techsResponse,
    boardVisitsResponse,
    boardNonVisitEventsResponse,
    boardManDayItemsResponse,
    startDay,
    customWeekStartDay
  ]);
};

export const useVisitDrop = () => {
  const [collected, dropRef] = useDrop({
    accept: [ItemTypes.TABLE_VISIT, ItemTypes.BOARD_VISIT],
    collect: monitor => ({
      isOver: monitor.isOver(),
      dragScenario: selectDragScenario(monitor.getItem()),
      primaryTech: monitor.getItem()?.data?.primaryTechId,
      extraTechs: monitor.getItem()?.data?.extraTechs
    })
  });

  return [collected, dropRef];
};
