const exportLayout = () => ({
  fields: {
    imageUrl: {
      default: 'imageUrl',
      edit: 'imageUrl'
    }
  },
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        {
          options: {
            direction: 'column',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'DatePicker'
                  },
                  source: 'invoiceDateRange',
                  options: {
                    label: 'Date Range',
                    placeholder: '',
                    fullWidth: true,
                    alignment: 'left'
                  }
                }
              ]
            },
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: 12
              },
              contents: [
                {
                  component: {
                    default: 'ExportType'
                  },
                  source: 'type',
                  options: {
                    label: 'Export Type',
                    placeholder: '',
                    isRequired: true
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  }
});

export default exportLayout;
