import React, { Component } from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import { Logger } from 'services/Logger';
import StorageService from 'services/StorageService';

/**
 * Renders an Image in the screen
 */
class ImageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s3ImageUrl: ''
    };
  }

  componentWillMount = async () => {
    await this.getImage();
  };

  componentDidUpdate = async prevProps => {
    if (this.props.image.fileUrl !== prevProps.image.fileUrl) {
      await this.getImage();
    }
  };

  getImage = async () => {
    const imageKey = this.props.image.fileUrl;
    let s3ImageUrl;

    try {
      const storageService = new StorageService();
      s3ImageUrl = await storageService.getFile(imageKey);
      this.setState({ s3ImageUrl });
    } catch (error) {
      Logger.info(`Error uploading image ${error}`);
    }
  };

  render() {
    const { image } = this.props;
    const { s3ImageUrl } = this.state;

    return (
      <>
        {s3ImageUrl && (
          <CardMedia
            component="img"
            alt={image.name}
            style={{ height: 60, width: 60 }}
            image={s3ImageUrl}
            title={image.name}
          />
        )}
      </>
    );
  }
}

export default ImageComponent;
