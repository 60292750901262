import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from '@dispatch/components/VisitsTabs/VisitsTabs.styles';

const JobNumber = ({ record }) => {
  const classes = useStyles();
  return <div className={classes.TableCell}>{record?.jobCustomIdentifier || record?.jobNo}</div>;
};

JobNumber.propTypes = {
  record: PropTypes.object.isRequired
};

export default memo(JobNumber);
