const styles = theme => ({
  inputRoot: {
    color: 'inherit',
    // width: '100%',
    marginBottom: theme.spacing(2.5)
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(),
    transition: theme.transitions.create('width'),
    // width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  },
  iconButton: {
    color: '#3f3f3f',
    fontSize: 20
  },
  results: {
    padding: 5,
    borderBottom: '1px solid rgba(189, 189, 189, 0.3)'
  },
  resultsText: {
    paddingLeft: '10px !important'
  }
});
export default styles;
