import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Tabs, Tab, Grid, Box, Typography, Divider } from '@material-ui/core/';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { checkPermission } from 'utils';
import ErrorBoundaries from 'scenes/Error';
import PropTypes from 'prop-types';
import { ButtonType } from '@buildhero/sergeant';
import { useConfirmModal } from 'customHooks/ConfirmModalContext';
import styles from './styles';
import IconElement from './IconElement';

function BHTab(props) {
  return (
    <Box display="flex" flex={1} flexDirection="column">
      {props.children}
    </Box>
  );
}

const switchConfirmContent = {
  body: 'You have unsaved work, are you sure you want to switch tabs?',
  title: 'Switch Tab',
  buttonLabel: 'Switch Tab',
  cancelButtonLabel: 'Cancel'
};

// Automatically injects error boundaries around the detail of each tab so you don't have to!
const useStyles = makeStyles(styles);
function BHTabs(props) {
  const launchDarklyFlags = useFlags();
  const [currentTab, setCurrentTab] = useState(0);
  const confirmContext = useConfirmModal();

  const handleChange = async (_, newValue) => {
    if (!props.confirmSwitch || (await confirmContext.confirm(switchConfirmContent))) {
      if (props.onChange) props.onChange(_, newValue);
      // if selectedTab prop exist, control the current tab being selected
      if (props.selectedTab) {
        setCurrentTab(props.selectedTab.current);
      } else {
        setCurrentTab(newValue);
      }
    }
  };

  const { children, disableBottomPadding, user, showDivider } = props;
  const classes = useStyles({ disableBottomPadding });
  const theme = useTheme();

  const singleChild = React.isValidElement(children) ? [children] : [];
  const adaptedChildren = Array.isArray(children) ? children : singleChild;
  // Checking user permission. Label is also checked to support conditional rendering of tabs. For e.g.: Accounting
  const childrenWithPermission = adaptedChildren.filter(
    item =>
      item &&
      checkPermission(
        'read',
        item.props?.caslKey,
        user,
        null,
        item.props?.featureGate,
        item.props?.featureFlag,
        launchDarklyFlags
      ) &&
      item?.props?.label
  );

  if (childrenWithPermission.length === 0) return null;

  return (
    <>
      <div className={classes.root}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          TabIndicatorProps={{ className: classes.tabIndicator }}
          TabScrollButtonProps={{ className: classes.scrollButton }}
          variant="scrollable"
          scrollButtons="auto"
          style={disableBottomPadding ? { marginBottom: 0, minHeight: 36 } : { minHeight: 36 }}
        >
          {childrenWithPermission.map((item, index) => {
            const { icon, label, tabKey } = item.props || {};
            const isActiveTab = index === currentTab;
            return (
              <Tab
                disableRipple
                component="a"
                onClick={e => e.preventDefault()}
                label={
                  <Grid container direction="row" justify="flex-start" alignItems="flex-end">
                    <Grid item>
                      <Typography
                        className={`${classes.tabHeading} ${isActiveTab &&
                          classes.selectedTabHeading}`}
                      >
                        {label}
                      </Typography>
                    </Grid>
                    {icon && (
                      <Grid item className={classes.iconContainer}>
                        <IconElement
                          icon={icon}
                          style={{
                            color: isActiveTab
                              ? theme.palette.grayscale(20)
                              : theme.palette.grayscale(80)
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                }
                href={tabKey}
                className={classes.tab}
                key={`linkTab-${tabKey || label}`}
                testingid={label}
              />
            );
          })}
        </Tabs>
        {showDivider && <Divider />}
      </div>
      <ErrorBoundaries>
        {/** In the tab rendering cycles some where the children (item) is coming undefined and gives wierd errors. Hence having the check. If you are making change check Dashboard page */}
        {childrenWithPermission?.map((item, index) => currentTab === index && item)}
      </ErrorBoundaries>
    </>
  );
}

export { BHTab as Tab };

const mapStateToProps = state => ({
  user: state.user
});
const ReduxTabs = connect(mapStateToProps)(BHTabs);
export { ReduxTabs as Tabs };

BHTabs.propTypes = {
  onChange: PropTypes.func,
  selectedTab: PropTypes.object,
  user: PropTypes.object,
  disableBottomPadding: PropTypes.bool,
  showDivider: PropTypes.bool
};

BHTabs.defaultProps = {
  onChange: null,
  selectedTab: null,
  user: {},
  disableBottomPadding: false,
  showDivider: false
};
