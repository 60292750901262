/* eslint-disable no-console */
/* eslint-disable no-return-await */
import { axiosInstancePMAPI as instance } from 'configs/http-client';

export async function getCustomerProperties() {
  return await instance
    .get(`customerproperty`, {
      params: { include: ['companyAddresses'] }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function getCustomerPropertiesByCustomer(
  customerId,
  term = null,
  searchColumn = 'companyName'
) {
  return await instance
    .get(`customerproperty/bycustomer/${customerId}`, {
      params: { include: ['companyAddresses'] }
    })
    .then(({ data }) => {
      if (!term) return data;
      return data.filter(
        element =>
          typeof element[searchColumn] === 'string' &&
          element[searchColumn].toUpperCase().indexOf(term.toUpperCase()) >= 0
      );
    })
    .catch(err => {
      console.log(err);
      return [];
    });
}

export async function customerPropertySearch(term, searchColumns) {
  if (term.length > 0) {
    return await instance
      .post(`customerproperty/search/${term}`, {
        params: { include: ['companyAddresses'] },
        customerId: selectedCustomer?.id,
        searchColumns: searchColumns || ['companyName']
      })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
  }
  return await getCustomerProperties(selectedCustomer);
}
