import gql from 'graphql-tag';

export const getJobCostingReport = gql`
  query getJobCostingReport($partitionKey: String!, $jobId: String!) {
    getJobCostingReport(partitionKey: $partitionKey, jobId: $jobId) {
      costCodeId
      costCode
      jobCostTypeId
      revenueTypeId
      revenueType
      jobCostType
      cost
      revenue
      profit
      grossMargin
    }
  }
`;

export const getLaborCostForJob = gql`
  query getLabourCostForJob($partitionKey: String!, $jobId: String!) {
    getLabourCostForJob(partitionKey: $partitionKey, jobId: $jobId) {
      employeeId
      name
      labourTypeId
      labourTypeName
      visitId
      hourTypeId
      hourTypeAbbreviation
      hourCost
      totalCost
      duration
    }
  }
`;

export default getJobCostingReport;
