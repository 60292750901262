import React, { useMemo } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Checkbox, MoreButton } from '@buildhero/sergeant';
import { calculateMarginFromMarkup } from '@buildhero/math';

import { tableEmptyValueFormatter, tablePercentageFormatter } from 'components/WrapTable';
import { convertForMathLib } from 'utils/mathLibrary';

import { useReviewReportDisabled } from '../../../ReviewReport/ReviewReport.contexts';
import { tableCurrencyFormatterWithZero } from 'components/WrapTable/valueFormatters/tableCurrencyFormatterWithZero';

export const useInventoryItemsColumns = ({
  isReviewReport,
  handleEditClick,
  handleDeleteClick,
  handleInvoiceToggle,
  marginEnabled
}) => {
  const { disabled } = useReviewReportDisabled();

  return useMemo(
    () => [
      {
        field: 'id',
        hide: true
      },
      {
        field: 'version',
        hide: true
      },
      {
        field: 'includeInInvoice',
        flex: 1,
        maxWidth: 41,
        hide: !isReviewReport,
        headerName: '',
        noPadding: true,
        align: 'center',
        justify: 'center',
        renderCell: ({ value, row }) => (
          <Checkbox
            css={{ marginTop: 0 }}
            checked={value}
            onClick={() => handleInvoiceToggle({ value, row })}
            disabled={disabled || row.id.includes('optimistic')}
          />
        )
      },
      {
        field: 'name',
        flex: 1,
        headerName: 'Name',
        valueFormatter: tableEmptyValueFormatter
      },
      {
        field: 'description',
        flex: 1,
        headerName: 'Description',
        valueFormatter: tableEmptyValueFormatter
      },
      {
        field: 'quantity',
        flex: 1,
        maxWidth: 50,
        noWrap: true,
        headerName: 'Qty',
        valueFormatter: tableEmptyValueFormatter
      },
      {
        field: 'unitOfMeasure',
        flex: 1,
        maxWidth: 64,
        noWrap: true,
        headerName: 'UOM',
        valueFormatter: tableEmptyValueFormatter
      },
      {
        field: 'unitCost',
        flex: 1,
        maxWidth: 100,
        headerName: 'Unit cost',
        valueFormatter: tableCurrencyFormatterWithZero
      },
      {
        field: 'markup',
        flex: 1,
        maxWidth: 72,
        headerName: marginEnabled ? 'Margin' : 'Markup',
        valueFormatter: tablePercentageFormatter,
        valueGetter: ({ row }) =>
          marginEnabled ? convertForMathLib(calculateMarginFromMarkup, row.markup) : row.markup
      },
      {
        field: 'unitPrice',
        flex: 1,
        maxWidth: 100,
        headerName: 'Unit price',
        valueFormatter: tableCurrencyFormatterWithZero
      },
      {
        field: 'costCode',
        flex: 1,
        headerName: 'Cost code',
        valueFormatter: tableEmptyValueFormatter
      },
      {
        field: 'costType',
        flex: 1,
        headerName: 'Cost type',
        valueFormatter: tableEmptyValueFormatter
      },
      {
        field: 'revenueType',
        flex: 1,
        headerName: 'Revenue type',
        valueFormatter: tableEmptyValueFormatter
      },
      {
        field: 'amount',
        flex: 1,
        maxWidth: 100,
        headerName: 'Amount',
        valueFormatter: tableCurrencyFormatterWithZero
      },
      {
        field: 'addedBy',
        flex: 1,
        headerName: 'Added by',
        valueFormatter: tableEmptyValueFormatter
      },
      {
        field: 'actions',
        flex: 1,
        maxWidth: 41,
        hide: !isReviewReport,
        headerName: '',
        noPadding: true,
        align: 'center',
        justify: 'center',
        renderCell: ({ row }) => (
          <MoreButton
            options={[
              {
                label: 'Edit',
                icon: EditIcon,
                disabled: disabled || row.id.includes('optimistic'),
                onClick: () => handleEditClick(row.id)
              },
              {
                label: 'Delete',
                icon: DeleteIcon,
                disabled: disabled || row.id.includes('optimistic'),
                onClick: () => handleDeleteClick(row.id)
              }
            ]}
          />
        )
      }
    ],
    [handleInvoiceToggle, handleDeleteClick, handleEditClick, isReviewReport]
  );
};
