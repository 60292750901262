/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ResponsiveTable from 'components/ResponsiveTable';
import { Mode, FormVersion } from 'utils/constants';
import Context from 'components/Context';
import Labels from 'meta/labels';
import { SectionHeader, DefaultButton, SergeantModal } from 'components';
import DisplayData from 'components/ResponsiveTable/DisplayData';
import { communicationRowsMeta, communicationForm } from 'meta/Settings/Company/Communications';
import ErrorBoundaries from 'scenes/Error';
import { snackbarOn } from 'redux/actions/globalActions';
import { PermissionConstants } from 'utils/AppConstants';
import { Typography, Box } from '@material-ui/core';
import {
  getCompanyExternalMessages,
  getCompanyDepartments,
  addExternalMessagesToCompany,
  updateExternalMessage,
  deleteExternalMessage
} from './service';
import { Logger } from '../../../../services/Logger';

const TYPES = {
  invoice: {
    en: 'Invoice'
  },
  quote: {
    en: 'Quote'
  },
  purchaseorder: {
    en: 'Purchase Order'
  }
};
class CommunicationSection extends Component {
  constructor(props) {
    super(props);
    this.mounted = props.mounted;
    const initialFormData = { name: '', type: '', departmentIds: '', message: '' };
    const communicationTypes = [
      {
        label: 'Invoice',
        value: 'Invoice'
      },
      {
        label: 'Quote',
        value: 'Quote'
      },
      {
        label: 'Purchase Order',
        value: 'PurchaseOrder'
      }
    ];
    this.departmentNames = new Map();
    this.sectionHeaderButton = [
      <DefaultButton
        handle={() => this.handleRowActions(Mode.ADD, initialFormData)}
        label="Add External Message Template"
      />
    ];
    this.state = {
      refreshCounter: 0,
      communicationsList: [],
      communicationTypes,
      departmentOptions: [],
      hasFetched: false,
      modal: {
        open: false,
        data: null,
        dataType: 'External Message',
        mode: null,
        handlePrimaryAction: () => console.log('no primary action set'),
        deleteItemLabel: '',
        formVersion: ''
      }
    };
  }

  componentDidMount = () => {
    this.getAllDepartmentOptions();
    this.getAllCommunications();
  };

  componentDidUpdate = prevProps => {
    if (prevProps.departmentsUpdatedCount !== this.props.departmentsUpdatedCount) {
      this.getAllDepartmentOptions(true);
    }
  };

  getAllDepartmentOptions = async (alwaysfetch = false) => {
    let departments;
    if (Context.getCompanyContext() && alwaysfetch === false) {
      departments = this.getDepartmentsFromContext();
    } else {
      departments = await this.fetchAllDepartments();
    }
    const departmentOptions = this.processDepartmentOptions(departments);
    this.setState({ departmentOptions });
  };

  processDepartmentOptions = departments => {
    this.departmentNames.clear();
    return departments.map(({ tagName, id }) => {
      this.departmentNames.set(id, tagName);
      return {
        label: tagName,
        value: id
      };
    });
  };

  getDepartmentsFromContext = () => {
    const company = Context.getCompanyContext();
    return company?.getCompany?.departments?.items || [];
  };

  fetchAllDepartments = async () => {
    const { user } = this.props;
    const sortKey = `${user.tenantId}_Company_${user.tenantCompanyId}`;
    try {
      const { data } = await getCompanyDepartments(user.tenantId, sortKey);
      if (data?.getCompany) {
        return data.getCompany.departments?.items || [];
      }
    } catch (error) {
      this.props.snackbarOn(
        'error',
        `Unable to fetch the company's department options, please try again later`,
        error
      );
    }
  };

  getAllCommunications = async () => {
    const { user } = this.props;
    const sortKey = `${user.tenantId}_Company_${user.tenantCompanyId}`;
    if (!user.tenantId) return;
    try {
      const { data } = await getCompanyExternalMessages(user.tenantId, sortKey);
      if (data?.getCompany) {
        const { externalMessages } = data.getCompany;
        const communicationsList = externalMessages?.items?.map(el => ({
          departmentIds:
            el.externalMessageDepartments?.items?.map(item => item.department.id) || [],
          id: el.id,
          name: el.name,
          type: el.type,
          typeName: TYPES[el.type?.toLowerCase()][user.locale],
          message: el.message,
          version: el.version
        }));
        this.setState({ communicationsList });
      }
    } catch (error) {
      this.props.snackbarOn(
        'error',
        `Unable to fetch the company's external messages, please try again later`,
        error
      );
    } finally {
      this.setState({
        hasFetched: true
      });
    }
  };

  handleRowActions = (actionName, record) => {
    const { modal } = this.state;
    const updatedModal = { ...modal };
    updatedModal.open = true;
    updatedModal.data = record;
    updatedModal.mode = actionName;
    updatedModal.deleteItemLabel = `'${record.name}'`;

    switch (actionName) {
      case Mode.VIEW: {
        updatedModal.formVersion = FormVersion.DEFAULT;
        break;
      }
      case Mode.EDIT: {
        updatedModal.handlePrimaryAction = this.handleEditAction;
        updatedModal.formVersion = FormVersion.EDIT;
        break;
      }
      case Mode.DELETE: {
        updatedModal.handlePrimaryAction = this.handleDeleteAction;
        break;
      }
      default: {
        updatedModal.handlePrimaryAction = this.handleAddAction;
        updatedModal.formVersion = FormVersion.EDIT;
      }
    }
    this.setState({ modal: updatedModal });
  };

  processAddExternalMessageData = record => {
    const { name, departmentIds, message, type } = record;
    const { tenantCompanyId } = this.props.user;
    const externalMessageDepartments = departmentIds.map(departmentId => ({ departmentId }));
    return {
      companyId: tenantCompanyId,
      externalMessages: [
        {
          externalMessageDepartments,
          name,
          message,
          type
        }
      ]
    };
  };

  handleAddAction = async (record, stopLoadingModal) => {
    const { communicationsList } = this.state;
    const { tenantId } = this.props.user;
    if (record?.name) {
      const processedRecord = this.processAddExternalMessageData(record);
      try {
        const { data } = await addExternalMessagesToCompany(tenantId, processedRecord);
        if (data) {
          this.props.snackbarOn(
            'success',
            `Successfully added external message: ${record.tagName || ''}`
          );
          const { id, version } = data.addExternalMessagesToCompany[0];
          const updatedList = [...communicationsList];
          const newRecord = record;
          newRecord.typeName = TYPES[record.type?.toLowerCase()][this.props.user.locale];
          newRecord.id = id;
          newRecord.version = version;
          updatedList.unshift(newRecord);
          this.setState({ communicationsList: updatedList });
        }
      } catch (error) {
        Logger.debug(error);
        this.props.snackbarOn(
          'error',
          'Unable to add external message, please try again later',
          error
        );
      }
    }
    stopLoadingModal();
    this.handleModalClose();
  };

  processEditExternalMessageData = record => {
    const { name, departmentIds, message, type, id, version } = record;
    const externalMessageDepartments = departmentIds.map(departmentId => ({ departmentId }));
    return {
      externalMessageDepartments,
      name,
      message,
      type,
      id,
      version
    };
  };

  handleEditAction = async (record, stopLoadingModal) => {
    const { communicationsList } = this.state;
    const { tenantId } = this.props.user;
    if (record?.name) {
      const processedRecord = this.processEditExternalMessageData(record);
      try {
        const { data } = await updateExternalMessage(tenantId, processedRecord);
        if (data) {
          this.props.snackbarOn(
            'success',
            `Successfully updated the external message: ${record.name || ''}`
          );
          const { version } = data.updateExternalMessage;
          const updatedList = [...communicationsList];
          const indexOfItem = updatedList.findIndex(i => i.id === record.id);
          const newRecord = record;
          newRecord.typeName = TYPES[record.type?.toLowerCase()][this.props.user.locale];
          newRecord.version = version;
          updatedList[indexOfItem] = newRecord;
          this.setState({ communicationsList: updatedList });
        }
      } catch (error) {
        Logger.debug(error);
        this.props.snackbarOn(
          'error',
          'Unable to update external message, please try again later',
          error
        );
      }
      stopLoadingModal();
      this.handleModalClose();
    }
  };

  handleDeleteAction = async (record, stopLoadingModal) => {
    const { communicationsList } = this.state;
    if (record?.id) {
      const { id } = record;
      const { tenantId } = this.props.user;
      try {
        const { data } = await deleteExternalMessage(tenantId, id);
        if (data) {
          this.props.snackbarOn(
            'success',
            `Successfully deleted the external message: ${record.name || ''}`
          );
          const updatedList = [...communicationsList];
          const indexOfItem = updatedList.findIndex(i => i.id === id);
          updatedList.splice(indexOfItem, 1);
          this.setState({ communicationsList: updatedList });
        }
      } catch (error) {
        Logger.debug(error);
        this.props.snackbarOn(
          'error',
          'Unable to delete the external message, please try again later',
          error
        );
      }
    }
    stopLoadingModal();
    this.handleModalClose();
  };

  handleModalClose = () => {
    const { modal } = this.state;
    const updatedModal = { ...modal };
    updatedModal.open = false;
    this.setState({ modal: updatedModal });
  };

  getDepartmentNamesFromMap = ids => {
    let departmentNames = '';
    ids.forEach(id => {
      const punctuation = departmentNames.length ? ', ' : '';
      const departmentName = this.departmentNames.get(id);
      departmentNames += departmentName ? `${punctuation}${this.departmentNames.get(id)}` : '';
    });
    return departmentNames;
  };

  // Overrides for cells so the values use mapped versions (types) or a formatted
  // concat string (departments).
  getCustomCellComponents = () => {
    const CommunicationType = ({ meta, record, ...rest }) => {
      const { user } = this.props;
      const updatedMeta = { ...meta, type: 'enum', isCustom: false };
      const updatedRecord = {
        ...record,
        type: TYPES[record?.type?.toLowerCase()][user.locale]
      };
      return <DisplayData meta={updatedMeta} record={updatedRecord} {...rest} />;
    };
    const CommunicationDepartments = ({ meta, record, ...rest }) => {
      const updatedMeta = { ...meta, type: 'text', isCustom: false };
      const updatedRecord = {
        ...record,
        departmentIds: this.getDepartmentNamesFromMap(record.departmentIds)
      };
      return <DisplayData meta={updatedMeta} record={updatedRecord} {...rest} />;
    };

    return {
      CommunicationType,
      CommunicationDepartments
    };
  };

  setCustomControls = () => {
    const SelectInputView = ({ options, displayValue }) => {
      const labelClass = {
        fontFamily: 'Inter',
        fontSize: '10px',
        color: '#333333',
        textTransform: 'uppercase',
        fontWeight: 'normal'
      };
      const { valueSet } = options;
      const selectedValueLabels = valueSet.reduce((acc, { value, label }) => {
        if (displayValue.includes(value)) {
          acc.push(label);
        }
        return acc;
      }, []);
      return (
        <Box display="flex" flexDirection="column">
          <Typography style={labelClass} variant="caption">
            {options.label}
          </Typography>
          <Typography variant="body1">{selectedValueLabels.join(', ') || '-'}</Typography>
        </Box>
      );
    };
    return {
      SelectInputView
    };
  };

  render() {
    const { user } = this.props;
    const {
      communicationsList,
      refreshCounter,
      modal,
      communicationTypes,
      departmentOptions,
      hasFetched
    } = this.state;

    return (
      <ErrorBoundaries>
        <SectionHeader
          title={Labels.Communications[user.locale]}
          overrideHeaderButtons={this.sectionHeaderButton}
        />
        <ResponsiveTable
          isLoading={!hasFetched}
          rowMetadata={communicationRowsMeta}
          data={communicationsList}
          noDataMsg="No External Messages"
          caslKey={PermissionConstants.OBJECT_INVOICE}
          key={refreshCounter}
          rowActions={this.handleRowActions}
          customCellComponents={this.getCustomCellComponents()}
          rowActionButtons={{
            view: {
              label: 'View',
              icon: 'Launch'
            },
            edit: {
              label: 'Edit',
              icon: 'EditOutlined'
            },
            delete: {
              label: 'Delete',
              icon: 'DeleteOutlined'
            }
          }}
          showToolbars
          disableFilter
        />
        <SergeantModal
          open={modal.open}
          layout={communicationForm(communicationTypes, departmentOptions)}
          data={modal.data}
          dataType={modal.dataType}
          mode={modal.mode}
          customComponents={this.setCustomControls()}
          confirmRemoveItemLabel={modal.deleteItemLabel}
          handlePrimaryAction={modal.handlePrimaryAction}
          handleClose={this.handleModalClose}
          formVersion={modal.formVersion}
        />
      </ErrorBoundaries>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatcherToProps = { snackbarOn };
const reduxConnectedCommunications = connect(
  mapStateToProps,
  mapDispatcherToProps
)(CommunicationSection);

export default reduxConnectedCommunications;
