import React, { useCallback, useEffect, useMemo, useState } from 'react';

import every from 'lodash/every';
import { object } from 'prop-types';
import { pipe } from 'ramda';
import { useSelector } from 'react-redux';
import uuid from 'uuid';

import { ThemeProvider, Typography } from '@buildhero/sergeant';
import { TablePlaceholderVariant } from 'components/WrapTable/WrapTable.constants';
import WrapTable from 'components/WrapTable/WrapTable.container';
import withLazyMount from 'utils/withLazyMount';

import { convertToCurrencyString } from '../../../../../../../utils';
import { defaultHourRateId } from '../../../../ReviewReport/LabourLineItems/constants';
import {
  selectDepartmentId,
  selectDepartmentName,
  selectJobPriceBookId,
  selectLaborLineItems,
  selectReviewReportPriceBookId
} from '../../../../selectors';
import { useReviewReportDisabled } from '../../ReviewReport.contexts';
import { reviewReportDefaultProps, reviewReportPropTypes } from '../../reviewReportPropTypes';

import AddLineItemButton from './components/AddLineItemButton';
import PriceBookSelect from './components/PriceBookSelect';
import { useLaborItemColumns } from './ReviewReportLaborLineItems.columns';
import {
  flattenBillingHours,
  patchLineItemDepartment,
  patchLineItemHourRates,
  patchLineItemHoursField,
  patchLineItemLaborType,
  patchLineItemTechnician
} from './ReviewReportLaborLineItems.helpers';
import { useMissingVisitTechnicians } from './ReviewReportLaborLineItems.hooks';
import {
  useDeleteLaborLineItemMutation,
  useUpdateLaborItemsMutation,
  useUpdatePriceBookMutation
} from './ReviewReportLaborLineItems.mutations';
import {
  useCompanyEmployees,
  useReportBillingHourTypes,
  useReportLaborRates,
  useReportLaborTypes,
  useReportPriceBooks
} from './ReviewReportLaborLineItems.queries';
import { useStyles } from './ReviewReportLaborLineItems.styles';

const ReviewReportLaborLineItems = ({
  visit,
  job,
  reviewReport,
  loading: loadingReport,
  error
}) => {
  const title = 'Labor Line Items';
  const noDataMessage = 'No Labor Line Items';
  const [laborLineItems, setLaborLineItems] = useState([]);
  const [isDirtyLineItems, setIsDirtyLineItems] = useState(false);

  const canSave = useMemo(
    () =>
      isDirtyLineItems &&
      every(
        laborLineItems,
        lineItem => Boolean(lineItem?.employeeId) && Boolean(lineItem?.labourTypeId)
      ),
    [isDirtyLineItems, laborLineItems]
  );

  const missingVisitTechnicians = useMissingVisitTechnicians({ laborLineItems, visit });

  const { disabled } = useReviewReportDisabled();
  const styles = useStyles();
  const defaultPriceBookId = useSelector(state => state?.company?.defaultPriceBookId);
  const tenantId = useSelector(state => state?.user?.tenantId);
  const visitDepartmentName = selectDepartmentName(visit) || '-';
  const visitDepartmentId = selectDepartmentId(visit) || undefined;
  const priceBookId =
    selectReviewReportPriceBookId(reviewReport) || selectJobPriceBookId(job) || defaultPriceBookId;

  useEffect(() => {
    const lineItems = selectLaborLineItems(reviewReport) || [];
    const lineItemsWithDefaultDepartment = lineItems.map(lineItem => ({
      ...lineItem,
      departmentId: lineItem.departmentId ?? visitDepartmentId
    }));
    setLaborLineItems(lineItemsWithDefaultDepartment);
  }, [reviewReport, visitDepartmentId]);

  const { data: allLaborRates, loading: loadingLaborRates } = useReportLaborRates(priceBookId);
  const { data: laborTypes, loading: loadingLaborTypes } = useReportLaborTypes();
  const { data: hourTypes, loading: loadingBillingHourTypes } = useReportBillingHourTypes();
  const { data: priceBooks, loading: loadingPriceBooks } = useReportPriceBooks();
  const priceBooksWithTechniciansDefault = [
    ...(priceBooks ?? []),
    { id: defaultHourRateId, name: '(Default) Technician Labor Rate Sheet' }
  ];

  const isTechnicianDefaultRates = priceBookId === defaultHourRateId;

  const {
    data: employees,
    dataMap: employeeMap,
    loading: loadingEmployees
  } = useCompanyEmployees();
  const [updatePriceBookMutation, { loading: updatingPriceBook }] = useUpdatePriceBookMutation();
  const [updateLaborLineItems, { loading: updatingLaborItems }] = useUpdateLaborItemsMutation(
    reviewReport.id
  );
  const [
    deleteLaborLineItemMutation,
    { loading: deletingLaborLineItem }
  ] = useDeleteLaborLineItemMutation(reviewReport?.id);

  const laborRates = useMemo(
    () => allLaborRates?.filter(rate => rate?.parentId === priceBookId) || [],
    [priceBookId, allLaborRates]
  );

  const loading =
    loadingReport ||
    loadingEmployees ||
    loadingLaborRates ||
    loadingLaborTypes ||
    loadingPriceBooks ||
    loadingBillingHourTypes ||
    updatingPriceBook ||
    updatingLaborItems ||
    deletingLaborLineItem;

  const save = useCallback(async () => {
    if (canSave && isDirtyLineItems && !disabled && !loading) {
      setIsDirtyLineItems(false);
      await updateLaborLineItems({ tenantId, reviewReportId: reviewReport.id, laborLineItems });
    }
  }, [
    canSave,
    isDirtyLineItems,
    disabled,
    loading,
    updateLaborLineItems,
    setIsDirtyLineItems,
    tenantId,
    reviewReport.id,
    laborLineItems
  ]);

  useEffect(() => {
    save();
  }, [isDirtyLineItems, save]);

  const handlePriceBookChange = useCallback(
    async value => {
      await updatePriceBookMutation({
        tenantId,
        priceBookId: value,
        reviewReportId: reviewReport?.id,
        version: reviewReport?.version
      });
      const newPriceBookLaborRates =
        allLaborRates?.filter(laborRate => laborRate?.parentId === value) || [];
      const updatedLaborLineItems = laborLineItems
        .map(lineItem => {
          const { id: lineItemId, labourTypeId: laborTypeId } = lineItem || {};
          return pipe(
            patchLineItemHourRates({
              lineItemId,
              laborTypeId,
              laborRates: newPriceBookLaborRates,
              employeeMap,
              isTechnicianDefaultRates: value === defaultHourRateId
            })
          )([lineItem]);
        })
        .flat();
      setLaborLineItems(updatedLaborLineItems);
      if (updatedLaborLineItems.length) {
        setIsDirtyLineItems(true);
      }
    },
    [
      updatePriceBookMutation,
      allLaborRates,
      laborLineItems,
      reviewReport.id,
      reviewReport.version,
      tenantId,
      employeeMap
    ]
  );

  const handleDeleteLaborLineItemDelete = useCallback(
    async row => {
      if (row?.createdDate) {
        const lineItemId = row.id;
        await deleteLaborLineItemMutation({ tenantId, lineItemId });
      } else {
        setLaborLineItems(laborLineItems.filter(lineItem => lineItem?.id !== row?.id));
      }
    },
    [deleteLaborLineItemMutation, tenantId, laborLineItems]
  );

  const handleAddLaborLineItem = useCallback(() => {
    setLaborLineItems([
      ...laborLineItems,
      {
        id: uuid.v4(),
        labourRateBillingHourLines: {
          items: hourTypes?.map(hourType => ({ hourTypeId: hourType?.id } || []))
        }
      }
    ]);
  }, [laborLineItems, hourTypes]);

  const handleAddLaborLineItemsForTechnicians = useCallback(() => {
    const missingLineItems = missingVisitTechnicians.map(id => ({
      id: uuid.v4(),
      employeeId: id,
      labourTypeId: employeeMap[id]?.labourTypeId,
      labourRateBillingHourLines: {
        items: hourTypes?.map(hourType => ({ hourTypeId: hourType?.id } || []))
      }
    }));

    const updatedLaborLineItems = missingLineItems
      .map(lineItem => {
        const { id: lineItemId, labourTypeId: laborTypeId } = lineItem || {};
        return pipe(
          patchLineItemHourRates({
            lineItemId,
            laborTypeId,
            laborRates,
            employeeMap,
            isTechnicianDefaultRates
          }),
          patchLineItemDepartment({
            lineItemId,
            departmentId: visitDepartmentId
          })
        )([lineItem]);
      })
      .flat();
    if (updatedLaborLineItems.length) {
      setLaborLineItems([...laborLineItems, ...updatedLaborLineItems]);
      setIsDirtyLineItems(true);
    }
  }, [
    missingVisitTechnicians,
    employeeMap,
    laborRates,
    laborLineItems,
    isTechnicianDefaultRates,
    hourTypes,
    visitDepartmentId
  ]);

  const handleChangeHours = useCallback(
    ({ lineItemId, hourTypeId, value }) => {
      const updatedLineItems = patchLineItemHoursField({
        lineItemId,
        hourTypeId,
        fieldKey: 'totalHours',
        fieldValue: value || null
      })(laborLineItems);
      setLaborLineItems(updatedLineItems);
      setIsDirtyLineItems(true);
    },
    [laborLineItems]
  );

  const handleChangeRate = useCallback(
    ({ lineItemId, hourTypeId, value }) => {
      const updatedLineItems = patchLineItemHoursField({
        lineItemId,
        hourTypeId,
        fieldKey: 'rate',
        fieldValue: value || null
      })(laborLineItems);
      setLaborLineItems(updatedLineItems);
      setIsDirtyLineItems(true);
    },
    [laborLineItems]
  );

  const handleChangeLaborType = useCallback(
    ({ lineItemId, laborTypeId }) => {
      const updatedLaborLineItems = pipe(
        patchLineItemLaborType({ lineItemId, laborTypeId }),
        patchLineItemHourRates({
          lineItemId,
          laborTypeId,
          laborRates,
          employeeMap,
          isTechnicianDefaultRates
        })
      )(laborLineItems);
      setLaborLineItems(updatedLaborLineItems);
      setIsDirtyLineItems(true);
    },
    [laborLineItems, laborRates, employeeMap, isTechnicianDefaultRates]
  );

  const handleChangeTechnician = useCallback(
    ({ lineItemId, technicianId }) => {
      const { labourTypeId: laborTypeId } =
        employees.find(employee => employee.id === technicianId) || {};
      const updatedLaborLineItems = pipe(
        patchLineItemTechnician({ lineItemId, technicianId }),
        patchLineItemLaborType({ lineItemId, laborTypeId }),
        patchLineItemHourRates({
          lineItemId,
          laborTypeId,
          laborRates,
          employeeMap,
          isTechnicianDefaultRates
        }),
        patchLineItemDepartment({
          lineItemId,
          visitDepartmentId
        })
      )(laborLineItems);
      setLaborLineItems(updatedLaborLineItems);
      setIsDirtyLineItems(true);
    },
    [
      laborLineItems,
      employees,
      laborRates,
      employeeMap,
      isTechnicianDefaultRates,
      visitDepartmentId
    ]
  );

  const handleChangeDepartment = useCallback(
    ({ lineItemId, departmentId }) => {
      const updatedLaborLineItem = patchLineItemDepartment({ lineItemId, departmentId })(
        laborLineItems
      );
      setLaborLineItems(updatedLaborLineItem);
      setIsDirtyLineItems(true);
    },
    [laborLineItems]
  );

  const columns = useLaborItemColumns({
    loading,
    disabled,
    employees,
    employeeMap,
    laborTypes,
    hourTypes,
    isTechnicianDefaultRates,
    visitDepartmentId,
    visitDepartmentName,
    onDelete: handleDeleteLaborLineItemDelete,
    onChangeHours: handleChangeHours,
    onChangeRate: handleChangeRate,
    onChangeLaborType: handleChangeLaborType,
    onChangeTechnician: handleChangeTechnician,
    onChangeDepartment: handleChangeDepartment
  });

  const rows = useMemo(
    () =>
      laborLineItems.map(item => ({
        ...item,
        ...flattenBillingHours(item)
      })),
    [laborLineItems]
  );

  const lineItemsTotal = useMemo(
    () => rows.reduce((result, row) => result + (row.subTotal || 0), 0),
    [rows]
  );

  return (
    <ThemeProvider>
      <div css={styles.container}>
        <Typography css={styles.subSectionTitle}>{title}</Typography>
        <div css={styles.buttonsContainer}>
          <PriceBookSelect
            disabled={loading || disabled || canSave}
            priceBookId={priceBookId}
            priceBooks={priceBooksWithTechniciansDefault}
            onChange={handlePriceBookChange}
          />
          <AddLineItemButton
            disabled={loading || disabled}
            onAddLineItem={handleAddLaborLineItem}
            onAddLineItemsForTechnicians={handleAddLaborLineItemsForTechnicians}
            disableAddLineItemsForTechnicians={!missingVisitTechnicians?.length}
          />
        </div>
      </div>
      <WrapTable
        placeholderVariant={TablePlaceholderVariant.TEXT}
        columns={columns}
        rows={rows}
        loading={loading}
        error={error}
        noDataMessage={noDataMessage}
        loadingRows={laborLineItems.length || 10}
        scrollX
      />
      <div css={styles.lineItemsTotal}>
        Total: <strong>{convertToCurrencyString(lineItemsTotal)}</strong>
      </div>
    </ThemeProvider>
  );
};

ReviewReportLaborLineItems.propTypes = {
  ...reviewReportPropTypes,
  job: object.isRequired,
  visit: object.isRequired
};
ReviewReportLaborLineItems.defaultProps = reviewReportDefaultProps;

export default withLazyMount(ReviewReportLaborLineItems);
