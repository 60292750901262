import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import SearchBox from './SearchBox';
import FilterMenu from './FilterMenu';
import FilterSummary from './FilterSummary';
import useStyles from './styles';
import { addDefaultRefinement, clearRefine } from './helpers';

export default function InputFilter({
  refreshCounter,
  onFilterResults,
  RHSElement,
  children,
  user,
  ...algoliaProps
}) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [clearRefinement, setClearRefinement] = useState({});
  const [selectedFilters, setSelectedFilters] = useState([]);

  const delayedUpdate = debounce(({ hits, query }) => {
    setData(hits);
    setSearchText(query);
  }, 500);

  useEffect(() => {
    const { refine } = clearRefinement;
    if (refine) clearRefine(clearRefinement, setSelectedFilters);
    // eslint-disable-next-line
  }, [refreshCounter, clearRefine]);

  const searchResultHandler = searchResults => {
    const { hits = [], query = '' } = searchResults || {};
    delayedUpdate({ hits, query, selectedFilters });
  };
  const onRefinementHandler = attribute => setFilter(state => ({ ...state, ...attribute }));
  const { refineList } = algoliaProps;

  let ids = [];
  const hasSearch = [searchText, selectedFilters].some(item => item.length !== 0);

  if (hasSearch) {
    ids = data.map(({ id }) => id);
  }

  return (
    <Grid container style={{ flexDirection: 'column' }}>
      <Grid className={classes.container} item justify="space-between">
        <Grid container item>
          <div className={classes.searchWrapper}>
            <SearchBox
              {...algoliaProps}
              inputClassNames={classes.algoliaInput}
              onLoading={status => setIsLoading(status)}
              onSearchResults={searchResultHandler}
              onRefinementHandler={onRefinementHandler}
              onClearRefinement={refinement => setClearRefinement(refinement)}
              refineList={addDefaultRefinement(algoliaProps.refineList, selectedFilters)}
            />
          </div>
          <FilterMenu
            filters={filter}
            refineList={refineList}
            isVisible={!isLoading}
            selectedFilters={selectedFilters}
            updateSelectedFilters={setSelectedFilters}
          />
          <FilterSummary
            resultCount={data.length}
            selectedFilters={selectedFilters}
            isVisible={selectedFilters.length > 0}
            updateSelectedFilter={setSelectedFilters}
          />
        </Grid>
        {RHSElement}
      </Grid>
      {children({ ids, hasSearch })}
    </Grid>
  );
}

InputFilter.propTypes = {
  filterList: PropTypes.array,
  refreshCounter: PropTypes.number,
  user: PropTypes.object.isRequired,
  refineList: PropTypes.array,
  searchIndex: PropTypes.string.isRequired,
  RHSElement: PropTypes.element,
  placeholder: PropTypes.string,
  onFilterResults: PropTypes.func.isRequired,
  service: PropTypes.func.isRequired
};

InputFilter.defaultProps = {
  filterList: [],
  refineList: [],
  refreshCounter: 0,
  RHSElement: null,
  onFilterResults: () => {},
  placeholder: 'Search...'
};
