import { AccountType } from 'utils/constants';

export const TaxRateColumns = [
  {
    id: 'name',
    maxTextLen: 30,
    label: 'Name'
  },
  {
    id: 'city',
    maxTextLen: 30,
    label: 'City'
  },
  {
    id: 'county',
    maxTextLen: 30,
    label: 'County'
  },
  {
    id: 'state',
    label: 'State'
  },
  {
    id: 'accountType',
    label: 'Account Type'
  },
  {
    id: 'taxRate',
    label: 'Tax Rate',
    type: 'percentage',
    filterType: 'number'
  }
];

/* eslint-disable no-template-curly-in-string */
export const TaxRateLayout = isIntegrated => ({
  fields: {
    name: {
      default: 'name',
      edit: 'name'
    },
    taxRate: {
      default: 'taxRate',
      edit: 'taxRate'
    },
    city: {
      default: 'city',
      edit: 'city'
    },
    county: {
      default: 'county',
      edit: 'county'
    },
    state: {
      default: 'state',
      edit: 'state'
    },
    accountType: {
      default: 'accountType',
      edit: 'accountType'
    }
  },
  layouts: {
    edit: {
      options: {
        pageSize: 'LETTER'
      },
      contents: [
        // Row 1
        {
          options: {
            direction: 'row'
          },
          contents: [
            {
              options: {
                grow: 2,
                direction: 'column',
                paddingRight: 25
              },
              contents: [
                {
                  options: {
                    label: 'Name',
                    isRequired: true
                  },
                  component: isIntegrated ? 'FieldWithLabel' : 'TextInput',
                  source: 'name'
                }
              ]
            },
            {
              options: {
                direction: 'column'
              },
              contents: [
                {
                  options: {
                    label: 'Tax Rate',
                    isRequired: true,
                    type: isIntegrated ? 'percentage' : 'number',
                    endAdornmentText: '%'
                  },
                  component: isIntegrated ? 'FieldWithLabel' : 'TextInput',
                  source: 'taxRate'
                }
              ]
            }
          ]
        },
        // Row 2
        {
          options: {
            paddingTop: 15,
            direction: 'row'
          },
          contents: [
            {
              options: {
                direction: 'column'
              },
              contents: [
                {
                  options: {
                    label: 'Account Type',
                    isClearable: true,
                    isSearchable: true,
                    valueSet: [
                      { label: AccountType.AR, value: AccountType.AR },
                      { label: AccountType.AP, value: AccountType.AP }
                    ]
                  },
                  component: 'SelectInput',
                  source: 'accountType'
                }
              ]
            }
          ]
        },
        // Row 3
        {
          options: {
            paddingTop: 15,
            direction: 'row'
          },
          contents: [
            {
              options: {
                direction: 'column'
              },
              contents: [
                {
                  options: {
                    searchOptions: [{ types: ['(regions)'] }],
                    valuesToSet: [
                      {
                        city: 'city.shortName',
                        county: 'county.shortName',
                        state: 'state.shortName'
                      }
                    ],
                    label: 'City, County, State Search',
                    fullWidth: true
                  },
                  component: 'PlacesSearch',
                  source: 'places'
                }
              ]
            }
          ]
        },
        // Row 3
        {
          options: {
            paddingTop: 15,
            direction: 'row'
          },
          contents: [
            {
              options: {
                paddingRight: 10,
                direction: 'column'
              },
              contents: [
                {
                  options: {
                    label: 'City'
                  },
                  component: 'FieldWithLabel',
                  source: 'city'
                }
              ]
            },
            {
              options: {
                paddingRight: 10,
                direction: 'column'
              },
              contents: [
                {
                  options: {
                    label: 'County'
                  },
                  component: 'FieldWithLabel',
                  source: 'county'
                }
              ]
            },
            {
              options: {
                direction: 'column'
              },
              contents: [
                {
                  options: {
                    label: 'State'
                  },
                  component: 'FieldWithLabel',
                  source: 'state'
                }
              ]
            }
          ]
        }
      ]
    }
  },
  validation: {
    type: 'object',
    properties: {
      name: {
        type: 'string'
      },
      taxRate: {
        type: 'number'
      }
    },
    required: ['name', 'taxRate']
  },
  validationErrors: {
    name: {
      required: 'Name is required.'
    },
    taxRate: {
      required: 'Tax rate is required.'
    }
  }
});

export default TaxRateLayout;
