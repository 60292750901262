import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Checkbox, Grid, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorBoundaries from 'scenes/Error';
import moment from 'moment';
import AppConstants from 'utils/AppConstants';
import styles from './styles';

const ReviewSummary = props => {
  const { classes, freezeEdit, summary } = props;
  const { dateAdded } = summary;
  const { lastUpdatedDateTime, createdDateTime } = summary;
  // when items in review report is not edited, both created and updated time should be same
  const isModified = lastUpdatedDateTime !== createdDateTime;
  // dateAdded will be null for items added directly in review report
  const modifiedDate =
    isModified || !dateAdded ? parseInt(summary.lastUpdatedDateTime, 10) / 1000 : dateAdded;

  return (
    <ErrorBoundaries>
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <Typography className={classes.content}>{summary?.summary || ''}</Typography>
              {summary?.lastUpdatedBy && (
                <Typography className={classes.footer}>
                  Last edited by {summary.addedBy || summary.lastUpdatedBy} on &nbsp;
                  {moment.unix(modifiedDate).format(AppConstants.DATETIME_FORMAT)}
                </Typography>
              )}
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              {!freezeEdit && (
                <Tooltip title="Edit">
                  <IconButton
                    aria-label="Edit"
                    className={classes.buttonHover}
                    onClick={() => props.editAction()}
                  >
                    <EditIcon color="secondary" className={classes.iconColor} />
                  </IconButton>
                </Tooltip>
              )}
              {!freezeEdit && (
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="Delete"
                    className={classes.buttonHover}
                    onClick={() => props.deleteAction()}
                  >
                    <DeleteIcon color="secondary" className={classes.iconColor} />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
          <Checkbox
            defaultChecked={props.defChecked}
            checked={props.includeInvoice}
            onChange={event =>
              props.changeIncludeInvoice && props.changeIncludeInvoice(event.target.checked)
            }
            disabled={freezeEdit}
          />
        </Grid>
      </Grid>
    </ErrorBoundaries>
  );
};

export default withStyles(styles, { withTheme: true })(ReviewSummary);
