import gql from 'graphql-tag';

const createPurchaseOrderTag = gql`
  mutation savePurchaseOrderTags(
    $partitionKey: String!
    $companyId: String!
    $data: [PurchaseOrderTagInput]!
  ) {
    savePurchaseOrderTags(
      partitionKey: $partitionKey
      data: { companyId: $companyId, purchaseOrderTags: $data }
    ) {
      id
      tagType
      tagName
      sortOrder
    }
  }
`;

export default createPurchaseOrderTag;
