import { makeStyles } from '@material-ui/core';

export const useLoaderStyles = makeStyles(() => ({
  rowOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%'
  },
  loadingContainer: {
    position: 'relative',
    minWidth: '100%'
  }
}));
