import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

const StyledTabs = withStyles(() => ({
  root: {
    marginLeft: 16,
    borderTop: '1px solid #E5E5E5',
    minHeight: 41,
    height: 41
  },
  indicator: {
    backgroundColor: 'rgba(255, 255, 255, 0)'
  }
}))(Tabs);

const StyledTab = withStyles(theme => ({
  root: {
    padding: '12px 20px',
    minWidth: 100,
    minHeight: 40,
    height: 40,
    color: theme.palette.grayscale(50),
    backgroundColor: theme.palette.grayscale(94),
    opacity: 1,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '16px',
    borderBottom: '1px solid #E5E5E5'
  },
  selected: {
    color: '#00874D',
    backgroundColor: theme.palette.common.white,
    borderRight: '1px solid #E5E5E5',
    borderLeft: '1px solid #E5E5E5',
    borderBottom: 'none',
    '&:first-child': {
      borderLeft: 'none'
    }
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
}))(props => <Tab disableRipple {...props} />);

const StyledBox = withStyles(theme => ({
  root: {
    width: '100%',
    flexGrow: 1,
    borderBottom: '1px solid #E5E5E5',
    backgroundColor: theme.palette.grayscale(94)
  }
}))(Box);

const CustomTabs = ({ value, tabMenus, onChange, tabStyle }) => {
  return (
    <StyledTabs value={value} onChange={onChange} indicatorColor="secondary">
      {tabMenus.map(tab => (
        <StyledTab
          key={tab.tabLabel}
          label={tab.tabLabel}
          style={tabStyle}
          disabled={tab.disabled}
        />
      ))}
      <StyledBox />
    </StyledTabs>
  );
};

export default CustomTabs;
