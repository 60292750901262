import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  descriptionInput: {
    '& input': {
      fontSize: 14
    }
  },
  cloudIcon: {
    width: 'auto'
  },
  pageActionButton: {
    marginBottom: theme.spacing(2.5)
  },
  table: {
    minWidth: '100%'
  },
  tableRow: {
    border: 'none'
  },
  imgIcon: {
    height: '100%'
  },
  icon: {
    flexShrink: 1,
    width: 'auto',
    height: 'auto',
    fontSize: '0px',
    background: theme.palette.grayscale(60)
  },
  removeOutline: {
    border: '1px solid',
    borderColor: theme.palette.grayscale(60)
  },
  removeIcon: {
    minHeight: '10px',
    minWidth: '10px',
    padding: 0
  },
  unitPrice: {},
  title: {
    paddingLeft: 8,
    paddingRight: 24
  },
  titleWPadding: {
    padding: '0 0 16px 8px'
  }
}));

export default styles;
