import { object, string } from 'yup';

const rowStyle = {
  marginBottom: 16
};

const ServiceChannelConfig = {
  layouts: {
    default: {
      props: {},
      contents: [
        {
          // row 1
          props: { style: rowStyle },
          contents: [
            {
              component: 'Input',
              source: 'clientId',
              props: {
                label: 'Client ID',
                required: true
              }
            }
          ]
        },
        {
          // row 2
          contents: [
            {
              component: 'Input',
              source: 'clientSecret',
              props: {
                label: 'Client Secret',
                required: true
              }
            }
          ]
        }
      ]
    }
  },
  validation: object({
    clientId: string()
      .nullable()
      .matches(
        // ex: SB.2014917243.70943550-9974-41E7-8B38-CA8C8C024262
        /^\S+\.\d+\.[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}$/im,
        'Must be a valid client ID'
      )
      .required('Client ID is required'),
    clientSecret: string()
      .nullable()
      .matches(
        // ex: 1C600CB7-4D24-4787-BB3E-ACD2B2FBF745
        /^[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}$/im,
        'Must be a valid UUID'
      )
      .required('Client secret is required')
  })
};

export default ServiceChannelConfig;
