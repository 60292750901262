export const useStyles = () => ({
  container: theme => ({
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }),
  lineItemsContainer: theme => ({
    paddingLeft: theme.spacing(8)
  }),
  title: theme => ({
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  })
});
