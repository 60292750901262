const styles = () => {
  return {
    root: {
      paddingLeft: 17
    },
    kpiContainer: {
      height: 146,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative'
    },
    kpiSubTitle: {
      position: 'absolute',
      top: 12,
      left: 0,
      fontSize: 14
    },
    searchAndButtonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      height: 58
    },
    highlightCardContainer: {
      marginTop: 20
    },
    approvalModalHeader: {
      fontWeight: 'bold',
      fontSize: '20px',
      paddingBottom: '15px'
    }
  };
};

export default styles;
