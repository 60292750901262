import gql from 'graphql-tag';

const updateDiscount = gql`
  mutation updateDiscount($partitionKey: String!, $data: UpdateDiscountInput!) {
    updateDiscount(partitionKey: $partitionKey, data: $data) {
      id
      sortKey
      itemName
      createdDateTime
      reasonForDiscount
      departmentName
      accountingRefIdOfClass
      taxable
      version
      amount
      includeInInvoice
    }
  }
`;

export default updateDiscount;
