import { ElementSizes } from '@dispatch/Dispatch.styles';

export const CardTypes = {
  AvailableTime: 'AvailableTime',
  NonVisitEvent: 'NonVisitEvent',
  Visit: 'Visit',
  ManDay: 'ManDay'
};

export const CardHeights = {
  [CardTypes.AvailableTime]: ElementSizes.availableTimeHeight,
  [CardTypes.NonVisitEvent]: ElementSizes.cellHeight - 1,
  [CardTypes.Visit]: ElementSizes.cellHeight - 1,
  [CardTypes.ManDay]: ElementSizes.cellHeight - 1
};

export const RAND_MAX_POLL_INTERVAL = 60000; // 1 min in ms
export const BASE_POLL_INTERVAL = 300000; // 5 min in ms
