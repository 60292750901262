import theme from 'themes/BuildHeroTheme';

const styles = {
  stickyHeader: {
    position: 'fixed',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    zIndex: 5,
    top: 60,
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(3)
  },
  greenHeading: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    marginBottom: 8
  },
  settingIcon: {
    color: theme.palette.secondary.main
  },
  formEndDivider: { marginTop: 40, marginBottom: 6 },
  defaultHourTypes: {
    width: 222,
    backgroundColor: theme.palette.other.palePink,
    height: 36,
    textAlign: 'left',
    paddingLeft: 8,
    fontWeight: 500,
    lineHeight: 28,
    margin: 2,
    paddingTop: 8
  },
  payrollHourTypes: {
    width: 222,
    backgroundColor: theme.palette.other.palePurple,
    height: 36,
    textAlign: 'left',
    paddingLeft: 8,
    fontWeight: 500,
    lineHeight: 28,
    margin: 2,
    paddingTop: 8
  },
  hourTypeContainer: {
    marginTop: 25,
    marginBottom: 15
  },
  greyCaption: {
    fontSize: 10,
    color: theme.palette.grayscale(60)
  }
};

export default styles;
