import gql from 'graphql-tag';

const deleteVisit = gql`
  mutation deleteVisit($input: DeleteVisitInput!) {
    deleteVisit(input: $input) {
      id
    }
  }
`;

export default deleteVisit;
