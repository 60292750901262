import React, { useCallback, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Tooltip,
  Checkbox,
  Box,
  Typography,
  Grid,
  Menu,
  MenuItem,
  Dialog,
  Slide,
  FormControlLabel
} from '@material-ui/core';
import { ThemeProvider, Button, ButtonType, theme as sgtheme } from '@buildhero/sergeant';

import AddIcon from '@material-ui/icons/Add';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useTheme } from '@material-ui/core/styles';
import { jsx } from '@emotion/react';
import {
  AppConstants,
  PermissionConstants,
  ReviewReportStatus,
  VisitStatus,
  TechReportStatus,
  AccessConstants,
  JOB_REPORT_STATUS,
  VISIT_REVIEW_STATUS
} from 'utils/AppConstants';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { InvoiceService } from 'services/core';
import { sentryMessage } from 'services/Logger';
import _ from 'lodash';
import Radio from '@material-ui/core/Radio';
import ErrorBoundaries from 'scenes/Error';
import Labels from 'meta/labels';
import { invoiceRows, reportRows } from 'meta/Jobs/Invoice';
import {
  Modal,
  SectionHeader,
  AddRecordButton,
  ResponsiveTable,
  SergeantModal,
  UserPermission,
  StatusChip
} from 'components';
import { snackbarOn } from 'redux/actions/globalActions';
import { capitalizeFirstLetter, checkPermission, isJSONParseableObjectOrArray } from 'utils';

import AddInvoice from 'scenes/Invoices/AddInvoice';
import { typeModalDefaultState } from 'constants/common';
import { Mode } from 'utils/constants';
import SwitchComponent from 'components/BuildHeroFormComponents/Switch';
import PresetSelect, {
  SENTRY_CORRUPT_SETTINGS
} from 'components/BuildHeroFormComponents/PresetSelect';
import RenameModal from 'components/ResponsiveTable/RenameModal';
import useExtendedQuery from 'customHooks/useExtendedQuery';
import useExtendedMutation from 'customHooks/useExtendedMutation';
import { FeatureFlags } from 'utils/FeatureFlagConstants';

import StorageService from 'services/StorageService';
import {
  GET_JOB_REPORT_PRESETS,
  SAVE_JOB_REPORT_PRESET_SETTING,
  DELETE_USER_SETTING_BY_ID
} from './gql';
import styles from './styles';
import JobReportPdf from './JobReportPdf';
import LayoutV1 from '../ReportPdfConfirm';
import LayoutV2 from '../ReportPdfConfirmV2';
import { getBillableInvoices } from './helpers';
import invoiceWarningLayout from './layout';
import { generateJobReportPDFs } from './exportJobReportService';
import { JobReportsPDFTable } from './JobReportsPDFTable.component';
import { getJobCloseoutType } from 'scenes/JobCloseout/utils';

const GENERATE_REPORT = 'Generate Report';
const SKIP_REVIEW = 'Skip Review';
const DEFAULT_CONFIRMATION_DATA_V1 = {
  hideCost: false,
  hidePrice: false,
  hideBeforeAfterPhotos: false,
  hideForms: false
};

const PRESET_TYPE = 'JobReport Presets';

// IMPORTANT - any changes to DEFAULT_CONFIRMATION_DATA_V2 need to reflected in src/classes/utils/default-json/defaultJobReportSettings.json of buildhero-deployment
const DEFAULT_CONFIRMATION_DATA_V2 = {
  [PRESET_TYPE]: { label: '', value: '' },
  presetId: 'detailedJobReport',
  showJobCost: true,
  showJobDescription: true,
  showJobSummary: true,
  showTaskCompleted: true,
  showHoursWorked: true,
  showHoursBilled: true,
  showCostSummary: true,
  showPriceSummary: true,
  showInventoryItems: true,
  showPurchasedItems: true,
  showMaterialsCostSummary: true,
  showMaterialsPriceSummary: true,
  showVendor: true,
  showBeforeAfterPhotos: true,
  showDateAndTimeForPhotos: true,
  showForms: true,
  showTaskForms: true,
  showCustomerSignature: true,
  showCostAndPriceSummary: true
};

export const DEFAULT_PRESET = {
  id: DEFAULT_CONFIRMATION_DATA_V2.presetId,
  label: 'Detailed Job Report',
  settings: JSON.stringify(DEFAULT_CONFIRMATION_DATA_V2),
  isDefault: true
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const DraftReportWarningMessage = () => {
  const theme = useTheme();
  return (
    <Box bgcolor={theme.palette.other.supportYellow1} p={1} display="flex">
      <InfoOutlinedIcon fontSize="small" style={{ marginRight: theme.spacing(1) }} />
      <Typography variant="caption">
        One or more review reports selected are still in “Draft” and are subject to changes
      </Typography>
    </Box>
  );
};

const TooltipInfo = ({ options: { message } }) => {
  const theme = useTheme();
  return (
    <Tooltip title={message}>
      <InfoOutlinedIcon css={{ marginLeft: theme.spacing(0.5), cursor: 'pointer', fontSize: 14 }} />
    </Tooltip>
  );
};

const statusBackgroundColor = {
  [TechReportStatus.ERROR]: sgtheme.palette.support.red.medium,
  [TechReportStatus.PROCESSING]: sgtheme.palette.support.yellow.medium
};

export const TechReport = ({ record }) => {
  const { techReport, technicianReport } = record;
  const download = async () => {
    const storageService = new StorageService();
    const url = await storageService.getFileUrl(techReport);
    window.open(url);
  };

  if (!techReport) return '-';
  if ([TechReportStatus.ERROR, TechReportStatus.PROCESSING].includes(techReport))
    return (
      <StatusChip
        label={techReport}
        backgroundColor={statusBackgroundColor[techReport]}
        style={{ borderRadius: 2 }}
      />
    );

  return (
    <ThemeProvider>
      <Box display="flex" flexDirection="column">
        <Typography css={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={download}>
          {technicianReport}
        </Typography>
      </Box>
    </ThemeProvider>
  );
};

const initialRenameModalState = {
  open: false,
  mode: Mode.ADD,
  presetOption: null
};

const InvoiceAndReport = props => {
  const theme = useTheme();
  const {
    data: presets,
    loading: loadingPresets,
    error: failedLoadingPresets,
    refetch: refetchPresets
  } = useExtendedQuery(GET_JOB_REPORT_PRESETS, {
    variables: {
      partitionKey: props.user.tenantId,
      sortKey: `${props.user.tenantId}_Company_${props.user.tenantCompanyId}`
    },
    transform: result => result?.getCompany?.userSettings?.items || [],
    fetchPolicy: 'cache-and-network'
  });

  const [
    removePreset,
    { loading: removingPreset, error: errorRemovingPreset }
  ] = useExtendedMutation(DELETE_USER_SETTING_BY_ID, {
    serializer: params => ({
      variables: {
        partitionKey: props.user.tenantId,
        id: params.id
      }
    })
  });

  const [savePreset, { loading: savingPreset, error: errorSavingPreset }] = useExtendedMutation(
    SAVE_JOB_REPORT_PRESET_SETTING,
    {
      serializer: params => {
        const payload = {
          partitionKey: props.user.tenantId,
          data: {
            companyId: props.user.tenantCompanyId,
            access: AccessConstants.PRIVATE,
            ...params
          }
        };

        return {
          variables: payload
        };
      }
    }
  );

  const presetOptions = useMemo(
    () =>
      loadingPresets || failedLoadingPresets
        ? []
        : presets.map(p => ({
            id: p.id,
            label: p.displayName,
            settings: p.settings,
            isDefault: p.isDefault || false
          })),
    [loadingPresets, failedLoadingPresets]
  );

  const flags = useFlags();
  const asyncJobReport = flags?.[FeatureFlags.ASYNC_JOB_REPORT];
  const presetJobReport = flags?.[FeatureFlags.PRESET_JOB_REPORT];

  const [openWarningPopup, setOpenWarningPopup] = React.useState(false);
  const [openInvoicePopup, setOpenInvoicePopup] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showJobReport, setShowJobReport] = React.useState(false);
  const [hasDraftReport, setHasDraftReport] = React.useState(false);
  const [visitsForJobReport, setVisitsForJobReport] = React.useState([]);
  const [visitsForSkipReport, setVisitsForSkipReport] = React.useState([]);
  const [newAsyncJobReport, setNewAsyncJobReport] = React.useState({});
  const [totalReportItems, setTotalReportItems] = React.useState({ skipReport: 0, jobReport: 0 });
  const [showCheckBoxes, setShowCheckBoxes] = React.useState(false);
  const [reportsBtn, setReportsBtn] = React.useState();
  const [selectedValue, setSelectedValue] = React.useState();
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [openTypeModal, setOpenTypeModal] = React.useState({
    ...typeModalDefaultState,
    mode: Mode.ADD,
    dataType: 'Tag'
  });
  const [pdfOptions, setPdfOptions] = React.useState({});

  const [renameModalState, setRenameModalState] = React.useState(initialRenameModalState);

  const classes = styles(theme);

  const draftReviewReportsIds = props.techReports
    .map(
      data =>
        data?.reviewReports?.items?.[0]?.status?.toLowerCase() === ReviewReportStatus.DRAFT &&
        data.id
    )
    .filter(item => !!item);

  const handleInvoicesRowActions = async (mode, record) => {
    const name = record.id;
    props.history.push(`/invoice/${mode}/${name}`, {
      recordSortKey: record.sortKey
    });
  };

  const invoiceTotal = (items, attributeName) => {
    let totalAmount = items.reduce((a, b) => a + b[attributeName], 0);
    if (totalAmount) {
      totalAmount =
        totalAmount &&
        parseFloat(totalAmount)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
    return totalAmount;
  };

  const getTechReportNames = visit => {
    let primaryTechName = '';
    let secondaryTechNames = '';

    if (visit?.primaryTechs?.length) {
      primaryTechName = `${visit.primaryTechs[0].firstName} ${visit.primaryTechs[0].lastName}`;
    }

    if (visit?.extraTechs?.length) {
      visit.extraTechs.forEach((tech, index) => {
        if (index === visit.extraTechs.length - 1) {
          secondaryTechNames += ' and ';
        }
        secondaryTechNames += `${tech.firstName} ${tech.lastName}`;
      });
    }
    const finalName = secondaryTechNames
      ? `${primaryTechName}, ${secondaryTechNames}`
      : primaryTechName;
    return finalName;
  };

  const isDraftReportSelected = (selectedIds = []) =>
    selectedIds.some(visitId => draftReviewReportsIds.includes(visitId));

  const processVisitForJobreport = useCallback(
    (event, visitId) => {
      const localVisitsForJobReport = [...visitsForJobReport];
      if (event.target.checked) {
        localVisitsForJobReport.push(visitId);
      } else if (localVisitsForJobReport.indexOf(visitId) > -1) {
        localVisitsForJobReport.splice(localVisitsForJobReport.indexOf(visitId), 1);
      }
      setHasDraftReport(isDraftReportSelected(localVisitsForJobReport));
      setVisitsForJobReport(localVisitsForJobReport);
    },
    [visitsForJobReport]
  );

  const processVisitsForSkipReport = useCallback(
    (event, visitId) => {
      const localVisitsForSkipReport = [...visitsForSkipReport];
      if (event.target.checked) {
        localVisitsForSkipReport.push(visitId);
      } else if (localVisitsForSkipReport.indexOf(visitId) > -1) {
        localVisitsForSkipReport.splice(localVisitsForSkipReport.indexOf(visitId), 1);
      }
      setVisitsForSkipReport(localVisitsForSkipReport);
    },
    [visitsForSkipReport]
  );
  const disabled = removingPreset || loadingPresets || savingPreset;

  const sanatize = useCallback(
    values =>
      // DEFAULT_CONFIRMATION_DATA_V2 acts as both a whitelist of props as well as a fallback in case more props get added to code in the future
      Object.keys(DEFAULT_CONFIRMATION_DATA_V2).reduce((acc, k) => {
        const key = k === 'presetId' ? 'id' : k;
        return { ...acc, [key]: values[key] ?? DEFAULT_CONFIRMATION_DATA_V2[key] };
      }, {}),
    []
  );

  const onClickCreateNewPreset = useCallback(
    ({ values }) =>
      setRenameModalState({
        open: true,
        mode: Mode.ADD,
        presetOption: {
          settings: JSON.stringify(values)
        }
      }),
    [disabled]
  );
  const onClickUpdatePreset = useCallback(
    ({ presetId, values }) => {
      if (disabled) return;
      if (presetId === DEFAULT_CONFIRMATION_DATA_V2.presetId) {
        props.snackbarOn('error', 'Cannot update default preset');
        return;
      }
      savePreset({
        id: presetId,
        settings: JSON.stringify(sanatize(values))
      });
    },
    // @TODO move the disabled prop inside of Sgt rather than having it outside when the SGT component is consolidated as part of BUOP-13578
    [disabled]
  );
  const onClickRenamePreset = useCallback(
    async presetId =>
      setRenameModalState({
        open: true,
        mode: Mode.RENAME,
        presetOption: presetOptions.find(o => o.id === presetId),
        presetId
      }),
    [disabled, presetOptions]
  );
  const onClickDuplicatePreset = useCallback(
    async presetId => {
      if (disabled) return;
      const option =
        presetId === DEFAULT_PRESET.id
          ? DEFAULT_PRESET
          : presetOptions.find(o => o.id === presetId);

      if (!isJSONParseableObjectOrArray(option?.settings)) {
        sentryMessage(SENTRY_CORRUPT_SETTINGS, { preset: option });
        return;
      }

      const duplicateName = `${option.label} copy`;

      const parsedSettings = JSON.parse(option.settings);

      const namedSettings = {
        ...parsedSettings,
        [PRESET_TYPE]: {
          ...parsedSettings[PRESET_TYPE],
          label: duplicateName
        }
      };

      await savePreset({
        name: duplicateName,
        displayName: duplicateName,
        settings: JSON.stringify(sanatize(namedSettings))
      });
      refetchPresets(); // @TODO in BUOP-13578 use the update option in extended mutation of savePreset to edit cache directly instead of refetching
    },
    [disabled, presetOptions]
  );

  const onClickRemovePreset = useCallback(
    async presetId => {
      if (disabled) return;
      if (presetId === DEFAULT_CONFIRMATION_DATA_V2.presetId) {
        props.snackbarOn('error', 'Cannot remove default preset');
        return;
      }

      await removePreset({ id: presetId });
      refetchPresets(); // @TODO in BUOP-13578 use the update option in extended mutation of removePreset to edit cache directly instead of refetching
    },
    [disabled]
  );
  const onRenamePreset = useCallback(
    async name => {
      if (disabled) return;

      if (!name) {
        props.snackbarOn('error', 'Preset name is required');
        return;
      }
      switch (renameModalState.mode) {
        case Mode.RENAME: {
          if (renameModalState.presetId === DEFAULT_PRESET.id) {
            props.snackbarOn('error', 'Cannot rename the default preset');
            return;
          }

          if (!isJSONParseableObjectOrArray(renameModalState.presetOption?.settings)) {
            sentryMessage(SENTRY_CORRUPT_SETTINGS, {
              preset: renameModalState.presetOption,
              renameModalState
            });

            return;
          }

          if (
            presetOptions.find(
              o =>
                (o.id !== renameModalState.presetId && o.label === name) ||
                DEFAULT_PRESET.label === name
            )
          ) {
            props.snackbarOn('error', 'A preset with this name already exists');
            return;
          }

          const parsedSettings = JSON.parse(renameModalState.presetOption.settings);

          const renamedSettings = {
            ...parsedSettings,
            [PRESET_TYPE]: {
              ...parsedSettings[PRESET_TYPE],
              label: name
            }
          };

          savePreset({
            id: renameModalState.presetOption.id,
            name,
            displayName: name,
            settings: JSON.stringify(sanatize(renamedSettings))
          });
          break;
        }
        case Mode.ADD: {
          if (!isJSONParseableObjectOrArray(renameModalState.presetOption?.settings)) {
            sentryMessage(SENTRY_CORRUPT_SETTINGS, {
              preset: renameModalState.presetOption,
              renameModalState
            });
            return;
          }

          if (DEFAULT_PRESET.label === name || presetOptions.find(o => o.label === name)) {
            props.snackbarOn('error', 'A preset with this name already exists');
            return;
          }

          const parsedSettings = JSON.parse(renameModalState.presetOption.settings);

          const namedSettings = {
            ...parsedSettings,
            [PRESET_TYPE]: {
              ...parsedSettings[PRESET_TYPE],
              label: name
            }
          };

          await savePreset({
            name,
            displayName: name,
            settings: JSON.stringify(sanatize(namedSettings))
          });
          refetchPresets(); // @TODO in BUOP-13578 use the update option in extended mutation of savePreset to edit cache directly instead of refetching

          break;
        }
        default: {
          // do nothing
        }
      }
    },
    [renameModalState, disabled, presetOptions]
  );

  const selectedVisitsForJobReport = () => {
    const visitsWithReviewReport =
      props?.techReports?.map(
        techReport => techReport?.reviewReports?.items?.length > 0 && techReport.id
      ) || [];
    const localVisitsForJobReport = visitsWithReviewReport.filter(item => !!item); // filtering visits with only tech reports

    if (localVisitsForJobReport.length === 0) {
      setSelectedValue('');
      props.snackbarOn('error', 'There are no review reports available to generate report');
    }
    setVisitsForJobReport(localVisitsForJobReport);
    setTotalReportItems(prev => ({ ...prev, jobReport: localVisitsForJobReport.length }));
    setHasDraftReport(isDraftReportSelected(localVisitsForJobReport));
    setShowCheckBoxes(true);
    setReportsBtn('JobReport');
  };

  const selectedVisitsForSkipReport = () => {
    const localVisitsForSkipReport = [];
    const data = props.techReports || [];
    if (data && !_.isEmpty(data)) {
      data.forEach(techReport => {
        if (['Complete', 'Closed'].includes(techReport.status)) {
          localVisitsForSkipReport.push(techReport.id);
        }

        const reviewReport = techReport?.reviewReports?.items || [];
        if (
          ['Converted', 'On hold'].includes(techReport.status) &&
          !_.isEmpty(reviewReport) &&
          reviewReport?.[0]?.status === ReviewReportStatus.DRAFT
        ) {
          localVisitsForSkipReport.push(techReport.id);
        }

        if (techReport.status === 'On hold' && reviewReport && _.isEmpty(reviewReport)) {
          localVisitsForSkipReport.push(techReport.id);
        }
      });
    }

    if (!localVisitsForSkipReport.length) {
      setSelectedValue('');
      props.snackbarOn('error', 'There is no technician reports available to skip review report');
    }
    setVisitsForSkipReport(localVisitsForSkipReport);
    setTotalReportItems(prev => ({ ...prev, skipReport: localVisitsForSkipReport.length }));
    setReportsBtn('SkipReport');
    setShowCheckBoxes(true);
  };

  const skipReviewReportForVisits = async techReports => {
    setShowSpinner(true);
    const techReportsSortkey = [];
    const data = props.techReports || [];
    if (!_.isEmpty(data)) {
      data.forEach(rowData => {
        techReports.forEach(report => {
          if (report === rowData.id) {
            techReportsSortkey.push({ visitSortKey: rowData.sortKey });
          }
        });
      });
    }

    const invoiceService = new InvoiceService();
    await invoiceService.skipReviewReportForVisits(props.user.tenantId, {
      visits: techReportsSortkey
    });
    await props.refetchVisits(props.jobInfo.jobNumber);
    setShowCheckBoxes(false);
    setReportsBtn('');
    setVisitsForSkipReport([]);
    setSelectedValue('');
    setShowSpinner(false);
  };

  const { jobInfo, invoices, isJobTypeInternal } = props;

  const DEFAULT_CONFIRMATION_DATA =
    presetJobReport || asyncJobReport ? DEFAULT_CONFIRMATION_DATA_V2 : DEFAULT_CONFIRMATION_DATA_V1;

  const showHideReportPDFConfirmation = (showConfirm, showPdf) => {
    setShowJobReport(showPdf);
    setOpenTypeModal({
      ...openTypeModal,
      open: showConfirm,
      data: {
        hasDraftReport,
        ...DEFAULT_CONFIRMATION_DATA,
        showJobCost: !!jobInfo.closeoutReport
      }
    });
  };

  const data = props.techReports || [];
  const visitsWithInvoices = [];

  invoices.forEach(invoice => {
    if (invoice.invoiceVisits) {
      invoice.invoiceVisits.items.forEach(visit => {
        visitsWithInvoices.push(visit.visit.visitNumber);
      });
    }
  });

  const links = [
    {
      label: GENERATE_REPORT,
      action: () => selectedVisitsForJobReport()
    },
    {
      label: SKIP_REVIEW,
      action: () => selectedVisitsForSkipReport()
    }
  ];

  // BUOP-4886 - count all invoices regardless of status
  const numInvoicesIssued = invoices?.length || 0;

  const renderJobReportAction = useCallback(
    (recordProps, closeoutReport) => {
      const rowData = recordProps?.record || {};

      if (closeoutReport) {
        return (
          <Checkbox
            checked={visitsForJobReport.includes(rowData.id)}
            onChange={event => processVisitForJobreport(event, rowData.id)}
          />
        );
      }

      return reportsBtn === 'JobReport'
        ? showCheckBoxes && rowData?.reviewReports?.items?.length > 0 && (
            <Checkbox
              checked={visitsForJobReport.includes(rowData.id)}
              onChange={event => processVisitForJobreport(event, rowData.id)}
            />
          )
        : ([VisitStatus.COMPLETE, VisitStatus.CLOSED].includes(rowData.status) ||
            ([VisitStatus.CONVERTED, VisitStatus.ONHOLD].includes(rowData.status) &&
              rowData?.reviewReports?.items?.[0]?.status?.toLowerCase() ===
                ReviewReportStatus.DRAFT) ||
            (rowData.status === VisitStatus.ONHOLD &&
              rowData?.reviewReports?.items?.length === 0)) &&
            showCheckBoxes && (
              <Checkbox
                checked={visitsForSkipReport.includes(rowData.id)}
                onChange={event => processVisitsForSkipReport(event, rowData.id)}
              />
            );
    },
    [visitsForSkipReport, visitsForJobReport]
  );

  const reportTableData = data.map(rowData => ({
    ...rowData,
    visitNumber: `#${rowData.visitNumber}`,
    technicianReport: `By ${getTechReportNames(rowData)}
    ${
      rowData.submittedDate
        ? `, ${moment.unix(rowData.submittedDate).format(AppConstants.DATETIME_FORMAT)}`
        : ''
    }`,
    reviewReportId: rowData?.reviewReports?.items?.[0]?.id || null,
    reviewedReport: rowData?.reviewReports?.items?.[0]?.status
      ? `${capitalizeFirstLetter(rowData.reviewReports.items[0].status)}
      by ${rowData.reviewReports.items[0].lastUpdatedBy},
      ${moment(+rowData.reviewReports.items[0].lastUpdatedDateTime).format(
        AppConstants.DATETIME_FORMAT
      )}`
      : null,
    jobCloseoutType: getJobCloseoutType(jobInfo)
  }));

  const label = isJobTypeInternal ? 'maintenanceReports' : 'job reports';
  const reportType = isJobTypeInternal ? 'Maintenance' : 'Job';
  const enableGenerateReportBtn = useMemo(
    () =>
      (jobInfo.closeoutReport || selectedValue === GENERATE_REPORT) &&
      !_.isEmpty(visitsForJobReport),
    [visitsForJobReport]
  );

  const enableSkipReportBtn = useMemo(
    () => selectedValue === SKIP_REVIEW && !_.isEmpty(visitsForSkipReport),
    [visitsForSkipReport]
  );

  const enableAllCheckbox = useMemo(() => {
    if (jobInfo.closeoutReport) return visitsForJobReport.length === props.techReports.length;

    if (selectedValue === GENERATE_REPORT)
      return totalReportItems.jobReport === visitsForJobReport.length;
    if (selectedValue === SKIP_REVIEW)
      return totalReportItems.skipReport === visitsForSkipReport.length;
    return false;
  }, [
    selectedValue,
    totalReportItems,
    visitsForJobReport.length,
    visitsForSkipReport.length,
    jobInfo
  ]);

  useEffect(() => {
    if (jobInfo.closeoutReport) {
      setVisitsForJobReport(props.techReports.map(t => t.id));
    }
  }, []);

  return (
    <>
      {!asyncJobReport && (
        <SectionHeader
          title={Labels[label][props.user.locale]}
          enablePadding
          icon="assignmentIcon"
        />
      )}

      {jobInfo.closeoutReport ? (
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box />
          <Box>
            <ThemeProvider>
              <Button
                type={ButtonType.TERTIARY}
                loading={showSpinner}
                disabled={!enableGenerateReportBtn}
                onClick={() => {
                  showHideReportPDFConfirmation(true, false);
                }}
                css={{ marginRight: 16 }}
              >
                Generate PDF Report
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="body2"> SELECT VISIT TO :</Typography>
            {links &&
              Array.isArray(links) &&
              links.map(link => (
                <FormControlLabel
                  key={link.label}
                  onChange={async e => {
                    setSelectedValue(e.target.value);
                    link.action();
                  }}
                  checked={selectedValue === link.label}
                  control={<Radio />}
                  value={link.label}
                  label={link.label}
                />
              ))}
          </Box>
          <Box>
            <ThemeProvider>
              <Button
                type={ButtonType.TERTIARY}
                loading={showSpinner}
                disabled={!enableGenerateReportBtn}
                onClick={() => {
                  showHideReportPDFConfirmation(true, false);
                }}
                css={{ marginRight: 16 }}
              >
                {GENERATE_REPORT}
              </Button>
              <Button
                type={ButtonType.TERTIARY}
                disabled={!enableSkipReportBtn}
                onClick={() => skipReviewReportForVisits(visitsForSkipReport)}
              >
                {SKIP_REVIEW}
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      )}
      <Box display="flex" flexDirection="column">
        {!jobInfo.closeoutReport && showCheckBoxes && (
          <Checkbox
            css={classes.positionedHeaderCheckbox}
            checked={enableAllCheckbox}
            onChange={event => {
              event.preventDefault();
              if (event.target.checked) {
                return selectedValue === GENERATE_REPORT
                  ? selectedVisitsForJobReport()
                  : selectedVisitsForSkipReport();
              }
              setVisitsForJobReport([]);
              setVisitsForSkipReport([]);
            }}
            key={enableAllCheckbox}
          />
        )}
        {jobInfo.closeoutReport && (
          <Checkbox
            css={classes.positionedHeaderCheckbox}
            checked={enableAllCheckbox}
            onChange={event => {
              event.preventDefault();
              if (event.target.checked) {
                setVisitsForJobReport(props.techReports.map(t => t.id));
                return;
              }
              setVisitsForJobReport([]);
              setShowCheckBoxes(false);
            }}
            key={enableAllCheckbox}
          />
        )}
        <ResponsiveTable
          rowMetadata={reportRows(jobInfo)}
          data={reportTableData}
          customCellComponents={{
            action: ({ ...recordProps }) =>
              renderJobReportAction(recordProps, jobInfo.closeoutReport),
            TechReport
          }}
          noDataMsg="No visits"
          caslKey={PermissionConstants.OBJECT_VISIT}
        />
      </Box>
      {asyncJobReport && (
        <Box paddingTop={3} paddingBottom={3}>
          <SectionHeader
            title={Labels[label][props.user.locale]}
            enablePadding
            icon="assignmentIcon"
          />
          <JobReportsPDFTable
            jobId={jobInfo.id}
            newAsyncJobReport={newAsyncJobReport}
            refresh={() => setNewAsyncJobReport({})}
            key={newAsyncJobReport.createdDateTime}
          />
        </Box>
      )}
      <Grid container spacing={5} css={classes.billingBox}>
        <Grid item xs={2}>
          <Typography css={classes.labelText}>
            {Labels.invoicesIssued[props.user.locale]}
          </Typography>
          <Typography css={classes.valueText}>{numInvoicesIssued}</Typography>
        </Grid>
        {checkPermission('allow', PermissionConstants.DATA_VIEW_PRICE_DATA, props.user) && (
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Typography css={classes.labelText}>
              {Labels.totalBilledForJob[props.user.locale]}
            </Typography>
            <Typography css={classes.valueText}>
              $ {invoiceTotal(getBillableInvoices(invoices), 'totalAmount')}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid style={{ paddingTop: 40 }} />
      <SectionHeader
        title={Labels.invoices[props.user.locale]}
        enablePadding
        icon="descriptionIcon"
      />
      {!jobInfo.closeoutReport && (
        <UserPermission I="create" action={PermissionConstants.OBJECT_INVOICE}>
          <Grid>
            <AddRecordButton
              label="Create"
              icon={AddIcon}
              disabled={data.length === 0}
              handle={event => setAnchorEl(event.currentTarget)}
              aria-owns={anchorEl ? 'simple-menu' : undefined}
              aria-haspopup="true"
            />
          </Grid>
        </UserPermission>
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        style={{ top: 64 }}
      >
        <MenuItem
          onClick={() => {
            if (props.jobInfo.jobTypeInternal === 'Maintenance') {
              setOpenWarningPopup(true);
            } else {
              setOpenInvoicePopup(true);
            }
          }}
        >
          Invoice
        </MenuItem>
      </Menu>

      {openWarningPopup && (
        <SergeantModal
          open={openWarningPopup}
          dataType="Invoice"
          customPrimaryButtonLabel="Yes"
          mode="Create"
          maxWidth={496}
          layout={invoiceWarningLayout}
          handlePrimaryAction={() => {
            setOpenWarningPopup(false);
            setOpenInvoicePopup(true);
          }}
          handleClose={() => setOpenWarningPopup(false)}
        />
      )}

      {openInvoicePopup && (
        <Modal
          open={openInvoicePopup}
          width="1056"
          handleClose={() => {
            setOpenInvoicePopup(false);
          }}
        >
          <AddInvoice
            visits={data}
            jobInfo={jobInfo}
            handleClose={() => setOpenInvoicePopup(false)}
            history={props.history}
          />
        </Modal>
      )}
      <ResponsiveTable
        rowMetadata={invoiceRows}
        isLoading={!invoices}
        data={invoices}
        rowActionButtons={{
          view: {
            label: 'View',
            caslAction: 'view',
            icon: 'Launch'
          },
          edit: {
            label: 'Edit',
            caslAction: 'edit',
            icon: 'Edit'
          }
        }}
        rowActions={handleInvoicesRowActions}
        noDataMsg="No invoice"
        caslKey={PermissionConstants.OBJECT_INVOICE}
      />
      <SergeantModal
        open={openTypeModal.open}
        data={openTypeModal.data}
        mode={openTypeModal.mode}
        dataType={openTypeModal.dataType}
        handlePrimaryAction={(values, modalCallback) => {
          if (asyncJobReport) {
            // TODO - in the past you could only select visits with a Review Report
            // if closeoutReport is true, there are no review reports and you can select any visit
            // due to this the pdf generation fails. Will be fixed in BUOP-14584
            generateJobReportPDFs({
              visitIds: visitsForJobReport,
              viewSettings: values,
              jobId: jobInfo.id,
              isJobCloseoutReport: jobInfo.closeoutReport,
              snackbarOn: props.snackbarOn
            });
            const visitNumbers = reportTableData
              ?.map(v => {
                if (visitsForJobReport.includes(v.id)) {
                  return v.visitNumber?.replace('#', '');
                }
                return false;
              })
              ?.filter(Boolean)
              ?.join(',');

            setNewAsyncJobReport({
              status: JOB_REPORT_STATUS.PROCESSING,
              visitNumbers,
              createdBy: props.user.displayName,
              createdDateTime: moment().unix()
            });
            showHideReportPDFConfirmation(false, false);
            setShowCheckBoxes(false);
            setShowJobReport(false);
            setVisitsForJobReport([]);
            setReportsBtn('');
            setSelectedValue('');
            modalCallback();
            props.snackbarOn(
              'success',
              'Generating Job Report. Please check back in a few minutes'
            );
            return;
          }
          showHideReportPDFConfirmation(false, true);
          setPdfOptions(values);
          modalCallback();
        }}
        layout={
          presetJobReport || asyncJobReport
            ? LayoutV2(
                hasDraftReport,
                theme,
                presetOptions,
                // @TODO consolidate with useMutatePresets as part of BUOP-13578
                onClickCreateNewPreset,
                onClickUpdatePreset,
                onClickRenamePreset,
                onClickDuplicatePreset,
                onClickRemovePreset,
                jobInfo.closeoutReport
              )
            : LayoutV1(hasDraftReport)
        }
        maxWidth={496}
        customComponents={{
          Switch: SwitchComponent,
          DraftReportWarningMessage,
          TooltipInfo,
          PresetSelect
        }}
        title={`Generate ${reportType} Report PDF`}
        customPrimaryButtonLabel={`GENERATE ${reportType} REPORT`}
        handleClose={() => showHideReportPDFConfirmation(false, false)}
      />
      <Dialog
        fullScreen
        open={showJobReport}
        onClose={() => {
          setShowJobReport(false);
          setVisitsForJobReport([]);
          setShowCheckBoxes(false);
          setReportsBtn('');
          setSelectedValue('');
        }}
        TransitionComponent={Transition}
      >
        <Grid container direction="row" spacing={2} justify="space-between" alignItems="center">
          <Grid item style={{ marginLeft: 30 }}>
            <Typography variant="subtitle2">
              {`${reportType} Report #${jobInfo.customIdentifier || jobInfo.jobNumber} Preview`}
            </Typography>
          </Grid>
          <Grid item>
            <AddRecordButton
              label="CLOSE"
              handle={() => {
                setShowJobReport(false);
                setVisitsForJobReport([]);
                setShowCheckBoxes(false);
                setReportsBtn('');
                setSelectedValue('');
                setPdfOptions({});
              }}
            />
          </Grid>
        </Grid>
        <ErrorBoundaries>
          <JobReportPdf
            reportType={reportType}
            pdfOptions={pdfOptions}
            jobNumber={jobInfo.jobNumber}
            visitIdList={visitsForJobReport}
            presetJobReport={presetJobReport}
          />
        </ErrorBoundaries>
      </Dialog>

      <RenameModal
        open={renameModalState.open}
        currentLabel={
          renameModalState.mode === Mode.ADD
            ? ''
            : renameModalState.presetOption?.label ?? DEFAULT_PRESET.label
        }
        handleViewRename={onRenamePreset}
        handleClose={() => setRenameModalState(initialRenameModalState)}
        dataType="Job Report Preset"
        mode={renameModalState.mode}
      />
    </>
  );
};

const jobDataPropType = PropTypes.shape({
  jobNumber: PropTypes.string.isRequired,
  jobTypeInternal: PropTypes.string.isRequired,
  customIdentifier: PropTypes.string.isRequired
});

InvoiceAndReport.propTypes = {
  jobInfo: jobDataPropType.isRequired,
  history: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  techReports: PropTypes.array.isRequired,
  snackbarOn: PropTypes.func.isRequired,
  refetchVisits: PropTypes.func.isRequired,
  invoices: PropTypes.array.isRequired,
  isJobTypeInternal: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

const mapNewViewJobToProps = dispatch => ({
  snackbarOn: (mode, message) => dispatch(snackbarOn(mode, message))
});

const reduxConnectedInvoiceAndReport = connect(
  mapStateToProps,
  mapNewViewJobToProps
)(InvoiceAndReport);

export default reduxConnectedInvoiceAndReport;
