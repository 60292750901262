import gql from 'graphql-tag';

const updateCostCode = gql`
  mutation updateCostCode($partitionKey: String!, $data: UpdateCostCodeInput!) {
    updateCostCode(partitionKey: $partitionKey, data: $data) {
      id
      name
      description
      isActive
      version
    }
  }
`;

export default updateCostCode;
