import React, { memo } from 'react';

import { useVisitTransition } from '@dispatch/mutations/useVisitTransition';
import useUpdateBillableNonVisit from '@dispatch/mutations/useUpdateBillableNonVisit';
import useUpdateManDay from '@dispatch/mutations/useUpdateManDay';
import AvailableTimeCard from './AvailableTimeCard.component';

const Memoized = memo(AvailableTimeCard);

const DataProvider = props => {
  const [triggerVisitTransition] = useVisitTransition();
  const [triggerUpdateNonVisit] = useUpdateBillableNonVisit();
  const [triggerUpdateManDay] = useUpdateManDay();
  return (
    <Memoized
      {...props}
      triggerVisitTransition={triggerVisitTransition}
      triggerUpdateNonVisit={triggerUpdateNonVisit}
      triggerUpdateManDay={triggerUpdateManDay}
    />
  );
};

export default DataProvider;
