import gql from 'graphql-tag';

export const GetAdjustments = gql`
  query GetAdjustments(
    $partitionKey: String!
    $sortKey: String!
    $filter: GenericFilterInput
    $limit: Int
    $offset: Int
    $sort: [SortingInput]
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      adjustments(
        entityConnection: "Adjustment"
        pagination: { limit: $limit, offset: $offset }
        filter: $filter
        sorting: $sort
      ) {
        nextToken
        items {
          id
          number
          date
          amount
          appliedAmount
          departmentId
          department(entityConnection: "Department") {
            tagName
          }
          billingCustomerId
          billingCustomer(entityConnection: "Customer") {
            customerName
          }
          adjustmentTypeId
          adjustmentType(entityConnection: "AdjustmentType") {
            name
          }
          ledgerAccountId
          ledgerAccount(entityConnection: "LedgerAccount") {
            name
          }
          transactionType
          status
          exportStatus
          syncStatus
          createdBy
          createdDate
          transactions(entityConnection: "AdjustmentTransaction") {
            items {
              id
              appliedAmount
              transactionEntity
              transactionId
              transaction {
                ... on Invoice {
                  id
                  invoiceNumber
                }
                ... on Payment {
                  id
                  paymentNumber
                }
              }
            }
          }
        }
      }
    }
  }
`;
