import { QuoteLineItemType } from 'utils/constants';

class PageHeaderUtils {
  static calcTotalBudgetedLaborHours(quoteLineTasks, hasTotalBudgetedHoursOn) {
    if (!hasTotalBudgetedHoursOn) return null;
    return quoteLineTasks.items.reduce((acc, task) => {
      const laborTotalPerTask = task.quoteLineLabors.items.reduce(
        (laborAcc, laborItem) =>
          laborAcc +
          laborItem.quoteLineLaborPayrollHours.items.reduce((sum, type) => sum + type.hours, 0),
        0
      );
      return acc + laborTotalPerTask;
    }, 0);
  }

  static composeAddProjectDetails(propertyDetails, quoteInfo) {
    return {
      customer: propertyDetails?.customer,
      billingCustomer: propertyDetails?.property?.billingCustomer,
      property: propertyDetails?.property,
      projectManagerDepartment: quoteInfo?.department,
      address1: propertyDetails?.property?.address?.addressLine1,
      address2: propertyDetails?.property?.address?.addressLine2,
      addressCity: propertyDetails?.property?.address?.city,
      addressState: propertyDetails?.property?.address?.state,
      addressPostal: propertyDetails?.property?.address?.zipcode,
      projectManager: quoteInfo?.owner,
      soldBy: quoteInfo?.soldBy,
      description: quoteInfo?.issueDescription
    };
  }

  static composeAddPurchaseOrderData(quoteInfo) {
    let lineItemNumber = 0;
    const quoteLineProducts = (quoteInfo?.quoteLineProducts?.items || [])
      .filter(item => item.lineItemType === QuoteLineItemType.MATERIAL)
      .map(product => {
        lineItemNumber += 1;
        return {
          ...product,
          costCodeObj: product.costCode,
          revenueTypeObj: product.revenueType,
          jobCostType: product.jobCostType,
          itemName: product.name,
          totalCost: product.unitCost * product.quantity,
          itemNumber: lineItemNumber
        };
      });
    const quoteTaskProducts = (quoteInfo?.quoteLineTasks?.items || [])
      .reduce((acc, task) => {
        const taskProducts = task?.quoteLineProducts?.items || task?.products?.items || [];
        return [...acc, ...taskProducts];
      }, [])
      .map(product => {
        lineItemNumber += 1;
        return {
          ...product,
          itemName: product.name,
          totalCost: product.unitCost * product.quantity,
          itemNumber: lineItemNumber
        };
      });

    return {
      department: quoteInfo?.department
        ? {
            id: quoteInfo.department.id,
            tagName: quoteInfo.department.tagName
          }
        : null,
      quoteProducts: [...quoteLineProducts, ...quoteTaskProducts]
    };
  }

  static composeFieldsForJob({
    addingQuoteFromJob,
    companyRepOnQuote,
    companyRepOnJob,
    departmentOnQuote,
    projectManagerOnJob,
    projectManagerOnQuote,
    propertyRepOnJob,
    propertyRepOnQuote,
    soldByOnJob,
    soldByOnQuote
  }) {
    const COMPANY_REP = 'Company Representative';
    const DEPARTMENT = 'Department';
    const PROPERTY_REP = 'Property Representative';
    const PROJECT_MGR = 'Project Manager';
    const SOLD_BY = 'Sold by';
    const fields = [COMPANY_REP, DEPARTMENT, PROPERTY_REP, PROJECT_MGR, SOLD_BY];
    const copyFields = [];
    const doNotCopyFields = [];

    const doNotCopy = {
      [COMPANY_REP]: companyRepOnQuote && companyRepOnJob,
      [DEPARTMENT]: addingQuoteFromJob,
      [PROJECT_MGR]: projectManagerOnQuote && projectManagerOnJob,
      [PROPERTY_REP]: propertyRepOnQuote && propertyRepOnJob,
      [SOLD_BY]: soldByOnQuote && soldByOnJob
    };

    const copy = {
      [COMPANY_REP]: companyRepOnQuote,
      [DEPARTMENT]: departmentOnQuote,
      [PROJECT_MGR]: projectManagerOnQuote,
      [PROPERTY_REP]: propertyRepOnQuote,
      [SOLD_BY]: soldByOnQuote
    };

    fields.forEach(field => {
      if (doNotCopy[field]) {
        doNotCopyFields.push(field);
      } else if (copy[field]) {
        copyFields.push(field);
      }
    });

    return { copyFields, doNotCopyFields };
  }

  static hasTotalBudgetedHoursOn({ getTenantSettingValueForKey, quoteSettings, SettingConstants }) {
    const showTotalBudgetedHours =
      getTenantSettingValueForKey('budgetLaborHoursAtJobLevel') === 'true';
    const hasEnableOverridesOn = JSON.parse(quoteSettings)?.[SettingConstants.QUOTE_MODE]?.[
      SettingConstants.ENABLE_OVERRIDES
    ];
    return showTotalBudgetedHours && !hasEnableOverridesOn;
  }
}

export default PageHeaderUtils;
