import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  },
  breadCrumb: {
    color: theme.palette.grayscale(20),
    fontSize: 12,
    fontWeight: 'normal',
    letterSpacing: 0,
    height: 17
  },
  breadCrumbSlash: {
    color: theme.palette.brand.logoGreen
  },
  existingJobModal: {
    '& [class^="MuiDialogContent-root"]': {
      overflowY: 'initial'
    }
  },
  quoteTitle: {
    color: 'rgb(0, 0, 0)',
    fontSize: 24,
    fontWeight: 'bold',
    letterSpacing: -0.02,
    minWidth: 72,
    marginRight: 4
  },
  editIcon: {
    fill: theme.palette.grayscale(60),
    borderRadius: 0,
    height: 16,
    width: 16
  },
  quoteNumber: {
    color: 'rgb(0, 0, 0)',
    fontSize: 20,
    fontWeight: 'normal',
    letterSpacing: -0.02,
    maxWidth: 115,
    marginRight: 8
  },
  primaryBtn: {
    backgroundColor: theme.palette.brand.green,
    color: theme.palette.grayscale(100),
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    textAlign: 'center',
    marginLeft: 8,
    textTransform: 'uppercase',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.brand.green,
      boxShadow: 'none'
    }
  },
  optionsList: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'normal',
    color: 'rgb(0, 0, 0)'
  }
}));
