import * as React from 'react';
import { DataGridPro as Grid, GridOverlay } from '@mui/x-data-grid-pro';
import { isEqual } from 'lodash';
import { useQuery } from '@apollo/client';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { snackbarOn } from 'redux/actions/globalActions';
import { useBuildHeroTableStyles } from 'components/XGrid/style';
import { ThemeProvider, Typography } from '@buildhero/sergeant';
import Skeleton from 'react-loading-skeleton';
import Toolbar from './Toolbar';

function CustomLoadingOverlay({ isFirstLoad, pageSize, rowHeight }) {
  return (
    <GridOverlay>
      <div
        style={{ position: 'absolute', top: 0, width: '100%', height: '100%', overflow: 'hidden' }}
      >
        <LinearProgress />
        {isFirstLoad && <Skeleton count={pageSize} height={rowHeight} />}
      </div>
    </GridOverlay>
  );
}

function CustomNoRowsOverlay({ entityTypeName }) {
  return (
    <GridOverlay>
      <Typography>No {entityTypeName} found</Typography>
    </GridOverlay>
  );
}

const useListQuery = (
  query,
  { tenantId, filter, sorting, pageSize, page, queryVariables },
  useItemsCountAsRowCount
) => {
  const {
    data: { data: { rowCount, items: rows } } = { data: {} },
    loading,
    error,
    refetch
  } = useQuery(query, {
    variables: {
      tenantId,
      filter,
      sorting,
      pagination: {
        limit: pageSize,
        offset: pageSize * page
      },
      ...queryVariables
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  let newRowCount = rowCount;
  if (useItemsCountAsRowCount) {
    newRowCount = rows ? rows.length : rowCount;
  }

  return { rowCount: newRowCount, rows, loading, error, refetch };
};

const XGrid = ({
  columns,
  defaultSort,
  query,
  user,
  snackbar,
  refetchTrigger,
  tableName, // Identifier for Table Views
  entityTypeName, // Label for the entity each row represents. Used in ViewsToolbarButton and NoRowsOverlay/NoResultsOverlay
  queryVariables = {},
  globalFilters = [],
  silentFilter = [], // will not showup in the filter modal but always appends the filter to the query. useful for tables under customer detail, sa details, etc
  enableRefreshBtn = false,
  enableExport = false,
  disableToolbar = false,
  onRefresh = async () => {},
  onRemoveGlobalFilters = async () => {},
  customComponent,
  toolbarCustomComponent,
  pageSize = 1000,
  headerHeight = 48,
  rowHeight = 30,
  getSelectedRowsRef,
  useItemsCountAsRowCount = false,
  tenantIdOverride = undefined, // to override the query's tenant ID. Used for system tenant activities
  ...rest
}) => {
  const tableClasses = useBuildHeroTableStyles();
  const [page, setPage] = React.useState(0);

  const appliedGlobalFilterIds = React.useRef([]);

  const [filterModel, setFilterModel] = React.useState({
    items: globalFilters,
    linkOperator: 'and'
  });
  const [defaultDensity, setDensity] = React.useState();
  const [sortModel, setSortModel] = React.useState(defaultSort || []);

  const [localColumns, setLocalColumns] = React.useState(columns);
  React.useEffect(() => {
    setLocalColumns(columns);
  }, [columns]);

  const { rowCount = 0, rows = [], loading, error, refetch } = useListQuery(
    query,
    {
      tenantId: tenantIdOverride ?? user.tenantId,
      filter: { ...filterModel, items: [...filterModel.items, ...silentFilter] },
      sorting: sortModel,
      pageSize,
      page,
      queryVariables
    },
    useItemsCountAsRowCount
  );

  React.useEffect(() => {
    const existingNonGlobalFilters = filterModel.items.filter(item => !item.id || item.id > 0);

    const newFilters = [...existingNonGlobalFilters, ...globalFilters];
    if (!isEqual(newFilters, filterModel.items)) {
      setFilterModel({
        ...filterModel,
        items: newFilters
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilters]);

  React.useEffect(() => {
    if (!loading) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchTrigger]);

  React.useEffect(() => {
    if (error)
      snackbar('error', `Unable to fetch ${entityTypeName}, please try again later`, error);
  }, [error, snackbar]);

  // Table Event Handlers
  const handlePageChange = React.useCallback(model => setPage(model), []);
  const onFilterChange = React.useCallback(model => setFilterModel(model), []);

  const handleSortModelChange = model => {
    if (model !== sortModel) setSortModel(model);
  };

  // ToolBar Functions
  const handleViewChange = view => {
    const { filters, meta, density, sort } = view;

    // Since a lot of column logic is done on the frontend and not
    // stored in the view, we need a map of the column meta for easy
    // look up
    const baseColumnMap = columns.reduce((acc, baseCol) => {
      return { ...acc, [baseCol.field]: { ...baseCol } };
    }, {});
    // We then use the map to fill the gaps for the columns loaded
    // from the views. The main aspects store in the view (for now)
    // are order, hide, and width
    const newColumns = meta.map(col => {
      const { field, hide, width } = col;
      const baseCol = { ...baseColumnMap[field] };
      if (hide) baseCol.hide = hide;
      if (width) baseCol.width = width;
      return baseCol;
    });
    setLocalColumns(newColumns);
    setDensity(density || 'standard');

    if (filters?.items) {
      const nonGlobalFilters = filters.items.filter(item => !item.id || item.id > 0);
      const newFilters = [...nonGlobalFilters, ...globalFilters];

      setFilterModel({ ...filters, items: newFilters });
    }
    if (sort && sort.length > 0) setSortModel(sort);
  };

  const defaultSetting = {
    filters: { items: [], linkOperator: 'and' },
    meta: columns,
    numberOfRows: pageSize,
    sort: defaultSort
  };

  if (getSelectedRowsRef) {
    // eslint-disable-next-line no-param-reassign
    getSelectedRowsRef.current = () =>
      rest?.selectionModel?.map(selectedId =>
        rows.find(item => !item.id || item.id === selectedId)
      );
  }

  return (
    <ThemeProvider>
      {customComponent && customComponent({ setFilterModel, filterModel })}
      <div style={{ display: 'flex', height: '100%', width: '100%' }}>
        <Grid
          className={tableClasses.root}
          columns={localColumns}
          rows={rows}
          components={{
            LoadingOverlay: CustomLoadingOverlay,
            NoRowsOverlay: CustomNoRowsOverlay,
            NoResultsOverlay: CustomNoRowsOverlay,
            Toolbar
          }}
          componentsProps={{
            loadingOverlay: { isFirstLoad: rows.length === 0, pageSize, rowHeight },
            toolbar: {
              tableName,
              entityTypeName,
              defaultSetting,
              handleViewChange,
              enableRefreshBtn,
              enableExport,
              refetch,
              onRefresh,
              disableToolbar,
              toolbarCustomComponent,
              rows
            },
            noRowsOverlay: { entityTypeName },
            noResultsOverlay: { entityTypeName }
          }}
          disableSelectionOnClick
          loading={loading}
          page={page}
          pageSize={pageSize}
          rowCount={rowCount} // Needs to be total rows in the DB
          rowHeight={rowHeight}
          density={defaultDensity}
          headerHeight={headerHeight}
          pagination
          disableColumnMenu
          paginationMode="server"
          onPageChange={handlePageChange}
          filterModel={filterModel}
          filterMode="server"
          onFilterModelChange={model => {
            onFilterChange(model);

            const newAppliedGlobalFilterIds = model.items.map(item => item.id).filter(id => id < 0);

            const removedGlobalFilters = appliedGlobalFilterIds.current.filter(
              id => !newAppliedGlobalFilterIds.includes(id)
            );
            if (removedGlobalFilters.length) {
              onRemoveGlobalFilters(removedGlobalFilters);
            }
            appliedGlobalFilterIds.current = newAppliedGlobalFilterIds;
          }}
          sortingMode="server"
          sortModel={sortModel}
          columnBuffer={localColumns.length}
          onSortModelChange={handleSortModelChange}
          {...rest}
        />
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = state => ({
  user: state.user
});
const mapDispatchToProps = { snackbar: snackbarOn };

const connectedXGrid = connect(mapStateToProps, mapDispatchToProps)(XGrid);

export default connectedXGrid;
