export const useStyles = () => ({
  container: theme => ({
    paddingLeft: theme.default.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  }),
  addButton: theme => ({
    marginTop: theme.default.spacing(2),
    marginBottom: theme.default.spacing(2),
    alignSelf: 'flex-end'
  })
});
