import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  stepper: {
    paddingLeft: 20,
    paddingRight: 20,
    flex: 1
  },
  cursor: {
    cursor: 'default'
  }
});

const StepsStatus = props => {
  const classes = useStyles();
  const { steps, currentStepIndex, onClickStep, clickable, furthestStepIndex, style } = props;

  return (
    <div className={classes.root}>
      <Stepper activeStep={currentStepIndex} className={classes.stepper} style={style}>
        {steps.map((label, index) => (
          <Step key={`procurement-steps-${label}`}>
            <StepButton
              disabled={index > furthestStepIndex}
              onClick={clickable ? () => onClickStep(index) : null}
              disableTouchRipple={!clickable}
              className={classNames(!clickable && classes.cursor)}
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

StepsStatus.propTypes = {
  steps: PropTypes.array.isRequired,
  currentStepIndex: PropTypes.number.isRequired,
  furthestStepIndex: PropTypes.number.isRequired,
  onClickStep: PropTypes.func,
  clickable: PropTypes.bool,
  style: PropTypes.object
};

StepsStatus.defaultProps = {
  onClickStep: () => {},
  clickable: true,
  style: {}
};

export default StepsStatus;
