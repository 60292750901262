import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import ErrorBoundaries from 'scenes/Error';
import { ResponsiveTable, PageHeader } from 'components';
import DefaultButton from 'components/Buttons/DefaultButton';
import { Logger } from 'services/Logger';
import { PermissionConstants } from 'utils/AppConstants';
import moment from 'moment';
import { CommonService } from 'services/core';
import AmplifyService from 'services/AmplifyService';
import { snackbarOn } from 'redux/actions/globalActions';
import { useTrigger } from 'customHooks/useTrigger';
import Filter from '../Maintenance/Filter';
import { getDueDateRangeFromFilter } from '../Maintenance/helpers';
import { bulkCreateInvoicesMutation, ACTIVE_SERVICE_AGREEMENTS_LIST_DATA } from './queries';
import ConfirmModal from './ConfirmModal';

function RecurringMaintenanceBillingView(props) {
  const { user, snackbar } = props;
  const [refresh, triggerRefresh] = useTrigger();
  const [quietRefresh, setQuietRefresh] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openConfirmModal, SetOpenConfirmModal] = useState(false);

  const commonService = useRef(new CommonService()).current;
  const getServiceAgreementsListData = async (columns, filter, sorting, pagination) => {
    const response = await commonService.getQuery(
      {
        columns,
        filter,
        sorting,
        pagination
      },
      ACTIVE_SERVICE_AGREEMENTS_LIST_DATA
    );
    return response.data.activeServiceAgreementsListData;
  };

  const handleClick = async records => {
    setIsSubmitting(true);
    setQuietRefresh(true);
    try {
      const appsyncClient = AmplifyService.appSyncClient();
      const serviceAgreementIds = records.map(record => record.id);
      const payload = {
        companyId: user.tenantCompanyId,
        serviceAgreementIds
      };
      const response = await appsyncClient.client.mutate({
        mutation: bulkCreateInvoicesMutation,
        variables: {
          data: payload
        }
      });
      if (response?.data) {
        snackbar('success', `Successfully created invoices.`);
        triggerRefresh();
        SetOpenConfirmModal({
          open: true,
          data: response.data?.bulkCreateInvoicesFromServiceAgreements
        });
      }
    } catch (error) {
      Logger.error(error);
      snackbar('error', error.message || `Unable to create invoices. Please try again.`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ErrorBoundaries>
      <PageHeader pageMapKey="recurringMaintenanceBilling" userLocale={props.user.locale} />
      <ResponsiveTable
        fullScreen
        refreshData={refresh} // To enforce re-fetch when invoices created
        refreshQuietly={quietRefresh} // Quiet refresh when invoices created
        tableName="Recurring Maintenance Billing"
        user={user}
        caslKey={PermissionConstants.OBJECT_SERVICE_AGREEMENT}
        noDataMsg="No Recurring Maintenance"
        listDataService={getServiceAgreementsListData}
        topPanel={({ filter, setFilter }) => {
          return (
            <Box style={{ float: 'left', marginTop: '28px' }}>
              <Filter
                onChangeDateRange={dateRange => {
                  setFilter({
                    ...filter,
                    nextBillDate: {
                      condition: 'between',
                      type: 'integerFilters',
                      value: [moment(dateRange.startDate).unix(), moment(dateRange.endDate).unix()]
                    }
                  });
                }}
                filterName="Select Billing Date Range"
                dueDateRange={getDueDateRangeFromFilter(filter)}
              />
            </Box>
          );
        }}
        rowActionButtons={{
          select: true
        }}
        actionPanel={selectedRecords => (
          <DefaultButton
            variant="containedPrimary"
            label="Create Invoices"
            onClick={() => handleClick(selectedRecords)}
            showSpinner={isSubmitting}
            disabled={!selectedRecords.length || isSubmitting}
          />
        )}
        timezoneSensitiveColumnIds={[
          'startDate',
          'endDate',
          'nextBillDate',
          'lastBilledDate',
          'firstBillDate',
          'renewalDate'
        ]}
      />
      <ConfirmModal
        open={openConfirmModal.open}
        onClose={() => {
          SetOpenConfirmModal({ open: false });
          setQuietRefresh(false);
        }}
        title="Invoices Created"
        data={openConfirmModal.data || []}
      />
    </ErrorBoundaries>
  );
}

RecurringMaintenanceBillingView.propTypes = {
  user: PropTypes.shape({
    locale: PropTypes.string
  }).isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps, { snackbar: snackbarOn })(RecurringMaintenanceBillingView);
