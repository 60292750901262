import { makeStyles } from '@material-ui/core';
import { DispatchTheme } from '@dispatch/Dispatch.styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2)
  },
  buttonGroup: {
    paddingLeft: theme.spacing(2),
    '& *': {
      textTransform: 'none',
      ...DispatchTheme.mixins.oneLineClamp
    }
  }
}));
