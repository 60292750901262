import gql from 'graphql-tag';

const addTag = gql`
  mutation addTag($partitionKey: String!, $data: AddTagInput!) {
    addTag(partitionKey: $partitionKey, data: $data) {
      id
      sortKey
      entityType
      version
    }
  }
`;

export default addTag;
