import React, { memo } from 'react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import { Grid, Typography } from '@material-ui/core';
import { ResponsiveTable, SergeantModal } from 'components';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import CallSplit from '@material-ui/icons/CallSplit';
import Warning from '@material-ui/icons/Warning';
import LocationOn from '@material-ui/icons/LocationOn';
import workTable from 'meta/Accounting/report/TimeTrackingWorkTable';
import DefaultButton from 'components/Buttons/DefaultButton';
import { Mode } from 'utils/constants';
import emptyCheck from 'utils/emptyCheck';
import TimeInput from './TimeInput';
import TimestampDetail from './TimestampDetail';
import HourTypeDropdown from './HourTypeDropdown';
import AssignmentInput from './AssignmentInput';
import TimestampType from './TimestampType';
import {
  checkShift,
  getCustomerInfo,
  getHourTypeLabel,
  calculateTime,
  checkOverlap,
  secondsToHour,
  checkHourTypeSelected
} from './helpers';
import { timeStampWarning, entryKeysToCheck, serviceErrorMessage } from './constants';
import { bulkUpdateTimesheetEntries } from './services';
import useStyles from './WorkTable.style';

const WorkTable = ({
  user,
  shifts,
  employee,
  isVisible,
  hourTypes,
  snackbarOn,
  refetch,
  dayStartUTC,
  hasEditPermission,
  onSelectPayrollHour,
  onChangePayrollHour,
  selectedPayrollHours,
  onTimesheetEntryEdit,
  fetchTimeSheetPeriods,
  onHandleTimestampAction,
  setLeaveConfirmation
}) => {
  const [showTimestampDetail, setShowTimestampDetail] = React.useState(false);
  const [timestampDetails, setTimestampDetail] = React.useState({});
  const [showLoader, setShowLoader] = React.useState(false);
  const [showOverLap, setShowOverLap] = React.useState(false);
  const [refreshCount, setRefreshCount] = React.useState(0);
  const [confirmModalSetting, setConfirmModalSetting] = React.useState({});

  const increaseRefreshCount = () => {
    setShowOverLap(false);
    setRefreshCount(refreshCount + 1);
  };

  const withOutCustomEntry = shifts.filter(({ isCustomEntry }) => !isCustomEntry);

  const classes = useStyles();
  if (!isVisible) return null;

  const timestampStateHandler = (detail, showDetail) => {
    setTimestampDetail(detail);
    setShowTimestampDetail(showDetail);
  };

  const handleViewLocation = detail => timestampStateHandler(detail, true);
  const handleCloseLocation = () => timestampStateHandler({}, false);

  const CustomTimeComponent = ({ meta, record, rowIndex }) => {
    const time = record[meta.editId] || record[meta.id];
    const handleTimeChange = date => {
      const dependantTime = record[meta.dependantEditId] || record[meta.dependantId];

      const value = date !== null ? calculateTime({ value: date, dayStartUTC }) : date;

      const floorToNearestMinute = t => Math.floor(t / 60) * 60;

      // update the dependant entry to the minute -.
      // this way two times that are seconds off will not result in a strange "-0.00" value
      // this will be unnessessary for new timevalue entries after BUOP-6021 since all time entries will be rounded to the minute on BE
      const dependantValue = floorToNearestMinute(dependantTime);
      onTimesheetEntryEdit(
        rowIndex,
        meta.editId,
        value,
        false,
        meta.dependantEditId,
        dependantValue
      );

      // splitting entries will make the actualStartTimeUTC & actualyEndTimeUTC null, and they must be set.
      if (record[meta.dependantId] === null) {
        onTimesheetEntryEdit(rowIndex, meta.dependantId, dependantValue);
      }
      if (record[meta.id] === null) {
        onTimesheetEntryEdit(rowIndex, meta.id, value);
      }
    };
    const { isCustomEntry = false } = record;
    const isShift = checkShift(record);

    const isEndTime = meta.locationIdPrefix === 'end';
    const invalidItem = [isCustomEntry, isShift && isEndTime].some(Boolean);

    let isOverlap = false;

    if (!invalidItem) {
      isOverlap = checkOverlap({
        shifts,
        rowIndex,
        isEndTime,
        compareValue: time
      });
    }

    if (isOverlap && !showOverLap) {
      setShowOverLap(true);
    }

    return (
      <TimeInput
        value={time ? moment.unix(time) : null}
        isOverlap={isOverlap}
        startAdornment={
          <LocationOn
            onClick={() => handleViewLocation({ ...record, meta })}
            className={classes.icon}
          />
        }
        name={meta.id}
        hasEditPermission={hasEditPermission && !isShift}
        InputProps={{ onBlur: () => increaseRefreshCount() }}
        onChange={handleTimeChange}
      />
    );
  };

  return (
    <Box
      p={3}
      display="flex"
      paddingTop={1}
      paddingBottom={1}
      flexDirection="column"
      style={{ width: '100%' }}
    >
      <ResponsiveTable
        noMaxHeight
        disablePagination
        rowMetadata={workTable}
        data={shifts.sort((shiftA, shiftB) => {
          const shiftAStart = Number.isInteger(shiftA.actualStartTimeOverrideUTC)
            ? shiftA.actualStartTimeOverrideUTC
            : shiftA.actualStartTimeUTC;

          const shiftBStart = Number.isInteger(shiftB.actualStartTimeOverrideUTC)
            ? shiftB.actualStartTimeOverrideUTC
            : shiftB.actualStartTimeUTC;

          const shiftAEnd = Number.isInteger(shiftA.actualEndTimeOverrideUTC)
            ? shiftA.actualEndTimeOverrideUTC
            : shiftA.actualEndTimeUTC;

          const shiftBEnd = Number.isInteger(shiftB.actualEndTimeOverrideUTC)
            ? shiftB.actualEndTimeOverrideUTC
            : shiftB.actualEndTimeUTC;

          if (shiftAStart === null && shiftBStart === null) {
            // both of these shifts were generated by spliting an entry.
            // sort the by their shift end value;
            return shiftAEnd - shiftBEnd;
          }

          if (shiftAStart === null) {
            // shiftA was generated by spliting an entry.
            // sort it by the shiftEnd, but subtract 1 so that it comes before shifts that start at the same time
            return shiftAEnd - shiftBStart - 1;
          }

          if (shiftBStart === null) {
            // shiftB was generated by spliting an entry.
            // sort it by the shiftEnd, but add 1 so that it comes before shifts that start at the same time
            return shiftAStart - shiftBEnd + 1;
          }

          if (shiftA.customLabel === 'Shift End' && shiftB.customLabel !== 'Shift End') {
            if (shiftAStart === shiftBStart && shiftBEnd > shiftAEnd) {
              // start of next shift should come after end of previous shift
              return -1;
            }
            return shiftAEnd - shiftBStart + 1; // + 1 since we want shift end to come after shift start in case they have the same times
          }
          if (shiftA.customLabel !== 'Shift End' && shiftB.customLabel === 'Shift End') {
            if (shiftAStart === shiftBStart && shiftAEnd > shiftBEnd) {
              // start of next shift should come after end of previous shift
              return 1;
            }
            return shiftAStart - shiftBEnd - 1; // - 1 since we want shift end to come after shift start in case they have the same times
          }

          return shiftAStart - shiftBStart;
        })}
        key={refreshCount}
        customCellComponents={{
          CustomTimeComponent,
          TimestampType,
          Dropdown: ({ record, rowIndex }) => {
            const isShift = checkShift(record);
            if (isShift) return '-';
            const isSelected = checkHourTypeSelected(selectedPayrollHours, rowIndex);
            return (
              <HourTypeDropdown
                items={hourTypes}
                isSelected={isSelected}
                showSelectedLabel={record.isCustomEntry}
                increaseRefreshCount={() => increaseRefreshCount()}
                label={getHourTypeLabel(hourTypes, record.hourTypeId)}
                onSelect={status => onSelectPayrollHour({ status, rowIndex })}
                onChange={value => onChangePayrollHour({ value, rowIndex })}
                hasEditPermission={hasEditPermission}
              />
            );
          },
          Delete: ({ rowIndex, record }) => {
            if (record.isCustomEntry || !hasEditPermission) return '-';
            return (
              <DeleteOutlined
                className={classes.deleteIcon}
                onClick={() =>
                  setConfirmModalSetting({
                    open: true,
                    data: record,
                    handlePrimaryAction: (data, stopSpinning) => {
                      onHandleTimestampAction(rowIndex, 'delete', record);
                      setConfirmModalSetting({ open: false });
                      stopSpinning();
                    },
                    layout: null
                  })
                }
              />
            );
          },
          Search: ({ meta, record, rowIndex }) => {
            const handleOnChange = ({ id, type: billableEntityType, assignmentDetail }) => {
              const value = { [meta.id]: id, billableEntityType, assignmentDetail };
              onTimesheetEntryEdit(rowIndex, meta.id, value, true);
              increaseRefreshCount();
            };
            return (
              <div className={classes.searchBox}>
                <AssignmentInput
                  record={record}
                  onChange={handleOnChange}
                  hasEditPermission={!record.isCustomEntry && hasEditPermission}
                />
              </div>
            );
          },
          TotalHours: ({ record }) => {
            const { actualTotalDurationOverride, actualTotalDuration } = record;

            const hour = secondsToHour(
              Number.isInteger(actualTotalDurationOverride)
                ? actualTotalDurationOverride
                : actualTotalDuration
            );

            const isShift = checkShift(record);
            return (
              <Typography className={classes.totalHours} variant="body2">
                {isShift ? '-' : hour}
              </Typography>
            );
          },
          CustomerName: ({ record }) => getCustomerInfo(record),
          CustomerProperty: ({ record }) => getCustomerInfo(record, true),
          SplittedIcon: ({ record: { isSplitted = false } }) =>
            isSplitted && <CallSplit className={classes.splitIcon} />,
          SplitButton: ({ rowIndex, record }) => {
            if (record.isCustomEntry || !hasEditPermission) return '-';
            return (
              <DefaultButton
                variant="outlined"
                color="secondary"
                disabled={record.actualStartTimeUTC === null || record.actualEndTimeUTC === null}
                buttonProps={{
                  className: classes.splitButton,
                  endIcon: <CallSplit className={classes.splitIcon} />
                }}
                handle={() => {
                  onHandleTimestampAction(rowIndex, 'split');
                  increaseRefreshCount();
                }}
                label="SPLIT"
              />
            );
          }
        }}
        noDataMsg="No items"
        noEmptyRows
        disableFilter
      />
      <TimestampDetail
        detail={timestampDetails}
        onClose={handleCloseLocation}
        isVisible={showTimestampDetail}
      />
      {showOverLap && (
        <Typography className={classes.warningMessage} variant="body2">
          <Warning /> {timeStampWarning}
        </Typography>
      )}
      <Grid className={classes.saveAction} container justify="center" alignItems="center">
        <DefaultButton
          variant="outlined"
          color="secondary"
          showSpinner={showLoader}
          spinnerProps={{ styles: { margin: 0, marginLeft: 5 } }}
          disabled={!hasEditPermission || shifts.length === 0}
          handle={async () => {
            if (emptyCheck(withOutCustomEntry, entryKeysToCheck)) {
              return snackbarOn('error', serviceErrorMessage.emptyTimes);
            }
            await bulkUpdateTimesheetEntries({
              data: shifts,
              employeeId: employee.id,
              snackbarOn,
              partitionKey: user.partitionKey,
              successCallback: refetch,
              setShowLoader
            });
            setLeaveConfirmation(false);
            await fetchTimeSheetPeriods({ updateDateApproval: true });
          }}
          label="Save Changes"
        />
      </Grid>
      <SergeantModal
        layout={null}
        open={false}
        dataType="Entry"
        mode={Mode.DELETE}
        handlePrimaryAction={() => {}}
        {...confirmModalSetting}
        handleClose={() => setConfirmModalSetting({})}
      />
    </Box>
  );
};

WorkTable.propTypes = {
  dayStartUTC: PropTypes.number.isRequired,
  isVisible: PropTypes.bool,
  shifts: PropTypes.array,
  user: PropTypes.object.isRequired,
  snackbarOn: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  employee: PropTypes.object.isRequired,
  hourTypes: PropTypes.array.isRequired
};

WorkTable.defaultProps = {
  isVisible: false,
  shifts: []
};

function areEqual(prevProps, nextProps) {
  return isEqual(JSON.stringify(prevProps), JSON.stringify(nextProps));
}

export default memo(WorkTable, areEqual);
