const attachmentLayout = {
  entity: {
    name: 'Attachment',
    tenant: 'default',
    layouts: {
      web: {
        sections: [
          {
            title: 'Add attachment',
            sm: '12',
            md: '12',
            lg: '12',
            rows: [
              {
                spacing: '3',
                cols: [
                  {
                    type: 'dbField',
                    value: 'customFileName',
                    label: 'customFileName',
                    name: 'customFileName',
                    behavior: {
                      new: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '3'
                      },
                      edit: {
                        ui: 'TextInput',
                        sm: '12',
                        md: '6',
                        lg: '3'
                      }
                    }
                  },
                  {
                    type: 'dbField',
                    value: 'fileName',
                    label: 'fileName',
                    name: 'fileName',
                    behavior: {
                      new: {
                        ui: 'Attachment',
                        sm: '12',
                        md: '6',
                        lg: '9'
                      },
                      edit: {
                        ui: 'Attachment',
                        sm: '12',
                        md: '6',
                        lg: '9'
                      }
                    }
                  },
                  {
                    type: 'dbHidden',
                    value: 'fileUrl',
                    label: 'id',
                    name: 'fileUrl',
                    behavior: {
                      edit: {
                        ui: 'Hidden',
                        sm: '1',
                        md: '1',
                        lg: '1'
                      }
                    }
                  },
                  {
                    type: 'dbHidden',
                    value: 'id',
                    label: 'id',
                    name: 'id',
                    behavior: {
                      new: {
                        ui: 'Hidden',
                        sm: '1',
                        md: '1',
                        lg: '1'
                      },
                      edit: {
                        ui: 'Hidden',
                        sm: '1',
                        md: '1',
                        lg: '1'
                      }
                    }
                  }
                ]
              },
              {
                spacing: '1',
                cols: [
                  {
                    type: 'dbField',
                    value: 'comment',
                    label: 'attachmentComments',
                    name: 'comment',
                    behavior: {
                      new: {
                        ui: 'TextArea',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      },
                      edit: {
                        ui: 'TextArea',
                        sm: '12',
                        md: '12',
                        lg: '12'
                      }
                    }
                  }
                ]
              }
            ]
          }
        ],
        buttons: {
          save: {
            label: 'Save',
            color: 'primary',
            buttonType: 'contained',
            behavior: {
              new: true,
              edit: true
            }
          }
        }
      }
    }
  }
};
export default attachmentLayout;

export const attachmentRows = (flags, hasShareWithTechsOption, hasInternalFileOption) =>
  [
    {
      id: 'fileName',
      numeric: false,
      disablePadding: true,
      label: 'File name',
      type: 'attachment',
      other: 'fileUrl'
    },
    {
      id: 'comment',
      numeric: false,
      disablePadding: true,
      label: 'Description',
      maxTextLen: 180,
      headerStyle: {
        width: '35%'
      }
    },
    {
      id: 'createdDate',
      numeric: false,
      type: 'dateOnly',
      disablePadding: true,
      label: 'Date added'
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: true,
      label: 'Added by'
    },
    hasShareWithTechsOption && {
      id: 'shareWithTechniciansOnMobile',
      numeric: false,
      disablePadding: true,
      label: 'Shared With Techs'
    },
    hasInternalFileOption && {
      id: 'internalFile',
      numeric: false,
      disablePadding: true,
      label: 'Internal File'
    }
  ].filter(Boolean);

export const receiptRows = [
  {
    id: 'visit',
    numeric: false,
    disablePadding: true,
    label: 'File name',
    type: 'attachment',
    other: 'fileUrl'
  },
  {
    id: 'receiptNumber',
    numeric: false,
    disablePadding: true,
    label: 'Receipt Number'
  },
  {
    id: 'createdBy',
    numeric: false,
    disablePadding: true,
    label: 'Created by'
  },
  {
    id: 'poNumber',
    numeric: false,
    disablePadding: true,
    label: 'PO Number'
  },
  {
    id: 'dateOfPurchase',
    numeric: false,
    disablePadding: true,
    label: 'Date Issued',
    type: 'dateOnly'
  },
  {
    id: 'total',
    numeric: false,
    disablePadding: true,
    label: 'Total'
  },
  {
    id: 'addedBy',
    numeric: false,
    disablePadding: true,
    label: 'Added by'
  },
  {
    id: 'comment',
    numeric: false,
    disablePadding: true,
    label: 'Comment'
  }
  // {
  //   id: "notesCount",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Notes"
  // }
];

export const purchaseRecieptRows = [
  {
    id: 'visitNumber',
    numeric: false,
    disablePadding: true,
    label: 'Visit'
  },
  {
    id: 'receiptNumber',
    isCustom: true,
    type: 'viewImage',
    disablePadding: true,
    label: 'Receipt Number'
  },
  {
    id: 'vendorName',
    numeric: false,
    disablePadding: true,
    label: 'Vendor'
  },
  {
    id: 'poNumber',
    numeric: false,
    disablePadding: true,
    label: 'PO Number'
  },
  {
    id: 'dateOfPurchase',
    numeric: false,
    disablePadding: true,
    label: 'Date Issued',
    type: 'date'
  },
  {
    id: 'totalAmountPreTax',
    numeric: false,
    disablePadding: true,
    label: 'Total',
    type: 'currency'
  },
  {
    id: 'addedBy',
    numeric: false,
    disablePadding: true,
    label: 'Added by'
  }
  // {
  //   id: "notesCount",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Notes"
  // }
];
