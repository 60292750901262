import theme from 'themes/BuildHeroTheme';

// @TODO - awaiting feedback - from Design from Haiyun for setting category 2 row 2

export const serviceAgreementSetting = isTotalPercentageError => ({
  fields: {},
  layouts: {
    default: {
      options: {
        pageSize: 'LETTER',
        grow: 0
      },
      contents: [
        // setting category 1 title
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    color: theme.palette.secondary.main,
                    fontWeight: 'bold',
                    fontSize: 14
                  },
                  component: 'BodyText',
                  source: 'All Visit Creation'
                }
              ]
            }
          ]
        },
        // setting category 1 row 1
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0,
            paddingBottom: 75,
            borderBottom: `1px solid ${theme.palette.grayscale(80)}`
          },
          contents: [
            {
              options: {
                direction: 'column',
                padding: [12, 0],
                width: '50%'
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '80%',
                    justify: 'space-between'
                  },
                  contents: [
                    {
                      options: {
                        fontSize: 20,
                        marginTop: 5 // to align the text in middle of the switch
                      },
                      component: 'BodyText',
                      source: 'Preferred Technician Selection at Maintenance Template Level'
                    },
                    {
                      component: {
                        view: 'ReadOnlyCheckboxInput',
                        default: 'CheckboxInput'
                      },
                      source: 'enableTechnicianSelectionAtMaintenanceTemplateLevel',
                      options: {
                        checked: false,
                        value: '',
                        fullWidth: true,
                        labelPlacement: 'start',
                        displayAsSwitch: true
                      }
                    }
                  ]
                }
              ]
            },
            {
              options: {
                direction: 'column',
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '335px'
                  },
                  contents: [
                    {
                      options: {
                        color: theme.palette.grayscale(60)
                      },
                      component: 'BodyText',
                      source: `
                        By default, preferred technicians are determined at the
                        agreement level and applied to all maintenance templates.
                        Enabling this feature will allow setting preferred technicians
                        at the maintenance template level for auto, bulk, and manual visit creation.
                      `
                    }
                  ]
                }
              ]
            }
          ]
        },
        // setting category 2 title
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    color: theme.palette.secondary.main,
                    fontWeight: 'bold',
                    fontSize: 14
                  },
                  component: 'BodyText',
                  source: 'Auto Visit Creation'
                }
              ]
            }
          ]
        },
        // setting category 2 row 1
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: [12, 0, 0, 0],
                width: '50%'
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '80%',
                    justify: 'space-between'
                  },
                  contents: [
                    {
                      options: {
                        fontSize: 20,
                        marginTop: 5 // to align the text in middle of the switch
                      },
                      component: 'BodyText',
                      source: 'Enable Auto Visit Creation'
                    },
                    {
                      component: {
                        default: 'CheckboxInput',
                        view: 'ReadOnlyCheckboxInput'
                      },
                      source: 'enableAutoVisitCreation',
                      options: {
                        checked: false,
                        value: '',
                        fullWidth: true,
                        labelPlacement: 'start',
                        displayAsSwitch: true
                      }
                    }
                  ]
                }
              ]
            },
            {
              contents: [
                {
                  component: null
                }
              ]
            }
          ]
        },
        // setting category 2 row 2
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0,
            borderBottom: `1px solid ${theme.palette.grayscale(80)}`
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: [0, 0, 12, 0],
                width: '50%'
              },
              contents: [
                {
                  options: {
                    direction: 'column',
                    grow: 1,
                    padding: [12, 0]
                  },
                  contents: [
                    {
                      component: 'RadioButtonsGroup',
                      source: 'autoDateAssignment',
                      options: {
                        btnDirection: 'Horizontal',
                        inputOptions: `Do Not Assign a Date, Assign Maintenance Due Date`,
                        isRequired: false,
                        fullWidth: true,
                        alignment: 'left',
                        disabled: false,
                        // label styles are array because it gets stripped when forms are processed for finding contents []
                        labelStyle: [{ fontSize: 20, color: theme.palette.grayscale(20) }]
                      },
                      dependentField: {
                        fieldName: 'enableAutoVisitCreation',
                        expectedValue: false,
                        operation: 'bool',
                        action: 'DISABLE'
                      }
                    }
                  ]
                }
              ]
            },
            {
              options: {
                direction: 'column',
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '335px'
                  },
                  contents: [
                    {
                      options: {
                        color: theme.palette.grayscale(60)
                      },
                      component: 'BodyText',
                      source: `Auto-create maintenance visits without
                      assigning a date or create them with a date that
                      is based on the maintenance due dates.
                      Example: If budgeted hours per visit equals 3,
                      estimated # of visits equals 2, and due date is Dec 20, 2020,
                      two 3 hour visits will be generated.
                      One on Dec 20th and one on Dec 19th.`
                    }
                  ]
                }
              ]
            }
          ]
        },
        // setting category 3 title
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    color: theme.palette.secondary.main,
                    fontWeight: 'bold',
                    fontSize: 14
                  },
                  component: 'BodyText',
                  source: 'Bulk Visit Creation'
                }
              ]
            }
          ]
        },
        // setting category 3 row 1
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0,
            paddingBottom: 75,
            borderBottom: `1px solid ${theme.palette.grayscale(80)}`
          },
          contents: [
            {
              options: {
                direction: 'column',
                padding: [12, 0],
                width: '50%'
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '80%',
                    justify: 'space-between'
                  },
                  contents: [
                    {
                      options: {
                        fontSize: 20,
                        marginTop: 5 // to align the text in middle of the switch
                      },
                      component: 'BodyText',
                      source: 'Bulk Visit Creation for Scheduled Maintenances'
                    },
                    {
                      component: {
                        view: 'ReadOnlyCheckboxInput',
                        default: 'CheckboxInput'
                      },
                      source: 'enableBulkVisitCreation',
                      options: {
                        checked: false,
                        value: '',
                        fullWidth: true,
                        labelPlacement: 'start',
                        displayAsSwitch: true
                      }
                    }
                  ]
                }
              ]
            },
            {
              options: {
                direction: 'column',
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '335px'
                  },
                  contents: [
                    {
                      options: {
                        color: theme.palette.grayscale(60)
                      },
                      component: 'BodyText',
                      source: `
                        Enabling this feature will allow users to add
                        visits for scheduled maintenances
                      `
                    }
                  ]
                }
              ]
            }
          ]
        },
        // setting category 4 title
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    color: theme.palette.secondary.main,
                    fontWeight: 'bold',
                    fontSize: 14
                  },
                  component: 'BodyText',
                  source: 'New Job'
                }
              ]
            }
          ]
        },
        // setting category 4 row 1
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0,
            paddingBottom: 75,
            borderBottom: `1px solid ${theme.palette.grayscale(80)}`
          },
          contents: [
            {
              options: {
                direction: 'column',
                padding: [12, 0],
                width: '50%'
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '80%',
                    justify: 'space-between'
                  },
                  contents: [
                    {
                      options: {
                        fontSize: 20,
                        marginTop: 5 // to align the text in middle of the switch
                      },
                      component: 'BodyText',
                      source: 'Auto-populate service agreement field for new jobs'
                    },
                    {
                      component: {
                        view: 'ReadOnlyCheckboxInput',
                        default: 'CheckboxInput'
                      },
                      source: 'populateSAForNewJob',
                      options: {
                        checked: false,
                        value: '',
                        fullWidth: true,
                        labelPlacement: 'start',
                        displayAsSwitch: true
                      }
                    }
                  ]
                }
              ]
            },
            {
              options: {
                direction: 'column',
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '335px'
                  },
                  contents: [
                    {
                      options: {
                        color: theme.palette.grayscale(60)
                      },
                      component: 'BodyText',
                      source: `
                        When this setting is enabled,
                        any time when a Job is created for a Property that has a single active Service Agreement associated with it,
                        the Service Agreement field is automatically populated for the Job along with the Pricebook attached to the Service Agreement
                        (if one exists).
                        When the setting is off, Service Agreement field is not automatically populated.
                      `
                    }
                  ]
                }
              ]
            }
          ]
        },
        // setting category 5 title
        {
          options: {
            size: 1,
            direction: 'row',
            grow: 0
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    color: theme.palette.secondary.main,
                    fontWeight: 'bold',
                    fontSize: 14
                  },
                  component: 'BodyText',
                  source: 'Recurring Maintenance Billing'
                }
              ]
            }
          ]
        },
        // setting category 5 row 1
        {
          options: {
            size: 2,
            direction: 'row',
            grow: 0,
            borderBottom: `1px solid ${theme.palette.grayscale(80)}`
          },
          contents: [
            {
              options: {
                direction: 'column',
                grow: 1,
                padding: [0, 0, 12, 0],
                width: '50%'
              },
              contents: [
                {
                  options: {
                    direction: 'column',
                    grow: 1,
                    padding: [12, 0]
                  },
                  contents: [
                    {
                      component: 'RecurringMaintenanceBilling',
                      source: 'recurringMaintenanceBillingItems',
                      options: {
                        isTotalPercentageError
                      }
                    }
                  ]
                }
              ]
            },
            {
              options: {
                direction: 'column',
                padding: [12, 0]
              },
              contents: [
                {
                  options: {
                    direction: 'row',
                    width: '335px'
                  },
                  contents: [
                    {
                      options: {
                        color: theme.palette.grayscale(60)
                      },
                      component: 'BodyText',
                      source: `Determine how a service agreement’s term price will be divided into 
                      line items on an invoice. Add and select line items and allocate a percentage 
                      of the term price towards each line item.`
                    }
                  ]
                },
                {
                  options: {
                    direction: 'row',
                    width: '335px'
                  },
                  contents: [
                    {
                      options: {
                        color: theme.palette.grayscale(60)
                      },
                      component: 'BodyText',
                      source: `Note: Total Percentage Allocated must be 100%.`
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  }
});

export default serviceAgreementSetting;
