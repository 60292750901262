import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { connect, useSelector } from 'react-redux';
import {
  Divider,
  InlineAlert,
  InlineAlertTypes,
  SgtForm,
  ThemeProvider,
  Typography,
  TV
} from '@buildhero/sergeant';
import {
  UserPermission,
  PageHeader,
  StatusChip,
  SyncStatus,
  ConfirmLeave,
  TagButtons,
  EmailStatus
} from 'components';
import { PermissionConstants, TagType } from 'utils/AppConstants';
import ErrorBoundary from 'scenes/Error';
import { checkPermission } from 'utils';
import { EnumType, NetworkStatuses, Mode, AccountingApp } from 'utils/constants';
import { snackbarOn } from 'redux/actions/globalActions';
import { useTheme, Box } from '@material-ui/core';
import useAutosaveManager from 'customHooks/useAutosaveManager';
import { cloneDeep, isEqual } from 'lodash';
import CloudIcon from 'components/CloudIcon';
import getInvoiceLayout from './InvoiceConfiguration';
import InvoiceSetting from './InvoiceSetting';
import InvoiceInfoPanel, { normalizePropsFromInvoiceData } from './InvoiceInfoPanel';
import {
  useInvoiceQuery,
  useInvoiceUpdate,
  useInvoiceSubscription,
  useMutateInvoiceTags
} from './InvoiceDetail.gql';
import { calculateTotals, formatChangesForUpdate } from './InvoiceDetail.utils';
import validationSchema from './InvoiceDetail.validation';
import useInvoiceItems from './InvoiceItem/InvoiceItem.hook';
import { Action } from './InvoiceDetail.constants';
import {
  ActivityList,
  InvoiceActions,
  EditAddressButton,
  CustomerSignaturesDisplay,
  CustomerSignaturesDisplayPDF,
  Refunds
} from './components';
import useStyle from './InvoiceDetail.style';
import useUserFriendlyError from 'customHooks/useUserFriendlyError';

const SgtFormWrapper = React.memo(SgtForm);

function InvoiceDetail({ user, snackbar, computedMatch }) {
  const { palette, spacing } = useTheme();
  const [formService, setFormService] = useState();
  const formServiceRef = useRef();
  const [initialValues, setInitialValues] = useState();
  const [submitting, setSubmitting] = useState(false);

  const { loading: initializingData, error: initializingError, data, refetch } = useInvoiceQuery(
    computedMatch.params.id
  );
  const { loading: loadingUserFriendlyMessage, userFriendlyErrorMessage } = useUserFriendlyError(
    data?.tenantId,
    data?.syncLog
  );

  useInvoiceSubscription(user.tenantId, computedMatch.params.id);
  const [updateInvoice, updating] = useInvoiceUpdate(data?.id);
  const [mutateInvoiceTags, mutatingInvoiceTags] = useMutateInvoiceTags(data?.id);

  const { autosave, status, setHasTempData, confirmLeave } = useAutosaveManager({
    update: updateInvoice,
    initialVersion: data?.version,
    entityName: 'invoice',
    formatChanges: formatChangesForUpdate,
    destructureUpdateResult: result => result?.data?.updateInvoice,
    snackbarOn: snackbar
  });

  const blockInteractions = useMemo(
    () => initializingData || status === NetworkStatuses.RETRYING || submitting,
    [initializingData, status, submitting]
  );

  const {
    loading: mutatingInvoiceItem,
    handleInvoiceItemChange,
    InvoiceItemModal
  } = useInvoiceItems(data, formServiceRef);

  const userCanUpdateInvoice = checkPermission('edit', PermissionConstants.OBJECT_INVOICE, user);

  const configuration = useMemo(
    () =>
      data ? getInvoiceLayout(data, handleInvoiceItemChange, userCanUpdateInvoice) : undefined,
    [data?.status, userCanUpdateInvoice]
  );
  const accountingApp = useSelector(state => state.settings?.accountingApp);
  const isCanadaCompany = useSelector(
    state => state.settings?.accountingAppSettings?.setting?.isNonUSACompany
  );
  const isCanadaQuickbooksEnabled = isCanadaCompany && accountingApp === AccountingApp.QUICKBOOKS;
  const sgtForm = useMemo(
    () =>
      initializingData || !initialValues ? (
        <Skeleton height={900} />
      ) : (
        <SgtFormWrapper
          layout="default"
          configuration={configuration}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onCreateService={service => {
            setFormService(service);
            formServiceRef.current = service;
          }}
          onFieldChange={(key, _, { values, setValues }) => {
            setHasTempData(true);
            if (
              [
                'laborItems',
                'partsAndMaterials',
                'discountsAndFees',
                'taxRate',
                'amountPaid'
              ].includes(key)
            ) {
              setValues({
                ...values,
                ...calculateTotals(
                  [values.laborItems, values.partsAndMaterials, values.discountsAndFees].flat(),
                  values.taxRate?.taxRate,
                  values.amountPaid,
                  values.adjustedAmount,
                  isCanadaQuickbooksEnabled,
                  data?.syncStatus,
                  data?.totalAmount
                )
              });
            }
          }}
          onSaveOnChange={(changes, { values }) => autosave({ changes, values })}
          formikProps={{ validateOnMount: true, enableReinitialize: true }}
          customComponents={{
            EditAddressButton,
            CustomerSignaturesDisplay,
            CustomerSignaturesDisplayPDF
          }}
        />
      ),
    [initialValues, configuration, initializingData]
  );

  const infoPanel = useMemo(
    () =>
      initializingData || !initialValues ? (
        <Skeleton height={900} />
      ) : (
        <InvoiceInfoPanel {...normalizePropsFromInvoiceData(data)} />
      ),
    [initialValues, data, initializingData]
  );

  const settings = useRef();
  useEffect(() => {
    // force refresh on payment & adjustment updates
    if (
      (!initialValues && data) ||
      !isEqual(data?.payments, initialValues?.payments) ||
      data?.adjustedAmount !== initialValues?.adjustedAmount
    ) {
      setInitialValues(cloneDeep(data));
      settings.current = data.settings;
    }
  }, [data]);

  const handleSettingsSave = useCallback(
    (_, { values }) =>
      autosave({
        changes: { settingsJSON: JSON.stringify(values) },
        values
      }),
    [autosave]
  );

  const handleSettingsChange = useCallback(
    (_, { values }) => {
      setHasTempData(true);
      settings.current = values;
    },
    [setHasTempData]
  );

  const latestData = formService
    ? {
        ...formService.formikContext.values,
        status: data.status
      }
    : data;
  // for vista, every invoice item requires jc contract item.
  const isMissingJcContractItems =
    accountingApp === AccountingApp.VISTA &&
    latestData &&
    [
      ...latestData.laborItems,
      ...latestData.partsAndMaterials,
      ...latestData.discountsAndFees
    ].some(i => !i.jcContractItem?.value);

  const styles = useStyle();

  if (initializingError) return <>Error loading data.</>;
  return (
    <UserPermission I="read" action={PermissionConstants.OBJECT_INVOICE}>
      <ErrorBoundary>
        <ConfirmLeave when={confirmLeave || mutatingInvoiceItem || mutatingInvoiceTags} />
        {InvoiceItemModal}
        <InvoiceSetting
          onSettingsSave={handleSettingsSave}
          onSettingsChange={handleSettingsChange}
          settings={data?.settings}
          blockInteractions={blockInteractions || !userCanUpdateInvoice}
        />
        <PageHeader
          pageMapKey="invoices"
          title={`Invoice ${data?.invoiceNumber ?? ''}`}
          breadcrumbsArray={[
            { title: 'Accounting', link: '' },
            { title: 'Invoices', link: '/invoice/list' }
          ]}
          userLocale={user.locale}
          headerContainerStyle={data?.refunds && { marginBottom: 0 }}
          additionalTitleComponents={
            initializingData ? (
              <Skeleton height={32} width={120} />
            ) : (
              <>
                <StatusChip
                  label={data?.status}
                  enumType={EnumType.INVOICE_STATUS}
                  enumValue={data?.status}
                />
                {userCanUpdateInvoice ? (
                  <CloudIcon status={mutatingInvoiceTags ? NetworkStatuses.UPDATING : status} />
                ) : (
                  <InlineAlert
                    type={InlineAlertTypes.BLUE}
                    message="You do not have permission to update invoices."
                  />
                )}
                <TagButtons
                  readonly={!userCanUpdateInvoice}
                  info={{
                    id: data?.id,
                    version: data?.version
                  }}
                  tags={data?.invoiceInvoiceTags?.items}
                  getService={() => ({
                    // trigger mutation to update algolia search
                    updateInvoice: (_, payload) => updateInvoice(payload),
                    updateInvoiceTags: mutateInvoiceTags(Mode.ADD),
                    deleteInvoiceInvoiceTag: mutateInvoiceTags(Mode.DELETE)
                  })}
                  TagType={TagType.INVOICE}
                />
              </>
            )
          }
        >
          <InvoiceActions
            data={latestData}
            settings={settings}
            initializingData={initializingData}
            blockInteractions={blockInteractions}
            updateInvoice={updateInvoice}
            accountingApp={data?.accountingApp}
            formService={formService}
            refetch={refetch}
            userCanUpdateInvoice={userCanUpdateInvoice}
            onActionStart={action => {
              if (action === Action.POST) {
                setSubmitting(true);
              }
            }}
            onActionComplete={action => {
              if (action === Action.POST) {
                setSubmitting(false);
              }
            }}
            isMissingJcContractItems={isMissingJcContractItems}
          />
        </PageHeader>
        <ThemeProvider>
          <Refunds refunds={data?.refunds} />
          <Divider
            marginLeft={-spacing(3)}
            width={`calc(100% + ${spacing(6)}px)`}
            marginBottom={0}
          />
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            style={blockInteractions ? { cursor: 'progress' } : undefined}
          >
            <Box css={styles.leftInfoPanelContainer}>{infoPanel}</Box>
            <Box css={styles.centreContainer}>
              <Box
                style={blockInteractions ? { pointerEvents: 'none' } : undefined}
                css={styles.invoiceFormContainer}
              >
                {sgtForm}
              </Box>
              <Box css={styles.syncStatusContainer}>
                {!initializingData && (
                  <EmailStatus
                    status={data?.latestEmail?.status}
                    openCount={data?.latestEmail?.openCount}
                    bouncedEmails={data?.latestEmail?.bouncedEmails}
                  />
                )}
                {data?.accountingApp && !loadingUserFriendlyMessage && (
                  <SyncStatus
                    locale={user.locale}
                    syncStatus={data.syncStatus ?? undefined}
                    syncLog={data.syncLog}
                    userFriendlyErrorMessage={userFriendlyErrorMessage}
                  />
                )}
                {isMissingJcContractItems && (
                  <Typography
                    variant={TV.S1}
                    color={palette.error.main}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <ErrorOutlineIcon style={{ fontSize: 16, marginRight: 4 }} />
                    <div>
                      <b>Action Required: </b> JC Contract Item is required for all invoice items.
                    </div>
                  </Typography>
                )}
              </Box>
              <Box css={styles.activityListContainer}>
                <ActivityList loading={initializingData} data={data} />
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </ErrorBoundary>
    </UserPermission>
  );
}

export default connect(state => ({ user: state.user }), { snackbar: snackbarOn })(InvoiceDetail);
