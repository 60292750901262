import gql from 'graphql-tag';

const getAdjustmentTypes = gql`
  query getCompany($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      adjustmentTypes(
        entityConnection: "AdjustmentType"
        filter: { booleanFilters: { fieldName: "isActive", filterInput: { eq: true } } }
      ) {
        items {
          id
          name
          type
          ledgerAccountId
          ledgerAccount(entityConnection: "LedgerAccount") {
            name
          }
          ledgerOffsetAccountId
          ledgerOffsetAccount(entityConnection: "LedgerAccount") {
            name
          }
          lastUpdatedDateTime
        }
      }
    }
  }
`;

export default getAdjustmentTypes;
