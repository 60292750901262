/* eslint-disable camelcase */
import React from 'react';
import { Typography, Divider } from '@material-ui/core';
import { ResponsiveTable, AddRecordButton, SergeantModal } from 'components';
import { useLazyFetchPricebookEntry } from 'components/Tasks/components/useLazyFetchPricebookEntry';
import AlgoliaSearch from 'components/BuildHeroFormComponents/AlgoliaSearchWrapper';
import { PermissionConstants } from 'utils/AppConstants';
import invoiceLabels from 'meta/Jobs/Invoice/labels';
import { discountRows } from 'meta/Jobs/Invoice/review-report-tables';
import discountLayoutSergeant from 'meta/Jobs/Invoice/DiscountFormSergeant';
import RowActions from './ReviewReportRowActions';
import { useSetStopLoading } from '../ReviewReport/ReviewReport.hook';

const Discounts = ({ current, send, service, user, classes, isReviewReport }) => {
  const { context, value } = current;
  const setStopLoading = useSetStopLoading(service);

  /* Amount Calculation for Discount Items */
  const discountItems = context?.discounts?.map(discountItem => {
    const discount = discountItem;
    if (discount && discount.amount) {
      discount.amount = parseFloat(discount.amount).toFixed(2);
    }
    return discount;
  });

  const discountMeta = [...discountRows];

  discountMeta.push({
    id: 'actions',
    isCustom: true,
    type: 'actions',
    label: '',
    cellStyle: {
      float: 'right'
    }
  });

  const DiscountActions = ({ record }) => (
    <RowActions
      editAction={() => send('EDIT_DISCOUNT', { data: record })}
      deleteAction={() => send('DELETE_DISCOUNT', { data: record })}
      checkboxAction={event =>
        send('INCLUDE_DISCOUNT_INVOICE', {
          data: record,
          checked: event.target.checked
        })
      }
      record={record}
      isEditable
    />
  );

  const isEditable = isReviewReport && !context.freezeEdit;
  const { productFilter } = context.modalRecord;
  const filters = Object.keys(productFilter || {}).map(key => ({
    attribute: key,
    valueArray: [...productFilter[key]]
  }));
  let title = ' ';
  if (value.discount_new) title = 'Add Discount';
  if (value.discount_edited) title = 'Edit Discount';

  const fetchPricebookEntry = useLazyFetchPricebookEntry();
  const handleOnItemSelection = async (selectedItem, selectedItemName, form) => {
    const { values } = form;
    if (!selectedItem)
      return form.setValues({
        ...values,
        itemName: '',
        taxable: false,
        amount: '',
        priceBookEntryId: ''
      });
    const pbEntry = await fetchPricebookEntry({
      pricebookId: context.reviewReportPriceBookId,
      productSortKey: selectedItem.sortKey
    });
    const valuesToSet = {
      ...values,
      itemName: selectedItem.name,
      taxable: selectedItem.taxable,
      amount: pbEntry.unitPrice,
      priceBookEntryId: pbEntry.id
    };
    form.setValues(valuesToSet);
  };

  return (
    <>
      <Typography className={classes.sectionTitle}>
        {invoiceLabels.discount[user.locale]}
      </Typography>
      <Divider variant="fullWidth" classes={{ root: classes.blueDivider }} />
      {isEditable && <AddRecordButton label="+ Add discount" handle={() => send('ADD_DISCOUNT')} />}
      <ResponsiveTable
        rowMetadata={discountMeta}
        data={discountItems}
        disableFilter
        noDataMsg="No items"
        caslKey={PermissionConstants.OBJECT_REVIEWREPORT}
        customCellComponents={{ actions: DiscountActions }}
        disablePagination
        noMaxHeight
      />
      <SergeantModal
        open={value?.discount_new || value?.discount_edited || false}
        data={context.modalRecord}
        mode={value.discount_new ? 'new' : 'edit'}
        formVersion="edit"
        layout={discountLayoutSergeant(filters, handleOnItemSelection)}
        customComponents={{ AlgoliaSearch }}
        handlePrimaryAction={(values, stopLoading) => {
          setStopLoading(stopLoading);
          send('SAVE', { saveData: values });
        }}
        handleClose={() => send('BACK_TO_LOADED')}
        title={title}
      />
    </>
  );
};
export default Discounts;
