import { column, ColumnType } from 'components/XGrid/columnTypes';

export const PROJECT_COLUMNS = [
  {
    field: 'number',
    headerName: 'Number',
    valueGetter: ({ id, value }) => {
      return {
        label: value,
        to: `/project/view/${id}/dashboard`
      };
    },
    width: 150,
    ...column[ColumnType.LINK]
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'subtype',
    valueGetter: ({ row }) => {
      return row?.ProjectSubtype?.name || '-';
    },
    headerName: 'Subtype',
    width: 120,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'projectManager',
    headerName: 'Project Manager',
    valueGetter: ({ row }) => {
      return row?.projectManager?.name || '-';
    },
    width: 200,
    ...column[ColumnType.TEXT]
  },
  {
    field: 'dateStart',
    headerName: 'Date Start',
    width: 150,
    ...column[ColumnType.DATE]
  },
  {
    field: 'dateEnd',
    headerName: 'Date End',
    width: 150,
    ...column[ColumnType.DATE]
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    ...column[ColumnType.TEXT]
  }
];
