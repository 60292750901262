import gql from 'graphql-tag';

const softDeleteDepartmentById = gql`
  mutation softDeleteDepartment($partitionKey: String!, $id: String!) {
    softDeleteDepartment(partitionKey: $partitionKey, id: $id) {
      id
      version
      deletedDate
    }
  }
`;

export default softDeleteDepartmentById;
