import gql from 'graphql-tag';

const getLabourRates = gql`
  query getLabourRates(
    $partitionKey: String!
    $sortKey: String!
    $filter: TablePriceBookFilterInput
  ) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      priceBooks: priceBooks(entityConnection: "PriceBook", filter: $filter) {
        items {
          id
          isDefault
          pricebookLabourEntries(entityConnection: "PricebookLabourEntry") {
            items {
              id
              parentId
              rate
              version
              isActive
              costCodeId
              labourTypeId
              revenueTypeId
              billingHourTypeId
            }
          }
        }
      }
    }
  }
`;

export default getLabourRates;
