export const useStyles = () => ({
  projectLink: {
    textDecoration: 'none',
    color: 'inherit'
  },
  addProjectBtnContainer: {
    minWidth: 218,
    minHeight: 267,
    background: '#F0F0F0',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  addProjectBtn: {
    color: '#333333',
    border: '1px solid #333333',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    padding: '10px 20px'
  },
  searchContainer: theme => ({
    marginBottom: theme.default.spacing(2)
  })
});
