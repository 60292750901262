import gql from 'graphql-tag';

const getUnassignedVisitsWithCache = gql`
  query getUnassignedVisitsWithCache($partitionKey: String!, $sortKey: String!) {
    getCompany(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      visits(entityConnection: "Visit", filter: { status: { eq: "Unassigned" } }) {
        items {
          id
          sortKey
          version
          status
          scheduledFor
          onHold
          tentativeDate
          tentativePeriod
          description
          departmentName
          departmentId
        }
      }
    }
  }
`;

export default getUnassignedVisitsWithCache;
