const taskLaborHeader = (settingsJSON, SettingConstants, laborToggles, styles, fontFamilyStyle) =>
  laborToggles.some(toggle => settingsJSON.Tasks.Time[toggle])
    ? `<tr>
          <td colspan="2" style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;">
            <span fontfamily=${fontFamilyStyle} class="text-small"><strong>${
        settingsJSON.Tasks.Time[SettingConstants.SHOW_ITEMIZED_LABOR] ? 'Labor' : ''
      }</strong></span>
          </td>
          <td style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;text-align:right;">
            <span fontfamily=${fontFamilyStyle} class="text-small"><strong>${
        settingsJSON.Tasks.Time[SettingConstants.SHOW_ITEMIZED_LABOR_QUANTITY] ? 'Qty' : ''
      }</strong></span>
          </td>
          <td style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;text-align:right;">
            <span fontfamily=${fontFamilyStyle} class="text-small"><strong>${
        settingsJSON.Tasks.Time[SettingConstants.SHOW_ITEMIZED_LABOR_PRICING] ? 'Rate' : ''
      }</strong></span>
          </td>
          <td style="${styles}border-bottom:solid hsl(207, 90%, 54%, 0);border-left:solid hsl(207, 90%, 54%, 0);border-right:solid hsl(207, 90%, 54%, 0);border-top:solid hsl(207, 90%, 54%, 0);padding:2px;text-align:right;">
            <span fontfamily=${fontFamilyStyle} class="text-small"><strong>${
        settingsJSON.Tasks.Time[SettingConstants.SHOW_ITEMIZED_LABOR_PRICING] ? 'Subtotal' : ''
      }</strong></span>
          </td>
        </tr>`
    : ``;

export default taskLaborHeader;
