import gql from 'graphql-tag';

const getInvoiceLineItem = gql`
  query getInvoiceLineItem($partitionKey: String!, $sortKey: String!) {
    getInvoice(partitionKey: $partitionKey, sortKey: $sortKey) {
      id
      sortKey
      invoiceNumber
      invoiceItems(entityConnection: "InvoiceItem") {
        items {
          id
          name
          description
          quantity
          unitCost
          unitPrice
          amount
          taxable
        }
      }
    }
  }
`;

export default getInvoiceLineItem;
