import React from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import labels from 'meta/Scheduler/labels';
import iconMap from '../assets/icons';
import styles from './styles';

class ScheduleInfo extends React.Component {
  render() {
    const { classes, scheduledFor, locale } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.row}>
          {iconMap('scheduled', 16)}
          <span className={classNames(classes.scheduledText, classes.left)}>
            {labels.viewVisit_scheduleInfo_scheduledFor[locale]}
          </span>
        </div>
        <div className={classes.row}>
          <span className={classNames(classes.dateText, classes.left)}>{scheduledFor}</span>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ScheduleInfo);
