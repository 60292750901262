import React from 'react';
import { jsx } from '@emotion/react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const style = {
  viewIcon: {
    height: 16
  }
};

const ViewableIcon = ({ viewable, color }) =>
  viewable ? (
    <VisibilityIcon color="primary" css={{ ...style.viewIcon, color }} />
  ) : (
    <VisibilityOffIcon color="primary" css={{ ...style.viewIcon, color }} />
  );

export default ViewableIcon;
