/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { MUIForm } from '@buildhero/sergeant';
import PropTypes from 'prop-types';
import { Dialog, Grid } from '@material-ui/core';
import { Mode } from 'utils/constants';
import Spinner from 'components/Spinners/CircularIndeterminate';
import ErrorBoundaries from 'scenes/Error';
import { makeStyles } from '@material-ui/core/styles';
import { toTitleCase } from 'utils';
import BODialogTitle from './BODialogTitle';
import BODialogContent from './BODialogContent';
import BODialogActions from './BODialogActions';
import BOButton from './BOButton';
import ConfirmRemoveContent from './ConfirmRemoveContent';

const useStyles = makeStyles({
  paper: {
    maxWidth: props => props.maxWidth,
    overflowY: 'visible'
  }
});

const sergeantModalPropTypes = {
  // controls open state of the modal
  open: PropTypes.bool.isRequired,
  // provide data when edit mode
  data: PropTypes.object,
  // provide the data type name as a string (casing doesn't matter), ex: attachment
  dataType: PropTypes.string.isRequired,
  // is 'primary' or 'secondary' based on BuildHeroTheme
  customSecondaryButtonColor: PropTypes.string,
  // one of Mode defined in constants.js - edit, view, add, delete (no workflow for view yet)
  mode: PropTypes.oneOf(Object.values(Mode)).isRequired,
  // provide layout when not delete mode - MUIForm
  layout: PropTypes.shape({
    fields: PropTypes.object,
    layouts: PropTypes.object
  }),
  // provide maxWidth if desiring a narrower modal than content or caption would allow.
  maxWidth: PropTypes.number,
  // provide a text representation of the data when delete mode
  confirmRemoveStatement: PropTypes.string,
  // provide when using custom components for MUIForm
  customComponents: PropTypes.object,
  // callback function when user performs the primary action. should have 2 params:
  // data: the form data when edit/add, otherwise null
  // callback: call this function once server responds to stop the spinner
  handlePrimaryAction: PropTypes.func.isRequired,
  // function to close the modal
  handleClose: PropTypes.func.isRequired,
  // boolean that determines whether the primary button is disabled
  disablePrimaryButton: PropTypes.bool,
  // custom icon to display next to modal title
  titleIcon: PropTypes.func
};

const defaultSergeantModalPropTypes = {
  data: {},
  layout: { fields: {}, layouts: {} },
  confirmRemoveStatement: '',
  maxWidth: null,
  customComponents: null,
  disablePrimaryButton: false,
  titleIcon: null
};

function SergeantModal({
  open,
  data,
  dataType,
  customPrimaryButtonLabel,
  customSecondaryButtonLabel,
  customSecondaryButtonColor,
  mode,
  layout,
  renderContent,
  confirmRemoveItemLabel,
  confirmRemoveStatement,
  onFieldChange,
  onFormChange,
  formVersion,
  customComponents,
  maxWidth,
  handlePrimaryAction,
  handleSecondaryAction,
  warningMessageFont,
  handleClose,
  hideTitle,
  disablePrimaryButton,
  title: headerTitle,
  alignCloseRight,
  validationSchema,
  titleIcon,
  fullWidth
}) {
  const classes = useStyles({ maxWidth });
  const [formService, setFormService] = useState(null);

  const Loading = { FALSE: 0, PRIMARY: 1, SECONDARY: 2 };
  const [isLoading, setIsLoading] = useState(Loading.FALSE);

  const stopLoading = () => setIsLoading(Loading.FALSE);

  const isConfirmRemoveMode = [Mode.DELETE, Mode.DEACTIVATE, Mode.VOID].includes(mode);

  const title = headerTitle || toTitleCase(`${mode} ${dataType}`);
  const validData = data ?? {};
  const primaryButtonLabel = isConfirmRemoveMode ? title.toLocaleLowerCase() : 'Save';
  let content;
  if (isConfirmRemoveMode) {
    content = (
      <ConfirmRemoveContent
        dataType={dataType}
        data={validData}
        confirmRemoveItemLabel={confirmRemoveItemLabel}
        confirmRemoveStatement={confirmRemoveStatement}
        mode={mode}
        warningMessageFont={warningMessageFont}
      />
    );
  } else if (renderContent) {
    content = renderContent({
      setFormService,
      onComplete: completed => {
        setIsLoading(Loading.PRIMARY);
        handlePrimaryAction(completed, stopLoading);
      }
    });
  } else {
    content = layout && (
      <MUIForm
        configuration={layout}
        customComponents={customComponents}
        data={validData}
        layout={formVersion ?? 'edit'}
        onCreateService={service => setFormService(service)}
        onComplete={completed => {
          setIsLoading(Loading.PRIMARY);
          handlePrimaryAction(completed, stopLoading);
        }}
        onFieldChange={onFieldChange}
        onFormChange={onFormChange}
        validationSchema={validationSchema}
      />
    );
  }

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md" classes={classes} fullWidth={fullWidth}>
      <ErrorBoundaries>
        <BODialogTitle onClose={handleClose} alignCloseRight={alignCloseRight}>
          {titleIcon && titleIcon()}
          {!hideTitle && title}
        </BODialogTitle>
        <BODialogContent dividers>{content}</BODialogContent>
        {mode !== Mode.VIEW && (
          <BODialogActions>
            <Grid container direction="column">
              <Grid item style={{ paddingTop: 10, paddingBottom: 10 }}>
                <BOButton
                  onClick={() => {
                    if (isConfirmRemoveMode || mode === Mode.ACTIVATE) {
                      setIsLoading(Loading.PRIMARY);
                      handlePrimaryAction(data, stopLoading);
                    } else formService.submit();
                  }}
                  variant="contained"
                  isConfirmRemoveMode={isConfirmRemoveMode}
                  disabled={isLoading !== Loading.FALSE || disablePrimaryButton}
                  testingid={customPrimaryButtonLabel || primaryButtonLabel}
                >
                  {isLoading === Loading.PRIMARY ? (
                    <Spinner styles={{ margin: 0 }} size={12} />
                  ) : (
                    customPrimaryButtonLabel || primaryButtonLabel
                  )}
                </BOButton>
              </Grid>
              {handleSecondaryAction && (
                <Grid item>
                  <BOButton
                    onClick={() => {
                      setIsLoading(Loading.SECONDARY);
                      handleSecondaryAction(data, stopLoading);
                    }}
                    color={customSecondaryButtonColor}
                    variant="contained"
                    isConfirmRemoveMode={isConfirmRemoveMode}
                    disabled={isLoading !== Loading.FALSE}
                    testingid={customSecondaryButtonLabel}
                  >
                    {isLoading === Loading.SECONDARY ? (
                      <Spinner styles={{ margin: 0 }} size={12} />
                    ) : (
                      customSecondaryButtonLabel
                    )}
                  </BOButton>
                </Grid>
              )}
            </Grid>
          </BODialogActions>
        )}
      </ErrorBoundaries>
    </Dialog>
  );
}

SergeantModal.propTypes = sergeantModalPropTypes;
SergeantModal.defaultProps = defaultSergeantModalPropTypes;

export default SergeantModal;
