import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import ErrorBoundaries from 'scenes/Error';
import { ResponsiveTable, PageHeader } from 'components';
import CreateEntryButton from 'components/Buttons/CreateEntryButton';
import { PermissionConstants } from 'utils/AppConstants';
import { CommonService } from 'services/core';
import { SERVICE_AGREEMENTS_LIST_DATA } from './queries';
import { getBillDateFormatted } from './DetailView/helpers';
import CreateServiceAgreement from './CreateServiceAgreement';

const ServiceAgreementsView = ({ user, history }) => {
  const commonService = useRef(new CommonService()).current;
  const [createAgreementModal, setcreateAgreementModal] = useState(false);

  document.title = 'Service Agreements';
  const getServiceAgreementsListData = async (columns, filter, sorting, pagination) => {
    const { data } = await commonService.getQuery(
      {
        columns,
        filter,
        sorting,
        pagination
      },
      SERVICE_AGREEMENTS_LIST_DATA
    );

    const saItemsWithFormattedDates = data.serviceAgreementsListData.items?.map(sa => {
      const { nextBillDate, lastBilledDate } = getBillDateFormatted(sa) || {};
      return { ...sa, nextBillDate, lastBilledDate };
    });

    return { ...data.serviceAgreementsListData, items: saItemsWithFormattedDates };
  };

  const handleModalClose = (close, data) => {
    setcreateAgreementModal(close);
    if (data?.id) history.push(`/serviceAgreement/edit/${data.id}`);
  };
  return (
    <ErrorBoundaries>
      <PageHeader pageMapKey="serviceAgreements" userLocale={user.locale}>
        <Box display="flex">
          <CreateEntryButton
            label="New Agreement"
            caslKey={PermissionConstants.OBJECT_SERVICE_AGREEMENT}
            key="createAgreement"
            handleAdd={() => setcreateAgreementModal(true)}
          />
        </Box>
      </PageHeader>
      <ResponsiveTable
        fullScreen
        tableName="Service Agreement"
        user={user}
        caslKey={PermissionConstants.OBJECT_SERVICE_AGREEMENT}
        noDataMsg="No Service Agreements"
        listDataService={getServiceAgreementsListData}
        timezoneSensitiveColumnIds={[
          'startDate',
          'endDate',
          'nextBillDate',
          'lastBilledDate',
          'firstBillDate'
        ]}
      />
      <CreateServiceAgreement
        showModal={createAgreementModal}
        onModalClose={handleModalClose}
        user={user}
      />
    </ErrorBoundaries>
  );
};

ServiceAgreementsView.propTypes = {
  user: PropTypes.shape({
    locale: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps, null)(ServiceAgreementsView);
