import React, { useCallback, useState } from 'react';
import { Modal, Button, ThemeProvider, SgtForm } from '@buildhero/sergeant';
import ErrorBoundaries from 'scenes/Error';

import configuration from './NewTenantModal.config';
import { useProvisionTenant } from './NewTenantModal.gql';

const NewTenantModal = ({ open, onClose }) => {
  const [formService, setFormService] = useState();
  const [provisionTenant, { loading }] = useProvisionTenant();

  const handleSubmit = useCallback(
    async newData => {
      const {
        data: {
          tenantProvision: { id }
        }
      } = await provisionTenant({ ...newData, type: newData.type?.value });
      onClose(id);
    },
    [provisionTenant, onClose]
  );

  return (
    <ErrorBoundaries>
      <ThemeProvider>
        <Modal
          title="New Tenant"
          open={open}
          onClose={() => onClose()}
          actions={
            <Button
              loading={loading}
              name="save"
              onClick={formService?.formikContext?.handleSubmit}
            >
              Save Tenant
            </Button>
          }
          fullScreen
        >
          <SgtForm
            layout="default"
            configuration={configuration}
            onCreateService={setFormService}
            initialValues={{
              firstName: '',
              lastName: '',
              companyName: '',
              accountName: '',
              userName: '',
              email: '',
              type: { label: 'Test', value: 'Test' },
              salesforceId: '',
              tier: 'free',
              status: 'active',
              ownerName: 'BuildOps'
            }}
            onSubmit={handleSubmit}
            formikProps={{
              validateOnChange: false,
              validateOnBlur: true
            }}
          />
        </Modal>
      </ThemeProvider>
    </ErrorBoundaries>
  );
};

export default NewTenantModal;
