import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { GoogleApiWrapper } from 'google-maps-react';
import { GOOGLE_MAPS_API_KEY } from 'utils/AppConstants';
import PlacesAutoComplete from '../PlacesAutoComplete';
import MuiTextInput from '../MuiTextInput';
import { makeStyles } from '@material-ui/styles';
import { addressObjectToString } from 'utils';
import { GoogleMapsComponentType } from 'utils/AppConstants';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  placesSearch: {
    paddingBottom: theme.spacing(3)
  }
}));

const CityCountyStatePicker = props => {
  const { form } = props;
  const initialAddressObject = form.values[props.field.name];
  const [addressFields, setAddressFields] = useState(initialAddressObject);
  const [textValue, setTextValue] = useState(addressObjectToString(initialAddressObject));
  const classes = useStyles();

  const addressComponentKeys = {
    state: GoogleMapsComponentType.STATE,
    county: GoogleMapsComponentType.COUNTY,
    city: GoogleMapsComponentType.CITY
  };

  const handleSelect = addressComponents => {
    // Adapt from Maps API native format to our frontend format
    const parsedAddressComponents = {};
    Object.keys(addressComponentKeys).forEach(key => {
      const addressComponentType = addressComponentKeys[key];
      // addressComponent has a longName and shortName property
      const addressComponent = addressComponents[addressComponentType];
      // if state, store CA not california
      const componentName =
        addressComponentType === GoogleMapsComponentType.STATE
          ? addressComponent?.shortName
          : addressComponent?.longName;
      if (componentName) parsedAddressComponents[key] = componentName;
    });
    // Only update if there is meaningful data
    if (Object.keys(parsedAddressComponents).length <= 0) return;
    if (props.field.name) {
      form.setFieldValue(props.field.name, parsedAddressComponents);
    }
    setAddressFields({ ...parsedAddressComponents });
    setTextValue(addressObjectToString(parsedAddressComponents));
  };

  const handleChange = event => {
    if (event?.target) {
      setTextValue(event.target.value);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Grid container spacing={3} className={classes.placesSearch}>
        <Grid item xs={12} md={6}>
          <PlacesAutoComplete
            onSelect={handleSelect}
            onChange={handleChange}
            value={textValue}
            searchOptions={{ types: ['(regions)'] }}
            label={'City / County / State'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <MuiTextInput disabled value={addressFields.city || ''} label="City" />
        </Grid>
        <Grid item xs={12} md={3}>
          <MuiTextInput disabled value={addressFields.county || ''} label="County" />
        </Grid>
        <Grid item xs={12} md={3}>
          <MuiTextInput disabled value={addressFields.state || ''} label="State" />
        </Grid>
      </Grid>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAPS_API_KEY
})(CityCountyStatePicker);
