import moment from 'moment';
import { ElementSizes } from '@dispatch/Dispatch.styles';

const numberOfIntervalsInHour = 4;
const intervalInPixels = ElementSizes.cellWidth / numberOfIntervalsInHour;
const intervalInMinutes = 60 / numberOfIntervalsInHour;
const { cellWidth } = ElementSizes;

export const calculateHoverDataFromPosition = hoverPosition => {
  if (hoverPosition < ElementSizes?.techCellWidth) {
    return {
      position: null,
      time: null
    };
  }

  const interval = Math.floor((hoverPosition - ElementSizes.techCellWidth) / intervalInPixels);
  const position = ElementSizes.techCellWidth + interval * intervalInPixels;
  const minutes = interval * intervalInMinutes;
  const time = moment().set({
    hour: 0,
    minutes,
    second: 0,
    millisecond: 0
  });
  return {
    position,
    time: time.format('h:mm a'),
    minutes
  };
};

export const getCreatePreviewProps = ({ techId, eventData }) => {
  if (
    eventData?.primaryTechId !== techId ||
    !eventData?.scheduledFor ||
    !eventData?.actualDuration
  ) {
    return;
  }
  const { scheduledFor, actualDuration } = eventData;
  const date = moment.unix(scheduledFor);
  const midnight = moment(date).startOf('day');
  const minutes = date.diff(midnight, 'minutes');
  return {
    left: (cellWidth / 60) * minutes,
    width: (cellWidth / 60) * actualDuration
  };
};
