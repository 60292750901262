/**
 * Transforms the settings object into a simpler-named object for use in config
 * @param {object} settings Settings object parsed from JSON
 */
export const parseInvoiceSettings = settings => {
  const laborLineItems = settings.Labor['Labor Line Items'];
  // parts & materials
  const pm = settings['Parts & Materials'];
  const pmLineItems = pm['Parts & Materials Line Items'];
  // discounts & fees
  const df = settings['Discounts & Fees'];
  const dfLineItems = df['Discounts & Fees Line Items'];

  const paymentLineItems = settings.Payments['Payment Line Items'];
  const totals = settings['Totals & Subtotals'];
  return {
    labor: {
      total: settings.Labor['Show labor total'],
      name: laborLineItems['Show labor name'],
      description: laborLineItems['Show labor description'],
      taxable: laborLineItems['Show labor taxable'],
      hours: laborLineItems['Show labor hours'],
      rate: laborLineItems['Show labor rate'],
      subtotal: laborLineItems['Show labor subtotal']
    },
    partsAndMaterials: {
      total: pm['Show parts & materials total'],
      name: pmLineItems['Show item name'],
      code: pmLineItems['Show product code'],
      description: pmLineItems['Show item description'],
      taxable: pmLineItems['Show item taxable'],
      quantity: pmLineItems['Show item quantity'],
      unitPrice: pmLineItems['Show item unit price'],
      subtotal: pmLineItems['Show item subtotal']
    },
    discountsAndFees: {
      total: df['Show discounts & fees total'],
      name: dfLineItems['Show item name'],
      description: dfLineItems['Show item description'],
      type: dfLineItems['Show item type'],
      taxable: dfLineItems['Show item taxable'],
      quantity: dfLineItems['Show item quantity'],
      unitPrice: dfLineItems['Show item unit price'],
      subtotal: dfLineItems['Show item subtotal']
    },
    payments: {
      total: settings.Payments['Show payments total'],
      number: paymentLineItems['Show payment number'],
      type: paymentLineItems['Show payment type'],
      date: paymentLineItems['Show payment date'],
      amount: paymentLineItems['Show payment amount']
    },
    totals: {
      subtotal: totals['Show invoice subtotal'],
      discount: totals['Show discounts & fees'],
      tax: totals['Show tax'],
      amountPaid: totals['Show amount paid'],
      balance: totals['Show balance']
    },
    mode: {
      simple: settings['Invoice Mode']['Simple Invoice']
    }
  };
};
